import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { SELECT_ASSIGN_JOBS } from './graphql/Query.js'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectAssignJobs = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const [isData, isSetData] = useState([])

  const { isActive, jobGroupCode, jobflag } = filters || {}
  const [getAssignJobsFn, getAssignJobs] = useLazyQuery(SELECT_ASSIGN_JOBS)

  useEffect(() => {
    getAssignJobsFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [isActive, jobGroupCode, jobflag])

  useEffect(() => {
    if (getAssignJobs.data) {
      isSetData(
        _.map(getAssignJobs?.data?.getAssignJobs, (item, index) => {
          return {
            key: index,
            label: `${item.jobNumber}` + ' ' + displayText(item?.jobSubjectTH, item?.jobSubjectEN),
            value: item?.jobCode,
            source: item,
          }
        }),
      )
    }
  }, [getAssignJobs.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getAssignJobs.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          return handleChange(_.find(isData, (data) => data.value === value))
        }}
      />
    </>
  )
}

export default SelectAssignJobs
