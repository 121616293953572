import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import * as _ from 'lodash'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { LabelNew as Label } from '../../../components/label/Label'
import { ButtonNew } from '../../../components/button/Button'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { InputNew } from '../../../components/input/Input'
import { Datatable } from '../../../components/datatable/Datatable'
import { Status } from '../../../components/status/Status'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayText, displayError } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import SelectJobTypes from '../../../components/inputfromapi/selectjobtypes/SelectJobTypes'
import SelectApproveStatus from '../../../components/inputfromapi/selectapprovestatus/SelectApproveStatus.jsx'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import { Columns } from './utils/ApproveAdhocJobColumns'
import { GET_APPROVE_ADHOCJOB_PAGINATION } from './graphql/Query'
import { useDecodeUser } from '../../../hooks/useDecodeUser'

const ApproveAdhocJobList = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const Responsive = {
    md: 8,
    lg: 8,
  }
  const ResponsiveTable = {
    md: 24,
    lg: 24,
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/adhocjob' },
    { label: t('อนุมัติการมอบหมายงาน') },
  ]

  const [user] = useDecodeUser()
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' },
  ])
  const [filters, setFilters] = useState({})
  const [isPCode, setPCode] = useState(null)
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)

  /* ---------------- API ----------- */
  const [getDataPaginationFn, dataPagination] = useLazyQuery(GET_APPROVE_ADHOCJOB_PAGINATION)

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    const inputSearch = {
      approveStatusCode: input.approveStatusCode || '',
      dateEnd: input.dateEnd || '',
      dateStart: input.dateStart || '',
      jobTypeCode: input.jobTypeCode || '',
      organizationCode: input.organizationCode || '',
      requestPersonnel: input.requestPersonnel || '',
      personnelCode: isPCode,
    }

    setFilters(inputSearch)
    getDataPaginationFn({
      variables: {
        input: encryptInput({
          filters: inputSearch,
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }

  const resetField = () => {
    form.resetFields()
    setFilters({})
    getDataPaginationFn({
      variables: {
        input: encryptInput({
          filters: { personnelCode: isPCode },
          pagination: { limit: 10, page: 1 },
          sort: [
            { fieldSort: 'updatedAt', sortType: 'DESC' },
            { fieldSort: 'createdAt', sortType: 'DESC' },
          ],
        }),
      },
    })
  }

  const showErrorAlert = (item) => {
    Alert({
      type: 'error',
      title: t(item.title),
      content: t(displayError(item.content)),
      onOk() {},
    })
  }

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    user && setPCode(user?.pCode)
  }, [user])

  useEffect(() => {
    if (isPCode) {
      getDataPaginationFn({
        variables: {
          input: encryptInput({
            filters: { ...filters, personnelCode: isPCode },
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
    }
  }, [page, limit, sort, isPCode])

  useEffect(() => {
    if (dataPagination.data) {
      let response = dataPagination?.data?.getApproveAdhocJobPagination
      setCount(response?.count)

      setDataLists(
        _.map(response?.result, (data) => {
          let assignJobPersonnels = []
          const listPersonnel = displayText(data.assignJobPersonnelsTH, data.assignJobPersonnelsEN)
          if (listPersonnel) {
            assignJobPersonnels = listPersonnel.split(', ')
          }

          return {
            ...data,
            key: data.no,
            jobSubject: displayText(data.jobSubjectTH, data.jobSubjectEN),
            jobTypeName: displayText(data.jobTypeNameTH, data.jobTypeNameEN),
            responsibleOrganizationName:
              displayText(data.responsibleOrganizationNameTH, data.responsibleOrganizationNameEN) || '-',
            assignJobPersonnels: assignJobPersonnels.map((item, idx) => {
              return (
                <React.Fragment key={`${item}${idx}`}>
                  {item}
                  {idx < assignJobPersonnels.length && <br />}
                </React.Fragment>
              )
            }),
            requestPersonnel: data.requestPersonnel || '-',
            requestOrganizationName: displayText(data.requestOrganizationNameTH, data.requestOrganizationNameEN) || '-',
            durationJob: dateDisplay(data.dateStart) + ' - ' + dateDisplay(data.dateEnd),
            approveStatusName: (
              <Status
                text={
                  data.approveStatusCode === '10'
                    ? 'รออนุมัติ'
                    : data.approveStatusCode === '20'
                    ? 'อนุมัติ'
                    : 'ไม่อนุมัติ'
                }
              />
            ),
            tools:
              data.approveStatusCode === '10' ? (
                <ButtonNew
                  type="flagOutlinedInTableBlue"
                  roles={{ type: 'approve', menu: menu }}
                  onClick={() => navigate(`form`, { state: { type_action: 'approve', refCode: data.jobCode } })}
                >
                  {t('อนุมัติ')}
                </ButtonNew>
              ) : (
                <ButtonNew
                  type="moreHorizontalInTableOrange"
                  roles={{ type: 'approve', menu: menu }}
                  onClick={() => navigate(`form`, { state: { type_action: 'approve', refCode: data.jobCode } })}
                >
                  {t('แก้ไขการอนุมัติ')}
                </ButtonNew>
              ),
          }
        }),
      )
    }

    if (dataPagination.error) {
      showErrorAlert({
        title: 'ไม่สามารถดึงข้อมูลได้',
        content: dataPagination.error,
      })
    }
  }, [dataPagination.data])

  return (
    <>
      {dataPagination.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('อนุมัติการมอบหมายงาน')} />
      <CardNew topic={t('Filters')} icon="Filter" toggledrop="true" buttontopright="true">
        <Form name="form-search" form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[12, 12]}>
            <Col {...Responsive}>
              <Form.Item name="jobTypeCode" label={<Label type="tab-header-inactive">{t('ประเภทงาน')}</Label>}>
                <SelectJobTypes
                  placeholder={t('เลือกประเภทงาน')}
                  formname={'form-search'}
                  filters={{ jobflag: 1, isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ jobTypeCode: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name="approveStatusCode"
                label={<Label type="tab-header-inactive">{t('สถานะของการอนุมัติ')}</Label>}
              >
                <SelectApproveStatus
                  placeholder={t('เลือกสถานะ')}
                  formname={'form-search'}
                  filters={{ isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ approveStatusCode: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'dateStart'}
                label={<Label type="tab-header-inactive">{t('วันที่เริ่มต้น')}</Label>}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระบุ'),
                  },
                ]}
              >
                <Datepicker
                  autoComplete="off"
                  setValueDateFn={async (data) => {
                    await setIsMinDate(data), await form.setFieldsValue({ dateStart: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateStart: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                  isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'dateEnd'}
                label={<Label type="tab-header-inactive">{t('วันที่สิ้นสุด')}</Label>}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระบุ'),
                  },
                ]}
              >
                <Datepicker
                  autoComplete="off"
                  setValueDateFn={async (data) => {
                    await setIsMaxDate(data), await form.setFieldsValue({ dateEnd: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateEnd: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                  isMinDate={isMinDate ? new Date(isMinDate) : null}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="organizationCode" label={<Label type="tab-header-inactive">{t('หน่วยงาน')}</Label>}>
                <SelectOrganization
                  placeholder={t('เลือกหน่วยงาน')}
                  formname={'form-search'}
                  filters={{ isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ organizationCode: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name={'requestPersonnel'} label={<Label type="tab-header-inactive">{t('ผู้ร้องขอ')}</Label>}>
                <InputNew placeholder={t('ระบุผู้ร้องขอ')} maxLength={100} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <ButtonNew type="search" htmlType="submit">
                {t('ค้นหา')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew type="clear" onClick={() => resetField()}>
                {t('ล้างค่า')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </CardNew>

      <CardNew topic={t('รายการงานมอบหมาย')} icon="List" toggledrop="false">
        <Row gutter={[12, 12]}>
          <Col {...ResponsiveTable}>
            <Datatable
              paginationCustom={true}
              columns={Columns}
              data={[...dataLists]}
              total={count}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => {
                const reNewField = [
                  { jobSubject: displayText('jobSubjectTH', 'jobSubjectEN') },
                  { jobTypeName: displayText('jobTypeNameTH', 'jobTypeNameEN') },
                  {
                    responsibleOrganizationName: displayText(
                      'responsibleOrganizationNameTH',
                      'responsibleOrganizationNameEN',
                    ),
                  },
                  {
                    requestOrganizationName: displayText('requestOrganizationNameTH', 'requestOrganizationNameEN'),
                  },
                  { approveStatusName: displayText('approveStatusNameTH', 'approveStatusNameEN') },
                  { assignJobPersonnels: displayText('assignJobPersonnelsTH', 'assignJobPersonnelsEN') },
                  { durationJob: 'durationJob' },
                ]
                setSort(handleSort(e?.sorter, reNewField))
              }}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default ApproveAdhocJobList
