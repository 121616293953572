import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'antd'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Status } from '../../../components/status/Status'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayError, displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import ControlFormFilter from './components/filter/ControlFormFilter'
import EmailModal from './components/modal/EmailModal'
import { DELETE_CONTROL_FORM } from './graphql/Mutation'
import { GET_CONTROL_FORM_PAGINATION } from './graphql/Query'
import { controlFormListCrumb } from './utils/constants'

/**
 * @function ControlFormList
 * @description
 * Menu: Control Form
 * เมนู: แบบประเมินควบคุมภายใน
 * @returns Component
 */

const ControlFormList = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [user] = useDecodeUser()

  const [modalEmail, setModalEmail] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState([])
  const [isCotrolFormCode, setIsControlFormCode] = useState(null)

  const [getControlFormsFn, getControlForms] = useLazyQuery(GET_CONTROL_FORM_PAGINATION)
  const [deleteControlFormFn, deleteControlForm] = useMutation(DELETE_CONTROL_FORM)

  useEffect(() => {
    if (user?.userId) {
      getControlFormsFn({
        variables: {
          input: encryptInput({
            filters: { ...filters, createdBy: user?.userId },
            pagination: { limit, page },
            sort
          })
        }
      })
    }
  }, [page, limit, filters, sort, user?.userId])

  useEffect(() => {
    if (getControlForms?.data) {
      let response = getControlForms?.data?.getControlFormPagination
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            ...data,
            key: data.controlFormId,
            operationStatusCodeButton: data.operationStatusCode,
            operationStatusCodeIcon: data.operationStatusCode,
            controlFormEN: `${data.controlFormCode}: ${data.controlFormEN}`,
            controlFormTH: `${data.controlFormCode}: ${data.controlFormTH}`,
            controlFormYear: displayText(data.controlFormYear + 543, data.controlFormYear),
            dateEnd: dateDisplay(data.dateEnd),
            dateStart: dateDisplay(data.dateStart)
          }
        })
      )
    }

    if (getControlForms.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getControlForms?.error)),
        onOk() {}
      })
    }
  }, [getControlForms?.data])

  useEffect(() => {
    if (deleteControlForm?.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          getControlForms.refetch()
        }
      })
    }

    if (deleteControlForm.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(deleteControlForm?.error)),
        onOk() {}
      })
    }
  }, [deleteControlForm.data])

  const onFilter = (data) => {
    Promise.all([setPage(1), setLimit(10), setFilters(data)])
  }

  const onDeleteControlForm = (controlFormId) =>
    Alert({
      type: 'confirm',
      title: t('กรุณากดยืนยันหากจะลบข้อมูลนี้'),
      onOk() {
        deleteControlFormFn({ fetchPolicy: 'no-cache', variables: encryptInput({ controlFormId: controlFormId }) })
      },
      onCancel() {}
    })

  const columns = [
    { title: t('ประจำปี'), dataIndex: 'controlFormYear', align: 'center', sorter: { multiple: 1 }, width: '100px' },
    { title: t('ชื่อแบบประเมิน'), dataIndex: displayText('controlFormTH', 'controlFormEN'), width: '350px' },
    { title: t('กำหนดส่งแบบประเมิน'), dataIndex: 'dateEnd', align: 'center', sorter: { multiple: 2 }, width: '200px' },
    {
      title: t('สถานะดำเนินการ'),
      dataIndex: 'operationStatusCode',
      align: 'center',
      sorter: { multiple: 3 },
      width: '150px',
      render: (d, record) => <Status text={displayText(record?.operationStatusNameTH, record?.operationStatusNameEN)} />
    },
    {
      title: t('สถานะการใช้งาน'),
      dataIndex: 'isActive',
      align: 'center',
      sorter: { multiple: 4 },
      width: '150px',
      render: (d) => <Status text={d ? 'ใช้งาน' : 'ไม่ใช้งาน'} />
    },
    {
      title: '',
      dataIndex: 'operationStatusCodeButton',
      width: '180px',
      render: (text, record) => {
        if (!record.isActive) {
          return (
            <ButtonNew type="flagOutlinedInTableGray" roles={{ type: 'add', menu: props.menu }}>
              {t('ส่งแบบประเมิน')}
            </ButtonNew>
          )
        } else {
          switch (record.operationStatusCode) {
            case '10':
              return (
                <ButtonNew
                  type="flagOutlinedInTableBlue"
                  roles={{ type: 'add', menu: props.menu }}
                  onClick={() => {
                    setIsControlFormCode(record)
                    setModalEmail(true)
                  }}
                >
                  {t('ส่งแบบประเมิน')}
                </ButtonNew>
              )
            case '20':
              return (
                <ButtonNew type="moreHorizontalInTableOrange" roles={{ type: 'add', menu: props.menu }}>
                  {t('กำลังดำเนินการ')}
                </ButtonNew>
              )
            case '30':
              return (
                <ButtonNew type="moreHorizontalInTableGray" roles={{ type: 'add', menu: props.menu }}>
                  {t('ประเมินเสร็จสิ้น')}
                </ButtonNew>
              )
            default:
              return null
          }
        }
      }
    },
    {
      title: '',
      dataIndex: 'operationStatusCodeIcon',
      width: '120px',
      align: 'right',
      render: (text, record) => {
        let list = [
          {
            type: 'copy',
            onClick: () =>
              navigate('/internalcontrol/controlform/copy', {
                state: { activeType: 'copy', controlFormId: record?.controlFormId }
              })
          },
          {
            type: 'view',
            onClick: () =>
              navigate('/internalcontrol/controlform/view', {
                state: { activeType: 'view', controlFormId: record?.controlFormId }
              })
          }
        ]
        text === '10' &&
          list.splice(
            2,
            0,
            {
              type: 'edit',
              onClick: () => {
                navigate('/internalcontrol/controlform/edit', {
                  state: { activeType: 'edit', controlFormId: record?.controlFormId }
                })
              }
            },
            { type: 'delete', onClick: () => onDeleteControlForm(record.controlFormId) }
          )
        return <ButtonGroup menu={props.menu} size={18} icons={list} />
      }
    }
  ]

  return (
    <>
      {(deleteControlForm?.loading || getControlForms?.loading) && <SpinnersNew />}
      <Col sm={24} md={24} lg={24}>
        <Breadcrumb data={controlFormListCrumb} title={t('แบบประเมินควบคุมภายใน')} />
        <ControlFormFilter onFilter={onFilter} menu={props.menu} />
        <div style={{ marginBottom: '2rem' }}>
          <CardNew topic={t('รายการแบบประเมินควบคุมภายใน')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col sm={24} md={24} lg={24} xl={24}>
                <Datatable
                  columns={columns}
                  data={[...dataLists]}
                  total={count}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  pageSize={limit}
                  page={page}
                  showSizeChanger={true}
                  searchCustom={true}
                  handleTableChange={(e) => setSort(handleSort(e?.sorter))}
                  btnAdd={
                    <Link to="create">
                      <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: props.menu }}>
                        {t('เพิ่มแบบประเมินควบคุมภายใน')}
                      </ButtonNew>
                    </Link>
                  }
                />
              </Col>
            </Row>
          </CardNew>
        </div>
        {modalEmail && (
          <EmailModal
            close={(e) => setModalEmail(e)}
            getSendMail={() => getControlForms.refetch()}
            open={modalEmail}
            controlFormCode={isCotrolFormCode}
            menu={props.menu}
          />
        )}
      </Col>
    </>
  )
}

ControlFormList.propTypes = {
  menu: PropTypes.string.isRequired
}

export default ControlFormList
