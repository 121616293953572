import { gql } from '@apollo/client'

export const GET_AUDITOR_WORK_PAGINATION = gql`
  query getAuditorWorkPagination($input: AuditorWorkFilterInput!) {
    getAuditorWorkPagination(input: $input) {
      result {
        projectCode
        projectNameTH
        projectNameEN
        dateStart
        dateEnd
        countIssue
        countSuggest
        count10
        count20
        countNull
        auditOpenDoc {
          key
          docTypeName
          openDocDetailTH
          openDocDetailEN
          fileDetail
          dateRequest
          listFile {
            fileId
            filename
            links
            refFile {
              originalname
              name
              fileType
            }
          }
        }
      }
      summary {
        summaryIssue
        summarySuggest
        countAssignActivity
        countNotAssignActivity
      }
      count
      page
      limit
    }
  }
`
