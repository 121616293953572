/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ModalNew } from '../../../../components/modal/Modal'
import { InputNew } from '../../../../components/input/Input'
import { encryptInput, decryptData } from '../../../../utilitys/crypto'
import { convertEmailJson } from '../../../../utilitys/helper'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { Alert } from '../../../../components/alert/Alert'
import { displayError } from '../../../../utilitys/helper'
import SelectTagEmailPersonnels from '../../../../components/inputfromapi/selecttagemailpersonnels/SelectTagEmailPersonnels'
import { GET_EMAIL_ORGANIZATION_BY_FORM } from '../graphql/Query'
import { SEND_MAIL_RISK_FORM } from '../graphql/Mutation'
const formItemLayout = {
  labelCol: { sm: 14, md: 6, lg: 6, xl: 6 },
  wrapperCol: { sm: 16, md: 18, lg: 18, xl: 18, align: 'left' },
  Col: {
    sm: 24,
    md: 16,
    lg: 18,
  },
}
const SendMailModal = (props) => {
  const { open, close, riskFormData, onSuccess } = props

  const { email, userId, pCode, personnelEmail } = decryptData(localStorage.getItem('user'))
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const Item = Form.Item
  const formName = 'sendmail'

  const [visible, setVisible] = useState(false)

  const [getEmailOrganizationByFormFn, getEmailOrganizationByForm] = useLazyQuery(GET_EMAIL_ORGANIZATION_BY_FORM)
  const [sendMailFn, sendMail] = useMutation(SEND_MAIL_RISK_FORM)

  useEffect(() => {
    if (open) {
      setVisible(open)
      getEmailOrganizationByFormFn({
        variables: { riskFormCode: riskFormData.riskFormCode },
      })
    }
  }, [open, riskFormData])

  useEffect(() => {
    if (sendMail?.data) {
      Alert({
        type: 'success',
        title: t('ส่งแบบประเมินสำเร็จ'),
        onOk() {
          setVisible(false)
          onSuccess(false)
        },
      })
    }
    if (sendMail.error) {
      const err = sendMail.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {
          onSuccess(false)
        },
      })
    }
  }, [sendMail.data])

  useEffect(async () => {
    if (getEmailOrganizationByForm?.data) {
      const _email = getEmailOrganizationByForm?.data?.getEmailOrganizationByForm
      const email_ = _email.map((item) => JSON.stringify({ personnelCode: item.personnelCode, email: item.email }))

      const admin = [JSON.stringify({ personnelCode: pCode, email: personnelEmail })]

      const setDear = _email.map((dt) => `${dt?.firstNameTH} ${dt?.lastNameTH} : ${dt?.organizationNameTH}`).join(', ')

      form.setFieldsValue({
        dear: _email.length ? setDear : '',
        subject: t('แจ้งเตือนให้จัดทำแบบประเมินความเสี่ยง'),
        sendMailTo: riskFormData.riskFormType === 1 ? admin : email_,
        sendMailCopy: admin,
        sendMailSecret: [],
      })
    }
    if (getEmailOrganizationByForm.error) {
      const err = getEmailOrganizationByForm.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {
          onSuccess(false)
        },
      })
    }
  }, [getEmailOrganizationByForm.data])

  async function onSubmit(val_) {
    const inputDt = {
      dear: val_.dear,
      subject: val_.subject,
      sendMailTo: val_.sendMailTo ? convertEmailJson(val_.sendMailTo) : '',
      sendMailCopy: val_.sendMailCopy ? convertEmailJson(val_.sendMailCopy) : '',
      sendMailSecret: val_.sendMailSecret ? convertEmailJson(val_.sendMailSecret) : '',
      personnelCode: pCode + '',
      sendBy: pCode + '',
      menuCode: 'RA01',
      userId: userId,
      riskFormCode: riskFormData.riskFormCode,
      Year: riskFormData.Year,
      dateStart: riskFormData.dateStart,
      dateEnd: riskFormData.dateEnd,
      riskFormName: riskFormData.riskFormName,
      organizationCode: riskFormData.organizationCode,
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการส่ง ?'),
      onOk: async () => {
        await sendMailFn({
          fetchPolicy: 'no-cache',
          variables: { input: encryptInput({ ...inputDt }) },
        })
      },
      onCancel: () => {},
    })
  }

  function onClose(e) {
    setVisible(visible ? false : true)
    close(e)
  }

  return (
    <>
      {(sendMail.loading || getEmailOrganizationByForm.loading) && <SpinnersNew />}
      <ModalNew
        visible={visible}
        onSubmit={() => {
          form
            .validateFields()
            .then((v) => onSubmit(v))
            .catch((err) => console.log(err))
        }}
        onClose={(e) => onClose(e)}
        btnName={t('ส่ง')}
        testTitle={t('ข้อมูลการแจ้งเตือนอีเมล')}
        type="medium"
      >
        <Row justify="center">
          <Col {...formItemLayout.Col}>
            <Form
              name={formName}
              layout="horizontal"
              form={form}
              labelAlign="left"
              labelCol={{ ...formItemLayout.labelCol }}
              wrapperCol={{ ...formItemLayout.wrapperCol }}
            >
              <Item name={'dear'} label={t('เรียน')} rules={[{ required: true, message: t('กรุณาระบุ') }]}>
                <InputNew placeholder={t('กรุณาระบุ')} />
              </Item>
              <Item name={'subject'} label={t('เรื่อง')} rules={[{ required: true, message: t('กรุณาระบุเรื่อง') }]}>
                <InputNew placeholder={t('กรุณาระบุเรื่อง')} />
              </Item>
              <Item
                name={'sendMailTo'}
                label={t('ถึง')}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระบุอีเมล'),
                  },
                ]}
              >
                <SelectTagEmailPersonnels
                  // data={sendMailToOptions}
                  placeholder={t('ระบุถึง')}
                  formname={formName}
                  handleChange={(e) => {
                    form.setFieldsValue({ sendMailTo: e })
                  }}
                />
              </Item>
              <Item name={'sendMailCopy'} label={t('สำเนาถึง')}>
                <SelectTagEmailPersonnels
                  placeholder={t('ระบุถึง')}
                  formname={formName}
                  handleChange={(e) => {
                    form.setFieldsValue({ sendMailCopy: e })
                  }}
                />
              </Item>
              <Item name={'sendMailSecret'} label={t('สำเนาลับ')}>
                <SelectTagEmailPersonnels
                  placeholder={t('ระบุถึง')}
                  formname={formName}
                  handleChange={(e) => {
                    form.setFieldsValue({ sendMailSecret: e })
                  }}
                />
              </Item>
              <Item hidden={true} name={'data'}>
                <Input />
              </Item>
            </Form>
          </Col>
        </Row>
      </ModalNew>
    </>
  )
}

export default SendMailModal
