/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_PERSONNELS } from './graphql/Query'
import { Select } from '../../select/Select'
import { decryptData, encryptInput } from '../../../utilitys/crypto'
import { InputTagNew } from '../../input/Input'

const SelectTagEmailPersonnels = (props) => {
  const { filters, placeholder, formname, handleChange, getOption, disableFreeKey, ...otherProp } = props

  //   const { isActive, approveStatusCode } = filters || {}

  const [isData, isSetData] = useState([])

  const [cellGet, { data: dataGet, loading: loadingGet }] = useLazyQuery(GET_PERSONNELS)

  useEffect(() => {
    cellGet({
      variables: { input: encryptInput({ ...filters }) },
    })
  }, [])

  useEffect(() => {
    if (dataGet) {
      const dataMap = _.map(dataGet?.getPersonnels, (item) => {
        const email = decryptData(item.email)
        return {
          value: JSON.stringify({ personnelCode: item.personnelCode, email: email }),
          label: email,
        }
      })
      isSetData(dataMap)
      if (typeof getOption === 'function') getOption(dataMap)
    }
  }, [dataGet])

  return (
    <>
      <InputTagNew
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        formname={formname}
        loading={loadingGet}
        handleChange={(value) => {
          handleChange(value)
        }}
        disableFreeKey={disableFreeKey ? true : false}
      />
    </>
  )
}

export default SelectTagEmailPersonnels
