import React from 'react'
import { Trans } from 'react-i18next'

export const Columns = () => {
  return [
    {
      title: <Trans>{'ลำดับ'}</Trans>,
      dataIndex: 'no',
      width: '100px',
      align: 'center',
      fixed: 'left',
      sorter: true,
    },
    {
      title: <Trans>{'เลขที่งาน'}</Trans>,
      dataIndex: 'jobNumber',
      width: '100px',
      align: 'left',
      fixed: 'left',
      sorter: true,
    },

    {
      title: <Trans>{'ชื่องาน'}</Trans>,
      dataIndex: 'jobSubject',
      width: '400px',
      align: 'left',
      fixed: 'left',
      sorter: true,
    },
    {
      title: <Trans>{'ประเภทงาน'}</Trans>,
      dataIndex: 'jobTypeName',
      align: 'left',
      width: '150px',
      sorter: true,
    },
    {
      title: <Trans>{'หน่วยงานที่รับผิดชอบ'}</Trans>,
      dataIndex: 'organizationName',
      align: 'left',
      width: '150px',
      sorter: true,
    },

    {
      title: <Trans>{'หน่วยงานที่ร้องขอ'}</Trans>,
      dataIndex: 'requestOrg',
      align: 'left',
      width: '150px',
      sorter: true,
    },
    {
      title: <Trans>{'ผู้รับผิดชอบ'}</Trans>,
      dataIndex: 'fullName',
      align: 'left',
      width: '170px',
      sorter: true,
    },
    {
      title: <Trans>{'ระยะเวลาดำเนินการ'}</Trans>,
      align: 'center',
      children: [
        {
          title: <Trans>{'วันที่เริ่มต้น'}</Trans>,
          dataIndex: 'dateStart',
          align: 'center',
          width: '150px',
          sorter: true,
        },
        {
          title: <Trans>{'วันที่สิ้นสุด'}</Trans>,
          dataIndex: 'dateEnd',
          align: 'center',
          width: '150px',
          sorter: true,
        },
      ],
    },
    {
      title: <Trans>{'สถานะดำเนินการ'}</Trans>,
      dataIndex: 'jobStatusName',
      align: 'left',
      width: '250px',
      sorter: true,
    },
    {
      title: <Trans>{'สถานะการอนุมัติ'}</Trans>,
      dataIndex: 'approveStatusName',
      align: 'left',
      width: '250px',
      sorter: true,
    },
  ]
}
