import { gql } from '@apollo/client'

export const UPDATE_OR_CREATE_PREPARE_AUDIT_REPORT = gql`
  mutation updateOrCreatePrepareAuditReport($input: PrepareAuditReportInput!) {
    updateOrCreatePrepareAuditReport(input: $input) {
      status
      finalReportId
      documentNo
    }
  }
`

export const UPDATE_OR_CREATE_PREPARE_AUDIT_REPORT_DETAIL = gql`
  mutation updateOrCreatePrepareAuditReportDetail($input: PrepareAuditReportDetailInput!) {
    updateOrCreatePrepareAuditReportDetail(input: $input) {
      status
      finalReportId
      finalReportDetailId
      documentNo
    }
  }
`
export const DELETE_PREPARE_AUDIT_REPORT = gql`
  mutation deletePrepareAuditReport($input: PrepareAuditReportInput!) {
    deletePrepareAuditReport(input: $input) {
      status
    }
  }
`

export const SEND_MAIL_PREPARE_AUDIT_REPORT = gql`
  mutation sendMailPrepareAuditReport($input: PrepareAuditReportSendMailInput!) {
    sendMailPrepareAuditReport(input: $input) {
      status
    }
  }
`

export const GENERATE_DOCUMENTNO_PREPARE_AUDIT = gql`
  mutation generateDocumentNoPrepareAudit($input: PrepareAuditGenerateDocumentNoInput!) {
    generateDocumentNoPrepareAudit(input: $input) {
      documentNo
      docTypeCode
    }
  }
`
