import React, { useState, useEffect } from 'react'
// import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import { useMutation, useLazyQuery } from '@apollo/client'
import { ModalNew } from '../../../../../components/modal/Modal'
import { InputNew } from '../../../../../components/input/Input'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { LabelNew } from '../../../../../components/label/Label'
import { Datepicker } from '../../../../../components/datepicker/Datepicker.jsx'
import { Alert } from '../../../../../components/alert/Alert'
import { UploadNew } from '../../../../../components/upload/Upload.jsx'
import SelectPersonnels from '../../../../../components/inputfromapi/selectpersonnels/SelectPersonnels.jsx'
import { encryptInput } from '../../../../../utilitys/crypto'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'
import { SAVE_TIMESHEET_REQUEST } from '../../graphql/Mutation'
import { GET_TIMESHEET_REQUEST_BY_ID } from '../../graphql/Query'
import { displayError } from '../../../../../utilitys/helper'

const TimeSheetRequestModal = (props) => {
  const { dataParams } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [idFile, setIdFile] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])
  const [isPCode, setPCode] = useState(null)
  const [user] = useDecodeUser()
  const [idFileDefault, setIdFileDefault] = useState([])

  /* ---------------- API ----------- */
  const [createFn, { loading: loadinCreate, error: errorCreate, data: responseSave }] =
    useMutation(SAVE_TIMESHEET_REQUEST)

  const [
    getTimeSheetRequestFn,
    { loading: loadingTimeSheetRequest, error: errorTimeSheetRequest, data: dataTimeSheetRequest },
  ] = useLazyQuery(GET_TIMESHEET_REQUEST_BY_ID)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    setVisible(props.open)

    if (dataParams?.type_action === 'edit') {
      getTimeSheetRequestFn({
        variables: {
          requestId: encryptInput(dataParams.refCode),
        },
      })
    }
  }, [dataParams, props.open])

  useEffect(() => {
    user && setPCode(user?.pCode)
  }, [user])

  useEffect(() => {
    if (responseSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          props.refetch()
        },
      })
    }
  }, [responseSave])

  useEffect(() => {
    if (errorCreate) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorCreate)),
        onOk() {},
      })
    }
  }, [errorCreate])

  useEffect(() => {
    if (dataTimeSheetRequest) {
      const data = dataTimeSheetRequest.getTimeSheetRequestById
      form.setFieldsValue({
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        fileDetail: data.fileDetail,
        approver: data.approver,
      })

      if (data.files) {
        setIdFile(() => {
          return data.files.split(',').map((item) => {
            return parseInt(item)
          })
        })

        setIdFileDefault(() => {
          return data.files.split(',').map((item) => {
            return parseInt(item)
          })
        })
      }
    }

    if (errorTimeSheetRequest) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(errorTimeSheetRequest)),
        onOk() {
          props.close(false)
        },
      })
    }
  }, [dataTimeSheetRequest])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    showConfirm(input)
  }

  const showConfirm = (input) => {
    let dataInput = {
      ...input,
      fileDetail: input.fileDetail || null,
      requestId: dataParams?.refCode || null,
      files: idFile.join() || null,
      requestPersonnel: isPCode,
      action: dataParams?.type_action === 'add' ? 'create' : 'update',
      organizationCode: user?.orgCode,
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        createFn({
          variables: {
            input: encryptInput(dataInput),
          },
        })
        form.resetFields()
        setIdFile([])
        props.close(false)
      },
      onCancel() {},
    })
  }

  function onClose(e) {
    setIdFile([])
    form.resetFields()
    props.close(e)
  }
  return (
    <>
      {(loadinCreate || loadingTimeSheetRequest) && <SpinnersNew />}
      <ModalNew
        visible={visible}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        type="medium"
        testTitle={
          dataParams?.type_action === 'edit' ? t('แก้ไขคำขออนุมัติเวลาปฏิบัติงาน') : t('เพิ่มคำขออนุมัติเวลาปฏิบัติงาน')
        }
        typeAction={dataParams?.type_action}
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="timeSheetRequestForm"
        >
          <Form.Item
            name="dateStart"
            label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
            rules={[
              {
                required: true,
                message: t('กรุณาระบุ'),
              },
            ]}
          >
            <Datepicker
              setValueDateFn={async (data) => {
                await setIsMinDate(data), await form.setFieldsValue({ dateStart: await data })
              }}
              onClear={() => {
                form.setFieldsValue({ dateStart: null })
              }}
              placeholder={t('DD/MM/YYYY')}
              isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
            />
          </Form.Item>
          <Form.Item
            name="dateEnd"
            label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
            rules={[
              {
                required: true,
                message: t('กรุณาระบุ'),
              },
            ]}
          >
            <Datepicker
              setValueDateFn={async (data) => {
                await setIsMaxDate(data), await form.setFieldsValue({ dateEnd: await data })
              }}
              onClear={() => {
                form.setFieldsValue({ dateEnd: null })
              }}
              placeholder={t('DD/MM/YYYY')}
              isMinDate={isMinDate ? new Date(isMinDate) : null}
            />
          </Form.Item>
          <Form.Item
            name="approver"
            label={<LabelNew type="tab-header-inactive">{t('ผู้อนุมัติ')}</LabelNew>}
            rules={[
              {
                required: true,
                message: t('กรุณาเลือกผู้อนุมัติ'),
              },
            ]}
          >
            <SelectPersonnels
              placeholder={t('ผู้อนุมัติ')}
              formname={'timeSheetRequestForm'}
              filters={{ levelCode: 11, organizationChecked: 2 }}
              handleChange={(e) => form.setFieldsValue({ approver: e?.value })}
            />
          </Form.Item>
          <Form.Item name="fileDetail" label={<LabelNew type="tab-header-inactive">{t('ลิงค์ภายนอก')}</LabelNew>}>
            <InputNew />
          </Form.Item>
          <Form.Item label={<LabelNew type="tab-header-inactive">{t('เอกสารเพิ่มเติม')}</LabelNew>}>
            <UploadNew
              type="mutiple"
              setIdFile={setIdFile}
              idFile={idFile}
              typeFile="file"
              actionType={dataParams?.type_action}
              idFileDelete={idFileDelete}
              setIdFileDelete={setIdFileDelete}
              defaults={idFileDefault}
            />
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
export default TimeSheetRequestModal
