import { Form } from 'antd'
import { useTranslation } from 'react-i18next'

import { InputNew } from '../../../../components/input/Input'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { regexEngNoSpace } from '../../../../utilitys/helper'

export const Columns = ({ editable = false, fncDelete = () => {}, menu }) => {
  const { t } = useTranslation()

  return [
    {
      title: t('รหัส'),
      dataIndex: 'factorActRiskCode',
      width: '17%',
      render: (item, record, index) => (
        <Form.Item
          name={['riskFactorActionRisk', index, 'factorActRiskCode']}
          initialValue={item}
          wrapperCol={{
            md: 24,
          }}
          rules={[
            { required: true, message: t('กรุณาระบุ') },
            { pattern: regexEngNoSpace, message: t(`กรุณากรอกด้วยภาษาอังกฤษพิมพ์ใหญ่,ตัวเลข A-Z, 0-9 ไม่เกิน 10 ต้ว`) },
          ]}
        >
          <InputNew placeholder={t('กรอกรหัส')} disabled={!editable} maxLength={10} />
        </Form.Item>
      ),
    },
    {
      title: t('ชื่อความเสี่ยง'),
      dataIndex: 'factorActRiskName',
      width: '20%',
      render: (item, record, index) => (
        <Form.Item
          name={['riskFactorActionRisk', index, 'factorActRiskName']}
          initialValue={item}
          wrapperCol={{
            md: 24,
          }}
          rules={[{ required: true, message: t('กรุณาระบุ') }]}
        >
          <InputNew placeholder={t('กรอกชื่อความเสี่ยง')} disabled={!editable} maxLength={255} />
        </Form.Item>
      ),
    },
    {
      title: t('คำอธิบาย'),
      dataIndex: 'remark',
      width: '35%',
      render: (item, record, index) => (
        <Form.Item
          name={['riskFactorActionRisk', index, 'remark']}
          initialValue={item}
          wrapperCol={{
            md: 24,
          }}
        >
          <InputNew placeholder={t('กรอกคำอธิบาย')} disabled={!editable} maxLength={200} />
        </Form.Item>
      ),
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '8%',
      render: (item, record, index) => {
        const icons = []

        if (editable) {
          icons.push({
            type: 'delete',
            onClick: () => {
              fncDelete(index)
            },
          })
        }

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} icons={icons} />
          </div>
        )
      },
    },
  ]
}
