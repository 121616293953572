import _ from 'lodash'
import moment from 'moment'
import { decryptData } from './crypto'

export const getSegmentUrl = (number) => {
  let segment = window.location.pathname.split('/')
  return segment[number]
}

// input ได้แค่ abc , 0-9 และ spacebar
export const regexEngThai = /^[ก-๙a-zA-Z0-9 ]*$/

// input ได้แค่ abc , 0-9 และ spacebar
export const regexEng = /^[a-zA-Z0-9 ]*$/

// input ได้แค่ กขค , ๑๒๓ , 0-9 และ spacebar
export const regexThai = /^[ก-๙0-9. ]*$/

// input ได้แค่  0-9 และ .
export const regexNumber = /^[0-9]+$/

// input ได้แค่  0-9 และ . ,
export const regexNumberComma = /^[0-9.,]+$/

// input ได้แค่ abc , 0-9 for Code
export const regexEngNoSpace = /^[A-Z0-9]{0,10}$/

// input ได้แค่ abc for UserId
export const regexEngNoSpaceUser = /^[a-zA-Z0-9._]*$/

// input ต้องใส่อย่างละ 1 เช่น A , a , 0-9 และตัวอักษรพิเศษ !@#$
export const regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/

// input รูปแบบ Email
export const regexEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// generatePassword
export const generatePassword = () => {
  const alpha = 'abcdefghijklmnopqrstuvwxyz'
  const calpha = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  const num = '1234567890'
  const specials = ',.!@#$%^&*'
  const options = [alpha, alpha, calpha, calpha, calpha, num, num, num, specials, specials]
  let opt, choose
  let pass = ''
  for (let i = 0; i < 10; i++) {
    opt = Math.floor(Math.random() * options.length)
    choose = Math.floor(Math.random() * options[opt].length)
    pass = pass + options[opt][choose]
    options.splice(opt, 1)
  }
  return pass
}

export const displayText = (th, en) => {
  const isLang = localStorage.getItem('lang')
  return isLang === 'en' ? (en ? en : th) : th
}

// สำหรับ check link ว่ามี http หรือไม่
export const validateURL = (link) => {
  if (link) {
    if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) {
      return link
    } else {
      return 'http://' + link
    }
  } else {
    return '-'
  }
}

export const mergeArrayObjects = (arr1, arr2) => {
  const arr_ = [...arr1, ...arr2]
  return _.uniqBy(arr_, (i) => i.organizationCode)
}

//check action role if receive props roles
export const checkRole = (role) => {
  if (Object.keys(role).length === 0) return true
  const { roles = [] } = decryptData(localStorage.getItem('user'))
  return roles.find((item) => item[role.type] === true && item.menuCode === role.menu)
}

//แปลง ใส่ comma ไปในตัวเลข
export const moneyFormat = (price) => {
  const formatter = new Intl.NumberFormat('th-TH', {
    style: 'currency',
    currency: 'THB'
  })

  return formatter.format(price)
}

//convert jsonstringtify email for post api
export const convertEmailJson = (input_) => {
  const dataRes = []
  if (input_) {
    if (input_.length > 0) {
      input_.map((dt) => {
        try {
          dataRes.push(JSON.parse(dt))
        } catch (e) {
          dataRes.push(dt)
        }
      })
    }
  }
  return dataRes
}

export const displayError = (error, defaultMsg = '') => {
  return typeof error === 'object' ? (error.message && error.graphQLErrors.length > 0 ? error.message : '') : defaultMsg
}

export const displayLogEditChangeType = (changeType) => {
  const changeTypeArr = []
  changeTypeArr[1] = { en: 'Add', th: 'เพิ่มข้อมูล' }
  changeTypeArr[2] = { en: 'Edit', th: 'แก้ไขข้อมูล' }
  changeTypeArr[3] = { en: 'Delete', th: 'ลบข้อมูล' }
  const lang = localStorage.getItem('lang')

  return changeTypeArr[changeType] ? changeTypeArr[changeType][lang] || '-' : '-'
}

export const displayLogEditMenuNameLanguage = () => {
  const lang = localStorage.getItem('lang')
  const menuNameLang = { name: '' }
  lang === 'th' ? (menuNameLang.name = 'menuNameTH') : (menuNameLang.name = 'menuNameEN')
  return menuNameLang.name || '-'
}

// เช็คความต่างระหว่าง 2 อ็อบเจค
export const compareObject = (object1, object2) => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      const obj1 = object1[key]
      const obj2 = object2[key]
      obj1?.createdAt && delete obj1?.createdAt
      obj1?.createdBy && delete obj1?.createdBy
      obj1?.updatedAt && delete obj1?.updatedAt
      obj1?.updatedBy && delete obj1?.updatedBy
      obj1?.tableName && delete obj1?.tableName
      obj1?.MyIndex && delete obj1?.MyIndex

      obj2?.createdAt && delete obj2?.createdAt
      obj2?.createdBy && delete obj2?.createdBy
      obj2?.updatedAt && delete obj2?.updatedAt
      obj2?.updatedBy && delete obj2?.updatedBy
      obj2?.tableName && delete obj2?.tableName
      obj2?.MyIndex && delete obj2?.MyIndex

      return { before: obj1, after: obj2, different: object2[key] }
    }
  }
  return true
}

export const MaskNameText = (text = '', min = 1) => {
  let minLength = 1
  let maxLength = text.length
  let replaceText = ''

  for (let i = 0; i < maxLength; i++) {
    if (text[i] === '.') {
      replaceText += '.'
    } else {
      replaceText += 'x'
    }
  }

  if (min > 1) {
    if (maxLength > 3) {
      minLength = 4
    } else {
      replaceText += 'xxx'
    }
  }

  return text.replace(text.substring(minLength, maxLength), replaceText)
}

export const MaskFullNameText = (fullname = '') => {
  const [first, last] = fullname.split(' ')
  return MaskNameText(first, 2) + ' ' + MaskNameText(last, 2)
}

export const MaskEmail = (email = '') => {
  const [name, domain] = email.split('@')
  const { length: len } = name
  const maskedName = name[0] + 'xxxxx' + name[len - 1]
  const maskedEmail = maskedName + '@' + MaskNameText(domain, 1)
  return maskedEmail
}

export const MaskPhone = (phoneNumber) => {
  let maxLength = 0
  let replaceText = ''
  if (phoneNumber.length > 5) {
    maxLength = phoneNumber.length - 4
    replaceText = 'XXX-XXX-'
  } else if (phoneNumber.length > 3) {
    maxLength = phoneNumber.length - 2
    replaceText = 'XXX-'
  } else {
    maxLength = phoneNumber.length - 1
    replaceText = 'XX'
  }
  return phoneNumber.replace(phoneNumber.substring(0, maxLength), replaceText)
}

export const numberFormat = (num, decimal = 0) => {
  if (num) {
    return num.toLocaleString(undefined, { minimumFractionDigits: decimal })
  } else {
    return ''
  }
}

// แปลงวันที่ในรูปแบบต่างๆให้เป็น YYYY-MM-DD
export const dateFormat = (date) => {
  return moment(date).format('YYYY-MM-DD')
}
