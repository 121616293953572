import { gql } from '@apollo/client'

export const GET_AUDIT_RESOLUTIONS = gql`
  query getAuditResolution($input: AuditResolutionFilter) {
    getAuditResolution(input: $input) {
      result {
        no
        resId
        resDate
        resTimes
        resYear
        locationTH
        locationEN
      }
      count
    }
  }
`

export const GET_AUDIT_RESOLUTION_BY_ID = gql`
  query getAuditResolutionById($resId: String!) {
    getAuditResolutionById(resId: $resId) {
      resId
      resDate
      resTimes
      resYear
      locationTH
      locationEN
    }
  }
`

export const GET_ALL_AUDIT_RESOLUTION_AC_BY_RES_ID = gql`
  query getAllAuditResolutionACByResId($input: AuditResolutionACByResIdInput!) {
    getAllAuditResolutionACByResId(input: $input) {
      result {
        agendaId
        agendaTime
        agendaTimeSub
        agendaSubNameTH
        agendaSubNameEN
        jobType
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDIT_RESOLUTION_AC_BY_ID = gql`
  query getAuditResolutionACById($agendaId: String!) {
    getAuditResolutionACById(agendaId: $agendaId) {
      agendaId
      copyType
      jobType
      projectCode
      jobNameTH
      jobNameEN
      organization {
        organizationCode
        organizationNameTH
        organizationNameEN
      }
      agendaTime
      agendaNameTH
      agendaNameEN
      agendaTimeSub
      agendaSubNameTH
      agendaSubNameEN
      considerCode
      personnelCreateCode
      personnelPresentCode
      meetingDetailTH
      meetingDetailEN
      resolutionACTH
      resolutionACEN
      meetingTimes
      auditor
      isActive
      files
      fileDetail
      auditResolutionPersonnels {
        personnelCode
        personnelType
      }
      auditResolutionOrganizations {
        organizationCode
      }
      systemUsers {
        personnels {
          firstNameTH
          lastNameTH
          firstNameEN
          lastNameEN
        }
      }
    }
  }
`

export const GET_PERSONNEL_BY_PERSONNEL_CODE = gql`
  query getPersonnelsByPersonnelCode($personnelCode: String!) {
    getPersonnelsByPersonnelCode(personnelCode: $personnelCode) {
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
    }
  }
`

export const AUDIT_RESOLUTION_EXPORT_FILE = gql`
  query auditResolutionExportFile($input: AuditResolutionExportFileInput!) {
    auditResolutionExportFile(input: $input) {
      message
      fileUrl
    }
  }
`
