import { gql } from '@apollo/client'

export const GET_RISKLEVEL_PAGINATION = gql`
  query getMasterRiskLevelPagination($input: RiskLevelPaginationInput!) {
    getMasterRiskLevelPagination(input: $input) {
      result {
        riskLevelCode
        riskLevelNameTH
        riskLevelNameEN
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_RISKLEVEL_BY_CODE = gql`
  query getMasterRiskLevelByCode($riskLevelCode: String!) {
    getMasterRiskLevelByCode(riskLevelCode: $riskLevelCode) {
      riskLevelCode
      riskLevelNameTH
      riskLevelNameEN
      riskLevelYear
      isActive
    }
  }
`
