import { gql } from '@apollo/client'

export const GET_CONSULTATION_PAGINATION = gql`
  query getConsultationResultPagination($input: ConsultationResultPaginationInput!) {
    getConsultationResultPagination(input: $input) {
      result {
        no
        consultId
        jobTypeNameTH
        jobTypeNameEN
        jobNumber
        jobCode
        requestPersonnel
        requestOrganization
        responsibleOrganization
        jobSubjectTH
        resultDate
        fullName
        jobStatusCode
        consultStatus
      }
      count
      page
      limit
    }
  }
`

export const GET_CONSULTATION_RESULT = gql`
  query GetConsultationResult($input: GetConsultationResultInput!) {
    getConsultationResult(input: $input) {
      consultId
      jobCode
      fact
      result
      assessment
      files
      fileDetail
      jobTypeNameTH
      jobTypeNameEN
      jobNumber
      requestOrganization
      telephone
      requestOrganizationTH
      requestOrganizationEN
      responsibleOrganizationTH
      responsibleOrganizationEN
      Subject
      jobSubjectTH
      jobSubjectEN
      resultDate
      createdAt
      organizationCode
      requestPersonnel
      fullName
      consultStatus
    }
  }
`
export const CONSULT_RESULT_EXPORT = gql`
  query consultResultExport($input: ConsultExportInput!) {
    consultResultExport(input: $input) {
      message
      fileUrl
    }
  }
`
