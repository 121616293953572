import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Form, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_AUDIT_RCM_PERSONNEL } from '../../graphql/Query'
import { UPDATE_AUDIT_RCMS_PERSONNEL } from '../../graphql/Mutation'
import { ButtonNew } from '../../../../../components/button/Button'
import { ModalNew } from '../../../../../components/modal/Modal'
import { Select } from '../../../../../components/select/Select'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { InputNew } from '../../../../../components/input/Input'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { Alert } from '../../../../../components/alert/Alert'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText, displayError } from '../../../../../utilitys/helper'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'

const PersonnelRCMFormModal = (props) => {
  const formname = 'PersonnelRCMFormModal'
  const labelCol = { sm: 14, md: 6, lg: 6, xl: 6 }
  const wrapperCol = { sm: 16, md: 18, lg: 18, xl: 18, align: 'left' }

  const { open, close, menu, actionModal, idActionPersonnelRCMForm, getData } = props

  const [form] = Form.useForm()
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)
  const [isDisable, setIsDisable] = useState(false)
  const [riskModelName, setRiskModelName] = useState([])
  const [personnelList, setPersonnelList] = useState([])

  const [getAuditRCMPersonnelFn, getAuditRCMPersonnel] = useLazyQuery(GET_AUDIT_RCM_PERSONNEL)
  const [updateAuditRCMPersonnelFn, updateAuditRCMPersonnel] = useMutation(UPDATE_AUDIT_RCMS_PERSONNEL)

  useEffect(() => {
    setVisible(open)
  }, [open])

  useEffect(() => {
    if (actionModal) {
      switch (actionModal) {
        case 'edit':
          setIsDisable(false)
          break
        case 'view':
          setIsDisable(true)
          break
        default:
          setIsDisable(false)
          break
      }
    }
  }, [actionModal])

  useEffect(() => {
    if (idActionPersonnelRCMForm) {
      if (idActionPersonnelRCMForm.rateFacProName) {
        form.setFieldsValue({ rateFacProName: idActionPersonnelRCMForm.rateFacProName })
      }

      getAuditRCMPersonnelFn({
        variables: {
          input: encryptInput({
            rcmResultId: idActionPersonnelRCMForm?.rcmResultId,
            rcmId: idActionPersonnelRCMForm?.rcmId,
          }),
        },
      })
    }
  }, [idActionPersonnelRCMForm])

  useEffect(() => {
    if (getAuditRCMPersonnel?.data) {
      const results = getAuditRCMPersonnel?.data?.getPersonnelRCM
      const setForm = {}

      if (results?.auditRCMResult) {
        form.setFieldsValue({ riskDetail: results?.auditRCMResult?.riskDetail })
      }

      if (results?.resultRiskModel.length === 1) {
        setRiskModelName([
          {
            value: results.resultRiskModel[0].riskModelCode,
            label: displayText(results.resultRiskModel[0].riskModelTH, results.resultRiskModel[0].riskModelEN),
          },
        ])
        setForm.riskProcess = results.resultRiskModel[0].riskModelCode
      }

      if (results?.resultPersonnels) {
        setPersonnelList(
          _.map(results?.resultPersonnels, (d) => {
            return {
              label: displayText(
                `${d.organizationCode}: ${d.organizationNameTH}`,
                `${d.organizationCode}: ${d.organizationNameEN}`,
              ),
              value: d.personnelCode,
            }
          }),
        )
      }

      if (results?.resultAuditPersonnels) {
        const auditPersonnel = _.map(results?.resultAuditPersonnels, (d) => {
          return {
            label: displayText(d.personnelNameTH, d.personnelNameEN),
            value: d.personnelCode,
          }
        })
        setForm.personnelCode = auditPersonnel
      }

      form.setFieldsValue(setForm)
    }

    if (getAuditRCMPersonnel?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: displayError(getAuditRCMPersonnel?.error),
        onOk() {},
      })
    }
  }, [getAuditRCMPersonnel.data])

  useEffect(() => {
    if (updateAuditRCMPersonnel?.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk: () => {
          getData(updateAuditRCMPersonnel?.data)
          form.resetFields()
          onClose()
        },
        onCancel: () => {},
      })
    }

    if (updateAuditRCMPersonnel?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: displayError(updateAuditRCMPersonnel?.error),
        onOk() {},
      })
    }
  }, [updateAuditRCMPersonnel?.data])

  const onFinish = (data) => {
    const setPersonnelCode = _.map(data?.personnelCode, (p) => (_.isObject(p) ? p?.value : p))

    const objData = encryptInput({
      rcmResultId: idActionPersonnelRCMForm?.rcmResultId,
      rcmId: idActionPersonnelRCMForm?.rcmId,
      personnelCode: setPersonnelCode,
      riskDetail: data?.riskDetail,
    })

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk: () => {
        updateAuditRCMPersonnelFn({ fetchPolicy: 'no-cache', variables: { input: objData } })
      },
      onCancel: () => {},
    })
  }

  const onClose = () => {
    form.resetFields()
    setVisible(false)
    close(false)
  }

  const textTitle = isDisable ? 'ดูประเมินความเสี่ยง' : 'แก้ไขประเมินความเสี่ยง'

  return (
    <ModalNew
      onSubmit={() => {}}
      onClose={() => onClose()}
      visible={visible}
      testTitle={t(textTitle)}
      type="medium"
      width={720}
      footer={null}
    >
      {(getAuditRCMPersonnel?.loading || updateAuditRCMPersonnel?.loading) && <SpinnersNew />}
      <Col sm={24} md={20} lg={20} style={{ margin: 'auto' }}>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{ ...labelCol }}
          wrapperCol={{ ...wrapperCol }}
          layout="horizontal"
          name={formname}
        >
          <Form.Item name="riskProcess" label={<Label type="tab-header-inactive">{t('กระบวนการหลัก')}</Label>}>
            <Select
              disabled={true}
              scrollableId={formname}
              placeholder={t('เลือกกระบวนการหลัก')}
              data={riskModelName}
            />
          </Form.Item>
          <Form.Item name="rateFacProName" label={<Label type="tab-header-inactive">{t('ปัจจัย/ความเสี่ยง')}</Label>}>
            <InputNew disabled={true} />
          </Form.Item>
          <Form.Item name="riskDetail" label={<Label type="tab-header-inactive">{t('รายละเอียดความเสี่ยง')}</Label>}>
            <TextAreaNew
              placeholder={t('ระบุรายละเอียดความเสี่ยง')}
              autoSize={{ minRows: 4, maxRows: 4 }}
              disabled={isDisable}
            />
          </Form.Item>
          <Form.Item
            name="personnelCode"
            label={<Label type="tab-header-inactive">{t('ผู้รับผิดชอบ')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกผู้รับผิดชอบ') }]}
          >
            <Select
              mode="multiple"
              placeholder={t('เลือกผู้รับผิดชอบ')}
              // disabled={isDisable}
              disabled={true}
              onChange={(e) => form.setFieldsValue({ personnelCode: e })}
              scrollableId={formname}
              data={personnelList}
            />
          </Form.Item>
          <Col align="center">
            <Space size={[8, 8]} wrap>
              {isDisable ? (
                <ButtonNew type="back" onClick={() => onClose()}>
                  {t('ยกเลิก')}
                </ButtonNew>
              ) : (
                <>
                  <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                    {t('บันทึก')}
                  </ButtonNew>
                  <ButtonNew type="back" onClick={() => onClose()}>
                    {t('ยกเลิก')}
                  </ButtonNew>
                </>
              )}
            </Space>
          </Col>
        </Form>
      </Col>
    </ModalNew>
  )
}

PersonnelRCMFormModal.propTypes = {
  menu: PropTypes.string.isRequired,
  idActionPersonnelRCMForm: PropTypes.object,
  actionModal: PropTypes.string,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  getData: PropTypes.func.isRequired,
}

export default PersonnelRCMFormModal
