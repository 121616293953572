import styled from 'styled-components'

import { fontSize, colors } from '../../configs/styles.config'

export const RadioStyle = styled.div`
  colors: ${(props) => (props.color ? props.color : colors.black)};
  font-size: ${(props) => (props.fontSize ? props.color : fontSize.textDefault)};

  .ant-radio-inner {
    width: 14px;
    height: 14px;
  }

  .ant-radio-checked .ant-radio-inner::after {
    transform: scale(0.8);
    background-color: ${(props) => (props.color ? props.color : colors.primary)};
  }

  .ant-radio-disabled + span {
    color: #999;
    line-height: 2;
  }
`
