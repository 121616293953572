import { useContext, useState, useEffect, useRef } from 'react'
import { Menu, Dropdown, Avatar, Layout, Row, Col } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { NavbarStyle, MenuStyle, MenuNotiStyle, NavLogo } from './NavbarStyles'
import ActiveImg from '../../assets/images/Active.png'
import { AuthContext } from '../../context/authContext'
import { useLazyQuery, useMutation } from '@apollo/client'
import { IconNew } from '../../components/icon/Icon'
import { Alert } from '../../components/alert/Alert'
import { encryptInput, decryptData } from '../../utilitys/crypto'
import { dateTimeDisplay } from '../../utilitys/dateHelper'
import { NOTI_GET } from './graphql/Query'
import { NOTI_READ, LOGOUT } from './graphql/Mutation'
import { SubscriptionData } from './SubscriptionData'
import { SubscriptionLoginData } from './SubscriptionLoginData'
const { Header } = Layout

const Navbar = (props) => {
  const dropDownNotiRef = useRef('')
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [user, setUser] = useContext(AuthContext)
  const lang = localStorage.getItem('lang')

  //--- GET NOTI ---
  const [stateGetNotiUnRead, setStateGetNotiUnRead] = useState(0)
  const [stateGetNotiTotal, setStateGetNotiTotal] = useState(0)
  const [stateGetNotiList, setStateGetNotiList] = useState([])
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [alertNotiLogin, setAlertNotiLogin] = useState(false)
  const userLogin = decryptData(localStorage.getItem('user'))

  const [callGetNoti, { data: dataGetNoti }] = useLazyQuery(NOTI_GET)
  const [callGetNotiMore, { data: dataGetNotiMore, loading: loadingGetNotiMore }] = useLazyQuery(NOTI_GET)
  const notiLimit = 6

  useEffect(() => {
    if (userLogin.pCode) {
      callGetNoti({
        variables: encryptInput({
          personnelCode: userLogin.pCode,
          limit: notiLimit,
          page: 0,
        }),
      })
    }
  }, [])

  useEffect(() => {
    if (dataGetNoti && dataGetNoti.getNoti) {
      setStateGetNotiUnRead(dataGetNoti.getNoti.unRead)
      setStateGetNotiTotal(dataGetNoti.getNoti.total)
      setStateGetNotiList(dataGetNoti.getNoti.detail)
    }
  }, [dataGetNoti])

  //---
  const fncNotiLoadMore = (page = 0) => {
    callGetNotiMore({
      variables: encryptInput({
        personnelCode: userLogin.pCode,
        limit: notiLimit,
        page: page,
      }),
    })
  }

  useEffect(() => {
    if (dataGetNotiMore && dataGetNotiMore.getNoti) {
      setStateGetNotiTotal(dataGetNotiMore.getNoti.total)
      setStateGetNotiList(dataGetNotiMore.getNoti.detail)
      //---
      const dataList = _.orderBy(dataGetNotiMore.getNoti.detail, ['notiId'], ['desc'])
      setStateGetNotiList(_.unionBy(stateGetNotiList, dataList, 'notiId'))
    }
  }, [dataGetNotiMore])

  //---
  const dataSubNoti = SubscriptionData(userLogin.pCode, userLogin.userId)
  const dataSubLoginNoti = SubscriptionLoginData(userLogin.userId)

  const [callReadNoti, { data: dataReadNoti }] = useMutation(NOTI_READ)
  const fncReadNoti = (personnelCode) => {
    if (personnelCode) {
      callReadNoti({
        variables: encryptInput({
          personnelCode: personnelCode,
        }),
      })
    }
  }

  useEffect(() => {
    if (dataSubNoti && dataSubNoti.notification) {
      setLoadingNotiLoadMore(false)
      const dataSubList = _.orderBy(dataSubNoti.notification.detail, ['notiId'], ['desc'])
      setStateGetNotiList(_.unionBy(dataSubList, stateGetNotiList, 'notiId'))
      if (dataSubNoti.notification.unRead > 0) {
        setStateGetNotiUnRead(dataSubNoti.notification.unRead)
      }
    }
  }, [dataSubNoti])

  useEffect(() => {
    if (dataSubLoginNoti?.notificationLogin?.userId) {
      setAlertNotiLogin(dataSubLoginNoti?.notificationLogin?.userId)
    }
  }, [dataSubLoginNoti])

  useEffect(() => {
    if (alertNotiLogin) {
      Alert({
        type: 'warning',
        title: t(
          'มีผู้ใช้งานท่านอื่นพยายามเข้าใช้งานระบบด้วยชื่อผู้ใช้งานของท่าน กรุณาเปลี่ยนรหัสผ่านเพื่อความปลอดภัย',
        ),
        onOk() {
          setAlertNotiLogin(false)
        },
      })
    }
  }, [alertNotiLogin])

  useEffect(() => {
    if (dataReadNoti) {
      setStateGetNotiUnRead(0)
    }
  }, [dataReadNoti])

  const scrollToBottom = () => {
    const domNode = dropDownNotiRef.current
    if (domNode) {
      domNode.scrollTop = domNode.scrollHeight
    }
  }

  const [loadingNotiLoadMore, setLoadingNotiLoadMore] = useState(false)
  const notiList = (type) => {
    const ele = []
    let itemsProcessed = 0
    stateGetNotiList.forEach((item, key) => {
      itemsProcessed++
      ele.push(
        <Menu.Item
          key={key}
          onClick={() => {
            if (item.route) {
              navigate(item.route, {
                state: { refCode: item.refCode || '' },
              })
            }
            setDropdownVisible(false)
          }}
        >
          <span className="title">{t(item.title)}</span>
          <span className="desc">{item.detail}</span>
          <span className="datetime">{dateTimeDisplay(item.createdAt)}</span>
        </Menu.Item>,
      )
      if (stateGetNotiList.length === itemsProcessed) {
        //if last
        if (type === 'loadmore') {
          setTimeout(() => {
            scrollToBottom()
          }, 500)
        }
      }
    })
    return ele
  }

  const menuNoti = (
    <MenuNotiStyle>
      <div className="scroll" ref={dropDownNotiRef}>
        {!_.isEmpty(stateGetNotiList) ? (
          <>
            <Menu>{loadingNotiLoadMore ? notiList('loadmore') : notiList('normal')}</Menu>
            {stateGetNotiTotal !== stateGetNotiList.length && stateGetNotiTotal > notiLimit && (
              <div
                className="link-more"
                onClick={() => {
                  fncNotiLoadMore(stateGetNotiList.length)
                  setLoadingNotiLoadMore(true)
                }}
              >
                {loadingGetNotiMore ? t('กำลังโหลด') : t('ดูเพิ่ม')}
              </div>
            )}
          </>
        ) : (
          <Menu>
            <Menu.Item key={0} className="notiEmpty">
              ไม่มีข้อมูล
            </Menu.Item>
          </Menu>
        )}
      </div>
    </MenuNotiStyle>
  )

  const [callLogout, { data: dataLogout }] = useMutation(LOGOUT)
  useEffect(() => {
    if (dataLogout) {
      localStorage.removeItem('user')
      localStorage.removeItem('sess_expire_min')
      localStorage.removeItem('sess_active_screen')
      localStorage.removeItem('sess_start_time')
      setUser({ loginName: null })
      navigate('login')
    }
  }, [dataLogout])

  const menu = (
    <MenuStyle>
      <Menu>
        <Menu.Item key="0" onClick={() => navigate('profile')}>
          <span>{t('โปรไฟล์')}</span>
        </Menu.Item>
        <Menu.Item
          key="1"
          onClick={() => {
            const input = {
              logfileLoginId: userLogin.logfileLoginId ? userLogin.logfileLoginId : null,
              userId: userLogin.userId,
            }
            callLogout({ variables: encryptInput(input) })
          }}
        >
          <span>{t('ออกจากระบบ')}</span>
        </Menu.Item>
      </Menu>
    </MenuStyle>
  )

  const AvatarTitle = ({ name, dep, code }) => {
    return (
      <div className="profile" style={{ flexDirection: 'column' }}>
        <div className="name"> {name}</div>
        <div className="dev"> {dep}</div>
        <div className="code">ID: {code}</div>
      </div>
    )
  }

  return (
    <NavbarStyle>
      <Header className="header">
        <Row>
          <Col lg={{ span: 3 }} md={{ span: 3 }} sm={{ span: 3 }}>
            <NavLogo />
            {process.env.REACT_APP_ENV !== 'production' && (
              <div
                style={{
                  position: 'absolute',
                  marginLeft: '100px',
                  marginTop: '-50px',
                  fontSize: '1.2rem',
                  color: '#eb2227',
                }}
              >
                {process.env.REACT_APP_ENV === 'develop' && '(Develop)'}
                {process.env.REACT_APP_ENV === 'uat' && '(UAT)'}
              </div>
            )}
          </Col>
          <Col
            lg={{ span: 4, offset: 17 }}
            md={{ span: 6, offset: 15 }}
            sm={{ span: 10, offset: 9 }}
            xs={{ span: 10, offset: 8 }}
          >
            <div style={{ display: 'flex', justifyContent: 'end' }}>
              <Dropdown
                visible={dropdownVisible}
                overlay={menuNoti}
                trigger={['click']}
                placement="bottomLeft"
                onVisibleChange={(e) => {
                  if (e === false) {
                    setDropdownVisible(false)
                    setLoadingNotiLoadMore(false)
                  }
                }}
              >
                <a
                  className="ant-dropdown-link link-noti"
                  onClick={(e) => {
                    e.preventDefault()
                    setDropdownVisible(true)
                    setLoadingNotiLoadMore(false)
                    fncReadNoti(userLogin.pCode)
                  }}
                >
                  <span className="icon-noti">
                    <IconNew icon={'Bell'} size={34} color="#555" />
                    {stateGetNotiUnRead && stateGetNotiUnRead > 0 ? (
                      <span className="num">{stateGetNotiUnRead > 99 ? '99+' : stateGetNotiUnRead}</span>
                    ) : null}
                  </span>
                </a>
              </Dropdown>
              <AvatarTitle
                name={user.fullname}
                dep={lang === 'en' ? user.orgNameEN : user.orgNameTH}
                code={user.pCode ? user.pCode : '-'}
              />
              <div className="avatar">
                <Dropdown overlay={menu} trigger={['click']} placement="bottomLeft">
                  <Avatar
                    className={props.activeScreen}
                    size={47}
                    src={<img src={ActiveImg} style={{ height: '45px', back: 'no-repeat' }} />}
                    onClick={(e) => e.preventDefault()}
                  ></Avatar>
                </Dropdown>
              </div>
            </div>
          </Col>
        </Row>
      </Header>
    </NavbarStyle>
  )
}

export default Navbar
