import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_SELECT_ADHOCJOB } from '../../graphql/Query'
import { Select } from '../../../../../components/select/Select.jsx'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText } from '../../../../../utilitys/helper'
import { dateDisplay } from '../../../../../utilitys/dateHelper'

const SelectAdhocJob = (props) => {
  const { filters, placeholder, formname, handleChange, setInitData, ...otherProp } = props
  const { jobTypeCode, personnelCode } = filters || {}

  const [firstRun, isFirstRun] = useState(true)
  const [isData, isSetData] = useState([])

  const [getSelectAdhocJobFn, getSelectAdhocJob] = useLazyQuery(GET_SELECT_ADHOCJOB)

  useEffect(() => {
    getSelectAdhocJobFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({}) },
    })
  }, [jobTypeCode, personnelCode])

  // useEffect(() => {
  //   if (typeAction === 'edit' || typeAction === 'view') {
  //     getData(_.find(isData, (data) => data.value === fieldCode))
  //   }
  // }, [fieldCode])

  useEffect(() => {
    if (getSelectAdhocJob.data) {
      isSetData(
        _.map(getSelectAdhocJob?.data?.getSelectAdhocJob, (item) => {
          return {
            label: item?.jobCode + ': ' + displayText(item?.jobSubjectTH, item?.jobSubjectEN),
            value: item?.jobCode,
            source: item,
          }
        }),
      )
    }
  }, [getSelectAdhocJob.data])

  useEffect(() => {
    if (setInitData) {
      if (!_.isEmpty(isData) && firstRun && props.value) {
        const e = _.find(isData, (data) => data.value === props.value)
        setInitData({
          duration: dateDisplay(e?.source?.dateStart) + ' - ' + dateDisplay(e?.source?.dateEnd),
          useday: e?.source?.useday,
          dateStartJobAndProject: new Date(e?.source?.dateStart),
        })
        isFirstRun(false)
      }
    }
  }, [isData])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getSelectAdhocJob.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          handleChange(_.find(isData, (data) => data.value === value))
        }}
      />
    </>
  )
}

export default SelectAdhocJob
