export let code = `import { TabNew } from '../../../components/tab/Tab'
import { IconNew } from '../../../components/icon/Icon'
import { Row, Col } from 'antd'
import Table from './examplecomponents/Table'
import FormExample from '../form/FormExample'
export default function App() {
  const tabs = [
    // Import components content
    {
      key: 1,
      iconTab: () => <IconNew icon="List" size={14} />,
      name: 'Tab 1 Table',
      disabled: false,
      content: () => <Table />,
    },
    // Import components content
    {
      key: 2,
      iconTab: () => <IconNew icon="List" size={14} />,
      name: 'Tab 2 Example From',
      disabled: false,
      content: () => <FormExample formId={'Press enter id'} disabledCode={true} />,
    },
    // Built in components content
    {
      key: 3,
      iconTab: () => <IconNew icon="TrendingUp" size={14} />,
      name: 'Tab 3',
      disabled: false,
      content: function () {
        return (
          <div style={{ background: '#f0f0f5' }}>
            <hr />
            <h2>!!! wirte components here !!!</h2>
            <br />
            <h2>Tab 3</h2>
            <br />
            <h2>contents</h2>
            <br />
            <hr />
          </div>
        )
      },
    },
    // disabled true
    // Built in components content
    {
      key: 4,
      name: 'Tab 4 disabled',
      disabled: true,
      content: function () {
        return <span>Tab4</span>
      },
    },
  ]
      return <>
        <Row>
          <Col span={24} align="middle" style={{ marginTop: 16 }}>
            {/* 
              available now 
              defaultActiveKey detect from tab key
            */}
            <TabNew tab={tabs} defaultActiveKey="2" />
          </Col>
        </Row>
      </>
  }
`
