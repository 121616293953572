import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { GET_CLOSING_MEETING_RESULTS_PAGINATION, GET_CLOSING_MEETING_RESULTS_EXPORT } from './graphql/Query'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { displayText, displayError } from '../../../utilitys/helper'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { encryptInput } from '../../../utilitys/crypto'
import { handleSort } from '../../../utilitys/pagination'
import ClosingMeetingResultsFilter from './components/ClosingMeetingResultsFilter.jsx'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import SendEmailReportModal from '../components/SendEmailReportModal.jsx'

const ClosingMeetingResultsList = (props) => {
  const wrapper = { sm: 24, md: 24, lg: 24, xl: 24 }
  const { menu } = props

  const { t } = useTranslation()

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState([])
  const [user] = useDecodeUser()

  /* สำหรับการส่งเมลรายงาน */
  const [visibleSendMail, setVisibleSendMail] = useState(false)
  const [mailValue, setMailValue] = useState()
  const [inputSendEmail, setInputSendEmail] = useState()

  const [cellPagination, { data: dataPagination, loading: loadingPagination, error: errorPagination }] = useLazyQuery(
    GET_CLOSING_MEETING_RESULTS_PAGINATION,
  )

  const [cellExport, { data: dataExport, loading: loadingExport, error: errorExprot }] = useLazyQuery(
    GET_CLOSING_MEETING_RESULTS_EXPORT,
  )

  const [cellSendEmailReport, { data: dataReport, loading: loadingReport, error: errorReport }] = useLazyQuery(
    GET_CLOSING_MEETING_RESULTS_EXPORT,
  )

  useEffect(() => {
    if (dataReport) {
      Alert({
        type: 'success',
        title: t('ส่งอีเมลสำเร็จ'),
        onOk() {},
      })
    }

    if (errorReport) {
      Alert({
        type: 'error',
        title: t('ส่งอีเมลไม่สำเร็จ'),
        content: t(displayError(errorReport)),
        onOk() {},
      })
    }
  }, [dataReport])

  /* สำหรับการส่งเมลรายงาน */
  useEffect(() => {
    if (inputSendEmail) {
      // Call API Export
      let data = {
        projectCode: mailValue.refCode,
        lang: localStorage.getItem('lang'),
        emailData: inputSendEmail,
      }

      cellSendEmailReport({
        variables: {
          input: encryptInput(data),
        },
      })
    }
  }, [inputSendEmail])

  useEffect(() => {
    if (user?.orgCode) {
      cellPagination({
        variables: {
          input: encryptInput({
            organizationCode: user.orgCode,
            filters: { ...filters },
            pagination: { limit, page },
            sort,
          }),
        },
      })
    }
  }, [page, limit, filters, sort, user?.orgCode])

  useEffect(() => {
    if (dataExport) {
      if (dataExport.closingMeetingResultsExport.message === 'succcess') {
        window.open(`${dataExport.closingMeetingResultsExport.fileUrl}`, '_blank')
      } else {
        Alert({
          type: 'error',
          title: t('ไม่มีข้อมูล'),
          onOk() {},
        })
      }
    }

    if (errorExprot) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorExprot)),
        onOk() {},
      })
    }
  }, [dataExport])

  useEffect(() => {
    if (dataPagination) {
      const response = dataPagination.getClosingMeetingResultsPagination
      setCount(response.count)
      setDataLists(
        _.map(response?.result, (data, index) => {
          return {
            // ...data,
            key: index,
            projectName: displayText(
              `${data.projectCode}: ${data.projectNameTH}`,
              `${data.projectCode}: ${data.projectNameEN}`,
            ),
            projectRefName: displayText(data.projectRefNameTH, data.projectRefNameEN),
            dateEnd: dateDisplay(data.dateEnd),
            dateStart: dateDisplay(data.dateStart),
            organization: displayText(data.adOrganizationNameTH, data.adOrganizationNameEN),
            personnelName: displayText(data.personnelNameTH, data.personnelNameEN),
            tools: data.projectCode,
          }
        }),
      )
    }

    if (errorPagination) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(errorPagination)),
        onOk() {},
      })
    }
  }, [dataPagination])

  const onFilter = (data) => setFilters(data)

  const columns = [
    { title: t('โครงการ'), dataIndex: 'projectName', width: '400px', align: 'left', sorter: true },
    { title: t('แหล่งที่มาของโครงการ'), dataIndex: 'projectRefName', width: '250px', sorter: true },
    { title: t('วันที่เริ่มต้น'), dataIndex: 'dateStart', width: '150px', align: 'center', sorter: true },
    { title: t('วันที่สิ้นสุด'), dataIndex: 'dateEnd', width: '150px', align: 'center', sorter: true },
    { title: t('หน่วยรับตรวจ'), dataIndex: 'organization', width: '250px' },
    { title: t('ผู้รับผิดชอบ'), dataIndex: 'personnelName', width: '200px', sorter: true },
    {
      title: '',
      dataIndex: 'tools',
      width: '120px',
      align: 'right',
      render: (projectCode) => {
        let list = [
          {
            type: 'download',
            onClick: () => {
              let data = {
                projectCode: projectCode,
                lang: localStorage.getItem('lang'),
              }
              cellExport({
                variables: {
                  input: encryptInput(data),
                },
              })
            },
          },
          {
            type: 'settings',
            onClick: () => {
              setVisibleSendMail(true)
              setMailValue({ reportName: 'รายงานผลการตรวจสอบเพื่อประชุมผลการปิดตรวจ', refCode: projectCode })
            },
          },
        ]
        return <ButtonGroup menu={menu} size={18} icons={list} />
      },
    },
  ]

  const crumb = [
    { label: t('รายงาน'), path: '/reports/closingmeetingresults' },
    { label: t('รายงานผลการตรวจสอบเพื่อประชุมผลการปิดตรวจ') },
  ]

  return (
    <>
      {(loadingPagination || loadingExport || loadingReport) && <SpinnersNew />}
      <Col {...wrapper}>
        <Breadcrumb data={crumb} title={t('รายงานผลการตรวจสอบเพื่อประชุมผลการปิดตรวจ')} />
        <ClosingMeetingResultsFilter onFilter={onFilter} menu={menu} />
        <div style={{ marginBottom: '2rem' }}>
          <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col {...wrapper}>
                <Datatable
                  columns={columns}
                  data={[...dataLists]}
                  total={count}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  pageSize={limit}
                  page={page}
                  searchCustom={true}
                  handleTableChange={(e) => {
                    const reNewField = [
                      { projectName: displayText('projectNameTH', 'projectNameEN') },
                      { projectRefName: displayText('projectRefNameTH', 'projectRefNameEH') },
                      { organization: displayText('organizationNameTH', 'organizationNameEN') },
                      { personnelName: displayText('firstNameTH', 'firstNameEN') },
                    ]

                    setSort(handleSort(e?.sorter, reNewField))
                  }}
                />
              </Col>
            </Row>
          </CardNew>
        </div>
      </Col>
      <SendEmailReportModal
        menu={menu}
        open={visibleSendMail}
        close={(e) => {
          setVisibleSendMail(e)
        }}
        fileType={{ pdf: false, word: false, excel: true, csv: false }}
        setInputSendEmail={(e) => setInputSendEmail(e)}
        mailValue={mailValue}
      />
    </>
  )
}

ClosingMeetingResultsList.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default ClosingMeetingResultsList
