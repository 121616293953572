import styled from 'styled-components'
import { colors } from '../../configs/styles.config'
export const LabelStyle = styled.div`    
    .icon-show {
        margin-right: 5px;
        display: inline-flex;
    }
    .header {
        font-family: NotoSansThai;
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 32px;
        color:${colors.textDefault}
    }

    .header-primary {
        font-family: NotoSansThai;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color:${colors.primary}
    }

    .sub-header{
        font-family: NotoSansThai;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 24px;
    }

    .sub-header-bold{
        font-family: NotoSansThai;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
    }

    .sub-header-primary{
        font-family: NotoSansThai;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
        color:${colors.primary}
    }

    .inFilter{
        font-family: NotoSansThai;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
    }

    .body-header{
        font-family: NotoSansThai;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: ${colors.primary}
    }

    .header-table{
        font-family: NotoSansThai;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color: ${colors.textDefault}
    }

    .tab-header-active{
        font-family: NotoSansThai;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height:20px;
        color: ${colors.primaryHover};
        }
    }
    .tab-header-inactive{
        font-family: NotoSansThai;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height:15px;
        color: ${colors.lightGray2};
        }
    }

    .body{
        font-family: ThSarabunNew;
        font-style: normal;
        font-size: 14px;
        font-weight: 300;
        line-height:22px;
    }
    .body-primary{
        font-family: ThSarabunNew;
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: ${colors.primary};
    }

    .body-warning{
        font-family: ThSarabunNew;
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: ${colors.orange};
    }

    .body-danger{
        font-family: ThSarabunNew;
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: ${colors.danger};
    }

    .notify-text{
        font-family: NotoSansThai;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height:22px;
        color: ${colors.primary};
    }

    .title-card{
        font-family: NotoSansThai;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height:22px;
        color:#000000a6
    }

    .error{
        color: ${colors.error};
    }

    /* Label For card */

    .card-title {
        display: flex;
        clear: both;
        margin-bottom: 16px;
        article{
            font-weight: 500;
            color: #333333;
            font-size:18px;
        }
    }
`
