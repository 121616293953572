import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { CardNew } from '../../../components/card/Card'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { LabelNew } from '../../../components/label/Label'
import { InputNew, InputNumberNew } from '../../../components/input/Input'
import { TextAreaNew } from '../../../components/textarea/TextArea'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { Timepicker } from '../../../components/timepicker/Timepicker.jsx'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import SelectJobGroups from '../../../components/inputfromapi/selectjobgroups/SelectJobGroups.jsx'
import SelectJobTypes from '../../../components/inputfromapi/selectjobtypes/SelectJobTypes.jsx'
import SelectProjectTypes from '../../../components/inputfromapi/selectprojecttypes/SelectProjectTypes.jsx'
import SelectJobStatus from '../../../components/inputfromapi/selectjobstatus/SelectJobStatus.jsx'
import SelectUrgents from '../../../components/inputfromapi/selecturgents/SelectUrgents.jsx'
import SelectAdhocJob from './components/select/SelectAdhocJob.jsx'
import SelectAuditProject from './components/select/SelectAuditProject'
import SelectAuditJobStep from './components/select/SelectAuditJobStep'
import SelectApproveStatus from '../../../components/inputfromapi/selectapprovestatus/SelectApproveStatus.jsx'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayText, validateURL, displayError } from '../../../utilitys/helper'
import { GET_TIMESHEET_BY_ID } from './graphql/Query'
import { encryptInput } from '../../../utilitys/crypto'
import Files from './components/Files'

const TimeSheetApproveDetail = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { state } = useLocation()

  if (!state) {
    return <Navigate to={'/auditoperations/timesheetapprove'} />
  }

  const { type_action } = state

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/timesheetapprove' },
    { label: t('รายการคำขออนุมัติปฏิบัติงาน'), path: '/auditoperations/timesheetapprove' },
    { label: t('อนุมัติการปฏิบัติงาน') },
    { label: t('รายละเอียดการปฏิบัติงาน') },
  ]

  const Responsive = {
    md: 18,
    lg: 18,
  }
  const Responsive2 = {
    md: 24,
    lg: 24,
  }

  const { refCode, timeSheetId } = state
  const [fieldJobGroup, setFieldJobGroup] = useState()
  const [fieldTypeCode, setFieldTypeCode] = useState()
  const [fieldCode, setFieldCode] = useState()
  const [fileDetail, setFileDetail] = useState()
  const [getFiles, setFiles] = useState([])

  /* ---------------- API ----------- */
  const [getDataFn, { loading: loadingGetData, error: errorGetData, data: dataTimeSheet }] =
    useLazyQuery(GET_TIMESHEET_BY_ID)

  /* ---------------- EFFECT ----------- */

  useEffect(() => {
    if (timeSheetId) {
      getDataFn({
        variables: {
          timeSheetId: encryptInput(timeSheetId),
        },
      })
    }
  }, [timeSheetId])

  useEffect(() => {
    if (dataTimeSheet) {
      const data = dataTimeSheet.getTimeSheetById

      setFieldJobGroup(data.jobGroupCode)
      setFieldTypeCode(data.jobTypeCode || data.projectTypeCode)
      setFieldCode(data.jobCode || data.projectCode)
      setFileDetail(data.fileDetail)
      setFiles(
        data.listFile.map((item, key) => {
          return <Files key={key} item={item} index={key} />
        }),
      )

      form.setFieldsValue({
        duration: dateDisplay(data.durationDateStart) + ' - ' + dateDisplay(data.durationDateEnd),
        jobGroupCode: data.jobGroupCode || null,
        jobTypeCode: data.jobTypeCode || null,
        projectTypeCode: data.projectTypeCode || null,
        jobCode: data.jobCode || null,
        projectCode: data.projectCode || null,
        stepId: data.stepId || null,
        urgentCode: data.urgentCode || null,
        placeOfWork: displayText(data.placeOfWorkTH, data.placeOfWorkEN) || '',
        placeOfWorkTH: data.placeOfWorkTH || '',
        placeOfWorkEN: data.placeOfWorkEN || '',
        jobDetail: displayText(data.jobDetailTH, data.jobDetailEN) || '',
        jobDetailTH: data.jobDetailTH || '',
        jobDetailEN: data.jobDetailEN || '',
        jobResponsibility: displayText(data.jobResponsibilityTH, data.jobResponsibilityEN) || '',
        jobResponsibilityTH: data.jobResponsibilityTH || '',
        jobResponsibilityEN: data.jobResponsibilityEN || '',
        output: displayText(data.outputTH, data.outputEN) || '',
        outputTH: data.outputTH || '',
        outputEN: data.outputEN || '',
        outcome: displayText(data.outcomeTH, data.outcomeEN) || '',
        outcomeTH: data.outcomeTH || '',
        outcomeEN: data.outcomeEN || '',
        useday: data.useday || null,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        timeStart: data.timeStart,
        timeEnd: data.timeEnd,
        hour: data.hour || null,
        fileDetail: data.fileDetail || null,
        jobStatusCode: data.jobStatusCode || null,
        approveStatusCode: data.approveStatusCode || null,
        cost: data.cost || null,
      })
    }

    if (errorGetData) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(errorGetData)),
      })
    }
  }, [dataTimeSheet])

  return (
    <>
      {loadingGetData && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('รายละเอียดการปฏิบัติงาน')} />
      <Form
        form={form}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ md: { span: 8 }, lg: { span: 5 } }}
        wrapperCol={{ md: { span: 16 }, lg: { span: 12 } }}
        name="formTimeSheet"
      >
        <CardNew topic={t('รายละเอียดการปฏิบัติงาน')} icon="AlertCircle" toggledrop="false">
          <Row gutter={[12, 12]} justify="center">
            <Col {...Responsive}>
              <Row style={{ margin: '10px 0px' }}>
                <Col {...Responsive2}>
                  <LabelNew type="sub-header-primary">{t('รายละเอียดทั่วไป')}</LabelNew>
                </Col>
              </Row>
              <Row justify="center">
                <Col {...Responsive}>
                  <Form.Item
                    name="jobGroupCode"
                    label={<LabelNew type="tab-header-inactive">{t('ลักษณะงาน')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <SelectJobGroups
                      placeholder={t('เลือกลักษณะงาน')}
                      formname={'formTimeSheet'}
                      filters={{ isActive: 1 }}
                      handleChange={(e) => {
                        form.setFieldsValue({ jobGroupCode: e?.value }),
                          setFieldJobGroup(e?.value),
                          form.resetFields(['projectTypeCode', 'jobTypeCode', 'stepId', 'jobCode', 'projectCode']),
                          setFieldTypeCode(),
                          setFieldCode()
                      }}
                      onClear={() => {
                        setFieldJobGroup()
                        setFieldTypeCode()
                        setFieldCode()
                      }}
                      disabled
                    />
                  </Form.Item>

                  {fieldJobGroup === '10' ? (
                    <Form.Item
                      name="projectTypeCode"
                      label={<LabelNew type="tab-header-inactive">{t('ประเภท')}</LabelNew>}
                    >
                      <SelectProjectTypes
                        placeholder={t('เลือกประเภทโครงการ')}
                        formname={'formTimeSheet'}
                        filters={{ isActive: 1 }}
                        handleChange={(e) => {
                          form.setFieldsValue({ projectTypeCode: e?.value }),
                            setFieldTypeCode(e?.value),
                            form.resetFields(['projectCode', 'stepId']),
                            setFieldCode()
                        }}
                        onClear={() => {
                          setFieldTypeCode()
                          setFieldCode()
                        }}
                        disabled
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item name="jobTypeCode" label={<LabelNew type="tab-header-inactive">{t('ประเภท')}</LabelNew>}>
                      <SelectJobTypes
                        placeholder={t('เลือกประเภทงาน')}
                        formname={'formTimeSheet'}
                        filters={{ jobGroupCode: fieldJobGroup || null, isActive: 1 }}
                        handleChange={(e) => {
                          form.setFieldsValue({ jobTypeCode: e?.value }),
                            setFieldTypeCode(e?.value),
                            form.resetFields(['jobCode', 'stepId']),
                            setFieldCode()
                        }}
                        disabled
                        onClear={() => {
                          setFieldTypeCode()
                          setFieldCode()
                        }}
                      />
                    </Form.Item>
                  )}

                  {fieldJobGroup === '10' ? (
                    <Form.Item
                      name="projectCode"
                      label={<LabelNew type="tab-header-inactive">{t('งาน/โครงการ')}</LabelNew>}
                    >
                      <SelectAuditProject
                        // placeholder={t('')}
                        formname={'formTimeSheet'}
                        filters={{ projectTypeCode: fieldTypeCode || null }}
                        handleChange={(e) => {
                          setFieldCode(e?.value),
                            form.resetFields(['stepId']),
                            form.setFieldsValue({
                              projectCode: e?.value,
                              duration: dateDisplay(e.source.dateStart) + ' - ' + dateDisplay(e.source.dateStart),
                              useday: e.source.useday,
                            })
                        }}
                        disabled
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="jobCode"
                      label={<LabelNew type="tab-header-inactive">{t('งาน/โครงการ')}</LabelNew>}
                    >
                      <SelectAdhocJob
                        formname={'formTimeSheet'}
                        filters={{ jobTypeCode: fieldTypeCode || null }}
                        handleChange={(e) => {
                          setFieldCode(e?.value),
                            form.resetFields(['stepId']),
                            form.setFieldsValue({
                              jobCode: e?.value,
                              duration: dateDisplay(e?.source.dateStart) + ' - ' + dateDisplay(e?.source.dateStart),
                              useday: e?.source.useday,
                            })
                        }}
                        disabled
                        getData={(e) => {
                          form.setFieldsValue({
                            duration: dateDisplay(e?.source.dateStart) + ' - ' + dateDisplay(e?.source.dateStart),
                            useday: e?.source.useday,
                          })
                        }}
                        fieldCode={fieldCode}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="stepId"
                    label={<LabelNew type="tab-header-inactive">{t('ขั้นตอนปฏิบัติงาน')}</LabelNew>}
                  >
                    <SelectAuditJobStep
                      formname={'formTimeSheet'}
                      filters={{
                        jobGroupCode: fieldJobGroup || null,
                        jobAndProjectCode: fieldCode || null,
                      }}
                      handleChange={(e) => form.setFieldsValue({ stepId: e?.value })}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name="urgentCode"
                    label={<LabelNew type="tab-header-inactive">{t('ความเร่งด่วน')}</LabelNew>}
                  >
                    <SelectUrgents
                      // placeholder={t('เลือก')}
                      formname={'formTimeSheet'}
                      handleChange={(e) => form.setFieldsValue({ urgentCode: e?.value })}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name="placeOfWork"
                    label={<LabelNew type="tab-header-inactive">{t('สถานที่ปฏิบัติงาน')}</LabelNew>}
                  >
                    <InputNew disabled maxLength={100} />
                  </Form.Item>
                  <Form.Item name="placeOfWorkTH" hidden={true}>
                    <InputNew />
                  </Form.Item>
                  <Form.Item name="placeOfWorkEN" hidden={true}>
                    <InputNew />
                  </Form.Item>
                  <Form.Item
                    name="jobDetail"
                    label={<LabelNew type="tab-header-inactive">{t('รายละเอียดงาน')}</LabelNew>}
                  >
                    <TextAreaNew placeholder={t('รายละเอียดงาน')} autoSize={{ minRows: 4, maxRows: 4 }} disabled />
                  </Form.Item>
                  <Form.Item name="jobDetailTH" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item name="jobDetailEN" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item
                    name="jobResponsibility"
                    label={<LabelNew type="tab-header-inactive">{t('สรุปผลการทำงาน')}</LabelNew>}
                  >
                    <TextAreaNew placeholder={t('งานที่รับผิดชอบ')} autoSize={{ minRows: 4, maxRows: 4 }} disabled />
                  </Form.Item>
                  <Form.Item name="jobResponsibilityTH" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item name="jobResponsibilityEN" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item name="output" label={<LabelNew type="tab-header-inactive">{t('ผลที่ได้')}</LabelNew>}>
                    <TextAreaNew placeholder={t('ผลที่ได้')} autoSize={{ minRows: 4, maxRows: 4 }} disabled />
                  </Form.Item>
                  <Form.Item name="outputTH" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item name="outputEN" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item
                    name="outcome"
                    label={<LabelNew type="tab-header-inactive">{t('ผลลัพธ์ที่ได้')}</LabelNew>}
                  >
                    <TextAreaNew placeholder={t('ผลลัพธ์ที่ได้')} autoSize={{ minRows: 4, maxRows: 4 }} disabled />
                  </Form.Item>
                  <Form.Item name="outcomeTH" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item name="outcomeEN" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ margin: '10px 0px' }}>
                <Col {...Responsive2}>
                  <LabelNew type="sub-header-primary">{t('เวลาปฏิบัติงาน')}</LabelNew>
                </Col>
              </Row>
              <Row justify="center">
                <Col {...Responsive}>
                  <Form.Item
                    name="duration"
                    label={<LabelNew type="tab-header-inactive">{t('ระยะเวลาเริ่มต้น - สิ้นสุด')}</LabelNew>}
                  >
                    <InputNew disabled />
                  </Form.Item>
                  <Form.Item
                    name="useday"
                    label={<LabelNew type="tab-header-inactive">{t('จำนวนวันที่ใช้ไปแล้ว')}</LabelNew>}
                  >
                    <InputNew disabled />
                  </Form.Item>
                  <Form.Item
                    name="dateStart"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <Datepicker
                      autoComplete="off"
                      setValueDateFn={async (data) => {
                        form.setFieldsValue({ dateStart: await data /* dateEnd: await data */ })
                      }}
                      placeholder={t('DD/MM/YYYY')}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name="timeStart"
                    label={<LabelNew type="tab-header-inactive">{t('เวลาเริ่มต้น')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <Timepicker onChangeTime={() => {}} notDateNow disabled />
                  </Form.Item>
                  <Form.Item
                    name="timeEnd"
                    label={<LabelNew type="tab-header-inactive">{t('เวลาสิ้นสุด')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <Timepicker onChangeTime={() => {}} notDateNow disabled />
                  </Form.Item>
                  <Form.Item
                    name="dateEnd"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                  >
                    <Datepicker
                      autoComplete="off"
                      setValueDateFn={async (data) => {
                        form.setFieldsValue({ dateEnd: await data })
                      }}
                      onClear={() => {
                        form.resetFields(['dateEnd'])
                      }}
                      placeholder={t('DD/MM/YYYY')}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name="hour"
                    label={<LabelNew type="tab-header-inactive">{t('เวลาที่ทำงาน(ชั่วโมง)')}</LabelNew>}
                  >
                    <InputNumberNew min={0} step={0.01} align="right" maxLength={6} disabled />
                  </Form.Item>
                  <Form.Item name="cost" label={<LabelNew type="tab-header-inactive">{t('งบประมาณ')}</LabelNew>}>
                    <InputNew maxLength={13} disabled />
                  </Form.Item>
                  <Form.Item
                    name="jobStatusCode"
                    label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <SelectJobStatus
                      placeholder={t('เลือกสถานะ')}
                      formname={'formTimeSheet'}
                      filters={{ statusflag: 1, isActive: 1 }}
                      handleChange={(e) => form.setFieldsValue({ jobStatusCode: e?.value })}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name="approveStatusCode"
                    label={<LabelNew type="tab-header-inactive">{t('สถานะการอนุมัติ')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <SelectApproveStatus
                      formname={'formTimeSheet'}
                      filters={{ isActive: 1 }}
                      handleChange={(e) => form.setFieldsValue({ approveStatusCode: e?.value })}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name="fileDetail"
                    label={<LabelNew type="tab-header-inactive">{t('ลิงค์ภายนอก')}</LabelNew>}
                  >
                    <a
                      href={validateURL(fileDetail)}
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: '#2b4990', fontSize: '14px' }}
                    >
                      <u>{fileDetail}</u>
                    </a>
                  </Form.Item>
                  <Form.Item label={<LabelNew type="tab-header-inactive">{t('เอกสารเพิ่มเติม')}</LabelNew>}>
                    <Col style={{ display: 'flex' }}>{getFiles}</Col>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 20]} justify="center" style={{ marginTop: '15px' }}>
                <Col>
                  <ButtonNew
                    type="back"
                    onClick={() =>
                      navigate('/auditoperations/timesheetapprove/form', {
                        state: { type_action: type_action, refCode: refCode },
                      })
                    }
                  >
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardNew>
      </Form>
    </>
  )
}

export default TimeSheetApproveDetail
