import { gql } from '@apollo/client'

export const SELECT_ASSIGN_JOBS = gql`
  query getAssignJobs($input: SelectJobTypeInput!) {
    getAssignJobs(input: $input) {
      jobCode
      jobNumber
      jobSubjectTH
      jobSubjectEN
      requestPersonnel
      telephone
      requestOrganization
      responsibleOrganizationTH
      responsibleOrganizationEN
      fullName
    }
  }
`
