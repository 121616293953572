import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'antd'
import { ModalNew } from '../../../../components/modal/Modal.jsx'
import { Alert } from '../../../../components/alert/Alert'
import { useTranslation } from 'react-i18next'
import { InputNew } from '../../../../components/input/Input.jsx'
import { CREATE_EDUCATION_PERSONNELS } from '../graphql/Mutation'
import { GET_ONE_EDUCATION } from '../graphql/Query.js'
import SelectEducationLevels from '../../../../components/inputfromapi/selecteducationlevels/SelectEducationLevels.jsx'
import SelectEducationPrograms from '../../../../components/inputfromapi/selecteducationprograms/SelectEducationPrograms.jsx'
import SelectEducationMajors from '../../../../components/inputfromapi/selecteducationmajors/SelectEducationMajors.jsx'
// import { useNavigate } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../../utilitys/crypto'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { validateRange } from '../../../../utilitys/validationAntd'
import { displayError } from '../../../../utilitys/helper.js'

const Responesive = {
  sm: 24,
  md: 24,
  lg: 24,
}

const EducationModal = (props) => {
  const { t } = useTranslation()
  const { formType, codeEdit } = props
  const [visible, setVisible] = useState(false)
  const [getEducation, { data: getData, error: errorData, loading: loadingData }] = useLazyQuery(GET_ONE_EDUCATION)
  const [PersonnelEducationsSave, { loading: loadingPersonnelEducations, data: dataEducation, error: errorEducation }] =
    useMutation(CREATE_EDUCATION_PERSONNELS)

  const [form] = Form.useForm()
  useEffect(() => {
    if (visible && formType === 'edit') {
      getEducation({
        variables: {
          input: {
            personnelEducatId: encryptInput(codeEdit),
          },
        },
      })
    }
  }, [visible])
  useEffect(() => {
    if (props.open) {
      setVisible(props.open)
    } else {
      setVisible(false)
    }
  }, [props.open])
  // const navigate  = useNavigate()
  useEffect(() => {
    // Get value From your API
    if (formType === 'edit' || formType === 'view') {
      if (getData) {
        // Set Form value on formType = UPDATE
        form.setFieldsValue({
          personnelCode: getData.getEducation.personnelCode,
          educationLevelCode: getData.getEducation.educationLevelCode,
          educationProgramCode: getData.getEducation.educationProgramCode,
          educationMajorCode: getData.getEducation.educationMajorCode,
          year: getData.getEducation.year,
          institution: getData.getEducation.institution,
        })
      }
    } else if (formType === 'add') {
      // Set Form value on formType = CREATE
      form.resetFields()
    }
  }, [getData, visible])

  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((er) => {
      const nameField = er.name[0] || ''
      const message = er.errors[0] || ''
      if (nameField === 'datePicker') setErrorField('datePickerMockUp', message)
    })
  }
  // onValuesChange
  const onValuesChange = () => {}

  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: text,
      onOk() {},
    })
  }
  useEffect(() => {
    if (errorEducation && errorEducation.message) {
      showAlertError(t(displayError(errorEducation)))
    }
    if (errorData && errorData.message) {
      showAlertError(t(displayError(errorData)))
    }
  }, [errorEducation, errorData])

  useEffect(() => {
    if (dataEducation) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          onClose()
          props.getEducationPagenation.refetch()
        },
      })
    }
  }, [dataEducation])

  function onClose(e) {
    setVisible(false)
    props.colse(e)
  }

  const fnConfirm = (values) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        onSubmit(values)
      },
      onCancel() {
        onClose()
      },
    })
  }

  const onFinish = (values) => {
    fnConfirm(values)
  }

  const onSubmit = async (values) => {
    let dataInput = {
      personnelEducatId: props.codeEdit,
      personnelCode: props.refCode,
      educationLevelCode: values.educationLevelCode,
      educationProgramCode: values.educationProgramCode,
      educationMajorCode: values.educationMajorCode || '',
      year: parseInt(values.year) || '',
      institution: values.institution || '',
    }
    // year: parseInt(values.year) || '',
    if (props.formType === 'add') {
      dataInput['action'] = 'add'
    } else if (props.formType === 'edit') {
      dataInput['action'] = 'edit'
    }
    await PersonnelEducationsSave({
      variables: {
        input: encryptInput(dataInput),
      },
    })
  }
  return (
    <>
      {loadingPersonnelEducations || loadingData ? <SpinnersNew /> : ''}
      <ModalNew
        visible={visible}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        testTitle={
          formType !== 'view' ? (formType === 'add' ? t('เพิ่มการศึกษา') : t('แก้ไขการศึกษา')) : t('ดูการศึกษา')
        }
        type="medium"
        okButtonProps={formType === 'view' ? { style: { display: 'none' } } : {}}
      >
        <Row gutter={[16, 16]}>
          <Col {...Responesive}>
            <Row style={{ marginTop: 24 }}>
              <Col md={24} lg={24}>
                <Form
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onValuesChange={onValuesChange}
                  autoComplete="off"
                  labelAlign="left"
                  labelCol={{
                    sm: {
                      span: 10,
                      offset: 3,
                    },
                    md: {
                      span: 9,
                      offset: 3,
                    },
                    xl: {
                      span: 9,
                      offset: 3,
                    },
                  }}
                  wrapperCol={{
                    sm: 24,
                    md: 24,
                    xl: 12,
                    align: 'left',
                  }}
                  layout="horizontal"
                  name="educationForm"
                >
                  <Row>
                    <Col md={18}>
                      <Form.Item
                        name="educationLevelCode"
                        label={t('วุฒิการศึกษา')}
                        rules={[{ required: true, message: t('กรุณาระบุวุฒิการศึกษา') }]}
                      >
                        <SelectEducationLevels
                          formname="educationForm"
                          placeholder={t('เลือกวุฒิการศึกษา')}
                          disabled={formType === 'view' ? true : false}
                          handleChange={(e) =>
                            form.setFieldsValue({
                              educationLevelCode: e?.value,
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="educationProgramCode"
                        label={t('สาขาวิชา')}
                        rules={[{ required: true, message: t('กรุณาระบุสาขาวิชา') }]}
                      >
                        <SelectEducationPrograms
                          formname={'educationForm'}
                          placeholder={t('เลือกสาขาวิชา')}
                          disabled={formType === 'view' ? true : false}
                          handleChange={(e) =>
                            form.setFieldsValue({
                              educationProgramCode: e?.value,
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item name="educationMajorCode" label={t('วิชาเอก')}>
                        <SelectEducationMajors
                          formname={'educationForm'}
                          placeholder={t('เลือกวิชาเอก')}
                          disabled={formType === 'view' ? true : false}
                          handleChange={(e) =>
                            form.setFieldsValue({
                              educationMajorCode: e?.value,
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="year"
                        label={t('ปีการศึกษาที่จบ (พ.ศ.)')}
                        rules={[...validateRange(t(''), false, 1900)]}
                      >
                        <InputNew disabled={formType === 'view' ? true : false} />
                      </Form.Item>
                      <Form.Item name="institution" label={t('สถาบันที่จบ')}>
                        <InputNew disabled={formType === 'view' ? true : false} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalNew>
    </>
  )
}

export default EducationModal
