import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GET_LIST_RISK_LEVEL_FIVE_YEAR_REPORT_PAGINATION } from '../graphql/Query'
import { encryptInput } from '../../../../utilitys/crypto'
import { Alert } from '../../../../components/alert/Alert'
import { displayError } from '../../../../utilitys/helper'

export const useGetPagination = (options) => {
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [getPaginationFn, getPagination] = useLazyQuery(GET_LIST_RISK_LEVEL_FIVE_YEAR_REPORT_PAGINATION)

  useEffect(() => {
    const { filters, page, limit, sort } = options
    const { yearStart, yearEnd } = filters

    if (yearStart && yearEnd) {
      const dataInput = {
        sort: sort,
        filters: { ...filters },
        pagination: {
          page: page,
          limit: limit,
        },
      }
      getPaginationFn({
        variables: { input: encryptInput(dataInput) },
      })
    }
  }, [options])

  useEffect(() => {
    if (getPagination.error) {
      const errorList = getPagination.error
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorList)),
      })
    }
  }, [getPagination.error])

  useEffect(() => {
    if (getPagination?.data) setData(getPagination?.data?.getListRiskLevelFiveYearReportPagination)
  }, [getPagination?.data])

  const refresh = () => getPagination.refetch()

  return [data, getPagination?.loading, refresh]
}
