import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import * as _ from 'lodash'
import { TabNew } from '../../../components/tab/Tab'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { InputNew } from '../../../components/input/Input'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { Datatable } from '../../../components/datatable/Datatable'
import { displayError, displayText } from '../../../utilitys/helper'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import RiskFormSecondStepTabs1 from './tabs/RiskFormSecondStepTabs1'
import RiskFormSecondStepTabs2 from './tabs/RiskFormSecondStepTabs2'
import CheckerOrganizationModal from '../../components/modalformapi/modalCheckerOrganization/CheckerOrganizationModal'
import { GET_RISK_FORM_BY_ID, GET_RISK_FORM_DETAIL } from './graphql/Query'
import { UPDATE_RISK_FORM } from './graphql/Mutation'
const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  label: { sm: 12, md: 8, lg: 4 },
  col: { sm: 12, md: 16, lg: 8 },
}
const RiskFormSecondStep = (props) => {
  const { menu } = props
  const propsType = useLocation()
  const { riskFormCode, type_action } = propsType.state
  const disabled = type_action === 'view' ? true : false
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const isLang = localStorage.getItem('lang')

  const [dataLists, setDataLists] = useState({})
  const [riskFormOrganizationList, setRiskFormOrganizationList] = useState([])
  const [tabs, setTabs] = useState([])
  const [inputJson, setInputJson] = useState([])
  const [riskFactorsError, setRiskFactorsError] = useState(false)

  if (!propsType.state) return <Navigate to={'/riskassessment/riskform'} />
  const [riskFactorsFn, riskFactors] = useLazyQuery(GET_RISK_FORM_DETAIL)
  const [riskFormFn, riskForm] = useLazyQuery(GET_RISK_FORM_BY_ID)
  const [updateRiskFactorsFn, updateRiskFactors] = useMutation(UPDATE_RISK_FORM)

  const [modal, setModal] = useState(false)

  function setOrganizationList(v) {
    if (_.isEmpty(riskFormOrganizationList)) return setRiskFormOrganizationList([...v])
    setRiskFormOrganizationList([...mergeArrayObjects(riskFormOrganizationList, v)])
  }

  function mergeArrayObjects(arr1, arr2) {
    const arr_ = [...arr1, ...arr2]
    return _.uniqBy(arr_, (i) => i.organizationCode)
  }

  useEffect(() => {
    riskFactorsFn({
      variables: { input: encryptInput({ riskFormCode: riskFormCode }) },
    })
    riskFormFn({
      variables: { input: encryptInput({ riskFormCode: riskFormCode }) },
    })
  }, [])

  useEffect(() => {
    if (updateRiskFactors.data) {
      const resRiskForm = updateRiskFactors.data.updateRiskForm
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate(`/riskassessment/riskform`, {
            state: {
              riskFormId: resRiskForm.riskFormId,
              riskFormCode: resRiskForm.riskFormCode,
              type_action: 'updated',
            },
          })
        },
      })
    }
    if (updateRiskFactors.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        onOk() {},
      })
    }
  }, [updateRiskFactors.data])
  useEffect(() => {
    if (riskForm.data) {
      const riskForm_ = riskForm?.data?.getRiskById
      const riskFormOrganizationList_ = riskForm?.data?.getRiskById.riskFormOrganizationList.map((item, idx) => {
        return {
          key: idx + 1,
          organizationCode: item.organizationCode,
          organizationName: isLang === 'th' ? item.organizationNameTH : item.organizationNameEN,
          organizationNameTH: item.organizationNameTH,
          organizationNameEN: item.organizationNameEN,
          organizationLevelName: isLang === 'th' ? item.organizationLevelNameTH : item.organizationLevelNameEN,
          organizationLevelNameTH: item.organizationLevelNameTH,
          organizationLevelNameEN: item.organizationLevelNameEN,
          personnelCode: item.personnelCode,
          personnelName: isLang === 'th' ? item.personnelNameTH : item.personnelNameEN,
          createdAt: item.createdAt,
          createdBy: item.createdBy,
          deleteId: item.organizationCode,
        }
      })
      setRiskFormOrganizationList([...riskFormOrganizationList_])
      form.setFieldsValue({
        Year: riskForm_.Year,
        riskFormTH: riskForm_.riskFormTH,
        riskFormEN: riskForm_.riskFormEN,
        riskFormCode: riskForm_.riskFormCode,
        riskModelCode: riskForm_.riskModelCode,
        riskFormType: riskForm_.riskFormType,
        oganizationAudit: riskForm_.oganizationAudit,
        operationStatusName: displayText(riskForm_.operationStatusNameTH, riskForm_.operationStatusNameEN),
        operationStatusCode: displayText(riskForm_.operationStatusNameTH, riskForm_.operationStatusNameEN),
        dateStart: riskForm_.dateStart,
        dateEnd: riskForm_.dateEnd,
        isActive: riskForm_.isActive,
        organizationList: JSON.stringify(riskFormOrganizationList_),
      })
    }
    if (riskForm.error) {
      const error = riskForm.error
      Alert({
        type: 'error',
        title: t(displayError(error)),
        onOk() {},
      })
    }
  }, [riskForm.data])

  useEffect(() => {
    for (const key in dataLists) {
      form.setFieldsValue({
        ['riskFactors_' + key]: JSON.stringify(dataLists[key]),
      })
    }
  }, [dataLists, riskFactors.data])

  useEffect(() => {
    if (riskFactors.data) {
      const tabs_ = _.uniqBy(riskFactors?.data.getRiskFormDetail, 'riskFactorCode')
      setInputJson([...tabs_])
      const rows = riskFactors?.data.getRiskFormDetail
      setTabs(
        tabs_.map((item, idx__) => {
          dataLists[item.riskFactorCode] = rows
            .map((item_, idx) => {
              if (item_.riskFactorCode === item.riskFactorCode) {
                return {
                  key: idx,
                  tools: (
                    <>
                      {disabled ? (
                        <></>
                      ) : (
                        <>
                          <Row justify="end">
                            <Col span={4} align="right">
                              <ButtonGroup
                                menu={menu}
                                icons={[
                                  {
                                    type: 'delete',
                                    onClick: () => {
                                      // tabLength
                                      if (dataLists[item.riskFactorCode].length > 1) {
                                        deleteRows(item.riskFactorCode, item_.factorActRiskCode)
                                      }
                                      if (dataLists[item.riskFactorCode].length === 1) {
                                        setRiskFactorsError(true)
                                        setTimeout(() => setRiskFactorsError(false), 4500)
                                      }
                                    },
                                  },
                                ]}
                              />
                            </Col>
                          </Row>
                        </>
                      )}
                    </>
                  ),
                  ...item_,
                }
              }
            })
            .filter((isNotUndefined) => isNotUndefined !== undefined)
          // ซ่อน factorActNameTH ที่ไม่ใช่แถวที่ 1
          const factorActNameShow = dataLists[item.riskFactorCode]
          const uniqFacAct = _.uniqBy(factorActNameShow, 'factorActCode')
          const v = []
          uniqFacAct.map((item, idx) => {
            const no = idx + 1
            const x = factorActNameShow.filter((e) => e.factorActCode === item.factorActCode)
            v.push(
              ...x.map((_item, idx_) => {
                return {
                  ..._item,
                  no: no,
                  factorActNameShow: x.length - x.length === idx_ && _item.factorActNameTH,
                }
              }),
            )
          })
          dataLists[item.riskFactorCode] = v
          return {
            // key: item.riskFactorCode,
            key: idx__ + 1,
            name: displayText(item.riskFactorNameTH, item.riskFactorNameEN),
            content: () => (
              <>
                <Datatable
                  columns={Columns}
                  data={[...dataLists[item.riskFactorCode]]}
                  total={dataLists[item.riskFactorCode].length}
                  handleTableChange={() => {}}
                  onChangePagination={() => {}}
                  paginationCustom={false}
                />
              </>
            ),
          }
        }),
      )
      setDataLists({ ...dataLists })
    }
    if (riskFactors.error) {
      const error = riskFactors.error
      Alert({
        type: 'error',
        title: t(displayError(error)),
        onOk() {},
      })
    }
  }, [riskFactors.data])

  // onFinish
  function deleteRows(key, id) {
    _.remove(dataLists[key], (v) => v.factorActRiskCode === id)
    const dataList_ = dataLists[key]
    // ซ่อน factorActName ที่ไม่ใช่แถวที่ 1
    const uniqFacAct = _.uniqBy(dataList_, 'factorActCode')
    const v = []
    uniqFacAct.map((item, idx) => {
      const no = idx + 1
      const x = dataList_.filter((e) => e.factorActCode === item.factorActCode)
      v.push(
        ...x.map((_item, idx_) => {
          return {
            ..._item,
            no: no,
            factorActNameShow: x.length - x.length === idx_ && _item.factorActNameTH,
          }
        }),
      )
    })
    dataLists[key] = v
    setDataLists({ ...dataLists })
  }

  const Columns = [
    {
      title: t('กิจกรรม'),
      dataIndex: 'factorActName',
      width: '20%',
      render: (_, record) => {
        const { factorActNameShow, factorActNameTH, factorActNameEN, no } = record
        return (
          <>{!factorActNameShow ? '' : isLang === 'th' ? no + '.' + factorActNameTH : no + '.' + factorActNameEN}</>
        )
      },
    },
    {
      title: t('ความเสี่ยง'),
      dataIndex: 'factorActRiskName',
      render: (_, record) => {
        const { factorActRiskNameTH, factorActRiskNameEN } = record
        return <>{displayText(factorActRiskNameTH, factorActRiskNameEN)}</>
      },
    },
    {
      title: t('คำอธิบายเพิ่มเติม'),
      dataIndex: 'remark',
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '20%',
    },
  ]

  const onFinish = async () => {
    const values = form.getFieldValue()
    const riskFactorsList_ = []
    inputJson.map((v) => {
      const el = JSON.parse(values['riskFactors_' + v.riskFactorCode])
      riskFactorsList_.push(...el)
    })
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk: async () => {
        await updateRiskFactorsFn({
          variables: {
            input: encryptInput({
              riskFormCode: values.riskFormCode,
              riskFormEN: values.riskFormEN,
              riskFormTH: values.riskFormTH,
              riskFormType: values.riskFormType,
              riskModelCode: values.riskModelCode,
              Year: values.Year,
              dateEnd: values.dateEnd,
              dateStart: values.dateStart,
              isActive: values.isActive,
              oganizationAudit: values.oganizationAudit,
              operationStatusCode: values.operationStatusCode,
              userId: String(decryptData(localStorage.getItem('user')).userId),
              organizationList: values.organizationList,
              riskFormDetailsList: JSON.stringify(riskFactorsList_),
            }),
          },
        })
      },
      onCancel() {},
    })
  }
  // onFinishFailed
  const tabsDetails = [
    {
      key: 1,
      name: t('ข้อมูลทั่วไป'),
      content: () => (
        <>
          <RiskFormSecondStepTabs1
            disabled={disabled}
            responsiveLabel={Responsive.label}
            responsiveCol={Responsive.col}
            form={form}
          />
          {inputJson.map((v, idx) => {
            return (
              <Form.Item key={idx} hidden={true} name={`riskFactors_${v.riskFactorCode}`}>
                <InputNew />
              </Form.Item>
            )
          })}
        </>
      ),
    },
    {
      key: 2,
      name: t('หน่วยงานรับตรวจ'),
      content: () => (
        <>
          <RiskFormSecondStepTabs2
            menu={menu}
            disabled={disabled}
            riskFormOrganizationList={[...riskFormOrganizationList]}
            setRiskFormOrganizationList={setRiskFormOrganizationList}
            form={form}
            setModal={(e) => setModal(e)}
          />
        </>
      ),
    },
  ]
  const breadcrumbList = [
    { label: t('งานประเมินความเสี่ยง'), path: '/riskassessment/' },
    { label: t('แบบประเมินความเสี่ยง') },
  ]
  return (
    <>
      {(riskForm.loading || riskFactors.loading || updateRiskFactors?.loading) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('แบบประเมินความเสี่ยง')} />
      <CardNew topic={t('รายละเอียดแบบประเมินความเสี่ยง')} icon="Info" toggledrop={'false'}>
        <Form form={form} onFinish={onFinish} autoComplete="off" labelAlign="left" name="risk_form">
          <TabNew tab={tabsDetails} />
        </Form>
        {modal && (
          <CheckerOrganizationModal
            menu={menu}
            close={(e) => setModal(e)}
            open={modal}
            getData={(v) => setOrganizationList(v)}
            orgActive={riskFormOrganizationList}
          />
        )}
      </CardNew>

      <CardNew topic={t('รายการคำถามแบบประเมินความเสี่ยง')} icon="List" toggledrop={'true'}>
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            {riskFactorsError && (
              <span style={{ color: '#ea5456' }}>{t('กรุณาระบุหน่วยรับตรวจอย่างน้อย 1 รายการ')}</span>
            )}
          </Col>
          <Col {...Responsive}>
            <TabNew tab={tabs} />
          </Col>
          <Col {...Responsive} align="center">
            <Space size={[8, 8]} wrap>
              {!disabled && (
                <ButtonNew
                  type="primary"
                  menu={menu}
                  roles={{ type: 'edit', menu: menu }}
                  onClick={() => {
                    form.submit()
                  }}
                >
                  {t('บันทึก')}
                </ButtonNew>
              )}
              &nbsp;
              <ButtonNew type="back" onClick={() => navigate('/riskassessment/riskform')}>
                {t('ยกเลิก')}
              </ButtonNew>
            </Space>
          </Col>
        </Row>
      </CardNew>
      {/* <RiskFormFirstStepModal close={(e) => setModal(e)} open={modal} /> */}
    </>
  )
}

export default RiskFormSecondStep
