import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Card, Col, Form, Row } from 'antd'
import { IconNew } from '../../../../../../components/icon/Icon'
import { LabelNew } from '../../../../../../components/label/Label.jsx'
import { Datepicker } from '../../../../../../components/datepicker/Datepicker.jsx'
import SelectOrganization from '../../../../../../components/inputfromapi/selectorganization/SelectOrganization.jsx'
import { getFirstDayPresentYear, getLastDayPresentYear } from '../../../../../../utilitys/dateHelper'
import { FilterStyled } from '../../css/FilterStyled'

const StatusCauseAndIssueDetectedFilter = (props) => {
  const formName = 'StatusCauseAndIssueDetectedFilterForm'
  const ResponsiveCol = { sm: 24, md: 8, lg: 8 }

  const { getValueFn } = props

  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [filterData, setfilterData] = useState({})
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)

  const setErrorField = (name, errors) => {
    return form.setFields([{ name: name, errors: errors ? [errors] : false }])
  }

  useEffect(() => {
    const field = {
      dateStart: getFirstDayPresentYear(),
      dateEnd: getLastDayPresentYear(),
    }
    setfilterData(field)
    form.setFieldsValue(field)
  }, [])

  useEffect(() => {
    const { dateStart, dateEnd } = filterData

    getValueFn(filterData)
    setErrorField('dateStart', dateStart ? false : t('กรุณาเลือกวันที่เริ่มต้น'))
    setErrorField('dateEnd', dateEnd ? false : t('กรุณาเลือกวันที่สิ้นสุด'))
  }, [filterData?.dateStart, filterData?.dateEnd, filterData?.organizationCode])

  return (
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <FilterStyled>
          <Card
            className="card-dash card-filter"
            title={
              <>
                <span className="card-title-icon">
                  <IconNew icon={'Filter'} size={17} />
                </span>
                <span className="card-title-text">{t('คัดกรองข้อมูล')}</span>
              </>
            }
          >
            <Form form={form} name={formName} layout="vertical">
              <Row gutter={[16, 8]}>
                <Col {...ResponsiveCol}>
                  <Form.Item
                    name="dateStart"
                    rules={[{ required: true, message: t('กรุณาเลือกวันที่เริ่มต้น') }]}
                    label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        await setIsMinDate(data)
                        await form.setFieldsValue({ dateStart: data })
                        await setfilterData({ ...filterData, dateStart: data })
                      }}
                      onClear={() => {
                        setIsMinDate(null)
                        form.setFieldsValue({ dateStart: null })
                        setfilterData({ ...filterData, dateStart: null })
                      }}
                      isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                      selectsStart={true}
                      autoComplete="off"
                      placeholder={t('เลือกวันที่เริ่มต้น')}
                    />
                  </Form.Item>
                </Col>
                <Col {...ResponsiveCol} offset={8}>
                  <Form.Item
                    name="organizationCode"
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยรับตรวจ')}</LabelNew>}
                  >
                    <SelectOrganization
                      formname={formName}
                      placeholder={t('เลือกหน่วยรับตรวจ')}
                      filters={{ isActive: 1, organizationLevelCode: 40 }}
                      handleChange={(data) => {
                        form.setFieldsValue({ organizationCode: data?.value })
                        setfilterData({ ...filterData, organizationCode: data?.value })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col {...ResponsiveCol}>
                  <Form.Item
                    name="dateEnd"
                    rules={[{ required: true, message: t('กรุณาเลือกวันที่สิ้นสุด') }]}
                    label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        await setIsMaxDate(data)
                        await form.setFieldsValue({ dateEnd: data })
                        await setfilterData({ ...filterData, dateEnd: data })
                      }}
                      onClear={() => {
                        setIsMaxDate(null)
                        setfilterData({ ...filterData, dateEnd: null })
                        form.setFieldsValue({ dateEnd: null })
                      }}
                      isMinDate={isMinDate ? new Date(isMinDate) : null}
                      selectsEnd={true}
                      placeholder={t('เลือกวันที่สิ้นสุด')}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </FilterStyled>
      </Col>
    </Row>
  )
}

StatusCauseAndIssueDetectedFilter.propTypes = {
  getValueFn: PropTypes.func.isRequired,
}

export default StatusCauseAndIssueDetectedFilter
