import { gql } from '@apollo/client'

export const MASTER_SET_EMAIL_SAVE = gql`
  mutation MasterSetEmailSave($input: EmalSendSetUpFormInput!) {
    masterSetEmailSave(input: $input) {
      setMailId
    }
  }
`

export const MASTER_SET_EMAIL_DELETE = gql`
  mutation MasterSetEmailDelete($setMailId: String!) {
    masterSetEmailDelete(setMailId: $setMailId) {
      setMailId
    }
  }
`
