import React, { useState, useEffect } from 'react'
import { Row, Col, Space, Form, Input, Cascader, TreeSelect, Switch } from 'antd'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { ModalNew } from '../../../components/modal/Modal'
import { ButtonNew } from '../../../components/button/Button'
import { InputNew, InputNumberNew } from '../../../components/input/Input'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { Select } from '../../../components/select/Select'
import { Checkbox } from '../../../components/checkbox/Checkbox'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
// import { multipleRowCode } from '../form/text'

const dataSelect = [
  { label: 'Jack', value: '01' },
  { label: 'Lucy', value: '02' },
  { label: 'Adam', value: '03' },
  { label: 'test4', value: '04', disabled: true }, // option disable
  { label: 'test5', value: '05' },
  { label: 'test6', value: '06' },
]

//  type="big"
//  type="medium"
function ModalIndex(props) {
  let code = ` import { ModalNew } from '../../../components/modal/Modal'
 import { ButtonNew } from '../../../components/button/Button'

    const [visible, setVisible] = useState(false)
    const [visible3, setVisible3] = useState(false)
    const [visible4, setVisible4] = useState(false)
    const [form] = Form.useForm()
    const [form2] = Form.useForm()
    const [form3] = Form.useForm()
    const handleSubmit = (values) => {
      console.log(values)
    }
    
    export default function App() {
        return ( 
            <>
              <ButtonNew type="primary" onClick={() => setVisible(true)}>
                OpenModal
              </ButtonNew>
              <Row justify="center" align="bottom" style={{ height: '100%' }}>
                <Col span={24}>
                  <Row style={{ marginTop: 24 }}>
                    <Col md={24} lg={24}>
                      <Form
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        onValuesChange={onValuesChange}
                        autoComplete="off"
                        labelAlign="left"
                        labelCol={{
                          span: 5,
                          offset: 6,
                        }}
                        wrapperCol={{
                          span: 6,
                        }}
                        layout="horizontal"
                        name="exampleForm"
                      >
                        <ModalNew
                          visible={visible}
                          onSubmit={form.submit}
                          onClose={setVisible}
                          testTitle="คลังขั้นตอนการปฎิบัติงาน"
                          // type ="medium"
                          // type="big"
                        >
                          <Form.Item label="Form type" name="formType">
                            <RadioGroup>
                              <Radio value="CREATE">CREATE</Radio>
                              <Radio value="UPDATE">UPDATE</Radio>
                            </RadioGroup>
                          </Form.Item>
                          <Form.Item
                            name="textInput"
                            label="Text input"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <InputNew />
                          </Form.Item>
                          <Form.Item name="select" label="Select" rules={[{ required: true, message: 'required' }]}>
                            <Select
                              title=""
                              data={dataSelect}
                              placeholder="default select..."
                              onChange={(val) => console.log(val)}
                              scrollableId="exampleForm"
                            />
                          </Form.Item>
                          <Form.Item
                            name="selectMultiple"
                            label="Select Multiple"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Select
                              title={''}
                              data={dataSelect}
                              placeholder="multiple select..."
                              multiple
                              onChange={(val) => console.log(val)}
                              scrollableId="exampleForm"
                            />
                          </Form.Item>
                          <Form.Item
                            name="treeSelect"
                            label="TreeSelect"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <TreeSelect
                              treeData={[
                                {
                                  title: 'Light',
                                  value: 'light',
                                  children: [
                                    {
                                      title: 'Bamboo',
                                      value: 'bamboo',
                                    },
                                  ],
                                },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item name="cascader" label="Cascader" rules={[{ required: true, message: 'required' }]}>
                            <Cascader
                              options={[
                                {
                                  value: 'zhejiang',
                                  label: 'Zhejiang',
                                  children: [
                                    {
                                      value: 'hangzhou',
                                      label: 'Hangzhou',
                                    },
                                  ],
                                },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item
                            hidden={true}
                            label="DatePicker"
                            name="datePicker"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name="datePickerMockUp"
                            label="DatePicker"
                            initialValue={'datePickerMockUp'}
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <>
                              <Datepicker
                                language="th"
                                setValueDateFn={(data) => {
                                  form.setFieldsValue({
                                    datePicker: data,
                                  })
                                  setErrorField('datePickerMockUp', false) // set false error field
                                }}
                              />
                            </>
                          </Form.Item>
                          <Form.Item
                            name="inputNumber"
                            label="InputNumber"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <InputNumberNew min={1} max={10} />
                          </Form.Item>
                          <Form.Item
                            label="Switch"
                            name="switch"
                            valuePropName="checked"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Switch />
                          </Form.Item>
                          <Form.Item
                            label="checkbox"
                            name="checkbox"
                            valuePropName="checked"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Checkbox onChange={(val) => console.log(val)}>Remember me</Checkbox>
                          </Form.Item>
                        </ModalNew>
                      </Form>
                      <Row></Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
        )
    }
`
  // const { menu } = props
  const [visible, setVisible] = useState(false)
  const [visible3, setVisible3] = useState(false)
  const [visible4, setVisible4] = useState(false)

  const formId = props.formId || null // Get id form parent element
  const [formType, setFormType] = useState(formId ? 'UPDATE' : 'CREATE') // Intitial value formType
  const [form] = Form.useForm()
  // const handleSubmit = (values) => {
  //   console.log(values)
  // }

  useEffect(() => {
    // Get value From your API
    if (formType === 'UPDATE') {
      // Set Form value on formType = UPDATE
      form.setFieldsValue({
        textInput: 'Is update',
        select: 'demo',
        datePicker: '2020-08-12',
        treeSelect: {
          value: 'bamboo',
        },
        selectMultiple: ['02', '05'],
        cascader: ['zhejiang', 'hangzhou'],
        inputNumber: 100,
        switch: true,
        checkbox: true,
      })
    } else if (formType === 'CREATE') {
      // Set Form value on formType = CREATE
      form.resetFields()
    }
  }, [formType])

  // onFinish
  const onFinish = (values) => {
    console.log('onFinish:', values)
    setErrorField('datePickerMockUp', false) // Set false error field
  }

  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => {
    console.log('onFinishFailed', errorFields)
    errorFields.map((er) => {
      const nameField = er.name[0] || ''
      const message = er.errors[0] || ''
      if (nameField === 'datePicker') setErrorField('datePickerMockUp', message)
    })
  }

  // onValuesChange
  const onValuesChange = ({ formType }, allValue) => {
    console.log('onValuesChange allValue', allValue)
    setFormType(formType) // CREATE or UPDATE *** intitial value = CREATE
  }

  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }
  return (
    <React.Fragment>
      <div className="site-layout-background">
        <Row>
          <Col span={2}></Col>
          <Col span={19}>
            <Row>
              <Space size={[8, 8]}>
                <ButtonNew type="primary" onClick={() => setVisible(true)}>
                  OpenModal
                </ButtonNew>
                <ButtonNew type="primary" onClick={() => setVisible3(true)}>
                  OpenModal medium
                </ButtonNew>
                <ButtonNew type="primary" onClick={() => setVisible4(true)}>
                  OpenModal big
                </ButtonNew>
              </Space>
            </Row>
            <div style={{ marginBottom: '15px' }}>
              <Row justify="center" align="bottom" style={{ height: '100%' }}>
                <Col span={24}>
                  <Row style={{ marginTop: 24 }}>
                    <Col md={24} lg={24}>
                      <Form
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        onValuesChange={onValuesChange}
                        autoComplete="off"
                        labelAlign="left"
                        labelCol={{
                          span: 5,
                          offset: 6,
                        }}
                        wrapperCol={{
                          span: 6,
                        }}
                        layout="horizontal"
                        name="exampleForm"
                      >
                        <ModalNew
                          visible={visible}
                          onSubmit={form.submit}
                          onClose={setVisible}
                          testTitle="คลังขั้นตอนการปฎิบัติงาน"
                        >
                          <Form.Item label="Form type" name="formType">
                            <RadioGroup>
                              <Radio value="CREATE">CREATE</Radio>
                              <Radio value="UPDATE">UPDATE</Radio>
                            </RadioGroup>
                          </Form.Item>
                          <Form.Item
                            name="textInput"
                            label="Text input"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <InputNew />
                          </Form.Item>
                          <Form.Item name="select" label="Select" rules={[{ required: true, message: 'required' }]}>
                            <Select
                              title=""
                              data={dataSelect}
                              placeholder="default select..."
                              onChange={(val) => console.log(val)}
                              scrollableId="exampleForm"
                            />
                          </Form.Item>
                          <Form.Item
                            name="selectMultiple"
                            label="Select Multiple"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Select
                              title={''}
                              data={dataSelect}
                              placeholder="multiple select..."
                              multiple
                              onChange={(val) => console.log(val)}
                              scrollableId="exampleForm"
                            />
                          </Form.Item>
                          <Form.Item
                            name="treeSelect"
                            label="TreeSelect"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <TreeSelect
                              treeData={[
                                {
                                  title: 'Light',
                                  value: 'light',
                                  children: [
                                    {
                                      title: 'Bamboo',
                                      value: 'bamboo',
                                    },
                                  ],
                                },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item name="cascader" label="Cascader" rules={[{ required: true, message: 'required' }]}>
                            <Cascader
                              options={[
                                {
                                  value: 'zhejiang',
                                  label: 'Zhejiang',
                                  children: [
                                    {
                                      value: 'hangzhou',
                                      label: 'Hangzhou',
                                    },
                                  ],
                                },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item
                            hidden={true}
                            label="DatePicker"
                            name="datePicker"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name="datePickerMockUp"
                            label="DatePicker"
                            initialValue={'datePickerMockUp'}
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <>
                              <Datepicker
                                language="th"
                                setValueDateFn={(data) => {
                                  form.setFieldsValue({
                                    datePicker: data,
                                  })
                                  setErrorField('datePickerMockUp', false) // set false error field
                                }}
                              />
                            </>
                          </Form.Item>
                          <Form.Item
                            name="inputNumber"
                            label="InputNumber"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <InputNumberNew min={1} max={10} />
                          </Form.Item>
                          <Form.Item
                            label="Switch"
                            name="switch"
                            valuePropName="checked"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Switch />
                          </Form.Item>
                          <Form.Item
                            label="checkbox"
                            name="checkbox"
                            valuePropName="checked"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Checkbox onChange={(val) => console.log(val)}>Remember me</Checkbox>
                          </Form.Item>
                        </ModalNew>
                      </Form>
                      <Row></Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row justify="center" align="bottom" style={{ height: '100%' }}>
                <Col span={24}>
                  <Row style={{ marginTop: 24 }}>
                    <Col md={24} lg={24}>
                      <Form
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        onValuesChange={onValuesChange}
                        autoComplete="off"
                        labelAlign="left"
                        labelCol={{
                          span: 5,
                          offset: 6,
                        }}
                        wrapperCol={{
                          span: 6,
                        }}
                        layout="horizontal"
                        name="exampleForm"
                      >
                        <ModalNew
                          visible={visible3}
                          onSubmit={form.submit}
                          onClose={setVisible3}
                          type="medium"
                          testTitle="คลังขั้นตอนการปฎิบัติงาน"
                        >
                          <Form.Item label="Form type" name="formType">
                            <RadioGroup>
                              <Radio value="CREATE">CREATE</Radio>
                              <Radio value="UPDATE">UPDATE</Radio>
                            </RadioGroup>
                          </Form.Item>
                          <Form.Item
                            name="textInput"
                            label="Text input"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <InputNew />
                          </Form.Item>
                          <Form.Item name="select" label="Select" rules={[{ required: true, message: 'required' }]}>
                            <Select
                              title=""
                              data={dataSelect}
                              placeholder="default select..."
                              onChange={(val) => console.log(val)}
                              scrollableId="exampleForm"
                            />
                          </Form.Item>
                          <Form.Item
                            name="selectMultiple"
                            label="Select Multiple"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Select
                              title={''}
                              data={dataSelect}
                              placeholder="multiple select..."
                              multiple
                              onChange={(val) => console.log(val)}
                              scrollableId="exampleForm"
                            />
                          </Form.Item>
                          <Form.Item
                            name="treeSelect"
                            label="TreeSelect"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <TreeSelect
                              treeData={[
                                {
                                  title: 'Light',
                                  value: 'light',
                                  children: [
                                    {
                                      title: 'Bamboo',
                                      value: 'bamboo',
                                    },
                                  ],
                                },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item name="cascader" label="Cascader" rules={[{ required: true, message: 'required' }]}>
                            <Cascader
                              options={[
                                {
                                  value: 'zhejiang',
                                  label: 'Zhejiang',
                                  children: [
                                    {
                                      value: 'hangzhou',
                                      label: 'Hangzhou',
                                    },
                                  ],
                                },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item
                            hidden={true}
                            label="DatePicker"
                            name="datePicker"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name="datePickerMockUp"
                            label="DatePicker"
                            initialValue={'datePickerMockUp'}
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <>
                              <Datepicker
                                language="th"
                                setValueDateFn={(data) => {
                                  form.setFieldsValue({
                                    datePicker: data,
                                  })
                                  setErrorField('datePickerMockUp', false) // set false error field
                                }}
                              />
                            </>
                          </Form.Item>
                          <Form.Item
                            name="inputNumber"
                            label="InputNumber"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <InputNumberNew min={1} max={10} />
                          </Form.Item>
                          <Form.Item
                            label="Switch"
                            name="switch"
                            valuePropName="checked"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Switch />
                          </Form.Item>
                          <Form.Item
                            label="checkbox"
                            name="checkbox"
                            valuePropName="checked"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Checkbox onChange={(val) => console.log(val)}>Remember me</Checkbox>
                          </Form.Item>
                        </ModalNew>
                      </Form>
                      <Row></Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row justify="center" align="bottom" style={{ height: '100%' }}>
                <Col span={24}>
                  <Row style={{ marginTop: 24 }}>
                    <Col md={24} lg={24}>
                      <Form
                        form={form}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        onValuesChange={onValuesChange}
                        autoComplete="off"
                        labelAlign="left"
                        labelCol={{
                          span: 5,
                          offset: 6,
                        }}
                        wrapperCol={{
                          span: 6,
                        }}
                        layout="horizontal"
                        name="exampleForm"
                      >
                        <ModalNew
                          visible={visible4}
                          onSubmit={form.submit}
                          onClose={setVisible4}
                          type="big"
                          testTitle="คลังขั้นตอนการปฎิบัติงาน"
                        >
                          <Form.Item label="Form type" name="formType">
                            <RadioGroup>
                              <Radio value="CREATE">CREATE</Radio>
                              <Radio value="UPDATE">UPDATE</Radio>
                            </RadioGroup>
                          </Form.Item>
                          <Form.Item
                            name="textInput"
                            label="Text input"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <InputNew />
                          </Form.Item>
                          <Form.Item name="select" label="Select" rules={[{ required: true, message: 'required' }]}>
                            <Select
                              title=""
                              data={dataSelect}
                              placeholder="default select..."
                              onChange={(val) => console.log(val)}
                              scrollableId="exampleForm"
                            />
                          </Form.Item>
                          <Form.Item
                            name="selectMultiple"
                            label="Select Multiple"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Select
                              title={''}
                              data={dataSelect}
                              placeholder="multiple select..."
                              multiple
                              onChange={(val) => console.log(val)}
                              scrollableId="exampleForm"
                            />
                          </Form.Item>
                          <Form.Item
                            name="treeSelect"
                            label="TreeSelect"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <TreeSelect
                              treeData={[
                                {
                                  title: 'Light',
                                  value: 'light',
                                  children: [
                                    {
                                      title: 'Bamboo',
                                      value: 'bamboo',
                                    },
                                  ],
                                },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item name="cascader" label="Cascader" rules={[{ required: true, message: 'required' }]}>
                            <Cascader
                              options={[
                                {
                                  value: 'zhejiang',
                                  label: 'Zhejiang',
                                  children: [
                                    {
                                      value: 'hangzhou',
                                      label: 'Hangzhou',
                                    },
                                  ],
                                },
                              ]}
                            />
                          </Form.Item>
                          <Form.Item
                            hidden={true}
                            label="DatePicker"
                            name="datePicker"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Input />
                          </Form.Item>
                          <Form.Item
                            name="datePickerMockUp"
                            label="DatePicker"
                            initialValue={'datePickerMockUp'}
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <>
                              <Datepicker
                                language="th"
                                setValueDateFn={(data) => {
                                  form.setFieldsValue({
                                    datePicker: data,
                                  })
                                  setErrorField('datePickerMockUp', false) // set false error field
                                }}
                              />
                            </>
                          </Form.Item>
                          <Form.Item
                            name="inputNumber"
                            label="InputNumber"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <InputNumberNew min={1} max={10} />
                          </Form.Item>
                          <Form.Item
                            label="Switch"
                            name="switch"
                            valuePropName="checked"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Switch />
                          </Form.Item>
                          <Form.Item
                            label="checkbox"
                            name="checkbox"
                            valuePropName="checked"
                            rules={[{ required: true, message: 'required' }]}
                          >
                            <Checkbox onChange={(val) => console.log(val)}>Remember me</Checkbox>
                          </Form.Item>
                        </ModalNew>
                      </Form>
                      <Row></Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <CodeBlock code={code} />
      </div>
    </React.Fragment>
  )
}

export default ModalIndex
