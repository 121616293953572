import { useLazyQuery } from '@apollo/client'
import { Col, Row, Tooltip } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  TimelineMarkers,
  CustomMarker,
} from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { Alert } from '../../../components/alert/Alert'
import { GroupLeft, SideBarHeadDiv, TimeLineStyle } from '../../../components/genttchart/GanttChartStyles'
import { LabelNew } from '../../../components/label/Label'
import { PaginationComp } from '../../../components/pagination/Pagination'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayError, displayText } from '../../../utilitys/helper'
import { GET_LIST_ACTION_PLAN_CALENDAR_PAGINATION, GET_HOLIDAY, GET_MEETINGDAY } from './graphql/Query'
import { useDecodeUser } from '../../../hooks/useDecodeUser'

const Responsive = {
  md: 24,
  lg: 24,
}

const ActionPlanCalendarList = ({ activeKey = '2' }) => {
  if (activeKey !== '2') return <></>

  const color = {
    graphHeaderMonthNameBackground: '#C1C8C7',
    graphHeaderMonthNameText: '#FFFFFF',
    graphHeaderDatehNameBackground: '#FFFFFF',
    graphHeaderDatehNameText: '#000000',
    graphCurrentDateBackground: '#B2B2B2',
    graphWeekendDateBackground: '#e8d595',
    graphHolidayBackground: '#FDFFBC',
    graphMeetingBackground: '#FFFDDD',
    statusNoapprove: '#C1C8C7',
    statusNoActionPlan: '#FAE0D8',
    statusActionPlanDontApprove: '#f7d4de',
    statusWaitForProcess: '#a0c5e2',
    statusProcessing: '#2676bb',
    statusProcessed: '#54a648',
    statusLate: '#C43D56',
  }

  const { t } = useTranslation()
  const [user] = useDecodeUser()
  const navigate = useNavigate()

  const [paginate, setPaginate] = useState({ page: 1, pageSize: 10 })
  const [sort] = useState([{ fieldSort: 'projectCode', sortType: 'ASC' }])

  const [
    filters,
    //  setfilters
  ] = useState({})
  const [count, setcount] = useState(0)

  useEffect(() => {
    if (sort && paginate && filters && user) fncGetList()
  }, [paginate.page, paginate.limit, sort, filters, user])

  const [callList, { loading: loadingList, error: errorList, data: dataList }] = useLazyQuery(
    GET_LIST_ACTION_PLAN_CALENDAR_PAGINATION,
  )

  useEffect(() => {
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorList)),
      })
    }
  }, [errorList])

  useEffect(() => {
    if (dataList) {
      const { count, result } = dataList.getListActionPlanCalendarPagination
      setcount(count)

      if (_.isEmpty(result)) {
        setitems([])
        setgroups([])
        return null
      }

      //   let noSub = ''
      const groupTemp = result.map((item) => {
        return {
          ...item,
          id: item.actionPlanId,
          title: item.projectCode + ' : ' + displayText(item.projectNameTH, item.projectNameEN),
          stackItems: true,
          height: 60,
          dateStart: dateDisplay(item.dateStart),
          dateEnd: dateDisplay(item.dateEnd),
        }
      })

      const newResult = result.map((item) => {
        const auditJobSteps = item.auditJobSteps
        return {
          ...item,
          total: auditJobSteps.length,
          status10: auditJobSteps.filter((data) => {
            return data.operationStatusCode === '10'
          }).length,
          status20: auditJobSteps.filter((data) => {
            return data.operationStatusCode === '20' || data.operationStatusCode === '21'
          }).length,
          status30: auditJobSteps.filter((data) => {
            return data.operationStatusCode === '30' || data.operationStatusCode === '31'
          }).length,
        }
      })

      const itemTemp = newResult
        .map((item) => {
          const dateStart = new Date(item.dateStart)
          const dateEnd = new Date(item.dateEnd)
          const start_time = moment(
            dateStart.getFullYear() + '-' + (dateStart.getMonth() + 1) + '-' + dateStart.getDate() + ' 00:00',
          )
          const end_time = moment(
            dateEnd.getFullYear() + '-' + (dateEnd.getMonth() + 1) + '-' + dateEnd.getDate() + ' 23:59',
          )

          const title = {
            late: false,
            width: '100%',
          }

          return {
            id: item.actionPlanId,
            group: item.actionPlanId,
            title: title,
            start_time,
            end_time,
            ...item,
            canMove: false,
            canResize: false,
            canChangeGroup: false,
          }
        })
        .concat(
          newResult
            .filter((item) => {
              return (
                item.auditJobSteps.filter((data) => {
                  return data.operationStatusCode !== '30' && data.operationStatusCode !== '31'
                }).length > 0 && moment(item.dateEnd) < moment(new Date())
              )
            })
            .map((data) => {
              let dateStart = new Date(moment(data.dateEnd).add(1, 'days'))
              let dateEnd = new Date()
              return {
                id: data.actionPlanId + '_late',
                group: data.actionPlanId,
                title: { late: true },
                start_time: moment(
                  dateStart.getFullYear() + '-' + (dateStart.getMonth() + 1) + '-' + dateStart.getDate() + ' 00:00',
                ),
                end_time: moment(
                  dateEnd.getFullYear() + '-' + (dateEnd.getMonth() + 1) + '-' + dateEnd.getDate() + ' 23:59',
                ),
                canMove: false,
                canResize: false,
                canChangeGroup: false,
              }
            }),
        )

      setgroups(groupTemp)
      setitems(itemTemp)
    }
  }, [dataList])

  const fncGetList = () => {
    const dataInput = {
      sort: sort,
      filters: { ...filters, personnelCode: user.pCode },
      pagination: {
        page: paginate.page,
        limit: paginate.pageSize,
      },
    }
    callList({
      variables: { input: encryptInput(dataInput) },
    })
  }

  useEffect(() => {
    callHoliday()
    callMeeting()
  }, [])

  const [callHoliday, { data: dataHoliday, error: errorHoliday, loading: loadingHoliday }] = useLazyQuery(GET_HOLIDAY)

  useEffect(() => {
    if (errorHoliday) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorHoliday)),
      })
    }
  }, [errorHoliday])

  useEffect(() => {
    if (dataHoliday) {
      let response = dataHoliday.auditProjectGetCalendarDetailsType1
      setHoliday(
        response.map((data) => {
          return moment(new Date(moment(data.dateAt).format('YYYY-MM-DD') + ' 00:00'))
        }),
      )
    }
  }, [dataHoliday])

  const [callMeeting, { data: dataMeeting, error: errorMeeting, loading: loadingMeeting }] =
    useLazyQuery(GET_MEETINGDAY)

  useEffect(() => {
    if (errorMeeting) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(errorMeeting.message),
      })
    }
  }, [errorMeeting])

  useEffect(() => {
    if (dataMeeting) {
      let response = dataMeeting.auditProjectGetCalendarDetailsType2
      setMeeting(
        response.map((data) => {
          return moment(new Date(moment(data.dateAt).format('YYYY-MM-DD') + ' 00:00'))
        }),
      )
    }
  }, [dataMeeting])

  const [groups, setgroups] = useState([])
  const [items, setitems] = useState([])
  const [holiday, setHoliday] = useState([])
  const [meeting, setMeeting] = useState([])
  const defaultTimeStart = moment().startOf('month').toDate()
  const defaultTimeEnd = moment().startOf('month').add(1, 'month').toDate()
  const defaultTimeRange = defaultTimeEnd - defaultTimeStart

  const groupRenderer = ({ group }) => {
    return (
      <Tooltip placement="top" title={group.title}>
        <GroupLeft
          onClick={() => {
            navigate('/auditoperations/actionplan/form', {
              state: {
                refCode: group.actionPlanId,
                type_action: 'view',
                defaultActiveKey: '3',
                projectCode: group.projectCode,
                auditTeamCode: group.auditTeamCode,
              },
            })
          }}
        >
          <Row>
            <Col md={24} style={{ height: '30px', marginTop: '-10px' }}>
              <span>{group.title}</span>
            </Col>

            <Col md={24} style={{ height: '30px', marginTop: '-10px' }}>
              <span style={{ color: color.graphHeaderMonthNameBackground, fontSize: '14px' }}>
                {group.dateStart + ' - ' + group.dateEnd}
              </span>
            </Col>
          </Row>
        </GroupLeft>
      </Tooltip>
    )
  }

  const itemRenderer = ({ item, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()

    const propsTop = getItemProps(item.itemProps)

    propsTop.style =
      item.title.late === false
        ? {
            ...propsTop.style,
            color: 'white',
            background: color.statusNoapprove,
            border: `1px solid ${color.statusNoapprove}`,
            height: '30px',
          }
        : {
            ...propsTop.style,
            color: 'white',
            background: color.statusLate,
            border: `1px solid ${color.statusLate}`,
            height: '30px',
          }

    const status10 = Math.floor(item.total ? (item.status10 / item.total) * 100 : 0)

    const status20 = Math.floor(item.total ? (item.status20 / item.total) * 100 : 0)

    const status30 = Math.floor(item.total ? (item.status30 / item.total) * 100 : 0)

    return (
      <>
        <div {...propsTop}>
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}

          {item.title.late === false && item.status10 !== 0 ? (
            <div
              className="rct-item-content"
              style={{
                maxHeight: `${itemContext.dimensions.height}`,
                background: color.statusWaitForProcess,
                width: `${status10}%`,
              }}
            >
              {status10}%
            </div>
          ) : (
            ''
          )}
          {item.title.late === false && item.status20 !== 0 ? (
            <div
              className="rct-item-content"
              style={{
                maxHeight: `${itemContext.dimensions.height}`,
                background: color.statusProcessing,
                width: `${status20}%`,
              }}
            >
              {status20}%
            </div>
          ) : (
            ''
          )}
          {item.title.late === false && item.status30 !== 0 ? (
            <div
              className="rct-item-content"
              style={{
                maxHeight: `${itemContext.dimensions.height}`,
                background: color.statusProcessed,
                width: `${status30}%`,
              }}
            >
              {status30}%
            </div>
          ) : (
            ''
          )}
          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
        </div>
      </>
    )
  }

  const onChangePagination = (page, pageSize) => setPaginate({ page, pageSize })

  return (
    <React.Fragment>
      {(loadingHoliday || loadingList || loadingMeeting) && <SpinnersNew />}
      <Row>
        <Col {...Responsive}>
          {groups.length !== 0 && items.length !== 0 && (
            <TimeLineStyle height={groups.length > 10 ? '665px' : 'auto'}>
              <Timeline
                groups={groups}
                items={items}
                minZoom={defaultTimeRange}
                maxZoom={defaultTimeRange}
                defaultTimeStart={defaultTimeStart}
                defaultTimeEnd={defaultTimeEnd}
                groupRenderer={groupRenderer}
                itemRenderer={itemRenderer}
                sidebarWidth={450}
                key={{
                  groupIdKey: 'id',
                  groupTitleKey: 'title',
                  groupRightTitleKey: 'rightTitle',
                  itemIdKey: 'id',
                  itemTitleKey: 'title', // key for item div content
                  itemDivTitleKey: 'title', // key for item div title (<div title="text"/>)
                  itemGroupKey: 'group',
                  itemTimeStartKey: 'start_time',
                  itemTimeEndKey: 'end_time',
                }}
              >
                <TimelineHeaders>
                  <SidebarHeader>
                    {({ getRootProps }) => {
                      return (
                        <SideBarHeadDiv {...getRootProps()}>
                          <Row justify="space-between" align="middle" style={{ height: '100%' }}>
                            <Col>{t('ชื่องานตรวจสอบ')}</Col>
                          </Row>
                        </SideBarHeadDiv>
                      )
                    }}
                  </SidebarHeader>
                  <DateHeader
                    unit="month"
                    labelFormat="MM YYYY"
                    style={{ height: 50, color: '#FFFFFF', backgroundColor: '#C1C8C7', pointerEvents: 'none' }}
                    intervalRenderer={({ getIntervalProps, intervalContext }) => {
                      const monthSTR = intervalContext.intervalText.split(' ')
                      const month =
                        monthSTR[0] === '01'
                          ? t('มกราคม')
                          : monthSTR[0] === '02'
                          ? t('กุมภาพันธ์')
                          : monthSTR[0] === '03'
                          ? t('มีนาคม')
                          : monthSTR[0] === '04'
                          ? t('เมษายน')
                          : monthSTR[0] === '05'
                          ? t('พฤษภาคม')
                          : monthSTR[0] === '06'
                          ? t('มิถุนายน')
                          : monthSTR[0] === '07'
                          ? t('กรกฎาคม')
                          : monthSTR[0] === '08'
                          ? t('สิงหาคม')
                          : monthSTR[0] === '09'
                          ? t('กันยายน')
                          : monthSTR[0] === '10'
                          ? t('ตุลาคม')
                          : monthSTR[0] === '11'
                          ? t('พฤศจิกายน')
                          : monthSTR[0] === '12'
                          ? t('ธันวาคม')
                          : ''
                      const year = localStorage.getItem('lang') === 'th' ? parseInt(monthSTR[1]) + 543 : monthSTR[1]
                      return (
                        <div justify="center" align="middle" {...getIntervalProps()}>
                          <div style={{ border: '1px solid #bbb' }}>{month + ' ' + year}</div>
                        </div>
                      )
                    }}
                  />
                  <DateHeader
                    unit="day"
                    labelFormat="D"
                    style={{ height: 50, color: '#000000', backgroundColor: '#FFFFFF', pointerEvents: 'none' }}
                    intervalRenderer={({ getIntervalProps, intervalContext }) => {
                      return (
                        <div justify="center" align="middle" {...getIntervalProps()}>
                          <div style={{ border: '1px solid #bbb', borderTop: '0px', paddingBottom: '5px' }}>
                            {intervalContext.intervalText}
                          </div>
                        </div>
                      )
                    }}
                  />
                </TimelineHeaders>
                <TimelineMarkers>
                  <CustomMarker date={new Date(moment().format('YYYY-MM-DD') + ' 00:00').getTime()}>
                    {({ styles }) => {
                      const customStyles = {
                        ...styles,
                        backgroundColor: color.graphWeekendDateBackground,
                        width: '3.325%',
                      }
                      return <div style={customStyles} />
                    }}
                  </CustomMarker>
                  {holiday.map((data, i) => {
                    const dateData = new Date(data)
                    return dateData.getDay() !== 0 && dateData.getDay() !== 6 ? (
                      <CustomMarker date={new Date(moment(data).format('YYYY-MM-DD') + ' 00:00').getTime()} key={i}>
                        {({ styles }) => {
                          const customStyles = {
                            ...styles,
                            backgroundColor: color.graphHolidayBackground,
                            width: '3.325%',
                          }
                          return <div style={customStyles} />
                        }}
                      </CustomMarker>
                    ) : (
                      ''
                    )
                  })}
                  {meeting.map((data, i) => {
                    const dateData = new Date(data)
                    return dateData.getDay() !== 0 && dateData.getDay() !== 6 ? (
                      <CustomMarker date={new Date(moment(data).format('YYYY-MM-DD') + ' 00:00').getTime()} key={i}>
                        {({ styles }) => {
                          const customStyles = {
                            ...styles,
                            backgroundColor: color.graphMeetingBackground,
                            width: '3.325%',
                          }
                          return <div style={customStyles} />
                        }}
                      </CustomMarker>
                    ) : (
                      ''
                    )
                  })}
                </TimelineMarkers>
              </Timeline>
            </TimeLineStyle>
          )}
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: '100px' }}>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                backgroundColor: color.statusWaitForProcess,
                width: '20px',
                height: '20px',
                marginRight: '20px',
              }}
            ></div>
            <div>
              <LabelNew type="tab-header-inactive">{t('รอดำเนินการ')}</LabelNew>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusProcessing, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <LabelNew type="tab-header-inactive">{t('อยู่ระหว่างดำเนินการ')}</LabelNew>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusProcessed, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <LabelNew type="tab-header-inactive">{t('ดำเนินการแล้วเสร็จ')}</LabelNew>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusLate, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <LabelNew type="tab-header-inactive">{t('ล่าช้ากว่ากำหนด')}</LabelNew>
            </div>
          </div>
        </Col>

        <Col md={6}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <PaginationComp
              total={count}
              onChangePagination={onChangePagination}
              defaultCurrent={1}
              pageSize={paginate.pageSize}
              page={paginate.page}
            />
          </div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ActionPlanCalendarList
