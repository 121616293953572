export const colors = {
  primary: '#2b4990',
  primaryActive: '#2B66A2',
  primaryHover: '#2a51af',
  primaryHover2: '#e9edf8',
  link: '#2b4990',
  linkHover: '#2b4990',
  success: '#45A648',
  successHover: '#47b053',
  successHover2: '#EDF9F5',
  warning: '#ff9e2c',
  warningHover: '#fff9f4',
  info: '#1890ff',
  blue: '#1DA2C7',
  orange: '#F59D19',
  yellow: '#FFE60A',
  lightOrange: '#FFE0C3',
  lightOrangeHover: '#FFcc9c',
  lightOrange2: '#FF9630',
  lightBlue: '#E0ECF8',
  lightBlueHover: '#d0e2f8',
  drakGreen: '#54a648',
  lightGreen: '#eafce5',
  lightGreenHover: '#d0f7c5',
  lightGreenActive: '#bbf7ab',
  lime: '#E4F519',
  error: '#EA5456',
  erroHover: '#ec6567',
  btnDanger: '#f5222d',
  btnDangerHover: '#fef7f8',
  danger: '#F24A4A',
  dangerHover: '#F24A4A',
  textDefault: '#333333',
  lightGray: '#E5E5E5',
  lightGrayHover: '#dbdbdb',
  lightGray2: '#666666',
  lightGray3: '#EEEEEE',
  lightGray3Hover: '#e4e4e4',
  black: '#333333',
  blackHover: '#787878',
  white: '#fff',
  green: '#96C43C',
  successApprove1: '#6BCA21',
  successApprove2: '#E1FACD',
  successApproveHover: '#D5F5E3',
  colorhover: '#e9e9e9',
}

export const layout = {
  layoutBg: '#fafafa',
  layoutMenuSelectedBg: 'linear-gradient(to right, #2b4b91, #1d9fc5)',
  layoutMenuSelectedText: '#fff',
  linearBgNotSupport: '#2b4b91',
  menuBg: '#f5f5f5',
  menuLabel: '#666666',
  menuSubmenuTitle: '#666666',
  menuSubmenuOpenBg: '#dfdfdf',
  menuSubmenuOpenText: '#666666',
  menuItemHover: '#e9e9e9',
  layoutSliderTriggerBg: '#e9e9e9',
  menuArrowBg: '#666666',
  menuArrowHover: '#2b4990',
}

export const fontSize = {
  text1: '32px',
  text2: '24px',
  text4: '20px',
  text5: '18px',
  textDefault: '16px',
}
