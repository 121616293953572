import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'key',
    // sorter: true,
    width: '8%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>รหัสความเสี่ยง</Trans>,
    dataIndex: 'riskLevelCode',
    width: '10%',
    className: 'text-nowrap',
    // sorter: true,
  },
  {
    title: <Trans>ชื่อระดับความเสี่ยงภาษาไทย</Trans>,
    dataIndex: 'riskLevelNameTH',
    width: '25%',
    // sorter: true,
  },
  {
    title: <Trans>ชื่อระดับความเสี่ยงภาษาอังกฤษ</Trans>,
    dataIndex: 'riskLevelNameEN',
    width: '25%',
    // sorter: true,
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActive',
    width: '10%',
    className: 'text-nowrap',
    // align: 'center',
    // sorter: true,
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '10%',
    align: 'right',
  },
]
