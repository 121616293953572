import { Row, Col, Form } from 'antd'
import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { Datatable } from '../../../../components/datatable/Datatable'
import { useTranslation } from 'react-i18next'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { ButtonNew } from '../../../../components/button/Button'
import { displayText } from '../../../../utilitys/helper'
import { ModalNew } from '../../../../components/modal/Modal'
import { useLazyQuery } from '@apollo/client'
import { GET_ORGANIZATION_PAGINATION } from '../graphql/Query'
import { EDIT_AUDIT_ISSUE } from '../graphql/Mutation'
import { useLocation, useNavigate } from 'react-router-dom'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import SelectOrganizationLevel from '../../../../components/inputfromapi/selectorganizationlevel/SelectOrganizationLevel'
import { LabelNew } from '../../../../components/label/Label'
import { InputNew } from '../../../../components/input/Input'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { Alert } from '../../../../components/alert/Alert'
import { encryptInput } from '../../../../utilitys/crypto'

const SoiAuditCommitteeTabIssueOrg = (props) => {
  const formName = 'formIssue'
  const mainPage = '/auditoperations/soiauditcommittee/view'
  const [form] = Form.useForm()
  const { state } = useLocation()
  const { action } = state
  const { t } = useTranslation()
  const [formFilter] = Form.useForm()
  const [modalOpen, setmodalOpen] = useState(false)

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const navigate = useNavigate()
  const [searchTrigger, setsearchTrigger] = useState(false)

  const [allOrgList, allOrgListData] = useLazyQuery(GET_ORGANIZATION_PAGINATION)
  const [orgForAddList, setOrgForAddList] = useState([])
  const [currentIssueOrg, setCurrentIssueOrg] = useState([])

  const [callEdit, editResponse] = useMutation(EDIT_AUDIT_ISSUE)
  const formItemResponsive = {
    sm: 24,
    md: 12,
    lg: 8,
  }
  const columns = [
    {
      title: t('รหัสหน่วยงาน'),
      dataIndex: 'organizationCode',
      width: '30%',
    },
    {
      title: t('ชื่อหน่วยงาน'),
      dataIndex: 'organizationName',
      width: '50%',
    },
    {
      title: '',
      width: '20%',
      render: (record) => {
        const listButton =
          state.action === 'view'
            ? []
            : [
                {
                  type: 'delete',
                  onClick: () => {
                    setCurrentIssueOrg(
                      currentIssueOrg.filter((item) => item.organizationCode !== record.organizationCode),
                    )
                  },
                },
              ]

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={props.menu} icons={listButton} />
          </div>
        )
      },
    },
  ]

  const alertSuccess = () => {
    Alert({
      type: 'success',
      title: t('บันทึกข้อมูลสำเร็จ'),
      onOk() {
        navigate(mainPage, {
          state: { ...state, action: 'edit' },
        })
      },
    })
  }

  const alertError = (result) => {
    Alert({
      type: 'error',
      title: t('บันทึกข้อมูลไม่สำเร็จ'),
      content: result?.errors[0].message,
      onOk() {},
    })
  }

  const onSubmit = () => {
    let input = {
      actionPlanId: state.actionPlanId,
      projectCode: state.projectCode,
      issueOrg: currentIssueOrg.map((x) => x.organizationCode),
    }

    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกหน่วยรับตรวจ'),
      onOk: async () => {
        if (state.action === 'edit') {
          input['issueId'] = state.issueId
          callEdit({
            variables: {
              input: encryptInput(input),
            },
          }).then((result) => {
            if (result?.data) {
              alertSuccess()
            } else {
              alertError(result)
            }
          })
        }
      },
      onCancel: () => null,
    })
  }

  useEffect(() => {
    if (props.getDataHandler.data) {
      const dataFromDb = props.getDataHandler.data.getAuditIssueById
      if (dataFromDb) {
        setCurrentIssueOrg(dataFromDb.auditIssueOrganizations)
      }
    }
  }, [props.getDataHandler.data])

  useEffect(() => {
    if (allOrgListData?.data) {
      const dataFromDb = allOrgListData.data.getOrganizationsPagination.result.map((item, index) => {
        let orgName = displayText(item.organizationNameTH, item.organizationNameEN).split(' : ')
        return {
          key: ++index,
          orgCode: item.organizationCode,
          orgName: orgName[1],
          orgLV: displayText(item.organizationLevelNameTH, item.organizationLevelNameEN),
          orgResponsible: `${
            displayText(item.responsiblePerson?.firstNameTH, item.responsiblePerson?.firstNameTH) || ''
          } ${displayText(item.responsiblePerson?.lastNameTH, item.responsiblePerson?.lastNameTH) || ''}`,
        }
      })
      setOrgForAddList(dataFromDb)
      setCount(allOrgListData.data.getOrganizationsPagination.count)
    }
  }, [allOrgListData.data])

  useEffect(() => {
    // ต้องดึงข้อมูลขณะที่ modalOpen === true เท่านั้น ไม่เช่นนั้น จะขึ้น Warning ใน Console เพราะ React หา Modal ไม่เจอ
    if (state.action !== 'view' && modalOpen === true) {
      const filters = formFilter.getFieldsValue(['organizationCode', 'organizationName', 'organizationLevel'])
      filters.issueOrgCodeList = currentIssueOrg.map((x) => x.organizationCode)

      allOrgList({
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            filters: filters,
            pagination: { limit, page },
            sort: [],
          },
        },
      })
    }
  }, [page, searchTrigger])

  return (
    <>
      {/* หน่วยรับตรวจปัจจุบัน */}
      {editResponse.loading && <SpinnersNew />}

      <Form
        name={formName}
        form={form}
        autoComplete="off"
        labelAlign="left"
        layout="horizontal"
        onFinish={onSubmit}
        labelCol={{ sm: 24, md: 4 }}
        wrapperCol={{ sm: 24, md: 20 }}
      >
        <Row>
          <Col md={24}>
            <Datatable
              columns={columns}
              data={currentIssueOrg.map((item, index) => {
                return {
                  key: ++index,
                  organizationCode: item.organizationCode,
                  organizationName: displayText(item.organizationNameTH, item.organizationNameEN),
                }
              })}
              btnAdd={
                state.action === 'view' ? null : (
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: props.menu }}
                    style={{ marginRight: '12px' }}
                    onClick={() => {
                      setmodalOpen(true)
                      formFilter.resetFields()
                      setsearchTrigger(!searchTrigger)
                    }}
                  >
                    {t('เพิ่มหน่วยรับตรวจ')}
                  </ButtonNew>
                )
              }
            />
          </Col>
        </Row>
        <Row gutter={[12, 20]} justify="center" style={{ marginTop: '15px' }}>
          {action !== 'view' && (
            <Col>
              <ButtonNew type="primary" roles={{ type: action, menu: props.menu }} htmlType="submit">
                {t('บันทึก')}
              </ButtonNew>
            </Col>
          )}
          <Col>
            <ButtonNew
              type="back"
              onClick={() =>
                navigate(mainPage, {
                  state: {
                    ...state,
                    action: action === 'edit' || state.backToEdit ? 'edit' : action,
                    backToEdit: null,
                  },
                })
              }
            >
              {t('ยกเลิก')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>

      {/* Popup สำหรับเพิ่มหน่วยรับตรวจ */}

      <ModalNew // ปุ่มกดเพื่อแสดง Popup
        visible={modalOpen}
        testTitle={t('เพิ่มหน่วยรับตรวจ')}
        onClose={() => setmodalOpen(false)}
        onSubmit={() => {
          const data = formFilter.getFieldValue()
          const selectedOrg = []
          for (const [key, value] of Object.entries(data)) {
            if (key.search('selectedOrg_') !== -1 && value) {
              selectedOrg.push(value)
            }
          }
          const newList = [
            ...currentIssueOrg,
            ...selectedOrg.map((x) => {
              return {
                organizationCode: x.orgCode,
                organizationNameTH: x.orgName,
                organizationNameEN: x.orgName,
              }
            }),
          ]
          setCurrentIssueOrg(newList)
          setmodalOpen(false)
        }}
        typeAction={'add'}
      >
        {allOrgListData.loading && <SpinnersNew />}
        <Form
          name={'filterOrg'}
          form={formFilter}
          autoComplete="off"
          layout="vertical" // ส่วน Search
        >
          <Row gutter={[12, 12]}>
            <Col {...formItemResponsive}>
              <Form.Item
                label={<LabelNew type="tab-header-inactive">{t('รหัสหน่วยงาน')}</LabelNew>}
                name="organizationCode"
              >
                <InputNew placeholder={t('รหัสหน่วยงาน')} />
              </Form.Item>
            </Col>
            <Col {...formItemResponsive}>
              <Form.Item
                label={<LabelNew type="tab-header-inactive">{t('ชื่อหน่วยงาน')}</LabelNew>}
                name="organizationName"
              >
                <InputNew placeholder={t('ชื่อหน่วยงาน')} />
              </Form.Item>
            </Col>
            <Col {...formItemResponsive}>
              <Form.Item
                label={<LabelNew type="tab-header-inactive">{t('ระดับหน่วยงาน')}</LabelNew>}
                name="organizationLevel"
              >
                <SelectOrganizationLevel
                  form={formFilter}
                  formname="filterOrg"
                  placeholder={t('เลือกระดับหน่วยงาน')}
                  handleChange={(e) => {
                    formFilter.setFieldsValue({ organizationLevel: e?.value || null })
                  }}
                  disabled={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <ButtonNew
                type="search"
                onClick={() => {
                  setsearchTrigger(!searchTrigger)
                }}
                roles={{ type: 'view', menu: props.menu }}
              >
                {t('ค้นหา')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew
                type="clear"
                onClick={() => {
                  formFilter.resetFields()
                  setsearchTrigger(!searchTrigger)
                }}
              >
                {t('ล้างค่า')}
              </ButtonNew>
            </Col>
          </Row>

          <Row>
            <Col md={24}>
              <Datatable // ส่วนแสดงหน่วยรับตรวจที่มีให้เลือก
                paginationCustom={true}
                searchCustom={true}
                pageSize={limit}
                page={page}
                total={count}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                columns={[
                  {
                    title: t('เลือก'),
                    width: '10%',
                    className: 'text-nowrap',
                    render: (record) => {
                      return (
                        <Form.Item
                          wrapperCol={{
                            md: 24,
                            lg: 24,
                          }}
                          name={`selectedOrg_${record.orgCode}`}
                          valuePropName="checked" // ต้องมี
                          style={{ margin: '0px' }}
                        >
                          <Checkbox
                            checked={record.orgCode}
                            onChange={() => {
                              if (formFilter.getFieldValue(`selectedOrg_${record.orgCode}`)) {
                                formFilter.setFieldsValue({
                                  [`selectedOrg_${record.orgCode}`]: record,
                                })
                              } else {
                                formFilter.resetFields([`selectedOrg_${record.orgCode}`])
                              }
                            }}
                          />
                        </Form.Item>
                      )
                    },
                  },
                  {
                    title: t('หน่วยงาน'),
                    width: '20%',
                    className: 'text-nowrap',
                    dataIndex: 'orgCode',
                  },
                  {
                    title: t('หน่วยงาน'),
                    width: '50%',
                    className: 'text-nowrap',
                    dataIndex: 'orgName',
                  },
                  {
                    title: t('ระดับหน่วยงาน'),
                    width: '20%',
                    className: 'text-nowrap',
                    dataIndex: 'orgLV',
                  },
                ]}
                data={orgForAddList}
              />
            </Col>
          </Row>
        </Form>
      </ModalNew>
    </>
  )
}

export default SoiAuditCommitteeTabIssueOrg
