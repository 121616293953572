import { gql } from '@apollo/client'

export const GET_SETUP_RUNNUG_NUMBER_PAGINATION = gql`
  query SetupRunningNumberPaginationGet($input: SetupRunningNumberPaginationInput!) {
    setupRunningNumberPaginationGet(input: $input) {
      result {
        no
        numberSeriesId
        numberTypeNameTH
        documentName
        organizationNameTH
        menuNameTH
        startEnd
        formatDetail
        lastNumber
        isActive
        numberTypeCode
      }
      count
      page
      limit
    }
  }
`

export const GET_MASTER_NUMBER_SERIES = gql`
  query MasterNumberSeriesGet($numberSeriesId: String) {
    masterNumberSeriesGet(numberSeriesId: $numberSeriesId) {
      numberSeriesId
      numberTypeCode
      organizationCode
      menuCode
      jobTypeCode
      docTypeCode
      startDate
      endDate
      lastNumber
      used
      isActive
      masterNumberFormat {
        numberFormatId
        numberSeriesId
        formatSort
        formatTypeCode
        formatDetail
      }
    }
  }
`
