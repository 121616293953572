import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew as Card } from '../../../components/card/Card'
import { LabelNew as Label } from '../../../components/label/Label'
import { Row, Col, Tooltip } from 'antd'
import { IconNew as Icon } from '../../../components/icon/Icon'
import { useLocation, Navigate } from 'react-router-dom'
import { useLanguage } from '../../../hooks/useLanguage'
import { useLazyQuery } from '@apollo/client'
import { GET_APPROVE_FILES, GET_AUDIT_PLAN_BY_APPROVE_PLAN_ONE } from './graphql/Query'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { convertDate2FullDate } from '../../../utilitys/dateHelper.js'
import { downloadFile } from '../../../utilitys/files'
import { DownlodApproveStyle } from './utils/approveStyle'
import { colors } from '../../../configs/styles.config'
import { encryptInput } from '../../../utilitys/crypto' //encryptData decryptData,
import { checkLanguageReturnData } from './component/fnForApprove'
import ApproveSubHeader from './component/aprovesubheader/ApproveSubHeader'
// import { TabNew } from '../../../components/tab/Tab'
import ApproveTabIndex from './component/tabapprove/ApproveTabIndex'
import { Alert } from '../../../components/alert/Alert'
import { displayError } from '../../../utilitys/helper'

const Responsive = {
  md: 24,
  lg: 24,
}

export default function ApprovePlanDetail(props) {
  const { menu } = props
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const { state } = useLocation()

  if (!state) return <Navigate to={'/auditplanning/approveauditplan'} />

  const breadcrumbDetail = [
    { label: t('งานวางแผนการตรวจสอบ'), path: '/auditplanning' },
    { label: t('อนุมัติแผนการตรวจสอบ'), path: '/auditplanning/approveauditplan' },
    { label: t('งานวางแผนการตรวจสอบ') },
  ]

  const [getFiles, setFiles] = useState([])
  const [dataObject, setDataObject] = useState([])

  const [getApproveFilesFn, getApproveFiles] = useLazyQuery(GET_APPROVE_FILES, {
    fetchPolicy: 'no-cache',
    variables: {
      inputData: { fileId: [0] },
    },
  })

  const [getApproveAuditPlanfn, getApproveAuditPlan] = useLazyQuery(GET_AUDIT_PLAN_BY_APPROVE_PLAN_ONE)

  const checkEmtryData = (values) => {
    if (values === 'NaN') {
      return false
    } else if (values === undefined) {
      return false
    } else if (values === null) {
      return false
    } else if (values === '') {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    if (getApproveFiles?.data) {
      setFiles(
        getApproveFiles?.data.getApproveFiles.map((dt, keys) => {
          return (
            <Tooltip key={'toolTipFilesApprove' + keys} placement="top" title={dt.fileNameLabel + dt.fileType}>
              <DownlodApproveStyle>
                <div>
                  <Label
                    type="tab-header-inactive"
                    key={'labelFilesApprove' + keys}
                    onClick={() => {
                      downloadFile(dt.fileNameLabel + dt.fileType, dt.fileNameGen + dt.fileType, () => {})
                    }}
                  >
                    {dt.fileType === '.jpg' || dt.fileType === '.png' ? (
                      <Icon
                        key={'iconFilesApprove' + keys}
                        icon={'Image'}
                        size={28}
                        color={colors.link}
                        className="approve-downloads"
                      />
                    ) : (
                      <Icon
                        key={'iconFilesApprove' + keys}
                        icon={'FileText'}
                        size={28}
                        color={colors.link}
                        className="approve-downloads"
                      />
                    )}
                  </Label>
                </div>
              </DownlodApproveStyle>
            </Tooltip>
          )
        }),
      )
    }
    if (getApproveFiles.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getApproveFiles.error)),
        onOk() {},
      })
    }
  }, [getApproveFiles.data])

  useEffect(() => {
    getApproveAuditPlanfn({
      variables: {
        inputData: encryptInput({ planCode: state.refCode }),
      },
    })
  }, [state.refCode])

  useEffect(() => {
    if (getApproveAuditPlan.data) {
      setDataObject(getApproveAuditPlan.data.getApproveAuditPlanOne)
      const res = getApproveAuditPlan.data.getApproveAuditPlanOne
      if (checkEmtryData(res.files)) {
        let fileIdArr = JSON.parse('[' + res.files + ']')
        if (fileIdArr.length !== 0) {
          getApproveFilesFn({
            fetchPolicy: 'no-cache',
            variables: {
              inputData: encryptInput({ fileId: fileIdArr }),
            },
          })
        }
      }
    }
    if (getApproveAuditPlan.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getApproveAuditPlan.error)),
        onOk() {},
      })
    }
  }, [getApproveAuditPlan.data])

  return (
    <>
      {(getApproveFiles.loading || getApproveAuditPlan.loading) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbDetail} title={t('งานวางแผนการตรวจสอบ')} />
      <Card
        topic={
          t('รายละเอียดการตรวจสอบ') +
          ' : ' +
          dataObject.planCode +
          ' : ' +
          checkLanguageReturnData(isLang, dataObject.planNameTH, dataObject.planNameTH)
        }
        icon="AlertCircle"
      >
        <Row style={{ marginBottom: 24 }} align="center">
          <Col {...Responsive} md={20} align="left">
            <Row style={{ marginTop: 24 }} align="center">
              <Col md={4} align="left">
                <Label type="tab-header-inactive">{t('ประจำปี')}</Label>
              </Col>
              <Col md={8} align="left">
                <Label type="body-primary">
                  {dataObject.planYear !== undefined
                    ? isLang === 'th'
                      ? dataObject.planYear + 543
                      : dataObject.planYear
                    : ''}
                </Label>
              </Col>
              <Col md={4} align="left">
                <Label type="tab-header-inactive">{t('ประเภทแผน')}</Label>
              </Col>
              <Col md={8} align="left">
                <Label type="body-primary">
                  {dataObject.planType !== undefined
                    ? t(dataObject.planType === 1 ? 'แผนประจำปี' : dataObject.planType === 2 ? 'แผนระยะยาว' : '')
                    : ''}
                </Label>
              </Col>
            </Row>
            <Row style={{ marginTop: 24 }} align="center">
              <Col md={4} align="left">
                <Label type="tab-header-inactive">{t('ผู้รับผิดชอบ(คน)')}</Label>
              </Col>
              <Col md={8} align="left">
                <Label type="body-primary">
                  {dataObject.numberOfPeople !== undefined
                    ? dataObject.numberOfPeople !== null
                      ? dataObject.numberOfPeople + ' ' + t('คน')
                      : 0 + ' ' + t('คน')
                    : ''}
                </Label>
              </Col>
              <Col md={4} align="left">
                <Label type="tab-header-inactive">{t('ระยะเวลา')}</Label>
              </Col>
              <Col md={8} align="left">
                <Label type="body-primary">
                  {dataObject.dateStart !== undefined || dataObject.dateEnd !== undefined
                    ? convertDate2FullDate(dataObject.dateStart) +
                      ' ' +
                      t('ถึง') +
                      ' ' +
                      convertDate2FullDate(dataObject.dateEnd)
                    : ''}
                </Label>
              </Col>
            </Row>
            <Row style={{ marginTop: 24 }} align="center">
              <Col md={4} align="left">
                <Label type="tab-header-inactive">{t('แผนการตรวจสอบ')}</Label>
              </Col>
              <Col md={20}>
                <Label type="body-primary">
                  {dataObject.planCode !== undefined ? dataObject.planCode + ' : ' + t(dataObject.planNameTH) : ''}
                </Label>
              </Col>
            </Row>
            <Row style={{ marginTop: 24 }} align="center">
              <Col md={4} align="left">
                <Label type="tab-header-inactive">{t('แบบประเมินความเสี่ยง')}</Label>
              </Col>
              <Col md={20}>
                <Label type="body-primary">
                  {dataObject.riskFormTH !== undefined
                    ? isLang === 'th'
                      ? t(dataObject.riskFormTH)
                      : t(dataObject.riskFormEN)
                    : ''}
                </Label>
              </Col>
              <Col md={20}>
                <Label type="tab-header-inactive">{t('')}</Label>
              </Col>
            </Row>
            <Row style={{ marginTop: 24 }} align="center">
              <Col md={4} align="left">
                <Label type="tab-header-inactive">{t('เอกสารอ้างอิง')}</Label>
              </Col>
              <Col md={20} style={{ display: 'flex' }}>
                {getFiles}
              </Col>
            </Row>
          </Col>
        </Row>
        <ApproveSubHeader icons={'List'} topic={t('รายการโครงการตรวจสอบ')} />
        {/* <Label type="card-title" icon="List">
          {t('รายการโครงการตรวจสอบ')}
        </Label> */}
        <ApproveTabIndex menu={menu} state={state} />
      </Card>
    </>
  )
}
