import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_CONTROL_FORM_QUESTIONS } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectControlFormQuestions = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { questId, questTH, questEN, formConfigId, choiceTypeCode, questDetailType, questrequired, isActive } =
    filters || {}

  const [isData, isSetData] = useState([])

  const [getSelectionControlFormQuestionsFn, getSelectionControlFormQuestions] =
    useLazyQuery(GET_CONTROL_FORM_QUESTIONS)

  useEffect(() => {
    getSelectionControlFormQuestionsFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [questId, questTH, questEN, formConfigId, choiceTypeCode, questDetailType, questrequired, isActive])

  useEffect(() => {
    if (getSelectionControlFormQuestions.data) {
      isSetData(
        _.map(getSelectionControlFormQuestions?.data?.getSelectionControlFormQuestions, (item) => {
          return {
            label: displayText(item?.questTH, item?.questEN),
            value: item?.questId,
            source: item,
          }
        }),
      )
    }
  }, [getSelectionControlFormQuestions.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getSelectionControlFormQuestions.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectControlFormQuestions
