import { gql } from '@apollo/client'

export const GET_SEARCH_RISK_FORMS_LIST = gql`
  query getSearchRiskFormsList($input: RiskAssessmentReportPagenationInput!) {
    getSearchRiskFormsList(input: $input) {
      result {
        riskFormCode
        Year
        riskFormTH
        riskFormEN
        riskFormType
        operationStatusCode
        riskResultId
        riskModelCode
      }
      limit
      page
      count
    }
  }
`
export const EXPORT_RISK_FORMS = gql`
  query exportRiskForms($input: ExportsRiskFormInput!) {
    exportRiskForms(input: $input) {
      message
      fileUrl
    }
  }
`
export const RICK_RESULT_EXPORT = gql`
  query riskResultExport($input: ExportsInput!) {
    riskResultExport(input: $input) {
      message
      fileUrl
    }
  }
`
