import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Alert } from '../../../../../components/alert/Alert'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'

const GET_EXPORT_STATUS_CAUSE_AND_ISSUR_DETECTED = gql`
  query exportsStatusCauseAndIssueDetected($input: ExportsStatusCauseAndIssueDetectedInput!) {
    exportsStatusCauseAndIssueDetected(input: $input) {
      message
      fileUrl
    }
  }
`

export const useExportsStatusCauseAndIssueDetected = () => {
  const { t } = useTranslation()

  const [data, setData] = useState(null)

  const [exportsStatusCauseAndIssueDetectedFn, exportsStatusCauseAndIssueDetected] = useLazyQuery(
    GET_EXPORT_STATUS_CAUSE_AND_ISSUR_DETECTED,
  )

  useEffect(() => {
    if (data) exportsStatusCauseAndIssueDetectedFn({ variables: { input: encryptInput({ ...data }) } })
  }, [data])

  useEffect(() => {
    if (exportsStatusCauseAndIssueDetected?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(exportsStatusCauseAndIssueDetected?.error)),
        onOk() {},
      })
    }
  }, [exportsStatusCauseAndIssueDetected?.error])

  useEffect(() => {
    if (exportsStatusCauseAndIssueDetected?.data) {
      if (exportsStatusCauseAndIssueDetected?.data?.exportsStatusCauseAndIssueDetected?.message === 'succcess') {
        window.open(
          `${exportsStatusCauseAndIssueDetected?.data?.exportsStatusCauseAndIssueDetected?.fileUrl}`,
          '_blank',
        )
      }
    }
  }, [exportsStatusCauseAndIssueDetected?.data])

  return [exportsStatusCauseAndIssueDetected?.loading, setData]
}
