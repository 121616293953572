import { gql } from '@apollo/client'

export const GETMASTERCALENDARS = gql`
  query getMasterCalendars($inputData: MastercalendarsPaginationInput!) {
    getMasterCalendars(inputData: $inputData) {
      result {
        no
        calendarId
        calendarCode
        calendarNameTH
        calendarNameEN
        calendarYear
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      count
      page
      limit
    }
  }
`

export const GETMASTERCALENDARRAWONE = gql`
  query getMasterCalendarRawOne($inputData: MastercalendarsInput!) {
    getMasterCalendarRawOne(inputData: $inputData) {
      calendarId
      calendarCode
      calendarNameTH
      calendarNameEN
      calendarYear
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`

export const GETMASTERCALENDARDETAILSBYMCDCODE = gql`
  query getMasterCalendarDetailsByMCD($inputData: MasterCalendarDetailsByMCDInput!) {
    getMasterCalendarDetailsByMCDCode(inputData: $inputData) {
      calendarDetailId
      calendarCode
      dateType
      dateNameTH
      dateNameEN
      dateAt
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`
