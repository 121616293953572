import { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CardNew as Card } from '../../../../../components/card/Card'
import { MainTable } from '../../../auditopens/css/Styles'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { dateDisplay } from '../../../../../utilitys/dateHelper'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { ButtonNew as Button } from '../../../../../components/button/Button'
import { Alert } from '../../../../../components/alert/Alert'
import AcResolutionListAddModal from '../modal/AcResolutionListAddModal'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_AUDIT_RESOLUTIONS } from '../../graphql/Query'
import { DELETE_AUDIT_RESOLUTION } from '../../graphql/Mutation'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText, displayError } from '../../../../../utilitys/helper'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'
import { SpinnersNew as Spinner } from '../../../../../components/spinners/Spinners'
import { handleSort } from '../../../../../utilitys/pagination'

const AcResolutionListDataTable = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [user] = useDecodeUser()
  const systemLanguage = localStorage.getItem('lang')

  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([{ fieldSort: 'resId', sortType: 'ASC' }])

  const [paginate, setPaginate] = useState({ page: 1, limit: 10 })
  const [tableData, setTableData] = useState([])
  const [isAddModalOpen, setIsAddModalOpen] = useState(false)

  const [getAuditResolutions, getAuditResolutionsResult] = useLazyQuery(GET_AUDIT_RESOLUTIONS)
  const [deleteAuditResolution, deleteAuditResolutionResult] = useMutation(DELETE_AUDIT_RESOLUTION)

  const columns = [
    {
      title: t('ลำดับ'),
      dataIndex: 'rowNumber',
      width: '15%',
      align: 'left',
    },
    {
      title: t('ครั้งที่/ปี'),
      dataIndex: 'meetingTimes',
      width: '15%',
      align: 'center',
      sorter: true,
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('วันที่ประชุม')}</div>,
      dataIndex: 'meetingDate',
      width: '15%',
      align: 'center',
      render: (record) => <div style={{ textAlign: 'center' }}>{dateDisplay(record)}</div>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('สถานที่ประชุม')}</div>,
      dataIndex: 'location',
      width: '40%',
      align: 'left',
    },
    {
      title: '',
      // dataIndex: 'action',
      width: '15%',
      render: (record) => {
        let list = [
          {
            type: 'view',
            onClick: () => navigate('detail', { state: { detailPageAction: 'view', resId: record.resId } }),
          },
          {
            type: 'edit',
            onClick: () => navigate('detail', { state: { detailPageAction: 'edit', resId: record.resId } }),
          },
          {
            type: 'delete',
            onClick: () => {
              Alert({
                type: 'confirm',
                title: t('ต้องการยืนยันการลบ?'),
                onOk() {
                  deleteAuditResolution({
                    variables: {
                      resId: encryptInput(record?.resId),
                      userId: encryptInput(user.userId),
                    },
                  })
                    .then((response) => {
                      if (response.data?.deleteAuditResolution === true) {
                        getAuditResolutionsResult.refetch()
                        Alert({
                          type: 'success',
                          title: t('ลบข้อมูลสำเร็จ'),
                          onOk() {},
                        })
                      } else if (response.data?.deleteAuditResolution === false) {
                        Alert({
                          type: 'error',
                          title: t('ไม่สามารถลบได้เนื่องจากมติการตรวจสอบดังกล่าว มีวาระการประชุมที่ถูกนำไปใช้แล้ว'),
                          content: t(''),
                          onOk() {},
                        })
                      }
                    })
                    .catch((error) =>
                      Alert({
                        type: 'error',
                        title: t('ไม่สามารถลบข้อมูลได้'),
                        content: t(error),
                        onOk() {},
                      }),
                    )
                },
                onCancel() {},
              })
            },
          },
        ]
        return <ButtonGroup menu={props.menu} size={18} icons={list} />
      },
    },
  ]

  useEffect(() => {
    const input = encryptInput({
      filters: props.filters,
      pagination: { ...paginate },
      sort: sort,
    })
    getAuditResolutions({
      variables: {
        input: input,
      },
      fetchPolicy: 'no-cache',
    })
  }, [paginate, sort, props.filters])

  useEffect(() => {
    if (getAuditResolutionsResult.data) {
      setTableData(
        getAuditResolutionsResult.data.getAuditResolution.result.map((item, index) => {
          return {
            rowNumber: item.no,
            meetingTimes: item.resTimes,
            meetingDate: new Date(item.resDate),
            location: displayText(item.locationTH, item.locationEN),
            resId: item.resId,
            key: index,
          }
        }),
      )
      setCount(getAuditResolutionsResult.data.getAuditResolution.count)
    }
  }, [getAuditResolutionsResult.data])

  useEffect(() => {
    if (getAuditResolutionsResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getAuditResolutionsResult.error)),
        onOk() {},
      })
    }
  }, [getAuditResolutionsResult.error])

  useEffect(() => {
    if (deleteAuditResolutionResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(deleteAuditResolutionResult.error)),
        onOk() {},
      })
    }
  }, [deleteAuditResolutionResult.error])

  return (
    <>
      {deleteAuditResolutionResult.loading && <Spinner />}
      <Card topic={t('รายการมติคณะกรรมการตรวจสอบ')} icon="AlignJustify" toggledrop="false">
        <Row>
          <Col md={24}>
            <MainTable>
              <Datatable
                columns={columns}
                data={tableData}
                total={count}
                searchCustom={true}
                pageSize={paginate.limit}
                page={paginate.page}
                isLoading={getAuditResolutionsResult.loading}
                paginationCustom={true}
                scroll={{ x: 1500 }}
                onChangePagination={({ page, pageSize }) => {
                  setPaginate({ page, limit: pageSize })
                }}
                handleTableChange={(e) => {
                  const reNewField = [{ agendaName: 'resId' }, { meetingTimes: 'resTimes' }]

                  setSort(handleSort(e?.sorter, reNewField))
                }}
                btnAdd={
                  <>
                    <Button
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: props.menu }}
                      style={{ marginRight: '12px' }}
                      onClick={() => {
                        setIsAddModalOpen(true)
                      }}
                    >
                      {t('เพิ่มมติการตรวจสอบ')}
                    </Button>
                  </>
                }
              />
            </MainTable>
          </Col>
        </Row>
        <AcResolutionListAddModal
          open={isAddModalOpen}
          setModalOpen={setIsAddModalOpen}
          systemLanguage={systemLanguage}
          user={user}
          dataTableHandler={getAuditResolutionsResult}
        />
      </Card>
    </>
  )
}

export default AcResolutionListDataTable
