import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { ButtonNew } from '../../../components/button/Button'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { Alert } from '../../../components/alert/Alert'
import { dateDisplay, dateTimeDisplay } from '../../../utilitys/dateHelper'
import { encryptInput } from '../../../utilitys/crypto'
import { GET_PERMISSION_PAGINATION } from './graphql/Query'
import { DELETE_SYSTEM_ROLES } from './graphql/Mutation'
import { Columns } from './utils/PermissionsColumns'
import { handleSort } from '../../../utilitys/pagination'
import { displayError, displayText } from '../../../utilitys/helper'
import PermissionsListFilter from './components/filter/PermissionsListFilter.jsx'

const Responesive = {
  sm: 24,
  md: 24,
  lg: 24,
}

const PermissionsList = (props) => {
  const { t } = useTranslation()
  const { menu } = props
  const navigate = useNavigate()
  const [language /*setLanguage*/] = useState('th')
  const [dataLists, setDataLists] = useState([])

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  const [filters, setFilters] = useState({})

  const [getPermissionsPagination, { data: dataPermissions, loading: loadingPermissions, error: errorPermissions }] =
    useLazyQuery(GET_PERMISSION_PAGINATION)

  const [deleteSystemRoles, { data: dataDelete, loading: loadingDelete, error: errorDelete }] =
    useMutation(DELETE_SYSTEM_ROLES)

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบข้อมูลสำเร็จ'),
        onOk() {
          getPermissionsPagination({
            variables: {
              input: encryptInput({
                filters: filters,
                pagination: { limit, page },
                sort: [],
              }),
            },
          })
        },
      })
    }

    if (errorDelete) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถลบข้อมูลได้`),
        content: t(displayError(errorDelete)),
        onOk() {},
      })
    }
  }, [dataDelete])

  const fnConfirm = (roleCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        onSubmit(roleCode)
      },
      onCancel() {},
    })
  }

  const onSubmit = (roleCode) => {
    let variables = encryptInput({ roleCode: roleCode })
    deleteSystemRoles({
      variables,
    })
  }

  useEffect(() => {
    getPermissionsPagination({
      variables: {
        input: encryptInput({
          filters: filters,
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (dataPermissions) {
      let count = dataPermissions.getPermissionPagination.count
      setCount(count)

      setDataLists(
        _.map(dataPermissions?.getPermissionPagination?.result, (data) => {
          return {
            ...data,
            roleName: displayText(data.roleNameTH, data.roleNameEN),
            key: data.no,
            startDate: dateDisplay(data.startDate),
            expireDate: dateDisplay(data.expireDate),
            createdAt: dateTimeDisplay(data.createdAt),
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () =>
                        navigate('/administrator/permissions/form', {
                          state: { type_action: 'view', refCode: data.roleCode, roleId: data.roleId },
                        }),
                    },
                    {
                      type: 'edit',
                      onClick: () =>
                        navigate('/administrator/permissions/form', {
                          state: { type_action: 'edit', refCode: data.roleCode, roleId: data.roleId },
                        }),
                    },
                    {
                      type: 'delete',
                      onClick: async () => {
                        fnConfirm(data.roleCode)
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }

    if (errorPermissions) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorPermissions)),
        onOk() {},
      })
    }
  }, [dataPermissions])

  const fncReset = () => {
    setFilters({})
    getPermissionsPagination({
      variables: {
        input: encryptInput({
          filters: {},
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }

  return (
    <>
      {loadingPermissions || loadingDelete ? <SpinnersNew /> : null}
      <PermissionsListFilter
        fncReset={fncReset}
        getValue={(e) => {
          setFilters({
            roleCode: e.roleCode,
            roleName: e.roleName,
            dateStart: e.dateStart,
            dateEnd: e.dateEnd,
          })
          getPermissionsPagination({
            variables: {
              input: encryptInput({
                filters: {
                  roleCode: e.roleCode,
                  roleName: e.roleName,
                  dateStart: e.dateStart,
                  dateEnd: e.dateEnd,
                },
                pagination: { limit, page },
                sort: [],
              }),
            },
          })
        }}
      />
      <CardNew topic={language === 'th' ? t('จัดการกลุ่มสิทธิ์การใช้งาน') : ''} icon="Lock" toggledrop={'false'}>
        <Row gutter={[16, 16]}>
          <Col {...Responesive}>
            <Datatable
              columns={Columns}
              data={[...dataLists]}
              total={count}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              paginationCustom={true}
              searchCustom={true}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => {
                const reNewField = [
                  { roleName: displayText('roleNameTH', 'roleNameEN') },
                  { startDate: 'startDate' },
                  { expireDate: 'expireDate' },
                  { createdAt: 'createdAt' },
                  { username: 'username' },
                ]

                setSort(handleSort(e?.sorter, reNewField))
              }}
              scroll={{ x: 1000, y: false }}
              btnAdd={
                <ButtonNew
                  type="plusTableBorderPrimary"
                  onClick={() => {
                    navigate('/administrator/permissions/form', {
                      state: { type_action: 'add' },
                    })
                  }}
                  roles={{ type: 'add', menu: menu }}
                >
                  {t('เพิ่มกลุ่มสิทธิ์')}
                </ButtonNew>
              }
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default PermissionsList
