import { gql } from '@apollo/client'

export const GET_MASTER_AUDIT_TYPES = gql`
  query getMasterAuditTypes($input: MasterAuditTypesInput!) {
    getMasterAuditTypes(data: $input) {
      auditTypeId
      auditTypeCode
      auditTypeNameTH
      auditTypeNameEN
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`
