import React, { useState, useEffect } from 'react'
import { Form, Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { UPDATE_OR_CREATE_PREPARE_AUDIT_REPORT_DETAIL } from '../../graphql/Mutation'
import { GET_PREPARE_AUDIT_REPORT_DETAIL_BY_CODE } from '../../graphql/Query'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { LabelNew } from '../../../../../components/label/Label'
import { ButtonNew } from '../../../../../components/button/Button'
import { Alert } from '../../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { displayError } from '../../../../../utilitys/helper'
import { encryptInput } from '../../../../../utilitys/crypto'

const SummaryAuditResultsTab = (props) => {
  if (!props.active) return <></>
  const formname = 'summaryAuditResultsForm'
  const responsive = { sm: 24, md: 24, lg: 16 }
  const subWrapper = { md: 18, lg: 18, xl: 18 }

  const { menu } = props
  const navigate = useNavigate()
  const { state } = useLocation()

  const { t } = useTranslation()
  const [form] = Form.useForm()

  if (!state) return <Navigate to={'/auditoperations/prepareauditreport'} />

  const [otherInput, setOtherInput] = useState({})
  const [trigger, setTrigger] = useState({})

  const [updateOrCreatePrepareAuditReportDetailFn, updateOrCreatePrepareAuditReportDetail] = useMutation(
    UPDATE_OR_CREATE_PREPARE_AUDIT_REPORT_DETAIL,
  )
  const [getPrepareAuditReportDetailByCodeFn, getPrepareAuditReportDetailByCode] = useLazyQuery(
    GET_PREPARE_AUDIT_REPORT_DETAIL_BY_CODE,
  )

  useEffect(() => {
    if (state.typeAction !== 'add' && state.finalReportId) {
      getPrepareAuditReportDetailByCodeFn({
        variables: { input: encryptInput({ finalReportId: state.finalReportId }) },
      })
    }
  }, [state.typeAction])

  useEffect(() => {
    if (getPrepareAuditReportDetailByCode?.data) {
      const resDataDetail = getPrepareAuditReportDetailByCode?.data?.getPrepareAuditReportDetailByCode

      form.setFieldsValue({ ...resDataDetail })
      setTrigger({
        ...trigger,
        finalReportDetailId: resDataDetail?.finalReportDetailId,
        documentNo: resDataDetail?.documentNo,
      })
    }

    if (getPrepareAuditReportDetailByCode?.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getPrepareAuditReportDetailByCode?.error)),
        onOk() {},
      })
    }
  }, [getPrepareAuditReportDetailByCode?.data])

  useEffect(() => {
    if (updateOrCreatePrepareAuditReportDetail?.data) {
      const resDataDetail = updateOrCreatePrepareAuditReportDetail?.data.updateOrCreatePrepareAuditReportDetail
      const url = '/auditoperations/prepareauditreport'
      Alert({
        type: 'success',
        title: t(otherInput?.status === 1 ? 'บันทึกเสร็จสิ้นสำเร็จ' : 'บันทึกสำเร็จ'),
        onOk() {
          navigate(url)
        },
      })

      setTrigger({ ...trigger, finalReportDetailId: resDataDetail?.finalReportDetailId })
    }

    if (updateOrCreatePrepareAuditReportDetail?.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(updateOrCreatePrepareAuditReportDetail?.error)),
        onOk() {},
      })
    }
  }, [updateOrCreatePrepareAuditReportDetail?.data])

  const onFinish = (data) => {
    if (state?.finalReportId) data.finalReportId = state.finalReportId
    if (trigger?.finalReportDetailId) data.finalReportDetailId = trigger.finalReportDetailId
    let dataInput = {
      ...data,
      ...otherInput,
    }

    Alert({
      type: 'confirm',
      title: t(otherInput?.status === 1 ? 'ต้องการยืนยันการบันทึกเสร็จสิ้น?' : 'ต้องการยืนยันการบันทึก?'),
      onOk() {
        updateOrCreatePrepareAuditReportDetailFn({
          fetchPolicy: 'no-cache',
          variables: { input: encryptInput(dataInput) },
        })
      },
      onCancel() {},
    })
  }

  return (
    <>
      {(updateOrCreatePrepareAuditReportDetail?.loading || getPrepareAuditReportDetailByCode.loading) && (
        <SpinnersNew />
      )}
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        labelCol={{
          lg: 8,
          xl: 8,
        }}
        wrapperCol={{
          lg: 16,
          xl: 16,
          align: 'left',
        }}
        name={formname}
      >
        <Row style={{ margin: '10px 0px' }}>
          <Col {...subWrapper}>
            <LabelNew type="sub-header-primary">{t('รายละเอียดสรุปผลการตรวจสอบ')}</LabelNew>
          </Col>
        </Row>
        <Row justify="center">
          <Col {...responsive}>
            <Form.Item
              name="issue"
              label={<LabelNew type="tab-header-inactive">{t('ข้อสังเกต')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุข้อสังเกต') }]}
            >
              <TextAreaNew
                placeholder={t('ระบุข้อสังเกต')}
                autoSize={{ minRows: 6, maxRows: 6 }}
                disabled={state.typeAction === 'view'}
              />
            </Form.Item>
            <Form.Item
              name="rules"
              label={
                <LabelNew type="tab-header-inactive">{t('เก็บข้อมูลเกณฑ์ ระเบียบ คำสั่ง ที่เกี่ยวข้อง')}</LabelNew>
              }
              rules={[{ required: true, message: t('กรุณาระบุเก็บข้อมูลเกณฑ์ ระเบียบ คำสั่ง ที่เกี่ยวข้อง') }]}
            >
              <TextAreaNew
                placeholder={t('ระบุเก็บข้อมูลเกณฑ์ ระเบียบ คำสั่ง ที่เกี่ยวข้อง')}
                autoSize={{ minRows: 6, maxRows: 6 }}
                disabled={state.typeAction === 'view'}
              />
            </Form.Item>
            <Form.Item
              name="fact"
              label={<LabelNew type="tab-header-inactive">{t('ข้อเท็จจริง')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุข้อเท็จจริง') }]}
            >
              <TextAreaNew
                placeholder={t('ระบุข้อเท็จจริง')}
                autoSize={{ minRows: 6, maxRows: 6 }}
                disabled={state.typeAction === 'view'}
              />
            </Form.Item>
            <Form.Item
              name="cause"
              label={<LabelNew type="tab-header-inactive">{t('สาเหตุ')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุสาเหตุ') }]}
            >
              <TextAreaNew
                placeholder={t('ระบุสาเหตุ')}
                autoSize={{ minRows: 6, maxRows: 6 }}
                disabled={state.typeAction === 'view'}
              />
            </Form.Item>
            <Form.Item
              name="risk"
              label={<LabelNew type="tab-header-inactive">{t('ความเสี่ยง')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุความเสี่ยง') }]}
            >
              <TextAreaNew
                placeholder={t('ระบุความเสี่ยง')}
                autoSize={{ minRows: 6, maxRows: 6 }}
                disabled={state.typeAction === 'view'}
              />
            </Form.Item>
            <Form.Item
              name="control"
              label={<LabelNew type="tab-header-inactive">{t('การควบคุมอื่นๆ ที่ทดแทน')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุการควบคุมอื่นๆ ที่ทดแทน') }]}
            >
              <TextAreaNew
                placeholder={t('ระบุการควบคุมอื่นๆ ที่ทดแทน')}
                autoSize={{ minRows: 6, maxRows: 6 }}
                disabled={state.typeAction === 'view'}
              />
            </Form.Item>
            <Form.Item
              name="summaryTest"
              label={<LabelNew type="tab-header-inactive">{t('สรุปผลตรวจสอบ')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุสรุปผลตรวจสอบ') }]}
            >
              <TextAreaNew
                placeholder={t('ระบุสรุปผลตรวจสอบ')}
                autoSize={{ minRows: 6, maxRows: 6 }}
                disabled={state.typeAction === 'view'}
              />
            </Form.Item>
            <Form.Item
              name="feedback"
              label={<LabelNew type="tab-header-inactive">{t('ข้อเสนอแนะ')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุข้อเสนอแนะ') }]}
            >
              <TextAreaNew
                placeholder={t('ระบุข้อเสนอแนะ')}
                autoSize={{ minRows: 6, maxRows: 6 }}
                disabled={state.typeAction === 'view'}
              />
            </Form.Item>

            <Form.Item
              name="conlusionActionExecutive"
              label={<LabelNew type="tab-header-inactive">{t('สรุปผลการดำเนินการผู้บริหาร')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุสรุปผลการดำเนินการผู้บริหาร') }]}
            >
              <TextAreaNew
                placeholder={t('ระบุสรุปผลการดำเนินการผู้บริหาร')}
                autoSize={{ minRows: 6, maxRows: 6 }}
                disabled={state.typeAction === 'view'}
              />
            </Form.Item>
            <Form.Item
              name="resultAssessmentRemark"
              label={<LabelNew type="tab-header-inactive">{t('ผลของการประเมินจากข้อสังเกต')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุผลของการประเมินจากข้อสังเกต') }]}
            >
              <TextAreaNew
                placeholder={t('ระบุผลของการประเมินจากข้อสังเกต')}
                autoSize={{ minRows: 6, maxRows: 6 }}
                disabled={state.typeAction === 'view'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 20]} justify="center">
          {state.typeAction !== 'view' && (
            <>
              <Col>
                <ButtonNew
                  type="primary"
                  onClick={() => {
                    setOtherInput({ ...otherInput, status: 0 })
                  }}
                  roles={{ type: state.typeAction, menu: menu }}
                  htmlType="submit"
                >
                  {t('บันทึก')}
                </ButtonNew>
              </Col>
              <Col>
                <ButtonNew
                  disabled={
                    state.typeAction !== 'edit' || !trigger.documentNo || !trigger.finalReportDetailId ? true : false
                  }
                  type="success"
                  onClick={() => {
                    setOtherInput({ ...otherInput, status: 1 })
                  }}
                  roles={{ type: state.typeAction, menu: menu }}
                  htmlType="submit"
                >
                  {t('เสร็จสิ้น')}
                </ButtonNew>
              </Col>
            </>
          )}
          <Col>
            <ButtonNew
              type="back"
              onClick={() =>
                navigate('/auditoperations/prepareauditreport', {
                  state: state,
                })
              }
            >
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default SummaryAuditResultsTab
