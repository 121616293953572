import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'antd'
import { LabelNew } from '../../../../../components/label/Label'
import { useTranslation } from 'react-i18next'
import { ButtonNew } from '../../../../../components/button/Button'
import SelectOrganization from '../../../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectAuditTeams from '../../../../../components/inputfromapi/selectauditteams/SelectAuditTeams'
import SelectProjectSizes from '../../../../../components/inputfromapi/selectprojectsizes/SelectProjectSizes'

import { InputNew as Input } from '../../../../../components/input/Input'
import { InputNumberNew as InputNumber } from '../../../../../components/input/Input'

import {
  GET_AUDITTEAM_DETAIL_ROLE,
  AUDIT_PROJECT_GET_CALENDARDETAILS1,
  DATA_AUDITPROJECT_FORM_TEAM_TAB,
} from '../graphql/Query'
import { SAVE_AUDIT_PROJECT_TEAM } from '../graphql/Mutation'

import { useLazyQuery, useMutation } from '@apollo/client'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { Alert } from '../../../../../components/alert/Alert'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'
import { encryptInput } from '../../../../../utilitys/crypto'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import * as _ from 'lodash'
import { displayError, displayText } from '../../../../../utilitys/helper'
import { Select } from '../../../../../components/select/Select'

const FormAuditProjectsTeam = (props) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [maxNumberOfPeople, setMaxNumberOfPeople] = useState(0)
  const [planDateStart, setPlanDateStart] = useState(null)
  const [planDateEnd, setPlanDateEnd] = useState(null)
  const [personnelTeam, setPersonnelTeam] = useState([])
  const [holiday, setHoliday] = useState({})
  const [dateStart, setDateStart] = useState(null)
  const [mandayPersonnal, setMandayPersonnal] = useState('')
  const [getBusinessDaysReadyUse, setGetBusinessDaysReadyUse] = useState(false)
  const [auditTeamCode, setauditTeamCode] = useState()
  const [dataSelectPerssonels, setdataSelectPerssonels] = useState([])

  // ===================================== Alert =============================
  const showConfirmDataPlan = () => {
    Alert({
      type: 'confirm',
      title: t('ระยะเวลาโครงการ ไม่สอดคล้องกับระยะเวลาของโครงการใหญ่   ต้องการบันทึกข้อมูลหรือไม่ ?'),
      onOk() {
        form.submit()
      },
      onCancel() {},
    })
  }

  const showConfirmGeneral = () => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        const valid = validDate()
        if (
          valid &&
          form.getFieldsError().filter((item) => {
            return item.errors.length !== 0
          }).length === 0
        ) {
          if (form.getFieldValue('dateStart') && form.getFieldValue('dateEnd') && planDateStart && planDateEnd) {
            if (
              moment(planDateStart) <= moment(form.getFieldValue('dateStart')) &&
              moment(planDateEnd) >= moment(form.getFieldValue('dateEnd'))
            ) {
              form.submit()
            } else {
              showConfirmDataPlan()
            }
          } else {
            form.submit()
          }
        }
      },
      onCancel() {},
    })
  }

  const showAlertSuccess = (data) => {
    const dataContent = {
      content: '',
    }
    if (data) {
      if (data.detail === 'Please reapprove agian') {
        dataContent.content = t('กรุณาอนุมัติโครงการใหม่')
      }
    }

    Alert({
      type: 'success',
      title: t('บันทึกสำเร็จ'),
      onOk() {},
      ...dataContent,
    })
  }

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const validDate = () => {
    let valid = false
    if (form.getFieldValue('dateStart') && form.getFieldValue('dateEnd')) {
      let dateStart = new Date(form.getFieldValue('dateStart'))
      let dateEnd = new Date(form.getFieldValue('dateEnd'))
      if (dateStart <= dateEnd) {
        setErrorField('dateStart', false)
        setErrorField('dateEnd', false)
        valid = true
      } else {
        setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นน้อยกว่าวันที่สิ้นสุด'))
        setErrorField('dateEnd', t('กรุณาเลือกวันที่สิ้นสุดมากกว่าวันที่เริ่มต้น'))
      }
    } else {
      valid = true
    }
    return valid
  }

  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  // func เลือกวันสิ้นสุดให้หลังจาก เลือกวันเริ่มต้น
  const getBusinessDays = async () => {
    let numWork = mandayPersonnal
    let countDateWork = 0
    let dateEnd = moment(dateStart)
    while (countDateWork < numWork) {
      if (dateEnd.day() !== 0 && dateEnd.day() !== 6) {
        if (!_.isEmpty(holiday)) {
          if (holiday[dateEnd.format('YYYY')]) {
            if (holiday[dateEnd.format('YYYY')].length) {
              let checkHoliday = await holiday[dateEnd.format('YYYY')].find((item) => {
                return moment(item.dateAt).format('YYYY-MM-DD') === dateEnd.format('YYYY-MM-DD')
              })
              if (!checkHoliday) {
                countDateWork++
              }
            } else {
              countDateWork++
            }
          } else {
            countDateWork++
          }
        } else {
          countDateWork++
        }
      }
      dateEnd = dateEnd.add(1, 'days')
    }
    if (countDateWork === numWork) {
      form.setFieldsValue({
        dateEnd: dateEnd.add(-1, 'days').format('YYYY-MM-DD'),
      })
    }
  }

  // ============================================ Api ผู้รับผิดชอบหลัก ============================================
  const [getAuditTeamsDetailRoleFn, getAuditTeamsDetailRole] = useLazyQuery(GET_AUDITTEAM_DETAIL_ROLE)
  useEffect(() => {
    if (getAuditTeamsDetailRole?.data) {
      let response = getAuditTeamsDetailRole?.data?.getAuditTeamsDetailRole

      if (!_.isEmpty(response)) {
        const filterPersonnel = response.filter((item) => item.auditTeamRole === 1)

        setdataSelectPerssonels(
          filterPersonnel.map((item) => {
            return {
              value: item.personnelCode,
              label: displayText(
                item.namePrefixTH + ' ' + item.firstNameTH + ' ' + item.lastNameTH,
                item.namePrefixEN + ' ' + item.firstNameEN + ' ' + item.lastNameEN,
              ),
              source: {
                auditTeamCode: item.auditTeamCode,
                personnelCode: item.personnelCode,
                auditTeamRole: item.auditTeamRole,
                namePrefixTH: item.namePrefixTH,
                namePrefixEN: item.namePrefixEN,
                firstNameTH: item.firstNameTH,
                lastNameTH: item.lastNameTH,
                firstNameEN: item.firstNameEN,
                lastNameEN: item.lastNameEN,
              },
            }
          }),
        )

        const value = filterPersonnel[0]

        let setForm = {
          auditTeamCode: value.auditTeamCode,
          personnelCode: value.personnelCode,
          personnel: displayText(
            value.namePrefixTH + ' ' + value.firstNameTH + ' ' + value.lastNameTH,
            value.namePrefixEN + ' ' + value.firstNameEN + ' ' + value.lastNameEN,
          ),
        }

        let teamLeangth = response.filter((item) => {
          return item.auditTeamCode === value.auditTeamCode
        }).length

        if (form.getFieldValue('numberOfPeople') > teamLeangth) {
          setForm['numberOfPeople'] = teamLeangth
          if (setForm['numberOfPeople'] && form.getFieldValue('manday')) {
            setForm['mandayPersonnal'] = Math.floor(form.getFieldValue('manday') / setForm['numberOfPeople'])
          } else {
            setForm['mandayPersonnal'] = 0
          }
        }
        setMandayPersonnal(setForm['mandayPersonnal'])
        form.setFieldsValue(setForm)
        setErrorField('personnel', false)

        setMaxNumberOfPeople(teamLeangth)

        setPersonnelTeam(
          response.map((item) => {
            return {
              auditTeamCode: item.auditTeamCode,
              personnelCode: item.personnelCode,
              auditTeamRole: item.auditTeamRole,
              namePrefixTH: item.namePrefixTH,
              namePrefixEN: item.namePrefixEN,
              firstNameTH: item.firstNameTH,
              lastNameTH: item.lastNameTH,
              firstNameEN: item.firstNameEN,
              lastNameEN: item.lastNameEN,
            }
          }),
        )
      } else {
        setdataSelectPerssonels([])
      }
    }
  }, [getAuditTeamsDetailRole.data])

  // ============================================ Api วันหยุด ============================================
  const [auditProjectGetCalendarDetailsType1Fn, auditProjectGetCalendarDetailsType1] = useLazyQuery(
    AUDIT_PROJECT_GET_CALENDARDETAILS1,
  )
  useEffect(() => {
    if (auditProjectGetCalendarDetailsType1?.data) {
      let response = auditProjectGetCalendarDetailsType1?.data?.auditProjectGetCalendarDetailsType1
      if (response) {
        setHoliday(_.groupBy(response, (item) => item.year))
      }
    }
  }, [auditProjectGetCalendarDetailsType1.data])

  // ============================================ api รายละเอียด ผู้รับผิดชอบ/ระยะเวลาดำเนินการ ============================================
  const [dataAuditProjectFormTeamTabFn, dataAuditProjectFormTeamTab] = useLazyQuery(DATA_AUDITPROJECT_FORM_TEAM_TAB)

  //เรียก api รายละเอียด ผู้รับผิดชอบ/ระยะเวลาดำเนินการ
  const runDataApi = async () => {
    let response = dataAuditProjectFormTeamTab?.data?.dataAuditProjectFormTeamTab

    if (response) {
      //วันเริ่มต้น auditPlan
      setPlanDateStart(response.planDateStart)

      //วันสิ้นสุด auditPlan
      setPlanDateEnd(response.planDateEnd)
      let personnel = await personnelTeam.find((item) => {
        return item.auditTeamCode === response.auditTeamCode && item.auditTeamRole === 1
      })

      // //จำนวนคน
      // setMaxNumberOfPeople(
      //   personnelTeam.filter((item) => {
      //     return item.auditTeamCode === response.auditTeamCode
      //   }).length,
      // )

      //จำนวนวันทำงานต่อคน
      setMandayPersonnal(response.mandayPersonnal)

      //วันเริ่มต้น
      setDateStart(response.dateStart)

      // ทีมตรวจสอบ

      setauditTeamCode(response.auditTeamCode)

      form.setFieldsValue({
        organizationCode: response.organizationCode,
        organizationNameTH: response.organizationNameTH,
        organizationNameEN: response.organizationNameEN,
        auditTeamCode: response.auditTeamCode,
        projectSizeCode: response.projectSizeCode,
        manday: response.manday,
        numberOfPeople: response.numberOfPeople,
        mandayPersonnal: response.mandayPersonnal,
        dateStart: response.dateStart,
        dateEnd: response.dateEnd,
        personnelCode: personnel ? personnel.personnelCode : null,
        personnel: personnel
          ? displayText(
              personnel.namePrefixTH + ' ' + personnel.firstNameTH + ' ' + personnel.lastNameTH,
              personnel.namePrefixEN + ' ' + personnel.firstNameEN + ' ' + personnel.lastNameEN,
            )
          : null,
      })
    }
  }

  useEffect(() => {
    if (dataAuditProjectFormTeamTab?.data) {
      runDataApi()
    }
  }, [dataAuditProjectFormTeamTab.data])

  // ============================================ Save API ============================================
  const [saveDataFn, saveData] = useMutation(SAVE_AUDIT_PROJECT_TEAM)

  useEffect(() => {
    if (saveData?.data) {
      let response = saveData?.data?.auditProjectsTeamSave
      if (response) {
        showAlertSuccess(response)
      }
    }
  }, [saveData.data])

  // ============================================ Error Alert ============================================

  useEffect(() => {
    if (getAuditTeamsDetailRole.error) {
      showAlertLoadDataError(displayError(getAuditTeamsDetailRole.error))
    }
    if (auditProjectGetCalendarDetailsType1.error) {
      showAlertLoadDataError(displayError(auditProjectGetCalendarDetailsType1.error))
    }
    if (dataAuditProjectFormTeamTab.error) {
      showAlertLoadDataError(displayError(dataAuditProjectFormTeamTab.error))
    }
    if (saveData.error) {
      showAlertError(displayError(saveData.error))
    }
  }, [
    getAuditTeamsDetailRole.error,
    dataAuditProjectFormTeamTab.error,
    auditProjectGetCalendarDetailsType1.error,
    saveData.error,
  ])

  //============================================ CAll API ============================================
  useEffect(() => {
    auditProjectGetCalendarDetailsType1Fn({
      fetchPolicy: 'no-cache',
    })

    dataAuditProjectFormTeamTabFn({
      fetchPolicy: 'no-cache',
      variables: encryptInput({
        projectCode: props.refCode,
      }),
    })
  }, [])

  useEffect(() => {
    if (auditTeamCode) {
      getAuditTeamsDetailRoleFn({
        fetchPolicy: 'no-cache',
        variables: { input: encryptInput({ isActive: 1, auditTeamCode: auditTeamCode }) },
      })
    }
  }, [auditTeamCode])

  useEffect(() => {
    if (getBusinessDaysReadyUse && mandayPersonnal && dateStart) {
      getBusinessDays()
    }
  }, [getBusinessDaysReadyUse, holiday, mandayPersonnal, dateStart])

  //========================================================================================

  const onFinish = (values) => {
    let input = {
      menuCode: 'AP01',
      projectCode: props.refCode,
      organizationCode: values.organizationCode,
      auditTeamCode: values.auditTeamCode,
      personnelCode: values.personnelCode,
      projectSizeCode: values.projectSizeCode,
      manday: values.manday,
      numberOfPeople: values.numberOfPeople,
      mandayPersonnal: values.mandayPersonnal,
      dateStart: values.dateStart,
      dateEnd: values.dateEnd,
      organizationNameTH: values.organizationNameTH,
      organizationNameEN: values.organizationNameEN,
    }
    saveDataFn({
      variables: {
        input: encryptInput(input),
      },
      fetchPolicy: 'no-cache',
    })
  }

  const formName = 'auditProject'

  return (
    <>
      {(auditProjectGetCalendarDetailsType1.loading || dataAuditProjectFormTeamTab.loading || saveData.loading) && (
        <SpinnersNew />
      )}
      <Row>
        <Col md={24}>
          <Form
            form={form}
            autoComplete="off"
            labelAlign="left"
            labelCol={{
              sm: 24,
              md: 8,
            }}
            wrapperCol={{
              sm: 24,
              md: 16,
            }}
            layout="horizontal"
            name={formName}
            onFinish={onFinish}
          >
            <LabelNew type="body-header">{t('ผู้รับผิดชอบ/ระยะเวลาดำเนินการ')}</LabelNew>
            <Row justify="center">
              <Col md={18}>
                <Form.Item name="organizationNameTH" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item name="organizationNameEN" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="organizationCode"
                  label={<LabelNew type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาเลือกหน่วยงานที่รับผิดชอบ') }]}
                >
                  <SelectOrganization
                    filters={{ checkedList: JSON.stringify([1, 2]), isActive: 1 }}
                    formname={formName}
                    placeholder={t('เลือกหน่วยงานที่รับผิดชอบ')}
                    handleChange={(value) => {
                      form.setFieldsValue({
                        organizationCode: value ? value.value : null,
                        organizationNameTH: value ? value.source.organizationNameTH : null,
                        organizationNameEN: value ? value.source.organizationNameEN : null,
                      })
                      if (value) {
                        setErrorField('organizationCode', false)
                      } else {
                        setErrorField('organizationCode', t('กรุณาเลือกหน่วยงานที่รับผิดชอบ'))
                      }
                    }}
                    disabled={props.typeForm === 'view' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  name="auditTeamCode"
                  label={<LabelNew type="tab-header-inactive">{t('ทีมตรวจสอบ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาเลือกทีมตรวจสอบ') }]}
                >
                  <SelectAuditTeams
                    filters={{ isActive: 1 }}
                    formname={formName}
                    placeholder={t('เลือกทีมตรวจสอบ')}
                    disabled={props.typeForm === 'view' ? true : false}
                    onClear={() => {
                      form.setFieldsValue({
                        auditTeamCode: null,
                        personnelCode: null,
                        personnel: null,
                        // numberOfPeople: 0,
                        // mandayPersonnal: 0,
                      })
                      setMandayPersonnal(0)
                      setMaxNumberOfPeople(0)
                      setErrorField('auditTeamCode', t('กรุณาเลือกทีมตรวจสอบ'))
                    }}
                    handleChange={async (value) => {
                      if (value) {
                        setauditTeamCode(value.source?.auditTeamCode)
                      }
                      form.setFieldsValue({
                        auditTeamCode: value ? value.value : null,
                        personnelCode: null,
                        personnel: null,
                        // numberOfPeople: 0,
                        // mandayPersonnal: 0,
                      })
                      setErrorField('personnel', t('กรุณาเลือกทีมตรวจสอบที่มีผู้รับผิดชอบหลัก'))
                    }}
                  />
                </Form.Item>
                <Form.Item name="personnelCode" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="personnel"
                  label={<LabelNew type="tab-header-inactive">{t('ผู้รับผิดชอบหลัก')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาเลือกทีมตรวจสอบที่มีผู้รับผิดชอบหลัก') }]}
                >
                  {/* <Input placeholder={t('ระบุผู้รับผิดชอบหลัก')} disabled={true} /> */}
                  <Select
                    data={dataSelectPerssonels}
                    placeholder={t('ระบุผู้รับผิดชอบหลัก')}
                    disabled={props.typeForm === 'view' ? true : false}
                    scrollableId={formName}
                    loading={getAuditTeamsDetailRole.loading}
                    onClear={() => {
                      form.setFieldsValue({
                        personnelCode: null,
                        personnel: null,
                      })
                    }}
                    onChange={(item) => {
                      if (item) {
                        const data = _.find(dataSelectPerssonels, (data) => data.value === item)
                        form.setFieldsValue({
                          personnelCode: data.source.personnelCode,
                          personnel: displayText(
                            data.source.namePrefixTH + ' ' + data.source.firstNameTH + ' ' + data.source.lastNameTH,
                            data.source.namePrefixEN + ' ' + data.source.firstNameEN + ' ' + data.source.lastNameEN,
                          ),
                        })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="projectSizeCode"
                  label={<LabelNew type="tab-header-inactive">{t('ขนาดโครงการ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาเลือกขนาดโครงการ') }]}
                >
                  <SelectProjectSizes
                    filters={{ isActive: 1 }}
                    formname={formName}
                    placeholder={t('เลือกขนาดโครงการ')}
                    disabled={props.typeForm === 'view' ? true : false}
                    onClear={() => {
                      form.setFieldsValue({
                        projectSizeCode: null,
                        manday: null,
                        mandayPersonnal: 0,
                      })
                      setMandayPersonnal(0)
                      setErrorField('projectSizeCode', t('กรุณาเลือกขนาดโครงการ'))
                    }}
                    handleChange={(value) => {
                      if (value) {
                        if (
                          value.source.manday &&
                          value.source.manday !== 0 &&
                          form.getFieldValue('numberOfPeople') &&
                          form.getFieldValue('numberOfPeople') !== '0'
                        ) {
                          form.setFieldsValue({
                            projectSizeCode: value ? value.value : null,
                            manday: value.source.manday,
                            mandayPersonnal: Math.floor(value.source.manday / form.getFieldValue('numberOfPeople')),
                          })
                          setMandayPersonnal(Math.floor(value.source.manday / form.getFieldValue('numberOfPeople')))
                        } else {
                          form.setFieldsValue({
                            projectSizeCode: value ? value.value : null,
                            manday: value.source.manday,
                            mandayPersonnal: 0,
                          })
                          setMandayPersonnal(0)
                        }
                        setErrorField('projectSizeCode', false)
                        setErrorField('manday', false)
                      } else {
                        form.setFieldsValue({
                          projectSizeCode: value ? value.value : null,
                          manday: null,
                          mandayPersonnal: 0,
                        })
                        setMandayPersonnal(0)
                        setErrorField('projectSizeCode', t('กรุณาเลือกขนาดโครงการ'))
                        setErrorField('manday', t('กรุณาเลือกขนาดโครงการที่มีจำนวนวันทำงาน'))
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="manday"
                  label={<LabelNew type="tab-header-inactive">{t('จำนวนวันทำงาน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาเลือกขนาดโครงการที่มีจำนวนวันทำงาน') }]}
                >
                  <InputNumber
                    placeholder={t('ระบุจำนวนวันทำงาน')}
                    min={0}
                    disabled={props.typeForm === 'view' ? true : false}
                    onChange={(value) => {
                      if (
                        value &&
                        value !== '0' &&
                        form.getFieldValue('numberOfPeople') &&
                        form.getFieldValue('numberOfPeople') !== '0'
                      ) {
                        form.setFieldsValue({
                          mandayPersonnal: Math.floor(value / form.getFieldValue('numberOfPeople')),
                        })
                        setMandayPersonnal(Math.floor(value / form.getFieldValue('numberOfPeople')))
                      } else {
                        form.setFieldsValue({
                          mandayPersonnal: 0,
                        })
                        setMandayPersonnal(0)
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="numberOfPeople"
                  label={<LabelNew type="tab-header-inactive">{t('จำนวนคน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุจำนวนคน') }]}
                  initialValue={0}
                >
                  <InputNumber
                    placeholder={t('ระบุจำนวนคน')}
                    min={0}
                    max={maxNumberOfPeople}
                    disabled={props.typeForm === 'view' ? true : false}
                    onChange={(value) => {
                      if (
                        value &&
                        value !== '0' &&
                        form.getFieldValue('manday') &&
                        form.getFieldValue('manday') !== '0'
                      ) {
                        form.setFieldsValue({
                          mandayPersonnal: Math.floor(form.getFieldValue('manday') / value),
                        })
                        setMandayPersonnal(Math.floor(form.getFieldValue('manday') / value))
                      } else {
                        form.setFieldsValue({
                          mandayPersonnal: 0,
                        })
                        setMandayPersonnal(0)
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="mandayPersonnal"
                  label={<LabelNew type="tab-header-inactive">{t('จำนวนวันทำงานต่อคน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุจำนวนคนเพื่อคำนวนจำนวนวันทำงานต่อคน') }]}
                  initialValue={0}
                >
                  <InputNumber
                    min={0}
                    placeholder={t('ระบุจำนวนวันทำงานต่อคน')}
                    onChange={(value) => {
                      setMandayPersonnal(Math.floor(parseFloat(value)))
                    }}
                    disabled={props.typeForm === 'view' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  name="dateStart"
                  labelAlign="left"
                  label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาเลือกวันที่เริ่มต้น') }]}
                >
                  <Datepicker
                    setValueDateFn={async (data) => {
                      form.setFieldsValue({
                        dateStart: await data,
                      })
                      validDate()
                    }}
                    onChange={(data) => {
                      setGetBusinessDaysReadyUse(true)
                      setDateStart(data)
                    }}
                    disabled={props.typeForm === 'view' ? true : false}
                    onClear={() => {
                      form.setFieldsValue({ dateStart: null })
                      setDateStart(null)
                    }}
                    placeholder={t('เลือกวันที่เริ่มต้น')}
                  />
                </Form.Item>
                <Form.Item
                  name="dateEnd"
                  labelAlign="left"
                  label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาเลือกวันที่สิ้นสุด') }]}
                >
                  <Datepicker
                    setValueDateFn={async (data) => {
                      form.setFieldsValue({
                        dateEnd: await data,
                      })
                      validDate()
                    }}
                    disabled={props.typeForm === 'view' ? true : false}
                    onClear={() => {
                      form.setFieldsValue({ dateEnd: null })
                    }}
                    placeholder={t('เลือกวันที่สิ้นสุด')}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]} justify="center">
              {props.typeForm !== 'view' && (
                <Col>
                  <ButtonNew
                    type="primary"
                    roles={{ type: props.typeForm, menu: props.menu }}
                    onClick={() => {
                      showConfirmGeneral()
                    }}
                    htmlType="button"
                  >
                    {t('บันทึก')}
                  </ButtonNew>
                </Col>
              )}
              <Col>
                <ButtonNew
                  type="cancel"
                  onClick={() => {
                    navigate('/auditplanning/auditplan/auditproject/list', {
                      state: { refCode: props.planCode },
                    })
                  }}
                >
                  {t('ยกเลิก')}
                </ButtonNew>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default FormAuditProjectsTeam
