import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError } from '../../../../../utilitys/helper'
import { dateDisplay } from '../../../../../utilitys/dateHelper'
import { displayText } from '../../../../../utilitys/helper'
import { Datatable } from '../../../../../components/datatable/Datatable.jsx'
import { ButtonNew } from '../../../../../components/button/Button'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { DATAFOLLOW_NUMBER_TAB_LIST } from '../../graphql/Query'
import { encryptInput } from '../../../../../utilitys/crypto'

const FormFollowUpAuditFollowNumberTabIndex = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const [dataFollownumberTabListFn, dataFollownumberTabList] = useLazyQuery(DATAFOLLOW_NUMBER_TAB_LIST)
  useEffect(() => {
    if (dataFollownumberTabList?.data) {
      let response = dataFollownumberTabList?.data?.dataFollownumberTabList
      setCount(response?.count)
      const issueId = []
      const suggestId = []
      setDataLists(
        response?.result.map((item) => {
          let issue = ''
          if (!issueId.includes(item.issueId)) {
            issue = displayText(item.issueTH, item.issueEN)
            issueId.push(item.issueId)
          }
          let suggest = ''
          if (!suggestId.includes(item.suggestId)) {
            suggest = displayText(item.suggestTH, item.suggestEN)
            suggestId.push(item.suggestId)
          }
          return {
            key: item.followTimeId,
            issue: issue,
            suggest: suggest,
            activity: displayText(item.activityTH, item.activityEN),
            followUpDetail: item.actionDetailTH ? item.actionDetailTH : item.followUpDetail,
            expectDate: item.expectDate ? dateDisplay(item.expectDate) : null,
            createdAt: item.createdAt ? dateDisplay(item.createdAt) : null,
            followTime: item.followTime,
          }
        }),
      )
    }
    if (dataFollownumberTabList.error) {
      showAlertLoadDataError(displayError(dataFollownumberTabList.error))
    }
  }, [dataFollownumberTabList?.data])

  const columns = [
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('ประเด็น/ข้อสังเกต')}</div>,
      dataIndex: 'issue',
      width: '250px',
      className: 'vertical-align-top',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('ข้อเสนอแนะ')}</div>,
      dataIndex: 'suggest',
      width: '250px',
      className: 'vertical-align-top',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('กิจกรรมที่ต้องดำเนินการ')}</div>,
      dataIndex: 'activity',
      width: '500px',
      className: 'vertical-align-top',
      render: (record) => <div style={{ wordBreak: 'break-word' }}>{record}</div>,
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('รายละเอียดการติดตาม')}</div>,
      dataIndex: 'followUpDetail',
      width: '200px',
      className: 'vertical-align-top',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('วันที่คาดว่าจะเสร็จ')}</div>,
      dataIndex: 'expectDate',
      width: '150px',
      className: 'vertical-align-top',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('วันที่ติดตาม')}</div>,
      dataIndex: 'createdAt',
      width: '150px',
      className: 'vertical-align-top',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('จำนวนครั้งที่ติดตาม')}</div>,
      dataIndex: 'followTime',
      width: '200px',
      className: 'vertical-align-top',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
  ]
  const callApiSearch = () => {
    dataFollownumberTabListFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          filters: {
            projectCode: props.refCode,
          },
          pagination: { limit, page },
          sort: [],
        }),
      },
    })
  }

  useEffect(() => {
    callApiSearch()
  }, [page, limit])

  return (
    <>
      {dataFollownumberTabList.loading && <SpinnersNew />}

      <Row>
        <Col md={24}>
          <Datatable
            columns={columns}
            data={dataLists}
            handleTableChange={() => {}}
            paginationCustom={true}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            searchCustom={true}
            total={count}
            enableScroll={{ x: true, y: true }}
            pageSize={limit}
            page={page}
            btnAdd={<></>}
          />
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="center" style={{ marginTop: '50px' }}>
        <Col>
          <ButtonNew
            type="cancel"
            onClick={() => {
              navigate('/followup/followupaudit')
            }}
          >
            {t('ยกเลิก')}
          </ButtonNew>
        </Col>
      </Row>
    </>
  )
}

export default FormFollowUpAuditFollowNumberTabIndex
