import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { SELECT_PERSONNEL } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectPersonnel = (props) => {
  const { displayCode, filters, placeholder, formname, handleChange = () => {}, ...otherProp } = props
  const {
    levelCode,
    organizationCode,
    personnelCode,
    personnaloperationCode,
    levelCodeWhereIn,
    auditTeamCode,
    personnaloperationCodeWhereIn,
    emailNotNull,
    nonePersonnelCode,
    auditTeamRole,
    organizationChecked,
  } = filters || {}

  const [isData, isSetData] = useState([])

  const [getPersonnelSelectFn, getPersonnelSelect] = useLazyQuery(SELECT_PERSONNEL)

  useEffect(() => {
    getPersonnelSelectFn({
      variables: {
        input: filters
          ? nonePersonnelCode
            ? encryptInput({ ...filters, nonePersonnelCode: _.map(JSON.parse(nonePersonnelCode), (d) => d) })
            : encryptInput({ ...filters })
          : encryptInput({}),
      },
    })
  }, [
    levelCode,
    organizationCode,
    personnelCode,
    personnaloperationCode,
    personnaloperationCodeWhereIn,
    levelCodeWhereIn,
    auditTeamCode,
    emailNotNull,
    nonePersonnelCode,
    auditTeamRole,
    organizationChecked,
  ])

  useEffect(() => {
    if (getPersonnelSelect.data) {
      isSetData(
        _.map(getPersonnelSelect?.data?.personnelSelect, (item) => {
          return {
            value: item?.personnelCode,
            label: displayCode
              ? displayText(
                  item?.personnelCode + ' : ' + item?.firstNameTH + ' ' + item?.lastNameTH,
                  item?.personnelCode + ' : ' + item?.firstNameEN + ' ' + item?.lastNameEN,
                )
              : displayText(
                  item?.personnelCode + ' : ' + item?.firstNameTH + ' ' + item?.lastNameTH,
                  item?.personnelCode + ' : ' + item?.firstNameEN + ' ' + item?.lastNameEN,
                ),
            source: item,
          }
        }),
      )
    }
  }, [getPersonnelSelect.data, displayCode])

  const setOnChange = props.multiple
    ? {
        onChange: (value) => {
          handleChange(value)
        },
      }
    : {
        onChange: (value) => {
          handleChange(_.find(isData, (data) => data.value === value))
        },
      }

  return (
    <Select
      {...otherProp}
      data={isData}
      placeholder={placeholder}
      scrollableId={formname}
      showSearch
      loading={getPersonnelSelect.loading}
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      {...setOnChange}
    />
  )
}

export default SelectPersonnel
