import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: () => (
      <div style={{ textAlign: 'left' }}>
        <Trans>โครงการ</Trans>
      </div>
    ),
    dataIndex: 'projectName',
    render: (record) => <div style={{ textAlign: 'left' }}>{record}</div>,
    width: '20%',
    sorter: { multiple: 1 },
  },
  {
    title: <Trans>แหล่งที่มาของโครงการ</Trans>,
    dataIndex: 'projectRefNameTH',
    width: '10%',
    sorter: { multiple: 2 },
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>วันที่เริ่มต้น</Trans>
      </div>
    ),
    dataIndex: 'startDateReport',
    width: '5%',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    sorter: { multiple: 3 },
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>วันที่สิ้นสุด</Trans>
      </div>
    ),
    dataIndex: 'endDateReport',
    width: '5%',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    sorter: { multiple: 4 },
  },
  {
    title: <Trans>หน่วยงานที่รับผิดชอบ</Trans>,
    dataIndex: 'organizationNameTH',
    width: '15%',
    sorter: { multiple: 5 },
  },
  {
    title: <Trans>ผู้รับผิดชอบ</Trans>,
    dataIndex: 'fullName',
    width: '10%',
    sorter: { multiple: 6 },
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '10%',
  },
]
