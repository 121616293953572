import React from 'react'
import { Button } from 'antd'
import { ButtonDowloadsStyle } from './ButtonDowloadsStyle'

export function ButtonDowloadsExcel(props) {
  let obj = {
    classBtn: 'btn-template-dowloads btn-color-dowloads',
    block: false,
    type: '',
  }

  return (
    <ButtonDowloadsStyle>
      <a
        href={process.env.REACT_APP_API_UPLOAD + `/assets/templateexcel/${props.file.fileName}`}
        download={props.file.fileName}
      >
        <Button
          {...props}
          className={obj.classBtn + ' ' + props.className}
          block={obj.block}
          type={obj.type}
          htmlType={props.htmlType ? props.htmlType : 'button'}
        >
          <div className={obj.classIcon}>{obj.icon}</div>
          {props.children}
        </Button>
      </a>
    </ButtonDowloadsStyle>
  )
}
