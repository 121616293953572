import React from 'react'
import { Route, Routes } from 'react-router-dom'
import AdhocjobIndex from './adhocjob/AdhocjobIndex'
import AdhocjobForm from './adhocjob/AdhocjobForm'
import AdhocjobPersonnels from './adhocjob/components/tab/AdhocjobPersonnels'
import AdhocjobCalendar from './adhocjob/AdhocjobCalendar'
import ApproveAdhocJobIndex from './approveadhocjob/ApproveAdhocJobIndex'
import ApproveAdhocJobForm from './approveadhocjob/ApproveAdhocJobForm'
import TimeSheetIndex from './timesheet/TimeSheetIndex'
import ActionPlanIndex from './actionplan/ActionPlanIndex'
import ActionPlanFormIndex from './actionplan/ActionPlanFormIndex'
import TimeSheetForm from './timesheet/TimeSheetForm'
import TimeSheetRequestForm from './timesheet/TimeSheetRequestForm'
import ActionPlanFormStepMain from './actionplan/tab2/ActionPlanFormStepMain'
import ActionPlanFormStepDetail from './actionplan/tab2/ActionPlanFormStepDetail'
import AuditOpensIndex from './auditopens/AuditOpensIndex'
import AuditResultIndex from './auditresult/AuditResultIndex'
import AuditResultTab from './auditresult/AuditResultTab'
import AuditResultForm from './auditresult/form/AuditResultForm.jsx'
import FormAuditOpensIndex from './auditopens/tab/FormAuditOpensIndex.jsx'
import ConsultResultIndex from './consultationresult/ConsultationResultIndex.jsx'
import ConsultationResultForm from './consultationresult/ConsultationResultForm.jsx'
import ConflictOfInterestIndex from './conflictofinterest/ConflictOfInterestList'
import TimeSheetApproveIndex from './timesheetapprove/TimeSheetApproveIndex'
import TimeSheetApproveForm from './timesheetapprove/TimeSheetApproveForm'
import TimeSheetApproveDetail from './timesheetapprove/TimeSheetApproveDetail'
import ManageAssessmentIndex from './manageassessment/ManageAssessmentIndex'
import ManageAssessmentForm from './manageassessment/ManageAssessmentForm'
import RacMatrixList from './racmatrix/RacMatrixList'
import RacMatrixForm from './racmatrix/RacMatrixForm'
import RacMatrixApproveList from './racmatrixapprove/RacMatrixApproveList'
import RacMatrixApproveForm from './racmatrixapprove/RacMatrixApproveForm'
import FormAuditOpensAdddoc from './auditopens/tab/adddoc/FormAuditOpensAdddoc.jsx'
import RacMatrixRiskForm from './racmatrix/RacMatrixRiskForm'
import RacMatrixApproveRiskForm from './racmatrixapprove/RacMatrixApproveRiskForm'
import TabThreeForm from './auditresult/form/TabThreeForm'
import NotificationForm from './auditresult/form/NotificationForm'
import AuditOpensPetition from './auditopens/AuditOpensPetition'
import AuditClosesIndex from './auditcloses/AuditClosesList'
import AuditClosesDetail from './auditcloses/AuditClosesDetail'
import AuditCloseIssueForm from './auditcloses/AuditCloseIssueForm'
import SuggestForm from './auditcloses/SuggestForm'
import EmailForm from './auditcloses/EmailForm'
import SuggestFormExit from './auditclosesexit/SuggestFormExit'
import EmailFormExit from './auditclosesexit/EmailFormExit'
import AuditCloseExitIssueForm from './auditclosesexit/AuditCloseExitIssueForm'
import AuditClosesExitIndex from './auditclosesexit/AuditClosesExitList'
import AuditClosesExitDetail from './auditclosesexit/AuditClosesExitDetail'
import AuditDocumentList from './auditdocument/AuditDocumentList'
import AuditDocumentListFiles from './auditdocument/AuditDocumentListFiles'
import AuditOpensAdddocPetition from './auditopens/tab/adddoc/AuditOpensAdddocPetition'
import AcResolutionList from './acresolution/AcResolutionList'
import AcResolutionDetail from './acresolution/AcResolutionDetail'
import AcResolutionAgenda from './acresolution/AcResolutionAgenda'
import ApproveActionPlanList from './approveactionplan/ApproveActionPlanList'
import AcknowledgeIssueIndex from './acknowledgeissue/AcknowledgeIssueIndex'
import AcknowledgmentSubList from './acknowledgeissue/AcknowledgmentSubList'
import AuditJobStepViewList from './approveactionplan/AuditJobStepViewList'
import AssessCooperationIndex from './assesscooperation/AssessCooperationIndex'
import AssessCooperationDetails from './assesscooperation/AssessCooperationDetails'
import PrepareAuditReportList from './prepareauditreport/PrepareAuditReportList'
import PrepareAuditReportForm from './prepareauditreport/PrepareAuditReportForm'
import AssessmentAuditWorkIndex from './assessmentauditwork/AssessmentAuditWorkIndex'
import AssessmentAuditWorkDetails from './assessmentauditwork/AssessmentAuditWorkDetails'
import SatisfactionAssessmentIndex from './satisfactionassessment/SatisfactionAssessmentIndex'
import SoiAuditCommitteeIndex from './soiauditcommittee/SoiAuditCommitteeIndex'
import SoiAuditCommitteeView from './soiauditcommittee/SoiAuditCommitteeView'
import SoiAuditCommitteeIssueForm from './soiauditcommittee/SoiAuditCommitteeIssueForm'
import SoiAuditCommitteeSuggestForm from './soiauditcommittee/SoiAuditCommitteeSuggestForm'
import SatisfactionAssessmentDetails from './satisfactionassessment/SatisfactionAssessmentDetails'
import ResultAssessmentIndex from './resultassessment/ResultAssessmentIndex'
import ResultAssessmentDetails from './resultassessment/ResultAssessmentDetails'
import ImportHistoricalData from './importhistoricaldata/ImportHistoricalData'

const Router = () => {
  return (
    <Routes>
      <Route path="/adhocjob">
        <Route path="" element={<AdhocjobIndex menu={'AO05'} />} />
        <Route path=":tabParam" element={<AdhocjobIndex menu={'AO05'} />} />
        <Route path="tab/responsible/personnels" element={<AdhocjobPersonnels menu={'AO05'} />} />
        <Route path="tab/responsible/personnels/calendar" element={<AdhocjobCalendar menu={'AO05'} />} />
        <Route path="tab/*" element={<AdhocjobForm menu={'AO05'} />} />
      </Route>
      <Route path="/approveadhocjob">
        <Route path="" element={<ApproveAdhocJobIndex menu={'AO06'} />} />
        <Route path="form" element={<ApproveAdhocJobForm menu={'AO06'} />} />
      </Route>
      <Route path="/timesheet">
        <Route path="" element={<TimeSheetIndex menu={'AO07'} />} />
        <Route path="requestapprove" element={<TimeSheetIndex menu={'AO07'} />} />
        <Route path="form" element={<TimeSheetForm menu={'AO07'} />} />
        <Route path="requestapprove/form" element={<TimeSheetRequestForm menu={'AO07'} />} />
      </Route>
      <Route path="/timesheetapprove">
        <Route path="" element={<TimeSheetApproveIndex menu={'AO10'} />} />
        <Route path="form" element={<TimeSheetApproveForm menu={'AO10'} />} />
        <Route path="timesheet/detail" element={<TimeSheetApproveDetail menu={'AO10'} />} />
      </Route>
      <Route path="/auditresult">
        <Route path="" element={<AuditResultIndex menu={'AO08'} />} />
        <Route path="*" element={<AuditResultTab menu={'AO08'} />} />
        {/* <Route path="/formtabthree" element={<TabThreeForm menu={'AO08'} />} /> */}
        <Route path="form">
          <Route path="*" element={<AuditResultForm menu={'AO08'} />} />
        </Route>
        <Route path="formtab">
          <Route path="three" element={<TabThreeForm menu={'AO08'} />} />
          <Route path="notification" element={<NotificationForm menu={'AO08'} />} />
        </Route>
      </Route>
      <Route path="/actionplan">
        <Route path="" element={<ActionPlanIndex menu={'AO01'} />} />
        <Route path=":tabParam" element={<ActionPlanIndex menu={'AO01'} />} />
        <Route path="form">
          <Route path="" element={<ActionPlanFormIndex menu={'AO01'} />} />
          <Route path="main" element={<ActionPlanFormStepMain menu={'AO01'} />} />
          <Route path="detail" element={<ActionPlanFormStepDetail menu={'AO01'} />} />
        </Route>
      </Route>
      <Route path="/auditopens">
        <Route path="" element={<AuditOpensIndex menu={'AO14'} />} />
        <Route path="information/add" element={<FormAuditOpensIndex menu={'AO14'} />} />
        <Route path="information/edit" element={<FormAuditOpensIndex menu={'AO14'} />} />
        <Route path="information/view" element={<FormAuditOpensIndex menu={'AO14'} />} />
        <Route path="petition" element={<AuditOpensPetition menu={'AO14'} />} />
        <Route path="adddoc/edit" element={<FormAuditOpensIndex menu={'AO14'} />} />
        <Route path="adddoc/view" element={<FormAuditOpensIndex menu={'AO14'} />} />
        <Route path="adddoc/edit/petition" element={<AuditOpensAdddocPetition menu={'AO14'} />} />
        <Route path="adddoc/edit/formdoc/add" element={<FormAuditOpensAdddoc menu={'AO14'} />} />
        <Route path="adddoc/edit/formdoc/edit" element={<FormAuditOpensAdddoc menu={'AO14'} />} />
        <Route path="adddoc/edit/formdoc/view" element={<FormAuditOpensAdddoc menu={'AO14'} />} />
        <Route path="adddoc/view/formdoc/view" element={<FormAuditOpensAdddoc menu={'AO14'} />} />
      </Route>
      <Route path="/racmatrix">
        <Route path="" element={<RacMatrixList menu={'AO03'} />} />
        <Route path="tab/risk/form" element={<RacMatrixRiskForm menu={'AO03'} />} />
        <Route path="tab/*" element={<RacMatrixForm menu={'AO03'} />} />
      </Route>
      <Route path="/racmatrixapprove">
        <Route path="" element={<RacMatrixApproveList menu={'AO09'} />} />
        <Route path="tab/risk/form" element={<RacMatrixApproveRiskForm menu={'AO09'} />} />
        <Route path="tab/*" element={<RacMatrixApproveForm menu={'AO09'} />} />
      </Route>
      <Route path="/acknowledgeissue">
        <Route path="" element={<AcknowledgeIssueIndex menu={'AO12'} />} />
        <Route path="details" element={<AcknowledgmentSubList menu={'AO12'} />} />
      </Route>
      <Route path="/consultationresult">
        <Route path="" element={<ConsultResultIndex menu={'AO11'} />} />
        <Route path="form" element={<ConsultationResultForm menu={'AO11'} />} />
      </Route>
      <Route path="/conflictofinterest">
        <Route path="" element={<ConflictOfInterestIndex menu={'AO02'} />} />
      </Route>
      <Route path="/auditcloses">
        <Route path="" element={<AuditClosesIndex menu={'AO13'} />} />
        <Route path="auditclosesdetail">
          <Route path="" element={<AuditClosesDetail menu={'AO13'} />} />
          <Route path="suggestform" element={<SuggestForm menu={'AO13'} />} />
          <Route path="emailform" element={<EmailForm menu={'AO13'} />} />
          <Route path="auditIssue" element={<AuditCloseIssueForm menu={'AO13'} />} />
        </Route>
      </Route>
      <Route path="/auditclosesexit">
        <Route path="" element={<AuditClosesExitIndex menu={'AO24'} />} />
        <Route path="auditclosesexitdetail">
          <Route path="" element={<AuditClosesExitDetail menu={'AO24'} />} />
          <Route path="suggestform" element={<SuggestFormExit menu={'AO24'} />} />
          <Route path="emailform" element={<EmailFormExit menu={'AO24'} />} />
          <Route path="auditIssue" element={<AuditCloseExitIssueForm menu={'AO24'} />} />
        </Route>
      </Route>
      <Route path="/createassessmentform">
        <Route path="" element={<ManageAssessmentIndex menu={'AO14'} />} />
        <Route path="createassessmentformdetail">
          <Route path="" element={<ManageAssessmentForm menu={'AO14'} />}>
            <Route path="*" element={<ManageAssessmentForm menu={'AO14'} />} />
          </Route>
        </Route>
      </Route>

      <Route path="/auditdocument">
        <Route path="" element={<AuditDocumentList menu={'AO22'} />} />
        <Route path="auditdocumentfiles" element={<AuditDocumentListFiles menu={'AO22'} />} />
      </Route>
      <Route path="/acresolution">
        <Route path="" element={<AcResolutionList menu={'AO19'} />} />
        <Route path="detail">
          <Route path="" element={<AcResolutionDetail menu={'AO19'} />} />
          <Route path="agenda" element={<AcResolutionAgenda menu={'AO19'} />} />
        </Route>
      </Route>

      <Route path="/approveactionplan">
        <Route path="" element={<ApproveActionPlanList menu={'AO04'} />} />
        <Route path="detail" element={<AuditJobStepViewList menu={'AO04'} />} />
      </Route>

      <Route path="/assesscooperation">
        <Route path="" element={<AssessCooperationIndex menu={'AO21'} />} />
        <Route path="assesscooperationdetails">
          <Route path="" element={<AssessCooperationDetails menu={'AO21'} />}>
            <Route path="*" element={<AssessCooperationDetails menu={'AO21'} />} />
          </Route>
        </Route>
      </Route>

      <Route path="/prepareauditreport">
        <Route path="" element={<PrepareAuditReportList menu={'AO23'} />} />
        <Route path="tab/*" element={<PrepareAuditReportForm menu={'AO23'} />} />
      </Route>
      <Route path="/assessmentaudit">
        <Route path="" element={<AssessmentAuditWorkIndex menu={'AO17'} />} />
        <Route path="assessmentauditdetails">
          <Route path="" element={<AssessmentAuditWorkDetails menu={'AO17'} />}>
            <Route path="*" element={<AssessmentAuditWorkDetails menu={'AO17'} />} />
          </Route>
        </Route>
      </Route>

      <Route path="/satisfactionassessment">
        <Route path="" element={<SatisfactionAssessmentIndex menu={'AO16'} />} />
        <Route path="details">
          <Route path="" element={<SatisfactionAssessmentDetails menu={'AO16'} />}>
            <Route path="*" element={<SatisfactionAssessmentDetails menu={'AO16'} />} />
          </Route>
        </Route>
      </Route>
      <Route path="/soiauditcommittee">
        <Route path="" element={<SoiAuditCommitteeIndex menu={'AO20'} />} />
        <Route path="view">
          <Route path="" element={<SoiAuditCommitteeView menu={'AO20'} />} />
          <Route path="auditIssue" element={<SoiAuditCommitteeIssueForm menu={'AO20'} />} />
          <Route path="auditSuggest" element={<SoiAuditCommitteeSuggestForm menu={'AO20'} />} />
        </Route>
      </Route>
      <Route path="/assessmentformresults">
        <Route path="" element={<ResultAssessmentIndex menu={'AO18'} />} />
        <Route path="details">
          <Route path="" element={<ResultAssessmentDetails menu={'AO18'} />}>
            <Route path="*" element={<ResultAssessmentDetails menu={'AO18'} />} />
          </Route>
        </Route>
      </Route>
      <Route path="/importhistoricaldata">
        <Route path="" element={<ImportHistoricalData menu={'AO25'} />} />
      </Route>
    </Routes>
  )
}
export default Router
