import { gql } from '@apollo/client'
export const GET_MASTER_CONTROL_TYPES = gql`
  query GetMasterControlTypes($input: MasterControlTypesInput!) {
    getMasterControlTypes(input: $input) {
      controlTypeId
      controlTypeCode
      controlTypeNameTH
      controlTypeNameEN
      isActive
    }
  }
`
