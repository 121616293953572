import React, { useState } from 'react'
import { Row, Col } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { decryptData } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import RiskListFilter from './filters/AssessRiskListFilter'
import ForwardAssessRickModal from './modal/ForwardAssessRickModal'
import { RowStyle } from './css/Styles'
import { Status } from '../../../components/status/Status'
const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
}
const AssessRiskList = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const menu = props.menu
  const { pCode } = decryptData(localStorage.getItem('user'))
  const lang = localStorage.getItem('lang')
  const [dataLists, setDataLists] = useState([])
  const [operationStatusCode, setOperationStatusCode] = useState([])
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' },
  ])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [data, setData] = useState({})
  const [reFetch, setReFetch] = useState(false)
  const [modal, setModal] = useState(false)
  const Columns = [
    {
      title: t('ประจำปี'),
      dataIndex: 'Year',
      width: '5%',
      sorter: { multiple: 1 },
    },
    {
      title: t('รหัส'),
      dataIndex: 'riskFormCode',
      width: '5%',
      sorter: { multiple: 2 },
    },
    {
      title: t('ชื่อแบบประเมิน'),
      dataIndex: 'riskFormTH',
      width: '20%',
      sorter: { multiple: 3 },
    },
    {
      title: t('ประเภทประเมิน'),
      dataIndex: 'riskFormType',
      width: '10%',
      render: (row) => {
        return row === 1 ? (
          <>
            <span>{t('ประเมินสรุป')}</span>
          </>
        ) : (
          <>
            <span>{t('ประเมินรายหน่วยงาน')}</span>
          </>
        )
      },
    },
    {
      title: t('สถานะ'),
      dataIndex: 'operationStatusCode',
      width: '10%',
      render: (operationStatusCode, record) => {
        // ผู้รับผิดชอบโดยตรง
        if (record?.responsibilityBy === pCode) {
          switch (operationStatusCode) {
            case '11':
              return <Status text={t('ยังไม่ประเมิน')} />
            case '12':
              return <Status text={t('ส่งต่อแบบประเมิน')} />
            case '21':
              return <Status text={t('กำลังดำเนินการ')} />
            case '22':
              return <Status text={t('รอตรวจสอบ')} />
            case '31':
              return <Status text={t('ประเมินเสร็จสิ้น')} />
            default:
              return <></>
          }
        }
        // ผู้ที่ได้รับหมอบหมาย
        if (record?.makerBy === pCode) {
          switch (record?.makerStatusCode) {
            case '11':
              return <Status text={t('ยังไม่ประเมิน')} />
            case '21':
              return <Status text={t('กำลังดำเนินการ')} />
            case '22':
              return <Status text={t('รอตรวจสอบ')} />
            case '31':
              return <Status text={t('ประเมินเสร็จสิ้น')} />
            default:
              return <></>
          }
        }
      },
    },
    {
      title: '',
      dataIndex: 'operationStatusCodeBtn',
      width: '8%',
      render: (field, record) => {
        // ผู้รับผิดชอบโดยตรง
        if (record?.responsibilityBy === pCode) {
          switch (field) {
            case '11':
              return (
                <>
                  <ButtonNew
                    type="flagOutlinedInTableBlue"
                    roles={{ type: 'edit', menu: menu }}
                    onClick={() =>
                      navigate(`assessriskdetail`, {
                        state: {
                          refCode: record?.riskResultId,
                          type: 'update',
                        },
                      })
                    }
                  >
                    {t('เริ่มประเมิน')}
                  </ButtonNew>
                </>
              )
            case '12':
              return (
                <>
                  <ButtonNew type="moreHorizontalInTableOrange" roles={{ type: 'edit', menu: menu }} onClick={() => {}}>
                    {t('มอบหมาย')}
                  </ButtonNew>
                </>
              )
            case '21':
              if (record.makerStatusCode === '21') {
                return (
                  <>
                    <ButtonNew
                      type="moreHorizontalInTableOrange"
                      roles={{ type: 'edit', menu: menu }}
                      onClick={() => {}}
                    >
                      {t('กำลังดำเนินการ')}
                    </ButtonNew>
                  </>
                )
              } else {
                return (
                  <>
                    <ButtonNew
                      type="moreHorizontalInTableOrange"
                      roles={{ type: 'edit', menu: menu }}
                      onClick={() => {
                        navigate(`assessriskdetail`, {
                          state: {
                            refCode: record.riskResultId,
                            type_action: 'update',
                          },
                        })
                      }}
                    >
                      {t('กำลังดำเนินการ')}
                    </ButtonNew>
                  </>
                )
              }
            case '22':
              return (
                <>
                  <ButtonNew
                    type="moreHorizontalInTableOrange"
                    roles={{ type: 'edit', menu: menu }}
                    onClick={() => {
                      navigate(`assessriskdetail`, {
                        state: {
                          refCode: record.riskResultId,
                          type_action: 'update',
                        },
                      })
                    }}
                  >
                    {t('รอตรวจสอบ')}
                  </ButtonNew>
                </>
              )
            case '31':
              return (
                <>
                  <ButtonNew
                    type="moreHorizontalInTableGreen"
                    roles={{ type: 'view', menu: menu }}
                    onClick={() => {
                      navigate(`assessriskdetail`, {
                        state: { refCode: record.riskResultId, type_action: 'view' },
                      })
                    }}
                  >
                    {t('ดูผลการประเมิน')}
                  </ButtonNew>
                </>
              )
            default:
              return <></>
          }
        }
        // ผู้ที่ได้รับหมอบหมาย
        if (record?.makerBy === pCode) {
          switch (record?.makerStatusCode) {
            case '11':
              return (
                <>
                  <ButtonNew
                    type="flagOutlinedInTableBlue"
                    roles={{ type: 'edit', menu: menu }}
                    onClick={() =>
                      navigate(`assessriskdetail`, {
                        state: {
                          refCode: record?.riskResultId,
                          type: 'update',
                        },
                      })
                    }
                  >
                    {t('เริ่มประเมิน')}
                  </ButtonNew>
                </>
              )
            case '21':
              return (
                <>
                  <ButtonNew
                    type="moreHorizontalInTableOrange"
                    roles={{ type: 'edit', menu: menu }}
                    onClick={() =>
                      navigate(`assessriskdetail`, { state: { refCode: record?.riskResultId, type: 'update' } })
                    }
                  >
                    {t('กำลังดำเนินการ')}
                  </ButtonNew>
                </>
              )
            case '22':
              return (
                <>
                  <ButtonNew
                    type="moreHorizontalInTableOrange"
                    roles={{ type: 'edit', menu: menu }}
                    onClick={() => {
                      navigate(`assessriskdetail`, {
                        state: {
                          refCode: record.riskResultId,
                          type_action: 'view',
                        },
                      })
                    }}
                  >
                    {t('รอตรวจสอบ')}
                  </ButtonNew>
                </>
              )
            case '31':
              return (
                <>
                  <ButtonNew
                    type="moreHorizontalInTableGreen"
                    roles={{ type: 'view', menu: menu }}
                    onClick={() => {
                      navigate(`assessriskdetail`, {
                        state: { refCode: record.riskResultId, type_action: 'view' },
                      })
                    }}
                  >
                    {t('ดูผลการประเมิน')}
                  </ButtonNew>
                </>
              )
            default:
              return <></>
          }
        }
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '5%',
    },
  ]
  function getDataListFromFilter(value) {
    setDataLists([
      ...value.map((item, idx) => {
        const data_ = {
          riskResultId: item.riskResultId,
          riskFormTH: item.riskFormTH,
          riskFormEN: item.riskFormEN,
          riskModelTH: item.riskModelTH,
          riskModelEN: item.riskModelEN,
          organizationNameTH: item.organizationNameTH,
          organizationNameEN: item.organizationNameEN,
          riskFormType: item.riskFormType,
        }
        const btn = [
          {
            type: 'send',
            onClick: () =>
              Promise.all([setOperationStatusCode(item.operationStatusCode), setData(data_), setModal(true)]),
          },
          {
            type: 'view',
            path: '/button/view',
            onClick: () => {
              navigate(`assessriskdetail`, {
                state: {
                  refCode: item.riskResultId,
                  type_action: 'view',
                },
              })
            },
          },
        ]
        const isResponsibility = item.responsibilityBy === pCode
        const isResponsibilityShiftBtn = item.operationStatusCode !== '11' && item.operationStatusCode !== '12'
        const isMaker = item.makerBy === pCode
        const isMakerShiftBtn = item.makerStatusCode === null || item.makerStatusCode !== '11'
        if (isResponsibility) {
          if (isResponsibilityShiftBtn && isMakerShiftBtn) {
            btn.shift()
          }
        } else if (isMaker) {
          btn.shift()
        }

        const isExpire =
          (new Date(item.dateEnd) < new Date() && item.operationStatusCode === '11') ||
          item.operationStatusCode === '12'
        return {
          key: idx + 1,
          isExpire: isExpire,
          Year: lang === 'en' ? item.Year : item.Year + 543,
          riskFormCode: item.riskFormCode,
          riskResultId: item.riskResultId,
          riskFormTH: item.riskFormTH,
          riskFormType: item.riskFormType,
          makerBy: item.makerBy,
          responsibilityBy: item.responsibilityBy,
          operationStatusCode: item.operationStatusCode,
          makerStatusCode: item.makerStatusCode,
          operationStatusCodeBtn: item.operationStatusCode,
          tools: (
            <>
              <ButtonGroup menu={menu} icons={btn} />
            </>
          ),
        }
      }),
    ])
  }
  const breadcrumbList = [
    { label: t('งานประเมินความเสี่ยง'), path: '/riskassessment/assessrisk' },
    { label: t('ประเมินความเสี่ยง') },
  ]
  return (
    <>
      <Breadcrumb data={breadcrumbList} title={t('แบบประเมินความเสี่ยง')} />
      <RiskListFilter
        page={page}
        limit={limit}
        sort={sort}
        menu={menu}
        reFetch={reFetch}
        getCount={(e) => setCount(e)}
        getValue={(v) => getDataListFromFilter(v)}
      />
      <CardNew topic={t('รายการแบบประเมินความเสี่ยง')} icon="List" toggledrop={'false'}>
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <RowStyle>
              <Datatable
                rowClassName={(_) => (_.isExpire ? 'is-expire' : '')}
                columns={Columns}
                data={dataLists}
                paginationCustom={true}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                searchCustom={true}
                total={count}
                pageSize={limit}
                page={page}
                handleTableChange={(e) => {
                  const reNewField = [
                    { riskFormName: displayText('riskFormTH', 'riskFormEN') },
                    { Year: 'Year' },
                    { riskFormCode: 'riskFormCode' },
                  ]
                  setSort(handleSort(e?.sorter, reNewField))
                }}
              />
            </RowStyle>
          </Col>
        </Row>
      </CardNew>
      <ForwardAssessRickModal
        data={data}
        operationStatusCode={operationStatusCode}
        open={modal}
        onSuccess={(e) => {
          setReFetch(reFetch ? false : true)
          setModal(e)
        }}
        close={(e) => setModal(e)}
      />
    </>
  )
}

export default AssessRiskList
