import React, { useEffect, useState } from 'react'
import { Col, Row, Form } from 'antd'
import { useTranslation } from 'react-i18next'

//components
import { CardNew } from '../../../components/card/Card'
import { LabelNew } from '../../../components/label/Label'
import { InputNew } from '../../../components/input/Input'
import SelectMasterOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectRoles from '../../../components/inputfromapi/selectroles/SelectRoles'
import SelectUserActive from './components/select/SelectUserActive'
import { ButtonNew } from '../../../components/button/Button'
import ExportModalList from './components/modal/ExportModalList'
import SendEmailReportModal from '../components/SendEmailReportModal'
import { encryptInput } from '../../../utilitys/crypto'
import { Alert } from '../../../components/alert/Alert'
import { displayError } from '../../../utilitys/helper'
import { useLazyQuery } from '@apollo/client'
import { GET_EXPORT_SYSTEM_USERS_LIST_REPORT } from './graphql/Query'

const SystemUsersReportFilters = ({ getValue = () => {}, menu }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const formName = 'SystemUsersReportFilters'

  const [modalVisible, setmodalVisible] = useState(false)
  const [filterData, setfilterData] = useState({})

  const onFinish = () => {
    const field = form.getFieldValue()
    setfilterData(field)
    getValue(field)
  }

  const Responesive = {
    inputFilter: {
      md: 8,
      lg: 8,
    },
  }

  //=========================== สำหรับการส่งเมลรายงาน ===========================

  const [exportSystemUsersReportMail, dataExportSystemUsersReportMail] = useLazyQuery(
    GET_EXPORT_SYSTEM_USERS_LIST_REPORT,
  )

  useEffect(() => {
    if (dataExportSystemUsersReportMail.data) {
      Alert({
        type: 'success',
        title: t('ส่งอีเมลสำเร็จ'),
        onOk() {},
      })
    }
    if (dataExportSystemUsersReportMail.error) {
      const err = dataExportSystemUsersReportMail.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [dataExportSystemUsersReportMail?.data])

  //=========================== Email Modal ===========================
  const [emailModalVisible, setemailModalVisible] = useState(false)
  const [mailValue, setMailValue] = useState()
  const [inputSendEmail, setInputSendEmail] = useState()

  useEffect(() => {
    if (inputSendEmail) {
      const dataInput = {
        filters: filterData,
        lang: localStorage.getItem('lang'),
        fileType: inputSendEmail.fileType,
        emailData: inputSendEmail, // สำหรับการส่งเมลรายงาน
      }

      // Call API Export
      exportSystemUsersReportMail({
        variables: {
          input: encryptInput(dataInput),
        },
      })
    }
  }, [inputSendEmail])

  const fncOnpressEmailSetting = () => {
    setMailValue({ reportName: 'รายงานข้อมูลผู้ใช้งานและสิทธิ์การใช้งาน', refCode: null })
    setemailModalVisible(true)
  }

  //=========================== =========================== ====

  return (
    <CardNew topic={t('Filters')} icon="Filter" toggledrop={'false'}>
      <ExportModalList
        filterData={filterData}
        visible={modalVisible}
        onModalClose={() => {
          setmodalVisible(false)
        }}
      />
      <SendEmailReportModal
        menu={menu}
        open={emailModalVisible}
        close={(e) => {
          setemailModalVisible(e)
        }}
        fileType={{ pdf: false, word: false, excel: true, csv: true }}
        setInputSendEmail={(e) => setInputSendEmail(e)}
        mailValue={mailValue}
      />
      <Row style={{ marginTop: 24 }}>
        <Col sm={24} md={24} lg={24}>
          <Form form={form} onFinish={onFinish} name={formName} layout="vertical">
            <Row gutter={[16, 8]}>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="username"
                  rules={[{ required: false, message: 'required' }]}
                  label={<LabelNew type="tab-header-inactive">{t('ชื่อผู้ใช้งาน')}</LabelNew>}
                >
                  <InputNew placeholder={t('ระบุชื่อผู้ใช้งาน')} />
                </Form.Item>
              </Col>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="fullname"
                  rules={[{ required: false, message: 'required' }]}
                  label={<LabelNew type="tab-header-inactive">{t('ชื่อ-นามสกุล')}</LabelNew>}
                >
                  <InputNew placeholder={t('ระบุชื่อ-นามสกุล')} />
                </Form.Item>
              </Col>
              {/* <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="email"
                  rules={[{ required: false, message: 'required' }]}
                  label={<LabelNew type="tab-header-inactive">{t('อีเมล')}</LabelNew>}
                >
                  <InputNew placeholder={t('ระบุอีเมล')} />
                </Form.Item>
              </Col> */}
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="organizationCode"
                  label={<LabelNew type="tab-header-inactive">{t('หน่วยงานที่สังกัด')}</LabelNew>}
                >
                  <SelectMasterOrganization
                    placeholder={t('เลือกหน่วยงานที่สังกัด')}
                    formname={formName}
                    filters={{ checkedList: JSON.stringify([1, 2, 3]), isActive: 1 }}
                    handleChange={(e) => form.setFieldsValue({ organizationCode: e?.value })}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="roleCode"
                  label={<LabelNew type="tab-header-inactive">{t('กลุ่มสิทธิ์การใช้งาน')}</LabelNew>}
                >
                  <SelectRoles
                    placeholder={t('เลือกกลุ่มสิทธิ์การใช้งาน')}
                    formname={formName}
                    handleChange={(e) => {
                      form.setFieldsValue({ roleCode: e })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="isActive"
                  initialValue={1}
                  label={<LabelNew type="tab-header-inactive">{t('สถานะของการใช้งาน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาเลือกสถานะของการใช้งาน') }]}
                >
                  <SelectUserActive
                    formname={formName}
                    placeholder={t('เลือกสถานะของการใช้งาน')}
                    handleChange={(e) => {
                      form.setFieldsValue({ isActive: e?.value })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]} justify="center" style={{ marginTop: '2rem' }}>
              <Col>
                <ButtonNew type="search" htmlType="submit">
                  {t('ค้นหา')}
                </ButtonNew>
              </Col>
              <Col>
                <ButtonNew
                  type="printerSuccess"
                  menu={menu}
                  onClick={() => {
                    //   Promise.all([setExportStatus(1), setVisible(true)])
                    form.validateFields().then(() => {
                      const field = form.getFieldValue()
                      setfilterData(field)
                      setmodalVisible(true)
                    })
                  }}
                >
                  {t('พิมพ์')}
                </ButtonNew>
              </Col>
              <Col>
                <ButtonNew type="clear" onClick={() => form.resetFields()}>
                  {t('ล้างค่า')}
                </ButtonNew>
              </Col>
              <Col>
                <ButtonNew
                  type="success"
                  menu={menu}
                  onClick={() => {
                    form.validateFields().then(() => {
                      const field = form.getFieldValue()
                      setfilterData(field)

                      fncOnpressEmailSetting()
                    })
                  }}
                >
                  {t('ส่งรายงาน')}
                </ButtonNew>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </CardNew>
  )
}

export default SystemUsersReportFilters
