import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'antd'
import { useMutation } from '@apollo/client'

import { ModalNew as Modal } from '../../../components/modal/Modal'
import { InputNew as Input } from '../../../components/input/Input'
import { Radio, RadioGroup } from '../../../components/radio/Radio'
import { TextAreaNew } from '../../../components/textarea/TextArea'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'

import { displayError, regexEng, regexEngNoSpace } from '../../../utilitys/helper'

import { SAVE_RISK_PROCESS } from './graphql/Mutation'
import { encryptInput } from '../../../utilitys/crypto'

const RiskProcessListForm = ({ visible = false, onClose = () => {}, onSuccess = () => {} }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_RISK_PROCESS)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          onSuccess(dataSave.saveRiskProcess)
        },
      })
    }
  }, [dataSave])

  const onFinish = (data) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        const dataInput = {
          rateFacGroupCode: data.rateFacGroupCode,
          rateFacGroupNameTH: data.rateFacGroupNameTH,
          rateFacGroupNameEN: data.rateFacGroupNameEN ? data.rateFacGroupNameEN : data.rateFacGroupNameTH,
          isActive: data.isActive,
          typeAction: 'add',
        }
        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  return (
    <React.Fragment>
      {loadingSave && <SpinnersNew />}
      <Modal
        visible={visible}
        onSubmit={form.submit}
        onClose={(data) => {
          onClose(data)
          form.resetFields()
        }}
        testTitle={t('เพิ่มกลุ่มความเสี่ยงกระบวนการ')}
        typeAction={'add'}
        type="medium"
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 8,
            lg: 8,
          }}
          layout="horizontal"
          name="riskFactorForm"
        >
          <Form.Item
            name="rateFacGroupCode"
            label={t('รหัสกลุ่มความเสี่ยงกระบวนการ')}
            rules={[
              { required: true, message: t('กรุณาระบุ') },
              {
                pattern: regexEngNoSpace,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษพิมพ์ใหญ่,ตัวเลข A-Z, 0-9 ไม่เกิน 10 ต้ว`),
              },
            ]}
          >
            <Input placeholder={t('กรอกรหัสปัจจัยความเสี่ยง')} />
          </Form.Item>

          <Form.Item
            name="rateFacGroupNameTH"
            label={t('ชื่อกลุ่มความเสี่ยงกระบวนการภาษาไทย')}
            rules={[{ required: true, message: t('กรุณาระบุ') }]}
          >
            <TextAreaNew maxLength={8000} rows={5} />
          </Form.Item>
          <Form.Item
            name="rateFacGroupNameEN"
            label={t('ชื่อกลุ่มความเสี่ยงกระบวนการภาษาอังกฤษ')}
            rules={[{ pattern: regexEng, message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`) }]}
          >
            <TextAreaNew maxLength={8000} rows={5} />
          </Form.Item>

          <Form.Item name="isActive" label={t('สถานะ')} initialValue={1}>
            <RadioGroup>
              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                </Col>
                <Col>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}
export default RiskProcessListForm
