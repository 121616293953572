import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'antd'
import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Status } from '../../../components/status/Status.jsx'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayError, displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import PrepareAuditReportListFilter from './components/filter/PrepareAuditReportListFilter'
import PrepareAuditReportExport from './components/modal/PrepareAuditReportExport'
import PrepareAuditReportSendMail from './components/modal/PrepareAuditReportSendMail'
import { DELETE_PREPARE_AUDIT_REPORT } from './graphql/Mutation'
import { GET_LIST_PREPARE_AUDIT_REPORT_PAGINATION } from './graphql/Query'
import { PrepareAuditReportListCrumb } from './utils/constants'

const PrepareAuditReportList = (props) => {
  const { menu } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState([])
  const [dataForSendMail, setDataForSendMail] = useState({})
  const [dataForExport, setDataForExport] = useState({})
  const [prepareAuditReportSendMailModal, setPrepareAuditReportSendMailModal] = useState(false)
  const [prepareAuditReportExportModal, setPrepareAuditReportExportModal] = useState(false)

  const [getListPrepareAuditReportPaginationfn, getListPrepareAuditReportPagination] = useLazyQuery(
    GET_LIST_PREPARE_AUDIT_REPORT_PAGINATION
  )
  const [deletePrepareAuditReportfn, deletePrepareAuditReport] = useMutation(DELETE_PREPARE_AUDIT_REPORT)

  useEffect(() => {
    getListPrepareAuditReportPaginationfn({
      variables: { input: encryptInput({ filters: { ...filters }, pagination: { limit, page }, sort }) }
    })
  }, [page, limit, filters, sort])

  useEffect(() => {
    if (getListPrepareAuditReportPagination?.data) {
      let response = getListPrepareAuditReportPagination?.data?.getListPrepareAuditReportPagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            ...data,
            key: data.finalReportId,
            documentNo: data.documentNo,
            finalReportId: data.finalReportId,
            projectName: data.projectCode
              ? displayText(`${data.projectCode}: ${data.projectNameTH}`, `${data.projectCode}: ${data.projectNameEN}`)
              : '',
            startDateReport: dateDisplay(data.startDateReport),
            endDateReport: dateDisplay(data.endDateReport),
            organization: displayText(data.organizationNameTH, data.organizationNameEN)
          }
        })
      )
    }
    if (getListPrepareAuditReportPagination.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getListPrepareAuditReportPagination.error))
      })
    }
  }, [getListPrepareAuditReportPagination?.data])

  useEffect(() => {
    if (deletePrepareAuditReport?.data) {
      Alert({
        type: 'success',
        title: t('ลบข้อมูลสำเร็จ'),
        onOk() {
          getListPrepareAuditReportPagination.refetch()
        }
      })
    }

    if (deletePrepareAuditReport.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(deletePrepareAuditReport.error))
      })
    }
  }, [deletePrepareAuditReport.data])

  const onFilterPrepareAuditReportListFilter = (data) => {
    setFilters(data)
  }

  const onDeletePrepareAuditReport = (finalReportId) =>
    Alert({
      type: 'confirm',
      title: t('กรุณากดยืนยันหากต้องการลบข้อมูล'),
      onOk() {
        deletePrepareAuditReportfn({
          variables: { input: encryptInput({ finalReportId: finalReportId }) }
        })
      }
    })

  const columns = [
    { title: t('ลำดับ'), dataIndex: 'no', width: '10%', align: 'center', sorter: { multiple: 1 } },
    { title: t('เรื่อง'), dataIndex: 'projectName', width: '25%', align: 'left', sorter: { multiple: 2 } },
    { title: t('หน่วยรับตรวจ'), dataIndex: 'organization', width: '20%', sorter: { multiple: 3 }, align: 'left' },
    {
      title: t('วันที่เริ่มต้นรายงาน'),
      dataIndex: 'startDateReport',
      width: '12%',
      sorter: { multiple: 4 },
      align: 'center'
    },
    {
      title: t('วันที่สิ้นสุดรายงาน'),
      dataIndex: 'endDateReport',
      width: '12%',
      align: 'center',
      sorter: { multiple: 5 }
    },
    {
      title: t('สถานะการบันทึก'),
      dataIndex: 'status',
      width: '11%',
      align: 'center',
      sorter: { multiple: 6 },
      render: (data) => {
        return <Status text={data === 0 ? t('บันทึกร่าง') : t('เสร็จสิ้น')} />
      }
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '10%',
      align: 'center',
      render: (text, data) => {
        let list = [
          {
            type: 'download',
            onClick: () => {
              setDataForExport({
                ...dataForExport,
                ...data,
                typeAction: 'export'
              })
              setPrepareAuditReportExportModal(true)
            }
          },
          {
            disabled: data.status === 0 ? true : false,
            type: 'send',
            onClick: () => {
              setDataForSendMail({
                ...dataForSendMail,
                ...data,
                typeAction: 'send'
              })
              setPrepareAuditReportSendMailModal(true)
            }
          },
          {
            type: 'view',
            onClick: () =>
              navigate(`/auditoperations/prepareauditreport/tab/general`, {
                state: { typeAction: 'view', finalReportId: data.finalReportId, documentNo: data.documentNo }
              })
          },
          {
            disabled: data.status === 1 ? true : false,
            type: 'edit',
            onClick: () =>
              navigate(`/auditoperations/prepareauditreport/tab/general`, {
                state: { typeAction: 'edit', finalReportId: data.finalReportId, documentNo: data.documentNo }
              })
          },
          {
            disabled: data.status === 1 ? true : false,
            type: 'delete',
            onClick: () => {
              onDeletePrepareAuditReport(data.finalReportId)
            }
          }
        ]
        return <ButtonGroup menu={menu} size={18} icons={list} />
      }
    }
  ]

  return (
    <>
      {(getListPrepareAuditReportPagination?.loading || deletePrepareAuditReport.loading) && <SpinnersNew />}
      <Col sm={24} md={24} lg={24}>
        <Breadcrumb data={PrepareAuditReportListCrumb} title={t('จัดทำรายงานตรวจสอบ')} />
        <PrepareAuditReportListFilter
          onFilterPrepareAuditReportListFilter={onFilterPrepareAuditReportListFilter}
          menu={menu}
        />
        <PrepareAuditReportSendMail
          menu={menu}
          closeModal={(e) => setPrepareAuditReportSendMailModal(e)}
          prepareAuditReportSendMailModal={prepareAuditReportSendMailModal}
          dataForSendMail={dataForSendMail}
        />
        <PrepareAuditReportExport
          menu={menu}
          closeModal={(e) => setPrepareAuditReportExportModal(e)}
          prepareAuditReportExportModal={prepareAuditReportExportModal}
          dataForExport={dataForExport}
        />
        <div style={{ marginBottom: '2rem' }}>
          <CardNew topic={t('รายการรายงานผลการตรวจสอบ')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col sm={24} md={24} lg={24} xl={24}>
                <Datatable
                  columns={columns}
                  data={[...dataLists]}
                  total={count}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  enableScroll={{ x: true, y: false }}
                  pageSize={limit}
                  page={page}
                  showSizeChanger={true}
                  searchCustom={true}
                  handleTableChange={(e) => {
                    const reNewField = [
                      { projectName: displayText('projectNameTH', 'projectNameEN') },
                      { organization: displayText('organizationNameTH', 'organizationNameEN') }
                    ]
                    setSort(handleSort(e?.sorter, reNewField))
                  }}
                  btnAdd={
                    <ButtonNew
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: menu }}
                      onClick={() => navigate(`tab/general`, { state: { typeAction: 'add' } })}
                    >
                      {t('เพิ่มรายงานผลการตรวจสอบ')}
                    </ButtonNew>
                  }
                />
              </Col>
            </Row>
          </CardNew>
        </div>
      </Col>
    </>
  )
}

export default PrepareAuditReportList
