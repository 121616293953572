import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form } from 'antd'
import { ModalNew as Modal } from '../../../../../components/modal/Modal'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { RadioGroup, Radio } from '../../../../../components/radio/Radio'
import { InputNew as Input } from '../../../../../components/input/Input'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError } from '../../../../../utilitys/helper'
import { encryptInput } from '../../../../../utilitys/crypto'
import { useLazyQuery } from '@apollo/client'
import { SpinnersNew as Spinner } from '../../../../../components/spinners/Spinners'
import { AUDIT_RESOLUTION_EXPORT_FILE } from '../../graphql/Query'

const AcResolutionListDownloadModal = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const systemLanguage = localStorage.getItem('lang')

  const [isWatermarkAvailable, setIsWatermarkAvailable] = useState(true)

  const [auditResolutionExportFile, auditResolutionExportFileResult] = useLazyQuery(AUDIT_RESOLUTION_EXPORT_FILE)

  const responsiveValues = { lg: 17 }

  const onFinish = (data) => {
    auditResolutionExportFile({
      variables: {
        input: encryptInput({
          agendaId: props.agendaId,
          systemLanguage: systemLanguage,
          fileType: data.fileType,
          ...(data.fileType === 'pdf' && data.waterMark ? { waterMark: data.waterMark } : {}),
        }),
      },
    })
      .then((response) => {
        if (response.data?.auditResolutionExportFile?.fileUrl) {
          window.open(`${response.data.auditResolutionExportFile.fileUrl}`, '_blank')
          props.setModalOpen(false)
        } else {
          Alert({
            type: 'error',
            title: t('ไม่สามารถดาวน์โหลดข้อมูลได้'),
            content: t(response.data.auditResolutionExportFile.message),
            onOk() {},
          })
        }
      })
      .catch((error) =>
        Alert({
          type: 'error',
          title: t('ไม่สามารถดาวน์โหลดข้อมูลได้'),
          content: t(error),
          onOk() {},
        }),
      )
  }

  useEffect(() => {
    if (auditResolutionExportFileResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดาวน์โหลดข้อมูลได้'),
        content: t(displayError(auditResolutionExportFileResult.error)),
        onOk() {},
      })
    }
  }, [auditResolutionExportFileResult.error])

  return (
    <>
      {auditResolutionExportFileResult.loading && <Spinner />}
      <Modal
        onSubmit={() => {
          form.submit()
        }}
        onClose={() => Promise.all([form.resetFields(), props.setModalOpen(false), setIsWatermarkAvailable(true)])}
        visible={props.open}
        // typeAction={dataForExport.typeAction}
        testTitle={t('พิมพ์รายงาน')}
        btnName={t('พิมพ์')}
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelCol={{
            lg: 8,
            xl: 8,
          }}
          wrapperCol={{
            span: 15,
          }}
          layout="horizontal"
          labelAlign="left"
        >
          <Row justify="center">
            <Col {...responsiveValues}>
              <Form.Item
                label={<Label type="tab-header-inactive">{t('ประเภทรายงาน')}</Label>}
                name="fileType"
                initialValue={'pdf'}
                onClick={(e) => {
                  if (e.target.value) {
                    if (e.target.value !== 'pdf') {
                      setIsWatermarkAvailable(false)
                    } else {
                      setIsWatermarkAvailable(true)
                    }
                  }
                }}
              >
                <RadioGroup>
                  <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <Radio value="pdf">
                        <Label type="tab-header-inactive">{t('PDF')}</Label>
                      </Radio>
                    </Col>
                    <Col span={12}>
                      <Radio value="word">
                        <Label type="tab-header-inactive">{t('Word')}</Label>
                      </Radio>
                    </Col>
                  </Row>
                </RadioGroup>
              </Form.Item>
              <Form.Item
                name="waterMark"
                label={<Label type="tab-header-inactive">{t('ลายน้ำ')}</Label>}
                hidden={!isWatermarkAvailable}
              >
                <Input placeholder={t('ระบุลายน้ำ (เฉพาะ PDF)')} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default AcResolutionListDownloadModal
