import { gql } from '@apollo/client'
export const GET_SELECT_RATE_RISK_FACTOR_PROCESS_BY_RCM = gql`
  query getRateRiskRactorProcessByAuditRCM($input: SelectRateRiskRactorProcessByAuditRCMInput!) {
    getRateRiskRactorProcessByAuditRCM(data: $input) {
      rateFacProCode
      rateFacProNameTH
      rateFacProNameEN
    }
  }
`
