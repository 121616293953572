import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'key',
    width: '8%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ชื่อระดับความเสี่ยง</Trans>,
    dataIndex: 'riskLevelName',
    width: '33%',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>กำหนดวันแจ้งเตือน (วัน)</Trans>,
    dataIndex: 'dueDate',
    width: '10%',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>เตือนล่วงหน้า (วัน)</Trans>,
    dataIndex: 'dueDateBefor',
    width: '10%',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActive',
    width: '17%',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '10%',
    className: 'text-nowrap',
  },
]
