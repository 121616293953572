import { gql } from '@apollo/client'

export const SAVE_CAUSEOFISSUE = gql`
  mutation masterCauseOfIssueSave($input: CauseOfIssueInput!) {
    masterCauseOfIssueSave(input: $input)
  }
`

export const DELETE_CAUSEOFISSUE_BY_CODE = gql`
  mutation masterCauseOfIssueDelete($causeCode: String!) {
    masterCauseOfIssueDelete(causeCode: $causeCode)
  }
`
