import styled from 'styled-components'

import { fontSize, colors } from '../../configs/styles.config'

export const CheckboxStyle = styled.div`
  colors: ${(props) => (props.color ? props.color : colors.black)};
  font-size: ${(props) => (props.fontSize ? props.color : fontSize.textDefault)};

  .ant-checkbox-checked .ant-checkbox-inner {
    background: ${(props) => (props.background ? props.color : colors.primary)};
    colors: ${(props) => (props.color ? props.color : colors.primaryActive)};
  }

  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: ${(props) => (props.color ? props.color : colors.primary)};
  }

  .ant-checkbox-disabled + span {
    color: #999;
    line-height: 2;
  }
`
