import styled from 'styled-components'
import { colors } from '../../configs/styles.config'

export const UploadStyle = styled.div`
  ${'' /* width prograss */}
  .ant-upload-list-text .ant-upload-list-text-container .ant-upload-list-item-uploading .ant-upload-list-item-progress {
    width: 94%;
  }

  ${'' /* show Icon  */}
  .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }

  .upload-file-style
    .ant-upload-list
    .ant-upload-list-text-container
    .ant-upload-list-item
    .ant-upload-list-item-info
    .ant-upload-span
    .ant-upload-list-item-card-actions
    button[title='Remove file'] {
    display: ${(props) => props.actionType === 'view' && 'none'};
  }

  .ant-upload-list-item-info {
    .ant-upload-list-item-name {
      color: #666;
      cursor: default;
      pointer-events: none;
    }
  }

  .ant-upload-list-item-card-actions-btn:hover {
    svg {
      fill: ${colors.primaryHover};
    }
  }
`

// สำหรับแสดงไฟล์ แนวนอน
export const UploadInlineStyle = styled(UploadStyle)`
  .upload-file-style-inline {
    display: flex;
  }

  .ant-upload.ant-upload-select {
    order: ${(props) => (props.orderLabel ? 2 : 'unset')};
    display: ${(props) => props.actionType === 'view' && 'none'};
  }

  .ant-upload-list-item-card-actions {
    display: ${(props) => props.multiRow && 'none !important'};
  }
`
