import { gql } from '@apollo/client'

export const GET_LOGFILE_CHANGEPASSWORD_PAGINATION = gql`
  query logfilePasswordChangePaginationGet($input: LogfilePasswordChangePaginationInput!) {
    logfilePasswordChangePaginationGet(input: $input) {
      result {
        logfileCangeId
        userId

        username
        email
        fullname
        changeDate
      }
      count
      page
      limit
    }
  }
`
