import i18n from 'i18next'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Modal } from 'antd'
import { colors } from '../../../../configs/styles.config'
import { TextAreaNew } from '../../../../components/textarea/TextArea'

export const AlertconfirmNotApprove = (param) => {
  let valueModal = ''
  Modal.confirm({
    icon: (
      <>
        <div className="modal-confirm-icon">
          <ExclamationCircleOutlined style={{ fontSize: '80px', color: colors.warning }} />
        </div>
      </>
    ),
    wrapClassName: 'modal-confirm-custom',
    content: (
      <TextAreaNew
        showCount
        maxLength={300}
        rows={5}
        onChange={(e) => {
          // param.onChange(e.target.value) // example ส่งค่าผ่าน onchange
          valueModal = e.target.value
        }}
      />
    ),
    okText: i18n.t('Confirm'),
    cancelText: i18n.t('Cancel'),
    onOk() {
      param.onConfirm(valueModal)
    },
    onCancel() {
      if (typeof param.onOk !== 'undefined') {
        param.onCancel()
      }
    },
    ...param,
  })
}
