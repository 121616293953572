import { gql } from '@apollo/client'

export const GET_MASTER_GENDERS = gql`
  query getMasterGenders($input: MasterGendersInput!) {
    getMasterGenders(data: $input) {
      genderId
      genderCode
      genderTH
      genderEN
      isActive
    }
  }
`
