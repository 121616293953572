import { useLazyQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import 'react-calendar-timeline/lib/Timeline.css'
import { useTranslation } from 'react-i18next'

import { Alert } from '../../../../components/alert/Alert'
import { CalendarTimeline } from '../../../../components/calendartimeline/CalendarTimeline'
import { LabelNew as Label } from '../../../../components/label/Label'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { dateDisplay } from '../../../../utilitys/dateHelper'
import { dateFormat, displayError, displayText } from '../../../../utilitys/helper'

import {
  AUDIT_PROJECT_GET_CALENDARDETAILS1,
  AUDIT_PROJECT_GET_CALENDARDETAILS2,
  GET_AUDITPLAN,
  GET_MASTER_PROJECT_STATUS
} from './graphql/Query'
const AuditPlan = (props) => {
  const color = {
    graphHeaderMonthNameBackground: '#C1C8C7',
    graphHeaderMonthNameText: '#FFFFFF',
    graphHeaderDatehNameBackground: '#FFFFFF',
    graphHeaderDatehNameText: '#000000',
    graphCerrentDateBackground: '#B2B2B2',
    graphSanAndSunDateBackground: '#e8d595',
    graphHolidayType1DateBackground: '#FDFFBC',
    graphHolidayType2DateBackground: '#FFFDDD',
    statusNoproject: '#C1C8C7',
    statusWaitForProcess: '#a0c5e2',
    statusProcessing: '#2676bb',
    statusProcessed: '#54a648',
    statusLate: '#C43D56'
  }
  const { t } = useTranslation()
  const [groups, setGroups] = useState([])
  const [items, setItems] = useState([])
  const [projectStatus, setProjectStatus] = useState([])
  const [projectStatusName10, setProjectStatusName10] = useState('')
  const [projectStatusName20, setProjectStatusName20] = useState('')
  const [projectStatusName30, setProjectStatusName30] = useState('')

  // const [weekendHoliday, setWeekendHoliday] = useState([])
  const [auditProjectGetCalendarDetailsType1Fn, auditProjectGetCalendarDetailsType1] = useLazyQuery(
    AUDIT_PROJECT_GET_CALENDARDETAILS1
  )
  const [auditProjectGetCalendarDetailsType2Fn, auditProjectGetCalendarDetailsType2] = useLazyQuery(
    AUDIT_PROJECT_GET_CALENDARDETAILS2
  )
  const [getMasterProjectStatusFn, getMasterProjectStatus] = useLazyQuery(GET_MASTER_PROJECT_STATUS)
  const [getAuditPlanFn, getAuditPlan] = useLazyQuery(GET_AUDITPLAN)
  const callApiSearch = () => {
    getAuditPlanFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          lang: localStorage.getItem('lang'),
          planYear: props.formFilter.getFieldValue('planYear') ? props.formFilter.getFieldValue('planYear') : null,
          planType: props.formFilter.getFieldValue('planType') ? props.formFilter.getFieldValue('planType') : null,
          planStatusCode: props.formFilter.getFieldValue('planStatusCode')
            ? props.formFilter.getFieldValue('planStatusCode')
            : null,
          isActive:
            props.formFilter.getFieldValue('isActive') === undefined ||
            props.formFilter.getFieldValue('isActive') === null
              ? null
              : props.formFilter.getFieldValue('isActive'),
          dateStart: props.formFilter.getFieldValue('dateStart') ? props.formFilter.getFieldValue('dateStart') : null,
          dateEnd: props.formFilter.getFieldValue('dateEnd') ? props.formFilter.getFieldValue('dateEnd') : null,
          planInfor: (
            typeof props.formFilter.getFieldValue('planInfor') === 'string'
              ? props.formFilter.getFieldValue('planInfor').trim()
              : props.formFilter.getFieldValue('planInfor')
          )
            ? typeof props.formFilter.getFieldValue('planInfor') === 'string'
              ? props.formFilter.getFieldValue('planInfor').trim()
              : props.formFilter.getFieldValue('planInfor')
            : null
        })
      }
    })
  }

  useEffect(() => {
    if (getAuditPlan?.data && projectStatus.length) {
      let response = getAuditPlan?.data?.getAuditPlan
      setGroups(
        response.map((data) => {
          return {
            id: data.planCode,
            title:
              data.planCode +
              ' : ' +
              displayText(data.planNameTH, data.planNameEN) +
              ' ' +
              t('ประจำปี') +
              ' ' +
              (localStorage.getItem('lang') === 'th' ? data.planYear + 543 : data.planYear),
            dateStart: dateDisplay(data.dateStart),
            dateEnd: dateDisplay(data.dateEnd)
          }
        })
      )
      let item = response
        .map((data) => {
          return {
            id: data.planCode,
            group: data.planCode,
            title: {
              late: false,
              countStatus10: Math.floor(
                data.countProjectAll ? (data.countApproveStatus10 / data.countProjectAll) * 100 : 0
              ),
              countStatus20: Math.floor(
                data.countProjectAll ? (data.countApproveStatus20 / data.countProjectAll) * 100 : 0
              ),
              countStatus30: Math.floor(
                data.countProjectAll ? (data.countApproveStatus30 / data.countProjectAll) * 100 : 0
              )
            },
            start_time: dateFormat(data.dateStart),
            end_time: dateFormat(data.dateEnd)
          }
        })
        .concat(
          response
            .filter((data) => {
              return (
                (data.countApproveStatus10 !== 0 || data.countApproveStatus20 !== 0) &&
                moment(data.dateEnd) < moment(new Date())
              )
            })
            .map((data) => {
              let dateStart = new Date(moment(data.dateEnd).add(1, 'days'))
              let dateEnd = new Date()
              return {
                id: data.planCode + '_late',
                group: data.planCode,
                title: { late: true },
                start_time: dateFormat(dateStart),
                end_time: dateFormat(dateEnd)
              }
            })
        )
      setItems(item)
    }
  }, [getAuditPlan.data, projectStatus])

  useEffect(() => {
    if (getMasterProjectStatus?.data) {
      let response = getMasterProjectStatus?.data?.getMasterProjectStatus
      setProjectStatus(
        response.map((data) => {
          return {
            projectStatusCode: data.projectStatusCode,
            projectStatusNameTH: data.projectStatusNameTH,
            projectStatusNameEN: data.projectStatusNameEN
          }
        })
      )
      let status10 = response.find((data) => {
        return data.projectStatusCode === '10'
      })
      let status20 = response.find((data) => {
        return data.projectStatusCode === '20'
      })
      let status30 = response.find((data) => {
        return data.projectStatusCode === '30'
      })
      setProjectStatusName10(status10 ? displayText(status10.projectStatusNameTH, status10.projectStatusNameEN) : '')
      setProjectStatusName20(status20 ? displayText(status20.projectStatusNameTH, status20.projectStatusNameEN) : '')
      setProjectStatusName30(status30 ? displayText(status30.projectStatusNameTH, status30.projectStatusNameEN) : '')
    }
  }, [getMasterProjectStatus.data])

  useEffect(() => {
    auditProjectGetCalendarDetailsType1Fn({
      fetchPolicy: 'no-cache'
    })
    auditProjectGetCalendarDetailsType2Fn({
      fetchPolicy: 'no-cache'
    })
    callApiSearch()
    getMasterProjectStatusFn({
      variables: { input: encryptInput({ isActive: 1 }) }
    })
  }, [])

  useEffect(() => {
    callApiSearch()
  }, [props.searchStatus])

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {}
    })
  }
  useEffect(() => {
    if (getAuditPlan.error) {
      showAlertLoadDataError(displayError(getAuditPlan.error))
    }
    if (getMasterProjectStatus.error) {
      showAlertLoadDataError(displayError(getMasterProjectStatus.error))
    }
    if (auditProjectGetCalendarDetailsType1.error) {
      showAlertLoadDataError(displayError(auditProjectGetCalendarDetailsType1.error))
    }
    if (auditProjectGetCalendarDetailsType2.error) {
      showAlertLoadDataError(displayError(auditProjectGetCalendarDetailsType2.error))
    }
  }, [
    getAuditPlan.error,
    getMasterProjectStatus.error,
    auditProjectGetCalendarDetailsType1.error,
    auditProjectGetCalendarDetailsType2.error
  ])

  const year = props.formFilter.getFieldValue('planYear') ? props.formFilter.getFieldValue('planYear') : new Date()

  return (
    <>
      {(getAuditPlan.loading ||
        getMasterProjectStatus.loading ||
        auditProjectGetCalendarDetailsType1.loading ||
        auditProjectGetCalendarDetailsType2.loading) && <SpinnersNew />}

      <Row justify="center">
        <Col span={24}>
          <CalendarTimeline
            headerContent={t('แผนการตรวจสอบ')}
            path="/auditplanning/auditplan/auditproject/"
            groups={groups}
            items={items}
            year={year}
          />
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: '50px' }}>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusNoproject, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{t('ไม่มีข้อมูลโครงการ')}</Label>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                backgroundColor: color.statusWaitForProcess,
                width: '20px',
                height: '20px',
                marginRight: '20px'
              }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{projectStatusName10}</Label>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusProcessing, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{projectStatusName20}</Label>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusProcessed, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{projectStatusName30}</Label>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusLate, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{t('ล่าช้ากว่ากำหนด')}</Label>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default AuditPlan
