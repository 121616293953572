import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useLazyQuery, useMutation } from '@apollo/client'
import * as _ from 'lodash'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb.jsx'
import { CardNew } from '../../../components/card/Card.jsx'
import { LabelNew as Label } from '../../../components/label/Label.jsx'
import { ButtonNew } from '../../../components/button/Button.jsx'
import { displayText } from '../../../utilitys/helper'
import { Datepicker } from '../../../components/datepicker/Datepicker.jsx'
import { InputNew } from '../../../components/input/Input.jsx'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import SelectJobTypes from '../../../components/inputfromapi/selectjobtypes/SelectJobTypes.jsx'
import { ButtonGroup } from '../../../components/button/ButtonGroup.jsx'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization.jsx'
import ConsultationResultModal from './modal/ConsultationResultModal.jsx'
import { SpinnersNew } from '../../../components/spinners/Spinners.jsx'
import { Status } from '../../../components/status/Status.jsx'
import { Columns } from './utils/ConsultationResultTable.js'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { Alert } from '../../../components/alert/Alert'
import { GET_CONSULTATION_PAGINATION } from './graphql/Query.jsx'
import { CONSULTATION_RESULT_DELETE } from './graphql/Mutation'
import { displayError } from '../../../utilitys/helper'

const ConsultationResultList = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [deleteData, { data: dataDelete, error: errorDelete }] = useMutation(CONSULTATION_RESULT_DELETE)
  const [form] = Form.useForm()

  const userLogin = decryptData(localStorage.getItem('user'))

  const Responsive = {
    md: 8,
    lg: 8,
  }
  const ResponsiveTable = {
    md: 24,
    lg: 24,
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/consultationresult' },
    { label: t('บันทึกการให้คำปรึกษา') },
  ]

  const [dataLists, setDataLists] = useState([])
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [oldFilter, setOldFilter] = useState({
    jobTypeCode: null,
    jobNumber: null,
    jobSubject: null,
    organizationCode: null,
    requestPersonnel: null,
    personnelName: null,
    dateStart: null,
    dateEnd: null,
    personnelCode: userLogin.pCode || null,
  })

  //   const [filters, setFilters] = useState({})

  /* ---------------- API ----------- */
  const [getDataPaginationFn, dataPagination] = useLazyQuery(GET_CONSULTATION_PAGINATION)

  /* ---------------- ACTION ----------- */

  const showConfirmDelete = (consultId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteData({
          variables: {
            input: { consultId: encryptInput(consultId) },
          },
        })
      },
      onCancel() {
        dataPagination.refetch()
      },
    })
  }
  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          dataPagination.refetch()
        },
      })
    }
  }, [dataDelete])

  useEffect(() => {
    if (errorDelete) {
      showAlertError(displayError(errorDelete))
    }
  }, [errorDelete])

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const onFinish = (values) => {
    setOldFilter({
      jobTypeCode: values.jobTypeCode,
      jobNumber: values.jobNumber,
      jobSubject: values.jobSubject,
      organizationCode: values.organizationCode,
      requestPersonnel: values.requestPersonnel,
      personnelName: values.personnelName,
      dateStart: values.dateStart,
      dateEnd: values.dateEnd,
      personnelCode: userLogin.pCode || null,
    })
    const inputData = {
      filters: {
        jobTypeCode: values.jobTypeCode || null,
        jobNumber: values.jobNumber || null,
        jobSubject: values.jobSubject || null,
        organizationCode: values.organizationCode || null,
        requestPersonnel: values.requestPersonnel || null,
        personnelName: values.personnelName || null,
        dateStart: values.dateStart || null,
        dateEnd: values.dateEnd || null,
        personnelCode: userLogin.pCode || null,
      },
      pagination: { limit, page },
      sort: [
        {
          fieldSort: 'consultId',
          sortType: 'DESC',
        },
      ],
    }

    getDataPaginationFn({
      variables: {
        input: encryptInput(inputData),
      },
    })
  }

  // const resetField = () => {
  //   form.resetFields()
  //   getDataPaginationFn({
  //     variables: {
  //       input: {
  //         filters: {},
  //         pagination: { limit: 10, page: 1 },
  //         sort: [
  //           {
  //             fieldSort: null,
  //             sortType: 'DESC',
  //           },
  //         ],
  //       },
  //     },
  //   })
  // }

  const showErrorAlert = (item) => {
    Alert({
      type: 'error',
      title: t(item.title),
      content: t(item.content),
      onOk() {},
    })
  }
  /*------------Check correct DateInput--------------------*/

  /*------------useEffect------------*/
  useEffect(() => {
    // if (dataPagination.data) {
    const inputData = {
      filters: {
        jobTypeCode: oldFilter.jobTypeCode || null,
        jobNumber: oldFilter.jobNumber || null,
        jobSubject: oldFilter.jobSubject || null,
        organizationCode: oldFilter.organizationCode || null,
        requestPersonnel: oldFilter.requestPersonnel || null,
        personnelName: oldFilter.personnelName || null,
        dateStart: oldFilter.dateStart || null,
        dateEnd: oldFilter.dateEnd || null,
        personnelCode: userLogin.pCode || null,
      },
      pagination: { limit, page },
      sort: [
        {
          fieldSort: 'consultId',
          sortType: 'DESC',
        },
      ],
    }

    getDataPaginationFn({
      variables: {
        input: encryptInput(inputData),
      },
    })
    // }
  }, [page])

  /*-------การแสดงหน้า-------*/
  useEffect(() => {
    if (dataPagination.data) {
      let response = dataPagination.data?.getConsultationResultPagination
      setCount(response?.count)
      form.setFieldsValue({ jobTypeCode: '31' })
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            ...data,
            key: data.no,
            no: data.consultId,
            jobTypeName: displayText(data.jobTypeNameTH, data.jobTypeNameEN),
            jobNumber: data.jobNumber,
            requestPersonnel: data.requestPersonnel,
            organizationName: data.requestOrganization,
            Subject: displayText(data.jobSubjectTH, data.jobSubjectEN),
            resultDate: moment(data.resultDate).format('DD/MM/YYYY'),
            responsibleOrganization: data.responsibleOrganization,
            responsiblePersonnel: data.fullName,
            consultStatus: (
              <Status
                text={
                  data.jobStatusCode === '10'
                    ? 'รอดำเนินการ'
                    : data.jobStatusCode === '20'
                    ? 'กำลังดำเนินการ'
                    : data.jobStatusCode === '30'
                    ? 'ดำเนินการแล้วเสร็จ'
                    : data.jobStatusCode === '40'
                    ? 'บันทึกร่าง'
                    : '-'
                }
              />
            ),
            tools: (
              <ButtonGroup
                menu={menu}
                icons={[
                  {
                    type: 'download',
                    onClick: () => {
                      Promise.all([
                        setData({
                          consultId: data.consultId,
                          jobCode: data.jobCode,
                          responsiblePersonnel: data.fullName,
                        }),
                        setVisible(true),
                      ])
                    },
                  },
                  {
                    type: 'view',
                    onClick: () => {
                      navigate('/auditoperations/consultationresult/form', {
                        state: { refCode: data.consultId, type_action: 'view' },
                      })
                    },
                  },
                  {
                    type: 'edit',
                    onClick: () => {
                      if (data.consultStatus === '0') {
                        navigate('/auditoperations/consultationresult/form', {
                          state: { refCode: data.consultId, type_action: 'edit' },
                        })
                      } else {
                        showAlertError('ไม่สามารถแก้ไขได้ เนื่องจากรายการนี้เสร็จสิ้นแล้ว')
                      }
                    },
                  },
                  {
                    type: 'delete',
                    onClick: () => {
                      showConfirmDelete(data.consultId)
                    },
                  },
                ]}
              />
            ),
          }
        }),
      )
    }

    if (dataPagination.error) {
      showErrorAlert({
        title: 'ไม่สามารถดึงข้อมูลได้',
        content: t(displayError(dataPagination.error)),
      })
    }
  }, [dataPagination.data])

  const validDate = () => {
    let valid = false
    if (form.getFieldValue('dateStart') && form.getFieldValue('dateEnd')) {
      let dateStart = new Date(form.getFieldValue('dateStart'))
      let dateEnd = new Date(form.getFieldValue('dateEnd'))
      if (dateStart <= dateEnd) {
        setErrorField('dateStart', false)
        setErrorField('dateEnd', false)
        valid = true
      } else {
        setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นน้อยกว่าวันที่สิ้นสุด'))
        setErrorField('dateEnd', t('กรุณาเลือกวันที่สิ้นสุดมากกว่าวันที่เริ่มต้น'))
      }
    } else {
      valid = true
    }
    return valid
  }
  /*-----setField-------*/

  const setErrorField = (name, errors) => {
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  return (
    <>
      {dataPagination.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('บันทึกการให้คำปรึกษา')} />
      <CardNew topic={t('Filters')} icon="Filter" toggledrop="true" buttontopright="true">
        <Form name="form-search" form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[12, 12]}>
            <Col {...Responsive}>
              <Form.Item name="jobTypeCode" label={<Label type="tab-header-inactive">{t('ประเภทงาน')}</Label>}>
                <SelectJobTypes
                  placeholder={t('เลือกประเภทงาน')}
                  formname={'form-search'}
                  filters={{ isActive: 1 }}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="jobNumber" label={<Label type="tab-header-inactive">{t('เลขที่งาน')}</Label>}>
                <InputNew placeholder={t('เลขที่งาน')} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'requestPersonnel'}
                label={<Label type="tab-header-inactive">{t('ผู้ขอปรึกษา/แนะนำ')}</Label>}
              >
                <InputNew placeholder={t('ระบุผู้ขอคำปรึกษา/แนะนำ')} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="organizationCode" label={<Label type="tab-header-inactive">{t('หน่วยงาน/สาขา')}</Label>}>
                <SelectOrganization
                  placeholder={t('เลือกหน่วยงาน/สาขา')}
                  formname={'form-search'}
                  filters={{ isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ organizationCode: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'jobSubject'}
                label={<Label type="tab-header-inactive">{t('เรื่องที่ให้คำปรึกษา/แนะนำ')}</Label>}
              >
                <InputNew placeholder={t('เลือกเรื่องที่ให้คำปรึกษา/แนะนำ')} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'personnelName'}
                label={<Label type="tab-header-inactive">{t('ผู้ให้คำปรึกษา/แนะนำ')}</Label>}
              >
                <InputNew placeholder={t('เลือกผู้ให้คำปรึกษา/แนะนำ')} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'dateStart'}
                label={<Label type="tab-header-inactive">{t('วันที่เริ่มต้นบันทึกเอกสาร')}</Label>}
              >
                <Datepicker
                  autoComplete="off"
                  setValueDateFn={async (data) => {
                    form.setFieldsValue({ dateStart: await data }), validDate()
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateStart: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'dateEnd'}
                label={<Label type="tab-header-inactive">{t('วันที่สิ้นสุดบันทึกเอกสาร')}</Label>}
              >
                <Datepicker
                  autoComplete="off"
                  setValueDateFn={async (data) => {
                    form.setFieldsValue({ dateEnd: await data }), validDate()
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateEnd: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <ButtonNew type="search" htmlType="submit">
                {t('ค้นหา')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew type="clear" onClick={() => form.resetFields()} htmlType="submit">
                {t('ล้างค่า')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </CardNew>
      {/* - - - DataTable - - - */}
      <CardNew topic={t('รายการบันทึกการให้คำปรึกษา')} icon="List" toggledrop="false">
        <Row gutter={[12, 12]}>
          <Col {...ResponsiveTable}>
            <Datatable
              columns={Columns}
              data={[...dataLists]}
              handleTableChange={() => {}}
              paginationCustom={true}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
              total={count}
              pageSize={limit}
              page={page}
              btnAdd={
                <>
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    menu={menu}
                    roles={{ type: 'add', menu: menu }}
                    style={{ marginRight: '12px' }}
                    onClick={() => {
                      navigate('/auditoperations/consultationresult/form', { state: { type_action: 'add' } })
                    }}
                  >
                    {t('บันทึกการให้คำปรึกษา')}
                  </ButtonNew>
                </>
              }
            />
          </Col>
        </Row>
      </CardNew>
      <ConsultationResultModal visible={visible} setVisible={setVisible} data={data} />
    </>
  )
}

export default ConsultationResultList
