import React from 'react'
import { Row, Col } from 'antd'
import { Trans } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import CauseOfIssueList from './CauseOfIssueList'

/**
 * @function CauseOfIssueIndex
 * @description
 * Menu: CauseOfIssue
 * เมนู: จัดการข้อมูลสาเหตุ
 * @returns Component
 */
const arrCrumb = [
  { label: <Trans>จัดการข้อมูลพื้นฐาน</Trans>, path: '#' },
  { label: <Trans>จัดการข้อมูลสาเหตุ</Trans> },
]
const CauseOfIssueIndex = (props) => {
  const { menu } = props
  const Responsive = {
    md: 24,
    lg: 24,
  }
  return (
    <React.Fragment>
      <Row>
        <Col {...Responsive}>
          <Breadcrumb data={arrCrumb} title={<Trans>จัดการข้อมูลสาเหตุ</Trans>} />
          <CauseOfIssueList menu={menu} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CauseOfIssueIndex
