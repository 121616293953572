import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import * as _ from 'lodash'
import { GET_SELECT_RISK_FACTORS_EXAMINE } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectRiskFactorExamine = (props) => {
  const { placeholder, formname, handleChange, filters, projectCode, ...otherProp } = props

  const { approveStatusCode = null } = filters || {}

  const [isData, isSetData] = useState([])

  const [cellGet, { data, loading }] = useLazyQuery(GET_SELECT_RISK_FACTORS_EXAMINE)

  useEffect(() => {
    cellGet({
      variables: {
        input: encryptInput({ projectCode: projectCode, approveStatusCode: approveStatusCode }),
      },
    })
  }, [projectCode, approveStatusCode])

  useEffect(() => {
    if (data) {
      isSetData(
        data?.getSelectRiskFactorExamine.map((item) => {
          let label = displayText(item.rateFacProNameTH, item.rateFacProNameEN)
          if (!label) label = item.rateFacProNameTH
          return {
            value: item.rateFacProCode,
            label: `${item.rateFacProCode} : ${label}`,
            source: item,
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        loading={loading}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectRiskFactorExamine
