import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// import { useLanguage } from '../../../hooks/useLanguage'
import { Row, Col } from 'antd'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import { GET_EDUCATION_PERSONNELS, GET_PERSONNEL } from './graphql/Query'
import { DELETE_ONE_EDUCATION } from './graphql/Mutation'
import * as _ from 'lodash'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { Alert } from '../../../components/alert/Alert'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { ButtonNew } from '../../../components/button/Button'
import { columnsEducate as columns } from './utils/table/EducationTable'
import { useLocation } from 'react-router-dom'
import { encryptInput } from '../../../utilitys/crypto'
import EducationModal from './modal/EducationModal.jsx'
import { displayError } from '../../../utilitys/helper'

const PersonnelEducationList = (props) => {
  const { t } = useTranslation()
  // const [isLang] = useLanguage()
  const { type_action } = props
  const [formType, setFormType] = useState(type_action === 'add' ? 'add' : type_action === 'edit' ? 'edit' : 'view') // Intitial value formType
  const { state } = useLocation()
  const [dataLists, setDataLists] = useState([])
  const [count, setCount] = useState(0)
  const [codeEdit, setCodeEdit] = useState('')
  const [modalAdd, setModalAdd] = useState(false)
  const [contentName, setContentName] = useState()
  //get prefix, Name, lastName
  const [getPersonnelFn, dataPersonnel] = useLazyQuery(GET_PERSONNEL)
  const getData = (personnelCode) => {
    getPersonnelFn({ variables: { input: encryptInput({ personnelCode: personnelCode }) } })
  }
  useEffect(() => {
    getData(state.refCode)
  }, [state.refCode])
  //
  const getEducationPagenation = useQuery(GET_EDUCATION_PERSONNELS, {
    variables: {
      input: {
        personnelCode: state.refCode,
      },
    },
  })
  const [deleteData, { loading: deleteDataLoading, data: dataDelete, error: errorDelete }] =
    useMutation(DELETE_ONE_EDUCATION)
  const showConfirmDelete = (personnelEducatId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteData({
          variables: {
            input: { personnelEducatId: encryptInput(personnelEducatId) },
          },
        })
      },
      onCancel() {
        getEducationPagenation.refetch()
      },
    })
  }

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          getEducationPagenation.refetch()
        },
      })
    }
  }, [dataDelete])

  useEffect(() => {
    if (errorDelete) {
      showAlertError(t(displayError(errorDelete)))
    }
  }, [errorDelete])

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: text,
      onOk() {},
    })
  }

  useEffect(() => {
    if (modalAdd === false) {
      setFormType('')
    }
  }, [modalAdd])
  //เก็บ Prefix, Name ,lastName ที่ดึงมาจาก query
  useEffect(() => {
    if (dataPersonnel.data) {
      setContentName({
        prefix: dataPersonnel.data.getPersonnel.namePrefixTH,
        fName: dataPersonnel.data.getPersonnel.firstNameTH,
        lName: dataPersonnel.data.getPersonnel.lastNameTH,
      })
    }
  }, [dataPersonnel.data])

  useEffect(() => {
    if (getEducationPagenation?.data) {
      setCount(getEducationPagenation.data.getEducationPagenation.leght)
      let response = getEducationPagenation?.data?.getEducationPagenation
      setCount(response?.count)
      setDataLists(
        _.map(response, (data, i) => {
          return {
            key: i,
            no: i + 1,
            degree: data.educationLevelTH,
            minor: data.educationProgramTH,
            major: data.educationMajorTH,
            academicYear: data.year,
            university: data.institution,
            action: (
              <ButtonGroup
                menu={props.menu}
                icons={[
                  {
                    type: 'view',
                    onClick: () => {
                      setFormType('view')
                      setModalAdd(true)
                      setCodeEdit(data.personnelEducatId)
                    },
                  },
                  {
                    type: 'edit',
                    onClick: () => {
                      setFormType('edit')
                      setModalAdd(true)
                      setCodeEdit(data.personnelEducatId)
                    },
                  },
                  {
                    type: 'delete',
                    onClick: () => {
                      showConfirmDelete(data.personnelEducatId)
                    },
                  },
                ]}
              />
            ),
          }
        }),
      )
    }
  }, [getEducationPagenation.data])

  return (
    <>
      {(getEducationPagenation.loading || deleteDataLoading) && <SpinnersNew />}
      <Row gutter={[16, 24]}>
        {contentName ? contentName.prefix + ' ' + contentName.fName + ' ' + contentName.lName : ''}
        <Col md={24}>
          <Datatable
            columns={columns}
            data={dataLists}
            handleTableChange={() => {}}
            paginationCustom={false}
            searchCustom={true}
            total={count}
            contentName={contentName}
            // enableScroll={{ x: true, y: true }}
            btnAdd={
              <>
                {type_action !== 'view' ? (
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: props.menu }}
                    style={{ marginRight: '12px' }}
                    onClick={() => {
                      setModalAdd(true)
                      setFormType('add')
                      setCodeEdit('')
                    }}
                  >
                    {t('เพิ่มการศึกษา')}
                  </ButtonNew>
                ) : (
                  ''
                )}
              </>
            }
          />
        </Col>
      </Row>
      <EducationModal
        colse={setModalAdd}
        open={modalAdd}
        refCode={state.refCode}
        codeEdit={codeEdit}
        formType={formType}
        setFormType={setFormType}
        getEducationPagenation={getEducationPagenation}
      />
    </>
  )
}

export default PersonnelEducationList
