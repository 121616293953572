import { gql } from '@apollo/client'
export const GET_SELECT_STEP_MODEL_DETAIL = gql`
  query GetSelectStepModelMain($input: SelectStepModelDetailInput!) {
    getSelectStepModelDetail(input: $input) {
      stepDetailId
      stepSort
      stepDetailTH
      stepDetailEN
    }
  }
`
