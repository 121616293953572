import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_AUDIT_ISSUES } from './graphql/Query'
import { Select } from '../../select/Select'
import _ from 'lodash'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectAuditIssues = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const {
    projectCode,
    issueType,
    issueShow,
    rateFacProCode,
    isActive,
    rateRiskRuleCode,
    rateRiskConCode,
    approveStatusCode,
  } = filters || {}

  const [isData, isSetData] = useState([])

  const [getAuditIssuesFn, { data, loading }] = useLazyQuery(GET_AUDIT_ISSUES)

  useEffect(() => {
    getAuditIssuesFn({
      variables: { input: encryptInput(filters ? { ...filters } : { isActive: '1' }) },
    })
  }, [
    projectCode,
    issueType,
    issueShow,
    rateFacProCode,
    isActive,
    rateRiskRuleCode,
    rateRiskConCode,
    approveStatusCode,
  ])

  useEffect(() => {
    if (data) {
      isSetData(
        data?.getAuditIssues.map((item) => {
          let label = displayText(item.issueTH, item.issueEN)
          if (!label) label = item.issueTH
          return {
            value: item.issueId,
            label: `${item.issueId} : ${label}`,
            source: item,
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        loading={loading}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectAuditIssues
