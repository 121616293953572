import styled from 'styled-components'
import { colors } from '../../configs/styles.config'

export const ButtonStyle = styled.span`
  .btn-stt {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: ${(props) => (props.width ? props.width + 'px' : '98px')};
    min-height: ${(props) => (props.height ? props.height + 'px' : '40px')};
    border-radius: 3px;
    font-size: 14px;
  }
  .btn-stt-upload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    top: ${(props) => (props.top ? props.top + 'px' : '0px')};
    min-width: ${(props) => (props.width ? props.width + 'px' : '98px')};
    min-height: ${(props) => (props.height ? props.height + 'px' : '40px')};
    border-radius: 3px;
    font-size: 14px;
    z-index: 99;
  }

  .btn-stt-mini-module {
    display: block;
    /* flex-direction: row; */
    justify-content: left;
    align-items: center;
    text-align: left;
    min-width: ${(props) => (props.width ? props.width + 'px' : '98px')};
    min-height: ${(props) => (props.height ? props.height + 'px' : '60px')};
    border-radius: 3px;
    font-size: 14px;

    background-color: ${colors.lightBlue};
    border: 1px solid ${colors.lightBlue};
    color: ${colors.primary};
    &:hover {
      transition: 200ms;
      background-color: ${colors.lightBlueHover};
    }
    &:active {
      color: ${colors.white};
      transition: 200ms;
      background-color: ${colors.primary};
    }
  }

  .btn-stt-module {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 262px;
    min-height: 44px;
    border-radius: 3px;
    font-size: 14px;

    background-color: ${colors.lightBlue};
    border: 1px solid ${colors.lightBlue};
    color: ${colors.primary};
    &:hover {
      transition: 200ms;
      background-color: ${colors.lightBlueHover};
    }
    &:active {
      color: ${colors.white};
      transition: 200ms;
      background-color: ${colors.primary};
    }
  }

  .btn-stt-icon {
    display: flex;
    align-items: center;
    padding: 0px;
    color: ${colors.black};
    &:hover {
      color: ${colors.blackHover};
    }
  }

  .btn-stt-tb {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    border-radius: 3px;
    font-size: 14px;
  }

  .btn-stt-icon2 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    ${'' /* width: 98px; */}
    min-height: 33px;
    border-radius: 3px;
    font-size: 14px;
  }

  .btn-stt-icon3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: ${(props) => (props.width ? props.width + 'px' : '144px')};
    min-height: ${(props) => (props.height ? props.height + 'px' : '36px')};
    border-radius: 3px;
    font-size: 14px;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    ${'' /* margin-top: auto; */}
  }

  .btn-disable {
    color: #00000040 !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }

  .btn-color-primary,
  .btn-color-search {
    background-color: ${colors.primary};
    border: 1px solid ${colors.primary};
    color: ${colors.white};
    &:hover {
      transition: 200ms;
      background-color: ${colors.primaryHover};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.primaryActive};
    }
  }

  .btn-color-edit-in-tb {
    background-color: ${colors.lightOrange};
    border: 1px solid ${colors.lightOrange};
    color: ${colors.lightOrange2};
    &:hover {
      transition: 200ms;
      background-color: ${colors.lightOrangeHover};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.primaryActive};
    }
  }

  .btn-color-approve-in-tb {
    background-color: ${colors.successApprove2};
    border: 1px solid ${colors.successApprove2};
    color: ${colors.successApprove1};
    &:hover {
      transition: 200ms;
      background-color: ${colors.successApproveHover};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.primaryActive};
    }
  }

  .btn-color-examine-in-tb {
    background-color: ${colors.lightGray};
    border: 1px solid ${colors.lightGray};
    color: ${colors.lightGray2};
    &:hover {
      transition: 200ms;
      background-color: ${colors.lightGrayHover};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.primaryActive};
    }
  }

  .btn-color-state-in-tb {
    background-color: ${colors.lightBlue};
    border: 1px solid ${colors.lightBlue};
    color: ${colors.primary};
    &:hover {
      transition: 200ms;
      background-color: ${colors.lightBlueHover};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.primaryActive};
    }
  }

  .btn-color-state-in-tb-gren {
    background-color: ${colors.lightGreen};
    border: 1px solid ${colors.lightGreen};
    color: ${colors.drakGreen};
    &:hover {
      transition: 200ms;
      background-color: ${colors.lightGreenHover};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.lightGreenActive};
    }
  }

  .btn-color-back {
    background-color: ${colors.white};
    color: ${colors.warning};
    border: 1px solid ${colors.warning};
    &:hover {
      transition: 200ms;
      background-color: ${colors.warningHover};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.warning};
      color: ${colors.white};
    }
  }

  .btn-color-allow {
    background-color: ${colors.white};
    color: ${colors.success};
    border: 1px solid ${colors.success};
    &:hover {
      transition: 200ms;
      background-color: ${colors.successHover2};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.success};
      color: ${colors.white};
    }
  }

  .btn-color-noAllow {
    background-color: ${colors.white};
    color: ${colors.btnDanger};
    border: 1px solid ${colors.btnDanger};
    &:hover {
      transition: 200ms;
      background-color: ${colors.btnDangerHover};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.btnDanger};
      color: ${colors.white};
    }
  }

  .btn-color-add-multiple {
    background-color: ${colors.lightGray3};
    color: ${colors.primary};
    border: 1px dashed ${colors.lightGray2};
    margin-top: 5px;
    margin-bottom: 5px;
    &:hover {
      transition: 200ms;
      background-color: ${colors.lightGray3Hover};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.primaryActive};
    }
  }

  .btn-color-clear,
  .btn-color-tb {
    background-color: ${colors.white};
    color: ${colors.primary};
    border: 1px solid ${colors.primary};
    &:hover {
      transition: 200ms;
      background-color: ${colors.primaryHover2};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.primary};
      color: ${colors.white};
    }
  }

  .btn-color-gen {
    background-color: #f5f5f5;
    color: #333333;
    border: 1px solid #a7a9ac;
    &:hover {
      transition: 200ms;
      background-color: #ebebeb;
    }
    &:active {
      transition: 200ms;
      background-color: #e1e1e1;
      color: #333333;
    }
  }

  .btn-color-success {
    background-color: ${colors.success};
    border: 1px solid ${colors.success};
    color: ${colors.white};
    &:hover {
      transition: 200ms;
      background-color: ${colors.successHover};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.primaryActive};
    }
  }

  .btn-cancel {
    order: 2;
    border-radius: 3px;
    font-size: 14px;
    width: 98px;
    height: 40px;
    background-color: #fff;
    border: 1px solid #ff9e2c;
    color: #ff9e2c;
    &:hover {
      transition: 200ms;
      background-color: #fff9f4;
    }
    &:active {
      transition: 200ms;
      background-color: #ff9e2c;
      color: #fff;
    }
  }
`
