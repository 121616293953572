import React from 'react'
import PropTypes from 'prop-types'
import { Pagination } from 'antd'
import { useTranslation } from 'react-i18next'
import './PaginationStyle.scss'

export const PaginationComp = (props) => {
  const { t } = useTranslation()

  return (
    <div className="pagination-wrapper">
      <Pagination
        size="small"
        total={props.total ? props.total : 0}
        onChange={props.onChangePagination}
        current={props.page}
        defaultPageSize={props.pageSize ? props.pageSize : 10}
        defaultCurrent={props.defaultCurrent ? props.defaultCurrent : 1}
        showTotal={(total, range) => `${range[0]}-${range[1]} ${t('Of')} ${total}`}
        responsive={true}
        showLessItems={true}
        showSizeChanger={props.showSizeChanger ? true : false}
        locale={{ items_per_page: `/ ${t('Page')}` }}
      />
    </div>
  )
}

PaginationComp.propTypes = {
  total: PropTypes.number.isRequired,
  onChangePagination: PropTypes.func,
  page: PropTypes.number,
  pageSize: PropTypes.number,
}
