import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import * as _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { Columns } from './utils/CauseOfIssueColumns'
import { Status } from '../../../components/status/Status'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import CauseOfIssueModal from './CauseOfIssueModal'
import { GET_CAUSEOFISSUE_PAGINATION } from './graphql/Query'
import { DELETE_CAUSEOFISSUE_BY_CODE } from './graphql/Mutation'
import { encryptInput } from '../../../utilitys/crypto'
import { handleSort } from '../../../utilitys/pagination'
import { displayError } from '../../../utilitys/helper'

const CauseOfIssueIndex = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const Responsive = {
    md: 24,
    lg: 24,
  }
  const [visible, setVisible] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataCause, setDataCause] = useState()
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' },
  ])

  /* ---------------- API ----------- */
  const [getCauseOfIssueFn, dataCauseOfIssueList] = useLazyQuery(GET_CAUSEOFISSUE_PAGINATION)
  const [deleteCauseOfIssueFn, dataDeleteCauseOfIssue] = useMutation(DELETE_CAUSEOFISSUE_BY_CODE)

  /* ---------------- EFFECT ----------- */

  useEffect(() => {
    getCauseOfIssueFn({
      variables: {
        input: encryptInput({
          filters: {},
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (dataDeleteCauseOfIssue.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          dataCauseOfIssueList.refetch()
        },
      })
    }

    if (dataDeleteCauseOfIssue.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(dataDeleteCauseOfIssue.error)),
        onOk() {},
      })
    }
  }, [dataDeleteCauseOfIssue.data])

  useEffect(() => {
    if (dataCauseOfIssueList?.data) {
      let response = dataCauseOfIssueList?.data?.getMasterCauseOfIssuePagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data, index) => {
          return {
            ...data,
            key: index + 1,
            isActive: <Status text={data.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => {
                        setVisible(true), setDataCause({ causeCode: data.causeCode, type_action: 'view' })
                      },
                    },
                    {
                      type: 'edit',
                      onClick: () => {
                        setVisible(true), setDataCause({ causeCode: data.causeCode, type_action: 'edit' })
                      },
                    },
                    {
                      type: 'delete',
                      onClick: () => showConfirmDelete(data.causeCode),
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }

    if (dataCauseOfIssueList.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataCauseOfIssueList.error)),
        onOk() {},
      })
    }
  }, [dataCauseOfIssueList?.data])

  /* ---------------- ACTION ----------- */
  const showConfirmDelete = (causeCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteCauseOfIssueFn({
          variables: {
            causeCode: encryptInput(causeCode),
          },
        })
      },
      onCancel() {},
    })
  }

  return (
    <>
      {(dataCauseOfIssueList.loading || dataDeleteCauseOfIssue.loading) && <SpinnersNew />}
      <Col {...Responsive}>
        <CardNew topic={t('จัดการข้อมูลสาเหตุ')} icon="List" toggledrop={'false'}>
          <Row gutter={[12, 12]}>
            <Col {...Responsive}>
              <Datatable
                columns={Columns}
                data={[...dataLists]}
                total={count}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                handleTableChange={(e) => setSort(handleSort(e?.sorter))}
                paginationCustom={true}
                searchCustom={true}
                pageSize={limit}
                page={page}
                // enableScroll={{ x: true, y: true }}
                scroll={{ x: 1000, y: false }}
                btnAdd={
                  <>
                    <ButtonNew
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: menu }}
                      onClick={() => setVisible(true)}
                    >
                      {t('เพิ่มสาเหตุ')}
                    </ButtonNew>
                  </>
                }
              />
            </Col>
          </Row>
        </CardNew>
      </Col>
      <CauseOfIssueModal
        open={visible}
        close={(e) => {
          setVisible(e)
          setDataCause()
        }}
        refetch={() => dataCauseOfIssueList.refetch()}
        dataParams={dataCause}
        menu={menu}
      />
    </>
  )
}

export default CauseOfIssueIndex
