import { gql } from '@apollo/client'

export const SAVE_RATE_RISK_FACTOR_PROCESS = gql`
  mutation SaveRateRiskFactorProcess($input: RateRiskFactorProcessSaveInput!) {
    saveRateRiskFactorProcess(input: $input) {
      rateFacProCode
    }
  }
`

export const DELETE_RATE_RISK_FACTOR_PROCESS = gql`
  mutation DeleteRateRiskFactorProcess($input: RateRiskFactorProcessDeleteInput!) {
    deleteRateRiskFactorProcess(input: $input) {
      rateFacProCode
    }
  }
`
