import React, { useLayoutEffect, useState } from 'react'
import { Card, Spin } from 'antd'
import { CardStyle, CardStyleF } from './CardStyle'
import { IconNew } from '../../../../../components/icon/Icon'
import { colors } from '../../../../../configs/styles.config'
import { LoadingOutlined } from '@ant-design/icons'

export const CardNewDb = (props) => {
  const { loading } = props
  let color
  if (props.color === 'danger') {
    color = colors.error
  } else if (props.color === 'orange') {
    color = colors.lightOrange2
  } else if (props.color === 'blue') {
    color = colors.blue
  } else if (props.color === 'green') {
    color = colors.green
  }

  return (
    <>
      <CardStyle color={color}>
        <Card>
          <span className="card-icon">
            <IconNew icon={props.icon} style={{ marginTop: '5px' }} color={'#FFFFFF'} />
          </span>
          <span style={{ fontSize: '30px', color: '#2B4990' }}>
            {loading ? <Spin indicator={<LoadingOutlined style={{ fontSize: '30' }} spin />} /> : props.content}
          </span>
          <br></br>
          <span style={{ fontSize: '12px' }}>{props.description}</span>
        </Card>
      </CardStyle>
    </>
  )
}

export const CardNewDbF = (props) => {
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight })

  useLayoutEffect(() => {
    const handleResize = async () => {
      setSize({ width: window.innerWidth, height: window.innerHeight })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <>
      <CardStyleF padding={props.padding} size={size}>
        <Card>{props.children}</Card>
      </CardStyleF>
    </>
  )
}
