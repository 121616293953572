import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Col, Row, Input, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { UPDATE_OR_CREATE_PREPARE_AUDIT_REPORT } from '../../graphql/Mutation'
import { GET_PREPARE_AUDIT_REPORT_BY_CODE } from '../../graphql/Query'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { InputNew } from '../../../../../components/input/Input'
import { LabelNew } from '../../../../../components/label/Label'
import { ButtonNew } from '../../../../../components/button/Button'
import { Alert } from '../../../../../components/alert/Alert'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { useLanguage } from '../../../../../hooks/useLanguage'
import { UploadNew as Upload } from '../../../../../components/upload/Upload'
import { displayText, displayError } from '../../../../../utilitys/helper'
import { encryptInput } from '../../../../../utilitys/crypto'
import SelectPersonnel from '../../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import SelectAuditProjects from '../../../../../components/inputfromapi/selectauditprojects/SelectAuditProjects'
import GenerateDocumentNo from '../modal/GenerateDocumentNo'
import { TableFile } from '../../css/Styles'

const GeneralTab = (props) => {
  if (!props.active) return <></>
  const formname = 'generalForm'
  const responsive = { sm: 24, md: 24, lg: 16 }
  const subWrapper = { md: 18, lg: 18, xl: 18 }

  const { menu } = props
  const [isLang] = useLanguage()
  const [form] = Form.useForm()
  const { state } = useLocation()

  const { t } = useTranslation()
  const navigate = useNavigate()

  if (!state) return <Navigate to={'/auditoperations/prepareauditreport'} />

  const [file, setFile] = useState([])

  const [idFileDefault, setIdFileDefault] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])
  const [otherInput, setOtherInput] = useState({})
  const [startDateReport, setStartDateReport] = useState(null)
  const [endDateReport, setEndDateReport] = useState(null)
  const [generateDocumentNoModal, setGenerateDocumentNoModal] = useState(false)
  const [trigger, setTrigger] = useState({})

  const [updateOrCreatePrepareAuditReportFn, updateOrCreatePrepareAuditReport] = useMutation(
    UPDATE_OR_CREATE_PREPARE_AUDIT_REPORT,
  )
  const [getPrepareAuditReportByCodeFn, getPrepareAuditReportByCode] = useLazyQuery(GET_PREPARE_AUDIT_REPORT_BY_CODE)

  useEffect(() => {
    if (state.typeAction !== 'add' && state.finalReportId) {
      getPrepareAuditReportByCodeFn({
        variables: { input: encryptInput({ finalReportId: state.finalReportId, documentNo: state.documentNo }) },
      })
    }
  }, [state.typeAction])

  const [projectCode, setprojectCode] = useState(null)

  useEffect(() => {
    if (getPrepareAuditReportByCode?.data) {
      const resData = getPrepareAuditReportByCode?.data?.getPrepareAuditReportByCode
      setprojectCode(resData?.projectCode)
      form.setFieldsValue({
        documentNo: resData?.documentNo,
        projectCode: resData?.projectCode,
        forName: displayText(resData?.forNameTH, resData?.forNameEN),
        titleName: displayText(resData?.titleNameTH, resData?.titleNameEN),
        sendTo: resData?.sendTo.split(','),
        sendCC: resData?.sendCC ? resData?.sendCC?.split(',') : [],
        detail: resData?.detail,
        detailFinal: resData?.detailFinal,
        personnelCreateCode: resData?.personnelCreateCode,
        personalCheckReport: resData?.personalCheckReport,
        personalCreateReport: resData?.personalCreateReport,
        organizationCodeReportText: displayText(resData?.organizationNameTH, resData?.organizationNameEN),
        organizationCodeReport: resData?.organizationCodeReport,
        positionPersonnelCreateCode: displayText(resData?.positionNameTH, resData?.positionNameEN),
        signingDate: resData?.signingDate,
        startDateReport: resData?.startDateReport,
        endDateReport: resData?.endDateReport,
        docName: resData.fileDetails ? JSON.parse(resData.fileDetails).name : '',
        link: resData.fileDetails ? JSON.parse(resData.fileDetails).link : '',
      })
      setIdFileDefault(
        resData.files
          ? resData.files.split(',').map((item) => {
              return parseInt(item)
            })
          : [],
      )
      state.finalReportId = resData?.finalReportId

      setTrigger({ ...trigger, finalReportDetailId: resData?.finalReportDetailId })
      let dataInput = {
        documentNo: resData?.documentNo,
        organizationCodeReport: resData?.organizationCodeReport,
        status: resData?.status,
      }
      setOtherInput({ ...otherInput, ...dataInput })
    }

    if (getPrepareAuditReportByCode?.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getPrepareAuditReportByCode?.error)),
        onOk() {},
      })
    }
  }, [getPrepareAuditReportByCode?.data])

  useEffect(() => {
    if (updateOrCreatePrepareAuditReport?.data) {
      const response = updateOrCreatePrepareAuditReport?.data?.updateOrCreatePrepareAuditReport
      let url = '/auditoperations/prepareauditreport/tab/summaryauditresults'
      if (response.status === 1) {
        url = '/auditoperations/prepareauditreport'
      }

      Alert({
        type: 'success',
        title: t(otherInput?.status === 1 ? 'บันทึกเสร็จสิ้นสำเร็จ' : 'บันทึกสำเร็จ'),
        onOk() {
          navigate(url, {
            state: { typeAction: 'edit', finalReportId: response.finalReportId, documentNo: response.documentNo },
          })
        },
      })
    }

    if (updateOrCreatePrepareAuditReport?.error) {
      const errorMessage = updateOrCreatePrepareAuditReport?.error.message
      if (errorMessage === 'DocumentNo duplicate please select documentNo again') {
        setOtherInput({ ...otherInput, documentNo: null })
      }
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(updateOrCreatePrepareAuditReport?.error)),
        onOk() {},
      })
    }
  }, [updateOrCreatePrepareAuditReport?.data])

  const onFinish = (data) => {
    if (!data.documentNo) delete data.documentNo
    if (state.finalReportId) data.finalReportId = state.finalReportId
    data.lang = isLang
    data.files = file.join()
    data.idFileDelete = idFileDelete.join()
    data.fileDetails = JSON.stringify({
      link: data.link,
      name: data.docName,
    })
    delete data.positionPersonnelCreateCode
    delete data.organizationCodeReportText
    delete data.link
    delete data.docName

    let dataInput = {
      ...data,
      sendCC: data.sendCC ? data.sendCC : null,
      ...otherInput,
    }
    Alert({
      type: 'confirm',
      title: t(otherInput?.status === 1 ? 'ต้องการยืนยันการบันทึกเสร็จสิ้น?' : 'ต้องการยืนยันการบันทึก?'),
      onOk() {
        updateOrCreatePrepareAuditReportFn({ variables: { input: encryptInput(dataInput) } })
      },
      onCancel() {},
    })
  }

  return (
    <>
      {(updateOrCreatePrepareAuditReport?.loading || getPrepareAuditReportByCode?.loading) && <SpinnersNew />}

      <GenerateDocumentNo
        generateDocumentNoModal={generateDocumentNoModal}
        menu={menu}
        state={state}
        close={(e) => setGenerateDocumentNoModal(e)}
        fieldForGenerate={{ ...form.getFieldValue(), ...otherInput }}
        formSetValueGeneral={form}
        setOtherInput={setOtherInput}
      />
      <Form
        form={form}
        onFinish={onFinish}
        name={formname}
        autoComplete="off"
        labelAlign="left"
        labelCol={{
          lg: 6,
          xl: 6,
        }}
        // wrapperCol={{
        //   lg: 16,
        //   xl: 16,
        //   align: 'left',
        // }}
      >
        <Row style={{ margin: '10px 0px' }}>
          <Col {...subWrapper}>
            <LabelNew type="sub-header-primary">{t('ข้อมูลทั่วไป')}</LabelNew>
          </Col>
        </Row>
        <Row justify="center">
          <Col {...responsive}>
            <Form.Item name="documentNo" label={<LabelNew type="tab-header-inactive">{t('เลขที่ตรวจสอบ')}</LabelNew>}>
              <Input.Group>
                <InputNew disabled={true} value={otherInput.documentNo} placeholder={t('เลือกเลขที่ตรวจสอบ')} />
                <ButtonNew
                  disabled={state.typeAction !== 'edit' || otherInput.documentNo ? true : false}
                  htmlType="button"
                  style={{ minHeight: '37px', marginLeft: '5px' }}
                  type="primary"
                  roles={{ type: state.typeAction, menu: menu }}
                  onClick={async () => {
                    await setGenerateDocumentNoModal(true)
                  }}
                >
                  {t('เลขที่หนังสือ')}
                </ButtonNew>
              </Input.Group>
            </Form.Item>
            <Form.Item
              name="projectCode"
              label={<LabelNew type="tab-header-inactive">{t('โครงการ')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกโครงการ') }]}
            >
              <SelectAuditProjects
                placeholder={t('เลือกโครงการ')}
                formname={formname}
                disabled={state?.typeAction === 'view'}
                filters={{
                  hasInnerAuditClose: true,
                  projectStatusCode: 90,
                  projectStatusCodeOperator: '!=',
                  isNullAuditFinalReport: true,
                  projectCode: projectCode,
                }}
                handleChange={async (e) => {
                  await form.setFieldsValue({
                    projectCode: e?.value,
                    organizationCodeReportText: displayText(
                      e?.source?.organizationNameTH,
                      e?.source?.organizationNameEN,
                    ),
                  })
                  setOtherInput({
                    ...otherInput,
                    organizationCodeReport: e?.source?.organizationCode,
                  })
                }}
              />
            </Form.Item>
            <Form.Item
              name="titleName"
              label={<LabelNew type="tab-header-inactive">{t('เรื่อง')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุเรื่อง') }]}
            >
              <InputNew placeholder={t('ระบุเรื่อง')} maxLength={255} disabled={state?.typeAction === 'view'} />
            </Form.Item>
            <Form.Item
              name="forName"
              label={<LabelNew type="tab-header-inactive">{t('เพื่อ')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุเพื่อ') }]}
            >
              <InputNew placeholder={t('ระบุเพื่อ')} maxLength={255} disabled={state?.typeAction === 'view'} />
            </Form.Item>
            <Form.Item
              name="signingDate"
              label={<LabelNew type="tab-header-inactive">{t('วันที่ลงนาม')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกวันที่ลงนาม') }]}
            >
              <Datepicker
                disabled={state?.typeAction === 'view'}
                autoComplete="off"
                setValueDateFn={async (date) => {
                  await form.setFieldsValue({ signingDate: await date })
                }}
                onClear={() => {
                  form.setFieldsValue({ signingDate: null })
                }}
                placeholder={t('DD/MM/YYYY')}
              />
            </Form.Item>
            <Form.Item
              name="sendTo"
              label={<LabelNew type="tab-header-inactive">{t('เรียน')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกเรียน') }]}
            >
              <SelectPersonnel
                multiple
                filters={{ emailNotNull: true, personnaloperationCodeWhereIn: '10,20' }}
                placeholder={t('เลือกเรียน')}
                formname={formname}
                disabled={state?.typeAction === 'view'}
                handleChange={async (e) => {
                  await form.setFieldsValue({ sendTo: e })
                }}
              />
            </Form.Item>
            <Form.Item
              name="sendCC"
              label={<LabelNew type="tab-header-inactive">{t('สำเนาเรียน')}</LabelNew>}
              // rules={[{ required: true, message: t('กรุณาเลือกสำเนาเรียน') }]}
            >
              <SelectPersonnel
                multiple
                filters={{ emailNotNull: true, personnaloperationCodeWhereIn: '10,20' }}
                placeholder={t('เลือกสำเนาเรียน')}
                formname={formname}
                disabled={state?.typeAction === 'view'}
                handleChange={async (e) => {
                  await form.setFieldsValue({ sendCC: e })
                }}
              />
            </Form.Item>
            <Form.Item
              name="organizationCodeReportText"
              label={<LabelNew type="tab-header-inactive">{t('จาก(หน่วยงาน)')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกจาก(หน่วยงาน)') }]}
            >
              <InputNew disabled={true} placeholder={t('เลือกจาก(หน่วยงาน)')} />
            </Form.Item>
            <Form.Item
              name="detail"
              label={<LabelNew type="tab-header-inactive">{t('รายละเอียด')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกรายละเอียด') }]}
            >
              <TextAreaNew
                placeholder={t('ระบุรายละเอียด')}
                autoSize={{ minRows: 6, maxRows: 6 }}
                disabled={state?.typeAction === 'view'}
              />
            </Form.Item>
            <Form.Item
              name="detailFinal"
              label={<LabelNew type="tab-header-inactive">{t('คำลงท้าย')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกคำลงท้าย') }]}
            >
              <TextAreaNew
                placeholder={t('ระบุคำลงท้าย')}
                autoSize={{ minRows: 6, maxRows: 6 }}
                disabled={state?.typeAction === 'view'}
              />
            </Form.Item>
            <Form.Item
              name="personnelCreateCode"
              label={<LabelNew type="tab-header-inactive">{t('ผู้ลงนาม')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกผู้ลงนาม') }]}
            >
              <SelectPersonnel
                placeholder={t('เลือกผู้ลงนาม')}
                formname={formname}
                disabled={state?.typeAction === 'view'}
                handleChange={async (e) => {
                  await form.setFieldsValue({
                    personnelCreateCode: e?.value,
                    positionPersonnelCreateCode: displayText(e?.source.positionNameTH, e?.source.positionNameEN),
                  })
                }}
              />
            </Form.Item>
            <Form.Item
              name="positionPersonnelCreateCode"
              label={<LabelNew type="tab-header-inactive">{t('ตำแหน่งผู้ลงนาม')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกตำแหน่งผู้ลงนาม') }]}
            >
              <InputNew disabled={true} placeholder={t('เลือกตำแหน่งผู้ลงนาม')} />
            </Form.Item>
            <Form.Item
              name="personalCreateReport"
              label={<LabelNew type="tab-header-inactive">{t('ผู้จัดทำรายงาน')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกผู้จัดทำรายงาน') }]}
            >
              <InputNew placeholder={t('ระบุผู้จัดทำรายงาน')} maxLength={255} disabled={state?.typeAction === 'view'} />
            </Form.Item>
            <Form.Item
              name="personalCheckReport"
              label={<LabelNew type="tab-header-inactive">{t('ผู้สอบทานรายงาน')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกผู้สอบทานรายงาน') }]}
            >
              <InputNew
                placeholder={t('ระบุผู้สอบทานรายงาน')}
                maxLength={255}
                disabled={state?.typeAction === 'view'}
              />
            </Form.Item>
            <Form.Item
              name="startDateReport"
              label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้นรายงาน')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกวันที่เริ่มต้นรายงาน') }]}
            >
              <Datepicker
                disabled={state?.typeAction === 'view'}
                autoComplete="off"
                setValueDateFn={async (date) => {
                  await setEndDateReport(date)
                  await form.setFieldsValue({ startDateReport: await date })
                }}
                onClear={() => {
                  form.setFieldsValue({ startDateReport: null })
                }}
                placeholder={t('DD/MM/YYYY')}
                isMaxDate={startDateReport ? new Date(startDateReport) : null}
              />
            </Form.Item>
            <Form.Item
              name="endDateReport"
              label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุดรายงาน')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกวันที่สิ้นสุดรายงาน') }]}
            >
              <Datepicker
                autoComplete="off"
                disabled={state?.typeAction === 'view'}
                setValueDateFn={async (date) => {
                  await setStartDateReport(date)
                  await form.setFieldsValue({ endDateReport: await date })
                }}
                onClear={() => {
                  form.setFieldsValue({ endDateReport: null })
                }}
                placeholder={t('DD/MM/YYYY')}
                isMinDate={endDateReport ? new Date(endDateReport) : null}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ margin: '10px 0px' }}>
          <Col md={18} lg={18} xl={18}>
            <LabelNew type="sub-header-primary">{t('แนบเอกสาร')}</LabelNew>
          </Col>
        </Row>
        <Row style={{ marginBottom: '10px' }}>
          <Col md={24}>
            <TableFile>
              <Table
                pagination={false}
                columns={[
                  {
                    title: <div style={{ textAlign: 'center' }}>{t('ชื่อเอกสาร')}</div>,
                    dataIndex: 'docName',
                    width: '30%',
                  },
                  {
                    title: <div style={{ textAlign: 'center' }}>{t('ลิงค์')}</div>,
                    dataIndex: 'link',
                    width: '30%',
                  },
                  {
                    title: <div style={{ textAlign: 'center' }}>{t('แนบเอกสาร')}</div>,
                    dataIndex: 'file',
                    width: '30%',
                  },
                  {
                    title: '',
                    dataIndex: 'action',
                    width: '10%',
                  },
                ]}
                dataSource={[
                  {
                    key: 1,
                    docName: (
                      <Form.Item name="docName">
                        <InputNew
                          placeholder={t('ระบุชื่อเอกสาร')}
                          disabled={state.typeForm === 'view' ? true : false}
                        />
                      </Form.Item>
                    ),
                    link: (
                      <>
                        <Form.Item
                          name="link"
                          rules={[
                            {
                              type: 'url',
                              message: t('กรุณาระบุลิงค์เอกสารให้ถูกต้อง'),
                            },
                          ]}
                          hidden={state.typeForm === 'view' ? true : false}
                        >
                          <InputNew placeholder={t('ระบุลิงค์')} />
                        </Form.Item>
                        <a
                          href={form.getFieldValue('link')}
                          target="_blank"
                          rel="noreferrer"
                          style={{ color: '#2b4990', display: state.typeForm !== 'view' ? 'none' : 'auto' }}
                        >
                          <u>{form.getFieldValue('link')}</u>
                        </a>
                      </>
                    ),
                    file: (
                      <Upload
                        setIdFile={setFile}
                        idFile={file}
                        actionType={state.typeForm === 'add' ? 'create' : state.typeForm}
                        idFileDelete={idFileDelete}
                        defaults={idFileDefault}
                        setIdFileDelete={setIdFileDelete}
                        typeFile="file"
                        disabled={state.typeForm === 'view' ? true : false}
                      />
                    ),
                    action: (
                      <ButtonNew
                        disabled={props.typeForm === 'view' ? true : false}
                        onClick={() => {
                          form.setFieldsValue({
                            docName: '',
                            link: '',
                          })
                        }}
                        type="clearUseInTable"
                      />
                    ),
                  },
                ]}
              />
            </TableFile>
          </Col>
        </Row>
        <Row gutter={[12, 20]} justify="center">
          {state.typeAction !== 'view' && (
            <>
              <Col>
                <ButtonNew
                  type="primary"
                  onClick={() => {
                    setOtherInput({ ...otherInput, status: 0 })
                  }}
                  roles={{ type: state.typeAction, menu: menu }}
                  htmlType="submit"
                >
                  {t('บันทึก')}
                </ButtonNew>
              </Col>

              <Col>
                <ButtonNew
                  type="success"
                  onClick={() => {
                    setOtherInput({ ...otherInput, status: 1 })
                  }}
                  disabled={
                    state.typeAction !== 'edit' || !otherInput.documentNo || !trigger.finalReportDetailId ? true : false
                  }
                  roles={{ type: state.typeAction, menu: menu }}
                  htmlType="submit"
                >
                  {t('เสร็จสิ้น')}
                </ButtonNew>
              </Col>
            </>
          )}
          <Col>
            <ButtonNew type="back" onClick={() => navigate('/auditoperations/prepareauditreport')}>
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
    </>
  )
}

GeneralTab.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default GeneralTab
