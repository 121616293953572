import React, { useState } from 'react'
import { Row, Col, Space, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import SelectYear from '../../../../components/inputfromapi/selectyear/SelectYear'
import SelectRiskModelNew from '../../../../components/inputfromapi/selectriskmodel/SelectRiskModel'
import SelectOrganizationNew from '../../../../components/inputfromapi/selectorganization/SelectOrganization'
import { Datepicker } from '../../../../components/datepicker/Datepicker'
import { LabelNew } from '../../../../components/label/Label'
import { Select } from '../../../../components/select/Select'
import { Radio, RadioGroup } from '../../../../components/radio/Radio'
import { InputNew } from '../../../../components/input/Input'
const RiskformSecondStepTabs1 = ({ disabled, responsiveLabel, responsiveCol, form }) => {
  const { t } = useTranslation()
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const formName = 'risk_form'
  return (
    <>
      <Row style={{ marginTop: 24 }} justify="center">
        <Col sm={24} md={20} lg={20}>
          <Row gutter={[16, 8]}>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">
                {t('ประจำปี')} <span style={{ color: '#ea5456' }}>*</span>
              </LabelNew>
            </Col>
            <Col {...responsiveCol}>
              <Form.Item name="Year" rules={[{ required: true, message: t('กรุณาระบุปี') }]}>
                <SelectYear
                  formname={formName}
                  placeholder={t('ระบุปี')}
                  handleChange={(v) => {
                    form.setFieldsValue({
                      Year: v.value,
                    })
                  }}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">
                {t('รหัสแบบประเมิน')} <span style={{ color: '#ea5456' }}>*</span>
              </LabelNew>
            </Col>
            <Col {...responsiveCol}>
              <Form.Item name="riskFormCode" rules={[{ required: true, message: t('ระบุรหัสแบบประเมิน') }]}>
                <InputNew placeholder={t('ระบุรหัสแบบประเมิน')} disabled={true} />
              </Form.Item>
            </Col>
            {/*  */}
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">
                {t('ชื่อแบบประเมินภาษาไทย')} <span style={{ color: '#ea5456' }}>*</span>
              </LabelNew>
            </Col>
            <Col {...responsiveCol}>
              <Form.Item name="riskFormTH" rules={[{ required: true, message: t('ระบุชื่อแบบประเมินภาษาไทย') }]}>
                <InputNew placeholder={t('ระบุชื่อแบบประเมินภาษาไทย')} disabled={disabled} maxLength={200} />
              </Form.Item>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">{t('ชื่อแบบประเมินภาษาอังกฤษ')}</LabelNew>
            </Col>
            <Col {...responsiveCol}>
              <Form.Item name="riskFormEN">
                <InputNew placeholder={t('ระบุชื่อแบบประเมินภาษาอังกฤษ')} disabled={disabled} maxLength={200} />
              </Form.Item>
            </Col>
            {/*  */}
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">
                {t('คลังแบบประเมินความเสี่ยง')} <span style={{ color: '#ea5456' }}>*</span>
              </LabelNew>
            </Col>
            <Col {...responsiveCol}>
              <Form.Item name="riskModelCode" rules={[{ required: true, message: t('ระบุคลังแบบประเมินความเสี่ยง') }]}>
                <SelectRiskModelNew
                  placeholder={t('ระบุคลังแบบประเมินความเสี่ยง')}
                  formname={formName}
                  handleChange={(v) => {
                    form.setFieldsValue({
                      riskModelCode: v.value,
                    })
                  }}
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">
                {t('ประเภทการประเมิน')} <span style={{ color: '#ea5456' }}>*</span>
              </LabelNew>
            </Col>
            <Col {...responsiveCol}>
              <Form.Item name="riskFormType" rules={[{ required: true, message: t('ระบุประเภทการประเมิน') }]}>
                {/* ประเภทการประเมิน 1 = ประเมินสรุป , 2 = ประเมินรายหน่วยงาน */}
                <Select
                  title=""
                  data={[
                    { value: 1, label: t('ประเมินสรุป') },
                    { value: 2, label: t('ประเมินรายหน่วยงาน') },
                  ]}
                  placeholder={t('ระบุประเภทการประเมิน')}
                  scrollableId={formName}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
            {/*  */}
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">
                {t('หน่วยตรวจสอบ')} <span style={{ color: '#ea5456' }}>*</span>
              </LabelNew>
            </Col>
            <Col {...responsiveCol}>
              <Form.Item name="oganizationAudit">
                <SelectOrganizationNew
                  placeholder={t('ระบุหน่วยตรวจสอบ')}
                  formname={formName}
                  handleChange={(v) => {
                    form.setFieldsValue({
                      oganizationAudit: v.value,
                    })
                  }}
                  filters={{
                    // organizationChecked: 2,
                    isActive: 1,
                  }}
                  disabled={true}
                />
              </Form.Item>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">
                {t('วันเริ่มต้นประเมิน')} <span style={{ color: '#ea5456' }}>*</span>
              </LabelNew>
            </Col>
            <Col {...responsiveCol}>
              <Form.Item name={'dateStart'} rules={[{ required: true, message: t('กรุณาระบุวันเริ่มต้นประเมิน') }]}>
                <Datepicker
                  setValueDateFn={async (data) => {
                    setIsMinDate(data)
                    form.setFieldsValue({ dateStart: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateStart: null })
                  }}
                  isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                  autoComplete="off"
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
            {/*  */}
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">
                {t('วันสิ้นสุดประเมิน')} <span style={{ color: '#ea5456' }}>*</span>
              </LabelNew>
            </Col>
            <Col {...responsiveCol}>
              <Form.Item name={'dateEnd'} rules={[{ required: true, message: t('กรุณาระบุวันสิ้นสุดประเมิน') }]}>
                <Datepicker
                  setValueDateFn={async (data) => {
                    setIsMaxDate(data)
                    form.setFieldsValue({ dateEnd: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateEnd: null })
                  }}
                  isMinDate={isMinDate ? new Date(isMinDate) : null}
                  autoComplete="off"
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">
                {t('สถานะดำเนินการ')} <span style={{ color: '#ea5456' }}>*</span>
              </LabelNew>
            </Col>
            <Col {...responsiveCol}>
              <Form.Item name="operationStatusCode" rules={[{ required: true, message: t('ระบุสถานะดำเนินการ') }]}>
                <InputNew disabled={true} />
              </Form.Item>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">
                {t('สถานะ')} <span style={{ color: '#ea5456' }}>*</span>
              </LabelNew>
            </Col>
            <Col {...responsiveCol}>
              <Form.Item name="isActive" rules={[{ required: true, message: t('ระบุสถานะ') }]}>
                <RadioGroup disabled={disabled}>
                  <Space size={[8, 8]} wrap>
                    <Radio value={1}>{t('ใช้งาน')}</Radio>
                    &nbsp;
                    <Radio value={2}>{t('ไม่ใช้งาน')}</Radio>
                  </Space>
                </RadioGroup>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Form.Item hidden={true} name="organizationList" rules={[{ required: true, message: 'required' }]}>
        <Input />
      </Form.Item>
      <Form.Item hidden={true} name="operationStatusName" rules={[{ required: true, message: 'required' }]}>
        <Input />
      </Form.Item>
    </>
  )
}

export default RiskformSecondStepTabs1
