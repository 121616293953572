import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { LabelNew } from '../../../../../../components/label/Label'
import { Datatable } from '../../../../../../components/datatable/Datatable'
import { CardNew } from '../../../../../../components/card/Card'
import { SpinnersNew } from '../../../../../../components/spinners/Spinners'
import { dateDisplay, yearDisplay } from '../../../../../../utilitys/dateHelper'
import { displayText } from '../../../../../../utilitys/helper'
import { causeAndAuditIssueColumns } from '../../utils/columns'
import { useGetSummaryAuditStatusCauseAndIssue, useGetAuditFollowupActivityInspector } from '../../hooks'
import { WrapperCard } from '../../css/Styled'

const SummaryAuditStatusCauseAndIssue = (props) => {
  const ResponseTitle = { md: 24, lg: 6, xl: 5, xxl: 4 }
  const ResponseCol = { md: 24, lg: 18, xl: 19, xxl: 20 }
  const { filterDt } = props

  const { t } = useTranslation()

  const [auditStatus, setAuditStatus] = useState({})

  const options = useMemo(() => {
    return {
      dateStart: filterDt?.dateStart,
      dateEnd: filterDt?.dateEnd,
    }
  }, [filterDt?.dateStart, filterDt?.dateEnd])

  const [summaryAuditStatusCauseAndIssue, summaryAuditStatusCauseAndIssueLoading] =
    useGetSummaryAuditStatusCauseAndIssue(options)

  useEffect(() => {
    if (summaryAuditStatusCauseAndIssue) setAuditStatus(summaryAuditStatusCauseAndIssue)
  }, [summaryAuditStatusCauseAndIssue])

  return (
    <>
      {summaryAuditStatusCauseAndIssueLoading && <SpinnersNew />}
      <WrapperCard>
        <CardNew
          topic={`${t('ข้อมูลตั้งแต่วันที่')} ${dateDisplay(filterDt?.dateStart)} ${t('ถึงวันที่')} ${dateDisplay(
            filterDt?.dateEnd,
          )}`}
          toggledrop={'false'}
        >
          <Row gutter={[8, 8]}>
            <Col {...ResponseTitle}>
              <LabelNew type="body">1. {t('ผ่านการรายงาน AC แล้ว')}: </LabelNew>
            </Col>
            <Col {...ResponseCol}>
              <LabelNew type="body">
                {`${t('จำนวน')} ${auditStatus?.cntReportAcProject} ${t('โครงการ')}, ${t('จำนวน')} ${
                  auditStatus?.cntReportAcPoint
                } ${t('ประเด็น')}`}{' '}
              </LabelNew>
            </Col>
            <Col {...ResponseTitle}>
              <LabelNew type="body">2. {t('กจ. ลงนามสั่งการแล้ว')}: </LabelNew>
            </Col>
            <Col {...ResponseCol}>
              <LabelNew type="body">
                {`${t('จำนวน')} ${auditStatus?.cntAssignOrderProject} ${t('โครงการ')}, ${t('จำนวน')} ${
                  auditStatus?.cntAssignOrderPoint
                } ${t('ประเด็น')}`}{' '}
              </LabelNew>
            </Col>
            <Col {...ResponseTitle}>
              <LabelNew type="body">3. {t('อยู่ระหว่างดำเนินการ')}: </LabelNew>
            </Col>
            <Col {...ResponseCol}>
              <LabelNew type="body">{`${t('จำนวน')} ${auditStatus?.cntInProcessPoint} ${t('ประเด็น')}`} </LabelNew>
            </Col>
            <Col {...ResponseTitle}>
              <LabelNew type="body">4. {t('แก้ไขแล้ว')}: </LabelNew>
            </Col>
            <Col {...ResponseCol}>
              <LabelNew type="body">{`${t('จำนวน')} ${auditStatus?.cntEditedPoint} ${t('ประเด็น')}`} </LabelNew>
            </Col>
            <Col {...ResponseTitle}>
              <LabelNew type="body">5. {t('เกินกำหนดแล้วยังไม่ตอบ')}: </LabelNew>
            </Col>
            <Col {...ResponseCol}>
              <LabelNew type="body">{`${t('จำนวน')} ${auditStatus?.cntNotResponsePoint} ${t('ประเด็น')}`} </LabelNew>
            </Col>
          </Row>
        </CardNew>
      </WrapperCard>
    </>
  )
}

const StatusCauseAndIssueDetectedTable = (props) => {
  const { filterDt, projectCodes } = props

  const { t } = useTranslation()

  const [dataList, setDataList] = useState([])

  const optionProjectCodes = useMemo(() => {
    if (!projectCodes.length) setDataList([])
    return { projectCode: projectCodes }
  }, [projectCodes])

  const [auditFollowupActivityInspector, auditFollowupActivityInspectorLoading] =
    useGetAuditFollowupActivityInspector(optionProjectCodes)

  useEffect(() => {
    if (auditFollowupActivityInspector) {
      const gAuditFollowupActivityInspector = _.groupBy(auditFollowupActivityInspector, 'projectCode')

      const makeDt = []
      const summary = {}

      Object.keys(gAuditFollowupActivityInspector).forEach((key, idx) => {
        const dt = gAuditFollowupActivityInspector[key]

        dt.forEach((d, i) => {
          const setDt = {
            key: `${idx}_${i}`,
            leadTeamName: displayText(
              `${d.namePrefixTH}${d.firstNameTH} ${d.lastNameTH}`,
              `${d.namePrefixEN}${d.firstNameEN} ${d.lastNameEN}`,
            ),
            issueName: displayText(d.issueTH, d.issueEN),
            causeName: displayText(d.causeNameTH, d.causeNameEN),
            oraganizationName: displayText(d.organizationNameTH, d.organizationNameEN),
            riskLevelName: displayText(d.riskLevelNameTH, d.riskLevelNameEN),
            actionStatusName: displayText(d.actionStatusNameTH, d.actionStatusNameEN),
            actionConditionDate: dateDisplay(d.actionConditionDate),
          }

          if (i === 0) {
            setDt.no = idx + 1
            setDt.year = yearDisplay(d.dateStart)
            setDt.projectName = displayText(
              `${d.projectCode}: ${d.projectNameTH}`,
              `${d.projectCode}: ${d.projectNameEN}`,
            )
          }

          makeDt.push(setDt)
        })
      })

      summary.key = 'summary'
      summary.no = t('รวม')
      summary.projectName = Object.keys(gAuditFollowupActivityInspector).length
      summary.oraganizationName = auditFollowupActivityInspector.length

      makeDt.push(summary)
      setDataList(makeDt)
    }
  }, [auditFollowupActivityInspector])

  return (
    <>
      {auditFollowupActivityInspectorLoading && <SpinnersNew />}
      <SummaryAuditStatusCauseAndIssue filterDt={filterDt} />
      <Row gutter={[16, 16]} style={{ marginTop: '1rem' }}>
        <Col span={24}>
          <LabelNew type="body-header">{t('ผู้ตรวจสอบ')}</LabelNew>
        </Col>
        <Col span={24}>
          <Datatable columns={causeAndAuditIssueColumns(t)} data={dataList} />
        </Col>
      </Row>
    </>
  )
}

StatusCauseAndIssueDetectedTable.propTypes = {
  filterDt: PropTypes.object,
}

export default StatusCauseAndIssueDetectedTable
