import { gql } from '@apollo/client'

export const GET_FOLLOW_UP_REPORT_LIST_PAGINATION = gql`
  query GetFollowUpReportListPagination($input: FollowUpReportListInput!) {
    getFollowUpReportListPagination(input: $input) {
      result {
        no
        exportDate
        bankCode
        projectTypeNameTH
        projectTypeNameEN
        projectCode
        projectNameTH
        projectNameEN
        dateStart
        dateEnd
        issueId
        issueTH
        issueEN
        findingTypeNameTH
        findingTypeNameEN
        suggestTH
        suggestEN
        activityId
        actionId
        actionDetailTH
        actionDetailEN
        riskLevelNameTH
        riskLevelNameEN
        issueDateEnd
        followDateNext
        planDetailTH
        planDetailEN
        actionStatusNameTH
        actionStatusNameEN
        issueCloseDate
      }
      count
      limit
      page
    }
  }
`

export const GET_FOLLOW_UP_REPORT_EXPORT = gql`
  query GetFollowUpReportExport($input: FollowUpReportExportInput!) {
    getFollowUpReportExport(input: $input) {
      message
      fileUrl
    }
  }
`
