import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_CAUSE_OF_ISSUE } from './graphql/Query'
import { Select } from '../../select/Select'
import { displayText } from '../../../utilitys/helper'
import { encryptInput } from '../../../utilitys/crypto'

const SelectCauseOfIssue = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { isActive } = filters || {}

  const [isData, isSetData] = useState([])

  const [cellGet, { data: dataGet, loading: loadingGat }] = useLazyQuery(GET_MASTER_CAUSE_OF_ISSUE)

  useEffect(() => {
    cellGet({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [isActive])

  useEffect(() => {
    if (dataGet) {
      isSetData(
        _.map(dataGet?.getMasterCauseOfIssue, (item) => {
          return {
            label: item?.causeCode + ' : ' + displayText(item?.causeNameTH, item?.causeNameEN),
            value: item?.causeCode,
            source: item,
          }
        }),
      )
    }
  }, [dataGet])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={loadingGat}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectCauseOfIssue
