import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { Row, Col } from 'antd'
import { ButtonNew as Button } from '../../../../../components/button/Button'
import { useNavigate, useLocation } from 'react-router-dom'
// import { CardNew as Card } from '../../../../../components/card/Card'
import { GET_APPROVE_AUDIT_PROJECT_ONE, GET_APPROVE_FILES } from '../../graphql/Query'
import { useLazyQuery } from '@apollo/client'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { downloadFile } from '../../../../../utilitys/files'
import { DownlodApproveStyle } from '../../utils/approveStyle'
import { encryptInput } from '../../../../../utilitys/crypto'
import * as _ from 'lodash'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError } from '../../../../../utilitys/helper'
// import { decryptData } from '../../../../../utilitys/crypto'
// import { Upload } from 'antd'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}

export default function AdditionalDocuments() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  // const state = JSON.parse(decryptData(localStorage.getItem('pageApproveState')))
  // const urlApipath = process.env.REACT_APP_API.split('graphql')[0]
  // const state = props.state
  const { state } = useLocation()

  const AdditionalDocumentsColumn = [
    {
      title: t('ชื่อเอกสาร'),
      dataIndex: 'documentName',
      // sorter: true,
    },
    {
      title: t('เอกสารอ้างอิง'),
      dataIndex: 'documentRef',
      align: 'left',
      width: '60%',
    },
  ]

  const [getDataList, setDataList] = useState([])
  const [dataFiles, setDataFiles] = useState([])

  const [getApproveAuditProjectOneFn, getApproveAuditProjectOne] = useLazyQuery(GET_APPROVE_AUDIT_PROJECT_ONE)
  const [getProjectFilesFn, getProjectFiles] = useLazyQuery(GET_APPROVE_FILES)

  useEffect(() => {
    if (state.refCode) {
      getApproveAuditProjectOneFn({
        variables: {
          inputData: encryptInput({ planCode: state.refCode, projectCode: state.projectCode }),
        },
      })
    }
  }, [state.refCode])

  useEffect(() => {
    if (getApproveAuditProjectOne?.data) {
      const resData = getApproveAuditProjectOne?.data.getApproveAuditProjectOne
      if (resData.files) {
        const fileData = JSON.parse(resData.fileDetail)
        setDataFiles(fileData)
        getProjectFilesFn({
          fetchPolicy: 'no-cache',
          variables: {
            inputData: encryptInput({
              fileId: fileData.map((dt) => {
                return dt.fileId
              }),
            }),
          },
        })
      }
    }
    if (getApproveAuditProjectOne.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getApproveAuditProjectOne.error)),
        onOk() {},
      })
    }
  }, [getApproveAuditProjectOne?.data])

  useEffect(() => {
    if (getProjectFiles?.data) {
      const files = getProjectFiles?.data?.getApproveFiles
      setDataList(
        dataFiles.map((dt, keys) => {
          const dataFormfiles = _.filter(files, function (o) {
            return dt.fileId === o.fileId
          })
          return {
            key: keys,
            documentName: dt.name,
            documentRef: (
              <DownlodApproveStyle>
                <div>
                  <Label
                    type="body-primary"
                    key={'labelFilesApprove' + keys}
                    className="approve-downloads"
                    onClick={() => {
                      if (dataFormfiles.length > 0) {
                        downloadFile(
                          dataFormfiles[0].fileNameLabel + dataFormfiles[0].fileType,
                          dataFormfiles[0].fileNameGen + dataFormfiles[0].fileType,
                          () => {},
                        )
                      }
                    }}
                  >
                    {dataFormfiles.length > 0 ? dataFormfiles[0].fileNameLabel + dataFormfiles[0].fileType : ''}
                  </Label>
                </div>
              </DownlodApproveStyle>
            ),
          }
        }),
      )
    }
    if (getProjectFiles.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getProjectFiles.error)),
        onOk() {},
      })
    }
  }, [getProjectFiles?.data])

  return (
    <>
      {(getApproveAuditProjectOne.loading || getProjectFiles.loading) && <SpinnersNew />}
      <Label type="card-title" icon="Plus">
        {t('รายละเอียดโครงการ')}
      </Label>
      <Row gutter={[16, 16]}>
        <Col {...Responsive}>
          <Datatable
            columns={AdditionalDocumentsColumn}
            data={getDataList}
            total={199}
            handleTableChange={() => {}}
            onChangePagination={() => {}}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 24, marginBottom: 24 }} align="center">
        <Col md={3}>
          <Button
            type="back"
            onClick={() => {
              navigate('/auditplanning/approveauditplan/detail/', {
                state: state,
              })
            }}
          >
            {t('ย้อนกลับ')}
          </Button>
        </Col>
      </Row>
    </>
  )
}
