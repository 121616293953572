import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import { InputNew } from '../input/Input'
import { IconNew } from '../icon/Icon'
import './SearchTableStyle.scss'

export const SearchTable = (props) => {
  const { dataSource, searchDataSource, searchCustom, btnAdd } = props

  const [isKeys, setIsKeys] = useState([])
  const [isValueSearch, isSetValueSearch] = useState('')

  useEffect(() => {
    setIsKeys(dataSource.length && dataSource[0] ? Object.keys(dataSource[0]) : [])
  }, [dataSource])

  const handleSearch = (value) => {
    isSetValueSearch(value)
    const resDataSource = dataSource.length
      ? dataSource.filter(
          (row) =>
            isKeys.length &&
            isKeys.some(
              (column) => row[column] && row[column].toString().toLowerCase().indexOf(value.toLowerCase()) > -1,
            ),
        )
      : []
    searchDataSource(resDataSource.length ? resDataSource : [])
  }
  return (
    <div className={`search-table-wrapper ${btnAdd || searchCustom ? 'mb' : ''}`}>
      <Row style={{ justifyContent: 'space-between' }}>
        <Col>{btnAdd && <Row>{btnAdd}</Row>}</Col>
        <Col>
          {searchCustom && (
            <InputNew
              placeholder=""
              shape="searchbar"
              name="search_table"
              prefix={<IconNew icon={'Search'} />}
              onChange={(e) => handleSearch(e.target.value && e.target.value.toString().toLowerCase())}
              value={isValueSearch}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

SearchTable.propTypes = {
  dataSource: PropTypes.array,
  searchDataSource: PropTypes.func,
  btnAdd: PropTypes.element,
  searchCustom: PropTypes.bool,
}
