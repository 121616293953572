import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import * as _ from 'lodash'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Datatable } from '../../../../components/datatable/Datatable.jsx'
import { Status } from '../../../../components/status/Status'
import { ButtonNew } from '../../../../components/button/Button'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../../components/spinners/Spinners.jsx'
import { GET_AUDIT_ISSUE_PAGINATION } from '../graphql/Query'
import { DELETE_AUDIT_ISSUE } from '../graphql/Mutation'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayText, displayError } from '../../../../utilitys/helper'
import { Alert } from '../../../../components/alert/Alert'
import { handleSort } from '../../../../utilitys/pagination'

const TabOne = (props) => {
  const { t } = useTranslation()
  const { menu, setStatus } = props
  const [dataLists, setDataLists] = useState([])
  const navigate = useNavigate()
  const { state } = useLocation()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [sort, setSort] = useState([])
  const [count, setCount] = useState(0)

  if (!state) {
    return <Navigate to={'/auditoperations/auditresult/'} />
  }

  const [pagination, { data: dataPagination, loading: loadingPagination, error: errorPagination }] =
    useLazyQuery(GET_AUDIT_ISSUE_PAGINATION)

  const [cellDelete, { data: dataDelete, loading: loadingDelete, error: errorDelete }] = useMutation(DELETE_AUDIT_ISSUE)

  useEffect(() => {
    if (dataDelete) {
      if (dataDelete.auditIssueDelete.issueId) {
        Alert({
          type: 'success',
          title: t('ลบข้อมูลสำเร็จ'),
          onOk() {
            pagination({
              variables: {
                input: encryptInput({
                  filters: { actionPlanId: state.actionPlanId },
                  pagination: { limit, page },
                  sort: sort,
                }),
              },
            })
          },
        })
      }
    }
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถลบข้อมูลได้`),
        content: t(displayError(errorDelete)),
        onOk() {},
      })
    }
  }, [dataDelete])

  useEffect(() => {
    pagination({
      variables: {
        input: encryptInput({
          filters: { actionPlanId: state.actionPlanId },
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (dataPagination) {
      let response = dataPagination.auditIssuePaginationGet
      setCount(response.count)
      if (response.result.length > 0) {
        setStatus(true)
      }

      setDataLists(
        _.map(response.result, (item, keys) => {
          let show = true
          let show2 = true

          if (keys !== 0) {
            show = response.result[keys - 1].rateFacProCode !== response.result[keys].rateFacProCode ? true : false
          }

          if (keys !== 0) {
            show2 = response.result[keys - 1].rateRiskConCode !== response.result[keys].rateRiskConCode ? true : false
          }

          return {
            key: keys + 1,
            show: show,
            show2: show2,
            factorsRisks: item.rateFacProCode + ' : ' + displayText(item.rateFacProNameTH, item.rateFacProNameEN),
            controlMethod: displayText(item.rateRiskConTH, item.rateRiskConEN),
            issuesRemarks: displayText(item.issueTH, item.issueEN),
            personResponsible: displayText(item.personnelsNameTH, item.personnelsNameEN),
            isActive: <Status text={item.isActive === '1' ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => {
                        state.issueId = item.issueId
                        state.type_actionSub = 'view'
                        navigate('/auditoperations/auditresult/form/issue', {
                          state: state,
                        })
                      },
                    },
                    {
                      type: 'edit',
                      disabled: state.type_action === 'view' ? true : false,
                      onClick: () => {
                        state.issueId = item.issueId
                        state.type_actionSub = 'edit'
                        navigate('/auditoperations/auditresult/form/issue', {
                          state: state,
                        })
                      },
                    },
                    {
                      type: 'delete',
                      disabled: state.type_action === 'view' ? true : false,
                      onClick: async () => {
                        fnConfirm(item.issueId, state?.refCode)
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (errorPagination) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorPagination)),
        onOk() {},
      })
    }
  }, [dataPagination])

  const fnConfirm = (issueId, projectCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        onSubmit(issueId, projectCode)
      },
      onCancel() {},
    })
  }

  const onSubmit = async (issueId, projectCode) => {
    let variables = encryptInput({ issueId: issueId, projectCode: projectCode })
    cellDelete({ variables })
  }

  const columnsQuestion = [
    {
      title: t('ปัจจัยความเสี่ยง'),
      dataIndex: 'factorsRisks',
      width: '20%',
      align: 'left',
      // sorter: true,
      render: (item, record) => {
        if (record.show) {
          return <div>{record.factorsRisks}</div>
        } else {
          return <></>
        }
      },
    },
    {
      title: t('วิธีการควบคุม'),
      dataIndex: 'controlMethod',
      width: '30%',
      align: 'left',
      // sorter: true,
      render: (item, record) => {
        if (record.show2) {
          return <div>{record.controlMethod}</div>
        } else {
          return <></>
        }
      },
    },
    {
      title: t('ข้อสังเกต'),
      dataIndex: 'issuesRemarks',
      width: '15%',
      align: 'left',
      // sorter: true,
    },
    { title: t('ผู้รับผิดชอบประเด็น'), dataIndex: 'personResponsible', width: '15%', align: 'left' },
    {
      title: t('สถานะ'),
      dataIndex: 'isActive',
      width: '10%',
      align: 'center',
      // sorter: true,
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '10%',
    },
  ]

  return (
    <>
      {(loadingPagination || loadingDelete) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Datatable
            columns={columnsQuestion}
            data={dataLists}
            enableScroll={{ x: true, y: true }}
            searchCustom={true}
            total={count}
            pageSize={limit}
            page={page}
            paginationCustom={true}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            handleTableChange={(e) => {
              const reNewField = [
                {
                  factorsRisks: localStorage.getItem('lang') === 'th' ? 'rateFacProNameTH' : 'rateFacProNameEN',
                  controlMethod: localStorage.getItem('lang') === 'th' ? 'rateRiskConTH' : 'rateRiskConEN',
                  issuesRemarks: localStorage.getItem('lang') === 'th' ? 'issueTH' : 'issueEN',
                },
              ]
              setSort(handleSort(e?.sorter, reNewField))
            }}
            btnAdd={
              <>
                {state.type_action === 'add' || state.type_action === 'edit' ? (
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    style={{ marginRight: '12px' }}
                    onClick={() => {
                      delete state.issueId
                      state.type_actionSub = 'add'
                      navigate('/auditoperations/auditresult/form/issue', {
                        state: { ...state },
                      })
                    }}
                  >
                    {t('บันทึกผลการตรวจสอบ')}
                  </ButtonNew>
                ) : (
                  ''
                )}
              </>
            }
          />
        </Col>
      </Row>
    </>
  )
}

export default TabOne
