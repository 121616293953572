import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'key',
    width: '100px',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ประเภทเลขที่</Trans>,
    dataIndex: 'numberTypeCode',
    width: '150px',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>เอกสาร/งาน</Trans>,
    dataIndex: 'documentName',
    width: '200px',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>หน่วยงาน</Trans>,
    dataIndex: 'organizationNameTH',
    width: '250px',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>เมนู</Trans>,
    dataIndex: 'menuNameTH',
    width: '250px',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>วันที่เริ่มต้น-สิ้นสุด</Trans>,
    dataIndex: 'startEnd',
    width: '250px',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>รูปแบบเลขที่</Trans>,
    dataIndex: 'formatDetail',
    width: '150px',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>เลขที่ล่าสุด</Trans>,
    dataIndex: 'lastNumber',
    width: '100px',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActive',
    width: '150px',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '200px',
    className: 'text-nowrap',
  },
]
