import React from 'react'
import { Trans } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
// import PersonnelCreate from './PersonnelCreate'
import PersonnelList from './PersonnelList'

/**
 * @function PersonelIndex
 * @description
 * Menu: Personel
 * เมนู: จัดการข้อมูลบุคลากร
 * @returns Component
 */
const arrCrumb = [
  { label: <Trans>จัดการข้อมูลพื้นฐาน</Trans>, path: '/administrator/personnel' },
  { label: <Trans>จัดการข้อมูลบุคลากร</Trans> },
]
function PersonnelIndex(props) {
  return (
    <React.Fragment>
      <Breadcrumb data={arrCrumb} title={<Trans>จัดการข้อมูลบุคลากร</Trans>} />
      <PersonnelList menu={props.menu} />
    </React.Fragment>
  )
}

export default PersonnelIndex
