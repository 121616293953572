import { Trans } from 'react-i18next'

export const racMatrixListCrumb = [
  { label: <Trans>งานปฎิบัติการตรวจสอบ</Trans>, path: '/auditoperations/racmatrix' },
  { label: <Trans>ประเมินความเสี่ยงและการควบคุม</Trans> },
]

export const racMatrixCreateCrumb = [
  { label: <Trans>งานปฎิบัติการตรวจสอบ</Trans>, path: '/auditoperations/racmatrix' },
  { label: <Trans>ประเมินความเสี่ยงและการควบคุม</Trans>, path: '/auditoperations/racmatrix' },
  { label: <Trans>เพิ่มการประเมินความเสี่ยงและการควบคุม</Trans> },
]

export const racMatrixEditrumb = [
  { label: <Trans>งานปฎิบัติการตรวจสอบ</Trans>, path: '/auditoperations/racmatrix' },
  { label: <Trans>ประเมินความเสี่ยงและการควบคุม</Trans>, path: '/auditoperations/racmatrix' },
  { label: <Trans>แก้ไขการประเมินความเสี่ยงและการควบคุม</Trans> },
]
