import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Row, Col, Tooltip } from 'antd'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { ButtonNew } from '../../../components/button/Button'
import { CalendarNew } from '../../../components/calendar/Calendar'
import { Alert } from '../../../components/alert/Alert'
import moment from 'moment'
import * as _ from 'lodash'
import { GET_ADHOCJOB_CALENDAR } from './graphql/Query'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText, displayError } from '../../../utilitys/helper'

const AdhocjobCalendar = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()

  if (!state) {
    return <Navigate to={'/auditoperations/adhocjob'} />
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/adhocjob' },
    { label: t('เพิ่มงานมอบหมาย'), path: '/auditoperations/adhocjob' },
    { label: t('เลือกผู้รับผิดชอบ') },
  ]

  const Responsive = {
    md: 24,
    lg: 24,
  }

  const [eventList, setEventList] = useState([])
  const [calendarOfYear, setCalendarOfYear] = useState([])

  /* ---------------- API ----------- */
  const [getAdhocJobCalendarFn, dataAdhocJobCalendar] = useLazyQuery(GET_ADHOCJOB_CALENDAR)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    getAdhocJobCalendarFn({
      variables: {
        personnelCode: encryptInput(state.personnelCode),
      },
    })
  }, [])

  useEffect(() => {
    if (dataAdhocJobCalendar.data) {
      const response = dataAdhocJobCalendar.data.getAdhocJobCalendarPersonnel

      setEventList(
        _.map(response?.event, (item) => {
          return {
            ...item,
            title: displayText(item.titleTH, item.titleEN),
            custom:
              item.type === 'sub' ? (
                <Tooltip
                  placement="topLeft"
                  title={
                    <span style={{ whiteSpace: 'pre-line' }}>
                      {item.reference?.refCode + ': ' + displayText(item.reference?.titleTH, item.reference?.titleEN)}
                      {'\n' + displayText(item.titleTH, item.titleEN)}
                    </span>
                  }
                >
                  {displayText(item.titleTH, item.titleEN)}
                </Tooltip>
              ) : (
                <span>{displayText(item.titleTH, item.titleEN)}</span>
              ),
          }
        }),
      )

      const calendarOfYear = response.calendarOfYear.map((item) => item.start)
      setCalendarOfYear(calendarOfYear)
    }

    if (dataAdhocJobCalendar.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataAdhocJobCalendar.error)),
        onOk() {},
      })
    }
  }, [dataAdhocJobCalendar.data])

  const ColoredDateCellWrapper = ({ children, value }) =>
    React.cloneElement(React.Children.only(children), {
      style: {
        ...children.style,
        backgroundColor: calendarOfYear.includes(moment(value).format('YYYY-MM-DD')) && '#FAA0A0',
      },
    })

  const eventPropGetter = (event) => {
    let styles = { backgroundColor: '', color: '' }

    switch (event.stepGroup) {
      case '1':
        /* 'งานตรวจสอบ' */
        styles = {
          backgroundColor: event.type === 'main' ? '#1d76bb' : '#76A3C6',
          color: event.type === 'main' ? '#fff' : '#000',
        }
        break
      case '2':
        /* งานมอบหมาย */
        styles = {
          backgroundColor: event.type === 'main' ? '#45A648' : '#A3E892',
          color: event.type === 'main' ? '#fff' : '#000',
        }
        break
      case 'Holiday':
        /* วันหยุดประจำปี */
        styles = {
          backgroundColor: '#f66767',
          color: '#000',
        }
        break
      default:
        styles = { backgroundColor: '#F0F0F0', color: '#000' }
        break
    }

    return { style: styles }
  }

  return (
    <>
      <Breadcrumb data={breadcrumbList} title={t('ผู้รับผิดชอบ')} />
      <CardNew
        topic={t('ปฏิทินการทำงาน') + ': ' + state.personnelCode + ' ' + state.personnelName}
        icon="List"
        toggledrop="false"
      >
        <Row gutter={[12, 20]} justify="center">
          <Col {...Responsive}>
            <CalendarNew
              eventList={eventList}
              eventPropGetter={(event) => eventPropGetter(event)}
              dateCellWrapperCustom={ColoredDateCellWrapper}
            />
          </Col>
          <Col>
            <ButtonNew
              type="back"
              onClick={() =>
                navigate(-1, {
                  state: state,
                })
              }
            >
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default AdhocjobCalendar
