import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Form, Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import styled from 'styled-components'
import { validateUserId, validateEmail } from '../../../utilitys/validationAntd'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { ButtonNew } from '../../../components/button/Button'
import { InputNew } from '../../../components/input/Input'
import { LabelNew } from '../../../components/label/Label'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import SelectUserType from '../../../components/inputfromapi/selectusertype/SelectUserType'
import SelectPersonnels from '../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import SelectRoles from '../../../components/inputfromapi/selectroles/SelectRoles'
import { GET_SYSTEM_USERS, GET_USER_LDAP } from './graphql/Query'
import { CREATE_SYSTEM_USER, UPDATE_UNLOCK_USER } from './graphql/Mutation'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { Alert } from '../../../components/alert/Alert'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
import { generatePassword, displayError } from '../../../utilitys/helper'

const Responesive = { md: 24, lg: 24 }
const IconDispaly = styled.div`
  .display-none-icon
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .sc-fotOHu
    .ant-input-suffix {
    display: none;
  }
`

const UsersForm = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()
  const [typeAction, setTypeAction] = useState(false)
  const [form] = Form.useForm()
  const [active, setActive] = useState(false)
  const [active2, setActive2] = useState(false)
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [isPersonnelCode, setIsPersonnelCode] = useState(null)
  const [isUserLock, setIsUserLock] = useState(null)
  const [isLangTH, setIsLangTH] = useState(false)

  if (!state) {
    return <Navigate to={'/administrator/users'} />
  }

  const [getSystemUsers, { data: dataSystemUsers, loading: loadingSystemUsers, error: errorSystemUsers }] =
    useLazyQuery(GET_SYSTEM_USERS, { variables: { userId: state.refCode } })

  const [getUserLdapFn, { data: userLdap, error: errorUserLdap, loading: loadingUserLdap }] =
    useLazyQuery(GET_USER_LDAP)

  const [
    systemUserCreate,
    { data: dataSystemUserCreate, loading: loadingSystemUserCreate, error: errorSystemUserCreate },
  ] = useMutation(CREATE_SYSTEM_USER)

  const [
    systemUserUnlockUser,
    { data: dataSystemUserUnlockUser, loading: loadingSystemUserUnlockUser, error: errorSystemUserUnlockUser },
  ] = useMutation(UPDATE_UNLOCK_USER)

  useEffect(() => {
    const lang = localStorage.getItem('lang')

    if (lang === 'th') setIsLangTH(lang)
  }, [localStorage.getItem('lang')])

  useEffect(() => {
    if (dataSystemUserUnlockUser) {
      Alert({
        type: 'success',
        title: t('ปลดล๊อคการเข้าถึงสำเร็จ'),
        onOk() {
          navigate(-1)
        },
      })
    }
    if (errorSystemUserUnlockUser) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถปลดล๊อคการเข้าถึง'),
        content: t(displayError(errorSystemUserUnlockUser)),
        onOk() {},
      })
    }
  }, [dataSystemUserUnlockUser])

  useEffect(() => {
    if (dataSystemUserCreate) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          navigate(-1)
        },
      })
    }
    if (errorSystemUserCreate) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSystemUserCreate)),
        onOk() {},
      })
    }
  }, [dataSystemUserCreate])

  useEffect(() => {
    if (state.type_action) {
      if (state.type_action === 'edit' || state.type_action === 'view') {
        getSystemUsers()
        if (state.type_action === 'view') {
          setTypeAction(true)
        }
      }
    }
  }, [])

  useEffect(() => {
    if (isPersonnelCode) {
      getUserLdapFn({
        variables: { input: encryptInput({ initials: isPersonnelCode }) },
        fetchPolicy: 'no-cache',
      })
    }
  }, [isPersonnelCode])

  useEffect(() => {
    if (userLdap?.getUserLdap) {
      const user = userLdap?.getUserLdap?.users[0]
      form.setFieldsValue({ user: user?.sAMAccountName ? user?.sAMAccountName : null })
    }
    if (errorUserLdap) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(errorUserLdap)),
        onOk() {},
      })
    }
  }, [userLdap])

  useEffect(() => {
    if (dataSystemUsers?.systemUsersGet) {
      const result = dataSystemUsers.systemUsersGet

      if (result?.userType === 1) setActive(true)

      form.setFieldsValue({
        userType: result?.userType,
        personnel: result?.personnelCode,
        organizationTh: result?.organizationNameTH,
        organizationEn: result?.organizationNameEN,
        organizationCode: result?.organizationCode,
        firstName: result?.firstname,
        lastName: result?.lastname,
        firstNameEN: result?.firstnameEN ? result?.firstnameEN : result?.firstname,
        lastNameEN: result?.lastnameEN ? result?.lastnameEN : result?.lastname,
        user: result?.username,
        email: result?.email,
        dateStart: result?.startDate,
        dateEnd: result?.expireDate,
        isActive: result?.isActive + '',
        remark: result?.remark,
        password: '0123456789',
        confirmPassword: '0123456789',
        rightsGroup: result?.systemUserRoles.map((item) => item.roleCode),
      })
      setIsUserLock(dataSystemUsers?.systemUsersGet?.userLock)
      setActive2(result.isActive ? false : true)
    }

    if (errorSystemUsers) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(errorSystemUsers)),
        onOk() {},
      })
    }
  }, [dataSystemUsers])

  // onFinish
  const onFinish = (values) => fnConfirm(values)

  const onSubmit = async (values) => {
    let pass = generatePassword()
    let dataInput = {
      userId2: state.refCode || '',
      email: values.email,
      username: values.user,
      password: state.type_action === 'create' ? pass : '',
      firstname: values.firstName,
      lastname: values.lastName || '',
      firstnameEN: values.firstNameEN ? values.firstNameEN : values.firstName,
      lastnameEN: values.lastNameEN ? values.lastNameEN : values.lastName,
      userType: values.userType,
      personnelCode: values.personnel,
      organizationCode: values.organizationCode,
      organizationNameTH: values.organizationTh,
      organizationNameEN: values.organizationEn,
      startDate: values.userType === 1 ? null : values.dateStart,
      expireDate: values.userType === 1 ? null : values.dateEnd,
      remark: values.remark || '',
      isActive: values.isActive,
      rightsGroup: values.rightsGroup,
      typeAction: state.type_action,
    }

    await systemUserCreate({
      variables: { input: encryptInput(dataInput) },
      fetchPolicy: 'no-cache',
    })
  }
  // onFinishFailed
  const onFinishFailed = () => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t('กรุณากรอกข้อมูลให้ครบถ้วน'),
      onOk() {},
    })
  }

  const fnConfirm = (values) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        onSubmit(values)
      },
      onCancel() {},
    })
  }

  const breadcrumbList = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '#' },
    { label: t('จัดการข้อมูลผู้ใช้งาน'), path: '/administrator/permissions' },
    {
      label:
        state.type_action === 'create'
          ? t('เพิ่มข้อมูลผู้ใช้งาน')
          : state.type_action === 'edit'
          ? t('แก้ไขข้อมูลผู้ใช้งาน')
          : t('ดูข้อมูลผู้ใช้งาน'),
    },
  ]

  const onClickUnlockUser = () => {
    const dataInput = { userId2: state.refCode || '' }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันปลดล๊อค ?'),
      onOk() {
        systemUserUnlockUser({ variables: { input: encryptInput(dataInput) }, fetchPolicy: 'no-cache' })
      },
      onCancel() {},
    })
  }

  return (
    <>
      {(loadingSystemUserCreate || loadingSystemUsers || loadingUserLdap || loadingSystemUserUnlockUser) && (
        <SpinnersNew />
      )}
      <IconDispaly>
        <Breadcrumb
          data={breadcrumbList}
          title={
            state.type_action === 'create'
              ? t('เพิ่มข้อมูลผู้ใช้งาน')
              : state.type_action === 'edit'
              ? t('แก้ไขข้อมูลผู้ใช้งาน')
              : t('ดูข้อมูลผู้ใช้งาน')
          }
        />
        <CardNew
          topic={
            state.type_action === 'create'
              ? t('เพิ่มข้อมูลผู้ใช้งาน')
              : state.type_action === 'edit'
              ? t('แก้ไขข้อมูลผู้ใช้งาน')
              : t('ดูข้อมูลผู้ใช้งาน')
          }
          icon={state.type_action === 'create' ? `Plus` : state.type_action === 'edit' ? 'Edit' : 'View'}
          toggledrop={'false'}
        >
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            labelCol={{ md: 8, lg: 8 }}
            wrapperCol={{ md: 24, lg: 14, align: 'left' }}
            layout="horizontal"
            name="user_form"
          >
            <Row style={{ marginBottom: 20 }} justify="start">
              <Col md={2} lg={2}></Col>
              <Col md={22} lg={22}>
                <LabelNew type="sub-header-primary">{t('ข้อมูลผู้ใช้งาน')}</LabelNew>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} justify="center">
              <Col md={24} lg={14}>
                <Form.Item
                  name="userType"
                  label={<LabelNew type="tab-header-inactive">{t('ประเภทผู้ใช้งาน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุประเภทผู้ใช้งาน') }]}
                >
                  <SelectUserType
                    disabled={typeAction}
                    formname="user_form"
                    placeholder={t('เลือกประเภทผู้ใช้งาน')}
                    handleChange={async (e) => {
                      setActive(e?.value === 1 ? true : false)
                      form.setFieldsValue({
                        firstName: null,
                        lastName: null,
                        firstNameEN: null,
                        lastNameEN: null,
                        organizationTh: null,
                        organizationEn: null,
                        organizationCode: null,
                        personnel: null,
                        email: null,
                        user: null,
                        userType: e?.value,
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="personnel"
                  label={<LabelNew type="tab-header-inactive">{t('บุคลากร')}</LabelNew>}
                  rules={[{ required: active, message: t('กรุณาระบุบุคลากร') }]}
                >
                  <SelectPersonnels
                    disabled={state.type_action === 'view' ? typeAction : !active}
                    placeholder={t('ระบุบุคลากร')}
                    formname="user_form"
                    displayCode
                    handleChange={(e) => {
                      setIsPersonnelCode(e?.source?.personnelCode)
                      form.setFieldsValue({
                        organizationTh: e?.source?.organizationNameTH ? e.source.organizationNameTH : '',
                        organizationEn: e?.source?.organizationNameEN ? e.source.organizationNameEN : '',
                        organizationCode: e?.source?.organizationCode ? e.source.organizationCode : '',
                        personnel: e?.value ? e.value : '',
                        firstName: e?.source?.firstNameTH,
                        lastName: e?.source?.lastNameTH,
                        firstNameEN: e?.source?.firstNameEN ? e?.source?.firstNameEN : e?.source?.firstNameTH,

                        lastNameEN: e?.source?.lastNameEN ? e?.source?.lastNameEN : e?.source?.lastNameTH,

                        email: e?.source?.email ? decryptData(e?.source?.email) : '',
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item name="organizationCode" hidden>
                  <InputNew />
                </Form.Item>
                <Form.Item
                  name="organizationTh"
                  label={<LabelNew type="tab-header-inactive">{t('หน่วยงาน')}</LabelNew>}
                  rules={[{ required: !active, message: t('กรุณาระบุหน่วยงาน') }]}
                  hidden={isLangTH ? false : true}
                >
                  <InputNew
                    disabled={active}
                    maxLength={100}
                    placeholder={t('ระบุหน่วยงาน')}
                    onChange={(e) => form.setFieldsValue({ organizationTh: e.target.value })}
                  />
                </Form.Item>
                <Form.Item
                  name="organizationEn"
                  label={<LabelNew type="tab-header-inactive">{t('หน่วยงาน')}</LabelNew>}
                  rules={[{ required: !active, message: t('กรุณาระบุหน่วยงาน') }]}
                  hidden={!isLangTH ? false : true}
                >
                  <InputNew
                    disabled={active}
                    maxLength={100}
                    placeholder={t('ระบุหน่วยงาน')}
                    onChange={(e) => form.setFieldsValue({ organizationEn: e.target.value })}
                  />
                </Form.Item>
                <Form.Item
                  name="firstName"
                  label={<LabelNew type="tab-header-inactive">{t('ชื่อ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุชื่อ') }]}
                  hidden={isLangTH ? false : true}
                >
                  <InputNew maxLength={100} disabled={typeAction} placeholder={t('ระบุชื่อ')} />
                </Form.Item>
                <Form.Item
                  name="lastName"
                  label={<LabelNew type="tab-header-inactive">{t('นามสกุล')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุนามสกุล') }]}
                  hidden={isLangTH ? false : true}
                >
                  <InputNew maxLength={100} disabled={typeAction} placeholder={t('ระบุนามสกุล')} />
                </Form.Item>
                <Form.Item
                  name="firstNameEN"
                  label={<LabelNew type="tab-header-inactive">{t('ชื่อ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุชื่อ') }]}
                  hidden={!isLangTH ? false : true}
                >
                  <InputNew maxLength={100} disabled={typeAction} placeholder={t('ระบุชื่อ')} />
                </Form.Item>
                <Form.Item
                  name="lastNameEN"
                  label={<LabelNew type="tab-header-inactive">{t('นามสกุล')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุนามสกุล') }]}
                  hidden={!isLangTH ? false : true}
                >
                  <InputNew maxLength={100} disabled={typeAction} placeholder={t('ระบุนามสกุล')} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: 20 }} justify="start">
              <Col md={2} lg={2}></Col>
              <Col md={22} lg={22}>
                <LabelNew type="sub-header-primary">{t('ข้อมูลบัญชีผู้ใช้')}</LabelNew>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} justify="center">
              <Col md={24} lg={14}>
                <Form.Item
                  name="user"
                  label={<LabelNew type="tab-header-inactive">{t('ชื่อผู้ใช้งาน')}</LabelNew>}
                  rules={[...validateUserId(true, t('กรุณาระบุชื่อผู้ใช้งาน'))]}
                >
                  <InputNew
                    maxLength={25}
                    disabled={typeAction || state.type_action === 'edit' ? true : false}
                    placeholder={t('ระบุชื่อผู้ใช้งาน')}
                  />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={<LabelNew type="tab-header-inactive">{t('อีเมล')}</LabelNew>}
                  rules={[...validateEmail(true, t('กรุณาระบุอีเมล'))]}
                >
                  <InputNew maxLength={255} disabled={true} placeholder={t('ระบุอีเมล')} />
                </Form.Item>

                <Form.Item
                  name="dateStart"
                  label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้นใช้งาน')}</LabelNew>}
                  // rules={!active ? [{ required: true, message: t('กรุณาระบุวันที่เริ่มต้นใช้งาน') }] : []}
                >
                  <Datepicker
                    disabled
                    // disabled={state.type_action === 'view' ? true : active ? true : false}
                    setValueDateFn={async (data) => {
                      await setIsMinDate(data)
                      await form.setFieldsValue({ dateStart: await data })
                    }}
                    isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                    selectsStart={true}
                    onClear={() => {
                      form.setFieldsValue({ dateStart: null })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="dateEnd"
                  label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุดใช้งาน')}</LabelNew>}
                  // rules={!active ? [{ required: true, message: t('กรุณาระบุวันที่สิ้นสุดใช้งาน') }] : []}
                >
                  <Datepicker
                    disabled
                    // disabled={state.type_action === 'view' ? true : active ? true : false}
                    setValueDateFn={async (data) => {
                      await setIsMaxDate(data)
                      await form.setFieldsValue({ dateEnd: await data })
                    }}
                    isMinDate={isMinDate ? new Date(isMinDate) : null}
                    selectsEnd={true}
                    onClear={() => form.setFieldsValue({ dateEnd: null })}
                  />
                </Form.Item>
                <Form.Item
                  label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                  name="isActive"
                  initialValue={'1'}
                  onChange={(e) => setActive2(e.target.value === '1' ? false : true)}
                >
                  <RadioGroup>
                    <Space size={[8, 8]} wrap>
                      <Radio disabled={typeAction} value="1">
                        <LabelNew type="tab-header-inactive">{t('ใช้งาน')}</LabelNew>
                      </Radio>
                      &nbsp;
                      <Radio disabled={typeAction} value="0">
                        <LabelNew type="tab-header-inactive">{t('ไม่ใช้งาน')}</LabelNew>
                      </Radio>
                    </Space>
                  </RadioGroup>
                </Form.Item>
                <Form.Item
                  name="remark"
                  label={<LabelNew type="tab-header-inactive">{t('แจ้งเหตุผลไม่ใช้งาน')}</LabelNew>}
                  rules={[{ required: active2, message: t('กรุณาระบุแจ้งเหตุผลไม่ใช้งาน') }]}
                >
                  <InputNew maxLength={255} disabled={typeAction} placeholder={t('ระบุแจ้งเหตุผลไม่ใช้งาน')} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: 20 }} justify="start">
              <Col md={2} lg={2}></Col>
              <Col md={22} lg={22}>
                <LabelNew type="sub-header-primary">{t('รายการกลุ่มสิทธิ์')}</LabelNew>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} justify="center">
              <Col md={24} lg={14}>
                <Form.Item
                  name="rightsGroup"
                  rules={[{ required: true, message: t('กรุณาระบุกลุ่มสิทธิ์') }]}
                  label={<LabelNew type="tab-header-inactive">{t('กลุ่มสิทธิ์')}</LabelNew>}
                >
                  <SelectRoles
                    disabled={typeAction}
                    placeholder={t('ระบุกลุ่มสิทธิ์')}
                    formname="user_form"
                    handleChange={(e) => form.setFieldsValue({ rightsGroup: e })}
                  />
                </Form.Item>
              </Col>
              {state.type_action === 'edit' && (
                <Col md={24} lg={14}>
                  <Form.Item
                    name="userLock"
                    label={<LabelNew type="tab-header-inactive">{t('ปลดล๊อคการเข้าใช้งาน')}</LabelNew>}
                  >
                    <ButtonNew
                      style={{ minHeight: '37px' }}
                      type="primary"
                      roles={{ type: 'add', menu: menu }}
                      action={state.type_action === 'create' ? 'add' : state.type_action}
                      disabled={
                        state?.type_action === 'create' || state?.type_action === 'view'
                          ? true
                          : isUserLock === null
                          ? true
                          : false
                      }
                      onClick={() => onClickUnlockUser()}
                    >
                      {t('ปลดล๊อค')}
                    </ButtonNew>
                  </Form.Item>
                </Col>
              )}
            </Row>

            {/* ---------- */}
            <Row gutter={[16, 16]}>
              <Col {...Responesive} align="center">
                <Space size={[8, 8]} wrap>
                  {state.type_action !== 'view' ? (
                    <>
                      <ButtonNew
                        type="primary"
                        roles={{ type: state.type_action === 'create' ? 'add' : state.type_action, menu: menu }}
                        htmlType="submit"
                      >
                        {t('บันทึก')}
                      </ButtonNew>
                    </>
                  ) : (
                    ''
                  )}
                  &nbsp;
                  <ButtonNew type="back" onClick={() => navigate(-1)}>
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Space>
              </Col>
            </Row>
          </Form>
        </CardNew>
      </IconDispaly>
    </>
  )
}

export default UsersForm
