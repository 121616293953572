import React, { useEffect, useState } from 'react'
import { Col, Form, Input, Row, Space } from 'antd'
import * as _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { LabelNew as Label, LabelNew } from '../../../components/label/Label'
import SelectOrganizationLevel from '../../../components/inputfromapi/selectorganizationlevel/SelectOrganizationLevel'
import SelectOrganizationSize from '../../../components/inputfromapi/selectorganizationsize/SelectOrganizationSize'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectOrganizationChecked from '../../../components/inputfromapi/selectorganizationchecked/SelectOrganizationChecked'
import SelectOrganizationType from '../../../components/inputfromapi/selectorganizationtype/SelectOrganizationType'
import { InputNew } from '../../../components/input/Input'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
import { CardNew } from '../../../components/card/Card'
import { ButtonNew } from '../../../components/button/Button'
import { GET_ORGANIZATION_BY_CODE } from './graphql/Query'
import { useLazyQuery, useMutation } from '@apollo/client'
import { encryptInput } from '../../../utilitys/crypto'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Datatable } from '../../../components/datatable/Datatable'
import { Columns } from './utils/OrganizationLogColumns'
import { dateTimeDisplay } from '../../../utilitys/dateHelper'
import { displayError } from '../../../utilitys/helper'
import ChangeNameModal from './component/modal/ChangeNameModal'
import { UPDATE_ORGANIZATION } from './graphql/Mutation'
import { Alert } from '../../../components/alert/Alert'
import DetailModal from './component/modal/DetailModal'
import { IconNew } from '../../../components/icon/Icon'

/**
 * @function OrganizationEdit
 * @description
 * Menu: Organization
 * เมนู: การจัดการข้อมูลหน่วยงาน
 * @returns Component
 */

const OrganizationEdit = (props) => {
  const formname = 'OrganizationEditForm'
  const [form] = Form.useForm()
  const { state } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { menu } = props
  const labelCol = { sm: 8, md: 8, lg: { span: 6, offset: 2 }, xl: { span: 6, offset: 2 } }
  const wrapperCol = { sm: 16, md: 16, lg: 10, xl: 10, align: 'left' }
  const Responsive = {
    md: 24,
    lg: 24,
  }
  const organizationEditCrumb = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/organization' },
    { label: t('การจัดการข้อมูลหน่วยงาน'), path: '/administrator/organization' },
    { label: t('แก้ไขหน่วยงาน') },
  ]
  const [stateDataLogs, setStateDataLogs] = useState([])
  const [stateDetail, setStateDetail] = useState()
  const [stateDataView, setStateDataView] = useState()
  const [visible, setVisible] = useState(false)
  const [visibleView, setVisibleView] = useState(false)
  const [stateDisabled, setStateDisabled] = useState(true)
  const [stateOrgLevel, setStateOrgLevel] = useState()

  if (!state && state?.organizationCode) return <Navigate to={'/administrator/organization'} />

  const [getOrganizationByCodeFn, dataOrganizationByCode] = useLazyQuery(GET_ORGANIZATION_BY_CODE)
  const [
    updateOrganizationFn,
    { loading: loadingUpdateOrganization, error: errorUpdateOrganization, data: dataUpdateOrganization },
  ] = useMutation(UPDATE_ORGANIZATION)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (state) getOrganizationByCodeFn({ variables: { organizationCode: encryptInput(state?.organizationCode) } })
  }, [state])

  useEffect(() => {
    if (dataUpdateOrganization) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/administrator/organization')
        },
      })
    }
    if (errorUpdateOrganization) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorUpdateOrganization)),
        onOk() {},
      })
    }
  }, [dataUpdateOrganization])

  useEffect(() => {
    if (dataOrganizationByCode?.data) {
      const listOrganization = dataOrganizationByCode?.data?.getOrganizationByCode?.result
      const listOrganizationLog = dataOrganizationByCode?.data?.getOrganizationByCode?.organizationLogs

      form.setFieldsValue({
        organizationCode: listOrganization.organizationCode,
        organizationNameTH: listOrganization.organizationNameTH,
        organizationNameEN: listOrganization.organizationNameEN,
        organizationLevelCode: listOrganization.organizationLevelCode,
        organizationParentCode: listOrganization.organizationParentCode,
        organizationSizeCode: listOrganization.organizationSizeCode,
        organizationChecked: listOrganization.organizationChecked,
        organizationType: listOrganization.organizationType,
        isActive: listOrganization.isActive,
      })
      listOrganization.organizationType === 2 ? setStateDisabled(true) : setStateDisabled(false)

      setStateDetail({
        organizationCode: listOrganization.organizationCode,
        organizationNameTH: listOrganization.organizationNameTH,
        organizationNameEN: listOrganization.organizationNameEN,
        type_action: 'edit',
      })

      setStateDataLogs(
        _.map(listOrganizationLog, (data, index) => {
          return {
            ...data,
            key: index + 1,
            changeType: data?.changeType === 1 ? 'เพิ่ม' : 'แก้ไข',
            createdAt: dateTimeDisplay(data?.createdAt),
            tools: (
              <div style={{ textAlign: 'center', cursor: 'pointer' }}>
                <IconNew
                  icon={'FileText'}
                  color=""
                  onClick={() => {
                    setVisibleView(true), setStateDataView({ logfileOrgId: data.logfileOrgId, type_action: 'view' })
                  }}
                />
              </div>
            ),
          }
        }),
      )
    }

    if (dataOrganizationByCode?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(dataOrganizationByCode?.error)),
        onOk() {},
      })
    }
  }, [dataOrganizationByCode?.data])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    showConfirm(input)
  }
  const showConfirm = (input) => {
    delete input.organizationNameTH
    delete input.organizationNameEN
    let dataInput = {
      ...input,
      organizationChecked: parseInt(input.organizationChecked),
      isActive: parseInt(input.isActive),
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        updateOrganizationFn({
          variables: {
            input: encryptInput(dataInput),
          },
        })
        // form.resetFields()
      },
      onCancel() {},
    })
  }

  const onInputValueChange = (e) => {
    if (e.value === 2) {
      setStateDisabled(true)
      form.setFieldsValue({
        organizationParentCode: null,
        organizationLevelCode: null,
        organizationSizeCode: null,
        organizationChecked: null,
      })
    } else {
      setStateDisabled(false)
    }
  }

  return (
    <>
      {dataOrganizationByCode.loading || (loadingUpdateOrganization && <SpinnersNew />)}
      <Col {...Responsive}>
        <Breadcrumb data={organizationEditCrumb} title={t('การจัดการข้อมูลหน่วยงาน')} />
        <CardNew topic={t('แก้ไขหน่วยงาน')} icon="AlignJustify" toggledrop={'false'}>
          <Row gutter={[12, 12]}>
            <Col {...Responsive}>
              <Form
                form={form}
                onFinish={onFinish}
                autoComplete="off"
                labelAlign="left"
                labelCol={{ ...labelCol }}
                wrapperCol={{ ...wrapperCol }}
                layout="horizontal"
                name={formname}
              >
                <LabelNew type="body-header">{t('รายละเอียดทั่วไป')}</LabelNew>
                <Form.Item
                  name="organizationCode"
                  label={<Label type="tab-header-inactive">{t('รหัสหน่วยงาน')}</Label>}
                >
                  <InputNew placeholder={t('รหัสหน่วยงาน')} disabled={true} />
                </Form.Item>
                <Form.Item label={<Label type="tab-header-inactive">{t('ชื่อหน่วยงานภาษาไทย')}</Label>}>
                  <Input.Group>
                    <Col span={24} style={{ paddingRight: 0 }}>
                      <Form.Item name="organizationNameTH" style={{ marginBottom: 0 }}>
                        <InputNew placeholder={t('ชื่อหน่วยงานภาษาไทย')} disabled={true} />
                      </Form.Item>
                    </Col>
                    {state?.type_action === 'view' ? (
                      ''
                    ) : (
                      <ButtonNew
                        style={{ minHeight: '37px', marginLeft: '5px' }}
                        type="primary"
                        roles={{ type: 'edit', menu: menu }}
                        onClick={() => {
                          setVisible(true)
                        }}
                      >
                        {t('แก้ไขชื่อหน่วยงาน')}
                      </ButtonNew>
                    )}
                  </Input.Group>
                </Form.Item>
                <Form.Item
                  name="organizationNameEN"
                  label={<Label type="tab-header-inactive">{t('ชื่อหน่วยงานภาษาอังกฤษ')}</Label>}
                >
                  <InputNew placeholder={t('ชื่อหน่วยงานภาษาอังกฤษ')} disabled={true} />
                </Form.Item>
                <Form.Item
                  name="organizationType"
                  label={<Label type="tab-header-inactive">{t('ประเภทหน่วยงาน')}</Label>}
                  rules={[{ required: true, message: 'กรุณาเลือกประเภทหน่วยงาน' }]}
                >
                  <SelectOrganizationType
                    formname="OrganizationEditForm"
                    placeholder={t('เลือกประเภทหน่วยงาน')}
                    handleChange={(e) => {
                      form.setFieldsValue({ organizationType: e?.value })
                      onInputValueChange(e)
                    }}
                    disabled={state?.type_action === 'view' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  name="organizationLevelCode"
                  label={<Label type="tab-header-inactive">{t('ระดับหน่วยงาน')}</Label>}
                  rules={[stateDisabled ? {} : { required: true, message: 'กรุณาเลือกระดับหน่วยงาน' }]}
                >
                  <SelectOrganizationLevel
                    formname="OrganizationEditForm"
                    placeholder={t('เลือกระดับหน่วยงาน')}
                    handleChange={(e) => {
                      form.setFieldsValue({ organizationLevelCode: e?.value })
                      setStateOrgLevel(e?.value)
                      form.setFieldsValue({
                        organizationParentCode: null,
                      })
                    }}
                    disabled={state?.type_action === 'view' ? true : stateDisabled}
                  />
                </Form.Item>
                <Form.Item
                  name="organizationParentCode"
                  label={<Label type="tab-header-inactive">{t('สังกัดหน่วยงาน')}</Label>}
                >
                  <SelectOrganization
                    formname="OrganizationEditForm"
                    placeholder={t('เลือกสังกัดหน่วยงาน')}
                    filters={
                      stateOrgLevel
                        ? { lessOrganizationLevelCode: parseInt(stateOrgLevel), isActive: 1 }
                        : { isActive: 1 }
                    }
                    handleChange={(e) => {
                      form.setFieldsValue({ organizationParentCode: e?.value })
                    }}
                    disabled={state?.type_action === 'view' ? true : stateDisabled}
                  />
                </Form.Item>
                <Form.Item
                  name="organizationSizeCode"
                  label={<Label type="tab-header-inactive">{t('ขนาดหน่วยงาน')}</Label>}
                >
                  <SelectOrganizationSize
                    formname="OrganizationEditForm"
                    placeholder={t('เลือกขนาดหน่วยงาน')}
                    handleChange={(e) => {
                      form.setFieldsValue({ organizationSizeCode: e?.value })
                    }}
                    disabled={state?.type_action === 'view' ? true : stateDisabled}
                  />
                </Form.Item>
                <Form.Item
                  name="organizationChecked"
                  label={<Label type="tab-header-inactive">{t('หน่วยรับตรวจ')}</Label>}
                  rules={[{ required: true, message: 'กรุณาเลือกหน่วยรับตรวจ' }]}
                >
                  <SelectOrganizationChecked
                    formname="OrganizationEditForm"
                    placeholder={t('เลือกหน่วยรับตรวจ')}
                    handleChange={(e) => {
                      form.setFieldsValue({ organizationChecked: e?.value })
                    }}
                    disabled={state?.type_action === 'view' ? true : stateDisabled}
                  />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>}
                  name="isActive"
                  initialValue={1}
                >
                  <RadioGroup>
                    <Space size={[8, 8]} wrap>
                      <Radio value={1} disabled={state?.type_action === 'view' ? true : stateDisabled}>
                        {<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}
                      </Radio>
                      &nbsp;
                      <Radio value={0} disabled={state?.type_action === 'view' ? true : stateDisabled}>
                        {<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}
                      </Radio>
                    </Space>
                  </RadioGroup>
                </Form.Item>
                <LabelNew type="body-header">{t('ประวัตการแก้ไขชื่อหน่วยงาน')}</LabelNew>
                <Col {...Responsive}>
                  <Datatable
                    columns={Columns}
                    data={[...stateDataLogs]}
                    paginationCustom={false}
                    searchCustom={true}
                    scroll={{ x: 1000, y: false }}
                  />
                </Col>
                <Col align="center" style={{ marginTop: '40px' }}>
                  <Space size={[8, 8]} wrap>
                    {state?.type_action === 'view' ? (
                      ''
                    ) : (
                      <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                        {t('บันทึก')}
                      </ButtonNew>
                    )}
                    <ButtonNew type="back" onClick={() => navigate(-1)}>
                      {t('ยกเลิก')}
                    </ButtonNew>
                  </Space>
                </Col>
              </Form>
            </Col>
          </Row>
        </CardNew>
      </Col>
      <ChangeNameModal
        open={visible}
        close={(e) => {
          setVisible(e)
          setStateDetail()
        }}
        refetch={() => dataOrganizationByCode.refetch()}
        dataParams={stateDetail}
        menu={menu}
      />
      <DetailModal
        open={visibleView}
        close={(e) => {
          setVisibleView(e)
          setStateDataView()
        }}
        dataParams={stateDataView}
        menu={menu}
      />
    </>
  )
}

export default OrganizationEdit
