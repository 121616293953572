import { gql } from '@apollo/client'

export const GET_LIST_RISK_LEVEL_FIVE_YEAR_REPORT_PAGINATION = gql`
  query GetListRiskLevelFiveYearReportPagination($input: RiskLevelFiveYearReportPaginationInput!) {
    getListRiskLevelFiveYearReportPagination(input: $input) {
      result {
        no
        year
        riskFormTH
        riskFormEN
        projectCode
        projectNameTH
        projectNameEN
        riskScore
        riskLevelCode
        riskLevelYear
        riskLevelNameTH
        riskLevelNameEN
        riskLevelYearName
      }
      count
      page
      limit
    }
  }
`

export const GET_EXPORT_RISK_LEVEL_FIVE_YEAR_REPORT = gql`
  query ExportRiskLevelFiveYearReport($input: ExportRiskLevelFiveYearReportInput!) {
    exportRiskLevelFiveYearReport(input: $input) {
      message
      fileUrl
    }
  }
`
