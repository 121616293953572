import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_SELECT_ADHOCJOB } from '../../graphql/Query'
import { Select } from '../../../../../components/select/Select.jsx'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText } from '../../../../../utilitys/helper'

const SelectAdhocJob = (props) => {
  const { filters, placeholder, formname, handleChange, getData, fieldCode, ...otherProp } = props
  const { jobTypeCode } = filters || {}

  const [isData, isSetData] = useState([])

  const [getSelectAdhocJobFn, getSelectAdhocJob] = useLazyQuery(GET_SELECT_ADHOCJOB)

  useEffect(() => {
    getSelectAdhocJobFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({}) },
    })
  }, [jobTypeCode])

  useEffect(() => {
    getData(_.find(isData, (data) => data.value === fieldCode))
  }, [fieldCode])

  useEffect(() => {
    if (getSelectAdhocJob.data) {
      isSetData(
        _.map(getSelectAdhocJob?.data?.getSelectAdhocJob, (item) => {
          return {
            label: item?.jobCode + ': ' + displayText(item?.jobSubjectTH, item?.jobSubjectEN),
            value: item?.jobCode,
            source: item,
          }
        }),
      )
    }
  }, [getSelectAdhocJob.data])
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getSelectAdhocJob.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          handleChange(_.find(isData, (data) => data.value === value))
        }}
      />
    </>
  )
}

export default SelectAdhocJob
