import { gql } from '@apollo/client'

export const GET_RISK_RESULT_BY_ID = gql`
  query getRiskResultById($input: RickResultID!) {
    getRiskResultById(input: $input) {
      riskResultId
      Year
      riskFormCode
      riskFormTH
      riskFormEN
      operationStatusCode
      organizationNameTH
      organizationNameEN
      makerBy
      makerByNameTH
      makerByNameEN
      responsibilityBy
      riskFormType
      riskResultsFactors {
        riskResultId
        resultScore
        riskResultFacId
        riskFactorCode
        riskFactorNameTH
        riskFactorNameEN
      }
      riskResultDetail {
        riskResultId
        riskFactorCode
        riskResulDetailId
        remark
        impact
        likelihood
        Score
        resultDetail
        factorActCode
        factorActNameTH
        factorActNameEN
        factorActRiskCode
        factorActRiskNameTH
        factorActRiskNameEN
      }
      riskFormulars {
        riskFactorCode
        riskFactorNameEN
        riskFactorNameTH
        riskFormulaCode
        riskFormulaNameEN
        riskFormulaNameTH
        variableCode
        variableType
        variableDetailTH
        variableDetailEN
        valueDetail
        valueVariable
        operationType
      }
    }
  }
`
export const GET_LIST_FILTER_RISK_RESULT = gql`
  query getListFilterRiskResult($input: RiskResultFilterPagenation!) {
    getListFilterRiskResult(input: $input) {
      result {
        Year
        riskResultId
        riskFormCode
        ownerNameTH
        ownerNameEN
        riskFormTH
        riskFormEN
        riskModelTH
        riskModelEN
        riskFormType
        responsibilityBy
        makerBy
        succeedAt
        organizationCode
        _operationStatusCode
        makerStatusCode
        responsibilityByNameTH
        responsibilityByNameEN
        organizationAuditNameTH
        organizationAuditNameEN
        organizationNameTH
        organizationNameEN
        operationStatusCode
        operationStatusNameTH
        operationStatusNameEN
        riskResultIdSelects {
          riskResultId
          organizationCode
          organizationNameTH
          organizationNameEN
        }
        riskResultsList {
          Year
          riskResultId
          riskFormCode
          riskFormTH
          riskFormEN
          riskModelTH
          riskModelEN
          riskFormType
          succeedAt
          responsibilityBy
          makerBy
          organizationCode
          _operationStatusCode
          makerStatusCode
          responsibilityByNameTH
          responsibilityByNameEN
          organizationAuditNameTH
          organizationAuditNameEN
          organizationNameTH
          organizationNameEN
          operationStatusCode
          operationStatusNameTH
          operationStatusNameEN
        }
      }
      limit
      page
      count
    }
  }
`
export const RICK_RESULT_EXPORT = gql`
  query riskResultExport($input: ExportsInput!) {
    riskResultExport(input: $input) {
      message
      fileUrl
    }
  }
`
