import { Link } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { Home } from 'react-feather'
import AppConfigs from '../../configs/app.config'
import SepechevronsRight from '../../assets/images/chevrons-right.png'
import { BreadcrumbStyle } from './BreadcrumbStyles'

export const BreadcrumbNew = ({ data, title }) => {
  const homeLink = AppConfigs.defaultWebPath ? AppConfigs.defaultWebPath : '#'
  const breadcrumbItem = data.map((item, key) => {
    return item.path ? (
      <Breadcrumb.Item key={key}>
        <Link to={item.path}>{item.label ? item.label : ''}</Link>
      </Breadcrumb.Item>
    ) : (
      <Breadcrumb.Item key={key}>{item.label ? item.label : ''}</Breadcrumb.Item>
    )
  })
  return (
    <BreadcrumbStyle>
      <span className="title"> {title} </span>
      <span className="pipe">|</span>
      <Breadcrumb separator={<img src={SepechevronsRight} />} className="item">
        <Breadcrumb.Item>
          <Link to={homeLink}>
            <Home size={22} className="home" />
          </Link>
        </Breadcrumb.Item>
        {breadcrumbItem}
      </Breadcrumb>
    </BreadcrumbStyle>
  )
}
