import { gql } from '@apollo/client'
export const GET_SELECT_RISK_RULES_EXAMINE = gql`
  query GetSelectRiskRulesExamine($input: SelectRateRiskRulesExamineInput!) {
    getSelectRiskRulesExamine(input: $input) {
      rateRiskRuleCode
      rateRiskRuleTH
      rateRiskRuleEN
    }
  }
`
