import React from 'react'
import { Row, Col } from 'antd'
import ProfileForm from './ProfileForm.jsx'
/**
 * @function ProfileIndex
 * @description
 * Menu: Internal Control
 * เมนู: โปรไฟล์
 * @returns Component
 */

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}

function ProfileIndex(props) {
  const { menu } = props
  return (
    <>
      <Row>
        <Col {...Responsive}>
          <ProfileForm menu={menu} type_action="view" />
        </Col>
      </Row>
    </>
  )
}

export default ProfileIndex
