import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_ACTIONPLAN_PERSONNEL } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectActionPlanPersonnel = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { projectCode } = filters || {}

  const [isData, isSetData] = useState([])

  const [getActionPlanPersonnelForAuditOpenFn, getActionPlanPersonnelForAuditOpen] =
    useLazyQuery(GET_ACTIONPLAN_PERSONNEL)

  useEffect(() => {
    getActionPlanPersonnelForAuditOpenFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({}) },
    })
  }, [projectCode])

  useEffect(() => {
    if (getActionPlanPersonnelForAuditOpen.data) {
      const data = getActionPlanPersonnelForAuditOpen?.data?.getActionPlanPersonnelForAuditOpen
      const uniqData = _.uniqBy(data, (items) => items.actPlanPersonId)

      isSetData(
        _.map(uniqData, (item) => {
          return {
            label:
              displayText(item?.namePrefixTH, item?.namePrefixEN) +
              displayText(item?.firstNameTH, item?.firstNameEN) +
              ' ' +
              displayText(item?.lastNameTH, item?.lastNameEN),
            value: item?.actPlanPersonId,
            source: item,
          }
        }),
      )
    }
  }, [getActionPlanPersonnelForAuditOpen.data])
  const setOnChange = props.multiple
    ? {
        onChange: (value) => {
          handleChange(value)
        },
      }
    : { onChange: (value) => handleChange(_.find(isData, (data) => data.value === value)) }
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getActionPlanPersonnelForAuditOpen.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...setOnChange}
      />
    </>
  )
}

export default SelectActionPlanPersonnel
