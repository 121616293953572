import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { ButtonNew } from '../../../../../components/button/Button'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { Columns } from '../../utils/RiskFormulaVariableColumns'
import { Status } from '../../../../../components/status/Status'
// import VariableModal from '../modal/VariableModal'
import { useMutation } from '@apollo/client'
import { DELETE_RISK_FORMULAR_VARIABLE } from '../../graphql/Mutation'
import { Alert } from '../../../../../components/alert/Alert'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError, displayText } from '../../../../../utilitys/helper'

const RiskFormulaVariableTab = (props) => {
  const { t } = useTranslation()
  const { menu, data, disabled, setVisible, setStateDetail, onDeleteDataRiskFormulaVariable } = props
  const Responsive = { md: 24, lg: 24 }

  const [dataLists, setDataLists] = useState([])
  const [deleteRiskFormulaVariablesFn, dataDeleteRiskFormulaVariables] = useMutation(DELETE_RISK_FORMULAR_VARIABLE)

  useEffect(() => {
    if (dataDeleteRiskFormulaVariables.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          onDeleteDataRiskFormulaVariable(true)
        },
      })
    }

    if (dataDeleteRiskFormulaVariables.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(dataDeleteRiskFormulaVariables.error)),
        onOk() {},
      })
    }
  }, [dataDeleteRiskFormulaVariables.data])

  /* ---------------- ACTION ----------- */
  const showConfirmDelete = (variableCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteRiskFormulaVariablesFn({
          variables: {
            variableCode: encryptInput(variableCode),
          },
        })
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    if (data) {
      setDataLists(
        _.map(data, (data, index) => {
          return {
            ...data,
            key: index + 1,
            variableName: data.variableName,
            variableType: data.variableType === 1 ? 'โอกาส' : data.variableType === 2 ? 'ผลกระทบ' : 'ตัวแปรอื่นๆ',
            variableDetail: displayText(data.variableDetailTH, data.variableDetailEN),
            isActive: <Status text={data.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
            tools: (
              <div style={{ textAlign: 'right' }}>
                {!disabled ? (
                  <ButtonGroup
                    menu={menu}
                    icons={[
                      {
                        type: 'view',
                        onClick: () => {
                          setVisible(true)
                          setStateDetail({ variableCode: data.variableCode, type_action: 'view' })
                        },
                      },
                    ]}
                  />
                ) : (
                  <ButtonGroup
                    menu={menu}
                    icons={[
                      {
                        type: 'view',
                        onClick: () => {
                          setVisible(true)
                          setStateDetail({ variableCode: data.variableCode, type_action: 'view' })
                        },
                      },
                      {
                        type: 'edit',
                        onClick: () => {
                          setVisible(true)
                          setStateDetail({ variableCode: data.variableCode, type_action: 'edit' })
                        },
                      },
                      {
                        type: 'delete',
                        onClick: () => showConfirmDelete(data.variableCode),
                      },
                    ]}
                  />
                )}
              </div>
            ),
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Row>
        <Col {...Responsive}>
          <Datatable
            columns={Columns}
            data={[...dataLists]}
            paginationCustom={false}
            searchCustom={true}
            scroll={{ x: 1000, y: false }}
            btnAdd={
              <>
                {disabled ? (
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    onClick={() => {
                      setVisible(true)
                      setStateDetail({ type_action: 'add' })
                    }}
                  >
                    {t('เพิ่มตัวแปร')}
                  </ButtonNew>
                ) : (
                  ''
                )}
              </>
            }
          />
        </Col>
      </Row>
    </>
  )
}
export default RiskFormulaVariableTab
