import { gql } from '@apollo/client'

export const GET_RATE_RISK_RULE = gql`
  query getRateRiskRule($input: SelectRateRiskRuleInput!) {
    getRateRiskRule(input: $input) {
      rateRiskRuleId
      rateRiskRuleTH
      rateRiskRuleCode
      rateRiskRuleEN
      rateFacProCode
      sort
    }
  }
`
