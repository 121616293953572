import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'antd'
import { useMutation } from '@apollo/client'

import { SAVE_RATE_RISK_CONTROL } from './graphql/Mutation'
import { ModalNew as Modal } from '../../../../components/modal/Modal'
import { InputNew as Input } from '../../../../components/input/Input'
import { Radio, RadioGroup } from '../../../../components/radio/Radio'
import { Alert } from '../../../../components/alert/Alert'

import { displayError, regexEngNoSpace, regexNumber } from '../../../../utilitys/helper'
import SelectControlTypes from '../../../../components/inputfromapi/selectcontroltypes/SelectControlTypes'
import { useLanguage } from '../../../../hooks/useLanguage'
import SelectControlNatures from '../../../../components/inputfromapi/selectcontrolnature/SelectControlNature'
import { TextAreaNew } from '../../../../components/textarea/TextArea'
import SelectRateRiskRule from '../../../../components/inputfromapi/selectrateriskrule/SelectRateRiskRule'
import { encryptInput } from '../../../../utilitys/crypto'
import { SpinnersNew } from '../../../../components/spinners/Spinners'

const RateRiskControlForm = ({
  visible = false,
  formType = 'view',
  dataForm = null,
  rateFacGroupCode = null,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const [form] = Form.useForm()

  const [editable, seteditable] = useState(false)

  useEffect(() => {
    if (dataForm && visible) {
      form.setFieldsValue(dataForm)
    }
  }, [dataForm, visible])

  useEffect(() => {
    if (formType) {
      if (formType === 'add') form.resetFields()
      seteditable(formType !== 'view')
    }
  }, [formType])

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_RATE_RISK_CONTROL)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorSave)),
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          onSuccess()
        },
      })
    }
  }, [dataSave])

  const onFinish = () => {
    const field = form.getFieldValue()

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        const dataInput = {
          rateRiskConCode: field.rateRiskConCode,
          rateRiskRuleCode: field.rateRiskRuleCode,
          controlTypeCode: field.controlTypeCode,
          controlNatureCode: field.controlNatureCode,
          sort: field.sort,
          isActive: field.isActive,
        }
        if (formType === 'add') {
          dataInput.rateRiskConTH = field.rateRiskConTH
          dataInput.rateRiskConEN = field.rateRiskConEN ? field.rateRiskConEN : field.rateRiskConTH
          dataInput.typeAction = 'add'
        } else {
          dataInput.rateRiskConTH = field.rateRiskConTH
          dataInput.rateRiskConEN = field.rateRiskConEN
          dataInput.typeAction = 'edit'
        }

        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const footerHide =
    formType === 'view'
      ? { cancelButtonProps: { style: { display: 'none' } }, okButtonProps: { style: { display: 'none' } } }
      : {}

  const renderTitle = () => {
    if (formType === 'add') {
      return t('เพิ่มวิธีการควบคุม')
    }
    if (formType === 'view') {
      return t('ดูวิธีการควบคุม')
    }
    if (formType === 'edit') {
      return t('แก้ไขวิธีการควบคุม')
    }

    return ''
  }

  const formName = 'control'

  return (
    <React.Fragment>
      {loadingSave && <SpinnersNew />}
      <Modal
        visible={visible}
        onSubmit={form.submit}
        onClose={(data) => {
          onClose(data)
          form.resetFields()
        }}
        testTitle={renderTitle()}
        typeAction={formType}
        type="medium"
        btnName={t('บันทึก')}
        {...footerHide}
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 8,
            lg: 8,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name={'rateRiskConCode'}
            label={t('รหัสกระบวนการควบคุม')}
            rules={[
              { required: true, message: t('กรุณาระบุ') },
              {
                pattern: regexEngNoSpace,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษพิมพ์ใหญ่,ตัวเลข A-Z, 0-9 ไม่เกิน 10 ต้ว`),
              },
            ]}
          >
            <Input disabled={formType === 'edit' || !editable} placeholder={t('กรอกรหัสกระบวนการควบคุม')} />
          </Form.Item>

          <Form.Item
            name={'rateRiskConTH'}
            label={t('ชื่อกระบวนการควบคุมภาษาไทย')}
            rules={[{ required: true, message: t('กรุณาระบุ') }]}
          >
            <TextAreaNew disabled={!editable} placeholder={t('กรอกชื่อกระบวนการควบคุม')} rows={5} />
          </Form.Item>
          <Form.Item
            name={'rateRiskConEN'}
            label={t('ชื่อกระบวนการควบคุมภาษาอังกฤษ')}
            // rules={[{ pattern: regexEng, message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`) }]}
          >
            <TextAreaNew disabled={!editable} placeholder={t('กรอกชื่อกระบวนการควบคุม')} rows={5} />
          </Form.Item>
          <Form.Item
            name={'rateRiskRule'}
            label={t('เกณฑ์ ระเบียบ คำสั่ง')}
            rules={[
              {
                required: true,
                message: t('กรุณาระบุ'),
              },
            ]}
          >
            <SelectRateRiskRule
              filters={{ isActive: 1, rateFacGroupCode: rateFacGroupCode }}
              disabled={!editable}
              formname={formName}
              placeholder={t('เลือกเกณฑ์ ระเบียบ คำสั่ง')}
              handleChange={(data) => {
                const name = data ? (isLang === 'en' ? data.source.rateRiskRuleEN : data.source.rateRiskRuleTH) : null
                const value = data ? data.source.rateRiskRuleCode : null
                form.setFieldsValue({ rateRiskRule: name, rateRiskRuleCode: value })
              }}
            />
          </Form.Item>
          <Form.Item
            name={'controlTypeCode'}
            label={t('ประเภทการควบคุม')}
            rules={[
              {
                required: true,
                message: t('กรุณาระบุ'),
              },
            ]}
          >
            <SelectControlTypes
              disabled={!editable}
              formname={formName}
              placeholder={t('เลือกประเภทการควบคุม')}
              handleChange={(data) => {
                const name = data
                  ? isLang === 'en'
                    ? data.source.controlTypeNameEN
                    : data.source.controlTypeNameTH
                  : null
                const value = data ? data.source.controlTypeCode : null
                form.setFieldsValue({ controlTypeName: name, controlTypeCode: value })
              }}
            />
          </Form.Item>
          <Form.Item
            name={'controlNatureCode'}
            label={t('ลักษณะการควบคุม')}
            rules={[
              {
                required: true,
                message: t('กรุณาระบุ'),
              },
            ]}
          >
            <SelectControlNatures
              disabled={!editable}
              formname={formName}
              placeholder={t('เลือกลักษณะการควบคุม')}
              handleChange={(data) => {
                const name = data
                  ? isLang === 'en'
                    ? data.source.controlNatureNameEN
                    : data.source.controlNatureNameTH
                  : null
                const value = data ? data.source.controlNatureCode : null
                form.setFieldsValue({ controlNatureName: name, controlNatureCode: value })
              }}
            />
          </Form.Item>

          <Form.Item
            name={'sort'}
            label={t('ลำดับ')}
            rules={[
              { required: true, message: t('กรุณาระบุ') },
              {
                pattern: regexNumber,
                message: t(`กรุณากรอกตัวเลข 0-9`),
              },
            ]}
          >
            <Input disabled={!editable} placeholder={t('กรอกลำดับ')} />
          </Form.Item>

          <Form.Item name={'isActive'} label={t('สถานะ')} initialValue={1}>
            <RadioGroup disabled={!editable}>
              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                </Col>
                <Col>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}
export default RateRiskControlForm
