import React, { useState } from 'react'
import { breadcrumbData, pageTitle } from './utils/Constants'
import { BreadcrumbNew } from '../../../../components/breadcrumb/Breadcrumb'
import OverviewSlaProcessFilter from './components/filter/OverviewSlaProcessFilter'
import OverviewSlaProcessGraph from './OverviewSlaProcessGraph'
import OverviewSlaProcessList from './OverviewSlaProcessList'

const OverviewSlaProcessIndex = () => {
  const [dataFilter, setdataFilter] = useState({
    dateStart: null,
    dateEnd: null,
    organizationCode: null,
    auditTeamCode: null,
    jobStatusCode: null,
  })

  const onGetValue = (data) => {
    setdataFilter(data)
  }

  return (
    <React.Fragment>
      <BreadcrumbNew data={breadcrumbData()} title={pageTitle} />
      <OverviewSlaProcessFilter getValue={onGetValue} />
      <OverviewSlaProcessGraph dataFilter={dataFilter} />
      <OverviewSlaProcessList dataFilter={dataFilter} />
    </React.Fragment>
  )
}

export default OverviewSlaProcessIndex
