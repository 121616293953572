import CryptoJS from 'crypto-js'
// const secret = process.env['REACT_APP_SECRET_FE_BE']
export function encryptData(data, secret = process.env['REACT_APP_SECRET_FE_BE']) {
  if (typeof data !== 'undefined') {
    return CryptoJS.AES.encrypt(JSON.stringify(data), secret).toString()
  }
  return ''
}
export function decryptData(ciphertext, secret = process.env['REACT_APP_SECRET_FE_BE']) {
  try {
    var bytes = CryptoJS.AES.decrypt(ciphertext, secret)
    var decryptedData = bytes.toString(CryptoJS.enc.Utf8)
    if (decryptedData) {
      return JSON.parse(decryptedData)
    }
  } catch {
    return ''
  }
}

export function deryptByPass(obj, secret = process.env['REACT_APP_SECRET_FE_BE']) {
  try {
    const base64 = atob(obj)
    const bytes = CryptoJS.AES.decrypt(base64, secret)
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    return decryptedData
  } catch {
    return {}
  }
}

export const encryptInput = (input, secret) => {
  let newInput = {},
    randomKey = secret
  if (!secret) {
    const user = decryptData(localStorage.getItem('user'))
    randomKey = user.randomKey
  }
  if (typeof input === 'object') {
    Object.entries(input).forEach(([key, value]) => {
      newInput[key] = typeof value !== 'undefined' && encryptData(value, randomKey)

      // newInput[key] = value && encryptData(value, randomKey)
    })
  } else {
    newInput = input && encryptData(input, randomKey)
  }
  return newInput
}
