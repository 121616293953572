import { gql } from '@apollo/client'

export const GET_MASTER_PERSONNEL_OPERATIONS = gql`
  query getMasterPersonnelOperations($input: MasterPersonnelOperationInput!) {
    getMasterPersonnelOperations(data: $input) {
      personnalOperationId
      personnaloperationCode
      personnaloperationNameTH
      personnaloperationNameEN
      isActive
    }
  }
`
