import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import { encryptInput } from '../../../../../utilitys/crypto'

const GET_AUDIT_CAUSE_OF_ISSUE_HEATMAP = gql`
  query getAuditCauseOfIssueHeatmap($input: GetAuditCauseOfIssueHeatmapInput!) {
    getAuditCauseOfIssueHeatmap(input: $input) {
      causeCode
      riskLevelCode
      projectCode
      organizationCode
    }
  }
`

export const useGetAuditCauseOfIssueHeatmap = (options) => {
  const [data, setData] = useState(null)
  const [getAuditCauseOfIssueHeatmapFn, getAuditCauseOfIssueHeatmap] = useLazyQuery(GET_AUDIT_CAUSE_OF_ISSUE_HEATMAP)

  useEffect(() => {
    const { dateStart, dateEnd, organizationCodes } = options

    if (organizationCodes.length && dateStart && dateEnd)
      getAuditCauseOfIssueHeatmapFn({
        variables: { input: encryptInput({ dateStart, dateEnd, organizationCode: organizationCodes }) },
      })
  }, [options])

  useEffect(() => {
    if (getAuditCauseOfIssueHeatmap?.data) setData(getAuditCauseOfIssueHeatmap?.data?.getAuditCauseOfIssueHeatmap)
  }, [getAuditCauseOfIssueHeatmap?.data])

  const refresh = () => getAuditCauseOfIssueHeatmap.refetch()

  return [data, getAuditCauseOfIssueHeatmap?.loading, refresh]
}
