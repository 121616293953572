import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { useLocation, useNavigate } from 'react-router-dom'
import { ButtonNew } from '../../../../../components/button/Button'
import { ModalNew } from '../../../../../components/modal/Modal'
import { GET_ORGANIZATION_PAGINATION } from '../../graphql/Query'
import { EDIT_AUDIT_ISSUE } from '../../graphql/Mutation'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { LabelNew } from '../../../../../components/label/Label'
import { InputNew } from '../../../../../components/input/Input'
import SelectOrganizationLevel from '../../../../../components/inputfromapi/selectorganizationlevel/SelectOrganizationLevel'
import { encryptInput } from '../../../../../utilitys/crypto'
import { Alert } from '../../../../../components/alert/Alert'
import { displayText, displayError } from '../../../../../utilitys/helper'

const AuditCloseIssueOrg = (props) => {
  const formName = 'formIssueOrg'
  const formItemResponsive = { sm: 24, md: 12, lg: 8 }
  const mainPage = '/auditoperations/auditcloses'
  const { menu, dataIssueOrgs } = props

  const navigate = useNavigate()
  const { state } = useLocation()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [formFilter] = Form.useForm()

  const { action, actionPlanId, projectCode, issueId } = state

  const [callOrg, dataOrg] = useLazyQuery(GET_ORGANIZATION_PAGINATION)
  const [callEdit, editResponse] = useMutation(EDIT_AUDIT_ISSUE)

  const [modalOpen, setmodalOpen] = useState(false)
  const [listOrg, setlistOrg] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [searchTrigger, setsearchTrigger] = useState(false)
  const [curIssueOrgs, setCurIssueOrgs] = useState([])

  useEffect(() => {
    if (dataIssueOrgs) setCurIssueOrgs(dataIssueOrgs)
  }, [dataIssueOrgs])

  const columns = [
    { title: t('รหัสหน่วยงาน'), dataIndex: 'organizationCode', width: '30%' },
    { title: t('ชื่อหน่วยงาน'), dataIndex: 'organizationName', width: '50%' },
    {
      title: '',
      width: '20%',
      align: 'center',
      render: (record) => {
        const onClickFn = () =>
          setCurIssueOrgs(curIssueOrgs.filter((item) => item.organizationCode !== record.organizationCode))
        const listButton = action === 'view' ? [] : [{ type: 'delete', onClick: () => onClickFn() }]

        return <ButtonGroup menu={menu} icons={listButton} />
      },
    },
  ]

  useEffect(() => {
    if (dataOrg?.data) {
      const x = dataOrg.data.getOrganizationsPagination.result.map((x, i) => {
        let orgName = displayText(x.organizationNameTH, x.organizationNameEN).split(' : ')
        return {
          key: ++i,
          orgCode: x.organizationCode,
          // orgName: displayText(x.organizationNameTH, x.organizationNameEN),
          orgName: orgName[1],
          orgLV: displayText(x.organizationLevelNameTH, x.organizationLevelNameEN),
          orgResponsible: `${displayText(x.responsiblePerson?.firstNameTH, x.responsiblePerson?.firstNameTH) || ''} ${
            displayText(x.responsiblePerson?.lastNameTH, x.responsiblePerson?.lastNameTH) || ''
          }`,
        }
      })
      setlistOrg(x)
      setCount(dataOrg.data.getOrganizationsPagination.count)
    }
  }, [dataOrg.data])

  useEffect(() => {
    if (action !== 'view') {
      callOrg({
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            filters: formFilter.getFieldValue(),
            pagination: { limit, page },
            sort: [],
          },
        },
      })
    }
  }, [page, searchTrigger])

  useEffect(() => {
    if (editResponse?.data) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          navigate(mainPage, { state: state })
        },
      })
    }

    if (editResponse?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(editResponse?.error)),
        onOk() {},
      })
    }
  }, [editResponse?.data])

  const onSubmit = () => {
    let input = {
      actionPlanId: actionPlanId,
      projectCode: projectCode,
      issueOrg: curIssueOrgs.map((x) => x.organizationCode),
    }

    if (action === 'edit') {
      input['issueId'] = issueId

      Alert({
        type: 'confirm',
        title: t('กดยืนยันเพื่อบันทึกผลการตรวจสอบ'),
        onOk: () => callEdit({ variables: { input: encryptInput(input) } }),
        onCancel: () => null,
      })
    }
  }

  return (
    <>
      {editResponse?.loading && <SpinnersNew />}
      <Form
        name={formName}
        form={form}
        autoComplete="off"
        labelAlign="left"
        layout="horizontal"
        onFinish={onSubmit}
        labelCol={{ sm: 24, md: 4 }}
        wrapperCol={{ sm: 24, md: 20 }}
      >
        <Row>
          <Col md={24}>
            <Datatable
              columns={columns}
              data={curIssueOrgs.map((x, i) => {
                return {
                  key: ++i,
                  organizationCode: x.organizationCode,
                  organizationName: displayText(x.organizationNameTH, x.organizationNameEN),
                }
              })}
              btnAdd={
                action === 'view' ? null : (
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    style={{ marginRight: '12px' }}
                    onClick={() => setmodalOpen(true)}
                  >
                    {t('เพิ่มหน่วยรับตรวจ')}
                  </ButtonNew>
                )
              }
            />
          </Col>
        </Row>
        <Row gutter={[12, 20]} justify="center" align="center" style={{ marginTop: '15px' }}>
          {state?.action !== 'view' && (
            <Col>
              <ButtonNew type="primary" roles={{ type: state?.action, menu: menu }} htmlType="submit">
                {t('บันทึก')}
              </ButtonNew>
            </Col>
          )}
          <Col>
            <ButtonNew type="back" onClick={() => navigate(-1)}>
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
      <ModalNew
        visible={modalOpen}
        testTitle={t('เพิ่มหน่วยรับตรวจ')}
        onClose={() => setmodalOpen(false)}
        forceRender
        onSubmit={() => {
          const data = formFilter.getFieldValue()
          const selectedOrg = []
          for (const [key, value] of Object.entries(data)) {
            if (key.search('selectedOrg_') !== -1 && value) {
              selectedOrg.push(value)
            }
          }
          const newList = [
            ...curIssueOrgs,
            ...selectedOrg.map((x) => {
              return {
                organizationCode: x.orgCode,
                organizationNameTH: x.orgName,
                organizationNameEN: x.orgName,
              }
            }),
          ]
          setCurIssueOrgs(newList)
          setmodalOpen(false)
        }}
        typeAction={'add'}
      >
        {dataOrg.loading && <SpinnersNew />}
        <Form name={'filterOrg'} form={formFilter} autoComplete="off" layout="vertical">
          <Row gutter={[12, 12]}>
            <Col {...formItemResponsive}>
              <Form.Item
                label={<LabelNew type="tab-header-inactive">{t('รหัสหน่วยงาน')}</LabelNew>}
                name="organizationCode"
              >
                <InputNew placeholder={t('รหัสหน่วยงาน')} />
              </Form.Item>
            </Col>
            <Col {...formItemResponsive}>
              <Form.Item
                label={<LabelNew type="tab-header-inactive">{t('ชื่อหน่วยงาน')}</LabelNew>}
                name="organizationName"
              >
                <InputNew placeholder={t('ชื่อหน่วยงาน')} />
              </Form.Item>
            </Col>
            <Col {...formItemResponsive}>
              <Form.Item
                label={<LabelNew type="tab-header-inactive">{t('ระดับหน่วยงาน')}</LabelNew>}
                name="organizationLevel"
              >
                <SelectOrganizationLevel
                  form={formFilter}
                  formname="filterOrg"
                  placeholder={t('เลือกระดับหน่วยงาน')}
                  handleChange={(e) => {
                    formFilter.setFieldsValue({ organizationLevel: e?.value || null })
                  }}
                  disabled={false}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <ButtonNew
                type="search"
                onClick={() => {
                  setsearchTrigger(!searchTrigger)
                }}
                roles={{ type: 'add', menu: props.menu }}
              >
                {t('ค้นหา')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew type="clear" onClick={() => formFilter.resetFields()}>
                {t('ล้างค่า')}
              </ButtonNew>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Datatable
                paginationCustom={true}
                searchCustom={true}
                pageSize={limit}
                page={page}
                total={count}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                columns={[
                  {
                    title: t('เลือก'),
                    width: '10%',
                    className: 'text-nowrap',
                    render: (record) => {
                      return (
                        <Form.Item
                          wrapperCol={{ md: 24, lg: 24 }}
                          name={`selectedOrg_${record.orgCode}`}
                          valuePropName="checked" // ต้องมี
                          style={{ margin: '0px' }}
                        >
                          <Checkbox
                            checked={record.orgCode}
                            onChange={() => formFilter.setFieldsValue({ [`selectedOrg_${record.orgCode}`]: record })}
                          />
                        </Form.Item>
                      )
                    },
                  },
                  {
                    title: t('หน่วยงาน'),
                    width: '20%',
                    className: 'text-nowrap',
                    dataIndex: 'orgCode',
                  },
                  {
                    title: t('หน่วยงาน'),
                    width: '50%',
                    className: 'text-nowrap',
                    dataIndex: 'orgName',
                  },
                  {
                    title: t('ระดับหน่วยงาน'),
                    width: '20%',
                    className: 'text-nowrap',
                    dataIndex: 'orgLV',
                  },
                ]}
                data={listOrg.filter((x) => curIssueOrgs.map((x) => x.organizationCode).indexOf(x.orgCode) === -1)}
              />
            </Col>
          </Row>
        </Form>
      </ModalNew>
    </>
  )
}

export default AuditCloseIssueOrg
