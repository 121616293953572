import React, { useState } from 'react'
import { Row, Col /*, Table*/ } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { Status } from '../../../components/status/Status'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import AuditStatusReportFilter from './filters/SetupReportSubMissionsReportFilter'
// import AuditStatusReportModal from './modal/AuditStatusReportModal'
import { ExpandStyle } from './css/Styles'
const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
}
const SetupReportSubMissionsReport = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { menu } = props
  const [dataLists, setDataLists] = useState([])
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' },
  ])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  // const [reFetch, setReFetch] = useState(false)
  // // const [data, setData] = useState({})
  // const [modal, setModal] = useState(false)

  const Columns = [
    {
      title: t('ลำดับที่'),
      dataIndex: 'no',
      width: '10%',
      // sorter: { multiple: 1 },
    },
    {
      title: t('ชื่อรายงาน'),
      dataIndex: 'menuName',
      width: '30%',
    },
    {
      title: t('วันที่ส่ง'),
      dataIndex: 'sendDate',
      width: '20%',
    },
    {
      title: t('สถานะการส่ง'),
      dataIndex: 'sendStatus',
      width: '20%',
      render: (item) => {
        return <Status text={item === 0 ? 'ยังไม่ส่ง' : 'ส่งแล้ว'} />
      },
    },
    {
      title: t('สถานะการยกเลิก'),
      dataIndex: 'cancelStatus',
      width: '10%',
      render: (item) => {
        return <Status text={item === 0 ? 'ยกเลิก' : 'ปกติ'} />
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '10%',
      render: (item) => {
        const data = {
          setMailReportId: item.setMailReportId,
          sendId: item.sendId,
        }
        const btn = [
          {
            type: 'view',
            onClick: () => {
              navigate(`detail`, {
                state: {
                  type_action: 'view',
                  refCode: item.setMailReportId,
                  ...data,
                },
              })
            },
          },
          {
            type: 'edit',
            onClick: () => {
              navigate(`detail`, {
                state: {
                  type_action: 'edit',
                  refCode: item.setMailReportId,
                  ...data,
                },
              })
            },
          },
        ]
        item.sendStatus === 1 && btn.pop() // ถ้าเป็นส่งแล้วให้ลบปุ่ม edit
        return <ButtonGroup menu={menu} icons={btn} />
      },
    },
  ]
  function getDataListFromFilter(value) {
    setDataLists([
      ...value.map((item, idx) => {
        return {
          key: idx + 1,
          no: item.no,
          menuName: displayText(item.menuNameTH, item.menuNameEN),
          sendStatus: item.sendStatus,
          sendDate: dateDisplay(item.sendDate),
          cancelStatus: item.cancelStatus,
          tools: item,
        }
      }),
    ])
  }
  const breadcrumbList = [{ label: t('รายงาน'), path: '/reports' }, { label: t('รายการรายงานตั้งเวลาการส่งอีเมล') }]
  return (
    <>
      <Breadcrumb data={breadcrumbList} title={t('รายการรายงานตั้งเวลาการส่งอีเมล')} />
      <AuditStatusReportFilter
        page={page}
        limit={limit}
        menu={menu}
        sort={sort}
        // reFetch={reFetch}
        getCount={(e) => setCount(e)}
        getValue={(v) => getDataListFromFilter(v)}
      />
      <CardNew topic={t('รายการรายงานตั้งเวลาการส่งอีเมล')} icon="List" toggledrop={'false'}>
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <ExpandStyle>
              <Datatable
                columns={Columns}
                data={dataLists}
                paginationCustom={true}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                total={count}
                pageSize={limit}
                page={page}
                handleTableChange={(e) => {
                  const reNewField = [{ riskFormName: displayText('riskFormTH', 'riskFormEN') }]
                  setSort(handleSort(e?.sorter, reNewField))
                }}
              />
            </ExpandStyle>
          </Col>
        </Row>
      </CardNew>
      {/* <AuditStatusReportModal
        data={data}
        menu={menu}
        open={modal}
        onSuccess={(e) => {
          setReFetch(reFetch ? false : true)
          setModal(e)
        }}
        close={(e) => setModal(e)}
      /> */}
    </>
  )
}

export default SetupReportSubMissionsReport
