import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useQuery } from '@apollo/client'
import { GET_ADHOCJOB_STEP_MAIN_BY_CODE } from '../../graphql/Query'
import { Select } from '../../../../../components/select/Select.jsx'
import { useLanguage } from '../../../../../hooks/useLanguage'

const SelectJobStepMain = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props

  const [isData, isSetData] = useState([])
  const [isLang] = useLanguage()

  const getJobStepMainByCode = useQuery(GET_ADHOCJOB_STEP_MAIN_BY_CODE, {
    fetchPolicy: 'no-cache',
    variables: filters ? filters : {},
  })

  useEffect(() => {
    if (getJobStepMainByCode.data) {
      isSetData(
        _.map(getJobStepMainByCode?.data?.getJobStepMainByCode, (item) => {
          return {
            label:
              item.stepSort +
              '. ' +
              (isLang !== 'en' ? item?.stepNameTH : item?.stepNameEN ? item?.stepNameEN : item?.stepNameTH),
            value: item?.stepId,
            source: item,
          }
        }),
      )
    }
  }, [getJobStepMainByCode.data, isLang])
  return (
    <>
      <Select
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getJobStepMainByCode.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          return handleChange(_.find(isData, (data) => data.value === value))
        }}
        {...otherProp}
      />
    </>
  )
}

export default SelectJobStepMain
