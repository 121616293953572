import React, { useState } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../../components/breadcrumb/Breadcrumb'
import FilterAuditPlanList from './FilterAuditPlanList.jsx'
import { TabNew } from '../../../../components/tab/Tab.jsx'
import { useNavigate } from 'react-router-dom'
import AuditPlanList from './AuditPlanList.jsx'
import AuditPlan from './AuditPlan.jsx'
import { CardNew } from '../../../../components/card/Card.jsx'
import { getSegmentUrl } from '../../../../utilitys/helper'
const AuditPlanIndex = (props) => {
  const { t } = useTranslation()
  const params = getSegmentUrl(3)
  const navigate = useNavigate()
  const breadcrumbList = [
    { label: t('งานวางแผนการตรวจสอบ'), path: '/auditplanning/auditplan' },
    { label: t('แผนการตรวจสอบ') }
  ]
  const [form] = Form.useForm()
  const [formFilter] = Form.useForm()
  const [searchStatus, setSearchStatus] = useState(0)
  const tabs = [
    {
      key: 1,
      iconTab: () => <></>,
      name: t('ตารางแผนการตรวจสอบ'),
      disabled: false,
      content: () => (
        <>
          {params !== 'list' ? (
            <AuditPlan
              menu={props.menu}
              formFilter={formFilter}
              setSearchStatus={setSearchStatus}
              searchStatus={searchStatus}
            />
          ) : (
            ''
          )}
        </>
      )
    },
    {
      key: 2,
      iconTab: () => <></>,
      name: t('จัดการข้อมูลแผนการตรวจสอบ'),
      disabled: false,
      content: () => (
        <>
          {params === 'list' ? (
            <AuditPlanList
              form={form}
              formFilter={formFilter}
              setSearchStatus={setSearchStatus}
              searchStatus={searchStatus}
              menu={props.menu}
            />
          ) : (
            ''
          )}
        </>
      )
    }
  ]
  return (
    <>
      <Row>
        <Col md={24}>
          <Breadcrumb data={breadcrumbList} title={t('แผนการตรวจสอบ')} menu={props.menu} />
          <FilterAuditPlanList
            menu={props.menu}
            formFilter={formFilter}
            searchStatus={searchStatus}
            setSearchStatus={setSearchStatus}
          />
          <CardNew topic={t('รายการแผนการตรวจสอบ')} icon="AlignJustify" toggledrop="false">
            <TabNew
              tab={tabs}
              activeKey={params !== 'list' ? '1' : '2'}
              onTabClick={(key) => {
                if (key === '1') {
                  navigate('/auditplanning/auditplan/')
                } else if (key === '2') {
                  navigate('/auditplanning/auditplan/list')
                }
              }}
            />
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default AuditPlanIndex
