import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import { CardNew } from '../../../../../components/card/Card'
import { ButtonNew } from '../../../../../components/button/Button'
import { LabelNew } from '../../../../../components/label/Label'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'
import { useLanguage } from '../../../../../hooks/useLanguage'
import SelectOrganization from '../../../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectAuditProjects from '../../../../../components/inputfromapi/selectauditprojects/SelectAuditProjects'

const PrepareAuditReportListFilter = (props) => {
  const Responsive = { md: 8, lg: 8 }
  const { onFilterPrepareAuditReportListFilter, menu } = props
  const [isLang] = useLanguage()
  const formname = 'PrepareAuditReportListFilter'

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)

  const onFinish = (data) => {
    onFilterPrepareAuditReportListFilter({ ...data, lang: isLang })
  }
  const onClear = () => Promise.all([form.resetFields(), onFilterPrepareAuditReportListFilter(form.getFieldsValue())])

  return (
    <CardNew topic={t('Filters')} icon="List" toggledrop="false">
      <Form name={formname} form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <Form.Item name="projectCode" label={<LabelNew type="tab-header-inactive">{t('เรื่อง')}</LabelNew>}>
              <SelectAuditProjects
                placeholder={t('เลือกเรื่อง')}
                filters={{ hasInnerAuditClose: true, projectStatusCode: 90, projectStatusCodeOperator: '!=' }}
                formname={formname}
                handleChange={(e) => form.setFieldsValue({ projectCode: e?.value })}
              />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name="organizationCodeReport"
              label={<LabelNew type="tab-header-inactive">{t('หน่วยรับตรวจ')}</LabelNew>}
            >
              <SelectOrganization
                multiple
                placeholder={t('เลือกหน่วยรับตรวจ')}
                formname={formname}
                handleChange={(e) => form.setFieldsValue({ organizationCodeReport: e })}
              />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name="startDateReport"
              label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้นรายงาน')}</LabelNew>}
            >
              <Datepicker
                autoComplete="off"
                setValueDateFn={async (data) => {
                  await setIsMinDate(data)
                  await form.setFieldsValue({ dateStart: await data })
                }}
                onClear={() => {
                  form.setFieldsValue({ dateStart: null })
                }}
                placeholder={t('DD/MM/YYYY')}
                isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
              />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name="endDateReport"
              label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุดรายงาน')}</LabelNew>}
            >
              <Datepicker
                autoComplete="off"
                setValueDateFn={async (data) => {
                  await setIsMaxDate(data)
                  form.setFieldsValue({ dateEnd: await data })
                }}
                onClear={() => {
                  form.setFieldsValue({ dateEnd: null })
                }}
                placeholder={t('DD/MM/YYYY')}
                isMinDate={isMinDate ? new Date(isMinDate) : null}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <ButtonNew type="search" roles={{ type: 'view', menu: menu }} htmlType="submit">
              {t('ค้นหา')}
            </ButtonNew>
          </Col>
          <Col>
            <ButtonNew type="clear" onClick={onClear}>
              {t('ล้างค่า')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
    </CardNew>
  )
}

export default PrepareAuditReportListFilter
