import { Trans } from 'react-i18next'

export const columnsEducate = [
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>ลำดับ</Trans>
      </div>
    ),
    dataIndex: 'no',
    width: '10%',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    className: 'text-nowrap',
  },
  {
    title: <Trans>วุฒิการศึกษา</Trans>,
    dataIndex: 'degree',
    width: '15%',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>สาขาวิชา</Trans>,
    dataIndex: 'minor',
    width: '15%',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>วิชาเอก</Trans>,
    dataIndex: 'major',
    width: '15%',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>ปีการศึกษา</Trans>
      </div>
    ),
    dataIndex: 'academicYear',
    sorter: true,
    width: '15%',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    className: 'text-nowrap',
  },
  {
    title: <Trans>สถาบันที่จบ</Trans>,
    dataIndex: 'university',
    sorter: true,
    width: '10%',
    className: 'text-nowrap',
  },
  {
    title: '',
    dataIndex: 'action',
    width: '10%',
    className: 'text-nowrap',
    align: 'right',
  },
]
