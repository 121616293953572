import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { Row, Col, Form, Card } from 'antd'
import { useQuery, useLazyQuery } from '@apollo/client'
import { numberFormat, displayError } from '../../../../utilitys/helper'
import { Alert } from '../../../../components/alert/Alert'
import { IconNew } from '../../../../components/icon/Icon.jsx'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../../../hooks/useLanguage'
import { encryptInput } from '../../../../utilitys/crypto'
import { SpinnersNew, SpinnersSmaillNew } from '../../../../components/spinners/Spinners'
import { BreadcrumbNew as Breadcrumb } from '../../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../../components/card/Card'
import { LabelNew } from '../../../../components/label/Label'
import { Select } from '../../../../components/select/Select'
import { Datatable } from '../../../../components/datatable/Datatable'
import { ReceivableauditListStyled } from './ReceivableauditListStyled'

import {
  GET_MASTER,
  GET_AMOUNT,
  GET_GRAPHPIE,
  GET_GRAPHPIE_LASTYEAR,
  GET_GRAPHCOLUMN_YEAR,
  GET_GRAPHCOLUMN_BRANCH,
  GET_TABLE,
} from './graphql/Query'
import ExportModal from './components/ExportModal'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { GraphPie } from '../../../../components/graphpie/GraphPie'
import { NoData } from '../../../../components/nodatagraph/NoData'
import { GraphColumn } from '../../../../components/graphcolumn/GraphColumn'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  xl: 22,
}

const ResponsiveL = {
  sm: 5,
  md: 5,
  lg: 5,
}
const ResponsiveR = {
  sm: 19,
  md: 19,
  lg: 19,
}

const ReceivableauditList = ({ menu }) => {
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const [form] = Form.useForm()
  const formName = 'form-fillter'

  const breadcrumbList = [{ label: t('Dashboard') }, { label: t('รายงานลูกหนี้ที่ต้องตรวจสอบตามแผน') }]

  const masterData = useQuery(GET_MASTER)
  const [filterYear, setFilterYear] = useState([])
  const [filterMonth, setFilterMonth] = useState([])
  const [filterBranch, setFilterBranch] = useState([])

  useEffect(() => {
    if (masterData?.data?.getDataReceivableAuditMasterForFilter) {
      const year = masterData?.data?.getDataReceivableAuditMasterForFilter?.year?.map((item) => ({
        value: item,
        label: item + '',
      }))
      setFilterYear(year)
      const month = masterData?.data?.getDataReceivableAuditMasterForFilter?.month?.map((item) => {
        return {
          value: item.month_th,
          label: isLang === 'th' ? item.month_th : item.month_en,
        }
      })
      setFilterMonth(month)
      const branch = masterData?.data?.getDataReceivableAuditMasterForFilter?.branch?.map((item) => ({
        value: item.BranchName,
        label: item.BranchName,
      }))
      setFilterBranch(branch)

      // --- first load data ---
      const currentYear = parseInt(moment().format('YYYY')) + 543
      form.setFieldsValue({ fyear: currentYear })
      form.submit()
    }
  }, [masterData.data])

  useEffect(() => {
    if (masterData.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(masterData.error)),
        onOk() {},
      })
    }
  }, [masterData.error])

  //--- จำนวน 3 การ์ด ---
  const [callAmountFnc, callAmount] = useLazyQuery(GET_AMOUNT)
  const [dataAmount, setDataAmount] = useState({
    amount1: null,
    amount2: null,
    amount3: null,
  })
  useEffect(() => {
    if (callAmount.data?.getDataReceivableAuditAmount) {
      const data = callAmount.data?.getDataReceivableAuditAmount
      setDataAmount({
        amount1: data?.countDebtor ? numberFormat(data?.countDebtor) : '-',
        amount2: data?.countDebtorBeforeOneYear ? numberFormat(data?.countDebtorBeforeOneYear) : '-',
        amount3: data?.Yamana ? numberFormat(data?.Yamana) : '-',
      })
    }
  }, [callAmount.data])

  //---- กราฟวงกลม 1 ---
  const [dataGraphPie1, setDataGraphPie1] = useState([])
  const [dataGraphPie2, setDataGraphPie2] = useState([])
  const [dataGraphColumn1, setDataGraphColumn1] = useState([])
  const [dataGraphColumn2, setDataGraphColumn2] = useState([])
  const [dataTbl, setDataTbl] = useState([])

  const [callGraphPieFn, callGraphPie] = useLazyQuery(GET_GRAPHPIE)

  useEffect(() => {
    if (callGraphPie.data) {
      if (!_.isEmpty(callGraphPie.data?.getDataReceivableAuditGraphPie?.result)) {
        const data = callGraphPie.data?.getDataReceivableAuditGraphPie?.result?.map((item) => {
          return {
            type: item.Port,
            value: item.CountPLNPLTotal,
          }
        })
        setDataGraphPie1(data)
      }
    }
  }, [callGraphPie.data])

  //---- กราฟวงกลม 2 ---
  const [callGraphPieLastYearFn, callGraphPieLastYear] = useLazyQuery(GET_GRAPHPIE_LASTYEAR)

  useEffect(() => {
    if (callGraphPieLastYear.data) {
      if (!_.isEmpty(callGraphPieLastYear.data?.getDataReceivableAuditGraphPieLastYear?.result)) {
        const data = callGraphPieLastYear.data?.getDataReceivableAuditGraphPieLastYear?.result?.map((item) => {
          return {
            type: item.Port,
            value: item.CountPLNPLTotal,
          }
        })
        setDataGraphPie2(data)
      }
    }
  }, [callGraphPieLastYear.data])

  //---- กราฟแท่ง 1 ---
  const [callGraphColumnYearFn, callGraphColumnYear] = useLazyQuery(GET_GRAPHCOLUMN_YEAR)

  useEffect(() => {
    if (callGraphColumnYear.data) {
      if (!_.isEmpty(callGraphColumnYear.data?.getDataReceivableAuditGraphColumnYear?.result)) {
        const data = callGraphColumnYear.data?.getDataReceivableAuditGraphColumnYear?.result?.map((item) => {
          return {
            year: form.getFieldValue('fyear'),
            type: item.Port,
            value: item.CountPLNPLTotal,
          }
        })
        setDataGraphColumn1(data)
      }
    }
  }, [callGraphColumnYear.data])

  //---- กราฟแท่ง 2 ---
  const [callGraphColumnBranchFn, callGraphColumnBranch] = useLazyQuery(GET_GRAPHCOLUMN_BRANCH)

  useEffect(() => {
    if (callGraphColumnBranch.data) {
      if (!_.isEmpty(callGraphColumnBranch.data?.getDataReceivableAuditGraphColumnBranch?.result)) {
        const dataNPL = callGraphColumnBranch.data?.getDataReceivableAuditGraphColumnBranch?.result?.map((item) => {
          return {
            year: item.BranchName,
            type: 'NPL',
            value: item.NPL,
          }
        })
        const dataPL = callGraphColumnBranch.data?.getDataReceivableAuditGraphColumnBranch?.result?.map((item) => {
          return {
            year: item.BranchName,
            type: 'PL',
            value: item.PL,
          }
        })
        const data = _.concat(dataNPL, dataPL)
        setDataGraphColumn2(data)
      }
    }
  }, [callGraphColumnBranch.data])

  //---- ข้อมูลในตาราง ---
  const [callTableFn, callTable] = useLazyQuery(GET_TABLE)

  useEffect(() => {
    if (callTable.data) {
      if (!_.isEmpty(callTable.data?.getDataReceivableAuditTable?.result)) {
        const data = callTable.data?.getDataReceivableAuditTable?.result?.map((item, index) => {
          return {
            key: index,
            no: index + 1,
            BranchName: item.BranchName,
            StaffFile: item.StaffFile,
            StarGradeMNow: item.StarGradeMNow,
            GradePriority: item.GradePriority,
            Port: item.Port,
            CustomerRegistration: item.CustomerRegistration,
            BorrowerName: item.BorrowerName,
            ApprovalLimit: numberFormat(item.ApprovalLimit, 2),
            Balance: numberFormat(item.Balance, 2),
            YearApproval: item.YearApproval,
          }
        })
        setDataTbl(data)
      }
    }
  }, [callTable.data])

  //---- Submit Filter ---
  const onFinish = (params) => {
    const input = encryptInput({
      year: params.fyear,
      month: params.fmonth || null,
      branch: params.fbranch || null,
    })

    callAmountFnc({
      variables: {
        input: input,
      },
    })
    //---
    callGraphPieFn({
      variables: {
        input: input,
      },
    })
    callGraphPieLastYearFn({
      variables: {
        input: input,
      },
    })
    //---
    callGraphColumnYearFn({
      variables: {
        input: input,
      },
    })
    callGraphColumnBranchFn({
      variables: {
        input: input,
      },
    })
    //---
    callTableFn({
      variables: {
        input: input,
      },
    })
  }

  const columnsTbl = [
    {
      title: <div style={{ textAlign: 'center' }}>{t('ลำดับ')}</div>,
      dataIndex: 'no',
      width: '10%',
      className: 'text-nowrap',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: t('ชื่อสาขา'),
      dataIndex: 'BranchName',
      sorter: false,
      className: 'text-nowrap',
    },
    {
      title: t('เจ้าหน้าที่ดูแลแฟ้ม'),
      dataIndex: 'StaffFile',
      sorter: false,
      className: 'text-nowrap',
    },
    {
      title: t('เกรด'),
      dataIndex: 'StarGradeMNow',
      sorter: false,
      className: 'text-nowrap text-center',
    },
    {
      title: t('GradePriority'),
      dataIndex: 'GradePriority',
      sorter: false,
      className: 'text-nowrap text-center',
    },
    {
      title: t('Port'),
      dataIndex: 'Port',
      sorter: false,
      className: 'text-nowrap text-center',
    },
    {
      title: t('ทะเบียนลูกค้า'),
      dataIndex: 'CustomerRegistration',
      sorter: false,
      className: 'text-nowrap text-center',
    },
    {
      title: t('ชื่อลูกหนี้'),
      dataIndex: 'BorrowerName',
      sorter: false,
      className: 'text-nowrap',
    },
    {
      title: t('วงเงินอนุมัติ'),
      dataIndex: 'ApprovalLimit',
      sorter: false,
      className: 'text-nowrap text-right',
    },
    {
      title: t('เงินต้นคงเหลือ'),
      dataIndex: 'Balance',
      sorter: false,
      className: 'text-nowrap text-right',
    },
    {
      title: t('ปีที่อนุมัติ'),
      dataIndex: 'YearApproval',
      sorter: false,
      className: 'text-nowrap text-center',
    },
  ]

  //=========================== Modal ===========================
  const [modalVisible, setmodalVisible] = useState(false)
  const [dataFilter, setDataFilter] = useState()
  const [exportGraph, setexportGraph] = useState(null)

  const fncOnpressExport = (graphIndex) => {
    const data = form.getFieldValue()

    setDataFilter({ year: data?.fyear, branch: data?.fbranch, month: data?.fmonth })
    setmodalVisible(true)
    setexportGraph(graphIndex)
  }

  const renderAlert = (text) => {
    Alert({
      type: 'error',
      title: t(text),
      onOk() {},
    })
  }

  const fncSubmitExport = (data) => {
    const input = encryptInput({
      year: dataFilter.year || '',
      month: dataFilter.month || '',
      branch: dataFilter.branch || '',
      ...data,
    })

    switch (exportGraph) {
      case 1: //--- จำนวนลูกหนี้ทั้ง port
        callGraphPieFn({
          variables: {
            input: input,
          },
        })
          .then((GraphPieData) => {
            if (GraphPieData?.data) {
              if (!_.isEmpty(GraphPieData?.data?.getDataReceivableAuditGraphPie?.export)) {
                const exportData = GraphPieData?.data?.getDataReceivableAuditGraphPie?.export

                if (exportData.message === 'succcess') {
                  window.open(`${exportData.fileUrl}`, '_blank')
                } else renderAlert('ไม่มีข้อมูล')
              }
            }
          })
          .catch(() => renderAlert('ไม่สามารถโหลดข้อมูลได้'))
        break
      case 2: //--- จำนวนลูกหนี้ที่มีการอนุมัติย้อนหลัง 1 ปี
        callGraphPieLastYearFn({
          variables: {
            input: input,
          },
        })
          .then((GraphPieLastYearData) => {
            if (GraphPieLastYearData?.data) {
              if (!_.isEmpty(GraphPieLastYearData?.data?.getDataReceivableAuditGraphPieLastYear?.export)) {
                const exportData = GraphPieLastYearData?.data?.getDataReceivableAuditGraphPieLastYear?.export

                if (exportData.message === 'succcess') {
                  window.open(`${exportData.fileUrl}`, '_blank')
                } else renderAlert('ไม่มีข้อมูล')
              }
            }
          })
          .catch(() => renderAlert('ไม่สามารถโหลดข้อมูลได้'))
        break
      case 3: //---- กราฟแท่ง 1 ---
        callGraphColumnYearFn({
          variables: {
            input: input,
          },
        })
          .then((GraphColumnYearData) => {
            if (GraphColumnYearData?.data) {
              if (!_.isEmpty(GraphColumnYearData?.data?.getDataReceivableAuditGraphColumnYear?.export)) {
                const exportData = GraphColumnYearData?.data?.getDataReceivableAuditGraphColumnYear?.export

                if (exportData.message === 'succcess') {
                  window.open(`${exportData.fileUrl}`, '_blank')
                } else renderAlert('ไม่มีข้อมูล')
              }
            }
          })
          .catch(() => renderAlert('ไม่สามารถโหลดข้อมูลได้'))
        break

      case 4: //---- กราฟแท่ง 2 ---
        callGraphColumnBranchFn({
          variables: {
            input: input,
          },
        })
          .then((GraphColumnBranchData) => {
            if (GraphColumnBranchData?.data) {
              if (!_.isEmpty(GraphColumnBranchData?.data?.getDataReceivableAuditGraphColumnBranch?.export)) {
                const exportData = GraphColumnBranchData?.data?.getDataReceivableAuditGraphColumnBranch?.export

                if (exportData.message === 'succcess') {
                  window.open(`${exportData.fileUrl}`, '_blank')
                } else renderAlert('ไม่มีข้อมูล')
              }
            }
          })
          .catch(() => renderAlert('ไม่สามารถโหลดข้อมูลได้'))
        break
      case 5: //---- กราฟแท่ง 2 ---
        callTableFn({
          variables: {
            input: input,
          },
        })
          .then((TableData) => {
            if (TableData?.data) {
              if (!_.isEmpty(TableData?.data?.getDataReceivableAuditTable?.export)) {
                const exportData = TableData?.data?.getDataReceivableAuditTable?.export

                if (exportData.message === 'succcess') {
                  window.open(`${exportData.fileUrl}`, '_blank')
                } else renderAlert('ไม่มีข้อมูล')
              }
            }
          })
          .catch(() => renderAlert('ไม่สามารถโหลดข้อมูลได้'))
        break

      default:
        break
    }

    setmodalVisible(false)
  }

  return (
    <>
      <ExportModal
        dataFilter={dataFilter}
        visible={modalVisible}
        onSubmitExport={fncSubmitExport}
        onModalClose={() => {
          setmodalVisible(false)
        }}
      />
      {masterData.loading ? <SpinnersNew /> : null}
      <Breadcrumb data={breadcrumbList} title={t('รายงานลูกหนี้ที่ต้องตรวจสอบตามแผน')} />

      <ReceivableauditListStyled>
        <CardNew topic={t('รายงานลูกหนี้ที่ต้องตรวจสอบตามแผน')} toggledrop={'false'}>
          <Row justify="center">
            <Col {...Responsive}>
              <Row gutter={[16, 16]}>
                <Col {...ResponsiveL}>
                  <Card
                    className="card-dash card-filter"
                    title={
                      <>
                        <span className="card-title-icon">
                          <IconNew icon={'Filter'} size={17} />
                        </span>
                        <span className="card-title-text">{t('รายงานลูกหนี้ที่ต้องตรวจสอบตามแผน')}</span>
                      </>
                    }
                  >
                    <Form
                      form={form}
                      onFinish={onFinish}
                      autoComplete="off"
                      labelAlign="left"
                      labelCol={{
                        offset: 0,
                        md: 24,
                        lg: 24,
                      }}
                      wrapperCol={{
                        md: 24,
                        lg: 24,
                      }}
                      layout="vertical"
                      name={formName}
                    >
                      <Form.Item name="fyear" label={<LabelNew type="tab-header-inactive">{t('ประจำปี')}</LabelNew>}>
                        <Select
                          formname={formName}
                          data={filterYear}
                          placeholder={t('ทั้งหมด')}
                          scrollableId={formName}
                          allowClear={false}
                          showSearch
                          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          onChange={() => {
                            form.submit()
                          }}
                        />
                      </Form.Item>

                      <Form.Item name="fmonth" label={<LabelNew type="tab-header-inactive">{t('เดือน')}</LabelNew>}>
                        <Select
                          formname={formName}
                          data={filterMonth}
                          placeholder={t('เดือน')}
                          scrollableId={formName}
                          showSearch
                          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          onChange={() => {
                            form.submit()
                          }}
                        />
                      </Form.Item>

                      <Form.Item name="fbranch" label={<LabelNew type="tab-header-inactive">{t('สาขา')}</LabelNew>}>
                        <Select
                          formname={formName}
                          data={filterBranch}
                          placeholder={t('สาขา')}
                          scrollableId={formName}
                          showSearch
                          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                          onChange={() => {
                            form.submit()
                          }}
                        />
                      </Form.Item>
                      <br />
                      <br />
                    </Form>
                  </Card>
                </Col>

                {/* คอลัม ทางขวา */}
                <Col {...ResponsiveR}>
                  <Row gutter={[16, 16]}>
                    {/* // จำนวน 1 */}

                    <Col span={8}>
                      <Card
                        className="card-dash card-amount"
                        title={
                          <>
                            <span className="card-title-icon">
                              <IconNew icon={'Map'} size={17} />
                            </span>
                            <span className="card-title-text">{t('จำนวนลูกหนี้ทั้งหมด')}</span>
                          </>
                        }
                      >
                        <div className="block-amount">
                          {callAmount.loading && <SpinnersSmaillNew />}
                          <div className="icon">
                            <IconNew icon={'MapPin'} size={65} />
                          </div>
                          <div className="amount">
                            <div className="number">{dataAmount.amount1}</div>
                            <div className="text">{t('บัญชี')}</div>
                          </div>
                        </div>
                      </Card>
                    </Col>

                    {/* // จำนวน 2 */}
                    <Col span={8}>
                      <Card
                        className="card-dash card-amount"
                        title={
                          <>
                            <span className="card-title-icon">
                              <IconNew icon={'Map'} size={17} />
                            </span>
                            <span className="card-title-text">{t('จำนวนประชากรลูกหนี้ ย้อนหลัง 1 ปี')}</span>
                          </>
                        }
                      >
                        <div className="block-amount">
                          {callAmount.loading && <SpinnersSmaillNew />}
                          <div className="icon">
                            <IconNew icon={'MapPin'} size={65} />
                          </div>
                          <div className="amount">
                            <div className="number">{dataAmount.amount2}</div>
                            <div className="text">{t('บัญชี')}</div>
                          </div>
                        </div>
                      </Card>
                    </Col>

                    {/* // จำนวน 3 */}
                    <Col span={8}>
                      <Card
                        className="card-dash card-amount"
                        title={
                          <>
                            <span className="card-title-icon">
                              <IconNew icon={'TrendingUp'} size={17} />
                            </span>
                            <span className="card-title-text">{t('จำนวนลูกหนี้ที่สุ่มตรวจ')}</span>
                          </>
                        }
                      >
                        <div className="block-amount">
                          {callAmount.loading && <SpinnersSmaillNew />}
                          <div className="icon">
                            <IconNew icon={'TrendingUp'} size={65} />
                          </div>
                          <div className="amount">
                            <div className="number">{dataAmount.amount3}</div>
                            <div className="text">{t('บัญชี')}</div>
                          </div>
                        </div>
                      </Card>
                    </Col>

                    {/* // กราฟวงกลม 1  */}
                    <Col span={12}>
                      <Card
                        className="card-dash card-pie"
                        title={
                          <Row>
                            <Col md={22} lign="left">
                              <span className="card-title-icon">
                                <IconNew icon={'Activity'} size={17} />
                              </span>
                              <span className="card-title-text">{t('จำนวนลูกหนี้ทั้ง port')}</span>
                            </Col>
                            <Col md={2} align="right">
                              <ButtonGroup
                                menu={menu}
                                icons={[
                                  {
                                    type: 'download',
                                    onClick: () => {
                                      fncOnpressExport(1)
                                    },
                                  },
                                ]}
                              />
                            </Col>
                          </Row>
                        }
                      >
                        <div className="block-graph-pie">
                          {callGraphPie.loading && <SpinnersSmaillNew />}
                          {!_.isEmpty(dataGraphPie1) ? <GraphPie data={dataGraphPie1} /> : <NoData />}
                        </div>
                        <div className="card-remark">
                          <span className="card-remark-text">
                            {t('หมายเหตุ : PL หรือ NPL แสดงผลตามรายบัญชีของลูกหนี้แต่ละราย')}
                          </span>
                        </div>
                      </Card>
                    </Col>

                    {/* // กราฟวงกลม 2  */}
                    <Col span={12}>
                      <Card
                        className="card-dash card-pie"
                        title={
                          <Row>
                            <Col md={22} align="left">
                              <span className="card-title-icon">
                                <IconNew icon={'Activity'} size={17} />
                              </span>
                              <span className="card-title-text">{t('จำนวนลูกหนี้ที่มีการอนุมัติย้อนหลัง 1 ปี')}</span>
                            </Col>
                            <Col md={2} align="right">
                              <ButtonGroup
                                style={{ float: 'right' }}
                                menu={menu}
                                icons={[
                                  {
                                    type: 'download',
                                    onClick: () => {
                                      fncOnpressExport(2)
                                    },
                                  },
                                ]}
                              />
                            </Col>
                          </Row>
                        }
                      >
                        <div className="block-graph-pie">
                          {callGraphPieLastYear.loading && <SpinnersSmaillNew />}
                          {!_.isEmpty(dataGraphPie2) ? <GraphPie data={dataGraphPie2} /> : <NoData />}
                        </div>
                        <div className="card-remark">
                          <span className="card-remark-text">
                            {t('หมายเหตุ : PL หรือ NPL แสดงผลตามรายบัญชีของลูกหนี้แต่ละราย')}
                          </span>
                        </div>
                      </Card>
                    </Col>

                    {/* // กราฟแท่ง 1  */}
                    <Col span={24}>
                      <Card
                        className="card-dash card-bar"
                        title={
                          <Row>
                            <Col md={23} lg={23} align="left">
                              <span className="card-title-icon">
                                <IconNew icon={'Activity'} size={17} />
                              </span>
                              <span className="card-title-text">
                                {t('จํานวนของลูกหนี้สุ่มตรวจ จําแนกรายปี จำแนกตามประเภท (PL, NPL)')}
                              </span>
                            </Col>
                            <Col md={1} align="right">
                              <ButtonGroup
                                style={{ float: 'right' }}
                                menu={menu}
                                icons={[
                                  {
                                    type: 'download',
                                    onClick: () => {
                                      fncOnpressExport(3)
                                    },
                                  },
                                ]}
                              />
                            </Col>
                          </Row>
                        }
                      >
                        <div className="block-graph-bar">
                          {callGraphColumnYear.loading && <SpinnersSmaillNew />}
                          {!_.isEmpty(dataGraphColumn1) ? <GraphColumn data={dataGraphColumn1} /> : <NoData />}
                        </div>
                      </Card>
                    </Col>

                    {/* // กราฟแท่ง 2  */}
                    <Col span={24}>
                      <Card
                        className="card-dash card-bar"
                        title={
                          <Row>
                            <Col md={23} lg={23} align="left">
                              <span className="card-title-icon">
                                <IconNew icon={'Activity'} size={17} />
                              </span>
                              <span className="card-title-text">
                                {t('สาขาที่มีจำนวนลูกหนี้สุ่มตรวจ ยอดสูงสุด 5 อันดับแรก จำแนกตามประเภท (PL, NPL)')}
                              </span>
                            </Col>
                            <Col md={1} align="right">
                              <ButtonGroup
                                style={{ float: 'right' }}
                                menu={menu}
                                icons={[
                                  {
                                    type: 'download',
                                    onClick: () => {
                                      fncOnpressExport(4)
                                    },
                                  },
                                ]}
                              />
                            </Col>
                          </Row>
                        }
                      >
                        <div className="block-graph-bar">
                          {callGraphColumnBranch.loading && <SpinnersSmaillNew />}
                          {!_.isEmpty(dataGraphColumn2) ? <GraphColumn data={dataGraphColumn2} /> : <NoData />}
                        </div>
                      </Card>
                    </Col>

                    {/* // ตาราง  */}
                    <Col span={24}>
                      <Card
                        className="card-dash card-table"
                        title={
                          <Row>
                            <Col md={23} lg={23} align="left">
                              <span className="card-title-icon">
                                <IconNew icon={'Activity'} size={17} />
                              </span>
                              <span className="card-title-text">{t('แสดงรายการลูกหนี้สุ่มตรวจ ประเภท NPL')}</span>
                            </Col>
                            <Col md={1} align="right">
                              <ButtonGroup
                                style={{ float: 'right' }}
                                menu={menu}
                                icons={[
                                  {
                                    type: 'download',
                                    onClick: () => {
                                      fncOnpressExport(5)
                                    },
                                  },
                                ]}
                              />
                            </Col>
                          </Row>
                        }
                      >
                        <div className="block-graph-bar">
                          {callTable.loading && <SpinnersSmaillNew />}
                          <Datatable
                            columns={columnsTbl}
                            data={dataTbl}
                            total={0}
                            isScoll={{ x: true, y: true }}
                            paginationCustom={false}
                            pagination={false}
                            pageSize={100}
                            page={1}
                          />
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <br />
                  <br />
                </Col>
              </Row>
            </Col>
          </Row>
        </CardNew>
      </ReceivableauditListStyled>
    </>
  )
}

export default ReceivableauditList
