import React from 'react'

import { Row, Col, Form } from 'antd'

import { Radio, RadioGroup } from '../../../components/radio/Radio'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { allCodeRadio } from './RadioCode'

const RadioIndex = () => {
  const [value, setValue] = React.useState(1)

  const onChange = (e) => {
    console.log('radio checked', e.target.value)
    setValue(e.target.value)
  }

  return (
    <React.Fragment>
      <Form name={'checkbox_form'} className="site-layout-background">
        <Row>
          <Col span={9}>
            <Radio>Basic เบสิค</Radio>
          </Col>
          <Col span={1}></Col>
          <Col span={11}>
            <Radio disabled>Disabled</Radio>
          </Col>
        </Row>
        <div style={{ marginBottom: '20px' }}></div>
        <Row>
          <Col span={10}>
            <h1>Group</h1>
            <RadioGroup onChange={onChange} value={value}>
              <Radio value={1}>A</Radio>
              <Radio value={2}>B</Radio>
              <Radio value={3}>C</Radio>
              <Radio value={4}>D</Radio>
            </RadioGroup>
          </Col>
        </Row>
        <div style={{ marginBottom: '20px' }}></div>

        <CodeBlock code={allCodeRadio} />
      </Form>
    </React.Fragment>
  )
}

export default RadioIndex
