import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'antd'
import { ModalNew } from '../../../../components/modal/Modal.jsx'
import { Alert } from '../../../../components/alert/Alert'
import { useTranslation } from 'react-i18next'
import { InputNew } from '../../../../components/input/Input.jsx'
import { RadioGroup, Radio } from '../../../../components/radio/Radio.jsx'
import { LabelNew as Label } from '../../../../components/label/Label.jsx'
import { useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../../utilitys/crypto'
import { COMPLETE_RESULT_REPORT_EXPORT } from '../graphql/Query'
// import { SpinnersNew } from '../../../../components/spinners/Spinners'
// import { useNavigate } from 'react-router-dom'

const CompleteResultReportModal = (props) => {
  const { t } = useTranslation()
  const { visible, setVisible, data } = props
  const [CompleteResultExportFn, CompleteResultExport] = useLazyQuery(COMPLETE_RESULT_REPORT_EXPORT)
  const [disableAction, setDisableAction] = useState(false)
  const lang = localStorage.getItem('lang')
  const [form] = Form.useForm()
  useEffect(() => {
    setVisible(visible)
  }, [visible])

  useEffect(() => {
    const _ = data
    if (Object.keys(_).length !== 0) {
      form?.setFieldsValue({
        consultId: _?.consultId,
        jobCode: _?.jobCode,
        responsiblePersonnel: _?.responsiblePersonnel,
        maker: '',
        exportType: 'pdf',
      })
    }
  }, [data])

  useEffect(() => {
    if (CompleteResultExport.data) {
      const { fileUrl } = CompleteResultExport.data.CompleteResultExport
      window.open(fileUrl)
    }
    if (CompleteResultExport.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(CompleteResultExport.error.message),
        onOk() {},
      })
    }
  }, [CompleteResultExport.data])

  async function onSubmit(input) {
    await CompleteResultExportFn({
      variables: {
        input: encryptInput({
          projectId: data.projectId,
          fileType: input?.fileType,
          waterMark: input?.waterMark || '',
          lang: lang,
        }),
      },
    })
  }

  function onClose(e) {
    setVisible(e)
  }

  return (
    <>
      {/* {loadingPersonnelEducations ? <SpinnersNew /> : ''} */}
      <ModalNew
        visible={visible}
        testTitle={t('พิมพ์รายงาน')}
        onSubmit={() => {
          form
            .validateFields()
            .then((v) => onSubmit(v))
            .catch(() => {})
        }}
        onClose={(e) => onClose(e)}
        btnName={t('พิมพ์')}
      >
        <Form
          form={form}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="auditTeamForm"
        >
          <Form.Item
            label={<Label type="tab-header-inactive">{t('ประเภทรายงาน')}</Label>}
            name="fileType"
            initialValue={'pdf'}
            onClick={(e) => {
              if (e.target.value) {
                if (e.target.value !== 'pdf') {
                  form.setFieldsValue({
                    waterMark: '',
                  })
                  setDisableAction(true)
                } else {
                  setDisableAction(false)
                }
              }
            }}
          >
            <RadioGroup>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="pdf">
                    <Label type="tab-header-inactive">{t('PDF')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="word">
                    <Label type="tab-header-inactive">{t('Word')}</Label>
                  </Radio>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="excel">
                    <Label type="tab-header-inactive">{t('Excel')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="csv">
                    <Label type="tab-header-inactive">{t('CSV')}</Label>
                  </Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
          {!disableAction ? (
            <Form.Item name="waterMark" label={<Label type="tab-header-inactive">{t('ลายน้ำ')}</Label>}>
              <InputNew disabled={disableAction} />
            </Form.Item>
          ) : (
            ''
          )}
          <Form.Item name={'consultId'} hidden={true}>
            <InputNew />
          </Form.Item>
          <Form.Item name={'jobCode'} hidden={true}>
            <InputNew />
          </Form.Item>
          <Form.Item name={'responsiblePersonnel'} hidden={true}>
            <InputNew />
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}

export default CompleteResultReportModal
