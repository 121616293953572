import { gql } from '@apollo/client'
export const GET_LIST_SUB_RISK_FACTORS_PAGINATION = gql`
  query GetListSubRiskFactorsPagination($input: SubRiskFactorsPagination!) {
    getListSubRiskFactorsPagination(input: $input) {
      result {
        no
        factorActCode
        factorActNameTH
        factorActNameEN
        riskFactorCode
        riskFactorNameTH
        riskFactorNameEN
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_SUB_RISK_FACTORS = gql`
  query GetSubRiskFactor($input: SubRiskFactorGetInput!) {
    getSubRiskFactor(input: $input) {
      factorActCode
      factorActNameTH
      factorActNameEN
      riskFactorCode
      isActive
      riskFactorActionRisk {
        factorActRiskCode
        factorActRiskNameTH
        factorActRiskNameEN
        remark
      }
    }
  }
`
