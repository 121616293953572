import { gql } from '@apollo/client'

export const CREATE_UPDATE_AUDIT_RCMS = gql`
  mutation createUpdateAuditRCMs($input: CreateUpdateAuditRCMsInput!) {
    createUpdateAuditRCMs(input: $input) {
      rcmId
      projectCode
    }
  }
`

export const DELETE_AUDIT_RAC_MATRIX = gql`
  mutation deleteAuditRacMatrix($rcmId: String!) {
    deleteAuditRacMatrix(rcmId: $rcmId) {
      rcmId
    }
  }
`

export const UPDATE_AUDIT_RCMS_RESULT = gql`
  mutation updateAuditRCMResult($input: UpdateAuditRCMResultInput!) {
    updateAuditRCMResult(input: $input) {
      rcmResultId
    }
  }
`

export const UPDATE_AUDIT_RCMS_PERSONNEL = gql`
  mutation updateAuditRCMPersonnels($input: UpdateAuditRCMPersonnelInput!) {
    updateAuditRCMPersonnels(input: $input) {
      rcmResultId
    }
  }
`
export const DELETE_AUDIT_RCM_RESULT = gql`
  mutation deleteAuditRCMResult($rcmResultId: String!) {
    deleteAuditRCMResult(rcmResultId: $rcmResultId) {
      rcmResultId
    }
  }
`

export const DELETE_AUDIT_RCM_RESULT_CONTROL = gql`
  mutation deleteAuditRCMResultControl($rcmControlId: String!) {
    deleteAuditRCMResultControl(rcmControlId: $rcmControlId) {
      rcmControlId
    }
  }
`

export const CREATE_UPDATE_AUDIT_RCM_RESULT_CONTROL = gql`
  mutation createUpdateAuditRCMResultControl($input: CreateUpdateAuditRCMResultControlInput!) {
    createUpdateAuditRCMResultControl(input: $input) {
      rcmControlId
    }
  }
`

export const UPDATE_AUDIT_RCMS_FILE = gql`
  mutation updateAuditRCMsFiles($input: AuditRCMsFileInput!) {
    updateAuditRCMsFiles(input: $input) {
      rcmId
    }
  }
`

export const UPDATE_RATE_FAC_GROUP_DETAIL = gql`
  mutation updateRateFacGroupDetail($input: RateFacGroupDetailInput!) {
    updateRateFacGroupDetail(input: $input) {
      rcmId
    }
  }
`

export const CREATE_RISK_AUDIT_RCM_RESULT = gql`
  mutation createRiskAuditRCMResult($input: CreateRiskAuditRCMResultInput!) {
    createRiskAuditRCMResult(input: $input) {
      rcmId
    }
  }
`
