import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Form, Row } from 'antd'
import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { InputNew } from '../../../components/input/Input'
import SelectAssignJobPersonnels from '../../../components/inputfromapi/selectassignjobpersonnels/SelectAssignJobPersonnels'
import SelectJobStatus from '../../../components/inputfromapi/selectjobstatus/SelectJobStatus'
import SelectJobTypes from '../../../components/inputfromapi/selectjobtypes/SelectJobTypes'
import { LabelNew as Label, LabelNew } from '../../../components/label/Label'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Status } from '../../../components/status/Status'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayError, displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { DELETE_ADHOCJOB_BY_CODE } from './graphql/Mutation'
import { GET_ADHOCJOB_PAGINATION } from './graphql/Query'
import { Columns } from './utils/AdhocJobColumns'

const AdhocjobIndex = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/adhocjob' },
    { label: t('รายการงานมอบหมาย') }
  ]

  const Responsive = {
    md: 8,
    lg: 8
  }
  const ResponsiveTable = {
    md: 24,
    lg: 24
  }

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' }
  ])
  const [filters, setFilters] = useState({})
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)

  /* ---------------- API ----------- */
  const [getAdhocJobPaginationFn, dataAdhocJobPagination] = useLazyQuery(GET_ADHOCJOB_PAGINATION)
  const [deleteAdhocJobFn, dataDeleteAdhocJob] = useMutation(DELETE_ADHOCJOB_BY_CODE)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    getAdhocJobPaginationFn({
      variables: {
        input: encryptInput({
          filters: filters,
          pagination: { limit, page },
          sort: sort
        })
      }
    })
  }, [page, limit, sort])

  /* ---------------- ACTION ----------- */

  const resetField = () => {
    setFilters({})
    form.resetFields()
    getAdhocJobPaginationFn({
      variables: {
        input: encryptInput({
          filters: {},
          pagination: { limit: limit, page: 1 },
          sort: [
            { fieldSort: 'updatedAt', sortType: 'DESC' },
            { fieldSort: 'createdAt', sortType: 'DESC' }
          ]
        })
      }
    })
  }

  useEffect(() => {
    if (dataAdhocJobPagination.data) {
      let response = dataAdhocJobPagination?.data?.getAdhocJobPagination
      setCount(response?.count)

      setDataLists(
        _.map(response?.result, (data) => {
          let assignJobPersonnels = []
          const listPersonnel = displayText(data.assignJobPersonnelsTH, data.assignJobPersonnelsEN)
          if (listPersonnel) {
            assignJobPersonnels = listPersonnel.split(', ')
          }

          return {
            ...data,
            key: data.no,
            jobNumber: data.jobNumber || '-',
            jobSubject: displayText(data.jobSubjectTH, data.jobSubjectEN),
            jobTypeName: displayText(data.jobTypeNameTH, data.jobTypeNameEN),
            responsibleOrganizationName:
              displayText(data.responsibleOrganizationNameTH, data.responsibleOrganizationNameEN) || '-',
            assignJobPersonnels: assignJobPersonnels.map((item, idx) => {
              return (
                <React.Fragment key={`${item}${idx}`}>
                  {item}
                  {idx < assignJobPersonnels.length && <br />}
                </React.Fragment>
              )
            }),
            requestPersonnel: data.requestPersonnel || '-',
            requestOrganizationName: displayText(data.requestOrganizationNameTH, data.requestOrganizationNameEN) || '-',
            durationJob: dateDisplay(data.dateStart) + ' - ' + dateDisplay(data.dateEnd),
            jobStatusName: (
              <Status
                text={
                  data.jobStatusCode === '10'
                    ? 'รอดำเนินการ'
                    : data.jobStatusCode === '20'
                    ? 'กำลังดำเนินการ'
                    : data.jobStatusCode === '30'
                    ? 'ดำเนินการแล้วเสร็จ'
                    : data.jobStatusCode === '40'
                    ? 'บันทึกร่าง'
                    : '-'
                }
              />
            ),
            approveStatusName: (
              <Status
                text={
                  data.approveStatusCode === '10'
                    ? 'รออนุมัติ'
                    : data.approveStatusCode === '20'
                    ? 'อนุมัติ'
                    : 'ไม่อนุมัติ'
                }
              />
            ),
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () =>
                        navigate(`tab/information`, { state: { type_action: 'view', refCode: data.jobCode } })
                    },
                    {
                      type: 'edit',
                      onClick: () =>
                        navigate(`tab/information`, { state: { type_action: 'edit', refCode: data.jobCode } })
                    },
                    {
                      type: 'delete',
                      onClick: () => showConfirmDelete(data.jobCode)
                    }
                  ]}
                />
              </div>
            )
          }
        })
      )
    }

    if (dataAdhocJobPagination.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataAdhocJobPagination.error)),
        onOk() {}
      })
    }
  }, [dataAdhocJobPagination.data])

  useEffect(() => {
    if (dataDeleteAdhocJob.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          dataAdhocJobPagination.refetch()
        }
      })
    }

    if (dataDeleteAdhocJob.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(dataDeleteAdhocJob.error)),
        onOk() {}
      })
    }
  }, [dataDeleteAdhocJob.data])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    const inputSearch = {
      jobSubject: input.jobSubject || '',
      jobStatusCode: input.jobStatusCode || '',
      dateEnd: input.dateEnd || '',
      dateStart: input.dateStart || '',
      jobTypeCode: input.jobTypeCode || '',
      personnelCode: input.personnelCode || ''
    }

    setFilters(inputSearch)

    getAdhocJobPaginationFn({
      variables: {
        input: encryptInput({
          filters: inputSearch,
          pagination: { limit, page },
          sort: sort
        })
      }
    })
  }

  const showConfirmDelete = (jobCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteAdhocJobFn({
          variables: {
            jobCode: encryptInput(jobCode)
          }
        })
      },
      onCancel() {}
    })
  }

  return (
    <>
      {(dataAdhocJobPagination.loading || dataDeleteAdhocJob.loading) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('งานมอบหมาย')} />
      <CardNew topic={t('Filters')} icon="Filter" toggledrop="true" buttontopright="true">
        <Form name="form-search" form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[12, 0]}>
            <Col {...Responsive}>
              <Form.Item name={'jobSubject'} label={<LabelNew type="tab-header-inactive">{t('ชื่องาน')}</LabelNew>}>
                <InputNew placeholder={t('ชื่องาน')} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="jobTypeCode" label={<Label type="tab-header-inactive">{t('ประเภทงาน')}</Label>}>
                <SelectJobTypes
                  placeholder={t('เลือกประเภทงาน')}
                  formname={'form-search'}
                  filters={{ jobflag: 1, isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ jobTypeCode: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="jobStatusCode" label={<Label type="tab-header-inactive">{t('สถานะดำเนินการ')}</Label>}>
                <SelectJobStatus
                  placeholder={t('เลือกสถานะดำเนินการ')}
                  formname={'form-search'}
                  filters={{ isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ jobStatusCode: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'dateStart'}
                label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระบุ')
                  }
                ]}
              >
                <Datepicker
                  setValueDateFn={async (data) => {
                    await setIsMinDate(data), await form.setFieldsValue({ dateStart: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateStart: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                  isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'dateEnd'}
                label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระบุ')
                  }
                ]}
              >
                <Datepicker
                  setValueDateFn={async (data) => {
                    await setIsMaxDate(data), await form.setFieldsValue({ dateEnd: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateEnd: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                  isMinDate={isMinDate ? new Date(isMinDate) : null}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="personnelCode" label={<Label type="tab-header-inactive">{t('ผู้รับผิดชอบ')}</Label>}>
                <SelectAssignJobPersonnels
                  placeholder={t('ระบุผู้รับผิดชอบ')}
                  formname={'form-search'}
                  handleChange={(e) => form.setFieldsValue({ personnelCode: e?.value })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <ButtonNew type="search" htmlType="submit">
                {t('ค้นหา')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew type="clear" onClick={() => resetField()}>
                {t('ล้างค่า')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </CardNew>

      <CardNew topic={t('รายการงานมอบหมาย')} icon="List" toggledrop="false">
        <Row gutter={[12, 12]}>
          <Col {...ResponsiveTable}>
            <Datatable
              columns={Columns}
              data={[...dataLists]}
              total={count}
              pageSize={limit}
              page={page}
              // enableScroll={{ x: true, y: false }}
              handleTableChange={(e) => {
                const reNewField = [
                  { jobSubject: displayText('jobSubjectTH', 'jobSubjectEN') },
                  { jobTypeName: displayText('jobTypeNameTH', 'jobTypeNameEN') },
                  {
                    responsibleOrganizationName: displayText(
                      'responsibleOrganizationNameTH',
                      'responsibleOrganizationNameEN'
                    )
                  },
                  {
                    requestOrganizationName: displayText('requestOrganizationNameTH', 'requestOrganizationNameEN')
                  },
                  { jobStatusName: displayText('jobStatusNameTH', 'jobStatusNameEN') },
                  { approveStatusName: displayText('approveStatusNameTH', 'approveStatusNameEN') },
                  { assignJobPersonnels: displayText('assignJobPersonnelsTH', 'assignJobPersonnelsEN') },
                  { durationJob: 'durationJob' }
                ]
                setSort(handleSort(e?.sorter, reNewField))
              }}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              paginationCustom={true}
              showSizeChanger={true}
              searchCustom={true}
              btnAdd={
                <>
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    onClick={() => navigate(`tab`, { state: { type_action: 'add' } })}
                  >
                    {t('เพิ่มงานมอบหมาย')}
                  </ButtonNew>
                </>
              }
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default AdhocjobIndex
