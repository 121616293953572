import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Form } from 'antd'
import { ModalNew } from '../../../../../components/modal/Modal'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { SAVE_AUDIT_SUGGEST_EXPLAIN_SUB } from '../../graphql/Mutation'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { Alert } from '../../../../../components/alert/Alert'
import { UploadNew } from '../../../../../components/upload/Upload'

const AcknowledgmentModal = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { objData, setStatus } = props
  const [visible, setVisible] = useState(false)

  const [idFile, setIdFile] = useState([])
  const [idFileDefault /*setIdFileDefault*/] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])

  const [cellSave, { data: dataSave, loading: loadingSave, error: errorSave }] =
    useMutation(SAVE_AUDIT_SUGGEST_EXPLAIN_SUB)

  useEffect(() => {
    if (dataSave) {
      if (dataSave.auditSuggestExplainSubSave.suggestId) {
        Alert({
          type: 'success',
          title: t('บันทึกข้อมูลสำเร็จ'),
          onOk() {
            setStatus(true)
            form.resetFields()
            props.close(false)
          },
        })
      }
    }
    if (errorSave) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถบันทึกข้อมูลได้`),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [dataSave])

  // onFinish
  const onFinish = (values) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        showConfirm(values)
      },
      onCancel() {},
    })
  }

  const showConfirm = async (input) => {
    let dataInput = {
      suggestId: objData.suggestId,
      organizationCode: objData.organizationCode,
      personnelCode: objData.personnelCode,
      explainTH: input.statement,
      explainEN: input.statement,
      idFile: idFile,
      lang: localStorage.getItem('lang') || '',
    }

    await cellSave({
      variables: {
        input: encryptInput(dataInput),
      },
    })
  }

  useEffect(() => {
    setVisible(props.open)
  }, [props.open])

  function onClose(e) {
    form.resetFields()
    props.close(e)
  }
  return (
    <>
      {loadingSave && <SpinnersNew />}
      <ModalNew
        visible={visible}
        testTitle={t('คำชี้แจง')}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        typeAction={objData.type}
      >
        <Form
          form={form}
          labelAlign="left"
          onFinish={onFinish}
          labelCol={{
            span: 5,
            offset: 4,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="statementForm"
        >
          <Form.Item
            name="statement"
            label={<Label type="tab-header-inactive">{t('คำชี้แจง')}</Label>}
            rules={[{ required: true, message: t('คำชี้แจง') }]}
          >
            <TextAreaNew
              // showCount
              disabled={objData.type === 'view' ? true : false}
              // maxLength={300}
              rows={5}
              placeholder={t('ระบุคำชี้แจง')}
            />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('เอกสารเพิ่มเติม')}</Label>}>
            <UploadNew
              type="mutiple"
              setIdFile={setIdFile}
              idFile={idFile}
              typeFile="file"
              actionType={'add'}
              idFileDelete={idFileDelete}
              setIdFileDelete={setIdFileDelete}
              disabled={false}
              defaults={idFileDefault}
            />
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
export default AcknowledgmentModal
