import React, { useLayoutEffect, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { SearchTable } from '../searchtable/SearchTable'
import { PaginationComp } from '../pagination/Pagination'
import { SpinnersNew } from '../spinners/Spinners'
import './DatatableStyle.scss'

export const Datatable = (props) => {
  const contentRef = useRef()
  const { t } = useTranslation()
  const [size, setSize] = useState({})

  const {
    locale = { emptyText: <div className="no-data">{t('No Data')}</div> },
    handleTableChange: handleChange = () => {}
  } = props

  const [isDataSources, setIsDataSources] = useState([])

  useLayoutEffect(() => {
    const handleResize = async () => {
      const current = contentRef && contentRef?.current?.getBoundingClientRect()
      setSize({ width: current?.width, height: current?.height })
    }
    ;(async () => {
      handleResize()
      await window.addEventListener('resize', handleResize())
    })()
    return async () => await window.removeEventListener('resize', handleResize())
  }, [])

  useEffect(() => {
    props.data?.length ? setIsDataSources(props.data) : setIsDataSources([])
  }, [props.data])

  const handleTableChange = (pagination, filters, sorter, extra) => handleChange({ pagination, filters, sorter, extra })

  const onChangePagination = (page, pageSize) => props.onChangePagination({ page, pageSize })

  const setScroll = (axis) => {
    /* scroll สำหรับ custom ขนาด x, y [string, number] */
    /* enableScroll สำหรับปรับขนาดให้ตาม DOM [boolean] */

    const { scroll, enableScroll } = props
    const { width, height } = size

    switch (axis) {
      case 'x': {
        let sizeAxisX = null

        if (scroll?.x) {
          sizeAxisX = scroll?.x
        } else {
          if (enableScroll?.x && width > 991) {
            sizeAxisX = width
          } else {
            sizeAxisX = 'max-content'
          }
        }
        return sizeAxisX
      }
      case 'y': {
        let sizeAxisY = null

        if (scroll?.y) {
          sizeAxisY = scroll?.y
        } else {
          if (enableScroll?.y) sizeAxisY = height
        }
        return sizeAxisY
      }
      default: {
        if (axis === 'x') return 'max-content'
        if (axis === 'y') return null
      }
    }
  }

  return (
    <div ref={contentRef} style={{ height: '100%' }}>
      {props.isLoading && <SpinnersNew />}
      <SearchTable
        btnAdd={props.btnAdd}
        dataSource={props.data}
        searchDataSource={(data) => setIsDataSources(data)}
        searchCustom={props.searchCustom}
      />

      <Table
        {...props}
        locale={locale}
        style={props.style}
        className={props.className}
        columns={props.columns}
        dataSource={[...isDataSources]}
        pagination={
          props.pagination
            ? {
                page: props.page || 1,
                pageSize: props.pageSize || 10,
                total: props.total || 0,
                showTotal: (total, range) => `${range[0]}-${range[1]} ${t('Of')} ${total}`
              }
            : false
        }
        showSorterTooltip={false}
        onChange={handleTableChange}
        scroll={{ x: setScroll('x'), y: setScroll('y') }}
      />
      {props.paginationCustom && (
        <PaginationComp
          total={props.total}
          onChangePagination={onChangePagination}
          defaultCurrent={1}
          pageSize={props.pageSize}
          page={props.page}
          showSizeChanger={props.showSizeChanger}
        />
      )}
    </div>
  )
}

Datatable.propTypes = {
  handleTableChange: PropTypes.func,
  onChangePagination: PropTypes.func,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  total: PropTypes.number,
  scroll: PropTypes.object,
  paginationCustom: PropTypes.bool,
  searchCustom: PropTypes.bool,
  btnAdd: PropTypes.element,
  enableScroll: PropTypes.object,
  local: PropTypes.object,
  isLoading: PropTypes.bool
}
