import styled from 'styled-components'
import { colors } from '../../../../../configs/styles.config'

export const ApproveSubMenuStyle = styled.div`
  .approve-submenu-head {
    width: 100%;
    height: 100%;
    border-bottom: none;
    .approve-submenu-icon {
      float: left;
      margin-right: 5px;
    }
    .approve-submenu-text {
      font-size: 18px;
      font-weight: 500;
      color: ${colors.black};
    }
  }
`
