import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery /*, useMutation*/ } from '@apollo/client'
import * as _ from 'lodash'
import { Row, Col, Table, Form, Space } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { TabNew } from '../../../components/tab/Tab'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew as Card } from '../../../components/card/Card'
import { ButtonNew } from '../../../components/button/Button'
import { Select } from '../../../components/select/Select'
import { InputNew } from '../../../components/input/Input'
import { LabelNew as Label } from '../../../components/label/Label'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput /*, decryptData*/ } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'
import { Alert } from '../../../components/alert/Alert'
import { displayError } from '../../../utilitys/helper'
import { GET_RISK_RESULT_BY_ID } from './graphql/Query'
import { TableRow } from './css/Styles'
const Responsive = {
  card: {
    label: {
      sm: 12,
      md: 6,
      lg: 6,
    },
    col: {
      sm: 12,
      md: 18,
      lg: 18,
    },
  },
}
const RiskResultDetail = () => {
  const propsType = useLocation()
  const navigate = useNavigate()
  const { riskResultId, view_type, selects, riskFormInitial } = propsType.state
  const viewAll = view_type === 'all' ? true : false
  const viewUnit = view_type === 'unit' ? true : false
  const dataSelect = selects || []
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const formName = 'riskResultDetail'
  const [riskForm, setRiskForm] = useState({})
  const [riskResultId_, setRiskResultId_] = useState(0)
  const [dataList, setDataList] = useState({})
  const [tabs, setTabs] = useState([])

  const [riskResultFn, riskResult] = useLazyQuery(GET_RISK_RESULT_BY_ID)

  const sharedOnCell = (_) => {
    return {
      colSpan: _.factorActHead ? 0 : 1,
    }
  }

  const Columns = [
    {
      title: t('ความเสี่ยง'),
      dataIndex: 'factorActRiskName',
      render: (text, row) => {
        return <>{row.factorActHead ? text : <div style={{ textIndent: '1.5em' }}>{text}</div>}</>
      },
      onCell: (_) => {
        return {
          colSpan: _.factorActHead ? 5 : 1,
        }
      },
    },
    {
      title: t('คำอธิบาย'),
      dataIndex: 'remark',
      width: '10%',
      onCell: sharedOnCell,
    },
    {
      title: t('โอกาส'),
      dataIndex: 'impact',
      width: '10%',
      onCell: sharedOnCell,
    },
    {
      title: t('ผลกระทบ'),
      dataIndex: 'likelihood',
      width: '10%',
      onCell: sharedOnCell,
    },
    {
      title: t('ระดับความเสี่ยง'),
      dataIndex: 'Score',
      width: '10%',
      onCell: sharedOnCell,
    },
  ]

  useEffect(() => {
    if (viewUnit) {
      riskResultFn({
        variables: { input: encryptInput({ riskResultId: riskResultId }) },
      })
    }
  }, [])

  useEffect(() => {
    if (riskResultId_) {
      riskResultFn({
        variables: { input: encryptInput({ riskResultId: riskResultId_ }) },
      })
    }
  }, [riskResultId_])

  useEffect(() => {
    setRiskResultId_(riskForm.riskResultId)
    form.setFieldsValue({
      makerByName: displayText(riskForm?.makerByNameTH, riskForm?.makerByNameEN),
      riskResultId: riskForm.riskResultId,
    })
  }, [riskForm])

  useEffect(() => {
    if (riskResult.data) {
      const tab_ = riskResult?.data?.getRiskResultById?.riskResultsFactors
      const children_ = riskResult?.data?.getRiskResultById?.riskResultDetail
      const uniqFactorAct_ = _.uniqBy(children_, 'factorActCode')

      const children = []
      uniqFactorAct_.map((item) => {
        const x = children_.filter((e) => e.factorActCode === item.factorActCode)
        x.map((item_, idx_) => {
          if (idx_ === 0) {
            children.push(
              {
                ...item_,
                factorActRiskNameTH: item_.factorActNameTH,
                factorActRiskNameEN: item_.factorActNameEN,
                factorActHead: true,
              },
              {
                ...item_,
              },
            )
          } else {
            children.push({
              ...item_,
            })
          }
        })
      })
      setRiskForm(riskResult?.data?.getRiskResultById)
      setTabs(
        tab_.map((item, idx) => {
          dataList[item.riskFactorCode] = [
            ...children
              .filter((e) => e?.riskFactorCode === item?.riskFactorCode)
              .map((item_, idx_) => {
                return {
                  key: idx_ + 1,
                  factorActHead: item_?.factorActHead ? item_?.factorActHead : null,
                  factorActRiskName: displayText(item_?.factorActRiskNameTH, item_?.factorActRiskNameEN),
                  remark: item_?.remark,
                  impact: !item_.factorActHead ? <>{item_?.impact ? item_?.impact : 0}</> : <></>,
                  likelihood: !item_.factorActHead ? <>{item_?.likelihood ? item_?.likelihood : 0}</> : <></>,
                  Score: !item_.factorActHead ? <>{item_?.Score ? item_?.Score : 0}</> : <></>,
                  resultDetail: !item_?.factorActHead ? <></> : <></>,
                }
              }),
          ]
          return {
            key: idx + 1,
            name: displayText(item.riskFactorNameTH, item.riskFactorNameEN),
            content: () => (
              <>
                <TableRow>
                  <Table
                    rowClassName={(record) => (record.factorActHead ? 'table-row-color' : '')}
                    columns={Columns}
                    dataSource={[...dataList[item.riskFactorCode]]}
                    size="middle"
                    pagination={false}
                    scroll={{ x: '100%', y: '100%' }}
                  />
                </TableRow>
              </>
            ),
          }
        }),
      )
      setDataList({ ...dataList })
    }
    if (riskResult.error) {
      const err = riskResult.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {
          navigate('/riskassessment/riskresult')
        },
      })
    }
  }, [riskResult.data])
  const breadcrumbList = [
    { label: t('งานประเมินความเสี่ยง'), path: '/riskassessment/riskresult' },
    { label: t('ประเมินความเสี่ยง') },
  ]

  const convertYear = (year) => {
    if (localStorage.getItem('lang') === 'th') {
      return parseInt(year) + 543
    } else {
      return parseInt(year) - 543
    }
  }

  return (
    <>
      {riskResult.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('แบบประเมินความเสี่ยง')} />
      <Form form={form} name={formName}>
        <Card topic={t('รายละเอียดแบบประเมิน')} icon="AlertCircle" toggledrop={'true'}>
          <Row justify="center">
            <Col md={20}>
              <Row gutter={[24, 24]}>
                <Col {...Responsive.card.label} align="left">
                  <Label type="tab-header-inactive">{t('ประจำปี')}</Label>
                </Col>
                <Col {...Responsive.card.col} align="left">
                  <Label type="body-primary">
                    {riskForm.Year ? convertYear(riskForm.Year) : convertYear(riskFormInitial.Year)}
                  </Label>
                </Col>
                <Col {...Responsive.card.label} align="left">
                  <Label type="tab-header-inactive">{t('ชื่อแบบประเมิน')}</Label>
                </Col>
                <Col {...Responsive.card.col} align="left">
                  <Label type="body-primary">
                    {riskForm.riskFormTH
                      ? displayText(riskForm.riskFormTH, riskForm.riskFormEN)
                      : displayText(riskFormInitial.riskFormTH, riskFormInitial.riskFormEN)}
                  </Label>
                </Col>
                <Col {...Responsive.card.label} align="left">
                  <Label type="tab-header-inactive">{t('ประเภทแบบประเมิน')}</Label>
                </Col>
                <Col {...Responsive.card.col} align="left">
                  <Label type="body-primary">
                    {riskForm.riskFormType
                      ? riskForm.riskFormType === 1
                        ? t('ประเมินสรุป')
                        : t('ประเมินรายหน่วยงาน')
                      : riskFormInitial.riskFormType === 1
                      ? t('ประเมินสรุป')
                      : t('ประเมินรายหน่วยงาน')}
                  </Label>
                </Col>
                <Col {...Responsive.card.label} align="left">
                  <Label type="tab-header-inactive">{t('หน่วยที่ทำแบบประเมิน')}</Label>
                </Col>
                <Col {...Responsive.card.col} align="left">
                  {viewUnit && (
                    <Label type="body-primary">
                      {displayText(riskForm.organizationNameTH, riskForm.organizationNameEN)}
                    </Label>
                  )}
                  {viewAll && (
                    <Form.Item name={'riskResultId'}>
                      <Select
                        onChange={(value) => {
                          setRiskResultId_(value)
                        }}
                        data={dataSelect.map((item) => ({
                          value: item.riskResultId,
                          label: displayText(item.organizationNameTH, item.organizationNameEN),
                        }))}
                        scrollableId={formName}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col {...Responsive.card.label} align="left">
                  <Label type="tab-header-inactive">{t('ผู้ทำการประเมิน')}</Label>
                </Col>
                <Col {...Responsive.card.col} align="left">
                  {viewUnit && (
                    <Label type="body-primary">{displayText(riskForm?.makerByNameTH, riskForm?.makerByNameEN)}</Label>
                  )}
                  {viewAll && (
                    <Form.Item name={t('makerByName')}>
                      <InputNew disabled={true} />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
        {riskResultId_ ? (
          <>
            <Card topic={displayText(riskForm.riskFormTH, riskForm.riskFormEN)} icon="List" toggledrop={'false'}>
              <Row justify="center">
                <Col md={24}>
                  <TabNew tab={tabs} defaultActiveKey={'1'} />
                </Col>
              </Row>
              <Row justify="center">
                <Col {...Responsive} align="center">
                  <Space size={[8, 8]} wrap>
                    <ButtonNew
                      type="back"
                      onClick={() => {
                        navigate('/riskassessment/riskresult')
                      }}
                    >
                      {t('ยกเลิก')}
                    </ButtonNew>
                  </Space>
                </Col>
              </Row>
            </Card>
          </>
        ) : null}
      </Form>
    </>
  )
}

export default RiskResultDetail
