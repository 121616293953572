import { Trans } from 'react-i18next'
export const Columns = [
  {
    title: <Trans>ลำดับที่</Trans>,
    dataIndex: 'key',
    width: '10%',
    // sorter: { multiple: 1 },
    align: 'center',
    className: 'text-nowrap',
  },
  {
    title: <Trans>เลขที่คำขอ</Trans>,
    dataIndex: 'requestNumber',
    width: '10%',
    sorter: { multiple: 1 },
  },
  {
    title: <Trans>วันที่เริ่มต้น</Trans>,
    dataIndex: 'dateStart',
    width: '10%',
    sorter: { multiple: 2 },
  },
  {
    title: <Trans>วันที่สิ้นสุด</Trans>,
    dataIndex: 'dateEnd',
    width: '10%',
    sorter: { multiple: 3 },
  },
  {
    title: <Trans>ผู้อนุมัติ</Trans>,
    dataIndex: 'approverName',
    width: '30%',
    sorter: { multiple: 4 },
  },
  {
    title: <Trans>สถานะพิจารณา</Trans>,
    dataIndex: 'requestStatusName',
    width: '20%',
    sorter: { multiple: 5 },
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '10%',
    align: 'center',
    // className: 'text-nowrap',
  },
]
