import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_NUMBER_TYPES } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectMasterNumberTypes = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { numberTypeId, numberTypeCode, numberTypeNameTH, numberTypeNameEN, isActive } = filters || {}

  const [isData, isSetData] = useState([])

  const [getMasterNumberTypesFn, getMasterNumberTypes] = useLazyQuery(GET_MASTER_NUMBER_TYPES)

  useEffect(() => {
    getMasterNumberTypesFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [numberTypeId, numberTypeCode, numberTypeNameTH, numberTypeNameEN, isActive])

  useEffect(() => {
    if (getMasterNumberTypes.data) {
      isSetData(
        _.map(getMasterNumberTypes?.data?.getMasterNumberTypes, (item) => {
          return {
            label: item?.numberTypeCode + ':' + displayText(item?.numberTypeNameTH, item?.numberTypeNameEN),
            value: item?.numberTypeCode,
            source: item,
          }
        }),
      )
    }
  }, [getMasterNumberTypes.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterNumberTypes.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectMasterNumberTypes
