import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useQuery } from '@apollo/client'
import { GET_MASTER_URGENTS } from './graphql/Query'
import { Select } from '../../select/Select'
import { displayText } from '../../../utilitys/helper'

const SelectUrgents = (props) => {
  const { placeholder, formname, handleChange, ...otherProp } = props
  const [isData, isSetData] = useState([])
  const getMasterUrgents = useQuery(GET_MASTER_URGENTS)

  useEffect(() => {
    if (getMasterUrgents.data) {
      isSetData(
        _.map(getMasterUrgents?.data?.getMasterUrgents, (item) => {
          return {
            label: displayText(item?.urgentNameTH, item?.urgentNameEN),
            value: item?.urgentCode,
            source: item,
          }
        }),
      )
    }
  }, [getMasterUrgents.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterUrgents.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          return handleChange(_.find(isData, (data) => data.value === value))
        }}
      />
    </>
  )
}

export default SelectUrgents
