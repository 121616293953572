import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Form, Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import styled from 'styled-components'
import { ButtonNew } from '../../../../components/button/Button'
import { LabelNew } from '../../../../components/label/Label'
import SelectRateRiskRulesExamine from '../../../../components/inputfromapi/selectrateriskrulesexamine/SelectRateRiskRulesExamine'
import SelectRateRiskControlExamine from '../../../../components/inputfromapi/selectrateriskcontrolexamine/SelectRateRiskControlExamine'
import SelectRiskFactorExamine from '../../../../components/inputfromapi/selectriskfactorexamine/SelectRiskFactorExamine'
import SelectAuditFindingType from '../../../../components/inputfromapi/selectauditfindingtype/SelectAuditFindingType'
import SelectISO from '../../../../components/inputfromapi/selectiso/SelectISO'
import SelectISOSub from '../../../../components/inputfromapi/selectisosub/SelectISOSub'
import SelectRiskLevel from '../../../../components/inputfromapi/selectrisklevel/SelectRiskLevel'
import SelectShowPoint from '../../../../components/inputfromapi/selectshowpoint/SelectShowPoint'
import SelectActionPlanPersonnels from '../../../../components/inputfromapi/selectactionplanpersonnels/SelectActionPlanPersonnels.jsx'
import SelectIssueHave from '../../../../components/inputfromapi/selectissuehave/SelectIssueHave'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { Alert } from '../../../../components/alert/Alert'
import { TextAreaNew } from '../../../../components/textarea/TextArea'
import { SAVE_AUDIT_RESULT } from '../graphql/Mutation'
import { GET_AUDIT_RESULT_ISSUE } from '../graphql/Query'
import { displayText, displayError, getSegmentUrl } from '../../../../utilitys/helper'

const Responesive = { md: 24, lg: 24 }
const IconDispaly = styled.div`
  .display-none-icon
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .sc-fotOHu
    .ant-input-suffix {
    display: none;
  }
`

const AuditResultIssue = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()
  const [typeAction, setTypeAction] = useState(false)
  const [form] = Form.useForm()
  const [rateFacProCode, setRateFacProCode] = useState(null)
  const [rateRiskRuleCode, setRateRiskRuleCode] = useState(null)
  const [iSOCode, setISOCode] = useState(null)
  const [typeActionTab, setTypeActionTab] = useState('add')

  const params = getSegmentUrl(4)
  if (params !== 'issue') return <></>

  if (!state) {
    return <Navigate to={'/auditoperations/auditresult/'} />
  }

  const [cellGet, { data: dataGet, loading: loadingGet, error: errorGet }] = useLazyQuery(GET_AUDIT_RESULT_ISSUE)

  const [cellSave, { data: dataSave, loading: loadingSave, error: errorSave }] = useMutation(SAVE_AUDIT_RESULT)

  useEffect(() => {
    if (state?.issueId) {
      if (state.type_actionSub === 'view') {
        setTypeAction(true)
        setTypeActionTab('view')
      } else {
        setTypeActionTab('edit')
      }
      let variables = encryptInput({ issueId: state.issueId })

      cellGet({ variables })
    }
  }, [state])

  useEffect(() => {
    if (dataGet) {
      const result = dataGet.auditResultIssueGet
      let personnelCode

      setRateFacProCode(result.rateFacProCode)
      setRateRiskRuleCode(result.rateRiskRuleCode)

      if (result.auditIssuePersonnels.length > 0) {
        personnelCode = result.auditIssuePersonnels.map((item) => {
          return item.personnelCode
        })
      }

      setISOCode(result.ISOCode)
      form.setFieldsValue({
        rateFacProCode: result.rateFacProCode,
        rateRiskRuleCode: result.rateRiskRuleCode,
        rateRiskConCode: result.rateRiskConCode,
        fact: displayText(result.factTH, result.factEN),
        issueHave: result.issueHave,
        issue: displayText(result.issueTH, result.issueEN),
        findingTypeCode: result.findingTypeCode,
        iSOCode: result.ISOCode,
        iSOCSubCode: result.ISOSubCode,
        riskLevelCode: result.riskLevelCode,
        showPoint: result.issueShow + '',
        risk: displayText(result.riskTH, result.riskEN),
        effect: displayText(result.effectTH, result.effectEN),
        personnelCode: personnelCode,
      })
    }

    if (errorGet) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorGet)),
        onOk() {},
      })
    }
  }, [dataGet])

  useEffect(() => {
    if (dataSave) {
      if (dataSave.auditResultSave.issueId) {
        Alert({
          type: 'success',
          title: t('บันทึกข้อมูลสำเร็จ'),
          onOk() {
            setTypeActionTab('edit')
            props.setIssueId(dataSave.auditResultSave.issueId)
          },
        })
      }
    }
    if (errorSave) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถบันทึกข้อมูลได้`),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [dataSave])

  // onFinish
  const onFinish = (values) => {
    fnConfirm(values)
  }

  const onSubmit = async (values) => {
    let dataInput = {
      issueId: state.issueId || '',
      actionPlanId: state.actionPlanId,
      projectCode: state.refCode,
      effectTH: values.effect || '',
      effectEN: values.effect || '',
      factTH: values.fact || '',
      factEN: values.fact || '',
      findingTypeCode: values.findingTypeCode || '',
      ISOSubCode: values.iSOCSubCode || '',
      ISOCode: values.iSOCode || '',
      issueTH: values.issue || '',
      issueEN: values.issue || '',
      personnelCode: values.personnelCode || '',
      rateFacProCode: values.rateFacProCode || '',
      rateRiskConCode: values.rateRiskConCode || '',
      rateRiskRuleCode: values.rateRiskRuleCode || '',
      riskTH: values.risk || '',
      riskEN: values.risk || '',
      riskLevelCode: values.riskLevelCode || '',
      issueShow: values.showPoint || '',
      lang: localStorage.getItem('lang') || '',
      typeAction: typeActionTab,
      issueHave: values.issueHave === 0 || values.issueHave === 1 ? values.issueHave : '',
    }

    await cellSave({ variables: { input: encryptInput(dataInput) } })
  }
  // onFinishFailed
  const onFinishFailed = () => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t('กรุณากรอกข้อมูลให้ครบถ้วน'),
      onOk() {},
    })
  }

  const fnConfirm = (values) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        onSubmit(values)
      },
      onCancel() {},
    })
  }

  return (
    <>
      {loadingSave || loadingGet ? <SpinnersNew /> : null}
      <IconDispaly>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          labelAlign="left"
          initialValues={{ showPoint: '1' }}
          labelCol={{ md: 8, lg: 8 }}
          wrapperCol={{ md: 24, lg: 16, align: 'left' }}
          layout="horizontal"
          name="riskFactorExamine_form"
        >
          <Row style={{ marginBottom: 20 }} justify="start">
            <Col md={2} lg={2}></Col>
            <Col md={22} lg={22}>
              <LabelNew type="sub-header-primary">{t('ผลการตรวจสอบ')}</LabelNew>
            </Col>
          </Row>
          <Row style={{ marginBottom: 24 }} justify="center">
            <Col md={24} lg={14}>
              <Form.Item
                name="rateFacProCode"
                label={<LabelNew type="tab-header-inactive">{t('ปัจจัยความเสี่ยง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุปัจจัยความเสี่ยง') }]}
              >
                <SelectRiskFactorExamine
                  disabled={typeAction}
                  projectCode={state.refCode}
                  filters={{ approveStatusCode: 20 }}
                  formname="riskFactorExamine_form"
                  placeholder={t('เลือกปัจจัยความเสี่ยง')}
                  handleChange={(e) => {
                    setRateFacProCode(e?.value || null)
                    setRateRiskRuleCode(null)
                    form.setFieldsValue({
                      rateFacProCode: e?.value || null,
                      rateRiskRuleCode: null,
                      rateRiskConCode: null,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="rateRiskRuleCode"
                label={<LabelNew type="tab-header-inactive">{t('เกณฑ์ระเบียบคำสั่ง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุเกณฑ์ระเบียบคำสั่ง') }]}
              >
                <SelectRateRiskRulesExamine
                  disabled={typeAction}
                  rateFacProCode={rateFacProCode}
                  placeholder={t('เลือกเกณฑ์ระเบียบคำสั่ง')}
                  formname="riskFactorExamine_form"
                  handleChange={(e) => {
                    setRateRiskRuleCode(e?.value || null)
                    form.setFieldsValue({ rateRiskRuleCode: e?.value || null, rateRiskConCode: null })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="rateRiskConCode"
                label={<LabelNew type="tab-header-inactive">{t('วิธีการควบคุม')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุวิธีการควบคุม') }]}
              >
                <SelectRateRiskControlExamine
                  disabled={typeAction}
                  rateRiskRuleCode={rateRiskRuleCode} // ข้อมูล test
                  placeholder={t('เลือกวิธีการควบคุม')}
                  formname="riskFactorExamine_form"
                  handleChange={(e) => {
                    form.setFieldsValue({ rateRiskConCode: e?.value || null })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="fact"
                label={<LabelNew type="tab-header-inactive">{t('ข้อเท็จจริง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุข้อเท็จจริง') }]}
              >
                <TextAreaNew
                  showCount
                  disabled={typeAction}
                  maxLength={5000}
                  rows={5}
                  placeholder={t('ระบุข้อเท็จจริง')}
                />
              </Form.Item>
              <Form.Item
                name="issueHave"
                label={<LabelNew type="tab-header-inactive">{t('การตรวจพบประเด็น')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาเลือกการตรวจพบประเด็น') }]}
              >
                <SelectIssueHave
                  disabled={typeAction}
                  placeholder={t('เลือกการตรวจพบประเด็น')}
                  formname="riskFactorExamine_form"
                  handleChange={(e) => form.setFieldsValue({ issueHave: e?.value })}
                />
              </Form.Item>
              <Form.Item
                name="issue"
                label={<LabelNew type="tab-header-inactive">{t('ข้อสังเกต')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุข้อสังเกต') }]}
              >
                <TextAreaNew
                  showCount
                  disabled={typeAction}
                  maxLength={3000}
                  rows={5}
                  placeholder={t('ระบุข้อสังเกต')}
                />
              </Form.Item>
              <Form.Item
                name="findingTypeCode"
                label={<LabelNew type="tab-header-inactive">{t('ประเภทของข้อสังเกตุที่ตรวจพบ')}</LabelNew>}
                // rules={[{ required: true, message: t('กรุณาระบุประเภทของข้อสังเกตุที่ตรวจพบ') }]}
              >
                <SelectAuditFindingType
                  disabled={typeAction}
                  placeholder={t('เลือกประเภทของข้อสังเกตุที่ตรวจพบ')}
                  formname="riskFactorExamine_form"
                  handleChange={(e) => {
                    form.setFieldsValue({ findingTypeCode: e?.value || null })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="iSOCode"
                label={<LabelNew type="tab-header-inactive">{t('ความสอดคล้อง')}</LabelNew>}
                // rules={[{ required: true, message: t('กรุณาระบุความสอดคล้อง') }]}
              >
                <SelectISO
                  disabled={typeAction}
                  placeholder={t('เลือกความสอดคล้อง')}
                  formname="riskFactorExamine_form"
                  handleChange={(e) => {
                    setISOCode(e?.value || null)
                    form.setFieldsValue({ iSOCode: e?.value || null, iSOCSubCode: null })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="iSOCSubCode"
                label={<LabelNew type="tab-header-inactive">{t('ความสอดคล้องย่อย')}</LabelNew>}
                // rules={[{ required: true, message: t('กรุณาระบุความสอดคล้องย่อย') }]}
              >
                <SelectISOSub
                  disabled={typeAction}
                  iSOCode={iSOCode}
                  placeholder={t('เลือกความสอดคล้องย่อย')}
                  formname="riskFactorExamine_form"
                  handleChange={(e) => {
                    form.setFieldsValue({ iSOCSubCode: e?.value || null })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="riskLevelCode"
                label={<LabelNew type="tab-header-inactive">{t('ระดับความเสี่ยง')}</LabelNew>}
                // rules={[{ required: true, message: t('กรุณาระบุระดับความเสี่ยง') }]}
              >
                <SelectRiskLevel
                  disabled={typeAction}
                  placeholder={t('เลือกระดับความเสี่ยง')}
                  formname="riskFactorExamine_form"
                  handleChange={(e) => {
                    form.setFieldsValue({ riskLevelCode: e?.value || null })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="showPoint"
                label={<LabelNew type="tab-header-inactive">{t('แสดงประเด็นนำเสนอ')}</LabelNew>}
              >
                <SelectShowPoint
                  disabled={typeAction}
                  placeholder={t('เลือกแสดงประเด็น')}
                  formname="riskFactorExamine_form"
                  handleChange={(e) => {
                    form.setFieldsValue({ showPoint: e?.value || null })
                  }}
                />
              </Form.Item>
              <Form.Item name="risk" label={<LabelNew type="tab-header-inactive">{t('ความเสี่ยง')}</LabelNew>}>
                <TextAreaNew
                  showCount
                  disabled={typeAction}
                  maxLength={300}
                  rows={5}
                  placeholder={t('ระบุความเสี่ยง')}
                />
              </Form.Item>
              <Form.Item name="effect" label={<LabelNew type="tab-header-inactive">{t('ผลกระทบ')}</LabelNew>}>
                <TextAreaNew showCount disabled={typeAction} maxLength={300} rows={5} placeholder={t('ระบุผลกระทบ')} />
              </Form.Item>
              <Form.Item
                name="personnelCode"
                label={<LabelNew type="tab-header-inactive">{t('ผู้รับผิดชอบ')}</LabelNew>}
              >
                <SelectActionPlanPersonnels
                  disabled={typeAction}
                  filters={{ projectCode: state.refCode }}
                  multiple
                  placeholder={t('เลือกผู้รับผิดชอบ')}
                  formname="riskFactorExamine_form"
                  handleChange={(e) => {
                    form.setFieldsValue({ personnelCode: e })
                  }}
                />
              </Form.Item>
              {/* <Form.Item name="conclusionCode" label={<LabelNew type="tab-header-inactive">{t('ข้อมูลมติ')}</LabelNew>}>
                <SelectMasterConclusions
                  disabled={typeAction}
                  placeholder={t('เลือกข้อมูลมติ')}
                  formname="riskFactorExamine_form"
                  handleChange={(e) => {
                    form.setFieldsValue({ conclusionCode: e?.value || null })
                  }}
                />
              </Form.Item> */}
            </Col>
          </Row>

          {/* ---------- */}
          <Row gutter={[16, 16]}>
            <Col {...Responesive} align="center">
              <Space size={[8, 8]} wrap>
                {state.type_actionSub !== 'view' ? (
                  <>
                    <ButtonNew type="primary" roles={{ type: state.type_actionSub, menu: menu }} htmlType="submit">
                      {t('บันทึก')}
                    </ButtonNew>
                  </>
                ) : (
                  ''
                )}
                &nbsp;
                <ButtonNew
                  type="back"
                  onClick={() => {
                    navigate(`/auditoperations/auditresult/TabOne`, {
                      state: state,
                    })
                  }}
                >
                  {t('ย้อนกลับ')}
                </ButtonNew>
              </Space>
            </Col>
          </Row>
        </Form>
      </IconDispaly>
    </>
  )
}

export default AuditResultIssue
