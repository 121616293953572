import { useMutation } from '@apollo/client'
import { Form, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '../../../components/alert/Alert'
import { InputNew } from '../../../components/input/Input'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectOrganizationChecked from '../../../components/inputfromapi/selectorganizationchecked/SelectOrganizationChecked'
import SelectOrganizationLevel from '../../../components/inputfromapi/selectorganizationlevel/SelectOrganizationLevel'
import SelectOrganizationSize from '../../../components/inputfromapi/selectorganizationsize/SelectOrganizationSize'
import SelectOrganizationType from '../../../components/inputfromapi/selectorganizationtype/SelectOrganizationType'
import { LabelNew as Label } from '../../../components/label/Label'
import { ModalNew } from '../../../components/modal/Modal'
import { Radio, RadioGroup } from '../../../components/radio/Radio'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError, regexEng, regexThai } from '../../../utilitys/helper'
import { CREATE_ORGANIZATION } from './graphql/Mutation'

const OrganizationCreate = (props) => {
  const { dataParams } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [stateDisabled, setStateDisabled] = useState(true)
  const [stateOrgLevel, setStateOrgLevel] = useState()
  const [stateRuleOrgChecked, setStateRuleOrgChecked] = useState(null)

  /* ---------------- API ----------- */
  const [
    createOrganizationFn,
    { loading: loadingCreateOrganization, error: errorCreateOrganization, data: dataCreateOrganization }
  ] = useMutation(CREATE_ORGANIZATION)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    setVisible(props.open)
  }, [props.open])

  useEffect(() => {
    if (dataCreateOrganization) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          props.refetch()
        }
      })
    }
    if (errorCreateOrganization) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorCreateOrganization)),
        onOk() {}
      })
    }
  }, [dataCreateOrganization])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    showConfirm(input)
  }
  const showConfirm = (input) => {
    let dataInput = {
      ...input,
      organizationNameEN: input.organizationNameEN ? input.organizationNameEN : input.organizationNameTH,
      organizationChecked: parseInt(input.organizationChecked) ? parseInt(input.organizationChecked) : '',
      organizationParentCode: input.organizationParentCode ? input.organizationParentCode : '',
      organizationSizeCode: input.organizationSizeCode ? input.organizationSizeCode : '',
      isActive: parseInt(input.isActive)
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        createOrganizationFn({
          variables: {
            input: encryptInput(dataInput)
          }
        })
        form.resetFields()
        setVisible(false)
        props.close(false)
      },
      onCancel() {}
    })
  }

  function onClose(e) {
    form.resetFields()
    setVisible(e)
    setStateDisabled(true)
    props.close(e)
  }
  const onInputValueChange = (e) => {
    if (e.value === 2) {
      setStateDisabled(true)
      form.setFieldsValue({
        organizationParentCode: null,
        organizationLevelCode: null,
        organizationSizeCode: null,
        organizationChecked: null
      })
    } else {
      setStateDisabled(false)
    }
  }
  return (
    <>
      {loadingCreateOrganization && <SpinnersNew />}
      <ModalNew
        visible={visible}
        typeAction={dataParams?.type_action}
        testTitle={t('เพิ่มหน่วยงาน')}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        type="medium"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2
          }}
          wrapperCol={{
            span: 10
          }}
          layout="horizontal"
          name="organizationForm"
        >
          <Form.Item
            name="organizationCode"
            label={<Label type="tab-header-inactive">{t('รหัสหน่วยงาน')}</Label>}
            rules={[
              { required: true, message: t('กรุณากรอกรหัสหน่วยงาน') },
              {
                pattern: regexEng,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`)
              }
            ]}
          >
            <InputNew placeholder={t('รหัสหน่วยงาน')} />
          </Form.Item>
          <Form.Item
            name="organizationNameTH"
            label={<Label type="tab-header-inactive">{t('ชื่อหน่วยงานภาษาไทย')}</Label>}
            rules={[
              { required: true, message: t('กรุณากรอกชื่อหน่วยงานภาษาไทย') },
              {
                pattern: regexThai,
                message: t(`กรุณากรอกด้วยภาษาไทยหรือตัวเลข ก-๙, 0-9`)
              }
            ]}
          >
            <InputNew placeholder={t('ชื่อหน่วยงานภาษาไทย')} />
          </Form.Item>
          <Form.Item
            name="organizationNameEN"
            label={<Label type="tab-header-inactive">{t('ชื่อหน่วยงานภาษาอังกฤษ')}</Label>}
            rules={[
              {
                pattern: regexEng,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`)
              }
            ]}
          >
            <InputNew placeholder={t('ชื่อหน่วยงานภาษาอังกฤษ')} />
          </Form.Item>
          <Form.Item
            name="organizationType"
            label={<Label type="tab-header-inactive">{t('ประเภทหน่วยงาน')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกประเภทหน่วยงาน') }]}
          >
            <SelectOrganizationType
              formname="organizationForm"
              placeholder={t('เลือกประเภทหน่วยงาน')}
              handleChange={async (e) => {
                await setStateRuleOrgChecked(e?.value)
                await form.setFieldsValue({ organizationType: e?.value })
                await onInputValueChange(e)
              }}
            />
          </Form.Item>
          <Form.Item
            name="organizationLevelCode"
            label={<Label type="tab-header-inactive">{t('ระดับหน่วยงาน')}</Label>}
            rules={[stateDisabled ? {} : { required: true, message: t('กรุณาเลือกระดับหน่วยงาน') }]}
          >
            <SelectOrganizationLevel
              formname="organizationForm"
              placeholder={t('เลือกระดับหน่วยงาน')}
              handleChange={(e) => {
                form.setFieldsValue({ organizationLevelCode: e?.value })
                setStateOrgLevel(e?.value)
              }}
              disabled={stateDisabled}
            />
          </Form.Item>
          <Form.Item
            name="organizationParentCode"
            label={<Label type="tab-header-inactive">{t('สังกัดหน่วยงาน')}</Label>}
          >
            <SelectOrganization
              formname="organizationForm"
              placeholder={t('เลือกสังกัดหน่วยงาน')}
              filters={{ lessOrganizationLevelCode: parseInt(stateOrgLevel) }}
              handleChange={(e) => {
                form.setFieldsValue({ organizationParentCode: e?.value })
              }}
              disabled={stateDisabled}
            />
          </Form.Item>
          <Form.Item name="organizationSizeCode" label={<Label type="tab-header-inactive">{t('ขนาดหน่วยงาน')}</Label>}>
            <SelectOrganizationSize
              formname="organizationForm"
              placeholder={t('เลือกขนาดหน่วยงาน')}
              handleChange={(e) => {
                form.setFieldsValue({ organizationSizeCode: e?.value })
              }}
              disabled={stateDisabled}
            />
          </Form.Item>
          <Form.Item
            name="organizationChecked"
            label={<Label type="tab-header-inactive">{t('หน่วยรับตรวจ')}</Label>}
            rules={stateRuleOrgChecked === 2 ? [] : [{ required: true, message: t('กรุณาเลือกหน่วยรับตรวจ') }]}
          >
            <SelectOrganizationChecked
              formname="organizationForm"
              placeholder={t('เลือกหน่วยรับตรวจ')}
              handleChange={(e) => {
                form.setFieldsValue({ organizationChecked: e?.value })
              }}
              disabled={stateDisabled}
            />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
            <RadioGroup>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                &nbsp;
                <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
export default OrganizationCreate
