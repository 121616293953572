import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Row, Col, Form, Space } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { displayText, displayError } from '../../../utilitys/helper'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew as Card } from '../../../components/card/Card'
import { LabelNew as Label } from '../../../components/label/Label'
import { InputNew as Input } from '../../../components/input/Input'
import { TextAreaNew as TextArea } from '../../../components/textarea/TextArea'
import { Select } from '../../../components/select/Select'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { Timepicker } from '../../../components/timepicker/Timepicker'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
import { ButtonNew as Button } from '../../../components/button/Button'
import { Alert } from '../../../components/alert/Alert'
import { SelectAuditProjectsClosed } from '../../../components/inputfromapi/selectauditprojects/SelectAuditProjects'
import SelectPersonnels from '../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import SelectMasterConsiders from '../../../components/inputfromapi/selectmasterconsiders/SelectMasterConsiders'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import AcResolutionAgendaUpload from './components/upload/AcResolutionAgendaUpload'
import { useLazyQuery, useMutation } from '@apollo/client'
import { encryptInput } from '../../../utilitys/crypto'
import { SpinnersNew as Spinner } from '../../../components/spinners/Spinners'
import { GET_AUDIT_RESOLUTION_AC_BY_ID, GET_PERSONNEL_BY_PERSONNEL_CODE } from './graphql/Query'
import { v4 } from 'uuid'
import { CREATE_AUDIT_RESOLUTION_AC, UPDATE_AUDIT_RESOLUTION_AC } from './graphql/Mutation'

const AcResolutionAgenda = ({ menu }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { detailPageAction, agendaPageAction, resId, acResolutionDetail, agendaId } = useLocation().state
  const [user] = useDecodeUser()
  const systemLanguage = localStorage.getItem('lang')
  const arrCrumb = [
    { label: t('งานปฎิบัติการตรวจสอบ'), path: '/auditoperations/acresolution' },
    { label: t('บันทึกมติคณะกรรมการตรวจสอบ') },
  ]

  const [fileData, setFileData] = useState([])
  const [jobType, setJobType] = useState(0)

  const isDisabled = ['add', 'edit'].includes(agendaPageAction) ? false : true
  const [form] = Form.useForm()
  const formName = 'agendaDetail'

  const [getAuditResolutionACById, getAuditResolutionACByIdResult] = useLazyQuery(GET_AUDIT_RESOLUTION_AC_BY_ID)
  const [getPersonnelByPersonnelCode, getPersonnelByPersonnelCodeResult] = useLazyQuery(GET_PERSONNEL_BY_PERSONNEL_CODE)
  const [createAuditResolutionAC, createAuditResolutionACResult] = useMutation(CREATE_AUDIT_RESOLUTION_AC)
  const [updateAuditResolutionAC, updateAuditResolutionACResult] = useMutation(UPDATE_AUDIT_RESOLUTION_AC)

  const onSubmit = (data) => {
    const files = Object.values(data).filter((x) => x?.filename)
    const completedFileData = fileData.map((item) => {
      const itemData = files.find((subFile) => subFile.fileId === item.fileId)
      return { ...item, filename: itemData?.filename, links: itemData?.links }
    })

    let detail = {
      resId: resId,
      copyType: data.copyType,
      jobType: data.jobType,
      projectCode: data.projectCode || '',
      organizationCode: data.organizationCode || '',
      jobName: data.jobName || null,
      agendaTime: data.agendaTime,
      agendaName: data.agendaName,
      agendaTimeSub: data.agendaTimeSub,
      agendaSubName: data.agendaSubName,
      recipients: data.recipients,
      considerCode: data.considerCode,
      creators: data?.creators || null,
      speakers: data?.speakers || null,
      meetingTime: data.meetingTime,
      meetingDetail: data.meetingDetail || null,
      resolutionAC: data.resolutionAC || null,
      meetingDate: data.meetingDate,
      meetingTimes: data?.meetingTimes
        ? moment().format('YYYY-MM-DD') + ' ' + data.meetingTimes
        : moment().format('YYYY-MM-DD'),
      auditor: data.auditor || null,
      isActive: data.isActive,
      files: files.map((x) => x.fileId).join(),
      fileDetail: JSON.stringify(completedFileData),
    }

    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกมติคณะกรรมการตรวจสอบ'),
      onOk: async () => {
        if (agendaPageAction === 'add') {
          await createAuditResolutionAC({
            variables: {
              userId: encryptInput(user.userId),
              systemLanguage: encryptInput(systemLanguage),
              detail: encryptInput(detail),
            },
          })
            .then((response) => {
              if (response.data?.createAuditResolutionAC === true) {
                Alert({
                  type: 'success',
                  title: t('เพิ่มข้อมูลสำเร็จ'),
                  onOk() {
                    navigate('/auditoperations/acresolution/detail', {
                      state: { detailPageAction: detailPageAction, resId: resId },
                    })
                  },
                })
              }
            })
            .catch((error) =>
              Alert({
                type: 'error',
                title: t('ไม่สามารถเพิ่มข้อมูลได้'),
                content: t(error),
                onOk() {},
              }),
            )
        } else if (agendaPageAction === 'edit') {
          await updateAuditResolutionAC({
            variables: {
              userId: encryptInput(user.userId),
              agendaId: encryptInput(agendaId),
              systemLanguage: encryptInput(systemLanguage),
              detail: encryptInput(detail),
            },
          })
            .then((response) => {
              if (response.data?.updateAuditResolutionAC === true) {
                Alert({
                  type: 'success',
                  title: t('แก้ไขข้อมูลสำเร็จ'),
                  onOk() {
                    navigate('/auditoperations/acresolution/detail', {
                      state: { detailPageAction: detailPageAction, resId: resId },
                    })
                  },
                })
              }
            })
            .catch((error) =>
              Alert({
                type: 'error',
                title: t('ไม่สามารถแก้ไขข้อมูลได้'),
                content: t(error),
                onOk() {},
              }),
            )
        }
      },
      onCancel() {
        return null
      },
    })
  }

  useEffect(() => {
    if (agendaPageAction !== 'add') {
      getAuditResolutionACById({
        variables: {
          agendaId: encryptInput(agendaId),
        },
      })
    }
    form.setFieldsValue({
      meetingTime: acResolutionDetail.resTimes,
      meetingDate: acResolutionDetail.resDate,
    })
  }, [])

  useEffect(() => {
    if (getAuditResolutionACByIdResult.data) {
      const data = getAuditResolutionACByIdResult.data.getAuditResolutionACById

      if (data?.files && data?.fileDetail) {
        const files = data.files.split(',')
        const fileDetails = JSON.parse(data.fileDetail)
        setFileData(
          files.map((file) => {
            const { filename, links, fileId, tools } = fileDetails.find(
              (fileDetail) => fileDetail.fileId.toString() === file.toString(),
            )
            return {
              key: v4(),
              filename: filename,
              links: links,
              fileId: fileId,
              tools: tools,
            }
          }),
        )
      }

      form.setFieldsValue({
        ...data,
        organizationCode: data.organization?.organizationCode,
        organizationName: displayText(data.organization?.organizationNameTH, data.organization?.organizationNameEN),
        agendaName: displayText(data.agendaNameTH, data.agendaNameEN),
        agendaSubName: displayText(data.agendaSubNameTH, data.agendaSubNameEN),
        jobName: displayText(data.jobNameTH, data.jobNameEN),
        recipients: data.auditResolutionPersonnels?.reduce((filtered, item) => {
          if (item.personnelType === 1) {
            filtered.push(item.personnelCode)
          }
          return filtered
        }, []),
        speakers: data.auditResolutionPersonnels?.reduce((filtered, item) => {
          if (item.personnelType === 2) {
            filtered.push(item.personnelCode)
          }
          return filtered
        }, []),
        creators: data.auditResolutionOrganizations?.map((item) => {
          return item.organizationCode
        }),
        meetingDetail: displayText(data.meetingDetailTH, data.meetingDetailEN),
        resolutionAC: displayText(data.resolutionACTH, data.resolutionACEN),
        meetingTimes: moment(data.meetingTimes).format('HH:mm:00'),
        createdBy: displayText(
          data.systemUsers?.personnels?.firstNameTH + ' ' + data.systemUsers?.personnels?.lastNameTH,
          data.systemUsers?.personnels?.firstNameEN + ' ' + data.systemUsers?.personnels?.lastNameEN,
        ),
      })

      setJobType(data.jobType)
    }
  }, [getAuditResolutionACByIdResult.data])

  useEffect(() => {
    if (getPersonnelByPersonnelCodeResult.data) {
      const data = getPersonnelByPersonnelCodeResult.data.getPersonnelsByPersonnelCode

      form.setFieldsValue({
        createdBy: displayText(data.firstNameTH + ' ' + data.lastNameTH, data.firstNameEN + ' ' + data.lastNameEN),
      })
    } else {
      form.setFieldsValue({
        createdBy: user?.fullname,
      })
    }
  }, [getPersonnelByPersonnelCodeResult.data])

  useEffect(() => {
    if (user && agendaPageAction === 'add') {
      getPersonnelByPersonnelCode({
        variables: {
          personnelCode: encryptInput(user.pCode),
        },
      })
    }
  }, [user])

  useEffect(() => {
    if (getAuditResolutionACByIdResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getAuditResolutionACByIdResult.error)),
        onOk() {},
      })
    }
  }, [getAuditResolutionACByIdResult.error])

  useEffect(() => {
    if (getPersonnelByPersonnelCodeResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getPersonnelByPersonnelCodeResult.error)),
        onOk() {},
      })
    }
  }, [getPersonnelByPersonnelCodeResult.error])

  useEffect(() => {
    if (createAuditResolutionACResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถเพิ่มข้อมูลได้'),
        content: t(displayError(createAuditResolutionACResult.error)),
        onOk() {},
      })
    }
  }, [createAuditResolutionACResult.error])

  useEffect(() => {
    if (updateAuditResolutionACResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถแก้ไขข้อมูลได้'),
        content: t(displayError(updateAuditResolutionACResult.error)),
        onOk() {},
      })
    }
  }, [updateAuditResolutionACResult.error])

  return (
    <>
      {(getAuditResolutionACByIdResult.loading ||
        createAuditResolutionACResult.loading ||
        updateAuditResolutionACResult.loading) && <Spinner />}
      <Breadcrumb data={arrCrumb} title={t('บันทึกมติคณะกรรมการตรวจสอบ')} />
      <Card topic={t('รายละเอียดบันทึกมติกรรมการการตรวจสอบ')} icon="List">
        <Form
          form={form}
          onFinish={onSubmit}
          name={formName}
          labelAlign="left"
          labelCol={{
            sm: 24,
            md: 4,
          }}
          wrapperCol={{
            sm: 24,
            md: 20,
          }}
        >
          <Row>
            <Label type="body-header">{t('รายละเอียดทั่วไป')}</Label>
          </Row>
          <Row style={{ marginTop: 36 }}>
            <Col offset={2} md={20}>
              <Form.Item
                name="copyType"
                label={<Label type="tab-header-inactive">{t('ประเภทฉบับ')}</Label>}
                rules={[{ required: true, message: t('กรุณาเลือกประเภทฉบับ') }]}
              >
                <Select
                  formname={formName}
                  data={[
                    { label: 'ฉบับพิจารณา', value: 1 },
                    { label: 'ฉบับรับรอง', value: 2 },
                  ]}
                  placeholder={t('กรุณาเลือกประเภทฉบับ')}
                  scrollableId={formName}
                  disabled={isDisabled}
                />
              </Form.Item>
              <Form.Item
                name="jobType"
                label={<Label type="tab-header-inactive">{t('ประเภทงาน')}</Label>}
                rules={[{ required: true, message: t('กรุณาเลือกประเภทงาน') }]}
              >
                <Select
                  formname={formName}
                  data={[
                    { label: 'งานโครงการ', value: 1 },
                    { label: 'งานอื่น', value: 2 },
                  ]}
                  placeholder={t('กรุณาเลือกประเภทงาน')}
                  scrollableId={formName}
                  disabled={agendaPageAction === 'edit' || isDisabled}
                  onChange={(value) => {
                    setJobType(value)
                    form.setFieldsValue({
                      projectCode: null,
                      jobName: null,
                      organizationCode: null,
                      organizationName: null,
                    })
                  }}
                />
              </Form.Item>
              {jobType === 1 && (
                <>
                  <Form.Item
                    name="projectCode"
                    label={<Label type="tab-header-inactive">{t('โครงการ')}</Label>}
                    rules={[{ required: true, message: t('กรุณาเลือกโครงการ') }]}
                  >
                    <SelectAuditProjectsClosed
                      placeholder={t('เลือกโครงการ')}
                      formname={formName}
                      disabled={isDisabled}
                      handleChange={(e) => {
                        form.setFieldsValue({
                          projectCode: e?.value,
                          organizationName: displayText(e?.source.organizationNameTH, e?.source.organizationNameEN),
                          organizationCode: e?.source.organizationCode,
                        })
                      }}
                    />
                  </Form.Item>
                  <Form.Item hidden={true} name="organizationCode">
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="organizationName"
                    label={<Label type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</Label>}
                  >
                    <Input maxLength={200} placeholder={t('ระบุหน่วยงานที่รับผิดชอบ')} disabled={true} />
                  </Form.Item>
                </>
              )}
              {jobType === 2 && (
                <>
                  <Form.Item
                    name="jobName"
                    label={<Label type="tab-header-inactive">{t('ชื่องาน')}</Label>}
                    rules={[{ required: true, message: t('กรุณาระบุชื่องาน') }]}
                  >
                    <Input maxLength={200} placeholder={t('ระบุชื่องาน')} disabled={isDisabled} />
                  </Form.Item>
                  <Form.Item
                    name="organizationCode"
                    label={<Label type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</Label>}
                    rules={[{ required: true, message: t('กรุณาเลือกหน่วยงานที่รับผิดชอบ') }]}
                  >
                    <SelectOrganization
                      formname={formName}
                      optionLabelProp="label"
                      placeholder={t('เลือกหน่วยงานที่รับผิดชอบ')}
                      filters={{ organizationChecked: 2 }}
                      disabled={isDisabled}
                      handleChange={(e) => form.setFieldsValue({ organizationCode: e.value })}
                    />
                  </Form.Item>
                </>
              )}
              <Form.Item
                name="agendaTime"
                label={<Label type="tab-header-inactive">{t('วาระที่')}</Label>}
                rules={[
                  { required: true, message: t('กรุณาระบุวาระที่') },
                  { pattern: new RegExp(/^(\d+)*$/), message: t('อนุญาตให้กรอกเฉพาะตัวเลขเท่านั้น') },
                ]}
              >
                <Input maxLength={200} placeholder={t('กรุณาระบุวาระที่')} disabled={isDisabled} />
              </Form.Item>
              <Form.Item
                name="agendaName"
                label={<Label type="tab-header-inactive">{t('ชื่อวาระ')}</Label>}
                rules={[{ required: true, message: t('กรุณาระบุชื่อวาระ') }]}
              >
                <Input maxLength={200} placeholder={t('กรุณาระบุชื่อวาระ')} disabled={isDisabled} />
              </Form.Item>
              <Form.Item
                name="agendaTimeSub"
                label={<Label type="tab-header-inactive">{t('วาระย่อยที่')}</Label>}
                rules={[{ required: true, message: t('กรุณาระบุวาระย่อยที่') }]}
              >
                <Input maxLength={200} placeholder={t('กรุณาระบุวาระย่อยที่')} disabled={isDisabled} />
              </Form.Item>
              <Form.Item
                name="agendaSubName"
                label={<Label type="tab-header-inactive">{t('วาระย่อย')}</Label>}
                rules={[{ required: true, message: t('กรุณาระบุวาระย่อย') }]}
              >
                <Input maxLength={200} placeholder={t('กรุณาระบุวาระย่อย')} disabled={isDisabled} />
              </Form.Item>
              <Form.Item
                name="recipients"
                label={<Label type="tab-header-inactive">{t('เรียน')}</Label>}
                rules={[{ required: true, message: t('กรุณาระบุบุคคลที่จะเรียนถึง') }]}
              >
                <SelectPersonnels
                  formname={formName}
                  multiple={true}
                  placeholder={t('กรุณาระบุบุคคลที่จะเรียนถึง')}
                  filters={{ levelCode: 12 }}
                  disabled={isDisabled}
                  handleChange={(e) => form.setFieldsValue({ recipients: e })}
                />
              </Form.Item>
              <Form.Item
                name="considerCode"
                label={<Label type="tab-header-inactive">{t('เพื่อ')}</Label>}
                rules={[{ required: true, message: t('กรุณาระบุการพิจารณา') }]}
              >
                <SelectMasterConsiders
                  formname={formName}
                  placeholder={t('กรุณาระบุการพิจารณา')}
                  disabled={isDisabled}
                  handleChange={(e) => form.setFieldsValue({ considerCode: e?.value })}
                />
              </Form.Item>
              <Form.Item
                name="creators"
                label={<Label type="tab-header-inactive">{t('จัดทำเอกสารโดย')}</Label>}
                // rules={[{ required: true, message: t('กรุณาเลือกหน่วยงานที่จัดทำเอกสาร') }]}
              >
                <SelectOrganization
                  placeholder={t('เลือกหน่วยงานที่จัดทำเอกสาร')}
                  formname={formName}
                  multiple={true}
                  optionLabelProp="label"
                  filters={{ isCreatorsInputInAcresolution: true }}
                  disabled={isDisabled}
                  handleChange={(e) => {
                    form.setFieldsValue({ creators: e })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="speakers"
                label={<Label type="tab-header-inactive">{t('นำเสนอโดย')}</Label>}
                // rules={[{ required: true, message: t('กรุณาระบุผู้นำเสนอ') }]}
              >
                <SelectPersonnels
                  placeholder={t('เลือกผู้นำเสนอ')}
                  multiple={true}
                  formname={formName}
                  filters={{ personnaloperationCode: 10 }}
                  disabled={isDisabled}
                  handleChange={(e) => form.setFieldsValue({ speakers: e })}
                />
              </Form.Item>
              <Form.Item
                name="meetingTime"
                label={<Label type="tab-header-inactive">{t('มติการตรวจสอบ ครั้งที่')}</Label>}
                // rules={[{ required: true, message: t('กรุณาระบุครั้งที่ตรวจสอบ') }]}
              >
                <Input maxLength={200} placeholder={t('กรอกมติการตรวจสอบครั้งที่')} disabled={true} />
              </Form.Item>
              <Form.Item
                name="meetingDetail"
                label={<Label type="tab-header-inactive">{t('รายละเอียดการประชุม')}</Label>}
                // rules={[{ required: true, message: t('กรุณาระบุรายละเอียดการประชุม') }]}
              >
                <TextArea placeholder={t('กรอกรายละเอียดการประชุม')} disabled={isDisabled} />
              </Form.Item>
              <Form.Item
                name="resolutionAC"
                label={<Label type="tab-header-inactive">{t('มติที่ประชุม')}</Label>}
                // rules={[{ required: true, message: t('กรุณาระบุมติที่ประชุม') }]}
              >
                <TextArea placeholder={t('กรอกมติที่ประชุม')} disabled={isDisabled} />
              </Form.Item>
              <Form.Item
                name="meetingDate"
                label={<Label type="tab-header-inactive">{t('วันที่ประชุม')}</Label>}
                // rules={[{ required: true, message: t('กรุณาระบุวันที่ประชุม') }]}
              >
                <Datepicker
                  setValueDateFn={async (data) => {
                    form.setFieldsValue({ meetingDate: await data })
                  }}
                  onChange={() => {}}
                  disabled={true}
                  onClear={() => {
                    form.setFieldsValue({ meetingDate: null })
                  }}
                  placeholder={t('เลือกวันที่ประชุม')}
                />
              </Form.Item>
              <Form.Item
                name="meetingTimes"
                label={<Label type="tab-header-inactive">{t('เวลาที่ประชุม')}</Label>}
                // rules={[
                //   {
                //     required: true,
                //     message: t('กรุณาระบุเวลาที่ประชุม'),
                //   },
                // ]}
              >
                <Timepicker
                  disabled={isDisabled}
                  form={form}
                  onChangeTime={(time) => {
                    if (time) {
                      form.setFieldsValue({ meetingTimes: moment(time).format('HH:mm:00') })
                    } else {
                      form.setFieldsValue({ meetingTimes: null })
                    }
                  }}
                />
              </Form.Item>
              <Form.Item name="createdBy" label={<Label type="tab-header-inactive">{t('ผู้บันทึก')}</Label>}>
                <Input maxLength={200} placeholder={t('')} disabled={true} />
              </Form.Item>
              <Form.Item
                name="auditor"
                label={<Label type="tab-header-inactive">{t('ผู้ตรวจ')}</Label>}
                // rules={[{ required: true, message: t('กรุณาระบุผู้ตรวจ') }]}
              >
                <SelectPersonnels
                  formname={formName}
                  placeholder={t('กรุณาระบุผู้ตรวจ')}
                  filters={{ levelCode: 12, organizationChecked: 2 }}
                  disabled={isDisabled}
                  handleChange={(e) => form.setFieldsValue({ auditor: e?.value })}
                />
              </Form.Item>
              <Form.Item
                label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>}
                name="isActive"
                initialValue={1}
              >
                <RadioGroup disabled={isDisabled}>
                  <Space size={[8, 8]} wrap>
                    <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                    &nbsp;
                    <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
                  </Space>
                </RadioGroup>
              </Form.Item>

              <AcResolutionAgendaUpload
                menu={menu}
                form={form}
                fileData={fileData}
                setFileData={setFileData}
                isDisabled={isDisabled}
                action={agendaPageAction}
              />
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            {!isDisabled && (
              <Col>
                <Button
                  type="primary"
                  // onClick={() => {
                  //   form.setFieldsValue({ consultStatus: 0 })
                  // }}
                  menu={menu}
                  htmlType="submit"
                  roles={{ type: agendaPageAction, menu: menu }}
                >
                  {t('บันทึก')}
                </Button>
              </Col>
            )}
            <Col>
              <Button
                type="back"
                onClick={() => {
                  navigate('/auditoperations/acresolution/detail', {
                    state: { detailPageAction: detailPageAction, resId: resId },
                  })
                }}
              >
                {t('ย้อนกลับ')}
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
    </>
  )
}

export default AcResolutionAgenda
