import React from 'react'
import { Row, Col } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import PermissionsList from './PermissionsList'
import { useTranslation } from 'react-i18next'
/**
 * @function PermissionIndex
 * @description
 * Menu: Permissions
 * เมนู: จัดการกลุ่มสิทธิผู้ใช้งาน
 * @returns Component
 */

const PermissionIndex = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const arrCrumb = [{ label: t('จัดการข้อมูลพื้นฐาน'), path: '#' }, { label: t('จัดการกลุ่มสิทธิผู้ใช้งาน') }]
  const Responesive = {
    sm: 24,
    md: 24,
    lg: 24,
  }
  return (
    <React.Fragment>
      <Row>
        <Col {...Responesive}>
          <Breadcrumb data={arrCrumb} title={t('จัดการกลุ่มสิทธิผู้ใช้งาน')} />
          <PermissionsList menu={menu} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default PermissionIndex
