import { gql } from '@apollo/client'

export const TASK_IN_RESPONSIBILITY_EXPORT = gql`
  query taskInResponsibilityExport($input: TaskInResponsibilityReportInput!) {
    taskInResponsibilityExport(input: $input) {
      message
      fileUrl
    }
  }
`

export const GET_TASK_IN_RESPONSIBILITY_PAGINATION = gql`
  query getTaskInResponsibilityPagination($input: TaskInResponsibilityReportPaginationInput!) {
    getTaskInResponsibilityPagination(input: $input) {
      result {
        no
        personnelCode
        fullName
        organizationName
        groupName
        typeName
        jobAndProjectCode
        jobAndProjectName
        dateStart
        dateEnd
        manday
      }
      count
      page
      limit
    }
  }
`
