import { gql } from '@apollo/client'

export const GET_RESULT_ASSESSMENT_PAGINATION = gql`
  query getResultAssessmentPagination($input: ResultAssessmentPaginationInput!) {
    getResultAssessmentPagination(input: $input) {
      result {
        formId
        formCode
        formNameTH
        formNameEN
        createdBy
        formTypeCode
        formTypeNameTH
        formTypeNameEN
        operationStatusCode
        operationStatusNameTH
        operationStatusNameEN
        projectNameTH
        projectNameEN
        formAssessId
      }
      count
      page
      limit
    }
  }
`

export const GET_SUB_RESULT_ASSESSMENT = gql`
  query getSubResultAssessment($input: SubResultAssessmentInput!) {
    getSubResultAssessment(input: $input) {
      formResultId
      formAssessId
      formCode
      formYear
      formNameTH
      formNameEN
      organizationCode
      organizationNameTH
      organizationNameEN
      operationStatusCode
      operationStatusNameTH
      operationStatusNameEN
      responsibilityBy
      reviewAt
      fullnameTH
      fullnameEN
    }
  }
`

export const EXPORT_RESULT_ASSESSMENT = gql`
  query exportResultAssessment($input: ResultAssessmentExportInput!) {
    exportResultAssessment(input: $input) {
      message
      fileUrl
    }
  }
`

export const GETGROUPSANDQUESTIONSBY_RESULT_ASSESSMENT = gql`
  query getGroupsAndQuestionsByResultAssessment($input: ResultAssessmentInput!) {
    getGroupsAndQuestionsByResultAssessment(input: $input) {
      resultDetailId
      formResultId
      formGroupId
      formCode
      formSort
      formGroupNameTH
      formGroupNameEN
      formQuestId
      formSortByQ
      formQuestNameTH
      formQuestNameEN
      choiceTypeCode
      choiceGroupCode
      formQuestType
      formRequired
      files
      choiceDetailId
      answerDetail
      choiceDetailNameTH
      choiceDetailNameEN
      fileNameLabel
      fileNameGen
      fileType
      fileByte
      filepath
    }
  }
`

export const GETMASTERCHOICEGROUPDETAILBY_RESULT_ASSESSMENT = gql`
  query getMasterChoiceGroupDetailByResultAssessment($isActive: Float!) {
    getMasterChoiceGroupDetailByResultAssessment(isActive: $isActive) {
      choiceDetailId
      choiceDetailNameTH
      choiceDetailNameEN
      choiceGroupCode
      score
      isActive
    }
  }
`
