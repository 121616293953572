import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Row, Col, Form, Input, Space, Tabs } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { displayText } from '../../../../../utilitys/helper'
import { CardNew } from '../../../../../components/card/Card'
import { InputNew } from '../../../../../components/input/Input'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'
import { Radio, RadioGroup } from '../../../../../components/radio/Radio'
import { ButtonNew } from '../../../../../components/button/Button'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { Status } from '../../../../../components/status/Status'
import { LabelNew as Label } from '../../../../../components/label/Label'
import SelectOperationStatus from '../../../../../components/inputfromapi/selectoperationstatus/SelectOperationStatus'
import SelectYear from '../../../../../components/inputfromapi/selectyear/SelectYear'
import TabStyle from '../../../../../components/tab/TabStyles'

const ControlFormAllEdit = (props) => {
  const responsiveCol = { sm: 24, md: 24, lg: 24 }
  const wrapperForm = { md: 24, lg: 24, xl: 24 }
  const wrapperSubForm = { sm: 24, md: 24, lg: 12, xl: 12 }

  const { form, formname, orgList, onDeleteOrg, masterChoiceTypes, questDetailTypes, menu } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [disabled, setDisabled] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [dataConfigQuest, setDataConfigQuest] = useState([])
  const [isMasterChoiceTypes, isSetMasterChoiceTypes] = useState(null)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)

  useEffect(() => {
    switch (props.activeType) {
      case 'view':
        setDisabled(true)
        break
      case 'edit':
      case 'createQuestion':
      case 'copy':
        setDisabled(false)
        break
      default:
        setDisabled(false)
        break
    }
  }, [props.activeType])

  useEffect(() => {
    masterChoiceTypes && isSetMasterChoiceTypes(masterChoiceTypes)
  }, [masterChoiceTypes])

  useEffect(() => {
    form.setFieldsValue({ organizationList: JSON.stringify(dataLists) })
  }, [dataLists])

  useEffect(() => {
    setDataLists([...orgList])
  }, [orgList])

  useEffect(() => {
    const setDataTable = []

    for (let c of _.sortBy(props.isConfiguration, 'formConfigNo')) {
      setDataTable.push({
        key: c.key,
        formComfig: `${c.formConfigNo}. ${displayText(c.formConfigTH, c.formConfigEN)}`,
        formConfigEN: c.formConfigEN,
        formConfigTH: c.formConfigTH,
        formConfigNo: c.formConfigNo,
        question: '',
        choiceTypeCode: '',
        questDetailType: '',
        isActive: c.isActive,
        type: 'config',
      })

      let quest = props.isQuestion.filter((q) => q.formConfigNo === c.formConfigNo)
      if (quest.length) {
        let indexQ = 1
        for (let q of quest) {
          setDataTable.push({
            key: q.key,
            formComfig: '',
            formConfigNo: q.formConfigNo,
            question: `${c.formConfigNo}.${indexQ} ${displayText(q.questTH, q.questEN)}`,
            questEN: q.questEN,
            questTH: q.questTH,
            choiceGroupCode: q.choiceGroupCode,
            questRequired: q.questRequired,
            choiceTypeCode: q.choiceTypeCode,
            questDetailType: questDetailTypes && questDetailTypes[q.questDetailType],
            isActive: q.isActive,
            type: 'quest',
          })
          indexQ++
        }
      }
    }
    setDataConfigQuest(setDataTable)
  }, [props.isQuestion, props.isConfiguration, questDetailTypes])

  const columnsOrganization = [
    { title: t('ลำดับ'), dataIndex: 'key', width: '10%', render: (row, x, index) => index + 1 },
    { title: t('รหัสหน่วยงาน'), dataIndex: 'organizationCode', width: '15%' },
    { title: t('หน่วยงาน'), dataIndex: 'organizationName', width: '20%' },
    { title: t('ระดับหน่วยงาน'), dataIndex: 'organizationLevelName', width: '25%' },
    { title: t('ผู้รับผิดชอบ'), dataIndex: 'personnelName', width: '25%' },
    {
      title: '',
      dataIndex: 'organizationId',
      width: '5%',
      render: (text, record) => {
        return (
          <div style={{ textAlign: 'center', display: `${disabled ? 'none' : 'block'}` }}>
            <ButtonGroup
              menu={menu}
              size={18}
              icons={[
                {
                  type: 'delete',
                  onClick: () => {
                    if (dataLists.length > 1) {
                      let filtered = [..._.filter(dataLists, (e) => e.organizationCode !== record.organizationCode)]
                      setDataLists(filtered)
                      onDeleteOrg(filtered)
                    }
                  },
                },
              ]}
            />
          </div>
        )
      },
    },
  ]

  const columnsQuestion = [
    { title: t('องค์ประกอบควบคุม'), dataIndex: 'formComfig', width: '20%', align: 'left' },
    { title: t('คำถาม'), dataIndex: 'question', width: '30%', align: 'left' },
    {
      title: t('ประเภทคำตอบ'),
      dataIndex: 'choiceTypeCode',
      width: '15%',
      align: 'left',
      render: (text, record) => {
        if (isMasterChoiceTypes && record?.choiceTypeCode.length) {
          return displayText(
            masterChoiceTypes[record.choiceTypeCode]?.choiceTypeNameTH,
            masterChoiceTypes[record.choiceTypeCode]?.choiceTypeNameEN,
          )
        }
      },
    },
    { title: t('คำอธิบายเพิ่มเติม'), dataIndex: 'questDetailType', width: '15%', align: 'left' },
    {
      title: t('สถานะ'),
      dataIndex: 'isActive',
      width: '10%',
      align: 'center',
      render: (record) => <Status text={record === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '10%',
      render: (text, record) => {
        let list = [
          {
            type: 'view',
            onClick: () => {
              props.accessTools('view')

              if (record.type === 'config') {
                props.setModalConfiguration(true)
                props.configItem(record)
              } else {
                const masterQuestDetailType = { [t('No Description')]: 0, [t('Has Description')]: 1 }
                props.setModalQuestion(true)
                props.questionItem({ ...record, questDetailType: masterQuestDetailType[record.questDetailType] })
              }
            },
          },
          {
            type: 'edit',
            onClick: () => {
              props.accessTools('edit')

              if (record.type === 'config') {
                props.setModalConfiguration(true)
                props.configItem(record)
              } else {
                const masterQuestDetailType = { [t('No Description')]: 0, [t('Has Description')]: 1 }
                props.setModalQuestion(true)
                props.questionItem({ ...record, questDetailType: masterQuestDetailType[record.questDetailType] })
              }
            },
          },
          {
            type: 'delete',
            onClick: () => {
              if (record.type === 'config') {
                setDataConfigQuest(
                  _.filter(dataConfigQuest, (d) => d.key !== record.key && d.formConfigNo !== record.formConfigNo),
                )
                props.onDeleteConfig(_.filter(props.isConfiguration, (d) => d.key !== record.key))
                props.onDeleteQuestion(_.filter(props.isQuestion, (d) => d.formConfigNo !== record.formConfigNo))
              }
              if (record.type === 'quest') {
                setDataConfigQuest(_.filter(dataConfigQuest, (d) => d.key !== record.key))
                props.onDeleteQuestion(_.filter(props.isQuestion, (d) => d.key !== record.key))
              }
            },
          },
        ]
        return (
          <div style={{ textAlign: 'center', display: `${disabled ? 'none' : 'block'}` }}>
            <ButtonGroup menu={menu} size={18} icons={list} />
          </div>
        )
      },
    },
  ]

  const generalForm = () => {
    return (
      <Col {...wrapperForm}>
        <Row style={{ marginTop: 24 }} align="center" gutter={[24, 16]}>
          <Col {...wrapperSubForm}>
            <Form.Item
              name={'controlFormYear'}
              label={<Label type="tab-header-inactive">{t('ประจำปี')}</Label>}
              rules={[{ required: true, message: t('กรุณาระบุปี') }]}
            >
              <SelectYear
                formname={formname}
                placeholder={t('ระบุปีงบประมาณ')}
                disabled={disabled}
                handleChange={(e) => form.setFieldsValue({ controlFormYear: e?.value })}
              />
            </Form.Item>
            <Form.Item
              name="controlFormCode"
              label={<Label type="tab-header-inactive">{t('รหัสแบบประเมิน')}</Label>}
              rules={[{ required: true, message: t('กรุณาระบุรหัสแบบประเมิน') }]}
            >
              <InputNew
                placeholder={t('ระบุรหัสแบบประเมิน')}
                maxLength={10}
                disabled={props.activeType === 'copy' ? false : true}
              />
            </Form.Item>
            <Form.Item
              name="controlFormTH"
              label={<Label type="tab-header-inactive">{t('ชื่อแบบประเมินไทย')}</Label>}
              rules={[{ required: true, message: t('กรุณาระบุชื่อแบบประเมินภาษาไทย') }]}
            >
              <InputNew placeholder={t('ระบุชื่อแบบประเมินภาษาไทย')} disabled={disabled} maxLength={255} />
            </Form.Item>
            <Form.Item
              name="controlFormEN"
              label={<Label type="tab-header-inactive">{t('ชื่อแบบประเมินภาษาอังกฤษ')}</Label>}
            >
              <InputNew placeholder={t('ระบุชื่อแบบประเมินภาษาอังกฤษ')} disabled={disabled} maxLength={255} />
            </Form.Item>
          </Col>

          <Col {...wrapperSubForm}>
            <Form.Item
              name="dateStart"
              label={<Label type="tab-header-inactive">{t('วันเริ่มต้นประเมิน')}</Label>}
              rules={[{ required: true, message: t('กรุณาเลือกวันเริ่มต้นประเมิน') }]}
            >
              <Datepicker
                setValueDateFn={async (data) => {
                  form.setFieldsValue({ dateStart: await data })
                  data && (await setIsMinDate(data))
                }}
                placeholder={t('เลือกวันเริ่มต้นประเมิน')}
                disabled={disabled}
                isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                selectsStart={true}
              />
            </Form.Item>
            <Form.Item
              name="dateEnd"
              label={<Label type="tab-header-inactive">{t('วันสิ้นสุดประเมิน')}</Label>}
              rules={[{ required: true, message: t('กรุณาเลือกวันสิ้นสุดประเมิน') }]}
            >
              <Datepicker
                setValueDateFn={async (data) => {
                  form.setFieldsValue({ dateEnd: await data })
                  data && (await setIsMaxDate(data))
                }}
                placeholder={t('เลือกวันสิ้นสุดประเมิน')}
                disabled={disabled}
                isMinDate={isMinDate ? new Date(isMinDate) : null}
                selectsEnd={true}
              />
            </Form.Item>
            <Form.Item
              name={'operationStatusCode'}
              label={<Label type="tab-header-inactive">{t('สถานะการดำเนินงาน')}</Label>}
            >
              <SelectOperationStatus
                formname={formname}
                placeholder={t('รอดำเนินการ')}
                needOperationStatusCode={true}
                handleChange={(e) => form.setFieldsValue({ operationStatusCode: e?.value })}
                disabled={true}
              />
            </Form.Item>
            <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
              <RadioGroup disabled={disabled}>
                <Space size={[8, 8]} wrap>
                  <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                  <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
                </Space>
              </RadioGroup>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    )
  }

  const unitAcceptForm = () => {
    return (
      <Col {...responsiveCol}>
        <Datatable
          columns={columnsOrganization}
          data={[...dataLists]}
          total={dataLists.length}
          onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
          paginationCustom={true}
          searchCustom={true}
          pageSize={limit}
          page={page}
          btnAdd={
            <div style={{ display: `${disabled ? 'none' : 'block'}` }}>
              <ButtonNew
                type="plusTableBorderPrimary"
                roles={{ type: 'add', menu: menu }}
                onClick={() => props.setModalForm(true)}
              >
                {t('เพิ่มหน่วยรับตรวจ')}
              </ButtonNew>
            </div>
          }
        />
      </Col>
    )
  }

  return (
    <>
      <CardNew
        topic={
          props.activeType === 'view'
            ? t('แบบประเมินควบคุมภายใน')
            : props.activeType === 'copy'
            ? t('คัดลอกแบบประเมินควบคุมภายใน')
            : t('แก้ไขแบบประเมินควบคุมภายใน')
        }
        icon="Home"
        toggledrop={'false'}
      >
        <TabStyle>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab={t('ข้อมูลทั่วไป')} key="1">
              {generalForm()}
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('หน่วยรับตรวจ')} key="2">
              {unitAcceptForm()}
            </Tabs.TabPane>
          </Tabs>
        </TabStyle>
      </CardNew>
      <CardNew topic={'รายการคำถามแบบประเมินควบคุมภายใน'} icon="List" toggledrop={'false'}>
        <Col {...responsiveCol} align="center">
          <Datatable
            columns={columnsQuestion}
            data={dataConfigQuest}
            searchCustom={true}
            btnAdd={
              <div style={{ display: `${disabled ? 'none' : 'flex'}` }}>
                <ButtonNew
                  style={{ marginRight: '1rem' }}
                  type="plusTableBorderPrimary"
                  roles={{ type: 'add', menu: menu }}
                  onClick={() => {
                    props.accessTools(null)
                    props.setModalConfiguration(true)
                    props.isCreateConfItem(true)
                  }}
                >
                  {t('เพิ่มองค์ประกอบการควบคุม')}
                </ButtonNew>
                <ButtonNew
                  type="plusTableBorderPrimary"
                  roles={{ type: 'add', menu: menu }}
                  onClick={() => {
                    props.setModalQuestion(true)
                    props.isCreateQuestion(true)
                  }}
                >
                  {t('เพิ่มคำถาม')}
                </ButtonNew>
              </div>
            }
          />
          <Space size={[8, 8]} wrap style={{ marginTop: '2rem' }}>
            {!disabled && (
              <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                {t('บันทึก')}
              </ButtonNew>
            )}
            <ButtonNew
              type="back"
              onClick={() =>
                Promise.all([form.resetFields(), setDataLists([...[]]), navigate('/internalcontrol/controlform')])
              }
            >
              {t(disabled ? 'ย้อนกลับ' : 'ยกเลิก')}
            </ButtonNew>
          </Space>
        </Col>
        <Form.Item hidden={true} name="organizationList" rules={[{ required: true, message: 'required' }]}>
          <Input />
        </Form.Item>
      </CardNew>
    </>
  )
}

ControlFormAllEdit.propTypes = {
  form: PropTypes.object,
  formname: PropTypes.string,
  orgList: PropTypes.array,
  onDeleteOrg: PropTypes.func,
  menu: PropTypes.string.isRequired,
}

export default ControlFormAllEdit
