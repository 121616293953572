import { gql } from '@apollo/client'
export const GET_MASTER_RISK_LEVEL_YEAR = gql`
  query GetMasterRiskLevelYear($input: MasterRiskLevelYearInput!) {
    getMasterRiskLevelYear(input: $input) {
      riskLevelYearId
      riskLevelYearCode
      riskLevelYear
      riskLevelYearName
      isActive
    }
  }
`
