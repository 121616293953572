import { gql } from '@apollo/client'

export const CONSULTATION_RESULT_SAVE = gql`
  mutation consultationResultSave($input: ConsultationResultInput!) {
    consultationResultSave(input: $input)
  }
`

export const CONSULTATION_RESULT_DELETE = gql`
  mutation ConsultationResultDelete($input: ConsultationResultInput!) {
    consultationResultDelete(input: $input) {
      consultId
    }
  }
`
