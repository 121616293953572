import { Trans } from 'react-i18next'
import { Status } from '../../../../components/status/Status'
export const ColumnDetails = [
  {
    title: <Trans>โครงการ</Trans>,
    dataIndex: 'pjNameCol',
    // sorter: true,
    width: '300px',
    className: 'text-nowrap',
  },
  {
    title: <Trans>แหล่งที่มาของโครงการ</Trans>,
    dataIndex: 'pjSourceCol',
    // sorter: true,
    width: '200px',
    className: 'text-nowrap',
  },
  {
    title: <Trans>วันที่เริ่มต้น</Trans>,
    dataIndex: 'pjDateStartCol',
    // sorter: true,
    width: '150px',
    className: 'text-nowrap',
  },
  {
    title: <Trans>วันที่สิ้นสุด</Trans>,
    dataIndex: 'pjDateEndCol',
    // sorter: true,
    width: '150px',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ทีมที่รับผิดชอบ</Trans>,
    dataIndex: 'pjAuditTeamCol',
    // sorter: true,
    width: '200px',
    className: 'text-nowrap',
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'pjApproveStatus',
    // sorter: true,
    width: '200px',
    className: 'text-nowrap',
    render: (item) => {
      return <Status text={item}></Status>
    },
  },
  {
    title: '',
    dataIndex: 'pjOptionsCol',
    width: '50px',
    align: 'right',
  },
]
