import { Trans, useTranslation } from 'react-i18next'

export const pageTitle = 'อัตรากำลังและการพัฒนาทักษะของสายงานตรวจสอบ'

export const breadcrumbData = () => {
  const { t } = useTranslation()
  return [{ label: t('แดชบอร์ด'), path: '/dashboard' }, { label: t(pageTitle) }]
}

export const Columns = () => {
  return [
    {
      title: <Trans>{'ลำดับ'}</Trans>,
      dataIndex: 'no',
      width: '50px',
      align: 'center'
    },
    {
      title: <Trans>{'ชื่อ-นามสกุล'}</Trans>,
      dataIndex: 'name',
      width: '100px',
      align: 'left',
      sorter: true
    },
    {
      title: <Trans>{'ตำแหน่ง'}</Trans>,
      dataIndex: 'positionName',
      width: '50px',
      align: 'left',
      sorter: true
    },
    {
      title: <Trans>{'สถานะการปฏิบัติงาน'}</Trans>,
      dataIndex: 'personnelStatusName',
      width: '120px',
      align: 'left',
      sorter: true
    },
    {
      title: <Trans>{'วุฒิบัตรที่ได้รับ'}</Trans>,
      dataIndex: 'certificateShot',
      width: '100px',
      align: 'left',
      sorter: true
    },
    {
      title: <Trans>{'ชั่วโมงการอบรมคงเหลือ'}</Trans>,
      dataIndex: 'trainingHours',
      width: '100px',
      align: 'right',
      sorter: true
    }
  ]
}
