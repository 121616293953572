import { gql } from '@apollo/client'
export const GET_SELECT_AUDIT_PROJECTS_NEW = gql`
  query GetSelectAuditProjectsNew($input: SelectAuditProjectInput!) {
    getSelectAuditProjectsNew(input: $input) {
      projectCode
      projectNameTH
      projectNameEN
      planCode
      projectTypeCode
      auditTypeCode
      projectRefCode
      riskFormCode
      projectSizeCode
      manday
      auditTeamCode
      personnelCode
      numberOfPeople
      mandayPersonnal
      organizationCode
      organizationNameTH
      organizationNameEN
      dateStart
      dateEnd
      approveStatusCode
      approvePersonnelCode
      approveDate
      approveRemarkTH
      approveRemarkEN
      projectStatusCode
      cancelRemarkTH
      cancelRemarkEN
      files
      fileDetail
      isActive
    }
  }
`

export const GET_SELECT_AUDIT_PROJECTS_CLOSED = gql`
  query {
    getSelectAuditProjectClosed {
      projectCode
      projectNameTH
      projectNameEN
      organizationCode
      organizationNameTH
      organizationNameEN
    }
  }
`
