import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Row, Col, Form } from 'antd'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../../components/card/Card'
import { CardNewDb, CardNewDbF } from '../components/card/Card'
import { LabelNew } from '../../../../components/label/Label'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import SelectYear from '../../../../components/inputfromapi/selectyear/SelectYear'
import { Alert } from '../../../../components/alert/Alert'
import { Columns } from './utils/InspectorWorkColumns.js'
import { Datatable } from '../../../../components/datatable/Datatable'
import { useDecodeUser } from '../../../../hooks/useDecodeUser'
import { handleSort } from '../../../../utilitys/pagination'
import { MainTable, SubTable } from './css/Styles.js'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayText, displayError } from '../../../../utilitys/helper'
import { dateDisplay } from '../../../../utilitys/dateHelper'
import { GET_AUDITOR_WORK_PAGINATION } from './graphql/Query.js'
import Files from './components/Files.jsx'
import { numberFormat } from '../../../../utilitys/helper'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}

const InspectorWorkList = () => {
  const { t } = useTranslation()
  const [language /*setLanguage*/] = useState('th')
  const [form] = Form.useForm()
  const formName = 'form-fillter'
  const [dataObj, setDataObj] = useState({
    statusStart: 'success',
    messageStart: null,
  })

  const breadcrumbList = [{ label: t('Dashboard') }, { label: t('ผลการตรวจสอบ') }]

  const [filters, setFilters] = useState({
    yearStart: new Date().getFullYear(),
  })
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  const [user] = useDecodeUser()

  const [newData, setNewData] = useState([])
  const [summary, setSummary] = useState({})

  /* ---------------- API ----------- */
  const [getDataPaginationFn, dataPagination] = useLazyQuery(GET_AUDITOR_WORK_PAGINATION)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (user?.pCode) {
      getDataPaginationFn({
        variables: {
          input: encryptInput({
            filters: {
              ...filters,
              organizationCode: user?.orgCode,
              personnelCode: user?.pCode,
            },
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
    }
  }, [page, limit, sort, user?.pCode, filters])

  useEffect(() => {
    if (dataPagination.data) {
      let response = dataPagination?.data?.getAuditorWorkPagination
      setSummary(response.summary)
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data, index) => {
          return {
            key: index,
            projectName: displayText(data.projectNameTH, data.projectNameEN) || '-',
            countIssue: data.countIssue,
            countSuggest: data.countSuggest,
            count10: data.count10,
            count20: data.count20,
            countNull: data.countNull,
            auditOpenDoc: data.auditOpenDoc,
          }
        }),
      )
    }

    if (dataPagination.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataPagination.error)),
        onOk() {},
      })
    }
  }, [dataPagination.data])

  useEffect(() => {
    setNewData(
      _.map(dataLists, (data) => ({
        ...data,
        description: (
          <SubTable>
            <Datatable columns={subColumns} data={data.auditOpenDoc ? data.auditOpenDoc : []} />
          </SubTable>
        ),
      })),
    )
  }, [dataLists])

  const subColumns = [
    { title: t('ชื่อเอกสาร'), dataIndex: 'openDocDetailTH', width: '45%' },
    { title: t('ประเภทการขอ'), dataIndex: 'docTypeName', align: 'center', width: '10%' },
    {
      title: t('วันที่ขอ'),
      dataIndex: 'dateRequest',
      align: 'center',
      width: '10%',
      render: (item) => {
        return dateDisplay(item)
      },
    },
    {
      title: t('ไฟล์แนบ'),
      dataIndex: 'listFile',
      width: '35%',
      render: (item) => {
        const listFile = item.map((data, key) => {
          return <Files key={key} item={data} index={key} />
        })
        return <div style={{ display: 'flex' }}>{listFile}</div>
      },
    },
  ]

  return (
    <>
      {dataPagination.loading ? <SpinnersNew /> : null}
      <Breadcrumb data={breadcrumbList} title={t('ผลการตรวจสอบ')} />
      <Row gutter={[24, 8]} style={{ marginBottom: '25px', marginTop: '20px' }}>
        <Col md={24} lg={9}>
          <CardNewDbF padding={'25px 24px 139px 24px'}>
            <Form
              form={form}
              name={formName}
              labelCol={{
                md: 6,
                lg: 6,
              }}
              wrapperCol={{
                md: 17,
                lg: 17,
                align: 'left',
              }}
            >
              <Form.Item
                name="yearStart"
                label={<LabelNew type="tab-header-inactive">{t('ประจำปี')}</LabelNew>}
                style={{ paddingBottom: '15px' }}
                validateStatus={dataObj.statusStart}
                help={dataObj.messageStart}
                initialValue={new Date().getFullYear()}
              >
                <SelectYear
                  formname={formName}
                  placeholder={t('เลือก ปีเริ่มต้น')}
                  handleChange={(e) => {
                    if (!e?.value) {
                      setDataObj({
                        ...dataObj,
                        statusStart: 'error',
                        messageStart: t('กรุณาเลือกปีเริ่มต้น'),
                      })
                    } else {
                      setDataObj({
                        statusStart: 'success',
                        messageStart: null,
                      })
                    }
                    setFilters({
                      yearStart: e?.value,
                    })
                    form.setFieldsValue({ yearStart: e?.value })
                  }}
                />
              </Form.Item>
            </Form>
          </CardNewDbF>
        </Col>

        <Col md={24} lg={15}>
          <Row gutter={[40, 15]}>
            <Col span={12} title={t('จำนวนที่ประเด็นตรวจพบทั้งหมดภายในปีที่เลือก')}>
              <CardNewDb
                icon="Search"
                color={'blue'}
                content={summary.summaryIssue ? numberFormat(summary.summaryIssue) : 0}
                description={t('จำนวนประเด็นที่ตรวจพบ')}
                // loading={dataSummary.loading}
              />
            </Col>
            <Col span={12} title={t('จำนวนข้อเสนอแนะที่ตรวจพบทั้งหมดภายในปีที่เลือก')}>
              <CardNewDb
                icon="FileText"
                color={'green'}
                content={summary.summarySuggest ? numberFormat(summary.summarySuggest) : 0}
                description={t('จำนวนข้อเสนอแนะ')}
                // loading={dataSummary.loading}
              />
            </Col>
            <Col span={12} title={t('จำนวนรวมของสถานะ รอดำเนินการ(10) และ กำลังดำเนินการ(20) ภายในปีที่เลือก')}>
              <CardNewDb
                icon="Clock"
                color={'orange'}
                content={summary.countAssignActivity ? numberFormat(summary.countAssignActivity) : 0}
                description={t('จำนวนกิจกรรมที่ต้องดำเนินการ')}
                // loading={dataSummary.loading}
              />
            </Col>
            <Col span={12} title={t('จำนวนรวมยังไม่ระบุกิจกรรมที่ดำเนินการ(is null) ภายในปีที่เลือก')}>
              <CardNewDb
                icon="Info"
                color={'danger'}
                content={summary.countNotAssignActivity ? numberFormat(summary.countNotAssignActivity) : 0}
                description={t('ยังไม่ระบุกิจกรรมที่ดำเนินการ')}
                // loading={dataSummary.loading}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <CardNew
        topic={language === 'th' ? t('งานตรวจสอบที่อยู่ในความรับผิดชอบ') : ''}
        icon="TrendingUp"
        toggledrop={'false'}
      >
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            <MainTable>
              <Datatable
                columns={Columns()}
                data={[...newData]}
                total={count}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                paginationCustom={true}
                pageSize={limit}
                page={page}
                handleTableChange={(e) => setSort(handleSort(e?.sorter))}
                scroll={{ x: 1000, y: false }}
                /** 13/06/2024 พี่ชายบอกให้ comment ไปก่อน ลูกค้า ถามหาค่อยเอากลับมา เป็นส่วนที่แสดงข้อมูลเอกสารที่เกี่ยวข้องกับ กิจกรรมการตรวจสอบ auditOpenDoc */
                /* expandable={{
                  expandedRowRender: (record) => record.description,
                  // onExpandedRowsChange: (e) => setControlFormCode(_.map(e, (d) => _.last(d.split('_')))),
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <IconNew icon={'ChevronUp'} onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <IconNew icon={'ChevronDown'} onClick={(e) => onExpand(record, e)} />
                    )
                }} */
              />
            </MainTable>
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default InspectorWorkList
