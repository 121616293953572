import { gql } from '@apollo/client'
export const GET_SELECT_MASTER_ORGANIZATION_LEVEL = gql`
  query {
    getSelectMasterOrganizationLevel {
      organizationLevelCode
      organizationLevelNameTH
      organizationLevelNameEN
    }
  }
`
