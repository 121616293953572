import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_ISO } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectISO = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const [isData, isSetData] = useState([])

  const { isActive } = filters || {}
  const [celGet, { data, loading }] = useLazyQuery(GET_MASTER_ISO)

  useEffect(() => {
    celGet({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [isActive])

  useEffect(() => {
    if (data) {
      isSetData(
        _.map(data?.getMasterISO, (item) => {
          return {
            label: displayText(item?.ISONameTH, item?.ISONameEN),
            value: item?.ISOCode,
            source: item,
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          return handleChange(_.find(isData, (data) => data.value === value))
        }}
      />
    </>
  )
}

export default SelectISO
