import { gql } from '@apollo/client'

export const GET_MASTER_DOCUMENT_TYPES = gql`
  query GetMasterDocumentTypes($input: MasterDocumentTypesInput!) {
    getMasterDocumentTypes(input: $input) {
      docTypeId
      docTypeCode
      docTypeNameTH
      docTypeNameEN
      isActive
    }
  }
`
