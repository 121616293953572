import React, { useState, useLayoutEffect, useEffect } from 'react'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { Row, Col, Space, Form } from 'antd'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import * as _ from 'lodash'
import { breadcrumbList } from './utils/breadcrumbData'
import { checkLanguageReturnData } from '../manageassessment/components/fnForManageAssessment'
//import { yearDisplay } from '../../../utilitys/dateHelper'
import { CardNew } from '../../../components/card/Card'
import { LabelNew as Label } from '../../../components/label/Label'
import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonNew } from '../../../components/button/Button'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { colunmformGroups } from './utils/assessCooperationColumn'
import {
  GETASSESSCOOPERATIONONE,
  GETGROUPSANDQUESTIONSBYASSESSCOOPERATION,
  GETMASTERCHOICEGROUPDETAILBYASSESSCOOPERATION,
} from './graphql/Query'
import { SAVEFORMRESULTSBYASSESSCOOPERATION } from './graphql/Mutation'
import ModalSendAssesss from './components/modal/ModalSendAssesss'
import { Alert } from '../../../components/alert/Alert'
import { displayError } from '../../../utilitys/helper'

export default function AssessCooperationDetails(props) {
  const { menu } = props
  const { state } = useLocation()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userData = decryptData(localStorage.getItem('user'))
  // const [isLang] = useLanguage()
  const Responsive = {
    md: 24,
    lg: 24,
  }
  if (!state) return <Navigate to={'/auditoperations/assesscooperation'} />

  const formName = 'assess-cooperation-details'
  const [dataShow, setDataShow] = useState({})
  const [dataList, setDataList] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])
  const [visibleModal, setVisitbleModal] = useState(false)
  const [optionChoiceGroup, setOptionChoiceGroup] = useState([])
  const [dataSave, setDataSave] = useState({})
  const [userType, setUserType] = useState('')
  const [editable, seteditable] = useState(true)

  const [getAssessCooperationOnefn, getAssessCooperationOne] = useLazyQuery(GETASSESSCOOPERATIONONE)
  const [getGroupsAndQuestionsByAssessCooperationfn, getGroupsAndQuestionsByAssessCooperation] = useLazyQuery(
    GETGROUPSANDQUESTIONSBYASSESSCOOPERATION,
  )
  const [getMasterChoiceGroupDetailByAssessCooperationfn, getMasterChoiceGroupDetailByAssessCooperation] = useLazyQuery(
    GETMASTERCHOICEGROUPDETAILBYASSESSCOOPERATION,
  )
  const [
    saveformResultsByAssessCooperationFn,
    { loading: loadingSave, error, data: saveformResultsByAssessCooperation },
  ] = useMutation(SAVEFORMRESULTSBYASSESSCOOPERATION)

  const onFinish = (btnType) => {
    Alert({
      type: 'confirm',
      title:
        btnType === 'save'
          ? t('ต้องการบันทึกแบบประเมินความร่วมมือ ?')
          : t('ต้องการดำเนินการเสร็จแบบประเมินความร่วมมือ ?'),
      onOk() {
        saveformResultsByAssessCooperationFn({
          variables: {
            input: encryptInput({
              formResults: dataShow,
              formResultDetails: dataSave.formResultDetails.map((dt) => {
                return {
                  resultDetailId: dt.resultDetailId,
                  choiceDetailId: dt.choiceDetailId ? dt.choiceDetailId : null,
                  answerDetail: dt.answerDetail,
                  files: dt.files,
                }
              }),
              userType: userType,
              actions: btnType,
            }),
          },
        })
      },
      onCancel() {},
    })
  }

  const onChangeData = (data) => {
    setDataSave(data)
  }

  const checkUserType = (obj) => {
    if (obj.makerBy === userData.pCode) {
      setUserType('maker')
    }
    if (obj.responsibilityBy === userData.pCode) {
      setUserType('responsibility')
    }
    if (obj.responsibilityBy === userData.pCode && obj.makerBy) {
      setUserType('reviewer')
    }
  }

  useLayoutEffect(() => {
    // setDataList(demoData)
    if (state.pageType === 'send') {
      setVisitbleModal(true)
      seteditable(true)
    } else if (state.pageType === 'edit') {
      seteditable(true)
    } else {
      seteditable(false)
    }
    // setDataList([])
  }, [getAssessCooperationOne?.data, getGroupsAndQuestionsByAssessCooperation?.data])

  useEffect(() => {
    if (state.refCode) {
      getAssessCooperationOnefn({
        variables: {
          input: encryptInput({
            formResultId: state.refCode,
          }),
        },
      })
      getGroupsAndQuestionsByAssessCooperationfn({
        variables: {
          input: encryptInput({
            formResultId: state.refCode,
          }),
        },
      })
      getMasterChoiceGroupDetailByAssessCooperationfn({
        variables: {
          isActive: 1,
        },
      })
    }
  }, [state.refCode])

  useEffect(() => {
    if (getAssessCooperationOne?.data) {
      const res = getAssessCooperationOne?.data?.getAssessCooperationOne
      setDataShow({
        formResultId: res.formResultId,
        formYear: localStorage.getItem('lang') === 'th' ? res.formYear + 543 : res.formYear,
        formCode: res.formCode,
        formName: checkLanguageReturnData(res.formNameTH, res.formNameEN),
        organizationName: checkLanguageReturnData(res.organizationNameTH, res.organizationNameEN),
        organizationName2: checkLanguageReturnData(res.organizationNameTH2, res.organizationNameEN2),
        responsibilityBy: res.responsibilityBy,
        formAssessId: res.formAssessId,
        makerBy: res.makerBy,
      })
      checkUserType(res)
      // setDataSave({
      //   formResultId: res.formResultId,
      //   formResultDetails: [],
      // })
    }
    if (getAssessCooperationOne.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getAssessCooperationOne.error)),
        onOk() {},
      })
    }
  }, [getAssessCooperationOne?.data])

  useEffect(() => {
    if (getGroupsAndQuestionsByAssessCooperation?.data) {
      const res = getGroupsAndQuestionsByAssessCooperation?.data?.getGroupsAndQuestionsByAssessCooperation
      const dataGroup = []
      const data = []
      _.map(res, (dt, key) => {
        data.push({
          resultDetailId: dt.resultDetailId,
          formResultId: dt.formResultId,
          formGroupId: dt.formGroupId,
          formQuestId: dt.formQuestId,
          choiceTypeCode: dt.choiceTypeCode,
          formRequired: dt.formRequired,
          choiceDetailId: dt.choiceDetailId,
          answerDetail: dt.answerDetail,
          files: dt.files,
        })
        const checkdup = _.find(dataGroup, function (o) {
          return dt.formGroupId === o.formGroupId && dt.formSort === o.formSort
        })
        if (!checkdup) {
          dataGroup.push({
            formGroupId: dt.formGroupId,
            formSort: dt.formSort,
            formGroupNameTH: dt.formGroupNameTH,
            formGroupNameEN: dt.formGroupNameEN,
            formQuestions: [],
          })
        }
        const groupIndex = _.findIndex(dataGroup, function (o) {
          return dt.formGroupId === o.formGroupId && dt.formSort === o.formSort
        })
        dataGroup[groupIndex].formQuestions.push({
          key: key,
          resultDetailId: dt.resultDetailId,
          formResultId: dt.formResultId,
          formGroupSort: dt.formSort,
          formQuestId: dt.formQuestId,
          formSort: dt.formSortByQ,
          formQuestNameTH: dt.formQuestNameTH,
          formGroupNameEN: dt.formQuestNameTH,
          formQuestType: dt.formQuestType,
          formRequired: dt.formRequired,
          choiceTypeCode: dt.choiceTypeCode,
          choiceGroupCode: dt.choiceGroupCode,
          choiceDetailId: dt.choiceDetailId,
          answerDetail: dt.answerDetail,
          files: dt.files,
        })
      })
      setDataList(dataGroup)
      setDataSave({
        formResultId: dataShow.formResultId,
        formResultDetails: data,
      })
    }
    if (getGroupsAndQuestionsByAssessCooperation.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getGroupsAndQuestionsByAssessCooperation.error)),
        onOk() {},
      })
    }
  }, [getGroupsAndQuestionsByAssessCooperation?.data])

  useEffect(() => {
    if (getMasterChoiceGroupDetailByAssessCooperation?.data) {
      setOptionChoiceGroup(
        getMasterChoiceGroupDetailByAssessCooperation?.data?.getMasterChoiceGroupDetailByAssessCooperation.map(
          (dt, key) => {
            return {
              key: key,
              value: dt.choiceDetailId,
              label: checkLanguageReturnData(dt.choiceDetailNameTH, dt.choiceDetailNameEN),
              source: dt,
            }
          },
        ),
      )
    }
    if (getMasterChoiceGroupDetailByAssessCooperation.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getMasterChoiceGroupDetailByAssessCooperation.error)),
        onOk() {},
      })
    }
  }, [getMasterChoiceGroupDetailByAssessCooperation?.data])

  useEffect(() => {
    if (saveformResultsByAssessCooperation) {
      const res = saveformResultsByAssessCooperation.saveformResultsByAssessCooperation
      Alert({
        type: 'success',
        title: t('ส่งแบบประเมินความร่วมมือสำเร็จ'),
        onOk() {
          navigate('/auditoperations/assesscooperation/assesscooperationdetails', {
            state: {
              pageType:
                (userType === 'responsibility' && res.operationStatusCode === '31') ||
                (userType === 'maker' && res.makerStatusCode === '31')
                  ? 'view'
                  : 'edit',
              refCode: res.formResultId,
            },
          })
          window.location.reload()
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [saveformResultsByAssessCooperation])

  return (
    <React.Fragment>
      {(getAssessCooperationOne?.loading ||
        getGroupsAndQuestionsByAssessCooperation?.loading ||
        getMasterChoiceGroupDetailByAssessCooperation.loading ||
        loadingSave) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('ประเมินความร่วมมือ')} />
      <CardNew topic={t('รายการประเมินความร่วมมือ')} icon="List" toggledrop={'false'}>
        <Row style={{ marginBottom: 24 }} align="center">
          <Col {...Responsive} md={16} offset={4} align="left">
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={4} align="left">
                <Label type="tab-header-inactive">{t('ประจำปี')}</Label>
              </Col>
              <Col md={20} align="left">
                <Label type="body-primary">{dataShow.formYear}</Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={4} align="left">
                <Label type="tab-header-inactive">{t('ชื่อแบบประเมิน')}</Label>
              </Col>
              <Col md={20} align="left">
                <Label type="body-primary">{`${dataShow.formCode} : ${dataShow.formName}`}</Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={4} align="left">
                <Label type="tab-header-inactive">{t('หน่วยตรวจสอบ')}</Label>
              </Col>
              <Col md={20} align="left">
                <Label type="body-primary">{dataShow.organizationName2}</Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={4} align="left">
                <Label type="tab-header-inactive">{t('หน่วยรับตรวจ')}</Label>
              </Col>
              <Col md={20} align="left">
                <Label type="body-primary">{dataShow.organizationName}</Label>
              </Col>
            </Row>
          </Col>
        </Row>
        <Label type="card-title" icon="List">
          {t('รายการคำถามแบบประเมินความร่วมมือ')}
        </Label>
        <Form
          form={form}
          // onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            // span: 6,
            offset: 1,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 12,
            lg: 24,
          }}
          layout="horizontal"
          name={formName}
        >
          {dataList.length > 0
            ? dataList.map((dt, key) => {
                return (
                  <React.Fragment key={key}>
                    <br />
                    <Label type="body-header">{`${dt.formSort}. ${checkLanguageReturnData(
                      dt.formGroupNameTH,
                      dt.formGroupNameEN,
                    )}`}</Label>
                    <Datatable
                      columns={colunmformGroups({
                        menu,
                        formName,
                        idFileDelete,
                        setIdFileDelete,
                        optionChoiceGroup,
                        dataSave,
                        onChangeData,
                        editable,
                        form,
                        key,
                        t,
                      })}
                      data={dt.formQuestions}
                      paginationCustom={false}
                      pagination={false}
                      searchCustom={false}
                    />
                  </React.Fragment>
                )
              })
            : ''}
          <Row style={{ marginTop: 24 }} justify="center">
            <Col {...Responsive} align="center">
              {editable ? (
                <Space size={[8, 8]} wrap>
                  <ButtonNew
                    type="primary"
                    roles={{ type: 'add', menu: menu }}
                    onClick={() => {
                      form
                        .validateFields()
                        .then(() => onFinish('save'))
                        .catch(() => {})
                    }}
                    htmlType="submit"
                  >
                    {t('บันทึก')}
                  </ButtonNew>
                  <ButtonNew
                    type="success"
                    roles={{ type: 'add', menu: menu }}
                    onClick={() => {
                      form
                        .validateFields()
                        .then(() => onFinish('success'))
                        .catch(() => {})
                    }}
                    htmlType="submit"
                  >
                    {t('เสร็จสิ้น')}
                  </ButtonNew>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/auditoperations/assesscooperation')
                    }}
                  >
                    {t('ยกเลิก')}
                  </ButtonNew>
                </Space>
              ) : (
                <Space>
                  {' '}
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/auditoperations/assesscooperation')
                    }}
                  >
                    {t('ยกเลิก')}
                  </ButtonNew>{' '}
                </Space>
              )}
            </Col>
          </Row>
        </Form>
      </CardNew>
      <ModalSendAssesss
        menu={menu}
        open={visibleModal}
        onClose={(e) => {
          setVisitbleModal(e)
        }}
        dataObj={dataShow}
      />
    </React.Fragment>
  )
}
