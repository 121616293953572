import { gql } from '@apollo/client'

export const GET_DATA_OVERVIEW_FOLLOWUP_GRAPHPIE = gql`
  query GetDataOverviewFollowUpGraphPie($input: OverviewFollowUpInput!) {
    getDataOverviewFollowUpGraphPie(input: $input) {
      jobTypeCode
      jobTypeName
      jobStatusCode
      statusName
      countStatus
      projectCode
    }
  }
`

export const GET_DATA_OVERVIEW_FOLLOWUP_AMOUNT = gql`
  query GetDataOverviewFollowUpAmount($input: OverviewFollowUpInput!) {
    getDataOverviewFollowUpAmount(input: $input) {
      stepTypeCode
      stepTypeNameTH
      stepTypeNameEN
      countStep
      countStepLate
      projectCode
    }
  }
`

export const GET_DATA_OVERVIEW_FOLLOWUP_PROJECT = gql`
  query GetDataOverviewFollowUpProject($input: OverviewFollowUpPaginationInput!) {
    getDataOverviewFollowUpProject(input: $input) {
      result {
        no
        actionplanId
        projectCode
        planYear
        projectNameTH
        projectNameEN
        projectTypeNameTH
        projectTypeNameEN
        auditTeamCode
        auditTeamNameTH
        auditTeamNameEN
        dateStart
        dateEnd
        planDate
        quarter
        dateStart_step
        dateEnd_step
        datelate
        projectStatus
        stepTypeNameTH
        stepTypeNameEN
        organizationName
      }
      count
      page
      limit
    }
  }
`

export const GET_DATA_OVERVIEW_FOLLOWUP_CONSULT = gql`
  query GetDataOverviewFollowUpConsult($input: OverviewFollowUpPaginationInput!) {
    getDataOverviewFollowUpConsult(input: $input) {
      result {
        no
        jobCode
        jobNumber
        jobSubjectTH
        jobSubjectEN
        jobTypeNameTH
        jobTypeNameEN
        organizationNameTH
        organizationNameEN
        requestOrgTH
        requestOrgEN
        fullName
        dateStart
        dateEnd
        jobStatusNameTH
        jobStatusNameEN
        approveStatusNameTH
        approveStatusNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_EXPORT_OVERVIEW_FOLLOWUP_PROJECT = gql`
  query GetExportOverviewFollowUpProject($input: OverviewFollowUpReportExportInput!) {
    getExportOverviewFollowUpProject(input: $input) {
      message
      fileUrl
    }
  }
`

export const GET_EXPORT_OVERVIEW_FOLLOWUP_CONSULT = gql`
  query GetExportOverviewFollowUpConsult($input: OverviewFollowUpReportExportInput!) {
    getExportOverviewFollowUpConsult(input: $input) {
      message
      fileUrl
    }
  }
`
