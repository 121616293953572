import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Col, Row } from 'antd'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { GET_AUDIT_AND_ORGANIZATION_RCM } from '../../graphql/Query'
import { Alert } from '../../../../../components/alert/Alert'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { InputNew, InputTagNew } from '../../../../../components/input/Input'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { ButtonNew } from '../../../../../components/button/Button'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText, displayError } from '../../../../../utilitys/helper'
import SelectActionAuditPlans from '../../../../../components/inputfromapi/selectactionauditplan/SelectActionAuditPlan'
import SelectPersonnel from '../../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import SelectOperationStatus from '../../../../../components/inputfromapi/selectoperationstatus/SelectOperationStatus'

const GeneralTab = () => {
  const formname = 'generalForm'
  const responsive = { sm: 24, md: 24, lg: 22, xl: 22 }
  const wrapper = { md: 24, lg: 24, xl: 24 }
  const subWrapper = { md: 18, lg: 18, xl: 18 }

  const navigate = useNavigate()
  const { state } = useLocation()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  if (!state && state?.rcmId) return <Navigate to={'/auditoperations/racmatrixapprove'} />

  const [getAuditAndOrganizationRCMFn, getAuditAndOrganizationRCM] = useLazyQuery(GET_AUDIT_AND_ORGANIZATION_RCM)

  useEffect(() => {
    if (state?.rcmId) {
      getAuditAndOrganizationRCMFn({ variables: { rcmId: encryptInput(state?.rcmId) } })
    }
  }, [state?.rcmId])

  useEffect(() => {
    if (getAuditAndOrganizationRCM?.data) {
      const response = getAuditAndOrganizationRCM?.data?.getAuditAndOrganizationRCMs

      const orgs = displayText(response?.organizationNameTH, response?.organizationNameEN)

      form.setFieldsValue({
        organization: orgs ? orgs.split(',') : [],
        operationStatusCode: response?.operationStatusCode,
        personnelMaker: response?.personnelMaker,
        personnelReview: response?.personnelReview,
        projectCode: response?.projectCode,
        rcmObjective: response?.rcmObjective,
        rcmScope: response?.rcmScope,
        riskProcess: displayText(response?.riskModelTH, response?.riskModelEN),
        riskProcessSub: response?.riskProcessSub,
      })
    }

    if (getAuditAndOrganizationRCM?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditAndOrganizationRCM?.error)),
        onOk() {
          navigate('/auditoperations/racmatrixapprove')
        },
      })
    }
  }, [getAuditAndOrganizationRCM?.data])

  return (
    <>
      {getAuditAndOrganizationRCM?.loading && <SpinnersNew />}
      <Form
        form={form}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ md: { span: 8 }, lg: { span: 6 }, xl: { span: 5 } }}
        wrapperCol={{ md: { span: 16 }, lg: { span: 18 }, xl: { span: 12 } }}
        name={formname}
      >
        <Row justify="center">
          <Col {...wrapper}>
            <Row style={{ margin: '10px 0px' }}>
              <Col {...subWrapper}>
                <Label type="sub-header-primary">{t('รายละเอียด')}</Label>
              </Col>
            </Row>
            <Row justify="center" gutter={[24, 16]}>
              <Col {...responsive}>
                <Form.Item name="projectCode" label={<Label type="tab-header-inactive">{t('โครงการ')}</Label>}>
                  <SelectActionAuditPlans placeholder={t('เลือกโครงการ')} formname={formname} disabled={true} />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item name="rcmObjective" label={<Label type="tab-header-inactive">{t('วัตถุประสงค์')}</Label>}>
                  <TextAreaNew
                    placeholder={t('ระบุวัตถุประสงค์')}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item name="rcmScope" label={<Label type="tab-header-inactive">{t('ขอบเขตการดำเนินงาน')}</Label>}>
                  <TextAreaNew
                    placeholder={t('ระบุขอบเขตการดำเนินงาน')}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item name="riskProcess" label={<Label type="tab-header-inactive">{t('กระบวนการหลัก')}</Label>}>
                  <InputNew placeholder={t('เลือกกระบวนการหลัก')} disabled={true} />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item name="riskProcessSub" label={<Label type="tab-header-inactive">{t('กระบวนการย่อย')}</Label>}>
                  <TextAreaNew
                    placeholder={t('ระบุกระบวนการย่อย')}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Col {...subWrapper}>
                <Label type="sub-header-primary">{t('ข้อมูลผู้ตรวจสอบ/หน่วยรับตรวจ')}</Label>
              </Col>
            </Row>
            <Row justify="center" gutter={[24, 16]}>
              <Col {...responsive}>
                <Form.Item name="organization" label={<Label type="tab-header-inactive">{t('หน่วยรับตรวจ')}</Label>}>
                  <InputTagNew placeholder={t('เลือกหน่วยรับตรวจ')} formname={formname} disabled={true} />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item name="personnelMaker" label={<Label type="tab-header-inactive">{t('ผู้จัดทำ')}</Label>}>
                  <SelectPersonnel placeholder={t('เลือกผู้จัดทำ')} formname={formname} disabled={true} />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item name="personnelReview" label={<Label type="tab-header-inactive">{t('ผู้สอบถาม')}</Label>}>
                  <SelectPersonnel placeholder={t('เลือกผู้สอบทาน')} formname={formname} disabled={true} />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item
                  name="operationStatusCode"
                  label={<Label type="tab-header-inactive">{t('สถานะดำเนินงาน')}</Label>}
                >
                  <SelectOperationStatus
                    formname={formname}
                    placeholder={t('เลือกสถานะ')}
                    disabled={true}
                    needOperationStatusCode={true}
                    filters={{ operationStatusType: 1, isActive: 1 }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[12, 20]} justify="center">
          <Col>
            <ButtonNew type="back" onClick={() => navigate('/auditoperations/racmatrixapprove')}>
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
    </>
  )
}

GeneralTab.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default GeneralTab
