import React from 'react'
import { useTranslation } from 'react-i18next'
import { TabNew } from '../../../../../components/tab/Tab'
import TabApprove from './TabApprove'
// import TabApproveCancel from './TabApproveCancel'

export default function TabIndex(props) {
  const { menu, filters } = props
  const { t } = useTranslation()
  const tabs = [
    {
      key: 1,
      name: t('ขออนุมัติโครงการ'),
      disabled: false,
      content: () => (
        <>
          <TabApprove menu={menu} filters={filters} />
        </>
      ),
    },
    // ************ เนื่องจากมาการเปลี่ยน flow เปลี่ยนแท็บนี้แยกไปเป็นอีก menu ใหม่ จึงปิดแท็บนี้ไว้ 20220428
    // {
    //   key: 2,
    //   name: t('ขอยกเลิกโครงการ'),
    //   disabled: false,
    //   content: () => (
    //     <>
    //       <TabApproveCancel menu={menu} filters={filters} />
    //     </>
    //   ),
    // },
  ]

  return (
    <>
      <TabNew tab={tabs} defaultActiveKey="1" onTabClick={() => {}} />
    </>
  )
}
