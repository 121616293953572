import React, { useState, useEffect } from 'react'
import { Form, Space } from 'antd'
import { ModalNew } from '../../../../../components/modal/Modal'
import { useTranslation } from 'react-i18next'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { RadioGroup, Radio } from '../../../../../components/radio/Radio'
import { InputNew } from '../../../../../components/input/Input'
import { Alert } from '../../../../../components/alert/Alert'
import { regexNumberComma } from '../../../../../utilitys/helper'
import { Select } from '../../../../../components/select/Select'
import SelectChoiceTypes from '../../../../../components/inputfromapi/selectchoicetypes/SelectChoiceTypes'
import SelectChoiceGroups from '../../../../../components/inputfromapi/selectchoicegroups/SelectChoiceGroups'
import { checkLanguageReturnData } from '../assessmentFormfn'
import * as _ from 'lodash'

export default function FormQuestionsModal(props) {
  const { open, modalType, dataQuestList, dataQuestObj, dataQuestSubList } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const formName = 'form-questions-create'

  const [optionFormGroup, setOptionFormGroup] = useState([])
  const [editable, setEditable] = useState(true)

  const [disableField, setDisableField] = useState(true)
  const [reqField, setReqField] = useState(false)

  const alertDup = () => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถเพิ่มข้อที่ซ้ำได้'),
      content: '',
      onOk() {},
    })
  }

  const onClose = (e) => {
    form.resetFields()
    props.close(e)
  }

  const onFinish = async (input) => {
    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกแบบประเมิน'),
      onOk: () => {
        if (modalType === 'add') {
          const dupIndex = _.find(dataQuestSubList, function (o) {
            return Number(o.formSort) === Number(input.formSort) && Number(o.groupSort) === Number(input.groupSort)
          })
          if (dupIndex) {
            alertDup()
          } else {
            const fieldV = form.getFieldValue()
            props.dataOnSave(fieldV)
            form.resetFields()
            props.close(false)
          }
        } else {
          // เชค sort ซ้ำ เมื่อ update
          const fieldV = form.getFieldValue()

          // เชคว่ามีการเปลี่ยนกลุ่มใหม่หรือไม่ ถ้ามีให้ไปเชคว่ากลุ่มใหม่มีลำดับเดียวกันหรือไม่
          if (fieldV.groupSort !== dataQuestObj.groupSort) {
            // หาค่า ที่ลำดับเดียวกันจากกลุ่มใหม่
            const dupIndex = _.find(dataQuestSubList, function (o) {
              return Number(o.formSort) === Number(fieldV.formSort) && Number(o.groupSort) === Number(fieldV.groupSort)
            })
            if (dupIndex) {
              alertDup()
            } else {
              // find new group สำหรับเพิ่ม แถวที่เราย้ายไป หาจาก groupSort ใหม่
              const newG = _.filter(dataQuestSubList, function (o) {
                return o.groupSort === fieldV.groupSort
              })
              // find old group ที่ไม่เอาแถวที่เราย้ายไป จะหาจาก groupSort เก่าและไม่เอา record ที่ไม่เท่ากลับ key ของ record ที่เราย้าย
              const oldG = _.filter(dataQuestSubList, function (o) {
                return o.groupSort === dataQuestObj.groupSort && o.key !== dataQuestObj.key
              })
              // find group อื่นๆที่ไม่เกี่ยวข้องเพื่อให้ค่าของอันอื่นเหมือนเดิม
              const otherG = _.filter(dataQuestSubList, function (o) {
                return o.groupSort !== fieldV.groupSort && o.groupSort !== dataQuestObj.groupSort
              })
              fieldV['key'] = fieldV.formSort
              fieldV['state'] = fieldV.isActive
              fieldV['formRequiredName'] = String(fieldV.formRequired)
                ? String(fieldV.formRequired) === '0'
                  ? 'ไม่จำเป็นต้องตอบ'
                  : 'จำเป็นต้องตอบ'
                : ''
              fieldV['formQuestName'] =
                fieldV.groupSort +
                '.' +
                fieldV.formSort +
                ' ' +
                checkLanguageReturnData(fieldV.formQuestNameTH, fieldV.formQuestNameEN)
              fieldV['formQuestTypeName'] = String(fieldV.formQuestType)
                ? String(fieldV.formQuestType) === '0' || fieldV.formQuestType === null
                  ? 'ไม่ต้องมีคำอธิบาย'
                  : 'มีคำอธิบาย'
                : ''
              newG.push(fieldV)

              // นำ group ทั้งหมดมารวมกัน
              const newArr = [...newG, ...oldG, ...otherG]

              props.dataOnUpdate(newArr)
              form.resetFields()
              props.close(false)
            }
          } else {
            // หาค่า ที่ลำดับเดียวกันจากกลุ่มเดิม
            const dupIndex = _.find(dataQuestSubList, function (o) {
              return (
                Number(dataQuestObj.formSort) !== Number(fieldV.formSort) &&
                Number(o.formSort) === Number(fieldV.formSort) &&
                Number(o.groupSort) === Number(fieldV.groupSort)
              )
            })
            if (dupIndex) {
              alertDup()
            } else {
              const dataRes = _.map(dataQuestSubList, (dt) => {
                if (
                  Number(dt.groupSort) === Number(fieldV.groupSort) &&
                  Number(dt.formSort) === Number(fieldV.formSortOld)
                ) {
                  dt['key'] = fieldV.formSort
                  dt['state'] = fieldV.isActive
                  dt['formRequiredName'] = String(fieldV.formRequired)
                    ? String(fieldV.formRequired) === '0'
                      ? 'ไม่จำเป็นต้องตอบ'
                      : 'จำเป็นต้องตอบ'
                    : ''
                  dt['formQuestName'] =
                    fieldV.groupSort +
                    '.' +
                    fieldV.formSort +
                    ' ' +
                    checkLanguageReturnData(fieldV.formQuestNameTH, fieldV.formQuestNameEN)
                  dt['groupSort'] = fieldV.groupSort
                  dt['formSort'] = fieldV.formSort
                  dt['formQuestNameTH'] = fieldV.formQuestNameTH
                  dt['formQuestNameEN'] = fieldV.formQuestNameEN
                  dt['choiceTypeCode'] = fieldV.choiceTypeCode
                  dt['choiceGroupCode'] = fieldV.choiceGroupCode
                  dt['formQuestType'] = fieldV.formQuestType
                  dt['formRequired'] = fieldV.formRequired
                  dt['isActive'] = fieldV.isActive
                  dt['formQuestTypeName'] = String(fieldV.formQuestType)
                    ? String(fieldV.formQuestType) === '0' || fieldV.formQuestType === null
                      ? 'ไม่ต้องมีคำอธิบาย'
                      : 'มีคำอธิบาย'
                    : ''

                  return dt
                } else {
                  return dt
                }
              })

              props.dataOnUpdate(dataRes)

              form.resetFields()
              props.close(false)
            }
          }
        }
      },
      onCancel() {
        return null
      },
    })
  }

  useEffect(() => {
    if (open) {
      setOptionFormGroup(
        dataQuestList.map((dt, key) => {
          return {
            key: key,
            label: checkLanguageReturnData(dt.formGroupNameTH, dt.formGroupNameEN),
            value: dt.formSort,
            sort: dt,
          }
        }),
      )
      if (modalType === 'view') {
        setEditable(true)
        form.setFieldsValue({
          groupSort: dataQuestObj.groupSort,
          formSort: dataQuestObj.formSort,
          formQuestNameTH: dataQuestObj.formQuestNameTH,
          formQuestNameEN: dataQuestObj.formQuestNameEN,
          choiceTypeCode: dataQuestObj.choiceTypeCode,
          choiceGroupCode: dataQuestObj.choiceGroupCode,
          formQuestType: dataQuestObj.formQuestType,
          formRequired: dataQuestObj.formRequired,
          isActive: dataQuestObj.state,
          choiceTypeName: dataQuestObj.choiceTypeName,
        })
      } else if (modalType === 'edit') {
        setEditable(false)
        if (dataQuestObj.choiceTypeCode !== '20') {
          setDisableField(false)
          setReqField(true)
        } else {
          setDisableField(true)
          setReqField(false)
        }
        form.setFieldsValue({
          groupSort: dataQuestObj.groupSort,
          formSortOld: dataQuestObj.formSort,
          formSort: dataQuestObj.formSort,
          formQuestNameTH: dataQuestObj.formQuestNameTH,
          formQuestNameEN: dataQuestObj.formQuestNameEN,
          choiceTypeCode: dataQuestObj.choiceTypeCode,
          choiceGroupCode: dataQuestObj.choiceGroupCode,
          formQuestType: dataQuestObj.formQuestType,
          formRequired: dataQuestObj.formRequired,
          isActive: dataQuestObj.state,
          choiceTypeName: dataQuestObj.choiceTypeName,
        })
      } else {
        setEditable(false)
      }
    }
  }, [open])

  return (
    <React.Fragment>
      <ModalNew
        okButtonProps={modalType === 'view' ? { style: { display: 'none' } } : {}}
        visible={open}
        testTitle={t('เพิ่มคำถาม')}
        btnName={modalType === 'add' ? t('เพิ่ม') : t('แก้ไข')}
        onSubmit={form.submit}
        onClose={(e) => {
          onClose(e)
        }}
        type="medium"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name="groupSort"
            label={<Label type="tab-header-inactive">{t('กลุ่มคำถาม')}</Label>}
            rules={[{ required: true, message: 'กรุณากรอกกลุ่มคำถาม' }]}
          >
            <Select
              formname={formName}
              data={optionFormGroup}
              placeholder={t('ระบุกลุ่มคำถาม')}
              scrollableId={formName}
              showSearch
              disabled={editable}
              onChange={(e) => {
                form.setFieldsValue(e.value)
              }}
            />
          </Form.Item>
          <Form.Item
            name="formSort"
            label={<Label type="tab-header-inactive">{t('ข้อที่')}</Label>}
            rules={[
              { required: true, message: t('กรุณากรอกข้อที่') },
              {
                pattern: regexNumberComma,
                message: t(`กรุณากรอกด้วยตัวเลข 0-9`),
              },
            ]}
          >
            <InputNew placeholder={t('กรุณาระบุข้อที่')} maxLength={4} disabled={editable} />
          </Form.Item>
          <Form.Item
            name="formQuestNameTH"
            label={<Label type="tab-header-inactive">{t('คำถามภาษาไทย')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกคำถามภาษาไทย') }]}
          >
            <InputNew placeholder={t('กรุณาระบุคำถามภาษาไทย')} maxLength={200} disabled={editable} />
          </Form.Item>
          <Form.Item
            name="formQuestNameEN"
            label={<Label type="tab-header-inactive">{t('คำถามภาษาอังกฤษ')}</Label>}
            rules={[{ required: false, message: t('กรุณากรอกคำถามภาษาอังกฤษ') }]}
          >
            <InputNew placeholder={t('กรุณาระบุคำถามภาษาอังกฤษ')} maxLength={200} disabled={editable} />
          </Form.Item>
          <Form.Item
            name="choiceTypeName"
            label={<Label type="tab-header-inactive">{t('ประเภทคำตอบ')}</Label>}
            rules={[{ required: false, message: t('กรุณากรอกประเภทคำตอบ') }]}
            hidden={true}
          >
            <InputNew placeholder={t('กรุณาระบุประเภทคำตอบ')} disabled={editable} />
          </Form.Item>
          <Form.Item
            name="choiceTypeCode"
            label={<Label type="tab-header-inactive">{t('ประเภทคำตอบ')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกประเภทคำตอบ') }]}
          >
            <SelectChoiceTypes
              formname={formName}
              placeholder={t('ระบุประเภทคำตอบ')}
              handleChange={(e) => {
                if (e.value === '20') {
                  setDisableField(true)
                  setReqField(false)
                  form.setFieldsValue({
                    choiceGroupCode: null,
                    formQuestType: null,
                  })
                } else {
                  setDisableField(false)
                  setReqField(true)
                }
                form.setFieldsValue({
                  choiceTypeCode: e.value,
                  choiceTypeName: checkLanguageReturnData(e.source.choiceTypeNameTH, e.source.choiceTypeNameEN),
                })
              }}
              scrollableId={formName}
              showSearch
              disabled={editable}
            />
          </Form.Item>
          <Form.Item
            name="formRequired"
            label={<Label type="tab-header-inactive">{t('ความจำเป็น')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกความจำเป็น') }]}
          >
            <Select
              formname={formName}
              data={[
                { label: 'ไม่จำเป็นต้องตอบ', value: 0 },
                { label: 'จำเป็นต้องตอบ', value: 1 },
              ]}
              placeholder={t('ระบุความจำเป็น')}
              scrollableId={formName}
              showSearch
              disabled={editable}
              onChange={(e) => {
                form.setFieldsValue(e.value)
              }}
            />
          </Form.Item>
          <Form.Item
            name="choiceGroupCode"
            label={<Label type="tab-header-inactive">{t('กลุ่มของคำตอบ')}</Label>}
            rules={[{ required: reqField, message: t('กรุณากรอกกลุ่มของคำตอบ') }]}
          >
            <SelectChoiceGroups
              formname={formName}
              placeholder={t('ระบุกลุ่มของคำตอบ')}
              scrollableId={formName}
              showSearch
              disabled={disableField ? disableField : editable}
              handleChange={(e) => {
                form.setFieldsValue({
                  choiceGroupCode: e.value,
                })
              }}
            />
          </Form.Item>
          <Form.Item
            name="formQuestType"
            label={<Label type="tab-header-inactive">{t('คำอธิบาย')}</Label>}
            rules={[{ required: false, message: t('กรุณากรอกคำอธิบาย') }]}
          >
            <Select
              formname={formName}
              data={[
                { label: 'ไม่มีคำอธิบายเพิ่มเติม', value: 0 },
                { label: 'มีคำอธิบายเพิ่มเติม', value: 1 },
              ]}
              placeholder={t('ระบุคำอธิบาย')}
              scrollableId={formName}
              showSearch
              disabled={disableField ? disableField : editable}
            />
          </Form.Item>

          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
            <RadioGroup disabled={editable}>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                &nbsp;
                <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </React.Fragment>
  )
}
