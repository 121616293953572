import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Col, Form, Row } from 'antd'

import { ModalNew } from '../../../components/modal/Modal'
import { InputNew } from '../../../components/input/Input'
import { Radio, RadioGroup } from '../../../components/radio/Radio'
import { Alert } from '../../../components/alert/Alert'

import { displayError, regexEng, regexEngNoSpace, regexNumber } from '../../../utilitys/helper'
import { SAVE_PROJECT_SIZE_SETUP } from './graphql/Mutation'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { TextAreaNew } from '../../../components/textarea/TextArea'
// import { SpinnersNew } from '../../../components/spinners/Spinners'
// import { encryptInput } from '../../../utilitys/crypto'

const ProjectSizeSetupForm = ({
  visible = false,
  type_action = 'view',
  dataForm = null,
  onSuccess = () => {},
  onClose = () => {},
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [editable, seteditable] = useState(false)

  useEffect(() => {
    if (type_action) {
      seteditable(type_action !== 'view')
    }
  }, [type_action])

  useEffect(() => {
    if (dataForm) {
      form.setFieldsValue(dataForm)
    }
  }, [dataForm])

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_PROJECT_SIZE_SETUP)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          onSuccess()
        },
      })
    }
  }, [dataSave])

  const onFinish = () => {
    const fields = form.getFieldValue()
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        const dataInput = {
          projectSizeCode: fields.projectSizeCode,
          projectSizeNameTH: fields.projectSizeNameTH,
          manday: fields.manday,
          remark: fields.remark ? fields.remark : null,
          isActive: fields.isActive,
        }

        if (type_action === 'add') {
          dataInput.projectSizeNameEN = fields.projectSizeNameEN ? fields.projectSizeNameEN : fields.projectSizeNameTH
          dataInput.typeAction = 'add'
        } else {
          dataInput.projectSizeNameEN = fields.projectSizeNameEN
          dataInput.typeAction = 'edit'
        }

        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const footerHide = type_action === 'view' ? { okButtonProps: { style: { display: 'none' } } } : {}

  const renderTitle = () => {
    if (type_action === 'add') {
      return 'เพิ่มตั้งค่าขนาดโครงการ'
    }
    if (type_action === 'view') {
      return 'ดูตั้งค่าขนาดโครงการ'
    }
    if (type_action === 'edit') {
      return 'แก้ไขตั้งค่าขนาดโครงการ'
    }

    return ''
  }

  return (
    <React.Fragment>
      {loadingSave && <SpinnersNew />}
      <ModalNew
        visible={visible}
        onSubmit={form.submit}
        onClose={(data) => {
          onClose(data)
          form.resetFields()
        }}
        testTitle={t(renderTitle())}
        typeAction={type_action}
        type="medium"
        {...footerHide}
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 8,
            lg: 8,
          }}
          layout="horizontal"
          name="riskFactorForm"
        >
          <Form.Item
            name="projectSizeCode"
            label={t('รหัสขนาดโครงการ')}
            rules={[
              { required: true, message: t('กรุณาระบุ') },
              {
                pattern: regexEngNoSpace,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษพิมพ์ใหญ่,ตัวเลข A-Z, 0-9 ไม่เกิน 10 ต้ว`),
              },
            ]}
          >
            <InputNew disabled={type_action === 'edit' || !editable} placeholder={t('กรอกรหัสขนาดโครงการ')} />
          </Form.Item>

          <Form.Item
            name="projectSizeNameTH"
            label={t('ขนาดโครงการภาษาไทย')}
            rules={[{ required: true, message: t('กรุณาระบุ') }]}
          >
            <InputNew disabled={!editable} placeholder={t('กรอกขนาดโครงการภาษาไทย')} />
          </Form.Item>
          <Form.Item
            name="projectSizeNameEN"
            label={t('ขนาดโครงการภาษาอังกฤษ')}
            rules={[{ pattern: regexEng, message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`) }]}
          >
            <InputNew disabled={!editable} placeholder={t('กรอกขนาดโครงการภาษาอังกฤษ')} />
          </Form.Item>

          <Form.Item
            name="manday"
            label={t('จำนวนวันทำงาน')}
            rules={[
              { required: true, message: t('กรุณาระบุ') },
              { pattern: regexNumber, message: t(`กรุณากรอกด้วยตัวเลข 0-9`) },
            ]}
          >
            <InputNew disabled={!editable} placeholder={t('กรอกจำนวนวันทำงาน')} />
          </Form.Item>

          <Form.Item name="remark" label={t('คำอธิบายภาษาไทย')}>
            <TextAreaNew
              disabled={!editable}
              placeholder={t('กรอกคำอธิบายภาษาไทย')}
              showCount
              maxLength={300}
              rows={5}
            />
          </Form.Item>

          {/* <Form.Item
            name="remarkEN"
            label={t('คำอธิบายภาษาอังกฤษ')}
            rules={[{ pattern: regexEng, message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`) }]}
          >
            <TextAreaNew
              disabled={!editable}
              placeholder={t('กรอกคำอธิบายภาษาอังกฤษ')}
              showCount
              maxLength={300}
              rows={5}
            />
          </Form.Item> */}

          <Form.Item name="isActive" label={t('สถานะ')} initialValue={1}>
            <RadioGroup disabled={!editable}>
              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                </Col>
                <Col>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </React.Fragment>
  )
}
export default ProjectSizeSetupForm
