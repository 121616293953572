import React, { useState, useEffect } from 'react'
import { Row, Col /*, Table*/ } from 'antd'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { displayError } from '../../../utilitys/helper'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import SendEmailReportModal from '../components/SendEmailReportModal'
import AuditStatusReportFilter from './filters/AuditStatusReportFilter'

import { ExpandStyle } from './css/Styles'
import { EXPORT_AUDIT_STATUS } from './graphql/Query'
const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
}
const AuditStatusReport = (props) => {
  // const navigate = useNavigate()
  const { pCode } = decryptData(localStorage.getItem('user'))
  const { t } = useTranslation()
  const { menu } = props
  const [dataLists, setDataLists] = useState([])
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' },
  ])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)

  /* สำหรับการส่งเมลรายงาน */
  const [visibleSendMail, setVisibleSendMail] = useState(false)
  const [mailValue, setMailValue] = useState()
  const [inputSendEmail, setInputSendEmail] = useState()

  const [exportAuditStatusFn, exportAuditStatus] = useLazyQuery(EXPORT_AUDIT_STATUS)
  const [exportAuditStatusFnMail, exportAuditStatusMail] = useLazyQuery(EXPORT_AUDIT_STATUS)

  useEffect(() => {
    if (exportAuditStatus.data) {
      const { fileUrl } = exportAuditStatus.data.exportAuditStatus
      window.open(fileUrl)
    }

    if (exportAuditStatus.error) {
      const err = exportAuditStatus.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [exportAuditStatus?.data])

  useEffect(() => {
    if (exportAuditStatusMail.error) {
      const err = exportAuditStatusMail.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [exportAuditStatusMail?.data])

  /* สำหรับการส่งเมลรายงาน */
  useEffect(() => {
    if (inputSendEmail) {
      const dataInput = {
        projectCode: mailValue.refCode,
        personnelCode: pCode,
        lang: localStorage.getItem('lang'),
        emailData: inputSendEmail, // สำหรับการส่งเมลรายงาน
      }

      // Call API Export
      exportAuditStatusFnMail({
        variables: {
          input: encryptInput(dataInput),
        },
      })
    }
  }, [inputSendEmail])

  const Columns = [
    {
      title: t('โครงการ'),
      dataIndex: 'projectCode',
      width: '20%',
    },
    {
      title: t('แหล่งที่มาของโครงการ'),
      dataIndex: 'projectRefName',
      width: '10%',
    },
    {
      title: t('วันที่เริ่มต้น'),
      dataIndex: 'dateStart',
      width: '10%',
    },
    {
      title: t('วันที่สิ้นสุด'),
      dataIndex: 'dateEnd',
      width: '10%',
    },
    {
      title: t('หน่วยงานที่รับผิดชอบ'),
      dataIndex: 'organizationName',
      width: '10%',
    },
    {
      title: t('ผู้รับผิดชอบ'),
      dataIndex: 'personnelName',
      width: '10%',
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '10%',
      render: (item) => {
        const data = {
          projectCode: item.projectCode || '',
          projectName: `${item.projectCode} : ${displayText(item.projectNameTH, item.projectNameEN)}`,
          projectRefName: displayText(item.projectRefNameTH, item.projectRefNameEN),
          dateStart: dateDisplay(item.dateStart),
          dateEnd: dateDisplay(item.dateEnd),
          auditTeam: displayText(item.auditTeamNameTH, item.auditTeamNameEN),
          personnelName: displayText(item.personnelNameTH, item.personnelNameEN),
          projectStatusName: displayText(item.projectStatusNameTH, item.projectStatusNameEN),
          lang: localStorage.getItem('lang'),
        }
        const btn = [
          {
            type: 'download',
            onClick: () => {
              exportAuditStatusFn({
                variables: { input: encryptInput(data) },
              })
            },
          },
          {
            type: 'settings',
            onClick: () => {
              setVisibleSendMail(true)
              setMailValue({ reportName: 'รายงานผลการตรวจสอบ', refCode: item.projectCode })
            },
          },
        ]
        return <ButtonGroup menu={menu} icons={btn} />
      },
    },
  ]
  function getDataListFromFilter(value) {
    setDataLists([
      ...value.map((item, idx) => {
        return {
          key: idx + 1,
          projectCode: `${item.projectCode} : ${displayText(item.projectNameTH, item.projectNameEN)}`,
          projectRefName: displayText(item.projectRefNameTH, item.projectRefNameEN),
          dateStart: dateDisplay(item.dateStart),
          dateEnd: dateDisplay(item.dateEnd),
          organizationName: displayText(item.organizationNameTH, item.organizationNameEN),
          personnelName: displayText(item.personnelNameTH, item.personnelNameEN),
          tools: item,
        }
      }),
    ])
  }

  const breadcrumbList = [{ label: t('รายงาน'), path: '/reports' }, { label: t('รายงานผลการตรวจสอบ') }]

  return (
    <>
      <Breadcrumb data={breadcrumbList} title={t('รายงานผลการตรวจสอบ')} />
      <AuditStatusReportFilter
        page={page}
        limit={limit}
        menu={menu}
        sort={sort}
        // reFetch={reFetch}
        getCount={(e) => setCount(e)}
        getValue={(v) => getDataListFromFilter(v)}
      />
      <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="List" toggledrop={'false'}>
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <ExpandStyle>
              <Datatable
                columns={Columns}
                data={dataLists}
                paginationCustom={true}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                total={count}
                pageSize={limit}
                page={page}
                handleTableChange={(e) => {
                  const reNewField = [{ riskFormName: displayText('riskFormTH', 'riskFormEN') }]
                  setSort(handleSort(e?.sorter, reNewField))
                }}
              />
            </ExpandStyle>
          </Col>
        </Row>
      </CardNew>
      <SendEmailReportModal
        menu={menu}
        open={visibleSendMail}
        close={(e) => {
          setVisibleSendMail(e)
        }}
        fileType={{ pdf: true, word: true, excel: true, csv: true }}
        setInputSendEmail={(e) => setInputSendEmail(e)}
        mailValue={mailValue}
      />
    </>
  )
}

export default AuditStatusReport
