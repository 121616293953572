import { gql } from '@apollo/client'

export const GET_ASSESS_RISK_BY_ID = gql`
  query getAssessRiskById($input: AssessRiskInputId!) {
    getAssessRiskById(input: $input) {
      riskResultId
      Year
      riskFormCode
      riskFormTH
      riskFormEN
      operationStatusCode
      organizationNameTH
      organizationNameEN
      makerBy
      makerByName
      responsibilityBy
      riskFormType
      riskResultsFactors {
        riskResultId
        resultScore
        riskResultFacId
        riskFactorCode
        riskFactorNameTH
        riskFactorNameEN
      }
      riskResultDetail {
        riskResultId
        riskFactorCode
        riskResulDetailId
        remark
        impact
        likelihood
        Score
        resultDetail
        factorActCode
        factorActNameTH
        factorActNameEN
        factorActRiskCode
        factorActRiskNameTH
        factorActRiskNameEN
      }
      riskFormulars {
        riskFactorCode
        riskFactorNameEN
        riskFactorNameTH
        riskFormulaCode
        riskFormulaNameEN
        riskFormulaNameTH
        variableName
        variableCode
        variableType
        variableDetailTH
        variableDetailEN
        valueDetail
        valueVariable
        operationType
      }
    }
  }
`
export const GET_LIST_FILTER_ASSESS_RISK = gql`
  query getListFilterAssessRisk($input: ListFilterPagenation!) {
    getListFilterAssessRisk(input: $input) {
      result {
        Year
        riskResultId
        riskFormCode
        riskFormTH
        riskFormEN
        riskModelTH
        riskModelEN
        riskFormType
        responsibilityBy
        makerBy
        dateStart
        dateEnd
        organizationCode
        makerStatusCode
        organizationNameTH
        organizationNameEN
        operationStatusCode
        operationStatusNameTH
        operationStatusNameEN
      }
      limit
      page
      count
    }
  }
`
