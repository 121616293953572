import { gql } from '@apollo/client'

export const GET_CAUSEOFISSUE_PAGINATION = gql`
  query getMasterCauseOfIssuePagination($input: CauseOfIssuePaginationInput!) {
    getMasterCauseOfIssuePagination(input: $input) {
      result {
        causeId
        causeCode
        causeNameTH
        causeNameEN
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_CAUSEOFISSUE_BY_CODE = gql`
  query getMasterCauseOfIssueByCode($causeCode: String!) {
    getMasterCauseOfIssueByCode(causeCode: $causeCode) {
      causeCode
      causeNameTH
      causeNameEN
      isActive
    }
  }
`
