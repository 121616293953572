import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { Select } from '../../../../components/select/Select'
import SelectOperationType from '../../../../components/inputfromapi/selectoperationtype/SelectOperationType'
// import _ from 'lodash'

export const CalculateColumns = ({
  editable = false,
  fncDeleteCalculate = () => {},
  menu,
  variableData,
  form,
  setCalculateDataLists = () => {},
  calculateDataLists = [],
}) => {
  const { t } = useTranslation()
  return [
    {
      title: t('ตัวแปร'),
      dataIndex: 'variableCode',
      width: '20%',
      render: (item, record, index) => (
        <Form.Item
          name={['riskFormulaCalculates', index, 'variableCode']}
          initialValue={item}
          wrapperCol={{
            md: 24,
          }}
          rules={[
            {
              required: true,
              validator(_, value) {
                const result = calculateDataLists.filter((item, key) => {
                  if (key !== index) {
                    return parseInt(item.variableCode) === parseInt(value)
                  }
                })
                if (!value) {
                  return Promise.reject(new Error(t('กรุณาเลือกค่าตัวแปร')))
                } else if (result.length > 0) {
                  return Promise.reject(new Error('ค่าตัวแปรซ้ำ'))
                } else {
                  return Promise.resolve()
                }
              },
            },
          ]}
        >
          <Select
            data={variableData}
            placeholder={t('เลือกค่าตัวแปร')}
            showSearch
            scrollableId="CalculateAndScoreForm"
            disabled={!editable}
            onChange={(e) => {
              let newData = calculateDataLists
              newData[index].variableCode = e
              form.setFieldsValue({ riskFormulaCalculates: newData })
              setCalculateDataLists([...newData])
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: t('ตัวคำนวณ'),
      dataIndex: 'operationType',
      width: '20%',
      render: (item, record, index) => (
        <Form.Item
          name={['riskFormulaCalculates', index, 'operationType']}
          initialValue={item}
          wrapperCol={{ md: 24 }}
          rules={[
            {
              required: true,
              validator(validData) {
                if (calculateDataLists.length > 0) {
                  const result = calculateDataLists.find((item) => {
                    return validData.field === `riskFormulaCalculates.${item.key}.operationType` && !item?.operationType
                  })

                  if (index + 1 === calculateDataLists.length) return Promise.resolve()

                  return result ? Promise.reject(new Error(t('กรุณาเลือกตัวคำนวณ'))) : Promise.resolve()
                }
              },
            },
          ]}
        >
          <SelectOperationType
            formname="CalculateAndScoreForm"
            placeholder={t('เลือกตัวคำนวณ')}
            disabled={!editable}
            handleChange={(e) => {
              let newData = calculateDataLists
              newData[index].operationType = e?.value
              form.setFieldsValue({ riskFormulaCalculates: newData })
              setCalculateDataLists([...newData])
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '8%',
      render: (item, record, index) => {
        const icons = []

        if (editable) {
          icons.push({
            type: 'delete',
            onClick: () => {
              fncDeleteCalculate(index)
            },
          })
        }

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} icons={icons} />
          </div>
        )
      },
    },
  ]
}
