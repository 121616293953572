const codeBasic = `import { Checkbox } from '../../../components/checkbox/Checkbox'

  const [checked, setchecked] = useState(true)

  const onChangeCheckbox = (e) => {
    console.log("checked: ", e.target.checked)
  }

  const onClickButton = () => {
    setchecked(!checked)
  }

  // Basic Checkbox
  <Checkbox onChange={onChangeCheckbox}>Basic เบสิค</Checkbox>

  // Disabled Checkbox
  <Checkbox onChange={onChangeCheckbox} disabled>Disabled</Checkbox>

  <ButtonNew type="primary" onClick={onClickButton} roles={{ type: 'add', menu: menu }}>Toggle</ButtonNew>
  `

const codeRequired = `import { Checkbox } from '../../../components/checkbox/Checkbox'


const [checkedRequired, setcheckedRequired] = useState(false)


  const onChangeRequired = (e) => {
    setcheckedRequired(e.target.checked)
    console.log("check required: ", e.target.checked)
  }

  <Form.Item
    name="required_checkbox"
    valuePropName="checked"  // ต้องมี
    rules={[
      {
        required: true,
        validator: (_, value) =>
          value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
      },
    ]}
  >
    <Checkbox checked={checkedRequired} onChange={onChangeRequired}>
      Required
    </Checkbox>
  </Form.Item>
  `

const codeGroup = `import { Checkbox, CheckboxGroup } from '../../../components/checkbox/Checkbox'

  const plainOptions = [
    { label: 'Apple', value: '01' },
    { label: 'Pear', value: '02' },
    { label: 'Orange', value: '03' },
  ]
  const defaultCheckedList = ['01', '02']

  const [checkedList, setCheckedList] = useState(defaultCheckedList)
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)

  const onChangeFruit = (list) => {
    console.log('list: ', list)
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < plainOptions.length)
    setCheckAll(list.length === plainOptions.length)
  }

  const onCheckAllChange = (e) => {
    setCheckedList( e.target.checked 
      ? plainOptions.map(({ value }) => { return value })
      : [],
    )
    console.log('e.target.checked: ', e.target.checked)
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }


  <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>Check all</Checkbox>

  <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChangeFruit} /> 
  `

export { codeBasic, codeRequired, codeGroup }
