import styled from 'styled-components'
import { colors } from '../../configs/styles.config'

export const UploadsExcelStyle = styled.span`
  .btn-stt-icon-upload {
    float: right;
    display: flex !important;
    flex-direction: row !important;
    justify-content: start !important;
    align-items: center !important;
    border-radius: 3px !important;
    font-size: 14px !important;
    min-width: 69px !important;
    min-height: 37px !important;
  }

  .btn-color-examine-uploads {
    background-color: #f5f5f5 !important;
    border: 1px solid #a7a9ac !important;
    color: ${colors.lightGray2} !important;
    &:hover {
      transition: 200ms !important ;
      background-color: ${colors.lightGrayHover};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.primaryActive};
    }
  }
`

export const UploadExcelInlineStyle = styled.div`
  ${'' /* width prograss */}
  .ant-upload-list-text .ant-upload-list-text-container .ant-upload-list-item-uploading .ant-upload-list-item-progress {
    width: 94%;
  }

  ${'' /* show Icon  */}
  .ant-upload-list-item-card-actions-btn {
    opacity: 1;
  }

  .upload-excel-file-style-inline {
    display: flex;
  }

  .ant-upload.ant-upload-select {
    order: unset;
  }

  .ant-upload-list-item-card-actions {
    display: ${(props) => props.multiRow && 'none !important'};
  }
`

// ${'' /* border: 1px solid #32a1ce; */}
// position: absolute;
//     right: 40%;
