import { Col, Row } from 'antd'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import SoiAuditCommitteeTabIssueOrg from './tab/SoiAuditCommiteeTabIssueOrg'
import { useLocation } from 'react-router-dom'
import { GET_AUDIT_ISSUE_BY_ID } from './graphql/Query'
import { useLazyQuery } from '@apollo/client'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { TabNew } from '../../../components/tab/Tab'
import { Tab1 } from './tab/auditIssue/tab1'

const SoiAuditCommitteeIssueForm = (props) => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const { action } = state

  const [activeTab, setactiveTab] = useState(1)
  const [getData, getDataHandler] = useLazyQuery(GET_AUDIT_ISSUE_BY_ID)

  useEffect(() => {
    if (action !== 'add') {
      getData({
        variables: {
          input: encryptInput(state.issueId),
        },
      })
    }
  }, [])

  const renderTextTitle = () => {
    if (action === 'add') {
      return 'เพิ่มผลการตรวจสอบ'
    }
    if (action === 'edit') {
      return 'แก้ไขผลการตรวจสอบ'
    }

    return 'ดูผลการตรวจสอบ'
  }

  const arrCrumb = [
    { label: t('งานปฎิบัติการตรวจสอบ'), path: '/auditoperations/acresolution' },
    { label: t('บันทึกสรุปประเด็นคณะกรรมการตรวจสอบ') },
    { label: t(renderTextTitle()) },
  ]

  return (
    <>
      <BreadcrumbNew data={arrCrumb} title={t(renderTextTitle())} menu={props.menu} />
      {getDataHandler.loading && <SpinnersNew />}
      <CardNew
        topic={
          state.action === 'add'
            ? t('เพิ่มผลการตรวจสอบ')
            : state.action === 'edit'
            ? t('แก้ไขผลการตรวจสอบ')
            : t('ดูผลการตรวจสอบ')
        }
        icon="Plus"
        toggledrop="false"
      >
        <Row>
          <Col md={24}>
            <TabNew
              activeKey={activeTab}
              onTabClick={(tab) => {
                setactiveTab(tab)
              }}
              tab={[
                {
                  key: 1,
                  name: t('รายละเอียดโครงการ'),
                  disabled: false,
                  content: () => <Tab1 getDataHandler={getDataHandler} action={action} menu={props.menu} />,
                },
                {
                  key: 2,
                  name: t('หน่วยรับตรวจ'),
                  disabled: action === 'add',
                  content: () => (
                    <SoiAuditCommitteeTabIssueOrg getDataHandler={getDataHandler} action={action} menu={props.menu} />
                  ),
                },
              ]}
              defaultActiveKey={1}
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default SoiAuditCommitteeIssueForm
