import i18n from 'i18next'
import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { colors } from '../../../../../configs/styles.config'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'

const ConfirmNotApproveAlert = (param) => {
  let valueModal = ''
  Modal.confirm({
    icon: (
      <div className="modal-confirm-icon">
        <ExclamationCircleOutlined style={{ fontSize: '80px', color: colors.warning }} />
      </div>
    ),
    wrapClassName: 'modal-confirm-custom',
    content: <TextAreaNew showCount maxLength={200} rows={5} onChange={(e) => (valueModal = e.target.value)} />,
    okText: i18n.t('Confirm'),
    cancelText: i18n.t('Cancel'),
    onOk() {
      param.onConfirm(valueModal)
    },
    onCancel() {
      if (typeof param.onOk !== 'undefined') param.onCancel()
    },
    ...param,
  })
}

export default ConfirmNotApproveAlert
