import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import * as _ from 'lodash'
import { useLanguage } from '../../../hooks/useLanguage'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import { handleSort } from '../../../utilitys/pagination'
import { ButtonNew } from '../../../components/button/Button'
import { breadcrumbList } from './utils/breadcrumbData'
import { colummList } from './utils/assessmentColumn'
import { dateDisplay } from '../../../utilitys/dateHelper'
import SendMailModal from './components/modal/SendMailModal'
import { GETFORMASSESSPAGINATION } from './graphql/Query'
import { FORMASSESSDELETE, CANCEL_ASSESSMENT } from './graphql/Mutation'
import { checkLanguageReturnData } from './components/fnForManageAssessment'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { displayError } from '../../../utilitys/helper'

export default function ManageAssessmentList(props) {
  const { menu } = props
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const navigate = useNavigate()
  const userData = decryptData(localStorage.getItem('user'))

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  const [visibleModal, setVisibleModal] = useState(false)
  const [dataList, setDataList] = useState([])
  const [dataSendMail, setDataSendMail] = useState({})

  const [getFormAssessPaginationfn, getFormAssessPagination] = useLazyQuery(GETFORMASSESSPAGINATION)
  const [formAssessDeleteFn, { loading: loadingDelete, error, data: dataDelete }] = useMutation(FORMASSESSDELETE)

  const onSendMail = (data) => {
    setDataSendMail(data)
    setVisibleModal(true)
  }

  const onDelete = (code) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        formAssessDeleteFn({
          variables: {
            input: encryptInput({
              formAssessId: code,
            }),
          },
        })
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    getFormAssessPaginationfn({
      variables: {
        input: encryptInput({
          filters: { organizationCode: userData.orgCode, createdBy: userData.userId },
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (getFormAssessPagination.data) {
      const response = getFormAssessPagination?.data?.getFormAssessPagination

      setCount(response?.count)
      setDataList(
        _.map(response?.result, (dt, keys) => {
          return {
            key: keys,
            formAssessId: dt.formAssessId,
            projectCode: dt.projectCode,
            formCode: dt.formCode,
            projectName: checkLanguageReturnData(dt.projectNameTH, dt.projectNameEN),
            formTypeName: checkLanguageReturnData(dt.formTypeNameTH, dt.formTypeNameEN),
            formName: checkLanguageReturnData(dt.formNameTH, dt.formNameEN),
            startDate: dateDisplay(dt.startDate),
            endDate: dateDisplay(dt.endDate),
            operationStatusCode: dt.operationStatusCode,
            status: dt.isActive,
            formTypeCode: dt.formTypeCode,
            auditProjectPersonnelCode: dt.auditProjectPersonnelCode,
            email: dt.email,
          }
        }),
      )
    }
    if (getFormAssessPagination.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getFormAssessPagination.error)),
        onOk() {},
      })
    }
  }, [getFormAssessPagination.data])

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          getFormAssessPagination.refetch()
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [dataDelete])

  const [cancelAssessFn, { loading: loadingCancelAssess, error: errorCancelAssess, data: dataCancelAssess }] =
    useMutation(CANCEL_ASSESSMENT)

  const onCancel = (code) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยกเลิกแบบประเมิน ?'),
      onOk() {
        cancelAssessFn({
          variables: {
            input: encryptInput({
              formAssessId: code,
            }),
          },
        })
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    if (dataCancelAssess) {
      Alert({
        type: 'success',
        title: t('ยกเลิกสำเร็จ'),
        onOk() {
          getFormAssessPagination.refetch()
        },
      })
    }

    if (errorCancelAssess) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถยกเลิกได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [dataCancelAssess])

  return (
    <React.Fragment>
      {(getFormAssessPagination.loading || loadingCancelAssess) && <SpinnersNew />}
      {loadingDelete && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('จัดการแบบประเมิน')} />
      <CardNew topic={t('รายการแบบประเมิน')} icon="List" toggledrop={'false'}>
        <Datatable
          columns={colummList({ menu, onSendMail, onDelete, onCancel })}
          data={dataList}
          total={count}
          isScoll={{ x: true, y: true }}
          onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
          paginationCustom={true}
          searchCustom={true}
          pageSize={limit}
          page={page}
          handleTableChange={(e) => {
            const lang = isLang === 'th' ? true : false
            const reNewField = [
              { projectName: lang ? 'projectNameTH' : 'projectNameEN' },
              { formTypeName: lang ? 'formTypeNameTH' : 'formTypeNameEN' },
              { formName: lang ? 'formNameTH' : 'formNameEN' },
              { status: 'isActive' },
            ]
            setSort(handleSort(e?.sorter, reNewField))
          }}
          btnAdd={
            <>
              <ButtonNew
                type="plusTableBorderPrimary"
                roles={{ type: 'add', menu: menu }}
                onClick={() => {
                  navigate('/auditoperations/createassessmentform/createassessmentformdetail/create')
                }}
              >
                {t('เพิ่มแบบประเมิน')}
              </ButtonNew>
            </>
          }
        />
      </CardNew>
      <SendMailModal
        dataSendMail={dataSendMail}
        open={visibleModal}
        onSuccess={(e) => {
          getFormAssessPagination.refetch()
          setVisibleModal(e)
        }}
        close={(e) => setVisibleModal(e)}
        menu={menu}
      />
    </React.Fragment>
  )
}
