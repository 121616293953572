import { gql } from '@apollo/client'

export const SAVE_AUDIT_RESULT = gql`
  mutation AuditResultSave($input: AuditIssuesInput!) {
    auditResultSave(input: $input) {
      issueId
    }
  }
`

export const SAVE_AUDIT_RESULT_INSPEC_TION = gql`
  mutation AuditResultInspectionSave($input: AuditIssueOrganizationsInput!) {
    auditResultInspectionSave(input: $input) {
      issueId
    }
  }
`

export const SAVE_AUDIT_RESULT_FILE = gql`
  mutation AuditResultFileSave($input: AuditIssuesInput!) {
    auditResultFileSave(input: $input) {
      issueId
    }
  }
`

export const DELETE_AUDIT_ISSUE = gql`
  mutation AuditIssueDelete($issueId: String!, $projectCode: String!) {
    auditIssueDelete(issueId: $issueId, projectCode: $projectCode) {
      issueId
    }
  }
`

export const SAVE_AUDIT_CAUSE_OF_ISSUES = gql`
  mutation AuditCauseOfIssuesSave($input: AuditCauseOfIssuesInput!) {
    auditCauseOfIssuesSave(input: $input) {
      causeCode
    }
  }
`

export const DELETE_AUDIT_CAUSE_OF_ISSUES = gql`
  mutation AuditCauseOfIssuesDelete($causeId: String!) {
    auditCauseOfIssuesDelete(causeId: $causeId) {
      causeId
    }
  }
`

export const SAVE_FEED_BACK = gql`
  mutation FeedBackSave($input: AuditSuggestOfIssuesInput!) {
    feedBackSave(input: $input) {
      suggestId
    }
  }
`

export const DELETE_AUDIT_SUGGEST_OF_ISSUES = gql`
  mutation AuditSuggestOfIssuesDelete($suggestId: String!) {
    auditSuggestOfIssuesDelete(suggestId: $suggestId) {
      suggestId
    }
  }
`

export const SAVE_SEND_EMAIL = gql`
  mutation SendEmailSave($input: SendMailInput!) {
    sendEmailSave(input: $input) {
      sendId
    }
  }
`

export const SAVE_AUDIT_ISSUE = gql`
  mutation AuditIssuesSave($issueId: String!) {
    auditIssuesSave(issueId: $issueId) {
      issueId
    }
  }
`

export const SAVE_STATEMENT = gql`
  mutation StatementSave($input: AuditSuggestExplainInput!) {
    statementSave(input: $input) {
      explainId
    }
  }
`
