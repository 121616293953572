import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col, Row, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_AUDIT_RCM_RESULT } from './graphql/Query'
import { UPDATE_AUDIT_RCMS_RESULT } from './graphql/Mutation'
import { CardNew } from '../../../components/card/Card'
import { Select } from '../../../components/select/Select'
import { ButtonNew } from '../../../components/button/Button'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Datatable } from '../../../components/datatable/Datatable'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { racMatrixEditrumb } from './utils/constants'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText, displayError } from '../../../utilitys/helper'
import { colors } from '../../../configs/styles.config'

const RacMatrixRiskFormTopic = (props) => {
  const responsiveLabel = { sm: 10, md: 8, lg: 6 }
  const responsiveData = { sm: 14, md: 16, lg: 18 }

  const { auditResult } = props

  const { t } = useTranslation()

  const [personnelName, setPersonnelName] = useState(null)

  useEffect(() => {
    const personnelRCMName = displayText(auditResult?.personnelRCMNameTH, auditResult?.personnelRCMNameEN)

    setPersonnelName(personnelRCMName ? personnelRCMName : `-`)
  }, [auditResult])

  return (
    <CardNew topic={t('รายละเอียดความเสี่ยงกระบวนการ')} icon="Info" toggledrop={'false'}>
      <Col span={24} offset={2}>
        <Row gutter={[16, 24]}>
          <Col {...responsiveLabel}>{t('กระบวนการหลัก')}</Col>
          <Col {...responsiveData} style={{ color: colors.primary }}>
            {`${displayText(auditResult?.riskModelTH, auditResult?.riskModelEN)}`}
          </Col>

          <Col {...responsiveLabel}>{t('กลุ่มความเสี่ยงกระบวนการ')}</Col>
          <Col {...responsiveData} style={{ color: colors.primary }}>
            {`${
              auditResult?.rateFacGroupDetail
                ? auditResult?.rateFacGroupDetail
                : displayText(auditResult?.rateFacGroupNameTH, auditResult?.rateFacGroupNameEN)
            }`}
          </Col>

          <Col {...responsiveLabel}>{t('ความเสี่ยงกระบวนการ')}</Col>
          <Col {...responsiveData} style={{ color: colors.primary }}>
            {`${auditResult?.shortCode}: ${displayText(auditResult?.rateFacProNameTH, auditResult?.rateFacProNameEN)}`}
          </Col>

          <Col {...responsiveLabel}>{t('ผู้รับผิดชอบ')}</Col>
          <Col {...responsiveData} style={{ color: colors.primary }}>
            {`${personnelName}`}
          </Col>
        </Row>
      </Col>
    </CardNew>
  )
}

const selectData = [
  { label: '0', value: 0 },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
]

const RacMatrixRiskForm = (props) => {
  const wrapper = { sm: 24, md: 24, lg: 24 }
  const { menu } = props
  const formname = 'RacMatrixRiskForm'

  const navigate = useNavigate()
  const { state } = useLocation()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  if (!state || !state?.rcmResultId || state?.typeAction === 'view')
    return <Navigate to={'/auditoperations/racmatrix'} />

  const [dataLists, setDataLists] = useState([])

  const [getAuditRCMResultFn, getAuditRCMResult] = useLazyQuery(GET_AUDIT_RCM_RESULT)
  const [updateAuditRMCResultFn, updateAuditRMCResult] = useMutation(UPDATE_AUDIT_RCMS_RESULT)

  useEffect(() => {
    if (state?.rcmResultId && state?.typeAction === 'edit') {
      getAuditRCMResultFn({ variables: { rcmResultId: encryptInput(state?.rcmResultId) } })
    }
  }, [state?.rcmResultId, state?.typeAction])

  useEffect(() => {
    if (getAuditRCMResult?.data) {
      const result = getAuditRCMResult?.data?.getAuditRCMResult?.resultDetails

      const ansResult = _.map(result, (d) => {
        return {
          key: d.rcmResultDetailId,
          rcmResultDetailId: d.rcmResultDetailId,
          riskFactorName: displayText(d.riskFactorNameTH, d.riskFactorNameEN),
          likelihood: d.likelihood || 0,
          impact: d.impact || 0,
        }
      })

      const setForm = {}
      for (const d of result) setForm[d.rcmResultDetailId] = { impact: d.impact || 0, likelihood: d.likelihood || 0 }

      form.setFieldsValue(setForm)
      setDataLists(ansResult)
    }

    if (getAuditRCMResult.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditRCMResult?.error)),
        onOk() {},
      })
    }
  }, [getAuditRCMResult?.data])

  useEffect(() => {
    if (updateAuditRMCResult?.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          navigate('/auditoperations/racmatrix/tab/risk', { state: { ...state } })
        },
      })
    }

    if (updateAuditRMCResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(updateAuditRMCResult?.error)),
        onOk() {},
      })
    }
  }, [updateAuditRMCResult?.data])

  const onFinish = (data) => {
    const results = []

    Object.keys(data).forEach((d) => {
      results.push({
        rcmResultDetailId: parseInt(d),
        likelihood: data[d].likelihood,
        impact: data[d].impact,
      })
    })

    // const validateResult = _.find(results, (d) => d.likelihood && d.impact)

    // if (!validateResult) {
    //   Alert({
    //     type: 'warning',
    //     title: t('กรุณาประเมินความเสี่ยง'),
    //     onOk() {},
    //   })
    // } else {
    //   Alert({
    //     type: 'confirm',
    //     title: t('ต้องการยืนยันการบันทึก?'),
    //     onOk() {
    //       updateAuditRMCResultFn({
    //         fetchPolicy: 'no-cache',
    //         variables: { input: encryptInput({ rcmResultId: state?.rcmResultId, auditRCMResultDetail: results }) },
    //       })
    //     },
    //     onCancel() {},
    //   })
    // }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        updateAuditRMCResultFn({
          fetchPolicy: 'no-cache',
          variables: { input: encryptInput({ rcmResultId: state?.rcmResultId, auditRCMResultDetail: results }) },
        })
      },
      onCancel() {},
    })
  }

  const columns = [
    {
      title: t('ความเสี่ยง'),
      dataIndex: 'riskFactorName',
      width: '65%',
      align: 'left',
    },
    {
      title: t('มีโอกาสเกิด'),
      dataIndex: 'likelihood',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        return (
          <Form.Item name={[record.key, 'likelihood']}>
            <Select
              data={selectData}
              placeholder={t('เลือก')}
              scrollableId={formname}
              disabled={false}
              showSearch
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(e) => {
                form.setFieldsValue({ [record.key]: { likelihood: e || 0 } })
              }}
            />
          </Form.Item>
        )
      },
    },
    {
      title: t('มีผลกระทบ(ข้อ)'),
      dataIndex: 'impact',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        return (
          <Form.Item name={[record.key, 'impact']}>
            <Select
              data={selectData}
              placeholder={t('เลือก')}
              scrollableId={formname}
              disabled={false}
              showSearch
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={(e) => form.setFieldsValue({ [record.key]: { impact: e || 0 } })}
            />
          </Form.Item>
        )
      },
    },
  ]

  return (
    <>
      {(getAuditRCMResult?.loading || updateAuditRMCResult?.loading) && <SpinnersNew />}
      <Col {...wrapper}>
        <Breadcrumb data={racMatrixEditrumb} title={t('รายละเอียดประเมินความเสี่ยงและการควบคุม')} />
        <RacMatrixRiskFormTopic auditResult={getAuditRCMResult?.data?.getAuditRCMResult?.result} />
        <CardNew topic={displayText(t('รายละเอียดประเมินความเสี่ยงและการควบคุม'))} icon="List" toggledrop={'false'}>
          <Row gutter={[12, 12]}>
            <Col {...wrapper}>
              <Form name={formname} form={form} layout="vertical" onFinish={onFinish}>
                <Datatable columns={columns} data={dataLists} enableScroll={{ x: true, y: false }} />
                <Row gutter={[12, 20]} justify="center" style={{ marginTop: '2rem' }}>
                  <Col>
                    <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                      {t('บันทึก')}
                    </ButtonNew>
                  </Col>
                  <Col>
                    <ButtonNew
                      type="back"
                      onClick={() => navigate('/auditoperations/racmatrix/tab/risk', { state: { ...state } })}
                    >
                      {t('ย้อนกลับ')}
                    </ButtonNew>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </CardNew>
      </Col>
    </>
  )
}

RacMatrixRiskForm.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default RacMatrixRiskForm
