import { gql } from '@apollo/client'
export const GET_SELECT_RISK_FACTORS_EXAMINE = gql`
  query GetSelectRiskFactorExamine($input: SelectRiskFactorExamineInput!) {
    getSelectRiskFactorExamine(input: $input) {
      rateFacProCode
      rateFacProNameTH
      rateFacProNameEN
    }
  }
`
