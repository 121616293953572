export const CalLevel = (scoreWeight) => {
  const score = scoreWeight ? parseFloat(scoreWeight).toFixed(2) : 0
  if (score >= 3.25) {
    return 'VH'
  } else if (score >= 2.5 && score < 3.25) {
    return 'H'
  } else if (score >= 1.75 && score < 2.5) {
    return 'M'
  } else if (score < 1.75) {
    return 'L'
  }
}
