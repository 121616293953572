/* eslint-disable no-unused-vars */
import React from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
// import PropTypes from 'prop-types'
// import _ from 'lodash'
import { IconNew } from '../icon/Icon'
import './CalendarStyles.scss'

moment.locale(localStorage.getItem('lang'))
const localizer = momentLocalizer(moment)

export const CalendarNew = (props) => {
  const { dateCellWrapperCustom, ...otherProps } = props

  // const evenClick = (e) => {
  //   console.log('evenClick: ', e)
  // }

  // function Book() {
  //   return <div className="rbc-day-bg rbc-off-range-bg">{/* <button>Book Class</button> */}</div>
  // }

  const Event = ({ event }) => {
    return event.custom ? event.custom : <span>{event.title}</span>
  }

  const DefaultDateCellWrapper = ({ children }) =>
    React.cloneElement(React.Children.only(children), {
      style: {
        ...children.style,
      },
    })

  return (
    <>
      <Calendar
        localizer={localizer}
        events={props.eventList}
        defaultDate={moment().toDate()}
        defaultView="month"
        startAccessor="start"
        endAccessor="end"
        style={{ height: '100vh' }}
        // onSelectSlot={(slotInfo) => {
        //   console.log('slotInfo: ', slotInfo)
        // }}
        // eventPropGetter={evenClick}
        // onSelectEvent={evenClick}
        selectable
        popup={true}
        tooltipAccessor={() => null}
        components={{
          event: Event,
          toolbar: CustomToolbar,
          dateCellWrapper: dateCellWrapperCustom || DefaultDateCellWrapper,
        }}
        {...otherProps}
      />
    </>
  )
}

const CustomToolbar = (props) => {
  let { label } = props
  const label_err = label.split(' ')
  const label_txt =
    label_err[0] + ' ' + (localStorage.getItem('lang') === 'th' ? parseInt(label_err[1]) + 543 : label_err[1])

  let navigateText = {
    PREVIOUS: 'PREV',
    NEXT: 'NEXT',
    TODAY: 'TODAY',
    DATE: 'DATE',
  }

  const navigate = (action) => {
    props.onNavigate(action)
  }

  return (
    <>
      <div className="rbc-toolbar">
        <span className="rbc-btn-group today">
          <button type="button" className="btn-today" onClick={navigate.bind(null, navigateText.TODAY)}>
            <IconNew icon={'Calendar'} size={20} color="#FFF" />
          </button>
        </span>
        <span className="rbc-btn-group">
          <button type="button" className="btn-prev" onClick={navigate.bind(null, navigateText.PREVIOUS)}>
            <IconNew icon={'ChevronLeft'} size={20} color="#FFF" />
          </button>
        </span>
        <span className="rbc-toolbar-label">{label_txt}</span>
        <span className="rbc-btn-group">
          <button type="button" className="btn-next" onClick={navigate.bind(null, navigateText.NEXT)}>
            <IconNew icon={'ChevronRight'} size={20} color="#FFF" />
          </button>
        </span>
      </div>
    </>
  )
}

// UploadNew.propTypes = {
//   idFile: PropTypes.array.isRequired,
//   setIdFile: PropTypes.func.isRequired,
//   typeFile: PropTypes.string.isRequired,
//   type: PropTypes.string,
// }
