import { gql } from '@apollo/client'

export const GET_RATE_RISK_CONTROL = gql`
  query getRateRiskControl($input: RateRiskControlInput!) {
    getRateRiskControl(input: $input) {
      rateRisConkId
      rateRiskConCode
      rateRiskConTH
      rateRiskConEN
      rateRiskRuleCode
      controlNatureCode
      controlTypeCode
      sort
      isActive
    }
  }
`
