import { useLazyQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayError, displayText } from '../../../utilitys/helper'
import { GET_LIST_AUDIT_JOB_STEP_BY_ACTION_PLAN_ID } from './graphql/Query'
import { Columns } from './utils/AuditJobStepViewListColumn'

const Responsive = {
  md: 24,
  lg: 24,
}

const AuditJobStepViewList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { state } = useLocation()

  if (!state) {
    return <Navigate to={'/auditoperations/approveactionplan'} />
  }
  const { refCode = null } = state

  const arrCrumb = [
    { label: t('งานปฎิบัติการตรวจสอบ'), path: '/auditoperations/approveactionplan' },
    { label: t('อนุมัติแผนปฏิบัติงาน'), path: '/auditoperations/approveactionplan' },
    { label: t('ขั้นตอนการปฏิบัติงาน') },
  ]

  const [dataRow, setdataRow] = useState([])

  const [callList, { loading: loadingList, error: errorList, data: dataList }] = useLazyQuery(
    GET_LIST_AUDIT_JOB_STEP_BY_ACTION_PLAN_ID,
  )

  useEffect(() => {
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: displayError(errorList),
      })
    }
  }, [errorList])

  useEffect(() => {
    if (dataList) {
      const { getListAuditJobStepByActionPlanID } = dataList

      let noSub = ''
      let dataTemp = getListAuditJobStepByActionPlanID.map((item, key) => {
        let stepName = ''
        if (item.stepType === 1) {
          stepName = (
            <span style={{ fontWeight: 'bold' }}>
              {item.stepSort + '.' + displayText(item.stepNameTH, item.stepNameEN)}
            </span>
          )
          noSub = item.stepSort
        } else {
          stepName = (
            <>&emsp;{'' + noSub + '.' + item.stepSort + '.' + displayText(item.stepNameTH, item.stepNameEN)} </>
          )
        }

        let operationStatusName = displayText(item.operationStatusNameTH, item.operationStatusNameEN)
        return {
          ...item,
          key: key + 1,
          stepName,
          operationStatusName,
          dateStart: dateDisplay(item.dateStart),
          dateEnd: dateDisplay(item.dateEnd),
        }
      })

      setdataRow(dataTemp)
    }
  }, [dataList])

  useEffect(() => {
    fncGetList()
  }, [])

  const fncGetList = () => {
    const dataInput = {
      actionPlanId: refCode,
    }

    callList({
      variables: { input: encryptInput(dataInput) },
    })
  }

  return (
    <React.Fragment>
      <BreadcrumbNew data={arrCrumb} title={t('อนุมัติแผนปฏิบัติงาน')} />
      <CardNew topic={t('ขั้นตอนการปฏิบัติงาน')} icon="List">
        <Row>
          <Col {...Responsive}>
            <Datatable
              columns={Columns()}
              data={dataRow}
              searchCustom={true}
              isLoading={loadingList}
              scroll={{ x: 1448 }}
              enableScroll={{ x: true, y: false }}
            />
          </Col>
        </Row>
        <Row gutter={[12, 12]} justify="center" style={{ marginBottom: 20, marginTop: 40 }}>
          <Col>
            <ButtonNew onClick={() => navigate(`/auditoperations/approveactionplan`)} type="back">
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Col>
        </Row>
      </CardNew>
    </React.Fragment>
  )
}

export default AuditJobStepViewList
