import { useLazyQuery } from '@apollo/client'
import { Col, Form, Row } from 'antd'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '../../../../components/alert/Alert'

import { LabelNew } from '../../../../components/label/Label'
import { ModalNew } from '../../../../components/modal/Modal'
import { Radio, RadioGroup } from '../../../../components/radio/Radio'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayError } from '../../../../utilitys/helper'

import { GET_EXPORT_ACTION_PLAN } from '../graphql/Query'

const ExportModal = ({ visible, onModalClose, actionPlanId }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  function onClose() {
    form.resetFields()
    onModalClose()
  }

  const [callExport, { data: dataExport, loading: loadingExport, error: errorExport }] =
    useLazyQuery(GET_EXPORT_ACTION_PLAN)

  useEffect(() => {
    if (dataExport) {
      const { exportActionPlan } = dataExport
      if (exportActionPlan.message === 'succcess') {
        window.open(`${exportActionPlan.fileUrl}`, '_blank')

        form.resetFields()
        onModalClose()
      }
      if (errorExport) {
        Alert({
          type: 'error',
          title: t('ไม่สามารถโหลดข้อมูลได้'),
          content: t(displayError(errorExport)),
          onOk() {},
        })
      }
    }
  }, [dataExport])

  function onFinish() {
    const { fileType } = form.getFieldValue()

    const dataInput = {
      actionPlanId,
      lang: localStorage.getItem('lang') === 'en' ? 'en' : 'th',
      fileType,
    }

    callExport({ variables: { input: encryptInput(dataInput) } })
  }

  return (
    <>
      {loadingExport && <SpinnersNew />}
      <ModalNew
        visible={visible}
        testTitle={t('พิมพ์รายงาน')}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        btnName={t('พิมพ์')}
        // type="medium"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{ span: 8, offset: 2 }}
          wrapperCol={{ span: 10 }}
          layout="horizontal"
          name="auditTeamForm"
        >
          <Form.Item
            label={<LabelNew type="tab-header-inactive">{t('ประเภทรายงาน')}</LabelNew>}
            name="fileType"
            initialValue={'excel'}
          >
            <RadioGroup>
              <Row gutter={[4, 4]}>
                <Col span={12}>
                  <Radio value="excel">
                    <LabelNew type="tab-header-inactive">{t('Excel')}</LabelNew>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="csv">
                    <LabelNew type="tab-header-inactive">{t('CSV')}</LabelNew>
                  </Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}

export default ExportModal
