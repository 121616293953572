import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import * as _ from 'lodash'
import moment from 'moment'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { handleSort } from '../../../utilitys/pagination'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { GET_SATISFACTION_ASSESSMENT_PAGINATION } from './graphql/Query'
import { displayText } from '../../../utilitys/helper'
import { Status } from '../../../components/status/Status'
import { CheckStatus } from './utils/CheckStatus'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { ButtonNew } from '../../../components/button/Button'
import { Columns } from './utils/SatisfactionAssessmentColumn'
import ForwardModal from './components/modal/ForwardModal'
import { Col, Row } from 'antd'
import { RowStyle } from './css/tableExpireStyle'

const SatisfactionAssessmentList = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const userData = decryptData(localStorage.getItem('user'))
  const Responsive = {
    md: 24,
    lg: 24,
  }
  const [dataList, setDataList] = useState([])
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])

  const [getSatisfactionAssessmentPaginationFn, getSatisfactionAssessmentPagination] = useLazyQuery(
    GET_SATISFACTION_ASSESSMENT_PAGINATION,
  )

  useEffect(() => {
    getSatisfactionAssessmentPaginationFn({
      variables: {
        input: encryptInput({
          filters: {
            personnelCode: userData.pCode,
          },
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (getSatisfactionAssessmentPagination?.data) {
      let response = getSatisfactionAssessmentPagination?.data?.getSatisfactionAssessmentPagination
      setCount(response?.count)
      setDataList(
        _.map(response?.result, (data, index) => {
          let statusText = CheckStatus({
            responsibilityBy: data.responsibilityBy,
            makerBy: data.makerBy,
            operationStatusCode: data.operationStatusCode,
            makerStatusCode: data.makerStatusCode,
            userCode: userData.pCode,
          })
          return {
            ...data,
            key: index + 1,
            formYear: displayText(data.formYear + 543, data.formYear),
            formCode: data.formCode,
            formName: displayText(data.formNameTH, data.formNameEN),
            startDate: dateDisplay(data.startDate),
            endDate: dateDisplay(data.endDate),
            rawEndDate: data.endDate,
            rawOperationStatusCode: data.operationStatusCode,
            status: <Status text={statusText.statusUse} />,
            operationStatusCode: (
              <ButtonNew
                type={statusText.btnName}
                roles={{ type: statusText.btnRoles, menu: menu }}
                onClick={() =>
                  navigate('/auditoperations/satisfactionassessment/details', {
                    state: { type_action: statusText.btnRoles, formResultId: data.formResultId },
                  })
                }
              >
                {statusText.btnText}
              </ButtonNew>
            ),
            tools:
              statusText.statusUse === 'ยังไม่ประเมิน' || statusText.statusUse === 'ส่งต่อแบบประเมิน' ? (
                <div style={{ textAlign: 'right' }}>
                  <ButtonGroup
                    menu={menu}
                    icons={[
                      {
                        type: 'send',
                        onClick: () => {
                          setVisible(true)
                          setData({ data: data, type_action: 'send' })
                        },
                      },
                      {
                        type: 'view',
                        onClick: () =>
                          navigate('/auditoperations/satisfactionassessment/details', {
                            state: { type_action: 'view', formResultId: data.formResultId },
                          }),
                      },
                    ]}
                  />
                </div>
              ) : (
                <div style={{ textAlign: 'right' }}>
                  <ButtonGroup
                    menu={menu}
                    icons={[
                      {
                        type: 'view',
                        onClick: () =>
                          navigate('/auditoperations/satisfactionassessment/details', {
                            state: { type_action: 'view', formResultId: data.formResultId },
                          }),
                      },
                    ]}
                  />
                </div>
              ),
          }
        }),
      )
    }
  }, [getSatisfactionAssessmentPagination?.data])

  return (
    <>
      {getSatisfactionAssessmentPagination.loading && <SpinnersNew />}
      <Col {...Responsive}>
        <CardNew topic={t('รายการประเมินความพึงพอใจ')} icon="List" toggledrop={'false'}>
          <Row gutter={[12, 12]}>
            <Col {...Responsive}>
              <RowStyle>
                <Datatable
                  columns={Columns}
                  data={[...dataList]}
                  total={count}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  searchCustom={true}
                  pageSize={limit}
                  page={page}
                  scroll={{ x: 1000, y: false }}
                  handleTableChange={(e) => {
                    const lang = localStorage.getItem('lang') === 'th' ? true : false
                    const reNewField = [{ formName: lang ? 'formNameTH' : 'formNameEN' }, { status: 'isActive' }]
                    setSort(handleSort(e?.sorter, reNewField))
                  }}
                  rowClassName={(record) => {
                    const now = moment().format('YYYY-MM-DD 00:00:00')
                    const endDate = moment(record.rawEndDate).format('YYYY-MM-DD 23:59:59')
                    const statusCheck = ['30', '31']

                    if (
                      endDate < now &&
                      !statusCheck.includes(record.rawOperationStatusCode) &&
                      userData.pCode === record.responsibilityBy
                    ) {
                      return 'table-row-color'
                    } else if (
                      endDate < now &&
                      !statusCheck.includes(record.makerStatusCode) &&
                      userData.pCode === record.makerBy
                    ) {
                      return 'table-row-color'
                    } else {
                      return ''
                    }
                  }}
                />
              </RowStyle>
            </Col>
          </Row>
        </CardNew>
      </Col>
      <ForwardModal
        open={visible}
        close={(e) => {
          setVisible(e)
          setData()
        }}
        refetch={() => getSatisfactionAssessmentPagination.refetch()}
        dataParams={data}
        menu={menu}
      />
    </>
  )
}
export default SatisfactionAssessmentList
