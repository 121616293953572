import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Form, Space, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_PERSONNEL_BY_ORGS } from '../../graphql/Query'
import { SEND_EMAIL_CONTROL_FORM } from '../../graphql/Mutation'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'
import { encryptInput, decryptData } from '../../../../../utilitys/crypto'
import { convertEmailJson, displayError } from '../../../../../utilitys/helper'
import { ModalNew } from '../../../../../components/modal/Modal'
import { InputNew } from '../../../../../components/input/Input'
import { ButtonNew } from '../../../../../components/button/Button'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { Select as SelectNew } from '../../../../../components/select/Select'
import { Alert } from '../../../../../components/alert/Alert'
import SelectTagEmailPersonnels from '../../../../../components/inputfromapi/selecttagemailpersonnels/SelectTagEmailPersonnels'

const EmailModal = (props) => {
  const formname = 'EmailModalForm'
  const labelCol = { sm: 14, md: 6, lg: 6, xl: 6 }
  const wrapperCol = { sm: 16, md: 18, lg: 18, xl: 18, align: 'left' }

  const { controlFormCode, open, close, getSendMail, menu } = props

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [user] = useDecodeUser()
  const [visible, setVisible] = useState(false)

  const [sendMailFn, sendMail] = useMutation(SEND_EMAIL_CONTROL_FORM)
  const [getPersonnelByOrganizationFn, getPersonnelByOrganization] = useLazyQuery(GET_PERSONNEL_BY_ORGS)

  useEffect(() => {
    setVisible(open)
  }, [open])

  useEffect(() => {
    if (controlFormCode) {
      getPersonnelByOrganizationFn({ variables: { controlFormCode: encryptInput(controlFormCode?.controlFormCode) } })
    }
  }, [controlFormCode])

  useEffect(() => {
    if (getPersonnelByOrganization?.data?.getPersonnelsByOrganizarion) {
      const result = getPersonnelByOrganization?.data?.getPersonnelsByOrganizarion

      form.setFieldsValue({
        sendTo: _.map(result, (d) => JSON.stringify({ personnelCode: d.personnelCode, email: decryptData(d.email) })),
        sendToCopy: [JSON.stringify({ personnelCode: user.pCode, email: user.personnelEmail })],
        personnelCode: _.map(result, 'personnelCode'),
      })
    }

    if (getPersonnelByOrganization?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getPersonnelByOrganization?.error)),
        onOk() {},
      })
    }
  }, [getPersonnelByOrganization.data])

  useEffect(() => {
    if (sendMail?.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk: () => {
          Promise.all([form.resetFields(), setVisible(false), getSendMail(sendMail.data), close(false)])
        },
        onCancel: () => {},
      })
    }

    if (sendMail.error) {
      Alert({
        type: 'error',
        title: t('บันทึกข้อมูลการแจ้งเตือนไม่สำเร็จ'),
        content: t(displayError(sendMail?.error)),
        onOk() {},
      })
    }
  }, [sendMail.data])

  const onFinish = async (data) => {
    const objData = encryptInput({
      dear: data.dear,
      personnelCode: data.personnelCode,
      sendTo: convertEmailJson(data.sendTo),
      sendToCopy: convertEmailJson(data.sendToCopy),
      sendToSecret: convertEmailJson(data.sendToSecret),
      subject: data.subject,
      menuCode: 'IC01',
      userId: user.userId,
      sendBy: user.pCode + '',
      organizationCode: user.orgCode,
      controlForm: {
        controlFormCode: controlFormCode.controlFormCode,
        controlFormEN: controlFormCode.controlFormEN,
        controlFormId: controlFormCode.controlFormId,
        controlFormTH: controlFormCode.controlFormTH,
        controlFormYear: controlFormCode.controlFormYear,
        dateStart: controlFormCode.dateStart,
        dateEnd: controlFormCode.dateEnd,
        operationStatusCode: controlFormCode.operationStatusCode,
      },
    })

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการส่ง ?'),
      onOk: () => {
        sendMailFn({ fetchPolicy: 'no-cache', variables: { input: objData } })
      },
      onCancel: () => {},
    })
  }

  return (
    <>
      <ModalNew
        onSubmit={() => {}}
        onClose={() => Promise.all([setVisible(false), close(false)])}
        visible={visible}
        testTitle={t('ข้อมูลการแจ้งเตือนอีเมล')}
        type="medium"
        footer={null}
      >
        {(sendMail?.loading || getPersonnelByOrganization?.loading) && <SpinnersNew />}
        <Col sm={24} md={16} lg={18} style={{ margin: 'auto' }}>
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            labelAlign="left"
            labelCol={{ ...labelCol }}
            wrapperCol={{ ...wrapperCol }}
            layout="horizontal"
            name={formname}
          >
            <Form.Item name="personnelCode" hidden>
              <SelectNew mode="tags" placeholder="" scrollableId={formname} />
            </Form.Item>
            <Form.Item name="dear" label={t('เรียน')} rules={[{ required: true, message: t('กรุณาระบุเรียนถึง') }]}>
              <InputNew placeholder="" maxLength={200} />
            </Form.Item>
            <Form.Item name="subject" label={t('เรื่อง')} rules={[{ required: true, message: t('กรุณาระบุเรื่อง') }]}>
              <InputNew placeholder="" maxLength={200} />
            </Form.Item>
            <Form.Item name="sendTo" label={t('ถึง')} rules={[{ required: true, message: t('กรุณาระบุถึง') }]}>
              <SelectTagEmailPersonnels
                placeholder={t('ระบุถึง')}
                formname={formname}
                handleChange={(e) => form.setFieldsValue({ sendTo: e })}
              />
            </Form.Item>
            <Form.Item name="sendToCopy" label={t('สำเนา')}>
              <SelectTagEmailPersonnels
                placeholder={t('ระบุสำเนาถึง')}
                formname={formname}
                handleChange={(e) => form.setFieldsValue({ sendToCopy: e })}
              />
            </Form.Item>
            <Form.Item name="sendToSecret" label={t('สำเนาลับ')}>
              <SelectTagEmailPersonnels
                placeholder={t('ระบุสำเนาลับถึง')}
                formname={formname}
                handleChange={(e) => form.setFieldsValue({ sendToSecret: e })}
              />
            </Form.Item>

            <Col align="center">
              <Space size={[8, 8]} wrap>
                <ButtonNew type="primary" roles={{ type: 'send', menu: menu }} htmlType="submit">
                  {t('ส่งอีเมล')}
                </ButtonNew>
                <ButtonNew type="back" onClick={() => Promise.all([setVisible(false), close(false)])}>
                  {t('ยกเลิก')}
                </ButtonNew>
              </Space>
            </Col>
          </Form>
        </Col>
      </ModalNew>
    </>
  )
}

EmailModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  controlFormCode: PropTypes.object,
  menu: PropTypes.string.isRequired,
}

export default EmailModal
