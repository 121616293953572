import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { Alert } from '../../../../../components/alert/Alert'
import { useNavigate, Navigate } from 'react-router-dom'
import { ButtonNew } from '../../../../../components/button/Button'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'
import { InputNew } from '../../../../../components/input/Input'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { LabelNew as Label } from '../../../../../components/label/Label'
import SelectOrganization from '../../../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectJobTypes from '../../../../../components/inputfromapi/selectjobtypes/SelectJobTypes'
import SelectApproveStatus from '../../../../../components/inputfromapi/selectapprovestatus/SelectApproveStatus'
import SelectPersonnels from '../../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import { GET_ADHOCJOB_BY_CODE } from '../../graphql/Query'
import { SAVE_ADHOCJOB, GENERATE_JOB_NUMBER } from '../../graphql/Mutation'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText, displayError } from '../../../../../utilitys/helper'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'

const AdhocjobInfomation = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { state } = useLocation()
  const [user] = useDecodeUser()

  if (!state) {
    return <Navigate to={'/auditoperations/adhocjob'} />
  }

  const { type_action, refCode } = state
  const disabled = type_action === 'view' ? true : false
  const [btnGenDisabled, setBtnGenDisabled] = useState(false)
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [responsibleOrg, setResponsibleOrg] = useState()

  const Responsive = {
    md: 18,
    lg: 18,
  }
  const Responsive2 = {
    md: 24,
    lg: 24,
  }

  /* ---------------- API ----------- */
  const [getAdhocJobByCodeFn, dataAdhocJobByCode] = useLazyQuery(GET_ADHOCJOB_BY_CODE)
  const [saveAdhocJobFn, { loading: loadingSaveAdhocJob, error: errorSaveAdhocJob, data: saveAdhocJob }] =
    useMutation(SAVE_ADHOCJOB)
  const [genJobNumberFn, { loading: loadingGenJobNumber, error: errorGenJobNumber, data: genJobNumber }] =
    useMutation(GENERATE_JOB_NUMBER)

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    showConfirm(input)
  }

  const showConfirm = (input) => {
    let dataInput = {
      responsibleOrganization: input.responsibleOrganization || '',
      requestOrganization: input.requestOrganization || '',
      requestPersonnel: input.requestPersonnel || '',
      telephone: input.telephone || '',
      jobCode: input.jobCode || '',
      dateStart: input.dateStart || null,
      dateEnd: input.dateEnd || null,
      approvePersonnel: input.approvePersonnel || '',
      approveStatusCode: input.approveStatusCode || '',
      jobTypeCode: input.jobTypeCode || '',
      jobNumber: input.jobNumber || '',
      organizationCode: user?.orgCode,
    }

    if (type_action === 'add') {
      dataInput['jobSubjectTH'] = input.jobSubject || ''
      dataInput['jobSubjectEN'] = input.jobSubject || ''
      dataInput['jobDetailTH'] = input.jobDetail || ''
      dataInput['jobDetailEN'] = input.jobDetail || ''
      dataInput['action'] = 'create'
    } else if (type_action === 'edit') {
      dataInput['jobSubjectTH'] = (localStorage.getItem('lang') === 'th' ? input.jobSubject : input.jobSubjectTH) || ''
      dataInput['jobSubjectEN'] = (localStorage.getItem('lang') === 'en' ? input.jobSubject : input.jobSubjectEN) || ''
      dataInput['jobDetailTH'] = (localStorage.getItem('lang') === 'th' ? input.jobDetail : input.jobDetailTH) || ''
      dataInput['jobDetailEN'] = (localStorage.getItem('lang') === 'en' ? input.jobDetail : input.jobDetailEN) || ''
      dataInput['action'] = 'update'
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        saveAdhocJobFn({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const showErrorAlert = (item) => {
    Alert({
      type: 'error',
      title: t(item.title),
      content: t(displayError(item.content)),
      onOk() {},
    })
  }

  const generateCode = async () => {
    await form
      .validateFields(['jobTypeCode'])
      .then(({ jobTypeCode }) => {
        genJobNumberFn({
          variables: {
            input: encryptInput({
              jobTypeCode: jobTypeCode,
              organizationCode: user?.orgCode,
            }),
          },
        })
      })
      .catch(() => {})
  }

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (refCode) {
      getAdhocJobByCodeFn({
        variables: {
          jobCode: encryptInput(refCode),
        },
      })
    }
  }, [refCode])

  useEffect(() => {
    if (dataAdhocJobByCode.data) {
      const data = dataAdhocJobByCode.data.getAdhocJobByCode
      setResponsibleOrg(data.responsibleOrganization)
      form.setFieldsValue({
        jobCode: data.jobCode,
        jobNumber: data.jobNumber,
        jobTypeCode: data.jobTypeCode,
        jobSubject: displayText(data.jobSubjectTH, data.jobSubjectEN) || '',
        jobSubjectTH: data.jobSubjectTH || '',
        jobSubjectEN: data.jobSubjectEN || '',
        jobDetail: displayText(data.jobDetailTH, data.jobDetailEN) || '',
        jobDetailTH: data.jobDetailTH || '',
        jobDetailEN: data.jobDetailEN || '',
        responsibleOrganization: data.responsibleOrganization,
        requestOrganization: data.requestOrganization,
        requestPersonnel: data.requestPersonnel,
        telephone: data.telephone,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        approvePersonnel: data.approvePersonnel,
        approveStatusCode: data.approveStatusCode,
      })

      setBtnGenDisabled(data.jobNumber ? true : false)
    }

    if (dataAdhocJobByCode.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataAdhocJobByCode.error)),
        onOk() {},
      })
    }
  }, [dataAdhocJobByCode.data])

  useEffect(() => {
    form.setFieldsValue({
      approveStatusCode: '10',
    })
  }, [])

  useEffect(() => {
    if (saveAdhocJob) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/auditoperations/adhocjob/tab', {
            state: { type_action: 'edit', refCode: saveAdhocJob.adhocJobSave.jobCode },
          })
        },
      })
    }
  }, [saveAdhocJob])

  useEffect(() => {
    if (errorSaveAdhocJob) {
      showErrorAlert({
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        content: errorSaveAdhocJob,
      })
    }
  }, [errorSaveAdhocJob])

  useEffect(() => {
    if (genJobNumber) {
      form.setFieldsValue({
        jobNumber: genJobNumber.adhocJobNumberGenerate.jobNumber,
      })
    }
  }, [genJobNumber])

  useEffect(() => {
    if (errorGenJobNumber) {
      showErrorAlert({
        title: 'ไม่สามารถดึงเลขที่งานได้',
        content: errorGenJobNumber,
      })
    }
  }, [errorGenJobNumber])

  return (
    <>
      {(loadingSaveAdhocJob || loadingGenJobNumber) && <SpinnersNew />}
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ md: { span: 8 }, lg: { span: 5 } }}
        wrapperCol={{ md: { span: 16 }, lg: { span: 12 } }}
        name="formtab1"
      >
        <Row justify="center">
          <Col {...Responsive}>
            <Row style={{ margin: '10px 0px' }}>
              <Col {...Responsive2}>
                <Label type="sub-header-primary">{t('รายละเอียด')}</Label>
              </Col>
            </Row>
            <Row justify="center">
              <Col {...Responsive}>
                <Form.Item
                  name="jobCode"
                  label={<Label type="tab-header-inactive">{t('รหัสงาน')}</Label>}
                  rules={[
                    {
                      required: true,
                      message: t('กรุณาระบุ'),
                    },
                  ]}
                >
                  <InputNew disabled={type_action !== 'add' && true} maxLength={10} />
                </Form.Item>
                <Form.Item
                  name="jobTypeCode"
                  label={<Label type="tab-header-inactive">{t('ประเภทงาน')}</Label>}
                  rules={[
                    {
                      required: true,
                      message: t('กรุณาเลือกประเภทงาน'),
                    },
                  ]}
                >
                  <SelectJobTypes
                    placeholder={t('เลือกประเภทงาน')}
                    formname={'formtab1'}
                    filters={{ jobflag: 1, isActive: 1 }}
                    disabled={disabled}
                    handleChange={(e) => form.setFieldsValue({ jobTypeCode: e?.value })}
                  />
                </Form.Item>
                <Form.Item label={<Label type="tab-header-inactive">{t('เลขที่งาน')}</Label>}>
                  <Input.Group>
                    <Form.Item name="jobNumber" style={{ width: '100%' }}>
                      <InputNew placeholder={t('เลขที่งานมอบหมาย')} disabled={true} />
                    </Form.Item>
                    {btnGenDisabled ? (
                      <></>
                    ) : (
                      <ButtonNew
                        style={{ minHeight: '37px', marginLeft: '5px' }}
                        type="primary"
                        roles={{ type: 'add', menu: menu }}
                        htmlType="button"
                        onClick={() => generateCode()}
                      >
                        {t('เลขที่งาน')}
                      </ButtonNew>
                    )}
                  </Input.Group>
                </Form.Item>

                <Form.Item
                  name="jobSubject"
                  label={<Label type="tab-header-inactive">{t('งาน(ชื่องาน)')}</Label>}
                  rules={[
                    {
                      required: true,
                      message: t('กรุณาระบุ'),
                    },
                  ]}
                >
                  <InputNew placeholder={t('ระบุชื่องาน')} disabled={disabled} maxLength={8000} />
                </Form.Item>
                <Form.Item name="jobSubjectTH" hidden={true}>
                  <InputNew />
                </Form.Item>
                <Form.Item name="jobSubjectEN" hidden={true}>
                  <InputNew />
                </Form.Item>
                <Form.Item
                  name="responsibleOrganization"
                  label={<Label type="tab-header-inactive">{t('หน่วยงานรับผิดชอบ')}</Label>}
                  rules={[
                    {
                      required: true,
                      message: t('กรุณาเลือกหน่วยงานรับผิดชอบ'),
                    },
                  ]}
                >
                  <SelectOrganization
                    placeholder={t('เลือกหน่วยงาน')}
                    formname={'formtab1'}
                    filters={{ /* organizationChecked: 1, */ isActive: 1 }}
                    handleChange={(e) => {
                      form.setFieldsValue({ responsibleOrganization: e?.value }),
                        setResponsibleOrg(e?.value),
                        form.resetFields(['approvePersonnel'])
                    }}
                    onClear={() => {
                      setResponsibleOrg()
                    }}
                    disabled={disabled}
                  />
                </Form.Item>

                <Form.Item
                  name="requestOrganization"
                  label={<Label type="tab-header-inactive">{t('หน่วยงานที่ร้องขอ')}</Label>}
                >
                  <SelectOrganization
                    placeholder={t('เลือกหน่วยงาน')}
                    formname={'formtab1'}
                    filters={{ /* organizationChecked: 1, */ isActive: 1 }}
                    handleChange={(e) => form.setFieldsValue({ requestOrganization: e?.value })}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item name="requestPersonnel" label={<Label type="tab-header-inactive">{t('ผู้ร้องขอ')}</Label>}>
                  <InputNew disabled={disabled} maxLength={100} />
                </Form.Item>
                <Form.Item name="telephone" label={<Label type="tab-header-inactive">{t('เบอร์ติดต่อ')}</Label>}>
                  <InputNew disabled={disabled} maxLength={20} />
                </Form.Item>
                <Form.Item name="jobDetail" label={<Label type="tab-header-inactive">{t('รายละเอียดงาน')}</Label>}>
                  <TextAreaNew
                    placeholder={t('รายละเอียดงาน')}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item name="jobDetailTH" hidden={true}>
                  <InputNew />
                </Form.Item>
                <Form.Item name="jobDetailEN" hidden={true}>
                  <InputNew />
                </Form.Item>
                <Form.Item
                  name="approvePersonnel"
                  label={<Label type="tab-header-inactive">{t('ผู้อนุมัติ')}</Label>}
                  rules={[
                    {
                      required: true,
                      message: t('กรุณาเลือกผู้อนุมัติ'),
                    },
                  ]}
                >
                  <SelectPersonnels
                    placeholder={t('ผู้อนุมัติ')}
                    formname={'formtab1'}
                    filters={{ levelCode: 8, organizationCode: responsibleOrg || '' }}
                    disabled={disabled ? disabled : responsibleOrg ? false : true}
                    handleChange={(e) => form.setFieldsValue({ approvePersonnel: e?.value })}
                  />
                </Form.Item>
                <Form.Item name="dateStart" label={<Label type="tab-header-inactive">{t('วันที่เริ่มต้น')}</Label>}>
                  <Datepicker
                    setValueDateFn={async (data) => {
                      await setIsMinDate(data), await form.setFieldsValue({ dateStart: await data })
                    }}
                    onClear={() => {
                      form.setFieldsValue({ dateStart: null })
                    }}
                    placeholder={t('DD/MM/YYYY')}
                    isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item name="dateEnd" label={<Label type="tab-header-inactive">{t('วันที่สิ้นสุด')}</Label>}>
                  <Datepicker
                    setValueDateFn={async (data) => {
                      await setIsMaxDate(data), await form.setFieldsValue({ dateEnd: await data })
                    }}
                    onClear={() => {
                      form.setFieldsValue({ dateEnd: null })
                    }}
                    placeholder={t('DD/MM/YYYY')}
                    isMinDate={isMinDate ? new Date(isMinDate) : null}
                    disabled={disabled}
                  />
                </Form.Item>
                <Form.Item
                  name="approveStatusCode"
                  label={<Label type="tab-header-inactive">{t('สถานะอนุมัติ')}</Label>}
                >
                  <SelectApproveStatus
                    formname={'formtab1'}
                    filters={{ isActive: 1 }}
                    handleChange={(e) => form.setFieldsValue({ approveStatusCode: e?.value })}
                    disabled={true}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row gutter={[12, 20]} justify="center">
          {type_action !== 'view' && (
            <Col>
              <ButtonNew type="primary" roles={{ type: type_action, menu: menu }} htmlType="submit">
                {t('บันทึก')}
              </ButtonNew>
            </Col>
          )}
          <Col>
            <ButtonNew type="back" onClick={() => navigate('/auditoperations/adhocjob')}>
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default AdhocjobInfomation
