import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { InputNumberNew } from '../../../../components/input/Input'
import { ColorPicker } from '../../../../components/colorpicker/ColorPicker'
import SelectRiskLevel from '../../../../components/inputfromapi/selectrisklevel/SelectRiskLevel'

export const ScoreColumns = ({
  editable = false,
  fncDeleteScore = () => {},
  menu,
  form,
  setScoreDataLists = () => {},
  scoreDataLists = [],
}) => {
  const { t } = useTranslation()
  return [
    {
      title: t('ช่วงคะแนนเริ่มต้น'),
      dataIndex: 'scoreBegin',
      width: '20%',
      render: (item, record, index) => (
        <Form.Item
          name={['riskFormulaScores', index, 'scoreBegin']}
          initialValue={item}
          wrapperCol={{ md: 24 }}
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.reject(new Error(t('กรุณากรอกค่าช่วงคะแนนเริ่มต้น')))
                }

                if (value > 0) {
                  return Promise.resolve()
                } else {
                  return Promise.reject(new Error(t('กรุณากรอกค่าช่วงคะแนนเริ่มต้นให้มากกว่า 0')))
                }
              },
            }),
          ]}
        >
          <InputNumberNew
            maxlenght="4"
            placeholder={t('กรอกค่าช่วงคะแนนเริ่มต้น')}
            disabled={!editable}
            onChange={(e) => {
              let newData = scoreDataLists
              newData[index].scoreBegin = e
              form.setFieldsValue({ riskFormulaScores: newData })
              setScoreDataLists([...newData])
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: t('ช่วงคะแนนสิ้นสุด'),
      dataIndex: 'scoreEnd',
      width: '20%',
      render: (item, record, index) => (
        <Form.Item
          name={['riskFormulaScores', index, 'scoreEnd']}
          initialValue={item}
          wrapperCol={{ md: 24 }}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                let scoreBegin = getFieldValue(['riskFormulaScores', index, 'scoreBegin'])
                if (!value) {
                  return Promise.reject(new Error(t('กรุณากรอกค่าช่วงคะแนนสิ้นสุด')))
                } else if (parseInt(scoreBegin) > parseInt(value)) {
                  return Promise.reject(new Error(t('คะแนนสิ้นสุดต้องมีค่ามากกว่าช่วงคะแนนเริ่มต้น')))
                } else {
                  return Promise.resolve()
                }
              },
            }),
          ]}
        >
          <InputNumberNew
            maxlenght="4"
            placeholder={t('กรอกค่าช่วงคะแนนสิ้นสุด')}
            disabled={!editable}
            onChange={(e) => {
              let newData = scoreDataLists
              newData[index].scoreEnd = e
              form.setFieldsValue({ riskFormulaScores: newData })
              setScoreDataLists([...newData])
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: t('สีแสดงความเสี่ยง'),
      dataIndex: 'scoreColor',
      width: '20%',
      render: (item, record, index) => (
        <Form.Item
          name={['riskFormulaScores', index, 'scoreColor']}
          initialValue={item}
          wrapperCol={{ md: 24 }}
          rules={[{ required: true, message: t('กรุณาเลือกสีแสดงความเสี่ยง') }]}
        >
          <ColorPicker
            disabled={!editable}
            handleChange={(e) => {
              let newData = scoreDataLists
              newData[index].scoreColor = e
              form.setFieldsValue({ riskFormulaScores: newData })
              setScoreDataLists([...newData])
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: t('ระดับความเสี่ยง'),
      dataIndex: 'riskLevelCode',
      width: '20%',
      render: (item, record, index) => (
        <Form.Item
          name={['riskFormulaScores', index, 'riskLevelCode']}
          initialValue={item}
          wrapperCol={{ md: 24 }}
          rules={[{ required: true, message: t('กรุณาเลือกระดับความเสี่ยง') }]}
        >
          <SelectRiskLevel
            formname="CalculateAndScoreForm"
            placeholder={t('เลือกระดับความเสี่ยง')}
            disabled={!editable}
            filters={{ isActive: 1 }}
            handleChange={(e) => {
              let newData = scoreDataLists
              newData[index].riskLevelCode = e?.value
              form.setFieldsValue({ riskFormulaScores: newData })
              setScoreDataLists([...newData])
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '8%',
      render: (item, record, index) => {
        const icons = []

        if (editable) {
          icons.push({
            type: 'delete',
            onClick: () => {
              fncDeleteScore(index)
            },
          })
        }

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} icons={icons} />
          </div>
        )
      },
    },
  ]
}
