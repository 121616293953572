import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import { CardNew } from '../../../components/card/Card'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { Form, Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { InputNew } from '../../../components/input/Input'
import { Radio, RadioGroup } from '../../../components/radio/Radio'
import { ButtonNew } from '../../../components/button/Button'
import { Datatable } from '../../../components/datatable/Datatable'
import { Columns } from './utils/AuditPersonnelColumns'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { SpinnersNew as Spinners } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { LabelNew as Label } from '../../../components/label/Label'
import { regexEng, regexEngThai } from '../../../utilitys/helper'
import { GET_AUDIT_TEAM_BY_CODE } from './graphql/Query'
import { UPDATE_AUDIT_TEAM } from './graphql/Mutation'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText, displayError } from '../../../utilitys/helper'

const AuditTeamForm = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { state } = useLocation()

  if (!state) return <Navigate to={'/administrator/manageauditteam'} />

  const { type_action, auditTeamCode, personnelList, selectPersonnelList } = state
  const Responsive = {
    md: 24,
    lg: 24,
  }

  const data = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/manageauditteam' },
    { label: t('จัดการข้อมูลทีมตรวจสอบ'), path: '/administrator/manageauditteam' },
    { label: t('รายละเอียดทีมตรวจสอบ') },
  ]

  const [dataLists, setDataLists] = useState([])
  const [disabled] = useState(type_action === 'view' ? true : false)
  const [changeValue, setChangeValue] = useState(false)

  /* ---------------- API ----------- */
  const [getAuditTeamByCode, dataAuditTeamByCode] = useLazyQuery(GET_AUDIT_TEAM_BY_CODE)
  const [updateAuditTeamFn, dataUpdateAuditTeam] = useMutation(UPDATE_AUDIT_TEAM)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (auditTeamCode) {
      getAuditTeamByCode({
        variables: { auditTeamCode: encryptInput(auditTeamCode) },
      })
    }
  }, [auditTeamCode])

  useEffect(() => {
    if (dataAuditTeamByCode.data) {
      const data = dataAuditTeamByCode.data.getAuditTeamByCode
      form.setFieldsValue({
        auditTeamCode: data.auditTeamCode,
        auditTeamNameTH: data.auditTeamNameTH,
        auditTeamNameEN: data.auditTeamNameEN,
        isActive: data.isActive,
      })

      /*
        personnelList คือ รายชื่อบุคลากรที่ได้จาก API ครั้งแรก
        selectPersonnelList คือ รายชื่อบุคลากร ที่มาจากการเลือก
      */
      if (personnelList) {
        let dataMerge = [...personnelList, ...selectPersonnelList]
        dataMerge = _.uniqBy(dataMerge, (i) => i.personnelCode)
        setDataTable(dataMerge)
        setChangeValue(true)
      } else {
        setDataTable(data.auditTeamPersonnels)
      }
    }

    if (dataAuditTeamByCode.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataAuditTeamByCode.error)),
        onOk() {},
      })
    }
  }, [dataAuditTeamByCode.data])

  const setDataTable = async (data) => {
    setDataLists(
      _.map(data, (item, index) => {
        return {
          key: index,
          personnelCode: item.personnelCode,
          personnelName: item.personnelName || displayText(item.personnelNameTH, item.personnelNameEN),
          organization: item.organization || displayText(item.organizationNameTH, item.organizationNameEN),
          auditTeamRole: item.auditTeamRole || 2,
          inUsed: item.inUsed || 0,
        }
      }),
    )
  }

  useEffect(() => {
    if (dataUpdateAuditTeam.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/administrator/manageauditteam')
        },
      })
    }

    if (dataUpdateAuditTeam.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(dataUpdateAuditTeam.error)),
        onOk() {},
      })
    }
  }, [dataUpdateAuditTeam.data])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    /* เงื่อนไขเช็ค ให้มี หัวหน้าทีมได้ 1 คน */
    const checkDuplicateRole = _.filter(input.radioRole, (value) => value === 1).length

    if (checkDuplicateRole === 0) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t('กรุณาเลือกหัวหน้าทีม'),
        onOk() {},
      })
    } else if (checkDuplicateRole > 1) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t('หัวหน้าทีม มีได้เพียง 1 คนเท่านั้น'),
        onOk() {},
      })
    } else {
      const auditTeamDetail = dataLists.map((item, index) => {
        return {
          personnelCode: item.personnelCode,
          auditTeamRole: input.radioRole[index],
        }
      })

      Alert({
        type: 'confirm',
        title: t('ต้องการยืนยันการบันทึก?'),
        onOk() {
          updateAuditTeamFn({
            variables: {
              input: encryptInput({
                auditTeamNameTH: input.auditTeamNameTH,
                auditTeamNameEN: input.auditTeamNameEN,
                isActive: input.isActive,
                auditTeamDetail: auditTeamDetail,
              }),
              auditTeamCode: encryptInput(auditTeamCode),
            },
          })
        },
        onCancel() {},
      })
    }
  }

  const onChangeRole = (radio) => {
    if (radio.radioRole) {
      const array = [...dataLists]
      radio.radioRole.map((value, index) => {
        array[index] = { ...array[index], auditTeamRole: value }
      })
      setDataLists(array)
    }
  }

  /* ---------------- ACTION ----------- */
  const onCancel = () => {
    if (changeValue) {
      Alert({
        type: 'confirm',
        title: t('ต้องการยกเลิกใช่หรือไม่ ?'),
        onOk() {
          navigate('/administrator/manageauditteam')
        },
        onCancel() {},
      })
    } else {
      navigate('/administrator/manageauditteam')
    }
  }

  return (
    <>
      {(dataAuditTeamByCode.loading || dataUpdateAuditTeam.loading) && <Spinners />}
      <Breadcrumb data={data} title={t('รายละเอียดทีมตรวจสอบ')} />
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        labelCol={{
          md: { span: 6, offset: 2 },
          lg: { span: 6, offset: 2 },
        }}
        wrapperCol={{
          md: { span: 12, offset: 2 },
          lg: { span: 10, offset: 1 },
          align: 'left',
        }}
        layout="horizontal"
        name="auditForm"
        onValuesChange={(e) => {
          onChangeRole(e), setChangeValue(true)
        }}
      >
        <CardNew topic={t('รายละเอียดทีมตรวจสอบ')} icon="AlertCircle" toggledrop="false">
          <Row gutter={[12, 12]}>
            <Col {...Responsive}>
              <Form.Item name="auditTeamCode" label={<Label type="tab-header-inactive">{t('รหัสทีมตรวจสอบ')}</Label>}>
                <InputNew type="body-primary" disabled={true} maxLength={10} />
              </Form.Item>
              <Form.Item
                name="auditTeamNameTH"
                label={<Label type="tab-header-inactive">{t('ชื่อทีมตรวจสอบภาษาไทย')}</Label>}
                placeholder={t('ระบุชื่อทีมตรวจสอบภาษาไทย')}
                rules={[
                  { required: true, message: t('กรุณาระบุชื่อทีมตรวจสอบ') },
                  {
                    pattern: regexEngThai,
                    message: t(`กรุณากรอกด้วยภาษาไทยหรือภาษาอังกฤษหรือตัวเลข ก-๙, A-Z, a-z 0-9`),
                  },
                ]}
              >
                <InputNew maxLength={100} disabled={disabled} />
              </Form.Item>
              <Form.Item
                name="auditTeamNameEN"
                label={<Label type="tab-header-inactive">{t('ชื่อทีมตรวจสอบภาษาอังกฤษ')}</Label>}
                placeholder={t('ระบุชื่อทีมตรวจสอบภาษาอังกฤษ')}
                rules={[
                  {
                    pattern: regexEng,
                    message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
                  },
                ]}
              >
                <InputNew maxLength={100} disabled={disabled} />
              </Form.Item>
              <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive">
                <RadioGroup>
                  <Space size={[8, 8]} wrap>
                    <Radio value={1} disabled={disabled}>
                      <Label type="tab-header-inactive">{t('ใช้งาน')}</Label>
                    </Radio>
                    &nbsp;
                    <Radio value={0} disabled={disabled}>
                      <Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>
                    </Radio>
                  </Space>
                </RadioGroup>
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ marginTop: 45, marginBottom: 10 }}>
            <Col>
              <Label type="card-title" icon="List">
                {t('จัดการข้อมูลทีมตรวจสอบ')}
              </Label>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col {...Responsive}>
              <Datatable
                columns={Columns(dataLists, setDataLists, form, type_action, disabled, setChangeValue)}
                data={dataLists}
                // handleTableChange={(data) => }
                scroll={{ x: 1000, y: false }}
                btnAdd={
                  type_action !== 'view' ? (
                    <>
                      <ButtonNew
                        type="plusTableBorderPrimary"
                        onClick={() =>
                          navigate('/administrator/manageauditteam/personnel', {
                            state: { auditTeamCode: auditTeamCode, personnelList: dataLists },
                          })
                        }
                      >
                        {t('เพิ่มผู้ตรวจสอบ')}
                      </ButtonNew>
                    </>
                  ) : (
                    <></>
                  )
                }
              />
            </Col>
            <Col {...Responsive} align="center">
              <Space size={[8, 8]} wrap>
                {type_action !== 'view' && (
                  <ButtonNew type="primary" roles={{ type: 'edit', menu: menu }} htmlType="submit">
                    {t('บันทึก')}
                  </ButtonNew>
                )}
                &nbsp;
                <ButtonNew type="back" onClick={() => onCancel()}>
                  {t('ยกเลิก')}
                </ButtonNew>
              </Space>
            </Col>
          </Row>
        </CardNew>
      </Form>
    </>
  )
}
export default AuditTeamForm
