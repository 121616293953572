import { Trans } from 'react-i18next'
import { displayText } from '../../../../utilitys/helper'
import { Tooltip } from 'antd'
import { downloadFile } from '../../../../utilitys/files'
import { IconNew } from '../../../../components/icon/Icon'
import { LabelNew } from '../../../../components/label/Label'

export const Columns = () => {
  return [
    {
      title: <Trans>{'คำถาม'}</Trans>,
      dataIndex: 'formQuestName',
      width: '40%',
      className: 'text-nowrap',
      render: (item, record) => {
        return (
          <p>{`${record.formGroupSort}.${record.formSort} ${displayText(
            record.formQuestNameTH,
            record.formQuestNameEN,
          )}`}</p>
        )
      },
    },
    {
      title: <Trans>{'คำตอบ'}</Trans>,
      dataIndex: 'choiceDetailId',
      width: '20%',
      className: 'text-nowrap',
      render: (item, record) => {
        return (
          <p>
            {displayText(
              record.choiceDetailNameTH ? record.choiceDetailNameTH : '-',
              record.choiceDetailNameEN ? record.choiceDetailNameEN : '-',
            )}
          </p>
        )
      },
    },
    {
      title: <Trans>{'คำอธิบายเพิ่มเติม'}</Trans>,
      dataIndex: 'answerDetail',
      width: '20%',
      className: 'text-nowrap',
      render: (item, record) => {
        return <p>{record.answerDetail ? record.answerDetail : '-'}</p>
      },
    },
    {
      title: <Trans>{'แนบเอกสาร'}</Trans>,
      dataIndex: 'files',
      width: '20%',
      className: 'text-nowrap',
      render: (item, record) => {
        return record?.files.length ? (
          <>
            <Tooltip key={'files'} placement="top" title={record.fileNameLabel + record.fileType}>
              <LabelNew
                type="tab-header-inactive"
                key={'files'}
                onClick={() =>
                  downloadFile(record.fileNameLabel + record.fileType, record.fileNameGen + record.fileType)
                }
              >
                <IconNew icon={'FileText'} className="approve-downloads" />
              </LabelNew>
            </Tooltip>
          </>
        ) : (
          <></>
        )
      },
    },
  ]
}
