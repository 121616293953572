import { Trans } from 'react-i18next'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'

export const Columns = ({ fncOnpressDelete, menu, editable }) => {
  return [
    {
      title: <Trans>รหัสหน่วยงาน</Trans>,
      dataIndex: 'organizationCode',
    },
    {
      title: <Trans>หน่วยงาน</Trans>,
      dataIndex: 'organizationName',
    },
    {
      title: <Trans>ระดับหน่วยงาน</Trans>,
      dataIndex: 'organizationLevelName',
    },
    {
      title: <Trans>ผู้รับผิดชอบ</Trans>,
      dataIndex: 'personnelName',
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '5%',
      render: (item, record, index) => {
        if (editable) {
          return (
            <div style={{ textAlign: 'center' }}>
              <ButtonGroup
                menu={menu}
                icons={[
                  {
                    type: 'delete',
                    onClick: () => {
                      fncOnpressDelete(index)
                    },
                  },
                ]}
              />
            </div>
          )
        }
      },
    },
  ]
}
