import React from 'react'
import { ButtonNew } from '../button/Button'
import { ButtonGroupStyle } from './ButtonGroupStyle'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

export const ButtonGroup = (props) => {
  const { menu, icons, ...otherProps } = props

  let isDisabled = false
  let iconType = ''
  let rolesType = ''
  const iconsList = icons.map((e, key) => {
    isDisabled = false
    switch (e.type) {
      case 'view':
        iconType = 'viewUseInTable'
        rolesType = e.type
        break
      case 'print':
        iconType = 'printUseInTable'
        rolesType = 'export'
        break
      case 'edit':
        iconType = 'editUseInTable'
        rolesType = e.type
        isDisabled = e.disabled
        break
      case 'delete':
        iconType = 'deleteUseInTable'
        rolesType = e.type
        isDisabled = e.disabled
        break
      case 'approve':
        iconType = 'approveUseInTable'
        rolesType = e.type
        break
      case 'download':
        iconType = 'downloadUseInTable'
        rolesType = 'export'
        isDisabled = e.disabled
        break
      case 'calendar':
        iconType = 'calendarUseInTable'
        rolesType = 'view'
        break
      case 'filetext':
        iconType = 'filetextUseInTable'
        rolesType = ''
        break
      case 'copy':
        iconType = 'copyUseInTable'
        rolesType = 'duplicate'
        break
      case 'send':
        iconType = 'sendUseInTable'
        rolesType = 'send'
        isDisabled = e.disabled
        break
      case 'clear':
        iconType = 'clearUseInTable'
        rolesType = 'clear'
        break

      case 'settings':
        iconType = 'settingUseInTable'
        rolesType = 'send'
        break

      case 'cancel':
        iconType = 'cancelUseInTable'
        rolesType = 'edit'
        break

      default:
        iconType = 'viewUseInTable'
        break
    }

    return e.onClick ? (
      <ButtonNew
        type={iconType}
        roles={{ type: rolesType, menu: menu }}
        onClick={e.onClick}
        key={key}
        size={props.size}
        disabled={isDisabled}
        {...otherProps}
      />
    ) : (
      <Link key={key} to={e.path ? e.path : ''}>
        <ButtonNew type={iconType} roles={{ type: rolesType, menu: menu }} size={props.size} />
      </Link>
    )
  })
  return (
    <>
      <ButtonGroupStyle>{iconsList}</ButtonGroupStyle>
    </>
  )
}

ButtonGroup.propTypes = {
  menu: PropTypes.string,
  icons: PropTypes.array.isRequired,
}
