import { Trans } from 'react-i18next'
export const Columns = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'key',
    // sorter: true,
    width: '5%',
    align: 'center',
    className: 'text-nowrap',
    render: (item, record, index) => {
      return index + 1
    },
  },
  {
    title: <Trans>รหัสบุคลากร</Trans>,
    dataIndex: 'personnelCode',
    // sorter: true,
    width: '15%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ชื่อ-นามสกุล</Trans>,
    dataIndex: 'personnelName',
    // sorter: true,
    width: '30%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>หน่วยงาน</Trans>,
    dataIndex: 'organization',
    // sorter: true,
    width: '30%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ตำแหน่ง</Trans>,
    dataIndex: 'position',
    // sorter: true,
    width: '30%',
    className: 'text-nowrap',
  },
]
