import { Trans } from 'react-i18next'
// import { Alert } from '../../../../components/alert/Alert'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { Status } from '../../../../components/status/Status'

export const Columns = ({ fncOnpressExport, fncOnpressDelete, fncOnpressView, fncOnpressEdit, menu }) => {
  return [
    {
      title: <Trans>โครงการ</Trans>,
      dataIndex: 'projectName',
      sorter: true,
      width: '400px',
      fixed: 'left',
      // render: (item, record) => {
      //   return <div style={{ textAlign: 'start' }}>{record.no}</div>
      // },
    },
    {
      title: <Trans>แหล่งที่มาของโครงการ</Trans>,
      dataIndex: 'projectRefName',
      sorter: true,
      width: '200px',
    },
    {
      title: <Trans>วันที่เริ้มต้น</Trans>,
      dataIndex: 'dateStart',
      sorter: true,
      width: '150px',
    },
    {
      title: <Trans>วันที่สิ้นสุด</Trans>,
      dataIndex: 'dateEnd',
      sorter: true,
      width: '150px',
    },
    {
      title: <Trans>ทีมรับผิดชอบ</Trans>,
      dataIndex: 'organizationName',
      sorter: true,
      width: '250px',
    },
    {
      title: <Trans>ผู้รับผิดชอบหลัก</Trans>,
      dataIndex: 'personnelName',
      sorter: true,
      width: '250px',
    },
    {
      title: <Trans>สถานะ</Trans>,
      dataIndex: 'approveStatusCode',
      sorter: true,
      width: '200px',
      render: (item) => {
        return <Status text={item} />
        // return <Status text={item === '10' ? 'รอพิจารณา' : item === '20' ? 'กำลังพิจารณา' : 'พิจารณาแล้ว'} />
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '120px',
      render: (item, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup
              menu={menu}
              icons={[
                {
                  type: 'download',
                  onClick: () => {
                    fncOnpressExport(record)
                  },
                },
                {
                  type: 'view',
                  onClick: () => {
                    fncOnpressView(record)
                  },
                },
                {
                  type: 'edit',
                  onClick: () => {
                    fncOnpressEdit(record)
                  },
                },
                {
                  type: 'delete',
                  onClick: () => {
                    fncOnpressDelete(record)
                  },
                },
              ]}
            />
          </div>
        )
      },
    },
  ]
}
