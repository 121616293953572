import { gql } from '@apollo/client'

export const SAVE_RATE_RISK_CONTROL = gql`
  mutation SaveRateRiskControl($input: RateRiskControlSaveInput!) {
    saveRateRiskControl(input: $input) {
      rateRiskConCode
    }
  }
`

export const DELETE_RATE_RISK_CONTROL = gql`
  mutation DeleteRateRiskControl($input: RateRiskControlDeleteInput!) {
    deleteRateRiskControl(input: $input) {
      rateRiskConCode
    }
  }
`
