import { gql } from '@apollo/client'

export const GET_MASTER_PERSONNEL_LEVELS = gql`
  query getMasterPersonnelLevels($input: MasterPersonnelLevelsInput!) {
    getMasterPersonnelLevels(data: $input) {
      levelId
      levelCode
      levelNameTH
      levelNameEN
      startSalary
      endSalary
      baseSalary
      isActive
    }
  }
`
