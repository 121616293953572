import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_SELECT_MASTER_ACTION_STATUS } from './graphql/Query'
import { Select } from '../../select/Select'
import _ from 'lodash'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectMasterActionStatus = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { isActive, hasInnerFollowUp, hasInnerAuditProject, projectCode, issueId, suggestId, rateFacProCode } =
    filters || {}

  const [isData, isSetData] = useState([])

  const [getSelectMasterActionStatusFn, { data, loading }] = useLazyQuery(GET_SELECT_MASTER_ACTION_STATUS)

  useEffect(() => {
    getSelectMasterActionStatusFn({
      variables: { input: filters ? encryptInput({ ...filters, isActive: 1 }) : encryptInput({ isActive: 1 }) },
    })
  }, [isActive, hasInnerFollowUp, hasInnerAuditProject, projectCode, issueId, suggestId, rateFacProCode])

  useEffect(() => {
    if (data) {
      isSetData(
        data?.getSelectMasterActionStatus.map((item) => {
          let label = displayText(item?.actionStatusNameTH, item?.actionStatusNameEN)
          if (!label) label = item.actionStatusNameTH

          return {
            value: item.actionStatusCode,
            label: `${item.actionStatusCode} : ${label}`,
            source: item,
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        loading={loading}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectMasterActionStatus
