import { Form, Input, Checkbox } from 'antd'
import { Trans } from 'react-i18next'
import { LabelNew } from '../../../../components/label/Label'
import _ from 'lodash'
export const Columns = (typeAction, form, dataTable, checkStatusHeader) => {
  // console.log('checkStatusHeader: ', checkStatusHeader)
  const onCheckAllHeader = ({ e }) => {
    const fields = form.getFieldsValue()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    let newFields = result.map((item, index) => {
      return {
        ...item,
        check_all: e.target.checked,
        view: dataTable[index] && dataTable[index].view.disable ? false : e.target.checked,
        add: dataTable[index] && dataTable[index].add.disable ? false : e.target.checked,
        edit: dataTable[index] && dataTable[index].edit.disable ? false : e.target.checked,
        delete: dataTable[index] && dataTable[index].delete.disable ? false : e.target.checked,
        email: dataTable[index] && dataTable[index].email.disable ? false : e.target.checked,
        download: dataTable[index] && dataTable[index].download.disable ? false : e.target.checked,
        approve: dataTable[index] && dataTable[index].approve.disable ? false : e.target.checked,
        duplicate: dataTable[index] && dataTable[index].duplicate.disable ? false : e.target.checked,
      }
    })
    let tempNew = { ...newFields }
    tempNew.check_all_view = checkStatusHeader.canView ? false : e.target.checked
    tempNew.check_all_add = checkStatusHeader.canAdd ? false : e.target.checked
    tempNew.check_all_edit = checkStatusHeader.canEdit ? false : e.target.checked
    tempNew.check_all_delete = checkStatusHeader.canDelete ? false : e.target.checked
    tempNew.check_all_email = checkStatusHeader.candSend ? false : e.target.checked
    tempNew.check_all_download = checkStatusHeader.canExport ? false : e.target.checked
    tempNew.check_all_approve = checkStatusHeader.canApprove ? false : e.target.checked
    tempNew.check_all_duplicate = checkStatusHeader.canDuplicate ? false : e.target.checked

    form.setFieldsValue(tempNew)
  }

  const fuCheckHeader = () => {
    const fields = form.getFieldsValue()
    let ch_all = {
      view: false,
      canAdd: false,
      canEdit: false,
      canDelete: false,
      candSend: false,
      canExport: false,
      canApprove: false,
      canDuplicate: false,
    }

    if (!checkStatusHeader.canView) {
      if (fields.check_all_view) ch_all.view = true
    } else {
      ch_all.view = true
    }
    if (!checkStatusHeader.canAdd) {
      if (fields.check_all_add) ch_all.canAdd = true
    } else {
      ch_all.canAdd = true
    }
    if (!checkStatusHeader.canEdit) {
      if (fields.check_all_edit) ch_all.canEdit = true
    } else {
      ch_all.canEdit = true
    }
    if (!checkStatusHeader.canDelete) {
      if (fields.check_all_delete) ch_all.canDelete = true
    } else {
      ch_all.canDelete = true
    }
    if (!checkStatusHeader.candSend) {
      if (fields.check_all_email) ch_all.candSend = true
    } else {
      ch_all.candSend = true
    }
    if (!checkStatusHeader.canExport) {
      if (fields.check_all_download) ch_all.canExport = true
    } else {
      ch_all.canExport = true
    }
    if (!checkStatusHeader.canApprove) {
      if (fields.check_all_approve) ch_all.canApprove = true
    } else {
      ch_all.canApprove = true
    }
    if (!checkStatusHeader.canDuplicate) {
      if (fields.check_all_duplicate) ch_all.canDuplicate = true
    } else {
      ch_all.canDuplicate = true
    }
    // fields.check_all_view &&
    //   fields.check_all_add &&
    //   fields.check_all_edit &&
    //   fields.check_all_delete &&
    //   fields.check_all_email &&
    //   fields.check_all_download &&
    //   fields.check_all_approve &&
    //   fields.check_all_duplicate
    if (
      ch_all.view &&
      ch_all.canAdd &&
      ch_all.canEdit &&
      ch_all.canDelete &&
      ch_all.candSend &&
      ch_all.canExport &&
      ch_all.canApprove &&
      ch_all.canDuplicate
    ) {
      form.setFieldsValue({
        check_all_header: true,
      })
    } else {
      form.setFieldsValue({
        check_all_header: false,
      })
    }
  }

  const onCheckAllView = ({ e }) => {
    const fields = form.getFieldsValue()
    fuCheckHeader()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    let newFields = result.map((item, index) => {
      return {
        ...item,
        view: dataTable[index] && dataTable[index].view.disable ? false : e.target.checked,
      }
    })

    form.setFieldsValue(newFields)
  }

  const onCheckAllAdd = ({ e }) => {
    const fields = form.getFieldsValue()
    fuCheckHeader()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    let newFields = result.map((item, index) => {
      return {
        ...item,
        add: dataTable[index] && dataTable[index].add.disable ? false : e.target.checked,
      }
    })

    form.setFieldsValue(newFields)
  }

  const onCheckAllDelete = ({ e }) => {
    const fields = form.getFieldsValue()
    fuCheckHeader()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    let newFields = result.map((item, index) => {
      return {
        ...item,
        delete: dataTable[index] && dataTable[index].delete.disable ? false : e.target.checked,
      }
    })

    form.setFieldsValue(newFields)
  }

  const onCheckAllEmail = ({ e }) => {
    const fields = form.getFieldsValue()
    fuCheckHeader()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    let newFields = result.map((item, index) => {
      return {
        ...item,
        email: dataTable[index] && dataTable[index].email.disable ? false : e.target.checked,
      }
    })

    form.setFieldsValue(newFields)
  }

  const onCheckAllEdit = ({ e }) => {
    const fields = form.getFieldsValue()
    fuCheckHeader()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    let newFields = result.map((item, index) => {
      return {
        ...item,
        edit: dataTable[index] && dataTable[index].edit.disable ? false : e.target.checked,
      }
    })

    form.setFieldsValue(newFields)
  }

  const onCheckAllDownload = ({ e }) => {
    const fields = form.getFieldsValue()
    fuCheckHeader()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    let newFields = result.map((item, index) => {
      return {
        ...item,
        download: dataTable[index] && dataTable[index].download.disable ? false : e.target.checked,
      }
    })

    form.setFieldsValue(newFields)
  }

  const onCheckAllApprove = ({ e }) => {
    const fields = form.getFieldsValue()
    fuCheckHeader()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    let newFields = result.map((item, index) => {
      return {
        ...item,
        approve: dataTable[index] && dataTable[index].approve.disable ? false : e.target.checked,
      }
    })

    form.setFieldsValue(newFields)
  }

  const onCheckAllduplicate = ({ e }) => {
    const fields = form.getFieldsValue()
    fuCheckHeader()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    let newFields = result.map((item, index) => {
      return {
        ...item,
        duplicate: dataTable[index] && dataTable[index].duplicate.disable ? false : e.target.checked,
      }
    })

    form.setFieldsValue(newFields)
  }

  // eslint-disable-next-line no-unused-vars
  const onChangeCheckbox = ({ e, index, type }) => {
    // console.log('type: ', type)
    const fields = form.getFieldsValue()

    if (!_.isEmpty(fields[index])) {
      const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
      let checkBoxInTable = {
        viewCheckbox: true,
        addCheckbox: true,
        editCheckbox: true,
        deleteCheckbox: true,
        emailCheckbox: true,
        downloadCheckbox: true,
        approveCheckbox: true,
        duplicateCheckbox: true,
      }

      // เช็ค เปิดให้ติ๊ก แต่ไม่ได้ติ๊กให้เป็น false
      result.forEach((item, index) => {
        if ((item?.view === false || item?.view === null) && !dataTable[index].view.disable) {
          checkBoxInTable.viewCheckbox = false
        }
        if ((item?.add === false || item?.add === null) && !dataTable[index].add.disable) {
          checkBoxInTable.addCheckbox = false
        }
        if ((item?.edit === false || item?.edit === null) && !dataTable[index].edit.disable) {
          checkBoxInTable.editCheckbox = false
        }
        if ((item?.delete === false || item?.delete === null) && !dataTable[index].delete.disable) {
          checkBoxInTable.deleteCheckbox = false
        }
        if ((item?.email === false || item?.email === null) && !dataTable[index].email.disable) {
          checkBoxInTable.emailCheckbox = false
        }
        if ((item?.download === false || item?.download === null) && !dataTable[index].download.disable) {
          checkBoxInTable.downloadCheckbox = false
        }
        if ((item?.approve === false || item?.approve === null) && !dataTable[index].approve.disable) {
          checkBoxInTable.approveCheckbox = false
        }
        if ((item?.duplicate === false || item?.duplicate === null) && !dataTable[index].duplicate.disable) {
          checkBoxInTable.duplicateCheckbox = false
        }
      })

      let ch_all = {
        view: false,
        canAdd: false,
        canEdit: false,
        canDelete: false,
        candSend: false,
        canExport: false,
        canApprove: false,
        canDuplicate: false,
      }

      let ch_all2 = {
        view: false,
        canAdd: false,
        canEdit: false,
        canDelete: false,
        candSend: false,
        canExport: false,
        canApprove: false,
        canDuplicate: false,
      }

      if (!checkStatusHeader.canView) {
        if (checkBoxInTable.viewCheckbox) {
          ch_all.view = true
          ch_all2.view = true
        }
      } else {
        ch_all2.view = true
      }
      if (!checkStatusHeader.canAdd) {
        if (checkBoxInTable.addCheckbox) {
          ch_all.canAdd = true
          ch_all2.canAdd = true
        }
      } else {
        ch_all2.canAdd = true
      }
      if (!checkStatusHeader.canEdit) {
        if (checkBoxInTable.editCheckbox) {
          ch_all.canEdit = true
          ch_all2.canEdit = true
        }
      } else {
        ch_all2.canEdit = true
      }
      if (!checkStatusHeader.canDelete) {
        if (checkBoxInTable.deleteCheckbox) {
          ch_all.canDelete = true
          ch_all2.canDelete = true
        }
      } else {
        ch_all2.canDelete = true
      }
      if (!checkStatusHeader.candSend) {
        if (checkBoxInTable.emailCheckbox) {
          ch_all.candSend = true
          ch_all2.candSend = true
        }
      } else {
        ch_all2.candSend = true
      }
      if (!checkStatusHeader.canExport) {
        if (checkBoxInTable.downloadCheckbox) {
          ch_all.canExport = true
          ch_all2.canExport = true
        }
      } else {
        ch_all2.canExport = true
      }
      if (!checkStatusHeader.canApprove) {
        if (checkBoxInTable.approveCheckbox) {
          ch_all.canApprove = true
          ch_all2.canApprove = true
        }
      } else {
        ch_all2.canApprove = true
      }
      if (!checkStatusHeader.canDuplicate) {
        if (checkBoxInTable.duplicateCheckbox) {
          ch_all.canDuplicate = true
          ch_all2.canDuplicate = true
        }
      } else {
        ch_all2.canDuplicate = true
      }

      fields.check_all_view = ch_all.view
      fields.check_all_add = ch_all.canAdd
      fields.check_all_edit = ch_all.canEdit
      fields.check_all_delete = ch_all.canDelete
      fields.check_all_email = ch_all.candSend
      fields.check_all_download = ch_all.canExport
      fields.check_all_approve = ch_all.canApprove
      fields.check_all_duplicate = ch_all.canDuplicate

      // //  --- view ---
      // // || item?.view === undefined
      // let viewCheckbox = result.find((item) => item?.view === false || item?.view === null)
      // fields.check_all_view = viewCheckbox === undefined ? true : false

      // //  --- add ---
      // // || item?.add === undefined
      // let addCheckbox = result.find((item) => item?.add === false || item?.add === null)
      // fields.check_all_add = addCheckbox === undefined ? true : false

      // //  --- edit ---
      // // || item?.edit === undefined
      // let editCheckbox = result.find((item) => item?.edit === false || item?.edit === null)
      // fields.check_all_edit = editCheckbox === undefined ? true : false

      // //  --- delete ---
      // // || item?.delete === undefined
      // let deleteCheckbox = result.find((item) => item?.delete === false || item?.delete === null)
      // fields.check_all_delete = deleteCheckbox === undefined ? true : false

      // //  --- email ---
      // // || item?.delete === undefined
      // let emailCheckbox = result.find((item) => item?.email === false || item?.email === null)
      // fields.check_all_email = emailCheckbox === undefined ? true : false

      // //  --- download ---
      // // || item?.download === undefined
      // let downloadCheckbox = result.find((item) => item?.download === false || item?.download === null)
      // fields.check_all_download = downloadCheckbox === undefined ? true : false

      // //  --- approve ---
      // // || item?.approve === undefined
      // let approveCheckbox = result.find((item) => item?.approve === false || item?.approve === null)
      // fields.check_all_approve = approveCheckbox === undefined ? true : false

      // //  --- duplicate ---
      // // || item?.duplicate === undefined
      // let duplicateCheckbox = result.find((item) => item?.duplicate === false || item?.duplicate === null)
      // fields.check_all_duplicate = duplicateCheckbox === undefined ? true : false

      if (
        ch_all2.view &&
        ch_all2.canAdd &&
        ch_all2.canEdit &&
        ch_all2.canDelete &&
        ch_all2.candSend &&
        ch_all2.canExport &&
        ch_all2.canApprove &&
        ch_all2.canDuplicate
      ) {
        fields.check_all_header = true
      } else {
        fields.check_all_header = false
      }
    }
    form.setFieldsValue(fields)
  }

  return [
    {
      title: <Trans>รายการเมนูย่อย</Trans>,
      align: 'center',
      children: [
        {
          dataIndex: 'menuCode',
          key: 'menuCode',
          width: 0,
          render: (item, record, index) => {
            return (
              <Form.Item hidden name={[index, 'menuCode']} initialValue={item} style={{ margin: '0px', width: '10px' }}>
                <Input />
              </Form.Item>
            )
          },
        },
        {
          title: (
            <>
              <Form.Item
                name={'check_all_header'}
                valuePropName="checked" // ต้องมี
                initialValue={false}
                className="text-nowrap"
                style={{ margin: '0px' }}
              >
                <Checkbox
                  disabled={typeAction || checkStatusHeader.check_all_header}
                  style={{ fontSize: '14px' }}
                  onChange={(e) => {
                    onCheckAllHeader({ e })
                  }}
                >
                  {' '}
                  <Trans>เลือกทั้งหมด</Trans>
                </Checkbox>
              </Form.Item>
            </>
          ),
          dataIndex: 'menu',
          key: 'menu',
          align: 'left',
          className: 'text-nowrap',
          render: (item) => {
            return <LabelNew type="body">{item}</LabelNew>
          },
        },
      ],
    },
    {
      title: <Trans>สิทธิ์การใช้งาน</Trans>,
      children: [
        {
          title: <Trans>ดู</Trans>,
          align: 'center',
          height: '5px',
          className: 'text-nowrap',
          children: [
            {
              title: (
                <>
                  <Form.Item
                    wrapperCol={{
                      md: 24,
                      lg: 24,
                    }}
                    name={'check_all_view'}
                    valuePropName="checked" // ต้องมี
                    initialValue={false}
                    style={{ margin: '0px' }}
                  >
                    <Checkbox
                      disabled={typeAction || checkStatusHeader.canView}
                      style={{ fontSize: '14px' }}
                      onChange={(e) => onCheckAllView({ e })}
                    />
                  </Form.Item>
                </>
              ),
              dataIndex: 'view',
              key: 'view',
              align: 'center',
              width: 79,
              render: (item, record, index) => {
                return (
                  <Form.Item
                    wrapperCol={{
                      md: 24,
                      lg: 24,
                    }}
                    name={[index, 'view']}
                    valuePropName="checked" // ต้องมี
                    initialValue={item.value}
                    style={{ margin: '0px' }}
                  >
                    <Checkbox
                      checked={item.value}
                      disabled={typeAction || item.disable} //typeAction
                      onChange={(e) => onChangeCheckbox({ e, index, type: 'view' })}
                    />
                  </Form.Item>
                )
              },
            },
          ],
        },
        {
          title: <Trans>เพิ่ม</Trans>,
          align: 'center',
          children: [
            {
              title: (
                <Form.Item
                  wrapperCol={{
                    md: 24,
                    lg: 24,
                  }}
                  name={'check_all_add'}
                  valuePropName="checked" // ต้องมี
                  initialValue={false}
                  style={{ margin: '0px' }}
                >
                  <Checkbox
                    disabled={typeAction || checkStatusHeader.canAdd}
                    style={{ fontSize: '14px' }}
                    onChange={(e) => onCheckAllAdd({ e })}
                  />
                </Form.Item>
              ),
              dataIndex: 'add',
              align: 'center',
              key: 'add',
              width: 79,
              render: (item, record, index) => {
                return (
                  <Form.Item
                    wrapperCol={{
                      md: 24,
                      lg: 24,
                    }}
                    name={[index, 'add']}
                    valuePropName="checked" // ต้องมี
                    initialValue={item.value}
                    style={{ margin: '0px' }}
                  >
                    <Checkbox
                      disabled={typeAction || item.disable}
                      onChange={(e) => onChangeCheckbox({ e, index, type: 'add' })}
                    />
                  </Form.Item>
                )
              },
            },
          ],
        },
        {
          title: <Trans>แก้ไข</Trans>,
          align: 'center',
          children: [
            {
              title: (
                <Form.Item
                  wrapperCol={{
                    md: 24,
                    lg: 24,
                  }}
                  name={'check_all_edit'}
                  valuePropName="checked" // ต้องมี
                  initialValue={false}
                  style={{ margin: '0px' }}
                >
                  <Checkbox
                    disabled={typeAction || checkStatusHeader.canEdit}
                    style={{ fontSize: '14px' }}
                    onChange={(e) => onCheckAllEdit({ e })}
                  />
                </Form.Item>
              ),
              dataIndex: 'edit',
              align: 'center',
              key: 'edit',
              width: 79,
              render: (item, record, index) => {
                return (
                  <Form.Item
                    wrapperCol={{
                      md: 24,
                      lg: 24,
                    }}
                    name={[index, 'edit']}
                    valuePropName="checked" // ต้องมี
                    initialValue={item.value}
                    style={{ margin: '0px' }}
                  >
                    <Checkbox
                      disabled={typeAction || item.disable}
                      onChange={(e) => onChangeCheckbox({ e, index, type: 'edit' })}
                    />
                  </Form.Item>
                )
              },
            },
          ],
        },
        {
          title: <Trans>ลบ</Trans>,
          align: 'center',
          children: [
            {
              title: (
                <Form.Item
                  wrapperCol={{
                    md: 24,
                    lg: 24,
                  }}
                  name={'check_all_delete'}
                  valuePropName="checked" // ต้องมี
                  className="text-nowrap"
                  initialValue={false}
                  style={{ margin: '0px' }}
                >
                  <Checkbox
                    disabled={typeAction || checkStatusHeader.canDelete}
                    style={{ fontSize: '14px' }}
                    onChange={(e) => onCheckAllDelete({ e })}
                  />
                </Form.Item>
              ),
              dataIndex: 'delete',
              align: 'center',
              key: 'delete',
              width: 79,
              render: (item, record, index) => {
                return (
                  <Form.Item
                    wrapperCol={{
                      md: 24,
                      lg: 24,
                    }}
                    name={[index, 'delete']}
                    valuePropName="checked" // ต้องมี
                    initialValue={item.value}
                    style={{ margin: '0px' }}
                  >
                    <Checkbox
                      disabled={typeAction || item.disable}
                      onChange={(e) => onChangeCheckbox({ e, index, type: 'delete' })}
                    />
                  </Form.Item>
                )
              },
            },
          ],
        },
        {
          title: <Trans>ส่งเมล</Trans>,
          align: 'center',
          children: [
            {
              title: (
                <Form.Item
                  wrapperCol={{
                    md: 24,
                    lg: 24,
                  }}
                  name={'check_all_email'}
                  valuePropName="checked" // ต้องมี
                  initialValue={false}
                  className="text-nowrap"
                  style={{ margin: '0px' }}
                >
                  <Checkbox
                    disabled={typeAction || checkStatusHeader.candSend}
                    style={{ fontSize: '14px' }}
                    onChange={(e) => onCheckAllEmail({ e })}
                  />
                </Form.Item>
              ),
              dataIndex: 'email',
              align: 'center',
              key: 'email',
              width: 79,
              render: (item, record, index) => {
                return (
                  <Form.Item
                    wrapperCol={{
                      md: 24,
                      lg: 24,
                    }}
                    name={[index, 'email']}
                    valuePropName="checked" // ต้องมี
                    initialValue={item.value}
                    style={{ margin: '0px' }}
                  >
                    <Checkbox
                      disabled={typeAction || item.disable}
                      onChange={(e) => onChangeCheckbox({ e, index, type: 'email' })}
                    />
                  </Form.Item>
                )
              },
            },
          ],
        },
        {
          title: <Trans>ดาวน์โหลด</Trans>,
          align: 'center',
          children: [
            {
              title: (
                <Form.Item
                  wrapperCol={{
                    md: 24,
                    lg: 24,
                  }}
                  name={'check_all_download'}
                  valuePropName="checked" // ต้องมี
                  initialValue={false}
                  style={{ margin: '0px' }}
                  className="text-nowrap"
                >
                  <Checkbox
                    disabled={typeAction || checkStatusHeader.canExport}
                    style={{ fontSize: '14px' }}
                    onChange={(e) => onCheckAllDownload({ e })}
                  />
                </Form.Item>
              ),
              dataIndex: 'download',
              align: 'center',
              key: 'download',
              width: 79,
              render: (item, record, index) => {
                return (
                  <Form.Item
                    wrapperCol={{
                      md: 24,
                      lg: 24,
                    }}
                    name={[index, 'download']}
                    valuePropName="checked" // ต้องมี
                    initialValue={item.value}
                    style={{ margin: '0px' }}
                    className="text-nowrap"
                  >
                    <Checkbox
                      disabled={typeAction || item.disable}
                      style={{ fontSize: '14px' }}
                      onChange={(e) => onChangeCheckbox({ e, index, type: 'download' })}
                    />
                  </Form.Item>
                )
              },
            },
          ],
        },
        {
          title: <Trans>อนุมัติ</Trans>,
          align: 'center',
          children: [
            {
              title: (
                <Form.Item
                  wrapperCol={{
                    md: 24,
                    lg: 24,
                  }}
                  name={'check_all_approve'}
                  valuePropName="checked" // ต้องมี
                  initialValue={false}
                  style={{ margin: '0px' }}
                  className="text-nowrap"
                >
                  <Checkbox
                    disabled={typeAction || checkStatusHeader.canApprove}
                    style={{ fontSize: '14px' }}
                    onChange={(e) => onCheckAllApprove({ e })}
                  />
                </Form.Item>
              ),
              dataIndex: 'approve',
              align: 'center',
              key: 'approve',
              width: 79,
              render: (item, record, index) => {
                return (
                  <Form.Item
                    wrapperCol={{
                      md: 24,
                      lg: 24,
                    }}
                    name={[index, 'approve']}
                    valuePropName="checked" // ต้องมี
                    initialValue={item.value}
                    style={{ margin: '0px' }}
                  >
                    <Checkbox
                      disabled={typeAction || item.disable}
                      onChange={(e) => onChangeCheckbox({ e, index, type: 'approve' })}
                    />
                  </Form.Item>
                )
              },
            },
          ],
        },
        {
          title: <Trans>คัดลอก</Trans>,
          align: 'center',
          children: [
            {
              title: (
                <Form.Item
                  wrapperCol={{
                    md: 24,
                    lg: 24,
                  }}
                  name={'check_all_duplicate'}
                  valuePropName="checked" // ต้องมี
                  initialValue={false}
                  style={{ margin: '0px' }}
                >
                  <Checkbox
                    disabled={typeAction || checkStatusHeader.canDuplicate}
                    style={{ fontSize: '14px' }}
                    onChange={(e) => onCheckAllduplicate({ e })}
                  />
                </Form.Item>
              ),
              dataIndex: 'duplicate',
              align: 'center',
              key: 'duplicate',
              width: 79,
              render: (item, record, index) => {
                return (
                  <Form.Item
                    wrapperCol={{
                      md: 24,
                      lg: 24,
                    }}
                    name={[index, 'duplicate']}
                    valuePropName="checked" // ต้องมี
                    initialValue={item.value}
                    style={{ margin: '0px' }}
                  >
                    <Checkbox
                      disabled={typeAction || item.disable}
                      onChange={(e) => onChangeCheckbox({ e, index, type: 'duplicate' })}
                    />
                  </Form.Item>
                )
              },
            },
          ],
        },
      ],
    },
  ]
}
