import React, { useState, useEffect } from 'react'
import { breadcrumbList } from './utils/breadcrumbData'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card.jsx'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import { colummList } from './utils/formColumn'
import { Status } from '../../../components/status/Status'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { handleSort } from '../../../utilitys/pagination'
import { useLanguage } from '../../../hooks/useLanguage'
import { ButtonNew } from '../../../components/button/Button'
import CreateModal from './components/modal/CreateModal'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GETFORMSPAGINATION } from './graphql/Query'
import { FORMSDELETE } from './graphql/Mutation'
import { checkLanguageReturnData } from './components/assessmentFormfn'
import * as _ from 'lodash'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { Alert } from '../../../components/alert/Alert'
import { displayError } from '../../../utilitys/helper'

export default function AssessmentFormList(props) {
  const { menu } = props
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [dataList, setDataList] = useState([])

  const [getFormsPaginationfn, getFormsPagination] = useLazyQuery(GETFORMSPAGINATION)
  const [formsDeleteFn, { loading: loadingDelete, error, data: dataDelete }] = useMutation(FORMSDELETE)

  /* ---------------- delete on api ----------- */
  const formsDeletefn = (dataCode, checkDelete) => {
    if (checkDelete) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t('เนื่องจากมีการใช้งานแบบประเมินไปแล้ว'),
        onOk() {},
      })
    } else {
      Alert({
        type: 'confirm',
        title: t('ต้องการยืนยันการลบ ?'),
        onOk() {
          formsDeleteFn({
            variables: {
              input: encryptInput({
                formCode: dataCode,
              }),
            },
          })
        },
        onCancel() {},
      })
    }
  }

  useEffect(() => {
    getFormsPaginationfn({
      variables: {
        input: encryptInput({
          filters: {},
          pagination: {
            limit: limit,
            page: page,
          },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (getFormsPagination.data) {
      let response = getFormsPagination?.data?.getFormsPagination
      setCount(response?.count)
      setDataList(
        _.map(response?.result, (dt, keys) => {
          return {
            key: keys,
            no: dt.no,
            // formName: dt.formCode ? dt.formCode + ' : ' + checkLanguageReturnData(dt.formNameTH, dt.formNameEN) : '',
            formName: checkLanguageReturnData(dt.formNameTHShow, dt.formNameENShow),
            formTypeName: dt.formTypeCode ? checkLanguageReturnData(dt.formTypeNameTH, dt.formTypeNameEN) : '',
            isActive: <Status key={'status-apl-1'} text={dt.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}></Status>,
            options: (
              <div key={'div-view-templaterick' + 1} style={{ textAlign: 'left' }}>
                <ButtonGroup
                  key={'btn-view-templaterick' + 1}
                  menu={menu}
                  icons={[
                    {
                      type: 'copy',
                      onClick: () => {
                        navigate('/administrator/assessmentsetup/assessmentformdetail/copy', {
                          state: {
                            refCode: dt.formCode,
                          },
                        })
                      },
                    },
                    {
                      type: 'view',
                      onClick: () => {
                        navigate('/administrator/assessmentsetup/assessmentformdetail/view', {
                          state: {
                            refCode: dt.formCode,
                          },
                        })
                      },
                    },
                    {
                      type: 'edit',
                      onClick: () => {
                        navigate('/administrator/assessmentsetup/assessmentformdetail/edit', {
                          state: {
                            refCode: dt.formCode,
                          },
                        })
                      },
                    },
                    {
                      type: 'delete',
                      onClick: () => {
                        formsDeletefn(dt.formCode, dt.statusEdit)
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (getFormsPagination.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getFormsPagination.error)),
        onOk() {},
      })
    }
  }, [getFormsPagination.data])

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          getFormsPagination.refetch()
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [dataDelete])

  return (
    <React.Fragment>
      {getFormsPagination.loading && <SpinnersNew />}
      {loadingDelete && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('จัดการข้อมูลแบบประเมิน')} />
      <CardNew topic={t('รายการข้อมูลแบบประเมิน')} icon="List" toggledrop={'false'}>
        <Datatable
          columns={colummList()}
          data={dataList}
          total={count}
          isScoll={{ x: true, y: true }}
          onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
          paginationCustom={true}
          searchCustom={true}
          pageSize={limit}
          page={page}
          handleTableChange={(e) => {
            const lang = isLang === 'th' ? true : false
            const reNewField = [
              { formName: lang ? 'formNameTHShow' : 'formNameENShow' },
              { formTypeName: lang ? 'formTypeNameTH' : 'formTypeNameEN' },
            ]
            setSort(handleSort(e?.sorter, reNewField))
          }}
          btnAdd={
            <>
              <ButtonNew
                type="plusTableBorderPrimary"
                roles={{ type: 'add', menu: menu }}
                onClick={() => {
                  setModalVisible(true)
                }}
              >
                {t('เพิ่มข้อมูลแบบประเมิน')}
              </ButtonNew>
            </>
          }
        />
      </CardNew>
      <CreateModal
        open={modalVisible}
        close={(e) => {
          setModalVisible(e)
        }}
        menu={menu}
      />
    </React.Fragment>
  )
}
