import { gql } from '@apollo/client'
export const GET_SELECT_RATE_RISK_RULE = gql`
  query GetSelectRateRiskRule($input: SelectRateRiskRuleInput!) {
    getSelectRateRiskRule(input: $input) {
      rateRiskRuleId
      rateRiskRuleTH
      rateRiskRuleCode
      rateRiskRuleEN
      rateFacProCode
      sort
    }
  }
`
