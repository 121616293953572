import React from 'react'
import { Row, Col } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import RiskLevelList from './RiskLevelList'
import { useTranslation } from 'react-i18next'

/**
 * @function RiskLevelIndex
 * @description
 * Menu: CauseOfIssue
 * เมนู: จัดการระดับความเสี่ยง
 * @returns Component
 */
const RiskLevelIndex = ({ menu }) => {
  const { t } = useTranslation()
  const arrCrumb = [{ label: t('จัดการข้อมูลพื้นฐาน'), path: '#' }, { label: t('จัดการระดับความเสี่ยง') }]

  const Responsive = {
    md: 24,
    lg: 24,
  }
  return (
    <React.Fragment>
      <Row>
        <Col {...Responsive}>
          <Breadcrumb data={arrCrumb} title={t('จัดการระดับความเสี่ยง')} />
          <RiskLevelList menu={menu} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default RiskLevelIndex
