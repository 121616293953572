import { gql } from '@apollo/client'

export const GET_DATA_OVERVIEW_AUDIT_GRAPH_PERSONNEL_TRAINING = gql`
  query GetOverviewAuditGraphPersonnelTraining($input: OverviewAuditGraphInput!) {
    getOverviewAuditGraphPersonnelTraining(input: $input) {
      level
      type
      value
    }
  }
`

export const GET_DATA_OVERVIEW_AUDIT_GRAPH_PERSONNEL_POSITION = gql`
  query GetOverviewAuditGraphPersonnelPosition($input: OverviewAuditGraphInput!) {
    getOverviewAuditGraphPersonnelPosition(input: $input) {
      level {
        nameTh
        nameEn
      }
      type {
        nameTh
        nameEn
      }
      value
    }
  }
`

export const GET_DATA_OVERVIEW_AUDIT_GRAPH_PERSONNEL_START_WORKING = gql`
  query GetOverviewAuditGraphPersonnelStartWorking($input: OverviewAuditGraphInput!) {
    getOverviewAuditGraphPersonnelStartWorking(input: $input) {
      level
      type {
        nameTh
        nameEn
      }
      value
    }
  }
`

export const GET_DATA_OVERVIEW_AUDIT_GRAPH_ASSIGN_JOBS = gql`
  query GetOverviewAuditGraphAssignJobs($input: OverviewAuditGraphInput!) {
    getOverviewAuditGraphAssignJobs(input: $input) {
      level
      type
      value
    }
  }
`

export const GET_DATA_OVERVIEW_AUDIT_GRAPH_PERSONNEL_LEAVE_STATUS = gql`
  query GetOverviewAuditGraphPersonnelLeaveStatus($input: OverviewAuditGraphInput!) {
    getOverviewAuditGraphPersonnelLeaveStatus(input: $input) {
      level
      type {
        nameTh
        nameEn
      }
      value
    }
  }
`

export const GET_DATA_OVERVIEW_AUDIT_GRAPH_CERTIFICATES = gql`
  query GetOverviewAuditGraphCertificates($input: OverviewAuditGraphInput!) {
    getOverviewAuditGraphCertificates(input: $input) {
      level
      type
      value
    }
  }
`

export const GET_DATA_OVERVIEW_AUDIT_GRAPH_CERTIFICATE_GROUP = gql`
  query GetOverviewAuditGraphCertificateGroup($input: OverviewAuditGraphInput!) {
    getOverviewAuditGraphCertificateGroup(input: $input) {
      level
      type
      value
    }
  }
`

export const GET_DATA_OVERVIEW_AUDIT_TABLE_LIST = gql`
  query GetOverviewAuditTableList($input: OverviewAuditTableListInput!) {
    getOverviewAuditTableList(input: $input) {
      result {
        nameTh
        nameEn
        positionNameTh
        positionNameEn
        personnelStatusNameTh
        personnelStatusNameEn
        certificateShotTh
        certificateShotEn
        trainingHours
      }
      count
      page
      limit
    }
  }
`

export const GET_DATA_OVERVIEW_AUDIT_TABLE_LIST_EXPORT = gql`
  query GetOverviewAuditTableListExport($input: OverviewAuditExportInput!) {
    getOverviewAuditTableListExport(input: $input) {
      message
      fileUrl
    }
  }
`
