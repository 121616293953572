import React from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'

import { Datepicker } from '../../../../components/datepicker/Datepicker'
import { CardNew } from '../../../../components/card/Card.jsx'
import { LabelNew } from '../../../../components/label/Label'
import { ButtonNew } from '../../../../components/button/Button'
import SelectProjectRefer from '../../../../components/inputfromapi/selectprojectrefer/SelectProjectRefer'
import SelectProjectSizes from '../../../../components/inputfromapi/selectprojectsizes/SelectProjectSizes'
import SelectProjectStatus from '../../../../components/inputfromapi/selectprojectstatus/SelectProjectStatus'
import SelectApproveStatus from '../../../../components/inputfromapi/selectapprovestatus/SelectApproveStatus'
import SelectAuditTeams from '../../../../components/inputfromapi/selectauditteams/SelectAuditTeams.jsx'
import { InputNew as Input } from '../../../../components/input/Input'

const FilterAuditProjectsList = (props) => {
  const { t } = useTranslation()

  const onFinish = () => {}
  const onFinishFailed = () => {}

  function setErrorField(name, errors) {
    // Set field errors
    return props.formFilter.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  const validDate = () => {
    let valid = false
    if (props.formFilter.getFieldValue('dateStart') && props.formFilter.getFieldValue('dateEnd')) {
      let dateStart = new Date(props.formFilter.getFieldValue('dateStart'))
      let dateEnd = new Date(props.formFilter.getFieldValue('dateEnd'))
      if (dateStart <= dateEnd) {
        setErrorField('dateStart', false)
        setErrorField('dateEnd', false)
        valid = true
      } else {
        setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นน้อยกว่าวันที่สิ้นสุด'))
        setErrorField('dateEnd', t('กรุณาเลือกวันที่สิ้นสุดมากกว่าวันที่เริ่มต้น'))
      }
    } else {
      setErrorField('dateStart', false)
      setErrorField('dateEnd', false)
      valid = true
    }
    return valid
  }

  return (
    <>
      <CardNew topic={t('Filters')} icon="AlignJustify" toggledrop="false">
        <Row>
          <Col md={24}>
            <Form
              form={props.formFilter}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              name="filterAuditProject"
              layout="vertical"
            >
              <Row gutter={[12, 12]}>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="auditProjectInfor"
                    label={<LabelNew type="tab-header-inactive">{t('รหัสหรือชื่อโครงการ')}</LabelNew>}
                    rules={[
                      {
                        max: 200,
                        message: t('กรุณาระบุรหัสหรือชื่อโครงการน้อยกว่าหรือเท่ากับ 200 ตัวอักษร'),
                      },
                    ]}
                  >
                    <Input maxLength={200} placeholder={t('ระบุรหัสหรือชื่อโครงการ')} />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="dateStart"
                    labelAlign="left"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        props.formFilter.setFieldsValue({
                          dateStart: await data,
                        })
                        validDate()
                      }}
                      onClear={() => {
                        props.formFilter.setFieldsValue({ dateStart: null })
                      }}
                      placeholder={t('เลือกวันที่เริ่มต้น')}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="dateEnd"
                    labelAlign="left"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        props.formFilter.setFieldsValue({
                          dateEnd: await data,
                        })
                        validDate()
                      }}
                      onClear={() => {
                        props.formFilter.setFieldsValue({ dateEnd: null })
                      }}
                      placeholder={t('เลือกวันที่สิ้นสุด')}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="projectRefCode"
                    label={<LabelNew type="tab-header-inactive">{t('แหล่งที่มาของโครงการ')}</LabelNew>}
                  >
                    <SelectProjectRefer
                      filters={{ isActive: 1 }}
                      formname="filterAuditProject"
                      placeholder={t('เลือกแหล่งที่มาของโครงการ')}
                      handleChange={(value) => {
                        props.formFilter.setFieldsValue({ projectRefCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="projectSizeCode"
                    label={<LabelNew type="tab-header-inactive">{t('ขนาดโครงการ')}</LabelNew>}
                  >
                    <SelectProjectSizes
                      filters={{ isActive: 1 }}
                      formname="filterAuditProject"
                      placeholder={t('เลือกขนาดโครงการ')}
                      handleChange={(value) => {
                        props.formFilter.setFieldsValue({ projectSizeCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="projectStatusCode"
                    label={<LabelNew type="tab-header-inactive">{t('สถานะของโครงการ')}</LabelNew>}
                  >
                    <SelectProjectStatus
                      filters={{ isActive: 1 }}
                      formname="filterAuditProject"
                      placeholder={t('เลือกสถานะของโครงการ')}
                      handleChange={(value) => {
                        props.formFilter.setFieldsValue({ projectStatusCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="approveStatusCode"
                    label={<LabelNew type="tab-header-inactive">{t('สถานะการอนุมัติ')}</LabelNew>}
                  >
                    <SelectApproveStatus
                      filters={{ isActive: 1 }}
                      formname="filterAuditProject"
                      placeholder={t('เลือกสถานะการอนุมัติ')}
                      handleChange={(value) => {
                        props.formFilter.setFieldsValue({ approveStatusCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item name="auditTeamCode" label={t('ทีมตรวจสอบ')}>
                    <SelectAuditTeams
                      filters={{ isActive: 1 }}
                      formname={'filterAuditProject'}
                      handleChange={(value) => {
                        props.formFilter.setFieldsValue({ auditTeamCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew
                    type="search"
                    onClick={() => {
                      const valid = validDate()
                      if (
                        valid &&
                        props.formFilter.getFieldsError().filter((item) => {
                          return item.errors.length !== 0
                        }).length === 0
                      ) {
                        props.setSearchStatus(props.searchStatus + 1)
                      }
                    }}
                    roles={{ type: 'view', menu: props.menu }}
                    htmlType="submit"
                  >
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew type="clear" onClick={() => props.formFilter.resetFields()}>
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default FilterAuditProjectsList
