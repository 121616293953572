import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CardNew } from '../../../components/card/Card'
import { LabelNew } from '../../../components/label/Label'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { Datatable } from '../../../components/datatable/Datatable'
import { colors } from '../../../configs/styles.config'
import { Columns } from './utils/TimeSheetApproveRequestColumns'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { Alert } from '../../../components/alert/Alert'
import { Status } from '../../../components/status/Status.jsx'
import { SpinnersNew } from '../../../components/spinners/Spinners.jsx'
import { TextAreaNew } from '../../../components/textarea/TextArea'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay, timeDisplay } from '../../../utilitys/dateHelper'
import { validateURL, displayText, displayError } from '../../../utilitys/helper'
import Files from './components/Files'
import { GET_TIMESHEET_REQUEST_BY_ID } from './graphql/Query'
import { APPROVE_TIMESHEET_REQUEST } from './graphql/Mutation'

const TimeSheetApproveForm = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()

  if (!state || !state.refCode) {
    return <Navigate to={'/auditoperations/timesheetapprove'} />
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/timesheetapprove' },
    { label: t('รายการคำขออนุมัติปฏิบัติงาน'), path: '/auditoperations/timesheetapprove' },
    { label: t('อนุมัติการปฏิบัติงาน') },
  ]

  const responsiveLabel = { sm: 10, md: 6, lg: 6 }
  const responsiveData = { sm: 14, md: 18, lg: 18 }
  const { refCode, type_action } = state

  const disabled = type_action === 'view' ? true : false

  const [timeSheetRequest, setTimeSheetRequest] = useState({})
  const [dataLists, setDataLists] = useState([])
  const [getSelectData, setSelectData] = useState([])
  const [getFiles, setFiles] = useState([])

  /* ---------------- API ----------- */
  const [
    getTimeSheetRequestFn,
    { loading: loadingTimeSheetRequest, error: errorTimeSheetRequest, data: dataTimeSheetRequest },
  ] = useLazyQuery(GET_TIMESHEET_REQUEST_BY_ID)

  const [approveFn, { loading: loadingApprove, error: errorApprove, data: approveTimeSheet }] =
    useMutation(APPROVE_TIMESHEET_REQUEST)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (refCode) {
      getTimeSheetRequestFn({
        variables: {
          requestId: encryptInput(refCode),
        },
      })
    }
  }, [refCode])

  useEffect(() => {
    if (dataTimeSheetRequest) {
      const data = dataTimeSheetRequest.getTimeSheetRequestById
      setTimeSheetRequest(data)
      setFiles(
        data.listFile.map((item, key) => {
          return <Files key={key} item={item} index={key} />
        }),
      )
      setDataLists(
        _.map(data.requestDetail, (item, index) => {
          return {
            key: index + 1,
            timeSheetId: item.timeSheetId,
            jobAndProjectName: displayText(item.jobAndProjectNameTH, item.jobAndProjectNameEN) || '-',
            jobGroupName: displayText(item.jobGroupNameTH, item.jobGroupNameEN) || '-',
            typeName: displayText(item.typeNameTH, item.typeNameEN) || '-',
            urgentName: displayText(item.urgentNameTH, item.urgentNameEN) || '-',
            jobDetail: displayText(item.jobDetailTH, item.jobDetailEN) || '-',
            dateStart: dateDisplay(item.dateStart) || '-',
            dateEnd: dateDisplay(item.dateEnd) || '-',
            timeStart: timeDisplay(item.timeStart) || '-',
            timeEnd: timeDisplay(item.timeEnd) || '-',
            jobStatusName: <Status text={item.jobStatusNameTH} />,
            approveStatusName: <Status text={item.approveStatusNameTH} />,
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () =>
                        navigate(`/auditoperations/timesheetapprove/timesheet/detail`, {
                          state: { type_action: type_action, refCode: refCode, timeSheetId: item.timeSheetId },
                        }),
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }

    if (errorTimeSheetRequest) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(errorTimeSheetRequest)),
        onOk() {
          navigate('/auditoperations/timesheetapprove')
        },
      })
    }
  }, [dataTimeSheetRequest])

  useEffect(() => {
    if (approveTimeSheet) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/auditoperations/timesheetapprove')
        },
      })
    }
  }, [approveTimeSheet])

  useEffect(() => {
    if (errorApprove) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorApprove)),
      })
    }
  }, [errorApprove])

  /* ---------------- ACTION ----------- */
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectData(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  }

  const btnAlertNotSelect = () => {
    Alert({
      type: 'error',
      title: t(`กรุณาเลือกรายการปฏิบัติงาน`),
      onOk() {},
    })
  }

  const btnConfirmApprove = () => {
    const dataApprove = {
      requestId: refCode,
      approveStatusCode: '20',
      cancelRemarkTH: '',
      cancelRemarkEN: '',
      timesheetList: getSelectData.map((item) => {
        return item.timeSheetId
      }),
    }

    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อทำการอนุมัติรายการที่เลือก'),
      onOk: async () => {
        await approveFn({
          variables: {
            input: encryptInput(dataApprove),
          },
        })
      },
      onCancel() {
        return null
      },
    })
  }

  const btnConfirmNotApprove = () => {
    let remark = ''

    Alert({
      type: 'confirm',
      title: t('ระบุเหตุผลการไม่อนุมัติการปฏิบัติงาน'),
      content: (
        <TextAreaNew
          showCount
          maxLength={300}
          rows={5}
          onChange={(e) => {
            remark = e.target.value
          }}
        />
      ),
      onOk: async () => {
        if (remark.length === 0) {
          Alert({
            type: 'error',
            title: t(`กรุณาระบุเหตุผลการไม่อนุมัติการปฏิบัติงาน`),
            onOk() {},
          })
        } else {
          const dataPost = {
            requestId: refCode,
            approveStatusCode: '30',
            cancelRemarkTH: remark,
            cancelRemarkEN: remark,
            timesheetList: getSelectData.map((item) => {
              return item.timeSheetId
            }),
          }

          await approveFn({
            variables: {
              input: encryptInput(dataPost),
            },
          })
        }
      },
      onCancel() {
        return null
      },
    })
  }

  return (
    <>
      {(loadingTimeSheetRequest || loadingApprove) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('อนุมัติการปฏิบัติงาน')} />
      <CardNew topic={t('รายละเอียดใบคำขออนุมัติปฏิบัติงาน')} icon={'AlertCircle'} toggledrop="false">
        <Col span={24} offset={2}>
          <Row gutter={[16, 24]}>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">{t('เลขที่คำขอ')}</LabelNew>
            </Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              <LabelNew type="body-primary">{timeSheetRequest?.requestNumber}</LabelNew>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>
            </Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              <LabelNew type="body-primary">{dateDisplay(timeSheetRequest?.dateStart)}</LabelNew>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>
            </Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              <LabelNew type="body-primary">{dateDisplay(timeSheetRequest?.dateEnd)}</LabelNew>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">{t('ลิงค์ภายนอก')}</LabelNew>
            </Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              <LabelNew type="body-primary">
                {timeSheetRequest?.fileDetail ? (
                  <a
                    href={validateURL(timeSheetRequest?.fileDetail)}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#2b4990', fontSize: '14px' }}
                  >
                    <u>{timeSheetRequest?.fileDetail}</u>
                  </a>
                ) : (
                  '-'
                )}
              </LabelNew>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">{t('เอกสารเพิ่มเติม')}</LabelNew>
            </Col>
            <Col {...responsiveData} style={{ color: colors.primary, display: 'flex' }}>
              {getFiles}
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">{t('ผู้อนุมัติ')}</LabelNew>
            </Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              <LabelNew type="body-primary">
                {displayText(timeSheetRequest.approverNameTH, timeSheetRequest.approverNameEN) || '-'}
              </LabelNew>
            </Col>
          </Row>
        </Col>
      </CardNew>
      <CardNew topic={t('รายการปฏิบัติงานรายวัน')} icon={'List'} toggledrop="false">
        <Row>
          <Col md={24}>
            <Datatable
              rowSelection={
                disabled
                  ? ''
                  : {
                      type: 'checkbox',
                      ...rowSelection,
                    }
              }
              columns={Columns}
              data={dataLists}
              handleTableChange={() => {}}
              searchCustom={true}
              paginationCustom={false}
              pagination={true}
            />
          </Col>
        </Row>

        <Row gutter={[12, 12]} style={{ marginTop: 20 }} align="center">
          <>
            {!disabled && (
              <>
                <Col>
                  <ButtonNew
                    type="allow"
                    roles={{ type: 'approve', menu: menu }}
                    onClick={() => {
                      getSelectData.length === 0 ? btnAlertNotSelect() : btnConfirmApprove()
                    }}
                  >
                    {t('อนุมัติ')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew
                    type="noAllow"
                    roles={{ type: 'approve', menu: menu }}
                    onClick={() => {
                      getSelectData.length === 0 ? btnAlertNotSelect() : btnConfirmNotApprove()
                    }}
                  >
                    {t('ไม่อนุมัติ')}
                  </ButtonNew>
                </Col>
              </>
            )}

            <Col>
              <ButtonNew
                type="back"
                onClick={() => {
                  navigate('/auditoperations/timesheetapprove')
                }}
              >
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          </>
        </Row>
      </CardNew>
    </>
  )
}

export default TimeSheetApproveForm
