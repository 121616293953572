import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Space /*, Table*/ } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { IconNew } from '../../../components/icon/Icon'
import { ButtonNew } from '../../../components/button/Button'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { LabelNew } from '../../../components/label/Label'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { displayText, displayError } from '../../../utilitys/helper'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { Alert } from '../../../components/alert/Alert'
import { GET_SETUP_REPORT_SUB_MISSIONS_BY_ID } from './graphql/Query'
import { CANCEL_SEND_SETUP_REPORT_SUBMISSIONS } from './graphql/Mutation'
const Responsive = {
  label: {
    sm: 4,
    md: 4,
    lg: 4,
  },
  value: {
    sm: 20,
    md: 20,
    lg: 20,
  },
  row: {
    sm: 24,
    md: 24,
    lg: 24,
  },
  marginRow: {
    marginBottom: '18pt',
  },
  textIndent: {
    textIndent: '3em',
  },
}
const SetupReportSubMissionsReportDetail = () => {
  // const { menu } = props
  const navigate = useNavigate()
  const propsType = useLocation()
  const { refCode, type_action } = propsType.state
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const formName = 'setup_report_sub_missions'

  const [dataDisplay, setDataDisplay] = useState({})
  const [isView, setIsView] = useState(false)
  const [cancelSendSetupReportSubMissionsFn, cancelSendSetupReportSubMissions] = useMutation(
    CANCEL_SEND_SETUP_REPORT_SUBMISSIONS,
  )

  const [getSetupReportSubMissionsByIdFn, getSetupReportSubMissionsById] = useLazyQuery(
    GET_SETUP_REPORT_SUB_MISSIONS_BY_ID,
  )

  /* แปลง token email ใน วงเล็บของแต่ละคน */
  const replaceMailToken = (text) => {
    if (text) {
      const regex = /\(([^()]*)\)/g
      const mailToken = text.match(regex).map(function ($0) {
        return $0.substring(1, $0.length - 1)
      })

      mailToken.forEach((emailToken) => {
        text = text.replaceAll(emailToken, decryptData(emailToken))
      })
      return text
    }
  }

  useEffect(() => {
    getSetupReportSubMissionsByIdFn({
      variables: {
        input: encryptInput({
          setMailReportId: refCode,
        }),
      },
    })
    type_action === 'view' && setIsView(true)
  }, [])

  useEffect(() => {
    if (cancelSendSetupReportSubMissions.data) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          navigate(`/reports/setupreportsubmissions`)
        },
      })
    }
    if (cancelSendSetupReportSubMissions.error) {
      const err = cancelSendSetupReportSubMissions.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [cancelSendSetupReportSubMissions.data])

  useEffect(() => {
    if (getSetupReportSubMissionsById.data) {
      const result = getSetupReportSubMissionsById.data?.getSetupReportSubMissionsById

      result['mailToTH'] = result.mailToTH && replaceMailToken(result.mailToTH)
      result['mailToEN'] = result.mailToEN && replaceMailToken(result.mailToEN)
      result['mailCopyTH'] = result.mailCopyTH && replaceMailToken(result.mailCopyTH)
      result['mailCopyEN'] = result.mailCopyEN && replaceMailToken(result.mailCopyEN)

      setDataDisplay(result)
    }
    if (getSetupReportSubMissionsById.error) {
      const err = getSetupReportSubMissionsById.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [getSetupReportSubMissionsById.data])
  // onFinish
  const onFinish = async () => {
    const upsert = {
      setMailReportId: dataDisplay.setMailReportId,
    }
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk: async () => {
        await cancelSendSetupReportSubMissionsFn({
          variables: {
            input: encryptInput(upsert),
          },
        })
      },
      onCancel() {
        return null
      },
    })
  }

  const breadcrumbList = [
    { label: t('รายงาน'), path: '/reports/' },
    { label: t('รายการรายงานตั้งเวลาการส่งอีเมล'), path: '/reports/setupreportsubmissions/' },
    { label: t('รายละเอียดการส่งอีเมล') },
  ]

  return (
    <>
      <Breadcrumb data={breadcrumbList} title={t('รายละเอียดการส่งอีเมล')} />
      <Form form={form} name={formName}>
        <CardNew topic={t('รายละเอียดการส่งอีเมล')} icon="Info" toggledrop={'false'}>
          <Row style={Responsive.marginRow}>
            <Col {...Responsive.label} style={Responsive.textIndent}>
              <LabelNew type="tab-header-inactive">{t('ชื่อรายงาน')}</LabelNew>
            </Col>
            <Col {...Responsive.value}>
              <LabelNew type="tab-header-active">
                {displayText(dataDisplay.menuNameTH, dataDisplay.menuNameEN)}
              </LabelNew>
            </Col>
          </Row>
          <Row style={Responsive.marginRow}>
            <Col {...Responsive.label} style={Responsive.textIndent}>
              <LabelNew type="tab-header-inactive">{t('ผู้รับ')}</LabelNew>
            </Col>
            <Col {...Responsive.value}>
              <LabelNew type="tab-header-active">{displayText(dataDisplay.mailToTH, dataDisplay.mailToEN)}</LabelNew>
            </Col>
          </Row>
          <Row style={Responsive.marginRow}>
            <Col {...Responsive.label} style={Responsive.textIndent}>
              <LabelNew type="tab-header-inactive">{t('สำเนาถึง')}</LabelNew>
            </Col>
            <Col {...Responsive.value}>
              <LabelNew type="tab-header-active">
                {displayText(dataDisplay.mailCopyTH, dataDisplay.mailCopyEN)}
              </LabelNew>
            </Col>
          </Row>
          <Row style={Responsive.marginRow}>
            <Col {...Responsive.label} style={Responsive.textIndent}>
              <LabelNew type="tab-header-inactive">{t('วันที่ส่ง')}</LabelNew>
            </Col>
            <Col {...Responsive.value}>
              <LabelNew type="tab-header-active">{dateDisplay(dataDisplay.sendDate)}</LabelNew>
            </Col>
          </Row>
          <Row style={Responsive.marginRow}>
            <Col {...Responsive.label} style={Responsive.textIndent}>
              <LabelNew type="tab-header-inactive">{t('เรื่อง')}</LabelNew>
            </Col>
            <Col {...Responsive.value}>
              <LabelNew type="tab-header-active">{dataDisplay.subject}</LabelNew>
            </Col>
          </Row>
          <Row style={Responsive.marginRow}>
            <Col {...Responsive.label} style={Responsive.textIndent}>
              <LabelNew type="tab-header-inactive">{t('รายละเอียด')}</LabelNew>
            </Col>
            <Col {...Responsive.value}>
              <LabelNew type="tab-header-active">{dataDisplay.detail}</LabelNew>
            </Col>
          </Row>
          <Row style={Responsive.marginRow}>
            <Col {...Responsive.label} style={Responsive.textIndent}>
              <LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>
            </Col>
            <Col {...Responsive.value}>
              <LabelNew type="tab-header-active">
                {dataDisplay.sendStatus === 0 ? t('ยังไม่ส่ง') : t('ส่งแล้ว')}
              </LabelNew>
            </Col>
          </Row>
          <Row style={Responsive.marginRow}>
            <Col {...Responsive.label} style={Responsive.textIndent}>
              <LabelNew type="tab-header-inactive">{t('เอกสารแนบ')}</LabelNew>
            </Col>
            <Col {...Responsive.value}>
              <LabelNew type="tab-header-active">
                <IconNew
                  style={{ cursor: 'pointer' }}
                  icon={'FileText'}
                  size={24}
                  color=""
                  onClick={() => window.open(dataDisplay.files)}
                />
              </LabelNew>
            </Col>
          </Row>
          <Row style={Responsive.marginRow}>
            <Col {...Responsive.row} align="center">
              <Space>
                {!isView && !dataDisplay.sendStatus && (
                  <ButtonNew
                    type="cancelSend"
                    // roles={{ type: 'add', menu: menu }}
                    onClick={() => {
                      onFinish()
                    }}
                  >
                    {t('ยกเลิกการส่ง')}
                  </ButtonNew>
                )}

                <ButtonNew
                  type="back"
                  // roles={{ type: 'add', menu: menu }}
                  onClick={() => {
                    navigate(`/reports/setupreportsubmissions/`)
                  }}
                >
                  {t('ย้อนกลับ')}
                </ButtonNew>
              </Space>
            </Col>
          </Row>
        </CardNew>
      </Form>
    </>
  )
}

export default SetupReportSubMissionsReportDetail
