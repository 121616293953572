import { Col, Row } from 'antd'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import { CardNew } from '../../../components/card/Card'
import AuditCloseExitIssueOrg from './tab/AuditCloseExitIssueOrg'
import AuditCloseExitIssueDetailForm from './tab/AuditCloseExitIssueDetailForm'
import { TabNew } from '../../../components/tab/Tab'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { displayError } from '../../../utilitys/helper'
import { encryptInput } from '../../../utilitys/crypto'
import { GET_AUDIT_ISSUE_BY_ID } from './graphql/Query'

const AuditCloseExitIssueForm = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const { state } = useLocation()

  const { action, issueId } = state

  const [issueOrg, setissueOrg] = useState([])
  const [isAuditIssue, setIsAuditIssue] = useState(null)
  const [activeTab, setactiveTab] = useState(1)

  const [getData, data] = useLazyQuery(GET_AUDIT_ISSUE_BY_ID)

  useEffect(() => {
    if (action !== 'add') {
      getData({ variables: { input: encryptInput(issueId) } })
    }
  }, [action, issueId])

  useEffect(() => {
    if (data.data) {
      setIsAuditIssue(data)
      setissueOrg(data?.data?.getAuditIssueById?.auditIssueOrganizations)
    }

    if (data?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(data?.error)),
        onOk() {},
      })
    }
  }, [data.data])

  return (
    <>
      {data.loading && <SpinnersNew />}
      <CardNew topic={t('เพิ่มบันทึกสรุปประเด็น')} icon="Plus" toggledrop="false">
        <Row>
          <Col md={24}>
            <TabNew
              activeKey={activeTab}
              onTabClick={(tab) => setactiveTab(tab)}
              tab={[
                {
                  key: 1,
                  name: t('รายละเอียดโครงการ'),
                  disabled: false,
                  content: () => (
                    <AuditCloseExitIssueDetailForm menu={menu} dataAuditIssue={isAuditIssue} newIssueOrg={issueOrg} />
                  ),
                },
                {
                  key: 2,
                  name: t('หน่วยรับตรวจ'),
                  disabled: action === 'add',
                  content: () => <AuditCloseExitIssueOrg dataIssueOrgs={issueOrg} menu={menu} />,
                },
              ]}
              defaultActiveKey={1}
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default AuditCloseExitIssueForm
