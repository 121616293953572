import { gql } from '@apollo/client'

export const GET_ACTION_PLAN_BY_ID = gql`
  query GetActionPlanByID($input: ActionPlanGetInput!) {
    getActionPlanByID(input: $input) {
      actionPlanId
      projectCode
      dateJust
      approver
      approveStatusCode
      isActive
    }
  }
`
