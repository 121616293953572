import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'antd'
import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Status } from '../../../components/status/Status'
import { useLanguage } from '../../../hooks/useLanguage'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { checkLanguageReturnData } from './components/fnForTemplateRisk'
import CreateModal from './components/modal/CreateModal'
import { RISKMODELSDELETE } from './graphql/Mutation'
import { GETRISKMODELSPAGINATION } from './graphql/Query'
import { columnList } from './utils/columnAllInRick'

export default function TemplateRiskList(props) {
  const Responsive = {
    md: 24,
    lg: 24
  }
  const navigate = useNavigate()
  const { menu } = props
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const breadcrumbList = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/templaterskprocess' },
    { label: t('คลังแบบประเมินความเสี่ยง') }
  ]

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  const [dataLists, setDataLists] = useState([])
  const [visible, setVisible] = useState(false)

  const [getRiskModelsPaginationFn, getRiskModelsPagination] = useLazyQuery(GETRISKMODELSPAGINATION, {})
  const [deleteRiskModelsFn, dataDeleteRiskModels] = useMutation(RISKMODELSDELETE, {})

  /* ---------------- delete on api ----------- */
  const riskModelsDelete = (rickCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteRiskModelsFn({
          variables: {
            input: encryptInput({
              riskModelCode: rickCode
            })
          }
        })
      },
      onCancel() {}
    })
  }

  useEffect(() => {
    if (dataDeleteRiskModels.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          getRiskModelsPagination.refetch()
        }
      })
    }

    if (dataDeleteRiskModels.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(dataDeleteRiskModels.error)),
        onOk() {}
      })
    }
  }, [dataDeleteRiskModels.data])

  useEffect(() => {
    getRiskModelsPaginationFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          filters: {},
          pagination: { limit, page },
          sort: sort
        }
      }
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (getRiskModelsPagination.data) {
      let response = getRiskModelsPagination?.data?.getRiskModelsPagination
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (dt, keys) => {
          return {
            key: keys,
            no: dt.RowData,
            riskModelCode: dt.riskModelCode + ' : ' + checkLanguageReturnData(isLang, dt.riskModelTH, dt.riskModelEN),
            isActive: <Status key={'status-apl-1' + keys} text={dt.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}></Status>,
            options: (
              <div key={'div-view-templaterick' + 1} style={{ textAlign: 'left' }}>
                <ButtonGroup
                  key={'btn-view-templaterick' + 1}
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => {
                        const pageType = 'view'
                        navigate('/administrator/templaterskprocess/form', {
                          state: {
                            pageType: pageType,
                            refCode: dt.riskModelCode
                          }
                        })
                      }
                    },
                    {
                      type: 'edit',
                      onClick: () => {
                        const pageType = 'edit'
                        navigate('/administrator/templaterskprocess/form', {
                          state: {
                            pageType: pageType,
                            refCode: dt.riskModelCode
                          }
                        })
                      }
                    },
                    {
                      type: 'delete',
                      onClick: () => {
                        riskModelsDelete(dt.riskModelCode)
                      }
                    }
                  ]}
                />
              </div>
            )
          }
        })
      )
    }
    if (getRiskModelsPagination.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getRiskModelsPagination.error)),
        onOk() {}
      })
    }
  }, [getRiskModelsPagination.data])

  return (
    <>
      {getRiskModelsPagination.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('คลังแบบประเมินความเสี่ยง')} />
      <Row gutter={[24, 24]}>
        <Col {...Responsive}>
          <CardNew topic={t('รายการคลังประเมินความเสี่ยง')} icon="AlignJustify" toggledrop={'false'}>
            <Row gutter={[16, 16]}>
              <Col {...Responsive}>
                <Datatable
                  columns={columnList}
                  data={dataLists}
                  total={count}
                  isScoll={{ x: true, y: true }}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  showSizeChanger={true}
                  searchCustom={true}
                  pageSize={limit}
                  page={page}
                  handleTableChange={(e) => setSort(handleSort(e?.sorter))}
                  btnAdd={
                    <>
                      <ButtonNew
                        type="plusTableBorderPrimary"
                        roles={{ type: 'add', menu: menu }}
                        onClick={() => setVisible(true)}
                      >
                        {t('เพิ่มกระบวนการ')}
                      </ButtonNew>
                    </>
                  }
                />
              </Col>
            </Row>
          </CardNew>
        </Col>
      </Row>
      <CreateModal
        open={visible}
        close={(e) => {
          setVisible(e)
        }}
        // refetch={() => dataCauseOfIssueList.refetch()}
        menu={menu}
      />
    </>
  )
}
