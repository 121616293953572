import { gql } from '@apollo/client'

export const GET_PERSONNELS = gql`
  query GetPersonnels($input: SelectTagEmailPersonnelsInput!) {
    getPersonnels(input: $input) {
      personnelCode
      email
    }
  }
`
