import { gql } from '@apollo/client'

export const SAVE_RATE_RISK_RULE = gql`
  mutation SaveRateRiskRule($input: RateRiskRuleSaveInput!) {
    saveRateRiskRule(input: $input) {
      rateRiskRuleCode
    }
  }
`

export const DELETE_RATE_RISK_RULE = gql`
  mutation DeleteRateRiskRule($input: RateRiskRuleDeleteInput!) {
    deleteRateRiskRule(input: $input) {
      rateRiskRuleCode
    }
  }
`
