/* eslint-disable no-unused-vars */
import React from 'react'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { ButtonNew } from '../../../components/button/Button'
import { useMutation, gql } from '@apollo/client'

const data = [{ label: 'งานควบคุมภายใน', path: '/internalcontrol/assess' }, { label: 'ประเมินการควบคุมภายใน' }]

function BreadcrumbIndex() {
  let code = ` import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
    
    const data = [{ 
            label: 'งานควบคุมภายใน', 
            path: '/internalcontrol/assess' 
        },{ 
            label: 'ประเมินการควบคุมภายใน' 
        }]

    export default function App() {
        return ( 
            <Breadcrumb data={data} title="ประเมินการควบคุมภายใน" />
        )
    }
`

  const NOTIG = gql`
    mutation TestSendNoti2 {
      testSendNoti2 {
        status
      }
    }
  `

  const [testNoti, { loading: loadingNoti, error: errorNoti, data: dataNoti }] = useMutation(NOTIG)
  console.log('dataNoti: ', dataNoti)
  const fncTestNoti = () => {
    //
    testNoti()
  }

  const NOTIG2 = gql`
    mutation TestSendNoti {
      testSendNoti {
        status
      }
    }
  `
  const [testNoti2, { loading: loadingNoti2, error: errorNoti2, data: dataNoti2 }] = useMutation(NOTIG2)
  console.log('dataNoti2: ', dataNoti2)
  const fncTestNoti2 = () => {
    //
    testNoti2()
  }

  return (
    <React.Fragment>
      <Breadcrumb data={data} title="ประเมินการควบคุมภายใน" />
      <div className="site-layout-background">
        <h3>Test Noti</h3>
        <ButtonNew type="primary" onClick={fncTestNoti}>
          TestNoti Timesheet
        </ButtonNew>
        <ButtonNew type="primary" onClick={fncTestNoti2}>
          TestNoti Test
        </ButtonNew>

        <br />
        <br />
        <CodeBlock code={code} />
      </div>
    </React.Fragment>
  )
}

export default BreadcrumbIndex
