import { gql } from '@apollo/client'

export const GET_MASTER_PROJECT_TYPES = gql`
  query getMasterProjectTypes($input: MasterProjectTypesInput!) {
    getMasterProjectTypes(data: $input) {
      projectTypeId
      projectTypeCode
      projectTypeNameTH
      projectTypeNameEN
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`
