import React, { useState } from 'react'
import { Form, Button, Col } from 'antd'
import _ from 'lodash'

import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { Select } from '../../../components/select/Select'
import { Checkbox } from '../../../components/checkbox/Checkbox'
import { InputNew } from '../../../components/input/Input'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'

import { example_select_data } from './utils/constants'
import { inputSelectCode } from './TableAddMultiRowCode'

const { useForm } = Form

let id = 3 // ต้องมีให้มากกว่าจำนวนใน data

const TableAddMultiRowIndex = (props) => {
  const [form] = useForm()
  const { menu } = props
  const [editable] = useState(true)

  // ต้องมีกัน error key

  const onFinish = (values) => {
    console.log('values: ', Object.values(values))
  }

  const handlePressAdd = () => {
    id++
    const fields = form.getFieldsValue()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    result.push({
      input: null,
      select: null,
      check_all: false,
      view: false,
      add: false,
      edit: false,
      delete: false,
    })
    form.setFieldsValue(result)

    let newData = [...data]
    newData.push({
      key: id,
      name: id,
      input: null,
      select: null,
      check_all: false,
      view: false,
      add: false,
      edit: false,
      delete: false,
    }) //ต้องเพิ่มตาม field
    setData(newData)
  }

  const onRemove = ({ index }) => {
    const fields = form.getFieldsValue()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    result[index] = {
      input: null,
      select: null,
      check_all: false,
      view: false,
      add: false,
      edit: false,
      delete: false,
    }
    let newFields = result.filter((item, key) => key !== index)

    form.setFieldsValue(newFields)
    let newData = data.filter((item, key) => key !== index)
    setData(newData)
  }

  //เมื่อกด check all ทั้งหมดแล้วเปลี่ยนแปลงปุ่มที่เหลือตาม
  const onCheckAllHeader = ({ e }) => {
    const fields = form.getFieldsValue()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    let newFields = result.map((item) => {
      return {
        ...item,
        check_all: e.target.checked,
        view: e.target.checked,
        add: e.target.checked,
        edit: e.target.checked,
        delete: e.target.checked,
      }
    })

    form.setFieldsValue(newFields)
  }

  //เมื่อกด check all ทั้งหมดแล้วเปลี่ยนแปลงปุ่มที่เหลือตาม
  const onCheckAll = ({ e, index }) => {
    const fields = form.getFieldsValue()
    fields[index] = {
      ...fields[index],
      view: e.target.checked,
      add: e.target.checked,
      edit: e.target.checked,
      delete: e.target.checked,
    }
    form.setFieldsValue(fields)
  }

  // เมื่อปุ่ม checkbox แต่ละแบบจะมาเปลี่ยนการแสดงผลของ check_all สำหรับ check_all
  const onChangeCheckbox = ({ e, index, type }) => {
    const fields = form.getFieldsValue()

    if (!_.isEmpty(fields[index])) {
      if (type === 'view' && e.target.checked && fields[index].add && fields[index].edit && fields[index].delete) {
        fields[index].check_all = true
      } else if (
        type === 'add' &&
        fields[index].view &&
        e.target.checked &&
        fields[index].edit &&
        fields[index].delete
      ) {
        fields[index].check_all = true
      } else if (
        type === 'edit' &&
        fields[index].view &&
        fields[index].add &&
        e.target.checked &&
        fields[index].delete
      ) {
        fields[index].check_all = true
      } else if (
        type === 'delete' &&
        fields[index].view &&
        fields[index].add &&
        fields[index].edit &&
        e.target.checked
      ) {
        fields[index].check_all = true
      } else {
        fields[index].check_all = false
      }
    }
    form.setFieldsValue(fields)
  }

  const columns = [
    {
      title: () => <div style={{ textAlign: 'center' }}>{'ลำดับ'}</div>,
      dataIndex: 'name',
      width: '5%',
      render: (item, record, index) => {
        return <div style={{ textAlign: 'center' }}>{index + 1}</div>
      },
    },
    {
      title: 'Input',
      dataIndex: 'input',
      width: '20%',
      render: (item, record, index) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <Form.Item
              name={[index, 'input']}
              initialValue={item}
              rules={[{ required: true, message: 'Missing first name' }]}
            >
              <InputNew placeholder="First Name" disabled={!editable} />
            </Form.Item>
          </div>
        )
      },
    },
    {
      title: 'Select',
      dataIndex: 'select',
      width: '20%',
      render: (item, record, index) => {
        return (
          <Form.Item
            name={[index, 'select']}
            initialValue={item}
            rules={[{ required: true, message: 'Missing Select' }]}
          >
            <Select
              data={example_select_data}
              placeholder={'Validate select...'}
              scrollableId="input_select"
              disabled={!editable}
            />
          </Form.Item>
        )
      },
    },
    {
      title: (
        <div>
          <Checkbox disabled={!editable} onChange={(e) => onCheckAllHeader({ e })}>
            Check All
          </Checkbox>
        </div>
      ),
      width: '8%',
      dataIndex: 'check_all',
      render: (item, record, index) => {
        return (
          <Form.Item
            name={[index, 'check_all']}
            valuePropName="checked" // ต้องมี
            initialValue={item}
          >
            <Checkbox disabled={!editable} onChange={(e) => onCheckAll({ e, index })}></Checkbox>
          </Form.Item>
        )
      },
    },
    {
      title: 'View',
      dataIndex: 'view',
      width: '8%',
      render: (item, record, index) => {
        return (
          <Form.Item
            name={[index, 'view']}
            valuePropName="checked" // ต้องมี
            initialValue={item}
          >
            <Checkbox disabled={!editable} onChange={(e) => onChangeCheckbox({ e, index, type: 'view' })} />
          </Form.Item>
        )
      },
    },
    {
      title: 'Add',
      dataIndex: 'add',
      width: '8%',
      render: (item, record, index) => {
        return (
          <Form.Item
            name={[index, 'add']}
            valuePropName="checked" // ต้องมี
            initialValue={item}
          >
            <Checkbox disabled={!editable} onChange={(e) => onChangeCheckbox({ e, index, type: 'add' })} />
          </Form.Item>
        )
      },
    },
    {
      title: 'Edit',
      dataIndex: 'edit',
      width: '8%',
      render: (item, record, index) => {
        return (
          <Form.Item
            name={[index, 'edit']}
            valuePropName="checked" // ต้องมี
            initialValue={item}
          >
            <Checkbox disabled={!editable} onChange={(e) => onChangeCheckbox({ e, index, type: 'edit' })} />
          </Form.Item>
        )
      },
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      width: '8%',
      render: (item, record, index) => {
        return (
          <Form.Item
            name={[index, 'delete']}
            valuePropName="checked" // ต้องมี
            initialValue={item}
          >
            <Checkbox disabled={!editable} onChange={(e) => onChangeCheckbox({ e, index, type: 'delete' })} />
          </Form.Item>
        )
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '5%',
      render: (item, record, index) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup
              menu={menu}
              disabled={!editable}
              icons={[
                // {
                //   type: 'clear',
                //   onClick: () => {
                //     onClear({ index })
                //   },
                // },
                {
                  type: 'delete',
                  onClick: () => {
                    onRemove({ index })
                  },
                },
              ]}
            />
          </div>
        )
      },
    },
  ]

  const [data, setData] = useState([
    {
      key: 0,
      name: 0,
      input: 'qwe',
      select: '01',
      check_all: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
    },
    {
      key: 1,
      name: 1,
      input: 'asd',
      select: '02',
      check_all: false,
      view: true,
      add: true,
      edit: false,
      delete: false,
    },
    {
      key: 2,
      name: 2,
      input: 'zxc',
      select: '03',
      check_all: false,
      view: false,
      add: false,
      edit: false,
      delete: false,
    },
  ])

  return (
    <div>
      <Form form={form} name="input_select" onFinish={onFinish} autoComplete="off">
        <Datatable enableScroll={{ x: true, y: false }} columns={columns} data={data} pagination={false} />
        <Form.Item>
          {editable && (
            <ButtonNew type="addMultiple" onClick={handlePressAdd}>
              เพิ่มข้อมูล
            </ButtonNew>
          )}
        </Form.Item>
        <Form.Item>
          <Button disabled={!editable} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <Col>
        <CodeBlock code={inputSelectCode} />
      </Col>
    </div>
  )
}

export default TableAddMultiRowIndex
