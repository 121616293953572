import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { ModalNew } from '../../../../components/modal/Modal'
import { useLanguage } from '../../../../hooks/useLanguage'
import CheckerOrganizationFilter from './filters/CheckerOrganizationFilter'
import { Datatable } from '../../../../components/datatable/Datatable'

const CheckerOrganizationModal = (props) => {
  const Responsive = { sm: 24, md: 24, lg: 24 }
  const { getData, close, open, orgActive } = props
  const [isLang] = useLanguage()
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [dataSelecteds, setDataSelecteds] = useState([])
  const [paginate] = useState({ page: 1, pageSize: 5 })
  const [total, settotal] = useState(0)
  const [keySelecteds, setkeySelecteds] = useState([])

  useEffect(() => {
    setVisible(open)
  }, [open])

  const onSubmit = () => {
    getData(dataSelecteds)
    setVisible(false)
    close(false)
  }

  const onClose = (e) => {
    setVisible(e)
    close(e)
  }

  const getDataListFromFilter = (value) => {
    settotal(value.length)
    setDataLists([
      ...value.map((item) => {
        return {
          // key: idx + 1,
          key: item.organizationCode,
          organizationId: item.organizationId,
          organizationCode: item.organizationCode,
          organizationName: isLang === 'th' ? item.organizationNameTH : item.organizationNameEN,
          organizationNameTH: item.organizationNameTH,
          organizationNameEN: item.organizationNameEN,
          organizationLevelName: isLang === 'th' ? item.organizationLevelNameTH : item.organizationLevelNameEN,
          personnelCode: item.personnelCode,
          personnelName: isLang === 'th' ? item.personnelNameTH : item.personnelNameEN,
          organizationLevelCode: item.organizationLevelCode,
          deleteId: item.organizationCode,
        }
      }),
    ])
  }

  const Columns = [
    { title: t('รหัสหน่วยงานรับตรวจ'), dataIndex: 'organizationCode', width: '20%' },
    { title: t('หน่วยงานรับตรวจ'), dataIndex: 'organizationName', width: '30%' },
    { title: t('ระดับหน่วยงานรับตรวจ'), dataIndex: 'organizationLevelName', width: '25%' },
    { title: t('ผู้บริหารหน่วยงานรับตรวจ'), dataIndex: 'personnelName', width: '25%' },
  ]

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setDataSelecteds(selectedRows), setkeySelecteds(selectedRowKeys)
    },
    getCheckboxProps: (record) => ({
      disabled: _.find(orgActive, (data) => data.organizationCode === record.organizationCode),
      name: record.name,
    }),
    selectedRowKeys: orgActive
      ? [...orgActive.map(({ organizationCode }) => organizationCode), ...keySelecteds]
      : [...keySelecteds],
  }

  return (
    <>
      <ModalNew
        visible={visible}
        onSubmit={() => onSubmit()}
        onClose={(e) => onClose(e)}
        testTitle={t('เพิ่มหน่วยรับตรวจ')}
        btnName={t('เพิ่ม')}
        type="medium"
      >
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            <CheckerOrganizationFilter getValue={(value) => getDataListFromFilter(value)} />
          </Col>
          <Col {...Responsive}>
            <Datatable
              data={dataLists}
              rowSelection={{ type: 'checkbox', ...rowSelection }}
              size="middle"
              total={total}
              columns={Columns}
              pagination={true}
              pageSize={paginate.pageSize}
              page={paginate.page}
              scroll={{ x: '100%', y: 350 }}
            />
          </Col>
        </Row>
      </ModalNew>
    </>
  )
}

export default CheckerOrganizationModal
