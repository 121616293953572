import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import * as _ from 'lodash'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { Status } from '../../../components/status/Status'
import { displayText, displayError } from '../../../utilitys/helper'
import { GET_RISK_FORMULAR_PAGINATION } from './graphql/Query'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Columns } from './utils/RiskFormulaColumns'
import { handleSort } from '../../../utilitys/pagination'
import { Alert } from '../../../components/alert/Alert'
import { DELETE_RISK_FORMULAR } from './graphql/Mutation'
import { encryptInput } from '../../../utilitys/crypto'

const RiskFormulaList = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const Responsive = {
    md: 24,
    lg: 24,
  }
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  /* ---------------- API ----------- */
  const [getRiskFormulaFn, dataRiskFormulaList] = useLazyQuery(GET_RISK_FORMULAR_PAGINATION)
  const [deleteRiskFormulaFn, datadDeleteRiskFormula] = useMutation(DELETE_RISK_FORMULAR, {})

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    getRiskFormulaFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          filters: {},
          pagination: { limit, page },
          sort: sort,
        },
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (dataRiskFormulaList?.data) {
      let response = dataRiskFormulaList?.data?.getRiskFormulaPagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data, index) => {
          return {
            ...data,
            key: index + 1,
            riskFormulaName: displayText(data.riskFormulaNameTH, data.riskFormulaNameEN),
            isActive: <Status text={data.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () =>
                        navigate('/administrator/riskformula/view', {
                          state: { type_action: 'view', riskFormulaCode: data.riskFormulaCode },
                        }),
                    },
                    {
                      type: 'edit',
                      onClick: () =>
                        navigate('/administrator/riskformula/edit', {
                          state: { type_action: 'edit', riskFormulaCode: data.riskFormulaCode },
                        }),
                    },
                    {
                      type: 'delete',
                      onClick: () => showConfirmDelete(data.riskFormulaCode),
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }

    if (dataRiskFormulaList.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(dataRiskFormulaList.error)),
        onOk() {},
      })
    }
  }, [dataRiskFormulaList?.data])

  useEffect(() => {
    if (datadDeleteRiskFormula.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          dataRiskFormulaList.refetch()
        },
      })
    }

    if (datadDeleteRiskFormula.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(datadDeleteRiskFormula.error)),
        onOk() {},
      })
    }
  }, [datadDeleteRiskFormula.data])

  /* ---------------- ACTION ----------- */
  const showConfirmDelete = (riskFormulaCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteRiskFormulaFn({
          variables: {
            riskFormulaCode: encryptInput(riskFormulaCode),
          },
        })
      },
      onCancel() {},
    })
  }

  return (
    <>
      {dataRiskFormulaList.loading && <SpinnersNew />}
      <Col {...Responsive}>
        <CardNew topic={t('รายการหน่วยงาน')} icon="AlignJustify" toggledrop={'false'}>
          <Row gutter={[12, 12]}>
            <Col {...Responsive}>
              <Datatable
                columns={Columns}
                data={[...dataLists]}
                total={count}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                paginationCustom={true}
                searchCustom={true}
                pageSize={limit}
                page={page}
                scroll={{ x: 1000, y: false }}
                handleTableChange={(e) => {
                  const lang = localStorage.getItem('lang') === 'th' ? true : false
                  const reNewField = [
                    { riskFormulaName: lang ? 'riskFormulaNameTH' : 'riskFormulaNameEN' },
                    { isActive: 'isActive' },
                  ]

                  setSort(handleSort(e?.sorter, reNewField))
                }}
                btnAdd={
                  <>
                    <ButtonNew
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: menu }}
                      onClick={() =>
                        navigate('/administrator/riskformula/create', {
                          state: { type_action: 'add' },
                        })
                      }
                    >
                      {t('เพิ่มสูตรคำนวนความเสี่ยง')}
                    </ButtonNew>
                  </>
                }
              />
            </Col>
          </Row>
        </CardNew>
      </Col>
    </>
  )
}
export default RiskFormulaList
