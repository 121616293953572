import { gql } from '@apollo/client'

export const GET_AUDITTEAM = gql`
  query getAuditTeams($input: AuditTeamsSelectInput!) {
    getAuditTeams(data: $input) {
      auditTeamId
      auditTeamCode
      auditTeamNameTH
      auditTeamNameEN
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`
