import { gql } from '@apollo/client'

export const FOLLOW_UP_MANAGER_SAVE = gql`
  mutation followUpManagerTabSave($input: FollowUpManagerTabSaveInput!) {
    followUpManagerTabSave(data: $input) {
      followUpId
    }
  }
`

export const FOLLOW_UP_ACTIVITY_SAVE = gql`
  mutation followUpActivityTabSave($input: FollowUpActivityTabSaveInput!) {
    followUpActivityTabSave(data: $input) {
      activityId
    }
  }
`

export const FOLLOW_UP_ACTIVITY_DELETE = gql`
  mutation followUpActivityDelete($input: FollowUpActivityDeleteInput!) {
    followUpActivityDelete(data: $input) {
      activityId
    }
  }
`

export const FOLLOW_UP_RESULT_FOLLOW_TAP_SAVE = gql`
  mutation followUpResultFollowTabFormSave($input: FollowUpResultFollowTabFormSaveInput!) {
    followUpResultFollowTabFormSave(data: $input) {
      actionId
    }
  }
`

export const FOLLOW_UP_CLOSE_ISSUE_TAP_SAVE = gql`
  mutation followUpCloseIssueTabSave($input: FollowUpCloseIssueTabFormSaveInput!) {
    followUpCloseIssueTabSave(data: $input) {
      activityId
    }
  }
`
