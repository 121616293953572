import * as _ from 'lodash'

export const handleSort = (data, fields) => {
  const setData = _.flattenDeep([data])

  if (!fields) fields = []

  if (setData.length) {
    return _.map(setData, (d) => {
      const newField = {}

      for (const f of fields) {
        if (_.head(_.keys(f)) === d.field) {
          newField.fieldSort = _.head(_.values(f))
          newField.sortType = d.order
        }
      }

      return {
        fieldSort: newField?.fieldSort ? newField?.fieldSort : d.field,
        sortType: newField?.sortType ? conditionSortType(newField?.sortType) : conditionSortType(d?.order),
      }
    })
  }
}

const conditionSortType = (value) => {
  switch (value) {
    case 'ascend':
      return 'ASC'
    case 'descend':
      return 'DESC'
    default:
      return 'ASC'
  }
}
