import { Trans } from 'react-i18next'
export const Columns = [
  {
    title: <Trans>ขั้นตอนการปฎิบัติงาน</Trans>,
    dataIndex: 'stepName',
    width: '20%',
  },
  {
    title: <Trans>วันที่เริ่มต้น</Trans>,
    dataIndex: 'dateStart',
    width: '15%',
  },
  {
    title: <Trans>วันที่สิ้นสุด</Trans>,
    dataIndex: 'dateEnd',
    width: '15%',
  },
  {
    title: <Trans>ผู้รับผิดชอบ</Trans>,
    dataIndex: 'responsible',
    width: '15%',
  },
  {
    title: <Trans>สถานะการดำเนินงาน</Trans>,
    dataIndex: 'operationStatus',
    width: '15%',
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActive',
    width: '10%',
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '10%',
  },
]
