import React, { useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Row, Col, Form, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { InputNew } from '../../../../../components/input/Input'
import { LabelNew } from '../../../../..//components/label/Label'
import { ButtonNew } from '../../../../../components/button/Button'
import { CardNew } from '../../../../../components/card/Card'
import SelectOrganizationLevel from '../../../../../components/inputfromapi/selectorganizationlevel/SelectOrganizationLevel'
import { encryptInput } from '../../../../../utilitys/crypto'
import { GET_MASTER_ORGANIZATIONS_FILTERS } from '../graphql/Query'
import { useLanguage } from '../../../../../hooks/useLanguage'

const CheckerOrganizationFilter = (props) => {
  const { getValue } = props
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const [form] = Form.useForm()
  const formName = 'filter'
  const Responsive = { md: 8, lg: 8, btn: { md: 24, lg: 24 } }
  const wrapper = { sm: 24, md: 24, lg: 24 }

  const [getMasterOrganizationsFiltersFn, getMasterOrganizationsFilters] = useLazyQuery(
    GET_MASTER_ORGANIZATIONS_FILTERS,
  )

  useEffect(() => {
    getMasterOrganizationsFiltersFn({
      variables: {
        input: encryptInput({
          organizationCode: '',
          organizationNameTH: '',
          organizationNameEN: '',
          organizationLevelCode: '',
          organizationChecked: 3,
        }),
      },
    })
  }, [])

  useEffect(() => {
    if (getMasterOrganizationsFilters?.data) {
      const res_ = getMasterOrganizationsFilters?.data?.getMasterOrganizationsFilters
      getValue(res_)
    }
  }, [getMasterOrganizationsFilters?.data])

  const onFinish = async (value) => {
    await getMasterOrganizationsFiltersFn({
      variables: {
        input: encryptInput({
          organizationCode: value?.organizationCode || '',
          organizationNameTH: isLang === 'th' ? value?.organizationName || '' : '',
          organizationNameEN: isLang === 'en' ? value?.organizationName || '' : '',
          organizationLevelCode: value?.organizationLevelCode || '',
          organizationChecked: 3,
        }),
      },
    })
  }

  return (
    <CardNew topic={t('Filters')} icon="List" toggledrop="false">
      <Col {...wrapper}>
        <Form form={form} onFinish={onFinish} name={formName} layout="vertical">
          <Row gutter={[12, 12]}>
            <Col {...Responsive}>
              <Form.Item
                name={'organizationCode'}
                label={<LabelNew type="tab-header-inactive">{t('รหัสหน่วยงานรับตรวจ')}</LabelNew>}
              >
                <InputNew placeholder={t('ระบุรหัสหน่วยรับตรวจ')} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'organizationName'}
                label={<LabelNew type="tab-header-inactive">{t('ชื่อหน่วยงานรับตรวจ')}</LabelNew>}
              >
                <InputNew placeholder={t('ระบุชื่อหน่วยงานรับตรวจ')} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'organizationLevelCode'}
                label={<LabelNew type="tab-header-inactive">{t('ระดับหน่วยงานรับตรวจ')}</LabelNew>}
              >
                <SelectOrganizationLevel
                  placeholder={t('เลือกระดับหน่วยงานรับตรวจ')}
                  formname={formName}
                  handleChange={(v) => form.setFieldsValue({ organizationLevelCode: v.value })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify="center">
            <Col {...Responsive.btn} align="center">
              <Space size={[8, 8]} wrap>
                <ButtonNew type="search" htmlType="submit">
                  {t('ค้นหา')}
                </ButtonNew>
                &nbsp;
                <ButtonNew
                  type="clear"
                  onClick={() => {
                    form.resetFields()
                    form.submit()
                  }}
                >
                  {t('ยกเลิก')}
                </ButtonNew>
              </Space>
            </Col>
          </Row>
        </Form>
      </Col>
    </CardNew>
  )
}

export default CheckerOrganizationFilter
