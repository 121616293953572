import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { ButtonNew } from '../../../../../components/button/Button'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { Columns } from '../../utils/AdhocPersonnelColumns'
import { Alert } from '../../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_ADHOCJOB_PERSONNELS_LIST } from '../../graphql/Query'
import { SAVE_ADHOCJOB_PERSONNEL } from '../../graphql/Mutation'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText, displayError } from '../../../../../utilitys/helper'

const AdhocjobResponsible = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()

  if (!state) {
    return <Navigate to={'/auditoperations/adhocjob'} />
  }

  const { type_action, refCode, personnelList, selectPersonnelList } = state
  const Responsive = {
    md: 24,
    lg: 24,
  }

  const [dataLists, setDataLists] = useState([])

  /* ---------------- API ----------- */
  const [getAdhocJobPersonnelByCodeFn, dataAdhocJobPersonnelByCode] = useLazyQuery(GET_ADHOCJOB_PERSONNELS_LIST)
  const [
    saveAdhocJobPersonnelFn,
    { loading: loadingAdhocJobPersonnel, error: errorAdhocJobPersonnel, data: saveAdhocJobPersonnel },
  ] = useMutation(SAVE_ADHOCJOB_PERSONNEL)

  /* ---------------- ACTION ----------- */
  const onFinish = () => {
    const personnelList = dataLists.map((item) => {
      return {
        personnelCode: item.personnelCode,
      }
    })

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        saveAdhocJobPersonnelFn({
          variables: {
            input: encryptInput({
              jobCode: refCode,
              adhocJobPersonnels: personnelList,
              action: 'create',
            }),
          },
        })
        // form.resetFields()
      },
      onCancel() {},
    })
  }

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (refCode) {
      getAdhocJobPersonnelByCodeFn({
        variables: {
          jobCode: encryptInput(refCode),
        },
      })
    }
  }, [refCode])

  useEffect(() => {
    if (dataAdhocJobPersonnelByCode.data) {
      const data = dataAdhocJobPersonnelByCode.data.getAdhocJobPersonnelByCode

      /*
        personnelList คือ รายชื่อบุคลากรที่ได้จาก API ครั้งแรก
        selectPersonnelList คือ รายชื่อบุคลากร ที่มาจากการเลือก
      */
      if (personnelList) {
        let dataMerge = [...personnelList, ...selectPersonnelList]
        dataMerge = _.uniqBy(dataMerge, (i) => i.personnelCode)
        setDataTable(dataMerge)
      } else {
        setDataTable(data)
      }
    }

    if (dataAdhocJobPersonnelByCode.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataAdhocJobPersonnelByCode.error)),
        onOk() {},
      })
    }
  }, [dataAdhocJobPersonnelByCode.data])

  const setDataTable = async (data) => {
    setDataLists(
      _.map(data, (item, index) => {
        return {
          key: index,
          personnelCode: item.personnelCode,
          personnelName: item.personnelName || displayText(item.personnelNameTH, item.personnelNameEN),
          organization: item.organization || displayText(item.organizationNameTH, item.organizationNameEN),
          position: item.position || displayText(item.positionNameTH, item.positionNameEN),
        }
      }),
    )
  }

  useEffect(() => {
    if (saveAdhocJobPersonnel) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          // navigate('/auditoperations/adhocjob')
        },
      })
    }
  }, [saveAdhocJobPersonnel])

  useEffect(() => {
    if (errorAdhocJobPersonnel) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorAdhocJobPersonnel)),
        onOk() {},
      })
    }
  }, [errorAdhocJobPersonnel])

  return (
    <>
      {loadingAdhocJobPersonnel && <SpinnersNew />}
      <Row justify="center">
        <Col {...Responsive}>
          <Row justify="center" style={{ marginBottom: '20px' }}>
            <Col {...Responsive}>
              <Datatable
                columns={Columns(dataLists, setDataLists, type_action, state)}
                data={dataLists}
                isScoll={{ x: true }}
                // paginationCustom={true}
                searchCustom={true}
                btnAdd={
                  type_action !== 'view' ? (
                    <>
                      <ButtonNew
                        type="plusTableBorderPrimary"
                        roles={{ type: type_action, menu: menu }}
                        onClick={() =>
                          navigate(`/auditoperations/adhocjob/tab/responsible/personnels`, {
                            state: {
                              ...state,
                              personnelList: dataLists,
                            },
                          })
                        }
                      >
                        {t('เพิ่มผู้รับผิดชอบ')}
                      </ButtonNew>
                    </>
                  ) : (
                    <></>
                  )
                }
              />
            </Col>
          </Row>
          <Row gutter={[12, 20]} justify="center">
            {type_action !== 'view' && (
              <Col>
                <ButtonNew
                  type="primary"
                  roles={{ type: type_action, menu: menu }}
                  htmlType="button"
                  onClick={() => onFinish()}
                >
                  {t('บันทึก')}
                </ButtonNew>
              </Col>
            )}
            <Col>
              <ButtonNew type="back" onClick={() => navigate('/auditoperations/adhocjob')}>
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default AdhocjobResponsible
