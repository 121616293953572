import React from 'react'
import { Trans } from 'react-i18next'
import { displayText } from '../../../../utilitys/helper'

export const Columns = () => {
  return [
    {
      title: <Trans>ขั้นตอนการปฎิบัติงาน</Trans>,
      dataIndex: 'stepName',
      sorter: false,
      width: '25%',
    },
    {
      title: <Trans>วันที่เริ้มต้น</Trans>,
      dataIndex: 'dateStart',
      sorter: false,
      width: '8%',
    },
    {
      title: <Trans>วันที่สิ้นสุด</Trans>,
      dataIndex: 'dateEnd',
      sorter: false,
      width: '8%',
    },
    {
      title: <Trans>หน่วยรับตรวจ</Trans>,
      dataIndex: 'organizations',
      sorter: false,
      width: '19%',

      render: (item, record) => {
        return record.organizations.map((val, idx) => {
          const name = displayText(val.organizationNameTH, val.organizationNameTH)
          return (
            <React.Fragment key={`${name}${idx}`}>
              {name}
              {idx < record.organizations.length && <br />}
            </React.Fragment>
          )
        })
      },
    },
    {
      title: <Trans>ผู้รับผิดชอบ</Trans>,
      dataIndex: 'personnels',
      sorter: false,
      width: '20%',
      render: (item, record) => {
        return record.personnels.map((val, idx) => {
          const name = displayText(val.personnelNameTH, val.personnelNameEN)
          return (
            <React.Fragment key={`${name}${idx}`}>
              {name}
              {idx < record.personnels.length && <br />}
            </React.Fragment>
          )
        })
      },
    },
    {
      title: <Trans>สถานะ</Trans>,
      dataIndex: 'operationStatusName',
      sorter: false,
      width: '15%',
    },
  ]
}
