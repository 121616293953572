import { gql } from '@apollo/client'
export const GET_MASTER_ISO = gql`
  query GetMasterISO($input: MasterISOInput!) {
    getMasterISO(input: $input) {
      ISOId
      ISOCode
      ISONameTH
      ISONameEN
      isActive
    }
  }
`
