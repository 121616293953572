import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useLazyQuery } from '@apollo/client'
import { Space, Form } from 'antd'
import { ModalNew } from '../../../components/modal/Modal'
import { InputNew } from '../../../components/input/Input'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { LabelNew as Label } from '../../../components/label/Label'
import { decryptData, encryptInput } from '../../../utilitys/crypto'
import { Alert } from '../../../components/alert/Alert'
import { regexEng, regexThai } from '../../../utilitys/helper'
import { GET_RISKLEVEL_BY_CODE } from './graphql/Query'
import { SAVE_RISKLEVEL } from './graphql/Mutation'
import SelectRiskLevelYear from '../../../components/inputfromapi/selectrisklevelyear/SelectRiskLevelYear'

const RiskLevelModal = (props) => {
  const { dataParams } = props
  const userId = decryptData(localStorage.getItem('user')).userId
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const disabled = dataParams?.type_action === 'view' ? true : false

  /* ---------------- API ----------- */
  const [saveRiskLevelFn, { loading: loadingSaveRiskLevel, error: errorSaveRiskLevel, data: saveRiskLevel }] =
    useMutation(SAVE_RISKLEVEL)

  const [getRiskLevelByCodeFn, dataRiskLevelByCode] = useLazyQuery(GET_RISKLEVEL_BY_CODE, {})

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    setVisible(props.open)
    if (dataParams) {
      getRiskLevelByCodeFn({
        variables: {
          riskLevelCode: dataParams.riskLevelCode,
        },
      })
    }
  }, [dataParams, props.open])

  useEffect(() => {
    if (dataParams && dataRiskLevelByCode.data) {
      const data = dataRiskLevelByCode.data.getMasterRiskLevelByCode
      form.setFieldsValue({
        riskLevelCode: data.riskLevelCode,
        riskLevelNameTH: data.riskLevelNameTH,
        riskLevelNameEN: data.riskLevelNameEN ? data.riskLevelNameEN : '',
        isActive: data.isActive,
        riskLevelYear: data.riskLevelYear,
      })
    }
  }, [dataRiskLevelByCode.data, dataParams?.riskLevelCode])

  useEffect(() => {
    if (saveRiskLevel) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          props.refetch()
        },
      })
    }
    if (errorSaveRiskLevel) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(errorSaveRiskLevel.message),
        onOk() {},
      })
    }
  }, [saveRiskLevel, errorSaveRiskLevel])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    showConfirm(input)
  }
  const showConfirm = (input) => {
    let dataInput = {
      ...input,
      riskLevelNameEN: input.riskLevelNameEN ? input.riskLevelNameEN : '',
      isActive: parseInt(input.isActive),
      userId: userId,
      action: dataParams?.riskLevelCode ? 'edit' : 'create',
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        saveRiskLevelFn({
          variables: {
            input: encryptInput(dataInput),
          },
        })
        form.resetFields()
        setVisible(false)
        props.close(false)
      },
      onCancel() {},
    })
  }

  function onClose(e) {
    form.resetFields()
    setVisible(e)
    props.close(e)
  }
  return (
    <>
      {loadingSaveRiskLevel && <SpinnersNew />}
      <ModalNew
        visible={visible}
        typeAction={dataParams?.type_action}
        testTitle={
          !dataParams
            ? t('เพิ่มระดับความเสี่ยง')
            : dataParams.type_action === 'edit'
            ? t('แก้ไขระดับความเสี่ยง')
            : t('ข้อมูลระดับความเสี่ยง')
        }
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        type="medium"
        okButtonProps={dataParams?.type_action === 'view' ? { style: { display: 'none' } } : {}}
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="riskLevelForm"
        >
          <Form.Item
            name="riskLevelCode"
            label={<Label type="tab-header-inactive">{t('รหัสระดับความเสี่ยง')}</Label>}
            rules={[
              { required: true, message: t('กรุณากรอกรหัสระดับความเสี่ยง') },
              {
                pattern: regexEng,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
              },
            ]}
          >
            <InputNew placeholder={t('รหัสระดับความเสี่ยง')} disabled={dataParams ? true : false} />
          </Form.Item>
          <Form.Item
            name="riskLevelNameTH"
            label={<Label type="tab-header-inactive">{t('ชื่อระดับความเสี่ยงภาษาไทย')}</Label>}
            rules={[
              { required: true, message: t('กรุณากรอกชื่อระดับความเสี่ยงภาษาไทย') },
              {
                pattern: regexThai,
                message: t(`กรุณากรอกด้วยภาษาไทยหรือตัวเลข ก-๙, 0-9`),
              },
            ]}
          >
            <InputNew placeholder={t('ชื่อระดับความเสี่ยงภาษาไทย')} disabled={disabled} />
          </Form.Item>
          <Form.Item
            name="riskLevelNameEN"
            label={<Label type="tab-header-inactive">{t('ชื่อระดับความเสี่ยงภาษาอังกฤษ')}</Label>}
            rules={[
              {
                pattern: regexEng,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
              },
            ]}
          >
            <InputNew placeholder={t('ชื่อระดับความเสี่ยงภาษาอังกฤษ')} disabled={disabled} />
          </Form.Item>
          <Form.Item
            name="riskLevelYear"
            label={<Label type="tab-header-inactive">{t('จำนวนปีที่ต้องตรวจสอบ')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกจำนวนปีที่ต้องตรวจสอบ') }]}
          >
            <SelectRiskLevelYear
              disabled={disabled}
              placeholder={t('เลือกจำนวนปีที่ต้องตรวจสอบ')}
              formname="riskLevelForm"
              handleChange={(e) => {
                form.setFieldsValue({ riskLevelYear: e?.value })
              }}
            />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
            <RadioGroup>
              <Space size={[8, 8]} wrap>
                <Radio value={1} disabled={disabled}>
                  {<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}
                </Radio>
                &nbsp;
                <Radio value={0} disabled={disabled}>
                  {<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}
                </Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
export default RiskLevelModal
