import React, { useState, useEffect } from 'react'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { InputNew } from '../../../components/input/Input'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
import { LabelNew as Label } from '../../../components/label/Label'
import { Select } from '../../../components/select/Select'
import { CardNew } from '../../../components/card/Card'
import { Row, Col, Space, Form } from 'antd'
import { ButtonNew as Button } from '../../../components/button/Button'
import { TabNew } from '../../../components/tab/Tab'
import OrganizationsData from './components/tab/OrganizationsData'
import RiskModelFactorDataType1 from './components/tab/RiskModelFactorDataType1'
import RiskModelFactorDataType2 from './components/tab/RiskModelFactorDataType2'
import { getSegmentUrl, displayError } from '../../../utilitys/helper'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GETRISKMODELS, GETRISKMODELORGANIZATIONPAGINATION, GETRISKMODELFACTOR } from './graphql/Query'
import { RISKMODELSSAVE } from './graphql/Mutation'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput } from '../../../utilitys/crypto'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'

export default function TemplateRiskForm(props) {
  const Responsive = {
    md: 24,
    lg: 24,
  }
  const { t } = useTranslation()
  const { state } = useLocation()
  const { menu } = props
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const params = getSegmentUrl(4)
  const formName = 'from-templaterick-form'

  if (!state) return <Navigate to={'/administrator/templaterskprocess'} />

  const breadcrumbList = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator' },
    { label: t('คลังแบบประเมินความเสี่ยง'), path: '/administrator/templaterskprocess' },
    { label: t('รายละเอียดการประเมิน') },
  ]
  const weights = [
    { value: 0, label: t('ไม่ถ่วงน้ำหนัก') },
    { value: 1, label: t('ถ่วงน้ำหนัก') },
  ]
  const haveWeights = [
    { value: 0, label: t('ไม่มี') },
    { value: 1, label: t('มี') },
  ]
  const haveGroup = [
    { value: 1, label: t('ประเมินองค์กร') },
    { value: 2, label: t('ประเมินกระบวนการ') },
  ]

  const [getRequired, setRequired] = useState(false)
  const [getVisitOztName, setVisitOztName] = useState(false)
  const [getRiskModelId, setRiskModelId] = useState()
  const [getOzt, setOzt] = useState([])
  const [getRskModel1, setRskModel1] = useState([])
  const [getRskModel2, setRskModel2] = useState([])
  const [getRskWeight, setRskWeight] = useState(0)

  const [getRiskModelsfn, getRiskModels] = useLazyQuery(GETRISKMODELS)
  const [riskModelSavefn, { loading: loadingSaved, error, data: dataSaved }] = useMutation(RISKMODELSSAVE)
  const [getRiskModelsOztfn, getRiskModelsOzt] = useLazyQuery(GETRISKMODELORGANIZATIONPAGINATION)
  const [getRiskModelFactorType1fn, getRiskModelFactorType1] = useLazyQuery(GETRISKMODELFACTOR)
  const [getRiskModelFactorType2fn, getRiskModelFactorType2] = useLazyQuery(GETRISKMODELFACTOR)

  const onFinish = async (input) => {
    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกคลังประเมินความเสี่ยง'),
      onOk: async () => {
        await riskModelSavefn({
          variables: {
            inputData: encryptInput({
              riskModels: {
                riskModelId: getRiskModelId,
                riskModelCode: input.riskModelCode,
                riskModelTH: input.riskModelTH,
                riskModelEN: input.riskModelEN,
                weight: input.weight,
                riskModelGroup: input.riskModelGroup,
                organizationCode: input.organizationCode,
                controlOrganization: input.controlOrganization,
                organizationName: input.organizationName,
                isActive: input.isActive,
                action: 'update',
              },
              riskModelOrganization: getOzt.map((dt) => {
                return {
                  organizationCode: dt.organizationCode,
                  organizationNameTH: dt.organizationNameTH,
                  organizationNameEN: dt.organizationNameEN,
                }
              }),
              riskModelFactors: getRskModel1.map((dt) => {
                return {
                  riskModelFacType: 1,
                  refCode: dt.riskFactorCode,
                  weight: dt.weight,
                  isActive: dt.isActive,
                }
              }),
              riskModelFactorType2: getRskModel2.map((dt) => {
                return {
                  riskModelFacType: 2,
                  refCode: dt.rateFacGroupCode,
                  isActive: dt.isActive,
                }
              }),
            }),
          },
          fetchPolicy: 'no-cache',
        })
      },
      onCancel() {
        return null
      },
    })
  }

  const alertErrorfn = () => {
    Alert({
      type: 'error',
      title: t('ลำดับซ้ำ'),
      onOk() {},
    })
  }

  useEffect(() => {
    getRiskModelsfn({
      variables: {
        inputData: encryptInput({
          riskModelCode: state.refCode,
        }),
      },
    })
    getRiskModelsOztfn({
      variables: {
        input: encryptInput({
          riskModelCode: state.refCode,
        }),
      },
    })
    getRiskModelFactorType1fn({
      variables: {
        inputData: encryptInput({
          riskModelCode: state.refCode,
          riskModelFacType: 1,
        }),
      },
    })
    getRiskModelFactorType2fn({
      variables: {
        inputData: encryptInput({
          riskModelCode: state.refCode,
          riskModelFacType: 2,
        }),
      },
    })
  }, [state.refCode])

  useEffect(() => {
    if (getRiskModelsOzt.data) {
      setOzt(getRiskModelsOzt.data.getRiskModelOrganizationPagination)
    }
    if (getRiskModelsOzt.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getRiskModelsOzt.error)),
        onOk() {},
      })
    }
  }, [getRiskModelsOzt.data])

  useEffect(() => {
    if (getRiskModelFactorType1.data) {
      setRskModel1(
        getRiskModelFactorType1.data.getRiskModelFactor.map((dt, key) => {
          return {
            isActive: dt.isActive,
            riskFactor: dt.riskFactor,
            riskFactorCode: dt.riskFactorCode,
            riskFactorNameEN: dt.riskFactorNameEN,
            riskFactorNameTH: dt.riskFactorNameTH,
            riskNo: dt.riskNo ? dt.riskNo : String(key + 1),
            weight: dt.weight,
          }
        }),
      )
    }
    if (getRiskModelFactorType1.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getRiskModelFactorType1.error)),
        onOk() {},
      })
    }
  }, [getRiskModelFactorType1.data])

  useEffect(() => {
    if (getRiskModelFactorType2.data) {
      setRskModel2(
        getRiskModelFactorType2.data.getRiskModelFactor.map((dt, key) => {
          return {
            isActive: dt.isActive,
            rateFacGroupCode: dt.rateFacGroupCode,
            rateFacGroupNameEN: dt.rateFacGroupNameEN,
            rateFacGroupNameTH: dt.rateFacGroupNameTH,
            riskFactorGroup: dt.riskFactorGroup,
            riskModelFacId: dt.riskModelFacId,
            riskNo: dt.riskNo ? dt.riskNo : String(key + 1),
          }
        }),
      )
    }
    if (getRiskModelFactorType2.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getRiskModelFactorType2.error)),
        onOk() {},
      })
    }
  }, [getRiskModelFactorType2.data])

  useEffect(() => {
    if (getRiskModels.data) {
      const res = getRiskModels.data.getRiskModels
      if (res.controlOrganization === 1) {
        setRequired(true)
        setVisitOztName(false)
      } else {
        setRequired(false)
        setVisitOztName(true)
      }
      setRiskModelId(res.riskModelId)
      setRskWeight(res.weight)
      form.setFieldsValue({
        riskModelCode: res.riskModelCode,
        riskModelTH: res.riskModelTH,
        riskModelEN: res.riskModelEN,
        weight: res.weight,
        organizationCode: res.organizationCode,
        riskModelGroup: res.riskModelGroup,
        controlOrganization: res.controlOrganization,
        organizationName: res.organizationName,
        isActive: res.isActive,
      })
    }
    if (getRiskModels.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getRiskModels.error)),
        onOk() {},
      })
    }
  }, [getRiskModels.data])

  useEffect(() => {
    if (dataSaved) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          getRiskModels.refetch()
          getRiskModelsOzt.refetch()
          getRiskModelFactorType1.refetch()
          getRiskModelFactorType2.refetch()
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [dataSaved])

  const tabs = [
    {
      key: 1,
      name: t('หน่วยรับตรวจ'),
      disabled: false,
      content: () => (
        <>
          {params === 'organizationsdata' || !params ? (
            <OrganizationsData
              menu={menu}
              obj={getOzt}
              getValue={(e) => {
                setOzt(e)
              }}
              oztDelete={(e) => {
                const riskIndex = getOzt.findIndex((x) => x.organizationCode === e)
                if (riskIndex !== -1) {
                  setOzt(getOzt.filter((x) => x.organizationCode !== e))
                }
              }}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      key: 2,
      name: t('ปัจจัยเสี่ยงองค์กร'),
      disabled: false,
      content: () => (
        <>
          {params === 'riskmodelfactordata1' ? (
            <RiskModelFactorDataType1
              menu={menu}
              obj={getRskModel1}
              getValue={(e) => {
                const checkDup = getRskModel1.find((x) => x.riskNo === e.riskNo)
                if (checkDup) {
                  alertErrorfn()
                } else {
                  setRskModel1([...getRskModel1, e])
                }
              }}
              setDelete={(no) => {
                setRskModel1(getRskModel1.filter((x) => x.riskNo !== no))
              }}
              rskWeight={getRskWeight}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      key: 3,
      name: t('เกณฑ์ประเมินความเสี่ยง'),
      disabled: false,
      content: () => (
        <>
          {params === 'riskmodelfactordata2' ? (
            <RiskModelFactorDataType2
              menu={menu}
              objType2={getRskModel2}
              getValue={(e) => {
                const checkDup = getRskModel2.find((x) => x.riskNo === e.riskNo)
                if (checkDup) {
                  alertErrorfn()
                } else {
                  setRskModel2([...getRskModel2, e])
                }
              }}
              setDelete={(no) => {
                setRskModel2(getRskModel2.filter((x) => x.riskNo !== no))
              }}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
  ]

  return (
    <>
      {getRiskModels.loading && <SpinnersNew />}
      {loadingSaved && <SpinnersNew />}
      {getRiskModelsOzt.loading && <SpinnersNew />}
      {getRiskModelFactorType1.loading && <SpinnersNew />}
      {getRiskModelFactorType2.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('คลังแบบประเมินความเสี่ยง')} />
      <Row gutter={[24, 24]}>
        <Col {...Responsive}>
          <Form
            form={form}
            onFinish={onFinish}
            labelAlign="left"
            labelCol={{
              span: 8,
              offset: 2,
            }}
            wrapperCol={{
              span: 10,
            }}
            layout="horizontal"
            name={formName}
          >
            <CardNew
              topic={t(state.pageType === 'edit' ? 'แก้ไขคลังแบบประเมินความเสี่ยง' : 'คลังแบบประเมินความเสี่ยง')}
              icon="AlignJustify"
              toggledrop={'false'}
            >
              <Form.Item name="riskModelCode" label={<Label type="tab-header-inactive">{t('รหัสกระบวนการ')}</Label>}>
                <InputNew placeholder={t('ระบุรหัสกระบวนการ')} disabled={true} />
              </Form.Item>
              <Form.Item
                name="riskModelTH"
                label={<Label type="tab-header-inactive">{t('ชื่อกระบวนการภาษาไทย')}</Label>}
                rules={[{ required: true, message: t('กรุณากรอกชื่อกระบวนการภาษาไทย') }]}
              >
                <InputNew
                  placeholder={t('ระบุชื่อกระบวนการภาษาไทย')}
                  disabled={state.pageType === 'edit' ? false : true}
                  maxLength={200}
                />
              </Form.Item>
              <Form.Item
                name="riskModelEN"
                label={<Label type="tab-header-inactive">{t('ชื่อกระบวนการภาษาอังกฤษ')}</Label>}
              >
                <InputNew
                  placeholder={t('ระบุชื่อกระบวนการภาษาอังกฤษ')}
                  disabled={state.pageType === 'edit' ? false : true}
                  maxLength={200}
                />
              </Form.Item>
              <Form.Item name="weight" label={<Label type="tab-header-inactive">{t('การถ่วงน้ำหนัก')}</Label>}>
                <Select
                  disabled={state.pageType === 'edit' ? false : true}
                  formname={formName}
                  data={weights}
                  placeholder={t('ระบุการถ่วงน้ำหนัก')}
                  scrollableId={formName}
                  showSearch
                  onChange={(e) => {
                    e === 1 ? setRskWeight(1) : setRskWeight(0)
                  }}
                />
              </Form.Item>
              <Form.Item
                name="riskModelGroup"
                label={<Label type="tab-header-inactive">{t('กระบวนการ')}</Label>}
                rules={[{ required: true, message: t('กรุณาเลือกกระบวนการ') }]}
              >
                <Select
                  disabled={state.pageType === 'edit' ? false : true}
                  formname={formName}
                  data={haveGroup}
                  placeholder={t('เลือกกระบวนการ')}
                  scrollableId={formName}
                  onChange={() => {}}
                  showSearch
                />
              </Form.Item>
              <Form.Item
                name="organizationCode"
                label={<Label type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</Label>}
                rules={[{ required: true, message: t('กรุณาเลือกหน่วยงานที่รับผิดชอบ') }]}
              >
                <SelectOrganization
                  formname={formName}
                  disabled={state.pageType === 'edit' ? false : true}
                  placeholder={t('เลือกหน่วยงานที่รับผิดชอบ')}
                  filters={{ organizationChecked: 2 }}
                  handleChange={(e) => {
                    form.setFieldsValue({ organizationCode: e?.value })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="controlOrganization"
                label={<Label type="tab-header-inactive">{t('มีหน่วยงานควบคุม')}</Label>}
              >
                <Select
                  disabled={state.pageType === 'edit' ? false : true}
                  formname={formName}
                  data={haveWeights}
                  placeholder={t('ระบุหน่วยงานควบคุม')}
                  scrollableId={formName}
                  onChange={(e) => {
                    if (e === 1) {
                      setRequired(true)
                      setVisitOztName(false)
                    } else {
                      setRequired(false)
                      setVisitOztName(true)
                      form.setFieldsValue({
                        organizationName: '',
                      })
                    }
                  }}
                  showSearch
                />
              </Form.Item>
              <Form.Item
                name="organizationName"
                label={<Label type="tab-header-inactive">{t('หน่วยงานควบคุม')}</Label>}
                rules={[{ required: getRequired, message: 'กรุณากรอกหน่วยงานควบคุม' }]}
              >
                <InputNew
                  placeholder={t('ระบุหน่วยงานควบคุม')}
                  disabled={state.pageType === 'edit' ? getVisitOztName : true}
                  maxLength={200}
                />
              </Form.Item>
              <Form.Item
                label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>}
                name="isActive"
                initialValue={1}
              >
                <RadioGroup disabled={state.pageType === 'edit' ? false : true}>
                  <Space size={[8, 8]} wrap>
                    <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                    &nbsp;
                    <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
                  </Space>
                </RadioGroup>
              </Form.Item>
            </CardNew>
          </Form>
        </Col>
      </Row>
      <CardNew topic={t('รายละเอียดคลังความเสี่ยง')} icon="List" toggledrop={'false'}>
        <TabNew
          tab={tabs}
          activeKey={
            params === 'organizationsdata'
              ? '1'
              : params === 'riskmodelfactordata1'
              ? '2'
              : params === 'riskmodelfactordata2'
              ? '3'
              : '1'
          }
          onTabClick={(key) => {
            if (key === '1') {
              navigate('/administrator/templaterskprocess/form/organizationsdata', {
                state: state,
              })
            } else if (key === '2') {
              navigate('/administrator/templaterskprocess/form/riskmodelfactordata1', {
                state: state,
              })
            } else if (key === '3') {
              navigate('/administrator/templaterskprocess/form/riskmodelfactordata2', {
                state: state,
              })
            }
          }}
        />
        {state.pageType === 'edit' ? (
          <Row gutter={[12, 12]} style={{ marginTop: 24, marginBottom: 24 }} align="center">
            <Col>
              <Button
                formname={formName}
                type="primary"
                htmlType="submit"
                roles={{ type: 'edit', menu: menu }}
                onClick={() => {
                  const field = form.getFieldValue()
                  onFinish(field)
                }}
              >
                {t('บันทึก')}
              </Button>
            </Col>
            <Col>
              <Button
                type="back"
                onClick={() => {
                  navigate('/administrator/templaterskprocess', {
                    state: state,
                  })
                }}
              >
                {t('ยกเลิก')}
              </Button>
            </Col>
          </Row>
        ) : (
          <Row gutter={[12, 12]} style={{ marginTop: 24, marginBottom: 24 }} align="center">
            <Col>
              <Button
                type="back"
                onClick={() => {
                  navigate('/administrator/templaterskprocess', {
                    state: state,
                  })
                }}
              >
                {t('ยกเลิก')}{' '}
              </Button>
            </Col>
          </Row>
        )}
      </CardNew>
    </>
  )
}
