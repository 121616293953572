import React from 'react'
import { LabelNew } from '../../../components/label/Label'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { Space, Row } from 'antd'

function LabelIndex() {
  let code = ` import { LabelNew } from '../../../components/label/Label'

    export default function App() {
        return <>
        <LabelNew type="header">หัวข้อหลัก</LabelNew>
            <LabelNew type="header-primary">หัวข้อหลักมีสี</LabelNew>
            <LabelNew type="sub-header">หัวข้อรอง</LabelNew>
            <LabelNew type="sub-header-primary">หัวข้อรองมีสี</LabelNew>
            <LabelNew type="tab-header-active">แท็บ active</LabelNew>
            <LabelNew type="tab-header-inactive">แท็บ inactive</LabelNew>
            <LabelNew type="body-header">หัวข้อเนื้อหาทั่วไป 16</LabelNew>
            <LabelNew type="body">เนื้อหาทั่วไป 14</LabelNew>
            <LabelNew type="body-primary">เนื้อหาทั่วไป 14</LabelNew>
            <LabelNew type="body-01">เนื้อหาทั่วไป 12</LabelNew>
            <LabelNew type="header-table">หัวข้อตาราง</LabelNew>
            <LabelNew type="body-header">รายละเอียด</LabelNew>
            <LabelNew type="notify-text">คำอธิบายแจ้งเตือน</LabelNew>
            <LabelNew type="header-table" need="need">
              required field
            </LabelNew>
            <LabelNew type="error">มีข้อผิดพลาดเกิดขึ้น</LabelNew>
            <LabelNew type="tile-card">รายการปัจจัยความเสี่ยงกระบวนการ</LabelNew>
        </>
    }
`
  return (
    <React.Fragment>
      <div className="site-layout-background">
        <Row>
          <Space direction="vertical">
            <LabelNew type="header">หัวข้อหลัก</LabelNew>
            <LabelNew type="header-primary">หัวข้อหลักมีสี</LabelNew>
            <LabelNew type="sub-header">หัวข้อรอง</LabelNew>
            <LabelNew type="sub-header-primary">หัวข้อรองมีสี</LabelNew>
            <LabelNew type="tab-header-active">แท็บ active</LabelNew>
            <LabelNew type="tab-header-inactive">แท็บ inactive</LabelNew>
            <LabelNew type="body-header">หัวข้อเนื้อหาทั่วไป 16</LabelNew>
            <LabelNew type="body">เนื้อหาทั่วไป 14</LabelNew>
            <LabelNew type="body-primary">เนื้อหาทั่วไป 14</LabelNew>
            <LabelNew type="body-01">เนื้อหาทั่วไป 12</LabelNew>
            <LabelNew type="header-table">หัวข้อตาราง</LabelNew>
            <LabelNew type="body-header">รายละเอียด</LabelNew>
            <LabelNew type="notify-text">คำอธิบายแจ้งเตือน</LabelNew>
            <LabelNew type="header-table" need="need">
              required field
            </LabelNew>
            <LabelNew type="error">มีข้อผิดพลาดเกิดขึ้น</LabelNew>
            <LabelNew type="tile-card">รายการปัจจัยความเสี่ยงกระบวนการ</LabelNew>
          </Space>
        </Row>
        <CodeBlock code={code} />
      </div>
    </React.Fragment>
  )
}

export default LabelIndex
