import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Form, Space } from 'antd'
import { ModalNew } from '../../../../components/modal/Modal'
import { InputNumberNew } from '../../../../components/input/Input'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { LabelNew as Label } from '../../../../components/label/Label'
import { encryptInput } from '../../../../utilitys/crypto'
import SelectRiskLevel from '../../../../components/inputfromapi/selectrisklevel/SelectRiskLevel.jsx'
import { Alert } from '../../../../components/alert/Alert'
import { GET_EMAIL_SEND_SETUP_BYID } from '../graphql/Query'
import { MASTER_SET_EMAIL_SAVE } from '../graphql/Mutation'
import { RadioGroup, Radio } from '../../../../components/radio/Radio'
import { displayError } from '../../../../utilitys/helper'

const TabOneModal = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { objData, setStatus } = props
  const [disabled, setDisabled] = useState(false)

  const [cellGet, { data: dataGet, loading: loadingGet, error: errorGet }] = useLazyQuery(GET_EMAIL_SEND_SETUP_BYID)

  const [cellSave, { data: dataSave, loading: loadingSave, error: errorSave }] = useMutation(MASTER_SET_EMAIL_SAVE)

  useEffect(() => {
    setDisabled(false)
    if (objData.type === 'edit' || objData.type === 'view') {
      const variables = encryptInput({ setMailId: objData.setMailId })
      cellGet({ variables })
    }
    if (objData.type === 'view') {
      setDisabled(true)
    }
  }, [objData])

  useEffect(() => {
    if (dataGet) {
      form.setFieldsValue({
        riskLevelCode: dataGet.emalSendSetUpById.riskLevelCode,
        dueDate: dataGet.emalSendSetUpById.dueDate,
        dueDateBefor: dataGet.emalSendSetUpById.dueDateBefor,
        isActive: dataGet.emalSendSetUpById.isActive,
      })
    }
    if (errorGet) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorGet)),
        onOk() {},
      })
    }
  }, [dataGet])

  useEffect(() => {
    if (dataSave) {
      if (dataSave.masterSetEmailSave.setMailId) {
        Alert({
          type: 'success',
          title: t('บันทึกข้อมูลสำเร็จ'),
          onOk() {
            setStatus(true)
            form.resetFields()
            props.close(false)
          },
        })
      }
    }
    if (errorSave) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถบันทึกข้อมูลได้`),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [dataSave])

  const onFinish = (input) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        showConfirm(input)
      },
      onCancel() {},
    })
  }

  const showConfirm = async (input) => {
    let data = {}
    if (objData.setMailId) data = { setMailId: objData.setMailId }
    let dataInput = {
      ...data,
      setMailType: 1,
      riskLevelCode: input.riskLevelCode,
      dueDate: parseInt(input.dueDate),
      dueDateBefor: parseInt(input.dueDateBefor),
      isActive: input.isActive,
      typeAction: objData.type,
    }

    await cellSave({
      variables: {
        input: encryptInput(dataInput),
      },
    })
  }

  function onClose(e) {
    form.resetFields()
    props.close(e)
  }
  return (
    <>
      {(loadingSave || loadingGet) && <SpinnersNew />}
      <ModalNew
        typeAction={objData.type}
        visible={props.open}
        testTitle={t('การเตือนตามระดับความเสี่ยง')}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="tabOneModal"
        >
          <Form.Item
            name="riskLevelCode"
            label={<Label type="tab-header-inactive">{t('ระดับความเสี่ยง')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกระดับความเสี่ยง') }]}
          >
            <SelectRiskLevel
              disabled={disabled}
              placeholder={t('ระบุระดับความเสี่ยง')}
              formname="tabOneModal"
              handleChange={(e) => {
                form.setFieldsValue({ riskLevelCode: e.value })
              }}
            />
          </Form.Item>
          <Form.Item
            name="dueDate"
            label={<Label type="tab-header-inactive">{t('กำหนดวันแจ้งเตือน (วัน)')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกกำหนดวันแจ้งเตือน (วัน)') }]}
          >
            <InputNumberNew disabled={disabled} placeholder={t('ระบุกำหนดวันแจ้งเตือน (วัน)')} />
          </Form.Item>
          <Form.Item
            name="dueDateBefor"
            label={<Label type="tab-header-inactive">{t('เตือนล่วงหน้า (วัน)')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกเตือนล่วงหน้า (วัน)') }]}
          >
            <InputNumberNew disabled={disabled} placeholder={t('ระบุเตือนล่วงหน้า (วัน)')} />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={0}>
            <RadioGroup disabled={disabled}>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>
                  <Label type="tab-header-inactive">{t('ใช้งาน')}</Label>
                </Radio>
                &nbsp;
                <Radio value={0}>
                  <Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>
                </Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
export default TabOneModal
