import { gql } from '@apollo/client'

export const GET_TIMESHEET_REPORT_PAGINATION = gql`
  query getTimeSheetPagination($input: TimeSheetPaginationInput!) {
    getTimeSheetPagination(input: $input) {
      result {
        no
        jobAndProjectNameTH
        jobAndProjectNameEN
        jobGroupCode
        jobGroupNameTH
        jobGroupNameEN
        timeSheetId
        jobTypeCode
        projectTypeCode
        typeNameTH
        typeNameEN
        jobDetailTH
        jobDetailEN
        dateStart
        timeStart
        dateEnd
        timeEnd
        jobStatusCode
        jobStatusNameTH
        jobStatusNameEN
      }
      count
      page
      limit
    }
  }
`

export const TIMESHEET_DETAIL_EXPORT = gql`
  query timeSheetDetailExport($input: TimeSheetReportInput!) {
    timeSheetDetailExport(input: $input) {
      message
      fileUrl
    }
  }
`

export const TIMESHEET_EXPORT = gql`
  query timeSheetListExport($input: TimeSheetReportInput!) {
    timeSheetListExport(input: $input) {
      message
      fileUrl
    }
  }
`
