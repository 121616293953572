const allCodeRadio = `import { Radio } from '../../../components/radio/Radio

const [value, setValue] = React.useState(1)

const onChange = (e) => {
    console.log('radio checked', e.target.value)
    setValue(e.target.value)
}

<Radio>Basic เบสิค</Radio>

<Radio disabled>Disabled</Radio>

// Group
<RadioGroup onChange={onChange} value={value}>
    <Radio value={1}>A</Radio>
    <Radio value={2}>B</Radio>
    <Radio value={3}>C</Radio>
    <Radio value={4}>D</Radio>
</RadioGroup>
`

export { allCodeRadio }
