import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_PERSONNEL_STATUS } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectPersonnelStatus = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { personnelStatusId, personnelStatusCode, personnelStatusNameTH, personnelStatusNameEN, isActive } =
    filters || {}

  const [isData, isSetData] = useState([])

  const [getMasterPersonnelStatusFn, getMasterPersonnelStatus] = useLazyQuery(GET_MASTER_PERSONNEL_STATUS)

  useEffect(() => {
    getMasterPersonnelStatusFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [personnelStatusId, personnelStatusCode, personnelStatusNameTH, personnelStatusNameEN, isActive])

  useEffect(() => {
    if (getMasterPersonnelStatus.data) {
      isSetData(
        _.map(getMasterPersonnelStatus?.data?.getMasterPersonnelStatus, (item) => {
          return {
            label: displayText(item?.personnelStatusNameTH, item?.personnelStatusNameEN),
            value: item?.personnelStatusCode,
            source: item,
          }
        }),
      )
    }
  }, [getMasterPersonnelStatus.data])
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterPersonnelStatus.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectPersonnelStatus
