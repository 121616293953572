import React from 'react'
import { Row, Col, Form, Collapse } from 'antd'
import { CodeBlock as CodeBlockOld } from 'react-code-blocks'
import { CardNew } from '../../../components/card/Card.jsx'
import { useTranslation } from 'react-i18next'
import SelectAuditProjects from '../../../components/inputfromapi/selectauditprojects/SelectAuditProjects'

const SelectAuditProjectShow = () => {
  const { t } = useTranslation()

  return (
    <Row>
      <Col md={24}>
        <CardNew topic={t('Filters')} icon="AlignJustify" toggledrop="false">
          <Row>
            <Col md={24}>
              <Form
                autoComplete="off"
                name="SelectAuditProjects"
                labelAlign="left"
                labelCol={{ sm: { span: 24 }, md: { span: 8 } }}
                wrapperCol={{ sm: { span: 24 }, md: { span: 16 } }}
              >
                <Form.Item name="projectCode" label={t('โครงการ')}>
                  <SelectAuditProjects
                    formname="SelectAuditProjects"
                    placeholder={t('เลือก')}
                    handleChange={() => {}}
                  />
                </Form.Item>
                <br />
                <Collapse>
                  <Collapse.Panel header="Example Code" key="1">
                    <CodeBlockOld
                      text={`
                        <Form.Item name="projectCode" label={t('โครงการ')}>
                            <SelectAuditProjects
                                formname="SelectAuditProjects"
                                placeholder={t('เลือก')}
                                handleChange={() => {}}
                            />
                        </Form.Item>
                      `}
                      language={'jsx'}
                      showLineNumbers={false}
                      theme={'a11yLight'}
                    />
                  </Collapse.Panel>
                </Collapse>
              </Form>
            </Col>
          </Row>
        </CardNew>
      </Col>
    </Row>
  )
}

export default SelectAuditProjectShow
