import React from 'react'
import { Row, Col, Form } from 'antd'
import { CardNew as Card } from '../../../components/card/Card'
import { useTranslation } from 'react-i18next'
import { LabelNew as Label } from '../../../components/label/Label'
import { InputNew as Input } from '../../../components/input/Input'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import { ButtonNew } from '../../../components/button/Button'

const FilterAuditOpens = (props) => {
  const { t } = useTranslation()
  const onFinish = () => {}
  function setErrorField(name, errors) {
    // Set field errors
    return props.formFilter.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }
  const validDate = () => {
    let valid = false
    if (props.formFilter.getFieldValue('dateStart') && props.formFilter.getFieldValue('dateEnd')) {
      let dateStart = new Date(props.formFilter.getFieldValue('dateStart'))
      let dateEnd = new Date(props.formFilter.getFieldValue('dateEnd'))
      if (dateStart <= dateEnd) {
        setErrorField('dateStart', false)
        setErrorField('dateEnd', false)
        valid = true
      } else {
        setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นน้อยกว่าวันที่สิ้นสุด'))
        setErrorField('dateEnd', t('กรุณาเลือกวันที่สิ้นสุดมากกว่าวันที่เริ่มต้น'))
      }
    } else {
      setErrorField('dateStart', false)
      setErrorField('dateEnd', false)
      valid = true
    }
    return valid
  }
  return (
    <>
      <Card topic={t('Filters')} icon="AlignJustify" toggledrop="false">
        <Row>
          <Col md={24}>
            <Form
              form={props.formFilter}
              onFinish={onFinish}
              autoComplete="off"
              name="filterAuditOpens"
              layout="vertical"
            >
              <Row gutter={[12, 12]}>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="projectCode"
                    label={<Label type="tab-header-inactive">{t('รหัสหรือชื่อโครงการ')}</Label>}
                  >
                    <Input placeholder={t('ระบุรหัสหรือชื่อโครงการ')} />
                  </Form.Item>
                </Col>

                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="dateStart"
                    labelAlign="left"
                    label={<Label type="tab-header-inactive">{t('วันที่เริ่มต้น')}</Label>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        props.formFilter.setFieldsValue({
                          dateStart: await data,
                        })
                        validDate()
                      }}
                      onClear={() => {
                        props.formFilter.setFieldsValue({ dateStart: null })
                      }}
                      placeholder={t('เลือกวันที่เริ่มต้น')}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="dateEnd"
                    labelAlign="left"
                    label={<Label type="tab-header-inactive">{t('วันที่สิ้นสุด')}</Label>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        props.formFilter.setFieldsValue({
                          dateEnd: await data,
                        })
                        validDate()
                      }}
                      onClear={() => {
                        props.formFilter.setFieldsValue({ dateEnd: null })
                      }}
                      placeholder={t('เลือกวันที่สิ้นสุด')}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="organizationCode"
                    label={<Label type="tab-header-inactive">{t('หน่วยรับตรวจ')}</Label>}
                  >
                    <SelectOrganization
                      multiple={true}
                      filters={{ checkedList: JSON.stringify([3]), isActive: 1 }}
                      formname="filterAuditOpens"
                      placeholder={t('เลือกหน่วยรับตรวจ')}
                      handleChange={(value) => {
                        props.formFilter.setFieldsValue({ organizationCode: value })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew
                    type="search"
                    onClick={() => {
                      const valid = validDate()
                      if (
                        valid &&
                        props.formFilter.getFieldsError().filter((item) => {
                          return item.errors.length !== 0
                        }).length === 0
                      ) {
                        props.setSearchStatus(props.searchStatus + 1)
                      }
                    }}
                    roles={{ type: 'view', menu: props.menu }}
                    htmlType="submit"
                  >
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew
                    type="clear"
                    onClick={() => {
                      props.formFilter.resetFields(), props.setSearchStatus(props.searchStatus + 1)
                    }}
                  >
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default FilterAuditOpens
