import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'key',
    // sorter: true,
    width: '5%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>หน่วยงาน</Trans>,
    dataIndex: 'organizationName',
    width: '20%',
    className: 'text-nowrap',
    sorter: { multiple: 1 },
  },
  {
    title: <Trans>ประเภท</Trans>,
    dataIndex: 'organizationType',
    width: '10%',
    className: 'text-nowrap',
    sorter: { multiple: 2 },
  },
  {
    title: <Trans>ระดับ</Trans>,
    dataIndex: 'organizationLevelName',
    width: '15%',
    sorter: { multiple: 3 },
  },
  {
    title: <Trans>สังกัดหน่วยงาน</Trans>,
    dataIndex: 'organizationParentName',
    width: '20%',
    sorter: { multiple: 4 },
  },
  {
    title: <Trans>ขนาด</Trans>,
    dataIndex: 'organizationSizeName',
    width: '10%',
    sorter: { multiple: 5 },
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActive',
    width: '10%',
    className: 'text-nowrap',
    sorter: { multiple: 6 },
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '10%',
    align: 'right',
  },
]
