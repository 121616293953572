import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Col, Form, Row } from 'antd'

import { ModalNew } from '../../../components/modal/Modal'
import { InputNew } from '../../../components/input/Input'
import { Radio, RadioGroup } from '../../../components/radio/Radio'
import SelectRiskTypes from '../../../components/inputfromapi/selectrisktypes/SelectRiskTypes'
import SelectRiskFormulas from '../../../components/inputfromapi/selectriskformulars/SelectRiskFormulas'
import { Alert } from '../../../components/alert/Alert'

import { displayError, displayText, regexEng, regexEngNoSpace } from '../../../utilitys/helper'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { SAVE_RISK_FACTOR } from './graphql/Mutation'
import { encryptInput } from '../../../utilitys/crypto'
import SelectRiskGroups from '../../../components/inputfromapi/selectriskGroups/SelectRiskGroups'

const RiskFactorsForm = ({
  visible = false,
  type_action = 'view',
  dataForm = null,
  onSuccess = () => {},
  onClose = () => {},
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [editable, seteditable] = useState(false)

  useEffect(() => {
    if (type_action) {
      seteditable(type_action !== 'view')
    }
  }, [type_action])

  useEffect(() => {
    if (dataForm) {
      const data = {
        riskFactorCode: dataForm.riskFactorCode,
        riskFactorNameTH: dataForm.riskFactorNameTH,
        riskFactorNameEN: dataForm.riskFactorNameEN,
        riskType: dataForm.riskType,
        riskGroup: dataForm.riskGroup,
        riskFormulaCode: dataForm.riskFormulaCode,
        isActive: dataForm.isActive,
      }

      form.setFieldsValue(data)
    }
  }, [dataForm])

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_RISK_FACTOR)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          onSuccess()
        },
      })
    }
  }, [dataSave])

  const onFinish = () => {
    const fields = form.getFieldValue()
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        const dataInput = {
          riskFactorCode: fields.riskFactorCode,
          riskType: fields.riskType,
          riskFormulaCode: fields.riskFormulaCode,
          riskGroup: fields.riskGroup ? fields.riskGroup : null,
          isActive: fields.isActive,
          typeAction: type_action,
        }

        if (type_action === 'add') {
          dataInput.riskFactorNameTH = fields.riskFactorNameTH
          dataInput.riskFactorNameEN = fields.riskFactorNameEN ? fields.riskFactorNameEN : fields.riskFactorNameTH
          dataInput.typeAction = 'add'
        }

        if (type_action === 'edit') {
          dataInput.riskFactorNameTH = fields.riskFactorNameTH
          dataInput.riskFactorNameEN = fields.riskFactorNameEN
          dataInput.typeAction = 'edit'
        }

        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const footerHide = type_action === 'view' ? { okButtonProps: { style: { display: 'none' } } } : {}

  const renderTitle = () => {
    if (type_action === 'add') {
      return 'เพิ่มปัจจัยความเสี่ยง'
    }
    if (type_action === 'view') {
      return 'ดูปัจจัยความเสี่ยง'
    }
    if (type_action === 'edit') {
      return 'แก้ไขปัจจัยความเสี่ยง'
    }

    return ''
  }

  return (
    <React.Fragment>
      <ModalNew
        visible={visible}
        onSubmit={form.submit}
        onClose={(data) => {
          onClose(data)
          form.resetFields()
        }}
        testTitle={t(renderTitle())}
        typeAction={type_action}
        type="medium"
        {...footerHide}
      >
        {loadingSave && <SpinnersNew />}
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 8,
            lg: 8,
          }}
          layout="horizontal"
          name="riskFactorForm"
        >
          <Form.Item
            name="riskFactorCode"
            label={t('รหัสปัจจัยความเสี่ยง')}
            rules={[
              { required: true, message: t('กรุณาระบุ') },
              {
                pattern: regexEngNoSpace,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษพิมพ์ใหญ่,ตัวเลข A-Z, 0-9 ไม่เกิน 10 ต้ว`),
              },
            ]}
          >
            <InputNew
              maxLength={10}
              disabled={type_action === 'edit' || !editable}
              placeholder={t('กรอกรหัสปัจจัยความเสี่ยง')}
            />
          </Form.Item>

          <Form.Item
            name="riskFactorNameTH"
            label={t('ชื่อปัจจัยความเสี่ยงภาษาไทย')}
            rules={[{ required: true, message: t('กรุณาระบุ') }]}
          >
            <InputNew maxLength={200} disabled={!editable} placeholder={t('กรอกชื่อปัจจัยความเสี่ยงภาษาไทย')} />
          </Form.Item>
          <Form.Item
            name="riskFactorNameEN"
            label={t('ชื่อปัจจัยความเสี่ยงภาษาอังกฤษ')}
            rules={[{ pattern: regexEng, message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`) }]}
          >
            <InputNew maxLength={200} disabled={!editable} placeholder={t('กรอกชื่อปัจจัยความเสี่ยงภาษาอังกฤษ')} />
          </Form.Item>
          <Form.Item
            name="riskType"
            label={t('ประเภทความเสี่ยง')}
            rules={[{ required: true, message: t('กรุณาระบุ') }]}
          >
            <SelectRiskTypes
              disabled={!editable}
              formname="riskFactorForm"
              handleChange={(data) => {
                const value = data ? data.value : null
                form.setFieldsValue({ riskType: value })
              }}
            />
          </Form.Item>
          <Form.Item name="riskGroup" label={t('กลุ่มของความเสี่ยง')}>
            <SelectRiskGroups
              disabled={!editable}
              formname="riskFactorForm"
              handleChange={(data) => {
                const value = data ? data.value : null
                form.setFieldsValue({ riskGroup: value })
              }}
            />
          </Form.Item>
          <Form.Item
            name="riskFormulaCode"
            label={t('สูตรประเมินความเสี่ยง')}
            rules={[{ required: true, message: t('กรุณาระบุ') }]}
          >
            <SelectRiskFormulas
              disabled={!editable}
              formname="riskFactorForm"
              handleChange={(data) => {
                const name = data ? displayText(data.source?.riskFormulaNameTH, data.source?.riskFormulaNameEN) : null
                const value = data ? data.source.riskFormulaCode : null
                form.setFieldsValue({ riskFormulaName: name, riskFormulaCode: value })
              }}
            />
          </Form.Item>

          <Form.Item name="isActive" label={t('สถานะ')} initialValue={1}>
            <RadioGroup disabled={!editable}>
              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                </Col>
                <Col>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </React.Fragment>
  )
}
export default RiskFactorsForm
