import { gql } from '@apollo/client'
export const GET_SELECT_RATE_RISK_FACTOR_PROCESS = gql`
  query GetSelectRateRiskFactorProcess($input: SelectRateRiskFactorProcessInput!) {
    getSelectRateRiskFactorProcess(input: $input) {
      rateFacProCode
      rateFacProNameTH
      rateFacProNameEN
    }
  }
`
