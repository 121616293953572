import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Col, Table, Row } from 'antd'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { SearchTable } from '../../../../../components/searchtable/SearchTable'
import { IconNew } from '../../../../../components/icon/Icon'
import { ButtonNew } from '../../../../../components/button/Button'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { Alert } from '../../../../../components/alert/Alert'
import { Status } from '../../../../../components/status/Status'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { GET_AUDIT_RESULTS_APPROVE, GET_AUDIT_RESULT_DETAIL_APPROVE } from '../../graphql/Query'
import { UPDATE_AUDIT_RCMS_RESULT_APPROVE } from '../../graphql/Mutation'
import { displayText, displayError } from '../../../../../utilitys/helper'
import { handleSort } from '../../../../../utilitys/pagination'
import { encryptInput } from '../../../../../utilitys/crypto'
import { RiskTable } from '../../css/RiskTabStyle'
import ConfirmNotApproveAlert from '../alert/ConfirmNotApproveAlert'

const RiskTab = (props) => {
  const wrapper = { md: 24, lg: 24, xl: 24 }

  const { menu } = props

  const navigate = useNavigate()
  const { state } = useLocation()
  const { t } = useTranslation()

  if (!state && state?.rcmId) return <Navigate to={'/auditoperations/racmatrixapprove'} />

  const [sort, setSort] = useState([])
  const [columns, setColumns] = useState([])
  const [dataLists, setDataLists] = useState([])
  const [isDataSources, setIsDataSources] = useState([])
  const [isRcmResultId, setIsRcmResultId] = useState(null)
  const [isCheckedRcmResultId, setIsCheckedRcmResultId] = useState([])
  const [dataListDetail, setDataListDetail] = useState([])
  const [disabled, setDisabled] = useState(false)

  const [getAuditResultsApproveFn, getAuditResultsApprove] = useLazyQuery(GET_AUDIT_RESULTS_APPROVE)
  const [getAuditResultDetailApproveFn, getAuditResultDetailApprove] = useLazyQuery(GET_AUDIT_RESULT_DETAIL_APPROVE)
  const [updateAuditRCMResultApproveFn, updateAuditRCMResultApprove] = useMutation(UPDATE_AUDIT_RCMS_RESULT_APPROVE)

  const calculateScore = (score) => {
    if (score === 1) return 'L'
    if (score === 2) return 'ML'
    if (score === 3) return 'M'
    if (score === 4) return 'MH'
    if (score === 5) return 'H'
    if (score === '-') return score
  }

  const calculateRiskScore = (score) => {
    if (score >= 1 && score <= 5) return 'L'
    if (score >= 6 && score <= 10) return 'ML'
    if (score >= 11 && score <= 15) return 'M'
    if (score >= 16 && score <= 20) return 'MH'
    if (score >= 21) return 'H'
    if (score === '-') return score
  }

  const rowSelection = {
    selectedRowKeys: isCheckedRcmResultId,
    onChange: (selectedRowKeys) => {
      if (selectedRowKeys) setIsCheckedRcmResultId([...selectedRowKeys])
    },
    getCheckboxProps: (record) => {
      return { disabled: !record?.mainRecord || disabled || record?.riskScore === '-' }
    },
  }

  useEffect(() => {
    setDisabled(state.typeAction === 'view' ? true : false)
  }, [state?.typeAction])

  useEffect(() => {
    if (isRcmResultId) {
      getAuditResultDetailApproveFn({ variables: { input: encryptInput({ rcmResultId: isRcmResultId }) } })
    }
  }, [isRcmResultId])

  useEffect(() => {
    getAuditResultsApproveFn({
      variables: {
        input: encryptInput({
          filters: { rcmId: state?.rcmId },
          pagination: { limit: 10, page: 1 },
          sort: sort,
        }),
      },
    })
  }, [state?.rcmId, sort])

  useEffect(() => {
    if (getAuditResultsApprove?.data) {
      const results = getAuditResultsApprove?.data?.getAuditResultsApprove

      const ansResults = _.map(_.sortBy(results, 'sort'), (d, i) => {
        const personnelRCMName = displayText(d?.personnelRCMNameTH, d?.personnelRCMNameEN)

        return {
          ...d,
          key: d.rcmResultId,
          no: i + 1,
          rateFacProName: displayText(d.rateFacProNameTH, d.rateFacProNameEN),
          rateStatus: d.rateStatus ? 1 : 0,
          likelihood: d.likelihood
            ? d.likelihood
            : d.likelihood === null || d.likelihood === undefined
            ? '-'
            : d.likelihood,
          impact: d.impact ? d.impact : d.impact === null || d.impact === undefined ? '-' : d.impact,
          riskScore: d.riskScore ? d.riskScore : d.riskScore === null || d.riskScore === undefined ? '-' : d.riskScore,
          personnelName: personnelRCMName ? personnelRCMName : '-',
          shortCode: d.shortCode,
          approveStatusCode: d.approveStatusCode,
          mainRecord: true,
          children: [],
        }
      })
      setDataLists(ansResults)
      setIsDataSources([...ansResults])
    }

    if (getAuditResultsApprove?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditResultsApprove?.error)),
        onOk() {},
      })
    }
  }, [getAuditResultsApprove?.data])

  useEffect(() => {
    if (getAuditResultDetailApprove?.data) {
      const results = getAuditResultDetailApprove?.data?.getAuditResultDetailApprove
      const ansResultDetail = _.map(results, (d) => {
        return {
          ...d,
          key: uuidv4(),
          no: '',
          rateFacProName: displayText(d.riskFactorNameTH, d.riskFactorNameEN),
          rateStatus: '',
          likelihood: d.likelihood ? d.likelihood : '-',
          impact: d.impact ? d.impact : '-',
          riskScore: d.riskScore ? d.riskScore : '-',
          personnelName: '',
          shortCode: '',
          mainRecord: false,
        }
      })
      setDataListDetail(ansResultDetail)
    }

    if (getAuditResultDetailApprove?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditResultDetailApprove?.error)),
        onOk() {},
      })
    }
  }, [getAuditResultDetailApprove?.data])

  useEffect(() => {
    if (updateAuditRCMResultApprove?.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          setIsCheckedRcmResultId([])
          getAuditResultsApprove.refetch()
        },
      })
    }

    if (updateAuditRCMResultApprove?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(updateAuditRCMResultApprove?.error)),
        onOk() {
          setIsCheckedRcmResultId([])
        },
      })
    }
  }, [updateAuditRCMResultApprove?.data])

  useEffect(() => {
    if (dataLists.length && dataListDetail.length) {
      const groupResults = _.groupBy(dataListDetail, 'rcmResultId')

      const newDataLists = _.map(dataLists, (d) => {
        const getGroupResults = groupResults[d.key]
        return {
          ...d,
          children: getGroupResults ? getGroupResults : [],
        }
      })

      setIsDataSources([...newDataLists])
    }
  }, [dataLists, dataListDetail])

  useEffect(() => {
    const initColumns = [
      { title: t('เลือก'), dataIndex: 'check', width: '20px' },
      {
        title: t('ปัจจัย/ความเสี่ยง'),
        dataIndex: 'rateFacProName',
        width: '450px',
        align: 'left',
        render: (text, render) => {
          return (
            <Row>
              <Col sm={2}>{`${render.mainRecord ? `${render.shortCode}: ` : ''}`}</Col>
              <Col sm={22}>{text}</Col>
            </Row>
          )
        },
      },
      {
        title: t('สถานะประเมิน'),
        dataIndex: 'rateStatus',
        width: '120px',
        align: 'center',
        render: (text, record) => (record.mainRecord ? (text === 1 ? t('ประเมินแล้ว') : t('ยังไม่ประเมิน')) : ''),
      },
      {
        title: t('ผลการประเมินความเสี่ยง'),
        children: [
          {
            title: t('โอกาส'),
            dataIndex: 'likelihood',
            align: 'center',
            key: 'likelihood',
            width: '120px',
            render: (text, record) => (record.mainRecord ? calculateScore(text) : text),
          },
          {
            title: t('ผลกระทบ'),
            dataIndex: 'impact',
            align: 'center',
            key: 'impact',
            width: '120px',
            render: (text, record) => (record.mainRecord ? calculateScore(text) : text),
          },
          {
            title: t('ระดับความเสี่ยง'),
            dataIndex: 'riskScore',
            align: 'center',
            key: 'riskScore',
            width: '120px',
            render: (text, record) => (record.mainRecord ? calculateRiskScore(text) : text),
          },
        ],
      },
      {
        title: t('ผู้รับผิดชอบ'),
        dataIndex: 'personnelName',
        width: '200px',
        align: 'center',
        render: (text, record) => (record.mainRecord ? text : ''),
      },
      {
        title: t('สถานะยืนยัน'),
        dataIndex: 'approveStatusCode',
        width: '130px',
        align: 'center',
        render: (text, record) => {
          const status = record?.approveStatusCode
          return record?.mainRecord ? (
            <Status
              text={
                status === '10'
                  ? 'รอการอนุมัติ'
                  : status === '20'
                  ? 'อนุมัติแล้ว'
                  : status === '30'
                  ? 'ไม่อนุมัติ'
                  : '-'
              }
            />
          ) : (
            ''
          )
        },
      },
      {
        title: '',
        dataIndex: 'tools',
        width: '50px',
        align: 'center',
        render: (text, record) => {
          let list = [
            {
              type: 'view',
              onClick: () => {
                navigate(`/auditoperations/racmatrixapprove/tab/risk/form`, {
                  state: { ...state, rcmResultId: record?.rcmResultId, riskForm: 'view' },
                })
              },
            },
          ]
          return record.mainRecord ? <ButtonGroup menu={menu} size={18} icons={list} /> : ''
        },
      },
    ]

    setColumns(initColumns)
  }, [])

  const allowRacMatrixFn = () => {
    const obj = {
      rcmId: state?.rcmId,
      approveStatusCode: '20',
      rcmResultId: isCheckedRcmResultId.length ? isCheckedRcmResultId : null,
    }

    if (!isCheckedRcmResultId.length) {
      Alert({ type: 'error', title: t(`กรุณาเลือกรายการปัจจัย/ความเสี่ยง`), onOk() {} })
    } else {
      Alert({
        type: 'confirm',
        title: t('กรุณากดยืนยันหากต้องการอนุมัติความเสี่ยง?'),
        onOk() {
          updateAuditRCMResultApproveFn({ fetchPolicy: 'no-cache', variables: { input: encryptInput(obj) } })
        },
        onCancel() {},
      })
    }
  }

  const noAllowRacMatrixFn = () => {
    const obj = {
      rcmId: state?.rcmId,
      approveStatusCode: '30',
      rcmResultId: isCheckedRcmResultId.length ? isCheckedRcmResultId : null,
    }

    if (!isCheckedRcmResultId.length) {
      Alert({ type: 'error', title: t(`กรุณาเลือกรายการปัจจัย/ความเสี่ยง`), onOk() {} })
    } else {
      ConfirmNotApproveAlert({
        type: 'confirm',
        title: t('ระบุเหตุผลการไม่อนุมัติความเสี่ยง'),
        onConfirm: (value) => {
          if (value.length === 0) {
            Alert({ type: 'error', title: t(`กรุณาระบุเหตุผลการไม่อนุมัติความเสี่ยง`), onOk() {} })
          } else {
            obj.remark = value
            updateAuditRCMResultApproveFn({ fetchPolicy: 'no-cache', variables: { input: encryptInput(obj) } })
          }
        },
        onCancel() {},
      })
    }
  }

  return (
    <>
      {(getAuditResultsApprove?.loading ||
        getAuditResultDetailApprove?.loading ||
        updateAuditRCMResultApprove?.loading) && <SpinnersNew />}
      <Row justify="center">
        <Col {...wrapper}>
          <SearchTable dataSource={dataLists} searchDataSource={(data) => setIsDataSources(data)} searchCustom={true} />
          <RiskTable>
            <Table
              columns={columns}
              dataSource={[...isDataSources]}
              size="middle"
              pagination={false}
              showSorterTooltip={false}
              scroll={{ x: 'max-content' }}
              rowSelection={{ type: 'checkbox', ...rowSelection }}
              onChange={(pagination, filters, sorter) => {
                const reNewField = [
                  { rateFacProName: displayText('rateFacProNameTH', 'rateFacProNameEN') },
                  { personnelName: displayText('personnelNameTH', 'personnelNameEN') },
                ]
                setSort(handleSort(sorter, reNewField))
              }}
              expandable={{
                childrenColumnName: 'children',
                onExpandedRowsChange: (e) => setIsRcmResultId(e),
                expandIcon: ({ expanded, onExpand, record }) =>
                  record.mainRecord ? (
                    <IconNew icon={expanded ? 'ChevronUp' : 'ChevronDown'} onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <></>
                  ),
              }}
            />
          </RiskTable>
          <Row gutter={[12, 20]} justify="center" style={{ marginTop: '2rem' }}>
            {!disabled && (
              <>
                <Col>
                  <ButtonNew type="allow" roles={{ type: 'approve', menu: menu }} onClick={() => allowRacMatrixFn()}>
                    {t('อนุมัติ')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew
                    type="noAllow"
                    roles={{ type: 'approve', menu: menu }}
                    onClick={() => noAllowRacMatrixFn()}
                  >
                    {t('ไม่อนุมัติ')}
                  </ButtonNew>
                </Col>
              </>
            )}
            <Col>
              <ButtonNew type="back" onClick={() => navigate('/auditoperations/racmatrixapprove')}>
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

RiskTab.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default RiskTab
