import { gql } from '@apollo/client'

export const GET_RAC_MATRIX_REPORT_PAGINATION = gql`
  query getRacMatrixReportPagination($input: RacMatrixReportPaginationInput!) {
    getRacMatrixReportPagination(input: $input) {
      result {
        rcmId
        projectCode
        projectNameTH
        projectNameEN
        projectRefNameTH
        projectRefNameEN
        dateStart
        dateEnd
        organizationNameTH
        organizationNameEN
        personnelNameTH
        personnelNameEN
      }
      count
      page
      limit
    }
  }
`

export const EXPORT_RAC_MATRIX_REPORT = gql`
  query exportRacMatrixReport($input: ExportRacMatrixReportInput!) {
    exportRacMatrixReport(input: $input) {
      message
      fileUrl
    }
  }
`
