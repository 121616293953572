import { gql } from '@apollo/client'

export const GET_MASTER_URGENTS = gql`
  query getMasterUrgents {
    getMasterUrgents {
      urgentId
      urgentCode
      urgentNameTH
      urgentNameEN
      isActive
    }
  }
`
