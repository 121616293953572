import React, { useState, useEffect } from 'react'
// import { useTranslation } from 'react-i18next'
// import { useLanguage } from '../../../hooks/useLanguage'
import { Row, Col } from 'antd'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import { GET_EXPERIENCE_PERSONNELS, GET_PERSONNEL } from './graphql/Query'
import * as _ from 'lodash'
import { useQuery, useLazyQuery } from '@apollo/client'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { columnsExperience } from './utils/table/ExperienceTable'
import { encryptInput } from '../../../utilitys/crypto'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

const PersonnelExperienceList = () => {
  // const [isLang] = useLanguage()
  const { state } = useLocation()
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  //get prefix, Name, lastName
  const [contentName, setContentName] = useState()
  const [getPersonnelFn, dataPersonnel] = useLazyQuery(GET_PERSONNEL)
  const getData = (personnelCode) => {
    getPersonnelFn({ variables: { input: encryptInput({ personnelCode: personnelCode }) } })
  }
  useEffect(() => {
    getData(state.refCode)
  }, [state.refCode])
  //
  const getPersonnelExperience = useQuery(GET_EXPERIENCE_PERSONNELS, {
    variables: {
      input: {
        filters: { personnelCode: state.refCode },
        pagination: { limit, page },
        sort: [
          {
            fieldSort: 'experienceId',
            sortType: 'DESC',
          },
        ],
      },
    },
  })

  useEffect(() => {
    if (getPersonnelExperience?.data) {
      let response = getPersonnelExperience?.data?.getPersonnelExperience
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data, i) => {
          return {
            key: i,
            no: (page - 1) * limit + i + 1,
            jobTypeRefer: data.jobTypeRefer,
            experienceType: data.experienceType,
            startDate: moment(new Date(data.startDate)).format('DD/MM/YYYY'),
            endDate: moment(new Date(data.endDate)).format('DD/MM/YYYY'),
            manday: data.manday,
            costPersonnel: data.costPersonnel,
            costJob: data.costJob,
          }
        }),
      )
    }
  }, [getPersonnelExperience.data])
  //เก็บ Prefix, Name ,lastName ที่ดึงมาจาก query
  useEffect(() => {
    if (dataPersonnel.data) {
      setContentName({
        prefix: dataPersonnel.data.getPersonnel.namePrefixTH,
        fName: dataPersonnel.data.getPersonnel.firstNameTH,
        lName: dataPersonnel.data.getPersonnel.lastNameTH,
      })
    }
  }, [dataPersonnel.data])

  return (
    <>
      {getPersonnelExperience.loading && <SpinnersNew />}
      <Row gutter={[16, 16]}>
        {contentName ? contentName.prefix + ' ' + contentName.fName + ' ' + contentName.lName : ''}
        <Col md={24}>
          <Datatable
            columns={columnsExperience}
            data={dataLists}
            handleTableChange={() => {}}
            paginationCustom={true}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            searchCustom={true}
            total={count}
            enableScroll={{ x: true, y: true }}
            pageSize={limit}
            page={page}
          />
        </Col>
      </Row>
    </>
  )
}

export default PersonnelExperienceList
