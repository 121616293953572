import { gql } from '@apollo/client'

export const GET_RAC_MATRIX_PAGINATION = gql`
  query getRacMaTrixPagination($input: RacMatrixPaginationInput!) {
    getRacMaTrixPagination(input: $input) {
      result {
        rcmId
        projectCode
        projectNameTH
        projectNameEN
        projectRefNameTH
        projectRefNameEN
        dateStart
        dateEnd
        organizationNameTH
        organizationNameEN
        personnelNameTH
        personnelNameEN
        createdBy
        operationStatusNameTH
        operationStatusNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_ORGANIZATION_PAGINATION = gql`
  query getMasterOrganizationsPagination($input: MasterOrganizationsPagination!) {
    getMasterOrganizationsPagination(input: $input) {
      result {
        organizationId
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationLevelNameTH
        organizationLevelNameEN
        organizationLevelCode
        personnelCode
        personnelNameTH
        personnelNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDIT_RCMS_BY_ID = gql`
  query getAuditRCMsById($rcmId: String!) {
    getAuditRCMsById(rcmId: $rcmId) {
      auditRCMs {
        rcmId
        projectCode
        rcmObjective
        rcmScope
        riskProcess
        riskProcessSub
        personnelApprove
        personnelMaker
        personnelReview
        isActive
        operationStatusCode
        planStatusCode
        riskModelCode
        riskModelTH
        riskModelEN
        createdBy
      }
      AuditRCMOrgs {
        rcmOrgId
        rcmId
        organizationId
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationLevelNameTH
        organizationLevelNameEN
        organizationLevelCode
        personnelCode
        personnelNameTH
        personnelNameEN
      }
    }
  }
`

export const GET_AUDIT_PROJECT_ORGS = gql`
  query getAuditProjectOrgs($projectCode: String!) {
    getAuditProjectOrgs(projectCode: $projectCode) {
      organizationId
      organizationCode
      organizationNameTH
      organizationNameEN
      organizationLevelNameTH
      organizationLevelNameEN
      organizationLevelCode
      personnelCode
      personnelNameTH
      personnelNameEN
    }
  }
`

export const GET_AUDIT_RESULTS = gql`
  query getAuditResults($input: AuditResultInput!) {
    getAuditResults(input: $input) {
      rcmResultId
      rcmId
      rateFacGroupCode
      rateFacProCode
      likelihood
      impact
      riskScore
      isActive
      rateStatus
      rateFacProNameTH
      rateFacProNameEN
      shortCode
      sort
      personnelNameTH
      personnelNameEN
      personnelRCMNameTH
      personnelRCMNameEN
      createdBy
      rateFacGroupDetail
      rateFacGroupNameTH
      rateFacGroupNameEN
      approveStatusCode
      approveStatusNameTH
      approveStatusNameEN
    }
  }
`

export const GET_AUDIT_RESULT_DETAIL = gql`
  query getAuditResultDetail($input: AuditResultDetailInput!) {
    getAuditResultDetail(input: $input) {
      rcmResultDetailId
      rcmResultId
      rateFacProCode
      riskFactorCode
      likelihood
      impact
      riskScore
      riskFactorNameTH
      riskFactorNameEN
    }
  }
`

export const GET_AUDIT_RCM_RESULT = gql`
  query getAuditRCMResult($rcmResultId: String!) {
    getAuditRCMResult(rcmResultId: $rcmResultId) {
      result {
        rcmResultId
        rcmId
        rateFacGroupCode
        rateFacProCode
        likelihood
        impact
        riskScore
        isActive
        rateStatus
        rateFacProNameTH
        rateFacProNameEN
        shortCode
        sort
        rateFacGroupNameTH
        rateFacGroupNameEN
        riskModelTH
        riskModelEN
        personnelRCMNameTH
        personnelRCMNameEN
        rateFacGroupDetail
      }
      resultDetails {
        rcmResultDetailId
        rcmResultId
        rateFacProCode
        riskFactorCode
        likelihood
        impact
        riskScore
        riskFactorNameTH
        riskFactorNameEN
      }
    }
  }
`

export const GET_AUDIT_RCM_PERSONNEL = gql`
  query getPersonnelRCM($input: AuditRCMPersonnelInput!) {
    getPersonnelRCM(input: $input) {
      resultRiskModel {
        riskModelId
        riskModelCode
        riskModelTH
        riskModelEN
      }
      resultPersonnels {
        rcmOrgId
        rcmId
        organizationId
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationLevelNameTH
        organizationLevelNameEN
        organizationLevelCode
        personnelCode
        personnelNameTH
        personnelNameEN
      }
      resultAuditPersonnels {
        personnelCode
        organizationCode
        personnelNameTH
        personnelNameEN
      }
      auditRCMResult {
        riskDetail
      }
    }
  }
`

export const GET_AUDIT_RESULT_CONTROLS = gql`
  query getAuditRCMResultControl($input: AuditRCMResultControlListInput!) {
    getAuditRCMResultControl(input: $input) {
      rcmControlId
      rcmResultId
      rateFacGroupCode
      rateFacProCode
      rateRiskRuleCode
      rateRiskConCode
      controlScore
      controlDetailTH
      controlDetailEN
      riskBalance
      riskBalanceDetailTH
      riskBalanceDetailEN
      riskFrequencyTH
      riskFrequencyEN
      objectiveTH
      objectiveEN
      guideTH
      guideEN
      isActive
      rateRiskRuleTH
      rateRiskRuleEN
      rateFacProNameTH
      rateFacProNameEN
      shortCode
      _rateFacProNameTH
      _rateFacProNameEN
      sort
      riskScore
    }
  }
`

export const GET_AUDIT_RESULT_CONTROL = gql`
  query getAuditRCMResultControlById($input: AuditRCMResultControlInput!) {
    getAuditRCMResultControlById(input: $input) {
      rcmControlId
      rcmResultId
      rateFacGroupCode
      rateFacProCode
      rateRiskRuleCode
      rateRiskConCode
      controlScore
      controlDetailTH
      controlDetailEN
      riskBalance
      riskBalanceDetailTH
      riskBalanceDetailEN
      riskFrequencyTH
      riskFrequencyEN
      objectiveTH
      objectiveEN
      guideTH
      guideEN
      isActive
      rateRiskRuleTH
      rateRiskRuleEN
      rateFacProNameTH
      rateFacProNameEN
      riskScore
    }
  }
`

export const GET_AUDIT_RCM_RESULT_ITEM = gql`
  query getAuditRCMResultsItem($input: AuditRCMResultItemInput!) {
    getAuditRCMResultsItem(input: $input) {
      rcmResultId
      riskScore
    }
  }
`

export const GET_AUDIT_RCM_FILES = gql`
  query getAuditRCMsFileById($rcmId: String!) {
    getAuditRCMsFileById(rcmId: $rcmId) {
      fileId
      filename
      links
      refFile {
        originalname
        name
      }
    }
  }
`

export const EXPORT_RAC_MATRIX = gql`
  query exportRacMatrix($input: ExportRacMatrixInput!) {
    exportRacMatrix(input: $input) {
      message
      fileUrl
    }
  }
`

export const GET_PERSONNEL_ORGANIZATION = gql`
  query getPersonnelOrganization($input: PersonnelOrganizationInput!) {
    getPersonnelOrganization(data: $input) {
      personnelCode
      organizationCode
      organizationChecked
      userId
    }
  }
`

export const GET_RATE_RISK_FACTOR_PROCESS_RCM = gql`
  query getRateRiskFactorProcessRCM($input: RateRiskFactorProcessRCMInput!) {
    getRateRiskFactorProcessRCM(input: $input) {
      rateFacGroupCode
      rateFacGroupNameEN
      rateFacGroupNameTH
      rateFacProCode
      shortCode
      rateFacProNameEN
      rateFacProNameTH
    }
  }
`
