import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { Form, Space, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { displayText } from '../../../../../utilitys/helper'
import { ModalNew } from '../../../../../components/modal/Modal'
import { InputNew } from '../../../../../components/input/Input'
import { ButtonNew } from '../../../../../components/button/Button'
import { Radio, RadioGroup } from '../../../../../components/radio/Radio'
import { Select } from '../../../../../components/select/Select'
import { LabelNew as Label } from '../../../../../components/label/Label'
import SelectChoiceTypes from '../../../../../components/inputfromapi/selectchoicetypes/SelectChoiceTypes'
import SelectChoiceGroups from '../../../../../components/inputfromapi/selectchoicegroups/SelectChoiceGroups'
import SelectQuestDetailTypes from '../../../../../components/inputfromapi/selectquestdetailtypes/SelectQuestDetailTypes'
import SelectQuestRequired from '../../../../../components/inputfromapi/selectquestrequired/SelectQuestRequired'

const QuestionModal = (props) => {
  const formname = 'QuestionFormModal'
  const labelCol = { sm: 14, md: 9, lg: 9, xl: 9 }
  const wrapperCol = { sm: 16, md: 16, lg: 16, xl: 16, align: 'left' }

  const [form] = Form.useForm()
  const { t } = useTranslation()

  const [isKey, setIsKey] = useState(uuidv4())
  const [visible, setVisible] = useState(false)
  const [isFormConfiguration, setIsFormConfiguration] = useState([])
  const [isChoiceTypeCode, setIsChoiceTypeCode] = useState(null)
  const [disableQuestDetailType, setDisableQuestDetailType] = useState(true)
  const [disableChoiceGroupCode, setDisableChoiceGroupCode] = useState(true)

  useEffect(() => {
    setVisible(props.open)
  }, [props.open])

  useEffect(() => {
    if (props.isConfiguration) {
      setIsFormConfiguration(
        props?.isConfiguration.map((data) => {
          return {
            label: displayText(data?.formConfigTH, data?.formConfigEN),
            value: data.formConfigNo,
            source: data,
          }
        }),
      )
    }
  }, [props.isConfiguration])

  useEffect(() => {
    if (isFormConfiguration.length && props.isQuestionItem && props.accessModalConfAction === 'edit') {
      setIsKey(props?.isQuestionItem?.key)
      setIsChoiceTypeCode(props?.isQuestionItem?.choiceTypeCode)

      form.setFieldsValue({
        choiceGroupCode: props.isQuestionItem.choiceGroupCode,
        choiceTypeCode: props.isQuestionItem.choiceTypeCode,
        formConfigNo: props.isQuestionItem.formConfigNo,
        isActive: props.isQuestionItem.isActive,
        key: props.isQuestionItem.key,
        questDetailType: props.isQuestionItem.questDetailType,
        // questDetailType: null,
        questEN: props.isQuestionItem.questEN,
        questRequired: props.isQuestionItem.questRequired,
        questTH: props.isQuestionItem.questTH,
      })
    }
  }, [isFormConfiguration, props.isQuestionItem, props.accessModalConfAction])

  const onFinish = (data) => {
    form.resetFields()
    setVisible(false)
    props.getData({ ...data, isActive: data.isActive ? 1 : 0, key: isKey })
    props.close(false)
  }

  const onCancel = () => {
    form.resetFields()
    setVisible(false)
    props.close(false)
  }

  useEffect(() => {
    if (props?.accessTools === 'view') {
      setDisableChoiceGroupCode(true)
      setDisableQuestDetailType(true)
    } else {
      if (isChoiceTypeCode === '10') {
        setDisableChoiceGroupCode(false)
        setDisableQuestDetailType(false)
      }
      if (isChoiceTypeCode === '20') {
        setDisableChoiceGroupCode(true)
        setDisableQuestDetailType(true)
      }
    }
  }, [props.accessTools, isChoiceTypeCode])

  return (
    <ModalNew
      onSubmit={() => {}}
      onClose={() => onCancel()}
      visible={visible}
      typeAction={props.accessTools === 'edit' ? 'edit' : props.accessTools === 'view' ? 'view' : null}
      testTitle={
        props.accessTools === 'edit' ? t('แก้ไขคำถาม') : props.accessTools === 'view' ? t('คำถาม') : t('เพิ่มคำถาม')
      }
      type="medium"
      footer={null}
    >
      <Col sm={24} md={16} lg={18} style={{ margin: 'auto' }}>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{ ...labelCol }}
          wrapperCol={{ ...wrapperCol }}
          layout="horizontal"
          name={formname}
        >
          <Form.Item
            name="formConfigNo"
            label={t('องค์ประกอบการควบคุม')}
            rules={[{ required: true, message: t('กรุณาเลือกองค์ประกอบการควบคุม') }]}
          >
            <Select
              disabled={props.accessTools ? (props.accessTools === 'view' ? true : false) : false}
              scrollableId={formname}
              placeholder={t('เลือกองค์ประกอบการควบคุม')}
              showSearch
              data={isFormConfiguration}
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            />
          </Form.Item>
          <Form.Item
            name="questTH"
            label={t('คำถามภาษาไทย')}
            rules={[{ required: true, message: t('กรุณาระบุคำถามภาษาไทย') }]}
          >
            <InputNew
              placeholder={t('ระบุคำถามภาษาไทย')}
              disabled={props.accessTools ? (props.accessTools === 'view' ? true : false) : false}
              maxLength={200}
            />
          </Form.Item>
          <Form.Item name="questEN" label={t('คำถามภาษาอังกฤษ')}>
            <InputNew
              placeholder={t('ระบุคำถามภาษาอังกฤษ')}
              disabled={props.accessTools ? (props.accessTools === 'view' ? true : false) : false}
              maxLength={200}
            />
          </Form.Item>
          <Form.Item
            name="choiceTypeCode"
            label={t('ประเภทคำตอบ')}
            rules={[{ required: true, message: t('กรุณาระบุประเภทคำตอบ') }]}
          >
            <SelectChoiceTypes
              formname={formname}
              placeholder={t('เลือกประเภทคำตอบ')}
              handleChange={(e) => {
                setIsChoiceTypeCode(e?.value)
                form.setFieldsValue({ choiceGroupCode: null, questDetailType: null })
                form.setFieldsValue({ choiceTypeCode: e?.value })
              }}
              disabled={props.accessTools ? (props.accessTools === 'view' ? true : false) : false}
            />
          </Form.Item>
          <Form.Item
            name="choiceGroupCode"
            label={t('กลุ่มตัวเลือก')}
            rules={[!disableChoiceGroupCode ? { required: true, message: t('กรุณาเลือกกลุ่มตัวเลือก') } : {}]}
          >
            <SelectChoiceGroups
              formname={formname}
              placeholder={t('เลือกกลุ่มตัวเลือก')}
              handleChange={(e) => form.setFieldsValue({ choiceGroupCode: e?.value })}
              disabled={disableChoiceGroupCode}
            />
          </Form.Item>
          <Form.Item
            name="questDetailType"
            label={t('คำอธิบาย')}
            rules={[!disableQuestDetailType ? { required: true, message: t('กรุณาเลือกคำอธิบาย') } : {}]}
          >
            <SelectQuestDetailTypes
              formname={formname}
              placeholder={t('เลือกคำอธิบาย')}
              handleChange={(e) => form.setFieldsValue({ questDetailType: e?.value })}
              disabled={disableQuestDetailType}
            />
          </Form.Item>
          <Form.Item
            name="questRequired"
            label={t('ความจำเป็น')}
            rules={[{ required: true, message: t('กรุณาเลือกความจำเป็น') }]}
          >
            <SelectQuestRequired
              formname={formname}
              placeholder={t('เลือกความจำเป็น')}
              handleChange={(e) => form.setFieldsValue({ questRequired: e?.value })}
              disabled={props.accessTools ? (props.accessTools === 'view' ? true : false) : false}
            />
          </Form.Item>
          <Form.Item
            label={t('สถานะ')}
            name="isActive"
            initialValue={1}
            rules={[{ required: true, message: t('กรุณาระบุสถานะ') }]}
          >
            <RadioGroup disabled={props.accessTools ? (props.accessTools === 'view' ? true : false) : false}>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
              </Space>
            </RadioGroup>
          </Form.Item>

          {props?.accessTools && props?.accessTools === 'view' ? (
            <Col align="center">
              <Space size={[8, 8]} wrap>
                <ButtonNew type="back" onClick={() => Promise.all([setVisible(false), props.close(false)])}>
                  {t('ยกเลิก')}
                </ButtonNew>
              </Space>
            </Col>
          ) : (
            <Col align="center">
              <Space size={[8, 8]} wrap>
                <ButtonNew type="primary" roles={{ type: 'add', menu: props.menu }} htmlType="submit">
                  {t('เพิ่ม')}
                </ButtonNew>
                <ButtonNew type="back" onClick={() => onCancel()}>
                  {t('ยกเลิก')}
                </ButtonNew>
              </Space>
            </Col>
          )}
        </Form>
      </Col>
    </ModalNew>
  )
}

QuestionModal.propTypes = {
  open: PropTypes.bool,
  getData: PropTypes.func,
  close: PropTypes.func,
  isConfiguration: PropTypes.array,
  menu: PropTypes.string.isRequired,
}

export default QuestionModal
