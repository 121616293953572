import { gql } from '@apollo/client'

export const GET_CONTROLTYPE_PAGINATION = gql`
  query getMasterControlTypePagination($input: ControlTypePaginationInput!) {
    getMasterControlTypePagination(input: $input) {
      result {
        controlTypeCode
        controlTypeNameTH
        controlTypeNameEN
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_CONTROLTYPE_BY_CODE = gql`
  query getMasterControlTypeByCode($controlTypeCode: String!) {
    getMasterControlTypeByCode(controlTypeCode: $controlTypeCode) {
      controlTypeCode
      controlTypeNameTH
      controlTypeNameEN
      isActive
    }
  }
`
