import { gql } from '@apollo/client'
export const GET_AUDIT_SUGGEST = gql`
  query getAuditSuggestOfIssues($input: SelectAuditSuggestOfIssuesInput!) {
    getAuditSuggestOfIssues(data: $input) {
      suggestId
      suggestTH
      suggestEN
    }
  }
`
