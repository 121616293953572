import { gql } from '@apollo/client'

export const GET_LIST_AUDIT_JOB_STEP_BY_ACTION_PLAN_ID = gql`
  query GetListAuditJobStepByActionPlanID($input: AuditJobStepListInput!) {
    getListAuditJobStepByActionPlanID(input: $input) {
      stepId
      actionPlanId
      stepIdRef
      stepNameTH
      stepNameEN
      stepType
      stepSort
      dateStart
      dateEnd
      operationStatusCode
      operationStatusNameTH
      operationStatusNameEN
      isActive
      personnels {
        personnelCode
        personnelNameTH
        personnelNameEN
      }
      organizations {
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationCode
        organizationNameTH
        organizationNameEN
        personnelCode
        personnelNameTH
        personnelNameEN
        organizationLevelCode
        organizationLevelNameTH
        organizationLevelNameEN
      }
    }
  }
`
