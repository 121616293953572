import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import AcResolutionListFilter from './components/filter/AcResolutionListFilter'
import AcResolutionListDataTable from './components/dataTable/AcResolutionListDataTable'

import { Row, Col } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'

const AcResolutionList = ({ menu }) => {
  const { t } = useTranslation()

  const [filters, setFilters] = useState({})

  const arrCrumb = [
    { label: t('งานปฎิบัติการตรวจสอบ'), path: '/auditoperations/acresolution' },
    { label: t('บันทึกมติคณะกรรมการตรวจสอบ') },
  ]

  return (
    <>
      <Row>
        <Col md={24}>
          <Breadcrumb data={arrCrumb} title={t('บันทึกมติคณะกรรมการตรวจสอบ')} />
          <AcResolutionListFilter menu={menu} filters={filters} setFilters={setFilters} />
          <AcResolutionListDataTable menu={menu} filters={filters} />
        </Col>
      </Row>
    </>
  )
}

export default AcResolutionList
