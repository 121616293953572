import React from 'react'
import { Trans } from 'react-i18next'

import styled from 'styled-components'

const StyledC = styled.span`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  color: #999;
`

export const NoData = () => {
  return (
    <StyledC className="graph-nodata">
      <Trans>No Data</Trans>
    </StyledC>
  )
}
