import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getSegmentUrl } from '../../../../../utilitys/helper'

import { LabelNew } from '../../../../../components/label/Label'
import { InputNew } from '../../../../../components/input/Input'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import FormFollowUpAuditManagerTabMultiRow from './FormFollowUpAuditManagerTabMultiRow'
import { ButtonNew } from '../../../../../components/button/Button'
import { Alert } from '../../../../../components/alert/Alert'
import { FOLLOW_UP_MANAGER_SAVE } from '../../graphql/Mutation'
import { DATA_FOLLOW_UP_MANAGER_TAB } from '../../graphql/Query'
import { useMutation, useLazyQuery } from '@apollo/client'
import { displayError } from '../../../../../utilitys/helper'
import { encryptInput } from '../../../../../utilitys/crypto'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { displayText } from '../../../../../utilitys/helper'

const FormFollowUpAuditManagerTabIndex = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const type = getSegmentUrl(4)

  const [form] = Form.useForm()
  const [followUpId, setFollowUpId] = useState(null)

  const [filesEdit, setFilesEdit] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])
  const showConfirm = () => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        form.submit()
      },
      onCancel() {}
    })
  }
  const showAlertSaveError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t(text),
      onOk() {}
    })
  }

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {}
    })
  }
  const showAlertSuccess = () => {
    Alert({
      type: 'success',
      title: t('บันทึกสำเร็จ'),
      onOk() {
        if (type === 'follow') {
          navigate('/followup/followupaudit/activity/edit', {
            state: { refCode: props.refCode }
          })
        }
      }
    })
  }
  const [dataFollowUpManagerTabFn, dataFollowUpManagerTab] = useLazyQuery(DATA_FOLLOW_UP_MANAGER_TAB)
  useEffect(() => {
    if (dataFollowUpManagerTab?.data) {
      let response = dataFollowUpManagerTab?.data?.dataFollowUpManagerTab
      if (response) {
        setFollowUpId(response.followUpId)
        form.setFieldsValue({
          mdNumber: response.mdNumber,
          dateEntryMC: response.dateEntryMC,
          dateSendReport: response.dateSendReport,
          remark: displayText(response.remarkTH, response.remarkEN)
        })
        const fileDetail = JSON.parse(response.fileDetail)
          ? JSON.parse(response.fileDetail).map((item) => {
              let fileDetail2 = response.refFile.find((subItem) => {
                return item.fileId === subItem.fileId
              })
              return {
                ...item,
                originalname: fileDetail2 ? fileDetail2.originalname : '',
                name: fileDetail2 ? fileDetail2.name : ''
              }
            })
          : []
        setFilesEdit(fileDetail)
      }
    }
    if (dataFollowUpManagerTab.error) {
      showAlertLoadDataError(displayError(dataFollowUpManagerTab.error))
    }
  }, [dataFollowUpManagerTab.data])
  const [followUpManagerTabSaveFn, followUpManagerTabSave] = useMutation(FOLLOW_UP_MANAGER_SAVE)
  useEffect(() => {
    if (followUpManagerTabSave?.data) {
      let response = followUpManagerTabSave?.data?.followUpManagerTabSave
      if (response) {
        showAlertSuccess()
      }
    }
    if (followUpManagerTabSave.error) {
      showAlertSaveError(displayError(followUpManagerTabSave.error))
    }
  }, [followUpManagerTabSave.data])

  useEffect(() => {
    if (type !== 'follow') {
      dataFollowUpManagerTabFn({
        variables: {
          projectCode: encryptInput(props.refCode)
        },
        fetchPolicy: 'no-cache'
      })
    }
  }, [type])
  const onFinish = (values) => {
    const { mdNumber, dateSendReport, remark, dateEntryMC } = values
    delete values['dateSendReport']
    delete values['mdNumber']
    delete values['remark']
    delete values['dateEntryMC']
    let fileInfor = Object.values(values)
    let files = fileInfor
      .filter((item) => {
        return item.fileId !== null
      })
      .map((item) => {
        return item.fileId
      })
      .join()
    let fileDetail = JSON.stringify(fileInfor)
    const input = {
      followUpId: followUpId,
      projectCode: props.refCode,
      mdNumber: mdNumber,
      dateSendReport: dateSendReport,
      dateEntryMC: dateEntryMC,
      files: files,
      fileDetail: fileDetail,
      idFileDelete: idFileDelete.join(),
      remark: remark,
      lang: localStorage.getItem('lang'),
      typeAction: type === 'follow' ? 'add' : 'edit'
    }
    followUpManagerTabSaveFn({
      variables: {
        input: encryptInput(input)
      },
      fetchPolicy: 'no-cache'
    })
  }
  return (
    <>
      {(followUpManagerTabSave.loading || dataFollowUpManagerTab.loading) && <SpinnersNew />}

      <Row>
        <Col md={24}>
          <Form
            form={form}
            autoComplete="off"
            labelAlign="left"
            labelCol={{
              sm: 24,
              md: 8
            }}
            wrapperCol={{
              sm: 24,
              md: 16
            }}
            layout="horizontal"
            name="managerForm"
            onFinish={onFinish}
          >
            <LabelNew type="body-header">{t('ข้อมูล ผจ.ลบ')}</LabelNew>
            <Row justify="center">
              <Col md={18}>
                <Form.Item
                  name="mdNumber"
                  label={<LabelNew type="tab-header-inactive">{t('เลขที่ ผจ.ลบ')}</LabelNew>}
                  rules={[
                    { required: true, message: t('กรุณาระบุเลขที่ ผจ.ลบ') },
                    {
                      max: 20,
                      message: t('กรุณาระบุเลขที่ ผจ.ลบ น้อยกว่าหรือเท่ากับ 20 ตัวอักษร')
                    }
                  ]}
                >
                  <InputNew
                    maxLength={20}
                    placeholder={t('ระบุเลขที่ ผจ.ลบ')}
                    disabled={type === 'view' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  name="dateSendReport"
                  labelAlign="left"
                  label={
                    <LabelNew type="tab-header-inactive">{t('วันที่ส่งรายงานให้หน่วยรับตรวจ ( MD สั่งการ)')}</LabelNew>
                  }
                  rules={[{ required: true, message: t('กรุณาเลือกวันที่ส่งรายงานให้หน่วยรับตรวจ ( MD สั่งการ)') }]}
                >
                  <Datepicker
                    setValueDateFn={async (data) => {
                      form.setFieldsValue({
                        dateSendReport: await data
                      })
                    }}
                    onClear={() => {
                      form.setFieldsValue({ dateSendReport: null })
                    }}
                    placeholder={t('เลือกวันที่ส่งรายงานให้หน่วยรับตรวจ (MD สั่งการ)')}
                    disabled={type === 'view' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  name={'dateEntryMC'}
                  labelAlign="left"
                  label={<LabelNew type="tab-header-inactive">{t('วันที่เข้า MC')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาเลือกวันที่เข้า MC') }]}
                >
                  <Datepicker
                    setValueDateFn={async (data) => {
                      form.setFieldsValue({
                        dateEntryMC: await data
                      })
                    }}
                    onClear={() => {
                      form.setFieldsValue({ dateEntryMC: null })
                    }}
                    placeholder={t('เลือกวันที่เข้า MC')}
                    disabled={type === 'view' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  label={<LabelNew type="tab-header-inactive">{t('หมายเหตุ')}</LabelNew>}
                  name="remark"
                  rules={[
                    { required: true, message: t('กรุณาระบุหมายเหตุ') },
                    {
                      max: 200,
                      message: t('กรุณาระบุหมายเหตุ น้อยกว่าหรือเท่ากับ 200 ตัวอักษร')
                    }
                  ]}
                  maxLength={200}
                >
                  <TextAreaNew placeholder={t('ระบุหมายเหตุ')} disabled={type === 'view' ? true : false} />
                </Form.Item>
              </Col>
            </Row>
            <LabelNew type="body-header">{t('แนบเอกสาร')}</LabelNew>
            <FormFollowUpAuditManagerTabMultiRow
              form={form}
              typeForm={type}
              menu={props.menu}
              idFileDelete={idFileDelete}
              files={filesEdit}
              setIdFileDelete={setIdFileDelete}
            />
            <Row gutter={[12, 12]} justify="center" style={{ marginTop: '50px' }}>
              {type !== 'view' && (
                <Col>
                  <ButtonNew
                    type="primary"
                    roles={{ type: type === 'follow' ? 'add' : type, menu: props.menu }}
                    onClick={() => {
                      showConfirm()
                    }}
                    htmlType="button"
                  >
                    {t('บันทึก')}
                  </ButtonNew>
                </Col>
              )}
              <Col>
                <ButtonNew
                  type="cancel"
                  onClick={() => {
                    navigate('/followup/followupaudit')
                  }}
                >
                  {t('ยกเลิก')}
                </ButtonNew>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default FormFollowUpAuditManagerTabIndex
