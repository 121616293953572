import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useMutation, useLazyQuery } from '@apollo/client'
import { Space, Form, Row, Col } from 'antd'
import { ModalNew } from '../../../../../components/modal/Modal'
import { InputNew } from '../../../../../components/input/Input'
import { RadioGroup, Radio } from '../../../../../components/radio/Radio'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { LabelNew as Label, LabelNew } from '../../../../../components/label/Label'
import { encryptInput } from '../../../../../utilitys/crypto'
import { Alert } from '../../../../../components/alert/Alert'
import { regexEng, displayError } from '../../../../../utilitys/helper'
import { GET_RISK_FORMULAR_VARIABLE_BY_CODE } from '../../graphql/Query'
import { CREATE_RISK_FORMULAR_VARIABLE, UPDATE_RISK_FORMULAR_VARIABLE } from '../../graphql/Mutation'
import SelectVariableType from '../../../../../components/inputfromapi/selectvariabletype/SelectVariableType'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { ButtonNew } from '../../../../../components/button/Button'
import { Columns } from '../../utils/RiskFormularValueColumns'
import _ from 'lodash'

const VariableModal = (props) => {
  const { dataParams, menu, riskFormulaCode, lockVariableCode } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const formname = 'VariableForm'
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [editable, seteditable] = useState(true)
  const [dataLists, setDataLists] = useState([])
  const [stateCode, setStateCode] = useState(riskFormulaCode)

  const { state } = useLocation()
  if (!state) {
    return <Navigate to={'/administrator/riskformula'} />
  }
  /* ---------------- API ----------- */
  const [
    createRiskFormulaVariableFn,
    {
      loading: loadingCreateRiskFormulaVariable,
      error: errorCreateRiskFormulaVariable,
      data: dataCreateRiskFormulaVariable,
    },
  ] = useMutation(CREATE_RISK_FORMULAR_VARIABLE)
  const [
    editRiskFormulaVariableFn,
    { loading: loadingEditRiskFormulaVariable, error: errorEditRiskFormulaVariable, data: dataEditRiskFormulaVariable },
  ] = useMutation(UPDATE_RISK_FORMULAR_VARIABLE)

  const [getRiskFormulaVariablesByCodeFn, dataRiskFormulaVariablesByCode] = useLazyQuery(
    GET_RISK_FORMULAR_VARIABLE_BY_CODE,
  )

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    setVisible(props.open)
    if (dataParams?.type_action === 'edit' || dataParams?.type_action === 'view') {
      getRiskFormulaVariablesByCodeFn({
        variables: {
          variableCode: encryptInput(dataParams.variableCode),
        },
      })
    }
    if (dataParams?.type_action === 'view') {
      seteditable(false)
    } else {
      seteditable(true)
    }
  }, [dataParams, props.open])

  useEffect(() => {
    if (dataParams && dataRiskFormulaVariablesByCode.data) {
      const data = dataRiskFormulaVariablesByCode?.data?.getRiskFormulaVariablesByCode?.riskFormulaVariable
      const dataRiskFormulaValues =
        dataRiskFormulaVariablesByCode?.data?.getRiskFormulaVariablesByCode?.riskFormulaValues
      form.setFieldsValue({
        variableCode: data.variableCode,
        variableName: data.variableName,
        variableDetailTH: data.variableDetailTH ? data.variableDetailTH : '',
        variableDetailEN: data.variableDetailEN ? data.variableDetailEN : '',
        variableType: data.variableType,
        isActive: data.isActive,
      })
      setStateCode(data.riskFormulaCode)
      const mapDataList = dataRiskFormulaValues.map((items, key) => {
        return { ...items, key }
      })
      setDataLists(mapDataList)
    }
    if (dataRiskFormulaVariablesByCode?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(dataRiskFormulaVariablesByCode?.error)),
        onOk() {},
      })
    }
  }, [dataRiskFormulaVariablesByCode.data, dataParams?.riskLevelCode])

  useEffect(() => {
    if (dataCreateRiskFormulaVariable) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          setVisible(false)
          props.close(false)
          navigate('/administrator/riskformula/edit', {
            state: { type_action: 'edit', riskFormulaCode: riskFormulaCode },
          })
        },
      })
    }
    if (errorCreateRiskFormulaVariable) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorCreateRiskFormulaVariable)),
        onOk() {},
      })
    }
  }, [dataCreateRiskFormulaVariable])

  useEffect(() => {
    if (dataEditRiskFormulaVariable) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          setVisible(false)
          props.close(false)
          navigate('/administrator/riskformula/edit', {
            state: { type_action: 'edit', riskFormulaCode: stateCode },
          })
        },
      })
    }
    if (errorEditRiskFormulaVariable) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorEditRiskFormulaVariable)),
        onOk() {},
      })
    }
  }, [dataEditRiskFormulaVariable])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    showConfirm(input)
  }
  const showConfirm = (input) => {
    const dataInput = {
      ...input,
      riskFormulaCode: stateCode,
      variableDetailEN: input.variableDetailEN ? input.variableDetailEN : '',
      isActive: parseInt(input.isActive),
    }
    if (input.riskFormulaValues) {
      dataInput.riskFormulaValues = input.riskFormulaValues.map((items) => {
        return {
          valueVariable: items.valueVariable,
          color: items.color,
          riskLevelCode: items.riskLevelCode,
          valueDetail: items.valueDetail,
          valueEffect: items.valueEffect,
        }
      })
    } else {
      dataInput.riskFormulaValues = []
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        if (dataParams?.type_action === 'add') {
          createRiskFormulaVariableFn({
            variables: {
              input: encryptInput(dataInput),
            },
          })
        }
        if (dataParams?.type_action === 'edit') {
          editRiskFormulaVariableFn({
            variables: {
              input: encryptInput(dataInput),
            },
          })
        }
      },
      onCancel() {},
    })
  }

  function onClose(e) {
    form.resetFields()
    setVisible(e)
    props.close(e)
    setDataLists([])
  }

  const handlePressAdd = () => {
    let newData = {
      key: dataLists.length,
      variableCode: null,
      color: null,
      riskLevelCode: null,
      valueVariable: null,
      valueDetail: null,
      valueEffect: null,
    }
    const fields = form.getFieldsValue()
    const result = Object.values(fields)

    result.push(newData)
    setDataLists([...dataLists, newData])
    form.setFieldsValue(result)
  }

  const fncDelete = (index) => {
    const fields = form.getFieldsValue()
    let { riskFormulaValues } = fields

    let filterTable = [..._.filter(riskFormulaValues, (e, key) => key !== index)]

    if (dataLists.length > 1) {
      let newTable = filterTable.map((item, key) => {
        return { ...item, key }
      })

      setDataLists(newTable)
      fields.riskFormulaValues = newTable
    } else {
      setDataLists([])
      fields.riskFormulaValues = []
    }

    form.setFieldsValue(fields)
  }

  return (
    <>
      {(loadingCreateRiskFormulaVariable ||
        loadingEditRiskFormulaVariable ||
        dataRiskFormulaVariablesByCode.loading) && <SpinnersNew />}
      <ModalNew
        visible={visible}
        typeAction={dataParams?.type_action}
        testTitle={
          !dataParams ? t('เพิ่มตัวแปร') : dataParams.type_action === 'edit' ? t('แก้ไขตัวแปร') : t('ข้อมูลตัวแปร')
        }
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        type="big"
        okButtonProps={dataParams?.type_action === 'view' ? { style: { display: 'none' } } : {}}
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{ span: 8, offset: 2 }}
          wrapperCol={{ span: 10 }}
          layout="horizontal"
          name={formname}
        >
          <Row>
            <Col span={12}>
              <Form.Item
                name="variableCode"
                label={<Label type="tab-header-inactive">{t('รหัสตัวแปร')}</Label>}
                rules={[
                  { required: true, message: t('กรุณากรอกรหัสตัวแปร') },
                  {
                    pattern: regexEng,
                    message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
                  },
                ]}
              >
                <InputNew
                  placeholder={t('รหัสตัวแปร')}
                  disabled={dataParams?.type_action !== 'add' ? true : false}
                  maxLength={10}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="variableName"
                label={<Label type="tab-header-inactive">{t('ตัวแปร')}</Label>}
                rules={[
                  { required: true, message: t('กรุณากรอกตัวแปร') },
                  {
                    pattern: regexEng,
                    message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
                  },
                ]}
              >
                <InputNew placeholder={t('ตัวแปร')} disabled={!editable} maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="variableDetailTH"
                label={<Label type="tab-header-inactive">{t('คำอธิบายภาษาไทย')}</Label>}
                rules={[{ required: true, message: t('กรุณากรอกคำอธิบายภาษาไทย') }]}
              >
                <InputNew placeholder={t('คำอธิบายภาษาไทย')} disabled={!editable} maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="variableDetailEN"
                label={<Label type="tab-header-inactive">{t('คำอธิบายภาษาอังกฤษ')}</Label>}
                rules={[
                  {
                    pattern: regexEng,
                    message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
                  },
                ]}
              >
                <InputNew placeholder={t('คำอธิบายภาษาอังกฤษ')} disabled={!editable} maxLength={100} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="variableType"
                label={<Label type="tab-header-inactive">{t('ประเภทตัวแปร')}</Label>}
                rules={[{ required: true, message: t('กรุณาเลือกประเภทตัวแปร') }]}
              >
                <SelectVariableType
                  formname={formname}
                  lockVariableTypes={JSON.stringify(lockVariableCode)}
                  editVariableType={form.getFieldsValue()?.variableType}
                  placeholder={t('เลือกประเภทตัวแปร')}
                  handleChange={(e) => form.setFieldsValue({ variableType: e?.value })}
                  disabled={!editable}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>}
                name="isActive"
                initialValue={1}
              >
                <RadioGroup>
                  <Space size={[8, 8]} wrap>
                    <Radio value={1} disabled={!editable}>
                      {<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}
                    </Radio>
                    &nbsp;
                    <Radio value={0} disabled={!editable}>
                      {<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}
                    </Radio>
                  </Space>
                </RadioGroup>
              </Form.Item>
            </Col>
            <Col span={24}>
              <LabelNew type="body-header">{t('ระดับความเสี่ยงที่ใช้ประเมิน')}</LabelNew>
              <Datatable
                enableScroll={{ x: true, y: false }}
                columns={Columns({ editable, fncDelete, menu, form, formname, dataLists })}
                data={dataLists}
                pagination={false}
              />
            </Col>
            <Col span={24}>
              {editable && (
                <ButtonNew type="addMultiple" onClick={handlePressAdd}>
                  {t('เพิ่มข้อมูล')}
                </ButtonNew>
              )}
            </Col>
          </Row>
        </Form>
      </ModalNew>
    </>
  )
}
export default VariableModal
