import { gql } from '@apollo/client'

export const GETFORMSPAGINATION = gql`
  query getFormsPagination($input: FormsPaginationInput!) {
    getFormsPagination(input: $input) {
      result {
        no
        formId
        formCode
        formNameTH
        formNameEN
        formTypeCode
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
        formTypeNameTH
        formTypeNameEN
        formNameTHShow
        formNameENShow
        statusEdit
      }
      count
      page
      limit
    }
  }
`

export const GETMASTERFORMTYPESBYFORMS = gql`
  query getMasterFormTypesByForms($isActive: Float!) {
    getMasterFormTypesByForms(isActive: $isActive) {
      formTypeId
      formTypeCode
      formTypeNameTH
      formTypeNameEN
      isActive
    }
  }
`

export const GETFORMSONE = gql`
  query getFormsOne($input: FormsInput!) {
    getFormsOne(input: $input) {
      formId
      formCode
      formNameTH
      formNameEN
      formTypeCode
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
      statusEdit
    }
  }
`

export const GETFORMGROUPSBYFORMS = gql`
  query getFormGroupsByForms($input: FormsInput!) {
    getFormGroupsByForms(input: $input) {
      formGroupId
      formCode
      formSort
      formGroupNameTH
      formGroupNameEN
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`

export const GETFORMQUESTIONSBYFORMS = gql`
  query getFormQuestionsByForms($input: FormQuestionsInput!) {
    getFormQuestionsByForms(input: $input) {
      formQuestId
      formGroupId
      formSort
      formQuestNameTH
      formQuestNameEN
      choiceTypeCode
      choiceGroupCode
      formQuestType
      formRequired
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
      choiceTypeNameTH
      choiceTypeNameEN
      choiceGroupNameTH
      choiceGroupNameEN
      groupSort
    }
  }
`
