import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Form } from 'antd'
import { ModalNew } from '../../../../../components/modal/Modal'
import { InputNew } from '../../../../../components/input/Input'
import { LabelNew as Label, LabelNew } from '../../../../../components/label/Label'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'
import { GET_ORGANIZATION_LOG_BY_CODE } from '../../graphql/Query'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { IconNew } from '../../../../../components/icon/Icon'
import { Alert } from '../../../../../components/alert/Alert'
import { downloadFile } from '../../../../../utilitys/files'

const DetailModal = (props) => {
  const { dataParams } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [stateFiles, setStateFiles] = useState([])
  /* ---------------- API ----------- */

  const [getOrganizationLogByCodeFn, dataOrganizationLogByCode] = useLazyQuery(GET_ORGANIZATION_LOG_BY_CODE, {})

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    setVisible(props.open)
    if (dataParams) {
      getOrganizationLogByCodeFn({
        variables: {
          logfileOrgId: encryptInput(dataParams.logfileOrgId),
        },
      })
    }
  }, [dataParams, props.open])

  useEffect(() => {
    if (dataParams && dataOrganizationLogByCode.data) {
      const data = dataOrganizationLogByCode.data.getOrganizationLogByCode.organizationLog
      form.setFieldsValue({
        organizationNameTH: data.organizationNameTH,
        organizationNameEN: data.organizationNameEN,
        remark: data.remark,
      })

      const getFiles = dataOrganizationLogByCode.data.getOrganizationLogByCode.files
      setStateFiles(
        getFiles.map((item, key) => {
          return (
            <LabelNew
              key={key}
              style={{ cursor: 'pointer', paddingBottom: '12px' }}
              onClick={() => {
                downloadFile(item.fileNameLabel + item.fileType, item.fileNameGen + item.fileType, () => {})
              }}
            >
              <IconNew icon={'Download'} className="approve-downloads" size={16} /> {item.fileNameLabel + item.fileType}{' '}
            </LabelNew>
          )
        }),
      )
    }

    if (dataOrganizationLogByCode?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(dataOrganizationLogByCode?.error)),
        onOk() {},
      })
    }
  }, [dataOrganizationLogByCode.data, dataParams?.logfileOrgId])

  /* ---------------- ACTION ----------- */
  function onClose(e) {
    setVisible(e)
    props.close(e)
  }
  return (
    <>
      <ModalNew
        visible={visible}
        typeAction={dataParams?.type_action}
        testTitle={t('รายละเอียดการแก้ไขชื่อหน่วยงาน')}
        onClose={(e) => onClose(e)}
        onSubmit={form.submit}
        type="medium"
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Form
          form={form}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="organizationLogForm"
        >
          <Form.Item
            name="organizationNameTH"
            label={<Label type="tab-header-inactive">{t('ชื่อหน่วยงานภาษาไทย ')}</Label>}
          >
            <InputNew placeholder={t('ชื่อหน่วยงานภาษาไทย ')} disabled={true} />
          </Form.Item>
          <Form.Item
            name="organizationNameEN"
            label={<Label type="tab-header-inactive">{t('ชื่อหน่วยงานภาษาไทย ')}</Label>}
          >
            <InputNew placeholder={t('ชื่อหน่วยงานภาษาอังกฤษ ')} disabled={true} />
          </Form.Item>
          <Form.Item name="remark" label={<Label type="tab-header-inactive">{t('หมายเหตุ ')}</Label>}>
            <TextAreaNew rows={4} placeholder={t('ระบุหมายเหตุ')} disabled={true} />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('เอกสารแนบ')}</Label>}>{stateFiles}</Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
export default DetailModal
