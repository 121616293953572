/* eslint-disable no-unused-vars */
import styled, { css } from 'styled-components'

export const CardStyle = styled.div`
  margin-bottom: 0px;
  margin-top: 0px;
  .ant-card {
    width: 100%;
    .ant-card-head {
      border-bottom: none;
      .ant-card-head-wrapper {
        .ant-card-extra {
          padding: 10px 0px 0px 0px;
        }
      }
    }
  }

  .card-title-icon {
    float: left;
    margin-right: 5px;
  }

  .card-icon {
    display: block;
    float: right;
    background-color: ${(props) => props.color};
    text-align: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .ant-card-body {
    padding: 10px 24px 24px 24px;
  }
`

export const CardStyleF = styled(CardStyle)`
  .ant-card-body {
    padding: ${(props) => props.padding || '10px 24px 24px 24px'};
    padding-bottom: ${(props) => props.size.width < 992 && '0px'};
  }
`
