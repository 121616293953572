import React from 'react'
import { Row, Col } from 'antd'
import { Status } from '../../../components/status/Status'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'

const StatusIndex = () => {
  let code = `
    import { Status } from '../../../components/status/Status'
    export default function App() {
      return (<>
        <Status text="ใช้งาน"></Status>
      </>)
    }
  `

  return (
    <React.Fragment>
      <div className="site-layout-background">
        <Row>
          <Col span={2}>
            <Status text="ใช้งาน"></Status>
          </Col>
          <Col span={2}>
            <Status text="ไม่ใช้งาน"></Status>
          </Col>
        </Row>
        <CodeBlock code={code} />
      </div>
    </React.Fragment>
  )
}

export default StatusIndex
