import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Form, Row, Col } from 'antd'
import { ModalNew } from '../../../../../components/modal/Modal'
import { InputNew } from '../../../../../components/input/Input'
import { RadioGroup, Radio } from '../../../../../components/radio/Radio'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { Alert } from '../../../../../components/alert/Alert'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'
import { EXPORT_RESULT_ASSESSMENT } from '../../graphql/Query'

const ExportModal = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [disableAction, setDisableAction] = useState(false)

  const [exportResultAssessmentFn, exportResultAssessment] = useLazyQuery(EXPORT_RESULT_ASSESSMENT)

  useEffect(() => {
    setVisible(props.open)
  }, [props.open])

  useEffect(() => {
    if (exportResultAssessment?.data) {
      if (exportResultAssessment?.data?.exportResultAssessment?.message === 'succcess') {
        window.open(`${exportResultAssessment?.data?.exportResultAssessment?.fileUrl}`, '_blank')
      }
    }

    if (exportResultAssessment?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(exportResultAssessment?.error)),
        onOk() {},
      })
    }
  }, [exportResultAssessment?.data])

  const onFinish = (input) => {
    const obj = {
      formCode: props.dataExportModal?.formCode,
      fileType: input?.fileType,
      waterMark: input?.waterMark || '',
      lang: localStorage.getItem('lang'),
    }

    if (props?.dataExportModal?.type === 'mainResult') {
      delete obj.controlResultId
      exportResultAssessmentFn({ variables: { input: encryptInput(obj) } })
    }
  }

  const onClose = (e) => {
    form.resetFields()
    setVisible(e)
    props.close(e)
  }

  return (
    <>
      <ModalNew
        visible={visible}
        testTitle={t('พิมพ์รายงาน')}
        btnName={t('พิมพ์')}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
      >
        {exportResultAssessment?.loading && <SpinnersNew />}
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{ span: 8, offset: 2 }}
          wrapperCol={{ span: 10 }}
          layout="horizontal"
          name="resultAssessmentExportForm"
        >
          <Form.Item
            label={<Label type="tab-header-inactive">{t('ประเภทรายงาน')}</Label>}
            name="fileType"
            initialValue={'pdf'}
            onClick={(e) => {
              if (e.target.value) {
                if (e.target.value !== 'pdf') {
                  form.setFieldsValue({ waterMark: '' })
                  setDisableAction(true)
                } else {
                  setDisableAction(false)
                }
              }
            }}
          >
            <RadioGroup>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="pdf">
                    <Label type="tab-header-inactive">{t('PDF')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="word">
                    <Label type="tab-header-inactive">{t('Word')}</Label>
                  </Radio>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="excel">
                    <Label type="tab-header-inactive">{t('Excel')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="csv">
                    <Label type="tab-header-inactive">{t('CSV')}</Label>
                  </Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
          {!disableAction ? (
            <Form.Item name="waterMark" label={<Label type="tab-header-inactive">{t('ลายน้ำ')}</Label>}>
              <InputNew disabled={disableAction} maxLength={255} />
            </Form.Item>
          ) : (
            ''
          )}
        </Form>
      </ModalNew>
    </>
  )
}
export default ExportModal
