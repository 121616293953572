import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew as Card } from '../../../components/card/Card'
import { LabelNew as Label } from '../../../components/label/Label'
import { InputNew as Input } from '../../../components/input/Input'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { ButtonNew as Button } from '../../../components/button/Button'
import { Alert } from '../../../components/alert/Alert'
import AcResolutionDetailDataTable from './components/dataTable/AcResolutionDetailDataTable'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { encryptInput } from '../../../utilitys/crypto'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { displayText, displayError } from '../../../utilitys/helper'
import { SpinnersNew as Spinner } from '../../../components/spinners/Spinners'
import { GET_AUDIT_RESOLUTION_BY_ID } from './graphql/Query'
import { UPDATE_AUDIT_RESOLUTION } from './graphql/Mutation'

const AcResolutionDetail = ({ menu }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { detailPageAction, resId } = useLocation().state
  const isDisabled = ['add', 'edit'].includes(detailPageAction) ? false : true
  const [user] = useDecodeUser()
  const systemLanguage = localStorage.getItem('lang')

  const [acResolutionDetail, setAcResolutionDetail] = useState()

  const [getAuditResolutionById, getAuditResolutionByIdResult] = useLazyQuery(GET_AUDIT_RESOLUTION_BY_ID)
  const [updateAuditResolution, updateAuditResolutionResult] = useMutation(UPDATE_AUDIT_RESOLUTION)

  const arrCrumb = [
    { label: t('งานปฎิบัติการตรวจสอบ'), path: '/auditoperations/acresolution' },
    { label: t('บันทึกมติคณะกรรมการตรวจสอบ') },
  ]

  const [form] = Form.useForm()
  const formName = 'resolutionDetail'
  const ResponsiveValues = {
    sm: 24,
    xl: 12,
  }

  const onFinish = (values) => {
    const input = {
      ...values,
      location: values.location,
      resId: resId,
      userId: user.userId,
      systemLanguage: systemLanguage,
    }
    console.log('input: ', input)

    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อแก้ไขมติคณะกรรมการตรวจสอบ'),
      onOk: async () => {
        await updateAuditResolution({
          variables: {
            input: encryptInput(input),
          },
        })
          .then((response) => {
            if (response.data?.updateAuditResolution === true) {
              Alert({
                type: 'success',
                title: t('แก้ไขข้อมูลสำเร็จ'),
                onOk() {
                  navigate('/auditoperations/acresolution')
                },
              })
            }
          })
          .catch((error) =>
            Alert({
              type: 'error',
              title: t('ไม่สามารถแก้ไขข้อมูลได้'),
              content: t(error),
              onOk() {},
            }),
          )
      },
      onCancel() {
        return null
      },
    })
  }

  useEffect(() => {
    getAuditResolutionById({
      variables: {
        resId: encryptInput(resId),
      },
    })
  }, [])

  useEffect(() => {
    if (getAuditResolutionByIdResult.data) {
      const dataFromDb = getAuditResolutionByIdResult.data.getAuditResolutionById

      form.setFieldsValue({
        resTimes: dataFromDb.resTimes,
        resDate: dataFromDb.resDate,
        location: displayText(dataFromDb.locationTH, dataFromDb.locationEN),
      })

      setAcResolutionDetail(dataFromDb)
    }
  }, [getAuditResolutionByIdResult.data])

  useEffect(() => {
    if (updateAuditResolutionResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถแก้ไขข้อมูลได้'),
        content: t(displayError(updateAuditResolutionResult.error)),
        onOk() {},
      })
    }
  }, [updateAuditResolutionResult.error])

  return (
    <>
      {(getAuditResolutionByIdResult.loading || updateAuditResolutionResult.loading) && <Spinner />}
      <Breadcrumb data={arrCrumb} title={t('บันทึกมติคณะกรรมการตรวจสอบ')} />
      <Card topic={t('แก้ไขมติคณะกรรมการตรวจสอบ')} icon="Edit">
        <Row justify="center">
          <Col md={21}>
            <Form
              form={form}
              onFinish={onFinish}
              name={formName}
              labelAlign="left"
              labelCol={{ sm: { span: 8, offset: 0 }, xl: { span: 6, offset: 0 } }}
              wrapperCol={{ sm: { span: 16, offset: 0 }, xl: { span: 18, offset: 0 } }}
            >
              <Row gutter={[36, 12]}>
                <Col {...ResponsiveValues}>
                  <Form.Item
                    label={<Label type="tab-header-inactive">{t('ครั้งที่/ปี')}</Label>}
                    name="resTimes"
                    rules={[{ required: true, message: t('กรุณากรอกครั้งที่/ปี') }]}
                  >
                    <Input disabled={isDisabled} placeholder={t('ครั้งที่/ปี')} />
                  </Form.Item>
                </Col>
                <Col {...ResponsiveValues}>
                  <Form.Item
                    name="resDate"
                    label={<Label type="tab-header-inactive">{t('วันที่ประชุม')}</Label>}
                    rules={[{ required: true, message: t('กรุณากรอกวันที่ประชุม') }]}
                  >
                    <Datepicker
                      disabled={isDisabled}
                      setValueDateFn={(data) => {
                        form.setFieldsValue({ resDate: data })
                      }}
                      onClear={() => {
                        form.setFieldsValue({ resDate: null })
                      }}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col sm={24}>
                  <Form.Item
                    label={<Label type="tab-header-inactive">{t('สถานที่ประชุม')}</Label>}
                    name="location"
                    labelCol={{ sm: { span: 8, offset: 0 }, xl: { span: 3, offset: 0 } }}
                    wrapperCol={{ sm: { span: 16, offset: 0 }, xl: { span: 21, offset: 0 } }}
                  >
                    <Input disabled={isDisabled} placeholder={t('สถานที่ประชุม')} />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col sm={24}>
            <AcResolutionDetailDataTable
              menu={menu}
              isDisabled={isDisabled}
              detailPageAction={detailPageAction}
              resId={resId}
              acResolutionDetail={acResolutionDetail}
            />
          </Col>
        </Row>
        <Row gutter={[12, 12]} justify="center">
          {!isDisabled && (
            <Col>
              <Button type="primary" form={formName} roles={{ type: 'add', menu: menu }} htmlType="submit">
                {t('บันทึก')}
              </Button>
            </Col>
          )}

          <Col>
            <Button
              type="back"
              onClick={() => {
                navigate('/auditoperations/acresolution')
              }}
            >
              {t('ยกเลิก')}
            </Button>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default AcResolutionDetail
