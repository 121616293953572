import { Trans } from 'react-i18next'

export const columnList = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'no',
    // sorter: true,
    width: '10%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>กระบวนการ</Trans>,
    dataIndex: 'riskModelCode',
    sorter: true,
    width: '50%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActive',
    sorter: true,
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: '',
    dataIndex: 'options',
    // sorter: true,
    width: '20%',
    className: 'text-nowrap',
  },
]

export const colunmTab1 = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'no',
    // sorter: true,
    width: '10%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>รหัสหน่วยงาน</Trans>,
    dataIndex: 'organizationCode',
    // sorter: true,
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>หน่วยงาน</Trans>,
    dataIndex: 'organizationName',
    // sorter: true,
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ระดับหน่วยงาน</Trans>,
    dataIndex: 'organizationLevel',
    // sorter: true,
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ผู้รับผิดชอบ</Trans>,
    dataIndex: 'fullName',
    // sorter: true,
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: '',
    dataIndex: 'options',
    // sorter: true,
    width: '10%',
    className: 'text-nowrap',
  },
]
export const colunmTab2 = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'no',
    // sorter: true,
    width: '10%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ชื่อรายการคลังประเมินความเสี่ยง</Trans>,
    dataIndex: 'riskFactorName',
    // sorter: true,
    width: '50%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>น้ำหนัก</Trans>,
    dataIndex: 'weight',
    // sorter: true,
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActive',
    // sorter: true,
    width: '10%',
    className: 'text-nowrap',
  },
  {
    title: '',
    dataIndex: 'options',
    // sorter: true,
    width: '10%',
    className: 'text-nowrap',
  },
]
export const colunmTab3 = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'no',
    // sorter: true,
    width: '10%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ชื่อปัจจัยความเสี่ยงกระบวนการ</Trans>,
    dataIndex: 'riskFactorName',
    // sorter: true,
    width: '50%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActive',
    // sorter: true,
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: '',
    dataIndex: 'options',
    // sorter: true,
    width: '20%',
    className: 'text-nowrap',
  },
]

export const colunmOrganization = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'no',
    // sorter: true,
    width: '10%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>รหัสหน่วยงาน</Trans>,
    dataIndex: 'organizationCode',
    // sorter: true,
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>หน่วยงาน</Trans>,
    dataIndex: 'organizationName',
    // sorter: true,
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ระดับหน่วยงาน</Trans>,
    dataIndex: 'organizationLevelName',
    // sorter: true,
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ผู้รับผิดชอบ</Trans>,
    dataIndex: 'fullName',
    // sorter: true,
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: '',
    dataIndex: 'options',
    // sorter: true,
    width: '10%',
    className: 'text-nowrap',
  },
]
