import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GET_DATA_OVERVIEW_AUDIT_GRAPH_PERSONNEL_POSITION } from '../graphql/Query'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError, displayText } from '../../../../../utilitys/helper'
import { Alert } from '../../../../../components/alert/Alert'

export const useGetGraphPersonnelPosition = (options) => {
  const { filters } = options
  const { dateStart, dateEnd, organizationCode, positionCode } = filters
  const { t } = useTranslation()

  const [data, setData] = useState(null)

  const [getDataFn, getData] = useLazyQuery(GET_DATA_OVERVIEW_AUDIT_GRAPH_PERSONNEL_POSITION)

  useEffect(() => {
    if (dateStart && dateEnd && organizationCode) {
      const dataInput = {
        dateStart,
        dateEnd,
        organizationCode,
        positionCode
      }
      getDataFn({
        variables: { input: encryptInput(dataInput) }
      })
    }
  }, [dateStart, dateEnd, organizationCode, positionCode])

  useEffect(() => {
    if (getData.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getData.error)),
        onOk() {}
      })
    }
  }, [getData.error])

  useEffect(() => {
    if (getData?.data) {
      const target = getData?.data?.getOverviewAuditGraphPersonnelPosition
      setData(
        target.map((item) => ({
          ...item,
          level: displayText(item.level.nameTh, item.level.nameEn),
          type: displayText(item.type.nameTh, item.type.nameEn)
        }))
      )
    }
  }, [getData?.data])

  const refresh = () => getData.refetch()

  return [data, getData?.loading, refresh]
}
