import React from 'react'
import { Row, Col } from 'antd'
import AcknowledgeIssueList from './AcknowledgeIssueList'
/**
 * @function AcknowledgeIssueIndex
 * @description
 * Menu: Internal Control
 * เมนู: รับทราบประเด็น
 * @returns Component
 */

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}

const AcknowledgeIssueIndex = (props) => {
  const { menu } = props
  return (
    <>
      <Row>
        <Col {...Responsive}>
          <AcknowledgeIssueList menu={menu} />
        </Col>
      </Row>
    </>
  )
}

export default AcknowledgeIssueIndex
