import React, { useState } from 'react'
import { Row, Col, Form } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { getSegmentUrl } from '../../../utilitys/helper'
import { CardNew } from '../../../components/card/Card.jsx'
import FilterAuditProjectsList from './FilterAuditProjectsList.jsx'
import { TabNew } from '../../../components/tab/Tab.jsx'
import AuditProject from './AuditProject'
import AuditProjectList from './AuditProjectList'

const AuditProjectsIndex = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = getSegmentUrl(3)
  const [formFilter] = Form.useForm()
  const [searchStatus, setSearchStatus] = useState(0)
  const breadcrumbList = [
    { label: t('งานวางแผนการตรวจสอบ'), path: '/auditplanning/auditplan' },
    { label: t('จัดการข้อมูลโครงการนอกแผน') },
  ]

  const tabs = [
    {
      key: 1,
      iconTab: () => <></>,
      name: t('ตารางข้อมูลโครงการ'),
      disabled: false,
      content: () => (
        <>
          {params !== 'list' ? (
            <AuditProject
              menu={props.menu}
              formFilter={formFilter}
              setSearchStatus={setSearchStatus}
              searchStatus={searchStatus}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      key: 2,
      iconTab: () => <></>,
      name: t('จัดการข้อมูลโครงการ'),
      disabled: false,
      content: () => (
        <>
          {params === 'list' ? (
            <AuditProjectList
              formFilter={formFilter}
              setSearchStatus={setSearchStatus}
              searchStatus={searchStatus}
              menu={props.menu}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
  ]
  return (
    <>
      <Row>
        <Col md={24}>
          <Breadcrumb data={breadcrumbList} title={t('จัดการข้อมูลโครงการนอกแผน')} menu={props.menu} />
          <FilterAuditProjectsList
            topic={''}
            formFilter={formFilter}
            setSearchStatus={setSearchStatus}
            searchStatus={searchStatus}
            menu={props.menu}
          />
          <CardNew topic={t('ข้อมูลโครงการนอกแผน')} icon="AlignJustify" toggledrop="false">
            <TabNew
              tab={tabs}
              activeKey={params !== 'list' ? '1' : '2'}
              onTabClick={(key) => {
                if (key === '1') {
                  navigate('/auditplanning/offplanprojects/')
                } else if (key === '2') {
                  navigate('/auditplanning/offplanprojects/list')
                }
              }}
            />
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default AuditProjectsIndex
