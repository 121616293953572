import { Trans } from 'react-i18next'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'

export const Columns = ({ fncOnpressDelete, fncOnpressView, fncOnpressEdit, mainFormType, menu }) => {
  return [
    {
      title: (
        <div style={{ textAlign: 'start' }}>
          <Trans>ปัจจัยความเสี่ยงกระบวนการ</Trans>
        </div>
      ),
      dataIndex: 'rateFacProName',
      sorter: true,
      width: '30%',
      render: (item, record) => {
        if (record.show) {
          return (
            <div>
              {record.rateFacProCode} : {record.rateFacProName}
            </div>
          )
        } else {
          return <></>
        }
      },
    },
    {
      title: <Trans>ความเสี่ยง</Trans>,
      dataIndex: 'riskFactorName',
      sorter: true,
      width: '40%',
      render: (item, record) => {
        return (
          <div>
            {record.riskFactorCode} : {record.riskFactorName}
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '15%',
      render: (item, record) => {
        const icons = [
          {
            type: 'view',
            onClick: () => {
              fncOnpressView(record)
            },
          },
        ]
        if (mainFormType !== 'view') {
          icons.push({
            type: 'edit',
            onClick: () => {
              fncOnpressEdit(record)
            },
          })
          icons.push({
            type: 'delete',
            onClick: () => {
              fncOnpressDelete(record)
            },
          })
        }
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} icons={icons} />
          </div>
        )
      },
    },
  ]
}
