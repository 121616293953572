import { Result, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export function Page403() {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Result
      status="403"
      title="403"
      subTitle={t('Sorry, you are not authorized to access this page.')}
      extra={
        <Button type="primary" onClick={() => navigate('/')}>
          {t('กลับหน้าหลัก')}
        </Button>
      }
    />
  )
}
