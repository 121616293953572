import styled from 'styled-components'

export const MainTable = styled.div`
  .ant-table-thead .ant-table-cell {
    background-color: #d5e4f2;
  }

  .ant-table-tbody td {
    background-color: #f2f7fc;
  }

  .ant-table-tbody tr.ant-table-expanded-row > td {
    margin: 0;
    padding: 0;
  }

  .ant-table-tbody tr.ant-table-expanded-row:hover > td {
    margin: 0;
    padding: 0;
    background-color: none !important;
  }
`

export const SubTable = styled.div`
  .ant-table-content {
    display: flex;
    justify-content: center;
  }

  .ant-table table {
    min-width: 90% !important;
    table-layout: auto;
  }

  .table-row-color {
    background-color: none !important;
  }

  .ant-table-thead .ant-table-cell {
    background-color: unset !important;
  }

  .ant-table-tbody td {
    background-color: unset !important;
  }

  .ant-table-tbody tr.ant-table-expanded-row > td {
    background-color: #992e2e !important;
  }

  .ant-table-tbody tr.ant-table-expanded-row:hover > td {
    background-color: #992e2e !important;
  }

  .search-table-wrapper {
    margin: 0 !important;
  }
`
