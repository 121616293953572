import { gql } from '@apollo/client'
export const GET_SELECT_RISK_FORMULAS = gql`
  query GetSelectRiskFormulas {
    getSelectRiskFormulas {
      riskFormulaCode
      riskFormulaNameTH
      riskFormulaNameEN
      isActive
    }
  }
`
