export const generateCurrentYearOptions = (langUse) => {
  const currentYear = new Date().getFullYear() - 10
  let res = []
  if (langUse === 'th') {
    const yearTh = currentYear + 543
    for (var i = 0; i < 20; i++) {
      res.push({ value: currentYear + i, label: yearTh + i })
    }
  } else {
    for (var a = 0; a < 20; i++) {
      res.push({ value: currentYear + a, label: currentYear + a })
    }
  }
  return res
}

export const convertDate2FullDate = (val) => {
  var monthNamesThai = [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฎาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤษจิกายน',
    'ธันวาคม',
  ]
  var monthNamesEng = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  var date = new Date(val)
  if (localStorage.getItem('lang') === 'th') {
    return date.getDate() + ' ' + monthNamesThai[date.getMonth()] + ' ' + (date.getFullYear() + 543)
  } else {
    return date.getDate() + ' ' + monthNamesEng[date.getMonth()] + ' ' + date.getFullYear()
  }
}

export const checkLanguageReturnData = (isLang, valueTH, valueEN) => {
  if (isLang === 'th') {
    return valueTH
  } else {
    if (valueEN === undefined || valueEN === null) {
      return valueTH
    } else {
      return valueEN
    }
  }
}
