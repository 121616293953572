import { gql } from '@apollo/client'
export const GET_SELECT_RISK_FACTORS = gql`
  query GetSelectRiskFactors($input: SelectRiskFactorsInput!) {
    getSelectRiskFactors(input: $input) {
      riskFactorId
      riskFactorCode
      riskFactorNameTH
      riskFactorNameEN
      riskType
      riskFormulaCode
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
      factorActCode
      factorActNameTH
      factorActNameEN
      factorActRiskCode
      factorActRiskNameTH
      factorActRiskNameEN
      remark
    }
  }
`
