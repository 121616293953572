import { Trans, useTranslation } from 'react-i18next'

export const pageTitle = 'ภาพรวมการดำเนินงานตาม SLA'

export const breadcrumbData = () => {
  const { t } = useTranslation()
  return [{ label: t('แดชบอร์ด'), path: '/dashboard' }, { label: t(pageTitle) }]
}

export const Columns = () => {
  return [
    {
      title: <Trans>{'ลำดับ'}</Trans>,
      dataIndex: 'no',
      width: '100px',
      align: 'center',
      fixed: 'left',
      sorter: true
    },
    {
      title: <Trans>{'โครงการ'}</Trans>,
      dataIndex: 'projectName',
      width: '200px',
      align: 'left',
      fixed: 'left',
      sorter: true
    },
    {
      title: <Trans>{'สถานะ'}</Trans>,
      dataIndex: 'projectStatusName',
      width: '150px',
      align: 'left',
      sorter: true
    },
    {
      title: <Trans>{'สถานะ RCM'}</Trans>,
      dataIndex: 'operationStatusName',
      width: '150px',
      align: 'left',
      sorter: true
    },
    {
      title: <Trans>{'สถานะเปิดตรวจ'}</Trans>,
      dataIndex: 'openDate',
      width: '100px',
      align: 'left',
      sorter: true
    },
    {
      title: <Trans>{'สถานะบันทึกผลการตรวจสอบ'}</Trans>,
      dataIndex: 'createdAt',
      width: '120px',
      align: 'left',
      sorter: true
    },
    {
      title: <Trans>{'Exit Meeting'}</Trans>,
      dataIndex: 'dateExit',
      width: '100px',
      align: 'left',
      sorter: true
    },
    {
      title: <Trans>{'นำเสนอ AC'}</Trans>,
      dataIndex: 'meetingDate',
      width: '100px',
      align: 'left',
      sorter: true
    },
    {
      title: <Trans>{'วันที่เข้า MC'}</Trans>,
      dataIndex: 'dateEntryMC',
      width: '100px',
      align: 'left',
      sorter: true
    },
    {
      title: <Trans>{'วันที่สั่งการ'}</Trans>,
      dataIndex: 'dateSendReport',
      width: '100px',
      align: 'left',
      sorter: true
    }
  ]
}
