import React, { useState, useEffect } from 'react'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { Row, Col, Form, Space } from 'antd'
import { InputNew } from '../../../../../components/input/Input'
import { Radio, RadioGroup } from '../../../../../components/radio/Radio'
import { useTranslation } from 'react-i18next'
import { ButtonNew as Button } from '../../../../../components/button/Button'
import { useNavigate, useLocation } from 'react-router-dom'
// import { CardNew as Card } from '../../../../../components/card/Card'
import { Datatable } from '../../../../../components/datatable/Datatable'
import {
  GET_APPROVE_PLAN_AUDIT_ORGANIZATION_PAGINATIONS,
  GET_APPROVE_AUDIT_PROJECT_GENERAL_INFO,
} from '../../graphql/Query'
// import { useLocation } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { useLanguage } from '../../../../../hooks/useLanguage'
import * as _ from 'lodash'
// import { decryptData } from '../../../../../utilitys/crypto'
import { encryptInput } from '../../../../../utilitys/crypto' //encryptData
import { checkLanguageReturnData } from '../../component/fnForApprove'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError, displayText } from '../../../../../utilitys/helper'

const Responsive = {
  md: 24,
  lg: 24,
}

export default function ApprovePlanProjectInformations() {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLang] = useLanguage()
  // const state = JSON.parse(decryptData(localStorage.getItem('pageApproveState')))
  // const state = props.state
  const { state } = useLocation()

  const column = [
    {
      title: t('ลำดับ'),
      dataIndex: 'no',
      align: 'center',
    },
    {
      title: t('รหัสหน่วยงาน'),
      dataIndex: 'organizationCode',
      align: 'left',
    },
    {
      title: t('หน่วยงาน'),
      dataIndex: 'organizationName',
      align: 'left',
    },
    {
      title: t('ระดับหน่วยงาน'),
      dataIndex: 'organizationLevelName',
      align: 'left',
    },
    {
      title: t('ผู้รับผิดชอบ'),
      dataIndex: 'fullName',
      align: 'left',
    },
  ]

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(4)
  const [count, setCount] = useState(0)
  const [dataList, setDataList] = useState([])

  const [getApproveAuditProjectGeneralInfoFn, getApproveAuditProjectGeneralInfo] = useLazyQuery(
    GET_APPROVE_AUDIT_PROJECT_GENERAL_INFO,
  )

  const [getApproveAuditProjectOrganizationFn, getApproveAuditProjectOrganization] = useLazyQuery(
    GET_APPROVE_PLAN_AUDIT_ORGANIZATION_PAGINATIONS,
    {},
  )

  useEffect(() => {
    getApproveAuditProjectOrganizationFn({
      fetchPolicy: 'no-cache',
      variables: {
        inputData: {
          filters: encryptInput({ projectCode: state.projectCode }),
          pagination: encryptInput({ limit, page }),
          sort: [],
        },
      },
    })
  }, [getApproveAuditProjectOrganizationFn, page, limit])

  useEffect(() => {
    if (getApproveAuditProjectOrganization?.data) {
      let response = getApproveAuditProjectOrganization?.data?.getApprovePlanAuditOrganizationPagination

      setCount(response?.count)
      setDataList(
        _.map(response?.result, (dt, keys) => {
          return {
            key: keys,
            no: dt.RowData,
            organizationCode: dt.organizationCode,
            organizationName: checkLanguageReturnData(isLang, dt.organizationNameTH, dt.organizationNameEN),
            organizationLevelName: checkLanguageReturnData(
              isLang,
              dt.organizationLevelNameTH,
              dt.organizationLevelNameEN,
            ),
            fullName: checkLanguageReturnData(isLang, dt.fullNameTH, dt.fullNameEN),
          }
        }),
      )
    }
    if (getApproveAuditProjectOrganization.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getApproveAuditProjectOrganization.error)),
        onOk() {},
      })
    }
  }, [getApproveAuditProjectOrganization?.data])

  useEffect(() => {
    if (getApproveAuditProjectGeneralInfo.data) {
      const dt = getApproveAuditProjectGeneralInfo.data.getApproveAuditProjectGeneralInfo

      const formData = {
        planName: dt.planCode ? dt.planCode + ' : ' + displayText(dt.planNameTH, dt.planNameEN) : '',
        projectCoce: dt.projectCode,
        projectNameTH: displayText(dt.projectNameTH, dt.projectNameEN),
        projectType:
          dt.projectTypeCode === undefined
            ? ''
            : dt.projectTypeCode + ' : ' + displayText(dt.projectNameTH, dt.projectNameEN),
        projectRef:
          dt.projectRefCode === null || dt.projectRefCode === undefined
            ? ''
            : dt.projectRefCode + ' : ' + displayText(dt.projectRefNameTH, dt.projectRefNameEN),
        auditType: displayText(dt.auditTypeNameTH, dt.auditTypeNameEN),
        riskForm: displayText(dt.riskFormTH, dt.riskFormEN),
        projectApprove: displayText(dt.approveStatusNameTH, dt.approveStatusNameEN),
        projectStatus: displayText(dt.projectStatusNameTH, dt.projectStatusNameEN),
        isActive: dt.isActive,
      }

      form.setFieldsValue(formData)
    }
    if (getApproveAuditProjectGeneralInfo.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getApproveAuditProjectGeneralInfo.error)),
        onOk() {},
      })
    }
  }, [getApproveAuditProjectGeneralInfo.data])

  useEffect(() => {
    getApproveAuditProjectGeneralInfoFn({
      variables: {
        inputData: encryptInput({ planCode: state.refCode, projectCode: state.projectCode }),
      },
    })
  }, [])

  //

  return (
    <>
      {(getApproveAuditProjectGeneralInfo.loading || getApproveAuditProjectOrganization.loading) && <SpinnersNew />}
      <Label type="card-title" icon="Plus">
        {t('รายละเอียดโครงการ')}
      </Label>
      <Form
        form={form}
        autoComplete="off"
        labelAlign="left"
        labelCol={{
          sm: 8,
          md: 8,
          lg: 8,
          xl: 8,
        }}
        wrapperCol={{
          sm: 16,
          md: 16,
          lg: 24,
          xl: 16,
          align: 'left',
        }}
        layout="horizontal"
        name="projectdetail_form"
      >
        <Label type="body-header">{t('รายละเอียดโครงการ')}</Label>
        <Row style={{ marginTop: 24 }} align="center">
          <Col md={24} lg={16}>
            <Form.Item name="planName" label={t('แผนการตรวจสอบ')} placeholder={t('ระบุแผนการตรวจสอบ')}>
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item
              name="projectCoce"
              label={t('รหัสโครงการ')}
              placeholder={t('ระบุรหัสโครงการ')}
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item
              name="projectNameTH"
              label={t('ชื่อโครงการภาษาไทย')}
              placeholder={t('ระบุชื่อโครงการภาษาไทย')}
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item name="projectType" label={t('ประเภทโครงการ')} placeholder="ระบุประเภทโครงการ">
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item
              name="projectRef"
              label={t('แหล่งที่มาของโครงการ')}
              placeholder={t('ระบุแหล่งที่มาของโครงการ')}
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item name="auditType" label={t('ประเภทการตรวจสอบ')} placeholder={t('ระบุประเภทการตรวจสอบ')}>
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item name="riskForm" label={t('แบบประเมินความเสี่ยง')} placeholder={t('ระบุแบบประเมินความเสี่ยง')}>
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item name="projectApprove" label={t('สถานะอนุมัติ')} placeholder="ระบุสถานะอนุมัติ">
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item name="projectStatus" label={t('สถานะการดำเนินงาน')} placeholder="ระบุสถานะการดำเนินงาน">
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item label={t('สถานะ')} name="isActive" valuePropName="checked">
              <RadioGroup disabled={true} value="1">
                <Space size={[8, 8]} wrap>
                  <Radio value="1">{t('ใช้งาน')}</Radio>
                  &nbsp;
                  <Radio value="2">{t('ไม่ใช้งาน')}</Radio>
                </Space>
              </RadioGroup>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Label type="card-title" icon="List">
        {t('หน่วยงานรับตรวจ')}
      </Label>
      <Row gutter={[16, 16]}>
        <Col {...Responsive}>
          <Datatable
            columns={column}
            data={[...dataList]}
            total={count}
            isScoll={{ x: true, y: true }}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            paginationCustom={true}
            searchCustom={true}
            pageSize={limit}
            page={page}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 24, marginBottom: 24 }} align="center">
        <Col md={3}>
          <Button
            type="back"
            onClick={() => {
              navigate('/auditplanning/approveauditplan/detail/', {
                state: state,
              })
            }}
          >
            {t('ย้อนกลับ')}
          </Button>
        </Col>
      </Row>
    </>
  )
}
