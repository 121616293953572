export const checkLanguageReturnData = (valueTH, valueEN) => {
  let isLang = localStorage.getItem('lang')
  if (isLang === 'th') {
    return valueTH
  } else {
    if (valueEN === undefined || valueEN === null) {
      return valueTH
    } else {
      return valueEN
    }
  }
}
