import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_STATUS } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectJobStatus = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { isActive, jobStatusCode } = filters || {}

  const [isData, isSetData] = useState([])

  const [getMasterJobStatusFn, getMasterJobStatus] = useLazyQuery(GET_MASTER_STATUS)

  useEffect(() => {
    getMasterJobStatusFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [isActive, jobStatusCode])

  useEffect(() => {
    if (getMasterJobStatus.data) {
      isSetData(
        _.map(getMasterJobStatus?.data?.getMasterJobStatus, (item) => {
          return {
            label: displayText(item?.jobStatusNameTH, item?.jobStatusNameEN),
            value: item?.jobStatusCode,
            source: item,
          }
        }),
      )
    }
  }, [getMasterJobStatus.data])
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterJobStatus.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectJobStatus
