import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../../../components/breadcrumb/Breadcrumb'
import { CardNew as Card } from '../../../../../components/card/Card'
import { getSegmentUrl } from '../../../../../utilitys/helper'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import SelectOpenDocType from '../../../../../components/inputfromapi/selectopendoctype/SelectOpenDocType'
import { LabelNew as Label } from '../../../../../components/label/Label'
import SelectAuditOpenOrganizations from '../../../../../components/inputfromapi/selectauditopenorganizations/SelectAuditOpenOrganizations'
import { TextAreaNew as TextArea } from '../../../../../components/textarea/TextArea'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'
import { Alert } from '../../../../../components/alert/Alert'
import { ButtonNew as Button } from '../../../../../components/button/Button'
import FormAuditOpensAdddocMultiRow from './FormAuditOpensAdddocMultiRow.jsx'
import { displayText } from '../../../../../utilitys/helper'

import { SAVE_AUDITOPENS_ADDDOC } from '../../graphql/Mutation'
import { DATA_AUDIT_OPENS_ADDDOC_TAB_FORM } from '../../graphql/Query'

import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { useMutation, useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'

const FormAuditOpensAdddoc = (props) => {
  const typeMain = getSegmentUrl(4)
  const type = getSegmentUrl(6)
  const { state } = useLocation()
  if (!state) return <Navigate to={'/auditoperations/auditopens'} />
  if (!(type !== 'add' ? state.refCode.split('_').length === 2 : true))
    return <Navigate to={'/auditoperations/auditopens'} />
  const openId = type === 'add' ? state.refCode : state.refCode.split('_')[0]
  const openDocId = type === 'add' ? '' : state.refCode.split('_')[1]
  const { t } = useTranslation()
  const navigate = useNavigate()
  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ') },
    { label: t('บันทึกเปิดตรวจ') },
    { label: t('รายละเอียดบันทึกเปิดตรวจ') },
  ]
  const [form] = Form.useForm()
  const [filesEdit, setFilesEdit] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])

  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }
  const validDate = () => {
    let valid = false
    if (form.getFieldValue('dateStart') && form.getFieldValue('dateEnd')) {
      let dateStart = new Date(form.getFieldValue('dateStart'))
      let dateEnd = new Date(form.getFieldValue('dateEnd'))
      if (dateStart <= dateEnd) {
        setErrorField('dateStart', false)
        setErrorField('dateEnd', false)
        valid = true
      } else {
        setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นน้อยกว่าวันที่สิ้นสุด'))
        setErrorField('dateEnd', t('กรุณาเลือกวันที่สิ้นสุดมากกว่าวันที่เริ่มต้น'))
      }
    } else {
      valid = true
    }
    return valid
  }
  const showAlertSuccess = () => {
    Alert({
      type: 'success',
      title: t('บันทึกสำเร็จ'),
      onOk() {
        if (type === 'add') {
          navigate('/auditoperations/auditopens/adddoc/edit', {
            state: { refCode: openId },
          })
        }
      },
    })
  }
  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const [dataAuditOpensAddDocTabFormFn, dataAuditOpensAddDocTabForm] = useLazyQuery(DATA_AUDIT_OPENS_ADDDOC_TAB_FORM)
  useEffect(() => {
    if (dataAuditOpensAddDocTabForm?.data) {
      let response = dataAuditOpensAddDocTabForm?.data?.dataAuditOpensAddDocTabForm
      if (response) {
        form.setFieldsValue({
          openDocType: response.openDocType,
          organizationCode: response.organizationCode,
          openDocDetail: displayText(response.openDocDetailTH, response.openDocDetailEN),
          dateStart: response.dateStart,
          dateEnd: response.dateEnd,
        })
        let fileDetail = JSON.parse(response.fileDetail)
          ? JSON.parse(response.fileDetail).map((item) => {
              let fileDetail2 = response.refFile.find((subItem) => {
                return item.fileId === subItem.fileId
              })
              return {
                ...item,
                originalname: fileDetail2 ? fileDetail2.originalname : '',
                name: fileDetail2 ? fileDetail2.name : '',
              }
            })
          : []
        setFilesEdit(fileDetail)
      }
    }
    if (dataAuditOpensAddDocTabForm.error) {
      showAlertLoadDataError(displayError(dataAuditOpensAddDocTabForm.error))
    }
  }, [dataAuditOpensAddDocTabForm.data])
  const [auditOpensAdddocSaveFn, auditOpensAdddocSave] = useMutation(SAVE_AUDITOPENS_ADDDOC)
  useEffect(() => {
    if (auditOpensAdddocSave?.data) {
      let response = auditOpensAdddocSave?.data?.auditOpensAdddocSave
      if (response) {
        showAlertSuccess()
      }
    }
    if (auditOpensAdddocSave.error) {
      showAlertError(displayError(auditOpensAdddocSave.error))
    }
  }, [auditOpensAdddocSave.data])

  const onFinish = (values) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        const valid = validDate()
        if (valid) {
          let { openDocType, organizationCode, openDocDetail, dateStart, dateEnd } = values
          delete values['openDocType']
          delete values['organizationCode']
          delete values['openDocDetail']
          delete values['dateStart']
          delete values['dateEnd']
          let fileInfor = Object.values(values)
          let files = fileInfor
            .filter((item) => {
              return item.fileId !== null
            })
            .map((item) => {
              return item.fileId
            })
            .join()
          let fileDetail = JSON.stringify(fileInfor)
          let input = {
            openId: parseInt(openId),
            typeAction: type,
            lang: localStorage.getItem('lang'),
            files: files,
            fileDetail: fileDetail,
            idFileDelete: idFileDelete.join(),
            openDocType: openDocType,
            organizationCode: organizationCode,
            openDocDetail: openDocDetail,
            dateStart: dateStart,
            dateEnd: dateEnd,
          }
          if (type !== 'add') {
            input['openDocId'] = parseInt(openDocId)
          }
          auditOpensAdddocSaveFn({
            variables: {
              input: encryptInput(input),
            },
            fetchPolicy: 'no-cache',
          })
        }
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    if (type !== 'add') {
      dataAuditOpensAddDocTabFormFn({
        variables: {
          openDocId: encryptInput(openDocId),
        },
        fetchPolicy: 'no-cache',
      })
    }
  }, [])
  return (
    <>
      {(auditOpensAdddocSave.loading || dataAuditOpensAddDocTabForm.loading) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Breadcrumb data={breadcrumbList} title={t('บันทึกเปิดตรวจ')} menu={props.menu} />
          <Card
            topic={t('คำขอเอกสารเพิ่มเติม')}
            icon={type === 'add' ? 'Plus' : type === 'edit' ? 'Edit2' : 'FileText'}
            toggledrop="false"
          >
            <Form
              form={form}
              autoComplete="off"
              labelAlign="left"
              layout="horizontal"
              name="auditOpensAddDoc"
              onFinish={onFinish}
              labelCol={{
                sm: 24,
                md: 4,
              }}
              wrapperCol={{
                sm: 24,
                md: 20,
              }}
            >
              <Label type="body-header">{t('รายละเอียดทั่วไป')}</Label>
              <Row justify="center">
                <Col md={18}>
                  <Form.Item
                    initialValue={1}
                    name="openDocType"
                    label={<Label type="tab-header-inactive">{t('ประเภทการขอเอกสาร')}</Label>}
                    rules={[{ required: true, message: t('กรุณาเลือกประเภทการขอเอกสาร') }]}
                  >
                    <SelectOpenDocType
                      disabled={type === 'view' ? true : false}
                      filters={{ isActive: 1 }}
                      formname="auditOpensAddDoc"
                      placeholder={t('เลือกประเภทการขอเอกสาร')}
                      handleChange={(value) => {
                        form.setFieldsValue({
                          openDocType: value ? value.value : null,
                        })
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="organizationCode"
                    label={<Label type="tab-header-inactive">{t('หน่วยรับตรวจ')}</Label>}
                    rules={[{ required: true, message: t('กรุณาเลือกหน่วยรับตรวจ') }]}
                  >
                    <SelectAuditOpenOrganizations
                      disabled={type === 'view' ? true : false}
                      filters={{ openId: openId }}
                      formname="auditOpensAddDoc"
                      placeholder={t('เลือกหน่วยรับตรวจ')}
                      handleChange={(value) => {
                        form.setFieldsValue({
                          organizationCode: value ? value.value : null,
                        })
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<Label type="tab-header-inactive">{t('รายละเอียด')}</Label>}
                    name="openDocDetail"
                    rules={[{ required: true, message: t('กรุณาระบุรายละเอียด') }]}
                  >
                    <TextArea placeholder={t('ระบุรายละเอียด')} disabled={type === 'view' ? true : false} />
                  </Form.Item>
                  <Form.Item
                    label={<Label type="tab-header-inactive">{t('วันที่เริ่มต้น')}</Label>}
                    name="dateStart"
                    rules={[{ required: true, message: t('กรุณาเลือกวันที่เริ่มต้น') }]}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        form.setFieldsValue({
                          dateStart: await data,
                        })
                        validDate()
                      }}
                      disabled={type === 'view' ? true : false}
                      onClear={() => {
                        form.setFieldsValue({ dateStart: null })
                      }}
                      placeholder={t('เลือกวันที่เริ่มต้น')}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<Label type="tab-header-inactive">{t('วันที่สิ้นสุด')}</Label>}
                    name="dateEnd"
                    rules={[{ required: true, message: t('กรุณาเลือกวันที่สิ้นสุด') }]}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        form.setFieldsValue({
                          dateEnd: await data,
                        })
                        validDate()
                      }}
                      disabled={type === 'view' ? true : false}
                      onClear={() => {
                        form.setFieldsValue({ dateEnd: null })
                      }}
                      placeholder={t('เลือกวันที่สิ้นสุด')}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Label type="body-header">{t('แนบเอกสาร')}</Label>
              <FormAuditOpensAdddocMultiRow
                form={form}
                typeForm={type}
                menu={props.menu}
                idFileDelete={idFileDelete}
                files={filesEdit}
                setIdFileDelete={setIdFileDelete}
              />
              <Row gutter={[12, 12]} justify="center" style={{ marginTop: '50px' }}>
                {type !== 'view' && (
                  <Col>
                    <Button type="primary" roles={{ type: typeMain, menu: props.menu }} htmlType="submit">
                      {t('บันทึก')}
                    </Button>
                  </Col>
                )}
                <Col>
                  <Button
                    type="cancel"
                    onClick={() => {
                      navigate('/auditoperations/auditopens/adddoc/' + typeMain, {
                        state: { refCode: openId },
                      })
                    }}
                  >
                    {t('ยกเลิก')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FormAuditOpensAdddoc
