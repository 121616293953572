import { gql } from '@apollo/client'

export const SAVE_OPERATION_SETUP = gql`
  mutation SaveOperationSetup($input: OperationSetupSaveInput!) {
    saveOperationSetup(input: $input) {
      stepModelCode
    }
  }
`

export const SAVE_OPERATION_SETUP_DETAIL = gql`
  mutation SaveOperationSetupSaveDetail($input: OperationSetupSaveDetailInput!) {
    saveOperationSetupSaveDetail(input: $input) {
      stepDetailId
    }
  }
`

export const DELETE_OPERATION_SETUP_DETAIL = gql`
  mutation DeleteOperationSetupDetail($input: OperationSetupDetailDeleteInput!) {
    deleteOperationSetupDetail(input: $input) {
      message
    }
  }
`

export const DELETE_OPERATION_SETUP = gql`
  mutation DeleteOperationSetup($input: OperationSetupDeleteInput!) {
    deleteOperationSetup(input: $input) {
      message
    }
  }
`
