import { gql } from '@apollo/client'

export const GET_MASTER_IDEA_STATUS = gql`
  query getMasterIdeaStatus($input: MasterIdeaStatusInput!) {
    getMasterIdeaStatus(data: $input) {
      ideaStatusId
      ideaStatusCode
      ideaStatusNameTH
      ideaStatusNameEN
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`
