import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { MainTable, SubTable } from './css/Styles'
import { v4 as uuidv4 } from 'uuid'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../../components/card/Card.jsx'
import { Datatable } from '../../../../components/datatable/Datatable'
import { ButtonNew } from '../../../../components/button/Button'
import { IconNew } from '../../../../components/icon/Icon'
import { DATA_FOLLOW_UP_ACTIVITY_LIST_PAGINATION } from '../graphql/Query'
import { DELETE_ACTION_EXPLAIN } from '../graphql/Mutation'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayError } from '../../../../utilitys/helper'
import { displayText } from '../../../../utilitys/helper'
import { dateDisplay } from '../../../../utilitys/dateHelper'
import { Alert } from '../../../../components/alert/Alert'
import { Status } from '../../../../components/status/Status'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { useDecodeUser } from '../../../../hooks/useDecodeUser'

const FormActionList = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [user] = useDecodeUser()
  const { state } = useLocation()

  if (!state) return <Navigate to={'/followup/actionexplain/formactionlist'} />

  const breadcrumbList = [
    { label: t('งานติดตามผลการตรวจสอบ'), path: '/followup/actionexplain' },
    { label: t('ดำเนินการชี้แจง') },
    { label: t('รายการข้อเสนอแนะ') },
  ]

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [orgCode, setOrgCode] = useState(null)
  const [disabled, setDisabled] = useState(false)
  //
  const [dataFollowUpActivityListPaginationFn, dataFollowUpActivityListPagination] = useLazyQuery(
    DATA_FOLLOW_UP_ACTIVITY_LIST_PAGINATION,
  )

  const [deleteActionExplainFn, deleteActionExplain] = useMutation(DELETE_ACTION_EXPLAIN)

  useEffect(() => {
    if (state?.actionType === 'view') setDisabled(true)
  }, [state?.actionType])

  useEffect(() => {
    if (user?.orgCode) setOrgCode(user?.orgCode)
  }, [user?.orgCode])

  useEffect(() => {
    if (orgCode && state?.projectCode) {
      dataFollowUpActivityListPaginationFn({
        variables: {
          input: encryptInput({
            filters: {
              projectCode: state.projectCode,
              organizationCode: orgCode,
            },
            pagination: { limit, page },
            sort: [],
          }),
        },
      })
    }
  }, [page, limit, orgCode, state?.projectCode])

  useEffect(() => {
    if (dataFollowUpActivityListPagination.data) {
      let response = dataFollowUpActivityListPagination?.data?.dataFollowUpActivityListPagination

      if (response) {
        setCount(response.count)
        if (response.result) {
          setDataLists(
            response.result.map((item) => {
              return {
                key: item.issueId,
                issue: displayText(item.issueTH, item.issueEN),
                issueStatusName:
                  item.issueStatusNameTH || item.issueStatusNameTH ? (
                    <Status text={displayText(item.issueStatusNameTH, item.issueStatusNameEN)} />
                  ) : (
                    ''
                  ),
                description: (
                  <SubTable>
                    <Datatable
                      columns={[
                        {
                          title: <div style={{ textAlign: 'center' }}>{t('ข้อเสนอแนะ')}</div>,
                          dataIndex: 'suggest',
                          width: '500px',
                        },
                        {
                          title: <div style={{ textAlign: 'center' }}>{t('กิจกรรที่ต้องดำเนินการ')}</div>,
                          dataIndex: 'activity',
                          width: '250px',
                        },
                        {
                          title: <div style={{ textAlign: 'center' }}>{t('สถานะของการดำเนินการ')}</div>,
                          dataIndex: 'actionStatusName',
                          width: '250px',
                        },
                        {
                          title: <div style={{ textAlign: 'center' }}>{t('การดำเนินการ/แผน')}</div>,
                          dataIndex: 'planDetail',
                          width: '250px',
                        },
                        {
                          title: (
                            <div style={{ textAlign: 'center' }}>{t('วันที่ดำเนินการ/วันที่คาดว่าจะแล้วเสร็จ')}</div>
                          ),
                          dataIndex: 'expectDate',
                          width: '350px',
                          render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
                        },
                        {
                          title: <div style={{ textAlign: 'center' }}>{t('หน่วยรับตรวจ')}</div>,
                          dataIndex: 'organizationName',
                          width: '150px',
                          render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
                        },
                        {
                          title: <div style={{ textAlign: 'center' }}>{t('ข้อคิดเห็น')}</div>,
                          dataIndex: 'ideaStatusName',
                          width: '150px',
                          render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
                        },
                        {
                          title: <div style={{ textAlign: 'center' }}>{t('เหตุผล')}</div>,
                          dataIndex: 'reason',
                          width: '150px',
                          render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
                        },
                        {
                          title: '',
                          dataIndex: 'extraBTN',
                          width: '150px',
                          render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
                        },
                        {
                          title: '',
                          dataIndex: 'action',
                          width: '100px',
                          render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
                        },
                      ]}
                      data={item.subItem.map((subItem) => {
                        return {
                          key: uuidv4(),
                          suggest: displayText(subItem.suggestTH, subItem.suggestEN),
                          activity: displayText(subItem.activityTH, subItem.activityEN),
                          actionStatusName: displayText(subItem.actionStatusNameTH, subItem.actionStatusNameEN),
                          planDetail: displayText(subItem.planDetailTH, subItem.planDetailEN),
                          expectDate: subItem.expectDate ? dateDisplay(subItem.expectDate) : '',
                          organizationName: displayText(subItem.organizationNameTH, subItem.organizationNameEN),
                          ideaStatusName:
                            subItem.ideaStatusNameTH || subItem.ideaStatusNameEN ? (
                              <Status text={displayText(subItem.ideaStatusNameTH, subItem.ideaStatusNameEN)} />
                            ) : (
                              ''
                            ),
                          reason: displayText(subItem.reasonTH, subItem.reasonEN),
                          action: (
                            <div style={{ textAlign: 'center' }}>
                              <ButtonGroup
                                menu={props.menu}
                                disabled={subItem.actionId ? false : true}
                                icons={[
                                  {
                                    type: 'view',
                                    onClick: () => {
                                      navigate('/followup/actionexplain/formactionmanage', {
                                        state: {
                                          actionId: subItem.actionId,
                                          projectCode: state.projectCode,
                                          type: 'view',
                                        },
                                      })
                                    },
                                  },
                                ].concat(
                                  !disabled
                                    ? subItem.actionId
                                      ? subItem.formSucceed === 0
                                        ? [
                                            {
                                              type: 'edit',
                                              onClick: () => {
                                                navigate('/followup/actionexplain/formactionmanage', {
                                                  state: {
                                                    actionId: subItem.actionId,
                                                    projectCode: state.projectCode,
                                                    type: 'edit',
                                                  },
                                                })
                                              },
                                            },
                                            {
                                              type: 'delete',
                                              onClick: () => {
                                                Alert({
                                                  type: 'confirm',
                                                  title: t('ต้องการยืนยันการลบ ?'),
                                                  onOk() {
                                                    deleteActionExplainFn({
                                                      variables: {
                                                        input: encryptInput({
                                                          actionId: subItem.actionId,
                                                          type: 'delete',
                                                          menu: props.menu,
                                                        }),
                                                      },
                                                    })
                                                  },
                                                  onCancel() {},
                                                })
                                              },
                                            },
                                          ]
                                        : []
                                      : []
                                    : [],
                                )}
                              />
                            </div>
                          ),
                        }
                      })}
                    />
                  </SubTable>
                ),
              }
            }),
          )
        }
      }
    }
    if (dataFollowUpActivityListPagination.error) {
      showAlertLoadDataError(dataFollowUpActivityListPagination.error)
    }
  }, [dataFollowUpActivityListPagination.data])

  useEffect(() => {
    if (deleteActionExplain.data) {
      showAlertDeleteSuccess()
    }
    if (deleteActionExplain.error) {
      showAlertDeleteDataError(deleteActionExplain.error)
    }
  }, [deleteActionExplain.data])

  const showAlertLoadDataError = (error) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(displayError(error)),
    })
  }

  const showAlertDeleteDataError = (error) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถลบข้อมูลได้'),
      content: t(displayError(error)),
      onOk() {},
    })
  }

  const showAlertDeleteSuccess = () => {
    Alert({
      type: 'success',
      title: t('ลบบันทึกสำเร็จ'),
      onOk() {
        dataFollowUpActivityListPagination.refetch()
      },
    })
  }

  const mainColumns = [
    {
      title: t('ประเด็น/ข้อสังเกต'),
      dataIndex: 'issue',
      width: '2000px',
      align: 'left',
    },
    {
      title: t('สถานะประเด็น/ข้อสังเกต'),
      dataIndex: 'issueStatusName',
      width: '700px',
      align: 'center',
    },
  ]

  return (
    <>
      {(dataFollowUpActivityListPagination.loading || deleteActionExplain.loading) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Breadcrumb data={breadcrumbList} title={t('รายการข้อเสนอแนะ')} menu={props.menu} />
          <CardNew topic={t('รายการกิจกรรมตรวจสอบ')} icon="AlignJustify" toggledrop="false">
            <MainTable>
              <Datatable
                columns={mainColumns}
                data={dataLists}
                handleTableChange={() => {}}
                paginationCustom={true}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                searchCustom={true}
                total={count}
                enableScroll={{ x: false, y: true }}
                scroll={{ x: true, y: false }}
                pageSize={limit}
                page={page}
                expandable={{
                  expandedRowRender: (record) => record.description,
                  onExpandedRowsChange: () => {},
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <IconNew icon={'ChevronUp'} onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <IconNew icon={'ChevronDown'} onClick={(e) => onExpand(record, e)} />
                    ),
                }}
                btnAdd={
                  disabled ? (
                    <></>
                  ) : (
                    <ButtonNew
                      type="flagOutlinedInTableBlue"
                      roles={{ type: 'add', menu: props.menu }}
                      onClick={() => {
                        navigate('/followup/actionexplain/formactionmanage', {
                          state: { projectCode: state.projectCode, type: 'add' },
                        })
                      }}
                    >
                      {t('บันทึกคำชี้แจง')}
                    </ButtonNew>
                  )
                }
              />
            </MainTable>
            <Row align="center">
              <ButtonNew type="back" onClick={() => navigate('/followup/actionexplain')}>
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Row>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default FormActionList
