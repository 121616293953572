import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Space } from 'antd'
import * as _ from 'lodash'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonNew } from '../../../components/button/Button'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { GET_ACKNOWLEDGE_ISSUE_SUB_PAGINATION } from './graphql/Query'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText, displayError } from '../../../utilitys/helper'
import { Alert } from '../../../components/alert/Alert'
import { handleSort } from '../../../utilitys/pagination'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import AcknowledgmentModal from './components/modal/AcknowledgmentModal'
import { useDecodeUser } from '../../../hooks/useDecodeUser'

const AcknowledgmentSubList = (props) => {
  const { t } = useTranslation()
  const { menu } = props
  const [dataLists, setDataLists] = useState([])
  const navigate = useNavigate()
  const { state } = useLocation()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [sort, setSort] = useState([])
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [objData, setObjData] = useState({
    type: 'add',
    causeId: 0,
  })
  const [status, setStatus] = useState(false)
  const [user] = useDecodeUser()

  if (!state) {
    return <Navigate to={'/auditoperations/auditresult/'} />
  }

  const [pagination, { data: dataPagination, loading: loadingPagination, error: errorPagination }] = useLazyQuery(
    GET_ACKNOWLEDGE_ISSUE_SUB_PAGINATION,
  )

  useEffect(() => {
    if (user?.orgCode) {
      pagination({
        variables: {
          input: encryptInput({
            organizationCode: user.orgCode,
            filters: { actionPlanId: state.refCode },
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
    }
  }, [page, limit, sort, user?.orgCode])

  useEffect(() => {
    if (status) {
      pagination({
        variables: {
          input: encryptInput({
            organizationCode: user.orgCode,
            filters: { actionPlanId: state.refCode },
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
      setStatus(false)
    }
  }, [status])

  useEffect(() => {
    if (dataPagination) {
      let response = dataPagination.acknowledgeIssueSubPaginationGet
      setCount(response.count)
      setDataLists(
        _.map(response.result, (item, keys) => {
          let show = true
          let show2 = true

          if (keys !== 0) {
            show = response.result[keys - 1].rateFacProCode !== response.result[keys].rateFacProCode ? true : false
          }

          if (keys !== 0) {
            show2 = response.result[keys - 1].rateRiskConCode !== response.result[keys].rateRiskConCode ? true : false
          }

          return {
            key: keys + 1,
            show: show,
            show2: show2,
            factorsRisks: item.rateFacProCode + ' : ' + displayText(item.rateFacProNameTH, item.rateFacProNameEN),
            controlMethod: item.rateRiskConCode + ' : ' + displayText(item.rateRiskConTH, item.rateRiskConEN),
            issuesRemarks: displayText(item.issueTH, item.issueEN),
            feedBack: displayText(item.suggestTH, item.suggestEN),
            explain: displayText(item.explainTH, item.explainEN),
            button: {
              suggestId: item.suggestId,
              status: item.status,
            },
          }
        }),
      )
    }
    if (errorPagination) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorPagination)),
        onOk() {},
      })
    }
  }, [dataPagination])

  const columnsQuestion = [
    {
      title: t('ปัจจัยความเสี่ยง'),
      dataIndex: 'factorsRisks',
      width: '25%',
      align: 'left',
      // sorter: true,
      render: (item, record) => {
        if (record.show) {
          return <div>{record.factorsRisks}</div>
        } else {
          return <></>
        }
      },
    },
    {
      title: t('วิธีการควบคุม'),
      dataIndex: 'controlMethod',
      width: '25%',
      align: 'left',
      // sorter: true,
      render: (item, record) => {
        if (record.show2) {
          return <div>{record.controlMethod}</div>
        } else {
          return <></>
        }
      },
    },
    {
      title: t('ข้อสังเกต'),
      dataIndex: 'issuesRemarks',
      width: '20%',
      align: 'left',
      // sorter: true,
    },
    {
      title: t('ข้อเสนอแนะ'),
      dataIndex: 'feedBack',
      width: '15%',
      align: 'left',
      // sorter: true,
    },
    {
      title: t('คำชี้แจง'),
      dataIndex: 'explain',
      width: '25%',
      align: 'left',
      // sorter: true,
    },
    {
      title: '',
      dataIndex: 'button',
      width: '5%',
      render: (item) => {
        return item.status === 0 ? (
          <>
            <ButtonNew
              type="flagOutlinedInTableBlue"
              roles={{ type: 'edit', menu: menu }}
              onClick={() => {
                setVisible(true)
                setObjData({
                  type: 'add',
                  suggestId: item.suggestId,
                  organizationCode: user.orgCode,
                  personnelCode: user.pCode,
                })
              }}
            >
              {t('รับทราบประเด็น')}
            </ButtonNew>
          </>
        ) : (
          <>
            <ButtonNew type="moreHorizontalInTableGray">{t('รับทราบประเด็นแล้ว')}</ButtonNew>
          </>
        )
      },
    },
  ]

  const Responesive = {
    md: 24,
    lg: 24,
  }
  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/acknowledgeissue' },
    { label: t('รับทราบประเด็น') },
  ]
  return (
    <>
      {loadingPagination && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Breadcrumb data={breadcrumbList} title={t('รับทราบประเด็น')} />
          <CardNew topic={t('รายการแผนการตรวจสอบ')} icon="List" toggledrop="false">
            <Datatable
              columns={columnsQuestion}
              data={dataLists}
              searchCustom={true}
              total={count}
              pageSize={limit}
              page={page}
              paginationCustom={true}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              handleTableChange={(e) => {
                const reNewField = [
                  {
                    factorsRisks: localStorage.getItem('lang') === 'th' ? 'rateFacProNameTH' : 'rateFacProNameEN',
                  },
                  {
                    controlMethod: localStorage.getItem('lang') === 'th' ? 'rateRiskConTH' : 'rateRiskConEN',
                  },
                  {
                    issuesRemarks: localStorage.getItem('lang') === 'th' ? 'issueTH' : 'issueEN',
                  },
                ]
                setSort(handleSort(e?.sorter, reNewField))
              }}
            />
          </CardNew>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col {...Responesive} align="center">
          <Space size={[8, 8]} wrap>
            &nbsp;
            <ButtonNew
              type="back"
              onClick={() => {
                navigate('/auditoperations/acknowledgeissue')
              }}
            >
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Space>
        </Col>
      </Row>
      <AcknowledgmentModal open={visible} close={(e) => setVisible(e)} objData={objData} setStatus={setStatus} />
    </>
  )
}

export default AcknowledgmentSubList
