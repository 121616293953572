import React from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { TabNew } from '../../../components/tab/Tab'
import ApprovePlanProjectInformations from './component/tab/ApprovePlanProjectInformations'
import ResponsiblePersonAndDuration from './component/tab/ResponsiblePersonAndDuration'
import AdditionalDocuments from './component/tab/AdditionalDocuments'
import { useNavigate, useLocation } from 'react-router-dom'
import { getSegmentUrl } from '../../../utilitys/helper'
import { Card } from 'antd'

export default function ApprovePlanProjectDetail() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = getSegmentUrl(5)
  const { state } = useLocation()

  const breadcrumbDetail = [
    { label: t('งานวางแผนการตรวจสอบ'), path: '/auditplanning' },
    { label: t('อนุมัติแผนการตรวจสอบ'), path: '/auditplanning/approveauditplan' },
    { label: t('งานวางแผนการตรวจสอบ'), path: '/auditplanning/approveauditplan/detail' },
    { label: t('รายละเอียดโครงการ'), path: '' },
  ]

  const tabs = [
    {
      key: 1,
      name: t('ข้อมูลทั่วไปโครงการ'),
      disabled: false,
      content: () => (
        <>{params === 'generalinformation' || !params ? <ApprovePlanProjectInformations state={state} /> : ''}</>
      ),
    },
    {
      key: 2,
      name: t('ผู้รับผิดชอบ/ระยะเวลา'),
      disabled: false,
      content: () => <>{params === 'personandduration' ? <ResponsiblePersonAndDuration state={state} /> : ''}</>,
    },
    {
      key: 3,
      name: t('เอกสารเพิ่มเติม'),
      disabled: false,
      content: () => <>{params === 'documents' ? <AdditionalDocuments state={state} /> : ''}</>,
    },
  ]

  return (
    <>
      <Breadcrumb data={breadcrumbDetail} title={t('รายละเอียดโครงการ')} />
      <Card>
        <TabNew
          tab={tabs}
          activeKey={
            params === 'generalinformation'
              ? '1'
              : params === 'personandduration'
              ? '2'
              : params === 'documents'
              ? '3'
              : '1'
          }
          onTabClick={(key) => {
            if (key === '1') {
              navigate('/auditplanning/approveauditplan/detail/projectdetail/generalinformation', { state: state })
            } else if (key === '2') {
              navigate('/auditplanning/approveauditplan/detail/projectdetail/personandduration', { state: state })
            } else if (key === '3') {
              navigate('/auditplanning/approveauditplan/detail/projectdetail/documents', { state: state })
            }
          }}
        />
      </Card>
    </>
  )
}
