import { Trans } from 'react-i18next'

export const PrepareAuditReportListCrumb = [
  { label: <Trans>งานปฎิบัติการตรวจสอบ</Trans>, path: '/auditoperations/prepareauditreport' },
  { label: <Trans>จัดทำรายงานตรวจสอบ</Trans> },
]

export const PrepareAuditReportFormCrumb = [
  { label: <Trans>งานปฎิบัติการตรวจสอบ</Trans>, path: '/auditoperations/prepareauditreport' },
  { label: <Trans>จัดทำรายงานตรวจสอบ</Trans>, path: '/auditoperations/prepareauditreport' },
  { label: <Trans>รายละเอียดรายงานตรวจสอบ</Trans> },
]
