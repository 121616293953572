import { gql } from '@apollo/client'

export const GET_MASTER_TRAIN_TYPES = gql`
  query getMasterTrainTypes($input: MasterTrainTypesInput!) {
    getMasterTrainTypes(data: $input) {
      trainTypeId
      trainTypeCode
      trainTypeNameTH
      trainTypeNameEN
      isActive
    }
  }
`
