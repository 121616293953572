import { gql } from '@apollo/client'
export const GET_MASTER_RISK_LEVEL = gql`
  query GetMasterRiskLevel($input: MasterRiskLevelInput!) {
    getMasterRiskLevel(input: $input) {
      riskLevelId
      riskLevelCode
      riskLevelNameTH
      riskLevelNameEN
      isActive
    }
  }
`
