import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Space } from 'antd'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Select } from '../../../../components/select/Select'
import { ButtonNew } from '../../../../components/button/Button'
import SelectOperationStatus from '../../../../components/inputfromapi/selectoperationstatus/SelectOperationStatus'
import SelectOrganization from '../../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectYear from '../../../../components/inputfromapi/selectyear/SelectYear'
import { Alert } from '../../../../components/alert/Alert'
import { CardNew } from '../../../../components/card/Card'
import { encryptInput } from '../../../../utilitys/crypto'
import { LabelNew } from '../../../../components/label/Label'
import { InputNew } from '../../../../components/input/Input'
import { IconNew } from '../../../../components/icon/Icon'
import { displayError } from '../../../../utilitys/helper'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { GET_SEARCH_RISK_FORMS_LIST, EXPORT_RISK_FORMS } from '../graphql/Query'

const RiskAssessmentReportFilter = (props) => {
  const { limit, page, getCount, getValue, reFetch, sort, menu } = props
  const { t } = useTranslation()
  const isLang = localStorage.getItem('lang')
  const [form] = Form.useForm()
  const formName = 'filter'
  const Responsive = { md: 6, lg: 6, btn: { md: 24, lg: 24 } }

  const [allowExport, setAllowExport] = useState(true)
  const [isRiskGroup, setIsRiskGroup] = useState(null)
  const [dataRef, setDataRef] = useState([])
  const [filters, setFilters] = useState({})
  const [isLocalFilter, setIsLocalFilter] = useState(null)

  const [exportRiskFormsFn, exportRiskForms] = useLazyQuery(EXPORT_RISK_FORMS)
  const [getSearchRiskFormsListFn, getSearchRiskFormsList] = useLazyQuery(GET_SEARCH_RISK_FORMS_LIST)

  useEffect(() => {
    const { Year, riskFormType } = form.getFieldsValue()
    if (isLang && riskFormType && Year) {
      const localFilter = {
        Year: Year,
        riskFormType: riskFormType,
        lang: isLang,
        type: 'search',
        ...filters,
      }
      setIsLocalFilter(localFilter)
      getSearchRiskFormsListFn({
        variables: {
          input: {
            filters: encryptInput(localFilter),
            pagination: { limit, page },
            sort: sort,
          },
        },
      })
    }
  }, [page, limit, isLang, reFetch, sort, filters])

  useEffect(() => {
    if (getSearchRiskFormsList.data) {
      const res_ = getSearchRiskFormsList?.data?.getSearchRiskFormsList
      getCount(res_?.count)
      getValue(res_?.result)
      setDataRef(res_?.result)
    }

    if (getSearchRiskFormsList.error) {
      const err = getSearchRiskFormsList.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [getSearchRiskFormsList.data])

  useEffect(() => {
    if (exportRiskForms.data) {
      const { fileUrl } = exportRiskForms.data.exportRiskForms
      window.open(fileUrl)
    }
    if (exportRiskForms.error) {
      const err = exportRiskForms.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [exportRiskForms.data])

  const onFinish = async (value) => {
    const localFilter = {
      Year: value?.Year || '',
      riskFormCode: value?.riskFormCode || '',
      riskFormType: value?.riskFormType || '',
      organizationCode: value?.organizationCode || '',
      operationStatusCode: value?.operationStatusCode ? String(value?.operationStatusCode) : '' || '',
      riskFormName: value?.riskFormName || '',
      responsibilityName: value?.responsibilityName || '',
      riskModelGroup: value?.riskModelGroup || '',
      lang: isLang,
    }

    setFilters({ ...localFilter })
  }

  const Export = async () => {
    const { Year, organizationCode } = form.getFieldsValue()

    await exportRiskFormsFn({
      variables: {
        input: encryptInput({
          filters: isLocalFilter,
          // riskFormCode: dataRef.map((item) => ({ riskFormCode: item.riskFormCode, riskModelCode: item.riskModelCode })),
          fileType: 'excel',
          lang: isLang,
          year: Year,
          riskGroup: isRiskGroup,
          organizationCode: organizationCode,
        }),
      },
    })
  }

  return (
    <>
      {(getSearchRiskFormsList?.loading || exportRiskForms?.loading) && <SpinnersNew />}
      <Row style={{ marginBottom: 24 }}>
        <Col sm={24} md={24} lg={24}>
          <CardNew topic={t('Filters')} icon="List" toggledrop={'true'}>
            <Form form={form} onFinish={onFinish} name={formName} layout="vertical">
              <Row gutter={[12, 12]}>
                <Col {...Responsive}>
                  <Form.Item
                    name={'Year'}
                    label={<LabelNew type="tab-header-inactive">{t('ปีงบประมาณ')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุปีงบประมาณ') }]}
                  >
                    <SelectYear
                      formname={formName}
                      placeholder={t('ระบุปี')}
                      handleChange={(v) => {
                        form.setFieldsValue({
                          Year: v.value,
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'riskFormCode'}
                    label={<LabelNew type="tab-header-inactive">{t('รหัสแบบประเมิน')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุรหัสแบบประเมิน')} />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'riskFormName'}
                    label={<LabelNew type="tab-header-inactive">{t('ชื่อแบบประเมิน')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุชื่อแบบประเมิน')} />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name="organizationCode"
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยงานรับผิดชอบ')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุหน่วยงานรับผิดชอบ') }]}
                  >
                    <SelectOrganization
                      placeholder={t('ระบุหน่วยงานรับผิดชอบ')}
                      formname={formName}
                      handleChange={(v) => {
                        if (v?.source?.organizationCode) {
                          const orgCode = v?.source?.organizationCode
                          const checkOrg = v?.source?.organizationCode.slice(orgCode.length - 2, orgCode.length)

                          setIsRiskGroup(checkOrg === '29' ? 1 : checkOrg === '30' ? 2 : null)
                        }
                        form.setFieldsValue({ organizationCode: v.value })
                      }}
                      filters={{ organizationChecked: 2, isActive: 1 }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'riskFormType'}
                    label={<LabelNew type="tab-header-inactive">{t('ประเภทการประเมิน')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุประเภทการประเมิน') }]}
                  >
                    <Select
                      title=""
                      data={[
                        { value: 1, label: t('ประเมินสรุป') },
                        { value: 2, label: t('ประเมินรายหน่วยงาน') },
                      ]}
                      onChange={() => {
                        const { riskFormType } = form.getFieldsValue()
                        setAllowExport(riskFormType === 1 ? false : true)
                      }}
                      placeholder={t('ระบุประเภทการประเมิน')}
                      scrollableId={formName}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'responsibilityName'}
                    label={<LabelNew type="tab-header-inactive">{t('ผู้ประเมิน')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุผู้ประเมิน')} />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'riskModelGroup'}
                    label={<LabelNew type="tab-header-inactive">{t('กลุ่มการประเมิน')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุกลุ่มการประเมิน') }]}
                  >
                    <Select
                      title=""
                      data={[
                        { value: 1, label: t('ประเมินองค์กร') },
                        { value: 2, label: t('ประเมินสาขา') },
                      ]}
                      onChange={() => {
                        const { riskFormType } = form.getFieldsValue()
                        setAllowExport(riskFormType === 1 ? false : true)
                      }}
                      placeholder={t('ระบุกลุ่มการประเมิน')}
                      scrollableId={formName}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'operationStatusCode'}
                    label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                  >
                    <SelectOperationStatus
                      filters={{ operationStatusType: 1 }}
                      needOperationStatusCode={true}
                      placeholder={t('ระบุสถานะ')}
                      formname={formName}
                      handleChange={(v) => {
                        form.setFieldsValue({
                          operationStatusCode: v.value,
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col {...Responsive.btn} align="center">
                  <Space size={[8, 8]} wrap>
                    <ButtonNew type="search" htmlType="submit">
                      {t('ค้นหา')}
                    </ButtonNew>
                    &nbsp;
                    <ButtonNew
                      type="success"
                      roles={{ type: 'export', menu: menu }}
                      disabled={!allowExport && dataRef.length > 0 ? false : true}
                      onClick={() => Export()}
                    >
                      <IconNew icon={'Download'} size={18} color="" />
                      &nbsp;
                      {t('ส่งออก')}
                    </ButtonNew>
                    &nbsp;
                    <ButtonNew
                      type="clear"
                      onClick={() => {
                        form.resetFields()
                        setFilters({})
                        getCount(0)
                        getValue([])
                        setIsLocalFilter(null)
                        setAllowExport(true)
                      }}
                    >
                      {t('ล้างค่า')}
                    </ButtonNew>
                  </Space>
                </Col>
              </Row>
            </Form>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default RiskAssessmentReportFilter
