import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { TimePicker } from 'antd'
import { useTranslation } from 'react-i18next'
import localeTH from 'antd/es/date-picker/locale/th_TH'
import localeEN from 'antd/es/date-picker/locale/en_US'
import './TimepickerStyle.scss'

export const Timepicker = (props) => {
  const { value } = props

  const format = 'HH:mm'
  let styles
  if (props.style) styles = props.style && delete props.style

  const { t } = useTranslation()
  const [isLang, setIsLang] = useState(localeEN)

  useEffect(() => {
    let lang = localStorage.getItem('lang')

    setIsLang(lang === 'en' ? localeEN : localeTH)
  }, [localStorage.getItem('lang')])

  const [isDisplay, setIsDisplay] = useState()
  useEffect(() => {
    if (value) {
      setIsDisplay(moment(value, format))
    } else {
      setIsDisplay(null)
    }
  }, [value])

  return (
    <TimePicker
      {...props}
      className="time_picker_item"
      onChange={(time) => props.onChangeTime(time)}
      defaultValue={props.notDateNow ? null : moment()}
      value={isDisplay}
      format={format}
      style={styles}
      locale={{
        lang: {
          ...isLang.lang,
          now: t('Now'),
          ok: t('Ok'),
        },
      }}
      placeholder={t('กรุณาระบุเวลา')}
    />
  )
}

Timepicker.propTypes = {
  onChangeTime: PropTypes.func.isRequired,
  notDateNow: PropTypes.bool,
}
