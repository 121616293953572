import React, { useContext, useEffect } from 'react'
import * as _ from 'lodash'
import { Layout } from 'antd'
import { Outlet, useNavigate, Navigate } from 'react-router-dom'
import Navbar from './navbar/Navbar'
import Sidebar from './sidebar/Sidebar'
import { Page403 } from '../components/result/Page403'
import Home from '../modules/main/home/HomeIndex'
import { AuthContext } from '../context/authContext'
import { decryptData } from '../utilitys/crypto'
const { Content } = Layout

function FullLayout(props) {
  const [user] = useContext(AuthContext)
  const navigate = useNavigate()

  const pathName = window?.location?.pathname

  if (!localStorage.getItem('path') && localStorage.getItem('user')) {
    localStorage.removeItem('user')
    window.location.href = 'login'
  }

  const { userMenus = [] } = decryptData(localStorage.getItem('user'))
  const dataMenus = decryptData(localStorage.getItem('path'))

  useEffect(() => {
    if (!user.loginName) {
      // ลูกค้าอยากปรับให้ไม่ต้องขึ้น alert ให้ไปที่หน้าแรกเลย วันที่ 17/07/2023
      localStorage.removeItem('user')
      navigate('login')
    }
  }, [user])

  const data =
    dataMenus !== '' && userMenus !== ''
      ? dataMenus.filter((item) => userMenus.includes(item.menuCode)).map((item) => item?.children)
      : []

  const dashboardOverview = _.some(_.flattenDeep(data), (d) => d.menuPath === '/dashboard/overviewfollowup')
  const checkPermission = _.some(
    _.flattenDeep(data),
    (d) =>
      pathName.includes(d.menuPath) ||
      pathName === '/profile' ||
      (pathName.includes('/components') && process.env.REACT_APP_ENV === 'local'),
  )
  const checkHome = pathName === '/' ? true : false

  const conditionToRender = () => {
    if (checkPermission) {
      return <Outlet />
    } else {
      if (checkHome) {
        if (dashboardOverview) {
          return <Navigate to="/dashboard/overviewfollowup" />
        } else {
          return <Home />
        }
      } else {
        return <Page403 />
      }
    }
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout className="site-layout">
        <Navbar activeScreen={props.activeScreen} />
        <div className="layout-main">
          <Content>{conditionToRender()}</Content>
        </div>
      </Layout>
    </Layout>
  )
}
export default FullLayout
