import React, { useState, useEffect } from 'react'
import { Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { CardNew } from '../../../components/card/Card'
import { getSegmentUrl } from '../../../utilitys/helper'
import { TabNew } from '../../../components/tab/Tab'
import TabOne from './tab/TabOne'
import TabTwo from './tab/TabTwo'
import TabThree from './tab/TabThree'
// import TabFour from './tab/TabFour'
import TabFive from './tab/TabFive'

const AuditResultTab = (props) => {
  const { t } = useTranslation()
  const params = getSegmentUrl(3)
  const { menu } = props
  const navigate = useNavigate()
  const { state } = useLocation()
  const [status, setStatus] = useState(false)

  if (!state) {
    return <Navigate to={'/auditoperations/auditresult/'} />
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/auditresult/' },
    { label: t('บันทึกผลการปฏิบัติงานตรวจสอบ') },
  ]

  useEffect(() => {
    if (status) {
      state.status = true
    }
  }, [status])

  const tabs = [
    {
      key: 'TabOne',
      iconTab: () => <></>,
      name: t('ประเด็นการตรวจสอบ'),
      disabled: false,
      content: () => <>{params === 'TabOne' || !params ? <TabOne menu={menu} setStatus={setStatus} /> : ''}</>,
    },
    {
      key: 'TabTwo',
      iconTab: () => <></>,
      name: t('สาเหตุ'),
      disabled: status || state?.status ? false : true,
      content: () => <>{params === 'TabTwo' ? <TabTwo menu={menu} /> : ''}</>,
    },
    {
      key: 'TabThree',
      iconTab: () => <></>,
      name: t('ข้อเสนอแนะ'),
      disabled: status || state?.status ? false : true,
      content: () => <>{params === 'TabThree' ? <TabThree menu={menu} /> : ''}</>,
    },
    // {
    //   key: 'TabFour',
    //   iconTab: () => <></>,
    //   name: t('คำชี้แจง'),
    //   disabled: status || state?.status ? false : true,
    //   content: () => <>{params === 'TabFour' ? <TabFour menu={menu} /> : ''}</>,
    // },
    {
      key: 'TabFive',
      iconTab: () => <></>,
      name: t('บันทึกเสร็จสิ้น'),
      disabled: status || state?.status ? false : true,
      content: () => <>{params === 'TabFive' ? <TabFive menu={menu} /> : ''}</>,
    },
  ]

  const Responesive = {
    md: 24,
    lg: 24,
  }
  return (
    <>
      <Row>
        <Col md={24}>
          <Breadcrumb data={breadcrumbList} title={t('บันทึกผลการปฏิบัติงานตรวจสอบ')} menu={props.menu} />
          <CardNew topic={t('รายการแผนการตรวจสอบ')} icon="List" toggledrop="false">
            <TabNew
              tab={tabs}
              activeKey={!params ? 'TabOne' : params}
              onTabClick={(key) => {
                if (key === 'TabOne') {
                  navigate('/auditoperations/auditresult/TabOne', {
                    state: state,
                  })
                } else if (key === 'TabTwo') {
                  navigate('/auditoperations/auditresult/TabTwo', {
                    state: state,
                  })
                } else if (key === 'TabThree') {
                  navigate('/auditoperations/auditresult/TabThree', {
                    state: state,
                  })
                } else if (key === 'TabFour') {
                  navigate('/auditoperations/auditresult/TabFour', {
                    state: state,
                  })
                } else if (key === 'TabFive') {
                  navigate('/auditoperations/auditresult/TabFive', {
                    state: state,
                  })
                }
              }}
            />
          </CardNew>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col {...Responesive} align="center">
          <Space size={[8, 8]} wrap>
            &nbsp;
            <ButtonNew
              type="back"
              onClick={() => {
                navigate('/auditoperations/auditresult')
              }}
            >
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Space>
        </Col>
      </Row>
    </>
  )
}

export default AuditResultTab
