import React from 'react'
import { Trans } from 'react-i18next'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { displayText } from '../../../../../utilitys/helper'

export const Columns = ({ typeActionMain, fncOnpressDelete, fncOnpressView, fncOnpressEdit, menu }) => {
  return [
    {
      title: <Trans>ขั้นตอนการปฎิบัติงาน</Trans>,
      dataIndex: 'stepName',
      sorter: false,
      width: '400px',
      fixed: 'left'
    },
    {
      title: <Trans>วันที่เริ้มต้น</Trans>,
      dataIndex: 'dateStart',
      sorter: false,
      width: '120px'
    },
    {
      title: <Trans>วันที่สิ้นสุด</Trans>,
      dataIndex: 'dateEnd',
      sorter: false,
      width: '120px'
    },
    {
      title: <Trans>หน่วยรับตรวจ</Trans>,
      dataIndex: 'organizations',
      sorter: false,
      width: '300px',

      render: (item, record) => {
        return record.organizations.map((val, idx) => {
          const name = displayText(val.organizationNameTH, val.organizationNameEN)
          return (
            <React.Fragment key={`${name}${idx}`}>
              {name}
              {idx < record.organizations.length && <br />}
            </React.Fragment>
          )
        })
      }
    },
    {
      title: <Trans>ผู้รับผิดชอบ</Trans>,
      dataIndex: 'personnels',
      sorter: false,
      width: '150px',
      render: (item, record) => {
        return record.personnels.map((val, idx) => {
          const name = displayText(val.personnelNameTH, val.personnelNameEN)
          return (
            <React.Fragment key={`${name}${idx}`}>
              {name}
              {idx < record.personnels.length && <br />}
            </React.Fragment>
          )
        })
      }
    },
    {
      title: <Trans>สถานะ</Trans>,
      dataIndex: 'operationStatusName',
      sorter: false,
      width: '150px'
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '100px',
      render: (item, record) => {
        //20 = พักงาน , 30 = ลาออก , 40 = ย้ายออก , 50 = เกษียร
        const personnaloperationCode = ['20', '30', '40', '50']

        let isEnabled

        //เพิ่มเงื่อนไขการตรวจสอบสถานะการปฎิบัติงาน ถ้าไม่ได้ปฏิบัติงาน code = 10  ให้เปิดสิทธิ์การแก้ไข
        record?.personnels.forEach((val) => {
          if (personnaloperationCode.includes(val.personnaloperationCode)) {
            isEnabled = true
          }
        })

        const icons = [
          {
            type: 'view',
            onClick: () => {
              fncOnpressView(record)
            }
          }
        ]

        if (typeActionMain !== 'view') {
          icons.push({
            type: 'edit',
            disabled: record.operationStatusCode === '10' || isEnabled ? false : true,
            onClick: () => {
              fncOnpressEdit(record)
            }
          })
          icons.push({
            type: 'delete',
            disabled: record.operationStatusCode === '10' || isEnabled ? false : true,
            onClick: () => {
              fncOnpressDelete(record)
            }
          })
        }

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} icons={icons} />
          </div>
        )
      }
    }
  ]
}
