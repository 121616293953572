import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_AUDIT_SUGGEST_ORG_FOR_AUDIT_CLOSES } from './graphql/Query'
import { Select } from '../../select/Select'
import _ from 'lodash'
import { displayText } from '../../../utilitys/helper'

const SelectAuditSuggestOrganizationsForAuditCloses = (props) => {
  const { placeholder, formname, handleChange, ...otherProp } = props

  const [isData, isSetData] = useState([])

  const [getAuditSuggestOrganizationsFn, { data, loading }] = useLazyQuery(GET_AUDIT_SUGGEST_ORG_FOR_AUDIT_CLOSES)

  useEffect(() => {
    getAuditSuggestOrganizationsFn()
  }, [])

  useEffect(() => {
    if (data) {
      isSetData(
        data?.getAuditSuggestOrganizationsOfAuditCloses.map((item) => {
          let label = displayText(item.organizationNameTH, item.organizationNameEN)
          if (!label) label = item.organizationNameTH
          return {
            value: item.organizationCode,
            label: `${item.organizationCode} : ${label}`,
            source: item,
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        loading={loading}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectAuditSuggestOrganizationsForAuditCloses
