export const colors = [
  'pink',
  'red',
  'yellow',
  'orange',
  'cyan',
  'green',
  'blue',
  'purple',
  'geekblue',
  'magenta',
  'volcano',
  'gold',
  'lime',
]

let str = ''

colors.map(
  (color) =>
    (str += `<Tooltip title={text} color={${color}}>
                <Button>${color}</Button>
            </Tooltip>`),
)

export const strColor = `import React from 'react'
import { Tooltip, Button } from 'antd'
export default function App() {
    const text = <span>prompt text</span>
    return <> 
    <Row style={{ margin: 28 }}>
        <Col span={24} align="middle">
        ${str}
        </Col>
    </Row>
    </>
}
`

export let code = `import React from 'react'
import { Tooltip, Button } from 'antd'
export default function App() {
    const text = <span>prompt text</span>
    return <> 
    <Row style={{ margin: 28 }}>
          <Col span={24} align="middle">
            {/* <Tooltip placement="topLeft" title={text}>
              <Button>TL</Button>
            </Tooltip>
            <Tooltip placement="top" title={text}>
              <Button>Top</Button>
            </Tooltip>
            <Tooltip placement="topRight" title={text}>
              <Button>TR</Button>
            </Tooltip>
            <Tooltip placement="leftTop" title={text}>
              <Button>LT</Button>
            </Tooltip>
            <Tooltip placement="left" title={text}>
              <Button>Left</Button>
            </Tooltip>
            <Tooltip placement="leftBottom" title={text}>
              <Button>LB</Button>
            </Tooltip>
            <Tooltip placement="rightTop" title={text}>
              <Button>RT</Button>
            </Tooltip>
            <Tooltip placement="right" title={text}>
              <Button>Right</Button>
            </Tooltip>
            <Tooltip placement="rightBottom" title={text}>
              <Button>RB</Button>
            </Tooltip>
            <Tooltip placement="bottomLeft" title={text}>
              <Button>BL</Button>
            </Tooltip> */}
            <Tooltip placement="bottom" title={text}>
              <Button>Bottom</Button>
            </Tooltip>
            {/* <Tooltip placement="bottomRight" title={text}>
              <Button>BR</Button>
            </Tooltip> */}
          </Col>
        </Row>
    </>
}
`
