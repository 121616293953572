import { Trans } from 'react-i18next'

export const breadcrumbList = [
  { label: <Trans>งานปฏิบัติการตรวจสอบ</Trans>, path: '/auditoperations/assesscooperation' },
  { label: <Trans>ประเมินความร่วมมือ</Trans> },
]

export const breadcrumbDetails = [
  { label: <Trans>งานปฏิบัติการตรวจสอบ</Trans>, path: '/auditoperations' },
  { label: <Trans>ประเมินความร่วมมือ</Trans>, path: '/auditoperations/assesscooperation' },
  { label: <Trans></Trans>, path: '/auditoperations/assesscooperation/assesscooperationdetails' },
]
