import { Trans } from 'react-i18next'

export const breadcrumbList = [
  { label: <Trans>{'งานวางแผนการตรวจสอบ'}</Trans>, path: '/auditplanning' },
  { label: <Trans>{'อนุมัติการขอยกเลิกโครงการ'}</Trans>, path: '/auditplanning/approvecancelproject' },
]

export const breadcrumbDetail = [
  { label: <Trans>{'งานวางแผนการตรวจสอบ'}</Trans>, path: '/administrator' },
  { label: <Trans>{'อนุมัติการขอยกเลิกโครงการ'}</Trans>, path: '/administrator/assessmentsetup' },
  { label: <Trans>{'รายละเอียดโครงการ'}</Trans> },
]
