import { gql } from '@apollo/client'
export const GET_LIST_OPERATION_SETUP_PAGINATION = gql`
  query GetListOperationSetupPagination($input: OperationSetupPagination!) {
    getListOperationSetupPagination(input: $input) {
      result {
        no
        stepModelCode
        stepModelTH
        stepModelEN
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_OPERATION_SETUP = gql`
  query GetOperationSetup($input: OperationSetupGetInput!) {
    getOperationSetup(input: $input) {
      stepModelCode
      stepModelTH
      stepModelEN
      isActive
      stepModelDetail {
        stepDetailId
        stepSort
        stepDetailTH
        stepDetailEN
        stepModelCode
        stepDetailType
        stepDetailRef
        isActive
        stepTypeCode
        stepProcess
        stepTypeNameTH
        stepTypeNameEN
      }
    }
  }
`
