import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'key',
    // sorter: true,
    width: '7%',
    className: 'text-nowrap',
    align: 'center',
  },
  {
    title: <Trans>รหัส</Trans>,
    dataIndex: 'controlTypeCode',
    width: '8%',
    className: 'text-nowrap',
    sorter: { multiple: 1 },
  },
  {
    title: <Trans>ประเภทการควบคุมภาษาไทย</Trans>,
    dataIndex: 'controlTypeNameTH',
    width: '25%',
    className: 'text-nowrap',
    sorter: { multiple: 2 },
  },
  {
    title: <Trans>ประเภทการควบคุมภาษาอังกฤษ</Trans>,
    dataIndex: 'controlTypeNameEN',
    width: '25%',
    className: 'text-nowrap',
    sorter: { multiple: 3 },
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActive',
    width: '10%',
    className: 'text-nowrap',
    sorter: { multiple: 4 },
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '10%',
    sorter: { multiple: 5 },
  },
]
