import { Trans } from 'react-i18next'
import { Alert } from '../components/alert/Alert'

export const downloadFile = (fileNameLabel, fileNameGen, callback) => {
  fetch(`${process.env.REACT_APP_API.replace('/graphql', '')}/downloadfileencrypt/${fileNameGen}`, {
    method: 'GET',
  })
    .then(async (response) => {
      if (response.status === 200) {
        return response.blob()
      } else {
        const res = await response.json()
        Alert({
          type: 'error',
          title: <Trans>ไม่สามารถดาวน์โหลดไฟล์ได้</Trans>,
          content: <Trans>{res.message}</Trans>,
        })
      }
    })
    .then((blob) => {
      if (blob) {
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = fileNameLabel
        document.body.appendChild(a)
        a.click()
        a.remove()
      }
      callback(true)
    })
    .catch(async (err) => {
      console.log('err: ', err)
      callback(err)
    })
}
