import React, { useState } from 'react'
import { breadcrumbData, pageTitle } from './utils/Constants'
import { BreadcrumbNew } from '../../../../components/breadcrumb/Breadcrumb'
import OverviewAuditFilter from './components/filter/OverviewAuditFilter'
import OverviewAuditGraph from './OverviewAuditGraph'
import OverviewAuditList from './OverviewAuditList'
import ExportModal from './components/modal/exports'

const OverviewAuditIndex = () => {
  const [openExportModal, setOpenExportModal] = useState(false)
  const [dataFilter, setdataFilter] = useState({
    dateStart: null,
    dateEnd: null,
    organizationCode: null,
    positionCode: null
  })

  const onGetValue = (data) => {
    setdataFilter(data)
  }

  return (
    <React.Fragment>
      <BreadcrumbNew data={breadcrumbData()} title={pageTitle} />
      <OverviewAuditFilter getValue={onGetValue} openExportModal={() => setOpenExportModal(true)} />
      <OverviewAuditGraph dataFilter={dataFilter} />
      <OverviewAuditList dataFilter={dataFilter} />

      <ExportModal
        dataFilter={dataFilter}
        isOpen={openExportModal}
        close={() => setOpenExportModal(false)}
        open={() => setOpenExportModal(true)}
      />
    </React.Fragment>
  )
}

export default OverviewAuditIndex
