import { gql } from '@apollo/client'
export const GET_SELECTMASTER_ORGANIZATIONS = gql`
  query getSelectMasterOrganizations($input: MasterOrganizationsInput!) {
    getSelectMasterOrganizations(data: $input) {
      organizationId
      organizationCode
      organizationNameTH
      organizationNameEN
      organizationLevelCode
      organizationParentCode
      organizationSizeCode
      organizationChecked
      isActive
    }
  }
`
