import { gql } from '@apollo/client'

export const GETAUDITPROJECTLIST = gql`
  query getAuditProjectList($inputData: AuditProjectInput!) {
    getAuditProjectList(inputData: $inputData) {
      projectId
      projectCode
      projectNameTH
      projectNameEN
      cancelStatus
      projectRefNameTH
      projectRefNameEN
      dateStart
      dateEnd
      auditTeamNameTH
      auditTeamNameEN
      fullNameTH
      fullNameEN
      approveStatusNameTH
      approveStatusNameEN
      approveStatusCode
    }
  }
`

export const GETAUDITPROJECTROWONE = gql`
  query getAuditProjectRawOne($inputData: AuditProjectInput!) {
    getAuditProjectRawOne(inputData: $inputData) {
      projectId
      projectCode
      projectNameTH
      projectNameEN
      projectTypeCode
      projectRefCode
      riskFormCode
      projectSizeCode
      manday
      auditTeamCode
      personnelCode
      numberOfPeople
      mandayPersonnal
      organizationCode
      dateStart
      dateEnd
      projectStatusCode
      approveStatusCode
      isActive
      projectRefNameTH
      projectRefNameEN
      organizationNameTH
      organizationNameEN
      approveStatusNameTH
      approveStatusNameEN
      projectTypeNameTH
      projectTypeNameEN
      fullNameTH
      fullNameEN
      auditTeamNameTH
      auditTeamNameEN
      projectSizeNameTH
      projectSizeNameEN
      files
      fileDetail
      riskFormTH
      riskFormEN
      organizationLevelNameTH
      organizationLevelNameEN
      planNameTH
      planNameEN
      planType
      auditTypeNameTH
      auditTypeNameEN
      projectStatusNameTH
      projectStatusNameEN
    }
  }
`

export const GETAPPROVEPROJECTNOTPLANAUDITORGANIZATIONPAGINATION = gql`
  query getApproveProjectNotPlanAuditOrganizationPagination($inputData: ApproveProjectNotPaginationInput!) {
    getApproveProjectNotPlanAuditOrganizationPagination(inputData: $inputData) {
      result {
        RowData
        organizationCode
        projectCode
        personnelCode
        organizationNameTH
        organizationNameEN
        organizationLevelNameTH
        organizationLevelNameEN
        fullNameTH
        fullNameEN
      }
      count
      page
      limit
    }
  }
`

export const GETPROJECTNOTPLANFILES = gql`
  query getProjectDataFiles($inputData: ProjectFileInput!) {
    getProjectDataFiles(inputData: $inputData) {
      fileId
      fileNameLabel
      fileNameGen
      fileType
      filepath
    }
  }
`

export const GETPROJECTNOTPLANAUDITTEAM = gql`
  query getProjectNotPlanAuditTeam {
    getProjectNotPlanAuditTeam {
      auditTeamId
      auditTeamCode
      auditTeamNameTH
      auditTeamNameEN
      isActive
    }
  }
`
