import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'antd'
import { ModalNew } from '../../../../components/modal/Modal.jsx'
import { Alert } from '../../../../components/alert/Alert'
import { useTranslation } from 'react-i18next'
import { InputNew } from '../../../../components/input/Input.jsx'
import { RadioGroup, Radio } from '../../../../components/radio/Radio.jsx'
import { LabelNew as Label } from '../../../../components/label/Label.jsx'
import { useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../../utilitys/crypto'
import { CONSULT_RESULT_EXPORT } from '../../../auditoperations/consultationresult/graphql/Query'
import { CONSULT_RESULT_REPORT_EXPORT } from '../graphql/Query'
// import { useNavigate } from 'react-router-dom'
// import { SpinnersNew } from '../../../../components/spinners/Spinners'

const ConsultationResultReportModal = (props) => {
  const { t } = useTranslation()
  const { visible, setVisible, data, dataRef, exportStatus } = props
  const [consultResultExportFn, consultResultExport] = useLazyQuery(CONSULT_RESULT_EXPORT)
  const [consultResultReportExportFn, consultResultReportExport] = useLazyQuery(CONSULT_RESULT_REPORT_EXPORT)
  const [disableAction, setDisableAction] = useState(false)
  const lang = localStorage.getItem('lang')
  const [form] = Form.useForm()
  useEffect(() => {
    setVisible(visible)
  }, [visible])

  useEffect(() => {
    const _ = data
    if (Object.keys(_).length !== 0) {
      form?.setFieldsValue({
        consultId: _?.consultId,
        jobCode: _?.jobCode,
        responsiblePersonnel: _?.responsiblePersonnel,
        maker: '',
        exportType: 'pdf',
      })
    }
  }, [data])

  useEffect(() => {
    if (consultResultExport.data) {
      const { fileUrl } = consultResultExport.data.consultResultExport
      window.open(fileUrl)
    }
    if (consultResultExport.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(consultResultExport.error.message),
        onOk() {},
      })
    }
    if (consultResultReportExport.data) {
      const { fileUrl } = consultResultReportExport.data.consultResultReportExport
      window.open(fileUrl)
    }
    if (consultResultReportExport.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(consultResultReportExport.error.message),
        onOk() {},
      })
    }
  }, [
    consultResultExport.data,
    consultResultExport.error,
    consultResultReportExport.data,
    consultResultReportExport.error,
  ])
  async function onSubmit(_) {
    if (exportStatus === 0) {
      await consultResultExportFn({
        variables: {
          input: encryptInput({
            consultId: _?.consultId,
            jobCode: _?.jobCode,
            responsiblePersonnel: _?.responsiblePersonnel,
            fileType: _?.fileType,
            waterMark: _?.waterMark || '',
            lang: lang,
          }),
        },
      })
    } else {
      await consultResultReportExportFn({
        variables: {
          input: encryptInput({
            consultId: dataRef.result.map((item) => ({ consultId: item.consultId })),
            jobCode: dataRef.result.map((item) => ({ jobCode: item.jobCode })),
            responsiblePersonnel: dataRef.result.map((item) => ({ fullName: item.fullName })),
            dateStart: dataRef.date.dateStart,
            dateEnd: dataRef.date.dateEnd,
            fileType: _?.fileType,
            waterMark: _?.waterMark || '',
            lang: lang,
          }),
        },
      })
    }
  }

  // Custom trow errors fields

  function onClose(e) {
    setVisible(e)
  }

  const fnConfirm = (values) => {
    // Alert({
    //   type: 'confirm',
    //   title: t('ต้องการยืนยันการบันทึก ?'),
    //   onOk() {
    //     onSubmit(values)
    //   },
    //   onCancel() {},
    // })
    onSubmit(values)
  }

  const onFinish = (values) => {
    fnConfirm(values)
  }

  return (
    <>
      {/* {loadingPersonnelEducations ? <SpinnersNew /> : ''} */}
      <ModalNew
        visible={visible}
        testTitle={t('พิมพ์รายงาน')}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        btnName={t('พิมพ์')}
        // type="medium"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="auditTeamForm"
        >
          <Form.Item
            label={<Label type="tab-header-inactive">{t('ประเภทรายงาน')}</Label>}
            name="fileType"
            initialValue={'pdf'}
            onClick={(e) => {
              if (e.target.value) {
                if (e.target.value !== 'pdf') {
                  form.setFieldsValue({
                    waterMark: '',
                  })
                  setDisableAction(true)
                } else {
                  setDisableAction(false)
                }
              }
            }}
          >
            <RadioGroup>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="pdf">
                    <Label type="tab-header-inactive">{t('PDF')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="word">
                    <Label type="tab-header-inactive">{t('Word')}</Label>
                  </Radio>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="excel">
                    <Label type="tab-header-inactive">{t('Excel')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="csv">
                    <Label type="tab-header-inactive">{t('CSV')}</Label>
                  </Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
          {!disableAction ? (
            <Form.Item name="waterMark" label={<Label type="tab-header-inactive">{t('ลายน้ำ')}</Label>}>
              <InputNew disabled={disableAction} />
            </Form.Item>
          ) : (
            ''
          )}
          <Form.Item name={'consultId'} hidden={true}>
            <InputNew />
          </Form.Item>
          <Form.Item name={'jobCode'} hidden={true}>
            <InputNew />
          </Form.Item>
          <Form.Item name={'responsiblePersonnel'} hidden={true}>
            <InputNew />
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}

export default ConsultationResultReportModal
