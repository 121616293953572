import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useNavigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { CardNew } from '../../../components/card/Card'
import { ColumnsApp } from './utils/approvePlanListDatas'
import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { Status } from '../../../components/status/Status'
import ApprovePlanListFillter from './component/fillter/ApprovePlanListFilter'
import { useLazyQuery } from '@apollo/client'
import * as _ from 'lodash'
import { GET_AUDIT_PLAN_BY_APPROVE_PLAN_PAGINATION } from './graphql/Query'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { useLanguage } from '../../../hooks/useLanguage'
import { checkLanguageReturnData } from './component/fnForApprove'
import { encryptInput } from '../../../utilitys/crypto'
import { Alert } from '../../../components/alert/Alert'
import { displayError } from '../../../utilitys/helper'
import { useDecodeUser } from '../../../hooks/useDecodeUser'

const Responsive = { sm: 24, md: 24, lg: 24 }

const ApprovePlanList = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { menu } = props
  localStorage.removeItem('pageState')
  const [isLang] = useLanguage()
  const [user] = useDecodeUser()

  const breadcrumbList = [
    { label: t('งานวางแผนการตรวจสอบ'), path: '/auditplanning/approveauditplan' },
    { label: t('อนุมัติแผนการตรวจสอบ') },
  ]

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [pCode, setPCode] = useState(null)

  const [getAuditPlanPaginationFn, getAuditPlanPagination] = useLazyQuery(GET_AUDIT_PLAN_BY_APPROVE_PLAN_PAGINATION, {})

  useEffect(() => {
    if (user?.pCode) setPCode(user?.pCode)
  }, [user?.pCode])

  useEffect(() => {
    if (pCode) {
      getAuditPlanPaginationFn({
        fetchPolicy: 'no-cache',
        variables: {
          input: {
            filters: encryptInput({ isActive: 1, personnelCode: pCode }),
            pagination: encryptInput({ limit, page }),
            sort: [],
          },
        },
      })
    }
  }, [page, limit, pCode])

  useEffect(() => {
    if (getAuditPlanPagination?.data) {
      let response = getAuditPlanPagination?.data?.getApprovePlanPagination
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (dt, keys) => {
          return {
            key: keys,
            no: dt.no,
            code: dt.planCode + ' : ' + checkLanguageReturnData(isLang, dt.planNameTH, dt.planNameEN),
            type: dt.planType === 1 ? t('แผนประจำปี') : t('แผนระยะยาว'),
            status: (
              <Status
                key={'status-apl-1' + keys}
                text={
                  dt.approveStatusCode === '10'
                    ? 'รอพิจารณา'
                    : dt.approveStatusCode === '20'
                    ? 'กำลังพิจารณา'
                    : 'พิจารณาแล้ว'
                }
              ></Status>
            ),
            options:
              dt.approveStatusCode === '20' ? (
                <div key={'options-apl-1' + keys} style={{ textAlign: 'center' }}>
                  <ButtonNew
                    key={'btn-options-approve1' + keys}
                    type="moreHorizontalInTableOrange"
                    roles={{ type: 'approve', menu: menu }}
                    onClick={() => {
                      const pageType = 'edit'
                      navigate('/auditplanning/approveauditplan/detail', {
                        state: {
                          pageType: pageType,
                          refCode: dt.planCode,
                        },
                      })
                    }}
                  >
                    {t('กำลังพิจารณา')}
                  </ButtonNew>
                </div>
              ) : dt.approveStatusCode === '10' ? (
                <div key={'options-2' + keys} style={{ textAlign: 'right' }}>
                  <ButtonNew
                    key={'btn-options-approve2' + keys}
                    type="checkCircleInTableBlue"
                    roles={{ type: 'approve', menu: menu }}
                    onClick={() => {
                      const pageType = 'edit'
                      navigate('/auditplanning/approveauditplan/detail', {
                        state: {
                          pageType: pageType,
                          refCode: dt.planCode,
                        },
                      })
                    }}
                  >
                    {t('พิจารณา')}
                  </ButtonNew>
                </div>
              ) : (
                <div key={'options-2' + keys} style={{ textAlign: 'right' }}>
                  <ButtonNew
                    key={'btn-options-approve3' + keys}
                    type="moreHorizontalInTableGreen"
                    roles={{ type: 'approve', menu: menu }}
                    onClick={() => {
                      const pageType = 'view'
                      navigate('/auditplanning/approveauditplan/detail', {
                        state: {
                          pageType: pageType,
                          refCode: dt.planCode,
                        },
                      })
                    }}
                  >
                    {t('พิจารณาแล้ว')}
                  </ButtonNew>
                </div>
              ),
            view: (
              <div key={'div-view' + keys} style={{ textAlign: 'left' }}>
                <ButtonGroup
                  key={'btn-view-approve' + keys}
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => {
                        const pageType = 'view'
                        navigate('/auditplanning/approveauditplan/detail', {
                          state: {
                            pageType: pageType,
                            refCode: dt.planCode,
                          },
                        })
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (getAuditPlanPagination.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getAuditPlanPagination.error)),
        onOk() {},
      })
    }
  }, [getAuditPlanPagination?.data])

  return (
    <>
      {getAuditPlanPagination.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('อนุมัติแผนการตรวจสอบ')} />
      <ApprovePlanListFillter
        getValue={(e) => {
          getAuditPlanPaginationFn({
            variables: {
              input: {
                filters: encryptInput({
                  personnelCode: user.pCode,
                  planYear: e.planYear ? e.planYear : '',
                  planType: e.planType ? e.planType : '',
                  planCode: e.planCode ? e.planCode : '',
                  planNameTH: e.planName ? e.planName : '',
                  organizationCode2: e.planOrganization2 ? e.planOrganization2 : '',
                  organizationCode3: !_.isEmpty(e.planOrganization3) ? e.planOrganization3 : '',
                  planStatusCode: e.planStatus ? e.planStatus : '',
                  isActive: 1,
                }),
                pagination: encryptInput({ limit, page }),
                sort: [],
              },
            },
          })
        }}
      />
      <CardNew topic={t('รายการแผนการตรวจสอบ')} icon="AlignJustify" toggledrop={'false'}>
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            <Datatable
              columns={ColumnsApp}
              data={[...dataLists]}
              total={count}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              paginationCustom={true}
              searchCustom={true}
              pageSize={limit}
              page={page}
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default ApprovePlanList
