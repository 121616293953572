import React from 'react'
import { Form, Input, Button, Space, Card, Row, Col } from 'antd'
import { MinusCircleOutlined } from '@ant-design/icons'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { ButtonNew } from '../../../components/button/Button'
import { multipleRowCode } from './text'
const FormExample = (props) => {
  const disabledCode = props.disabledCode || false
  const onFinish = (values) => {
    console.log('Received values of form:', values)
  }
  return (
    <>
      <Row style={{ marginTop: 24 }}>
        <Col span={24}>
          <Card title="Form multiple rows" extra={<a href="#">More</a>} style={{ width: '100%' }}>
            <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
              <Form.List name="users">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="middle">
                        <Form.Item
                          {...restField}
                          name={[name, 'first']}
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'last']}
                          rules={[{ required: true, message: 'Missing last name' }]}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      {/* <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add field
                      </Button> */}
                      <ButtonNew type="addMultiple" onClick={() => add()}>
                        เพิ่มข้อมูล
                      </ButtonNew>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
            {disabledCode ? (
              <>
                <Col span={24} style={{ marginTop: 24 }}>
                  <CodeBlock code={multipleRowCode} />
                </Col>
              </>
            ) : null}
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default FormExample
