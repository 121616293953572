import React, { useState } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import ActionExplainFilter from './ActionExplainFilter.jsx'
import ActionExplainList from './ActionExplainList'

const ActionExplainIndex = (props) => {
  const { t } = useTranslation()
  const breadcrumbList = [
    { label: t('งานติดตามผลการตรวจสอบ'), path: '/followup/actionexplain' },
    { label: t('ดำเนินการชี้แจง') },
  ]
  const [formFilter] = Form.useForm()
  const [searchStatus, setSearchStatus] = useState(0)
  return (
    <>
      <Row>
        <Col md={24}>
          <Breadcrumb data={breadcrumbList} title={t('ดำเนินการชี้แจง')} menu={props.menu} />
          <ActionExplainFilter
            menu={props.menu}
            formFilter={formFilter}
            setSearchStatus={setSearchStatus}
            searchStatus={searchStatus}
          />
          <ActionExplainList menu={props.menu} formFilter={formFilter} searchStatus={searchStatus} />
        </Col>
      </Row>
    </>
  )
}

export default ActionExplainIndex
