import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../../../components/breadcrumb/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { CardNew } from '../../../../../components/card/Card.jsx'
import { TabNew } from '../../../../../components/tab/Tab.jsx'
import { getSegmentUrl } from '../../../../../utilitys/helper'
import FormAuditProjectsGeneral from './FormAuditProjectsGeneral'
import FormAuditProjectsTeam from './FormAuditProjectsTeam'
import FormAuditProjectsDocument from './FormAuditProjectsDocument'
import {
  AUDITPROJECT_CHECK_PLANCODE,
  // AUDITPROJECT_CHECK_PLANCODE,
  CHECK_AUDITPLAN_AND_PROJECT_HAS_ITEM,
} from '../graphql/Query'
import { useLazyQuery } from '@apollo/client'
import { Alert } from '../../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'

const FormAuditProjectsIndex = (props) => {
  const { state } = useLocation()
  if (!state) return <Navigate to={'/auditplanning/auditplan/'} />
  /*
    -add- state.refCode = planCode
    -edit- state.refCode = projectCode
    -view- state.refCode = projectCode
  */
  const { t } = useTranslation()
  const type = getSegmentUrl(5)
  const params = getSegmentUrl(4)
  const navigate = useNavigate()

  const breadcrumbList = [
    { label: t('งานวางแผนการตรวจสอบ'), path: '/auditplanning/auditplan' },
    { label: t('แผนการตรวจสอบ'), path: '/auditplanning/auditplan' },
    { label: t('จัดการข้อมูลโครงการ'), path: '/auditplanning/auditplan' },
    { label: t('โครงการ') },
  ]

  /* redirec for project list must planCode only but state.refCode at send to have planCode or projectCode */
  const [checkAuditPlanAndProjectHasItemFn, checkAuditPlanAndProjectHasItem] = useLazyQuery(
    CHECK_AUDITPLAN_AND_PROJECT_HAS_ITEM,
  )
  useEffect(() => {
    if (checkAuditPlanAndProjectHasItem?.data) {
      let response = checkAuditPlanAndProjectHasItem?.data?.checkAuditPlanAndProjectHasItem
      if (response) {
        if (!response.status) {
          navigate('/auditplanning/auditplan')
        }
      }
    }
    if (checkAuditPlanAndProjectHasItem.error) {
      showAlertLoadDataError(displayError(checkAuditPlanAndProjectHasItem.error))
    }
  }, [checkAuditPlanAndProjectHasItem.data])

  // ====================================================== API Check PlanCode =============================
  const [planCode, setPlanCode] = useState('')

  const [auditProjectsCheckPlanCodeFn, auditProjectsCheckPlanCode] = useLazyQuery(AUDITPROJECT_CHECK_PLANCODE)
  useEffect(() => {
    if (auditProjectsCheckPlanCode?.data) {
      let response = auditProjectsCheckPlanCode?.data?.auditProjectsCheckPlanCode
      if (response) {
        setPlanCode(response.planCode)
      }
    }
    if (auditProjectsCheckPlanCode.error) {
      showAlertLoadDataError(displayError(auditProjectsCheckPlanCode.error))
    }
  }, [auditProjectsCheckPlanCode.data])

  useEffect(() => {
    if (planCode) {
      settabs(getTabsFn())
    }
  }, [planCode])

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  useEffect(() => {
    auditProjectsCheckPlanCodeFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          action: type,
          code: state.refCode,
        }),
      },
    })
    checkAuditPlanAndProjectHasItemFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          action: type,
          code: state.refCode,
        }),
      },
    })
  }, [type, params])

  const getTabsFn = () => {
    return [
      {
        key: 1,
        iconTab: () => <></>,
        name: t('ข้อมูลทั่วไปโครงการ'),
        disabled: type === 'add' ? true : false,
        content: () => (
          <FormAuditProjectsGeneral
            menu={props.menu}
            typeForm={type}
            refCode={state.refCode}
            params={params}
            type={type}
            planCode={planCode}
          />
        ),
      },
      {
        key: 2,
        iconTab: () => <></>,
        name: t('ผู้รับผิดชอบ/ระยะเวลา'),
        disabled: type === 'add' ? true : false,
        content: () => (
          <FormAuditProjectsTeam
            menu={props.menu}
            params={params}
            typeForm={type}
            planCode={planCode}
            refCode={state.refCode}
          />
        ),
      },
      {
        key: 3,
        iconTab: () => <></>,
        name: t('เอกสารเพิ่มเติม'),
        disabled: type === 'add' ? true : false,
        content: () => (
          <FormAuditProjectsDocument
            menu={props.menu}
            params={params}
            typeForm={type}
            planCode={planCode}
            refCode={state.refCode}
          />
        ),
      },
    ]
  }
  const [tabs, settabs] = useState(getTabsFn())

  return (
    <>
      {(checkAuditPlanAndProjectHasItem.loading || checkAuditPlanAndProjectHasItem.loading) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('โครงการ')} menu={props.menu} />
      <Row>
        <Col md={24}>
          <CardNew
            topic={t('โครงการ')}
            icon={type === 'add' ? 'Plus' : type === 'edit' ? 'Edit2' : 'FileText'}
            toggledrop="false"
          >
            <TabNew
              tab={tabs}
              activeKey={params === 'general' ? '1' : params === 'team' ? '2' : params === 'document' ? '3' : ''}
              onTabClick={(key) => {
                if (key === '1') {
                  navigate('/auditplanning/auditplan/auditproject/general/' + type, {
                    state: { refCode: state.refCode },
                  })
                } else if (key === '2') {
                  navigate('/auditplanning/auditplan/auditproject/team/' + type, {
                    state: { refCode: state.refCode },
                  })
                } else if (key === '3') {
                  navigate('/auditplanning/auditplan/auditproject/document/' + type, {
                    state: { refCode: state.refCode },
                  })
                }
              }}
            />
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default FormAuditProjectsIndex
