import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_AUDITOPEN_ORGANIZATION } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectAuditOpenOrganizations = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { openId } = filters || {}

  const [isData, isSetData] = useState([])

  const [getAuditOpenOrganizationsSelectFn, getAuditOpenOrganizationsSelect] = useLazyQuery(GET_AUDITOPEN_ORGANIZATION)

  useEffect(() => {
    getAuditOpenOrganizationsSelectFn({
      variables: { input: encryptInput({ openId: openId }) },
    })
  }, [openId])

  useEffect(() => {
    if (getAuditOpenOrganizationsSelect.data) {
      isSetData(
        _.map(getAuditOpenOrganizationsSelect?.data?.getAuditOpenOrganizationsSelect, (item) => {
          return {
            label: item?.organizationCode + ' : ' + displayText(item?.organizationNameTH, item?.organizationNameEN),
            value: item?.organizationCode,
            source: item,
          }
        }),
      )
    }
  }, [getAuditOpenOrganizationsSelect.data])
  const setOnChange = props.multiple
    ? {
        onChange: (value) => {
          handleChange(value)
        },
      }
    : { onChange: (value) => handleChange(_.find(isData, (data) => data.value === value)) }
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getAuditOpenOrganizationsSelect.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...setOnChange}
      />
    </>
  )
}

export default SelectAuditOpenOrganizations
