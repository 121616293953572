import { gql } from '@apollo/client'

export const GET_ACTION_AUDIT_PLAN = gql`
  query getActionAuditPlans($data: ActionsAuditPlanInput!) {
    getActionAuditPlans(data: $data) {
      actionPlanId
      projectCode
      projectNameTH
      projectNameEN
      riskModelCode
      riskModelTH
      riskModelEN
    }
  }
`
