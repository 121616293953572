import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_SELECT_ACTION_PLAN_PERSONNELS } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
// import { displayText } from '../../../utilitys/helper'

const SelectActionPlanPersonnels = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { projectCode } = filters || {}

  const [isData, isSetData] = useState([])

  const [cellGet, { data, loading }] = useLazyQuery(GET_SELECT_ACTION_PLAN_PERSONNELS)

  useEffect(() => {
    cellGet({
      variables: { input: encryptInput({ ...filters }) },
    })
  }, [projectCode])

  useEffect(() => {
    if (data) {
      isSetData(
        _.map(data?.getSelectActionPlanPersonnels, (item) => {
          return {
            value: item.personnelCode,
            label: item.fullName,
            source: item,
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        // multiple
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          handleChange(value)
        }}
      />
    </>
  )
}

export default SelectActionPlanPersonnels
