import { gql } from '@apollo/client'

export const GET_MASTER_JOB_GROUPS = gql`
  query getMasterJobGroups($input: MasterJobGroupsInput!) {
    getMasterJobGroups(input: $input) {
      jobGroupId
      jobGroupCode
      jobGroupNameTH
      jobGroupNameEN
      isActive
    }
  }
`
