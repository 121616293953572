import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../../components/breadcrumb/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { displayText, getSegmentUrl } from '../../../../utilitys/helper'
import { CardNew } from '../../../../components/card/Card.jsx'
import FilterAuditProjectsList from './FilterAuditProjectsList.jsx'
import { TabNew } from '../../../../components/tab/Tab.jsx'
import AuditProject from './AuditProject'
import AuditProjectList from './AuditProjectList'
import { GET_AUDITPLAN } from '../plan/graphql/Query'
import { useLazyQuery } from '@apollo/client'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { Alert } from '../../../../components/alert/Alert'
import { ButtonNew } from '../../../../components/button/Button'
import { displayError } from '../../../../utilitys/helper'

const AuditProjectsIndex = (props) => {
  const { state } = useLocation()
  if (!state) return <Navigate to={'/auditplanning/auditplan/'} />
  /* state.refCode = planCode */
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = getSegmentUrl(4)
  const [formFilter] = Form.useForm()
  const [searchStatus, setSearchStatus] = useState(0)
  const [topic, setTopic] = useState('')
  const breadcrumbList = [
    { label: t('งานวางแผนการตรวจสอบ'), path: '/auditplanning/auditplan' },
    { label: t('แผนการตรวจสอบ'), path: '/auditplanning/auditplan' },
    { label: t('จัดการข้อมูลโครงการ') },
  ]

  const [getAuditPlanFn, getAuditPlan] = useLazyQuery(GET_AUDITPLAN)
  useEffect(() => {
    getAuditPlanFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          lang: localStorage.getItem('lang'),
          planCode: state.refCode,
        }),
      },
    })
  }, [])
  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }
  useEffect(() => {
    if (getAuditPlan?.data) {
      let response = getAuditPlan?.data?.getAuditPlan
      if (response.length) {
        setTopic(response[0].planCode + ' : ' + displayText(response[0].planNameTH, response[0].planNameEN))
      }
    }
    if (getAuditPlan.error) {
      showAlertLoadDataError(displayError(getAuditPlan.error))
    }
  }, [getAuditPlan.data])
  const tabs = [
    {
      key: 1,
      iconTab: () => <></>,
      name: t('ตารางข้อมูลโครงการ'),
      disabled: false,
      content: () => (
        <>
          {params !== 'list' ? (
            <AuditProject
              menu={props.menu}
              formFilter={formFilter}
              setSearchStatus={setSearchStatus}
              searchStatus={searchStatus}
              refCode={state.refCode}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
    {
      key: 2,
      iconTab: () => <></>,
      name: t('จัดการข้อมูลโครงการ'),
      disabled: false,
      content: () => (
        <>
          {params === 'list' ? (
            <AuditProjectList
              formFilter={formFilter}
              setSearchStatus={setSearchStatus}
              searchStatus={searchStatus}
              menu={props.menu}
              refCode={state.refCode}
            />
          ) : (
            ''
          )}
        </>
      ),
    },
  ]
  return (
    <>
      {getAuditPlan.loading && <SpinnersNew />}

      <Row>
        <Col md={24}>
          <Breadcrumb data={breadcrumbList} title={t('จัดการข้อมูลโครงการ')} menu={props.menu} />
          <FilterAuditProjectsList
            topic={topic}
            formFilter={formFilter}
            setSearchStatus={setSearchStatus}
            searchStatus={searchStatus}
            menu={props.menu}
          />
          <CardNew topic={topic} icon="AlignJustify" toggledrop="false">
            <TabNew
              tab={tabs}
              activeKey={params !== 'list' ? '1' : '2'}
              onTabClick={(key) => {
                if (key === '1') {
                  navigate('/auditplanning/auditplan/auditproject/', {
                    state: { refCode: state ? state.refCode : '' },
                  })
                } else if (key === '2') {
                  navigate('/auditplanning/auditplan/auditproject/list', {
                    state: { refCode: state ? state.refCode : '' },
                  })
                }
              }}
            />
            <Row gutter={[12, 12]} justify="center" style={{ marginTop: '50px' }}>
              <Col>
                <ButtonNew
                  type="cancel"
                  onClick={() => {
                    navigate('/auditplanning/auditplan')
                  }}
                >
                  {t('ย้อนกลับ')}
                </ButtonNew>
              </Col>
            </Row>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default AuditProjectsIndex
