import React, { useState } from 'react'
import { Row, Col, Table } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { dateTimeDisplay } from '../../../utilitys/dateHelper'
import { displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { IconNew } from '../../../components/icon/Icon'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import RiskResultListFilter from './filters/RiskResultListFilter'
import RickResultModal from './modal/RickResultModal'
import { RowStyle, ExpandStyle } from './css/Styles'
import { Status } from '../../../components/status/Status'
const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
}
const RiskResultList = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { menu } = props
  const [dataLists, setDataLists] = useState([])
  const [operationStatusCode, setOperationStatusCode] = useState([])
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' },
  ])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [data, setData] = useState({})
  const [reFetch, setReFetch] = useState(false)
  const [modal, setModal] = useState(false)
  const Columns = [
    {
      title: t('ชื่อแบบประเมิน'),
      dataIndex: 'riskFormName',
      width: '30%',
      sorter: { multiple: 1 },
    },
    {
      title: t('ประเภทประเมิน'),
      dataIndex: 'riskFormType',
      width: '20%',
      render: (row) => {
        return row === 1 ? (
          <>
            <span>{t('ประเมินสรุป')}</span>
          </>
        ) : (
          <>
            <span>{t('ประเมินรายหน่วยงาน')}</span>
          </>
        )
      },
    },
    {
      title: t('หน่วยงานตรวจสอบ'),
      dataIndex: 'organizationAuditName',
      width: '10%',
    },
    {
      title: t('ผู้รับผิดชอบ'),
      dataIndex: 'ownerName',
      width: '20%',
    },
    {
      title: t('สถานะ'),
      dataIndex: '_operationStatusCode',
      width: '10%',
      render: (_operationStatusCode) => {
        switch (_operationStatusCode) {
          case '10':
            return <Status text={'ยังไม่ประเมิน'} />
          case '20':
            return <Status text={'กำลังประเมิน'} />
          case '30':
            return <Status text={'ประเมินเสร็จสิ้น'} />
          default:
            return <></>
        }
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '5%',
    },
  ]
  const column_ = [
    {
      title: t('ผู้ประเมิน'),
      dataIndex: 'responsibilityByName',
      key: 'responsibilityByName',
    },
    {
      title: t('หน่วยรับตรวจ'),
      dataIndex: 'organizationName',
      key: 'organizationName',
    },
    {
      title: t('วันเวลาที่ประเมิน'),
      dataIndex: 'succeedAt',
      key: 'succeedAt',
      render: (field) => <>{dateTimeDisplay(field)}</>,
    },
    {
      title: t('สถานะ'),
      dataIndex: 'operationStatusCode',
      key: 'operationStatusCode',
      width: '10%',
      render: (operationStatusCode) => {
        switch (operationStatusCode) {
          case '11':
            return <Status text={'ยังไม่ประเมิน'} />
          case '12':
            return <Status text={'ยังไม่ประเมิน'} />
          case '21':
            return <Status text={'กำลังประเมิน'} />
          case '22':
            return <Status text={'กำลังประเมิน'} />
          case '31':
            return <Status text={'ประเมินเสร็จสิ้น'} />
          default:
            return <></>
        }
      },
    },
    {
      dataIndex: 'tools',
      key: 'tools',
      render: (_, field) => {
        const data_ = {
          riskResultId: field.riskResultId,
          riskFormCode: field.riskFormCode,
          riskFormTH: field.riskFormTH,
          riskFormEN: field.riskFormEN,
          type: 'unit',
        }
        return (
          <>
            <ButtonGroup
              menu={menu}
              icons={[
                {
                  type: 'view',
                  onClick: () => {
                    navigate(`riskresultdetail`, {
                      state: {
                        riskResultId: field.riskResultId,
                        riskFormInitial: {
                          Year: field.Year,
                          riskFormType: field.riskFormType,
                          riskFormTH: field.riskFormTH,
                          riskFormEN: field.riskFormEN,
                        },
                        type_action: 'view',
                        view_type: 'unit',
                      },
                    })
                  },
                },
                {
                  type: 'download',
                  onClick: () => {
                    Promise.all([setOperationStatusCode(field.operationStatusCode), setData(data_), setModal(true)])
                  },
                },
              ]}
            />
          </>
        )
      },
    },
  ]
  function getDataListFromFilter(value) {
    setDataLists([
      ...value.map((item, idx) => {
        const data_ = {
          riskResultId: item.riskResultId,
          riskFormCode: item.riskFormCode,
          riskFormTH: item.riskFormTH,
          riskFormEN: item.riskFormEN,
          type: 'all',
        }
        const btn = [
          {
            type: 'view',
            path: '/button/view',
            onClick: () => {
              navigate(`riskresultdetail`, {
                state: {
                  riskResultId: item.riskResultId,
                  selects: item.riskResultIdSelects,
                  riskFormInitial: {
                    Year: item.Year,
                    riskFormType: item.riskFormType,
                    riskFormTH: item.riskFormTH,
                    riskFormEN: item.riskFormEN,
                  },
                  type_action: 'view',
                  view_type: 'all',
                },
              })
            },
          },
          {
            type: 'download',
            onClick: () =>
              Promise.all([setOperationStatusCode(item.operationStatusCode), setData(data_), setModal(true)]),
          },
        ]
        return {
          key: idx + 1,
          Year: item.Year,
          riskFormCode: item.riskFormCode,
          riskResultId: item.riskResultId,
          riskFormName: displayText(item.riskFormTH, item.riskFormEN),
          riskFormType: item.riskFormType,
          makerBy: item.makerBy,
          responsibilityBy: item.responsibilityBy,
          organizationAuditName: displayText(item.organizationAuditNameTH, item.organizationAuditNameEN),
          ownerName: displayText(item.ownerNameTH, item.ownerNameEN),
          _operationStatusCode: item._operationStatusCode,
          makerStatusCode: item.makerStatusCode,
          operationStatusCodeBtn: item.operationStatusCode,
          description: (
            <>
              <RowStyle>
                <Table
                  rowClassName={(record) => (record.factorActHead ? 'table-row-color' : '')}
                  columns={column_}
                  dataSource={[
                    ...item.riskResultsList.map((item, idx) => ({
                      ...item,
                      key: idx + 1,
                      responsibilityByName: displayText(item.responsibilityByNameTH, item.responsibilityByNameEN),
                      organizationName: displayText(item.organizationNameTH, item.organizationNameEN),
                    })),
                  ]}
                  pagination={false}
                />
              </RowStyle>
            </>
          ),
          tools: (
            <>
              <ButtonGroup menu={menu} icons={btn} />
            </>
          ),
        }
      }),
    ])
  }
  const breadcrumbList = [
    { label: t('งานประเมินความเสี่ยง'), path: '/riskassessment/riskresult' },
    { label: t('ผลประเมินความเสี่ยง') },
  ]
  return (
    <>
      <Breadcrumb data={breadcrumbList} title={t('ผลประเมินความเสี่ยง')} />
      <RiskResultListFilter
        page={page}
        limit={limit}
        menu={menu}
        sort={sort}
        reFetch={reFetch}
        getCount={(e) => setCount(e)}
        getValue={(v) => getDataListFromFilter(v)}
      />
      <CardNew topic={t('รายการแบบประเมินความเสี่ยง')} icon="List" toggledrop={'false'}>
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <ExpandStyle>
              <Datatable
                columns={Columns}
                data={dataLists}
                paginationCustom={true}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                total={count}
                pageSize={limit}
                page={page}
                expandable={{
                  expandedRowRender: (record) => record.description,
                  rowExpandable: (record) => record.name !== 'Not Expandable',
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <IconNew icon={'ChevronUp'} style={{ cursor: 'pointer' }} onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <IconNew
                        icon={'ChevronDown'}
                        style={{ cursor: 'pointer' }}
                        onClick={(e) => onExpand(record, e)}
                      />
                    ),
                }}
                handleTableChange={(e) => {
                  const reNewField = [{ riskFormName: displayText('riskFormTH', 'riskFormEN') }]
                  setSort(handleSort(e?.sorter, reNewField))
                }}
              />
            </ExpandStyle>
          </Col>
        </Row>
      </CardNew>
      <RickResultModal
        data={data}
        operationStatusCode={operationStatusCode}
        open={modal}
        onSuccess={(e) => {
          setReFetch(reFetch ? false : true)
          setModal(e)
        }}
        close={(e) => setModal(e)}
      />
    </>
  )
}

export default RiskResultList
