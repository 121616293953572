import { gql } from '@apollo/client'
export const GET_SELECT_AUDIT_PROJECTS_BY_ACTION_PLAN = gql`
  query GetSelectAuditProjectsByActionPlan($input: ActionPlanSelectAuditProjectGetInput!) {
    getSelectAuditProjectsByActionPlan(input: $input) {
      projectCode
      projectNameTH
      projectNameEN
      planCode
      planNameTH
      planNameEN
      projectTypeCode
      auditTypeCode
      projectRefCode
      projectRefNameTH
      projectRefNameEN
      riskFormCode
      riskFormTH
      riskFormEN
      projectSizeCode
      manday
      auditTeamCode
      auditTeamNameTH
      auditTeamNameEN
      personnelCode
      personnelNameTH
      personnelNameEN
      numberOfPeople
      mandayPersonnal
      organizationCode
      organizationNameTH
      organizationNameEN
      dateStart
      dateEnd
      approveStatusCode
      approvePersonnelCode
      approveDate
      approveRemarkTH
      approveRemarkEN
      projectStatusCode
      cancelRemarkTH
      cancelRemarkEN
      files
      fileDetail
      isActive
    }
  }
`

export const GET_LIST_ACTION_PLAN_PAGINATION = gql`
  query GetListActionPlanPagination($input: ActionPlanPaginationInput!) {
    getListActionPlanPagination(input: $input) {
      result {
        no
        actionPlanId
        projectCode
        projectNameTH
        projectNameEN
        projectRefCode
        projectRefNameTH
        projectRefNameEN
        dateStart
        dateEnd
        organizationCode
        organizationNameTH
        organizationNameEN
        approver
        personnelNameTH
        personnelNameEN
        approveStatusCode
        isActive
        auditTeamCode
        approveStatusNameTH
        approveStatusNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_ACTION_PLAN_BY_ID = gql`
  query GetActionPlanByID($input: ActionPlanGetInput!) {
    getActionPlanByID(input: $input) {
      actionPlanId
      projectCode
      dateJust
      approver
      approveStatusCode
      isActive
    }
  }
`
export const GET_SELECT_AUDIT_JOB_STEP_BY_ACTION_PLAN_ID = gql`
  query GetSelectAuditJobStepByActionPlanId($input: AuditJobStepSelectInput!) {
    getSelectAuditJobStepByActionPlanId(input: $input) {
      stepId
      stepSort
      stepNameTH
      stepNameEN
      dateStart
      dateEnd
      isActive
    }
  }
`

export const GET_HOLIDAY = gql`
  query auditProjectGetCalendarDetailsType1 {
    auditProjectGetCalendarDetailsType1 {
      dateAt
    }
  }
`

export const GET_MEETINGDAY = gql`
  query auditProjectGetCalendarDetailsType2 {
    auditProjectGetCalendarDetailsType2 {
      dateAt
    }
  }
`

export const GET_LIST_ACTION_PLAN_CALENDAR_PAGINATION = gql`
  query GetListActionPlanCalendarPagination($input: ActionPlanPaginationInput!) {
    getListActionPlanCalendarPagination(input: $input) {
      result {
        auditJobSteps {
          operationStatusCode
          isActive
        }
        no
        actionPlanId
        projectCode
        projectNameTH
        projectNameEN
        projectRefCode
        projectRefNameTH
        projectRefNameEN
        dateStart
        dateEnd
        organizationCode
        organizationNameTH
        organizationNameEN
        approver
        personnelNameTH
        personnelNameEN
        approveStatusCode
        auditTeamCode
        isActive
      }
      count
      limit
      page
    }
  }
`

export const GET_EXPORT_ACTION_PLAN = gql`
  query ExportActionPlan($input: ExportActionPlanInput!) {
    exportActionPlan(input: $input) {
      message
      fileUrl
    }
  }
`
