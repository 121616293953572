import { gql } from '@apollo/client'

export const GET_STEP_MODELS = gql`
  query getStepModels {
    getStepModels {
      stepModelCode
      stepModelTH
      stepModelEN
      isActive
    }
  }
`
