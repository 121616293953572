import React, { useState, useEffect } from 'react'
import { CodeBlock as CodeBlockOld } from 'react-code-blocks'
import { Card, Row, Col } from 'antd'
import FormFiltersBasicNew from '../../../components/form/FormFiltersBasic'
import { FormfiltersbasicExampleCode } from './text'
const dataSelect = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
]
const FormfiltersbasicExample = () => {
  const [inputList, setInputList] = useState([])
  useEffect(() => {
    //   // type of input
    //   text
    //   number
    //   select
    //   select_search
    //   select_multiple
    //   datepicker
    setInputList([
      {
        type: 'text',
        name: 'text',
        label: 'Example input text',
        placeholder: 'press enter text',
        rules: [{ required: true, message: 'required' }],
      },
      {
        type: 'number',
        name: 'number',
        label: 'Example input number',
        placeholder: 'press enter number',
        rules: [{ required: true, message: 'required' }],
      },
      {
        type: 'datepicker',
        name: 'datepicker1', // hidden input value
        nameThowError: 'datepicker1', // is show input
        label: 'Example input datepicker',
        placeholder: 'press enter datepicker',
        rules: [{ required: true, message: 'required' }],
      },
      {
        type: 'datepicker',
        name: 'datepicker2', // hidden input value
        nameThowError: 'datepicker2', // is show input
        label: 'Example input datepicker',
        placeholder: 'press enter datepicker',
        rules: [{ required: true, message: 'required' }],
      },
      {
        type: 'select',
        name: 'select',
        data: dataSelect,
        label: 'Example input select',
        placeholder: 'press enter select',
        // rules: [{ required: true, message: 'required' }],
      },
      {
        type: 'select_search',
        name: 'selectSearch',
        data: dataSelect,
        label: 'Example input select search',
        placeholder: 'press enter select search',
        // rules: [{ required: true, message: 'required' }],
      },
      {
        type: 'select_multiple',
        name: 'selectMultiple',
        data: dataSelect,
        label: 'Example input select multiple',
        placeholder: 'press enter select multiple',
        rules: [{ required: true, message: 'required' }],
      },
    ])
  }, [])
  function getValue(value) {
    console.log('getvalue', value)
  }
  return (
    <>
      <Row style={{ marginTop: 24 }}>
        <Col sm={24} md={24} lg={24}>
          <Card title={`Dynamic Form Filters Example`}>
            <FormFiltersBasicNew inputs={inputList} formName="filters" getValue={(value) => getValue(value)} />
            <CodeBlockOld
              text={FormfiltersbasicExampleCode}
              language={'jsx'}
              showLineNumbers={false}
              theme={'a11yLight'}
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FormfiltersbasicExample
