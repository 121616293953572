import React, { useState } from 'react'
import { decryptData } from '../utilitys/crypto'
export const AuthContext = React.createContext('')
const ciphertext = localStorage.getItem('user') ? localStorage.getItem('user') : ''
const authContext = ({ children }) => {
  const data = ciphertext ? decryptData(ciphertext) : null
  const [user, setUser] = useState({
    loginName: data ? data.username : null,
    fullname: data ? data.fullname : null,
    userId: data ? data.userId : null,
    orgNameTH: data ? data.orgNameTH : null,
    orgNameEN: data ? data.orgNameEN : null,
    pCode: data ? data.pCode : null,
  })
  return <AuthContext.Provider value={[user, setUser]}>{children}</AuthContext.Provider>
}
export default authContext
