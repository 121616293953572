import { gql } from '@apollo/client'

export const GET_LIST_PREPARE_AUDIT_REPORT_PAGINATION = gql`
  query getListPrepareAuditReportPagination($input: PrepareAuditReportListPagenation!) {
    getListPrepareAuditReportPagination(input: $input) {
      result {
        no
        finalReportId
        documentNo
        titleNameTH
        titleNameEN
        organizationNameTH
        organizationNameEN
        projectCode
        projectNameTH
        projectNameEN
        sendFrom
        sendTo
        sendCC
        organizationCodeReport
        startDateReport
        endDateReport
        status
      }
      count
      page
      limit
    }
  }
`
export const GET_PREPARE_AUDIT_REPORT_BY_CODE = gql`
  query getPrepareAuditReportByCode($input: PrepareAuditReportByCodeInput!) {
    getPrepareAuditReportByCode(input: $input) {
      finalReportId
      finalReportDetailId
      documentNo
      titleNameTH
      titleNameEN
      forNameTH
      forNameEN
      sendTo
      sendCC
      sendFrom
      auditObjectives
      projectCode
      scopeWork
      detail
      detailFinal
      organizationCodeReport
      personnelCreateCode
      personalCreateReport
      personalCheckReport
      startDateReport
      endDateReport
      signingDate
      files
      fileDetails
      status
      positionNameTH
      positionNameEN
      organizationNameTH
      organizationNameEN
      emailSendTo {
        email
        personnelCode
      }
      emailSendCC {
        email
        personnelCode
      }
    }
  }
`

export const GET_PREPARE_AUDIT_REPORT_DETAIL_BY_CODE = gql`
  query getPrepareAuditReportDetailByCode($input: PrepareAuditReportByCodeInput!) {
    getPrepareAuditReportDetailByCode(input: $input) {
      finalReportDetailId
      documentNo
      finalReportId
      issue
      rules
      fact
      cause
      risk
      control
      summaryTest
      feedback
      conlusionActionExecutive
      resultAssessmentRemark
    }
  }
`

export const GET_PREPARE_AUDIT_REPORT_EXPORT = gql`
  query getPrepareAuditReportExport($input: PrepareAuditReportExportInput!) {
    getPrepareAuditReportExport(input: $input) {
      message
      fileUrl
    }
  }
`
