import { gql } from '@apollo/client'
export const UPSERT_AUDIT_CLOSES = gql`
  mutation upSertAuditCloses($input: AuditCloseInput!) {
    upSertAuditCloses(input: $input) {
      status
    }
  }
`

export const UPDATE_RISK_FORM = gql`
  mutation updateRiskForm($input: RiskFormInput!) {
    updateRiskForm(input: $input) {
      riskFormCode
      riskFormTH
    }
  }
`

export const DELETE_AUDIT_CLOSE = gql`
  mutation deleteAuditCloses($input: AuditCloseInput!) {
    deleteAuditCloses(input: $input) {
      status
    }
  }
`

export const SEND_MAIL_RISK_FORM = gql`
  mutation sendMailRiskForm($input: sendMailRiskFormInput!) {
    sendMailRiskForm(input: $input) {
      riskFormCode
    }
  }
`

export const SAVE_SUGGEST = gql`
  mutation auditSuggestSave($input: AuditClosesSuggestOfIssuesInput!) {
    auditSuggestSave(input: $input) {
      suggestId
    }
  }
`
export const DELETE_SUGGEST = gql`
  mutation auditSuggestDelete($suggestId: String!) {
    auditSuggestDelete(suggestId: $suggestId)
  }
`

export const SAVE_SEND_EMAIL_SUGGEST = gql`
  mutation sendEmailSuggestSave($input: SendMailSuggestInput!) {
    sendEmailSuggestSave(input: $input) {
      sendId
    }
  }
`
export const DELETE_AUDIT_ISSUE = gql`
  mutation AuditIssueDelete($issueId: String!, $projectCode: String!) {
    auditIssueDelete(issueId: $issueId, projectCode: $projectCode) {
      issueId
    }
  }
`

export const CREATE_AUDIT_CAUSE_OF_ISSUE = gql`
  mutation SoiAuditCommitteeCreateCause($input: AuditCauseOfIssuesInput!) {
    SoiAuditCommitteeCreateCause(input: $input)
  }
`

export const EDIT_AUDIT_CAUSE_OF_ISSUE = gql`
  mutation SoiAuditCommitteeEditCause($input: AuditCauseOfIssuesInput!) {
    SoiAuditCommitteeEditCause(input: $input)
  }
`

export const DELETE_CAUSE_ISSUE = gql`
  mutation SoiAuditCommitteeDeleteCause($input: AuditCauseId!) {
    SoiAuditCommitteeDeleteCause(input: $input)
  }
`
export const CREATE_AUDIT_ISSUE = gql`
  mutation SoiAuditCommitteeCreateIssue($input: AuditIssuesInput!) {
    SoiAuditCommitteeCreateIssue(input: $input)
  }
`

export const EDIT_AUDIT_ISSUE = gql`
  mutation SoiAuditCommitteeEditIssue($input: AuditIssuesInput!) {
    SoiAuditCommitteeEditIssue(input: $input)
  }
`
