import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import { CardNew as Card } from '../../../../../components/card/Card'
import { ButtonNew } from '../../../../../components/button/Button'
import { LabelNew } from '../../../../../components/label/Label'
import { InputNew } from '../../../../../components/input/Input'
import SelectIsActive from '../../../../../components/inputfromapi/selectisactive/SelectIsActive.jsx'
import SelectOrganization from '../../../../../components/inputfromapi/selectorganization/SelectOrganization'

export default function UsersListFilter(props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { menu, getValue } = props
  const formName = 'from-approve-fillter'

  const Responesive = {
    inputFilter: { md: 12, lg: 8 },
    buttonFilter: { md: 24, lg: 24 },
  }

  const onFinish = (values) => getValue(values)

  const onClear = () => {
    form.resetFields()
    getValue(null)
  }

  return (
    <>
      <Card topic={t('Filters')} icon="Filter" toggledrop={'false'}>
        <Row style={{ marginTop: 24 }}>
          <Col sm={24} md={24} lg={24}>
            <Form form={form} onFinish={onFinish} name={formName} layout="vertical">
              <Row gutter={[16, 8]}>
                {/* <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="email"
                    rules={[{ required: false, message: 'required' }]}
                    label={<LabelNew type="tab-header-inactive">{t('อีเมล')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุอีเมล')} />
                  </Form.Item>
                </Col> */}
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="status"
                    label={<LabelNew type="tab-header-inactive">{t('สถานะการใช้งาน')}</LabelNew>}
                  >
                    <SelectIsActive
                      formname={formName}
                      placeholder={t('ระบุสถานะการใช้งาน')}
                      handleChange={(value) => {
                        form.setFieldsValue({ status: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="codePersonnel"
                    rules={[{ required: false, message: 'required' }]}
                    label={<LabelNew type="tab-header-inactive">{t('รหัสบุคลากร')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุรหัสบุคลากร')} />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="fullName"
                    rules={[{ required: false, message: 'required' }]}
                    label={<LabelNew type="tab-header-inactive">{t('ชื่อ-นามสกุล')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุชื่อ-นามสกุล')} />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="organizationCode"
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยงาน')}</LabelNew>}
                  >
                    <SelectOrganization
                      placeholder={t('เลือกหน่วยงาน')}
                      formname={formName}
                      handleChange={(e) =>
                        form.setFieldsValue({
                          organizationCode: e?.value,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew type="search" menu={menu} htmlType="submit">
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew type="clear" menu={menu} onClick={() => onClear()}>
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}
