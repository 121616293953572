import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GET_RISK_AUDIT_UNIVERSE_GRAPH_COLUMN_1 } from '../graphql/Query'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'
import { Alert } from '../../../../../components/alert/Alert'

export const useGetColumn1 = (options) => {
  const { filters } = options
  const { dateStart, dateEnd, organizationCode } = filters
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [getDataFn, getData] = useLazyQuery(GET_RISK_AUDIT_UNIVERSE_GRAPH_COLUMN_1)

  useEffect(() => {
    if (dateStart && dateEnd && organizationCode) {
      const dataInput = {
        dateStart,
        dateEnd,
        organizationCode,
      }
      getDataFn({
        variables: { input: encryptInput(dataInput) },
      })
    }
  }, [dateStart, dateEnd, organizationCode])

  useEffect(() => {
    if (getData.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getData.error)),
        onOk() {},
      })
    }
  }, [getData.error])

  useEffect(() => {
    if (getData?.data) setData(getData?.data?.getRiskAuditUniverseGraphColumn1)
  }, [getData?.data])

  const refresh = () => getData.refetch()

  return [data, getData?.loading, refresh]
}
