import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import { CardNew } from '../../../../../components/card/Card'
import { ButtonNew } from '../../../../../components/button/Button'
import { InputNew } from '../../../../../components/input/Input'
import { LabelNew } from '../../../../../components/label/Label'
import SelectYear from '../../../../../components/inputfromapi/selectyear/SelectYear'
import SelectOperationStatus from '../../../../../components/inputfromapi/selectoperationstatus/SelectOperationStatus'

const AccessFilter = (props) => {
  const Responsive = { md: 8, lg: 8 }
  const formname = 'AssessFilter'
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onFinish = (data) => props.onFilter({ ...data })

  const onClear = () => {
    form.resetFields()
    props.onFilter(form.getFieldsValue())
  }

  return (
    <CardNew topic={t('Filters')} icon="List" toggledrop="false">
      <Form name={formname} form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <Form.Item name="controlFormYear" label={<LabelNew type="tab-header-inactive">{t('ประจำปี')}</LabelNew>}>
              <SelectYear
                placeholder={t('ระบุปีงบประมาณ')}
                name={'controlFormYear'}
                formname={formname}
                handleChange={(e) => form.setFieldsValue({ controlFormYear: e?.value })}
              />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name="controlFormName"
              label={<LabelNew type="tab-header-inactive">{t('ชื่อแบบประเมิน')}</LabelNew>}
            >
              <InputNew placeholder={t('ระบุชื่อแบบประเมิน')} maxLength={255} />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item name="operationStatusCode" label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}>
              <SelectOperationStatus
                formname={formname}
                placeholder={t('เลือกสถานะ')}
                needOperationStatusCode={true}
                handleChange={(e) => form.setFieldsValue({ operationStatusCode: e?.value })}
                filters={{ operationStatusType: 2, isActive: 1 }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} justify="center" style={{ marginTop: '2rem' }}>
          <Col>
            <ButtonNew type="search" htmlType="submit">
              {t('ค้นหา')}
            </ButtonNew>
          </Col>
          <Col>
            <ButtonNew type="clear" onClick={onClear}>
              {t('ล้างค่า')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
    </CardNew>
  )
}

AccessFilter.propTypes = {
  onFilter: PropTypes.func.isRequired,
  menu: PropTypes.string.isRequired,
}

export default AccessFilter
