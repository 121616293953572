import React from 'react'
import { Row, Col } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import OrganizationList from './OrganizationList'
import { useTranslation } from 'react-i18next'
/**
 * @function OrganizationIndex
 * @description
 * Menu: Organization
 * เมนู: การจัดการข้อมูลหน่วยงาน
 * @returns Component
 */
const OrganizationIndex = ({ menu }) => {
  const { t } = useTranslation()
  const arrCrumb = [{ label: t('จัดการข้อมูลพื้นฐาน'), path: '#' }, { label: t('การจัดการข้อมูลหน่วยงาน') }]

  const Responsive = {
    md: 24,
    lg: 24,
  }
  return (
    <React.Fragment>
      <Row>
        <Col {...Responsive}>
          <Breadcrumb data={arrCrumb} title={t('การจัดการข้อมูลหน่วยงาน')} />
          <OrganizationList menu={menu} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default OrganizationIndex
