import styled from 'styled-components'
export const GroupLeft = styled.div`
  cursor: pointer;
  height: 100%;
  &:hover {
    color: #ffffff;
    background-color: #2d7bae;
  }
  &:active {
    color: #ffffff;
    background-color: #2c4d93;
  }
`
export const SideBarHeadDiv = styled.div`
  color: #000000;
  background-color: #ffffff;
`

export const TimeLineStyle = styled.div`
  height: ${(props) => (props.height ? props.height : 'auto')};
  .react-calendar-timeline {
    .rct-outer {
      overflow-y: scroll;
      height: ${(props) => (props.height ? props.height : 'auto')} !important;
    }
  }

  .react-calendar-timeline {
    .rct-vertical-lines {
      .rct-vl.rct-day-6 {
        background-color: ${(props) => (props.backgroundColorSatAndSun ? props.backgroundColorSatAndSun : '#e8d595')};
      }
      ,
      .rct-vl.rct-day-0 {
        background-color: ${(props) => (props.backgroundColorSatAndSun ? props.backgroundColorSatAndSun : '#e8d595')};
      }
    }
  }
  ,
  .react-calendar-timeline {
    .rct-horizontal-lines {
      .rct-hl-odd {
        background: transparent;
      }
    }
  }
  ,
  .react-calendar-timeline {
    .rct-sidebar {
      .rct-sidebar-row.rct-sidebar-row-odd {
        background: transparent;
      }
    }
  }
`
