import { Form } from 'antd'
import { useTranslation } from 'react-i18next'

import { InputNew } from '../../../../components/input/Input'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { regexNumber } from '../../../../utilitys/helper'
import { ColorPicker } from '../../../../components/colorpicker/ColorPicker'
import SelectRiskLevel from '../../../../components/inputfromapi/selectrisklevel/SelectRiskLevel'

export const Columns = ({ editable = false, fncDelete = () => {}, menu, form, formname, dataLists }) => {
  const { t } = useTranslation()

  return [
    {
      title: t('ค่าตัวแปร'),
      dataIndex: 'valueVariable',
      width: '10%',
      render: (item, record, index) => (
        <Form.Item
          name={['riskFormulaValues', index, 'valueVariable']}
          initialValue={item}
          wrapperCol={{
            md: 24,
          }}
          rules={[
            { required: true, message: t('กรุณากรอกค่าตัวแปร') },
            {
              pattern: regexNumber,
              message: t(`กรุณากรอกด้วยตัวเลข 0-9`),
            },
          ]}
        >
          <InputNew placeholder={t('กรอกค่าตัวแปร')} disabled={!editable} maxLength={10} />
        </Form.Item>
      ),
    },
    {
      title: t('คำอธิบายความเสี่ยง'),
      dataIndex: 'valueDetail',
      width: '20%',
      render: (item, record, index) => (
        <Form.Item
          name={['riskFormulaValues', index, 'valueDetail']}
          initialValue={item}
          wrapperCol={{
            md: 24,
          }}
          rules={[{ required: true, message: t('กรุณากรอกคำอธิบายความเสี่ยง') }]}
        >
          <InputNew placeholder={t('กรอกคำอธิบายความเสี่ยง')} disabled={!editable} />
        </Form.Item>
      ),
    },
    {
      title: t('ความถี่/ผลกระทบ'),
      dataIndex: 'valueEffect',
      width: '20%',
      render: (item, record, index) => (
        <Form.Item
          name={['riskFormulaValues', index, 'valueEffect']}
          initialValue={item}
          wrapperCol={{
            md: 24,
          }}
        >
          <InputNew placeholder={t('กรอกความถี่/ผลกระทบ')} disabled={!editable} />
        </Form.Item>
      ),
    },
    {
      title: t('สีแสดงความเสี่ยง'),
      dataIndex: 'color',
      width: '15%',
      render: (item, record, index) => (
        <Form.Item name={['riskFormulaValues', index, 'color']} initialValue={item} wrapperCol={{ md: 24 }}>
          <ColorPicker
            disabled={!editable}
            handleChange={(e) => {
              let newData = dataLists
              newData[index].color = e
              form.setFieldsValue({ riskFormulaValues: newData })
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: t('ระดับความเสี่ยง'),
      dataIndex: 'riskLevelCode',
      width: '15%',
      render: (item, record, index) => (
        <Form.Item name={['riskFormulaValues', index, 'riskLevelCode']} initialValue={item} wrapperCol={{ md: 24 }}>
          <SelectRiskLevel
            formname={formname}
            placeholder={t('เลือกระดับความเสี่ยง')}
            disabled={!editable}
            filters={{ isActive: 1 }}
            handleChange={(e) => {
              let newData = dataLists
              newData[index].riskLevelCode = e?.value
              form.setFieldsValue({ riskFormulaValues: newData })
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '5%',
      render: (item, record, index) => {
        const icons = []

        if (editable) {
          icons.push({
            type: 'delete',
            onClick: () => {
              fncDelete(index)
            },
          })
        }

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} icons={icons} />
          </div>
        )
      },
    },
  ]
}
