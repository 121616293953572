import { gql } from '@apollo/client'

export const GET_SELECT_SYSTEM_MENUS = gql`
  query GetSelectSystemMenus($input: SelectSystemMenusInput!) {
    getSelectSystemMenus(input: $input) {
      menuId
      menuCode
      menuNameTH
      menuNameEN
      menuParentCode
      systemCode
      sort
      menuPath
      isActive
    }
  }
`
