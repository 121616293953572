import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_SELECTMASTER_ORGANIZATIONS } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectOrganization = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const {
    organizationId,
    organizationCode,
    organizationNameTH,
    organizationNameEN,
    organizationLevelCode,
    organizationParentCode,
    organizationSizeCode,
    organizationChecked,
    isActive,
    responsibility,
    checkedList,
    lessOrganizationLevelCode,
    isCreatorsInputInAcresolution,
  } = filters || {}

  const [isData, isSetData] = useState([])

  const [organizationFn, organization] = useLazyQuery(GET_SELECTMASTER_ORGANIZATIONS)

  useEffect(() => {
    organizationFn({
      variables: {
        input: filters
          ? filters.checkedList
            ? encryptInput({ ...filters, checkedList: filters.checkedList && JSON.parse(filters.checkedList) })
            : encryptInput({ ...filters })
          : encryptInput({ isActive: 1 }),
      },
    })
  }, [
    organizationId,
    organizationCode,
    organizationNameTH,
    organizationNameEN,
    organizationLevelCode,
    organizationParentCode,
    organizationSizeCode,
    organizationChecked,
    isActive,
    responsibility,
    checkedList,
    lessOrganizationLevelCode,
    isCreatorsInputInAcresolution,
  ])

  useEffect(() => {
    if (organization.data) {
      isSetData(
        organization?.data?.getSelectMasterOrganizations.map((v) => ({
          value: v.organizationCode,
          label: v.organizationCode + ' : ' + displayText(v?.organizationNameTH, v?.organizationNameEN),
          source: v,
        })),
      )
    }
  }, [organization?.data])
  const setOnChange = props.multiple
    ? {
        onChange: (value) => {
          handleChange(value)
        },
      }
    : { onChange: (value) => handleChange(_.find(isData, (data) => data.value === value)) }
  return (
    <>
      <Select
        {...otherProp}
        multiple={props.multiple}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={organization.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...setOnChange}
      />
    </>
  )
}

export default SelectOrganization
