import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import { CardNew } from '../../../../../components/card/Card'
import { ButtonNew } from '../../../../../components/button/Button'
import { InputNew } from '../../../../../components/input/Input'
import { LabelNew } from '../../../../../components/label/Label'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'
import { useLanguage } from '../../../../../hooks/useLanguage'
import SelectOrganization from '../../../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectProjectRefer from '../../../../../components/inputfromapi/selectprojectrefer/SelectProjectRefer'
import SelectPersonnels from '../../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'

const AuditDocumentFilter = (props) => {
  const Responsive = { md: 8, lg: 8 }
  const { onFilterAuditDocument, menu } = props
  const [isLang] = useLanguage()
  const formname = 'AuditDocumentFilter'

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)

  const onFinish = (data) => {
    onFilterAuditDocument({ ...data, lang: isLang })
  }
  const onClear = () => Promise.all([form.resetFields(), onFilterAuditDocument(form.getFieldsValue())])

  return (
    <CardNew topic={t('Filters')} icon="List" toggledrop="false">
      <Form name={formname} form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <Form.Item name="projectName" label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ')}</LabelNew>}>
              <InputNew placeholder={t('ระบุโครงการตรวจสอบ')} maxLength={200} />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name="projectRefCode"
              label={<LabelNew type="tab-header-inactive">{t('แหล่งที่มาของโครงการ')}</LabelNew>}
            >
              <SelectProjectRefer
                placeholder={t('เลือกแหล่งที่มาของโครงการ')}
                formname={formname}
                handleChange={(e) => form.setFieldsValue({ projectRefCode: e?.value })}
              />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name="organizationCode"
              label={<LabelNew type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</LabelNew>}
            >
              <SelectOrganization
                placeholder={t('เลือกหน่วยงานที่รับผิดชอบ')}
                formname={formname}
                handleChange={(e) => form.setFieldsValue({ organizationCode: e?.value })}
              />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item name="personnelCode" label={<LabelNew type="tab-header-inactive">{t('ผู้รับผิดชอบ')}</LabelNew>}>
              <SelectPersonnels
                placeholder={t('เลือกผู้รับผิดชอบ')}
                formname={formname}
                handleChange={(e) => form.setFieldsValue({ personnelCode: e?.value })}
                filters={{ personnaloperationCode: 10 }}
              />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item name="dateStart" label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}>
              <Datepicker
                autoComplete="off"
                setValueDateFn={async (data) => {
                  await setIsMinDate(data)
                  await form.setFieldsValue({ dateStart: await data })
                }}
                onClear={() => {
                  form.setFieldsValue({ dateStart: null })
                }}
                placeholder={t('DD/MM/YYYY')}
                isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
              />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item name="dateEnd" label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}>
              <Datepicker
                autoComplete="off"
                setValueDateFn={async (data) => {
                  await setIsMaxDate(data)
                  form.setFieldsValue({ dateEnd: await data })
                }}
                onClear={() => {
                  form.setFieldsValue({ dateEnd: null })
                }}
                placeholder={t('DD/MM/YYYY')}
                isMinDate={isMinDate ? new Date(isMinDate) : null}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <ButtonNew type="search" roles={{ type: 'view', menu: menu }} htmlType="submit">
              {t('ค้นหา')}
            </ButtonNew>
          </Col>
          <Col>
            <ButtonNew type="clear" onClick={onClear}>
              {t('ล้างค่า')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
    </CardNew>
  )
}

AuditDocumentFilter.propTypes = {
  // formname: PropTypes.string.isRequired,
  onFilterAuditDocument: PropTypes.func,
  menu: PropTypes.string.isRequired,
}

export default AuditDocumentFilter
