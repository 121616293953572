import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Row, Col } from 'antd'
import * as _ from 'lodash'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { Alert } from '../../../components/alert/Alert'
import UsersListFilter from './components/filter/UsersListFilter'
import UsersModel from './components/modal/UsersModel'
import { Column } from './utils/UserColumnList'
import { GET_USERS_PAGINATION } from './graphql/Query'
import { DELETE_SYSTEM_USER, SEND_SYSTEM_USER } from './graphql/Mutation'
import { handleSort } from '../../../utilitys/pagination'
import { Status } from '../../../components/status/Status'
import { displayError, displayText } from '../../../utilitys/helper'

const UsersList = (props) => {
  const Responsive = { sm: 24, md: 24, lg: 24 }

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { menu } = props

  const breadcrumbList = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/users' },
    { label: t('จัดการข้อมูลผู้ใช้งาน') },
  ]

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [visible, setVisible] = useState(false)
  const [userId, setUserId] = useState()
  const [user] = useDecodeUser()
  const [sort, setSort] = useState([])
  const [filters, setFilters] = useState({})

  const [
    usersPaginationGet,
    { data: dataUsersPaginationGet, loading: loadingUsersPaginationGet, error: errorUsersPaginationGet },
  ] = useLazyQuery(GET_USERS_PAGINATION)

  const [
    deleteSystemUsers,
    { data: dataDeleteSystemUsers, loading: loadingDeleteSystemUsers, error: errorDeleteSystemUsers },
  ] = useMutation(DELETE_SYSTEM_USER)

  const [sendSystemUsers, { data: dataSendSystemUsers, loading: loadingSendSystemUsers, error: errorSendSystemUsers }] =
    useMutation(SEND_SYSTEM_USER)

  const fnConfirm = (userId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        onSubmit(userId)
      },
      onCancel() {},
    })
  }

  const onSubmit = (userId) => {
    let variables = encryptInput({ userId: userId })
    deleteSystemUsers({ variables })
  }

  useEffect(() => {
    if (dataSendSystemUsers) {
      if (dataSendSystemUsers.systemUsersSend.userId) {
        Alert({
          type: 'success',
          title: t('ส่งอีเมลสำเร็จ'),
          onOk() {},
        })
      }
    }

    if (errorSendSystemUsers) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถส่งอีเมลได้`),
        content: t(displayError(errorSendSystemUsers)),
        onOk() {},
      })
    }
  }, [dataSendSystemUsers])

  useEffect(() => {
    if (dataDeleteSystemUsers) {
      Alert({
        type: 'success',
        title: t('ลบข้อมูลสำเร็จ'),
        onOk() {
          usersPaginationGet({
            variables: {
              input: encryptInput({ filters: filters, pagination: { limit, page }, sort: [] }),
            },
          })
        },
      })
    }
    if (errorDeleteSystemUsers) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถลบข้อมูลได้`),
        content: t(displayError(errorDeleteSystemUsers)),
        onOk() {},
      })
    }
  }, [dataDeleteSystemUsers])

  useEffect(() => {
    usersPaginationGet({
      variables: {
        input: encryptInput({ filters: filters, pagination: { limit, page }, sort: sort }),
      },
    })
  }, [filters, page, limit, sort])

  useEffect(() => {
    if (dataUsersPaginationGet) {
      let response = dataUsersPaginationGet.usersPaginationGet
      setCount(response.count)
      setDataLists(
        _.map(response.result, (item, keys) => {
          return {
            key: keys,
            no: item.no,
            username: item.username,
            personnelCode: item.personnelCode,
            fullName: displayText(item.fullName, item.fullNameEN),
            email: item.email,
            organizationName: displayText(item.organizationNameTH, item.organizationNameEN),
            isActiveName: <Status text={item.isActiveName} />,
            remark: item.remark,
            sendEmail: (
              <ButtonNew
                onClick={() => {
                  let variables = encryptInput({ userId: item.userId })
                  sendSystemUsers({
                    variables,
                  })
                }}
                type="flagOutlinedInTableBlue"
                width={92}
                height={36}
                roles={{ type: 'send', menu: menu }}
              >
                {t('ส่งเมล')}
              </ButtonNew>
            ),
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'download',
                      onClick: () => {
                        setVisible(true)
                        setUserId(item.userId)
                      },
                    },
                    {
                      type: 'view',
                      onClick: () =>
                        navigate('/administrator/users/form', {
                          state: { type_action: 'view', refCode: item.userId },
                        }),
                    },
                    {
                      type: 'edit',
                      onClick: () =>
                        navigate('/administrator/users/form', {
                          state: { type_action: 'edit', refCode: item.userId },
                        }),
                    },
                    {
                      type: 'delete',
                      disabled: user.userId === item.userId ? true : false,
                      onClick: async () => {
                        fnConfirm(item.userId)
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (errorUsersPaginationGet) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(errorUsersPaginationGet)),
        onOk() {},
      })
    }
  }, [dataUsersPaginationGet])

  const filterUsers = (e) => {
    const filters = e
      ? {
          // email: e?.email,
          status: e?.status,
          codePersonnel: e?.codePersonnel,
          fullName: e?.fullName,
          organizationCode: e?.organizationCode,
        }
      : {}

    setLimit(10)
    setPage(1)
    setFilters({ ...filters })
  }

  return (
    <>
      {(loadingUsersPaginationGet || loadingSendSystemUsers || loadingDeleteSystemUsers) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('จัดการข้อมูลผู้ใช้งาน')} />
      <UsersListFilter getValue={(e) => filterUsers(e)} />
      <CardNew topic={t('รายการผู้ใช้งาน')} icon="Users" toggledrop={'false'}>
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            <Datatable
              columns={Column}
              data={[...dataLists]}
              total={count}
              isScoll={{ x: true, y: true }}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              paginationCustom={true}
              searchCustom={true}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => {
                const reNewField = [
                  { fullName: 'firstname' },
                  { organizationName: displayText('organizationNameTH', 'organizationNameEN') },
                ]

                setSort(handleSort(e?.sorter, reNewField))
              }}
              btnAdd={
                <ButtonNew
                  type="plusTableBorderPrimary"
                  onClick={() => navigate('/administrator/users/form', { state: { type_action: 'create' } })}
                  roles={{ type: 'add', menu: menu }}
                >
                  {t('เพิ่มผู้ใช้งาน')}
                </ButtonNew>
              }
            />
          </Col>
        </Row>
      </CardNew>
      <UsersModel open={visible} close={(e) => setVisible(e)} menu={menu} userId={userId} />
    </>
  )
}

export default UsersList
