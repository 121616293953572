import React, { useEffect, useState } from 'react'
import { Row, Col, Tooltip } from 'antd'
import {
  GET_AUDITPROJECT,
  AUDIT_PROJECT_GET_CALENDARDETAILS1,
  AUDIT_PROJECT_GET_CALENDARDETAILS2,
  GET_MASTER_OPERATION_STATUS,
} from './graphql/Query'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  TimelineMarkers,
  CustomMarker,
} from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import { useNavigate } from 'react-router-dom'
import { encryptInput } from '../../../utilitys/crypto'
import { GroupLeft, SideBarHeadDiv, TimeLineStyle } from '../../../components/genttchart/GanttChartStyles'
import { useTranslation } from 'react-i18next'
import { LabelNew as Label } from '../../../components/label/Label'
import { Alert } from '../../../components/alert/Alert'
import { displayError } from '../../../utilitys/helper'

const AuditProject = (props) => {
  const color = {
    graphHeaderMonthNameBackground: '#C1C8C7',
    graphHeaderMonthNameText: '#FFFFFF',
    graphHeaderDatehNameBackground: '#FFFFFF',
    graphHeaderDatehNameText: '#000000',
    graphCerrentDateBackground: '#B2B2B2',
    graphSanAndSunDateBackground: '#e8d595',
    graphHolidayType1DateBackground: '#FDFFBC',
    graphHolidayType2DateBackground: '#FFFDDD',
    statusNoapprove: '#C1C8C7',
    statusNoActionPlan: '#FAE0D8',
    statusActionPlanDontApprove: '#f7d4de',
    statusWaitForProcess: '#a0c5e2',
    statusProcessing: '#2676bb',
    statusProcessed: '#54a648',
    statusLate: '#C43D56',
  }
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [holiday1, setHoliday1] = useState([])
  const [holiday2, setHoliday2] = useState([])
  const [operationStatus, setOperationStatus] = useState([])
  const [operationStatusName10, setOperationStatusName10] = useState('')
  const [operationStatusName20, setOperationStatusName20] = useState('')
  const [operationStatusName30, setOperationStatusName30] = useState('')
  const [groups, setGroups] = useState([])
  const [items, setItems] = useState([])

  const [getMasterOperationStatusFn, getMasterOperationStatus] = useLazyQuery(GET_MASTER_OPERATION_STATUS)

  const [auditProjectGetCalendarDetailsType1Fn, auditProjectGetCalendarDetailsType1] = useLazyQuery(
    AUDIT_PROJECT_GET_CALENDARDETAILS1,
  )
  const [auditProjectGetCalendarDetailsType2Fn, auditProjectGetCalendarDetailsType2] = useLazyQuery(
    AUDIT_PROJECT_GET_CALENDARDETAILS2,
  )
  const [getAuditProjectFn, getAuditProject] = useLazyQuery(GET_AUDITPROJECT)
  useEffect(() => {
    if (getAuditProject?.data && operationStatus.length) {
      let response = getAuditProject?.data?.getAuditProject
      setGroups(
        response.map((data) => {
          return {
            id: data.projectCode,
            title:
              data.projectCode +
              ' : ' +
              (localStorage.getItem('lang') === 'th' ? data.projectNameTH : data.projectNameEN),
            stackItems: true,
            height: 60,
            dateStart: dateDisplay(data.dateStart),
            dateEnd: dateDisplay(data.dateEnd),
          }
        }),
      )
      let item = response
        .map((data) => {
          let dateStart = new Date(data.dateStart)
          let dateEnd = new Date(data.dateEnd)
          return {
            id: data.projectCode,
            group: data.projectCode,
            title: {
              statusActionPlan: data.statusActionPlan,
              approveStatusCode: data.approveStatusCode,
              late: false,
              countProjectStepStatus10: Math.floor(
                data.countProjectStepStatusAll
                  ? (data.countProjectStepStatus10 / data.countProjectStepStatusAll) * 100
                  : 0,
              ),
              countProjectStepStatus20: Math.floor(
                data.countProjectStepStatusAll
                  ? (data.countProjectStepStatus20 / data.countProjectStepStatusAll) * 100
                  : 0,
              ),
              countProjectStepStatus30: Math.floor(
                data.countProjectStepStatusAll
                  ? (data.countProjectStepStatus30 / data.countProjectStepStatusAll) * 100
                  : 0,
              ),
            },
            start_time: moment(
              dateStart.getFullYear() + '-' + (dateStart.getMonth() + 1) + '-' + dateStart.getDate() + ' 00:00',
            ),
            end_time: moment(
              dateEnd.getFullYear() + '-' + (dateEnd.getMonth() + 1) + '-' + dateEnd.getDate() + ' 23:59',
            ),
            canMove: false,
            canResize: false,
            canChangeGroup: false,
          }
        })
        .concat(
          response
            .filter((data) => {
              return (
                (data.countProjectStepStatus10 !== 0 || data.countProjectStepStatus20 !== 0) &&
                moment(data.dateEnd) < moment(new Date())
              )
            })
            .map((data) => {
              let dateStart = new Date(moment(data.dateEnd).add(1, 'days'))
              let dateEnd = new Date()
              return {
                id: data.projectCode + '_late',
                group: data.projectCode,
                title: { late: true },
                start_time: moment(
                  dateStart.getFullYear() + '-' + (dateStart.getMonth() + 1) + '-' + dateStart.getDate() + ' 00:00',
                ),
                end_time: moment(
                  dateEnd.getFullYear() + '-' + (dateEnd.getMonth() + 1) + '-' + dateEnd.getDate() + ' 23:59',
                ),
                canMove: false,
                canResize: false,
                canChangeGroup: false,
              }
            }),
        )
      setItems(item)
    }
  }, [getAuditProject.data, operationStatus])
  useEffect(() => {
    if (auditProjectGetCalendarDetailsType1?.data) {
      let response = auditProjectGetCalendarDetailsType1?.data?.auditProjectGetCalendarDetailsType1
      setHoliday1(
        response.map((data) => {
          return moment(new Date(moment(data.dateAt).format('YYYY-MM-DD') + ' 00:00'))
        }),
      )
    }
  }, [auditProjectGetCalendarDetailsType1.data])
  useEffect(() => {
    if (auditProjectGetCalendarDetailsType2?.data) {
      let response = auditProjectGetCalendarDetailsType2?.data?.auditProjectGetCalendarDetailsType2
      setHoliday2(
        response.map((data) => {
          return moment(new Date(moment(data.dateAt).format('YYYY-MM-DD') + ' 00:00'))
        }),
      )
    }
  }, [auditProjectGetCalendarDetailsType2.data])
  useEffect(() => {
    if (getMasterOperationStatus?.data) {
      let response = getMasterOperationStatus?.data?.getMasterOperationStatus
      setOperationStatus(
        response.map((data) => {
          return {
            operationStatusCode: data.operationStatusCode,
            operationStatusNameTH: data.operationStatusNameTH,
            operationStatusNameEN: data.operationStatusNameEN,
          }
        }),
      )
      let status10 = response.find((data) => {
        return data.operationStatusCode === '10'
      })
      let status20 = response.find((data) => {
        return data.operationStatusCode === '20'
      })
      let status30 = response.find((data) => {
        return data.operationStatusCode === '30'
      })
      setOperationStatusName10(
        status10
          ? localStorage.getItem('lang') === 'th'
            ? status10.operationStatusNameTH
            : status10.operationStatusNameEN
          : '',
      )
      setOperationStatusName20(
        status20
          ? localStorage.getItem('lang') === 'th'
            ? status20.operationStatusNameTH
            : status20.operationStatusNameEN
          : '',
      )
      setOperationStatusName30(
        status30
          ? localStorage.getItem('lang') === 'th'
            ? status30.operationStatusNameTH
            : status30.operationStatusNameEN
          : '',
      )
    }
  }, [getMasterOperationStatus.data])
  const callApiSearch = () => {
    getAuditProjectFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          planCode: null,
          lang: localStorage.getItem('lang'),
          projectTypeCode: '20',
          auditProjectInfor: (
            typeof props.formFilter.getFieldValue('auditProjectInfor') === 'string'
              ? props.formFilter.getFieldValue('auditProjectInfor').trim()
              : props.formFilter.getFieldValue('auditProjectInfor')
          )
            ? typeof props.formFilter.getFieldValue('auditProjectInfor') === 'string'
              ? props.formFilter.getFieldValue('auditProjectInfor').trim()
              : props.formFilter.getFieldValue('auditProjectInfor')
            : null,
          dateStart: props.formFilter.getFieldValue('dateStart') ? props.formFilter.getFieldValue('dateStart') : null,
          dateEnd: props.formFilter.getFieldValue('dateEnd') ? props.formFilter.getFieldValue('dateEnd') : null,
          projectRefCode: props.formFilter.getFieldValue('projectRefCode')
            ? props.formFilter.getFieldValue('projectRefCode')
            : null,
          projectSizeCode: props.formFilter.getFieldValue('projectSizeCode')
            ? props.formFilter.getFieldValue('projectSizeCode')
            : null,
          approveStatusCode: props.formFilter.getFieldValue('approveStatusCode')
            ? props.formFilter.getFieldValue('approveStatusCode')
            : null,
          projectStatusCode: props.formFilter.getFieldValue('projectStatusCode')
            ? props.formFilter.getFieldValue('projectStatusCode')
            : null,
          auditTeamCode: props.formFilter.getFieldValue('auditTeamCode')
            ? props.formFilter.getFieldValue('auditTeamCode')
            : null,
        }),
      },
    })
  }

  useEffect(() => {
    auditProjectGetCalendarDetailsType1Fn({
      fetchPolicy: 'no-cache',
    })
    auditProjectGetCalendarDetailsType2Fn({
      fetchPolicy: 'no-cache',
    })
    callApiSearch()
    getMasterOperationStatusFn({
      variables: { input: encryptInput({ isActive: 1 }) },
    })
  }, [])

  const groupRenderer = ({ group }) => {
    return (
      <Tooltip placement="top" title={group.title}>
        <GroupLeft
          onClick={() => {
            navigate('/auditplanning/offplanprojects/general/view', {
              state: { refCode: group.id },
            })
          }}
        >
          <Row>
            <Col md={24} style={{ height: '30px', marginTop: '-10px' }}>
              <span>{group.title}</span>
            </Col>
            <Col md={24} style={{ height: '30px', marginTop: '-5px' }}>
              <span style={{ color: '#c1c8c7', fontSize: '14px' }}>{group.dateStart + ' - ' + group.dateEnd}</span>
            </Col>
          </Row>
        </GroupLeft>
      </Tooltip>
    )
  }

  const itemRenderer = ({ item, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
    const propsTop = getItemProps(item.itemProps)
    propsTop.style =
      item.title.late === false
        ? {
            ...propsTop.style,
            color: 'white',
            background:
              item.title.approveStatusCode === '20'
                ? item.title.statusActionPlan === 'noActionPlan'
                  ? color.statusNoActionPlan
                  : item.title.statusActionPlan === 'dontApprove'
                  ? color.statusActionPlanDontApprove
                  : color.statusNoActionPlan
                : color.statusNoapprove,
            border:
              item.title.approveStatusCode === '20'
                ? item.title.statusActionPlan === 'noActionPlan'
                  ? '1px solid ' + color.statusNoActionPlan
                  : item.title.statusActionPlan === 'dontApprove'
                  ? '1px solid ' + color.statusActionPlanDontApprove
                  : '1px solid ' + color.statusNoActionPlan
                : '1px solid ' + color.statusNoapprove,
            height: '30px',
          }
        : {
            ...propsTop.style,
            color: 'white',
            background: color.statusLate,
            border: '1px solid ' + color.statusLate,
            height: '30px',
          }
    propsTop.title = ''

    return (
      <>
        <div {...propsTop}>
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
          {item.title.late === false && item.title.countProjectStepStatus30 ? (
            <div
              className="rct-item-content"
              style={{
                maxHeight: `${itemContext.dimensions.height}`,
                background: color.statusProcessed,
                width: item.title.countProjectStepStatus30 + '%',
              }}
            >
              {item.title.countProjectStepStatus30 + '%'}
            </div>
          ) : (
            ''
          )}
          {item.title.late === false && item.title.countProjectStepStatus20 ? (
            <div
              className="rct-item-content"
              style={{
                maxHeight: `${itemContext.dimensions.height}`,
                background: color.statusProcessing,
                width: item.title.countProjectStepStatus20 + '%',
              }}
            >
              {item.title.countProjectStepStatus20 + '%'}
            </div>
          ) : (
            ''
          )}
          {item.title.late === false && item.title.countProjectStepStatus10 ? (
            <div
              className="rct-item-content"
              style={{
                maxHeight: `${itemContext.dimensions.height}`,
                background: color.statusWaitForProcess,
                width: item.title.countProjectStepStatus10 + '%',
              }}
            >
              {item.title.countProjectStepStatus10 + '%'}
            </div>
          ) : (
            ''
          )}
          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
        </div>
      </>
    )
  }
  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }
  useEffect(() => {
    if (getAuditProject.error) {
      showAlertLoadDataError(displayError(getAuditProject.error))
    }
    if (auditProjectGetCalendarDetailsType1.error) {
      showAlertLoadDataError(displayError(auditProjectGetCalendarDetailsType1.error))
    }
    if (auditProjectGetCalendarDetailsType2.error) {
      showAlertLoadDataError(displayError(auditProjectGetCalendarDetailsType2.error))
    }
    if (getMasterOperationStatus.error) {
      showAlertLoadDataError(displayError(getMasterOperationStatus.error))
    }
  }, [
    getAuditProject.error,
    auditProjectGetCalendarDetailsType1.error,
    auditProjectGetCalendarDetailsType2.error,
    getMasterOperationStatus.error,
  ])
  const defaultTimeStart = moment().startOf('month').toDate()
  const defaultTimeEnd = moment().startOf('month').add(1, 'month').toDate()
  const defaultTimeRange = defaultTimeEnd - defaultTimeStart
  return (
    <>
      {(getAuditProject.loading ||
        auditProjectGetCalendarDetailsType1.loading ||
        getMasterOperationStatus.loading ||
        auditProjectGetCalendarDetailsType2.loading) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          {groups.length !== 0 && items.length !== 0 && (
            <TimeLineStyle height={groups.length > 10 ? '665px' : 'auto'}>
              <Timeline
                groups={groups}
                items={items}
                minZoom={defaultTimeRange}
                maxZoom={defaultTimeRange}
                defaultTimeStart={defaultTimeStart}
                defaultTimeEnd={defaultTimeEnd}
                groupRenderer={groupRenderer}
                itemRenderer={itemRenderer}
                sidebarWidth={450}
                key={{
                  groupIdKey: 'id',
                  groupTitleKey: 'title',
                  groupRightTitleKey: 'rightTitle',
                  itemIdKey: 'id',
                  itemTitleKey: 'title', // key for item div content
                  itemDivTitleKey: 'title', // key for item div title (<div title="text"/>)
                  itemGroupKey: 'group',
                  itemTimeStartKey: 'start_time',
                  itemTimeEndKey: 'end_time',
                }}
              >
                <TimelineHeaders>
                  <SidebarHeader>
                    {({ getRootProps }) => {
                      return (
                        <SideBarHeadDiv {...getRootProps()}>
                          <Row justify="center" align="middle" style={{ height: '100%' }}>
                            <Col>{t('โครงการ')}</Col>
                          </Row>
                        </SideBarHeadDiv>
                      )
                    }}
                  </SidebarHeader>
                  <DateHeader
                    unit="month"
                    labelFormat="MM YYYY"
                    style={{
                      height: 50,
                      color: color.graphHeaderMonthNameText,
                      backgroundColor: color.graphHeaderMonthNameBackground,
                      pointerEvents: 'none',
                    }}
                    intervalRenderer={({ getIntervalProps, intervalContext }) => {
                      const monthSTR = intervalContext.intervalText.split(' ')
                      const month =
                        monthSTR[0] === '01'
                          ? t('มกราคม')
                          : monthSTR[0] === '02'
                          ? t('กุมภาพันธ์')
                          : monthSTR[0] === '03'
                          ? t('มีนาคม')
                          : monthSTR[0] === '04'
                          ? t('เมษายน')
                          : monthSTR[0] === '05'
                          ? t('พฤษภาคม')
                          : monthSTR[0] === '06'
                          ? t('มิถุนายน')
                          : monthSTR[0] === '07'
                          ? t('กรกฎาคม')
                          : monthSTR[0] === '08'
                          ? t('สิงหาคม')
                          : monthSTR[0] === '09'
                          ? t('กันยายน')
                          : monthSTR[0] === '10'
                          ? t('ตุลาคม')
                          : monthSTR[0] === '11'
                          ? t('พฤศจิกายน')
                          : monthSTR[0] === '12'
                          ? t('ธันวาคม')
                          : ''
                      const year = localStorage.getItem('lang') === 'th' ? parseInt(monthSTR[1]) + 543 : monthSTR[1]
                      return (
                        <div justify="center" align="middle" {...getIntervalProps()}>
                          <div style={{ border: '1px solid #bbb' }}>{month + ' ' + year}</div>
                        </div>
                      )
                    }}
                  />
                  <DateHeader
                    unit="day"
                    labelFormat="D"
                    style={{
                      height: 50,
                      color: color.graphHeaderDatehNameText,
                      backgroundColor: color.graphHeaderDatehNameBackground,
                      pointerEvents: 'none',
                    }}
                    intervalRenderer={({ getIntervalProps, intervalContext }) => {
                      return (
                        <div justify="center" align="middle" {...getIntervalProps()}>
                          <div style={{ border: '1px solid #bbb', borderTop: '0px', paddingBottom: '5px' }}>
                            {intervalContext.intervalText}
                          </div>
                        </div>
                      )
                    }}
                  />
                </TimelineHeaders>
                <TimelineMarkers>
                  <CustomMarker date={new Date(moment().format('YYYY-MM-DD') + ' 00:00').getTime()}>
                    {({ styles }) => {
                      const customStyles = {
                        ...styles,
                        backgroundColor: color.graphCerrentDateBackground,
                        width: '33px',
                      }
                      return <div style={customStyles} />
                    }}
                  </CustomMarker>
                  {holiday1.map((data, i) => {
                    const dateData = new Date(data)
                    return dateData.getDay() !== 0 && dateData.getDay() !== 6 ? (
                      <CustomMarker date={new Date(moment(data).format('YYYY-MM-DD') + ' 00:00').getTime()} key={i}>
                        {({ styles }) => {
                          const customStyles = {
                            ...styles,
                            backgroundColor: color.graphHolidayType1DateBackground,
                            width: '33px',
                          }
                          return <div style={customStyles} />
                        }}
                      </CustomMarker>
                    ) : (
                      ''
                    )
                  })}
                  {holiday2.map((data, i) => {
                    const dateData = new Date(data)
                    return dateData.getDay() !== 0 && dateData.getDay() !== 6 ? (
                      <CustomMarker date={new Date(moment(data).format('YYYY-MM-DD') + ' 00:00').getTime()} key={i}>
                        {({ styles }) => {
                          const customStyles = {
                            ...styles,
                            backgroundColor: color.graphHolidayType2DateBackground,
                            width: '33px',
                          }
                          return <div style={customStyles} />
                        }}
                      </CustomMarker>
                    ) : (
                      ''
                    )
                  })}
                </TimelineMarkers>
              </Timeline>
            </TimeLineStyle>
          )}
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: '50px' }}>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusNoapprove, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{t('ไม่อนุมัติ')}</Label>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusNoActionPlan, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{t('ยังไม่มีแผนปฏิบัติงาน')}</Label>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                backgroundColor: color.statusActionPlanDontApprove,
                width: '20px',
                height: '20px',
                marginRight: '20px',
              }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{t('ไม่อนุมัติแผนปฏิบัติงาน')}</Label>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                backgroundColor: color.statusWaitForProcess,
                width: '20px',
                height: '20px',
                marginRight: '20px',
              }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{operationStatusName10}</Label>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusProcessing, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{operationStatusName20}</Label>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusProcessed, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{operationStatusName30}</Label>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusLate, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{t('ล่าช้ากว่ากำหนด')}</Label>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default AuditProject
