import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_SELECT_AUDITPROJECT } from '../../graphql/Query'
import { Select } from '../../../../../components/select/Select.jsx'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText } from '../../../../../utilitys/helper'

const SelectAuditProject = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { projectTypeCode } = filters || {}

  const [isData, isSetData] = useState([])

  const [getSelectAuditProjectsFn, getSelectAuditProjects] = useLazyQuery(GET_SELECT_AUDITPROJECT)

  useEffect(() => {
    getSelectAuditProjectsFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({}) },
    })
  }, [projectTypeCode])

  useEffect(() => {
    if (getSelectAuditProjects.data) {
      isSetData(
        _.map(getSelectAuditProjects?.data?.getSelectAuditProjects, (item) => {
          return {
            label: item?.projectCode + ': ' + displayText(item?.projectNameTH, item?.projectNameEN),
            value: item?.projectCode,
            source: item,
          }
        }),
      )
    }
  }, [getSelectAuditProjects.data])
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getSelectAuditProjects.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectAuditProject
