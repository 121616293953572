import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { useLazyQuery } from '@apollo/client'
import { InputNew } from '../../../components/input/Input'
import { LabelNew } from '../../../components/label/Label'
import { ButtonNew } from '../../../components/button/Button'
import { Datatable } from '../../../components/datatable/Datatable'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Columns } from './utils/TaskInResponsibilityReportColumns'

import TaskInResponsibilityModal from './TaskInResponsibilityModal'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError } from '../../../utilitys/helper'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { handleSort } from '../../../utilitys/pagination'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { GET_TASK_IN_RESPONSIBILITY_PAGINATION, TASK_IN_RESPONSIBILITY_EXPORT } from './graphql/Query'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import SendEmailReportModal from '../components/SendEmailReportModal'

const TaskInResponsibilityReport = (props) => {
  const { menu } = props
  const [user] = useDecodeUser()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const ResponsiveTable = {
    md: 24,
    lg: 24,
  }

  const Responsive = {
    md: 8,
    lg: 8,
  }

  const breadcrumbList = [{ label: t('รายงาน') }, { label: t('รายงานข้อมูลจำนวนงานในความรับผิดชอบ') }]

  const [exportAll, setExportAll] = useState(false)
  const [dataFilter, setDataFilter] = useState(false)
  // const [disabled /* setDisabled */] = useState(false)
  // const [fieldJobGroup, setFieldJobGroup] = useState()
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([
    { fieldSort: 'personnelCode', sortType: 'ASC' },
    { fieldSort: 'groupName', sortType: 'ASC' },
    { fieldSort: 'typeName', sortType: 'ASC' },
  ])
  const [filters, setFilters] = useState({})
  const [visible, setVisible] = useState(false)
  const [dataLists, setDataLists] = useState([])

  /* สำหรับการส่งเมลรายงาน */
  const [visibleSendMail, setVisibleSendMail] = useState(false)
  const [mailValue, setMailValue] = useState()
  const [inputSendEmail, setInputSendEmail] = useState()

  /* ---------------- API ----------- */
  const [getDataPaginationFn, dataPagination] = useLazyQuery(GET_TASK_IN_RESPONSIBILITY_PAGINATION)
  const [exportFn, { data: dataExports, loading: loadingExport, error: errorExport }] =
    useLazyQuery(TASK_IN_RESPONSIBILITY_EXPORT)

  useEffect(() => {
    if (Object.keys(filters).length !== 0) {
      getDataPaginationFn({
        variables: {
          input: encryptInput({
            filters: { ...filters },
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
    }
  }, [page, limit, sort])

  useEffect(() => {
    if (dataPagination.data) {
      let response = dataPagination?.data?.getTaskInResponsibilityPagination
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            key: data.no,
            fullName: data.fullName || '-',
            groupName: data.groupName || '-',
            typeName: data.typeName || '-',
            jobAndProjectName: data.jobAndProjectName || '',
            dateStart: dateDisplay(data.dateStart) || '-',
            dateEnd: dateDisplay(data.dateEnd) || '-',
            manday: data.manday || '-',
            organizationName: data.organizationName || '-',
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'download',
                      onClick: () => {
                        setVisible(true), setExportAll(false), setDataFilter({ data: data, ...form.getFieldValue() })
                      },
                    },
                    {
                      type: 'settings',
                      onClick: () => {
                        setDataFilter({ data: data, ...form.getFieldValue() })
                        setVisibleSendMail(true)
                        setMailValue({
                          reportName: 'รายงานข้อมูลจำนวนงานในความรับผิดชอบ',
                          refCode: data.jobAndProjectCode,
                        })
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }

    if (dataPagination.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(dataPagination.error),
        onOk() {},
      })
    }
  }, [dataPagination.data])

  /* สำหรับการส่งเมลรายงาน */
  useEffect(() => {
    if (inputSendEmail) {
      const dataInput = {
        ...dataFilter,
        personnelCode: user.pCode,
        lang: localStorage.getItem('lang'),
        emailData: inputSendEmail, // สำหรับการส่งเมลรายงาน
      }

      // Call API Export
      exportFn({
        variables: {
          input: encryptInput(dataInput),
        },
      })
    }
  }, [inputSendEmail])

  useEffect(() => {
    if (dataExports) {
      Alert({
        type: 'success',
        title: t('ส่งอีเมลสำเร็จ'),
        onOk() {},
      })
    }

    if (errorExport) {
      Alert({
        type: 'error',
        title: t('ส่งอีเมลไม่สำเร็จ'),
        content: t(displayError(errorExport)),
        onOk() {},
      })
    }
  }, [dataExports])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    const inputSearch = {
      fullName: input.fullName || '',
      jobAndProjectName: input.jobAndProjectName || '',
      dateEnd: input.dateEnd || '',
      dateStart: input.dateStart || '',
      organization: input.organization || '',
      // personnelCode: user.pCode,
    }
    setFilters(inputSearch)
    getDataPaginationFn({
      variables: {
        input: encryptInput({
          filters: inputSearch,
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }

  return (
    <>
      {(dataPagination.loading || loadingExport) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('รายงานข้อมูลจำนวนงานในความรับผิดชอบ')} />
      <CardNew topic={t('Filters')} icon="Filter" toggledrop="true" buttontopright="true">
        <Form name="form-search" form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[12, 0]}>
            <Col {...Responsive}>
              <Form.Item
                name={'organization'}
                label={<LabelNew type="tab-header-inactive">{t('หน่วยงาน')}</LabelNew>}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระบุ'),
                  },
                ]}
              >
                <SelectOrganization
                  placeholder={t('เลือกหน่วยงาน')}
                  formname={'form-search'}
                  filters={{ /* organizationChecked: 1, */ isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ organization: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name={'fullName'} label={<LabelNew type="tab-header-inactive">{t('ผู้รับผิดชอบ')}</LabelNew>}>
                <InputNew placeholder={t('ระบุผู้รับผิดชอบ')} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'jobAndProjectName'}
                label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ / งาน')}</LabelNew>}
              >
                <InputNew placeholder={t('ระบุโครงการ / งาน')} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'dateStart'}
                label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระบุ'),
                  },
                ]}
              >
                <Datepicker
                  setValueDateFn={async (data) => {
                    await setIsMinDate(data), await form.setFieldsValue({ dateStart: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateStart: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                  isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'dateEnd'}
                label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระบุ'),
                  },
                ]}
              >
                <Datepicker
                  setValueDateFn={async (data) => {
                    await setIsMaxDate(data), await form.setFieldsValue({ dateEnd: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateEnd: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                  isMinDate={isMinDate ? new Date(isMinDate) : null}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <ButtonNew type="search" htmlType="submit">
                {t('ค้นหา')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew
                type="export"
                roles={{ type: 'export', menu: menu }}
                // disabled={!allowExport && dataRef.length > 0 ? false : true}
                onClick={() => {
                  setVisible(true), setExportAll(true), setDataFilter(form.getFieldValue())
                }}
              >
                {t('ส่งออก')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew
                type="clear"
                onClick={() => {
                  form.resetFields(), setDataLists([])
                }}
              >
                {t('ล้างค่า')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </CardNew>
      <CardNew topic={t('รายการงานในความรับผิดชอบ')} icon="List" toggledrop={'false'}>
        <Row gutter={[12, 12]}>
          <Col {...ResponsiveTable}>
            <Datatable
              paginationCustom={true}
              columns={Columns}
              data={dataLists}
              total={count}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => setSort(handleSort(e?.sorter))}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
            />
          </Col>
        </Row>
      </CardNew>
      <TaskInResponsibilityModal
        open={visible}
        close={(e) => {
          setVisible(e), setExportAll(false)
        }}
        menu={menu}
        dataFilter={dataFilter}
        exportAll={exportAll}
      />
      <SendEmailReportModal
        menu={menu}
        open={visibleSendMail}
        close={(e) => {
          setVisibleSendMail(e)
        }}
        fileType={{ pdf: true, word: true, excel: true, csv: true }}
        setInputSendEmail={(e) => setInputSendEmail(e)}
        mailValue={mailValue}
      />
    </>
  )
}

export default TaskInResponsibilityReport
