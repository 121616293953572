import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { List } from 'react-feather'
import { ModalNew } from '../../../components/modal/Modal.jsx'
import { InputNew } from '../../../components/input/Input'
import { LabelNew as Label } from '../../../components/label/Label'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
import { TextAreaNew } from '../../../components/textarea/TextArea'
import { ButtonNew } from '../../../components/button/Button'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { Alert } from '../../../components/alert/Alert'
import SelectTagEmailPersonnels from '../../../components/inputfromapi/selecttagemailpersonnels/SelectTagEmailPersonnels'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { convertEmailJson } from '../../../utilitys/helper'

const SendEmailReportModal = (props) => {
  const labelCol = { sm: 8, md: 8, lg: 8, xl: 8 }
  const wrapperCol = { sm: 16, md: 16, lg: 24, xl: 16, align: 'left' }
  const { t } = useTranslation()
  const [user] = useDecodeUser()

  const { menu, open, setInputSendEmail, mailValue, fileType } = props
  const [form] = Form.useForm()
  const [waterMark, setWaterMark] = useState(false)
  const [sendNow, setSendNow] = useState(true)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    if (open) {
      setVisible(open)
      form.setFieldsValue({ reportName: mailValue?.reportName })
      !fileType.pdf && setWaterMark(true)
    }
  }, [open])

  const showConfirm = (input) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการส่งอีเมล ?'),
      onOk() {
        setInputSendEmail(input)
        onClose(false)
      },
      onCancel() {
        onClose(false)
      },
    })
  }

  const onFinish = (input) => {
    const inputData = {
      ...input,
      sendMailCopy: input.sendMailCopy ? convertEmailJson(input.sendMailCopy) : '',
      sendMailSecret: input.sendMailSecret ? convertEmailJson(input.sendMailSecret) : '',
      sendMailTo: input.sendMailTo ? convertEmailJson(input.sendMailTo) : '',
      sendDate: input.sendDate || moment().format('YYYY-MM-DD'),
      menuCode: menu,
      sendBy: user.pCode,
      refCode: mailValue?.refCode || '',
    }

    showConfirm(inputData)
  }

  const onClose = (e) => {
    form.resetFields()
    setWaterMark(e)
    setVisible(e)
    setSendNow(true)
    props.close(e)
  }

  return (
    <>
      <ModalNew
        customIcon={<List size={22} />}
        visible={visible}
        testTitle={t('รายละเอียดการตั้งค่าการส่งรายงาน')}
        footer={null}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        type="big"
        bodyStyle={{ overflowY: 'auto' }}
      >
        <Form
          form={form}
          autoComplete="off"
          labelAlign="left"
          labelCol={{ ...labelCol }}
          wrapperCol={{ ...wrapperCol }}
          layout="horizontal"
          name="sendEmailForm"
          onFinish={onFinish}
        >
          <Row align="center">
            <Col md={24} lg={24} xl={16}>
              <Form.Item name="reportName" label={<Label type="tab-header-inactive">{t('ชื่อรายงาน')}</Label>} disabled>
                <InputNew placeholder={t('ชื่อรายงาน')} disabled />
              </Form.Item>
              <Form.Item
                name="sendMailTo"
                label={<Label type="tab-header-inactive">{t('ถึง')}</Label>}
                rules={[{ required: true, message: t('กรุณาระบุอีเมล') }]}
              >
                <SelectTagEmailPersonnels
                  placeholder={t('ระบุถึง')}
                  formname={'sendEmailForm'}
                  handleChange={(e) => {
                    form.setFieldsValue({ sendMailTo: e })
                  }}
                  disableFreeKey={true}
                />
              </Form.Item>
              <Form.Item name="sendMailCopy" label={<Label type="tab-header-inactive">{t('สำเนาถึง')}</Label>}>
                <SelectTagEmailPersonnels
                  placeholder={t('ระบุถึง')}
                  formname={'sendEmailForm'}
                  handleChange={(e) => {
                    form.setFieldsValue({ sendMailCopy: e })
                  }}
                  disableFreeKey={true}
                />
              </Form.Item>
              <Form.Item name="sendMailSecret" label={<Label type="tab-header-inactive">{t('สำเนาลับ')}</Label>}>
                <SelectTagEmailPersonnels
                  placeholder={t('ระบุถึง')}
                  formname={'sendEmailForm'}
                  handleChange={(e) => {
                    form.setFieldsValue({ sendMailSecret: e })
                  }}
                  disableFreeKey={true}
                />
              </Form.Item>
              <Form.Item
                name="dear"
                label={<Label type="tab-header-inactive">{t('เรียน')}</Label>}
                rules={[{ required: true, message: t('เรียน') }]}
              >
                <InputNew placeholder={t('เรียน')} />
              </Form.Item>
              <Form.Item
                name="subject"
                label={<Label type="tab-header-inactive">{t('เรื่อง')}</Label>}
                rules={[{ required: true, message: t('เรื่อง') }]}
              >
                <InputNew placeholder={t('เรื่อง')} />
              </Form.Item>
              <Form.Item name="detail" label={<Label type="tab-header-inactive">{t('รายละเอียด')}</Label>}>
                <TextAreaNew autoSize={{ minRows: 4, maxRows: 4 }} />
              </Form.Item>
              <Form.Item
                label={<Label type="tab-header-inactive">{t('ประเภทรายงาน')}</Label>}
                name="fileType"
                initialValue={
                  fileType &&
                  Object.keys(fileType).filter(function (key) {
                    return fileType[key]
                  })[0]
                }
                onClick={(e) => {
                  if (e.target.value) {
                    if (e.target.value !== 'pdf') {
                      setWaterMark(true)
                    } else {
                      setWaterMark(false)
                    }
                  }
                }}
              >
                <RadioGroup>
                  <Row gutter={[8, 8]}>
                    {fileType.pdf && (
                      <Col span={12}>
                        <Radio value="pdf">
                          <Label type="tab-header-inactive">{t('PDF')}</Label>
                        </Radio>
                      </Col>
                    )}
                    {fileType.word && (
                      <Col span={12}>
                        <Radio value="word">
                          <Label type="tab-header-inactive">{t('Word')}</Label>
                        </Radio>
                      </Col>
                    )}
                    {fileType.excel && (
                      <Col span={12}>
                        <Radio value="excel">
                          <Label type="tab-header-inactive">{t('Excel')}</Label>
                        </Radio>
                      </Col>
                    )}
                    {fileType.csv && (
                      <Col span={12}>
                        <Radio value="csv">
                          <Label type="tab-header-inactive">{t('CSV')}</Label>
                        </Radio>
                      </Col>
                    )}
                  </Row>
                </RadioGroup>
              </Form.Item>
              {!waterMark ? (
                <Form.Item name="waterMark" label={<Label type="tab-header-inactive">{t('ลายน้ำ')}</Label>}>
                  <InputNew placeholder={t('ลายน้ำ')} />
                </Form.Item>
              ) : (
                ''
              )}
              <Form.Item
                label={<Label type="tab-header-inactive">{t('ประเภทการส่ง')}</Label>}
                name="sendType"
                initialValue={'sendNow'}
                onClick={(e) => {
                  if (e.target.value) {
                    if (e.target.value === 'sendNow') {
                      setSendNow(true)
                    } else {
                      setSendNow(false)
                    }
                  }
                }}
              >
                <RadioGroup>
                  <Row gutter={[8, 8]}>
                    <Col>
                      <Radio value="sendNow">
                        <Label type="tab-header-inactive">{t('ส่งทันที')}</Label>
                      </Radio>
                    </Col>
                    <Col>
                      <Radio value="sendLater">
                        <Label type="tab-header-inactive">{t('กำหนดวันส่ง')}</Label>
                      </Radio>
                    </Col>
                  </Row>
                </RadioGroup>
              </Form.Item>
              {!sendNow ? (
                <Form.Item name="sendDate" label={<Label type="tab-header-inactive">{t('วันที่ส่ง')}</Label>}>
                  <Datepicker
                    setValueDateFn={(data) => {
                      form.setFieldsValue({
                        sendDate: data,
                      })
                    }}
                    onChange={() => {}}
                    onClear={() => {
                      form.setFieldsValue({ sendDate: null })
                    }}
                    placeholder={t('เลือกวันที่ส่ง')}
                  />
                </Form.Item>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row gutter={[12, 20]} justify="center" style={{ marginTop: '15px' }}>
            {sendNow ? (
              <Col>
                <ButtonNew type="success" roles={{ type: 'send', menu: menu }} htmlType="submit">
                  {t('ส่ง')}
                </ButtonNew>
              </Col>
            ) : (
              <Col>
                <ButtonNew type="primary" roles={{ type: 'send', menu: menu }} htmlType="submit">
                  {t('บันทึก')}
                </ButtonNew>
              </Col>
            )}
            <Col>
              <ButtonNew type="back" onClick={() => onClose(false)}>
                {t('ยกเลิก')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </ModalNew>
    </>
  )
}

export default SendEmailReportModal
