import { gql } from '@apollo/client'
export const GET_SELECT_MASTER_ACTION_STATUS = gql`
  query getSelectMasterActionStatus($input: SelectMasterActionStatusInput!) {
    getSelectMasterActionStatus(input: $input) {
      actionStatusId
      actionStatusCode
      actionStatusNameTH
      actionStatusNameEN
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`
