import { Trans } from 'react-i18next'

export const ColumnsApp = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'no',
    width: '8%',
    // sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>แผนการตรวจสอบ</Trans>,
    dataIndex: 'code',
    width: '37%',
    // sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>ประเภทแผนการตรวจสอบ</Trans>, //
    dataIndex: 'type',
    width: '15%',
    align: 'center',
    // sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'status',
    width: '20%',
    // sorter: true,
  },
  {
    title: '',
    dataIndex: 'options',
    width: '10%',
    // sorter: true,
    align: 'right',
  },
  {
    title: '',
    dataIndex: 'view',
    width: '5%',
    // sorter: true,
    align: 'right',
  },
]

export const DotApprove = {
  marginTop: '5px',
  marginRight: '5px',
  width: '14px',
  height: '14px',
  backgroundColor: '#45A648',
  borderRadius: '50%',
  display: 'inline-block',
}

export const DotConsidering = {
  marginTop: '5px',
  marginRight: '5px',
  width: '14px',
  height: '14px',
  backgroundColor: '#FFA800',
  borderRadius: '50%',
  display: 'inline-block',
}
