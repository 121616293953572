import { gql } from '@apollo/client'

export const GET_MASTER_CONSIDERS = gql`
  query GetMasterConsiders($input: MasterConsidersInput!) {
    getMasterConsiders(input: $input) {
      considerId
      considerCode
      considerNameTH
      considerNameEN
      isActive
    }
  }
`
