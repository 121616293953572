import { Trans } from 'react-i18next'

export const Columns = [
  {
    dataIndex: 'check',
    title: <Trans>เลือก</Trans>,
    width: '5%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>รหัสบุคลากร</Trans>,
    dataIndex: 'personnelCode',
    // sorter: true,
    width: '15%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ชื่อ-นามสกุล</Trans>,
    dataIndex: 'personnelName',
    // sorter: true,
    width: '30%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>หน่วยงาน</Trans>,
    dataIndex: 'organization',
    // sorter: true,
    width: '30%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ตำแหน่ง</Trans>,
    dataIndex: 'position',
    // sorter: true,
    width: '30%',
    className: 'text-nowrap',
  },
  {
    title: '',
    dataIndex: 'tools',
    // sorter: true,
    width: '10%',
  },
]
