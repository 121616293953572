import styled from 'styled-components'
import { colors } from '../../configs/styles.config'

export const ButtonDowloadsStyle = styled.span`
  .btn-template-dowloads {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    border-radius: 3px;
    font-size: 14px;
    width: 358px;
    height: 37px;
  }

  .btn-color-dowloads {
    background-color: ${colors.white};
    color: ${colors.primary};
    border: 1px solid ${colors.primary};
    &:hover {
      transition: 200ms;
      background-color: ${colors.primaryHover2};
    }
    &:active {
      transition: 200ms;
      background-color: ${colors.primary};
      color: ${colors.white};
    }
  }
`
