import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { ColumnDetails } from '../../utils/approvePlanDetails'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ButtonNew as Button } from '../../../../../components/button/Button'
import { Alert } from '../../../../../components/alert/Alert'
import { AlertconfirmNotApprove } from '../../component/fnAlertconfirmNotApprove'
import { encryptInput } from '../../../../../utilitys/crypto'
import { UPDATE_APPROVE_PROJECT } from '../../graphql/Mutation'
import { GET_APPROVE_AUDIT_PROJECT_LIST } from '../../graphql/Query'
import { useLazyQuery, useMutation } from '@apollo/client'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { dateDisplay } from '../../../../../utilitys/dateHelper.js'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'
import { displayError, displayText } from '../../../../../utilitys/helper'

export default function ApproveTab(props) {
  const { menu, state } = props
  const [user] = useDecodeUser()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [userData] = useDecodeUser()
  const Responsive = { md: 24, lg: 24 }

  const [getSelectData, setSelectData] = useState({ projectCode: [] })
  const [dataLists, setDataLists] = useState([])
  const [disabled, setDisabled] = useState(false)

  const [getApproveAuditProjectListfn, getApproveAuditProjectList] = useLazyQuery(GET_APPROVE_AUDIT_PROJECT_LIST)
  const [updateApproveProjectFn, { loading: loadingUpdated, error, data: dataUpdated }] =
    useMutation(UPDATE_APPROVE_PROJECT)

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectData({ projectCode: selectedRows })
    },
    getCheckboxProps: (record) => {
      return { disabled: String(record.approveStatusCode) !== '10' ? true : disabled, name: record.name }
    },
  }

  useEffect(() => {
    setDisabled(state?.pageType === 'view' ? true : false)
  }, [state?.pageType])

  const btnAlertNotSelect = () => {
    Alert({
      type: 'error',
      title: t(`กรุณาเลือกรายการโครงการตรวจสอบ`),
      onOk() {},
    })
  }

  const btnConfirmNotApprove = () => {
    AlertconfirmNotApprove({
      type: 'confirm',
      title: t('ระบุเหตุผลการไม่อนุมัติแผนปฏิบัติงานรายบุคคล'),
      onConfirm: async (value) => {
        if (value.length === 0) {
          Alert({
            type: 'error',
            title: t(`กรุณาระบุเหตุผลการไม่อนุมัติแผนปฏิบัติ`),
            onOk() {},
          })
        } else {
          let dataPost = {}
          dataPost = {
            planCode: state.refCode,
            approveStatusCode: '30',
            cancelRemarkTH: value,
            cancelRemarkEN: value,
            approvePersonnelCode: userData.pCode,
            projectCode: getSelectData.projectCode.map((ap) => ap.projectCode),
          }

          await updateApproveProjectFn({
            variables: {
              inputData: encryptInput(dataPost),
            },
            fetchPolicy: 'no-cache',
          })
        }
      },
      onCancel() {
        return null
      },
    })
  }

  const btnConfirmApprove = () => {
    const dataApprove = encryptInput({
      planCode: state.refCode,
      approveStatusCode: '20',
      approvePersonnelCode: userData.pCode,
      cancelRemarkTH: '',
      cancelRemarkEN: '',
      projectCode: getSelectData.projectCode.map((ap) => ap.projectCode),
    })

    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อทำการอนุมัติรายการที่เลือก'),
      onOk: async () => {
        await updateApproveProjectFn({
          variables: {
            inputData: dataApprove,
          },
          fetchPolicy: 'no-cache',
        })
      },
      onCancel() {
        return null
      },
    })
  }

  useEffect(() => {
    if (user) {
      getApproveAuditProjectListfn({
        variables: {
          inputData: encryptInput({ planCode: state.refCode, cancelStatus: [0], personnelCode: user.pCode }),
        },
      })
    }
  }, [state.refCode, user])

  useEffect(() => {
    if (dataUpdated) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          window.location.reload()
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [dataUpdated])

  useEffect(() => {
    if (getApproveAuditProjectList?.data) {
      setDataLists(
        getApproveAuditProjectList?.data.getApproveAuditProjectList.map((dt, keys) => {
          return {
            key: keys,
            projectCode: dt.projectCode,
            pjNameCol: dt.projectCode + ' : ' + displayText(dt.projectNameTH, dt.projectNameEN),
            pjSourceCol: displayText(dt.projectRefNameTH, dt.projectRefNameEN),
            pjDateStartCol: dateDisplay(dt.dateStart),
            pjDateEndCol: dateDisplay(dt.dateEnd),
            pjAuditTeamCol: displayText(dt.auditTeamNameTH, dt.auditTeamNameEN),
            pjApproveStatus: dt.approveStatusNameTH,
            approveStatusCode: dt.approveStatusCode,

            pjOptionsCol: (
              <div key={'div-view-apdt-approve' + keys} style={{ textAlign: 'left' }}>
                <ButtonGroup
                  key={'btnGroup-view-apdt-approve' + keys}
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => {
                        navigate('/auditplanning/approveauditplan/detail/projectdetail', {
                          state: {
                            refCode: state.refCode,
                            projectCode: dt.projectCode,
                            planType: dt.planType,
                            planFiles: dt.planFiles,
                            projectFiles: dt.files,
                            pageType: state.pageType,
                          },
                        })
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (getApproveAuditProjectList.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getApproveAuditProjectList.error)),
        onOk() {},
      })
    }
  }, [getApproveAuditProjectList.data])

  return (
    <>
      {(loadingUpdated || getApproveAuditProjectList.loading) && <SpinnersNew />}
      <Row gutter={[16, 16]}>
        <Col {...Responsive}>
          <Datatable
            columns={ColumnDetails}
            data={dataLists}
            // enableScroll={{ x: true, y: true }}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            paginationCustom={false}
            pagination={true}
            pageSize={10}
            handleTableChange={() => {}}
          />
        </Col>
      </Row>
      <Row gutter={[12, 12]} style={{ marginTop: 24, marginBottom: 24 }} align="center">
        {state?.pageType === 'edit' ? (
          <>
            <Col>
              <Button
                type="allow"
                roles={{ type: 'approve', menu: menu }}
                onClick={() => {
                  getSelectData.projectCode.length === 0 ? btnAlertNotSelect() : btnConfirmApprove()
                }}
              >
                {t('อนุมัติ')}
              </Button>
            </Col>
            <Col>
              <Button
                type="noAllow"
                roles={{ type: 'approve', menu: menu }}
                onClick={() => {
                  getSelectData.projectCode.length === 0 ? btnAlertNotSelect() : btnConfirmNotApprove()
                }}
              >
                {t('ไม่อนุมัติ')}
              </Button>
            </Col>
            <Col>
              <Button
                type="back"
                onClick={() => {
                  navigate('/auditplanning/approveauditplan')
                }}
              >
                {t('ย้อนกลับ')}
              </Button>
            </Col>
          </>
        ) : (
          <>
            <Col md={3}>
              <Button
                type="back"
                onClick={() => {
                  navigate('/auditplanning/approveauditplan')
                }}
              >
                {t('ย้อนกลับ')}
              </Button>
            </Col>
          </>
        )}
      </Row>
    </>
  )
}
