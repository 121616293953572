import { Spin } from 'antd'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { colors } from '../../configs/styles.config'
import { SpinnersStyle, SpinnersSmaillStyle } from './SpinnersStyle'
import { useTranslation } from 'react-i18next'

export const SpinnersNew = (props) => {
  const antIcon2 = <Loading3QuartersOutlined style={{ fontSize: 50, color: colors.primary }} spin />
  //   delay={500}
  const { t } = useTranslation()
  return (
    <SpinnersStyle>
      <Spin
        indicator={antIcon2}
        spinning={props.loading ? props.loading : true}
        className="spinner-title"
        tip={t('กำลังโหลด...')}
      >
        {props.children}
      </Spin>
    </SpinnersStyle>
  )
}

export const SpinnersSmaillNew = () => {
  return (
    <SpinnersSmaillStyle>
      <Spin size="large" />
    </SpinnersSmaillStyle>
  )
}
