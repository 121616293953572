import React, { useState, useEffect } from 'react'
// import _ from 'lodash'
import moment from 'moment'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CardNew } from '../../../components/card/Card'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { LabelNew } from '../../../components/label/Label'
import { InputNew, InputNumberNew } from '../../../components/input/Input'
import { TextAreaNew } from '../../../components/textarea/TextArea'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { Timepicker } from '../../../components/timepicker/Timepicker.jsx'
import { UploadNew } from '../../../components/upload/Upload.jsx'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import SelectJobGroups from '../../../components/inputfromapi/selectjobgroups/SelectJobGroups.jsx'
import SelectJobTypes from '../../../components/inputfromapi/selectjobtypes/SelectJobTypes.jsx'
import SelectProjectTypes from '../../../components/inputfromapi/selectprojecttypes/SelectProjectTypes.jsx'
import SelectJobStatus from '../../../components/inputfromapi/selectjobstatus/SelectJobStatus.jsx'
import SelectUrgents from '../../../components/inputfromapi/selecturgents/SelectUrgents.jsx'
import SelectAdhocJob from './components/select/SelectAdhocJob.jsx'
import SelectAuditProject from './components/select/SelectAuditProject'
import SelectAuditJobStep from './components/select/SelectAuditJobStep'
import SelectApproveStatus from '../../../components/inputfromapi/selectapprovestatus/SelectApproveStatus.jsx'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayText, validateURL, moneyFormat, displayError } from '../../../utilitys/helper'
import { encryptInput } from '../../../utilitys/crypto'
import { SAVE_TIMESHEET } from './graphql/Mutation'
import { GET_TIMESHEET_BY_ID } from './graphql/Query'

const TimeSheetForm = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { state } = useLocation()

  if (!state) {
    return <Navigate to={'/auditoperations/timesheet'} />
  }
  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/timesheet' },
    { label: t('บันทึกการปฏิบัติงาน'), path: '/auditoperations/timesheet' },
  ]

  const Responsive = {
    md: 18,
    lg: 18,
  }
  const Responsive2 = {
    md: 24,
    lg: 24,
  }

  const { refCode, type_action } = state
  const [user] = useDecodeUser()
  const [disabled, setDisabled] = useState(type_action === 'view' ? true : false)
  const [disabledDate, setDisabledDate] = useState(type_action === 'view' ? true : false)
  const [isPCode, setPCode] = useState(null)
  const [fieldJobGroup, setFieldJobGroup] = useState()
  const [fieldTypeCode, setFieldTypeCode] = useState()
  const [fieldCode, setFieldCode] = useState()
  const [idFile, setIdFile] = useState([])
  const [idFileDefault, setIdFileDefault] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])
  const [fileDetail, setFileDetail] = useState()
  const [timeStart, setTimeStart] = useState()
  const [timeEnd, setTimeEnd] = useState()
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [dateStartJobAndProject, setDateStartJobAndProject] = useState(null)

  const [initData, setInitData] = useState({
    duration: null,
    useday: null,
  })

  const convertMinuteToHour = (timeStart, timeEnd) => {
    var end = moment(timeEnd)
    var start = moment(timeStart)
    var duration = moment.duration(end.diff(start))
    var minute = duration.asMinutes()

    return `${(minute / 60) ^ 0}`.slice(-2) + '.' + ('0' + (minute % 60)).slice(-2)
  }
  /* ---------------- API ----------- */
  const [getDataFn, { loading: loadingGetData, error: errorGetData, data: dataTimeSheet }] =
    useLazyQuery(GET_TIMESHEET_BY_ID)

  const [saveFn, { loading: loadingSave, error: errorSave, data: saveTimeSheet }] = useMutation(SAVE_TIMESHEET)

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    const dataInput = {
      timeSheetId: refCode || null,
      jobGroupCode: input.jobGroupCode || null,
      jobTypeCode: input.jobTypeCode || null,
      projectTypeCode: input.projectTypeCode || null,
      jobCode: input.jobCode || null,
      projectCode: input.projectCode || null,
      stepId: input.stepId || null,
      urgentCode: input.urgentCode || null,
      placeOfWorkTH: (localStorage.getItem('lang') === 'th' ? input.placeOfWork : input.placeOfWorkTH) || '',
      placeOfWorkEN: (localStorage.getItem('lang') === 'en' ? input.placeOfWork : input.placeOfWorkEN) || '',
      jobDetailTH: (localStorage.getItem('lang') === 'th' ? input.jobDetail : input.jobDetailTH) || '',
      jobDetailEN: (localStorage.getItem('lang') === 'en' ? input.jobDetail : input.jobDetailEN) || '',
      jobResponsibilityTH:
        (localStorage.getItem('lang') === 'th' ? input.jobResponsibility : input.jobResponsibilityTH) || '',
      jobResponsibilityEN:
        (localStorage.getItem('lang') === 'th' ? input.jobResponsibility : input.jobResponsibilityEN) || '',
      outputTH: (localStorage.getItem('lang') === 'th' ? input.output : input.outputTH) || '',
      outputEN: (localStorage.getItem('lang') === 'th' ? input.output : input.outputEN) || '',
      outcomeTH: (localStorage.getItem('lang') === 'th' ? input.outcome : input.outcomeTH) || '',
      outcomeEN: (localStorage.getItem('lang') === 'th' ? input.outcome : input.outcomeEN) || '',
      useday: input.useday || null,
      dateStart: input.dateStart || null,
      dateEnd: input.dateEnd || null,
      timeStart: input.timeStart || null,
      timeEnd: input.timeEnd || null,
      hour: input.hour || null,
      fileDetail: input.fileDetail || null,
      attachFile: idFile.join() || null,
      jobStatusCode: input.jobStatusCode || null,
      approveStatusCode: input.approveStatusCode || null,
      action: type_action,
      personnelAction: isPCode,
      cost: input.cost || null,
    }

    if (dataInput.timeStart !== null || dataInput.timeEnd !== null) {
      if (validTime() && validDateJobAndProject()) {
        showConfirm(dataInput)
      }
    } else {
      if (validDateJobAndProject()) {
        showConfirm(dataInput)
      }
    }
  }

  const showConfirm = (input) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        saveFn({
          variables: {
            input: encryptInput(input),
          },
        })
      },
      onCancel() {},
    })
  }

  const showErrorAlert = (item) => {
    Alert({
      type: 'error',
      title: t(item.title),
      content: t(displayError(item.content)),
      onOk() {},
    })
  }

  const setErrorField = (name, errors) => {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  const validTime = () => {
    let valid = false
    let timeStart = form.getFieldValue('timeStart')
    let timeEnd = form.getFieldValue('timeEnd')

    if (timeStart <= timeEnd) {
      setErrorField('timeStart', false)
      setErrorField('timeEnd', false)
      valid = true
    } else {
      setErrorField('timeStart', t('กรุณาเลือกเวลาเริ่มต้นน้อยกว่าเวลาสิ้นสุด'))
      setErrorField('timeEnd', t('กรุณาเลือกเวลาสิ้นสุดมากกว่าเวลาเริ่มต้น'))
    }

    return valid
  }

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    user && setPCode(user?.pCode)
  }, [user])

  useEffect(() => {
    if (refCode) {
      getDataFn({
        variables: {
          timeSheetId: encryptInput(refCode),
        },
      })
    }
  }, [refCode])

  useEffect(() => {
    form.setFieldsValue({
      approveStatusCode: '10',
    })
  }, [])

  useEffect(() => {
    if (dataTimeSheet) {
      const data = dataTimeSheet.getTimeSheetById
      if (data.attachFile) {
        setIdFile(() => {
          return data.attachFile.split(',').map((item) => {
            return parseInt(item)
          })
        })

        setIdFileDefault(() => {
          return data.attachFile.split(',').map((item) => {
            return parseInt(item)
          })
        })
      }

      /* เช็คสถานะว่า timesheet นี้ถูกส่งคำขอหรือยัง ถ้าส่งคำขอแล้วจะแก้ไขไม่ได้ */
      if (data.statusSendRequest === 1) setDisabledDate(true)

      /* เช็คสถานะว่า timesheet นี้อนุมัติ หรือ ไม่อนุมัติ หรือยัง ? ถ้าใช่จะแก้ไขไม่ได้ */
      if (data.approveStatusCode === '20' || data.approveStatusCode === '30') setDisabled(true)

      setFieldJobGroup(data.jobGroupCode)
      setFieldTypeCode(data.jobTypeCode || data.projectTypeCode)
      setFieldCode(data.jobCode || data.projectCode)
      setFileDetail(data.fileDetail)

      if (data.timeStart && data.timeEnd) {
        setTimeStart(moment().format('YYYY-MM-DD') + ' ' + data.timeStart)
        setTimeEnd(moment().format('YYYY-MM-DD') + ' ' + data.timeEnd)
      }

      form.setFieldsValue({
        jobGroupCode: data.jobGroupCode || null,
        jobTypeCode: data.jobTypeCode || null,
        projectTypeCode: data.projectTypeCode || null,
        jobCode: data.jobCode || null,
        projectCode: data.projectCode || null,
        stepId: data.stepId || null,
        urgentCode: data.urgentCode || null,
        placeOfWork: displayText(data.placeOfWorkTH, data.placeOfWorkEN) || '',
        placeOfWorkTH: data.placeOfWorkTH || '',
        placeOfWorkEN: data.placeOfWorkEN || '',
        jobDetail: displayText(data.jobDetailTH, data.jobDetailEN) || '',
        jobDetailTH: data.jobDetailTH || '',
        jobDetailEN: data.jobDetailEN || '',
        jobResponsibility: displayText(data.jobResponsibilityTH, data.jobResponsibilityEN) || '',
        jobResponsibilityTH: data.jobResponsibilityTH || '',
        jobResponsibilityEN: data.jobResponsibilityEN || '',
        output: displayText(data.outputTH, data.outputEN) || '',
        outputTH: data.outputTH || '',
        outputEN: data.outputEN || '',
        outcome: displayText(data.outcomeTH, data.outcomeEN) || '',
        outcomeTH: data.outcomeTH || '',
        outcomeEN: data.outcomeEN || '',
        useday: data.useday || null,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        timeStart: data.timeStart,
        timeEnd: data.timeEnd,
        hour: data.hour || null,
        fileDetail: data.fileDetail || null,
        jobStatusCode: data.jobStatusCode || null,
        approveStatusCode: data.approveStatusCode || null,
        cost: data.cost || null,
      })
    }

    if (errorGetData) {
      showErrorAlert({
        title: 'ไม่สามารถดึงข้อมูลได้',
        content: errorGetData,
      })
    }
  }, [dataTimeSheet])

  useEffect(() => {
    if (saveTimeSheet) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/auditoperations/timesheet')
        },
      })
    }

    if (errorSave) {
      showErrorAlert({
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        content: errorSave,
      })
    }
  }, [saveTimeSheet])

  useEffect(() => {
    if (timeStart && timeEnd) {
      form.setFieldsValue({ hour: convertMinuteToHour(timeStart, timeEnd) })
    }
  }, [timeStart, timeEnd])

  useEffect(() => {
    if (initData) {
      form.setFieldsValue({
        duration: initData.duration,
        useday: initData.useday,
      })

      if (initData.dateStartJobAndProject) {
        setDateStartJobAndProject(moment(initData.dateStartJobAndProject).format('YYYY-MM-DD'))
      }
    }
  }, [initData])

  /* Function Check วันทีเริ่มต้นต้องมากกว่าวันที่เริ่มต้นโปรเจค */
  const validDateJobAndProject = () => {
    // ถ้ามี การเลือกงาน/โครงการ วันทีเริ่มต้นต้องมากกว่าวันที่เริ่มต้นโปรเจค
    if (dateStartJobAndProject) {
      let inputDateStart = form.getFieldValue('dateStart')
      const mainDateProject = moment(dateStartJobAndProject).format('YYYY-MM-DD')

      if (mainDateProject && inputDateStart) {
        var checkDate = moment(inputDateStart).isBefore(mainDateProject) // true
        if (checkDate) {
          setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นให้อยู่ในระยะเวลาเริ่มต้น - สิ้นสุด'))
        } else {
          return true
        }
      }
    }
    // ถ้าไม่มี การเลือกงาน/โครงการ เช็ค fieldJobGroup ว่าใช่ 90 ไหมถ้าใช่ให้ผ่าน
    else {
      if (fieldJobGroup === '90') return true
      else {
        //ถ้าไม่ เช็ค fieldTypeCode
        if (fieldTypeCode === '31' || fieldTypeCode === '35') {
          return false
        } else {
          return true
        }
      }
    }
  }

  return (
    <>
      {(loadingSave || loadingGetData) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('บันทึกการปฏิบัติงาน')} />
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ md: { span: 8 }, lg: { span: 5 } }}
        wrapperCol={{ md: { span: 16 }, lg: { span: 12 } }}
        name="formTimeSheet"
      >
        <CardNew
          topic={type_action === 'add' ? t('เพิ่มการปฏิบัติงาน') : t('แก้ไขการปฏิบัติงาน')}
          icon={type_action === 'add' ? 'Plus' : 'Edit'}
          toggledrop="false"
        >
          <Row gutter={[12, 12]} justify="center">
            <Col {...Responsive}>
              <Row style={{ margin: '10px 0px' }}>
                <Col {...Responsive2}>
                  <LabelNew type="sub-header-primary">{t('รายละเอียดทั่วไป')}</LabelNew>
                </Col>
              </Row>
              <Row justify="center">
                <Col {...Responsive}>
                  <Form.Item
                    name="jobGroupCode"
                    label={<LabelNew type="tab-header-inactive">{t('ลักษณะงาน')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <SelectJobGroups
                      placeholder={t('เลือกลักษณะงาน')}
                      formname={'formTimeSheet'}
                      filters={{ isActive: 1 }}
                      handleChange={(e) => {
                        form.setFieldsValue({ jobGroupCode: e?.value }),
                          setFieldJobGroup(e?.value),
                          form.resetFields(['projectTypeCode', 'jobTypeCode', 'stepId', 'jobCode', 'projectCode']),
                          setFieldTypeCode(),
                          setFieldCode()
                      }}
                      onClear={() => {
                        setFieldJobGroup()
                        setFieldTypeCode()
                        setFieldCode()
                      }}
                      disabled={disabled}
                    />
                  </Form.Item>

                  {fieldJobGroup === '10' ? (
                    <Form.Item
                      name="projectTypeCode"
                      label={<LabelNew type="tab-header-inactive">{t('ประเภท')}</LabelNew>}
                      rules={[
                        {
                          required: true,
                          message: t('กรุณาระบุ'),
                        },
                      ]}
                    >
                      <SelectProjectTypes
                        placeholder={t('เลือกประเภทโครงการ')}
                        formname={'formTimeSheet'}
                        filters={{ isActive: 1 }}
                        handleChange={(e) => {
                          form.setFieldsValue({ projectTypeCode: e?.value }),
                            setFieldTypeCode(e?.value),
                            form.resetFields(['projectCode', 'stepId']),
                            setFieldCode()
                        }}
                        onClear={() => {
                          setFieldTypeCode()
                          setFieldCode()
                        }}
                        disabled={disabled ? disabled : fieldJobGroup ? false : true}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="jobTypeCode"
                      label={<LabelNew type="tab-header-inactive">{t('ประเภท')}</LabelNew>}
                      rules={[
                        {
                          required: true,
                          message: t('กรุณาระบุ'),
                        },
                      ]}
                    >
                      <SelectJobTypes
                        placeholder={t('เลือกประเภทงาน')}
                        formname={'formTimeSheet'}
                        filters={{ jobGroupCode: fieldJobGroup || null, isActive: 1 }}
                        handleChange={(e) => {
                          form.setFieldsValue({ jobTypeCode: e?.value }),
                            setFieldTypeCode(e?.value),
                            form.resetFields(['jobCode', 'stepId']),
                            setFieldCode()
                        }}
                        disabled={disabled ? disabled : fieldJobGroup ? false : true}
                        onClear={() => {
                          setFieldTypeCode()
                          setFieldCode()
                        }}
                      />
                    </Form.Item>
                  )}
                  {}
                  {fieldJobGroup === '10' ? (
                    <Form.Item
                      name="projectCode"
                      label={<LabelNew type="tab-header-inactive">{t('งาน/โครงการ')}</LabelNew>}
                      rules={[
                        {
                          required: true,
                          message: t('กรุณาระบุ'),
                        },
                      ]}
                    >
                      <SelectAuditProject
                        formname={'formTimeSheet'}
                        filters={{ projectTypeCode: fieldTypeCode || null, personnelCode: isPCode || null }}
                        handleChange={(e) => {
                          setFieldCode(e?.value),
                            form.resetFields(['stepId']),
                            form.setFieldsValue({
                              projectCode: e?.value,
                              duration: dateDisplay(e?.source?.dateStart) + ' - ' + dateDisplay(e?.source?.dateEnd),
                              useday: e?.source?.useday,
                            }),
                            setDateStartJobAndProject(e?.source?.dateStart)
                        }}
                        disabled={disabled ? disabled : fieldJobGroup === '90' ? true : fieldTypeCode ? false : true}
                        setInitData={setInitData}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="jobCode"
                      label={<LabelNew type="tab-header-inactive">{t('งาน/โครงการ')}</LabelNew>}
                      rules={[
                        {
                          required:
                            fieldJobGroup === '90'
                              ? false
                              : fieldTypeCode === '31' || fieldTypeCode === '35'
                              ? true
                              : false,
                          message: t('กรุณาระบุ'),
                        },
                      ]}
                    >
                      <SelectAdhocJob
                        formname={'formTimeSheet'}
                        filters={{ jobTypeCode: fieldTypeCode || null, personnelCode: isPCode }}
                        handleChange={(e) => {
                          setFieldCode(e?.value),
                            form.resetFields(['stepId']),
                            form.setFieldsValue({
                              jobCode: e?.value,
                              duration: dateDisplay(e?.source?.dateStart) + ' - ' + dateDisplay(e?.source?.dateEnd),
                              useday: e?.source?.useday,
                            }),
                            setDateStartJobAndProject(e?.source?.dateStart)
                        }}
                        disabled={disabled ? disabled : fieldJobGroup === '90' ? true : fieldTypeCode ? false : true}
                        // getData={(e) => {
                        //   form.setFieldsValue({
                        //     duration: dateDisplay(e?.source?.dateStart) + ' - ' + dateDisplay(e?.source?.dateEnd),
                        //     useday: e?.source?.useday,
                        //   })
                        // }}
                        // fieldCode={fieldCode}
                        // typeAction={type_action}
                        setInitData={setInitData}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="stepId"
                    label={<LabelNew type="tab-header-inactive">{t('ขั้นตอนปฏิบัติงาน')}</LabelNew>}
                  >
                    <SelectAuditJobStep
                      formname={'formTimeSheet'}
                      filters={{
                        jobGroupCode: fieldJobGroup || null,
                        jobAndProjectCode: fieldCode || null,
                        personnelCode: isPCode,
                      }}
                      handleChange={(e) => form.setFieldsValue({ stepId: e?.value })}
                      disabled={disabled ? disabled : fieldCode ? false : true}
                    />
                  </Form.Item>
                  <Form.Item
                    name="urgentCode"
                    label={<LabelNew type="tab-header-inactive">{t('ความเร่งด่วน')}</LabelNew>}
                  >
                    <SelectUrgents
                      // placeholder={t('เลือก')}
                      formname={'formTimeSheet'}
                      handleChange={(e) => form.setFieldsValue({ urgentCode: e?.value })}
                      disabled={disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    name="placeOfWork"
                    label={<LabelNew type="tab-header-inactive">{t('สถานที่ปฏิบัติงาน')}</LabelNew>}
                  >
                    <InputNew disabled={disabled} maxLength={100} />
                  </Form.Item>
                  <Form.Item name="placeOfWorkTH" hidden={true}>
                    <InputNew />
                  </Form.Item>
                  <Form.Item name="placeOfWorkEN" hidden={true}>
                    <InputNew />
                  </Form.Item>
                  <Form.Item
                    name="jobDetail"
                    label={<LabelNew type="tab-header-inactive">{t('รายละเอียดงาน')}</LabelNew>}
                  >
                    <TextAreaNew
                      placeholder={t('รายละเอียดงาน')}
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      disabled={disabled}
                    />
                  </Form.Item>
                  <Form.Item name="jobDetailTH" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item name="jobDetailEN" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item
                    name="jobResponsibility"
                    label={<LabelNew type="tab-header-inactive">{t('สรุปผลการทำงาน')}</LabelNew>}
                  >
                    <TextAreaNew
                      placeholder={t('งานที่รับผิดชอบ')}
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      disabled={disabled}
                    />
                  </Form.Item>
                  <Form.Item name="jobResponsibilityTH" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item name="jobResponsibilityEN" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item name="output" label={<LabelNew type="tab-header-inactive">{t('ผลที่ได้')}</LabelNew>}>
                    <TextAreaNew
                      placeholder={t('ผลที่ได้')}
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      disabled={disabled}
                    />
                  </Form.Item>
                  <Form.Item name="outputTH" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item name="outputEN" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item
                    name="outcome"
                    label={<LabelNew type="tab-header-inactive">{t('ผลลัพธ์ที่ได้')}</LabelNew>}
                  >
                    <TextAreaNew
                      placeholder={t('ผลลัพธ์ที่ได้')}
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      disabled={disabled}
                    />
                  </Form.Item>
                  <Form.Item name="outcomeTH" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                  <Form.Item name="outcomeEN" hidden={true}>
                    <TextAreaNew />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ margin: '10px 0px' }}>
                <Col {...Responsive2}>
                  <LabelNew type="sub-header-primary">{t('เวลาปฏิบัติงาน')}</LabelNew>
                </Col>
              </Row>
              <Row justify="center">
                <Col {...Responsive}>
                  <Form.Item
                    name="duration"
                    label={<LabelNew type="tab-header-inactive">{t('ระยะเวลาเริ่มต้น - สิ้นสุด')}</LabelNew>}
                  >
                    <InputNew disabled />
                  </Form.Item>
                  <Form.Item
                    name="useday"
                    label={<LabelNew type="tab-header-inactive">{t('จำนวนวันที่ใช้ไปแล้ว')}</LabelNew>}
                  >
                    <InputNew disabled />
                  </Form.Item>
                  <Form.Item
                    name="dateStart"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <Datepicker
                      autoComplete="off"
                      setValueDateFn={async (data) => {
                        if (data) {
                          await setIsMinDate(data),
                            form.setFieldsValue({ dateStart: await data /* dateEnd: await data */ }),
                            validDateJobAndProject()
                        }
                      }}
                      onClear={() => {
                        form.resetFields(['dateStart' /* 'dateEnd' */]),
                          form.resetFields(['timeStart', 'timeEnd', 'hour'])
                      }}
                      placeholder={t('DD/MM/YYYY')}
                      disabled={disabledDate}
                      isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                      onChange={() => {
                        form.resetFields(['timeStart', 'timeEnd', 'hour'])
                      }}
                    />
                  </Form.Item>

                  <Form.Item
                    name="dateEnd"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <Datepicker
                      autoComplete="off"
                      setValueDateFn={async (data) => {
                        await setIsMaxDate(data), form.setFieldsValue({ dateEnd: await data })
                      }}
                      onClear={() => {
                        form.resetFields(['dateEnd']), form.resetFields(['timeStart', 'timeEnd', 'hour'])
                      }}
                      placeholder={t('DD/MM/YYYY')}
                      disabled={disabledDate}
                      isMinDate={isMinDate ? new Date(isMinDate) : null}
                      onChange={() => {
                        form.resetFields(['timeStart', 'timeEnd', 'hour'])
                      }}
                    />
                  </Form.Item>
                  <Row style={{ marginTop: '-10px', marginBottom: '7px' }}>
                    <Col md={8} lg={5}>
                      {' '}
                    </Col>
                    <Col md={16} lg={12}>
                      <div>
                        <LabelNew type="body" style={{ color: '#ea5456' }}>
                          {t('หากวันที่เริ่มต้น และวันที่สิ้นสุดเป็นวันเดียวกัน จะต้องระบุเวลาด้วย')}
                        </LabelNew>
                      </div>
                    </Col>
                  </Row>
                  {isMinDate === isMaxDate && (isMinDate !== null || isMaxDate !== null) && (
                    <>
                      <Form.Item
                        name="timeStart"
                        label={<LabelNew type="tab-header-inactive">{t('เวลาเริ่มต้น')}</LabelNew>}
                        rules={[
                          {
                            required: true,
                            message: t('กรุณาระบุ'),
                          },
                        ]}
                      >
                        <Timepicker
                          onChangeTime={(time) => {
                            if (time) {
                              form.setFieldsValue({ timeStart: moment(time).format('HH:mm:00') }),
                                setTimeStart(moment(time).format('YYYY-MM-DD HH:mm:00')),
                                validTime()
                            } else {
                              form.setFieldsValue({ timeStart: null })
                            }
                          }}
                          notDateNow
                          disabled={disabled}
                        />
                      </Form.Item>
                      <Form.Item
                        name="timeEnd"
                        label={<LabelNew type="tab-header-inactive">{t('เวลาสิ้นสุด')}</LabelNew>}
                        rules={[
                          {
                            required: true,
                            message: t('กรุณาระบุ'),
                          },
                        ]}
                      >
                        <Timepicker
                          onChangeTime={(time) => {
                            if (time) {
                              form.setFieldsValue({ timeEnd: moment(time).format('HH:mm:00') }),
                                setTimeEnd(moment(time).format('YYYY-MM-DD HH:mm:00')),
                                validTime()
                            } else {
                              form.setFieldsValue({ timeEnd: null })
                            }
                          }}
                          disabled={disabled}
                          notDateNow
                        />
                      </Form.Item>
                    </>
                  )}
                  <Form.Item
                    name="hour"
                    label={<LabelNew type="tab-header-inactive">{t('เวลาที่ทำงาน(ชั่วโมง)')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <InputNumberNew min={0} step={0.01} align="right" maxLength={6} disabled={disabled} />
                  </Form.Item>
                  <Form.Item name="cost" label={<LabelNew type="tab-header-inactive">{t('งบประมาณ')}</LabelNew>}>
                    <InputNumberNew
                      min={0}
                      step={0.01}
                      align="right"
                      placeholder={t('ระบุประมาณ')}
                      formatter={(value) => moneyFormat(value)}
                      disabled={disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    name="jobStatusCode"
                    label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <SelectJobStatus
                      placeholder={t('เลือกสถานะ')}
                      formname={'formTimeSheet'}
                      filters={{ statusflag: 1, isActive: 1 }}
                      handleChange={(e) => form.setFieldsValue({ jobStatusCode: e?.value })}
                      disabled={disabled}
                    />
                  </Form.Item>
                  <Form.Item
                    name="approveStatusCode"
                    label={<LabelNew type="tab-header-inactive">{t('สถานะการอนุมัติ')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <SelectApproveStatus
                      formname={'formTimeSheet'}
                      filters={{ isActive: 1 }}
                      handleChange={(e) => form.setFieldsValue({ approveStatusCode: e?.value })}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name="fileDetail"
                    label={<LabelNew type="tab-header-inactive">{t('ลิงค์ภายนอก')}</LabelNew>}
                  >
                    {type_action !== 'view' ? (
                      <InputNew disabled={disabled} />
                    ) : (
                      <a
                        href={validateURL(fileDetail)}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: '#2b4990', fontSize: '14px' }}
                      >
                        <u>{fileDetail}</u>
                      </a>
                    )}
                  </Form.Item>
                  <Form.Item label={<LabelNew type="tab-header-inactive">{t('เอกสารเพิ่มเติม')}</LabelNew>}>
                    <UploadNew
                      type="mutiple"
                      setIdFile={setIdFile}
                      idFile={idFile}
                      typeFile="file"
                      actionType={type_action === 'add' ? 'create' : type_action}
                      idFileDelete={idFileDelete}
                      setIdFileDelete={setIdFileDelete}
                      disabled={type_action === 'view' ? true : false}
                      defaults={idFileDefault}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 20]} justify="center" style={{ marginTop: '15px' }}>
                {!disabled && (
                  <Col>
                    <ButtonNew type="primary" roles={{ type: type_action, menu: menu }} htmlType="submit">
                      {t('บันทึก')}
                    </ButtonNew>
                  </Col>
                )}
                <Col>
                  <ButtonNew type="back" onClick={() => navigate('/auditoperations/timesheet')}>
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardNew>
      </Form>
    </>
  )
}

export default TimeSheetForm
