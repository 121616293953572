import React from 'react'
import { Row, Col } from 'antd'
import UsersList from './UsersList'
/**
 * @function UsersIndext
 * @description
 * Menu: Internal Control
 * เมนู: จัดการข้อมูลผู้ใช้งาน
 * @returns Component
 */

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}

function UsersIndex(props) {
  const { menu } = props
  return (
    <>
      <Row>
        <Col {...Responsive}>
          <UsersList menu={menu} />
        </Col>
      </Row>
    </>
  )
}

export default UsersIndex
