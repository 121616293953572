import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { CardNew } from '../../../components/card/Card.jsx'
import { Datatable } from '../../../components/datatable/Datatable'
import { ACTIONEXPAIN_LIST } from './graphql/Query'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError } from '../../../utilitys/helper'
import { displayText } from '../../../utilitys/helper'
import { Alert } from '../../../components/alert/Alert'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { useNavigate } from 'react-router-dom'
import ActionExplainReportExport from './modal/ActionExplainReportExport'

const ActionExplainList = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [user] = useDecodeUser()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [dataForExport, setDataForExport] = useState({})
  const [actionExplainReportExportModal, setActionExplainReportExportModal] = useState(false)

  const [actionExplainListFn, actionExplainList] = useLazyQuery(ACTIONEXPAIN_LIST)

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  useEffect(() => {
    if (actionExplainList?.data) {
      let response = actionExplainList?.data?.actionExplainList

      setCount(response.count)
      setDataLists(
        response?.result?.map((item, index) => {
          return {
            key: index,
            projectName: item.projectCode + ' : ' + displayText(item.projectNameTH, item.projectNameEN),
            projectRef: displayText(item.projectRefNameTH, item.projectRefNameEN),
            issueStatus: displayText(item.issueStatusTH, item.issueStatusEN),
            extraBTN:
              item.hasAction === 0 ? (
                <ButtonNew
                  type="flagOutlinedInTableBlue"
                  roles={{ type: 'edit', menu: props.menu }}
                  onClick={() => {
                    navigate('/followup/actionexplain/formactionlist', {
                      state: { projectCode: item.projectCode },
                    })
                  }}
                >
                  {t('ดำเนินการ')}
                </ButtonNew>
              ) : (
                <ButtonNew
                  type="moreHorizontalInTableOrange"
                  roles={{ type: 'edit', menu: props.menu }}
                  onClick={() => {
                    navigate('/followup/actionexplain/formactionlist', {
                      state: { projectCode: item.projectCode },
                    })
                  }}
                >
                  {t('แก้ไขดำเนินการ')}
                </ButtonNew>
              ),
            action: (
              <div style={{ textAlign: 'center' }}>
                <ButtonGroup
                  menu={props.menu}
                  icons={[
                    {
                      type: 'download',
                      onClick: (data) => {
                        setDataForExport({
                          ...dataForExport,
                          ...data,
                          projectCode: item.projectCode,
                          typeAction: 'export',
                        })
                        setActionExplainReportExportModal(true)
                      },
                    },
                    {
                      type: 'view',
                      onClick: () => {
                        navigate('/followup/actionexplain/formactionlist', {
                          state: { projectCode: item.projectCode, actionType: 'view' },
                        })
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (actionExplainList.error) {
      showAlertLoadDataError(displayError(actionExplainList.error))
    }
  }, [actionExplainList.data])

  const columns = [
    { title: t('โครงการ'), dataIndex: 'projectName', width: '250px' },
    { title: t('แหล่งที่มาของโครงการ'), dataIndex: 'projectRef', width: '200px' },
    { title: t('สถานะ'), dataIndex: 'issueStatus', width: '150px' },
    { title: '', dataIndex: 'extraBTN', width: '25px', align: 'center', render: (record) => record },
    { title: '', dataIndex: 'action', width: '20px', align: 'center', render: (record) => record },
  ]

  const callApiSearch = () => {
    actionExplainListFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          filters: {
            lang: localStorage.getItem('lang'),
            projectRefCode: props.formFilter.getFieldValue('projectRefCode')
              ? props.formFilter.getFieldValue('projectRefCode')
              : null,
            project: props.formFilter.getFieldValue('project') ? props.formFilter.getFieldValue('project') : null,
            organizationCode: props.formFilter.getFieldValue('organizationCode')
              ? props.formFilter.getFieldValue('organizationCode').join()
              : null,
            projectStatusCode: props.formFilter.getFieldValue('projectStatusCode')
              ? props.formFilter.getFieldValue('projectStatusCode')
              : null,
            personnelCode: user.pCode,
          },
          pagination: { limit, page },
          sort: [],
        }),
      },
    })
  }

  useEffect(() => {
    if (user?.pCode) {
      callApiSearch()
    }
  }, [page, limit, props.searchStatus, user?.pCode])

  return (
    <>
      <Row>
        <Col md={24}>
          {actionExplainList.loading && <SpinnersNew />}
          <ActionExplainReportExport
            menu={props.menu}
            closeModal={(e) => setActionExplainReportExportModal(e)}
            actionExplainReportExportModal={actionExplainReportExportModal}
            dataForExport={dataForExport}
          />
          <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="AlignJustify" toggledrop="false">
            <Datatable
              columns={columns}
              data={dataLists}
              handleTableChange={() => {}}
              paginationCustom={true}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
              total={count}
              pageSize={limit}
              page={page}
            />
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default ActionExplainList
