import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import * as _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../../../hooks/useLanguage'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { breadcrumbList } from './utils/breadcrumbData'
import { assessmentAuditWorkListColumn } from './utils/assessmentAuditWorkColumn'
import { checkLanguageReturnData } from './components/fnForAssessmentAuditWork'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import { handleSort } from '../../../utilitys/pagination'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { GETASSESSMENTAUDITWORKPAGINATION } from './graphql/Query'
import { RowStyle } from './css/tableListDateExpireStyle'
import { Alert } from '../../../components/alert/Alert'
import { displayError } from '../../../utilitys/helper'

export default function AssessmentAuditWorkList(props) {
  const { menu } = props
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const navigate = useNavigate()
  const userData = decryptData(localStorage.getItem('user'))

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  const [dataList, setDataList] = useState([])

  const [getAssessmentAuditWorkPaginationfn, getAssessmentAuditWorkPagination] = useLazyQuery(
    GETASSESSMENTAUDITWORKPAGINATION,
  )

  const onOpenDetails = (data, pageType) => {
    navigate('/auditoperations/assessmentaudit/assessmentauditdetails', {
      state: {
        pageType: pageType,
        refCode: data.formResultId,
      },
    })
  }

  useEffect(() => {
    getAssessmentAuditWorkPaginationfn({
      variables: {
        input: encryptInput({
          filters: {
            personnelCode: userData.pCode,
          },
          pagination: {
            limit: limit,
            page: page,
          },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (getAssessmentAuditWorkPagination?.data) {
      const res = getAssessmentAuditWorkPagination?.data?.getAssessmentAuditWorkPagination
      setCount(res.count)
      setDataList(
        _.map(res.result, (dt, key) => {
          return {
            key: key,
            formYear: dt.formYear ? (isLang === 'th' ? dt.formYear + 543 : dt.formYear) : '',
            formCode: dt.formCode,
            formName: checkLanguageReturnData(dt.formNameTH, dt.formNameEN),
            formNameTH: dt.formNameTH,
            formNameEN: dt.formNameEN,
            startDate: dateDisplay(dt.startDate),
            endDate: dateDisplay(dt.endDate),
            status: dt.operationStatusCode,
            operationStatusCode: dt.operationStatusCode,
            responsibilityBy: dt.responsibilityBy,
            makerBy: dt.makerBy,
            makerStatusCode: dt.makerStatusCode,
            formResultId: dt.formResultId,
            userCode: userData.pCode,
            rawStartDate: dt.startDate,
            rawEndDate: dt.endDate,
          }
        }),
      )
    }
    if (getAssessmentAuditWorkPagination.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(getAssessmentAuditWorkPagination.error)),
        onOk() {},
      })
    }
  }, [getAssessmentAuditWorkPagination?.data])

  return (
    <React.Fragment>
      {getAssessmentAuditWorkPagination.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('ประเมินการปฏิบัติงาน')} />
      <CardNew topic={t('รายการประเมินการปฏิบัติงาน')} icon="List" toggledrop={'false'}>
        <RowStyle>
          <Datatable
            columns={assessmentAuditWorkListColumn({ menu, onOpenDetails })}
            data={dataList}
            total={count}
            isScoll={{ x: true, y: true }}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            paginationCustom={true}
            searchCustom={true}
            pageSize={limit}
            page={page}
            handleTableChange={(e) => {
              const lang = isLang === 'th' ? true : false
              const reNewField = [
                { projectName: lang ? 'projectNameTH' : 'projectNameEN' },
                { formTypeName: lang ? 'formTypeNameTH' : 'formTypeNameEN' },
                { formName: lang ? 'formNameTH' : 'formNameEN' },
                { status: 'isActive' },
              ]
              setSort(handleSort(e?.sorter, reNewField))
            }}
            rowClassName={(record) => {
              const now = new Date()
              const endDate = new Date(record.rawEndDate)
              const statusCheck = ['30', '31']
              if (
                endDate < now &&
                !statusCheck.includes(record.operationStatusCode) &&
                userData.pCode === record.responsibilityBy
              ) {
                return 'table-row-color'
              } else if (
                endDate < now &&
                !statusCheck.includes(record.makerStatusCode) &&
                userData.pCode === record.makerBy
              ) {
                return 'table-row-color'
              } else {
                return ''
              }
            }}
          />
        </RowStyle>
      </CardNew>
    </React.Fragment>
  )
}
