import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GET_LIST_RISK_LEVEL_FIVE_YEAR_REPORT_PAGINATION } from '../graphql/Query'
import { encryptInput } from '../../../../utilitys/crypto'
import { Alert } from '../../../../components/alert/Alert'
import { displayError, displayText } from '../../../../utilitys/helper'

export const useGetExport = (options) => {
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [getExportFn, getExport] = useLazyQuery(GET_LIST_RISK_LEVEL_FIVE_YEAR_REPORT_PAGINATION)

  useEffect(() => {
    if (options) {
      const { filters, fileType } = options
      const { yearStart, yearEnd } = filters
      if (yearStart && yearEnd) {
        if (fileType) {
          const dataInput = {
            filters: filters,
            lang: displayText('th', 'en'),
            // waterMark: waterM,
            fileType,
          }
          getExportFn({
            variables: { input: encryptInput(dataInput) },
          })
        }
      }
    }
  }, [options])

  useEffect(() => {
    if (getExport.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getExport.error)),
        onOk() {},
      })
    }
  }, [getExport.error])

  useEffect(() => {
    if (getExport?.data) setData(getExport?.data?.getListRiskLevelFiveYearReportPagination)
  }, [getExport?.data])

  const refresh = () => getExport.refetch()

  return [data, getExport?.loading, refresh]
}
