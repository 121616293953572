import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'key',
    width: '8%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ตัวแปร</Trans>,
    dataIndex: 'variableName',
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ประเภทตัวแปร</Trans>,
    dataIndex: 'variableType',
    width: '30%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ประเภทตัวแปร</Trans>,
    dataIndex: 'variableDetail',
    width: '30%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActive',
    width: '10%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>รายละเอียด</Trans>,
    dataIndex: 'tools',
    width: '15%',
    className: 'text-nowrap',
  },
]
