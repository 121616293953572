import {} from 'react'
import { useSubscription } from '@apollo/client'
import { NOTI_LOGIN_SUB } from './graphql/Query'

export const SubscriptionLoginData = (userId) => {
  if (userId) {
    const { data, error } = useSubscription(NOTI_LOGIN_SUB, { variables: { userId: userId } })

    if (error) {
      console.log('errorSubNotiLogin: ', error)
    }

    if (data) {
      return data
    }
  }
}
