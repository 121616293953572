import React, { useState, useEffect } from 'react'
import { Row, Col /*, Space */ } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
// import { useMutation } from '@apollo/client'
import { displayText } from '../../../utilitys/helper'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { handleSort } from '../../../utilitys/pagination'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { Status } from '../../../components/status/Status'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput } from '../../../utilitys/crypto'
import AuditClosesFilter from './filters/AuditClosesExitFilter'
// import { DELETE_AUDIT_CLOSE } from './graphql/Mutation'
import { AUDIT_CLOSE_EXPORT } from './graphql/Query'
import { useLazyQuery } from '@apollo/client'
import { displayError } from '../../../utilitys/helper'
const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24
}
const AuditClosesExitList = (props) => {
  const currentLang = localStorage.getItem('lang')
  const navigate = useNavigate()
  const menu = props.menu
  const { t } = useTranslation()

  const [dataLists, setDataLists] = useState([])
  const [sort, setSort] = useState([{ fieldSort: 'createdAt', sortType: 'DESC' }])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)

  const [callExport, exportResponse] = useLazyQuery(AUDIT_CLOSE_EXPORT)

  useEffect(() => {
    if (exportResponse.data) {
      if (exportResponse.data.auditCloseExport.message === 'succcess') {
        window.open(`${exportResponse.data.auditCloseExport.fileUrl}`, '_blank')
      } else {
        Alert({
          type: 'error',
          title: t('ไม่มีข้อมูล'),
          onOk() {}
        })
      }
    }

    if (exportResponse.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(exportResponse.error)),
        onOk() {}
      })
    }
  }, [exportResponse])

  // const [reFetch, setReFetch] = useState(false)

  // const [deleteAuditClosesFn, deleteAuditCloses] = useMutation(DELETE_AUDIT_CLOSE)
  // useEffect(() => {
  //   if (deleteAuditCloses.data) {
  //     Alert({
  //       type: 'success',
  //       title: t('ลบสำเร็จ'),
  //       onOk() {
  //         setReFetch(reFetch ? false : true)
  //       },
  //     })
  //   }
  //   if (deleteAuditCloses.error) {
  //     Alert({
  //       type: 'error',
  //       title: t('ไม่สามารถลบข้อมูลได้'),
  //       onOk() {},
  //     })
  //   }
  // }, [deleteAuditCloses.data])
  // const deleteAuditCloses_ = async (d) => {
  //   Alert({
  //     type: 'confirm',
  //     title: t('ต้องการยืนยันการลบ ?'),
  //     onOk: async () => {
  //       await deleteAuditClosesFn({
  //         variables: {
  //           input: encryptInput({
  //             projectCode: d.projectCode,
  //             closeId: d.closeId,
  //           }),
  //         },
  //       })
  //     },
  //     onCancel() {},
  //   })
  // }
  const Columns = [
    {
      title: t('ลำดับ'),
      dataIndex: 'no',
      width: '10%'
      // sorter: { multiple: 1 },
    },
    {
      title: t('โครงการ'),
      dataIndex: 'project',
      // sorter: { multiple: 2 },
      width: '30%'
    },
    {
      title: t('วันที่ปิดตรวจ'),
      dataIndex: 'dateExit',
      width: '10%'
      // sorter: { multiple: 3 },
    },
    {
      title: t('หน่วยงานที่รับผิดชอบ'),
      dataIndex: 'organizationName',
      width: '10%'
    },
    {
      title: t('ผู้รับผิดชอบ'),
      dataIndex: 'personnelName',
      width: '10%'
    },
    {
      title: t('สถานะ'),
      dataIndex: 'closeStatus',
      width: '15%',
      render: (d) => <Status text={d === 0 ? t('บันทึกร่าง') : t('เสร็จสิ้น')} />
    },
    {
      title: '',
      dataIndex: 'btn',
      width: '10%'
    },
    {
      title: '',
      render: (item) =>
        item.exitApproveStatus === 30 && (
          <>
            <Row justify="end">
              <Col>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'download',
                      onClick: () => {
                        let data = {
                          projectCode: item.projectCode,
                          issueType: 4,
                          lang: currentLang
                        }
                        callExport({
                          variables: {
                            input: encryptInput(data)
                          }
                        })
                      }
                    },
                    {
                      type: 'view',
                      path: '/button/view',
                      onClick: () => {
                        const obj = {
                          actionPlanId: item.actionPlanId,
                          projectCode: item.projectCode,
                          closeId: item.closeId
                        }
                        navigate(`/auditoperations/auditclosesexit/auditclosesexitdetail`, {
                          state: {
                            refCode: obj,
                            type_action: 'view'
                          }
                        })
                      }
                    }
                  ]}
                />
              </Col>
            </Row>
          </>
        )
    }
    // {
    //   title: '',
    //   dataIndex: 'tools',
    //   width: '10%',
    // },
  ]
  function getDataListFromFilter(value) {
    setDataLists([
      ...value.map((item, idx) => {
        const obj = {
          actionPlanId: item.actionPlanId,
          closeId: item.closeId,
          projectCode: item.projectCode,
          exitApproveStatus: item.exitApproveStatus
        }
        // item.exitApproveStatus = 20
        return {
          ...item,
          key: idx + 1,
          no: item.no,
          project: `${item.projectCode} : ${displayText(item.projectNameTH, item.projectNameEN)}`,
          dateExit: dateDisplay(item.dateExit),
          organizationName: displayText(item.organizationNameTH, item.organizationNameEN),
          personnelName: displayText(item.fullNameTH, item.fullNameEN),
          closeStatus: item.closeStatus,
          btn:
            item.exitApproveStatus === 30 ? (
              <ButtonNew
                type="moreHorizontalInTableGray"
                roles={{ type: 'edit', menu: menu }}
                onClick={() => {
                  navigate('/auditoperations/auditclosesexit/auditclosesexitdetail', {
                    state: {
                      refCode: obj,
                      type_action: 'view'
                    }
                  })
                }}
              >
                {t('ตรวจสอบเสร็จสิ้น')}
              </ButtonNew>
            ) : item.exitApproveStatus === 20 ? (
              <ButtonNew
                type="moreHorizontalInTableOrange"
                roles={{ type: 'edit', menu: menu }}
                onClick={() => {
                  navigate('/auditoperations/auditclosesexit/auditclosesexitdetail', {
                    state: { type_action: 'edit', refCode: obj }
                  })
                }}
              >
                {t('แก้ไขการตรวจสอบ')}
              </ButtonNew>
            ) : (
              <ButtonNew
                type="moreHorizontalInTableOrange"
                roles={{ type: 'edit', menu: menu }}
                onClick={() => {
                  navigate('/auditoperations/auditclosesexit/auditclosesexitdetail', {
                    state: { type_action: 'edit', refCode: obj }
                  })
                }}
              >
                {t('ตรวจสอบ')}
              </ButtonNew>
            )
          // tools: (
          //   <>
          //     <Row justify="end">
          //       <Col>
          //         <ButtonGroup menu={menu} icons={btn} />
          //       </Col>
          //     </Row>
          //   </>
          // ),
        }
      })
    ])
  }
  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/auditcloses' },
    { label: t('บันทึกปิดตรวจ') }
  ]
  return (
    <>
      <Breadcrumb data={breadcrumbList} title={t('บันทึกปิดตรวจ')} />
      <AuditClosesFilter
        page={page}
        limit={limit}
        menu={menu}
        sort={sort}
        // reFetch={reFetch}
        getCount={(e) => setCount(e)}
        getValue={(v) => getDataListFromFilter(v)}
      />
      <CardNew topic={t('รายการการปิดตรวจ')} icon="List" toggledrop={'false'}>
        <Row gutter={[12, 12]}>
          {/* <Col {...Responsive}>
            <Space>
              <ButtonNew
                type="plusTableBorderPrimary"
                roles={{ type: 'add', menu: menu }}
                onClick={() => {
                  navigate(`/auditoperations/auditcloses/auditclosesdetail`, {
                    state: {
                      refCode: {},
                      type_action: 'add',
                    },
                  })
                }}
              >
                {t('บันทึกปิดตรวจ')}
              </ButtonNew>
            </Space>
          </Col> */}
          <Col {...Responsive}>
            <Datatable
              columns={Columns}
              data={dataLists}
              paginationCustom={true}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
              total={count}
              enableScroll={{ x: true }}
              pageSize={limit}
              page={page}
              showSizeChanger={true}
              handleTableChange={(e) => {
                const reNewField = [
                  { riskFormName: displayText('riskFormTH', 'riskFormEN') },
                  { Year: 'Year' },
                  { riskFormCode: 'riskFormCode' }
                ]
                setSort(handleSort(e?.sorter, reNewField))
              }}
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default AuditClosesExitList
