import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import moment from 'moment'
import { Row, Col, Form } from 'antd'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonDowloadsExcel } from '../../../components/button/ButtonDowloadsExcel'
import { ButtonNew as Button } from '../../../components/button/Button'
import { UploadExcel } from '../../../components/upload/UploadExcel'
import { LabelNew as Label } from '../../../components/label/Label'
import { Datatable } from '../../../components/datatable/Datatable'
import { CardNew } from '../../../components/card/Card'
import { Select } from '../../../components/select/Select'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { RowStyle, WrapperLabel } from './css/ImportHistoricalDataStyle'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { useLanguage } from '../../../hooks/useLanguage'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError } from '../../../utilitys/helper'
import { IMPORT_HISTORICAL_DATA } from './graphql/Mutation'
import { columnPlanAndProject, columnReportExamine, columnAuditCommitteeSummary } from './utils/columns'
import {
  importHistoricalDataInfoCrumb,
  initalFieldPlanAndProject,
  initialAuditCommitteeSummary,
  initialFieldReportExamine,
} from './utils/constants'

const SelectImportDataType = (props) => {
  const { formname, onChange, ...otherProp } = props
  const { t } = useTranslation()

  const dataType = [
    { label: t('แผนและโครงการ'), value: 'Plan_And_Project' },
    { label: t('สรุปประเด็นคณะกรรมการตรวจสอบ'), value: 'Audit_Committee_Summary' },
    { label: t('จัดทำรายงานตรวจสอบ'), value: 'Report_Examine' },
  ]

  return (
    <div style={{ width: '355px' }}>
      <Select
        {...otherProp}
        data={dataType}
        placeholder={t('เลือกประเภทข้อมูลนำเข้า')}
        scrollableId={formname}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(e) => onChange(e)}
      />
    </div>
  )
}

const ImportHistoricalData = (props) => {
  const formName = 'import_data_form'
  const Responsive = { md: 24, lg: 24 }
  const { menu } = props
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [isLang] = useLanguage()

  const [dataExcel, setDataExcel] = useState([])
  const [rowError, setRowError] = useState([])
  const [dataList, setdataList] = useState([])
  const [isFileName, setIsFileName] = useState(null)
  const [dataType, setDataType] = useState(null)
  const [disableImportForm, setDisableImportForm] = useState(true)

  const [importHistoricalDataFn, importHistoricalData] = useMutation(IMPORT_HISTORICAL_DATA)

  const checkFormatDate = (d) => {
    var date = moment(d, 'YYYY-MM-DD', true)
    return date.isValid()
  }

  const validateDataTable = (dt, idx) => {
    const setDataError = []
    let initField = null

    if (dataType === 'Plan_And_Project') initField = initalFieldPlanAndProject
    if (dataType === 'Audit_Committee_Summary') initField = initialAuditCommitteeSummary
    if (dataType === 'Report_Examine') initField = initialFieldReportExamine

    for (const [key, value] of Object.entries(dt)) {
      if (key.includes('dateStart') || key.includes('dateEnd')) {
        if (!checkFormatDate(value)) setDataError.push({ numRow: idx + 1, messageError: `กรอกข้อมูลวันที่ไม่ถูกต้อง` })
      }
      if (!value) {
        setDataError.push({ numRow: idx + 1, messageError: `ไม่พบข้อมูล${initField ? initField[key] : ''}` })
      }
    }

    return setDataError
  }

  const conditionDataType = (dt, idx) => {
    let data = null

    if (dataType === 'Plan_And_Project') {
      data = {
        key: idx,
        auditPlans_planCode: dt.auditPlans_planCode,
        auditPlans_planNameTH: dt.auditPlans_planNameTH,
        auditPlans_planType: dt.auditPlans_planType,
        auditPlans_planYear: isLang === 'th' ? parseInt(dt.auditPlans_planYear) + 543 : dt.auditPlans_planYear,
        auditPlans_dateStart: dateDisplay(dt.auditPlans_dateStart),
        auditPlans_dateEnd: dateDisplay(dt.auditPlans_dateEnd),
        auditProjects_projectCode: dt.auditProjects_projectCode,
        auditProjects_projectNameTH: dt.auditProjects_projectNameTH,
        auditProjects_projectTypeCode: dt.auditProjects_projectTypeCode,
        auditProjects_projectRefCode: dt.auditProjects_projectRefCode,
        auditProjects_auditTypeCode: dt.auditProjects_auditTypeCode,
        auditProjects_approvePersonnelCode: dt.auditProjects_approvePersonnelCode,
        auditProjectOrganizations_organizationCode: dt.auditProjectOrganizations_organizationCode,
        auditProjects_organizationCode: dt.auditProjects_organizationCode,
        auditProjects_auditTeamCode: dt.auditProjects_auditTeamCode,
        auditProjects_personnelCode: dt.auditProjects_personnelCode,
        auditProjects_projectSizeCode: dt.auditProjects_projectSizeCode,
        auditProjects_manday: dt.auditProjects_manday,
        auditProjects_numberOfPeople: dt.auditProjects_numberOfPeople,
        auditProjects_mandayPersonnal: dt.auditProjects_mandayPersonnal,
        auditProjects_dateStart: dateDisplay(dt.auditProjects_dateStart),
        auditProjects_dateEnd: dateDisplay(dt.auditProjects_dateEnd),
      }
    }
    if (dataType === 'Audit_Committee_Summary') {
      data = {
        key: idx,
        auditResolution_resDate: dt.auditResolution_resDate,
        auditResolution_resTimes: dt.auditResolution_resTimes,
        auditResolutionAC_projectCode: dt.auditResolutionAC_projectCode,
        auditResolutionAC_agendaNameTH: dt.auditResolutionAC_agendaNameTH,
        auditResolutionAC_considerCode: dt.auditResolutionAC_considerCode,
        auditResolutionAC_organizationCode: dt.auditResolutionAC_organizationCode,
        auditResolutionOrganizations_organizationCode: dt.auditResolutionOrganizations_organizationCode,
        auditIssues_rateFacProCode: dt.auditIssues_rateFacProCode,
        auditIssues_rateRiskRuleCode: dt.auditIssues_rateRiskRuleCode,
        auditIssues_rateRiskConCode: dt.auditIssues_rateRiskConCode,
        auditIssues_factTH: dt.auditIssues_factTH,
        auditIssues_issueGroupType: dt.auditIssues_issueGroupType,
        auditIssues_issueTH: dt.auditIssues_issueTH,
        auditIssues_findingTypeCode: dt.auditIssues_findingTypeCode,
        auditIssues_ISOCode: dt.auditIssues_ISOCode,
        auditIssues_ISOSubCode: dt.auditIssues_ISOSubCode,
        auditIssues_riskLevelCode: dt.auditIssues_riskLevelCode,
        auditIssues_riskTH: dt.auditIssues_riskTH,
        auditIssues_effectTH: dt.auditIssues_effectTH,
        auditIssuePersonnels_personnelCode: dt.auditIssuePersonnels_personnelCode,
        auditCauseOfIssues_causeCode: dt.auditCauseOfIssues_causeCode,
        auditCauseOfIssues_causeDetailTH: dt.auditCauseOfIssues_causeDetailTH,
        auditSuggestOfIssues_suggestGroupType: dt.auditSuggestOfIssues_suggestGroupType,
        auditSuggestOfIssues_suggestTH: dt.auditSuggestOfIssues_suggestTH,
        auditSuggestOfIssues_dateExpected: dt.auditSuggestOfIssues_dateExpected,
        auditSuggestOfIssues_personnelCode: dt.auditSuggestOfIssues_personnelCode,
        auditSuggestOrganizations_organizationCode: dt.auditSuggestOrganizations_organizationCode,
        followUp_mdNumber: dt.followUp_mdNumber,
        followUp_dateSendReport: dateDisplay(dt.followUp_dateSendReport),
        followUpActivity_activityTH: dt.followUpActivity_activityTH,
        followUpActivity_expectDate: dateDisplay(dt.followUpActivity_expectDate),
        followUpActivity_organizationCode: dt.followUpActivity_organizationCode,
        followUpActivity_personnelCode: dt.followUpActivity_personnelCode,
        followUpActions_actionDetailTH: dt.followUpActions_actionDetailTH,
        followUpActions_actionDate: dateDisplay(dt.followUpActions_actionDate),
        followUpActions_planDetailTH: dt.followUpActions_planDetailTH,
        followUpActions_organizationCode: dt.followUpActions_organizationCode,
        followUpActions_personnelCode: dt.followUpActions_personnelCode,
        followUpActions_ideaStatusCode: dt.followUpActions_ideaStatusCode,
        followUpActions_idePersonnelCode: dt.followUpActions_idePersonnelCode,
        followUpActions_actionStatusCode: dt.followUpActions_actionStatusCode,
        auditIssues_issueStatusCode: dt.auditIssues_issueStatusCode,
      }
    }
    if (dataType === 'Report_Examine') {
      data = {
        key: idx,
        auditFinalReport_projectCode: dt.auditFinalReport_projectCode,
        auditFinalReport_documentNo: dt.auditFinalReport_documentNo,
        auditFinalReport_startDateReport: dt.auditFinalReport_startDateReport,
        auditFinalReport_endDateReport: dt.auditFinalReport_endDateReport,
        auditFinalReportDetail_issue: dt.auditFinalReportDetail_issue,
        auditFinalReportDetail_rules: dt.auditFinalReportDetail_rules,
        auditFinalReportDetail_fact: dt.auditFinalReportDetail_fact,
        auditFinalReportDetail_cause: dt.auditFinalReportDetail_cause,
        auditFinalReportDetail_risk: dt.auditFinalReportDetail_risk,
        auditFinalReportDetail_control: dt.auditFinalReportDetail_control,
        auditFinalReportDetail_summaryTest: dt.auditFinalReportDetail_summaryTest,
        auditFinalReportDetail_feedback: dt.auditFinalReportDetail_feedback,
        auditFinalReportDetail_conlusionActionExecutive: dt.auditFinalReportDetail_conlusionActionExecutive,
        auditFinalReportDetail_resultAssessmentRemark: dt.auditFinalReportDetail_resultAssessmentRemark,
      }
    }
    return data
  }

  const conditionColumn = () => {
    switch (dataType) {
      case 'Plan_And_Project':
        return columnPlanAndProject()
      case 'Audit_Committee_Summary':
        return columnAuditCommitteeSummary()
      case 'Report_Examine':
        return columnReportExamine()
      default:
        return []
    }
  }

  useEffect(() => {
    setDisableImportForm(isFileName ? false : true)
  }, [isFileName])

  useEffect(() => {
    if (dataExcel.length) {
      const setDataError = []
      const getDataExcel = _.map(dataExcel, (dt, idx) => {
        /* validate data many row in table */
        setDataError.push(validateDataTable(dt, idx))
        /* set data into table */
        return conditionDataType(dt, idx)
      })
      setRowError(_.flattenDeep(setDataError))
      setdataList(getDataExcel)
    }
  }, [dataExcel])

  useEffect(() => {
    if (importHistoricalData.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          window.location.reload()
        },
      })
    }
  }, [importHistoricalData.data])

  useEffect(() => {
    if (importHistoricalData.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: importHistoricalData?.error?.message.includes('VALIDATE') ? (
          <ul style={{ textAlign: 'left' }}>
            {_.chain(importHistoricalData?.error?.message.slice(0, -1))
              .replace('VALIDATE', '')
              .split(',')
              .uniq()
              .map((dt, idx) => <li key={idx}>{dt}</li>)
              .value()}
          </ul>
        ) : (
          t(displayError(importHistoricalData.error))
        ),
        onOk() {},
      })
    }
  }, [importHistoricalData.error])

  const clearImportData = () => {
    form.resetFields()
    setIsFileName(null)
    setdataList([])
    setRowError([])
    setDataType(null)
    setDataExcel([])
  }

  const onFinish = () => {
    if (dataExcel.length) {
      Alert({
        type: 'confirm',
        title: 'ต้องการยืนยันการบันทึก',
        onOk() {
          importHistoricalDataFn({
            fetchPolicy: 'no-cache',
            variables: { input: encryptInput({ data: dataExcel, dataType, lang: isLang }) },
          })
        },
        onCancel() {},
      })
    } else {
      Alert({
        type: 'warning',
        title: t('กรุณาอัฟโหลดไฟล์นำเข้าข้อมูล'),
        onOk() {},
      })
    }
  }

  return (
    <>
      {importHistoricalData?.loading && <SpinnersNew />}
      <Breadcrumb data={importHistoricalDataInfoCrumb} title={t('นำเข้าข้อมูลผลการตรวจย้อนหลัง')} />
      <CardNew topic={t('นำเข้าข้อมูลผลการตรวจย้อนหลัง')} icon="Plus" toggledrop={'false'}>
        <Form form={form} name={formName} className="site-layout-background">
          <Row gutter={[24, 24]} style={{ marginTop: 24, marginBottom: 24 }} align="center">
            <Col md={6} align="left">
              <Label type="tab-header-inactive">{t('ประเภทข้อมูลนำเข้า')}</Label>
            </Col>
            <Col md={14} align="left">
              <Form.Item name="dataType">
                <SelectImportDataType
                  formname={formName}
                  onChange={(e) => {
                    setIsFileName(e ? `${e}.xlsx` : null)
                    setDataType(e)
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={6} align="left">
              <Label type="tab-header-inactive">{t('แบบฟอร์มนำเข้า')}</Label>
            </Col>
            <Col md={14} align="left">
              <ButtonDowloadsExcel file={{ filePath: '', fileName: isFileName }} disabled={disableImportForm}>
                {t('ดาวน์โหลด Template .XLSX')}
              </ButtonDowloadsExcel>
            </Col>
            <Col md={6} align="left">
              <Label type="tab-header-inactive">{t('ไฟล์นำเข้า')}</Label>
            </Col>
            <Col md={14} align="left">
              <UploadExcel
                dataType={dataType}
                importHistoricalData={true}
                disabled={disableImportForm}
                dataFiles={(e) => setDataExcel(e.data)}
              />
              {disableImportForm && (
                <WrapperLabel>
                  <Label type="header-table" need="need">
                    {t('กรุณาเลือกประเภทข้อมูลนำเข้า')}
                  </Label>
                </WrapperLabel>
              )}
            </Col>
          </Row>
        </Form>
        <Row className="py-1 justify-content-md-center">
          <Col md="2" className="m-auto" />
          <Col md="8">
            <ul className="pl-2">
              <li className="pb-1">
                <Label type="header-table" need="need">
                  {t('ไฟล์ที่นำเข้าจะต้องมีรูปแบบตามตัวอย่างไฟล์ที่กำหนดไว้เท่านั้น')}
                </Label>
              </li>
              <li className="pb-1">
                <Label type="header-table" need="need">
                  {t('ไฟล์ที่นำเข้าจะต้องเป็น .xlsx จาก Templete')}
                </Label>
              </li>
            </ul>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginTop: 24, marginBottom: 24 }} align="center">
          <Col>
            <Button type="primary" width={141} roles={{ type: 'add', menu: menu }} onClick={() => onFinish()}>
              {t('นำเข้าข้อมูล')}
            </Button>
          </Col>
          <Col>
            <Button type="back" onClick={() => clearImportData()}>
              {t('ล้างข้อมูล')}
            </Button>
          </Col>
        </Row>
        <Row style={{ display: dataExcel.length > 0 ? '' : 'none' }} gutter={[24, 24]}>
          <Col {...Responsive}>
            <Row style={{ marginTop: 45, marginBottom: 10 }}>
              <Col>
                <Label type="sub-header">{t('ผลประมวณผลไฟล์นำเข้า')}</Label>
              </Col>
            </Row>
            {rowError.length > 0 && (
              <Row style={{ marginTop: 10, marginBottom: 10 }}>
                <Col>
                  {rowError.map((numRow, i) => (
                    <Label key={'error-messageError' + i} type="error">
                      {t('แถวที่') + ' ' + numRow.numRow + ' ' + t(numRow.messageError)}
                    </Label>
                  ))}
                </Col>
              </Row>
            )}
            <Row style={{ marginTop: 45, marginBottom: 10 }}>
              <Col>
                <RowStyle>
                  <Datatable
                    columns={conditionColumn()}
                    data={dataList}
                    isScoll={{ x: true, y: true }}
                    rowClassName={() => {}}
                  />
                </RowStyle>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

ImportHistoricalData.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default ImportHistoricalData
