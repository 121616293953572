import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input } from 'antd'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { ModalNew } from '../../../../../components/modal/Modal'
import { InputNew } from '../../../../../components/input/Input'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'
import { encryptInput, decryptData } from '../../../../../utilitys/crypto'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { Alert } from '../../../../../components/alert/Alert'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { convertEmailJson, displayError } from '../../../../../utilitys/helper'
import SelectTagEmailPersonnels from '../../../../../components/inputfromapi/selecttagemailpersonnels/SelectTagEmailPersonnels'
import {
  GETEMAILORGANIZATIONBYFORMASSESS,
  GETEMAILORGANIZATIONPROJECTBYFORMASSESS,
  GETEMAILPERSONNELBYFORMASSESS,
} from '../../graphql/Query'
import { SENDMAILFORMASSESS } from '../../graphql/Mutation'

const formItemLayout = {
  labelCol: { sm: 14, md: 6, lg: 6, xl: 6 },
  wrapperCol: { sm: 16, md: 18, lg: 18, xl: 18, align: 'left' },
  Col: {
    sm: 24,
    md: 16,
    lg: 18,
  },
}
const SendMailModal = (props) => {
  const { open, close, onSuccess, dataSendMail, menu } = props
  const [userData] = useDecodeUser()

  const [getEmailOrganizationByFormAssessfn, getEmailOrganizationByFormAssess] = useLazyQuery(
    GETEMAILORGANIZATIONBYFORMASSESS,
  )
  const [getEmailOrganizationProjectByFormAssessfn, getEmailOrganizationProjectByFormAssess] = useLazyQuery(
    GETEMAILORGANIZATIONPROJECTBYFORMASSESS,
  )
  const [getEmailPersonnelByFormAssessfn, getEmailPersonnelByFormAssess] = useLazyQuery(GETEMAILPERSONNELBYFORMASSESS)
  const [sendMailFormAssessFn, { loading: loadingSendMail, error, data: resDataSendmail }] =
    useMutation(SENDMAILFORMASSESS)

  const [organizationByForm, setOrganizationByForm] = useState([])
  const [organizationProject, setOrganizationProject] = useState([])
  const [personnelByFormAssess, setPersonnelByFormAssess] = useState([])
  const [sendMailToOptions, setSendMailToOptions] = useState([])
  const [sendMailCopyToOptions, setSendMailCopyToOptions] = useState([])
  // const [getEmailUser, setEmailUser] = useState([])

  // const { email, userId, pCode } = decryptData(localStorage.getItem('user'))
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const Item = Form.Item
  const formName = 'sendmail'

  function onClose(e) {
    close(e)
    form.resetFields()
  }

  const onSubmit = () => {
    const e = form.getFieldValue()
    sendMailFormAssessFn({
      variables: {
        input: encryptInput({
          data: e.data ? e.data : '',
          dear: e.dear ? e.dear : '',
          sendMailCopy: e.sendMailCopy ? convertEmailJson(e.sendMailCopy) : '',
          sendMailSecret: e.sendMailSecret ? convertEmailJson(e.sendMailSecret) : '',
          sendMailTo: e.sendMailTo ? convertEmailJson(e.sendMailTo) : '',
          subject: e.subject ? e.subject : '',
          details: e.details ? e.details : '',
          sendBy: userData.pCode,
          menuCode: menu,
        }),
      },
    })
  }

  useEffect(() => {
    if (open === true) {
      getEmailOrganizationByFormAssessfn({
        variables: encryptInput({
          formAssessId: dataSendMail.formAssessId,
        }),
      })
      getEmailOrganizationProjectByFormAssessfn({
        variables: encryptInput({
          formAssessId: dataSendMail.formAssessId,
        }),
      })

      if (dataSendMail.formTypeCode === '30') {
        getEmailPersonnelByFormAssessfn({
          variables: encryptInput({
            formAssessId: dataSendMail.formAssessId,
          }),
        })
      }

      form.setFieldsValue({
        data: dataSendMail,
      })
      // setEmailUser([
      //   {
      //     label: userData.email,
      //     value: JSON.stringify({ personnelCode: userData.pCode, email: userData.email }),
      //   },
      // ])
    }
  }, [open])

  useEffect(() => {
    if (getEmailOrganizationByFormAssess?.data) {
      const res = getEmailOrganizationByFormAssess?.data
      const dataAdjust = _.map(res.getEmailOrganizationByFormAssess, (dt) => {
        return {
          label: dt.email,
          value: JSON.stringify({ personnelCode: dt.personnelCode, email: dt.email }),
        }
      })
      setOrganizationByForm(dataAdjust)
    }
    if (getEmailOrganizationByFormAssess.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getEmailOrganizationByFormAssess.error)),
        onOk() {},
      })
    }
  }, [getEmailOrganizationByFormAssess?.data])

  useEffect(() => {
    if (getEmailOrganizationProjectByFormAssess?.data) {
      const res = getEmailOrganizationProjectByFormAssess?.data
      const dataAdjust = _.map(res.getEmailOrganizationProjectByFormAssess, (dt) => {
        return {
          label: dt.email,
          value: JSON.stringify({ personnelCode: dt.personnelCode, email: dt.email }),
        }
      })
      setOrganizationProject(dataAdjust)
    }
    if (getEmailOrganizationProjectByFormAssess.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getEmailOrganizationProjectByFormAssess.error)),
        onOk() {},
      })
    }
  }, [getEmailOrganizationProjectByFormAssess?.data])

  useEffect(() => {
    if (getEmailPersonnelByFormAssess?.data) {
      const res = getEmailPersonnelByFormAssess?.data
      const dataAdjust = _.map(res.getEmailPersonnelByFormAssess, (dt) => {
        return {
          label: dt.email,
          value: JSON.stringify({ personnelCode: dt.personnelCode, email: dt.email }),
        }
      })
      setPersonnelByFormAssess(dataAdjust)
    }
    if (getEmailPersonnelByFormAssess.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getEmailPersonnelByFormAssess.error)),
        onOk() {},
      })
    }
  }, [getEmailPersonnelByFormAssess?.data])

  useEffect(() => {
    if (dataSendMail.formTypeCode === '10') {
      /* ความพึงพอใจ */
      form.setFieldsValue({
        sendMailTo: organizationByForm.map((dt) => {
          return dt.value
        }),
        sendMailCopy: organizationProject.map((dt) => {
          return dt.value
        }),
      })
      setSendMailToOptions(organizationByForm)
      setSendMailCopyToOptions(organizationProject)
    } else if (dataSendMail.formTypeCode === '20') {
      /* ความร่วมมือ */
      // sendMailTo ดึง ฟิลด์ personnelCode จาก table auditProject
      form.setFieldsValue({
        sendMailTo: [
          JSON.stringify({
            personnelCode: dataSendMail.auditProjectPersonnelCode,
            email: decryptData(dataSendMail.email),
          }),
        ],
        // sendMailCopy: organizationProject.map((dt) => {
        //   console.log(dt, dataSendMail.auditProjectPersonnelCode)
        //   return dt.value
        // }),
        sendMailCopy: organizationProject
          .map((dt) => {
            if (
              dt.value !==
              JSON.stringify({
                personnelCode: dataSendMail.auditProjectPersonnelCode,
                email: decryptData(dataSendMail.email),
              })
            ) {
              return dt.value
            }
          })
          .filter(Boolean),
      })
      setSendMailToOptions(organizationByForm)
      setSendMailCopyToOptions(organizationProject)
    } else if (dataSendMail.formTypeCode === '30') {
      /* ปฏิบัติงาน */
      form.setFieldsValue({
        sendMailTo: personnelByFormAssess.map((dt) => {
          return dt.value
        }),
        // sendMailCopy:
        //   userData &&
        //   getEmailUser.map((dt) => {
        //     return dt.value
        //   }),
        sendMailCopy: organizationProject.map((dt) => {
          return dt.value
        }),
      })
      setSendMailToOptions(personnelByFormAssess)
      // setSendMailCopyToOptions(getEmailUser)
      setSendMailCopyToOptions(organizationProject)
    }
  }, [organizationByForm, organizationProject, personnelByFormAssess])

  useEffect(() => {
    if (resDataSendmail) {
      Alert({
        type: 'success',
        title: t('ส่งสำเร็จ'),
        onOk() {
          form.resetFields()
          onSuccess(false)
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถส่งข้อมูลได้'),
        content: t(error.message),
        onOk() {},
      })
    }
  }, [resDataSendmail])

  return (
    <>
      {(getEmailOrganizationByFormAssess.loading ||
        getEmailOrganizationProjectByFormAssess.loading ||
        getEmailPersonnelByFormAssess.loading ||
        loadingSendMail) && <SpinnersNew />}
      <ModalNew
        visible={open}
        onSubmit={() => {
          form
            .validateFields()
            .then((v) => onSubmit(v))
            .catch((err) => console.log(err))
        }}
        onClose={(e) => onClose(e)}
        btnName={t('ส่ง')}
        testTitle={t('ข้อมูลการแจ้งเตือนอีเมล')}
        type="medium"
      >
        <Row justify="center">
          <Col {...formItemLayout.Col}>
            <Form
              name={formName}
              layout="horizontal"
              form={form}
              labelAlign="left"
              labelCol={{ ...formItemLayout.labelCol }}
              wrapperCol={{ ...formItemLayout.wrapperCol }}
            >
              <Item name={'dear'} label={t('เรียน')} rules={[{ required: true, message: t('กรุณาระบุ') }]}>
                <InputNew placeholder={t('กรุณาระบุ')} />
              </Item>
              <Item name={'subject'} label={t('เรื่อง')} rules={[{ required: true, message: t('กรุณาระบุเรื่อง') }]}>
                <InputNew placeholder={t('กรุณาระบุเรื่อง')} />
              </Item>
              <Item
                name={'sendMailTo'}
                label={t('ถึง')}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระบุอีเมล'),
                  },
                ]}
              >
                <SelectTagEmailPersonnels
                  data={sendMailToOptions}
                  placeholder={t('ระบุถึง')}
                  formname={formName}
                  handleChange={(e) => {
                    form.setFieldsValue({ sendMailTo: e })
                  }}
                />
              </Item>
              <Item name={'sendMailCopy'} label={t('สำเนาถึง')}>
                <SelectTagEmailPersonnels
                  data={sendMailCopyToOptions}
                  placeholder={t('ระบุถึง')}
                  formname={formName}
                  handleChange={(e) => {
                    form.setFieldsValue({ sendMailCopy: e })
                  }}
                />
              </Item>
              <Item name={'sendMailSecret'} label={t('สำเนาลับ')}>
                <SelectTagEmailPersonnels
                  placeholder={t('ระบุถึง')}
                  formname={formName}
                  handleChange={(e) => {
                    form.setFieldsValue({ sendMailSecret: e })
                  }}
                />
              </Item>
              <Item
                name={'details'}
                label={t('รายละเอียด')}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระบุรายละเอียด'),
                  },
                ]}
              >
                <TextAreaNew maxLength={300} rows={5} />
              </Item>
              <Item hidden={true} name={'data'}>
                <Input />
              </Item>
            </Form>
          </Col>
        </Row>
      </ModalNew>
    </>
  )
}

export default SendMailModal
