import { gql } from '@apollo/client'

export const RISK_FORMULAR_SAVE = gql`
  mutation riskFormulaSave($input: CreateRiskFormulaInput!) {
    riskFormulaSave(input: $input)
  }
`

export const CREATE_RISK_FORMULAR_VARIABLE = gql`
  mutation createRiskFormulaVariable($input: CreateRiskFormulaVariableInput!) {
    createRiskFormulaVariable(input: $input)
  }
`

export const UPDATE_RISK_FORMULAR_VARIABLE = gql`
  mutation editRiskFormulaVariable($input: CreateRiskFormulaVariableInput!) {
    editRiskFormulaVariable(input: $input)
  }
`

export const CALCULATE_AND_SCORE_SAVE = gql`
  mutation calculateAndScoreSave($input: CreateCalculateAndScoreInput!) {
    calculateAndScoreSave(input: $input)
  }
`

export const DELETE_RISK_FORMULAR = gql`
  mutation deleteRiskFormula($riskFormulaCode: String!) {
    deleteRiskFormula(riskFormulaCode: $riskFormulaCode)
  }
`

export const DELETE_RISK_FORMULAR_VARIABLE = gql`
  mutation deleteRiskFormulaVariables($variableCode: String!) {
    deleteRiskFormulaVariables(variableCode: $variableCode)
  }
`
