import { gql } from '@apollo/client'

export const GET_MASTER_EDUCATION_PROGRAMS = gql`
  query getMasterEducationPrograms($input: MasterEducationProgramsInput!) {
    getMasterEducationPrograms(data: $input) {
      educationProgramId
      educationProgramCode
      educationProgramTH
      educationProgramEN
      isActive
    }
  }
`
