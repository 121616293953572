import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'key',
    width: '8%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ชื่อหน่วยงานภาษาไทย</Trans>,
    dataIndex: 'organizationNameTH',
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ชื่อหน่วยงานภาษาอังกฤษ</Trans>,
    dataIndex: 'organizationNameEN',
    width: '20%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>ประเภท</Trans>,
    dataIndex: 'changeType',
    width: '10%',
  },
  {
    title: <Trans>วันที่เพิ่ม/แก้ไข</Trans>,
    dataIndex: 'createdAt',
    width: '20%',
  },
  {
    title: <Trans>รายละเอียด</Trans>,
    dataIndex: 'tools',
    width: '10%',
    className: 'text-nowrap',
  },
]
