import { gql } from '@apollo/client'

export const GET_MASTER_REQUEST_STATUS = gql`
  query getMasterRequestStatus($input: MasterRequestStatusInput!) {
    getMasterRequestStatus(input: $input) {
      requestStatusId
      requestStatusCode
      requestStatusNameTH
      requestStatusNameEN
      isActive
    }
  }
`
