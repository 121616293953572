import { gql } from '@apollo/client'

export const SAVE_CONTROLTYPE = gql`
  mutation masterControlTypeSave($input: ControlTypeInput!) {
    masterControlTypeSave(input: $input)
  }
`

export const DELETE_CONTROLTYPE_BY_CODE = gql`
  mutation masterControlTypeDelete($controlTypeCode: String!) {
    masterControlTypeDelete(controlTypeCode: $controlTypeCode)
  }
`
