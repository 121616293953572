import React from 'react'
import { Tooltip, Button } from 'antd'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { Row, Col, Card } from 'antd'
import { code, colors, strColor } from './text'
const TooltipIndex = () => {
  const text = <span>prompt text</span>
  return (
    <React.Fragment>
      <Row style={{ margin: 28 }}>
        <Col span={24}>
          <Card title="ระบบเราใช้ tooltip ตัวนี้" extra={<a href="#">More</a>} style={{ width: '100%' }}>
            {/* <Tooltip placement="topLeft" title={text}>
              <Button>TL</Button>
            </Tooltip>
            <Tooltip placement="top" title={text}>
              <Button>Top</Button>
            </Tooltip>
            <Tooltip placement="topRight" title={text}>
              <Button>TR</Button>
            </Tooltip>
            <Tooltip placement="leftTop" title={text}>
              <Button>LT</Button>
            </Tooltip>
            <Tooltip placement="left" title={text}>
              <Button>Left</Button>
            </Tooltip>
            <Tooltip placement="leftBottom" title={text}>
              <Button>LB</Button>
            </Tooltip>
            <Tooltip placement="rightTop" title={text}>
              <Button>RT</Button>
            </Tooltip>
            <Tooltip placement="right" title={text}>
              <Button>Right</Button>
            </Tooltip>
            <Tooltip placement="rightBottom" title={text}>
              <Button>RB</Button>
            </Tooltip>
            <Tooltip placement="bottomLeft" title={text}>
              <Button>BL</Button>
            </Tooltip> */}
            <Tooltip placement="bottom" title={text}>
              <Button>Bottom</Button>
            </Tooltip>
            {/* <Tooltip placement="bottomRight" title={text}>
              <Button>BR</Button>
            </Tooltip> */}
            <br />
            <span style={{ marginTop: 24 }}>&nbsp;</span>
            <CodeBlock code={code} />
          </Card>
        </Col>
      </Row>
      <Row style={{ margin: 28 }}>
        <Col span={24}>
          <Card title="tooltip แบบมีสี" extra={<a href="#">More</a>} style={{ width: '100%' }}>
            {colors.map((color) => (
              <Tooltip title="prompt text" color={color} key={color}>
                <Button>{color}</Button>
              </Tooltip>
            ))}
            <br />
            <span style={{ marginTop: 24 }}>&nbsp;</span>
            <CodeBlock code={strColor} />
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default TooltipIndex
