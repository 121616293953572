import React from 'react'
import { Progress } from 'antd'
import { ProgressStyles } from './ProgressStyles'
import { colors } from '../../configs/styles.config'

export const ProgressNew = (props) => {
  const { typecolor } = props
  let color = ''
  switch (typecolor) {
    case 'primary':
      color = colors.primary
      break
    case 'danger':
      color = colors.error
      break
    case 'success':
      color = colors.success
      break

    default:
      color = colors.primary
      break
  }

  return (
    <ProgressStyles color={color}>
      <Progress
        percent={props.percent}
        className="progress-color-inline"
        status={props.status}
        showInfo={props.showInfo}
        {...props}
      />
    </ProgressStyles>
  )
}
