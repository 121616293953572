import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import moment from 'moment'
import { ModalNew } from '../../../../components/modal/Modal'
import { useMutation, useLazyQuery } from '@apollo/client'
import { CREATE_REWARD_PERSONNELS } from '../graphql/Mutation'
import { GET_ONE_REWARD } from '../graphql/Query'
import { TextAreaNew } from '../../../../components/textarea/TextArea.jsx'
import { InputNew } from '../../../../components/input/Input.jsx'
import { UploadNew } from '../../../../components/upload/Upload.jsx'
import { Datepicker } from '../../../../components/datepicker/Datepicker.jsx'
import { LabelNew } from '../../../../components/label/Label.jsx'
import { Alert } from '../../../../components/alert/Alert'
import { useTranslation } from 'react-i18next'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { regexEng, regexThai } from '../../../../utilitys/helper'
import { displayError } from '../../../../utilitys/helper'

const Responesive = {
  sm: 24,
  md: 24,
  lg: 24,
}
const RewardModal = (props) => {
  const { t } = useTranslation()
  const { formType, codeEdit, open, colse } = props
  const [form] = Form.useForm()
  const [file, setFile] = useState([])
  const [limitSize, setLimitSize] = useState()
  const [idFileDelete, setIdFileDelete] = useState([])
  const [idFileDefault, setIdFileDefault] = useState([])
  const [visible, setVisible] = useState(false)
  const [getRewardFn, { data: getData, loading: loadingData, error: errorData }] = useLazyQuery(GET_ONE_REWARD)

  useEffect(() => {
    setIdFileDefault([])
    if (visible && formType === 'edit') {
      getRewardFn({
        variables: {
          input: {
            personnelRewardId: encryptInput(codeEdit),
          },
        },
      })
    }
  }, [visible])

  useEffect(() => {
    if (open) {
      setVisible(open)
    } else {
      setVisible(false)
    }
  }, [open])

  function onClose(e) {
    colse(e)
  }

  const [createPersonnelReward, { loading: loadingPersonnelReward, error: errorReward }] =
    useMutation(CREATE_REWARD_PERSONNELS)
  const fnConfirm = (values) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        onSubmit(values)
      },
      onCancel() {
        onClose(false)
      },
    })
  }
  const onSubmit = async (values) => {
    let dataInput = {
      personnelRewardId: props.codeEdit,
      personnelCode: props.refCode,
      rewardDate: values.rewardDate,
      reward: values.reward,
      rewardNameTH: values.rewardNameTH,
      rewardNameEN: values.rewardNameEN || '',
      remark: values.remark || '',
      attachFile: file.join(),
      idFileDelete: idFileDelete.join(),
    }
    if (props.formType === 'add') {
      dataInput['action'] = 'add'
    } else if (props.formType === 'edit') {
      dataInput['action'] = 'edit'
    }
    await createPersonnelReward({
      variables: {
        input: encryptInput(dataInput),
      },
    }).then(() => {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          onClose(false)
          props.getPersonnelReward.refetch()
        },
      })
    })
  }

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: text,
      onOk() {},
    })
  }
  useEffect(() => {
    if (errorReward && errorReward.message) {
      showAlertError(t(displayError(errorReward)))
    }
    if (errorData && errorData.message) {
      showAlertError(t(displayError(errorData)))
    }
  }, [errorReward, errorData])

  useEffect(() => {
    // Get value From your API
    if (formType === 'edit' || formType === 'view') {
      // Set Form value on formType = UPDATE
      if (getData) {
        form.setFieldsValue({
          rewardDate: moment(getData.getReward.rewardDate).format('YYYY-MM-DD'),
          reward: getData.getReward.reward,
          rewardNameTH: getData.getReward.rewardNameTH,
          rewardNameEN: getData.getReward.rewardNameEN,
          remark: getData.getReward.remark,
        })
        setIdFileDelete([])
        setFile(() => {
          return getData.getReward.attachFile.split(',').map((item) => {
            return parseInt(item)
          })
        })

        setIdFileDefault(() => {
          return getData.getReward.attachFile.split(',').map((item) => {
            return parseInt(item)
          })
        })
      }
    } else if (formType === 'add') {
      // Set Form value on formType = CREATE
      setFile([])
      setIdFileDefault([])
      setIdFileDelete([])
      form.resetFields()
    }
  }, [getData, visible])
  // onFinish
  const onFinish = (values) => {
    fnConfirm(values)
  }
  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((er) => {
      const nameField = er.name[0] || ''
      const message = er.errors[0] || ''
      if (nameField === 'rewardDate') setErrorField('rewardDate', message)
    })
  }
  // onValuesChange
  const onValuesChange = () => {}

  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  return (
    <>
      {loadingPersonnelReward || loadingData ? <SpinnersNew /> : ''}
      <ModalNew
        visible={open}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        testTitle={
          formType !== 'view'
            ? formType === 'add'
              ? t('เพิ่มรางวัล ผลงาน')
              : t('แก้ไขรางวัล ผลงาน')
            : t('ดูรางวัล ผลงาน')
        }
        btnName={t('บันทึก')}
        okButtonProps={formType === 'view' ? { style: { display: 'none' } } : {}}
        type="medium"
      >
        <Row gutter={[16, 16]}>
          <Col {...Responesive}>
            <Row style={{ marginTop: 24 }}>
              <Col md={24} lg={24}>
                <Form
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onValuesChange={onValuesChange}
                  autoComplete="off"
                  labelAlign="left"
                  labelCol={{
                    sm: {
                      span: 10,
                      offset: 3,
                    },
                    md: {
                      span: 9,
                      offset: 3,
                    },
                    xl: {
                      span: 9,
                      offset: 3,
                    },
                  }}
                  wrapperCol={{
                    sm: 24,
                    md: 12,
                    xl: 12,
                    align: 'left',
                  }}
                  layout="horizontal"
                  name="rewardForm"
                >
                  <Row>
                    <Col md={18}>
                      <Form.Item
                        name="rewardDate"
                        label={t('วันที่สร้างผลงาน')}
                        rules={[{ required: true, message: t('กรุณาระบุวันที่สร้างผลงาน') }]}
                      >
                        <Datepicker
                          language="th"
                          disabled={formType === 'view' ? true : false}
                          setValueDateFn={(data) => {
                            form.setFieldsValue({
                              dateReward: data,
                            })
                            setErrorField('dateReward', false) // set false error field
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="reward"
                        label={t('ระดับรางวัล/ผลงาน')}
                        rules={[{ required: true, message: t('กรุณาระบุระดับรางวัล/ผลงาน') }]}
                      >
                        <InputNew placeholder={t('ระดับรางวัลผลงาน')} disabled={formType === 'view' ? true : false} />
                      </Form.Item>
                      <Form.Item
                        name="rewardNameTH"
                        label={t('ชื่อรางวัลหรือชื่อผลงาน(TH)')}
                        rules={[
                          { required: true, message: t('กรุณาระบุชื่อรางวัลหรือชื่อผลงาน') },
                          {
                            pattern: regexThai,
                            message: t(`กรุณากรอกด้วยภาษาไทยหรือตัวเลข ก-๙`),
                          },
                        ]}
                      >
                        <InputNew placeholder={t('ชื่อรางวัลภาษาไทย')} disabled={formType === 'view' ? true : false} />
                      </Form.Item>
                      <Form.Item
                        name="rewardNameEN"
                        label={t('ชื่อรางวัลหรือชื่อผลงาน(EN)')}
                        rules={[
                          {
                            pattern: regexEng,
                            message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z`),
                          },
                        ]}
                      >
                        <InputNew
                          placeholder={t('ชื่อรางวัลภาษาอังกฤษ')}
                          disabled={formType === 'view' ? true : false}
                        />
                      </Form.Item>
                      <Form.Item name="remark" label={t('รายละเอียดเพิ่มเติม')}>
                        <TextAreaNew
                          placeholder={t('รายละเอียดเพิ่มเติม')}
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          disabled={formType === 'view' ? true : false}
                        />
                      </Form.Item>
                      <Form.Item label={t('ไฟล์แนบ')}>
                        <UploadNew
                          type="mutiple"
                          setIdFile={setFile}
                          defaults={idFileDefault}
                          idFile={file}
                          typeFile="file"
                          actionType={formType === 'add' ? 'create' : formType}
                          idFileDelete={idFileDelete}
                          setIdFileDelete={setIdFileDelete}
                          setLimitSize={setLimitSize}
                          disabled={formType === 'view' ? true : false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col md={{ span: 23, offset: 1 }}>
                    <LabelNew type="error">
                      ไฟล์ต้องมีนามสกุล zip, pdf, png, jpg, jpeg, doc, docx, csv, xls, xlsx, ppt, pptx และ
                      ขนาดไฟล์ทั้งหมดรวมกันต้องไม่เกิน {limitSize} MB
                    </LabelNew>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalNew>
    </>
  )
}

export default RewardModal
