import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'
import { GET_CONTROL_FORM_BY_ID, GET_MASTER_CHOICE_TYPES } from './graphql/Query'
import { CREATE_CONFIG_QUEST, UPDATE_CONTROL_FORM, COPY_CONTROL_FORM } from './graphql/Mutation'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import ControlFormAllEdit from './components/form/ControlFormAllEdit'
import QuestionModal from './components/modal/QuestoinModal'
import ConfigurationModal from './components/modal/ConfigurationModal'
import CheckerOrganizationModal from '../../components/modalformapi/modalCheckerOrganization/CheckerOrganizationModal'
import { controlFormEditCrumb } from './utils/constants'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText, displayError } from '../../../utilitys/helper'

/**
 * @function ControlFormEdit
 * @description
 * Menu: Control Form
 * เมนู: แบบประเมินควบคุมภายใน
 * @returns Component
 */

const ControlFormEdit = (props) => {
  const formname = 'ControlFormEditForm'
  const labelCol = { sm: 8, md: 8, lg: 8, xl: 8 }
  const wrapperCol = { sm: 16, md: 16, lg: 24, xl: 16, align: 'left' }
  const [form] = Form.useForm()
  const { state } = useLocation()
  const { t } = useTranslation()
  const [user] = useDecodeUser()
  const navigate = useNavigate()

  const [modalForm, setModalForm] = useState(false)
  const [modalQuestion, setModalQuestion] = useState(false)
  const [modalConfiguration, setModalConfiguration] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [isQuestion, setIsQuestion] = useState([])
  const [isConfiguration, setIsConfiguration] = useState([])
  const [masterChoiceTypes, setMasterChoiceTypes] = useState(null)
  const [questDetailTypes, setQuestDetailTypes] = useState(null)
  const [isConfigItem, setIsConfigItem] = useState(null)
  const [isQuestionItem, setIsQuestionItem] = useState(null)
  const [accessModalConfAction, setAccessModalConfAction] = useState('')
  const [accessTools, setAccessTools] = useState(null)

  const [getMasterChoiceTypesFn, getMasterChoiceTypes] = useLazyQuery(GET_MASTER_CHOICE_TYPES)
  const [getControlFormFn, getControlForm] = useLazyQuery(GET_CONTROL_FORM_BY_ID)
  const [updateControlFormFn, updateControlForm] = useMutation(UPDATE_CONTROL_FORM)
  const [createConfQuestionFn, createConfQuestion] = useMutation(CREATE_CONFIG_QUEST)
  const [copyControlFormFn, copyControlForm] = useMutation(COPY_CONTROL_FORM)

  if (!state && state?.controlFormId) return <Navigate to={'/internalcontrol/controlform'} />

  useEffect(() => {
    if (state) getControlFormFn({ variables: { controlFormId: encryptInput(state?.controlFormId) } })
  }, [state])

  useEffect(() => {
    getMasterChoiceTypesFn({ variables: { input: encryptInput({ isActive: 1 }) } })
  }, [])

  useEffect(() => {
    if (getMasterChoiceTypes?.data?.getMasterChoiceTypes) {
      setMasterChoiceTypes(_.keyBy(getMasterChoiceTypes?.data?.getMasterChoiceTypes, (o) => o.choiceTypeCode))
      setQuestDetailTypes({ 0: t('No Description'), 1: t('Has Description') })
    }

    if (getMasterChoiceTypes.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getMasterChoiceTypes?.error)),
        onOk() {},
      })
    }
  }, [getMasterChoiceTypes.data])

  useEffect(() => {
    if (state?.activeType === 'createQuestion' && createConfQuestion.data) navigate('/internalcontrol/controlform')
    if (state?.activeType === 'edit' && updateControlForm?.data) navigate('/internalcontrol/controlform')
    if (state?.activeType === 'copy' && copyControlForm?.data) navigate('/internalcontrol/controlform')
    if (
      state?.activeType === 'createQuestion' &&
      getControlForm?.data?.getControlFormById?.controlFormConfigurations.length
    )
      navigate('/internalcontrol/controlform')

    if (updateControlForm.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถแก้ไขข้อมูลได้'),
        content: t(displayError(updateControlForm?.error)),
        onOk() {},
      })
    }

    if (createConfQuestion?.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(createConfQuestion?.error)),
        onOk() {},
      })
    }

    if (copyControlForm?.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(copyControlForm?.error)),
        onOk() {},
      })
    }
  }, [createConfQuestion.data, getControlForm.data, state.activeType, updateControlForm.data, copyControlForm.data])

  useEffect(() => {
    if (getControlForm?.data) {
      const listConfig = getControlForm?.data?.getControlFormById?.controlFormConfigurations
      const listQuestion = getControlForm?.data?.getControlFormById?.controlFormQuestions

      if (listConfig.length) {
        setIsConfiguration([
          ..._.map(listConfig, (d) => {
            return {
              formConfigEN: d.formConfigEN,
              formConfigNo: d.formConfigNo,
              formConfigTH: d.formConfigTH,
              isActive: d.isActive,
              key: uuidv4(),
            }
          }),
        ])
      }

      if (listQuestion.length && listConfig.length) {
        setIsQuestion([
          ..._.map(listQuestion, (d) => {
            return {
              choiceGroupCode: d.choiceGroupCode,
              choiceTypeCode: d.choiceTypeCode,
              formConfigNo: _.find(listConfig, (c) => c.formConfigId === d.formConfigId).formConfigNo,
              isActive: d.isActive,
              key: uuidv4(),
              questDetailType: d.questDetailType,
              questEN: d.questEN,
              questRequired: d.questRequired,
              questTH: d.questTH,
            }
          }),
        ])
      }

      setDataLists([
        ..._.map(getControlForm?.data?.getControlFormById?.controlFormOrganizations, (d) => ({
          key: uuidv4(),
          organizationId: d.organizationId,
          organizationCode: d.organizationCode,
          organizationName: displayText(d.organizationNameTH, d.organizationNameEN),
          organizationLevelName: displayText(d.organizationLevelNameTH, d.organizationLevelNameEN),
          personnelCode: d.personnelCode,
          personnelName: displayText(d.personnelNameTH, d.personnelNameEN),
          organizationLevelCode: d.organizationLevelCode,
          deleteId: d.organizationCode,
          organizationNameEN: d.organizationNameEN,
          organizationNameTH: d.organizationNameTH,
        })),
      ])

      const dControlForm = getControlForm?.data?.getControlFormById?.controlForm

      form.setFieldsValue({
        controlFormCode: state?.activeType !== 'copy' ? dControlForm.controlFormCode : null,
        controlFormEN: dControlForm.controlFormEN,
        controlFormTH: dControlForm.controlFormTH,
        controlFormYear: dControlForm.controlFormYear,
        dateStart: dControlForm.dateStart,
        dateEnd: dControlForm.dateEnd,
        isActive: dControlForm.isActive,
        operationStatusCode: '10',
      })
    }

    if (getControlForm?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getControlForm?.error)),
        onOk() {},
      })
    }
  }, [getControlForm?.data])

  const onFinish = (data) => {
    const checkConf = []
    if (isConfiguration.length) {
      const setUniq = _.uniqBy(isConfiguration, 'isActive')
      if (setUniq.length === 1) {
        const ans = _.find(setUniq, (i) => i.isActive === 0)
        ans && checkConf.push(ans)
      }
    }

    const checkQuest = []
    if (isQuestion.length) {
      const quest = _.groupBy(isQuestion, 'formConfigNo')

      Object.keys(quest).forEach((d) => {
        const setUniq = _.uniqBy(quest[d], 'isActive')
        if (setUniq.length === 1) {
          const ans = _.find(setUniq, (i) => i.isActive === 0)
          ans && checkQuest.push(ans)
        }
      })
    }

    let objData = {
      controlFormId: getControlForm?.data?.getControlFormById?.controlForm?.controlFormId,
      controlForm: {
        controlFormCode: data.controlFormCode,
        controlFormTH: data.controlFormTH,
        controlFormEN: data.controlFormEN ? data.controlFormEN : data.controlFormTH,
        controlFormYear: data.controlFormYear,
        operationStatusCode: data.operationStatusCode,
        dateStart: new Date(data.dateStart),
        dateEnd: new Date(data.dateEnd),
        isActive: data.isActive ? 1 : 0,
        createdBy: user.userId,
        updatedBy: user.userId,
      },
      controlFormOrganizations: JSON.parse(data.organizationList).map((d) => ({
        organizationNameTH: d.organizationNameTH,
        organizationNameEN: d.organizationNameEN,
        organizationCode: d.organizationCode + '',
        createdBy: getControlForm?.data?.getControlFormById?.controlFormOrganizations[0].createdBy,
        createdAt: new Date(getControlForm?.data?.getControlFormById?.controlFormOrganizations[0].createdAt),
        updatedBy: user.userId,
      })),
      controlFormConfigurations: _.map(isConfiguration, (d) => {
        return {
          ...d,
          formConfigEN: d.formConfigEN ? d.formConfigEN : d.formConfigTH,
          controlFormCode: data.controlFormCode,
          createdBy: user.userId,
        }
      }),
      controlQuestions: _.map(isQuestion, (d) => {
        return { ...d, questEN: d.questEN ? d.questEN : d.questTH, createdBy: user.userId }
      }),
    }

    if (checkQuest.length || checkConf.length) {
      let content = ''
      if (checkQuest.length) content = t('กรุณาเปลี่ยนสถานะเป็นใช้งานอย่างน้อย 1 คำถามในแต่ละองค์ประกอบควบคุม')
      if (checkConf.length) content = t('กรุณาเปลี่ยนสถานะเป็นใช้งานอย่างน้อย 1 องค์ประกอบควบคุม')
      if (checkQuest.length && checkConf.length)
        content = t(
          'กรุณาเปลี่ยนสถานะเป็นใช้งานอย่างน้อย 1 องค์ประกอบควบคุม และ เปลี่ยนสถานะเป็นใช้งานอย่างน้อย 1 คำถามในแต่ละองค์ประกอบควบคุม',
        )

      Alert({ type: 'error', title: t('เกิดข้อผิดพลาด'), content: content, onOk() {} })
    } else {
      Alert({
        type: 'confirm',
        title: t(
          state?.activeType === 'createQuestion' || state?.activeType === 'copy'
            ? t('ต้องการยืนยันการบันทึก?')
            : t('ต้องการยืนยันการแก้ไข?'),
        ),
        onOk() {
          if (state?.activeType === 'copy') {
            let objDataCp = encryptInput(objData)
            if (objDataCp.controlFormId) delete objDataCp.controlFormId
            copyControlFormFn({ fetchPolicy: 'no-cache', variables: { input: objDataCp } })
          }
          if (state?.activeType === 'createQuestion')
            createConfQuestionFn({ fetchPolicy: 'no-cache', variables: { input: encryptInput(objData) } })
          if (state?.activeType === 'edit')
            updateControlFormFn({ fetchPolicy: 'no-cache', variables: { input: encryptInput(objData) } })
        },
        onCancel() {},
      })
    }
  }

  const setOrganizationList = (v) => {
    if (_.isEmpty(dataLists)) return setDataLists([...v])
    setDataLists([..._.uniqBy([...dataLists, ...v], 'organizationCode')])
  }

  return (
    <Col sm={24} md={24} lg={24}>
      {(getControlForm?.loading || copyControlForm?.loading || updateControlForm?.loading) && <SpinnersNew />}
      <Breadcrumb data={controlFormEditCrumb} title={t('แบบประเมินควบคุมภายใน')} />
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ ...labelCol }}
        wrapperCol={{ ...wrapperCol }}
        layout="horizontal"
        name={formname}
      >
        <ControlFormAllEdit
          form={form}
          formname={formname}
          orgList={dataLists}
          onDeleteOrg={(v) => setDataLists(v)}
          setModalForm={(e) => setModalForm(e)}
          setModalQuestion={(e) => setModalQuestion(e)}
          setModalConfiguration={(e) => setModalConfiguration(e)}
          activeType={state?.activeType}
          isQuestion={isQuestion}
          isConfiguration={isConfiguration}
          onDeleteQuestion={(v) => setIsQuestion(v)}
          onDeleteConfig={(v) => setIsConfiguration(v)}
          masterChoiceTypes={masterChoiceTypes}
          questDetailTypes={questDetailTypes}
          configItem={(d) => {
            setAccessModalConfAction('edit')
            setIsConfigItem(d)
          }}
          questionItem={(d) => {
            setAccessModalConfAction('edit')
            setIsQuestionItem(d)
          }}
          accessTools={(d) => setAccessTools(d)}
          isCreateConfItem={() => setAccessModalConfAction('add')}
          isCreateQuestion={() => setAccessModalConfAction('add')}
          menu={props.menu}
        />
      </Form>
      {modalForm && (
        <CheckerOrganizationModal
          close={(e) => setModalForm(e)}
          open={modalForm}
          getData={(v) => setOrganizationList(v)}
          menu={props.menu}
          orgActive={dataLists}
        />
      )}
      {modalConfiguration && (
        <ConfigurationModal
          close={(e) => setModalConfiguration(e)}
          open={modalConfiguration}
          accessModalConfAction={accessModalConfAction}
          isConfiguration={isConfiguration}
          isConfigItem={isConfigItem}
          getData={(v) => {
            if (accessModalConfAction === 'add') setIsConfiguration([...isConfiguration, v])
            if (accessModalConfAction === 'edit')
              setIsConfiguration([..._.map(isConfiguration, (c) => (c.key === v.key ? v : c))])
          }}
          getPrevConfigNo={(e) => {
            setIsQuestion(
              _.map(isQuestion, (q) => {
                if (q.formConfigNo === e?.prev) return { ...q, formConfigNo: e?.cur }
                else return { ...q }
              }),
            )
          }}
          accessTools={accessTools}
          menu={props.menu}
        />
      )}
      {modalQuestion && (
        <QuestionModal
          close={(e) => setModalQuestion(e)}
          accessModalConfAction={accessModalConfAction}
          open={modalQuestion}
          isConfiguration={isConfiguration}
          isQuestionItem={isQuestionItem}
          getData={(v) => {
            if (accessModalConfAction === 'add') setIsQuestion([...isQuestion, v])
            if (accessModalConfAction === 'edit')
              setIsQuestion([..._.map(isQuestion, (c) => (c.key === v.key ? v : c))])
          }}
          accessTools={accessTools}
          menu={props.menu}
        />
      )}
    </Col>
  )
}

ControlFormEdit.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default ControlFormEdit
