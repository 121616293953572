import { gql } from '@apollo/client'

export const NOTI_READ = gql`
  mutation readNoti($personnelCode: String!) {
    readNoti(personnelCode: $personnelCode)
  }
`

export const NOTI_READ_BY_MENU = gql`
  mutation readNotiByMenu($menuCode: String!, $personnelCode: String!) {
    readNotiByMenu(menuCode: $menuCode, personnelCode: $personnelCode)
  }
`

export const LOGOUT = gql`
  mutation logout($userId: String!, $logfileLoginId: String!) {
    logout(userId: $userId, logfileLoginId: $logfileLoginId)
  }
`
