import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { DATAFOLLOWUP_RESULT_FOLLOW_TAB_LIST } from '../../graphql/Query'
import { useLazyQuery } from '@apollo/client'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError } from '../../../../../utilitys/helper'
import { encryptInput } from '../../../../../utilitys/crypto'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { MainTable, SubTable } from './css/Styles'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { IconNew } from '../../../../../components/icon/Icon'
import { displayText } from '../../../../../utilitys/helper'
import { dateDisplay } from '../../../../../utilitys/dateHelper'
import { Status } from '../../../../../components/status/Status'
import { getSegmentUrl } from '../../../../../utilitys/helper'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { ButtonNew } from '../../../../../components/button/Button'
import { useNavigate } from 'react-router-dom'

const FormFollowUpAuditResultfollowTabIndex = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const typeMain = getSegmentUrl(4)

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }
  const [dataFollowUpResultFollowTabListFn, dataFollowUpResultFollowTabList] = useLazyQuery(
    DATAFOLLOWUP_RESULT_FOLLOW_TAB_LIST,
  )
  useEffect(() => {
    if (dataFollowUpResultFollowTabList.data) {
      let response = dataFollowUpResultFollowTabList?.data?.dataFollowUpResultFollowTabList
      if (response) {
        setCount(response.count)
        setDataLists(
          response.result.map((item) => {
            return {
              key: item.issueId,
              issue: displayText(item.issueTH, item.issueEN),
              issueStatusName: <Status text={item.issueStatusNameTH} />,
              description: (
                <SubTable>
                  <Datatable
                    columns={[
                      {
                        title: <div className="text-center">{t('ข้อเสนอแนะ')}</div>,
                        dataIndex: 'suggest',
                        width: '300px',
                        className: 'vertical-align-top',
                      },
                      {
                        title: <div className="text-center">{t('กิจกรรที่ต้องดำเนินการ')}</div>,
                        dataIndex: 'activity',
                        width: '900px',
                        className: 'vertical-align-top',
                        render: (record) => <div style={{ wordBreak: 'break-word' }}>{record}</div>,
                      },
                      {
                        title: <div className="text-center">{t('สถานะของการดำเนินการ')}</div>,
                        dataIndex: 'actionStatusName',
                        width: '250px',
                        className: 'vertical-align-top',
                        render: (record) => <div className="text-center">{record}</div>,
                      },
                      {
                        title: <div className="text-center">{t('การดำเนินการ/แผน')}</div>,
                        dataIndex: 'planDetail',
                        width: '250px',
                        className: 'vertical-align-top',
                      },
                      {
                        title: <div className="text-center">{t('วันที่ดำเนินการ/วันที่คาดว่าจะแล้วเสร็จ')}</div>,
                        dataIndex: 'expectDate',
                        width: '500px',
                        className: 'vertical-align-top',
                        render: (record) => <div className="text-center">{record}</div>,
                      },
                      {
                        title: <div className="text-center">{t('หน่วยรับตรวจ')}</div>,
                        dataIndex: 'organizationName',
                        width: '150px',
                        className: 'vertical-align-top',
                      },
                      {
                        title: <div className="text-center">{t('ข้อคิดเห็น')}</div>,
                        dataIndex: 'ideaStatusName',
                        width: '150px',
                        className: 'vertical-align-top',
                        render: (record) => <div className="text-center">{record}</div>,
                      },
                      {
                        title: <div className="text-center">{t('เหตุผล')}</div>,
                        dataIndex: 'reason',
                        width: '150px',
                        className: 'vertical-align-top',
                        render: (record) => <div className="text-center">{record}</div>,
                      },
                      {
                        title: '',
                        dataIndex: 'extraBTN',
                        width: '150px',
                        className: 'vertical-align-top',
                        render: (record) => <div className="text-center">{record}</div>,
                      },
                      {
                        title: '',
                        dataIndex: 'action',
                        width: '120px',
                        className: 'vertical-align-top',
                        render: (record) => <div className="text-center">{record}</div>,
                      },
                    ]}
                    data={item.subItem.map((subItem) => {
                      return {
                        key: subItem.actionId,
                        suggest: displayText(subItem.suggestTH, subItem.suggestEN),
                        activity: displayText(subItem.activityTH, subItem.activityEN),
                        actionStatusName: displayText(subItem.actionStatusNameTH, subItem.actionStatusNameEN),
                        planDetail: displayText(subItem.planDetailTH, subItem.planDetailEN),
                        expectDate: subItem.expectDate ? dateDisplay(subItem.expectDate) : '',
                        organizationName: displayText(subItem.organizationNameTH, subItem.organizationNameEN),
                        ideaStatusName: displayText(subItem.ideaStatusNameTH, subItem.ideaStatusNameEN),
                        reason: displayText(subItem.reasonTH, subItem.reasonEN),
                        extraBTN: subItem.actionId ? (
                          subItem.ideaSucceed === 1 ? (
                            subItem.ideaStatusCode === '10' ? (
                              <>
                                <ButtonNew
                                  type="flagOutlinedInTableBlue"
                                  roles={{ type: 'edit', menu: props.menu }}
                                  disabled={typeMain === 'view' ? true : false}
                                  onClick={() => {
                                    navigate('/followup/followupaudit/resultfollow/' + typeMain + '/scan/edit', {
                                      state: { refCode: subItem.actionId },
                                    })
                                  }}
                                >
                                  {t('รอพิจารณา')}
                                </ButtonNew>
                              </>
                            ) : (
                              <ButtonNew type="checkCircleInTableGreen">
                                {displayText(subItem.ideaStatusNameTH, subItem.ideaStatusNameEN)}
                              </ButtonNew>
                            )
                          ) : (
                            <ButtonNew
                              type="flagOutlinedInTableBlue"
                              roles={{ type: 'edit', menu: props.menu }}
                              disabled={typeMain === 'view' ? true : false}
                              onClick={() => {
                                navigate('/followup/followupaudit/resultfollow/' + typeMain + '/scan/edit', {
                                  state: { refCode: subItem.actionId },
                                })
                              }}
                            >
                              {t('พิจารณา')}
                            </ButtonNew>
                          )
                        ) : (
                          <ButtonNew type="moreHorizontalInTableOrange" disabled={true}>
                            {t('รอชี้แจง')}
                          </ButtonNew>
                        ),
                        action: (
                          <div className="text-center">
                            <ButtonGroup
                              menu={props.menu}
                              disabled={subItem.actionId ? false : true}
                              icons={[
                                {
                                  type: 'view',
                                  onClick: () => {
                                    navigate('/followup/followupaudit/resultfollow/' + typeMain + '/scan/view', {
                                      state: { refCode: subItem.actionId },
                                    })
                                  },
                                },
                              ].concat(
                                typeMain === 'edit'
                                  ? subItem.actionId
                                    ? subItem.ideaSucceed === 0 || subItem.ideaStatusCode === '10'
                                      ? [
                                          {
                                            type: 'edit',
                                            onClick: () => {
                                              navigate(
                                                '/followup/followupaudit/resultfollow/' + typeMain + '/scan/edit',
                                                {
                                                  state: { refCode: subItem.actionId },
                                                },
                                              )
                                            },
                                          },
                                        ]
                                      : []
                                    : []
                                  : [],
                              )}
                            />
                          </div>
                        ),
                      }
                    })}
                  />
                </SubTable>
              ),
            }
          }),
        )
      }
    }
    if (dataFollowUpResultFollowTabList.error) {
      showAlertLoadDataError(displayError(dataFollowUpResultFollowTabList.error))
    }
  }, [dataFollowUpResultFollowTabList.data])

  const callApiSearch = () => {
    dataFollowUpResultFollowTabListFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          filters: {
            projectCode: props.refCode,
          },
          pagination: { limit, page },
          sort: [],
        }),
      },
    })
  }
  useEffect(() => {
    callApiSearch()
  }, [page, limit])
  const mainColumns = [
    {
      title: <div className="text-center">{t('ประเด็น/ข้อสังเกต')}</div>,
      dataIndex: 'issue',
      width: '80%',
    },
    {
      title: <div className="text-center">{t('สถานะประเด็น/ข้อสังเกต')}</div>,
      dataIndex: 'issueStatusName',
      width: '20%',
      className: 'center-text',
    },
  ]
  return (
    <>
      {dataFollowUpResultFollowTabList.loading && <SpinnersNew />}

      <Row>
        <Col md={24}>
          <MainTable>
            <Datatable
              columns={mainColumns}
              data={dataLists}
              handleTableChange={() => {}}
              paginationCustom={true}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
              total={count}
              enableScroll={{ x: false, y: true }}
              scroll={{ x: true, y: false }}
              pageSize={limit}
              page={page}
              expandable={{
                expandedRowRender: (record) => record.description,
                onExpandedRowsChange: () => {},
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <IconNew icon={'ChevronUp'} onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <IconNew icon={'ChevronDown'} onClick={(e) => onExpand(record, e)} />
                  ),
              }}
              btnAdd={<></>}
            />
          </MainTable>
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="center" style={{ marginTop: '50px' }}>
        <Col>
          <ButtonNew
            type="cancel"
            onClick={() => {
              navigate('/followup/followupaudit')
            }}
          >
            {t('ยกเลิก')}
          </ButtonNew>
        </Col>
      </Row>
    </>
  )
}

export default FormFollowUpAuditResultfollowTabIndex
