import { gql } from '@apollo/client'

export const GET_AUDITOPEN_ORGANIZATION = gql`
  query getAuditOpenOrganizationsSelect($input: AuditOpenOrganizationsInput!) {
    getAuditOpenOrganizationsSelect(data: $input) {
      organizationCode
      organizationNameTH
      organizationNameEN
    }
  }
`
