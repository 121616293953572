import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_NEW_PERSONNEL_STATUS } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectNewPersonnelStatuses = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { newPersonnelStatusId, newPersonnelStatusCode, newPersonnelStatusNameTH, newPersonnelStatusNameEN, isActive } =
    filters || {}

  const [isData, isSetData] = useState([])

  const [getMasterNewPersonnelStatusFn, getMasterNewPersonnelStatus] = useLazyQuery(GET_MASTER_NEW_PERSONNEL_STATUS)

  useEffect(() => {
    getMasterNewPersonnelStatusFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) }
    })
  }, [newPersonnelStatusId, newPersonnelStatusCode, newPersonnelStatusNameTH, newPersonnelStatusNameEN, isActive])

  useEffect(() => {
    if (getMasterNewPersonnelStatus.data) {
      isSetData(
        _.map(getMasterNewPersonnelStatus?.data?.getMasterNewPersonnelStatus, (item) => {
          return {
            label: displayText(item?.newPersonnelStatusNameTH, item?.newPersonnelStatusNameEN),
            value: item?.newPersonnelStatusCode,
            source: item
          }
        })
      )
    }
  }, [getMasterNewPersonnelStatus.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterNewPersonnelStatus.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectNewPersonnelStatuses
