/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Radio, RadioGroup } from '../../../../components/radio/Radio'
import { ModalNew } from '../../../../components/modal/Modal'
import { LabelNew } from '../../../../components/label/Label'
import { encryptInput, decryptData } from '../../../../utilitys/crypto'
import { Alert } from '../../../../components/alert/Alert'
import { displayError } from '../../../../utilitys/helper'
import { RICK_RESULT_EXPORT } from '../graphql/Query'
const formItemLayout = {
  labelCol: { sm: 14, md: 7, lg: 7, xl: 7 },
  wrapperCol: { sm: 16, md: 19, lg: 19, xl: 19, align: 'left' },
  Col: {
    sm: 24,
    md: 16,
    lg: 18,
  },
}
const RiskAssessmentReportModal = (props) => {
  const { open, close, data, onSuccess } = props
  const { t } = useTranslation()
  const lang = localStorage.getItem('lang')
  const [form] = Form.useForm()
  const formName = 'riskresultexports'
  const [visible, setVisible] = useState(false)
  const [riskResultExportFn, riskResultExport] = useLazyQuery(RICK_RESULT_EXPORT)

  useEffect(() => {
    setVisible(open)
  }, [open])

  useEffect(() => {
    const _ = data
    if (Object.keys(_).length !== 0) {
      form?.setFieldsValue({
        riskFormCode: _?.riskFormCode,
        riskResultId: _?.riskResultId,
        type: _?.type,
        maker: '',
        exportType: 'excel',
      })
    }
  }, [data])
  useEffect(() => {
    if (riskResultExport.data) {
      const { fileUrl } = riskResultExport.data.riskResultExport
      window.open(fileUrl)
    }
    if (riskResultExport.error) {
      const err = riskResultExport.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [riskResultExport.data])
  async function onSubmit(_) {
    await riskResultExportFn({
      variables: {
        input: encryptInput({
          riskResultId: _?.riskResultId,
          riskFormCode: _?.riskFormCode,
          fileType: _?.exportType,
          waterMark: _?.maker,
          isTypeAction: data.type,
          lang: lang,
        }),
      },
    })
  }
  function onClose(e) {
    setVisible(visible ? false : true)
    close(e)
  }
  return (
    <>
      <ModalNew
        visible={visible}
        btnName={t('พิมพ์')}
        onSubmit={() => {
          form
            .validateFields()
            .then((v) => onSubmit(v))
            .catch((err) => console.log(err))
        }}
        onClose={(e) => onClose(e)}
        testTitle={t('พิมพ์รายงาน')}
        type="medium"
      >
        <Row justify="center">
          <Col {...formItemLayout.Col}>
            <Form
              name={formName}
              layout="horizontal"
              form={form}
              labelAlign="left"
              labelCol={{ ...formItemLayout.labelCol }}
              wrapperCol={{ ...formItemLayout.wrapperCol }}
            >
              <Form.Item label={<LabelNew type="tab-header-inactive">{t('ประเภทรายงาน')}</LabelNew>} name="exportType">
                <RadioGroup>
                  <Space size={[8, 8]} wrap>
                    <Radio value={'pdf'}>{t('pdf')}</Radio>
                    &nbsp;
                    <Radio value={'word'}>{t('word')}</Radio>
                  </Space>
                  <Space size={[8, 8]} wrap>
                    <Radio value={'excel'}>{t('excel')}</Radio>
                    &nbsp;
                    <Radio value={'csv'}>{t('csv')}</Radio>
                  </Space>
                </RadioGroup>
              </Form.Item>
              <Form.Item name={'maker'} label={t('ลายน้ำ ')}>
                <Input placeholder={t('ระบุลายน้ำ')} />
              </Form.Item>
              <Form.Item name={'riskFormCode'} hidden={true}>
                <Input />
              </Form.Item>
              <Form.Item name={'riskResultId'} hidden={true}>
                <Input />
              </Form.Item>
              <Form.Item name={'type'} hidden={true}>
                <Input />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </ModalNew>
    </>
  )
}

export default RiskAssessmentReportModal
