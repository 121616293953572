import React, { useState, useEffect } from 'react'
import { Upload } from 'antd'
import { useTranslation } from 'react-i18next'
import { UploadStyle, UploadInlineStyle } from './UploadStyles'
import { ButtonNew } from '../button/Button'
import { colors } from '../../configs/styles.config'
import { Alert } from '../../components/alert/Alert'
import { getSegmentUrl } from '../../utilitys/helper'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { decryptData, encryptInput } from '../../utilitys/crypto'
import { SpinnersNew } from '../../components/spinners/Spinners'
import { downloadFile } from '../../utilitys/files'

export const UploadNew = (props) => {
  const { t } = useTranslation()

  let propsApi = {}
  let textBtt = 'อัพโหลด'
  const {
    idFile,
    idFileDelete,
    typeFile,
    actionType = 'create',
    inline,
    orderLabel,
    multiRow,
    setLimitSize,
    defaults,
    otherProps,
  } = props
  const [filesList, setFilesList] = useState([])
  const [loading, setLoading] = useState(false)
  const [sizeFilesDynamic, setSizeFilesDynamic] = useState(false)
  const [fileSizeImage, setFileSizeImage] = useState(false)
  const [namePartUpload, setNamePartUpload] = useState('')
  const [download, setDownload] = useState(false)
  const [deleteting, setDeleteting] = useState(false)
  const [actionFirst, setActionFirst] = useState(false)

  //function get file all
  const getFile = (idFileIndex) => {
    if (!_.isEmpty(idFileIndex) && !actionFirst) {
      fetch(process.env.REACT_APP_API_UPLOAD + `/files`, {
        method: 'GET',
        headers: {
          idFile: idFileIndex,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.files) {
            setFilesList(res.files)
            const dataArray = res.files
              .filter((item) => item.uid)
              .map((item2) => {
                return item2.uid
              })
            setActionFirst(true)
            props.setIdFile(dataArray)
          }
          setLoading(true)
        })
        .catch((err) => {
          console.log('err: ', err)
        })
    } else {
      setLoading(true)
    }
  }

  //function get file all
  const getSizeFile = (typeFile) => {
    if (!_.isEmpty(typeFile)) {
      fetch(process.env.REACT_APP_API_UPLOAD + `/sizeFiles`, {
        method: 'GET',
        headers: {
          typeFile: typeFile,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.sizeFiles) {
            setSizeFilesDynamic(res.sizeFiles)
            if (setLimitSize) {
              setLimitSize(res.sizeFiles)
            }
          }
        })
        .catch((err) => {
          console.log('err: ', err)
        })
    } else {
      // setLoading(true)
    }
  }

  // function delete file
  const deleteFile = (fileName) => {
    if (fileName) {
      fetch(process.env.REACT_APP_API_UPLOAD + `/deleteFile/${fileName}`, {
        method: 'GET',
      })
        .then((res) => {
          setDeleteting(false)
          console.log('res: ', res)
        })
        .catch((err) => {
          setDeleteting(false)
          console.log('err: ', err)
        })
    }
  }

  useEffect(() => {
    const getData = async () => {
      await getSizeFile(typeFile)
    }
    getData()
  }, [])

  useEffect(() => {
    const getData = async () => {
      await getFile(defaults)
    }
    getData()
  }, [defaults])

  const formatSizeMb = (bytes) => {
    return (bytes / 1048576).toFixed(2)
  }

  const deleteFileError = () => {
    const data = filesList.filter((item) => item.status !== 'error')
    const newIdFile = data.map((item) => item.uid)
    props.setIdFile(newIdFile)
    setFilesList(data)
  }

  // userId
  // props upload ant
  let data = getSegmentUrl(1).toLocaleLowerCase()
  let user = decryptData(localStorage.getItem('user'))
  let limits = encryptInput(sizeFilesDynamic)
  let size = encryptInput(fileSizeImage)
  let token = decryptData(localStorage.getItem('user'))
  propsApi = {
    name: 'file',
    action: namePartUpload,
    headers: {
      userId: user.userId,
      module: data,
      limits: limits,
      size: size,
      token: token.access_token,
      // authorization: `Bearer ${token.access_token}`,
    },
    //when the api is celled
    beforeUpload(file) {
      setFileSizeImage(file.size)
      const isJpgOrPng =
        file.type === 'image/jpeg' ||
        file.type === 'image/jpg' ||
        file.type === 'image/png' ||
        file.type === 'image/gif'
      // เช็คนามสกุลไฟล์
      if (isJpgOrPng) {
        setNamePartUpload(process.env.REACT_APP_API_UPLOAD + '/uploadImage')
        // ไฟล์รูปเกิน ขนาด 2 Mb
        if (formatSizeMb(file.size) > sizeFilesDynamic) {
          file.status = 'error'
          file.response = `เกินขนาดไฟล์ ${sizeFilesDynamic}Mb` // เกินขนาดไฟล์ 2Mb
          setFilesList([file, ...filesList])
          Alert({
            type: 'error',
            title: `ไฟล์นามสกุล .jpeg, .jpg, .png, .gif เกินขนาด ${sizeFilesDynamic}Mb`,
            onOk() {
              deleteFileError()
            },
          })
          return false
        }
      } else {
        setNamePartUpload(process.env.REACT_APP_API_UPLOAD + '/uploadAll')
        // เช็คไฟล์ที่ไม่ใช้ไฟล์ รูป
        if (formatSizeMb(file.size) > sizeFilesDynamic) {
          file.status = 'error'
          file.response = `เกินขนาดไฟล์ ${sizeFilesDynamic}Mb` // เกินขนาดไฟล์ 10Mb
          setFilesList([file, ...filesList])
          Alert({
            type: 'error',
            title: `ไฟล์เกินขนาด ${sizeFilesDynamic}Mb`,
            onOk() {
              deleteFileError()
            },
          })
          return false
        }
      }
    },
    onChange(info) {
      let fileList2 = [...info.fileList]
      // delete old file
      if (filesList?.length > 0 && props.type !== 'mutiple') {
        deleteFile(filesList[0]?.response?.name)
        // contA--
      }

      // move data
      let arrayFileId = []
      fileList2 = fileList2.map((file, index) => {
        if (file.response) {
          if (file.response.name) file.name = file.response.name
          if (file.response.originalname) file.originalname = file.response.originalname
          if (file.response.url) file.url = file.response.url
          if (file.response.fileId) file.uid = file.response.fileId

          // จัดข้อมูลให้ fileId ทีมเอาไปใช้ต่อ
          arrayFileId[index] = file.response.fileId
        }
        return file
      })
      props.setIdFile(arrayFileId)
      setFilesList(fileList2)

      if (props.setFileData) props.setFileData(fileList2[0])
    },
    // set data in component upload ant
    fileList: filesList,
    showUploadList: {
      showDownloadIcon: true,
    },
    onDownload: (file) => {
      setDownload(true)
      downloadFile(file.originalname, file.name, () => {
        setDownload(false)
      })
    },
    onRemove: (file) => {
      if (file.status !== 'error') {
        if (actionType === 'create' || actionType === 'add') {
          setDeleteting(true)
          deleteFile(file.name)
        }
        // contA--
      }
      props.setIdFileDelete([...idFileDelete, file.uid])
      //  delete data in array
      const index = filesList.indexOf(file)
      const newFileList = filesList.slice()
      newFileList.splice(index, 1)
      setFilesList(newFileList)
      //   -------------------

      // delete index idFile
      const newIdFile = idFile.slice()
      newIdFile.splice(index, 1)
      props.setIdFile(newIdFile)
      // ---------------------
    },
    progress: {
      strokeColor: {
        '0%': colors.primary,
        '100%': colors.success,
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  }

  return (
    <>
      {(download || deleteting) && <SpinnersNew />}
      {!inline ? (
        <UploadStyle actionType={actionType}>
          {loading ? (
            <Upload
              {...propsApi}
              {...otherProps}
              className="upload-file-style"
              accept={props.accept}
              maxCount={props.type === 'mutiple' ? '' : 1}
              multiple={props.type === 'mutiple' ? true : false}
            >
              <ButtonNew type="upload" iconname="Upload" disabled={props.disabled ? true : false}>
                {t(textBtt)}
              </ButtonNew>
            </Upload>
          ) : (
            ''
          )}
        </UploadStyle>
      ) : (
        <UploadInlineStyle orderLabel={orderLabel} multiRow={multiRow} actionType={actionType}>
          {loading ? (
            <Upload
              {...propsApi}
              {...otherProps}
              className="upload-file-style-inline"
              accept={props.accept}
              maxCount={props.type === 'mutiple' ? '' : 1}
              multiple={props.type === 'mutiple' ? true : false}
              disabled={props.disabled ? true : false}
            >
              <ButtonNew type="upload" iconname="Upload">
                {t(textBtt)}
              </ButtonNew>
            </Upload>
          ) : (
            ''
          )}
        </UploadInlineStyle>
      )}
    </>
  )
}

UploadNew.propTypes = {
  idFile: PropTypes.array.isRequired,
  setIdFile: PropTypes.func.isRequired,
  typeFile: PropTypes.string.isRequired,
  type: PropTypes.string,
  inline: PropTypes.bool,
  setFileData: PropTypes.func,
}
