import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { TabNew } from '../../../components/tab/Tab'
import { CardNew } from '../../../components/card/Card'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { racMatrixApproveInfoCrumb } from './utils/constants'
import { getSegmentUrl } from '../../../utilitys/helper'
import GeneralTab from './components/tab/GeneralTab'
import RiskTab from './components/tab/RiskTab'

const RacMatrixApproveInForm = (props) => {
  const wrapper = { sm: 24, md: 24, lg: 24, xl: 24 }
  const { menu } = props

  const { t } = useTranslation()
  const { state } = useLocation()
  const navigate = useNavigate()
  const params = getSegmentUrl(4)

  if (!state) return <Navigate to={'/auditoperations/racmatrixapprove'} />

  const [tabs, setTabs] = useState([])

  useEffect(() => {
    setTabs([
      { key: 'general', name: t('ข้อมูลทั่วไป'), disabled: false, content: () => <GeneralTab menu={menu} /> },
      { key: 'risk', name: t('อนุมัติปัจจัย/ความเสี่ยง'), disabled: false, content: () => <RiskTab menu={menu} /> },
    ])
  }, [])

  return (
    <>
      <Col {...wrapper}>
        <Breadcrumb data={racMatrixApproveInfoCrumb} title={t('อนุมัติความเสี่ยงและการควบคุม')} />
        <CardNew topic={t('รายละเอียดประเมินความเสี่ยงและการควบคุม')} icon="List" toggledrop={'false'}>
          <TabNew
            tab={tabs}
            activeKey={!params ? 'general' : params}
            onTabClick={(key) => {
              switch (key) {
                case 'general':
                  navigate('/auditoperations/racmatrixapprove/tab/general', { state: state })
                  break
                case 'risk':
                  navigate('/auditoperations/racmatrixapprove/tab/risk', { state: state })
                  break
                default:
                  navigate('/auditoperations/racmatrixapprove')
                  break
              }
            }}
          />
        </CardNew>
      </Col>
    </>
  )
}

RacMatrixApproveInForm.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default RacMatrixApproveInForm
