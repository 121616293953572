import { gql } from '@apollo/client'

export const GET_RISK_AUDIT_UNIVERSE_GRAPH_COLUMN_1 = gql`
  query GetRiskAuditUniverseGraphColumn1($input: RiskAuditUniverseInput!) {
    getRiskAuditUniverseGraphColumn1(input: $input) {
      year
      riskFormCode
      projectCode
      riskLevelCode
    }
  }
`

export const GET_RISK_AUDIT_UNIVERSE_GRAPH_COLUMN_2 = gql`
  query GetRiskAuditUniverseGraphColumn2($input: RiskAuditUniverseInput!) {
    getRiskAuditUniverseGraphColumn2(input: $input) {
      year
      riskFormCode
      projectCode
      riskLevelCode
    }
  }
`

export const GET_RISK_AUDIT_UNIVERSE_PAGINATION = gql`
  query GetRiskAuditUniversePagination($input: RiskAuditUniversePaginationInput!) {
    getRiskAuditUniversePagination(input: $input) {
      count
      limit
      page
      resRiskLevel {
        riskLevelCode
        riskLevelNameTH
        riskLevelNameEN
      }
      result {
        no
        year
        detail {
          riskFactorNameTH
          isWeight
          TotalScore
          weight
        }
        isWeight
        riskFormCode
        riskModelCode
        riskModelEN
        riskModelTH
      }
    }
  }
`
