import { gql } from '@apollo/client'

export const GET_COMPLETE_RESULT_PAGINATION = gql`
  query getCompleteResultPagination($input: CompleteResultPaginationInput!) {
    getCompleteResultPagination(input: $input) {
      result {
        no
        projectId
        projectRefNameTH
        dateStart
        dateEnd
        projectName
        organizationNameTH
        organizationCheck
        fullName
      }
      count
      page
      limit
    }
  }
`

export const GET_COMPLETE_RESULT_REPORT = gql`
  query getConsultationResultReport($input: CompleteResultInput!) {
    getConsultationResultReport(input: $input) {
      projectId
    }
  }
`

export const COMPLETE_RESULT_REPORT_EXPORT = gql`
  query CompleteResultExport($input: CompleteExportInput!) {
    CompleteResultExport(input: $input) {
      message
      fileUrl
    }
  }
`
