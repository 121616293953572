import { useLazyQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayError, displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import AuditResultListFilter from './components/filter/AuditResultListFilter'
import { GET_AUDIT_ISSUES_EXPORT, GET_AUDIT_RESULT_PAGINATION } from './graphql/Query'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24
}

const AuditResultList = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { menu } = props

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/auditresult' },
    { label: t('บันทึกผลการปฏิบัติงานตรวจสอบ') }
  ]

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  const [dataLists, setDataLists] = useState([])
  const [filters, setFilters] = useState({})
  const [user] = useDecodeUser()

  const [pagination, { data: dataPagination, loading: loadingPagination, error: errorPagination }] =
    useLazyQuery(GET_AUDIT_RESULT_PAGINATION)

  const [cellExport, { data: dataExport, loading: loadingExport, error: errorExprot }] =
    useLazyQuery(GET_AUDIT_ISSUES_EXPORT)

  useEffect(() => {
    if (dataExport) {
      if (dataExport.auditIssuesExport.message === 'succcess') {
        window.open(`${dataExport.auditIssuesExport.fileUrl}`, '_blank')
      } else {
        Alert({
          type: 'error',
          title: t('ไม่มีข้อมูล'),
          onOk() {}
        })
      }
    }

    if (errorExprot) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorExprot)),
        onOk() {}
      })
    }
  }, [dataExport])

  const Column = [
    {
      title: t('โครงการ'),
      dataIndex: 'projectName',
      sorter: true,
      width: '15%',
      className: 'text-nowrap'
    },
    {
      title: t('แหล่งที่มาของโครงการ'),
      dataIndex: 'projectRefNameTH',
      sorter: true,
      width: '15%',
      className: 'text-nowrap'
    },
    {
      title: t('วันที่เริ่มต้น'),
      dataIndex: 'dateStart',
      sorter: true,
      width: '10%',
      className: 'text-nowrap'
    },
    {
      title: t('วันที่สิ้นสุด'),
      dataIndex: 'dateEnd',
      sorter: true,
      width: '10%',
      className: 'text-nowrap'
    },
    {
      title: t('หน่วยงานที่รับผิดชอบ'),
      dataIndex: 'organizationNameTH',
      sorter: true,
      width: '15%',
      className: 'text-nowrap'
    },
    {
      title: t('ผู้รับผิดชอบ'),
      dataIndex: 'fullName',
      sorter: true,
      width: '15%',
      className: 'text-nowrap'
    },
    {
      title: '',
      dataIndex: 'button',
      width: '10%',
      className: 'text-nowrap',
      render: (item) => {
        return item.button === '0' && item.flag === '0' ? (
          <>
            <ButtonNew
              type="flagOutlinedInTableBlue"
              roles={{ type: 'add', menu: menu }}
              onClick={() => {
                navigate('/auditoperations/auditresult/TabOne', {
                  state: { type_action: 'add', refCode: item.projectCode, actionPlanId: item.actionPlanId }
                })
              }}
            >
              {t('บันทึกผลการตรวจสอบ')}
            </ButtonNew>
          </>
        ) : item.button === '1' && item.flag === '1' ? (
          <>
            <ButtonNew
              type="moreHorizontalInTableOrange"
              roles={{ type: 'edit', menu: menu }}
              onClick={() => {
                navigate('/auditoperations/auditresult/TabOne', {
                  state: { type_action: 'edit', refCode: item.projectCode, actionPlanId: item.actionPlanId }
                })
              }}
            >
              {t('แก้ไขผลการตรวจสอบ')}
            </ButtonNew>
          </>
        ) : (
          <>
            <ButtonNew type="moreHorizontalInTableGray" roles={{ type: 'add', menu: menu }}>
              {t('ตรวจสอบเสร็จสิ้น')}
            </ButtonNew>
          </>
        )
      }
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '10%',
      align: 'right'
    }
  ]

  useEffect(() => {
    if (user?.orgCode) {
      pagination({
        variables: {
          input: encryptInput({
            organizationCode: user.orgCode,
            filters: filters,
            pagination: { limit, page },
            sort: sort
          })
        }
      })
    }
  }, [page, limit, sort, user?.orgCode])

  useEffect(() => {
    if (dataPagination) {
      let response = dataPagination.auditResultPaginationGet

      setCount(response.count)
      setDataLists(
        _.map(response.result, (item, key) => {
          return {
            key: key + 1,
            projectName: displayText(item.projectName, item.projectNameEN),
            projectRefNameTH: displayText(item.projectRefNameTH, item.projectRefNameEN),
            dateStart: dateDisplay(item.dateStart),
            dateEnd: dateDisplay(item.dateEnd),
            organizationNameTH: displayText(item.organizationNameTH, item.organizationNameEN),
            responsiblePerson: item.responsiblePerson,
            fullName: item.fullName,
            button: {
              button: item.button,
              flag: item.flag,
              projectCode: item.projectCode,
              actionPlanId: item.actionPlanId
            },
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'download',
                      onClick: () => {
                        let data = {
                          projectCode: item.projectCode,
                          lang: localStorage.getItem('lang')
                        }
                        cellExport({
                          variables: {
                            input: encryptInput(data)
                          }
                        })
                      }
                    },
                    {
                      type: 'view',
                      onClick: () => {
                        navigate('/auditoperations/auditresult/TabOne', {
                          state: { type_action: 'view', refCode: item.projectCode, actionPlanId: item.actionPlanId }
                        })
                      }
                    }
                  ]}
                />
              </div>
            )
          }
        })
      )
    }
    if (errorPagination) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorPagination)),
        onOk() {}
      })
    }
  }, [dataPagination])

  const fncReset = () => {
    setFilters({})
    pagination({
      variables: {
        input: encryptInput({
          organizationCode: user.orgCode,
          filters: {},
          pagination: { limit: limit, page: 1 },
          sort: sort
        })
      }
    })
  }

  return (
    <>
      {(loadingPagination || loadingExport) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('บันทึกผลการปฏิบัติงานตรวจสอบ')} />
      <AuditResultListFilter
        fncReset={fncReset}
        getValue={(e) => {
          setFilters({
            projectRefCode: e.projectRefCode,
            projectName: e.projectName,
            organizationCode: e.organizationCode,
            organizationChecked: e.organizationChecked,
            fullName: e.fullName
          })
          pagination({
            variables: {
              input: encryptInput({
                organizationCode: user.orgCode,
                filters: {
                  projectRefCode: e.projectRefCode,
                  projectName: e.projectName,
                  organizationCode: e.organizationCode,
                  organizationChecked: e.organizationChecked,
                  fullName: e.fullName
                },
                pagination: { limit, page },
                sort: []
              })
            }
          })
        }}
        menu={menu}
      />
      <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="List" toggledrop={'false'}>
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            <Datatable
              columns={Column}
              data={[...dataLists]}
              total={count}
              isScoll={{ x: true, y: true }}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              paginationCustom={true}
              showSizeChanger={true}
              searchCustom={true}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => {
                const reNewField = [
                  {
                    projectName: 'projectNameTH'
                  }
                ]
                setSort(handleSort(e?.sorter, reNewField))
              }}
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default AuditResultList
