import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useLazyQuery } from '@apollo/client'
import { Space, Form } from 'antd'
import { ModalNew } from '../../../components/modal/Modal'
import { InputNew } from '../../../components/input/Input'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { LabelNew as Label } from '../../../components/label/Label'
import { Alert } from '../../../components/alert/Alert'
import { regexEng, regexEngThai, displayError } from '../../../utilitys/helper'
import { GET_CONTROLNATURE_BY_CODE } from './graphql/Query'
import { SAVE_CONTROLNATURE } from './graphql/Mutation'
import { encryptInput } from '../../../utilitys/crypto'

const ControlNatureModal = (props) => {
  const { dataParams } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const disabled = dataParams?.type_action === 'view' ? true : false

  /* ---------------- API ----------- */
  const [saveControlNatureFn, saveControlNature] = useMutation(SAVE_CONTROLNATURE)
  const [getControlNatureByCodeFn, dataControlNatureByCode] = useLazyQuery(GET_CONTROLNATURE_BY_CODE)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    setVisible(props.open)
    if (dataParams) {
      getControlNatureByCodeFn({
        variables: {
          controlNatureCode: encryptInput(dataParams.controlNatureCode),
        },
      })
    }
  }, [dataParams, props.open])

  useEffect(() => {
    if (dataControlNatureByCode.data) {
      const data = dataControlNatureByCode.data.getMasterControlNatureByCode
      form.setFieldsValue({
        controlNatureCode: data.controlNatureCode,
        controlNatureNameTH: data.controlNatureNameTH,
        controlNatureNameEN: data.controlNatureNameEN,
        isActive: data.isActive,
      })
    }

    if (dataControlNatureByCode.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataControlNatureByCode.error)),
        onOk() {},
      })
    }
  }, [dataControlNatureByCode.data])

  useEffect(() => {
    if (saveControlNature.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          props.refetch()
        },
      })
    }

    if (saveControlNature.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(saveControlNature.error)),
        onOk() {},
      })
    }
  }, [saveControlNature.data])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    showConfirm(input)
  }
  const showConfirm = (input) => {
    let dataInput = {
      ...input,
      controlNatureNameTH: input.controlNatureNameTH || '',
      controlNatureNameEN: input.controlNatureNameEN || input.controlNatureNameTH,
      isActive: parseInt(input.isActive),
      action: dataParams?.controlNatureCode ? 'edit' : 'create',
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        saveControlNatureFn({
          variables: {
            input: encryptInput(dataInput),
          },
        })
        form.resetFields()
        setVisible(false)
        props.close(false)
      },
      onCancel() {},
    })
  }

  function onClose(e) {
    form.resetFields()
    setVisible(e)
    props.close(e)
  }
  return (
    <>
      {(dataControlNatureByCode.loading || saveControlNature.loading) && <SpinnersNew />}
      <ModalNew
        visible={visible}
        typeAction={dataParams?.type_action}
        testTitle={
          !dataParams
            ? t('เพิ่มลักษณะการควบคุม')
            : dataParams.type_action === 'edit'
            ? t('เเก้ไขลักษณะการควบคุม')
            : 'ข้อมูลลักษณะการควบคุม'
        }
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        type="medium"
        okButtonProps={dataParams?.type_action === 'view' ? { style: { display: 'none' } } : {}}
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="controlNatureForm"
        >
          <Form.Item
            name="controlNatureCode"
            label={<Label type="tab-header-inactive">{t('รหัสลักษณะการควบคุม')}</Label>}
            rules={[
              { required: true, message: t('กรุณากรอกรหัสลักษณะการควบคุม') },
              {
                pattern: regexEng,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
              },
            ]}
          >
            <InputNew placeholder={t('รหัสลักษณะการควบคุม')} disabled={dataParams ? true : false} maxLength={10} />
          </Form.Item>
          <Form.Item
            name="controlNatureNameTH"
            label={<Label type="tab-header-inactive">{t('ลักษณะการควบคุมภาษาไทย')}</Label>}
            rules={[
              { required: true, message: t('กรุณากรอกลักษณะการควบคุม') },
              {
                pattern: regexEngThai,
                message: t(`กรุณากรอกด้วยภาษาไทยหรือภาษาอังกฤษหรือตัวเลข ก-๙, A-Z, a-z 0-9`),
              },
            ]}
          >
            <InputNew placeholder={t('ลักษณะการควบคุมภาษาไทย')} disabled={disabled} maxLength={100} />
          </Form.Item>
          <Form.Item
            name="controlNatureNameEN"
            label={<Label type="tab-header-inactive">{t('ลักษณะการควบคุมภาษาอังกฤษ')}</Label>}
            rules={[
              {
                pattern: regexEng,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
              },
            ]}
          >
            <InputNew placeholder={t('ลักษณะการควบคุมภาษาอังกฤษ')} disabled={disabled} maxLength={100} />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
            <RadioGroup>
              <Space size={[8, 8]} wrap>
                <Radio value={1} disabled={disabled}>
                  {<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}
                </Radio>
                &nbsp;
                <Radio value={0} disabled={disabled}>
                  {<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}
                </Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
export default ControlNatureModal
