import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useQuery } from '@apollo/client'
import { GET_ADHOCJOB_PERSONNELS_LIST } from '../../graphql/Query'
import { Select } from '../../../../../components/select/Select.jsx'
import { useLanguage } from '../../../../../hooks/useLanguage'
import { displayText } from '../../../../../utilitys/helper'

const SelectResponsible = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props

  const [isData, isSetData] = useState([])
  const [isLang] = useLanguage()

  const getResponsible = useQuery(GET_ADHOCJOB_PERSONNELS_LIST, {
    fetchPolicy: 'no-cache',
    variables: filters ? filters : {},
  })

  useEffect(() => {
    if (getResponsible.data) {
      isSetData(
        _.map(getResponsible?.data?.getAdhocJobPersonnelByCode, (item) => {
          return {
            value: item?.personnelCode,
            label: displayText(item.personnelNameTH, item.personnelNameEN),
            source: item,
          }
        }),
      )
    }
  }, [getResponsible.data, isLang])
  return (
    <>
      <Select
        {...otherProp}
        multiple={props.multiple ? true : false}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getResponsible.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          return handleChange(value)
        }}
      />
    </>
  )
}

export default SelectResponsible
