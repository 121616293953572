import React, { useState, useEffect } from 'react'
import { Row, Col, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { useLanguage } from '../../../hooks/useLanguage'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { Status } from '../../../components/status/Status'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput } from '../../../utilitys/crypto'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import RiskListFilter from './filters/RiskListFilter'
import SendMailModal from './modal/SendMailModal'
import { DELETE_RISK_FORM } from './graphql/Mutation'

const Responsive = { sm: 24, md: 24, lg: 24, xl: 24 }

const RiskList = (props) => {
  const navigate = useNavigate()
  const menu = props.menu
  const { t } = useTranslation()
  const [isLang] = useLanguage()

  const [dataLists, setDataLists] = useState([])
  const [operationStatusCode, setOperationStatusCode] = useState([])
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' },
  ])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [reFetch, setReFetch] = useState(false)
  const [modal, setModal] = useState(false)
  const [riskFormData, setRiskFormData] = useState({})
  const [deleteRiskFormsFn, deleteRiskForms] = useMutation(DELETE_RISK_FORM)

  useEffect(() => {
    if (deleteRiskForms.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          setReFetch(reFetch ? false : true)
        },
      })
    }
    if (deleteRiskForms.error) {
      Alert({
        type: 'error',
        title: t('ข้อมูลถูกนำไปใช้แล้ว'),
        onOk() {},
      })
    }
  }, [deleteRiskForms.data])

  const deleteRiskForm_ = async (riskFormCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk: async () => {
        await deleteRiskFormsFn({
          variables: { input: encryptInput({ riskFormCode: riskFormCode }) },
        })
      },
      onCancel() {},
    })
  }
  const Columns = [
    {
      title: t('ประจำปี'),
      dataIndex: 'Year',
      width: '10%',
      sorter: { multiple: 1 },
    },
    {
      title: t('รหัส'),
      dataIndex: 'riskFormCode',
      sorter: { multiple: 2 },
    },
    {
      title: t('ชื่อแบบประเมิน'),
      dataIndex: 'riskFormName',
      width: '30%',
      sorter: { multiple: 3 },
    },
    {
      title: t('ประเภทประเมิน'),
      dataIndex: 'riskFormType',
      render: (row) => {
        return row === 1 ? (
          <>
            <span>{t('ประเมินสรุป')}</span>
          </>
        ) : (
          <>
            <span>{t('ประเมินรายหน่วยงาน')}</span>
          </>
        )
      },
    },
    {
      title: t('สถานะ'),
      dataIndex: 'operationStatusCode',
      width: '15%',
      render: (d) =>
        d && <Status text={d === '10' ? t('รอดำเนินการ') : d === '20' ? t('กำลังดำเนินการ') : t('เสร็จสิ้น')} />,
    },
    {
      title: '',
      dataIndex: 'operationStatusCodeBtn',
      width: '15%',
      render: (row, record) => {
        const { riskFormCode, oganizationAudit, riskFormTH, dateEnd, dateStart, Year, riskFormType } = record
        const dateStart_ = new Date(dateStart).toLocaleDateString('th-TH', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
        const dateEnd_ = new Date(dateEnd).toLocaleDateString('th-TH', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
        return row === '10' ? (
          <>
            <ButtonNew
              type="flagOutlinedInTableBlue"
              roles={{ type: 'add', menu: menu }}
              onClick={() =>
                Promise.all([
                  setOperationStatusCode(row),
                  setModal(true),
                  setRiskFormData({
                    ...riskFormData,
                    riskFormType: riskFormType,
                    riskFormCode: riskFormCode,
                    organizationCode: oganizationAudit,
                    riskFormName: riskFormTH,
                    Year: Year,
                    dateStart: dateStart_,
                    dateEnd: dateEnd_,
                  }),
                ])
              }
            >
              {t('ส่งแบบประเมิน')}
            </ButtonNew>
          </>
        ) : row === '20' ? (
          <>
            <ButtonNew type="moreHorizontalInTableOrange" roles={{ type: 'edit', menu: menu }} onClick={() => {}}>
              {t('กำลังดำเนินการ')}
            </ButtonNew>
          </>
        ) : (
          <>
            <ButtonNew type="moreHorizontalInTableGray" roles={{ type: 'add', menu: menu }} onClick={() => {}}>
              {t('เสร็จสิ้นการประเมิน')}
            </ButtonNew>
          </>
        )
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '10%',
    },
  ]

  function getDataListFromFilter(value) {
    setDataLists([
      ...value.map((item, idx) => {
        const btn = [
          {
            type: 'copy',
            onClick: () =>
              navigate(`createfirststep`, { state: { riskFormCode: item.riskFormCode, type_action: 'duplicate' } }),
          },
          {
            type: 'view',
            path: '/button/view',
            onClick: () => {
              navigate(`/riskassessment/riskform/createsecondstep`, {
                state: {
                  riskFormCode: item.riskFormCode,
                  type_action: 'view',
                },
              })
            },
          },
          {
            type: 'edit',
            onClick: () => {
              navigate(`/riskassessment/riskform/createsecondstep`, {
                state: {
                  riskFormCode: item.riskFormCode,
                  type_action: 'update',
                },
              })
            },
          },
          {
            type: 'delete',
            onClick: () => deleteRiskForm_(item.riskFormCode),
          },
        ]
        if (item.operationStatusCode !== '10') btn.pop(), btn.pop()
        return {
          ...item,
          key: idx + 1,
          Year: isLang === 'en' ? item.Year : item.Year + 543,
          riskFormCode: item.riskFormCode,
          riskFormName: isLang === 'th' ? item.riskFormTH : item.riskFormEN,
          riskFormType: item.riskFormType,
          operationStatusCode: item.operationStatusCode,
          operationStatusCodeBtn: item.operationStatusCode,
          tools: (
            <>
              <Row justify="end">
                <Col>
                  <ButtonGroup menu={menu} icons={btn} />
                </Col>
              </Row>
            </>
          ),
        }
      }),
    ])
  }

  const breadcrumbList = [
    { label: t('งานประเมินความเสี่ยง'), path: '/riskassessment/riskform' },
    { label: t('แบบประเมินความเสี่ยง') },
  ]

  return (
    <>
      {deleteRiskForms.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('แบบประเมินความเสี่ยง')} />
      <RiskListFilter
        page={page}
        limit={limit}
        menu={menu}
        sort={sort}
        reFetch={reFetch}
        getCount={(e) => setCount(e)}
        getValue={(v) => getDataListFromFilter(v)}
        setPage={(e) => setPage(e)}
        setLimit={(e) => setLimit(e)}
      />
      <CardNew topic={t('รายการแบบประเมินความเสี่ยง')} icon="List" toggledrop={'false'}>
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <Space>
              <ButtonNew
                type="plusTableBorderPrimary"
                roles={{ type: 'add', menu: menu }}
                onClick={() => navigate(`createfirststep`, { state: { riskFormCode: '', type_action: 'create' } })}
              >
                {t('เพิ่มแบบประเมินความเสี่ยง')}
              </ButtonNew>
            </Space>
          </Col>
          <Col {...Responsive}>
            <Datatable
              columns={Columns}
              data={dataLists}
              paginationCustom={true}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
              total={count}
              enableScroll={{ x: true }}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => {
                const reNewField = [
                  { riskFormName: displayText('riskFormTH', 'riskFormEN') },
                  { Year: 'Year' },
                  { riskFormCode: 'riskFormCode' },
                ]
                setSort(handleSort(e?.sorter, reNewField))
              }}
            />
          </Col>
        </Row>
      </CardNew>

      <SendMailModal
        menu={menu}
        riskFormData={riskFormData}
        operationStatusCode={operationStatusCode}
        open={modal}
        onSuccess={(e) => {
          setReFetch(reFetch ? false : true)
          setModal(e)
        }}
        close={(e) => setModal(e)}
      />
    </>
  )
}

export default RiskList
