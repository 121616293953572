import { gql } from '@apollo/client'
export const GET_LIST_RATE_RISK_PROCESS_PAGINATION = gql`
  query GetListRateRiskProcessPagination($input: RateRiskProcessPagination!) {
    getListRateRiskProcessPagination(input: $input) {
      result {
        no
        rateRiskProId
        rateFacProCode
        riskFactorCode
        riskFactorNameTH
        riskFactorNameEN
        rateFacProNameTH
        rateFacProNameEN
        sort
        isActive
      }
      count
      page
      limit
    }
  }
`
