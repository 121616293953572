import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { CardNew } from '../../../components/card/Card'
import { LabelNew } from '../../../components/label/Label'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { Datatable } from '../../../components/datatable/Datatable'
import { colors } from '../../../configs/styles.config'
import { Columns } from './utils/TimeSheetRequestFormColumns'
import { Alert } from '../../../components/alert/Alert'
import { Status } from '../../../components/status/Status.jsx'
import { SpinnersNew } from '../../../components/spinners/Spinners.jsx'
import { GET_TIMESHEET_REQUEST_BY_ID } from './graphql/Query'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { validateURL, displayText, displayError } from '../../../utilitys/helper'
import Files from './components/Files'

const TimeSheetRequestForm = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()

  if (!state || !state.refCode) {
    return <Navigate to={'/auditoperations/timesheet/requestapprove'} />
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/timesheet/requestapprove' },
    { label: t('บันทึกการปฏิบัติงาน'), path: '/auditoperations/timesheet/requestapprove' },
  ]

  const responsiveLabel = { sm: 10, md: 6, lg: 6 }
  const responsiveData = { sm: 14, md: 18, lg: 18 }
  const { refCode } = state
  const [timeSheetRequest, setTimeSheetRequest] = useState({})
  const [dataLists, setDataLists] = useState([])
  const [getFiles, setFiles] = useState([])

  /* ---------------- API ----------- */
  const [
    getTimeSheetRequestFn,
    { loading: loadingTimeSheetRequest, error: errorTimeSheetRequest, data: dataTimeSheetRequest },
  ] = useLazyQuery(GET_TIMESHEET_REQUEST_BY_ID)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (refCode) {
      getTimeSheetRequestFn({
        variables: {
          requestId: encryptInput(refCode),
        },
      })
    }
  }, [refCode])

  useEffect(() => {
    if (dataTimeSheetRequest) {
      const data = dataTimeSheetRequest.getTimeSheetRequestById
      setTimeSheetRequest(data)
      setFiles(
        data.listFile.map((item, key) => {
          return <Files key={key} item={item} index={key} />
        }),
      )
      setDataLists(
        _.map(data.requestDetail, (item, index) => {
          return {
            key: index,
            jobAndProjectName: displayText(item.jobAndProjectNameTH, item.jobAndProjectNameEN) || '-',
            jobTypeName: displayText(item.typeNameTH, item.typeNameEN) || '-',
            urgentName: displayText(item.urgentNameTH, item.urgentNameEN) || '-',
            jobDetail: displayText(item.jobDetailTH, item.jobDetailEN) || '-',
            dateStart: dateDisplay(item.dateStart) || '-',
            dateEnd: dateDisplay(item.dateEnd) || '-',
            approveStatusName: <Status text={displayText(item.approveStatusNameTH, item.approveStatusNameEN)} />,
          }
        }),
      )
    }

    if (errorTimeSheetRequest) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(errorTimeSheetRequest)),
      })
    }
  }, [dataTimeSheetRequest])

  return (
    <>
      {loadingTimeSheetRequest && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('บันทึกการปฏิบัติงาน')} />
      <CardNew topic={t('รายละเอียดใบคำขออนุมัติปฏิบัติงาน')} icon={'AlertCircle'} toggledrop="false">
        <Col span={24} offset={2}>
          <Row gutter={[16, 24]}>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">{t('เลขที่คำขอ')}</LabelNew>
            </Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              <LabelNew type="body-primary">{timeSheetRequest?.requestNumber}</LabelNew>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>
            </Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              <LabelNew type="body-primary">{dateDisplay(timeSheetRequest?.dateStart)}</LabelNew>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>
            </Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              <LabelNew type="body-primary">{dateDisplay(timeSheetRequest?.dateEnd)}</LabelNew>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">{t('ผู้อนุมัติ')}</LabelNew>
            </Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              <LabelNew type="body-primary">
                {displayText(timeSheetRequest.approverNameTH, timeSheetRequest.approverNameEN)}
              </LabelNew>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">{t('ลิงค์ภายนอก')}</LabelNew>
            </Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              <LabelNew type="body-primary">
                {timeSheetRequest?.fileDetail ? (
                  <a
                    href={validateURL(timeSheetRequest?.fileDetail)}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: '#2b4990', fontSize: '14px' }}
                  >
                    <u>{timeSheetRequest?.fileDetail}</u>
                  </a>
                ) : (
                  '-'
                )}
              </LabelNew>
            </Col>
            <Col {...responsiveLabel}>
              <LabelNew type="tab-header-inactive">{t('เอกสารเพิ่มเติม')}</LabelNew>
            </Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              <Col md={20} style={{ display: 'flex' }}>
                {getFiles}
              </Col>
            </Col>
          </Row>
        </Col>
      </CardNew>
      <CardNew topic={t('ดูใบคำขออนุมัติปฏิบัติงานรายวัน')} icon={'Eye'} toggledrop="false">
        <Row>
          <Col md={24}>
            <Datatable
              columns={Columns}
              data={dataLists}
              handleTableChange={() => {}}
              searchCustom={true}
              paginationCustom={false}
              pagination={true}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col md={24} align="center">
            <ButtonNew type="back" onClick={() => navigate('/auditoperations/timesheet/requestapprove')}>
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default TimeSheetRequestForm
