import { gql } from '@apollo/client'

export const CREATE_CONTROL_FORM = gql`
  mutation createControlForm($input: CreateControlFormInput!) {
    createControlForm(input: $input) {
      controlFormId
      controlFormCode
      controlFormTH
      controlFormEN
      controlFormYear
      operationStatusCode
      dateStart
      dateEnd
      isActive
      createdBy
      createdAt
      controlFormOrganizationsList {
        controlFormOrgId
        controlFormCode
        organizationCode
        orgCreatedBy: createdBy
        orgCreatedAt: createdAt
      }
    }
  }
`

export const UPDATE_CONTROL_FORM = gql`
  mutation updateControlForm($input: UpdateControlFormInput!) {
    updateControlForm(input: $input) {
      controlFormCode
    }
  }
`
export const DELETE_CONTROL_FORM = gql`
  mutation delectControlForm($controlFormId: String!) {
    delectControlForm(controlFormId: $controlFormId) {
      controlFormId
    }
  }
`

export const CREATE_CONFIG_QUEST = gql`
  mutation createControlFormConfQuestion($input: CreateControlFormConfigurationInput!) {
    createControlFormConfQuestion(input: $input) {
      controlFormCode
    }
  }
`
export const COPY_CONTROL_FORM = gql`
  mutation copyControlForm($input: UpdateControlFormInput!) {
    copyControlForm(input: $input) {
      controlFormCode
    }
  }
`
export const SEND_EMAIL_CONTROL_FORM = gql`
  mutation sendEmailControlForm($input: SendEmailFormInput!) {
    sendEmailControlForm(input: $input) {
      controlFormCode
    }
  }
`
