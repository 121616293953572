import { gql } from '@apollo/client'
export const GET_LIST_RISK_FACTORS_PAGINATION = gql`
  query GetListRiskFactorsPagination($input: RiskFactorsPagination!) {
    getListRiskFactorsPagination(input: $input) {
      result {
        no
        riskFactorCode
        riskFactorNameTH
        riskFactorNameEN
        riskType
        riskGroup
        riskFormulaCode
        riskFormulaNameTH
        riskFormulaNameEN
        isActive
      }
      count
      page
      limit
    }
  }
`
