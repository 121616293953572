import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { CardNew } from '../../../../../../components/card/Card'
import { useTranslation } from 'react-i18next'

import SelectYear from '../../../../../../components/inputfromapi/selectyear/SelectYear'
import SelectOperationStatus from '../../../../../../components/inputfromapi/selectoperationstatus/SelectOperationStatus'
import SelectRiskTypes from '../../../../../../components/inputfromapi/selectrisktypes/SelectRiskTypes'
import { LabelNew } from '../../../../../../components/label/Label'
import { ButtonNew } from '../../../../../../components/button/Button'
import { Datatable } from '../../../../../../components/datatable/Datatable.jsx'
import { InputNew as Input } from '../../../../../../components/input/Input'
import { GET_AUDITPROJECT_RISKFORM_PAGINATION } from '../../graphql/Query'
import { useLazyQuery } from '@apollo/client'
import * as _ from 'lodash'
import { Radio, RadioGroup } from '../../../../../../components/radio/Radio'
import { encryptInput } from '../../../../../../utilitys/crypto'
import { SpinnersNew } from '../../../../../../components/spinners/Spinners'
import { Alert } from '../../../../../../components/alert/Alert'
import { displayError } from '../../../../../../utilitys/helper'

const ModalGeneralRiskForm = (props) => {
  const { t } = useTranslation()

  const [form] = Form.useForm()
  const [searchStatus, setSearchStatus] = useState(false)

  const onFinish = () => {}
  const onFinishFailed = () => {}
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [count, setCount] = useState(0)

  const [getAuditProjectRiskFormsPagenationFn, getAuditProjectRiskFormsPagenation] = useLazyQuery(
    GET_AUDITPROJECT_RISKFORM_PAGINATION,
  )
  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }
  const callApiSearch = () => {
    getAuditProjectRiskFormsPagenationFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          filters: {
            lang: localStorage.getItem('lang'),
            Year: form.getFieldValue('Year') ? form.getFieldValue('Year') : null,
            riskFormType: form.getFieldValue('riskFormType') ? form.getFieldValue('riskFormType') : null,
            operationStatusCode: form.getFieldValue('operationStatusCode')
              ? form.getFieldValue('operationStatusCode')
              : null,
            riskForm: (
              typeof form.getFieldValue('riskForm') === 'string'
                ? form.getFieldValue('riskForm').trim()
                : form.getFieldValue('riskForm')
            )
              ? typeof form.getFieldValue('riskForm') === 'string'
                ? form.getFieldValue('riskForm').trim()
                : form.getFieldValue('riskForm')
              : null,
          },
          pagination: { limit, page },
          sort: [
            {
              fieldSort: 'riskFormId',
              sortType: 'DESC',
            },
          ],
        }),
      },
    })
  }

  useEffect(() => {
    callApiSearch()
  }, [page, limit])

  useEffect(() => {
    if (searchStatus) {
      callApiSearch()
      setSearchStatus(false)
    }
  }, [searchStatus])

  const columns = [
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('เลือก')}</div>,
      dataIndex: 'select',
      width: '10%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('ประจำปี')}</div>,
      dataIndex: 'Year',
      width: '15%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('รหัส')}</div>,
      dataIndex: 'riskFormCode',
      width: '15%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('ชื่อแบบประเมิน')}</div>,
      dataIndex: 'riskForm',
      width: '20%',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('ประเภทประเมิน')}</div>,
      dataIndex: 'riskFormType',
      width: '20%',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('สถานะ')}</div>,
      dataIndex: 'operationStatusName',
      width: '20%',
    },
  ]

  useEffect(() => {
    if (getAuditProjectRiskFormsPagenation?.data && props.modalRiskForm) {
      let response = getAuditProjectRiskFormsPagenation?.data?.getAuditProjectRiskFormsPagenation

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            select: (
              <>
                <Radio
                  value={data.riskFormCode}
                  onClick={() => {
                    props.setRiskFormBuffer({
                      code: data.riskFormCode,
                      name: localStorage.getItem('lang') === 'th' ? data.riskFormTH : data.riskFormEN,
                    })
                  }}
                ></Radio>
              </>
            ),
            key: data.riskFormCode,
            Year: localStorage.getItem('lang') === 'th' ? parseInt(data.Year) + 543 : data.Year,
            riskFormCode: data.riskFormCode,
            riskForm: localStorage.getItem('lang') === 'th' ? data.riskFormTH : data.riskFormEN,
            riskFormType: data.riskFormType === 1 ? 'ประเมินสรุป' : 'ประเมินรายหน่วยงาน',
            operationStatusName:
              localStorage.getItem('lang') === 'th' ? data.operationStatusNameTH : data.operationStatusNameEN,
          }
        }),
      )
    }
    if (getAuditProjectRiskFormsPagenation.error) {
      showAlertLoadDataError(displayError(getAuditProjectRiskFormsPagenation.error))
    }
  }, [props.modalRiskForm, props.riskForm, getAuditProjectRiskFormsPagenation?.data])

  useEffect(() => {
    if (props.modalRiskForm && dataLists.length !== 0) {
      if (props.riskFormBuffer.code) {
        form.setFieldsValue({ riskFormSelect: props.riskFormBuffer.code })
      } else {
        if (props.riskForm.code) {
          form.setFieldsValue({ riskFormSelect: props.riskForm.code })
        } else {
          form.setFieldsValue({ riskFormSelect: null })
        }
      }
    }
  }, [props.modalRiskForm, dataLists, props.riskFormBuffer, props.riskForm])
  return (
    <>
      {getAuditProjectRiskFormsPagenation.loading && <SpinnersNew />}
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        name="filterRiskForm"
        layout="vertical"
      >
        <Row>
          <Col md={24}>
            <CardNew topic={t('Filters')} toggledrop="false">
              <Row gutter={[12, 12]}>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item name="Year" label={<LabelNew type="tab-header-inactive">{t('ประจำปี')}</LabelNew>}>
                    <SelectYear formname="filterRiskForm" placeholder={t('เลือกปี')} handleChange={() => {}} />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="riskForm"
                    label={<LabelNew type="tab-header-inactive">{t('รหัสหรือชื่อแบบประเมินควบคุมภายใน')}</LabelNew>}
                    rules={[
                      {
                        max: 200,
                        message: t('กรุณาระบุรหัสหรือชื่อแบบประเมินควบคุมภายในน้อยกว่าหรือเท่ากับ 200 ตัวอักษร'),
                      },
                    ]}
                  >
                    <Input maxLength={200} placeholder={t('ระบุรหัสหรือชื่อแบบประเมินควบคุมภายใน')} />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="riskFormType"
                    label={<LabelNew type="tab-header-inactive">{t('ประเภทการประเมิน')}</LabelNew>}
                  >
                    <SelectRiskTypes
                      formname="filterRiskForm"
                      placeholder={t('เลือกประเภทการประเมิน')}
                      handleChange={() => {}}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="operationStatusCode"
                    label={<LabelNew type="tab-header-inactive">{t('สถานะดำเนินการ')}</LabelNew>}
                  >
                    <SelectOperationStatus
                      filters={{ isActive: 1 }}
                      needOperationStatusCode={true}
                      formname="filterRiskForm"
                      placeholder={t('เลือกสถานะดำเนินการ')}
                      handleChange={() => {}}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew
                    type="search"
                    onClick={() => {
                      if (
                        form.getFieldsError().filter((item) => {
                          return item.errors.length !== 0
                        }).length === 0
                      ) {
                        setSearchStatus(true)
                      }
                    }}
                    htmlType="button"
                  >
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew type="clear" onClick={() => form.resetFields()}>
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </CardNew>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <CardNew topic={''} toggledrop="false">
              <Form.Item name="riskFormSelect">
                <RadioGroup>
                  <Datatable
                    columns={columns}
                    data={dataLists}
                    handleTableChange={() => {}}
                    paginationCustom={true}
                    onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                    searchCustom={true}
                    total={count}
                    enableScroll={{ x: true, y: true }}
                    scroll={{ x: '100%', y: false }}
                    pageSize={limit}
                    page={page}
                    btnAdd={<></>}
                  />
                </RadioGroup>
              </Form.Item>
            </CardNew>
          </Col>
        </Row>
      </Form>
    </>
    // okButtonProps={typeForm === 'view' ? { style: { display: 'none' } } : {}}
  )
}

export default ModalGeneralRiskForm
