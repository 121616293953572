import { Trans } from 'react-i18next'
export const Columns = [
  {
    title: <Trans>ลำดับที่</Trans>,
    dataIndex: 'key',
    width: '100px',
    align: 'center',
  },
  {
    title: <Trans>ชื่องาน/โครงการ</Trans>,
    dataIndex: 'jobAndProjectName',
    width: '250px',
    sorter: { multiple: 1 },
  },
  {
    title: <Trans>ลักษณะงาน</Trans>,
    dataIndex: 'jobGroupName',
    width: '150px',
    sorter: { multiple: 2 },
  },
  {
    title: <Trans>ประเภทงาน/โครงการ</Trans>,
    dataIndex: 'typeName',
    width: '250px',
    sorter: { multiple: 3 },
  },
  {
    title: <Trans>รายละเอียดงาน</Trans>,
    dataIndex: 'jobDetail',
    width: '300px',
    sorter: { multiple: 4 },
  },
  {
    title: <Trans>วันที่เริ่มต้น</Trans>,
    dataIndex: 'dateStart',
    width: '150px',
    sorter: { multiple: 5 },
  },
  {
    title: <Trans>เวลาเริ่มต้น</Trans>,
    dataIndex: 'timeStart',
    width: '150px',
    sorter: { multiple: 6 },
  },
  {
    title: <Trans>วันที่สิ้นสุด</Trans>,
    dataIndex: 'dateEnd',
    width: '150px',
    sorter: { multiple: 7 },
  },
  {
    title: <Trans>เวลาสิ้นสุด</Trans>,
    dataIndex: 'timeEnd',
    width: '150px',
    sorter: { multiple: 8 },
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'jobStatusName',
    width: '200px',
    sorter: { multiple: 9 },
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '100px',
  },
]
