import { gql } from '@apollo/client'

export const GET_LOGFILE_LOGIN_PAGINATION = gql`
  query logfileLoginPaginationGet($input: LogfileLoginPaginationInput!) {
    logfileLoginPaginationGet(input: $input) {
      result {
        logfileLoginId
        userId
        menuCode
        createdAt
        createdBy

        username
        email
        loginDate
        logoutDate
        fullname
      }
      count
      page
      limit
    }
  }
`
