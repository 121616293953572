import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Space } from 'antd'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { ButtonNew } from '../../../../components/button/Button'
import SelectSystemMenus from '../../../../components/inputfromapi/selectsystemmenus/SelectSystemMenus.jsx'
import { Select } from '../../../../components/select/Select'
import { Alert } from '../../../../components/alert/Alert'
import { CardNew } from '../../../../components/card/Card'
import { Datepicker } from '../../../../components/datepicker/Datepicker'
import { encryptInput } from '../../../../utilitys/crypto'
import { LabelNew } from '../../../../components/label/Label'
import { displayError } from '../../../../utilitys/helper'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { GET_SERACH_SETUP_REPORT_SUB_MISSIONS_LIST } from '../graphql/Query'

const SetupReportSubMissionsReportFilter = (props) => {
  const { limit, page, getCount, getValue, reFetch, sort, menu } = props
  const { t } = useTranslation()
  const isLang = localStorage.getItem('lang')
  const [form] = Form.useForm()
  const formName = 'filter'
  const Responsive = {
    md: 8,
    lg: 8,
    btn: {
      md: 24,
      lg: 24,
    },
  }

  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [filters, setFilters] = useState({})
  const [getSerachSetupReportSubMissionsListFn, getSerachSetupReportSubMissionsList] = useLazyQuery(
    GET_SERACH_SETUP_REPORT_SUB_MISSIONS_LIST,
  )
  useEffect(() => {
    if (isLang) {
      getSerachSetupReportSubMissionsListFn({
        variables: {
          input: {
            filters: encryptInput({
              ...filters,
            }),
            pagination: { limit, page },
            sort: sort,
          },
        },
      })
    }
  }, [page, limit, isLang, reFetch, sort, filters])
  useEffect(() => {
    if (getSerachSetupReportSubMissionsList.data) {
      const res_ = getSerachSetupReportSubMissionsList?.data?.getSerachSetupReportSubMissionsList
      getCount(res_?.count)
      getValue(res_?.result)
    }

    if (getSerachSetupReportSubMissionsList.error) {
      const err = getSerachSetupReportSubMissionsList.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [getSerachSetupReportSubMissionsList.data])

  const onFinish = async (value) => {
    const localFilter = {
      menuCode: value?.menuCode || '',
      sendStatus: value?.sendStatus === 0 ? '0' : value?.sendStatus ? String(value?.sendStatus) : '',
      dateStart: value?.dateStart || '',
      dateEnd: value?.dateEnd || '',
      lang: isLang,
    }
    setFilters({
      ...localFilter,
    })
  }
  return (
    <>
      {getSerachSetupReportSubMissionsList.loading && <SpinnersNew />}
      <Row style={{ marginBottom: 24 }}>
        <Col sm={24} md={24} lg={24}>
          <CardNew topic={t('Filters')} icon="List" toggledrop={'true'}>
            <Form form={form} onFinish={onFinish} name={formName} layout="vertical">
              <Row gutter={[12, 12]}>
                <Col {...Responsive}>
                  <Form.Item name={'menuCode'} label={<LabelNew type="tab-header-inactive">{t('รายงาน')}</LabelNew>}>
                    <SelectSystemMenus
                      formname={formName}
                      placeholder={t('เลือกรายงาน')}
                      filters={{ isActive: '1' }}
                      handleChange={(e) => {
                        form.setFieldsValue({ menuCode: e?.value })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'dateStart'}
                    label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                    // rules={[{ required: true, message: t('กรุณาระบุวันที่เริ่มต้น') }]}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        setIsMinDate(data)
                        form.setFieldsValue({ dateStart: await data })
                      }}
                      onClear={() => {
                        form.setFieldsValue({ dateStart: null })
                      }}
                      isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'dateEnd'}
                    label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                    // rules={[{ required: true, message: t('กรุณาระบุวันที่สิ้นสุด') }]}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        setIsMaxDate(data)
                        form.setFieldsValue({ dateEnd: await data })
                      }}
                      onClear={() => {
                        form.setFieldsValue({ dateEnd: null })
                      }}
                      isMinDate={isMinDate ? new Date(isMinDate) : null}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name="sendStatus"
                    label={<LabelNew type="tab-header-inactive">{t('สถานะการส่ง')}</LabelNew>}
                  >
                    <Select
                      placeholder={t('เลือกสถานะการส่ง')}
                      scrollableId={formName}
                      onChange={(e) => {
                        form.setFieldsValue({
                          sendStatus: e,
                        })
                      }}
                      data={[
                        { value: 1, label: t('ส่งแล้ว') },
                        { value: 0, label: t('ยังไม่ได้ส่ง') },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col {...Responsive.btn} align="center">
                  <Space size={[8, 8]} wrap>
                    <ButtonNew type="search" roles={{ type: 'view', menu: menu }} htmlType="submit">
                      {t('ค้นหา')}
                    </ButtonNew>
                    <ButtonNew
                      type="clear"
                      onClick={() => {
                        form.resetFields()
                        setFilters({})
                        getCount(0)
                        getValue([])
                      }}
                    >
                      {t('ล้างค่า')}
                    </ButtonNew>
                  </Space>
                </Col>
              </Row>
            </Form>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default SetupReportSubMissionsReportFilter
