import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { Datatable } from '../../../../components/datatable/Datatable'
import { useTranslation } from 'react-i18next'

import { ButtonNew } from '../../../../components/button/Button'
import { ModalNew } from '../../../../components/modal/Modal'
import ModalGeneralOrganization from './modal/ModalGeneralOrganization'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'

const FormAuditProjectsGeneralOrganizations = (props) => {
  const { t } = useTranslation()

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [organizationsBuffer, setOrganizationsBuffer] = useState([])
  const [modalOrganizationsSubMit, setModalOrganizationsSubMit] = useState(false)

  useEffect(() => {
    setCount(props.organizations.length)
    setDataLists(
      props.organizations
        .filter((item, i) => {
          item = ''
          return i >= (page - 1) * limit && i <= page * limit - 1
        })
        .map((item, i) => {
          return {
            key: i,
            no: (page - 1) * limit + i + 1,
            organizationCode: item.organizationCode,
            organizationName: item.organizationName,
            organizationLevelName: item.organizationLevelName,
            personnel: item.personnels,
            action: (
              <div style={{ textAlign: 'center' }}>
                <ButtonGroup
                  disabled={props.typeForm === 'view' ? true : false}
                  menu={props.menu}
                  icons={[
                    {
                      type: 'delete',
                      onClick: () => {
                        props.setOrganizations(
                          props.organizations.filter((subItem) => {
                            return subItem.organizationCode !== item.organizationCode
                          }),
                        )
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
    )
  }, [props.organizations, page, limit])
  const [modalOrganizations, setModalOrganizations] = useState(false)
  useEffect(() => {
    if (modalOrganizationsSubMit) {
      props.setOrganizations(organizationsBuffer)
      setModalOrganizationsSubMit(false)
    }
  }, [modalOrganizationsSubMit])
  useEffect(() => {
    setOrganizationsBuffer(props.organizations)
  }, [props.organizations])
  const columns = [
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('ลำดับ')}</div>,
      dataIndex: 'no',
      width: '10%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('รหัสหน่วยงาน')}</div>,
      dataIndex: 'organizationCode',
      width: '15%',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('หน่วยงาน')}</div>,
      dataIndex: 'organizationName',
      width: '25%',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('ระดับหน่วยงาน')}</div>,
      dataIndex: 'organizationLevelName',
      width: '20%',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('ผู้รับผิดชอบ')}</div>,
      dataIndex: 'personnel',
      width: '20%',
    },
    {
      title: '',
      dataIndex: 'action',
      width: '10%',
    },
  ]
  return (
    <>
      <Row>
        <Col md={24}>
          <Datatable
            columns={columns}
            data={dataLists}
            handleTableChange={() => {}}
            paginationCustom={true}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            searchCustom={true}
            total={count}
            enableScroll={{ x: true, y: true }}
            scroll={{ x: '100%', y: false }}
            pageSize={limit}
            page={page}
            btnAdd={
              <>
                <ButtonNew
                  type="plusTableBorderPrimary"
                  roles={{ type: props.typeForm, menu: props.menu }}
                  style={{ marginRight: '12px' }}
                  onClick={() => {
                    setModalOrganizations(true)
                  }}
                  disabled={props.typeForm === 'view' ? true : false}
                >
                  {t('เพิ่มหน่วยรับตรวจ')}
                </ButtonNew>
              </>
            }
          />
        </Col>
      </Row>
      <ModalNew
        visible={modalOrganizations}
        onSubmit={() => {}}
        onOk={() => {
          setModalOrganizationsSubMit(true)
          setModalOrganizations(false)
        }}
        onCancel={() => {
          setModalOrganizations(false)
        }}
        onClose={setModalOrganizations}
        testTitle={t('หน่วยรับตรวจ')}
        btnName={t('เพิ่ม')}
        type="big"
      >
        <ModalGeneralOrganization
          organizations={props.organizations}
          organizationsBuffer={organizationsBuffer}
          setOrganizationsBuffer={setOrganizationsBuffer}
          type={props.typeForm}
          modalOrganizations={modalOrganizations}
        />
      </ModalNew>
    </>
    // okButtonProps={typeForm === 'view' ? { style: { display: 'none' } } : {}}
  )
}

export default FormAuditProjectsGeneralOrganizations
