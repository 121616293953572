import { gql } from '@apollo/client'

export const GET_APPROVE_STATUS = gql`
  query getMasterApproveStatus($data: MasterApproveStatusInput!) {
    getMasterApproveStatus(data: $data) {
      approveStatusId
      approveStatusCode
      approveStatusNameTH
      approveStatusNameEN
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`
