import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form } from 'antd'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../../../components/breadcrumb/Breadcrumb'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { CardNew } from '../../../../../components/card/Card'
import { InputNew } from '../../../../../components/input/Input'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { ButtonNew } from '../../../../../components/button/Button'
import { Columns } from '../../utils/PersonnelColumns'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { Alert } from '../../../../../components/alert/Alert'
import { encryptInput } from '../../../../../utilitys/crypto'
import SelectOrganization from '../../../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectCertificates from '../../../../../components/inputfromapi/selectcertificates/SelectCertificates'
import SelectPersonnelPositions from '../../../../../components/inputfromapi/selectpersonnelpositions/SelectPersonnelPositions'
import { GET_PERSONNELS_LIST } from '../../graphql/Query'
import { displayText, displayError } from '../../../../../utilitys/helper'

const AdhocjobPersonnels = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { state } = useLocation()

  if (!state) {
    return <Navigate to={'/auditoperations/adhocjob'} />
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/adhocjob' },
    { label: t('งานมอบหมาย') /* path: '/auditoperations/adhocjob' */ },
    { label: t('เลือกผู้รับผิดชอบ') },
  ]

  const Responsive = {
    md: 8,
    lg: 8,
  }
  const ResponsiveTable = {
    md: 24,
    lg: 24,
  }
  const [dataLists, setDataLists] = useState([])
  const [selectPersonnelList, setSelectPersonnelList] = useState([])

  /* ---------------- API ----------- */
  const [getPersonnelListFn, dataPersonnelList] = useLazyQuery(GET_PERSONNELS_LIST)

  const onFinish = (input) => {
    getPersonnelListFn({
      variables: {
        input: encryptInput({
          personnelCode: input.personnelCode || '',
          organizationCode: input.organizationCode || '',
          personnelName: input.personnelName || '',
          certificateCode: input.certificateCode || '',
          positionCode: input.positionCode || '',
        }),
      },
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows) {
        setSelectPersonnelList(
          _.map(selectedRows, (item) => {
            return {
              personnelCode: item.personnelCode,
              personnelName: item.personnelName,
              organization: item.organization,
              position: item.position,
            }
          }),
        )
      }
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      // Column configuration not to be checked
      name: record.name,
    }),
  }

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    getPersonnelListFn({
      variables: {
        input: encryptInput({ personnelCode: '', organizationCode: '', personnelName: '' }),
      },
    })
  }, [])

  useEffect(() => {
    if (dataPersonnelList?.data) {
      let personnelList = dataPersonnelList?.data?.getPersonnelsList
      setDataLists(
        _.map(personnelList, (data, index) => {
          return {
            key: index + 1,
            personnelCode: data.personnelCode,
            personnelName:
              displayText(data.personnelNameTH, data.personnelNameEN) || data.personnelNameTH || data.personnelNameEN,
            organization:
              displayText(data.organizationNameTH, data.organizationNameEN) ||
              data.organizationNameTH ||
              data.organizationNameEN,
            position:
              displayText(data.positionNameTH, data.positionNameEN) || data.positionNameTH || data.positionNameEN,
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'calendar',
                      onClick: () =>
                        navigate(`/auditoperations/adhocjob/tab/responsible/personnels/calendar`, {
                          state: {
                            ...state,
                            personnelCode: data.personnelCode,
                            personnelName: displayText(
                              data.firstNameTH + ' ' + data.lastNameTH,
                              data.firstNameEN + ' ' + data.lastNameEN,
                            ),
                          },
                        }),
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )

      if (personnelList.length === 0) {
        Alert({
          type: 'error',
          title: t('ไม่พบข้อมูล'),
          onOk() {},
        })
      }
    }

    if (dataPersonnelList.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataPersonnelList.error)),
        onOk() {},
      })
    }
  }, [dataPersonnelList.data])

  return (
    <>
      {dataPersonnelList.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('ผู้รับผิดชอบ')} />
      <CardNew topic={t('Filters')} icon="List" toggledrop="true" buttontopright="true">
        <Form name="form-search" form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[16, 8]}>
            <Col {...Responsive}>
              <Form.Item name={'personnelCode'} label={<Label type="tab-header-inactive">{t('รหัสบุคลากร')}</Label>}>
                <InputNew placeholder={t('ระบุรหัสบุคลากร')} maxLength={10} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name={'personnelName'} label={<Label type="tab-header-inactive">{t('ชื่อนามสกุล')}</Label>}>
                <InputNew placeholder={t('ระบุชื่อนามสกุล')} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="organizationCode" label={<Label type="tab-header-inactive">{t('หน่วยงาน')}</Label>}>
                <SelectOrganization
                  placeholder={t('เลือกหน่วยงาน')}
                  formname={'form-search'}
                  filters={{ /* organizationChecked: 1, */ isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ organizationCode: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="positionCode" label={<Label type="tab-header-inactive">{t('ตำแหน่ง')}</Label>}>
                <SelectPersonnelPositions
                  formname={'form-search'}
                  placeholder={t('เลือกตำแหน่ง')}
                  filters={{ /* organizationChecked: 1, */ isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ positionCode: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="certificateCode" label={<Label type="tab-header-inactive">{t('วุฒิบัตร')}</Label>}>
                <SelectCertificates
                  formname={'form-search'}
                  placeholder={t('เลือกวุฒิบัตร')}
                  handleChange={(e) => form.setFieldsValue({ certificateCode: e?.value })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <ButtonNew type="search" htmlType="submit">
                {t('ค้นหา')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew type="clear" onClick={() => form.resetFields()}>
                {t('ล้างค่า')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </CardNew>

      <CardNew topic={t('รายการบุคลากร')} icon="List" toggledrop="false">
        <Row gutter={[12, 12]}>
          <Col {...ResponsiveTable}>
            <Datatable
              columns={Columns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              data={dataLists}
              size="middle"
              scroll={{ x: '100%', y: false }}
              handleTableChange={() => {}}
              searchCustom={true}
              paginationCustom={false}
              pagination={true}
            />
          </Col>
        </Row>
        <Row gutter={[12, 20]} justify="center">
          <Col>
            <ButtonNew
              type="primary"
              htmlType="submit"
              onClick={() =>
                navigate('/auditoperations/adhocjob/tab/responsible', {
                  state: {
                    // type_action: 'edit',
                    // auditTeamCode: auditTeamCode,
                    // personnelList: personnelList,
                    ...state,
                    selectPersonnelList: selectPersonnelList,
                  },
                })
              }
            >
              {t('เพิ่ม')}
            </ButtonNew>
          </Col>
          <Col>
            <ButtonNew
              type="back"
              onClick={() =>
                navigate('/auditoperations/adhocjob/tab/responsible', {
                  state: { ...state, selectPersonnelList: selectPersonnelList },
                })
              }
            >
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default AdhocjobPersonnels
