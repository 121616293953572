import React, { useState, useEffect } from 'react'
import { Form, Input, Card, Row, Col, Cascader, TreeSelect, Switch, Space } from 'antd'
// import moment from 'moment'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { ButtonNew } from '../../../components/button/Button'
import { InputNew, InputNumberNew } from '../../../components/input/Input'
import { Select } from '../../../components/select/Select'
import { Checkbox } from '../../../components/checkbox/Checkbox'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
import { FormExampleHorizontal } from './text'
const dataSelect = [
  { label: 'Jack', value: '01' },
  { label: 'Lucy', value: '02' },
  { label: 'Adam', value: '03' },
  { label: 'test4', value: '04', disabled: true }, // option disable
  { label: 'test5', value: '05' },
  { label: 'test6', value: '06' },
]
const FormExampleNew = (props) => {
  const formId = props.formId || null // Get id form parent element
  const [formType, setFormType] = useState(formId ? 'UPDATE' : 'CREATE') // Intitial value formType
  const [form] = Form.useForm()
  useEffect(() => {
    // Get value From your API
    if (formType === 'UPDATE') {
      // Set Form value on formType = UPDATE
      form.setFieldsValue({
        textInput: 'Is update',
        select: 'demo',
        datePicker: '2020-08-12',
        treeSelect: {
          value: 'bamboo',
        },
        selectMultiple: ['02', '05'],
        cascader: ['zhejiang', 'hangzhou'],
        inputNumber: 100,
        switch: true,
        checkbox: true,
      })
    } else if (formType === 'CREATE') {
      // Set Form value on formType = CREATE
      form.resetFields()
    }
  }, [formType])
  // onFinish
  const onFinish = (values) => {
    console.log('onFinish:', values)
    setErrorField('datePickerMockUp', false) // Set false error field
  }
  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => {
    console.log('onFinishFailed', errorFields)
    errorFields.map((er) => {
      const nameField = er.name[0] || ''
      const message = er.errors[0] || ''
      if (nameField === 'datePicker') setErrorField('datePickerMockUp', message)
    })
  }
  // onValuesChange
  const onValuesChange = ({ formType }, allValue) => {
    console.log('onValuesChange allValue', allValue)
    setFormType(formType) // CREATE or UPDATE *** intitial value = CREATE
  }

  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  return (
    <>
      <Row style={{ marginTop: 24 }}>
        <Col md={24} lg={24}>
          <Card title={`Form example ${formType}`} extra={<a href="#">More</a>} style={{ width: '100%' }}>
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
              autoComplete="off"
              labelAlign="left"
              labelCol={{
                // sm: 8,
                // md: 8,
                // xl: 5,
                md: {
                  span: 8,
                  offset: 2,
                },
              }}
              wrapperCol={{
                sm: 16,
                md: 16,
                xl: 8,
                align: 'left',
              }}
              layout="horizontal"
              name="exampleForm"
            >
              <Form.Item label="Form type" name="formType">
                <RadioGroup>
                  <Radio value="CREATE">CREATE</Radio>
                  <Radio value="UPDATE">UPDATE</Radio>
                </RadioGroup>
              </Form.Item>
              <Form.Item name="textInput" label="Text input" rules={[{ required: true, message: 'required' }]}>
                <InputNew />
              </Form.Item>
              <Form.Item name="select" label="Select" rules={[{ required: true, message: 'required' }]}>
                <Select
                  title=""
                  data={dataSelect}
                  placeholder="default select..."
                  onChange={(val) => console.log(val)}
                  scrollableId="exampleForm"
                />
              </Form.Item>
              {/* <Form.Item name="selectAutocomplete" label="Select Autocomplete" rules={[{ required: true, message: 'required' }]}>
                <Select
                  title=""
                  data={dataSelect}
                  showSearch
                  placeholder="default select..."
                  onChange={(val) => console.log(val)}
                  scrollableId="exampleForm"
                />
              </Form.Item> */}
              <Form.Item
                name="selectMultiple"
                label="Select Multiple"
                rules={[{ required: true, message: 'required' }]}
              >
                <Select
                  title={''}
                  data={dataSelect}
                  placeholder="multiple select..."
                  multiple
                  onChange={(val) => console.log(val)}
                  scrollableId="exampleForm"
                />
              </Form.Item>
              <Form.Item name="treeSelect" label="TreeSelect" rules={[{ required: true, message: 'required' }]}>
                <TreeSelect
                  treeData={[
                    {
                      title: 'Light',
                      value: 'light',
                      children: [
                        {
                          title: 'Bamboo',
                          value: 'bamboo',
                        },
                      ],
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="cascader" label="Cascader" rules={[{ required: true, message: 'required' }]}>
                <Cascader
                  options={[
                    {
                      value: 'zhejiang',
                      label: 'Zhejiang',
                      children: [
                        {
                          value: 'hangzhou',
                          label: 'Hangzhou',
                        },
                      ],
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                hidden={true}
                label="DatePicker"
                name="datePicker"
                rules={[{ required: true, message: 'required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="datePickerMockUp"
                label="DatePicker"
                initialValue={'datePickerMockUp'}
                rules={[{ required: true, message: 'required' }]}
              >
                <>
                  <Datepicker
                    language="th"
                    // value={
                    //   formType === 'UPDATE'
                    //     ? moment(new Date('2020-02-01')).format('YYYY-MM-DD')
                    //     : moment(new Date()).format('YYYY-MM-DD')
                    // }
                    // value={'11-02-2022'}
                    setValueDateFn={(data) => {
                      form.setFieldsValue({
                        datePicker: data,
                      })
                      setErrorField('datePickerMockUp', false) // set false error field
                    }}
                  />
                </>
              </Form.Item>
              <Form.Item name="inputNumber" label="InputNumber" rules={[{ required: true, message: 'required' }]}>
                <InputNumberNew min={1} max={10} />
              </Form.Item>
              <Form.Item
                label="Switch"
                name="switch"
                valuePropName="checked"
                rules={[{ required: true, message: 'required' }]}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="checkbox"
                name="checkbox"
                valuePropName="checked"
                rules={[{ required: true, message: 'required' }]}
              >
                <Checkbox onChange={(val) => console.log(val)}>Remember me</Checkbox>
              </Form.Item>
              <Form.Item label={<></>}>
                <>
                  <Space size={[8, 8]} wrap>
                    <ButtonNew type="primary" roles={{ type: 'add', menu: 'example' }} htmlType="submit">
                      บันทึก
                    </ButtonNew>
                    &nbsp;
                    <ButtonNew type="back" onClick={() => form.resetFields()}>
                      ยกเลิก
                    </ButtonNew>
                  </Space>
                </>
              </Form.Item>
            </Form>
            <Row>
              <Col md={24}>
                {props.disabledCode ? null : (
                  <>
                    <CodeBlock code={FormExampleHorizontal} />
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FormExampleNew
