import { Trans } from 'react-i18next'
export const Column = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'no',
    width: '50px',
    className: 'text-nowrap',
    fixed: 'left',
  },
  {
    title: <Trans>ผู้ใช้งาน</Trans>,
    dataIndex: 'username',
    sorter: true,
    width: '100px',
    className: 'text-nowrap',
    fixed: 'left',
  },
  {
    title: <Trans>รหัสบุคลากร</Trans>,
    dataIndex: 'personnelCode',
    sorter: true,
    width: '120px',
    className: 'text-nowrap',
    fixed: 'left',
  },
  {
    title: <Trans>ชื่อ-นามสกุล</Trans>,
    dataIndex: 'fullName',
    sorter: true,
    width: '200px',
    className: 'text-nowrap',
  },
  {
    title: <Trans>Email</Trans>,
    dataIndex: 'email',
    // sorter: true,
    width: '250px',
    className: 'text-nowrap',
  },
  {
    title: <Trans>หน่วยงาน</Trans>,
    dataIndex: 'organizationName',
    sorter: true,
    width: '200px',
    className: 'text-nowrap',
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActiveName',
    sorter: true,
    width: '100px',
    className: 'text-nowrap',
  },
  {
    title: <Trans>หมายเหตุ</Trans>,
    dataIndex: 'remark',
    sorter: true,
    width: '150px',
    className: 'text-nowrap',
  },
  {
    title: '',
    dataIndex: 'sendEmail',
    // sorter: true,
    width: '50px',
    className: 'text-nowrap',
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '100px',
    align: 'right',
  },
]
