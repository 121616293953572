import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { useLazyQuery, useMutation } from '@apollo/client'
import * as _ from 'lodash'
import { Datatable } from '../../../../components/datatable/Datatable.jsx'
import { Status } from '../../../../components/status/Status'
import { Alert } from '../../../../components/alert/Alert'
import { ButtonNew } from '../../../../components/button/Button'
import TabTwoModal from '../modal/TabTwoModal.jsx'
import { Columns } from '../utils/TabTwoColumns'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../../components/spinners/Spinners.jsx'
import { GET_EMAIL_SEND_SETUP_TABTWO_PAGINATION } from '../graphql/Query'
import { MASTER_SET_EMAIL_DELETE } from '../graphql/Mutation'
import { encryptInput } from '../../../../utilitys/crypto'
import { handleSort } from '../../../../utilitys/pagination'
import { displayError } from '../../../../utilitys/helper'

const TabTwo = (props) => {
  const { t } = useTranslation()
  const { menu } = props

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState(false)
  const [sort, setSort] = useState([])
  const [objData, setObjData] = useState({
    type: 'add',
    setMailId: 0,
  })

  const [cellPagination, { data: dataPagination, loading: loadingPagination, error: errorPagination }] = useLazyQuery(
    GET_EMAIL_SEND_SETUP_TABTWO_PAGINATION,
  )

  const [cellDelete, { data: dataDelete, loading: loadingDelete, error: errorDelete }] =
    useMutation(MASTER_SET_EMAIL_DELETE)

  const fnConfirm = (setMailId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        let variables = encryptInput({ setMailId: setMailId })
        cellDelete({
          variables,
        })
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบข้อมูลสำเร็จ'),
        onOk() {
          cellPagination({
            variables: {
              input: encryptInput({
                filters: {},
                pagination: { limit, page },
                sort: sort,
              }),
            },
          })
        },
      })
    }
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถลบข้อมูลได้`),
        content: t(displayError(errorDelete)),
        onOk() {},
      })
    }
  }, [dataDelete])

  useEffect(() => {
    if (status) {
      cellPagination({
        variables: {
          input: encryptInput({
            filters: {},
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
      setStatus(false)
    }
  }, [status])

  useEffect(() => {
    cellPagination({
      variables: {
        input: encryptInput({
          filters: {},
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (dataPagination) {
      let response = dataPagination.emalSendSetUpPaginationTabTwoGet
      setCount(response.count)
      setDataLists(
        _.map(response.result, (item) => {
          return {
            key: item.no,
            time: item.time,
            dueDate: item.dueDate,
            isActive: <Status text={item.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => {
                        setVisible(true)
                        setObjData({ type: 'view', setMailId: item.setMailId })
                      },
                    },
                    {
                      type: 'edit',
                      onClick: () => {
                        setVisible(true)
                        setObjData({ type: 'edit', setMailId: item.setMailId })
                      },
                    },
                    {
                      type: 'delete',
                      onClick: async () => {
                        fnConfirm(item.setMailId)
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (errorPagination) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorPagination)),
        onOk() {},
      })
    }
  }, [dataPagination])

  return (
    <>
      {(loadingPagination || loadingDelete) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Datatable
            columns={Columns}
            data={dataLists}
            handleTableChange={(e) => {
              setSort(handleSort(e?.sorter))
            }}
            paginationCustom={true}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            searchCustom={true}
            total={count}
            // enableScroll={{ x: true, y: true }}
            pageSize={limit}
            page={page}
            btnAdd={
              <>
                <ButtonNew
                  type="plusTableBorderPrimary"
                  roles={{ type: 'add', menu: props.menu }}
                  style={{ marginRight: '12px' }}
                  onClick={() => {
                    setObjData({ type: 'add' })
                    setVisible(true)
                  }}
                >
                  {t('เพิ่มการแจ้งเตือนตามการติดตาม')}
                </ButtonNew>
              </>
            }
          />
        </Col>
      </Row>
      <TabTwoModal open={visible} close={(e) => setVisible(e)} objData={objData} setStatus={setStatus} />
    </>
  )
}

export default TabTwo
