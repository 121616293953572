import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col, Row, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { GET_LIST_AUDIT_DOCUMENT_FILES_PAGINATION } from './graphql/Query'
import { CardNew } from '../../../components/card/Card'
import { ButtonNew } from '../../../components/button/Button'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { AuditDocumentListCrumb } from './utils/constants'
import { displayText, displayError } from '../../../utilitys/helper'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { encryptInput } from '../../../utilitys/crypto'
import { handleSort } from '../../../utilitys/pagination'

const AuditDocumentListFiles = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const navigate = useNavigate()

  const Responsive = {
    sm: 24,
    md: 24,
    lg: 24,
  }

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState([])

  function validURL(str) {
    var pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i',
    ) // fragment locator
    return !!pattern.test(str)
  }

  const [getListAuditDocumentFilesPaginationfn, getListAuditDocumentFilesPagination] = useLazyQuery(
    GET_LIST_AUDIT_DOCUMENT_FILES_PAGINATION,
  )

  useEffect(() => {
    if (state.projectCode) {
      setFilters({
        projectCode: state.projectCode,
      })
    }
  }, [state.projectCode])

  useEffect(() => {
    getListAuditDocumentFilesPaginationfn({
      variables: { input: encryptInput({ filters: { ...filters }, pagination: { limit, page }, sort }) },
    })
  }, [page, limit, filters, sort])

  useEffect(() => {
    if (getListAuditDocumentFilesPagination?.data) {
      let response = getListAuditDocumentFilesPagination?.data?.getListAuditDocumentFilesPagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          let fileDetail = JSON.parse(data?.fileDetail)
          let convertToArr = Array.isArray(fileDetail) ? fileDetail : [fileDetail]
          let objLink = convertToArr.find((item) => item.fileId === parseInt(data.fileId))
          let links = validURL(objLink?.links) ? objLink.links : null
          let file = `${process.env.REACT_APP_API_UPLOAD}/downloadfileencrypt/${data.fileNameGen}${data.fileType}`
          return {
            ...data,
            key: data.fileId,
            file: (
              <a href={`${file}`} target="_blank" rel="noreferrer">
                {`${data.fileNameGen}${data.fileType}`}
              </a>
            ),
            links: (
              <a href={`${links}`} target="_blank" rel="noreferrer">
                {links}
              </a>
            ),
            fileName: displayText(data.fileNameLabel),
            createdAt: dateDisplay(data.createdAt),
          }
        }),
      )
    }
    if (getListAuditDocumentFilesPagination?.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: displayError(getListAuditDocumentFilesPagination.error),
        onOk() {},
      })
    }
  }, [getListAuditDocumentFilesPagination?.data])

  const columns = [
    { title: t('ลำดับ'), dataIndex: 'no', width: '10%', align: 'center', sorter: { multiple: 1 } },
    { title: t('ชื่อเอกสาร'), dataIndex: 'fileName', width: '35%', sorter: { multiple: 2 } },
    { title: t('ลิงค์เอกสาร'), dataIndex: 'links', width: '20%', sorter: { multiple: 4 } },
    { title: t('ไฟล์แนบ'), dataIndex: 'file', width: '20%', sorter: { multiple: 3 } },
    { title: t('วันที่อัปโหลด'), dataIndex: 'createdAt', width: '15%', sorter: { multiple: 5 }, align: 'center' },
  ]

  return (
    <>
      {getListAuditDocumentFilesPagination?.loading && <SpinnersNew />}
      <Col sm={24} md={24} lg={24}>
        <Breadcrumb data={AuditDocumentListCrumb} title={t('เอกสารประกอบการตรวจสอบ')} />
        <div style={{ marginBottom: '2rem' }}>
          <CardNew topic={t('รายการเอกสารประกอบการตรวจสอบ')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col sm={24} md={24} lg={24} xl={24}>
                <Datatable
                  columns={columns}
                  data={[...dataLists]}
                  total={count}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  enableScroll={{ x: true, y: false }}
                  pageSize={limit}
                  page={page}
                  searchCustom={true}
                  handleTableChange={(e) => {
                    // const reNewField = [
                    //   { projectName: displayText('projectNameTH', 'projectNameEN') },
                    //   { projectRefName: displayText('projectRefNameTH', 'projectRefNameEN') },
                    //   { organizationName: displayText('organizationNameTH', 'organizationNameEN') },
                    //   { personnelName: displayText('personnelNameTH', 'personnelNameEN') },
                    // ]
                    setSort(handleSort(e?.sorter))
                  }}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: '3em' }}>
              <Col {...Responsive} align="center">
                <Space size={[8, 8]} wrap>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/auditoperations/auditdocument')
                    }}
                  >
                    {t('ยกเลิก')}
                  </ButtonNew>
                </Space>
              </Col>
            </Row>
          </CardNew>
        </div>
      </Col>
    </>
  )
}

AuditDocumentListFiles.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default AuditDocumentListFiles
