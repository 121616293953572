import React from 'react'
import { CardNew as Card } from '../../../../../components/card/Card'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd' //Space
import SelectYear from '../../../../../components/inputfromapi/selectyear/SelectYear'
import { ButtonNew } from '../../../../../components/button/Button'
import { LabelNew } from '../../../../../components/label/Label'
import { InputNew } from '../../../../../components/input/Input'
import SelectPlanStatus from '../../../../../components/inputfromapi/selectplanstatus/SelectPlanStatus'
import { Select } from '../../../../../components/select/Select'
import SelectOrganization from '../../../../../components/inputfromapi/selectorganization/SelectOrganization'

//**************** เปลี่ยน filter เป้น form item ปรกติ
//***** แปะ masterPlanstatus ไว้หน้าบ้าน */
// แปลชื่อ column

export default function ApprovePlanListFillter(props) {
  const { t } = useTranslation()
  // const langUse = localStorage.getItem('lang')
  const [form] = Form.useForm()
  const { menu } = props
  const formName = 'from-approve-fillter'

  const Responesive = {
    inputFilter: { md: 12, lg: 8 },
    buttonFilter: { md: 24, lg: 24 },
  }

  const planTypeOptions = [
    { value: 1, label: t('แผนประจำปี') },
    { value: 2, label: t('แผนระยะยาว') },
  ]

  const onFinish = (values) => {
    props.getValue(values)
  }

  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => errorFields.map((er) => console.log(er))

  return (
    <>
      <Card topic={t('Filters')} toggledrop={'false'}>
        <Row style={{ marginTop: 24 }}>
          <Col sm={24} md={24} lg={24}>
            <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} name={formName} layout="vertical">
              <Row gutter={[16, 8]}>
                <Col {...Responesive.inputFilter}>
                  <Form.Item name="planYear" label={<LabelNew type="tab-header-inactive">{t('ประจำปี')}</LabelNew>}>
                    <SelectYear
                      formname={formName}
                      placeholder={t('ระบุปี')}
                      handleChange={(e) => form.setFieldsValue({ planYear: e?.value })}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item name="planType" label={<LabelNew type="tab-header-inactive">{t('ประเภทแผน')}</LabelNew>}>
                    <Select
                      formname={formName}
                      data={planTypeOptions}
                      placeholder={t('ระบุประเภทแผน')}
                      scrollableId={formName}
                      showSearch
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="planCode"
                    rules={[{ required: false, message: 'required' }]}
                    label={<LabelNew type="tab-header-inactive">{t('รหัสแผนการตรวจสอบ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุรหัสแผนการตรวจสอบ')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="planName"
                    rules={[{ required: false, message: 'required' }]}
                    label={<LabelNew type="tab-header-inactive">{t('ชื่อแผนการตรวจสอบ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุชื่อแผนการตรวจสอบ')} />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="planOrganization2"
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยการตรวจสอบ')}</LabelNew>}
                  >
                    <SelectOrganization
                      formname={formName}
                      placeholder={t('ระบุหน่วยการตรวจสอบ')}
                      scrollableId={formName}
                      showSearch
                      filters={{ checkedList: JSON.stringify([2]), isActive: 1 }}
                      handleChange={(e) => form.setFieldsValue({ planOrganization2: e?.value })}
                    />
                  </Form.Item>
                </Col>
                {/* <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="planOrganization3"
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยรับตรวจสอบ')}</LabelNew>}
                  >
                    <SelectOrganization
                      formname={formName}
                      placeholder={t('ระบุหน่วยรับตรวจสอบ')}
                      scrollableId={formName}
                      showSearch
                      filters={{ organizationChecked: 3, isActive: 1 }}
                      multiple
                      handleChange={(e) => {
                        if (e) {
                          form.setFieldsValue({ planOrganization3: e })
                        } else {
                          form.setFieldsValue({ planOrganization3: null })
                        }
                      }}
                    />
                  </Form.Item>
                </Col> */}
                <Col {...Responesive.inputFilter}>
                  <Form.Item name="planStatus" label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}>
                    <SelectPlanStatus
                      formname={formName}
                      placeholder={t('ระบุสถานะ')}
                      scrollableId={formName}
                      showSearch
                      handleChange={(e) => {
                        if (e) form.setFieldsValue({ planStatus: e.value })
                        else form.setFieldsValue({ planStatus: null })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew type="search" menu={menu} htmlType="submit">
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew
                    type="clear"
                    menu={menu}
                    onClick={() => {
                      form.resetFields()
                      props.getValue({})
                    }}
                  >
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}

{
  /* <Form.Item>
                    <Space size={[12, 12]} wrap>
                      <ButtonNew type="search" menu={menu} roles={{ type: 'add', menu: 'example' }} htmlType="submit">
                        ค้นหา
                      </ButtonNew>
                      &nbsp;
                      <ButtonNew type="clear" menu={menu} onClick={() => form.resetFields()}>
                        ยกเลิก
                      </ButtonNew>
                    </Space>
                  </Form.Item> */
}
