import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { BreadcrumbNew as Breadcrumb } from '../../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../../components/card/Card'
import { CardNewDb, CardNewDbF } from '../components/card/Card'
import { LabelNew } from '../../../../components/label/Label'
import SelectYear from '../../../../components/inputfromapi/selectyear/SelectYear'
import { Columns } from './utils/OverviewIssueColumns'
import { Datatable } from '../../../../components/datatable/Datatable'
import { Alert } from '../../../../components/alert/Alert'
import { useDecodeUser } from '../../../../hooks/useDecodeUser'
import { handleSort } from '../../../../utilitys/pagination'
import { GET_OVERVIEW_ISSUE_PAGINATION } from './graphql/Query'
import { displayText, displayError } from '../../../../utilitys/helper'
import { dateDisplay } from '../../../../utilitys/dateHelper'
import { encryptInput } from '../../../../utilitys/crypto'
import { SpinnersNew } from '../../../../components/spinners/Spinners'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}

const OverviewIssueList = () => {
  const { t } = useTranslation()
  const [language /*setLanguage*/] = useState('th')
  const [form] = Form.useForm()
  const formName = 'form-fillter'
  const [dataObj, setDataObj] = useState({
    statusStart: 'success',
    messageStart: null,
    statusEnd: 'success',
    messageEnd: null,
  })

  const breadcrumbList = [{ label: t('Dashboard') }, { label: t('ภาพรวมประเด็น') }]

  const [filters, setFilters] = useState({
    yearStart: new Date().getFullYear(),
    yearEnd: new Date().getFullYear(),
  })
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  const [user] = useDecodeUser()
  const [summary, setSummary] = useState({})

  /* ---------------- API ----------- */
  const [getDataPaginationFn, dataPagination] = useLazyQuery(GET_OVERVIEW_ISSUE_PAGINATION)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (user?.pCode) {
      getDataPaginationFn({
        variables: {
          input: encryptInput({
            filters: {
              ...filters,
              personnelCode: user?.pCode,
            },
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
    }
  }, [page, limit, sort, user?.pCode, filters])

  useEffect(() => {
    if (dataPagination.data) {
      let response = dataPagination?.data?.getOverviewIssuePagination

      setSummary(response.summary)
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data, index) => {
          return {
            key: index,
            projectName: displayText(data.projectNameTH, data.projectNameEN) || '-',
            dateStart: dateDisplay(data.dateStart) || '-',
            dateEnd: dateDisplay(data.dateEnd) || '-',
            countIssue: data.countIssue,
            countOrganizations: data.countOrganizations,
            countIssue60: data.countIssue60,
            countIssueNot60: data.countIssueNot60,
          }
        }),
      )
    }

    if (dataPagination.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataPagination.error)),
        onOk() {},
      })
    }
  }, [dataPagination.data])

  return (
    <>
      {dataPagination.loading ? <SpinnersNew /> : null}
      <Breadcrumb data={breadcrumbList} title={t('ภาพรวมประเด็น')} />
      <Row gutter={[24, 8]} style={{ marginBottom: '10px', marginTop: '20px' }}>
        <Col md={24} lg={9}>
          <CardNewDbF padding={'25px 24px 70px 24px'}>
            <Form
              form={form}
              name={formName}
              labelCol={{
                md: 6,
                lg: 6,
              }}
              wrapperCol={{
                md: 17,
                lg: 17,
                align: 'left',
              }}
            >
              <Form.Item
                name="yearStart"
                label={<LabelNew type="tab-header-inactive">{t('ปีเริ่มต้น')}</LabelNew>}
                style={{ paddingBottom: '15px' }}
                validateStatus={dataObj.statusStart}
                help={dataObj.messageStart}
                initialValue={new Date().getFullYear()}
              >
                <SelectYear
                  formname={formName}
                  placeholder={t('เลือก ปีเริ่มต้น')}
                  handleChange={(e) => {
                    const yearsEnd = form.getFieldValue('yearEnd')
                    if (!e?.value) {
                      setDataObj({
                        ...dataObj,
                        statusStart: 'error',
                        messageStart: t('กรุณาเลือกปีเริ่มต้น'),
                      })
                    } else {
                      if (e?.value > yearsEnd && yearsEnd) {
                        setDataObj({
                          ...dataObj,
                          statusStart: 'error',
                          messageStart: t('กรุณาเลือกปีเริ่มต้นน้อยกว่าหรือเท่ากับปีสิ้นสุด'),
                        })
                      } else {
                        setDataObj({
                          statusStart: 'success',
                          messageStart: null,
                          statusEnd: 'success',
                          messageEnd: null,
                        })
                        setFilters({
                          yearStart: e?.value,
                          yearEnd: yearsEnd,
                        })
                      }
                    }
                    form.setFieldsValue({ yearStart: e?.value })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="yearEnd"
                label={<LabelNew type="tab-header-inactive">{t('ปีสิ้นสุด')}</LabelNew>}
                style={{ paddingBottom: '15px' }}
                validateStatus={dataObj.statusEnd}
                help={dataObj.messageEnd}
                initialValue={new Date().getFullYear()}
              >
                <SelectYear
                  formname={formName}
                  placeholder={t('เลือก ปีสิ้นสุด')}
                  handleChange={(e) => {
                    const yearStart = form.getFieldValue('yearStart')
                    if (!e?.value) {
                      setDataObj({
                        ...dataObj,
                        statusEnd: 'error',
                        messageEnd: t('กรุณาเลือกปีสิ้นสุด'),
                      })
                    } else {
                      if (e?.value < yearStart && yearStart) {
                        setDataObj({
                          ...dataObj,
                          statusEnd: 'error',
                          messageEnd: t('กรุณาเลือกปีสิ้นสุดมากกว่าหรือเท่ากับปีเริ่มต้น'),
                        })
                      } else {
                        setDataObj({
                          statusStart: 'success',
                          messageStart: null,
                          statusEnd: 'success',
                          messageEnd: null,
                        })
                        setFilters({
                          yearStart: yearStart,
                          yearEnd: e?.value,
                        })
                      }
                    }
                    form.setFieldsValue({ yearEnd: e?.value })
                  }}
                />
              </Form.Item>
            </Form>
          </CardNewDbF>
        </Col>

        <Col md={24} lg={15}>
          <Row gutter={[40, 15]}>
            <Col span={12}>
              <CardNewDb
                icon="Layers"
                color={'blue'}
                content={(summary.summaryProject && summary.summaryProject.toLocaleString()) || 0}
                description={t('จำนวนโครงการทั้งหมด')}
              />
            </Col>
            <Col span={12}>
              <CardNewDb
                icon="FileText"
                color={'danger'}
                content={(summary.summaryIssue && summary.summaryIssue.toLocaleString()) || 0}
                description={t('จำนวนประเด็นที่ตรวจพบทั้งหมด')}
              />
            </Col>
            <Col span={12}>
              <CardNewDb
                icon="Clock"
                color={'orange'}
                content={(summary.countIssueNot60 && summary.countIssueNot60.toLocaleString()) || 0}
                description={t('จำนวนประเด็นที่อยู่ระหว่างดำเนินการ')}
              />
            </Col>
            <Col span={12}>
              <CardNewDb
                icon="Clock"
                color={'green'}
                content={(summary.countIssue60 && summary.countIssue60.toLocaleString()) || 0}
                description={t('จำนวนประเด็นที่ดำเนินการแล้วเสร็จ')}
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <CardNew topic={language === 'th' ? t('รายละเอียดจำแนกรายโครงการ') : ''} icon="TrendingUp" toggledrop={'false'}>
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            <Datatable
              columns={Columns()}
              data={[...dataLists]}
              total={count}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              paginationCustom={true}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => setSort(handleSort(e?.sorter))}
              scroll={{ x: 1000, y: false }}
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default OverviewIssueList
