import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'antd'
import { useMutation } from '@apollo/client'

import { SAVE_RATE_RISK_PROCESS } from './graphql/Mutation'

import { ModalNew as Modal } from '../../../../components/modal/Modal'
import { InputNew as Input } from '../../../../components/input/Input'
import { Radio, RadioGroup } from '../../../../components/radio/Radio'
import { Alert } from '../../../../components/alert/Alert'
import SelectRiskFactors from '../../../../components/inputfromapi/selectriskfactors/SelectRiskFactors'
import { useLanguage } from '../../../../hooks/useLanguage'
import { displayError, regexNumber } from '../../../../utilitys/helper'
import SelectRateRiskFactorProcess from '../../../../components/inputfromapi/selectrateriskfactorprocess/SelectRateRiskFactorProcess'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'

const RateRiskProcessForm = ({
  visible = false,
  formType = 'view',
  rateFacGroupCode = null,
  dataForm = null,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isLang] = useLanguage()

  useEffect(() => {
    if (dataForm && visible) {
      form.setFieldsValue(dataForm)
    }
  }, [dataForm, visible])

  const [editable, seteditable] = useState(false)

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_RATE_RISK_PROCESS)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorSave)),
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          onSuccess()
        },
      })
    }
  }, [dataSave])

  useEffect(() => {
    if (formType) {
      if (formType === 'add') form.resetFields()
      seteditable(formType !== 'view')
    }
  }, [formType])

  const onFinish = () => {
    const field = form.getFieldValue()

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        const dataInput = {
          rateFacProCode: field.rateFacProCode,
          riskFactorCode: field.riskFactorCode,
          sort: field.sort ? field.sort : null,
          isActive: field.isActive,
        }
        if (formType === 'add') {
          dataInput.typeAction = 'add'
        } else {
          dataInput.rateRiskProId = parseInt(field.rateRiskProId)
          dataInput.typeAction = 'edit'
        }

        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const footerHide =
    formType === 'view'
      ? { cancelButtonProps: { style: { display: 'none' } }, okButtonProps: { style: { display: 'none' } } }
      : {}

  const renderTitle = () => {
    if (formType === 'add') {
      return t('เพิ่มความเสี่ยง')
    }
    if (formType === 'view') {
      return t('ดูความเสี่ยง')
    }
    if (formType === 'edit') {
      return t('แก้ไขความเสี่ยง')
    }

    return ''
  }

  const formName = 'Process'

  return (
    <React.Fragment>
      {loadingSave && <SpinnersNew />}
      <Modal
        visible={visible}
        onSubmit={form.submit}
        onClose={(data) => {
          onClose(data)
          form.resetFields()
        }}
        testTitle={renderTitle()}
        typeAction={formType}
        type="medium"
        btnName={t('บันทึก')}
        {...footerHide}
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 8,
            lg: 8,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name={'rateFacProCode'}
            label={t('ความเสี่ยงกระบวนการ')}
            rules={[
              {
                required: true,
                message: t('กรุณาระบุ'),
              },
            ]}
          >
            <SelectRateRiskFactorProcess
              filters={{ isActive: 1, rateFacGroupCode: rateFacGroupCode }}
              disabled={!editable}
              formname={formName}
              placeholder={t('เลือกประเภทการควบคุม')}
              handleChange={(data) => {
                const name = data
                  ? isLang === 'en'
                    ? data.source.rateFacProNameEN
                    : data.source.rateFacProNameTH
                  : null
                const value = data ? data.source.rateFacProCode : null
                form.setFieldsValue({ rateFacProName: name, rateFacProCode: value })
              }}
            />
          </Form.Item>
          <Form.Item
            name="riskFactorCode"
            label={t('ความเสี่ยง')}
            rules={[{ required: true, message: t('กรุณาระบุ') }]}
          >
            <SelectRiskFactors
              disabled={!editable}
              formname={formName}
              placeholder={t('เลือกความเสี่ยง')}
              handleChange={(data) => {
                const name = data
                  ? isLang === 'en'
                    ? data.source.riskFactorNameEN
                    : data.source.riskFactorNameTH
                  : null
                const value = data ? data.source.riskFactorCode : null
                form.setFieldsValue({ riskFactorName: name, riskFactorCode: value })
              }}
            />
          </Form.Item>

          <Form.Item
            name={'sort'}
            label={t('ลำดับ')}
            rules={[
              { required: true, message: t('กรุณาระบุ') },
              {
                pattern: regexNumber,
                message: t(`กรุณากรอกตัวเลข 0-9`),
              },
            ]}
          >
            <Input disabled={!editable} placeholder={t('กรอกลำดับ')} />
          </Form.Item>

          <Form.Item name={'isActive'} label={t('สถานะ')} initialValue={1}>
            <RadioGroup disabled={!editable}>
              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                </Col>
                <Col>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}
export default RateRiskProcessForm
