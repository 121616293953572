import { gql } from '@apollo/client'
export const GET_LIST_AUDIT_JOB_STEP_BY_ACTION_PLAN_ID = gql`
  query GetListAuditJobStepByActionPlanID($input: AuditJobStepListInput!) {
    getListAuditJobStepByActionPlanID(input: $input) {
      stepId
      actionPlanId
      stepIdRef
      stepNameTH
      stepNameEN
      stepType
      stepSort
      dateStart
      dateEnd
      operationStatusCode
      operationStatusNameTH
      operationStatusNameEN
      isActive
      auditTeamCode
      personnels {
        personnelCode
        personnelNameTH
        personnelNameEN
        personnaloperationCode
      }
      organizations {
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationCode
        organizationNameTH
        organizationNameEN
        personnelCode
        personnelNameTH
        personnelNameEN
        organizationLevelCode
        organizationLevelNameTH
        organizationLevelNameEN
      }
    }
  }
`

export const GET_AUDIT_JOB_STEP_BY_STEP_ID = gql`
  query GetAuditJobStepByStepId($input: AuditJobStepGetInput!) {
    getAuditJobStepByStepId(input: $input) {
      stepId
      actionPlanId
      stepNameTH
      stepNameEN
      stepType
      stepProcess
      stepSort
      dateStart
      dateEnd
      operationStatusCode
      operationStatusNameTH
      operationStatusNameEN
      isActive
      stepIdRef
      personnels {
        personnelCode
        personnelNameTH
        personnelNameEN
      }
      organizations {
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationCode
        organizationNameTH
        organizationNameEN
        personnelCode
        personnelNameTH
        personnelNameEN
        organizationLevelCode
        organizationLevelNameTH
        organizationLevelNameEN
      }
      stepDetailTH
      stepDetailEN
      manDay
      budgets
      remarkTH
      remarkEN
      stepTypeCode
    }
  }
`

export const GET_AUDIT_PROJECT_DATE_BY_ACTION_PLAN_ID = gql`
  query GetAuditProjectDateByActionPlanId($input: AuditProjectGetDateInput!) {
    getAuditProjectDateByActionPlanId(input: $input) {
      projectCode
      dateStart
      dateEnd
    }
  }
`

export const GET_AUDIT_RESULT_INSPEC_TION_PROJECT = gql`
  query AuditResultInspectionProjectGet($projectCode: String) {
    auditResultInspectionProjectGet(projectCode: $projectCode) {
      organizationCode
      organizationNameTH
      organizationNameEN
      organizationLevelNameTH
      organizationLevelNameEN
      organizationLevelCode
      personnelCode
      personnelNameTH
      personnelNameEN
      organizationId
    }
  }
`
