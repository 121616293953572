import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_NAME_FORMAT_TYPE } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'

const SelectNumberFormatTypes = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props

  const [isData, isSetData] = useState([])

  const [getMasterNumberFormatTypesFn, getMasterNumberFormatTypes] = useLazyQuery(GET_MASTER_NAME_FORMAT_TYPE)

  useEffect(() => {
    getMasterNumberFormatTypesFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [filters])

  useEffect(() => {
    if (getMasterNumberFormatTypes.data) {
      isSetData(
        _.map(getMasterNumberFormatTypes?.data?.getMasterNumberFormatTypes, (item) => {
          return {
            label: item?.formatTypeCode + ':' + item?.formatTypeNameTH,
            value: item?.formatTypeCode,
            source: item,
          }
        }),
      )
    }
  }, [getMasterNumberFormatTypes.data])
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterNumberFormatTypes.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectNumberFormatTypes
