import React from 'react'
import { Button } from 'antd'
import {
  RotateCcw,
  Plus,
  Eye,
  Printer,
  Edit,
  Trash2,
  CheckCircle,
  Download,
  Calendar,
  FileText,
  Copy,
  Send,
  Settings,
  XCircle,
} from 'react-feather'
import { FlagOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'
import { ButtonStyle } from './ButtonStyles'
import { IconNew } from '../icon/Icon'
import { checkRole } from '../../utilitys/helper'

export const ButtonNew = (props) => {
  let obj = {
    classBtn: '',
    icon: '',
    classIcon: '',
    block: false,
    type: '',
    width: '',
    height: '',
    top: '',
  }
  switch (props.type) {
    case 'cancel':
      obj.classBtn = 'btn-cancel'
      obj.width = props.width
      obj.height = props.height
      break
    case 'primary':
      obj.classBtn = 'btn-stt ' + (props.disabled ? 'btn-disabled' : 'btn-color-primary')
      obj.width = props.width
      obj.height = props.height
      break
    case 'primaryEdit':
      obj.classBtn = 'btn-stt btn-color-primary'
      obj.icon = <Edit size={props.size ? props.size : 18} className="edit" />
      obj.classIcon = 'icon'
      obj.width = 157
      obj.height = 40
      break
    case 'back':
      obj.classBtn = 'btn-stt btn-color-back'
      break
    case 'cancelSend':
      obj.classBtn = 'btn-stt btn-color-noAllow'
      break
    case 'allow':
      obj.classBtn = 'btn-stt btn-color-allow'
      break
    case 'noAllow':
      obj.classBtn = 'btn-stt btn-color-noAllow'
      break
    case 'success':
      obj.classBtn = 'btn-stt btn-color-success'
      break
    case 'search':
      obj.classBtn = 'btn-stt btn-color-search'
      obj.icon = (
        <IconNew icon={props.iconname ? props.iconname : 'Search'} size={props.size ? props.size : 18} color="" />
      )
      obj.classIcon = 'icon'
      break
    case 'clear':
      obj.classBtn = 'btn-stt btn-color-clear'
      obj.icon = (
        <IconNew icon={props.iconname ? props.iconname : 'RotateCcw'} size={props.size ? props.size : 18} color="" />
      )
      obj.classIcon = 'icon'
      break
    case 'generate':
      obj.classBtn = 'btn-stt btn-color-gen'
      // obj.icon = (
      //   <IconNew icon={props.iconname ? props.iconname : 'RotateCcw'} size={props.size ? props.size : 18} color="" />
      // )
      // obj.classIcon = 'icon'
      break
    case 'printerSuccess':
      obj.classBtn = 'btn-stt btn-color-success'
      obj.icon = <Printer size={props.size ? props.size : 18} className="printer" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'upload':
      props.disabled
        ? (obj.classBtn = 'btn-stt-upload btn-color-clear btn-disable')
        : (obj.classBtn = 'btn-stt-upload btn-color-clear')
      obj.icon = (
        <IconNew icon={props.iconname ? props.iconname : 'Upload'} size={props.size ? props.size : 18} color="" />
      )
      obj.classIcon = 'icon'
      obj.top = props.top ? 50 : ''
      break
    case 'plusTableBorderPrimary':
      obj.classBtn = 'btn-stt-tb ' + (props.disabled ? 'btn-disabled' : 'btn-color-tb')
      obj.icon = (
        <IconNew icon={props.iconname ? props.iconname : 'Plus'} size={props.size ? props.size : 18} color="" />
      )
      obj.classIcon = 'icon'
      break
    case 'viewTable':
      obj.classBtn = 'btn-stt-icon2 btn-color-tb'
      obj.icon = <IconNew icon={props.iconname ? props.iconname : 'Eye'} size={props.size ? props.size : 18} color="" />
      obj.classIcon = 'icon'
      break
    case 'moreHorizontalInTableOrange':
      obj.classBtn = 'btn-stt-icon3 ' + (props.disabled ? 'btn-disabled' : 'btn-color-edit-in-tb')
      obj.icon = (
        <IconNew
          icon={props.iconname ? props.iconname : 'MoreHorizontal'}
          size={props.size ? props.size : 18}
          color=""
        />
      )
      obj.classIcon = 'icon'
      obj.width = props.width
      obj.height = props.height
      break
    case 'downloadInTableOrange':
      obj.classBtn = 'btn-stt-icon3 btn-color-edit-in-tb'
      obj.icon = (
        <IconNew icon={props.iconname ? props.iconname : 'Download'} size={props.size ? props.size : 18} color="" />
      )
      obj.classIcon = 'icon'
      obj.width = props.width
      obj.height = props.height
      break
    case 'flagOutlinedInTableGray':
      obj.classBtn = 'btn-stt-icon3 btn-color-examine-in-tb'
      obj.icon = <FlagOutlined style={{ fontSize: props.size ? `${props.size}px` : '18px' }} />
      obj.classIcon = 'icon'
      obj.width = props.width
      obj.height = props.height
      break
    case 'flagOutlinedInTableBlue':
      obj.classBtn = 'btn-stt-icon3  ' + (props.disabled ? 'btn-disabled' : 'btn-color-state-in-tb')
      obj.icon = <FlagOutlined style={{ fontSize: props.size ? `${props.size}px` : '18px' }} />
      obj.classIcon = 'icon'
      obj.width = props.width
      obj.height = props.height
      break
    case 'checkCircleInTableBlue':
      obj.classBtn = 'btn-stt-icon3 btn-color-state-in-tb'
      obj.icon = (
        <IconNew icon={props.iconname ? props.iconname : 'CheckCircle'} size={props.size ? props.size : 18} color="" />
      )
      // obj.icon = <CheckCircle style={{ fontSize: props.size ? `${props.size}px` : '18px' }} />
      obj.classIcon = 'icon'
      obj.width = props.width
      obj.height = props.height
      break
    case 'checkCircleInTableGreen':
      obj.classBtn = 'btn-stt-icon3 btn-color-state-in-tb-gren'
      obj.icon = (
        <IconNew icon={props.iconname ? props.iconname : 'CheckCircle'} size={props.size ? props.size : 18} color="" />
      )
      // obj.icon = <CheckCircle style={{ fontSize: props.size ? `${props.size}px` : '18px' }} />
      obj.classIcon = 'icon'
      obj.width = props.width
      obj.height = props.height
      break
    case 'fileTextCircleInTableBlue':
      obj.classBtn = 'btn-stt-icon3 btn-color-state-in-tb'
      obj.icon = (
        <IconNew icon={props.iconname ? props.iconname : 'FileText'} size={props.size ? props.size : 18} color="" />
      )
      // obj.icon = <FileText style={{ fontSize: props.size ? `${props.size}px` : '18px' }} />
      obj.classIcon = 'icon'
      obj.width = props.width
      obj.height = props.height
      break
    case 'moreHorizontalInTableGray':
      obj.classBtn = 'btn-stt-icon3 btn-color-examine-in-tb'
      obj.icon = (
        <IconNew
          icon={props.iconname ? props.iconname : 'MoreHorizontal'}
          size={props.size ? props.size : 18}
          color=""
        />
      )
      obj.classIcon = 'icon'
      obj.width = props.width
      obj.height = props.height
      break
    case 'xInTableOrange':
      obj.classBtn = 'btn-stt-icon3 btn-color-edit-in-tb'
      obj.icon = <IconNew icon={props.iconname ? props.iconname : 'X'} size={props.size ? props.size : 18} color="" />
      obj.classIcon = 'icon'
      obj.width = props.width
      obj.height = props.height
      break
    case 'addMultiple':
      obj.classBtn = 'btn-stt-tb ' + (props.disabled ? 'btn-disabled' : 'btn-color-add-multiple')
      obj.icon = <Plus size={props.size ? props.size : 18} className="plus" />
      obj.classIcon = 'icon'
      obj.block = true
      break
    case 'printUseInTable':
      obj.classBtn = 'btn-stt-icon'
      obj.icon = <Printer size={props.size ? props.size : 18} className="printer" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'editUseInTable':
      obj.classBtn = 'btn-stt-icon'
      obj.icon = <Edit size={props.size ? props.size : 18} className="edit" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'viewUseInTable':
      obj.classBtn = 'btn-stt-icon'
      obj.icon = <Eye size={props.size ? props.size : 18} className="eye" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'deleteUseInTable':
      obj.classBtn = 'btn-stt-icon'
      obj.icon = <Trash2 size={props.size ? props.size : 18} className="trash2" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'clearUseInTable':
      obj.classBtn = 'btn-stt-icon'
      obj.icon = <RotateCcw size={props.size ? props.size : 18} className="rotate-ccw" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'approveUseInTable':
      obj.classBtn = 'btn-stt-icon'
      obj.icon = <CheckCircle size={props.size ? props.size : 18} className="check-circle" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'downloadUseInTable':
      obj.classBtn = 'btn-stt-icon'
      obj.icon = <Download size={props.size ? props.size : 18} className="download" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'calendarUseInTable':
      obj.classBtn = 'btn-stt-icon'
      obj.icon = <Calendar size={props.size ? props.size : 18} className="calendar" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'filetextUseInTable':
      obj.classBtn = 'btn-stt-icon'
      obj.icon = <FileText size={props.size ? props.size : 18} className="file-text" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'copyUseInTable':
      obj.classBtn = 'btn-stt-icon'
      obj.icon = <Copy size={props.size ? props.size : 18} className="copy" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'sendUseInTable':
      obj.classBtn = 'btn-stt-icon'
      obj.icon = <Send size={props.size ? props.size : 18} className="send" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'settingUseInTable':
      obj.classBtn = 'btn-stt-icon'
      obj.icon = <Settings size={props.size ? props.size : 18} className="settings" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'cancelUseInTable':
      obj.classBtn = 'btn-stt-icon'
      obj.icon = <XCircle size={props.size ? props.size : 18} className="x-circle" />
      obj.classIcon = 'icon'
      obj.type = 'link'
      break
    case 'module':
      obj.classBtn = 'btn-stt-module'
      break
    case 'miniModule':
      obj.classBtn = 'btn-stt-mini-module'
      obj.width = props.width
      obj.height = props.height
      break
    case 'moreHorizontalInTableGreen':
      obj.classBtn = 'btn-stt-icon3 btn-color-approve-in-tb'
      obj.icon = (
        <IconNew
          icon={props.iconname ? props.iconname : 'MoreHorizontal'}
          size={props.size ? props.size : 18}
          color=""
        />
      )
      obj.classIcon = 'icon'
      obj.width = props.width
      obj.height = props.height
      break
    case 'export':
      obj.classBtn = 'btn-stt btn-color-success'
      obj.icon = (
        <IconNew icon={props.iconname ? props.iconname : 'Download'} size={props.size ? props.size : 18} color="" />
      )
      obj.classIcon = 'icon'
      break
    default:
    // code block
  }
  return checkRole(props.roles || {}) ? (
    <ButtonStyle width={obj.width} height={obj.height} top={obj.top}>
      <Button
        {...props}
        className={obj.classBtn + ' ' + props.className}
        block={obj.block}
        type={obj.type}
        htmlType={props.htmlType ? props.htmlType : 'button'}
      >
        <div className={obj.classIcon}>{obj.icon}</div>
        {props.children}
      </Button>
    </ButtonStyle>
  ) : (
    <></>
  )
}

ButtonNew.propTypes = {
  type: PropTypes.string.isRequired,
  roles: PropTypes.object,
  htmlType: PropTypes.string,
}
