import React, { useState, useEffect } from 'react'
import { ModalNew } from '../../../../../components/modal/Modal'
import { useTranslation } from 'react-i18next'
import { Space, Form } from 'antd'
import { InputNew, InputNumberNew } from '../../../../../components/input/Input'
import { RadioGroup, Radio } from '../../../../../components/radio/Radio'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { Select } from '../../../../../components/select/Select'
import { GETRSKFACTORBYRSKMODELS } from '../../graphql/Query'
import { useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../../../utilitys/crypto' //encryptData decryptData,
import { Alert } from '../../../../../components/alert/Alert'
import { displayError, displayText, regexNumber } from '../../../../../utilitys/helper'

export default function RiskFactorModal(props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const formName = 'from-rickfactor-modal'
  const [rskFactorOptions, setRskFactorOptions] = useState([])

  const [getRskFactorByRskModelsfn, getRskFactorByRskModels] = useLazyQuery(GETRSKFACTORBYRSKMODELS)

  useEffect(() => {
    getRskFactorByRskModelsfn({
      variables: {
        inputData: encryptInput({
          riskType: 1,
        }),
      },
    })
  }, [props.open])

  useEffect(() => {
    if (getRskFactorByRskModels.data) {
      setRskFactorOptions(
        getRskFactorByRskModels.data.getRskFactorByRskModels.map((dt) => {
          return {
            label: dt.riskFactorCode + ' : ' + displayText(dt.riskFactorNameTH, dt.riskFactorNameEN),
            value: dt.riskFactorCode,
            source: dt,
          }
        }),
      )
    }
    if (getRskFactorByRskModels.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getRskFactorByRskModels.error)),
        onOk() {},
      })
    }
  }, [getRskFactorByRskModels.data])

  const onClose = (e) => {
    form.resetFields()
    props.onClose(e)
  }

  const onFinish = (values) => {
    if (props.rskWeight === 1) {
      if (Number(props.totalWeight) + Number(values.weight) <= 100) {
        setErrorField('weight', false)
        props.rskObj(values)
      } else {
        setErrorField('weight', t('การถ่วงน้ำหนักรวมไม่ควรเกิน 100 %'))
      }
    } else {
      props.rskObj(values)
    }
  }

  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  const onSearch = () => {
    //
  }

  return (
    <>
      <ModalNew
        visible={props.open}
        testTitle={t('เพิ่มคลังประเมินความเสี่ยง')}
        onSubmit={form.submit}
        onClose={(e) => {
          onClose(e)
        }}
        type="medium"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item name="riskNo" label={<Label type="tab-header-inactive">{t('ลำดับ')}</Label>}>
            <InputNumberNew placeholder={t('ระบุลำดับ')} formname={formName} />
          </Form.Item>
          <Form.Item name="riskFactorCode" label={<Label type="tab-header-inactive">{t('ลำดับ')}</Label>} hidden={true}>
            <InputNew placeholder={t('ระบุลำดับ')} formname={formName} />
          </Form.Item>
          <Form.Item
            name="riskFactorNameTH"
            label={<Label type="tab-header-inactive">{t('ลำดับ')}</Label>}
            hidden={true}
          >
            <InputNew placeholder={t('ระบุลำดับ')} formname={formName} />
          </Form.Item>
          <Form.Item
            name="riskFactorNameEN"
            label={<Label type="tab-header-inactive">{t('ลำดับ')}</Label>}
            hidden={true}
          >
            <InputNew placeholder={t('ระบุลำดับ')} formname={formName} />
          </Form.Item>
          <Form.Item
            name="riskFactor"
            label={<Label type="tab-header-inactive">{t('ปัจจัยความเสี่ยง')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกปัจจัยความเสี่ยง') }]}
          >
            <Select
              formname={formName}
              data={rskFactorOptions}
              placeholder={t('ระบุปัจจัยความเสี่ยง')}
              scrollableId={formName}
              onChange={(e) => {
                const res = rskFactorOptions.find((i) => i.value === e)
                form.setFieldsValue({
                  riskFactorCode: res.source.riskFactorCode,
                  riskFactorNameTH: res.source.riskFactorNameTH,
                  riskFactorNameEN: res.source.riskFactorNameEN,
                })
              }}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
              onSearch={onSearch}
              showSearch
            />
          </Form.Item>
          <Form.Item
            name="weight"
            label={<Label type="tab-header-inactive">{t('การถ่วงน้ำหนัก')}</Label>}
            rules={[
              { required: props.rskWeight === 1 ? true : false, message: 'กรุณากรอกปัจจัยความเสี่ยง' },
              {
                pattern: regexNumber,
                message: t(`กรุณากรอกตัวเลข(%)`),
              },
            ]}
          >
            <InputNew placeholder={t('ระบุการถ่วงน้ำหนัก')} disabled={props.rskWeight === 1 ? false : true} />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
            <RadioGroup formname={formName}>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                &nbsp;
                <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
