import React, { useState } from 'react'
import { BreadcrumbNew } from '../../../../components/breadcrumb/Breadcrumb'
import { breadcrumbData, pageTitle } from './utils/Constants'
import RiskAuditUniverseFilter from './components/filter/RiskAuditUniverseFilter'
import RiskAuditUniverseGraph from './RiskAuditUniverseGraph'
import RiskAuditUniverseList from './RiskAuditUniverseList'

const RiskAuditUniverseIndex = () => {
  const [dataFilter, setdataFilter] = useState({
    dateStart: null,
    dateEnd: null,
    organizationCode: null,
  })

  const onGetValue = (data) => {
    setdataFilter(data)
  }

  return (
    <React.Fragment>
      <BreadcrumbNew data={breadcrumbData()} title={pageTitle} />
      <RiskAuditUniverseFilter getValue={onGetValue} />
      <RiskAuditUniverseGraph dataFilter={dataFilter} />
      <RiskAuditUniverseList dataFilter={dataFilter} />
    </React.Fragment>
  )
}

export default RiskAuditUniverseIndex
