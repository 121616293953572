import * as _ from 'lodash'
import moment from 'moment'
import 'moment/locale/th'

export const yearBoundaryFn = (yearBoundary, minDate, maxDate, lang) => {
  let thisYear
  const resLang = lang ? lang : localStorage.getItem('lang')
  const resYearBoundary = yearBoundary ? yearBoundary : 10

  resLang === 'en' ? (thisYear = moment().year()) : (thisYear = moment().add(543, 'year').year())

  const minYear = minDate ? moment(minDate).year() : thisYear - resYearBoundary
  const maxYear = maxDate ? moment(maxDate).year() : thisYear + resYearBoundary
  return _.range(minYear, maxYear + 1, 1)
}

export const dateDisplay = (data) => {
  if (data) {
    if (localStorage.getItem('lang') === 'th') {
      return moment(new Date(data)).format('DD/MM/') + (parseInt(moment(new Date(data)).format('YYYY')) + 543)
    } else {
      return moment(new Date(data)).format('DD/MM/YYYY')
    }
  }
  return ''
}

export const dateTimeDisplay = (data) => {
  if (data) {
    const date = new Date(data)
    if (localStorage.getItem('lang') === 'th') {
      return (
        moment(date).format('DD/MM/') +
        (parseInt(moment(date).format('YYYY')) + 543) +
        ' ' +
        moment(date).format('HH:mm')
      )
    } else {
      return moment(date).format('DD/MM/YYYY') + ' ' + moment(date).format('HH:mm')
    }
  }
  return ''
}

export const timeDisplay = (data) => {
  if (data) {
    const date = new Date(data)
    return moment(date).format('HH:mm')
  }
  return ''
}

export const yearDisplay = (data) => {
  if (data) {
    if (localStorage.getItem('lang') === 'th') {
      return parseInt(moment(new Date(data)).format('YYYY')) + 543
    } else {
      return moment(new Date(data)).format('YYYY')
    }
  }
}

export const dateStringToPicker = (date = new Date()) => {
  if (localStorage.getItem('lang') === 'th') {
    return (date.getFullYear() + 543).toString() + '-' + moment(date).format('MM-DD').toString()
  } else {
    return moment(date).format('YYYY-MM-DD').toString()
  }
}

export const convertDate2FullDate = (val) => {
  if (val) {
    const monthNamesThai = [
      'มกราคม',
      'กุมภาพันธ์',
      'มีนาคม',
      'เมษายน',
      'พฤษภาคม',
      'มิถุนายน',
      'กรกฎาคม',
      'สิงหาคม',
      'กันยายน',
      'ตุลาคม',
      'พฤษจิกายน',
      'ธันวาคม',
    ]
    const monthNamesEng = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    var date = new Date(val)
    if (localStorage.getItem('lang') === 'th') {
      return date.getDate() + ' ' + monthNamesThai[date.getMonth()] + ' ' + (date.getFullYear() + 543)
    } else {
      return date.getDate() + ' ' + monthNamesEng[date.getMonth()] + ' ' + date.getFullYear()
    }
  }
  return ''
}

export const getFirstDayPresentYear = () => {
  return moment().startOf('year').format('YYYY-MM-DD')
}

export const getLastDayPresentYear = () => {
  return moment().endOf('year').format('YYYY-MM-DD')
}
