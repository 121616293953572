import { gql } from '@apollo/client'

export const FORMASSESSSAVE = gql`
  mutation formAssessSave($inputData: FormAssessSaveInput!) {
    formAssessSave(inputData: $inputData) {
      formAssessId
    }
  }
`

export const FORMASSESSDELETE = gql`
  mutation formAssessDelete($input: FormAssessInput!) {
    formAssessDelete(input: $input)
  }
`

export const SENDMAILFORMASSESS = gql`
  mutation sendMailFormAssess($input: sendMailFormAssessInput!) {
    sendMailFormAssess(input: $input) {
      formAssessId
    }
  }
`

export const CANCEL_ASSESSMENT = gql`
  mutation formAssessCancel($input: FormAssessInput!) {
    formAssessCancel(input: $input)
  }
`
