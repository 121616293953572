import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { GET_FOLLOW_UP_REPORT_LIST_PAGINATION } from './graphql/Query'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { FollowUpReportListCrumb, Columns } from './utils/constants'
import { displayError, displayText } from '../../../utilitys/helper'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { encryptInput } from '../../../utilitys/crypto'
import { handleSort } from '../../../utilitys/pagination'
import FollowUpReportListFilter from './components/filter/FollowUpReportListFilter'
// import FollowUpReportExport from './components/modal/FollowUpReportExport'
// import SendEmailReportModal from '../components/SendEmailReportModal'

const FollowUpReportList = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState([])
  // const [dataForSendMail, setDataForSendMail] = useState({})
  // const [dataForExport, setDataForExport] = useState({})
  // const [followUpReportSendMailModal, setFollowUpReportSendMailModal] = useState(false)
  // const [followUpReportExportModal, setFollowUpReportExportModal] = useState(false)

  /* สำหรับการส่งเมลรายงาน */
  // const [visibleSendMail, setVisibleSendMail] = useState(false)
  // const [mailValue, setMailValue] = useState()
  // const [inputSendEmail, setInputSendEmail] = useState()
  // const [inputForExport, setInputForExport] = useState()

  // const [getFollowUpReportExportFn, getFollowUpReportExport] = useLazyQuery(GET_FOLLOW_UP_REPORT_EXPORT)

  const [getFollowUpReportListPaginationfn, getFollowUpReportListPagination] = useLazyQuery(
    GET_FOLLOW_UP_REPORT_LIST_PAGINATION,
  )

  useEffect(() => {
    getFollowUpReportListPaginationfn({
      variables: { input: encryptInput({ filters: { ...filters }, pagination: { limit, page }, sort }) },
    })
  }, [page, limit, filters, sort])

  useEffect(() => {
    if (getFollowUpReportListPagination?.data) {
      let response = getFollowUpReportListPagination?.data?.getFollowUpReportListPagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data, key) => {
          return {
            ...data,
            key: key,
            exportDate: dateDisplay(data.exportDate),
            projectTypeName: displayText(data.projectTypeNameTH, data.projectTypeNameEN),
            projectName: displayText(data.projectNameTH, data.projectNameEN),
            dateStart: dateDisplay(data.dateStart),
            dateEnd: dateDisplay(data.dateEnd),
            issue: displayText(data.issueTH, data.issueEN),
            findingTypeName: displayText(data.findingTypeNameTH, data.findingTypeNameEN),
            suggest: displayText(data.suggestTH, data.suggestEN),
            planDetail: displayText(data.planDetailTH, data.planDetailEN),
            riskLevelName: displayText(data.riskLevelNameTH, data.riskLevelNameEN),
            issueDateEnd: dateDisplay(data.issueDateEnd),
            followDateNext: dateDisplay(data.followDateNext),
            actionDetail: displayText(data.actionDetailTH, data.actionDetailEN),
            actionStatusName: displayText(data.actionStatusNameTH, data.actionStatusNameEN),
            issueCloseDate: dateDisplay(data.issueCloseDate),
          }
        }),
      )
    }

    const errorList = getFollowUpReportListPagination.error
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorList)),
      })
    }
  }, [getFollowUpReportListPagination?.data])

  // useEffect(() => {
  //   if (getFollowUpReportExport?.data) {
  //     const resData = getFollowUpReportExport?.data?.getFollowUpReportExport
  //     if (resData) {
  //       if (resData.message === 'succcess') {
  //         window.open(`${resData.fileUrl}`, '_blank')
  //         // setFollowUpReportExportModal(false)
  //       } else {
  //         // setFollowUpReportExportModal(false)
  //       }
  //     }
  //   }

  //   if (getFollowUpReportExport?.error) {
  //     showAlertError('ไม่สามารถโหลดข้อมูลได้', getFollowUpReportExport.error)
  //   }
  // }, [getFollowUpReportExport?.data])

  // useEffect(() => {
  //   if (inputSendEmail) {
  //     const dataInput = {
  //       projectCode: mailValue.projectCode,
  //       lang: localStorage.getItem('lang'),
  //       emailData: inputSendEmail, // สำหรับการส่งเมลรายงาน
  //     }
  //     // Call API Export
  //     getFollowUpReportExportFn({
  //       variables: {
  //         input: encryptInput(dataInput),
  //       },
  //     })
  //   }
  // }, [inputSendEmail])

  // useEffect(() => {
  //   if (inputForExport) {
  //     // Call API Export
  //     getFollowUpReportExportFn({
  //       variables: {
  //         input: encryptInput(inputForExport),
  //       },
  //     })
  //   }
  // }, [inputForExport])

  const onFilterFollowUpReportListFilter = (data) => {
    setFilters(data)
  }

  // const followUpReportExportFn = (data) => {
  //   setDataForExport({
  //     ...dataForExport,
  //     ...data,
  //     typeAction: 'export',
  //   })
  //   setFollowUpReportExportModal(true)
  // }

  // const showAlertError = (text, error) => {
  //   Alert({
  //     type: 'error',
  //     title: t(text),
  //     content: t(displayError(error)),
  //     onOk() {},
  //   })
  // }

  return (
    <>
      {getFollowUpReportListPagination?.loading && <SpinnersNew />}
      <Col sm={24} md={24} lg={24}>
        <Breadcrumb data={FollowUpReportListCrumb} title={t('รายงานการติดตามผลการดำเนินงาน')} />
        <FollowUpReportListFilter onFilterFollowUpReportListFilter={onFilterFollowUpReportListFilter} menu={menu} />
        {/* <SendEmailReportModal
          menu={menu}
          open={visibleSendMail}
          close={(e) => {
            setVisibleSendMail(e)
          }}
          fileType={{ pdf: true, word: true, excel: true, csv: true }}
          setInputSendEmail={(e) => setInputSendEmail(e)}
          mailValue={mailValue}
        /> */}
        {/* <FollowUpReportExport
          menu={menu}
          closeModal={(e) => setFollowUpReportExportModal(e)}
          followUpReportExportModal={followUpReportExportModal}
          dataForExport={dataForExport}
          setInputForExport={setInputForExport}
        /> */}
        <div style={{ marginBottom: '2rem' }}>
          <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col sm={24} md={24} lg={24} xl={24}>
                <Datatable
                  columns={Columns()}
                  data={[...dataLists]}
                  total={count}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  enableScroll={{ x: true, y: false }}
                  pageSize={limit}
                  page={page}
                  searchCustom={true}
                  handleTableChange={(e) => {
                    const reNewField = [
                      { projectTypeName: displayText('projectTypeNameTH', 'projectTypeNameEN') },
                      { projectName: displayText('projectNameTH', 'projectNameEN') },
                      { issue: displayText('issueTH', 'issueEN') },
                      { findingTypeName: displayText('findingTypeNameTH', 'findingTypeNameEN') },
                      { suggest: displayText('suggestTH', 'suggestEN') },
                      { planDetail: displayText('planDetailTH', 'planDetailEN') },
                      { riskLevelName: displayText('riskLevelNameTH', 'riskLevelNameEN') },
                      { actionDetail: displayText('actionDetailTH', 'actionDetailEN') },
                      { actionStatusName: displayText('actionStatusNameTH', 'actionStatusNameEN') },
                    ]
                    setSort(handleSort(e?.sorter, reNewField))
                  }}
                />
              </Col>
            </Row>
          </CardNew>
        </div>
      </Col>
    </>
  )
}

export default FollowUpReportList
