import { Space } from 'antd'
import React from 'react'
import { ButtonNew } from '../../../../../components/button/Button'
import { colors } from '../../../../../configs/styles.config'
import { displayText } from '../../../../../utilitys/helper'

export const Columns = ({ handleClick }) => {
  return [
    {
      title: displayText('การเตรียมงานตรวจสอบ', 'Pre audit'),
      dataIndex: 10,
      width: '110px',
      align: 'center',
      render: (item) => {
        return (
          <Space size={[8, 8]}>
            <ButtonNew type="miniModule" onClick={() => handleClick(item.projectCode)} iconrightname={'ArrowRight'}>
              <div>{item.countStep ? item.countStep : '-'} โครงการ</div>
              <div style={{ color: colors.error }}>ล่าช้า {item.countStepLate ? item.countStepLate : '-'} โครงการ</div>
            </ButtonNew>
          </Space>
        )
      },
    },
    {
      title: displayText('การเปิดงานตรวจสอบ', 'Open Project'),
      align: 'center',
      dataIndex: 20,
      width: '100px',
      render: (item) => {
        return (
          <Space size={[8, 8]}>
            <ButtonNew type="miniModule" onClick={() => handleClick(item.projectCode)}>
              <div>{item.countStep ? item.countStep : '-'} โครงการ</div>
              <div style={{ color: colors.error }}>ล่าช้า {item.countStepLate ? item.countStepLate : '-'} โครงการ</div>
            </ButtonNew>
          </Space>
        )
      },
    },
    {
      title: displayText('การปฎิบัติงานตรวจสอบ', 'Fieldwork'),
      align: 'center',
      dataIndex: 30,
      width: '130px',
      render: (item) => {
        return (
          <Space size={[8, 8]}>
            <ButtonNew type="miniModule" onClick={() => handleClick(item.projectCode)}>
              <div>{item.countStep ? item.countStep : '-'} โครงการ</div>
              <div style={{ color: colors.error }}>ล่าช้า {item.countStepLate ? item.countStepLate : '-'} โครงการ</div>
            </ButtonNew>
          </Space>
        )
      },
    },
    {
      title: displayText('การประชุมปิดการตรวจสอบ', 'Exit Meeting'),
      align: 'center',
      dataIndex: 40,
      width: '100px',
      render: (item) => {
        return (
          <Space size={[8, 8]}>
            <ButtonNew type="miniModule" onClick={() => handleClick(item.projectCode)}>
              <div>{item.countStep ? item.countStep : '-'} โครงการ</div>
              <div style={{ color: colors.error }}>ล่าช้า {item.countStepLate ? item.countStepLate : '-'} โครงการ</div>
            </ButtonNew>
          </Space>
        )
      },
    },
    {
      title: displayText('การประชุม คตส.', 'AC'),
      align: 'center',
      dataIndex: 50,
      width: '180px',
      render: (item) => {
        return (
          <Space size={[8, 8]}>
            <ButtonNew type="miniModule" onClick={() => handleClick(item.projectCode)}>
              <div>{item.countStep ? item.countStep : '-'} โครงการ</div>
              <div style={{ color: colors.error }}>ล่าช้า {item.countStepLate ? item.countStepLate : '-'} โครงการ</div>
            </ButtonNew>
          </Space>
        )
      },
    },
    {
      title: displayText('การจัดทำรายงาน', 'Reporting'),
      align: 'center',
      dataIndex: 60,
      width: '160px',
      render: (item) => {
        return (
          <Space size={[8, 8]}>
            <ButtonNew type="miniModule" onClick={() => handleClick(item.projectCode)}>
              <div>{item.countStep ? item.countStep : '-'} โครงการ</div>
              <div style={{ color: colors.error }}>ล่าช้า {item.countStepLate ? item.countStepLate : '-'} โครงการ</div>
            </ButtonNew>
          </Space>
        )
      },
    },
    {
      title: displayText('การจัดเก็บกระดาษทำการ', 'Working Paper'),
      align: 'center',
      dataIndex: 70,
      width: '140px',
      render: (item) => {
        return (
          <Space size={[8, 8]}>
            <ButtonNew type="miniModule" onClick={() => handleClick(item.projectCode)}>
              <div>{item.countStep ? item.countStep : '-'} โครงการ</div>
              <div style={{ color: colors.error }}>ล่าช้า {item.countStepLate ? item.countStepLate : '-'} โครงการ</div>
            </ButtonNew>
          </Space>
        )
      },
    },
  ]
}
