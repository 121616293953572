import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Row, Col } from 'antd'
import * as _ from 'lodash'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { Alert } from '../../../components/alert/Alert'
import PwddRevisionHistoryFilter from './components/filter/PwddRevisionHistoryFilter'
import { GET_LOGFILE_CHANGEPASSWORD_PAGINATION } from './graphql/Query.js'
import { handleSort } from '../../../utilitys/pagination'
import { displayError } from '../../../utilitys/helper'
import { dateTimeDisplay } from '../../../utilitys/dateHelper'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}

const PwddRevisionHistoryList = (props) => {
  const { t } = useTranslation()
  const { menu } = props

  const breadcrumbList = [
    { label: t('งานประวัติการแก้ไขข้อมูล'), path: '/logs/horodinformation' },
    { label: t('ประวัติการแก้ไขรหัสผ่าน') },
  ]

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [sort, setSort] = useState([])
  const [filters, setFilters] = useState({})

  const [cellPagination, { data: dataPagination, loading: loadingPagination, error: errorPagination }] = useLazyQuery(
    GET_LOGFILE_CHANGEPASSWORD_PAGINATION,
  )
  useEffect(() => {
    cellPagination({
      variables: {
        input: encryptInput({
          filters: { ...filters },
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort, filters])

  const Column = [
    {
      title: t('ชื่อผู้ใช้งาน'),
      dataIndex: 'username',
      sorter: true,
      width: '15%',
      className: 'text-nowrap',
    },
    {
      title: t('อีเมล'),
      dataIndex: 'email',
      // sorter: true,
      width: '15%',
      className: 'text-nowrap',
    },
    {
      title: t('ชื่อ-นามสกุล'),
      dataIndex: 'fullname',
      sorter: true,
      width: '10%',
      className: 'text-nowrap',
    },
    {
      title: t('วันเวลาที่แก้ไข'),
      dataIndex: 'changeDate',
      sorter: true,
      width: '15%',
      className: 'text-nowrap',
    },
  ]

  useEffect(() => {
    if (dataPagination) {
      let response = dataPagination.logfilePasswordChangePaginationGet
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (item, key) => {
          return {
            key: key + 1,
            username: item.username,
            email: item.email,
            fullname: item.fullname,
            changeDate: dateTimeDisplay(item.changeDate),
          }
        }),
      )
    }
    if (errorPagination) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorPagination)),
        onOk() {},
      })
    }
  }, [dataPagination])

  return (
    <>
      {loadingPagination && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('ประวัติการแก้ไขรหัสผ่าน')} />
      <PwddRevisionHistoryFilter
        menu={menu}
        getValue={(e) => {
          setFilters({
            // email: e.email,
            username: e.username,
            fullname: e.fullname,
            dateStart: e.dateStart,
            dateEnd: e.dateEnd,
          })
        }}
      />
      <CardNew topic={t('รายการ Log การแก้ไขรหัสผ่าน')} icon="List" toggledrop={'false'}>
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            <Datatable
              columns={Column}
              data={[...dataLists]}
              total={count}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              paginationCustom={true}
              searchCustom={true}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => {
                const reNewField = []
                setSort(handleSort(e?.sorter, reNewField))
              }}
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default PwddRevisionHistoryList
