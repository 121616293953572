import { gql } from '@apollo/client'

export const FOLLOWUP_LIST = gql`
  query followUplist($input: InputPagenationForFollowAudit!) {
    followUplist(data: $input) {
      result {
        projectCode
        projectNameTH
        projectNameEN
        projectRefNameTH
        projectRefNameEN
        riskFormTH
        riskFormEN
        dateStart
        dateEnd
        organizationNameTH
        organizationNameEN
        namePrefixTH
        namePrefixEN
        firstNameTH
        lastNameTH
        firstNameEN
        lastNameEN
        buttoStatus
      }
      count
      page
      limit
    }
  }
`

export const DATA_FOLLOW_UP_MANAGER_TAB = gql`
  query dataFollowUpManagerTab($projectCode: String!) {
    dataFollowUpManagerTab(projectCode: $projectCode) {
      followUpId
      projectCode
      mdNumber
      dateSendReport
      dateEntryMC
      remarkTH
      remarkEN
      fileDetail
      refFile {
        fileId
        originalname
        name
      }
    }
  }
`

export const DATAFOLLOWUP_ACTIVITY_TAB_LIST = gql`
  query dataFollowUpActivityTabList($input: InputPagenationForFollowAudit!) {
    dataFollowUpActivityTabList(data: $input) {
      result {
        deleteAbled
        activityId
        issueId
        issueTH
        issueEN
        suggestId
        suggestTH
        suggestEN
        activityTH
        activityEN
        organizationNameTH
        organizationNameEN
        organizationParentNameTH
        organizationParentNameEN
        meetingDate
        agendaNameTH
        agendaNameEN
        mdNumber
        dateSendReport
        expectDate
        documentNo
        signingDate
      }
      count
      page
      limit
    }
  }
`
export const GET_FOLLOW_UP_AUDIT_REPORT_EXPORT = gql`
  query getFollowUpAuditReportExport($input: InputFollowAuditExport!) {
    getFollowUpAuditReportExport(data: $input) {
      message
      fileUrl
    }
  }
`

export const DATA_FOLLOW_UP_ACTIVITY_TAB = gql`
  query dataFollowUpActivityTab($activityId: String!) {
    dataFollowUpActivityTab(activityId: $activityId) {
      activityId
      projectCode
      activityTH
      activityEN
      expectDate
      issueId
      rateRiskRuleCode
      rateRiskConCode
      organizationCode
      organizationNameTH
      organizationNameEN
      personnelNameTH
      personnelNameEN
      personnelCode
      rateFacProCode
      remarkTH
      remarkEN
      suggestId
      files
      fileDetails
      refFile {
        fileId
        originalname
        name
      }
    }
  }
`

export const DATAFOLLOWUP_RESULT_FOLLOW_TAB_LIST = gql`
  query dataFollowUpResultFollowTabList($input: InputPagenationForFollowAudit!) {
    dataFollowUpResultFollowTabList(data: $input) {
      result {
        issueId
        issueTH
        issueEN
        issueStatusNameTH
        issueStatusNameEN
        subItem {
          actionId
          suggestTH
          suggestEN
          activityTH
          activityEN
          actionStatusNameTH
          actionStatusNameEN
          planDetailTH
          planDetailEN
          expectDate
          organizationNameTH
          organizationNameEN
          ideaStatusNameTH
          ideaStatusNameEN
          reasonTH
          reasonEN
          ideaSucceed
          ideaStatusCode
        }
      }
      count
      page
      limit
    }
  }
`

export const DATAFOLLOWUP_RESULT_FOLLOW_TAB_FORM = gql`
  query dataFollowUpResultFollowTabForm($input: DataFollowUpResultFollowTabFormInput!) {
    dataFollowUpResultFollowTabForm(data: $input) {
      projectCode
      actionId
      rateFacProCode
      rateFacProNameTH
      rateFacProNameEN
      issueId
      issueTH
      issueEN
      suggestId
      suggestTH
      suggestEN
      activityId
      activityTH
      activityEN
      actionStatusCode
      actionStatusNameTH
      actionStatusNameEN
      actionDetailTH
      actionDetailEN
      actionDate
      planDetailTH
      planDetailEN
      expectDate
      reasonTH
      reasonEN
      ideaStatusCode
      historyTH
      historyEN
      remarkTH
      remarkEN
      files
      fileDetails
      refFile {
        fileId
        originalname
        name
      }
    }
  }
`

export const DATAFOLLOWUP_CLOSE_ISSUE_TAB_LIST = gql`
  query dataFollowUpCloseIssueTabList($input: InputPagenationForFollowAudit!) {
    dataFollowUpCloseIssueTabList(data: $input) {
      result {
        issueId
        issueTH
        issueEN
        issueStatusNameTH
        issueStatusNameEN
        subItem {
          activityId
          suggestTH
          suggestEN
          activityTH
          activityEN
          followUpStatusCode
          followUpStatusNameTH
          followUpStatusNameEN
        }
      }
      count
      page
      limit
    }
  }
`

export const DATAFOLLOW_NUMBER_TAB_LIST = gql`
  query dataFollownumberTabList($input: InputPagenationForFollowAudit!) {
    dataFollownumberTabList(data: $input) {
      result {
        followTimeId
        followTime
        issueId
        issueTH
        issueEN
        suggestId
        suggestTH
        suggestEN
        activityTH
        activityEN
        followUpDetail
        expectDate
        createdAt
        actionDetailTH
      }
      count
      page
      limit
    }
  }
`
