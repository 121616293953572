import { gql } from '@apollo/client'

export const DELETE_TIMESHET_BY_ID = gql`
  mutation timeSheetDeleteById($timeSheetId: String!) {
    timeSheetDeleteById(timeSheetId: $timeSheetId)
  }
`

export const DELETE_TIMESHEET_REQUEST_BY_ID = gql`
  mutation timeSheetRequestDeleteById($requestId: String!) {
    timeSheetRequestDeleteById(requestId: $requestId)
  }
`

export const SAVE_TIMESHEET = gql`
  mutation timeSheetSave($input: TimeSheetsInput!) {
    timeSheetSave(input: $input)
  }
`

export const SAVE_TIMESHEET_REQUEST = gql`
  mutation timeSheetRequestSave($input: TimeSheetRequestInput!) {
    timeSheetRequestSave(input: $input)
  }
`
