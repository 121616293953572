import { gql } from '@apollo/client'
export const SAVE_AUDIT_PLAN = gql`
  mutation auditPlanSave($input: AddAuditPlanInput!) {
    auditPlanSave(data: $input) {
      planId
    }
  }
`

export const DELETE_AUDIT_PLAN = gql`
  mutation auditPlanDelete($input: AuditPlanDeleteInput!) {
    auditPlanDelete(data: $input) {
      planCode
    }
  }
`
