import React from 'react'
import { Trans } from 'react-i18next'
// import { Alert } from '../../../../components/alert/Alert'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { Status } from '../../../../components/status/Status'
import { displayText } from '../../../../utilitys/helper'

export const Columns = ({ fncOnpressExport, menu }) => {
  return [
    {
      title: <Trans>ลำดับ</Trans>,
      dataIndex: 'no',
      //   sorter: true,
      fixed: 'left',
      width: '100px',
      // render: (item, record) => {
      //   return <div style={{ textAlign: 'start' }}>{record.no}</div>
      // },
    },
    {
      title: <Trans>ชื่อผู้ใช้งาน</Trans>,
      dataIndex: 'username',
      fixed: 'left',
      sorter: true,
      width: '200px',
    },
    {
      title: <Trans>อีเมล</Trans>,
      dataIndex: 'email',
      // sorter: true,
      width: '200px',
    },

    {
      title: <Trans>ชื่อ-นามสกุล</Trans>,
      dataIndex: 'fullname',
      sorter: true,
      width: '250px',
    },
    {
      title: <Trans>หน่วยงานที่สังกัด</Trans>,
      dataIndex: 'organizationName',
      sorter: true,
      width: '250px',
    },
    {
      title: <Trans>กลุ่มสิทธิ์</Trans>,
      dataIndex: 'roleList',
      sorter: true,
      width: '200px',
      render: (item) => {
        return item.map((val, idx) => {
          const name = displayText(val.roleNameTH, val.roleNameEN)
          return (
            <React.Fragment key={`${name}${idx}`}>
              {name}
              {idx < item.length && <br />}
            </React.Fragment>
          )
        })
      },
    },
    {
      title: <Trans>สถานะ</Trans>,
      dataIndex: 'isActive',
      sorter: true,
      width: '150px',
      render: (item) => {
        return <Status text={item === '1' ? 'ใช้งาน' : 'ไม่ใช้งาน'}></Status>
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '100px',
      render: (item, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup
              menu={menu}
              icons={[
                {
                  type: 'download',
                  onClick: () => {
                    fncOnpressExport(record)
                  },
                },
              ]}
            />
          </div>
        )
      },
    },
  ]
}
