import React from 'react'
import { Modal } from 'antd'
import { Plus, Edit2, FileText } from 'react-feather'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { ModalStyles } from './ModalStyles'

export const ModalNew = (props) => {
  let width = ''
  let height = ''
  const { t } = useTranslation()
  switch (props.type) {
    case 'medium':
      width = '914px'
      break
    case 'big':
      width = '974px'
      break

    default:
      break
  }

  let icon = ''
  if (props.typeAction === 'view') {
    icon = <FileText size={22} />
  } else if (props.typeAction === 'edit') {
    icon = <Edit2 size={22} />
  } else {
    icon = <Plus size={22} />
  }

  return (
    <>
      <ModalStyles typeAction={props.typeAction}>
        <Modal
          title={
            <>
              {props.customIcon ? props.customIcon : icon} {props.testTitle}
            </>
          }
          maskClosable={false}
          centered
          visible={props.visible}
          className="modalStyleNew"
          width={width ? width : 755}
          onOk={props.onSubmit ? props.onSubmit : ''}
          onCancel={() => {
            props.onClose(false)
          }}
          getContainer={false}
          bodyStyle={{ minHeight: height ? height : '128px' }}
          okText={t(props.btnName ? props.btnName : 'บันทึก')}
          cancelText={t('ยกเลิก')}
          {...props}
        >
          {props.children}
        </Modal>
      </ModalStyles>
    </>
  )
}

ModalNew.propTypes = {
  type: PropTypes.string,
  testTitle: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  typeAction: PropTypes.string,
}
