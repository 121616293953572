import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { ButtonNew } from '../../../../components/button/Button'
import { Datatable } from '../../../../components/datatable/Datatable'
import { InputNew } from '../../../../components/input/Input'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { Alert } from '../../../../components/alert/Alert'
import { UploadNew } from '../../../../components/upload/Upload.jsx'
import { downloadFile } from '../../../../utilitys/files'
import { AUDITPROJECT_FILE_SAVE } from '../graphql/Mutation'
import { GET_AUDITPROJECT_FILES } from '../graphql/Query'
import { encryptInput } from '../../../../utilitys/crypto'
import { v4 as uuidv4 } from 'uuid'
import { displayError } from '../../../../utilitys/helper'

// function delete file
const deleteFile = (fileName) => {
  fetch(process.env.REACT_APP_API_UPLOAD + `/deleteFile/${fileName}`, {
    method: 'GET',
  }).catch(() => {})
}

const FormAuditProjectsDocument = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const disabled = props.typeForm === 'view' ? true : false
  const [, /* idFile */ setIdFile] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])

  const [dataList, setDataLists] = useState([])
  const [download, setDownload] = useState(false)
  const [deleteting, setDeleteting] = useState(false)

  const ResponsiveOut = {
    md: 24,
    lg: 24,
  }

  const Responsive = {
    md: 24,
    lg: 24,
  }

  const validateURL = (link) => {
    if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) {
      return link
    } else {
      return 'https://' + link
    }
  }

  const Columns = [
    {
      title: t('ชื่อเอกสาร'),
      dataIndex: 'filename',
      width: '30%',
      render: (item, record) => {
        return props.typeForm === 'edit' ? (
          <div>
            <Form.Item hidden={true} name={[record.key, 'fileId']} initialValue={record.fileId}>
              <InputNew disabled={disabled} />
            </Form.Item>
            <Form.Item
              name={[record.key, 'filename']}
              initialValue={item}
              rules={[{ required: true, message: t('กรุณากรอกชื่อเอกสาร') }]}
            >
              <InputNew
                placeholder={t('ชื่อเอกสาร')}
                disabled={disabled}
                onChange={(e) => {
                  form.setFieldsValue({ [record.key]: { filename: e.target.value } })
                }}
              />
            </Form.Item>
          </div>
        ) : (
          <>{item}</>
        )
      },
    },
    {
      title: t('ลิงค์'),
      dataIndex: 'links',
      width: '40%',
      render: (item, record) => {
        return props.typeForm === 'edit' ? (
          <div>
            <Form.Item name={[record.key, 'links']} initialValue={item}>
              <InputNew
                placeholder={t('ระบุลิงค์')}
                onChange={(e) => {
                  form.setFieldsValue({ [record.key]: { links: e.target.value } })
                }}
              />
            </Form.Item>
          </div>
        ) : item ? (
          <a href={validateURL(item)} target="_blank" rel="noreferrer" style={{ color: '#2b4990' }}>
            <u>{item}</u>
          </a>
        ) : (
          '-'
        )
      },
    },
    {
      title: t('เอกสารอ้างอิง'),
      dataIndex: 'fileId',
      width: '25%',
      align: 'left',
      render: (item, record, index) => {
        return (
          <div>
            <Form.Item name={[record.key, 'fileId']}>
              <UploadNew
                key={index}
                // setIdFile={(e) => {
                //   onUpload(record, e, index)
                // }}
                setIdFile={setIdFile}
                idFile={[record.fileId] || []}
                defaults={[record.fileId] || []}
                typeFile="file"
                inline={true}
                multiRow={true}
                setFileData={(e) => {
                  onUpload(record, e, index)
                }}
                actionType={props.typeForm}
              />
            </Form.Item>
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '5%',
      render: (item, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {props.typeForm === 'view' && record.fileId ? (
              <ButtonGroup
                menu={menu}
                icons={[
                  {
                    type: 'download',
                    onClick: () => {
                      item &&
                        downloadFile(item.originalname, item.name, () => {
                          setDownload(false)
                        })
                    },
                  },
                ]}
              />
            ) : (
              <ButtonGroup
                menu={menu}
                icons={(record.fileId
                  ? [
                      {
                        type: 'download',
                        onClick: () => {
                          item &&
                            downloadFile(item.originalname, item.name, () => {
                              setDownload(false)
                            })
                        },
                      },
                    ]
                  : []
                ).concat([
                  {
                    type: 'delete',
                    onClick: () => {
                      setIdFileDelete([...idFileDelete, record.fileId]), onRemove(item?.name, record)
                    },
                  },
                ])}
              />
            )}
          </div>
        )
      },
    },
  ]

  /* ---------------- API ----------- */
  const [getAuditProjectFilesFn, getAuditProjectFiles] = useLazyQuery(GET_AUDITPROJECT_FILES)
  const [auditProjectFileSaveFn, auditProjectFileSave] = useMutation(AUDITPROJECT_FILE_SAVE)

  /* ---------------- ACTION ----------- */
  const onFinish = async (input) => {
    showConfirm(input)
  }

  const showConfirm = (input) => {
    const fileDetail = Object.values(input).map((item) => {
      return {
        fileId: item.fileId || '',
        name: item.filename,
        links: item.links,
      }
    })

    const listFileId = fileDetail.map((item) => item.fileId).filter((x) => x)

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        auditProjectFileSaveFn({
          variables: {
            input: encryptInput({
              menuCode: 'AP03',
              projectCode: props.refCode,
              files: listFileId.join(),
              fileDetail: JSON.stringify(fileDetail),
              listFileDelete: idFileDelete,
              // action: props.typeForm === 'add' ? 'create' : props.typeForm,
            }),
          },
        })
      },
      onCancel() {},
    })
  }

  const handleAdd = () => {
    const uid = uuidv4()

    const fields = form.getFieldsValue()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    result.push({
      key: uid,
      fileId: null,
      filename: null,
      links: null,
    })
    form.setFieldsValue(result)

    let newData = [...dataList]
    newData.push({
      key: uid,
      fileId: null,
      filename: null,
      links: null,
    }) //ต้องเพิ่มตาม field

    setDataLists(newData)
  }

  const onUpload = (record, value, index) => {
    const data = [...dataList]
    data[index].fileId = value.uid
    data[index].tools = { originalname: value.originalname, name: value.name }

    setDataLists([...data])
    setIdFile(value.uid)
    form.setFieldsValue({ [record.key]: { fileId: value.uid } })
  }

  const onRemove = (filename, record) => {
    let newData = dataList.filter((item) => item.key !== record.key)
    setDataLists(newData)

    if (props.typeForm === 'add') {
      setDeleteting(true)
      if (filename) deleteFile(filename)
    }
  }

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (props.refCode) {
      getAuditProjectFilesFn({
        variables: {
          projectCode: encryptInput(props.refCode),
        },
      })
    }
  }, [props.refCode])

  useEffect(() => {
    if (getAuditProjectFiles.data) {
      const data = getAuditProjectFiles.data.getAuditProjectFiles
      setDataLists(
        data.map((item) => {
          const uid = uuidv4()

          const dataJob = {
            key: uid,
            fileId: item.fileId,
            filename: item.filename,
            links: item.links,
            tools: item.refFile,
          }

          return dataJob
        }),
      )
    }
  }, [getAuditProjectFiles.data])

  useEffect(() => {
    if (auditProjectFileSave.data) {
      const response = auditProjectFileSave.data.auditProjectFileSave
      const dataContent = {
        content: '',
      }
      if (response.detail === 'Please reapprove agian') {
        dataContent.content = t('กรุณาอนุมัติโครงการใหม่')
      }

      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {},
        ...dataContent,
      })
    }
    if (auditProjectFileSave.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(auditProjectFileSave.error)),
        onOk() {},
      })
    }
  }, [auditProjectFileSave.data])

  return (
    <>
      {(download || deleteting || auditProjectFileSave.loading) && <SpinnersNew />}
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ md: { span: 24 }, lg: { span: 24 } }}
        wrapperCol={{ md: { span: 24 }, lg: { span: 24 } }}
        name="formtab1"
      >
        <Row justify="center">
          <Col {...ResponsiveOut}>
            <Row justify="center">
              <Col {...Responsive}>
                <Datatable
                  // enableScroll={{ x: true, y: false }}
                  // scroll={{ x: 1000 }}
                  columns={Columns}
                  data={dataList}
                  pagination={false}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '5px', marginBottom: '10px' }}>
              {props.typeForm !== 'view' && (
                <Col {...Responsive}>
                  <ButtonNew type="addMultiple" onClick={() => handleAdd()}>
                    {t('เพิ่มเอกสาร')}
                  </ButtonNew>
                </Col>
              )}
            </Row>
            <Row gutter={[12, 20]} justify="center" style={{ marginTop: '15px' }}>
              {props.typeForm !== 'view' && (
                <Col>
                  <ButtonNew type="primary" roles={{ type: props.typeForm, menu: menu }} htmlType="submit">
                    {t('บันทึก')}
                  </ButtonNew>
                </Col>
              )}
              <Col>
                <ButtonNew
                  type="back"
                  onClick={() => {
                    navigate('/auditplanning/offplanprojects/list')
                  }}
                >
                  {t('ย้อนกลับ')}
                </ButtonNew>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default FormAuditProjectsDocument
