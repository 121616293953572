import { gql } from '@apollo/client'

export const GET_CLOSING_MEETING_RESULTS_PAGINATION = gql`
  query GetClosingMeetingResultsPagination($input: ClosingMeetingResultsPaginationInput!) {
    getClosingMeetingResultsPagination(input: $input) {
      result {
        no
        projectCode
        projectNameTH
        projectNameEN
        dateStart
        dateEnd
        projectRefNameTH
        projectRefNameEN
        personnelNameTH
        personnelNameEN
        organizationNameTH
        organizationNameEN
        adOrganizationNameTH
        adOrganizationNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_CLOSING_MEETING_RESULTS_EXPORT = gql`
  query ClosingMeetingResultsExport($input: ClosingMeetingResultsExportInput!) {
    closingMeetingResultsExport(input: $input) {
      message
      fileUrl
    }
  }
`
