import styled from 'styled-components'
import { colors } from '../../configs/styles.config'

export const ModalStyles = styled.div`
  .modalStyleNew {
    .ant-modal-content {
      border-radius: 8px;
      .ant-modal-header {
        background: #eee;
        height: 50px;
        border-radius: 8px 8px 0px 0px;
        .ant-modal-title {
          display: flex;
          svg {
            margin-right: 8px;
          }
        }
      }
      .ant-modal-footer {
        display: flex;
        justify-content: center;
        .ant-btn {
          order: 2;
          border-radius: 3px;
          font-size: 14px;
          width: 98px;
          height: 40px;
          background-color: #fff;
          border: 1px solid #ff9e2c;
          color: #ff9e2c;
          &:hover {
            transition: 200ms;
            background-color: #fff9f4;
          }
          &:active {
            transition: 200ms;
            background-color: #ff9e2c;
            color: #fff;
          }
        }
        .ant-btn-primary {
          display: ${(props) => (props.typeAction === 'view' ? 'none' : '')};
          margin-right: 10px;
          order: 1;
          border-radius: 3px;
          font-size: 14px;
          width: 98px;
          height: 40px;
          background-color: #2b4990;
          border: 1px solid #2b4990;
          color: #fff;
          &:hover {
            transition: 200ms;
            background-color: #2a51af;
          }
          &:active {
            transition: 200ms;
            background-color: ${colors.primaryActive};
          }
        }
      }
    }
  }
`
