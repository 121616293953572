import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'antd'
import { Datatable } from '../../../../components/datatable/Datatable'
import { useLocation } from 'react-router-dom'
import { ButtonNew } from '../../../../components/button/Button'
import { useTranslation } from 'react-i18next'
import { Status } from '../../../../components/status/Status'
import { ModalNew } from '../../../../components/modal/Modal'
import { LabelNew } from '../../../../components/label/Label'
import SelectRateRiskFactorProcessModel from '../../../../components/inputfromapi/selectrateriskfactorprocessmodel/SelectRateRiskFactorProcessModel'
import SelectRateRiskRulesModel from '../../../../components/inputfromapi/selectrateriskrulemodel/SelectRateRiskRuleModel'
import SelectRateRiskControl from '../../../../components/inputfromapi/selectrateriskcontrol/SelectRateRiskControl'
import SelectCauseOfIssue from '../../../../components/inputfromapi/selectcauseofIssue/SelectCauseOfIssue'
import SelectAuditIssues from '../../../../components/inputfromapi/selectauditIssues/SelectAuditIssues'
import { TextAreaNew } from '../../../../components/textarea/TextArea'
import { Alert } from '../../../../components/alert/Alert'
import { CREATE_AUDIT_CAUSE_OF_ISSUE, EDIT_AUDIT_CAUSE_OF_ISSUE, DELETE_CAUSE_ISSUE } from '../graphql/Mutation'
import { GET_AUDIT_CAUSE_OF_ISSUE, GET_AUDIT_CAUSE_OF_ISSUE_BY_ID } from '../graphql/Query'
import { useMutation, useLazyQuery } from '@apollo/client'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayText } from '../../../../utilitys/helper'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { InputNew } from '../../../../components/input/Input'

const SoiAuditCommitteeTabCauseOfIssue = (props) => {
  const currentLang = localStorage.getItem('lang')
  const { t } = useTranslation()
  const { state } = useLocation()
  const { action } = state
  const [currentAction, setcurrentAction] = useState(action)
  const [modalOpen, setmodalOpen] = useState(false)
  const [rateFacProCode, setrateFacProCode] = useState('')
  const [rateRiskRuleCode, setrateRiskRuleCode] = useState('')
  const [callData, dataResponse] = useLazyQuery(GET_AUDIT_CAUSE_OF_ISSUE)
  const [callCauseOfIssue, causeOfIssue] = useLazyQuery(GET_AUDIT_CAUSE_OF_ISSUE_BY_ID)
  const [callSave, saveResponse] = useMutation(CREATE_AUDIT_CAUSE_OF_ISSUE)
  const [callEdit, editResponse] = useMutation(EDIT_AUDIT_CAUSE_OF_ISSUE)
  const [callDelete, deleteResponse] = useMutation(DELETE_CAUSE_ISSUE)
  const [form] = Form.useForm()
  const [dataList, setDataList] = useState([])
  const [isDisable, setisDisable] = useState(action === 'view' ? true : false)

  useEffect(() => {
    setisDisable(currentAction === 'view' ? true : false)
  }, [currentAction])

  useEffect(() => {
    callData({
      variables: encryptInput({
        projectCode: state.projectCode,
      }),
    })
  }, [])

  useEffect(() => {
    if (dataResponse.data) {
      let i = 1
      const x = dataResponse.data.getAuditCauseOfIssue
      const data = x.map((z) => {
        return {
          key: i++,
          ...z,
          issue: displayText(z.issueTH, z.issueEN),
          causeDetail: displayText(z.causeDetailTH, z.causeDetailEN),
          rateFacProName: `${z.rateFacProCode} : ${displayText(z.rateFacProNameTH, z.rateFacProNameEN)}`,
          causeName: displayText(z.causeNameTH, z.causeNameEN),
        }
      })
      setDataList(data)
    }
  }, [dataResponse.data])

  useEffect(() => {
    if (causeOfIssue.data) {
      const x = causeOfIssue.data.getAuditCauseOfIssueById
      form.setFieldsValue({
        causeId: x.causeId,
        rateFacProCode: x.rateFacProCode,
        rateRiskRuleCode: x.rateRiskRuleCode,
        rateRiskConCode: x.rateRiskConCode,
        issueId: x.issueId,
        causeCode: x.causeCode,
        causeDetail: displayText(x.causeDetailTH, x.causeDetailEN),
      })
    }
  }, [causeOfIssue.data])

  const formName = 'formAuditCauseOfIssue'

  const columns = [
    {
      title: t('ประเด็น/ข้อสังเกตุ'),
      dataIndex: 'issue',
      width: '15%',
      className: 'text-nowrap',
    },
    {
      title: t('ประเภทสาเหตุ'),
      dataIndex: 'causeName',
      width: '10%',
      className: 'text-nowrap',
    },
    {
      title: t('คำอธิบาย'),
      dataIndex: 'causeDetail',
      width: '15%',
      className: 'text-nowrap',
    },
    {
      title: t('ผลกระทบ/ความเสี่ยง'),
      dataIndex: 'rateFacProName',
      width: '15%',
      className: 'text-nowrap',
    },
    {
      title: t('สถานะ'),
      dataIndex: 'isActive',
      width: '10%',
      className: 'text-nowrap',
      render: (item) => {
        return <Status text={item === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />
      },
    },
    {
      title: '',
      dataIndex: '',
      width: '10%',
      render: (record) => {
        let list = [
          {
            type: 'view',
            onClick: () => {
              setcurrentAction('view')
              setmodalOpen(true)
              callCauseOfIssue({
                variables: encryptInput({ causeId: record.causeId }),
              })
            },
          },
        ]
        if (state.action !== 'view') {
          list.push(
            {
              type: 'edit',
              onClick: () => {
                setcurrentAction('edit')
                setmodalOpen(true)
                callCauseOfIssue({
                  variables: encryptInput({ causeId: record.causeId }),
                })
              },
            },
            {
              type: 'delete',
              onClick: () => {
                Alert({
                  type: 'confirm',
                  title: t('ต้องการยืนยันการลบ?'),
                  onOk() {
                    callDelete({
                      variables: {
                        input: encryptInput({ causeId: record.causeId }),
                      },
                    }).then((result) => {
                      if (result?.data.SoiAuditCommitteeDeleteCause) {
                        Alert({
                          type: 'success',
                          title: t('ลบสำเร็จ'),
                          onOk() {
                            form.resetFields()
                            closeModal()
                            callData({
                              variables: encryptInput({
                                projectCode: state.projectCode,
                              }),
                            })
                          },
                        })
                      }
                    })
                  },
                  onCancel() {},
                })
              },
            },
          )
        }
        return <ButtonGroup menu={props.menu} size={18} icons={list} />
      },
    },
  ]

  const closeModal = () => {
    setmodalOpen(false)
  }

  const onSubmit = (data) => {
    let input = {
      ...data,
    }
    input[`causeDetail${currentLang.toUpperCase()}`] = data.causeDetail

    delete input.causeDetail
    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกสาเหตุ'),
      onOk() {
        if (currentAction === 'add') {
          delete input.causeId
          callSave({
            variables: {
              input: encryptInput(input),
            },
          }).then((result) => {
            if (result?.data) {
              Alert({
                type: 'success',
                title: t('บันทึกข้อมูลสำเร็จ'),
                onOk() {
                  form.resetFields()
                  setmodalOpen(false)
                  callData({
                    variables: encryptInput({
                      projectCode: state.projectCode,
                    }),
                  })
                },
              })
            }
          })
        } else {
          callEdit({
            variables: {
              input: encryptInput(input),
            },
          }).then((result) => {
            if (result?.data) {
              Alert({
                type: 'success',
                title: t('บันทึกข้อมูลสำเร็จ'),
                onOk() {
                  form.resetFields()
                  setmodalOpen(false)
                  callData({
                    variables: encryptInput({
                      projectCode: state.projectCode,
                    }),
                  })
                },
              })
            }
          })
        }
      },
      onCancel() {},
    })
  }

  return (
    <>
      {(dataResponse.loading || saveResponse.loading) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Datatable
            columns={columns}
            data={dataList}
            btnAdd={
              action === 'view' ? null : (
                <>
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: props.menu }}
                    style={{ marginRight: '12px' }}
                    onClick={() => {
                      setcurrentAction('add')
                      form.resetFields()
                      setmodalOpen(true)
                    }}
                  >
                    {t('สาเหตุ')}
                  </ButtonNew>
                </>
              )
            }
          />
        </Col>
      </Row>
      <ModalNew
        visible={modalOpen}
        testTitle={t('เพิ่มสาเหตุประเด็น')}
        onClose={closeModal}
        onSubmit={form.submit}
        typeAction={currentAction}
      >
        {(causeOfIssue.loading || editResponse.loading || deleteResponse.loading) && <SpinnersNew />}
        <Form
          onFinish={onSubmit}
          form={form}
          name={formName}
          autoComplete="off"
          labelAlign="left"
          layout="horizontal"
          labelCol={{
            sm: 24,
            md: 6,
          }}
          wrapperCol={{
            sm: 24,
            md: 18,
          }}
        >
          <Row>
            <Col offset={2} md={20}>
              <Form.Item hidden={true} name="causeId">
                <InputNew disabled={true} />
              </Form.Item>
              <Form.Item
                name="rateFacProCode"
                label={<LabelNew type="tab-header-inactive">{t('ปัจจัย/ความเสี่ยง')}</LabelNew>}
                rules={[{ required: true, message: t('ปัจจัย/ความเสี่ยง') }]}
              >
                <SelectRateRiskFactorProcessModel
                  placeholder={t('เลือกความเสี่ยงกระบวนการ')}
                  formname={formName}
                  disabled={isDisable}
                  filters={{
                    // projectCode: state?.projectCode,
                    isActive: 1,
                    approveStatusCode: 20,
                  }}
                  handleChange={async (e) => {
                    if (e.value) {
                      form.setFieldsValue({
                        rateFacProCode: e.value,
                        rateRiskRuleCode: null,
                        rateRiskConCode: null,
                      })
                      setrateFacProCode(e.value)
                    } else {
                      form.setFieldsValue(null)
                      setrateFacProCode(null)
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="rateRiskRuleCode"
                label={<LabelNew type="tab-header-inactive">{t('เกณฑ์ ระเบียบ คำสั่ง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุเกณฑ์ ระเบียบ คำสั่ง') }]}
              >
                <SelectRateRiskRulesModel
                  placeholder={t('เลือกเกณฑ์ ระเบียบ คำสั่ง')}
                  formname={formName}
                  disabled={isDisable}
                  filters={{ isActive: 1, rateFacProCode: rateFacProCode }}
                  handleChange={(e) => {
                    if (e.value) {
                      form.setFieldsValue({
                        rateRiskConCode: null,
                        rateRiskRuleCode: e.value,
                      })
                      setrateRiskRuleCode(e.value)
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="rateRiskConCode"
                label={<LabelNew type="tab-header-inactive">{t('วิธีการควบคุม')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุวิธีการควบคุม') }]}
              >
                <SelectRateRiskControl
                  placeholder={t('เลือกวิธีการควบคุม')}
                  formname={formName}
                  disabled={isDisable}
                  filters={{ isActive: 1, rateRiskRuleCode: rateRiskRuleCode }}
                  handleChange={(e) => {
                    if (e.value) {
                      form.setFieldsValue({
                        rateRiskConCode: e.value,
                      })
                    }
                  }}
                />
              </Form.Item>
              <Form.Item
                name="issueId"
                label={<LabelNew type="tab-header-inactive">{t('ประเด็น/ข้อสังเกตุ')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุประเด็น/ข้อสังเกตุ') }]}
              >
                <SelectAuditIssues
                  placeholder={t('ประเด็น/ข้อสังเกตุ')}
                  formname={formName}
                  disabled={isDisable}
                  filters={{
                    projectCode: state.projectCode,
                    issueType: 2,
                  }}
                  handleChange={(e) => {
                    form.setFieldsValue({ issueId: e.value || null })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="causeCode"
                label={<LabelNew type="tab-header-inactive">{t('สาเหตุ')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุสาเหตุ') }]}
              >
                <SelectCauseOfIssue
                  placeholder={t('เลือกวิธีการควบคุม')}
                  formname={formName}
                  disabled={isDisable}
                  filters={{ isActive: 1 }}
                  handleChange={(e) => {
                    form.setFieldsValue({ causeCode: e.value || null })
                  }}
                />
              </Form.Item>
              <Form.Item name="causeDetail" label={<LabelNew type="tab-header-inactive">{t('คำอธิบาย')}</LabelNew>}>
                <TextAreaNew rows={4} placeholder={t('คำอธิบาย')} disabled={isDisable} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ModalNew>
    </>
  )
}

export default SoiAuditCommitteeTabCauseOfIssue
