import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { Form, Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import styled from 'styled-components'
import { validateUserId, validateEmail } from '../../../utilitys/validationAntd'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { ButtonNew } from '../../../components/button/Button'
import { InputNew } from '../../../components/input/Input'
import { LabelNew } from '../../../components/label/Label'
import { CardNew } from '../../../components/card/Card'
import SelectUserType from '../../../components/inputfromapi/selectusertype/SelectUserType'
import SelectPersonnels from '../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import SelectRoles from '../../../components/inputfromapi/selectroles/SelectRoles'
import { GET_SYSTEM_USERS } from '../../administrator/users/graphql/Query'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { decryptData } from '../../../utilitys/crypto'
import { Alert } from '../../../components/alert/Alert'
import { RadioGroup, Radio } from '../../../components/radio/Radio.jsx'
import { displayText, displayError } from '../../../utilitys/helper'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import ResetPasswordModal from './components/modal/ResetPasswordModel.jsx'

const Responesive = { md: 24, lg: 24 }
const IconDispaly = styled.div`
  .display-none-icon
    .ant-form-item-control
    .ant-form-item-control-input
    .ant-form-item-control-input-content
    .sc-fotOHu
    .ant-input-suffix {
    display: none;
  }
`

const ProfileForm = (props) => {
  const { type_action } = props

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()
  const [form] = Form.useForm()
  const [user] = useDecodeUser()

  const [typeAction, setTypeAction] = useState(true)
  const [active, setActive] = useState(false)
  const [active2, setActive2] = useState(false)
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [visible, setVisible] = useState(false)
  const [objData, setObjData] = useState({})

  const [getSystemUsers, { data: dataSystemUsers, loading: loadingSystemUsers, error: errorSystemUsers }] =
    useLazyQuery(GET_SYSTEM_USERS)

  useEffect(() => {
    if (type_action) {
      if (type_action === 'view' && user?.userId) {
        getSystemUsers({ variables: { userId: user?.userId } })
        setTypeAction(true)
      }
    }
  }, [user?.userId])

  useEffect(() => {
    if (dataSystemUsers?.systemUsersGet) {
      const result = dataSystemUsers?.systemUsersGet

      if (result?.userType === 1) setActive(true)

      form.setFieldsValue({
        userType: result?.userType,
        personnel: result?.personnelCode,
        organizationTh: result?.organizationNameTH,
        organizationCode: result?.organizationCode,
        firstName: result?.firstname,
        lastName: result?.lastname,
        user: result?.username,
        email: result?.email,
        dateStart: result?.startDate,
        dateEnd: result?.expireDate,
        isActive: result?.isActive + '',
        remark: result?.remark,
        password: '0123456789',
        confirmPassword: '0123456789',
        rightsGroup: result?.systemUserRoles.map((item) => item.roleCode),
      })
    }

    if (errorSystemUsers) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(errorSystemUsers)),
        onOk() {},
      })
    }
  }, [dataSystemUsers])

  return (
    <>
      {loadingSystemUsers ? <SpinnersNew /> : null}
      <IconDispaly>
        <CardNew topic={t('ดูข้อมูลผู้ใช้งาน')} icon={'View'} toggledrop={'false'}>
          <Form
            form={form}
            autoComplete="off"
            labelAlign="left"
            labelCol={{ md: 8, lg: 8 }}
            wrapperCol={{ md: 24, lg: 14, align: 'left' }}
            layout="horizontal"
            name="user_form"
          >
            <Row style={{ marginBottom: 20 }} justify="start">
              <Col md={2} lg={2}></Col>
              <Col md={22} lg={22}>
                <LabelNew type="sub-header-primary">{t('ข้อมูลผู้ใช้งาน')}</LabelNew>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} justify="center">
              <Col md={24} lg={14}>
                <Form.Item
                  name="userType"
                  label={<LabelNew type="tab-header-inactive">{t('ประเภทผู้ใช้งาน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุประเภทผู้ใช้งาน') }]}
                >
                  <SelectUserType
                    disabled={typeAction}
                    formname="user_form"
                    placeholder={t('เลือกประเภทผู้ใช้งาน')}
                    handleChange={(e) => {
                      if (e.value === 1) {
                        setActive(true)
                        form.setFieldsValue({ organizationTh: null })
                        form.setFieldsValue({ organizationCode: null })
                        form.setFieldsValue({ personnel: null })
                      } else {
                        setActive(false)
                        form.setFieldsValue({ organizationTh: null })
                        form.setFieldsValue({ organizationCode: null })
                        form.setFieldsValue({ personnel: null })
                      }
                      form.setFieldsValue({ userType: e.value })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="personnel"
                  label={<LabelNew type="tab-header-inactive">{t('บุคลากร')}</LabelNew>}
                  rules={[{ required: active, message: t('กรุณาระบุบุคลากร') }]}
                >
                  <SelectPersonnels
                    disabled={typeAction}
                    placeholder={t('ระบุบุคลากร')}
                    formname="user_form"
                    handleChange={(e) => {
                      form.setFieldsValue({
                        organizationTh: e?.source?.organizationNameTH ? e.source.organizationNameTH : '',
                        organizationCode: e?.source?.organizationCode ? e.source.organizationCode : '',
                        personnel: e?.value ? e.value : '',
                        firstName: displayText(e?.source?.firstNameTH, e?.source?.firstNameEN),
                        lastName: displayText(e?.source?.lastNameTH, e?.source?.lastNameEN),
                        email: e?.source?.email ? decryptData(e?.source?.email) : '',
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item name="organizationCode" hidden>
                  <InputNew />
                </Form.Item>

                <Form.Item
                  name="organizationTh"
                  label={<LabelNew type="tab-header-inactive">{t('หน่วยงาน')}</LabelNew>}
                  rules={[{ required: !active, message: t('กรุณาระบุหน่วยงาน') }]}
                  // active
                >
                  <InputNew
                    disabled={active}
                    maxLength={100}
                    placeholder={t('ระบุหน่วยงาน')}
                    onChange={(e) => form.setFieldsValue({ organizationTh: e.target.value })}
                  />
                </Form.Item>
                <Form.Item
                  name="firstName"
                  label={<LabelNew type="tab-header-inactive">{t('ชื่อ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุชื่อ') }]}
                >
                  <InputNew maxLength={100} disabled={typeAction} placeholder={t('ระบุชื่อ')} />
                </Form.Item>
                <Form.Item name="lastName" label={<LabelNew type="tab-header-inactive">{t('นามสกุล')}</LabelNew>}>
                  <InputNew maxLength={100} disabled={typeAction} placeholder={t('ระบุนามสกุล')} />
                </Form.Item>
              </Col>
            </Row>
            {/* ----------- */}
            <Row style={{ marginBottom: 20 }} justify="start">
              <Col md={2} lg={2}></Col>
              <Col md={22} lg={22}>
                <LabelNew type="sub-header-primary">{t('ข้อมูลบัญชีผู้ใช้')}</LabelNew>
              </Col>
            </Row>

            <Row style={{ marginBottom: 24 }} justify="center">
              <Col md={24} lg={14}>
                <Form.Item
                  name="user"
                  label={<LabelNew type="tab-header-inactive">{t('ชื่อผู้ใช้งาน')}</LabelNew>}
                  rules={[...validateUserId(true, t('กรุณาระบุชื่อผู้ใช้งาน'))]}
                >
                  <InputNew maxLength={25} disabled={typeAction} placeholder={t('ระบุชื่อผู้ใช้งาน')} />
                </Form.Item>
                <Form.Item
                  name="email"
                  label={<LabelNew type="tab-header-inactive">{t('อีเมล')}</LabelNew>}
                  rules={[...validateEmail(true, t('กรุณาระบุอีเมล'))]}
                >
                  <InputNew maxLength={255} disabled={typeAction} placeholder={t('ระบุอีเมล')} />
                </Form.Item>

                <Form.Item
                  name="dateStart"
                  label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้นใช้งาน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุวันที่เริ่มต้นใช้งาน') }]}
                >
                  <Datepicker
                    disabled={typeAction}
                    setValueDateFn={async (data) => {
                      await setIsMinDate(data)
                      await form.setFieldsValue({ dateStart: await data })
                    }}
                    isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                    selectsStart={true}
                    onClear={() => form.setFieldsValue({ dateStart: null })}
                  />
                </Form.Item>
                <Form.Item
                  name="dateEnd"
                  label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุดใช้งาน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุวันที่สิ้นสุดใช้งาน') }]}
                >
                  <Datepicker
                    disabled={typeAction}
                    setValueDateFn={async (data) => {
                      await setIsMaxDate(data)
                      await form.setFieldsValue({ dateEnd: await data })
                    }}
                    isMinDate={isMinDate ? new Date(isMinDate) : null}
                    selectsEnd={true}
                    onClear={() => form.setFieldsValue({ dateEnd: null })}
                  />
                </Form.Item>
                <Form.Item
                  label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                  name="isActive"
                  initialValue={'1'}
                  onChange={(e) => setActive2(e.target.value === '1' ? false : true)}
                >
                  <RadioGroup>
                    <Space size={[8, 8]} wrap>
                      <Radio disabled={typeAction} value="1">
                        <LabelNew type="tab-header-inactive">{t('ใช้งาน')}</LabelNew>
                      </Radio>
                      &nbsp;
                      <Radio disabled={typeAction} value="0">
                        <LabelNew type="tab-header-inactive">{t('ไม่ใช้งาน')}</LabelNew>
                      </Radio>
                    </Space>
                  </RadioGroup>
                </Form.Item>
                <Form.Item
                  name="remark"
                  label={<LabelNew type="tab-header-inactive">{t('แจ้งเหตุผลไม่ใช้งาน')}</LabelNew>}
                  rules={[{ required: active2, message: t('กรุณาระบุแจ้งเหตุผลไม่ใช้งาน') }]}
                >
                  <InputNew maxLength={255} disabled={typeAction} placeholder={t('ระบุแจ้งเหตุผลไม่ใช้งาน')} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ marginBottom: 20 }} justify="start">
              <Col md={2} lg={2}></Col>
              <Col md={22} lg={22}>
                <LabelNew type="sub-header-primary">{t('รายการกลุ่มสิทธิ์')}</LabelNew>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} justify="center">
              <Col md={24} lg={14}>
                <Form.Item
                  name="rightsGroup"
                  rules={[{ required: true, message: t('กรุณาระบุกลุ่มสิทธิ์') }]}
                  label={<LabelNew type="tab-header-inactive">{t('กลุ่มสิทธิ์')}</LabelNew>}
                >
                  <SelectRoles
                    disabled={typeAction}
                    placeholder={t('ระบุกลุ่มสิทธิ์')}
                    formname="user_form"
                    handleChange={(e) => {
                      form.setFieldsValue({ rightsGroup: e })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/* ---------- */}
            <Row gutter={[16, 16]}>
              <Col {...Responesive} align="center">
                <Space size={[8, 8]} wrap>
                  <ButtonNew
                    type="primary"
                    onClick={() => {
                      setObjData({ logfileLoginId: user?.logfileLoginId, userId: user?.userId })
                      setVisible(true)
                    }}
                    disabled={active}
                    hidden
                  >
                    {t('เปลี่ยนรหัสผ่าน')}
                  </ButtonNew>
                  &nbsp;
                  <ButtonNew type="back" onClick={() => navigate(-1)}>
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Space>
              </Col>
            </Row>
          </Form>
        </CardNew>
      </IconDispaly>
      <ResetPasswordModal open={visible} close={(e) => setVisible(e)} projectCode={state?.refCode} objData={objData} />
    </>
  )
}

export default ProfileForm
