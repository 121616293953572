import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import { CardNew as Card } from '../../../../../components/card/Card'
import { ButtonNew } from '../../../../../components/button/Button'
import { LabelNew } from '../../../../../components/label/Label'
import { InputNew } from '../../../../../components/input/Input'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'

const PwddRevisionHistoryFilter = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { menu } = props
  const formName = 'from-auditresult-fillter'
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)

  const Responesive = {
    inputFilter: {
      md: 12,
      lg: 8,
    },
    buttonFilter: {
      md: 24,
      lg: 24,
    },
  }

  const onFinish = (values) => {
    props.getValue({ ...values })
  }

  const onClear = () => {
    form.resetFields()
    props.getValue(form.getFieldsValue())
  }

  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((er) => {
      console.log(er)
    })
  }

  return (
    <>
      <Card topic={t('Filters')} icon="Filter" toggledrop={'false'}>
        <Row style={{ marginTop: 24 }}>
          <Col sm={24} md={24} lg={24}>
            <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} name={formName} layout="vertical">
              <Row gutter={[16, 8]}>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="username"
                    label={<LabelNew type="tab-header-inactive">{t('ชื่อผู้ใช้งาน')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุชื่อผู้ใช้งาน')} />
                  </Form.Item>
                </Col>
                {/* <Col {...Responesive.inputFilter}>
                  <Form.Item name="email" label={<LabelNew type="tab-header-inactive">{t('อีเมล')}</LabelNew>}>
                    <InputNew placeholder={t('ระบุอีเมล')} />
                  </Form.Item>
                </Col> */}
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="fullname"
                    label={<LabelNew type="tab-header-inactive">{t('ชื่อ - นามสกุล')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุชื่อ-นามสกุล')} />
                  </Form.Item>
                </Col>

                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="dateStart"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่แก้ไขเริ่มต้น')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุวันที่แก้ไขเริ่มต้น'),
                      },
                    ]}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        await setIsMinDate(data)
                        await form.setFieldsValue({ dateStart: await data })
                      }}
                      isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                      selectsStart={true}
                      onClear={() => {
                        form.setFieldsValue({ dateStart: null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="dateEnd"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่แก้ไขสิ้นสุด')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุวันที่แก้ไขสิ้นสุด'),
                      },
                    ]}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        await setIsMaxDate(data)
                        await form.setFieldsValue({ dateEnd: await data })
                      }}
                      isMinDate={isMinDate ? new Date(isMinDate) : null}
                      selectsEnd={true}
                      onClear={() => {
                        form.setFieldsValue({ dateEnd: null })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew type="search" menu={menu} roles={{ type: 'view', menu: menu }} htmlType="submit">
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew
                    type="clear"
                    menu={menu}
                    onClick={() => {
                      onClear()
                      setIsMinDate(null)
                      setIsMaxDate(null)
                    }}
                  >
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default PwddRevisionHistoryFilter
