import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Row, Col, Form } from 'antd'
import { Datatable } from '../../../../components/datatable/Datatable.jsx'
import { GET_AUDITPLAN_PAGINATION, AUDITPLAN_EXPORT } from './graphql/Query'
import { DELETE_AUDIT_PLAN } from './graphql/Mutation'
import * as _ from 'lodash'
import { Status } from '../../../../components/status/Status'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Alert } from '../../../../components/alert/Alert'
import { dateDisplay } from '../../../../utilitys/dateHelper'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { ButtonNew } from '../../../../components/button/Button'
import { ModalNew } from '../../../../components/modal/Modal'
import FormAuditPlan from './FormAuditPlan.jsx'
import { encryptInput } from '../../../../utilitys/crypto'
import { RadioGroup, Radio } from '../../../../components/radio/Radio'
import { LabelNew as Label } from '../../../../components/label/Label'
import { InputNew as Input } from '../../../../components/input/Input'
import { displayError } from '../../../../utilitys/helper'

const AuditPlanList = (props) => {
  const { t } = useTranslation()

  const [modalAdd, setModalAdd] = useState(false)

  const [modalExport, setModalExport] = useState(false)
  const [typeForm, setTypeForm] = useState('')

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [codeEdit, setCodeEdit] = useState('')
  const [codeExport, setCodeExport] = useState('')
  const [formExport] = Form.useForm()
  const [waterMark, setWaterMark] = useState(false)
  const [auditPlanExportFn, auditPlanExport] = useLazyQuery(AUDITPLAN_EXPORT)
  useEffect(() => {
    if (auditPlanExport.data) {
      let response = auditPlanExport?.data?.auditPlanExport
      if (response) {
        if (response.message === 'succcess') {
          window.open(`${response.fileUrl}`, '_blank')
          setModalExport(false)
        } else {
          showAlertNotFoundError()
          setModalExport(false)
        }
      }
    }
    if (auditPlanExport.error) {
      showAlertLoadDataError(displayError(auditPlanExport.error))
    }
  }, [auditPlanExport.data])

  const [getAuditPlanPagenationFn, getAuditPlanPagenation] = useLazyQuery(GET_AUDITPLAN_PAGINATION)
  const [deleteDataFn, deleteData] = useMutation(DELETE_AUDIT_PLAN)
  useEffect(() => {
    if (deleteData.data) {
      let response = deleteData?.data?.auditPlanDelete
      if (response) {
        showAlertSuccess()
      }
    }
    if (deleteData.error) {
      showAlertDeleteError(displayError(deleteData.error))
    }
  }, [deleteData.data])

  useEffect(() => {
    if (getAuditPlanPagenation?.data) {
      let response = getAuditPlanPagenation?.data?.getAuditPlanPagenation
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data, i) => {
          return {
            key: data.planCode,
            no: (page - 1) * limit + i + 1,
            planName:
              data.planCode + ' : ' + (localStorage.getItem('lang') === 'th' ? data.planNameTH : data.planNameEN),
            planYear: localStorage.getItem('lang') === 'th' ? parseInt(data.planYear) + 543 : data.planYear,
            dateStart: dateDisplay(data.dateStart),
            dateEnd: dateDisplay(data.dateEnd),
            planStatus:
              localStorage.getItem('lang') === 'th' ? (
                <Status text={data.planStatusNameTH} />
              ) : (
                <Status text={data.planStatusNameEN} />
              ),
            isActive: data.isActive === 1 ? <Status text={t('ใช้งาน')} /> : <Status text={t('ไม่ใช้งาน')} />,
            countProjectALL: data.countProjectALL,
            countApproveStatus10: data.countApproveStatus10,
            countApproveStatus20: data.countApproveStatus20,
            countApproveStatus30: data.countApproveStatus30,
            action: (
              <div style={{ textAlign: 'center' }}>
                <ButtonGroup
                  menu={props.menu}
                  icons={[
                    {
                      type: 'download',
                      onClick: () => {
                        setCodeExport(data.planCode)
                        setModalExport(true)
                      },
                    },
                    {
                      type: 'view',
                      onClick: () => {
                        setTypeForm('view')
                        setModalAdd(true)
                        setCodeEdit(data.planCode)
                      },
                    },
                    {
                      type: 'edit',
                      onClick: () => {
                        setTypeForm('edit')
                        setModalAdd(true)
                        setCodeEdit(data.planCode)
                      },
                    },
                    {
                      type: 'delete',
                      onClick: () => {
                        showConfirmDelete(data.planCode)
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (getAuditPlanPagenation.error) {
      showAlertLoadDataError(displayError(getAuditPlanPagenation.error))
    }
  }, [getAuditPlanPagenation?.data])

  const columns = [
    {
      title: <div style={{ textAlign: 'center' }}>{t('ลำดับ')}</div>,
      dataIndex: 'no',
      width: '80px',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('ชื่อแผน')}</div>,
      dataIndex: 'planName',
      width: '250px',
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('ประจำปี')}</div>,
      dataIndex: 'planYear',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
      width: '100px',
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('วันที่เริ่มต้น')}</div>,
      dataIndex: 'dateStart',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
      width: '110px',
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('วันที่สิ้นสุด')}</div>,
      dataIndex: 'dateEnd',
      width: '110px',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('จำนวนโครงการทั้งหมด')}</div>,
      dataIndex: 'countProjectALL',
      width: '200px',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('จำนวนโครงการรอการอนุมัติ')}</div>,
      dataIndex: 'countApproveStatus10',
      width: '200px',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('จำนวนโครงการสถานะอนุมัติ')}</div>,
      dataIndex: 'countApproveStatus20',
      width: '200px',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('จำนวนโครงการสถานะไม่อนุมัติ')}</div>,
      dataIndex: 'countApproveStatus30',
      width: '220px',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('สถานะพิจารณา')}</div>,
      dataIndex: 'planStatus',
      width: '150px',
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('สถานะ')}</div>,
      dataIndex: 'isActive',
      width: '120px',
    },
    {
      title: '',
      dataIndex: 'action',
      width: '120px',
    },
  ]

  const showAlertSuccess = () => {
    Alert({
      type: 'success',
      title: t('ลบสำเร็จ'),
      onOk() {
        getAuditPlanPagenation.refetch()
      },
    })
  }

  const showConfirmEdit = () => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        const valid = validDate()
        if (
          valid &&
          props.form.getFieldsError().filter((item) => {
            return item.errors.length !== 0
          }).length === 0
        ) {
          props.form.submit()
        }
      },
      onCancel() {
        setModalAdd(false)
      },
    })
  }

  const showAlertNotFoundError = () => {
    Alert({
      type: 'error',
      title: t('ไม่พบข้อมูล'),
      onOk() {},
    })
  }

  const showConfirmDelete = (planCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ?'),
      onOk() {
        auditPlanDelete(planCode)
      },
      onCancel() {},
    })
  }

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showAlertDeleteError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถลบข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  function setErrorField(name, errors) {
    // Set field errors
    return props.form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  const validDate = () => {
    let valid = false
    if (props.form.getFieldValue('dateStart') && props.form.getFieldValue('dateEnd')) {
      let dateStart = new Date(props.form.getFieldValue('dateStart'))
      let dateEnd = new Date(props.form.getFieldValue('dateEnd'))
      if (dateStart <= dateEnd) {
        setErrorField('dateStart', false)
        setErrorField('dateEnd', false)
        valid = true
      } else {
        setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นน้อยกว่าวันที่สิ้นสุด'))
        setErrorField('dateEnd', t('กรุณาเลือกวันที่สิ้นสุดมากกว่าวันที่เริ่มต้น'))
      }
    } else {
      valid = true
    }
    return valid
  }

  useEffect(() => {
    if (modalAdd === false) {
      setTypeForm('')
    }
  }, [modalAdd])

  const auditPlanDelete = async (codeDelete) => {
    deleteDataFn({
      variables: {
        input: encryptInput({ planCode: codeDelete }),
      },
      fetchPolicy: 'no-cache',
    })
  }

  const callApiSearch = () => {
    getAuditPlanPagenationFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          filters: {
            lang: localStorage.getItem('lang'),
            planYear: props.formFilter.getFieldValue('planYear') ? props.formFilter.getFieldValue('planYear') : null,
            planType: props.formFilter.getFieldValue('planType') ? props.formFilter.getFieldValue('planType') : null,
            planStatusCode: props.formFilter.getFieldValue('planStatusCode')
              ? props.formFilter.getFieldValue('planStatusCode')
              : null,
            isActive:
              props.formFilter.getFieldValue('isActive') === undefined ||
              props.formFilter.getFieldValue('isActive') === null
                ? null
                : String(props.formFilter.getFieldValue('isActive')),
            dateStart: props.formFilter.getFieldValue('dateStart') ? props.formFilter.getFieldValue('dateStart') : null,
            dateEnd: props.formFilter.getFieldValue('dateEnd') ? props.formFilter.getFieldValue('dateEnd') : null,
            planInfor: (
              typeof props.formFilter.getFieldValue('planInfor') === 'string'
                ? props.formFilter.getFieldValue('planInfor').trim()
                : props.formFilter.getFieldValue('planInfor')
            )
              ? typeof props.formFilter.getFieldValue('planInfor') === 'string'
                ? props.formFilter.getFieldValue('planInfor').trim()
                : props.formFilter.getFieldValue('planInfor')
              : null,
          },
          pagination: { limit, page },
          sort: [
            {
              fieldSort: 'planId',
              sortType: 'DESC',
            },
          ],
        }),
      },
    })
  }

  useEffect(() => {
    callApiSearch()
  }, [page, limit, props.searchStatus])

  const auditplanExportData = (values) => {
    auditPlanExportFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          planCode: codeExport,
          fileType: values.fileType,
          waterMark: values.waterMark ? values.waterMark : '',
          lang: localStorage.getItem('lang'),
        }),
      },
    })
  }

  return (
    <>
      {(getAuditPlanPagenation.loading || deleteData.loading || auditPlanExport.loading) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Datatable
            columns={columns}
            data={dataLists}
            handleTableChange={() => {}}
            paginationCustom={true}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            searchCustom={true}
            total={count}
            enableScroll={{ x: true, y: true }}
            pageSize={limit}
            page={page}
            btnAdd={
              <>
                <ButtonNew
                  type="plusTableBorderPrimary"
                  roles={{ type: 'add', menu: props.menu }}
                  style={{ marginRight: '12px' }}
                  onClick={() => {
                    setModalAdd(true)
                    setTypeForm('add')
                  }}
                >
                  {t('เพิ่มแผนการตรวจสอบ')}
                </ButtonNew>
              </>
            }
          />
        </Col>
      </Row>
      <ModalNew
        typeAction={typeForm}
        visible={modalAdd}
        onSubmit={showConfirmEdit}
        onClose={setModalAdd}
        testTitle={t('แผนการตรวจสอบ')}
        okButtonProps={typeForm === 'view' ? { style: { display: 'none' } } : {}}
      >
        <FormAuditPlan
          form={props.form}
          modalFormSetState={setModalAdd}
          typeForm={typeForm}
          validDate={validDate}
          dataLists={getAuditPlanPagenation}
          codeEdit={codeEdit}
          lang={localStorage.getItem('lang')}
          modal={modalAdd}
        />
      </ModalNew>
      <ModalNew
        visible={modalExport}
        onSubmit={() => {
          formExport.submit()
        }}
        onClose={() => {
          setModalExport(false)
        }}
        testTitle={t('ส่งออกข้อมูลแผนการตรวจสอบ')}
      >
        <Form
          form={formExport}
          onFinish={auditplanExportData}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="auditplanExportForm"
        >
          <Form.Item
            label={<Label type="tab-header-inactive">{t('ประเภทรายงาน')}</Label>}
            name="fileType"
            initialValue={'pdf'}
            onClick={(e) => {
              if (e.target.value) {
                if (e.target.value !== 'pdf') {
                  setWaterMark(true)
                } else {
                  setWaterMark(false)
                }
              }
            }}
          >
            <RadioGroup>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="pdf">
                    <Label type="tab-header-inactive">{t('PDF')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="word">
                    <Label type="tab-header-inactive">{t('Word')}</Label>
                  </Radio>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="excel">
                    <Label type="tab-header-inactive">{t('Excel')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="csv">
                    <Label type="tab-header-inactive">{t('CSV')}</Label>
                  </Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
          <Form.Item
            name="waterMark"
            label={<Label type="tab-header-inactive">{t('ลายน้ำ')}</Label>}
            hidden={waterMark}
          >
            <Input placeholder={t('ระบุลายน้ำ (เฉพาะ PDF)')} />
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}

export default AuditPlanList
