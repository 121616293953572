import { gql } from '@apollo/client'

export const GET_AUDIT_COMMITTEE = gql`
  query getAuditCommittee($input: AuditResultPaginationInput!) {
    getAuditCommittee(input: $input) {
      result {
        actionPlanId
        projectCode
        projectName
        projectNameEN
        projectRefNameTH
        projectRefNameEN
        dateStart
        dateEnd
        organizationNameTH
        organizationNameEN
        organizationName
        fullName
        meetingDate
        meetingTimes
        meetingTime
        isActive
        agendaId
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDIT_ISSUE = gql`
  query getAuditIssues($input: SelectAuditIssuesInput!) {
    getAuditIssues(data: $input) {
      issueId
      issueTH
      issueEN
      rateRiskRuleCode
      rateRiskConCode
      issueType
      actionPlanId
      isActive
      rateRiskControl {
        rateRiskConTH
        rateRiskConEN
      }
      rateRiskFactorProcess {
        rateFacProCode
        rateFacProNameTH
        rateFacProNameEN
      }
    }
  }
`

export const GET_AUDIT_ISSUE_BY_ID = gql`
  query getAuditIssueById($input: String!) {
    getAuditIssueById(issueId: $input) {
      issueId
      issueTH
      issueEN
      factTH
      factEN
      findingTypeCode
      ISOCode
      ISOSubCode
      rateFacProCode
      rateRiskRuleCode
      rateRiskConCode
      riskLevelCode
      issueShow
      riskTH
      riskEN
      effectTH
      effectEN
      meetingTime
      remark
      toKnow
      performance
      conclusion
      files
      fileDetail
      issueHave
      auditIssueOrganizations {
        organizationCode
        organizationNameTH
        organizationNameEN
      }
      creators {
        personnels {
          firstNameTH
          lastNameTH
          firstNameEN
          lastNameEN
        }
      }
    }
  }
`

export const GET_AUDIT_CAUSE_OF_ISSUE = gql`
  query getAuditCauseOfIssue($projectCode: String!) {
    getAuditCauseOfIssue(projectCode: $projectCode) {
      issueTH
      issueEN
      rateFacProNameTH
      rateFacProNameEN
      causeNameTH
      causeNameEN
      causeId
      rateFacProCode
      rateRiskRuleCode
      rateRiskConCode
      issueId
      causeCode
      causeDetailTH
      causeDetailEN
      isActive
    }
  }
`

export const GET_AUDIT_CAUSE_OF_ISSUE_BY_ID = gql`
  query getAuditCauseOfIssueById($causeId: String!) {
    getAuditCauseOfIssueById(causeId: $causeId) {
      causeId
      rateFacProCode
      rateRiskRuleCode
      rateRiskConCode
      issueId
      causeCode
      causeDetailTH
      causeDetailEN
    }
  }
`

export const GET_AUDIT_SUGGEST = gql`
  query getAuditSuggest($projectCode: String!) {
    getAuditSuggest(projectCode: $projectCode) {
      suggestId
      issueTH
      issueEN
      suggestTH
      suggestEN
      dateExpected
      isActive
    }
  }
`

export const GET_AUDIT_SUGGEST_BY_ID = gql`
  query getAuditSuggestById($suggestId: String!) {
    getAuditSuggestById(suggestId: $suggestId) {
      suggestId
      rateFacProCode
      rateRiskRuleCode
      rateRiskConCode
      issueId
      suggestTH
      suggestEN
      dateExpected
      refInfoCode
      personnelCode
      auditSuggestOrganizations {
        organizationCode
        organizationNameTH
        organizationNameEN
        masterOrganization {
          masterOrganizationLevel {
            organizationLevelNameTH
            organizationLevelNameEN
          }
          responsiblePerson {
            firstNameTH
            lastNameTH
            firstNameEN
            lastNameEN
          }
        }
      }
    }
  }
`

export const GET_ORGANIZATION_PAGINATION = gql`
  query getOrganizationsPagination($input: OrganizationsPaginationInput!) {
    getOrganizationsPagination(input: $input) {
      result {
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationParentCode
        organizationParentNameTH
        organizationParentNameEN
        organizationLevelCode
        organizationLevelNameTH
        organizationLevelNameEN
        organizationSizeCode
        organizationSizeNameTH
        organizationSizeNameEN
        organizationType
        isActive
        responsiblePerson {
          firstNameTH
          lastNameTH
          firstNameEN
          lastNameEN
        }
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDIT_RESOLUTION_AC_BY_ID = gql`
  query getAuditResolutionACById($agendaId: String!) {
    getAuditResolutionACById(agendaId: $agendaId) {
      agendaId
      copyType
      jobType
      projectCode
      organization {
        organizationCode
        organizationNameTH
        organizationNameEN
      }
      agendaTime
      agendaNameTH
      agendaNameEN
      agendaTimeSub
      agendaSubNameTH
      agendaSubNameEN
      considerCode
      personnelCreateCode
      personnelPresentCode
      meetingDetailTH
      meetingDetailEN
      resolutionACTH
      resolutionACEN
      meetingTimes
      meetingTime
      meetingDate
      locationTH
      locationEN
      auditor
      isActive
      files
      fileDetail
      auditResolutionPersonnels {
        personnelCode
        personnelType
      }
      auditResolutionOrganizations {
        organizationCode
      }
      systemUsers {
        personnels {
          firstNameTH
          lastNameTH
          firstNameEN
          lastNameEN
        }
      }
    }
  }
`

export const GET_AUDIT_COMMITEE_EXPORT = gql`
  query auditCommitteeExport($input: AuditCommitteeExportInput!) {
    auditCommitteeExport(input: $input) {
      message
      fileUrl
    }
  }
`
