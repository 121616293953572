import styled from 'styled-components'

export const ButtonGroupStyle = styled.div`
  display: flex;

  [disabled] {
    color: #999;
    &:hover {
      color: #999;
    }
  }
`
