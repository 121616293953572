import React from 'react'
import { Row, Col } from 'antd'
import ClosingMeetingResultsList from './ClosingMeetingResultsList.jsx'

const ClosingMeetingResultsIndex = (props) => {
  const { menu } = props
  const Responsive = {
    md: 24,
    lg: 24,
  }
  return (
    <>
      <Row>
        <Col {...Responsive}>
          <ClosingMeetingResultsList menu={menu} />
        </Col>
      </Row>
    </>
  )
}

export default ClosingMeetingResultsIndex
