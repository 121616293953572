import { gql } from '@apollo/client'

export const FORMSSAVE = gql`
  mutation formsSave($inputData: FormsSaveInput!) {
    formsSave(inputData: $inputData) {
      formCode
    }
  }
`

export const FORMSDELETE = gql`
  mutation formsDelete($input: FormsInput!) {
    formsDelete(input: $input)
  }
`
