import { useTranslation } from 'react-i18next'
import { Form } from 'antd'

import { useNavigate } from 'react-router-dom'
import { ModalNew as Modal } from '../../../../../components/modal/Modal'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { InputNew as Input } from '../../../../../components/input/Input'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'
import { Alert } from '../../../../../components/alert/Alert'
import { encryptInput } from '../../../../../utilitys/crypto'
import { SpinnersNew as Spinner } from '../../../../../components/spinners/Spinners'
import { CREATE_AUDIT_RESOLUTION } from '../../graphql/Mutation'
import { useMutation } from '@apollo/client'
import { useEffect } from 'react'

const AcResolutionListAddModal = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [form] = Form.useForm()

  const [createAuditResolution, createAuditResolutionResult] = useMutation(CREATE_AUDIT_RESOLUTION)

  const onFinish = (values) => {
    const input = {
      ...values,
      location: values.location || '',
      userId: props.user.userId,
      systemLanguage: props.systemLanguage,
    }

    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อเพิ่มมติคณะกรรมการตรวจสอบ'),
      onOk: async () => {
        await createAuditResolution({
          variables: {
            input: encryptInput(input),
          },
        })
          .then((response) => {
            if (response.data?.createAuditResolution) {
              props.dataTableHandler.refetch()
              Alert({
                type: 'success',
                title: t('เพิ่มข้อมูลสำเร็จ'),
                onOk() {
                  props.setModalOpen(false)
                  navigate('/auditoperations/acresolution/detail', {
                    state: { detailPageAction: 'edit', resId: response.data.createAuditResolution },
                  })
                },
              })
            }
          })
          .catch((error) =>
            Alert({
              type: 'error',
              title: t('ไม่สามารถเพิ่มข้อมูลได้'),
              content: t(error),
              onOk() {},
            }),
          )
      },
      onCancel() {
        return null
      },
    })
  }

  useEffect(() => {
    if (createAuditResolutionResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถเพิ่มข้อมูลได้'),
        content: t(createAuditResolutionResult.error),
        onOk() {},
      })
    }
  }, [createAuditResolutionResult.error])

  return (
    <>
      {createAuditResolutionResult.loading && <Spinner />}
      <Modal
        // typeAction={objData.type}
        visible={props.open}
        testTitle={t('มติคณะกรรมการตรวจสอบ')}
        onSubmit={form.submit}
        onClose={() => {
          props.setModalOpen(false)
        }}
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="addAcResolutionForm"
        >
          <Form.Item
            name="resTimes"
            label={<Label type="tab-header-inactive">{t('ครั้งที่')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกครั้งที่') }]}
          >
            <Input placeholder={t('ครั้งที่/ปี')} />
          </Form.Item>
          <Form.Item
            name="resDate"
            label={<Label type="tab-header-inactive">{t('วันที่ประชุม')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกวันที่ประชุม') }]}
          >
            <Datepicker
              setValueDateFn={(data) => {
                form.setFieldsValue({ resDate: data })
              }}
              onClear={() => {
                form.setFieldsValue({ resDate: null })
              }}
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item name="location" label={<Label type="tab-header-inactive">{t('สถานที่ประชุม')}</Label>}>
            <Input placeholder={t('สถานที่ประชุม')} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default AcResolutionListAddModal
