import { gql } from '@apollo/client'
export const GET_LIST_RATE_RISK_CONTROL_PAGINATION = gql`
  query GetListRateRiskControlPagination($input: RateRiskControlPagination!) {
    getListRateRiskControlPagination(input: $input) {
      result {
        no
        rateRiskConCode
        rateRiskConTH
        rateRiskConEN
        rateRiskRuleCode
        rateRiskRuleTH
        rateRiskRuleEN
        controlTypeCode
        controlNatureCode
        sort
        isActive
      }
      count
      page
      limit
    }
  }
`
