import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput } from '../../../utilitys/crypto'
import OperationSetupListModal from './OperationSetupListModal'
import { handleSort } from '../../../utilitys/pagination'
import { Columns } from './utils/OperationSetupListColumn'
import { GET_LIST_OPERATION_SETUP_PAGINATION } from './graphql/Query'
import { displayError, displayText } from '../../../utilitys/helper'
import { DELETE_OPERATION_SETUP } from './graphql/Mutation'

const Responsive = {
  md: 24,
  lg: 24,
}

const OperationSetupList = ({ menu }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const arrCrumb = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/operationsetup' },
    { label: t('จัดการคลังขั้นตอนการปฎิบัติงาน') },
  ]

  const [editable] = useState(true)

  const [formType, setformType] = useState()
  const [dataForm, setdataForm] = useState()

  const [dataRow, setdataRow] = useState([])

  const [modal_visible, setmodal_visible] = useState(false)
  const [paginate, setPaginate] = useState({ page: 1, limit: 10 })

  const [sort, setsort] = useState([{ fieldSort: 'stepModelCode', sortType: 'ASC' }])

  const [
    filters,
    //  setfilters
  ] = useState({})
  const [count, setcount] = useState(1)

  const [callGetList, { loading: loadingList, error: errorList, data: dataList }] = useLazyQuery(
    GET_LIST_OPERATION_SETUP_PAGINATION,
  )

  useEffect(() => {
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorList)),
      })
    }
  }, [errorList])

  useEffect(() => {
    if (dataList) {
      let { result, count } = dataList.getListOperationSetupPagination

      let dataTemp = result.map((item, key) => {
        let stepModel = displayText(item.stepModelTH, item.stepModelEN)

        return {
          ...item,
          key: key + 1,
          stepModel,
        }
      })

      setdataRow(dataTemp)
      setcount(count)
    }
  }, [dataList])

  useEffect(() => {
    if (sort && paginate) fncGetList()
  }, [paginate, sort])

  const [callDelete, { loading: loadingDelete, error: errorDelete, data: dataDelete }] =
    useMutation(DELETE_OPERATION_SETUP)

  useEffect(() => {
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(errorDelete)),
        onOk() {},
      })
    }
  }, [errorDelete])

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        content: t(dataDelete.message),
        onOk() {
          fncGetList()
        },
      })
    }
  }, [dataDelete])

  const fncGetList = () => {
    const dataInput = {
      sort: sort,
      filters: filters,
      pagination: {
        page: paginate.page,
        limit: paginate.limit,
      },
    }
    callGetList({
      variables: {
        input: encryptInput(dataInput),
      },
    })
  }

  const handleModalOnSuccess = (data) => {
    setdataForm()
    setmodal_visible(false)
    navigate('/administrator/operationsetup/form', {
      state: { type_action: 'add', refCode: data },
    })
  }

  const handleModalOnClose = (data) => {
    setdataForm()
    setmodal_visible(data)
  }

  const fncOnpressDelete = (data) => {
    const dataInput = {
      stepModelCode: data.stepModelCode,
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ?'),
      onOk() {
        callDelete({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const fncOnpressView = (data) => {
    navigate('/administrator/operationsetup/form', {
      state: { type_action: 'view', refCode: data.stepModelCode },
    })
  }

  const fncOnpressEdit = (data) => {
    navigate('/administrator/operationsetup/form', {
      state: { type_action: 'edit', refCode: data.stepModelCode },
    })
  }

  return (
    <React.Fragment>
      <BreadcrumbNew data={arrCrumb} title={t('จัดการคลังขั้นตอนการปฎิบัติงาน')} />
      <CardNew topic={t('รายการคลังขั้นตอนการปฎิบัติงาน')} icon="List">
        <Row>
          <Col {...Responsive}>
            <Datatable
              columns={Columns({ fncOnpressDelete, fncOnpressView, fncOnpressEdit, menu })}
              data={dataRow}
              total={count}
              searchCustom={true}
              pageSize={paginate.limit}
              page={paginate.page}
              isLoading={loadingList || loadingDelete}
              paginationCustom={true}
              scroll={{ x: 768 }}
              onChangePagination={({ page, pageSize }) => {
                setPaginate({ page, limit: pageSize })
              }}
              handleTableChange={(e) => {
                const reNewField = [
                  {
                    stepModel: displayText('stepModelTH', 'stepModelEN'),
                  },
                ]

                setsort(handleSort(e?.sorter, reNewField))
              }}
              btnAdd={
                editable ? (
                  <ButtonNew
                    onClick={() => {
                      setformType('add')
                      setmodal_visible(true)
                    }}
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                  >
                    {t('เพิ่มคลังขั้นตอนการปฎิบัติงาน')}
                  </ButtonNew>
                ) : (
                  <></>
                )
              }
            />
          </Col>
        </Row>
      </CardNew>
      <OperationSetupListModal
        formType={formType}
        dataForm={dataForm}
        visible={modal_visible}
        onSuccess={handleModalOnSuccess}
        onClose={handleModalOnClose}
      />
    </React.Fragment>
  )
}
export default OperationSetupList
