import { Trans } from 'react-i18next'

export const resultListCrumb = [
  { label: <Trans>งานประเมินการควบคุมภายใน</Trans>, path: '/internalcontrol/result' },
  { label: <Trans>ผลประเมินควบคุมภายใน</Trans> },
]

export const resultItemCrumb = [
  { label: <Trans>ระบบตรวจสอบภายใน</Trans>, path: '/internalcontrol/result' },
  { label: <Trans>งานประเมินควบคุมภายใน</Trans> },
  { label: <Trans>การประเมินควบคุมภายใน</Trans> },
]
