import { Trans } from 'react-i18next'
import * as _ from 'lodash'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { ButtonNew } from '../../../../components/button/Button'
import { Status } from '../../../../components/status/Status.jsx'
import { checkLanguageReturnData } from '../components/fnForManageAssessment'
import { Select } from '../../../../components/select/Select'
import { InputNew } from '../../../../components/input/Input'
import { UploadNew } from '../../../../components/upload/Upload'
import { Form } from 'antd'
// import { useNavigate } from 'react-router-dom'

// record.responsibilityBy !== record.userCode && !record.makerBy && item === '11'
//                     ? 'flagOutlinedInTableBlue'
//                     : item === '12' && record.makerBy !== null
//                     ? 'moreHorizontalInTableOrange'
//                     : 'moreHorizontalInTableGray'
const checkButton = (obj) => {
  let btnName = ''
  let btnText = ''
  let statusUse = ''
  let btnRoles = ''
  // เงื่อนไขรายการปรกติ ไม่มีใครส่งต่อแบบประเมินให้เรา
  if (obj.responsibilityBy === obj.userCode && obj.operationStatusCode === '11' && obj.makerBy === null) {
    btnName = 'flagOutlinedInTableBlue'
    btnText = 'ทำแบบประเมิน'
    statusUse = 'ยังไม่ประเมิน'
    btnRoles = 'edit'
    // เงื่อนไขรายการ มีคนส่งต่อแบบประเมินให้เรา
  } else if (
    obj.responsibilityBy !== obj.userCode &&
    obj.makerBy === obj.userCode &&
    obj.operationStatusCode === '12' &&
    obj.makerStatusCode === '11'
  ) {
    btnName = 'flagOutlinedInTableBlue'
    btnText = 'ทำแบบประเมิน'
    statusUse = 'ยังไม่ประเมิน'
    btnRoles = 'edit'
    // เงื่อนไขรายการ เราส่งต่อแบบประเมินให้คนอื่น
  } else if (
    obj.responsibilityBy === obj.userCode &&
    obj.makerBy !== obj.userCode &&
    obj.operationStatusCode === '12'
  ) {
    btnName = 'moreHorizontalInTableOrange'
    btnText = 'ส่งต่อแบบประเมิน'
    statusUse = 'ส่งต่อแบบประเมิน'
    btnRoles = 'edit'
    // เงื่อนไขรายการ เราส่งต่อแบบประเมินให้คนอื่น และเขาทำการบันทึกแบบประเมินแล้ว หรือ เราไม่ได้ส่งต่อแบบประเมินให้ใครแต่เราทำเอง และบันทึกแบบประเมินไว้แล้ว
  } else if (
    (obj.operationStatusCode === '20' && obj.operationStatusCode === '20') ||
    obj.operationStatusCode === '21'
  ) {
    btnName = 'moreHorizontalInTableOrange'
    btnText = 'กำลังทำแบบประเมิน'
    statusUse = 'กำลังดำเนินการ'
    btnRoles = 'edit'
    // เงื่อนไขรายการ เราส่งต่อแบบประเมินให้คนอื่น และเขาทำการบันทึก "เสร็จสิ้น" แบบประเมินแล้ว
  } else if (
    obj.responsibilityBy === obj.userCode &&
    obj.operationStatusCode === '22' &&
    obj.makerStatusCode === '31'
  ) {
    btnName = 'moreHorizontalInTableOrange'
    btnText = 'รอตรวจสอบ'
    statusUse = 'รอตรวจสอบ'
    btnRoles = 'edit'
    // เงื่อนไขรายการ มีคนส่งต่อแบบประเมินให้เรา และเราทำการบันทึก "เสร็จสิ้น" แบบประเมินแล้ว
  } else if (obj.makerBy === obj.userCode && obj.operationStatusCode === '22' && obj.makerStatusCode === '31') {
    btnName = 'moreHorizontalInTableGreen'
    btnText = 'ดูผลแบบประเมิน'
    statusUse = 'ดำเนินการแล้วเสร็จ'
    btnRoles = 'view'
    // แบบประเมิน เสร็จสิ้นการประเมิน แล้ว
  } else if (obj.operationStatusCode === '31') {
    btnName = 'moreHorizontalInTableGreen'
    btnText = 'เสร็จสิ้นการประเมิน'
    statusUse = 'เสร็จสิ้นการประเมิน'
    btnRoles = 'view'
  } else if (obj.operationStatusCode === '50' && obj.makerStatusCode === '50') {
    btnName = 'xInTableOrange'
    btnText = 'ยกเลิก'
    statusUse = 'ยกเลิก'
    btnRoles = 'view'
  }
  return { btnName: btnName, btnText: <Trans>{`${btnText}`}</Trans>, statusUse: statusUse, btnRoles: btnRoles }
}

export const assessCooperationListColumn = ({ menu, onOpenDetails = () => {} }) => {
  return [
    {
      title: <Trans>{'ประจำปี'}</Trans>,
      dataIndex: 'formYear',
      width: '100px',
      sorter: true,
      className: 'text-nowrap',
    },
    {
      title: <Trans>{'รหัส'}</Trans>,
      dataIndex: 'formCode',
      width: '150px',
      sorter: true,
      className: 'text-nowrap',
    },
    {
      title: <Trans>{'ชื่อแบบประเมิน'}</Trans>,
      dataIndex: 'formName',
      width: '300px',
      sorter: true,
      className: 'text-nowrap',
    },
    {
      title: <Trans>{'หน่วยรับตรวจ'}</Trans>,
      dataIndex: 'organizationName',
      width: '300px',
      sorter: true,
      className: 'text-nowrap',
    },
    {
      title: <Trans>{'วันที่เริ่มต้นประเมิน'}</Trans>,
      dataIndex: 'startDate',
      width: '100px',
      sorter: true,
      className: 'text-nowrap',
    },
    {
      title: <Trans>{'วันที่สิ้นสุดประเมิน'}</Trans>,
      dataIndex: 'endDate',
      width: '100px',
      sorter: true,
      className: 'text-nowrap',
    },
    {
      title: <Trans>{'สถานะ'}</Trans>,
      dataIndex: 'status',
      width: '200px',
      className: 'text-nowrap',
      align: 'center',
      sorter: true,
      render: (item, record) => {
        return <Status key={'status-form-1'} text={checkButton(record).statusUse}></Status>
      },
    },
    {
      title: '',
      dataIndex: 'operationStatusCode',
      width: '150px',
      className: 'text-nowrap',
      align: 'center',
      render: (item, record) => {
        return (
          <>
            {item && (
              <ButtonNew
                type={checkButton(record).btnName}
                // disabled={item === '11' ? false : true}
                roles={{ type: checkButton(record).btnRoles, menu: menu }}
                onClick={() => onOpenDetails(record, checkButton(record).btnRoles)}
              >
                {checkButton(record).btnText}
              </ButtonNew>
            )}
          </>
        )
      },
    },
    {
      title: '',
      dataIndex: 'options',
      width: '100px',
      className: 'text-nowrap',
      render: (item, record) => {
        const icons = []
        if (
          (record.operationStatusCode === '11' && record.makerBy === null) ||
          (record.operationStatusCode === '12' && record.makerStatusCode === '11')
        ) {
          icons.push({
            type: 'send',
            onClick: () => {
              onOpenDetails(record, 'send')
            },
          })
        }
        icons.push({
          type: 'view',
          onClick: () => {
            onOpenDetails(record, 'view')
          },
        })
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} size={14} icons={icons} />
          </div>
        )
      },
    },
  ]
}

export const colunmformGroups = ({
  formName,
  idFileDelete,
  setIdFileDelete,
  optionChoiceGroup,
  dataSave,
  onChangeData = () => {},
  editable,
  // form,
  key,
  t,
}) => {
  return [
    {
      title: <Trans>{'คำถาม'}</Trans>,
      dataIndex: 'formQuestName',
      width: '40%',
      className: 'text-nowrap',
      render: (item, record) => {
        return (
          <p>{`${record.formGroupSort}.${record.formSort} ${checkLanguageReturnData(
            record.formQuestNameTH,
            record.formQuestNameEN,
          )}`}</p>
        )
      },
    },
    {
      title: <Trans>{'คำตอบ'}</Trans>,
      dataIndex: 'choiceDetailId',
      width: '20%',
      className: 'text-nowrap',
      render: (item, record, index) => {
        const dataOptions = _.filter(optionChoiceGroup, function (o) {
          return record.choiceGroupCode === o.source.choiceGroupCode
        })
        // const defaultOption = _.filter(dataSave.formResultDetails, function (o) {
        //   return record.resultDetailId === o.resultDetailId
        // })
        const dataSaveManage = dataSave
        const fieldName = `choiceDetailId${index}`
        return (
          <>
            {record.choiceTypeCode === '10' ? (
              <Form.Item
                name={[fieldName, key]}
                initialValue={item}
                rules={[{ required: record.formRequired === 1 ? true : false, message: <Trans>จำเป็นต้องตอบ</Trans> }]}
              >
                <Select
                  disabled={!editable}
                  // defaultValue={defaultOption[0].choiceDetailId}
                  data={dataOptions}
                  placeholder={`ระบุคำตอบ`}
                  scrollableId={formName}
                  showSearch
                  onChange={(e) => {
                    const indexChange = _.findIndex(dataSaveManage.formResultDetails, function (o) {
                      return o.resultDetailId === record.resultDetailId
                    })
                    dataSaveManage.formResultDetails[indexChange].choiceDetailId = e
                    onChangeData(dataSaveManage)
                  }}
                />
              </Form.Item>
            ) : (
              ''
              //<InputNew placeholder={'ระบุคำอธิบายเพิ่มเติม...'} maxLength={250} />
            )}
          </>
        )
      },
    },
    {
      title: <Trans>{'คำอธิบายเพิ่มเติม'}</Trans>,
      dataIndex: 'answerDetail',
      width: '20%',
      className: 'text-nowrap',
      render: (item, record, index) => {
        const defaultData = _.filter(dataSave.formResultDetails, function (o) {
          return record.resultDetailId === o.resultDetailId
        })
        const dataSaveManage = dataSave
        const fieldName = `answerDetail${index}`
        return (
          <>
            <Form.Item
              name={[fieldName, key]}
              initialValue={defaultData[0].answerDetail}
              // rules={[{ required: record.formRequired === 1 ? true : false, message: <Trans>จำเป็นต้องตอบ</Trans> }]}
              rules={
                record?.formRequired && record.choiceTypeCode === '20'
                  ? [{ required: true, message: <Trans>{'จำเป็นต้องตอบ'}</Trans> }]
                  : []
              }
            >
              <InputNew
                // disabled={!editable}
                disabled={
                  !editable ? true : record.choiceTypeCode === '20' || record.formQuestType === 1 ? false : true
                }
                placeholder={t('ระบุคำอธิบายเพิ่มเติม...')}
                maxLength={250}
                // defaultValue={defaultData[0].answerDetail}
                onChange={(e) => {
                  const indexChange = _.findIndex(dataSaveManage.formResultDetails, function (o) {
                    return o.resultDetailId === record.resultDetailId
                  })
                  dataSaveManage.formResultDetails[indexChange].answerDetail = e.target.value
                  onChangeData(dataSaveManage)
                }}
              />
            </Form.Item>
          </>
        )
      },
    },
    {
      title: <Trans>{'แนบเอกสาร'}</Trans>,
      dataIndex: 'files',
      width: '20%',
      className: 'text-nowrap',
      render: (item, record) => {
        const dataSaveManage = dataSave
        const indexData = _.findIndex(dataSaveManage.formResultDetails, function (o) {
          return o.resultDetailId === record.resultDetailId
        })
        const idFile = dataSaveManage.formResultDetails[indexData].files
          ? JSON.parse('[' + dataSaveManage.formResultDetails[indexData].files + ']')
          : []
        return (
          <UploadNew
            disabled={!editable}
            type="mutiple"
            setIdFile={(e) => {
              dataSaveManage.formResultDetails[indexData].files = e.join()
              onChangeData(dataSaveManage)
            }}
            idFile={idFile}
            defaults={idFile}
            typeFile="file"
            actionType={editable ? 'edit' : 'view'}
            idFileDelete={idFileDelete}
            setIdFileDelete={setIdFileDelete}
          />
        )
      },
    },
  ]
}
