import React from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { TabNew } from '../../../components/tab/Tab'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { getSegmentUrl } from '../../../utilitys/helper'
import PJNPInformations from './conponent/tab/PJNPInformations'
import PJNPResponsiblePersonAndDuration from './conponent/tab/PJNPResponsiblePersonAndDuration'
import PJNPAdditionalDocuments from './conponent/tab/PJNPAdditionalDocuments'
// import { CardNew as Card } from '../../../components/card/Card'
import { Card } from 'antd'

export default function ApproveAuditProjectNotPlanDetail() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = getSegmentUrl(4)
  const { state } = useLocation()
  if (!state) return <Navigate to={'/auditplanning/approveoffplanprojects'} />

  const breadcrumbDetail = [
    { label: t('งานวางแผนการตรวจสอบ'), path: '/auditplanning/' },
    { label: t('อนุมัติโครงการนอกแผน'), path: '/auditplanning/approveoffplanprojects' },
    { label: t('รายละเอียดโครงการ'), path: '' },
  ]

  const tabs = [
    {
      key: 1,
      name: t('ข้อมูลทั่วไปโครงการ'),
      disabled: false,
      content: () => <>{params === 'pjgeneralinformation' || !params ? <PJNPInformations /> : ''}</>,
    },
    {
      key: 2,
      name: t('ผู้รับผิดชอบ/ระยะเวลา'),
      disabled: false,
      content: () => <>{params === 'pjpersonandduration' ? <PJNPResponsiblePersonAndDuration /> : ''}</>,
    },
    {
      key: 3,
      name: t('เอกสารเพิ่มเติม'),
      disabled: false,
      content: () => <>{params === 'pjdocuments' ? <PJNPAdditionalDocuments /> : ''}</>,
    },
  ]
  return (
    <>
      <Breadcrumb data={breadcrumbDetail} title={t('รายละเอียดโครงการ')} />
      <Card>
        <TabNew
          tab={tabs}
          activeKey={
            params === 'pjgeneralinformation'
              ? '1'
              : params === 'pjpersonandduration'
              ? '2'
              : params === 'pjdocuments'
              ? '3'
              : '1'
          }
          onTabClick={(key) => {
            if (key === '1') {
              navigate('/auditplanning/approveoffplanprojects/auditprojectnotplandetial/pjgeneralinformation', {
                state: state,
              })
            } else if (key === '2') {
              navigate('/auditplanning/approveoffplanprojects/auditprojectnotplandetial/pjpersonandduration', {
                state: state,
              })
            } else if (key === '3') {
              navigate('/auditplanning/approveoffplanprojects/auditprojectnotplandetial/pjdocuments', { state: state })
            }
          }}
        />
      </Card>
    </>
  )
}
