import { gql } from '@apollo/client'

export const SELECT_ROLES = gql`
  query RolesSelect {
    rolesSelect {
      roleId
      roleNameTH
      roleNameEN
      roleCode
    }
  }
`
