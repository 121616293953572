import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GET_SELECT_AUDIT_JOB_STEP } from '../../graphql/Query'
import { Select } from '../../../../../components/select/Select.jsx'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText } from '../../../../../utilitys/helper'

const SelectAuditJobStep = (props) => {
  const { t } = useTranslation()
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { jobGroupCode, jobAndProjectCode, personnelCode } = filters || {}

  const [isData, isSetData] = useState([])

  const [getSelectAuditJobStepFn, getSelectAuditJobStep] = useLazyQuery(GET_SELECT_AUDIT_JOB_STEP)

  useEffect(() => {
    getSelectAuditJobStepFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({}) },
    })
  }, [jobGroupCode, jobAndProjectCode, personnelCode])

  useEffect(() => {
    if (getSelectAuditJobStep.data) {
      isSetData(
        _.map(getSelectAuditJobStep?.data?.getSelectAuditJobStep, (item) => {
          return {
            label: t('ขั้นที่') + '  ' + item.customSort + ' ' + displayText(item?.stepNameTH, item?.stepNameEN),
            value: item?.stepId,
            source: item,
          }
        }),
      )
    }
  }, [getSelectAuditJobStep.data])
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getSelectAuditJobStep.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectAuditJobStep
