import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_CERTIFICATE_TYPE } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectCertificatesType = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { isActive } = filters || {}
  const [isData, isSetData] = useState([])

  const [getMasterCertificateTypeFn, getMasterCertificateType] = useLazyQuery(GET_MASTER_CERTIFICATE_TYPE)

  useEffect(() => {
    getMasterCertificateTypeFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) }
    })
  }, [isActive])

  useEffect(() => {
    if (getMasterCertificateType.data) {
      isSetData(
        _.map(getMasterCertificateType?.data?.getMasterCertificateType, (item) => {
          return {
            label: displayText(item?.certificateTypeNameTH, item?.certificateTypeNameEN),
            value: item?.certificateTypeCode,
            source: item
          }
        })
      )
    }
  }, [getMasterCertificateType.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterCertificateType.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectCertificatesType
