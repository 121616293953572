import { gql } from '@apollo/client'

export const GET_ICA_REPORT_PAGINATION = gql`
  query getIcaReportPagination($input: IcaReportInput!) {
    getIcaReportPagination(input: $input) {
      result {
        controlFormCode
        controlFormId
        controlFormTH
        controlFormEN
        operationStatusCode
      }
      count
      page
      limit
    }
  }
`

export const GET_ICA_SUB_REPORT = gql`
  query getIcaSubReport($input: IcaSubReportInput!) {
    getIcaSubReport(input: $input) {
      controlResultId
      controlFormCode
      controlFormYear
      controlFormEN
      controlFormTH
      organizationCode
      organizationNameEN
      organizationNameTH
      operationStatusCode
      responsibilityBy
      makerBy
      succeedAt
      dateStart
      dateEnd
      p1_fullNameTH
      p1_fullNameEN
      p2_fullNameTH
      p2_fullNameEN
    }
  }
`

export const EXPORT_ICA_REPORT = gql`
  query exportIcaReport($input: IcaReportExportInput!) {
    exportIcaReport(input: $input) {
      message
      fileUrl
    }
  }
`
