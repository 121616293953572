import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_RAC_MATRIX_PAGINATION } from './graphql/Query'
import { DELETE_AUDIT_RAC_MATRIX } from './graphql/Mutation'
import { CardNew } from '../../../components/card/Card'
import { ButtonNew } from '../../../components/button/Button'
import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { Status } from '../../../components/status/Status'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { racMatrixListCrumb } from './utils/constants'
import { displayText, displayError } from '../../../utilitys/helper'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { encryptInput } from '../../../utilitys/crypto'
import { handleSort } from '../../../utilitys/pagination'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import RacMatrixFilter from './components/filter/RacMatrixFilter'
import ExportModal from './components/modal/ExportModal'

const RacMatrixList = (props) => {
  const { menu } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [user] = useDecodeUser()

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState([])
  const [visible, setVisible] = useState(false)
  const [dataExportModal, setDataExportModal] = useState(null)
  const [userId, setUserId] = useState(null)

  const [deleteAuditRacMatrixFn, deleteAuditRacMatrix] = useMutation(DELETE_AUDIT_RAC_MATRIX)
  const [getRacMatrixPaginationFn, getRacMatrixPagination] = useLazyQuery(GET_RAC_MATRIX_PAGINATION)

  useEffect(() => {
    if (user?.userId) setUserId(user?.userId + '')
  }, [user?.userId])

  useEffect(() => {
    if (user?.orgCode && user?.pCode) {
      const pCode = { personnelCode: user?.pCode }
      const orgCode = filters.organizationCode ? {} : { organizationCode: user?.orgCode }

      getRacMatrixPaginationFn({
        variables: {
          input: encryptInput({
            filters: { ...filters, ...pCode, ...orgCode },
            pagination: { limit, page },
            sort,
          }),
        },
      })
    }
  }, [page, limit, filters, sort, user?.orgCode, user?.pCode])

  useEffect(() => {
    if (getRacMatrixPagination?.data) {
      let response = getRacMatrixPagination?.data?.getRacMaTrixPagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            ...data,
            key: data.projectCode,
            projectName: displayText(
              `${data.projectCode}: ${data.projectNameTH}`,
              `${data.projectCode}: ${data.projectNameEN}`,
            ),
            projectRefName: displayText(data.projectRefNameTH, data.projectRefNameEN),
            dateEnd: dateDisplay(data.dateEnd),
            dateStart: dateDisplay(data.dateStart),
            organizationName: displayText(data.organizationNameTH, data.organizationNameEN),
            personnelName: displayText(data.personnelNameTH, data.personnelNameEN),
            operationStatusName: displayText(data.operationStatusNameTH, data.operationStatusNameEN),
          }
        }),
      )
    }

    if (getRacMatrixPagination.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getRacMatrixPagination?.error)),
        onOk() {},
      })
    }
  }, [getRacMatrixPagination?.data])

  useEffect(() => {
    if (deleteAuditRacMatrix?.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          getRacMatrixPagination.refetch()
        },
      })
    }

    if (deleteAuditRacMatrix.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(deleteAuditRacMatrix?.error)),
        onOk() {},
      })
    }
  }, [deleteAuditRacMatrix.data])

  const onFilter = (data) => {
    Promise.all([setPage(1), setLimit(10), setFilters(data)])
  }

  const onDeleteAuditRacMatrix = (rcmId) =>
    Alert({
      type: 'confirm',
      title: t('กรุณากดยืนยันหากต้องการลบข้อมูล'),
      onOk() {
        deleteAuditRacMatrixFn({ fetchPolicy: 'no-cache', variables: encryptInput({ rcmId: rcmId }) })
      },
      onCancel() {},
    })

  const columns = [
    {
      title: t('โครงการ'),
      dataIndex: 'projectName',
      width: '400px',
      align: 'left',
      sorter: { multiple: 1 },
      fixed: 'left',
    },
    { title: t('แหล่งที่มาของโครงการ'), dataIndex: 'projectRefName', width: '250px', sorter: { multiple: 2 } },
    { title: t('วันที่เริ่มต้น'), dataIndex: 'dateStart', width: '150px', sorter: { multiple: 3 }, align: 'center' },
    { title: t('วันที่สิ้นสุด'), dataIndex: 'dateEnd', width: '150px', sorter: { multiple: 4 }, align: 'center' },
    { title: t('หน่วยงานที่รับผิดชอบ'), dataIndex: 'organizationName', width: '250px', sorter: { multiple: 5 } },
    { title: t('ผู้รับผิดชอบ'), dataIndex: 'personnelName', width: '200px' },
    {
      title: t('สถานะ'),
      dataIndex: 'operationStatusName',
      width: '150px',
      render: (d) => <Status text={d} />,
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '120px',
      align: 'right',
      render: (text, record) => {
        let list = [
          {
            type: 'download',
            onClick: () => {
              setDataExportModal({ rcmId: record?.rcmId })
              setVisible(true)
            },
          },
          {
            type: 'view',
            onClick: () =>
              navigate(`tab/general`, {
                state: {
                  typeAction: 'view',
                  rcmId: record.rcmId,
                  projectCode: record.projectCode,
                  rcmCreateBy: record?.createdBy,
                },
              }),
          },
          {
            type: 'edit',
            onClick: () =>
              navigate(`tab/general`, {
                state: {
                  typeAction: 'edit',
                  rcmId: record.rcmId,
                  projectCode: record.projectCode,
                  rcmCreateBy: record?.createdBy,
                },
              }),
          },
          { type: 'delete', onClick: () => onDeleteAuditRacMatrix(record.rcmId) },
        ]

        if (userId !== record?.createdBy) list.pop()

        return <ButtonGroup menu={menu} size={18} icons={list} />
      },
    },
  ]

  return (
    <>
      {(getRacMatrixPagination?.loading || deleteAuditRacMatrix?.loading) && <SpinnersNew />}

      <Col sm={24} md={24} lg={24}>
        <Breadcrumb data={racMatrixListCrumb} title={t('ประเมินความเสี่ยงและการควบคุม')} />
        <RacMatrixFilter onFilter={onFilter} menu={menu} />
        <div style={{ marginBottom: '2rem' }}>
          <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col sm={24} md={24} lg={24} xl={24}>
                <Datatable
                  columns={columns}
                  data={[...dataLists]}
                  total={count}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  pageSize={limit}
                  page={page}
                  showSizeChanger={true}
                  searchCustom={true}
                  handleTableChange={(e) => {
                    const reNewField = [
                      { projectName: displayText('projectNameTH', 'projectNameEN') },
                      { projectRefName: displayText('projectRefNameTH', 'projectRefNameEH') },
                      { organizationName: displayText('organizationNameTH', 'organizationNameEN') },
                      { personnelName: displayText('firstNameTH', 'firstNameEN') },
                    ]

                    setSort(handleSort(e?.sorter, reNewField))
                  }}
                  btnAdd={
                    <ButtonNew
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: menu }}
                      onClick={() => navigate(`tab/general`, { state: { typeAction: 'add' } })}
                    >
                      {t('เพิ่มการประเมินความเสี่ยง')}
                    </ButtonNew>
                  }
                />
              </Col>
            </Row>
          </CardNew>
          <ExportModal
            open={visible}
            dataExportModal={dataExportModal}
            close={(e) => setVisible(e)}
            menu={props.menu}
          />
        </div>
      </Col>
    </>
  )
}

RacMatrixList.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default RacMatrixList
