import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_SELECT_RISK_FORMULAS } from './graphql/Query'
import { Select } from '../../select/Select'
import { displayText } from '../../../utilitys/helper'
import * as _ from 'lodash'

const SelectRiskFormulas = (props) => {
  const { placeholder, formname, handleChange, ...otherProp } = props

  const [isData, isSetData] = useState([])

  const getSelectRiskFormulas = useQuery(GET_SELECT_RISK_FORMULAS)

  useEffect(() => {
    if (getSelectRiskFormulas.data) {
      isSetData(
        _.map(getSelectRiskFormulas?.data?.getSelectRiskFormulas, (item) => {
          let label = displayText(item.riskFormulaNameTH, item.riskFormulaNameEN)
          if (!label) label = item.riskFormulaNameTH
          return {
            value: item.riskFormulaCode,
            label: `${item.riskFormulaCode} : ${label}`,
            source: item,
          }
        }),
      )
    }
  }, [getSelectRiskFormulas.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getSelectRiskFormulas.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectRiskFormulas
