import { gql } from '@apollo/client'
export const GET_AUDIT_SUGGEST_ORG = gql`
  query getAuditSuggestOrganizations($input: SelectAuditSuggestOrganizationsInput!) {
    getAuditSuggestOrganizations(data: $input) {
      organizationCode
      organizationNameTH
      organizationNameEN
      personnelCode
      personnelNameTH
      personnelNameEN
    }
  }
`

export const GET_AUDIT_SUGGEST_ORG_FOR_AUDIT_CLOSES = gql`
  query GetAuditSuggestOrganizationsOfAuditCloses {
    getAuditSuggestOrganizationsOfAuditCloses {
      organizationCode
      organizationNameTH
      organizationNameEN
    }
  }
`
