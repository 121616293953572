import { Circle, Grid } from 'react-feather'
import { IconNew } from '../components/icon/Icon'
import { decryptData } from '../utilitys/crypto'

const Components = [
  {
    menuCode: 'C00',
    menuNameTH: 'Components',
    menuNameEN: 'Components',
    menuPath: '/components',
    icon: <Grid size={22} />,
    children: [
      {
        menuCode: 'C01',
        menuPath: '/components/breadcrumb',
        menuNameTH: 'Breadcrumb',
        menuNameEN: 'Breadcrumb',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C02',
        menuPath: '/components/modal',
        menuNameTH: 'modal',
        menuNameEN: 'modal',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C03',
        menuPath: '/components/progress',
        menuNameTH: 'progress',
        menuNameEN: 'progress',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C04',
        menuPath: '/components/spinners',
        menuNameTH: 'spinners',
        menuNameEN: 'spinners',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C05',
        menuPath: '/components/datatable',
        menuNameTH: 'datatable',
        menuNameEN: 'datatable',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C06',
        menuPath: '/components/select',
        menuNameTH: 'select',
        menuNameEN: 'select',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C07',
        menuPath: '/components/checkbox',
        menuNameTH: 'checkbox',
        menuNameEN: 'checkbox',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C08',
        menuPath: '/components/radio',
        menuNameTH: 'radio',
        menuNameEN: 'radio',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C09',
        menuPath: '/components/tabcomponent',
        menuNameTH: 'tabcomponent',
        menuNameEN: 'tabcomponent',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C10',
        menuPath: '/components/feathericons',
        menuNameTH: 'feathericons',
        menuNameEN: 'feathericons',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C11',
        menuPath: '/components/tooltip',
        menuNameTH: 'tooltip',
        menuNameEN: 'tooltip',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C12',
        menuPath: '/components/button',
        menuNameTH: 'button',
        menuNameEN: 'button',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C13',
        menuPath: '/components/input',
        menuNameTH: 'input',
        menuNameEN: 'input',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C14',
        menuPath: '/components/textarea',
        menuNameTH: 'textarea',
        menuNameEN: 'textarea',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C15',
        menuPath: '/components/datepicker',
        menuNameTH: 'datepicker',
        menuNameEN: 'datepicker',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C16',
        menuPath: '/components/timepicker',
        menuNameTH: 'timepicker',
        menuNameEN: 'timepicker',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C17',
        menuPath: '/components/label',
        menuNameTH: 'label',
        menuNameEN: 'label',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C18',
        menuPath: '/components/upload',
        menuNameTH: 'upload',
        menuNameEN: 'upload',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C19',
        menuPath: '/components/tableaddmultirow',
        menuNameTH: 'tableaddmultirow',
        menuNameEN: 'tableaddmultirow',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C20',
        menuPath: '/components/formvalidate',
        menuNameTH: 'formvalidate',
        menuNameEN: 'formvalidate',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C21',
        menuPath: '/components/card',
        menuNameTH: 'card',
        menuNameEN: 'card',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C22',
        menuPath: '/components/alert',
        menuNameTH: 'alert',
        menuNameEN: 'alert',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C23',
        menuPath: '/components/filter',
        menuNameTH: 'filter',
        menuNameEN: 'filter',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C24',
        menuPath: '/components/inputfromapi',
        menuNameTH: 'inputfromapi',
        menuNameEN: 'inputfromapi',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C25',
        menuPath: '/components/status',
        menuNameTH: 'status',
        menuNameEN: 'status',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C26',
        menuPath: '/components/tablemergecolumn',
        menuNameTH: 'Table merge column',
        menuNameEN: 'Table merge column',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C27',
        menuPath: '/components/calendar',
        menuNameTH: 'Calendar',
        menuNameEN: 'Calendar',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
      {
        menuCode: 'C28',
        menuPath: '/components/colorpicker',
        menuNameTH: 'Color Picker',
        menuNameEN: 'Color Picker',
        menuParentCode: 'C00',
        icon: <Circle size={12} />,
      },
    ],
  },
]

if (!localStorage.getItem('path') && localStorage.getItem('user')) {
  localStorage.removeItem('user')
  window.location.href = 'login'
}
const env = process.env.REACT_APP_ENV
const { userMenus = [] } = decryptData(localStorage.getItem('user'))
const dataMenus = decryptData(localStorage.getItem('path'))
const data =
  dataMenus !== '' && userMenus !== ''
    ? dataMenus
        .filter((item) => userMenus.includes(item.menuCode))
        .map((item) => {
          return {
            ...item,
            icon: <IconNew icon={item.icon} size={22} />,
            children: item.children
              ? item.children
                  .filter((item2) => userMenus.includes(item2.menuCode))
                  .map((item2) => {
                    return {
                      ...item2,
                      icon: <IconNew icon={item2.icon} size={12} />,
                      menuPath: item2.menuPath ? item2.menuPath.trim() : '',
                    }
                  })
              : [],
          }
        })
    : []

const menus = env === 'local' ? Components.concat(data) : data
export default menus
