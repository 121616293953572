import React from 'react'
import { Route, Routes } from 'react-router-dom'
import FollowUpAuditIndex from './followupaudit/FollowUpAuditIndex'
import FormFollowUpAuditIndex from './followupaudit/tab/FormFollowUpAuditIndex'
import FormFollowUpAuditActivityTabForm from './followupaudit/tab/activity/FormFollowUpAuditActivityTabForm'
import FormFollowUpAuditResultfollowTabForm from './followupaudit/tab/resultfollow/FormFollowUpAuditResultfollowTabForm'
import ActionExplainIndex from './actionexplain/ActionExplainIndex'
import FormActionList from './actionexplain/form/FormActionList.jsx'
import FormActionManage from './actionexplain/form/FormActionManage.jsx'
/**
 * @function Router
 * @description
 * Module: Audit Plan
 * โมดูล: แผนการตรวจสอบ
 * @returns Routes
 */

const Router = () => {
  return (
    <Routes>
      <Route path="/followupaudit">
        <Route path="" element={<FollowUpAuditIndex menu={'FU01'} />} />
        <Route path="manager/follow" element={<FormFollowUpAuditIndex menu={'FU01'} />} />
        <Route path="manager/edit" element={<FormFollowUpAuditIndex menu={'FU01'} />} />
        <Route path="manager/view" element={<FormFollowUpAuditIndex menu={'FU01'} />} />
        <Route path="activity/edit" element={<FormFollowUpAuditIndex menu={'FU01'} />} />
        <Route path="activity/edit/form/add" element={<FormFollowUpAuditActivityTabForm menu={'FU01'} />} />
        <Route path="activity/edit/form/edit" element={<FormFollowUpAuditActivityTabForm menu={'FU01'} />} />
        <Route path="activity/edit/form/view" element={<FormFollowUpAuditActivityTabForm menu={'FU01'} />} />
        <Route path="activity/view" element={<FormFollowUpAuditIndex menu={'FU01'} />} />
        <Route path="activity/view/form/view" element={<FormFollowUpAuditActivityTabForm menu={'FU01'} />} />
        <Route path="resultfollow/edit" element={<FormFollowUpAuditIndex menu={'FU01'} />} />
        <Route path="resultfollow/edit/scan/edit" element={<FormFollowUpAuditResultfollowTabForm menu={'FU01'} />} />
        <Route path="resultfollow/edit/scan/view" element={<FormFollowUpAuditResultfollowTabForm menu={'FU01'} />} />
        <Route path="resultfollow/view" element={<FormFollowUpAuditIndex menu={'FU01'} />} />
        <Route path="closeissue/edit" element={<FormFollowUpAuditIndex menu={'FU01'} />} />
        <Route path="closeissue/view" element={<FormFollowUpAuditIndex menu={'FU01'} />} />
        <Route path="follownumber/edit" element={<FormFollowUpAuditIndex menu={'FU01'} />} />
        <Route path="follownumber/view" element={<FormFollowUpAuditIndex menu={'FU01'} />} />
      </Route>
      <Route path="/actionexplain">
        <Route path="" element={<ActionExplainIndex menu={'FU02'} />} />
        <Route path="formactionlist" element={<FormActionList menu={'FU02'} />} />
        <Route path="formactionmanage" element={<FormActionManage menu={'FU02'} />} />
      </Route>
    </Routes>
  )
}
export default Router
