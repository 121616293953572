import React from 'react'
import { Select } from '../../select/Select'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'

const SelectOrganizationChecked = (props) => {
  const { placeholder, formname, handleChange, ...otherProp } = props
  const { t } = useTranslation()

  let isData = [
    { value: 1, label: t('ไม่เป็นหน่วยรับตรวจ') },
    { value: 2, label: t('เป็นหน่วยตรวจสอบ') },
    { value: 3, label: t('หน่วยรับตรวจ') },
  ]
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectOrganizationChecked
