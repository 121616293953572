import { gql } from '@apollo/client'

export const SENDFORMBYASSESSCOOPERATION = gql`
  mutation sendFormByAssessCooperation($input: AssessCooperationSendInput!) {
    sendFormByAssessCooperation(input: $input) {
      formResultId
    }
  }
`

export const SAVEFORMRESULTSBYASSESSCOOPERATION = gql`
  mutation saveformResultsByAssessCooperation($input: AssessCooperationSaveInput!) {
    saveformResultsByAssessCooperation(input: $input) {
      formResultId
      operationStatusCode
      makerStatusCode
    }
  }
`
