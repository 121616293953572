import { Form } from 'antd'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { InputNumberNew, InputNew } from '../../../../components/input/Input'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import SelectNumberFormatTypes from '../../../../components/inputfromapi/selectnumberformattypes/SelectNumberFormatTypes.jsx'

export const validateKeyNo = (required, message = false, other = false) => {
  const { t } = useTranslation()

  let validObj = {
    validator: (_, value) => {
      if (!value) {
        return Promise.reject(new Error(t(message)))
      } else {
        return Promise.resolve()
      }
    },
  }

  if (required) validObj.required = true

  return !other ? [{ ...validObj }] : [{ ...validObj, ...other }]
}

export const Columns = ({ editable = false, fncDelete = () => {}, menu, form, formName, disable, setDisable }) => {
  const { t } = useTranslation()
  return [
    {
      title: t('ลำดับ'),
      dataIndex: 'keyNo',
      width: '10%',
      render: (item, record, index) => (
        <Form.Item
          name={['doucumentForm', index, 'keyNo']}
          initialValue={item}
          wrapperCol={{
            md: 24,
          }}
          rules={[
            { required: true, message: t('กรุณากรอกลำดับ') },
            {
              validator: (_, value) => {
                const formNew = form.getFieldsValue()
                const exists = formNew.doucumentForm.find(
                  (p, indexF) => p.keyNo + '' === value + '' && indexF !== index,
                )
                if (exists) {
                  return Promise.reject(new Error(t('ลำดับถูกใช้งานแล้ว')))
                } else {
                  return Promise.resolve()
                }
              },
            },
          ]}
        >
          <InputNumberNew disabled={!editable} placeholder={t('กรอกลำดับ')} min={1} />
        </Form.Item>
      ),
    },
    {
      title: t('ประเภทรูปแบบ'),
      dataIndex: 'pattern',
      width: '27%',
      render: (item, record, index) => (
        <Form.Item
          name={['doucumentForm', index, 'pattern']}
          initialValue={item}
          wrapperCol={{
            md: 24,
          }}
        >
          <SelectNumberFormatTypes
            formname={formName}
            placeholder={t('ประเภทรูปแบบ')}
            disabled={!editable}
            handleChange={(e) => {
              let items = [...disable]
              const fields = form.getFieldsValue()
              if (e.value === 'AD' || e.value === 'BE') {
                fields.doucumentForm[index].patternNo = e.source.formatDefault
                items[index] = true
              } else {
                items[index] = false
                fields.doucumentForm[index].patternNo = null
              }
              fields.doucumentForm[index].pattern = e.value

              setDisable(items)
              form.setFieldsValue(fields)
            }}
          />
        </Form.Item>
      ),
    },
    {
      title: t('รูปที่เลขที่'),
      dataIndex: 'patternNo',
      width: '35%',
      render: (item, record, index) => (
        <Form.Item
          name={['doucumentForm', index, 'patternNo']}
          initialValue={item}
          wrapperCol={{
            md: 24,
          }}
          onChange={(e) => {
            const fields = form.getFieldsValue()
            fields.doucumentForm[index].patternNo = e.target.value
            form.setFieldsValue(fields)
          }}
        >
          <InputNew
            maxLength={20}
            placeholder={t('กรอกรูปที่เลขที่')}
            disabled={!editable ? !editable : disable.length > 0 ? disable[index] : false}
          />
        </Form.Item>
      ),
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '8%',
      render: (item, record, index) => {
        const icons = []

        if (editable) {
          icons.push({
            type: 'delete',
            onClick: () => {
              fncDelete(index)
            },
          })
        }

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} size={14} icons={icons} />
          </div>
        )
      },
    },
  ]
}
