import { gql } from '@apollo/client'

export const GET_MASTER_PERSONNEL_STATUS = gql`
  query getMasterPersonnelStatus($input: MasterPersonnelStatusInput!) {
    getMasterPersonnelStatus(data: $input) {
      personnelStatusId
      personnelStatusCode
      personnelStatusNameTH
      personnelStatusNameEN
      isActive
    }
  }
`
