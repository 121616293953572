import { Col, Form, Radio, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import moment from 'moment'

import { Columns } from './utils/CheckerOrganizationColumn'
import SelectAuditJobStepByActionPlanId from '../components/Select/SelectAuditJobStepByStepIdRef'
import { SAVE_AUDIT_JOB_STEP } from './graphql/Mutation'
import {
  GET_AUDIT_JOB_STEP_BY_STEP_ID,
  GET_AUDIT_PROJECT_DATE_BY_ACTION_PLAN_ID,
  GET_AUDIT_RESULT_INSPEC_TION_PROJECT,
} from './graphql/Query'
import { GET_HOLIDAY } from '../graphql/Query'

import { BreadcrumbNew } from '../../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../../components/button/Button'
import { CardNew } from '../../../../components/card/Card'
import { Datatable } from '../../../../components/datatable/Datatable'
import { InputNew, InputNumberNew } from '../../../../components/input/Input'
import { LabelNew } from '../../../../components/label/Label'
import { RadioGroup } from '../../../../components/radio/Radio'
import { TextAreaNew } from '../../../../components/textarea/TextArea'
import { displayError, displayText, mergeArrayObjects, moneyFormat, regexNumber } from '../../../../utilitys/helper'
import CheckerOrganizationModal from '../../../components/modalformapi/modalCheckerOrganization/CheckerOrganizationModal'
import { useLanguage } from '../../../../hooks/useLanguage'
import { encryptInput } from '../../../../utilitys/crypto'
import { Alert } from '../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import SelectPersonnel from '../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import { Datepicker } from '../../../../components/datepicker/Datepicker'
import SelectAuditTeams from '../../../../components/inputfromapi/selectauditteams/SelectAuditTeams.jsx'

const Responsive = {
  md: 24,
  lg: 24,
  offset: 2,
}

const ActionPlanFormStepDetail = ({ menu }) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isLang] = useLanguage()

  const { state } = useLocation()

  if (!state) {
    return <Navigate to={'/auditoperations/actionplan'} />
  }

  let {
    refCode: stepId = null,
    actionPlanId = null,
    type_action = 'view',
    auditTeamCode = null,
    typeActionMain = 'view',
    projectCode = null,
  } = state

  const [editable, seteditable] = useState(false)

  useEffect(() => {
    if (type_action) {
      if (type_action !== 'view') seteditable(true)
    }
  }, [type_action])

  const [callGetHoliday, { data: dataHoliday }] = useLazyQuery(GET_HOLIDAY)
  useEffect(() => {
    if (dataHoliday) {
      const response = dataHoliday.auditProjectGetCalendarDetailsType1

      if (response) {
        const holiday = _.groupBy(response, (item) => moment(item.dateAt).format('YYYY'))

        form.setFieldsValue({ holiday })
      }
    }
  }, [dataHoliday])

  const [callGetProjectDate, { loading: loadingGetProjectDate, error: errorGetProjectDate, data: dataGetProjectDate }] =
    useLazyQuery(GET_AUDIT_PROJECT_DATE_BY_ACTION_PLAN_ID)

  useEffect(() => {
    if (actionPlanId) {
      const dataInput = {
        actionPlanId: parseInt(actionPlanId),
      }
      callGetProjectDate({
        variables: {
          input: encryptInput(dataInput),
        },
      })
    }
  }, [actionPlanId])

  useEffect(() => {
    if (errorGetProjectDate) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorGetProjectDate)),
      })
    }
  }, [errorGetProjectDate])

  useEffect(() => {
    if (dataGetProjectDate) {
      const fields = dataGetProjectDate.getAuditProjectDateByActionPlanId
      const { dateStart, dateEnd, projectCode } = fields

      form.setFieldsValue({ projectDateStart: dateStart, projectDateEnd: dateEnd, projectCode })
    }
  }, [dataGetProjectDate])

  const [callGet, { loading: loadingGet, error: errorGet, data: dataGet }] = useLazyQuery(GET_AUDIT_JOB_STEP_BY_STEP_ID)

  useEffect(() => {
    if (stepId && actionPlanId) {
      const dataInput = {
        stepId: parseInt(stepId),
      }
      callGet({
        variables: {
          input: encryptInput(dataInput),
        },
      })
    }
    callGetHoliday()
  }, [])
  useEffect(() => {
    if (dataGet) {
      const fields = dataGet.getAuditJobStepByStepId

      let {
        personnels = [],
        stepNameTH,
        stepNameEN,
        stepDetailTH,
        stepDetailEN,
        remarkTH,
        remarkEN,
        manDay,
        organizations,
        stepIdRef,
      } = fields
      setIdRef(stepIdRef)

      fields.stepName = displayText(stepNameTH, stepNameEN)
      fields.stepDetail = displayText(stepDetailTH, stepDetailEN)
      fields.remark = displayText(remarkTH, remarkEN)
      fields.manDay = parseInt(manDay)
      fields.personnels = personnels.map((items) => {
        return items.personnelCode
      })
      /* auditTeamCode มาจาก State ที่ส่งมาจาก List */
      fields.auditTeamCode = auditTeamCode

      const organizationsTemp = organizations.map((items, key) => {
        const organizationName = displayText(items.organizationNameTH, items.organizationNameEN)
        const organizationLevelName = displayText(items.organizationLevelNameTH, items.organizationLevelNameEN)
        const personnelName = displayText(items.personnelNameTH, items.personnelNameEN)
        return {
          ...items,
          key,
          organizationName,
          organizationLevelName,
          personnelName,
        }
      })

      fields.organizations = organizationsTemp
      setDataLists(organizationsTemp)

      // setprojectCode(projectCode)

      form.setFieldsValue(fields)
    }
    if (errorGet) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorGet)),
      })
    }
  }, [dataGet])

  useEffect(() => {
    if (auditTeamCode) {
      form.setFieldsValue({ auditTeamCode })
    }
  }, [auditTeamCode])

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_AUDIT_JOB_STEP, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/auditoperations/actionplan/form', {
            state: { ...state, refCode: actionPlanId, type_action: 'edit', defaultActiveKey: '2', auditTeamCode },
          })
        },
      })
    }
  }, [dataSave])

  const arrCrumb = [
    { label: t('งานปฎิบัติการตรวจสอบ'), path: '/auditoperations/actionplan' },
    { label: t('แผนการปฎิบัติงาน'), path: '/auditoperations/actionplan' },
    { label: t('รายละเอียดแผนปฏิบัติงาน'), path: '/auditoperations/actionplan' },
    { label: t('เพิ่มขั้นตอนย่อย') },
  ]

  const [errorOrganizationList, seterrorOrganizationList] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [modal, setModal] = useState(false)
  const [paginate, setPaginate] = useState({ page: 1, pageSize: 10 })

  const [idRef, setIdRef] = useState()

  const onFinish = () => {
    if (!validDate()) {
      return null
    }

    const fields = form.getFieldValue()

    const {
      stepSort,
      stepName,
      stepNameTH,
      stepNameEN,
      stepDetail,
      stepDetailTH,
      stepDetailEN,
      remark,
      remarkTH,
      remarkEN,
      manDay,
      budgets,
      dateStart,
      dateEnd,
      operationStatusCode,
      isActive,
      personnels,
      organizations,
      projectCode,
      stepId,
      stepIdRef,
    } = fields

    const dataInput = {
      actionPlanId,
      stepType: 2,
      stepSort,
      manDay: manDay.toString(),
      budgets: budgets ? parseFloat(budgets) : null,
      dateStart,
      dateEnd,
      isActive,
      personnels,
      organizations: organizations
        ? organizations.map((items) => {
            return items.organizationCode
          })
        : null,
      projectCode,
      stepIdRef: parseInt(stepIdRef),
    }

    if (type_action === 'add') {
      dataInput.typeAction = 'add'
      dataInput.stepNameTH = stepName
      dataInput.stepNameEN = stepName
      dataInput.remarkTH = remark ? remark : null
      dataInput.remarkEN = remark ? remark : null
      dataInput.stepDetailTH = stepDetail ? stepDetail : null
      dataInput.stepDetailEN = stepDetail ? stepDetail : null

      dataInput.operationStatusCode = 10
    } else {
      dataInput.stepId = stepId
      dataInput.typeAction = 'edit'
      dataInput.operationStatusCode = operationStatusCode
      if (isLang === 'th') {
        dataInput.stepNameTH = stepName
        dataInput.stepNameEN = stepNameEN
        dataInput.remarkTH = remark ? remark : null
        dataInput.remarkEN = remarkEN ? remarkEN : null
        dataInput.stepDetailTH = stepDetail ? stepDetail : null
        dataInput.stepDetailEN = stepDetailEN ? stepDetailEN : null
      } else {
        dataInput.stepNameTH = stepNameTH
        dataInput.stepNameEN = stepName
        dataInput.remarkTH = remarkTH ? remarkTH : null
        dataInput.remarkEN = remark ? remark : null
        dataInput.stepDetailTH = stepDetailTH ? stepDetailTH : null
        dataInput.stepDetailEN = stepDetail ? stepDetail : null
      }
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const fncOnpressDelete = (index) => {
    let filterTable = [..._.filter(dataLists, (e, key) => key !== index)]

    if (dataLists.length > 1) {
      let newTable = filterTable.map((item, key) => {
        return { ...item, key }
      })

      newTable = _.uniqBy(newTable, 'organizationCode')

      setDataLists(newTable)
      form.setFieldsValue({ organizations: newTable })
    } else {
      form.setFieldsValue({ organizations: [] })
      setDataLists([])
    }
  }

  const setOrganizationList = (v) => {
    if (_.isEmpty(dataLists)) {
      form.setFieldsValue({ organizations: v })
      seterrorOrganizationList(false)
      return setDataLists(v)
    }
    let resMerge = mergeArrayObjects(dataLists, v)

    resMerge = _.uniqBy(resMerge, 'organizationCode')

    form.setFieldsValue({ organizations: resMerge })
    // check หน่วยงานซ้ำ
    setDataLists(resMerge)
  }

  const formName = 'stepDetail'

  const titleCard = () => {
    if (type_action === 'edit') return 'แก้ไขขั้นตอนย่อย'
    if (type_action === 'view') return 'ดูขั้นตอนย่อย'
    return 'เพิ่มขั้นตอนย่อย'
  }

  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  const getBusinessDays = async () => {
    const { dateStart, manDay, holiday } = form.getFieldValue()

    if (!dateStart || !manDay || _.isNaN(manDay) || !holiday) return null

    const workDay = parseInt(manDay)
    let countDateWork = 0
    let dateEnd = moment(dateStart)
    while (countDateWork < workDay) {
      if (dateEnd.day() !== 0 && dateEnd.day() !== 6) {
        if (!_.isEmpty(holiday)) {
          if (holiday[dateEnd.format('YYYY')]) {
            if (holiday[dateEnd.format('YYYY')].length) {
              let checkHoliday = await holiday[dateEnd.format('YYYY')].find((item) => {
                return moment(item.dateAt).format('YYYY-MM-DD') === dateEnd.format('YYYY-MM-DD')
              })
              if (!checkHoliday) {
                countDateWork++
              }
            } else {
              countDateWork++
            }
          } else {
            countDateWork++
          }
        } else {
          countDateWork++
        }
      }
      dateEnd = dateEnd.add(1, 'days')
    }

    if (countDateWork === workDay) {
      const res = dateEnd.add(-1, 'days').format('YYYY-MM-DD')
      form.setFieldsValue({
        dateEnd: res,
      })
    }
  }

  const validDate = () => {
    let valid = true
    // const { dateStart, dateEnd, projectDateStart, projectDateEnd, manDay, organizations, mainDateStart, mainDateEnd } =
    //   form.getFieldValue()
    const { dateStart, dateEnd, projectDateStart, manDay, organizations, mainDateStart } = form.getFieldValue()

    if (_.isEmpty(organizations)) {
      seterrorOrganizationList(true)
      valid = false
    } else {
      seterrorOrganizationList(false)
    }

    if (_.isNaN(manDay) || !manDay) {
      setErrorField('manDay', t('กรุณาระบุ'))

      valid = false
    } else {
      setErrorField('manDay', false)
    }

    if (!dateStart || !dateEnd) {
      valid = false
    } else {
      const dateStartTemp = moment(dateStart)
      const dateEndTemp = moment(dateEnd)

      if (dateEndTemp < dateStartTemp) {
        setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นน้อยกว่าวันที่สิ้นสุด'))
        setErrorField('dateEnd', t('กรุณาเลือกวันที่สิ้นสุดมากกว่าวันที่เริ่มต้น'))
        valid = false
      }

      // if (dateStart && dateEnd && projectDateEnd && projectDateStart) {
      if (dateStart && dateEnd && projectDateStart) {
        const projectDateStartTemp = moment(projectDateStart)
        // const projectDateEndTemp = moment(projectDateEnd)

        if (dateStartTemp < projectDateStartTemp) {
          setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นหลังวันเริ่มต้นโปรเจค'))
          valid = false
        }
        // if (dateEndTemp > projectDateEndTemp) {
        //   setErrorField('dateEnd', t('กรุณาเลือกวันที่สิ้นสุดก่อนวันสิ้นสุดโปรเจค'))
        //   valid = false
        // }
      }

      // if (dateStart && dateEnd && mainDateEnd && mainDateStart) {
      if (dateStart && dateEnd && mainDateStart) {
        const mainDateStartTemp = moment(mainDateStart)

        // const mainDateEndTemp = moment(mainDateEnd)

        if (dateStartTemp < mainDateStartTemp) {
          setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นหลังวันเริ่มต้นขั้นตอนหลัก'))
          valid = false
        }
        // if (dateEndTemp > mainDateEndTemp) {
        //   setErrorField('dateEnd', t('กรุณาเลือกวันที่สิ้นสุดก่อนวันสิ้นสุดขั้นตอนหลัก'))
        //   valid = false
        // }
      }
    }

    if (!valid) return valid

    setErrorField('dateStart', false)
    setErrorField('dateEnd', false)
    return valid
  }

  const handleRadioOnChange = () => {
    const { isActive } = form.getFieldValue()

    if (isActive === 1) setErrorField('remark', false)
  }

  const renderIconTitle = () => {
    if (type_action === 'view') return 'FileText'
    if (type_action === 'edit') return 'Edit2'
    else return 'Plus'
  }

  // ============================================= หน่วยรับตรวจ =====================================

  if (type_action === 'add' || type_action === 'edit') {
    const [callAddInspection, addInspection] = useLazyQuery(GET_AUDIT_RESULT_INSPEC_TION_PROJECT)

    useEffect(() => {
      const variables = encryptInput({ projectCode: projectCode })

      if (type_action === 'add') {
        callAddInspection({
          variables,
        })
      } else if (type_action === 'edit' && dataGet) {
        const fields = dataGet.getAuditJobStepByStepId

        if (fields?.organizations?.length < 1) {
          callAddInspection({
            variables,
          })
        }
      }
    }, [projectCode, dataGet])

    useEffect(() => {
      if (addInspection.data) {
        const organizationsTemp = addInspection.data.auditResultInspectionProjectGet.map((items, key) => {
          return {
            ...items,
            key,
            organizationName: displayText(items.organizationNameTH, items.organizationNameEN),
            organizationLevelName: displayText(items.organizationLevelNameTH, items.organizationLevelNameEN),
            personnelName: displayText(items.personnelNameTH, items.personnelNameEN),
          }
        })

        form.setFieldsValue({ organizations: organizationsTemp })
        setDataLists(organizationsTemp)
      }
      if (addInspection.error) {
        Alert({
          type: 'error',
          title: t('ไม่สามารถโหลดข้อมูลได้'),
          onOk() {},
          content: t(displayError(addInspection.error)),
        })
      }
    }, [addInspection.data])
  }

  return (
    <React.Fragment>
      {(loadingGet || loadingGetProjectDate || loadingSave) && <SpinnersNew />}
      <BreadcrumbNew data={arrCrumb} title={t('รายละเอียดแผนปฏิบัติงาน')} />
      <CardNew topic={t(titleCard())} icon={renderIconTitle()}>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 9,
            lg: 9,
            xl: 7,
          }}
          wrapperCol={{
            md: 9,
            lg: 9,
          }}
          layout="horizontal"
          name={formName}
        >
          <Row style={{ marginTop: 10 }}>
            <Col {...Responsive}>
              <LabelNew type="body-header">{t('รายละเอียด')}</LabelNew>
            </Col>
          </Row>
          <Form.Item
            name="stepIdRef"
            label={t('ชื่อขั้นตอนหลัก')}
            rules={[{ required: true, message: t('กรุณาระบุ') }]}
          >
            <SelectAuditJobStepByActionPlanId
              disabled={!editable}
              actionPlanId={actionPlanId}
              filters={{
                isActive: 1,
                approveStatusCode: 20,
                projectStatusCode: 10,
              }}
              stepIdRef={idRef}
              formname={formName}
              placeholder={t('เลือกชื่อขั้นตอนหลัก')}
              handleChange={(data) => {
                let dataField = {}
                if (data) {
                  const { source } = data

                  dataField = {
                    stepIdRef: source.stepId,
                    mainDateStart: source.dateStart,
                    mainDateEnd: source.dateEnd,
                  }
                } else {
                  dataField = {
                    stepIdRef: null,
                    mainDateStart: null,
                    mainDateEnd: null,
                  }
                }
                form.setFieldsValue(dataField)
              }}
            />
          </Form.Item>
          <Form.Item
            style={{ marginTop: 10 }}
            name="stepSort"
            label={t('ขั้นตอนที่')}
            rules={[
              { required: true, message: t('กรุณาระบุ') },
              {
                pattern: regexNumber,
                message: t(`กรุณากรอกตัวเลข 0-9`),
              },
            ]}
          >
            <InputNew disabled={!editable} maxLength={10} placeholder={t('ระบุขั้นตอนที่')} />
          </Form.Item>
          <Form.Item name="stepName" label={t('ชื่อขั้นตอนย่อย')} rules={[{ required: true, message: t('กรุณาระบุ') }]}>
            <InputNew disabled={!editable} maxLength={200} placeholder={t('ระบุชื่อขั้นตอนย่อย')} />
          </Form.Item>
          <Form.Item name="stepDetail" label={t('รายละเอียดงาน')}>
            <TextAreaNew disabled={!editable} maxLength={255} placeholder={t('ระบุรายละเอียดงาน')} rows={5} />
          </Form.Item>
          <Form.Item name="auditTeamCode" label={t('ทีมตรวจสอบ')}>
            <SelectAuditTeams filters={{ isActive: 1, auditTeamCode: auditTeamCode }} formname={formName} disabled />
          </Form.Item>
          <Form.Item name="personnels" label={t('ผู้รับผิดชอบ')} rules={[{ required: true, message: t('กรุณาระบุ') }]}>
            <SelectPersonnel
              placeholder={t('เลือกผู้รับผิดชอบ')}
              formname={formName}
              multiple={true}
              filters={{
                personnaloperationCode: 10,
                organizationChecked: 2,
                //  organizationCode: ['ORG0000029', 'ORG0000030']
              }}
              disabled={!editable}
              handleChange={(e) => {
                form.setFieldsValue({ personnels: e })
              }}
            />
          </Form.Item>
          <Form.Item name="manDay" label={t('จำนวนวัน')} rules={[{ required: true, message: t('กรุณาระบุ') }]}>
            <InputNumberNew
              placeholder={t('ระบุจำนวนวันทำงาน')}
              stringMode
              min={0}
              maxLength={3}
              disabled={!editable}
              onChange={() => {
                getBusinessDays()
              }}
            />
          </Form.Item>
          <Form.Item name="budgets" label={t('งบประมาณ')}>
            <InputNumberNew
              min={0}
              step={0.01}
              align="right"
              maxLength={15}
              disabled={!editable}
              placeholder={t('ระบุงบประมาณ')}
              formatter={(value) => moneyFormat(value)}
              // parser={(value) => parserNumber(value)}
            />
          </Form.Item>
          <Form.Item name="dateStart" label={t('วันที่เริ่มต้น')} rules={[{ required: true, message: t('กรุณาระบุ') }]}>
            <Datepicker
              setValueDateFn={(data) => {
                form.setFieldsValue({
                  dateStart: data,
                })
                getBusinessDays()
              }}
              onChange={() => {}}
              disabled={!editable}
              onClear={() => {
                form.setFieldsValue({ dateStart: null })
              }}
              placeholder={t('เลือกวันที่เริ่มต้น')}
            />
          </Form.Item>
          <Form.Item name="dateEnd" label={t('วันที่สิ้นสุด')} rules={[{ required: true, message: t('กรุณาระบุ') }]}>
            <Datepicker
              setValueDateFn={(data) => {
                form.setFieldsValue({
                  dateEnd: data,
                })
              }}
              disabled={!editable}
              onClear={() => {
                form.setFieldsValue({ dateEnd: null })
              }}
              placeholder={t('เลือกวันที่สิ้นสุด')}
            />
          </Form.Item>
          <Form.Item name={'isActive'} label={t('สถานะ')} initialValue={1}>
            <RadioGroup disabled={!editable} onChange={handleRadioOnChange}>
              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                </Col>
                <Col>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.isActive !== currentValues.isActive}
          >
            {({ getFieldValue }) => {
              return getFieldValue('isActive') === 0 ? (
                <Form.Item name="remark" label={t('หมายเหตุ')} rules={[{ required: true, message: t('กรุณาระบุ') }]}>
                  <TextAreaNew disabled={!editable} maxLength={255} placeholder={t('กรอกหมายเหตุ')} rows={5} />
                </Form.Item>
              ) : (
                <Form.Item name="remark" label={t('หมายเหตุ')}>
                  <TextAreaNew disabled={!editable} maxLength={255} placeholder={t('กรอกหมายเหตุ')} rows={5} />
                </Form.Item>
              )
            }}
          </Form.Item>
        </Form>
        <Row gutter={[16, 16]}>
          <Col>
            <Datatable
              columns={Columns({ fncOnpressDelete, menu, editable })}
              data={dataLists}
              pagination={paginate}
              setLimit={1}
              scroll={{ y: 350 }}
              searchCustom={true}
              handleTableChange={(value) => {
                const { pagination } = value
                if (pagination) {
                  setPaginate({ page: pagination.current, pageSize: pagination.pageSize })
                }
              }}
              btnAdd={
                <>
                  {editable && (
                    <ButtonNew
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: menu }}
                      onClick={() => {
                        setModal(true)
                      }}
                    >
                      {t('เพิ่มหน่วยรับตรวจ')}
                    </ButtonNew>
                  )}

                  <Space size={[8, 8]} wrap>
                    &nbsp;
                  </Space>
                  {errorOrganizationList ? (
                    <span style={{ color: '#ea5456', alignSelf: 'center', marginLeft: 5 }}>
                      {t('กรุณาระบุหน่วยรับตรวจอย่างน้อย 1 รายการ')}
                    </span>
                  ) : null}
                </>
              }
            />
          </Col>

          <Col {...Responsive} align="center">
            <Space size={[8, 8]} wrap>
              {type_action !== 'view' && (
                <ButtonNew
                  type="primary"
                  roles={{ type: 'add', menu: menu }}
                  onClick={() => {
                    form.submit()
                  }}
                >
                  {t('บันทึก')}
                </ButtonNew>
              )}
              &nbsp;
              <ButtonNew
                type="back"
                onClick={() => {
                  navigate('/auditoperations/actionplan/form', {
                    state: {
                      refCode: actionPlanId,
                      type_action: typeActionMain,
                      // type_action: type_action,
                      defaultActiveKey: '2',
                      auditTeamCode: auditTeamCode,
                      projectCode: projectCode,
                    },
                  })
                }}
              >
                {t('ยกเลิก')}
              </ButtonNew>
            </Space>
          </Col>
        </Row>
      </CardNew>
      {modal && (
        <CheckerOrganizationModal
          open={modal}
          close={(e) => setModal(e)}
          getData={setOrganizationList}
          orgActive={dataLists}
        />
      )}
    </React.Fragment>
  )
}

export default ActionPlanFormStepDetail
