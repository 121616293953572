import { gql } from '@apollo/client'

export const GET_LOGFILE_ORGANIZATIONS_PAGINATION = gql`
  query LogfileOrganizationsPaginationGet($input: LogfileOrganizationsPaginationInput!) {
    logfileOrganizationsPaginationGet(input: $input) {
      result {
        logfileOrgId
        userId
        menuCode
        organizationCode
        organizationNameTH
        organizationNameEN
        changeType
        createdAt
        createdBy
        remark
        files
        username
        email
      }
      count
      page
      limit
    }
  }
`
