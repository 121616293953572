import { Card, Col, Row } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Datatable } from '../../../../components/datatable/Datatable'

import { Columns } from './utils/Constants'
import { DataTableStyled } from './css/DataTableStyled'

import { handleSort } from '../../../../utilitys/pagination'
import { useGetPagination } from './hooks/useGetPagination'
import { SpinnersSmaillNew } from '../../../../components/spinners/Spinners'
import { displayText } from '../../../../utilitys/helper'
import { CalLevel } from './utils/Helper'

const Responsive = {
  md: 24,
  lg: 24,
}

const RiskAuditUniverseList = (props) => {
  const { dataFilter } = props

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])

  const inputOptions = useMemo(() => {
    return { filters: { ...dataFilter }, page, limit, sort }
  }, [dataFilter, page, limit, sort])

  //========================== API PAGINATION ======================================
  const [dataList, listLoading] = useGetPagination(inputOptions)

  useEffect(() => {
    if (dataList) {
      const { result, count, resRiskLevel } = dataList

      const newData = result.map((dt, idx) => {
        // แปลง array to objects
        const detail = dt.detail.map((data) => {
          const TotalScore = data?.TotalScore

          return {
            [data.riskFactorNameTH.replace(/ /g, '')]: TotalScore ? TotalScore : 0,
            weight: data.weight,
          }
        })

        const convertDetail = {}

        // คะแนนถ่วงน้ำหนัก
        let scoreWeight = 0

        detail.forEach((item) => {
          const key = Object.keys(item)[0]
          const value = item[key]
          convertDetail[key] = value

          if (dt?.isWeight === 1 && item.weight !== null && item.weight !== 0) {
            // การคำนวนคะแนนถ่วงน้ำหนัก
            const calValue = parseFloat(value) * ((item?.weight ? item?.weight : 0) / 100)
            scoreWeight += calValue
          }
        })

        let riskLevel = ''
        const riskLevelCode = CalLevel(scoreWeight)

        if (resRiskLevel) {
          const code = resRiskLevel?.filter((item) => item.riskLevelCode === riskLevelCode)

          if (code) {
            riskLevel = displayText(code[0]?.riskLevelNameTH, code[0]?.riskLevelNameTH)
          }
        }

        return {
          ...dt,
          key: idx,
          riskModelName: dt?.riskModelCode + ': ' + displayText(dt?.riskModelTH, dt?.riskModelEN),
          ...convertDetail,
          scoreWeight: parseFloat(scoreWeight).toFixed(2),
          riskLevel: riskLevel,
        }
      })
      setDataLists(newData)
      setCount(count)
    }
  }, [dataList])

  return (
    <DataTableStyled>
      <Card className="">
        <Row gutter={[16, 8]}>
          <Col {...Responsive}>
            {listLoading && <SpinnersSmaillNew />}
            <Datatable
              columns={Columns({})}
              data={dataLists}
              total={count}
              searchCustom={false}
              paginationCustom={true}
              scroll={{ x: 2000 }}
              enableScroll={{ x: true, y: false }}
              pageSize={limit}
              page={page}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              showSizeChanger={true}
              handleTableChange={(e) => {
                const reNewField = []

                setSort(handleSort(e?.sorter, reNewField))
              }}
            />
          </Col>
        </Row>
      </Card>
    </DataTableStyled>
  )
}

export default RiskAuditUniverseList
