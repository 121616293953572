import React, { useState, useEffect } from 'react'
import { Row, Col, Space, Table } from 'antd'
import * as _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { ButtonNew } from '../../../../components/button/Button'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'

// import RiskFormFirstStepModal from '../modal/RiskFormFirstStepModal'
const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}
const RiskformSecondStepTabs2 = ({
  form,
  riskFormOrganizationList,
  setRiskFormOrganizationList,
  disabled,
  menu,
  setModal,
}) => {
  const { t } = useTranslation()

  const [errorOrganizationList, setErrorOrganizationList] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      organizationList: JSON.stringify([...riskFormOrganizationList]),
    })
  }, [riskFormOrganizationList])

  const Columns = [
    {
      title: t('ลำดับ'),
      dataIndex: 'key',
      width: '10%',
    },
    {
      title: t('รหัสหน่วยงาน'),
      dataIndex: 'organizationCode',
    },
    {
      title: t('หน่วยงาน'),
      dataIndex: 'organizationName',
    },
    {
      title: t('ระดับหน่วยงาน'),
      dataIndex: 'organizationLevelName',
    },
    {
      title: t('ผู้รับผิดชอบ'),
      dataIndex: 'personnelName',
    },
    {
      title: '',
      dataIndex: 'deleteId',
      width: '20%',
      render: (row) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {disabled ? (
              <></>
            ) : (
              <ButtonGroup
                menu={menu}
                icons={[
                  {
                    type: 'delete',
                    onClick: () => {
                      if (riskFormOrganizationList.length > 1)
                        return setRiskFormOrganizationList([
                          ..._.filter(riskFormOrganizationList, (e) => e.deleteId !== row),
                        ])
                      if (riskFormOrganizationList.length === 1) {
                        setErrorOrganizationList(true)
                        setTimeout(() => setErrorOrganizationList(false), 4500)
                      }
                    },
                  },
                ]}
              />
            )}
          </div>
        )
      },
    },
  ]

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col {...Responsive}>
          {disabled ? (
            <></>
          ) : (
            <Space size={[8, 8]} wrap>
              <ButtonNew
                type="plusTableBorderPrimary"
                roles={{ type: 'edit', menu: menu }}
                onClick={() => {
                  setModal(true)
                }}
              >
                {t('หน่วยงานรับตรวจ')}
              </ButtonNew>
            </Space>
          )}
          &nbsp;
          {errorOrganizationList && (
            <span style={{ color: '#ea5456' }}>{t(' กรุณาระบุหน่วยรับตรวจอย่างน้อย 1 รายการ')}</span>
          )}
        </Col>
        <Col {...Responsive}>
          <Table
            columns={Columns}
            dataSource={[...riskFormOrganizationList]}
            size="middle"
            pagination={true}
            scroll={{ x: '100%', y: 350 }}
          />
        </Col>
      </Row>
    </>
  )
}

export default RiskformSecondStepTabs2
