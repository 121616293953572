import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Space } from 'antd'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Select } from '../../../../components/select/Select'
import { ButtonNew } from '../../../../components/button/Button'
import SelectOperationStatus from '../../../../components/inputfromapi/selectoperationstatus/SelectOperationStatus'
// import SelectOrganization from '../../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectYear from '../../../../components/inputfromapi/selectyear/SelectYear'
import { decryptData } from '../../../../utilitys/crypto'
import { CardNew } from '../../../../components/card/Card'
import { encryptInput } from '../../../../utilitys/crypto'
import { LabelNew } from '../../../../components/label/Label'
import { InputNew } from '../../../../components/input/Input'
import { Alert } from '../../../../components/alert/Alert'
import { displayError } from '../../../../utilitys/helper'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { GET_LIST_FILTER_ASSESS_RISK } from '../graphql/Query'
const RiskListFilter = (props) => {
  const { limit, page, getCount, getValue, reFetch, sort, menu } = props
  const { pCode, orgCode } = decryptData(localStorage.getItem('user'))
  const { t } = useTranslation()
  const isLang = localStorage.getItem('lang')
  const [form] = Form.useForm()
  const formName = 'filter'
  const Responsive = {
    md: 8,
    lg: 8,
    btn: {
      md: 24,
      lg: 24,
    },
  }

  const [reset, setReset] = useState(false)
  const [filters, setFilters] = useState({ lang: isLang })

  const [getListFilterAssessRiskFn, getListFilterAssessRisk] = useLazyQuery(GET_LIST_FILTER_ASSESS_RISK)
  useEffect(() => {
    if (isLang) {
      getListFilterAssessRiskFn({
        variables: {
          input: {
            filters: encryptInput(filters),
            constFilter: encryptInput({
              personnelCode: pCode,
              organizationCode: orgCode,
            }),
            pagination: { limit, page },
            sort: sort,
          },
        },
      })
    }
  }, [page, limit, isLang, reset, reFetch, sort])

  useEffect(() => {
    if (getListFilterAssessRisk?.data) {
      const res_ = getListFilterAssessRisk?.data?.getListFilterAssessRisk
      getCount(res_.count)
      getValue(res_.result)
    }
    if (getListFilterAssessRisk.error) {
      const err = getListFilterAssessRisk.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [getListFilterAssessRisk?.data])

  useEffect(() => {
    onFinish({})
  }, [])

  const onFinish = async (value) => {
    const localFilter = {
      Year: value?.Year || '',
      riskFormType: value?.riskFormType || '',
      oganizationAudit: value?.oganizationAudit || '',
      operationStatusCode: value?.operationStatusCode ? String(value?.operationStatusCode) : '' || '',
      organizationName: value?.organizationName || '',
      riskFormTH: value?.riskFormTH || '',
      lang: isLang,
    }
    await getListFilterAssessRiskFn({
      variables: {
        input: {
          filters: encryptInput(localFilter),
          constFilter: encryptInput({
            personnelCode: pCode,
            organizationCode: orgCode,
          }),
          pagination: { limit, page },
          sort: [
            {
              fieldSort: 'createdAt',
              sortType: 'DESC',
            },
          ],
        },
      },
    })
    setFilters({
      ...filters,
      ...localFilter,
    })
  }
  return (
    <>
      {getListFilterAssessRisk.loading && <SpinnersNew />}
      <Row style={{ marginBottom: 24 }}>
        <Col sm={24} md={24} lg={24}>
          <CardNew topic={t('Filters')} icon="List" toggledrop={'true'}>
            <Form form={form} onFinish={onFinish} name={formName} layout="vertical">
              <Row gutter={[12, 12]}>
                <Col {...Responsive}>
                  <Form.Item name={'Year'} label={<LabelNew type="tab-header-inactive">{t('ประจำปี')}</LabelNew>}>
                    <SelectYear
                      formname={formName}
                      placeholder={t('ระบุปี')}
                      handleChange={(v) => {
                        form.setFieldsValue({
                          Year: v.value,
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'riskFormType'}
                    label={<LabelNew type="tab-header-inactive">{t('ประเภทการประเมิน')}</LabelNew>}
                  >
                    <Select
                      title=""
                      data={[
                        { value: 1, label: t('ประเมินสรุป') },
                        { value: 2, label: t('ประเมินรายหน่วยงาน') },
                      ]}
                      placeholder={t('ระบุประเภทการประเมิน')}
                      scrollableId={formName}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'riskFormTH'}
                    label={<LabelNew type="tab-header-inactive">{t('ชื่อแบบประเมิน')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุชื่อแบบประเมิน')} />
                  </Form.Item>
                </Col>
                {/* <Col {...Responsive}>
                  <Form.Item
                    name="oganizationAudit"
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยตรวจสอบ')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุหน่วยตรวจสอบ') }]}
                  >
                    <SelectOrganization
                      placeholder={t('ระบุหน่วยตรวจสอบ')}
                      formname={formName}
                      handleChange={(v) => {
                        form.setFieldsValue({
                          oganizationAudit: v.value,
                        })
                      }}
                      filters={{ organizationChecked: 2, isActive: 1 }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'organizationName'}
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยรับตรวจ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุหน่วยรับตรวจ')} />
                  </Form.Item>
                </Col> */}
                <Col {...Responsive}>
                  <Form.Item
                    name={'operationStatusCode'}
                    label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                  >
                    <SelectOperationStatus
                      filters={{ operationStatusType: 2 }}
                      needOperationStatusCode={true}
                      placeholder={t('ระบุสถานะ')}
                      formname={formName}
                      handleChange={(v) => {
                        form.setFieldsValue({
                          operationStatusCode: v.value,
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col {...Responsive.btn} align="center">
                  <Space size={[8, 8]} wrap>
                    <ButtonNew type="search" roles={{ type: 'view', menu: menu }} htmlType="submit">
                      {t('ค้นหา')}
                    </ButtonNew>
                    &nbsp;
                    <ButtonNew
                      type="clear"
                      onClick={() => {
                        form.resetFields()
                        setFilters({ lang: isLang })
                        setReset(reset ? false : true)
                      }}
                    >
                      {t('ล้างค่า')}
                    </ButtonNew>
                  </Space>
                </Col>
              </Row>
            </Form>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default RiskListFilter
