export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const monthsTH = [
  'มกราคม',
  'กุมภาพันธ์',
  'มีนาคม',
  'เมษายน',
  'พฤษภาคม',
  'มิถุนายน',
  'กรกฎาคม',
  'สิงหาคม',
  'กันยายน',
  'ตุลาคม',
  'พฤศจิกายน',
  'ธันวาคม',
]

export const shortNameDays = {
  อาทิตย์: 'Su',
  จันทร์: 'Mo',
  อังคาร: 'Tu',
  พุธ: 'We',
  พฤหัสบดี: 'Th',
  ศุกร์: 'Fr',
  เสาร์: 'Sa',
  Sunday: 'Su',
  Monday: 'Mo',
  Tuesday: 'Tu',
  Wednesday: 'We',
  Thursday: 'Th',
  Friday: 'Fr',
  Saturday: 'Sa',
}

export const shortNameTHDays = {
  อาทิตย์: 'Su',
  จันทร์: 'Mo',
  อังคาร: 'Tu',
  พุธ: 'We',
  พฤหัสบดี: 'Th',
  ศุกร์: 'Fr',
  เสาร์: 'Sa',
  Sunday: 'อา',
  Monday: 'จ',
  Tuesday: 'อ',
  Wednesday: 'พ',
  Thursday: 'พฤ',
  Friday: 'ศ',
  Saturday: 'ส',
}
