import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { CardNew } from '../../../components/card/Card'
import { ButtonNew } from '../../../components/button/Button'
import { Datatable } from '../../../components/datatable/Datatable'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { LabelNew as Label } from '../../../components/label/Label'
import { InputNew } from '../../../components/input/Input'
import { TextAreaNew } from '../../../components/textarea/TextArea'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Columns } from './utils/ApproveAdhocJobPersonnelColumns'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectJobTypes from '../../../components/inputfromapi/selectjobtypes/SelectJobTypes'
import SelectApproveStatus from '../../../components/inputfromapi/selectapprovestatus/SelectApproveStatus'
import SelectPersonnels from '../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import {
  GET_APPROVE_ADHOCJOB_BY_CODE,
  GET_APPROVE_ADHOCJOB_FILES,
  GET_APPROVE_ADHOCJOB_PERSONNELS_LIST,
} from './graphql/Query'
import { APPROVE_ADHOCJOB } from './graphql/Mutation'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText, displayError } from '../../../utilitys/helper'
import Files from './components/Files'

const ApproveAdhocJobForm = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { state } = useLocation()

  if (!state) {
    return <Navigate to={'/auditoperations/approveadhocjob'} />
  }

  const { refCode } = state
  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/approveadhocjob' },
    { label: t('อนุมัติการมอบหมายงาน'), path: '/auditoperations/approveadhocjob' },
  ]

  const Responsive = {
    md: 18,
    lg: 18,
  }
  const Responsive2 = {
    md: 24,
    lg: 24,
  }

  const [remarkRequired, setRemarkRequired] = useState(false)
  const [getFiles, setFiles] = useState([])
  const [dataLists, setDataLists] = useState([])

  /* ---------------- API ----------- */
  const [getDataFn, { loading: loadingDataAdhocJobByCode, error: errorDataAdhocJobByCode, data: dataAdhocJobByCode }] =
    useLazyQuery(GET_APPROVE_ADHOCJOB_BY_CODE)

  const [approveFn, { loading: loadingApprove, error: errorApprove, data: saveApprove }] = useMutation(APPROVE_ADHOCJOB)

  const [getFilesFn, { loading: loadingDataFiles, error: errorDataFiles, data: dataFiles }] =
    useLazyQuery(GET_APPROVE_ADHOCJOB_FILES)

  const [getPersonnelFn, { loading: loadingPersonnelList, error: errorPersonnelList, data: dataPersonnelList }] =
    useLazyQuery(GET_APPROVE_ADHOCJOB_PERSONNELS_LIST)

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    showConfirm(input)
  }

  const showConfirm = (input) => {
    let dataInput = {
      jobCode: input.jobCode || '',
      approveStatusCode: input.approveStatusCode || '',
      jobRemarkTH: (localStorage.getItem('lang') === 'th' ? input.jobRemark : input.jobRemarkTH) || '',
      jobRemarkEN: (localStorage.getItem('lang') === 'en' ? input.jobRemark : input.jobRemarkEN) || '',
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        approveFn({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const showErrorAlert = (item) => {
    Alert({
      type: 'error',
      title: t(item.title),
      content: t(displayError(item.content)),
      onOk() {},
    })
  }

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (refCode) {
      getDataFn({
        variables: {
          jobCode: encryptInput(refCode),
        },
      })

      getFilesFn({
        variables: {
          jobCode: encryptInput(refCode),
        },
      })

      getPersonnelFn({
        variables: {
          jobCode: encryptInput(refCode),
        },
      })
    }
  }, [refCode])

  useEffect(() => {
    if (dataAdhocJobByCode) {
      const data = dataAdhocJobByCode.getAdhocJobByCode
      form.setFieldsValue({
        jobCode: data.jobCode,
        jobNumber: data.jobNumber,
        jobTypeCode: data.jobTypeCode,
        jobSubject: displayText(data.jobSubjectTH, data.jobSubjectEN) || '',
        jobSubjectTH: data.jobSubjectTH || '',
        jobSubjectEN: data.jobSubjectEN || '',
        jobDetail: displayText(data.jobDetailTH, data.jobDetailEN) || '',
        jobDetailTH: data.jobDetailTH || '',
        jobDetailEN: data.jobDetailEN || '',
        responsibleOrganization: data.responsibleOrganization,
        requestOrganization: data.requestOrganization,
        requestPersonnel: data.requestPersonnel,
        telephone: data.telephone,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        approvePersonnel: data.approvePersonnel,
        jobRemark: displayText(data.jobRemarkTH, data.jobRemarkEN),
        jobRemarkTH: data.jobRemarkTH || data.jobRemarkEN,
        jobRemarkEN: data.jobRemarkEN || data.jobRemarkTH,
      })

      if (data.approveStatusCode !== '10') {
        form.setFieldsValue({ approveStatusCode: data.approveStatusCode })
        setRemarkRequired(data.approveStatusCode === '30' ? true : false)
      }
    }

    if (errorDataAdhocJobByCode) {
      showErrorAlert({
        title: 'ไม่สามารถดึงข้อมูลได้',
        content: errorDataAdhocJobByCode,
      })
    }
  }, [dataAdhocJobByCode])

  useEffect(() => {
    if (dataFiles) {
      const data = dataFiles.getAdhocJobFiles
      setFiles(
        data.map((item, key) => {
          return <Files key={key} item={item} index={key} />
        }),
      )
    }

    if (errorDataFiles) {
      showErrorAlert({
        title: 'ไม่สามารถดึงข้อมูลได้',
        content: errorDataFiles,
      })
    }
  }, [dataFiles])

  useEffect(() => {
    if (dataPersonnelList) {
      let result = dataPersonnelList.getAdhocJobPersonnelByCode

      setDataLists(
        _.map(result, (data, index) => {
          return {
            key: index + 1,
            personnelCode: data.personnelCode,
            personnelName: displayText(data.personnelNameTH, data.personnelNameEN),
            organization: displayText(data.organizationNameTH, data.organizationNameEN),
            position: displayText(data.positionNameTH, data.positionNameEN),
          }
        }),
      )
    }

    if (errorPersonnelList) {
      showErrorAlert({
        title: 'ไม่สามารถดึงข้อมูลได้',
        content: errorPersonnelList,
      })
    }
  }, [dataPersonnelList])

  /* APPROVE */
  useEffect(() => {
    if (saveApprove) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/auditoperations/approveadhocjob')
        },
      })
    }

    if (errorApprove) {
      showErrorAlert({
        title: 'ไม่สามารถบันทึกข้อมูลได้',
        content: errorApprove,
      })
    }
  }, [saveApprove])

  return (
    <>
      {(loadingPersonnelList || loadingDataFiles || loadingDataAdhocJobByCode || loadingApprove) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('อนุมัติการมอบหมายงาน')} />
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ md: { span: 8 }, lg: { span: 5 } }}
        wrapperCol={{ md: { span: 16 }, lg: { span: 12 } }}
        name="approveForm"
      >
        <CardNew topic={t('รายละเอียดงานมอบหมาย')} icon={'AlertCircle'} toggledrop="false">
          <Row gutter={[12, 12]} justify="center">
            <Col {...Responsive}>
              <Row style={{ margin: '10px 0px' }}>
                <Col {...Responsive2}>
                  <Label type="sub-header-primary">{t('รายละเอียด')}</Label>
                </Col>
              </Row>
              <Row justify="center">
                <Col {...Responsive}>
                  <Form.Item
                    name="jobCode"
                    label={<Label type="tab-header-inactive">{t('รหัสงาน')}</Label>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <InputNew disabled />
                  </Form.Item>
                  <Form.Item
                    name="jobTypeCode"
                    label={<Label type="tab-header-inactive">{t('ประเภทงาน')}</Label>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาเลือกประเภทงาน'),
                      },
                    ]}
                  >
                    <SelectJobTypes
                      placeholder={t('เลือกประเภทงาน')}
                      formname={'approveForm'}
                      filters={{ jobflag: 1, isActive: 1 }}
                      handleChange={(e) => form.setFieldsValue({ jobTypeCode: e?.value })}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name="jobNumber"
                    label={<Label type="tab-header-inactive">{t('เลขที่งาน')}</Label>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <InputNew placeholder={t('เลขที่งานมอบหมาย')} disabled />
                  </Form.Item>
                  <Form.Item
                    name="jobSubject"
                    label={<Label type="tab-header-inactive">{t('งาน(ชื่องาน)')}</Label>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <InputNew placeholder={t('ระบุชื่องาน')} disabled maxLength={8000} />
                  </Form.Item>
                  <Form.Item
                    name="responsibleOrganization"
                    label={<Label type="tab-header-inactive">{t('หน่วยงานรับผิดชอบ')}</Label>}
                  >
                    <SelectOrganization
                      placeholder={t('เลือกหน่วยงาน')}
                      formname={'approveForm'}
                      filters={{ /* organizationChecked: 1, */ isActive: 1 }}
                      handleChange={(e) => form.setFieldsValue({ responsibleOrganization: e?.value })}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name="requestOrganization"
                    label={<Label type="tab-header-inactive">{t('หน่วยงานที่ร้องขอ')}</Label>}
                  >
                    <SelectOrganization
                      placeholder={t('เลือกหน่วยงาน')}
                      formname={'approveForm'}
                      filters={{ /* organizationChecked: 1, */ isActive: 1 }}
                      handleChange={(e) => form.setFieldsValue({ requestOrganization: e?.value })}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item name="requestPersonnel" label={<Label type="tab-header-inactive">{t('ผู้ร้องขอ')}</Label>}>
                    <InputNew disabled />
                  </Form.Item>
                  <Form.Item name="telephone" label={<Label type="tab-header-inactive">{t('เบอร์ติดต่อ')}</Label>}>
                    <InputNew disabled />
                  </Form.Item>
                  <Form.Item name="jobDetail" label={<Label type="tab-header-inactive">{t('รายละเอียดงาน')}</Label>}>
                    <TextAreaNew placeholder={t('รายละเอียดงาน')} autoSize={{ minRows: 4, maxRows: 4 }} disabled />
                  </Form.Item>
                  <Form.Item
                    name="approvePersonnel"
                    label={<Label type="tab-header-inactive">{t('ผู้อนุมัติ')}</Label>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาเลือกผู้อนุมัติ'),
                      },
                    ]}
                  >
                    <SelectPersonnels
                      placeholder={t('ผู้อนุมัติ')}
                      formname={'approveForm'}
                      filters={{ levelCode: 8 }}
                      disabled
                      handleChange={(e) => form.setFieldsValue({ approvePersonnel: e?.value })}
                    />
                  </Form.Item>
                  <Form.Item name="dateStart" label={<Label type="tab-header-inactive">{t('วันที่เริ่มต้น')}</Label>}>
                    <Datepicker
                      setValueDateFn={async (data) => form.setFieldsValue({ dateStart: await data })}
                      onClear={() => {
                        form.setFieldsValue({ dateStart: null })
                      }}
                      placeholder={t('DD/MM/YYYY')}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item name="dateEnd" label={<Label type="tab-header-inactive">{t('วันที่สิ้นสุด')}</Label>}>
                    <Datepicker
                      setValueDateFn={async (data) => form.setFieldsValue({ dateEnd: await data })}
                      onClear={() => {
                        form.setFieldsValue({ dateEnd: null })
                      }}
                      placeholder={t('DD/MM/YYYY')}
                      disabled
                    />
                  </Form.Item>
                  <Form.Item
                    name="approveStatusCode"
                    label={<Label type="tab-header-inactive">{t('สถานะอนุมัติ')}</Label>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <SelectApproveStatus
                      placeholder={t('สถานะอนุมัติ')}
                      formname={'approveForm'}
                      filters={{ isActive: 1, approveStatusCode: 'IN(20,30)' }}
                      onChange={(value) => setRemarkRequired(value === '30' ? true : false)}
                      handleChange={(e) => {
                        form.setFieldsValue({ approveStatusCode: e?.value }),
                          setRemarkRequired(e.value === '30' ? true : false)
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="jobRemark"
                    label={<Label type="tab-header-inactive">{t('เหตุผลการไม่อนุมัติ')}</Label>}
                    rules={[
                      {
                        required: remarkRequired,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <TextAreaNew
                      placeholder={t('เหตุผลการไม่อนุมัติ')}
                      autoSize={{ minRows: 4, maxRows: 4 }}
                      maxLength={200}
                    />
                  </Form.Item>
                  <Form.Item name="jobRemarkTH" hidden={true}>
                    <InputNew />
                  </Form.Item>
                  <Form.Item name="jobRemarkEN" hidden={true}>
                    <InputNew />
                  </Form.Item>
                  <Form.Item label={<Label type="tab-header-inactive">{t('เอกสารเพิ่มเติม')}</Label>}>
                    <Col md={20} style={{ display: 'flex' }}>
                      {getFiles}
                    </Col>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardNew>

        <CardNew topic={t('รายการบุคลากร')} icon="List" toggledrop={'false'}>
          <Row gutter={[12, 12]}>
            <Col {...Responsive2}>
              <Datatable columns={Columns} data={[...dataLists]} scroll={{ x: '100%', y: false }} searchCustom={true} />
            </Col>
          </Row>
          <Row gutter={[12, 20]} justify="center" style={{ marginTop: '15px' }}>
            <Col>
              <ButtonNew type="primary" roles={{ type: 'approve', menu: menu }} htmlType="submit">
                {t('บันทึก')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew type="back" onClick={() => navigate('/auditoperations/approveadhocjob')}>
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          </Row>
        </CardNew>
      </Form>
    </>
  )
}

export default ApproveAdhocJobForm
