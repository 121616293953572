import React, { useState, useEffect } from 'react'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { breadcrumbList } from './utils/breadcrumbData.js'
import { columnCalendarList } from './utils/columnData'
import { useTranslation } from 'react-i18next'
import { CardNew } from '../../../components/card/Card'
import { Row, Col, Space } from 'antd'
import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonNew } from '../../../components/button/Button'
import { handleSort } from '../../../utilitys/pagination'
import { yearDisplay } from '../../../utilitys/dateHelper'
import { Status } from '../../../components/status/Status'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GETMASTERCALENDARS } from './graphql/Query'
import { MASTERCALENDARDELETE } from './graphql/Mutation'
import { displayText } from '../../../utilitys/helper'
import * as _ from 'lodash'
import { encryptInput } from '../../../utilitys/crypto'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { useLanguage } from '../../../hooks/useLanguage'
import { displayError } from '../../../utilitys/helper'

export default function CalendarSetList(props) {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const Responsive = {
    md: 24,
    lg: 24,
  }
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  const [dataList, setDataList] = useState([])

  const [getMasterCalendarsfn, getMasterCalendars] = useLazyQuery(GETMASTERCALENDARS)
  const [masterCalendarDeleteFn, { loading: loadingDelete, error, data: dataDelete }] =
    useMutation(MASTERCALENDARDELETE)

  /* ---------------- delete on api ----------- */
  const calendarDelete = (dataCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        masterCalendarDeleteFn({
          variables: {
            input: encryptInput({
              calendarCode: dataCode,
            }),
          },
        })
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    getMasterCalendarsfn({
      variables: {
        inputData: {
          filters: {},
          pagination: { limit, page },
          sort: sort,
        },
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (getMasterCalendars.data) {
      let response = getMasterCalendars?.data?.getMasterCalendars
      setCount(response?.count)
      setDataList(
        _.map(response?.result, (dt, keys) => {
          return {
            key: keys,
            calendarCode: dt.calendarCode,
            calendarYear: yearDisplay(String(dt.calendarYear)),
            calendarName: displayText(dt.calendarNameTH, dt.calendarNameEN),
            isActive: <Status key={'status-apl-1'} text={dt.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}></Status>,
            options: (
              <div key={'div-view-templaterick' + 1} style={{ textAlign: 'left' }}>
                <ButtonGroup
                  key={'btn-view-templaterick' + 1}
                  menu={menu}
                  icons={[
                    {
                      type: 'copy',
                      onClick: () => {
                        navigate('/administrator/managecalendar/form/copy', {
                          state: {
                            refCode: dt.calendarCode,
                          },
                        })
                      },
                    },
                    {
                      type: 'view',
                      onClick: () => {
                        navigate('/administrator/managecalendar/form/view', {
                          state: {
                            refCode: dt.calendarCode,
                          },
                        })
                      },
                    },
                    {
                      type: 'edit',
                      onClick: () => {
                        navigate('/administrator/managecalendar/form/edit', {
                          state: {
                            refCode: dt.calendarCode,
                          },
                        })
                      },
                    },
                    {
                      type: 'delete',
                      onClick: () => {
                        calendarDelete(dt.calendarCode)
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (getMasterCalendars.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getMasterCalendars.error)),
        onOk() {},
      })
    }
  }, [getMasterCalendars.data])

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          getMasterCalendars.refetch()
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [dataDelete])

  return (
    <>
      {getMasterCalendars.loading && <SpinnersNew />}
      {loadingDelete && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('จัดการข้อมูลปฏิทิน')} />
      <Row gutter={[24, 24]}>
        <Col {...Responsive}>
          <CardNew topic={t('รายการปฎิทินประจำปี')} icon="AlignJustify" toggledrop={'false'}>
            <Row gutter={[16, 16]}>
              <Col {...Responsive}>
                <Datatable
                  columns={columnCalendarList}
                  data={dataList}
                  total={count}
                  isScoll={{ x: true, y: true }}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  searchCustom={true}
                  pageSize={limit}
                  page={page}
                  handleTableChange={(e) => {
                    const lang = isLang === 'th' ? true : false
                    const reNewField = [{ calendarName: lang ? 'calendarNameTH' : 'calendarNameEN' }]
                    setSort(handleSort(e?.sorter, reNewField))
                  }}
                  btnAdd={
                    <>
                      <Space size={[8, 8]} wrap>
                        <ButtonNew
                          type="plusTableBorderPrimary"
                          roles={{ type: 'add', menu: menu }}
                          onClick={() => {
                            navigate('/administrator/managecalendar/form/create')
                          }}
                        >
                          {t('เพิ่มวัน')}
                        </ButtonNew>
                        <ButtonNew
                          type="primary"
                          width={141}
                          roles={{ type: 'add', menu: menu }}
                          onClick={() => {
                            navigate('/administrator/managecalendar/import')
                          }}
                        >
                          {t('นำเข้าข้อมูล')}
                        </ButtonNew>
                      </Space>
                    </>
                  }
                />
              </Col>
            </Row>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}
