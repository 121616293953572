import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col, Space, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_AUDIT_RESULT_CONTROL, GET_AUDIT_RCM_RESULT_ITEM } from '../../graphql/Query'
import { CREATE_UPDATE_AUDIT_RCM_RESULT_CONTROL } from '../../graphql/Mutation'
import { ModalNew } from '../../../../../components/modal/Modal'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { ButtonNew } from '../../../../../components/button/Button'
import { InputNew } from '../../../../../components/input/Input'
import { Select } from '../../../../../components/select/Select'
import { Alert } from '../../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText, displayError } from '../../../../../utilitys/helper'
import SelectRateRiskFactorProcessModel from '../../../../../components/inputfromapi/selectrateriskfactorprocessmodel/SelectRateRiskFactorProcessModel'
import SelectRateRiskRuleModel from '../../../../../components/inputfromapi/selectrateriskrulemodel/SelectRateRiskRuleModel'
import SelectRateRiskControl from '../../../../../components/inputfromapi/selectrateriskcontrol/SelectRateRiskControl'

const ControlFormModal = (props) => {
  const formname = 'ControlFormModalForm'
  const labelCol = { sm: 14, md: 6, lg: 6, xl: 6 }
  const wrapperCol = { sm: 16, md: 14, lg: 14, xl: 14 }
  const wrapper = { sm: 24, md: 22, lg: 22 }

  const { menu, open, close, action, rcmControlId, resultModal } = props

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { state } = useLocation()

  const [visible, setVisible] = useState(false)
  const [disabled, setDisable] = useState(false)
  const [isRateFacProCode, setIsRateFacProCode] = useState(null)
  const [isRateRiskRuleCode, setIsRateRiskRuleCode] = useState(null)
  const [rateFacGroupCode, setRateFacGroupCode] = useState(null)
  const [riskScore, setRiskScore] = useState(null)
  const [scoreRiskBalance, setScoreRiskBalance] = useState('')
  const [rcmResultId, setRcmResultId] = useState(null)

  const [getAuditRCMResultsItemFn, getAuditRCMResultItem] = useLazyQuery(GET_AUDIT_RCM_RESULT_ITEM)
  const [getAuditResultControlFn, getAuditResultControl] = useLazyQuery(GET_AUDIT_RESULT_CONTROL)
  const [createUpdateAuditRCMResultControlFn, createUpdateAuditRCMResultControl] = useMutation(
    CREATE_UPDATE_AUDIT_RCM_RESULT_CONTROL,
  )

  useEffect(() => {
    if ((action === 'edit' || action === 'view') && rcmControlId) {
      getAuditResultControlFn({ variables: { input: encryptInput({ rcmControlId: rcmControlId }) } })
    }
  }, [action, rcmControlId])

  useEffect(() => {
    if (action === 'add' && rateFacGroupCode && isRateFacProCode && state?.rcmId) {
      getAuditRCMResultsItemFn({
        variables: {
          input: encryptInput({
            rcmId: state?.rcmId,
            rateFacGroupCode,
            rateFacProCode: isRateFacProCode,
          }),
        },
      })
    }
  }, [action, isRateFacProCode, rateFacGroupCode, state?.rcmId])

  useEffect(() => {
    setVisible(open)
  }, [open])

  useEffect(() => {
    if (action) {
      switch (action) {
        case 'add':
        case 'edit':
          setDisable(false)
          break
        case 'view':
          setDisable(true)
          break
        default:
          break
      }
    }
  }, [action])

  useEffect(() => {
    if (getAuditRCMResultItem?.data) {
      const result = getAuditRCMResultItem?.data?.getAuditRCMResultsItem

      if (result.length === 1) {
        if (result[0]?.riskScore !== 0 || result[0]?.riskScore) setRiskScore(convertRiskScore(result[0]?.riskScore))
        if (result[0]?.rcmResultId) setRcmResultId(result[0]?.rcmResultId)
      }
    }

    if (getAuditRCMResultItem?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditRCMResultItem?.error)),
        onOk() {},
      })
    }
  }, [getAuditRCMResultItem?.data])

  useEffect(() => {
    if (getAuditResultControl?.data) {
      const result = getAuditResultControl?.data?.getAuditRCMResultControlById

      if (result?.riskScore !== 0 || result?.riskScore) setRiskScore(convertRiskScore(result?.riskScore))
      if (result?.rateFacGroupCode) setRateFacGroupCode(result?.rateFacGroupCode)
      if (result?.riskBalance) setScoreRiskBalance(result?.riskBalance)
      if (result?.rcmResultId) setRcmResultId(result?.rcmResultId)

      form.setFieldsValue({
        rateFacProCode: result?.rateFacProCode,
        rateRiskRuleCode: result?.rateRiskRuleCode,
        rateRiskConCode: result?.rateRiskConCode,
        controlScore: result?.controlScore,
        controlDetail: displayText(result?.controlDetailTH, result?.controlDetailEN),
        riskBalance: convertScoreToFormat(
          result?.riskBalance,
          convertRiskScore(result?.riskScore),
          result?.controlScore,
        ),
        riskBalanceDetail: displayText(result?.riskBalanceDetailTH, result?.riskBalanceDetailEN),
        riskFrequency: displayText(result?.riskFrequencyTH, result?.riskFrequencyEN),
        objective: displayText(result?.objectiveTH, result?.objectiveEN),
        guide: displayText(result?.guideTH, result?.guideEN),
      })
    }

    if (getAuditResultControl?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditResultControl?.error)),
        onOk() {},
      })
    }
  }, [getAuditResultControl?.data])

  useEffect(() => {
    if (createUpdateAuditRCMResultControl?.data) {
      const result = createUpdateAuditRCMResultControl?.data?.createUpdateAuditRCMResultControl?.rcmControlId

      Alert({
        type: 'success',
        title: t(action === 'add' ? 'บันทึกสำเร็จ' : 'แก้ไขสำเร็จ'),
        onOk() {
          resultModal(result)
          Promise.all([form.resetFields(), setVisible(false), close(false)])
        },
      })
    }

    if (createUpdateAuditRCMResultControl?.error) {
      Alert({
        type: 'error',
        title: t(action === 'add' ? 'ไม่สามารถบันทึกข้อมูลได้' : 'ไม่สามารถแก้ไขข้อมูลได้'),
        content: t(displayError(createUpdateAuditRCMResultControl?.error)),
        onOk() {},
      })
    }
  }, [createUpdateAuditRCMResultControl?.data])

  const onFinish = async (data) => {
    const objData = {
      ...data,
      riskFrequency: data.riskFrequency ? data.riskFrequency : null,
      rcmResultId: rcmResultId ? rcmResultId : null,
      rateFacGroupCode: rateFacGroupCode ? rateFacGroupCode : null,
      riskBalance: scoreRiskBalance ? scoreRiskBalance : null,
      action: action,
    }

    if (action === 'edit') objData.rcmControlId = rcmControlId

    Alert({
      type: 'confirm',
      title: t(action === 'add' ? 'ต้องการยืนยันการบันทึก?' : 'ต้องการยืนยันการแก้ไข?'),
      onOk() {
        createUpdateAuditRCMResultControlFn({ fetchPolicy: 'no-cache', variables: { input: encryptInput(objData) } })
      },
      onCancel() {},
    })
  }

  const getValueRiskScore = (range) => {
    const ans = []
    for (let i = 1; i <= range; i++) ans.push({ label: i + '', value: i })
    return ans
  }

  const convertScoreToFormat = (score, riskScore, controlScore) => {
    let text = null

    if (score === 0) text = '-'
    else if (score >= 1 && score <= 4) {
      if ((riskScore === 1 && controlScore === 4) || (riskScore === 4 && controlScore === 1)) {
        text = 'M'
      } else {
        text = 'L'
      }
    } else if (score >= 5 && score <= 9) {
      text = 'M'
    } else if (score >= 10 && score <= 25) {
      text = 'H'
    }

    return text && text
  }

  const convertRiskScore = (score) => {
    if (score) {
      if (score >= 1 && score <= 5) return 1
      else if (score >= 6 && score <= 10) return 2
      else if (score >= 11 && score <= 15) return 3
      else if (score >= 16 && score <= 20) return 4
      else if (score >= 21 && score <= 25) return 5
    }
  }

  return (
    <ModalNew
      onSubmit={() => {}}
      onClose={() => Promise.all([form.resetFields(), setVisible(false), close(false)])}
      visible={visible}
      typeAction={action === 'edit' ? 'edit' : action === 'view' ? 'view' : null}
      testTitle={t(action === 'add' ? 'เพิ่มการควบคุม' : action === 'edit' ? 'แก้ไขการควบคุม' : 'การควบคุม')}
      type="medium"
      footer={null}
    >
      {getAuditResultControl?.loading && <SpinnersNew />}
      <Col {...wrapper} style={{ margin: 'auto' }}>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{ ...labelCol }}
          wrapperCol={{ ...wrapperCol }}
          layout="horizontal"
          name={formname}
        >
          <Form.Item
            name="rateFacProCode"
            label={<Label type="tab-header-inactive">{t('ความเสี่ยงกระบวนการ')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกความเสี่ยงกระบวนการ') }]}
          >
            <SelectRateRiskFactorProcessModel
              placeholder={t('เลือกความเสี่ยงกระบวนการ')}
              formname={formname}
              disabled={disabled}
              filters={{ rcmId: state?.rcmId }}
              onClick={(e) => {
                if (e) {
                  if (action === 'add') form.setFieldsValue({ controlScore: null, riskBalance: null })
                  form.setFieldsValue({ rateRiskRuleCode: null, rateRiskConCode: null })
                }
              }}
              handleChange={async (e) => {
                if (!e?.value) form.setFieldsValue({ rateRiskRuleCode: null, rateRiskConCode: null })

                await setRateFacGroupCode(e?.source?.rateFacGroupCode ? e?.source?.rateFacGroupCode : null)
                await setIsRateFacProCode(e?.value ? e?.value : null)
                await setIsRateRiskRuleCode(null)
                await form.setFieldsValue({ rateFacProCode: e?.value })
              }}
            />
          </Form.Item>
          <Form.Item
            name="rateRiskRuleCode"
            label={<Label type="tab-header-inactive">{t('เกณฑ์ ระเบียบ คำสั่ง')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกเกณฑ์ ระเบียบ คำสั่ง') }]}
          >
            <SelectRateRiskRuleModel
              placeholder={t('เลือกเกณฑ์ ระเบียบ คำสั่ง')}
              formname={formname}
              disabled={disabled ? disabled : !isRateFacProCode ? true : false}
              filters={{ isActive: 1, rateFacProCode: isRateFacProCode }}
              handleChange={(e) => {
                setIsRateRiskRuleCode(e?.value ? e?.value : null)
                !e?.value && form.setFieldsValue({ rateRiskConCode: null })
                form.setFieldsValue({ rateRiskRuleCode: e?.value })
              }}
            />
          </Form.Item>
          <Form.Item
            name="rateRiskConCode"
            label={<Label type="tab-header-inactive">{t('การควบคุม')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกการควบคุม') }]}
          >
            <SelectRateRiskControl
              placeholder={t('เลือกการควบคุม')}
              formname={formname}
              disabled={disabled ? disabled : !isRateRiskRuleCode ? true : false}
              filters={{ isActive: 1, rateRiskRuleCode: isRateRiskRuleCode }}
              handleChange={(e) => form.setFieldsValue({ rateRiskConCode: e?.value })}
            />
          </Form.Item>
          <Form.Item name="controlScore" label={<Label type="tab-header-inactive">{t('คะแนนการควบคุม')}</Label>}>
            <Select
              showSearch
              placeholder={t('เลือกคะแนนการควบคุม')}
              data={getValueRiskScore(5)}
              scrollableId={formname}
              disabled={disabled ? disabled : action === 'add' && !rateFacGroupCode && !isRateFacProCode ? true : false}
              onChange={(e) => {
                const calRiskScore = e * (riskScore ? riskScore : 0)
                setScoreRiskBalance(calRiskScore)
                form.setFieldsValue({ controlScore: e, riskBalance: convertScoreToFormat(calRiskScore, riskScore, e) })
              }}
            />
          </Form.Item>
          <Form.Item
            name="controlDetail"
            label={<Label type="tab-header-inactive">{t('รายละเอียดการควบคุมที่มีอยู่')}</Label>}
            rules={[{ required: true, message: t('กรุณาระบุรายละเอียดการควบคุมที่มีอยู่') }]}
          >
            <TextAreaNew
              placeholder={t('ระบุรายละเอียดการควบคุมที่มีอยู่')}
              autoSize={{ minRows: 4, maxRows: 4 }}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            name="riskBalance"
            label={<Label type="tab-header-inactive">{t('ความเสี่ยงที่เหลืออยู่')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกความเสี่ยงที่เหลืออยู่') }]}
          >
            <InputNew placeholder={t('ระบุความเสี่ยงที่เหลืออยู่')} disabled={true} />
          </Form.Item>
          <Form.Item
            name="riskBalanceDetail"
            label={<Label type="tab-header-inactive">{t('รายละเอียดความเสี่ยงที่เหลืออยู่')}</Label>}
            rules={[{ required: true, message: t('กรุณาระบุรายละเอียดความเสี่ยงที่เหลืออยู่') }]}
          >
            <TextAreaNew
              placeholder={t('ระบุรายละเอียดความเสี่ยงที่เหลืออยู่')}
              autoSize={{ minRows: 4, maxRows: 4 }}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item name="riskFrequency" label={<Label type="tab-header-inactive">{t('ความถี่')}</Label>}>
            <InputNew placeholder={t('ระบุความถี่')} disabled={disabled} />
          </Form.Item>
          <Form.Item
            name="objective"
            label={<Label type="tab-header-inactive">{t('วัตถุประสงค์')}</Label>}
            rules={[{ required: true, message: t('กรุณาระบุวัตถุประสงค์') }]}
          >
            <TextAreaNew
              placeholder={t('ระบุวัตถุประสงค์')}
              autoSize={{ minRows: 4, maxRows: 4 }}
              disabled={disabled}
            />
          </Form.Item>
          <Form.Item
            name="guide"
            label={<Label type="tab-header-inactive">{t('วิธีการ/แนวทาง')}</Label>}
            rules={[{ required: true, message: t('กรุณาระบุวิธีการ/แนวทาง') }]}
          >
            <TextAreaNew
              placeholder={t('ระบุวิธีการ/แนวทาง')}
              autoSize={{ minRows: 4, maxRows: 4 }}
              disabled={disabled}
            />
          </Form.Item>
          <Col align="center">
            <Space size={[8, 8]} wrap>
              {!disabled && (
                <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                  {t('บันทึก')}
                </ButtonNew>
              )}
              <ButtonNew type="back" onClick={() => Promise.all([form.resetFields(), setVisible(false), close(false)])}>
                {t('ยกเลิก')}
              </ButtonNew>
            </Space>
          </Col>
        </Form>
      </Col>
    </ModalNew>
  )
}

ControlFormModal.propTypes = {
  menu: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  rcmControlId: PropTypes.number,
  resultModal: PropTypes.func.isRequired,
}

export default ControlFormModal
