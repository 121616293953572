import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// import { useLanguage } from '../../../hooks/useLanguage'
import { Row, Col } from 'antd'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import { GET_TRAINING_PERSONNELS, GET_PERSONNEL } from './graphql/Query'
import { DELETE_ONE_TRAINING } from './graphql/Mutation'
import * as _ from 'lodash'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { Alert } from '../../../components/alert/Alert'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { ButtonNew } from '../../../components/button/Button'
import { useLocation } from 'react-router-dom'
import { encryptInput } from '../../../utilitys/crypto'
import { columnsTraining } from './utils/table/TrainingTable'
import TrainingModal from './modal/TrainingModal.jsx'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { RowSummaryStyle } from './css/Styles'
import { displayError } from '../../../utilitys/helper'

const PersonnelTrainingList = (props) => {
  const { t } = useTranslation()
  // const [isLang] = useLanguage()
  const { type_action } = props
  const { state } = useLocation()
  const [formType, setFormType] = useState(type_action === 'add' ? 'add' : type_action === 'edit' ? 'edit' : 'view') // Intitial value formType
  const [dataLists, setDataLists] = useState([])
  const [codeEdit, setCodeEdit] = useState('')
  const [modalAdd, setModalAdd] = useState(false)
  const [contentName, setContentName] = useState()

  //get prefix, Name, lastName
  const [getPersonnelFn, dataPersonnel] = useLazyQuery(GET_PERSONNEL)
  const getData = (personnelCode) => {
    getPersonnelFn({ variables: { input: encryptInput({ personnelCode: personnelCode }) } })
  }
  useEffect(() => {
    getData(state.refCode)
  }, [state.refCode])
  //

  const getTrainingPagenation = useQuery(GET_TRAINING_PERSONNELS, {
    variables: {
      input: {
        personnelCode: state.refCode,
      },
    },
  })
  const [deleteData, { loading: deleteDataLoading, data: dataDelete, error: errorDelete }] =
    useMutation(DELETE_ONE_TRAINING)
  // const showConfirmEdit = () => {
  //   Alert({
  //     type: 'confirm',
  //     title: t('ต้องการยืนยันการบันทึก ?'),
  //     onOk() {},
  //     onCancel() {
  //       setModalAdd(false)
  //     },
  //   })
  // }

  const showConfirmDelete = (personnelTrainId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteData({
          variables: {
            input: { personnelTrainId: encryptInput(personnelTrainId) },
          },
        })
      },
      onCancel() {
        getTrainingPagenation.refetch()
      },
    })
  }

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          getTrainingPagenation.refetch()
        },
      })
    }
  }, [dataDelete])

  useEffect(() => {
    if (errorDelete) {
      showAlertError(t(displayError(errorDelete.message)))
    }
  }, [errorDelete])

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: text,
      onOk() {},
    })
  }
  useEffect(() => {
    if (dataPersonnel.data) {
      setContentName({
        prefix: dataPersonnel.data.getPersonnel.namePrefixTH,
        fName: dataPersonnel.data.getPersonnel.firstNameTH,
        lName: dataPersonnel.data.getPersonnel.lastNameTH,
      })
    }
  }, [dataPersonnel.data])
  // function setErrorField(name, errors) {
  //   // Set field errors
  //   return props.form.setFields([
  //     {
  //       name: name, // Name fields
  //       errors: errors ? [errors] : false, // Message errors OR remove fields errors
  //     },
  //   ])
  // }

  useEffect(() => {
    if (getTrainingPagenation?.data) {
      let response = getTrainingPagenation?.data?.getTrainingPagenation
      setDataLists([
        ..._.map(response.result, (data, i) => {
          return {
            key: i,
            no: i + 1,
            year: data.year,
            dateStart:
              moment(new Date(data.dateStart)).format('DD/MM/YYYY') +
              ' - ' +
              moment(new Date(data.dateEnd)).format('DD/MM/YYYY'),
            course: data.course,
            trainTypeNameTH: data.trainTypeNameTH,
            lecturer: data.lecturer,
            hour: data.hour,
            attachFile: data.attachFile,
            action: (
              <ButtonGroup
                menu={props.menu}
                icons={[
                  {
                    type: 'view',
                    onClick: () => {
                      setFormType('view')
                      setModalAdd(true)
                      setCodeEdit(data.personnelTrainId)
                    },
                  },
                  {
                    type: 'edit',
                    onClick: () => {
                      setFormType('edit')
                      setModalAdd(true)
                      setCodeEdit(data.personnelTrainId)
                    },
                  },
                  {
                    type: 'delete',
                    onClick: () => {
                      showConfirmDelete(data.personnelTrainId)
                    },
                  },
                ]}
              />
            ),
          }
        }),
        {
          key: uuidv4(),
          no: '',
          year: '',
          dateStart: '',
          course: '',
          trainTypeNameTH: '',
          lecturer: t('รวม(ชั่วโมง)'),
          hour: getTrainingPagenation.data.getTrainingPagenation.summary.summary,
          attachFile: '',
          action: '',
        },
      ])
    }
  }, [getTrainingPagenation.data])

  return (
    <>
      {(getTrainingPagenation.loading || deleteDataLoading) && <SpinnersNew />}
      <Row gutter={[16, 24]}>
        {contentName ? contentName.prefix + ' ' + contentName.fName + ' ' + contentName.lName : ''}
        <Col md={24}>
          <RowSummaryStyle>
            <Datatable
              columns={columnsTraining}
              data={dataLists}
              isScoll={{ x: false, y: false }}
              paginationCustom={false}
              pagination={true}
              pageSize={10}
              page={1}
              rowClassName={(record) => (record.lecturer === 'รวม(ชั่วโมง)' ? 'table-row-summary' : '')}
              btnAdd={
                <>
                  {type_action !== 'view' ? (
                    <ButtonNew
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: props.menu }}
                      style={{ marginRight: '12px' }}
                      onClick={() => {
                        setModalAdd(true)
                        setFormType('add')
                        setCodeEdit('')
                      }}
                    >
                      {t('เพิ่มการฝึกอบรม')}
                    </ButtonNew>
                  ) : (
                    ''
                  )}
                </>
              }
            />
          </RowSummaryStyle>
        </Col>
      </Row>
      <TrainingModal
        colse={(e) => {
          setModalAdd(e)
        }}
        open={modalAdd}
        refCode={state.refCode}
        codeEdit={codeEdit}
        formType={formType}
        setFormType={setFormType}
        getTrainingPagenation={getTrainingPagenation}
      />
    </>
  )
}

export default PersonnelTrainingList
