import { gql } from '@apollo/client'

export const GET_PERSONNEL = gql`
  query getPersonnel($input: PersonnelsInputPagination!) {
    getPersonnel(input: $input) {
      personnelCode
      namePrefixCode
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
      genderCode
      dateOfBirth
      personnelStatusCode
      positionCode
      levelCode
      personnalTypeCode
      organizationCode
      personnaloperationCode
      namePrefixTH
      namePrefixEN
      telephone
      mobile
      emergency
      email
      lineId
      profilePicture
      responsibility
      dateOfLeave
      newPersonnelStatusCode
      dateOfStartWorking
    }
  }
`

export const GET_PERSONNELS = gql`
  query GetPaginationPersonnels($input: PersonnelsPaginationInput!) {
    getPaginationPersonnels(input: $input) {
      result {
        dateOfBirth
        fullName
        fullNameEN
        email
        profilePicture
        organizationNameTH
        organizationNameEN
        personnelCode
        positionCode
        organizationCode
        personnalTypeCode
      }
      count
      page
      limit
    }
  }
`

export const GET_EDUCATION_PERSONNELS = gql`
  query getEducationPagenation($input: PersonnelEducationsInput!) {
    getEducationPagenation(input: $input) {
      personnelEducatId
      personnelCode
      educationLevelTH
      educationProgramTH
      educationMajorTH
      year
      institution
    }
  }
`

export const GET_ONE_EDUCATION = gql`
  query getEducation($input: PersonnelEducationsInput!) {
    getEducation(input: $input) {
      personnelEducatId
      personnelCode
      educationLevelCode
      educationProgramCode
      educationMajorCode
      year
      institution
    }
  }
`

export const GET_ONE_TRAINING = gql`
  query getTraining($input: PersonnelTrainingInput!) {
    getTraining(input: $input) {
      personnelTrainId
      personnelCode
      year
      dateStart
      dateEnd
      course
      trainTypeCode
      lecturer
      hour
      remark
      attachFile
    }
  }
`

export const GET_TRAINING_PERSONNELS = gql`
  query getTrainingPagenation($input: PersonnelTrainingInput!) {
    getTrainingPagenation(input: $input) {
      result {
        personnelTrainId
        personnelCode
        year
        dateStart
        dateEnd
        course
        trainTypeNameTH
        lecturer
        hour
        remark
        attachFile
      }
      summary {
        summary
      }
    }
  }
`
export const GET_REWARD_PERSONNELS = gql`
  query getPersonnelReward($input: PersonnelRewardInput!) {
    getPersonnelReward(input: $input) {
      personnelRewardId
      personnelCode
      rewardDate
      reward
      rewardNameTH
      rewardNameEN
      remark
      attachFile
    }
  }
`

export const GET_ONE_REWARD = gql`
  query getReward($input: PersonnelRewardInput!) {
    getReward(input: $input) {
      personnelRewardId
      personnelCode
      rewardDate
      reward
      rewardNameTH
      rewardNameEN
      remark
      attachFile
    }
  }
`

export const GET_CERTIFICATE_PERSONNELS = gql`
  query getPersonnelCertificate($input: PersonnelCertificateInput!) {
    getPersonnelCertificate(input: $input) {
      personnelCertificateId
      personnelCode
      certificateNameTH
      certificateNumber
      expireDate
      attachFile
    }
  }
`

export const GET_ONE_CERTIFICATE = gql`
  query getCertificate($input: PersonnelCertificateInput!) {
    getCertificate(input: $input) {
      personnelCertificateId
      personnelCode
      certificateCode
      certificateNumber
      certificateTypeCode
      expireDate
      attachFile
    }
  }
`

export const GET_EXPERIENCE_PERSONNELS = gql`
  query getPersonnelExperience($input: PersonnelExperiencePaginateInput!) {
    getPersonnelExperience(input: $input) {
      result {
        experienceType
        personnelCode
        jobTypeRefer
        experienceRefer
        startDate
        endDate
        manday
        costPersonnel
        costJob
      }
      count
      page
      limit
    }
  }
`

export const GET_USER_LDAP = gql`
  query getUserLdap($input: UserLdapInput!) {
    getUserLdap(input: $input) {
      users {
        initials
        dn
        distinguishedName
        userPrincipalName
        sAMAccountName
        mail
        lockoutTime
        whenCreated
        pwdLastSet
        userAccountControl
        employeeID
        sn
        givenName
        initials
        cn
        displayName
        comment
        description
        title
        physicalDeliveryOfficeName
        department
      }
      ldapConnection
    }
  }
`
