import React, { useEffect } from 'react'
import { Col, Row, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { GENERATE_DOCUMENTNO_PREPARE_AUDIT } from '../../graphql/Mutation'
import { ModalNew } from '../../../../../components/modal/Modal'
import { LabelNew } from '../../../../../components/label/Label'
import { ButtonNew } from '../../../../../components/button/Button'
import { Alert } from '../../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'
import SelectDocumentTypes from '../../../../../components/inputfromapi/selectdocumenttypes/SelectDocumentTypes'

const GenerateDocumentNo = (props) => {
  const responsive = { lg: 16 }
  const { menu, close, generateDocumentNoModal, state, fieldForGenerate, setOtherInput } = props

  const { t } = useTranslation()
  const formname = 'generateDocumentNo'
  const [form] = Form.useForm()

  const [generateDocumentNoPrepareAuditfn, generateDocumentNoPrepareAudit] = useMutation(
    GENERATE_DOCUMENTNO_PREPARE_AUDIT,
  )

  const onFinish = (data) => {
    let dataInput = {
      docTypeCode: data.docTypeCode,
      organizationCodeReport: fieldForGenerate.organizationCodeReport,
      startDateReport: fieldForGenerate.startDateReport,
      endDateReport: fieldForGenerate.endDateReport,
      menuCode: menu,
      finalReportId: state.finalReportId,
    }

    generateDocumentNoPrepareAuditfn({
      variables: { input: encryptInput({ ...dataInput }) },
    })
    close(false)
  }

  useEffect(() => {
    if (generateDocumentNoPrepareAudit?.data) {
      const resData = generateDocumentNoPrepareAudit?.data.generateDocumentNoPrepareAudit
      setOtherInput({
        ...fieldForGenerate.otherInput,
        documentNo: resData.documentNo,
        docTypeCode: resData.docTypeCode,
      })
    }
    if (generateDocumentNoPrepareAudit?.error) {
      showAlertGenerateDocumentNoError(generateDocumentNoPrepareAudit.error)
    }
  }, [generateDocumentNoPrepareAudit?.data])

  const showAlertGenerateDocumentNoError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถออกเลขที่ตรวจสอบได้'),
      content: t(displayError(text)),
      onOk() {},
    })
  }

  return (
    <>
      {generateDocumentNoPrepareAudit.loading && <SpinnersNew />}
      <ModalNew
        onSubmit={() => {}}
        onClose={() => Promise.all([form.resetFields(), close(false)])}
        visible={generateDocumentNoModal}
        typeAction={'add'}
        testTitle={t('เลือกเลขที่ตรวจสอบ')}
        type="medium"
        footer={null}
      >
        <Form
          name={formname}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelCol={{
            lg: 8,
            xl: 8,
          }}
          wrapperCol={{
            lg: 10,
            xl: 10,
          }}
          layout="horizontal"
          labelAlign="left"
        >
          <Row justify="center">
            <Col {...responsive}>
              <Form.Item
                name="docTypeCode"
                label={<LabelNew type="tab-header-inactive">{t('เอกสาร/งาน')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาเอกสาร/งาน') }]}
              >
                <SelectDocumentTypes
                  placeholder={t('เอกสาร/งาน')}
                  formname={formname}
                  filters={{ isActive: true }}
                  handleChange={async (e) => {
                    await form.setFieldsValue({
                      docTypeCode: e?.value,
                    })
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 20]} justify="center">
            <Col>
              <ButtonNew type="primary" roles={{ type: state.typeAction, menu: menu }} htmlType="submit">
                {t('ออกเลข')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew type="back" onClick={() => close(false)}>
                {t('ยกเลิก')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </ModalNew>
    </>
  )
}

export default GenerateDocumentNo
