import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_SELECT_AUDIT_PROJECTS_NEW, GET_SELECT_AUDIT_PROJECTS_CLOSED } from './graphql/Query'
import { Select } from '../../select/Select'
import _ from 'lodash'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectAuditProjects = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const {
    isActive,
    planCode,
    projectTypeCode,
    hasActionPlan,
    hasInnerAuditClose,
    projectStatusCode,
    projectStatusCodeOperator,
    projectCode,
  } = filters || {}

  const [isData, isSetData] = useState([])

  const [getSelectAuditProjectsNewFn, { data, loading }] = useLazyQuery(GET_SELECT_AUDIT_PROJECTS_NEW)

  useEffect(() => {
    getSelectAuditProjectsNewFn({
      variables: { input: filters ? encryptInput({ ...filters, isActive: 1 }) : encryptInput({ isActive: 1 }) },
    })
  }, [
    isActive,
    planCode,
    projectTypeCode,
    hasActionPlan,
    hasInnerAuditClose,
    projectStatusCode,
    projectStatusCodeOperator,
    projectCode,
  ])

  useEffect(() => {
    if (data) {
      isSetData(
        data?.getSelectAuditProjectsNew.map((item) => {
          let label = displayText(item?.projectNameTH, item?.projectNameEN)
          if (!label) label = item.projectNameTH

          return {
            value: item.projectCode,
            label: `${item.projectCode} : ${label}`,
            source: item,
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        loading={loading}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectAuditProjects

export const SelectAuditProjectsClosed = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { isActive, planCode, projectTypeCode, hasActionPlan } = filters || {}

  const [isData, isSetData] = useState([])

  const [getSelectAuditProjectClosed, { data, loading }] = useLazyQuery(GET_SELECT_AUDIT_PROJECTS_CLOSED)

  useEffect(() => {
    getSelectAuditProjectClosed({
      variables: { input: filters ? encryptInput({ ...filters, isActive: 1 }) : encryptInput({ isActive: 1 }) },
    })
  }, [isActive, planCode, projectTypeCode, hasActionPlan])

  useEffect(() => {
    if (!data) return null
    isSetData(
      data?.getSelectAuditProjectClosed.map((item) => {
        let label = displayText(item?.projectNameTH, item?.projectNameEN)
        if (!label) label = item.projectNameTH

        return {
          value: item.projectCode,
          label: `${item.projectCode} : ${label}`,
          source: item,
        }
      }),
    )
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        loading={loading}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}
