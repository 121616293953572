import { gql } from '@apollo/client'

export const GET_ORGANIZATION_PAGINATION = gql`
  query getOrganizationsPagination($input: OrganizationsPaginationInput!) {
    getOrganizationsPagination(input: $input) {
      result {
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationParentCode
        organizationParentNameTH
        organizationParentNameEN
        organizationLevelCode
        organizationLevelNameTH
        organizationLevelNameEN
        organizationSizeCode
        organizationSizeNameTH
        organizationSizeNameEN
        organizationType
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_ORGANIZATION_BY_CODE = gql`
  query getOrganizationByCode($organizationCode: String!) {
    getOrganizationByCode(organizationCode: $organizationCode) {
      result {
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationParentCode
        organizationLevelCode
        organizationSizeCode
        organizationChecked
        organizationType
        isActive
      }
      organizationLogs {
        logfileOrgId
        userId
        organizationCode
        organizationNameTH
        organizationNameEN
        changeType
        createdAt
      }
    }
  }
`

export const GET_ORGANIZATION_LOG_BY_CODE = gql`
  query getOrganizationLogByCode($logfileOrgId: String!) {
    getOrganizationLogByCode(logfileOrgId: $logfileOrgId) {
      organizationLog {
        logfileOrgId
        userId
        organizationCode
        organizationNameTH
        organizationNameEN
        remark
        files
      }
      files {
        fileId
        fileNameGen
        fileType
        fileNameLabel
      }
    }
  }
`
