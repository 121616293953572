import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'

import { Datatable } from '../../../../../components/datatable/Datatable'
import { MainTable, SubTable } from './css/Styles'
import { IconNew } from '../../../../../components/icon/Icon'
import { ButtonNew } from '../../../../../components/button/Button'
import { useNavigate } from 'react-router-dom'
import { DATAFOLLOWUP_CLOSE_ISSUE_TAB_LIST } from '../../graphql/Query'
import { FOLLOW_UP_CLOSE_ISSUE_TAP_SAVE } from '../../graphql/Mutation'
import { useLazyQuery, useMutation } from '@apollo/client'
import { displayError } from '../../../../../utilitys/helper'
import { encryptInput } from '../../../../../utilitys/crypto'
import { Alert } from '../../../../../components/alert/Alert'
import { Status } from '../../../../../components/status/Status'
import { displayText } from '../../../../../utilitys/helper'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { getSegmentUrl } from '../../../../../utilitys/helper'

const FormFollowUpAuditCloseissueTabIndex = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const typeMain = getSegmentUrl(4)

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showConfirm = (activityId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันยุติการติดตาม ?'),
      onOk() {
        followUpCloseIssueTabSaveFn({
          fetchPolicy: 'no-cache',
          variables: {
            input: encryptInput({
              activityId: activityId,
            }),
          },
        })
      },
      onCancel() {},
    })
  }
  const showAlertSaveError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }
  const showAlertSuccess = () => {
    Alert({
      type: 'success',
      title: t('บันทึกสำเร็จ'),
      onOk() {
        dataFollowUpCloseIssueTabList.refetch()
      },
    })
  }
  const [followUpCloseIssueTabSaveFn, followUpCloseIssueTabSave] = useMutation(FOLLOW_UP_CLOSE_ISSUE_TAP_SAVE)
  useEffect(() => {
    if (followUpCloseIssueTabSave.data) {
      let response = followUpCloseIssueTabSave?.data?.followUpCloseIssueTabSave
      if (response) {
        showAlertSuccess()
      }
    }
    if (followUpCloseIssueTabSave.error) {
      showAlertSaveError(displayError(followUpCloseIssueTabSave.error))
    }
  }, [followUpCloseIssueTabSave.data])
  const [dataFollowUpCloseIssueTabListFn, dataFollowUpCloseIssueTabList] = useLazyQuery(
    DATAFOLLOWUP_CLOSE_ISSUE_TAB_LIST,
  )
  useEffect(() => {
    if (dataFollowUpCloseIssueTabList.data) {
      let response = dataFollowUpCloseIssueTabList?.data?.dataFollowUpCloseIssueTabList
      if (response) {
        setCount(response.count)
        setDataLists(
          response.result.map((item) => {
            return {
              key: item.issueId,
              issue: displayText(item.issueTH, item.issueEN),
              issueStatusName: <Status text={item.issueStatusNameTH} />,
              description: (
                <SubTable>
                  <Datatable
                    columns={[
                      {
                        title: <div className="text-center">{t('ข้อเสนอแนะ')}</div>,
                        dataIndex: 'suggest',
                        width: '300px',
                        className: 'vertical-align-top',
                      },
                      {
                        title: <div className="text-center">{t('กิจกรรมที่ต้องดำเนินการ')}</div>,
                        dataIndex: 'activity',
                        width: '900px',
                        className: 'vertical-align-top',
                        render: (record) => <div style={{ wordBreak: 'break-word' }}>{record}</div>,
                      },
                      {
                        title: <div className="text-center">{t('ข้อยุติ')}</div>,
                        dataIndex: 'followUpStatusName',
                        width: '150px',
                        className: 'vertical-align-top',
                      },
                      {
                        title: '',
                        dataIndex: 'btnExtra',
                        width: '150px',
                        className: 'vertical-align-top',
                      },
                    ]}
                    scroll={{ x: '100%', y: false }}
                    data={item.subItem.map((subItem) => {
                      return {
                        key: subItem.activityId,
                        suggest: displayText(subItem.suggestTH, subItem.suggestEN),
                        activity: displayText(subItem.activityTH, subItem.activityEN),
                        followUpStatusName: displayText(subItem.followUpStatusNameTH, subItem.followUpStatusNameEN),
                        btnExtra:
                          subItem.followUpStatusCode === '10' ? (
                            <ButtonNew
                              type="flagOutlinedInTableBlue"
                              roles={{ type: 'edit', menu: props.menu }}
                              disabled={typeMain === 'view' ? true : false}
                              onClick={() => {
                                showConfirm(subItem.activityId)
                              }}
                            >
                              {t('ยุติการติดตาม')}
                            </ButtonNew>
                          ) : subItem.followUpStatusCode === '20' ? (
                            <ButtonNew type="moreHorizontalInTableOrange" disabled={true}>
                              {t('ยุติการติดตามแล้ว')}
                            </ButtonNew>
                          ) : (
                            ''
                          ),
                      }
                    })}
                  />
                </SubTable>
              ),
            }
          }),
        )
      }
    }
    if (dataFollowUpCloseIssueTabList.error) {
      showAlertLoadDataError(displayError(dataFollowUpCloseIssueTabList.error))
    }
  }, [dataFollowUpCloseIssueTabList.data])

  const callApiSearch = () => {
    dataFollowUpCloseIssueTabListFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          filters: {
            projectCode: props.refCode,
          },
          pagination: { limit, page },
          sort: [],
        }),
      },
    })
  }
  useEffect(() => {
    callApiSearch()
  }, [page, limit])
  const mainColumns = [
    {
      title: <div className="text-center">{t('ประเด็น/ข้อสังเกต')}</div>,
      dataIndex: 'issue',
      width: '80%',
    },
    {
      title: <div className="text-center">{t('สถานะประเด็น/ข้อสังเกต')}</div>,
      dataIndex: 'issueStatusName',
      width: '20%',
      className: 'text-center',
    },
  ]
  return (
    <>
      {(dataFollowUpCloseIssueTabList.loading || followUpCloseIssueTabSave.loading) && <SpinnersNew />}

      <Row>
        <Col md={24}>
          <MainTable>
            <Datatable
              columns={mainColumns}
              data={dataLists}
              handleTableChange={() => {}}
              paginationCustom={true}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
              total={count}
              enableScroll={{ x: false, y: true }}
              scroll={{ x: '100%', y: false }}
              pageSize={limit}
              page={page}
              expandable={{
                expandedRowRender: (record) => record.description,
                onExpandedRowsChange: () => {},
                expandIcon: ({ expanded, onExpand, record }) =>
                  expanded ? (
                    <IconNew icon={'ChevronUp'} onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <IconNew icon={'ChevronDown'} onClick={(e) => onExpand(record, e)} />
                  ),
              }}
              btnAdd={<></>}
            />
          </MainTable>
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="center" style={{ marginTop: '50px' }}>
        <Col>
          <ButtonNew
            type="cancel"
            onClick={() => {
              navigate('/followup/followupaudit')
            }}
          >
            {t('ยกเลิก')}
          </ButtonNew>
        </Col>
      </Row>
    </>
  )
}

export default FormFollowUpAuditCloseissueTabIndex
