import React, { useEffect, useState } from 'react'
import { Bar } from '@ant-design/plots'
import _ from 'lodash'

export const GraphColumn = (props) => {
  const { data } = props
  const [dataGraph, setDataGraph] = useState([])

  const colorMap = {
    'เป็นไปตาม SLA': '#0C6ABF',
    'ไม่เป็นไปตาม SLA': '#FF3333',
    SLA: '#0C6ABF',
    'Not SLA': '#FF3333',
  }

  const [configs, setConfigs] = useState({
    data: [],
    isStack: true,
    xField: 'value',
    yField: 'level',
    seriesField: 'type',
    color: ['#0C6ABF', '#FF3333'],
    minColumnWidth: 20,
    maxColumnWidth: 50,
    height: 300,
    label: {
      position: 'middle',
      layout: [{ type: 'interval-adjust-position' }, { type: 'interval-hide-overlap' }, { type: 'adjust-color' }],
    },
  })

  useEffect(() => {
    setDataGraph(data)

    if (!_.isEmpty(data)) {
      setConfigs({
        ...configs,
        color: (data) => colorMap[data.type],
      })
    }
  }, [data])

  useEffect(() => {
    setConfigs({
      ...configs,
      data: dataGraph,
    })
  }, [dataGraph])

  return !_.isEmpty(data) ? <Bar {...configs} /> : null
}
