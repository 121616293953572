import { gql } from '@apollo/client'
export const GET_LIST_PROJECT_SIZE_SETUP_PAGINATION = gql`
  query GetListProjectSizeSetupPagination($input: ProjectSizeSetupPagination!) {
    getListProjectSizeSetupPagination(input: $input) {
      result {
        no
        projectSizeCode
        projectSizeNameTH
        projectSizeNameEN
        isActive
        manday
        remark
      }
      count
      page
      limit
    }
  }
`
