import { gql } from '@apollo/client'
export const GET_MASTER_AUDIT_FINDING_TYPE = gql`
  query GetMasterAuditFindingType($input: MasterAuditFindingTypeInput!) {
    getMasterAuditFindingType(input: $input) {
      FindingTypeId
      FindingTypeCode
      FindingTypeNameTH
      FindingTypeNameEN
      isActive
    }
  }
`
