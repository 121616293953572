import { gql } from '@apollo/client'

// For CheckUnitFilter.jsx
export const GET_SELECT_MASTER_ORGANIZATION_LEVEL = gql`
  query {
    getSelectMasterOrganizationLevel {
      organizationLevelCode
      organizationLevelNameTH
      organizationLevelNameEN
    }
  }
`
export const GET_MASTER_ORGANIZATIONS_FILTERS = gql`
  query getMasterOrganizationsFilters($input: MasterOrganizationsInput!) {
    getMasterOrganizationsFilters(input: $input) {
      organizationId
      organizationCode
      organizationNameTH
      organizationNameEN
      createdAt
      createdBy
      organizationLevelNameTH
      organizationLevelNameEN
      organizationLevelCode
      personnelCode
      personnelNameTH
      personnelNameEN
    }
  }
`
