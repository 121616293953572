const codeSelectValidate = `import { Select } from '../../../components/select/Select'
  
const example_Data = [
  { label: 'Jack', value: '01' },
  { label: 'Lucy', value: '02' },
  { label: 'Adam', value: '03' },
  { label: 'test4', value: '04', disabled: true }, // option disable
  { label: 'test5', value: '05' },
  { label: 'test6', value: '06' },
]

const handleChange = (value) => {
console.log('value: ', value)
}

// ============ 
ถ้า scrollableId อยู่ใน form
ให้ใช้ form name ใส่ scrollableId
ถ้าไม่อยู่
ให้ใส่ id ของ scroll
เช่น
// ============

<Form name="select_form">
<Form.Item
    name={'validate_select'}
    rules={[
      {
        required: true,
        message: 'Please select item!',
      },
    ]}
>
  <Select
      title={'Validate Select'}
      data={example_Data}
      placeholder={'Validate select...'}
      onChange={handleChange}
      scrollableId="select_form" 
  />
</Form.Item>
</Form>
`

const codeBasic = `<Select
title="Basic"
data={example_Data}
placeholder="Basic select..."
onChange={handleChange}
scrollableId="select_form"
/>

<Select
title="Default"
data={example_Data}
defaultValue={example_Data[2].value}
placeholder="Default select..."
onChange={handleChange}
scrollableId="select_form"
/>

<Select 
title="Loading" 
placeholder="Loading select..." 
loading 
onChange={handleChange}
scrollableId="select_form"
/>

<Select
title="Search"
data={example_Data}
placeholder="Search select..."
showSearch
onChange={handleChange}
scrollableId="select_form"
filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
/>

<Select
title={'Disabled'}
data={example_Data}
placeholder="Disabled select..."
disabled
onChange={handleChange}
scrollableId="select_form"
/>

<Select
title={'Borderless'}
data={example_Data}
placeholder="Borderless select..."
bordered={false}
onChange={handleChange}
scrollableId="select_form"
/>

<Select
title={'Multiple '}
data={example_Data}
placeholder="Multiple select..."
multiple
onChange={handleChange}
scrollableId="select_form"
/>

<Select
title={'Multiple Default'}
defaultValue={['02', '05']}
data={example_Data}
placeholder="Multiple default select..."
multiple
onChange={handleChange}
scrollableId="select_form"
/>
`

export { codeBasic, codeSelectValidate }
