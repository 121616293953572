import { gql } from '@apollo/client'

export const GET_ACTIONPLAN_ORGANIZATION = gql `
  query getActionPlanOrganizationsSelect($input: ActionPlanOrganizationsInput!) {
    getActionPlanOrganizationsSelect(data: $input) {
      organizationCode
      organizationNameTH
      organizationNameEN
      personnelCode
      namePrefixTH
      namePrefixEN
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
      email
    }
  }
`

export const GET_ACTIONPLAN_PERSONNEL = gql `
  query getActionPlanPersonnelForAuditOpen($input: ActionPlanPersonnelInput!) {
    getActionPlanPersonnelForAuditOpen(data: $input) {
      actPlanPersonId
      namePrefixTH
      namePrefixEN
      organizationCode
      personnelCode
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
      email
    }
  }
`

export const SELECT_PERSONNEL = gql `
  query personnelSelect($input: SelectPersonnelsInput!) {
    personnelSelect(input: $input) {
      personnelCode
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
      levelCode
      organizationNameTH
      organizationNameEN
      organizationCode
      telephone
      mobile
      email
    }
  }
`

export const GET_AUDITOPENS_PAGINATION = gql `
  query getAuditOpensPagenation($input: AuditOpensPagenation!) {
    getAuditOpensPagenation(data: $input) {
      result {
        openId
        opened
        openNumber
        projectCode
        projectNameTH
        projectNameEN
        dateStart
        dateEnd
        auditOpenOrganizations {
          organizationNameTH
          organizationNameEN
        }
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDITOPENS_ADDDOC_PAGINATION = gql `
  query dataAuditOpensAddDocTabPagination($input: AuditOpensAddDocTabPagination!) {
    dataAuditOpensAddDocTabPagination(data: $input) {
      result {
        openDocId
        openId
        openDocType
        organizationCode
        organizationNameTH
        organizationNameEN
        openDocDetailTH
        openDocDetailEN
        files
        fileDetail
        dateStart
        dateEnd
        dateSend
        sendStatus
      }
      count
      page
      limit
    }
  }
`

export const DATA_AUDIT_OPENS_INFORMATION_TAB = gql `
  query dataAuditOpensInformationTab($openId: String!) {
    dataAuditOpensInformationTab(openId: $openId) {
      openId
      openDate
      openNumber
      projectTypeCode
      planCode
      projectCode
      organizationCode
      organizationNameTH
      organizationNameEN
      openForTH
      openForEN
      openSubjectTH
      openSubjectEN
      openInformTH
      openInformEN
      openPostTH
      openPostEN
      openSigner
      openReview
      openCoordinate
      openCoordinateTelephone
      openCoordinateMobile
      dateStart
      dateEnd
      sendFor {
        organizationCode
        openId
        openToType
        personnelCode
        email
      }
      sendForCopy {
        personnelCode
      }
      files
      fileDetail
    }
  }
`

export const DATA_AUDIT_OPENS_ADDDOC_TAB_FORM = gql `
  query dataAuditOpensAddDocTabForm($openDocId: String!) {
    dataAuditOpensAddDocTabForm(openDocId: $openDocId) {
      openDocId
      openId
      openDocType
      organizationCode
      openDocDetailTH
      openDocDetailEN
      files
      fileDetail
      dateStart
      dateEnd
      sendStatus
      refFile {
        fileId
        originalname
        name
      }
    }
  }
`

export const DATA_AUDIT_OPENS_PETTITION = gql `
  query dataAuditOpensPetition($openId: String!) {
    dataAuditOpensPetition(openId: $openId) {
      openNumber
      openSubjectTH
      openSubjectEN
      fileSend {
        idFile
        refFile {
          fileId
          originalname
          name
        }
      }
      sendTo {
        email
        personnelCode
      }
      sendToCopy {
        email
        personnelCode
      }
    }
  }
`

export const DATA_AUDIT_OPENS_DOC_PETTITION = gql `
  query dataAuditOpensDocPetition($openDocId: String!) {
    dataAuditOpensDocPetition(openDocId: $openDocId) {
      openId
      openNumber
      openSubjectTH
      openSubjectEN
      fileSend {
        idFile
        refFile {
          fileId
          originalname
          name
        }
      }
      sendTo {
        email
        personnelCode
      }
      sendToCopy {
        email
        personnelCode
      }
    }
  }
`

export const AUDITOPENS_EXPORT = gql `
  query auditOpensExport($input: AuditOpensExportInput!) {
    auditOpensExport(data: $input) {
      message
      fileUrl
    }
  }
`