import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation, useLazyQuery } from '@apollo/client'
import { RadioGroup, Radio } from '../../../../../components/radio/Radio'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { InputNew } from '../../../../../components/input/Input'
import { ModalNew } from '../../../../../components/modal/Modal'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { Alert } from '../../../../../components/alert/Alert'
import { SAVE_SINGLE_ADHOCJOB_STEP } from '../../graphql/Mutation'
import { GET_ADHOCJOB_STEP_BY_ID } from '../../graphql/Query'
import { encryptInput } from '../../../../../utilitys/crypto'
import { regexEng, regexThai, displayError } from '../../../../../utilitys/helper'
import SelectResponsible from '../select/SelectResponsible.jsx'

const AdhocjobStepMain = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { refCode, stepId, typeAction } = props
  const disabled = typeAction === 'view' ? true : false
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)

  const ResponsiveModal = {
    md: 20,
    lg: 20,
  }

  /* ---------------- API ----------- */
  const [getJobStepById, dataJobStepById] = useLazyQuery(GET_ADHOCJOB_STEP_BY_ID)
  const [
    saveSingleAdhocJobStepFn,
    { loading: loadingSingleAdhocJobStep, error: errorSingleAdhocJobStep, data: dataSingleAdhocJobStep },
  ] = useMutation(SAVE_SINGLE_ADHOCJOB_STEP)

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    let dataInput = {
      stepId: input.stepId || '',
      stepNameTH: input.stepNameTH || '',
      stepNameEN: input.stepNameEN || '',
      dateStart: input.dateStart || '',
      dateEnd: input.dateEnd || '',
      stepSort: Number(input.stepSort),
      isActive: input.isActive,
      stepType: 1,
      responsible: input.responsible || [],
      action: input.stepId ? 'update' : 'create',
    }

    saveSingleAdhocJobStepFn({
      variables: {
        input: encryptInput(dataInput),
        jobCode: encryptInput(refCode),
      },
    })
  }

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (stepId) {
      getJobStepById({
        variables: {
          stepId: encryptInput(stepId),
        },
      })
    }
  }, [stepId])

  useEffect(() => {
    form.resetFields()
    if (dataJobStepById.data) {
      const data = dataJobStepById.data.getJobStepById
      if (stepId) {
        form.setFieldsValue({
          stepId: data.stepId,
          stepSort: data.stepSort,
          stepNameTH: data.stepNameTH,
          stepNameEN: data.stepNameEN,
          dateStart: data.dateStart,
          dateEnd: data.dateEnd,
          isActive: data.isActive,
          responsible: data.responsible,
        })
      }
    }

    if (dataJobStepById.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataJobStepById.error)),
        onOk() {},
      })
    }
  }, [dataJobStepById.data, stepId])

  useEffect(() => {
    if (dataSingleAdhocJobStep) {
      form.resetFields()
      props.close(false)
      props.refetch()
    }
  }, [dataSingleAdhocJobStep])

  useEffect(() => {
    if (errorSingleAdhocJobStep) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถเพิ่มข้อมูลได้'),
        content: t(displayError(errorSingleAdhocJobStep)),
        onOk() {},
      })
    }
  }, [errorSingleAdhocJobStep])

  return (
    <>
      {loadingSingleAdhocJobStep && <SpinnersNew />}
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ md: { span: 8 }, lg: { span: 8 } }}
        wrapperCol={{ md: { span: 16 }, lg: { span: 16 } }}
        layout="horizontal"
        name="modalMain"
      >
        <ModalNew
          visible={props.visible}
          onSubmit={form.submit}
          onClose={(e) => {
            form.resetFields(), props.close(e)
          }}
          typeAction={typeAction}
          testTitle={
            !typeAction ? t('เพิ่มขั้นตอนหลัก') : typeAction === 'edit' ? t('แก้ไขขั้นตอนหลัก') : t('ขั้นตอนหลัก')
          }
          okButtonProps={typeAction === 'view' ? { style: { display: 'none' } } : {}}
        >
          <Row justify="center">
            <Col {...ResponsiveModal}>
              <Form.Item hidden={true} name="stepId">
                <InputNew />
              </Form.Item>
              <Form.Item
                name="stepSort"
                label={<Label type="tab-header-inactive">{t('ขั้นที่')}</Label>}
                rules={[
                  { required: true, message: t('กรุณาระบุขั้นที่') },
                  {
                    pattern: /^[0-9]+$/,
                    message: t(`กรุณากรอกตัวเลข 0-9 และไม่เกิน 2 หลัก`),
                  },
                ]}
              >
                <InputNew placeholder={t('ระบุขั้นที่')} maxLength={2} disabled={disabled} />
              </Form.Item>
              <Form.Item
                name="stepNameTH"
                label={<Label type="tab-header-inactive">{t('ชื่อขั้นตอนหลักภาษาไทย')}</Label>}
                rules={[
                  { required: true, message: t('กรุณาระบุขั้นตอนหลักภาษาไทย') },
                  {
                    pattern: regexThai,
                    message: t(`กรุณากรอกด้วยภาษาไทยหรือตัวเลข ก-๙, 0-9`),
                  },
                ]}
              >
                <InputNew placeholder={t('ระบุขั้นตอนหลักภาษาไทย')} disabled={disabled} maxLength={200} />
              </Form.Item>
              <Form.Item
                name="stepNameEN"
                label={<Label type="tab-header-inactive">{t('ชื่อขั้นตอนหลักภาษาอังกฤษ')}</Label>}
                rules={[
                  {
                    pattern: regexEng,
                    message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
                  },
                ]}
              >
                <InputNew placeholder={t('ระบุขั้นตอนหลักภาษาอังกฤษ')} disabled={disabled} maxLength={200} />
              </Form.Item>
              <Form.Item
                name="responsible"
                label={<Label type="tab-header-inactive">{t('ผู้รับผิดชอบ')}</Label>}
                rules={[{ required: true, message: t('กรุณาเลือกผู้รับผิดชอบ') }]}
              >
                <SelectResponsible
                  multiple={true}
                  placeholder={t('ผู้รับผิดชอบ')}
                  formname={'modalSub'}
                  filters={{ jobCode: encryptInput(refCode) }}
                  disabled={disabled}
                  handleChange={(e) => {
                    form.setFieldsValue({ responsible: e })
                  }}
                />
              </Form.Item>
              <Form.Item name="dateStart" label={<Label type="tab-header-inactive">{t('วันที่เริ่มต้น')}</Label>}>
                <Datepicker
                  setValueDateFn={async (data) => {
                    await setIsMinDate(data), await form.setFieldsValue({ dateStart: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateStart: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                  isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                  disabled={disabled}
                />
              </Form.Item>
              <Form.Item name="dateEnd" label={<Label type="tab-header-inactive">{t('วันที่สิ้นสุด')}</Label>}>
                <Datepicker
                  setValueDateFn={async (data) => {
                    await setIsMaxDate(data), await form.setFieldsValue({ dateEnd: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateEnd: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                  isMinDate={isMinDate ? new Date(isMinDate) : null}
                  disabled={disabled}
                />
              </Form.Item>

              <Form.Item
                label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>}
                name="isActive"
                initialValue={1}
              >
                <RadioGroup>
                  <Space size={[8, 8]} wrap>
                    <Radio value={1} disabled={disabled}>
                      <Label type="tab-header-inactive">{t('ใช้งาน')}</Label>
                    </Radio>
                    &nbsp;
                    <Radio value={0} disabled={disabled}>
                      <Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>
                    </Radio>
                  </Space>
                </RadioGroup>
              </Form.Item>
            </Col>
          </Row>
        </ModalNew>
      </Form>
    </>
  )
}

export default AdhocjobStepMain
