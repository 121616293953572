import { gql } from '@apollo/client'

export const GET_MASTER_PROJECT_SIZES = gql`
  query getMasterProjectSizes($input: MasterProjectSizesInput!) {
    getMasterProjectSizes(data: $input) {
      projectSizeId
      projectSizeCode
      projectSizeNameTH
      projectSizeNameEN
      manday
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`
