import React, { useState, useEffect } from 'react'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { breadcrumbList } from './utils/breadcrumbData.js'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { CardNew } from '../../../components/card/Card'
import _ from 'lodash'
import { Row, Col } from 'antd'
import { ButtonNew as Button } from '../../../components/button/Button'
import { useNavigate } from 'react-router-dom'
import { LabelNew as Label } from '../../../components/label/Label'
import { ButtonDowloadsExcel } from '../../../components/button/ButtonDowloadsExcel'
import { UploadExcel } from '../../../components/upload/UploadExcel'
import { Datatable } from '../../../components/datatable/Datatable'
import { ColumnsImport } from './utils/columnData'
import { MASTERCALENDARSSAVE } from './graphql/Mutation'
import { useMutation } from '@apollo/client'
import { getSegmentUrl, displayError } from '../../../utilitys/helper'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput } from '../../../utilitys/crypto'
import { RowStyle } from './css/ImportCalendarsRow'
import { dateDisplay } from '../../../utilitys/dateHelper'

export default function CalendarSetImport(props) {
  const { menu } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const pageType = getSegmentUrl(3)
  const Responsive = {
    md: 24,
    lg: 24
  }

  const checkDateType = ['วันหยุดประจำปี', 'วันประชุม AC', 'วันที่ประชุม MC']
  const [dataExcel, setDataExcel] = useState([])
  const [dataList, setdataList] = useState([])
  const [dataCalendar, setdataCalendar] = useState({})
  const [dataCalendarDetail, setdataCalendarDetail] = useState({})
  const [dataFileName, setFileName] = useState()
  const [rowError, setRowError] = useState([])

  const [masterCalendarsSavefn, { loading: loadingSaved, error, data: dataSaved }] = useMutation(MASTERCALENDARSSAVE)

  const clearInputType = () => {
    setdataList([])
    setdataCalendar({})
    setdataCalendarDetail({})
    setRowError([])
  }

  const deleteFile = (fileName) => {
    fetch(process.env.REACT_APP_API_UPLOAD + `/deleteImportExcel/${fileName}`, {
      method: 'GET'
    })
      .then(async (res) => {
        const resData = await res.json()
        Alert({
          type: 'error',
          title: t('ไม่สามารถลบข้อมูลได้'),
          content: t(resData.message),
          onOk() {}
        })
      })
      .catch((err) => {
        Alert({
          type: 'error',
          title: t('ไม่สามารถลบข้อมูลได้'),
          content: t(err),
          onOk() {}
        })
      })
  }

  const onFinish = () => {
    if (rowError.length > 0) {
      Alert({
        type: 'error',
        title: t('การนำเข้าข้อมูลไม่ถูกต้อง'),
        content: '',
        onOk() {}
      })
    } else {
      Alert({
        type: 'confirm',
        title: t('กดยืนยันเพื่อบันทึกคลังประเมินความเสี่ยง'),
        onOk: async () => {
          await masterCalendarsSavefn({
            variables: {
              inputData: encryptInput({
                mastercalendars: dataCalendar,
                masterCalendarDetails: dataCalendarDetail,
                actions: pageType
              })
            }
          })
        },
        onCancel() {
          return null
        }
      })
    }
  }

  useEffect(() => {
    if (dataSaved) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          deleteFile(dataFileName)
          navigate('/administrator/managecalendar/form/edit', {
            state: {
              refCode: dataSaved.masterCalendarsSave.calendarCode
            }
          })
        }
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(error)),
        onOk() {}
      })
    }
  }, [dataSaved])

  /*
  calendarCode
  calendarYear
  calendarNameTH
  dateType
  dateTypeName
  dateNameTH
  dateAt
  */

  const checkFormatDate = (d) => {
    var date = moment(d, 'YYYY-MM-DD', true)
    return date.isValid()
  }

  useEffect(() => {
    if (dataExcel) {
      const dataShow = []
      const dataDetail = []
      const dataShowError = []
      setRowError([])
      setdataCalendar({})
      dataExcel.map((dt, key) => {
        let dateNameTH = ''
        let dateNameEN = ''
        if (_.isObject(dt.dateNameTH)) {
          dt.dateNameTH.richText.forEach((element) => {
            dateNameTH += element.text
          })
        } else {
          dateNameTH = dt.dateNameTH
        }

        if (_.isObject(dt.dateNameEN)) {
          dt.dateNameEN.richText.forEach((element) => {
            dateNameEN += element.text
          })
        } else {
          dateNameEN = dt.dateNameEN
        }

        !dt.calendarCode
          ? dataShowError.push({ numRow: key + 1, messageError: `ไม่กรอกรหัสปฏิทิน` })
          : !dt.calendarYear
          ? dataShowError.push({ numRow: key + 1, messageError: `ไม่กรอกข้อมูลปี` })
          : !dt.calendarNameTH
          ? dataShowError.push({ numRow: key + 1, messageError: `ไม่กรอกชื่อปฏิทินภาษาไทย` })
          : !dt.dateTypeName
          ? dataShowError.push({ numRow: key + 1, messageError: `ไม่กรอกชื่อประเภทวัน` })
          : !dt.dateNameTH
          ? dataShowError.push({ numRow: key + 1, messageError: `ไม่กรอกชื่อวันภาษาไทย` })
          : !dt.dateAt
          ? dataShowError.push({ numRow: key + 1, messageError: `ไม่กรอกวันที่` })
          : !checkDateType.includes(dt.dateTypeName)
          ? dataShowError.push({ numRow: key + 1, messageError: `กรอกข้อมูลประเภทวันไม่ตามข้อกำหนด` })
          : !checkFormatDate(dt.dateAt)
          ? dataShowError.push({ numRow: key + 1, messageError: `กรอกข้อมูลวันที่ไม่ถูกต้อง` })
          : setdataCalendar({
              calendarCode: dt.calendarCode,
              calendarNameTH: dt.calendarNameTH,
              calendarNameEN: dt.calendarNameEN,
              calendarYear: dt.calendarYear,
              isActive: 1
            })
        //ประเภทวัน 1 = วันหยุดประจำปี , 2 = วันประชุม AC, 3 = วันที่ประชุม MC
        let dateType = 0
        switch (dt.dateTypeName) {
          case 'วันหยุดประจำปี':
            dateType = 1
            break
          case 'วันประชุม AC':
            dateType = 2
            break
          case 'วันที่ประชุม MC':
            dateType = 3
            break
          default:
            dataShowError.push({ numRow: key + 1, messageError: `กรอกข้อมูลประเภทวันไม่ตามข้อกำหนด` })
            break
        }

        dataDetail.push({
          dateNameTH: dateNameTH,
          dateNameEN: dateNameEN,
          dateAt: dt.dateAt, //dateDisplay(dt.dateAt),
          dateType: dateType
        })
        dataShow.push({
          key: key,
          numRow: key + 1,
          calendarCode: dt.calendarCode,
          calendarYear: dt.calendarYear,
          calendarNameTH: dt.calendarNameTH,
          calendarNameEN: dt.calendarNameEN,
          dateType: dt.dateTypeName === 'วันหยุดประจำปี' ? 1 : dt.dateTypeName === 'วันประชุม AC' && 2,
          dateTypeName: dt.dateTypeName,
          dateNameTH: dateNameTH,
          dateNameEN: dateNameEN,
          dateAt: dateDisplay(dt.dateAt)
        })
      })
      setRowError(dataShowError)
      setdataList(dataShow)

      setdataCalendarDetail(dataDetail)
    }
  }, [dataExcel])

  return (
    <React.Fragment>
      {loadingSaved && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('จัดการข้อมูลปฏิทิน')} />
      <CardNew topic={t('นำเข้าข้อมูลวัน')} icon="Plus" toggledrop={'false'}>
        <Row gutter={[24, 24]} style={{ marginTop: 24, marginBottom: 24 }} align="center">
          <Col md={6} align="left">
            <Label type="tab-header-inactive">{t('แบบฟอร์มนำเข้า')}</Label>
          </Col>
          <Col md={14} align="left">
            <ButtonDowloadsExcel file={{ filePath: '', fileName: 'Template_Import_Calendar.xlsx' }}>
              {t('ดาวน์โหลด Template .XLSX')}
            </ButtonDowloadsExcel>
          </Col>
          <Col md={6} align="left">
            <Label type="tab-header-inactive">{t('ไฟล์นำเข้า')}</Label>
          </Col>
          <Col md={14} align="left">
            <UploadExcel
              dataFiles={(e) => {
                setDataExcel(e.data)
                setFileName(e.fileName)
              }}
            />
          </Col>
        </Row>
        {/* หมายเหตุ */}
        <Row className="py-1 justify-content-md-center">
          <Col md="2" className="m-auto" />
          <Col md="8">
            <ul className="pl-2">
              <li className="pb-1">
                <Label type="header-table" need="need">
                  {t('ไฟล์ที่นำเข้าจะต้องมีรูปแบบตามตัวอย่างไฟล์ที่กำหนดไว้เท่านั้น')}
                </Label>
              </li>
              <li className="pb-1">
                <Label type="header-table" need="need">
                  {t('ไฟล์ที่นำเข้าจะต้องเป็น .xlsx จาก Templete')}
                </Label>
              </li>
              <li className="pb-1">
                <Label type="header-table" need="need">
                  {t('ข้อมูลคอลัมน์ dateTypeName ต้องเป็น วันหยุดประจำปี, วันประชุม AC หรือ วันที่ประชุม MC เท่านั้น')}
                </Label>
              </li>
            </ul>
          </Col>
        </Row>
        <Row gutter={[24, 24]} style={{ marginTop: 24, marginBottom: 24 }} align="center">
          <Col>
            <Button
              type="primary"
              width={141}
              roles={{ type: 'add', menu: menu }}
              onClick={() => {
                onFinish()
              }}
            >
              {t('นำเข้าข้อมูล')}
            </Button>
          </Col>
          <Col>
            <Button
              type="back"
              onClick={() => {
                clearInputType()
              }}
            >
              {t('ล้างข้อมูล')}
            </Button>
          </Col>
          <Col>
            <Button
              type="back"
              onClick={() => {
                navigate('/administrator/managecalendar')
              }}
            >
              {t('ยกเลิก')}
            </Button>
          </Col>
        </Row>
        <Row style={{ display: dataExcel.length > 0 ? '' : 'none' }} gutter={[24, 24]}>
          <Col {...Responsive}>
            <Row style={{ marginTop: 45, marginBottom: 10 }}>
              <Col>
                <Label type="sub-header">{t('ผลประมวณผลไฟล์นำเข้า')}</Label>
              </Col>
            </Row>
            <Row style={{ marginTop: 45, marginBottom: 10 }}>
              <Col>
                {rowError.length > 0 &&
                  rowError.map((numRow, i) => (
                    <Label key={'error-messageError' + i} type="error">
                      {t('แถวที่') + ' ' + numRow.numRow + ' ' + t(numRow.messageError)}
                    </Label>
                  ))}
              </Col>
            </Row>
            <Row style={{ marginTop: 45, marginBottom: 10 }}>
              <Col>
                <RowStyle>
                  <Datatable
                    columns={ColumnsImport()}
                    data={dataList}
                    isScoll={{ x: true, y: true }}
                    paginationCustom={false}
                    searchCustom={false}
                    rowClassName={(record) => {
                      if (
                        !record.calendarCode ||
                        !record.calendarYear ||
                        !record.calendarNameTH ||
                        !record.dateTypeName ||
                        !record.dateNameTH ||
                        !record.dateAt ||
                        !checkDateType.includes(record.dateTypeName) ||
                        _.filter(rowError, function (o) {
                          return record.numRow === o.numRow
                        }).length > 0
                      ) {
                        return 'table-row-color'
                      } else {
                        return ''
                      }
                    }}
                  />
                </RowStyle>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardNew>
    </React.Fragment>
  )
}
