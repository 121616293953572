import React from 'react'

import { Row, Col, Form, Anchor } from 'antd'

import { ButtonNew } from '../../../components/button/Button'
import { Select } from '../../../components/select/Select'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { codeBasic, codeSelectValidate } from './SelectCode'
import SelectPersonnel from '../../../components/inputfromapi/selectpersonnels/SelectPersonnels'

const SelectIndex = ({ menu }) => {
  const [form] = Form.useForm()

  const example_Data = [
    { label: 'Jack', value: '01' },
    { label: 'Lucy', value: '02' },
    { label: 'Adam', value: '03' },
    { label: 'เทส4', value: '04', disabled: true }, // option disable
    { label: 'test5', value: '05' },
    { label: 'test6', value: '06' },
  ]

  const handleChange = (value) => {
    form.setFields([
      {
        name: 'form_select',
        value: value,
      },
      {
        name: 'test_error',
        errors: ['Show error when select '],
      },
    ])
  }

  return (
    <React.Fragment>
      <Anchor affix={false}>
        <Form form={form} name={'select_form'} className="site-layout-background">
          <Row>
            <Col span={9}>
              <Form.Item
                name={'validate_select'}
                rules={[
                  {
                    required: true,
                    message: 'Please select item!',
                  },
                ]}
              >
                <Select
                  title={'Validate Select'}
                  data={example_Data}
                  placeholder={'Validate select...'}
                  onChange={handleChange}
                  scrollableId="select_form"
                />
              </Form.Item>
            </Col>
            <Col span={1}></Col>
            <Col span={5}>
              <ButtonNew type="primary" htmlType="submit" roles={{ type: 'add', menu: menu }}>
                Submit
              </ButtonNew>
            </Col>
          </Row>

          <Row>
            <Col span={10}>
              <Form.Item name={'SelectPersonnelType'}>
                <SelectPersonnel
                  title={'SelectPersonnelType'}
                  formname="select_form"
                  placeholder={'เลือก'}
                  handleChange={(e) => {
                    console.log('e: ', e)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <div style={{ marginBottom: '20px' }} />
          <CodeBlock code={codeSelectValidate} />
          <div style={{ marginBottom: '20px' }} />
          <Row>
            <Col span={5}>
              <Select
                name={'select'}
                title={'Basic'}
                data={example_Data}
                placeholder={'Basic select...'}
                onChange={handleChange}
                scrollableId="select_form"
              />
            </Col>
            <Col span={1}></Col>
            <Col span={9}>
              <Select
                title={'Default'}
                data={example_Data}
                defaultValue={example_Data[2].value}
                placeholder={'Default select...'}
                onChange={handleChange}
                scrollableId="select_form"
              />
            </Col>
          </Row>

          <div style={{ marginBottom: '20px' }} />

          <Row>
            <Col span={6}>
              <Select title={'Loading'} placeholder={'Loading select...'} loading onChange={handleChange} />
            </Col>
            <Col span={1}></Col>
            <Col span={9}>
              <Select
                title={'Search'}
                data={example_Data}
                placeholder={'Search select...'}
                showSearch
                // defaultValue={'02'}
                onChange={handleChange}
                scrollableId="select_form"
                filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              />
            </Col>
          </Row>
          <div style={{ marginBottom: '20px' }}></div>

          <Row>
            <Col span={9}>
              <Select
                title={'Disabled ดิส'}
                data={example_Data}
                placeholder={'Disabled select...'}
                defaultValue={'02'}
                disabled
                onChange={handleChange}
                scrollableId="select_form"
              />
            </Col>
            <Col span={1}></Col>
            <Col span={9}>
              <Select
                title={'Borderless'}
                data={example_Data}
                placeholder={'Borderless select...'}
                bordered={false}
                onChange={handleChange}
                scrollableId="select_form"
              />
            </Col>
          </Row>
          <div style={{ marginBottom: '20px' }}></div>

          <Row>
            <Col span={9}>
              <Select
                title={'Multiple '}
                data={example_Data}
                placeholder={'Multiple select...'}
                multiple
                onChange={handleChange}
                scrollableId="select_form"
              />
            </Col>
            <Col span={1}></Col>
            <Col span={9}>
              <Select
                title={'Multiple Default'}
                defaultValue={['02', '05']}
                data={example_Data}
                placeholder={'Multiple default select...'}
                multiple
                onChange={handleChange}
                scrollableId="select_form"
              />
            </Col>
          </Row>
          <Row>
            <Col span={5}>
              <div style={{ marginBottom: '20px' }}></div>
            </Col>
          </Row>
          <CodeBlock code={codeBasic} />
        </Form>
      </Anchor>
    </React.Fragment>
  )
}

export default SelectIndex
