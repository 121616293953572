import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { Datatable } from '../../../../../components/datatable/Datatable.jsx'
import { ButtonNew as Button } from '../../../../../components/button/Button'
import { useNavigate } from 'react-router-dom'
import { GET_AUDITOPENS_ADDDOC_PAGINATION } from '../../graphql/Query'
import { AUDITOPENS_ADDDOC_DELETE } from '../../graphql/Mutation'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Alert } from '../../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { displayText } from '../../../../../utilitys/helper'
import { dateDisplay } from '../../../../../utilitys/dateHelper'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'
import { getSegmentUrl } from '../../../../../utilitys/helper'

const AuditOpensAdddocList = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const typeMain = getSegmentUrl(4)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [dataAuditOpensAddDocTabPaginationFn, dataAuditOpensAddDocTabPagination] = useLazyQuery(
    GET_AUDITOPENS_ADDDOC_PAGINATION,
  )
  const [auditOpensAddDocTabDeleteFn, auditOpensAddDocTabDelete] = useMutation(AUDITOPENS_ADDDOC_DELETE)
  useEffect(() => {
    if (auditOpensAddDocTabDelete.data) {
      let response = auditOpensAddDocTabDelete?.data?.auditOpensAddDocTabDelete
      if (response) {
        showAlertSuccess()
      }
    }
    if (auditOpensAddDocTabDelete.error) {
      showAlertDeleteError(displayError(auditOpensAddDocTabDelete.error))
    }
  }, [auditOpensAddDocTabDelete.data])

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showConfirmDelete = (openDocId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        auditOpensAddDocTabDeleteFn({
          variables: {
            input: encryptInput({ openDocId: openDocId }),
          },
          fetchPolicy: 'no-cache',
        })
      },
      onCancel() {},
    })
  }

  const showAlertDeleteError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถลบข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showAlertSuccess = () => {
    Alert({
      type: 'success',
      title: t('ลบสำเร็จ'),
      onOk() {
        dataAuditOpensAddDocTabPagination.refetch()
      },
    })
  }

  useEffect(() => {
    dataAuditOpensAddDocTabPaginationFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          filters: {
            openId: props.refCode,
          },
          pagination: { limit, page },
          sort: [],
        }),
      },
    })
  }, [props.typeForm])
  useEffect(() => {
    if (dataAuditOpensAddDocTabPagination.data) {
      let response = dataAuditOpensAddDocTabPagination?.data?.dataAuditOpensAddDocTabPagination
      if (response) {
        setCount(response.count)
        setDataLists(
          response.result.map((item, i) => {
            return {
              key: item.openDocId,
              no: (page - 1) * limit + i + 1,
              organization: displayText(item.organizationNameTH, item.organizationNameEN),
              docType: item.openDocType === 1 ? 'ขอเอกสารเพิ่ม' : 'ตามเอกสาร',
              startDate: dateDisplay(item.dateStart),
              endDate: dateDisplay(item.dateEnd),
              extra: (
                <Button
                  type="flagOutlinedInTableBlue"
                  roles={{ type: 'send', menu: props.menu }}
                  disabled={props.typeForm === 'view' ? true : false}
                  onClick={() => {
                    navigate('/auditoperations/auditopens/adddoc/' + props.typeForm + '/petition', {
                      state: { refCode: item.openDocId },
                    })
                  }}
                >
                  {t('แจ้งขอ')}
                </Button>
              ),
              action: (
                <div style={{ textAlign: 'center' }}>
                  <ButtonGroup
                    menu={props.menu}
                    icons={[
                      {
                        type: 'view',
                        onClick: () => {
                          navigate('/auditoperations/auditopens/adddoc/' + props.typeForm + '/formdoc/view', {
                            state: { refCode: item.openId + '_' + item.openDocId },
                          })
                        },
                      },
                    ].concat(
                      props.typeForm !== 'view'
                        ? [
                            {
                              type: 'edit',
                              onClick: () => {
                                navigate('/auditoperations/auditopens/adddoc/' + props.typeForm + '/formdoc/edit', {
                                  state: { refCode: item.openId + '_' + item.openDocId },
                                })
                              },
                            },
                            {
                              type: 'delete',
                              onClick: () => {
                                showConfirmDelete(item.openDocId)
                              },
                            },
                          ]
                        : [],
                    )}
                  />
                </div>
              ),
            }
          }),
        )
      }
    }
    if (dataAuditOpensAddDocTabPagination.error) {
      showAlertLoadDataError(displayError(dataAuditOpensAddDocTabPagination.error))
    }
  }, [dataAuditOpensAddDocTabPagination.data])

  const columns = [
    {
      title: <div style={{ textAlign: 'center' }}>{t('ลำดับ')}</div>,
      dataIndex: 'no',
      width: '10%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('หน่วยรับตรวจ')}</div>,
      dataIndex: 'organization',
      width: '20%',
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('ประเภทคำขอเอกสาร')}</div>,
      dataIndex: 'docType',
      width: '20%',
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('วันที่เริ่มต้น')}</div>,
      dataIndex: 'startDate',
      width: '15%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('วันที่สิ้นสุด')}</div>,
      dataIndex: 'endDate',
      width: '15%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: '',
      dataIndex: 'extra',
      width: '10%',
    },
    {
      title: '',
      dataIndex: 'action',
      width: '10%',
    },
  ]
  return (
    <>
      {(dataAuditOpensAddDocTabPagination.loading || auditOpensAddDocTabDelete.loading) && <SpinnersNew />}

      <Row>
        <Col md={24}>
          <Datatable
            columns={columns}
            data={dataLists}
            handleTableChange={() => {}}
            paginationCustom={true}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            searchCustom={true}
            total={count}
            enableScroll={{ x: false, y: true }}
            scroll={{ x: '100%', y: false }}
            pageSize={limit}
            page={page}
            btnAdd={
              <>
                <Button
                  disabled={props.typeForm === 'view' ? true : false}
                  type="plusTableBorderPrimary"
                  roles={{ type: typeMain, menu: props.menu }}
                  style={{ marginRight: '12px' }}
                  onClick={() => {
                    navigate('/auditoperations/auditopens/adddoc/edit/formdoc/add', {
                      state: { refCode: props.refCode },
                      // openId
                    })
                  }}
                >
                  {t('เพิ่มคำขอเอกสารเพิ่มเติม')}
                </Button>
              </>
            }
          />
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="center" style={{ marginTop: '50px' }}>
        <Col>
          <Button
            type="cancel"
            onClick={() => {
              navigate('/auditoperations/auditopens')
            }}
          >
            {t('ยกเลิก')}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default AuditOpensAdddocList
