import { gql } from '@apollo/client'

export const SELECT_PERSONNEL = gql`
  query PersonnelSelect($input: SelectPersonnelsInput!) {
    personnelSelect(input: $input) {
      personnelCode
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
      levelCode
      organizationNameTH
      organizationNameEN
      organizationCode
      telephone
      mobile
      email
      positionCode
      positionNameTH
      positionNameEN
    }
  }
`
