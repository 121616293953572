import React from 'react'
import { ApproveSubMenuStyle } from './ApproveSubHeaderStyle.js'
import { Row, Col } from 'antd'
import { IconNew as Icon } from '../../../../../components/icon/Icon'
//'AlignJustify'
//t('รายการโครงการตรวจสอบ')
export default function ApproveSubHeader(props) {
  const { icons, topic, topiccolor } = props
  return (
    <ApproveSubMenuStyle>
      <div>
        <Row style={{ marginTop: 45, marginBottom: 10 }} className="approve-submenu-head">
          <Col>
            {icons && (
              <span className="approve-submenu-icon">
                <Icon icon={icons} />
              </span>
            )}
            <span className="approve-submenu-text" style={topiccolor ? { color: topiccolor } : null}>
              {topic}
            </span>
          </Col>
        </Row>
      </div>
    </ApproveSubMenuStyle>
  )
}
