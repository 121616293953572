import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col, Space, Input } from 'antd'
import { CardNew } from '../card/Card'
import { InputNew, InputNumberNew } from '../input/Input'
import { Select } from '../select/Select'
import { ButtonNew } from '../button/Button'
import { Datepicker } from '../datepicker/Datepicker'
import { Timepicker } from '../timepicker/Timepicker'
import { yearBoundaryFn } from '../../utilitys/dateHelper'

export const Filter = (props) => {
  const { inputList } = props

  const formName = props.formName ? props.formName : 'filter'

  const [form] = Form.useForm()
  const { t } = useTranslation()

  const [isSelectYear, setIsSelectYear] = useState([])

  useEffect(() => {
    const yearBoundary = yearBoundaryFn(10)
    setIsSelectYear(
      yearBoundary.length &&
        yearBoundary.map((item) => {
          return { label: item + '', value: item + '' }
        }),
    )
  }, [])

  const onFinish = (values) => props.onFinishFn(values)

  const renderByType = (item, index) => {
    switch (item.type) {
      case 'text':
        return (
          <Col key={index} {...item?.col}>
            <label htmlFor={item?.name}>{item?.label}</label>
            <Form.Item name={item?.name}>
              <InputNew placeholder={item?.placeholder} />
            </Form.Item>
          </Col>
        )
      case 'number':
        return (
          <Col key={index} {...item?.col}>
            <label htmlFor={item?.name}>{item?.label}</label>
            <Form.Item id={`number${index}`} name={item?.name}>
              <InputNumberNew placeholder={item?.placeholder} />
            </Form.Item>
          </Col>
        )
      case 'select':
        return (
          <Col key={index} {...item?.col}>
            <label htmlFor={item?.name}>{item?.label}</label>
            <Form.Item name={item?.name}>
              <Select
                data={item?.defaultValues ? item?.defaultValues : isSelectYear}
                placeholder={item?.placeholder}
                scrollableId={formName}
              />
            </Form.Item>
          </Col>
        )
      case 'datepicker':
        return (
          <Col key={index} {...item?.col}>
            <Form.Item hidden={true} name={`${item?.name}_${index}`}>
              <Input />
            </Form.Item>
            <label htmlFor={`${item?.name}_${index}`}>{item?.label}</label>
            <Form.Item name={`${item?.name}_${index}`}>
              <Datepicker
                setValueDateFn={(data) => form.setFieldsValue({ [item?.name]: data })}
                placeholder={item?.placeholder}
              />
            </Form.Item>
          </Col>
        )
      case 'timepicker':
        return (
          <Col key={index} {...item?.col}>
            <Form.Item hidden={true} name={`${item?.name}_${index}`}>
              <Input />
            </Form.Item>
            <label htmlFor={`${item?.name}_${index}`}>{item?.label}</label>
            <Form.Item name={`${item?.name}_${index}`}>
              <Timepicker
                onChangeTime={(data) => form.setFieldsValue({ [item?.name]: data })}
                placeholder={item?.placeholder}
                notDateNow={true}
              />
            </Form.Item>
          </Col>
        )
      case 'tag':
        return (
          <Col key={index} {...item?.col}>
            <label htmlFor={item?.name}>{item?.label}</label>
            <Form.Item name={item?.name}>
              <Select
                title={''}
                data={item?.defaultValues ? item?.defaultValues : isSelectYear}
                placeholder={item?.placeholder}
                multiple
                scrollableId={formName}
              />
            </Form.Item>
          </Col>
        )
      default:
        return
    }
  }

  return (
    <div>
      <CardNew topic={t('Search')} icon="AlignJustify" toggledrop="true">
        <Form form={form} onFinish={onFinish} name={formName}>
          <Row gutter={[16, 8]}>{inputList.length && inputList.map((item, index) => renderByType(item, index))}</Row>
          <Row>
            <Col sm={24} md={24} lg={24} align="middle">
              <Form.Item>
                <Space size={[8, 8]} wrap>
                  <ButtonNew type="primary" roles={{ type: 'add', menu: 'example' }} htmlType="submit">
                    {t('บันทึก')}
                  </ButtonNew>
                  &nbsp;
                  <ButtonNew type="clear" onClick={() => form.resetFields()}>
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Space>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CardNew>
    </div>
  )
}

Filter.propTypes = {
  formName: PropTypes.string,
  inputList: PropTypes.array.isRequired,
  onFinishFn: PropTypes.func.isRequired,
}
