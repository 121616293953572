import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/alert/Alert'

import { ButtonNew } from '../../../components/button/Button'
import { Datatable } from '../../../components/datatable/Datatable'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayError, displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import ExportModal from './components/Modal/ExportModal'
import { DELETE_ACTION_PLAN } from './graphql/Mutation'

import { GET_LIST_ACTION_PLAN_PAGINATION } from './graphql/Query'
import { Columns } from './utils/ActionPlanListColumn'

const Responsive = {
  md: 24,
  lg: 24,
}

const ActionPlanList = ({ type_action: mainType_action = 'edit', menu, activeKey = '1' }) => {
  if (activeKey !== '1') return <></>
  const { t } = useTranslation()
  const [user] = useDecodeUser()
  const navigate = useNavigate()

  const [dataRow, setdataRow] = useState([])
  const [paginate, setPaginate] = useState({ page: 1, limit: 10 })
  const [sort, setsort] = useState([{ fieldSort: 'projectCode', sortType: 'ASC' }])

  const [
    filters,
    //  setfilters
  ] = useState({})
  const [count, setcount] = useState(0)

  useEffect(() => {
    if (sort && paginate && filters && user) fncGetList()
  }, [paginate.page, paginate.limit, sort, filters, user])

  const [callList, { loading: loadingList, error: errorList, data: dataList }] = useLazyQuery(
    GET_LIST_ACTION_PLAN_PAGINATION,
  )

  useEffect(() => {
    if (dataList) {
      let { result, count } = dataList.getListActionPlanPagination
      let dataTemp = result.map((item, key) => {
        let projectName = item.projectCode + ' : ' + displayText(item.projectNameTH, item.projectNameEN)
        let projectRefName = displayText(item.projectRefNameTH, item.projectRefNameEN)
        let personnelName = displayText(item.personnelNameTH, item.personnelNameEN)
        let organizationName = displayText(item.organizationNameTH, item.organizationNameEN)
        return {
          ...item,
          key: key + 1,
          projectName,
          projectRefName,
          personnelName,
          organizationName,
          dateStart: dateDisplay(item.dateStart),
          dateEnd: dateDisplay(item.dateEnd),
          approveStatusCode: displayText(item.approveStatusNameTH, item.approveStatusNameEN),
        }
      })
      setdataRow(dataTemp)
      setcount(count)
    }
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorList)),
      })
    }
  }, [dataList])

  const fncGetList = () => {
    const dataInput = {
      sort: sort,
      filters: { ...filters, personnelCode: user.pCode },
      pagination: {
        page: paginate.page,
        limit: paginate.limit,
      },
    }
    callList({
      variables: { input: encryptInput(dataInput) },
    })
  }

  const [callDelete, { loading: loadingDelete, error: errorDelete, data: dataDelete }] = useMutation(DELETE_ACTION_PLAN)

  useEffect(() => {
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorDelete)),
      })
    }
  }, [errorDelete])

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        content: t(dataDelete.message),
        onOk() {
          fncGetList()
        },
      })
    }
  }, [dataDelete])

  const fncOnpressView = (data) => {
    navigate('/auditoperations/actionplan/form', {
      state: {
        refCode: data.actionPlanId,
        type_action: 'view',
        projectCode: data.projectCode,
        auditTeamCode: data.auditTeamCode,
      },
    })
  }

  const fncOnpressEdit = (data) => {
    navigate('/auditoperations/actionplan/form', {
      state: {
        refCode: data.actionPlanId,
        type_action: 'edit',
        projectCode: data.projectCode,
        auditTeamCode: data.auditTeamCode,
      },
    })
  }

  const fncOnpressDelete = (data) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ?'),
      onOk() {
        const dataInput = {
          actionPlanId: data.actionPlanId,
        }
        callDelete({
          variables: { input: encryptInput(dataInput) },
        })
      },
      onCancel() {},
    })
  }

  //=========================== Modal ===========================
  const [modalVisible, setmodalVisible] = useState(false)
  const [actionPlanId, setactionPlanId] = useState()

  const fncOnpressExport = (data) => {
    setactionPlanId(data.actionPlanId)
    setmodalVisible(true)
  }

  //=========================== =========================== ====

  return (
    <Row>
      <Col {...Responsive}>
        <ExportModal
          actionPlanId={actionPlanId}
          visible={modalVisible}
          onModalClose={() => {
            setmodalVisible(false)
          }}
        />
        <Datatable
          columns={Columns({
            fncOnpressExport,
            fncOnpressView,
            fncOnpressEdit,
            fncOnpressDelete,
            mainType_action,
            menu,
          })}
          data={dataRow}
          total={count}
          searchCustom={true}
          pageSize={paginate.limit}
          page={paginate.page}
          isLoading={loadingList || loadingDelete}
          paginationCustom={true}
          scroll={{ x: 1448 }}
          onChangePagination={({ page, pageSize }) => {
            setPaginate({ page, limit: pageSize })
          }}
          handleTableChange={(e) => {
            const reNewField = [
              { projectName: 'projectCode' },
              { projectRefName: 'projectRefCode' },
              { dateStart: 'dateStart' },
              { dateEnd: 'dateEnd' },
              { personnelName: 'approver' },
              { approveStatusCode: 'approveStatusCode' },
            ]

            setsort(handleSort(e?.sorter, reNewField))
          }}
          btnAdd={
            mainType_action !== 'view' ? (
              <ButtonNew
                onClick={() => {
                  navigate('/auditoperations/actionplan/form', {
                    state: { refCode: null, type_action: 'add' },
                  })
                }}
                type="plusTableBorderPrimary"
                roles={{ type: 'add', menu: menu }}
              >
                {t('เพิ่มแผนปฎิบัติงาน')}
              </ButtonNew>
            ) : (
              <></>
            )
          }
        />
      </Col>
    </Row>
  )
}

export default ActionPlanList
