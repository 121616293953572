import { gql } from '@apollo/client'

export const GETRISKMODELSPAGINATION = gql`
  query getRiskModelsPagination($input: RiskModelsPaginationInput!) {
    getRiskModelsPagination(input: $input) {
      result {
        RowData
        riskModelCode
        riskModelTH
        riskModelEN
        weight
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      count
      page
      limit
    }
  }
`

export const GETRISKMODELS = gql`
  query getRiskModels($inputData: RiskModelsInput!) {
    getRiskModels(inputData: $inputData) {
      riskModelId
      riskModelCode
      riskModelTH
      riskModelEN
      weight
      riskModelGroup
      controlOrganization
      organizationCode
      organizationName
      isActive
    }
  }
`

export const GETRISKMODELORGANIZATIONPAGINATION = gql`
  query getRiskModelOrganizationPagination($input: RiskModelsInput!) {
    getRiskModelOrganizationPagination(input: $input) {
      riskModelOrgId
      riskModelCode
      organizationCode
      organizationNameTH
      organizationNameEN
      organizationLevelNameTH
      organizationLevelNameEN
      fullNameTH
      fullNameEN
    }
  }
`

export const GETRISKMODELMASTERORGANIZATION = gql`
  query getMasterOrganizationByRiskModel($input: RiskModelsOztFilterInput!) {
    getMasterOrganizationByRiskModel(input: $input) {
      organizationCode
      organizationNameTH
      organizationNameEN
      organizationLevelCode
      organizationParentCode
      organizationChecked
      isActive
      organizationLevelNameTH
      organizationLevelNameEN
      fullNameTH
      fullNameEN
    }
  }
`

export const GETRISKMODELFACTOR = gql`
  query getRiskModelFactor($inputData: RiskModelFactorInput!) {
    getRiskModelFactor(inputData: $inputData) {
      riskModelFacId
      riskModelCode
      riskModelFacType
      riskFactorCode
      rateFacGroupCode
      weight
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
      riskFactorNameTH
      riskFactorNameEN
      rateFacGroupNameTH
      rateFacGroupNameEN
    }
  }
`

export const GETRSKFACTORBYRSKMODELS = gql`
  query getRskFactorByRskModels($inputData: RskFactorByRskModelsInput!) {
    getRskFactorByRskModels(inputData: $inputData) {
      riskFactorId
      riskFactorCode
      riskFactorNameTH
      riskFactorNameEN
      riskType
      riskFormulaCode
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`

export const GETRATERISKFACTORGROUPBYRSKMODELS = gql`
  query getRateRiskFactorGroupByRskModels {
    getRateRiskFactorGroupByRskModels {
      rateFacGroupId
      rateFacGroupCode
      rateFacGroupNameTH
      rateFacGroupNameEN
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`
