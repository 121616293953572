import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import { CardNew as Card } from '../../../../components/card/Card'
import { ButtonNew } from '../../../../components/button/Button'
import { LabelNew } from '../../../../components/label/Label'
import { InputNew } from '../../../../components/input/Input'
import SelectOrganization from '../../../../components/inputfromapi/selectorganization/SelectOrganization.jsx'
import SelectProjectRefer from '../../../../components/inputfromapi/selectprojectrefer/SelectProjectRefer'

const CompleteResultReportFilter = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { menu } = props
  const formName = 'fillter'

  const Responesive = {
    inputFilter: {
      md: 12,
      lg: 8,
    },
    buttonFilter: {
      md: 24,
      lg: 24,
    },
  }

  const onFinish = (values) => {
    props.getValue({ ...values })
  }

  const onClear = () => {
    form.resetFields()
    props.getValue({})
  }
  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((er) => {
      console.log(er)
    })
  }

  return (
    <>
      <Card topic={t('Filters')} icon="Filter" toggledrop={'false'}>
        <Row style={{ marginTop: 24 }}>
          <Col sm={24} md={24} lg={24}>
            <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} name={formName} layout="vertical">
              <Row gutter={[16, 8]}>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="projectRefNameTH"
                    label={<LabelNew type="tab-header-inactive">{t('แหล่งที่มาของโครงการ')}</LabelNew>}
                  >
                    <SelectProjectRefer
                      filters={{ isActive: 1 }}
                      formname={formName}
                      placeholder={t('เลือกแหล่งที่มาของโครงการ')}
                      handleChange={(value) => {
                        form.setFieldsValue({ projectRefNameTH: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="projectName"
                    label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุชื่อโครงการ')} />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="organizationNameTH"
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</LabelNew>}
                  >
                    <SelectOrganization
                      placeholder={t('ระบุหน่วยงานที่รับผิดชอบ')}
                      formname={formName}
                      handleChange={(e) =>
                        form.setFieldsValue({
                          organizationNameTH: e?.value,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="organizationCheck"
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยรับตรวจ')}</LabelNew>}
                  >
                    <SelectOrganization
                      placeholder={t('ระบุหน่วยงานที่รับตรวจ')}
                      filters={{ organizationChecked: 3 }}
                      formname={formName}
                      handleChange={(e) =>
                        form.setFieldsValue({
                          organizationCheck: e?.value,
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="fullName"
                    label={<LabelNew type="tab-header-inactive">{t('ผู้รับผิดชอบหลัก')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุผู้รับผิดชอบหลัก')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew type="search" roles={{ type: 'view', menu: menu }} htmlType="submit">
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew
                    type="clear"
                    menu={menu}
                    onClick={() => {
                      onClear()
                    }}
                  >
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default CompleteResultReportFilter
