import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { Form, Col, Row, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { CREATE_UPDATE_AUDIT_RCMS } from '../../graphql/Mutation'
import { GET_AUDIT_RCMS_BY_ID, GET_AUDIT_PROJECT_ORGS } from '../../graphql/Query'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { LabelNew } from '../../../../../components/label/Label'
import { Select } from '../../../../../components/select/Select'
import { CardNew } from '../../../../../components/card/Card'
import { ButtonNew } from '../../../../../components/button/Button'
import { Alert } from '../../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { RadioGroup, Radio } from '../../../../../components/radio/Radio'
import { displayText, displayError } from '../../../../../utilitys/helper'
import { encryptInput } from '../../../../../utilitys/crypto'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'
import { OrganizationStyle } from '../../css/GeneralStyle'
import CheckerOrganizationModal from '../../../../components/modalformapi/modalCheckerOrganization/CheckerOrganizationModal'
import SelectActionAuditPlans from '../../../../../components/inputfromapi/selectactionauditplan/SelectActionAuditPlan'
import SelectPersonnel from '../../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import SelectOperationStatus from '../../../../../components/inputfromapi/selectoperationstatus/SelectOperationStatus'
import OrganizationGroup from '../card/OrganizationGroup'

const GeneralTab = (props) => {
  const formname = 'generalForm'
  const responsive = { sm: 24, md: 24, lg: 22, xl: 22 }
  const wrapper = { md: 24, lg: 24, xl: 24 }
  const subWrapper = { md: 18, lg: 18, xl: 18 }

  const { menu } = props

  const navigate = useNavigate()
  const { state } = useLocation()
  const { t } = useTranslation()
  const [user] = useDecodeUser()
  const [form] = Form.useForm()

  if (!state) return <Navigate to={'/auditoperations/racmatrix'} />

  const [riskModelName, setRiskModelName] = useState([])
  const [modal, setModal] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [orgListError, setOrgListError] = useState(false)
  const [viewAction, setViewAction] = useState(false)
  const [nonePersonnelCode, setNonePersonnelCode] = useState(null)
  const [projectCode, setProjectCode] = useState(null)
  const [isCreated, setIsCreated] = useState(false)

  const [createUpdateAuditRCMsFn, createUpdateAuditRCMs] = useMutation(CREATE_UPDATE_AUDIT_RCMS)
  const [getAuditRCMFn, getAuditRCM] = useLazyQuery(GET_AUDIT_RCMS_BY_ID)
  const [getAuditProjectOrgsFn, getAuditProjectOrgs] = useLazyQuery(GET_AUDIT_PROJECT_ORGS)

  useEffect(() => {
    if (dataLists.length) setOrgListError(false)
  }, [dataLists])

  useEffect(() => {
    form.setFieldsValue({ operationStatusCode: '10' })

    if (state?.typeAction === 'view') setViewAction(true)
    if (state?.typeAction === 'edit' || state?.typeAction === 'view')
      getAuditRCMFn({ variables: { rcmId: encryptInput(state?.rcmId) } })
  }, [state?.typeAction])

  useEffect(() => {
    if (projectCode && state?.typeAction === 'add') {
      getAuditProjectOrgsFn({ variables: { projectCode: encryptInput(projectCode) } })
    }
  }, [projectCode, state?.typeAction])

  useEffect(() => {
    if (getAuditProjectOrgs?.data) {
      const response = getAuditProjectOrgs?.data?.getAuditProjectOrgs

      const ansOrgs = _.map(response, (d) => {
        return {
          key: uuidv4(),
          organizationId: d.organizationId,
          organizationCode: d.organizationCode,
          organizationName: displayText(d.organizationNameTH, d.organizationNameEN),
          organizationLevelName: displayText(d.organizationLevelNameTH, d.organizationLevelNameEN),
          personnelCode: d.personnelCode,
          personnelName: displayText(d.personnelNameTH, d.personnelNameEN),
          organizationLevelCode: d.organizationLevelCode,
          deleteId: d.organizationCode,
          organizationNameEN: d.organizationNameEN,
          organizationNameTH: d.organizationNameTH,
        }
      })
      setDataLists(ansOrgs)
    }

    if (getAuditProjectOrgs?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditProjectOrgs?.error)),
        onOk() {},
      })
    }
  }, [getAuditProjectOrgs?.data])

  useEffect(() => {
    if (state?.typeAction === 'add') setIsCreated(true)

    if (getAuditRCM?.data) {
      const getAuditRCMs = getAuditRCM?.data?.getAuditRCMsById?.auditRCMs
      const getAuditRCMOrgs = getAuditRCM?.data?.getAuditRCMsById?.AuditRCMOrgs

      if (user?.userId && user?.userId + '' === getAuditRCMs?.createdBy + '') setIsCreated(true)

      setRiskModelName([
        {
          value: getAuditRCMs?.riskModelCode,
          label: displayText(getAuditRCMs.riskModelTH, getAuditRCMs.riskModelEN),
        },
      ])

      form.setFieldsValue({
        isActive: getAuditRCMs?.isActive,
        operationStatusCode: getAuditRCMs?.operationStatusCode,
        personnelApprove: getAuditRCMs?.personnelApprove,
        personnelMaker: getAuditRCMs?.personnelMaker,
        personnelReview: getAuditRCMs?.personnelReview,
        projectCode: getAuditRCMs?.projectCode,
        rcmObjective: getAuditRCMs?.rcmObjective,
        rcmScope: getAuditRCMs?.rcmScope,
        riskProcess: getAuditRCMs?.riskProcess,
        riskProcessSub: getAuditRCMs?.riskProcessSub,
      })

      if (getAuditRCMOrgs && getAuditRCMOrgs.length) {
        const ansOrgs = _.map(getAuditRCMOrgs, (d) => {
          return {
            key: uuidv4(),
            organizationId: d.organizationId,
            organizationCode: d.organizationCode,
            organizationName: displayText(d.organizationNameTH, d.organizationNameEN),
            organizationLevelName: displayText(d.organizationLevelNameTH, d.organizationLevelNameEN),
            personnelCode: d.personnelCode,
            personnelName: displayText(d.personnelNameTH, d.personnelNameEN),
            organizationLevelCode: d.organizationLevelCode,
            deleteId: d.organizationCode,
            organizationNameEN: d.organizationNameEN,
            organizationNameTH: d.organizationNameTH,
          }
        })
        setDataLists(ansOrgs)
      }
    }

    if (getAuditRCM?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditRCM?.error)),
        onOk() {},
      })
    }
  }, [getAuditRCM?.data, user?.userId, state?.typeAction])

  useEffect(() => {
    if (createUpdateAuditRCMs?.data) {
      const response = createUpdateAuditRCMs?.data?.createUpdateAuditRCMs
      Alert({
        type: 'success',
        title: t(state?.typeAction === 'add' ? 'บันทึกสำเร็จ' : 'แก้ไขสำเร็จ'),

        onOk() {
          navigate('/auditoperations/racmatrix/tab/general', {
            state: { typeAction: 'edit', rcmId: response.rcmId, projectCode: response.projectCode },
          })
        },
      })
    }

    if (createUpdateAuditRCMs?.error) {
      Alert({
        type: 'error',
        title: t(state?.typeAction === 'add' ? 'ไม่สามารถบันทึกข้อมูลได้' : 'ไม่สามารถแก้ไขข้อมูลได้'),
        content: t(displayError(createUpdateAuditRCMs?.error)),
        onOk() {},
      })
    }
  }, [createUpdateAuditRCMs?.data])

  const onFinish = (data) => {
    !dataLists.length && setOrgListError(true)

    if (dataLists.length) {
      let dataInput = {
        isActive: data?.isActive,
        operationStatusCode: data?.operationStatusCode,
        personnelApprove: data?.personnelApprove,
        personnelMaker: data?.personnelMaker,
        personnelReview: data?.personnelReview,
        projectCode: data?.projectCode,
        rcmObjective: data?.rcmObjective,
        rcmScope: data?.rcmScope,
        riskProcess: data?.riskProcess,
        riskProcessSub: data?.riskProcessSub,
        planStatusCode: '10',
        auditRCMOrganization: dataLists && dataLists?.length ? dataLists : [],
      }

      if (state?.typeAction === 'add') {
        dataInput.operationStatusCode = data?.operationStatusCode ? data?.operationStatusCode : '10'
        dataInput.action = 'create'
      }
      if (state?.typeAction === 'edit') {
        dataInput.rcmId = state?.rcmId
        dataInput.action = 'edit'
      }

      Alert({
        type: 'confirm',
        title: t(state?.typeAction === 'add' ? 'ต้องการยืนยันการบันทึก?' : 'ต้องการยืนยันการแก้ไข?'),
        onOk() {
          createUpdateAuditRCMsFn({ fetchPolicy: 'no-cache', variables: { input: encryptInput(dataInput) } })
        },
        onCancel() {},
      })
    }
  }

  const onFinishFailed = () => !dataLists.length && setOrgListError(true)

  const setOrganizationList = (v) => {
    if (_.isEmpty(dataLists)) return setDataLists([...v])
    setDataLists([..._.uniqBy([...dataLists, ...v], 'organizationCode')])
  }

  return (
    <>
      {(createUpdateAuditRCMs?.loading || getAuditRCM.loading) && <SpinnersNew />}
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ md: { span: 8 }, lg: { span: 6 }, xl: { span: 5 } }}
        wrapperCol={{ md: { span: 16 }, lg: { span: 18 }, xl: { span: 12 } }}
        name={formname}
      >
        <Row justify="center">
          <Col {...wrapper}>
            <Row style={{ margin: '10px 0px' }}>
              <Col {...subWrapper}>
                <Label type="sub-header-primary">{t('รายละเอียด')}</Label>
              </Col>
            </Row>
            <Row justify="center" gutter={[24, 16]}>
              <Col {...responsive}>
                <Form.Item
                  name="projectCode"
                  label={<LabelNew type="tab-header-inactive">{t('โครงการ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาเลือกโครงการ') }]}
                >
                  <SelectActionAuditPlans
                    placeholder={t('เลือกโครงการ')}
                    formname={formname}
                    disabled={state?.typeAction === 'edit' || viewAction ? true : false}
                    handleChange={async (e) => {
                      await setRiskModelName([
                        {
                          value: e?.source?.riskModelCode,
                          label: displayText(e?.source?.riskModelTH, e?.source?.riskModelEN),
                        },
                      ])
                      await form.setFieldsValue({ riskProcess: e?.source?.riskModelCode })
                      await form.setFieldsValue({ projectCode: e?.value })
                      await setProjectCode(e?.value)
                    }}
                  />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item
                  name="rcmObjective"
                  label={<Label type="tab-header-inactive">{t('วัตถุประสงค์')}</Label>}
                  rules={[{ required: true, message: t('กรุณาระบุวัตถุประสงค์') }]}
                >
                  <TextAreaNew
                    placeholder={t('ระบุวัตถุประสงค์')}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    disabled={viewAction ? true : isCreated ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item
                  name="rcmScope"
                  label={<Label type="tab-header-inactive">{t('ขอบเขตการดำเนินงาน')}</Label>}
                  rules={[{ required: true, message: t('กรุณาระบุขอบเขตการดำเนินงาน') }]}
                >
                  <TextAreaNew
                    placeholder={t('ระบุขอบเขตการดำเนินงาน')}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    disabled={viewAction ? true : isCreated ? false : true}
                  />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item
                  name="riskProcess"
                  label={<Label type="tab-header-inactive">{t('กระบวนการหลัก')}</Label>}
                  rules={[{ required: true, message: t('กรุณาเลือกกระบวนการหลัก') }]}
                >
                  <Select
                    disabled={true}
                    scrollableId={formname}
                    placeholder={t('เลือกกระบวนการหลัก')}
                    data={riskModelName}
                  />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item
                  name="riskProcessSub"
                  label={<Label type="tab-header-inactive">{t('กระบวนการย่อย')}</Label>}
                  rules={[{ required: true, message: t('กรุณาระบุกระบวนการย่อย') }]}
                >
                  <TextAreaNew
                    placeholder={t('ระบุกระบวนการย่อย')}
                    autoSize={{ minRows: 4, maxRows: 4 }}
                    disabled={viewAction ? true : isCreated ? false : true}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Col md={18} lg={18} xl={18}>
                <Label type="sub-header-primary">{t('ข้อมูลผู้ตรวจสอบ')}</Label>
              </Col>
            </Row>
            <Row justify="center" gutter={[24, 16]}>
              <Col {...responsive}>
                <Form.Item
                  name="personnelMaker"
                  label={<LabelNew type="tab-header-inactive">{t('ผู้จัดทำ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาเลือกผู้จัดทำ') }]}
                >
                  <SelectPersonnel
                    placeholder={t('เลือกผู้จัดทำ')}
                    formname={formname}
                    filters={{
                      personnaloperationCode: '10',
                      organizationChecked: 2,
                      levelCodeWhereIn: "'05','06','07','08','09','10','11'",
                    }}
                    disabled={viewAction ? true : isCreated ? false : true}
                    handleChange={(e) => {
                      e?.value && setNonePersonnelCode({ ...nonePersonnelCode, personnelMaker: e?.value })
                      form.setFieldsValue({ personnelMaker: e?.value })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item
                  name="personnelReview"
                  label={<LabelNew type="tab-header-inactive">{t('ผู้สอบทาน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาเลือกผู้สอบทาน') }]}
                >
                  <SelectPersonnel
                    placeholder={t('เลือกผู้สอบทาน')}
                    formname={formname}
                    filters={{
                      personnaloperationCode: '10',
                      organizationChecked: 2,
                      levelCodeWhereIn: "'08','09','10','11'",
                    }}
                    disabled={viewAction ? true : isCreated ? false : true}
                    handleChange={(e) => {
                      e?.value && setNonePersonnelCode({ ...nonePersonnelCode, personnelReview: e?.value })
                      form.setFieldsValue({ personnelReview: e?.value })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item
                  name="personnelApprove"
                  label={<LabelNew type="tab-header-inactive">{t('ผู้อนุมัติ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาเลือกผู้อนุมัติ') }]}
                >
                  <SelectPersonnel
                    placeholder={t('เลือกผู้อนุมัติ')}
                    formname={formname}
                    filters={
                      nonePersonnelCode
                        ? {
                            personnaloperationCode: '10',
                            organizationChecked: 2,
                            levelCode: 12,
                            nonePersonnelCode: JSON.stringify(nonePersonnelCode),
                          }
                        : {
                            personnaloperationCode: '10',
                            organizationChecked: 2,
                            levelCode: 12,
                          }
                    }
                    disabled={viewAction ? true : isCreated ? false : true}
                    handleChange={(e) => form.setFieldsValue({ personnelApprove: e?.value })}
                  />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item
                  name="operationStatusCode"
                  label={<LabelNew type="tab-header-inactive">{t('สถานะดำเนินงาน')}</LabelNew>}
                >
                  <SelectOperationStatus
                    formname={formname}
                    placeholder={t('เลือกสถานะ')}
                    disabled={true}
                    needOperationStatusCode={true}
                    handleChange={(e) => form.setFieldsValue({ operationStatusCode: e?.value })}
                    filters={{ operationStatusType: 1, isActive: 1 }}
                  />
                </Form.Item>
              </Col>
              <Col {...responsive}>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>}
                  name="isActive"
                  initialValue={1}
                >
                  <RadioGroup disabled={viewAction}>
                    <Space size={[8, 8]} wrap>
                      <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                      <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
                    </Space>
                  </RadioGroup>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <OrganizationStyle>
          <CardNew topic={t('หน่วยงานรับตรวจ')} icon="List" toggledrop={'false'}>
            <OrganizationGroup
              menu={menu}
              orgListError={orgListError}
              setModal={(e) => setModal(e)}
              dataListsFromModal={[...dataLists]}
              deletedOrg={(v) => setDataLists([...v])}
              viewAction={viewAction}
              isCreated={isCreated}
            />
          </CardNew>
        </OrganizationStyle>
        <Row gutter={[12, 20]} justify="center">
          {isCreated ? (
            <>
              {state?.typeAction !== 'view' && (
                <Col>
                  <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                    {t('บันทึก')}
                  </ButtonNew>
                </Col>
              )}
              <Col>
                <ButtonNew type="back" onClick={() => navigate('/auditoperations/racmatrix')}>
                  {t('ย้อนกลับ')}
                </ButtonNew>
              </Col>
            </>
          ) : (
            <Col>
              <ButtonNew type="back" onClick={() => navigate('/auditoperations/racmatrix')}>
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          )}
        </Row>
      </Form>
      {modal && (
        <CheckerOrganizationModal
          close={(e) => setModal(e)}
          open={modal}
          getData={(v) => setOrganizationList(v)}
          menu={props.menu}
          orgActive={dataLists}
        />
      )}
    </>
  )
}

GeneralTab.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default GeneralTab
