import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import { CardNew as Card } from '../../../../../components/card/Card'
import { ButtonNew } from '../../../../../components/button/Button'
import { LabelNew } from '../../../../../components/label/Label'
import { InputNew } from '../../../../../components/input/Input'
import SelectProjectRefer from '../../../../../components/inputfromapi/selectprojectrefer/SelectProjectRefer'
import SelectOrganization from '../../../../../components/inputfromapi/selectorganization/SelectOrganization'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'

export default function AcknowledgeIssueListFilter(props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { menu } = props
  const formName = 'from-auditresult-fillter'
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)

  const Responesive = {
    inputFilter: {
      md: 12,
      lg: 8,
    },
    buttonFilter: {
      md: 24,
      lg: 24,
    },
  }

  const onFinish = (values) => {
    props.getValue(values)
  }

  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((er) => {
      console.log(er)
    })
  }

  return (
    <>
      <Card topic={t('Filters')} icon="Filter" toggledrop={'false'}>
        <Row style={{ marginTop: 24 }}>
          <Col sm={24} md={24} lg={24}>
            <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} name={formName} layout="vertical">
              <Row gutter={[16, 8]}>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="projectRefCode"
                    label={<LabelNew type="tab-header-inactive">{t('แหล่งที่มาของโครงการ')}</LabelNew>}
                  >
                    <SelectProjectRefer
                      filters={{ isActive: 1 }}
                      formname={formName}
                      placeholder={t('เลือกแหล่งที่มาของโครงการ')}
                      handleChange={(value) => {
                        form.setFieldsValue({ projectRefCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="projectName"
                    label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุชื่อโครงการ')} />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="organizationCode"
                    rules={[{ required: false, message: t('required') }]}
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</LabelNew>}
                  >
                    <SelectOrganization
                      filters={{ checkedList: JSON.stringify([1, 2]), isActive: 1 }}
                      formname={formName}
                      placeholder={t('เลือกหน่วยงานที่รับผิดชอบ')}
                      handleChange={(value) => {
                        form.setFieldsValue({ organizationCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="fullName"
                    rules={[{ required: false, message: t('required') }]}
                    label={<LabelNew type="tab-header-inactive">{t('ผู้รับผิดชอบหลัก')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุผู้รับผิดชอบหลัก')} />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="dateStart"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        await setIsMinDate(data)
                        await form.setFieldsValue({ dateStart: await data })
                      }}
                      isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                      selectsStart={true}
                      onClear={() => {
                        form.setFieldsValue({ dateStart: null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="dateEnd"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        await setIsMaxDate(data)
                        await form.setFieldsValue({ dateEnd: await data })
                      }}
                      isMinDate={isMinDate ? new Date(isMinDate) : null}
                      selectsEnd={true}
                      onClear={() => {
                        form.setFieldsValue({ dateEnd: null })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew type="search" menu={menu} roles={{ type: 'view', menu: menu }} htmlType="submit">
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew
                    type="clear"
                    menu={menu}
                    onClick={() => {
                      form.resetFields()
                      setIsMinDate(null)
                      setIsMaxDate(null)
                    }}
                  >
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}
