import React, { useState, useEffect } from 'react'
import { Form, Space } from 'antd'
import { ModalNew } from '../../../../../components/modal/Modal'
import { useTranslation } from 'react-i18next'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { Select } from '../../../../../components/select/Select'
import { RadioGroup, Radio } from '../../../../../components/radio/Radio'
import { InputNew } from '../../../../../components/input/Input'
import { Alert } from '../../../../../components/alert/Alert'
import { regexEngNoSpace, displayError } from '../../../../../utilitys/helper'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GETMASTERFORMTYPESBYFORMS } from '../../graphql/Query'
import { checkLanguageReturnData } from '../assessmentFormfn'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { FORMSSAVE } from '../../graphql/Mutation.js'
import { encryptInput } from '../../../../../utilitys/crypto'

export default function CreateModal(props) {
  const { open } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const formName = 'assessment-form-create'

  const [getMasterFormTypesByFormsfn, getMasterFormTypesByForms] = useLazyQuery(GETMASTERFORMTYPESBYFORMS)
  const [formsSavefn, { loading: loadingSaved, error, data: dataSaved }] = useMutation(FORMSSAVE)
  const [optionsFormType, setOptionsFormType] = useState([])

  const onClose = (e) => {
    form.resetFields()
    props.close(e)
  }

  const onFinish = async () => {
    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกแบบประเมิน'),
      onOk: async () => {
        const dataForm = form.getFieldValue()
        await formsSavefn({
          variables: {
            inputData: encryptInput({
              forms: {
                formCode: dataForm.formCode,
                formNameTH: dataForm.formNameTH,
                formNameEN: dataForm.formNameEN ? dataForm.formNameEN : dataForm.formNameTH,
                formTypeCode: dataForm.formTypeCode,
                isActive: dataForm.isActive,
              },
              actions: 'create',
            }),
          },
        })
      },
      onCancel() {
        return null
      },
    })
  }

  useEffect(() => {
    getMasterFormTypesByFormsfn({
      variables: {
        isActive: 1,
      },
    })
  }, [open])

  useEffect(() => {
    if (getMasterFormTypesByForms.data) {
      setOptionsFormType(
        getMasterFormTypesByForms.data.getMasterFormTypesByForms.map((dt, keys) => {
          return {
            key: keys,
            label: checkLanguageReturnData(dt.formTypeNameTH, dt.formTypeNameEN),
            value: dt.formTypeCode,
            source: dt,
          }
        }),
      )
    }
    if (getMasterFormTypesByForms.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getMasterFormTypesByForms.error)),
        onOk() {},
      })
    }
  }, [getMasterFormTypesByForms.data])

  useEffect(() => {
    if (dataSaved) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          props.close(false)
          navigate('/administrator/assessmentsetup/assessmentformdetail/edit', {
            state: {
              refCode: dataSaved.formsSave.formCode,
            },
          })
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(error),
        onOk() {},
      })
    }
  }, [dataSaved])

  return (
    <React.Fragment>
      {getMasterFormTypesByForms.loading && <SpinnersNew />}
      {loadingSaved && <SpinnersNew />}
      <ModalNew
        visible={open}
        testTitle={t('เพิ่มแบบประเมิน')}
        onSubmit={form.submit}
        onClose={(e) => {
          onClose(e)
        }}
        type="medium"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name="formCode"
            label={<Label type="tab-header-inactive">{t('รหัสแบบประเมิน')}</Label>}
            rules={[
              { required: true, message: t('กรุณากรอกรหัสแบบประเมิน') },
              {
                pattern: regexEngNoSpace,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, 0-9 และไม่เกิน 10 ตัวอักษร`),
              },
            ]}
          >
            {/* maxLength={10} */}
            <InputNew placeholder={t('ระบุรหัสแบบประเมิน')} maxLength={10} />
          </Form.Item>
          <Form.Item
            name="formNameTH"
            label={<Label type="tab-header-inactive">{t('ชื่อแบบประเมินภาษาไทย')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกชื่อแบบประเมินภาษาไทย') }]}
          >
            <InputNew placeholder={t('ระบุชื่อแบบประเมินภาษาไทย')} maxLength={200} />
          </Form.Item>
          <Form.Item
            name="formNameEN"
            label={<Label type="tab-header-inactive">{t('ชื่อแบบประเมินภาษาอังกฤษ')}</Label>}
          >
            <InputNew placeholder={t('ระบุชื่อแบบประเมินภาษาอังกฤษ')} maxLength={200} />
          </Form.Item>
          <Form.Item
            name="formTypeCode"
            label={<Label type="tab-header-inactive">{t('ประเภทการประเมิน')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกประเภทการประเมิน') }]}
          >
            <Select
              formname={formName}
              data={optionsFormType}
              placeholder={t('ระบุประเภทการประเมิน')}
              scrollableId={formName}
              showSearch
            />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
            <RadioGroup>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                &nbsp;
                <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </React.Fragment>
  )
}
