import packageVersion from '../../package.json'

const appConfig = {
  version: packageVersion.version,
  appName: 'SME Development Bank Audit Management Tools',
  appShortName: 'Audit Management Tools',
  defaultWebPath: '/dashboard',
  systemStartYear: 2012,
}
export default appConfig
