import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col, Row } from 'antd'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { CardNew } from '../../../components/card/Card'
import { IconNew } from '../../../components/icon/Icon'
import { Status } from '../../../components/status/Status'
import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { handleSort } from '../../../utilitys/pagination'
import { displayText, displayError } from '../../../utilitys/helper'
import { encryptInput } from '../../../utilitys/crypto'
import { dateTimeDisplay } from '../../../utilitys/dateHelper'
import { MainTable, SubTable } from './css/Styles'
import { GET_RESULT_ASSESSMENT_PAGINATION, GET_SUB_RESULT_ASSESSMENT } from './graphql/Query'
import Filter from './components/filter/Filter'
import ExportModal from './components/modal/ExportModal'
import { useNavigate } from 'react-router-dom'
import { ButtonNew } from '../../../components/button/Button'

const ResultAssessmentList = (props) => {
  const wrapper = { sm: 24, md: 24, lg: 24, xl: 24 }
  const { menu } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [dataLists, setDataLists] = useState([])
  const [dataResult, setDataResult] = useState([])
  const [newData, setNewData] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState([])
  const [formCode, setFormCode] = useState(null)
  const [formAssessId, setFormAssessId] = useState(null)
  const [visible, setVisible] = useState(false)
  const [dataExportModal, setDataExportModal] = useState(null)

  const [getResultAssessmentPaginationFn, getResultAssessmentPagination] = useLazyQuery(
    GET_RESULT_ASSESSMENT_PAGINATION,
  )
  const [getSubResultAssessmentFn, getSubResultAssessment] = useLazyQuery(GET_SUB_RESULT_ASSESSMENT)

  useEffect(() => {
    getResultAssessmentPaginationFn({
      variables: {
        input: encryptInput({
          filters: { ...filters },
          pagination: { limit, page },
          sort,
        }),
      },
    })
  }, [page, limit, filters, sort])

  useEffect(() => {
    if (formCode && formCode.length) {
      getSubResultAssessmentFn({
        variables: { input: encryptInput({ formCode: formCode, formAssessId: formAssessId }) },
      })
    }
  }, [formCode, formAssessId])

  useEffect(() => {
    if (getSubResultAssessment?.data) {
      setDataResult(
        _.map(getSubResultAssessment?.data?.getSubResultAssessment, (d) => ({
          key: d.formResultId,
          formCode: d.formCode,
          responsibilityBy: displayText(d.fullnameTH, d.fullnameEN),
          reviewAt: dateTimeDisplay(d.reviewAt),
          organizationName: displayText(d.organizationNameTH, d.organizationNameEN),
          operationStatusName: displayText(d.operationStatusNameTH, d.operationStatusNameEN),
          tools:
            d.operationStatusCode !== '50' ? (
              <ButtonNew
                type="viewTable"
                onClick={() =>
                  navigate('/auditoperations/assessmentformresults/details', {
                    state: { type_action: 'view', formCode: d.formCode, formResultId: d.formResultId, data: d },
                  })
                }
              >
                {t('ดูผลการประเมิน')}
              </ButtonNew>
            ) : (
              ''
            ),
        })),
      )
    }
    if (getSubResultAssessment.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getSubResultAssessment?.error)),
        onOk() {},
      })
    }
  }, [getSubResultAssessment?.data])

  useEffect(() => {
    if (getResultAssessmentPagination?.data) {
      let response = getResultAssessmentPagination?.data?.getResultAssessmentPagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            ...data,
            key: `${data.formAssessId}_${data.formId}_${data.formCode}`,
            formName: displayText(data.formNameTH, data.formNameEN),
            projectName: displayText(data.projectNameTH, data.projectNameEN),
            formType: displayText(data.formTypeNameTH, data.formTypeNameEN),
            operationStatusName: displayText(data.operationStatusNameTH, data.operationStatusNameEN),
          }
        }),
      )
    }
    if (getResultAssessmentPagination.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getResultAssessmentPagination?.error)),
        onOk() {},
      })
    }
  }, [getResultAssessmentPagination?.data])

  useEffect(() => {
    const dataResultGroup = _.groupBy(dataResult, 'formCode')

    setNewData(
      _.map(dataLists, (data) => ({
        ...data,
        description: (
          <SubTable>
            <Datatable
              columns={subColumns}
              data={dataResultGroup[data.formCode] ? dataResultGroup[data.formCode] : []}
            />
          </SubTable>
        ),
      })),
    )
  }, [dataLists, dataResult])

  const onFilter = (data) => {
    Promise.all([setPage(1), setLimit(10), setFilters(data)])
  }

  const mainColumns = [
    { title: t('ชื่อแบบประเมิน'), dataIndex: 'formName' },
    { title: t('ชื่อโครงการ'), dataIndex: 'projectName' },
    { title: t('ประเภทแบบประเมิน'), dataIndex: 'formType' },
    {
      title: t('สถานะ'),
      dataIndex: 'operationStatusName',
      align: 'center',
      sorter: { multiple: 1 },
      width: '20%',
      render: (d) => <Status text={d} />,
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '10%',
      align: 'right',
      render: (text, record) => {
        let list = [
          {
            type: 'view',
            onClick: () => {
              navigate('/auditoperations/assessmentformresults/details', {
                state: { type_action: 'view', formCode: record.formCode, formResultId: '' },
              })
            },
          },
          {
            type: 'download',
            onClick: () => {
              setDataExportModal({ type: 'mainResult', formCode: record.formCode })
              setVisible(true)
            },
          },
        ]
        return <ButtonGroup menu={props.menu} size={18} icons={list} />
      },
    },
  ]

  const subColumns = [
    {
      title: t('ผู้ประเมิน'),
      dataIndex: 'responsibilityBy',
    },
    { title: t('วันเวลาที่ประเมิน'), dataIndex: 'reviewAt' },
    { title: t('หน่วยรับตรวจ'), dataIndex: 'organizationName' },
    {
      title: t('สถานะ'),
      dataIndex: 'operationStatusName',
      key: 'operationStatusName',
      width: '15%',
      render: (d) => <Status text={d} />,
    },
    { title: '', dataIndex: 'tools' },
  ]

  return (
    <>
      {(getResultAssessmentPagination?.loading || getSubResultAssessment?.loading) && <SpinnersNew />}
      <Col {...wrapper}>
        <Filter onFilter={onFilter} menu={menu} />
        <div style={{ marginBottom: '2rem' }}>
          <CardNew topic={t('รายการผลของการประเมิน')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col {...wrapper}>
                <MainTable>
                  <Datatable
                    columns={mainColumns}
                    data={[...newData]}
                    total={count}
                    onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                    paginationCustom={true}
                    pageSize={limit}
                    page={page}
                    searchCustom={true}
                    handleTableChange={(e) => {
                      const reNewField = [
                        { operationStatusName: displayText('operationStatusNameTH', 'operationStatusNameEN') },
                      ]
                      setSort(handleSort(e?.sorter, reNewField))
                    }}
                    expandable={{
                      expandedRowRender: (record) => record.description,
                      onExpandedRowsChange: (e) => {
                        setFormAssessId(_.map(e, (d) => _.head(d.split('_'))))
                        setFormCode(_.map(e, (d) => _.last(d.split('_'))))
                      },
                      expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                          <IconNew icon={'ChevronUp'} onClick={(e) => onExpand(record, e)} />
                        ) : (
                          <IconNew icon={'ChevronDown'} onClick={(e) => onExpand(record, e)} />
                        ),
                    }}
                  />
                </MainTable>
              </Col>
            </Row>
          </CardNew>
          <ExportModal
            open={visible}
            dataExportModal={dataExportModal}
            close={(e) => setVisible(e)}
            menu={props.menu}
          />
        </div>
      </Col>
    </>
  )
}

ResultAssessmentList.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default ResultAssessmentList
