import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'

import { BreadcrumbNew as Breadcrumb } from '../../../../components/breadcrumb/Breadcrumb'
import { CardNew as Card } from '../../../../components/card/Card'
import { getSegmentUrl } from '../../../../utilitys/helper'
import { TabNew } from '../../../../components/tab/Tab'

import FormAuditOpensInformation from './FormAuditOpensInformation'
import AuditOpensAdddocList from './adddoc/AuditOpensAdddocList.jsx'

const FormAuditOpensIndex = (props) => {
  const { t } = useTranslation()
  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/auditopens' },
    { label: t('บันทึกเปิดตรวจ'), path: '/auditoperations/auditopens' },
    { label: t('รายละเอียดบันทึกเปิดตรวจ') },
  ]
  const type = getSegmentUrl(4)
  const params = getSegmentUrl(3)
  const navigate = useNavigate()
  const { state } = useLocation()
  if (!state && type !== 'add') return <Navigate to={'/auditoperations/auditopens'} />
  const [tabs, setTabs] = useState([])

  const dataTab = [
    {
      key: 1,
      iconTab: () => <></>,
      name: t('หนังสือเปิดตรวจ'),
      disabled: type === 'add' ? true : false,
      content: () => (
        <>
          <FormAuditOpensInformation
            typeForm={type}
            menu={props.menu}
            refCode={type !== 'add' ? state.refCode : null}
          />
        </>
      ),
    },
    {
      key: 2,
      iconTab: () => <></>,
      name: t('คำขอเอกสารเพิ่มเติม'),
      disabled: type === 'add' ? true : false,
      content: () => (
        <>
          <AuditOpensAdddocList typeForm={type} menu={props.menu} refCode={type !== 'add' ? state.refCode : null} />
        </>
      ),
    },
  ]

  useEffect(() => {
    setTabs(dataTab)
  }, [type])

  return (
    <>
      <Row>
        <Col md={24}>
          <Breadcrumb data={breadcrumbList} title={t('บันทึกเปิดตรวจ')} />
          <Card
            topic={t('รายละเอียดบันทึกเปิดตรวจ')}
            toggledrop="false"
            icon={type === 'add' ? 'Plus' : type === 'edit' ? 'Edit2' : 'FileText'}
          >
            <TabNew
              tab={tabs}
              activeKey={params === 'information' ? '1' : params === 'adddoc' ? '2' : ''}
              onTabClick={(key) => {
                if (type === 'add') {
                  if (key === '1') {
                    navigate('/auditoperations/auditopens/information/add')
                  }
                } else {
                  if (key === '1') {
                    navigate('/auditoperations/auditopens/information/' + type, {
                      state: { refCode: state.refCode },
                    })
                  } else if (key === '2') {
                    navigate('/auditoperations/auditopens/adddoc/' + type, {
                      state: { refCode: state.refCode },
                    })
                  }
                }
              }}
            />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FormAuditOpensIndex
