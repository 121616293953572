import { gql } from '@apollo/client'
export const GET_AUDITPROJECT_PAGINATION = gql`
  query getAuditProjectPagenation($input: AuditProjectPagenation!) {
    getAuditProjectPagenation(data: $input) {
      result {
        projectCode
        projectNameTH
        projectNameEN
        projectRefNameTH
        projectRefNameEN
        dateStart
        dateEnd
        isActive
        organizationNameTH
        organizationNameEN
        namePrefixTH
        namePrefixEN
        firstNameTH
        lastNameTH
        firstNameEN
        lastNameEN
        projectStatusCode
        cancelRemarkTH
        cancelRemarkEN
        approveStatusCode
        approveStatusNameTH
        approveStatusNameEN
        projectStatusNameTH
        projectStatusNameEN
        cancelStatus
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDITPROJECT_RISKFORM_PAGINATION = gql`
  query getAuditProjectRiskFormsPagenation($input: AuditProjectRiskFormsPagenation!) {
    getAuditProjectRiskFormsPagenation(data: $input) {
      result {
        riskFormCode
        Year
        riskFormTH
        riskFormEN
        riskFormType
        operationStatusNameTH
        operationStatusNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDITPROJECT_ORGANIZATIONS_PAGINATION = gql`
  query getAuditProjectOrganizationsPagenation($input: AuditProjectOrganizationsPagenation!) {
    getAuditProjectOrganizationsPagenation(data: $input) {
      result {
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationLevelNameTH
        organizationLevelNameEN
        personnelCode
        namePrefixTH
        namePrefixEN
        firstNameTH
        lastNameTH
        firstNameEN
        lastNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDITPROJECT_ORGANIZATIONS_ALL = gql`
  query getAuditProjectOrganizationsAll($input: AuditProjectOrganizationsAll!) {
    getAuditProjectOrganizationsAll(data: $input) {
      organizationCode
      organizationNameTH
      organizationNameEN
      organizationLevelNameTH
      organizationLevelNameEN
      personnelCode
      namePrefixTH
      namePrefixEN
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
    }
  }
`

export const AUDITPROJECT_CHECK_PLANCODE = gql`
  query auditProjectsCheckPlanCode($input: AuditProjectsCheckPlanCodeInput!) {
    auditProjectsCheckPlanCode(data: $input) {
      planCode
    }
  }
`

export const DATA_AUDITPROJECT_FORM_GENERAL_TAB = gql`
  query dataAuditProjectFormGeneralTab($projectCode: String!) {
    dataAuditProjectFormGeneralTab(projectCode: $projectCode) {
      projectCode
      projectNameTH
      projectNameEN
      planCode
      projectTypeCode
      projectRefCode
      riskFormCode
      riskFormTH
      riskFormEN
      isActive
      approveStatusNameTH
      approveStatusNameEN
      projectStatusNameTH
      projectStatusNameEN
      projectStatusCode
      cancelRemarkTH
      cancelRemarkEN
      auditTypeCode
      cancelStatus
      organization {
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationLevelNameTH
        organizationLevelNameEN
        namePrefixTH
        namePrefixEN
        personnelCode
        firstNameTH
        lastNameTH
        firstNameEN
        lastNameEN
      }
      approvePersonnelCode
    }
  }
`

export const GET_AUDITTEAM_DETAIL_ROLE = gql`
  query getAuditTeamsDetailRole($input: AuditTeamsSelectInput!) {
    getAuditTeamsDetailRole(data: $input) {
      auditTeamCode
      personnelCode
      auditTeamRole
      namePrefixTH
      namePrefixEN
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
    }
  }
`

export const DATA_AUDITPROJECT_FORM_TEAM_TAB = gql`
  query dataAuditProjectFormTeamTab($projectCode: String!) {
    dataAuditProjectFormTeamTab(projectCode: $projectCode) {
      projectCode
      organizationCode
      organizationNameTH
      organizationNameEN
      auditTeamCode
      personnelCode
      projectSizeCode
      manday
      numberOfPeople
      mandayPersonnal
      dateStart
      dateEnd
      planDateStart
      planDateEnd
    }
  }
`

export const CHECK_AUDITPLAN_AND_PROJECT_HAS_ITEM = gql`
  query checkAuditPlanAndProjectHasItem($input: CheckHasItemInput!) {
    checkAuditPlanAndProjectHasItem(data: $input) {
      status
    }
  }
`

export const GET_AUDITPROJECT_FILES = gql`
  query getAuditProjectFiles($projectCode: String!) {
    getAuditProjectFiles(projectCode: $projectCode) {
      fileId
      filename
      links
      refFile {
        originalname
        name
      }
    }
  }
`

export const AUDITPROJECT_EXPORT = gql`
  query auditProjectExport($input: AuditProjectExportInput!) {
    auditProjectExport(data: $input) {
      message
      fileUrl
    }
  }
`

export const AUDIT_PROJECT_GET_CALENDARDETAILS1 = gql`
  query auditProjectGetCalendarDetailsType1 {
    auditProjectGetCalendarDetailsType1 {
      year
      dateAt
    }
  }
`

export const AUDIT_PROJECT_GET_CALENDARDETAILS2 = gql`
  query auditProjectGetCalendarDetailsType2 {
    auditProjectGetCalendarDetailsType2 {
      year
      dateAt
    }
  }
`

export const GET_AUDITPROJECT = gql`
  query getAuditProject($input: AuditProjectSearchInput!) {
    getAuditProject(data: $input) {
      projectCode
      projectNameTH
      projectNameEN
      dateStart
      dateEnd
      approveStatusCode
      statusActionPlan
      countProjectStepStatusAll
      countProjectStepStatus10
      countProjectStepStatus20
      countProjectStepStatus30
    }
  }
`

export const GET_MASTER_OPERATION_STATUS = gql`
  query getMasterOperationStatus($input: MasterOperationStatusInput!) {
    getMasterOperationStatus(data: $input) {
      operationStatusCode
      operationStatusNameTH
      operationStatusNameEN
    }
  }
`
