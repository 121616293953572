import { gql } from '@apollo/client'
export const GET_SELECT_RATE_RISK_CONTRO_EXAMINE = gql`
  query GetSelectRateRiskControlExamine($input: SelectRateRiskControlExamineInput!) {
    getSelectRateRiskControlExamine(input: $input) {
      rateRiskConCode
      rateRiskConTH
      rateRiskConEN
    }
  }
`
