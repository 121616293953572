import React, { useEffect, useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { useMutation } from '@apollo/client'
import { encryptInput, encryptData, decryptData } from '../../../../utilitys/crypto'
import { newToken } from '../../../../configs/api.config'
import { LOGOUT } from '../../../../layouts/navbar/graphql/Mutation'

const ShowDebugTime = styled.div`
  position: absolute;
  z-index: 999;
  background-color: #fdffce;
  padding: 2px;
`

const CheckLogin = (props) => {
  const debug = parseInt(localStorage.getItem('debug')) // 1 = ไว้ดีบัค
  const [startTime, setStartTime] = useState('')
  const [endTime, setEndTime] = useState('')
  const [leftTime, setLeftTime] = useState(0)
  const [leftTimeMS, setLeftTimeMS] = useState('')
  const [activeScreen, setActiveScreen] = useState(0)
  const userLogin = decryptData(localStorage.getItem('user'))
  const sess_expire_min = parseInt(decryptData(localStorage.getItem('sess_expire_min')))

  //--- Logout ---
  const [callLogout, { data: dataLogout }] = useMutation(LOGOUT)
  useEffect(() => {
    if (dataLogout) {
      localStorage.removeItem('user')
      localStorage.removeItem('sess_expire_min')
      localStorage.removeItem('sess_active_screen')
      localStorage.removeItem('sess_start_time')
      window.location.href = '/login'
    }
  }, [dataLogout])

  const fncLogout = () => {
    if (debug === 1) {
      console.log('fncLogout')
    }
    const input = {
      logfileLoginId: userLogin.logfileLoginId ? userLogin.logfileLoginId : null,
      userId: userLogin.userId,
    }
    callLogout({ variables: encryptInput(input) })
  }
  //--- End logout ---

  useEffect(() => {
    if (debug === 1) {
      setStartTime(decryptData(localStorage.getItem('sess_start_time')))
    }
    fncCheckLeftTime()
    fncActiveScreen()
  }, [])

  window.addEventListener('mousemove', () => {
    fncEventActiveScreen()
  })
  window.addEventListener('keydown', () => {
    fncEventActiveScreen()
  })

  const [stateLogout, setStateLogout] = useState(0)
  useEffect(() => {
    if (stateLogout === 1) {
      fncLogout()
    }
  }, [stateLogout])

  const fncEventActiveScreen = () => {
    if (fncCheckLeftTime() === 0 && stateLogout === 0) {
      setStateLogout(1)
    } else {
      fncActiveScreen()
      props.setActiveScreen('active')
    }
  }

  const fncActiveScreen = () => {
    if (parseInt(decryptData(localStorage.getItem('sess_active_screen'))) !== 1) {
      localStorage.setItem('sess_active_screen', encryptData(1))
      if (debug === 1) {
        setActiveScreen(decryptData(localStorage.getItem('sess_active_screen')))
      }
    }
  }

  const fncCheckActiveScreen = () => {
    const sess_active_screen = parseInt(decryptData(localStorage.getItem('sess_active_screen')))
    if (debug === 1) {
      setActiveScreen(sess_active_screen)
    }
    return sess_active_screen
  }

  const fncCheckLeftTime = () => {
    //ฟังชั่นเช็คเวลา session ว่าหมดเวลาหรือยัง
    const dur_sem = moment.duration(sess_expire_min, 'm')
    const sem = dur_sem.asSeconds() //แปลงเวลา session expire เป็น วินาที
    //---
    const fromDate = moment(decryptData(localStorage.getItem('sess_start_time')), 'YYYY-MM-DD HH:mm:ss')
    const currentDate = moment(moment().format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')
    const diff = currentDate.diff(fromDate) //หาเวลาที่ Active ในระบบ
    const dur = moment.duration(diff)
    const ss = dur.asSeconds() //แปลงเวลา ที่อยู่ Active ในระบบ เป็น วินาที
    const _leftTime = sem >= ss ? sem - ss : 0 //หาเวลา session expire ที่เหลือว่ากี่วินาที
    return _leftTime
  }

  const [triggerReset, setTriggerReset] = useState('')
  let newTokenCount = 0
  useEffect(() => {
    const stv = setInterval(() => {
      if (localStorage.getItem('sess_start_time')) {
        const fclt = fncCheckLeftTime()
        //ถ้ายังไม่หมดเวลา Session
        if (fclt > 0) {
          const fcas = fncCheckActiveScreen()
          //ถ้า Active อยู่หน้าจอ ให้ new Token ใหม่
          if (fcas === 1) {
            if (debug === 1) {
              newTokenCount++
              console.log('newTokenCount: ', newTokenCount)
            }
            newToken()
            localStorage.setItem('sess_start_time', encryptData(moment().format('YYYY-MM-DD HH:mm:ss')))
            setStartTime(decryptData(localStorage.getItem('sess_start_time')))
            localStorage.setItem('sess_active_screen', encryptData(0))
            props.setActiveScreen('active')
          } else {
            props.setActiveScreen('inactive')
          }
        } else {
          //ถ้าหมดเวลา session ให้ logout เลย
          clearInterval(stv)
          fncLogout()
        }
        if (debug === 1) {
          setTriggerReset(Math.random())
        }
      }
    }, 59000)

    return () => clearInterval(stv)
  }, [])

  useEffect(() => {
    //ไว้สำหรับดีบัค ดู timer
    if (debug === 1) {
      const dur_sem = moment.duration(sess_expire_min, 'm')
      const sem = dur_sem.asSeconds() //แปลงเวลา session expire เป็น วินาที
      //---
      const fromDate = moment(decryptData(localStorage.getItem('sess_start_time')), 'YYYY-MM-DD HH:mm:ss')
      const currentDate = moment(moment().format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss')
      const diff = currentDate.diff(fromDate) //หาเวลาที่ Active ในระบบ
      const dur = moment.duration(diff)
      const ss = dur.asSeconds() //แปลงเวลา ที่อยู่ Active ในระบบ เป็น วินาที
      const _leftTime = sem >= ss ? sem - ss : 0 //หาเวลา session expire ที่เหลือว่ากี่วินาที

      //แสดงออกมาว่าเหลือเวลาทำงานในระบบกี่นาทีกี่วินาที
      const dur2 = moment.duration(_leftTime, 's')
      const mmss = moment.utc(dur2.asMilliseconds()).format('mm:ss')
      console.log('fncLeftTimeDebug: ', sem + 's', '-', ss + 's', '=', _leftTime + 's', `(${mmss})`)
      setLeftTime(mmss)

      const endDate = moment(fromDate, 'YYYY-MM-DD HH:mm:ss')
        .add(sess_expire_min, 'minutes')
        .format('YYYY-MM-DD HH:mm:ss')
      setEndTime(endDate)

      const duration = moment.duration(_leftTime, 's')
      let stv = setInterval(() => {
        duration.subtract(1, 's')
        const tt = moment.utc(duration.asMilliseconds()).format('mm:ss')
        setLeftTimeMS(tt)
        fncCheckActiveScreen()
        if (duration.asMilliseconds() === 0) {
          clearInterval(stv)
        }
      }, 1000)

      return () => clearInterval(stv)
    }
  }, [triggerReset])

  return (
    <>
      {debug === 1 && (
        <ShowDebugTime>
          <div>เวลาเริ่ม sess {startTime}</div>
          <div>เวลาหมด sess {endTime}</div>
          <div>
            เวลาที่เหลือ {leftTime} | {leftTimeMS}
          </div>
          <div>สถานะอยู่หน้าจอ {activeScreen}</div>
        </ShowDebugTime>
      )}
      {props.layout}
    </>
  )
}

export default CheckLogin
