import React from 'react'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { CardNew } from '../../../components/card/Card'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { Col, Row, Space } from 'antd'
import { Alert } from '../../../components/alert/Alert'

function AlertIndex() {
  const data = [{ label: 'งานควบคุมภายใน', path: '/internalcontrol/assess' }, { label: 'ประเมินการควบคุมภายใน' }]
  let code = ` import { Alert } from '../../../components/alert/Alert'

  Alert({
    type: 'confirm',
    title: 'กรุณากดยืนยันหากต้องการอนุมัติความเสี่ยงทั้งโครงการ?',
    onOk() {
      console.log('onOk')
    },
    onCancel() {
      console.log('onCancel')
    },
  })

  /*
  // onOk ใส่หรือไม่ใส่ก็ได้
  onOk() {
    console.log('onOk')
  },
  */

  Alert({
    type: 'success',
    title: 'บันทึกข้อมูลสำเร็จ',
    onOk() {
      console.log('onOk')
    },
  })

  Alert({
    type: 'warning',
    title: 'รหัสบัตรประชาชนไม่ถูกต้อง',
    onOk() {
      console.log('onOk')
    },
  })

  Alert({
    type: 'error',
    title: 'ไม่สามารถบันทึกข้อมูลได้',
    onOk() {
      console.log('onOk')
    },
  })
    `

  const showConfirm = () => {
    Alert({
      type: 'confirm',
      title: 'กรุณากดยืนยันหากต้องการอนุมัติความเสี่ยงทั้งโครงการ?',
      onOk() {
        console.log('onOk')
      },
      onCancel() {
        console.log('onCancel')
      },
    })
  }

  const showAlertSuccess = () => {
    Alert({
      type: 'success',
      title: 'บันทึกข้อมูลสำเร็จ',
      onOk() {
        console.log('onOk')
      },
    })
  }

  const showAlertWarning = () => {
    Alert({
      type: 'warning',
      title: 'รหัสบัตรประชาชนไม่ถูกต้อง',
      onOk() {
        console.log('onOk')
      },
    })
  }

  const showAlertError = () => {
    Alert({
      type: 'error',
      title: 'ไม่สามารถบันทึกข้อมูลได้',
      onOk() {
        console.log('onOk')
      },
    })
  }

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <Breadcrumb data={data} title="ประเมินการควบคุมภายใน" />
          <CardNew topic="Alert" icon="AlignJustify" toggledrop="true">
            <Space wrap>
              <ButtonNew type="primary" onClick={showConfirm}>
                Confirm
              </ButtonNew>
              <ButtonNew type="allow" onClick={showAlertSuccess}>
                Success
              </ButtonNew>
              <ButtonNew type="back" onClick={showAlertWarning}>
                Warning
              </ButtonNew>
              <ButtonNew type="noAllow" onClick={showAlertError}>
                Error
              </ButtonNew>
            </Space>
          </CardNew>
          <CardNew topic="CodeExample" icon="AlignJustify" toggledrop="false">
            <CodeBlock code={code} />
          </CardNew>
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default AlertIndex
