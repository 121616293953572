import React from 'react'
import { Row, Col } from 'antd'
import { Trans } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import AuditTeamList from './AuditTeamList'

/**
 * @function AuditTeamIndex
 * @description
 * Menu: AuditTeam
 * เมนู: จัดการข้อมูลทีมตรวจสอบ
 * @returns Component
 */
const AuditTeamIndex = (props) => {
  const arrCrumb = [
    { label: <Trans>จัดการข้อมูลพื้นฐาน</Trans>, path: '#' },
    { label: <Trans>จัดการข้อมูลทีมตรวจสอบ</Trans> },
  ]
  const { menu } = props
  const Responsive = {
    md: 24,
    lg: 24,
  }
  return (
    <React.Fragment>
      <Row>
        <Col {...Responsive}>
          <Breadcrumb data={arrCrumb} title={<Trans>จัดการข้อมูลทีมตรวจสอบ</Trans>} />
          <AuditTeamList menu={menu} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AuditTeamIndex
