import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import AdhocjobList from './AdhocjobList'
import { useNavigate, useParams } from 'react-router-dom'
import { decryptData } from '../../../utilitys/crypto'

const AdhocjobIndex = (props) => {
  const { menu } = props
  const Responsive = {
    md: 24,
    lg: 24,
  }

  const navigate = useNavigate()

  const param = useParams()

  useEffect(() => {
    if (param?.tabParam === 'overviewfollowup') {
      const localS = localStorage.getItem('adhocjob')
      if (localS) {
        const deData = decryptData(localS)
        if (deData) {
          localStorage.removeItem('adhocjob')
          navigate(`/auditoperations/adhocjob/tab/information`, {
            state: { type_action: 'view', refCode: deData.jobCode },
          })
        }
      }
    }
  }, [param?.tabParam])

  return (
    <>
      <Row>
        <Col {...Responsive}>
          <AdhocjobList menu={menu} />
        </Col>
      </Row>
    </>
  )
}

export default AdhocjobIndex
