import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useQuery } from '@apollo/client'
import { GET_ASSIGN_JOB_PERSONNELS } from './graphql/Query'
import { Select } from '../../select/Select'

const SelectAssignJobPersonnels = (props) => {
  const { placeholder, formname, handleChange, ...otherProp } = props

  const [isData, isSetData] = useState([])

  const getAssignJobPersonnels = useQuery(GET_ASSIGN_JOB_PERSONNELS)

  useEffect(() => {
    if (getAssignJobPersonnels.data) {
      isSetData(
        _.map(getAssignJobPersonnels?.data?.getAssignJobPersonnels, (item) => {
          return {
            label: item.personnelName,
            value: item?.personnelCode,
            source: item,
          }
        }),
      )
    }
  }, [getAssignJobPersonnels.data])
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getAssignJobPersonnels.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectAssignJobPersonnels
