import { gql } from '@apollo/client'

export const GET_RISK_FORMULAR_PAGINATION = gql`
  query getRiskFormulaPagination($input: RiskFormulaPaginationInput!) {
    getRiskFormulaPagination(input: $input) {
      result {
        riskFormulaCode
        riskFormulaNameTH
        riskFormulaNameEN
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_RISK_FORMULAR_BY_CODE = gql`
  query getRiskFormulaByCode($riskFormulaCode: String!) {
    getRiskFormulaByCode(riskFormulaCode: $riskFormulaCode) {
      riskFormula {
        riskFormulaCode
        riskFormulaNameTH
        riskFormulaNameEN
        isActive
      }
      riskFormulaVariables {
        riskFormulaCode
        variableCode
        variableName
        variableDetailTH
        variableDetailEN
        variableType
        isActive
      }
      riskFormulaCalculates {
        riskFormulaCode
        variableCode
        operationType
      }
      riskFormulaScores {
        riskFormulaCode
        riskLevelCode
        scoreBegin
        scoreEnd
        scoreColor
      }
    }
  }
`

export const GET_RISK_FORMULAR_VARIABLE_BY_CODE = gql`
  query getRiskFormulaVariablesByCode($variableCode: String!) {
    getRiskFormulaVariablesByCode(variableCode: $variableCode) {
      riskFormulaVariable {
        variableCode
        variableName
        variableDetailTH
        variableDetailEN
        variableType
        riskFormulaCode
        isActive
      }
      riskFormulaValues {
        variableCode
        valueVariable
        valueDetail
        valueEffect
        riskLevelCode
        color
        isActive
      }
    }
  }
`
