import React, { useEffect } from 'react'
import { InputNew, InputNumberNew, InputTagNew } from '../../../components/input/Input'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { Row, Col, Input, Form } from 'antd'
import { IconNew } from '../../../components/icon/Icon'
import { LabelNew } from '../../../components/label/Label'
import { ButtonNew } from '../../../components/button/Button'

function InputIndex() {
  const [form] = Form.useForm()

  let code = ` import { LabelNew } from '../../../components/label/Label'   
    export default function App() {
        return <> 
          <InputNew placeholder="Medium with Icon" shape="searchbar" prefix={<IconNew icon={'Search'} />} />
        
          <LabelNew type="header"> InputText </LabelNew>
          <InputNew placeholder="modal input field" />
          <InputNew placeholder="field in Form" />
          <InputNew placeholder="Input Password" hide="true" />
          <InputNew showCount maxLength={20} />

          <LabelNew type="header"> InputNumber </LabelNew>
          <InputNumberNew defaultValue={1} align="right" />
          <InputNumberNew defaultValue={1} step={0.01} align="right" />
          <InputNumberNew step={0.01} defaultValue={1} disabled={true} />

          <LabelNew type="header"> InputTag </LabelNew>
          <Form.Item name="inputTag2">
            <InputTagNew
              scrollableId="form_tag"
              handleChange={(value) => {
                form.setFieldsValue({
                  inputTag2: value,
                })
              }}
            />
          </Form.Item>

        </>
    }
    `

  //--- ตัวอย่างการ default ค่าใน InputTag
  useEffect(() => {
    form.setFieldsValue({
      inputTag2: ['test1@test.com', 'test2@test.com', 'test3@test.com'],
      inputTag3: ['test1@test.com'],
    })
  }, [])

  return (
    <React.Fragment>
      <div className="site-layout-background">
        <Row gutter={[0, 15]}>
          <Col span={24}>
            <InputNew placeholder="Medium with Icon" shape="searchbar" prefix={<IconNew icon={'Search'} />} />
          </Col>
          <Col span={24}>
            <LabelNew type="header"> InputText </LabelNew>
          </Col>
          <Col span={24}>
            <InputNew placeholder="modal input field" />
          </Col>
          <Col span={24}>
            <InputNew placeholder="field in Form" />
          </Col>

          <Col span={24}>
            <InputNew placeholder="Input Password" hide="true" />
          </Col>
          <Col span={24}>
            <InputNew showCount maxLength={20} rows={4} />
          </Col>
          <Col span={24}>
            <LabelNew type="header"> InputNumber </LabelNew>
          </Col>
          <Col span={24}>
            <InputNumberNew defaultValue={1} maxLength={13} />
          </Col>
          <Col span={24}>
            <InputNumberNew defaultValue={1} step={0.01} align="right" />
          </Col>
          <Col span={24}>
            <InputNumberNew step={0.01} defaultValue={1} disabled={true} />
          </Col>
          <Col span={24}>
            <Input.Group>
              <InputNew placeholder={'ระบุรหัสผ่าน'} hide="true" />
              <ButtonNew style={{ minHeight: '37px', marginLeft: '5px' }} type="primary">
                Generate
              </ButtonNew>
            </Input.Group>
          </Col>
          <Col span={24}>
            <Input.Group>
              <InputNew placeholder="เลขที่งานมอบหมาย" disabled />
              <ButtonNew style={{ minHeight: '37px', marginLeft: '5px' }} type="primary">
                เลขที่งาน
              </ButtonNew>
            </Input.Group>
          </Col>
          <Col span={24}>
            <LabelNew type="header"> InputTag </LabelNew>
          </Col>
          <Col span={24}>
            <InputTagNew name="inputTag" formname="form_tag" />
            <br />

            <Form
              form={form}
              name={'form_tag'}
              onFinish={(v) => {
                console.log('v: ', v)
              }}
            >
              แบบ Default ค่า
              <Form.Item name="inputTag2">
                <InputTagNew
                  formname="form_tag"
                  handleChange={(value) => {
                    form.setFieldsValue({
                      inputTag2: value,
                    })
                  }}
                />
              </Form.Item>
              แบบ Default ค่า และมี Option ให้เลือก
              <Form.Item name="inputTag3">
                <InputTagNew
                  formname="form_tag"
                  data={['A1', 'A2']}
                  handleChange={(value) => {
                    form.setFieldsValue({
                      inputTag3: value,
                    })
                  }}
                />
              </Form.Item>
              <ButtonNew type="primary" htmlType="submit">
                Submit Input Tag
              </ButtonNew>
            </Form>
          </Col>
        </Row>
      </div>

      <CodeBlock code={code} />
    </React.Fragment>
  )
}
export default InputIndex
