import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Space } from 'antd'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Select } from '../../../../components/select/Select'
import { ButtonNew } from '../../../../components/button/Button'
import SelectOperationStatus from '../../../../components/inputfromapi/selectoperationstatus/SelectOperationStatus'
import SelectYear from '../../../../components/inputfromapi/selectyear/SelectYear'
import { CardNew } from '../../../../components/card/Card'
import { encryptInput } from '../../../../utilitys/crypto'
import { LabelNew } from '../../../../components/label/Label'
import { InputNew } from '../../../../components/input/Input'
import { Alert } from '../../../../components/alert/Alert'
import { displayError } from '../../../../utilitys/helper'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { GET_RISK_FORM_FILTERS } from '../graphql/Query'
import { useDecodeUser } from '../../../../hooks/useDecodeUser'

const RiskListFilter = (props) => {
  const { limit, page, getCount, getValue, reFetch, sort, menu, setPage, setLimit } = props
  const { t } = useTranslation()
  const [user] = useDecodeUser()
  const isLang = localStorage.getItem('lang')
  const [form] = Form.useForm()
  const formName = 'filter'
  const [reset, setReset] = useState(false)
  const [orgCode, setOrgCode] = useState(null)
  const [filters, setFilters] = useState({ lang: isLang })

  const Responsive = {
    md: 8,
    lg: 8,
    btn: { md: 24, lg: 24 },
  }

  const [getRiskFormFiltersFn, getRiskFormFilters] = useLazyQuery(GET_RISK_FORM_FILTERS)

  useEffect(() => {
    if (user?.orgCode) setOrgCode(user?.orgCode)
  }, [user?.orgCode])

  useEffect(() => {
    if (isLang && orgCode) {
      getRiskFormFiltersFn({
        variables: {
          input: {
            filters: encryptInput({ ...filters, organization: orgCode }),
            pagination: { limit, page },
            sort: sort,
          },
        },
      })
    }
  }, [page, limit, isLang, reset, reFetch, sort, orgCode, filters])

  useEffect(() => {
    if (getRiskFormFilters.data) {
      const res_ = getRiskFormFilters?.data?.getRiskFormFilters
      getCount(res_.count)
      getValue(res_.result)
    }
    if (getRiskFormFilters.error) {
      const err = getRiskFormFilters.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [getRiskFormFilters?.data])

  const onFinish = async (value) => {
    const localFilter = {
      Year: value?.Year || '',
      riskFormType: value?.riskFormType || '',
      operationStatusCode: value?.operationStatusCode ? String(value?.operationStatusCode) : '' || '',
      organizationName: value?.organizationName || '',
      riskFormTH: value?.riskFormTH || '',
      lang: isLang,
    }

    Promise.all([setPage(1), setLimit(10), setFilters({ ...filters, ...localFilter })])
  }

  return (
    <>
      {getRiskFormFilters.loading && <SpinnersNew />}
      <Row style={{ marginBottom: 24 }}>
        <Col sm={24} md={24} lg={24}>
          <CardNew topic={t('Filters')} icon="List" toggledrop={'true'}>
            <Form form={form} onFinish={onFinish} name={formName} layout="vertical">
              <Row gutter={[12, 12]}>
                <Col {...Responsive}>
                  <Form.Item name={'Year'} label={<LabelNew type="tab-header-inactive">{t('ประจำปี')}</LabelNew>}>
                    <SelectYear
                      formname={formName}
                      placeholder={t('ระบุปี')}
                      handleChange={(v) => form.setFieldsValue({ Year: v.value })}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'riskFormType'}
                    label={<LabelNew type="tab-header-inactive">{t('ประเภทการประเมิน')}</LabelNew>}
                  >
                    <Select
                      title=""
                      data={[
                        { value: 1, label: t('ประเมินสรุป') },
                        { value: 2, label: t('ประเมินรายหน่วยงาน') },
                      ]}
                      placeholder={t('ระบุประเภทการประเมิน')}
                      scrollableId={formName}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'riskFormTH'}
                    label={<LabelNew type="tab-header-inactive">{t('ชื่อแบบประเมิน')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุชื่อแบบประเมิน')} />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'organizationName'}
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยรับตรวจ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุหน่วยรับตรวจ')} />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'operationStatusCode'}
                    label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                  >
                    <SelectOperationStatus
                      formname={formName}
                      placeholder={t('เลือกสถานะ')}
                      needOperationStatusCode={true}
                      handleChange={(e) => form.setFieldsValue({ operationStatusCode: e?.value })}
                      filters={{ operationStatusType: 1, isActive: 1 }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col {...Responsive.btn} align="center">
                  <Space size={[8, 8]} wrap>
                    <ButtonNew type="search" roles={{ type: 'view', menu: menu }} htmlType="submit">
                      {t('ค้นหา')}
                    </ButtonNew>
                    &nbsp;
                    <ButtonNew
                      type="clear"
                      onClick={() => {
                        form.resetFields()
                        setFilters({ lang: isLang })
                        setPage(1)
                        setLimit(10)
                        setReset(reset ? false : true)
                      }}
                    >
                      {t('ล้างค่า')}
                    </ButtonNew>
                  </Space>
                </Col>
              </Row>
            </Form>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default RiskListFilter
