import { useLazyQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError, displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import ExportModalById from './components/modal/ExportModalById'
import { GET_LIST_SYSTEM_USERS_REPORT_PAGINATION } from './graphql/Query'
import SystemUsersReportFilters from './SystemUsersReportFilters'
import { Columns } from './utils/SystemUsersReportListColumn'
import { useDecodeUser } from '../../../hooks/useDecodeUser'

const SystemUsersReportList = ({ menu }) => {
  const { t } = useTranslation()
  const [user] = useDecodeUser()

  // Breadcrumb
  const arrCrumb = [{ label: t('รายงาน') }, { label: t('รายงานข้อมูลผู้ใช้งาน') }]

  // Antd form
  const Responsive = {
    md: 24,
    lg: 24,
  }

  // Data Table Pagination
  const [dataRow, setdataRow] = useState([])
  const [filters, setfilters] = useState({})
  const [count, setcount] = useState(0)

  const [paginate, setPaginate] = useState({ page: 1, pageSize: 10 })
  const [sort, setsort] = useState([{ fieldSort: 'projectCode', sortType: 'ASC' }])

  //=========================== API Pagination ===========================
  const [isPressFilter, setisPressFilter] = useState(false)

  useEffect(() => {
    if (sort && filters && isPressFilter && paginate) fncGetList()
  }, [sort, filters, isPressFilter, paginate])

  const [callList, { loading: loadingList, error: errorList, data: dataList }] = useLazyQuery(
    GET_LIST_SYSTEM_USERS_REPORT_PAGINATION,
  )

  useEffect(() => {
    if (dataList) {
      let { result, count } = dataList.getListSystemUsersReportPagination

      let dataTemp = result.map((item, key) => {
        let fullname = displayText(item.fullnameTH, item.fullnameTH)
        let organizationName = displayText(item.organizationNameTH, item.organizationNameEN)
        return {
          ...item,
          key: key,
          fullname,
          organizationName,
        }
      })
      setdataRow(dataTemp)

      setcount(count)
    }
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: displayError(errorList),
      })
    }
  }, [dataList])

  const fncGetList = () => {
    const dataInput = {
      sort: sort,
      filters: { ...filters, personnelCode: user.pCode },
      pagination: {
        page: paginate.page,
        limit: paginate.pageSize,
      },
    }
    callList({
      variables: { input: encryptInput(dataInput) },
    })
  }

  const onGetFilter = (data) => {
    setisPressFilter(true)
    setfilters({ ...data, isActive: data.isActive === 1 ? '1' : '0' })
  }

  //=========================== Modal Export ===========================
  const [modalVisible, setmodalVisible] = useState(false)
  const [userId, setuserId] = useState()

  const fncOnpressExport = (data) => {
    setuserId(data.userId)
    setmodalVisible(true)
  }

  return (
    <React.Fragment>
      <BreadcrumbNew data={arrCrumb} title={t('รายงานข้อมูลผู้ใช้งาน')} />
      <SystemUsersReportFilters menu={menu} getValue={onGetFilter} />
      <ExportModalById
        userId={userId}
        visible={modalVisible}
        onModalClose={() => {
          setmodalVisible(false)
        }}
      />
      <CardNew topic={t('รายงานข้อมูลผู้ใช้งาน')} icon="List">
        <Row>
          <Col {...Responsive}>
            <Datatable
              columns={Columns({ fncOnpressExport, menu })}
              data={dataRow}
              total={count}
              searchCustom={true}
              // pagination={true}
              pageSize={paginate.pageSize}
              page={paginate.page}
              isLoading={loadingList}
              paginationCustom={true}
              onChangePagination={({ page, pageSize }) => {
                setPaginate({ page, pageSize })
              }}
              handleTableChange={(e) => {
                const reNewField = [
                  { username: 'username' },
                  { email: 'email' },
                  { fullname: displayText('fullnameTH', 'fullnameEN') },
                  { organizationName: displayText('organizationNameTH', 'organizationNameEN') },
                  { isActive: 'isActive' },
                ]

                const data = handleSort(e?.sorter, reNewField)

                setsort(data)
              }}
            />
          </Col>
        </Row>
      </CardNew>
    </React.Fragment>
  )
}

export default SystemUsersReportList
