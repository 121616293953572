OverviewFollowUpProject

import { Card, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import _, { isEmpty } from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

import { Datatable } from '../../../../components/datatable/Datatable'
import { NoData } from '../../../../components/nodatagraph/NoData'

import { GET_DATA_OVERVIEW_FOLLOWUP_PROJECT } from './graphql/Query'

import { encryptData, encryptInput } from '../../../../utilitys/crypto'
import { Alert } from '../../../../components/alert/Alert'

import { displayError, displayText } from '../../../../utilitys/helper'

import { Columns } from './utils/OverviewFollowUpProjectColumn'
import { TableAmountStyled } from './css/TableAmountStyled'
import { handleSort } from '../../../../utilitys/pagination'
import { SpinnersSmaillNew } from '../../../../components/spinners/Spinners'
import { dateDisplay } from '../../../../utilitys/dateHelper'

const Responsive = {
  md: 24,
  lg: 24,
}

const OverviewFollowUpProject = (props) => {
  const { dataFilter } = props

  const { t } = useTranslation()

  const [total, settotal] = useState(0)
  const [dataRow, setdataRow] = useState([])
  const [paginate, setPaginate] = useState({ page: 1, limit: 10 })
  const [sort, setsort] = useState([{ fieldSort: 'actionplanId', sortType: 'ASC' }])

  // =================================== API =======================================
  const [callGraphProjectFnc, { data: dataGraph, loading: loadingGraph, error: errorGraph }] = useLazyQuery(
    GET_DATA_OVERVIEW_FOLLOWUP_PROJECT,
  )

  useEffect(() => {
    if (dataGraph) {
      const { result, count } = dataGraph.getDataOverviewFollowUpProject
      setdataRow(
        result.map((items) => {
          const dateStart = moment(items.dateStart)

          const dateEnd = moment(items.dateEnd)

          const dateStart_step = moment(items.dateStart_step)
          const dateEnd_step = moment(items.dateEnd_step)

          return {
            ...items,
            projectName: displayText(items.projectNameTH, items.projectNameEN),
            projectTypeName: displayText(items.projectTypeNameTH, items.projectTypeNameEN),
            auditTeamName: displayText(items.auditTeamNameTH, items.auditTeamNameEN),
            stepTypeName: items.stepTypeNameEN,
            dateStart: dateDisplay(items.dateStart),
            dateEnd: dateDisplay(items.dateEnd),
            dateStart_step: dateDisplay(items.dateStart_step),
            dateEnd_step: dateDisplay(items.dateEnd_step),
            manday: _.isNaN(dateEnd.diff(dateStart, 'days')) ? '--' : dateEnd.diff(dateStart, 'days'),
            manDay_step: _.isNaN(dateEnd_step.diff(dateStart_step, 'days'))
              ? '--'
              : dateEnd_step.diff(dateStart_step, 'days'),
            key: items.no + 'project',
          }
        }),
      )
      settotal(count)
    }

    if (errorGraph) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorGraph)),
        onOk() {},
      })
    }
  }, [dataGraph])

  // =================================== CAll API =======================================
  const {
    auditTeamCode,
    dateEnd,
    dateStart,
    jobStatusCode,
    organizationCode,
    stepTypeCode,
    projectCode,
    dashboardType,
    graph,
  } = dataFilter

  useEffect(() => {
    FncGetData()
  }, [
    projectCode,
    auditTeamCode,
    dateEnd,
    dateStart,
    jobStatusCode,
    organizationCode,
    stepTypeCode,
    paginate.page,
    paginate.limit,
    sort,
    graph,
  ])

  useEffect(() => {
    setPaginate({ page: 1, limit: paginate.limit })
  }, [projectCode])

  const FncGetData = () => {
    setdataRow([])
    if (graph === 3) return

    if ((dateEnd && dateStart && !isEmpty(organizationCode)) || projectCode) {
      const dataInput = {
        sort: sort,
        filters: {
          auditTeamCode: auditTeamCode ? auditTeamCode : null,
          dateEnd: dateEnd ? dateEnd : null,
          dateStart: dateStart ? dateStart : null,
          jobStatusCode: jobStatusCode ? jobStatusCode : null,
          organizationCode: organizationCode ? organizationCode : null,
          stepTypeCode: stepTypeCode ? stepTypeCode : null,
          dashboardType: dashboardType ? dashboardType : 1,
          projectCode: projectCode ? projectCode : null,
        },
        pagination: {
          page: paginate.page,
          limit: paginate.limit,
        },
      }

      callGraphProjectFnc({
        variables: {
          input: encryptInput(dataInput),
        },
      })
    }
  }

  const onRowClick = async (record) => {
    const data = {
      actionplanId: record.actionplanId,
      projectCode: record.projectCode,
      auditTeamCode: record.auditTeamCode,
    }
    const enData = encryptData(data)
    localStorage.setItem('actionPlan', enData)

    const route = `/auditoperations/actionplan/overviewfollowup`
    window.open(route, '_blank')
  }

  return (
    <TableAmountStyled>
      <Card className="">
        <Row gutter={[16, 8]}>
          <Col {...Responsive}>
            {loadingGraph && <SpinnersSmaillNew />}
            {!_.isEmpty(dataRow) ? (
              <Datatable
                columns={Columns({})}
                data={dataRow}
                total={total}
                searchCustom={false}
                paginationCustom={true}
                scroll={{ x: 2000 }}
                enableScroll={{ x: true, y: false }}
                pageSize={paginate.limit}
                page={paginate.page}
                onChangePagination={({ page, pageSize }) => {
                  setPaginate({ page, limit: pageSize })
                }}
                showSizeChanger={true}
                handleTableChange={(e) => {
                  const reNewField = [
                    { no: 'actionplanId' },
                    { projectName: displayText('projectNameTH', 'projectNameEN') },
                    { projectTypeName: displayText('projectTypeNameTH', 'projectTypeNameEN') },
                    { auditTeamName: displayText('auditTeamNameTH', 'auditTeamNameEN') },
                    { stepTypeName: displayText('stepTypeNameTH', 'stepTypeNameEN') },
                  ]

                  setsort(handleSort(e?.sorter, reNewField))
                }}
                onRow={(record) => ({
                  onClick: () => {
                    onRowClick(record)
                  },
                })}
              />
            ) : (
              <NoData />
            )}
          </Col>
        </Row>
      </Card>
    </TableAmountStyled>
  )
}

export default OverviewFollowUpProject
