/* eslint-disable no-unused-vars */
import { useTranslation } from 'react-i18next'
import {
  regexEng,
  regexThai,
  regexEngNoSpace,
  regexEngNoSpaceUser,
  regexPassword,
  regexEmail,
  regexNumber,
} from './helper'
import _ from 'lodash'

export const validateEng = (required = false, message = false, other = false) => {
  const { t } = useTranslation()

  let validObj = {
    validator: (_, value) => {
      if (!value && message) {
        return Promise.reject(new Error(t(message)))
      } else if (value && value.length && !regexEng.test(value)) {
        return Promise.reject(new Error(t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`)))
      } else {
        return Promise.resolve()
      }
    },
  }

  if (required) validObj.required = true

  return !other ? [{ ...validObj }] : [{ ...validObj, ...other }]
}

export const validateTh = (required, message = false, other = false) => {
  const { t } = useTranslation()

  let validObj = {
    validator: (_, value) => {
      if (!value) {
        return Promise.reject(new Error(t(message)))
      } else if (value.length && !regexThai.test(value)) {
        return Promise.reject(new Error(t(`กรุณากรอกด้วยภาษาไทยหรือตัวเลข ก-๙, 0-9`)))
      } else {
        return Promise.resolve()
      }
    },
  }

  if (required) validObj.required = true

  return !other ? [{ ...validObj }] : [{ ...validObj, ...other }]
}

export const validateMaxLength = (required, message = false, maxLength, other = false) => {
  const { t } = useTranslation()

  let validObj = {
    validator: (_, value) => {
      if (!value) {
        return Promise.reject(new Error(t(message)))
      } else if (value?.length > maxLength) {
        return Promise.reject(new Error(t(`กรุณากรอกข้อมูลได้ไม่เกินจำนวน ${maxLength} อักษร`)))
      } else {
        return Promise.resolve()
      }
    },
  }

  if (required) validObj.required = true

  return !other ? [{ ...validObj }] : [{ ...validObj, ...other }]
}

export const validateCode = (required, message = false, other = false) => {
  const { t } = useTranslation()

  let validObj = {
    validator: (_, value) => {
      if (!value) {
        return Promise.reject(new Error(t(message)))
      } else if (value.length && !regexEngNoSpace.test(value)) {
        return Promise.reject(new Error(t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, 0-9`)))
      } else {
        return Promise.resolve()
      }
    },
  }

  if (required) validObj.required = true

  return !other ? [{ ...validObj }] : [{ ...validObj, ...other }]
}

export const validateUserId = (required, message = false, other = false) => {
  const { t } = useTranslation()

  let validObj = {
    validator: (_, value) => {
      if (!value) {
        return Promise.reject(new Error(t(message)))
      } else if (value.length && !regexEngNoSpaceUser.test(value)) {
        return Promise.reject(new Error(t(`กรุณากรอกด้วยภาษาอังกฤษ A-Z, a-z, 0-9 . _`)))
      } else {
        return Promise.resolve()
      }
    },
  }

  if (required) validObj.required = true

  return !other ? [{ ...validObj }] : [{ ...validObj, ...other }]
}

export const validateEmail = (required, message = false, other = false) => {
  const { t } = useTranslation()

  let validObj = {
    validator: (_, value) => {
      if (!value) {
        return Promise.reject(new Error(t(message)))
      } else if (value.length && !regexEmail.test(value)) {
        return Promise.reject(new Error(t(`กรุณากรอกรูปแบบอีเมลให้ถูกต้อง`)))
      } else {
        return Promise.resolve()
      }
    },
  }

  if (required) validObj.required = true

  return !other ? [{ ...validObj }] : [{ ...validObj, ...other }]
}

export const validatePassword = (required, message = false, other = false, condition = true) => {
  const { t } = useTranslation()

  let validObj = {
    validator: (_, value) => {
      if (condition) {
        if (!value) {
          return Promise.reject(new Error(t(message)))
        } else if (value.length && !regexPassword.test(value)) {
          return Promise.reject(new Error(t(`ต้องใส่อย่างละ 1 เช่น A , a , 0-9 และตัวอักษรพิเศษ !@#$`)))
        } else {
          return Promise.resolve()
        }
      } else {
        if (value.length && !regexPassword.test(value)) {
          return Promise.reject(new Error(t(`ต้องใส่อย่างละ 1 เช่น A , a , 0-9 และตัวอักษรพิเศษ !@#$`)))
        } else {
          return Promise.resolve()
        }
      }
    },
  }

  if (required) validObj.required = true

  return !other ? [{ ...validObj }] : [{ ...validObj, ...other }]
}

export const validateComparPassword = (
  required,
  message = false,
  other = false,
  condition = true,
  password = '',
  confirmPassword = '',
) => {
  const { t } = useTranslation()

  let validObj = {
    validator: (_, value) => {
      if (condition) {
        if (!value) {
          return Promise.reject(new Error(t(message)))
        } else if (confirmPassword !== password) {
          return Promise.reject(new Error(t(`ระบุยืนยันรหัสผ่านให้ตรงรหัสผ่าน`)))
        } else {
          return Promise.resolve()
        }
      } else {
        if (password !== '' || confirmPassword !== '') {
          if (confirmPassword !== password) {
            return Promise.reject(new Error(t(`ระบุยืนยันรหัสผ่านให้ตรงรหัสผ่าน`)))
          } else {
            return Promise.resolve()
          }
        } else {
          return Promise.resolve()
        }
      }
    },
  }

  if (required) validObj.required = true

  return !other ? [{ ...validObj }] : [{ ...validObj, ...other }]
}

export const validateRange = (required, message = false, other = false, min, max) => {
  const { t } = useTranslation()

  let validObj = {
    validator: (_, value) => {
      if (min && value < min) {
        return Promise.reject(new Error(t(`กรุณากรอกเลขไม่ต่ำกว่า ` + min)))
      } else if (max && value > max) {
        return Promise.reject(new Error(t(`กรุณากรอกเลขไม่เกิน ` + max)))
      } else {
        return Promise.resolve()
      }
    },
  }

  return !other ? [{ ...validObj }] : [{ ...validObj, ...other }]
}
