import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Col, Form, Row } from 'antd'

import { ModalNew } from '../../../components/modal/Modal'
import { InputNew } from '../../../components/input/Input'
import { Radio, RadioGroup } from '../../../components/radio/Radio'
import { Alert } from '../../../components/alert/Alert'

import { displayError, regexEng, regexNumber } from '../../../utilitys/helper'
import { SAVE_OPERATION_SETUP_DETAIL } from './graphql/Mutation'
import { encryptInput } from '../../../utilitys/crypto'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import SelectStepProcess from '../../../components/inputfromapi/selectstepprocess/SelectStepProcess'
import SelectStepTypes from '../../../components/inputfromapi/selectsteptype/SelectStepType'
// import { SpinnersNew } from '../../../components/spinners/Spinners'
// import { encryptInput } from '../../../utilitys/crypto'

const OperationSetupFormModalMain = ({
  stepModelCode,
  visible = false,
  formType = 'view',
  dataForm = null,
  onSuccess = () => {},
  onClose = () => {},
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [editable, seteditable] = useState(false)

  useEffect(() => {
    if (formType) {
      seteditable(formType !== 'view')
    }
  }, [formType])

  useEffect(() => {
    if (dataForm) {
      form.setFieldsValue(dataForm)
    }
  }, [dataForm])

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] =
    useMutation(SAVE_OPERATION_SETUP_DETAIL)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          onSuccess()
        },
      })
    }
  }, [dataSave])

  const onFinish = () => {
    const fields = form.getFieldValue()

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        let dataInput = {
          isActive: fields.isActive,
          stepDetailType: 1,
          stepSort: fields.stepSort,
          stepDetailTH: fields.stepDetailTH,
          stepModelCode,
          stepTypeCode: fields.stepTypeCode,
          stepProcess: fields.stepProcess,
        }

        if (formType === 'add') {
          dataInput = {
            ...dataInput,
            stepDetailEN: fields.stepDetailEN ? fields.stepDetailEN : fields.stepDetailTH,
            typeAction: 'add',
          }
        }
        if (formType === 'edit') {
          dataInput = {
            ...dataInput,
            stepDetailId: fields.stepDetailId,
            stepDetailEN: fields.stepDetailEN,
            typeAction: 'edit',
          }
        }

        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const footerHide =
    formType === 'view'
      ? { cancelButtonProps: { style: { display: 'none' } }, okButtonProps: { style: { display: 'none' } } }
      : {}

  const renderTitle = () => {
    if (formType === 'add') {
      return 'เพิ่มขั้นตอนหลัก'
    }
    if (formType === 'view') {
      return 'ดูขั้นตอนหลัก'
    }
    if (formType === 'edit') {
      return 'แก้ไขขั้นตอนหลัก'
    }

    return ''
  }

  const formName = 'stepModelMain'

  return (
    <React.Fragment>
      {loadingSave && <SpinnersNew />}
      <ModalNew
        visible={visible}
        onSubmit={form.submit}
        onClose={(data) => {
          onClose(data)
          form.resetFields()
        }}
        testTitle={t(renderTitle())}
        typeAction={formType}
        type="medium"
        {...footerHide}
      >
        {/* {loadingSave && <SpinnersNew />} */}
        <Form
          name={formName}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 8,
            lg: 8,
          }}
          layout="horizontal"
        >
          <Form.Item
            name="stepSort"
            label={t('ขั้นที่')}
            rules={[{ required: true, pattern: regexNumber, message: t(`กรุณากรอกตัวเลข 0-9`) }]}
          >
            <InputNew disabled={formType === 'edit' || !editable} placeholder={t('กรอกขั้นที่')} />
          </Form.Item>

          <Form.Item
            name="stepDetailTH"
            label={t('ชื่อขั้นตอนหลักภาษาไทย')}
            rules={[{ required: true, message: t('กรุณาระบุ') }]}
          >
            <InputNew disabled={!editable} placeholder={t('กรอกชื่อขั้นตอนหลักภาษาไทย')} />
          </Form.Item>
          <Form.Item
            name="stepDetailEN"
            label={t('ชื่อขั้นตอนหลักภาษาอังกฤษ')}
            rules={[{ pattern: regexEng, message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`) }]}
          >
            <InputNew disabled={!editable} placeholder={t('กรอกชื่อขั้นตอนหลักภาษาอังกฤษ')} />
          </Form.Item>
          <Form.Item
            name="stepProcess"
            label={t('ขั้นตอนของกระบวนการ')}
            rules={[{ required: true, message: t(`กรุณาเลือก`) }]}
          >
            <SelectStepProcess
              disabled={!editable}
              formname={formName}
              placeholder={t('เลือกขั้นตอนของกระบวนการ')}
              handleChange={(data) => {
                const value = data ? data.value : null
                form.setFieldsValue({ stepProcess: value })
              }}
            />
          </Form.Item>
          <Form.Item
            name="stepTypeCode"
            label={t('ประเภทขั้นตอน')}
            rules={[{ required: true, message: t(`กรุณาเลือก`) }]}
          >
            <SelectStepTypes
              disabled={!editable}
              formname={formName}
              placeholder={t('เลือกประเภทขั้นตอน')}
              handleChange={(data) => {
                const value = data ? data.value : null
                form.setFieldsValue({ stepTypeCode: value })
              }}
            />
          </Form.Item>

          <Form.Item name="isActive" label={t('สถานะ')} initialValue={1}>
            <RadioGroup disabled={!editable}>
              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                </Col>
                <Col>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </React.Fragment>
  )
}
export default OperationSetupFormModalMain
