import React from 'react'
import { Row, Col } from 'antd'
import { Trans } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import ControlTypeList from './ControlTypeList'

/**
 * @function ControlTypeIndex
 * @description
 * Menu: ControlType
 * เมนู: จัดการข้อมูลประเภทการควบคุม
 * @returns Component
 */
const arrCrumb = [
  { label: <Trans>จัดการข้อมูลพื้นฐาน</Trans>, path: '#' },
  { label: <Trans>จัดการข้อมูลประเภทการควบคุม</Trans> },
]
const ControlTypeIndex = (props) => {
  const { menu } = props
  const Responsive = {
    md: 24,
    lg: 24,
  }
  return (
    <React.Fragment>
      <Row>
        <Col {...Responsive}>
          <Breadcrumb data={arrCrumb} title={<Trans>จัดการข้อมูลประเภทการควบคุม</Trans>} />
          <ControlTypeList menu={menu} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default ControlTypeIndex
