import { useState } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { CardNew as Card } from '../../../../../components/card/Card'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { InputNew as Input } from '../../../../../components/input/Input'
import { ButtonNew as Button } from '../../../../../components/button/Button'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'

const AcResolutionListFilter = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)

  const formName = 'AcResolutionListFilter'
  const ResponsiveValues = {
    sm: 24,
    md: 12,
    lg: 8,
  }

  const onFinish = (values) => {
    props.setFilters(values)
  }

  const resetFilters = () => {
    form.resetFields()
    props.setFilters({})
  }

  return (
    <Card topic={t('Filters')} icon="AlignJustify" toggledrop="true" buttontopright="true">
      <Row>
        <Col md={24}>
          <Form form={form} onFinish={onFinish} name={formName} layout="vertical">
            <Row gutter={[12, 12]}>
              <Col {...ResponsiveValues}>
                <Form.Item label={<Label type="tab-header-inactive">{t('ครั้งที่/ปี')}</Label>} name="resTimes">
                  <Input placeholder={t('ครั้งที่/ปี')} />
                </Form.Item>
              </Col>
              <Col {...ResponsiveValues}>
                <Form.Item name={'dateStart'} label={<Label type="tab-header-inactive">{t('วันที่เริ่มต้น')}</Label>}>
                  <Datepicker
                    setValueDateFn={(data) => {
                      setIsMinDate(data)
                      form.setFieldsValue({ dateStart: data })
                    }}
                    onClear={() => {
                      form.setFieldsValue({ dateStart: null })
                    }}
                    isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col {...ResponsiveValues}>
                <Form.Item name={'dateEnd'} label={<Label type="tab-header-inactive">{t('วันที่สิ้นสุด')}</Label>}>
                  <Datepicker
                    setValueDateFn={(data) => {
                      setIsMaxDate(data)
                      form.setFieldsValue({ dateEnd: data })
                    }}
                    onClear={() => {
                      form.setFieldsValue({ dateEnd: null })
                    }}
                    isMinDate={isMinDate ? new Date(isMinDate) : null}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col {...ResponsiveValues}>
                <Form.Item label={<Label type="tab-header-inactive">{t('สถานที่ประชุม')}</Label>} name="location">
                  <Input placeholder={t('สถานที่ประชุม')} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]} justify="center">
              <Col>
                <Button type="search" roles={{ type: 'view', menu: props.menu }} htmlType="submit">
                  {t('ค้นหา')}
                </Button>
              </Col>
              <Col>
                <Button type="clear" onClick={() => resetFilters()}>
                  {t('ล้างค่า')}
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Card>
  )
}

export default AcResolutionListFilter
