import { ApolloClient, InMemoryCache, gql, split, HttpLink } from '@apollo/client'
import moment from 'moment'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'
import promiseToObservable from '../utilitys/promiseToObservable'
import { encryptData, decryptData } from '../utilitys/crypto'
import { Alert } from '../components/alert/Alert'

// const httpLink = createHttpLink({ uri: `${process.env['REACT_APP_API']}` })
const httpLink = new HttpLink({
  uri: `${process.env['REACT_APP_API']}`,
})

const wsLink = new WebSocketLink({
  uri: `${process.env['REACT_APP_API_SUB']}`,
  options: {
    reconnect: true,
  },
})

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsLink,
  httpLink,
)

const authLink = setContext((_, { headers }) => {
  let access_token = ''
  if (localStorage.getItem('user')) {
    const userObj = decryptData(localStorage.getItem('user'))
    access_token = userObj.access_token
  }
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${access_token}`,
    },
  }
})
const lang = localStorage.getItem('lang')
export const newToken = () => {
  const user = decryptData(localStorage.getItem('user'))
  return new Promise((resolve, reject) => {
    client
      .mutate({
        variables: { refreshToken: user.refresh_token, userId: user.userId, logfileLoginId: user.logfileLoginId },
        mutation: gql`
          mutation GetAccessToken($refreshToken: String!, $userId: Float!, $logfileLoginId: Float!) {
            getAccessToken(refresh_token: $refreshToken, userId: $userId, logfileLoginId: $logfileLoginId) {
              userId
              access_token
              refresh_token
              logfileLoginId
            }
          }
        `,
      })
      .then((result) => {
        const userEncrypt = localStorage.getItem('user')
        const dataUser = decryptData(userEncrypt)
        const { access_token, refresh_token } = result.data.getAccessToken
        const newEncrypt = encryptData({ ...dataUser, access_token: access_token, refresh_token: refresh_token })
        localStorage.setItem('user', newEncrypt)
        localStorage.setItem('sess_start_time', encryptData(moment().format('YYYY-MM-DD HH:mm:ss')))
        resolve(access_token)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      if (err.status) {
        const { status } = err
        switch (status) {
          case 401:
            return promiseToObservable(newToken()).flatMap((token) => {
              operation.setContext({
                headers: {
                  ...operation.getContext().headers,
                  authorization: `Bearer ${token}`,
                },
              })
              return forward(operation)
            })
          case 441:
            Alert({
              type: 'warning',
              title: lang === 'th' ? 'เซสชั่นหมดอายุ' : 'Session Expired.',
              onOk() {
                localStorage.removeItem('user')
                window.location.href = '/login'
              },
            })
            return
          default:
            return
        }
      }
    }
  }
})

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(errorLink).concat(splitLink),
  defaultOptions: {
    watchQuery: { fetchPolicy: 'no-cache', errorPolicy: 'all' },
    query: { fetchPolicy: 'no-cache', errorPolicy: 'all' },
    mutate: { errorPolicy: 'all' },
  },
})
