import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Form, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/alert/Alert'
import { ButtonNew as Button } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew as Card } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { IconNew } from '../../../components/icon/Icon'
import { InputNew as Input } from '../../../components/input/Input'
import { LabelNew as Label } from '../../../components/label/Label'
import { ModalNew } from '../../../components/modal/Modal'
import { Radio, RadioGroup } from '../../../components/radio/Radio'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayError } from '../../../utilitys/helper'
import { MainTable, SubTable } from './css/Styles'
import { AUDITOPENS_DELETE } from './graphql/Mutation'
import { AUDITOPENS_EXPORT, GET_AUDITOPENS_PAGINATION } from './graphql/Query'

const AuditOpensList = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [modalExport, setModalExport] = useState(false)
  const [codeExport, setCodeExport] = useState('')
  const [formExport] = Form.useForm()
  const [waterMark, setWaterMark] = useState(false)
  const [auditOpensExportFn, auditOpensExport] = useLazyQuery(AUDITOPENS_EXPORT)
  useEffect(() => {
    if (auditOpensExport.data) {
      let response = auditOpensExport?.data?.auditOpensExport
      if (response) {
        if (response.message === 'succcess') {
          window.open(`${response.fileUrl}`, '_blank')
          setModalExport(false)
        } else {
          showAlertNotFoundError()
          setModalExport(false)
        }
      }
    }

    if (auditOpensExport.error) {
      showAlertLoadDataError(displayError(auditOpensExport.error))
    }
  }, [auditOpensExport.data])
  const [auditOpensDeleteFn, auditOpensDelete] = useMutation(AUDITOPENS_DELETE)
  useEffect(() => {
    if (auditOpensDelete.data) {
      let response = auditOpensDelete?.data?.auditOpensDelete
      if (response) {
        showAlertSuccess()
      }
    }

    if (auditOpensDelete.error) {
      showAlertDeleteError(displayError(auditOpensDelete.error))
    }
  }, [auditOpensDelete.data])
  const [getAuditOpensPagenationFn, getAuditOpensPagenation] = useLazyQuery(GET_AUDITOPENS_PAGINATION)
  useEffect(() => {
    if (getAuditOpensPagenation.data) {
      let response = getAuditOpensPagenation?.data?.getAuditOpensPagenation
      if (response) {
        setCount(response.count)
        setDataLists(
          response.result.map((item, i) => {
            return {
              key: item.openId,
              no: (page - 1) * limit + i + 1,
              docNo: item.openNumber ? item.openNumber : '',
              projectName:
                (item.projectCode ? item.projectCode : '') +
                ' : ' +
                (localStorage.getItem('lang') === 'th'
                  ? item.projectNameTH
                    ? item.projectNameTH
                    : ''
                  : item.projectNameEN
                  ? item.projectNameEN
                  : ''),
              dateStart: item.dateStart ? dateDisplay(item.dateStart) : '',
              dateEnd: item.dateEnd ? dateDisplay(item.dateEnd) : '',
              extraBTN: (
                <Button
                  type="flagOutlinedInTableBlue"
                  roles={{ type: 'send', menu: props.menu }}
                  disabled={item.opened ? true : false}
                  onClick={() => {
                    navigate('/auditoperations/auditopens/petition', {
                      state: { refCode: String(item.openId) }
                    })
                  }}
                >
                  {t('เปิดตรวจ')}
                </Button>
              ),
              action: (
                <div style={{ textAlign: 'center' }}>
                  <ButtonGroup
                    menu={props.menu}
                    icons={[
                      {
                        type: 'download',
                        onClick: () => {
                          setCodeExport(item.openId)
                          setModalExport(true)
                        }
                      },
                      {
                        type: 'view',
                        onClick: () => {
                          navigate('/auditoperations/auditopens/information/view', {
                            state: { refCode: item.openId }
                          })
                        }
                      },
                      ...(item.opened === 1
                        ? [
                            {
                              type: 'edit',
                              onClick: () => {
                                navigate('/auditoperations/auditopens/information/edit', {
                                  state: { refCode: item.openId }
                                })
                              }
                            }
                          ]
                        : [
                            {
                              type: 'edit',
                              onClick: () => {
                                navigate('/auditoperations/auditopens/information/edit', {
                                  state: { refCode: item.openId }
                                })
                              }
                            },
                            {
                              type: 'delete',
                              onClick: () => {
                                showConfirmDelete(item.openId)
                              }
                            }
                          ])
                    ]}
                  />
                </div>
              ),
              description: (
                <SubTable>
                  <Datatable
                    scroll={{ x: '100%', y: false }}
                    columns={[
                      {
                        title: '',
                        dataIndex: 'sub1',
                        width: '25%'
                      },
                      {
                        title: '',
                        dataIndex: 'sub3',
                        width: '75%'
                      }
                    ]}
                    data={item.auditOpenOrganizations.map((subItem, j) => {
                      return {
                        key: j,
                        sub3:
                          localStorage.getItem('lang') === 'th'
                            ? subItem.organizationNameTH
                              ? subItem.organizationNameTH
                              : ''
                            : subItem.organizationNameEN
                            ? subItem.organizationNameEN
                            : ''
                      }
                    })}
                  />
                </SubTable>
              )
            }
          })
        )
      }
    }

    if (getAuditOpensPagenation.error) {
      showAlertLoadDataError(displayError(getAuditOpensPagenation.error))
    }
  }, [getAuditOpensPagenation.data])

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {}
    })
  }

  const showConfirmDelete = (openId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        auditOpensDeleteFn({
          variables: {
            input: encryptInput({ openId: openId })
          },
          fetchPolicy: 'no-cache'
        })
      },
      onCancel() {}
    })
  }

  const showAlertDeleteError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถลบข้อมูลได้'),
      content: t(text),
      onOk() {}
    })
  }

  const showAlertSuccess = () => {
    Alert({
      type: 'success',
      title: t('ลบสำเร็จ'),
      onOk() {
        getAuditOpensPagenation.refetch()
      }
    })
  }
  const showAlertNotFoundError = () => {
    Alert({
      type: 'error',
      title: t('ไม่พบข้อมูล'),
      onOk() {}
    })
  }

  const mainColumns = [
    {
      title: <div style={{ textAlign: 'center' }}>{t('ลำดับ')}</div>,
      dataIndex: 'no',
      width: '10%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('เลขที่เปิดตรวจ')}</div>,
      dataIndex: 'docNo',
      width: '15%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('โครงการ')}</div>,
      dataIndex: 'projectName',
      width: '20%'
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('วันที่เริ่มต้น')}</div>,
      dataIndex: 'dateStart',
      width: '15%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('วันที่สิ้นสุด')}</div>,
      dataIndex: 'dateEnd',
      width: '15%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>
    },
    {
      title: '',
      dataIndex: 'extraBTN',
      width: '15%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>
    },
    {
      title: '',
      dataIndex: 'action',
      width: '10%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>
    }
  ]
  const callApiSearch = () => {
    getAuditOpensPagenationFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          filters: {
            lang: localStorage.getItem('lang'),
            projectCode: props.formFilter.getFieldValue('projectCode')
              ? props.formFilter.getFieldValue('projectCode')
              : null,
            organizationCode: props.formFilter.getFieldValue('organizationCode')
              ? props.formFilter.getFieldValue('organizationCode').length
                ? props.formFilter.getFieldValue('organizationCode').join()
                : null
              : null,
            dateStart: props.formFilter.getFieldValue('dateStart') ? props.formFilter.getFieldValue('dateStart') : null,
            dateEnd: props.formFilter.getFieldValue('dateEnd') ? props.formFilter.getFieldValue('dateEnd') : null
          },
          pagination: { limit, page },
          sort: []
        })
      }
    })
  }
  useEffect(() => {
    callApiSearch()
  }, [page, limit])

  useEffect(() => {
    callApiSearch()
  }, [props.searchStatus])

  const auditOpensExportData = (values) => {
    auditOpensExportFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          openId: codeExport,
          fileType: values.fileType,
          waterMark: values.waterMark ? values.waterMark : '',
          lang: localStorage.getItem('lang')
        })
      }
    })
  }
  return (
    <>
      {(getAuditOpensPagenation.loading || auditOpensDelete.loading) && <SpinnersNew />}
      <Card topic={t('รายการการเปิดตรวจ')} icon="AlignJustify" toggledrop="false">
        <Row>
          <Col md={24}>
            <MainTable>
              <Datatable
                columns={mainColumns}
                data={dataLists}
                handleTableChange={() => {}}
                paginationCustom={true}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                showSizeChanger={true}
                searchCustom={true}
                total={count}
                enableScroll={{ x: false, y: true }}
                scroll={{ x: '100%', y: false }}
                pageSize={limit}
                page={page}
                expandable={{
                  expandedRowRender: (record) => record.description,
                  onExpandedRowsChange: () => {},
                  expandIcon: ({ expanded, onExpand, record }) =>
                    expanded ? (
                      <IconNew icon={'ChevronUp'} onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <IconNew icon={'ChevronDown'} onClick={(e) => onExpand(record, e)} />
                    )
                }}
                btnAdd={
                  <>
                    <Button
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: props.menu }}
                      style={{ marginRight: '12px' }}
                      onClick={() => {
                        navigate('/auditoperations/auditopens/information/add')
                      }}
                    >
                      {t('เพิ่มการเปิดตรวจ')}
                    </Button>
                  </>
                }
              />
            </MainTable>
          </Col>
        </Row>
      </Card>
      <ModalNew
        visible={modalExport}
        onSubmit={() => {
          formExport.submit()
        }}
        onClose={() => {
          setModalExport(false)
        }}
        testTitle={t('พิมพ์รายงาน')}
        btnName={t('พิมพ์')}
      >
        <Form
          form={formExport}
          onFinish={auditOpensExportData}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2
          }}
          wrapperCol={{
            span: 10
          }}
          layout="horizontal"
          name="auditOpensExportForm"
        >
          <Form.Item
            label={<Label type="tab-header-inactive">{t('ประเภทรายงาน')}</Label>}
            name="fileType"
            initialValue={'pdf'}
            onClick={(e) => {
              if (e.target.value) {
                if (e.target.value !== 'pdf') {
                  setWaterMark(true)
                } else {
                  setWaterMark(false)
                }
              }
            }}
          >
            <RadioGroup>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="pdf">
                    <Label type="tab-header-inactive">{t('PDF')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="word">
                    <Label type="tab-header-inactive">{t('Word')}</Label>
                  </Radio>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="excel">
                    <Label type="tab-header-inactive">{t('Excel')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="csv">
                    <Label type="tab-header-inactive">{t('CSV')}</Label>
                  </Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
          <Form.Item
            name="waterMark"
            label={<Label type="tab-header-inactive">{t('ลายน้ำ')}</Label>}
            hidden={waterMark}
          >
            <Input placeholder={t('ระบุลายน้ำ (เฉพาะ PDF)')} />
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}

export default AuditOpensList
