import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { ButtonNew } from '../../../../../components/button/Button'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'

const OrganizationGroup = (props) => {
  const responsiveCol = { sm: 24, md: 24, lg: 24 }

  const { orgListError, dataListsFromModal, deletedOrg, menu, viewAction, isCreated } = props

  const { t } = useTranslation()

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)

  useEffect(() => {
    setDataLists(dataListsFromModal ? [...dataListsFromModal] : [])
  }, [dataListsFromModal])

  const columns = [
    { title: t('ลำดับ'), dataIndex: 'key', width: '10%', render: (row, x, index) => index + 1 },
    { title: t('รหัสหน่วยงาน'), dataIndex: 'organizationCode', width: '15%' },
    { title: t('หน่วยงาน'), dataIndex: 'organizationName', width: '20%' },
    { title: t('ระดับหน่วยงาน'), dataIndex: 'organizationLevelName', width: '25%' },
    { title: t('ผู้รับผิดชอบ'), dataIndex: 'personnelName', width: '25%' },
    {
      title: '',
      dataIndex: 'organizationId',
      width: '5%',
      render: (row) => {
        return isCreated && !viewAction ? (
          dataLists?.length > 1 ? (
            <div style={{ textAlign: 'center' }}>
              <ButtonGroup
                menu={menu}
                size={18}
                icons={[
                  {
                    type: 'delete',
                    onClick: () =>
                      dataLists.length > 1 && deletedOrg([..._.filter(dataLists, (e) => e.organizationId !== row)]),
                  },
                ]}
              />
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )
      },
    },
  ]

  return (
    <Col {...responsiveCol}>
      <Datatable
        columns={columns}
        data={[...dataLists]}
        total={dataLists.length}
        onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
        paginationCustom={true}
        enableScroll={{ x: true, y: true }}
        searchCustom={true}
        pageSize={limit}
        page={page}
        btnAdd={
          isCreated ? (
            <>
              {viewAction ? (
                <></>
              ) : (
                <>
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    onClick={() => props.setModal(true)}
                  >
                    {t('เพิ่มหน่วยรับตรวจ')}
                  </ButtonNew>
                  {orgListError ? (
                    <span style={{ color: '#ea5456', margin: 'auto 15px' }}>{t('กรุณาระบุหน่วยรับตรวจ')}</span>
                  ) : null}
                </>
              )}
            </>
          ) : (
            <></>
          )
        }
      />
    </Col>
  )
}

OrganizationGroup.propTypes = {
  menu: PropTypes.string.isRequired,
  dataListsFromModal: PropTypes.array,
  deletedOrg: PropTypes.func.isRequired,
  viewAction: PropTypes.bool.isRequired,
  isCreated: PropTypes.bool.isRequired,
}

export default OrganizationGroup
