import React from 'react'
import { Row, Col } from 'antd'
import ApproveAuditProjectNotPlanList from './ApproveAuditProjectNotPlanList'

export default function ApproveAuditProjectNotPlanIndex(props) {
  const { menu } = props
  const Responsive = {
    md: 24,
    lg: 24,
  }

  return (
    <>
      <Row>
        <Col {...Responsive}>
          <ApproveAuditProjectNotPlanList menu={menu} />
        </Col>
      </Row>
    </>
  )
}
