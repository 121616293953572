import { gql } from '@apollo/client'

export const GET_ACKNOWLEDGE_ISSUE_PAGINATION = gql`
  query AcknowledgeIssuePaginationGet($input: AuditSuggestExplainPaginationInput!) {
    AcknowledgeIssuePaginationGet(input: $input) {
      result {
        actionPlanId
        projectCode
        projectNameTH
        projectNameEN
        projectRefNameTH
        projectRefNameEN
        organizationNameTH
        organizationNameEN
        fullNameTH
        fullNameEN
        status
        dateStart
        dateEnd
      }
      count
      page
      limit
    }
  }
`

export const GET_ACKNOWLEDGE_ISSUE_SUB_PAGINATION = gql`
  query AcknowledgeIssueSubPaginationGet($input: AuditSuggestExplainSubPaginationInput!) {
    acknowledgeIssueSubPaginationGet(input: $input) {
      result {
        suggestId
        rateFacProCode
        rateRiskRuleCode
        rateRiskConCode
        factTH
        factEN
        issueTH
        issueEN
        suggestTH
        suggestEN
        rateFacProNameTH
        rateFacProNameEN
        rateRiskRuleTH
        rateRiskRuleEN
        rateRiskConTH
        rateRiskConEN
        status
        explainTH
        explainEN
      }
      count
      page
      limit
    }
  }
`
