import React, { useState } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'

import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import FilterFollowUpAudit from './FilterFollowUpAudit'
import FollowUpAuditList from './FollowUpAuditList'
const FollowUpAuditIndex = (props) => {
  const { t } = useTranslation()
  const breadcrumbList = [
    { label: t('งานติดตามผลการตรวจสอบ'), path: '/followup/followupaudit' },
    { label: t('ติดตามผลการตรวจสอบ') },
  ]
  const [formFilter] = Form.useForm()
  const [searchStatus, setSearchStatus] = useState(0)

  return (
    <>
      <Row>
        <Col md={24}>
          <Breadcrumb data={breadcrumbList} title={t('ติดตามผลการตรวจสอบ')} menu={props.menu} />
          <FilterFollowUpAudit
            menu={props.menu}
            formFilter={formFilter}
            setSearchStatus={setSearchStatus}
            searchStatus={searchStatus}
          />
          <FollowUpAuditList menu={props.menu} formFilter={formFilter} searchStatus={searchStatus} />
        </Col>
      </Row>
    </>
  )
}

export default FollowUpAuditIndex
