import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import * as _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { ModalNew } from '../../../../../components/modal/Modal'
import { Select } from '../../../../../components/select/Select'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { decryptData, encryptInput } from '../../../../../utilitys/crypto'
import { Alert } from '../../../../../components/alert/Alert'
import { checkLanguageReturnData } from '../fnForManageAssessment'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { displayError } from '../../../../../utilitys/helper'
import { GETPERSONNELSBYASSESSCOOPERATION } from '../../graphql/Query'
import { SENDFORMBYASSESSCOOPERATION } from '../../graphql/Mutation'

export default function ModalSendAssesss(props) {
  const { open, onClose, dataObj } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const formName = 'modalSendAssesss'
  const userData = decryptData(localStorage.getItem('user'))
  const navigate = useNavigate()

  const Responsive = {
    md: 24,
    lg: 24,
  }

  const [getPersonnelsByAssessCooperationfn, getPersonnelsByAssessCooperation] = useLazyQuery(
    GETPERSONNELSBYASSESSCOOPERATION,
  )
  const [sendFormByAssessCooperationFn, { loading: loadingSave, error, data: sendFormByAssessCooperation }] =
    useMutation(SENDFORMBYASSESSCOOPERATION)

  const [personnelOptions, setPersonnelOptions] = useState([])

  const onFinish = (inputData) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการส่งแบบประเมินความร่วมมือ ?'),
      onOk() {
        sendFormByAssessCooperationFn({
          variables: {
            input: encryptInput({
              formResultId: dataObj.formResultId,
              makerBy: inputData.makerBy,
            }),
          },
        })
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    if (open) {
      getPersonnelsByAssessCooperationfn({
        variables: {
          personnelCode: userData.pCode,
        },
      })
    }
  }, [open])

  useEffect(() => {
    if (getPersonnelsByAssessCooperation?.data) {
      const res = getPersonnelsByAssessCooperation?.data?.getPersonnelsByAssessCooperation
      setPersonnelOptions(
        _.map(res, (dt, key) => {
          return {
            key: key,
            value: dt.personnelCode,
            label: checkLanguageReturnData(dt.fullNameTH, dt.fullNameEN),
            source: dt,
          }
        }),
      )
    }
    if (getPersonnelsByAssessCooperation.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getPersonnelsByAssessCooperation.error)),
        onOk() {},
      })
    }
  }, [getPersonnelsByAssessCooperation?.data])

  useEffect(() => {
    if (sendFormByAssessCooperation) {
      Alert({
        type: 'success',
        title: t('ส่งแบบประเมินความร่วมมือสำเร็จ'),
        onOk() {
          onClose(false)
          navigate('/auditoperations/assesscooperation/assesscooperationdetails', {
            state: {
              pageType: 'edit',
              refCode: sendFormByAssessCooperation.sendFormByAssessCooperation.formResultId,
            },
          })
          window.location.reload()
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถส่งแบบประเมินความร่วมมือได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [sendFormByAssessCooperation])

  return (
    <React.Fragment>
      {(getPersonnelsByAssessCooperation?.loading || loadingSave) && <SpinnersNew />}
      <ModalNew
        visible={open}
        testTitle={t('ส่งแบบประเมินความร่วมมือ')}
        onSubmit={form.submit}
        onClose={(e) => {
          onClose(e)
        }}
        type="medium"
      >
        <Row align="center">
          <Col {...Responsive} md={16} offset={6} align="left">
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={6} align="left">
                <Label type="tab-header-inactive">{t('ประจำปี')}</Label>
              </Col>
              <Col md={18} align="left">
                <Label type="body-primary">{dataObj.formYear}</Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={6} align="left">
                <Label type="tab-header-inactive">{t('ชื่อแบบประเมิน')}</Label>
              </Col>
              <Col md={18} align="left">
                <Label type="body-primary">{`${dataObj.formCode} : ${dataObj.formName}`}</Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={6} align="left">
                <Label type="tab-header-inactive">{t('หน่วยตรวจสอบ')}</Label>
              </Col>
              <Col md={18} align="left">
                <Label type="body-primary">{dataObj.organizationName2}</Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={6} align="left">
                <Label type="tab-header-inactive">{t('หน่วยรับตรวจ')}</Label>
              </Col>
              <Col md={18} align="left">
                <Label type="body-primary">{dataObj.organizationName}</Label>
              </Col>
            </Row>
          </Col>
        </Row>
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name="makerBy"
            label={<Label type="tab-header-inactive">{t('ผู้ทำแบบประเมิน')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกผู้ทำแบบประเมิน') }]}
          >
            <Select
              formname={formName}
              data={personnelOptions}
              // defaultValue={0}
              placeholder={t('เลือกผู้ทำแบบประเมิน')}
              scrollableId={formName}
              onChange={(e) => {
                form.setFieldsValue({
                  makerBy: e,
                })
              }}
              showSearch
            />
          </Form.Item>
        </Form>
      </ModalNew>
    </React.Fragment>
  )
}
