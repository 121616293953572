/* eslint-disable no-unused-vars */
import React from 'react'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { CardNew } from '../../../components/card/Card'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { Col, Row } from 'antd'
import { LabelNew } from '../../../components/label/Label'
import { IconNew } from '../../../components/icon/Icon'

function CardIndex() {
  const data = [{ label: 'งานควบคุมภายใน', path: '/internalcontrol/assess' }, { label: 'ประเมินการควบคุมภายใน' }]
  let code = ` import { LabelNew } from '../../../components/label/Label'
    export default function App() {
        return <>
        <CardNew topic="CardContent" icon="AlignJustify" toggledrop={'true'}>
            Card Line 01
        </CardNew>
        <CardNew topic="CardContent" icon="AlignJustify" toggledrop={'true'}>
            Card Line 02
            <CodeBlock code={code} />
        </CardNew>
        </>
    }
    `
  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <Breadcrumb data={data} title="ประเมินการควบคุมภายใน" />
          <CardNew topic="Filters" icon="Filter" toggledrop="true">
            Card Line 01 14
          </CardNew>
          <CardNew topic="CardContent" icon="List" toggledrop="false">
            Card Line 02
            <CodeBlock code={code} />
          </CardNew>

          {/* ตัวอย่างการรวม Card  */}
          <CardNew topic="CardContent 1" icon="List" toggledrop="true">
            Content 1
            <Row style={{ marginTop: 45, marginBottom: 10 }}>
              <Col>
                <LabelNew type="card-title" icon="List">
                  CardContent 2
                </LabelNew>
              </Col>
            </Row>
            Content 2
          </CardNew>
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default CardIndex
