import { gql } from '@apollo/client'

export const GET_MASTER_NAME_FORMAT_TYPE = gql`
  query GetMasterNumberFormatTypes($input: MasterNumberFormatTypesInput!) {
    getMasterNumberFormatTypes(input: $input) {
      formatTypeId
      formatTypeCode
      formatTypeNameTH
      formatTypeNameEN
      isActive
      formatDefault
    }
  }
`
