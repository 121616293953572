import { useState } from 'react'
import { Card, Col, Row } from 'antd'
import React, { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import moment from 'moment'

import { IconNew } from '../../../../components/icon/Icon'
import { SpinnersSmaillNew } from '../../../../components/spinners/Spinners'
import { GraphPie } from '../../../../components/graphpie/GraphPie'
import { NoData } from '../../../../components/nodatagraph/NoData'

import { GraphPieStyled } from './css/GraphPieStyled'
import { useGetGraphPie } from './hooks/useGetGraphPie'
import { GraphColumn } from './components/graphcolumn/GraphColumn'
import { GraphStyled } from './css/GraphStyled'
import { useGetNextMeetingAc } from './hooks/useGetNextMeetingAc'
import { useGetGraphColumn } from './hooks/useGetGraphColumn'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  graph: {
    md: 12,
    lg: 12,
  },
}

const getStatusColor = (items) => {
  let statusColor = ''
  if (items.jobStatusCode === '10') {
    statusColor = '#87CEEB'
  } else if (items.jobStatusCode === '20') {
    statusColor = '#40E0D0'
  } else if (items.jobStatusCode === '30') {
    statusColor = '#3CB371'
  } else statusColor = '#FF3333'

  return statusColor
}

const OverviewSlaProcessGraph = (props) => {
  const { dataFilter } = props

  const { t } = useTranslation()

  const [dataPie, setDataPie] = useState([])
  const [dateAC, setDateAC] = useState()
  const [dataColumn, setDataColumn] = useState([])

  const inputOptions = useMemo(() => {
    return { filters: { ...dataFilter } }
  }, [dataFilter])

  // ==================================== API Graph Pie ====================================

  const [resGraphPie, loadingGraphPie] = useGetGraphPie(inputOptions)

  useEffect(() => {
    if (resGraphPie) {
      const data = resGraphPie.filter((items) => items.jobTypeCode === '10')

      if (!_.isEmpty(data)) {
        setDataPie(
          data.map((items) => {
            return {
              type: items.statusName,
              value: parseInt(items.countStatus),
              color: getStatusColor(items),
              source: items,
            }
          }),
        )
      }
    }
  }, [resGraphPie])

  // ==================================== API Next Meeting AC ====================================

  const [resAC, loadingAC] = useGetNextMeetingAc(inputOptions)

  useEffect(() => {
    if (resAC) {
      const { resDate } = resAC

      if (resDate) {
        setDateAC(moment(new Date(resDate)).format(': DD/MM/YYYY'))
      }
    }
  }, [resAC])

  // ==================================== API Column ====================================

  const [resGraphColumn, loadingColumn] = useGetGraphColumn(inputOptions)

  useEffect(() => {
    if (resGraphColumn) {
      const { acResult, exitResult } = resGraphColumn

      const listDataColumn = []
      if (acResult) {
        const { isSLA, notSLA } = acResult
        listDataColumn.push({
          level: t('AC-รายงาน'),
          type: t('เป็นไปตาม SLA'),
          value: isSLA,
        })
        listDataColumn.push({
          level: t('AC-รายงาน'),
          type: t('ไม่เป็นไปตาม SLA'),
          value: notSLA,
        })
      }
      if (exitResult) {
        const { isSLA, notSLA } = exitResult
        listDataColumn.push({
          level: t('ประชุม Exit-AC'),
          type: t('เป็นไปตาม SLA'),
          value: isSLA,
        })
        listDataColumn.push({
          level: t('ประชุม Exit-AC'),
          type: t('ไม่เป็นไปตาม SLA'),
          value: notSLA,
        })
      }

      setDataColumn(listDataColumn)
    }
  }, [resGraphColumn])

  return (
    <Row gutter={[16, 8]}>
      <Col {...Responsive.graph}>
        <GraphPieStyled>
          <Card
            className="card-dash card-pie"
            title={
              <Row>
                <Col md={22} align="left">
                  <span className="card-title-icon">
                    <IconNew icon={'PieChart'} size={17} />
                  </span>
                  <span className="card-title-text">{t('งานตรวจสอบตามแผน')}</span>
                  <span className="card-title-text">{t(' Total : 100 โครงการ')}</span>
                </Col>
              </Row>
            }
          >
            <div className="block-graph-pie">
              {loadingGraphPie && <SpinnersSmaillNew />}
              {!_.isEmpty(dataPie) ? <GraphPie data={dataPie} /> : <NoData />}
            </div>
          </Card>
        </GraphPieStyled>
      </Col>
      <Col {...Responsive.graph}>
        <GraphStyled>
          <Card
            className="card-dash card-pie"
            title={
              <Row>
                <Col md={22} align="left">
                  {loadingAC && <SpinnersSmaillNew />}
                  {!_.isEmpty(dateAC) ? (
                    <div>
                      <span className="card-title-text">{t('วันประชุม AC ครั้งถัดไป')}</span>
                      <span className="card-title-text">{dateAC}</span>
                    </div>
                  ) : (
                    <div>
                      <span className="card-title-text">{t('วันประชุม AC ครั้งถัดไป')}</span>
                      <span className="card-title-text">{': --/--/--'}</span>
                    </div>
                  )}
                </Col>
              </Row>
            }
          >
            {loadingColumn && <SpinnersSmaillNew />}
            {!_.isEmpty(dataColumn) ? <GraphColumn data={dataColumn} /> : <NoData />}
          </Card>
        </GraphStyled>
      </Col>
    </Row>
  )
}

export default OverviewSlaProcessGraph
