import React from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb.jsx'
import { TabNew } from '../../../components/tab/Tab.jsx'
import TabOne from './tab/TabOne'
import TabTwo from './tab/TabTwo'
import TabThree from './tab/TabThree'
import { CardNew } from '../../../components/card/Card'
import { getSegmentUrl } from '../../../utilitys/helper'

const EmalSendSetupIndex = (props) => {
  const { t } = useTranslation()
  const params = getSegmentUrl(3)
  const navigate = useNavigate()
  const breadcrumbList = [{ label: t('จัดการข้อมูลพื้นฐาน'), path: '#' }, { label: t('ตั้งค่าการส่งอีเมล') }]
  const tabs = [
    {
      key: 'TabOne',
      iconTab: () => <></>,
      name: t('ตั้งค่าตามระดับความเสี่ยง'),
      disabled: false,
      content: () => <>{params === 'TabOne' || !params ? <TabOne menu={props.menu} /> : ''}</>,
    },
    {
      key: 'TabTwo',
      iconTab: () => <></>,
      name: t('ตั้งค่าตามการติดตาม'),
      disabled: false,
      content: () => <>{params === 'TabTwo' ? <TabTwo menu={props.menu} /> : ''}</>,
    },
    {
      key: 'TabThree',
      iconTab: () => <></>,
      name: t('ตั้งค่าติดตามการดำเนินงาน'),
      disabled: false,
      content: () => <>{params === 'TabThree' ? <TabThree menu={props.menu} /> : ''}</>,
    },
  ]

  const titleBreadcrumb = params === 'TabOne' ? t('รายการระดับความเสี่ยง') : t('รายการตั้งค่าการส่งอีเมล')

  return (
    <>
      <Row>
        <Col md={24}>
          <Breadcrumb data={breadcrumbList} title={t('ตั้งค่าการส่งอีเมล')} />
          <CardNew topic={titleBreadcrumb} icon="List" toggledrop="false">
            <TabNew
              tab={tabs}
              activeKey={!params ? 'TabOne' : params}
              onTabClick={(key) => {
                if (key === 'TabOne') {
                  navigate('/administrator/emalsendsetup/TabOne')
                } else if (key === 'TabTwo') {
                  navigate('/administrator/emalsendsetup/TabTwo')
                } else if (key === 'TabThree') {
                  navigate('/administrator/emalsendsetup/TabThree')
                }
              }}
            />
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default EmalSendSetupIndex
