import React, { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { Form, Input, Button, Row, Col, Select } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { useTranslation, Trans } from 'react-i18next'
import { useMutation, useQuery, useLazyQuery } from '@apollo/client'
import { LoginStyle, LogoStyle } from './LoginStyles'
import THImg from '../../../../assets/images/flag-th.png'
import ENImg from '../../../../assets/images/flag-en.png'
import { AuthContext } from '../../../../context/authContext'
import { MENUS, USER_ROLES, GET_LAST_SOFTWARE_VERSION, GET_SYSTEM_CONFIGURATIONS } from './graphql/Query'
import { LOGIN } from './graphql/Mutation'
import { encryptData, decryptData, encryptInput } from '../../../../utilitys/crypto'
import { LabelNew } from '../../../../components/label/Label'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { useCacheClean } from '../../../../hooks/useCacheClean'

const { Option } = Select
const lang = process.env['REACT_APP_DEFAULT_LANG']

function LoginPage() {
  const navigate = useNavigate()
  const [user, setUser] = useContext(AuthContext)
  const [language, setLanguage] = useState(lang)
  const { t, i18n } = useTranslation()
  const [errorMessage, setErrorMessage] = useState('')

  const { data: dataMenus, loading: menuLoading, error: menusError } = useQuery(MENUS)
  const { data: dataVersion, loading: versionLoading, error: versionError } = useQuery(GET_LAST_SOFTWARE_VERSION)
  const {
    data: dataSystemConfigurations,
    loading: loadingSystemConfigurations,
    error: errorSystemConfigurations
  } = useQuery(GET_SYSTEM_CONFIGURATIONS)
  const [getUserRoles, { data: userRoleData, loading: userRolesLoading, error: userRolesError }] =
    useLazyQuery(USER_ROLES)
  const [login, { data: loginData, loading: loginLoading, error: loginError }] = useMutation(LOGIN)

  //set language
  const handleChange = (lang) => {
    setLanguage(lang)
    i18n.changeLanguage(lang)
    localStorage.setItem('lang', lang)
  }

  // pass validate And Request Login
  const onFinish = async (values) => {
    if (values?.username && values?.password) {
      await login({
        variables: {
          input: encryptInput({
            username: values?.username,
            password: values?.password
          })
        }
      })
    }
  }

  const onFinishFailed = (errorInfo) => console.log('Failed:', errorInfo)

  useEffect(() => {
    if (loginData?.login) {
      const data = loginData?.login
      const ciphertext = encryptData(data)
      localStorage.setItem('user', ciphertext)
      i18n.changeLanguage(language)
      localStorage.setItem('lang', language)
      setUser({
        loginName: data?.username,
        fullname: data.fullname ? data.fullname : '',
        userId: data.userId ? data.userId : t('ยังไม่มีรหัส'),
        orgNameTH: data.orgNameTH ? data.orgNameTH : '',
        orgNameEN: data.orgNameEN ? data.orgNameEN : ''
      })
      getUserRoles()
      localStorage.setItem('sess_start_time', encryptData(moment().format('YYYY-MM-DD HH:mm:ss')))
    }
  }, [loginData?.login])

  useEffect(() => {
    if (loginError) {
      setErrorMessage(loginError.message && loginError.graphQLErrors.length > 0 ? loginError.message : '')
    }
  }, [loginError])

  // Default lang & check Status Login
  useEffect(() => {
    if (user.loginName && document.referrer) navigate(-1)
    const storageLang = localStorage.getItem('lang') ? localStorage.getItem('lang') : lang
    setLanguage(storageLang)
    i18n.changeLanguage(storageLang)
  }, [])

  // Get Menus
  useEffect(() => {
    if (dataMenus) {
      const data = dataMenus.getMenus
      const ciphertext = encryptData(data)
      localStorage.setItem('path', ciphertext)
    }
  }, [dataMenus])

  // Get Menus Error
  useEffect(() => {
    if (menusError) {
      console.log('menusError', menusError)
    }
  }, [menusError])

  // Get SystemConfigurations
  useEffect(() => {
    if (dataSystemConfigurations) {
      localStorage.setItem(
        'sess_expire_min',
        encryptData(dataSystemConfigurations.getSystemConfigurations?.sessionExpire)
      )
    }
  }, [dataSystemConfigurations])

  // Get SystemConfigurations Error
  useEffect(() => {
    if (errorSystemConfigurations) {
      console.log('errorSystemConfigurations', errorSystemConfigurations)
    }
  }, [errorSystemConfigurations])

  // Get version
  useEffect(() => {
    if (versionError) {
      console.log('versionError: ', versionError)
    }
  }, [versionError])

  //Get Roles Data & Redirect to system
  useEffect(() => {
    if (userRoleData) {
      const data = userRoleData.getUserRoles
      const user = decryptData(localStorage.getItem('user'))
      const ciphertext = encryptData({ ...user, roles: data })
      localStorage.setItem('user', ciphertext)
      const location = localStorage.getItem('expired_path') ? localStorage.getItem('expired_path') : '/'
      window.location.href = location
    }
  }, [userRoleData])

  //Get Roles Error
  useEffect(() => {
    if (userRolesError) {
      console.log('userRolesError', userRolesError)
    }
  }, [userRolesError])

  // clear user data when login
  useEffect(() => {
    const user = localStorage.getItem('user')
    if (user) {
      localStorage.removeItem('user')
    }
  }, [])

  //Switch Language
  const LanguageSelect = () => {
    return (
      <Select defaultValue={language} style={{ width: 160 }} onChange={handleChange} size="large">
        <Option value="en">
          <img src={ENImg} /> English
        </Option>
        <Option value="th">
          <img src={THImg} /> ภาษาไทย
        </Option>
      </Select>
    )
  }

  useCacheClean(dataVersion?.getLastSoftwareVersion?.[0]?.version, process.env['REACT_APP_ENV'])

  return (
    <LoginStyle>
      {(menuLoading || userRolesLoading || versionLoading || loadingSystemConfigurations) && <SpinnersNew />}
      <Row>
        <Col xs={{ span: 0 }} sm={{ span: 0 }} md={{ span: 0 }} lg={{ span: 15 }} className="content-left">
          <LogoStyle>
            <div className="login-logo"></div>
          </LogoStyle>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 9 }}
          style={{ minHeight: '100vh' }}
          className="content-right"
        >
          <div className="content-right-line"></div>
          <Row align="center">
            <Col>
              <Form
                name="normal_login"
                layout={'vertical'}
                className="login-form"
                style={{ alignItems: 'center' }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <div className="welcome" style={{ paddingTop: '130px' }}>
                  {t('Welcome to')}
                </div>
                <div className="title">
                  <Trans>Audit Management Tools</Trans>
                </div>
                <div className="login-title">
                  <Trans>Please sign-in to your account</Trans>
                </div>
                <Form.Item
                  name="username"
                  label={`${t('Username')}`}
                  rules={[{ required: true, message: t('') }]}
                  // {required: true, message: t('Please input your Username Or Email Address!') }
                >
                  <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="" size="large" />
                </Form.Item>
                <Form.Item
                  name="password"
                  label={t('Password')}
                  rules={[{ required: true, message: t('') }]}
                  // { required: true, message: t('Please input your Password!') }
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder=""
                    size="large"
                  />
                </Form.Item>
                <Form.Item name="language" label={t('Select Language')}>
                  <LanguageSelect />
                </Form.Item>
                {/* <Form.Item> */}
                {/* <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>
                      <Trans>Remember me</Trans>
                    </Checkbox>
                  </Form.Item> */}
                {/* <a className="login-form-forgot" href="/forgotpassword">
                    <Trans>Forgot Password</Trans>{' '}
                  </a> */}
                {/* </Form.Item> */}
                <div className="login-error">
                  <LabelNew type="error">{loginError && <Trans>{errorMessage}</Trans>}&nbsp;</LabelNew>
                </div>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    size="large"
                    loading={loginLoading ? true : false}
                  >
                    {' '}
                    <Trans>Sign in</Trans>
                  </Button>
                </Form.Item>
                <div className="version">
                  {dataVersion && (
                    <>
                      <Trans>Version</Trans> {dataVersion?.getLastSoftwareVersion?.[0]?.version}
                    </>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* </Layout> */}
    </LoginStyle>
  )
}

export default LoginPage
