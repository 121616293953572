import React, { useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import { Status } from '../../../components/status/Status'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { ButtonNew } from '../../../components/button/Button'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { displayText, displayError } from '../../../utilitys/helper'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { handleSort } from '../../../utilitys/pagination'
import { encryptInput } from '../../../utilitys/crypto'
import { Columns } from './utils/TimeSheetRequestColumns'
import { GET_TIMESHEET_REQUEST_PAGINATION } from './graphql/Query'
import { DELETE_TIMESHEET_REQUEST_BY_ID } from './graphql/Mutation'

import { useDecodeUser } from '../../../hooks/useDecodeUser'
import TimeSheetRequestModal from './components/modal/TimeSheetRequestModal.jsx'

const TimeSheetRequestList = (props) => {
  const { menu, filters } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' },
  ])
  const [user] = useDecodeUser()
  const [dataLists, setDataLists] = useState([])
  const [visible, setVisible] = useState(false)
  const [dataRequest, setDataRequest] = useState()

  /* ---------------- API ----------- */
  const [getDataPaginationFn, dataPagination] = useLazyQuery(GET_TIMESHEET_REQUEST_PAGINATION)
  const [deleteDataFn, dataDelete] = useMutation(DELETE_TIMESHEET_REQUEST_BY_ID)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    getDataPaginationFn({
      variables: {
        input: encryptInput({
          filters: { ...filters, personnelCode: user?.pCode },
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort, user])

  useEffect(() => {
    if (dataPagination.data) {
      let response = dataPagination?.data?.getTimeSheetRequestPagination
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          const iconList = [
            {
              type: 'view',
              onClick: () => navigate(`form`, { state: { type_action: 'view', refCode: data.requestId } }),
            },
          ]

          /* ถ้ารอการพิจารณา สามารถทำได้ */
          if (data.requestStatusCode === '10') {
            iconList.push(
              {
                type: 'edit',
                onClick: () => {
                  setVisible(true), setDataRequest({ refCode: data.requestId, type_action: 'edit' })
                },
              },
              {
                type: 'delete',
                onClick: () => showConfirmDelete(data.requestId),
              },
            )
          }

          return {
            key: data.no,
            requestNumber: data.requestNumber,
            dateStart: dateDisplay(data.dateStart),
            dateEnd: dateDisplay(data.dateEnd),
            requestStatusName: <Status text={displayText(data.requestStatusNameTH, data.requestStatusNameEN)} />,
            approverName: displayText(data.approverNameTH, data.approverNameEN),
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup menu={menu} icons={iconList} />
              </div>
            ),
          }
        }),
      )
    }

    if (dataPagination.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataPagination.error)),
        onOk() {},
      })
    }
  }, [dataPagination.data])

  useEffect(() => {
    if (dataDelete.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          dataPagination.refetch()
        },
      })
    }

    if (dataDelete.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(dataDelete.error)),
        onOk() {},
      })
    }
  }, [dataDelete.data])

  /* ---------------- ACTION ----------- */
  const showConfirmDelete = (requestId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteDataFn({
          variables: {
            requestId: encryptInput(requestId),
          },
        })
      },
      onCancel() {},
    })
  }

  return (
    <>
      {(dataPagination.loading || dataDelete.loading) && <SpinnersNew />}
      <Datatable
        paginationCustom={true}
        columns={Columns}
        data={dataLists}
        total={count}
        pageSize={limit}
        page={page}
        handleTableChange={(e) => {
          const reNewField = [
            { requestNumber: 'requestNumber' },
            { dateStart: 'dateStart' },
            { dateEnd: 'dateEnd' },
            { requestStatusName: displayText('requestStatusNameTH', 'requestStatusNameEN') },
            { approverName: displayText('approverNameTH', 'approverNameEN') },
          ]
          setSort(handleSort(e?.sorter, reNewField))
        }}
        onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
        searchCustom={true}
        btnAdd={
          <>
            <ButtonNew
              type="plusTableBorderPrimary"
              roles={{ type: 'add', menu: menu }}
              onClick={() => {
                setVisible(true), setDataRequest({ type_action: 'add' })
              }}
            >
              {t('คำขออนุมัติ')}
            </ButtonNew>
          </>
        }
      />
      <TimeSheetRequestModal
        open={visible}
        close={(e) => {
          setVisible(e), setDataRequest()
        }}
        refetch={() => dataPagination.refetch()}
        menu={menu}
        dataParams={dataRequest}
      />
    </>
  )
}

export default TimeSheetRequestList
