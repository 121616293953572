import React, { useState, useEffect } from 'react'
import { ModalNew } from '../../../../../components/modal/Modal'
import { useTranslation } from 'react-i18next'
import { Space, Form } from 'antd'
import { InputNumberNew, InputNew } from '../../../../../components/input/Input'
import { RadioGroup, Radio } from '../../../../../components/radio/Radio'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { Select } from '../../../../../components/select/Select'
import { GETRATERISKFACTORGROUPBYRSKMODELS } from '../../graphql/Query'
import { useLazyQuery } from '@apollo/client'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError, displayText } from '../../../../../utilitys/helper'

export default function RiskFactorType2Modal(props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [optionData, setOptionData] = useState([])
  //   const [getData, setData] = useState([])

  const formName = 'from-rickfactor-modal'

  const [getRateRiskFactorGroupByRskModelsfn, getRateRiskFactorGroupByRskModels] = useLazyQuery(
    GETRATERISKFACTORGROUPBYRSKMODELS,
  )

  useEffect(() => {
    getRateRiskFactorGroupByRskModelsfn()
  }, [props.open])

  const onClose = (e) => {
    form.resetFields()
    props.onClose(e)
  }

  const onFinish = (values) => {
    props.rskObj(values)
  }

  useEffect(() => {
    if (getRateRiskFactorGroupByRskModels.data) {
      setOptionData(
        getRateRiskFactorGroupByRskModels.data.getRateRiskFactorGroupByRskModels.map((dt, key) => {
          return {
            key: key,
            label: dt.rateFacGroupCode + ' : ' + displayText(dt.rateFacGroupNameTH, dt.rateFacGroupNameEN),
            value: dt.rateFacGroupCode,
            source: dt,
          }
        }),
      )
    }
    if (getRateRiskFactorGroupByRskModels.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getRateRiskFactorGroupByRskModels.error)),
        onOk() {},
      })
    }
  }, [getRateRiskFactorGroupByRskModels.data])

  return (
    <>
      <ModalNew
        visible={props.open}
        testTitle={t('เพิ่มคลังประเมินความเสี่ยง')}
        onSubmit={form.submit}
        onClose={(e) => {
          onClose(e)
        }}
        type="medium"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name="rateFacGroupCode"
            label={<Label type="tab-header-inactive">{t('ลำดับ')}</Label>}
            hidden={true}
          >
            <InputNew placeholder={t('')} formname={formName} />
          </Form.Item>
          <Form.Item
            name="rateFacGroupNameTH"
            label={<Label type="tab-header-inactive">{t('ลำดับ')}</Label>}
            hidden={true}
          >
            <InputNew placeholder={t('ระบุลำดับ')} formname={formName} />
          </Form.Item>
          <Form.Item
            name="rateFacGroupNameEN"
            label={<Label type="tab-header-inactive">{t('ลำดับ')}</Label>}
            hidden={true}
          >
            <InputNew placeholder={t('ระบุลำดับ')} formname={formName} />
          </Form.Item>
          <Form.Item name="riskNo" label={<Label type="tab-header-inactive">{t('ลำดับ')}</Label>}>
            <InputNumberNew placeholder={t('ระบุลำดับ')} />
          </Form.Item>
          <Form.Item
            name="riskFactorGroup"
            label={<Label type="tab-header-inactive">{t('ปัจจัยความเสี่ยง')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกปัจจัยความเสี่ยง') }]}
          >
            <Select
              formname={formName}
              data={optionData}
              placeholder={t('ระบุปัจจัยความเสี่ยง')}
              scrollableId={formName}
              showSearch
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }}
              onChange={(e) => {
                const res = optionData.find((i) => i.value === e)
                form.setFieldsValue({
                  rateFacGroupCode: res.source.rateFacGroupCode,
                  rateFacGroupNameTH: res.source.rateFacGroupNameTH,
                  rateFacGroupNameEN: res.source.rateFacGroupNameEN,
                })
              }}
            />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
            <RadioGroup>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                &nbsp;
                <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
