import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput } from '../../../utilitys/crypto'
import ProjectSizeSetupForm from './ProjectSizeSetupForm'
import { handleSort } from '../../../utilitys/pagination'
import { Columns } from './utils/ProjectSizeSetupListColumn'
import { GET_LIST_PROJECT_SIZE_SETUP_PAGINATION } from './graphql/Query'
import { DELETE_PROJECT_SIZE_SETUP } from './graphql/Mutation'
import { displayError } from '../../../utilitys/helper'

const Responsive = {
  md: 24,
  lg: 24,
}

const ProjectSizeSetupList = ({ menu }) => {
  const { t } = useTranslation()

  const arrCrumb = [
    { label: t('Administration'), path: '/administrator/projectsizesetup' },
    { label: t('ตั้งค่าขนาดโครงการ') },
  ]

  const [editable] = useState(true)

  const [type_action, settype_action] = useState()
  const [dataForm, setdataForm] = useState()

  const [dataRow, setdataRow] = useState([])

  const [modal_visible, setmodal_visible] = useState(false)
  const [paginate, setPaginate] = useState({ page: 1, limit: 10 })

  const [sort, setsort] = useState([{ fieldSort: 'projectSizeCode', sortType: 'ASC' }])

  const [
    filters,
    //  setfilters
  ] = useState({})
  const [count, setcount] = useState(1)

  const [callGetList, { loading: loadingList, error: errorList, data: dataList }] = useLazyQuery(
    GET_LIST_PROJECT_SIZE_SETUP_PAGINATION,
  )

  useEffect(() => {
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorList)),
      })
    }
  }, [errorList])

  useEffect(() => {
    if (dataList) {
      let { result, count } = dataList.getListProjectSizeSetupPagination

      let dataTemp = result.map((item, key) => {
        return {
          ...item,
          key: key + 1,
        }
      })

      setdataRow(dataTemp)
      setcount(count)
    }
  }, [dataList])

  useEffect(() => {
    if (sort && paginate) fncGetList()
  }, [paginate, sort])

  const [callDelete, { loading: loadingDelete, error: errorDelete, data: dataDelete }] =
    useMutation(DELETE_PROJECT_SIZE_SETUP)

  useEffect(() => {
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(errorDelete)),
        onOk() {},
      })
    }
  }, [errorDelete])

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        content: t(dataDelete.message),
        onOk() {
          fncGetList()
        },
      })
    }
  }, [dataDelete])

  const fncGetList = () => {
    const dataInput = {
      sort: sort,
      filters: filters,
      pagination: {
        page: paginate.page,
        limit: paginate.limit,
      },
    }
    callGetList({
      variables: {
        input: encryptInput(dataInput),
      },
    })
  }

  const handleModalOnSuccess = () => {
    setdataForm()
    setmodal_visible(false)
    fncGetList()
  }

  const handleModalOnClose = (data) => {
    setdataForm()
    setmodal_visible(data)
  }

  const fncOnpressDelete = (data) => {
    const dataInput = {
      projectSizeCode: data.projectSizeCode,
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ?'),
      onOk() {
        callDelete({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const fncOnpressView = (data) => {
    setdataForm(data)
    settype_action('view')
    setmodal_visible(true)
  }

  const fncOnpressEdit = (data) => {
    setdataForm(data)
    settype_action('edit')
    setmodal_visible(true)
  }

  return (
    <React.Fragment>
      <BreadcrumbNew data={arrCrumb} title={t('ตั้งค่าขนาดโครงการ')} />
      <CardNew topic={t('รายการตั้งค่าขนาดโครงการ')} icon="List">
        <Row>
          <Col {...Responsive}>
            <Datatable
              columns={Columns({ fncOnpressDelete, fncOnpressView, fncOnpressEdit, menu })}
              data={dataRow}
              total={count}
              searchCustom={true}
              pageSize={paginate.limit}
              page={paginate.page}
              isLoading={loadingList || loadingDelete}
              paginationCustom={true}
              scroll={{ x: 768 }}
              onChangePagination={({ page, pageSize }) => {
                setPaginate({ page, limit: pageSize })
              }}
              handleTableChange={(e) => {
                const reNewField = [
                  { projectSizeCode: 'projectSizeCode' },
                  { projectSizeNameTH: 'projectSizeNameTH' },
                  { projectSizeNameEN: 'projectSizeNameEN' },
                  { manday: 'manday' },
                  { isActive: 'isActive' },
                ]

                setsort(handleSort(e?.sorter, reNewField))
              }}
              btnAdd={
                editable ? (
                  <ButtonNew
                    onClick={() => {
                      settype_action('add')
                      setmodal_visible(true)
                    }}
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                  >
                    {t('เพิ่มตั้งค่าขนาดโครงการ')}
                  </ButtonNew>
                ) : (
                  <></>
                )
              }
            />
          </Col>
        </Row>
      </CardNew>
      <ProjectSizeSetupForm
        type_action={type_action}
        dataForm={dataForm}
        visible={modal_visible}
        onSuccess={handleModalOnSuccess}
        onClose={handleModalOnClose}
      />
    </React.Fragment>
  )
}
export default ProjectSizeSetupList
