import React from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { TabNew } from '../../../components/tab/Tab'
import { getSegmentUrl } from '../../../utilitys/helper'
import AdhocjobInfomation from './components/tab/AdhocjobInformation'
import AdhocjobResponsible from './components/tab/AdhocjobResponsible'
import AdhocjobStep from './components/tab/AdhocjobStep'
import AdhocjobDocument from './components/tab/AdhocjobDocument'

const AdhocjobForm = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { state } = useLocation()
  const { t } = useTranslation()
  const params = getSegmentUrl(4)

  if (!state) {
    return <Navigate to={'/auditoperations/adhocjob'} />
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/adhocjob' },
    { label: t('รายการงานมอบหมาย'), path: '/auditoperations/adhocjob' },
    { label: state.type_action === 'create' ? t('เพิ่มงานมอบหมาย') : t('แก้ไขงานมอบหมาย') },
  ]

  const Responsive = {
    md: 24,
    lg: 24,
  }

  const disabled = state.refCode ? false : true

  const tabs = [
    {
      key: 'information',
      iconTab: () => <></>,
      name: t('ข้อมูลงานมอบหมาย'),
      disabled: false,
      content: () => <AdhocjobInfomation menu={menu} />,
    },
    {
      key: 'responsible',
      iconTab: () => <></>,
      name: t('ผู้รับผิดชอบ'),
      disabled: disabled,
      content: () => <AdhocjobResponsible menu={menu} />,
    },
    {
      key: 'jobstep',
      iconTab: () => <></>,
      name: t('ขั้นตอนปฏิบัติงาน'),
      disabled: disabled,
      content: () => <AdhocjobStep menu={menu} />,
    },
    {
      key: 'document',
      iconTab: () => <></>,
      name: t('เอกสารเพิ่มเติม'),
      disabled: disabled,
      content: () => <AdhocjobDocument menu={menu} />,
    },
  ]

  return (
    <>
      <Breadcrumb data={breadcrumbList} title={t('งานมอบหมาย')} />
      <CardNew
        topic={state.type_action === 'add' ? t('เพิ่มงานมอบหมาย') : t('แก้ไขงานมอบหมาย')}
        icon={state.type_action === 'add' ? 'Plus' : 'Edit'}
        toggledrop="false"
      >
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <TabNew
              tab={tabs}
              activeKey={!params ? 'information' : params}
              onTabClick={(key) => {
                if (key === 'information') {
                  navigate('/auditoperations/adhocjob/tab/information', { state: state })
                } else if (key === 'responsible') {
                  navigate('/auditoperations/adhocjob/tab/responsible', { state: state })
                } else if (key === 'jobstep') {
                  navigate('/auditoperations/adhocjob/tab/jobstep', { state: state })
                } else if (key === 'document') {
                  navigate('/auditoperations/adhocjob/tab/document', { state: state })
                }
              }}
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default AdhocjobForm
