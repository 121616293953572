import { useTranslation, Trans } from 'react-i18next'

export const pageTitle = 'ความเสี่ยงการประเมิน Audit Universe'

export const breadcrumbData = () => {
  const { t } = useTranslation()
  return [{ label: t('แดชบอร์ด'), path: '/dashboard' }, { label: t(pageTitle) }]
}

export const Columns = () => {
  return [
    {
      title: <Trans>{'ลำดับ'}</Trans>,
      dataIndex: 'no',
      width: '100px',
      align: 'center',
      fixed: 'left',
    },
    {
      title: <Trans>{'กระบวนการ (แผนกระบวนการ)'}</Trans>,
      dataIndex: 'riskModelName',
      width: '200px',
      align: 'left',
      fixed: 'left',
    },
    {
      title: <Trans>{'ระดับความเสี่ยง'}</Trans>,
      dataIndex: 'riskLevel',
      width: '150px',
      align: 'left',
      fixed: 'left',
    },
    {
      title: <Trans>{'ประเมินความเสี่ยง'}</Trans>,
      align: 'center',
      children: [
        {
          title: <div>{'compliance'}</div>,
          dataIndex: 'compliance',
          width: '150px',
          align: 'center',
          render: (item) => {
            return item ? item : 0
          },
        },
        {
          title: <Trans>{'ปัจจัยเสี่ยงองค์กร'}</Trans>,
          dataIndex: 'ปัจจัยเสี่ยงองค์กร',
          width: '150px',
          align: 'center',
          render: (item) => {
            return item ? item : 0
          },
        },
        {
          title: <div>{'Key Strategy'}</div>,
          dataIndex: 'KeyStrategy',
          width: '150px',
          align: 'center',
          render: (item) => {
            return item ? item : 0
          },
        },
        {
          title: <Trans>{'ข้อสังเกตผู้ตรวจกระทรวงการคลัง'}</Trans>,
          dataIndex: 'ข้อสังเกตผู้ตรวจกระทรวงการคลัง',
          width: '150px',
          align: 'center',
          render: (item) => {
            return item ? item : 0
          },
        },
        {
          title: <Trans>{'ข้อสังเกต ธปท.'}</Trans>,
          dataIndex: 'ข้อสังเกตธปท.',
          width: '150px',
          align: 'center',
          render: (item) => {
            return item ? item : 0
          },
        },
        {
          title: <Trans>{'ความเห็นของผู้บริหาร'}</Trans>,
          dataIndex: 'ความเห็นของผู้บริหาร',
          width: '150px',
          align: 'center',
          render: (item) => {
            return item ? item : 0
          },
        },
        {
          title: <Trans>{'การตรวจสอบครั้งก่อน'}</Trans>,
          dataIndex: 'การตรวจสอบครั้งก่อน',
          width: '150px',
          align: 'center',
          render: (item) => {
            return item ? item : 0
          },
        },
        {
          title: <Trans>{'ความเสี่ยงทุจริต'}</Trans>,
          dataIndex: 'ความเสี่ยงทุจริต',
          width: '150px',
          align: 'center',
          render: (item) => {
            return item ? item : 0
          },
        },
        {
          title: <Trans>{'IT-Risk ความเสี่ยงด้าน IT โดยภาพรวม'}</Trans>,
          dataIndex: 'IT-Riskความเสี่ยงด้านITโดยภาพรวม',
          width: '150px',
          align: 'center',
          render: (item) => {
            return item ? item : 0
          },
        },
        {
          title: <Trans>{'แบบสำรวจความเสี่ยงด้าน IT'}</Trans>,
          dataIndex: 'แบบสำรวจความเสี่ยงด้านIT',
          width: '150px',
          align: 'center',
          render: (item) => {
            return item ? item : 0
          },
        },
        {
          title: <Trans>{'ถ่วงน้ำหนัก'}</Trans>,
          dataIndex: 'isWeight',
          width: '150px',
          align: 'center',
          render: (item) => {
            return item ? item === 1 ? <Trans>{'มี'}</Trans> : <Trans>{'ไม่มี'}</Trans> : null
          },
        },
      ],
    },
    {
      title: <Trans>{'คะแนนถ่วงน้ำหนัก'}</Trans>,
      dataIndex: 'scoreWeight',
      width: '150px',
      align: 'center',
    },
  ]
}
