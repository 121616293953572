import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { Status } from '../../../components/status/Status'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { Alert } from '../../../components/alert/Alert'
import { displayError } from '../../../utilitys/helper'
import { ButtonNew as Button } from '../../../components/button/Button'
// import { useLanguage } from '../../../hooks/useLanguage'
import { breadcrumbList } from './utils/breadcrumbData'
import { ApproveCancelProjectColumn } from './utils/ApproveCancelProjectColumn'
import ApproveCancelProjectFilter from './components/filter/ApproveCancelProjectFilter'
import { checkLanguageReturnData } from './components/approveCancelProjectfn'
import { fnAlertConfirmNotApprove } from './components/fnAlertconfirmNotApprove'
import { GETAPPROVECANCELPROJECTLIST } from './graphql/Query'
import { APPROVECANCLEPROJECTPLANSAVE } from './graphql/Mutation'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { useDecodeUser } from '../../../hooks/useDecodeUser'

export default function ApproveCancelProjectList(props) {
  const { menu } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  // const [isLang] = useLanguage()
  const [userData] = useDecodeUser()
  const Responsive = {
    md: 24,
    lg: 24,
  }

  const [getSelectData, setSelectData] = useState([])
  const [getFilters, setFilters] = useState({})

  const [getDataTable, setDataTable] = useState([])

  const [getApproveCancelProjectListfn, getApproveCancelProjectList] = useLazyQuery(GETAPPROVECANCELPROJECTLIST)

  const [approveCancleProjectPlanSaveFn, { loading: loadingSave, error: errorSave, data: dataSave }] =
    useMutation(APPROVECANCLEPROJECTPLANSAVE)

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          window.location.reload()
        },
      })
    }
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [dataSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          window.location.reload()
        },
      })
    }
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [dataSave])

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectData(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  }

  const btnAlertNotSelect = () => {
    Alert({
      type: 'error',
      title: t(`กรุณาเลือกรายการโครงการตรวจสอบ`),
      onOk() {},
    })
  }

  const btnConfirmApprove = () => {
    const dataApprove = encryptInput({
      cancelStatus: 2,
      cancelRemarkTH: '',
      cancelRemarkEN: '',
      approvePersonnelCode: userData.pCode,
      projectCode: getSelectData.map((ap) => {
        return ap.projectCode
      }),
    })
    if (getSelectData.length > 0) {
      Alert({
        type: 'confirm',
        title: t('กดยืนยันเพื่อทำการอนุมัติรายการที่เลือก'),
        onOk: async () => {
          await approveCancleProjectPlanSaveFn({
            variables: {
              inputData: dataApprove,
            },
            fetchPolicy: 'no-cache',
          })
        },
        onCancel() {
          return null
        },
      })
    } else {
      btnAlertNotSelect()
    }
  }

  const btnConfirmNotApprove = () => {
    if (getSelectData.length > 0) {
      fnAlertConfirmNotApprove({
        type: 'confirm',
        title: t('ระบุเหตุผลการไม่อนุมัติการขอยกเลิกโครงการ'),
        onConfirm: async (value) => {
          if (value.length === 0) {
            Alert({
              type: 'error',
              title: t(`กรุณาระบุเหตุผลการไม่อนุมัติการขอยกเลิกโครงการ`),
              onOk() {},
            })
          } else {
            let dataPost = {}
            dataPost = {
              cancelStatus: 3,
              cancelRemarkTH: value,
              cancelRemarkEN: value,
              approvePersonnelCode: userData.pCode,
              projectCode: getSelectData.map((ap) => {
                return ap.projectCode
              }),
            }
            await approveCancleProjectPlanSaveFn({
              variables: {
                inputData: encryptInput(dataPost),
              },
              fetchPolicy: 'no-cache',
            })
          }
        },
        onCancel() {
          return null
        },
      })
    } else {
      btnAlertNotSelect()
    }
  }

  useEffect(() => {
    if (userData) {
      const res = getFilters

      const dataSearching = {
        auditProjectCode: res.auditProjectCode ? res.auditProjectCode : '',
        auditProjectName: res.auditProjectName ? res.auditProjectName : '',
        auditProjectRefCode: res.auditProjectRefCode ? res.auditProjectRefCode : '',
        organizationCode: res.organizationCode ? res.organizationCode : '',
        auditProjectTeam: res.auditProjectTeam ? res.auditProjectTeam : '',
        auditProjectStatus: res.auditProjectStatus ? res.auditProjectStatus : '',
        personnelCode: userData.pCode ? userData.pCode : '',
        cancelStatus: res.auditCancelStatus ? res.auditCancelStatus : '',
      }

      getApproveCancelProjectListfn({
        variables: {
          inputData: encryptInput(dataSearching),
        },
      })
    }
  }, [getFilters, userData])

  useEffect(() => {
    if (getApproveCancelProjectList.data) {
      setDataTable(
        getApproveCancelProjectList.data.getApproveCancelProjectList.map((dt, key) => {
          return {
            key: key,
            projectCode: dt.projectCode,
            pjNameCol: dt.projectCode + ' : ' + checkLanguageReturnData(dt.projectNameTH, dt.projectNameEN),
            pjRefCol: checkLanguageReturnData(dt.projectRefNameTH, dt.projectRefNameEN),
            pjDateStartCol: dateDisplay(dt.dateStart),
            pjDateEndCol: dateDisplay(dt.dateEnd),
            pjAuditTeamCol: checkLanguageReturnData(dt.auditTeamNameTH, dt.auditTeamNameEN),
            pjPersonelCol: checkLanguageReturnData(dt.fullNameTH, dt.fullNameEN),
            pjApproveStatus: (
              <Status
                key={'status-apdt-1' + key}
                text={
                  dt.cancelStatus === 1
                    ? 'ขอยกเลิก'
                    : dt.cancelStatus === 2
                    ? 'อนุมัติยกเลิกโครงการ'
                    : dt.cancelStatus === 3 && 'ไม่อนุมัติยกเลิกโครงการ'
                }
              ></Status>
            ),
            pjOptionsCol: (
              <div key={'div-view-apdt-notplan'} style={{ textAlign: 'left' }}>
                <ButtonGroup
                  key={'btnGroup-view-apdt-approve-notplan'}
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => {
                        navigate('/auditplanning/approvecancelproject/approvecancelprojectdetails', {
                          state: {
                            refCode: dt.projectCode,
                          },
                        })
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (getApproveCancelProjectList.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getApproveCancelProjectList.error)),
        onOk() {},
      })
    }
  }, [getApproveCancelProjectList.data])

  return (
    <React.Fragment>
      {(loadingSave || getApproveCancelProjectList.loading) && <SpinnersNew />}
      <BreadcrumbNew data={breadcrumbList} title={t('อนุมัติการขอยกเลิกโครงการ')} />
      <ApproveCancelProjectFilter
        getValue={(e) => {
          setFilters({
            auditProjectCode: e.projectCode ? e.projectCode : '',
            auditProjectName: e.projectName ? e.projectName : '',
            auditProjectRefCode: e.projectRef ? e.projectRef : '',
            organizationCode: e.projectOrganization2 ? e.projectOrganization2 : '',
            auditProjectTeam: e.projectAuditTeam ? e.projectAuditTeam : '',
            auditCancelStatus: e.cancelStatus ? e.cancelStatus : '',
          })
        }}
      />
      <CardNew topic={t('อนุมัติการขอยกเลิกโครงการ')} icon="List" toggledrop={'false'}>
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            <Datatable
              columns={ApproveCancelProjectColumn}
              data={getDataTable}
              enableScroll={{ x: true }}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              paginationCustom={false}
              pagination={true}
              pageSize={10}
            />
          </Col>
        </Row>
        <Row gutter={[12, 12]} style={{ marginTop: 24, marginBottom: 24 }} align="center">
          <Col>
            <Button
              type="allow"
              roles={{ type: 'approve', menu: menu }}
              onClick={() => {
                rowSelection.length === 0 ? btnAlertNotSelect() : btnConfirmApprove()
              }}
            >
              {t('อนุมัติ')}
            </Button>
          </Col>
          <Col>
            <Button
              type="noAllow"
              roles={{ type: 'approve', menu: menu }}
              onClick={() => {
                rowSelection.length === 0 ? btnAlertNotSelect() : btnConfirmNotApprove()
              }}
            >
              {t('ไม่อนุมัติ')}
            </Button>
          </Col>
        </Row>
      </CardNew>
    </React.Fragment>
  )
}
