import { gql } from '@apollo/client'

export const CREATE_AUDIT_RESOLUTION = gql`
  mutation createAuditResolution($input: createAuditResolutionInput!) {
    createAuditResolution(input: $input)
  }
`

export const UPDATE_AUDIT_RESOLUTION = gql`
  mutation updateAuditResolution($input: updateAuditResolutionInput!) {
    updateAuditResolution(input: $input)
  }
`

export const DELETE_AUDIT_RESOLUTION = gql`
  mutation deleteAuditResolution($resId: String!, $userId: String!) {
    deleteAuditResolution(resId: $resId, userId: $userId)
  }
`

export const CREATE_AUDIT_RESOLUTION_AC = gql`
  mutation createAuditResolutionAC($userId: String!, $systemLanguage: String!, $detail: auditResolutionACDetail!) {
    createAuditResolutionAC(userId: $userId, systemLanguage: $systemLanguage, detail: $detail)
  }
`

export const UPDATE_AUDIT_RESOLUTION_AC = gql`
  mutation updateAuditResolutionAC(
    $userId: String!
    $agendaId: String!
    $systemLanguage: String!
    $detail: auditResolutionACDetail!
  ) {
    updateAuditResolutionAC(userId: $userId, agendaId: $agendaId, systemLanguage: $systemLanguage, detail: $detail)
  }
`

export const DELETE_AUDIT_RESOLUTION_AC = gql`
  mutation deleteAuditResolutionAC($agendaId: String!, $userId: String!) {
    deleteAuditResolutionAC(agendaId: $agendaId, userId: $userId)
  }
`
