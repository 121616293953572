import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_SELECT_RISK_MODEL } from './graphql/Query'
import { Select } from '../../select/Select'
import { displayText } from '../../../utilitys/helper'
import { encryptInput } from '../../../utilitys/crypto'

const SelectRiskModel = (props) => {
  const { placeholder, formname, handleChange, filters, ...otherProp } = props

  const { organizationCode } = filters || {}

  const [isData, isSetData] = useState([])

  const [getRiskModel, riskModel] = useLazyQuery(GET_SELECT_RISK_MODEL)

  useEffect(() => {
    getRiskModel({
      variables: { input: filters ? encryptInput({ ...filters, isActive: 1 }) : encryptInput({ isActive: 1 }) },
    })
  }, [organizationCode])

  useEffect(() => {
    if (riskModel.data) {
      isSetData(
        riskModel?.data?.getSelectRiskModel.map((v) => ({
          value: v.riskModelCode,
          label: v.riskModelCode + ' : ' + displayText(v.riskModelTH, v.riskModelEN),
          source: v,
        })),
      )
    }
  }, [riskModel?.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={riskModel.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectRiskModel
