import { gql } from '@apollo/client'

export const SAVE_RATE_RISK_PROCESS = gql`
  mutation SaveRateRiskProcess($input: RateRiskProcessSaveInput!) {
    saveRateRiskProcess(input: $input) {
      rateFacProCode
    }
  }
`

export const DELETE_RATE_RISK_PROCESS = gql`
  mutation DeleteRateRiskProcess($input: RateRiskProcessDeleteInput!) {
    deleteRateRiskProcess(input: $input) {
      rateRiskProId
    }
  }
`
