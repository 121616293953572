import styled from 'styled-components'

const PIC_WIDTH = 170
const PIC_HEIGHT = 170
export const UploadProfileStyles = styled.div`
  .upload-show {
    border: solid 0px #333;
    .ant-upload-list-picture-card {
      display: flex;
      min-height: 220px;
      position: relative;
      .ant-upload-list-picture-card-container {
        overflow: hidden;
        width: 100%;
        height: 100%;
        overflow: hidden;
        position: absolute;
        margin: 0px;
        .ant-upload-list-item-list-type-picture-card {
          width: ${PIC_WIDTH}px !important;
          height: ${PIC_HEIGHT}px !important;
          padding: 0px;
          border: 1px solid #d9d9d9;
          overflow: hidden;
          .ant-upload-list-item-info {
            transition: unset;
            opacity: 1;
            &::before {
              left: 0;
            }
            .ant-upload-span {
              .ant-upload-list-item-thumbnail {
                opacity: 1;
              }
              .ant-upload-list-item-name {
              }
            }
          }
          .ant-upload-list-item-actions {
          }
        }
      }
      .ant-upload-animate-inline-leave,
      .ant-upload-animate-inline-leave-start,
      .ant-upload-animate-inline,
      .ant-upload-animate-inline-leave-active {
        animation-duration: 0.00001s !important;
      }

      .ant-upload-animate-inline-appear,
      .ant-upload-animate-inline-appear-start,
      .ant-upload-animate-inline-appear-active {
        animation-duration: 0.00001s !important;
      }

      /* ------- */
      .ant-upload-select-picture-card {
        /* ปุ่มอัพโหลด */
        margin: auto;
        width: 104px;
        height: unset;
        background-color: unset;
        border: 0px dashed #d9d9d9;
        margin-bottom: 3px;
      }
    }
  }

  .hide-icon-remove {
    .ant-upload-list-picture-card .ant-upload-list-item-info::before {
      opacity: 0;
    }
    .ant-upload-list-item-actions {
      display: none;
    }
  }
`
