import styled from 'styled-components'
import Logo from '../../assets/images/logo.png'

export const SidebarStyles = styled.div`
  background-color: #f5f5f5 !important;
  .ant-menu.ant-menu-dark,
  .ant-menu-dark .ant-menu-sub,
  .ant-menu.ant-menu-dark .ant-menu-sub {
    background-color: #1d76bb;
  }
  .menu-scroll {
    max-height: calc(100vh - 202px);
    overflow-y: auto;
    padding-bottom: 20px;
  }

  //Scrollbar
  .menu-scroll::-webkit-scrollbar {
    //ความกว้าง scroll
    width: 8px;
    height: 8px;
  }
  /* .menu-scroll:hover::-webkit-scrollbar {
    //show scroll เมื่อ hover
    display: block;
  } */
  .menu-scroll::-webkit-scrollbar-track {
    //พื้นหลัง scroll
    background: #f0f2f5;
  }
  .menu-scroll::-webkit-scrollbar-thumb {
    //แท่ง scroll
    background: #9e9e9e;
    border-radius: 10px;
  }
  .menu-scroll::-webkit-scrollbar-thumb:hover {
    //เมื่อ hover แท่ง scroll
    background: #555;
  }

  //End Scrollbar
`
export const SidebarLineTop = styled.div`
  height: 18px;
  background-color: #1d76bb;
  border-top: 6px solid;
  border-color: #45a648;
`
export const AppName = styled.div`
  color: #0f4671;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 10px;
  @media screen and (max-width: 980px) {
    display: none;
  }
`
export const AppLogo = styled.div`
  @media screen and (min-width: 980px) {
    height: 123px;
    width: 86px;
    margin: 12px 15px 24px 22px;
    background-image: url(${Logo});
    background-repeat: no-repeat;
    background-size: cover;
  }
`
