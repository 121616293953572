import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Row, Col } from 'antd'
import * as _ from 'lodash'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { Alert } from '../../../components/alert/Alert'
import AccessHistoryFilter from './components/filter/AccessHistoryFilter'
import { GET_LOGFILE_LOGIN_PAGINATION } from './graphql/Query.js'
import { handleSort } from '../../../utilitys/pagination'
import { displayError } from '../../../utilitys/helper'
import { dateTimeDisplay } from '../../../utilitys/dateHelper'

const Responsive = { sm: 24, md: 24, lg: 24 }

const AccessHistoryList = (props) => {
  const { t } = useTranslation()
  const { menu } = props

  const breadcrumbList = [
    { label: t('งานประวัติการแก้ไขข้อมูล'), path: '/logs/accesshistroy' },
    { label: t('ประวัติการเข้าใช้งานระบบ') },
  ]

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [sort, setSort] = useState([])
  const [filters, setFilters] = useState({})

  const [cellPagination, { data: dataPagination, loading: loadingPagination, error: errorPagination }] =
    useLazyQuery(GET_LOGFILE_LOGIN_PAGINATION)

  useEffect(() => {
    if (Object.keys(filters).length) {
      cellPagination({
        variables: {
          input: encryptInput({
            filters: { ...filters },
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
    }
  }, [page, limit, sort, filters])

  const Column = [
    { title: t('ชื่อผู้ใช้งาน'), dataIndex: 'username', sorter: true, width: '15%', className: 'text-nowrap' },
    { title: t('อีเมล'), dataIndex: 'email', width: '15%', className: 'text-nowrap' },
    { title: t('ชื่อ-นามสกุล'), dataIndex: 'fullname', sorter: true, width: '10%', className: 'text-nowrap' },
    { title: t('วันเวลาเข้า'), dataIndex: 'loginDate', sorter: true, width: '15%', className: 'text-nowrap' },
    { title: t('วันเวลาออก'), dataIndex: 'logoutDate', sorter: true, width: '15%', className: 'text-nowrap' },
  ]

  useEffect(() => {
    if (dataPagination) {
      let response = dataPagination.logfileLoginPaginationGet
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (item, key) => {
          return {
            key: key + 1,
            username: item.username,
            email: item.email,
            fullname: item.fullname,
            loginDate: dateTimeDisplay(item.loginDate),
            logoutDate: dateTimeDisplay(item.logoutDate),
            changeType: item.changeType === 1 ? 'เพิ่ม' : 'แก้ไข',
          }
        }),
      )
    }
    if (errorPagination) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorPagination)),
        onOk() {},
      })
    }
  }, [dataPagination])

  return (
    <>
      {loadingPagination && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('ประวัติการเข้าใช้งานระบบ')} />
      <AccessHistoryFilter
        menu={menu}
        getValue={(e) => {
          const setFilterValue = {}

          // if (e.email) setFilterValue.email = e.email
          if (e.username) setFilterValue.username = e.username
          if (e.fullname) setFilterValue.fullname = e.fullname
          if (e.loginDate) setFilterValue.loginDate = e.loginDate
          if (e.logoutDate) setFilterValue.logoutDate = e.logoutDate

          setFilters({ ...setFilterValue })

          if (!Object.keys(setFilterValue).length) {
            setDataLists([])
            setPage(1)
            setLimit(10)
            setCount(0)
          }
        }}
      />
      <CardNew topic={t('รายการประวัติการเข้าใช้งานระบบ')} icon="List" toggledrop={'false'}>
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            <Datatable
              columns={Column}
              data={[...dataLists]}
              total={count}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              paginationCustom={true}
              searchCustom={true}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => setSort(handleSort(e?.sorter, []))}
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default AccessHistoryList
