import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import { Calendar } from 'react-feather'
import { months, monthsTH, shortNameDays, shortNameTHDays } from './utils/constants'
import { yearBoundaryFn } from '../../utilitys/dateHelper'
import 'moment/locale/th'
import './DatepickerStyle.scss'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-datepicker/dist/react-datepicker-cssmodules.css'
import { IconNew } from '../icon/Icon'

const dateFormatNew = (d) => {
  //2022-02-19 -> 19-02-2022
  if (d !== 'Invalid Date') {
    const setDate = new Date(d)
    const d_arr = moment(setDate).format('YYYY-MM-DD').split('-')
    return d_arr[2] + '-' + d_arr[1] + '-' + d_arr[0]
  }
}

const DatePickerTH = (props) => {
  const {
    yearBoundary,
    minDate,
    maxDate,
    lang,
    value,
    setValueDateFn,
    className,
    placeholder,
    disabled,
    isMaxDate,
    isMinDate,
    selectsStart,
    selectsEnd,
    ...otherProps
  } = props

  const years = yearBoundaryFn(yearBoundary, minDate, maxDate, lang)
  const [startDate, setStartDate] = useState(null)
  const [isDisplay, setIsDisplay] = useState(null)
  const [calendar, setCalendar] = useState(null)
  useEffect(() => {
    if (value) {
      const vdate = dateFormatNew(value)
      setIsDisplay(moment(vdate, 'DD-MM-YYYY').add(543, 'year')._d)
      setStartDate(moment(vdate, 'DD-MM-YYYY')._d)
    }
  }, [value])

  useEffect(() => {
    setValueDateFn(startDate ? moment(startDate).format('YYYY-MM-DD') : null)
  }, [startDate])

  return (
    <div style={{ position: 'relative' }} className={className}>
      <DatePicker
        ref={(e) => e && setCalendar(e)}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="header-calendar-row">
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className="header-calendar-wrap-item"
            >
              <IconNew icon="ChevronLeft" size={20} />
            </button>
            <select
              value={monthsTH[moment(date).month()]}
              onChange={({ target: { value } }) => changeMonth(monthsTH.indexOf(value))}
              className="header-calendar-wrap-item"
            >
              {monthsTH.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={moment(date).year() + 543}
              onChange={({ target: { value } }) => changeYear(value - 543)}
              className="header-calendar-wrap-item"
            >
              {years.map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                )
              })}
            </select>
            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className="header-calendar-wrap-item"
            >
              <IconNew icon="ChevronRight" size={20} />
            </button>
          </div>
        )}
        value={isDisplay}
        selected={isDisplay}
        onChange={(date) => {
          setStartDate(date)
          setIsDisplay(date ? new Date(moment(date).add(543, 'year')) : null)
        }}
        onFocus={() => {
          isDisplay && setIsDisplay(new Date(moment(isDisplay).subtract(543, 'year')))
        }}
        onBlur={() => {
          isDisplay && setIsDisplay(new Date(moment(isDisplay).add(543, 'year')))
        }}
        onSelect={(date) => {
          setStartDate(date)
          setIsDisplay(date ? new Date(moment(date).add(543, 'year')) : null)
        }}
        onClickOutside={() => {
          //เมื่อคลิกออกจากปฏิทิน
          if (parseInt(String(moment(isDisplay).year()).substring(0, 2)) < 25) {
            //ถ้าเป็น ค.ศ. ให้บวก 543
            isDisplay && setIsDisplay(new Date(moment(isDisplay).add(543, 'year')))
          }
        }}
        isClearable={false}
        readOnly={false}
        placeholderText={placeholder ? placeholder : 'เลือกวันที่'}
        className="ant-input"
        dateFormat="dd/MM/yyyy"
        formatWeekDay={(nameOfDay) => (shortNameTHDays[nameOfDay] ? shortNameTHDays[nameOfDay] : nameOfDay)}
        minDate={isMinDate}
        maxDate={isMaxDate}
        startDate={isMinDate}
        endDate={isMaxDate}
        selectsStart={selectsStart ? true : false}
        selectsEnd={selectsEnd ? true : false}
        onChangeRaw={(e) => e.preventDefault()} //disabled keyboard
        disabled={disabled}
        {...otherProps}
      />

      {!props.disabled && startDate ? (
        <button
          type="button"
          className="react-datepicker__close-icon"
          onClick={() => {
            if (props.onClear) {
              props.onClear()
            }
            setStartDate(null)
            setIsDisplay(null)
          }}
        ></button>
      ) : (
        <Calendar
          className="postion-calendar"
          onClick={() => {
            calendar.setOpen(true)
          }}
        />
      )}
    </div>
  )
}

const DatePickerEN = (props) => {
  const {
    yearBoundary,
    minDate,
    maxDate,
    lang,
    value,
    setValueDateFn,
    className,
    placeholder,
    disabled,
    isMaxDate,
    isMinDate,
    selectsStart,
    selectsEnd,
    ...otherProps
  } = props

  const years = yearBoundaryFn(yearBoundary, minDate, maxDate, lang)

  const [startDate, setStartDate] = useState(null)
  const [calendar, setCalendar] = useState(null)

  useEffect(() => {
    if (value) {
      const vdate = dateFormatNew(value)
      const spDate = vdate.split('-')
      const newDate = `${spDate[1]}-${spDate[0]}-${spDate[2]}`
      setStartDate(new Date(newDate))
    }
  }, [value])

  useEffect(() => {
    setValueDateFn(startDate ? moment(startDate).format('YYYY-MM-DD') : null)
  }, [startDate])

  return (
    <div style={{ position: 'relative' }} className={className}>
      <DatePicker
        ref={(e) => e && setCalendar(e)}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="header-calendar-row">
            <button
              type="button"
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className="header-calendar-wrap-item"
            >
              <IconNew icon="ChevronLeft" size={20} />
            </button>
            <select
              value={months[moment(date).month()]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              className="header-calendar-wrap-item"
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>

            <select
              value={moment(date).year()}
              onChange={({ target: { value } }) => changeYear(value)}
              className="header-calendar-wrap-item"
            >
              {years.map((option) => {
                return (
                  <option key={option} value={option}>
                    {option}
                  </option>
                )
              })}
            </select>
            <button
              type="button"
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              className="header-calendar-wrap-item"
            >
              <IconNew icon="ChevronRight" size={20} />
            </button>
          </div>
        )}
        value={startDate}
        selected={startDate}
        onChange={(date) => setStartDate(date)}
        isClearable={false}
        readOnly={false}
        placeholderText={placeholder ? placeholder : 'Select a date'}
        className="ant-input"
        dateFormat="dd/MM/yyyy"
        formatWeekDay={(nameOfDay) => (shortNameDays[nameOfDay] ? shortNameDays[nameOfDay] : nameOfDay)}
        minDate={isMinDate}
        maxDate={isMaxDate}
        startDate={isMinDate}
        endDate={isMaxDate}
        selectsStart={selectsStart ? true : false}
        selectsEnd={selectsEnd ? true : false}
        onChangeRaw={(e) => e.preventDefault()} //disabled keyboard
        disabled={disabled}
        {...otherProps}
      />

      {!props.disabled && startDate ? (
        <button
          type="button"
          className="react-datepicker__close-icon"
          onClick={() => {
            if (props.onClear) {
              props.onClear()
            }
            setStartDate(null)
          }}
        ></button>
      ) : (
        <Calendar
          className="postion-calendar"
          onClick={() => {
            calendar.setOpen(true)
          }}
        />
      )}
    </div>
  )
}

export const Datepicker = (props) => {
  const [isLang, setIsLang] = useState('en')

  useEffect(() => {
    setIsLang(localStorage.getItem('lang'))
  }, [localStorage.getItem('lang')])

  return isLang === 'en' ? <DatePickerEN {...props} lang={isLang} /> : <DatePickerTH {...props} lang={isLang} />
}

Datepicker.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  yearBoundary: PropTypes.number,
  setValueDateFn: PropTypes.func,
  minDate: PropTypes.string,
  maxDate: PropTypes.string,
}
