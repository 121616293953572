import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HorodinFormMationIndex from './horodinformation/HorodinFormMationIndex'
import LogFileEditDataIndex from './logfileeditdata/LogFileEditDataIndex'
import AccessHistoryIndex from './accesshistory/AccessHistoryIndex'
import PwddRevisionHistoryIndex from './pwddrevisionhistory/PwddRevisionHistoryIndex'
/**
 * @function Router
 * @description
 * Module: Logs
 * โมดูล: งานประวัติการแก้ไขข้อมูล
 * @returns Routes
 */

const Router = () => {
  return (
    <Routes>
      <Route path="/accesshistory">
        <Route path="" element={<AccessHistoryIndex menu={'LF01'} />} />
      </Route>
      <Route path="/PwddRevisionHistory">
        <Route path="" element={<PwddRevisionHistoryIndex menu={'LF02'} />} />
      </Route>
      <Route path="/horodinformation">
        <Route path="" element={<HorodinFormMationIndex menu={'LF04'} />} />
      </Route>
      <Route path="/logfileeditdata">
        <Route path="" element={<LogFileEditDataIndex menu={'LF03'} />} />
      </Route>
    </Routes>
  )
}

export default Router
