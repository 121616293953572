import { useMutation } from '@apollo/client'
import { Form } from 'antd'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '../../../../../components/alert/Alert'

import SelectStepModels from '../../../../../components/inputfromapi/selectstepmodels/SelectStepModels'
import { ModalNew } from '../../../../../components/modal/Modal'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../../utilitys/crypto'
import { SAVE_AUDIT_JOB_STEP_SELECT } from '../graphql/Mutation'

const ModalAddStep = ({ visible, actionPlanId, onClose = () => {}, onSuccess = () => {}, typeAction = 'add' }) => {
  const [form] = Form.useForm()
  const { t } = useTranslation()

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_AUDIT_JOB_STEP_SELECT)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        onOk() {},
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          onSuccess()
          form.resetFields()
        },
      })
    }
  }, [dataSave])

  const onFinish = () => {
    const field = form.getFieldValue()

    const { stepModelCode } = field

    const dataInput = {
      stepModelCode,
      actionPlanId,
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const renderTitle = () => {
    return t('คลังขั้นตอนการปฏิบัตงาน')
  }

  const footerHide =
    typeAction === 'view'
      ? { cancelButtonProps: { style: { display: 'none' } }, okButtonProps: { style: { display: 'none' } } }
      : {}

  const formName = 'modalAddStep'

  return (
    <React.Fragment>
      {loadingSave && <SpinnersNew />}
      <ModalNew
        visible={visible}
        onSubmit={form.submit}
        onClose={(data) => {
          onClose(data)
          form.resetFields()
        }}
        testTitle={renderTitle()}
        typeAction={typeAction}
        type="small"
        {...footerHide}
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 9,
            lg: 9,
            xl: 7,
          }}
          wrapperCol={{
            md: 9,
            lg: 9,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name="stepModelCode"
            label={t('คลังขั้นตอนการปฏิบัติงาน')}
            rules={[{ required: true, message: t('กรุณาเลือกขั้นตอนการปฏิบัติงาน') }]}
          >
            <SelectStepModels
              placeholder={t('เลือกขั้นตอนการปฏิบัติงาน')}
              formname={formName}
              handleChange={(e) => form.setFieldsValue({ stepModelCode: e?.value })}
            />
          </Form.Item>
        </Form>
      </ModalNew>
    </React.Fragment>
  )
}

export default ModalAddStep
