import React from 'react'
import { useTranslation } from 'react-i18next'
import { CardNew } from '../../../components/card/Card'
import { Row, Col, Form } from 'antd'
import { LabelNew } from '../../../components/label/Label'
import { InputNew } from '../../../components/input/Input'
import { ButtonNew } from '../../../components/button/Button'
import SelectProjectRefer from '../../../components/inputfromapi/selectprojectrefer/SelectProjectRefer'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import { Datepicker } from '../../../components/datepicker/Datepicker'

const SoiAuditCommitteeFilter = (props) => {
  const { t } = useTranslation()
  const formName = 'formFilter'
  // console.log(props)

  const onSubmit = () => {
    props.trigger.setFn(!props.trigger.value)
  }
  const formItemResponsive = {
    sm: 24,
    md: 12,
    lg: 8,
  }
  return (
    <>
      <CardNew topic={t('Filters')} icon="AlignJustify" toggledrop="true" buttontopright="true">
        <Row>
          <Col md={24}>
            <Form form={props.form} onFinish={onSubmit} autoComplete="off" name={formName} layout="vertical">
              <Row gutter={[12, 12]}>
                <Col {...formItemResponsive}>
                  <Form.Item
                    label={<LabelNew type="tab-header-inactive">{t('หัวเรื่องมติ')}</LabelNew>}
                    name="agendaName"
                  >
                    <InputNew placeholder={t('ระบุหัวเรื่องมติ')} />
                  </Form.Item>
                </Col>
                <Col {...formItemResponsive}>
                  <Form.Item
                    label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ')}</LabelNew>}
                    name="projectName"
                  >
                    <InputNew placeholder={t('ระบุชื่อโครงการ')} />
                  </Form.Item>
                </Col>
                <Col {...formItemResponsive}>
                  <Form.Item
                    label={<LabelNew type="tab-header-inactive">{t('แหล่งที่มาโครงการ')}</LabelNew>}
                    name="projectRefCode"
                  >
                    <SelectProjectRefer
                      filters={{ isActive: 1 }}
                      formname={formName}
                      placeholder={t('เลือกแหล่งที่มาของโครงการ')}
                      handleChange={(value) => {
                        props.form.setFieldsValue({ projectRefCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...formItemResponsive}>
                  <Form.Item
                    name="meetingDate"
                    labelAlign="left"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่ประชุม')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        props.form.setFieldsValue({
                          meetingDate: await data,
                        })
                      }}
                      onClear={() => {
                        props.form.setFieldsValue({ meetingDate: null })
                      }}
                      placeholder={t('วันที่ประชุม')}
                    />
                  </Form.Item>
                </Col>
                <Col {...formItemResponsive}>
                  <Form.Item
                    name="responsibleOrg"
                    labelAlign="left"
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยงานรับผิดชอบ')}</LabelNew>}
                  >
                    <SelectOrganization
                      placeholder={t('เลือกหน่วยงาน')}
                      formname={formName}
                      filters={{ isActive: 1 }}
                      handleChange={(value) => {
                        props.form.setFieldsValue({ responsibleOrg: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...formItemResponsive}>
                  <Form.Item
                    name="auditOrg"
                    labelAlign="left"
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยรับตรวจ')}</LabelNew>}
                  >
                    <SelectOrganization
                      filters={{ isActive: 1 }}
                      formname={formName}
                      placeholder={t('เลือกหน่วยรับตรวจ')}
                      handleChange={(value) => {
                        props.form.setFieldsValue({ auditOrg: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew type="search" roles={{ type: 'view', menu: props.menu }} htmlType="submit">
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew
                    type="clear"
                    onClick={() => {
                      props.form.resetFields()
                      props.trigger.setFn(!props.trigger.value)
                    }}
                  >
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default SoiAuditCommitteeFilter
