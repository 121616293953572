import { gql } from '@apollo/client'

export const SAVE_PROJECT_SIZE_SETUP = gql`
  mutation SaveProjectSizeSetup($input: ProjectSizeSetupSaveInput!) {
    saveProjectSizeSetup(input: $input) {
      projectSizeCode
    }
  }
`

export const DELETE_PROJECT_SIZE_SETUP = gql`
  mutation DeleteProjectSizeSetup($input: ProjectSizeSetupDeleteInput!) {
    deleteProjectSizeSetup(input: $input) {
      message
    }
  }
`
