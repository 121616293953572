import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useNavigate } from 'react-router-dom'
import { CREATE_CONTROL_FORM } from './graphql/Mutation'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import CheckerOrganizationModal from '../../components/modalformapi/modalCheckerOrganization/CheckerOrganizationModal'
import ControlFormAllCreate from './components/form/ControlFormAllCreate'
import { controlFormCreateCrumb } from './utils/constants'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError } from '../../../utilitys/helper'

/**
 * @function ControlFormCreate
 * @description
 * Menu: Control Form
 * เมนู: แบบประเมินควบคุมภายใน
 * @returns Component
 */

const ControlFormCreate = (props) => {
  const labelCol = { sm: 8, md: 8, lg: 8, xl: 8 }
  const wrapperCol = { sm: 16, md: 16, lg: 24, xl: 16, align: 'left' }

  const [form] = Form.useForm()
  const [user] = useDecodeUser()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [modal, setModal] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [orgListError, setOrgListError] = useState(false)

  const [createControlFormFn, createControlForm] = useMutation(CREATE_CONTROL_FORM)

  useEffect(() => {
    if (dataLists.length) setOrgListError(false)
  }, [dataLists])

  useEffect(() => {
    if (createControlForm.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          setDataLists([...[]])
          navigate('/internalcontrol/controlform/createquestion', {
            state: {
              activeType: 'createQuestion',
              controlFormId: createControlForm?.data?.createControlForm?.controlFormId,
            },
          })
        },
      })
    }

    if (createControlForm.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(createControlForm?.error)),
        onOk() {},
      })
    }
  }, [createControlForm.data])

  const onFinish = (data) => {
    const objData = encryptInput({
      controlFormCode: data.controlFormCode,
      controlFormTH: data.controlFormTH,
      controlFormEN: data.controlFormEN ? data.controlFormEN : data.controlFormTH,
      controlFormYear: data.controlFormYear,
      dateStart: data.dateStart,
      dateEnd: data.dateEnd,
      isActive: data.isActive ? 1 : 0,
      createdBy: user.userId,
      operationStatusCode: '10',
      controlFormOrganizationList: JSON.parse(data.organizationList).map((d) => ({
        organizationCode: d.organizationCode,
        createdBy: user.userId,
        organizationNameTH: d.organizationNameTH,
        organizationNameEN: d.organizationNameEN,
      })),
    })

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        createControlFormFn({ fetchPolicy: 'no-cache', variables: { input: objData } })
      },
      onCancel() {},
    })
  }

  const onFinishFailed = () => !dataLists.length && setOrgListError(true)

  const setOrganizationList = (v) => {
    if (_.isEmpty(dataLists)) return setDataLists([...v])
    setDataLists([..._.uniqBy([...dataLists, ...v], 'organizationCode')])
  }

  return (
    <>
      {createControlForm.loading && <SpinnersNew />}
      <Col sm={24} md={24} lg={24}>
        <Breadcrumb data={controlFormCreateCrumb} title={t('แบบประเมินควบคุมภายใน')} />
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          labelAlign="left"
          labelCol={{ ...labelCol }}
          wrapperCol={{ ...wrapperCol }}
          layout="horizontal"
          name="ControlFormCreate"
        >
          <ControlFormAllCreate
            form={form}
            formname={'ControlFormCreate'}
            dataListsFromModal={[...dataLists]}
            deletedOrg={(v) => setDataLists([...v])}
            setModal={(e) => setModal(e)}
            orgListError={orgListError}
            menu={props.menu}
          />
        </Form>
        {modal && (
          <CheckerOrganizationModal
            close={(e) => setModal(e)}
            open={modal}
            getData={(v) => setOrganizationList(v)}
            menu={props.menu}
            orgActive={dataLists}
          />
        )}
      </Col>
    </>
  )
}

ControlFormCreate.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default ControlFormCreate
