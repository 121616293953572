import React from 'react'
import { HomeStyle } from './HomeStyles'
import { useTranslation } from 'react-i18next'
import { LabelNew as Label } from '../../../components/label/Label'

const HomeIndex = () => {
  const { t } = useTranslation()

  return (
    <HomeStyle>
      <div className="container">
        <div className="center">
          <Label>{t('Welcome')}</Label>
        </div>
      </div>
    </HomeStyle>
  )
}

export default HomeIndex
