import { Col, Row, Tooltip } from 'antd'
import React from 'react'
import { Trans } from 'react-i18next'
import { displayText } from '../../../../../utilitys/helper'

export const Columns = () => {
  return [
    {
      title: <Trans>{'ลำดับ'}</Trans>,
      dataIndex: 'no',
      width: '100px',
      align: 'center',
      fixed: 'left',
      sorter: true,
    },
    {
      title: <Trans>{'ปี'}</Trans>,
      dataIndex: 'planYear',
      width: '100px',
      align: 'center',
      fixed: 'left',
      sorter: true,
      render: (item) => {
        const yearTH = item ? parseInt(item) + 543 : '-'
        return (
          <Row justify="center">
            <Col>
              <div>{item ? displayText(yearTH, item) : '-'}</div>
            </Col>
          </Row>
        )
      },
    },

    {
      title: <Trans>{'โครงการ'}</Trans>,
      dataIndex: 'projectName',
      width: '400px',
      align: 'left',
      fixed: 'left',
      sorter: true,
    },
    // {
    //   title: () => {
    //     return (
    //       <div>
    //         {'ความเสี่ยงตอนประเมิน'}
    //         <br />
    //         {'Audit Universe'}
    //       </div>
    //     )
    //   },
    //   align: 'center',
    //   dataIndex: 'AuditUniverse',
    //   width: '150px',
    // },
    {
      title: <Trans>{'ประเภทงาน'}</Trans>,
      align: 'left',
      dataIndex: 'projectTypeName',
      width: '150px',
      sorter: true,
    },
    {
      title: <Trans>{'ทีมตรวจ'}</Trans>,
      dataIndex: 'auditTeamName',
      align: 'left',
      width: '150px',
      sorter: true,
    },
    {
      title: <Trans>{'แผน'}</Trans>,
      align: 'center',
      children: [
        {
          title: <Trans>{'วันที่เริ่มต้น'}</Trans>,
          dataIndex: 'dateStart',
          width: '150px',
          align: 'center',
          sorter: true,
        },
        {
          title: <Trans>{'วันที่สิ้นสุด'}</Trans>,
          dataIndex: 'dateEnd',
          align: 'center',
          width: '150px',
          sorter: true,
        },
        {
          title: <Trans>{'วันทำงาน'}</Trans>,
          dataIndex: 'manday',
          align: 'center',
          width: '100px',
        },
        {
          title: <Trans>{'ไตรมาส'}</Trans>,
          align: 'center',
          dataIndex: 'quarter',
          width: '100px',
          sorter: true,
        },
      ],
    },

    {
      title: <Trans>{'ปฎิบัติจริง'}</Trans>,
      align: 'center',
      children: [
        {
          title: <Trans>{'วันที่เริ่มต้น'}</Trans>,
          dataIndex: 'dateStart_step',
          align: 'center',
          width: '150px',
          sorter: true,
        },
        {
          title: <Trans>{'วันที่สิ้นสุด'}</Trans>,
          dataIndex: 'dateEnd_step',
          align: 'center',
          width: '150px',
          sorter: true,
        },
        {
          title: <Trans>{'วันทำงาน'}</Trans>,
          dataIndex: 'manDay_step',
          align: 'center',
          width: '100px',
        },
      ],
    },
    {
      title: <Trans>{'ล่าช้าไปกี่วัน'}</Trans>,
      dataIndex: 'datelate',
      align: 'center',
      width: '150px',
      sorter: true,
    },
    {
      title: <Trans>{'สถานะการดำเนินงาน'}</Trans>,
      dataIndex: 'projectStatus',
      align: 'center',
      width: '170px',
      sorter: true,
    },
    {
      title: <Trans>{'ขั้นตอน'}</Trans>,
      dataIndex: 'stepTypeName',
      align: 'left',
      width: '200px',
      sorter: true,
      render: (item, record, index) => {
        return (
          <Tooltip key={'toolTip' + index} placement="top" title={record.stepTypeNameTH}>
            {item}
          </Tooltip>
        )
      },
    },
    {
      title: <Trans>{'หน่วยรับตรวจ'}</Trans>,
      dataIndex: 'organizationName',
      align: 'left',
      width: '250px',
      sorter: true,
    },
  ]
}
