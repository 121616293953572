import { gql } from '@apollo/client'

export const GET_MASTER_NAME_PREFIXES = gql`
  query getMasterNamePrefixes($input: MasterNamePrefixesInput!) {
    getMasterNamePrefixes(data: $input) {
      namePrefixId
      namePrefixCode
      namePrefixTH
      namePrefixEN
      isActive
    }
  }
`
