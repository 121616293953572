import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_DOCUMENT_TYPES } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectDocumentTypes = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props

  const [isData, isSetData] = useState([])

  const [getMasterDocumentTypesFn, getMasterDocumentTypes] = useLazyQuery(GET_MASTER_DOCUMENT_TYPES)

  useEffect(() => {
    getMasterDocumentTypesFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [filters])

  useEffect(() => {
    if (getMasterDocumentTypes.data) {
      isSetData(
        _.map(getMasterDocumentTypes?.data?.getMasterDocumentTypes, (item) => {
          return {
            label: item?.docTypeCode + ':' + displayText(item?.docTypeNameTH, item?.docTypeNameEN),
            value: item?.docTypeCode,
            source: item,
          }
        }),
      )
    }
  }, [getMasterDocumentTypes.data])
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterDocumentTypes.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectDocumentTypes
