import { gql } from '@apollo/client'

export const GET_EMAIL_SEND_SETUP_PAGINATION = gql`
  query EmalSendSetUpPaginationGet($input: EmalSendSetUpPaginationInput!) {
    emalSendSetUpPaginationGet(input: $input) {
      result {
        no
        setMailId
        setMailType
        riskLevelCode
        time
        dueDate
        dueDateBefor
        dueDateAfter
        isActive
        riskLevelNameTH
        riskLevelNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_EMAIL_SEND_SETUP_TABTWO_PAGINATION = gql`
  query EmalSendSetUpPaginationTabTwoGet($input: EmalSendSetUpPaginationInput!) {
    emalSendSetUpPaginationTabTwoGet(input: $input) {
      result {
        setMailId
        no
        setMailType
        time
        dueDate
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_EMAIL_SEND_SETUP_BYID = gql`
  query EmalSendSetUpById($setMailId: String!) {
    emalSendSetUpById(setMailId: $setMailId) {
      setMailId
      setMailType
      riskLevelCode
      time
      dueDate
      dueDateBefor
      isActive
      dueDateAfter
    }
  }
`

export const GET_EMAIL_SEND_SETUP_TABTHREE = gql`
  query EmalSendSetUpTabThree {
    emalSendSetUpTabThree {
      setMailId
      dueDateBefor
      isActive
    }
  }
`
