import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { colunmTab1 } from '../../utils/columnAllInRick'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { ButtonNew } from '../../../../../components/button/Button'
import { useLocation } from 'react-router-dom'
// import { Alert } from '../../../../../components/alert/Alert'
import OrganizationsModal from '../modal/OrganizationsModal'
// import * as _ from 'lodash'
import { checkLanguageReturnData } from '../../components/fnForTemplateRisk'

export default function OrganizationsData(props) {
  const Responsive = {
    md: 24,
    lg: 24,
  }
  const { menu } = props
  const { t } = useTranslation()
  const isLang = localStorage.getItem('lang')
  const { state } = useLocation()
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [visible, setVisible] = useState(false)

  /* ---------------- DELETE ----------- */
  const oztDelete = (riskCode) => {
    props.oztDelete(riskCode)
  }

  const handleFormOnClose = (e) => {
    setVisible(e)
  }

  useEffect(() => {
    let response = props.obj
    setCount(response?.lengt)
    const res = response.map((dt, keys) => {
      return {
        key: keys,
        no: keys + 1,
        organizationCode: dt.organizationCode,
        organizationNameTH: dt.organizationNameTH,
        organizationNameEN: dt.organizationNameEN,
        organizationName: checkLanguageReturnData(isLang, dt.organizationNameTH, dt.organizationNameEN),
        organizationLevelNameTH: dt.organizationLevelNameTH,
        organizationLevelNameEN: dt.organizationLevelNameEN,
        organizationLevel: checkLanguageReturnData(isLang, dt.organizationLevelNameTH, dt.organizationLevelNameEN),
        fullName: checkLanguageReturnData(isLang, dt.fullNameTH, dt.fullNameEN),
        options:
          state.pageType === 'edit' ? (
            <div key={'div-view-templaterick' + keys} style={{ textAlign: 'left' }}>
              <ButtonGroup
                key={'btn-view-templaterick' + keys}
                menu={menu}
                icons={[
                  {
                    type: 'delete',
                    onClick: () => {
                      oztDelete(dt.organizationCode)
                    },
                  },
                ]}
              />
            </div>
          ) : (
            ''
          ),
      }
    })
    setDataLists(res)
  }, [])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col {...Responsive}>
          <Datatable
            columns={colunmTab1}
            data={dataLists}
            total={count}
            isScoll={{ x: true, y: true }}
            // onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            paginationCustom={false}
            pagination={true}
            searchCustom={true}
            pageSize={10}
            page={1}
            btnAdd={
              <>
                {state.pageType === 'edit' ? (
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    onClick={() => setVisible(true)}
                  >
                    {t('เพิ่มหน่วยรับตรวจ')}
                  </ButtonNew>
                ) : (
                  ''
                )}
              </>
            }
          />
        </Col>
      </Row>
      <OrganizationsModal
        open={visible}
        dataSelect={props.obj}
        objData={(v) => {
          // setOztObj(v)
          props.getValue(v)
        }}
        onClose={(e) => {
          handleFormOnClose(e)
        }}
        // refetch={() => dataCauseOfIssueList.refetch()}
        menu={menu}
      />
    </>
  )
}
