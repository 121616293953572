import React, { useEffect, useState } from 'react'
import { Select } from '../../select/Select'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'

const SelectVariableType = (props) => {
  const { placeholder, formname, handleChange, lockVariableTypes, editVariableType, ...otherProp } = props
  const { t } = useTranslation()

  const [isData, setIsData] = useState([])

  useEffect(() => {
    const coreData = [
      { value: 1, label: t('โอกาส') },
      { value: 2, label: t('ผลกระทบ') },
      // { value: 3, label: t('ตัวแปรอื่นๆ') },
    ]

    if (lockVariableTypes) {
      const ans = _.filter(coreData, (d) => {
        const check = _.find(JSON.parse(lockVariableTypes), (v) => parseInt(v.variableType) === d.value)

        return (!check && d) || (check?.variableType === editVariableType && d)
      })
      setIsData([...ans])
    } else {
      setIsData(coreData)
    }
  }, [lockVariableTypes, editVariableType])

  return (
    <Select
      {...otherProp}
      data={isData}
      placeholder={placeholder}
      scrollableId={formname}
      showSearch
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
    />
  )
}

export default SelectVariableType
