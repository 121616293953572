import { gql } from '@apollo/client'
// For RiskFormFirstStep
export const GET_RISK_FORM_BY_ID = gql`
  query getRiskById($input: GetRiskFormInput!) {
    getRiskById(input: $input) {
      riskFormId
      riskFormCode
      riskFormTH
      riskFormEN
      Year
      riskModelCode
      riskFormType
      oganizationAudit
      dateStart
      dateEnd
      operationStatusCode
      operationStatusNameTH
      operationStatusNameEN
      isActive
      riskFormOrganizationList {
        riskFormCode
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationLevelNameTH
        organizationLevelNameEN
        personnelCode
        createdAt
        createdBy
        personnelNameTH
        personnelNameEN
      }
    }
  }
`

// For RiskFormSecondStep
export const GET_RISK_FORM_DETAIL = gql`
  query getRiskFormDetail($input: GetRiskFormInput!) {
    getRiskFormDetail(input: $input) {
      riskFactorCode
      riskFactorNameTH
      riskFactorNameEN
      factorActCode
      factorActNameTH
      factorActNameEN
      factorActRiskCode
      factorActRiskNameTH
      factorActRiskNameEN
      remark
      isActive
      createdAt
      createdBy
    }
  }
`

export const AUDIT_PROJECT_FOR_AUDIT_CLOSE = gql`
  query auditProjectForAuditClose($input: PersonnelInputForAuditClose!) {
    auditProjectForAuditClose(input: $input) {
      # projectId
      projectCode
      actionPlanId
      projectNameTH
      projectNameEN
      planCode
      riskFormCode
      organizationCode
      personnelCode
      auditTeamCode
      organizationNameTH
      organizationNameEN
      fullNameTH
      fullNameEN
    }
  }
`
export const GET_MASTER_ORGANIZATIONS_FILTERS = gql`
  query getMasterOrganizationsFilters($input: MasterOrganizationsInput!) {
    getMasterOrganizationsFilters(input: $input) {
      organizationId
      organizationCode
      organizationNameTH
      organizationNameEN
      createdAt
      createdBy
      organizationLevelNameTH
      organizationLevelNameEN
      organizationLevelCode
      personnelCode
      personnelNameTH
      personnelNameEN
    }
  }
`
// For RiskList
export const GET_AUDIT_CLOSE_FILTERS = gql`
  query auditCloseFormFilters($input: AuditCloseListPagenation!) {
    auditCloseFormFilters(input: $input) {
      result {
        no
        closeStatus
        closeId
        # projectId
        projectCode
        projectNameTH
        projectNameEN
        planCode
        auditTeamCode
        actionPlanId
        dateClose
        riskFormCode
        exitApproveStatus
        organizationCode
        personnelCode
        organizationNameTH
        organizationNameEN
        fullNameTH
        fullNameEN
      }
      count
      page
      limit
    }
  }
`
export const GET_EMAIL_ORGANIZATION_BY_FORM = gql`
  query getEmailOrganizationByForm($riskFormCode: String!) {
    getEmailOrganizationByForm(riskFormCode: $riskFormCode) {
      email
      personnelCode
      organizationCode
    }
  }
`

export const AUDITISSUE_FOR_AUDIT_CLOSES_GET = gql`
  query auditIssueForAuditClosesGet($input: AuditIssuesForAuditClosesInput!) {
    auditIssueForAuditClosesGet(input: $input) {
      issueId
      rateFacProCode
      rateRiskConCode
      dateExpected
      suggestTH
      suggestEN
      rateFacProNameTH
      rateRiskRuleTH
      rateRiskConTH
      factTH
      factEN
      issueTH
      issueEN
      isActive
    }
  }
`
export const AUDIT_CAUSE_OF_ISSUE_FOR_AUDIT_CLOSES_GET = gql`
  query auditCauseOfIssueForAuditClosesGet($input: AuditIssuesForAuditClosesInput!) {
    auditCauseOfIssueForAuditClosesGet(input: $input) {
      causeId
      issueTH
      issueEN
      causeNameTH
      causeNameEN
      causeDetailTH
      causeDetailEN
      rateFacProNameTH
      rateFacProNameEN
      isActive
    }
  }
`

export const AUDIT_SUGGEST_OF_ISSUE_FOR_AUDIT_CLOSES_GET = gql`
  query auditSuggestOfIssueForAuditCloseGet($input: AuditIssuesForAuditClosesInput!) {
    auditSuggestOfIssueForAuditCloseGet(input: $input) {
      suggestId
      suggestTH
      suggestEN
      issueTH
      issueEN
      dateExpected
      isActive
    }
  }
`

export const GET_AUDIT_CLOSE_BY_CODE = gql`
  query getAuditCloseByCode($input: AuditCloseByCodeInput!) {
    getAuditCloseByCode(input: $input) {
      closeId
      projectCode
      comment
      performance
      effect
      conclusion
      isActive
      dateClose
      organizationCode
      personnelCode
      auditTeamCode
      closeStatus
      organizationNameTH
      organizationNameEN
      fullNameTH
      fullNameEN
      auditIssueList {
        issueId
        rateFacProCode
        rateRiskConCode
        rateFacProNameTH
        rateRiskRuleTH
        rateRiskConTH
        personnelsNameTH
        personnelsNameEN
        factTH
        issueTH
        isActive
      }
      auditCauseOfIssueList {
        causeId
        issueTH
        issueEN
        causeNameTH
        causeNameEN
        causeDetailTH
        causeDetailEN
        rateFacProNameTH
        rateFacProNameEN
        isActive
      }
      auditSuggestOfIssueList {
        suggestId
        suggestTH
        suggestEN
        issueTH
        issueEN
        dateExpected
        isActive
        auditSuggestOrganizations {
          organizationCode
          organizationNameTH
          organizationNameEN
          sendmail
          sendDate
        }
      }
      auditStatementOfIssueList {
        suggestPersonId
        suggestId
        suggestTH
        suggestEN
        isActive
        issueTH
        issueEN
        dateExpected
        auditSuggestExplain {
          explainId
          suggestId
          organizationNameTH
          organizationNameEN
          explainEN
          explainTH
          files {
            fileId
            fileNameLabel
            fileNameGen
            fileType
          }
        }
      }
    }
  }
`

export const GET_AUDIT_CLOSE_SUGGEST_BY_ID = gql`
  query AuditSuggestOfIssueForAuditCloseGetByID($input: AuditClosesSuggestOfIssuesByIDInput!) {
    auditSuggestOfIssueForAuditCloseGetByID(input: $input) {
      issueTH
      suggestTH
      dateExpected
    }
  }
`

export const GET_AUDIT_CLOSE_GET_EMAIL_BY_ID = gql`
  query GetAuditSuggestOrganizationsEmailPersonnel($organizationCode: String!) {
    getAuditSuggestOrganizationsEmailPersonnel(organizationCode: $organizationCode) {
      personnelCode
      email
    }
  }
`
export const GET_AUDIT_CAUSE_OF_ISSUE_BY_ID = gql`
  query getAuditCauseOfIssueById($causeId: String!) {
    getAuditCauseOfIssueById(causeId: $causeId) {
      causeId
      rateFacProCode
      rateRiskRuleCode
      rateRiskConCode
      issueId
      causeCode
      causeDetailTH
      causeDetailEN
    }
  }
`
export const GET_AUDIT_ISSUE_BY_ID = gql`
  query getAuditIssueById($input: String!) {
    getAuditIssueById(issueId: $input) {
      issueId
      issueTH
      issueEN
      factTH
      factEN
      findingTypeCode
      ISOCode
      ISOSubCode
      rateFacProCode
      rateRiskRuleCode
      rateRiskConCode
      riskLevelCode
      issueShow
      riskTH
      riskEN
      effectTH
      effectEN
      meetingTime
      remark
      toKnow
      performance
      conclusion
      files
      fileDetail
      issueHave
      auditIssueOrganizations {
        organizationCode
        organizationNameTH
        organizationNameEN
      }
    }
  }
`

export const GET_ORGANIZATION_PAGINATION = gql`
  query getOrganizationsPagination($input: OrganizationsPaginationInput!) {
    getOrganizationsPagination(input: $input) {
      result {
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationParentCode
        organizationParentNameTH
        organizationParentNameEN
        organizationLevelCode
        organizationLevelNameTH
        organizationLevelNameEN
        organizationSizeCode
        organizationSizeNameTH
        organizationSizeNameEN
        organizationType
        isActive
        responsiblePerson {
          firstNameTH
          lastNameTH
          firstNameEN
          lastNameEN
        }
      }
      count
      page
      limit
    }
  }
`

export const AUDIT_CLOSE_EXPORT = gql`
  query auditCloseExport($input: AuditCloseExportInput!) {
    auditCloseExport(input: $input) {
      message
      fileUrl
    }
  }
`
