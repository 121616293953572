import styled from 'styled-components'

import { colors } from '../../../../configs/styles.config'

export const RowStyle = styled.div`
  .table-row-color {
    color: ${colors.error};
  }
`

export const WrapperLabel = styled.div`
  .header-table {
    color: ${colors.error} !important;
  }
`
