import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Space, Form } from 'antd'
import { ModalNew } from '../../../../../components/modal/Modal'
import { InputNew } from '../../../../../components/input/Input'
import { RadioGroup, Radio } from '../../../../../components/radio/Radio'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../../../components/alert/Alert'
import { regexEng, regexEngThai, displayError } from '../../../../../utilitys/helper'
import { encryptInput } from '../../../../../utilitys/crypto'
import { CREATE_AUDIT_TEAM } from '../../graphql/Mutation'

const AuditTeamModal = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)

  /* ---------------- API ----------- */
  const [createAuditTeamFn, { loading: loadingDataAuditTeam, error: errorDataAuditTeam, data: dataAuditTeam }] =
    useMutation(CREATE_AUDIT_TEAM)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    setVisible(props.open)
  }, [props.open])

  useEffect(() => {
    if (dataAuditTeam) {
      const auditTeamCode = dataAuditTeam.auditTeamCreate.auditTeamCode
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/administrator/manageauditteam/detail', {
            state: { type_action: 'edit', auditTeamCode: auditTeamCode },
          })
        },
      })
    }
  }, [dataAuditTeam])

  useEffect(() => {
    if (errorDataAuditTeam) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorDataAuditTeam)),
        onOk() {},
      })
    }
  }, [errorDataAuditTeam])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    showConfirm(input)
  }
  const showConfirm = (input) => {
    let dataInput = {
      ...input,
      auditTeamNameTH: input.auditTeamNameTH || '',
      auditTeamNameEN: input.auditTeamNameEN || '',
      isActive: parseInt(input.isActive),
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        createAuditTeamFn({
          variables: {
            input: encryptInput(dataInput),
          },
        })
        setVisible(false)
        props.close(false)
        form.resetFields()
      },
      onCancel() {},
    })
  }

  function onClose(e) {
    setVisible(e)
    props.close(e)
  }
  return (
    <>
      {loadingDataAuditTeam && <SpinnersNew />}
      <ModalNew
        visible={visible}
        testTitle={t('เพิ่มข้อมูลทีมตรวจสอบ')}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        type="medium"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="auditTeamForm"
        >
          <Form.Item
            name="auditTeamCode"
            label={<Label type="tab-header-inactive">{t('รหัสทีมตรวจสอบ')}</Label>}
            rules={[
              { required: true, message: t('กรุณากรอกรหัสทีมตรวจสอบ') },
              {
                pattern: regexEng,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
              },
            ]}
          >
            <InputNew maxLength={10} />
          </Form.Item>
          <Form.Item
            name="auditTeamNameTH"
            label={<Label type="tab-header-inactive">{t('ชื่อทีมตรวจสอบภาษาไทย')}</Label>}
            rules={[
              { required: true, message: t('กรุณากรอกชื่อทีมตรวจสอบ') },
              {
                pattern: regexEngThai,
                message: t(`กรุณากรอกด้วยภาษาไทยหรือภาษาอังกฤษหรือตัวเลข ก-๙, A-Z, a-z 0-9`),
              },
            ]}
          >
            <InputNew maxLength={100} />
          </Form.Item>
          <Form.Item
            name="auditTeamNameEN"
            label={<Label type="tab-header-inactive">{t('ชื่อทีมตรวจสอบภาษาอังกฤษ')}</Label>}
            rules={[
              {
                pattern: regexEng,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
              },
            ]}
          >
            <InputNew maxLength={100} />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
            <RadioGroup>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>
                  <Label type="tab-header-inactive">{t('ใช้งาน')}</Label>
                </Radio>
                &nbsp;
                <Radio value={0}>
                  <Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>
                </Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
export default AuditTeamModal
