import React, { useState, useEffect, useMemo } from 'react'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ListCrumb, baseColumn } from './utils/constants'
import { handleSort } from '../../../utilitys/pagination'
import RiskLevelFiveYearReportFilter from './components/filter/riskLevelFiveYearReportFilter'
import { useGetPagination } from './hooks/useGetPagination'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { displayText } from '../../../utilitys/helper'

const RiskLevelFiveYearReportList = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState([])

  //============================= Column ================================

  const [yearDiff, setyearDiff] = useState(null)
  const [column, setcolumn] = useState(baseColumn)

  const onFilter = (data) => {
    const { yearStart, yearEnd, lang } = data

    setyearDiff(yearEnd - yearStart)
    const otherColumn = []

    for (let index = yearStart; index <= yearEnd; index++) {
      otherColumn.push({
        title: lang === 'th' ? index + 543 : index,
        dataIndex: index,
        width: '15px',
        align: 'center',
      })
    }

    setcolumn([...baseColumn, ...otherColumn])
    setFilters({ ...data })
  }

  const inputOptions = useMemo(() => {
    return { filters: { ...filters }, page, limit, sort }
  }, [filters, page, limit, sort])

  //========================== API PAGINATION ======================================
  const [dataList, listLoading] = useGetPagination(inputOptions)

  useEffect(() => {
    if (dataList) {
      const { result, count } = dataList

      const newData = result.map((dt, idx) => {
        const yearProperties = {}
        // คำนวนจากปีเริ่มของ riskForm.year ไปจนสุดที่ปีที่เลือก
        if (dt?.year && dt?.riskLevelYear) {
          for (
            let yearStep = parseInt(dt?.year);
            yearStep <= parseInt(dt.year) + yearDiff;
            yearStep += parseInt(dt?.riskLevelYear)
          ) {
            yearProperties[yearStep] = 'X'
          }
        }

        return {
          ...dt,
          key: idx,
          riskFormName: displayText(dt?.riskFormTH, dt?.riskFormEN),
          projectName: displayText(dt?.projectNameTH, dt?.projectNameEN),
          riskLevelName: displayText(dt?.riskLevelNameTH, dt?.riskLevelNameEN),
          ...yearProperties,
        }
      })
      setDataLists(newData)
      setCount(count)
    }
  }, [dataList])

  return (
    <>
      <Col sm={24} md={24} lg={24}>
        {listLoading && <SpinnersNew />}
        <Breadcrumb data={ListCrumb} title={t('รายงานผลประเมินความเสี่ยงแผน 5 ปี')} />
        <RiskLevelFiveYearReportFilter onFilter={onFilter} menu={menu} />
        <div style={{ marginBottom: '2rem' }}>
          <CardNew topic={t('รายการผลประเมินความเสี่ยงแผน 5 ปี')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col sm={24} md={24} lg={24} xl={24}>
                <Datatable
                  columns={column}
                  data={[...dataLists]}
                  total={count}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  enableScroll={{ x: true, y: false }}
                  pageSize={limit}
                  page={page}
                  searchCustom={true}
                  handleTableChange={(e) => {
                    const reNewField = [
                      { projectName: 'projectCode' },
                      { riskFormName: 'riskFormCode' },
                      { riskLevelName: 'riskLevelCode' },
                    ]

                    setSort(handleSort(e?.sorter, reNewField))
                  }}
                />
              </Col>
            </Row>
          </CardNew>
        </div>
      </Col>
    </>
  )
}

export default RiskLevelFiveYearReportList
