import React, { useEffect } from 'react'
import { Col, Row, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation, useLazyQuery } from '@apollo/client'
import { GET_PREPARE_AUDIT_REPORT_BY_CODE } from '../../graphql/Query'
import { SEND_MAIL_PREPARE_AUDIT_REPORT } from '../../graphql/Mutation'
import { ModalNew } from '../../../../../components/modal/Modal'
import { LabelNew } from '../../../../../components/label/Label'
import { ButtonNew } from '../../../../../components/button/Button'
import { InputNew } from '../../../../../components/input/Input'
import { Alert } from '../../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { decryptData, encryptInput } from '../../../../../utilitys/crypto'
import { displayError, displayText } from '../../../../../utilitys/helper'
import SelectTagEmailPersonnels from '../../../../../components/inputfromapi/selecttagemailpersonnels/SelectTagEmailPersonnels'

const PrepareAuditReportSendMail = (props) => {
  const responsive = { lg: 17 }
  const { menu, closeModal, prepareAuditReportSendMailModal, dataForSendMail } = props

  const { t } = useTranslation()
  const formname = 'prepareAuditReportSendMail'
  const [form] = Form.useForm()

  const [sendMailPrepareAuditReportFn, sendMailPrepareAuditReport] = useMutation(SEND_MAIL_PREPARE_AUDIT_REPORT)

  const [getPrepareAuditReportByCodeFn, getPrepareAuditReportByCode] = useLazyQuery(GET_PREPARE_AUDIT_REPORT_BY_CODE)

  useEffect(() => {
    if (dataForSendMail.typeAction === 'send' && dataForSendMail.finalReportId) {
      getPrepareAuditReportByCodeFn({
        variables: { input: encryptInput({ finalReportId: dataForSendMail.finalReportId }) },
      })
    }
  }, [dataForSendMail.typeAction])

  useEffect(() => {
    if (getPrepareAuditReportByCode?.data) {
      const resData = getPrepareAuditReportByCode?.data?.getPrepareAuditReportByCode

      form.setFieldsValue({
        dear: t('ผู้เกี่ยวข้อง'),
        subject: displayText(resData?.titleNameTH, resData?.titleNameEN),
        sendTo: resData?.emailSendTo
          ? resData.emailSendTo
              .map((item) => {
                return JSON.stringify({ personnelCode: item.personnelCode, email: decryptData(item.email) })
              })
              .filter((item) => item)
          : [],
        sendCC: resData?.emailSendCC
          ? resData?.emailSendCC
              .map((item) => {
                return JSON.stringify({ personnelCode: item.personnelCode, email: decryptData(item.email) })
              })
              .filter((item) => item)
          : [],
      })
    }
    if (getPrepareAuditReportByCode?.error) {
      showAlertError('ไม่สามารถโหลดข้อมูลได้', getPrepareAuditReportByCode.error)
    }
  }, [getPrepareAuditReportByCode?.data])

  useEffect(() => {
    if (sendMailPrepareAuditReport?.data) {
      Alert({
        type: 'success',
        title: t('ส่งอีเมลสำเร็จ'),
        onOk() {},
      })
    }
    if (sendMailPrepareAuditReport?.error) {
      showAlertError('ไม่สามารถส่งอีเมลแจ้งเตือนได้', sendMailPrepareAuditReport?.error)
    }
  }, [sendMailPrepareAuditReport?.data])

  const onFinish = (data) => {
    data.finalReportId = dataForSendMail.finalReportId
    data.organizationName = dataForSendMail.organization
    data.organizationCodeReport = dataForSendMail.organizationCodeReport
    data.sendTo = data.sendTo.map((item) => {
      try {
        return JSON.parse(item)
      } catch (e) {
        return { personnelCode: null, email: item }
      }
    })
    if (data.sendCC && data.sendCC.length > 0) {
      data.sendCC = data.sendCC.map((item) => {
        try {
          return JSON.parse(item)
        } catch (e) {
          return { personnelCode: null, email: item }
        }
      })
    } else {
      delete data.sendCC
    }
    if (data.sendBCC && data.sendBCC.length > 0) {
      data.sendBCC = data.sendBCC.map((item) => {
        try {
          return JSON.parse(item)
        } catch (e) {
          return { personnelCode: null, email: item }
        }
      })
    } else {
      delete data.sendBCC
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการส่งอีเมล?'),
      onOk() {
        sendMailPrepareAuditReportFn({ variables: { input: encryptInput({ ...data }) } })
        closeModal(false)
      },
      onCancel() {},
    })
  }

  const showAlertError = (text, error) => {
    Alert({
      type: 'error',
      title: t(text),
      content: t(displayError(error)),
      onOk() {},
    })
  }

  return (
    <>
      {(sendMailPrepareAuditReport.loading || getPrepareAuditReportByCode.loading) && <SpinnersNew />}
      <ModalNew
        onSubmit={() => {}}
        onClose={() => Promise.all([form.resetFields(), closeModal(false)])}
        visible={prepareAuditReportSendMailModal}
        typeAction={dataForSendMail.typeAction}
        testTitle={t('เลือกเลขที่ตรวจสอบ')}
        type="medium"
        footer={null}
      >
        <Form
          name={formname}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelCol={{
            lg: 7,
            xl: 7,
          }}
          layout="horizontal"
          labelAlign="left"
        >
          <Row justify="center">
            <Col {...responsive}>
              <Form.Item
                name="dear"
                label={<LabelNew type="tab-header-inactive">{t('เรียน')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุเรียน') }]}
              >
                <InputNew placeholder={t('ระบุเรียน')} maxLength={255} />
              </Form.Item>
              <Form.Item
                name="subject"
                label={<LabelNew type="tab-header-inactive">{t('เรื่อง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุเรื่อง') }]}
              >
                <InputNew placeholder={t('ระบุเรื่อง')} maxLength={255} />
              </Form.Item>
              <Form.Item
                name="sendTo"
                label={<LabelNew type="tab-header-inactive">{t('ถึง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาเลือกถึง') }]}
              >
                <SelectTagEmailPersonnels
                  placeholder={t('เลือกถึง')}
                  formname={formname}
                  handleChange={async (e) => {
                    await form.setFieldsValue({
                      sendTo: e,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item name="sendCC" label={<LabelNew type="tab-header-inactive">{t('สำเนา')}</LabelNew>}>
                <SelectTagEmailPersonnels
                  placeholder={t('เลือกสำเนา')}
                  formname={formname}
                  handleChange={async (e) => {
                    await form.setFieldsValue({
                      sendCC: e,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item name="sendBCC" label={<LabelNew type="tab-header-inactive">{t('สำเนาลับ')}</LabelNew>}>
                <SelectTagEmailPersonnels
                  placeholder={t('เลือกสำเนาลับ')}
                  formname={formname}
                  handleChange={async (e) => {
                    await form.setFieldsValue({
                      sendBCC: e,
                    })
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 20]} justify="center">
            <Col>
              <ButtonNew type="primary" roles={{ type: dataForSendMail.typeAction, menu: menu }} htmlType="submit">
                {t('ส่งอีเมล')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew type="back" onClick={() => closeModal(false)}>
                {t('ยกเลิก')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </ModalNew>
    </>
  )
}

export default PrepareAuditReportSendMail
