import { gql } from '@apollo/client'

export const UPDATE_OR_CREATE_ACTION_EXPLAIN = gql`
  mutation updateOrCreateActionExplain($input: InputUpdateOrCreateActionExplain!) {
    updateOrCreateActionExplain(input: $input) {
      status
      projectCode
    }
  }
`

export const DELETE_ACTION_EXPLAIN = gql`
  mutation deleteActionExplain($input: InputActionExplainById!) {
    deleteActionExplain(input: $input) {
      status
      projectCode
    }
  }
`
