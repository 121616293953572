import { gql } from '@apollo/client'

export const GET_CONTROL_FORM_QUESTIONS = gql`
  query getSelectionControlFormQuestions($input: ControlFormQuestionInput!) {
    getSelectionControlFormQuestions(data: $input) {
      questId
      questTH
      questEN
      formConfigId
      choiceTypeCode
      questDetailType
      questRequired
      isActive
    }
  }
`
