import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { deryptByPass } from '../crypto'
const ByPass = () => {
  const { token } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (token) {
      redirect(token)
    }
  }, [token])

  const redirect = (token) => {
    const obj = deryptByPass(token)
    const { path } = obj
    delete obj?.path
    return navigate(path || '/', { state: obj })
  }
  return <></>
}
export default ByPass
