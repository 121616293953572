import { gql } from '@apollo/client'

export const GET_SERACH_AUDIT_STATUS_REPORT_LIST = gql`
  query getSearchAuditStatusReportList($input: AuditStatusReportPagenationInput!) {
    getSearchAuditStatusReportList(input: $input) {
      result {
        projectCode
        projectRefNameTH
        projectRefNameEN
        projectNameTH
        projectNameEN
        dateStart
        dateEnd
        organizationNameTH
        organizationNameEN
        auditTeamNameTH
        auditTeamNameEN
        personnelNameTH
        personnelNameEN
        projectStatusCode
        projectStatusNameTH
        projectStatusNameEN
      }
      limit
      page
      count
    }
  }
`
export const EXPORT_RISK_FORMS = gql`
  query exportRiskForms($input: ExportsRiskFormInput!) {
    exportRiskForms(input: $input) {
      message
      fileUrl
    }
  }
`
export const EXPORT_AUDIT_STATUS = gql`
  query exportAuditStatus($input: ExportsAuditStatusInput!) {
    exportAuditStatus(input: $input) {
      message
      fileUrl
    }
  }
`
