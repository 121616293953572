import { gql } from '@apollo/client'
export const GET_MASTER_CONTROL_NATURES = gql`
  query GetMasterControlNature($input: MasterControlNatureInput!) {
    getMasterControlNature(input: $input) {
      controlNatureId
      controlNatureCode
      controlNatureNameEN
      controlNatureNameTH
      isActive
    }
  }
`
