import React from 'react'
import { Select as SelectAntd } from 'antd'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { IconNew } from '../icon/Icon'
import { SelectStyle, TitleSelectStyle } from './SelectStyles'

// const { Option } = SelectAntd

export const Select = ({
  onChange = () => {},
  multiple = false,
  placeholder = 'เลือก...',
  allowClear = true,
  title,
  data = [],
  clearIcon = <IconNew icon={'X'} size={14} color="" />,
  scrollableId,
  filterOption,
  disabled = false,
  ...otherProp
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      {title ? (
        <>
          <TitleSelectStyle>{title}</TitleSelectStyle>
        </>
      ) : null}
      <SelectStyle>
        <SelectAntd
          className="ant-select-selection"
          mode={multiple ? 'multiple' : null}
          style={{ width: '100%' }}
          optionFilterProp="children"
          dropdownClassName="custom-select-dropdown"
          listHeight={168}
          dropdownMatchSelectWidth
          filterOption={filterOption}
          optionLabelProp="label"
          clearIcon={clearIcon}
          onChange={onChange}
          placeholder={t(placeholder)}
          allowClear={allowClear}
          options={data}
          getPopupContainer={() => document.getElementById(scrollableId)}
          disabled={disabled}
          {...otherProp}
        />
      </SelectStyle>
    </React.Fragment>
  )
}

Select.propTypes = {
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  placeholder: PropTypes.string,
  allowClear: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.array,
  clearIcon: PropTypes.element,
  scrollableId: PropTypes.string,
}
