import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'

import { GET_SELECT_AUDIT_JOB_STEP_BY_ACTION_PLAN_ID } from '../../graphql/Query'

import { displayText } from '../../../../../utilitys/helper'
import { Select } from '../../../../../components/select/Select'
import { encryptInput } from '../../../../../utilitys/crypto'
// import { encryptInput } from '../../../utilitys/crypto'

const SelectAuditJobStepByActionPlanId = (props) => {
  const {
    //   filters,
    stepIdRef,
    actionPlanId,
    placeholder,
    formname,
    handleChange,
    ...otherProp
  } = props

  const [isData, isSetData] = useState([])

  const [callSelect, { data, loading }] = useLazyQuery(GET_SELECT_AUDIT_JOB_STEP_BY_ACTION_PLAN_ID)

  useEffect(() => {
    if (actionPlanId) {
      const dataInput = {
        actionPlanId,
      }
      callSelect({
        variables: { input: encryptInput(dataInput) },
      })
    }
  }, [actionPlanId])

  useEffect(() => {
    if (data) {
      isSetData(
        data?.getSelectAuditJobStepByActionPlanId.map((item) => {
          let label = item.stepSort + ' : ' + displayText(item?.stepNameTH, item?.stepNameEN)

          return {
            value: item.stepId,
            label: label,
            source: item,
          }
        }),
      )
    }
  }, [data])

  useEffect(() => {
    if (stepIdRef && isData) {
      handleChange(_.find(isData, (data) => data.value === stepIdRef))
    }
  }, [stepIdRef, isData])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        loading={loading}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          handleChange(_.find(isData, (data) => data.value === value))
        }}
      />
    </>
  )
}

export default SelectAuditJobStepByActionPlanId
