import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import { CardNew } from '../../../../../components/card/Card'
import { InputNew } from '../../../../../components/input/Input'
import { ButtonNew } from '../../../../../components/button/Button'
import { LabelNew } from '../../../../../components/label/Label'
import { useLanguage } from '../../../../../hooks/useLanguage'
import SelectProjectRefer from '../../../../../components/inputfromapi/selectprojectrefer/SelectProjectRefer'
import SelectAuditTeams from '../../../../../components/inputfromapi/selectauditteams/SelectAuditTeams'
import SelectProjectStatus from '../../../../../components/inputfromapi/selectprojectstatus/SelectProjectStatus'
import SendEmailReportModal from '../../../components/SendEmailReportModal'
import ExportModalList from '../modal/ExportModalList'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError } from '../../../../../utilitys/helper'
import { useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../../../utilitys/crypto'
import { GET_FOLLOW_UP_REPORT_EXPORT } from '../../graphql/Query'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'

const FollowUpReportListFilter = (props) => {
  const Responsive = { md: 8, lg: 8 }
  const { onFilterFollowUpReportListFilter, menu } = props
  const [isLang] = useLanguage()
  const formname = 'FollowUpReportListFilter'

  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onFinish = (data) => {
    onFilterFollowUpReportListFilter({ ...data, lang: isLang })
  }
  const onClear = () => Promise.all([form.resetFields(), onFilterFollowUpReportListFilter({})])

  const [modalVisible, setmodalVisible] = useState(false)
  const [filterData, setfilterData] = useState({})

  //=========================== สำหรับการส่งเมลรายงาน ===========================

  const [exportReportMail, dataExportReportMail] = useLazyQuery(GET_FOLLOW_UP_REPORT_EXPORT)

  useEffect(() => {
    if (dataExportReportMail.data) {
      Alert({
        type: 'success',
        title: t('ส่งอีเมลสำเร็จ'),
        onOk() {},
      })
    }
    if (dataExportReportMail.error) {
      const err = dataExportReportMail.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [dataExportReportMail?.data])

  //=========================== Email Modal ===========================
  const [emailModalVisible, setemailModalVisible] = useState(false)
  const [mailValue, setMailValue] = useState()
  const [inputSendEmail, setInputSendEmail] = useState()

  useEffect(() => {
    if (inputSendEmail) {
      const dataInput = {
        filters: filterData,
        lang: localStorage.getItem('lang'),
        fileType: inputSendEmail.fileType,
        emailData: inputSendEmail, // สำหรับการส่งเมลรายงาน
      }

      // Call API Export
      exportReportMail({
        variables: {
          input: encryptInput(dataInput),
        },
      })
    }
  }, [inputSendEmail])

  const fncOnpressEmailSetting = () => {
    setMailValue({ reportName: 'รายงานการติดตามผลการดำเนินงาน', refCode: null })
    setemailModalVisible(true)
  }

  //=========================== =========================== ====

  return (
    <CardNew topic={t('Filters')} icon="List" toggledrop="false">
      <ExportModalList
        filterData={filterData}
        visible={modalVisible}
        onModalClose={() => {
          setmodalVisible(false)
        }}
      />
      {dataExportReportMail.loading && <SpinnersNew />}
      <SendEmailReportModal
        menu={menu}
        open={emailModalVisible}
        close={(e) => {
          setemailModalVisible(e)
        }}
        fileType={{ pdf: true, word: false, excel: true, csv: true }}
        setInputSendEmail={(e) => setInputSendEmail(e)}
        mailValue={mailValue}
      />
      <Form name={formname} form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <Form.Item
              name="projectRefCode"
              label={<LabelNew type="tab-header-inactive">{t('แหล่งที่มาของโครงการ')}</LabelNew>}
            >
              <SelectProjectRefer
                placeholder={t('เลือกแหล่งที่มาของโครงการ')}
                formname={formname}
                handleChange={(e) => form.setFieldsValue({ projectRefCode: e?.value })}
              />
            </Form.Item>
          </Col>

          <Col {...Responsive}>
            <Form.Item name="projectName" label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ')}</LabelNew>}>
              <InputNew placeholder={t('ระบุโครงการตรวจสอบ')} maxLength={200} />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name="auditTeamCode"
              label={<LabelNew type="tab-header-inactive">{t('ทีมงานที่รับผิดชอบ')}</LabelNew>}
            >
              <SelectAuditTeams
                placeholder={t('เลือกทีมงานที่รับผิดชอบ')}
                formname={formname}
                handleChange={(e) => form.setFieldsValue({ auditTeamCode: e?.value })}
              />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item name="projectStatusCode" label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}>
              <SelectProjectStatus
                placeholder={t('เลือกสถานะ')}
                formname={formname}
                handleChange={(e) => form.setFieldsValue({ projectStatusCode: e?.value })}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[12, 12]} justify="center">
          <Col>
            <ButtonNew type="search" roles={{ type: 'view', menu: menu }} htmlType="submit">
              {t('ค้นหา')}
            </ButtonNew>
          </Col>
          <Col>
            <ButtonNew type="clear" onClick={onClear}>
              {t('ล้างค่า')}
            </ButtonNew>
          </Col>
          <Col>
            <ButtonNew
              type="printerSuccess"
              roles={{ type: 'export', menu: menu }}
              menu={menu}
              onClick={() => {
                //   Promise.all([setExportStatus(1), setVisible(true)])
                form.validateFields().then(() => {
                  const field = form.getFieldValue()
                  setfilterData(field)
                  setmodalVisible(true)
                })
              }}
            >
              {t('พิมพ์')}
            </ButtonNew>
          </Col>
          <Col>
            <ButtonNew
              type="success"
              roles={{ type: 'export', menu: menu }}
              menu={menu}
              onClick={() => {
                form.validateFields().then(() => {
                  const field = form.getFieldValue()
                  setfilterData(field)

                  fncOnpressEmailSetting()
                })
              }}
            >
              {t('ส่งรายงาน')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
    </CardNew>
  )
}

export default FollowUpReportListFilter
