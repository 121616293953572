import { CodeBlock as CodeBlockOld } from 'react-code-blocks'
import { Row, Col, Card } from 'antd'

export const CodeBlock = ({ code }) => {
  return (
    <Row align="center">
      <Col span={24}>
        <Card title="Code" style={{ width: '100%', fontSize: '14px' }}>
          <CodeBlockOld text={code} language={'jsx'} showLineNumbers={false} theme={'a11yLight'} />
        </Card>
      </Col>
    </Row>
  )
}
