import React, { useState, useEffect } from 'react'
import { ModalNew } from '../../../../../components/modal/Modal'
import { useTranslation } from 'react-i18next'
import { Space, Form } from 'antd'
import { regexEngNoSpace, displayError } from '../../../../../utilitys/helper'
import { InputNew } from '../../../../../components/input/Input'
import { RadioGroup, Radio } from '../../../../../components/radio/Radio'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { Select } from '../../../../../components/select/Select'
import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { RISKMODELSSAVE } from '../../graphql/Mutation'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { Alert } from '../../../../../components/alert/Alert'
import { encryptInput } from '../../../../../utilitys/crypto'
import SelectOrganization from '../../../../../components/inputfromapi/selectorganization/SelectOrganization'

export default function CreateModal(props) {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [getRequired, setRequired] = useState(false)
  const [getVisitOztName, setVisitOztName] = useState(false)
  const navigate = useNavigate()

  const formName = 'from-rickmodal-create'
  const weights = [
    { value: 0, label: t('ไม่ถ่วงน้ำหนัก') },
    { value: 1, label: t('ถ่วงน้ำหนัก') },
  ]
  const haveWeights = [
    { value: 0, label: t('ไม่มี') },
    { value: 1, label: t('มี') },
  ]

  const haveGroup = [
    { value: 1, label: t('ประเมินองค์กร') },
    { value: 2, label: t('ประเมินสาขา') },
  ]

  const [riskModelSavefn, { loading: loadingSaved, error, data: dataSaved }] = useMutation(RISKMODELSSAVE)

  const onClose = (e) => {
    form.resetFields()
    props.close(e)
  }

  const onFinish = async (input) => {
    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกคลังประเมินความเสี่ยง'),
      onOk: async () => {
        await riskModelSavefn({
          variables: {
            inputData: encryptInput({
              riskModels: {
                riskModelCode: input.riskModelCode ? input.riskModelCode : '',
                riskModelTH: input.riskModelTH ? input.riskModelTH : '',
                riskModelEN: input.riskModelEN ? input.riskModelEN : '',
                weight: input.weight ? input.weight : '',
                organizationCode: input.organizationCode ? input.organizationCode : '',
                riskModelGroup: input.riskModelGroup,
                controlOrganization: input.controlOrganization ? input.controlOrganization : '',
                organizationName: input.organizationName ? input.organizationName : '',
                isActive: input.isActive ? input.isActive : '',
                action: 'create',
              },
            }),
            riskModelOrganization: [],
            riskModelFactors: [],
            riskModelFactorType2: [],
          },
          fetchPolicy: 'no-cache',
        })
      },
      onCancel() {
        return null
      },
    })
  }

  useEffect(() => {
    if (dataSaved) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          props.close(false)
          const pageType = 'edit'
          navigate('/administrator/templaterskprocess/form', {
            state: {
              pageType: pageType,
              refCode: dataSaved.riskModelsSave.riskModelCode,
            },
          })
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [dataSaved])

  return (
    <>
      {loadingSaved && <SpinnersNew />}
      <ModalNew
        visible={props.open}
        testTitle={t('เพิ่มคลังประเมินความเสี่ยง')}
        onSubmit={form.submit}
        onClose={(e) => {
          onClose(e)
        }}
        type="medium"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name="riskModelCode"
            label={<Label type="tab-header-inactive">{t('รหัสกระบวนการ')}</Label>}
            rules={[
              { required: true, message: t('กรุณากรอกรหัสกระบวนการ') },
              {
                pattern: regexEngNoSpace,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, 0-9`),
              },
            ]}
          >
            <InputNew placeholder={t('ระบุรหัสกระบวนการ')} maxLength={10} />
          </Form.Item>
          <Form.Item
            name="riskModelTH"
            label={<Label type="tab-header-inactive">{t('ชื่อกระบวนการภาษาไทย')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกชื่อกระบวนการภาษาไทย') }]}
          >
            <InputNew placeholder={t('ระบุชื่อกระบวนการภาษาไทย')} maxLength={200} />
          </Form.Item>
          <Form.Item
            name="riskModelEN"
            label={<Label type="tab-header-inactive">{t('ชื่อกระบวนการภาษาอังกฤษ')}</Label>}
          >
            <InputNew placeholder={t('ระบุชื่อกระบวนการภาษาอังกฤษ')} maxLength={200} />
          </Form.Item>
          <Form.Item name="weight" label={<Label type="tab-header-inactive">{t('การถ่วงน้ำหนัก')}</Label>}>
            <Select
              formname={formName}
              data={weights}
              placeholder={t('ระบุการถ่วงน้ำหนัก')}
              scrollableId={formName}
              showSearch
            />
          </Form.Item>
          <Form.Item
            name="riskModelGroup"
            label={<Label type="tab-header-inactive">{t('กระบวนการ')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกกระบวนการ') }]}
          >
            <Select
              formname={formName}
              data={haveGroup}
              // defaultValue={0}
              placeholder={t('เลือกกระบวนการ')}
              scrollableId={formName}
              onChange={() => {}}
              showSearch
            />
          </Form.Item>
          <Form.Item
            name="organizationCode"
            label={<Label type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกหน่วยงานที่รับผิดชอบ') }]}
          >
            <SelectOrganization
              formname={formName}
              placeholder={t('เลือกหน่วยงานที่รับผิดชอบ')}
              filters={{ organizationChecked: 2 }}
              handleChange={(e) => {
                form.setFieldsValue({ organizationCode: e?.value })
              }}
            />
          </Form.Item>
          <Form.Item
            name="controlOrganization"
            label={<Label type="tab-header-inactive">{t('มีหน่วยงานควบคุม')}</Label>}
          >
            <Select
              formname={formName}
              data={haveWeights}
              // defaultValue={0}
              placeholder={t('ระบุหน่วยงานควบคุม')}
              scrollableId={formName}
              onChange={(e) => {
                if (e === 1) {
                  setRequired(true)
                  setVisitOztName(false)
                } else {
                  setRequired(false)
                  setVisitOztName(true)
                }
              }}
              showSearch
            />
          </Form.Item>
          <Form.Item
            name="organizationName"
            label={<Label type="tab-header-inactive">{t('หน่วยงานควบคุม')}</Label>}
            rules={[{ required: getRequired, message: t('กรุณากรอกหน่วยงานควบคุม') }]}
          >
            <InputNew placeholder={t('ระบุหน่วยงานควบคุม')} disabled={getVisitOztName} maxLength={200} />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
            <RadioGroup>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                &nbsp;
                <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
