import { gql } from '@apollo/client'

export const FORWARD_SATISFACTION_ASSESSMENT = gql`
  mutation forwardSatisfactionAssessment($input: ForwardAssessmentInput!) {
    forwardSatisfactionAssessment(input: $input)
  }
`

export const SAVE_FORMRESULTS_BY_SATISFACTION_ASSESSMENT = gql`
  mutation saveformResultsBySatisfactionAssessment($input: SatisfactionAssessmentSaveInput!) {
    saveformResultsBySatisfactionAssessment(input: $input) {
      formResultId
      operationStatusCode
      makerStatusCode
    }
  }
`
