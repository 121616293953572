import React, { useState, useEffect } from 'react'
import { ModalNew } from '../../../../../components/modal/Modal'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import * as _ from 'lodash'
import { columnOrganizationfillter } from '../../utils/assessmentColumn'
import { Datatable } from '../../../../../components/datatable/Datatable'
import OrganizationFilter from '../filter/OrganizationFilter'
import { GET_ORGANIZATION_PAGINATION } from '../../graphql/Query'
import { useLazyQuery } from '@apollo/client'
import { checkLanguageReturnData } from '../../components/fnForManageAssessment'
import { encryptInput } from '../../../../../utilitys/crypto'
import { handleSort } from '../../../../../utilitys/pagination'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError } from '../../../../../utilitys/helper'

export default function OrganizationsModal(props) {
  const { open, dataDataFillter } = props
  const { t } = useTranslation()
  const [dataLists, setDataLists] = useState([])
  const [getData, setData] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  const [filters, setFilters] = useState({ isActive: 1, organizationChecked: 3 })

  const [getMasterOrganizationfn, getMasterOrganization] = useLazyQuery(GET_ORGANIZATION_PAGINATION)

  const onClose = (e) => {
    props.onClose(e)
  }

  const onSaveModal = () => {
    props.objData(getData)
    props.onClose(false)
  }

  const onFilterOrganization = (data) => {
    if (data.organizationName) {
      data[checkLanguageReturnData('organizationNameTH', 'organizationNameEN')] = data.organizationName
      delete data.organizationName
    }
    setFilters(data)
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setData(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  }

  useEffect(() => {
    if (open) {
      getMasterOrganizationfn({ variables: { input: encryptInput({ filters, pagination: { limit, page }, sort }) } })
    }
  }, [open, page, limit, filters, sort])

  useEffect(() => {
    if (getMasterOrganization?.data) {
      let response = getMasterOrganization?.data?.getMasterOrganizationsPagination
      const idCheck = _.map(dataDataFillter, (dt) => {
        return { personnelCode: dt.personnelCode }
      })
      const dataFiltered = _.differenceBy(response?.result, idCheck, 'personnelCode')
      setCount(dataFiltered.length)
      setDataLists(
        _.map(dataFiltered, (item) => {
          return {
            key: uuidv4(),
            organizationId: item.organizationId,
            organizationCode: item.organizationCode,
            organizationName: checkLanguageReturnData(item.organizationNameTH, item.organizationNameEN),
            organizationLevelName: checkLanguageReturnData(item.organizationLevelNameTH, item.organizationLevelNameEN),
            personnelCode: item.personnelCode,
            personnelName: checkLanguageReturnData(item.personnelNameTH, item.personnelNameEN),
            organizationLevelCode: item.organizationLevelCode,
            deleteId: item.organizationCode,
            organizationNameTH: item.organizationNameTH,
            organizationNameEN: item.organizationNameEN,
          }
        }),
      )
    }
    if (getMasterOrganization.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getMasterOrganization.error)),
        onOk() {},
      })
    }
  }, [getMasterOrganization?.data])

  return (
    <>
      <ModalNew
        visible={open}
        testTitle={t('เพิ่มหน่วยรับตรวจ')}
        onSubmit={onSaveModal}
        onClose={(e) => {
          onClose(e)
        }}
        type="big"
      >
        <OrganizationFilter onFilterOrganization={onFilterOrganization} menu={props.menu} />
        <Datatable
          columns={columnOrganizationfillter()}
          rowSelection={{ type: 'checkbox', ...rowSelection }}
          data={[...dataLists]}
          total={count}
          onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
          paginationCustom={true}
          enableScroll={{ x: true, y: true }}
          pageSize={limit}
          page={page}
          isLoading={getMasterOrganization?.loading ? true : false}
          handleTableChange={(e) => {
            const reNewField = [
              { organizationLevelName: checkLanguageReturnData('organizationLevelNameTH', 'organizationLevelNameEN') },
              { organizationName: checkLanguageReturnData('organizationNameTH', 'organizationNameEN') },
              { personnelName: checkLanguageReturnData('firstNameTH', 'firstNameEN') },
            ]

            setSort(handleSort(e?.sorter, reNewField))
          }}
        />
      </ModalNew>
    </>
  )
}
