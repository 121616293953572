import styled from 'styled-components'
import { colors } from '../../configs/styles.config'

export const BreadcrumbStyle = styled.div`
  display: flex;
  padding: 18px 18px 15px 0px;
  .title {
    font-size: 32px;
    padding-right: 10px;
    font-weight: 500;
  }
  .pipe {
    font-size: 32px;
    padding-right: 10px;
    color: #d9d9d9;
  }
  .home {
    margin-bottom: -5px;
  }
  .item {
    padding-top: 15px;
    span:not(.ant-breadcrumb-link, .ant-breadcrumb-separator, :last-child) {
      color: ${colors.primary};
      a {
        color: ${colors.primary};
      }
    }
  }

  /* // Medium devices (tablets, 768px and 991px) */
  @media (min-width: 768px) and (max-width: 991px) {
    .title {
      padding-top: 10px;
      font-size: 22px;
    }
    .ant-breadcrumb-link {
      font-size: 14px;
    }
  }
`
