import React, { useState, useEffect } from 'react'
import { breadcrumbDetail } from './utils/breadcrumbData'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card.jsx'
import { Form, Row, Col, Space } from 'antd'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
import { LabelNew as Label } from '../../../components/label/Label'
import { Alert } from '../../../components/alert/Alert'
import { InputNew } from '../../../components/input/Input'
import { regexEngNoSpace } from '../../../utilitys/helper'
import { Select } from '../../../components/select/Select'
import { ButtonNew } from '../../../components/button/Button'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import { columnsDetails, columnsExpand } from './utils/formColumn'
import { IconNew } from '../../../components/icon/Icon'
import { getSegmentUrl } from '../../../utilitys/helper'
// import { ButtonGroup } from '../../../components/button/ButtonGroup'
// import { Status } from '../../../components/status/Status.jsx'
import FormGroupModal from './components/modal/FormGroupModal'
import FormQuestions from './components/modal/FormQuestionsModal'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GETFORMSONE, GETFORMGROUPSBYFORMS, GETFORMQUESTIONSBYFORMS, GETMASTERFORMTYPESBYFORMS } from './graphql/Query'
import { FORMSSAVE } from './graphql/Mutation.js'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import * as _ from 'lodash'
import { checkLanguageReturnData } from './components/assessmentFormfn.js'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError } from '../../../utilitys/helper'

export default function AssessmentFormDetails(props) {
  const { menu } = props
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const pageType = getSegmentUrl(4)
  const navigate = useNavigate()
  const { state } = useLocation()
  const formName = 'assessment-detail-form-create'
  if (!state) return <Navigate to={'/administrator/assessmentsetup'} />

  const [editable, setEditable] = useState(true)
  const [dataList, setDataList] = useState([])
  const [dataSubList, setSubDataList] = useState([])
  const [newData, setNewData] = useState([])
  const [optionsFormType, setOptionsFormType] = useState([])
  const [statusEdit, setStatusEdit] = useState(false)

  // fro FormGroupModal
  const [visibleFormGroup, setvisibleFormGroup] = useState(false)
  const [typeFormGroup, setTypeFormGroup] = useState(null)
  const [dataFormGroupModal, setDataFormGroupModal] = useState(null)

  // FormQuestModal
  const [visibleFormQuestions, setVisibleFormQuestions] = useState(false)
  const [typeFormQuest, setTypeFormQuest] = useState(null)
  const [dataFormQuestModal, setDataFormQuestModal] = useState(null)

  const [getFormsOnefn, getFormsOne] = useLazyQuery(GETFORMSONE)
  const [getFormGroupsByFormsfn, getFormGroupsByForms] = useLazyQuery(GETFORMGROUPSBYFORMS)
  const [getFormQuestionsByFormsfn, getFormQuestionsByForms] = useLazyQuery(GETFORMQUESTIONSBYFORMS)
  const [getMasterFormTypesByFormsfn, getMasterFormTypesByForms] = useLazyQuery(GETMASTERFORMTYPESBYFORMS)
  const [formsSavefn, { loading: loadingSaved, error, data: dataSaved }] = useMutation(FORMSSAVE)

  const onFinish = async (input) => {
    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกแบบประเมิน'),
      onOk: async () => {
        const dataSave = {
          variables: {
            inputData: encryptInput({
              forms: {
                formId: input.formId,
                formCode: input.formCode,
                formNameTH: input.formNameTH,
                formNameEN: input.formNameEN ? input.formNameEN : input.formNameTH,
                formTypeCode: input.formTypeCode,
                isActive: input.isActive,
              },
              actions: pageType,
              formGroups: {
                data: dataList.map((dt) => {
                  dt['formQuest'] = _.filter(dataSubList, function (o) {
                    return Number(o.groupSort) === Number(dt.formSort)
                  })
                  return dt
                }),
              },
            }),
          },
        }
        await formsSavefn(dataSave)
      },
      onCancel() {
        return null
      },
    })
  }

  const fncDelete = (index) => {
    // ลบ dataList recode ที่เราลบ
    const filterDelete = _.filter(dataList, function (o) {
      return index.formSort !== o.formSort
    })
    // ลบ dataSubList ที่ เชื่อมกับ dataList recode ที่เราลบ
    const filterSubDelete = _.filter(dataSubList, function (o) {
      return index.formSort !== o.groupSort
    })
    setSubDataList(filterSubDelete)
    setDataList(filterDelete)
  }

  const fncQuestDelete = (index) => {
    const filterDelete = _.filter(dataSubList, function (o) {
      return index.formQuestId !== o.formQuestId
    })
    setSubDataList(filterDelete)
  }

  const fncButtonGroup = (data, type) => {
    setTypeFormGroup(type)
    setDataFormGroupModal(data)
    setvisibleFormGroup(true)
  }

  const fncButtonQuest = (data, type) => {
    setTypeFormQuest(type)
    setDataFormQuestModal(data)
    setVisibleFormQuestions(true)
  }

  // create กลุ่มคำถาม
  const fncSaveFormGroupModal = (data) => {
    data['key'] = data.formSort
    data['formGroupName'] = data.formSort + '. ' + checkLanguageReturnData(data.formGroupNameTH, data.formGroupNameEN)
    setDataList([...dataList, data])
  }

  // update กลุ่มคำถาม
  const fncUpdateFormGroupModal = (data, oldId, newID) => {
    // set dataList ใหม่
    setDataList(data)
    // set dataSubList ใหม่ เพื่อให้ไปตาม dataList ใหม่
    const dsArr = _.map(dataSubList, (dt) => {
      if (oldId === dt.formGroupId) {
        dt['formGroupId'] = newID
        dt['groupSort'] = newID
        dt['formQuestName'] =
          String(newID) + '.' + dt.formSort + ' ' + checkLanguageReturnData(dt.formQuestNameTH, dt.formQuestNameEN)
      }
      return dt
    })
    setSubDataList(dsArr)
  }

  // create คำถามใหม่
  const fncSaveFormQuestModal = (data) => {
    data['key'] = data.formSort
    data['state'] = data.isActive
    data['formRequiredName'] = String(data.formRequired)
      ? String(data.formRequired) === '0'
        ? 'ไม่จำเป็นต้องตอบ'
        : 'จำเป็นต้องตอบ'
      : ''
    data['formQuestName'] =
      data.groupSort + '.' + data.formSort + ' ' + checkLanguageReturnData(data.formQuestNameTH, data.formQuestNameEN)
    data['formQuestTypeName'] = String(data.formQuestType)
      ? String(data.formQuestType) === '0' || data.formQuestType === null
        ? 'ไม่ต้องมีคำอธิบาย'
        : 'มีคำอธิบาย'
      : ''
    setSubDataList([...dataSubList, data])
  }

  // update คำถาม
  const fncUpdateFormQuestModal = (data) => {
    setSubDataList(data)
  }

  useEffect(() => {
    if (pageType === 'edit' || pageType === 'copy') {
      setEditable(false)
    } else {
      setEditable(true)
    }
  }, [pageType])

  useEffect(() => {
    if (state.refCode) {
      getFormsOnefn({
        variables: {
          input: {
            formCode: state.refCode,
          },
        },
      })
      getFormGroupsByFormsfn({
        variables: {
          input: {
            formCode: state.refCode,
          },
        },
      })
      getFormQuestionsByFormsfn({
        variables: {
          input: {
            formCode: state.refCode,
          },
        },
      })
      getMasterFormTypesByFormsfn({
        variables: {
          isActive: 1,
        },
      })
    }
  }, [state.refCode])

  useEffect(() => {
    if (getFormsOne.data) {
      const res = getFormsOne.data.getFormsOne
      setStatusEdit(pageType === 'edit' || pageType === 'view' ? res.statusEdit : false)
      form.setFieldsValue({
        formId: res.formId,
        formTypeCode: res.formTypeCode,
        formNameTH: res.formNameTH,
        formNameEN: res.formNameEN,
        formCode: pageType === 'edit' ? res.formCode : '',
        isActive: res.isActive,
      })
    }
  }, [getFormsOne.data])

  useEffect(() => {
    if (dataSaved) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          // location.reload()
          navigate('/administrator/assessmentsetup')
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [dataSaved])

  useEffect(() => {
    if (getFormGroupsByForms.data) {
      const res = getFormGroupsByForms.data.getFormGroupsByForms
      const sortData = _.orderBy(res, ['formSort'], ['asc'])
      const dataRes = sortData.map((dt, key) => {
        return {
          key: key + 1,
          formGroupId: dt.formGroupId,
          formSort: dt.formSort,
          formGroupName: dt.formSort + '. ' + checkLanguageReturnData(dt.formGroupNameTH, dt.formGroupNameEN),
          isActive: dt.isActive,
          formCode: dt.formCode,
          formGroupNameTH: dt.formGroupNameTH,
          formGroupNameEN: dt.formGroupNameEN ? dt.formGroupNameEN : dt.formGroupNameTH,
        }
      })
      setDataList(dataRes)
    }
    if (getFormGroupsByForms.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(getFormGroupsByForms.error),
        onOk() {},
      })
    }
  }, [getFormGroupsByForms.data])

  useEffect(() => {
    if (getMasterFormTypesByForms.data) {
      setOptionsFormType(
        getMasterFormTypesByForms.data.getMasterFormTypesByForms.map((dt, keys) => {
          return {
            key: keys,
            label: checkLanguageReturnData(dt.formTypeNameTH, dt.formTypeNameEN),
            value: dt.formTypeCode,
            source: dt,
          }
        }),
      )
    }
    if (getMasterFormTypesByForms.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(getMasterFormTypesByForms.error),
        onOk() {},
      })
    }
  }, [getMasterFormTypesByForms.data])

  useEffect(() => {
    if (getFormQuestionsByForms.data) {
      setSubDataList(
        _.map(getFormQuestionsByForms.data.getFormQuestionsByForms, (dt, key) => {
          return {
            key: key + 1,
            formQuestName:
              String(dt.groupSort) +
              '.' +
              String(dt.formSort) +
              ' ' +
              checkLanguageReturnData(dt.formQuestNameTH, dt.formQuestNameEN),
            choiceTypeName: dt.choiceTypeCode ? checkLanguageReturnData(dt.choiceTypeNameTH, dt.choiceTypeNameEN) : '',
            formRequiredName: String(dt.formRequired)
              ? String(dt.formRequired) === '0'
                ? 'ไม่จำเป็นต้องตอบ'
                : 'จำเป็นต้องตอบ'
              : '',
            state: dt.isActive,
            formQuestId: dt.formQuestId,
            formGroupId: dt.formGroupId,
            formSort: dt.formSort,
            formQuestNameTH: dt.formQuestNameTH,
            formQuestNameEN: dt.formQuestNameEN,
            choiceTypeCode: dt.choiceTypeCode,
            choiceGroupCode: dt.choiceGroupCode,
            formQuestTypeName: String(dt.formQuestType)
              ? String(dt.formQuestType) === '0' || dt.formQuestType === null
                ? 'ไม่ต้องมีคำอธิบาย'
                : 'มีคำอธิบาย'
              : '',
            formQuestType: dt.formQuestType,
            formRequired: dt.formRequired,
            isActive: dt.isActive,
            groupSort: dt.groupSort,
          }
        }),
      )
    }
    if (getFormQuestionsByForms.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(getFormQuestionsByForms.error),
        onOk() {},
      })
    }
  }, [getFormQuestionsByForms.data])

  useEffect(() => {
    const QuestResults = _.groupBy(_.orderBy(dataSubList, ['formSort'], ['asc']), 'groupSort')
    const GroupResults = _.orderBy(dataList, ['formSort'], ['asc'])
    setNewData([
      ..._.map(GroupResults, (data) => {
        return {
          ...data,
          description: (
            <Datatable
              columns={columnsExpand({ fncQuestDelete, fncButtonQuest, editable, statusEdit, menu })}
              data={QuestResults[data.formSort] ? QuestResults[data.formSort] : []}
            />
          ),
        }
      }),
    ])
  }, [dataList, dataSubList])

  return (
    <React.Fragment>
      {getFormsOne.loading && <SpinnersNew />}
      {loadingSaved && <SpinnersNew />}
      <Breadcrumb data={breadcrumbDetail} title={t('จัดการข้อมูลแบบประเมิน')} />
      <CardNew
        topic={pageType === 'edit' ? t('แก้ไขข้อมูลแบบประเมิน') : t('เพิ่มข้อมูลแบบประเมิน')}
        icon="Home"
        toggledrop={'false'}
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            // span: 6,
            offset: 1,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 12,
            lg: 24,
          }}
          layout="horizontal"
          name={formName}
        >
          <Col md={24} lg={24} xl={22}>
            <Row style={{ marginBottom: 24 }} align="center" gutter={[24, 16]}>
              <Col sm={24} md={24} lg={12} xl={12}>
                <Form.Item name="formId" hidden={true}>
                  <InputNew />
                </Form.Item>
                <Form.Item
                  name="formTypeCode"
                  label={<Label type="tab-header-inactive">{t('ประเภทการประเมิน')}</Label>}
                  rules={[{ required: true, message: t('กรุณาเลือกประเภทการประเมิน') }]}
                >
                  <Select
                    formname={formName}
                    data={optionsFormType}
                    placeholder={t('ระบุประเภทการประเมิน')}
                    scrollableId={formName}
                    showSearch
                    disabled={pageType === 'edit' ? (statusEdit ? !editable : editable) : editable}
                  />
                </Form.Item>
                <Form.Item
                  name="formNameTH"
                  label={<Label type="tab-header-inactive">{t('ชื่อแบบประเมินภาษาไทย')}</Label>}
                  rules={[{ required: true, message: t('กรุณากรอกชื่อแบบประเมินภาษาไทย') }]}
                >
                  <InputNew
                    placeholder={t('ระบุชื่อแบบประเมินภาษาไทย')}
                    disabled={pageType === 'edit' ? (statusEdit ? !editable : editable) : editable}
                    maxLength={200}
                  />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>}
                  name="isActive"
                  initialValue={1}
                >
                  <RadioGroup disabled={pageType === 'edit' ? editable : editable}>
                    <Space size={[8, 8]} wrap>
                      <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                      &nbsp;
                      <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
                    </Space>
                  </RadioGroup>
                </Form.Item>
              </Col>
              <Col sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  name="formCode"
                  label={<Label type="tab-header-inactive">{t('รหัสแบบประเมิน')}</Label>}
                  rules={[
                    { required: true, message: t('กรุณากรอกรหัสแบบประเมิน') },
                    {
                      pattern: regexEngNoSpace,
                      message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, 0-9 และไม่เกิน 10 ตัวอักษร`),
                    },
                  ]}
                >
                  {/* maxLength={10} */}
                  <InputNew
                    placeholder={t('ระบุรหัสแบบประเมิน')}
                    maxLength={10}
                    disabled={pageType === 'edit' ? !editable : editable}
                  />
                </Form.Item>
                <Form.Item
                  name="formNameEN"
                  label={<Label type="tab-header-inactive">{t('ชื่อแบบประเมินภาษาอังกฤษ')}</Label>}
                >
                  <InputNew
                    placeholder={t('ระบุชื่อแบบประเมินภาษาอังกฤษ')}
                    disabled={pageType === 'edit' ? (statusEdit ? !editable : editable) : editable}
                    maxLength={200}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Label type="card-title" icon="List">
            {t('รายการคำถามแบบประเมิน')}
          </Label>
          <Datatable
            columns={columnsDetails({
              editable,
              statusEdit,
              fncDelete,
              fncButtonGroup,
              menu,
              formname: formName,
              form,
            })}
            data={newData}
            paginationCustom={false}
            searchCustom={true}
            expandable={{
              expandedRowRender: (record) => {
                return record.description
              },
              // onExpandedRowsChange: (e) => fncQuestDelete(e),
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <IconNew icon={'ChevronUp'} onClick={(e) => onExpand(record, e)} />
                ) : (
                  <IconNew icon={'ChevronDown'} onClick={(e) => onExpand(record, e)} />
                ),
            }}
            btnAdd={
              <>
                {(pageType === 'edit' || pageType === 'copy') && !statusEdit ? (
                  <>
                    <ButtonNew
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: menu }}
                      style={{ marginRight: '12px' }}
                      onClick={() => fncButtonGroup(null, 'add')}
                    >
                      {t('เพิ่มกลุ่มคำถาม')}
                    </ButtonNew>
                    <ButtonNew
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: menu }}
                      onClick={() => fncButtonQuest(null, 'add')}
                    >
                      {t('เพิ่มคำถาม')}
                    </ButtonNew>
                  </>
                ) : (
                  ''
                )}
              </>
            }
          />
          <Row gutter={[12, 12]} justify="center" style={{ marginBottom: 20, marginTop: 20 }}>
            {(pageType === 'edit' || pageType === 'copy') && (
              <Col>
                <ButtonNew type="primary" htmlType="submit" roles={{ type: 'add', menu: menu }}>
                  {t('บันทึก')}
                </ButtonNew>
              </Col>
            )}
            <Col>
              <ButtonNew onClick={() => navigate('/administrator/assessmentsetup')} type="back">
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </CardNew>
      <FormGroupModal
        open={visibleFormGroup}
        modalType={typeFormGroup}
        dataListObj={dataList}
        dataGroupObj={dataFormGroupModal}
        close={(e) => {
          setvisibleFormGroup(e)
        }}
        dataOnSave={(e) => {
          fncSaveFormGroupModal(e)
        }}
        dataOnUpdate={(e, oldId, newID) => {
          fncUpdateFormGroupModal(e, oldId, newID)
        }}
        menu={menu}
      />
      <FormQuestions
        open={visibleFormQuestions}
        modalType={typeFormQuest}
        dataQuestList={dataList}
        dataQuestSubList={dataSubList}
        dataQuestObj={dataFormQuestModal}
        close={(e) => {
          setVisibleFormQuestions(e)
        }}
        dataOnSave={(e) => {
          fncSaveFormQuestModal(e)
        }}
        dataOnUpdate={(e) => {
          fncUpdateFormQuestModal(e)
        }}
        menu={menu}
      />
    </React.Fragment>
  )
}
