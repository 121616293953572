import React from 'react'
import { Row, Col } from 'antd'
import TimeSheetApproveList from './TimeSheetApproveList'

const TimeSheetApproveIndex = (props) => {
  const { menu } = props
  const Responsive = {
    md: 24,
    lg: 24,
  }
  return (
    <>
      <Row>
        <Col {...Responsive}>
          <TimeSheetApproveList menu={menu} />
        </Col>
      </Row>
    </>
  )
}

export default TimeSheetApproveIndex
