import { gql } from '@apollo/client'

export const GET_MASTER = gql`
  query GetDataReceivableAuditMasterForFilter {
    getDataReceivableAuditMasterForFilter {
      year
      month {
        month_en
        month_th
      }
      branch {
        # BranchsKey
        BranchName
      }
    }
  }
`

export const GET_AMOUNT = gql`
  query GetDataReceivableAuditAmount($input: ReceivableAuditInput!) {
    getDataReceivableAuditAmount(input: $input) {
      countDebtor
      countDebtorBeforeOneYear
      Yamana
    }
  }
`

export const GET_GRAPHPIE = gql`
  query GetDataReceivableAuditGraphPie($input: ReceivableAuditInput!) {
    getDataReceivableAuditGraphPie(input: $input) {
      result {
        Port
        CountPLNPLTotal
        PercentPort
      }
      export {
        message
        fileUrl
      }
    }
  }
`

export const GET_GRAPHPIE_LASTYEAR = gql`
  query GetDataReceivableAuditGraphPieLastYear($input: ReceivableAuditInput!) {
    getDataReceivableAuditGraphPieLastYear(input: $input) {
      result {
        Port
        CountPLNPLTotal
        PercentPort
      }
      export {
        message
        fileUrl
      }
    }
  }
`

export const GET_GRAPHCOLUMN_YEAR = gql`
  query getDataReceivableAuditGraphColumnYear($input: ReceivableAuditInput!) {
    getDataReceivableAuditGraphColumnYear(input: $input) {
      result {
        Port
        CountPLNPLTotal
        PercentPort
      }
      export {
        message
        fileUrl
      }
    }
  }
`

export const GET_GRAPHCOLUMN_BRANCH = gql`
  query GetDataReceivableAuditGraphColumnBranch($input: ReceivableAuditInput!) {
    getDataReceivableAuditGraphColumnBranch(input: $input) {
      result {
        PL
        NPL
        BranchName
      }
      export {
        message
        fileUrl
      }
    }
  }
`

export const GET_TABLE = gql`
  query getDataReceivableAuditTable($input: ReceivableAuditInput!) {
    getDataReceivableAuditTable(input: $input) {
      result {
        BranchName
        StaffFile
        StarGradeMNow
        GradePriority
        Port
        CustomerRegistration
        BorrowerName
        ApprovalLimit
        Balance
        YearApproval
      }
      export {
        message
        fileUrl
      }
    }
  }
`
