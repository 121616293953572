import { Card, Col, Row } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'

import { GraphStyled } from './css/GraphStyled'
import { GraphColumn1 } from './components/graphcolumn/GraphColumn1'
import { GraphColumn2 } from './components/graphcolumn/GraphColumn2'
import { useGetColumn1 } from './hooks/useGetColumn1'
import { useGetColumn2 } from './hooks/useGetColumn2'

import { SpinnersSmaillNew } from '../../../../components/spinners/Spinners'
import { NoData } from '../../../../components/nodatagraph/NoData'
import { displayText } from '../../../../utilitys/helper'
import { colors } from '../../../../configs/styles.config'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  graph: {
    md: 12,
    lg: 12,
  },
}

const CalLevel = (levelCode) => {
  if (levelCode === 'VH') {
    return displayText('สูงมาก', 'Very High')
  } else if (levelCode === 'H') {
    return displayText('สูง', 'High')
  } else if (levelCode === 'M') {
    return displayText('กลาง', 'Medium')
  } else {
    return displayText('ต่ำ', 'Low')
  }
}

const LevelColor = (levelCode) => {
  if (levelCode === 'VH') {
    return '#0C6ABF'
  } else if (levelCode === 'H') {
    return '#EB895F'
  } else if (levelCode === 'M') {
    return colors.drakGreen
  } else {
    return '#2b4b91'
  }
}

const order = ['VH', 'H', 'M', 'L']

const RiskAuditUniverseGraph = (props) => {
  const { dataFilter } = props
  const [dataColumn1, setdataColumn1] = useState([])
  const [dataColumn2, setdataColumn2] = useState([])

  const inputOptions = useMemo(() => {
    return { filters: { ...dataFilter } }
  }, [dataFilter])

  // ==================================== Graph Column 1 ====================================

  const [resColumn1, loadingColumn1] = useGetColumn1(inputOptions)

  useEffect(() => {
    if (resColumn1) {
      const listDataColumn1 = []

      //แบ่งตามระดับความเสี่ยง
      const groupbyLevel = _.groupBy(resColumn1, 'riskLevelCode')

      // แปลง array to objects
      const convertLevel = Object.entries(groupbyLevel).map(([prop, data]) => ({
        prop,
        data,
      }))

      // เรียงลำดับ
      const sortedLevel = convertLevel.sort((a, b) => {
        const indexOfA = order.indexOf(a.prop)
        const indexOfB = order.indexOf(b.prop)
        if (indexOfA !== -1 && indexOfB !== -1) {
          return indexOfA - indexOfB
        }
        if (indexOfA !== -1) {
          return -1
        }
        if (indexOfB !== -1) {
          return 1
        }
        return 0
      })

      Object.entries(sortedLevel).forEach(([, valueLevel]) => {
        if (valueLevel?.prop !== 'null') {
          // หาจำนวนแบบประเมินความเสี่ยง
          const uniqRisk = _.uniqBy(valueLevel.data, 'riskFormCode')
          listDataColumn1.push({
            level: CalLevel(valueLevel?.prop),
            type: displayText('แบบประเมินความเสี่ยง', 'Risk Form'),
            value: uniqRisk.length,
          })

          // หาจำนวนโครงการ
          const uniqProject = _.uniqBy(valueLevel.data, 'projectCode')
          listDataColumn1.push({
            level: CalLevel(valueLevel?.prop),
            type: displayText('โครงการ', 'Audit Project'),
            value: uniqProject.length,
          })
        }
      })
      setdataColumn1(listDataColumn1)
    }
  }, [resColumn1])

  // ==================================== Graph Column 2 ====================================

  const [resColumn2, loadingColumn2] = useGetColumn2(inputOptions)

  useEffect(() => {
    if (resColumn2) {
      const listDataColumn2 = []
      const transYear = resColumn2.map((item) => {
        return { ...item, year: displayText(item.year ? parseInt(item.year) + 543 : item.year) }
      })

      // แบ่งตามปี
      const groupbyYear = _.groupBy(transYear, 'year')
      Object.entries(groupbyYear).forEach(([keyYear, valueYear]) => {
        // แบ่งตามระดับความเสี่ยง
        const groupbyLevel = _.groupBy(valueYear, 'riskLevelCode')

        // แปลง array to objects
        const convertLevel = Object.entries(groupbyLevel).map(([prop, data]) => ({
          prop,
          data,
        }))

        // เรียงลำดับ
        const sortedLevel = convertLevel.sort((a, b) => {
          const indexOfA = order.indexOf(a.prop)
          const indexOfB = order.indexOf(b.prop)
          if (indexOfA !== -1 && indexOfB !== -1) {
            return indexOfA - indexOfB
          }
          if (indexOfA !== -1) {
            return -1
          }
          if (indexOfB !== -1) {
            return 1
          }
          return 0
        })

        Object.entries(sortedLevel).forEach(([, valueLevel]) => {
          if (valueLevel.prop !== 'null') {
            // หาจำนวนโครงการ
            const uniqProject = _.uniqBy(valueLevel.data, 'projectCode')
            listDataColumn2.push({
              year: keyYear,
              type: CalLevel(valueLevel.prop),
              value: uniqProject.length,
              color: LevelColor(valueLevel.prop),
            })
          }
        })
      })

      setdataColumn2(listDataColumn2)
    }
  }, [resColumn2])

  return (
    <GraphStyled>
      <Row gutter={[16, 8]}>
        <Col {...Responsive.graph}>
          <Card className="card-dash card-pie">
            {loadingColumn1 && <SpinnersSmaillNew />}
            {!_.isEmpty(dataColumn1) ? <GraphColumn1 data={dataColumn1} /> : <NoData />}
          </Card>
        </Col>
        <Col {...Responsive.graph}>
          <Card className="card-dash card-pie">
            {loadingColumn2 && <SpinnersSmaillNew />}
            {!_.isEmpty(dataColumn2) ? <GraphColumn2 data={dataColumn2} /> : <NoData />}
          </Card>
        </Col>
      </Row>
    </GraphStyled>
  )
}

export default RiskAuditUniverseGraph
