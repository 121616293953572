import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_CONTROL_NATURES } from './graphql/Query'
import { Select } from '../../select/Select'
import * as _ from 'lodash'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectControlNatures = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { controlNatureId, controlNatureCode, controlNatureNameTH, controlNatureNameEN, isActive } = filters || {}

  const [isData, isSetData] = useState([])

  const [getMasterControlNatureFn, { data, loading }] = useLazyQuery(GET_MASTER_CONTROL_NATURES)

  useEffect(() => {
    getMasterControlNatureFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [controlNatureId, controlNatureCode, controlNatureNameTH, controlNatureNameEN, isActive])

  useEffect(() => {
    if (data) {
      isSetData(
        data?.getMasterControlNature.map((item) => {
          let label = displayText(item?.controlNatureNameTH, item?.controlNatureNameEN)
          if (!label) label = item.controlNatureNameTH

          return {
            value: item.controlNatureCode,
            label: `${item.controlNatureCode} : ${label}`,
            source: item,
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        loading={loading}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectControlNatures
