import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Row, Col, Input, Form, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { CardNew } from '../../../../../components/card/Card'
import { InputNew } from '../../../../../components/input/Input'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'
import { Radio, RadioGroup } from '../../../../../components/radio/Radio'
import { ButtonNew } from '../../../../../components/button/Button'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { LabelNew as Label } from '../../../../../components/label/Label'
import SelectOperationStatus from '../../../../../components/inputfromapi/selectoperationstatus/SelectOperationStatus'
import SelectYear from '../../../../../components/inputfromapi/selectyear/SelectYear'

const ControlFormAllCreate = (props) => {
  const responsiveCol = { sm: 24, md: 24, lg: 24 }
  const { form, formname, dataListsFromModal, deletedOrg, menu } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [dataLists, setDataLists] = useState([])
  const [orgListError, setOrgListError] = useState(false)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)

  useEffect(() => {
    if (dataLists.length) setOrgListError(false)
    form.setFieldsValue({ organizationList: JSON.stringify(dataLists) })
  }, [dataLists])

  useEffect(() => {
    setDataLists(dataListsFromModal ? [...dataListsFromModal] : [])
  }, [dataListsFromModal])

  const columns = [
    { title: t('ลำดับ'), dataIndex: 'key', width: '10%', render: (row, x, index) => index + 1 },
    { title: t('รหัสหน่วยงาน'), dataIndex: 'organizationCode', width: '15%' },
    { title: t('หน่วยงาน'), dataIndex: 'organizationName', width: '20%' },
    { title: t('ระดับหน่วยงาน'), dataIndex: 'organizationLevelName', width: '25%' },
    { title: t('ผู้รับผิดชอบ'), dataIndex: 'personnelName', width: '25%' },
    {
      title: '',
      dataIndex: 'organizationId',
      width: '5%',
      render: (row) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup
              menu={menu}
              size={18}
              icons={[
                {
                  type: 'delete',
                  onClick: () =>
                    dataLists.length > 1 && deletedOrg([..._.filter(dataLists, (e) => e.organizationId !== row)]),
                },
              ]}
            />
          </div>
        )
      },
    },
  ]

  return (
    <>
      <CardNew topic={t('เพิ่มแบบประเมินควบคุมภายใน')} icon="List" toggledrop={'false'}>
        <Row align="center">
          <Col md={24} lg={24} xl={16}>
            <Form.Item
              name="controlFormYear"
              label={<Label type="tab-header-inactive">{t('ประจำปี')}</Label>}
              rules={[{ required: true, message: t('กรุณาระบุปี') }]}
            >
              <SelectYear
                formname={formname}
                placeholder={t('ระบุปีงบประมาณ')}
                handleChange={(e) => form.setFieldsValue({ controlFormYear: e?.value })}
              />
            </Form.Item>

            <Form.Item
              name="controlFormCode"
              label={<Label type="tab-header-inactive">{t('รหัสแบบประเมิน')}</Label>}
              rules={[{ required: true, message: t('กรุณาระบุรหัสแบบประเมิน') }]}
            >
              <InputNew placeholder={t('ระบุรหัสแบบประเมิน')} maxLength={10} />
            </Form.Item>
            <Form.Item
              name="controlFormTH"
              label={<Label type="tab-header-inactive">{t('ชื่อแบบประเมินไทย')}</Label>}
              rules={[{ required: true, message: t('กรุณาระบุชื่อแบบประเมินภาษาไทย') }]}
            >
              <InputNew placeholder={t('ระบุชื่อแบบประเมินภาษาไทย')} maxLength={255} />
            </Form.Item>
            <Form.Item
              name="controlFormEN"
              label={<Label type="tab-header-inactive">{t('ชื่อแบบประเมินภาษาอังกฤษ')}</Label>}
            >
              <InputNew placeholder={t('ระบุชื่อแบบประเมินภาษาอังกฤษ')} maxLength={255} />
            </Form.Item>
            <Form.Item
              name={'operationStatusCode'}
              label={<Label type="tab-header-inactive">{t('สถานะการดำเนินงาน')}</Label>}
            >
              <SelectOperationStatus
                needOperationStatusCode={true}
                handleChange={(e) => form.setFieldsValue({ operationStatusCode: e?.value })}
                formname={formname}
                placeholder={t('รอดำเนินการ')}
                filters={{ operationStatusCode: '10', isActive: 1 }}
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              name="dateStart"
              label={<Label type="tab-header-inactive">{t('วันเริ่มต้นประเมิน')}</Label>}
              rules={[{ required: true, message: t('กรุณาเลือกวันเริ่มต้นประเมิน') }]}
            >
              <Datepicker
                setValueDateFn={async (data) => {
                  await setIsMinDate(data)
                  await form.setFieldsValue({ dateStart: data })
                }}
                placeholder={t('เลือกวันเริ่มต้นประเมิน')}
                isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                selectsStart={true}
              />
            </Form.Item>
            <Form.Item
              name="dateEnd"
              label={<Label type="tab-header-inactive">{t('วันสิ้นสุดประเมิน')}</Label>}
              rules={[{ required: true, message: t('กรุณาเลือกวันสิ้นสุดประเมิน') }]}
            >
              <Datepicker
                setValueDateFn={async (data) => {
                  await setIsMaxDate(data)
                  await form.setFieldsValue({ dateEnd: data })
                }}
                placeholder={t('เลือกวันสิ้นสุดประเมิน')}
                isMinDate={isMinDate ? new Date(isMinDate) : null}
                selectsEnd={true}
              />
            </Form.Item>
            <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
              <RadioGroup>
                <Space size={[8, 8]} wrap>
                  <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                  <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
                </Space>
              </RadioGroup>
            </Form.Item>
          </Col>
        </Row>
      </CardNew>
      <CardNew topic={t('หน่วยงานรับตรวจ')} icon="List" toggledrop={'true'}>
        <Row gutter={[16, 16]}>
          <Col {...responsiveCol}>
            <Datatable
              columns={columns}
              data={[...dataLists]}
              total={dataLists.length}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              paginationCustom={true}
              searchCustom={true}
              pageSize={limit}
              page={page}
              btnAdd={
                <>
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    onClick={() => props.setModal(true)}
                  >
                    {t('เพิ่มหน่วยรับตรวจ')}
                  </ButtonNew>
                  {props.orgListError || orgListError ? (
                    <span style={{ color: '#ea5456', margin: 'auto 15px' }}>{t('กรุณาระบุหน่วยรับตรวจ')}</span>
                  ) : null}
                </>
              }
            />
          </Col>
          <Col {...responsiveCol} align="center">
            <Space size={[8, 8]} wrap>
              <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                {t('บันทึก')}
              </ButtonNew>
              <ButtonNew
                type="back"
                onClick={() => Promise.all([form.resetFields(), setDataLists([...[]]), navigate(-1)])}
              >
                {t('ยกเลิก')}
              </ButtonNew>
            </Space>
          </Col>
          <Form.Item hidden={true} name="organizationList" rules={[{ required: true, message: 'required' }]}>
            <Input />
          </Form.Item>
        </Row>
      </CardNew>
    </>
  )
}

ControlFormAllCreate.propTypes = {
  form: PropTypes.object,
  formname: PropTypes.string,
  dataListsFromModal: PropTypes.array,
  menu: PropTypes.string.isRequired,
}

export default ControlFormAllCreate
