import React from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import SelectYear from '../../../../components/inputfromapi/selectyear/SelectYear.jsx'
import SelectIsActive from '../../../../components/inputfromapi/selectisactive/SelectIsActive.jsx'
import SelectPlanType from '../../../../components/inputfromapi/selectplantype/SelectPlanType.jsx'
import SelectPlanStatus from '../../../../components/inputfromapi/selectplanstatus/SelectPlanStatus.jsx'
import { Datepicker } from '../../../../components/datepicker/Datepicker'
import { CardNew } from '../../../../components/card/Card.jsx'
import { LabelNew } from '../../../../components/label/Label'
import { ButtonNew } from '../../../../components/button/Button'
import { InputNew as Input } from '../../../../components/input/Input'
import moment from 'moment'
// import { displayText } from '../../../../utilitys/helper.js'

const FilterAuditPlanList = (props) => {
  const { t } = useTranslation()

  const onFinish = () => {}
  const onFinishFailed = () => {}

  function setErrorField(name, errors) {
    // Set field errors
    return props.formFilter.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false // Message errors OR remove fields errors
      }
    ])
  }

  const validDate = () => {
    let valid = false
    if (props.formFilter.getFieldValue('dateStart') && props.formFilter.getFieldValue('dateEnd')) {
      let dateStart = new Date(props.formFilter.getFieldValue('dateStart'))
      let dateEnd = new Date(props.formFilter.getFieldValue('dateEnd'))
      if (dateStart <= dateEnd) {
        setErrorField('dateStart', false)
        setErrorField('dateEnd', false)
        valid = true
      } else {
        setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นน้อยกว่าวันที่สิ้นสุด'))
        setErrorField('dateEnd', t('กรุณาเลือกวันที่สิ้นสุดมากกว่าวันที่เริ่มต้น'))
      }
    } else {
      setErrorField('dateStart', false)
      setErrorField('dateEnd', false)
      valid = true
    }
    return valid
  }

  return (
    <>
      <CardNew topic={t('Filters')} icon="AlignJustify" toggledrop="false">
        <Row>
          <Col md={24}>
            <Form
              form={props.formFilter}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              name="filterAuditPlan"
              layout="vertical"
            >
              <Row gutter={[12, 12]}>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="planInfor"
                    label={<LabelNew type="tab-header-inactive">{t('รหัสหรือชื่อแผนงาน')}</LabelNew>}
                    rules={[
                      {
                        max: 200,
                        message: t('กรุณาระบุรหัสหรือชื่อแผนงานน้อยกว่าหรือเท่ากับ 200 ตัวอักษร')
                      }
                    ]}
                  >
                    <Input placeholder={t('ระบุรหัสหรือชื่อแผนงาน')} maxLength={200} />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="planYear"
                    label={<LabelNew type="tab-header-inactive">{t('ประจำปี')}</LabelNew>}
                    initialValue={Number(moment(new Date()).format('YYYY'))}
                  >
                    <SelectYear
                      formname="filterAuditPlan"
                      placeholder={t('เลือกปี')}
                      handleChange={(value) => {
                        props.formFilter.setFieldsValue({ planYear: value ? value.value : null })
                      }}
                      disabled={props.typeForm === 'view' ? true : false}
                      allowClear={false}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="dateStart"
                    labelAlign="left"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        props.formFilter.setFieldsValue({
                          dateStart: await data
                        })
                        validDate()
                      }}
                      onClear={() => {
                        props.formFilter.setFieldsValue({ dateStart: null })
                      }}
                      placeholder={t('เลือกวันที่เริ่มต้น')}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="dateEnd"
                    labelAlign="left"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        props.formFilter.setFieldsValue({
                          dateEnd: await data
                        })
                        validDate()
                      }}
                      onClear={() => {
                        props.formFilter.setFieldsValue({ dateEnd: null })
                      }}
                      placeholder={t('เลือกวันที่สิ้นสุด')}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="planType"
                    label={<LabelNew type="tab-header-inactive">{t('ประเภทแผนการตรวจสอบ')}</LabelNew>}
                  >
                    <SelectPlanType
                      formname="filterAuditPlan"
                      placeholder={t('เลือกประเภทแผนการตรวจสอบ')}
                      handleChange={(value) => {
                        props.formFilter.setFieldsValue({ planType: value ? value.value : null })
                      }}
                      disabled={props.typeForm === 'view' ? true : false}
                    />
                  </Form.Item>
                </Col>

                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="planStatusCode"
                    label={<LabelNew type="tab-header-inactive">{t('สถานะพิจารณา')}</LabelNew>}
                  >
                    <SelectPlanStatus
                      filters={{ isActive: 1 }}
                      formname="filterAuditPlan"
                      placeholder={t('เลือกสถานะพิจารณา')}
                      handleChange={(value) => {
                        props.formFilter.setFieldsValue({ planStatusCode: value ? value.value : null })
                      }}
                      disabled={props.typeForm === 'view' ? true : false}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item name="isActive" label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}>
                    <SelectIsActive
                      formname="filterAuditPlan"
                      placeholder={t('เลือกสถานะ')}
                      handleChange={(value) => {
                        props.formFilter.setFieldsValue({ isActive: value ? value.value : null })
                      }}
                      disabled={props.typeForm === 'view' ? true : false}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew
                    type="search"
                    onClick={() => {
                      const valid = validDate()
                      if (
                        valid &&
                        props.formFilter.getFieldsError().filter((item) => {
                          return item.errors.length !== 0
                        }).length === 0
                      ) {
                        props.setSearchStatus(props.searchStatus + 1)
                      }
                    }}
                    htmlType="submit"
                  >
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew
                    type="clear"
                    onClick={() => {
                      props.formFilter.resetFields()
                      props.setSearchStatus(props.searchStatus + 1)
                    }}
                  >
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default FilterAuditPlanList
