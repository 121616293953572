import styled from 'styled-components'

export const TableAmountStyled = styled.div`
  padding-top: 15px;

  .ant-col {
    position: relative;
  }
  .ant-card-head-title {
    text-align: center;
    padding: 40px 0px;
    .card-title-text {
      color: #0a5ba3 !important;
    }
  }

  .card-pie,
  .card-bar,
  .card-table {
    min-height: 350px;
  }

  .card-dash {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    .ant-card-head {
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      padding: 0 7px;
      min-height: auto;
      .ant-card-head-title {
        text-align: left;
        padding: 7px 0px;
        .card-title-icon {
          color: #333;
          padding-top: 4px;
          padding-bottom: 0px;
          padding-right: 5px;
          height: 0px;
        }
        .card-title-text {
          color: #333 !important;
          font-size: 14px;
          color: #fff;
          font-weight: normal;
        }
      }
    }
    .ant-card-body {
      padding: 5px;
      font-size: 14px;
    }
    .card-remark {
      padding-left: 4px;
      .card-remark-text {
        color: #333 !important;
      }
    }
  }

  .card-amount {
    .block-amount {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      .icon {
        padding: 12px 15px 0px 15px;
        color: #0a4780;
      }
      .amount {
        text-align: center;
        .number {
          min-width: 100px;
          font-size: 28px;
          color: #0a4780;
          padding: 10px 0 10px;
        }
        .text {
          font-size: 15px;
        }
      }
    }
  }
`
