import { gql } from '@apollo/client'

export const GET_MASTER_JOB_TYPES = gql`
  query getMasterJobTypes($input: MasterJobTypesInput!) {
    getMasterJobTypes(input: $input) {
      jobTypeId
      jobTypeCode
      jobTypeNameTH
      jobTypeNameEN
      jobGroupCode
      isActive
    }
  }
`
