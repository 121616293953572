import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_AUDIT_RESULT_CONTROLS, GET_PERSONNEL_ORGANIZATION, GET_AUDIT_RCMS_BY_ID } from '../../graphql/Query'
import { DELETE_AUDIT_RCM_RESULT_CONTROL } from '../../graphql/Mutation'
import { encryptInput } from '../../../../../utilitys/crypto'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'
import { displayText, displayError } from '../../../../../utilitys/helper'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { ButtonNew } from '../../../../../components/button/Button'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { Alert } from '../../../../../components/alert/Alert'
import { WrapperControl } from '../../css/ControlStyle'
import ControlFormModal from '../modal/ControlFormModal'

const ControlTab = (props) => {
  const { menu } = props

  const { t } = useTranslation()
  const { state } = useLocation()
  const navigate = useNavigate()
  const [user] = useDecodeUser()

  if (!state) return <Navigate to={'/auditoperations/racmatrix'} />

  const [modalControlForm, setModalControlForm] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [controlFormAction, setControlFormAction] = useState(null)
  const [rcmControlId, setRcmComtrolId] = useState(null)
  const [isPersonnelOrg, setIsPersonnelOrg] = useState(null)
  const [isCanEstimate, setIsCanEstimate] = useState(false)
  const [, /* isCanEdit */ setIsCanEdit] = useState(false)

  const [getAuditResultControlsFn, getAuditResultControls] = useLazyQuery(GET_AUDIT_RESULT_CONTROLS)
  const [deleteAuditRCMResultControlFn, deleteAuditRCMResultControl] = useMutation(DELETE_AUDIT_RCM_RESULT_CONTROL)
  const [getPersonnelOrganizationFn, getPersonnelOrganization] = useLazyQuery(GET_PERSONNEL_ORGANIZATION)
  const [getAuditRCMFn, getAuditRCM] = useLazyQuery(GET_AUDIT_RCMS_BY_ID)

  useEffect(() => {
    if (state?.rcmId) {
      getAuditResultControlsFn({ variables: { input: encryptInput({ rcmId: state?.rcmId }) } })
    }
  }, [state?.rcmId])

  useEffect(() => {
    if (state?.rcmId) {
      getAuditRCMFn({ variables: { rcmId: encryptInput(state?.rcmId) } })
    }
  }, [state?.rcmId])

  useEffect(() => {
    if (user?.orgCode) {
      getPersonnelOrganizationFn({
        variables: {
          input: encryptInput({
            organizationCode: user?.orgCode,
            personnelCode: user?.pCode,
            organizationChecked: 2,
          }),
        },
      })
    }
  }, [user?.orgCode, user?.pCode])

  useEffect(() => {
    if (getAuditResultControls?.data) {
      const results = getAuditResultControls?.data?.getAuditRCMResultControl

      const ans = _.map(results, (d, i) => {
        return {
          key: i,
          rcmControlId: d.rcmControlId,
          rcmResultId: d.rcmResultId,
          index: i + 1,
          rateFacProName: displayText(d._rateFacProNameTH, d._rateFacProNameEN),
          rateRiskRuleName: displayText(d.rateRiskRuleTH, d.rateRiskRuleEN),
          riskBalance: d.riskBalance ? d.riskBalance : '-',
          objectiveName: displayText(d.objectiveTH, d.objectiveEN),
          guideName: displayText(d.guideTH, d.guideEN),
        }
      })
      setDataLists(ans)
    }

    if (getAuditResultControls?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditResultControls?.error)),
        onOk() {},
      })
    }
  }, [getAuditResultControls?.data])

  useEffect(() => {
    if (deleteAuditRCMResultControl?.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          getAuditResultControls.refetch()
        },
      })
    }

    if (deleteAuditRCMResultControl?.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(deleteAuditRCMResultControl?.error)),
        onOk() {},
      })
    }
  }, [deleteAuditRCMResultControl?.data])

  useEffect(() => {
    if (getPersonnelOrganization?.data) {
      setIsPersonnelOrg(
        getPersonnelOrganization?.data?.getPersonnelOrganization.length
          ? getPersonnelOrganization?.data?.getPersonnelOrganization[0]
          : null,
      )
    }
    if (getPersonnelOrganization?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getPersonnelOrganization?.error)),
        onOk() {},
      })
    }
  }, [getPersonnelOrganization?.data])

  useEffect(() => {
    if (getAuditRCM?.data) {
      const dataRCM = getAuditRCM?.data?.getAuditRCMsById?.auditRCMs

      /* ผู้สร้างสามารถแก้ไขแบบประเมินได้ */
      if (parseInt(user?.userId) === parseInt(dataRCM?.createdBy)) {
        setIsCanEdit(true)
      } else {
        setIsCanEdit(false)
      }

      if (isPersonnelOrg) {
        /* คนๆ นี้ต้องเป็น ผู้จัดทำ หรือ ผู้สอบทาน */
        /* จึงจะสามารทำแบบประเมิน */
        if (
          isPersonnelOrg?.personnelCode.toLowerCase() === dataRCM.personnelMaker.toLowerCase() ||
          isPersonnelOrg?.personnelCode.toLowerCase() === dataRCM.personnelReview.toLowerCase() ||
          parseInt(isPersonnelOrg?.isUserId) === parseInt(dataRCM?.createdBy)
        ) {
          setIsCanEstimate(true)
        } else {
          setIsCanEstimate(false)
        }
      }
    }

    if (getAuditRCM?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditRCM?.error)),
        onOk() {},
      })
    }
  }, [getAuditRCM?.data, isPersonnelOrg, user?.userId])

  const calculateRiskBalance = (riskBalance) => {
    if (riskBalance >= 1 && riskBalance <= 4) return 'L'
    if (riskBalance >= 5 && riskBalance <= 9) return 'M'
    if (riskBalance >= 10 && riskBalance <= 25) return 'H'
    if (riskBalance === '-') return riskBalance
  }

  const onDeleteAuditRCMResultControl = (rcmControlId) => {
    if (rcmControlId) {
      Alert({
        type: 'confirm',
        title: t('กรุณากดยืนยันหากต้องการลบข้อมูล'),
        onOk() {
          deleteAuditRCMResultControlFn({
            fetchPolicy: 'no-cache',
            variables: encryptInput({ rcmControlId: rcmControlId }),
          })
        },
        onCancel() {},
      })
    }
  }

  const columns = [
    { title: t('ลำดับ'), dataIndex: 'index', width: '80px', align: 'center', fixed: 'left' },
    { title: t('ปัจจัย/ความเสี่ยง'), dataIndex: 'rateFacProName', width: '350px', fixed: 'left' },
    {
      title: t('วิธีการควบคุม'),
      dataIndex: 'rateRiskRuleName',
      width: '600px',
      render: (text) => {
        const splitTx = text.split(',')
        return splitTx.length
          ? _.map(splitTx, (t, i) => (
              <Row key={i} style={{ marginBottom: '0.5rem' }}>
                <Col sm={1}>-</Col>
                <Col sm={23}>{t}</Col>
              </Row>
            ))
          : text
      },
    },
    {
      title: t('ความเสี่ยงที่เหลืออยู่'),
      dataIndex: 'riskBalance',
      width: '220px',
      align: 'center',
      render: (text) => calculateRiskBalance(text),
    },
    { title: t('วัตถุประสงค์'), dataIndex: 'objectiveName', width: '300px' },
    { title: t('วิธีการ/แนวทาง'), dataIndex: 'guideName', width: '300px' },
    {
      title: '',
      dataIndex: 'tools',
      // width: '80px',
      render: (text, record) => {
        let list = [
          {
            type: 'view',
            onClick: async () => {
              await setRcmComtrolId(record?.rcmControlId)
              await setControlFormAction('view')
              await setModalControlForm(true)
            },
          },
          {
            type: 'edit',
            onClick: async () => {
              await setRcmComtrolId(record?.rcmControlId)
              await setControlFormAction('edit')
              await setModalControlForm(true)
            },
          },
          { type: 'delete', onClick: () => onDeleteAuditRCMResultControl(record?.rcmControlId) },
        ]
        return isCanEstimate && <ButtonGroup menu={menu} size={18} icons={list} />
      },
    },
  ]

  return (
    <WrapperControl>
      {getAuditResultControls?.loading && <SpinnersNew />}
      <Datatable
        columns={columns}
        data={[...dataLists]}
        total={dataLists.length}
        searchCustom={true}
        scroll={{ x: 'max-content', y: 450 }}
        btnAdd={
          isCanEstimate ? (
            <ButtonNew
              type="plusTableBorderPrimary"
              roles={{ type: 'add', menu: menu }}
              onClick={async () => {
                await setControlFormAction('add')
                await setModalControlForm(true)
              }}
            >
              {t('เพิ่มการควบคุม')}
            </ButtonNew>
          ) : (
            <></>
          )
        }
      />
      <Row gutter={[12, 20]} justify="center" style={{ marginTop: '2rem' }}>
        <Col>
          <ButtonNew type="back" onClick={() => navigate('/auditoperations/racmatrix')}>
            {t('ย้อนกลับ')}
          </ButtonNew>
        </Col>
      </Row>
      {modalControlForm && (
        <ControlFormModal
          action={controlFormAction}
          rcmControlId={rcmControlId}
          close={(e) => setModalControlForm(e)}
          open={modalControlForm}
          menu={menu}
          resultModal={async (e) => {
            if (e) {
              await setModalControlForm(null)
              await getAuditResultControls.refetch()
            }
          }}
        />
      )}
    </WrapperControl>
  )
}

ControlTab.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default ControlTab
