import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import ApproveActionPlanListFilter from './ApproveActionPlanListFilter'
import { Columns } from './utils/ApproveActionPlanListColumn'

import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError, displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { GET_LIST_APPROVE_ACTION_PLAN_PAGINATION } from './graphql/Query'
import { ButtonNew } from '../../../components/button/Button'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { SAVE_APPROVE_ACTION_PLAN } from './graphql/Mutation'
import { AlertconfirmNotApprove } from '../../auditplanning/approveauditprojectnotplan/conponent/fnAlertconfirmNotApprove'
import ExportModal from './Modal/ExportModal'

const Responsive = {
  md: 24,
  lg: 24,
}

const ApproveActionPlanList = ({ menu }) => {
  const { t } = useTranslation()
  const [user] = useDecodeUser()

  const navigate = useNavigate()

  const [dataRow, setdataRow] = useState([])
  const [keySelecteds, setkeySelecteds] = useState([])
  const [dataSelecteds, setDataSelecteds] = useState([])

  const [filters, setfilters] = useState({})
  const [count, setcount] = useState(0)
  const [paginate, setPaginate] = useState({ page: 1, pageSize: 10 })
  const [sort, setsort] = useState([{ fieldSort: 'projectCode', sortType: 'ASC' }])

  const arrCrumb = [
    { label: t('งานปฎิบัติการตรวจสอบ'), path: '/auditoperations/approveactionplan' },
    { label: t('อนุมัติแผนปฏิบัติงาน') },
  ]

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_APPROVE_ACTION_PLAN)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorSave)),
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          fncGetList()
        },
      })
    }
  }, [dataSave])

  useEffect(() => {
    if (sort && filters && user) fncGetList()
  }, [sort, filters, user])

  const [callList, { loading: loadingList, error: errorList, data: dataList }] = useLazyQuery(
    GET_LIST_APPROVE_ACTION_PLAN_PAGINATION,
  )

  useEffect(() => {
    if (dataList) {
      let { result, count } = dataList.getListApproveActionPlanPagination
      let dataTemp = result.map((item, key) => {
        let projectName = item.projectCode + ' : ' + displayText(item.projectNameTH, item.projectNameEN)
        let projectRefName = displayText(item.projectRefNameTH, item.projectRefNameEN)
        let personnelName = displayText(item.personnelNameTH, item.personnelNameEN)
        let organizationName = displayText(item.organizationNameTH, item.organizationNameEN)
        return {
          ...item,
          key: key + 1,
          projectName,
          projectRefName,
          personnelName,
          organizationName,
          dateStart: dateDisplay(item.dateStart),
          dateEnd: dateDisplay(item.dateEnd),
        }
      })
      setdataRow(dataTemp)

      setcount(count)
    }
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorList)),
      })
    }
  }, [dataList])

  const fncGetList = () => {
    setDataSelecteds([])
    setkeySelecteds([])

    const dataInput = {
      sort: sort,
      filters: { ...filters, approver: user.pCode },
      pagination: {
        page: paginate.page,
        limit: paginate.pageSize,
      },
    }
    callList({
      variables: { input: encryptInput(dataInput) },
    })
  }

  const onGetFilter = (data) => {
    setfilters({ ...data })
  }

  const fncOnpressView = (data) => {
    navigate('/auditoperations/approveactionplan/detail', {
      state: {
        refCode: data.actionPlanId,
      },
    })
  }

  // ข้อความเตือน dataTable ต้องเลือกอย่างน้อย 1
  const [errorSelectList, seterrorSelectList] = useState(false)

  const fncValidateSelect = () => {
    if (_.isEmpty(dataSelecteds)) {
      seterrorSelectList(true)

      return true
    }

    seterrorSelectList(false)

    const filterHaveRCMs = dataSelecteds.filter((items) => _.isEmpty(items.planStatusCode))

    const filterApproveRCMs = dataSelecteds.filter((items) => items.planStatusCode !== '30')

    const filterOpen = dataSelecteds.filter((items) => Number(items.opened) < 1)

    let errorRCM_Open = null
    if (!_.isEmpty(filterHaveRCMs)) {
      errorRCM_Open = 'โครงการนี้ยังไม่มี RCM'
    } else if (!_.isEmpty(filterApproveRCMs)) {
      errorRCM_Open = 'โครงการนี้ยังไม่อนุมัติ RCM'
    } else if (!_.isEmpty(filterOpen)) {
      errorRCM_Open = 'โครงการนี้ยังไม่ได้ทำหนังสือเปิดตรวจ'
    }
    if (errorRCM_Open) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถอนุมัติได้'),
        onOk() {},
        content: t(errorRCM_Open),
      })
      return true
    }

    return false
  }

  const fncOnClickApprove = () => {
    if (fncValidateSelect()) return null

    const dataInput = {
      dataField: dataSelecteds.map((items) => {
        return {
          actionPlanId: items.actionPlanId,
          approveStatusCode: '20',
          approveRemark: null,
        }
      }),
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const fncOnClickNotApprove = () => {
    if (fncValidateSelect()) return null

    AlertconfirmNotApprove({
      type: 'confirm',
      title: t('ระบุเหตุผลการไม่อนุมัติแผนปฏิบัติงาน?'),
      onConfirm: async (value) => {
        const dataInput = {
          dataField: dataSelecteds.map((items) => {
            return {
              actionPlanId: items.actionPlanId,
              approveStatusCode: '30',
              approveRemark: value,
            }
          }),
        }

        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setkeySelecteds(selectedRowKeys)
      setDataSelecteds(selectedRows)
    },
    getCheckboxProps: (record) => ({
      disabled: record.approveStatusCode === '20' || record.approveStatusCode === '30',
    }),
    selectedRowKeys: keySelecteds,
  }

  //=========================== Modal ===========================
  const [modalVisible, setmodalVisible] = useState(false)
  const [actionPlanId, setactionPlanId] = useState()

  const fncOnpressExport = (data) => {
    setactionPlanId(data.actionPlanId)
    setmodalVisible(true)
  }

  //=========================== =========================== ====

  return (
    <React.Fragment>
      <ExportModal
        actionPlanId={actionPlanId}
        visible={modalVisible}
        onModalClose={() => {
          setmodalVisible(false)
        }}
      />
      <BreadcrumbNew data={arrCrumb} title={t('อนุมัติแผนปฏิบัติงาน')} />
      <ApproveActionPlanListFilter menu={menu} getValue={onGetFilter} />
      <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="List">
        <Row>
          <Col {...Responsive}>
            <Datatable
              columns={Columns({ fncOnpressExport, fncOnpressView, menu })}
              data={dataRow}
              total={count}
              searchCustom={true}
              pagination={true}
              pageSize={paginate.pageSize}
              page={paginate.page}
              rowSelection={{ type: 'checkbox', ...rowSelection }}
              isLoading={loadingList || loadingSave}
              // paginationCustom={true}
              onChangePagination={({ page, pageSize }) => {
                setPaginate({ page, pageSize })
              }}
              handleTableChange={(e) => {
                const reNewField = [
                  { projectName: 'projectCode' },
                  { projectRefName: 'projectRefCode' },
                  { dateStart: 'dateStart' },
                  { dateEnd: 'dateEnd' },
                  { organizationName: displayText('organizationNameTH', 'organizationNameEN') },
                  { approveStatusCode: 'approveStatusCode' },
                ]

                setsort(handleSort(e?.sorter, reNewField))
              }}
              btnAdd={
                <>
                  {errorSelectList ? (
                    <span style={{ color: '#ea5456', alignSelf: 'center', marginLeft: 5 }}>
                      {t('กรุณาเลือกอย่างน้อย 1 รายการ')}
                    </span>
                  ) : (
                    <></>
                  )}
                </>
              }
            />
          </Col>
        </Row>
        <Row gutter={[12, 12]} justify="center" style={{ marginTop: 20, marginBottom: 100 }}>
          <Col>
            <ButtonNew type="allow" roles={{ type: 'approve', menu: menu }} onClick={() => fncOnClickApprove()}>
              {t('อนุมัติ')}
            </ButtonNew>
          </Col>
          <Col>
            <ButtonNew type="noAllow" roles={{ type: 'approve', menu: menu }} onClick={() => fncOnClickNotApprove()}>
              {t('ไม่อนุมัติ')}
            </ButtonNew>
          </Col>
        </Row>
      </CardNew>
    </React.Fragment>
  )
}

export default ApproveActionPlanList
