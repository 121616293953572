import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>เลขที่เอกสาร</Trans>
      </div>
    ),
    dataIndex: 'jobNumber',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    width: '5%',
    sorter: { multiple: 1 },
  },
  {
    title: <Trans>ชื่องาน</Trans>,
    dataIndex: 'jobTypeName',
    width: '10%',
    sorter: { multiple: 2 },
  },
  {
    title: <Trans>หน่วยงานที่ขอ</Trans>,
    dataIndex: 'requestOrganizationName',
    width: '10%',
    sorter: { multiple: 3 },
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>วันที่เริ่มต้น</Trans>
      </div>
    ),
    dataIndex: 'dateStart',
    width: '10%',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    sorter: { multiple: 4 },
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>วันที่สิ้นสุด</Trans>
      </div>
    ),
    dataIndex: 'dateEnd',
    width: '10%',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    sorter: { multiple: 5 },
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '10%',
  },
]
