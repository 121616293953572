import { gql } from '@apollo/client'

export const GET_MASTER_CERTIFICATE_TYPE = gql`
  query getMasterCertificateType($input: MasterCertificateTypeInput!) {
    getMasterCertificateType(data: $input) {
      certificateTypeId
      certificateTypeCode
      certificateTypeNameTH
      certificateTypeNameEN
      isActive
    }
  }
`
