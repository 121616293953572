import { gql } from '@apollo/client'

export const CREATE_SYSTEM_USER = gql`
  mutation SystemUserCreate($input: UserFormInput!) {
    systemUserCreate(input: $input) {
      userRoleId
    }
  }
`

export const DELETE_SYSTEM_USER = gql`
  mutation SystemUsersDelete($userId: String!) {
    systemUsersDelete(userId: $userId) {
      userId
    }
  }
`
export const SEND_SYSTEM_USER = gql`
  mutation SystemUsersSend($userId: String!) {
    systemUsersSend(userId: $userId) {
      userId
    }
  }
`

export const UPDATE_UNLOCK_USER = gql`
  mutation systemUserUnlockUser($input: UserUnlockInput!) {
    systemUserUnlockUser(input: $input) {
      userId
    }
  }
`
