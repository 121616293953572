import { Col, Row, Form } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonNew } from '../../../components/button/Button'
import { CardNew } from '../../../components/card/Card'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { InputNew } from '../../../components/input/Input'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectProjectRefer from '../../../components/inputfromapi/selectprojectrefer/SelectProjectRefer'
import SelectProjectStatus from '../../../components/inputfromapi/selectprojectstatus/SelectProjectStatus'
import { LabelNew } from '../../../components/label/Label'

const ProjectStatusReportFilters = ({ getValue = () => {} }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const formName = 'ProjectStatusReportFilters'

  const Responesive = {
    inputFilter: {
      md: 8,
      lg: 8,
    },
  }

  const onFinish = () => {
    const field = form.getFieldValue()

    getValue(field)
  }

  return (
    <CardNew topic={t('Filters')} icon="Filter" toggledrop={'false'}>
      <Row style={{ marginTop: 24 }}>
        <Col sm={24} md={24} lg={24}>
          <Form form={form} onFinish={onFinish} name={formName} layout="vertical">
            <Row gutter={[16, 8]}>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="projectRefCode"
                  rules={[{ required: false, message: 'required' }]}
                  label={<LabelNew type="tab-header-inactive">{t('แหล่งที่มาของโครงการ')}</LabelNew>}
                >
                  <SelectProjectRefer
                    filters={{ isActive: 1 }}
                    formname={formName}
                    placeholder={t('เลือกแหล่งที่มาของโครงการ')}
                    handleChange={(value) => {
                      form.setFieldsValue({ projectRefCode: value ? value.value : null })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="projectName"
                  rules={[{ required: false, message: 'required' }]}
                  label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ')}</LabelNew>}
                >
                  <InputNew placeholder={t('ระบุชื่อโครงการ')} />
                </Form.Item>
              </Col>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="organizationCode"
                  label={<LabelNew type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</LabelNew>}
                >
                  <SelectOrganization
                    formname={formName}
                    placeholder={t('เลือกหน่วยงานที่รับผิดชอบ')}
                    filters={{ organizationChecked: 2, isActive: 1 }}
                    handleChange={(data) => {
                      form.setFieldsValue({ organizationCode: !data ? null : data.value })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="dateStart"
                  rules={[{ required: true, message: t('กรุณาเลือกวันที่เริ่มต้น') }]}
                  label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                >
                  <Datepicker
                    setValueDateFn={(data) => {
                      form.setFieldsValue({
                        dateStart: data,
                      })
                    }}
                    onChange={() => {}}
                    onClear={() => {
                      form.setFieldsValue({ dateStart: null })
                    }}
                    autoComplete="off"
                    placeholder={t('เลือกวันที่เริ่มต้น')}
                  />
                </Form.Item>
              </Col>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="dateEnd"
                  rules={[{ required: true, message: t('กรุณาเลือกวันที่สิ้นสุด') }]}
                  label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                >
                  <Datepicker
                    setValueDateFn={(data) => {
                      form.setFieldsValue({
                        dateEnd: data,
                      })
                    }}
                    onClear={() => {
                      form.setFieldsValue({ dateEnd: null })
                    }}
                    placeholder={t('เลือกวันที่สิ้นสุด')}
                    autoComplete="off"
                  />
                </Form.Item>
              </Col>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="projectStatusCode"
                  label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                >
                  <SelectProjectStatus
                    filters={{ isActive: 1 }}
                    formname={formName}
                    placeholder={t('เลือกสถานะ')}
                    handleChange={(value) => {
                      form.setFieldsValue({ projectStatusCode: value ? value.value : null })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]} justify="center" style={{ marginTop: '2rem' }}>
              <Col>
                <ButtonNew type="search" htmlType="submit">
                  {t('ค้นหา')}
                </ButtonNew>
              </Col>
              <Col>
                <ButtonNew type="clear" onClick={() => form.resetFields()}>
                  {t('ล้างค่า')}
                </ButtonNew>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </CardNew>
  )
}

export default ProjectStatusReportFilters
