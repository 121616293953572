import React, { useState, useEffect } from 'react'
import { Upload, Modal } from 'antd'
import PropTypes from 'prop-types'
import _ from 'lodash'
import ImgCrop from 'antd-img-crop'
import { UploadProfileStyles } from './UploadProfileStyles'
import { ButtonNew } from '../button/Button'
import { colors } from '../../configs/styles.config'
import { Alert } from '../alert/Alert'
import { Trans } from 'react-i18next'
import { decryptData, encryptInput } from '../../utilitys/crypto'
import { SpinnersNew } from '../../components/spinners/Spinners'

export const UploadProfileNew = (props) => {
  const [showIconRemove, setShowIconRemove] = useState('hide-icon-remove')
  const { idFile, typeFile = 'image', otherProps } = props

  const [sizeFilesDynamic, setSizeFilesDynamic] = useState(false)
  const [fileSizeImage, setFileSizeImage] = useState(false)
  const [deleteting, setDeleteting] = useState(false)

  const [stateFilePreview, setStateFilePreview] = useState({
    previewVisible: false,
    previewImage: null,
  })

  const defaultprofile = [
    {
      uid: '-1',
      name: 'default-profile.png',
      status: 'done',
      url: require('../../assets/images/default-profile.png'),
    },
  ]
  const [filesList, setFilesList] = useState(defaultprofile)

  let module = 'profile'
  let user = decryptData(localStorage.getItem('user'))
  let limits = encryptInput(sizeFilesDynamic)
  let size = encryptInput(fileSizeImage)
  let token = decryptData(localStorage.getItem('user'))
  const propsApi = {
    name: 'file',
    action: process.env.REACT_APP_API_UPLOAD + '/uploadImageProfile',
    headers: {
      userId: user.userId,
      module: module,
      limits: limits,
      size: size,
      token: token.access_token,
      // authorization: `Bearer ${token.access_token}`,
    },
    //when the api is celled
    beforeUpload(file) {
      setFileSizeImage(file.size)
      // ไฟล์รูปเกิน ขนาด 2 Mb
      if (formatSizeMb(file.size) > sizeFilesDynamic) {
        file.status = 'error'
        file.response = `เกินขนาดไฟล์ ${sizeFilesDynamic}Mb` // เกินขนาดไฟล์ 2Mb
        setFilesList([file, ...filesList])
        Alert({
          type: 'error',
          title: `ไฟล์นามสกุล .jpeg, .jpg, .png, .gif เกินขนาด ${sizeFilesDynamic}Mb`,
          onOk() {
            deleteFileError()
          },
        })
        return false
      }
      return true
    },
    onChange(info) {
      let fileList2 = [...info.fileList]

      // move data
      let arrayFileId = []
      fileList2 = fileList2.map((file, index) => {
        if (file.response) {
          if (file.response.name) file.name = file.response.name
          if (file.response.originalname) file.originalname = file.response.originalname
          if (file.response.url) file.url = file.response.url
          if (file.response.fileId) file.uid = file.response.fileId

          // จัดข้อมูลให้ fileId ทีมเอาไปใช้ต่อ
          arrayFileId[index] = file.response.name
        }
        return file
      })
      if (!_.isEmpty(fileList2)) {
        props.setIdFile(arrayFileId)
        setFilesList([_.last(fileList2)])
        setShowIconRemove('show-icon-remove')
      }
    },
    // set data in component upload ant
    fileList: filesList,
    onDownload: (file) => {
      window.open(process.env.REACT_APP_API_UPLOAD + '/fileImageProfilePreview/' + file.name)
    },
    onRemove: (file) => {
      if (file.status !== 'error') {
        setDeleteting(true)
        deleteFile(file.name)
      }
      //  delete data in array
      const index = filesList.indexOf(file)
      const newFileList = filesList.slice()
      newFileList.splice(index, 1)
      //-------------------

      // delete index idFile
      const newIdFile = idFile.slice()
      newIdFile.splice(index, 1)

      props.setIdFile(newIdFile)
      // ---------------------
      setFilesList(defaultprofile)
      setShowIconRemove('hide-icon-remove')
    },
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }

      setStateFilePreview({
        previewImage: file.url || file.preview,
        previewVisible: true,
        previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
      })
    },
    progress: {
      strokeColor: {
        '0%': colors.primary,
        '100%': colors.success,
      },
      strokeWidth: 3,
      format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
    },
  }

  //function get file all
  const getFile = (filename) => {
    if (!_.isEmpty(filename)) {
      fetch(process.env.REACT_APP_API_UPLOAD + `/fileImageProfileData`, {
        method: 'GET',
        headers: {
          filename: filename,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (componentMounted) {
            if (!_.isEmpty(res.files)) {
              const _files = res.files.map((item) => {
                return {
                  ...item,
                  url: process.env.REACT_APP_API_UPLOAD + item.url,
                }
              })

              setFilesList(_files)
              setShowIconRemove('show-icon-remove')
            } else {
              setFilesList(defaultprofile)
              setShowIconRemove('hide-icon-remove')
            }
          }
        })
        .catch((err) => {
          console.log('err: ', err)
        })
    }
  }

  //function get file all
  const getSizeFile = (typeFile) => {
    if (!_.isEmpty(typeFile)) {
      fetch(process.env.REACT_APP_API_UPLOAD + `/sizeFiles`, {
        method: 'GET',
        headers: {
          typeFile: typeFile,
        },
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.sizeFiles) {
            setSizeFilesDynamic(res.sizeFiles)
          }
        })
        .catch((err) => {
          console.log('err: ', err)
        })
    }
  }

  // function delete file
  const deleteFile = (fileName) => {
    fetch(process.env.REACT_APP_API_UPLOAD + `/deleteFileImageProfile/${fileName}`, {
      method: 'GET',
    })
      .then(() => {
        setDeleteting(false)
      })
      .catch((err) => {
        console.log('err: ', err)
        setDeleteting(false)
      })
  }

  const formatSizeMb = (bytes) => {
    return (bytes / 1048576).toFixed(2)
  }

  const deleteFileError = () => {
    const data = filesList.filter((item) => item.status !== 'error')
    const newIdFile = data.filter((item) => item.uid !== '-1')
    props.setIdFile(newIdFile)
    setFilesList(data)
    setShowIconRemove('hide-icon-remove')
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handleCancel = () => setStateFilePreview({ previewVisible: false })

  useEffect(() => {
    getSizeFile(typeFile)
  }, [])

  const [componentMounted, setcomponentMounted] = useState(true)

  useEffect(() => {
    setcomponentMounted(true)
    if (idFile && !_.isEmpty(idFile) && componentMounted) {
      getFile(idFile)
    }
    return () => {
      setcomponentMounted(false)
    }
  }, [idFile])

  return (
    <>
      {deleteting && <SpinnersNew />}
      <UploadProfileStyles>
        <center>
          <ImgCrop
            rotate
            quality={1}
            modalTitle={<Trans>แก้ไขรูปภาพ</Trans>}
            modalOk={<Trans>ตกลง</Trans>}
            modalCancel={<Trans>ยกเลิก</Trans>}
          >
            <Upload
              {...propsApi}
              {...otherProps}
              className={`upload-show ${showIconRemove}`}
              listType="picture-card"
              accept={'.jpg,.jpeg,.gif,.png'}
              showUploadList={{
                showDownloadIcon: false,
              }}
            >
              <div>
                <ButtonNew
                  type="upload"
                  iconname="Upload"
                  disabled={filesList.filter((item) => item.name !== 'default-profile.png').length > 0}
                >
                  <Trans>อัพโหลดรูป</Trans>
                </ButtonNew>
              </div>
            </Upload>
          </ImgCrop>
        </center>

        <Modal
          visible={stateFilePreview.previewVisible}
          title={<Trans>ดูรูปภาพ</Trans>}
          footer={null}
          maskClosable={false}
          width={300}
          onCancel={handleCancel}
        >
          <img alt="" style={{ maxWidth: '100%' }} src={stateFilePreview.previewImage} />
        </Modal>
      </UploadProfileStyles>
    </>
  )
}

UploadProfileNew.propTypes = {
  idFile: PropTypes.array.isRequired,
  setIdFile: PropTypes.func.isRequired,
}
