import {} from 'react'
import { useSubscription } from '@apollo/client'
import { NOTI_SUB } from './graphql/Query'

export const SubscriptionData = (personnelCode, userId) => {
  if (personnelCode && userId) {
    // console.log('personnelCode: ', personnelCode)
    // console.log('userId: ', userId)
    const { data, error } = useSubscription(NOTI_SUB, {
      variables: {
        personnelCode: personnelCode,
        userId: userId,
      },
    })

    if (error) {
      console.log('errorSubNoti: ', error)
    }

    if (data) {
      return data
    }
  }
}
