import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: <Trans>{'ประจำปี'}</Trans>,
    dataIndex: 'formYear',
    width: '8%',
    sorter: { multiple: 1 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>{'รหัส'}</Trans>,
    dataIndex: 'formCode',
    width: '10%',
    sorter: { multiple: 2 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>{'ชื่อแบบประเมิน'}</Trans>,
    dataIndex: 'formName',
    width: '20%',
    sorter: { multiple: 3 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>{'วันที่เริ่มต้นประเมิน'}</Trans>,
    dataIndex: 'startDate',
    width: '15%',
    sorter: { multiple: 4 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>{'วันที่สิ้นสุดประเมิน'}</Trans>,
    dataIndex: 'endDate',
    width: '15%',
    sorter: { multiple: 5 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>{'สถานะ'}</Trans>,
    dataIndex: 'status',
    width: '10%',
    className: 'text-nowrap',
    align: 'center',
    sorter: { multiple: 6 },
  },
  {
    title: '',
    dataIndex: 'operationStatusCode',
    width: '15%',
    className: 'text-nowrap',
    align: 'center',
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '15%',
    className: 'text-nowrap',
  },
]
