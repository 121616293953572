import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import { encryptInput } from '../../../../../utilitys/crypto'

const GET_ORGANIZATION_IN_BRANCH = gql`
  query getOrganizationsInBranch($input: GetBranchOrganizationInput!) {
    getOrganizationsInBranch(input: $input) {
      organizationCode
    }
  }
`

export const useGetOrganizationsInBranch = (organizationCode) => {
  const [data, setData] = useState(null)
  const [getOrganizationsInBranchFn, getOrganizationsInBranch] = useLazyQuery(GET_ORGANIZATION_IN_BRANCH)

  useEffect(() => {
    getOrganizationsInBranchFn({
      variables: { input: encryptInput({ organizationCode: organizationCode ? organizationCode : null }) },
    })
  }, [organizationCode])

  useEffect(() => {
    if (getOrganizationsInBranch?.data) setData(getOrganizationsInBranch?.data?.getOrganizationsInBranch)
  }, [getOrganizationsInBranch?.data])

  const refresh = () => getOrganizationsInBranch.refetch()

  return [data, getOrganizationsInBranch?.loading, refresh]
}
