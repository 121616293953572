import { gql } from '@apollo/client'

export const GET_OVERVIEW_ISSUE_PAGINATION = gql`
  query getOverviewIssuePagination($input: OverviewIssueFilterInput!) {
    getOverviewIssuePagination(input: $input) {
      result {
        projectCode
        projectNameTH
        projectNameEN
        dateStart
        dateEnd
        countOrganizations
        countIssue
        countIssue60
        countIssueNot60
      }
      summary {
        summaryProject
        summaryIssue
        countIssue60
        countIssueNot60
      }
      count
      page
      limit
    }
  }
`
