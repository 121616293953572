import { Trans } from 'react-i18next'
import { Form } from 'antd'
import { useTranslation } from 'react-i18next'

import { InputNew } from '../../../../components/input/Input'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
// import SelectDateType from '../components/select/SelectDateType'
import { Datepicker } from '../../../../components/datepicker/Datepicker'
import { Select } from '../../../../components/select/Select'
// import { DatePicker } from "antd";

export const columnCalendarList = [
  {
    title: <Trans>ประจำปี</Trans>,
    dataIndex: 'calendarYear',
    sorter: true,
    width: '10%',
    className: 'text-nowrap'
  },
  {
    title: <Trans>ชื่อข้อมูลปฏิทิน</Trans>,
    dataIndex: 'calendarName',
    sorter: true,
    width: '50%',
    className: 'text-nowrap'
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActive',
    sorter: true,
    width: '20%',
    className: 'text-nowrap'
  },
  {
    title: '',
    dataIndex: 'options',
    // sorter: true,
    width: '20%',
    className: 'text-nowrap'
  }
]

export const Columns = ({ editable = false, fncDelete = () => {}, menu, formname, form }) => {
  const { t } = useTranslation()
  const itemDatetype = [
    { label: t('วันหยุดประจำปี'), value: 1 },
    { label: t('วันประชุม AC'), value: 2 },
    { label: t('วันที่ประชุม MC'), value: 3 }
  ]
  return [
    {
      title: t('วันหยุด/วันประชุมภาษาไทย'),
      dataIndex: 'dateNameTH',
      width: '25%',
      render: (item, record, index) => (
        <Form.Item
          name={['calendarDetails', index, 'dateNameTH']}
          initialValue={item}
          wrapperCol={{
            md: 24
          }}
          rules={[
            { required: true, message: t('กรุณากรอกวันหยุด/วันประชุมภาษาไทย') }
            // { pattern: regexEngNoSpace, message: t(`กรุณากรอกด้วยภาษาอังกฤษพิมพ์ใหญ่,ตัวเลข A-Z, 0-9 ไม่เกิน 10 ต้ว`) },
          ]}
        >
          <InputNew placeholder={t('กรอกวันหยุด/วันประชุมภาษาไทย')} disabled={!editable} maxLength={100} />
        </Form.Item>
      )
    },
    {
      title: t('วันหยุด/วันประชุมภาษาอังกฤษ'),
      dataIndex: 'dateNameEN',
      width: '25%',
      render: (item, record, index) => (
        <Form.Item
          name={['calendarDetails', index, 'dateNameEN']}
          initialValue={item}
          wrapperCol={{
            md: 24
          }}
        >
          <InputNew placeholder={t('กรอกวันหยุด/วันประชุมภาษาอังกฤษ')} disabled={!editable} maxLength={100} />
        </Form.Item>
      )
    },
    {
      title: t('วันที่'),
      dataIndex: 'dateAt',
      width: '26%',
      render: (item, record, index) => {
        return (
          <>
            <Form.Item
              name={['calendarDetails', index, 'dateAt']}
              initialValue={item}
              wrapperCol={{
                md: 24
              }}
              rules={[{ required: true, message: t('กรุณาระบุ') }]}
            >
              <Datepicker
                formname={formname}
                menu={menu}
                disabled={!editable}
                setValueDateFn={(data) => {
                  if (data) {
                    const fields = form.getFieldsValue()
                    const { calendarDetails } = fields
                    Object.assign(calendarDetails[index], { dateAt: data })
                    form.setFieldsValue({ calendarDetails })
                  }
                }}
                onClear={() => {
                  const fields = form.getFieldsValue()
                  const { calendarDetails } = fields
                  Object.assign(calendarDetails[index], { dateAt: null })
                  form.setFieldsValue({ calendarDetails })
                }}
              />
            </Form.Item>
          </>
        )
      }
    },
    {
      title: t('ประเภทวัน'),
      dataIndex: 'dateType',
      width: '16%',
      render: (item, record, index) => (
        <>
          <Form.Item
            name={['calendarDetails', index, 'dateType']}
            initialValue={item}
            wrapperCol={{
              md: 24
            }}
          >
            <Select
              data={itemDatetype}
              formname={formname}
              placeholder={t('กรอกประเภทวัน')}
              scrollableId={formname}
              disabled={!editable}
            />
          </Form.Item>
        </>
      )
    },
    {
      title: '',
      dataIndex: 'options',
      width: '8%',
      render: (item, record, index) => {
        const icons = []

        if (editable) {
          icons.push({
            type: 'delete',
            onClick: () => {
              fncDelete(index)
            }
          })
        }
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} size={14} icons={icons} />
          </div>
        )
      }
    }
  ]
}

export const ColumnsImport = () => {
  const { t } = useTranslation()
  return [
    {
      title: t('ลำดับ'),
      dataIndex: 'numRow',
      width: '50px'
    },
    {
      title: t('รหัสปฏิทิน'),
      dataIndex: 'calendarCode',
      width: '100px'
    },
    {
      title: t('ปี'),
      dataIndex: 'calendarYear',
      width: '100px'
    },
    {
      title: t('ชื่อปฏิทินภาษาไทย'),
      dataIndex: 'calendarNameTH',
      width: '200px'
    },
    {
      title: t('ชื่อปฏิทินภาษาอังกฤษ'),
      dataIndex: 'calendarNameEN',
      width: '200px'
    },
    // {
    //   title: t('ประเภทวัน'),
    //   dataIndex: 'dateType',
    //   width: '100px',
    // },
    {
      title: t('ชื่อประเภทวัน'),
      dataIndex: 'dateTypeName',
      width: '150px'
    },
    {
      title: t('ชื่อวันภาษาไทย'),
      dataIndex: 'dateNameTH',
      width: '200px'
    },
    {
      title: t('ชื่อวันภาษาอังกฤษ'),
      dataIndex: 'dateNameEN',
      width: '200px'
    },
    {
      title: t('วันที่'),
      dataIndex: 'dateAt',
      width: '150px'
    }
  ]
}

{
  /* <SelectDateType
              placeholder={t('กรอกประเภทวัน')}
              disabled={!editable}
              menu={menu}
              formname={formname}
              scrollableId={formname}
              onChange={(e) => {
                const fields = form.getFieldsValue()
                const { calendarDetails } = fields
                const res = e.sort.find((i) => i.value === e.value)
                Object.assign(calendarDetails[index], { dateType: res.dateType })
                form.setFieldsValue({ calendarDetails })
              }}
            /> */
}
