import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Table } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../../../components/breadcrumb/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { useLocation, Navigate } from 'react-router-dom'
import { CardNew as Card } from '../../../../../components/card/Card'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { InputNew as Input } from '../../../../../components/input/Input'
import SelectTagEmailPersonnels from '../../../../../components/inputfromapi/selecttagemailpersonnels/SelectTagEmailPersonnels'
import { TextAreaNew as TextArea } from '../../../../../components/textarea/TextArea'
import { DATA_AUDIT_OPENS_DOC_PETTITION } from '../../graphql/Query'
import { AUDITOPENS_SENDMAIL } from '../../graphql/Mutation'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Alert } from '../../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText, getSegmentUrl } from '../../../../../utilitys/helper'
import { decryptData } from '../../../../../utilitys/crypto'
import { downloadFile } from '../../../../../utilitys/files'
import { IconNew as Icon } from '../../../../../components/icon/Icon'
import { colors } from '../../../../../configs/styles.config'
import { ButtonNew as Button } from '../../../../../components/button/Button'
import { useNavigate } from 'react-router-dom'
import { displayError } from '../../../../../utilitys/helper'

const AuditOpensAdddocPetition = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const breadcrumbList = [{ label: t('งานปฏิบัติการตรวจสอบ') }, { label: t('บันทึกเปิดตรวจ') }]
  const { state } = useLocation()
  if (!state) return <Navigate to={'/auditoperations/auditopens'} />
  const type = getSegmentUrl(4)

  const [form] = Form.useForm()
  const [download, setDownload] = useState(false)
  const [files, setFiles] = useState([])
  const [fileId, setFileId] = useState('')
  const [openId, setOpenId] = useState(null)
  const showAlertSuccess = () => {
    Alert({
      type: 'success',
      title: t('ส่งอีเมลสำเร็จ'),
      onOk() {
        navigate('/auditoperations/auditopens/adddoc/' + type, {
          state: { refCode: openId },
        })
      },
    })
  }
  const [auditOpensSendMailFn, auditOpensSendMail] = useMutation(AUDITOPENS_SENDMAIL)
  useEffect(() => {
    if (auditOpensSendMail?.data) {
      let response = auditOpensSendMail?.data?.auditOpensSendMail
      if (response) {
        showAlertSuccess()
      }
    }
    if (auditOpensSendMail.error) {
      showAlertSentMailError(displayError(auditOpensSendMail.error))
    }
  }, [auditOpensSendMail.data])
  const [dataAuditOpensDocPetitionFn, dataAuditOpensDocPetition] = useLazyQuery(DATA_AUDIT_OPENS_DOC_PETTITION)
  useEffect(() => {
    if (dataAuditOpensDocPetition.data) {
      let response = dataAuditOpensDocPetition?.data?.dataAuditOpensDocPetition
      if (response) {
        setOpenId(response.openId)
        setFileId(response.fileSend.idFile)
        setFiles(
          response.fileSend.refFile.map((item, i) => {
            return {
              key: i,
              docName: (
                <a
                  href={void 0}
                  onClick={() => {
                    downloadFile(item.originalname, item.name, () => {
                      setDownload(false)
                    })
                  }}
                >
                  {item.originalname}
                </a>
              ),
              file: (
                <>
                  <center>
                    <Icon
                      onClick={() => {
                        downloadFile(item.originalname, item.name, () => {
                          setDownload(false)
                        })
                      }}
                      icon={'FileText'}
                      size={28}
                      color={colors.link}
                      className="approve-downloads"
                    />
                  </center>
                </>
              ),
            }
          }),
        )
        form.setFieldsValue({
          openNumber: response.openNumber,
          openSubject: displayText(response.openSubjectTH, response.openSubjectEN),
          emailTo: [
            ...new Set(
              response.sendTo
                .filter((item) => {
                  return decryptData(item.email)
                })
                .map((item) => {
                  return JSON.stringify({ personnelCode: item.personnelCode, email: decryptData(item.email) })
                })
                .filter((item) => {
                  return item
                }),
            ),
          ],
          emailCC: [
            ...new Set(
              response.sendToCopy
                .filter((item) => {
                  return decryptData(item.email)
                })
                .map((item) => {
                  return JSON.stringify({ personnelCode: item.personnelCode, email: decryptData(item.email) })
                })
                .filter((item) => {
                  return item
                }),
            ),
          ],
        })
      }
    }
    if (dataAuditOpensDocPetition.error) {
      showAlertLoadDataError(displayError(dataAuditOpensDocPetition.error))
    }
  }, [dataAuditOpensDocPetition.data])
  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showAlertSentMailError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถส่งอีเมลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showConfirm = () => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการส่งอีเมล ?'),
      onOk() {
        form.submit()
      },
      onCancel() {},
    })
  }
  useEffect(() => {
    dataAuditOpensDocPetitionFn({
      fetchPolicy: 'no-cache',
      variables: {
        openDocId: encryptInput(state.refCode),
      },
    })
  }, [state.refCode])
  const onFinish = (values) => {
    const input = {
      openId: openId,
      fileId: fileId ? fileId : '',
      openNumber: values.openNumber,
      openSubject: values.openSubject,
      emailTo: values.emailTo
        ? values.emailTo.map((item) => {
            try {
              return JSON.parse(item)
            } catch (e) {
              return { personnelCode: null, email: item }
            }
          })
        : [],
      emailCC: values.emailCC
        ? values.emailCC.map((item) => {
            try {
              return JSON.parse(item)
            } catch (e) {
              return { personnelCode: null, email: item }
            }
          })
        : [],
      emailBCC: values.emailBCC
        ? values.emailBCC.map((item) => {
            try {
              return JSON.parse(item)
            } catch (e) {
              return { personnelCode: null, email: item }
            }
          })
        : [],
      messageMail: values.messageMail ? values.messageMail : '',
    }
    auditOpensSendMailFn({
      variables: {
        input: encryptInput(input),
      },
      fetchPolicy: 'no-cache',
    })
  }
  return (
    <>
      {(dataAuditOpensDocPetition.loading || auditOpensSendMail.loading || download) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Breadcrumb data={breadcrumbList} title={t('บันทึกเปิดตรวจ')} menu={props.menu} />
          <Card topic={t('แจ้งขอเอกสาร')} icon="AlignJustify" toggledrop="false">
            <Form
              form={form}
              autoComplete="off"
              labelAlign="left"
              layout="horizontal"
              name="auditOpensPetition"
              onFinish={onFinish}
              labelCol={{
                sm: 24,
                md: 4,
              }}
              wrapperCol={{
                sm: 24,
                md: 20,
              }}
            >
              <Label type="body-header">{t('รายละเอียดการเปิดตรวจ')}</Label>
              <Row justify="center">
                <Col md={18}>
                  <Form.Item
                    label={<Label type="tab-header-inactive">{t('เลขที่หนังสือเปิดตรวจ')}</Label>}
                    name="openNumber"
                    rules={[{ required: true, message: t('กรุณาระบุเลขที่หนังสือเปิดตรวจ') }]}
                  >
                    <Input placeholder={t('ระบุเลขที่หนังสือเปิดตรวจ')} disabled={true} />
                  </Form.Item>
                  <Form.Item
                    label={<Label type="tab-header-inactive">{t('เรื่อง')}</Label>}
                    name="openSubject"
                    rules={[{ required: true, message: t('กรุณาระบุเรื่อง') }]}
                  >
                    <Input placeholder={t('ระบุเรื่อง')} disabled={true} />
                  </Form.Item>
                  <Form.Item
                    name="emailTo"
                    label={<Label type="tab-header-inactive">{t('ถึง')}</Label>}
                    rules={[{ required: true, message: t('กรุณาระบุถึง') }]}
                  >
                    <SelectTagEmailPersonnels
                      formname="auditOpensPetition"
                      placeholder={t('เลือกถึง')}
                      handleChange={(e) => {
                        form.setFieldsValue({ emailTo: e })
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="emailCC" label={<Label type="tab-header-inactive">{t('สำเนา')}</Label>}>
                    <SelectTagEmailPersonnels
                      formname="auditOpensPetition"
                      placeholder={t('เลือกสำเนา')}
                      handleChange={(e) => {
                        form.setFieldsValue({ emailCC: e })
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="emailBCC" label={<Label type="tab-header-inactive">{t('สำเนาลับ')}</Label>}>
                    <SelectTagEmailPersonnels
                      formname="auditOpensPetition"
                      placeholder={t('เลือกสำเนาลับ')}
                      handleChange={(e) => {
                        form.setFieldsValue({ emailBCC: e })
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<Label type="tab-header-inactive">{t('รายละเอียด')}</Label>}
                    name="messageMail"
                    rules={[{ required: true, message: t('กรุณาระบุรายละเอียด') }]}
                  >
                    <TextArea placeholder={t('ระบุรายละเอียด')} />
                  </Form.Item>
                </Col>
              </Row>
              <Label type="body-header">{t('สิ่งที่แนบมาด้วย')}</Label>
              <Row justify="center">
                <Col md={24}>
                  <Table
                    pagination={false}
                    columns={[
                      {
                        title: <div style={{ textAlign: 'center' }}>{t('ชื่อเอกสาร')}</div>,
                        dataIndex: 'docName',
                        width: '50%',
                      },
                      {
                        title: <div style={{ textAlign: 'center' }}>{t('แนบเอกสาร')}</div>,
                        dataIndex: 'file',
                        width: '50%',
                      },
                    ]}
                    dataSource={files}
                  />
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center" style={{ marginTop: '50px' }}>
                <Col>
                  <Button
                    type="primary"
                    roles={{ type: 'send', menu: props.menu }}
                    onClick={() => {
                      showConfirm()
                    }}
                    htmlType="button"
                  >
                    {t('ส่งอีเมล')}
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="cancel"
                    onClick={() => {
                      navigate(`/auditoperations/auditopens/adddoc/${type}`, {
                        state: { refCode: openId },
                      })
                    }}
                  >
                    {t('ยกเลิก')}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default AuditOpensAdddocPetition
