import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'

import { ButtonNew } from '../../../../../components/button/Button'
import { CardNew } from '../../../../../components/card/Card.jsx'
import { LabelNew } from '../../../../../components/label/Label'
import { BreadcrumbNew as Breadcrumb } from '../../../../../components/breadcrumb/Breadcrumb'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'
import { InputNew } from '../../../../../components/input/Input'
import { Alert } from '../../../../../components/alert/Alert'

import SelectRateRiskRactorProcessByAuditRCM from '../../../../../components/inputfromapi/selectrateriskfactorprocessbyauditrcm/SelectRateRiskRactorProcessByAuditRCM'
import SelectAuditIssues from '../../../../../components/inputfromapi/selectauditIssues/SelectAuditIssues'
import SelectAuditSuggestOfIssues from '../../../../../components/inputfromapi/selectauditsuggestofIssues/SelectAuditSuggestOfIssues'
import SelectAuditSuggestOrganizations from '../../../../../components/inputfromapi/selectauditsuggestorganizations/SelectAuditSuggestOrganizations'
import FormFollowUpAuditActivityTabFormMultiRow from './FormFollowUpAuditActivityTabFormMultiRow'

import { displayText } from '../../../../../utilitys/helper'
import { displayError } from '../../../../../utilitys/helper'
import { getSegmentUrl } from '../../../../../utilitys/helper'
import { encryptInput } from '../../../../../utilitys/crypto'

import { DATA_FOLLOW_UP_ACTIVITY_TAB } from '../../graphql/Query'
import { FOLLOW_UP_ACTIVITY_SAVE } from '../../graphql/Mutation'

import { SpinnersNew } from '../../../../../components/spinners/Spinners'

const FormFollowUpAuditActivityTabForm = (props) => {
  const { state } = useLocation()
  if (!state) return <Navigate to={'/followup/followupaudit/'} />

  const { t } = useTranslation()
  const navigate = useNavigate()
  const typeMain = getSegmentUrl(4)
  const type = getSegmentUrl(6)
  const [form] = Form.useForm()

  const [rateFacProCode, setRateFacProCode] = useState(null)
  const [issueId, setIssueId] = useState(null)
  const [suggestId, setSuggestId] = useState(null)
  const [projectCode, setProjectCode] = useState(null)

  const [filesEdit, setFilesEdit] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])

  const breadcrumbList = [
    { label: t('งานติดตามผลการตรวจสอบ'), path: '/followup/followupaudit' },
    { label: t('ติดตามผลการตรวจสอบ'), path: '/followup/followupaudit' },
    { label: t('กิจกรรมการตรวจสอบ') },
  ]

  const setErrorField = (name, errors) => {
    // Set field errors
    return form.setFields([{ name: name, errors: errors ? [errors] : false }])
  }

  const showConfirm = () => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        form.submit()
      },
      onCancel() {},
    })
  }

  const showAlertSaveError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showAlertSuccess = (activityId) => {
    Alert({
      type: 'success',
      title: t('บันทึกสำเร็จ'),
      onOk() {
        if (type === 'add') {
          navigate('/followup/followupaudit/activity/' + typeMain + '/form/edit', {
            state: { refCode: activityId },
          })
        }
      },
    })
  }

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const [dataFollowUpActivityTabFn, dataFollowUpActivityTab] = useLazyQuery(DATA_FOLLOW_UP_ACTIVITY_TAB)

  useEffect(() => {
    if (dataFollowUpActivityTab?.data) {
      let response = dataFollowUpActivityTab?.data?.dataFollowUpActivityTab

      if (response) {
        setProjectCode(response.projectCode)
        setRateFacProCode(response.rateFacProCode)
        setIssueId(response.issueId)
        setSuggestId(response.suggestId)

        form.setFieldsValue({
          activity: displayText(response.activityTH, response.activityEN),
          expectDate: response.expectDate,
          issueId: response.issueId,
          rateRiskRuleCode: response.rateRiskRuleCode,
          rateRiskConCode: response.rateRiskConCode,
          organizationCode: response.organizationCode,
          organizationNameTH: response.organizationNameTH,
          organizationNameEN: response.organizationNameEN,
          personnel: displayText(response.personnelNameTH, response.personnelNameEN),
          personnelCode: response.personnelCode,
          rateFacProCode: response.rateFacProCode,
          remark: displayText(response.remarkTH, response.remarkEN),
          suggestId: response.suggestId,
        })

        const fileDetail = JSON.parse(response.fileDetails)
          ? JSON.parse(response.fileDetails).map((item) => {
              let fileDetail2 = response.refFile.find((subItem) => {
                return item.fileId === subItem.fileId
              })
              return {
                ...item,
                originalname: fileDetail2 ? fileDetail2.originalname : '',
                name: fileDetail2 ? fileDetail2.name : '',
              }
            })
          : []

        setFilesEdit(fileDetail)
      }
    }
    if (dataFollowUpActivityTab.error) {
      showAlertLoadDataError(displayError(dataFollowUpActivityTab.error))
    }
  }, [dataFollowUpActivityTab.data])

  const [followUpActivityTabSaveFn, followUpActivityTabSave] = useMutation(FOLLOW_UP_ACTIVITY_SAVE)

  useEffect(() => {
    if (followUpActivityTabSave?.data) {
      let response = followUpActivityTabSave?.data?.followUpActivityTabSave
      if (response) {
        showAlertSuccess(response.activityId)
      }
    }
    if (followUpActivityTabSave.error) {
      showAlertSaveError(displayError(followUpActivityTabSave.error))
    }
  }, [followUpActivityTabSave.data])

  const onFinish = (values) => {
    const {
      activity: activityV,
      expectDate: expectDateV,
      issueId: issueIdV,
      rateRiskRuleCode: rateRiskRuleCodeV,
      rateRiskConCode: rateRiskConCodeV,
      organizationCode: organizationCodeV,
      organizationNameTH: organizationNameTHV,
      organizationNameEN: organizationNameENV,
      personnel: personnelV,
      personnelCode: personnelCodeV,
      rateFacProCode: rateFacProCodeV,
      remark: remarkV,
      suggestId: suggestIdV,
    } = values

    delete values['activity']
    delete values['expectDate']
    delete values['issueId']
    delete values['rateRiskRuleCode']
    delete values['rateRiskConCode']
    delete values['organizationCode']
    delete values['organizationNameTH']
    delete values['organizationNameEN']
    delete values['personnel']
    delete values['personnelCode']
    delete values['rateFacProCode']
    delete values['remark']
    delete values['suggestId']

    let fileInfor = Object.values(values)
    let files = fileInfor
      .filter((item) => item.fileId !== null)
      .map((item) => item.fileId)
      .join()
    let fileDetail = JSON.stringify(fileInfor)

    const input = {
      projectCode: projectCode,
      activity: activityV ? activityV : null,
      expectDate: expectDateV,
      issueId: issueIdV,
      rateRiskRuleCode: rateRiskRuleCodeV,
      rateRiskConCode: rateRiskConCodeV,
      organizationCode: organizationCodeV ? organizationCodeV : null,
      organizationNameTH: organizationNameTHV ? organizationNameTHV : null,
      organizationNameEN: organizationNameENV ? organizationNameENV : null,
      personnel: personnelV,
      personnelCode: personnelCodeV,
      rateFacProCode: rateFacProCodeV,
      remark: remarkV ? remarkV : null,
      suggestId: suggestIdV,
      files: files,
      fileDetail: fileDetail,
      idFileDelete: idFileDelete.join(),
      lang: localStorage.getItem('lang'),
      typeAction: type,
    }

    if (type !== 'add') {
      input['activityId'] = state.refCode
    }

    followUpActivityTabSaveFn({ variables: { input: encryptInput(input) }, fetchPolicy: 'no-cache' })
  }

  useEffect(() => {
    if (type !== 'add') {
      dataFollowUpActivityTabFn({ variables: { activityId: encryptInput(state.refCode) }, fetchPolicy: 'no-cache' })
    } else {
      setProjectCode(state.refCode)
    }
  }, [type])

  return (
    <>
      {(dataFollowUpActivityTab.loading || followUpActivityTabSave.loading) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('กิจกรรมการตรวจสอบ')} menu={props.menu} />
      <Row>
        <Col md={24}>
          <CardNew
            topic={t('กิจกรรมตรวจสอบ')}
            icon={type === 'add' ? 'Plus' : type === 'edit' ? 'Edit2' : 'FileText'}
            toggledrop="false"
          >
            <Form
              form={form}
              autoComplete="off"
              labelAlign="left"
              labelCol={{ sm: 24, md: 8 }}
              wrapperCol={{ sm: 24, md: 16 }}
              layout="horizontal"
              name="activityForm"
              onFinish={onFinish}
            >
              <LabelNew type="body-header">{t('กิจกรรมตรวจสอบ')}</LabelNew>
              <Row justify="center">
                <Col md={18}>
                  <Form.Item
                    name={'rateFacProCode'}
                    label={<LabelNew type="tab-header-inactive">{t('ปัจจัย/ความเสี่ยง')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุปัจจัย/ความเสี่ยง') }]}
                  >
                    <SelectRateRiskRactorProcessByAuditRCM
                      filters={{ isActive: 1, force: true, projectCode: projectCode, approveStatusCode: 20 }}
                      formname="activityForm"
                      placeholder={t('เลือกปัจจัย/ความเสี่ยง')}
                      disabled={type === 'view' ? true : false}
                      handleChange={(value) => {
                        form.setFieldsValue({
                          rateFacProCode: value ? value.value : null,
                          issueId: null,
                          rateRiskRuleCode: null,
                          rateRiskConCode: null,
                          suggestId: null,
                          organizationCode: null,
                          organizationNameTH: null,
                          organizationNameEN: null,
                          personnelCode: null,
                          personnel: null,
                        })
                        setRateFacProCode(value ? value.value : null)
                        if (value) {
                          setErrorField('rateFacProCode', false)
                        } else {
                          setErrorField('rateFacProCode', t('กรุณาเลือกปัจจัย/ความเสี่ยง'))
                        }
                        setIssueId(null)
                        setErrorField('issueId', t('กรุณาเลือกประเด็น/ข้อสังเกตุ'))
                        setSuggestId(null)
                        setErrorField('suggestId', t('กรุณาเลือกข้อเสนอแนะ'))
                      }}
                      onClear={() => {
                        form.setFieldsValue({
                          rateFacProCode: null,
                          issueId: null,
                          rateRiskRuleCode: null,
                          rateRiskConCode: null,
                          suggestId: null,
                          organizationCode: null,
                          organizationNameTH: null,
                          organizationNameEN: null,
                          personnelCode: null,
                          personnel: null,
                        })
                        setRateFacProCode(null)
                        setErrorField('rateFacProCode', t('กรุณาเลือกปัจจัย/ความเสี่ยง'))
                        setIssueId(null)
                        setErrorField('issueId', t('กรุณาเลือกประเด็น/ข้อสังเกตุ'))
                        setSuggestId(null)
                        setErrorField('suggestId', t('กรุณาเลือกข้อเสนอแนะ'))
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="rateRiskRuleCode" hidden={true}>
                    <InputNew />
                  </Form.Item>
                  <Form.Item name="rateRiskConCode" hidden={true}>
                    <InputNew />
                  </Form.Item>
                  <Form.Item
                    name="issueId"
                    label={<LabelNew type="tab-header-inactive">{t('ประเด็น/ข้อสังเกตุ')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุประเด็น/ข้อสังเกตุ') }]}
                  >
                    <SelectAuditIssues
                      filters={{
                        projectCode: projectCode,
                        rateFacProCode: rateFacProCode,
                        force: true,
                        issueType: 2,
                      }}
                      disabled={type === 'view' ? true : false}
                      formname="activityForm"
                      placeholder={t('เลือกประเด็น/ข้อสังเกตุ')}
                      handleChange={(value) => {
                        form.setFieldsValue({
                          issueId: value ? value.value : null,
                          rateRiskRuleCode: value ? value.source.rateRiskRuleCode : null,
                          rateRiskConCode: value ? value.source.rateRiskConCode : null,
                          suggestId: null,
                          organizationCode: null,
                          organizationNameTH: null,
                          organizationNameEN: null,
                          personnelCode: null,
                          personnel: null,
                        })
                        setIssueId(value ? value.value : null)
                        if (value) {
                          setErrorField('issueId', false)
                        } else {
                          setErrorField('issueId', t('กรุณาเลือกประเด็น/ข้อสังเกตุ'))
                        }
                        setSuggestId(null)
                        setErrorField('suggestId', t('กรุณาเลือกข้อเสนอแนะ'))
                      }}
                      onClear={() => {
                        form.setFieldsValue({
                          issueId: null,
                          rateRiskRuleCode: null,
                          rateRiskConCode: null,
                          suggestId: null,
                          organizationCode: null,
                          organizationNameTH: null,
                          organizationNameEN: null,
                          personnelCode: null,
                          personnel: null,
                        })
                        setIssueId(null)
                        setErrorField('issueId', t('กรุณาเลือกประเด็น/ข้อสังเกตุ'))
                        setSuggestId(null)
                        setErrorField('suggestId', t('กรุณาเลือกข้อเสนอแนะ'))
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="suggestId"
                    label={<LabelNew type="tab-header-inactive">{t('ข้อเสนอแนะ')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาระบุข้อเสนอแนะ') }]}
                  >
                    <SelectAuditSuggestOfIssues
                      filters={{
                        issueId: issueId,
                        rateFacProCode: rateFacProCode,
                        force: true,
                        isActive: '1',
                      }}
                      disabled={type === 'view' ? true : false}
                      formname="activityForm"
                      placeholder={t('เลือกข้อเสนอแนะ')}
                      handleChange={(value) => {
                        form.setFieldsValue({
                          suggestId: value ? value.value : null,
                          organizationCode: null,
                          organizationNameTH: null,
                          organizationNameEN: null,
                          personnelCode: null,
                          personnel: null,
                        })
                        setSuggestId(value ? value.value : null)
                        if (value) {
                          setErrorField('suggestId', false)
                        } else {
                          setErrorField('suggestId', t('กรุณาเลือกข้อเสนอแนะ'))
                        }
                      }}
                      onClear={() => {
                        form.setFieldsValue({
                          suggestId: null,
                          organizationCode: null,
                          organizationNameTH: null,
                          organizationNameEN: null,
                          personnelCode: null,
                          personnel: null,
                        })
                        setSuggestId(null)
                        setErrorField('suggestId', t('กรุณาเลือกข้อเสนอแนะ'))
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    label={<LabelNew type="tab-header-inactive">{t('กิจกรรมที่ต้องดำเนินการ')}</LabelNew>}
                    name="activity"
                    rules={[{ required: true, message: t('กรุณาระบุกิจกรรมที่ต้องดำเนินการ') }]}
                  >
                    <TextAreaNew
                      disabled={type === 'view' ? true : false}
                      placeholder={t('ระบุกิจกรรมที่ต้องดำเนินการ')}
                    />
                  </Form.Item>
                  <Form.Item
                    name="expectDate"
                    labelAlign="left"
                    label={<LabelNew type="tab-header-inactive">{t('วันที่คาดว่าจะแล้วเสร็จ')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาเลือกวันที่คาดว่าจะแล้วเสร็จ') }]}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => form.setFieldsValue({ expectDate: await data })}
                      disabled={type === 'view' ? true : false}
                      onClear={() => form.setFieldsValue({ expectDate: null })}
                      placeholder={t('เลือกวันที่คาดว่าจะแล้วเสร็จ')}
                    />
                  </Form.Item>
                  <Form.Item name="organizationNameTH" hidden={true}>
                    <InputNew />
                  </Form.Item>
                  <Form.Item name="organizationNameEN" hidden={true}>
                    <InputNew />
                  </Form.Item>
                  <Form.Item
                    name="organizationCode"
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยรับตรวจ')}</LabelNew>}
                    rules={[{ required: true, message: t('กรุณาเลือกหน่วยรับตรวจ') }]}
                  >
                    <SelectAuditSuggestOrganizations
                      filters={{ suggestId: suggestId }}
                      disabled={type === 'view' ? true : false}
                      formname="activityForm"
                      placeholder={t('เลือกหน่วยรับตรวจ')}
                      handleChange={(value) => {
                        form.setFieldsValue({
                          organizationCode: value ? value.value : null,
                          personnelCode: value ? value.source.personnelCode : null,
                          personnel: value
                            ? displayText(value.source.personnelNameTH, value.source.personnelNameEN)
                            : null,
                          organizationNameTH: value ? value.source.organizationNameTH : null,
                          organizationNameEN: value ? value.source.organizationNameEN : null,
                        })
                      }}
                      onClear={() => {
                        form.setFieldsValue({
                          organizationCode: null,
                          personnelCode: null,
                          personnel: null,
                          organizationNameTH: null,
                          organizationNameEN: null,
                        })
                      }}
                    />
                  </Form.Item>
                  <Form.Item name="personnelCode" hidden={true}>
                    <InputNew />
                  </Form.Item>
                  <Form.Item
                    name="personnel"
                    label={<LabelNew type="tab-header-inactive">{t('ผู้รับผิดชอบ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุผู้รับผิดชอบ')} disabled={true} />
                  </Form.Item>
                  <Form.Item name="remark" label={<LabelNew type="tab-header-inactive">{t('หมายเหตุ')}</LabelNew>}>
                    <InputNew placeholder={t('ระบุหมายเหตุ')} disabled={type === 'view' ? true : false} />
                  </Form.Item>
                </Col>
              </Row>
              <LabelNew type="body-header">{t('แนบเอกสาร')}</LabelNew>
              <FormFollowUpAuditActivityTabFormMultiRow
                form={form}
                typeForm={type}
                menu={props.menu}
                idFileDelete={idFileDelete}
                files={filesEdit}
                setIdFileDelete={setIdFileDelete}
              />
              <Row gutter={[12, 12]} justify="center" style={{ marginTop: '50px' }}>
                {type !== 'view' && typeMain !== 'view' && (
                  <Col>
                    <ButtonNew
                      type="primary"
                      roles={{ type: type, menu: props.menu }}
                      onClick={() => showConfirm()}
                      htmlType="button"
                    >
                      {t('บันทึก')}
                    </ButtonNew>
                  </Col>
                )}
                <Col>
                  <ButtonNew
                    type="cancel"
                    onClick={() => {
                      navigate('/followup/followupaudit/activity/' + typeMain, { state: { refCode: projectCode } })
                    }}
                  >
                    {t('ยกเลิก')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default FormFollowUpAuditActivityTabForm
