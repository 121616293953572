import React from 'react'
import { Route, Routes } from 'react-router-dom'
import RiskAssessmentReportIndex from './riskassessmentreport/RiskAssessmentReport'
import ClosingMeetingResultsIndex from './closingmeetingresults/ClosingMeetingResultsIndex'
import AuditStatusReportIndex from './auditstatusreport/AuditStatusReport'
import ConsultationResultReportIndex from './consultationresultreport/ConsultationResultReportIndex'
import ConsultationResultReportForm from './consultationresultreport/ConsultationResultReportForm'
import TimeSheetReport from './timesheetreport/TimeSheetReport'
import TaskInResponsibilityReport from './taskinresponsibilityreport/TaskInReponsibilityReport'
import RacMatrixReportList from './racmatrixreport/RacMatrixReportList'
import ProjectStatusReportList from './projectstatusreport/ProjectStatusReportList'
import CompleteResultReportIndex from './completeresultreport/CompleteResultReportIndex'
import IcaReportList from './icareport/IcaReportList'
import SetupReportSubMissionsReportIndex from './setupreportsubmissions/SetupReportSubMissionsReport'
import SetupReportSubMissionsReportDetail from './setupreportsubmissions/SetupReportSubMissionsReportDetail'
import FollowUpReportList from './followupreport/FollowUpReportList'
import SystemUsersReportList from './systemusersreport/SystemUsersReportList'
import RiskLevelFiveYearReportList from './risklevelfiveyearreport/RiskLevelFiveYearReportList'

const Router = () => {
  return (
    <Routes>
      <Route path="/closingmeetingresults">
        <Route path="" element={<ClosingMeetingResultsIndex menu={'RP06'} />} />
      </Route>
      <Route path="/projectstatusreport">
        <Route path="" element={<ProjectStatusReportList menu={'RP01'} />} />
      </Route>
      <Route path="/riskassessmentreport">
        <Route path="" element={<RiskAssessmentReportIndex menu={'RP09'} />} />
      </Route>
      <Route path="/followupreport">
        <Route path="" element={<FollowUpReportList menu={'RP04'} />} />
      </Route>
      <Route path="/consultationaummaryreport">
        <Route path="" element={<ConsultationResultReportIndex menu={'RP11'} />} />
        <Route path="form" element={<ConsultationResultReportForm menu={'RP11'} />} />
      </Route>
      <Route path="/timesheetreport">
        <Route path="" element={<TimeSheetReport menu={'RP10'} />} />
      </Route>
      <Route path="/taskinresponsibilityreport">
        <Route path="" element={<TaskInResponsibilityReport menu={'RP05'} />} />
      </Route>
      <Route path="/racmatrixreport">
        <Route path="" element={<RacMatrixReportList menu={'RP03'} />} />
      </Route>
      <Route path="/auditstatusreport">
        <Route path="" element={<AuditStatusReportIndex menu={'RP02'} />} />
      </Route>
      <Route path="/completeresultreport">
        <Route path="" element={<CompleteResultReportIndex menu={'RP07'} />} />
      </Route>
      <Route path="/icareport">
        <Route path="" element={<IcaReportList menu={'RP08'} />} />
      </Route>
      <Route path="/setupreportsubmissions">
        <Route path="" element={<SetupReportSubMissionsReportIndex menu={'RP12'} />} />
        <Route path="detail" element={<SetupReportSubMissionsReportDetail menu={'RP12'} />} />
      </Route>
      <Route path="/systemusersreport">
        <Route path="" element={<SystemUsersReportList menu={'RP13'} />} />
      </Route>
      <Route path="/risklevelfiveyearreport">
        <Route path="" element={<RiskLevelFiveYearReportList menu={'RP14'} />} />
      </Route>
    </Routes>
  )
}
export default Router
