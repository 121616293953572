import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Form } from 'antd'
import { ModalNew } from '../../../../../components/modal/Modal'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { encryptInput } from '../../../../../utilitys/crypto'
import { Alert } from '../../../../../components/alert/Alert'
import { RESET_PASSWORD } from '../../../resetpassword/graphql/Mutation'
import { LOGOUT } from '../../../../../layouts/navbar/graphql/Mutation'
import { displayError } from '../../../../../utilitys/helper'
import { InputNew } from '../../../../../components/input/Input.jsx'
import { validatePassword, validateComparPassword } from '../../../../../utilitys/validationAntd'
import { AuthContext } from '../../../../../context/authContext'

const ResetPasswordModal = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { objData } = props
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [, /*user*/ setUser] = useContext(AuthContext)

  const [resetPassword, { data: dataResetPassword, loading: loadingResetPassword, error: errorResetPassword }] =
    useMutation(RESET_PASSWORD)
  const [callLogout, { data: dataLogout }] = useMutation(LOGOUT)

  useEffect(() => {
    if (dataResetPassword) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          const input = {
            logfileLoginId: objData.logfileLoginId ? objData.logfileLoginId : null,
            userId: objData.userId,
          }

          callLogout({ variables: encryptInput(input) })
        },
      })
    }

    if (errorResetPassword) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorResetPassword.message)),
        onOk() {},
      })
    }
  }, [dataResetPassword])

  useEffect(() => {
    if (dataLogout) {
      localStorage.removeItem('user')
      setUser({ loginName: null })
      window.location.href = '/login'
    }
  }, [dataLogout])

  const onFinish = (input) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        showConfirm(input)
      },
      onCancel() {},
    })
  }

  const showConfirm = async (input) => {
    let dataInput = {
      userId2: objData.userId,
      password: input.password,
    }
    resetPassword({
      variables: {
        input: encryptInput(dataInput, process.env.REACT_APP_SECRET_FE_BE),
      },
    })
  }

  const onClose = (e) => {
    form.resetFields()
    props.close(e)
  }

  return (
    <>
      {loadingResetPassword && <SpinnersNew />}
      <ModalNew
        typeAction={objData?.type}
        visible={props.open}
        testTitle={t('เปลี่ยนรหัสผ่าน')}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{ span: 8, offset: 2 }}
          wrapperCol={{ span: 10 }}
          layout="horizontal"
          name="auditResultTwoModal"
        >
          <Form.Item
            name="password"
            label={<Label type="tab-header-inactive">{t('รหัสผ่านใหม่')}</Label>}
            onChange={(e) => setPassword(e.target.value)}
            rules={[...validatePassword(true, t('กรุณาระบุรหัสใหม่'))]}
          >
            <InputNew hide="true" placeholder="" size="large" />
          </Form.Item>
          <Form.Item
            name="confirmPassword"
            label={<Label type="tab-header-inactive">{t('ยืนยันรหัสผ่านใหม่')}</Label>}
            onChange={(e) => setConfirmPassword(e.target.value)}
            rules={[
              ...validateComparPassword(
                true,
                t('กรุณาระบุให้ตรงกับรหัสผ่านใหม่'),
                false,
                true,
                password,
                confirmPassword,
              ),
            ]}
          >
            <InputNew hide="true" placeholder="" size="large" />
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
export default ResetPasswordModal
