import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Space } from 'antd'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Datepicker } from '../../../../components/datepicker/Datepicker'
import { ButtonNew } from '../../../../components/button/Button'
import { decryptData } from '../../../../utilitys/crypto'
import SelectProjectRefer from '../../../../components/inputfromapi/selectprojectrefer/SelectProjectRefer'
import { CardNew } from '../../../../components/card/Card'
import { encryptInput } from '../../../../utilitys/crypto'
import { LabelNew } from '../../../../components/label/Label'
import { InputNew } from '../../../../components/input/Input'
import { Alert } from '../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { displayError } from '../../../../utilitys/helper'
import { GET_AUDIT_CLOSE_FILTERS } from '../graphql/Query'

const AuditClosesFilter = (props) => {
  const { limit, page, getCount, getValue, reFetch, sort, menu } = props
  const { t } = useTranslation()
  const isLang = localStorage.getItem('lang')
  const [form] = Form.useForm()
  const { pCode } = decryptData(localStorage.getItem('user'))
  const formName = 'filter'
  const [reset, setReset] = useState(false)
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [filters, setFilters] = useState({ lang: isLang })
  const Responsive = {
    md: 8,
    lg: 8,
    btn: {
      md: 24,
      lg: 24,
    },
  }
  const [
    auditCloseFormFiltersFn,
    { data: auditCloseFormFiltersData, loading: auditCloseFormFiltersLoading, error: auditCloseFormFiltersError },
  ] = useLazyQuery(GET_AUDIT_CLOSE_FILTERS)

  useEffect(() => {
    if (isLang) {
      const localFilter = {
        lang: isLang,
        personnelCode: pCode,
        ...filters,
      }
      auditCloseFormFiltersFn({
        variables: {
          input: {
            filters: encryptInput(localFilter),
            pagination: { limit, page },
            sort: sort,
          },
        },
      })
    }
  }, [page, limit, isLang, reset, reFetch, sort])

  useEffect(() => {
    if (auditCloseFormFiltersData) {
      const res_ = auditCloseFormFiltersData?.auditCloseFormFilters
      getCount(res_.count)
      getValue(res_.result)
    }
    if (auditCloseFormFiltersError) {
      const err = auditCloseFormFiltersError
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [auditCloseFormFiltersData])
  useEffect(() => {
    onFinish({})
  }, [])

  const onFinish = async (_) => {
    const localFilter = {
      projectRefCode: _?.projectRefCode || '',
      projectName: _?.projectName || '',
      organizationName: _?.organizationName ? String(_?.organizationName) : '' || '',
      personnelName: _?.personnelName || '',
      dateStart: _?.dateStart || '',
      dateEnd: _?.dateEnd || '',
      lang: isLang,
      personnelCode: pCode,
    }
    await auditCloseFormFiltersFn({
      variables: {
        input: {
          filters: encryptInput(localFilter),
          pagination: { limit, page },
          sort: [
            {
              fieldSort: 'createdAt',
              sortType: 'DESC',
            },
          ],
        },
      },
    })
    setFilters({
      ...filters,
      ...localFilter,
    })
  }
  return (
    <>
      {auditCloseFormFiltersLoading && <SpinnersNew />}
      <Row style={{ marginBottom: 24 }}>
        <Col sm={24} md={24} lg={24}>
          <CardNew topic={t('Filters')} icon="List" toggledrop={'true'}>
            <Form form={form} onFinish={onFinish} name={formName} layout="vertical">
              <Row gutter={[12, 12]}>
                <Col {...Responsive}>
                  <Form.Item
                    name={'projectRefCode'}
                    label={<LabelNew type="tab-header-inactive">{t('แหล่งที่มาของโครงการ')}</LabelNew>}
                  >
                    <SelectProjectRefer
                      filters={{ isActive: 1 }}
                      formname={formName}
                      placeholder={t('เลือกแหล่งที่มาของโครงการ')}
                      handleChange={(value) => {
                        form.setFieldsValue({ projectRefCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'projectName'}
                    label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุชื่อโครงการ')} />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'organizationName'}
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุหน่วยงานที่รับผิดชอบ')} />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'personnelName'}
                    label={<LabelNew type="tab-header-inactive">{t('ผู้รับผิดชอบหลัก')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุผู้รับผิดชอบหลัก')} />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'dateStart'}
                    label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                    // rules={[{ required: true, message: t('กรุณาระบุวันที่เริ่มต้น') }]}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        setIsMinDate(data)
                        form.setFieldsValue({ dateStart: await data })
                      }}
                      onClear={() => {
                        form.setFieldsValue({ dateStart: null })
                      }}
                      isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'dateEnd'}
                    label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                    // rules={[{ required: true, message: t('กรุณาระบุวันที่เริ่มต้น') }]}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        setIsMaxDate(data)
                        form.setFieldsValue({ dateEnd: await data })
                      }}
                      onClear={() => {
                        form.setFieldsValue({ dateEnd: null })
                      }}
                      isMinDate={isMinDate ? new Date(isMinDate) : null}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col {...Responsive.btn} align="center">
                  <Space size={[8, 8]} wrap>
                    <ButtonNew
                      // loading={auditCloseFormFiltersLoading}
                      type="search"
                      roles={{ type: 'view', menu: menu }}
                      htmlType="submit"
                    >
                      {t('ค้นหา')}
                    </ButtonNew>
                    &nbsp;
                    <ButtonNew
                      type="clear"
                      onClick={() => {
                        form.resetFields()
                        setFilters({ lang: isLang })
                        setReset(reset ? false : true)
                      }}
                    >
                      {t('ล้างค่า')}
                    </ButtonNew>
                  </Space>
                </Col>
              </Row>
            </Form>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default AuditClosesFilter
