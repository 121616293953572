import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_ACTION_AUDIT_PLAN } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectActionAuditPlan = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { isActive, approveStatusCode } = filters || {}

  const [isData, isSetData] = useState([])

  const [getActionAuditPlansFn, getActionAuditPlans] = useLazyQuery(GET_ACTION_AUDIT_PLAN)

  useEffect(() => {
    getActionAuditPlansFn({
      variables: { data: filters ? encryptInput({ ...filters, isActive: 1 }) : encryptInput({ isActive: 1 }) },
    })
  }, [isActive, approveStatusCode])

  useEffect(() => {
    if (getActionAuditPlans.data) {
      isSetData(
        _.map(getActionAuditPlans?.data?.getActionAuditPlans, (item) => {
          return {
            value: item.projectCode,
            label: item.projectCode + ': ' + displayText(`${item.projectNameTH}`, `${item.projectNameEN}`),
            source: item,
          }
        }),
      )
    }
  }, [getActionAuditPlans.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getActionAuditPlans.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectActionAuditPlan
