import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'key',
    width: '8%',
    // sorter: { multiple: 1 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>กลุ่มผู้ใช้งาน</Trans>,
    dataIndex: 'roleName',
    width: '33%',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>วันที่เริ่มต้น</Trans>,
    dataIndex: 'startDate',
    width: '10%',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>วันที่สิ้นสุด</Trans>,
    dataIndex: 'expireDate',
    width: '10%',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>อัพเดตล่าสุด</Trans>,
    dataIndex: 'createdAt',
    width: '17%',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>โดย</Trans>,
    dataIndex: 'username',
    width: '12%',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '10%',
    className: 'text-nowrap',
  },
]
