import React from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import { CardNew } from '../../../../../components/card/Card'
import { ButtonNew } from '../../../../../components/button/Button'
import { InputNew } from '../../../../../components/input/Input'
import { LabelNew } from '../../../../../components/label/Label'
import SelectMasterOrganizationLevel from '../../../../../components/inputfromapi/selectorganizationlevel/SelectOrganizationLevel'

const OrganizationFilter = (props) => {
  const Responsive = { md: 8, lg: 8 }
  const { menu, onFilterOrganization } = props

  const [form] = Form.useForm()
  const formName = 'from-organizatiolns-filter'
  const { t } = useTranslation()

  const onFinish = (data) => onFilterOrganization({ ...data, isActive: 1 })

  return (
    <CardNew topic={t('Filters')} icon="List" toggledrop="false">
      <Form form={form} onFinish={onFinish} name={formName} layout="vertical">
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <Form.Item
              name="organizationCode"
              label={<LabelNew type="tab-header-inactive">{t('รหัสหน่วยงาน')}</LabelNew>}
            >
              <InputNew placeholder={t('ระบุรหัสหน่วยงาน')} />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name="organizationName"
              label={<LabelNew type="tab-header-inactive">{t('ชื่อหน่วยงาน')}</LabelNew>}
            >
              <InputNew placeholder={t('ระบุชื่อหน่วยงาน')} />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name="organizationLevelCode"
              label={<LabelNew type="tab-header-inactive">{t('ระดับหน่วยงาน')}</LabelNew>}
            >
              <SelectMasterOrganizationLevel
                placeholder={t('เลือกระดับหน่วยงาน')}
                formname={formName}
                handleChange={(e) => form.setFieldsValue({ organizationLevelCode: e?.value })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <ButtonNew type="search" htmlType="submit" formname={formName}>
              {t('ค้นหา')}
            </ButtonNew>
          </Col>
          <Col>
            <ButtonNew type="clear" menu={menu} onClick={() => form.resetFields()}>
              {t('ยกเลิก')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
    </CardNew>
  )
}
export default OrganizationFilter
