import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import { encryptInput } from '../../../../../utilitys/crypto'

const GET_HEADER_ORGANIZATION = gql`
  query getHeaderOrganization($input: GetBranchOrganizationInput!) {
    getHeaderOrganization(input: $input) {
      organizationCode
      organizationNameTH
      organizationNameEN
      sub
    }
  }
`

export const useGetHeaderOrganization = (organizationCode) => {
  const [data, setData] = useState(null)
  const [getHeaderOrganizationFn, getHeaderOrganization] = useLazyQuery(GET_HEADER_ORGANIZATION)

  useEffect(() => {
    getHeaderOrganizationFn({
      variables: { input: encryptInput({ organizationCode: organizationCode ? organizationCode : null }) },
    })
  }, [organizationCode])

  useEffect(() => {
    if (getHeaderOrganization?.data) setData(getHeaderOrganization?.data?.getHeaderOrganization)
  }, [getHeaderOrganization?.data])

  const refresh = () => getHeaderOrganization.refetch()

  return [data, getHeaderOrganization?.loading, refresh]
}
