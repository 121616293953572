import styled from 'styled-components'
import { colors, layout } from '../../configs/styles.config'
import LogoAms from '../../assets/images/logo_ams.png'

export const NavbarStyle = styled.div`
  .header {
    background-color: #fff !important;
    -webkit-box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, 0.05);
    box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, 0.05);
  }

  .ant-layout-header {
    line-height: unset;
  }
  .profile {
    padding: 10px 15px 4px;
  }
  .profile > .name {
    white-space: nowrap;
    line-height: 15px;
    font-size: 13px;
    color: #666666;
    font-weight: bold;
  }
  .profile > .dev {
    white-space: nowrap;
    line-height: 15px;
    font-size: 12px;
    color: #6e6e6e;
  }
  .profile > .code {
    white-space: nowrap;
    line-height: 15px;
    font-size: 11px;
    color: #6e6e6e;
  }
  .avatar {
    padding-top: 10px;
    .ant-avatar {
      position: unset;
      img {
        border-radius: 50%;
      }
      &::after {
        content: ' ';
        border: solid 1.5px #fff;
        position: absolute;
        bottom: 10px;
        right: 0px;
        border-radius: 50%;
        width: 12px;
        height: 12px;
        background-color: #aaa;
      }
      &.active {
        &::after {
          background-color: #96c43c;
        }
      }
    }
  }

  .avatar > span {
    cursor: pointer;
  }

  .link-noti {
    &.ant-dropdown-open {
      svg {
        stroke: ${colors.primary};
      }
    }
    .icon-noti {
      font-size: 32px;
      margin: 8px 15px 0px;
      display: block;
      position: relative;
      .num {
        position: absolute;
        font-size: 9px;
        color: #fff;
        background-color: ${colors.danger};
        border-radius: 50%;
        width: 18px;
        height: 18px;
        text-align: center;
        padding: 2.5px 0px;
        right: -6px;
        bottom: 11px;
        font-family: 'NotoSansThai';
      }
    }
  }
`

export const MenuStyle = styled.div`
  box-shadow: 0px 2px 6px #ccc;
  min-width: 130px;
  overflow-y: auto;
  .ant-menu-vertical > .ant-menu-item {
    margin-top: 0px;
    height: unset;
    line-height: 1;
    padding: 10px 12px;
    &:hover {
      background-color: ${colors.colorhover};
    }

    .ant-menu-title-content {
      font-size: 14px;
    }
  }
  .ant-menu-vertical {
    .ant-menu-item:last-child {
      margin-bottom: 0px;
    }
    .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
      border-bottom: solid 1px #f1f1f1;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: unset;
    &:hover {
      background-color: ${layout.menuItemHover};
    }
  }
`

const NOTI_WIDTH = 290
const NOTI_HEIGHT = 390
export const MenuNotiStyle = styled.div`
  .scroll {
    background-color: #fff;
    box-shadow: 0px 2px 6px #ccc;
    width: ${NOTI_WIDTH}px;
    height: ${NOTI_HEIGHT}px;
    overflow-y: auto;
  }

  .ant-menu-vertical {
    border-right: 0px;
  }

  .ant-menu-vertical > .ant-menu-item {
    margin-top: 0px;
    height: unset;
    line-height: 1;
    padding: 10px 12px;
    &.notiEmpty {
      text-align: center;
      padding: 45% 0px;
      pointer-events: none;
    }
    &:hover {
      background-color: ${colors.colorhover};
    }

    .ant-menu-title-content {
      font-size: 14px;
      .title {
        color: ${colors.primary};
        padding-left: 5px;
        font-weight: 500;
        padding-right: 0px;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        white-space: normal;
        line-height: 1.2;
      }
      .desc {
        padding-left: 5px;
        margin-top: 3px;
        font-size: 13px;
        overflow: hidden;
        width: 270px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        white-space: break-spaces;
        padding-right: 15px;
        line-height: 1.3;
      }
      .datetime {
        display: block;
        width: 100%;
        padding-left: 5px;
        margin-top: 3px;
        font-size: 11px;
        color: #888;
      }
    }
  }
  .ant-menu-vertical {
    .ant-menu-item:last-child {
      margin-bottom: 0px;
    }
    .ant-menu-item:not(:last-child) {
      margin-bottom: 0px;
      border-bottom: solid 1px #f1f1f1;
    }
  }
  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: unset;
    &:hover {
      background-color: ${layout.menuItemHover};
    }
  }

  .link-more {
    text-align: center;
    font-size: 14px;
    padding: 3px;
    background-color: #f4f4f4;
    cursor: pointer;
    &:hover {
      background-color: #f1f1f1;
      color: ${colors.primary};
    }
  }
`
export const NavLogo = styled.div`
  background-image: url(${LogoAms});
  background-repeat: no-repeat;
  background-size: cover;
  height: 67px;
  width: 111px;
  @media screen and (min-width: 980px) {
    margin-left: -30px;
  }
  @media screen and (max-width: 979px) {
    margin-left: -35px;
  }
`
