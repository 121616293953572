import React, { useEffect, useState } from 'react'
import { Col, Row, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { GET_ACTION_EXPLAIN_REPORT_EXPORT } from '../graphql/Query'
import { ModalNew } from '../../../../components/modal/Modal'
import { LabelNew } from '../../../../components/label/Label'
import { RadioGroup, Radio } from '../../../../components/radio/Radio'
import { InputNew } from '../../../../components/input/Input'
import { Alert } from '../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayError } from '../../../../utilitys/helper'
import { useLanguage } from '../../../../hooks/useLanguage'

const ActionExplainReportExport = (props) => {
  const responsive = { lg: 17 }
  const { closeModal, actionExplainReportExportModal, dataForExport } = props

  const { t } = useTranslation()
  const formname = 'actionExplainReportExport'
  const [form] = Form.useForm()

  const [isLang] = useLanguage()
  const [waterMark, setWaterMark] = useState(false)

  const [getActionExplainReportExportFn, getActionExplainReportExport] = useLazyQuery(GET_ACTION_EXPLAIN_REPORT_EXPORT)

  useEffect(() => {
    if (getActionExplainReportExport?.data) {
      const resData = getActionExplainReportExport?.data?.getActionExplainReportExport
      if (resData) {
        if (resData.message === 'succcess') {
          window.open(`${resData.fileUrl}`, '_blank')
          closeModal(false)
        } else {
          closeModal(false)
        }
      }
    }
    if (getActionExplainReportExport?.error) {
      showAlertError('ไม่สามารถโหลดข้อมูลได้', getActionExplainReportExport.error)
    }
  }, [getActionExplainReportExport?.data])

  const onFinish = (data) => {
    data.waterMark = data.waterMark ? data.waterMark : ''
    data.projectCode = dataForExport.projectCode
    data.lang = isLang

    getActionExplainReportExportFn({
      variables: { input: encryptInput({ ...data }) },
    })
  }

  const showAlertError = (text, error) => {
    Alert({
      type: 'error',
      title: t(text),
      content: t(displayError(error)),
      onOk() {},
    })
  }

  return (
    <>
      {getActionExplainReportExport.loading && <SpinnersNew />}
      <ModalNew
        onSubmit={() => {
          form.submit()
        }}
        onClose={() => Promise.all([form.resetFields(), closeModal(false)])}
        visible={actionExplainReportExportModal}
        typeAction={dataForExport.typeAction}
        testTitle={t('พิมพ์รายงาน')}
        btnName={t('พิมพ์')}
      >
        <Form
          name={formname}
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelCol={{
            lg: 8,
            xl: 8,
          }}
          wrapperCol={{
            span: 15,
          }}
          layout="horizontal"
          labelAlign="left"
        >
          <Row justify="center">
            <Col {...responsive}>
              <Form.Item
                label={<LabelNew type="tab-header-inactive">{t('ประเภทรายงาน')}</LabelNew>}
                name="fileType"
                initialValue={'pdf'}
                onClick={(e) => {
                  if (e.target.value) {
                    if (e.target.value !== 'pdf') {
                      setWaterMark(true)
                    } else {
                      setWaterMark(false)
                    }
                  }
                }}
              >
                <RadioGroup>
                  <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <Radio value="pdf">
                        <LabelNew type="tab-header-inactive">{t('PDF')}</LabelNew>
                      </Radio>
                    </Col>
                    <Col span={12}>
                      <Radio value="word">
                        <LabelNew type="tab-header-inactive">{t('Word')}</LabelNew>
                      </Radio>
                    </Col>
                  </Row>
                  <Row gutter={[8, 8]}>
                    <Col span={12}>
                      <Radio value="excel">
                        <LabelNew type="tab-header-inactive">{t('Excel')}</LabelNew>
                      </Radio>
                    </Col>
                    <Col span={12}>
                      <Radio value="csv">
                        <LabelNew type="tab-header-inactive">{t('CSV')}</LabelNew>
                      </Radio>
                    </Col>
                  </Row>
                </RadioGroup>
              </Form.Item>
              <Form.Item
                name="waterMark"
                label={<LabelNew type="tab-header-inactive">{t('ลายน้ำ')}</LabelNew>}
                hidden={waterMark}
              >
                <InputNew placeholder={t('ระบุลายน้ำ (เฉพาะ PDF)')} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </ModalNew>
    </>
  )
}

export default ActionExplainReportExport
