// หน้านี้จะเก็บตัวแปล กลางต่างๆ เพื่อเช็ค ว่าฟอมแต่ละ แท็ปมีการเปลี่ยนแปลงค่าหรือเปล่า
//  isTab คือ เช็คสถานะ ตอนกดเปลี่ยนแท็ป
//  isUpdate คือ เช็คสถานะ เมื่อมีการเปลี่ยนค่า ใน form แต่ละ tab
//  isUpdate คือ เช็คสถานะ เมื่อมีการเปลี่ยนค่า ใน form แต่ละ tab
//  tabs เป็น state เพราะว่าให้มันสามารถ กดหนด การ เรนเดอร์ ใหม่ได้ ( ให้มันเรนเดอร์ใหม่ setTabs({...tabs}) )
import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import { CardNew } from '../../../components/card/Card'
import { TabNew } from '../../../components/tab/Tab'
import { LabelNew } from '../../../components/label/Label'
import { ButtonNew } from '../../../components/button/Button'
import PersonnelForm from './PersonnelForm'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Alert } from '../../../components/alert/Alert'
import { getSegmentUrl } from '../../../utilitys/helper'
import { useMutation } from '@apollo/client'
import { PERSONNELS_SAVE } from './graphql/Mutation'
import { encryptInput } from '../../../utilitys/crypto'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb.jsx'
import PersonnelEducationList from './PersonnelEducationList'
import PersonnelTrainingList from './PersonnelTrainingList'
import PersonnelRewardList from './PersonnelRewardList.jsx'
import PersonnelCertificateList from './PersonnelCertificateList'
import PersonnelExperienceList from './PersonnelExperienceList'
import { useTranslation } from 'react-i18next'
import { SpinnersNew } from '../../../components/spinners/Spinners'

const PersonnelFormIndex = (props) => {
  const { menu } = props

  const { state } = useLocation()
  const { t } = useTranslation()

  if (!state) return <Navigate to={'/administrator/personnel'} />

  const tabName = getSegmentUrl(3)
  const [saveData, { data: dataSave, loading: loadingSave }] = useMutation(PERSONNELS_SAVE)
  const [formType, setFormType] = useState(
    state.type_action === 'add' ? 'add' : state.type_action === 'edit' ? 'edit' : 'view',
  )
  const navigate = useNavigate()
  const [refCodeNew, setRefCodeNew] = useState()
  const [contentNameTypeCreate, setContentNameTypeCreate] = useState()

  //
  const [isTab, setIsTab] = useState(false)
  // เก็บสถานะการเปลี่ยนแปลง form นั้นๆ
  const [isUpdate, setIsUpdate] = useState(false)
  // เก็บ Data ของแต่ละ tab
  const [updateData, setUpdateData] = useState()
  const [keyData, setKeyData] = useState(0)
  // set tabs
  const [tabs, setTabs] = useState([
    {
      key: 1,
      name: t('ข้อมูลพื้นฐานบุคลากร'),
      disabled: false,
      content: () => (
        <PersonnelForm
          setIsUpdate={setIsUpdate}
          setUpdateData={setUpdateData}
          menu={menu}
          setFormType={setFormType}
          setRefCodeNew={setRefCodeNew}
          formType={formType}
          type_action={state.type_action}
          setContentNameTypeCreate={setContentNameTypeCreate}
          contentNameTypeCreate={contentNameTypeCreate}
        />
      ),
    },
    {
      key: 2,
      name: t('ข้อมูลการศึกษา'),
      disabled: state.refCode || refCodeNew ? false : true,
      content: function () {
        return (
          <div>
            <PersonnelEducationList menu={menu} type_action={formType} />
            <Row align="center" style={{ marginTop: '15px' }}>
              <ButtonNew
                type="back"
                onClick={() => {
                  navigate('/administrator/personnel')
                }}
              >
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Row>
          </div>
        )
      },
    },
    {
      key: 3,
      name: t('ข้อมูลการฝึกอบรม'),
      disabled: state.refCode || refCodeNew ? false : true,
      content: function () {
        return (
          <div>
            <PersonnelTrainingList menu={menu} type_action={formType} />
            <Row align="center" style={{ marginTop: '15px' }}>
              <ButtonNew
                type="back"
                onClick={() => {
                  navigate('/administrator/personnel')
                }}
              >
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Row>
          </div>
        )
      },
    },
    {
      key: 4,
      name: t('ข้อมูลรางวัล ผลงาน'),
      disabled: state.refCode || refCodeNew ? false : true,
      content: function () {
        return (
          <div>
            <PersonnelRewardList menu={menu} type_action={formType} />
            <Row align="center" style={{ marginTop: '15px' }}>
              <ButtonNew
                type="back"
                onClick={() => {
                  navigate('/administrator/personnel')
                }}
              >
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Row>
          </div>
        )
      },
    },
    {
      key: 5,
      name: t('ข้อมูลใบวุฒิบัตร'),
      disabled: state.refCode || refCodeNew ? false : true,
      content: function () {
        return (
          <div>
            <PersonnelCertificateList menu={menu} type_action={formType} />
            <Row align="center" style={{ marginTop: '15px' }}>
              <ButtonNew
                type="back"
                onClick={() => {
                  navigate('/administrator/personnel')
                }}
              >
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Row>
          </div>
        )
      },
    },
    {
      key: 6,
      name: t('ข้อมูลงานที่รับผิดชอบ'),
      disabled: state.refCode || refCodeNew ? false : true,
      content: function () {
        return (
          <div>
            <PersonnelExperienceList menu={menu} />
            <Row
              align="center"
              onClick={() => {
                navigate('/administrator/personnel')
              }}
            >
              <ButtonNew type="back">{t('ย้อนกลับ')}</ButtonNew>
            </Row>{' '}
          </div>
        )
      },
    },
  ])

  useEffect(() => {
    if (refCodeNew) {
      setTabs([
        {
          key: 1,
          name: t('ข้อมูลพื้นฐานบุคลากร'),
          disabled: false,
          content: () => (
            <PersonnelForm
              type_action={state.type_action}
              setIsUpdate={setIsUpdate}
              setUpdateData={setUpdateData}
              updateData={updateData} // เอาค่าที่ได้จาก ฟอม ส่งเข้าไปใหม่ เพื่อไม่ให้ข้อมูลเปลี่ยนแปลง ไปจากเดิม
              menu={menu}
              formType={formType}
              setFormType={setFormType}
              setRefCodeNew={setRefCodeNew}
              contentNameTypeCreate={contentNameTypeCreate}
              setContentNameTypeCreate={setContentNameTypeCreate}
            />
          ),
        },
        {
          key: 2,
          name: t('ข้อมูลการศึกษา'),
          disabled: state.refCode || refCodeNew ? false : true,
          content: function () {
            return (
              <div>
                <PersonnelEducationList menu={menu} formType={formType} setFormType={setFormType} />
                <Row align="center" style={{ marginTop: '15px' }}>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/administrator/personnel')
                    }}
                  >
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Row>
              </div>
            )
          },
        },
        {
          key: 3,
          name: t('ข้อมูลการฝึกอบรม'),
          disabled: state.refCode || refCodeNew ? false : true,
          content: function () {
            return (
              <div>
                <PersonnelTrainingList menu={menu} />
                <Row align="center" style={{ marginTop: '15px' }}>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/administrator/personnel')
                    }}
                  >
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Row>
              </div>
            )
          },
        },
        {
          key: 4,
          name: t('ข้อมูลรางวัล ผลงาน'),
          disabled: state.refCode || refCodeNew ? false : true,
          content: function () {
            return (
              <div>
                <PersonnelRewardList menu={menu} />
                <Row align="center" style={{ marginTop: '15px' }}>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/administrator/personnel')
                    }}
                  >
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Row>
              </div>
            )
          },
        },
        {
          key: 5,
          name: t('ข้อมูลใบวุฒิบัตร'),
          disabled: state.refCode || refCodeNew ? false : true,
          content: function () {
            return (
              <div>
                <PersonnelCertificateList menu={menu} />
                <Row align="center" style={{ marginTop: '15px' }}>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/administrator/personnel')
                    }}
                  >
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Row>
              </div>
            )
          },
        },
        {
          key: 6,
          name: t('ข้อมูลงานที่รับผิดชอบ'),
          disabled: state.refCode || refCodeNew ? false : true,
          content: function () {
            return (
              <div>
                <PersonnelExperienceList menu={menu} />
                <Row
                  align="center"
                  onClick={() => {
                    navigate('/administrator/personnel')
                  }}
                >
                  <ButtonNew type="back">{t('ย้อนกลับ')}</ButtonNew>
                </Row>{' '}
              </div>
            )
          },
        },
      ])
    }
  }, [refCodeNew])

  const [submitUpdate, setSubmitUpdate] = useState(false)
  const arrCrumb = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/personnel' },
    { label: t('จัดการข้อมูลบุคลากร') },
  ]
  // Intitial value formType
  if (state.type_action !== 'add') {
    if (!state) return <Navigate to={'/administrator/personnel/information'} />
  }

  useEffect(() => {
    // useEffect ตัวนี้จะจับการเปลี่ยนแปลง ของตัว isTab ถ้ามันมีการเปลี่ยนแปลงให้ทำที่ตัวนี้
    if (isUpdate && isTab) {
      // เมื่อ form มีการเปลี่ยนแปลง แล้ว มีการกดเปลี่ยน tab ให้ทำ บรรนทัดล่างต่อ
      // ทำการ เรนเดอร์ tab ใหม่ แล้วเอาดาต้า ( updateData ) ใหม่ใส่เข้าไปใน tab
      setTabs([
        {
          key: 1,
          name: t('ข้อมูลพื้นฐานบุคลากร'),
          disabled: false,
          content: () => (
            <PersonnelForm
              type_action={state.type_action}
              setIsUpdate={setIsUpdate}
              setUpdateData={setUpdateData}
              updateData={updateData} // เอาค่าที่ได้จาก ฟอม ส่งเข้าไปใหม่ เพื่อไม่ให้ข้อมูลเปลี่ยนแปลง ไปจากเดิม
              menu={menu}
              formType={formType}
              setFormType={setFormType}
              setRefCodeNew={setRefCodeNew}
            />
          ),
        },
        {
          key: 2,
          name: t('ข้อมูลการศึกษา'),
          disabled: state.refCode || refCodeNew ? false : true,
          content: function () {
            return (
              <div>
                <PersonnelEducationList menu={menu} formType={formType} setFormType={setFormType} />
                <Row align="center" style={{ marginTop: '15px' }}>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/administrator/personnel')
                    }}
                  >
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Row>
              </div>
            )
          },
        },
        {
          key: 3,
          name: t('ข้อมูลการฝึกอบรม'),
          disabled: state.refCode || refCodeNew ? false : true,
          content: function () {
            return (
              <div>
                <PersonnelTrainingList menu={menu} />
                <Row align="center" style={{ marginTop: '15px' }}>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/administrator/personnel')
                    }}
                  >
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Row>
              </div>
            )
          },
        },
        {
          key: 4,
          name: t('ข้อมูลรางวัล ผลงาน'),
          disabled: state.refCode || refCodeNew ? false : true,
          content: function () {
            return (
              <div>
                <PersonnelRewardList menu={menu} />
                <Row align="center" style={{ marginTop: '15px' }}>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/administrator/personnel')
                    }}
                  >
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Row>
              </div>
            )
          },
        },
        {
          key: 5,
          name: t('ข้อมูลใบวุฒิบัตร'),
          disabled: state.refCode || refCodeNew ? false : true,
          content: function () {
            return (
              <div>
                <PersonnelCertificateList menu={menu} />
                <Row align="center" style={{ marginTop: '15px' }}>
                  <ButtonNew
                    type="back"
                    onClick={() => {
                      navigate('/administrator/personnel')
                    }}
                  >
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Row>
              </div>
            )
          },
        },
        {
          key: 6,
          name: t('ข้อมูลงานที่รับผิดชอบ'),
          disabled: state.refCode || refCodeNew ? false : true,
          content: function () {
            return (
              <div>
                <PersonnelExperienceList menu={menu} />
                <Row
                  align="center"
                  onClick={() => {
                    navigate('/administrator/personnel')
                  }}
                >
                  <ButtonNew type="back">{t('ย้อนกลับ')}</ButtonNew>
                </Row>{' '}
              </div>
            )
          },
        },
      ])
      Alert({
        type: 'confirm',
        title: t('ต้องการยืนยันการบันทึก ?'),
        onOk() {
          setSubmitUpdate(true)
          setIsUpdate(false)
          setIsTab(false)
          // ทำการ เรนเดอร์ tab ใหม่
          setTabs([...tabs])
          if (keyData === '1') {
            navigate('/administrator/personnel/information', {
              state: { refCode: state.refCode ? state.refCode : refCodeNew, type_action: formType },
            })
          } else if (keyData === '2') {
            navigate('/administrator/personnel/education', {
              state: { refCode: state.refCode ? state.refCode : refCodeNew, type_action: formType },
            })
          } else if (keyData === '3') {
            navigate('/administrator/personnel/training', {
              state: { refCode: state.refCode ? state.refCode : refCodeNew, type_action: formType },
            })
          } else if (keyData === '4') {
            navigate('/administrator/personnel/reward', {
              state: { refCode: state.refCode ? state.refCode : refCodeNew, type_action: formType },
            })
          } else if (keyData === '5') {
            navigate('/administrator/personnel/certificate', {
              state: { refCode: state.refCode ? state.refCode : refCodeNew, type_action: formType },
            })
          } else if (keyData === '6') {
            navigate('/administrator/personnel/experience', {
              state: { refCode: state.refCode ? state.refCode : refCodeNew, type_action: formType },
            })
          }
        },
        onCancel() {
          setIsTab(false)
        },
      })
    }
  }, [isTab])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          setIsUpdate(false)
          setSubmitUpdate(false)
          setUpdateData('')
          setContentNameTypeCreate()
        },
      })
    }
  }, [dataSave])

  useEffect(() => {
    if (submitUpdate) {
      let dataInput = {
        personnelCode: updateData.personnelCode || '',
        personnalTypeCode: updateData.personnalTypeCode || '',
        organizationCode: updateData.organizationCode || '',
        positionCode: updateData.positionCode || '',
        levelCode: updateData.levelCode || '',
        personnaloperationCode: updateData.personnaloperationCode || '',
        namePrefixCode: updateData.namePrefixCode || '',
        firstNameTH: updateData.firstNameTH || '',
        lastNameTH: updateData.lastNameTH || '',
        firstNameEN: updateData.firstNameEN || '',
        lastNameEN: updateData.lastNameEN || '',
        genderCode: updateData.genderCode || 'z',
        dateOfBirth: updateData.dateOfBirth || '',
        mobile: updateData.mobile || '',
        emergency: updateData.emergency || '',
        lineId: updateData.lineId || '',
        email: updateData.email || '',
        telephone: updateData.telephone || '',
        responsibility: updateData.responsibility ? updateData.responsibility : 0,
        profilePicture: updateData.fileProfile || [],
        action: 'edit',
      }
      saveData({
        variables: {
          input: encryptInput(dataInput),
        },
      })
    }
  }, [submitUpdate])

  return (
    <React.Fragment>
      {loadingSave ? <SpinnersNew /> : ''}
      <Breadcrumb data={arrCrumb} title={t('จัดการข้อมูลบุคลากร')} />
      <CardNew topic={<LabelNew type="sub-header"> {t('เพิ่มข้อมูลบุคลากร')} </LabelNew>} icon="Plus">
        <TabNew
          dynamic={'false'}
          tab={tabs}
          activeKey={
            tabName === 'information'
              ? '1'
              : tabName === 'education'
              ? '2'
              : tabName === 'training'
              ? '3'
              : tabName === 'reward'
              ? '4'
              : tabName === 'certificate'
              ? '5'
              : '6'
          }
          onTabClick={(key) => {
            // เช็ค ว่า มีการเปลี่ยนแปลงค่าใน ฟอม ไหม tab
            if (isUpdate) {
              setIsTab(true)
              setKeyData(key)
            } else if (!isUpdate) {
              // เช็คในกรณีที่ เราไม่มีการเปลี่ยนแปลงค่าในฟอมให้ มันสามารถ เปลี่ยน tab ได้โดยไม่ต้อง ยืนยัน
              if (key === '1') {
                navigate('/administrator/personnel/information', {
                  state: { refCode: state.refCode ? state.refCode : refCodeNew, type_action: formType },
                })
              } else if (key === '2') {
                navigate('/administrator/personnel/education', {
                  state: { refCode: state.refCode ? state.refCode : refCodeNew, type_action: formType },
                })
              } else if (key === '3') {
                navigate('/administrator/personnel/training', {
                  state: { refCode: state.refCode ? state.refCode : refCodeNew, type_action: formType },
                })
              } else if (key === '4') {
                navigate('/administrator/personnel/reward', {
                  state: { refCode: state.refCode ? state.refCode : refCodeNew, type_action: formType },
                })
              } else if (key === '5') {
                navigate('/administrator/personnel/certificate', {
                  state: { refCode: state.refCode ? state.refCode : refCodeNew, type_action: formType },
                })
              } else if (key === '6') {
                navigate('/administrator/personnel/experience', {
                  state: { refCode: state.refCode ? state.refCode : refCodeNew, type_action: formType },
                })
              }
            }
          }}
        />
      </CardNew>
    </React.Fragment>
  )
}
export default PersonnelFormIndex
