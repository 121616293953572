import { Col, Form, Radio, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'

import './actionplanstyles/ActionPlanStyles.scss'
import SelectAuditProjects from '../components/Select/SelectAuditProjectsByActionPlan'

import { ButtonNew } from '../../../../components/button/Button'
import { InputNew } from '../../../../components/input/Input'
import { RadioGroup } from '../../../../components/radio/Radio'
import { displayError, displayText } from '../../../../utilitys/helper'
import { dateDisplay } from '../../../../utilitys/dateHelper'
import { Datepicker } from '../../../../components/datepicker/Datepicker'
import { IconNew } from '../../../../components/icon/Icon'
import SelectPersonnels from '../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import { Alert } from '../../../../components/alert/Alert'
import { SAVE_ACTION_PLAN } from './graphql/Mutation'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { GET_ACTION_PLAN_BY_ID } from './graphql/Query'

const ActionPlanFormDetail = ({
  activeKey = null,
  menu,
  actionPlanId,
  type_action,
  backPath,
  setauditProjectCode = () => {},
  setauditTeamCode = () => {},
  auditProjectCode = null,
}) => {
  if (activeKey !== '1') return <></>

  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [editable, seteditable] = useState(false)
  const [isDataForm, setisDataForm] = useState(false)

  useEffect(() => {
    if (type_action) {
      if (type_action !== 'view') seteditable(true)
    }
  }, [type_action])

  const [callGet, { loading: loadingGet, error: errorGet, data: dataGet }] = useLazyQuery(GET_ACTION_PLAN_BY_ID)

  useEffect(() => {
    if (!isDataForm && actionPlanId) {
      const dataInput = {
        actionPlanId,
      }
      callGet({
        variables: {
          input: encryptInput(dataInput),
        },
      })
    }
  }, [actionPlanId])

  useEffect(() => {
    if (errorGet) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorGet)),
      })
    }
  }, [errorGet])

  useEffect(() => {
    if (dataGet) {
      setisDataForm(true)

      const { approver, dateJust, projectCode, isActive } = dataGet.getActionPlanByID
      setauditProjectCode(projectCode)

      form.setFieldsValue({ approver, dateJust, projectCode, isActive })
    }
  }, [dataGet])

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_ACTION_PLAN)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorSave)),
      })
    }
  }, [errorSave])

  useEffect(() => {
    const field = form.getFieldValue()
    const { auditTeamCode, projectCode } = field
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/auditoperations/actionplan/form', {
            state: {
              refCode: dataSave.saveActionPlan.actionPlanId,
              type_action: 'edit',
              defaultActiveKey: '2',
              auditTeamCode: auditTeamCode,
              projectCode: projectCode,
            },
          })
        },
      })
    }
  }, [dataSave])

  const onFinish = () => {
    const field = form.getFieldValue()

    const { projectCode, approver = null, dateJust, isActive, auditTeamCode } = field

    const dataInput = {
      projectCode,
      dateJust: dateJust ? new Date(dateJust) : null,
      approver,
      isActive,
      approveStatusCode: '10',
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        if (type_action === 'add') {
          dataInput.typeAction = 'add'
        } else {
          dataInput.typeAction = 'edit'
          dataInput.actionPlanId = actionPlanId
        }

        setauditProjectCode(projectCode)
        setauditTeamCode(auditTeamCode)

        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const formName = 'ActionPlanDetail'

  return (
    <React.Fragment>
      {(loadingSave || loadingGet) && <SpinnersNew />}

      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        labelCol={{
          span: 6,
          offset: 1,
          md: 9,
          lg: 9,
          xl: 7,
        }}
        wrapperCol={{
          md: 9,
          lg: 9,
        }}
        layout="horizontal"
        name={formName}
      >
        <Form.Item
          style={{ marginTop: 30 }}
          name="projectCode"
          label={t('โครงการ')}
          rules={[{ required: true, message: t('กรุณาระบุ') }]}
        >
          <SelectAuditProjects
            disabled={!editable || type_action === 'edit'}
            projectCode={auditProjectCode}
            filters={{
              approveStatusCode: 20,
              projectStatusCode: 10,
            }}
            formname={formName}
            placeholder={t('เลือกโครงการ')}
            handleChange={(data) => {
              let dataField = {}
              if (data) {
                const { source } = data

                let projectType = ''

                if (source.projectTypeCode) {
                  if (Number(source.projectTypeCode) === 10) projectType = t('โครงการตามแผน')
                  if (Number(source.projectTypeCode) === 20) projectType = t('โครงการนอกแผน')
                }

                dataField = {
                  projectCode: source.projectCode,
                  projectName: source.projectCode + ' : ' + displayText(source.projectNameTH, source.projectNameEN),
                  projectRefName:
                    source.projectRefCode + ' : ' + displayText(source.projectRefNameTH, source.projectRefNameEN),
                  riskFormName: source.riskFormCode + ' : ' + displayText(source.riskFormTH, source.riskFormEN),
                  projectType: projectType,
                  organizationName:
                    source.organizationCode + ' : ' + displayText(source.organizationNameTH, source.organizationNameEN),
                  auditTeamName:
                    source.auditTeamCode + ' : ' + displayText(source.auditTeamNameTH, source.auditTeamNameEN),
                  numberOfPeople: source.numberOfPeople,
                  manday: source.manday,
                  personnelName: displayText(source.personnelNameTH, source.personnelNameEN),
                  dateStart: dateDisplay(source.dateStart),
                  dateEnd: dateDisplay(source.dateEnd),
                  auditTeamCode: source.auditTeamCode,
                }
              } else {
                dataField = {
                  projectCode: null,
                  projectName: null,
                  projectRefName: null,
                  riskFormName: null,
                  projectType: null,
                  organizationName: null,
                  auditTeamName: null,
                  personnelName: null,
                  numberOfPeople: null,
                  manday: null,
                  dateStart: null,
                  dateEnd: null,
                  auditTeamCode: null,
                }
              }

              form.setFieldsValue({ ...dataField })
            }}
          />
        </Form.Item>
        <Form.Item name="projectRefName" label={t('แหล่งที่มาของแผน')}>
          <InputNew
            disabled={true}
            placeholder={''}
            suffix={<IconNew className="postion-calendar-input" icon={'ChevronDown'} size={16} />}
          />
        </Form.Item>
        <Form.Item name="riskFormName" label={t('แบบประเมินความเสี่ยง')}>
          <InputNew
            disabled={true}
            placeholder={''}
            suffix={<IconNew className="postion-calendar-input" icon={'ChevronDown'} size={16} />}
          />
        </Form.Item>
        <Form.Item name="projectType" label={t('ประเภทโครงการ')}>
          <InputNew
            disabled={true}
            placeholder={''}
            suffix={<IconNew className="postion-calendar-input" icon={'ChevronDown'} size={16} />}
          />
        </Form.Item>
        <Form.Item name="organizationName" label={t('หน่วยงานที่รับผิดชอบ')}>
          <InputNew
            disabled={true}
            placeholder={''}
            suffix={<IconNew className="postion-calendar-input" icon={'ChevronDown'} size={16} />}
          />
        </Form.Item>
        <Form.Item name="auditTeamName" label={t('ทีมตรวจสอบ')}>
          <InputNew
            disabled={true}
            placeholder={''}
            suffix={<IconNew className="postion-calendar-input" icon={'ChevronDown'} size={16} />}
          />
        </Form.Item>
        <Form.Item name="personnelName" label={t('ผู้รับผิดชอบหลัก')}>
          <InputNew
            disabled={true}
            placeholder={''}
            suffix={<IconNew className="postion-calendar-input" icon={'ChevronDown'} size={16} />}
          />
        </Form.Item>
        <Form.Item name="numberOfPeople" label={t('จำนวนคน')}>
          <InputNew disabled={true} placeholder={''} />
        </Form.Item>
        <Form.Item name="manday" label={t('จำนวนวันทำงาน')}>
          <InputNew disabled={true} placeholder={''} />
        </Form.Item>
        <Form.Item name="dateStart" label={t('วันที่เริ่มต้น')}>
          <InputNew
            className="height"
            disabled={true}
            placeholder={''}
            suffix={<IconNew className="postion-calendar-input" icon={'Calendar'} size={16} />}
          />
        </Form.Item>
        <Form.Item name="dateEnd" label={t('วันที่สิ้นสุด')}>
          <InputNew
            className="height"
            disabled={true}
            placeholder={''}
            suffix={<IconNew className="postion-calendar-input" icon={'Calendar'} size={16} />}
          />
        </Form.Item>
        <Form.Item name="dateJust" label={t('เพียงวันที่')}>
          <Datepicker
            disabled={!editable}
            selectsStart={true}
            setValueDateFn={(data) => {
              form.setFieldsValue({ dateJust: data })
            }}
            onClear={() => {
              form.setFieldsValue({ dateJust: null })
            }}
          />
        </Form.Item>
        <Form.Item
          name="approver"
          label={t('ผู้อนุมัติแผนปฎิบัติงาน')}
          rules={[{ required: true, message: t('กรุณาระบุ') }]}
        >
          <SelectPersonnels
            placeholder={t('เลือกผู้อนุมัติแผนปฎิบัติงาน')}
            formname={formName}
            filters={{ levelCode: 7 }}
            disabled={!editable}
            handleChange={(e) => form.setFieldsValue({ approver: e?.value })}
          />
        </Form.Item>
        <Form.Item name={'isActive'} label={t('สถานะ')} initialValue={1}>
          <RadioGroup disabled={!editable}>
            <Row>
              <Col style={{ marginRight: 20 }}>
                <Radio value={1}>{t('ใช้งาน')}</Radio>
              </Col>
              <Col>
                <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
              </Col>
            </Row>
          </RadioGroup>
        </Form.Item>
        <Row gutter={[12, 12]} justify="center" style={{ marginBottom: 20, marginTop: 40 }}>
          {editable && (
            <Col>
              <ButtonNew type="primary" htmlType="submit" roles={{ type: type_action, menu: menu }}>
                {t('บันทึก')}
              </ButtonNew>
            </Col>
          )}
          <Col>
            <ButtonNew onClick={() => navigate(backPath ? backPath : `/auditoperations/actionplan/`)} type="back">
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
    </React.Fragment>
  )
}

export default ActionPlanFormDetail
