import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'

import { CardNew } from '../../../components/card/Card'
import { TabNew } from '../../../components/tab/Tab'

import ActionPlanFormDetail from './tab1/ActionPlanFormDetail'
import ActionPlanFormStepList from './tab2/ActionPlanFormStepList'
import AuditJobStepList from './tab3/AuditJobStepList'

const ActionPlanFormIndex = ({ menu }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { state } = useLocation()

  if (!state) {
    return <Navigate to={'/auditoperations/actionplan'} />
  }
  const {
    refCode = null,
    type_action = 'view',
    defaultActiveKey = '1',
    auditTeamCode: teamCode = null,
    backPath = null,
    projectCode = null,
  } = state

  const [typeActionMain, settypeActionMain] = useState()
  const [actionPlanId, setactionPlanId] = useState()
  const [auditTeamCode, setauditTeamCode] = useState()
  const [activeKey, setActiveKey] = useState()
  const [auditProjectCode, setauditProjectCode] = useState()
  const [stateBackPath, setStateBackPath] = useState()

  useEffect(() => {
    settypeActionMain(type_action)
    setactionPlanId(refCode)
    setauditTeamCode(teamCode)
    setActiveKey(defaultActiveKey)
    setauditProjectCode(projectCode)
    setStateBackPath(backPath)
  }, [state])

  const arrCrumb = [
    { label: t('งานปฎิบัติการตรวจสอบ'), path: '/auditoperations/actionplan' },
    { label: t('แผนการปฎิบัติงาน'), path: '/auditoperations/actionplan' },
    { label: t('รายละเอียดแผนปฏิบัติงาน') },
  ]
  const [tabs, setTabs] = useState([
    // Import components content
    {
      key: 1,
      name: t('รายละเอียดแผนปฏิบัติงาน'),
      disabled: false,
      content: () => {
        return (
          <ActionPlanFormDetail
            activeKey={activeKey}
            menu={menu}
            type_action={typeActionMain}
            actionPlanId={actionPlanId}
            setauditProjectCode={setauditProjectCode}
            setauditTeamCode={setauditTeamCode}
            auditProjectCode={auditProjectCode}
            backPath={stateBackPath}
          />
        )
      },
    },
    {
      key: 2,
      name: t('จัดการขั้นตอนการปฏิบัติงาน'),
      disabled: !actionPlanId,
      content: () => (
        <ActionPlanFormStepList
          activeKey={activeKey}
          menu={menu}
          typeActionMain={typeActionMain}
          actionPlanId={actionPlanId}
          auditTeamCode={auditTeamCode}
          projectCode={auditProjectCode}
          backPath={stateBackPath}
        />
      ),
    },
    {
      key: 3,
      name: t('ตารางขั้นตอนการปฏิบัติงาน'),
      disabled: !actionPlanId,
      content: () => (
        <AuditJobStepList activeKey={activeKey} menu={menu} actionPlanId={actionPlanId} backPath={stateBackPath} />
      ),
    },
  ])

  useEffect(() => {
    setTabs([
      {
        key: 1,
        name: t('รายละเอียดแผนปฏิบัติงาน'),
        disabled: false,
        content: () => {
          return (
            <ActionPlanFormDetail
              activeKey={activeKey}
              menu={menu}
              type_action={typeActionMain}
              actionPlanId={actionPlanId}
              setauditProjectCode={setauditProjectCode}
              auditProjectCode={auditProjectCode}
              backPath={stateBackPath}
            />
          )
        },
      },
      {
        key: 2,
        name: t('จัดการขั้นตอนการปฏิบัติงาน'),
        disabled: !actionPlanId,
        content: () => (
          <ActionPlanFormStepList
            activeKey={activeKey}
            menu={menu}
            typeActionMain={typeActionMain}
            actionPlanId={actionPlanId}
            auditTeamCode={auditTeamCode}
            projectCode={auditProjectCode}
            backPath={stateBackPath}
          />
        ),
      },
      {
        key: 3,
        name: t('ตารางขั้นตอนการปฏิบัติงาน'),
        disabled: !actionPlanId,
        content: () => (
          <AuditJobStepList activeKey={activeKey} menu={menu} actionPlanId={actionPlanId} backPath={stateBackPath} />
        ),
      },
    ])
  }, [activeKey, typeActionMain, actionPlanId])

  return (
    <React.Fragment>
      <BreadcrumbNew data={arrCrumb} title={t('รายละเอียดแผนปฏิบัติงาน')} />
      <CardNew topic={t('รายละเอียดแผนปฏิบัติงาน')} icon="List">
        <TabNew
          tab={tabs}
          defaultActiveKey={defaultActiveKey}
          onTabClick={(key) => {
            setActiveKey(key)
            if (key === '1') {
              navigate('/auditoperations/actionplan/form', {
                state: {
                  refCode: actionPlanId,
                  type_action: typeActionMain,
                  defaultActiveKey: '1',
                  auditTeamCode: auditTeamCode,
                  projectCode: auditProjectCode,
                  backPath: stateBackPath,
                },
              })
            }
            if (key === '2') {
              navigate('/auditoperations/actionplan/form', {
                state: {
                  refCode: actionPlanId,
                  type_action: typeActionMain,
                  defaultActiveKey: '2',
                  auditTeamCode: auditTeamCode,
                  projectCode: auditProjectCode,
                  backPath: stateBackPath,
                },
              })
            }
            if (key === '3') {
              navigate('/auditoperations/actionplan/form', {
                state: {
                  refCode: actionPlanId,
                  type_action: typeActionMain,
                  defaultActiveKey: '3',
                  auditTeamCode: auditTeamCode,
                  projectCode: auditProjectCode,
                  backPath: stateBackPath,
                },
              })
            }
          }}
        />
      </CardNew>
    </React.Fragment>
  )
}

export default ActionPlanFormIndex
