import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { ButtonNew } from '../../../../components/button/Button'
import { Datatable } from '../../../../components/datatable/Datatable'
import { InputNew } from '../../../../components/input/Input'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { Alert } from '../../../../components/alert/Alert'
import { UploadNew } from '../../../../components/upload/Upload'
import { downloadFile } from '../../../../utilitys/files'
import { SAVE_AUDIT_RESULT_FILE } from '../graphql/Mutation'
import { GET_AUDIT_ISSUE_FILES } from '../graphql/Query'
import { encryptInput } from '../../../../utilitys/crypto'
import { v4 as uuidv4 } from 'uuid'
import { validateURL, displayError, getSegmentUrl } from '../../../../utilitys/helper'

const AuditResultRefer = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { state } = useLocation()

  const params = getSegmentUrl(4)
  if (params !== 'refer') return <></>

  if (!state) {
    return <Navigate to={'/auditoperations/adhocjob'} />
  }
  // type_action,
  const { issueId, type_actionSub } = state
  const disabled = type_actionSub === 'view' ? true : false
  const [, /* idFile */ setIdFile] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])

  const [dataList, setDataLists] = useState([])
  const [download, setDownload] = useState(false)
  const [deleteting, setDeleteting] = useState(false)
  const [typeActionTab3, setTypeActionTab3] = useState('add')

  // , error: errorGet
  const [cellGet, { data: dataGet, loading: loadingGet, error: errorGet }] = useLazyQuery(GET_AUDIT_ISSUE_FILES)

  const [cellSave, { data: dataSave, loading: loadingSave, error: errorSave }] = useMutation(SAVE_AUDIT_RESULT_FILE)

  // function delete file
  const deleteFile = (fileName) => {
    fetch(process.env.REACT_APP_API_UPLOAD + `/deleteFile/${fileName}`, {
      method: 'GET',
    })
      .then(() => {
        setDeleteting(false)
      })
      .catch(() => {})
  }

  useEffect(() => {
    if (issueId) {
      let variables = encryptInput({ issueId: issueId })
      cellGet({
        variables,
      })
    }
  }, [issueId])

  useEffect(() => {
    if (dataGet) {
      const data = dataGet.getAuditIssueFiles
      setTypeActionTab3('edit')
      setDataLists(
        data.map((item) => {
          const uid = uuidv4()
          const dataJob = {
            key: uid,
            fileId: item.fileId,
            filename: item.filename,
            links: item.links,
            tools: item.refFile,
          }

          return dataJob
        }),
      )
    }
    if (errorGet) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorGet)),
        onOk() {},
      })
    }
  }, [dataGet])

  const ResponsiveOut = {
    md: 24,
    lg: 24,
  }

  const Responsive = {
    md: 24,
    lg: 24,
  }

  const Columns = [
    {
      title: t('ชื่อหลักฐานการตรวจสอบ'),
      dataIndex: 'filename',
      width: '30%',
      render: (item, record) => {
        return type_actionSub === 'edit' || type_actionSub === 'add' ? (
          <div>
            <Form.Item hidden={true} name={[record.key, 'fileId']} initialValue={record.fileId}>
              <InputNew disabled={disabled} />
            </Form.Item>
            <Form.Item
              name={[record.key, 'filename']}
              initialValue={item}
              rules={[{ required: true, message: t('กรุณากรอกชื่อหลักฐานการตรวจสอบ') }]}
            >
              <InputNew
                placeholder={t('ชื่อหลักฐานการตรวจสอบ')}
                disabled={disabled}
                onChange={(e) => {
                  form.setFieldsValue({ [record.key]: { filename: e.target.value } })
                }}
              />
            </Form.Item>
          </div>
        ) : (
          <>{item}</>
        )
      },
    },
    {
      title: t('ลิงค์'),
      dataIndex: 'links',
      width: '40%',
      render: (item, record) => {
        return type_actionSub === 'edit' || type_actionSub === 'add' ? (
          <div>
            <Form.Item name={[record.key, 'links']} initialValue={item}>
              <InputNew
                onChange={(e) => {
                  form.setFieldsValue({ [record.key]: { links: e.target.value } })
                }}
              />
            </Form.Item>
          </div>
        ) : item ? (
          <a href={validateURL(item)} target="_blank" rel="noreferrer" style={{ color: '#2b4990' }}>
            <u>{item}</u>
          </a>
        ) : (
          '-'
        )
      },
    },
    {
      title: t('หลักฐานการตรวจสอบ'),
      dataIndex: 'fileId',
      width: '25%',
      align: 'left',
      render: (item, record, index) => {
        return type_actionSub === 'view' && !item ? (
          '-'
        ) : (
          <div>
            <Form.Item name={[record.key, 'fileId']}>
              <UploadNew
                key={index}
                // setIdFile={(e) => {
                //   onUpload(record, e, index)
                // }}
                setIdFile={setIdFile}
                idFile={[record.fileId] || []}
                typeFile="file"
                inline={true}
                multiRow={true}
                setFileData={(e) => {
                  onUpload(record, e, index)
                }}
                actionType={type_actionSub}
                defaults={[record.fileId] || []}
              />
            </Form.Item>
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '5%',
      render: (item, record) => {
        let listButton = []
        const btnDownlaod = {
          type: 'download',
          onClick: () => {
            item &&
              downloadFile(item.originalname, item.name, () => {
                setDownload(false)
              })
          },
        }

        const btnDelete = {
          type: 'delete',
          onClick: () => {
            setIdFileDelete([...idFileDelete, record.fileId]), onRemove(item?.name, record)
          },
        }

        if (type_actionSub === 'view') {
          if (item) {
            listButton = [...listButton, btnDownlaod]
          }
        } else {
          if (item) {
            listButton = [...listButton, btnDownlaod]
          }
          listButton = [...listButton, btnDelete]
        }

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} icons={listButton} />
          </div>
        )
      },
    },
  ]

  /* ---------------- API ----------- */
  // const [getAdhocJobByCodeFn, dataAdhocJobByCode] = useLazyQuery(GET_JOB_FILES)
  // const [
  //   saveAdhocJobFilesFn,
  //   { loading: loadingSaveAdhocJobFiles, error: errorSaveAdhocJobFiles, data: saveAdhocJobFiles },
  // ] = useMutation(SAVE_ADHOCJOB_FILE)

  /* ---------------- ACTION ----------- */
  const onFinish = async (input) => {
    showConfirm(input)
  }

  const showConfirm = (input) => {
    const fileDetail = Object.values(input).map((item) => {
      return {
        fileId: item.fileId || '',
        name: item.filename,
        links: item.links,
      }
    })

    const listFileId = fileDetail.map((item) => item.fileId).filter((x) => x)

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        cellSave({
          variables: {
            input: encryptInput({
              issueId: issueId,
              files: listFileId.join(),
              fileDetail: JSON.stringify(fileDetail),
              listFileDelete: idFileDelete,
              typeAction: typeActionTab3,
            }),
          },
        })
      },
      onCancel() {},
    })
  }

  const handleAdd = () => {
    const uid = uuidv4()

    const fields = form.getFieldsValue()
    const result = Object.values(fields) // แปลง Objects เป็น Array แล้วค่อย map
    result.push({
      key: uid,
      fileId: null,
      filename: null,
      links: null,
    })
    form.setFieldsValue(result)

    let newData = [...dataList]
    newData.push({
      key: uid,
      fileId: null,
      filename: null,
      links: null,
    }) //ต้องเพิ่มตาม field

    setDataLists(newData)
  }

  const onUpload = (record, value, index) => {
    const data = [...dataList]
    data[index].fileId = value.uid
    data[index].tools = { originalname: value.originalname, name: value.name }

    setDataLists([...data])
    setIdFile(value.uid)
    form.setFieldsValue({ [record.key]: { fileId: value.uid } })
  }

  const onRemove = (filename, record) => {
    let newData = dataList.filter((item) => item.key !== record.key)
    setDataLists(newData)

    if (typeActionTab3 === 'add') {
      setDeleteting(true)
      if (filename) deleteFile(filename)
    }
  }

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (dataSave) {
      if (dataSave.auditResultFileSave.issueId) {
        Alert({
          type: 'success',
          title: t('บันทึกสำเร็จ'),
          onOk() {
            setTypeActionTab3('edit')
          },
        })
      }
    }
    if (errorSave) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถบันทึกข้อมูลได้`),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [dataSave])

  return (
    <>
      {(download || deleteting || loadingSave || loadingGet) && <SpinnersNew />}
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ md: { span: 24 }, lg: { span: 24 } }}
        wrapperCol={{ md: { span: 24 }, lg: { span: 24 } }}
        name="formtab1"
      >
        <Row justify="center">
          <Col {...ResponsiveOut}>
            <Row justify="center">
              <Col {...Responsive}>
                <Datatable
                  // enableScroll={{ x: true, y: false }}
                  // scroll={{ x: 1000 }}
                  columns={Columns}
                  data={dataList}
                  pagination={false}
                />
              </Col>
            </Row>
            <Row style={{ marginTop: '5px', marginBottom: '10px' }}>
              {type_actionSub !== 'view' && (
                <Col {...Responsive}>
                  <ButtonNew type="addMultiple" onClick={() => handleAdd()}>
                    {t('เพิ่มหลักฐานการตรวจสอบ')}
                  </ButtonNew>
                </Col>
              )}
            </Row>
            <Row gutter={[12, 20]} justify="center" style={{ marginTop: '15px' }}>
              {type_actionSub !== 'view' && (
                <Col>
                  <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                    {t('บันทึก')}
                  </ButtonNew>
                </Col>
              )}
              <Col>
                <ButtonNew
                  type="back"
                  onClick={() =>
                    navigate(`/auditoperations/auditresult/TabOne`, {
                      state: state,
                    })
                  }
                >
                  {t('ย้อนกลับ')}
                </ButtonNew>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default AuditResultRefer
