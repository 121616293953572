import React from 'react'
import * as _ from 'lodash'
import { Select } from '../../select/Select'
import { useTranslation } from 'react-i18next'

const SelectRiskTypes = (props) => {
  const { placeholder, formname, handleChange = () => {}, ...otherProp } = props
  const { t } = useTranslation()

  const isData = [
    { label: t('1 : ปัจจัยความเสี่ยงองค์กร'), value: 1 },
    { label: t('2 : ปัจจัยความเสี่ยงกระบวนการ'), value: 2 },
  ]

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectRiskTypes
