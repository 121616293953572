import { gql } from '@apollo/client'
export const GET_SYSTEM_MENUS = gql`
  query GetSystemMenus($roleCode: String) {
    getSystemMenus(roleCode: $roleCode) {
      menuId
      menuCode
      menuNameTH
      menuNameEN
      menuParentCode
      systemCode
      sort
      menuPath
      icon
      isActive
      canView
    }
  }
`

export const GET_SYSTEM_ROLES = gql`
  query GetSystemRoles {
    getSystemRoles {
      roleId
    }
  }
`

export const GET_SYSTEM_ROLE_MENU = gql`
  query GetSystemRoleMenu($input: PermissionInput!) {
    getSystemRoleMenu(input: $input) {
      menuCode
      menuId
      menuNameTH
      menuNameEN
      menuParentCode
      systemCode
      sort
      menuPath
      icon
      isActive
      canView
      canAdd
      canEdit
      canDelete
      candSend
      canExport
      canDuplicate
      canApprove
      dCanView
      dCanAdd
      dCanEdit
      dCanDelete
      dCanSend
      dCanExport
      dCanDuplicate
      dCanApprove
    }
  }
`

export const GET_PERMISSION_PAGINATION = gql`
  query GetPermissionPagination($input: PermissionPaginationInput!) {
    getPermissionPagination(input: $input) {
      result {
        no
        roleId
        roleCode
        roleNameTH
        roleNameEN
        startDate
        expireDate
        remark
        createdBy
        createdAt
        username
      }
      count
      page
      limit
    }
  }
`

export const GET_SYSTEM_ROLES_FILD = gql`
  query GetSystemRolesFind($roleCode: String!) {
    getSystemRolesFind(roleCode: $roleCode) {
      roleId
      roleCode
      roleNameTH
      roleNameEN
      startDate
      expireDate
    }
  }
`
