import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Form, Row, Col, Space } from 'antd'
import * as _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { useLazyQuery, useMutation } from '@apollo/client'
import moment from 'moment'
import { getSegmentUrl } from '../../../utilitys/helper'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { LabelNew } from '../../../components/label/Label'
import { ButtonNew } from '../../../components/button/Button'
import { LabelNew as Label } from '../../../components/label/Label'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
import { Select } from '../../../components/select/Select'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import SelectYear from '../../../components/inputfromapi/selectyear/SelectYear'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { InputNew } from '../../../components/input/Input'
import { Alert } from '../../../components/alert/Alert'
import OrganizationsModal from './components/modal/OrganizationsModal'
import { breadcrumbDetail } from './utils/breadcrumbData'
import { colunmOrganizations } from './utils/assessmentColumn'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import SelectPersonnel from '../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import {
  GETFORMSBYFORMASSESS,
  GETAUDITPROJECTBYFORMASSESS,
  AUDITPROJECTORGANIZATIONSBYFORMASSESS,
  GETFORMASSESSONE,
  GETFORMASSESSORGANIZATIONSBYFORMASSESS,
} from './graphql/Query'
import { FORMASSESSSAVE } from './graphql/Mutation'
import { displayError, displayText } from '../../../utilitys/helper'
import SelectResponsible from './components/select/SelectResponsible.jsx'

export default function ManageAssessmentForm(props) {
  const { menu } = props
  const pageType = getSegmentUrl(4)
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()
  const userData = decryptData(localStorage.getItem('user'))
  const formName = 'manage-assessment-form-detail'
  if (!state && pageType !== 'create') return <Navigate to={'/auditoperations/createassessmentform'} />
  const Responsive = { md: 24, lg: 24 }

  const [editable, setEditable] = useState(true)
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [visible, setVisible] = useState(false)
  const [visibleOperationStatus, setVisibleOperationStatus] = useState('')
  const [visibleFormTypeCode, setVisibleFormTypeCode] = useState('')
  const [dataOgt, setDataOgt] = useState([])
  const [optionsForms, setOptionsForms] = useState([])
  const [optionsProject, setOptionsProject] = useState([])
  const [projectCode, setProjectCode] = useState(null)

  const [getFormsByFormAssessfn, getFormsByFormAssess] = useLazyQuery(GETFORMSBYFORMASSESS)
  const [getAuditProjectByFormAssessfn, getAuditProjectByFormAssess] = useLazyQuery(GETAUDITPROJECTBYFORMASSESS)
  const [getformAssessOrganizationsByformAssessfn, getformAssessOrganizationsByformAssess] = useLazyQuery(
    GETFORMASSESSORGANIZATIONSBYFORMASSESS,
  )
  const [auditProjectOrganizationsByFormAssessfn, auditProjectOrganizationsByFormAssess] = useLazyQuery(
    AUDITPROJECTORGANIZATIONSBYFORMASSESS,
  )
  const [getFormAssessOnefn, getFormAssessOne] = useLazyQuery(GETFORMASSESSONE)
  const [formAssessSavefn, { loading: loadingSaved, error, data: dataSaved }] = useMutation(FORMASSESSSAVE)

  const onDelete = (data) => {
    const manageData = _.filter(dataOgt, function (o) {
      return o.key !== data.key
    })
    const adjustNumRow = _.map(manageData, (o, key) => {
      o['numRow'] = key + 1
      return o
    })
    setDataOgt(adjustNumRow)
  }

  const onFinishForm = (input) => {
    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกแบบประเมิน'),
      onOk: async () => {
        const dataSave = {
          variables: {
            inputData: encryptInput({
              formAssess: {
                formAssessId: input.formAssessId,
                formYear: input.formYear,
                projectCode: input.projectCode,
                formCode: input.formCode,
                personnelCode: input.personnelCode,
                startDate: input.startDate,
                endDate: input.endDate,
                isActive: input.isActive,
              },
              actions: pageType,
              organizations: JSON.stringify({
                data: dataOgt.map((dt) => {
                  return {
                    organizationCode: dt.organizationCode,
                    organizationNameTH: dt.organizationNameTH,
                    organizationNameEN: dt.organizationNameEN,
                    personnelCode: dt.personnelCode,
                  }
                }),
              }),
            }),
          },
        }

        await formAssessSavefn(dataSave)
      },
      onCancel() {
        return null
      },
    })
  }

  const adjustDataOgt = (dt) => {
    const dataReuse = _.map(dt, (d, keys) => {
      d['numRow'] = keys + 1
      return d
    })
    const resUse = _.orderBy(dataReuse, ['numRow'], ['asc'])
    setDataOgt(resUse)
  }

  useEffect(() => {
    if (pageType) {
      pageType === 'create' ? setEditable(true) : pageType === 'edit' ? setEditable(true) : setEditable(false)
    }
    getFormsByFormAssessfn({ variables: { isActive: 1 } })
    getAuditProjectByFormAssessfn({ variables: { inputData: encryptInput({ isActive: 1, approveStatusCode: 20 }) } })

    if (pageType === 'edit' || pageType === 'view') {
      getFormAssessOnefn({ variables: { inputData: encryptInput({ formAssessId: state.refCode }) } })
    } else {
      form.setFieldsValue({ formYear: parseInt(moment(new Date()).format('YYYY')) })
    }
  }, [])

  useEffect(() => {
    if (getFormsByFormAssess.data) {
      setOptionsForms(
        getFormsByFormAssess.data.getFormsByFormAssess.map((dt, key) => {
          return {
            key: key,
            label: displayText(dt.formNameTH, dt.formNameEN),
            value: dt.formCode,
            source: dt,
          }
        }),
      )
    }
    if (getFormsByFormAssess.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getFormsByFormAssess.error)),
        onOk() {},
      })
    }
  }, [getFormsByFormAssess.data])

  useEffect(() => {
    if (getAuditProjectByFormAssess.data) {
      const filterRes = _.filter(getAuditProjectByFormAssess.data.getAuditProjectByFormAssess, function (o) {
        return o.organizationCode === userData.orgCode
      })
      setOptionsProject(
        filterRes.map((dt, key) => {
          return {
            key: key,
            label: dt.projectCode + ': ' + displayText(dt.projectNameTH, dt.projectNameEN),
            value: dt.projectCode,
            source: dt,
          }
        }),
      )
    }
    if (getAuditProjectByFormAssess.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getAuditProjectByFormAssess.error)),
        onOk() {},
      })
    }
  }, [getAuditProjectByFormAssess.data])

  useEffect(() => {
    if (auditProjectOrganizationsByFormAssess.data) {
      let response = auditProjectOrganizationsByFormAssess.data

      const adjustData = _.map(response?.auditProjectOrganizationsByFormAssess, (item) => {
        return {
          key: uuidv4(),
          organizationCode: item.organizationCode,
          organizationName: displayText(item.organizationNameTH, item.organizationNameEN),
          organizationLevelName: displayText(item.organizationLevelNameTH, item.organizationLevelNameEN),
          personnelCode: item.personnelCode,
          personnelName: displayText(item.fullNameTH, item.fullNameEN),
          organizationLevelCode: item.organizationLevelCode,
          deleteId: item.organizationCode,
          organizationNameTH: item.organizationNameTH,
          organizationNameEN: item.organizationNameEN,
        }
      })
      // จะเพิ่มหน่วยรับตรวจก็ต่อเมื่อ ประเภทฟอร์มไม่เป็น 30 (แบบประเมินการปฏิบัติงาน) เท่านั้น
      if (visibleFormTypeCode !== '30') {
        adjustDataOgt(adjustData)
      }
    }
    if (auditProjectOrganizationsByFormAssess.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(auditProjectOrganizationsByFormAssess.error)),
        onOk() {},
      })
    }
  }, [auditProjectOrganizationsByFormAssess.data])

  useEffect(() => {
    if (getformAssessOrganizationsByformAssess.data) {
      let response = getformAssessOrganizationsByformAssess.data
      const adjustData = _.map(response?.getformAssessOrganizationsByformAssess, (item) => {
        return {
          key: uuidv4(),
          organizationCode: item.organizationCode,
          organizationName: displayText(item.organizationNameTH, item.organizationNameEN),
          organizationLevelName: displayText(item.organizationLevelNameTH, item.organizationLevelNameEN),
          personnelCode: item.personnelCode,
          personnelName: displayText(item.fullNameTH, item.fullNameEN),
          organizationLevelCode: item.organizationLevelCode,
          deleteId: item.organizationCode,
          organizationNameTH: item.organizationNameTH,
          organizationNameEN: item.organizationNameEN,
        }
      })
      adjustDataOgt(adjustData)
    }
    if (getformAssessOrganizationsByformAssess.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getformAssessOrganizationsByformAssess.error)),
        onOk() {},
      })
    }
  }, [getformAssessOrganizationsByformAssess.data])

  useEffect(() => {
    if (dataSaved) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/auditoperations/createassessmentform')
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [dataSaved])

  useEffect(() => {
    if (getFormAssessOne?.data) {
      const res = getFormAssessOne?.data?.getFormAssessOne

      setVisibleOperationStatus(res.operationStatusCode)

      if (res.operationStatusCode === '30') setEditable(false)

      setVisibleFormTypeCode(res.formTypeCode)

      form.setFieldsValue({
        personnelCode: res.personnelCode,
        formAssessId: res.formAssessId,
        formYear: res.formYear,
        projectCode: res.projectCode,
        formCode: res.formCode,
        formTypeCode: displayText(res.formTypeNameTH, res.formTypeNameEN),
        startDate: res.startDate,
        endDate: res.endDate,
        isActive: res.isActive,
      })

      // จะหน้าหน่วยรับตรวจก็ต่อเมื่อ ประเภทฟอร์มไม่เป็น 30 (แบบประเมินการปฏิบัติงาน) เท่านั้น
      if (res.operationStatusCode !== '30') {
        getformAssessOrganizationsByformAssessfn({ variables: { formAssessId: Number(res.formAssessId) } })
      }
    }

    if (getFormAssessOne.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getFormAssessOne.error)),
        onOk() {},
      })
    }
  }, [getFormAssessOne?.data])

  return (
    <React.Fragment>
      {(getFormsByFormAssess.loading ||
        getAuditProjectByFormAssess.loading ||
        auditProjectOrganizationsByFormAssess.loading) && <SpinnersNew />}
      {loadingSaved && <SpinnersNew />}
      <Breadcrumb data={breadcrumbDetail} title={t('จัดการแบบประเมิน')} />
      <CardNew
        topic={
          pageType === 'create' ? t('เพิ่มแบบประเมิน') : pageType === 'edit' ? t('แก้ไขแบบประเมิน') : t('แบบประเมิน')
        }
        icon="List"
        toggledrop={'false'}
      >
        <Form
          form={form}
          onFinish={onFinishForm}
          autoComplete="off"
          labelAlign="left"
          labelCol={{ span: 6, offset: 1, md: 8, lg: 8 }}
          wrapperCol={{ md: 8, lg: 8 }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name="formAssessId"
            label={<Label type="tab-header-inactive">{t('รหัส')}</Label>}
            placeholder={t('ระบุรหัส')}
            hidden={true}
          >
            <InputNew placeholder={t('รหัส')} disabled={true} />
          </Form.Item>
          <Form.Item
            name="formYear"
            label={<Label type="tab-header-inactive">{t('ประจำปี')}</Label>}
            rules={[{ required: true, message: t('กรุณาระบุประจำปี') }]}
          >
            <SelectYear
              disabled={visibleOperationStatus === '20' ? true : !editable}
              formname={formName}
              placeholder={t('เลือกประจำปี')}
              handleChange={(e) => {
                form.setFieldsValue({ formYear: e.value })
              }}
            />
          </Form.Item>
          <Form.Item
            name="projectCode"
            label={<Label type="tab-header-inactive">{t('โครงการ')}</Label>}
            placeholder={t('ระบุชื่อวันภาษาไทย')}
            rules={[{ required: true, message: t('กรุณาเลือกโครงการ') }]}
          >
            <Select
              formname={formName}
              data={optionsProject}
              placeholder={t('เลือกโครงการ')}
              scrollableId={formName}
              showSearch
              disabled={visibleOperationStatus === '20' ? true : !editable}
              onChange={(e) => {
                if (e) {
                  auditProjectOrganizationsByFormAssessfn({
                    variables: {
                      projectCode: e,
                    },
                  }),
                    setProjectCode(e)
                }
              }}
              onClear={() => {
                setProjectCode()
                form.resetFields(['responsible'])
              }}
            />
          </Form.Item>
          <Form.Item
            name="formCode"
            label={<Label type="tab-header-inactive">{t('แบบประเมิน')}</Label>}
            placeholder={t('กรุณาเลือกแบบประเมิน')}
            rules={[{ required: true, message: t('กรุณาเลือกแบบประเมิน') }]}
          >
            <Select
              formname={formName}
              data={optionsForms}
              placeholder={t('เลือกแบบประเมิน')}
              scrollableId={formName}
              showSearch
              disabled={visibleOperationStatus === '20' ? true : !editable}
              onChange={(e) => {
                if (e) {
                  const setData = _.filter(optionsForms, function (o) {
                    return o.value === e
                  })
                  form.setFieldsValue({
                    formTypeCode: displayText(
                      setData[0].source.formType.formTypeNameTH,
                      setData[0].source.formType.formTypeNameEN,
                    ),
                  })
                  // จะหน้าหน่วยรับตรวจก็ต่อเมื่อ ประเภทฟอร์มไม่เป็น 30 (แบบประเมินการปฏิบัติงาน) เท่านั้น
                  setVisibleFormTypeCode(setData[0].source.formTypeCode)
                  if (setData[0].source.formTypeCode === '30') {
                    setDataOgt([])
                  } else {
                    form.resetFields(['personnelCode'])
                  }
                }
              }}
              onClear={() => {
                form.resetFields(['formTypeCode', 'personnelCode'])
                setVisibleFormTypeCode()
              }}
            />
          </Form.Item>
          <Form.Item
            name="formTypeCode"
            label={<Label type="tab-header-inactive">{t('ประเภทแบบประเมิน')}</Label>}
            placeholder={t('เลือกประเภทแบบประเมิน')}
          >
            <InputNew placeholder={t('ระบุประเภทแบบประเมิน')} disabled={true} />
          </Form.Item>
          <Form.Item
            name="personnelCode"
            label={<Label type="tab-header-inactive">{t('ผู้ประเมิน')}</Label>}
            rules={[
              {
                required: visibleOperationStatus === '20' || visibleFormTypeCode === '30' ? true : !editable,
                message: t('กรุณาระบุผู้ประเมิน'),
              },
            ]}
          >
            <SelectPersonnel
              disabled={visibleOperationStatus === '20' || visibleFormTypeCode !== '30' ? true : !editable} //mac
              formname={formName}
              placeholder={t('เลือกผู้ประเมิน')}
              handleChange={(e) => {
                form.setFieldsValue({ personnelCode: e.value })
              }}
            />
          </Form.Item>
          <Form.Item
            name="startDate"
            label={<Label type="tab-header-inactive">{t('วันที่เริ่มต้น')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกวันที่เริ่มต้น') }]}
          >
            <Datepicker
              disabled={!editable}
              setValueDateFn={async (data) => {
                await setIsMinDate(data)
                await form.setFieldsValue({ startDate: data })
              }}
              placeholder={t('ระบุวันที่เริ่มต้น')}
              isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
              selectsStart={true}
              onClear={() => form.setFieldsValue({ startDate: null })}
            />
          </Form.Item>
          <Form.Item
            name="endDate"
            label={<Label type="tab-header-inactive">{t('วันที่สิ้นสุด')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกวันที่สิ้นสุด') }]}
          >
            <Datepicker
              setValueDateFn={async (data) => {
                await setIsMaxDate(data)
                await form.setFieldsValue({ endDate: data })
              }}
              placeholder={t('ระบุวันที่สิ้นสุด')}
              isMinDate={isMinDate ? new Date(isMinDate) : null}
              selectsEnd={true}
              onClear={() => form.setFieldsValue({ endDate: null })}
            />
          </Form.Item>
          <Form.Item name="responsible" label={<Label type="tab-header-inactive">{t('ผู้รับผิดชอบ')}</Label>}>
            <SelectResponsible
              multiple={true}
              placeholder={t('ผู้รับผิดชอบ')}
              formname={formName}
              filters={{ projectCode: projectCode || null }}
              disabled={true}
              handleChange={(e) => form.setFieldsValue({ responsible: e })}
              form={form}
            />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
            <RadioGroup disabled={!editable}>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>

                <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
          <LabelNew type="card-title" icon="List">
            {t('หน่วยงานรับตรวจ')}
          </LabelNew>
          <Row gutter={[16, 16]}>
            <Col {...Responsive}>
              <Datatable
                columns={colunmOrganizations({ menu, onDelete, pageType })}
                data={dataOgt}
                isScoll={{ x: true, y: true }}
                paginationCustom={false}
                pagination={true}
                searchCustom={true}
                pageSize={10}
                page={1}
                btnAdd={
                  <>
                    {/* หน้าที่จะเพิ่มหน่วยรับตรวจได้จะต้องเป็น หน้า create หรือ edit แล้ว status ไม่เป็น 30 (ดำเนินการแล้ว) และ ประเภทฟอร์มไม่เป็น 30 (แบบประเมินการปฏิบัติงาน) แล้วเท่านั้น */}
                    {pageType === 'edit' || pageType === 'create' ? (
                      <ButtonNew
                        type="plusTableBorderPrimary"
                        roles={{ type: pageType === 'create' ? 'add' : 'edit', menu: menu }}
                        onClick={() => setVisible(true)}
                      >
                        {t('เพิ่มหน่วยรับตรวจ')}
                      </ButtonNew>
                    ) : (
                      ''
                    )}
                  </>
                }
              />
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center" style={{ marginBottom: 20, marginTop: 20 }}>
            {editable && (
              <Col>
                <ButtonNew type="primary" htmlType="submit" roles={{ type: 'add', menu: menu }}>
                  {t('บันทึก')}
                </ButtonNew>
              </Col>
            )}
            <Col>
              <ButtonNew onClick={() => navigate('/auditoperations/createassessmentform')} type="back">
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </CardNew>
      <OrganizationsModal
        open={visible}
        dataDataFillter={dataOgt}
        objData={(v) => {
          const res = [...dataOgt, ...v]
          adjustDataOgt(res)
        }}
        onClose={(e) => {
          setVisible(e)
        }}
        menu={menu}
      />
    </React.Fragment>
  )
}
