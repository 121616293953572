import { gql } from '@apollo/client'

export const GET_SOFTWARE_VERSION = gql`
  query getSoftwareVersion {
    getSoftwareVersion {
      version
      date
      detail
    }
  }
`
