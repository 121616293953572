import { Trans } from 'react-i18next'
import { displayText } from '../../../../utilitys/helper'
import * as _ from 'lodash'
import { Select } from '../../../../components/select/Select'
import { InputNew } from '../../../../components/input/Input'
import { UploadNew } from '../../../../components/upload/Upload'
import { Form } from 'antd'

export const Columns = ({
  formName,
  idFileDelete,
  setIdFileDelete,
  optionChoiceGroup,
  dataSave,
  onChangeData = () => {},
  editable,
  // form,
  key,
  t,
}) => {
  return [
    {
      title: <Trans>{'คำถาม'}</Trans>,
      dataIndex: 'formQuestName',
      width: '40%',
      className: 'text-nowrap',
      render: (item, record) => {
        return (
          <p>{`${record.formGroupSort}.${record.formSort} ${displayText(
            record.formQuestNameTH,
            record.formQuestNameEN,
          )}`}</p>
        )
      },
    },
    {
      title: <Trans>{'คำตอบ'}</Trans>,
      dataIndex: 'choiceDetailId',
      width: '20%',
      className: 'text-nowrap',
      render: (item, record, index) => {
        const dataOptions = _.filter(optionChoiceGroup, function (o) {
          return record.choiceGroupCode === o.source.choiceGroupCode
        })
        // const defaultOption = _.filter(dataSave.formResultDetails, function (o) {
        //   return record.resultDetailId === o.resultDetailId
        // })
        const dataSaveManage = dataSave
        const fieldName = `choiceDetailId${index}`
        return (
          <>
            {record.choiceTypeCode === '10' ? (
              <Form.Item
                name={[fieldName, key]}
                initialValue={item}
                rules={[{ required: record.formRequired === 1 ? true : false, message: <Trans>จำเป็นต้องตอบ</Trans> }]}
              >
                <Select
                  disabled={!editable}
                  // defaultValue={defaultOption[0].choiceDetailId}
                  data={dataOptions}
                  placeholder={`ระบุคำตอบ`}
                  scrollableId={formName}
                  showSearch
                  onChange={(e) => {
                    const indexChange = _.findIndex(dataSaveManage.formResultDetails, function (o) {
                      return o.resultDetailId === record.resultDetailId
                    })
                    dataSaveManage.formResultDetails[indexChange].choiceDetailId = e
                    onChangeData(dataSaveManage)
                  }}
                />
              </Form.Item>
            ) : (
              ''
              //<InputNew placeholder={'ระบุคำอธิบายเพิ่มเติม...'} maxLength={250} />
            )}
          </>
        )
      },
    },
    {
      title: <Trans>{'คำอธิบายเพิ่มเติม'}</Trans>,
      dataIndex: 'answerDetail',
      width: '20%',
      className: 'text-nowrap',
      render: (item, record, index) => {
        const defaultData = _.filter(dataSave.formResultDetails, function (o) {
          return record.resultDetailId === o.resultDetailId
        })
        const dataSaveManage = dataSave
        const fieldName = `answerDetail${index}`
        return (
          <>
            <Form.Item
              name={[fieldName, key]}
              initialValue={defaultData[0].answerDetail}
              rules={
                record?.formRequired && record.choiceTypeCode === '20'
                  ? [{ required: true, message: <Trans>{'จำเป็นต้องตอบ'}</Trans> }]
                  : []
              }
            >
              <InputNew
                disabled={
                  !editable ? true : record.choiceTypeCode === '20' || record.formQuestType === 1 ? false : true
                }
                placeholder={t('ระบุคำอธิบายเพิ่มเติม...')}
                maxLength={250}
                // defaultValue={defaultData[0].answerDetail}
                onChange={(e) => {
                  const indexChange = _.findIndex(dataSaveManage.formResultDetails, function (o) {
                    return o.resultDetailId === record.resultDetailId
                  })
                  dataSaveManage.formResultDetails[indexChange].answerDetail = e.target.value
                  onChangeData(dataSaveManage)
                }}
              />
            </Form.Item>
          </>
        )
      },
    },
    {
      title: <Trans>{'แนบเอกสาร'}</Trans>,
      dataIndex: 'files',
      width: '20%',
      className: 'text-nowrap',
      render: (item, record) => {
        const dataSaveManage = dataSave
        const indexData = _.findIndex(dataSaveManage.formResultDetails, function (o) {
          return o.resultDetailId === record.resultDetailId
        })
        const idFile = dataSaveManage.formResultDetails[indexData].files
          ? JSON.parse('[' + dataSaveManage.formResultDetails[indexData].files + ']')
          : []
        return (
          <UploadNew
            disabled={!editable}
            type="mutiple"
            setIdFile={(e) => {
              dataSaveManage.formResultDetails[indexData].files = e.join()
              onChangeData(dataSaveManage)
            }}
            idFile={idFile}
            defaults={idFile}
            typeFile="file"
            actionType={editable ? 'edit' : 'view'}
            idFileDelete={idFileDelete}
            setIdFileDelete={setIdFileDelete}
          />
        )
      },
    },
  ]
}
