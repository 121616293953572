import { useLazyQuery } from '@apollo/client'
import { Col, Form, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Status } from '../../../components/status/Status'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay, timeDisplay } from '../../../utilitys/dateHelper'
import { displayError, displayText } from '../../../utilitys/helper'
import { MainTable } from '../auditopens/css/Styles'
import { GET_AUDIT_COMMITEE_EXPORT, GET_AUDIT_COMMITTEE } from './graphql/Query'
import SoiAuditCommitteeFilter from './SoiAuditCommitteeFilter'

const SoiAuditCommitteeIndex = ({ menu }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [formFilter] = Form.useForm()
  const [searchTrigger, setSearchTrigger] = useState(false)
  const [dataList, setDataList] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [
    sort
    // setSort
  ] = useState([])
  const [user] = useDecodeUser()
  const [callData, dataResponse] = useLazyQuery(GET_AUDIT_COMMITTEE)

  const [callExport, { data: dataExport, loading: loadingExport, error: errorExport }] =
    useLazyQuery(GET_AUDIT_COMMITEE_EXPORT)

  const arrCrumb = [
    { label: t('งานปฎิบัติการตรวจสอบ'), path: '/auditoperations/acresolution' },
    { label: t('บันทึกสรุปประเด็นคณะกรรมการตรวจสอบ') }
  ]

  useEffect(() => {
    if (user) {
      callData({
        variables: {
          input: encryptInput({
            organizationCode: user.orgCode,
            filters: formFilter.getFieldsValue(),
            pagination: { page, limit },
            sort: sort
          })
        }
      })
    }
  }, [user?.orgCode, searchTrigger, page, limit])

  useEffect(() => {
    if (dataResponse?.data) {
      const x = dataResponse?.data.getAuditCommittee
      setCount(x.count)
      let i = 1
      const data = x.result.map((x) => {
        return {
          ...x,
          projectRefName: displayText(x.projectRefNameTH, x.projectRefNameEN),
          organizationName: displayText(x.organizationNameTH, x.organizationNameEN),
          auditOrg: x.organizationName,
          projectCode: x.projectCode,
          key: i++
        }
      })

      setDataList(data)
    }
  }, [dataResponse.data])

  useEffect(() => {
    if (dataExport) {
      if (dataExport.auditCommitteeExport.message === 'succcess') {
        window.open(`${dataExport.auditCommitteeExport.fileUrl}`, '_blank')
      } else {
        Alert({
          type: 'error',
          title: t('ไม่มีข้อมูล'),
          onOk() {}
        })
      }
    }

    if (errorExport) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorExport)),
        onOk() {}
      })
    }
  }, [dataExport])

  const columns = [
    {
      title: t('โครงการ'),
      dataIndex: 'projectName',
      sorter: true,
      width: '15%',
      className: 'text-nowrap'
    },
    {
      title: t('แหล่งที่มาโครงการ'),
      dataIndex: 'projectRefName',
      sorter: true,
      width: '15%',
      className: 'text-nowrap'
    },
    {
      title: t('วันที่ประชุม'),
      dataIndex: 'meetingDate',
      width: '10%',
      className: 'text-nowrap',
      render: (item) => dateDisplay(item)
    },
    {
      title: t('เวลาที่ประชุม'),
      dataIndex: 'meetingTimes',
      width: '10%',
      className: 'text-nowrap',
      render: (item) => timeDisplay(item)
    },
    {
      title: t('ครั้งที่ประชุม'),
      dataIndex: 'meetingTime',
      width: '5%',
      className: 'text-nowrap'
    },
    {
      title: t('หน่วยงานที่รับผิดชอบ'),
      dataIndex: 'organizationName',
      sorter: true,
      width: '15%',
      className: 'text-nowrap'
    },
    {
      title: t('หน่วยรับตรวจ'),
      dataIndex: 'auditOrg',
      sorter: true,
      width: '15%',
      className: 'text-nowrap'
    },
    {
      title: t('สถานะ'),
      dataIndex: 'isActive',
      sorter: true,
      width: '15%',
      className: 'text-nowrap',
      render: (record) => {
        return <Status text={record === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />
      }
    },
    {
      title: '',
      width: '10%',
      render: (record) => {
        let list = [
          {
            type: 'download',
            onClick: () => {
              let data = {
                projectCode: record.projectCode,
                lang: localStorage.getItem('lang')
              }
              callExport({
                variables: {
                  input: encryptInput(data)
                }
              })
            }
          },
          {
            type: 'view',
            onClick: () =>
              navigate('view', {
                state: {
                  action: 'view',
                  actionPlanId: record.actionPlanId,
                  projectCode: record.projectCode,
                  agendaId: record.agendaId
                }
              })
          },
          {
            type: 'edit',
            onClick: () =>
              navigate('view', {
                state: {
                  action: 'edit',
                  actionPlanId: record.actionPlanId,
                  projectCode: record.projectCode,
                  agendaId: record.agendaId
                }
              })
          }
        ]
        return <ButtonGroup menu={menu} size={18} icons={list} />
      }
    }
  ]
  return (
    <>
      {(dataResponse.loading || loadingExport) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <BreadcrumbNew data={arrCrumb} title={t('บันทึกสรุปประเด็นคณะกรรมการตรวจสอบ')} menu={menu} />
          <SoiAuditCommitteeFilter
            menu={menu}
            form={formFilter}
            trigger={{ value: searchTrigger, setFn: setSearchTrigger }}
          />
          <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="AlignJustify" toggledrop="false">
            <Row>
              <Col md={24}>
                <MainTable>
                  <Datatable
                    columns={columns}
                    data={dataList}
                    total={count}
                    onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                    paginationCustom={true}
                    enableScroll={{ x: true, y: false }}
                    pageSize={limit}
                    page={page}
                    showSizeChanger={true}
                    searchCustom={true}
                    handleTableChange={() => {}}
                  />
                </MainTable>
              </Col>
            </Row>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default SoiAuditCommitteeIndex
