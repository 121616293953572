import { gql } from '@apollo/client'

export const GET_MASTER_PERSONNEL_POSITIONS = gql`
  query getMasterPersonnelPositions($input: MasterPersonnelPositionsInput!) {
    getMasterPersonnelPositions(data: $input) {
      positionId
      positionCode
      positionNameTH
      positionNameEN
      isActive
    }
  }
`
