import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Form, Space } from 'antd'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Alert } from '../../../../components/alert/Alert'
import { ButtonNew } from '../../../../components/button/Button'
import { LabelNew } from '../../../../components/label/Label'
import { InputNumberNew } from '../../../../components/input/Input'
import { RadioGroup, Radio } from '../../../../components/radio/Radio'
import { MASTER_SET_EMAIL_SAVE } from '../graphql/Mutation'
import { GET_EMAIL_SEND_SETUP_TABTHREE } from '../graphql/Query'
import { SpinnersNew } from '../../../../components/spinners/Spinners.jsx'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayError } from '../../../../utilitys/helper'

const TabThree = (props) => {
  const { t } = useTranslation()
  const { menu } = props
  const [form] = Form.useForm()
  const [setMailId, setSetMailId] = useState()

  const [cellGet, { data: dataGet, loading: loadingGet, error: errorGet }] = useLazyQuery(GET_EMAIL_SEND_SETUP_TABTHREE)
  const [cellSave, { data: dataSave, loading: loadingSave, error: errorSave }] = useMutation(MASTER_SET_EMAIL_SAVE)

  useEffect(() => {
    cellGet()
  }, [])

  useEffect(() => {
    if (dataGet) {
      setSetMailId(dataGet.emalSendSetUpTabThree?.setMailId)
      form.setFieldsValue({
        dueDateBefor: dataGet.emalSendSetUpTabThree?.dueDateBefor,
        isActive: dataGet.emalSendSetUpTabThree?.isActive,
      })
    }
    if (errorGet) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorGet)),
        onOk() {},
      })
    }
  }, [dataGet])

  useEffect(() => {
    if (dataSave) {
      if (dataSave.masterSetEmailSave.setMailId) {
        Alert({
          type: 'success',
          title: t('บันทึกข้อมูลสำเร็จ'),
          onOk() {
            setSetMailId(dataSave.masterSetEmailSave.setMailId)
          },
        })
      }
    }

    if (errorSave) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถบันทึกข้อมูลได้`),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [dataSave])

  const onFinish = (values) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        showConfirm(values)
      },
      onCancel() {},
    })
  }

  const showConfirm = async (input) => {
    let data = {}
    if (setMailId) data = { setMailId: setMailId }
    let dataInput = {
      ...data,
      setMailType: 3,
      dueDateBefor: parseInt(input.dueDateBefor),
      isActive: input.isActive,
    }

    await cellSave({
      variables: {
        input: encryptInput(dataInput),
      },
    })
  }

  const onFinishFailed = () => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t('กรุณากรอกข้อมูลให้ครบถ้วน'),
      onOk() {},
    })
  }

  const Responesive = {
    md: 24,
    lg: 24,
  }

  return (
    <>
      {(loadingSave || loadingGet) && <SpinnersNew />}
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelAlign="left"
        labelCol={{
          md: 8,
          lg: 8,
        }}
        wrapperCol={{
          md: 24,
          lg: 14,
          align: 'left',
        }}
        layout="horizontal"
        name="tabThreeForm"
      >
        <Row style={{ marginTop: 24, marginBottom: 24 }} justify="center">
          <Col md={24} lg={14}>
            <Form.Item
              name="dueDateBefor"
              label={<LabelNew type="tab-header-inactive">{t('ระยะเวลาแจ้งเตือนล่วงหน้า')}</LabelNew>}
            >
              <InputNumberNew placeholder={t('ระยะเวลาแจ้งเตือนล่วงหน้า')} />
            </Form.Item>
            <Form.Item
              label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
              name="isActive"
              initialValue={0}
            >
              <RadioGroup>
                <Space size={[8, 8]} wrap>
                  <Radio value={1}>
                    <LabelNew type="tab-header-inactive">{t('ใช้งาน')}</LabelNew>
                  </Radio>
                  &nbsp;
                  <Radio value={0}>
                    <LabelNew type="tab-header-inactive">{t('ไม่ใช้งาน')}</LabelNew>
                  </Radio>
                </Space>
              </RadioGroup>
            </Form.Item>
          </Col>
        </Row>
        <Col {...Responesive} align="center">
          <Space size={[8, 8]} wrap>
            <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
              {t('บันทึก')}
            </ButtonNew>
          </Space>
        </Col>
      </Form>
    </>
  )
}

export default TabThree
