import { gql } from '@apollo/client'

export const UPDATE_CONTROL_RESULT = gql`
  mutation updateControlResult($input: UpdateControlResultInput!) {
    updateControlResult(input: $input) {
      controlResultId
    }
  }
`

export const UPDATE_FINISH_CONTROL_RESULT = gql`
  mutation updateFinishControlResult($input: UpdateFinishControlResultInput!) {
    updateFinishControlResult(input: $input) {
      controlResultId
    }
  }
`

export const FORWARD_CONTROL_RESULT = gql`
  mutation forwardControlResult($input: ForwardControlResultInput!) {
    forwardControlResult(input: $input) {
      controlResultId
    }
  }
`
