import React, { useState, useEffect } from 'react'
import { UploadNew } from '../../../components/upload/Upload'
import { UploadProfileNew } from '../../../components/uploadprofile/UploadProfile'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { Row, Col } from 'antd'
import { UploadExcel } from '../../../components/upload/UploadExcel'

function UploadIndex() {
  let code = `   import React, { useState } from 'react'
   import { UploadNew } from '../../../components/upload/Upload'

    export default function App() {
        const [idFile, setIdFile] = useState([])
        const [idFileDelete, setIdFileDelete] = useState([])
        const [actionType, setActionType] = useState('edit')
        //  type="mutiple" ในกรณีอัพ โหลดหลายๆไฟล์
        //  accept="" เลือก นามสกุลไฟล์ไหนบ้าง เช่น accept=".jpg, .png"
        //  ----- จำเป็นต้องใส่ typeFile ------
        //  typeFile="image" ใช้กับไฟล์รูป
        //  typeFile="file" ใช้กับ file ต่างๆ
        //  -------------------------------
        return (
          <Row>
            <div style={{ marginBottom: '15px' }}>
            <UploadNew
                type="mutiple"
                setIdFile={setIdFile}
                idFile={idFile}
                typeFile="image"
                actionType={actionType}
                idFileDelete={idFileDelete}
                setIdFileDelete={setIdFileDelete}
              />
            </div>
        </Row>
        )
    }
`

  let code2 = `import React from 'react'
  import { UploadExcel } from '../../../components/upload/UploadExcel'

      export default function App() {
          return (
            <Row>
            <UploadExcel
            dataFiles={(e) => {
              console.log('eeee----->', e)
            }}
          />
          </Row>
          )
      }
`

  const [idFile, setIdFile] = useState([])
  const [idFileDefault, setIdFileDefault] = useState([])

  const [idFileDelete, setIdFileDelete] = useState([])
  const [actionType /*setActionType*/] = useState('edit')
  // [180, 181]

  useEffect(() => {
    //ตัวอย่างการ Default File Upload
    setIdFileDefault([1839, 1844])
  }, [])

  useEffect(() => {
    console.log('idFile', idFile)
  }, [idFile])

  //---------------------------------------------------------------------------------------
  const [fileProfile, setFileProfile] = useState([])

  useEffect(() => {
    //ตัวอย่าง default รูปโปรไฟล์
    setFileProfile(['16474448983007692.png'])
  }, [])

  useEffect(() => {
    console.log('idFile', idFile)
  }, [idFile])

  return (
    <React.Fragment>
      <div className="site-layout-background">
        <Row justify="center">
          <Col span={6}>
            <UploadProfileNew setIdFile={setFileProfile} idFile={fileProfile} />
          </Col>
        </Row>

        <hr />

        <Row>
          <Col span={24}>
            <div style={{ marginBottom: '15px' }}>
              {/* type="mutiple" */}
              {/* typeFile="image" */}
              {/* typeFile="file" */}

              <UploadNew
                type="mutiple"
                typeFile="file"
                setIdFile={setIdFile}
                idFile={idFile}
                defaults={idFileDefault}
                actionType={actionType}
                idFileDelete={idFileDelete}
                setIdFileDelete={setIdFileDelete}
                // disabled
                // inline
              />
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <CodeBlock code={code} />
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col>
            <h1>นำเข้าข้อมูล Excel</h1>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div style={{ marginBottom: '15px' }}>
              {/* type="mutiple" */}
              {/* typeFile="image" */}
              {/* typeFile="file" */}

              <UploadExcel
                dataFiles={(e) => {
                  console.log('eeee----->', e)
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <CodeBlock code={code2} />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

export default UploadIndex
