import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_ACTIONPLAN_PERSONNEL_BY_PROJECTCODE } from '../../graphql/Query'
import { Select } from '../../../../../components/select/Select.jsx'
import { useLanguage } from '../../../../../hooks/useLanguage'
import { displayText } from '../../../../../utilitys/helper'
import { encryptInput } from '../../../../../utilitys/crypto'

const SelectResponsible = (props) => {
  const { filters, placeholder, formname, handleChange, form, ...otherProp } = props

  const [isData, isSetData] = useState([])
  const [isLang] = useLanguage()
  const { projectCode } = filters || {}
  const [getResponsible, getResponsibleData] = useLazyQuery(GET_ACTIONPLAN_PERSONNEL_BY_PROJECTCODE)

  useEffect(() => {
    if (projectCode) {
      getResponsible({
        variables: { projectCode: encryptInput(projectCode) },
      })
    }
  }, [projectCode])

  useEffect(() => {
    if (getResponsibleData.data) {
      const res = _.uniqBy(getResponsibleData?.data?.getActionPlanPersonnelOfProject, (item) => item.personnelCode)
      const defaultResponsible = []
      isSetData(
        _.map(res, (item) => {
          defaultResponsible.push(item?.personnelCode)
          return {
            value: item?.personnelCode,
            label: displayText(item.fullNameTH, item.fullNameEN),
            source: item,
          }
        }),
      )
      form.setFieldsValue({ responsible: defaultResponsible })
    }
  }, [getResponsibleData.data, isLang])

  return (
    <>
      <Select
        {...otherProp}
        multiple={props.multiple ? true : false}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getResponsibleData.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          return handleChange(value)
        }}
      />
    </>
  )
}

export default SelectResponsible
