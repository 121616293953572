import { gql } from '@apollo/client'

export const GET_ASSIGN_JOB_PERSONNELS = gql`
  query getAssignJobPersonnels {
    getAssignJobPersonnels {
      personnelCode
      personnelName
    }
  }
`
