import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { Status } from '../../../../../components/status/Status'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { AuditProjectNotPlanColumn } from '../../utils/AuditProjectNotPlanColumn'
import { GETAUDITPROJECTLIST } from '../../graphql/Query'
import { APPROVEPROJECTNOTPLANSAVE } from '../../graphql/Mutation'
import { AlertconfirmNotApprove } from '../../conponent/fnAlertconfirmNotApprove'
import { useNavigate } from 'react-router-dom'
import { checkLanguageReturnData } from '../../conponent/fnAuditProjectNotPlan'
import { dateDisplay } from '../../../../../utilitys/dateHelper'
import { encryptInput } from '../../../../../utilitys/crypto'
import { useLanguage } from '../../../../../hooks/useLanguage'
import { ButtonNew as Button } from '../../../../../components/button/Button'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { Alert } from '../../../../../components/alert/Alert'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'
import { displayError } from '../../../../../utilitys/helper'

export default function TabApprove(props) {
  const { menu, filters } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [isLang] = useLanguage()
  const [userData] = useDecodeUser()
  const Responsive = {
    md: 24,
    lg: 24,
  }

  const [getAuditProjectfn, getAuditProject] = useLazyQuery(GETAUDITPROJECTLIST)
  const [approveProjectNotPlanSaveFn, { loading: loadingUpdated, error, data: dataUpdated }] =
    useMutation(APPROVEPROJECTNOTPLANSAVE)
  const [getDataTable, setDataTable] = useState([])
  const [getSelectData, setSelectData] = useState({ projectCode: [] })

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectData({
        projectCode: selectedRows,
      })
    },
    getCheckboxProps: (record) => {
      return {
        disabled: String(record.approveStatusCode) !== '10' ? true : record.name === 'Disabled User',
        name: record.name,
      }
    },
  }

  const btnAlertNotSelect = () => {
    Alert({
      type: 'error',
      title: t(`กรุณาเลือกรายการโครงการตรวจสอบ`),
      onOk() {},
    })
  }

  const btnConfirmApprove = () => {
    const dataApprove = encryptInput({
      approveStatusCode: '20',
      cancelRemarkTH: '',
      cancelRemarkEN: '',
      approvePersonnelCode: userData.pCode,
      projectCode: getSelectData.projectCode.map((ap) => {
        return ap.projectCode
      }),
    })
    if (getSelectData.projectCode.length > 0) {
      Alert({
        type: 'confirm',
        title: t('กดยืนยันเพื่อทำการอนุมัติรายการที่เลือก'),
        onOk: async () => {
          await approveProjectNotPlanSaveFn({
            variables: {
              inputData: dataApprove,
            },
            fetchPolicy: 'no-cache',
          })
        },
        onCancel() {
          return null
        },
      })
    } else {
      btnAlertNotSelect()
    }
  }

  const btnConfirmNotApprove = () => {
    if (getSelectData.projectCode.length > 0) {
      AlertconfirmNotApprove({
        type: 'confirm',
        title: t('ระบุเหตุผลการไม่อนุมัติแผนปฏิบัติงานรายบุคคล'),
        onConfirm: async (value) => {
          if (getSelectData.projectCode.length === 0) {
            Alert({
              type: 'error',
              title: t(`กรุณาระบุเหตุผลการไม่อนุมัติแผนปฏิบัติ`),
              onOk() {},
            })
          } else {
            let dataPost = {}

            dataPost = {
              approveStatusCode: '30',
              cancelRemarkTH: value,
              cancelRemarkEN: value,
              approvePersonnelCode: userData.pCode,
              projectCode: getSelectData.projectCode.map((ap) => {
                return ap.projectCode
              }),
            }

            await approveProjectNotPlanSaveFn({
              variables: {
                inputData: encryptInput(dataPost),
              },
              fetchPolicy: 'no-cache',
            })
          }
        },
        onCancel() {
          return null
        },
      })
    } else {
      btnAlertNotSelect()
    }
  }

  useEffect(() => {
    if (userData) {
      const res = filters
      const dataSearching = {
        auditProjectCode: res.auditProjectCode ? res.auditProjectCode : '',
        auditProjectName: res.auditProjectName ? res.auditProjectName : '',
        auditProjectRefCode: res.auditProjectRefCode ? res.auditProjectRefCode : '',
        organizationCode: res.organizationCode ? res.organizationCode : '',
        auditProjectTeam: res.auditProjectTeam ? res.auditProjectTeam : '',
        auditProjectStatus: res.auditProjectStatus ? res.auditProjectStatus : '',
        cancelStatus: [0],
        personnelCode: userData.pCode,
      }
      getAuditProjectfn({
        variables: {
          inputData: encryptInput(dataSearching),
        },
      })
    }
  }, [filters, userData])

  useEffect(() => {
    if (dataUpdated) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          window.location.reload()
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [dataUpdated])

  useEffect(() => {
    if (getAuditProject.data) {
      setDataTable(
        getAuditProject.data.getAuditProjectList.map((dt, key) => {
          return {
            key: key,
            projectCode: dt.projectCode,
            pjNameCol: dt.projectCode + ' : ' + checkLanguageReturnData(isLang, dt.projectNameTH, dt.projectNameEN),
            pjRefCol: checkLanguageReturnData(isLang, dt.projectRefNameTH, dt.projectRefNameEN),
            pjDateStartCol: dateDisplay(dt.dateStart),
            pjDateEndCol: dateDisplay(dt.dateEnd),
            pjAuditTeamCol: checkLanguageReturnData(isLang, dt.auditTeamNameTH, dt.auditTeamNameEN),
            pjPersonelCol: checkLanguageReturnData(isLang, dt.fullNameTH, dt.fullNameEN),
            pjApproveStatus: <Status key={'status-apdt-1' + key} text={dt.approveStatusNameTH}></Status>,
            approveStatusCode: dt.approveStatusCode,
            pjOptionsCol: (
              <div key={'div-view-apdt-notplan'} style={{ textAlign: 'left' }}>
                <ButtonGroup
                  key={'btnGroup-view-apdt-approve-notplan'}
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => {
                        navigate('/auditplanning/approveoffplanprojects/auditprojectnotplandetial', {
                          state: {
                            refCode: dt.projectCode,
                          },
                        })
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (getAuditProject.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getAuditProject.error)),
        onOk() {},
      })
    }
  }, [getAuditProject.data])

  return (
    <>
      {(loadingUpdated || getAuditProject.loading) && <SpinnersNew />}
      <Row gutter={[16, 16]}>
        <Col {...Responsive}>
          <Datatable
            columns={AuditProjectNotPlanColumn}
            data={getDataTable}
            enableScroll={{ x: true, y: true }}
            rowSelection={{
              type: 'checkbox',
              ...rowSelection,
            }}
            paginationCustom={false}
            pagination={true}
            pageSize={10}
          />
        </Col>
      </Row>
      <Row gutter={[12, 12]} style={{ marginTop: 24, marginBottom: 24 }} align="center">
        <Col>
          <Button
            type="allow"
            roles={{ type: 'approve', menu: menu }}
            onClick={() => {
              rowSelection.length === 0 ? btnAlertNotSelect() : btnConfirmApprove()
            }}
          >
            {t('อนุมัติ')}
          </Button>
        </Col>
        <Col>
          <Button
            type="noAllow"
            roles={{ type: 'approve', menu: menu }}
            onClick={() => {
              rowSelection.length === 0 ? btnAlertNotSelect() : btnConfirmNotApprove()
            }}
          >
            {t('ไม่อนุมัติ')}
          </Button>
        </Col>
      </Row>
    </>
  )
}
