import { gql } from '@apollo/client'

export const GET_SATISFACTION_ASSESSMENT_PAGINATION = gql`
  query getSatisfactionAssessmentPagination($input: SatisfactionAssessmentPaginationInput!) {
    getSatisfactionAssessmentPagination(input: $input) {
      result {
        formResultId
        formAssessId
        formYear
        formCode
        formNameTH
        formNameEN
        startDate
        endDate
        makerStatusCode
        operationStatusCode
        organizationCode
        organizationNameTH
        organizationNameEN
        responsibilityBy
        makerBy
        successedAt
        successedBy
        reviewAt
        reviewBy
        personnelCode
      }
      count
      page
      limit
    }
  }
`

export const GET_SATISFACTION_ASSESSMENT_ONE = gql`
  query getSatisfactionAssessmentOne($input: SatisfactionAssessmentInput!) {
    getSatisfactionAssessmentOne(input: $input) {
      formResultId
      formAssessId
      formCode
      organizationCode
      organizationCode
      organizationNameTH
      organizationNameEN
      operationStatusCode
      responsibilityBy
      makerBy
      makerStatusCode
      successedAt
      successedBy
      reviewAt
      reviewBy
      personnelCode
      formYear
      formNameTH
      formNameEN
      startDate
      endDate
      organizationNameTH2
      organizationNameEN2
    }
  }
`

export const GETGROUPSANDQUESTIONSBY_SATISFACTION_ASSESSMENT = gql`
  query getGroupsAndQuestionsBySatisfactionAssessment($input: SatisfactionAssessmentInput!) {
    getGroupsAndQuestionsBySatisfactionAssessment(input: $input) {
      resultDetailId
      formResultId
      formGroupId
      formCode
      formSort
      formGroupNameTH
      formGroupNameEN
      formQuestId
      formSortByQ
      formQuestNameTH
      formQuestNameEN
      choiceTypeCode
      choiceGroupCode
      formQuestType
      formRequired
      files
      choiceDetailId
      answerDetail
    }
  }
`

export const GETMASTERCHOICEGROUPDETAILBY_SATISFACTION_ASSESSMENT = gql`
  query getMasterChoiceGroupDetailBySatisfactionAssessment($isActive: Float!) {
    getMasterChoiceGroupDetailBySatisfactionAssessment(isActive: $isActive) {
      choiceDetailId
      choiceDetailNameTH
      choiceDetailNameEN
      choiceGroupCode
      score
      isActive
    }
  }
`
