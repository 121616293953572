import { Trans } from 'react-i18next'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'

export const FollowUpReportListCrumb = [
  { label: <Trans>รายงาน</Trans>, path: '/reports/projectstatusreport' },
  { label: <Trans>รายงานการติดตามผลการดำเนินงาน</Trans> },
]

export const OldColumns = (props) => {
  const { setVisibleSendMail, setMailValue, followUpReportExportFn, menu } = props
  return [
    { title: <Trans>ลำดับ</Trans>, dataIndex: 'no', width: '100px', align: 'center', sorter: { multiple: 1 } },
    { title: <Trans>โครงการ</Trans>, dataIndex: 'projectName', width: '500px', align: 'left', sorter: { multiple: 2 } },
    {
      title: <Trans>แหล่งที่มาของโครงการ</Trans>,
      dataIndex: 'projectRef',
      width: '200px',
      sorter: { multiple: 3 },
      align: 'left',
    },
    {
      title: <Trans>วันที่เริ่มต้น</Trans>,
      dataIndex: 'dateStart',
      width: '150px',
      sorter: { multiple: 4 },
      align: 'center',
    },
    {
      title: <Trans>วันที่สิ้นสุด</Trans>,
      dataIndex: 'dateEnd',
      width: '150px',
      align: 'center',
      sorter: { multiple: 5 },
    },
    {
      title: <Trans>หน่วยรับตรวจ</Trans>,
      dataIndex: 'organization',
      width: '200px',
      sorter: { multiple: 6 },
      align: 'left',
    },
    {
      title: <Trans>ผู้รับผิดชอบ</Trans>,
      dataIndex: 'personnel',
      width: '250px',
      sorter: { multiple: 7 },
      align: 'left',
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '100px',
      align: 'center',
      render: (text, data) => {
        let list = [
          {
            type: 'download',
            onClick: () => {
              followUpReportExportFn(data)
            },
          },
          {
            type: 'settings',
            onClick: () => {
              setVisibleSendMail(true)
              setMailValue({ reportName: 'รายงานการติดตามผลการดำเนินงาน', projectCode: data.projectCode })
            },
          },
        ]
        return <ButtonGroup menu={menu} size={18} icons={list} />
      },
    },
  ]
}

export const Columns = () => {
  return [
    {
      title: <Trans>ลำดับ</Trans>,
      dataIndex: 'no',
      width: '100px',
      align: 'center',
      sorter: { multiple: 1 },
      fixed: 'left',
    },
    {
      title: <Trans>วันที่ Export ข้อมูล</Trans>,
      dataIndex: 'exportDate',
      width: '110px',
      align: 'center',
      sorter: { multiple: 2 },
      fixed: 'left',
    },
    {
      title: <Trans>รหัสธนาคาร</Trans>,
      dataIndex: 'bankCode',
      width: '100px',
      align: 'center',
    },
    {
      title: <Trans>ประเภทการตรวจ</Trans>,
      dataIndex: 'projectTypeName',
      width: '150px',
      align: 'left',
      sorter: { multiple: 4 },
    },
    {
      title: <Trans>รหัสโครงการ</Trans>,
      dataIndex: 'projectCode',
      width: '150px',
      align: 'left',
      sorter: { multiple: 5 },
    },
    {
      title: <Trans>ชื่อโครงการงานตรวจสอบ</Trans>,
      dataIndex: 'projectName',
      width: '300px',
      align: 'left',
      sorter: { multiple: 6 },
    },
    {
      title: <Trans>วันที่เปิดตรวจ</Trans>,
      dataIndex: 'dateStart',
      width: '110px',
      align: 'center',
      sorter: { multiple: 7 },
    },
    {
      title: <Trans>วันที่ปิดตรวจ</Trans>,
      dataIndex: 'dateEnd',
      width: '110px',
      align: 'center',
      sorter: { multiple: 8 },
    },
    {
      title: <Trans>รหัสประเด็นการตรวจสอบ</Trans>,
      dataIndex: 'issueId',
      width: '150px',
      align: 'center',
      sorter: { multiple: 9 },
    },
    {
      title: <Trans>ประเด็น</Trans>,
      dataIndex: 'issue',
      width: '150px',
      align: 'left',
      sorter: { multiple: 10 },
    },
    {
      title: <Trans>ประเภทของประเด็น</Trans>,
      dataIndex: 'findingTypeName',
      width: '150px',
      align: 'left',
      sorter: { multiple: 11 },
    },
    {
      title: <Trans>ข้อเสนอแนะ</Trans>,
      dataIndex: 'suggest',
      width: '200px',
      align: 'left',
      // sorter: { multiple: 12 },
    },
    {
      title: <Trans>ตอบชี้แจงการแก้ไขจากหน่วยรับตรวจ</Trans>,
      dataIndex: 'planDetail',
      width: '300px',
      align: 'left',
      // sorter: { multiple: 13 },
    },
    {
      title: <Trans>ระดับความเสี่ยง</Trans>,
      dataIndex: 'riskLevelName',
      width: '150px',
      align: 'left',
      sorter: { multiple: 14 },
    },
    {
      title: <Trans>วันที่คาดการณ์ยุติประเด็น</Trans>,
      dataIndex: 'issueDateEnd',
      width: '150px',
      align: 'center',
      sorter: { multiple: 15 },
    },
    {
      title: <Trans>วันที่ติดตามครั้งถัดไป</Trans>,
      dataIndex: 'followDateNext',
      width: '150px',
      align: 'center',
      sorter: { multiple: 16 },
    },
    {
      title: <Trans>ความคืบหน้าการแก้ไขประเด็น</Trans>,
      dataIndex: 'actionDetail',
      width: '200px',
      align: 'left',
      // sorter: { multiple: 17 },
    },
    {
      title: <Trans>สถานะการแก้ไข</Trans>,
      dataIndex: 'actionStatusName',
      width: '150px',
      align: 'left',
      sorter: { multiple: 18 },
    },
    {
      title: <Trans>วันที่ยุติประเด็น</Trans>,
      dataIndex: 'issueCloseDate',
      width: '150px',
      align: 'center',
      sorter: { multiple: 19 },
    },
    // {
    //   title: '',
    //   dataIndex: 'tools',
    //   width: '100px',
    //   align: 'center',
    //   render: (text, data) => {
    //     let list = [
    //       {
    //         type: 'download',
    //         onClick: () => {
    //           followUpReportExportFn(data)
    //         },
    //       },
    //       {
    //         type: 'settings',
    //         onClick: () => {
    //           setVisibleSendMail(true)
    //           setMailValue({ reportName: 'รายงานการติดตามผลการดำเนินงาน', projectCode: data.projectCode })
    //         },
    //       },
    //     ]
    //     return <ButtonGroup menu={menu} size={18} icons={list} />
    //   },
    // },
  ]
}
