import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col, Row, Form } from 'antd'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { assessListCrumb } from './utils/constants'
import { displayText, displayError } from '../../../utilitys/helper'
import { yearDisplay, dateDisplay } from '../../../utilitys/dateHelper'
import { encryptInput } from '../../../utilitys/crypto'
import { colors } from '../../../configs/styles.config'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Datatable } from '../../../components/datatable/Datatable'
import { TabNew } from '../../../components/tab/Tab'
import { InputNew } from '../../../components/input/Input'
import { ButtonNew } from '../../../components/button/Button'
import { UploadNew } from '../../../components/upload/Upload'
import { Alert } from '../../../components/alert/Alert'
import { Select } from '../../../components/select/Select'
import { SpanStyle } from './css/Styles'
import { UPDATE_CONTROL_RESULT, UPDATE_FINISH_CONTROL_RESULT } from './graphql/Mutation'
import {
  GET_CONTROL_RESULT_ID,
  GET_CONTROL_FORM_CONF,
  GET_CONTROL_FORM_QUESTION,
  GET_MASTER_CHOICE_GROUP_DETAILS,
} from './graphql/Query'

/**
 * @function AssessEdit
 * @description
 * Menu: Assess
 * เมนู: ประเมินควบคุมภายใน
 * @returns Component
 */

const ControlResultDetail = (props) => {
  const responsiveLabel = { sm: 10, md: 6, lg: 6 }
  const responsiveData = { sm: 14, md: 18, lg: 18 }

  const { state } = useLocation()
  const { t } = useTranslation()

  const [data, setData] = useState(null)

  const [getControlResultsIdFn, { loading: loadingControlResultById, data: dataResult }] =
    useLazyQuery(GET_CONTROL_RESULT_ID)

  useEffect(() => {
    if (state?.controlResultId) {
      getControlResultsIdFn({ variables: { controlResultId: encryptInput(state?.controlResultId) } })
    }
  }, [state.controlResultId])

  useEffect(() => {
    if (dataResult) {
      const result = dataResult?.getControlResultById

      const fullName = result?.makerBy
        ? displayText(
            `${result.n2_namePrefixTH}${result.p2_firstNameTH} ${result.p2_lastNameTH}`,
            `${result.n2_namePrefixEN}${result.p2_firstNameEN} ${result.p2_lastNameEN}`,
          )
        : displayText(
            `${result.n1_namePrefixTH}${result.p1_firstNameTH} ${result.p1_lastNameTH}`,
            `${result.n1_namePrefixEN}${result.p1_firstNameEN} ${result.p1_lastNameEN}`,
          )

      setData({
        controlFormYear: result.controlFormYear,
        controlFormCode: result.controlFormCode,
        controlFormName: displayText(result.controlFormTH, result.controlFormEN),
        organizationName: displayText(result.organizationNameTH, result.organizationNameEN),
        fullName: fullName,
        dateStart: dateDisplay(result?.dateStart),
        dateEnd: dateDisplay(result?.dateEnd),
      })
      props.getControlResult(result)
    }
  }, [dataResult])

  return (
    <>
      {loadingControlResultById && <SpinnersNew />}
      <CardNew topic={t('รายละเอียดการประเมิน')} icon="Info" toggledrop={'false'}>
        <Col span={24} offset={2}>
          <Row gutter={[16, 24]}>
            <Col {...responsiveLabel}>{t('ประจำปี')}</Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              {data?.controlFormYear ? yearDisplay(data?.controlFormYear) : ''}
            </Col>

            <Col {...responsiveLabel}>{t('ชื่อแบบประเมิน')}</Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              {data?.controlFormCode && data?.controlFormName && `${data?.controlFormCode}: ${data?.controlFormName}`}
            </Col>

            <Col {...responsiveLabel}>{t('หน่วยงานรับตรวจ')}</Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              {data?.organizationName && data?.organizationName}
            </Col>

            <Col {...responsiveLabel}>{t('ระยะเวลาที่ประเมิน')}</Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              {data?.dateStart && data?.dateEnd && `${data?.dateStart} - ${data?.dateEnd}`}
            </Col>

            <Col {...responsiveLabel}>{t('ชื่อผู้ทำการประเมิน')}</Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              {data?.fullName && data?.fullName}
            </Col>
          </Row>
        </Col>
      </CardNew>
    </>
  )
}

const AssessEdit = (props) => {
  const formname = 'AssessEditForm'

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()
  const [user] = useDecodeUser()
  const [form] = Form.useForm()

  if (
    !state &&
    !state?.controlResultId &&
    !state?.controlFormCode &&
    (state?.operationStatusCode !== '11' || state?.operationStatusCode !== '21')
  )
    return <Navigate to={'/internalcontrol/assess'} />

  const [controlResult, setControlResult] = useState(null)
  const [inputControlQuest, setInputControlQuest] = useState(null)
  const [inputControlConfTab, setInputControlConfTab] = useState([])
  const [controlForm, setControlForm] = useState([])
  const [dataControlQuest, setDataControlQuest] = useState([])
  const [isQuestId, setQuestId] = useState([])
  const [disabled, setDisabled] = useState(false)
  const [idFileDelete, setIdFileDelete] = useState([])
  const [choiceGroupDetail, setChoiceGroupDetail] = useState([])

  const [getChoiceGroupDetailFn, getChoiceGroupDetail] = useLazyQuery(GET_MASTER_CHOICE_GROUP_DETAILS)
  const [getControlFormConfFn, getControlFormConf] = useLazyQuery(GET_CONTROL_FORM_CONF)
  const [getControlFomQuestionFn, getControlFomQuestion] = useLazyQuery(GET_CONTROL_FORM_QUESTION)
  const [updateControlResultFn, updateControlResult] = useMutation(UPDATE_CONTROL_RESULT)
  const [updateFinishControlResultFn, updateFinishControlResult] = useMutation(UPDATE_FINISH_CONTROL_RESULT)

  useEffect(() => {
    if (state.activeType === 'view') setDisabled(true)
  }, [state.activeType])

  useEffect(() => {
    getChoiceGroupDetailFn({ variables: { input: encryptInput({ isActive: 1 }) } })
  }, [])

  useEffect(() => {
    if (getChoiceGroupDetail?.data) {
      const result = getChoiceGroupDetail?.data?.getMasterChoiceGroupDetails

      const getChoiceGroups = _.map(result, (d) => ({
        label: `${displayText(d.choiceDetailNameTH, d.choiceDetailNameEN)}`,
        value: d.choiceDetailId,
        source: d,
      }))

      setChoiceGroupDetail(getChoiceGroups)
    }

    if (getChoiceGroupDetail.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getChoiceGroupDetail?.error)),
        onOk() {},
      })
    }
  }, [getChoiceGroupDetail?.data])

  useEffect(() => {
    if (controlResult?.controlFormCode) {
      getControlFormConfFn({ variables: { controlFormCode: encryptInput(controlResult?.controlFormCode) } })
    }
  }, [controlResult])

  useEffect(() => {
    if (getControlFormConf?.data?.getControlFormConfigurations) {
      const resultConf = getControlFormConf?.data?.getControlFormConfigurations

      setInputControlQuest({
        formConfigId: _.head(resultConf).formConfigId,
        controlResultId: controlResult.controlResultId,
      })

      setInputControlConfTab(
        _.map(resultConf, (d) => ({
          key: d.formConfigId,
          name: displayText(d.formConfigTH, d.formConfigEN),
          disabled: false,
        })),
      )
    }

    if (getControlFormConf.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getControlFormConf?.error)),
        onOk() {},
      })
    }
  }, [getControlFormConf.data])

  useEffect(() => {
    if (inputControlQuest) {
      getControlFomQuestionFn({ variables: { input: encryptInput(inputControlQuest) } })
    }
  }, [inputControlQuest])

  useEffect(() => {
    if (getControlFomQuestion?.data) {
      const resultQuest = getControlFomQuestion?.data?.getControlFormQuestions

      const setResultDetail = {}
      for (const d of resultQuest) {
        setResultDetail[d.resultDetailId] = {
          questDetailType: d.questDetailType,
          answerDetail: d.answerDetail,
          choiceDetailId: d.choiceDetailId,
          files: d.files ? [parseInt(d.files)] : [],
        }
      }

      setQuestId(_.map(resultQuest, 'resultDetailId'))

      const setDataQuest = _.map(resultQuest, (d) => ({
        key: d.resultDetailId,
        quest: displayText(d.questTH, d.questEN),
        choiceGroupCode: d.choiceGroupCode,
        choiceDetailId: d.choiceDetailId,
        answerDetail: d.answerDetail,
        files: d.files ? [parseInt(d.files)] : [],
        questRequired: d.questRequired,
        choiceTypeCode: d.choiceTypeCode,
        questDetailType: d.questDetailType,
      }))

      const setNewDataQuest = _.uniqBy([...dataControlQuest, ...setDataQuest], 'key')

      form.setFieldsValue(setNewDataQuest.reduce((a, v) => ({ ...a, [v.key]: v }), {}))

      setDataControlQuest(setNewDataQuest)
    }

    if (getControlFomQuestion.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getControlFomQuestion?.error)),
        onOk() {},
      })
    }
  }, [getControlFomQuestion?.data])

  useEffect(() => {
    if (dataControlQuest) {
      const newDataControlQuest = _.map(isQuestId, (d) => _.find(dataControlQuest, (q) => q.key === d))
      setControlForm(
        _.map(inputControlConfTab, (d) => ({
          ...d,
          content: () => <Datatable columns={columns} data={newDataControlQuest} />,
        })),
      )
    }
  }, [inputControlConfTab, isQuestId])

  useEffect(() => {
    if (updateControlResult.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          navigate('/internalcontrol/assess')
        },
      })
    }

    if (updateControlResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(updateControlResult?.error)),
        onOk() {},
      })
    }
  }, [updateControlResult.data])

  useEffect(() => {
    if (updateFinishControlResult.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          navigate('/internalcontrol/assess')
        },
      })
    }

    if (updateFinishControlResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(updateFinishControlResult?.error)),
        onOk() {},
      })
    }
  }, [updateFinishControlResult.data])

  const onTabClick = (e) =>
    setInputControlQuest({ formConfigId: parseInt(e), controlResultId: controlResult.controlResultId })

  const onFinish = (type) => {
    const ans = []
    for (const [key, value] of Object.entries(dataControlQuest)) {
      const keySplit = key.split('_')

      if (keySplit.length === 1) {
        ans.push({ resultDetailId: parseInt(_.head(keySplit)), ...value })
      }
    }

    const validateQuest = _.find(ans, (d) => d.answerDetail || d.choiceDetailId)

    if (!validateQuest) {
      Alert({
        type: 'warning',
        title: t('กรุณากรอกแบบประเมินควบคุมภายใน'),
        onOk() {},
      })
    } else {
      const checkResponsibility = controlResult?.responsibilityBy === user?.pCode ? true : false
      const checkMaker = controlResult?.makerBy === user?.pCode ? true : false

      Alert({
        type: 'confirm',
        title: t('ต้องการยืนยันการบันทึก?'),
        onOk() {
          if (type === 'save') {
            const objData = encryptInput({
              controlResultId: controlResult.controlResultId,
              checkResponsibility: checkResponsibility,
              checkMaker: checkMaker,
              operationStatusCode: controlResult?.operationStatusCode,
              controlResultDetail: _.map(ans, (d) => ({
                questRequired: d.questRequired,
                answerDetail: d.answerDetail,
                choiceTypeCode: d.choiceTypeCode,
                choiceDetailId: d.choiceDetailId ? d.choiceDetailId + '' : null,
                files: d.files,
                resultDetailId: d.key,
              })),
            })
            updateControlResultFn({ fetchPolicy: 'no-cache', variables: { input: { ...objData } } })
          }

          if (type === 'submit') {
            const objData = encryptInput({
              controlResultId: controlResult.controlResultId,
              controlFormCode: controlResult.controlFormCode,
              responsibilityBy: user.pCode,
              checkResponsibility: checkResponsibility,
              checkMaker: checkMaker,
              operationStatusCode: controlResult?.operationStatusCode,
              controlResultDetail: _.map(ans, (d) => ({
                questRequired: d.questRequired,
                answerDetail: d.answerDetail,
                choiceTypeCode: d.choiceTypeCode,
                choiceDetailId: d.choiceDetailId ? d.choiceDetailId + '' : null,
                files: d.files,
                resultDetailId: d.key,
              })),
            })
            updateFinishControlResultFn({ fetchPolicy: 'no-cache', variables: { input: { ...objData } } })
          }
        },
        onCancel() {},
      })
    }
  }

  const columns = [
    {
      title: t('คำถาม'),
      dataIndex: 'quest',
      width: '300px',
      align: 'left',
      render: (text, record, index) => {
        return (
          <label>
            {`${index + 1}. ${record.quest}`}
            {record?.questRequired === 1 && <SpanStyle>*</SpanStyle>}
          </label>
        )
      },
    },
    {
      title: t('คำตอบ'),
      dataIndex: 'choiceDetailId',
      width: '100px',
      align: 'center',
      render: (text, record) => {
        return (
          <Form.Item
            name={[record.key, 'choiceDetailId']}
            rules={
              record?.questRequired && record.choiceTypeCode === '10'
                ? [{ required: true, message: t('กรุณาเลือกคำตอบ') }]
                : []
            }
          >
            <Select
              data={_.filter(choiceGroupDetail, (d) => d.source.choiceGroupCode === record.choiceGroupCode)}
              placeholder={t('เลือกคำตอบ')}
              scrollableId={formname}
              disabled={disabled ? disabled : record.choiceTypeCode === '10' ? false : true}
              showSearch
              loading={getChoiceGroupDetail?.loading}
              filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              onChange={async (e) => {
                await setDataControlQuest((prevResult) =>
                  _.map(prevResult, (d) => (d.key === record.key ? { ...d, choiceDetailId: e } : d)),
                )
                await form.setFieldsValue({ [record.key]: { choiceDetailId: e } })
              }}
            />
          </Form.Item>
        )
      },
    },
    {
      title: t('คำอธิบายเพิ่มเติม'),
      dataIndex: 'answerDetail',
      width: '170px',
      align: 'center',
      render: (text, record) => {
        return (
          <Form.Item
            name={[record.key, 'answerDetail']}
            rules={
              record?.questRequired && record.choiceTypeCode === '20'
                ? [{ required: true, message: t('กรุณาระบุคำอธิบายเพิ่มเติม') }]
                : []
            }
          >
            <InputNew
              placeholder={t('ระบุคำอธิบายเพิ่มเติม')}
              maxLength={255}
              disabled={
                disabled ? disabled : record.choiceTypeCode === '20' ? false : record.questDetailType ? false : true
              }
              // disabled={disabled ? disabled : record.choiceTypeCode === '20' ? false : true}
              // disabled={disabled ? disabled : false}
              onChange={async (e) => {
                await setDataControlQuest((prevResult) =>
                  _.map(prevResult, (d) => (d.key === record.key ? { ...d, answerDetail: e.target.value } : d)),
                )
                await form.setFieldsValue({ [record.key]: { answerDetail: e.target.value } })
              }}
            />
          </Form.Item>
        )
      },
    },
    {
      title: t('แนบเอกสาร'),
      dataIndex: 'files',
      width: '260px',
      align: 'left',
      render: (text, record) => {
        return (
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues[record.key].choiceDetailId !== currentValues[record.key].choiceDetailId
            }
          >
            {() => {
              //const checked = getFieldValue(record.key)
              return (
                <Form.Item name={[record.key, 'files']}>
                  <UploadNew
                    setIdFile={async (e) => {
                      await setDataControlQuest((prevResult) =>
                        _.map(prevResult, (d) => (d.key === record.key ? { ...d, files: e } : d)),
                      )
                      await form.setFieldsValue({ [record.key]: { files: e } })
                    }}
                    actionType={'edit'}
                    idFile={record?.files ? record.files : []}
                    typeFile="file"
                    inline={true}
                    orderLabel={true}
                    disabled={disabled}
                    idFileDelete={idFileDelete}
                    defaults={record?.files ? record.files : []}
                    setIdFileDelete={setIdFileDelete}
                  />
                </Form.Item>
              )
            }}
          </Form.Item>
        )
      },
    },
  ]

  return (
    <Col sm={24} md={24} lg={24}>
      {(getControlFomQuestion?.loading || updateControlResult?.loading) && <SpinnersNew />}
      <Breadcrumb data={assessListCrumb} title={t('ประเมินควบคุมภายใน')} />
      <ControlResultDetail getControlResult={(d) => setControlResult(d)} />
      <CardNew topic={t('รายการคำถามแบบประเมินควบคุมภายใน')} icon="List" toggledrop={'false'}>
        <Row gutter={[12, 12]}>
          <Col sm={24} md={24} lg={24} xl={24}>
            <Form name={formname} form={form} layout="horizontal">
              <TabNew tab={controlForm} onTabClick={onTabClick} />
              {!disabled ? (
                <Row gutter={[12, 12]} justify="center" style={{ marginTop: '2rem' }}>
                  {controlResult?.operationStatusCode === '22' ? null : (
                    <Col>
                      <ButtonNew
                        type="primary"
                        onClick={() => form.validateFields().then(() => onFinish('save'))}
                        roles={{ type: 'edit', menu: props.menu }}
                      >
                        {t('บันทึก')}
                      </ButtonNew>
                    </Col>
                  )}
                  <Col>
                    <ButtonNew
                      type="success"
                      onClick={() => form.validateFields().then(() => onFinish('submit'))}
                      roles={{ type: 'edit', menu: props.menu }}
                    >
                      {t('เสร็จสิ้น')}
                    </ButtonNew>
                  </Col>
                  <Col>
                    <ButtonNew
                      type="back"
                      onClick={() => Promise.all([form.resetFields(), navigate('/internalcontrol/assess')])}
                    >
                      {t('ยกเลิก')}
                    </ButtonNew>
                  </Col>
                </Row>
              ) : (
                <Row justify="center" style={{ marginTop: '2rem' }}>
                  <Col>
                    <ButtonNew
                      type="back"
                      onClick={() => Promise.all([form.resetFields(), navigate('/internalcontrol/assess')])}
                    >
                      {t('ย้อนกลับ')}
                    </ButtonNew>
                  </Col>
                </Row>
              )}
            </Form>
          </Col>
        </Row>
      </CardNew>
    </Col>
  )
}

AssessEdit.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default AssessEdit
