import { Col, Form, Row } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonNew } from '../../../components/button/Button'
import { CardNew } from '../../../components/card/Card'
import { InputNew } from '../../../components/input/Input'
import { LabelNew } from '../../../components/label/Label'
import { Select } from '../../../components/select/Select'

const OrganizationListFilters = (props) => {
  const { getValue = () => {} } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const formName = 'OrganizationListFilters'

  const Responesive = {
    inputFilter: {
      md: 8,
      lg: 8
    }
  }

  const onFinish = () => {
    const field = form.getFieldValue()

    getValue(field)
  }

  return (
    <CardNew topic={t('Filters')} icon="Filter" toggledrop={'false'}>
      <Row style={{ marginTop: 24 }}>
        <Col sm={24} md={24} lg={24}>
          <Form form={form} onFinish={onFinish} name={formName} layout="vertical">
            <Row gutter={[16, 8]}>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="organizationCode"
                  label={<LabelNew type="tab-header-inactive">{t('รหัสหน่วยงาน')}</LabelNew>}
                >
                  <InputNew placeholder={t('ระบุรหัสหน่วยงาน')} />
                </Form.Item>
              </Col>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="organizationName"
                  label={<LabelNew type="tab-header-inactive">{t('ชื่อหน่วยงาน')}</LabelNew>}
                >
                  <InputNew placeholder={t('ระบุชื่อหน่วยงาน')} />
                </Form.Item>
              </Col>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="organizationType"
                  label={<LabelNew type="tab-header-inactive">{t('ประเภทหน่วยงาน')}</LabelNew>}
                >
                  <Select
                    formname={formName}
                    data={[
                      { label: 'หน่วยงานภายใน', value: 1 },
                      { label: 'หน่วยงานภายนอก', value: 2 }
                    ]}
                    placeholder={t('เลือกประเภทหน่วยงาน')}
                    scrollableId={formName}
                    showSearch
                    onChange={() => {}}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 8]}>
              <Col {...Responesive.inputFilter}>
                <Form.Item
                  name="organizationParentName"
                  label={<LabelNew type="tab-header-inactive">{t('สังกัดหน่วยงาน')}</LabelNew>}
                >
                  <InputNew placeholder={t('ระบุสังกัดหน่วยงาน')} />
                </Form.Item>
              </Col>
              <Col {...Responesive.inputFilter}>
                <Form.Item name="isActive" label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}>
                  <Select
                    formname={formName}
                    data={[
                      { label: 'ใช้งาน', value: 1 },
                      { label: 'ไม่ใช้งาน', value: 0 }
                    ]}
                    placeholder={t('เลือกสถานะ')}
                    scrollableId={formName}
                    showSearch
                    onChange={() => {}}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[12, 12]} justify="center" style={{ marginTop: '2rem' }}>
              <Col>
                <ButtonNew type="search" htmlType="submit">
                  {t('ค้นหา')}
                </ButtonNew>
              </Col>
              <Col>
                <ButtonNew
                  type="clear"
                  onClick={() => {
                    form.resetFields()
                    props.fncReset()
                  }}
                >
                  {t('ล้างค่า')}
                </ButtonNew>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </CardNew>
  )
}

export default OrganizationListFilters
