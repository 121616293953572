import React from 'react'
import { Row, Col } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import RiskFormulaList from './RiskFormulaList'
import { Trans } from 'react-i18next'

/**
 * @function RiskFormulaIndex
 * @description
 * Menu: RiskFormula
 * เมนู: จัดการสูตรประเมินความเสี่ยง
 * @returns Component
 */
const arrCrumb = [
  { label: <Trans>จัดการข้อมูลพื้นฐาน</Trans>, path: '#' },
  { label: <Trans>จัดการสูตรประเมินความเสี่ยง</Trans> },
]
const RiskFormulaIndex = ({ menu }) => {
  const Responsive = {
    md: 24,
    lg: 24,
  }
  return (
    <React.Fragment>
      <Row>
        <Col {...Responsive}>
          <Breadcrumb data={arrCrumb} title={<Trans>จัดการสูตรประเมินความเสี่ยง</Trans>} />
          <RiskFormulaList menu={menu} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default RiskFormulaIndex
