export const multipleRowCode = `
import React from 'react'
import { Form, Input, Button, Space, Card, Row, Col } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
const FormExample = () => {
  const onFinish = (values) => {
    console.log('Received values of form:', values)
  }
  return (
    <>
      <Row style={{ marginTop: 24 }}>
        <Col span={24}>
          <Card title="Form multiple rows" extra={<a href="#">More</a>} style={{ width: '100%' }}>
            <Form name="dynamic_form_nest_item" onFinish={onFinish} autoComplete="off">
              <Form.List name="users">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, 'first']}
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <Input placeholder="First Name" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'last']}
                          rules={[{ required: true, message: 'Missing last name' }]}
                        >
                          <Input placeholder="Last Name" />
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add field
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default FormExample
`
export const FormExampleHorizontal = `
import React, { useState, useEffect } from 'react'
import { Form, Input, Card, Row, Col, Cascader, TreeSelect, Switch, Space } from 'antd'
// import moment from 'moment'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { ButtonNew } from '../../../components/button/Button'
import { InputNew, InputNumberNew } from '../../../components/input/Input'
import { Select } from '../../../components/select/Select'
import { Checkbox } from '../../../components/checkbox/Checkbox'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
import { FormExampleHorizontal } from './text'
const dataSelect = [
  { label: 'Jack', value: '01' },
  { label: 'Lucy', value: '02' },
  { label: 'Adam', value: '03' },
  { label: 'test4', value: '04', disabled: true }, // option disable
  { label: 'test5', value: '05' },
  { label: 'test6', value: '06' },
]
const FormExampleNew = (props) => {
  const formId = props.formId || null // Get id form parent element
  const [formType, setFormType] = useState(formId ? 'UPDATE' : 'CREATE') // Intitial value formType
  const [form] = Form.useForm()
  useEffect(() => {
    // Get value From your API
    if (formType === 'UPDATE') {
      // Set Form value on formType = UPDATE
      form.setFieldsValue({
        textInput: 'Is update',
        select: 'demo',
        datePicker: '2020-08-12',
        treeSelect: {
          value: 'bamboo',
        },
        selectMultiple: ['02', '05'],
        cascader: ['zhejiang', 'hangzhou'],
        inputNumber: 100,
        switch: true,
        checkbox: true,
      })
    } else if (formType === 'CREATE') {
      // Set Form value on formType = CREATE
      form.resetFields()
    }
  }, [formType])
  // onFinish
  const onFinish = (values) => {
    console.log('onFinish:', values)
    setErrorField('datePickerMockUp', false) // Set false error field
  }
  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => {
    console.log('onFinishFailed', errorFields)
    errorFields.map((er) => {
      const nameField = er.name[0] || ''
      const message = er.errors[0] || ''
      if (nameField === 'datePicker') setErrorField('datePickerMockUp', message)
    })
  }
  // onValuesChange
  const onValuesChange = ({ formType }, allValue) => {
    console.log('onValuesChange allValue', allValue)
    setFormType(formType) // CREATE or UPDATE *** intitial value = CREATE
  }

  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  return (
    <>
      <Row style={{ marginTop: 24 }}>
        <Col md={24} lg={24}>
          <Card title={'Form example'} extra={<a href="#">More</a>} style={{ width: '100%' }}>
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
              autoComplete="off"
              labelAlign="left"
              labelCol={{
                sm: 6,
                md: 5,
                xl: 4,
                offset: 6,
              }}
              wrapperCol={{
                sm: 10,
                md: 8,
                xl: 8,
                align: 'left',
              }}
              layout="horizontal"
              name="exampleForm"
            >
              <Form.Item label="Form type" name="formType">
                <RadioGroup>
                  <Radio value="CREATE">CREATE</Radio>
                  <Radio value="UPDATE">UPDATE</Radio>
                </RadioGroup>
              </Form.Item>
              <Form.Item name="textInput" label="Text input" rules={[{ required: true, message: 'required' }]}>
                <InputNew />
              </Form.Item>
              <Form.Item name="select" label="Select" rules={[{ required: true, message: 'required' }]}>
                <Select
                  title=""
                  data={dataSelect}
                  placeholder="default select..."
                  onChange={(val) => console.log(val)}
                  scrollableId="exampleForm"
                />
              </Form.Item>
              {/* <Form.Item name="selectAutocomplete" label="Select Autocomplete" rules={[{ required: true, message: 'required' }]}>
                <Select
                  title=""
                  data={dataSelect}
                  showSearch
                  placeholder="default select..."
                  onChange={(val) => console.log(val)}
                  scrollableId="exampleForm"
                />
              </Form.Item> */}
              <Form.Item
                name="selectMultiple"
                label="Select Multiple"
                rules={[{ required: true, message: 'required' }]}
              >
                <Select
                  title={''}
                  data={dataSelect}
                  placeholder="multiple select..."
                  multiple
                  onChange={(val) => console.log(val)}
                  scrollableId="exampleForm"
                />
              </Form.Item>
              <Form.Item name="treeSelect" label="TreeSelect" rules={[{ required: true, message: 'required' }]}>
                <TreeSelect
                  treeData={[
                    {
                      title: 'Light',
                      value: 'light',
                      children: [
                        {
                          title: 'Bamboo',
                          value: 'bamboo',
                        },
                      ],
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item name="cascader" label="Cascader" rules={[{ required: true, message: 'required' }]}>
                <Cascader
                  options={[
                    {
                      value: 'zhejiang',
                      label: 'Zhejiang',
                      children: [
                        {
                          value: 'hangzhou',
                          label: 'Hangzhou',
                        },
                      ],
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item
                hidden={true}
                label="DatePicker"
                name="datePicker"
                rules={[{ required: true, message: 'required' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="datePickerMockUp"
                label="DatePicker"
                initialValue={'datePickerMockUp'}
                rules={[{ required: true, message: 'required' }]}
              >
                <>
                  <Datepicker
                    language="th"
                    // value={
                    //   formType === 'UPDATE'
                    //     ? moment(new Date('2020-02-01')).format('YYYY-MM-DD')
                    //     : moment(new Date()).format('YYYY-MM-DD')
                    // }
                    // value={'11-02-2022'}
                    setValueDateFn={(data) => {
                      form.setFieldsValue({
                        datePicker: data,
                      })
                      setErrorField('datePickerMockUp', false) // set false error field
                    }}
                  />
                </>
              </Form.Item>
              <Form.Item name="inputNumber" label="InputNumber" rules={[{ required: true, message: 'required' }]}>
                <InputNumberNew min={1} max={10} />
              </Form.Item>
              <Form.Item
                label="Switch"
                name="switch"
                valuePropName="checked"
                rules={[{ required: true, message: 'required' }]}
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="checkbox"
                name="checkbox"
                valuePropName="checked"
                rules={[{ required: true, message: 'required' }]}
              >
                <Checkbox onChange={(val) => console.log(val)}>Remember me</Checkbox>
              </Form.Item>
              <Form.Item label={<></>}>
                <>
                  <Space size={[8, 8]} wrap>
                    <ButtonNew type="primary" roles={{ type: 'add', menu: 'example' }} htmlType="submit">
                      บันทึก
                    </ButtonNew>
                    &nbsp;
                    <ButtonNew type="back" onClick={() => form.resetFields()}>
                      ยกเลิก
                    </ButtonNew>
                  </Space>
                </>
              </Form.Item>
            </Form>
            <Row>
              <Col md={24}>
                {props.disabledCode ? null : (
                  <>
                    <CodeBlock code={FormExampleHorizontal} />
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FormExampleNew


`

export const FormFilterExample = `
import React from 'react'
import { Form, Input, Card, Row, Col, Space } from 'antd'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { ButtonNew } from '../../../components/button/Button'
import { InputNew, InputNumberNew } from '../../../components/input/Input'
import { Select } from '../../../components/select/Select'
const dataSelect = [
  { label: 'Jack', value: '01' },
  { label: 'Lucy', value: '02' },
  { label: 'Adam', value: '03' },
  { label: 'test4', value: '04', disabled: true }, // option disable
  { label: 'test5', value: '05' },
  { label: 'test6', value: '06' },
]

const Responesive = {
  inputFilter: {
    sm: 24,
    md: 12,
    lg: 8,
  },
  buttonFilter: {
    sm: 24,
    md: 24,
    lg: 24,
  },
}
const FormFiltersExampleNew = (props) => {
  const [form] = Form.useForm()
  // onFinish
  const onFinish = (values) => {
    console.log('onFinish:', values)
    setErrorField('datePickerMockUp', false) // Set false error field
    /*
      // Set Form value 
      form.setFieldsValue({
        textInput: 'Is update',
        select: 'demo',
        select2: 'demo',
        datePicker: '2020-08-12',
        selectMultiple: ['02', '05'],
        inputNumber: 100,
        switch: true,
        checkbox: true,
      })
    
      // Reset Form value 
      form.resetFields()
      */
  }
  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => {
    console.log('onFinishFailed', errorFields)
    errorFields.map((er) => {
      const nameField = er.name[0] || ''
      const message = er.errors[0] || ''
      if (nameField === 'datePicker') setErrorField('datePickerMockUp', message)
    })
  }
  // onValuesChange
  const onValuesChange = (errField, allValue) => {
    console.log('onValuesChange errField', errField)
    console.log('onValuesChange allValue', allValue)
  }

  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  return (
    <>
      <Row style={{ marginTop: 24 }}>
        <Col sm={24} md={24} lg={24}>
          <Card title={'Form Filters Example'}>
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
              name="exampleForm"
            >
              <Row gutter={[16, 8]}>
                <Col {...Responesive.inputFilter}>
                  <label htmlFor="textInput">Example text Input</label>
                  <Form.Item name="textInput" rules={[{ required: true, message: 'required' }]}>
                    <InputNew />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <label htmlFor="searchSelect">Example Search Select</label>
                  <Form.Item name="searchSelect" rules={[{ required: true, message: 'required' }]}>
                    <Select
                      title={''}
                      data={dataSelect}
                      placeholder={'Search select...'}
                      showSearch
                      onChange={(e, { label, value }) => console.log('label ,value', label, value)}
                      scrollableId="exampleForm"
                      filterOption={(input, option) => {
                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <label htmlFor="selectMultiple">Select Multiple</label>
                  <Form.Item name="selectMultiple" rules={[{ required: true, message: 'required' }]}>
                    <Select
                      title={''}
                      data={dataSelect}
                      placeholder="multiple select..."
                      multiple
                      onChange={(val) => console.log(val)}
                      scrollableId="exampleForm"
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <label htmlFor="textInput">DatePicker</label>
                  <Form.Item hidden={true} name="datePicker" rules={[{ required: true, message: 'required' }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="datePickerMockUp"
                    initialValue={'datePickerMockUp'}
                    rules={[{ required: true, message: 'required' }]}
                  >
                    <>
                      <Datepicker
                        language="th"
                        // value={
                        //   formType === 'UPDATE'
                        //     ? moment(new Date('2020-02-01')).format('YYYY-MM-DD')
                        //     : moment(new Date()).format('YYYY-MM-DD')
                        // }
                        // value={'11-02-2022'}
                        setValueDateFn={(data) => {
                          form.setFieldsValue({
                            datePicker: data,
                          })
                          setErrorField('datePickerMockUp', false) // set false error field
                        }}
                      />
                    </>
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <label htmlFor="textInput">Example select</label>
                  <Form.Item name="select2" rules={[{ required: true, message: 'required' }]}>
                    <Select
                      data={dataSelect}
                      placeholder="default select..."
                      onChange={(val) => console.log(val)}
                      scrollableId="exampleForm"
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <label htmlFor="textInput">Example Number Input</label>
                  <Form.Item name="inputNumber" rules={[{ required: true, message: 'required' }]}>
                    <InputNumberNew min={1} max={10} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col {...Responesive.buttonFilter} align="middle">
                  <Form.Item>
                    <>
                      <Space size={[8, 8]} wrap>
                        <ButtonNew type="search" roles={{ type: 'add', menu: 'example' }} htmlType="submit">
                          บันทึก
                        </ButtonNew>
                        &nbsp;
                        <ButtonNew type="clear" onClick={() => form.resetFields()}>
                          ยกเลิก
                        </ButtonNew>
                      </Space>
                    </>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FormFiltersExampleNew

`
export const FormfiltersbasicExampleCode = `
import React, { useState, useEffect } from 'react'
import { Card, Row, Col } from 'antd'
import FormFiltersBasicNew from '../../../components/form/FormFiltersBasic'
const dataSelect = [
  { value: 'A', label: 'A' },
  { value: 'B', label: 'B' },
  { value: 'C', label: 'C' },
  { value: 'D', label: 'D' },
]
const FormfiltersbasicExample = () => {
  const [inputList, setInputList] = useState([])
  useEffect(() => {
    //   // type of input
    //   text
    //   number
    //   select
    //   select_search
    //   select_multiple
    //   datepicker
    setInputList([
      {
        type: 'text',
        name: 'text',
        label: 'Example input text',
        placeholder: 'press enter text',
        rules: [{ required: true, message: 'required' }],
      },
      {
        type: 'number',
        name: 'number',
        label: 'Example input number',
        placeholder: 'press enter number',
        rules: [{ required: true, message: 'required' }],
      },
      {
        type: 'datepicker',
        name: 'datepicker1', // hidden input value
        nameThowError: 'datepicker1', // is show input
        label: 'Example input datepicker',
        placeholder: 'press enter datepicker',
        rules: [{ required: true, message: 'required' }],
      },
      {
        type: 'datepicker',
        name: 'datepicker2', // hidden input value
        nameThowError: 'datepicker2', // is show input
        label: 'Example input datepicker',
        placeholder: 'press enter datepicker',
        rules: [{ required: true, message: 'required' }],
      },
      {
        type: 'select',
        name: 'select',
        data: dataSelect,
        label: 'Example input select',
        placeholder: 'press enter select',
        // rules: [{ required: true, message: 'required' }],
      },
      {
        type: 'select_search',
        name: 'selectSearch',
        data: dataSelect,
        label: 'Example input select search',
        placeholder: 'press enter select search',
        // rules: [{ required: true, message: 'required' }],
      },
      {
        type: 'select_multiple',
        name: 'selectMultiple',
        data: dataSelect,
        label: 'Example input select multiple',
        placeholder: 'press enter select multiple',
        rules: [{ required: true, message: 'required' }],
      },
    ])
  }, [])
  function getValue(value) {
    console.log('getvalue', value)
  }
  return (
    <>
      <Row style={{ marginTop: 24 }}>
        <Col sm={24} md={24} lg={24}>
          <Card title={'Dynamic Form Filters Example'}>
            <FormFiltersBasicNew inputs={inputList} formName="filters" getValue={(value) => getValue(value)} />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FormfiltersbasicExample

`
