import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import moment from 'moment'
import * as _ from 'lodash'
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb.jsx'
import { CardNew } from '../../../components/card/Card.jsx'
import { ButtonGroup } from '../../../components/button/ButtonGroup.jsx'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import CompleteResultReportModal from './modal/CompleteResultReportModal'
import { SpinnersNew } from '../../../components/spinners/Spinners.jsx'
import { Columns } from './utils/CompleteResultReportTable.js'
import { GET_COMPLETE_RESULT_PAGINATION, COMPLETE_RESULT_REPORT_EXPORT } from '../completeresultreport/graphql/Query'
import CompleteResultReportFilter from './filter/CompleteResultReportFilter.jsx'
import { useLazyQuery } from '@apollo/client'
import { displayError } from '../../../utilitys/helper.js'
import { Alert } from '../../../components/alert/Alert.js'
import SendEmailReportModal from '../components/SendEmailReportModal.jsx'
import { encryptInput } from '../../../utilitys/crypto.js'
import { handleSort } from '../../../utilitys/pagination'

const CompleteResultReportList = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)

  const ResponsiveTable = {
    md: 24,
    lg: 24,
  }

  const breadcrumbList = [
    { label: t('รายงาน'), path: '/reports/completeresultreport' },
    { label: t('รายงานผลการตรวจสอบฉบับสมบูรณ์') },
  ]

  const [dataLists, setDataLists] = useState([])
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  const [filters, setFilters] = useState({})
  /* ---------------- API ----------- */
  const [getDataPaginationFn, dataPagination] = useLazyQuery(GET_COMPLETE_RESULT_PAGINATION)
  const getValue = (data) => setFilters(data)
  /* ---------------- ACTION ----------- */

  /*------------useEffect------------*/
  useEffect(() => {
    getDataPaginationFn({
      variables: {
        input: encryptInput({
          filters: {
            ...filters,
          },
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, filters, sort])

  /*-------การแสดงหน้า-------*/
  useEffect(() => {
    if (dataPagination.data) {
      let response = dataPagination.data?.getCompleteResultPagination
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            ...data,
            key: data.no,
            projectName: data.projectName,
            projectRefNameTH: data.projectRefNameTH,
            startDateReport: moment(data.dateStart).format('DD/MM/YYYY'),
            endDateReport: moment(data.dateEnd).format('DD/MM/YYYY'),
            organizationNameTH: data.organizationNameTH,
            fullName: data.fullName,
            tools: (
              <ButtonGroup
                menu={menu}
                icons={[
                  {
                    type: 'download',
                    onClick: () => {
                      Promise.all([
                        setData({
                          projectId: data.projectId,
                        }),
                        setVisible(true),
                      ])
                    },
                  },
                  {
                    type: 'settings',
                    onClick: () => {
                      fncOnpressEmailSetting(data)
                    },
                  },
                ]}
              />
            ),
          }
        }),
      )
    }
    if (dataPagination.error) {
      const err = dataPagination.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [dataPagination.data])

  /*-----setField-------*/

  //=========================== สำหรับการส่งเมลรายงาน ===========================

  const [callCompleteResultExport, dataCompleteResultExport] = useLazyQuery(COMPLETE_RESULT_REPORT_EXPORT)

  useEffect(() => {
    if (dataCompleteResultExport.data) {
      Alert({
        type: 'success',
        title: t('ส่งอีเมลสำเร็จ'),
        onOk() {},
      })
    }
    if (dataCompleteResultExport.error) {
      const err = dataCompleteResultExport.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [dataCompleteResultExport?.data])

  //=========================== Email Modal ===========================
  const [emailModalVisible, setemailModalVisible] = useState(false)
  const [mailValue, setMailValue] = useState()
  const [inputSendEmail, setInputSendEmail] = useState()

  useEffect(() => {
    if (inputSendEmail) {
      const dataInput = {
        projectId: mailValue.refCode,
        lang: localStorage.getItem('lang'),
        fileType: inputSendEmail.fileType,
        emailData: inputSendEmail, // สำหรับการส่งเมลรายงาน
        waterMark: inputSendEmail?.waterMark || '',
      }

      // Call API Export
      callCompleteResultExport({
        variables: {
          input: encryptInput(dataInput),
        },
      })
    }
  }, [inputSendEmail])

  const fncOnpressEmailSetting = (data) => {
    setemailModalVisible(true)
    setMailValue({ reportName: 'รายงานผลการตรวจสอบฉบับสมบูรณ์', refCode: data.projectId })
  }

  return (
    <>
      {dataPagination.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('รายงานผลการตรวจสอบฉบับสมบูรณ์')} />
      <CompleteResultReportFilter getValue={getValue} menu={menu} />
      {/* - - - DataTable - - - */}
      <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="List" toggledrop="false">
        <Row gutter={[12, 12]}>
          <Col {...ResponsiveTable}>
            <Datatable
              columns={Columns}
              data={[...dataLists]}
              handleTableChange={(e) => {
                const reNewField = [
                  { projectName: 'projectName' },
                  { startDateReport: 'startDateReport' },
                  { endDateReport: 'endDateReport' },
                  { projectRefNameTH: 'projectRefNameTH' },
                  { organizationNameTH: 'organizationNameTH' },
                  { fullName: 'fullName' },
                ]
                setSort(handleSort(e?.sorter, reNewField))
              }}
              paginationCustom={true}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
              total={count}
              pageSize={limit}
              page={page}
            />
          </Col>
        </Row>
      </CardNew>
      <SendEmailReportModal
        menu={menu}
        open={emailModalVisible}
        close={(e) => {
          setemailModalVisible(e)
        }}
        fileType={{ pdf: true, word: true, excel: true, csv: true }}
        setInputSendEmail={(e) => setInputSendEmail(e)}
        mailValue={mailValue}
      />
      <CompleteResultReportModal visible={visible} setVisible={setVisible} data={data} />
    </>
  )
}

export default CompleteResultReportList
