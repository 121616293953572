import React from 'react'
import { Row, Col } from 'antd'
import AccessHistoryList from './AccessHistoryList'
/**
 * @function AccessHistoryIndex
 * @description
 * Menu: HorodinFormMation
 * เมนู: ข้อมูลการแก้ไขชื่อหน่วยงาน
 * @returns Component
 */

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}

const AccessHistoryIndex = (props) => {
  const { menu } = props
  return (
    <>
      <Row>
        <Col {...Responsive}>
          <AccessHistoryList menu={menu} />
        </Col>
      </Row>
    </>
  )
}

export default AccessHistoryIndex
