import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import { Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { ButtonNew } from '../../../../../components/button/Button'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { useLazyQuery, useMutation } from '@apollo/client'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { dateDisplay } from '../../../../../utilitys/dateHelper.js'
import { Status } from '../../../../../components/status/Status'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { Alert } from '../../../../../components/alert/Alert'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText, displayError } from '../../../../../utilitys/helper'
import AdhocjobStepModel from '../modal/AdhocjobStepModel'
import AdhocjobStepMain from '../modal/AdhocjobStepMain'
import AdhocjobStepSub from '../modal/AdhocjobStepSub'
import { GET_ADHOCJOB_STEP } from '../../graphql/Query'
import { DELETE_ADHOCJOB_STEP_BY_ID } from '../../graphql/Mutation'
import { Columns } from '../../utils/AdhocJobStepColumns'

const AdhocjobTab3 = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [visible2, setVisible2] = useState(false)
  const [visible3, setVisible3] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [typeAction, setTypeAction] = useState()
  const [stepId, setStepId] = useState()
  const { t } = useTranslation()
  const { state } = useLocation()

  if (!state) {
    return <Navigate to={'/auditoperations/adhocjob'} />
  }

  const { refCode, type_action } = state

  const Responsive = {
    md: 24,
    lg: 24,
  }

  /* ---------------- API ----------- */
  const [getAdhocJobStepFn, dataAdhocJobStep] = useLazyQuery(GET_ADHOCJOB_STEP, {
    variables: {
      jobCode: encryptInput(refCode),
    },
  })
  const [deleteAdhocJobStepFn, dataDeleteAdhocJobStep] = useMutation(DELETE_ADHOCJOB_STEP_BY_ID)

  /* ---------------- ACTION ----------- */
  const showConfirmDelete = (stepId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteAdhocJobStepFn({
          variables: {
            stepId: encryptInput(stepId),
          },
        })
      },
      onCancel() {},
    })
  }

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (refCode) {
      getAdhocJobStepFn({
        variables: {
          jobCode: encryptInput(refCode),
        },
      })
    }
  }, [refCode])

  useEffect(() => {
    if (dataAdhocJobStep.data) {
      const data = dataAdhocJobStep.data.getAdhocJobStepByCode
      let noSub = ''
      setDataLists(
        _.map(data, (item, index) => {
          let stepName = ''
          if (item.stepType === 1) {
            stepName = (
              <span style={{ fontWeight: 'bold' }}>
                {item.stepSort + '. ' + displayText(item.stepNameTH, item.stepNameEN)}
              </span>
            )
            noSub = item.stepSort
          } else {
            stepName = (
              <>&emsp;{'' + noSub + '.' + item.stepSort + ' ' + displayText(item.stepNameTH, item.stepNameEN)} </>
            )
          }

          let responsible = []
          const listResponsible = displayText(item.responsibleTH, item.responsibleEN)
          if (listResponsible) {
            responsible = listResponsible.split(', ')
          }

          return {
            key: index,
            stepName: stepName,
            dateStart: <span style={{ fontWeight: item.stepType === 1 && 'bold' }}>{dateDisplay(item.dateStart)}</span>,
            dateEnd: <span style={{ fontWeight: item.stepType === 1 && 'bold' }}>{dateDisplay(item.dateEnd)}</span>,
            responsible: responsible.map((value, idx) => {
              return (
                <React.Fragment key={`${value}${idx}`}>
                  <span style={{ fontWeight: item.stepType === 1 && 'bold' }}>{value}</span>
                  {idx < responsible.length && <br />}
                </React.Fragment>
              )
            }),
            operationStatus: (
              <span style={{ fontWeight: item.stepType === 1 && 'bold' }}>
                <Status text={displayText(item.operationStatusNameTH, item.operationStatusNameEN)} />
              </span>
            ),
            isActive: (
              <span style={{ fontWeight: item.stepType === 1 && 'bold' }}>
                <Status text={item.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />
              </span>
            ),
            tools: (
              <div style={{ textAlign: 'right' }}>
                {type_action === 'view' ? (
                  <ButtonGroup
                    menu={menu}
                    icons={[
                      {
                        type: 'view',
                        onClick: () => {
                          item.stepType === 1 ? setVisible2(true) : setVisible3(true),
                            setStepId(item.stepId),
                            setTypeAction('view')
                        },
                      },
                    ]}
                  />
                ) : (
                  <ButtonGroup
                    menu={menu}
                    icons={[
                      {
                        type: 'view',
                        onClick: () => {
                          item.stepType === 1 ? setVisible2(true) : setVisible3(true),
                            setStepId(item.stepId),
                            setTypeAction('view')
                        },
                      },
                      {
                        type: 'edit',
                        onClick: () => {
                          item.stepType === 1 ? setVisible2(true) : setVisible3(true),
                            setStepId(item.stepId),
                            setTypeAction('edit')
                        },
                      },
                      {
                        type: 'delete',
                        onClick: () => showConfirmDelete(item.stepId),
                      },
                    ]}
                  />
                )}
              </div>
            ),
          }
        }),
      )
    }

    if (dataAdhocJobStep.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataAdhocJobStep.error)),
        onOk() {},
      })
    }
  }, [dataAdhocJobStep.data])

  useEffect(() => {
    if (dataDeleteAdhocJobStep.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          dataAdhocJobStep.refetch()
        },
      })
    }

    if (dataDeleteAdhocJobStep.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(dataDeleteAdhocJobStep.error)),
        onOk() {},
      })
    }
  }, [dataDeleteAdhocJobStep.data])

  return (
    <>
      <AdhocjobStepModel
        visible={visible}
        setVisible={setVisible}
        refCode={refCode}
        stepId={stepId}
        refetch={() => dataAdhocJobStep.refetch()}
        typeAction={typeAction}
      />
      <AdhocjobStepMain
        visible={visible2}
        setVisible={setVisible2}
        refCode={refCode}
        stepId={stepId}
        refetch={() => dataAdhocJobStep.refetch()}
        typeAction={typeAction}
        close={(e) => {
          setVisible2(e)
          setTypeAction(null)
          setStepId(null)
        }}
      />
      <AdhocjobStepSub
        visible={visible3}
        setVisible={setVisible3}
        refCode={refCode}
        stepId={stepId}
        refetch={() => dataAdhocJobStep.refetch()}
        typeAction={typeAction}
        close={(e) => {
          setVisible3(e)
          setTypeAction(null)
          setStepId(null)
        }}
      />
      {dataAdhocJobStep.loading && <SpinnersNew />}
      <Row justify="center">
        <Col {...Responsive}>
          <Row justify="center">
            <Col {...Responsive}>
              <Datatable
                columns={Columns}
                data={dataLists}
                // enableScroll={{ x: false, y: true }}
                searchCustom={true}
                btnAdd={
                  type_action !== 'view' ? (
                    <>
                      <Space size={[12, 0]}>
                        <ButtonNew
                          type="plusTableBorderPrimary"
                          roles={{ type: type_action, menu: menu }}
                          onClick={() => setVisible(true)}
                        >
                          {t('เพิ่มคลังขั้นตอน')}
                        </ButtonNew>
                        <ButtonNew
                          type="plusTableBorderPrimary"
                          roles={{ type: type_action, menu: menu }}
                          onClick={() => setVisible2(true)}
                        >
                          {t('เพิ่มขั้นตอนหลัก')}
                        </ButtonNew>
                        <ButtonNew
                          type="plusTableBorderPrimary"
                          roles={{ type: type_action, menu: menu }}
                          onClick={() => setVisible3(true)}
                        >
                          {t('เพิ่มขั้นตอนย่อย')}
                        </ButtonNew>
                      </Space>
                    </>
                  ) : (
                    <></>
                  )
                }
              />
            </Col>
          </Row>
          <Row gutter={[12, 20]} justify="center" style={{ marginTop: '20px' }}>
            <Col>
              <ButtonNew type="back" onClick={() => navigate('/auditoperations/adhocjob')}>
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default AdhocjobTab3
