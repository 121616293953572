import { useEffect } from 'react'

export const useCacheClean = (version, appEnv) => {
  const caching = () => {
    if (appEnv === 'local') {
      return false
    }

    const localVersion = localStorage.getItem('version')
    if (localVersion !== version) {
      if ('caches' in window) {
        caches.keys().then((names) => names.forEach((name) => caches.delete(name)))

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true)
      }

      localStorage.removeItem('version')
      localStorage.setItem('version', version)
    }
  }

  useEffect(() => {
    if (version) {
      caching()
    }
  }, [version])
}
