import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { CardNew } from '../../../components/card/Card'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { Form, Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { ButtonNew } from '../../../components/button/Button'
import { Datatable } from '../../../components/datatable/Datatable'
import { Columns } from './utils/PersonnelColumns'
import { InputNew } from '../../../components/input/Input'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { LabelNew as Label } from '../../../components/label/Label'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { GET_PERSONNELS_LIST } from './graphql/Query'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectCertificates from '../../../components/inputfromapi/selectcertificates/SelectCertificates'
import SelectPersonnelPositions from '../../../components/inputfromapi/selectpersonnelpositions/SelectPersonnelPositions'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText, displayError } from '../../../utilitys/helper'
import { Alert } from '../../../components/alert/Alert'

const AuditPersonnelList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const Responsive = {
    md: 8,
    lg: 8,
  }

  if (!state) return <Navigate to={'/administrator/manageauditteam'} />

  const data = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/manageauditteam' },
    { label: t('จัดการข้อมูลทีมตรวจสอบ'), path: '/administrator/manageauditteam' },
    { label: t('รายละเอียดทีมตรวจสอบ') },
  ]

  const [form] = Form.useForm()
  const { auditTeamCode, personnelList } = state
  const [dataLists, setDataLists] = useState([])
  const [selectPersonnelList, setSelectPersonnelList] = useState([])
  const [keySelecteds, setkeySelecteds] = useState([])

  /* ---------------- API ----------- */
  const [getPersonnelListFn, dataPersonnelList] = useLazyQuery(GET_PERSONNELS_LIST)

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    getPersonnelListFn({
      variables: {
        input: encryptInput({
          personnelCode: input.personnelCode || '',
          organizationCode: input.organizationCode || '',
          personnelName: input.personnelName || '',
          certificateCode: input.certificateCode || '',
          positionCode: input.positionCode || '',
          organizationChecked: 2,
          personnaloperationCode: 10,
        }),
      },
    })
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows) {
        setSelectPersonnelList(
          _.map(selectedRows, (item) => {
            return {
              personnelCode: item.personnelCode,
              personnelName: item.personnelName,
              organization: item.organization,
            }
          }),
        )
        setkeySelecteds(selectedRowKeys)
      }
    },
    getCheckboxProps: (record) => ({
      disabled: _.find(personnelList, (data) => data.personnelCode === record.personnelCode),
      name: record.name,
    }),
    selectedRowKeys: personnelList
      ? [...personnelList.map(({ personnelCode }) => personnelCode), ...keySelecteds]
      : [...keySelecteds],
  }

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    getPersonnelListFn({
      variables: {
        input: encryptInput({ organizationChecked: 2, personnaloperationCode: 10 }),
      },
    })
  }, [])

  useEffect(() => {
    if (dataPersonnelList?.data) {
      let personnelList = dataPersonnelList?.data?.getPersonnelsList
      setDataLists(
        _.map(personnelList, (data) => {
          return {
            // key: index + 1,
            key: data.personnelCode,
            personnelCode: data.personnelCode,
            personnelName: displayText(data.personnelNameTH, data.personnelNameEN),
            organization: displayText(data.organizationNameTH, data.organizationNameEN),
            position: displayText(data.positionNameTH, data.positionNameEN),
          }
        }),
      )
    }

    if (dataPersonnelList.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataPersonnelList.error)),
        onOk() {},
      })
    }
  }, [dataPersonnelList.data])

  const resetFields = () => {
    form.resetFields()
    getPersonnelListFn({
      variables: {
        input: encryptInput({ organizationChecked: 2, personnaloperationCode: 10 }),
      },
    })
  }

  return (
    <>
      {dataPersonnelList.loading && <SpinnersNew />}
      <Breadcrumb data={data} title={t('รายละเอียดทีมตรวจสอบ')} />
      <CardNew topic="Filters" icon="Filter" toggledrop="true" buttontopright="true">
        <Form layout="vertical" form={form} onFinish={onFinish} name="auditForm">
          <Row gutter={[16, 8]}>
            <Col {...Responsive}>
              <Form.Item name={'personnelCode'} label={<Label type="tab-header-inactive">{t('รหัสบุคลากร')}</Label>}>
                <InputNew placeholder={t('ระบุรหัสบุคลากร')} maxLength={10} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name={'personnelName'} label={<Label type="tab-header-inactive">{t('ชื่อนามสกุล')}</Label>}>
                <InputNew placeholder={t('ระบุชื่อนามสกุล')} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="organizationCode" label={<Label type="tab-header-inactive">{t('หน่วยงาน')}</Label>}>
                <SelectOrganization
                  placeholder={t('เลือกหน่วยงาน')}
                  formname={'auditForm'}
                  filters={{ organizationChecked: 2, isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ organizationCode: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="positionCode" label={<Label type="tab-header-inactive">{t('ตำแหน่ง')}</Label>}>
                <SelectPersonnelPositions
                  formname={'auditForm'}
                  placeholder={t('เลือกตำแหน่ง')}
                  filters={{ /* organizationChecked: 1, */ isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ positionCode: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="certificateCode" label={<Label type="tab-header-inactive">{t('วุฒิบัตร')}</Label>}>
                <SelectCertificates
                  formname={'auditForm'}
                  placeholder={t('เลือกวุฒิบัตร')}
                  handleChange={(e) => form.setFieldsValue({ certificateCode: e?.value })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <ButtonNew type="search" htmlType="submit">
                {t('ค้นหา')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew type="clear" onClick={() => resetFields()}>
                {t('ล้างค่า')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </CardNew>

      <CardNew topic={t('รายการบุคลากร')} icon="List" toggledrop={'false'}>
        <Row gutter={[16, 16]}>
          <Col md={24}>
            <Datatable
              columns={Columns}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              data={dataLists}
              scroll={{ x: 1000, y: true }}
              searchCustom={true}
              paginationCustom={false}
              pagination={true}
            />
          </Col>
          <Col md={24} align="center">
            <Space size={[8, 8]} wrap>
              <ButtonNew
                type="primary"
                htmlType="submit"
                onClick={() =>
                  navigate('/administrator/manageauditteam/detail/', {
                    state: {
                      type_action: 'edit',
                      auditTeamCode: auditTeamCode,
                      personnelList: personnelList,
                      selectPersonnelList: selectPersonnelList,
                    },
                  })
                }
              >
                {t('เพิ่ม')}
              </ButtonNew>
              &nbsp;
              <ButtonNew
                type="back"
                onClick={() =>
                  navigate('/administrator/manageauditteam/detail/', {
                    state: {
                      type_action: 'edit',
                      auditTeamCode: auditTeamCode,
                      personnelList: personnelList,
                      selectPersonnelList: [],
                    },
                  })
                }
              >
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Space>
          </Col>
        </Row>
      </CardNew>
    </>
  )
}
export default AuditPersonnelList
