import styled from 'styled-components'

export const ForGotPasswordStyles = styled.div`
  font-family: 'NotoSansThai', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  .content-right {
    border-top: 5px solid #45a648;
  }
  .content-right-line {
    widht: 100%;
    border-top: 11px solid #1d76bb;
  }
  .login-form {
    max-width: 400px;
    margin: 20px;
  }
  .login-form-forgot {
    float: right;
  }
  .ant-col-rtl .login-form-forgot {
    float: left;
  }
  .login-form-button {
    width: 100%;
    background-color: #2b4b91 !important;
    background-image: linear-gradient(to right, #2b4b91, #1d9fc5);
    color: #ffffff !important;
  }
  .login-form-button: hover {
    width: 100%;
    background-color: #3c63b9 !important;
    background-image: linear-gradient(to right, #3c63b9, #1d9fc5);
    color: #ffffff !important;
  }
  .welcome {
    color: #000000;
    font-size: 2rem;
  }
  .title {
    color: #2b4990;
    font-size: 2rem;
    font-weight: 700;
    padding-bottom: 10px;
  }
  .login-title {
    font-size: 1.2rem;
    padding-bottom: 15px;
    color: #666666;
  }
  .login-error {
    padding: 15px;
    text-align: center;
  }
`

export const LogoStyle = styled.div`
  width: 124px;
  height: 165px;
  border: 15px solid #fff;
  border-radius: 0.8em;
  background-color: #fff;
  margin: 50px;
  .login-logo {
    width: 94px;
    height: 132px;
    background-repeat: no-repeat;
    background-size: cover;
  }
`
