import React, { useState } from 'react'
import { IconNew } from '../../../components/icon/Icon'
import { CodeBlock as CodeBlockOld } from 'react-code-blocks'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { Row, Col, Card, Form } from 'antd'
import { Select } from '../../../components/select/Select'
import IconName from './IconName'

function IconIndex() {
  const [iconResult, setIconResult] = useState(null)
  let codeIcon = ''
  IconName.map((item) => {
    codeIcon += `
      <IconNew icon={'${item}'} size={28} color="Please enter a color if you want." />
      `
  })

  let code = ` import { IconNew } from '../../../components/icon/Icon'   
    export default function App() {
        return <> 
        ${codeIcon}
        </>
    }
  `
  return (
    <React.Fragment>
      <Row style={{ marginTop: 24 }} gutter={[16, 16]}>
        <Col span={24}>
          <Card title="feathericons" style={{ width: '100%' }}>
            <Row align="center" gutter={[16, 16]}>
              <Col span={16}>
                <>
                  <Form name="select_form">
                    <Select
                      title={'Search icon'}
                      data={IconName.map((item, idx) => {
                        return { label: item, value: idx }
                      })}
                      placeholder={'Search select...'}
                      showSearch
                      onChange={(e, { label }) => setIconResult(label)}
                      scrollableId="select_form"
                      filterOption={(input, option) => {
                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    />
                  </Form>
                </>
              </Col>
              <Col span={16}>
                <Card title="Result search icon" style={{ width: '100%' }} align="center">
                  {iconResult ? (
                    <>
                      <IconNew icon={iconResult} size={76} color="" />
                      <p>{iconResult}</p>
                      <CodeBlockOld
                        text={`<IconNew icon={'${iconResult}'} size={28} color="" />`}
                        language={'jsx'}
                        showLineNumbers={false}
                        theme={'a11yLight'}
                      />
                    </>
                  ) : null}
                </Card>
              </Col>
            </Row>
            <hr />
            <Row gutter={[16, 16]}>
              {IconName.map((item, idx) => {
                return (
                  <Col span={3} key={idx} align="middle" style={{ marginTop: 16 }}>
                    {/* available now */}
                    <IconNew icon={item} size={28} color="" />
                    <br />
                    {item}
                  </Col>
                )
              })}
              <Col span={24} style={{ marginTop: 24 }}>
                <CodeBlock code={code} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default IconIndex
