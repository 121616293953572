import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_PERSONNEL_OPERATIONS } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectPersonnelOperations = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { personnalOperationId, personnaloperationCode, personnaloperationNameTH, personnaloperationNameEN, isActive } =
    filters || {}

  const [isData, isSetData] = useState([])

  const [getMasterPersonnelOperationsFn, getMasterPersonnelOperations] = useLazyQuery(GET_MASTER_PERSONNEL_OPERATIONS)

  useEffect(() => {
    getMasterPersonnelOperationsFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) }
    })
  }, [personnalOperationId, personnaloperationCode, personnaloperationNameTH, personnaloperationNameEN, isActive])

  useEffect(() => {
    if (getMasterPersonnelOperations.data) {
      isSetData(
        _.map(getMasterPersonnelOperations?.data?.getMasterPersonnelOperations, (item) => {
          return {
            label: displayText(item?.personnaloperationNameTH, item?.personnaloperationNameEN),
            value: item?.personnaloperationCode,
            source: item
          }
        })
      )
    }
  }, [getMasterPersonnelOperations.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterPersonnelOperations.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectPersonnelOperations
