import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_ACTIONPLAN_ORGANIZATION } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectActionPlanOrganizations = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { isActive } = filters || {}

  const [isData, isSetData] = useState([])

  const [getActionPlanOrganizationsSelectFn, getActionPlanOrganizationsSelect] =
    useLazyQuery(GET_ACTIONPLAN_ORGANIZATION)

  useEffect(() => {
    getActionPlanOrganizationsSelectFn({
      variables: { input: filters ? encryptInput({ ...filters, isActive: 1 }) : encryptInput({ isActive: 1 }) },
    })
  }, [isActive])

  useEffect(() => {
    if (getActionPlanOrganizationsSelect.data) {
      const data = getActionPlanOrganizationsSelect?.data?.getActionPlanOrganizationsSelect
      const uniqData = _.uniqBy(data, (items) => items.organizationCode)
      isSetData(
        _.map(uniqData, (item) => {
          return {
            label:
              item?.organizationCode +
              ' : ' +
              displayText(item?.organizationNameTH, item?.organizationNameEN) +
              '(' +
              displayText(item?.namePrefixTH, item?.namePrefixEN) +
              displayText(item?.firstNameTH, item?.firstNameEN) +
              ' ' +
              displayText(item?.lastNameTH, item?.lastNameEN) +
              ')',
            value: item?.organizationCode,
            source: item,
          }
        }),
      )
    }
  }, [getActionPlanOrganizationsSelect.data])
  const setOnChange = props.multiple
    ? {
        onChange: (value) => {
          handleChange(_.filter(isData, (data) => value.includes(String(data?.value))))
        },
      }
    : {
        onChange: (value) => {
          handleChange(_.find(isData, (data) => data.value === value))
        },
      }
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getActionPlanOrganizationsSelect.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...setOnChange}
      />
    </>
  )
}

export default SelectActionPlanOrganizations
