import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Space } from 'antd'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
// import { Select } from '../../../../components/select/Select'
import { ButtonNew } from '../../../../components/button/Button'
import SelectProjectStatus from '../../../../components/inputfromapi/selectprojectstatus/SelectProjectStatus'
import SelectAuditTeams from '../../../../components/inputfromapi/selectauditteams/SelectAuditTeams'
import SelectProjectRefer from '../../../../components/inputfromapi/selectprojectrefer/SelectProjectRefer'
import { Alert } from '../../../../components/alert/Alert'
import { CardNew } from '../../../../components/card/Card'
import { Datepicker } from '../../../../components/datepicker/Datepicker'
import { encryptInput } from '../../../../utilitys/crypto'
import { LabelNew } from '../../../../components/label/Label'
import { InputNew } from '../../../../components/input/Input'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { displayError } from '../../../../utilitys/helper'
import {
  GET_SERACH_AUDIT_STATUS_REPORT_LIST,
  // , EXPORT_RISK_FORMS
} from '../graphql/Query'

const AuditStatusReportFilter = (props) => {
  const { limit, page, getCount, getValue, sort } = props
  const { t } = useTranslation()
  const isLang = localStorage.getItem('lang')
  const [form] = Form.useForm()
  const formName = 'filter'
  const Responsive = {
    md: 8,
    lg: 8,
    btn: {
      md: 24,
      lg: 24,
    },
  }

  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [filters, setFilters] = useState({})
  const [getSearchAuditStatusReportListFn, getSearchAuditStatusReportList] = useLazyQuery(
    GET_SERACH_AUDIT_STATUS_REPORT_LIST,
  )
  useEffect(() => {
    if (isLang) {
      getSearchAuditStatusReportListFn({
        variables: {
          input: {
            filters: encryptInput({ ...filters }),
            pagination: { limit, page },
            sort: sort,
          },
        },
      })
    }
  }, [page, limit, isLang, sort, filters])

  useEffect(() => {
    const res_ = getSearchAuditStatusReportList?.data?.getSearchAuditStatusReportList
    getCount(res_?.count ? res_?.count : 0)
    getValue(res_?.result ? res_?.result : [])

    if (getSearchAuditStatusReportList.error) {
      const err = getSearchAuditStatusReportList.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [getSearchAuditStatusReportList?.data])

  const onFinish = async (value) => {
    const localFilter = {
      projectRefCode: value?.projectRefCode || '',
      projectName: value?.projectName || '',
      auditTeamCode: value?.auditTeamCode || '',
      projectStatusCode: value?.projectStatusCode || '',
      dateStart: value?.dateStart || '',
      dateEnd: value?.dateEnd || '',
      lang: isLang,
    }
    setFilters({
      ...localFilter,
    })
  }
  return (
    <>
      {getSearchAuditStatusReportList.loading && <SpinnersNew />}
      <Row style={{ marginBottom: 24 }}>
        <Col sm={24} md={24} lg={24}>
          <CardNew topic={t('Filters')} icon="List" toggledrop={'true'}>
            <Form form={form} onFinish={onFinish} name={formName} layout="vertical">
              <Row gutter={[12, 12]}>
                <Col {...Responsive}>
                  <Form.Item
                    name={'projectRefCode'}
                    label={<LabelNew type="tab-header-inactive">{t('แหล่งที่มาของโครงการ')}</LabelNew>}
                    // rules={[{ required: true, message: t('เลือกแหล่งที่มาของโครงการ') }]}
                  >
                    <SelectProjectRefer
                      filters={{ isActive: 1 }}
                      formname={formName}
                      placeholder={t('เลือกแหล่งที่มาของโครงการ')}
                      handleChange={(value) => {
                        form.setFieldsValue({ projectRefCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'projectName'}
                    label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุชื่อโครงการ')} />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name="auditTeamCode"
                    label={<LabelNew type="tab-header-inactive">{t('ทีมงานที่รับผิดชอบ')}</LabelNew>}
                  >
                    <SelectAuditTeams
                      filters={{ isActive: 1 }}
                      formname={formName}
                      placeholder={t('เลือกทีมตรวจสอบ')}
                      handleChange={async (value) => {
                        form.setFieldsValue({ auditTeamCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'projectStatusCode'}
                    label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                  >
                    <SelectProjectStatus
                      filters={{ isActive: 1 }}
                      formname={formName}
                      placeholder={t('เลือกสถานะของโครงการ')}
                      handleChange={(value) => {
                        form.setFieldsValue({ projectStatusCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'dateStart'}
                    label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        setIsMinDate(data)
                        form.setFieldsValue({ dateStart: await data })
                      }}
                      onClear={() => {
                        form.setFieldsValue({ dateStart: null })
                      }}
                      isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'dateEnd'}
                    label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                  >
                    <Datepicker
                      setValueDateFn={async (data) => {
                        setIsMaxDate(data)
                        form.setFieldsValue({ dateEnd: await data })
                      }}
                      onClear={() => {
                        form.setFieldsValue({ dateEnd: null })
                      }}
                      isMinDate={isMinDate ? new Date(isMinDate) : null}
                      autoComplete="off"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify="center">
                <Col {...Responsive.btn} align="center">
                  <Space size={[8, 8]} wrap>
                    <ButtonNew type="search" htmlType="submit">
                      {t('ค้นหา')}
                    </ButtonNew>
                    <ButtonNew
                      type="clear"
                      onClick={() => {
                        form.resetFields()
                        setFilters({})
                        getCount(0)
                        getValue([])
                      }}
                    >
                      {t('ล้างค่า')}
                    </ButtonNew>
                  </Space>
                </Col>
              </Row>
            </Form>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default AuditStatusReportFilter
