import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>ลำดับ</Trans>
      </div>
    ),
    dataIndex: 'key',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    width: '5%',
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>ประเภทของงาน</Trans>
      </div>
    ),
    dataIndex: 'jobTypeName',
    width: '10%',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    sorter: { multiple: 1 },
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>เลขที่งาน</Trans>
      </div>
    ),
    dataIndex: 'jobNumber',
    width: '10%',
    sorter: { multiple: 1 },
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
  },
  {
    title: <Trans>ผู้ขอคำปรึกษา/แนะนำ</Trans>,
    dataIndex: 'requestPersonnel',
    width: '10%',
    sorter: { multiple: 2 },
  },
  {
    title: <Trans>หน่วยงาน/สาขา</Trans>,
    dataIndex: 'organizationName',
    width: '10%',
    sorter: { multiple: 3 },
  },
  {
    title: <Trans>เรื่องที่ให้คำปรึกษา/แนะนำ</Trans>,
    dataIndex: 'Subject',
    width: '10%',
    sorter: { multiple: 4 },
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>วันที่บันทึกเอกสาร</Trans>
      </div>
    ),
    dataIndex: 'resultDate',
    width: '10%',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    sorter: { multiple: 5 },
  },
  {
    title: <Trans>หน่วยงานที่รับผิดชอบ</Trans>,
    dataIndex: 'responsibleOrganization',
    width: '10%',
    sorter: { multiple: 6 },
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>ผู้ให้คำปรึกษา/แนะนำ</Trans>
      </div>
    ),
    dataIndex: 'fullName',
    width: '10%',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    sorter: { multiple: 7 },
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>สถานะดำเนินการ</Trans>
      </div>
    ),
    dataIndex: 'consultStatus',
    width: '10%',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    sorter: { multiple: 8 },
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '10%',
  },
]
