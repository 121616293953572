import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Row, Col, Form } from 'antd'
import { Datatable } from '../../../../components/datatable/Datatable.jsx'
import { ButtonNew } from '../../../../components/button/Button'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { GET_AUDITPROJECT_PAGINATION, AUDITPROJECT_EXPORT } from './graphql/Query'
import { AUDITPROJECT_DELETE, AUDITPROJECT_CANCEL } from './graphql/Mutation'
import { useLazyQuery, useMutation } from '@apollo/client'
import { dateDisplay } from '../../../../utilitys/dateHelper'
import * as _ from 'lodash'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { Alert } from '../../../../components/alert/Alert'
import { encryptInput } from '../../../../utilitys/crypto'
import { ModalNew } from '../../../../components/modal/Modal'
import { TextAreaNew as TextArea } from '../../../../components/textarea/TextArea'
import { RadioGroup, Radio } from '../../../../components/radio/Radio'
import { LabelNew as Label } from '../../../../components/label/Label'
import { InputNew as Input } from '../../../../components/input/Input'
import { displayError } from '../../../../utilitys/helper'

const AuditProjectList = (props) => {
  const { state } = useLocation()
  if (!state) return <Navigate to={'/auditplanning/auditplan/'} />
  /* state.refCode = planCode */
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [codeCancel, setCodeCancel] = useState('')
  const [cancelModal, setCancelModal] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [form] = Form.useForm()
  const [formExport] = Form.useForm()
  const [modalExport, setModalExport] = useState(false)
  const [codeExport, setCodeExport] = useState('')
  const [waterMark, setWaterMark] = useState(false)
  useEffect(() => {
    setDataLists([])
    setCount(0)
  }, [])
  const [auditProjectExportFn, auditProjectExport] = useLazyQuery(AUDITPROJECT_EXPORT)
  useEffect(() => {
    if (auditProjectExport.data) {
      let response = auditProjectExport?.data?.auditProjectExport
      if (response) {
        if (response.message === 'succcess') {
          window.open(`${response.fileUrl}`, '_blank')
          setModalExport(false)
        } else {
          showAlertNotFoundError()
          setModalExport(false)
        }
      }
    }
    if (auditProjectExport.error) {
      showAlertLoadDataError(displayError(auditProjectExport.error))
    }
  }, [auditProjectExport.data])
  const [cencalDataFn, cancelData] = useMutation(AUDITPROJECT_CANCEL)
  useEffect(() => {
    if (cancelData.data) {
      let response = cancelData?.data?.auditProjectsCancel
      if (response) {
        setCodeCancel('')
        showAlertCancelSuccess()
      }
    }
    if (cancelData.error) {
      setCodeCancel('')
      showAlertCancelError(displayError(cancelData.error))
    }
  }, [cancelData.data])

  const [deleteDataFn, deleteData] = useMutation(AUDITPROJECT_DELETE)
  useEffect(() => {
    if (deleteData.data) {
      let response = deleteData?.data?.auditProjectsDelete
      if (response) {
        showAlertDeleteSuccess()
      }
    }
    if (deleteData.error) {
      showAlertDeleteError(displayError(deleteData.error))
    }
  }, [deleteData.data])
  const [getAuditProjectPagenationFn, getAuditProjectPagenation] = useLazyQuery(GET_AUDITPROJECT_PAGINATION)

  useEffect(() => {
    if (getAuditProjectPagenation?.data) {
      let response = getAuditProjectPagenation?.data?.getAuditProjectPagenation
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data, i) => {
          return {
            key: data.projectCode,
            isActive: data.isActive === 1 ? t('ใช้งาน') : 'ไม่ใช้งาน',
            no: (page - 1) * limit + i + 1,
            projectName:
              (data.projectCode ? data.projectCode : '') +
              ' : ' +
              (localStorage.getItem('lang') === 'th'
                ? data.projectNameTH
                  ? data.projectNameTH
                  : ''
                : data.projectNameEN
                ? data.projectNameEN
                : ''),
            projectRefName:
              localStorage.getItem('lang') === 'th'
                ? data.projectRefNameTH
                  ? data.projectRefNameTH
                  : ''
                : data.projectRefNameEN
                ? data.projectRefNameEN
                : '',
            dateStart: data.dateStart ? dateDisplay(data.dateStart) : '',
            dateEnd: data.dateEnd ? dateDisplay(data.dateEnd) : '',
            organizationName:
              localStorage.getItem('lang') === 'th'
                ? data.organizationNameTH
                  ? data.organizationNameTH
                  : ''
                : data.organizationNameEN
                ? data.organizationNameEN
                : '',
            personnelName:
              localStorage.getItem('lang') === 'th'
                ? (data.namePrefixTH ? data.namePrefixTH : '') +
                  (data.firstNameTH ? data.firstNameTH : '') +
                  ' ' +
                  (data.lastNameTH ? data.lastNameTH : '')
                : (data.namePrefixEN ? data.namePrefixEN : '') +
                  (data.firstNameEN ? data.firstNameEN : '') +
                  ' ' +
                  (data.lastNameEN ? data.lastNameEN : ''),
            approveStatusName:
              localStorage.getItem('lang') === 'th'
                ? data.approveStatusNameTH
                  ? data.approveStatusNameTH
                  : ''
                : data.approveStatusNameEN
                ? data.approveStatusNameEN
                : '',
            projectStatusName:
              localStorage.getItem('lang') === 'th'
                ? data.projectStatusNameTH
                  ? data.projectStatusNameTH
                  : ''
                : data.projectStatusNameEN
                ? data.projectStatusNameEN
                : '',
            cancelStatus:
              data.cancelStatus === 0
                ? t('ปกติ')
                : data.cancelStatus === 1
                ? t('ขอยกเลิกโครงการ')
                : data.cancelStatus === 2
                ? t('อนุมัติยกเลิกโครงการ')
                : data.cancelStatus === 3
                ? t('ไม่อนุมัติยกเลิกโครงการ')
                : '',
            cancelBTN: (
              <>
                <ButtonNew
                  type="moreHorizontalInTableOrange"
                  roles={{ type: 'edit', menu: props.menu }}
                  disabled={data.cancelStatus === 1 || data.cancelStatus === 2 ? true : false}
                  onClick={() => {
                    form.resetFields()
                    setCodeCancel(data.projectCode)
                    setCancelModal(true)
                  }}
                >
                  {t('ขออนุมัติการยกเลิกการตรวจสอบ')}
                </ButtonNew>
              </>
            ),
            action: (
              <>
                <div style={{ textAlign: 'center' }}>
                  <ButtonGroup
                    menu={props.menu}
                    icons={[
                      {
                        type: 'download',
                        onClick: () => {
                          setCodeExport(data.projectCode)
                          setModalExport(true)
                        },
                      },
                      {
                        type: 'view',
                        onClick: () => {
                          navigate('/auditplanning/auditplan/auditproject/general/view', {
                            state: { refCode: data.projectCode },
                            /* state.refCode = projectCode */
                          })
                        },
                      },
                    ]
                      .concat(
                        data.cancelStatus !== 2 &&
                          // data.approveStatusCode !== '20' &&
                          data.approveStatusCode !== '50'
                          ? [
                              {
                                type: 'edit',
                                onClick: () => {
                                  navigate('/auditplanning/auditplan/auditproject/general/edit', {
                                    state: { refCode: data.projectCode },
                                    /* state.refCode = projectCode */
                                  })
                                },
                              },
                            ]
                          : [],
                      )
                      .concat(
                        data.cancelStatus !== 2 && data.approveStatusCode !== '20' && data.approveStatusCode !== '50'
                          ? [
                              {
                                type: 'delete',
                                onClick: () => {
                                  showConfirmDelete(data.projectCode)
                                },
                              },
                            ]
                          : [],
                      )}
                  />
                </div>
              </>
            ),
          }
        }),
      )
    }
    if (getAuditProjectPagenation.error) {
      showAlertLoadDataError(displayError(getAuditProjectPagenation.error))
    }
  }, [getAuditProjectPagenation?.data])

  const showAlertNotFoundError = () => {
    Alert({
      type: 'error',
      title: t('ไม่พบข้อมูล'),
      onOk() {},
    })
  }

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showAlertDeleteSuccess = () => {
    Alert({
      type: 'success',
      title: t('ลบสำเร็จ'),
      onOk() {
        getAuditProjectPagenation.refetch()
      },
    })
  }

  const showAlertDeleteError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถลบข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showConfirmDelete = (projectCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        auditPlanDelete(projectCode)
      },
      onCancel() {},
    })
  }

  const showAlertCancelSuccess = () => {
    Alert({
      type: 'success',
      title: t('ยกเลิกสำเร็จ'),
      onOk() {
        setCancelModal(false)
        getAuditProjectPagenation.refetch()
      },
    })
  }

  const showAlertCancelError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถยกเลิกรายการได้'),
      content: t(text),
      onOk() {
        setCancelModal(false)
      },
    })
  }

  const showConfirmCancel = () => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการยกเลิก ?'),
      onOk() {
        form.submit()
      },
      onCancel() {},
    })
  }

  const onSubmitCancel = (value) => {
    cencalDataFn({
      variables: {
        input: encryptInput({
          menuCode: 'AP01',
          cancelRemark: value.cancelRemark,
          projectCode: codeCancel,
          lang: localStorage.getItem('lang'),
        }),
      },
      fetchPolicy: 'no-cache',
    })
    setCancelModal(false)
  }

  const columns = [
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('ลำดับ')}</div>,
      dataIndex: 'no',
      width: '80px',
      fixed: 'left',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('โครงการ')}</div>,
      dataIndex: 'projectName',
      width: '250px',
      fixed: 'left',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('แหล่งที่มาของโครงการ')}</div>,
      dataIndex: 'projectRefName',
      width: '200px',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('วันที่เริ้มต้น')}</div>,
      dataIndex: 'dateStart',
      width: '110px',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('วันที่สิ้นสุด')}</div>,
      dataIndex: 'dateEnd',
      width: '110px',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('ทีมรับผิดชอบ')}</div>,
      dataIndex: 'organizationName',
      width: '250px',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('ผู้รับผิดชอบหลัก')}</div>,
      dataIndex: 'personnelName',
      width: '200px',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('สถานะการอนุมัติ')}</div>,
      dataIndex: 'approveStatusName',
      width: '150px',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('สถานะดำเนินการ')}</div>,
      dataIndex: 'projectStatusName',
      width: '200px',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('สถานะการขออนุมัติยกเลิก')}</div>,
      dataIndex: 'cancelStatus',
      width: '200px',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('สถานะการใช้งาน')}</div>,
      dataIndex: 'isActive',
      width: '150px',
    },
    {
      title: () => '',
      dataIndex: 'cancelBTN',
      width: '250px',
    },
    {
      title: () => '',
      dataIndex: 'action',
      width: '120px',
    },
  ]

  const auditPlanDelete = async (codeDelete) => {
    deleteDataFn({
      variables: {
        input: encryptInput({
          projectCode: codeDelete,
          menuCode: 'AP01',
        }),
      },
      fetchPolicy: 'no-cache',
    })
  }

  const callApiSearch = () => {
    getAuditProjectPagenationFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          filters: {
            lang: localStorage.getItem('lang'),
            planCode: props.refCode,
            projectTypeCode: '10',
            auditProjectInfor: (
              typeof props.formFilter.getFieldValue('auditProjectInfor') === 'string'
                ? props.formFilter.getFieldValue('auditProjectInfor').trim()
                : props.formFilter.getFieldValue('auditProjectInfor')
            )
              ? typeof props.formFilter.getFieldValue('auditProjectInfor') === 'string'
                ? props.formFilter.getFieldValue('auditProjectInfor').trim()
                : props.formFilter.getFieldValue('auditProjectInfor')
              : null,
            dateStart: props.formFilter.getFieldValue('dateStart') ? props.formFilter.getFieldValue('dateStart') : null,
            dateEnd: props.formFilter.getFieldValue('dateEnd') ? props.formFilter.getFieldValue('dateEnd') : null,
            projectRefCode: props.formFilter.getFieldValue('projectRefCode')
              ? props.formFilter.getFieldValue('projectRefCode')
              : null,
            projectSizeCode: props.formFilter.getFieldValue('projectSizeCode')
              ? props.formFilter.getFieldValue('projectSizeCode')
              : null,
            approveStatusCode: props.formFilter.getFieldValue('approveStatusCode')
              ? props.formFilter.getFieldValue('approveStatusCode')
              : null,
            projectStatusCode: props.formFilter.getFieldValue('projectStatusCode')
              ? props.formFilter.getFieldValue('projectStatusCode')
              : null,
            auditTeamCode: props.formFilter.getFieldValue('auditTeamCode')
              ? props.formFilter.getFieldValue('auditTeamCode')
              : null,
          },
          pagination: { limit, page },
          sort: [],
        }),
      },
    })
  }
  useEffect(() => {
    callApiSearch()
  }, [page, limit, props.searchStatus])

  const auditprojectExportData = (values) => {
    auditProjectExportFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          projectCode: codeExport,
          fileType: values.fileType,
          waterMark: values.waterMark ? values.waterMark : '',
          lang: localStorage.getItem('lang'),
        }),
      },
    })
  }
  return (
    <>
      {(cancelData.loading || deleteData.loading || getAuditProjectPagenation.loading) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Datatable
            columns={columns}
            data={dataLists}
            handleTableChange={() => {}}
            paginationCustom={true}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            searchCustom={true}
            total={count}
            enableScroll={{ x: true, y: true }}
            pageSize={limit}
            page={page}
            btnAdd={
              <>
                <ButtonNew
                  type="plusTableBorderPrimary"
                  roles={{ type: 'add', menu: props.menu }}
                  style={{ marginRight: '12px' }}
                  onClick={() => {
                    navigate('/auditplanning/auditplan/auditproject/general/add', {
                      state: { refCode: state.refCode },
                      /* state.refCode = planCode */
                    })
                  }}
                >
                  {t('เพิ่มโครงการ')}
                </ButtonNew>
              </>
            }
          />
        </Col>
      </Row>
      <ModalNew
        visible={cancelModal}
        onSubmit={showConfirmCancel}
        onClose={setCancelModal}
        testTitle={t('ระบุเหตุผลการยกเลิกการตรวจสอบ')}
      >
        <Row justify="center">
          <Col md={24}>
            <Form
              form={form}
              onFinish={onSubmitCancel}
              onFinishFailed={() => {}}
              autoComplete="off"
              name="auditProjectCancel"
              labelAlign="left"
              layout="vertical"
            >
              <Form.Item
                name="cancelRemark"
                label={<Label type="tab-header-inactive">{t('เหตุผลการยกเลิกการตรวจสอบ')}</Label>}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระบุเหตุผลการยกเลิกการตรวจสอบ'),
                  },
                  {
                    max: 200,
                    message: t('กรุณาระบุเหตุผลการยกเลิกการตรวจสอบน้อยกว่าหรือเท่ากับ 200 ตัวอักษร'),
                  },
                ]}
              >
                <TextArea placeholder={t('ระบุเหตุผลการยกเลิกการตรวจสอบ')} />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </ModalNew>
      <ModalNew
        visible={modalExport}
        onSubmit={() => {
          formExport.submit()
        }}
        onClose={() => {
          setModalExport(false)
        }}
        testTitle={t('ส่งออกข้อมูลโครงการ')}
      >
        <Form
          form={formExport}
          onFinish={auditprojectExportData}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="auditprojectExportForm"
        >
          <Form.Item
            label={<Label type="tab-header-inactive">{t('ประเภทรายงาน')}</Label>}
            name="fileType"
            initialValue={'pdf'}
            onClick={(e) => {
              if (e.target.value) {
                if (e.target.value !== 'pdf') {
                  setWaterMark(true)
                } else {
                  setWaterMark(false)
                }
              }
            }}
          >
            <RadioGroup>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="pdf">
                    <Label type="tab-header-inactive">{t('PDF')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="word">
                    <Label type="tab-header-inactive">{t('Word')}</Label>
                  </Radio>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="excel">
                    <Label type="tab-header-inactive">{t('Excel')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="csv">
                    <Label type="tab-header-inactive">{t('CSV')}</Label>
                  </Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
          <Form.Item
            name="waterMark"
            label={<Label type="tab-header-inactive">{t('ลายน้ำ')}</Label>}
            hidden={waterMark}
          >
            <Input placeholder={t('ระบุลายน้ำ (เฉพาะ PDF)')} />
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}

export default AuditProjectList
