import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { ModalNew } from '../../../../components/modal/Modal'
import { Alert } from '../../../../components/alert/Alert'
import { CREATE_TRAINING_PERSONNELS } from '../graphql/Mutation'
import { GET_ONE_TRAINING } from '../graphql/Query'
import { TextAreaNew } from '../../../../components/textarea/TextArea.jsx'
import { InputNew } from '../../../../components/input/Input.jsx'
import { UploadNew } from '../../../../components/upload/Upload.jsx'
import { Datepicker } from '../../../../components/datepicker/Datepicker.jsx'
import { LabelNew } from '../../../../components/label/Label.jsx'
import SelectTrainTypes from '../../../../components/inputfromapi/selecttraintypes/SelectTrainTypes.jsx'
import SelectYear from '../../../../components/inputfromapi/selectyear/SelectYear.jsx'
import { useMutation, useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { regexEngNoSpace } from '../../../../utilitys/helper'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayError } from '../../../../utilitys/helper'

const Responesive = {
  sm: 24,
  md: 24,
  lg: 24,
}

const TrainingModal = (props) => {
  const { t } = useTranslation()
  const { formType, refCode, getTrainingPagenation, codeEdit, open, colse } = props
  const [form] = Form.useForm()
  const [limitSize, setLimitSize] = useState()
  const [file, setFile] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])
  const [idFileDefault, setIdFileDefault] = useState([])
  const [visible, setVisible] = useState(false)
  const [getTrainingFn, { data: getData, loading: loadingData, error: errorData }] = useLazyQuery(GET_ONE_TRAINING)
  const [createPersonnelTraining, { loading: loadingPersonnelTraining, error: errorTraining }] =
    useMutation(CREATE_TRAINING_PERSONNELS)
  useEffect(() => {
    setFile([])
    setIdFileDefault([])
    if (visible && formType === 'edit')
      getTrainingFn({
        variables: {
          input: {
            personnelTrainId: encryptInput(codeEdit),
          },
        },
      })
  }, [visible])

  useEffect(() => {
    if (open) {
      setVisible(open)
    } else {
      setVisible(false)
    }
  }, [open])

  function onClose(e) {
    colse(e)
  }

  const onFinish = (values) => {
    fnConfirm(values)
  }
  const fnConfirm = (values) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        onSubmit(values)
      },
      onCancel() {
        onClose(false)
      },
    })
  }
  const onSubmit = async (values) => {
    let dataInput = {
      personnelTrainId: codeEdit,
      personnelCode: refCode,
      year: parseInt(values.year),
      dateStart: values.dateStart,
      dateEnd: values.dateEnd,
      course: values.course,
      trainTypeCode: values.trainTypeCode || '',
      lecturer: values.lecturer || '',
      hour: parseInt(values.hour),
      remark: values.remark || '',
      attachFile: file.join(),
      idFileDelete: idFileDelete.join(),
    }
    if (formType === 'add') {
      dataInput['action'] = 'add'
    } else if (formType === 'edit') {
      dataInput['action'] = 'edit'
    }
    await createPersonnelTraining({
      variables: {
        input: encryptInput(dataInput),
      },
    }).then((res) => {
      if (res.errors) {
        Alert({
          type: 'error',
          title: t('ไม่สามารถบันทึกข้อมูลได้'),
          content: t(displayError(res.errors[0])),
          onOk() {},
        })
      } else {
        Alert({
          type: 'success',
          title: t('บันทึกข้อมูลสำเร็จ'),
          onOk() {
            onClose(false)
            getTrainingPagenation.refetch()
          },
        })
      }
    })
  }

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: text,
      onOk() {},
    })
  }
  useEffect(() => {
    if (errorTraining && errorTraining.message) {
      showAlertError(t(displayError(errorTraining)))
    }
    if (errorData && errorData.message) {
      showAlertError(t(displayError(errorData)))
    }
  }, [errorTraining, errorData])

  useEffect(() => {
    // Get value From your API'
    if (formType === 'edit' || formType === 'view') {
      // Set Form value on formType = UPDATE
      if (getData) {
        form.setFieldsValue({
          personnelCode: getData.getTraining.personnelCode,
          year: parseInt(getData.getTraining.year),
          dateStart: getData.getTraining.dateStart,
          dateEnd: getData.getTraining.dateEnd,
          course: getData.getTraining.course,
          trainTypeCode: getData.getTraining.trainTypeCode,
          lecturer: getData.getTraining.lecturer,
          hour: parseInt(getData.getTraining.hour),
          remark: getData.getTraining.remark,
        })
        if (getData.getTraining.attachFile) {
          setFile(() => {
            return getData.getTraining.attachFile.split(',').map((item) => {
              return parseInt(item)
            })
          })

          setIdFileDefault(() => {
            return getData.getTraining.attachFile.split(',').map((item) => {
              return parseInt(item)
            })
          })
        }
        setIdFileDelete([])
      }
    } else if (formType === 'add') {
      // Set Form value on formType = CREATE
      setFile([])
      setIdFileDefault([])
      setIdFileDelete([])
      form.resetFields()
    }
  }, [getData, visible])

  const validDate = () => {
    let valid = false
    if (form.getFieldValue('dateStart') && form.getFieldValue('dateEnd')) {
      let dateStart = new Date(form.getFieldValue('dateStart'))
      let dateEnd = new Date(form.getFieldValue('dateEnd'))
      if (dateStart <= dateEnd) {
        setErrorField('dateStart', false)
        setErrorField('dateEnd', false)
        valid = true
      } else {
        setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นน้อยกว่าวันที่สิ้นสุด'))
        setErrorField('dateEnd', t('กรุณาเลือกวันที่สิ้นสุดมากกว่าวันที่เริ่มต้น'))
      }
    } else {
      valid = true
    }
    return valid
  }

  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((er) => {
      const nameField = er.name[0] || ''
      const message = er.errors[0] || ''
      if (nameField === 'dateStart') setErrorField('dateStart', message)
      if (nameField === 'dateEnd') setErrorField('dateEnd', message)
    })
  }

  // onValuesChange
  const onValuesChange = () => {}
  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }
  return (
    <>
      {loadingPersonnelTraining || loadingData ? <SpinnersNew /> : ''}
      <ModalNew
        visible={open}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        testTitle={
          formType !== 'view' ? (formType === 'add' ? t('เพิ่มการฝึกอบรม') : t('แก้ไขการฝึกอบรม')) : t('ดูการฝึกอบรม')
        }
        type="medium"
        okButtonProps={formType === 'view' ? { style: { display: 'none' } } : {}}
      >
        <Row gutter={[16, 16]}>
          <Col {...Responesive}>
            <Row style={{ marginTop: 24 }}>
              <Col md={24} lg={24}>
                <Form
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onValuesChange={onValuesChange}
                  autoComplete="off"
                  labelAlign="left"
                  labelCol={{
                    sm: {
                      span: 10,
                      offset: 3,
                    },
                    md: {
                      span: 9,
                      offset: 3,
                    },
                    xl: {
                      span: 9,
                      offset: 3,
                    },
                  }}
                  wrapperCol={{
                    sm: 24,
                    md: 12,
                    xl: 12,
                    align: 'left',
                  }}
                  layout="horizontal"
                  name="trainingForm"
                >
                  <Row>
                    <Col md={18}>
                      <Form.Item
                        name="year"
                        label={t('ปีที่อบรม')}
                        initialValue={new Date().getFullYear()}
                        rules={[{ required: true, message: t('กรุณาระบุปีที่อบรม') }]}
                      >
                        <SelectYear
                          placeholder={t('เลือกปีที่อบรม')}
                          formname="trainingForm"
                          disabled={formType === 'view' ? true : false}
                          handleChange={(e) =>
                            form.setFieldsValue({
                              year: e?.value,
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="dateStart"
                        label={t('วันที่เข้าร่วม(เริ่มต้น)')}
                        rules={[{ required: true, message: t('กรุณาระบุวันที่เริ่มต้น') }]}
                      >
                        <Datepicker
                          language="th"
                          disabled={formType === 'view' ? true : false}
                          setValueDateFn={async (data) => {
                            form.setFieldsValue({ dateStart: await data }), validDate()
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="dateEnd"
                        label={t('วันที่เข้าร่วม(สิ้นสุด)')}
                        rules={[{ required: true, message: t('กรุณาระบุวันที่สิ้นสุด') }]}
                      >
                        <Datepicker
                          language="th"
                          disabled={formType === 'view' ? true : false}
                          setValueDateFn={async (data) => {
                            form.setFieldsValue({ dateEnd: await data }), validDate()
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name="course"
                        label={t('ชื่อการฝึกอบรม/สัมมนา/ประชุม')}
                        rules={[{ required: true, message: t('กรุณาระบุชื่อการฝึกอบรม/สัมนา/ประชุม') }]}
                      >
                        <TextAreaNew
                          placeholder={t('ชื่อการฝึกอบรม/สัมมนา/ประชุม')}
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          disabled={formType === 'view' ? true : false}
                        />
                      </Form.Item>
                      <Form.Item name="trainTypeCode" label={t('ประเภท')}>
                        <SelectTrainTypes
                          formname="trainingForm"
                          placeholder={t('เลือกประเภทอบรม')}
                          disabled={formType === 'view' ? true : false}
                          handleChange={(e) =>
                            form.setFieldsValue({
                              trainTypeCode: e?.value,
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item name="lecturer" label={t('ชื่อวิทยากร')}>
                        <InputNew placeholder={t('ชื่อวิทยากร')} disabled={formType === 'view' ? true : false} />
                      </Form.Item>
                      <Form.Item
                        name="hour"
                        label={t('จำนวนชั่วโมง')}
                        rules={[
                          { required: true, message: t('กรุณาระบุจำนวนชั่วโมง') },
                          {
                            pattern: regexEngNoSpace,
                            message: t(`ตัวเลขเท่านั้น`),
                          },
                        ]}
                      >
                        <InputNew placeholder={t('จำนวนชั่วโมง')} disabled={formType === 'view' ? true : false} />
                      </Form.Item>
                      <Form.Item name="remark" label={t('รายละเอียดเพิ่มเติม')}>
                        <TextAreaNew
                          placeholder={t('รายละเอียดเพิ่มเติม')}
                          autoSize={{ minRows: 4, maxRows: 4 }}
                          disabled={formType === 'view' ? true : false}
                        />
                      </Form.Item>
                      <Form.Item label={t('ไฟล์แนบ')}>
                        <UploadNew
                          type="mutiple"
                          setIdFile={setFile}
                          idFile={file}
                          actionType={formType === 'add' ? 'create' : formType}
                          idFileDelete={idFileDelete}
                          defaults={idFileDefault}
                          setIdFileDelete={setIdFileDelete}
                          typeFile="file"
                          disabled={formType === 'view' ? true : false}
                          setLimitSize={setLimitSize}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col md={{ span: 23, offset: 1 }}>
                    <LabelNew type="error">
                      {t(`ไฟล์ต้องมีนามสกุล zip, pdf, png, jpg, jpeg, doc, docx, csv, xls, xlsx, ppt, pptx
                      และขนาดไฟล์ทั้งหมดรวมกันต้องไม่เกิน ${limitSize} MB`)}
                    </LabelNew>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalNew>
    </>
  )
}

export default TrainingModal
