import styled from 'styled-components'

// import { colors } from '../../configs/styles.config'

export const RowStyle = styled.div`
  .table-row-color {
    background-color: #f2f7fc;
    color: #2b4990;
  }
  .ant-table-thead .ant-table-cell {
    background-color: #f2f7fc;
    color: #2b4990;
  }
  .ant-table-tbody tr.ant-table-expanded-row > td {
    background-color: #992e2e !important;
  }
  .ant-table-tbody tr.ant-table-expanded-row:hover > td {
    background-color: #992e2e !important;
  }
`
export const TableRow = styled.div`
  .table-row-color {
    background-color: #f2f7fc;
    color: #2b4990;
  }
`
export const ExpandStyle = styled.div`
  .ant-table-tbody tr.ant-table-expanded-row > td {
    margin: 0;
    padding: 0;
  }
  .ant-table-tbody tr.ant-table-expanded-row:hover > td {
    margin: 0;
    padding: 0;
    background-color: none !important;
  }
`
