import { gql } from '@apollo/client'

export const GET_SELECT_ACTION_PLAN_PERSONNELS = gql`
  query GetSelectActionPlanPersonnels($input: SelectActionPlanPersonnelsInput!) {
    getSelectActionPlanPersonnels(input: $input) {
      actPlanPersonId
      actionPlanId
      projectCode
      personnelCode
      fullName
    }
  }
`
