import { gql } from '@apollo/client'

export const GET_USERS_PAGINATION = gql`
  query UsersPaginationGet($input: UserPaginationInput!) {
    usersPaginationGet(input: $input) {
      result {
        no
        personnelCode
        email
        username
        fullName
        fullNameEN
        isActiveName
        remark
        userId
        organizationNameTH
        organizationNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_SYSTEM_USERS = gql`
  query SystemUsersGet($userId: Float) {
    systemUsersGet(userId: $userId) {
      userId
      email
      username
      firstname
      lastname
      firstnameEN
      lastnameEN
      userType
      personnelCode
      organizationCode
      organizationNameTH
      organizationNameEN
      startDate
      expireDate
      isActive
      remark
      userLock
      systemUserRoles {
        roleCode
      }
    }
  }
`
export const GET_SYSTEM_USERS_EXPORT = gql`
  query SystemUserExport($input: UserFormExportInput!) {
    systemUserExport(input: $input) {
      message
      fileUrl
    }
  }
`

export const GET_USER_LDAP = gql`
  query getUserLdap($input: UserLdapInput!) {
    getUserLdap(input: $input) {
      users {
        initials
        dn
        distinguishedName
        userPrincipalName
        sAMAccountName
        mail
        lockoutTime
        whenCreated
        pwdLastSet
        userAccountControl
        employeeID
        sn
        givenName
        initials
        cn
        displayName
        comment
        description
      }
      ldapConnection
    }
  }
`
