import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ControlFormList from './controlform/ControlFormList'
import ControlFormCreate from './controlform/ControlFormCreate'
import ControlFormEdit from './controlform/ControlFormEdit'
import AssessList from './assess/AssessList'
import AssessEdit from './assess/AssessEdit'
import ResultList from './result/ResultList'
import ResultDetail from './result/ResultDetail'

/**
 * @function Router
 * @description
 * Module: Internal Control
 * โมดูล: จัดการข้อมูลประเมินควบคุมภายใน
 * @returns Routes
 */

const Router = () => {
  return (
    <Routes>
      <Route path="/controlform">
        <Route path="" element={<ControlFormList menu={'IC01'} />} />
        <Route path="create" element={<ControlFormCreate menu={'IC01'} />} />
        <Route path="createquestion" element={<ControlFormEdit menu={'IC01'} />} />
        <Route path="edit" element={<ControlFormEdit menu={'IC01'} />} />
        <Route path="view" element={<ControlFormEdit menu={'IC01'} />} />
        <Route path="copy" element={<ControlFormEdit menu={'IC01'} />} />
      </Route>
      <Route path="/assess">
        <Route path="" element={<AssessList menu={'IC02'} />} />
        <Route path="edit" element={<AssessEdit menu={'IC02'} />} />
        <Route path="view" element={<AssessEdit menu={'IC02'} />} />
      </Route>
      <Route path="/result">
        <Route path="" element={<ResultList menu={'IC03'} />} />
        <Route path="view" element={<ResultDetail menu={'IC03'} />} />
      </Route>
    </Routes>
  )
}

export default Router
