import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { ButtonNew } from '../../../../../components/button/Button'
import { ModalNew } from '../../../../../components/modal/Modal'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { InputNew } from '../../../../../components/input/Input'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { Alert } from '../../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'
import { UPDATE_RATE_FAC_GROUP_DETAIL } from '../../graphql/Mutation'

const RateFacGroupModal = (props) => {
  const formname = 'RateFacGroupModal'
  const labelCol = { sm: 16, md: 8, lg: 8, xl: 8 }
  const wrapperCol = { sm: 14, md: 16, lg: 16, xl: 16, align: 'left' }

  const { open, close, menu, isRateFacGroupName, rateFacGroupDetail, rcmResultId, getData } = props

  const [form] = Form.useForm()
  const { t } = useTranslation()

  const [visible, setVisible] = useState(false)

  const [updateRateFacGroupDetailFn, updateRateFacGroupDetail] = useMutation(UPDATE_RATE_FAC_GROUP_DETAIL)

  useEffect(() => {
    setVisible(open)
  }, [open])

  useEffect(() => {
    if (isRateFacGroupName) {
      form.setFieldsValue({
        rateFacGroupName: isRateFacGroupName,
        rateFacGroupDetail: rateFacGroupDetail,
      })
    }
  }, [isRateFacGroupName])

  useEffect(() => {
    if (updateRateFacGroupDetail?.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk: () => {
          getData(updateRateFacGroupDetail?.data)
          form.resetFields()
          onClose()
        },
        onCancel: () => {},
      })
    }

    if (updateRateFacGroupDetail?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: displayError(updateRateFacGroupDetail?.error),
        onOk() {},
      })
    }
  }, [updateRateFacGroupDetail?.data])

  const onFinish = (data) => {
    const objData = encryptInput({
      rcmResultId: rcmResultId,
      rateFacGroupDetail: data?.rateFacGroupDetail ? data?.rateFacGroupDetail : null,
    })

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการแก้ไข ?'),
      onOk: () => {
        updateRateFacGroupDetailFn({ fetchPolicy: 'no-cache', variables: { input: objData } })
      },
      onCancel: () => {},
    })
  }

  const onClose = () => {
    form.resetFields()
    setVisible(false)
    close(false)
  }

  return (
    <>
      {updateRateFacGroupDetail?.loading && <SpinnersNew />}

      <ModalNew
        onSubmit={() => {}}
        onClose={() => onClose()}
        visible={visible}
        testTitle={t('แก้ไขกลุ่มความเสี่ยงกระบวนการ')}
        type="medium"
        width={720}
        footer={null}
      >
        <Col sm={24} md={20} lg={20} style={{ margin: 'auto' }}>
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            labelAlign="left"
            labelCol={{ ...labelCol }}
            wrapperCol={{ ...wrapperCol }}
            layout="horizontal"
            name={formname}
          >
            <Form.Item
              name="rateFacGroupName"
              label={<Label type="tab-header-inactive">{t('กลุ่มความเสี่ยงกระบวนการเดิม')}</Label>}
            >
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item
              name="rateFacGroupDetail"
              label={<Label type="tab-header-inactive">{t('กลุ่มความเสี่ยงกระบวนการ')}</Label>}
            >
              <TextAreaNew
                placeholder={t('ระบุรายละเอียดกลุ่มความเสี่ยงกระบวนการ')}
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Form.Item>
            <Col align="center">
              <Space size={[8, 8]} wrap>
                <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                  {t('บันทึก')}
                </ButtonNew>
                <ButtonNew type="back" onClick={() => onClose()}>
                  {t('ยกเลิก')}
                </ButtonNew>
              </Space>
            </Col>
          </Form>
        </Col>
      </ModalNew>
    </>
  )
}

RateFacGroupModal.propTypes = {
  menu: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default RateFacGroupModal
