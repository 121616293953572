import { gql } from '@apollo/client'

export const ACTIONEXPAIN_LIST = gql`
  query actionExplainList($input: InputPaginationForActionExplain!) {
    actionExplainList(input: $input) {
      result {
        projectCode
        projectNameTH
        projectNameEN
        projectRefNameTH
        projectRefNameEN
        issueStatusTH
        issueStatusEN
        hasAction
      }
      count
      page
      limit
    }
  }
`

export const DATA_FOLLOW_UP_ACTIVITY_LIST_PAGINATION = gql`
  query dataFollowUpActivityListPagination($input: InputPaginationForActionExplain!) {
    dataFollowUpActivityListPagination(input: $input) {
      result {
        issueId
        issueTH
        issueEN
        issueStatusNameTH
        issueStatusNameEN
        subItem {
          actionId
          suggestTH
          suggestEN
          activityTH
          activityEN
          actionStatusNameTH
          actionStatusNameEN
          planDetailTH
          planDetailEN
          expectDate
          organizationNameTH
          organizationNameEN
          ideaStatusCode
          ideaStatusNameTH
          ideaStatusNameEN
          reasonTH
          reasonEN
          formSucceed
        }
      }
      count
      page
      limit
    }
  }
`

export const GET_ACTION_EXPLAIN_REPORT_EXPORT = gql`
  query getActionExplainReportExport($input: InputActionExplainReportExport!) {
    getActionExplainReportExport(input: $input) {
      message
      fileUrl
    }
  }
`

export const GET_ACTION_EXPLAIN_BY_ID = gql`
  query getActionExplainById($input: InputActionExplainById!) {
    getActionExplainById(input: $input) {
      actionId
      projectCode
      activityId
      actionStatusCode
      issueId
      suggestId
      rateFacProCode
      planDetailTH
      planDetailEN
      expectDate
      actionDate
      actionDetailTH
      actionDetailEN
      remarkTH
      remarkEN
      files
      fileDetails
      refFile {
        fileId
        originalname
        name
      }
      formSucceed
    }
  }
`
