import { useEffect, useState } from 'react'

export const useLanguage = () => {
  const [isLang, setIsLang] = useState(localStorage.getItem('lang'))

  useEffect(() => {
    setIsLang(localStorage.getItem('lang'))
  }, [localStorage.getItem('lang')])

  return [isLang]
}
