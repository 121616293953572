import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_RISK_LEVEL_YEAR } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectRiskLevelYear = (props) => {
  const { multiple, filters, placeholder, formname, handleChange, ...otherProp } = props
  const { riskLevelYearId, riskLevelCode, riskLevelYear, riskLevelYearName, isActive } = filters || {}

  const [isData, isSetData] = useState([])

  useEffect(() => {
    callGet({ variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) } })
  }, [riskLevelYearId, riskLevelCode, riskLevelYear, riskLevelYearName, isActive])

  const [callGet, { data, loading }] = useLazyQuery(GET_MASTER_RISK_LEVEL_YEAR)

  useEffect(() => {
    if (data) {
      isSetData(
        data?.getMasterRiskLevelYear?.map((item) => {
          return {
            value: item?.riskLevelYear,
            label: displayText(item?.riskLevelYearName, item?.riskLevelYearName),
            source: item,
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        multiple={multiple}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        loading={loading}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          multiple ? handleChange(value) : handleChange(_.find(isData, (data) => data.value === value))
        }}
      />
    </>
  )
}

export default SelectRiskLevelYear
