import React, { useState } from 'react'
import moment from 'moment'
import { Row, Col } from 'antd'
import { Timepicker } from '../../../components/timepicker/Timepicker'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'

const TimepickerIndex = () => {
  const [value, setValue] = useState(null)

  const code = `
    import React, { useState } from 'react'
    import { Timepicker } from '../../../components/timepicker/Timepicker'

    /*
      Passing props into Timepicker Component
      ---------------------------------------------------------------
      onChangeTime: lifting up time, return default moment syntax [function]
    */

    const [value, setValue] = useState(null)

    export default function App(props) {
      return (
        <Timepicker onChangeTime={(time) => setValue(time)} />
        {console.log("TIME: ", value)}
      )
    }
  `

  return (
    <div className="site-layout-background">
      <Row style={{ marginBottom: '20px' }}>
        <Col span={4} style={{ margin: 'auto 2rem' }}>
          Time Picker
        </Col>
        <Col span={6} style={{ margin: 'auto 2rem' }}>
          <Timepicker onChangeTime={(time) => setValue(time)} />
        </Col>
        <Col span={6} style={{ margin: 'auto 2rem' }}>
          {value && moment(value).format('HH:mm:ss')}
        </Col>
      </Row>
      <CodeBlock code={code} />
    </div>
  )
}

export default TimepickerIndex
