import { useLazyQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import 'react-calendar-timeline/lib/Timeline.css'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Alert } from '../../../../components/alert/Alert'
import { CalendarTimeline } from '../../../../components/calendartimeline/CalendarTimeline'
import { LabelNew as Label } from '../../../../components/label/Label'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { dateDisplay } from '../../../../utilitys/dateHelper'
import { dateFormat, displayError, displayText } from '../../../../utilitys/helper'

import {
  AUDIT_PROJECT_GET_CALENDARDETAILS1,
  AUDIT_PROJECT_GET_CALENDARDETAILS2,
  GET_AUDITPROJECT,
  GET_MASTER_OPERATION_STATUS
} from './graphql/Query'

const AuditProject = (props) => {
  const location = useLocation()
  const { year } = location.state

  const color = {
    graphHeaderMonthNameBackground: '#C1C8C7',
    graphHeaderMonthNameText: '#FFFFFF',
    graphHeaderDatehNameBackground: '#FFFFFF',
    graphHeaderDatehNameText: '#000000',
    graphCurrentDateBackground: '#B2B2B2',
    graphSatAndSunDateBackground: '#e8d595',
    HolidayBackground: '#FDFFBC',
    graphMeetingBackground: '#FFFDDD',
    statusNoapprove: '#C1C8C7',
    statusNoActionPlan: '#FAE0D8',
    statusActionPlanDontApprove: '#f7d4de',
    statusWaitForProcess: '#a0c5e2',
    statusProcessing: '#2676bb',
    statusProcessed: '#54a648',
    statusLate: '#C43D56'
  }
  const { t } = useTranslation()
  const [operationStatus, setOperationStatus] = useState([])
  const [operationStatusName10, setOperationStatusName10] = useState('')
  const [operationStatusName20, setOperationStatusName20] = useState('')
  const [operationStatusName30, setOperationStatusName30] = useState('')
  const [groups, setGroups] = useState([])
  const [items, setItems] = useState([])

  const [getMasterOperationStatusFn, getMasterOperationStatus] = useLazyQuery(GET_MASTER_OPERATION_STATUS)

  const [auditProjectGetCalendarDetailsType1Fn, auditProjectGetCalendarDetailsType1] = useLazyQuery(
    AUDIT_PROJECT_GET_CALENDARDETAILS1
  )
  const [auditProjectGetCalendarDetailsType2Fn, auditProjectGetCalendarDetailsType2] = useLazyQuery(
    AUDIT_PROJECT_GET_CALENDARDETAILS2
  )
  const [getAuditProjectFn, getAuditProject] = useLazyQuery(GET_AUDITPROJECT)
  useEffect(() => {
    if (getAuditProject?.data && operationStatus.length) {
      let response = getAuditProject?.data?.getAuditProject
      setGroups(
        response.map((data) => {
          return {
            id: data.projectCode,
            title: data.projectCode + ' : ' + displayText(data.projectNameTH, data.projectNameEN),
            dateStart: dateDisplay(data.dateStart),
            dateEnd: dateDisplay(data.dateEnd)
          }
        })
      )
      let item = response
        .map((data) => {
          return {
            id: data.projectCode,
            group: data.projectCode,
            title: {
              statusActionPlan: data.statusActionPlan,
              approveStatusCode: data.approveStatusCode,
              late: false,
              countStatus10: Math.floor(
                data.countProjectStepStatusAll
                  ? (data.countProjectStepStatus10 / data.countProjectStepStatusAll) * 100
                  : 0
              ),
              countStatus20: Math.floor(
                data.countProjectStepStatusAll
                  ? (data.countProjectStepStatus20 / data.countProjectStepStatusAll) * 100
                  : 0
              ),
              countStatus30: Math.floor(
                data.countProjectStepStatusAll
                  ? (data.countProjectStepStatus30 / data.countProjectStepStatusAll) * 100
                  : 0
              )
            },
            start_time: dateFormat(data.dateStart),
            end_time: dateFormat(data.dateEnd)
          }
        })
        .concat(
          response
            .filter((data) => {
              return (
                (data.countProjectStepStatus10 !== 0 || data.countProjectStepStatus20 !== 0) &&
                moment(data.dateEnd) < moment(new Date())
              )
            })
            .map((data) => {
              let dateStart = new Date(moment(data.dateEnd).add(1, 'days'))
              let dateEnd = new Date()
              return {
                id: data.projectCode + '_late',
                group: data.projectCode,
                title: { late: true },
                start_time: dateFormat(dateStart),
                end_time: dateFormat(dateEnd)
              }
            })
        )
      setItems(item)
    }
  }, [getAuditProject.data, operationStatus])

  useEffect(() => {
    if (getMasterOperationStatus?.data) {
      let response = getMasterOperationStatus?.data?.getMasterOperationStatus
      setOperationStatus(
        response.map((data) => {
          return {
            operationStatusCode: data.operationStatusCode,
            operationStatusNameTH: data.operationStatusNameTH,
            operationStatusNameEN: data.operationStatusNameEN
          }
        })
      )
      let status10 = response.find((data) => {
        return data.operationStatusCode === '10'
      })
      let status20 = response.find((data) => {
        return data.operationStatusCode === '20'
      })
      let status30 = response.find((data) => {
        return data.operationStatusCode === '30'
      })
      setOperationStatusName10(
        status10 ? displayText(status10.operationStatusNameTH, status10.operationStatusNameEN) : ''
      )
      setOperationStatusName20(
        status20 ? displayText(status20.operationStatusNameTH, status20.operationStatusNameEN) : ''
      )
      setOperationStatusName30(
        status30 ? displayText(status30.operationStatusNameTH, status30.operationStatusNameEN) : ''
      )
    }
  }, [getMasterOperationStatus.data])
  const callApiSearch = () => {
    getAuditProjectFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          lang: localStorage.getItem('lang'),
          planCode: props.refCode,
          projectTypeCode: '10',
          auditProjectInfor: (
            typeof props.formFilter.getFieldValue('auditProjectInfor') === 'string'
              ? props.formFilter.getFieldValue('auditProjectInfor').trim()
              : props.formFilter.getFieldValue('auditProjectInfor')
          )
            ? typeof props.formFilter.getFieldValue('auditProjectInfor') === 'string'
              ? props.formFilter.getFieldValue('auditProjectInfor').trim()
              : props.formFilter.getFieldValue('auditProjectInfor')
            : null,
          dateStart: props.formFilter.getFieldValue('dateStart') ? props.formFilter.getFieldValue('dateStart') : null,
          dateEnd: props.formFilter.getFieldValue('dateEnd') ? props.formFilter.getFieldValue('dateEnd') : null,
          projectRefCode: props.formFilter.getFieldValue('projectRefCode')
            ? props.formFilter.getFieldValue('projectRefCode')
            : null,
          projectSizeCode: props.formFilter.getFieldValue('projectSizeCode')
            ? props.formFilter.getFieldValue('projectSizeCode')
            : null,
          approveStatusCode: props.formFilter.getFieldValue('approveStatusCode')
            ? props.formFilter.getFieldValue('approveStatusCode')
            : null,
          projectStatusCode: props.formFilter.getFieldValue('projectStatusCode')
            ? props.formFilter.getFieldValue('projectStatusCode')
            : null,
          auditTeamCode: props.formFilter.getFieldValue('auditTeamCode')
            ? props.formFilter.getFieldValue('auditTeamCode')
            : null
        })
      }
    })
  }

  useEffect(() => {
    auditProjectGetCalendarDetailsType1Fn({
      fetchPolicy: 'no-cache'
    })
    auditProjectGetCalendarDetailsType2Fn({
      fetchPolicy: 'no-cache'
    })
    callApiSearch()
    getMasterOperationStatusFn({
      variables: { input: encryptInput({ isActive: 1 }) }
    })
  }, [])

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {}
    })
  }
  useEffect(() => {
    if (getAuditProject.error) {
      showAlertLoadDataError(displayError(getAuditProject.error))
    }
    if (auditProjectGetCalendarDetailsType1.error) {
      showAlertLoadDataError(displayError(auditProjectGetCalendarDetailsType1.error))
    }
    if (auditProjectGetCalendarDetailsType2.error) {
      showAlertLoadDataError(displayError(auditProjectGetCalendarDetailsType2.error))
    }
    if (getMasterOperationStatus.error) {
      showAlertLoadDataError(displayError(getMasterOperationStatus.error))
    }
  }, [
    getAuditProject.error,
    auditProjectGetCalendarDetailsType1.error,
    auditProjectGetCalendarDetailsType2.error,
    getMasterOperationStatus.error
  ])

  return (
    <>
      {(getAuditProject.loading ||
        auditProjectGetCalendarDetailsType1.loading ||
        getMasterOperationStatus.loading ||
        auditProjectGetCalendarDetailsType2.loading) && <SpinnersNew />}

      <Row justify="center">
        <Col span={24}>
          <CalendarTimeline
            headerContent={t('แผนการตรวจสอบ')}
            path="/auditplanning/auditplan/auditproject/general/view"
            groups={groups}
            items={items}
            year={year}
          />
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: '50px' }}>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusNoapprove, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{t('ไม่อนุมัติ')}</Label>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusNoActionPlan, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{t('ยังไม่มีแผนปฏิบัติงาน')}</Label>
            </div>
          </div>
        </Col>
        <Col md={4}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                backgroundColor: color.statusActionPlanDontApprove,
                width: '20px',
                height: '20px',
                marginRight: '20px'
              }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{t('ไม่อนุมัติแผนปฏิบัติงาน')}</Label>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                backgroundColor: color.statusWaitForProcess,
                width: '20px',
                height: '20px',
                marginRight: '20px'
              }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{operationStatusName10}</Label>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusProcessing, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{operationStatusName20}</Label>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusProcessed, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{operationStatusName30}</Label>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusLate, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <Label type="tab-header-inactive">{t('ล่าช้ากว่ากำหนด')}</Label>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default AuditProject
