import { gql } from '@apollo/client'

export const GET_MASTER_REFER_INFORMATIONS = gql`
  query GetMasterReferInformations($input: MasterReferInformationsInput!) {
    getMasterReferInformations(input: $input) {
      refInfoId
      refInfoCode
      refInfoNameTH
      refInfoNameEN
      isActive
    }
  }
`
