import { gql } from '@apollo/client'

export const GETASSESSCOOPERATIONPAGINATION = gql`
  query getAssessCooperationPagination($input: AssessCooperationPaginationInput!) {
    getAssessCooperationPagination(input: $input) {
      result {
        formResultId
        formAssessId
        formCode
        organizationCode
        organizationCode
        organizationNameTH
        organizationNameEN
        operationStatusCode
        responsibilityBy
        makerBy
        makerStatusCode
        successedAt
        successedBy
        reviewAt
        reviewBy
        personnelCode
        formYear
        formNameTH
        formNameEN
        startDate
        endDate
      }
      count
      page
      limit
    }
  }
`

export const GETASSESSCOOPERATIONONE = gql`
  query getAssessCooperationOne($input: AssessCooperationInput!) {
    getAssessCooperationOne(input: $input) {
      formResultId
      formAssessId
      formCode
      organizationCode
      organizationCode
      organizationNameTH
      organizationNameEN
      operationStatusCode
      responsibilityBy
      makerBy
      makerStatusCode
      successedAt
      successedBy
      reviewAt
      reviewBy
      personnelCode
      formYear
      formNameTH
      formNameEN
      startDate
      endDate
      organizationNameTH2
      organizationNameEN2
    }
  }
`

export const GETGROUPSANDQUESTIONSBYASSESSCOOPERATION = gql`
  query getGroupsAndQuestionsByAssessCooperation($input: AssessCooperationInput!) {
    getGroupsAndQuestionsByAssessCooperation(input: $input) {
      resultDetailId
      formResultId
      formGroupId
      formCode
      formSort
      formGroupNameTH
      formGroupNameEN
      formQuestId
      formSortByQ
      formQuestNameTH
      formQuestNameEN
      choiceTypeCode
      choiceGroupCode
      formQuestType
      formRequired
      files
      choiceDetailId
      answerDetail
    }
  }
`

export const GETMASTERCHOICEGROUPDETAILBYASSESSCOOPERATION = gql`
  query getMasterChoiceGroupDetailByAssessCooperation($isActive: Float!) {
    getMasterChoiceGroupDetailByAssessCooperation(isActive: $isActive) {
      choiceDetailId
      choiceDetailNameTH
      choiceDetailNameEN
      choiceGroupCode
      score
      isActive
    }
  }
`

export const GETPERSONNELSBYASSESSCOOPERATION = gql`
  query getPersonnelsByAssessCooperation($personnelCode: String!) {
    getPersonnelsByAssessCooperation(personnelCode: $personnelCode) {
      personnelId
      personnelCode
      namePrefixCode
      fullNameTH
      fullNameEN
      organizationCode
      organizationNameTH
      organizationNameEN
    }
  }
`
