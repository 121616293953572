import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Form } from 'antd'
import { ModalNew } from '../../../../../components/modal/Modal'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { GET_STATEMENT_BY_ID } from '../../graphql/Query'
import { SAVE_STATEMENT } from '../../graphql/Mutation'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'
import { TextAreaNew } from '../../../../../components/textarea/TextArea.jsx'
import { Alert } from '../../../../../components/alert/Alert'

const AuditResultThreeModal = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { objData } = props
  const [visible, setVisible] = useState(false)

  const [cellGet, { data: dataGet, loading: loadingGet, error: errorGet }] = useLazyQuery(GET_STATEMENT_BY_ID)
  const [cellSave, { data: dataSave, loading: loadingSave, error: errorSave }] = useMutation(SAVE_STATEMENT)

  useEffect(() => {
    if (objData.explainId) {
      const variables = encryptInput({ explainId: objData.explainId })
      cellGet({ variables })
    }
  }, [objData])

  useEffect(() => {
    if (dataGet) {
      const resporns = dataGet.statementById
      form.setFieldsValue({
        comment: resporns.comment,
      })
    }
    if (errorGet) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorGet)),
        onOk() {},
      })
    }
  }, [dataGet])

  useEffect(() => {
    setVisible(props.open)
  }, [props.open])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          form.resetFields()
          setVisible(false)
          props.close(false)
        },
      })
    }

    if (errorSave) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถบันทึกข้อมูลได้`),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [dataSave])

  const onFinish = (input) => {
    input['explainId'] = objData.explainId
    cellSave({
      variables: {
        input: encryptInput(input),
      },
    })
  }

  function onClose(e) {
    form.resetFields()
    setVisible(e)
    props.close(e)
  }
  return (
    <>
      {(loadingGet || loadingSave) && <SpinnersNew />}
      <ModalNew
        visible={visible}
        testTitle={t('แสดงความคิดเห็น')}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        typeAction="add"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 5,
            offset: 4,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="commentForm"
        >
          <Form.Item name="comment" label={<Label type="tab-header-inactive">{t('แสดงความคิดเห็น')}</Label>}>
            <TextAreaNew showCount maxLength={300} rows={5} placeholder={t('ระบุแสดงความคิดเห็น')} />
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
export default AuditResultThreeModal
