import { gql } from '@apollo/client'

export const PERSONNELS_SAVE = gql`
  mutation personnelsSave($input: PersonnelsInput!) {
    personnelsSave(input: $input) {
      personnelCode
      personnalTypeCode
      organizationCode
      personnaloperationCode
      dateOfLeave
      newPersonnelStatusCode
      dateOfStartWorking
      positionCode
      levelCode
      namePrefixCode
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
      genderCode
      dateOfBirth
      responsibility
      telephone
      mobile
      emergency
      lineId
      email
    }
  }
`

export const DELETE_PERSONNELS = gql`
  mutation deletePersonnels($input: String!) {
    deletePersonnel(personnelCode: $input)
  }
`

export const CREATE_EDUCATION_PERSONNELS = gql`
  mutation PersonnelEducationsSave($input: PersonnelEducationsInput!) {
    PersonnelEducationsSave(input: $input) {
      personnelCode
    }
  }
`

export const DELETE_EDUCATION_PERSONNELS = gql`
  mutation deletePersonnelEducations($input: PersonnelEducationsInput!) {
    deletePersonnelEducations(input: $input) {
      personnelCode
    }
  }
`

export const DELETE_ONE_EDUCATION = gql`
  mutation personnelEducationsDelete($input: PersonnelEducationsInput!) {
    personnelEducationsDelete(input: $input) {
      personnelEducatId
    }
  }
`

export const CREATE_TRAINING_PERSONNELS = gql`
  mutation createPersonnelTraining($input: PersonnelTrainingInput!) {
    createPersonnelTraining(input: $input) {
      personnelCode
    }
  }
`

export const DELETE_ONE_TRAINING = gql`
  mutation personnelTrainingDelete($input: PersonnelTrainingInput!) {
    personnelTrainingDelete(input: $input) {
      personnelTrainId
    }
  }
`

export const DELETE_ONE_REWARD = gql`
  mutation personnelRewardDelete($input: PersonnelRewardInput!) {
    personnelRewardDelete(input: $input) {
      personnelRewardId
    }
  }
`

export const DELETE_ONE_CERTIFICATE = gql`
  mutation personnelCertificateDelete($input: PersonnelCertificateInput!) {
    personnelCertificateDelete(input: $input) {
      personnelCertificateId
    }
  }
`

export const CREATE_REWARD_PERSONNELS = gql`
  mutation personnelRewardSave($input: PersonnelRewardInput!) {
    personnelRewardSave(input: $input) {
      personnelCode
    }
  }
`

export const CREATE_CERTIFICATE_PERSONNELS = gql`
  mutation createPersonnelCertificate($input: PersonnelCertificateInput!) {
    createPersonnelCertificate(input: $input) {
      personnelCode
    }
  }
`

export const DELETE_ALL = gql`
  mutation personnelDelete($input: PersonnelsInput!) {
    personnelDelete(input: $input) {
      personnelCode
    }
  }
`
