import styled from 'styled-components'

export const MainTable = styled.div`
  .ant-table-tbody tr.ant-table-expanded-row > td {
    margin: 0;
    padding: 0;
  }

  .ant-table-tbody tr.ant-table-expanded-row:hover > td {
    margin: 0;
    padding: 0;
    background-color: none !important;
  }
`

export const SubTable = styled.div`
  .table-row-color {
    background-color: #f2f7fc;
    color: #2b4990;
  }

  .ant-table-thead .ant-table-cell {
    background-color: #f2f7fc;
    color: #2b4990;
  }

  .ant-table-tbody tr.ant-table-expanded-row > td {
    background-color: #992e2e !important;
  }

  .ant-table-tbody tr.ant-table-expanded-row:hover > td {
    background-color: #992e2e !important;
  }

  .search-table-wrapper {
    margin: 0 !important;
  }
`
