import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
// import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useLazyQuery } from '@apollo/client'
import * as _ from 'lodash'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb.jsx'
import { CardNew } from '../../../components/card/Card.jsx'
import { LabelNew as Label } from '../../../components/label/Label.jsx'
import { ButtonNew } from '../../../components/button/Button.jsx'
import { displayError, displayText } from '../../../utilitys/helper'
import { Datepicker } from '../../../components/datepicker/Datepicker.jsx'
// import { InputNew } from '../../../components/input/Input.jsx'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import { ButtonGroup } from '../../../components/button/ButtonGroup.jsx'
// import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization.jsx'
import ConsultationResultReportModal from './modal/ConsultationResultReportModal'
import { SpinnersNew } from '../../../components/spinners/Spinners.jsx'
import { Columns } from './utils/ConsultationResultTable.js'
import { Alert } from '../../../components/alert/Alert'
import { GET_CONSULTATION_REPORT_PAGINATION_REPORT, CONSULT_RESULT_REPORT_EXPORT } from './graphql/Query.jsx'
import SendEmailReportModal from '../components/SendEmailReportModal.jsx'
import { encryptInput } from '../../../utilitys/crypto.js'
import { CONSULT_RESULT_EXPORT } from '../../auditoperations/consultationresult/graphql/Query.jsx'
import { handleSort } from '../../../utilitys/pagination'

const ConsultationResultReportList = (props) => {
  const { menu } = props
  // const navigate = useNavigate()
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  const Responsive = {
    md: 8,
    lg: 8,
  }
  const ResponsiveTable = {
    md: 24,
    lg: 24,
  }

  const breadcrumbList = [
    { label: t('รายงาน'), path: '/reports/consultationaummaryreport' },
    { label: t('รายงานสรุปให้คำปรึกษา') },
  ]

  const [dataLists, setDataLists] = useState([])
  const [data, setData] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  const [exportStatus, setExportStatus] = useState([])
  const [dataRef, setDataRef] = useState([])
  const [filters, setFilters] = useState({})

  /* ---------------- API ----------- */
  const [getDataPaginationFn, dataPagination] = useLazyQuery(GET_CONSULTATION_REPORT_PAGINATION_REPORT)

  /* ---------------- ACTION ----------- */
  useEffect(() => {
    if (Object.keys(filters).length !== 0) {
      getDataPaginationFn({
        variables: {
          input: encryptInput({
            filters: { ...filters },
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
    } else {
      setDataLists([])
    }
  }, [page, limit, sort])

  const onFinish = (values) => {
    setFilters({ dateStart: values.dateStart, dateEnd: values.dateEnd })
    getDataPaginationFn({
      variables: {
        input: encryptInput({
          filters: {
            requestOrganization: values.requestOrganization,
            responsibleOrganization: values.responsibleOrganization,
            dateStart: values.dateStart,
            dateEnd: values.dateEnd,
          },
          pagination: { limit, page },
          sort,
        }),
      },
    })
  }

  const showErrorAlert = (item) => {
    Alert({
      type: 'error',
      title: t(item.title),
      content: t(item.content),
      onOk() {},
    })
  }
  /*------------Check correct DateInput--------------------*/

  /*-------การแสดงหน้า-------*/
  useEffect(() => {
    if (dataPagination.data) {
      let response = dataPagination.data?.getConsultationResultPaginationReport
      setCount(response?.count)
      setDataRef({ result: response?.result, date: filters })
      setDataLists(
        _.map(response?.result, (data, i) => {
          return {
            ...data,
            key: i + 1,
            jobNumber: data.jobNumber,
            jobTypeName: displayText(data.jobTypeNameTH, data.jobTypeNameEN),
            requestOrganizationName: data.requestOrganization,
            dateStart: moment(data.dateStart).format('DD/MM/YYYY'),
            dateEnd: moment(data.dateEnd).format('DD/MM/YYYY'),
            tools: (
              <ButtonGroup
                menu={menu}
                icons={[
                  {
                    type: 'download',
                    onClick: () => {
                      Promise.all([
                        setData({
                          consultId: data.consultId,
                          jobCode: data.jobCode,
                          responsiblePersonnel: data.fullName,
                        }),
                        setExportStatus(0),
                        setVisible(true),
                      ])
                    },
                  },
                  {
                    type: 'settings',
                    onClick: () => {
                      fncOnpressEmailSetting({ data, exportStatus: 0 })
                    },
                  },
                ]}
              />
            ),
          }
        }),
      )
    }
    if (dataPagination.error) {
      showErrorAlert({
        title: 'ไม่สามารถดึงข้อมูลได้',
        content: dataPagination.error.message,
      })
    }
  }, [dataPagination.data])

  const validDate = () => {
    let valid = false
    if (form.getFieldValue('dateStart') && form.getFieldValue('dateEnd')) {
      let dateStart = new Date(form.getFieldValue('dateStart'))
      let dateEnd = new Date(form.getFieldValue('dateEnd'))
      if (dateStart <= dateEnd) {
        setErrorField('dateStart', false)
        setErrorField('dateEnd', false)
        valid = true
      } else {
        setErrorField('dateStart', t('กรุณาเลือกวันที่เริ่มต้นน้อยกว่าวันที่สิ้นสุด'))
        setErrorField('dateEnd', t('กรุณาเลือกวันที่สิ้นสุดมากกว่าวันที่เริ่มต้น'))
      }
    } else {
      valid = true
    }
    return valid
  }
  /*-----setField-------*/
  const setErrorField = (name, errors) => {
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  //=========================== สำหรับการส่งเมลรายงาน ===========================

  const [consultResultExportFn, consultResultExport] = useLazyQuery(CONSULT_RESULT_EXPORT)
  const [consultResultReportExportFn, consultResultReportExport] = useLazyQuery(CONSULT_RESULT_REPORT_EXPORT)

  useEffect(() => {
    if (consultResultExport.data) {
      Alert({
        type: 'success',
        title: t('ส่งอีเมลสำเร็จ'),
        onOk() {},
      })
    }
    if (consultResultExport.error) {
      const err = consultResultExport.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [consultResultExport?.data])

  useEffect(() => {
    if (consultResultReportExport.data) {
      Alert({
        type: 'success',
        title: t('ส่งอีเมลสำเร็จ'),
        onOk() {},
      })
    }
    if (consultResultReportExport.error) {
      const err = consultResultReportExport.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [consultResultReportExport?.data])

  //=========================== Email Modal ===========================
  const [emailModalVisible, setemailModalVisible] = useState(false)
  const [mailValue, setMailValue] = useState()
  const [inputSendEmail, setInputSendEmail] = useState()

  useEffect(() => {
    if (inputSendEmail) {
      // Call API Export
      if (mailValue?.exportStatus === 0) {
        consultResultExportFn({
          variables: {
            input: encryptInput({
              consultId: mailValue?.consultId,
              jobCode: mailValue?.jobCode,
              responsiblePersonnel: mailValue?.fullName,
              fileType: inputSendEmail?.fileType,
              waterMark: inputSendEmail?.waterMark || '',
              emailData: inputSendEmail, // สำหรับการส่งเมลรายงาน
              lang: localStorage.getItem('lang'),
            }),
          },
        })
      } else {
        consultResultReportExportFn({
          variables: {
            input: encryptInput({
              lang: localStorage.getItem('lang'),
              fileType: inputSendEmail.fileType,
              emailData: inputSendEmail, // สำหรับการส่งเมลรายงาน
              consultId: dataRef.result.map((item) => ({ consultId: item.consultId })),
              jobCode: dataRef.result.map((item) => ({ jobCode: item.jobCode })),
              responsiblePersonnel: dataRef.result.map((item) => ({ fullName: item.fullName })),
              dateStart: dataRef.date.dateStart,
              dateEnd: dataRef.date.dateEnd,
              waterMark: inputSendEmail?.waterMark || '',
            }),
          },
        })
      }
    }
  }, [inputSendEmail])

  const fncOnpressEmailSetting = ({ data = null, exportStatus = null }) => {
    setemailModalVisible(true)
    setMailValue({ reportName: 'รายงานสรุปให้คำปรึกษา', ...data, exportStatus })
  }

  //=========================== =========================== ====

  return (
    <>
      {dataPagination.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('รายงานสรุปให้คำปรึกษา')} />
      <CardNew topic={t('Filters')} icon="Filter" toggledrop="true" buttontopright="true">
        <Form name="form-search" form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[12, 12]}>
            <Col {...Responsive}>
              <Form.Item
                name="requestOrganization"
                label={<Label type="tab-header-inactive">{t('หน่วยงานที่ขอ')}</Label>}
              >
                <SelectOrganization
                  placeholder={t('เลือกหน่วยงานที่ขอ')}
                  formname={'form-search'}
                  filters={{ isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ requestOrganization: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name="responsibleOrganization"
                label={<Label type="tab-header-inactive">{t('หน่วยงานที่ให้คำปรึกษา')}</Label>}
              >
                <SelectOrganization
                  placeholder={t('เลือกหน่วยงานที่ให้คำปรึกษา')}
                  formname={'form-search'}
                  filters={{ isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ responsibleOrganization: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'dateStart'}
                label={<Label type="tab-header-inactive">{t('วันที่เริ่มต้น')}</Label>}
                rules={[{ required: true, message: t('กรุณาระบุวันที่เริ่มต้น') }]}
              >
                <Datepicker
                  autoComplete="off"
                  setValueDateFn={async (data) => {
                    form.setFieldsValue({ dateStart: await data }), validDate()
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateStart: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'dateEnd'}
                label={<Label type="tab-header-inactive">{t('วันที่สิ้นสุด')}</Label>}
                rules={[{ required: true, message: t('กรุณาระบุวันที่สิ้นสุด') }]}
              >
                <Datepicker
                  autoComplete="off"
                  setValueDateFn={async (data) => {
                    form.setFieldsValue({ dateEnd: await data }), validDate()
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateEnd: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <ButtonNew type="search" htmlType="submit">
                {t('ค้นหา')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew
                type="printerSuccess"
                menu={menu}
                onClick={() => {
                  Promise.all([setExportStatus(1), setVisible(true)])
                }}
              >
                {t('พิมพ์')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew type="clear" onClick={() => form.resetFields()} htmlType="submit">
                {t('ล้างค่า')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew
                type="success"
                menu={menu}
                onClick={() => {
                  fncOnpressEmailSetting({ exportStatus: 1 })
                }}
              >
                {t('ส่งรายงาน')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </CardNew>
      {/* - - - DataTable - - - */}
      <CardNew topic={t('รายการสรุปให้คำปรึกษา')} icon="List" toggledrop="false">
        <Row gutter={[12, 12]}>
          <Col {...ResponsiveTable}>
            <Datatable
              columns={Columns}
              data={[...dataLists]}
              handleTableChange={(e) => {
                const reNewField = [
                  { jobTypeName: displayText('jobTypeNameTH', 'jobTypeNameEN') },
                  { jobNumber: 'jobNumber' },
                  { dateStart: 'dateStart' },
                  { dateEnd: 'dateEnd' },
                  { requestOrganizationName: 'requestOrganizationName' },
                ]
                setSort(handleSort(e?.sorter, reNewField))
              }}
              paginationCustom={true}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
              total={count}
              pageSize={limit}
              page={page}
            />
          </Col>
        </Row>
      </CardNew>
      <ConsultationResultReportModal
        visible={visible}
        setVisible={setVisible}
        data={data}
        exportStatus={exportStatus}
        dataRef={dataRef}
      />
      <SendEmailReportModal
        menu={menu}
        open={emailModalVisible}
        close={(e) => {
          setemailModalVisible(e)
        }}
        fileType={{ pdf: true, word: true, excel: true, csv: true }}
        setInputSendEmail={(e) => setInputSendEmail(e)}
        mailValue={mailValue}
      />
    </>
  )
}

export default ConsultationResultReportList
