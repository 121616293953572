import React, { useState } from 'react'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { Col, Row, Typography as Title } from 'antd'
import { ColorPicker } from '../../../components/colorpicker/ColorPicker'

function ColorPickerIndex() {
  let code = `
  import { ColorPicker } from '../../../components/colorpicker/ColorPicker'
  export default function App() {
    const [stateColor, setStateColor] = useState('#123456') //กำหนดสีเริ่มต้น

    return <> 
    <ColorPicker color={stateColor} handleChange={(e) => { setStateColor(e) }} />
  </>
  }
   `
  const [stateColor, setStateColor] = useState('#FF0000')
  return (
    <>
      <div className="site-layout-background">
        <ColorPicker
          color={stateColor}
          handleChange={(e) => {
            setStateColor(e)
          }}
        />
        <br />
        <Row>
          <Col span={12}>
            <ColorPicker
              color={stateColor}
              handleChange={(e) => {
                setStateColor(e)
              }}
            />
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <Title style={{ color: stateColor }}>สีที่ถูกเลือก : {stateColor}</Title>
      </div>
      <CodeBlock code={code} />
    </>
  )
}
export default ColorPickerIndex
