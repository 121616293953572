import React from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { CardNew as Card } from '../../../components/card/Card.jsx'
import { ButtonNew } from '../../../components/button/Button'
import SelectProjectRefer from '../../../components/inputfromapi/selectprojectrefer/SelectProjectRefer'
import { LabelNew as Label } from '../../../components/label/Label'
import { InputNew as Input } from '../../../components/input/Input'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectProjectStatus from '../../../components/inputfromapi/selectprojectstatus/SelectProjectStatus.jsx'
const FilterActionExplain = (props) => {
  const { t } = useTranslation()
  const onFinish = () => {}
  return (
    <>
      <Row>
        <Col md={24}>
          <Card topic={t('Filters')} icon="AlignJustify" toggledrop="false">
            <Form
              form={props.formFilter}
              onFinish={onFinish}
              autoComplete="off"
              name="filterFollowUp"
              layout="vertical"
            >
              <Row gutter={[12, 12]}>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="projectRefCode"
                    label={<Label type="tab-header-inactive">{t('แหล่งที่มาของโครงการ')}</Label>}
                  >
                    <SelectProjectRefer
                      filters={{ isActive: 1 }}
                      formname="filterFollowUp"
                      placeholder={t('เลือกแหล่งที่มาของโครงการ')}
                      handleChange={(value) => {
                        props.formFilter.setFieldsValue({ projectRefCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="project"
                    label={<Label type="tab-header-inactive">{t('รหัสหรือชื่อโครงการ')}</Label>}
                  >
                    <Input placeholder={t('ระบุรหัสหรือชื่อโครงการ')} />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="organizationCode"
                    label={<Label type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</Label>}
                  >
                    <SelectOrganization
                      multiple={true}
                      filters={{ checkedList: JSON.stringify([2]), isActive: 1 }}
                      formname="filterFollowUp"
                      placeholder={t('เลือกหน่วยงานที่รับผิดชอบ')}
                      handleChange={(value) => {
                        props.formFilter.setFieldsValue({ organizationCode: value })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item name="projectStatusCode" label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>}>
                    <SelectProjectStatus
                      filters={{ isActive: 1 }}
                      formname="filterFollowUp"
                      placeholder={t('เลือกสถานะ')}
                      handleChange={(value) => {
                        props.formFilter.setFieldsValue({ projectStatusCode: value ? value.value : null })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew
                    type="search"
                    onClick={() => {
                      if (
                        props.formFilter.getFieldsError().filter((item) => {
                          return item.errors.length !== 0
                        }).length === 0
                      ) {
                        props.setSearchStatus(props.searchStatus + 1)
                      }
                    }}
                    htmlType="submit"
                  >
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew
                    type="clear"
                    onClick={() => {
                      props.formFilter.resetFields()
                      props.setSearchStatus(props.searchStatus + 1)
                    }}
                  >
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FilterActionExplain
