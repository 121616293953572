import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { LabelNew } from '../../../components/label/Label'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { InputNew } from '../../../components/input/Input'
import { Datatable } from '../../../components/datatable/Datatable'
import { Status } from '../../../components/status/Status'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText, displayError } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import SelectRequestStatus from '../../../components/inputfromapi/selectrequeststatus/SelectRequestStatus'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import { Columns } from './utils/TimeSheetApproveColumns'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { GET_TIMESHEET_APPROVE_PAGINATION } from './graphql/Query'

const TimeSheetApproveList = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const Responsive = {
    md: 8,
    lg: 8,
  }
  const ResponsiveTable = {
    md: 24,
    lg: 24,
  }
  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/timesheetapprove' },
    { label: t('อนุมัติการปฏิบัติงาน') },
  ]

  const [user] = useDecodeUser()
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' },
  ])
  const [filters, setFilters] = useState({})
  const [isPCode, setPCode] = useState(null)

  /* ---------------- API ----------- */
  const [getDataPaginationFn, dataPagination] = useLazyQuery(GET_TIMESHEET_APPROVE_PAGINATION)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    user && setPCode(user?.pCode)
  }, [user])

  useEffect(() => {
    if (isPCode) {
      getDataPaginationFn({
        variables: {
          input: encryptInput({
            filters: { ...filters, personnelCode: isPCode },
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
    }
  }, [page, limit, sort, isPCode])

  useEffect(() => {
    if (dataPagination.data) {
      let response = dataPagination?.data?.getTimeSheetApprovePagination
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data, index) => {
          return {
            ...data,
            key: index + 1,
            requestNumber: data.requestNumber,
            requestPersonnel: displayText(data.requestPersonnelNameTH, data.requestPersonnelNameEN),
            requestOrganizationName: displayText(data.organizationNameTH, data.organizationNameEN) || '-',
            requestStatusName: <Status text={data.requestStatusNameTH} />,
            action:
              data.requestStatusCode === '10' ? (
                <ButtonNew
                  type="flagOutlinedInTableBlue"
                  roles={{ type: 'approve', menu: menu }}
                  onClick={() => navigate(`form`, { state: { type_action: 'approve', refCode: data.requestId } })}
                >
                  {t('อนุมัติปฏิบัติงาน')}
                </ButtonNew>
              ) : data.requestStatusCode === '20' ? (
                <ButtonNew
                  type="moreHorizontalInTableOrange"
                  roles={{ type: 'approve', menu: menu }}
                  onClick={() => navigate(`form`, { state: { type_action: 'approve', refCode: data.requestId } })}
                >
                  {t('แก้ไขการอนุมัติ')}
                </ButtonNew>
              ) : (
                <ButtonNew
                  type="moreHorizontalInTableGray"
                  roles={{ type: 'view', menu: menu }}
                  disabled={true}
                  // onClick={() => navigate(`form`, { state: { type_action: 'edit', refCode: data.requestId } })}
                >
                  {t('พิจารณาแล้ว')}
                </ButtonNew>
              ),
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => navigate(`form`, { state: { type_action: 'view', refCode: data.requestId } }),
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }

    if (dataPagination.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataPagination.error)),
        onOk() {},
      })
    }
  }, [dataPagination.data])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    const inputSearch = {
      requestNumber: input.requestNumber || '',
      requestStatusCode: input.requestStatusCode || '',
      organizationCode: input.organizationCode || '',
      requestPersonnel: input.requestPersonnel || '',
      personnelCode: isPCode,
    }

    setFilters(inputSearch)
    getDataPaginationFn({
      variables: {
        input: encryptInput({
          filters: inputSearch,
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }

  const resetField = () => {
    form.resetFields()
    setFilters({})
    getDataPaginationFn({
      variables: {
        input: encryptInput({
          filters: { personnelCode: isPCode },
          pagination: { limit: 10, page: 1 },
          sort: [
            { fieldSort: 'updatedAt', sortType: 'DESC' },
            { fieldSort: 'createdAt', sortType: 'DESC' },
          ],
        }),
      },
    })
  }

  return (
    <>
      {dataPagination.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('อนุมัติการปฏิบัติงาน')} />
      <CardNew topic={t('Filters')} icon="Filter" toggledrop="true" buttontopright="true">
        <Form name="form-search" form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[12, 12]}>
            <Col {...Responsive}>
              <Form.Item
                name={'requestNumber'}
                label={<LabelNew type="tab-header-inactive">{t('เลขที่คำขอ')}</LabelNew>}
              >
                <InputNew placeholder={t('ระบุเลขที่คำขอ')} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'requestPersonnel'}
                label={<LabelNew type="tab-header-inactive">{t('ผู้ขออนุมัติ')}</LabelNew>}
              >
                <InputNew placeholder={t('ระบุผู้ขออนุมัติ')} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name="organizationCode"
                label={<LabelNew type="tab-header-inactive">{t('หน่วยงาน')}</LabelNew>}
              >
                <SelectOrganization
                  placeholder={t('เลือกหน่วยงาน')}
                  formname={'form-search'}
                  filters={{ isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ organizationCode: e?.value })}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name="requestStatusCode"
                label={<LabelNew type="tab-header-inactive">{t('สถานะพิจารณา')}</LabelNew>}
              >
                <SelectRequestStatus
                  placeholder={t('เลือกสถานะพิจารณา')}
                  formname={'form-search'}
                  filters={{ isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ requestStatusCode: e.value })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <ButtonNew type="search" htmlType="submit">
                {t('ค้นหา')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew type="clear" onClick={() => resetField()}>
                {t('ล้างค่า')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </CardNew>
      <CardNew topic={t('รายการอนุมัติการปฏิบัติงาน')} icon="List" toggledrop="false">
        <Row gutter={[12, 12]}>
          <Col {...ResponsiveTable}>
            <Datatable
              paginationCustom={true}
              columns={Columns}
              data={[...dataLists]}
              total={count}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => {
                const reNewField = [
                  { requestPersonnel: displayText('requestPersonnelNameTH', 'requestPersonnelNameEN') },
                  {
                    requestOrganizationName: displayText('organizationNameTH', 'organizationNameEN'),
                  },
                  { requestStatusName: displayText('requestStatusNameTH', 'requestStatusNameEN') },
                  { requestNumber: 'requestNumber' },
                ]
                setSort(handleSort(e?.sorter, reNewField))
              }}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default TimeSheetApproveList
