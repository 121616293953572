import React from 'react'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { Space, Row } from 'antd'
import { TextAreaNew } from '../../../components/textarea/TextArea'

function InputIndex() {
  let code = ` import { LabelNew } from '../../../components/label/Label'   
    export default function App() {
        return <> 
        <TextAreaNew showCount maxLength={100} rows={5}/>
        </>
    }
    `
  return (
    <React.Fragment>
      <div className="site-layout-background">
        <Row>
          <Space direction="vertical">
            <TextAreaNew showCount maxLength={100} rows={5} />
          </Space>
        </Row>
      </div>
      <CodeBlock code={code} />
    </React.Fragment>
  )
}
export default InputIndex
