import React, { useEffect, useState, useLayoutEffect } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Form, Row, Col, Space, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { ButtonNew } from '../../../components/button/Button'
import { InputNew } from '../../../components/input/Input'
import { LabelNew } from '../../../components/label/Label'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { Alert } from '../../../components/alert/Alert'
import { CREATE_SYSTEM_ROLES } from './graphql/Mutation'
import { validateCode } from '../../../utilitys/validationAntd'
import { encryptInput } from '../../../utilitys/crypto'
import { colors } from '../../../configs/styles.config'
import { Columns } from './utils/PermissionsColumnsForm'
import { GET_SYSTEM_MENUS, GET_SYSTEM_ROLE_MENU, GET_SYSTEM_ROLES_FILD } from './graphql/Query'
import styled from 'styled-components'
import { displayError, regexEngThai, regexEng, displayText } from '../../../utilitys/helper'

const Responesive = {
  md: 24,
  lg: 24,
}
const ButtonActive = styled.div`
  .active {
    background-color: ${colors.primary};
    color: ${colors.white};
  }
  .active2 {
    background-color: #bfc8dd;
    color: ${colors.primary};
  }
`

const PermissionsForm = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()
  const [typeAction, setTypeAction] = useState(false)
  const [form] = Form.useForm()
  const [active, setActive] = useState()
  const [dataTable, setDataTable] = useState([])
  const [roleCode, setRoleCode] = useState()
  const [typeAction2, setTypeAction2] = useState()
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [checkStatusHeader, setCheckStatusHeader] = useState({
    canAdd: false,
    canView: false,
    canEdit: false,
    canDelete: false,
    candSend: false,
    canExport: false,
    canApprove: false,
    canDuplicate: false,
    check_all_header: false,
  })

  // const [disChecked, setDisChecked] = useState([])

  if (!state) {
    return <Navigate to={'/administrator/permissions'} />
  }

  const [getSystemMenu, { data: dataMenus, loading: loadingMenus, refetch: refetchSystemMenu, error: errorMenus }] =
    useLazyQuery(GET_SYSTEM_MENUS, {
      variables: {
        roleCode: roleCode ? roleCode : state.refCode ? state.refCode : '',
      },
    })

  const [
    // eslint-disable-next-line no-unused-vars
    getSystemRoleMenu,
    { data: dataRoleMenu, loading: loadingRoleMenu, refetch: refetchRoleMenu, error: errorRoleMenu },
  ] = useLazyQuery(GET_SYSTEM_ROLE_MENU, {
    variables: {
      input: {
        menuCode: active,
        roleCode: roleCode ? roleCode : '',
      },
    },
  })

  const [getSystemRoleFild, { data: dataRoleFild, loading: loadingRoleFild, error: errorSystemRoleFild }] =
    useLazyQuery(GET_SYSTEM_ROLES_FILD, {
      variables: {
        roleCode: roleCode ? roleCode : state.refCode,
      },
    })

  const [createSystemRoles, { data: dataCreateRoles, loading: loadingCreateRoles, error: errorCreateRoles }] =
    useMutation(CREATE_SYSTEM_ROLES)

  const onclearCheckBox = () => {
    const fields = form.getFieldsValue()
    const result = Object.values(fields)
    let newFields = result.map((item) => {
      if (item) {
        return {
          ...item,
          view: false,
          add: false,
          edit: false,
          delete: false,
          email: false,
          download: false,
          approve: false,
          duplicate: false,
        }
      }
    })

    let tempNew = { ...newFields }
    tempNew.check_all_view = false
    tempNew.check_all_add = false
    tempNew.check_all_edit = false
    tempNew.check_all_delete = false
    tempNew.check_all_email = false
    tempNew.check_all_download = false
    tempNew.check_all_approve = false
    tempNew.check_all_duplicate = false
    tempNew.check_all_header = false

    form.setFieldsValue(tempNew)
  }

  useLayoutEffect(() => {
    if (dataRoleMenu) {
      if (roleCode) {
        let checkStatus = {
          canAdd: true,
          canView: true,
          canEdit: true,
          canDelete: true,
          candSend: true,
          canExport: true,
          canApprove: true,
          canDuplicate: true,
        }
        let checkStatusHeaderIN = {
          canAdd: true,
          canView: true,
          canEdit: true,
          canDelete: true,
          candSend: true,
          canExport: true,
          canApprove: true,
          canDuplicate: true,
          check_all_header: true,
        }
        // console.log('dataRoleMenu: ', dataRoleMenu)
        const dataArray = dataRoleMenu?.getSystemRoleMenu.map((item, index) => {
          // if (!item.canAdd) checkStatus.canAdd = false
          // if (!item.canView) checkStatus.canView = false
          // if (!item.canEdit) checkStatus.canEdit = false
          // if (!item.canDelete) checkStatus.canDelete = false
          // if (!item.candSend) checkStatus.candSend = false
          // if (!item.canExport) checkStatus.canExport = false
          // if (!item.canApprove) checkStatus.canApprove = false
          // if (!item.canDuplicate) checkStatus.canDuplicate = false

          if (item.dCanAdd) {
            checkStatusHeaderIN.canAdd = false
            if (!item.canAdd) checkStatus.canAdd = false
          }
          if (item.dCanView) {
            checkStatusHeaderIN.canView = false
            if (!item.canView) checkStatus.canView = false
          }
          if (item.dCanEdit) {
            checkStatusHeaderIN.canEdit = false
            if (!item.canEdit) checkStatus.canEdit = false
          }
          if (item.dCanDelete) {
            checkStatusHeaderIN.canDelete = false
            if (!item.canDelete) checkStatus.canDelete = false
          }
          if (item.dCanSend) {
            checkStatusHeaderIN.candSend = false
            if (!item.candSend) checkStatus.candSend = false
          }
          if (item.dCanExport) {
            checkStatusHeaderIN.canExport = false
            if (!item.canExport) checkStatus.canExport = false
          }
          if (item.dCanApprove) {
            checkStatusHeaderIN.canApprove = false
            if (!item.canApprove) checkStatus.canApprove = false
          }
          if (item.dCanDuplicate) {
            checkStatusHeaderIN.canDuplicate = false
            if (!item.canDuplicate) checkStatus.canDuplicate = false
          }

          // if (item.dCanAdd) checkStatusHeaderIN.canAdd = false
          // if (item.dCanView) checkStatusHeaderIN.canView = false
          // if (item.dCanEdit) checkStatusHeaderIN.canEdit = false
          // if (item.dCanDelete) checkStatusHeaderIN.canDelete = false
          // if (item.dCanSend) checkStatusHeaderIN.candSend = false
          // if (item.dCanDownload) checkStatusHeaderIN.canExport = false
          // if (item.dCanApprove) checkStatusHeaderIN.canApprove = false
          // if (item.dCanDuplicate) checkStatusHeaderIN.canDuplicate = false

          // console.log('item', item.canAdd)
          // console.log('checkStatus.canAdd', checkStatus.canAdd)
          return {
            key: index,
            menu: displayText(item.menuNameTH, item.menuNameEN),
            menuCode: item.menuCode,
            view: { value: item.dCanView ? item.canView : false, disable: !item.dCanView },
            add: { value: item.dCanAdd ? item.canAdd : false, disable: !item.dCanAdd },
            edit: { value: item.dCanEdit ? item.canEdit : false, disable: !item.dCanEdit },
            delete: { value: item.dCanDelete ? item.canDelete : false, disable: !item.dCanDelete },
            email: { value: item.dCanSend ? item.candSend : false, disable: !item.dCanSend },
            download: { value: item.dCanExport ? item.canExport : false, disable: !item.dCanExport },
            approve: { value: item.dCanApprove ? item.canApprove : false, disable: !item.dCanApprove },
            duplicate: { value: item.dCanDuplicate ? item.canDuplicate : false, disable: !item.dCanDuplicate },
          }
        })
        setDataTable(dataArray)

        if (
          checkStatusHeaderIN.canView &&
          checkStatusHeaderIN.canAdd &&
          checkStatusHeaderIN.canEdit &&
          checkStatusHeaderIN.canDelete &&
          checkStatusHeaderIN.candSend &&
          checkStatusHeaderIN.canExport &&
          checkStatusHeaderIN.canApprove &&
          checkStatusHeaderIN.canDuplicate
        ) {
          checkStatusHeaderIN.check_all_header = true
        } else {
          checkStatusHeaderIN.check_all_header = false
        }
        setCheckStatusHeader({ ...checkStatusHeaderIN })

        const dataArray2 = dataArray.map((item, index) => {
          return {
            key: index,
            menu: item.menuNameTH,
            menuCode: item.menuCode,
            view: item.view.value,
            add: item.add.value,
            edit: item.edit.value,
            delete: item.delete.value,
            email: item.email.value,
            download: item.download.value,
            approve: item.approve.value,
            duplicate: item.duplicate.value,
          }
        })

        let objFormNew = { ...dataArray2 }
        // view
        objFormNew.check_all_view = checkStatus.canView && !checkStatusHeaderIN.canView ? true : false
        // add
        objFormNew.check_all_add = checkStatus.canAdd && !checkStatusHeaderIN.canAdd ? true : false
        // edit
        objFormNew.check_all_edit = checkStatus.canEdit && !checkStatusHeaderIN.canEdit ? true : false
        // delete
        objFormNew.check_all_delete = checkStatus.canDelete && !checkStatusHeaderIN.canDelete ? true : false
        //email
        objFormNew.check_all_email = checkStatus.candSend && !checkStatusHeaderIN.candSend ? true : false
        // download
        objFormNew.check_all_download = checkStatus.canExport && !checkStatusHeaderIN.canExport ? true : false
        // approve
        objFormNew.check_all_approve = checkStatus.canApprove && !checkStatusHeaderIN.canApprove ? true : false
        // duplicate
        objFormNew.check_all_duplicate = checkStatus.canDuplicate && !checkStatusHeaderIN.canDuplicate ? true : false

        let ch_all = {
          view: false,
          canAdd: false,
          canEdit: false,
          canDelete: false,
          candSend: false,
          canExport: false,
          canApprove: false,
          canDuplicate: false,
        }

        if (!checkStatusHeaderIN.canView) {
          if (objFormNew.check_all_view) ch_all.view = true
        } else {
          ch_all.view = true
        }
        if (!checkStatusHeaderIN.canAdd) {
          if (objFormNew.check_all_add) ch_all.canAdd = true
        } else {
          ch_all.canAdd = true
        }
        if (!checkStatusHeaderIN.canEdit) {
          if (objFormNew.check_all_edit) ch_all.canEdit = true
        } else {
          ch_all.canEdit = true
        }
        if (!checkStatusHeaderIN.canDelete) {
          if (objFormNew.check_all_delete) ch_all.canDelete = true
        } else {
          ch_all.canDelete = true
        }
        if (!checkStatusHeaderIN.candSend) {
          if (objFormNew.check_all_email) ch_all.candSend = true
        } else {
          ch_all.candSend = true
        }
        if (!checkStatusHeaderIN.canExport) {
          if (objFormNew.check_all_download) ch_all.canExport = true
        } else {
          ch_all.canExport = true
        }
        if (!checkStatusHeaderIN.canApprove) {
          if (objFormNew.check_all_approve) ch_all.canApprove = true
        } else {
          ch_all.canApprove = true
        }
        if (!checkStatusHeaderIN.canDuplicate) {
          if (objFormNew.check_all_duplicate) ch_all.canDuplicate = true
        } else {
          ch_all.canDuplicate = true
        }

        // objFormNew.check_all_view &&
        //   objFormNew.check_all_add &&
        //   objFormNew.check_all_edit &&
        //   objFormNew.check_all_delete &&
        //   objFormNew.check_all_email &&
        //   objFormNew.check_all_download &&
        //   objFormNew.check_all_approve &&
        //   objFormNew.check_all_duplicate

        if (
          ch_all.view &&
          ch_all.canAdd &&
          ch_all.canEdit &&
          ch_all.canDelete &&
          ch_all.candSend &&
          ch_all.canExport &&
          ch_all.canApprove &&
          ch_all.canDuplicate
        ) {
          objFormNew.check_all_header = true
        } else {
          objFormNew.check_all_header = false
        }
        form.setFieldsValue({ ...objFormNew })
      } else {
        // console.log('dataRoleMenu: ', dataRoleMenu)

        let checkStatusHeaderIN = {
          canAdd: true,
          canView: true,
          canEdit: true,
          canDelete: true,
          candSend: true,
          canExport: true,
          canApprove: true,
          canDuplicate: true,
          check_all_header: true,
        }

        const dataTable = dataRoleMenu?.getSystemRoleMenu.map((item, index) => {
          if (item.dCanAdd) checkStatusHeaderIN.canAdd = false
          if (item.dCanView) checkStatusHeaderIN.canView = false
          if (item.dCanEdit) checkStatusHeaderIN.canEdit = false
          if (item.dCanDelete) checkStatusHeaderIN.canDelete = false
          if (item.dCanSend) checkStatusHeaderIN.candSend = false
          if (item.dCanDownload) checkStatusHeaderIN.canExport = false
          if (item.dCanApprove) checkStatusHeaderIN.canApprove = false
          if (item.dCanDuplicate) checkStatusHeaderIN.canDuplicate = false

          return {
            key: index,
            menu: item.menuNameTH,
            menuCode: item.menuCode,
            view: { value: null, disable: !item.dCanView },
            add: { value: null, disable: !item.dCanAdd },
            edit: { value: null, disable: !item.dCanEdit },
            delete: { value: null, disable: !item.dCanDelete },
            email: { value: null, disable: !item.dCanSend },
            download: { value: null, disable: !item.dCanDownload },
            approve: { value: null, disable: !item.dCanApprove },
            duplicate: { value: null, disable: !item.dCanDuplicate },
          }
        })
        setDataTable(dataTable)

        if (
          checkStatusHeaderIN.canView &&
          checkStatusHeaderIN.canAdd &&
          checkStatusHeaderIN.canEdit &&
          checkStatusHeaderIN.canDelete &&
          checkStatusHeaderIN.candSend &&
          checkStatusHeaderIN.canExport &&
          checkStatusHeaderIN.canApprove &&
          checkStatusHeaderIN.canDuplicate
        ) {
          checkStatusHeaderIN.check_all_header = true
        } else {
          checkStatusHeaderIN.check_all_header = false
        }
        setCheckStatusHeader({ ...checkStatusHeaderIN })
      }
    }

    if (errorRoleMenu) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorRoleMenu)),
        onOk() {},
      })
    }
  }, [dataRoleMenu])

  useEffect(() => {
    if (state.refCode) {
      setRoleCode(state.refCode)
    }
  }, [state])

  useEffect(() => {
    if (errorMenus) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorMenus)),
        onOk() {},
      })
    }
  }, [dataMenus])

  useEffect(() => {
    if (errorCreateRoles) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorCreateRoles)),
        onOk() {},
      })
    }
  }, [dataCreateRoles])

  useEffect(() => {
    if (active) {
      if (!roleCode) {
        onclearCheckBox()
      }
      refetchRoleMenu()
    }
  }, [active])

  useEffect(() => {
    if (state.type_action) {
      if (state.type_action === 'edit' || state.type_action === 'view') {
        getSystemRoleFild()
        if (state.type_action === 'view') {
          setTypeAction(true)
        }
      }
      setTypeAction2(state.type_action)
    }
    getSystemMenu()
  }, [])

  useEffect(() => {
    if (dataRoleFild?.getSystemRolesFind) {
      let formNew = form.getFieldsValue()
      formNew.roleCode = dataRoleFild?.getSystemRolesFind?.roleCode
      formNew.nameTh = dataRoleFild?.getSystemRolesFind?.roleNameTH
      formNew.nameEn = dataRoleFild?.getSystemRolesFind?.roleNameEN
      formNew.dateStart = dataRoleFild?.getSystemRolesFind?.startDate
      formNew.dateEnd = dataRoleFild?.getSystemRolesFind?.expireDate

      form.setFieldsValue(formNew)
    }
    if (errorSystemRoleFild) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorSystemRoleFild)),
        onOk() {},
      })
    }
  }, [dataRoleFild])

  // onFinish
  const onFinish = (values) => {
    fnConfirm(values)
  }

  const onSubmit = async (values) => {
    if (!values?.nameEn) values.nameEn = values.nameTh

    const result = Object.values(values) // แปลง

    let newValueArray = false
    let newFields = result.filter((item) => {
      if (item.add) newValueArray = true
      if (item.view) newValueArray = true
      if (item.edit) newValueArray = true
      if (item.delete) newValueArray = true
      if (item.email) newValueArray = true
      if (item.download) newValueArray = true
      if (item.approve) newValueArray = true
      if (item.duplicate) newValueArray = true
      if (item?.menuCode) {
        return item
      }
    })

    if (newValueArray === true) {
      newFields.push({
        add: false,
        approve: false,
        delete: false,
        download: false,
        duplicate: false,
        edit: false,
        email: false,
        menuCode: active,
        view: true,
      })
    }

    let dataInput = {
      typeAction: typeAction2,
      roleCode: values.roleCode,
      roleNameTH: values.nameTh,
      roleNameEN: values.nameEn ? values.nameEn : values.nameTh,
      startDate: values.dateStart,
      expireDate: values.dateEnd,
      menuCode: active ? active : '',
      checkBoxInput: newFields,
    }
    await createSystemRoles({
      variables: {
        input: encryptInput(dataInput),
      },
    }).then((res) => {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          refetchSystemMenu()
          setRoleCode(res.data.createSystemRoles.roleCode)
          setTypeAction2('edit')
        },
      })
    })
  }

  const onFinishFailed = () => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t('กรุณากรอกข้อมูลให้ครบถ้วน'),
      onOk() {},
    })
  }

  const fnConfirm = (values) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        onSubmit(values)
      },
      onCancel() {},
    })
  }

  const breadcrumbList = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '#' },
    { label: t('จัดการกลุ่มสิทธิผู้ใช้งาน'), path: '/administrator/permissions' },
    {
      label:
        state.type_action === 'add'
          ? t('เพิ่มกลุ่มสิทธิผู้ใช้งาน')
          : state.type_action === 'edit'
          ? t('แก้ไขกลุ่มสิทธิผู้ใช้งาน')
          : '',
    },
  ]
  return (
    <>
      {loadingRoleMenu || loadingCreateRoles || loadingMenus || loadingRoleFild ? <SpinnersNew /> : null}
      <ButtonActive>
        <Breadcrumb
          data={breadcrumbList}
          title={
            state.type_action === 'add'
              ? t('เพิ่มกลุ่มสิทธิผู้ใช้งาน')
              : state.type_action === 'edit'
              ? t('แก้ไขกลุ่มสิทธิผู้ใช้งาน')
              : ''
          }
        />
        <CardNew
          topic={state.type_action === 'add' ? t('เพิ่มกลุ่มสิทธิ์ผู้ใช้งาน') : t('แก้ไขกลุ่มสิทธิ์ผู้ใช้งาน')}
          icon={state.type_action === 'add' ? `Plus` : state.type_action === 'edit' ? 'Edit' : ''}
          toggledrop={'false'}
        >
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelAlign="left"
            labelCol={{
              md: 8,
              lg: 8,
            }}
            wrapperCol={{
              md: 24,
              lg: 14,
              align: 'left',
            }}
            layout="horizontal"
            name="permissions_form"
          >
            <Row style={{ marginTop: 24, marginBottom: 24 }} justify="center">
              <Col md={24} lg={14}>
                <Form.Item
                  name="roleCode"
                  label={<LabelNew type="tab-header-inactive">{t('รหัสกลุ่มสิทธิ์')}</LabelNew>}
                  rules={[...validateCode(true, t('กรุณาระบุ'))]}
                >
                  <InputNew
                    placeholder={t('ระบุรหัสกลุ่มสิทธิ์')}
                    disabled={typeAction2 === 'edit' ? true : typeAction}
                    maxLength={10}
                  />
                </Form.Item>
                {/* {localStorage.getItem('lang') === 'th' ? ( */}
                <Form.Item
                  name="nameTh"
                  label={<LabelNew type="tab-header-inactive">{t('ชื่อกลุ่มสิทธิ์ภาษาไทย')}</LabelNew>}
                  placeholder={t('ระบุชื่อกลุ่มสิทธิ์ภาษาไทย')}
                  // rules={[...validateTh(true, t('กรุณาระบุชื่อกลุ่มสิทธิ์ไทย'))]}
                  rules={[
                    { required: true, message: t('กรุณาระบุ') },
                    {
                      pattern: regexEngThai,
                      message: t(`กรุณากรอกด้วยภาษาไทยหรือภาษาอังกฤษหรือตัวเลข ก-๙, 0-9`),
                    },
                  ]}
                >
                  <InputNew placeholder={t('ระบุชื่อกลุ่มสิทธิ์ภาษาไทย')} disabled={typeAction} maxLength={200} />
                </Form.Item>
                {/* ) : ( */}
                <Form.Item
                  name="nameEn"
                  label={<LabelNew type="tab-header-inactive">{t('ชื่อกลุ่มสิทธิ์ภาษาอังกฤษ')}</LabelNew>}
                  placeholder={t('ระบุชื่อกลุ่มสิทธิ์ภาษาอังกฤษ')}
                  // rules={[...validateEng(false, t('กรุณาระบุชื่อกลุ่มสิทธิ์อังกฤษ'))]}
                  rules={[
                    {
                      pattern: regexEng,
                      message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
                    },
                  ]}
                >
                  <InputNew placeholder={t('ระบุชื่อกลุ่มสิทธิ์ภาษาอังกฤษ')} disabled={typeAction} maxLength={200} />
                </Form.Item>
                {/* )} */}

                <Form.Item
                  name="dateStart"
                  label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้นใช้งาน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุวันที่เริ่มต้นใช้งาน') }]}
                >
                  <Datepicker
                    disabled={state.type_action === 'view' ? true : false}
                    setValueDateFn={async (data) => {
                      await setIsMinDate(data)
                      await form.setFieldsValue({ dateStart: await data })
                    }}
                    isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                    selectsStart={true}
                    onClear={() => {
                      form.setFieldsValue({ dateStart: null })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="dateEnd"
                  label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุดใช้งาน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุวันที่สิ้นสุดใช้งาน') }]}
                >
                  <Datepicker
                    disabled={state.type_action === 'view' ? true : false}
                    setValueDateFn={async (data) => {
                      await setIsMaxDate(data)
                      await form.setFieldsValue({ dateEnd: await data })
                    }}
                    isMinDate={isMinDate ? new Date(isMinDate) : null}
                    selectsEnd={true}
                    onClear={() => {
                      form.setFieldsValue({ dateEnd: null })
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            {/* ---------- */}
            <Row gutter={[16, 16]}>
              <Col {...Responesive} align="center">
                <LabelNew type="notify-text">{t('เลือกรายการโมดูลเพื่อจัดการสิทธิ์')}</LabelNew>
              </Col>
              <Col {...Responesive}>
                <Row gutter={[8, 8]} justify="center">
                  {dataMenus?.getSystemMenus.map((item, i) => {
                    return (
                      <Col key={i}>
                        <ButtonNew
                          className={active === item.menuCode ? 'active' : item.canView ? 'active2' : ''}
                          onClick={() => {
                            setActive(item.menuCode)
                          }}
                          type="module"
                        >
                          {displayText(item.menuNameTH, item.menuNameEN)}
                        </ButtonNew>
                      </Col>
                    )
                  })}
                </Row>
              </Col>
              <Col {...Responesive} align="center">
                {active ? (
                  <>
                    <Table
                      columns={Columns(typeAction, form, dataTable, checkStatusHeader)}
                      dataSource={dataTable}
                      bordered
                      size="middle"
                      scroll={{ x: '100%', y: 500 }}
                      pagination={false}
                    />
                  </>
                ) : (
                  ''
                )}
              </Col>
              <Col {...Responesive} align="center">
                <Space size={[8, 8]} wrap>
                  {state.type_action !== 'view' ? (
                    <>
                      <ButtonNew type="primary" roles={{ type: state.type_action, menu: menu }} htmlType="submit">
                        {t('บันทึก')}
                      </ButtonNew>
                    </>
                  ) : (
                    ''
                  )}
                  &nbsp;
                  <ButtonNew type="back" onClick={() => navigate(-1)}>
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Space>
              </Col>
            </Row>
          </Form>
        </CardNew>
      </ButtonActive>
    </>
  )
}

export default PermissionsForm
