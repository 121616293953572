import React from 'react'
import { Input } from 'antd'

export const TextAreaNew = (props) => {
  const { TextArea } = Input
  return (
    <>
      <TextArea {...props}>{props.children}</TextArea>
    </>
  )
}
