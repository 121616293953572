import React, { useState, useEffect } from 'react'
import { Form, Space } from 'antd'
import { ModalNew } from '../../../../../components/modal/Modal'
import { useTranslation } from 'react-i18next'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { RadioGroup, Radio } from '../../../../../components/radio/Radio'
import { InputNew, InputNumberNew } from '../../../../../components/input/Input'
import { Alert } from '../../../../../components/alert/Alert'
import { regexNumberComma } from '../../../../../utilitys/helper'
import * as _ from 'lodash'
import { checkLanguageReturnData } from '../assessmentFormfn'

export default function FormGroupModal(props) {
  const { open, modalType, dataGroupObj, dataListObj } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const formName = 'assessment-form-create'

  const [editable, setEditable] = useState(true)

  const alertDup = () => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถเพิ่มได้กลุ่มที่ซ้ำ'),
      content: '',
      onOk() {},
    })
  }

  const onClose = (e) => {
    form.resetFields()
    props.close(e)
  }

  const onFinish = async (input) => {
    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกแบบประเมิน'),
      onOk: () => {
        if (modalType === 'add') {
          // เชค sort ซ้ำ เมื่อ create
          const dupIndex = _.find(dataListObj, function (o) {
            return Number(o.formSort) === Number(input.formSort)
          })
          if (dupIndex) {
            alertDup()
          } else {
            const fieldV = form.getFieldValue()
            input['formGroupId'] = Number(fieldV.formSort)
            props.dataOnSave(input)
            form.resetFields()
            props.close(false)
          }
        } else {
          // เชค sort ซ้ำ เมื่อ update
          const dupIndex = _.find(dataListObj, function (o) {
            return Number(o.formSort) === Number(input.formSort)
          })
          if (dupIndex && input.formSort !== dataGroupObj.formSort) {
            alertDup()
          } else {
            const filterOle = _.filter(dataListObj, function (o) {
              return dataGroupObj.formSort !== o.formSort
            })
            input['key'] = Number(input.formSort)
            input['formGroupId'] = Number(input.formSort)
            input['formGroupName'] =
              input.formSort + '. ' + checkLanguageReturnData(input.formGroupNameTH, input.formGroupNameEN)
            props.dataOnUpdate([...filterOle, input], dataGroupObj.formGroupId, Number(input.formSort))
            form.resetFields()
            props.close(false)
          }
        }
      },
      onCancel() {
        return null
      },
    })
  }

  useEffect(() => {
    if (open) {
      if (modalType === 'view') {
        setEditable(true)
        form.setFieldsValue({
          formSort: dataGroupObj.formSort,
          formGroupNameTH: dataGroupObj.formGroupNameTH,
          formGroupNameEN: dataGroupObj.formGroupNameEN ? dataGroupObj.formGroupNameEN : dataGroupObj.formGroupNameTH,
          isActive: dataGroupObj.isActive,
        })
      } else if (modalType === 'edit') {
        setEditable(false)
        form.setFieldsValue({
          formGroupId: dataGroupObj.formGroupId,
          formSort: dataGroupObj.formSort,
          formGroupNameTH: dataGroupObj.formGroupNameTH,
          formGroupNameEN: dataGroupObj.formGroupNameEN ? dataGroupObj.formGroupNameEN : dataGroupObj.formGroupNameTH,
          isActive: dataGroupObj.isActive,
        })
      } else {
        setEditable(false)
      }
    }
  }, [open])

  return (
    <React.Fragment>
      <ModalNew
        okButtonProps={modalType === 'view' ? { style: { display: 'none' } } : {}}
        visible={open}
        testTitle={t('เพิ่มกลุ่มคำถาม')}
        btnName={modalType === 'add' ? t('เพิ่ม') : t('แก้ไข')}
        onSubmit={form.submit}
        onClose={(e) => {
          onClose(e)
        }}
        type="medium"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item name="formGroupId" hidden={true}>
            <InputNumberNew placeholder={t('ระบุกลุ่มที่')} maxLength={10} disabled={editable} />
          </Form.Item>
          <Form.Item
            name="formSort"
            label={<Label type="tab-header-inactive">{t('กลุ่มที่')}</Label>}
            rules={[
              { required: true, message: 'กรุณากรอกกลุ่มที่' },
              {
                pattern: regexNumberComma,
                message: t(`กรุณากรอกด้วยตัวเลข 0-9`),
              },
            ]}
          >
            {/* maxLength={10} */}
            <InputNumberNew placeholder={t('ระบุกลุ่มที่')} maxLength={4} disabled={editable} />
          </Form.Item>
          <Form.Item
            name="formGroupNameTH"
            label={<Label type="tab-header-inactive">{t('ชื่อกลุ่มข้อมูลภาษาไทย')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกชื่อกลุ่มข้อมูลภาษาไทย') }]}
          >
            <InputNew placeholder={t('กรุณาระบุชื่อกลุ่มข้อมูลภาษาไทย')} disabled={editable} maxLength={200} />
          </Form.Item>
          <Form.Item
            name="formGroupNameEN"
            label={<Label type="tab-header-inactive">{t('ชื่อกลุ่มข้อมูลภาษาอังกฤษ')}</Label>}
          >
            <InputNew placeholder={t('กรุณาระบุชื่อกลุ่มข้อมูลภาษาอังกฤษ')} disabled={editable} maxLength={200} />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
            <RadioGroup disabled={editable}>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                &nbsp;
                <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </React.Fragment>
  )
}
