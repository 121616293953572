import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError } from '../../../../../utilitys/helper'
import { dateDisplay } from '../../../../../utilitys/dateHelper'
import { displayText } from '../../../../../utilitys/helper'
import { Datatable } from '../../../../../components/datatable/Datatable.jsx'
import { ButtonNew } from '../../../../../components/button/Button'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { DATAFOLLOWUP_ACTIVITY_TAB_LIST } from '../../graphql/Query'
import { FOLLOW_UP_ACTIVITY_DELETE } from '../../graphql/Mutation'
import { encryptInput } from '../../../../../utilitys/crypto'
import { getSegmentUrl } from '../../../../../utilitys/helper'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'

const FormFollowUpAuditActivityTabIndex = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const typeMain = getSegmentUrl(4)

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }
  const showConfirmDelete = (activityId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        followUpActivityDeleteFn({
          variables: {
            input: encryptInput({ activityId: activityId }),
          },
          fetchPolicy: 'no-cache',
        })
      },
      onCancel() {},
    })
  }
  const showAlertDeleteError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถลบข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }
  const showAlertSuccess = () => {
    Alert({
      type: 'success',
      title: t('ลบสำเร็จ'),
      onOk() {
        dataFollowUpActivityTabList.refetch()
      },
    })
  }

  const [followUpActivityDeleteFn, followUpActivityDelete] = useMutation(FOLLOW_UP_ACTIVITY_DELETE)
  useEffect(() => {
    if (followUpActivityDelete.data) {
      let response = followUpActivityDelete?.data?.followUpActivityDelete
      if (response) {
        showAlertSuccess()
      }
    }
    if (followUpActivityDelete.error) {
      showAlertDeleteError(displayError(followUpActivityDelete.error))
    }
  }, [followUpActivityDelete.data])
  const [dataFollowUpActivityTabListFn, dataFollowUpActivityTabList] = useLazyQuery(DATAFOLLOWUP_ACTIVITY_TAB_LIST)
  useEffect(() => {
    if (dataFollowUpActivityTabList?.data) {
      let response = dataFollowUpActivityTabList?.data?.dataFollowUpActivityTabList
      setCount(response?.count)
      const issueId = []
      const suggestId = []
      setDataLists(
        response?.result.map((item, i) => {
          let issue = ''
          if (!issueId.includes(item.issueId)) {
            issue = displayText(item.issueTH, item.issueEN)
            issueId.push(item.issueId)
          }
          let suggest = ''
          if (!suggestId.includes(item.suggestId)) {
            suggest = displayText(item.suggestTH, item.suggestEN)
            suggestId.push(item.suggestId)
          }
          return {
            key: i,
            issue: issue,
            suggest: suggest,
            activity: displayText(item.activityTH, item.activityEN),
            organizationName: displayText(item.organizationNameTH, item.organizationNameEN),
            organizationParentName: displayText(item.organizationParentNameTH, item.organizationParentNameEN),
            signingDate: item.signingDate ? dateDisplay(item.signingDate) : null,
            documentNo: item.documentNo,
            meetingDate: item.meetingDate ? dateDisplay(item.meetingDate) : null,
            agendaName: displayText(item.agendaNameTH, item.agendaNameEN),
            mdNumber: item.mdNumber,
            dateSendReport: item.dateSendReport ? dateDisplay(item.dateSendReport) : null,
            expectDate: item.expectDate ? dateDisplay(item.expectDate) : null,
            action: (
              <div className="text-center">
                <ButtonGroup
                  menu={props.menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => {
                        navigate('/followup/followupaudit/activity/' + typeMain + '/form/view', {
                          state: { refCode: item.activityId },
                        })
                      },
                    },
                  ].concat(
                    typeMain === 'edit'
                      ? [
                          {
                            type: 'edit',
                            onClick: () => {
                              navigate('/followup/followupaudit/activity/' + typeMain + '/form/edit', {
                                state: { refCode: item.activityId },
                              })
                            },
                          },
                        ].concat(
                          item.deleteAbled === 1
                            ? [
                                {
                                  type: 'delete',
                                  onClick: () => {
                                    showConfirmDelete(item.activityId)
                                  },
                                },
                              ]
                            : [],
                        )
                      : [],
                  )}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (dataFollowUpActivityTabList.error) {
      showAlertLoadDataError(displayError(dataFollowUpActivityTabList.error))
    }
  }, [dataFollowUpActivityTabList?.data])

  const columns = [
    {
      title: () => <div className="text-center">{t('ประเด็น/ข้อสังเกต')}</div>,
      dataIndex: 'issue',
      width: '250px',
      className: 'vertical-align-top',
    },
    {
      title: () => <div className="text-center">{t('ข้อเสนอแนะ')}</div>,
      dataIndex: 'suggest',
      width: '250px',
      className: 'vertical-align-top',
    },
    {
      title: () => <div className="text-center">{t('กิจกรรมที่ต้องดำเนินการ')}</div>,
      dataIndex: 'activity',
      width: '200px',
      className: 'vertical-align-top',
    },
    {
      title: () => <div className="text-center">{t('ฝ่ายงานที่เกี่ยวข้อง')}</div>,
      dataIndex: 'organizationName',
      width: '150px',
      className: 'vertical-align-top',
    },
    {
      title: () => <div className="text-center">{t('สายงานที่เกี่ยวข้อง')}</div>,
      dataIndex: 'organizationParentName',
      width: '150px',
      className: 'vertical-align-top',
    },
    {
      title: () => <div className="text-center">{t('รหัสหนังสือภายในรายงานตรวจสอบ')}</div>,
      dataIndex: 'documentNo',
      width: '250px',
      className: 'vertical-align-top',
    },
    {
      title: () => <div className="text-center">{t('วันที่ออกหนังสือภายใน')}</div>,
      dataIndex: 'signingDate',
      width: '200px',
      className: 'vertical-align-top',
      render: (record) => <div className="text-center">{record}</div>,
    },
    {
      title: () => <div className="text-center">{t('วันที่เข้า AC')}</div>,
      dataIndex: 'meetingDate',
      width: '120px',
      className: 'vertical-align-top',
      render: (record) => <div className="text-center">{record}</div>,
    },
    {
      title: () => <div className="text-center">{t('หมายเลขวาระ AC')}</div>,
      dataIndex: 'agendaName',
      width: '150px',
      className: 'vertical-align-top',
    },
    {
      title: () => <div className="text-center">{t('เลขที่หนังสือ ผจ ลบ สั่งการ')}</div>,
      dataIndex: 'mdNumber',
      width: '200px',
      className: 'vertical-align-top',
    },
    {
      title: () => <div className="text-center">{t('วันที่ ผจ ลบ.')}</div>,
      dataIndex: 'dateSendReport',
      width: '150px',
      className: 'vertical-align-top',
      render: (record) => <div className="text-center">{record}</div>,
    },
    {
      title: () => <div className="text-center">{t('วันที่คาดว่าจะเสร็จ')}</div>,
      dataIndex: 'expectDate',
      width: '150px',
      className: 'vertical-align-top',
      render: (record) => <div className="text-center">{record}</div>,
    },
    {
      title: () => '',
      dataIndex: 'action',
      width: '120px',
      className: 'vertical-align-top',
    },
  ]
  const callApiSearch = () => {
    dataFollowUpActivityTabListFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          filters: {
            projectCode: props.refCode,
          },
          pagination: { limit, page },
          sort: [
            {
              fieldSort: 'projectId',
              sortType: 'DESC',
            },
          ],
        }),
      },
    })
  }

  useEffect(() => {
    callApiSearch()
  }, [page, limit])

  return (
    <>
      {(dataFollowUpActivityTabList.loading || followUpActivityDelete.loading) && <SpinnersNew />}

      <Row>
        <Col md={24}>
          <Datatable
            columns={columns}
            data={dataLists}
            handleTableChange={() => {}}
            paginationCustom={true}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            searchCustom={true}
            total={count}
            enableScroll={{ x: true, y: true }}
            pageSize={limit}
            page={page}
            btnAdd={
              <>
                <ButtonNew
                  type="plusTableBorderPrimary"
                  roles={{ type: 'add', menu: props.menu }}
                  style={{ marginRight: '12px' }}
                  disabled={typeMain === 'view' ? true : false}
                  onClick={() => {
                    navigate('/followup/followupaudit/activity/' + typeMain + '/form/add', {
                      state: { refCode: props.refCode },
                    })
                  }}
                >
                  {t('กิจกรรมตรวจสอบ')}
                </ButtonNew>
              </>
            }
          />
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="center" style={{ marginTop: '50px' }}>
        <Col>
          <ButtonNew
            type="cancel"
            onClick={() => {
              navigate('/followup/followupaudit')
            }}
          >
            {t('ยกเลิก')}
          </ButtonNew>
        </Col>
      </Row>
    </>
  )
}

export default FormFollowUpAuditActivityTabIndex
