import { Trans } from 'react-i18next'
// import { Alert } from '../../../../components/alert/Alert'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { Status } from '../../../../components/status/Status'

export const Columns = ({ fncOnpressDelete, fncOnpressView, fncOnpressEdit, menu }) => {
  return [
    {
      title: (
        <div style={{ textAlign: 'start' }}>
          <Trans>ลำดับ</Trans>
        </div>
      ),
      dataIndex: 'index',
      sorter: false,
      width: '8%',
      render: (item, record) => {
        return <div style={{ textAlign: 'start' }}>{record.no}</div>
      },
    },
    {
      title: <Trans>กิจกรรม</Trans>,
      dataIndex: 'factorActName',
      sorter: true,
      width: '31%',
      render: (item, record) => {
        return (
          <div>
            {record.factorActCode} : {record.factorActName}
          </div>
        )
      },
    },
    {
      title: <Trans>ปัจจัยความเสี่ยง</Trans>,
      dataIndex: 'riskFactorName',
      sorter: true,
      width: '31%',
      render: (item, record) => {
        return (
          <div>
            {record.riskFactorCode} : {record.riskFactorName}
          </div>
        )
      },
    },
    {
      title: <Trans>สถานะ</Trans>,
      dataIndex: 'isActive',
      sorter: true,
      width: '15%',
      render: (item) => {
        return <Status text={item === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '15%',
      render: (item, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup
              menu={menu}
              icons={[
                {
                  type: 'view',
                  onClick: () => {
                    fncOnpressView(record)
                  },
                },
                {
                  type: 'edit',
                  onClick: () => {
                    fncOnpressEdit(record)
                  },
                },
                {
                  type: 'delete',
                  onClick: () => {
                    fncOnpressDelete(record)
                  },
                },
              ]}
            />
          </div>
        )
      },
    },
  ]
}
