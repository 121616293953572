import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'antd'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { Columns } from './utils/OperationSetupFormColumn'
import { RowStyle } from './css/OperationSetupRow'
import OperationSetupFormModalMain from './OperationSetupFormModalMain'
import OperationSetupFormModalDetail from './OperationSetupFormModalDetail'

import { InputNew } from '../../../components/input/Input'
import { Radio, RadioGroup } from '../../../components/radio/Radio'
import { Alert } from '../../../components/alert/Alert'
import { LabelNew } from '../../../components/label/Label'
import { CardNew } from '../../../components/card/Card'
import { ButtonNew } from '../../../components/button/Button'
import { IconNew } from '../../../components/icon/Icon'
import { displayError, displayText, regexEngNoSpace } from '../../../utilitys/helper'

import { Datatable } from '../../../components/datatable/Datatable'
import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { GET_OPERATION_SETUP } from './graphql/Query'
import { encryptInput } from '../../../utilitys/crypto'
import { DELETE_OPERATION_SETUP_DETAIL, SAVE_OPERATION_SETUP } from './graphql/Mutation'

const OperationSetupForm = ({ menu }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [editable, seteditable] = useState(true)
  const { state } = useLocation()
  if (!state) {
    return <Navigate to={'/administrator/operationsetup'} />
  }

  const { type_action: mainFormType = 'view', refCode: stepModelCode } = state

  const renderTextTitle = () => {
    if (mainFormType === 'add') return 'เพิ่มจัดการคลังขั้นตอนการปฎิบัติงาน'
    if (mainFormType === 'view') return 'ดูจัดการคลังขั้นตอนการปฎิบัติงาน'
    if (mainFormType === 'edit') return 'แก้ไขจัดการคลังขั้นตอนการปฎิบัติงาน'
    return 'เพิ่มจัดการคลังขั้นตอนการปฎิบัติงาน'
  }

  const arrCrumb = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/operationsetup' },
    { label: t('จัดการคลังขั้นตอนการปฎิบัติงาน'), path: '/administrator/operationsetup' },
    { label: t(renderTextTitle()) },
  ]

  const [formTypeMain, setformTypeMain] = useState()
  const [dataFormMain, setdataFormMain] = useState()
  const [formTypeDetail, setformTypeDetail] = useState()
  const [dataFormDetail, setdataFormDetail] = useState()

  const [dataRow, setdataRow] = useState([])

  const [modalVisibleMain, setmodalVisibleMain] = useState(false)

  const [modalVisibleDetail, setmodalVisibleDetail] = useState(false)

  const [callGet, { loading: loadingGet, data: dataGet, error: errorGet }] = useLazyQuery(GET_OPERATION_SETUP)

  useEffect(() => {
    if (errorGet) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorGet)),
      })
    }
  }, [errorGet])

  useEffect(() => {
    if (dataGet) {
      const field = dataGet.getOperationSetup

      field.stepModel = displayText(field.stepModelTH, field.stepModelEN)

      const main = field.stepModelDetail.filter((value) => value.stepDetailType === 1)

      const mainAndChildren = main.map((value, key) => {
        const children = field.stepModelDetail.filter((vfil) => value.stepDetailId === vfil.stepDetailRef)
        let stepDetailMain = displayText(value.stepDetailTH, value.stepDetailEN)
        if (!stepDetailMain) stepDetailMain = value.stepDetailTH

        let stepTypeName = null
        if (value.stepTypeCode) {
          stepTypeName = displayText(value.stepTypeNameTH, value.stepTypeNameEN)
        }

        return {
          ...value,
          key,
          stepSortMain: value.stepSort,
          stepDetailMain,
          stepTypeName,
          children: children.map((vchild, vkey) => {
            let stepDtail = displayText(vchild.stepDetailTH, vchild.stepDetailEN)

            if (!stepDtail) stepDtail = vchild.stepDetailTH
            return {
              ...vchild,
              stepSortMain: value.stepSort,
              stepDtail,
              key: parseInt(1 + vkey + '00' + key),
            }
          }),
        }
      })
      setdataRow(mainAndChildren)
      form.setFieldsValue(field)
    }
  }, [dataGet])

  useEffect(() => {
    if (stepModelCode) {
      const dataInput = {
        stepModelCode: stepModelCode,
      }

      callGet({
        variables: {
          input: encryptInput(dataInput),
        },
      })

      if (mainFormType === 'view') seteditable(false)
    }
  }, [stepModelCode])

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_OPERATION_SETUP)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          navigate('/administrator/operationsetup')
        },
      })
    }
  }, [dataSave])

  const [callDelete, { loading: loadingDelete, error: errorDelete, data: dataDelete }] =
    useMutation(DELETE_OPERATION_SETUP_DETAIL)

  useEffect(() => {
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(errorDelete)),
        onOk() {},
      })
    }
  }, [errorDelete])

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        content: t(dataDelete.message),
        onOk() {
          fncGetList()
        },
      })
    }
  }, [dataDelete])

  const onFinish = () => {
    const fields = form.getFieldValue()
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        const dataInput = {
          stepModelCode: fields.stepModelCode,
          stepModelTH: fields.stepModelTH,
          stepModelEN: fields.stepModelEN ? fields.stepModelEN : fields.stepModelTH,
          isActive: fields.isActive,
          typeAction: 'edit',
        }

        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const fncGetList = () => {
    const dataInput = {
      stepModelCode: stepModelCode,
    }

    callGet({
      variables: {
        input: encryptInput(dataInput),
      },
    })
  }

  const handleModalOnSuccess = () => {
    setdataFormMain()
    setmodalVisibleMain(false)
    setmodalVisibleDetail(false)
    fncGetList()
  }

  const handleModalOnClose = (data) => {
    setdataFormMain()
    setdataFormDetail()
    setmodalVisibleMain(data)
    setmodalVisibleDetail(data)
  }

  const fncOnpressDelete = (data) => {
    const dataInput = {
      stepDetailId: data.stepDetailId,
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ?'),
      onOk() {
        callDelete({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const fncOnpressView = (data) => {
    if (data.stepDetailType === 1) {
      setmodalVisibleMain(true)
      setdataFormMain(data)
      setformTypeMain('view')
    } else {
      setmodalVisibleDetail(true)
      setdataFormDetail(data)
      setformTypeDetail('view')
      setmodalVisibleDetail(true)
    }
  }

  const fncOnpressEdit = (data) => {
    if (data.stepDetailType === 1) {
      setmodalVisibleMain(true)
      setdataFormMain(data)
      setformTypeMain('edit')
    } else {
      setdataFormDetail(data)
      setformTypeDetail('edit')
      setmodalVisibleDetail(true)
    }

    // navigate('/administrator/operationsetup/form', {
    //   state: { formType: 'edit', refCode: data.factorActCode },
    // })
  }

  const renderIconTitle = () => {
    if (mainFormType === 'view') return 'FileText'
    if (mainFormType === 'edit') return 'Edit2'
    else return 'Plus'
  }

  const formName = 'operationSetupForm'

  return (
    <React.Fragment>
      <BreadcrumbNew data={arrCrumb} title={t('จัดการคลังขั้นตอนการปฎิบัติ')} />
      <CardNew topic={t(renderTextTitle())} icon={renderIconTitle()}>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 9,
            lg: 9,
            xl: 7,
          }}
          wrapperCol={{
            md: 9,
            lg: 9,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name="stepModelCode"
            label={t('รหัสคลังขั้นตอนการปฎิบัติงาน')}
            rules={[
              { required: true, message: t('กรุณาระบุ') },
              {
                pattern: regexEngNoSpace,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษพิมพ์ใหญ่,ตัวเลข A-Z, 0-9 ไม่เกิน 10 ต้ว`),
              },
            ]}
          >
            <InputNew disabled={true} placeholder="กรอกรหัสคลังขั้นตอนการปฎิบัติงาน" />
          </Form.Item>

          <Form.Item
            name="stepModelTH"
            label={t('ชื่อคลังขั้นตอนการปฎิบัติงาน')}
            rules={[{ required: true, message: t('กรุณาระบุ') }]}
          >
            <InputNew disabled={!editable} placeholder={t('กรอกชื่อคลังขั้นตอนการปฎิบัติงาน')} />
          </Form.Item>
          <Form.Item name="stepModelEN" label={t('ชื่อคลังขั้นตอนการปฎิบัติงานอังกฤษ')}>
            <InputNew disabled={!editable} placeholder={t('กรอกชื่อคลังขั้นตอนการปฎิบัติงานอังกฤษ')} />
          </Form.Item>

          <Form.Item name="isActive" label={t('สถานะ')} initialValue={1}>
            <RadioGroup disabled={!editable}>
              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                </Col>
                <Col>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>

          <Row gutter={[12, 12]} align="middle" style={{ marginTop: 55, marginBottom: 20 }}>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconNew icon={'List'} size={24} color="" />
            </Col>
            <Col>
              <LabelNew type="body-header">{t('รายการข้อมูลขั้นตอนการปฎิบัติงาน')}</LabelNew>
            </Col>
          </Row>
        </Form>

        <RowStyle>
          <Datatable
            columns={Columns({ fncOnpressDelete, fncOnpressView, fncOnpressEdit, menu, editable })}
            data={dataRow}
            // scroll={{ x: 768 }}
            isLoading={loadingGet || loadingSave || loadingDelete}
            rowClassName={(record) => {
              if (record.children) return 'table-row-color'
              return ''
            }}
            // expandable={{ expandedRowRender }}
            //   isLoading={loadingList}

            btnAdd={
              editable ? (
                <>
                  <ButtonNew
                    onClick={() => {
                      setformTypeMain('add')
                      setmodalVisibleMain(true)
                      setdataFormMain()
                    }}
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    style={{ marginRight: 15 }}
                  >
                    {t('ขั้นตอนหลัก')}
                  </ButtonNew>
                  <ButtonNew
                    onClick={() => {
                      setdataFormDetail()
                      setformTypeDetail('add')
                      setmodalVisibleDetail(true)
                    }}
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                  >
                    {t('ขั้นตอนย่อย')}
                  </ButtonNew>
                </>
              ) : (
                <></>
              )
            }
          />
        </RowStyle>

        <Row gutter={[12, 12]} justify="center" style={{ marginBottom: 20, marginTop: 20 }}>
          {editable && (
            <Col>
              <ButtonNew
                onClick={() => {
                  form.validateFields().then(() => onFinish())
                }}
                type="primary"
                htmlType="submit"
                roles={{ type: 'add', menu: menu }}
              >
                {t('บันทึก')}
              </ButtonNew>
            </Col>
          )}
          <Col>
            <ButtonNew onClick={() => navigate('/administrator/operationsetup')} type="back">
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Col>
        </Row>
      </CardNew>
      <OperationSetupFormModalMain
        stepModelCode={stepModelCode}
        formType={formTypeMain}
        dataForm={dataFormMain}
        visible={modalVisibleMain}
        onSuccess={handleModalOnSuccess}
        onClose={handleModalOnClose}
      />
      <OperationSetupFormModalDetail
        stepModelCode={stepModelCode}
        formType={formTypeDetail}
        dataForm={dataFormDetail}
        visible={modalVisibleDetail}
        onSuccess={handleModalOnSuccess}
        onClose={handleModalOnClose}
      />
    </React.Fragment>
  )
}
export default OperationSetupForm
