import { Card, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'

import { Datatable } from '../../../../components/datatable/Datatable'
import { NoData } from '../../../../components/nodatagraph/NoData'

import { GET_DATA_OVERVIEW_FOLLOWUP_CONSULT } from './graphql/Query'

import { encryptData, encryptInput } from '../../../../utilitys/crypto'
import { Alert } from '../../../../components/alert/Alert'

import { displayError, displayText } from '../../../../utilitys/helper'

import { Columns } from './utils/OverviewFollowUpConsultColumn'
import { TableAmountStyled } from './css/TableAmountStyled'
import { handleSort } from '../../../../utilitys/pagination'
import { SpinnersSmaillNew } from '../../../../components/spinners/Spinners'
import { dateDisplay } from '../../../../utilitys/dateHelper'

const Responsive = {
  md: 24,
  lg: 24
}

const OverviewFollowUpConsult = (props) => {
  const { dataFilter } = props

  const { t } = useTranslation()

  const [total, settotal] = useState(0)
  const [dataRow, setdataRow] = useState([])
  const [paginate, setPaginate] = useState({ page: 1, limit: 10 })
  const [sort, setsort] = useState([{ fieldSort: 'jobNumber', sortType: 'ASC' }])

  // =================================== API =======================================
  const [callGraphConsultFnc, { data: dataTable, loading: loadingTable, error: errorTable }] = useLazyQuery(
    GET_DATA_OVERVIEW_FOLLOWUP_CONSULT
  )

  useEffect(() => {
    if (dataTable) {
      const { result, count } = dataTable.getDataOverviewFollowUpConsult
      setdataRow(
        result.map((items) => {
          return {
            ...items,
            jobTypeName: displayText(items.jobTypeNameTH, items.jobTypeNameEN),
            jobSubject: displayText(items.jobSubjectTH, items.jobSubjectEN),
            organizationName: displayText(items.organizationNameTH, items.organizationNameEN),
            requestOrg: displayText(items.requestOrgTH, items.requestOrgEN),
            jobStatusName: displayText(items.jobStatusNameTH, items.jobStatusNameEN),
            approveStatusName: displayText(items.approveStatusNameTH, items.approveStatusNameEN),
            dateStart: dateDisplay(items.dateStart),
            dateEnd: dateDisplay(items.dateEnd),
            key: items.no + 'consult'
          }
        })
      )
      settotal(count)
    }

    if (errorTable) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorTable)),
        onOk() {}
      })
    }
  }, [dataTable])

  // =================================== CAll API =======================================
  const { projectCode, graph } = dataFilter

  useEffect(() => {
    FncGetData()
  }, [projectCode, paginate.page, paginate.limit, sort, graph])

  useEffect(() => {
    setPaginate({ page: 1, limit: paginate.limit })
  }, [projectCode])

  const FncGetData = () => {
    setdataRow([])

    if (graph !== 3) return
    if (projectCode) {
      const dataInput = {
        sort: sort,
        filters: {
          projectCode: projectCode ? projectCode : null
        },
        pagination: {
          page: paginate.page,
          limit: paginate.limit
        }
      }

      callGraphConsultFnc({
        variables: {
          input: encryptInput(dataInput)
        }
      })
    }
  }

  const onRowClick = async (record) => {
    const data = {
      jobCode: record.jobCode
    }
    const enData = encryptData(data)
    localStorage.setItem('adhocjob', enData)

    const route = `/auditoperations/adhocjob/overviewfollowup`
    window.open(route, '_blank')
  }

  return (
    <TableAmountStyled>
      <Card className="">
        <Row gutter={[16, 8]}>
          <Col {...Responsive}>
            {loadingTable && <SpinnersSmaillNew />}
            {!_.isEmpty(dataRow) ? (
              <Datatable
                columns={Columns({})}
                data={dataRow}
                total={total}
                searchCustom={false}
                paginationCustom={true}
                scroll={{ x: 2000 }}
                enableScroll={{ x: true, y: false }}
                pageSize={paginate.limit}
                page={paginate.page}
                onChangePagination={({ page, pageSize }) => {
                  setPaginate({ page, limit: pageSize })
                }}
                handleTableChange={(e) => {
                  const reNewField = [
                    { no: 'jobId' },
                    { jobSubject: 'jobNumber' },
                    { jobTypeName: displayText('jobTypeNameTH', 'jobTypeNameEN') },
                    { organizationName: displayText('organizationNameTH', 'organizationNameEN') },
                    { requestOrg: displayText('requestOrgTH', 'requestOrgEN') },
                    { jobStatusName: displayText('jobStatusNameTH', 'jobStatusNameEN') },
                    { approveStatusName: displayText('approveStatusNameTH', 'approveStatusNameEN') }
                  ]

                  setsort(handleSort(e?.sorter, reNewField))
                }}
                onRow={(record) => ({
                  onClick: () => {
                    onRowClick(record)
                  }
                })}
              />
            ) : (
              <NoData />
            )}
          </Col>
        </Row>
      </Card>
    </TableAmountStyled>
  )
}

export default OverviewFollowUpConsult
