import { gql } from '@apollo/client'
export const GET_LIST_RISK_PROCESS_PAGINATION = gql`
  query GetListRiskProcessPagination($input: RiskProcessPagination!) {
    getListRiskProcessPagination(input: $input) {
      result {
        no
        rateFacGroupCode
        rateFacGroupNameTH
        rateFacGroupNameEN
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_RISK_PROCESS = gql`
  query GetRiskProcess($input: RiskProcessGetInput!) {
    getRiskProcess(input: $input) {
      rateFacGroupCode
      rateFacGroupNameTH
      rateFacGroupNameEN
      isActive
    }
  }
`
