import React, { useState } from 'react'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { useTranslation } from 'react-i18next'
import AuditProjectNotPlanListFilter from './conponent/filter/ApproveProjectNotPlanListFilter'
import { CardNew } from '../../../components/card/Card'
import { Row, Col } from 'antd'
import TabIndex from './conponent/tabapprovenotplan/TabIndex'

function ApproveAuditProjectNotPlanList(props) {
  const Responsive = {
    md: 24,
    lg: 24,
  }
  const { t } = useTranslation()
  const { menu } = props

  const [getFilters, setFilters] = useState({})

  const breadcrumbList = [
    { label: t('งานวางแผนการตรวจสอบ'), path: '/auditplanning/approveoffplanprojects' },
    { label: t('อนุมัติโครงการนอกแผน') },
  ]

  return (
    <>
      <Breadcrumb data={breadcrumbList} title={t('อนุมัติโครงการนอกแผน')} />
      <AuditProjectNotPlanListFilter
        getValue={(e) => {
          setFilters({
            auditProjectCode: e.projectCode ? e.projectCode : '',
            auditProjectName: e.projectName ? e.projectName : '',
            auditProjectRefCode: e.projectRef ? e.projectRef : '',
            organizationCode: e.projectOrganization2 ? e.projectOrganization2 : '',
            auditProjectTeam: e.projectAuditTeam ? e.projectAuditTeam : '',
            auditProjectStatus: e.projectStatus ? e.projectStatus : '',
          })
        }}
      />
      <CardNew topic={t('รายการแผนการตรวจสอบ')} icon="AlignJustify" toggledrop={'false'}>
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            <TabIndex menu={menu} filters={getFilters} />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default ApproveAuditProjectNotPlanList
