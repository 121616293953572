import { gql } from '@apollo/client'

export const GET_RAC_MATRIX_APPROVE_PAGINATION = gql`
  query getRacMaTrixApprovePagination($input: RacMatrixApprovePaginationInput!) {
    getRacMaTrixApprovePagination(input: $input) {
      result {
        rcmId
        projectCode
        projectNameTH
        projectNameEN
        projectRefNameTH
        projectRefNameEN
        dateStart
        dateEnd
        organizationNameTH
        organizationNameEN
        personnelNameTH
        personnelNameEN
        planStatusCode
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDIT_AND_ORGANIZATION_RCM = gql`
  query getAuditAndOrganizationRCMs($rcmId: String!) {
    getAuditAndOrganizationRCMs(rcmId: $rcmId) {
      rcmId
      projectCode
      rcmObjective
      rcmScope
      riskProcess
      riskProcessSub
      personnelApprove
      personnelMaker
      personnelReview
      isActive
      operationStatusCode
      planStatusCode
      riskModelCode
      riskModelTH
      riskModelEN
      personnelApproveNameTH
      personnelApproveNameEN
      personnelMakerNameTH
      personnelMakerNameEN
      personnelReviewNameTH
      personnelReviewNameEN
      organizationNameTH
      organizationNameEN
    }
  }
`

export const GET_AUDIT_RESULTS_APPROVE = gql`
  query getAuditResultsApprove($input: AuditResultApproveInput!) {
    getAuditResultsApprove(input: $input) {
      rcmResultId
      rcmId
      rateFacGroupCode
      rateFacProCode
      likelihood
      impact
      riskScore
      isActive
      rateStatus
      rateFacProNameTH
      rateFacProNameEN
      shortCode
      approveStatusCode
      sort
      personnelNameTH
      personnelNameEN
      personnelRCMNameTH
      personnelRCMNameEN
    }
  }
`

export const GET_AUDIT_RESULT_DETAIL_APPROVE = gql`
  query getAuditResultDetailApprove($input: AuditResultDetailApproveInput!) {
    getAuditResultDetailApprove(input: $input) {
      rcmResultDetailId
      rcmResultId
      rateFacProCode
      riskFactorCode
      likelihood
      impact
      riskScore
      riskFactorNameTH
      riskFactorNameEN
    }
  }
`

export const GET_AUDIT_RCM_RESULT_ITEM = gql`
  query getAuditRCMResultItem($rcmResultId: String!) {
    getAuditRCMResultItem(rcmResultId: $rcmResultId) {
      result {
        rcmResultId
        rcmId
        rateFacGroupCode
        rateFacProCode
        likelihood
        impact
        riskScore
        isActive
        rateStatus
        rateFacProNameTH
        rateFacProNameEN
        shortCode
        sort
        rateFacGroupNameTH
        rateFacGroupNameEN
        riskModelTH
        riskModelEN
        personnelRCMNameTH
        personnelRCMNameEN
      }
      resultDetails {
        rcmResultDetailId
        rcmResultId
        rateFacProCode
        riskFactorCode
        likelihood
        impact
        riskScore
        riskFactorNameTH
        riskFactorNameEN
      }
    }
  }
`
