import { gql } from '@apollo/client'
export const GET_ACTIONPLAN_PERSONNEL = gql`
  query getActionPlanPersonnelForAuditOpen($input: ActionPlanPersonnelInput!) {
    getActionPlanPersonnelForAuditOpen(data: $input) {
      actPlanPersonId
      namePrefixTH
      namePrefixEN
      organizationCode
      personnelCode
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
      email
    }
  }
`
