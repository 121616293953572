import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Space, Col } from 'antd'
import { v4 as uuidv4 } from 'uuid'
import { useTranslation } from 'react-i18next'
import { ModalNew } from '../../../../../components/modal/Modal'
import { InputNew, InputNumberNew } from '../../../../../components/input/Input'
import { ButtonNew } from '../../../../../components/button/Button'
import { Radio, RadioGroup } from '../../../../../components/radio/Radio'
import { LabelNew as Label } from '../../../../../components/label/Label'

const ConfigurationModal = (props) => {
  const formname = 'ConfigurationFormModal'
  const labelCol = { sm: 14, md: 9, lg: 9, xl: 9 }
  const wrapperCol = { sm: 16, md: 16, lg: 16, xl: 16, align: 'left' }

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [isKey, setIsKey] = useState(uuidv4())
  const [prevConfigNo, setPrevConfigNo] = useState(null)

  useEffect(() => {
    setVisible(props.open)
  }, [props.open])

  useEffect(() => {
    if (props.isConfigItem && props.accessModalConfAction === 'edit') {
      setIsKey(props?.isConfigItem?.key)
      setPrevConfigNo({ prev: props?.isConfigItem?.formConfigNo, cur: null })
      form.setFieldsValue({ ...props.isConfigItem })
    }
  }, [props.isConfigItem, props.accessModalConfAction])

  const onFinish = async (data) => {
    await props.getPrevConfigNo({ ...prevConfigNo, cur: parseInt(data.formConfigNo) })
    await props.getData({
      ...data,
      formConfigNo: parseInt(data.formConfigNo),
      isActive: data.isActive ? 1 : 0,
      key: isKey,
    })
    await setVisible(false)
    await props.close(false)
  }

  return (
    <ModalNew
      onSubmit={() => {}}
      onClose={() => Promise.all([setVisible(false), props.close(false)])}
      visible={visible}
      typeAction={props.accessTools === 'edit' ? 'edit' : props.accessTools === 'view' ? 'view' : null}
      testTitle={
        props.accessTools === 'edit'
          ? t('แก้ไของค์ประกอบการควบคุม')
          : props.accessTools === 'view'
          ? t('องค์ประกอบการควบคุม')
          : t('เพิ่มองค์ประกอบการควบคุม')
      }
      type="medium"
      footer={null}
    >
      <Col sm={24} md={16} lg={18} style={{ margin: 'auto' }}>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{ ...labelCol }}
          wrapperCol={{ ...wrapperCol }}
          layout="horizontal"
          name={formname}
        >
          <Form.Item
            name="formConfigNo"
            label={t('องค์ประกอบที่')}
            className="validate_form_config_no"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if (!value) {
                    return Promise.reject(new Error(t('กรุณาระบุองค์ประกอบที่')))
                  } else if (_.field === 'formConfigNo' && value?.length && props?.isConfiguration.length) {
                    if (props.isConfiguration.find((i) => i.formConfigNo === parseInt(value)))
                      return Promise.reject(new Error(t('องค์ประกอบที่ใช้งานแล้ว กรุณาระบุองค์ประกอบที่ใหม่')))
                    else return Promise.resolve()
                  } else {
                    return Promise.resolve()
                  }
                },
              },
            ]}
          >
            <InputNumberNew
              placeholder={t('ระบุองค์ประกอบที่')}
              disabled={props.accessTools ? (props.accessTools === 'view' ? true : false) : false}
            />
          </Form.Item>
          <Form.Item
            name="formConfigTH"
            label={'ชื่อองค์ประกอบการควบคุมไทย'}
            rules={[{ required: true, message: t('กรุณาระบุชื่อองค์ประกอบการควบคุมไทย') }]}
          >
            <InputNew
              placeholder={t('ระบุชื่อองค์ประกอบการควบคุมไทย')}
              disabled={props.accessTools ? (props.accessTools === 'view' ? true : false) : false}
              maxLength={100}
            />
          </Form.Item>
          <Form.Item name="formConfigEN" label={t('ชื่อองค์ประกอบการควบคุมอังกฤษ')}>
            <InputNew
              placeholder={t('ระบุชื่อองค์ประกอบการควบคุมอังกฤษ')}
              disabled={props.accessTools ? (props.accessTools === 'view' ? true : false) : false}
              maxLength={100}
            />
          </Form.Item>
          <Form.Item
            label={t('สถานะ')}
            name="isActive"
            initialValue={1}
            rules={[{ required: true, message: t('กรุณาระบุสถานะ') }]}
          >
            <RadioGroup disabled={props.accessTools ? (props.accessTools === 'view' ? true : false) : false}>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
              </Space>
            </RadioGroup>
          </Form.Item>

          {props?.accessTools && props?.accessTools === 'view' ? (
            <Col align="center">
              <Space size={[8, 8]} wrap>
                <ButtonNew type="back" onClick={() => Promise.all([setVisible(false), props.close(false)])}>
                  {t('ยกเลิก')}
                </ButtonNew>
              </Space>
            </Col>
          ) : (
            <Col align="center">
              <Space size={[8, 8]} wrap>
                <ButtonNew type="primary" roles={{ type: 'add', menu: props.menu }} htmlType="submit">
                  {t('เพิ่ม')}
                </ButtonNew>
                <ButtonNew type="back" onClick={() => Promise.all([setVisible(false), props.close(false)])}>
                  {t('ยกเลิก')}
                </ButtonNew>
              </Space>
            </Col>
          )}
        </Form>
      </Col>
    </ModalNew>
  )
}

ConfigurationModal.propTypes = {
  open: PropTypes.bool,
  getData: PropTypes.func,
  close: PropTypes.func,
  isConfiguration: PropTypes.array,
  menu: PropTypes.string.isRequired,
}

export default ConfigurationModal
