import { Trans } from 'react-i18next'
// import { Alert } from '../../../../components/alert/Alert'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { Status } from '../../../../../components/status/Status'

export const Columns = ({ fncOnpressDelete, fncOnpressView, fncOnpressEdit, mainFormType, menu }) => {
  return [
    {
      title: (
        <div style={{ textAlign: 'start', whiteSpace: 'nowrap' }}>
          <Trans>ลำดับ</Trans>
        </div>
      ),
      dataIndex: 'index',
      sorter: false,
      width: '8%',
      render: (item, record, index) => {
        return <div style={{ textAlign: 'start' }}>{index + 1}</div>
      },
    },
    {
      title: <Trans>ปัจจัยความเสี่ยงกระบวนการ</Trans>,
      dataIndex: 'rateFacProName',
      sorter: true,
      width: '62%',
      render: (item, record) => {
        return <div>{`${record.rateFacProCode} : ${record.rateFacProName}`}</div>
      },
    },
    {
      title: <Trans>สถานะ</Trans>,
      dataIndex: 'isActive',
      sorter: true,
      width: '15%',
      render: (item) => {
        return <Status text={item === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '15%',
      render: (item, record) => {
        const icons = [
          {
            type: 'view',
            onClick: () => {
              fncOnpressView(record)
            },
          },
        ]
        if (mainFormType !== 'view') {
          icons.push({
            type: 'edit',
            onClick: () => {
              fncOnpressEdit(record)
            },
          })
          icons.push({
            type: 'delete',
            onClick: () => {
              fncOnpressDelete(record)
            },
          })
        }
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} icons={icons} />
          </div>
        )
      },
    },
  ]
}
