import React from 'react'
import { Typography as Labels } from 'antd'
import { colors } from '../../configs/styles.config'
import { LabelStyle } from './LabelStyles'
import { IconNew } from '../icon/Icon'

export const LabelNew = (props) => {
  if (props.type === 'card-title') {
    return (
      <>
        <LabelStyle>
          <div className={props.type}>
            <div className="icon-show">
              <IconNew icon={props.icon} color="#333" />
            </div>
            <Labels>{props.children}</Labels>
          </div>
        </LabelStyle>
      </>
    )
  } else {
    return (
      <>
        <LabelStyle>
          {props.need === 'need' ? (
            <Labels {...props} className={props.type}>
              {props.children}
              <span style={{ color: colors.danger, fontSize: '18px' }}>*</span>{' '}
            </Labels>
          ) : (
            <Labels {...props} className={props.type}>
              {props.children}
            </Labels>
          )}
        </LabelStyle>
      </>
    )
  }
}
