import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_RATE_RISK_FACTOR_PROCESS, GET_RATE_RISK_FACTOR_PROCESS_BY_PROJECT_CODE } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectRateRiskFactorProcessModel = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { rateFacProCode, rateFacGroupCode, isActive, riskModelCode, projectCode, rcmId, approveStatusCode } =
    filters || {}

  const [isData, isSetData] = useState([])

  const [getRateRiskFactorProcessFn, getRateRiskFactorProcess] = useLazyQuery(
    props.exit ? GET_RATE_RISK_FACTOR_PROCESS_BY_PROJECT_CODE : GET_RATE_RISK_FACTOR_PROCESS,
  )

  useEffect(() => {
    getRateRiskFactorProcessFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [rateFacProCode, rateFacGroupCode, isActive, riskModelCode, projectCode, rcmId, approveStatusCode])

  useEffect(() => {
    if (getRateRiskFactorProcess.data) {
      const data = props.exit
        ? getRateRiskFactorProcess.data.getRateRiskFactorProcessByProjectCode
        : getRateRiskFactorProcess?.data?.getRateRiskFactorProcess

      const uniqData = _.uniqBy(data, 'rateFacProCode')
      const selectItem = uniqData.map((item) => {
        return {
          value: item.rateFacProCode,
          label: item.rateFacProCode + ': ' + displayText(item.rateFacProNameTH, item.rateFacProNameEN),
          source: item,
        }
      })
      isSetData(selectItem)
      // isSetData(
      //   _.map(getRateRiskFactorProcess?.data?.getRateRiskFactorProcess, (item) => {
      //     return {
      //       value: item.rateFacProCode,
      //       label: displayText(item.rateFacProNameTH, item.rateFacProNameEN),
      //       source: item,
      //     }
      //   }),
      // )
    }
  }, [getRateRiskFactorProcess.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getRateRiskFactorProcess.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectRateRiskFactorProcessModel
