import { gql } from '@apollo/client'

export const GETFORMASSESSPAGINATION = gql`
  query getFormAssessPagination($input: FormAssessPaginationInput!) {
    getFormAssessPagination(input: $input) {
      result {
        formAssessId
        formYear
        projectCode
        formCode
        startDate
        endDate
        isActive
        createdBy
        operationStatusCode
        formNameTH
        formNameEN
        formTypeNameTH
        formTypeNameEN
        projectNameTH
        projectNameEN
        formTypeCode
        auditProjectPersonnelCode
        organizationCode
        email
      }
      count
      page
      limit
    }
  }
`

export const GET_ORGANIZATION_PAGINATION = gql`
  query getMasterOrganizationsPagination($input: MasterOrganizationsPagination!) {
    getMasterOrganizationsPagination(input: $input) {
      result {
        organizationId
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationLevelNameTH
        organizationLevelNameEN
        organizationLevelCode
        personnelCode
        personnelNameTH
        personnelNameEN
      }
      count
      page
      limit
    }
  }
`

export const GETFORMSBYFORMASSESS = gql`
  query getFormsByFormAssess($isActive: Float!) {
    getFormsByFormAssess(isActive: $isActive) {
      formId
      formCode
      formNameTH
      formNameEN
      formTypeCode
      formType {
        formTypeNameTH
        formTypeNameEN
      }
    }
  }
`

export const GETAUDITPROJECTBYFORMASSESS = gql`
  query GetAuditProjectByFormAssess($inputData: GetAuditProjectByFormAssessInput!) {
    getAuditProjectByFormAssess(inputData: $inputData) {
      projectId
      projectCode
      projectNameTH
      projectNameEN
      organizationCode
    }
  }
`

export const AUDITPROJECTORGANIZATIONSBYFORMASSESS = gql`
  query auditProjectOrganizationsByFormAssess($projectCode: String!) {
    auditProjectOrganizationsByFormAssess(projectCode: $projectCode) {
      projectCode
      organizationCode
      personnelCode
      organizationNameTH
      organizationNameEN
      organizationLevelNameTH
      organizationLevelNameEN
      fullNameTH
      fullNameEN
    }
  }
`

export const GETFORMASSESSONE = gql`
  query getFormAssessOne($inputData: FormAssessInput!) {
    getFormAssessOne(inputData: $inputData) {
      personnelCode
      formAssessId
      formYear
      projectCode
      formCode
      startDate
      endDate
      isActive
      createdBy
      createdAt
      updatedAt
      createdAt
      formNameTH
      formNameEN
      formTypeNameTH
      formTypeNameEN
      operationStatusCode
      formTypeCode
    }
  }
`

export const GETFORMASSESSORGANIZATIONSBYFORMASSESS = gql`
  query getformAssessOrganizationsByformAssess($formAssessId: Float!) {
    getformAssessOrganizationsByformAssess(formAssessId: $formAssessId) {
      formOrgId
      formAssessId
      organizationCode
      personnelCode
      organizationNameTH
      organizationNameEN
      organizationLevelNameTH
      organizationLevelNameEN
      fullNameTH
      fullNameEN
    }
  }
`

export const GETEMAILORGANIZATIONBYFORMASSESS = gql`
  query getEmailOrganizationByFormAssess($formAssessId: String!) {
    getEmailOrganizationByFormAssess(formAssessId: $formAssessId) {
      personnelCode
      email
    }
  }
`

export const GETEMAILORGANIZATIONPROJECTBYFORMASSESS = gql`
  query getEmailOrganizationProjectByFormAssess($formAssessId: String!) {
    getEmailOrganizationProjectByFormAssess(formAssessId: $formAssessId) {
      personnelCode
      email
    }
  }
`

export const GETEMAILPERSONNELBYFORMASSESS = gql`
  query getEmailPersonnelByFormAssess($formAssessId: String!) {
    getEmailPersonnelByFormAssess(formAssessId: $formAssessId) {
      personnelCode
      email
    }
  }
`

export const GET_ACTIONPLAN_PERSONNEL_BY_PROJECTCODE = gql`
  query getActionPlanPersonnelOfProject($projectCode: String!) {
    getActionPlanPersonnelOfProject(projectCode: $projectCode) {
      personnelCode
      fullNameTH
      fullNameEN
    }
  }
`
