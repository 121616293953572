import { useTranslation } from 'react-i18next'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { ButtonNew } from '../../../../components/button/Button'
import { Status } from '../../../../components/status/Status.jsx'
import { useNavigate } from 'react-router-dom'

export const colummList = ({ menu, onSendMail = () => {}, onDelete = () => {}, onCancel = () => {} }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return [
    {
      title: t('โครงการ'),
      dataIndex: 'projectName',
      width: '200px',
      sorter: true,
      className: 'text-nowrap',
    },
    {
      title: t('ประเภทแบบประเมิน'),
      dataIndex: 'formTypeName',
      width: '200px',
      sorter: true,
      className: 'text-nowrap',
    },
    {
      title: t('ชื่อแบบประเมิน'),
      dataIndex: 'formName',
      width: '250px',
      sorter: true,
      className: 'text-nowrap',
    },
    {
      title: t('วันที่เริ่มประเมิน'),
      dataIndex: 'startDate',
      width: '100px',
      sorter: true,
      className: 'text-nowrap',
    },
    {
      title: t('วันที่สิ้นสุดประเมิน'),
      dataIndex: 'endDate',
      width: '100px',
      sorter: true,
      className: 'text-nowrap',
    },
    {
      title: t('สถานะการใช้งาน'),
      dataIndex: 'status',
      width: '100px',
      className: 'text-nowrap',
      align: 'center',
      sorter: true,
      render: (item) => {
        return <Status key={'status-form-1'} text={item === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}></Status>
      },
    },
    {
      title: '',
      dataIndex: 'operationStatusCode',
      width: '150px',
      className: 'text-nowrap',
      align: 'center',
      // sorter: true,
      render: (item, record) => {
        return (
          <>
            {item && (
              <ButtonNew
                type={
                  item === '10'
                    ? 'flagOutlinedInTableBlue'
                    : item === '50'
                    ? 'xInTableOrange'
                    : 'moreHorizontalInTableGray'
                }
                disabled={item === '10' ? false : true}
                roles={{ type: 'send', menu: menu }}
                onClick={() => item === '10' && onSendMail(record)}
              >
                {item === '10'
                  ? t('ส่งแบบประเมิน')
                  : item === '20'
                  ? t('กำลังดำเนินการ')
                  : item === '50'
                  ? t('ยกเลิก')
                  : t('ประเมินเสร็จสิ้น')}
              </ButtonNew>
            )}
          </>
        )
      },
    },
    {
      title: '',
      dataIndex: 'options',
      width: '100px',
      className: 'text-nowrap',
      render: (item, record) => {
        const icons = []
        icons.push({
          type: 'view',
          onClick: () => {
            navigate('/auditoperations/createassessmentform/createassessmentformdetail/view', {
              state: { refCode: record.formAssessId },
            })
          },
        })
        if (record.operationStatusCode === '10') {
          icons.push({
            type: 'edit',
            onClick: () => {
              navigate('/auditoperations/createassessmentform/createassessmentformdetail/edit', {
                state: { refCode: record.formAssessId },
              })
            },
          })
          icons.push({
            type: 'delete',
            onClick: () => {
              onDelete(record.formAssessId)
            },
          })
        }

        if (record.operationStatusCode === '20' && 1 === 2) {
          //ปิดปุ่ม cancel
          icons.push({
            type: 'cancel',
            onClick: () => {
              onCancel(record.formAssessId)
            },
          })
        }

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} size={14} icons={icons} />
          </div>
        )
      },
    },
  ]
}

export const colunmOrganizations = ({ menu, onDelete = () => {}, pageType }) => {
  const { t } = useTranslation()
  return [
    {
      title: t('ลำดับ'),
      dataIndex: 'numRow',
      // sorter: true,
      width: '10%',
      className: 'text-nowrap',
    },
    {
      title: t('รหัสหน่วยงาน'),
      dataIndex: 'organizationCode',
      // sorter: true,
      width: '20%',
      className: 'text-nowrap',
    },
    {
      title: t('หน่วยงาน'),
      dataIndex: 'organizationName',
      // sorter: true,
      width: '20%',
      className: 'text-nowrap',
    },
    {
      title: t('ระดับหน่วยงาน'),
      dataIndex: 'organizationLevelName',
      // sorter: true,
      width: '20%',
      className: 'text-nowrap',
    },
    {
      title: t('ผู้รับผิดชอบ'),
      dataIndex: 'personnelName',
      // sorter: true,
      width: '20%',
      className: 'text-nowrap',
    },
    {
      title: '',
      dataIndex: 'options',
      // sorter: true,
      width: '10%',
      className: 'text-nowrap',
      render: (item, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {pageType !== 'view' && (
              <ButtonGroup
                menu={menu}
                size={14}
                icons={[
                  {
                    type: 'delete',
                    onClick: () => {
                      onDelete({ key: record.key })
                    },
                  },
                ]}
              />
            )}
          </div>
        )
      },
    },
  ]
}

export const columnOrganizationfillter = () => {
  const { t } = useTranslation()
  return [
    {
      title: t('เลือก'),
      dataIndex: 'no',
      // sorter: true,
      width: '5%',
      className: 'text-nowrap',
    },
    {
      title: t('รหัสหน่วยงาน'),
      dataIndex: 'organizationCode',
      sorter: true,
      width: '15%',
      className: 'text-nowrap',
    },
    {
      title: t('หน่วยงาน'),
      dataIndex: 'organizationName',
      sorter: true,
      width: '30%',
      className: 'text-nowrap',
    },
    {
      title: t('ระดับหน่วยงาน'),
      dataIndex: 'organizationLevelName',
      sorter: true,
      width: '25%',
      className: 'text-nowrap',
    },
    { title: t('ผู้บริหารหน่วยงานรับตรวจ'), dataIndex: 'personnelName', width: '25%' },
  ]
}
