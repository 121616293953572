import React, { useEffect, useState } from 'react'
import { Column } from '@ant-design/plots'
import _ from 'lodash'

export const GraphColumnVertical = (props) => {
  const { data, colors, height } = props
  const [dataGraph, setDataGraph] = useState([])

  const [configs, setConfigs] = useState({
    data: [],
    isStack: true,
    autoFit: false,
    xField: 'level',
    yField: 'value',
    seriesField: 'type',
    color: Array.isArray(colors) ? colors : ['#0C6ABF', '#FF3333'],
    minColumnWidth: 20,
    maxColumnWidth: 50,
    height: height || 300,
    label: {
      position: 'middle',
      layout: [{ type: 'interval-adjust-position' }, { type: 'interval-hide-overlap' }, { type: 'adjust-color' }]
    }
  })

  useEffect(() => {
    setDataGraph(data)

    if (!_.isEmpty(data)) {
      setConfigs({
        ...configs
      })
    }
  }, [data])

  useEffect(() => {
    setConfigs({
      ...configs,
      data: dataGraph
    })
  }, [dataGraph])

  return !_.isEmpty(data) ? <Column {...configs} /> : null
}
