import React from 'react'
import { Row, Col } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import SatisfactionAssessmentList from './SatisfactionAssessmentList'
import { useTranslation } from 'react-i18next'

/**
 * @function SatisfactionAssessmentIndex
 * @description
 * Menu: SatisfactionAssessment
 * เมนู: ประเมินความพึงพอใจ
 * @returns Component
 */
const SatisfactionAssessmentIndex = ({ menu }) => {
  const { t } = useTranslation()

  const arrCrumb = [{ label: t('งานปฏิบัติการตรวจสอบ'), path: '#' }, { label: t('ประเมินความพึงพอใจ') }]

  const Responsive = {
    md: 24,
    lg: 24,
  }
  return (
    <React.Fragment>
      <Row>
        <Col {...Responsive}>
          <Breadcrumb data={arrCrumb} title={t('ประเมินความพึงพอใจ')} />
          <SatisfactionAssessmentList menu={menu} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default SatisfactionAssessmentIndex
