import { useLazyQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import SendEmailReportModal from '../components/SendEmailReportModal'
import { encryptInput } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayError, displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'

import { GET_LIST_PROJECT_STATUS_REPORT_PAGINATION, GET_EXPORT_PROJECT_STATUS_REPORT } from './graphql/Query'
import ExportModal from './Modal/ExportModal'
import ProjectStatusReportFilters from './ProjectStatusReportFilters'
import { Columns } from './utils/ProjectStatusReportListColumn'
import { useDecodeUser } from '../../../hooks/useDecodeUser'

const Responsive = {
  md: 24,
  lg: 24,
}

const ProjectStatusReportList = ({ menu }) => {
  const { t } = useTranslation()
  const [user] = useDecodeUser()

  const arrCrumb = [{ label: t('รายงาน'), path: '/reports/projectstatusreport' }, { label: t('รายงานสถานะของโครงการ') }]

  const [isPressFilter, setisPressFilter] = useState(false)

  const [dataRow, setdataRow] = useState([])
  const [filters, setfilters] = useState({})
  const [count, setcount] = useState(0)

  const [paginate, setPaginate] = useState({ page: 1, pageSize: 10 })
  const [sort, setsort] = useState([{ fieldSort: 'projectCode', sortType: 'ASC' }])

  useEffect(() => {
    if (sort && filters && isPressFilter) fncGetList()
  }, [sort, filters, isPressFilter])

  const [callList, { loading: loadingList, error: errorList, data: dataList }] = useLazyQuery(
    GET_LIST_PROJECT_STATUS_REPORT_PAGINATION,
  )

  useEffect(() => {
    if (dataList) {
      let { result, count } = dataList.getListProjectStatusReportPagination

      let dataTemp = result.map((item, key) => {
        let projectName = item.projectCode + ' : ' + displayText(item.projectNameTH, item.projectNameEN)
        let projectRefName = displayText(item.projectRefNameTH, item.projectRefNameEN)
        let personnelName = displayText(item.personnelNameTH, item.personnelNameEN)
        let organizationName = displayText(item.organizationNameTH, item.organizationNameEN)
        let projectStatusName = displayText(item.projectStatusNameTH, item.projectStatusNameEN)
        return {
          ...item,
          key: key + 1,
          projectName,
          projectRefName,
          personnelName,
          organizationName,
          projectStatusName,
          dateStart: dateDisplay(item.dateStart),
          dateEnd: dateDisplay(item.dateEnd),
        }
      })
      setdataRow(dataTemp)
      setcount(count)
    }
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: displayError(errorList),
      })
    }
  }, [dataList])

  const fncGetList = () => {
    const dataInput = {
      sort: sort,
      filters: { ...filters, personnelCode: user.pCode },
      pagination: {
        page: paginate.page,
        limit: paginate.pageSize,
      },
    }
    callList({
      variables: { input: encryptInput(dataInput) },
    })
  }

  const onGetFilter = (data) => {
    setisPressFilter(true)
    setfilters({ ...data })
  }

  //=========================== Modal ===========================
  const [modalVisible, setmodalVisible] = useState(false)
  const [actionPlanId, setactionPlanId] = useState()

  const fncOnpressExport = (data) => {
    setactionPlanId(data.actionPlanId)
    setmodalVisible(true)
  }

  //=========================== =========================== ====

  //=========================== สำหรับการส่งเมลรายงาน ===========================

  const [exportProjectStatusMail, dataExportProjectStatusMail] = useLazyQuery(GET_EXPORT_PROJECT_STATUS_REPORT)

  useEffect(() => {
    if (dataExportProjectStatusMail.data) {
      Alert({
        type: 'success',
        title: t('ส่งอีเมลสำเร็จ'),
        onOk() {},
      })
    }
    if (dataExportProjectStatusMail.error) {
      const err = dataExportProjectStatusMail.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [dataExportProjectStatusMail?.data])

  //=========================== Email Modal ===========================
  const [emailModalVisible, setemailModalVisible] = useState(false)
  const [mailValue, setMailValue] = useState()
  const [inputSendEmail, setInputSendEmail] = useState()

  useEffect(() => {
    if (inputSendEmail) {
      const dataInput = {
        actionPlanId: mailValue.refCode,
        lang: localStorage.getItem('lang'),
        fileType: inputSendEmail.fileType,
        emailData: inputSendEmail, // สำหรับการส่งเมลรายงาน
      }

      // Call API Export
      exportProjectStatusMail({
        variables: {
          input: encryptInput(dataInput),
        },
      })
    }
  }, [inputSendEmail])

  const fncOnpressEmailSetting = (data) => {
    setemailModalVisible(true)
    setMailValue({ reportName: 'รายงานสถานะของโครงการ', refCode: data.actionPlanId })
  }

  //=========================== =========================== ====

  return (
    <React.Fragment>
      <ExportModal
        actionPlanId={actionPlanId}
        visible={modalVisible}
        onModalClose={() => {
          setmodalVisible(false)
        }}
      />
      <BreadcrumbNew data={arrCrumb} title={t('รายงานสถานะของโครงการ')} />
      <ProjectStatusReportFilters menu={menu} getValue={onGetFilter} />
      <CardNew topic={t('รายงานสถานะของโครงการ')} icon="List">
        <Row>
          <Col {...Responsive}>
            <Datatable
              columns={Columns({ fncOnpressEmailSetting, fncOnpressExport, menu })}
              data={dataRow}
              total={count}
              searchCustom={true}
              pagination={true}
              pageSize={paginate.pageSize}
              page={paginate.page}
              isLoading={loadingList || dataExportProjectStatusMail.loading}
              // paginationCustom={true}
              onChangePagination={({ page, pageSize }) => {
                setPaginate({ page, pageSize })
              }}
              handleTableChange={(e) => {
                const reNewField = [
                  { projectName: 'projectCode' },
                  { projectRefName: 'projectRefCode' },
                  { dateStart: 'dateStart' },
                  { dateEnd: 'dateEnd' },
                  { organizationCode: 'organizationCode' },
                  { projectStatusCode: 'projectStatusCode' },
                ]

                setsort(handleSort(e?.sorter, reNewField))
              }}
            />
          </Col>
        </Row>
      </CardNew>
      <SendEmailReportModal
        menu={menu}
        open={emailModalVisible}
        close={(e) => {
          setemailModalVisible(e)
        }}
        fileType={{ pdf: false, word: false, excel: true, csv: true }}
        setInputSendEmail={(e) => setInputSendEmail(e)}
        mailValue={mailValue}
      />
    </React.Fragment>
  )
}

export default ProjectStatusReportList
