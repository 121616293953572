import { gql } from '@apollo/client'

export const GET_AUDIT_TEAM_PAGINATION = gql`
  query getAuditTeamsPagination($input: AuditTeamsPaginationInput!) {
    getAuditTeamsPagination(input: $input) {
      result {
        no
        auditTeamId
        auditTeamCode
        auditTeamNameTH
        auditTeamNameEN
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDIT_TEAM_BY_CODE = gql`
  query getAuditTeamByCode($auditTeamCode: String!) {
    getAuditTeamByCode(auditTeamCode: $auditTeamCode) {
      auditTeamId
      auditTeamCode
      auditTeamNameTH
      auditTeamNameEN
      isActive
      auditTeamPersonnels {
        personnelCode
        auditTeamRole
        personnelNameTH
        personnelNameEN
        organizationNameTH
        organizationNameEN
        inUsed
      }
    }
  }
`

export const GET_PERSONNELS_LIST = gql`
  query getPersonnelsList($input: FilterPersonnelInput!) {
    getPersonnelsList(input: $input) {
      personnelCode
      personnelNameTH
      personnelNameEN
      positionNameTH
      positionNameEN
      organizationNameTH
      organizationNameEN
    }
  }
`
