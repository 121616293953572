import React from 'react'
import { Form, Input, Button, Card, Row, Col, Checkbox } from 'antd'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { multipleRowCode } from './text'

const FormNew = () => {
  const onFinish = (values) => {
    console.log('Success:', values)
  }

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo)
  }
  return (
    <>
      <Row style={{ marginTop: 24 }}>
        <Col span={24}>
          <Card title="Form basic validation" extra={<a href="#">More</a>} style={{ width: '100%' }}>
            <Form onFinish={onFinish} onFinishFailed={onFinishFailed} autoComplete="off">
              <label htmlFor="username">username</label>
              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <label htmlFor="password">password</label>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>Remember me</Checkbox>
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <CodeBlock code={multipleRowCode} />
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FormNew
