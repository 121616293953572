import React, { useState, useEffect } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import { Status } from '../../../components/status/Status'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { ButtonNew } from '../../../components/button/Button'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { displayText, displayError } from '../../../utilitys/helper'
import { dateDisplay, timeDisplay } from '../../../utilitys/dateHelper'
import { handleSort } from '../../../utilitys/pagination'
import { encryptInput } from '../../../utilitys/crypto'
import { Columns } from './utils/TimeSheetColumns'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { GET_TIMESHEET_PAGINATION } from './graphql/Query'
import { DELETE_TIMESHET_BY_ID } from './graphql/Mutation'

const TimeSheetList = (props) => {
  const { menu, filters } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' },
  ])
  const [user] = useDecodeUser()
  const [dataLists, setDataLists] = useState([])

  /* ---------------- API ----------- */
  const [getDataPaginationFn, dataPagination] = useLazyQuery(GET_TIMESHEET_PAGINATION)
  const [deleteDataFn, dataDelete] = useMutation(DELETE_TIMESHET_BY_ID)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    getDataPaginationFn({
      variables: {
        input: encryptInput({
          filters: { ...filters, personnelCode: user?.pCode },
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort, user])

  useEffect(() => {
    if (dataPagination.data) {
      let response = dataPagination?.data?.getTimeSheetPagination
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            key: data.no,
            jobGroupName: displayText(data.jobGroupNameTH, data.jobGroupNameEN) || '-',
            // jobTypeName:
            //   data.jobGroupCode === '10'
            //     ? displayText(data.projectTypeNameTH, data.projectTypeNameEN) || '-'
            //     : displayText(data.jobTypeNameTH, data.jobTypeNameEN) || '-',
            jobAndProjectName: displayText(data.jobAndProjectNameTH, data.jobAndProjectNameEN) || '-',
            typeName: displayText(data.typeNameTH, data.typeNameEN) || '-',
            jobDetail: displayText(data.jobDetailTH, data.jobDetailEN) || '-',
            dateStart: dateDisplay(data.dateStart) || '-',
            dateEnd: dateDisplay(data.dateEnd) || '-',
            timeStart: timeDisplay(data.timeStart) || '-',
            timeEnd: timeDisplay(data.timeEnd) || '-',
            jobStatusName: <Status text={data.jobStatusNameTH} />,
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => navigate(`form`, { state: { type_action: 'view', refCode: data.timeSheetId } }),
                    },
                    {
                      type: 'edit',
                      onClick: () => navigate(`form`, { state: { type_action: 'edit', refCode: data.timeSheetId } }),
                    },
                    {
                      type: 'delete',
                      onClick: () => showConfirmDelete(data.timeSheetId),
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }

    if (dataPagination.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataPagination.error)),
        onOk() {},
      })
    }
  }, [dataPagination.data])

  useEffect(() => {
    if (dataDelete.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          dataPagination.refetch()
        },
      })
    }

    if (dataDelete.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(dataDelete.error)),
        onOk() {},
      })
    }
  }, [dataDelete.data])

  /* ---------------- ACTION ----------- */
  const showConfirmDelete = (timeSheetId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteDataFn({
          variables: {
            timeSheetId: encryptInput(timeSheetId),
          },
        })
      },
      onCancel() {},
    })
  }

  return (
    <>
      {(dataPagination.loading || dataDelete.loading) && <SpinnersNew />}
      <Datatable
        paginationCustom={true}
        columns={Columns}
        data={dataLists}
        total={count}
        pageSize={limit}
        page={page}
        showSizeChanger={true}
        handleTableChange={(e) => {
          const reNewField = [
            { jobAndProjectName: displayText('jobAndProjectNameTH', 'jobAndProjectNameEN') },
            { jobGroupName: displayText('jobGroupNameTH', 'jobGroupNameEN') },
            { typeName: displayText('typeNameTH', 'typeNameEN') },
            { jobDetail: displayText('jobDetailTH', 'jobDetailEN') },
            { jobStatusName: displayText('jobStatusNameTH', 'jobStatusNameEN') },
            { dateStart: 'dateStart' },
            { dateEnd: 'dateEnd' },
            { timeStart: 'timeStart' },
            { timeEnd: 'timeEnd' },
          ]
          setSort(handleSort(e?.sorter, reNewField))
        }}
        onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
        searchCustom={true}
        btnAdd={
          <>
            <ButtonNew
              type="plusTableBorderPrimary"
              roles={{ type: 'add', menu: menu }}
              onClick={() =>
                navigate('/auditoperations/timesheet/form', {
                  state: {
                    type_action: 'add',
                  },
                })
              }
            >
              {t('เพิ่มการปฏิบัติงานรายวัน')}
            </ButtonNew>
          </>
        }
      />
    </>
  )
}

export default TimeSheetList
