import { gql } from '@apollo/client'
export const RISKMODELSSAVE = gql`
  mutation riskModelsSave($inputData: RiskModelsSaveInput!) {
    riskModelsSave(inputData: $inputData) {
      riskModelCode
    }
  }
`

export const RISKMODELSDELETE = gql`
  mutation riskModelsDelete($input: RiskModelsInput!) {
    riskModelsDelete(input: $input)
  }
`
