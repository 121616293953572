import { gql } from '@apollo/client'

export const GET_LOGFILE_DATA_LIST = gql`
  query GetLogFileEditDataList($input: HistoryRevisionsWithinInputPagenation!) {
    getLogFileEditDataList(input: $input) {
      result {
        logfileEditId
        userId
        menuCode
        refCode
        username
        menuNameTH
        menuNameEN
        email
        changeType
        changeDetail
        createdAt
        createdBy
      }
      count
      page
      limit
    }
  }
`

export const GET_LOGFILE_DATA_DETAIL = gql`
  query GetLogFileEditDataDetail($input: HistoryRevisionsWithinInputDetail!) {
    getLogFileEditDataDetail(input: $input) {
      result {
        jsonDataAfter
        jsonDataBefore
        changeType
      }
    }
  }
`
