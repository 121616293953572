import React, { useState, useEffect, useLayoutEffect } from 'react'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { breadcrumbList } from './utils/breadcrumbData.js'
import { useTranslation } from 'react-i18next'
import { getSegmentUrl, displayError } from '../../../utilitys/helper'
import { CardNew } from '../../../components/card/Card'
import { Row, Col, Form, Space } from 'antd'
import SelectYear from '../../../components/inputfromapi/selectyear/SelectYear'
import { InputNew } from '../../../components/input/Input'
import { RadioGroup, Radio } from '../../../components/radio/Radio'
import { LabelNew as Label } from '../../../components/label/Label'
import { Datatable } from '../../../components/datatable/Datatable'
import * as _ from 'lodash'
import { Columns } from './utils/columnData'
import { ButtonNew } from '../../../components/button/Button.jsx'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { DataTabelCalendarStyle } from './utils/tableCalendarStyle'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GETMASTERCALENDARRAWONE, GETMASTERCALENDARDETAILSBYMCDCODE } from './graphql/Query'
import { MASTERCALENDARSSAVE } from './graphql/Mutation'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput } from '../../../utilitys/crypto'
import { regexEngNoSpace } from '../../../utilitys/helper'
// import { dateDisplay } from '../../../utilitys/dateHelper'

export default function CalendarSetFrom(props) {
  const { menu } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const { state } = useLocation()
  const navigate = useNavigate()
  const formName = 'calendarset_form'
  const pageType = getSegmentUrl(4)
  const [editable, seteditable] = useState(true)
  const [dataLists, setDataLists] = useState([])

  if (!state && pageType !== 'create') return <Navigate to={'/administrator/managecalendar'} />
  // const [mtCalendars, setCalendars] = useState({})

  const [getMasterCalendarRawOnefn, getMasterCalendarRawOne] = useLazyQuery(GETMASTERCALENDARRAWONE)
  const [getMasterCalendarDetailsByMCDCodefn, getMasterCalendarDetailsByMCDCode] = useLazyQuery(
    GETMASTERCALENDARDETAILSBYMCDCODE,
  )
  const [masterCalendarsSavefn, { loading: loadingSaved, error, data: dataSaved }] = useMutation(MASTERCALENDARSSAVE)

  const fncDelete = (index) => {
    const fields = form.getFieldsValue()
    let { calendarDetails } = fields
    let filterTable = [..._.filter(calendarDetails, (e, key) => key !== index)]
    if (dataLists.length > 1) {
      let newTable = filterTable.map((item, key) => {
        return { ...item, key }
      })
      setDataLists(newTable)
      fields.calendarDetails = newTable
    } else {
      setDataLists([])
      fields.calendarDetails = []
    }
    form.setFieldsValue(fields)
  }

  const handlePressAdd = () => {
    let newData = {
      key: dataLists.length,
      dateNameTH: null,
      dateNameEN: null,
      dateAt: null,
      dateType: null,
    }
    const fields = form.getFieldsValue()
    const result = Object.values(fields)

    result.push(newData)
    setDataLists([...dataLists, newData])
    form.setFieldsValue(result)
  }

  const onFinishForm = () => {
    const fields = form.getFieldsValue()
    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกข้อมูลปฏิทิน'),
      onOk: async () => {
        await masterCalendarsSavefn({
          variables: {
            inputData: encryptInput({
              mastercalendars: {
                calendarId: fields.calendarId,
                calendarCode: fields.calendarCode,
                calendarNameTH: fields.calendarNameTH,
                calendarNameEN: fields.calendarNameEN,
                calendarYear: fields.calendarYear,
                isActive: fields.isActive,
              },
              masterCalendarDetails: fields.calendarDetails ? fields.calendarDetails : [],
              actions: pageType,
            }),
          },
        })
      },
      onCancel() {
        return null
      },
    })
  }

  useEffect(() => {
    if (pageType === 'create') {
      seteditable(true)
    } else if (pageType === 'edit') {
      seteditable(true)
      getMasterCalendarRawOnefn({
        variables: {
          inputData: encryptInput({
            calendarCode: state.refCode,
          }),
        },
      })
      getMasterCalendarDetailsByMCDCodefn({
        variables: {
          inputData: encryptInput({
            calendarCode: state.refCode,
          }),
        },
      })
    } else if (pageType === 'copy') {
      seteditable(true)
      getMasterCalendarRawOnefn({
        variables: {
          inputData: encryptInput({
            calendarCode: state.refCode,
          }),
        },
      })
      getMasterCalendarDetailsByMCDCodefn({
        variables: {
          inputData: encryptInput({
            calendarCode: state.refCode,
          }),
        },
      })
    } else if (pageType === 'view') {
      seteditable(false)
      getMasterCalendarRawOnefn({
        variables: {
          inputData: encryptInput({
            calendarCode: state.refCode,
          }),
        },
      })
      getMasterCalendarDetailsByMCDCodefn({
        variables: {
          inputData: encryptInput({
            calendarCode: state.refCode,
          }),
        },
      })
    }
  }, [pageType])

  useEffect(() => {
    if (getMasterCalendarRawOne.data) {
      const res = getMasterCalendarRawOne.data.getMasterCalendarRawOne
      // setCalendars({
      //   calendarId: res.calendarId,
      //   calendarCode: res.calendarCode,
      //   calenderYear: res.calenderYear,
      //   calendarNameTH: res.calendarNameTH,
      //   calendarNameEN: res.calendarNameEN,
      //   isActive: res.isActive,
      // })
      form.setFieldsValue({
        calendarId: res.calendarId,
        calendarCode: pageType === 'copy' ? undefined : res.calendarCode,
        calendarYear: res.calendarYear,
        calendarNameTH: res.calendarNameTH,
        calendarNameEN: res.calendarNameEN,
        isActive: res.isActive,
      })
    }
    if (getMasterCalendarRawOne.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getMasterCalendarRawOne.error)),
        onOk() {},
      })
    }
  }, [getMasterCalendarRawOne.data])

  useLayoutEffect(() => {
    if (getMasterCalendarDetailsByMCDCode.data) {
      const mapDataList = getMasterCalendarDetailsByMCDCode.data.getMasterCalendarDetailsByMCDCode.map((items, key) => {
        return {
          ...items,
          key,
          dateNameTH: items.dateNameTH,
          dateNameEN: items.dateNameEN,
          dateAt: pageType === 'copy' ? undefined : items.dateAt,
          dateType: items.dateType,
        }
      })
      const subCalendar = {
        calendarDetails: mapDataList,
      }
      form.setFieldsValue(subCalendar)
      setDataLists(mapDataList)
    }
    if (getMasterCalendarDetailsByMCDCode.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getMasterCalendarDetailsByMCDCode.error)),
        onOk() {},
      })
    }
  }, [getMasterCalendarDetailsByMCDCode.data])

  useEffect(() => {
    if (dataSaved) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/administrator/managecalendar/form/edit', {
            state: {
              refCode: dataSaved.masterCalendarsSave.calendarCode,
            },
          })
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [dataSaved])

  return (
    <>
      {(getMasterCalendarRawOne.loading || getMasterCalendarDetailsByMCDCode.loading) && <SpinnersNew />}
      {loadingSaved && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('จัดการข้อมูลปฏิทิน')} />
      <CardNew
        topic={
          pageType === 'create'
            ? t('เพิ่มข้อมูลวัน')
            : pageType === 'edit'
            ? t('แก้ไขข้อมูลวัน')
            : pageType === 'copy'
            ? t('เพิ่มข้อมูลวัน')
            : t('ข้อมูลวัน')
        }
        icon="Plus"
        toggledrop={'false'}
      >
        <Form
          form={form}
          onFinish={onFinishForm}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 1,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 8,
            lg: 8,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item name="calendarId" label={<Label type="tab-header-inactive">{t('ลำดับ')}</Label>} hidden={true}>
            <InputNew formname={formName} />
          </Form.Item>
          <Form.Item
            name="calendarCode"
            label={<Label type="tab-header-inactive">{t('รหัสปฏิทิน')}</Label>}
            placeholder={t('รหัสปฏิทิน')}
            rules={[
              { required: true, message: t('กรุณาระบุรหัสปฏิทิน') },
              {
                pattern: regexEngNoSpace,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, 0-9`),
              },
            ]}
          >
            <InputNew disabled={pageType === 'edit' ? true : !editable} formname={formName} maxLength={10} />
          </Form.Item>
          <Form.Item
            name="calendarYear"
            label={<Label type="tab-header-inactive">{t('ประจำปี')}</Label>}
            placeholder={t('ระบุประจำปี')}
            rules={[{ required: true, message: t('กรุณาระบุประจำปี') }]}
          >
            <SelectYear
              disabled={!editable}
              formname={formName}
              handleChange={(e) => {
                form.setFieldsValue({ calendarYear: e.value })
              }}
            />
          </Form.Item>
          <Form.Item
            name="calendarNameTH"
            label={<Label type="tab-header-inactive">{t('ชื่อวันภาษาไทย')}</Label>}
            placeholder={t('ระบุชื่อวันภาษาไทย')}
            rules={[{ required: true, message: t('กรุณาชื่อวันภาษาไทย') }]}
          >
            <InputNew disabled={!editable} placeholder={t('')} formname={formName} maxLength={100} />
          </Form.Item>
          <Form.Item
            name="calendarNameEN"
            label={<Label type="tab-header-inactive">{t('ชื่อวันภาษาอังกฤษ')}</Label>}
            placeholder={t('ระบุชื่อวันภาษาอังกฤษ')}
          >
            <InputNew disabled={!editable} placeholder={t('')} formname={formName} maxLength={100} />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={1}>
            <RadioGroup disabled={!editable}>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>

                <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
          <Label type="body-header">{t('รายการปฏิทินประจำปี')}</Label>
          <DataTabelCalendarStyle>
            {/* <div className="ant-table-mac-kub"> */}
            <Datatable
              // enableScroll={{ x: true, y: false }}
              style={{ overFlow: 'unset' }}
              columns={Columns({ editable, fncDelete, menu, formname: formName, form })}
              data={dataLists}
              pagination={false}
            />
            {/* </div> */}
          </DataTabelCalendarStyle>
          {editable && (
            <ButtonNew type="addMultiple" onClick={handlePressAdd}>
              {t('เพิ่มข้อมูล')}
            </ButtonNew>
          )}
          <Row gutter={[12, 12]} justify="center" style={{ marginBottom: 20, marginTop: 20 }}>
            {editable && (
              <Col>
                <ButtonNew type="primary" htmlType="submit" roles={{ type: 'add', menu: menu }}>
                  {t('บันทึก')}
                </ButtonNew>
              </Col>
            )}
            <Col>
              <ButtonNew onClick={() => navigate('/administrator/managecalendar/')} type="back">
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </CardNew>
    </>
  )
}
