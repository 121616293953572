import React, { useEffect, useState } from 'react'
import { Column } from '@ant-design/plots'
import _ from 'lodash'
import { numberFormat } from '../../../../../../utilitys/helper'

export const GraphColumn1 = (props) => {
  const { data } = props
  const [dataGraph, setDataGraph] = useState([])

  const colorMap = {
    โครงการ: '#0C6ABF',
    แบบประเมินความเสี่ยง: '#EB895F',
    'Audit Project': '#0C6ABF',
    'Risk Form': '#EB895F',
  }

  const [configs, setConfigs] = useState({
    data: [],
    isStack: true,
    xField: 'level',
    yField: 'value',
    seriesField: 'type',
    color: ['#EB895F', '#0C6ABF'],
    minColumnWidth: 60,
    maxColumnWidth: 130,
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
      content: (item) => {
        return item.value
      },
    },
    tooltip: {
      formatter: (d) => {
        return { name: d.type, value: numberFormat(d.value) }
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'bottom',
    },
  })

  useEffect(() => {
    setDataGraph(data)

    if (!_.isEmpty(data)) {
      setConfigs({
        ...configs,
        color: (data) => colorMap[data.type],
      })
    }
  }, [data])

  useEffect(() => {
    setConfigs({
      ...configs,
      data: dataGraph,
    })
  }, [dataGraph])

  return !_.isEmpty(data) ? <Column {...configs} /> : null
}
