import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Row, Col } from 'antd'
import * as _ from 'lodash'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonNew } from '../../../components/button/Button'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { Alert } from '../../../components/alert/Alert'
import AcknowledgeIssueListFilter from './components/filter/AcknowledgeIssueListFilter'
import { GET_ACKNOWLEDGE_ISSUE_PAGINATION } from './graphql/Query'
import { handleSort } from '../../../utilitys/pagination'
import { displayError } from '../../../utilitys/helper'
import { dateDisplay } from '../../../utilitys/dateHelper'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}

const AcknowledgeIssueList = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { menu } = props

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/acknowledgeissue' },
    { label: t('รับทราบประเด็น') },
  ]

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [user] = useDecodeUser()
  const [sort, setSort] = useState([])

  const [cellPagination, { data: dataPagination, loading: loadingPagination, error: errorPagination }] = useLazyQuery(
    GET_ACKNOWLEDGE_ISSUE_PAGINATION,
  )

  useEffect(() => {
    if (user?.orgCode) {
      cellPagination({
        variables: {
          input: encryptInput({
            organizationCode: user.orgCode,
            filters: {},
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
    }
  }, [page, limit, sort, user?.orgCode])

  const Column = [
    {
      title: t('โครงการ'),
      dataIndex: 'projectName',
      sorter: true,
      width: '15%',
      className: 'text-nowrap',
    },
    {
      title: t('แหล่งที่มาของโครงการ'),
      dataIndex: 'projectRefName',
      sorter: true,
      width: '15%',
      className: 'text-nowrap',
    },
    {
      title: t('วันที่เริ่มต้น'),
      dataIndex: 'dateStart',
      sorter: true,
      width: '10%',
      className: 'text-nowrap',
    },
    {
      title: t('วันที่สิ้นสุด'),
      dataIndex: 'dateEnd',
      sorter: true,
      width: '10%',
      className: 'text-nowrap',
    },
    {
      title: t('หน่วยงานที่รับผิดชอบ'),
      dataIndex: 'organizationName',
      sorter: true,
      width: '15%',
      className: 'text-nowrap',
    },
    {
      title: t('ผู้รับผิดชอบ'),
      dataIndex: 'fullName',
      sorter: true,
      width: '15%',
      className: 'text-nowrap',
    },
    {
      title: '',
      dataIndex: 'button',
      width: '10%',
      className: 'text-nowrap',
      render: (item) => {
        return item.status === 0 ? (
          <>
            <ButtonNew
              type="flagOutlinedInTableBlue"
              roles={{ type: 'add', menu: menu }}
              onClick={() => {
                navigate('/auditoperations/acknowledgeissue/details', {
                  state: { type_action: 'add', refCode: item.actionPlanId },
                })
              }}
            >
              {t('รับทราบประเด็น')}
            </ButtonNew>
          </>
        ) : (
          <>
            <ButtonNew
              type="moreHorizontalInTableOrange"
              roles={{ type: 'edit', menu: menu }}
              onClick={() => {
                navigate('/auditoperations/acknowledgeissue/details', {
                  state: { type_action: 'add', refCode: item.actionPlanId },
                })
              }}
            >
              {t('รับทราบประเด็นแล้ว')}
            </ButtonNew>
          </>
        )
      },
    },
  ]

  useEffect(() => {
    if (dataPagination) {
      let response = dataPagination.AcknowledgeIssuePaginationGet
      setCount(response.count)
      setDataLists(
        _.map(response.result, (item, key) => {
          return {
            key: key + 1,
            projectName: localStorage.getItem('lang') === 'th' ? item.projectNameTH : item.projectNameEN,
            projectRefName: localStorage.getItem('lang') === 'th' ? item.projectRefNameTH : item.projectRefNameEN,
            dateStart: dateDisplay(item.dateStart),
            dateEnd: dateDisplay(item.dateEnd),
            organizationName: localStorage.getItem('lang') === 'th' ? item.organizationNameTH : item.organizationNameEN,
            fullName: localStorage.getItem('lang') === 'th' ? item.fullNameTH : item.fullNameEN,
            button: {
              status: item.status,
              actionPlanId: item.actionPlanId,
            },
          }
        }),
      )
    }
    if (errorPagination) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorPagination)),
        onOk() {},
      })
    }
  }, [dataPagination])

  return (
    <>
      {loadingPagination && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('รับทราบประเด็น')} />
      <AcknowledgeIssueListFilter
        menu={menu}
        getValue={(e) => {
          cellPagination({
            variables: {
              input: encryptInput({
                organizationCode: user.orgCode,
                filters: {
                  projectRefCode: e.projectRefCode,
                  projectName: e.projectName,
                  organizationCode: e.organizationCode,
                  dateStart: e.dateStart ? e.dateStart : undefined,
                  dateEnd: e.dateEnd ? e.dateEnd : undefined,
                  fullName: e.fullName,
                },
                pagination: { limit, page },
                sort: [],
              }),
            },
          })
        }}
      />
      <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="List" toggledrop={'false'}>
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            <Datatable
              columns={Column}
              data={[...dataLists]}
              total={count}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              paginationCustom={true}
              searchCustom={true}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => {
                const lang = localStorage.getItem('lang')
                const reNewField = [
                  {
                    projectName: lang === 'th' ? 'projectNameTH' : 'projectNameEN',
                  },
                  {
                    projectRefName: lang === 'th' ? 'projectRefNameTH' : 'projectRefNameEN',
                  },
                  {
                    organizationName: lang === 'th' ? 'organizationNameTH' : 'organizationNameEN',
                  },
                  {
                    fullName: lang === 'th' ? 'fullNameTH' : 'fullNameEN',
                  },
                ]
                setSort(handleSort(e?.sorter, reNewField))
              }}
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default AcknowledgeIssueList
