import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { GET_RAC_MATRIX_APPROVE_PAGINATION } from './graphql/Query'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Status } from '../../../components/status/Status'
import { racMatrixApproveListCrumb } from './utils/constants'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import RacMatrixApproveFilter from './components/filter/RacMatrixApproveFilter'

const RacMatrixApproveList = (props) => {
  const wrapper = { sm: 24, md: 24, lg: 24, xl: 24 }
  const { menu } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const [user] = useDecodeUser()

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState([])

  const [getRacMatrixApprovePaginationFn, getRacMatrixApprovePagination] = useLazyQuery(
    GET_RAC_MATRIX_APPROVE_PAGINATION,
  )

  useEffect(() => {
    if (user?.pCode) {
      const pCode = { personnelCode: user?.pCode }

      getRacMatrixApprovePaginationFn({
        variables: {
          input: encryptInput({ filters: { ...filters, ...pCode }, pagination: { limit, page }, sort }),
        },
      })
    }
  }, [page, limit, filters, sort, user?.pCode])

  useEffect(() => {
    if (getRacMatrixApprovePagination?.data) {
      let response = getRacMatrixApprovePagination?.data?.getRacMaTrixApprovePagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            ...data,
            key: data.rcmId,
            projectName: displayText(
              `${data.projectCode}: ${data.projectNameTH}`,
              `${data.projectCode}: ${data.projectNameEN}`,
            ),
            projectRefName: displayText(data.projectRefNameTH, data.projectRefNameEN),
            dateEnd: dateDisplay(data.dateEnd),
            dateStart: dateDisplay(data.dateStart),
            organizationName: displayText(data.organizationNameTH, data.organizationNameEN),
            personnelName: displayText(data.personnelNameTH, data.personnelNameEN),
            planStatusCode: data.planStatusCode,
            planStatusBtn: data.planStatusCode,
          }
        }),
      )
    }
  }, [getRacMatrixApprovePagination?.data])

  const onFilter = (data) => {
    Promise.all([setPage(1), setLimit(10), setFilters(data)])
  }

  const columns = [
    { title: t('โครงการ'), dataIndex: 'projectName', width: '400px', align: 'left', fixed: 'left' },
    { title: t('แหล่งที่มาของโครงการ'), dataIndex: 'projectRefName', width: '250px' },
    { title: t('วันที่เริ่มต้น'), dataIndex: 'dateStart', width: '150px', align: 'center' },
    { title: t('วันที่สิ้นสุด'), dataIndex: 'dateEnd', width: '150px', align: 'center' },
    { title: t('หน่วยงานที่รับผิดชอบ'), dataIndex: 'organizationName', width: '250px' },
    { title: t('ผู้รับผิดชอบ'), dataIndex: 'personnelName', width: '200px' },
    {
      title: t('สถานะพิจารณา'),
      dataIndex: 'planStatusCode',
      width: '150px',
      render: (d) =>
        d && <Status text={d === '10' ? 'รอพิจารณา' : d === '20' ? 'อยู่ระหว่างพิจารณา' : 'พิจารณาเสร็จสิ้น'} />,
    },
    {
      title: '',
      dataIndex: 'planStatusBtn',
      width: '180px',
      align: 'center',
      render: (d, record) => {
        switch (d) {
          case '10':
            return (
              <ButtonNew
                type="flagOutlinedInTableBlue"
                // roles={{ type: 'edit', menu: menu }}
                roles={{ type: 'approve', menu: menu }}
                onClick={() => navigate(`tab/general`, { state: { typeAction: 'edit', rcmId: record?.rcmId } })}
              >
                {t('พิจารณา')}
              </ButtonNew>
            )
          case '20':
            return (
              <ButtonNew
                type="moreHorizontalInTableOrange"
                roles={{ type: 'approve', menu: menu }}
                onClick={() => navigate(`tab/general`, { state: { typeAction: 'edit', rcmId: record?.rcmId } })}
              >
                {t('กำลังพิจารณา')}
              </ButtonNew>
            )
          case '30':
            return (
              <ButtonNew
                type="moreHorizontalInTableGray"
                roles={{ type: 'edit', menu: menu }}
                onClick={() => navigate(`tab/general`, { state: { typeAction: 'view', rcmId: record?.rcmId } })}
              >
                {t('พิจารณาเสร็จสิ้น')}
              </ButtonNew>
            )
          default:
            return null
        }
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '50px',
      align: 'right',
      render: (d, record) => (
        <ButtonGroup
          menu={menu}
          size={18}
          icons={[
            {
              type: 'view',
              onClick: () => navigate(`tab/general`, { state: { typeAction: 'view', rcmId: record?.rcmId } }),
            },
          ]}
        />
      ),
    },
  ]

  return (
    <>
      {getRacMatrixApprovePagination?.loading && <SpinnersNew />}
      <Col {...wrapper}>
        <Breadcrumb data={racMatrixApproveListCrumb} title={t('อนุมัติความเสี่ยงและการควบคุม')} />
        <RacMatrixApproveFilter onFilter={onFilter} menu={menu} />
        <div style={{ marginBottom: '2rem' }}>
          <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col {...wrapper}>
                <Datatable
                  columns={columns}
                  data={[...dataLists]}
                  total={count}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  pageSize={limit}
                  page={page}
                  searchCustom={true}
                  handleTableChange={(e) => {
                    const reNewField = [
                      { projectName: displayText('projectNameTH', 'projectNameEN') },
                      { projectRefName: displayText('projectRefNameTH', 'projectRefNameEH') },
                      { organizationName: displayText('organizationNameTH', 'organizationNameEN') },
                      { personnelName: displayText('firstNameTH', 'firstNameEN') },
                    ]

                    setSort(handleSort(e?.sorter, reNewField))
                  }}
                />
              </Col>
            </Row>
          </CardNew>
        </div>
      </Col>
    </>
  )
}

RacMatrixApproveList.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default RacMatrixApproveList
