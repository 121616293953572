import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Col, Space, Table } from 'antd'
import { useLocation, useNavigate /*, Navigate */ } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLanguage } from '../../../hooks/useLanguage'
import * as _ from 'lodash'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { ButtonNew } from '../../../components/button/Button'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { InputNew } from '../../../components/input/Input'
import { Select } from '../../../components/select/Select'
import SelectYear from '../../../components/inputfromapi/selectyear/SelectYear'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectRiskModel from '../../../components/inputfromapi/selectriskmodel/SelectRiskModel'
import { Radio, RadioGroup } from '../../../components/radio/Radio'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { LabelNew } from '../../../components/label/Label'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { displayError } from '../../../utilitys/helper'
import { Alert } from '../../../components/alert/Alert'
import CheckerOrganizationModal from '../../components/modalformapi/modalCheckerOrganization/CheckerOrganizationModal'
import { GET_RISK_FORM_BY_ID } from './graphql/Query'
import { CREATE_RISK_FORM } from './graphql/Mutation'
const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}
const Riskformfirststep = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { orgCode } = decryptData(localStorage.getItem('user'))
  const propsType = useLocation()
  const { riskFormCode, type_action } = propsType.state
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [isLang] = useLanguage()
  const formName = 'risk_form'

  const [dataLists, setDataLists] = useState([])
  const [modal, setModal] = useState(false)
  const [riskForm_, setRiskForm_] = useState({})
  const [errorOrganizationList, setErrorOrganizationList] = useState(false)
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)

  const [createRiskForm, { data: createRiskFormData, loading: createRiskFormLoading, error: createRiskFormError }] =
    useMutation(CREATE_RISK_FORM)

  const [riskFormFn, riskForm] = useLazyQuery(GET_RISK_FORM_BY_ID)

  useEffect(() => {
    if (type_action === 'duplicate') {
      riskFormFn({
        variables: { input: encryptInput({ riskFormCode: riskFormCode }) },
      })
    }
    if (type_action !== 'duplicate') {
      form.setFieldsValue({
        oganizationAudit: orgCode,
      })
    }
  }, [])

  useEffect(() => {
    if (createRiskFormData) {
      const res = createRiskFormData.createRiskForm
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          navigate(`/riskassessment/riskform/createsecondstep`, {
            state: {
              riskFormId: res.riskFormId,
              riskFormCode: res.riskFormCode,
              type_action: 'update',
            },
          })
        },
      })
    }
    if (createRiskFormError) {
      const error = createRiskFormError
      Alert({
        type: 'error',
        title: t(displayError(error)),
        onOk() {},
      })
    }
  }, [createRiskFormData])

  useEffect(() => {
    if (riskForm.data) {
      setRiskForm_({ ...riskForm?.data?.getRiskById })
      const l = riskForm?.data?.getRiskById.riskFormOrganizationList
      const l_ = l.map((item, idx) => {
        return {
          key: idx + 1,
          organizationCode: item.organizationCode,
          organizationName: isLang === 'th' ? item.organizationNameTH : item.organizationNameEN,
          organizationNameTH: item.organizationNameTH,
          organizationNameEN: item.organizationNameEN,
          organizationLevelName: isLang === 'th' ? item.organizationLevelNameTH : item.organizationLevelNameEN,
          personnelCode: item.personnelCode,
          personnelName: isLang === 'th' ? item.personnelNameTH : item.personnelNameEN,
          deleteId: item.organizationCode,
        }
      })
      setDataLists([...l_])
    }
    if (riskForm.error) {
      const error = riskForm.error
      Alert({
        type: 'error',
        title: t(displayError(error)),
        onOk() {},
      })
    }
  }, [riskForm.data])

  useEffect(() => {
    if (type_action === 'duplicate') {
      form.setFieldsValue({
        Year: riskForm_.Year,
        riskFormTH: riskForm_.riskFormTH,
        riskFormEN: riskForm_.riskFormEN,
        riskModelCode: riskForm_.riskModelCode,
        riskFormType: riskForm_.riskFormType,
        oganizationAudit: riskForm_.oganizationAudit,
        dateStart: riskForm_.dateStart,
        dateEnd: riskForm_.dateEnd,
        isActive: riskForm_.isActive,
      })
    }
  }, [riskForm_])

  useEffect(() => {
    if (dataLists.length) setErrorOrganizationList(false)
    form.setFieldsValue({
      organizationList: JSON.stringify(dataLists),
    })
  }, [dataLists])

  // onFinish
  const onFinish = async (values) => {
    if (JSON.parse(values.organizationList).length === 'organizationList') setErrorOrganizationList(true)
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk: async () => {
        await createRiskForm({
          variables: {
            input: encryptInput({
              riskFormCode: values?.riskFormCode,
              riskFormTH: values?.riskFormTH,
              riskFormEN: values?.riskFormEN || values?.riskFormTH,
              Year: values?.Year,
              riskModelCode: values?.riskModelCode || '',
              riskFormType: values?.riskFormType,
              oganizationAudit: values?.oganizationAudit,
              organizationList: values?.organizationList,
              dateStart: values?.dateStart,
              dateEnd: values?.dateEnd,
              operationStatusCode: '10',
              isActive: values?.isActive,
            }),
          },
        })
      },
      onCancel() {
        return null
      },
    })
  }
  // onFinishFailed
  const onFinishFailed = ({ values }) => {
    // set validate organizationList
    const _organizationList = JSON.parse(values?.organizationList)
    if (!_organizationList.length) setErrorOrganizationList(true)
  }

  function setOrganizationList(v) {
    if (_.isEmpty(dataLists)) return setDataLists([...v])
    // check หน่วยงานซ้ำ
    setDataLists([...mergeArrayObjects(dataLists, v)])
  }

  function mergeArrayObjects(arr1, arr2) {
    const arr_ = [...arr1, ...arr2]
    return _.uniqBy(arr_, (i) => i.organizationCode)
  }

  const Columns = [
    {
      title: t('ลำดับ'),
      dataIndex: 'key',
      width: '10%',
      render: (row, x, index) => index + 1,
    },
    {
      title: t('รหัสหน่วยงาน'),
      dataIndex: 'organizationCode',
    },
    {
      title: t('หน่วยงาน'),
      dataIndex: 'organizationName',
    },
    {
      title: t('ระดับหน่วยงาน'),
      dataIndex: 'organizationLevelName',
    },
    {
      title: t('ผู้รับผิดชอบ'),
      dataIndex: 'personnelName',
    },
    {
      title: '',
      dataIndex: 'deleteId',
      width: '20%',
      render: (row) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup
              menu={menu}
              icons={[
                {
                  type: 'delete',
                  onClick: () => {
                    if (dataLists.length > 1) return setDataLists([..._.filter(dataLists, (e) => e.deleteId !== row)])
                    if (dataLists.length === 1) {
                      setErrorOrganizationList(true)
                      setTimeout(() => setErrorOrganizationList(false), 4000)
                    }
                  },
                },
              ]}
            />
          </div>
        )
      },
    },
  ]

  const breadcrumbList = [
    { label: t('งานประเมินความเสี่ยง'), path: '/riskassessment/' },
    { label: t('แบบประเมินความเสี่ยง'), path: '/riskassessment/riskform/' },
    { label: t('เพิ่มแบบประเมินความเสี่ยง') },
  ]
  return (
    <>
      <Breadcrumb data={breadcrumbList} title={t('แบบประเมินความเสี่ยง')} />
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelAlign="left"
        labelCol={{
          sm: 24,
          md: 24,
          lg: 8,
          xl: 8,
        }}
        wrapperCol={{
          sm: 24,
          md: 24,
          lg: 16,
          xl: 16,
          align: 'left',
        }}
        layout="horizontal"
        name={formName}
      >
        {createRiskFormError ? 'error' : null}
        <CardNew topic={t('รายละเอียดแบบประเมินความเสี่ยง')} icon="Info" toggledrop={'false'}>
          <Row style={{ marginTop: 24 }} justify="center">
            <Col sm={20} md={20} lg={12}>
              <Form.Item
                name="Year"
                label={<LabelNew type="tab-header-inactive">{t('ประจำปี')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุปี') }]}
                initialValue={new Date().getFullYear()}
              >
                <SelectYear
                  formname={formName}
                  placeholder={t('ระบุปี')}
                  handleChange={(v) => {
                    form.setFieldsValue({
                      Year: v.value,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="riskFormCode"
                label={<LabelNew type="tab-header-inactive">{t('รหัสแบบประเมิน')}</LabelNew>}
                rules={[
                  { required: true, message: t('กรุณาระบุรหัสแบบประเมิน.') },
                  { max: 10, message: t('ความยาวไม่เกิน 10 ตัวอักษร.') },
                ]}
                maxLength={10}
              >
                <InputNew placeholder={t('ระบุรหัสแบบประเมิน')} />
              </Form.Item>
              <Form.Item
                name="riskFormTH"
                label={<LabelNew type="tab-header-inactive">{t('ชื่อแบบประเมินภาษาไทย')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุชื่อแบบประเมินภาษาไทย') }]}
              >
                <InputNew placeholder={t('ระบุชื่อแบบประเมินภาษาไทย')} maxLength={200} />
              </Form.Item>
              <Form.Item
                name="riskFormEN"
                label={<LabelNew type="tab-header-inactive">{t('ชื่อแบบประเมินภาษาอังกฤษ')}</LabelNew>}
              >
                <InputNew placeholder={t('ระบุชื่อแบบประเมินภาษาอังกฤษ')} maxLength={200} />
              </Form.Item>
              <Form.Item
                name="riskModelCode"
                label={<LabelNew type="tab-header-inactive">{t('คลังแบบประเมินความเสี่ยง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุคลังแบบประเมินความเสี่ยง') }]}
              >
                <SelectRiskModel
                  placeholder={t('ระบุคลังแบบประเมินความเสี่ยง')}
                  formname={formName}
                  filters={{ organizationCode: orgCode }}
                  handleChange={(v) => {
                    form.setFieldsValue({
                      riskModelCode: v.value,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="riskFormType"
                label={<LabelNew type="tab-header-inactive">{t('ประเภทการประเมิน')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุประเภทการประเมิน') }]}
              >
                {/* ประเภทการประเมิน 1 = ประเมินสรุป , 2 = ประเมินรายหน่วยงาน */}
                <Select
                  data={[
                    { value: 1, label: t('ประเมินสรุป') },
                    { value: 2, label: t('ประเมินรายหน่วยงาน') },
                  ]}
                  placeholder={t('ระบุประเภทการประเมิน')}
                  scrollableId={formName}
                />
              </Form.Item>
              <Form.Item
                name="oganizationAudit"
                label={<LabelNew type="tab-header-inactive">{t('หน่วยตรวจสอบ')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุหน่วยตรวจสอบ') }]}
              >
                <SelectOrganization
                  placeholder={t('ระบุหน่วยตรวจสอบ')}
                  formname={formName}
                  handleChange={(v) => {
                    form.setFieldsValue({
                      oganizationAudit: v.value,
                    })
                  }}
                  disabled={true}
                  filters={{
                    // organizationChecked: 2,
                    isActive: 1,
                  }}
                />
              </Form.Item>
              <Form.Item
                name={'dateStart'}
                label={<LabelNew type="tab-header-inactive">{t('วันเริ่มต้นประเมิน')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุวันเริ่มต้นประเมิน') }]}
              >
                <Datepicker
                  setValueDateFn={async (data) => {
                    setIsMinDate(data)
                    form.setFieldsValue({ dateStart: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateStart: null })
                  }}
                  isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                name={'dateEnd'}
                label={<LabelNew type="tab-header-inactive">{t('วันสิ้นสุดประเมิน')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุวันสิ้นสุดประเมิน') }]}
              >
                <Datepicker
                  setValueDateFn={async (data) => {
                    setIsMaxDate(data)
                    form.setFieldsValue({ dateEnd: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateEnd: null })
                  }}
                  isMinDate={isMinDate ? new Date(isMinDate) : null}
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                name="isActive"
                initialValue={1}
                rules={[{ required: true, message: t('กรุณาระบุสถานะ') }]}
              >
                <RadioGroup>
                  <Space size={[8, 8]} wrap>
                    <Radio value={1}>{t('ใช้งาน')}</Radio>
                    &nbsp;
                    <Radio value={2}>{t('ไม่ใช้งาน')}</Radio>
                  </Space>
                </RadioGroup>
              </Form.Item>
            </Col>
          </Row>
        </CardNew>
        <CardNew topic={t('หน่วยงานรับตรวจ')} icon="List" toggledrop={'true'}>
          <Row gutter={[16, 16]}>
            <Col {...Responsive}>
              <Space size={[8, 8]} wrap>
                <ButtonNew
                  menu={menu}
                  type="plusTableBorderPrimary"
                  roles={{ type: 'add', menu: menu }}
                  onClick={() => {
                    setModal(true)
                  }}
                >
                  {t('หน่วยงานรับตรวจ')}
                </ButtonNew>
                &nbsp;
              </Space>
              {errorOrganizationList ? (
                <>
                  <span style={{ color: '#ea5456' }}>{t('กรุณาระบุหน่วยรับตรวจอย่างน้อย 1 รายการ')}</span>
                </>
              ) : null}
            </Col>
            <Col {...Responsive}>
              <Table
                columns={Columns}
                dataSource={[...dataLists]}
                size="middle"
                pagination={true}
                scroll={{ x: '100%', y: 350 }}
              />
            </Col>
            <Col {...Responsive} align="center">
              <Space size={[8, 8]} wrap>
                <ButtonNew
                  type="primary"
                  roles={{ type: 'add', menu: menu }}
                  htmlType="submit"
                  loading={createRiskFormLoading ? true : false}
                >
                  {t('บันทึก')}
                </ButtonNew>
                &nbsp;
                <ButtonNew
                  type="back"
                  onClick={() => {
                    navigate('/riskassessment/riskform')
                  }}
                >
                  {t('ยกเลิก')}
                </ButtonNew>
              </Space>
            </Col>
          </Row>
        </CardNew>
        <Form.Item hidden={true} name="organizationList" rules={[{ required: true, message: 'required' }]}>
          <Input />
        </Form.Item>
      </Form>
      {modal && (
        <CheckerOrganizationModal
          menu={menu}
          close={(e) => setModal(e)}
          open={modal}
          getData={(v) => setOrganizationList(v)}
          orgActive={dataLists}
        />
      )}
    </>
  )
}

export default Riskformfirststep
