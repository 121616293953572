import React from 'react'
import { useTranslation } from 'react-i18next'
import { colors } from '../../configs/styles.config'
import { Badge } from 'antd'
import { StatusStyle } from './StatusStyle'

export const Status = (props) => {
  const { t } = useTranslation()
  const text = props.text || ''

  let color
  switch (text) {
    case 'ใช้งาน':
    case 'ปกติ':
      color = colors.success
      break
    case 'ไม่ใช้งาน':
      color = colors.danger
      break
    case 'รอพิจารณา':
      color = colors.yellow
      break
    case 'กำลังพิจารณา':
    case 'อยู่ระหว่างพิจารณา':
    case 'รอการอนุมัติ':
      color = colors.orange
      break
    case 'พิจารณาแล้ว':
    case 'พิจารณาเสร็จสิ้น':
    case 'อนุมัติแล้ว':
      color = colors.success
      break
    case 'รอดำเนินการ':
      color = colors.orange
      break
    case 'กำลังดำเนินการ':
      color = colors.orange
      break
    case 'กำลังประเมิน':
    case 'ส่งต่อแบบประเมิน':
    case 'รอตรวจสอบ':
      color = colors.lime
      break
    case 'ดำเนินการแล้วเสร็จ':
      color = colors.success
      break
    case 'เสร็จสิ้น':
    case 'เสร็จสิ้นการประเมิน':
    case 'ดำเนินการเสร็จสิ้น':
      color = colors.success
      break
    case 'อนุมัติ':
    case 'อนุมัติยกเลิกโครงการ':
      color = colors.success
      break
    case 'รออนุมัติขอยกเลิก':
    case 'รออนุมัติ':
    case 'ขอยกเลิก':
      color = colors.warning
      break
    case 'ไม่อนุมัติ':
    case 'ยังไม่ประเมิน':
    case 'ไม่อนุมัติยกเลิกโครงการ':
      color = colors.danger
      break
    case 'ตรวจสอบเสร็จสิ้น':
    case 'ส่งแล้ว':
      color = colors.success
      break
    case 'อนุมัติยกเลิก':
    case 'ยกเลิกการตรวจสอบ':
    case 'ยกเลิก':
    case 'ยังไม่ส่ง':
      color = colors.danger
      break
    case 'ยืนยันแล้ว':
      color = colors.success
      break
    case 'ยังไม่ยืนยัน':
    case 'บันทึกร่าง':
      color = colors.orange
      break
    case 'เพิ่มข้อมูล':
      color = colors.primary
      break
    case 'แก้ไขข้อมูล':
      color = colors.orange
      break
    case 'ลบข้อมูล':
      color = colors.danger
      break

    default:
      color = colors.primary
      break
  }
  return (
    <React.Fragment>
      <StatusStyle>
        <Badge color={color} text={t(text)} />
      </StatusStyle>
    </React.Fragment>
  )
}
