import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Form, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_RATE_RISK_FACTOR_PROCESS_RCM } from '../../graphql/Query'
import { CREATE_RISK_AUDIT_RCM_RESULT } from '../../graphql/Mutation'
import { ModalNew } from '../../../../../components/modal/Modal'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { ButtonNew } from '../../../../../components/button/Button'
import { Select } from '../../../../../components/select/Select'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { Alert } from '../../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText, displayError } from '../../../../../utilitys/helper'
import SelectPersonnel from '../../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'

const RiskModal = (props) => {
  const formname = 'RiskModalForm'
  const labelCol = { sm: 16, md: 8, lg: 8, xl: 8 }
  const wrapperCol = { sm: 14, md: 16, lg: 16, xl: 16, align: 'left' }

  const { menu, open, close, action, personnelMaker, getData } = props

  const [form] = Form.useForm()
  const { t } = useTranslation()
  const { state } = useLocation()

  const [visible, setVisible] = useState(false)
  const [isRateFacGroupCode, setIsRateFacGroupCode] = useState(null)
  const [dataMain, setDataMain] = useState([])
  const [dataRateFacGroup, setDataRateFacGroup] = useState([])
  const [dataRateFacPro, setDataRateFacPro] = useState([])

  const [getRateRiskFactorProcessRCMFn, getRateRiskFactorProcessRCM] = useLazyQuery(GET_RATE_RISK_FACTOR_PROCESS_RCM)
  const [createRiskAuditRCMResultFn, createRiskAuditRCMResult] = useMutation(CREATE_RISK_AUDIT_RCM_RESULT)

  useEffect(() => {
    setVisible(open)
  }, [open])

  useEffect(() => {
    if (personnelMaker) form.setFieldsValue({ personnelCode: personnelMaker })
  }, [personnelMaker])

  useEffect(() => {
    if (state?.rcmId) {
      getRateRiskFactorProcessRCMFn({
        variables: {
          input: encryptInput({ rcmId: state?.rcmId }),
        },
      })
    }
  }, [state?.rcmId])

  useEffect(() => {
    if (getRateRiskFactorProcessRCM?.data) {
      setDataMain(getRateRiskFactorProcessRCM?.data?.getRateRiskFactorProcessRCM)
    }

    if (getRateRiskFactorProcessRCM?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: displayError(getRateRiskFactorProcessRCM?.error),
        onOk() {},
      })
    }
  }, [getRateRiskFactorProcessRCM?.data])

  useEffect(() => {
    if (dataMain.length) {
      const rateFacGroups = _.groupBy(
        dataMain,
        (d) => `${d.rateFacGroupCode}|${d.rateFacGroupNameTH}|${d.rateFacGroupNameEN}`,
      )

      const newRateFacGroups = Object.keys(rateFacGroups)

      setDataRateFacGroup(
        _.map(newRateFacGroups, (d) => {
          const data = d.split('|')
          return { value: data[0], label: displayText(data[1], data[2]) }
        }),
      )
    }
  }, [dataMain])

  useEffect(() => {
    if (isRateFacGroupCode && dataMain.length) {
      const rateFacPros = _.filter(dataMain, (d) => d.rateFacGroupCode === isRateFacGroupCode)

      setDataRateFacPro(
        _.map(rateFacPros, (d) => {
          return {
            value: d.rateFacProCode,
            label: displayText(`${d.shortCode}: ${d.rateFacProNameTH}`, `${d.shortCode}: ${d.rateFacProNameEN}`),
          }
        }),
      )
    }
  }, [isRateFacGroupCode, dataMain])

  useEffect(() => {
    if (createRiskAuditRCMResult?.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk: () => {
          getData(createRiskAuditRCMResult?.data)
          form.resetFields()
          onClose()
        },
        onCancel: () => {},
      })
    }

    if (createRiskAuditRCMResult?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: displayError(createRiskAuditRCMResult?.error),
        onOk() {},
      })
    }
  }, [createRiskAuditRCMResult?.data])

  const onFinish = (data) => {
    const objData = encryptInput({
      rcmId: state?.rcmId,
      rateFacGroupCode: data?.rateFacGroupCode,
      rateFacProCode: data?.rateFacProCode,
      riskDetail: data?.riskDetail ? data?.riskDetail : null,
      personnelCode: data?.personnelCode,
    })

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk: () => {
        createRiskAuditRCMResultFn({ fetchPolicy: 'no-cache', variables: { input: objData } })
      },
      onCancel: () => {},
    })
  }

  const onClose = () => {
    form.resetFields()
    setVisible(false)
    close(false)
  }

  return (
    <ModalNew
      onSubmit={() => {}}
      onClose={() => onClose()}
      visible={visible}
      typeAction={action === 'edit' ? 'edit' : action === 'view' ? 'view' : null}
      testTitle={t(
        action === 'add'
          ? 'เพิ่มประเมินความเสี่ยง'
          : action === 'edit'
          ? 'แก้ไขประเมินความเสี่ยง'
          : 'ประเมินความเสี่ยง',
      )}
      type="medium"
      footer={null}
    >
      {(getRateRiskFactorProcessRCM?.loading || createRiskAuditRCMResult?.loading) && <SpinnersNew />}
      <Col sm={24} md={20} lg={20} style={{ margin: 'auto' }}>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{ ...labelCol }}
          wrapperCol={{ ...wrapperCol }}
          layout="horizontal"
          name={formname}
        >
          <Form.Item
            name="rateFacGroupCode"
            label={<Label type="tab-header-inactive">{t('กลุ่มความเสี่ยงกระบวนการ')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกกลุ่มความเสี่ยงกระบวนการ') }]}
          >
            <Select
              disabled={false}
              scrollableId={formname}
              placeholder={t('เลือกกลุ่มความเสี่ยงกระบวนการ')}
              data={dataRateFacGroup}
              onChange={async (e) => {
                await form.setFieldsValue({ rateFacProCode: null })
                await setIsRateFacGroupCode(e)
                await form.setFieldsValue({ rateFacGroupCode: e })
              }}
            />
          </Form.Item>
          <Form.Item
            name="rateFacProCode"
            label={<Label type="tab-header-inactive">{t('ปัจจัย/ความเสี่ยง')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกปัจจัย/ความเสี่ยง') }]}
          >
            <Select
              disabled={false}
              scrollableId={formname}
              placeholder={t('เลือกปัจจัย/ความเสี่ยง')}
              data={dataRateFacPro}
            />
          </Form.Item>
          <Form.Item name="riskDetail" label={<Label type="tab-header-inactive">{t('รายละเอียดความเสี่ยง')}</Label>}>
            <TextAreaNew
              placeholder={t('ระบุรายละเอียดความเสี่ยง')}
              autoSize={{ minRows: 4, maxRows: 4 }}
              disabled={false}
            />
          </Form.Item>
          <Form.Item
            name="personnelCode"
            label={<Label type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกหน่วยงานที่รับผิดชอบ') }]}
          >
            <SelectPersonnel
              placeholder={t('เลือกหน่วยงานที่รับผิดชอบ')}
              formname={formname}
              filters={{
                levelCodeWhereIn: "'05','06','07','08','09','10','11'",
              }}
              disabled={true}
            />
          </Form.Item>
          <Col align="center">
            <Space size={[8, 8]} wrap>
              <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                {t('บันทึก')}
              </ButtonNew>
              <ButtonNew type="back" onClick={() => onClose()}>
                {t('ยกเลิก')}
              </ButtonNew>
            </Space>
          </Col>
        </Form>
      </Col>
    </ModalNew>
  )
}

RiskModal.propTypes = {
  menu: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
}

export default RiskModal
