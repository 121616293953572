import React, { useEffect, useState, useMemo } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { Datatable } from '../../../../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../../../../components/spinners/Spinners'
import { useGetMasterCauseOfIssue, useGetAuditCauseOfIssueHeatmap, useGetHeaderOrganization } from '../../hooks'
import { heatMapColumns } from '../../utils/columns'
import { displayText } from '../../../../../../utilitys/helper'
import { ButtonNew } from '../../../../../../components/button/Button'
import { colors } from '../../../../../../configs/styles.config'
import { HeatmapStyled } from '../../css/HeatmapStyled'
// import { mock_auditCauseOfIssue } from '../../utils/mock'

const StatusCauseAndIssueDetectedHeatmap = (props) => {
  const { filterDt, projectCodesFn } = props

  const { t } = useTranslation()

  const [columns, setColumns] = useState([])
  const [dtList, setDtList] = useState([])

  const [causeOfIssueList, causeOfIssueLoading] = useGetMasterCauseOfIssue()

  const options = useMemo(() => {
    const { dateStart, dateEnd, organizationCodes } = filterDt
    return { dateStart, dateEnd, organizationCodes }
  }, [filterDt?.dateStart, filterDt?.dateEnd, filterDt?.organizationCodes])

  const [auditCauseOfIssue, auditCauseOfIssueLoading] = useGetAuditCauseOfIssueHeatmap(options)

  const optionOrg = useMemo(() => {
    return filterDt?.organizationCode ? filterDt?.organizationCode : null
  }, [filterDt?.organizationCode])

  const [headerOrganization, headerOrganizationLoading] = useGetHeaderOrganization(optionOrg)

  const setBgColor = (riskKey) => {
    switch (riskKey) {
      case 'L':
        return colors.yellow
      case 'M':
        return colors.success
      case 'H':
        return colors.orange
      case 'VH':
        return colors.danger
      default:
        return
    }
  }

  const setSortRiskLevel = (d) => {
    switch (d?.riskLevelCode) {
      case 'L':
        return { ...d, sort: 1 }
      case 'M':
        return { ...d, sort: 2 }
      case 'H':
        return { ...d, sort: 3 }
      case 'VH':
        return { ...d, sort: 4 }
      default:
        return { ...d }
    }
  }

  useEffect(() => {
    if (headerOrganization) {
      const sumCause = []
      let countSumCause = []
      const setNewAditCauseOfIssue = _.map(auditCauseOfIssue, (d) => setSortRiskLevel(d))
      const gAuditCauseOfIssue = _.groupBy(setNewAditCauseOfIssue, 'organizationCode')

      const makeDt = _.map(headerOrganization, (dt) => {
        const findAuditCauseOfIssue = _.filter(dt?.sub, (d) => gAuditCauseOfIssue[d])

        const result = {
          data: dt,
          key: dt?.organizationCode,
          organizationIdx: displayText(dt?.organizationNameTH, dt?.organizationNameEN),
        }

        if (findAuditCauseOfIssue.length) {
          const setAuditCauseOfIssue = _.flattenDeep(_.map(findAuditCauseOfIssue, (d) => gAuditCauseOfIssue[d]))
          const gCauseCode = _.groupBy(setAuditCauseOfIssue, 'causeCode')

          Object.keys(gCauseCode).forEach((dt) => sumCause.push({ [dt]: gCauseCode[dt] }))

          for (const [key, value] of Object.entries(gCauseCode)) {
            const gRiskLevelCode = _.groupBy(_.orderBy(value, 'sort', 'asc'), 'riskLevelCode')
            const setRiskLevel = []
            let countCause = 0

            for (const [riskKey, riskValue] of Object.entries(gRiskLevelCode)) {
              const setStyle = { textAlign: 'center', color: colors.black, background: setBgColor(riskKey) }
              //นับรวมจำนวนความเสี่ยงในแต่ละช่อง
              countCause += riskValue.length

              setRiskLevel.push(
                <ButtonNew
                  type="miniModule"
                  width={45}
                  height={40}
                  style={{ ...setStyle }}
                  key={riskKey}
                  onClick={() => projectCodesFn(_.map(riskValue, 'projectCode'))}
                >
                  {riskValue.length}
                </ButtonNew>,
              )
            }

            countSumCause.push(countCause)

            result[key] = (
              <Row gutter={[2, 2]} justify="center">
                {_.map(setRiskLevel, (d) => d)}
              </Row>
            )
          }
        }

        return result
      })

      /* generate record summary cause */
      const setSummaryCause = { key: 'setSummaryCause', organizationIdx: t('รวม') }

      sumCause.forEach((dt, key) => {
        setSummaryCause[_.head(_.keys(dt))] = countSumCause[key]
      })

      makeDt.push(setSummaryCause)

      setDtList(makeDt)
    }
  }, [headerOrganization, auditCauseOfIssue])

  useEffect(() => {
    if (auditCauseOfIssue) projectCodesFn(_.map(auditCauseOfIssue, 'projectCode'))
  }, [auditCauseOfIssue])

  useEffect(() => {
    /* get master cause of issue data for dynamic columns in data table */
    if (causeOfIssueList) setColumns(heatMapColumns(t, causeOfIssueList))
  }, [causeOfIssueList])

  return (
    <>
      {(causeOfIssueLoading || auditCauseOfIssueLoading || headerOrganizationLoading) && <SpinnersNew />}
      <Row justify="center">
        <Col span={23}>
          <HeatmapStyled>
            <Datatable columns={columns} data={dtList} searchCustom={false} paginationCustom={false} />
          </HeatmapStyled>
        </Col>
      </Row>
    </>
  )
}

StatusCauseAndIssueDetectedHeatmap.propTypes = {
  filterDt: PropTypes.object,
  projectCodesFn: PropTypes.func.isRequired,
}

export default StatusCauseAndIssueDetectedHeatmap
