import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { TabNew } from '../../../components/tab/Tab'
import { decryptData } from '../../../utilitys/crypto'
import ActionPlanCalendarList from './ActionPlanCalendarList'
import ActionPlanList from './ActionPlanList'

const ActionPlanIndex = ({ menu }) => {
  let defaultKey = '1'
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { state } = useLocation()

  const param = useParams()

  useEffect(() => {
    if (param?.tabParam === 'overviewfollowup') {
      const localS = localStorage.getItem('actionPlan')
      if (localS) {
        const deData = decryptData(localS)
        if (deData) {
          localStorage.removeItem('actionPlan')
          navigate('/auditoperations/actionplan/form', {
            state: {
              refCode: deData.actionplanId,
              type_action: 'view',
              projectCode: deData.projectCode,
              auditTeamCode: deData.auditTeamCode,
            },
          })
        }
      }
    }
  }, [param?.tabParam])

  if (state) {
    const { defaultActiveKey: ActiveKey = '1' } = state
    defaultKey = ActiveKey
  }

  const [activeKey, setActiveKey] = useState(defaultKey)

  const arrCrumb = [
    { label: t('งานปฎิบัติการตรวจสอบ'), path: '/auditoperations/actionplan' },
    { label: t('แผนการปฎิบัติงาน') },
  ]

  const tabs = [
    // Import components content
    {
      key: '1',
      name: t('จัดการข้อมูลแผนปฎิบัติงาน'),
      disabled: false,
      content: () => {
        return <ActionPlanList menu={menu} activeKey={activeKey} />
      },
    },
    {
      key: '2',
      name: t('ตารางข้อมูลแผนปฎิบัติงาน'),
      disabled: false,
      content: () => <ActionPlanCalendarList activeKey={activeKey} />,
    },
  ]

  return (
    <React.Fragment>
      <BreadcrumbNew data={arrCrumb} title={t('แผนการปฎิบัติงาน')} />
      <CardNew topic={t('รายการแผนการปฎิบัติงาน')} icon="List">
        <TabNew
          tab={tabs}
          defaultActiveKey={defaultKey}
          onTabClick={(key) => {
            setActiveKey(key)
            if (key === '1') {
              navigate('/auditoperations/actionplan', {
                state: {
                  defaultActiveKey: '1',
                },
              })
            }
            if (key === '2') {
              navigate('/auditoperations/actionplan', {
                state: {
                  defaultActiveKey: '2',
                },
              })
            }
          }}
        />
      </CardNew>
    </React.Fragment>
  )
}

export default ActionPlanIndex
