import { gql } from '@apollo/client'
export const SAVE_AUDIT_OPEN = gql `
  mutation auditOpensInformationSave($input: AuditOpensInformationInput!) {
    auditOpensInformationSave(data: $input) {
      openId
      openNumber
    }
  }
`

export const SAVE_AUDITOPENS_ADDDOC = gql `
  mutation auditOpensAdddocSave($input: AuditOpensAdddocInput!) {
    auditOpensAdddocSave(data: $input) {
      openDocId
    }
  }
`

export const AUDITOPENS_GENERATECODE = gql `
  mutation auditOpensGenerateCode($input: AuditOpensGenerateCode!) {
    auditOpensGenerateCode(data: $input) {
      codeGen
    }
  }
`

export const AUDITOPENS_ADDDOC_DELETE = gql `
  mutation auditOpensAddDocTabDelete($input: AuditOpensAddDocTabDelete!) {
    auditOpensAddDocTabDelete(data: $input) {
      openDocId
    }
  }
`

export const AUDITOPENS_DELETE = gql `
  mutation auditOpensDelete($input: AuditOpensDelete!) {
    auditOpensDelete(data: $input) {
      openId
    }
  }
`

export const AUDITOPENS_SENDMAIL = gql `
  mutation auditOpensSendMail($input: AuditOpensSendMail!) {
    auditOpensSendMail(data: $input) {
      status
    }
  }
`