import React, { useState } from 'react'
import { Upload, Button } from 'antd'
import { UploadsExcelStyle, UploadExcelInlineStyle } from './UploadsExcelStyle'
import { useTranslation } from 'react-i18next'
import { Alert } from '../alert/Alert'

export function UploadExcel(props) {
  const { importHistoricalData, dataType, dataFiles, disabled } = props

  const { t } = useTranslation()
  const [fileName, setFileName] = useState('')

  // function delete file
  const deleteFile = (fileName) => {
    fetch(process.env.REACT_APP_API_UPLOAD + `/deleteImportExcel/${fileName}`, { method: 'GET' })
      .then(async (res) => {
        const resData = await res.json()
        Alert({
          type: 'error',
          title: t('ไม่สามารถลบข้อมูลได้'),
          content: t(resData.message),
          onOk() {},
        })
      })
      .catch((err) => {
        Alert({
          type: 'error',
          title: t('ไม่สามารถลบข้อมูลได้'),
          content: t(err),
          onOk() {},
        })
      })
  }

  const propsApi = {
    action: importHistoricalData
      ? process.env.REACT_APP_API_UPLOAD + `/importHistoricalData/uploadExcel/${dataType}`
      : process.env.REACT_APP_API_UPLOAD + `/uploadExcel`,
    headers: { module: 'temp' },
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        if (fileList.length === 0) {
          dataFiles({ fileName: '', data: [] })
        } else {
          if (file?.response?.statusCode === 400 || file?.response?.status === 400) {
            Alert({
              type: 'error',
              title: t('ไม่สามารถนำเข้าข้อมูลได้'),
              content: t(file?.response?.message),
              onOk() {},
            })
          } else {
            setFileName(file?.response?.fileNameNew)
            dataFiles({
              fileName: file?.response?.fileNameNew,
              data: file?.response?.dataObj ? file?.response?.dataObj : [],
            })
          }
        }
      }
    },
    onRemove() {
      deleteFile(fileName)
    },
    defaultFileList: [],
  }

  return (
    <>
      <UploadExcelInlineStyle multiRow={false}>
        <Upload
          {...propsApi}
          multiple={false}
          maxCount={1}
          disabled={disabled}
          className="upload-excel-file-style-inline"
          accept=".xlsx,.XLSX"
        >
          <UploadsExcelStyle>
            <Button className="btn-stt-icon-upload btn-color-examine-uploads" type="primary">
              {t('อัฟโหลด')}
            </Button>
          </UploadsExcelStyle>
        </Upload>
      </UploadExcelInlineStyle>
    </>
  )
}
