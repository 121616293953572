import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { CardNew } from '../../../components/card/Card.jsx'
import { Datatable } from '../../../components/datatable/Datatable'
import { FOLLOWUP_LIST } from './graphql/Query'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError } from '../../../utilitys/helper'
import { displayText } from '../../../utilitys/helper'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { Alert } from '../../../components/alert/Alert'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { useNavigate } from 'react-router-dom'
import FollowUpAuditReportExport from './modal/FollowUpAuditReportExport.jsx'

const FollowUpAuditList = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [dataForExport, setDataForExport] = useState({})
  const [followUpAuditReportExportModal, setActionExplainReportExportModal] = useState(false)

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }
  const [followUplistFn, followUplist] = useLazyQuery(FOLLOWUP_LIST)
  useEffect(() => {
    if (followUplist?.data) {
      let response = followUplist?.data?.followUplist
      setCount(response.count)
      setDataLists(
        response?.result?.map((item, index) => {
          return {
            key: index,
            projectName: item.projectCode + ' : ' + displayText(item.projectNameTH, item.projectNameEN),
            projectRef: displayText(item.projectRefNameTH, item.projectRefNameEN),
            riskForm: displayText(item.riskFormTH, item.riskFormEN),
            dateStart: item.dateStart ? dateDisplay(item.dateStart) : '',
            dateEnd: item.dateEnd ? dateDisplay(item.dateEnd) : '',
            organization: displayText(item.organizationNameTH, item.organizationNameEN),
            personnel:
              displayText(item.namePrefixTH, item.namePrefixEN) +
              displayText(item.firstNameTH, item.firstNameEN) +
              ' ' +
              displayText(item.lastNameTH, item.lastNameEN),
            followBTN:
              item.buttoStatus === 0 ? (
                <ButtonNew
                  type="flagOutlinedInTableBlue"
                  roles={{ type: 'edit', menu: props.menu }}
                  onClick={() => {
                    navigate('/followup/followupaudit/manager/follow', {
                      state: { refCode: item.projectCode },
                    })
                  }}
                >
                  {t('Follow up')}
                </ButtonNew>
              ) : (
                <ButtonNew
                  type="moreHorizontalInTableOrange"
                  roles={{ type: 'edit', menu: props.menu }}
                  onClick={() => {
                    navigate('/followup/followupaudit/manager/edit', {
                      state: { refCode: item.projectCode },
                    })
                  }}
                >
                  {t('แก้ไขติดตามงาน')}
                </ButtonNew>
              ),
            action: (
              <>
                {item.buttoStatus === 1 ? (
                  <div className="text-center">
                    <ButtonGroup
                      menu={props.menu}
                      icons={[
                        {
                          type: 'download',
                          onClick: (data) => {
                            setDataForExport({
                              ...dataForExport,
                              ...data,
                              projectCode: item.projectCode,
                              typeAction: 'export',
                            })
                            setActionExplainReportExportModal(true)
                          },
                        },
                        {
                          type: 'view',
                          onClick: () => {
                            navigate('/followup/followupaudit/manager/view', {
                              state: { refCode: item.projectCode },
                            })
                          },
                        },
                      ]}
                    />
                  </div>
                ) : (
                  ''
                )}
              </>
            ),
          }
        }),
      )
    }
    if (followUplist.error) {
      showAlertLoadDataError(displayError(followUplist.error))
    }
  }, [followUplist.data])

  const columns = [
    {
      title: <div className="text-center">{t('โครงการ')}</div>,
      dataIndex: 'projectName',
      width: '250px',
    },
    {
      title: <div className="text-center">{t('แหล่งที่มาของโครงการ')}</div>,
      dataIndex: 'projectRef',
      width: '150px',
    },
    {
      title: <div className="text-center">{t('ความเสี่ยง')}</div>,
      dataIndex: 'riskForm',
      width: '120px',
    },
    {
      title: <div className="text-center">{t('วันที่เริ่มต้น')}</div>,
      dataIndex: 'dateStart',
      render: (record) => <div className="text-center">{record}</div>,
      width: '110px',
    },
    {
      title: <div className="text-center">{t('วันที่สิ้นสุด')}</div>,
      dataIndex: 'dateEnd',
      width: '110px',
      render: (record) => <div className="text-center">{record}</div>,
    },
    {
      title: <div className="text-center">{t('หน่วยงานที่รับผิดชอบ')}</div>,
      dataIndex: 'organization',
      width: '150px',
    },
    {
      title: <div className="text-center">{t('ผู้รับผิดชอบ')}</div>,
      dataIndex: 'personnel',
      width: '150px',
    },
    {
      title: '',
      dataIndex: 'followBTN',
      width: '150px',
      render: (record) => <div className="text-center">{record}</div>,
    },
    {
      title: '',
      dataIndex: 'action',
      width: '100px',
      render: (record) => <div className="text-center">{record}</div>,
    },
  ]
  const callApiSearch = () => {
    followUplistFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          filters: {
            lang: localStorage.getItem('lang'),
            personnel: props.formFilter.getFieldValue('personnel') ? props.formFilter.getFieldValue('personnel') : null,
            project: props.formFilter.getFieldValue('project') ? props.formFilter.getFieldValue('project') : null,
            projectRefCode: props.formFilter.getFieldValue('projectRefCode')
              ? props.formFilter.getFieldValue('projectRefCode')
              : null,
            organizationCode3: props.formFilter.getFieldValue('organizationCode3')
              ? props.formFilter.getFieldValue('organizationCode3').join()
              : null,
            organizationCode2: props.formFilter.getFieldValue('organizationCode2')
              ? props.formFilter.getFieldValue('organizationCode2').join()
              : null,
          },
          pagination: { limit, page },
          sort: [],
        }),
      },
    })
  }
  useEffect(() => {
    callApiSearch()
  }, [page, limit])
  useEffect(() => {
    callApiSearch()
  }, [props.searchStatus])

  return (
    <>
      <FollowUpAuditReportExport
        menu={props.menu}
        closeModal={(e) => setActionExplainReportExportModal(e)}
        followUpAuditReportExportModal={followUpAuditReportExportModal}
        dataForExport={dataForExport}
      />
      <Row>
        <Col md={24}>
          {followUplist.loading && <SpinnersNew />}

          <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="AlignJustify" toggledrop="false">
            <Datatable
              columns={columns}
              data={dataLists}
              handleTableChange={() => {}}
              paginationCustom={true}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
              total={count}
              enableScroll={{ x: true, y: true }}
              pageSize={limit}
              page={page}
              btnAdd={<></>}
            />
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default FollowUpAuditList
