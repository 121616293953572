import { useState } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { ApolloProvider } from '@apollo/client'
import { Page404 } from './components/result/Page404'
import { client } from './configs/api.config'
import { AppStyles } from './AppStyles'
import FullLayout from './layouts/FullLayout'
// import Dashboard from './modules/main/dashboard/DashboardIndex'
import DashboardRoutes from './modules/main/dashboard/Routes'
import Profile from './modules/main/profile/ProfileIndex.jsx'
import Components from './modules/components/Routes'
import AdministratorRoutes from './modules/administrator/Routes'
import RiskAssessment from './modules/riskassessment/Routes'
import InternalControlRoutes from './modules/internalcontrol/Routes'
import Login from './modules/main/auth/login/Login'
import ByPass from './utilitys/bypass/ByPass'
import ApprovePlanning from './modules/auditplanning/Routes'
import ReportsIndex from './modules/reports/Routes'
import Adhocjob from './modules/auditoperations/Routes'
import ResetPassword from './modules/main/resetpassword/ResetPassword'
import ForGotPassword from './modules/main/forgotpassword/ForGotPassword'
import FollowUp from './modules/followup/Routes'
import Logs from './modules/logs/Routes'
import CheckLogin from './modules/main/auth/checklogin/CheckLogin'
import './App.less'

function App() {
  const [activeScreen, setActiveScreen] = useState('active')

  return (
    <AppStyles>
      <ApolloProvider client={client}>
        <Routes>
          <Route
            element={
              <CheckLogin setActiveScreen={setActiveScreen} layout={<FullLayout activeScreen={activeScreen} />} />
            }
          >
            <Route path="/" element={<Navigate to="/dashboard/overviewfollowup" />} />
            <Route path="/dashboard/*" element={<DashboardRoutes />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/components/*" element={<Components />} />
            <Route path="/administrator/*" element={<AdministratorRoutes />} />
            <Route path="/internalcontrol/*" element={<InternalControlRoutes />} />
            <Route path="/riskassessment/*" element={<RiskAssessment />} />
            <Route path="/auditplanning/*" element={<ApprovePlanning />} />
            <Route path="/auditoperations/*" element={<Adhocjob />} />
            <Route path="/followup/*" element={<FollowUp />} />
            <Route path="/reports/*" element={<ReportsIndex />} />
            <Route path="/logs/*" element={<Logs />} />
          </Route>
          <Route path="login" element={<Login />} />
          <Route path="/bypass/:token" element={<ByPass />} />
          <Route path="/resetpassword" element={<ResetPassword />} />
          <Route path="/forgotpassword" element={<ForGotPassword />} />
          {/* Page 404 ต้องอยู่ล่างสุด **** */}
          <Route path="*" element={<Page404 />} />
        </Routes>
      </ApolloProvider>
    </AppStyles>
  )
}
export default App
