import { gql } from '@apollo/client'

export const GET_MASTER_PROJECT_STATUS = gql`
  query getMasterProjectStatus($input: MasterProjectStatusInput!) {
    getMasterProjectStatus(data: $input) {
      projectStatusId
      projectStatusCode
      projectStatusNameTH
      projectStatusNameEN
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`
