import { gql } from '@apollo/client'

export const GETASSESSMENTAUDITWORKPAGINATION = gql`
  query getAssessmentAuditWorkPagination($input: AssessmentAuditWorkPaginationInput!) {
    getAssessmentAuditWorkPagination(input: $input) {
      result {
        formResultId
        formAssessId
        formCode
        organizationCode
        organizationCode
        organizationNameTH
        organizationNameEN
        operationStatusCode
        responsibilityBy
        makerBy
        makerStatusCode
        successedAt
        successedBy
        reviewAt
        reviewBy
        personnelCode
        formYear
        formNameTH
        formNameEN
        startDate
        endDate
      }
      count
      page
      limit
    }
  }
`

export const GETASSESSMENTAUDITWORKONE = gql`
  query getAssessmentAuditWorkOne($input: AssessmentAuditWorkInput!) {
    getAssessmentAuditWorkOne(input: $input) {
      formResultId
      formAssessId
      formCode
      organizationCode
      organizationCode
      organizationNameTH
      organizationNameEN
      operationStatusCode
      responsibilityBy
      makerBy
      makerStatusCode
      successedAt
      successedBy
      reviewAt
      reviewBy
      personnelCode
      formYear
      formNameTH
      formNameEN
      startDate
      endDate
      makerFullNameTH
      makerFullNameEN
      fullNameAssessorTH
      fullNameAssessorEN
      fullNameAssessedTH
      fullNameAssessedEN
    }
  }
`

export const GETGROUPSANDQUESTIONSBYASSESSMENTAUDITWORK = gql`
  query getGroupsAndQuestionsByAssessmentAuditWork($input: AssessmentAuditWorkInput!) {
    getGroupsAndQuestionsByAssessmentAuditWork(input: $input) {
      resultDetailId
      formResultId
      formGroupId
      formCode
      formSort
      formGroupNameTH
      formGroupNameEN
      formQuestId
      formSortByQ
      formQuestNameTH
      formQuestNameEN
      choiceTypeCode
      choiceGroupCode
      formQuestType
      formRequired
      files
      choiceDetailId
      answerDetail
    }
  }
`

export const GETMASTERCHOICEGROUPDETAILBYASSESSMENTAUDITWORK = gql`
  query getMasterChoiceGroupDetailByAssessmentAuditWork($isActive: Float!) {
    getMasterChoiceGroupDetailByAssessmentAuditWork(isActive: $isActive) {
      choiceDetailId
      choiceDetailNameTH
      choiceDetailNameEN
      choiceGroupCode
      score
      isActive
    }
  }
`

export const GETPERSONNELSBYASSESSMENTAUDITWORK = gql`
  query getPersonnelsByAssessmentAuditWork($personnelCode: String!) {
    getPersonnelsByAssessmentAuditWork(personnelCode: $personnelCode) {
      personnelId
      personnelCode
      namePrefixCode
      fullNameTH
      fullNameEN
      organizationCode
      organizationNameTH
      organizationNameEN
    }
  }
`
