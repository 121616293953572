import { Trans } from 'react-i18next'
// import { Alert } from '../../../../components/alert/Alert'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'

export const Columns = ({ fncOnpressDelete, fncOnpressView, fncOnpressEdit, menu, editable }) => {
  return [
    {
      title: <Trans>ขั้นตอนหลัก</Trans>,
      dataIndex: 'stepDetailMain',
      sorter: false,
      width: '600px',
      render: (item, record) => {
        if (record.stepDetailMain) {
          return (
            <div>
              {record.stepSortMain} : {record.stepDetailMain}
            </div>
          )
        }
        if (record.stepDtail) {
          return (
            <div>
              &emsp;
              {record.stepSortMain}.{record.stepSort} : {record.stepDtail}
            </div>
          )
        }
        return
      },
    },
    {
      title: <Trans>ขั้นตอนของกระบวนการ</Trans>,
      dataIndex: 'stepProcess',
      sorter: false,
      width: '200px',
      render: (item, record) => {
        if (record.stepProcess) {
          return (
            <div>
              {record.stepProcess === 1 ? <Trans>ขั้นตอนก่อนปิดตรวจ</Trans> : <Trans>ขั้นตอนหลังปิดตรวจ</Trans>}
            </div>
          )
        }
        return
      },
    },
    {
      title: <Trans>ประเภทขั้นตอน</Trans>,
      dataIndex: 'stepType',
      sorter: false,
      width: '200px',
      render: (item, record) => {
        if (record.stepTypeName) {
          return <div>{record.stepTypeName}</div>
        }
        return
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '50px',
      render: (item, record) => {
        let icons = [
          {
            type: 'view',
            onClick: () => {
              fncOnpressView(record)
            },
          },
        ]
        if (editable) {
          icons.push({
            type: 'edit',
            onClick: () => {
              fncOnpressEdit(record)
            },
          })
          icons.push({
            type: 'delete',
            onClick: () => {
              fncOnpressDelete(record)
            },
          })
        }

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} icons={icons} />
          </div>
        )
      },
    },
  ]
}
