import { gql } from '@apollo/client'

export const GET_MASTER_NUMBER_TYPES = gql`
  query GetMasterNumberTypes($input: MasterNumberTypesInput!) {
    getMasterNumberTypes(input: $input) {
      numberTypeId
      numberTypeNameTH
      numberTypeCode
      numberTypeNameEN
      isActive
    }
  }
`
