import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'antd'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew as Button } from '../../../components/button/Button'
import { CardNew as Card } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew as Spinners } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { GET_LIST_SUB_RISK_FACTORS_PAGINATION } from './graphql/Query'

import { displayError, displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { DELETE_SUB_RISK_FACTOR } from './graphql/Mutation'
import { Columns } from './utils/subRiskFactorsListColumn'

const Responsive = {
  md: 24,
  lg: 24
}

const SubRiskFactorList = ({ menu }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const arrCrumb = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/subriskfactors' },
    { label: t('จัดการข้อมูลปัจจัยความเสี่ยงย่อย') }
  ]

  const [editable] = useState(true)

  const [dataRow, setdataRow] = useState([])

  const [paginate, setPaginate] = useState({ page: 1, limit: 10 })
  const [sort, setsort] = useState([{ fieldSort: 'factorActCode', sortType: 'ASC' }])

  const [
    filters
    //  setfilters
  ] = useState({})
  const [count, setcount] = useState(1)

  const [callGetList, { loading: loadingList, error: errorList, data: dataList }] = useLazyQuery(
    GET_LIST_SUB_RISK_FACTORS_PAGINATION,
    {
      fetchPolicy: 'no-cache'
    }
  )

  useEffect(() => {
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorList))
      })
    }
  }, [errorList])

  useEffect(() => {
    if (dataList) {
      let { result, count } = dataList.getListSubRiskFactorsPagination

      let dataTemp = result.map((item, key) => {
        let factorActName = displayText(item.factorActNameTH, item.factorActNameEN)
        let riskFactorName = displayText(item.riskFactorNameTH, item.riskFactorNameEN)

        return {
          ...item,
          key: key + 1,
          factorActName,
          riskFactorName
        }
      })

      setdataRow(dataTemp)
      setcount(count)
    }
  }, [dataList])

  useLayoutEffect(() => {
    if (sort && paginate) fncGetList()
  }, [paginate, sort])

  const [callDelete, { loading: loadingDelete, error: errorDelete, data: dataDelete }] = useMutation(
    DELETE_SUB_RISK_FACTOR,
    {
      fetchPolicy: 'no-cache'
    }
  )

  useEffect(() => {
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorDelete))
      })
    }
  }, [errorDelete])

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          fncGetList()
        }
      })
    }
  }, [dataDelete])

  const fncGetList = () => {
    const dataInput = {
      sort: sort,
      filters: filters,
      pagination: {
        page: paginate.page,
        limit: paginate.limit
      }
    }

    callGetList({
      variables: {
        input: encryptInput(dataInput)
      }
    })
  }

  const fncOnpressDelete = (data) => {
    const dataInput = {
      factorActCode: data.factorActCode
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ?'),
      onOk() {
        callDelete({
          variables: {
            input: encryptInput(dataInput)
          }
        })
      },
      onCancel() {}
    })
  }

  const fncOnpressView = (data) => {
    navigate('/administrator/subriskfactors/form', {
      state: { type_action: 'view', refCode: data.factorActCode }
    })
  }

  const fncOnpressEdit = (data) => {
    navigate('/administrator/subriskfactors/form', {
      state: { type_action: 'edit', refCode: data.factorActCode }
    })
  }

  return (
    <React.Fragment>
      {(loadingList || loadingDelete) && <Spinners />}

      <Breadcrumb data={arrCrumb} title={t('จัดการข้อมูลปัจจัยความเสี่ยงย่อย')} />
      <Card topic={t('รายการปัจจัยความเสี่ยงย่อย')} icon="List">
        <Row gutter={[16, 16]}>
          <Col {...Responsive}></Col>
        </Row>
        <Row>
          <Col {...Responsive}>
            <Datatable
              columns={Columns({ fncOnpressDelete, fncOnpressView, fncOnpressEdit, menu })}
              data={dataRow}
              total={count}
              showSizeChanger={true}
              searchCustom={true}
              pageSize={paginate.limit}
              page={paginate.page}
              isLoading={loadingList || loadingDelete}
              paginationCustom={true}
              scroll={{ x: 769 }}
              onChangePagination={({ page, pageSize }) => {
                setPaginate({ page, limit: pageSize })
              }}
              handleTableChange={(e) => {
                const reNewField = [
                  {
                    factorActName: 'factorActCode'
                  },
                  {
                    riskFactorName: 'riskFactorCode'
                  },
                  { isActive: 'isActive' }
                ]

                setsort(handleSort(e?.sorter, reNewField))
              }}
              btnAdd={
                editable ? (
                  <Button
                    onClick={() => {
                      navigate('/administrator/subriskfactors/form', {
                        state: { type_action: 'add' }
                      })
                    }}
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                  >
                    {t('เพิ่มปัจจัยความเสี่ยงย่อย')}
                  </Button>
                ) : (
                  <></>
                )
              }
            />
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  )
}
export default SubRiskFactorList
