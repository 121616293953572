import { Input } from 'antd'
import React, { useEffect, useState } from 'react'
import './ColorPickerStyle.scss'

export const ColorPicker = (props) => {
  const { value, disabled, handleChange, color: prop_color } = props
  const [color, setColor] = useState(prop_color)

  useEffect(() => {
    value && setColor(value)
  }, [value])

  return (
    <>
      <Input.Group compact>
        <Input style={{ width: '80%' }} value={color} disabled={disabled} />
        <Input
          disabled={disabled}
          style={{ backgroundColor: color }}
          type="color"
          value={color}
          onChange={(e) => {
            setColor(e?.target?.value)
            handleChange(e?.target?.value)
          }}
        />
      </Input.Group>
    </>
  )
}
