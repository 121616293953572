import styled from 'styled-components'

// import { colors } from '../../configs/styles.config'

export const RowStyle = styled.div`
  .table-row-color {
    background-color: #f2f7fc;
  }
  .is-expire {
    color: red;
  }
`
