import { Trans } from 'react-i18next'

export const racMatrixApproveListCrumb = [
  { label: <Trans>งานปฎิบัติการตรวจสอบ</Trans>, path: '/auditoperations/racmatrixapprove' },
  { label: <Trans>อนุมัติความเสี่ยงและการควบคุม</Trans> },
]

export const racMatrixApproveInfoCrumb = [
  { label: <Trans>งานปฎิบัติการตรวจสอบ</Trans>, path: '/auditoperations/racmatrixapprove' },
  { label: <Trans>อนุมัติความเสี่ยงและการควบคุม</Trans> },
]
