import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { Form } from 'antd'
import { Radio, RadioGroup } from '../../../../components/radio/Radio'
import { Trans } from 'react-i18next'
import _ from 'lodash'
import { Alert } from '../../../../components/alert/Alert'

export const Columns = (dataLists, setDataLists, form, type_action, disabled, setChangeValue) => {
  const { setFieldsValue, getFieldValue } = form

  const setRadioRole = (radioRole, index) => {
    let _radioRole = radioRole.filter((item, index2) => {
      return index !== index2
    })

    setFieldsValue({
      radioRole: _radioRole,
    })
  }
  return [
    {
      title: <Trans>ลำดับ</Trans>,
      dataIndex: 'key',
      // sorter: true,
      width: '5%',
      className: 'text-nowrap',
      align: 'center',
      render: (item, record, index) => {
        return index + 1
      },
    },
    {
      title: <Trans>รหัสบุคลากร</Trans>,
      dataIndex: 'personnelCode',
      // sorter: true,
      width: '10%',
      className: 'text-nowrap',
    },
    {
      title: <Trans>ชื่อ-นามสกุล</Trans>,
      dataIndex: 'personnelName',
      // sorter: true,
      width: '25%',
      className: 'text-nowrap',
    },
    {
      title: <Trans>หน่วยงาน</Trans>,
      dataIndex: 'organization',
      // sorter: true,
      width: '20%',
      className: 'text-nowrap',
    },
    {
      title: <Trans>บทบาทในทีม</Trans>,
      children: [
        {
          title: <Trans>หัวหน้าทีม</Trans>,
          dataIndex: 'auditTeamRole',
          width: '10%',
          className: 'text-nowrap',
          align: 'center',
          render: (item, record, index) => {
            return (
              <Form.Item
                name={['radioRole', index]}
                style={{ margin: 0 }}
                wrapperCol={{
                  md: 24,
                }}
              >
                <RadioGroup>
                  <Radio value={1} disabled={disabled} />
                </RadioGroup>
              </Form.Item>
            )
          },
        },
        {
          title: <Trans>ผู้ตรวจสอบ</Trans>,
          dataIndex: 'auditTeamRole',
          width: '10%',
          className: 'text-nowrap',
          align: 'center',
          render: (item, record, index) => {
            return (
              <Form.Item
                name={['radioRole', index]}
                style={{ margin: 0 }}
                initialValue={item}
                wrapperCol={{
                  md: 24,
                }}
              >
                <RadioGroup>
                  <Radio value={2} disabled={disabled} />
                </RadioGroup>
              </Form.Item>
            )
          },
        },
        {
          title: <Trans>ผู้สนับสนุน</Trans>,
          dataIndex: 'auditTeamRole',
          width: '10%',
          className: 'text-nowrap',
          align: 'center',
          render: (item, record, index) => {
            return (
              <Form.Item
                name={['radioRole', index]}
                style={{ margin: 0 }}
                wrapperCol={{
                  md: 24,
                }}
              >
                <RadioGroup>
                  <Radio value={3} disabled={disabled} />
                </RadioGroup>
              </Form.Item>
            )
          },
        },
      ],
    },
    {
      title: <Trans></Trans>,
      dataIndex: 'tools',
      width: '10%',
      align: 'center',
      render: (item, record, index) => {
        return (
          type_action === 'edit' && (
            <div style={{ textAlign: 'center' }}>
              <ButtonGroup
                menu={'AT04'}
                icons={[
                  {
                    type: 'delete',
                    onClick: () => {
                      if (record.inUsed) {
                        Alert({
                          type: 'error',
                          title: <Trans>ไม่สามารถลบได้</Trans>,
                          content: <Trans>บุคคลนี้ถูกมอบหมายแล้ว</Trans>,
                          onOk() {},
                        })
                      } else {
                        if (dataLists.length > 1) {
                          setDataLists([..._.filter(dataLists, (e) => e.key !== record.key)])
                          setRadioRole(getFieldValue('radioRole'), index)
                        } else {
                          setDataLists([])
                        }
                        setChangeValue(true)
                      }
                    },
                  },
                ]}
              />
            </div>
          )
        )
      },
    },
  ]
}
