import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'antd'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'

import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { InputNew } from '../../../components/input/Input'
import { Radio, RadioGroup } from '../../../components/radio/Radio'
import { TextAreaNew } from '../../../components/textarea/TextArea'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
// import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { encryptInput } from '../../../utilitys/crypto'
import { LabelNew } from '../../../components/label/Label'
import { CardNew } from '../../../components/card/Card'
import { ButtonNew } from '../../../components/button/Button'
import { TabNew } from '../../../components/tab/Tab'
import { IconNew } from '../../../components/icon/Icon'
import { displayError, displayText, regexEngNoSpace } from '../../../utilitys/helper'

import { SAVE_RISK_PROCESS } from './graphql/Mutation'
import RateRiskFactorRiskProcessList from './rateriskfactorprocess/RateRiskFactorProcessList'
import RateRiskProcessList from './rateriskprocess/RateRiskProcessList'
import RateRiskRulesList from './rateriskrules/RateRiskRulesList'
import RateRiskControlList from './rateriskcontrol/RateRiskControlList'
import { GET_RISK_PROCESS } from './graphql/Query'

const RiskProcessForm = ({ menu }) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const navigate = useNavigate()

  const [editable, seteditable] = useState(true)
  const [
    focusTab,
    // setfocusTab
  ] = useState('1')
  const { state } = useLocation()
  if (!state) {
    return <Navigate to={'/administrator/riskprocess'} />
  }

  const { type_action: mainFormType = 'view', refCode: rateFacGroupCode } = state

  const [callGet, { loading: loadingGet, data: dataGet, error: errorGet }] = useLazyQuery(GET_RISK_PROCESS)

  useEffect(() => {
    if (errorGet) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorGet)),
      })
    }
  }, [errorGet])

  useEffect(() => {
    if (dataGet) {
      const field = dataGet.getRiskProcess
      field.rateFacGroupName = displayText(field.rateFacGroupNameEN, field.rateFacGroupNameTH)

      form.setFieldsValue(field)
    }
  }, [dataGet])

  useEffect(() => {
    if (rateFacGroupCode) {
      const dataInput = {
        rateFacGroupCode: rateFacGroupCode,
      }
      callGet({
        variables: {
          input: encryptInput(dataInput),
        },
      })

      if (mainFormType === 'view') seteditable(false)
    }
  }, [rateFacGroupCode])

  const tabs = [
    // Import components content
    {
      key: 1,
      name: t('ปัจจัยความเสี่ยงกระบวนการ'),
      disabled: false,
      content: () => {
        return (
          <RateRiskFactorRiskProcessList
            focusTab={focusTab}
            mainFormType={mainFormType}
            rateFacGroupCode={rateFacGroupCode}
            menu={menu}
          />
        )
      },
    },
    {
      key: 2,
      name: t('ความเสี่ยง'),
      disabled: false,
      content: () => (
        <RateRiskProcessList
          focusTab={focusTab}
          mainFormType={mainFormType}
          rateFacGroupCode={rateFacGroupCode}
          menu={menu}
        />
      ),
    },
    {
      key: 3,
      name: t('เกณฑ์ ระเบียบ คำสั่ง'),
      disabled: false,
      content: () => (
        <RateRiskRulesList
          focusTab={focusTab}
          mainFormType={mainFormType}
          rateFacGroupCode={rateFacGroupCode}
          menu={menu}
        />
      ),
    },
    {
      key: 4,
      name: t('วิธีการควบคุม'),
      disabled: false,
      content: () => (
        <RateRiskControlList
          focusTab={focusTab}
          mainFormType={mainFormType}
          rateFacGroupCode={rateFacGroupCode}
          menu={menu}
        />
      ),
    },
  ]

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_RISK_PROCESS)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        onOk() {},
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/administrator/riskprocess')
        },
      })
    }
  }, [dataSave])

  const onFinish = () => {
    const field = form.getFieldValue()

    const { rateFacGroupCode, rateFacGroupNameTH, rateFacGroupNameEN = null, isActive } = field

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        const dataInput = {
          rateFacGroupCode,
          rateFacGroupNameTH: rateFacGroupNameTH,
          rateFacGroupNameEN: rateFacGroupNameEN,
          isActive,
          typeAction: 'edit',
        }
        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const renderIconTitle = () => {
    if (mainFormType === 'view') return 'FileText'
    if (mainFormType === 'edit') return 'Edit2'
    else return 'Plus'
  }

  const renderTextTitle = () => {
    if (mainFormType === 'view') return t('ดูจัดการข้อมูลกลุ่มความเสี่ยงกระบวนการ')
    return t('แก้ไขจัดการข้อมูลกลุ่มความเสี่ยงกระบวนการ')
  }

  const arrCrumb = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/riskprocess' },
    { label: t('จัดการข้อมูลกลุ่มความเสี่ยงกระบวนการ') },
    { label: renderTextTitle() },
  ]

  return (
    <React.Fragment>
      {(loadingSave || loadingGet) && <SpinnersNew />}
      <Breadcrumb data={arrCrumb} title={t('จัดการข้อมูลกลุ่มความเสี่ยงกระบวนการ')} />
      <CardNew topic={renderTextTitle()} icon={renderIconTitle()}>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 9,
            lg: 9,
            xl: 7,
          }}
          wrapperCol={{
            md: 9,
            lg: 9,
          }}
          layout="horizontal"
          name="riskFactorForm"
        >
          <Form.Item
            name="rateFacGroupCode"
            label={t('รหัสกลุ่มความเสี่ยงกระบวนการ')}
            rules={[
              { required: true, message: t('กรุณาระบุ') },
              {
                pattern: regexEngNoSpace,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษพิมพ์ใหญ่,ตัวเลข A-Z, 0-9 ไม่เกิน 10 ต้ว`),
              },
            ]}
          >
            <InputNew maxLength={10} disabled={true} placeholder="กรอกรหัสปัจจัยความเสี่ยง" />
          </Form.Item>
          <Form.Item
            name="rateFacGroupNameTH"
            label={t('ชื่อกลุ่มความเสี่ยงกระบวนการภาษาไทย')}
            rules={[{ required: true, message: t('กรุณาระบุ') }]}
          >
            <TextAreaNew disabled={!editable} maxLength={8000} rows={5} />
          </Form.Item>
          <Form.Item name="rateFacGroupNameEN" label={t('ชื่อกลุ่มความเสี่ยงกระบวนการภาษาอังกฤษ')} rules={[]}>
            <TextAreaNew disabled={!editable} maxLength={8000} rows={5} />
          </Form.Item>

          <Form.Item name="isActive" label={t('สถานะ')} initialValue={1}>
            <RadioGroup disabled={!editable}>
              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                </Col>
                <Col>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>

          <Row gutter={[12, 12]} align="middle" style={{ marginTop: 45, marginBottom: 10 }}>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconNew icon={'List'} size={24} color="" />
            </Col>
            <Col>
              <LabelNew type="title-card">{t('รายการปัจจัยความเสี่ยงกระบวนการ')}</LabelNew>
            </Col>
          </Row>
        </Form>
        <TabNew
          tab={tabs}
          defaultActiveKey="1"
          // onTabClick={(value) => {
          //   setfocusTab(value)
          // }}
        />

        <Row gutter={[12, 12]} justify="center" style={{ marginBottom: 20, marginTop: 20 }}>
          {editable && (
            <Col>
              <ButtonNew
                onClick={() => {
                  form.validateFields().then(() => onFinish())
                }}
                type="primary"
                htmlType="submit"
                roles={{ type: 'add', menu: menu }}
              >
                {t('บันทึก')}
              </ButtonNew>
            </Col>
          )}
          <Col>
            <ButtonNew onClick={() => navigate('/administrator/riskprocess')} type="back">
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Col>
        </Row>
      </CardNew>
    </React.Fragment>
  )
}
export default RiskProcessForm
