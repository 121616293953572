import { Trans } from 'react-i18next'

export const breadcrumbList = [
  { label: <Trans>งานปฏิบัติการตรวจสอบ</Trans>, path: '/auditoperations/createassessmentform' },
  { label: <Trans>จัดการแบบประเมิน</Trans> },
]

export const breadcrumbDetail = [
  { label: <Trans>งานปฏิบัติการตรวจสอบ</Trans>, path: '/auditoperations' },
  { label: <Trans>จัดการแบบประเมิน</Trans>, path: '/auditoperations/createassessmentform' },
  { label: <Trans>เพิ่มแบบประเมิน</Trans>, path: '' },
]
