import { gql } from '@apollo/client'

export const GET_AUDITPLAN_SELECT = gql`
  query getAuditPlansSelect($input: AuditPlansInput!) {
    getAuditPlansSelect(data: $input) {
      planCode
      planNameTH
      planNameEN
    }
  }
`
