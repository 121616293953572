import { useLazyQuery } from '@apollo/client'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Status } from '../../../components/status/Status'
import { encryptInput } from '../../../utilitys/crypto'

import { displayError, displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import SendEmailReportModal from '../components/SendEmailReportModal'
import { ExpandStyle } from './css/Styles'
import RiskResultListFilter from './filters/RiskAssessmentReportFilter'
import { RICK_RESULT_EXPORT } from './graphql/Query'
import RiskAssessmentReportModal from './modal/RiskAssessmentReportModal'
const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24
}
const RiskAssessmentReport = (props) => {
  const { t } = useTranslation()
  const { menu } = props
  const [dataLists, setDataLists] = useState([])
  const [operationStatusCode, setOperationStatusCode] = useState([])
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' }
  ])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [reFetch, setReFetch] = useState(false)
  const [data, setData] = useState({})
  const [modal, setModal] = useState(false)
  /* สำหรับการส่งเมลรายงาน */
  const [visibleSendMail, setVisibleSendMail] = useState(false)
  const [mailValue, setMailValue] = useState()
  const [inputSendEmail, setInputSendEmail] = useState()

  const [riskResultExportFn, riskResultExport] = useLazyQuery(RICK_RESULT_EXPORT)

  useEffect(() => {
    if (riskResultExport.data) {
      Alert({
        type: 'success',
        title: t('ส่งอีเมลสำเร็จ'),
        onOk() {}
      })
    }

    if (riskResultExport.error) {
      Alert({
        type: 'error',
        title: t('ส่งอีเมลไม่สำเร็จ'),
        content: t(displayError(riskResultExport.error)),
        onOk() {}
      })
    }
  }, [riskResultExport.data])

  /* สำหรับการส่งเมลรายงาน */
  useEffect(() => {
    if (inputSendEmail) {
      const dataInput = {
        riskResultId: mailValue.refCode,
        riskFormCode: data?.riskFormCode,
        fileType: inputSendEmail?.fileType || false,
        isTypeAction: data.type,
        waterMark: inputSendEmail?.waterMark || '',
        lang: localStorage.getItem('lang'),
        emailData: inputSendEmail // สำหรับการส่งเมลรายงาน
      }
      // Call API Export
      riskResultExportFn({
        variables: {
          input: encryptInput(dataInput)
        }
      })
    }
  }, [inputSendEmail])

  const Columns = [
    {
      title: t('ประจำปี'),
      dataIndex: 'Year',
      width: '10%'
    },
    {
      title: t('รหัส'),
      dataIndex: 'riskFormCode',
      width: '10%'
    },
    {
      title: t('ชื่อแบบประเมิน'),
      dataIndex: 'riskFormName',
      width: '30%'
    },
    {
      title: t('ประเภทประเมิน'),
      dataIndex: 'riskFormType',
      width: '15%',
      render: (row) => {
        return row === 1 ? (
          <>
            <span>{t('ประเมินสรุป')}</span>
          </>
        ) : (
          <>
            <span>{t('ประเมินรายหน่วยงาน')}</span>
          </>
        )
      }
    },
    {
      title: t('สถานะ'),
      dataIndex: 'operationStatusCode',
      width: '10%',
      render: (_operationStatusCode) => {
        switch (_operationStatusCode) {
          case '10':
            return <Status text={'ยังไม่ประเมิน'} />
          case '20':
            return <Status text={'กำลังประเมิน'} />
          case '30':
            return <Status text={'ประเมินเสร็จสิ้น'} />
          default:
            return <></>
        }
      }
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '10%',
      render: (item) => {
        const data_ = {
          riskResultId: item.riskResultId,
          riskFormCode: item.riskFormCode,
          riskFormTH: item.riskFormTH,
          riskFormEN: item.riskFormEN,
          type: 'unit'
        }
        const btn = [
          {
            type: 'download',
            onClick: () =>
              Promise.all([setOperationStatusCode(item.operationStatusCode), setData(data_), setModal(true)])
          },
          {
            type: 'settings',
            onClick: () => {
              setVisibleSendMail(true)
              setData(data_)
              setMailValue({ reportName: 'รายงานผลของการประเมินความเสี่ยง', refCode: item.riskResultId, filters: item })
            }
          }
        ]
        return <ButtonGroup menu={menu} icons={btn} />
      }
    }
  ]
  function getDataListFromFilter(value) {
    setDataLists([
      ...value.map((item, idx) => {
        return {
          key: idx + 1,
          Year: item.Year,
          riskFormCode: item.riskFormCode,
          riskResultId: item.riskResultId,
          riskFormName: displayText(item.riskFormTH, item.riskFormEN),
          riskFormType: item.riskFormType,
          riskModelCode: item.riskModelCode,
          operationStatusCode: item.operationStatusCode,
          operationStatusCodeBtn: item.operationStatusCode,
          tools: item
        }
      })
    ])
  }
  const breadcrumbList = [{ label: t('รายงาน'), path: '/reports' }, { label: t('รายงานผลของการประเมินความเสี่ยง') }]
  return (
    <>
      {riskResultExport.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('รายงานผลของการประเมินความเสี่ยง')} />
      <RiskResultListFilter
        page={page}
        limit={limit}
        menu={menu}
        sort={sort}
        reFetch={reFetch}
        getCount={(e) => setCount(e)}
        getValue={(v) => getDataListFromFilter(v)}
      />
      <CardNew topic={t('รายการแบบประเมินความเสี่ยง')} icon="List" toggledrop={'false'}>
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <ExpandStyle>
              <Datatable
                columns={Columns}
                data={dataLists}
                paginationCustom={true}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                total={count}
                pageSize={limit}
                page={page}
                handleTableChange={(e) => {
                  const reNewField = [{ riskFormName: displayText('riskFormTH', 'riskFormEN') }]
                  setSort(handleSort(e?.sorter, reNewField))
                }}
              />
            </ExpandStyle>
          </Col>
        </Row>
      </CardNew>
      <RiskAssessmentReportModal
        data={data}
        operationStatusCode={operationStatusCode}
        menu={menu}
        open={modal}
        onSuccess={(e) => {
          setReFetch(reFetch ? false : true)
          setModal(e)
        }}
        close={(e) => setModal(e)}
      />
      <SendEmailReportModal
        menu={menu}
        open={visibleSendMail}
        close={(e) => {
          setVisibleSendMail(e)
        }}
        fileType={{ pdf: true, word: true, excel: true, csv: true }}
        setInputSendEmail={(e) => {
          setInputSendEmail(e)
        }}
        mailValue={mailValue}
      />
    </>
  )
}

export default RiskAssessmentReport
