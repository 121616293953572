import { gql } from '@apollo/client'

export const GET_MASTER_CHOICE_TYPES = gql`
  query getMasterChoiceTypes($input: MasterChoiceTypesInput!) {
    getMasterChoiceTypes(data: $input) {
      choiceTypeId
      choiceTypeCode
      choiceTypeNameTH
      choiceTypeNameEN
      isActive
    }
  }
`
