import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import * as _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { Columns } from './utils/RiskLevelColumns'
import { displayError } from '../../../utilitys/helper'
import { Status } from '../../../components/status/Status'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import RiskLevelModal from './RiskLevelModal'
import { GET_RISKLEVEL_PAGINATION } from './graphql/Query'
import { DELETE_RISKLEVEL } from './graphql/Mutation'
import { encryptInput } from '../../../utilitys/crypto'

const RiskLevelList = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const Responsive = {
    md: 24,
    lg: 24,
  }
  const [visible, setVisible] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [data, setData] = useState()

  /* ---------------- API ----------- */
  const [getRiskLevelFn, dataRiskLevelList] = useLazyQuery(GET_RISKLEVEL_PAGINATION, {})
  const [deleteRiskLevelFn, dataDeleteRiskLevel] = useMutation(DELETE_RISKLEVEL, {})

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    getRiskLevelFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          filters: {},
          pagination: { limit, page },
          sort: [],
        },
      },
    })
  }, [getRiskLevelFn, page, limit])

  useEffect(() => {
    if (dataDeleteRiskLevel.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          dataRiskLevelList.refetch()
        },
      })
    }

    if (dataDeleteRiskLevel.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(dataDeleteRiskLevel?.error)),
        onOk() {},
      })
    }
  }, [dataDeleteRiskLevel.data])

  useEffect(() => {
    if (dataRiskLevelList?.data) {
      let response = dataRiskLevelList?.data?.getMasterRiskLevelPagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data, index) => {
          return {
            ...data,
            key: index + 1,
            isActive: <Status text={data.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => {
                        setVisible(true), setData({ riskLevelCode: data.riskLevelCode, type_action: 'view' })
                      },
                    },
                    {
                      type: 'edit',
                      onClick: () => {
                        setVisible(true), setData({ riskLevelCode: data.riskLevelCode, type_action: 'edit' })
                      },
                    },
                    {
                      type: 'delete',
                      onClick: () => showConfirmDelete(data.riskLevelCode),
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }

    if (dataRiskLevelList.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(dataRiskLevelList?.error)),
        onOk() {},
      })
    }
  }, [dataRiskLevelList?.data])

  /* ---------------- ACTION ----------- */
  const showConfirmDelete = (riskLevelCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteRiskLevelFn({
          variables: {
            riskLevelCode: encryptInput(riskLevelCode),
          },
        })
      },
      onCancel() {},
    })
  }

  return (
    <>
      {(dataRiskLevelList.loading || dataDeleteRiskLevel.loading) && <SpinnersNew />}
      <Col {...Responsive}>
        <CardNew topic={t('จัดการระดับความเสี่ยง')} icon="AlignJustify" toggledrop={'false'}>
          <Row gutter={[12, 12]}>
            <Col {...Responsive}>
              <Datatable
                columns={Columns}
                data={[...dataLists]}
                total={count}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                paginationCustom={true}
                searchCustom={true}
                pageSize={limit}
                page={page}
                // enableScroll={{ x: true, y: true }}
                scroll={{ x: 1000, y: false }}
                btnAdd={
                  <>
                    <ButtonNew
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: menu }}
                      onClick={() => setVisible(true)}
                    >
                      {t('เพิ่มระดับความเสี่ยง')}
                    </ButtonNew>
                  </>
                }
              />
            </Col>
          </Row>
        </CardNew>
      </Col>
      <RiskLevelModal
        open={visible}
        close={(e) => {
          setVisible(e)
          setData()
        }}
        refetch={() => dataRiskLevelList.refetch()}
        dataParams={data}
        menu={menu}
      />
    </>
  )
}

export default RiskLevelList
