import React, { useEffect, useState } from 'react'
import { Column } from '@ant-design/plots'
import _ from 'lodash'
import { numberFormat } from '../../../../../../utilitys/helper'
import { colors } from '../../../../../../configs/styles.config'

export const GraphColumn2 = (props) => {
  const { data } = props
  const [dataGraph, setDataGraph] = useState([])

  const colorMap = {
    สูงมาก: '#0C6ABF',
    สูง: '#EB895F',
    กลาง: colors.drakGreen,
    ต่ำ: '#2b4b91',
    'Very High': '#0C6ABF',
    High: '#EB895F',
    Mediumn: colors.drakGreen,
    Low: '#2b4b91',
  }

  const [configs, setConfigs] = useState({
    data: [],
    isStack: false,
    xField: 'year',
    yField: 'value',
    seriesField: 'type',
    isGroup: 'true',
    color: ['#0C6ABF', '#EB895F', colors.drakGreen, '#2b4b91'],
    label: {
      position: 'middle',
      layout: [
        {
          type: 'interval-adjust-position',
        },
        {
          type: 'interval-hide-overlap',
        },
        {
          type: 'adjust-color',
        },
      ],
      content: (item) => {
        return item.value
      },
    },
    tooltip: {
      formatter: (d) => {
        return { name: d.type, value: numberFormat(d.value) }
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'bottom',
    },
  })

  useEffect(() => {
    setConfigs({
      ...configs,
      data: dataGraph,
    })
  }, [dataGraph])

  useEffect(() => {
    setDataGraph(data)

    if (!_.isEmpty(data)) {
      setConfigs({
        ...configs,
        color: (data) => colorMap[data.type],
      })
    }
  }, [data])

  return !_.isEmpty(data) ? <Column {...configs} /> : null
}
