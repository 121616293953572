import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../utilitys/crypto'
import { yearDisplay } from '../../../utilitys/dateHelper'
import { displayText } from '../../../utilitys/helper'
import { colors } from '../../../configs/styles.config'
import { CardNew } from '../../../components/card/Card'
import { SpinnersNew } from '../../../components/spinners/Spinners'
// import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonNew } from '../../../components/button/Button'
import { Select } from '../../../components/select/Select'
import { LabelNew as Label } from '../../../components/label/Label.jsx'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { GETGROUPSANDQUESTIONSBY_RESULT_ASSESSMENT, GET_SUB_RESULT_ASSESSMENT } from './graphql/Query'
import { Columns } from './utils/GroupsAndQuestionsColumn'

const ResultAssessmentDetails = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const navigate = useNavigate()
  const responsiveLabel = { sm: 10, md: 6, lg: 6 }
  const responsiveData = { sm: 14, md: 18, lg: 18 }

  if (!state && !state?.formCode) return <Navigate to={'/auditoperations/assessmentformresults'} />

  const arrCrumb = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/assessmentformresults' },
    { label: t('ผลของการประเมิน'), path: '/auditoperations/assessmentformresults' },
    { label: t('รายละเอียดแบบประเมิน') },
  ]

  const [dataList, setDataList] = useState([])
  const [data, setData] = useState(null)
  const [organization, setOrganization] = useState([])
  const [getFullname, setFullname] = useState('')

  const [getSubResultAssessmentFn, { loading: loadingSubResultAssessment, data: dataResults }] =
    useLazyQuery(GET_SUB_RESULT_ASSESSMENT)
  const [
    getGroupsAndQuestionsByResultAssessmentFn,
    { loading: loadingGroupsAndQuestionsByResultAssessment, data: getGroupsAndQuestionsByResultAssessment },
  ] = useLazyQuery(GETGROUPSANDQUESTIONSBY_RESULT_ASSESSMENT)

  useEffect(() => {
    if (state?.formResultId !== '' && state?.type_action === 'view') {
      setData({
        formYear: state?.data.formYear,
        formCode: state?.data.formCode,
        formName: displayText(state?.data.formNameTH, state?.data.formNameEN),
        organizationName: displayText(state?.data.organizationNameTH, state?.data.organizationNameEN),
      })
      setFullname(displayText(state?.data.fullnameTH, state?.data.fullnameEN))
      callFn(state?.formResultId)
    }
    if (state?.formResultId === '' && state?.type_action === 'view') {
      getSubResultAssessmentFn({ variables: { input: encryptInput({ formCode: [state.formCode] }) } })
    }
  }, [state.formCode, state.formResultId, state.type_action])

  useEffect(() => {
    if (dataResults?.getSubResultAssessment) {
      const result = dataResults?.getSubResultAssessment
      const orgFilter = _.filter(result, function (o) {
        if (o.operationStatusCode !== '50') return o
      })

      const getOrgs = _.map(orgFilter, (d) => ({
        label: `${d.organizationCode}: ${displayText(d.organizationNameTH, d.organizationNameEN)}`,
        value: d.formResultId,
        fullname: displayText(d.fullnameTH, d.fullnameEN),
      }))

      const dataTopic = {}
      for (const d of result) {
        dataTopic.formYear = d.formYear
        dataTopic.formCode = d.formCode
        dataTopic.formName = displayText(d.formNameTH, d.formNameEN)
        dataTopic.organizationName = displayText(d.organizationNameTH, d.organizationNameEN)
      }

      setOrganization(getOrgs)
      setData(dataTopic)
    }
  }, [dataResults?.getSubResultAssessment])

  const callFn = (value) => {
    getGroupsAndQuestionsByResultAssessmentFn({
      variables: {
        input: encryptInput({
          formResultId: value,
        }),
      },
    })
  }

  useEffect(() => {
    if (getGroupsAndQuestionsByResultAssessment) {
      const res = getGroupsAndQuestionsByResultAssessment?.getGroupsAndQuestionsByResultAssessment
      const dataGroup = []
      const data = []
      _.map(res, (dt, key) => {
        data.push({
          resultDetailId: dt.resultDetailId,
          formResultId: dt.formResultId,
          formGroupId: dt.formGroupId,
          formQuestId: dt.formQuestId,
          choiceTypeCode: dt.choiceTypeCode,
          formRequired: dt.formRequired,
          choiceDetailId: dt.choiceDetailId,
          answerDetail: dt.answerDetail,
          files: dt.files,
        })
        const checkdup = _.find(dataGroup, function (o) {
          return dt.formGroupId === o.formGroupId && dt.formSort === o.formSort
        })
        if (!checkdup) {
          dataGroup.push({
            formGroupId: dt.formGroupId,
            formSort: dt.formSort,
            formGroupNameTH: dt.formGroupNameTH,
            formGroupNameEN: dt.formGroupNameEN,
            formQuestions: [],
          })
        }
        const groupIndex = _.findIndex(dataGroup, function (o) {
          return dt.formGroupId === o.formGroupId && dt.formSort === o.formSort
        })
        dataGroup[groupIndex].formQuestions.push({
          key: key,
          resultDetailId: dt.resultDetailId,
          formResultId: dt.formResultId,
          formGroupSort: dt.formSort,
          formQuestId: dt.formQuestId,
          formSort: dt.formSortByQ,
          formQuestNameTH: dt.formQuestNameTH,
          formGroupNameEN: dt.formQuestNameTH,
          formQuestType: dt.formQuestType,
          formRequired: dt.formRequired,
          choiceTypeCode: dt.choiceTypeCode,
          choiceGroupCode: dt.choiceGroupCode,
          choiceDetailId: dt.choiceDetailId,
          choiceDetailNameTH: dt.choiceDetailNameTH,
          choiceDetailNameEN: dt.choiceDetailNameEN,
          answerDetail: dt.answerDetail,
          files: dt.files ? [parseInt(dt.files)] : [],
          fileNameLabel: dt.fileNameLabel,
          fileNameGen: dt.fileNameGen,
          fileType: dt.fileType,
          fileByte: dt.fileByte,
          filepath: dt.filepath,
        })
      })
      setDataList(dataGroup)
    }
  }, [getGroupsAndQuestionsByResultAssessment])

  return (
    <>
      {(loadingSubResultAssessment || loadingGroupsAndQuestionsByResultAssessment) && <SpinnersNew />}
      <Col sm={24} md={24} lg={24}>
        <Breadcrumb data={arrCrumb} title={t('รายละเอียดแบบประเมิน')} />
        <CardNew topic={t('รายละเอียดการประเมิน')} icon="Info" toggledrop={'false'}>
          <Col span={24} offset={2}>
            <Row gutter={[16, 24]}>
              <Col {...responsiveLabel}>{t('ประจำปี')}</Col>
              <Col {...responsiveData} style={{ color: colors.primary }}>
                {yearDisplay(data?.formYear)}
              </Col>

              <Col {...responsiveLabel}>{t('ชื่อแบบประเมิน')}</Col>
              <Col {...responsiveData} style={{ color: colors.primary }}>
                {data?.formName}
              </Col>

              {state?.formResultId !== '' && state?.type_action === 'view' && (
                <>
                  <Col {...responsiveLabel}>{t('หน่วยงานรับตรวจ')}</Col>
                  <Col {...responsiveData} style={{ color: colors.primary }}>
                    {state?.formResultId !== '' ? (
                      data?.organizationName
                    ) : (
                      <Form autoComplete="off" name="organizationForm">
                        <Form.Item>
                          <Select
                            data={organization}
                            placeholder={t('เลือกหน่วยรับตรวจ')}
                            scrollableId={'organizationForm'}
                            showSearch
                            loading={loadingSubResultAssessment}
                            filterOption={(input, option) =>
                              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            onChange={(e) => {
                              const result = _.find(organization, ['value', e])
                              setFullname(result.fullname)
                              callFn(result.value)
                            }}
                          />
                        </Form.Item>
                      </Form>
                    )}
                  </Col>

                  <Col {...responsiveLabel}>{t('ชื่อผู้ทำการประเมิน')}</Col>
                  <Col {...responsiveData} style={{ color: colors.primary }}>
                    {getFullname}
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </CardNew>
        {getFullname !== '' ? (
          <CardNew topic={t('รายการคำถามประเมินการตรวจสอบ')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col sm={24} md={24} lg={24} xl={24}>
                {dataList.length > 0
                  ? dataList.map((dt, key) => {
                      return (
                        <React.Fragment key={key}>
                          <br />
                          <Label type="body-header">{`${dt.formSort}. ${displayText(
                            dt.formGroupNameTH,
                            dt.formGroupNameEN,
                          )}`}</Label>
                          <br />
                          <Table columns={Columns()} dataSource={dt.formQuestions} pagination={false} />
                        </React.Fragment>
                      )
                    })
                  : ''}

                <Row justify="center" style={{ marginTop: '2rem' }}>
                  <Col>
                    <ButtonNew type="back" onClick={() => navigate('/auditoperations/assessmentformresults')}>
                      {t('ย้อนกลับ')}
                    </ButtonNew>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardNew>
        ) : (
          <Row justify="center" style={{ marginTop: '2rem' }}>
            <Col>
              <ButtonNew type="back" onClick={() => navigate('/auditoperations/assessmentformresults')}>
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          </Row>
        )}
      </Col>
    </>
  )
}

export default ResultAssessmentDetails
