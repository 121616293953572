import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_SELECT_RATE_RISK_RULE } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectRateRiskRule = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { isActive, rateFacGroupCode } = filters || {}

  const [isData, isSetData] = useState([])

  useEffect(() => {
    callGet({ variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) } })
  }, [isActive, rateFacGroupCode])

  const [callGet, { data, loading }] = useLazyQuery(GET_SELECT_RATE_RISK_RULE)

  useEffect(() => {
    if (data) {
      isSetData(
        data?.getSelectRateRiskRule.map((item) => {
          let label = displayText(item.rateRiskRuleTH, item.rateRiskRuleEN)
          if (!label) label = item.rateRiskRuleTH
          return {
            value: item.rateRiskRuleCode,
            label: `${item.rateRiskRuleCode} : ${label}`,
            source: item,
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        loading={loading}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectRateRiskRule
