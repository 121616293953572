import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_CONTROL_RESULT_ID, GET_MASTER_ORGANIZATIONS_FILTERS } from '../../graphql/Query'
import { FORWARD_CONTROL_RESULT } from '../../graphql/Mutation'
import { encryptInput } from '../../../../../utilitys/crypto'
import { yearDisplay, dateDisplay } from '../../../../../utilitys/dateHelper'
import { displayText, displayError } from '../../../../../utilitys/helper'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'
import { ModalNew } from '../../../../../components/modal/Modal'
import { Select } from '../../../../../components/select/Select'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { ButtonNew } from '../../../../../components/button/Button'
import { Alert } from '../../../../../components/alert/Alert'

const SendFormModal = (props) => {
  const formname = 'sendControlResult'
  const formItemLayout = {
    labelCol: { sm: 14, md: 7, lg: 7, xl: 7 },
    wrapperCol: { sm: 16, md: 19, lg: 19, xl: 19, align: 'left' },
    Col: { sm: 24, md: 16, lg: 18 },
  }

  const { open, close, controlResultId, getForwardControlResult } = props

  const { t } = useTranslation()
  const [user] = useDecodeUser()
  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)
  const [data, setData] = useState(null)
  const [organizationCode, setOrganizationCode] = useState(null)

  const [forwardControlResultFn, forwardControlResult] = useMutation(FORWARD_CONTROL_RESULT)
  const [getControlResultsIdFn, { loading: loadingControlResultById, data: dataResult }] =
    useLazyQuery(GET_CONTROL_RESULT_ID)
  const [getMasterOrganizationsFiltersFn, getMasterOrganizationsFilters] = useLazyQuery(
    GET_MASTER_ORGANIZATIONS_FILTERS,
  )

  useEffect(() => {
    if (controlResultId) {
      getControlResultsIdFn({ variables: { controlResultId: encryptInput(parseInt(controlResultId)) } })
    }
  }, [controlResultId])

  useEffect(() => {
    if (organizationCode) {
      getMasterOrganizationsFiltersFn({
        variables: { input: encryptInput({ organizationCode: organizationCode, responsibility: true }) },
      })
    }
  }, [organizationCode])

  useEffect(() => {
    if (dataResult) {
      const result = dataResult?.getControlResultById

      setOrganizationCode(result?.organizationCode)

      const fullName = result?.makerBy
        ? displayText(
            `${result.n2_namePrefixTH}${result.p2_firstNameTH} ${result.p2_lastNameTH}`,
            `${result.n2_namePrefixEN}${result.p2_firstNameEN} ${result.p2_lastNameEN}`,
          )
        : displayText(
            `${result.n1_namePrefixTH}${result.p1_firstNameTH} ${result.p1_lastNameTH}`,
            `${result.n1_namePrefixEN}${result.p1_firstNameEN} ${result.p1_lastNameEN}`,
          )

      setData({
        controlFormYear: result.controlFormYear,
        controlFormCode: result.controlFormCode,
        controlFormName: displayText(result.controlFormTH, result.controlFormEN),
        organizationName: displayText(result.organizationNameTH, result.organizationNameEN),
        fullName: fullName,
        dateStart: dateDisplay(result?.dateStart),
        dateEnd: dateDisplay(result?.dateEnd),
      })
    }
  }, [dataResult])

  useEffect(() => {
    setVisible(open)
  }, [open])

  useEffect(() => {
    if (forwardControlResult?.data) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk: () => {
          Promise.all([
            form.resetFields(),
            setVisible(false),
            getForwardControlResult(forwardControlResult.data),
            close(false),
          ])
        },
        onCancel: () => {},
      })
    }
    if (forwardControlResult.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(forwardControlResult?.error)),
        onOk() {},
      })
    }
  }, [forwardControlResult.data])

  const onFinish = (data) => {
    const objData = encryptInput({
      personnelCode: user?.pCode,
      controlResultId: dataResult?.getControlResultById?.controlResultId,
      controlFormCode: dataResult?.getControlResultById?.controlFormCode,
      organizationCode: user?.orgCode,
      makerBy: data?.makerBy,
    })

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการส่ง ?'),
      onOk: () => {
        forwardControlResultFn({ fetchPolicy: 'no-cache', variables: { input: objData } })
      },
      onCancel: () => {},
    })
  }

  return (
    <>
      {(forwardControlResult?.loading || loadingControlResultById) && <SpinnersNew />}
      <ModalNew
        onSubmit={() => {}}
        onClose={() => Promise.all([form.resetFields(), setVisible(false), close(false)])}
        visible={visible}
        testTitle={t('ส่งแบบประเมินควบคุมภายใน')}
        type="medium"
        footer={null}
      >
        <Row justify="center">
          <Col {...formItemLayout.Col}>
            <Form
              form={form}
              onFinish={onFinish}
              autoComplete="off"
              labelAlign="left"
              labelCol={{ ...formItemLayout.labelCol }}
              wrapperCol={{ ...formItemLayout.wrapperCol }}
              layout="horizontal"
              name={formname}
            >
              <Form.Item name={'controlFormYear'} label={t('ประจำปี')}>
                <Label type="body-primary">{yearDisplay(data?.controlFormYear)}</Label>
              </Form.Item>
              <Form.Item name={'controlFormName'} label={t('ชื่อแบบประเมิน')}>
                <Label type="body-primary">{`${data?.controlFormCode}: ${data?.controlFormName}`}</Label>
              </Form.Item>
              <Form.Item name={'date'} label={t('ระยะเวลาที่ประเมิน')}>
                <Label type="body-primary">{`${data?.dateStart} - ${data?.dateEnd}`}</Label>
              </Form.Item>
              <Form.Item name={'organizationName'} label={t('หน่วยงานที่ประเมิน')}>
                <Label type="body-primary">{data?.organizationName}</Label>
              </Form.Item>
              <Form.Item
                name={'makerBy'}
                label={t('ผู้ทำการประเมิน')}
                rules={[{ required: true, message: t('กรุณาระุบผู้ตรวจสอบ') }]}
              >
                <Select
                  placeholder={t('ระุบผู้ตรวจสอบ')}
                  data={getMasterOrganizationsFilters?.data?.getMasterOrganizationsFilters.map((d) => ({
                    label: displayText(d.personnelNameTH, d.personnelNameEN),
                    value: d.personnelCode,
                  }))}
                  scrollableId={formname}
                />
              </Form.Item>
              <Col align="center">
                <Space size={[8, 8]} wrap>
                  <ButtonNew type="primary" roles={{ type: 'edit', menu: props.menu }} htmlType="submit">
                    {t('บันทึก')}
                  </ButtonNew>
                  <ButtonNew
                    type="back"
                    onClick={() => Promise.all([form.resetFields(), setVisible(false), close(false)])}
                  >
                    {t('ยกเลิก')}
                  </ButtonNew>
                </Space>
              </Col>
            </Form>
          </Col>
        </Row>
      </ModalNew>
    </>
  )
}

SendFormModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  controlResultId: PropTypes.number,
  getForwardControlResult: PropTypes.func.isRequired,
  menu: PropTypes.string.isRequired,
}

export default SendFormModal
