import { gql } from '@apollo/client'
export const GET_ORGANIZATION_SIZE = gql`
  query getMasterOrganizationSize($input: MasterOrganizationSizeInput!) {
    getMasterOrganizationSize(data: $input) {
      organizationSizeId
      organizationSizeCode
      organizationSizeNameTH
      organizationSizeNameEN
      isActive
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`
