import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { ButtonNew } from '../../../../../components/button/Button'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { InputNew } from '../../../../../components/input/Input'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
// import { Alert } from '../../../../../components/alert/Alert'
import { UploadNew } from '../../../../../components/upload/Upload.jsx'
import { downloadFile } from '../../../../../utilitys/files'
import { v4 as uuidv4 } from 'uuid'

const FormAuditOpensAdddocMultiRow = (props) => {
  // const menu = props.menu
  const { t } = useTranslation()
  const form = props.form

  const [, /* idFile */ setIdFile] = useState([])

  const [dataList, setDataLists] = useState([])
  const [download, setDownload] = useState(false)
  const [deleteting, setDeleteting] = useState(false)

  const onUpload = (record, value, index) => {
    const data = [...dataList]
    data[index].fileId = value.uid
    data[index].tools = { originalname: value.originalname, name: value.name }

    setDataLists([...data])
    setIdFile(value.uid)
    form.setFieldsValue({ [record.key]: { fileId: value.uid } })
  }
  const onRemove = (filename, record) => {
    let newData = dataList.filter((item) => item.key !== record.key)
    setDataLists(newData)

    if (props.typeForm === 'add') {
      setDeleteting(true)
      if (filename) {
        fetch(process.env.REACT_APP_API_UPLOAD + `/deleteFile/${filename}`, {
          method: 'GET',
        })
          .then(() => {
            setDeleteting(false)
          })
          .catch(() => {})
      } else {
        setDeleteting(false)
      }
    }
  }
  const columns = [
    {
      title: t('ชื่อเอกสาร'),
      dataIndex: 'filename',
      width: '30%',
      render: (item, record) => {
        return (
          <div>
            <Form.Item hidden={true} name={[record.key, 'fileId']} initialValue={record.fileId}>
              <InputNew />
            </Form.Item>
            <Form.Item
              name={[record.key, 'filename']}
              initialValue={item}
              rules={[{ required: true, message: t('กรุณากรอกชื่อเอกสาร') }]}
            >
              <InputNew
                placeholder={t('ชื่อเอกสาร')}
                disabled={props.typeForm === 'view' ? true : false}
                onChange={(e) => {
                  form.setFieldsValue({ [record.key]: { filename: e.target.value } })
                }}
              />
            </Form.Item>
          </div>
        )
      },
    },
    {
      title: t('ลิงค์'),
      dataIndex: 'links',
      width: '40%',
      render: (item, record) => {
        return props.typeForm !== 'view' ? (
          <div>
            <Form.Item name={[record.key, 'links']} initialValue={item}>
              <InputNew
                placeholder={t('ระบุลิงค์')}
                onChange={(e) => {
                  form.setFieldsValue({ [record.key]: { links: e.target.value } })
                }}
              />
            </Form.Item>
          </div>
        ) : item ? (
          <a href={item} target="_blank" rel="noreferrer" style={{ color: '#2b4990' }}>
            <u>{item}</u>
          </a>
        ) : (
          '-'
        )
      },
    },
    {
      title: t('เอกสารอ้างอิง'),
      dataIndex: 'fileId',
      width: '25%',
      align: 'left',
      render: (item, record, index) => {
        return (
          <div>
            <Form.Item name={[record.key, 'fileId']}>
              <UploadNew
                disabled={props.typeForm === 'view' ? true : false}
                key={index}
                setIdFile={setIdFile}
                idFile={[record.fileId] || []}
                defaults={[record.fileId] || []}
                typeFile="file"
                inline={true}
                multiRow={true}
                setFileData={(e) => {
                  onUpload(record, e, index)
                }}
                actionType={props.typeForm}
              />
            </Form.Item>
          </div>
        )
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '5%',
      render: (item, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {props.typeForm === 'view' && record.fileId ? (
              <ButtonGroup
                menu={props.menu}
                icons={[
                  {
                    type: 'download',
                    onClick: () => {
                      downloadFile(item.originalname, item.name, () => {
                        setDownload(false)
                      })
                    },
                  },
                ]}
              />
            ) : (
              <ButtonGroup
                menu={props.menu}
                icons={(record.fileId
                  ? [
                      {
                        type: 'download',
                        onClick: () => {
                          item &&
                            downloadFile(item.originalname, item.name, () => {
                              setDownload(false)
                            })
                        },
                      },
                    ]
                  : []
                ).concat([
                  {
                    type: 'delete',
                    onClick: () => {
                      props.setIdFileDelete([...props.idFileDelete, record.fileId]), onRemove(item?.name, record)
                    },
                  },
                ])}
              />
            )}
          </div>
        )
      },
    },
  ]

  const handleAdd = () => {
    const uid = uuidv4()

    const fields = form.getFieldsValue()
    fields[uid] = {
      fileId: null,
      filename: null,
      links: null,
    }
    form.setFieldsValue({
      ...fields,
    })

    let newData = [...dataList]
    newData.push({
      key: uid,
      fileId: null,
      filename: null,
      links: null,
    }) //ต้องเพิ่มตาม field

    setDataLists(newData)
  }

  useEffect(() => {
    if (props.typeForm !== 'add') {
      const fields = form.getFieldsValue()
      let newData = []
      for (let i = 0; i < props.files.length; i++) {
        const uid = uuidv4()
        fields[uid] = {
          fileId: props.files[i].fileId,
          filename: props.files[i].filename,
          links: props.files[i].links,
        }
        newData.push({
          key: uid,
          fileId: props.files[i].fileId,
          filename: props.files[i].filename,
          links: props.files[i].links,
          tools: {
            originalname: props.files[i].originalname,
            name: props.files[i].name,
          },
        })
      }
      form.setFieldsValue({
        ...fields,
      })
      setDataLists(newData)
    }
  }, [props.files, props.typeForm])

  return (
    <>
      {(download || deleteting) && <SpinnersNew />}
      <Row justify="center">
        <Col md={24}>
          <Datatable
            columns={columns}
            data={dataList}
            pagination={false}
            enableScroll={{ x: false, y: true }}
            scroll={{ x: '100%', y: false }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '5px', marginBottom: '10px' }}>
        <Col md={24}>
          <ButtonNew type="addMultiple" onClick={() => handleAdd()} disabled={props.typeForm === 'view' ? true : false}>
            {t('เพิ่มเอกสาร')}
          </ButtonNew>
        </Col>
      </Row>
    </>
  )
}

export default FormAuditOpensAdddocMultiRow
