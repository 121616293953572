import styled from 'styled-components'

export const OrganizationStyle = styled.div`
  .ant-card-bordered {
    border: none;
  }

  .ant-card-head {
    margin: 0;
    padding: 0;
  }

  .ant-card-body {
    margin: 0;
    padding: 0 !important;
  }
`
