import { useEffect, useState } from 'react'
import { decryptData } from '../utilitys/crypto'

export const useDecodeUser = () => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    const decodeUser = decryptData(localStorage.getItem('user'))
    setUser(decodeUser)
  }, [localStorage.getItem('user')])

  return [user]
}
