import React from 'react'
import { Col, Table, Row } from 'antd'
import { Checkbox } from '../../../components/checkbox/Checkbox'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'

const arrCrumb = [{ label: 'จัดการข้อมูลพื้นฐาน', path: '#' }, { label: 'จัดการกลุ่มสิทธิผู้ใช้งาน' }]

const code = `
import { Col, Table, Row } from 'antd'
import { Checkbox } from '../../../components/checkbox/Checkbox'

const App = () => {
  const columns = [
    {
      title: 'รายการเมนูย่อย',
      align: 'center',
      children: [
        {
          title: <Checkbox>เลือกทั้งหมด</Checkbox>,
          dataIndex: 'name',
          key: 'name',
        },
      ],
    },
    {
      title: 'สิทธิ์การใช้งาน',
      children: [
        {
          title: 'ดู',
          align: 'center',
          height: '5px',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'view',
              align: 'center',
              key: 'view',
              width: 79,
            },
          ],
        },
        {
          title: 'เพิ่ม',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'add',
              align: 'center',
              key: 'add',
              width: 79,
            },
          ],
        },
        {
          title: 'แก้ไข',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'edit',
              align: 'center',
              key: 'edit',
              width: 79,
            },
          ],
        },
        {
          title: 'ลบ',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'delete',
              align: 'center',
              key: 'delete',
              width: 79,
            },
          ],
        },
        {
          title: 'ส่งเมล',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'sendMail',
              align: 'center',
              key: 'sendMail',
              width: 79,
            },
          ],
        },
        {
          title: 'ดาวน์โหลด',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'download',
              align: 'center',
              key: 'download',
              width: 79,
            },
          ],
        },
        {
          title: 'อนุมัติ',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'approve',
              align: 'center',
              key: 'approve',
              width: 79,
            },
          ],
        },
        {
          title: 'คัดลอก',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'copy',
              align: 'center',
              key: 'copy',
              width: 79,
            },
          ],
        },
      ],
    },
  ]

  const data = []
  for (let i = 0; i < 20; i++) {
    data.push({
      key: i,
      name: 'เมนู ' + (i * 1 + 1),
      view: <Checkbox key={i} />,
      add: <Checkbox key={i} />,
      edit: <Checkbox key={i} />,
      delete: <Checkbox key={i} />,
      sendMail: <Checkbox key={i} />,
      download: <Checkbox key={i} />,
      approve: <Checkbox key={i} />,
      copy: <Checkbox key={i} />,
    })
  }
  return (
    <React.Fragment>
        <Table columns={columns} dataSource={data} size="middle" pagination={false} scroll={{ y: 460 }} />
    </React.Fragment>
  )
}

export default App
`
const TableMergeColumn = () => {
  const columns = [
    {
      title: 'รายการเมนูย่อย',
      align: 'center',
      children: [
        {
          title: <Checkbox>เลือกทั้งหมด</Checkbox>,
          dataIndex: 'name',
          key: 'name',
        },
      ],
    },
    {
      title: 'สิทธิ์การใช้งาน',
      children: [
        {
          title: 'ดู',
          align: 'center',
          height: '5px',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'view',
              align: 'center',
              key: 'view',
              width: 79,
            },
          ],
        },
        {
          title: 'เพิ่ม',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'add',
              align: 'center',
              key: 'add',
              width: 79,
            },
          ],
        },
        {
          title: 'แก้ไข',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'edit',
              align: 'center',
              key: 'edit',
              width: 79,
            },
          ],
        },
        {
          title: 'ลบ',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'delete',
              align: 'center',
              key: 'delete',
              width: 79,
            },
          ],
        },
        {
          title: 'ส่งเมล',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'sendMail',
              align: 'center',
              key: 'sendMail',
              width: 79,
            },
          ],
        },
        {
          title: 'ดาวน์โหลด',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'download',
              align: 'center',
              key: 'download',
              width: 79,
            },
          ],
        },
        {
          title: 'อนุมัติ',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'approve',
              align: 'center',
              key: 'approve',
              width: 79,
            },
          ],
        },
        {
          title: 'คัดลอก',
          align: 'center',
          children: [
            {
              title: <Checkbox />,
              dataIndex: 'copy',
              align: 'center',
              key: 'copy',
              width: 79,
            },
          ],
        },
      ],
    },
  ]

  const data = []
  for (let i = 0; i < 20; i++) {
    data.push({
      key: i,
      name: 'เมนู ' + (i * 1 + 1),
      view: <Checkbox key={i} />,
      add: <Checkbox key={i} />,
      edit: <Checkbox key={i} />,
      delete: <Checkbox key={i} />,
      sendMail: <Checkbox key={i} />,
      download: <Checkbox key={i} />,
      approve: <Checkbox key={i} />,
      copy: <Checkbox key={i} />,
    })
  }
  return (
    <React.Fragment>
      <Breadcrumb data={arrCrumb} title={'จัดการกลุ่มสิทธิผู้ใช้งาน'} />
      <Row>
        <Col>
          <CardNew topic={'เพิ่มกลุ่มสิทธิ์ผู้ใช้งาน'} icon="Plus" toggledrop={'false'}>
            <Table
              columns={columns}
              dataSource={data}
              size="middle"
              pagination={false}
              scroll={{ x: '100%', y: 500 }}
              bordered
            />
          </CardNew>
        </Col>
        <Col>
          <CodeBlock code={code} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default TableMergeColumn
