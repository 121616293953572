import styled from 'styled-components'
import { colors } from '../../configs/styles.config'

export const CalendarTimelineStyles = styled.div`
  #calendar {
    height: 350px;
  }

  .label-content-mouth {
    color: rgb(255, 255, 255);
    font-weight: lighter;
  }

  .label-content-day {
    color: rgb(0, 0, 0);
    font-weight: lighter;
  }

  .fc .fc-timeline-header-row:last-child .fc-timeline-slot-frame {
    background-color: rgb(255, 255, 255);
    border: 1px solid #bbb;
  }

  .fc-license-message {
    display: none !important;
  }

  .fc-timeline-slot-frame {
    background-color: ${colors.lightGray2};
    border: 1px solid #bbb;
  }

  .fc-datagrid-header .fc-scrollgrid-sync-table {
    width: 100% !important;
  }

  .fc .fc-datagrid-cell-cushion {
    margin: auto;
    font-weight: lighter;
  }

  .fc-day-sat,
  .fc-day-sun {
    background-color: #e8d595;
  }

  .fc-timeline .fc-day-today {
    background-color: ${colors.lightGray2};
  }

  .additional-text {
    color: rgb(193, 200, 199);
  }

  .clickable-resource {
    cursor: pointer;
  }

  .clickable-resource:hover {
    background-color: rgb(38, 118, 187);
    color: #fff;
  }
`
