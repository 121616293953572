import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { Form, Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { ButtonNew } from '../../../../components/button/Button'
import { InputNew } from '../../../../components/input/Input'
import { LabelNew } from '../../../../components/label/Label'
import { BreadcrumbNew as Breadcrumb } from '../../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../../components/card/Card'
import { TextAreaNew } from '../../../../components/textarea/TextArea.jsx'
import SelectTagEmailPersonnels from '../../../../components/inputfromapi/selecttagemailpersonnels/SelectTagEmailPersonnels'
import { GET_EMAIL_AUDIT_SUGGEST_ORGANIZATIONS, GET_EMAIL_COPY } from '../graphql/Query'
import { SAVE_SEND_EMAIL } from '../graphql/Mutation'
import { SpinnersNew } from '../../../../components/spinners/Spinners.jsx'
import { encryptInput, decryptData } from '../../../../utilitys/crypto'
import { Alert } from '../../../../components/alert/Alert'
import { convertEmailJson, displayError } from '../../../../utilitys/helper'
import { useDecodeUser } from '../../../../hooks/useDecodeUser'

const Responesive = {
  md: 24,
  lg: 24,
}

const NotificationForm = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()
  const [typeAction, setTypeAction] = useState(false)
  const [form] = Form.useForm()
  const [user] = useDecodeUser()

  if (!state) {
    return <Navigate to={'/administrator/users'} />
  }

  const [cellTo, { data: dataTo, loading: loadingTo, error: errorTo }] = useLazyQuery(
    GET_EMAIL_AUDIT_SUGGEST_ORGANIZATIONS,
  )
  const [cellCopy, { data: dataCopy, loading: loadingCopy, error: errorCopy }] = useLazyQuery(GET_EMAIL_COPY)

  const [cellSave, { data: dataSave, loading: loadingSave, error: errorSave }] = useMutation(SAVE_SEND_EMAIL)

  useEffect(() => {
    cellTo({
      variables: {
        suggestId: encryptInput(state.suggestId),
      },
    })
    cellCopy({
      variables: {
        projectCode: encryptInput(state.refCode),
      },
    })
  }, [state])

  useEffect(() => {
    if (dataTo) {
      const dataEmailTo = dataTo.getEmailAuditSuggestOrganizations.map((item) => {
        const email = decryptData(item.email)
        return JSON.stringify({ personnelCode: item.personnelCode, email: email })
      })
      form.setFieldsValue({ sendTo: dataEmailTo })
    }
    if (errorTo) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorTo)),
        onOk() {},
      })
    }
  }, [dataTo])

  useEffect(() => {
    // if (dataCopy && dataEmailPersernalAll) {
    if (dataCopy) {
      const dataEmailCopy = dataCopy.getEmailCopy.map((item) => {
        const email = decryptData(item.email)
        return JSON.stringify({ personnelCode: item.personnelCode, email: email })
      })
      // const dataFind = _.filter(dataEmailCopy, (item) => {
      //   if (_.includes(dataEmailPersernalAll, item)) {
      //     return true
      //   }
      // })
      // form.setFieldsValue({ sendToCopy: dataFind })
      form.setFieldsValue({ sendToCopy: dataEmailCopy })
    }
    if (errorCopy) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorCopy)),
        onOk() {},
      })
    }
    // , dataEmailPersernalAll
  }, [dataCopy])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          // navigate(-1)
        },
      })
    }
    if (errorSave) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถบันทึกข้อมูลได้`),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [dataSave])

  useEffect(() => {
    if (state.type_action) {
      if (state.type_action === 'edit' || state.type_action === 'view') {
        // getSystemUsers()
        if (state.type_action === 'view') {
          setTypeAction(true)
        }
      }
    }
  }, [])

  // onFinish
  const onFinish = (values) => {
    fnConfirm(values)
  }

  const onSubmit = async (values) => {
    let dataInput = {
      sendTo: convertEmailJson(values.sendTo),
      sendToCopy: convertEmailJson(values.sendToCopy),
      sendToSecret: convertEmailJson(values.sendToSecret),
      detail: values.detail,
      subject: values.subject,
      sendBy: user?.pCode,
      actionPlanId: state.actionPlanId,
      refCode: state.refCode,
      status: state.status,
      type_action: state.type_action,
      type_actionSub: state.type_actionSub || '',
      suggestId: state.suggestId,
    }
    cellSave({
      variables: {
        input: encryptInput(dataInput),
      },
    })
  }
  // onFinishFailed
  const onFinishFailed = () => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t('กรุณากรอกข้อมูลให้ครบถ้วน'),
      onOk() {},
    })
  }

  const fnConfirm = (values) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        onSubmit(values)
      },
      onCancel() {},
    })
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/auditresult' },
    { label: t('บันทึกผลการปฏิบัติงานตรวจสอบ') },
  ]

  return (
    <>
      {loadingTo || loadingCopy || loadingSave ? <SpinnersNew /> : null}
      <Breadcrumb data={breadcrumbList} title={t('บันทึกผลการปฏิบัติงานตรวจสอบ')} />
      <CardNew topic={t('แจ้งประเด็นการตรวจสอบ')} icon={'List'} toggledrop={'false'}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            md: 8,
            lg: 6,
          }}
          wrapperCol={{
            md: 24,
            lg: 16,
            align: 'left',
          }}
          layout="horizontal"
          name="notification_form"
        >
          <Row style={{ marginBottom: 20 }} justify="start">
            <Col md={2} lg={2}></Col>
            <Col md={22} lg={22}>
              <LabelNew type="sub-header-primary">{t('รายละเอียดการเปิดตรวจ')}</LabelNew>
            </Col>
          </Row>
          <Row style={{ marginBottom: 24 }} justify="center">
            <Col md={24} lg={16}>
              <Form.Item
                name="subject"
                label={<LabelNew type="tab-header-inactive">{t('เรื่อง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุเรื่อง') }]}
              >
                <InputNew maxLength={100} disabled={typeAction} placeholder={t('ระบุเรื่อง')} />
              </Form.Item>
              <Form.Item
                name="sendTo"
                label={<LabelNew type="tab-header-inactive">{t('ถึง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุถึง') }]}
              >
                <SelectTagEmailPersonnels
                  disabled={typeAction}
                  placeholder={t('ระบุถึง')}
                  formname="notification_form"
                  handleChange={(e) => {
                    form.setFieldsValue({ sendTo: e })
                  }}
                />
              </Form.Item>
              <Form.Item name="sendToCopy" label={<LabelNew type="tab-header-inactive">{t('สำเนา')}</LabelNew>}>
                <SelectTagEmailPersonnels
                  disabled={typeAction}
                  placeholder={t('ระบุสำเนา')}
                  formname="notification_form"
                  handleChange={(e) => {
                    form.setFieldsValue({ sendToCopy: e })
                  }}
                />
              </Form.Item>
              <Form.Item name="sendToSecret" label={<LabelNew type="tab-header-inactive">{t('สำเนาลับ')}</LabelNew>}>
                <SelectTagEmailPersonnels
                  disabled={typeAction}
                  placeholder={t('ระบุสำเนาลับ')}
                  formname="notification_form"
                  handleChange={(e) => {
                    form.setFieldsValue({ sendToSecret: e })
                  }}
                />
              </Form.Item>
              <Form.Item name="detail" label={<LabelNew type="tab-header-inactive">{t('รายละเอียด')}</LabelNew>}>
                <TextAreaNew
                  showCount
                  disabled={typeAction}
                  maxLength={300}
                  rows={5}
                  placeholder={t('ระบุรายละเอียด')}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* ---------- */}
          <Row gutter={[16, 16]}>
            <Col {...Responesive} align="center">
              <Space size={[8, 8]} wrap>
                {state.type_action !== 'view' ? (
                  <>
                    <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                      {t('บันทึก')}
                    </ButtonNew>
                  </>
                ) : (
                  ''
                )}
                &nbsp;
                <ButtonNew
                  type="back"
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  {t('ย้อนกลับ')}
                </ButtonNew>
              </Space>
            </Col>
          </Row>
        </Form>
      </CardNew>
    </>
  )
}
export default NotificationForm
