import _ from 'lodash'
import { displayText } from '../../../../../utilitys/helper'

export const heatMapColumns = (t, data) => {
  const initColumns = [
    {
      title: t('สายงาน/สาเหตุ'),
      dataIndex: 'organizationIdx',
      width: '300px',
      align: 'left',
    },
  ]
  const resColumns = _.map(data, (dt) => {
    return {
      title: displayText(dt?.causeNameTH, dt?.causeNameEN),
      dataIndex: dt?.causeCode,
      width: '220px',
      align: 'center',
      className: 'text-nowrap',
    }
  })

  return [...initColumns, ...resColumns]
}

export const causeAndAuditIssueColumns = (t) => {
  return [
    { title: t('ลำดับ'), dataIndex: 'no', width: '50px', align: 'center' },
    { title: t('ประจำปี'), dataIndex: 'year', width: '100px', align: 'center' },
    { title: t('ชื่อโครงการ'), dataIndex: 'projectName', width: '350px', align: 'left' },
    { title: t('ผู้รับผิดชอบ'), dataIndex: 'leadTeamName', width: '160px', align: 'left' },
    { title: t('ประเด็น'), dataIndex: 'issueName', width: '160px', align: 'left' },
    { title: t('ประเภทสาเหตุ'), dataIndex: 'causeName', width: '160px', align: 'left' },
    { title: t('หน่วยงาน'), dataIndex: 'oraganizationName', width: '160px', align: 'left' },
    { title: t('ความเสี่ยงประเด็น'), dataIndex: 'riskLevelName', width: '160px', align: 'center' },
    { title: t('สถานะ'), dataIndex: 'actionStatusName', width: '120px', align: 'left' },
    {
      title: t('วันที่คาดว่าจะแล้วเสร็จ/วันที่ดำเนินการแล้ว'),
      dataIndex: 'actionConditionDate',
      width: '170px',
      align: 'center',
    },
  ]
}
