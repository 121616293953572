import { gql } from '@apollo/client'

export const GET_AUDITPLAN_PAGINATION = gql`
  query getAuditPlanPagenation($input: AuditPlanPagenation!) {
    getAuditPlanPagenation(data: $input) {
      result {
        planId
        planCode
        planNameTH
        planNameEN
        planStatusNameTH
        planStatusNameEN
        planYear
        dateStart
        dateEnd
        isActive
        countProjectALL
        countApproveStatus10
        countApproveStatus20
        countApproveStatus30
        planStatusCode
        files
        fileDetail
        planType
      }
      count
      page
      limit
    }
  }
`

export const GET_AUDITPLAN = gql`
  query getAuditPlan($input: AuditPlanSearchInput!) {
    getAuditPlan(data: $input) {
      planId
      planCode
      planNameTH
      planNameEN
      planYear
      dateStart
      dateEnd
      isActive
      countApproveStatus10
      countApproveStatus20
      countApproveStatus30
      countProjectAll
      planType
    }
  }
`

export const GET_MASTER_PROJECT_STATUS = gql`
  query getMasterProjectStatus($input: MasterProjectStatusInput!) {
    getMasterProjectStatus(data: $input) {
      projectStatusCode
      projectStatusNameTH
      projectStatusNameEN
    }
  }
`

export const AUDITPLAN_EXPORT = gql`
  query auditPlanExport($input: AuditPlanExportInput!) {
    auditPlanExport(data: $input) {
      message
      fileUrl
    }
  }
`

export const AUDIT_PROJECT_GET_CALENDARDETAILS1 = gql`
  query auditProjectGetCalendarDetailsType1 {
    auditProjectGetCalendarDetailsType1 {
      dateAt
    }
  }
`

export const AUDIT_PROJECT_GET_CALENDARDETAILS2 = gql`
  query auditProjectGetCalendarDetailsType2 {
    auditProjectGetCalendarDetailsType2 {
      dateAt
    }
  }
`
