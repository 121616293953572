import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { ButtonNew } from '../../../components/button/Button'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput } from '../../../utilitys/crypto'
import { GET_SETUP_RUNNUG_NUMBER_PAGINATION } from './graphql/Query'
import { DELETE_SETUP_RUNNING_NUMBER } from './graphql/Mutation'
import { Columns } from './utils/SetupRunningNumberColumns'
import { handleSort } from '../../../utilitys/pagination'
import { displayError } from '../../../utilitys/helper'
import { Status } from '../../../components/status/Status'

const Responesive = {
  sm: 24,
  md: 24,
  lg: 24,
}

const SetupRunningNumberList = (props) => {
  const { t } = useTranslation()
  const { menu } = props
  const navigate = useNavigate()
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])

  const [getPagination, { data: dataPagination, loading: loadingPagination, error: errorPagination }] = useLazyQuery(
    GET_SETUP_RUNNUG_NUMBER_PAGINATION,
  )

  const [cellDelete, { data: dataDelete, loading: loadingDelete, error: errorDelete }] =
    useMutation(DELETE_SETUP_RUNNING_NUMBER)

  const fnConfirm = (numberSeriesId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        onSubmit(numberSeriesId)
      },
      onCancel() {},
    })
  }

  const onSubmit = (numberSeriesId) => {
    let variables = encryptInput({ numberSeriesId: numberSeriesId })
    cellDelete({ variables })
  }

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบข้อมูลสำเร็จ'),
        onOk() {
          getPagination({
            variables: {
              input: encryptInput({
                filters: {},
                pagination: { limit, page },
                sort: sort,
              }),
            },
          })
        },
      })
    }
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถลบข้อมูลได้`),
        content: t(errorDelete),
        onOk() {},
      })
    }
  }, [dataDelete])

  useEffect(() => {
    getPagination({
      variables: {
        input: encryptInput({
          filters: {},
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (dataPagination?.setupRunningNumberPaginationGet?.result) {
      setCount(dataPagination?.setupRunningNumberPaginationGet?.count)
      setDataLists(
        _.map(dataPagination?.setupRunningNumberPaginationGet?.result, (data) => {
          return {
            key: data.no,
            numberTypeCode: data.numberTypeNameTH,
            documentName: data.documentName,
            organizationNameTH: data.organizationNameTH,
            menuNameTH: data.menuNameTH,
            startEnd: data.startEnd,
            formatDetail: data.formatDetail,
            lastNumber: data.lastNumber,
            isActive: <Status text={data.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () =>
                        navigate('/administrator/setuprunningnumber/form', {
                          state: { type_action: 'view', refCode: data.numberSeriesId },
                        }),
                    },
                    {
                      type: 'edit',
                      onClick: () =>
                        navigate('/administrator/setuprunningnumber/form', {
                          state: { type_action: 'edit', refCode: data.numberSeriesId },
                        }),
                    },
                    {
                      type: 'delete',
                      onClick: async () => {
                        fnConfirm(data.numberSeriesId)
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }

    if (errorPagination) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(errorPagination)),
        onOk() {},
      })
    }
  }, [dataPagination])

  return (
    <>
      {loadingPagination || loadingPagination || loadingDelete ? <SpinnersNew /> : null}
      <CardNew topic={t('รายการเลขที่เอกสาร')} icon="List" toggledrop={'false'}>
        <Row gutter={[16, 16]}>
          <Col {...Responesive}>
            <Datatable
              columns={Columns}
              data={[...dataLists]}
              total={count}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              paginationCustom={true}
              searchCustom={true}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => setSort(handleSort(e?.sorter))}
              isScoll={{ x: true, y: true }}
              btnAdd={
                <ButtonNew
                  type="plusTableBorderPrimary"
                  onClick={() => {
                    navigate('/administrator/setuprunningnumber/form', {
                      state: { type_action: 'add' },
                    })
                  }}
                  roles={{ type: 'add', menu: menu }}
                >
                  {t('เพิ่มเลขที่เอกสาร')}
                </ButtonNew>
              }
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default SetupRunningNumberList
