import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Form, Row, Col } from 'antd'
import { ModalNew } from '../../../../../components/modal/Modal'
import { InputNew } from '../../../../../components/input/Input'
import { RadioGroup, Radio } from '../../../../../components/radio/Radio'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { GET_SYSTEM_USERS_EXPORT } from '../../graphql/Query'
import { encryptInput } from '../../../../../utilitys/crypto'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError } from '../../../../../utilitys/helper'

const UsersModel = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [disableAction, setDisableAction] = useState(false)

  const [
    getSystemUsersExport,
    { data: dataSystemUsersExport, loading: loadingSystemUsersExport, error: errorSystemUsersExport },
  ] = useLazyQuery(GET_SYSTEM_USERS_EXPORT)

  useEffect(() => {
    setVisible(props.open)
  }, [props.open])

  useEffect(() => {
    if (dataSystemUsersExport) {
      if (dataSystemUsersExport.systemUserExport.message === 'succcess') {
        window.open(`${dataSystemUsersExport.systemUserExport.fileUrl}`, '_blank')
      } else {
        Alert({
          type: 'error',
          title: t('ไม่มีข้อมูล'),
          onOk() {},
        })
      }
    }
    if (errorSystemUsersExport) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorSystemUsersExport)),
        onOk() {},
      })
    }
  }, [dataSystemUsersExport])

  const onFinish = (input) => {
    showConfirm(input)
  }
  const showConfirm = (input) => {
    let data = {
      fileType: input.fileType,
      waterMark: input.waterMark || '',
      userId2: props.userId,
      lang: localStorage.getItem('lang'),
    }
    getSystemUsersExport({
      variables: {
        input: encryptInput(data),
      },
    })
  }

  function onClose(e) {
    form.resetFields()
    setVisible(e)
    props.close(e)
  }
  return (
    <>
      {loadingSystemUsersExport && <SpinnersNew />}
      <ModalNew
        visible={visible}
        testTitle={t('พิมพ์รายงาน')}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        btnName={t('พิมพ์')}
        // type="medium"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="auditTeamForm"
        >
          <Form.Item
            label={<Label type="tab-header-inactive">{t('ประเภทรายงาน')}</Label>}
            name="fileType"
            initialValue={'pdf'}
            onClick={(e) => {
              if (e.target.value) {
                if (e.target.value !== 'pdf') {
                  form.setFieldsValue({
                    waterMark: '',
                  })
                  setDisableAction(true)
                } else {
                  setDisableAction(false)
                }
              }
            }}
          >
            <RadioGroup>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="pdf">
                    <Label type="tab-header-inactive">{t('PDF')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="word">
                    <Label type="tab-header-inactive">{t('Word')}</Label>
                  </Radio>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="excel">
                    <Label type="tab-header-inactive">{t('Excel')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="csv">
                    <Label type="tab-header-inactive">{t('CSV')}</Label>
                  </Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
          {!disableAction ? (
            <Form.Item name="waterMark" label={<Label type="tab-header-inactive">{t('ลายน้ำ')}</Label>}>
              <InputNew disabled={disableAction} />
            </Form.Item>
          ) : (
            ''
          )}
        </Form>
      </ModalNew>
    </>
  )
}
export default UsersModel
