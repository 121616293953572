import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_SELECT_STEP_TYPES } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectStepTypes = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const [isData, isSetData] = useState([])

  const [getFn, selectDetail] = useLazyQuery(GET_SELECT_STEP_TYPES)

  useEffect(() => {
    getFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [filters])

  useEffect(() => {
    if (selectDetail.data) {
      isSetData(
        _.map(selectDetail?.data?.getSelectStepTypes, (item) => {
          return {
            label: item?.stepTypeCode + ' : ' + displayText(item?.stepTypeNameTH, item?.stepTypeNameEN),
            value: item?.stepTypeCode,
            source: item,
          }
        }),
      )
    }
  }, [selectDetail.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={selectDetail.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          return handleChange(_.find(isData, (data) => data.value === value))
        }}
      />
    </>
  )
}

export default SelectStepTypes
