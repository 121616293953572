import { gql } from '@apollo/client'

export const SENDFORMBYASSESSMENTAUDITWORK = gql`
  mutation sendFormByAssessmentAuditWork($input: AssessmentAuditWorkSendInput!) {
    sendFormByAssessmentAuditWork(input: $input) {
      formResultId
    }
  }
`

export const SAVEFORMRESULTSBYASSESSMENTAUDITWORK = gql`
  mutation saveformResultsByAssessmentAuditWork($input: AssessmentAuditWorkSaveInput!) {
    saveformResultsByAssessmentAuditWork(input: $input) {
      formResultId
      operationStatusCode
      makerStatusCode
    }
  }
`
