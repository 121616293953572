import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import { encryptInput } from '../../../../../utilitys/crypto'

const GET_MASTER_CAUSE_OF_ISSUE = gql`
  query getMasterCauseOfIssue($input: MasterCauseOfIssueInput!) {
    getMasterCauseOfIssue(input: $input) {
      causeCode
      causeNameTH
      causeNameEN
    }
  }
`

export const useGetMasterCauseOfIssue = () => {
  const [data, setData] = useState(null)
  const [getMasterCauseOfIssueFn, getMasterCauseOfIssue] = useLazyQuery(GET_MASTER_CAUSE_OF_ISSUE)

  useEffect(() => {
    getMasterCauseOfIssueFn({ variables: { input: encryptInput({ isActive: 1 }) } })
  }, [])

  useEffect(() => {
    if (getMasterCauseOfIssue?.data) setData(getMasterCauseOfIssue?.data?.getMasterCauseOfIssue)
  }, [getMasterCauseOfIssue?.data])

  const refresh = () => getMasterCauseOfIssue.refetch()

  return [data, getMasterCauseOfIssue?.loading, refresh]
}
