import { Trans } from 'react-i18next'

export const ListCrumb = [
  { label: <Trans>รายงาน</Trans>, path: '/reports/risklevelfiveyearreport' },
  { label: <Trans>รายงานผลประเมินความเสี่ยงแผน 5 ปี</Trans> },
]

export const baseColumn = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'no',
    width: '10px',
    align: 'center',
    fixed: 'left',
    sorter: true,
  },
  {
    title: <Trans>แบบประเมินความเสี่ยง</Trans>,
    dataIndex: 'riskFormName',
    width: '30px',
    align: 'left',
    fixed: 'left',
    sorter: true,
  },
  {
    title: <Trans>โครงการ</Trans>,
    dataIndex: 'projectName',
    width: '40px',
    align: 'left',
    sorter: true,
  },
  {
    title: <Trans>ความเสี่ยง</Trans>,
    dataIndex: 'riskLevelName',
    width: '20px',
    align: 'center',
    sorter: true,
  },
]
