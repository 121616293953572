import { gql } from '@apollo/client'

export const GET_ACTIONPLAN_ORGANIZATION = gql`
  query getActionPlanOrganizationsSelect($input: ActionPlanOrganizationsInput!) {
    getActionPlanOrganizationsSelect(data: $input) {
      organizationCode
      organizationNameTH
      organizationNameEN
      personnelCode
      namePrefixTH
      namePrefixEN
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
      email
    }
  }
`
