import { gql } from '@apollo/client'

export const GET_LIST_APPROVE_ACTION_PLAN_PAGINATION = gql`
  query GetListApproveActionPlanPagination($input: ApproveActionPlanPaginationInput!) {
    getListApproveActionPlanPagination(input: $input) {
      result {
        no
        actionPlanId
        projectCode
        projectNameTH
        projectNameEN
        projectRefCode
        projectRefNameTH
        projectRefNameEN
        dateStart
        dateEnd
        organizationCode
        organizationNameTH
        organizationNameEN
        approver
        personnelNameTH
        personnelNameEN
        approveStatusCode
        isActive
        planStatusCode
        opened
      }
      count
      page
      limit
    }
  }
`

export const GET_LIST_AUDIT_JOB_STEP_BY_ACTION_PLAN_ID = gql`
  query GetListAuditJobStepByActionPlanID($input: AuditJobStepListInput!) {
    getListAuditJobStepByActionPlanID(input: $input) {
      stepId
      actionPlanId
      stepIdRef
      stepNameTH
      stepNameEN
      stepType
      stepSort
      dateStart
      dateEnd
      operationStatusCode
      operationStatusNameTH
      operationStatusNameEN
      isActive
      personnels {
        personnelCode
        personnelNameTH
        personnelNameEN
      }
      organizations {
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationCode
        organizationNameTH
        organizationNameEN
        personnelCode
        personnelNameTH
        personnelNameEN
        organizationLevelCode
        organizationLevelNameTH
        organizationLevelNameEN
      }
    }
  }
`

export const GET_EXPORT_ACTION_PLAN = gql`
  query ExportActionPlan($input: ExportActionPlanInput!) {
    exportActionPlan(input: $input) {
      message
      fileUrl
    }
  }
`
