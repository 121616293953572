import { gql } from '@apollo/client'
export const LOGIN = gql`
  mutation login($input: LoginInput!) {
    login(input: $input) {
      logfileLoginId
      userId
      username
      email
      personnelEmail
      fullname
      pCode
      orgCode
      orgNameTH
      orgNameEN
      randomKey
      access_token
      refresh_token
      userMenus
    }
  }
`
