import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { Status } from '../../../../components/status/Status.jsx'
import { Trans } from 'react-i18next'

export const colummList = () => {
  return [
    {
      title: <Trans>ลำดับที่</Trans>,
      dataIndex: 'no',
      width: '10%',
    },
    {
      title: <Trans>ชื่อแบบประเมิน</Trans>,
      dataIndex: 'formName',
      width: '40%',
      sorter: true,
    },
    {
      title: <Trans>ประเภทแบบประเมิน</Trans>,
      dataIndex: 'formTypeName',
      width: '30%',
      sorter: true,
    },
    {
      title: <Trans>สถานะ</Trans>,
      dataIndex: 'isActive',
      width: '20%',
      sorter: true,
    },
    {
      title: '',
      dataIndex: 'options',
      width: '10%',
    },
  ]
}

export const columnsDetails = ({ editable, statusEdit, fncDelete = () => {}, fncButtonGroup = () => {}, menu }) => {
  return [
    {
      title: <Trans>กลุ่มคำถาม</Trans>,
      dataIndex: 'formGroupName',
      width: '70%',
    },
    {
      title: <Trans>สถานะ</Trans>,
      dataIndex: 'isActive',
      width: '20%',
      render: (item) => {
        return <Status key={'status-form-1'} text={item === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}></Status>
      },
    },
    {
      title: '',
      dataIndex: 'options',
      width: '10%',
      render: (item, record) => {
        const icons = []
        if (!editable && statusEdit !== true) {
          icons.push({
            type: 'view',
            onClick: () => {
              fncButtonGroup(record, 'view')
            },
          })
          icons.push({
            type: 'edit',
            onClick: () => {
              fncButtonGroup(record, 'edit')
            },
          })
          icons.push({
            type: 'delete',
            onClick: () => {
              fncDelete(record)
            },
          })
        } else {
          icons.push({
            type: 'view',
            onClick: () => {
              fncButtonGroup(record, 'view')
            },
          })
        }
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} size={14} icons={icons} />
          </div>
        )
      },
    },
  ]
}

export const columnsExpand = ({ fncQuestDelete = () => {}, fncButtonQuest = () => {}, editable, statusEdit, menu }) => {
  return [
    {
      title: <Trans>คำถาม</Trans>,
      dataIndex: 'formQuestName',
      key: 'formQuestName',
      render: (item) => {
        return <p>{item}</p>
      },
    },
    {
      title: <Trans>ประเภทคำตอบ</Trans>,
      dataIndex: 'choiceTypeName',
      key: 'choiceType',
      render: (item) => {
        return <p>{item}</p>
      },
    },
    {
      title: <Trans>จำเป็นต้องตอบ</Trans>,
      dataIndex: 'formRequiredName',
      key: 'formRequiredName',
      render: (item) => {
        return <p>{item}</p>
      },
    },
    {
      title: <Trans>คำอธิบายเพิ่มเติม</Trans>,
      dataIndex: 'formQuestTypeName',
      key: 'formQuestTypeName',
      render: (item) => {
        return <p>{item}</p>
      },
    },
    {
      title: <Trans>สถานะ</Trans>,
      key: 'state',
      render: (item) => {
        return <Status key={'status-sub-detail-1'} text={item.state === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}></Status>
      },
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      render: (item, record) => {
        // item, record, index
        const icons = []
        if (!editable && statusEdit !== true) {
          icons.push({
            type: 'view',
            onClick: () => {
              // fncDelete(index)
              fncButtonQuest(record, 'view')
            },
          })
          icons.push({
            type: 'edit',
            onClick: () => {
              fncButtonQuest(record, 'edit')
            },
          })
          icons.push({
            type: 'delete',
            onClick: () => {
              fncQuestDelete(record)
            },
          })
        } else {
          icons.push({
            type: 'view',
            onClick: () => {
              fncButtonQuest(record, 'view')
            },
          })
        }
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} size={14} icons={icons} />
          </div>
        )
      },
    },
  ]
}
