import { gql } from '@apollo/client'
// For RiskFormFirstStep
export const CREATE_RISK_FORM = gql`
  mutation createRiskForm($input: RiskFormInput!) {
    createRiskForm(input: $input) {
      riskFormCode
      riskFormTH
    }
  }
`

export const UPDATE_RISK_FORM = gql`
  mutation updateRiskForm($input: RiskFormInput!) {
    updateRiskForm(input: $input) {
      riskFormCode
      riskFormTH
    }
  }
`

export const DELETE_RISK_FORM = gql`
  mutation deleteRiskForms($input: RiskFormCodeInput!) {
    deleteRiskForms(input: $input) {
      riskFormCode
    }
  }
`

export const SEND_MAIL_RISK_FORM = gql`
  mutation sendMailRiskForm($input: sendMailRiskFormInput!) {
    sendMailRiskForm(input: $input) {
      riskFormCode
    }
  }
`
