import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { colunmTab3 } from '../../utils/columnAllInRick'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { ButtonNew } from '../../../../../components/button/Button'
// import { Alert } from '../../../../../components/alert/Alert'
import RiskFactorType2Modal from '../modal/RiskFactorType2Modal'
import { checkLanguageReturnData } from '../../components/fnForTemplateRisk'
import { Status } from '../../../../../components/status/Status'
import { useLocation } from 'react-router-dom'
import * as _ from 'lodash'

export default function RiskModelFactorDataType2(props) {
  const Responsive = {
    md: 24,
    lg: 24,
  }
  const { menu, getValue } = props
  const { state } = useLocation()
  const { t } = useTranslation()
  const isLang = localStorage.getItem('lang')
  // const [page, setPage] = useState(1)
  // const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [visible, setVisible] = useState(false)
  // const [getRskObj, setRskObj] = useState([])

  /* ---------------- ACTION ----------- */
  const rskModelDelete = (no) => {
    props.setDelete(no)
  }

  const handleFormOnClose = (e) => {
    setVisible(e)
  }

  useEffect(() => {
    const obj = props.objType2.map((dt, key) => {
      return {
        key: key,
        no: dt.riskNo ? Number(dt.riskNo) : Number(key + 1),
        riskModelFacId: dt.riskModelFacId,
        rateFacGroupCode: dt.rateFacGroupCode,
        riskModelFacType: dt.riskModelFacType,
        riskFactorName:
          dt.rateFacGroupCode + ' : ' + checkLanguageReturnData(isLang, dt.rateFacGroupNameTH, dt.rateFacGroupNameEN),
        isActive: <Status key={'status-apl-1' + key} text={dt.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}></Status>,
        options:
          state.pageType === 'edit' ? (
            <div key={'div-view-templaterick' + 1} style={{ textAlign: 'left' }}>
              <ButtonGroup
                key={'btn-view-templaterick' + 1}
                menu={menu}
                icons={[
                  {
                    type: 'delete',
                    onClick: () => rskModelDelete(dt.riskNo),
                  },
                ]}
              />
            </div>
          ) : (
            ''
          ),
      }
    })
    const dataUniq = _.uniqBy(obj, 'no')
    setCount(dataUniq.length)
    setDataLists(_.orderBy(dataUniq, ['no'], ['asc']))
  }, [])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col {...Responsive}>
          <Datatable
            columns={colunmTab3}
            data={dataLists}
            total={count}
            isScoll={{ x: true, y: true }}
            paginationCustom={false}
            pagination={true}
            pageSize={10}
            page={1}
            btnAdd={
              <>
                {state.pageType === 'edit' ? (
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    onClick={() => setVisible(true)}
                  >
                    {t('เพิ่มเกณฑ์การประเมิน')}
                  </ButtonNew>
                ) : (
                  ''
                )}
              </>
            }
          />
        </Col>
      </Row>
      <RiskFactorType2Modal
        open={visible}
        rskObj={(v) => {
          getValue(v)
        }}
        onClose={(e) => {
          handleFormOnClose(e)
        }}
        menu={menu}
      />
    </>
  )
}
