import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_EDUCATION_PROGRAMS } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectEducationPrograms = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { educationProgramId, educationProgramCode, educationProgramTH, educationProgramEN, isActive } = filters || {}

  const [isData, isSetData] = useState([])

  const [getMasterEducationProgramsFn, getMasterEducationPrograms] = useLazyQuery(GET_MASTER_EDUCATION_PROGRAMS)

  useEffect(() => {
    getMasterEducationProgramsFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [educationProgramId, educationProgramCode, educationProgramTH, educationProgramEN, isActive])

  useEffect(() => {
    if (getMasterEducationPrograms.data) {
      isSetData(
        _.map(getMasterEducationPrograms?.data?.getMasterEducationPrograms, (item) => {
          return {
            label: displayText(item?.educationProgramTH, item?.educationProgramEN),
            value: item?.educationProgramCode,
            source: item,
          }
        }),
      )
    }
  }, [getMasterEducationPrograms.data])
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterEducationPrograms.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectEducationPrograms
