import { gql } from '@apollo/client'

export const GET_MASTER_EDUCATION_LEVELS = gql`
  query getMasterEducationLevels($input: MasterEducationLevelsInput!) {
    getMasterEducationLevels(data: $input) {
      educationLevelId
      educationLevelCode
      educationLevelTH
      educationLevelEN
      isActive
    }
  }
`
