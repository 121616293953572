import React from 'react'
import { ProgressNew } from '../../../components/progress/Progress'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'

const ProgressIndex = () => {
  let code = ` import { ProgressNew } from '../../../components/progress/Progress'

    export default function App() {
        return ( 
            <ProgressNew percent={10} typecolor="primary" />
            <ProgressNew percent={30} typecolor="primary" status={'active'} />
            <ProgressNew percent={70} typecolor="danger" status={'exception'} />
            <ProgressNew percent={100} typecolor="success" />
            <ProgressNew percent={50} typecolor="primary" showInfo={false} />
        )
    }
`

  return (
    <React.Fragment>
      <div className="site-layout-background">
        <div style={{ marginBottom: '15px' }}>
          <ProgressNew percent={10} typecolor="primary" />
          <ProgressNew percent={30} typecolor="primary" status={'active'} />
          <ProgressNew percent={70} typecolor="danger" status={'exception'} />
          <ProgressNew percent={100} typecolor="success" />
          <ProgressNew percent={50} typecolor="primary" showInfo={false} />
          <CodeBlock code={code} />
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProgressIndex
