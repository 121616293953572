import React, { useState } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { TabNew } from '../../../components/tab/Tab.jsx'
import { useNavigate } from 'react-router-dom'
import { ButtonNew } from '../../../components/button/Button'
import { CardNew } from '../../../components/card/Card'
import { LabelNew } from '../../../components/label/Label'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { getSegmentUrl } from '../../../utilitys/helper'
import SelectJobTypes from '../../../components/inputfromapi/selectjobtypes/SelectJobTypes.jsx'
import SelectJobStatus from '../../../components/inputfromapi/selectjobstatus/SelectJobStatus.jsx'
import SelectJobGroups from '../../../components/inputfromapi/selectjobgroups/SelectJobGroups.jsx'
import SelectProjectTypes from '../../../components/inputfromapi/selectprojecttypes/SelectProjectTypes.jsx'
import SelectRequestStatus from '../../../components/inputfromapi/selectrequeststatus/SelectRequestStatus.jsx'
import TimeSheetList from './TimeSheetList.jsx'
import TimeSheetRequestList from './TimeSheetRequestList.jsx'

const TimeSheetIndex = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const Responsive = {
    md: 8,
    lg: 8,
  }
  const ResponsiveTable = {
    md: 24,
    lg: 24,
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/timesheet' },
    { label: t('บันทึกการปฏิบัติงาน') },
  ]

  const [form] = Form.useForm()
  const params = getSegmentUrl(3)
  const navigate = useNavigate()
  const [filters, setFilters] = useState({})
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [fieldJobGroup, setFieldJobGroup] = useState()
  const [disabled /* setDisabled */] = useState(false)
  const [activeTab, setActiveTab] = useState(!params ? 'timesheet' : params)

  const [tabs, setTabs] = useState([
    {
      key: 'timesheet',
      iconTab: () => <></>,
      name: t('การปฏิบัติงานรายวัน'),
      disabled: false,
      content: () => <TimeSheetList menu={menu} filters={filters} />,
    },
    {
      key: 'requestapprove',
      iconTab: () => <></>,
      name: t('คำขออนุมัติ'),
      disabled: false,
      content: () => <TimeSheetRequestList menu={menu} filters={filters} />,
    },
  ])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    setFilters({ ...filters, ...input })
    setTabs([
      {
        key: 'timesheet',
        iconTab: () => <></>,
        name: t('การปฏิบัติงานรายวัน'),
        disabled: false,
        content: () => <TimeSheetList menu={menu} filters={{ ...filters, ...input }} />,
      },
      {
        key: 'requestapprove',
        iconTab: () => <></>,
        name: t('คำขออนุมัติ'),
        disabled: false,
        content: () => <TimeSheetRequestList menu={menu} filters={{ ...filters, ...input }} />,
      },
    ])
  }

  const resetField = () => {
    // form.resetFields()
    setFilters({})
    if (activeTab === 'timesheet') {
      form.resetFields(['jobGroupCode', 'projectTypeCode', 'jobTypeCode', 'jobStatusCode', 'dateStart', 'dateEnd'])
    } else if (activeTab === 'requestapprove') {
      form.resetFields(['requestStatusCode', 'dateStart', 'dateEnd'])
    }
  }

  return (
    <>
      <Row>
        <Col {...ResponsiveTable}>
          <Breadcrumb data={breadcrumbList} title={t('บันทึกการปฏิบัติงาน')} menu={menu} />
          <CardNew topic={t('Filters')} icon="Filter" toggledrop="true" buttontopright="true">
            <Form name="form-search" form={form} onFinish={onFinish} layout="vertical">
              <Row gutter={[12, 0]}>
                {activeTab === 'timesheet' && (
                  <>
                    <Col {...Responsive}>
                      <Form.Item
                        name="jobGroupCode"
                        label={<LabelNew type="tab-header-inactive">{t('ลักษณะงาน')}</LabelNew>}
                      >
                        <SelectJobGroups
                          placeholder={t('เลือกลักษณะงาน')}
                          formname={'form-search'}
                          filters={{ isActive: 1 }}
                          handleChange={(e) => {
                            form.setFieldsValue({ jobGroupCode: e?.value }),
                              setFieldJobGroup(e?.value),
                              form.resetFields(['projectTypeCode', 'jobTypeCode'])
                          }}
                          onClear={() => {
                            setFieldJobGroup()
                          }}
                          disabled={disabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col {...Responsive}>
                      {fieldJobGroup === '10' ? (
                        <Form.Item
                          name="projectTypeCode"
                          label={<LabelNew type="tab-header-inactive">{t('ประเภท')}</LabelNew>}
                        >
                          <SelectProjectTypes
                            placeholder={t('เลือกประเภทโครงการ')}
                            formname={'form-search'}
                            filters={{ isActive: 1 }}
                            handleChange={(e) => {
                              form.setFieldsValue({ projectTypeCode: e?.value })
                            }}
                            disabled={disabled ? disabled : fieldJobGroup ? false : true}
                          />
                        </Form.Item>
                      ) : (
                        <Form.Item
                          name="jobTypeCode"
                          label={<LabelNew type="tab-header-inactive">{t('ประเภท')}</LabelNew>}
                        >
                          <SelectJobTypes
                            placeholder={t('เลือกประเภทงาน')}
                            formname={'form-search'}
                            filters={{ jobGroupCode: fieldJobGroup || null, isActive: 1 }}
                            handleChange={(e) => {
                              form.setFieldsValue({ jobTypeCode: e?.value })
                            }}
                            disabled={disabled ? disabled : fieldJobGroup ? false : true}
                          />
                        </Form.Item>
                      )}
                    </Col>
                  </>
                )}
                <Col {...Responsive}>
                  {activeTab === 'timesheet' ? (
                    <Form.Item
                      name="jobStatusCode"
                      label={<LabelNew type="tab-header-inactive">{t('สถานะดำเนินการ')}</LabelNew>}
                    >
                      <SelectJobStatus
                        placeholder={t('เลือกสถานะดำเนินการ')}
                        formname={'form-search'}
                        filters={{ statusflag: 1, isActive: 1 }}
                        handleChange={(e) => form.setFieldsValue({ jobStatusCode: e?.value })}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      name="requestStatusCode"
                      label={<LabelNew type="tab-header-inactive">{t('สถานะพิจารณา')}</LabelNew>}
                    >
                      <SelectRequestStatus
                        placeholder={t('เลือกสถานะพิจารณา')}
                        formname={'form-search'}
                        filters={{ isActive: 1 }}
                        handleChange={(e) => form.setFieldsValue({ requestStatusCode: e.value })}
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'dateStart'}
                    label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <Datepicker
                      autoComplete="off"
                      setValueDateFn={async (data) => {
                        await setIsMinDate(data), await form.setFieldsValue({ dateStart: await data })
                      }}
                      onClear={() => {
                        form.setFieldsValue({ dateStart: null })
                      }}
                      placeholder={t('DD/MM/YYYY')}
                      isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responsive}>
                  <Form.Item
                    name={'dateEnd'}
                    label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                    rules={[
                      {
                        required: true,
                        message: t('กรุณาระบุ'),
                      },
                    ]}
                  >
                    <Datepicker
                      autoComplete="off"
                      setValueDateFn={async (data) => {
                        await setIsMaxDate(data), await form.setFieldsValue({ dateEnd: await data })
                      }}
                      onClear={() => {
                        form.setFieldsValue({ dateEnd: null })
                      }}
                      placeholder={t('DD/MM/YYYY')}
                      isMinDate={isMinDate ? new Date(isMinDate) : null}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew type="search" htmlType="submit">
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew type="clear" onClick={() => resetField()}>
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </CardNew>
          <CardNew topic={t('รายการปฏิบัติงาน')} icon="List" toggledrop="false">
            <Row gutter={[12, 12]}>
              <Col {...ResponsiveTable}>
                <TabNew
                  tab={tabs}
                  activeKey={!params ? 'timesheet' : params}
                  onTabClick={(key) => {
                    if (key === 'timesheet') {
                      setActiveTab('timesheet')
                      navigate('/auditoperations/timesheet')
                    } else if (key === 'requestapprove') {
                      setActiveTab('requestapprove')
                      navigate('/auditoperations/timesheet/requestapprove')
                    }
                  }}
                />
              </Col>
            </Row>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default TimeSheetIndex
