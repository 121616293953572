import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Form, Space } from 'antd'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { InputNew } from '../../../components/input/Input'
import { LabelNew as Label, LabelNew } from '../../../components/label/Label'
import { TabNew } from '../../../components/tab/Tab'
import { ButtonNew } from '../../../components/button/Button'
import { Radio, RadioGroup } from '../../../components/radio/Radio'
import { regexEng, regexThai, displayError } from '../../../utilitys/helper'
import { Alert } from '../../../components/alert/Alert'
import { useLazyQuery, useMutation } from '@apollo/client'
import { RISK_FORMULAR_SAVE } from './graphql/Mutation'
import { encryptInput } from '../../../utilitys/crypto'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { GET_RISK_FORMULAR_BY_CODE } from './graphql/Query'
import RiskFormulaVariableTab from './component/tab/RiskFormulaVariableTab'
import CalculateAndScoreTab from './component/tab/CalculateAndScoreTab'
import VariableModal from './component/modal/VariableModal'

const RiskFormulaEdit = (props) => {
  const formname = 'RiskFormulaEditForm'
  const [form] = Form.useForm()
  const { state } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { menu } = props
  const labelCol = { sm: 8, md: 8, lg: { span: 6, offset: 2 }, xl: { span: 6, offset: 2 } }
  const wrapperCol = { sm: 16, md: 16, lg: 10, xl: 10, align: 'left' }
  const Responsive = { md: 24, lg: 24 }
  const [stateVariableData, setStateVariableData] = useState([])
  const [stateCalculateData, setStateCalculateData] = useState([])
  const [stateScoreData, setStateScoreData] = useState()
  const [selectVariableData, setSelectVariableData] = useState()
  const [editable, seteditable] = useState(false)
  const [stateDetail, setStateDetail] = useState()
  const [visible, setVisible] = useState(false)
  const [stateFormRiskFormula, setStateFormRiskFormula] = useState(null)
  const [stateDisableBtn, setStateDisableBtn] = useState(false)
  const [activeKey, setActiveKey] = useState('1')

  if (!state && state?.riskFormulaCode) return <Navigate to={'/administrator/riskformula'} />

  const riskFormulaVariableTabFn = (stateVariableData, editable) => {
    return (
      <RiskFormulaVariableTab
        data={stateVariableData}
        menu={menu}
        disabled={editable}
        riskFormulaCode={state?.riskFormulaCode}
        setVisible={(e) => setVisible(e)}
        setStateDetail={(e) => setStateDetail(e)}
        onDeleteDataRiskFormulaVariable={(e) => e && dataRiskFormulaByCode.refetch()}
      />
    )
  }

  const calculateAndScoreTab = (selectVariableData, stateCalculateData, stateScoreData, stateFormRiskFormula) => {
    return (
      <CalculateAndScoreTab
        variableData={selectVariableData}
        calculateData={stateCalculateData}
        scoreData={stateScoreData}
        menu={menu}
        disabled={editable}
        riskFormulaCode={state?.riskFormulaCode}
        stateFormRiskFormula={stateFormRiskFormula}
      />
    )
  }

  const [tabs, setTabs] = useState([
    {
      key: '1',
      iconTab: () => <></>,
      name: t('จัดการตัวแปร'),
      disabled: false,
      content: () => riskFormulaVariableTabFn(stateVariableData, editable),
    },
    {
      key: '2',
      iconTab: () => <></>,
      name: t('จัดการสูตรคำนวณ'),
      disabled: false,
      content: () => calculateAndScoreTab(selectVariableData, stateCalculateData, stateScoreData, stateFormRiskFormula),
    },
  ])

  const arrCrumb = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/riskformula' },
    { label: t('จัดการสูตรประเมินความเสี่ยง'), path: '/administrator/riskformula' },
    { label: t('แก้ไขสูตรประเมินความเสี่ยง') },
  ]

  /* ---------------- API ----------- */
  const [getRiskFormulaByCodeFn, dataRiskFormulaByCode] = useLazyQuery(GET_RISK_FORMULAR_BY_CODE)
  const [
    riskFormulaSaveFn,
    { loading: loadingRiskFormulaSave, error: errorRiskFormulaSave, data: dataRiskFormulaSave },
  ] = useMutation(RISK_FORMULAR_SAVE)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (state) {
      getRiskFormulaByCodeFn({ variables: { riskFormulaCode: encryptInput(state?.riskFormulaCode) } })
      if (state?.type_action === 'edit') seteditable(true)
    }
  }, [state])

  useEffect(() => {
    if (dataRiskFormulaSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/administrator/riskformula')
        },
      })
    }
    if (errorRiskFormulaSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorRiskFormulaSave)),
        onOk() {},
      })
    }
  }, [dataRiskFormulaSave])

  useEffect(() => {
    if (dataRiskFormulaByCode?.data) {
      const response = dataRiskFormulaByCode?.data?.getRiskFormulaByCode

      const listRiskFormula = response?.riskFormula
      const listRiskFormulaVariables = response?.riskFormulaVariables
      const listRiskFormulaCalculates = response?.riskFormulaCalculates
      const listRiskFormulaScores = response?.riskFormulaScores
      const newListRiskFormulaVariables = listRiskFormulaVariables.map((items) => ({
        value: items.variableCode,
        label: items.variableName,
      }))

      form.setFieldsValue({
        riskFormulaCode: listRiskFormula.riskFormulaCode,
        riskFormulaNameTH: listRiskFormula.riskFormulaNameTH,
        riskFormulaNameEN: listRiskFormula.riskFormulaNameEN,
        isActive: listRiskFormula.isActive,
      })
      setStateVariableData(listRiskFormulaVariables)
      setStateCalculateData(listRiskFormulaCalculates)
      setStateScoreData(listRiskFormulaScores)
      setSelectVariableData(newListRiskFormulaVariables)

      setTabs([
        {
          key: '1',
          iconTab: () => <></>,
          name: t('จัดการตัวแปร'),
          disabled: false,
          content: () => riskFormulaVariableTabFn(listRiskFormulaVariables, editable),
        },
        {
          key: '2',
          iconTab: () => <></>,
          name: t('จัดการสูตรคำนวณ'),
          disabled: false,
          content: () =>
            calculateAndScoreTab(
              newListRiskFormulaVariables,
              listRiskFormulaCalculates,
              listRiskFormulaScores,
              stateFormRiskFormula,
            ),
        },
      ])
    }
    if (dataRiskFormulaByCode?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(dataRiskFormulaByCode?.error)),
        onOk() {},
      })
    }
  }, [dataRiskFormulaByCode?.data])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => showConfirm(input)

  const showConfirm = (input) => {
    let dataInput = {
      ...input,
      riskFormulaNameEN: input.riskFormulaNameEN ? input.riskFormulaNameEN : '',
      action: 'edit',
      isActive: parseInt(input.isActive),
    }
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        riskFormulaSaveFn({ variables: { input: encryptInput(dataInput) } })
      },
      onCancel() {},
    })
  }

  return (
    <>
      {(loadingRiskFormulaSave.loading || dataRiskFormulaByCode.loading) && <SpinnersNew />}
      <Col {...Responsive}>
        <Breadcrumb data={arrCrumb} title={t('แก้ไขสูตรประเมินความเสี่ยง')} />
        <CardNew topic={t('แก้ไขสูตรประเมินความเสี่ยง')} icon="AlignJustify" toggledrop={'false'}>
          <Form
            form={form}
            autoComplete="off"
            labelAlign="left"
            labelCol={{ ...labelCol }}
            wrapperCol={{ ...wrapperCol }}
            layout="horizontal"
            name={formname}
          >
            <Row gutter={[12, 12]}>
              <Col {...Responsive}>
                <Form.Item
                  name="riskFormulaCode"
                  label={<Label type="tab-header-inactive">{t('รหัสสูตรประเมินความเสี่ยง')}</Label>}
                >
                  <InputNew placeholder={t('ระบุรหัสสูตรประเมินความเสี่ยง')} maxLength={10} disabled={true} />
                </Form.Item>
                <Form.Item
                  name="riskFormulaNameTH"
                  label={<Label type="tab-header-inactive">{t('ชื่อสูตรประเมินความเสี่ยงภาษาไทย')}</Label>}
                  rules={[
                    { required: true, message: t('กรุณากรอกชื่อสูตรประเมินความเสี่ยงภาษาไทย') },
                    {
                      pattern: regexThai,
                      message: t(`กรุณากรอกด้วยภาษาไทยหรือตัวเลข ก-๙, 0-9`),
                    },
                  ]}
                >
                  <InputNew
                    placeholder={t('กรอกชื่อสูตรประเมินความเสี่ยงภาษาไทย')}
                    maxLength={100}
                    disabled={!editable}
                    onChange={() => setStateFormRiskFormula(form.getFieldsValue())}
                  />
                </Form.Item>
                <Form.Item
                  name="riskFormulaNameEN"
                  label={<Label type="tab-header-inactive">{t('ชื่อสูตรประเมินความเสี่ยงภาษาอังกฤษ')}</Label>}
                  rules={[
                    {
                      pattern: regexEng,
                      message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
                    },
                  ]}
                >
                  <InputNew
                    placeholder={t('ระบุชื่อสูตรประเมินความเสี่ยงภาษาอังกฤษ')}
                    maxLength={100}
                    disabled={!editable}
                    onChange={() => setStateFormRiskFormula(form.getFieldsValue())}
                  />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>}
                  name="isActive"
                  initialValue={0}
                >
                  <RadioGroup onChange={() => setStateFormRiskFormula(form.getFieldsValue())}>
                    <Space size={[8, 8]} wrap>
                      <Radio value={1} disabled={!editable}>
                        {<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}
                      </Radio>
                      &nbsp;
                      <Radio value={0} disabled={!editable}>
                        {<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}
                      </Radio>
                    </Space>
                  </RadioGroup>
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Row>
            <Col {...Responsive}>
              <LabelNew type="body-header">{t('รายการสูตรประเมินความเสี่ยง')}</LabelNew>
              <TabNew
                tab={tabs}
                activeKey={activeKey}
                onTabClick={async (e) => {
                  await setActiveKey(e)
                  await setStateDisableBtn(e === '2' ? true : false)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24} align="center" style={{ marginTop: '40px' }}>
              <Space size={[8, 8]} wrap>
                {!stateDisableBtn && (
                  <>
                    {editable && (
                      <ButtonNew
                        type="primary"
                        roles={{ type: 'add', menu: menu }}
                        htmlType="submit"
                        onClick={() => onFinish(form.getFieldsValue())}
                      >
                        {t('บันทึก')}
                      </ButtonNew>
                    )}
                    <ButtonNew type="back" onClick={() => navigate('/administrator/riskformula')}>
                      {t('ยกเลิก')}
                    </ButtonNew>
                  </>
                )}
              </Space>
            </Col>
          </Row>
        </CardNew>
      </Col>
      {visible && (
        <VariableModal
          open={visible}
          close={(e) => {
            setVisible(e)
            setStateDetail()
          }}
          lockVariableCode={stateVariableData}
          refetch={() => dataRiskFormulaByCode.refetch()}
          dataParams={stateDetail}
          menu={menu}
          riskFormulaCode={state?.riskFormulaCode}
        />
      )}
    </>
  )
}
export default RiskFormulaEdit
