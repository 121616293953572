import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { ButtonNew } from '../../../components/button/Button'
import { InputNew } from '../../../components/input/Input'
import { useNavigate } from 'react-router-dom'
import { LabelNew } from '../../../components/label/Label'
import { CardNew } from '../../../components/card/Card'
import { Alert } from '../../../components/alert/Alert'
import { useMutation, useLazyQuery } from '@apollo/client'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import SelectAssignJobs from '../../../components/inputfromapi/selectAssignJobs/SelectAssignJobs.jsx'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb.jsx'
import { TextAreaNew } from '../../../components/textarea/TextArea'
import { dateStringToPicker } from '../../../utilitys/dateHelper'
import { displayText } from '../../../utilitys/helper'
import { UploadNew } from '../../../components/upload/Upload'
import { CONSULTATION_RESULT_SAVE } from './graphql/Mutation'
import { GET_CONSULTATION_RESULT } from './graphql/Query'
import { displayError } from '../../../utilitys/helper'

const ConsultationResultForm = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()

  const userLogin = decryptData(localStorage.getItem('user'))

  const [file, setFile] = useState([])

  const [idFileDelete, setIdFileDelete] = useState([])
  const [idFileDefault, setIdFileDefault] = useState([])

  const [formType] = useState(state.type_action)
  const [saveData, { loading: loadingSave, data: dataSave, error: errorSave }] = useMutation(CONSULTATION_RESULT_SAVE)
  const [dataConsultFn, dataConsult] = useLazyQuery(GET_CONSULTATION_RESULT)
  useEffect(() => {
    if (state.refCode) {
      const dataInput = {
        consultId: state.refCode,
      }

      dataConsultFn({ variables: { input: encryptInput(dataInput) } })
    }
  }, [state.refCode])

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations' },
    { label: t('บันทึกการให้คำปรึกษา'), path: '/auditoperations/consultationResult' },
  ]
  //submit Data to API
  const onSubmit = (values) => {
    let dataInput = {
      consultId: state.refCode || '',
      jobCode: values.jobTypeCode,
      Subject: values.Subject || '',
      fact: values.fact,
      result: values.result,
      assessment: values.assessment,
      resultDate: values.resultDate,
      fileDetail: values.fileDetail || '',
      consultStatus: values.consultStatus,
      files: file.join(),
      action: state.type_action,
      idFileDelete: idFileDelete.join(),
    }
    if (props.type_action === 'add') {
      dataInput['action'] = 'add'
    } else if (props.type_action === 'edit') {
      dataInput['action'] = 'edit'
    }
    saveData({
      variables: {
        input: encryptInput(dataInput),
      },
    })
  }

  const [form] = Form.useForm()
  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  useEffect(() => {
    // check completed modal
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/auditoperations/consultationresult')
        },
      })
    }
  }, [dataSave])

  //ShowError
  useEffect(() => {
    if (errorSave && errorSave.message) {
      showAlertError(displayError(errorSave))
    }
  }, [errorSave])

  useEffect(() => {
    // Get value From your API
    // Set Form value on formType = UPDATE
    if (formType === 'edit' || formType === 'view') {
      if (dataConsult.data) {
        const response = dataConsult.data.getConsultationResult

        form.setFieldsValue({
          //SetData from api
          jobTypeCode: response.jobCode,
          organizationCode: displayText(response.requestOrganizationTH, response.requestOrganizationEN),
          requestPersonnel: response.fact,
          Subject: response.Subject,
          fact: response.fact,
          result: response.result,
          assessment: response.assessment,
          telephone: response.telephone,
          resultDate: response.resultDate,
          personnelCode: response.fullName,
          createdDocDate: response.createdAt ? moment(response.createdAt).format('DD/MM/YYYY') : '',
          responsibleOrganization: displayText(response.responsibleOrganizationTH, response.responsibleOrganizationEN),
          fileDetail: response.fileDetail,
          action: state.type_action,
        })
        setFile(() => {
          return response?.files?.split(',').map((item) => {
            return parseInt(item)
          })
        })
        setIdFileDefault(() => {
          return response?.files?.split(',').map((item) => {
            return parseInt(item)
          })
        })
        setIdFileDelete([])
      }
    } else {
      // Set Form value on formType = CREATE
      form.resetFields()
      setFile([])
      setIdFileDefault([])
      setIdFileDelete([])
    }
  }, [dataConsult.data])

  // onFinish
  const onFinish = () => {
    const field = form.getFieldValue()
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        onSubmit(field)
      },
      onCancel() {},
    })
  }

  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((er) => {
      const nameField = er.name[0] || ''
      const message = er.errors[0] || ''
      if (nameField === 'datePicker') setErrorField('datePickerMockUp', message)
    })
  }
  // onValuesChange
  const onValuesChange = () => {}

  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }
  return (
    <>
      {loadingSave ? <SpinnersNew /> : ''}
      <Breadcrumb data={breadcrumbList} title={t('บันทึกการให้คำปรึกษา')} />
      <CardNew topic={<LabelNew type="sub-header-bold">{t('รายละเอียดบันทึกการให้คำปรึกษา')}</LabelNew>} icon="List">
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            sm: {
              span: 6,
              offset: 0,
            },
            md: {
              span: 6,
              offset: 1,
            },
            xl: {
              span: 6,
              offset: 1,
            },
          }}
          wrapperCol={{
            sm: 24,
            md: 10,
            xl: 10,
            align: 'left',
          }}
          layout="horizontal"
          name="consultResultForm"
        >
          <Row>
            <Col md={24}>
              <LabelNew type="sub-header-primary">{t('รายละเอียดทั่วไป')}</LabelNew>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item
                name="jobTypeCode"
                label={t('งาน/เลขที่งาน')}
                rules={[{ required: true, message: t('กรุณาระบุงาน/เลขที่งาน') }]}
              >
                <SelectAssignJobs
                  formname={'consultResultForm'}
                  placeholder={t('เลือกงาน/เลขที่งาน')}
                  filters={{ jobTypeCode: 31, personnelCode: userLogin?.pCode || '' }}
                  disabled={formType === 'view' ? true : false}
                  handleChange={(e) => {
                    form.setFieldsValue({
                      jobTypeCode: e?.value,
                      organizationCode: e?.source?.requestOrganization,
                      requestPersonnel: e?.source?.requestPersonnel,
                      telephone: e?.source?.telephone,
                      createdDocDate: moment(new Date()).format('DD/MM/YYYY'),
                      responsibleOrganization: displayText(
                        e?.source?.responsibleOrganizationTH,
                        e?.source?.responsibleOrganizationEN,
                      ),
                      personnelCode: e?.source?.fullName,
                      //fieldName: e?.source.fieldName
                    })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="organizationCode"
                label={t('หน่วยงาน/สาขาที่ร้องขอ')}
                rules={[{ message: t('กรุณาระบุหน่วยงาน') }]}
              >
                <InputNew
                  placeholder={t('เลือกหน่วยงาน/สาขา')}
                  disabled
                  // filters={{ organizationChecked: 2, isActive: 1 }}
                />
              </Form.Item>
              <Form.Item name="requestPersonnel" label={t('ผู้ร้องขอ')}>
                <InputNew placeholder={t('ผู้ขอคำปรึกษา')} disabled />
              </Form.Item>
              <Form.Item name="telephone" label={t('เบอร์ติดต่อ')}>
                <InputNew placeholder={t('เบอร์ติดต่อ')} disabled />
              </Form.Item>
              <Form.Item name="Subject" label={t('รายละเอียดที่ให้คำปรึกษา/แนะนำ')}>
                <TextAreaNew
                  placeholder={t('รายละเอียดที่ให้คำปรึกษา/แนะนำ')}
                  rows={4}
                  disabled={formType === 'view' ? true : false}
                />
              </Form.Item>
              <Form.Item
                name="fact"
                label={t('ข้อเท็จจริง')}
                rules={[{ required: true, message: t('กรุณาระบุข้อเท็จจริง') }]}
              >
                <TextAreaNew
                  placeholder={t('กรอกข้อเท็จจริง')}
                  rows={4}
                  disabled={formType === 'view' ? true : false}
                />
              </Form.Item>
              <Form.Item
                name="result"
                label={t('สรุปการให้คำปรึกษา')}
                rules={[{ required: true, message: t('กรุณาระบุสถานะปฏิบัติงาน') }]}
              >
                <TextAreaNew
                  placeholder={t('กรอกสรุปการให้คำปรึกษา')}
                  rows={4}
                  disabled={formType === 'view' ? true : false}
                />
              </Form.Item>
              <Form.Item
                name="assessment"
                label={t('ประเมินผลการให้คำปรึกษา')}
                rules={[{ required: true, message: t('กรุณาระบุสถานะปฏิบัติงาน') }]}
              >
                <TextAreaNew
                  placeholder={t('กรอกประเมินผลการให้คำปรึกษา')}
                  rows={4}
                  disabled={formType === 'view' ? true : false}
                />
              </Form.Item>
              <Form.Item
                name="resultDate"
                label={t('วันที่บันทึกเอกสาร')}
                rules={[{ required: true, message: t('กรุณาระบุวันที่บันทึกเอกสาร') }]}
              >
                <Datepicker
                  minDate={dateStringToPicker(new Date(moment(new Date()).subtract(60, 'year')))}
                  maxDate={dateStringToPicker()}
                  disabled={formType === 'view' ? true : false}
                  setValueDateFn={async (data) => {
                    form.setFieldsValue({
                      resultDate: await data,
                    })
                    setErrorField('resultDate', false) // set false error field
                  }}
                  onClear={() => {
                    form.setFieldsValue({ resultDate: null })
                  }}
                />
              </Form.Item>
              <Form.Item name="createdDocDate" label={t('วันที่สร้างเอกสาร')}>
                <InputNew placeholder={t('DD/MM/YYYY')} disabled />
              </Form.Item>
              <Form.Item name="responsibleOrganization" label={t('หน่วยงานที่รับผิดชอบ')}>
                <InputNew placeholder={t('หน่วยงานที่รับผิดชอบ')} disabled />
              </Form.Item>
              <Form.Item name="personnelCode" label={t('ผู้ให้คำปรึกษา/แนะนำ')}>
                <InputNew placeholder={t('ผู้ให้คำปรึกษา/แนะนำ')} disabled />
              </Form.Item>
              <Form.Item name="fileDetail" label={t('ลิงค์')}>
                <InputNew disabled={formType === 'view' ? true : false} />
              </Form.Item>
              <Form.Item label={t('เอกสารเพิ่มเติม')}>
                <UploadNew
                  type="mutiple"
                  setIdFile={setFile}
                  idFile={file}
                  actionType={props.typeForm === 'add' ? 'create' : props.typeForm}
                  idFileDelete={idFileDelete}
                  defaults={idFileDefault}
                  setIdFileDelete={setIdFileDelete}
                  typeFile="file"
                  disabled={props.typeForm === 'view' ? true : false}
                />{' '}
              </Form.Item>
            </Col>
          </Row>
          <Row align="center">
            <Space size={[8, 8]} wrap>
              {formType !== 'view' && (
                <ButtonNew
                  type="primary"
                  onClick={() => {
                    form.setFieldsValue({ consultStatus: 0 })
                  }}
                  menu={menu}
                  htmlType="submit"
                  roles={{ type: formType, menu: menu }}
                >
                  {t('บันทึก')}
                </ButtonNew>
              )}
              {formType !== 'view' && (
                <ButtonNew
                  type="success"
                  onClick={() => {
                    form.setFieldsValue({ consultStatus: 1 })
                  }}
                  menu={menu}
                  htmlType="submit"
                  roles={{ type: formType, menu: 'AT10' }}
                >
                  {t('เสร็จสิ้น')}
                </ButtonNew>
              )}
              <ButtonNew
                type="back"
                onClick={() => {
                  navigate('/auditoperations/consultationresult')
                }}
              >
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Space>
          </Row>
        </Form>
      </CardNew>
    </>
  )
}

export default ConsultationResultForm
