import { gql } from '@apollo/client'

export const GET_MASTER_PROJECT_REFER = gql`
  query getMasterProjectRefer($input: MasterProjectReferInput!) {
    getMasterProjectRefer(data: $input) {
      projectRefId
      projectRefCode
      projectRefNameTH
      projectRefNameEN
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`
