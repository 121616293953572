import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col, Row, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { GET_AUDIT_RCM_RESULT_ITEM } from './graphql/Query'
import { Select } from '../../../components/select/Select'
import { Alert } from '../../../components/alert/Alert'
import { CardNew } from '../../../components/card/Card'
import { ButtonNew } from '../../../components/button/Button'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Datatable } from '../../../components/datatable/Datatable'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText, displayError } from '../../../utilitys/helper'
import { racMatrixApproveListCrumb } from './utils/constants'
import { colors } from '../../../configs/styles.config'

const RacMatrixApproveRiskFormTopic = (props) => {
  const responsiveLabel = { sm: 10, md: 8, lg: 6 }
  const responsiveData = { sm: 14, md: 16, lg: 18 }

  const { auditResult } = props

  const { t } = useTranslation()

  const [personnelName, setPersonnelName] = useState(null)

  useEffect(() => {
    const personnelRCMName = displayText(auditResult?.personnelRCMNameTH, auditResult?.personnelRCMNameEN)

    setPersonnelName(personnelRCMName ? personnelRCMName : `-`)
  }, [auditResult])

  return (
    <CardNew topic={t('รายละเอียดความเสี่ยงกระบวนการ')} icon="Info" toggledrop={'false'}>
      <Col span={24} offset={2}>
        <Row gutter={[16, 24]}>
          <Col {...responsiveLabel}>{t('กระบวนการหลัก')}</Col>
          <Col {...responsiveData} style={{ color: colors.primary }}>
            {`${displayText(auditResult?.riskModelTH, auditResult?.riskModelEN)}`}
          </Col>

          <Col {...responsiveLabel}>{t('กลุ่มความเสี่ยงกระบวนการ')}</Col>
          <Col {...responsiveData} style={{ color: colors.primary }}>
            {`${displayText(auditResult?.rateFacGroupNameTH, auditResult?.rateFacGroupNameEN)}`}
          </Col>

          <Col {...responsiveLabel}>{t('ความเสี่ยงกระบวนการ')}</Col>
          <Col {...responsiveData} style={{ color: colors.primary }}>
            {`${auditResult?.shortCode}: ${displayText(auditResult?.rateFacProNameTH, auditResult?.rateFacProNameEN)}`}
          </Col>

          <Col {...responsiveLabel}>{t('ผู้รับผิดชอบ')}</Col>
          <Col {...responsiveData} style={{ color: colors.primary }}>
            {`${personnelName}`}
          </Col>
        </Row>
      </Col>
    </CardNew>
  )
}

const RacMatrixApproveRiskForm = () => {
  const wrapper = { sm: 24, md: 24, lg: 24 }
  const formname = 'RacMatrixApproveRiskForm'

  const selectData = [
    { label: '0', value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
  ]

  const navigate = useNavigate()
  const { state } = useLocation()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  if (!state || !state?.rcmResultId) return <Navigate to={'/auditoperations/racmatrixapprove'} />

  const [dataLists, setDataLists] = useState([])

  const [getAuditRCMResultItemFn, getAuditRCMResultItem] = useLazyQuery(GET_AUDIT_RCM_RESULT_ITEM)

  useEffect(() => {
    if (state?.rcmResultId) {
      getAuditRCMResultItemFn({ variables: { rcmResultId: encryptInput(state?.rcmResultId) } })
    }
  }, [state?.rcmResultId])

  useEffect(() => {
    if (getAuditRCMResultItem?.data) {
      const result = getAuditRCMResultItem?.data?.getAuditRCMResultItem?.resultDetails

      const ansResult = _.map(result, (d) => {
        return {
          key: d.rcmResultDetailId,
          rcmResultDetailId: d.rcmResultDetailId,
          riskFactorName: displayText(d.riskFactorNameTH, d.riskFactorNameEN),
          likelihood: d.likelihood,
          impact: d.impact,
        }
      })

      const setForm = {}
      for (const d of result) setForm[d.rcmResultDetailId] = { impact: d.impact, likelihood: d.likelihood }

      form.setFieldsValue(setForm)
      setDataLists(ansResult)
    }

    if (getAuditRCMResultItem.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditRCMResultItem?.error)),
        onOk() {},
      })
    }
  }, [getAuditRCMResultItem?.data])

  const columns = [
    {
      title: t('ความเสี่ยง'),
      dataIndex: 'riskFactorName',
      width: '65%',
      align: 'left',
    },
    {
      title: t('มีโอกาสเกิด'),
      dataIndex: 'likelihood',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        return (
          <Form.Item name={[record.key, 'likelihood']}>
            <Select data={selectData} placeholder={t('เลือก')} scrollableId={formname} disabled={true} showSearch />
          </Form.Item>
        )
      },
    },
    {
      title: t('มีผลกระทบ(ข้อ)'),
      dataIndex: 'impact',
      width: '10%',
      align: 'center',
      render: (text, record) => {
        return (
          <Form.Item name={[record.key, 'impact']}>
            <Select data={selectData} placeholder={t('เลือก')} scrollableId={formname} disabled={true} showSearch />
          </Form.Item>
        )
      },
    },
  ]

  return (
    <>
      {getAuditRCMResultItem?.loading && <SpinnersNew />}
      <Col {...wrapper}>
        <Breadcrumb data={racMatrixApproveListCrumb} title={t('รายละเอียดประเมินความเสี่ยงและการควบคุม')} />
        <RacMatrixApproveRiskFormTopic auditResult={getAuditRCMResultItem?.data?.getAuditRCMResultItem?.result} />
        <CardNew topic={displayText(t('รายละเอียดประเมินความเสี่ยงและการควบคุม'))} icon="List" toggledrop={'false'}>
          <Row gutter={[12, 12]}>
            <Col {...wrapper}>
              <Form name={formname} form={form} layout="vertical">
                <Datatable columns={columns} data={dataLists} />
                <Row gutter={[12, 20]} justify="center" style={{ marginTop: '2rem' }}>
                  <Col>
                    <ButtonNew
                      type="back"
                      onClick={() => navigate('/auditoperations/racmatrixapprove/tab/risk', { state: { ...state } })}
                    >
                      {t('ย้อนกลับ')}
                    </ButtonNew>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </CardNew>
      </Col>
    </>
  )
}

RacMatrixApproveRiskForm.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default RacMatrixApproveRiskForm
