import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'

const DatepickerIndex = () => {
  const [form] = Form.useForm()
  const [isDate, setIsDate] = useState({
    ex1: null,
    ex2: null,
    ex3: null,
  })

  const stlyes = { marginBottom: '20px' }

  const code = `
    import React from 'react'
    import { Datepicker } from '../../../components/datepicker/Datepicker'

    /*
      Passing props into Datepicker Component
      ---------------------------------------------------------------
      placeholder: text [string],
      className: custom style wrapper Datepicker component [object] ,
      yearBoundary: range year [number],
      setValueDateFn: lifting up date [function],
      value: initial value [ex. '2022-02-19'] [string],
      minDate: scope minimum date [ex. new Date()] [string],
      maxDate: scope maximum date [ex. new Date()] [string],
    */

    export default function App(props) {
      return (
        <Datepicker setValueDateFn={(data) => console.log(data)} />
        <Datepicker value={'2022-02-19'} setValueDateFn={(data) => console.log(data)} />

        <Form name="form-search" form={form} layout="vertical">
          <Form.Item name={'inputdate3'}>
            <Datepicker
              setValueDateFn={async (data) => form.setFieldsValue({ inputdate3: await data })}
              onClear={() => {
                form.setFieldsValue({ inputdate3: null })
              }}
            />
          </Form.Item>
        </Form>
      )
    }
  `

  useEffect(() => {
    setTimeout(() => {
      form.setFieldsValue({ inputdate3: '2022-02-19' })
    }, 1000)
  }, [])

  return (
    <>
      <div className="site-layout-background">
        <Row style={stlyes}>
          <Col span={6} style={{ margin: 'auto 2rem' }}>
            Initial empty value
          </Col>
          <Col span={6} style={{ margin: 'auto' }}>
            <Datepicker setValueDateFn={(data) => setIsDate({ ...isDate, ex1: data })} />
          </Col>
          <Col span={6} style={{ margin: 'auto 2rem' }}>
            {isDate?.ex1}
          </Col>
        </Row>
        <Row style={stlyes}>
          <Col span={6} style={{ margin: 'auto 2rem' }}>
            Initial value
          </Col>
          <Col span={6} style={{ margin: 'auto' }}>
            <Datepicker value={'2022-02-19'} setValueDateFn={(data) => setIsDate({ ...isDate, ex2: data })} />
          </Col>
          <Col span={6} style={{ margin: 'auto 2rem' }}>
            {isDate?.ex2}
          </Col>
        </Row>
        <Row style={stlyes}>
          <Col span={6} style={{ margin: 'auto 2rem' }}>
            Initial value by setState
          </Col>
          <Col span={6} style={{ margin: 'auto' }}>
            <Form name="form-search" form={form} layout="vertical">
              <Form.Item name={'inputdate3'}>
                <Datepicker
                  setValueDateFn={async (data) => form.setFieldsValue({ inputdate3: await data })}
                  onClear={() => {
                    form.setFieldsValue({ inputdate3: null })
                  }}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={6} style={{ margin: 'auto 2rem' }}>
            {isDate?.ex3}
          </Col>
        </Row>
        <CodeBlock code={code} />
      </div>
    </>
  )
}

export default DatepickerIndex
