import { Trans } from 'react-i18next'
export const Columns = [
  {
    title: <Trans>ชื่องาน/โครงการ</Trans>,
    dataIndex: 'jobAndProjectName',
    width: '15%',
  },
  {
    title: <Trans>ประเภทงาน</Trans>,
    dataIndex: 'jobTypeName',
    width: '15%',
  },
  {
    title: <Trans>ความเร่งด่วน</Trans>,
    dataIndex: 'urgentName',
    width: '10%',
  },
  {
    title: <Trans>รายละเอียดงาน</Trans>,
    dataIndex: 'jobDetail',
    width: '35%',
  },
  {
    title: <Trans>วันที่เริ่มต้น</Trans>,
    dataIndex: 'dateStart',
    width: '10%',
  },
  {
    title: <Trans>เวลาสิ้นสุด</Trans>,
    dataIndex: 'dateEnd',
    width: '10%',
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'approveStatusName',
    width: '20%',
  },
]
