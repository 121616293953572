import { gql } from '@apollo/client'

export const CREATE_AUDIT_ISSUE = gql`
  mutation SoiAuditCommitteeCreateIssue($input: AuditIssuesInput!) {
    SoiAuditCommitteeCreateIssue(input: $input)
  }
`

export const EDIT_AUDIT_ISSUE = gql`
  mutation SoiAuditCommitteeEditIssue($input: AuditIssuesInput!) {
    SoiAuditCommitteeEditIssue(input: $input)
  }
`

export const DELETE_AUDIT_ISSUE = gql`
  mutation SoiAuditCommitteeDeleteIssue($input: AuditIssueId!) {
    SoiAuditCommitteeDeleteIssue(input: $input)
  }
`

export const CREATE_AUDIT_CAUSE_OF_ISSUE = gql`
  mutation SoiAuditCommitteeCreateCause($input: AuditCauseOfIssuesInput!) {
    SoiAuditCommitteeCreateCause(input: $input)
  }
`

export const EDIT_AUDIT_CAUSE_OF_ISSUE = gql`
  mutation SoiAuditCommitteeEditCause($input: AuditCauseOfIssuesInput!) {
    SoiAuditCommitteeEditCause(input: $input)
  }
`

export const DELETE_CAUSE_ISSUE = gql`
  mutation SoiAuditCommitteeDeleteCause($input: AuditCauseId!) {
    SoiAuditCommitteeDeleteCause(input: $input)
  }
`

export const CREATE_AUDIT_SUGGEST = gql`
  mutation createAuditSuggest($input: AuditSuggestOfIssuesInput!) {
    createAuditSuggest(input: $input)
  }
`

export const EDIT_AUDIT_SUGGEST = gql`
  mutation updateAuditSuggest($input: AuditSuggestOfIssuesInput!) {
    updateAuditSuggest(input: $input)
  }
`

export const DELETE_AUDIT_SUGGEST = gql`
  mutation SoiAuditCommitteeDeleteSuggest($input: AuditSuggestId!) {
    SoiAuditCommitteeDeleteSuggest(input: $input)
  }
`
