import React from 'react'
import { Route, Routes } from 'react-router-dom'
import InputFromApiIndex from './inputfromapi/InputFromApiIndex'
import BreadcrumbIndex from './breadcrumb/BreadcrumbIndex'
import ButtonIndex from './button/ButtonIndex'
import ModalIndex from './modal/ModalIndex'
import IconIndex from './icon/IconIndex'
import SelectIndex from './select/SelectIndex'
import TooltipIndex from './tooltip/TooltipIndex'
import TabIndex from './tab/TabIndex'
import DatepickerIndex from './datepicker/DatepickerIndex'
import LabelIndex from './label/LabelIndex'
import InputIndex from './input/InputIndex'
import TextAreaIndex from './textarea/TextAreaIndex'
import TimepickerIndex from './timepicker/TimepickerIndex'
import FormIndex from './form/FormIndex'
import CheckboxIndex from './checkbox/CheckboxIndex'
import RadioIndex from './radio/RadioIndex'
import ProgressIndex from './progress/ProgressIndex'
import DataTableIndex from './datatable/DataTableIndex'
import SpinnersIndex from './spinners/SpinnersIndex'
import CardIndex from './card/CardIndex'
import UploadIndex from './upload/UploadIndex'
import AlertIndex from './alert/AlertIndex'
import TableAddMultiRowIndex from './tableaddmultirow/TableAddMultiRowIndex'
import FilterIndex from './filter/FilterIndex'
import StatusIndex from './status/StatusIndex'
import TableMergeColumn from './tablemergecolumn/TableMergeColumn'
import CalendarIndex from './calendar/CalendarIndex'
import ColorPickerIndex from './colorpicker/ColorPickerIndex'

function Router() {
  return (
    <Routes>
      <Route path="/inputfromapi" element={<InputFromApiIndex />} />
      <Route path="/breadcrumb" element={<BreadcrumbIndex />} />
      <Route path="/dropdown" element={<></>} />
      <Route path="/modal" element={<ModalIndex />} />
      <Route path="/progress" element={<ProgressIndex />} />
      <Route path="/spinners" element={<SpinnersIndex />} />
      <Route path="/datatable" element={<DataTableIndex />} />
      <Route path="/select" element={<SelectIndex />} />
      <Route path="/Checkbox" element={<CheckboxIndex />} />
      <Route path="/Radio" element={<RadioIndex />} />
      <Route path="/Notifications" element={<></>} />
      <Route path="/listgroup" element={<></>} />
      <Route path="/tabcomponent" element={<TabIndex />} />
      <Route path="/feathericons" element={<IconIndex />} />
      <Route path="/popover" element={<></>} />
      <Route path="/tooltip" element={<TooltipIndex />} />
      <Route path="/badges" element={<></>} />
      <Route path="/importfiles" element={<></>} />
      <Route path="/draganddrop" element={<></>} />
      <Route path="/permission" element={<></>} />
      <Route path="/button" element={<ButtonIndex />} />
      <Route path="/collapse" element={<></>} />
      <Route path="/breadcrumb" element={<></>} />
      <Route path="/textbox" element={<></>} />
      <Route path="/textarea" element={<TextAreaIndex />} />
      <Route path="/datepicker" element={<DatepickerIndex />} />
      <Route path="/timepicker" element={<TimepickerIndex />} />
      <Route path="/autocomplete" element={<></>} />
      <Route path="/label" element={<LabelIndex />} />
      <Route path="/fileupload" element={<></>} />
      <Route path="/upload" element={<UploadIndex></UploadIndex>} />
      <Route path="/tableaddmultirow" element={<TableAddMultiRowIndex />} />
      <Route path="/formvalidate" element={<FormIndex />} />
      <Route path="/inputaddress" element={<></>} />
      <Route path="/input" element={<InputIndex />} />
      <Route path="/card" element={<CardIndex />} />
      <Route path="/alert" element={<AlertIndex />} />
      <Route path="/filter" element={<FilterIndex />} />
      <Route path="/status" element={<StatusIndex />} />
      <Route path="/tablemergecolumn" element={<TableMergeColumn />} />
      <Route path="/calendar" element={<CalendarIndex />} />
      <Route path="/colorpicker" element={<ColorPickerIndex />} />
    </Routes>
  )
}
export default Router
