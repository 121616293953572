import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useQuery } from '@apollo/client'
import { GET_SELECT_MASTER_ORGANIZATION_LEVEL } from './graphql/Query'
import { Select } from '../../select/Select'
import { displayText } from '../../../utilitys/helper'

const SelectOrganizationLevel = (props) => {
  const { placeholder, formname, handleChange, ...otherProp } = props

  const [isData, isSetData] = useState([])

  const organizationLevel = useQuery(GET_SELECT_MASTER_ORGANIZATION_LEVEL)

  useEffect(() => {
    if (organizationLevel?.data) {
      isSetData(
        organizationLevel?.data?.getSelectMasterOrganizationLevel.map((v) => ({
          value: v.organizationLevelCode,
          label: displayText(v.organizationLevelNameTH, v.organizationLevelNameEN),
        })),
      )
    }
  }, [organizationLevel?.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={organizationLevel.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectOrganizationLevel
