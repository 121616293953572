import { Trans } from 'react-i18next'
export const Columns = [
  {
    title: <Trans>ลำดับที่</Trans>,
    dataIndex: 'key',
    width: '10%',
    align: 'center',
    className: 'text-nowrap',
  },
  {
    title: <Trans>เลขที่คำขอ</Trans>,
    dataIndex: 'requestNumber',
    width: '10%',
    sorter: { multiple: 1 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>หน่วยงาน</Trans>,
    dataIndex: 'requestOrganizationName',
    width: '25%',
    sorter: { multiple: 2 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>ผู้ขออนุมัติ</Trans>,
    dataIndex: 'requestPersonnel',
    width: '20%',
    sorter: { multiple: 3 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>สถานะพิจารณา</Trans>,
    dataIndex: 'requestStatusName',
    width: '15%',
    sorter: { multiple: 4 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>จัดการอนุมัติ</Trans>,
    dataIndex: 'action',
    width: '10%',
    className: 'text-nowrap',
    align: 'center',
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '10%',
    align: 'center',
    className: 'text-nowrap',
  },
]
