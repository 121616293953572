import { gql } from '@apollo/client'

export const SAVE_RISK_PROCESS = gql`
  mutation SaveRiskProcess($input: RiskProcessSaveInput!) {
    saveRiskProcess(input: $input) {
      rateFacGroupCode
    }
  }
`

export const DELETE_RISK_PROCESS = gql`
  mutation DeleteRiskProcess($input: RiskProcessDeleteInput!) {
    deleteRiskProcess(input: $input) {
      rateFacGroupCode
    }
  }
`
