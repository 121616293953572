import { Col, Row } from 'antd'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'

import { ButtonNew as ButtonNew } from '../../../../components/button/Button'
import { Datatable } from '../../../../components/datatable/Datatable'
import { useLanguage } from '../../../../hooks/useLanguage'
import { Columns } from './utils/RateRiskFactorProcessListColumn'
import RateRiskFactorsProcessForm from './RateRiskFactorProcessForm'
import { encryptInput } from '../../../../utilitys/crypto'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { Alert } from '../../../../components/alert/Alert'

import { GET_LIST_RATE_RISK_FACTOR_PROCESS_PAGINATION } from './graphql/Query'
import { DELETE_RATE_RISK_FACTOR_PROCESS } from './graphql/Mutation'
import { handleSort } from '../../../../utilitys/pagination'
import { displayError } from '../../../../utilitys/helper'

const Responsive = {
  md: 24,
  lg: 24,
}

const RateRiskFactorRiskProcessList = ({ focusTab = null, mainFormType = 'view', rateFacGroupCode = null, menu }) => {
  const { t } = useTranslation()
  const [isLang] = useLanguage()

  const [form_visible, setform_visible] = useState(false)
  const [formType, setformType] = useState('view')
  const [dataForm, setdataForm] = useState({})

  const [dataRow, setdataRow] = useState([])
  const [paginate, setPaginate] = useState({ page: 1, limit: 10 })
  const [sort, setsort] = useState([{ fieldSort: 'rateFacProCode', sortType: 'ASC' }])

  const [
    filters,
    //  setfilters
  ] = useState({
    rateFacGroupCode,
  })
  const [count, setcount] = useState(1)

  useEffect(() => {
    if (sort && paginate && filters && rateFacGroupCode && focusTab === '1') fncGetList()
  }, [paginate, sort, filters, rateFacGroupCode, focusTab])

  const [callList, { loading: loadingList, error: errorList, data: dataList }] = useLazyQuery(
    GET_LIST_RATE_RISK_FACTOR_PROCESS_PAGINATION,
  )

  useEffect(() => {
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorList)),
      })
    }
  }, [errorList])

  useEffect(() => {
    if (dataList) {
      const { result, count } = dataList.getListRateRiskFactorProcessPagination

      const dataTemp = result.map((item, key) => {
        const rateFacProName = isLang !== 'en' ? item.rateFacProNameTH : item.rateFacProNameEN

        return {
          ...item,
          key: key + 1,
          rateFacProName,
        }
      })
      setdataRow(dataTemp)
      setcount(count)
    }
  }, [dataList])

  const [callDelete, { loading: loadingDelete, error: errorDelete, data: dataDelete }] = useMutation(
    DELETE_RATE_RISK_FACTOR_PROCESS,
  )

  useEffect(() => {
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorDelete)),
      })
    }
  }, [errorDelete])

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบข้อมูลสำเร็จ'),
        onOk() {
          fncGetList()
        },
      })
    }
  }, [dataDelete])

  const fncGetList = async () => {
    const dataInput = {
      sort: sort,
      filters: filters,
      pagination: {
        page: paginate.page,
        limit: paginate.limit,
      },
    }
    await callList({
      variables: { input: encryptInput(dataInput) },
    })
  }

  const handleFormOnClose = () => {
    setform_visible(false)
  }

  const handleFormOnSuccess = () => {
    fncGetList()
    setform_visible(false)
  }

  const fncOnpressView = (data) => {
    setdataForm(data)
    setformType('view')
    setform_visible(true)
  }

  const fncOnpressEdit = (data) => {
    setdataForm(data)
    setformType('edit')
    setform_visible(true)
  }

  const fncOnpressDelete = (data) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ?'),
      onOk() {
        const dataInput = {
          rateFacProCode: data?.rateFacProCode,
        }
        callDelete({
          variables: { input: encryptInput(dataInput) },
        })
      },
      onCancel() {},
    })
  }

  return (
    <React.Fragment>
      {(loadingList || loadingDelete) && <SpinnersNew />}
      <Row>
        <Col {...Responsive}>
          <Datatable
            columns={Columns({ fncOnpressView, fncOnpressEdit, fncOnpressDelete, mainFormType, menu })}
            data={dataRow}
            total={count}
            searchCustom={true}
            pageSize={paginate.limit}
            page={paginate.page}
            isLoading={loadingList || loadingDelete}
            paginationCustom={true}
            scroll={{ x: 768 }}
            onChangePagination={({ page, pageSize }) => {
              setPaginate({ page, limit: pageSize })
            }}
            handleTableChange={(e) => {
              const reNewField = [
                {
                  rateFacProName: 'rateFacProCode',
                },
                { isActive: 'isActive' },
              ]

              setsort(handleSort(e?.sorter, reNewField))
            }}
            btnAdd={
              mainFormType !== 'view' ? (
                <ButtonNew
                  onClick={() => {
                    setformType('add')
                    setform_visible(true)
                  }}
                  type="plusTableBorderPrimary"
                  roles={{ type: 'add', menu: menu }}
                >
                  {t('ความเสี่ยงกระบวนการ')}
                </ButtonNew>
              ) : (
                <></>
              )
            }
          />
        </Col>
      </Row>
      <RateRiskFactorsProcessForm
        rateFacGroupCode={rateFacGroupCode}
        formType={formType}
        dataForm={dataForm}
        visible={form_visible}
        onClose={handleFormOnClose}
        onSuccess={handleFormOnSuccess}
      />
    </React.Fragment>
  )
}
export default RateRiskFactorRiskProcessList
