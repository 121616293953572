import { Trans } from 'react-i18next'

export const columnsExperience = [
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>ลำดับ</Trans>
      </div>
    ),
    dataIndex: 'no',
    width: '10%',
    className: 'text-nowrap',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
  },
  {
    title: <Trans>ชื่องาน</Trans>,
    dataIndex: 'jobTypeRefer',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>ประเภทงาน</Trans>,
    dataIndex: 'experienceType',
    sorter: true,
    className: 'text-nowrap',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>วันที่เริ่ม</Trans>
      </div>
    ),
    dataIndex: 'startDate',
    sorter: true,
    className: 'text-nowrap',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>วันที่สิ้นสุด</Trans>
      </div>
    ),
    dataIndex: 'endDate',
    sorter: true,
    className: 'text-nowrap',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>วันทำงาน</Trans>
      </div>
    ),
    dataIndex: 'manday',
    sorter: true,
    className: 'text-nowrap',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>ต้นทุนรายบุคคล</Trans>
      </div>
    ),
    dataIndex: 'costPersonnel',
    sorter: true,
    className: 'text-nowrap',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>ต้นทุน/งาน</Trans>
      </div>
    ),
    dataIndex: 'costJob',
    sorter: true,
    className: 'text-nowrap',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
  },
]
