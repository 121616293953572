import styled from 'styled-components'

import { fontSize, colors } from '../../configs/styles.config'

export const SelectStyle = styled.div`
  color: ${colors.black} !important;
  font-size: ${fontSize.textDefault};
  font-family: 'NotoSansThai';

  border-color: ${colors.lightGray} !important;

  .ant-select {
    width: 100%;
    /* height: 37px; */
    /* .ant-select-selector {
    height: auto;
    } */
  }
`

export const OptionStyle = styled.div``

export const TitleSelectStyle = styled.div`
  font-family: 'NotoSansThai';
  font-size: ${fontSize.textDefault};
`
