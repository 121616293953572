import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'antd'
import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/alert/Alert'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Status } from '../../../components/status/Status'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError, displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { DELETE_ORGANIZATION } from './graphql/Mutation'
import { GET_ORGANIZATION_PAGINATION } from './graphql/Query'
import OrganizationCreate from './OrganizationCreate'
import OrganizationListFilters from './OrganizationListFilters'
import { Columns } from './utils/OrganizationColumns'

const OrganizationList = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const Responsive = {
    md: 24,
    lg: 24
  }
  const [dataLists, setDataLists] = useState([])
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  // const [isReset, setPage] = useState(1)

  /* ---------------- Filters ----------- */
  const [filters, setfilters] = useState({})

  const onGetFilter = (data) => {
    setfilters({ ...data })
  }

  /* ---------------- API ----------- */
  const [getOrganizationFn, dataOrganizationList] = useLazyQuery(GET_ORGANIZATION_PAGINATION, {})
  const [deleteOrganizationFn, dataDeleteOrganization] = useMutation(DELETE_ORGANIZATION, {})

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    getOrganizationFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          filters: filters ? { ...filters } : {},

          pagination: { limit, page },
          sort: sort
        }
      }
    })
  }, [getOrganizationFn, page, limit, sort, filters])

  useEffect(() => {
    if (dataDeleteOrganization.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          dataOrganizationList.refetch()
        }
      })
    }

    if (dataDeleteOrganization?.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(dataDeleteOrganization?.error)),
        onOk() {}
      })
    }
  }, [dataDeleteOrganization.data])

  useEffect(() => {
    if (dataOrganizationList?.data) {
      let response = dataOrganizationList?.data?.getOrganizationsPagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data, index) => {
          return {
            ...data,
            key: index + 1 + (page - 1) * limit,
            organizationName: displayText(data.organizationNameTH, data.organizationNameEN),
            organizationType:
              data.organizationType === 2
                ? displayText('ภายนอก', 'External Audit')
                : displayText('ภายใน', 'Internal Audit'),
            organizationParentName: displayText(data.organizationParentNameTH, data.organizationParentNameEN),
            organizationLevelName: displayText(data.organizationLevelNameTH, data.organizationLevelNameEN),
            organizationSizeName: displayText(data.organizationSizeNameTH, data.organizationSizeNameEN),
            isActive: <Status text={data.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () =>
                        navigate('/administrator/organization/view', {
                          state: { type_action: 'view', organizationCode: data.organizationCode }
                        })
                    },
                    {
                      type: 'edit',
                      onClick: () =>
                        navigate('/administrator/organization/edit', {
                          state: { type_action: 'edit', organizationCode: data.organizationCode }
                        })
                    },
                    {
                      type: 'delete',
                      onClick: () => showConfirmDelete(data.organizationCode)
                    }
                  ]}
                />
              </div>
            )
          }
        })
      )
    }

    if (dataOrganizationList?.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(dataOrganizationList?.error)),
        onOk() {}
      })
    }
  }, [dataOrganizationList?.data])

  /* ---------------- ACTION ----------- */
  const showConfirmDelete = (organizationCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteOrganizationFn({
          variables: {
            organizationCode: encryptInput(organizationCode)
          }
        })
      },
      onCancel() {}
    })
  }

  const fncReset = () => {
    onGetFilter()
    getOrganizationFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: {
          filters: filters ? { ...filters } : {},
          pagination: { limit, page },
          sort: sort
        }
      }
    })
  }

  return (
    <>
      {(dataOrganizationList.loading || dataDeleteOrganization.loading) && <SpinnersNew />}
      <OrganizationListFilters fncReset={fncReset} menu={menu} getValue={onGetFilter} />
      <Col {...Responsive}>
        <CardNew topic={t('รายการหน่วยงาน')} icon="AlignJustify" toggledrop={'false'}>
          <Row gutter={[12, 12]}>
            <Col {...Responsive}>
              <Datatable
                columns={Columns}
                data={[...dataLists]}
                total={count}
                onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                paginationCustom={true}
                showSizeChanger={true}
                pageSize={limit}
                page={page}
                scroll={{ x: 1000, y: false }}
                handleTableChange={(e) => {
                  const lang = localStorage.getItem('lang') === 'th' ? true : false
                  const reNewField = [
                    { organizationName: lang ? 'organizationNameTH' : 'organizationNameEN' },
                    { organizationParentName: lang ? 'organizationParentNameTH' : 'organizationParentNameEN' },
                    { organizationLevelName: lang ? 'organizationLevelNameTH' : 'organizationLevelNameEN' },
                    { organizationSizeName: lang ? 'organizationSizeNameTH' : 'organizationSizeNameEN' },
                    { organizationType: 'organizationType' },
                    { isActive: 'isActive' }
                  ]

                  setSort(handleSort(e?.sorter, reNewField))
                }}
                btnAdd={
                  <>
                    <ButtonNew
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: menu }}
                      onClick={() => setVisible(true)}
                    >
                      {t('เพิ่มหน่วยงาน')}
                    </ButtonNew>
                  </>
                }
              />
            </Col>
          </Row>
        </CardNew>
      </Col>
      <OrganizationCreate
        open={visible}
        close={(e) => {
          setVisible(e)
          setData()
        }}
        refetch={() => dataOrganizationList.refetch()}
        dataParams={data}
        menu={menu}
      />
    </>
  )
}

export default OrganizationList
