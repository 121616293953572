OverviewFollowUpGraphPie

import { useLazyQuery } from '@apollo/client'
import { Card, Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { Alert } from '../../../../components/alert/Alert'
// import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { GraphPie } from '../../../../components/graphpie/GraphPie'
import { IconNew } from '../../../../components/icon/Icon'
import { SpinnersSmaillNew } from '../../../../components/spinners/Spinners'

import { encryptInput } from '../../../../utilitys/crypto'
import { displayError } from '../../../../utilitys/helper'
import { GET_DATA_OVERVIEW_FOLLOWUP_GRAPHPIE } from './graphql/Query'
import { NoData } from '../../../../components/nodatagraph/NoData'
import { GraphPieStyled } from './css/GraphPieStyled'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  graph: {
    md: 8,
    lg: 8
  }
}

const Graph = ({ data, title, loading, handleClick = () => {} }) => {
  return (
    <GraphPieStyled>
      <Card
        className="card-dash card-pie"
        title={
          <Row>
            <Col md={22} align="left">
              <span className="card-title-icon">
                <IconNew icon={'PieChart'} size={17} />
              </span>
              <span className="card-title-text">{title}</span>
            </Col>
          </Row>
        }
      >
        <div className="block-graph-pie">
          {loading && <SpinnersSmaillNew />}
          {!_.isEmpty(data) ? <GraphPie data={data} onGetPieValue={handleClick} /> : <NoData />}
        </div>
      </Card>
    </GraphPieStyled>
  )
}

/* // กราฟวงกลม */
const OverviewFollowUpGraphPie = (props) => {
  const { dataFilter, getProjectCode = () => {} } = props

  const { t } = useTranslation()

  const [dataGraphPie1, setdataGraphPie1] = useState([])
  const [dataGraphPie2, setdataGraphPie2] = useState([])
  const [dataGraphPie3, setdataGraphPie3] = useState([])

  // =================================== API =======================================
  const [callGraphPieFnc, { data: dataGraphPie, loading: loadingGraphPie, error: errorGraphPie }] = useLazyQuery(
    GET_DATA_OVERVIEW_FOLLOWUP_GRAPHPIE
  )

  useEffect(() => {
    if (dataGraphPie) {
      const res = dataGraphPie.getDataOverviewFollowUpGraphPie

      const graph1 = res.filter((items) => items.jobTypeCode === '10')
      const graph2 = res.filter((items) => items.jobTypeCode === '20')
      const graph3 = res.filter((items) => items.jobTypeCode === '30')

      const getStatusColor = (items) => {
        let statusColor = ''
        if (items.jobStatusCode === '10') {
          statusColor = '#87CEEB'
        } else if (items.jobStatusCode === '20') {
          statusColor = '#40E0D0'
        } else if (items.jobStatusCode === '30') {
          statusColor = '#3CB371'
        } else statusColor = '#FF3333'

        return statusColor
      }

      if (!_.isEmpty(graph1)) {
        setdataGraphPie1(
          graph1.map((items) => {
            return {
              type: items.statusName,
              value: parseInt(items.countStatus),
              color: getStatusColor(items),
              source: items
            }
          })
        )
      }

      if (!_.isEmpty(graph2)) {
        setdataGraphPie2(
          graph2.map((items) => {
            return {
              type: items.statusName,
              value: parseInt(items.countStatus),
              color: getStatusColor(items),
              source: items
            }
          })
        )
      }

      if (!_.isEmpty(graph3)) {
        setdataGraphPie3(
          graph3.reduce((acc, cur) => {
            const findType = acc.find((item) => item.type === cur.statusName)
            if (findType) {
              findType.value += parseInt(cur.countStatus)
              findType.source = {
                ...findType.source,
                projectCode: findType.source.projectCode + ',' + cur.projectCode,
                countStatus: `${+findType.source.countStatus + +cur.countStatus}`
              }
            } else {
              acc.push({
                type: cur.statusName,
                value: parseInt(cur.countStatus),
                color: getStatusColor(cur),
                source: cur
              })
            }
            return acc
          }, [])
        )
      }
    }

    if (errorGraphPie) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorGraphPie)),
        onOk() {}
      })
    }
  }, [dataGraphPie])

  // =================================== CAll API =======================================
  const { auditTeamCode, dateEnd, dateStart, jobStatusCode, organizationCode, stepTypeCode } = dataFilter

  useEffect(() => {
    setdataGraphPie1(null)
    setdataGraphPie2(null)
    setdataGraphPie3(null)

    const dataInput = {}
    if (auditTeamCode) dataInput['auditTeamCode'] = auditTeamCode
    if (dateEnd) dataInput['dateEnd'] = dateEnd
    if (dateStart) dataInput['dateStart'] = dateStart
    if (jobStatusCode) dataInput['jobStatusCode'] = jobStatusCode
    if (organizationCode) dataInput['organizationCode'] = organizationCode
    if (stepTypeCode) dataInput['stepTypeCode'] = stepTypeCode

    if (dataInput?.dateStart && dataInput?.dateEnd && dataInput?.organizationCode) {
      callGraphPieFnc({ variables: { input: encryptInput(dataInput) } })
    }
  }, [auditTeamCode, dateEnd, dateStart, jobStatusCode, organizationCode, stepTypeCode])

  // ====================================== =======================================

  const handleClick = (data, graph) => {
    const projectCode = data?.source?.projectCode
    getProjectCode(projectCode, graph)
  }

  return (
    <Row gutter={[16, 8]}>
      <Col {...Responsive.graph}>
        <Graph
          data={dataGraphPie1}
          title={t('งานตรวจสอบตามแผน')}
          loading={loadingGraphPie}
          handleClick={(data) => handleClick(data, 1)}
        />
      </Col>
      <Col {...Responsive.graph}>
        <Graph
          data={dataGraphPie2}
          title={t('งานตรวจสอบนอกแผน')}
          loading={loadingGraphPie}
          handleClick={(data) => handleClick(data, 2)}
        />
      </Col>
      <Col {...Responsive.graph}>
        <Graph
          data={dataGraphPie3}
          title={t('งานให้คำปรึกษา')}
          loading={loadingGraphPie}
          handleClick={(data) => handleClick(data, 3)}
        />
      </Col>
    </Row>
  )
}

export default OverviewFollowUpGraphPie
