import React, { useState, useEffect } from 'react'
import { v4 } from 'uuid'
import { Col, Form, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { LabelNew } from '../../../../../components/label/Label'
import SelectRateRiskFactorProcessModel from '../../../../../components/inputfromapi/selectrateriskfactorprocessmodel/SelectRateRiskFactorProcessModel'
import SelectRateRiskRulesModel from '../../../../../components/inputfromapi/selectrateriskrulemodel/SelectRateRiskRuleModel'
import SelectRateRiskControl from '../../../../../components/inputfromapi/selectrateriskcontrol/SelectRateRiskControl'
import SelectAuditFindingType from '../../../../../components/inputfromapi/selectauditfindingtype/SelectAuditFindingType'
import SelectISO from '../../../../../components/inputfromapi/selectiso/SelectISO'
import SelectISOSub from '../../../../../components/inputfromapi/selectisosub/SelectISOSub'
import SelectRiskLevel from '../../../../../components/inputfromapi/selectrisklevel/SelectRiskLevel'
import SelectShowPoint from '../../../../../components/inputfromapi/selectshowpoint/SelectShowPoint'
import SelectIssueHave from '../../../../../components/inputfromapi/selectissuehave/SelectIssueHave'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { ButtonNew } from '../../../../../components/button/Button'
import { InputNew } from '../../../../../components/input/Input'
import { UploadNew } from '../../../../../components/upload/Upload'
import { downloadFile } from '../../../../../utilitys/files'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'
import { CREATE_AUDIT_ISSUE, EDIT_AUDIT_ISSUE } from '../../graphql/Mutation'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../../utilitys/crypto'
import { Alert } from '../../../../../components/alert/Alert'
import { displayText, displayError } from '../../../../../utilitys/helper'

const AuditCloseIssueDetailForm = (props) => {
  const { menu, dataAuditIssue } = props
  const currentLang = localStorage.getItem('lang')
  const formName = 'formIssue'
  const mainPage = '/auditoperations/auditcloses'
  const tableFileResponsive = { md: 24 }

  const { t } = useTranslation()
  const [user] = useDecodeUser()
  const navigate = useNavigate()
  const [form] = useForm()
  const { state } = useLocation()

  const { action } = state
  const isDisable = action === 'view' ? true : false
  const [rateFacProCode, setrateFacProCode] = useState('')
  const [rateRiskRuleCode, setrateRiskRuleCode] = useState('')
  const [ISOCode, setISOCode] = useState('')
  const [fileData, setfileData] = useState([])

  const [callSave, saveResponse] = useMutation(CREATE_AUDIT_ISSUE)
  const [callEdit, editResponse] = useMutation(EDIT_AUDIT_ISSUE)

  useEffect(() => {
    if (user) {
      form.setFieldsValue({ personResponsible: user.fullname })
    }
  }, [user])

  useEffect(() => {
    if (dataAuditIssue) {
      const x = dataAuditIssue.data.getAuditIssueById

      setISOCode(x.ISOCode)

      form.setFieldsValue({
        ...x,
        fact: displayText(x.factTH, x.factEN),
        issue: displayText(x.issueTH, x.issueEN),
        issueShow: x.issueShow?.toString(),
        risk: displayText(x.riskTH, x.riskEN),
        effect: displayText(x.effectTH, x.effectEN),
      })

      if (x.fileDetail) {
        const fileDetailFromDb = JSON.parse(x.fileDetail)
        const newFileList = []
        fileDetailFromDb.forEach((item) => {
          const uuid = v4()
          newFileList.push({
            key: uuid,
            fileId: item.fileId,
            filename: item.name,
          })
        })

        setfileData(newFileList)
      }
    }
  }, [dataAuditIssue?.data])

  useEffect(() => {
    if (saveResponse?.data) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          navigate(mainPage, { state: state })
        },
      })
    }

    if (saveResponse?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(saveResponse?.error)),
        onOk() {},
      })
    }
  }, [saveResponse?.data])

  useEffect(() => {
    if (editResponse?.data) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          navigate(mainPage, { state: state })
        },
      })
    }

    if (editResponse?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(editResponse?.error)),
        onOk() {},
      })
    }
  }, [editResponse?.data])

  const onSubmit = (data) => {
    const fileDetail = fileData
      .map((item) => {
        if (item.fileId) {
          return {
            fileId: item.fileId,
            name: data[item.key].filename || '',
          }
        } else {
          return null
        }
      })
      .filter((x) => x)

    let input = {
      actionPlanId: state.actionPlanId,
      projectCode: state.projectCode,
      rateFacProCode: data.rateFacProCode,
      rateRiskRuleCode: data.rateRiskRuleCode,
      rateRiskConCode: data.rateRiskConCode,
      findingTypeCode: data.findingTypeCode || null,
      ISOCode: data.ISOCode || null,
      ISOSubCode: data.ISOSubCode || null,
      riskLevelCode: data.riskLevelCode || null,
      issueShow: data.issueShow || null,
      // remark: data.remark,
      // toKnow: data.toKnow,
      // meetingTime: data.meetingTime,
      // issueOrg: _.uniq([...newIssueOrg.map((x) => x.organizationCode)]),
      issueType: 3,
      performance: data.performance || null,
      conclusion: data.conclusion || null,
      fileDetail: fileDetail,
      issueHave: data.issueHave === 0 || data.issueHave === 1 ? data.issueHave : null,
    }

    input[`issue${currentLang.toUpperCase()}`] = data.issue
    input[`fact${currentLang.toUpperCase()}`] = data.fact
    input[`risk${currentLang.toUpperCase()}`] = data.risk || null
    input[`effect${currentLang.toUpperCase()}`] = data.effect || null

    Alert({
      type: 'confirm',
      title: t('กดยืนยันเพื่อบันทึกผลการตรวจสอบ'),
      onOk: async () => {
        if (state.action === 'add') {
          callSave({ variables: { input: encryptInput(input) } })
        } else if (state.action === 'edit') {
          input['issueId'] = state.issueId
          callEdit({ variables: { input: encryptInput(input) } })
        }
      },
      onCancel: () => null,
    })
  }

  const tableFileColumn = [
    {
      title: t('ชื่อเอกสาร'),
      dataIndex: 'filename',
      width: '30%',
      render: (item, record) => {
        return action === 'view' ? (
          <>{item}</>
        ) : (
          <div>
            {/* <Form.Item
                    hidden={true} name={[record.key, 'fileId']} initialValue={record.fileId}
                    rules={[{ required: true, message: t('กรุณาเลือกเอกสาร') }]}
                >
                  <InputNew disabled={disabled} />
                </Form.Item> */}
            <Form.Item
              name={[record.key, 'filename']}
              initialValue={item}
              rules={[{ required: true, message: t('กรุณากรอกชื่อเอกสาร') }]}
            >
              <InputNew
                placeholder={t('ชื่อเอกสาร')}
                disabled={isDisable}
                onChange={(e) => {
                  form.setFieldsValue({ [record.key]: { filename: e.target.value } })
                }}
              />
            </Form.Item>
          </div>
        )
      },
    },
    {
      title: t('เอกสารอ้างอิง'),
      dataIndex: 'fileId',
      width: '25%',
      align: 'left',
      render: (item, record, index) => {
        return action === 'view' && !item ? (
          '-'
        ) : (
          <div>
            <Form.Item
              name={[record.key, 'fileId']}
              initialValue={item}
              rules={[{ required: true, message: t('กรุณาเลือกเอกสาร') }]}
            >
              <UploadNew
                key={index}
                setIdFile={() => {}}
                idFile={[record.fileId] || []}
                typeFile="file"
                inline={true}
                multiRow={true}
                setFileData={(e) => {
                  onUpload(record, e, index)
                }}
                actionType={action}
                defaults={[record.fileId] || []}
              />
            </Form.Item>
          </div>
        )
      },
    },
    {
      title: '',
      //   dataIndex: 'tools',
      width: '5%',
      render: (record) => {
        let listButton = []
        const btnDownlaod = {
          type: 'download',
          onClick: () => {
            record && downloadFile(record.originalname, record.name, () => {})
          },
        }

        const btnDelete = {
          type: 'delete',
          onClick: () => {
            onRemove(record?.name, record)
          },
        }

        record && listButton.push(btnDownlaod)
        action !== 'view' && listButton.push(btnDelete)

        // if (action === 'view') {
        //     if (record) {
        //       listButton = [...listButton, btnDownlaod]
        //     }
        //   } else {
        //     if (record) {
        //       listButton = [...listButton, btnDownlaod]
        //     }
        //     listButton = [...listButton, btnDelete]
        //   }

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} icons={listButton} />
          </div>
        )
      },
    },
  ]

  const addRowFile = () => {
    const uuid = v4()

    const newfileData = [
      ...fileData,
      {
        key: uuid,
        fileId: null,
        filename: null,
      },
    ]
    setfileData(newfileData)
  }

  const deleteFile = (fileName) => {
    fetch(process.env.REACT_APP_API_UPLOAD + `/deleteFile/${fileName}`, {
      method: 'GET',
    }).catch(() => {})
  }

  const onUpload = (record, value, index) => {
    const data = [...fileData]
    data[index].fileId = value.uid
    data[index].tools = { originalname: value.originalname, name: value.name }

    setfileData([...data])
    form.setFieldsValue({ [record.key]: { fileId: value.uid } })
  }

  const onRemove = (filename, record) => {
    let newData = fileData.filter((item) => item.key !== record.key)
    setfileData(newData)

    if (action === 'add') {
      filename && deleteFile(filename)
    }
  }

  return (
    <>
      {(saveResponse.loading || editResponse.loading) && <SpinnersNew />}
      <Form
        name={formName}
        form={form}
        autoComplete="off"
        labelAlign="left"
        layout="horizontal"
        onFinish={onSubmit}
        labelCol={{ sm: 24, md: 4, lg: 6 }}
        wrapperCol={{ sm: 24, md: 20, lg: 18 }}
      >
        <Row>
          <Col offset={2} md={20}>
            <Form.Item
              name="rateFacProCode"
              label={<LabelNew type="tab-header-inactive">{t('ปัจจัย/ความเสี่ยง')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุปัจจัย/ความเสี่ยง') }]}
            >
              <SelectRateRiskFactorProcessModel
                exit={1}
                placeholder={t('เลือกความเสี่ยงกระบวนการ')}
                formname={formName}
                disabled={isDisable}
                filters={{
                  projectCode: state?.projectCode,
                  isActive: 1,
                  approveStatusCode: '20',
                }}
                handleChange={async (e) => {
                  if (e.value) {
                    form.setFieldsValue({
                      rateFacProCode: e.value,
                      rateRiskRuleCode: null,
                      rateRiskConCode: null,
                    })
                    setrateFacProCode(e.value)
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="rateRiskRuleCode"
              label={<LabelNew type="tab-header-inactive">{t('เกณฑ์ ระเบียบ คำสั่ง')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุเกณฑ์ ระเบียบ คำสั่ง') }]}
            >
              <SelectRateRiskRulesModel
                placeholder={t('เลือกเกณฑ์ ระเบียบ คำสั่ง')}
                formname={formName}
                disabled={isDisable}
                filters={{ isActive: 1, rateFacProCode: rateFacProCode }}
                handleChange={(e) => {
                  if (e.value) {
                    form.setFieldsValue({ rateRiskConCode: null, rateRiskRuleCode: e.value })
                    setrateRiskRuleCode(e.value)
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="rateRiskConCode"
              label={<LabelNew type="tab-header-inactive">{t('วิธีการควบคุม')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุวิธีการควบคุม') }]}
            >
              <SelectRateRiskControl
                placeholder={t('เลือกวิธีการควบคุม')}
                formname={formName}
                disabled={isDisable}
                filters={{ isActive: 1, rateRiskRuleCode: rateRiskRuleCode }}
                handleChange={(e) => e?.value && form.setFieldsValue({ rateRiskConCode: e?.value })}
              />
            </Form.Item>
            <Form.Item
              name="fact"
              label={<LabelNew type="tab-header-inactive">{t('ข้อเท็จจริง')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุข้อเท็จจริง') }]}
            >
              <TextAreaNew placeholder={t('ข้อเท็จจริง')} disabled={isDisable} />
            </Form.Item>
            <Form.Item
              name="issueHave"
              label={<LabelNew type="tab-header-inactive">{t('การตรวจพบประเด็น')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกการตรวจพบประเด็น') }]}
            >
              <SelectIssueHave
                disabled={isDisable}
                placeholder={t('เลือกการตรวจพบประเด็น')}
                formname={formName}
                handleChange={(e) => form.setFieldsValue({ issueHave: e?.value })}
              />
            </Form.Item>
            <Form.Item
              name="issue"
              label={<LabelNew type="tab-header-inactive">{t('ประเด็น/ข้อสังเกตุ')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุประเด็น/ข้อสังเกตุ') }]}
            >
              <TextAreaNew placeholder={t('ประเด็น/ข้อสังเกตุ')} disabled={isDisable} />
            </Form.Item>
            <Form.Item
              name="findingTypeCode"
              label={<LabelNew type="tab-header-inactive">{t('ที่มาของประเด็น')}</LabelNew>}
            >
              <SelectAuditFindingType
                placeholder={t('เลือกที่มาของประเด็น')}
                formname={formName}
                disabled={isDisable}
                filters={{ isActive: 1 }}
                handleChange={(e) => e?.value && form.setFieldsValue({ findingTypeCode: e?.value })}
              />
            </Form.Item>
            <Form.Item name="ISOCode" label={<LabelNew type="tab-header-inactive">{t('ความสอดคล้อง')}</LabelNew>}>
              <SelectISO
                placeholder={t('เลือกความสอดคล้อง')}
                formname={formName}
                disabled={isDisable}
                filters={{ isActive: 1 }}
                handleChange={(e) => {
                  if (e?.value) {
                    form.setFieldsValue({ ISOCode: e?.value, ISOSubCode: null })
                    setISOCode(e.value)
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="ISOSubCode"
              label={<LabelNew type="tab-header-inactive">{t('ความสอดคล้องย่อย')}</LabelNew>}
            >
              <SelectISOSub
                placeholder={t('เลือกความสอดคล้องย่อย')}
                formname={formName}
                disabled={isDisable}
                iSOCode={ISOCode}
                handleChange={(e) => e?.value && form.setFieldsValue({ ISOSubCode: e?.value })}
              />
            </Form.Item>
            <Form.Item
              name="riskLevelCode"
              label={<LabelNew type="tab-header-inactive">{t('ระดับความเสี่ยง')}</LabelNew>}
            >
              <SelectRiskLevel
                placeholder={t('เลือกระดับความเสี่ยง')}
                formname={formName}
                disabled={isDisable}
                filters={{ isActive: 1 }}
                handleChange={(e) => e?.value && form.setFieldsValue({ riskLevelCode: e?.value })}
              />
            </Form.Item>
            <Form.Item name="issueShow" label={<LabelNew type="tab-header-inactive">{t('แสดงประเด็น')}</LabelNew>}>
              <SelectShowPoint
                placeholder={t('แสดงประเด็น')}
                formname={formName}
                disabled={isDisable}
                handleChange={(e) => e?.value && form.setFieldsValue({ issueShow: e?.value })}
              />
            </Form.Item>
            <Form.Item name="risk" label={<LabelNew type="tab-header-inactive">{t('ความเสี่ยง')}</LabelNew>}>
              <TextAreaNew placeholder={t('ความเสี่ยง')} disabled={isDisable} />
            </Form.Item>
            <Form.Item name="effect" label={<LabelNew type="tab-header-inactive">{t('ผลกระทบ')}</LabelNew>}>
              <TextAreaNew placeholder={t('ผลกระทบ')} disabled={isDisable} />
            </Form.Item>
            <Form.Item
              name="performance"
              label={<LabelNew type="tab-header-inactive">{t('สรุปผลการดำเนินงานของผู้บริหาร')}</LabelNew>}
            >
              <TextAreaNew placeholder={t('สรุปผลการดำเนินงานของผู้บริหาร')} disabled={isDisable} />
            </Form.Item>
            <Form.Item
              name="conclusion"
              label={<LabelNew type="tab-header-inactive">{t('สรุปผลการตรวจสอบ')}</LabelNew>}
            >
              <TextAreaNew placeholder={t('สรุปผลการตรวจสอบ')} disabled={isDisable} />
            </Form.Item>
            {/* <Form.Item
                              name="meetingTime"
                              label={<LabelNew type="tab-header-inactive">{t('การประชุมวาระครั้งที่')}</LabelNew>}
                              rules={[{ required: true, message: t('กรุณาระบุการประชุมวาระครั้งที่') }]}
                            >
                              <InputNumberNew placeholder={t('การประชุมวาระครั้งที่')} disabled={isDisable} />
                            </Form.Item>
                            <Form.Item
                              name="toKnow"
                              label={<LabelNew type="tab-header-inactive">{t('เพื่อทราบ')}</LabelNew>}
                              rules={[{ required: true, message: t('กรุณาระบุเพื่อทราบ') }]}
                            >
                              <TextAreaNew placeholder={t('เพื่อทราบ')} disabled={isDisable} />
                            </Form.Item>
                            <Form.Item
                              name="remark"
                              label={<LabelNew type="tab-header-inactive">{t('หมายเหตุ')}</LabelNew>}
                              rules={[{ required: true, message: t('กรุณาระบุหมายเหตุ') }]}
                            >
                              <TextAreaNew placeholder={t('หมายเหตุ')} disabled={isDisable} />
                            </Form.Item> */}
            <Form.Item
              name="personResponsible"
              label={<LabelNew type="tab-header-inactive">{t('ผู้รับผิดชอบ')}</LabelNew>}
            >
              <InputNew disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col {...tableFileResponsive}>
            <LabelNew type="body-header">{t('แนบเอกสาร')}</LabelNew>
            <Datatable columns={tableFileColumn} data={fileData} pagination={false} />
          </Col>
        </Row>
        <Row style={{ marginTop: '5px', marginBottom: '10px' }}>
          {action !== 'view' && (
            <Col {...tableFileResponsive}>
              <ButtonNew type="addMultiple" onClick={() => addRowFile()}>
                {t('เพิ่มเอกสาร')}
              </ButtonNew>
            </Col>
          )}
        </Row>
        <Row gutter={[12, 20]} justify="center" style={{ marginTop: '15px' }}>
          {action !== 'view' && (
            <Col>
              <ButtonNew type="primary" roles={{ type: action, menu: menu }} htmlType="submit">
                {t('บันทึก')}
              </ButtonNew>
            </Col>
          )}
          <Col>
            <ButtonNew type="back" onClick={() => navigate(-1, { state: { action: action, ...state } })}>
              {t('ย้อนกลับ')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default AuditCloseIssueDetailForm
