import { gql } from '@apollo/client'

export const GETAPPROVECANCELPROJECTLIST = gql`
  query getApproveCancelProjectList($inputData: ApproveCancelProjectInput!) {
    getApproveCancelProjectList(inputData: $inputData) {
      RowData
      planCode
      projectId
      projectCode
      projectNameTH
      projectNameEN
      dateStart
      dateEnd
      projectRefNameTH
      projectRefNameEN
      approveStatusNameTH
      approveStatusNameEN
      fullNameTH
      fullNameEN
      auditTeamNameTH
      auditTeamNameEN
      cancelStatus
    }
  }
`

export const GETAPPROVECANCELPROJECTAUDITTEAM = gql`
  query getApproveCancelProjectAuditTeam {
    getApproveCancelProjectAuditTeam {
      auditTeamId
      auditTeamCode
      auditTeamNameTH
      auditTeamNameEN
      isActive
    }
  }
`

export const GETAUDITPLANBYAPPROVECANCLEPLANONE = gql`
  query GetApproveCancleAuditPlanOne($inputData: ApproveCanclePlanOneInput!) {
    getApproveCancleAuditPlanOne(inputData: $inputData) {
      planId
      planCode
      planNameTH
      planNameEN
      planYear
      planType
      dateStart
      dateEnd
      planStatusCode
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
      planStatusNameTH
      files
      numberOfPeople
      riskFormTH
      riskFormEN
    }
  }
`

export const GETAPPROVEFILES = gql`
  query getApproveFiles($inputData: ApproveFileInput!) {
    getApproveFiles(inputData: $inputData) {
      fileId
      fileNameLabel
      fileNameGen
      fileType
      filepath
    }
  }
`

export const GETAPPROVEAUDITPROJECT = gql`
  query getApproveAuditProject($inputData: ApproveAuditProjectInput!) {
    getApproveAuditProject(inputData: $inputData) {
      projectId
      projectCode
      projectNameTH
      projectNameEN
      planCode
      projectTypeCode
      projectRefCode
      riskFromCode
      projectSizeCode
      manday
      auditTeamCode
      personnelCode
      numberOfPeople
      mandayPersonnal
      organizationCode
      dateStart
      dateEnd
      projectStatusCode
      approveStatusCode
      cancelRemarkTH
      cancelRemarkEN
      files
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
      cancelStatus
      projectRefNameTH
      projectRefNameEN
      organizationNameTH
      organizationNameEN
      approveStatusNameTH
      approveStatusNameEN
      projectTypeNameTH
      projectTypeNameEN
      auditTeamNameTH
      auditTeamNameEN
      fullNameTH
      fullNameEN
      projectSizeNameTH
      projectSizeNameEN
      planNameTH
      planNameEN
      planType
      auditTypeNameTH
      auditTypeNameEN
      riskFormTH
      riskFormEN
      projectStatusNameTH
      projectStatusNameEN
    }
  }
`

export const GET_CANCEL_AUDIT_PROJECT_ROW_ONE = gql`
  query GetCancleAuditProjectRawOne($inputData: CancleAuditProjectInput!) {
    getCancleAuditProjectRawOne(inputData: $inputData) {
      projectCode
      projectNameTH
      projectNameEN
      planCode
      projectTypeCode
      projectRefCode
      isActive
      projectRefNameTH
      projectRefNameEN
      approveStatusNameTH
      approveStatusNameEN
      projectTypeNameTH
      projectTypeNameEN
      planNameTH
      planNameEN
      auditTypeNameTH
      auditTypeNameEN
      riskFormTH
      riskFormEN
      projectStatusNameTH
      projectStatusNameEN
    }
  }
`

export const GETPROJECTNOTPLANFILES = gql`
  query getProjectDataFiles($inputData: ProjectFileInput!) {
    getProjectDataFiles(inputData: $inputData) {
      fileId
      fileNameLabel
      fileNameGen
      fileType
      filepath
    }
  }
`

export const GETAPPROVEPROJECTNOTPLANAUDITORGANIZATIONPAGINATION = gql`
  query getApproveProjectNotPlanAuditOrganizationPagination($inputData: ApproveProjectNotPaginationInput!) {
    getApproveProjectNotPlanAuditOrganizationPagination(inputData: $inputData) {
      result {
        RowData
        organizationCode
        projectCode
        personnelCode
        organizationNameTH
        organizationNameEN
        organizationLevelNameTH
        organizationLevelNameEN
        fullNameTH
        fullNameEN
      }
      count
      page
      limit
    }
  }
`
