import { gql } from '@apollo/client'

export const CONSULTATION_RESULT_REPORT_SAVE = gql`
  mutation consultationResultReportSave($input: ConsultationResultInput!) {
    consultationResultReportSave(input: $input)
  }
`

export const SEND_CONSULT_RESULT = gql`
  mutation ConsultResultSend($userId: String!) {
    consultResultSend(userId: $userId) {
      userId
    }
  }
`
