import React, { useState, useEffect } from 'react'
import { Form, Row, Input, Col, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { Datepicker } from '../../components/datepicker/Datepicker'
import { ButtonNew } from '../../components/button/Button'
import { InputNew, InputNumberNew } from '../../components/input/Input'
import SelectOperationStatus from '../../components/inputfromapi/selectoperationstatus/SelectOperationStatus'
import { Select } from '../../components/select/Select'
import SelectYear from '../inputfromapi/selectyear/SelectYear'
const Responesive = {
  inputFilter: {
    sm: 24,
    md: 12,
    lg: 8,
  },
  buttonFilter: {
    sm: 24,
    md: 24,
    lg: 24,
  },
}
const FormFiltersBasicNew = (props) => {
  const { t } = useTranslation()
  const [inputs, setInputs] = useState([])
  const formName = props.formName || 'defaultName'
  const [formFilter] = Form.useForm()
  useEffect(() => {
    setInputs(props.inputs || [])
  }, [props.inputs])
  // onFinish
  const onFinish = (values) => {
    inputs.filter((item) => item.type === 'datepicker').map((v) => setErrorField(v.nameThowError, false))
    props.getValue(values)
  }
  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((er) => {
      const nameField = er.name[0] || ''
      const message = er.errors[0] || ''
      const isFilter = inputs.filter((item) => item.type === 'datepicker')
      if (!isFilter) return null
      isFilter.map((v) => {
        if (nameField === v.name) setErrorField(v.nameThowError, message)
      })
    })
  }
  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return formFilter.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  return (
    <>
      <Form form={formFilter} onFinish={onFinish} onFinishFailed={onFinishFailed} name={formName}>
        <Row gutter={[16, 8]}>
          {inputs.map((input, idx) => {
            const rules = input.rules || []
            switch (input.type) {
              case 'text':
                return (
                  <Col key={idx} {...Responesive.inputFilter}>
                    <label htmlFor={input.name}>
                      {input.label}
                      {rules.map((item) => item.required === true).length ? (
                        <span style={{ color: '#ea5456' }}>*</span>
                      ) : null}
                    </label>
                    <Form.Item name={input.name} rules={rules}>
                      <InputNew placeholder={input.placeholder} />
                    </Form.Item>
                  </Col>
                )
              case 'select_search':
                return (
                  <Col key={idx} {...Responesive.inputFilter}>
                    <label htmlFor={input.name}>
                      {input.label}
                      {rules.map((item) => item.required === true).length ? (
                        <span style={{ color: '#ea5456' }}>*</span>
                      ) : null}
                    </label>
                    <Form.Item name={input.name} rules={rules}>
                      <Select
                        title={''}
                        data={input.data}
                        placeholder={input.placeholder}
                        showSearch
                        scrollableId={formName}
                        filterOption={(input, option) => {
                          return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                      />
                    </Form.Item>
                  </Col>
                )
              case 'select_year':
                return (
                  <Col key={idx} {...Responesive.inputFilter}>
                    <label htmlFor={input.name}>
                      {input.label}
                      {rules.map((item) => item.required === true).length ? (
                        <span style={{ color: '#ea5456' }}>*</span>
                      ) : null}
                    </label>
                    <SelectYear
                      formName={formName}
                      name={input.name}
                      rules={rules}
                      placeholder={t(input.placeholder)}
                      handleChange={() => {}}
                    />
                  </Col>
                )
              case 'select_multiple':
                return (
                  <Col key={idx} {...Responesive.inputFilter}>
                    <label htmlFor={input.name}>
                      {input.label}
                      {rules.map((item) => item.required === true).length ? (
                        <span style={{ color: '#ea5456' }}>*</span>
                      ) : null}
                    </label>
                    <Form.Item name={input.name} rules={rules}>
                      <Select
                        title={''}
                        data={input.data}
                        placeholder={input.placeholder}
                        multiple
                        scrollableId={formName}
                      />
                    </Form.Item>
                  </Col>
                )
              case 'select_operation_status':
                return (
                  <Col key={idx} {...Responesive.inputFilter}>
                    <label htmlFor={input.name}>
                      {input.label}
                      {rules.map((item) => item.required === true).length ? (
                        <span style={{ color: '#ea5456' }}>*</span>
                      ) : null}
                    </label>
                    <SelectOperationStatus
                      rules={rules}
                      formName={formName}
                      name={input.name}
                      placeholder={input.placeholder}
                      isFilterScreen={input.isFilterScreen}
                      handleChange={() => {}}
                    />
                  </Col>
                )
              case 'select':
                return (
                  <Col key={idx} {...Responesive.inputFilter}>
                    <label htmlFor={input.name}>
                      {input.label}
                      {rules.map((item) => item.required === true).length ? (
                        <span style={{ color: '#ea5456' }}>*</span>
                      ) : null}
                    </label>
                    <Form.Item name={input.name} rules={rules}>
                      <Select data={input.data} placeholder={input.placeholder} scrollableId={formName} />
                    </Form.Item>
                  </Col>
                )
              case 'number':
                return (
                  <Col key={idx} {...Responesive.inputFilter}>
                    <label htmlFor={input.name}>
                      {input.label}
                      {rules.map((item) => item.required === true).length ? (
                        <span style={{ color: '#ea5456' }}>*</span>
                      ) : null}
                    </label>
                    <Form.Item id={`number${idx}`} name={input.name} rules={rules}>
                      <InputNumberNew />
                    </Form.Item>
                  </Col>
                )
              case 'datepicker':
                return (
                  <Col key={idx} {...Responesive.inputFilter}>
                    <Form.Item hidden={true} name={input.name} rules={rules}>
                      <Input />
                    </Form.Item>
                    <label htmlFor={input.name}>
                      {input.label}
                      {rules.map((item) => item.required === true).length ? (
                        <>
                          <span style={{ color: '#ea5456' }}>*</span>
                        </>
                      ) : null}
                    </label>
                    <Form.Item name={input.nameThowError}>
                      <>
                        <Datepicker
                          language="th"
                          setValueDateFn={(data) => {
                            formFilter.setFieldsValue({
                              [input.name]: data,
                            })
                            setErrorField(input.nameThowError, false) // set false error field
                          }}
                        />
                      </>
                    </Form.Item>
                  </Col>
                )
              default:
                return <></>
            }
          })}
        </Row>
        <Row>
          <Col {...Responesive.buttonFilter} align="middle">
            <Form.Item>
              <>
                <Space size={[8, 8]} wrap>
                  <ButtonNew type="search" roles={{ type: 'add', menu: 'example' }} htmlType="submit">
                    {t('บันทึก')}
                  </ButtonNew>
                  &nbsp;
                  <ButtonNew type="clear" onClick={() => formFilter.resetFields()}>
                    {t('ยกเลิก')}
                  </ButtonNew>
                </Space>
              </>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default FormFiltersBasicNew
