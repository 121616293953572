import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { ModalNew } from '../../../../../components/modal/Modal'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { encryptInput } from '../../../../../utilitys/crypto'
import { Alert } from '../../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { displayError, displayText } from '../../../../../utilitys/helper'
import { FORWARD_SATISFACTION_ASSESSMENT } from '../../graphql/Mutation'
import SelectPersonnel from '../../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'

const ForwardModal = (props) => {
  const { dataParams } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const formName = 'forwardModalForm'
  const [visible, setVisible] = useState(false)

  const Responsive = {
    md: 24,
    lg: 24,
  }

  /* ---------------- API ----------- */

  const [
    forwardSatisfactionAssessmentFN,
    {
      loading: loadingForwardSatisfactionAssessment,
      error: errorForwardSatisfactionAssessment,
      data: dataForwardSatisfactionAssessment,
    },
  ] = useMutation(FORWARD_SATISFACTION_ASSESSMENT)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    setVisible(props.open)
  }, [props.open])

  const onFinish = (inputData) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการส่งแบบประเมินความพึงพอใจ ?'),
      onOk() {
        forwardSatisfactionAssessmentFN({
          variables: {
            input: encryptInput({
              formResultId: dataParams?.data?.formResultId,
              makerBy: inputData.makerBy,
            }),
          },
        })
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    if (dataForwardSatisfactionAssessment) {
      Alert({
        type: 'success',
        title: t('ส่งแบบประเมินความพึงพอใจสำเร็จ'),
        onOk() {
          props.refetch()
        },
      })
    }
    if (errorForwardSatisfactionAssessment) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถส่งแบบประเมินความพึงพอใจได้'),
        content: t(displayError(errorForwardSatisfactionAssessment)),
        onOk() {},
      })
    }
  }, [dataForwardSatisfactionAssessment])

  function onClose(e) {
    setVisible(e)
    props.close(e)
  }

  return (
    <>
      {loadingForwardSatisfactionAssessment && <SpinnersNew />}
      <ModalNew
        visible={visible}
        typeAction={dataParams?.type_action}
        testTitle={t('ส่งแบบประเมินความพึงพอใจ')}
        onSubmit={form.submit}
        onClose={(e) => {
          onClose(e)
        }}
        type="medium"
      >
        <Row align="center">
          <Col {...Responsive} md={16} offset={6} align="left">
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={6} align="left">
                <Label type="tab-header-inactive">{t('ประจำปี')}</Label>
              </Col>
              <Col md={18} align="left">
                <Label type="body-primary">
                  {displayText(dataParams?.data?.formYear + 543, dataParams?.data?.formYear)}
                </Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={6} align="left">
                <Label type="tab-header-inactive">{t('ชื่อแบบประเมิน')}</Label>
              </Col>
              <Col md={18} align="left">
                <Label type="body-primary">{`${dataParams?.data?.formCode} : ${displayText(
                  dataParams?.data?.formNameTH,
                  dataParams?.data?.formNameEN,
                )}`}</Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={6} align="left">
                <Label type="tab-header-inactive">{t('ประเภทการประเมิน')}</Label>
              </Col>
              <Col md={18} align="left">
                <Label type="body-primary">แบบประเมินความพึงพอใจ</Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={6} align="left">
                <Label type="tab-header-inactive">{t('หน่วยตรวจสอบ')}</Label>
              </Col>
              <Col md={18} align="left">
                <Label type="body-primary">{dataParams?.data?.organizationNameTH}</Label>
              </Col>
            </Row>
          </Col>
        </Row>
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name="makerBy"
            label={<Label type="tab-header-inactive">{t('ผู้ทำแบบประเมิน')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกผู้ทำแบบประเมิน') }]}
          >
            <SelectPersonnel
              formname={formName}
              placeholder={t('เลือกผู้ทำแบบประเมิน')}
              filters={{
                organizationCode: dataParams?.data?.organizationCode,
                personnelCode: dataParams?.data?.responsibilityBy,
              }}
              handleChange={(e) => {
                form.setFieldsValue({
                  makerBy: e?.value,
                })
              }}
            />
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}

export default ForwardModal
