import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { useLazyQuery } from '@apollo/client'
import { LabelNew } from '../../../components/label/Label'
import { ButtonNew } from '../../../components/button/Button'
import { Status } from '../../../components/status/Status'
import { LabelNew as Label } from '../../../components/label/Label'
import { Datatable } from '../../../components/datatable/Datatable'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Columns } from './utils/TimeSheetReportColumns'
import SelectJobGroups from '../../../components/inputfromapi/selectjobgroups/SelectJobGroups.jsx'
import SelectJobStatus from '../../../components/inputfromapi/selectjobstatus/SelectJobStatus'
import SelectJobTypes from '../../../components/inputfromapi/selectjobtypes/SelectJobTypes'
import SelectUrgents from '../../../components/inputfromapi/selecturgents/SelectUrgents.jsx'
import SelectProjectTypes from '../../../components/inputfromapi/selectprojecttypes/SelectProjectTypes.jsx'
import TimeSheetReportModal from './TimeSheetReportModal'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { handleSort } from '../../../utilitys/pagination'
import { dateDisplay, timeDisplay } from '../../../utilitys/dateHelper'
import { GET_TIMESHEET_REPORT_PAGINATION } from './graphql/Query'
import { TIMESHEET_DETAIL_EXPORT } from './graphql/Query'
import { displayError } from '../../../utilitys/helper'
import SendEmailReportModal from '../components/SendEmailReportModal'

const TimeSheetReport = (props) => {
  const { menu } = props
  const [user] = useDecodeUser()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const ResponsiveTable = {
    md: 24,
    lg: 24,
  }

  const Responsive = {
    md: 8,
    lg: 8,
  }

  const breadcrumbList = [{ label: t('รายงาน') }, { label: t('รายงานการปฏิบัติงานรายวัน') }]

  const [exportAll, setExportAll] = useState(false)
  const [dataFilter, setDataFilter] = useState(false)
  const [disabled /* setDisabled */] = useState(false)
  const [fieldJobGroup, setFieldJobGroup] = useState()
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' },
  ])
  const [filters, setFilters] = useState({})
  const [dataLists, setDataLists] = useState([])
  const [visible, setVisible] = useState(false)

  /* สำหรับการส่งเมลรายงาน */
  const [visibleSendMail, setVisibleSendMail] = useState(false)
  const [mailValue, setMailValue] = useState()
  const [inputSendEmail, setInputSendEmail] = useState()

  /* ---------------- API ----------- */
  const [getDataPaginationFn, dataPagination] = useLazyQuery(GET_TIMESHEET_REPORT_PAGINATION)
  const [
    timeSheetDetailExportFn,
    { data: dataTimeSheetDetail, loading: loadingTimeSheetDetail, error: errorTimeSheetDetail },
  ] = useLazyQuery(TIMESHEET_DETAIL_EXPORT)

  useEffect(() => {
    if (Object.keys(filters).length !== 0) {
      getDataPaginationFn({
        variables: {
          input: encryptInput({
            filters: { ...filters, personnelCode: user?.pCode },
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
    } else {
      setDataLists([])
    }
  }, [page, limit, sort, user])

  useEffect(() => {
    if (dataPagination.data) {
      let response = dataPagination?.data?.getTimeSheetPagination
      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            key: data.no,
            jobAndProjectName: displayText(data.jobAndProjectNameTH, data.jobAndProjectNameEN) || '-',
            jobGroupName: displayText(data.jobGroupNameTH, data.jobGroupNameEN) || '-',
            typeName: displayText(data.typeNameTH, data.typeNameEN) || '-',
            jobDetail: displayText(data.jobDetailTH, data.jobDetailEN) || '-',
            dateStart: dateDisplay(data.dateStart) || '-',
            dateEnd: dateDisplay(data.dateEnd) || '-',
            timeStart: timeDisplay(data.timeStart) || '-',
            timeEnd: timeDisplay(data.timeEnd) || '-',
            jobStatusName: <Status text={data.jobStatusNameTH} />,
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'download',
                      onClick: () => {
                        setVisible(true), setExportAll(false), setDataFilter({ timeSheetId: data.timeSheetId })
                      },
                    },
                    {
                      type: 'settings',
                      onClick: () => {
                        setVisibleSendMail(true)
                        setMailValue({ reportName: 'รายงานการปฏิบัติงานรายวัน', refCode: data.timeSheetId })
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }

    if (dataPagination.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(dataPagination.error),
        onOk() {},
      })
    }
  }, [dataPagination.data])

  /* สำหรับการส่งเมลรายงาน */
  useEffect(() => {
    if (inputSendEmail) {
      const dataInput = {
        timeSheetId: mailValue.refCode,
        personnelCode: user.pCode,
        lang: localStorage.getItem('lang'),
        emailData: inputSendEmail, // สำหรับการส่งเมลรายงาน
      }

      // Call API Export
      timeSheetDetailExportFn({
        variables: {
          input: encryptInput(dataInput),
        },
      })
    }
  }, [inputSendEmail])

  useEffect(() => {
    if (dataTimeSheetDetail) {
      Alert({
        type: 'success',
        title: t('ส่งอีเมลสำเร็จ'),
        onOk() {},
      })
    }

    if (errorTimeSheetDetail) {
      Alert({
        type: 'error',
        title: t('ส่งอีเมลไม่สำเร็จ'),
        content: t(displayError(errorTimeSheetDetail)),
        onOk() {},
      })
    }
  }, [dataTimeSheetDetail])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    const inputSearch = {
      jobGroupCode: input.jobGroupCode || '',
      jobTypeCode: input.jobTypeCode || '',
      projectTypeCode: input.projectTypeCode || '',
      jobStatusCode: input.jobStatusCode || '',
      dateEnd: input.dateEnd || '',
      dateStart: input.dateStart || '',
      urgentCode: input.urgentCode || '',
      personnelCode: user.pCode,
    }
    setFilters(inputSearch)
    getDataPaginationFn({
      variables: {
        input: encryptInput({
          filters: inputSearch,
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }

  return (
    <>
      {(dataPagination.loading || loadingTimeSheetDetail) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('รายงานการปฏิบัติงานรายวัน')} />
      <CardNew topic={t('Filters')} icon="Filter" toggledrop="true" buttontopright="true">
        <Form name="form-search" form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[12, 0]}>
            <Col {...Responsive}>
              <Form.Item name="jobGroupCode" label={<LabelNew type="tab-header-inactive">{t('ลักษณะงาน')}</LabelNew>}>
                <SelectJobGroups
                  placeholder={t('เลือกลักษณะงาน')}
                  formname={'form-search'}
                  filters={{ isActive: 1 }}
                  handleChange={(e) => {
                    form.setFieldsValue({ jobGroupCode: e?.value }),
                      setFieldJobGroup(e?.value),
                      form.resetFields(['projectTypeCode', 'jobTypeCode'])
                  }}
                  onClear={() => {
                    setFieldJobGroup()
                  }}
                  disabled={disabled}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              {fieldJobGroup === '10' ? (
                <Form.Item name="projectTypeCode" label={<LabelNew type="tab-header-inactive">{t('ประเภท')}</LabelNew>}>
                  <SelectProjectTypes
                    placeholder={t('เลือกประเภทโครงการ')}
                    formname={'form-search'}
                    filters={{ isActive: 1 }}
                    handleChange={(e) => {
                      form.setFieldsValue({ projectTypeCode: e?.value })
                    }}
                    disabled={disabled ? disabled : fieldJobGroup ? false : true}
                  />
                </Form.Item>
              ) : (
                <Form.Item name="jobTypeCode" label={<LabelNew type="tab-header-inactive">{t('ประเภท')}</LabelNew>}>
                  <SelectJobTypes
                    placeholder={t('เลือกประเภทงาน')}
                    formname={'form-search'}
                    filters={{ jobGroupCode: fieldJobGroup || null, isActive: 1 }}
                    handleChange={(e) => {
                      form.setFieldsValue({ jobTypeCode: e?.value })
                    }}
                    disabled={disabled ? disabled : fieldJobGroup ? false : true}
                  />
                </Form.Item>
              )}
            </Col>
            <Col {...Responsive}>
              <Form.Item name="urgentCode" label={<LabelNew type="tab-header-inactive">{t('ความเร่งด่วน')}</LabelNew>}>
                <SelectUrgents
                  placeholder={t('เลือกความเร่งด่วน')}
                  formname={'form-search'}
                  handleChange={(e) => form.setFieldsValue({ urgentCode: e?.value })}
                  // disabled={disabled}
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'dateStart'}
                label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระบุ'),
                  },
                ]}
              >
                <Datepicker
                  setValueDateFn={async (data) => {
                    await setIsMinDate(data), await form.setFieldsValue({ dateStart: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateStart: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                  isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item
                name={'dateEnd'}
                label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระบุ'),
                  },
                ]}
              >
                <Datepicker
                  setValueDateFn={async (data) => {
                    await setIsMaxDate(data), await form.setFieldsValue({ dateEnd: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateEnd: null })
                  }}
                  placeholder={t('DD/MM/YYYY')}
                  isMinDate={isMinDate ? new Date(isMinDate) : null}
                  autoComplete="off"
                />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="jobStatusCode" label={<Label type="tab-header-inactive">{t('สถานะดำเนินการ')}</Label>}>
                <SelectJobStatus
                  placeholder={t('เลือกสถานะดำเนินการ')}
                  formname={'form-search'}
                  filters={{ isActive: 1 }}
                  handleChange={(e) => form.setFieldsValue({ jobStatusCode: e?.value })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <ButtonNew type="search" htmlType="submit">
                {t('ค้นหา')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew
                type="export"
                roles={{ type: 'export', menu: menu }}
                // disabled={!allowExport && dataRef.length > 0 ? false : true}
                onClick={() => {
                  setVisible(true), setExportAll(true), setDataFilter(form.getFieldValue())
                }}
              >
                {t('ส่งออก')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew
                type="clear"
                onClick={() => {
                  form.resetFields(), setFilters({})
                }}
              >
                {t('ล้างค่า')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </CardNew>
      <CardNew topic={t('รายการปฏิบัติงานรายวัน')} icon="List" toggledrop={'false'}>
        <Row gutter={[12, 12]}>
          <Col {...ResponsiveTable}>
            <Datatable
              paginationCustom={true}
              columns={Columns}
              data={dataLists}
              total={count}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => {
                const reNewField = [
                  { jobAndProjectName: displayText('jobAndProjectNameTH', 'jobAndProjectNameEN') },
                  { jobGroupName: displayText('jobGroupNameTH', 'jobGroupNameEN') },
                  { typeName: displayText('typeNameTH', 'typeNameEN') },
                  { jobDetail: displayText('jobDetailTH', 'jobDetailEN') },
                  { jobStatusName: displayText('jobStatusNameTH', 'jobStatusNameEN') },
                  { dateStart: 'dateStart' },
                  { dateEnd: 'dateEnd' },
                  { timeStart: 'timeStart' },
                  { timeEnd: 'timeEnd' },
                ]
                setSort(handleSort(e?.sorter, reNewField))
              }}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
            />
          </Col>
        </Row>
      </CardNew>
      <TimeSheetReportModal
        open={visible}
        close={(e) => {
          setVisible(e), setExportAll(false)
        }}
        menu={menu}
        dataFilter={dataFilter}
        exportAll={exportAll}
      />
      <SendEmailReportModal
        menu={menu}
        open={visibleSendMail}
        close={(e) => {
          setVisibleSendMail(e)
        }}
        fileType={{ pdf: true, word: true, excel: true, csv: true }}
        setInputSendEmail={(e) => setInputSendEmail(e)}
        mailValue={mailValue}
      />
    </>
  )
}

export default TimeSheetReport
