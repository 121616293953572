import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { colunmTab2 } from '../../utils/columnAllInRick'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { ButtonNew } from '../../../../../components/button/Button'
import { useLocation } from 'react-router-dom'
// import { Alert } from '../../../../../components/alert/Alert'
import RiskFactorModal from '../modal/RiskFactorModal'
import { checkLanguageReturnData } from '../../components/fnForTemplateRisk'
import { Status } from '../../../../../components/status/Status'
import * as _ from 'lodash'

export default function RiskModelFactorDataType1(props) {
  const Responsive = {
    md: 24,
    lg: 24,
  }
  const { menu, setDelete } = props
  const { t } = useTranslation()
  const { state } = useLocation()
  // const [page, setPage] = useState(1)
  // const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [visible, setVisible] = useState(false)
  const isLang = localStorage.getItem('lang')
  const [totalWeight, settotalWeight] = useState(0)

  /* ---------------- ACTION ----------- */
  const rskModelDelete = (no) => {
    setDelete(no)
  }

  const handleFormOnClose = (e) => {
    setVisible(e)
  }

  useEffect(() => {
    const obj = props.obj.map((dt, key) => {
      return {
        key: key,
        no: dt.riskNo ? Number(dt.riskNo) : Number(key + 1),
        riskModelFacId: dt.riskModelFacId,
        riskModelCode: dt.riskModelCode,
        riskModelFacType: dt.riskModelFacType,
        riskFactorCode: dt.riskFactorCode,
        weight: dt.weight,
        riskFactorName:
          dt.riskFactorCode + ' : ' + checkLanguageReturnData(isLang, dt.riskFactorNameTH, dt.riskFactorNameEN),
        isActive: <Status key={'status-apl-1' + key} text={dt.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}></Status>,
        options:
          state.pageType === 'edit' ? (
            <div key={'div-view-templaterick' + 1} style={{ textAlign: 'left' }}>
              <ButtonGroup
                key={'btn-view-templaterick' + 1}
                menu={menu}
                icons={[
                  {
                    type: 'delete',
                    onClick: () => rskModelDelete(dt.riskNo),
                  },
                ]}
              />
            </div>
          ) : (
            ''
          ),
      }
    })
    const dataUniq = _.uniqBy(obj, 'no')
    let total = 0
    dataUniq.forEach((item) => {
      if (item.weight) {
        total += Number(item.weight)
      }
    })

    settotalWeight(total)
    setCount(dataUniq.length)
    setDataLists(_.orderBy(dataUniq, ['no'], ['asc']))
  }, [])

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col {...Responsive}>
          <Datatable
            columns={colunmTab2}
            data={dataLists}
            total={count}
            isScoll={{ x: true, y: true }}
            searchCustom={true}
            paginationCustom={false}
            pagination={true}
            pageSize={10}
            page={1}
            btnAdd={
              <>
                {state.pageType === 'edit' ? (
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    onClick={() => setVisible(true)}
                  >
                    {t('เพิ่มปัจจัยความเสี่ยง')}
                  </ButtonNew>
                ) : (
                  ''
                )}
              </>
            }
          />
        </Col>
      </Row>
      <RiskFactorModal
        totalWeight={totalWeight}
        rskWeight={props.rskWeight}
        open={visible}
        rskObj={(v) => {
          // setRskObj(v)
          props.getValue(v)
        }}
        onClose={(e) => {
          handleFormOnClose(e)
        }}
        // refetch={() => dataCauseOfIssueList.refetch()}
        menu={menu}
      />
    </>
  )
}
