import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ModalNew } from '../../../../../components/modal/Modal'
import * as _ from 'lodash'
import { Table } from 'antd'
import { useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../../../utilitys/crypto'
import { GET_LOGFILE_DATA_DETAIL } from '../../graphql/Query'
import { compareObject, displayText } from '../../../../../utilitys/helper'
import { Row, Col } from 'antd'
import { colors } from '../../../../../configs/styles.config'
import { SpinnersNew } from '../../../../../components/spinners/Spinners.jsx'
import { displayError } from '../../../../../utilitys/helper'
import { Alert } from '../../../../../components/alert/Alert'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  label: { sm: 10, md: 6, lg: 6 },
  data: { sm: 14, md: 18, lg: 18 },
}

const Logfileeditdatadetailmodal = (props) => {
  const { dataParams } = props
  const { t } = useTranslation()
  const [visible, setVisible] = useState(false)
  const [dataLists, setDataLists] = useState([])

  /* ---------------- API----------- */
  const [getLogFileEditDataDetail, datagetLogFileEditData] = useLazyQuery(GET_LOGFILE_DATA_DETAIL)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    setVisible(props.open)
    if (dataParams) {
      getLogFileEditDataDetail({
        variables: {
          input: encryptInput({
            logFileEdit: {
              refCode: dataParams.refCode,
              changeType: dataParams.changeTypeId,
            },
          }),
        },
      })
    }
  }, [dataParams, props.ope])

  useEffect(() => {
    if (dataParams && datagetLogFileEditData.data) {
      let response = datagetLogFileEditData.data.getLogFileEditDataDetail
      setDataLists(
        _.map(response.result, (item, key) => {
          const jsonAfterDate = item.jsonDataAfter && JSON.parse(JSON.parse(item.jsonDataAfter)[0].jsonData)[0]
          const jsonBeforDate = item.jsonDataBefore && JSON.parse(JSON.parse(item.jsonDataBefore)[0].jsonData)[0]
          const resDataAfterObj = { input: dataParams.changeTypeId === 1 ? jsonBeforDate : jsonAfterDate }
          const resDataBeforeObj = { input: dataParams.changeTypeId === 1 ? null : jsonBeforDate }

          const compareObj = compareObject(resDataBeforeObj, resDataAfterObj)

          return {
            key: key + 1,
            jsonDataAfter: compareObj.after || '',
            jsonDataBefore: compareObj.before || '',
          }
        }),
      )
    }
    if (datagetLogFileEditData.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(datagetLogFileEditData.error)),
        onOk() {},
      })
    }
  }, [datagetLogFileEditData.data])

  /* ---------------- ACTION ----------- */
  function onClose(e) {
    setVisible(e)
    props.close(e)
  }

  const columns = [
    {
      title: t('ข้อมูลก่อนหน้า'),
      dataIndex: 'jsonDataBefore',
      key: 'jsonDataBefore',
      width: '50%',
      render: (item, record) => {
        const textArr = []
        for (const [key, value] of Object.entries(record.jsonDataBefore)) {
          textArr.push(`${key}: ${value}`)
        }
        return (
          <>
            {_.map(textArr, (value, index) => {
              return <li key={index}>{value}</li>
            })}
          </>
        )
      },
    },
    {
      title: t('ข้อมูลหลังจากแก้ไข'),
      dataIndex: 'jsonDataAfter',
      key: 'jsonDataAfter',
      width: '50%',
      render: (item, record) => {
        const textArr = []
        for (const [key, value] of Object.entries(record.jsonDataAfter)) {
          textArr.push(`${key}: ${value}`)
        }
        return (
          <>
            {_.map(textArr, (value, index) => {
              return <li key={index}>{value}</li>
            })}
          </>
        )
      },
    },
  ]

  return (
    <>
      {datagetLogFileEditData.loading && <SpinnersNew />}
      <ModalNew
        visible={visible}
        style={{ objectFit: 'fill' }}
        testTitle={
          !dataParams
            ? t(' ')
            : dataParams.changeTypeId === 1
            ? t('ดูรายละเอียดการเพิ่มข้อมูล')
            : dataParams.changeTypeId === 2
            ? t('ดูรายละเอียดการแก้ไขข้อมูล')
            : t('ดูรายละเอียดการลบข้อมูล')
        }
        typeAction="view"
        onClose={(e) => onClose(e)}
        type="medium"
        okButtonProps={dataParams?.type_action === 'view' ? { style: { display: 'none' } } : {}}
      >
        <Row gutter={[16, 16]} align={{ align: 'center' }} style={{ marginBottom: '18px' }}>
          {dataParams ? (
            <>
              <Col {...Responsive.label}>{t('ผู้ใช้งาน')}</Col>
              <Col {...Responsive.data} style={{ color: colors.primary }}>
                {' '}
                {dataParams.username}
              </Col>
              <Col {...Responsive.label}>{t('อีเมล')}</Col>
              <Col {...Responsive.data} style={{ color: colors.primary }}>
                {' '}
                {dataParams.email}
              </Col>
              <Col {...Responsive.label}>{t('เมนู')}</Col>
              <Col {...Responsive.data} style={{ color: colors.primary }}>
                {' '}
                {displayText(dataParams.menuNameTH, dataParams.menuNameEN)}
              </Col>
              <Col {...Responsive.label}>{t('ประเภทการแก้ไข')}</Col>
              <Col {...Responsive.data} style={{ color: colors.primary }}>
                {' '}
                {dataParams.changeType}
              </Col>
              <Col {...Responsive.label}>{t('วันเวลาที่แก้ไข')}</Col>
              <Col {...Responsive.data} style={{ color: colors.primary }}>
                {' '}
                {dataParams.createdAt}
              </Col>
            </>
          ) : null}
        </Row>
        <Row gutter={[16, 16]} align={{ align: 'center' }}>
          <Col {...Responsive}>
            <Table columns={columns} dataSource={[...dataLists]} pagination={false} bordered tableLayout="fixed" />
          </Col>
        </Row>
      </ModalNew>
    </>
  )
}

export default Logfileeditdatadetailmodal
