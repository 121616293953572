import { Trans } from 'react-i18next'
export const Columns = [
  {
    title: <Trans>ชื่องาน/โครงการ</Trans>,
    dataIndex: 'jobAndProjectName',
    width: '250px',
  },
  {
    title: <Trans>ลักษณะงาน</Trans>,
    dataIndex: 'jobGroupName',
    width: '150px',
  },
  {
    title: <Trans>ประเภทงาน/โครงการ</Trans>,
    dataIndex: 'typeName',
    width: '250px',
  },
  {
    title: <Trans>รายละเอียดงาน</Trans>,
    dataIndex: 'jobDetail',
    width: '300px',
  },
  {
    title: <Trans>วันที่เริ่มต้น</Trans>,
    dataIndex: 'dateStart',
    width: '150px',
  },
  {
    title: <Trans>เวลาเริ่มต้น</Trans>,
    dataIndex: 'timeStart',
    width: '150px',
  },
  {
    title: <Trans>วันที่สิ้นสุด</Trans>,
    dataIndex: 'dateEnd',
    width: '150px',
  },
  {
    title: <Trans>เวลาสิ้นสุด</Trans>,
    dataIndex: 'timeEnd',
    width: '150px',
  },
  {
    title: <Trans>สถานะดำเนินงาน</Trans>,
    dataIndex: 'jobStatusName',
    width: '200px',
  },
  {
    title: <Trans>สถานะอนุมัติ</Trans>,
    dataIndex: 'approveStatusName',
    width: '200px',
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '100px',
  },
]
