import React from 'react'
import { Row, Col } from 'antd'
import AuditResultList from './AuditResultList'

const AuditResultIndex = (props) => {
  const { menu } = props
  const Responsive = {
    md: 24,
    lg: 24,
  }
  return (
    <>
      <Row>
        <Col {...Responsive}>
          <AuditResultList menu={menu} />
        </Col>
      </Row>
    </>
  )
}

export default AuditResultIndex
