import React from 'react'
import { Row, Col } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import ResultAssessmentList from './ResultAssessmentList'
import { useTranslation } from 'react-i18next'

/**
 * @function ResultAssessmentIndex
 * @description
 * Menu: SatisfactionAssessment
 * เมนู: ประเมินความพึงพอใจ
 * @returns Component
 */
const ResultAssessmentIndex = ({ menu }) => {
  const { t } = useTranslation()
  const arrCrumb = [{ label: t('งานปฏิบัติการตรวจสอบ'), path: '#' }, { label: t('ผลของการประเมิน') }]
  const Responsive = {
    md: 24,
    lg: 24,
  }
  return (
    <>
      <Row>
        <Col {...Responsive}>
          <Breadcrumb data={arrCrumb} title={t('ผลของการประเมิน')} />
          <ResultAssessmentList menu={menu} />
        </Col>
      </Row>
    </>
  )
}

export default ResultAssessmentIndex
