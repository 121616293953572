import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import * as moment from 'moment'
import { Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { GET_CONTROL_RESULT_PAGINATION } from './graphql/Query'
import { assessListCrumb } from './utils/constants'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { displayText, displayError } from '../../../utilitys/helper'
import { encryptInput } from '../../../utilitys/crypto'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { Datatable } from '../../../components/datatable/Datatable'
import { CardNew } from '../../../components/card/Card'
import { Status } from '../../../components/status/Status'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { colors } from '../../../configs/styles.config'
import { handleSort } from '../../../utilitys/pagination'
import { useLanguage } from '../../../hooks/useLanguage'
import AccessFilter from './components/filter/AccessFilter'
import SendFormModal from './components/modal/SendFormModal'

const AssessList = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [user] = useDecodeUser()
  const [lang] = useLanguage()

  const [page, setPage] = useState(1)
  const [count, setCount] = useState(0)
  const [limit, setLimit] = useState(10)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState([])
  const [dataLists, setDataLists] = useState([])
  const [controlResultId, setControlResultId] = useState(null)
  const [isPCode, setPCode] = useState(null)
  const [modal, setModal] = useState(false)

  const [getControlResultsFn, getControlResults] = useLazyQuery(GET_CONTROL_RESULT_PAGINATION)

  useEffect(() => {
    user && setPCode(user?.pCode)
  }, [user])

  useEffect(() => {
    if (isPCode) {
      getControlResultsFn({
        variables: {
          input: encryptInput({
            filters: { ...filters, personnelCode: isPCode, lang },
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
    }
  }, [page, limit, filters, sort, isPCode, lang])

  useEffect(() => {
    if (getControlResults?.data) {
      let response = getControlResults?.data?.getControlResultPagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            ...data,
            key: data.controlResultId,
            operationStatusCodeButton: data.operationStatusCode,
            operationStatusCodeIcon: data.operationStatusCode,
            operationStatusCode: data.operationStatusCode,
            controlFormEN: data.controlFormEN,
            controlFormTH: data.controlFormTH,
            controlFormCode: data.controlFormCode,
            controlFormYear: displayText(data.controlFormYear + 543, data.controlFormYear),
            dateEnd: dateDisplay(data.dateEnd),
            coreDateEnd: data.dateEnd,
          }
        }),
      )
    }

    if (getControlResults?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getControlResults?.error)),
        onOk() {},
      })
    }
  }, [getControlResults?.data])

  const checkWarningRecord = (record) => {
    const curDate = moment().format('YYYY-MM-DD')
    const { coreDateEnd, operationStatusCode } = record

    if (!moment(coreDateEnd).isSame(curDate) && operationStatusCode === '11') {
      if ((moment(coreDateEnd).isBefore() && operationStatusCode === '11') || operationStatusCode === '12') {
        return { color: colors.danger }
      } else {
        return {}
      }
    } else {
      return {}
    }
  }

  const onFilter = (data) => {
    Promise.all([setPage(1), setLimit(10), setFilters(data)])
  }

  const columns = [
    {
      title: t('ประจำปี'),
      dataIndex: 'controlFormYear',
      width: '100px',
      align: 'center',
      sorter: { multiple: 1 },
      render: (text, record) => <div style={checkWarningRecord(record)}>{text}</div>,
    },
    {
      title: t('รหัส'),
      dataIndex: 'controlFormCode',
      width: '80px',
      align: 'left',
      sorter: { multiple: 2 },
      render: (text, record) => <div style={checkWarningRecord(record)}>{text}</div>,
    },
    {
      title: t('ชื่อแบบประเมิน'),
      dataIndex: displayText('controlFormTH', 'controlFormEN'),
      width: '300px',
      render: (text, record) => <div style={checkWarningRecord(record)}>{text}</div>,
    },
    {
      title: t('กำหนดส่ง'),
      dataIndex: 'dateEnd',
      align: 'center',
      width: '100px',
      sorter: { multiple: 3 },
      render: (text, record) => <div style={checkWarningRecord(record)}>{text}</div>,
    },
    {
      title: t('สถานะ'),
      dataIndex: 'operationStatusCode',
      align: 'center',
      width: '150px',
      sorter: { multiple: 4 },
      render: (d, record) => {
        switch (d) {
          case '11':
            return <Status text={'ยังไม่ประเมิน'} />
          case '12':
            if (record?.makerBy === isPCode) return <Status text={'ยังไม่ประเมิน'} />
            if (record?.responsibilityBy === isPCode) return <Status text={'ส่งต่อแบบประเมิน'} />
            return
          case '21':
            return <Status text={'กำลังดำเนินการ'} />
          case '22':
            if (record?.makerBy === isPCode && record?.makerStatusCode === '31') {
              return <Status text={'ดำเนินการเสร็จสิ้น'} />
            } else {
              return <Status text={'รอตรวจสอบ'} />
            }
          case '31':
            return <Status text={'ดำเนินการเสร็จสิ้น'} />
          default:
            break
        }
      },
    },
    {
      title: '',
      dataIndex: 'operationStatusCodeButton',
      align: 'center',
      width: '150px',
      render: (text, record) => {
        switch (record.operationStatusCode) {
          case '11':
            return (
              <ButtonNew
                type="flagOutlinedInTableBlue"
                roles={{ type: 'edit', menu: props.menu }}
                onClick={() => {
                  navigate('/internalcontrol/assess/edit', {
                    state: {
                      activeType: 'edit',
                      controlFormCode: record?.controlFormCode,
                      controlResultId: record?.controlResultId,
                      operationStatusCode: record?.operationStatusCode,
                    },
                  })
                }}
              >
                {t('เริ่มประเมิน')}
              </ButtonNew>
            )
          case '12': {
            if (record?.makerBy && record?.makerBy === isPCode) {
              return (
                <ButtonNew
                  type="flagOutlinedInTableBlue"
                  roles={{ type: 'edit', menu: props.menu }}
                  onClick={() => {
                    navigate('/internalcontrol/assess/edit', {
                      state: {
                        activeType: 'edit',
                        controlFormCode: record?.controlFormCode,
                        controlResultId: record?.controlResultId,
                        operationStatusCode: record?.operationStatusCode,
                      },
                    })
                  }}
                >
                  {t('เริ่มประเมิน')}
                </ButtonNew>
              )
            } else {
              return (
                <ButtonNew type="moreHorizontalInTableOrange" roles={{ type: 'view', menu: props.menu }}>
                  {t('มอบหมาย')}
                </ButtonNew>
              )
            }
          }
          case '21': {
            return (
              <ButtonNew
                type="moreHorizontalInTableOrange"
                roles={{
                  type:
                    record?.makerBy === isPCode
                      ? 'edit'
                      : record?.responsibilityBy === isPCode
                      ? 'view'
                      : record?.makerBy
                      ? 'view'
                      : 'edit',
                  menu: props.menu,
                }}
                onClick={() => {
                  if (record?.makerBy === isPCode) {
                    navigate('/internalcontrol/assess/edit', {
                      state: {
                        activeType: 'edit',
                        controlFormCode: record?.controlFormCode,
                        controlResultId: record?.controlResultId,
                        operationStatusCode: record?.operationStatusCode,
                      },
                    })
                  }
                  if (record?.responsibilityBy === isPCode) {
                    navigate(`/internalcontrol/assess/${record?.makerBy ? 'view' : 'edit'}`, {
                      state: {
                        activeType: record?.makerBy ? 'view' : 'edit',
                        controlFormCode: record?.controlFormCode,
                        controlResultId: record?.controlResultId,
                        operationStatusCode: record?.operationStatusCode,
                      },
                    })
                  }
                }}
              >
                {t('กำลังดำเนินการ')}
              </ButtonNew>
            )
          }
          case '22':
            if (record?.makerBy === isPCode && record?.makerStatusCode === '31') {
              return (
                <ButtonNew
                  type="moreHorizontalInTableGreen"
                  roles={{ type: 'view', menu: props.menu }}
                  onClick={() =>
                    navigate('/internalcontrol/assess/view', {
                      state: {
                        activeType: 'view',
                        controlFormCode: record?.controlFormCode,
                        controlResultId: record?.controlResultId,
                        operationStatusCode: record?.operationStatusCode,
                      },
                    })
                  }
                >
                  {t('ดูผลการประเมิน')}
                </ButtonNew>
              )
            } else {
              return (
                <ButtonNew
                  type="moreHorizontalInTableOrange"
                  roles={{ type: isPCode === record?.makerBy ? 'view' : 'edit', menu: props.menu }}
                  onClick={() => {
                    if (isPCode === record?.makerBy) {
                      navigate('/internalcontrol/assess/view', {
                        state: {
                          activeType: 'view',
                          controlFormCode: record?.controlFormCode,
                          controlResultId: record?.controlResultId,
                          operationStatusCode: record?.operationStatusCode,
                        },
                      })
                    } else {
                      navigate('/internalcontrol/assess/edit', {
                        state: {
                          activeType: 'edit',
                          controlFormCode: record?.controlFormCode,
                          controlResultId: record?.controlResultId,
                          operationStatusCode: record?.operationStatusCode,
                        },
                      })
                    }
                  }}
                >
                  {t('รอตรวจสอบ')}
                </ButtonNew>
              )
            }
          case '31':
            return (
              <ButtonNew
                type="moreHorizontalInTableGreen"
                roles={{ type: 'view', menu: props.menu }}
                onClick={() =>
                  navigate('/internalcontrol/assess/view', {
                    state: {
                      activeType: 'view',
                      controlFormCode: record?.controlFormCode,
                      controlResultId: record?.controlResultId,
                      operationStatusCode: record?.operationStatusCode,
                    },
                  })
                }
              >
                {t('ดูผลการประเมิน')}
              </ButtonNew>
            )
          default:
            return null
        }
      },
    },
    {
      title: '',
      dataIndex: 'operationStatusCodeIcon',
      width: '80px',
      align: 'right',
      render: (text, record) => {
        let list = [
          {
            type: 'view',
            onClick: () => {
              navigate('/internalcontrol/assess/view', {
                state: {
                  activeType: 'view',
                  controlFormCode: record?.controlFormCode,
                  controlResultId: record?.controlResultId,
                  operationStatusCode: record?.operationStatusCode,
                },
              })
            },
          },
        ]

        if (record?.responsibilityBy === isPCode) {
          if (record?.operationStatusCode === '11' || record?.operationStatusCode === '12') {
            list.unshift({
              type: 'send',
              onClick: () => {
                setControlResultId(record?.controlResultId)
                setModal(true)
              },
            })
          }
        }

        return <ButtonGroup menu={props.menu} size={18} icons={list} />
      },
    },
  ]

  return (
    <>
      {getControlResults?.loading && <SpinnersNew />}
      <Col sm={24} md={24} lg={24}>
        <Breadcrumb data={assessListCrumb} title={t('ประเมินควบคุมภายใน')} />
        <AccessFilter onFilter={onFilter} menu={props.menu} />
        <div style={{ marginBottom: '2rem' }}>
          <CardNew topic={t('รายการประเมินควบคุมภายใน')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col sm={24} md={24} lg={24} xl={24}>
                <Datatable
                  columns={columns}
                  data={[...dataLists]}
                  total={count}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  pageSize={limit}
                  page={page}
                  searchCustom={true}
                  handleTableChange={(e) => setSort(handleSort(e?.sorter))}
                />
              </Col>
            </Row>
          </CardNew>
        </div>
        <SendFormModal
          controlResultId={controlResultId}
          open={modal}
          menu={props.menu}
          close={(e) => setModal(e)}
          getForwardControlResult={() => getControlResults.refetch()}
        />
      </Col>
    </>
  )
}

AssessList.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default AssessList
