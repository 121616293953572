import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Space, Form } from 'antd'
import { ModalNew } from '../../../../components/modal/Modal'
import { InputNumberNew } from '../../../../components/input/Input'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { LabelNew as Label } from '../../../../components/label/Label'
import { RadioGroup, Radio } from '../../../../components/radio/Radio'
import { encryptInput } from '../../../../utilitys/crypto'
import { Alert } from '../../../../components/alert/Alert'
import { MASTER_SET_EMAIL_SAVE } from '../graphql/Mutation'
import { GET_EMAIL_SEND_SETUP_BYID } from '../graphql/Query'
import { displayError } from '../../../../utilitys/helper'

const TabTwoModal = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [disabled, setDisabled] = useState(false)
  const { objData, setStatus } = props

  const [cellGet, { data: dataGet, loading: loadingGet, error: errorGet }] = useLazyQuery(GET_EMAIL_SEND_SETUP_BYID)
  const [cellSave, { data: dataSave, loading: loadingSave, error: errorSave }] = useMutation(MASTER_SET_EMAIL_SAVE)

  useEffect(() => {
    setDisabled(false)
    if (objData.type === 'edit' || objData.type === 'view') {
      const variables = encryptInput({ setMailId: objData.setMailId })
      cellGet({ variables })
    }
    if (objData.type === 'view') {
      setDisabled(true)
    }
  }, [objData])

  const onFinish = (input) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        showConfirm(input)
      },
      onCancel() {},
    })
  }

  const showConfirm = async (input) => {
    let data = {}
    if (objData.setMailId) data = { setMailId: objData.setMailId }
    let dataInput = {
      ...data,
      setMailType: 2,
      time: input.time,
      dueDate: parseInt(input.dueDate),
      isActive: input.isActive,
      typeAction: objData.type,
    }

    await cellSave({
      variables: {
        input: encryptInput(dataInput),
      },
    })
  }

  useEffect(() => {
    if (dataGet) {
      form.setFieldsValue({
        time: dataGet.emalSendSetUpById.time,
        dueDate: dataGet.emalSendSetUpById.dueDate,
        isActive: dataGet.emalSendSetUpById.isActive,
      })
    }
    if (errorGet) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorGet)),
        onOk() {},
      })
    }
  }, [dataGet])

  useEffect(() => {
    if (dataSave) {
      if (dataSave.masterSetEmailSave.setMailId) {
        Alert({
          type: 'success',
          title: t('บันทึกข้อมูลสำเร็จ'),
          onOk() {
            setStatus(true)
            form.resetFields()
            props.close(false)
          },
        })
      }
    }
    if (errorSave) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถบันทึกข้อมูลได้`),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [dataSave])

  function onClose(e) {
    form.resetFields()
    props.close(e)
  }
  return (
    <>
      {loadingSave || (loadingGet && <SpinnersNew />)}
      <ModalNew
        typeAction={objData.type}
        visible={props.open}
        testTitle={t('การแจ้งเตือนตามการติดตาม')}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="TabTwoModal"
        >
          <Form.Item
            name="time"
            label={<Label type="tab-header-inactive">{t('ครั้งที่')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกครั้งที่') }]}
          >
            <InputNumberNew disabled={disabled} placeholder={t('ระบุครั้งที่')} />
          </Form.Item>
          <Form.Item
            name="dueDate"
            label={<Label type="tab-header-inactive">{t('กำหนดวันแจ้งเตือน (วัน)')}</Label>}
            rules={[{ required: true, message: t('กรุณากรอกกำหนดวันแจ้งเตือน (วัน)') }]}
          >
            <InputNumberNew disabled={disabled} placeholder={t('ระบุกำหนดวันแจ้งเตือน (วัน)')} />
          </Form.Item>
          <Form.Item label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>} name="isActive" initialValue={0}>
            <RadioGroup disabled={disabled}>
              <Space size={[8, 8]} wrap>
                <Radio value={1}>
                  <Label type="tab-header-inactive">{t('ใช้งาน')}</Label>
                </Radio>
                &nbsp;
                <Radio value={0}>
                  <Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>
                </Radio>
              </Space>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
export default TabTwoModal
