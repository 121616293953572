import React from 'react'
import FormMultipleRows from './FormMultipleRows'
import Form from './Form'
import FormExample from './FormExample'
import FormFiltersExample from './FormFiltersExample'
import FormFiltersBasicExample from './FormFiltersBasicExample'
const FormIndex = () => {
  return (
    <div>
      <FormFiltersBasicExample />
      <FormFiltersExample />
      <FormExample />
      <FormMultipleRows />
      <Form />
    </div>
  )
}

export default FormIndex
