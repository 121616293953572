import React, { useState } from 'react'
import { Space, Row, Col } from 'antd'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { ButtonNew } from '../../../components/button/Button'

const SpinnersIndex = () => {
  let code = ` import { SpinnersNew } from '../../../components/spinners/Spinners'
 import { CodeBlock } from '../../../components/codeblock/CodeBlock'
 import { ButtonNew } from '../../../components/button/Button'

    const timeOut = () => {
      setTimeout(function () {
        setLoading(false)
      }, 5000)
    }

    const [loading, setLoading] = useState(false)
    export default function App() {
        return (<>
              {loading && <SpinnersNew />}
              <ButtonNew
                  type="primary"
                  onClick={() => {
                    setLoading(true)
                    timeOut()
                  }}
              >
                Open Loading
              </ButtonNew>
              
        </>)
    }
`

  const timeOut = () => {
    setTimeout(function () {
      setLoading(false)
    }, 5000) //
  }

  const [loading, setLoading] = useState(false)
  return (
    <React.Fragment>
      {loading && <SpinnersNew />}
      <div className="site-layout-background">
        <Row>
          <Col span={2}></Col>
          <Col span={19}>
            <div style={{ marginBottom: '15px' }}>
              <Space size={[8, 8]} wrap>
                <ButtonNew
                  type="primary"
                  onClick={() => {
                    setLoading(true)
                    timeOut()
                  }}
                >
                  Open Loading
                </ButtonNew>
              </Space>
            </div>
          </Col>
        </Row>
        <CodeBlock code={code} />
      </div>
    </React.Fragment>
  )
}

export default SpinnersIndex
