import { gql } from '@apollo/client'

export const CREATE_AUDIT_TEAM = gql`
  mutation auditTeamCreate($input: AuditTeamsInput!) {
    auditTeamCreate(input: $input) {
      auditTeamCode
    }
  }
`

export const UPDATE_AUDIT_TEAM = gql`
  mutation auditTeamUpdate($input: AuditTeamsInput!, $auditTeamCode: String!) {
    auditTeamUpdate(input: $input, auditTeamCode: $auditTeamCode) {
      auditTeamCode
    }
  }
`

export const DELETE_AUDIT_TEAM_BY_CODE = gql`
  mutation auditTeamDelete($auditTeamCode: String!) {
    auditTeamDelete(auditTeamCode: $auditTeamCode)
  }
`
