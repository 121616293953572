import React, { useEffect, useState } from 'react'
import { Col, Row, Space, Form, Image } from 'antd'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { CardNew } from '../../../components/card/Card'
import { ButtonNew } from '../../../components/button/Button'
import { InputNew } from '../../../components/input/Input'
import { LabelNew } from '../../../components/label/Label'
import { IconNew } from '../../../components/icon/Icon'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import moment from 'moment'
import { Alert } from '../../../components/alert/Alert'
import { GET_PERSONNELS } from './graphql/Query'
import { DELETE_ALL } from './graphql/Mutation'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization.jsx'
import SelectPersonnelPositions from '../../../components/inputfromapi/selectpersonnelpositions/SelectPersonnelPositions'
import SelectPersonnelType from '../../../components/inputfromapi/selectpersonneltype/SelectPersonnelType'
import { PaginationComp } from '../../../components/pagination/Pagination'
import './PersonnelList.less'
import { useTranslation } from 'react-i18next'
import { decryptData, encryptInput } from '../../../utilitys/crypto'
import { displayError, MaskFullNameText, MaskEmail, displayText } from '../../../utilitys/helper'

const PersonnelList = (props) => {
  const { menu } = props
  const Responesive = {
    inputFilter: {
      sm: 24,
      md: 12,
      lg: 8,
    },
    buttonFilter: {
      sm: 24,
      md: 24,
      lg: 24,
    },
  }
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [page, setPage] = useState(1)
  const [limit] = useState(10)
  const [count, setCount] = useState(0)
  const [oldFilter, setOldFilter] = useState({})
  const [deleteData, { loading: loadingDelete, data: dataDelete, error: errorDelete }] = useMutation(DELETE_ALL)
  const [personnelListFn, getPersonnelList] = useLazyQuery(GET_PERSONNELS)

  const navigate = useNavigate()
  // const [isLang] = useLanguage()
  const [detail, setDetail] = useState([])
  useEffect(() => {
    personnelListFn({
      variables: {
        input: encryptInput({
          filters: { ...oldFilter },
          pagination: { limit, page },
          sort: [
            {
              fieldSort: 'personnelId',
              sortType: 'DESC',
            },
          ],
        }),
      },
    })
  }, [page])

  useEffect(() => {
    let listOfData = []
    if (getPersonnelList.data) {
      const { result, count } = getPersonnelList.data.getPaginationPersonnels

      setCount(count)
      for (const item of result) {
        listOfData.push({
          name: displayText(item.fullName, item.fullNameEN),
          organizationNameTH: displayText(item.organizationNameTH, item.organizationNameEN),
          email: decryptData(item.email),
          age: new Date().getFullYear() - moment(item.dateOfBirth).format('YYYY'),
          refCode: item.personnelCode,
          profilePicture: item.profilePicture,
        })
      }
    }
    setDetail(listOfData)
  }, [getPersonnelList.data])

  // onFinish
  const onFinish = (values) => {
    setOldFilter({
      personnelCode: values.personnelCode,
      positionCode: values.positionCode,
      personnalTypeCode: values.personnalTypeCode,
      organizationCode: values.organizationCode,
    })
    personnelListFn({
      variables: {
        input: encryptInput({
          filters: {
            personnelCode: values.personnelCode,
            positionCode: values.positionCode,
            personnalTypeCode: values.personnalTypeCode,
            organizationCode: values.organizationCode,
          },
          pagination: { limit, page },
          sort: [
            {
              fieldSort: 'personnelId',
              sortType: 'DESC',
            },
          ],
        }),
      },
    })
  }
  const onChangePagination = (page) => setPage(page)

  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((er) => {
      const nameField = er.name[0] || ''
      const message = er.errors[0] || ''
      if (nameField === 'datePicker') setErrorField('datePickerMockUp', message)
    })
  }
  // onValuesChange
  const onValuesChange = () => {}

  const showConfirmDelete = (personnelCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteData({
          variables: {
            input: { personnelCode: encryptInput(personnelCode) },
          },
        })
      },
      onCancel() {
        getPersonnelList.refetch()
      },
    })
  }

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          getPersonnelList.refetch()
        },
      })
    }
  }, [dataDelete])

  useEffect(() => {
    if (errorDelete) {
      showAlertError(t(displayError(errorDelete)))
    }
  }, [errorDelete])

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: text,
      onOk() {},
    })
  }
  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }
  const callNavigate = (type_action, refCode) => {
    navigate('/administrator/personnel/information', {
      state: { type_action: type_action, refCode: refCode },
    })
  }

  const resetField = () => {
    form.resetFields()
    setOldFilter({})
    personnelListFn({
      variables: {
        input: encryptInput({
          filters: { ...oldFilter },
          pagination: { limit, page },
          sort: [
            {
              fieldSort: 'personnelId',
              sortType: 'DESC',
            },
          ],
        }),
      },
    })
  }

  const Responsive = {
    col1: {
      md: 7,
      lg: 6,
      xl: 4,
    },
    col2: {
      md: 17,
      lg: 18,
      xl: 20,
    },
  }
  const Responsive2 = {
    col1: {
      md: 8,
      lg: 8,
    },
    col2: {
      md: 9,
      lg: 9,
    },
    col3: {
      md: 7,
      lg: 7,
    },
  }

  return (
    <React.Fragment>
      {(getPersonnelList.loading || loadingDelete) && <SpinnersNew />}
      <CardNew topic={t('คัดกรองข้อมูล')} icon="Filter" toggledrop="true" buttontopright="true">
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
          name="personnelCardForm"
          layout="vertical"
        >
          <Row gutter={[12, 12]}>
            <Col {...Responesive.inputFilter}>
              <Form.Item
                name="personnelCode"
                label={<LabelNew type="tab-header-inactive">{t('รหัส/ชื่อบุคลากร')}</LabelNew>}
              >
                <InputNew placeholder={t('ระบุรายชื่อบุคลากร')} />
              </Form.Item>
            </Col>
            <Col {...Responesive.inputFilter}>
              <Form.Item
                name="organizationCode"
                label={<LabelNew type="tab-header-inactive">{t('หน่วยงาน')}</LabelNew>}
              >
                <SelectOrganization
                  placeholder={t('เลือกหน่วยงาน')}
                  formname="personnelCardForm"
                  handleChange={(e) =>
                    form.setFieldsValue({
                      organizationCode: e?.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
            <Col {...Responesive.inputFilter}>
              <Form.Item name="positionCode" label={<LabelNew type="tab-header-inactive">{t('ตำแหน่ง')}</LabelNew>}>
                <SelectPersonnelPositions
                  placeholder={t('เลือกตำแหน่ง')}
                  formname="personnelCardForm"
                  handleChange={(e) =>
                    form.setFieldsValue({
                      positionCode: e?.value,
                    })
                  }
                  // filters={{ organizationChecked: 2  , isActive: 1 }}
                />
              </Form.Item>
            </Col>
            <Col {...Responesive.inputFilter}>
              <Form.Item
                name="personnalTypeCode"
                label={<LabelNew type="tab-header-inactive">{t('ประเภทบุคลากร')}</LabelNew>}
              >
                <SelectPersonnelType
                  formname={'personnelCardForm'}
                  placeholder={t('เลือกประเภทบุคลากร')}
                  handleChange={(e) =>
                    form.setFieldsValue({
                      personnalTypeCode: e?.value,
                    })
                  }
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col {...Responesive.buttonFilter} align="middle">
              <Form.Item>
                <>
                  <Space size={[8, 8]} wrap>
                    <ButtonNew type="search" htmlType="submit">
                      {t('ค้นหา')}
                    </ButtonNew>
                    <ButtonNew type="clear" onClick={() => resetField()} htmlType="submit">
                      {t('ล้างค่า')}
                    </ButtonNew>
                  </Space>
                </>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CardNew>
      <ButtonNew
        type="plusTableBorderPrimary"
        roles={{ type: 'add', menu: menu }}
        onClick={() => {
          navigate('/administrator/personnel/information', { state: { type_action: 'add' } })
        }}
      >
        {t('เพิ่มข้อมูลบุคลากร')}
      </ButtonNew>
      {detail.map((item, key) => {
        return (
          <CardNew
            key={key}
            topic={MaskFullNameText(item.name) + ' ' + item.organizationNameTH}
            topiccolor="#2B4990"
            icon="User"
            toggledrop="false"
            buttontopright="true"
            menu={menu}
            editfnc={() => callNavigate('edit', item.refCode)}
            deletefnc={() => showConfirmDelete(item.refCode)}
          >
            <Row>
              <Col {...Responsive.col1} className="col-img">
                <Image
                  width={145}
                  preview={true}
                  src={process.env.REACT_APP_API_UPLOAD + '/fileImageProfilePreview/' + item.profilePicture}
                />
              </Col>
              <Col {...Responsive.col2} className="col-txts">
                <Row className="row-txt">
                  <Col {...Responsive2.col1} className="col-txt">
                    <LabelNew type="body">
                      <IconNew icon={'Mail'} className="icon-txt" />
                      <span className="list-txt">{item.email ? MaskEmail(item.email) : t('ไม่ระบุ')}</span>
                    </LabelNew>
                    <LabelNew type="body">
                      <IconNew icon={'User'} className="icon-txt" />
                      <span className="list-txt">
                        {item.age ? item.age : t('ไม่ระบุ')} {t('ปี')}
                      </span>
                    </LabelNew>
                  </Col>
                  <Col {...Responsive2.col2} className="col-txt">
                    <LabelNew type="body">
                      <IconNew icon={'User'} className="icon-txt" />
                      <span className="list-txt">
                        {t('ข้อมูลพื้นฐานบุคคล')}
                        <a
                          className="click-link"
                          onClick={() =>
                            navigate('/administrator/personnel/information', {
                              state: { type_action: 'view', refCode: item.refCode },
                            })
                          }
                        >
                          Click to view
                        </a>
                      </span>
                    </LabelNew>
                    <LabelNew type="body">
                      <IconNew icon={'BookOpen'} className="icon-txt" />
                      <span className="list-txt">
                        {t('ข้อมูลการศึกษา')}
                        <a
                          className="click-link"
                          onClick={() =>
                            navigate('/administrator/personnel/education', {
                              state: { type_action: 'view', refCode: item.refCode },
                            })
                          }
                        >
                          Click to view
                        </a>
                      </span>
                    </LabelNew>
                    <LabelNew type="body">
                      <IconNew icon={'TrendingUp'} className="icon-txt" />
                      <span className="list-txt">
                        {t('ข้อมูลการฝึกอบรม สัมมนา ประชุม')}
                        <a
                          className="click-link"
                          onClick={() =>
                            navigate('/administrator/personnel/training', {
                              state: { type_action: 'view', refCode: item.refCode },
                            })
                          }
                        >
                          Click to view
                        </a>
                      </span>
                    </LabelNew>
                    <LabelNew type="body">
                      <IconNew icon={'Award'} className="icon-txt" />
                      <span className="list-txt">
                        {t('ข้อมูลรางวัล ผลงาน')}
                        <a
                          className="click-link"
                          onClick={() =>
                            navigate('/administrator/personnel/reward', {
                              state: { type_action: 'view', refCode: item.refCode },
                            })
                          }
                        >
                          Click to view
                        </a>
                      </span>
                    </LabelNew>
                  </Col>
                  <Col {...Responsive2.col3} className="col-txt">
                    <LabelNew type="body">
                      <IconNew icon={'FileText'} className="icon-txt" />
                      <span className="list-txt">
                        {t('ข้อมูลใบประกอบวิชาชีพ')}
                        <a
                          className="click-link"
                          onClick={() =>
                            navigate('/administrator/personnel/certificate', {
                              state: { type_action: 'view', refCode: item.refCode },
                            })
                          }
                        >
                          Click to view
                        </a>
                      </span>
                    </LabelNew>
                  </Col>
                </Row>
              </Col>
            </Row>
          </CardNew>
        )
      })}
      <PaginationComp total={count} onChangePagination={onChangePagination} page={page} pageSize={10} />
    </React.Fragment>
  )
}

export default PersonnelList
