import { gql } from '@apollo/client'
export const MASTERCALENDARSSAVE = gql`
  mutation masterCalendarsSave($inputData: MastercalendarsSaveInput!) {
    masterCalendarsSave(inputData: $inputData) {
      calendarCode
    }
  }
`

export const MASTERCALENDARDELETE = gql`
  mutation masterCalendarDelete($input: MastercalendarsInput!) {
    masterCalendarDelete(input: $input)
  }
`
