import { gql } from '@apollo/client'
export const SAVE_AUDIT_PROJECT_GENERAL = gql`
  mutation auditProjectsGeneralSave($input: AddAuditProjectsGeneralInput!) {
    auditProjectsGeneralSave(data: $input) {
      message
      detail
    }
  }
`

export const SAVE_AUDIT_PROJECT_TEAM = gql`
  mutation auditProjectsTeamSave($input: AddAuditProjectsTeamInput!) {
    auditProjectsTeamSave(data: $input) {
      message
      detail
    }
  }
`

export const AUDITPROJECT_FILE_SAVE = gql`
  mutation auditProjectFileSave($input: AuditProjectFileInput!) {
    auditProjectFileSave(input: $input) {
      message
      detail
    }
  }
`

export const AUDITPROJECT_DELETE = gql`
  mutation auditProjectsDelete($input: AuditProjectsDeleteInput!) {
    auditProjectsDelete(data: $input) {
      projectCode
    }
  }
`

export const AUDITPROJECT_CANCEL = gql`
  mutation auditProjectsCancel($input: AuditProjectCancelInput!) {
    auditProjectsCancel(data: $input) {
      projectCode
    }
  }
`
