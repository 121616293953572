import { Trans } from 'react-i18next'

export const breadcrumbList = [
  { label: <Trans>งานปฏิบัติการตรวจสอบ</Trans>, path: '/auditoperations' },
  { label: <Trans>ประเมินการปฏิบัติงาน</Trans> },
]

export const breadcrumbDetails = [
  { label: <Trans>งานปฏิบัติการตรวจสอบ</Trans>, path: '/auditoperations' },
  { label: <Trans>ประเมินการปฏิบัติงาน</Trans>, path: '/auditoperations/assessmentaudit' },
  { label: <Trans></Trans>, path: '/auditoperations/assessmentaudit/assessmentauditdetails' },
]
