import { Col, Row } from 'antd'
import React from 'react'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { CardNew } from '../../../components/card/Card'
import { TabNew } from '../../../components/tab/Tab'
import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { useTranslation } from 'react-i18next'
import SoiAuditCommitteeTabIssue from './tab/SoiAuditCommitteeTabIssue'
import SoiAuditCommitteeTabCauseOfIssue from './tab/SoiAuditCommitteeTabCauseOfIssue'
import SoiAuditCommitteeTabAuditSuggest from './tab/SoiAuditCommitteeTabAuditSuggest'
import SoiAuditCommitteeTabDetailAcr from './tab/SoiAuditCommiteeTabDetailAcr'
import { ButtonNew } from '../../../components/button/Button'

const SoiAuditCommitteeView = (props) => {
  const { t } = useTranslation()
  const mainPage = '/auditoperations/soiauditcommittee'
  const { state } = useLocation()
  const navigate = useNavigate()

  if (!state) {
    return <Navigate to={mainPage} />
  }
  const arrCrumb = [
    { label: t('งานปฎิบัติการตรวจสอบ'), path: '/auditoperations/acresolution' },
    { label: t('บันทึกสรุปประเด็นคณะกรรมการตรวจสอบ') },
  ]
  const tabContent = [
    {
      key: '1',
      name: t('สรุปมติคณะกรรมการตรวจสอบ'),
      disabled: false,
      content: () => <SoiAuditCommitteeTabDetailAcr menu={props.menu} />,
    },
    {
      key: '2',
      name: t('ประเด็นการตรวจสอบ'),
      disabled: false,
      content: () => <SoiAuditCommitteeTabIssue menu={props.menu} />,
    },
    {
      key: '3',
      name: t('สาเหตุ'),
      disabled: false,
      content: () => <SoiAuditCommitteeTabCauseOfIssue menu={props.menu} />,
    },
    {
      key: '4',
      name: t('ข้อเสนอแนะ'),
      disabled: false,
      content: () => <SoiAuditCommitteeTabAuditSuggest menu={props.menu} />,
    },
  ]

  return (
    <>
      <Row>
        <Col md={24}>
          <BreadcrumbNew data={arrCrumb} title={t('บันทึกสรุปประเด็นคณะกรรมการตรวจสอบ')} menu={props.menu} />
          <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="List">
            <TabNew tab={tabContent} defaultActiveKey={1} />
            <Row gutter={[12, 20]} justify="center" style={{ marginTop: '15px' }}>
              <Col>
                <ButtonNew type="back" onClick={() => navigate(mainPage)}>
                  {t('ยกเลิก')}
                </ButtonNew>
              </Col>
            </Row>
          </CardNew>
        </Col>
      </Row>
    </>
  )
}

export default SoiAuditCommitteeView
