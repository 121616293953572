import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { Trans } from 'react-i18next'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

export const Columns = (dataLists, setDataLists, type_action, state) => {
  const navigate = useNavigate()

  return [
    {
      title: <Trans>ลำดับ</Trans>,
      dataIndex: 'key',
      // sorter: true,
      width: '5%',
      className: 'text-nowrap',
      render: (item, record, index) => {
        return index + 1
      },
    },
    {
      title: <Trans>รหัสบุคลากร</Trans>,
      dataIndex: 'personnelCode',
      // sorter: true,
      width: '15%',
      className: 'text-nowrap',
    },
    {
      title: <Trans>ชื่อ-นามสกุล</Trans>,
      dataIndex: 'personnelName',
      // sorter: true,
      width: '30%',
      className: 'text-nowrap',
    },
    {
      title: <Trans>หน่วยงาน</Trans>,
      dataIndex: 'organization',
      // sorter: true,
      width: '30%',
      className: 'text-nowrap',
    },
    {
      title: <Trans>ตำแหน่ง</Trans>,
      dataIndex: 'position',
      // sorter: true,
      width: '30%',
      className: 'text-nowrap',
    },
    {
      title: <Trans></Trans>,
      dataIndex: 'tools',
      width: '10%',
      align: 'center',
      render: (item, record) => {
        return type_action === 'edit' ? (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup
              menu={'AO05'}
              icons={[
                {
                  type: 'calendar',
                  onClick: () =>
                    navigate(`/auditoperations/adhocjob/tab/responsible/personnels/calendar`, {
                      state: {
                        ...state,
                        personnelCode: record.personnelCode,
                        personnelName: record.personnelName,
                      },
                    }),
                },
                {
                  type: 'delete',
                  onClick: () => {
                    if (dataLists.length > 1) {
                      setDataLists([..._.filter(dataLists, (e) => e.key !== record.key)])
                    } else {
                      setDataLists([])
                    }
                  },
                },
              ]}
            />
          </div>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup
              menu={'AO05'}
              icons={[
                {
                  type: 'calendar',
                  onClick: () =>
                    navigate(`/auditoperations/adhocjob/tab/responsible/personnels/calendar`, {
                      state: {
                        ...state,
                        personnelCode: record.personnelCode,
                        personnelName: record.personnelName,
                      },
                    }),
                },
              ]}
            />
          </div>
        )
      },
    },
  ]
}
