import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_EDUCATION_LEVELS } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectEducationLevels = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { educationLevelId, educationLevelCode, educationLevelTH, educationLevelEN, isActive } = filters || {}

  const [isData, isSetData] = useState([])

  const [getMasterEducationLevelsFn, getMasterEducationLevels] = useLazyQuery(GET_MASTER_EDUCATION_LEVELS)

  useEffect(() => {
    getMasterEducationLevelsFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [educationLevelId, educationLevelCode, educationLevelTH, educationLevelEN, isActive])

  useEffect(() => {
    if (getMasterEducationLevels.data) {
      isSetData(
        _.map(getMasterEducationLevels?.data?.getMasterEducationLevels, (item) => {
          return {
            label: displayText(item?.educationLevelTH, item?.educationLevelEN),
            value: item?.educationLevelCode,
            source: item,
          }
        }),
      )
    }
  }, [getMasterEducationLevels.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterEducationLevels.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectEducationLevels
