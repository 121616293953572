import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { CardNew } from '../../../../../components/card/Card'
import { useTranslation } from 'react-i18next'
import SelectOrganizationLevel from '../../../../../components/inputfromapi/selectorganizationlevel/SelectOrganizationLevel'
import { LabelNew } from '../../../../../components/label/Label'
import { ButtonNew } from '../../../../../components/button/Button'
import { Datatable } from '../../../../../components/datatable/Datatable.jsx'
import { InputNew as Input } from '../../../../../components/input/Input'
import { GET_AUDITPROJECT_ORGANIZATIONS_PAGINATION, GET_AUDITPROJECT_ORGANIZATIONS_ALL } from '../../graphql/Query'
import { Checkbox } from '../../../../../components/checkbox/Checkbox'
import { useLazyQuery } from '@apollo/client'
import * as _ from 'lodash'
import { encryptInput } from '../../../../../utilitys/crypto'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError } from '../../../../../utilitys/helper'
const ModalGeneralOrganization = (props) => {
  const { t } = useTranslation()
  const [searchStatus, setSearchStatus] = useState(false)

  const [form] = Form.useForm()
  const onFinish = () => {}
  const onFinishFailed = () => {}
  const [dataLists, setDataLists] = useState([])
  const [auditProjectOrganizationsAll, setAuditProjectOrganizationsAll] = useState([])

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(5)
  const [count, setCount] = useState(0)
  const [getAuditProjectOrganizationsAllFn, getAuditProjectOrganizationsAll] = useLazyQuery(
    GET_AUDITPROJECT_ORGANIZATIONS_ALL,
  )
  useEffect(() => {
    if (getAuditProjectOrganizationsAll?.data && props.modalOrganizations) {
      let response = getAuditProjectOrganizationsAll?.data?.getAuditProjectOrganizationsAll
      if (response) {
        setAuditProjectOrganizationsAll(
          response.map((item) => {
            return {
              organizationCode: item.organizationCode,
              organizationName:
                localStorage.getItem('lang') === 'th' ? item.organizationNameTH : item.organizationNameEN,
              organizationLevelName:
                localStorage.getItem('lang') === 'th' ? item.organizationLevelNameTH : item.organizationLevelNameEN,
              organizationNameTH: item.organizationNameTH,
              organizationNameEN: item.organizationNameEN,
              personnelCode: item.personnelCode,
              personnels:
                localStorage.getItem('lang') === 'th'
                  ? item.namePrefixTH + item.firstNameTH + ' ' + item.lastNameTH
                  : item.namePrefixEN + item.firstNameEN + ' ' + item.lastNameEN,
            }
          }),
        )
      }
    }
    if (getAuditProjectOrganizationsAll.error) {
      showAlertLoadDataError(displayError(getAuditProjectOrganizationsAll.error))
    }
  }, [getAuditProjectOrganizationsAll.data])
  const [getAuditProjectOrganizationsPagenationFn, getAuditProjectOrganizationsPagenation] = useLazyQuery(
    GET_AUDITPROJECT_ORGANIZATIONS_PAGINATION,
  )
  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const callApiSearch = () => {
    getAuditProjectOrganizationsPagenationFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          filters: {
            lang: localStorage.getItem('lang'),
            organizationLevelCode: form.getFieldValue('organizationLevelCode')
              ? form.getFieldValue('organizationLevelCode')
              : null,
            organizationInfor: form.getFieldValue('organizationInfor') ? form.getFieldValue('organizationInfor') : null,
          },
          pagination: { limit, page },
          sort: [
            {
              fieldSort: 'organizationId',
              sortType: 'DESC',
            },
          ],
        }),
      },
    })
  }
  const callApiSearchOrgAll = () => {
    getAuditProjectOrganizationsAllFn({
      fetchPolicy: 'no-cache',
      variables: {
        input: encryptInput({
          lang: localStorage.getItem('lang'),
          organizationLevelCode: form.getFieldValue('organizationLevelCode')
            ? form.getFieldValue('organizationLevelCode')
            : null,
          organizationInfor: form.getFieldValue('organizationInfor') ? form.getFieldValue('organizationInfor') : null,
        }),
      },
    })
  }
  const columns = [
    {
      title: () => (
        <div style={{ textAlign: 'center', display: 'flex' }}>
          <Checkbox
            checked={
              props.organizationsBuffer.length !== 0 &&
              auditProjectOrganizationsAll.length !== 0 &&
              props.organizationsBuffer.length === auditProjectOrganizationsAll.length
                ? true
                : false
            }
            onChange={(e) => {
              if (e.target.checked) {
                props.setOrganizationsBuffer(auditProjectOrganizationsAll)
              } else {
                props.setOrganizationsBuffer([])
              }
            }}
          />
          <span style={{ marginLeft: '10px' }}>{t('เลือก')}</span>
        </div>
      ),
      dataIndex: 'select',
      width: '10%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('รหัสหน่วยงาน')}</div>,
      dataIndex: 'organizationCode',
      width: '20%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('หน่วยงาน')}</div>,
      dataIndex: 'organizationName',
      width: '25%',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('ระดับหน่วยงาน')}</div>,
      dataIndex: 'organizationLevelName',
      width: '20%',
    },
    {
      title: () => <div style={{ textAlign: 'center' }}>{t('ผู้รับผิดชอบ')}</div>,
      dataIndex: 'personnels',
      width: '25%',
    },
  ]
  useEffect(() => {
    callApiSearch()
  }, [page, limit])

  useEffect(() => {
    if (searchStatus) {
      callApiSearch()
      callApiSearchOrgAll()
      setSearchStatus(false)
    }
  }, [searchStatus])
  useEffect(() => {
    if (getAuditProjectOrganizationsPagenation?.data && props.modalOrganizations) {
      let response = getAuditProjectOrganizationsPagenation?.data?.getAuditProjectOrganizationsPagenation
      setCount(response?.count)

      setDataLists(
        _.map(response?.result, (data, i) => {
          return {
            select: (
              <>
                <Checkbox
                  checked={
                    props.organizationsBuffer.find((item) => {
                      return item.organizationCode === data.organizationCode
                    })
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      props.setOrganizationsBuffer(
                        props.organizationsBuffer.concat([
                          {
                            organizationCode: data.organizationCode,
                            organizationName:
                              localStorage.getItem('lang') === 'th' ? data.organizationNameTH : data.organizationNameEN,
                            organizationLevelName:
                              localStorage.getItem('lang') === 'th'
                                ? data.organizationLevelNameTH
                                : data.organizationLevelNameEN,
                            organizationNameTH: data.organizationNameTH,
                            organizationNameEN: data.organizationNameEN,
                            personnelCode: data.personnelCode,
                            personnels:
                              localStorage.getItem('lang') === 'th'
                                ? data.namePrefixTH + data.firstNameTH + ' ' + data.lastNameTH
                                : data.namePrefixEN + data.firstNameEN + ' ' + data.lastNameEN,
                          },
                        ]),
                      )
                    } else {
                      props.setOrganizationsBuffer(
                        props.organizationsBuffer.filter((item) => {
                          return item.organizationCode !== data.organizationCode
                        }),
                      )
                    }
                  }}
                />
              </>
            ),
            key: i,
            organizationCode: data.organizationCode,
            organizationName: localStorage.getItem('lang') === 'th' ? data.organizationNameTH : data.organizationNameEN,
            organizationLevelName:
              localStorage.getItem('lang') === 'th' ? data.organizationLevelNameTH : data.organizationLevelNameEN,
            personnels:
              localStorage.getItem('lang') === 'th'
                ? data.namePrefixTH + data.firstNameTH + ' ' + data.lastNameTH
                : data.namePrefixEN + data.firstNameEN + ' ' + data.lastNameEN,
          }
        }),
      )
    }
    if (getAuditProjectOrganizationsPagenation.error) {
      showAlertLoadDataError(displayError(getAuditProjectOrganizationsPagenation.error))
    }
  }, [props.modalOrganizations, props.organizationsBuffer, getAuditProjectOrganizationsPagenation?.data])
  useEffect(() => {
    if (props.modalOrganizations) {
      props.setOrganizationsBuffer(props.organizations)
      callApiSearchOrgAll()
    }
  }, [props.modalOrganizations])

  return (
    <>
      {(getAuditProjectOrganizationsPagenation.loading || getAuditProjectOrganizationsAll.loading) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <CardNew topic={t('Filters')} toggledrop="false">
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              name="filterOrganization"
              layout="vertical"
            >
              <Row gutter={[12, 12]}>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="organizationLevelCode"
                    label={<LabelNew type="tab-header-inactive">{t('ระดับหน่วยงาน')}</LabelNew>}
                  >
                    <SelectOrganizationLevel
                      formname="filterOrganization"
                      placeholder={t('เลือกระดับหน่วยงาน')}
                      handleChange={() => {}}
                    />
                  </Form.Item>
                </Col>
                <Col sm={24} md={12} lg={8}>
                  <Form.Item
                    name="organizationInfor"
                    label={<LabelNew type="tab-header-inactive">{t('รหัสหรือชื่อหน่วยงาน')}</LabelNew>}
                    rules={[
                      {
                        max: 200,
                        message: t('กรุณาระบุรหัสหรือชื่อหน่วยงานน้อยกว่าหรือเท่ากับ 200 ตัวอักษร'),
                      },
                    ]}
                  >
                    <Input maxLength={200} placeholder={t('ระบุรหัสหรือชื่อหน่วยงาน')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew
                    type="search"
                    onClick={() => {
                      if (
                        form.getFieldsError().filter((item) => {
                          return item.errors.length !== 0
                        }).length === 0
                      ) {
                        setSearchStatus(true)
                      }
                    }}
                    htmlType="button"
                  >
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew type="clear" onClick={() => form.resetFields()}>
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </CardNew>
        </Col>
      </Row>
      <Row>
        <Col md={24}>
          <CardNew topic={''} toggledrop="false">
            <Datatable
              columns={columns}
              data={dataLists}
              handleTableChange={() => {}}
              paginationCustom={true}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
              total={count}
              enableScroll={{ x: true, y: true }}
              scroll={{ x: '100%', y: false }}
              pageSize={limit}
              page={page}
              btnAdd={<></>}
            />
          </CardNew>
        </Col>
      </Row>
    </>
    // okButtonProps={typeForm === 'view' ? { style: { display: 'none' } } : {}}
  )
}

export default ModalGeneralOrganization
