import { gql } from '@apollo/client'

export const GET_CONTROLNATURE_PAGINATION = gql`
  query getMasterControlNaturePagination($input: ControlNaturePaginationInput!) {
    getMasterControlNaturePagination(input: $input) {
      result {
        controlNatureCode
        controlNatureNameTH
        controlNatureNameEN
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_CONTROLNATURE_BY_CODE = gql`
  query getMasterControlNatureByCode($controlNatureCode: String!) {
    getMasterControlNatureByCode(controlNatureCode: $controlNatureCode) {
      controlNatureCode
      controlNatureNameTH
      controlNatureNameEN
      isActive
    }
  }
`
