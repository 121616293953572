import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import RiskProcessListForm from './RiskProcessListForm'
import { Columns } from './utils/RiskProcessListColumn'
import { GET_LIST_RISK_PROCESS_PAGINATION } from './graphql/Query'
import { DELETE_RISK_PROCESS } from './graphql/Mutation'

import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew as ButtonNew } from '../../../components/button/Button'
import { CardNew as Card } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { LabelNew as Label } from '../../../components/label/Label'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput } from '../../../utilitys/crypto'
import { useLanguage } from '../../../hooks/useLanguage'
import { handleSort } from '../../../utilitys/pagination'
import { displayError } from '../../../utilitys/helper'

const Responsive = {
  md: 24,
  lg: 24,
}

const RiskProcessList = ({ menu }) => {
  const [isLang] = useLanguage()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const arrCrumb = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/riskprocess' },
    { label: t('จัดการข้อมูลกลุ่มความเสี่ยงกระบวนการ') },
  ]

  const [form_visible, setform_visible] = useState(false)

  const [dataRow, setdataRow] = useState([])
  const [paginate, setPaginate] = useState({ page: 1, limit: 10 })
  const [sort, setsort] = useState([{ fieldSort: 'rateFacGroupCode', sortType: 'ASC' }])

  const [
    filters,
    //  setfilters
  ] = useState({})
  const [count, setcount] = useState(1)

  useEffect(() => {
    if (sort && paginate) fncGetList()
  }, [paginate, sort])

  const [callList, { loading: loadingList, error: errorList, data: dataList }] = useLazyQuery(
    GET_LIST_RISK_PROCESS_PAGINATION,
  )

  useEffect(() => {
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorList)),
      })
    }
  }, [errorList])

  useEffect(() => {
    if (dataList) {
      let { result, count } = dataList.getListRiskProcessPagination

      let dataTemp = result.map((item, key) => {
        let rateFacGroupName = isLang === 'en' ? item.rateFacGroupNameEN : item.rateFacGroupNameTH

        return {
          ...item,
          key: key + 1,
          rateFacGroupName: `${item.rateFacGroupCode} : ${rateFacGroupName}`,
        }
      })
      setdataRow(dataTemp)
      setcount(count)
    }
  }, [dataList])

  const [callDelete, { loading: loadingDelete, error: errorDelete, data: dataDelete }] =
    useMutation(DELETE_RISK_PROCESS)

  useEffect(() => {
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorDelete)),
      })
    }
  }, [errorDelete])

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบข้อมูลสำเร็จ'),
        onOk() {
          fncGetList()
        },
      })
    }
  }, [dataDelete])

  const fncGetList = async () => {
    const dataInput = {
      sort: sort,
      filters: filters,
      pagination: {
        page: paginate.page,
        limit: paginate.limit,
      },
    }
    await callList({
      variables: { input: encryptInput(dataInput) },
    })
  }

  const handleFormOnClose = () => {
    setform_visible(false)
  }

  const handleFormOnSuccess = (data) => {
    setform_visible(false)
    navigate('/administrator/riskprocess/form', {
      state: { type_action: 'edit', refCode: data.rateFacGroupCode },
    })
  }

  const fncOnpressEdit = (data) => {
    navigate('/administrator/riskprocess/form', {
      state: { type_action: 'edit', refCode: data.rateFacGroupCode },
    })
  }

  const fncOnpressView = (data) => {
    navigate('/administrator/riskprocess/form', {
      state: { type_action: 'view', refCode: data.rateFacGroupCode },
    })
  }

  const fncOnpressDelete = (data) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ?'),
      onOk() {
        const dataInput = {
          rateFacGroupCode: data.rateFacGroupCode,
        }
        callDelete({
          variables: { input: encryptInput(dataInput) },
        })
      },
      onCancel() {},
    })
  }

  return (
    <React.Fragment>
      <Breadcrumb data={arrCrumb} title={t('จัดการข้อมูลกลุ่มความเสี่ยงกระบวนการ')} />
      <Card topic={<Label type="sub-header">{t('รายการกลุ่มความเสี่ยงกระบวนการ')}</Label>} icon="List">
        <Row>
          <Col {...Responsive}>
            <Datatable
              columns={Columns({ fncOnpressView, fncOnpressEdit, fncOnpressDelete, menu })}
              data={dataRow}
              total={count}
              searchCustom={true}
              pageSize={paginate.limit}
              page={paginate.page}
              isLoading={loadingList || loadingDelete}
              paginationCustom={true}
              scroll={{ x: 768 }}
              onChangePagination={({ page, pageSize }) => {
                setPaginate({ page, limit: pageSize })
              }}
              handleTableChange={(e) => {
                const reNewField = [
                  {
                    rateFacGroupName: 'rateFacGroupCode',
                  },
                  { isActive: 'isActive' },
                ]

                setsort(handleSort(e?.sorter, reNewField))
              }}
              btnAdd={
                <ButtonNew
                  onClick={() => {
                    setform_visible(true)
                  }}
                  type="plusTableBorderPrimary"
                  roles={{ type: 'add', menu: menu }}
                >
                  {t('เพิ่มกลุ่มความเสี่ยงกระบวนการ')}
                </ButtonNew>
              }
            />
          </Col>
        </Row>
      </Card>
      <RiskProcessListForm visible={form_visible} onClose={handleFormOnClose} onSuccess={handleFormOnSuccess} />
    </React.Fragment>
  )
}
export default RiskProcessList
