import styled from 'styled-components'

import { colors } from '../../../../configs/styles.config'

export const RowStyle = styled.div`
  .table-row-color {
    color: ${colors.error};
  }
`

// .table-row-color {
//   background-color: ${colors.error};
// }
