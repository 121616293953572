import { gql } from '@apollo/client'

export const GET_SELECT_STEP_TYPES = gql`
  query GetSelectStepTypes($input: SelectStepTypesInput!) {
    getSelectStepTypes(input: $input) {
      stepTypeId
      stepTypeCode
      stepTypeNameTH
      stepTypeNameEN
      isActive
    }
  }
`
