import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row, Space } from 'antd'
import { LabelNew } from '../../../../components/label/Label'
import { InputNew, InputNumberNew } from '../../../../components/input/Input'
import { TextAreaNew } from '../../../../components/textarea/TextArea'
import { Datepicker } from '../../../../components/datepicker/Datepicker'
import { Timepicker } from '../../../../components/timepicker/Timepicker'
import { RadioGroup, Radio } from '../../../../components/radio/Radio'
import { UploadNew } from '../../../../components/upload/Upload'
import { validateURL, displayText } from '../../../../utilitys/helper'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { Datatable } from '../../../../components/datatable/Datatable'
import { Navigate, useLocation } from 'react-router-dom'
import SelectPersonnels from '../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import SelectOrganization from '../../../../components/inputfromapi/selectorganization/SelectOrganization'
import { SelectAuditProjectsClosed } from '../../../../components/inputfromapi/selectauditprojects/SelectAuditProjects'
import { encryptInput } from '../../../../utilitys/crypto'
import { GET_AUDIT_RESOLUTION_AC_BY_ID } from '../graphql/Query'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { downloadFile } from '../../../../utilitys/files'
import { useLazyQuery } from '@apollo/client/react/hooks'
import { v4 } from 'uuid'

const SoiAuditCommitteeTabDetailAcr = (props) => {
  const formName = 'acreSolutionForm'
  const mainPage = '/auditoperations/acresolution'
  const { t } = useTranslation()
  const { state } = useLocation()
  if (!state) {
    return <Navigate to={mainPage} />
  }
  const [form] = Form.useForm()
  const { action } = state
  const { menu } = props
  const tableFileResponsive = {
    md: 24,
  }

  const [getData, data] = useLazyQuery(GET_AUDIT_RESOLUTION_AC_BY_ID)

  useEffect(() => {
    if (action === 'view' || action === 'edit') {
      getData({
        variables: {
          agendaId: encryptInput(state.agendaId),
        },
      }).then((result) => {
        const data = result?.data.getAuditResolutionACById
        let files = []
        if (data.fileDetail) {
          const fileDetail = JSON.parse(data.fileDetail)
          files = fileDetail.map((x) => {
            const { filename, links, tools } = fileDetail.find((y) => y.fileId === x.fileId)
            return {
              key: v4(),
              fileId: x.fileId,
              filename: filename,
              name: tools?.name,
              originalname: tools?.originalname,
              links: links,
            }
          })
        }

        form.setFieldsValue({
          agendaName: displayText(data.agendaNameTH, data.agendaNameEN),
          agendaSubName: displayText(data.agendaSubNameTH, data.agendaSubNameEN),
          projectCode: data.projectCode,
          organizationCode: data.organizationCode,
          organizationName: displayText(data.organization.organizationNameTH, data.organization.organizationNameEN),
          creators: data.auditResolutionOrganizations?.map((item) => {
            return item.organizationCode
          }),
          personnelPresentCode: data.auditResolutionPersonnels?.reduce((filtered, item) => {
            if (item.personnelType === 2) {
              filtered.push(item.personnelCode)
            }

            return filtered
          }, []),
          personnelReviewCode: data.personnelReviewCode,
          meetingTime: data.meetingTime,
          meetingDate: data.meetingDate,
          meetingTimes: moment(data.meetingTimes).format('HH:mm:00'),
          location: displayText(data.locationTH, data.locationEN),
          resolutionAC: displayText(data.resolutionACTH, data.resolutionACEN),
          auditor: data.auditor,
          isActive: data.isActive,
          createdBy: displayText(
            data.systemUsers?.personnels?.firstNameTH + ' ' + data.systemUsers?.personnels?.lastNameTH,
            data.systemUsers?.personnels?.firstNameEN + ' ' + data.systemUsers?.personnels?.lastNameEN,
          ),
        })

        setfileData(files)
      })
    }
  }, [])

  const [fileData, setfileData] = useState([])

  const onUpload = (record, value, index) => {
    const data = [...fileData]
    data[index].fileId = value.uid
    data[index].tools = { originalname: value.originalname, name: value.name }

    setfileData([...data])
    // setIdFile(value.uid)
    form.setFieldsValue({ [record.key]: { fileId: value.uid } })
  }

  const tableFileColumn = [
    {
      title: t('ชื่อเอกสาร'),
      dataIndex: 'filename',
      width: '30%',
      render: (item, record) => {
        return action === 'view' ? (
          <>{item}</>
        ) : (
          <div>
            <Form.Item
              name={[record.key, 'filename']}
              initialValue={item}
              rules={[{ required: true, message: t('กรุณากรอกชื่อเอกสาร') }]}
            >
              <InputNew
                placeholder={t('ชื่อเอกสาร')}
                disabled={true}
                onChange={(e) => {
                  form.setFieldsValue({ [record.key]: { filename: e.target.value } })
                }}
              />
            </Form.Item>
          </div>
        )
      },
    },
    {
      title: t('ลิงค์'),
      dataIndex: 'links',
      width: '40%',
      render: (item, record) => {
        if (action === 'view') {
          if (item) {
            return (
              <a href={validateURL(item)} target="_blank" rel="noreferrer" style={{ color: '#2b4990' }}>
                <u>{item}</u>
              </a>
            )
          } else {
            return '-'
          }
        } else {
          return (
            <div>
              <Form.Item name={[record.key, 'links']} initialValue={item}>
                <InputNew
                  placeholder={t('links')}
                  disabled={true}
                  onChange={(e) => {
                    form.setFieldsValue({ [record.key]: { links: e.target.value } })
                  }}
                />
              </Form.Item>
            </div>
          )
        }
      },
    },
    {
      title: t('เอกสารอ้างอิง'),
      dataIndex: 'fileId',
      width: '25%',
      align: 'left',
      render: (item, record, index) => {
        return !item ? (
          '-'
        ) : (
          <div>
            <Form.Item
              name={[record.key, 'fileId']}
              initialValue={item}
              rules={[{ required: true, message: t('กรุณาเลือกเอกสาร') }]}
            >
              <UploadNew
                key={index}
                setIdFile={() => {}}
                idFile={[record.fileId] || []}
                typeFile="file"
                inline={true}
                multiRow={true}
                setFileData={(e) => {
                  onUpload(record, e, index)
                }}
                actionType={'view'}
                defaults={[record.fileId] || []}
                disabled={true}
              />
            </Form.Item>
          </div>
        )
      },
    },
    {
      title: '',
      //   dataIndex: 'tools',
      width: '5%',
      render: (record) => {
        let listButton = []
        const btnDownlaod = {
          type: 'download',
          onClick: () => {
            record && downloadFile(record.originalname, record.name, () => {})
          },
          disabled: record.originalname && record.name ? false : true,
        }

        if (action === 'view') {
          if (record) {
            listButton = [...listButton, btnDownlaod]
          }
        } else {
          if (record) {
            listButton = [...listButton, btnDownlaod]
          }
          // listButton = [...listButton, btnDelete]
        }

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} icons={listButton} />
          </div>
        )
      },
    },
  ]

  return (
    <>
      {data.loading && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <LabelNew type="body-header">{t('รายละเอียดบันทึกมติกรรมการตรวจสอบ')}</LabelNew>
          <Form
            name={formName}
            form={form}
            autoComplete="off"
            labelAlign="left"
            layout="horizontal"
            onFinish={() => {}}
            labelCol={{
              sm: 24,
              md: 4,
            }}
            wrapperCol={{
              sm: 24,
              md: 20,
            }}
          >
            <Row>
              <Col offset={2} md={20}>
                <Form.Item
                  name="agendaName"
                  label={<LabelNew type="tab-header-inactive">{t('วาระ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุวาระ') }]}
                >
                  <InputNew maxLength={200} placeholder={t('กรอกหัวเรื่องมติ')} disabled={true} />
                </Form.Item>
                <Form.Item
                  name="agendaSubName"
                  label={<LabelNew type="tab-header-inactive">{t('วาระย่อย')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุวาระย่อย') }]}
                >
                  <InputNew maxLength={200} placeholder={t('กรอกหัวเรื่องมติ')} disabled={true} />
                </Form.Item>
                <Form.Item
                  name="projectCode"
                  label={<LabelNew type="tab-header-inactive">{t('โครงการ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุโครงการ') }]}
                >
                  <SelectAuditProjectsClosed
                    placeholder={t('เลือกโครงการ')}
                    formname={formName}
                    disabled={true}
                    handleChange={(e) => {
                      form.setFieldsValue({
                        projectCode: e?.value,
                        organizationName: displayText(e?.source.organizationNameTH, e?.source.organizationNameEN),
                        organizationCode: e?.source.organizationCode,
                      })
                    }}
                  />
                </Form.Item>
                <Form.Item hidden={true} name="organizationCode">
                  <InputNew />
                </Form.Item>
                <Form.Item
                  name="organizationName"
                  label={<LabelNew type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุหน่วยงานที่รับผิดชอบ') }]}
                >
                  <InputNew maxLength={200} placeholder={t('ระบุหน่วยงานที่รับผิดชอบ')} disabled={true} />
                </Form.Item>
                <Form.Item
                  name="creators"
                  label={<LabelNew type="tab-header-inactive">{t('จัดทำเอกสารโดย')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุผู้จัดทำเอกสาร') }]}
                >
                  <SelectOrganization
                    placeholder={t('เลือกหน่วยงานที่จัดทำเอกสาร')}
                    formname={formName}
                    multiple={true}
                    optionLabelProp="label"
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  name="personnelPresentCode"
                  label={<LabelNew type="tab-header-inactive">{t('นำเสนอโดย')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุผู้นำเสนอ') }]}
                >
                  <SelectPersonnels
                    placeholder={t('เลือกผู้นำเสนอ')}
                    multiple={true}
                    formname={formName}
                    filters={{ personnaloperationCode: 10 }}
                    disabled={true}
                    handleChange={(e) => form.setFieldsValue({ personnelPresentCode: e?.value })}
                  />
                </Form.Item>
                <Form.Item
                  name="meetingTime"
                  label={<LabelNew type="tab-header-inactive">{t('มติการตรวจสอบ ครั้งที่')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุครั้งที่ตรวจสอบ') }]}
                >
                  <InputNumberNew maxLength={200} placeholder={t('กรอกมติตรวจสอบครั้งที่')} disabled={true} />
                </Form.Item>
                <Form.Item
                  name="location"
                  label={<LabelNew type="tab-header-inactive">{t('สถานที่ตรวจสอบ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุสถานที่ตรวจสอบ') }]}
                >
                  <TextAreaNew placeholder={t('กรอกสถานที่ประชุม')} disabled={true} />
                </Form.Item>
                <Form.Item
                  name="resolutionAC"
                  label={<LabelNew type="tab-header-inactive">{t('มติที่ประชุม')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุมติที่ประชุม') }]}
                >
                  <TextAreaNew placeholder={t('กรอกมติที่ประชุม')} disabled={true} />
                </Form.Item>
                <Form.Item
                  name="meetingDate"
                  label={<LabelNew type="tab-header-inactive">{t('วันที่ประชุม')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุวันที่ประชุม') }]}
                >
                  <Datepicker
                    setValueDateFn={async (data) => {
                      form.setFieldsValue({ meetingDate: await data })
                    }}
                    onChange={() => {}}
                    disabled={true}
                    onClear={() => {
                      form.setFieldsValue({ meetingDate: null })
                    }}
                    placeholder={t('เลือกวันที่ประชุม')}
                  />
                </Form.Item>
                <Form.Item
                  name="meetingTimes"
                  label={<LabelNew type="tab-header-inactive">{t('เวลาที่ประชุม')}</LabelNew>}
                  rules={[
                    {
                      required: true,
                      message: t('กรุณาระบุเวลาที่ประชุม'),
                    },
                  ]}
                >
                  <Timepicker
                    disabled={true}
                    form={form}
                    onChangeTime={(time) => {
                      if (time) {
                        form.setFieldsValue({ meetingTimes: moment(time).format('HH:mm:00') })
                        // setTimeStart(moment(time).format('YYYY-MM-DD HH:mm:00'))
                      } else {
                        form.setFieldsValue({ meetingTimes: null })
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item hidden={true} name="personnelReviewCode">
                  <InputNew />
                </Form.Item>
                <Form.Item name="createdBy" label={<LabelNew type="tab-header-inactive">{t('ผู้บันทึก')}</LabelNew>}>
                  <InputNew maxLength={200} placeholder={t('')} disabled={true} />
                </Form.Item>
                <Form.Item
                  name="auditor"
                  label={<LabelNew type="tab-header-inactive">{t('ผู้ตรวจ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุผู้ตรวจ') }]}
                >
                  <InputNew maxLength={200} placeholder={t('กรอกชื่อผู้ตรวจ')} disabled={true} />
                </Form.Item>
                <Form.Item
                  label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                  name="isActive"
                  initialValue={1}
                >
                  <RadioGroup disabled={true}>
                    <Space size={[8, 8]} wrap>
                      <Radio value={1}>{<LabelNew type="tab-header-inactive">{t('ใช้งาน')}</LabelNew>}</Radio>
                      &nbsp;
                      <Radio value={0}>{<LabelNew type="tab-header-inactive">{t('ไม่ใช้งาน')}</LabelNew>}</Radio>
                    </Space>
                  </RadioGroup>
                </Form.Item>
                <Row>
                  <LabelNew type="body-header">{t('แนบเอกสาร')}</LabelNew>
                  <Col {...tableFileResponsive}>
                    <Datatable
                      // enableScroll={{ x: true, y: false }}
                      // scroll={{ x: 1000 }}
                      columns={tableFileColumn}
                      data={fileData}
                      pagination={false}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default SoiAuditCommitteeTabDetailAcr
