/* eslint-disable no-unused-vars */
import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Form, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew as Card } from '../../../components/card/Card'
import { LabelNew as LabelNew } from '../../../components/label/Label'
import { Datatable } from '../../../components/datatable/Datatable'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { ButtonNew } from '../../../components/button/Button'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { encryptInput } from '../../../utilitys/crypto'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { TextAreaNew } from '../../../components/textarea/TextArea'
import { displayText, displayError } from '../../../utilitys/helper'
import SelectRiskFactorExamine from '../../../components/inputfromapi/selectriskfactorexamine/SelectRiskFactorExamine'
import SelectRateRiskRulesExamine from '../../../components/inputfromapi/selectrateriskrulesexamine/SelectRateRiskRulesExamine'
import SelectRateRiskControlExamine from '../../../components/inputfromapi/selectrateriskcontrolexamine/SelectRateRiskControlExamine'
import SelectAuditIssues from '../../../components/inputfromapi/selectauditIssues/SelectAuditIssues'
// import SelectReferInformations from '../../../components/inputfromapi/selectreferInformations/SelectReferInformations'
import SelectActionPlanPersonnels from '../../../components/inputfromapi/selectactionplanpersonnels/SelectActionPlanPersonnels'
import CheckerOrganizationModal from '../../components/modalformapi/modalCheckerOrganization/CheckerOrganizationModal'
import { SAVE_SUGGEST } from './graphql/Mutation'
import { GET_AUDIT_SUGGEST_OF_ISSUES_BY_ID, GET_AUDIT_RESULT_INSPEC_TION_PROJECT } from '../auditresult/graphql/Query'
import { useDecodeUser } from '../../../hooks/useDecodeUser'

const SuggestForm = (props) => {
  const [form] = Form.useForm()
  const { menu } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [errorOrganizationList, setErrorOrganizationList] = useState(false)
  const [typeAction, setTypeAction] = useState(false)
  const [rateFacProCode, setRateFacProCode] = useState(null)
  const [rateRiskRuleCode, setRateRiskRuleCode] = useState(null)
  const [rateRiskConCode, setRateRiskConCode] = useState(null)

  const [modal, setModal] = useState(false)
  const [user] = useDecodeUser()

  const Responsive = {
    sm: 24,
    md: 24,
    lg: 24,
  }

  const { state } = useLocation()

  if (!state) {
    return <Navigate to={'/administrator/subriskfactors'} />
  }

  const [cellSave, { data: dataSave, loading: loadingSave, error: errorSave }] = useMutation(SAVE_SUGGEST)
  const [callGet, { data: dataGet, loading: loadingGet, error: errorGet }] = useLazyQuery(
    GET_AUDIT_SUGGEST_OF_ISSUES_BY_ID,
  )
  const [cellGetAdd, { data: dataGetAdd, loading: loadingGetAdd, error: errorGetAdd }] = useLazyQuery(
    GET_AUDIT_RESULT_INSPEC_TION_PROJECT,
  )

  useEffect(() => {
    if (state.type_actionSub === 'edit' || state.type_actionSub === 'view') {
      if (state.type_actionSub === 'view') setTypeAction(true)

      const variables = encryptInput({ suggestId: state.suggestId })
      callGet({
        variables,
      })
    } else {
      const variables = encryptInput({ projectCode: state.refCode.projectCode })
      cellGetAdd({
        variables,
      })
    }
  }, [state])
  useEffect(() => {
    if (dataGetAdd) {
      const result = dataGetAdd.auditResultInspectionProjectGet
      if (result.length > 0) {
        const dataMap = result.map((item, index) => {
          return {
            key: index + 1,
            deleteId: item.organizationCode,
            organizationCode: item.organizationCode,
            organizationId: item.organizationId,
            organizationLevelCode: item.organizationLevelCode,
            organizationLevelName: displayText(item.organizationLevelNameTH, item.organizationLevelNameEN),
            organizationName: displayText(item.organizationNameTH, item.organizationNameEN),
            organizationNameEN: item.organizationNameEN,
            organizationNameTH: item.organizationNameTH,
            organizationLevelNameTH: item.organizationLevelNameTH,
            organizationLevelNameEN: item.organizationLevelNameEN,
            personnelCode: item.personnelCode,
            personnelName: displayText(item.personnelNameTH, item.personnelNameEN),
          }
        })
        setDataLists(dataMap)
      }
    }
    if (errorGetAdd) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(errorGetAdd)),
        onOk() {},
      })
    }
  }, [dataGetAdd])

  useEffect(() => {
    if (dataSave) {
      if (dataSave.auditSuggestSave.suggestId) {
        Alert({
          type: 'success',
          title: t('บันทึกข้อมูลสำเร็จ'),
          onOk() {
            navigate(`/auditoperations/auditcloses/auditclosesdetail`, {
              state: state,
            })
          },
        })
      }
    }

    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [dataSave])

  useEffect(() => {
    if (dataGet) {
      const { auditSuggestOfIssuesById } = dataGet

      if (auditSuggestOfIssuesById) {
        if (auditSuggestOfIssuesById.auditSuggestOrganizations.length > 0) {
          const dataMap = auditSuggestOfIssuesById.auditSuggestOrganizations.map((item, index) => {
            return {
              key: index + 1,
              deleteId: item.organizationCode,
              organizationCode: item.organizationCode,
              organizationId: item.organizationId,
              organizationLevelCode: item.organizationLevelCode,
              organizationLevelName: displayText(item.organizationLevelNameTH, item.organizationLevelNameEN),
              organizationName: displayText(item.organizationNameTH, item.organizationNameEN),
              organizationNameEN: item.organizationNameEN,
              organizationNameTH: item.organizationNameTH,
              organizationLevelNameTH: item.organizationLevelNameTH,
              organizationLevelNameEN: item.organizationLevelNameEN,
              personnelCode: item.personnelCode,
              personnelName: displayText(item.personnelNameTH, item.personnelNameEN),
            }
          })
          setDataLists(dataMap)
        }

        const personnelList = auditSuggestOfIssuesById.auditSuggestPersonnels.map((items) => {
          return {
            value: items.personnelCode,
          }
        })

        const numberSeriesForm = {
          personnelCode: personnelList[0]?.value,
          rateFacProCode: auditSuggestOfIssuesById.rateFacProCode,
          rateRiskRuleCode: auditSuggestOfIssuesById.rateRiskRuleCode,
          rateRiskConCode: auditSuggestOfIssuesById.rateRiskConCode,
          issueId: auditSuggestOfIssuesById.issueId,
          dateExpected: auditSuggestOfIssuesById.dateExpected,
          suggest: displayText(auditSuggestOfIssuesById.suggestTH, auditSuggestOfIssuesById.suggestEN),
          refInfoCode: auditSuggestOfIssuesById.refInfoCode,
        }
        setRateFacProCode(auditSuggestOfIssuesById.rateFacProCode)
        setRateRiskRuleCode(auditSuggestOfIssuesById.rateRiskRuleCode)
        setRateRiskConCode(auditSuggestOfIssuesById.rateRiskConCode)
        form.setFieldsValue(numberSeriesForm)
      }
    }

    if (errorGet) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(errorGet)),
        onOk() {},
      })
    }
  }, [dataGet])

  const formName = 'feedbackForm'

  const renderTextTitle = () => {
    if (state.type_actionSub === 'add') return 'เพิ่มข้อเสนอแนะ'
    if (state.type_actionSub === 'view') return 'ดูข้อเสนอแนะ'
    if (state.type_actionSub === 'edit') return 'แก้ไขข้อเสนอแนะ'
    return 'เพิ่มข้อเสนอแนะ'
  }

  const renderIconTitle = () => {
    if (state.type_actionSub === 'view') return 'FileText'
    if (state.type_actionSub === 'edit') return 'Edit2'
    else return 'Plus'
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/auditresult' },
    { label: t('บันทึกปิดตรวจ (pre exit)') },
    { label: t(renderTextTitle()) },
  ]

  const [dataLists, setDataLists] = useState([])

  const onFinishFailed = () => {}

  const onFinishForm = (values) => {
    let data = {}
    if (state?.suggestId) {
      data = {
        suggestId: state?.suggestId,
      }
    }

    const dataInput = {
      rateFacProCode: values.rateFacProCode,
      rateRiskConCode: values.rateRiskConCode,
      rateRiskRuleCode: values.rateRiskRuleCode,
      issueId: values.issueId,
      suggestTH: values.suggest || '',
      suggestEN: values.suggest || '',
      dateExpected: values.dateExpected,
      refInfoCode: values.refInfoCode,
      personnelCode: values.personnelCode || '',
      agency: dataLists,
      typeAction: state.type_actionSub,
      lang: localStorage.getItem('lang') || '',
      refCode: state.refCode.projectCode,
      sendBy: user?.pCode,
      personnelEmail: user?.personnelEmail,
      ...data,
    }

    /* เช็คหน่วยรับตรวจให้มีอย่างน้อย 1 รายการ */
    if (dataLists.length === 0) {
      setErrorOrganizationList(true)
      setTimeout(() => setErrorOrganizationList(false), 4000)
    } else {
      Alert({
        type: 'confirm',
        title: t('ต้องการยืนยันการบันทึก?'),
        onOk() {
          cellSave({
            variables: {
              input: encryptInput(dataInput),
            },
          })
        },
        onCancel() {},
      })
    }
  }

  const Columns = [
    {
      title: t('ลำดับ'),
      dataIndex: 'key',
      width: '10%',
      render: (row, x, index) => index + 1,
    },
    {
      title: t('รหัสหน่วยงาน'),
      width: '15%',
      dataIndex: 'organizationCode',
    },
    {
      title: t('หน่วยงาน'),
      width: '15%',
      dataIndex: 'organizationName',
    },
    {
      title: t('ระดับหน่วยงาน'),
      width: '25%',
      dataIndex: 'organizationLevelName',
    },
    {
      title: t('ผู้รับผิดชอบ'),
      width: '25%',
      dataIndex: 'personnelName',
    },
    {
      title: '',
      dataIndex: 'deleteId',
      width: '10%',
      render: (row) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {state.type_actionSub !== 'view' && (
              <ButtonGroup
                menu={menu}
                icons={[
                  {
                    type: 'delete',
                    onClick: () => {
                      if (dataLists.length > 1) return setDataLists([..._.filter(dataLists, (e) => e.deleteId !== row)])
                      if (dataLists.length === 1) {
                        setErrorOrganizationList(true)
                        setTimeout(() => setErrorOrganizationList(false), 4000)
                      }
                    },
                  },
                ]}
              />
            )}
          </div>
        )
      },
    },
  ]

  function setOrganizationList(v) {
    if (_.isEmpty(dataLists)) return setDataLists([...v])
    // check หน่วยงานซ้ำ
    setDataLists([...mergeArrayObjects(dataLists, v)])
  }

  function mergeArrayObjects(arr1, arr2) {
    const arr_ = [...arr1, ...arr2]
    return _.uniqBy(arr_, (i) => i.organizationCode)
  }
  return (
    <React.Fragment>
      {(loadingSave || loadingGet || loadingGetAdd) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t(renderTextTitle())} />
      <Card topic={t(renderTextTitle())} icon={renderIconTitle()}>
        <Form
          form={form}
          onFinish={onFinishForm}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            // span: 6,
            offset: 1,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 12,
            lg: 14,
          }}
          layout="horizontal"
          name={formName}
        >
          <Row style={{ marginTop: 24, marginBottom: 24 }} justify="center">
            <Col md={24} lg={14}>
              <Form.Item
                name="rateFacProCode"
                label={<LabelNew type="tab-header-inactive">{t('ปัจจัยความเสี่ยง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุปัจจัยความเสี่ยง') }]}
              >
                <SelectRiskFactorExamine
                  disabled={typeAction}
                  projectCode={state.refCode.projectCode}
                  filters={{ approveStatusCode: 20 }}
                  formname={formName}
                  placeholder={t('เลือกปัจจัยความเสี่ยง')}
                  handleChange={(e) => {
                    setRateFacProCode(e?.value || null)
                    setRateRiskRuleCode(null)
                    setRateRiskConCode(null)
                    form.setFieldsValue({
                      rateFacProCode: e?.value || null,
                      rateRiskRuleCode: null,
                      rateRiskConCode: null,
                    })
                  }}
                />
              </Form.Item>
              {/* {localStorage.getItem('lang') === 'th' ? ( */}
              <Form.Item
                name="rateRiskRuleCode"
                label={<LabelNew type="tab-header-inactive">{t('เกณฑ์ระเบียบคำสั่ง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุเกณฑ์ระเบียบคำสั่ง') }]}
              >
                <SelectRateRiskRulesExamine
                  disabled={typeAction}
                  rateFacProCode={rateFacProCode}
                  placeholder={t('เลือกเกณฑ์ระเบียบคำสั่ง')}
                  formname={formName}
                  handleChange={(e) => {
                    setRateRiskRuleCode(e?.value || null)
                    form.setFieldsValue({ rateRiskRuleCode: e?.value || null, rateRiskConCode: null })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="rateRiskConCode"
                label={<LabelNew type="tab-header-inactive">{t('วิธีการควบคุม')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุวิธีการควบคุม') }]}
              >
                <SelectRateRiskControlExamine
                  disabled={typeAction}
                  rateRiskRuleCode={rateRiskRuleCode} // ข้อมูล test
                  placeholder={t('เลือกวิธีการควบคุม')}
                  formname={formName}
                  handleChange={(e) => {
                    setRateRiskConCode(e?.value || null)
                    form.setFieldsValue({ rateRiskConCode: e?.value || null })
                  }}
                />
              </Form.Item>

              <Form.Item
                name="issueId"
                label={<LabelNew type="tab-header-inactive">{t('ข้อสังเกต')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุข้อสังเกต') }]}
              >
                <SelectAuditIssues
                  disabled={typeAction}
                  filters={{
                    projectCode: state.refCode.projectCode,
                    issueType: '3',
                    rateFacProCode: rateFacProCode,
                    rateRiskRuleCode: rateRiskRuleCode,
                    rateRiskConCode: rateRiskConCode,
                  }}
                  formname={formName}
                  placeholder={t('เลือกข้อสังเกต')}
                  handleChange={(e) => {
                    form.setFieldsValue({
                      issueId: e?.value || null,
                    })
                  }}
                />
              </Form.Item>
              <Form.Item name="suggest" label={<LabelNew type="tab-header-inactive">{t('ข้อเสนอแนะ')}</LabelNew>}>
                <TextAreaNew
                  showCount
                  disabled={typeAction}
                  maxLength={1000}
                  rows={5}
                  placeholder={t('ระบุข้อเสนอแนะ')}
                />
              </Form.Item>
              <Form.Item
                name="dateExpected"
                label={<LabelNew type="tab-header-inactive">{t('วันที่คาดว่าจะดำเนินการ')}</LabelNew>}
                // rules={[{ required: true, message: t('กรุณาระบุวันที่คาดว่าจะดำเนินการ') }]}
              >
                <Datepicker
                  disabled={state.type_action === 'view' ? true : false}
                  setValueDateFn={async (data) => {
                    await form.setFieldsValue({ dateExpected: await data })
                  }}
                  onClear={() => {
                    form.setFieldsValue({ dateExpected: null })
                  }}
                />
              </Form.Item>
              <Form.Item
                name="refInfoCode"
                label={<LabelNew type="tab-header-inactive">{t('กฏหมายและหลักเกณฑ์ที่เกี่ยวข้อง')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุกฏหมายและหลักเกณฑ์ที่เกี่ยวข้อง') }]}
              >
                <TextAreaNew
                  showCount
                  disabled={typeAction}
                  maxLength={1000}
                  rows={5}
                  placeholder={t('ระบุกฏหมายและหลักเกณฑ์ที่เกี่ยวข้อง')}
                />
                {/* <SelectReferInformations
                  disabled={typeAction}
                  formname={formName}
                  placeholder={t('เลือกแหล่งที่มาของข้อเสนอแนะ')}
                  handleChange={(e) => {
                    form.setFieldsValue({
                      refInfoCode: e?.value || null,
                    })
                  }}
                /> */}
              </Form.Item>
              <Form.Item
                name="personnelCode"
                label={<LabelNew type="tab-header-inactive">{t('ผู้รับผิดชอบ')}</LabelNew>}
                rules={[{ required: true, message: t('กรุณาระบุผู้รับผิดชอบ') }]}
              >
                <SelectActionPlanPersonnels
                  disabled={typeAction}
                  filters={{ projectCode: state.refCode.projectCode }}
                  placeholder={t('เลือกผู้รับผิดชอบ')}
                  formname={formName}
                  handleChange={(e) => {
                    form.setFieldsValue({ personnelCode: e })
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <LabelNew type="card-title" icon="List">
            {t('หน่วยงานรับตรวจ')}
          </LabelNew>
          <Row gutter={[16, 16]}>
            <Col {...Responsive}>
              <Space size={[8, 8]} wrap>
                {state.type_actionSub !== 'view' && (
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    onClick={() => {
                      setModal(true)
                    }}
                  >
                    {t('หน่วยงานรับตรวจ')}
                  </ButtonNew>
                )}
                &nbsp;
              </Space>
              {errorOrganizationList ? (
                <>
                  <span style={{ color: '#ea5456' }}>{t('กรุณาระบุหน่วยรับตรวจอย่างน้อย 1 รายการ')}</span>
                </>
              ) : null}
            </Col>
            <Col {...Responsive}>
              <Datatable
                columns={Columns}
                data={[...dataLists]}
                size="middle"
                pagination={true}
                scroll={{ x: '100%', y: 350 }}
              />
            </Col>
            <Col {...Responsive} align="center">
              <Space size={[8, 8]} wrap>
                {state.type_actionSub !== 'view' && (
                  <ButtonNew
                    type="primary"
                    roles={{ type: 'add', menu: menu }}
                    htmlType="submit"
                    // loading={loading ? true : false}
                  >
                    {t('บันทึก')}
                  </ButtonNew>
                )}
                &nbsp;
                <ButtonNew
                  type="back"
                  onClick={() => {
                    navigate(`/auditoperations/auditcloses/auditclosesdetail`, {
                      state: { ...state, activeTab: 4 },
                    })
                  }}
                >
                  {t('ย้อนกลับ')}
                </ButtonNew>
              </Space>
            </Col>
          </Row>
        </Form>
      </Card>
      {modal && (
        <CheckerOrganizationModal
          close={(e) => setModal(e)}
          open={modal}
          getData={(v) => setOrganizationList(v)}
          menu={menu}
          orgActive={dataLists}
        />
      )}
    </React.Fragment>
  )
}

export default SuggestForm
