import { gql } from '@apollo/client'

export const GET_APPROVE_ADHOCJOB_PAGINATION = gql`
  query getApproveAdhocJobPagination($input: ApproveAdhocJobPaginationInput!) {
    getApproveAdhocJobPagination(input: $input) {
      result {
        no
        jobCode
        jobNumber
        jobTypeNameTH
        jobTypeNameEN
        jobSubjectTH
        jobSubjectEN
        dateStart
        dateEnd
        jobStatusCode
        responsibleOrganization
        requestOrganization
        requestPersonnel
        approvePersonnelName
        approvePersonnelNameTH
        approvePersonnelNameEN
        approveStatusCode
        responsibleOrganizationName
        responsibleOrganizationNameTH
        responsibleOrganizationNameEN
        requestOrganizationName
        requestOrganizationNameTH
        requestOrganizationNameEN
        approveStatusNameTH
        approveStatusNameEN
        assignJobPersonnels
        assignJobPersonnelsTH
        assignJobPersonnelsEN
      }
      count
      page
      limit
    }
  }
`

export const GET_APPROVE_ADHOCJOB_BY_CODE = gql`
  query getAdhocJobByCode($jobCode: String!) {
    getAdhocJobByCode(jobCode: $jobCode) {
      jobCode
      jobNumber
      jobTypeCode
      jobTypeNameTH
      jobSubjectTH
      jobSubjectEN
      jobDetailTH
      jobDetailEN
      dateStart
      dateEnd
      responsibleOrganization
      responsibleOrganizationName
      requestOrganization
      requestOrganizationName
      approveStatusCode
      jobStatusCode
      jobStatusNameTH
      approveStatusNameTH
      requestPersonnel
      telephone
      approvePersonnel
      approvePersonnelName
      jobRemarkTH
      jobRemarkEN
      jobFile
    }
  }
`

export const GET_APPROVE_ADHOCJOB_FILES = gql`
  query getAdhocJobFiles($jobCode: String!) {
    getAdhocJobFiles(jobCode: $jobCode) {
      fileId
      filename
      links
      refFile {
        originalname
        name
        fileType
      }
    }
  }
`

export const GET_APPROVE_ADHOCJOB_PERSONNELS_LIST = gql`
  query getAdhocJobPersonnelByCode($jobCode: String!) {
    getAdhocJobPersonnelByCode(jobCode: $jobCode) {
      personnelCode
      personnelNameTH
      personnelNameEN
      positionNameTH
      positionNameEN
      organizationNameTH
      organizationNameEN
    }
  }
`
