import { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { gql } from '@apollo/client'
import { encryptInput } from '../../../../../utilitys/crypto'

const GET_SUMMARY_AUDIT_STATUS_CAUSE_AND_ISSUE = gql`
  query getSummaryAuditStatusCauseAndIssue($input: GetSummaryAuditStatusCauseAndIssueInput!) {
    getSummaryAuditStatusCauseAndIssue(input: $input) {
      cntReportAcProject
      cntReportAcPoint
      cntAssignOrderProject
      cntAssignOrderPoint
      cntInProcessPoint
      cntEditedPoint
      cntNotResponsePoint
    }
  }
`

export const useGetSummaryAuditStatusCauseAndIssue = (options) => {
  const [data, setData] = useState(null)
  const [getSummaryAuditStatusCauseAndIssueFn, getSummaryAuditStatusCauseAndIssue] = useLazyQuery(
    GET_SUMMARY_AUDIT_STATUS_CAUSE_AND_ISSUE,
  )

  useEffect(() => {
    const { dateStart, dateEnd } = options

    if (dateStart && dateEnd)
      getSummaryAuditStatusCauseAndIssueFn({
        variables: { input: encryptInput({ dateStart, dateEnd }) },
      })
  }, [options])

  useEffect(() => {
    if (getSummaryAuditStatusCauseAndIssue?.data)
      setData(getSummaryAuditStatusCauseAndIssue?.data?.getSummaryAuditStatusCauseAndIssue)
  }, [getSummaryAuditStatusCauseAndIssue?.data])

  const refresh = () => getSummaryAuditStatusCauseAndIssue.refetch()

  return [data, getSummaryAuditStatusCauseAndIssue?.loading, refresh]
}
