import React from 'react'
import { Row, Col } from 'antd'
import SelectCertificatesShow from './SelectCertificatesShow'
import SelectChoiceGroupsShow from './SelectChoiceGroupsShow'
import SelectChoiceTypesShow from './SelectChoiceTypesShow'
import SelectControlFormQuestionsShow from './SelectControlFormQuestionsShow'
import SelectEducationLevelsShow from './SelectEducationLevelsShow'
import SelectEducationMajorsShow from './SelectEducationMajorsShow'
import SelectEducationProgramsShow from './SelectEducationProgramsShow'
import SelectGendersShow from './SelectGendersShow'
import SelectIsActiveShow from './SelectIsActiveShow'
import SelectNamePrefixesShow from './SelectNamePrefixesShow'
import SelectOperationStatusShow from './SelectOperationStatusShow'
import SelectOrganizationShow from './SelectOrganizationShow'
import SelectOrganizationLevelShow from './SelectOrganizationLevelShow'
import SelectPersonnelLevelsShow from './SelectPersonnelLevelsShow'
import SelectPersonnelPositionsShow from './SelectPersonnelPositionsShow'
import SelectPersonnelStatusShow from './SelectPersonnelStatusShow'
import SelectPersonnelTypeShow from './SelectPersonnelTypeShow'
import SelectPlanStatusShow from './SelectPlanStatusShow'
import SelectPlanTypeShow from './SelectPlanTypeShow'
import SelectProjectTypesShow from './SelectProjectTypesShow'
import SelectQuestDetailTypesShow from './SelectQuestDetailTypesShow'
import SelectQuestRequiredShow from './SelectQuestRequiredShow'
import SelectRiskFormulasShow from './SelectRiskFormulasShow'
import SelectRiskModelShow from './SelectRiskModelShow'
import SelectRiskTypesShow from './SelectRiskTypesShow'
import SelectTrainTypesShow from './SelectTrainTypesShow'
import SelectYearShow from './SelectYearShow'
import SelectUserTypeShow from './SelectUserTypeShow'
import SelectProjectReferShow from './SelectProjectReferShow'
import SelectProjectSizesShow from './SelectProjectSizesShow'
import SelectProjectStatusShow from './SelectProjectStatusShow'
import SelectApproveStatusShow from './SelectApproveStatusShow'
import SelectOrganizationCheckedShow from './SelectOrganizationCheckedShow'
import SelectOrganizationSizeShow from './SelectOrganizationSizeShow'
import SelectJobTypesShow from './SelectJobTypesShow'
import SelectAssignJobPersonnelsShow from './SelectAssignJobPersonnelsShow'
import SelectPersonnelsShow from './SelectPersonnelsShow'
import SelectChoiceDetailShow from './SelectChoiceDetailsShow'
import SelectControlNaturesShow from './SelectControlNaturesShow'
import SelectControlTypesShow from './SelectControlTypesShow'
import SelectJobStatusShow from './SelectJobStatusShow'
import SelecteNumberTypesShow from './SelecteNumberTypesShow'
import SelectDocumentTypesShow from './SelectDocumentTypesShow'
import SelectChoiceGroupDetailsShow from './SelectChoiceGroupDetailShow'
import SelectAuditProjectShow from './SelectAuditProjectShow'

const Inputfromapiindex = () => {
  return (
    <Row>
      <Col lg={24} style={{ width: '100%' }}>
        <SelectChoiceGroupDetailsShow />
        <SelectJobStatusShow />
        <SelectControlTypesShow />
        <SelectControlNaturesShow />
        <SelectChoiceDetailShow />
        <SelectCertificatesShow />
        <SelectChoiceGroupsShow />
        <SelectChoiceTypesShow />
        <SelectControlFormQuestionsShow />
        <SelectEducationLevelsShow />
        <SelectEducationMajorsShow />
        <SelectEducationProgramsShow />
        <SelectGendersShow />
        <SelectIsActiveShow />
        <SelectNamePrefixesShow />
        <SelectOperationStatusShow />
        <SelectOrganizationShow />
        <SelectOrganizationLevelShow />
        <SelectPersonnelLevelsShow />
        <SelectPersonnelPositionsShow />
        <SelectPersonnelStatusShow />
        <SelectPersonnelTypeShow />
        <SelectPlanStatusShow />
        <SelectPlanTypeShow />
        <SelectProjectTypesShow />
        <SelectQuestDetailTypesShow />
        <SelectQuestRequiredShow />
        <SelectRiskFormulasShow />
        <SelectRiskModelShow />
        <SelectRiskTypesShow />
        <SelectTrainTypesShow />
        <SelectYearShow />
        <SelectUserTypeShow />
        <SelectProjectReferShow />
        <SelectProjectSizesShow />
        <SelectProjectStatusShow />
        <SelectApproveStatusShow />
        <SelectOrganizationCheckedShow />
        <SelectOrganizationSizeShow />
        <SelectJobTypesShow />
        <SelectAssignJobPersonnelsShow />
        <SelectPersonnelsShow />
        <SelecteNumberTypesShow />
        <SelectDocumentTypesShow />
        <SelectAuditProjectShow />
      </Col>
    </Row>
  )
}

export default Inputfromapiindex
