import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_AUDIT_TYPES } from './graphql/Query'
import { Select } from '../../select/Select'
import { displayText } from '../../../utilitys/helper'
import { encryptInput } from '../../../utilitys/crypto'

const SelectAuditTypes = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { isActive } = filters || {}

  const [isData, isSetData] = useState([])

  const [getMasterAuditTypesFn, getMasterAuditTypes] = useLazyQuery(GET_MASTER_AUDIT_TYPES)

  useEffect(() => {
    getMasterAuditTypesFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [isActive])

  useEffect(() => {
    if (getMasterAuditTypes.data) {
      isSetData(
        _.map(getMasterAuditTypes?.data?.getMasterAuditTypes, (item) => {
          return {
            label: displayText(item?.auditTypeNameTH, item?.auditTypeNameEN),
            value: item?.auditTypeCode,
            source: item,
          }
        }),
      )
    }
  }, [getMasterAuditTypes.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterAuditTypes.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectAuditTypes
