import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import * as _ from 'lodash'
import { GET_SELECT_RISK_RULES_EXAMINE } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectRateRiskRulesExamine = (props) => {
  const { placeholder, formname, handleChange, rateFacProCode, ...otherProp } = props

  const [isData, isSetData] = useState([])

  const [cellGet, { data, loading }] = useLazyQuery(GET_SELECT_RISK_RULES_EXAMINE)

  useEffect(() => {
    cellGet({
      variables: {
        input: encryptInput({ rateFacProCode: rateFacProCode }),
      },
    })
  }, [rateFacProCode])

  useEffect(() => {
    if (data) {
      isSetData(
        data?.getSelectRiskRulesExamine.map((item) => {
          let label = displayText(item.rateRiskRuleTH, item.rateRiskRuleEN)
          if (!label) label = item.rateRiskRuleTH
          return {
            value: item.rateRiskRuleCode,
            label: `${item.rateRiskRuleCode} : ${label}`,
            source: item,
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        loading={loading}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectRateRiskRulesExamine
