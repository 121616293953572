import { gql } from '@apollo/client'

export const DELETE_ADHOCJOB_BY_CODE = gql`
  mutation deleteAdhocJobByCode($jobCode: String!) {
    deleteAdhocJobByCode(jobCode: $jobCode)
  }
`

export const SAVE_ADHOCJOB = gql`
  mutation adhocJobSave($input: AdhocJobInput!) {
    adhocJobSave(input: $input) {
      jobCode
    }
  }
`

export const SAVE_ADHOCJOB_PERSONNEL = gql`
  mutation adhocJobPersonnelSave($input: AdhocJobInput!) {
    adhocJobPersonnelSave(input: $input)
  }
`

export const SAVE_ADHOCJOB_STEP = gql`
  mutation adhocJobStepSave($input: AdhocJobStepInput!) {
    adhocJobStepSave(input: $input)
  }
`

export const SAVE_SINGLE_ADHOCJOB_STEP = gql`
  mutation adhocJobStepSingleSave($input: AdhocJobStepInput!, $jobCode: String!) {
    adhocJobStepSingleSave(input: $input, jobCode: $jobCode)
  }
`

export const DELETE_ADHOCJOB_STEP_BY_ID = gql`
  mutation jobStepDeleteById($stepId: String!) {
    jobStepDeleteById(stepId: $stepId)
  }
`

export const SAVE_ADHOCJOB_FILE = gql`
  mutation adhocJobFileSave($input: AdhocJobInput!, $jobCode: String!) {
    adhocJobFileSave(input: $input, jobCode: $jobCode)
  }
`

export const GENERATE_JOB_NUMBER = gql`
  mutation adhocJobNumberGenerate($input: GenerateCodeAdhocJobInput!) {
    adhocJobNumberGenerate(input: $input) {
      jobNumber
    }
  }
`
