import { gql } from '@apollo/client'

export const NOTI_LOGIN_SUB = gql`
  subscription NotificationLogin($userId: Float!) {
    notificationLogin(userId: $userId) {
      userId
    }
  }
`

export const NOTI_SUB = gql`
  subscription Notification($personnelCode: String!, $userId: Float!) {
    notification(personnelCode: $personnelCode, userId: $userId) {
      unRead
      total
      detail {
        notiId
        menuCode
        personnelCode
        title
        detail
        route
        refCode
        readStatus
        receivedStatus
        createdAt
      }
    }
  }
`

export const NOTI_SIDEBAR = gql`
  query getNotificationSidebar($personnelCode: String!) {
    getNotificationSidebar(personnelCode: $personnelCode) {
      detail {
        menuCode
        personnelCode
        readStatus
      }
    }
  }
`

export const NOTI_GET = gql`
  query GetNoti($personnelCode: String!, $limit: String!, $page: String!) {
    getNoti(personnelCode: $personnelCode, limit: $limit, page: $page) {
      unRead
      total
      detail {
        notiId
        menuCode
        personnelCode
        title
        detail
        route
        refCode
        readStatus
        receivedStatus
        createdAt
      }
    }
  }
`
