import { gql } from '@apollo/client'

export const GET_CONTROL_FORM_PAGINATION = gql`
  query getControlFormPagination($input: ListControlFomsInput!) {
    getControlFormPagination(input: $input) {
      result {
        controlFormCode
        controlFormId
        controlFormYear
        controlFormTH
        controlFormEN
        dateEnd
        dateStart
        operationStatusCode
        operationStatusNameTH
        operationStatusNameEN
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_ORGANIZATION_PAGINATION = gql`
  query getMasterOrganizationsPagination($input: MasterOrganizationsPagination!) {
    getMasterOrganizationsPagination(input: $input) {
      result {
        organizationId
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationLevelNameTH
        organizationLevelNameEN
        organizationLevelCode
        personnelCode
        personnelNameTH
        personnelNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_CONTROL_FORM_BY_ID = gql`
  query getControlFormById($controlFormId: String!) {
    getControlFormById(controlFormId: $controlFormId) {
      controlForm {
        controlFormId
        controlFormCode
        controlFormTH
        controlFormEN
        controlFormYear
        operationStatusCode
        dateStart
        dateEnd
        isActive
      }
      controlFormOrganizations {
        organizationId
        controlFormOrgId
        controlFormCode
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationParentCode
        organizationSizeCode
        organizationChecked
        organizationLevelCode
        organizationLevelNameTH
        organizationLevelNameEN
        personnelCode
        personnelNameTH
        personnelNameEN
        createdAt
        createdBy
      }
      controlFormConfigurations {
        formConfigId
        formConfigNo
        formConfigTH
        formConfigEN
        controlFormCode
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
      controlFormQuestions {
        questId
        questTH
        questEN
        formConfigId
        choiceTypeCode
        choiceGroupCode
        questDetailType
        questRequired
        isActive
        createdAt
        createdBy
        updatedAt
        updatedBy
      }
    }
  }
`

export const GET_MASTER_CHOICE_TYPES = gql`
  query getMasterChoiceTypes($input: MasterChoiceTypesInput!) {
    getMasterChoiceTypes(data: $input) {
      choiceTypeId
      choiceTypeCode
      choiceTypeNameTH
      choiceTypeNameEN
      isActive
    }
  }
`
export const GET_PERSONNEL_BY_ORGS = gql`
  query getPersonnelsByOrganizarion($controlFormCode: String!) {
    getPersonnelsByOrganizarion(controlFormCode: $controlFormCode) {
      email
      personnelCode
      organizationCode
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
    }
  }
`
