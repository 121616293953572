import { gql } from '@apollo/client'

export const GET_MASTER_CHOICE_GROUP_DETAILS = gql`
  query getMasterChoiceGroupDetails($input: MasterChoiceGroupDetailsInput!) {
    getMasterChoiceGroupDetails(data: $input) {
      choiceDetailId
      choiceDetailNameTH
      choiceDetailNameEN
      choiceGroupCode
      score
      isActive
    }
  }
`
