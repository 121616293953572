import { gql } from '@apollo/client'

export const GET_CONSULTATION_REPORT_PAGINATION_REPORT = gql`
  query getConsultationResultPaginationReport($input: ConsultationResultPaginationReportInput!) {
    getConsultationResultPaginationReport(input: $input) {
      result {
        consultId
        jobTypeNameTH
        jobTypeNameEN
        jobNumber
        jobCode
        requestPersonnel
        requestOrganization
        jobSubjectTH
        resultDate
        responsibleOrganization
        fullName
        jobStatusCode
        consultStatus
        dateStart
        dateEnd
      }
      count
      page
      limit
    }
  }
`

export const GET_CONSULTATION_RESULT_REPORT = gql`
  query getConsultationResult($input: ConsultationResultInput!) {
    getConsultationResult(input: $input) {
      jobCode
      organizationNameTH
      organizationNameEN
      requestPersonnel
      telephone
      Subject
      fact
      result
      assessment
      resultDate
      responsibleOrganization
      fullName
      files
      fileDetail
      createdAt
      consultStatus
    }
  }
`
export const CONSULT_RESULT_REPORT_EXPORT = gql`
  query consultResultReportExport($input: ConsultExportReportInput!) {
    consultResultReportExport(input: $input) {
      message
      fileUrl
    }
  }
`
