import React, { useState, useEffect } from 'react'
import { Row, Col, Form, Space } from 'antd'
import { useTranslation } from 'react-i18next'
import { LabelNew } from '../../../../../components/label/Label'
import { InputNew as Input } from '../../../../../components/input/Input'
import SelectProjectRefer from '../../../../../components/inputfromapi/selectprojectrefer/SelectProjectRefer'
import SelectAuditTypes from '../../../../../components/inputfromapi/selectaudittypes/SelectAuditTypes'
import SelectPersonnels from '../../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'

import { ButtonNew } from '../../../../../components/button/Button'
import { Radio, RadioGroup } from '../../../../../components/radio/Radio'
import { ModalNew } from '../../../../../components/modal/Modal'
import ModalGeneralRiskForm from './modal/ModalGeneralRiskForm'
import FormAuditProjectsGeneralOrganizations from './FormAuditProjectsGeneralOrganizations'
import { CardNew } from '../../../../../components/card/Card'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { DATA_AUDITPROJECT_FORM_GENERAL_TAB } from '../graphql/Query'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'
import { Alert } from '../../../../../components/alert/Alert'
import { SAVE_AUDIT_PROJECT_GENERAL } from '../graphql/Mutation'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'

const FormAuditProjectsGeneral = (props) => {
  const [form] = Form.useForm()
  const { type, params, refCode, planCode } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [organizations, setOrganizations] = useState([])
  const [riskForm, setRiskForm] = useState({ code: null, name: '' })
  const [modalRiskForm, setModalRiskForm] = useState(false)
  const [modalRiskFormSubMit, setModalRiskFormSubMit] = useState(false)
  const [riskFormBuffer, setRiskFormBuffer] = useState({ code: null, name: '' })

  // ======================================================= API Check Data General ======================
  const [dataAuditProjectFormGeneralTabFn, dataAuditProjectFormGeneralTab] = useLazyQuery(
    DATA_AUDITPROJECT_FORM_GENERAL_TAB,
  )

  useEffect(() => {
    if (dataAuditProjectFormGeneralTab?.data) {
      let response = dataAuditProjectFormGeneralTab?.data?.dataAuditProjectFormGeneralTab
      if (response) {
        setOrganizations(
          response.organization.map((item) => {
            return {
              organizationCode: item.organizationCode,
              organizationName:
                localStorage.getItem('lang') === 'th' ? item.organizationNameTH : item.organizationNameEN,
              organizationLevelName:
                localStorage.getItem('lang') === 'th' ? item.organizationLevelNameTH : item.organizationLevelNameEN,
              organizationNameTH: item.organizationNameTH,
              organizationNameEN: item.organizationNameEN,
              personnelCode: item.personnelCode,
              personnels:
                localStorage.getItem('lang') === 'th'
                  ? item.namePrefixTH + item.firstNameTH + ' ' + item.lastNameTH
                  : item.namePrefixEN + item.firstNameEN + ' ' + item.lastNameEN,
            }
          }),
        )
        setRiskForm({
          code: response.riskFormCode ? response.riskFormCode : null,
          name: localStorage.getItem('lang') === 'th' ? response.riskFormTH : response.riskFormEN,
        })
        form.setFieldsValue({
          cancelStatus: response.cancelStatus,
          cancelRemark: localStorage.getItem('lang') === 'th' ? response.cancelRemarkTH : response.cancelRemarkEN,
          projectCode: response.projectCode,
          projectName: localStorage.getItem('lang') === 'th' ? response.projectNameTH : response.projectNameEN,
          projectNameTH: response.projectNameTH,
          projectNameEN: response.projectNameEN,
          projectRefCode: response.projectRefCode,
          auditTypeCode: response.auditTypeCode,
          approveStatusName:
            localStorage.getItem('lang') === 'th' ? response.approveStatusNameTH : response.approveStatusNameEN,
          projectStatusName:
            localStorage.getItem('lang') === 'th' ? response.projectStatusNameTH : response.projectStatusNameEN,
          isActive: response.isActive,
          approvePersonnelCode: response.approvePersonnelCode,
          riskFormCode: response.riskFormCode
            ? response.riskFormCode +
              ' : ' +
              (localStorage.getItem('lang') === 'th' ? response.riskFormTH : response.riskFormEN)
            : '',
        })
      }
    }

    if (dataAuditProjectFormGeneralTab.error) {
      showAlertLoadDataError(displayError(dataAuditProjectFormGeneralTab.error))
    }
  }, [dataAuditProjectFormGeneralTab.data])

  useEffect(() => {
    if (type !== 'add' && params === 'general') {
      dataAuditProjectFormGeneralTabFn({
        fetchPolicy: 'no-cache',
        variables: {
          projectCode: encryptInput(refCode),
        },
      })
    }
  }, [type, params])

  // ===================================================== API Save General ==============================
  const [saveDataFn, saveData] = useMutation(SAVE_AUDIT_PROJECT_GENERAL)

  useEffect(() => {
    if (saveData.data) {
      let response = saveData?.data?.auditProjectsGeneralSave
      if (response) {
        showAlertSuccess(response)
      }
    }
    if (saveData.error) {
      showAlertError(displayError(saveData.error))
    }
  }, [saveData.data])

  const showAlertSuccess = (data) => {
    const dataContent = {
      content: '',
    }
    if (data) {
      if (data.detail === 'Please reapprove agian') {
        dataContent.content = t('กรุณาอนุมัติโครงการใหม่')
      }
    }

    Alert({
      type: 'success',
      title: t('บันทึกสำเร็จ'),
      onOk() {
        if (type === 'add') {
          navigate('/auditplanning/auditplan/auditproject/team/edit', {
            state: { refCode: form.getFieldValue('projectCode') },
          })
        } else {
          dataAuditProjectFormGeneralTab.refetch()
        }
      },
      ...dataContent,
    })
  }

  const onFinish = async (value) => {
    if (!organizations.length) {
      showAlertOrgError()
    } else {
      let input = {
        menuCode: 'AP01',
        projectTypeCode: '10',
        planCode: planCode,
        projectCode: value.projectCode,
        projectRefCode: value.projectRefCode,
        riskFormCode: riskForm.code,
        organizationCode: organizations,
        isActive: value.isActive,
        auditTypeCode: value.auditTypeCode,
        approvePersonnelCode: value.approvePersonnelCode,
      }

      if (type === 'add') {
        input['projectNameTH'] = value.projectName
        input['projectNameEN'] = value.projectName
        input['action'] = 'create'
      } else if (type === 'edit') {
        input['projectNameTH'] = localStorage.getItem('lang') === 'th' ? value.projectName : value.projectNameTH
        input['projectNameEN'] = localStorage.getItem('lang') === 'en' ? value.projectName : value.projectNameEN
        input['action'] = 'update'
      }

      Alert({
        type: 'confirm',
        title: t('ต้องการยืนยันการบันทึก ?'),
        onOk() {
          if (organizations.length) {
            saveDataFn({
              variables: {
                input: encryptInput(input),
              },
              fetchPolicy: 'no-cache',
            })
          } else {
            showAlertOrgError()
          }
        },
        onCancel() {},
      })
    }
  }

  useEffect(() => {
    if (modalRiskFormSubMit) {
      setRiskForm(riskFormBuffer)
      form.setFieldsValue({ riskFormCode: riskFormBuffer.code + ' : ' + riskFormBuffer.name })
      setRiskFormBuffer({ code: null, name: '' })
      setModalRiskFormSubMit(false)
    }
  }, [modalRiskFormSubMit])

  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  // // ==================================== Alert ====================================
  // const showConfirmGeneral = () => {

  // }

  const showAlertLoadDataError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถโหลดข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const showAlertOrgError = () => {
    Alert({
      type: 'error',
      title: t('โปรดเพิ่มหน่วยรับตรวจอย่างน้อย 1 หน่วย'),
      content: '',
      onOk() {},
    })
  }

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const formName = 'ActionPlanDetail'

  return (
    <>
      {(dataAuditProjectFormGeneralTab.loading || saveData.loading) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            labelAlign="left"
            labelCol={{
              sm: 24,
              md: 8,
            }}
            wrapperCol={{
              sm: 24,
              md: 16,
            }}
            layout="horizontal"
            name={formName}
          >
            <LabelNew type="body-header">{t('รายละเอียดโครงการ')}</LabelNew>
            <Row justify="center">
              <Col md={18}>
                <Form.Item
                  name="projectCode"
                  label={<LabelNew type="tab-header-inactive">{t('รหัสโครงการ')}</LabelNew>}
                  rules={[
                    { required: true, message: t('กรุณาระบุรหัสโครงการ') },
                    {
                      max: 10,
                      message: t('กรุณาระบุรหัสโครงการน้อยกว่าหรือเท่ากับ 10 ตัวอักษร'),
                    },
                  ]}
                >
                  <Input
                    maxLength={10}
                    placeholder={t('ระบุรหัสโครงการ')}
                    disabled={props.typeForm !== 'add' ? true : false}
                  />
                </Form.Item>
                <Form.Item name="projectNameTH" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item name="projectNameEN" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="projectName"
                  label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ')}</LabelNew>}
                  rules={[
                    { required: true, message: 'กรุณาระบุชื่อโครงการ' },
                    {
                      max: 200,
                      message: t('กรุณาระบุชื่อโครงการน้อยกว่าหรือเท่ากับ 200 ตัวอักษร'),
                    },
                  ]}
                >
                  <Input
                    maxLength={200}
                    placeholder={t('ระบุชื่อโครงการ')}
                    disabled={props.typeForm === 'view' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  name="projectTypeCode"
                  label={<LabelNew type="tab-header-inactive">{t('ประเภทโครงการ')}</LabelNew>}
                  initialValue={t('โครงการในแผน')}
                >
                  <Input placeholder={t('ระบุประเภทโครงการ')} disabled={true} />
                </Form.Item>
                <Form.Item
                  name="projectRefCode"
                  label={<LabelNew type="tab-header-inactive">{t('แหล่งที่มาของโครงการ')}</LabelNew>}
                  rules={[
                    {
                      required: true,
                      message: t('กรุณาเลือกแหล่งที่มาของโครงการ'),
                    },
                  ]}
                >
                  <SelectProjectRefer
                    filters={{ isActive: 1 }}
                    formname={formName}
                    placeholder={t('เลือกแหล่งที่มาของโครงการ')}
                    handleChange={(value) => {
                      form.setFieldsValue({ projectRefCode: value ? value.value : null })
                      if (value) {
                        setErrorField('projectRefCode', false)
                      } else {
                        setErrorField('projectRefCode', t('กรุณาเลือกแหล่งที่มาของโครงการ'))
                      }
                    }}
                    disabled={props.typeForm === 'view' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  name="auditTypeCode"
                  label={<LabelNew type="tab-header-inactive">{t('ประเภทการตรวจสอบ')}</LabelNew>}
                  rules={[
                    {
                      required: true,
                      message: t('กรุณาเลือกประเภทการตรวจสอบ'),
                    },
                  ]}
                >
                  <SelectAuditTypes
                    filters={{ isActive: 1 }}
                    formname={formName}
                    placeholder={t('เลือกประเภทการตรวจสอบ')}
                    handleChange={(value) => {
                      form.setFieldsValue({ auditTypeCode: value ? value.value : null })
                      if (value) {
                        setErrorField('auditTypeCode', false)
                      } else {
                        setErrorField('auditTypeCode', t('กรุณาเลือกประเภทการตรวจสอบ'))
                      }
                    }}
                    disabled={props.typeForm === 'view' ? true : false}
                  />
                </Form.Item>
                <Form.Item
                  name="riskFormCode"
                  label={<LabelNew type="tab-header-inactive">{t('แบบประเมินความเสี่ยง')}</LabelNew>}
                >
                  <Input
                    addonAfter={
                      <ButtonNew
                        type=""
                        htmlType="button"
                        onClick={() => {
                          setModalRiskForm(true)
                        }}
                        style={{
                          marginLeft: '-53px',
                          marginRight: '-11px',
                          marginTop: '-2px',
                          height: '38px',
                          zIndex: 99,
                        }}
                        disabled={props.typeForm === 'view' ? true : false}
                      >
                        {t('เลือก')}
                      </ButtonNew>
                    }
                    disabled={true}
                    placeholder={t('เลือกแบบประเมินความเสี่ยง')}
                  />
                </Form.Item>
                <Form.Item
                  name="approveStatusName"
                  label={<LabelNew type="tab-header-inactive">{t('สถานะอนุมัติ')}</LabelNew>}
                  initialValue={props.typeForm === 'add' ? t('รออนุมัติ') : ''}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  name="projectStatusName"
                  label={<LabelNew type="tab-header-inactive">{t('สถานะของโครงการ')}</LabelNew>}
                  initialValue={props.typeForm === 'add' ? t('รอดำเนินการ') : ''}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item name="cancelStatus" hidden={true}>
                  <Input />
                </Form.Item>
                <Form.Item
                  name="cancelRemark"
                  hidden={props.typeForm === 'add' || form.getFieldValue('cancelStatus') === 0 ? true : false}
                  label={<LabelNew type="tab-header-inactive">{t('หมายเหตุของการขอยกเลิกการตรวจสอบ')}</LabelNew>}
                >
                  <Input disabled={true} />
                </Form.Item>
                <Form.Item
                  name="approvePersonnelCode"
                  label={<LabelNew type="tab-header-inactive">{t('ผู้อนุมัติแผนการตรวจสอบ')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุ') }]}
                >
                  <SelectPersonnels
                    placeholder={t('เลือกผู้อนุมัติแผนการตรวจสอบ')}
                    formname={formName}
                    filters={{ levelCode: 12, organizationChecked: 2, personnaloperationCode: 10 }}
                    disabled={props.typeForm === 'view' ? true : false}
                    handleChange={(e) => form.setFieldsValue({ approvePersonnelCode: e?.value })}
                  />
                </Form.Item>
                <Form.Item
                  name="isActive"
                  label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                  initialValue={1}
                >
                  <RadioGroup disabled={props.typeForm === 'view' ? true : false}>
                    <Space size={[8, 8]} wrap>
                      <Radio value={1}>{t('ใช้งาน')}</Radio>
                      <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                    </Space>
                  </RadioGroup>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
      <ModalNew
        visible={modalRiskForm}
        onSubmit={() => {}}
        onOk={() => {
          setModalRiskFormSubMit(true)
          setModalRiskForm(false)
        }}
        onCancel={() => {
          setRiskFormBuffer({ code: null, name: '' })
          setModalRiskForm(false)
        }}
        onClose={setModalRiskForm}
        testTitle={t('แบบประเมินความเสี่ยง')}
        btnName={t('เพิ่ม')}
        type="big"
      >
        <ModalGeneralRiskForm
          type={type}
          refCode={refCode}
          modalRiskForm={modalRiskForm}
          riskForm={riskForm}
          riskFormBuffer={riskFormBuffer}
          setRiskFormBuffer={setRiskFormBuffer}
        />
      </ModalNew>
      <Row>
        <Col md={24}>
          <CardNew topic={t('หน่วยงานรับตรวจ')} icon="AlignJustify" toggledrop="false">
            <FormAuditProjectsGeneralOrganizations
              typeForm={type}
              planCode={planCode}
              refCode={refCode}
              organizations={organizations}
              setOrganizations={setOrganizations}
              menu={props.menu}
            />
            <Row gutter={[12, 12]} justify="center">
              {type !== 'view' && (
                <Col>
                  <ButtonNew
                    type="primary"
                    roles={{ type: type, menu: props.menu }}
                    onClick={() => {
                      form.submit()
                    }}
                    htmlType="submit"
                  >
                    {t('บันทึก')}
                  </ButtonNew>
                </Col>
              )}
              <Col>
                <ButtonNew
                  type="cancel"
                  onClick={() => {
                    navigate('/auditplanning/auditplan/auditproject/list', {
                      state: { refCode: planCode },
                    })
                  }}
                >
                  {t('ยกเลิก')}
                </ButtonNew>
              </Col>
            </Row>
          </CardNew>
        </Col>
      </Row>
    </>
    //okButtonProps={typeForm === 'view' ? { style: { display: 'none' } } : {}}
  )
}

export default FormAuditProjectsGeneral
