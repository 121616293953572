import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Form, Radio, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew as Card } from '../../../components/card/Card'
import { LabelNew as LabelNew } from '../../../components/label/Label'
import { InputNew } from '../../../components/input/Input'
import { RadioGroup } from '../../../components/radio/Radio'
import { Datatable } from '../../../components/datatable/Datatable'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Columns } from './utils/SetupRunningNumberFormColumns'
import { ButtonNew } from '../../../components/button/Button'
import SelecteNumberTypes from '../../../components/inputfromapi/selectenumbertypes/SelecteNumberTypes'
import SelectDocumentTypes from '../../../components/inputfromapi/selectdocumenttypes/SelectDocumentTypes'
import SelectJobTypes from '../../../components/inputfromapi/selectjobtypes/SelectJobTypes'
import SelectOrganization from '../../../components/inputfromapi/selectorganization/SelectOrganization'
import SelectSystemMenus from '../../../components/inputfromapi/selectsystemmenus/SelectSystemMenus.jsx'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { encryptInput } from '../../../utilitys/crypto'
import { CREATE_SETUP_RUNNING_NUMBER } from './graphql/Mutation'
import { GET_MASTER_NUMBER_SERIES } from './graphql/Query'
import { displayError } from '../../../utilitys/helper'

const SetupRunningNumberForm = ({ menu }) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [orthodontics, setOrthodontics] = useState()
  const [isMinDate, setIsMinDate] = useState(null)
  const [isMaxDate, setIsMaxDate] = useState(null)
  const [disable, setDisable] = useState([])

  const { state } = useLocation()
  if (!state) {
    return <Navigate to={'/administrator/subriskfactors'} />
  }

  const { type_action = 'view', refCode: numberSeriesId } = state

  useEffect(() => {
    if (numberSeriesId) {
      const variables = encryptInput({ numberSeriesId: numberSeriesId })
      callGet({
        variables,
      })
      if (type_action === 'edit') seteditable(false)
      if (type_action === 'view') seteditable(false)
    }
  }, [numberSeriesId])

  const [callGet, { loading: loadingGet, error: errorGet, data: dataGet }] = useLazyQuery(GET_MASTER_NUMBER_SERIES)

  useEffect(() => {
    if (dataGet) {
      const { masterNumberSeriesGet } = dataGet

      if (masterNumberSeriesGet) {
        const mapDataList = masterNumberSeriesGet.masterNumberFormat.map((items, key) => {
          return {
            key,
            keyNo: items.formatSort,
            pattern: items.formatTypeCode,
            patternNo: items.formatDetail,
          }
        })
        // jobTypeCode
        // docTypeCode
        const numberSeriesForm = {
          orthodontics: masterNumberSeriesGet.numberTypeCode,
          document_job: masterNumberSeriesGet.jobTypeCode || masterNumberSeriesGet.docTypeCode,
          agency: masterNumberSeriesGet.organizationCode,
          menu: masterNumberSeriesGet.menuCode,
          dateStart: masterNumberSeriesGet.startDate,
          dateEnd: masterNumberSeriesGet.endDate,
          lastNumber: masterNumberSeriesGet.lastNumber,
          isActive: masterNumberSeriesGet.isActive,
        }
        setOrthodontics(masterNumberSeriesGet.numberTypeCode)
        form.setFieldsValue(numberSeriesForm)
        setDataLists(mapDataList)
      }
    }

    if (errorGet) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorGet)),
        onOk() {},
      })
    }
  }, [dataGet])

  const formName = 'mnDocumentForm'

  const [editable, seteditable] = useState(true)

  const renderTextTitle = () => {
    if (state.type_action === 'add') return 'เพิ่มการตั้งค่าเลขที่เอกสาร'
    if (state.type_action === 'view') return 'ดูการตั้งค่าเลขที่เอกสาร'
    if (state.type_action === 'edit') return 'แก้ไขการตั้งค่าเลขที่เอกสาร'
    return 'เพิ่มการตั้งค่าเลขที่เอกสาร'
  }

  const renderIconTitle = () => {
    if (state.type_action === 'view') return 'FileText'
    if (state.type_action === 'edit') return 'Edit2'
    else return 'Plus'
  }

  const arrCrumb = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator' },
    { label: t('จัดการเลขที่เอกสาร'), path: '/administrator/setuprunningnumber' },
    { label: t(renderTextTitle()) },
  ]

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] =
    useMutation(CREATE_SETUP_RUNNING_NUMBER)

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate(-1)
        },
      })
    }
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [dataSave])

  const [dataLists, setDataLists] = useState([])

  const handlePressAdd = () => {
    let newData = {
      key: dataLists.length,
      keyNo: dataLists.length + 1,
      factorActRiskCode: null,
      factorActRiskName: null,
      factorActRiskNameTH: null,
      factorActRiskNameEN: null,
      remark: null,
    }
    const fields = form.getFieldsValue()
    const result = Object.values(fields)

    result.push(newData)
    setDataLists([...dataLists, newData])
    form.setFieldsValue(result)
  }

  const fncDelete = (index) => {
    const fields = form.getFieldsValue()
    let { doucumentForm } = fields

    let filterTable = [..._.filter(doucumentForm, (e, key) => key !== index)]
    let items = []
    if (dataLists.length > 1) {
      let newTable = filterTable.map((item, key) => {
        if (item.pattern === 'AD' || item.pattern === 'BE') {
          items.push(true)
        } else {
          items.push(false)
        }
        return { ...item, key }
      })

      setDataLists(newTable)
      fields.doucumentForm = newTable
    } else {
      setDataLists([])
      fields.doucumentForm = []
    }
    setDisable(items)
    form.setFieldsValue(fields)
  }

  const onFinishFailed = () => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t('กรุณากรอกข้อมูลให้ครบถ้วน'),
      onOk() {},
    })
  }

  const onFinishForm = (values) => {
    let data = {}
    if (numberSeriesId) {
      data = {
        numberSeriesId: numberSeriesId,
      }
    }

    const dataInput = {
      ...data,
      numberTypeCode: values.orthodontics,
      documentJob: values.document_job,
      organizationCode: values.agency,
      menuCode: values.menu,
      startDate: values.dateStart,
      endDate: values.dateEnd,
      lastNumber: values.lastNumber,
      isActive: values.isActive,
      doucumentForm: values.doucumentForm ? values.doucumentForm : [],
      typeAction: type_action,
    }

    if (values.doucumentForm) {
      Alert({
        type: 'confirm',
        title: t('ต้องการยืนยันการบันทึก?'),
        onOk() {
          callSave({
            variables: {
              input: encryptInput(dataInput),
            },
          })
        },
        onCancel() {},
      })
    } else {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t('กรุณากำหนดรูปแบบเลขที่เอกสาร'),
        onOk() {},
      })
    }
  }

  return (
    <React.Fragment>
      {(loadingSave || loadingGet) && <SpinnersNew />}
      <Breadcrumb data={arrCrumb} title={t(renderTextTitle())} />
      <Card topic={t(renderTextTitle())} icon={renderIconTitle()}>
        <Form
          form={form}
          onFinish={onFinishForm}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            // span: 6,
            offset: 1,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 12,
            lg: 24,
          }}
          layout="horizontal"
          name={formName}
        >
          <Col md={24} lg={24} xl={22}>
            <Row style={{ marginBottom: 24 }} align="center" gutter={[24, 16]}>
              <Col sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  name="orthodontics"
                  label={<LabelNew type="tab-header-inactive">{t('ประเภทเลขที่')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุประเภทเลขที่') }]}
                >
                  <SelecteNumberTypes
                    disabled={!editable}
                    formname={formName}
                    placeholder={t('เลือกประเภทเลขที่')}
                    handleChange={(e) => {
                      form.setFieldsValue({ orthodontics: e.value, document_job: null })
                      setOrthodontics(e.value)
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="document_job"
                  label={<LabelNew type="tab-header-inactive">{t('เอกสาร/งาน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุเอกสาร/งาน') }]}
                >
                  {orthodontics === '10' ? (
                    <SelectDocumentTypes
                      disabled={!editable}
                      formname={formName}
                      placeholder={t('เอกสาร')}
                      handleChange={(e) => {
                        form.setFieldsValue({ document_job: e.value })
                      }}
                    />
                  ) : (
                    <SelectJobTypes
                      disabled={!editable}
                      formname={formName}
                      placeholder={t('งาน')}
                      filters={{ isActive: '1', jobflag: '1' }}
                      handleChange={(e) => {
                        form.setFieldsValue({ document_job: e.value })
                      }}
                    />
                  )}
                </Form.Item>
                <Form.Item
                  name="agency"
                  label={<LabelNew type="tab-header-inactive">{t('หน่วยงาน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุหน่วยงาน') }]}
                >
                  <SelectOrganization
                    disabled={!editable}
                    formname={formName}
                    placeholder={t('หน่วยงาน')}
                    filters={{ isActive: '1', organizationChecked: '2' }}
                    handleChange={(e) => {
                      form.setFieldsValue({ agency: e.value })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="menu"
                  label={<LabelNew type="tab-header-inactive">{t('เมนูที่ใช้งาน')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุเมนูที่ใช้งาน') }]}
                >
                  <SelectSystemMenus
                    disabled={!editable}
                    formname={formName}
                    placeholder={t('เมนูที่ใช้งาน')}
                    filters={{ isActive: '1', running: '1' }}
                    handleChange={(e) => {
                      form.setFieldsValue({ menu: e.value })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={24} lg={12} xl={12}>
                <Form.Item
                  name="dateStart"
                  label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุวันที่เริ่มต้น') }]}
                >
                  <Datepicker
                    disabled={state.type_action === 'view' ? true : false}
                    setValueDateFn={async (data) => {
                      await setIsMinDate(data)
                      await form.setFieldsValue({ dateStart: await data })
                    }}
                    isMaxDate={isMaxDate ? new Date(isMaxDate) : null}
                    selectsStart={true}
                    onClear={() => {
                      form.setFieldsValue({ dateStart: null })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="dateEnd"
                  label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุวันที่สิ้นสุด') }]}
                >
                  <Datepicker
                    disabled={state.type_action === 'view' ? true : false}
                    setValueDateFn={async (data) => {
                      await setIsMaxDate(data)
                      await form.setFieldsValue({ dateEnd: await data })
                    }}
                    isMinDate={isMinDate ? new Date(isMinDate) : null}
                    selectsEnd={true}
                    onClear={() => {
                      form.setFieldsValue({ dateEnd: null })
                    }}
                  />
                </Form.Item>
                <Form.Item
                  name="lastNumber"
                  label={<LabelNew type="tab-header-inactive">{t('เลขที่ล่าสุด')}</LabelNew>}
                  rules={[{ required: true, message: t('กรุณาระบุเลขที่ล่าสุด') }]}
                >
                  <InputNew
                    disabled={state.type_action === 'view' ? true : false}
                    placeholder={t('กรอกเลขที่ล่าสุด')}
                  />
                </Form.Item>

                <Form.Item
                  name="isActive"
                  label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
                  initialValue={1}
                  style={{ marginBottom: 45 }}
                >
                  <RadioGroup disabled={state.type_action === 'view' ? true : false}>
                    <Row>
                      <Col style={{ marginRight: 20 }}>
                        <Radio value={1}>{t('ใช้งาน')}</Radio>
                      </Col>
                      <Col>
                        <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                      </Col>
                    </Row>
                  </RadioGroup>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <LabelNew type="card-title" icon="List">
            {t('รูปแบบเลขที่เอกสาร')}
          </LabelNew>
          <Datatable
            // enableScroll={{ x: true, y: false }}
            columns={Columns({ editable, fncDelete, menu, form, formName, disable, setDisable })}
            data={dataLists}
            pagination={false}
          />
          {editable && (
            <ButtonNew type="addMultiple" onClick={handlePressAdd}>
              {t('เพิ่มข้อมูล')}
            </ButtonNew>
          )}
          <Row gutter={[12, 12]} justify="center" style={{ marginBottom: 20, marginTop: 20 }}>
            {(editable || type_action === 'edit' || type_action === 'add') && (
              <Col>
                <ButtonNew type="primary" htmlType="submit" roles={{ type: type_action, menu: menu }}>
                  {t('บันทึก')}
                </ButtonNew>
              </Col>
            )}
            <Col>
              <ButtonNew onClick={() => navigate(-1)} type="back">
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </Card>
    </React.Fragment>
  )
}
export default SetupRunningNumberForm
