import React, { useEffect } from 'react'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { Row, Col, Form } from 'antd'
import { InputNew } from '../../../../../components/input/Input'
import { useTranslation } from 'react-i18next'
import { Datepicker } from '../../../../../components/datepicker/Datepicker'
import { ButtonNew as Button } from '../../../../../components/button/Button'
import { useNavigate } from 'react-router-dom'
// import { CardNew as Card } from '../../../../../components/card/Card'
import { GET_APPROVE_AUDIT_PROJECT_ONE } from '../../graphql/Query'
import { useLazyQuery } from '@apollo/client'
// import { TabNew } from '../../../../../components/tab/Tab'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../../utilitys/crypto'
import { checkLanguageReturnData } from '../../component/fnForApprove'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError } from '../../../../../utilitys/helper'

export default function ResponsiblePersonAndDuration(props) {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const navigate = useNavigate()
  // const state = JSON.parse(decryptData(localStorage.getItem('pageApproveState')))
  const lang = localStorage.getItem('lang')
  const state = props.state

  const [getApproveAuditProjectRawOneFn, getApproveAuditProjectRawOne] = useLazyQuery(GET_APPROVE_AUDIT_PROJECT_ONE, {
    fetchPolicy: 'no-cache',
  })

  useEffect(() => {
    getApproveAuditProjectRawOneFn({
      variables: {
        inputData: encryptInput({ planCode: state.refCode, projectCode: state.projectCode }),
      },
    })
  }, [])

  useEffect(() => {
    if (getApproveAuditProjectRawOne.data) {
      const res = getApproveAuditProjectRawOne.data.getApproveAuditProjectOne
      form.setFieldsValue({
        organizations: t(res.organizationNameTH),
        auditTeam: checkLanguageReturnData(lang, res.auditTeamNameTH, res.auditTeamNameEN),
        mainsPersonnel: checkLanguageReturnData(lang, res.fullNameTH, res.fullNameEN),
        projectSize: checkLanguageReturnData(lang, res.projectSizeNameTH, res.projectSizeNameEN),
        projectManday: res.manday,
        numberOfPeople: res.numberOfPeople,
        mandayPersonnal: res.mandayPersonnal,
        startDate: res.dateStart,
        endDate: res.dateEnd,
      })
    }
    if (getApproveAuditProjectRawOne.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getApproveAuditProjectRawOne.error)),
        onOk() {},
      })
    }
  }, [getApproveAuditProjectRawOne.data])

  //

  return (
    <>
      {getApproveAuditProjectRawOne.loading && <SpinnersNew />}
      <Label type="card-title" icon="Plus">
        {t('รายละเอียดโครงการ')}
      </Label>
      <Label type="body-header">{t('ผู้รับผิดชอบ/ระยะเวลา')}</Label>
      <Row style={{ marginTop: 24 }} align="center">
        <Col md={24} lg={16}>
          <Form
            form={form}
            autoComplete="off"
            labelAlign="left"
            labelCol={{
              sm: 8,
              md: 8,
              lg: 8,
              xl: 8,
            }}
            wrapperCol={{
              sm: 16,
              md: 16,
              lg: 24,
              xl: 16,
              align: 'left',
            }}
            layout="horizontal"
            name="projectdetail_form"
          >
            <Form.Item
              name="organizations"
              label={t('หน่วยงานที่รับผิดชอบ')}
              placeholder={t('ระบุหน่วยงานที่รับผิดชอบ')}
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item
              name="auditTeam"
              label={t('ทีมตรวจสอบ')}
              placeholder={t('ระบุทีมตรวจสอบ')}
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item
              name="mainsPersonnel"
              label={t('ผู้รับผิดชอบหลัก')}
              placeholder={t('ระบุผู้รับผิดชอบหลัก')}
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item
              name="projectSize"
              label={t('ขนาดของโครงการ')}
              placeholder={t('ระบุขนาดของโครงการ')}
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item name="projectManday" label={t('จำนวนวันทำงาน')} placeholder={t('ระบุจำนวนวันทำงาน')}>
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item
              name="numberOfPeople"
              label={t('จำนวนคน')}
              placeholder={t('ระบุจำนวนคน')}
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item
              name="mandayPersonnal"
              label={t('จำนวนวันทำงานต่อคน')}
              placeholder={t('ระบุจำนวนวันทำงานต่อคน')}
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item label={t('วันที่เริ่มต้น')} name="startDate" rules={[{ required: true, message: 'required' }]}>
              <Datepicker
                language="th"
                disabled={true}
                setValueDateFn={(data) => {
                  form.setFieldsValue({
                    startDate: data,
                  })
                }}
              />
            </Form.Item>
            <Form.Item label={t('วันที่สิ้นสุด')} name="endDate">
              <Datepicker
                language="th"
                disabled={true}
                setValueDateFn={(data) => {
                  form.setFieldsValue({
                    endDate: data,
                  })
                }}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>

      <Row style={{ marginTop: 24, marginBottom: 24 }} align="center">
        <Col md={3}>
          <Button
            type="back"
            onClick={() => {
              navigate('/auditplanning/approveauditplan/detail/', {
                state: state,
              })
            }}
          >
            {t('ย้อนกลับ')}
          </Button>
        </Col>
      </Row>
    </>
  )
}
