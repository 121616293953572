import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import * as _ from 'lodash'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Datatable } from '../../../../components/datatable/Datatable.jsx'
import { Status } from '../../../../components/status/Status'
import { ButtonNew } from '../../../../components/button/Button'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../../components/spinners/Spinners.jsx'
import { GET_AUDIT_SUGGEST_OF_ISSUES_PAGINATION } from '../graphql/Query'
import { DELETE_AUDIT_SUGGEST_OF_ISSUES } from '../graphql/Mutation'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayText, displayError } from '../../../../utilitys/helper'
import { dateDisplay } from '../../../../utilitys/dateHelper'
import { Alert } from '../../../../components/alert/Alert'

const TabThree = (props) => {
  const { t } = useTranslation()
  const { menu } = props
  const [dataLists, setDataLists] = useState([])
  const navigate = useNavigate()
  const { state } = useLocation()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [sort /*setSort*/] = useState([])
  const [count, setCount] = useState(0)

  if (!state) {
    return <Navigate to={'/auditoperations/auditresult/'} />
  }

  const [pagination, { data: dataPagination, loading: loadingPagination, error: errorPagination }] = useLazyQuery(
    GET_AUDIT_SUGGEST_OF_ISSUES_PAGINATION,
  )

  const [cellDelete, { data: dataDelete, loading: loadingDelete, error: errorDelete }] =
    useMutation(DELETE_AUDIT_SUGGEST_OF_ISSUES)

  useEffect(() => {
    if (dataDelete) {
      if (dataDelete.auditSuggestOfIssuesDelete.suggestId) {
        Alert({
          type: 'success',
          title: t('ลบข้อมูลสำเร็จ'),
          onOk() {
            pagination({
              variables: {
                input: encryptInput({
                  filters: { projectCode: state.refCode },
                  pagination: { limit, page },
                  sort: sort,
                }),
              },
            })
          },
        })
      }
    }
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถลบข้อมูลได้`),
        content: t(displayError(errorDelete)),
        onOk() {},
      })
    }
  }, [dataDelete])

  useEffect(() => {
    pagination({
      variables: {
        input: encryptInput({
          filters: { projectCode: state.refCode },
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (dataPagination) {
      let response = dataPagination.auditSuggestOfIssuesPaginationGet
      setCount(response.count)
      setDataLists(
        _.map(response.result, (item, keys) => {
          let show = true

          if (keys !== 0) {
            if (localStorage.getItem('lang') === 'th') {
              show = response.result[keys - 1].issueTH !== response.result[keys].issueTH ? true : false
            } else {
              show = response.result[keys - 1].issueEN !== response.result[keys].issueEN ? true : false
            }
          }

          return {
            key: keys + 1,
            show: show,
            issuesObservations: displayText(item.issueTH, item.issueEN),
            feedback: displayText(item.suggestTH, item.suggestEN),
            dateEC: dateDisplay(item.dateExpected),
            isActive: <Status text={item.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
            // sendEmail: (
            //   <ButtonNew
            //     onClick={() => {
            //       state.suggestId = item.suggestId
            //       navigate('/auditoperations/auditresult/formtab/notification', {
            //         state: state,
            //       })
            //     }}
            //     type="flagOutlinedInTableBlue"
            //     width={92}
            //     height={36}
            //     roles={{ type: 'send', menu: menu }}
            //   >
            //     {t('แจ้งประเด็น')}
            //   </ButtonNew>
            // ),
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => {
                        state.suggestId = item.suggestId
                        state.type_actionSub = 'view'
                        navigate('/auditoperations/auditresult/formtab/three', {
                          state: state,
                        })
                      },
                    },
                    {
                      type: 'edit',
                      disabled: state.type_action === 'view' ? true : false,
                      onClick: () => {
                        state.suggestId = item.suggestId
                        state.type_actionSub = 'edit'
                        navigate('/auditoperations/auditresult/formtab/three', {
                          state: state,
                        })
                      },
                    },
                    {
                      type: 'delete',
                      disabled: state.type_action === 'view' ? true : false,
                      onClick: async () => {
                        fnConfirm(item.suggestId)
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (errorPagination) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorPagination)),
        onOk() {},
      })
    }
  }, [dataPagination])

  const fnConfirm = (suggestId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        onSubmit(suggestId)
      },
      onCancel() {},
    })
  }

  const onSubmit = async (suggestId) => {
    let variables = encryptInput({ suggestId: suggestId })
    cellDelete({
      variables,
    })
  }

  const columnsQuestion = [
    {
      title: t('ข้อสังเกตุ'),
      dataIndex: 'issuesObservations',
      width: '25%',
      align: 'left',
      render: (item, record) => {
        if (record.show) {
          return <div>{record.issuesObservations}</div>
        } else {
          return <></>
        }
      },
    },
    {
      title: t('ข้อเสนอแนะ'),
      dataIndex: 'feedback',
      width: '25%',
      align: 'left',
    },
    {
      title: t('วันที่คาดว่าจะแล้วเสร็จ'),
      dataIndex: 'dateEC',
      width: '15%',
      align: 'left',
    },
    {
      title: t('สถานะ'),
      dataIndex: 'isActive',
      width: '10%',
      align: 'center',
    },
    // { title: '', dataIndex: 'sendEmail', width: '15%', align: 'left' },
    {
      title: '',
      dataIndex: 'tools',
      width: '10%',
    },
  ]

  return (
    <>
      {(loadingPagination || loadingDelete) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Datatable
            columns={columnsQuestion}
            data={dataLists}
            searchCustom={true}
            total={count}
            pageSize={limit}
            page={page}
            paginationCustom={true}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            btnAdd={
              <>
                {state.type_action === 'add' || state.type_action === 'edit' ? (
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    style={{ marginRight: '12px' }}
                    onClick={() => {
                      delete state.issueId
                      state.type_actionSub = 'add'
                      navigate('/auditoperations/auditresult/formtab/three', {
                        state: state,
                      })
                    }}
                  >
                    {t('ข้อเสนอแนะ')}
                  </ButtonNew>
                ) : (
                  ''
                )}
              </>
            }
          />
        </Col>
      </Row>
    </>
  )
}

export default TabThree
