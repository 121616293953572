import { gql } from '@apollo/client'

export const GET_MASTER_EDUCATION_MAJORS = gql`
  query getMasterEducationMajors($input: MasterEducationMajorsInput!) {
    getMasterEducationMajors(data: $input) {
      educationMajorId
      educationMajorCode
      educationMajorTH
      educationMajorEN
      isActive
    }
  }
`
