import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_SELECT_STEP_MODEL_DETAIL } from './graphql/Query'
import { Select } from '../../../../components/select/Select'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayText } from '../../../../utilitys/helper'

const SelectStepModalDetail = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props

  const [isData, isSetData] = useState([])

  useEffect(() => {
    callGet({ variables: { input: encryptInput(filters ? { ...filters } : { isActive: 1 }) } })
  }, [filters])

  const [callGet, { data, loading }] = useLazyQuery(GET_SELECT_STEP_MODEL_DETAIL)

  useEffect(() => {
    if (data) {
      isSetData(
        data?.getSelectStepModelDetail.map((item) => {
          let label = displayText(item.stepDetailTH, item.stepDetailEN)
          if (!label) label = item.stepDetailTH
          return {
            value: item.stepDetailId,
            label: `${item.stepSort} : ${label}`,
            source: item,
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        loading={loading}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectStepModalDetail
