import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form, Input, Button, Row, Col } from 'antd'
import { LockOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { useTranslation, Trans } from 'react-i18next'
import { ResetPasswordStyles } from './ResetPasswordStyles'
import { Alert } from '../../../components/alert/Alert'
import { validatePassword, validateComparPassword } from '../../../utilitys/validationAntd'
import { RESET_PASSWORD } from './graphql/Mutation'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { AuthContext } from '../../../context/authContext'

const lang = localStorage.getItem('lang') || process.env['REACT_APP_DEFAULT_LANG']

const ResetPassword = () => {
  const { state } = useLocation()
  const { t, i18n } = useTranslation()
  const [password, setPassword] = useState()
  const [confirmPassword, setConfirmPassword] = useState()
  const [, /*user*/ setUser] = useContext(AuthContext)
  const navigate = useNavigate()

  const [resetPassword, { data: dataResetPassword, loading: loadingResetPassword, error: errorResetPassword }] =
    useMutation(RESET_PASSWORD, {
      fetchPolicy: 'no-cache',
    })

  const onFinish = (values) => {
    fnConfirm(values)
  }

  const fnConfirm = (values) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        onSubmit(values)
      },
      onCancel() {},
    })
  }

  const onSubmit = async (values) => {
    let dataInput = {
      userId2: state.userId,
      password: values.password,
    }
    resetPassword({
      variables: {
        input: encryptInput(dataInput, process.env.REACT_APP_SECRET_FE_BE),
      },
      fetchPolicy: 'no-cache',
    })
  }

  const onFinishFailed = () => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t('กรุณากรอกข้อมูลให้ครบถ้วน'),
      onOk() {},
    })
  }

  useEffect(() => {
    if (state) {
      if (new Date(state.dateEnd).getTime() < new Date().getTime()) {
        Alert({
          type: 'error',
          title: t('ลิงก์หมดอายุ'),
          onOk() {
            localStorage.removeItem('user')
            setUser({ loginName: null })
            navigate('/login')
          },
        })
      }
    } else {
      Alert({
        type: 'error',
        title: t('ลิงก์หมดอายุ'),
        onOk() {
          localStorage.removeItem('user')
          setUser({ loginName: null })
          navigate('/login')
        },
      })
    }
  }, [state])

  useEffect(() => {
    i18n.changeLanguage(lang)
  }, [])

  useEffect(() => {
    if (dataResetPassword) {
      Alert({
        type: 'success',
        title: t('บันทึกข้อมูลสำเร็จ'),
        onOk() {
          // navigate('/login')
          window.location.href = '/login'
        },
      })
    }
    if (errorResetPassword) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(errorResetPassword.message),
        onOk() {},
      })
    }
  }, [dataResetPassword])

  return (
    <ResetPasswordStyles>
      {loadingResetPassword ? <SpinnersNew /> : null}
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          style={{ minHeight: '100vh' }}
          className="content-right"
        >
          <div className="content-right-line"></div>
          <Row align="center">
            <Col>
              <Form
                name="normal_login"
                layout={'vertical'}
                className="login-form"
                style={{ alignItems: 'center' }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <div className="welcome" style={{ paddingTop: '80px' }}>
                  {t('Welcome to')}
                </div>
                <div className="title">
                  <Trans>Audit Management Tools</Trans>
                </div>
                <Form.Item
                  name="password"
                  label={`${t('รหัสผ่านใหม่')}`}
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                  rules={[...validatePassword(true, t('กรุณาระบุรหัสใหม่'))]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder=""
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  label={t('ยืนยันรหัสผ่านใหม่')}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value)
                  }}
                  rules={[
                    ...validateComparPassword(
                      true,
                      t('กรุณาระบุให้ตรงกับรหัสผ่านใหม่'),
                      false,
                      true,
                      password,
                      confirmPassword,
                    ),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder=""
                    size="large"
                  />
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit" className="login-form-button" size="large">
                    {' '}
                    <Trans>เปลี่ยนรหัสผ่าน</Trans>
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </ResetPasswordStyles>
  )
}

export default ResetPassword
