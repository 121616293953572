import { Col, Row } from 'antd'
import moment from 'moment'
import React from 'react'
// import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CalendarNew } from '../../../components/calendar/Calendar'
import { CalendarTimeline } from '../../../components/calendartimeline/CalendarTimeline'
import { CardNew } from '../../../components/card/Card'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'

function CalendarIndex() {
  const data = [{ label: 'งานควบคุมภายใน', path: '/internalcontrol/assess' }, { label: 'ประเมินการควบคุมภายใน' }]
  // let code = ` import { LabelNew } from '../../../components/label/Label'
  //   export default function App() {
  //       return <>
  //       <CardNew topic="CardContent" icon="AlignJustify" toggledrop={'true'}>
  //           Card Line 01
  //       </CardNew>
  //       <CardNew topic="CardContent" icon="AlignJustify" toggledrop={'true'}>
  //           Card Line 02
  //           <CodeBlock code={code} />
  //       </CardNew>
  //       </>
  //   }
  //   `

  const eventList = [
    {
      title: 'PJ64001 : แผนการตรวจสอบกระบวนการจัดซื้อจัดจ้างประจำปี 2564',
      start: moment('2022-03-16 12:00').format('YYYY-MM-DD HH:mm'),
      end: moment('2022-03-19 13:00').format('YYYY-MM-DD HH:mm'),
      action: 'click'
    },
    {
      title: 'งานที่ 1',
      start: moment('2022-03-16 12:00').format('YYYY-MM-DD HH:mm'),
      end: moment('2022-03-16 13:00').format('YYYY-MM-DD HH:mm'),
      action: 'click'
    },
    {
      title: 'งานที่ 2',
      start: moment('2022-03-17 12:00').format('YYYY-MM-DD HH:mm'),
      end: moment('2022-03-17 13:00').format('YYYY-MM-DD HH:mm'),
      action: 'click'
    },
    {
      title: 'งานที่ 3',
      start: moment('2022-03-18 12:00').format('YYYY-MM-DD HH:mm'),
      end: moment('2022-03-18 13:00').format('YYYY-MM-DD HH:mm'),
      action: 'click'
    }
  ]

  let code = `
import { CalendarTimeline } from '../../../components/calendartimeline/CalendarTimeline'


export default function CalendarIndex() {

  const groups = [
    {
      id: 'teststet',
      title: 'teststet : testest ประจำปี 2567',
      dateStart: '2024-06-16',
      dateEnd: '2024-06-26'
    },
    {
      id: '676767',
      title: '676767 : 676767 ประจำปี 2567',
      dateStart: '2024-06-03',
      dateEnd: '2024-12-31'
    }
  ]

  const items = [
    {
      id: 'teststet',
      group: 'teststet',
      title: {
        late: false,
        countStatus10: 0,
        countStatus20: 0,
        countStatus30: 0
      },
      start_time: '2024-06-16',
      end_time: '2024-06-26'
    },
    {
      id: '676767',
      group: '676767',
      title: {
        late: false,
        countStatus10: 0,
        countStatus20: 100,
        countStatus30: 0
      },
      start_time: '2024-06-03',
      end_time: '2024-12-31'
    }
  ]

  return (
            <CalendarTimeline
              headerContent="แผนการตรวจสอบ"
              path="/auditplanning/auditplan/auditproject/"
              groups={groups}
              items={items}
              year="2030"
              footerToolRight={true}
            />
  )
}
  `

  const groups = [
    {
      id: 'teststet',
      title: 'teststet : testest ประจำปี 2567',
      dateStart: '2024-06-16',
      dateEnd: '2024-06-26'
    },
    {
      id: '676767',
      title: '676767 : 676767 ประจำปี 2567',
      dateStart: '2024-06-03',
      dateEnd: '2024-12-31'
    }
  ]

  const items = [
    {
      id: 'teststet',
      group: 'teststet',
      title: {
        late: false,
        countStatus10: 0,
        countStatus20: 0,
        countStatus30: 0
      },
      start_time: '2024-06-16',
      end_time: '2024-06-26'
    },
    {
      id: '676767',
      group: '676767',
      title: {
        late: false,
        countStatus10: 0,
        countStatus20: 100,
        countStatus30: 0
      },
      start_time: '2024-06-03',
      end_time: '2024-12-31'
    }
  ]

  return (
    <React.Fragment>
      <Row>
        <Col span={24}>
          <Breadcrumb data={data} title="ประเมินการควบคุมภายใน" />
          <CardNew topic="Calendar" icon="AlignJustify" toggledrop="true">
            <CalendarTimeline
              headerContent="แผนการตรวจสอบ"
              path="/auditplanning/auditplan/auditproject/"
              groups={groups}
              items={items}
              year="2030"
              footerToolRight={true}
            />
            <CodeBlock code={code} />
            <div style={{ margin: '20px 0' }}></div>
            {/* <CodeBlock code={code} /> */}
            <CalendarNew eventList={eventList} />
          </CardNew>
        </Col>
      </Row>
    </React.Fragment>
  )
}
export default CalendarIndex
