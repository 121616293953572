import { gql } from '@apollo/client'

export const GET_DATA_OVERVIEW_SLA_PROCESS_GRAPHPIE = gql`
  query GetOverviewSlaProcessGraphPie($input: OverviewSlaProcessInput!) {
    getOverviewSlaProcessGraphPie(input: $input) {
      jobTypeCode
      jobTypeName
      jobStatusCode
      statusName
      countStatus
      projectCode
    }
  }
`

export const GET_DATA_NEXT_MEETING_AC = gql`
  query GetOverviewSlaProcessNextMeetingAc($input: OverviewSlaProcessInput!) {
    getOverviewSlaProcessNextMeetingAc(input: $input) {
      resDate
    }
  }
`

export const GET_DATA_OVERVIEW_SLA_PROCESS_GRAPH_COLUMN = gql`
  query GetOverviewSlaProcessGraphColumn($input: OverviewSlaProcessInput!) {
    getOverviewSlaProcessGraphColumn(input: $input) {
      acResult {
        isSLA
        notSLA
      }
      exitResult {
        isSLA
        notSLA
      }
    }
  }
`

export const GET_DATA_OVERVIEW_SLA_PROCESS_PAGINATION = gql`
  query GetOverviewSlaProcessPagination($input: OverviewSlaProcessPaginationInput!) {
    getOverviewSlaProcessPagination(input: $input) {
      result {
        no
        projectCode
        projectNameTH
        projectNameEN
        projectStatusNameTH
        projectStatusNameEN
        operationStatusNameTH
        operationStatusNameEN
        openDate
        createdAt
        dateExit
        meetingDate
        dateSendReport
        dateEntryMC
      }
      count
      page
      limit
    }
  }
`
