import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Alert } from '../../../../components/alert/Alert'
import { ButtonNew } from '../../../../components/button/Button'
import { Datatable } from '../../../../components/datatable/Datatable'
import { encryptInput } from '../../../../utilitys/crypto'
import { dateDisplay } from '../../../../utilitys/dateHelper'
import { displayError, displayText } from '../../../../utilitys/helper'

import { DELETE_AUDIT_JOB_STEP } from './graphql/Mutation'
import { GET_LIST_AUDIT_JOB_STEP_BY_ACTION_PLAN_ID } from './graphql/Query'

import ModalAddStep from './modal/ModalAddStep'
import { Columns } from './utils/ActionPlanFormStepListColumn'

const Responsive = {
  md: 24,
  lg: 24,
}

const ActionPlanFormStepList = ({
  typeActionMain = 'view',
  menu,
  actionPlanId,
  auditTeamCode,
  backPath,
  activeKey = null,
  projectCode = null,
}) => {
  if (activeKey !== '2') return <></>

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [visibleStep, setvisibleStep] = useState(false)

  const [dataRow, setdataRow] = useState([])

  const [callList, { loading: loadingList, error: errorList, data: dataList }] = useLazyQuery(
    GET_LIST_AUDIT_JOB_STEP_BY_ACTION_PLAN_ID,
  )

  useEffect(() => {
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorList)),
      })
    }
  }, [errorList])

  useEffect(() => {
    if (dataList) {
      const { getListAuditJobStepByActionPlanID } = dataList

      let noSub = ''
      let dataTemp = getListAuditJobStepByActionPlanID.map((item, key) => {
        let stepName = ''
        if (item.stepType === 1) {
          stepName = (
            <span style={{ fontWeight: 'bold' }}>
              {item.stepSort + '.' + displayText(item.stepNameTH, item.stepNameEN)}
            </span>
          )
          noSub = item.stepSort
        } else {
          stepName = (
            <>&emsp;{'' + noSub + '.' + item.stepSort + '.' + displayText(item.stepNameTH, item.stepNameEN)} </>
          )
        }

        let operationStatusName = displayText(item.operationStatusNameTH, item.operationStatusNameEN)
        return {
          ...item,
          key: key + 1,
          stepName,
          operationStatusName,
          dateStart: dateDisplay(item.dateStart),
          dateEnd: dateDisplay(item.dateEnd),
        }
      })

      setdataRow(dataTemp)
    }
  }, [dataList])

  useEffect(() => {
    fncGetList()
  }, [])

  const fncGetList = () => {
    const dataInput = {
      actionPlanId: actionPlanId,
    }

    callList({
      variables: { input: encryptInput(dataInput) },
    })
  }

  const [callDelete, { loading: loadingDelete, error: errorDelete, data: dataDelete }] =
    useMutation(DELETE_AUDIT_JOB_STEP)

  useEffect(() => {
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorDelete)),
      })
    }
  }, [errorDelete])

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        content: t(dataDelete.message),
        onOk() {
          fncGetList()
        },
      })
    }
  }, [dataDelete])

  const fncOnpressView = (data) => {
    if (data.stepType === 1) {
      navigate('/auditoperations/actionplan/form/main', {
        state: {
          refCode: data.stepId,
          actionPlanId,
          typeActionMain,
          type_action: 'view',
          auditTeamCode: data.auditTeamCode,
          projectCode: projectCode,
        },
      })
    } else {
      navigate('/auditoperations/actionplan/form/detail', {
        state: {
          refCode: data.stepId,
          actionPlanId,
          typeActionMain,
          type_action: 'view',
          auditTeamCode: auditTeamCode,
          projectCode: projectCode,
        },
      })
    }
  }

  const fncOnpressEdit = (data) => {
    if (data.stepType === 1) {
      navigate('/auditoperations/actionplan/form/main', {
        state: {
          refCode: data.stepId,
          actionPlanId,
          typeActionMain,
          type_action: 'edit',
          auditTeamCode: data.auditTeamCode,
          projectCode: projectCode,
        },
      })
    } else {
      navigate('/auditoperations/actionplan/form/detail', {
        state: {
          refCode: data.stepId,
          actionPlanId,
          typeActionMain,
          type_action: 'edit',
          auditTeamCode: auditTeamCode,
          projectCode: projectCode,
        },
      })
    }
  }

  const fncOnpressDelete = (data) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ?'),
      onOk() {
        const dataInput = {
          stepId: data.stepId,
        }

        callDelete({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  return (
    <React.Fragment>
      <Row>
        <Col {...Responsive}>
          <Datatable
            columns={Columns({ typeActionMain, fncOnpressView, fncOnpressEdit, fncOnpressDelete, menu })}
            data={dataRow}
            searchCustom={true}
            isLoading={loadingList || loadingDelete}
            // scroll={{ x: 1448 }}
            enableScroll={{ x: true, y: false }}
            btnAdd={
              <>
                {typeActionMain !== 'view' && (
                  <>
                    <ButtonNew
                      style={{ marginRight: 10, marginBottom: 10 }}
                      onClick={() => {
                        setvisibleStep(true)
                      }}
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: menu }}
                    >
                      {t('เพิ่มคลังขั้นตอนปฏิบัติงาน')}
                    </ButtonNew>
                    <ButtonNew
                      style={{ marginRight: 10, marginBottom: 10 }}
                      onClick={() => {
                        navigate('/auditoperations/actionplan/form/main', {
                          state: {
                            refCode: null,
                            actionPlanId,
                            type_action: 'add',
                            typeActionMain,
                            auditTeamCode,
                            projectCode: projectCode,
                          },
                        })
                      }}
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: menu }}
                    >
                      {t('เพิ่มขั้นตอนหลัก')}
                    </ButtonNew>
                    <ButtonNew
                      onClick={() => {
                        navigate('/auditoperations/actionplan/form/detail', {
                          state: {
                            refCode: null,
                            actionPlanId,
                            type_action: 'add',
                            typeActionMain,
                            auditTeamCode,
                            projectCode: projectCode,
                          },
                        })
                      }}
                      type="plusTableBorderPrimary"
                      roles={{ type: 'add', menu: menu }}
                    >
                      {t('เพิ่มขั้นตอนย่อย')}
                    </ButtonNew>
                  </>
                )}
              </>
            }
          />
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="center" style={{ marginBottom: 20, marginTop: 40 }}>
        <Col>
          <ButtonNew onClick={() => navigate(backPath ? backPath : `/auditoperations/actionplan/`)} type="back">
            {t('ย้อนกลับ')}
          </ButtonNew>
        </Col>
      </Row>
      <ModalAddStep
        visible={visibleStep}
        actionPlanId={actionPlanId}
        onClose={() => {
          setvisibleStep(false)
        }}
        onSuccess={() => {
          setvisibleStep(false)
          fncGetList()
        }}
      />
    </React.Fragment>
  )
}

export default ActionPlanFormStepList
