import { gql } from '@apollo/client'

export const GETMASTERPLANSTATUS = gql`
  query getMasterPlanStatus($data: MasterPlanStatusInput!) {
    getMasterPlanStatus(data: $data) {
      planStatusId
      planStatusCode
      planStatusNameTH
      planStatusNameEN
      isActive
    }
  }
`
