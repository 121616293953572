import { gql } from '@apollo/client'
export const GET_LIST_RATE_RISK_RULE_PAGINATION = gql`
  query GetListRateRiskRulePagination($input: RateRiskRulePagination!) {
    getListRateRiskRulePagination(input: $input) {
      result {
        rateFacProCode
        no
        rateRiskRuleCode
        rateRiskRuleTH
        rateRiskRuleEN
        rateFacProNameEN
        rateFacProNameTH
        sort
        isActive
      }
      count
      page
      limit
    }
  }
`
