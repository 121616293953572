import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { CardNew } from '../../../components/card/Card'
import { TabNew } from '../../../components/tab/Tab'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { racMatrixCreateCrumb, racMatrixEditrumb } from './utils/constants'
import { getSegmentUrl } from '../../../utilitys/helper'
import GeneralTab from './components/tab/GeneralTab'
import RiskTab from './components/tab/RiskTab'
import ControlTab from './components/tab/ControlTab'
import DocumentTab from './components/tab/DocumentTab'

const RacMatrixForm = (props) => {
  const responsive = { sm: 24, md: 24, lg: 24 }
  const { menu } = props

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()
  const params = getSegmentUrl(4)

  if (!state) return <Navigate to={'/auditoperations/racmatrix'} />

  const [disabled, setDisabled] = useState(false)

  useEffect(() => {
    if (state?.typeAction === 'add') setDisabled(true)
    if (state?.typeAction === 'edit') setDisabled(false)
  }, [state?.typeAction])

  const tabs = [
    { key: 'general', name: t('ข้อมูลทั่วไป'), disabled: false, content: () => <GeneralTab menu={menu} /> },
    { key: 'risk', name: t('ประเมินความเสี่ยง'), disabled, content: () => <RiskTab menu={menu} /> },
    { key: 'control', name: t('วิธีการควบคุม'), disabled, content: () => <ControlTab menu={menu} /> },
    { key: 'doc', name: t('เอกสารเพิ่มเติม'), disabled, content: () => <DocumentTab menu={menu} /> },
  ]

  return (
    <Col {...responsive}>
      <Breadcrumb
        data={state?.typeAction === 'add' ? racMatrixCreateCrumb : racMatrixEditrumb}
        title={t('รายละเอียดประเมินความเสี่ยงและการควบคุม')}
      />
      <CardNew topic={t('รายละเอียดประเมินความเสี่ยงและการควบคุม')} icon="List" toggledrop={'false'}>
        <TabNew
          tab={tabs}
          activeKey={!params ? 'general' : params}
          onTabClick={(key) => {
            switch (key) {
              case 'general':
                navigate('/auditoperations/racmatrix/tab/general', { state: state })
                break
              case 'risk':
                navigate('/auditoperations/racmatrix/tab/risk', { state: state })
                break
              case 'control':
                navigate('/auditoperations/racmatrix/tab/control', { state: state })
                break
              case 'doc':
                navigate('/auditoperations/racmatrix/tab/doc', { state: state })
                break
              default:
                navigate('/auditoperations/racmatrix')
                break
            }
          }}
        />
      </CardNew>
    </Col>
  )
}

RacMatrixForm.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default RacMatrixForm
