import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Col, Form, Row } from 'antd'

import { ModalNew } from '../../../components/modal/Modal'
import { InputNew } from '../../../components/input/Input'
import { Radio, RadioGroup } from '../../../components/radio/Radio'
import { Alert } from '../../../components/alert/Alert'

import { displayError, regexEng, regexEngNoSpace } from '../../../utilitys/helper'
import { SAVE_OPERATION_SETUP } from './graphql/Mutation'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
// import { SpinnersNew } from '../../../components/spinners/Spinners'
// import { encryptInput } from '../../../utilitys/crypto'

const OperationSetupListModal = ({
  visible = false,
  formType = 'view',
  dataForm = null,
  onSuccess = () => {},
  onClose = () => {},
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [editable, seteditable] = useState(false)

  useEffect(() => {
    if (formType) {
      seteditable(formType !== 'view')
    }
  }, [formType])

  useEffect(() => {
    if (dataForm) {
      form.setFieldsValue(dataForm)
    }
  }, [dataForm])

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_OPERATION_SETUP)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          onSuccess(dataSave.saveOperationSetup.stepModelCode)
        },
      })
    }
  }, [dataSave])

  const onFinish = () => {
    const fields = form.getFieldValue()
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        const dataInput = {
          stepModelCode: fields.stepModelCode,
          stepModelTH: fields.stepModelTH,
          stepModelEN: fields.stepModelEN ? fields.stepModelEN : fields.stepModelTH,
          isActive: fields.isActive,
          typeAction: 'add',
        }

        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const footerHide =
    formType === 'view'
      ? { cancelButtonProps: { style: { display: 'none' } }, okButtonProps: { style: { display: 'none' } } }
      : {}

  const renderTitle = () => {
    if (formType === 'add') {
      return 'เพิ่มคลังขั้นตอนการปฎิบัติงาน'
    }
    if (formType === 'view') {
      return 'ดูคลังขั้นตอนการปฎิบัติงาน'
    }
    if (formType === 'edit') {
      return 'แก้ไขคลังขั้นตอนการปฎิบัติงาน'
    }

    return ''
  }

  return (
    <React.Fragment>
      {loadingSave && <SpinnersNew />}
      <ModalNew
        visible={visible}
        onSubmit={form.submit}
        onClose={(data) => {
          onClose(data)
          form.resetFields()
        }}
        testTitle={t(renderTitle())}
        typeAction={formType}
        type="medium"
        {...footerHide}
      >
        {/* {loadingSave && <SpinnersNew />} */}
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 8,
            lg: 8,
          }}
          layout="horizontal"
          name="riskFactorForm"
        >
          <Form.Item
            name="stepModelCode"
            label={t('รหัสขั้นตอนการปฎิบัติงาน')}
            rules={[
              { required: true, message: t('กรุณาระบุรหัสขั้นตอนการปฎิบัติงาน') },
              {
                pattern: regexEngNoSpace,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษพิมพ์ใหญ่,ตัวเลข A-Z, 0-9 ไม่เกิน 10 ต้ว`),
              },
            ]}
          >
            <InputNew disabled={formType === 'edit' || !editable} placeholder={t('กรอกรหัสขั้นตอนการปฎิบัติงาน')} />
          </Form.Item>

          <Form.Item
            name="stepModelTH"
            label={t('ชื่อขั้นตอนการปฎิบัติงานไทย')}
            rules={[{ required: true, message: t('กรุณาระบุชื่อขั้นตอนการปฎิบัติงานไทย') }]}
          >
            <InputNew disabled={!editable} placeholder={t('กรอกชื่อขั้นตอนการปฎิบัติงานไทย')} />
          </Form.Item>
          <Form.Item
            name="stepModelEN"
            label={t('ชื่อขั้นตอนการปฎิบัติงานอังกฤษ')}
            rules={[{ pattern: regexEng, message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`) }]}
          >
            <InputNew disabled={!editable} placeholder={t('กรอกชื่อขั้นตอนการปฎิบัติงานอังกฤษ')} />
          </Form.Item>

          <Form.Item name="isActive" label={t('สถานะ')} initialValue={1}>
            <RadioGroup disabled={!editable}>
              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                </Col>
                <Col>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
        </Form>
      </ModalNew>
    </React.Fragment>
  )
}
export default OperationSetupListModal
