import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Row, Form, Space } from 'antd'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { InputNew } from '../../../components/input/Input'
import { LabelNew as Label } from '../../../components/label/Label'
import { ButtonNew } from '../../../components/button/Button'
import { Radio, RadioGroup } from '../../../components/radio/Radio'
import { regexEng, displayError } from '../../../utilitys/helper'
import { RISK_FORMULAR_SAVE } from './graphql/Mutation'
import { useMutation } from '@apollo/client'
import { Alert } from '../../../components/alert/Alert'
import { encryptInput } from '../../../utilitys/crypto'
import { SpinnersNew } from '../../../components/spinners/Spinners'

const RiskFormulaCreate = (props) => {
  const formname = 'RiskFormulaCreateForm'
  const [form] = Form.useForm()
  const { state } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { menu } = props
  const [stateCode, setStateCode] = useState()
  const labelCol = { sm: 8, md: 8, lg: { span: 6, offset: 2 }, xl: { span: 6, offset: 2 } }
  const wrapperCol = { sm: 16, md: 16, lg: 10, xl: 10, align: 'left' }
  const Responsive = {
    md: 24,
    lg: 24,
  }
  const arrCrumb = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/riskformula' },
    { label: t('จัดการสูตรประเมินความเสี่ยง'), path: '/administrator/riskformula' },
    { label: t('เพิ่มสูตรประเมินความเสี่ยง') },
  ]

  if (!state) return <Navigate to={'/administrator/riskformula'} />
  /* ---------------- API ----------- */
  const [
    riskFormulaSaveFn,
    { loading: loadingRiskFormulaSave, error: errorRiskFormulaSave, data: dataRiskFormulaSave },
  ] = useMutation(RISK_FORMULAR_SAVE)

  useEffect(() => {
    if (dataRiskFormulaSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/administrator/riskformula/edit', {
            state: { type_action: 'edit', riskFormulaCode: stateCode },
          })
        },
      })
    }
    if (errorRiskFormulaSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorRiskFormulaSave)),
        onOk() {},
      })
    }
  }, [dataRiskFormulaSave])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    showConfirm(input)
  }
  const showConfirm = (input) => {
    setStateCode(input.riskFormulaCode)
    let dataInput = {
      ...input,
      riskFormulaNameEN: input.riskFormulaNameEN ? input.riskFormulaNameEN : '',
      action: 'create',
      isActive: parseInt(input.isActive),
    }
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        riskFormulaSaveFn({
          variables: {
            input: encryptInput(dataInput),
          },
        })
        form.resetFields()
      },
      onCancel() {},
    })
  }

  return (
    <>
      {loadingRiskFormulaSave.loading && <SpinnersNew />}
      <Col {...Responsive}>
        <Breadcrumb data={arrCrumb} title={t('เพิ่มสูตรประเมินความเสี่ยง')} />
        <CardNew topic={t('เพิ่มสูตรประเมินความเสี่ยง')} icon="AlignJustify" toggledrop={'false'}>
          <Form
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            labelAlign="left"
            labelCol={{ ...labelCol }}
            wrapperCol={{ ...wrapperCol }}
            layout="horizontal"
            name={formname}
          >
            <Row gutter={[12, 12]}>
              <Col {...Responsive}>
                <Form.Item
                  name="riskFormulaCode"
                  label={<Label type="tab-header-inactive">{t('รหัสสูตรประเมินความเสี่ยง')}</Label>}
                  rules={[
                    { required: true, message: t('กรุณากรอกรหัสสูตรประเมินความเสี่ยง') },
                    {
                      pattern: regexEng,
                      message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
                    },
                  ]}
                >
                  <InputNew placeholder={t('ระบุรหัสสูตรประเมินความเสี่ยง')} maxLength={10} />
                </Form.Item>
                <Form.Item
                  name="riskFormulaNameTH"
                  label={<Label type="tab-header-inactive">{t('ชื่อสูตรประเมินความเสี่ยงภาษาไทย')}</Label>}
                  rules={[{ required: true, message: t('กรุณากรอกชื่อสูตรประเมินความเสี่ยงภาษาไทย') }]}
                >
                  <InputNew placeholder={t('กรอกชื่อสูตรประเมินความเสี่ยงภาษาไทย')} maxLength={100} />
                </Form.Item>
                <Form.Item
                  name="riskFormulaNameEN"
                  label={<Label type="tab-header-inactive">{t('ชื่อสูตรประเมินความเสี่ยงภาษาอังกฤษ')}</Label>}
                  rules={[
                    {
                      pattern: regexEng,
                      message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
                    },
                  ]}
                >
                  <InputNew placeholder={t('ระบุชื่อสูตรประเมินความเสี่ยงภาษาอังกฤษ')} maxLength={100} />
                </Form.Item>
                <Form.Item
                  label={<Label type="tab-header-inactive">{t('สถานะ')}</Label>}
                  name="isActive"
                  initialValue={1}
                >
                  <RadioGroup>
                    <Space size={[8, 8]} wrap>
                      <Radio value={1}>{<Label type="tab-header-inactive">{t('ใช้งาน')}</Label>}</Radio>
                      &nbsp;
                      <Radio value={0}>{<Label type="tab-header-inactive">{t('ไม่ใช้งาน')}</Label>}</Radio>
                    </Space>
                  </RadioGroup>
                </Form.Item>
              </Col>
              <Col span={24} align="center" style={{ marginTop: '40px' }}>
                <Space size={[8, 8]} wrap>
                  <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                    {t('บันทึก')}
                  </ButtonNew>
                  <ButtonNew type="back" onClick={() => navigate('/administrator/riskformula')}>
                    {t('ยกเลิก')}
                  </ButtonNew>
                </Space>
              </Col>
            </Row>
          </Form>
        </CardNew>
      </Col>
    </>
  )
}
export default RiskFormulaCreate
