import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { CardNew } from '../../../components/card/Card'
import { TabNew } from '../../../components/tab/Tab'
import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { PrepareAuditReportFormCrumb } from './utils/constants'
import { getSegmentUrl } from '../../../utilitys/helper'
import GeneralTab from './components/tab/GeneralTab'
import SummaryAuditResultsTab from './components/tab/SummaryAuditResultsTab'

const PrepareAuditReportForm = (props) => {
  const responsive = { sm: 24, md: 24, lg: 24 }
  const { menu } = props

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()
  const params = getSegmentUrl(4)

  if (!state) return <Navigate to={'/auditoperations/prepareauditreport'} />

  useEffect(() => {
    setTabs(getTabsFn())
  }, [params])

  const getTabsFn = () => {
    return [
      {
        key: 'general',
        name: t('ข้อมูลทั่วไป'),
        disabled: false,
        content: () => <GeneralTab menu={menu} active={params === 'general'} />,
      },
      {
        key: 'summaryauditresults',
        name: t('สรุปผลการตรวจสอบ'),
        disabled: state?.typeAction === 'add',
        content: () => <SummaryAuditResultsTab menu={menu} active={params === 'summaryauditresults'} />,
      },
    ]
  }

  const [tabs, setTabs] = useState(getTabsFn())

  return (
    <Col {...responsive}>
      <BreadcrumbNew data={PrepareAuditReportFormCrumb} title={t('จัดทำรายงานตรวจสอบ')} />
      <CardNew topic={t('รายละเอียดรายงานตรวจสอบ')} icon="List" toggledrop={'false'}>
        <TabNew
          tab={tabs}
          activeKey={!params ? 'general' : params}
          onTabClick={(key) => {
            switch (key) {
              case 'general':
                navigate('/auditoperations/prepareauditreport/tab/general', { state: state })

                break
              case 'summaryauditresults':
                navigate('/auditoperations/prepareauditreport/tab/summaryauditresults', { state: state })

                break
              default:
                navigate('/auditoperations/prepareauditreport')
                break
            }
          }}
        />
      </CardNew>
    </Col>
  )
}

PrepareAuditReportForm.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default PrepareAuditReportForm
