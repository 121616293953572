import React, { useState, useEffect } from 'react'
import { CardNew as Card } from '../../../../../components/card/Card'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import { ButtonNew } from '../../../../../components/button/Button'
// import { InputNew } from '../../../../components/input/Input'
import { Select } from '../../../../../components/select/Select'
import { LabelNew } from '../../../../../components/label/Label'
import { InputNew } from '../../../../../components/input/Input'
import SelectApproveStatus from '../../../../../components/inputfromapi/selectapprovestatus/SelectApproveStatus'
import SelectOrganization from '../../../../../components/inputfromapi/selectorganization/SelectOrganization'
import { GETAPPROVECANCELPROJECTAUDITTEAM } from '../../graphql/Query'
import { useLazyQuery } from '@apollo/client'
import { checkLanguageReturnData } from '../approveCancelProjectfn'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError } from '../../../../../utilitys/helper'

function ApproveCancelProjectFilter(props) {
  const { t } = useTranslation()
  const { menu } = props
  const [form] = Form.useForm()
  const [filters, setFilters] = useState({})
  const formName = 'from-auditnotplan-fillter'
  const Responesive = {
    inputFilter: {
      md: 12,
      lg: 8,
    },
    buttonFilter: {
      md: 24,
      lg: 24,
    },
  }

  const [getAuditTeam, setAuditTeam] = useState([])
  const porjectRef = [
    { label: t('ประเมินความเสี่ยง'), value: 1 },
    { label: t('ตามกฏหมาย'), value: 2 },
    { label: t('หน่วยงานภายนอก'), value: 3 },
  ]

  const onFinish = (values) => {
    setFilters(values)
  }

  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((er) => {
      console.log(er)
    })
  }

  const [getApproveCancelProjectAuditTeamfn, getApproveCancelProjectAuditTeam] = useLazyQuery(
    GETAPPROVECANCELPROJECTAUDITTEAM,
  )

  useEffect(() => {
    getApproveCancelProjectAuditTeamfn()
  }, [])

  useEffect(() => {
    props.getValue(filters)
  }, [filters])

  useEffect(() => {
    if (getApproveCancelProjectAuditTeam.data) {
      setAuditTeam(
        getApproveCancelProjectAuditTeam.data.getApproveCancelProjectAuditTeam.map((dt, key) => {
          return {
            key: key,
            label: dt.auditTeamCode + ' : ' + checkLanguageReturnData(dt.auditTeamNameTH, dt.auditTeamNameEN),
            value: dt.auditTeamCode,
          }
        }),
      )
    }
    if (getApproveCancelProjectAuditTeam.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getApproveCancelProjectAuditTeam.error)),
        onOk() {},
      })
    }
  }, [getApproveCancelProjectAuditTeam.data])

  return (
    <>
      <Card topic={t('Filters')} toggledrop={'false'}>
        <Row style={{ marginTop: 24 }}>
          <Col sm={24} md={24} lg={24}>
            <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} name={formName} layout="vertical">
              <Row gutter={[16, 8]}>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="projectRef"
                    label={<LabelNew type="tab-header-inactive">{t('เลือกแหล่งที่มาของโครงการ')}</LabelNew>}
                    // rules={[{ required: true, message: t('กรุณาระบุปี') }]}
                  >
                    <Select
                      formname={formName}
                      placeholder={t('ระบุแหล่งที่มาของโครงการ')}
                      data={porjectRef}
                      showSearch
                      scrollableId={formName}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="projectCode"
                    rules={[{ required: false, message: 'required' }]}
                    label={<LabelNew type="tab-header-inactive">{t('รหัสโครงการ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุรหัสโครงการ')} />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="projectName"
                    rules={[{ required: false, message: 'required' }]}
                    label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ')}</LabelNew>}
                  >
                    <InputNew placeholder={t('ระบุชื่อโครงการ')} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 8]}>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="projectOrganization2"
                    label={<LabelNew type="tab-header-inactive">{t('หน่วยการตรวจสอบ')}</LabelNew>}
                  >
                    <SelectOrganization
                      formname={formName}
                      placeholder={t('ระบุหน่วยการตรวจสอบ')}
                      scrollableId={formName}
                      showSearch
                      filters={{ checkedList: JSON.stringify([2]), isActive: 1 }}
                      handleChange={(e) => {
                        if (e) {
                          form.setFieldsValue({
                            projectOrganization2: e.value,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item
                    name="projectAuditTeam"
                    label={<LabelNew type="tab-header-inactive">{t('ทีมตรวจสอบ')}</LabelNew>}
                  >
                    <Select
                      formname={formName}
                      placeholder={t('ระบุหน่วยรับตรวจสอบ')}
                      data={getAuditTeam}
                      showSearch
                      scrollableId={formName}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <Form.Item name="cancelStatus" label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}>
                    <SelectApproveStatus
                      formname={formName}
                      placeholder={t('ระบุสถานะ')}
                      scrollableId={formName}
                      showSearch
                      filters={{ isActive: 1, approveStatusCode: 'IN(40,50,60)' }}
                      handleChange={(e) => {
                        if (e) {
                          form.setFieldsValue({
                            cancelStatus: e.value,
                          })
                        } else {
                          form.setFieldsValue({
                            cancelStatus: null,
                          })
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[12, 12]} justify="center">
                <Col>
                  <ButtonNew type="search" menu={menu} htmlType="submit">
                    {t('ค้นหา')}
                  </ButtonNew>
                </Col>
                <Col>
                  <ButtonNew
                    type="clear"
                    menu={menu}
                    onClick={() => {
                      form.resetFields()
                      setFilters({})
                    }}
                  >
                    {t('ล้างค่า')}
                  </ButtonNew>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Card>
    </>
  )
}

export default ApproveCancelProjectFilter
