import { gql } from '@apollo/client'

export const CREATE_SETUP_RUNNING_NUMBER = gql`
  mutation SetupRunningNumberCreate($input: SetupRunningNumberFormInput!) {
    setupRunningNumberCreate(input: $input) {
      numberSeriesId
    }
  }
`

export const DELETE_SETUP_RUNNING_NUMBER = gql`
  mutation SetupRunningNumberDelete($numberSeriesId: String!) {
    setupRunningNumberDelete(numberSeriesId: $numberSeriesId) {
      numberSeriesId
    }
  }
`
