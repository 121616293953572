function analyzeColor(hex) {
  // Convert hex to RGB
  const r = parseInt(hex.slice(1, 3), 16) / 255
  const g = parseInt(hex.slice(3, 5), 16) / 255
  const b = parseInt(hex.slice(5, 7), 16) / 255

  // Get min and max values
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)

  // Calculate HSL
  let h,
    s,
    l = (max + min) / 2

  if (max === min) {
    h = s = 0
  } else {
    const d = max - min
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min)

    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0)
        break
      case g:
        h = (b - r) / d + 2
        break
      case b:
        h = (r - g) / d + 4
        break
    }
    h /= 6
  }

  return { h, s, l }
}

export function generateProfessionalColors(count) {
  // Analyze reference colors to get their characteristics
  const referenceColors = ['#9bbb59', '#c0504d', '#4f81bd']
  const analyses = referenceColors.map((color) => analyzeColor(color))

  // Calculate average saturation and lightness
  const avgSaturation = analyses.reduce((sum, a) => sum + a.s, 0) / analyses.length
  const avgLightness = analyses.reduce((sum, a) => sum + a.l, 0) / analyses.length

  // Set ranges based on reference colors
  const saturationRange = {
    min: avgSaturation - 0.1,
    max: avgSaturation + 0.1
  }

  const lightnessRange = {
    min: avgLightness - 0.1,
    max: avgLightness + 0.1
  }

  const colors = []
  const goldenRatio = 0.618033988749895
  let hue = Math.random()

  for (let i = 0; i < count; i++) {
    // Use golden ratio for even hue distribution
    hue += goldenRatio
    hue %= 1

    // Use narrower ranges for saturation and lightness to match reference colors
    const saturation = Math.random() * (saturationRange.max - saturationRange.min) + saturationRange.min

    const lightness = Math.random() * (lightnessRange.max - lightnessRange.min) + lightnessRange.min

    // Convert to RGB and then hex
    const rgb = hslToRgb(hue, saturation, lightness)
    const hex = rgbToHex(rgb[0], rgb[1], rgb[2])

    colors.push(hex)
  }

  return colors
}

// Helper function to convert HSL to RGB (same as before)
function hslToRgb(h, s, l) {
  let r, g, b

  if (s === 0) {
    r = g = b = l
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1
      if (t > 1) t -= 1
      if (t < 1 / 6) return p + (q - p) * 6 * t
      if (t < 1 / 2) return q
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6
      return p
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s
    const p = 2 * l - q

    r = hue2rgb(p, q, h + 1 / 3)
    g = hue2rgb(p, q, h)
    b = hue2rgb(p, q, h - 1 / 3)
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)]
}

// Helper function to convert RGB to hex (same as before)
function rgbToHex(r, g, b) {
  const toHex = (n) => {
    const hex = n.toString(16)
    return hex.length === 1 ? '0' + hex : hex
  }

  return '#' + toHex(r) + toHex(g) + toHex(b)
}
