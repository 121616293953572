import { LabelNew } from '../../../../../components/label/Label'
import { Trans } from 'react-i18next'

export const Columns = () => {
  return [
    {
      title: <Trans>ชื่อโครงการ</Trans>,
      dataIndex: 'projectName',
      key: 'projectName',
      align: 'left',
      width: '350px',
      render: (item) => {
        return <LabelNew type="body">{item}</LabelNew>
      },
    },
    {
      title: <Trans>ประเด็นที่ตรวจพบ</Trans>,
      dataIndex: 'countIssue',
      key: 'countIssue',
      className: 'text-nowrap',
      width: '200px',
      align: 'center',
      render: (item) => {
        return <LabelNew type="body">{item}</LabelNew>
      },
    },
    {
      title: <Trans>อยู่ระหว่างดำเนินการ</Trans>,
      dataIndex: 'countIssueNot60',
      key: 'countIssueNot60',
      className: 'text-nowrap',
      align: 'center',
      width: '200px',
      render: (item) => {
        return <LabelNew type="body">{item}</LabelNew>
      },
    },
    {
      title: <Trans>ดำเนินการแล้วเสร็จ</Trans>,
      dataIndex: 'countIssue60',
      key: 'countIssue60',
      className: 'text-nowrap',
      align: 'center',
      width: '200px',
      render: (item) => {
        return <LabelNew type="body">{item}</LabelNew>
      },
    },

    {
      title: <Trans>หน่วยรับตรวจ</Trans>,
      dataIndex: 'countOrganizations',
      key: 'countOrganizations',
      align: 'center',
      className: 'text-nowrap',
      width: '200px',
      render: (item) => {
        return <LabelNew type="body">{item}</LabelNew>
      },
    },
    {
      title: <Trans>วันที่เริ่มต้น</Trans>,
      dataIndex: 'dateStart',
      key: 'dateStart',
      className: 'text-nowrap',
      width: '100px',
      render: (item) => {
        return <LabelNew type="body">{item}</LabelNew>
      },
    },
    {
      title: <Trans>วันที่สิ้นสุด</Trans>,
      dataIndex: 'dateEnd',
      key: 'dateEnd',
      className: 'text-nowrap',
      width: '100px',
      render: (item) => {
        return <LabelNew type="body">{item}</LabelNew>
      },
    },
  ]
}
