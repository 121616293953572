import { gql } from '@apollo/client'

export const GET_MASTER_PERSONNEL_TYPE = gql`
  query getMasterPersonnelType($input: MasterPersonnelTypeInput!) {
    getMasterPersonnelType(data: $input) {
      PersonnelTypeId
      PersonnelTypeCode
      PersonnelTypeNameTH
      PersonnelTypeNameEN
      isActive
    }
  }
`
