import styled from 'styled-components'
import { colors } from '../../configs/styles.config'

const TabStyle = styled.div`
  .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
    background-color: ${colors.primary} !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${colors.primary} !important;
  }

  .ant-tabs {
    overflow: unset;
  }
`
export default TabStyle
