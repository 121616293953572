import { gql } from '@apollo/client'

export const GET_SERACH_SETUP_REPORT_SUB_MISSIONS_LIST = gql`
  query getSerachSetupReportSubMissionsList($input: SetupReportSubMissionsPagenationInput!) {
    getSerachSetupReportSubMissionsList(input: $input) {
      result {
        no
        setMailReportId
        sendStatus
        sendId
        menuNameTH
        menuNameEN
        sendDate
        cancelStatus
      }
      limit
      page
      count
    }
  }
`
export const GET_SETUP_REPORT_SUB_MISSIONS_BY_ID = gql`
  query getSetupReportSubMissionsById($input: SetupReportSubMissionsInput!) {
    getSetupReportSubMissionsById(input: $input) {
      setMailReportId
      sendDate
      sendId
      subject
      detail
      files
      menuNameTH
      menuNameEN
      mailToTH
      mailToEN
      mailCopyTH
      mailCopyEN
      sendStatus
    }
  }
`
export const EXPORT_RISK_FORMS = gql`
  query exportRiskForms($input: ExportsRiskFormInput!) {
    exportRiskForms(input: $input) {
      message
      fileUrl
    }
  }
`
export const EXPORT_AUDIT_STATUS = gql`
  query exportAuditStatus($input: ExportsAuditStatusInput!) {
    exportAuditStatus(input: $input) {
      message
      fileUrl
    }
  }
`
