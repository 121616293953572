import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_GENDERS } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectGenders = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { genderId, genderCode, genderTH, genderEN, isActive } = filters || {}

  const [isData, isSetData] = useState([])

  const [getMasterGendersFn, getMasterGenders] = useLazyQuery(GET_MASTER_GENDERS)

  useEffect(() => {
    getMasterGendersFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [genderId, genderCode, genderTH, genderEN, isActive])

  useEffect(() => {
    if (getMasterGenders.data) {
      isSetData(
        _.map(getMasterGenders?.data?.getMasterGenders, (item) => {
          return {
            label: displayText(item?.genderTH, item?.genderEN),
            value: item?.genderCode,
            source: item,
          }
        }),
      )
    }
  }, [getMasterGenders.data])
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterGenders.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectGenders
