import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { Select } from '../../select/Select'
import { useLanguage } from '../../../hooks/useLanguage'
import AppConfigs from '../../../configs/app.config'

const SelectYear = (props) => {
  const { placeholder, formname, handleChange, maxYear, ...otherProp } = props

  const [isLang] = useLanguage()

  let [isData, isSetData] = useState([])

  useEffect(() => {
    if (isData.length === 0) {
      let currentYear = new Date().getFullYear()
      let newYear = []
      for (let i = currentYear + (maxYear ? maxYear : 10); i >= AppConfigs.systemStartYear; i--) {
        const isData_ = isLang === 'th' ? i + 543 : i
        newYear.push({ key: i, value: i, label: `${isData_}` })
      }
      isSetData(newYear)
    }
  }, [isData])

  useEffect(() => {
    if (isData.length) {
      isSetData([])
    }
  }, [isLang])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectYear
