import { gql } from '@apollo/client'

export const GET_MASTER_CERTIFICATES = gql`
  query getMasterCertificates($input: MasterCertificatesInput!) {
    getMasterCertificates(data: $input) {
      certificateId
      certificateCode
      certificateNameTH
      certificateNameEN
      certificateShotTH
      certificateShotEN
      certificateOrganization
      isActive
    }
  }
`
