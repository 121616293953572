import { gql } from '@apollo/client'
// For RiskFormFirstStep
export const GET_RISK_FORM_BY_ID = gql`
  query getRiskById($input: GetRiskFormInput!) {
    getRiskById(input: $input) {
      riskFormId
      riskFormCode
      riskFormTH
      riskFormEN
      Year
      riskModelCode
      riskFormType
      oganizationAudit
      dateStart
      dateEnd
      operationStatusCode
      operationStatusNameTH
      operationStatusNameEN
      isActive
      riskFormOrganizationList {
        riskFormCode
        organizationCode
        organizationNameTH
        organizationNameEN
        organizationLevelNameTH
        organizationLevelNameEN
        personnelCode
        createdAt
        createdBy
        personnelNameTH
        personnelNameEN
      }
    }
  }
`

// For RiskFormSecondStep
export const GET_RISK_FORM_DETAIL = gql`
  query getRiskFormDetail($input: GetRiskFormInput!) {
    getRiskFormDetail(input: $input) {
      riskFactorCode
      riskFactorNameTH
      riskFactorNameEN
      factorActCode
      factorActNameTH
      factorActNameEN
      factorActRiskCode
      factorActRiskNameTH
      factorActRiskNameEN
      remark
      isActive
      createdAt
      createdBy
    }
  }
`

// For RiskFormFirstStepModalFilter.jsx
export const GET_SELECT_MASTER_ORGANIZATION_LEVEL = gql`
  query {
    getSelectMasterOrganizationLevel {
      organizationLevelCode
      organizationLevelNameTH
      organizationLevelNameEN
    }
  }
`
export const GET_MASTER_ORGANIZATIONS_FILTERS = gql`
  query getMasterOrganizationsFilters($input: MasterOrganizationsInput!) {
    getMasterOrganizationsFilters(input: $input) {
      organizationId
      organizationCode
      organizationNameTH
      organizationNameEN
      createdAt
      createdBy
      organizationLevelNameTH
      organizationLevelNameEN
      organizationLevelCode
      personnelCode
      personnelNameTH
      personnelNameEN
    }
  }
`
// For RiskList
export const GET_RISK_FORM_FILTERS = gql`
  query getRiskFormFilters($input: RiskFormListPagenation!) {
    getRiskFormFilters(input: $input) {
      result {
        Year
        riskFormId
        riskFormCode
        riskFormTH
        riskFormEN
        riskFormType
        dateStart
        dateEnd
        oganizationAudit
        operationStatusCode
        operationStatusNameTH
        operationStatusNameEN
      }
      count
      page
      limit
    }
  }
`
export const GET_EMAIL_ORGANIZATION_BY_FORM = gql`
  query getEmailOrganizationByForm($riskFormCode: String!) {
    getEmailOrganizationByForm(riskFormCode: $riskFormCode) {
      email
      personnelCode
      organizationCode
      organizationNameTH
      firstNameTH
      lastNameTH
    }
  }
`
