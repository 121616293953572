import { gql } from '@apollo/client'

export const GET_CONTROL_FORM_PAGINATION = gql`
  query getControlFormPagination($input: ListControlFomsInput!) {
    getControlFormPagination(input: $input) {
      result {
        controlFormCode
        controlFormId
        controlFormTH
        controlFormEN
        operationStatusCode
        operationStatusNameTH
        operationStatusNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_CONTROL_RESULT_PAGINATION = gql`
  query getControlResultPagination($input: ListControlResultnput!) {
    getControlResultPagination(input: $input) {
      result {
        controlResultId
        controlFormCode
        organizationCode
        operationStatusCode
        responsibilityBy
        makerBy
        makerStatusCode
        controlFormYear
        controlFormTH
        controlFormEN
        dateEnd
      }
      count
      page
      limit
    }
  }
`

export const GET_CONTROL_RESULTS = gql`
  query getControlResults($input: ListResults!) {
    getControlResults(input: $input) {
      controlResultId
      controlFormCode
      controlFormYear
      controlFormEN
      controlFormTH
      organizationCode
      organizationNameEN
      organizationNameTH
      operationStatusCode
      responsibilityBy
      makerBy
      succeedAt
      dateStart
      dateEnd
      p1_fullNameTH
      p1_fullNameEN
      p2_fullNameTH
      p2_fullNameEN
      operationStatusNameTH
      operationStatusNameEN
    }
  }
`

export const GET_CONTROL_RESULT_ID = gql`
  query getControlResultById($controlResultId: String!) {
    getControlResultById(controlResultId: $controlResultId) {
      controlResultId
      controlFormCode
      organizationCode
      organizationNameEN
      organizationNameTH
      controlResultRef
      operationStatusCode
      responsibilityBy
      makerBy
      makerStatusCode
      succeedAt
      succeedBy
      reviewAt
      reviewBy
      controlFormYear
      controlFormEN
      controlFormTH
      dateStart
      dateEnd
      n1_namePrefixTH
      n1_namePrefixEN
      n1_namePrefixCode
      p1_firstNameTH
      p1_lastNameTH
      p1_firstNameEN
      p1_lastNameEN
      n2_namePrefixTH
      n2_namePrefixEN
      n2_namePrefixCode
      p2_firstNameTH
      p2_lastNameTH
      p2_firstNameEN
      p2_lastNameEN
    }
  }
`

export const GET_CONTROL_FORM_CONF = gql`
  query getControlFormConfigurations($controlFormCode: String!) {
    getControlFormConfigurations(controlFormCode: $controlFormCode) {
      formConfigId
      formConfigNo
      formConfigTH
      formConfigEN
      controlFormCode
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`

export const GET_CONTROL_FORM_QUESTION = gql`
  query getControlFormQuestions($input: ControlQuestionsInput!) {
    getControlFormQuestions(input: $input) {
      questTH
      questEN
      formConfigId
      choiceGroupCode
      questDetailType
      resultDetailId
      controlResultId
      questId
      choiceTypeCode
      questRequired
      choiceDetailId
      answerDetail
      files
      fileNameLabel
      fileNameGen
      fileType
      fileByte
      filepath
    }
  }
`

export const GET_MASTER_CHOICE_GROUP_DETAILS = gql`
  query getMasterChoiceGroupDetails($input: MasterChoiceGroupDetailsInput!) {
    getMasterChoiceGroupDetails(data: $input) {
      choiceDetailId
      choiceDetailNameTH
      choiceDetailNameEN
      choiceGroupCode
      score
      isActive
    }
  }
`

export const EXPORT_CONTROL_RESULT = gql`
  query exportControlResult($input: ExportInput!) {
    exportControlResult(input: $input) {
      message
      fileUrl
    }
  }
`

export const EXPORT_CONTROL_RESULTS = gql`
  query exportControlResults($input: ExportInput!) {
    exportControlResults(input: $input) {
      message
      fileUrl
    }
  }
`
