import React from 'react'
import { Row, Col } from 'antd'
import RiskList from './RiskList'
const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}
const RiskIndex = () => {
  return (
    <>
      <Row>
        <Col {...Responsive}>
          <RiskList menu={'RA01'} />
        </Col>
      </Row>
    </>
  )
}

export default RiskIndex
