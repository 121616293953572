import { Trans } from 'react-i18next'
export const Columns = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'key',
    width: '80px',
    align: 'center',
  },
  {
    title: <Trans>เลขที่งาน</Trans>,
    dataIndex: 'jobNumber',
    width: '150px',
    sorter: { multiple: 1 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>ชื่องาน</Trans>,
    dataIndex: 'jobSubject',
    width: '300px',
    sorter: { multiple: 2 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>ประเภทงาน</Trans>,
    dataIndex: 'jobTypeName',
    width: '180px',
    sorter: { multiple: 3 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>หน่วยงานที่รับผิดชอบ</Trans>,
    dataIndex: 'responsibleOrganizationName',
    width: '300px',
    sorter: { multiple: 4 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>หน่วยงานที่ร้องขอ</Trans>,
    dataIndex: 'requestOrganizationName',
    width: '300px',
    sorter: { multiple: 5 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>ผู้ร้องขอ</Trans>,
    dataIndex: 'requestPersonnel',
    width: '300px',
    sorter: { multiple: 6 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>ผู้รับผิดชอบ</Trans>,
    dataIndex: 'assignJobPersonnels',
    width: '300px',
    sorter: { multiple: 7 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>ระยะเวลาดำเนินการ</Trans>,
    dataIndex: 'durationJob',
    width: '200px',
    sorter: { multiple: 8 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>สถานะดำเนินการ</Trans>,
    dataIndex: 'jobStatusName',
    width: '150px',
    sorter: { multiple: 9 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>สถานะการอนุมัติ</Trans>,
    dataIndex: 'approveStatusName',
    width: '150px',
    className: 'text-nowrap',
    sorter: { multiple: 10 },
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '100px',
    // className: 'text-nowrap',
  },
]
