import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useQuery } from '@apollo/client'
import { SELECT_ROLES } from './graphql/Query'
import { Select } from '../../select/Select'
import { displayText } from '../../../utilitys/helper'

const SelectRoles = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props

  const [isData, isSetData] = useState([])

  const getRolesSelect = useQuery(SELECT_ROLES, {
    fetchPolicy: 'no-cache',
    variables: { input: filters ? { ...filters } : {} },
  })

  useEffect(() => {
    if (getRolesSelect.data) {
      isSetData(
        _.map(getRolesSelect?.data?.rolesSelect, (item) => {
          return {
            label: displayText(item?.roleNameTH, item?.roleNameEN),
            value: item?.roleCode,
            source: item,
          }
        }),
      )
    }
  }, [getRolesSelect.data])
  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        multiple
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getRolesSelect.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          handleChange(value)
        }}
      />
    </>
  )
}

export default SelectRoles
