/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { Input, InputNumber, Select } from 'antd'
import { InputStyle, InputTagStyle } from './InputStyles'
import _ from 'lodash'

export const InputNew = (props) => {
  return (
    <>
      <InputStyle>
        {props.hide === 'true' ? (
          <Input.Password {...props} className={props.shape ? props.shape : 'form'}>
            {props.children}
          </Input.Password>
        ) : (
          <Input {...props} className={props.shape ? props.shape : 'form'}>
            {props.children}
          </Input>
        )}
      </InputStyle>
    </>
  )
}

export const InputNumberNew = (props) => {
  return (
    <>
      <InputStyle>
        <InputNumber
          {...props}
          className={props.align}
          keyboard={false}
          controls={false}
          stringMode
          onKeyPress={(event) => {
            if (!/^[0-9.,\b]+$/.test(event.key)) {
              event.preventDefault()
            }
            // if (props.maxLength) {
            //   if (event.target.value.length > parseInt(props.maxLength) - 1) {
            //     // event.preventDefault()
            //   }
            // }
          }}
        >
          {props.children}
        </InputNumber>
      </InputStyle>
    </>
  )
}

export const InputTagNew = (props) => {
  const { defaults, handleChange, formname, data, disableFreeKey, ...otherProp } = props
  const { Option } = Select

  // const [dataOption, setDataOption] = useState([])
  // useEffect(() => {
  //   const options = []
  //   if (data && !_.isEmpty(data)) {
  //     data.forEach((item, index) => {
  //       options.push(<Option key={item + '' + index}>{item}</Option>)
  //     })
  //     setDataOption(options)
  //   }
  // }, [data])

  return (
    <InputTagStyle>
      <Select
        {...otherProp}
        mode={disableFreeKey ? 'multiple' : 'tags'}
        onChange={handleChange}
        getPopupContainer={() => document.getElementById(formname)}
        options={data}
      >
        {/* {dataOption} */}
      </Select>
    </InputTagStyle>
  )
}
