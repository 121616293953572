import { Trans } from 'react-i18next'

export const importHistoricalDataInfoCrumb = [
  { label: <Trans>งานปฎิบัติการตรวจสอบ</Trans>, path: '/auditoperations/importhistoricaldata' },
  { label: <Trans>นำเข้าข้อมูลย้อนหลัง</Trans> },
]

export const initalFieldPlanAndProject = {
  auditPlans_planCode: 'รหัสแผนการตรวจสอบ',
  auditPlans_planNameTH: 'ชื่อแผนการตรวจสอบ',
  auditPlans_planType: 'ประเภทแผนการตรวจสอบ',
  auditPlans_planYear: 'ประจำปี',
  auditPlans_dateStart: 'วันที่เริ่มต้น',
  auditPlans_dateEnd: 'วันที่สิ้นสุด',
  auditProjects_projectCode: 'รหัสโครงการ',
  auditProjects_projectNameTH: 'ชื่อโครงการ',
  auditProjects_projectTypeCode: 'ประเภทโครงการ',
  auditProjects_projectRefCode: 'แหล่งที่มาของโครงการ',
  auditProjects_auditTypeCode: 'ประเภทการตรวจสอบ',
  auditProjects_approvePersonnelCode: 'ผู้อนุมัติแผนการตรวจสอบ',
  auditProjectOrganizations_organizationCode: 'หน่วยงานรับตรวจ',
  auditProjects_organizationCode: 'หน่วยงานที่รับผิดชอบ',
  auditProjects_auditTeamCode: 'ทีมตรวจสอบ',
  auditProjects_personnelCode: 'ผู้รับผิดชอบหลัก',
  auditProjects_projectSizeCode: 'ขนาดโครงการ',
  auditProjects_manday: 'จำนวนวันทำงาน',
  auditProjects_numberOfPeople: 'จำนวนคน',
  auditProjects_mandayPersonnal: 'จำนวนวันทำงานต่อคน',
  auditProjects_dateStart: 'วันที่เริ่มต้น',
  auditProjects_dateEnd: 'วันที่สิ้นสุด',
}

export const initialFieldReportExamine = {
  auditFinalReport_projectCode: 'รหัสโครงการ',
  auditFinalReport_documentNo: 'เลขที่ตรวจสอบ',
  auditFinalReport_startDateReport: 'วันที่เริ่มต้นรายงาน',
  auditFinalReport_endDateReport: 'วันที่สิ้นสุดรายงาน',
  auditFinalReportDetail_issue: 'ข้อสังเกต',
  auditFinalReportDetail_rules: 'เก็บข้อมูลเกณฑ์ ระเบียบ คำสั่ง ที่เกี่ยวข้อง',
  auditFinalReportDetail_fact: 'ข้อเท็จจริง',
  auditFinalReportDetail_cause: 'สาเหตุ',
  auditFinalReportDetail_risk: 'ความเสี่ยง',
  auditFinalReportDetail_control: 'การควบคุมอื่นๆ ที่ทดแทน',
  auditFinalReportDetail_summaryTest: 'สรุปผลตรวจสอบ',
  auditFinalReportDetail_feedback: 'ข้อเสนอแนะ',
  auditFinalReportDetail_conlusionActionExecutive: 'สรุปผลการดำเนินการผู้บริหาร',
  auditFinalReportDetail_resultAssessmentRemark: 'ผลของการประเมินจากข้อสังเกต',
}

export const initialAuditCommitteeSummary = {
  auditResolution_resDate: 'วันที่ประชุม AC',
  auditResolution_resTimes: 'ครั้งที่ประชุม AC',
  auditResolutionAC_projectCode: 'รหัสโครงการ',
  auditResolutionAC_agendaNameTH: 'ชื่อวาระ',
  auditResolutionAC_considerCode: 'เพื่อพิจารณา / เพื่อทราบ',
  auditResolutionAC_organizationCode: 'หน่วยงานที่รับผิดชอบ',
  auditResolutionOrganizations_organizationCode: 'หน่วยรับตรวจ',
  auditIssues_rateFacProCode: 'รหัสปัจจัยความเสี่ยง',
  auditIssues_rateRiskRuleCode: 'รหัสเกณฑ์',
  auditIssues_rateRiskConCode: 'รหัสวิธีการควบคุม',
  auditIssues_factTH: 'ข้อเท็จจริง',
  auditIssues_issueGroupType: 'กลุ่มข้อสังเกต',
  auditIssues_issueTH: 'ข้อสังเกต',
  auditIssues_findingTypeCode: 'ประเภทของข้อสังเกตุที่ตรวจพบ',
  auditIssues_ISOCode: 'ความสอดคล้อง',
  auditIssues_ISOSubCode: 'ความสอดคล้องย่อย',
  auditIssues_riskLevelCode: 'ระดับความเสี่ยง',
  auditIssues_riskTH: 'ความเสี่ยง',
  auditIssues_effectTH: 'ผลกระทบ',
  auditIssuePersonnels_personnelCode: 'ผู้รับผิดชอบข้อสังเกต',
  auditCauseOfIssues_causeCode: 'สาเหตุ',
  auditCauseOfIssues_causeDetailTH: 'รายละเอียดของสาเหตุ',
  auditSuggestOfIssues_suggestGroupType: 'กลุ่มข้อเสนอแนะ',
  auditSuggestOfIssues_suggestTH: 'ข้อเสนอแนะ',
  auditSuggestOfIssues_dateExpected: 'วันที่คาดว่าจะดำเนินการ',
  auditSuggestOfIssues_personnelCode: 'ผู้รับผิดชอบข้อเสนอแนะ',
  auditSuggestOrganizations_organizationCode: 'หน่วยงานรับตรวจ',
  followUp_mdNumber: 'เลขที่ ผจ.ลบ',
  followUp_dateSendReport: 'วันที่ส่งรายงานให้หน่วยรับตรวจ ( MD สั่งการ)',
  followUpActivity_activityTH: 'กิจกรรมที่ต้องดำเนินการภาษาไทย',
  followUpActivity_expectDate: 'วันที่คาดว่าจะเสร็จ',
  followUpActivity_organizationCode: 'หน่วยรับตรวจ',
  followUpActivity_personnelCode: 'ผู้รับผิดชอบกิจกรรม',
  followUpActions_actionDetailTH: 'รายละเอียดการดำเนินการ',
  followUpActions_actionDate: 'วันที่ดำเนินการ',
  followUpActions_planDetailTH: 'รายละเอียแผนการดำเนินการ',
  followUpActions_organizationCode: 'หน่วยรับตรวจ',
  followUpActions_personnelCode: 'ผู้รับผิดชอบ',
  followUpActions_ideaStatusCode: 'สถานะของความเห็นชอบในการติดตาม',
  followUpActions_idePersonnelCode: 'ผู้ให้ความเห็นชอบ',
  followUpActions_actionStatusCode: 'สถานะของการดำเนินการ',
  auditIssues_issueStatusCode: 'สถานะประเด็น/ข้อสังเกต',
}
