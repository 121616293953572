import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_ORGANIZATION_SIZE } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectOrganizationSize = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { isActive } = filters || {}

  const [isData, isSetData] = useState([])

  const [getMasterOrganizationSizeFn, getMasterOrganizationSize] = useLazyQuery(GET_ORGANIZATION_SIZE)

  useEffect(() => {
    getMasterOrganizationSizeFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [isActive])

  useEffect(() => {
    if (getMasterOrganizationSize.data) {
      isSetData(
        getMasterOrganizationSize?.data?.getMasterOrganizationSize.map((v) => ({
          value: v.organizationSizeCode,
          label: displayText(v?.organizationSizeNameTH, v?.organizationSizeNameEN),
        })),
      )
    }
  }, [getMasterOrganizationSize?.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterOrganizationSize.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectOrganizationSize
