import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_AUDITTEAM } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectAuditTeams = (props) => {
  const { filters, placeholder, formname, handleChange = () => {}, ...otherProp } = props
  const { isActive } = filters || {}

  const [isData, isSetData] = useState([])

  const [getAuditTeamsFn, getAuditTeams] = useLazyQuery(GET_AUDITTEAM)

  useEffect(() => {
    getAuditTeamsFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
  }, [isActive])

  useEffect(() => {
    if (getAuditTeams.data) {
      isSetData(
        _.map(getAuditTeams?.data?.getAuditTeams, (item) => {
          return {
            value: item.auditTeamCode,
            label: item.auditTeamCode + ' : ' + displayText(item.auditTeamNameTH, item.auditTeamNameEN),
            source: item,
          }
        }),
      )
    }
  }, [getAuditTeams.data])

  const setOnChange = props.multiple
    ? {
        onChange: (value) => {
          handleChange(value)
        },
      }
    : { onChange: (value) => handleChange(_.find(isData, (data) => data.value === value)) }

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getAuditTeams.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        {...setOnChange}
      />
    </>
  )
}

export default SelectAuditTeams
