import { gql } from '@apollo/client'

export const SAVE_RISK_FACTOR = gql`
  mutation SaveRiskFactor($input: RiskFactorSaveInput!) {
    saveRiskFactor(input: $input) {
      riskFactorCode
    }
  }
`

export const DELETE_RISK_FACTOR = gql`
  mutation DeleteRiskFactor($input: RiskFactorDeleteInput!) {
    deleteRiskFactor(input: $input) {
      message
    }
  }
`
