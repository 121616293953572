import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'key',
    width: '8%',
    className: 'text-nowrap',
  },
  {
    title: <Trans>สูตรประเมินความเสี่ยง</Trans>,
    dataIndex: 'riskFormulaName',
    width: '50%',
    className: 'text-nowrap',
    sorter: { multiple: 1 },
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActive',
    width: '20%',
    className: 'text-nowrap',
    sorter: { multiple: 2 },
  },
  {
    // title: <Trans>รายละเอียด</Trans>,
    dataIndex: 'tools',
    width: '15%',
    className: 'text-nowrap',
  },
]
