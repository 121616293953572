import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import moment from 'moment'
import { CardNew } from '../../../../../components/card/Card'
import { InputNew } from '../../../../../components/input/Input'
import { ButtonNew } from '../../../../../components/button/Button'
import { LabelNew } from '../../../../../components/label/Label'
import { useLanguage } from '../../../../../hooks/useLanguage'
import SelectYear from '../../../../../components/inputfromapi/selectyear/SelectYear'
import ExportModalList from '../modal/ExportModalList'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { useGetExport } from '../../hooks/useGetExport'
import { GET_EXPORT_RISK_LEVEL_FIVE_YEAR_REPORT } from '../../graphql/Query'
import { useLazyQuery } from '@apollo/client'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError } from '../../../../../utilitys/helper'
import { encryptInput } from '../../../../../utilitys/crypto'
import SendEmailReportModal from '../../../components/SendEmailReportModal'

const RiskLevelFiveYearReportFilter = (props) => {
  const Responsive = { md: 8, lg: 8 }
  const { onFilter, menu } = props
  const [isLang] = useLanguage()
  const formname = 'RiskLevelFiveYearReportFilter'

  const [modalExport, setModalExport] = useState(false)
  const [filter, setFilter] = useState({})

  const [form] = Form.useForm()
  const { t } = useTranslation()

  const valiteYear = (data) => {
    let pass = false
    const { yearEnd, yearStart } = data

    if (yearEnd >= yearStart) {
      const yearDifference = Math.abs(yearEnd - yearStart)
      if (yearDifference <= 5) {
        pass = true
        setErrorField('yearStart', false)
        setErrorField('yearEnd', false)
      } else {
        setErrorField('yearStart', t('เลือกห่างกันไม่เกินใน 5 ปี'))
        setErrorField('yearEnd', t('เลือกห่างกันไม่เกินใน 5 ปี'))
      }
    } else {
      setErrorField('yearStart', t('กรุณาเลือกให้เท่ากับหรือน้อยกว่าปีสิ้นสุดไม่เกิน 5 ปี'))
      setErrorField('yearEnd', t('กรุณาเลือกให้เท่ากับหรือมากกว่าปีเริ่มต้นไม่เกิน 5 ปี'))
    }

    return pass
  }

  function setErrorField(name, errors) {
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  const onFinish = (data) => {
    if (!valiteYear(data)) return

    onFilter({ ...data, lang: isLang })
    setFilter({ ...data })
  }
  const onClear = () => Promise.all([form.resetFields(), onFilter({})])

  const [hooksOptions] = useState()
  //========================== API PAGINATION ======================================
  const [exportResponse, exportLoading] = useGetExport(hooksOptions)

  useEffect(() => {}, [exportResponse])

  //=========================== สำหรับการส่งเมลรายงาน ===========================

  const [exportReportMail, dataExportReportMail] = useLazyQuery(GET_EXPORT_RISK_LEVEL_FIVE_YEAR_REPORT)

  useEffect(() => {
    if (dataExportReportMail.data) {
      Alert({
        type: 'success',
        title: t('ส่งอีเมลสำเร็จ'),
        onOk() {},
      })
    }
    if (dataExportReportMail.error) {
      const err = dataExportReportMail.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [dataExportReportMail?.data])

  //=========================== Email Modal ===========================
  const [emailModalVisible, setemailModalVisible] = useState(false)
  const [mailValue, setMailValue] = useState()
  const [inputSendEmail, setInputSendEmail] = useState()

  useEffect(() => {
    if (inputSendEmail) {
      const dataInput = {
        filters: filter,
        lang: localStorage.getItem('lang'),
        fileType: inputSendEmail.fileType,
        emailData: inputSendEmail, // สำหรับการส่งเมลรายงาน
      }

      // Call API Export
      exportReportMail({
        variables: {
          input: encryptInput(dataInput),
        },
      })
    }
  }, [inputSendEmail])

  const fncOnpressEmailSetting = () => {
    setMailValue({ reportName: 'รายงานผลประเมินความเสี่ยงแผน 5 ปี', refCode: null })
    setemailModalVisible(true)
  }

  //=========================== =========================== ====

  return (
    <CardNew topic={t('Filters')} icon="List" toggledrop="false">
      <ExportModalList
        filterData={filter}
        visible={modalExport}
        onModalClose={() => {
          setModalExport(false)
        }}
      />
      {(exportLoading.loading || dataExportReportMail.loading) && <SpinnersNew />}
      <SendEmailReportModal
        menu={menu}
        open={emailModalVisible}
        close={(e) => {
          setemailModalVisible(e)
        }}
        fileType={{ pdf: false, word: false, excel: true, csv: true }}
        setInputSendEmail={(e) => setInputSendEmail(e)}
        mailValue={mailValue}
      />
      <Form name={formname} form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <Form.Item
              name="riskFormName"
              label={<LabelNew type="tab-header-inactive">{t('ชื่อแบบประเมินความเสี่ยง')}</LabelNew>}
            >
              <InputNew placeholder={t('ระบุชื่อแบบประเมินความเสี่ยง')} maxLength={200} />
            </Form.Item>
          </Col>

          <Col {...Responsive}>
            <Form.Item name="projectName" label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ')}</LabelNew>}>
              <InputNew placeholder={t('ระบุชื่อโครงการ')} maxLength={200} />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name={'yearStart'}
              label={<LabelNew type="tab-header-inactive">{t('ปีที่เริ่มต้น')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุ') }]}
              initialValue={parseInt(moment(new Date()).format('YYYY'))}
            >
              <SelectYear
                formname={formname}
                placeholder={t('เลือกประจำปี')}
                handleChange={(e) => {
                  form.setFieldsValue({ yearStart: e?.value })
                }}
              />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name={'yearEnd'}
              label={<LabelNew type="tab-header-inactive">{t('ปีที่สิ้นสุด')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุ') }]}
              initialValue={parseInt(moment(new Date()).add(5, 'years').format('YYYY'))}
            >
              <SelectYear
                formname={formname}
                placeholder={t('เลือกประจำปี')}
                handleChange={(e) => {
                  form.setFieldsValue({ yearEnd: e?.value })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} justify="center">
          <Col>
            <ButtonNew type="search" roles={{ type: 'view', menu: menu }} htmlType="submit">
              {t('ค้นหา')}
            </ButtonNew>
          </Col>
          <Col>
            <ButtonNew type="clear" onClick={onClear}>
              {t('ล้างค่า')}
            </ButtonNew>
          </Col>
          <Col>
            <ButtonNew
              type="printerSuccess"
              roles={{ type: 'export', menu: menu }}
              menu={menu}
              onClick={() => {
                form.validateFields().then(() => {
                  const field = form.getFieldValue()

                  if (valiteYear(field)) {
                    setFilter(field)
                    setModalExport(true)
                  }
                })
              }}
            >
              {t('พิมพ์')}
            </ButtonNew>
          </Col>
          <Col>
            <ButtonNew
              type="success"
              roles={{ type: 'export', menu: menu }}
              menu={menu}
              onClick={() => {
                form.validateFields().then(() => {
                  const field = form.getFieldValue()
                  if (valiteYear(field)) {
                    setFilter(field)
                    setModalExport(true)
                  }
                  fncOnpressEmailSetting()
                })
              }}
            >
              {t('ส่งรายงาน')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
    </CardNew>
  )
}

export default RiskLevelFiveYearReportFilter
