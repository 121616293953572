import { gql } from '@apollo/client'

export const GET_MASTER_NEW_PERSONNEL_STATUS = gql`
  query getMasterNewPersonnelStatus($input: MasterNewPersonnelStatusInput!) {
    getMasterNewPersonnelStatus(data: $input) {
      newPersonnelStatusId
      newPersonnelStatusCode
      newPersonnelStatusNameTH
      newPersonnelStatusNameEN
      isActive
    }
  }
`
