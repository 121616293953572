import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col, Space, Form } from 'antd'
import { Datepicker } from '../../../../components/datepicker/Datepicker'
import { UploadNew } from '../../../../components/upload/Upload'
import { Radio, RadioGroup } from '../../../../components/radio/Radio'
import SelectPlanType from '../../../../components/inputfromapi/selectplantype/SelectPlanType.jsx'
import SelectYear from '../../../../components/inputfromapi/selectyear/SelectYear.jsx'
import { useMutation } from '@apollo/client'
import { SAVE_AUDIT_PLAN } from './graphql/Mutation'
import { Alert } from '../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { LabelNew } from '../../../../components/label/Label'
import { InputNew as Input } from '../../../../components/input/Input'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayError } from '../../../../utilitys/helper'

const FormAuditPlan = (props) => {
  const { t } = useTranslation()
  const [file, setFile] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])
  const [idFileDefault, setIdFileDefault] = useState([])

  // const [statusRenderFile, setStatusRenderFile] = useState(false)
  const [saveDataFn, saveData] = useMutation(SAVE_AUDIT_PLAN)

  useEffect(() => {
    if (saveData.data) {
      let response = saveData?.data?.auditPlanSave
      if (response) showAlertSuccess()
    }

    if (saveData.error) showAlertError(displayError(saveData.error))
  }, [saveData.data])

  const showAlertSuccess = () => {
    Alert({
      type: 'success',
      title: t('บันทึกสำเร็จ'),
      onOk() {
        props.modalFormSetState(false)
        props.dataLists.refetch()
      },
    })
  }

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t(text),
      onOk() {},
    })
  }

  const onFinish = async (values) => {
    let input = {
      planCode: values.planCode,
      planType: values.planType,
      planYear: values.planYear,
      // dateStart: new Date(values.dateStart),
      // dateEnd: new Date(values.dateEnd),
      dateStart: values.dateStart,
      dateEnd: values.dateEnd,
      files: file.join(),
      fileDetail: values.fileDetail ? values.fileDetail : null,
      idFileDelete: idFileDelete.join(),
      isActive: values.isActive,
    }

    if (props.typeForm === 'add') {
      input['planNameTH'] = values.planName
      input['planNameEN'] = values.planName
      input['action'] = 'create'
    } else if (props.typeForm === 'edit') {
      input['planNameTH'] = localStorage.getItem('lang') === 'th' ? values.planName : values.planNameTH
      input['planNameEN'] = localStorage.getItem('lang') === 'en' ? values.planName : values.planNameEN
      input['action'] = 'update'
    }

    saveDataFn({ variables: { input: encryptInput(input) }, fetchPolicy: 'no-cache' })
  }

  const onFinishFailed = () => {}

  useEffect(() => {
    if (props.modal) {
      // setStatusRenderFile(true)
      if (props.typeForm === 'add') {
        props.form.resetFields()
        setFile([])
        setIdFileDefault([])
        setIdFileDelete([])
      } else if (props.typeForm === 'edit' || props.typeForm === 'view') {
        if (props.dataLists?.data) {
          let response = props.dataLists?.data?.getAuditPlanPagenation.result
          let data = response.find((item) => {
            return item.planCode === props.codeEdit
          })
          props.form.resetFields()
          if (data) {
            props.form.setFieldsValue({
              planCode: data.planCode,
              planName: localStorage.getItem('lang') === 'th' ? data.planNameTH : data.planNameEN,
              planNameTH: data.planNameTH,
              planNameEN: data.planNameEN,
              planType: data.planType,
              planYear: data.planYear,
              isActive: data.isActive,
              dateStart: data.dateStart,
              dateEnd: data.dateEnd,
              fileDetail: data.fileDetail,
            })

            setFile(() => {
              return data.files.split(',').map((item) => {
                return parseInt(item)
              })
            })
            setIdFileDefault(() => {
              return data.files.split(',').map((item) => {
                return parseInt(item)
              })
            })
            setIdFileDelete([])
          }
        }
      }
    }
  }, [props.modal, props.typeForm])

  function setErrorField(name, errors) {
    // Set field errors
    return props.form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  return (
    <>
      {saveData.loading && <SpinnersNew />}
      <Row justify="center">
        <Col md={18}>
          <Form
            form={props.form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            name="insertAuditPlan"
            labelAlign="left"
            labelCol={{ sm: { span: 24 }, md: { span: 8 } }}
            wrapperCol={{ sm: { span: 24 }, md: { span: 16 } }}
          >
            <Form.Item
              name="planCode"
              label={<LabelNew type="tab-header-inactive">{t('รหัสแผนงาน')}</LabelNew>}
              rules={[
                { required: true, message: t('กรุณาระบุรหัสแผนงาน') },
                { max: 10, message: t('กรุณาระบุรหัสแผนงานน้อยกว่าหรือเท่ากับ 10 ตัวอักษร') },
              ]}
            >
              <Input
                maxLength={10}
                placeholder={t('ระบุรหัสแผนงาน')}
                disabled={props.typeForm !== 'add' ? true : false}
              />
            </Form.Item>
            <Form.Item name="planNameTH" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item name="planNameEN" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item
              name="planName"
              label={<LabelNew type="tab-header-inactive">{t('ชื่อแผนการตรวจสอบ')}</LabelNew>}
              rules={[
                { required: true, message: t('กรุณาระบุชื่อแผนปฎิบัติงาน') },
                { max: 8000, message: t('กรุณาระบุชื่อแผนปฎิบัติงานน้อยกว่า') },
              ]}
            >
              <Input
                maxLength={8000}
                placeholder={t('ระบุชื่อแผนปฎิบัติงาน')}
                disabled={props.typeForm === 'view' ? true : false}
              />
            </Form.Item>
            <Form.Item
              name="planType"
              label={<LabelNew type="tab-header-inactive">{t('ประเภทแผนการตรวจสอบ')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกประเภทแผนการตรวจสอบ') }]}
            >
              <SelectPlanType
                formname="insertAuditPlan"
                placeholder={t('เลือกประเภทแผนการตรวจสอบ')}
                handleChange={(value) => {
                  props.form.setFieldsValue({ planType: value ? value.value : null })
                  if (value) {
                    setErrorField('planType', false)
                  } else {
                    setErrorField('planType', t('กรุณาเลือกประเภทแผนการตรวจสอบ'))
                  }
                }}
                disabled={props.typeForm === 'view' ? true : false}
              />
            </Form.Item>
            <Form.Item
              name="planYear"
              label={<LabelNew type="tab-header-inactive">{t('ประจำปี')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาเลือกปี') }]}
            >
              <SelectYear
                formname="insertAuditPlan"
                placeholder={t('เลือกปี')}
                handleChange={(value) => {
                  props.form.setFieldsValue({ planYear: value ? value.value : null })
                  if (value) {
                    setErrorField('planYear', false)
                  } else {
                    setErrorField('planYear', t('กรุณาเลือกปี'))
                  }
                }}
                disabled={props.typeForm === 'view' ? true : false}
              />
            </Form.Item>

            <Form.Item
              name="dateStart"
              label={<LabelNew type="tab-header-inactive">{t('วันที่เริ่มต้น')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุวันที่เริ่มต้น') }]}
            >
              <Datepicker
                setValueDateFn={async (data) => {
                  props.form.setFieldsValue({ dateStart: await data })
                  props.validDate()
                }}
                onClear={() => {
                  props.form.setFieldsValue({ dateStart: null })
                }}
                placeholder={t('เลือกวันที่เริ่มต้น')}
                disabled={props.typeForm === 'view' ? true : false}
              />
            </Form.Item>

            <Form.Item
              name="dateEnd"
              label={<LabelNew type="tab-header-inactive">{t('วันที่สิ้นสุด')}</LabelNew>}
              rules={[{ required: true, message: t('กรุณาระบุวันที่สิ้นสุด') }]}
            >
              <Datepicker
                setValueDateFn={async (data) => {
                  props.form.setFieldsValue({ dateEnd: await data })
                  props.validDate()
                }}
                onClear={() => {
                  props.form.setFieldsValue({ dateEnd: null })
                }}
                placeholder={t('เลือกวันที่สิ้นสุด')}
                disabled={props.typeForm === 'view' ? true : false}
              />
            </Form.Item>
            <Form.Item
              name="fileDetail"
              rules={[{ type: 'url', message: t('กรุณาระบุลิงค์เอกสารให้ถูกต้อง') }]}
              hidden={props.typeForm === 'view' ? true : false}
              label={<LabelNew type="tab-header-inactive">{t('ลิงค์เอกสาร')}</LabelNew>}
            >
              <Input placeholder={t('ระบุลิงค์เอกสาร')} />
            </Form.Item>
            {props.typeForm === 'view' ? (
              <Row>
                <Col sm={24} md={8}>
                  <LabelNew type="tab-header-inactive">{t('ลิงค์เอกสาร')}</LabelNew>
                </Col>
                <Col sm={24} md={16}>
                  {props.form.getFieldValue('fileDetail') ? (
                    <a href={props.form.getFieldValue('fileDetail')}>{props.form.getFieldValue('fileDetail')}</a>
                  ) : (
                    <LabelNew type="tab-header-inactive">{t('ไม่มีข้อมูล')}</LabelNew>
                  )}
                </Col>
              </Row>
            ) : (
              ''
            )}
            <Row>
              <Col sm={24} md={8}>
                <LabelNew type="tab-header-inactive">{t('เอกสารเพิ่มเติม')}</LabelNew>
              </Col>
              <Col sm={24} md={16}>
                <UploadNew
                  type="mutiple"
                  setIdFile={setFile}
                  idFile={file}
                  actionType={props.typeForm === 'add' ? 'create' : props.typeForm}
                  idFileDelete={idFileDelete}
                  defaults={idFileDefault}
                  setIdFileDelete={setIdFileDelete}
                  typeFile="file"
                  disabled={props.typeForm === 'view' ? true : false}
                />
              </Col>
            </Row>
            <Form.Item
              name="isActive"
              label={<LabelNew type="tab-header-inactive">{t('สถานะ')}</LabelNew>}
              initialValue={1}
            >
              <RadioGroup disabled={props.typeForm === 'view' ? true : false}>
                <Space size={[8, 8]} wrap>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Space>
              </RadioGroup>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default FormAuditPlan
