import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { Form, Row, Col } from 'antd'
import { CardNew } from '../../../../../components/card/Card'
import { ButtonNew } from '../../../../../components/button/Button'
import { InputNew } from '../../../../../components/input/Input'
import { LabelNew } from '../../../../../components/label/Label'
import SelectProjectRefer from '../../../../../components/inputfromapi/selectprojectrefer/SelectProjectRefer'
import SelectMasterOrganization from '../../../../../components/inputfromapi/selectorganization/SelectOrganization'

const RacMatrixFilter = (props) => {
  const { menu, onFilter } = props
  const Responsive = { md: 8, lg: 8 }
  const formname = 'RacMatrixFilter'

  const [form] = Form.useForm()
  const { t } = useTranslation()

  const onFinish = (data) => onFilter({ ...data })

  const onClear = () => {
    form.resetFields()
    onFilter(form.getFieldsValue())
  }

  return (
    <CardNew topic={t('Filters')} icon="List" toggledrop="false">
      <Form name={formname} form={form} onFinish={onFinish} layout="vertical">
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <Form.Item
              name="projectRefCode"
              label={<LabelNew type="tab-header-inactive">{t('แหล่งที่มาของโครงการ')}</LabelNew>}
            >
              <SelectProjectRefer
                placeholder={t('เลือกแหล่งที่มาของโครงการ')}
                formname={formname}
                handleChange={(e) => form.setFieldsValue({ projectRefCode: e?.value })}
              />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item name="projectName" label={<LabelNew type="tab-header-inactive">{t('ชื่อโครงการ')}</LabelNew>}>
              <InputNew placeholder={t('ระบุชื่อโครงการ')} maxLength={200} />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name="organizationCode"
              label={<LabelNew type="tab-header-inactive">{t('หน่วยงานที่รับผิดชอบ')}</LabelNew>}
            >
              <SelectMasterOrganization
                placeholder={t('เลือกหน่วยงานที่รับผิดชอบ')}
                formname={formname}
                filters={{ checkedList: JSON.stringify([1, 2]), isActive: 1 }}
                handleChange={(e) => form.setFieldsValue({ organizationCode: e?.value })}
              />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name="organizationGroupCode"
              label={<LabelNew type="tab-header-inactive">{t('หน่วยรับตรวจ')}</LabelNew>}
            >
              <SelectMasterOrganization
                placeholder={t('เลือกหน่วยรับตรวจ')}
                formname={formname}
                filters={{ organizationChecked: 3, isActive: 1 }}
                handleChange={(e) => form.setFieldsValue({ organizationGroupCode: e?.value })}
              />
            </Form.Item>
          </Col>
          <Col {...Responsive}>
            <Form.Item
              name="personnelName"
              label={<LabelNew type="tab-header-inactive">{t('ผู้รับผิดชอบหลัก')}</LabelNew>}
            >
              <InputNew placeholder={t('ระบุผู้รับผิดชอบหลัก')} maxLength={200} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[12, 12]} justify="center" style={{ marginTop: '2rem' }}>
          <Col>
            <ButtonNew type="search" roles={{ type: 'view', menu: menu }} htmlType="submit">
              {t('ค้นหา')}
            </ButtonNew>
          </Col>
          <Col>
            <ButtonNew type="clear" onClick={onClear}>
              {t('ล้างค่า')}
            </ButtonNew>
          </Col>
        </Row>
      </Form>
    </CardNew>
  )
}

RacMatrixFilter.propTypes = {
  menu: PropTypes.string.isRequired,
  onFilter: PropTypes.func.isRequired,
}

export default RacMatrixFilter
