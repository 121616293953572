import styled from 'styled-components'
import { layout } from './configs/styles.config'

export const AppStyles = styled.div`
  .ant-layout {
    font-family: 'NotoSansThai', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
      'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    background-color: ${layout.layoutBg};
  }
  .ant-layout-content {
    margin: 0px 24px !important;
  }
  .ant-menu {
    background-color: ${layout.menuBg} !important;
  }
  .ant-menu > .ant-menu-item-selected {
    background-color: ${layout.linearBgNotSupport} !important;
    background-image: ${layout.layoutMenuSelectedBg};
    color: ${layout.layoutMenuSelectedFont} !important;
  }
  .ant-menu > .ant-menu-submenu-selected {
    color: ${layout.layoutMenuSelectedFont} !important;
  }
  .ant-menu-submenu-title {
    color: ${layout.menuSubmenuTitle} !important;
  }
  .ant-menu > .ant-menu-submenu-open {
    background-color: ${layout.menuSubmenuOpenBg} !important;
    color: ${layout.menuSubmenuOpenText} !important;
  }
  .ant-menu > li {
    margin: 0px !important;
  }
  .ant-menu-submenu-title {
    margin: 0px !important;
  }
  .ant-menu > .ant-menu-item:hover {
    background-color: ${layout.menuItemHover} !important;
  }
  .ant-layout-sider-trigger {
    background-color: ${layout.layoutSliderTriggerBg} !important;
  }
  .ant-menu-sub > .ant-menu-item {
    padding-left: 20px;
  }
  .ant-menu-submenu-title {
    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      background: ${layout.menuArrowBg} !important;
    }
  }
  .ant-menu-submenu-title:hover {
    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow::after {
      background: ${layout.menuArrowHover} !important;
    }
  }
  .menu-label {
    color: ${layout.menuLabel} !important;
  }

  .layout-main {
    overflow-y: auto;
    max-height: calc(100vh - 64px);
    .ant-layout-content {
      padding: 0px;
      height: 100%;
    }
  }

  //Scrollbar
  .layout-main::-webkit-scrollbar {
    //ความกว้าง scroll
    width: 8px;
    height: 8px;
  }
  // .layout-main:hover::-webkit-scrollbar {
  //   //show scroll เมื่อ hover
  //   display: block;
  // }
  .layout-main::-webkit-scrollbar-track {
    //พื้นหลัง scroll
    background: #f0f2f5;
  }
  .layout-main::-webkit-scrollbar-thumb {
    //แท่ง scroll
    background: #9e9e9e;
    border-radius: 10px;
  }
  .layout-main::-webkit-scrollbar-thumb:hover {
    //เมื่อ hover แท่ง scroll
    background: #555;
  }

  //End Scrollbar
`
