import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
// import { useLanguage } from '../../../hooks/useLanguage'
import { Row, Col } from 'antd'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import { GET_CERTIFICATE_PERSONNELS, GET_PERSONNEL } from './graphql/Query'
import { DELETE_ONE_CERTIFICATE } from './graphql/Mutation'
import * as _ from 'lodash'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import { Alert } from '../../../components/alert/Alert'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { ButtonNew } from '../../../components/button/Button'
import { useLocation } from 'react-router-dom'
import { encryptInput } from '../../../utilitys/crypto'
import { columnsCertificate as columns } from './utils/table/CertificateTable'
import CertificateModal from './modal/CertificateModal.jsx'
import moment from 'moment'
import { displayError } from '../../../utilitys/helper.js'

const PersonnelTrainingList = (props) => {
  const { t } = useTranslation()
  // const [isLang] = useLanguage()
  const { type_action } = props
  const { state } = useLocation()
  const [formType, setFormType] = useState(state.type_action === 'add' ? 'add' : 'edit') // Intitial value formType
  const [typeAction, setAction] = useState(state.type_action === 'add' ? 'add' : 'edit')
  const [dataLists, setDataLists] = useState([])
  const [codeEdit, setCodeEdit] = useState('')
  const [count, setCount] = useState(0)
  const [modalAdd, setModalAdd] = useState(false)
  //get prefix, Name, lastName
  const [contentName, setContentName] = useState()
  const [getPersonnelFn, dataPersonnel] = useLazyQuery(GET_PERSONNEL)
  const getData = (personnelCode) => {
    getPersonnelFn({ variables: { input: encryptInput({ personnelCode: personnelCode }) } })
  }
  useEffect(() => {
    getData(state.refCode)
    setAction(type_action)
  }, [state.refCode])
  //
  const getPersonnelCertificate = useQuery(GET_CERTIFICATE_PERSONNELS, {
    variables: {
      input: {
        personnelCode: state.refCode,
      },
    },
  })
  const [deleteData, { loading: deleteDataLoading, data: dataDelete, error: errorDelete }] =
    useMutation(DELETE_ONE_CERTIFICATE)
  // const showConfirmEdit = () => {
  //   Alert({
  //     type: 'confirm',
  //     title: t('ต้องการยืนยันการบันทึก ?'),
  //     onOk() {},
  //     onCancel() {
  //       setModalAdd(false)
  //     },
  //   })
  // }

  const showConfirmDelete = (personnelCertificateId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteData({
          variables: {
            input: { personnelCertificateId: encryptInput(personnelCertificateId) },
          },
        })
      },
      onCancel() {
        getPersonnelCertificate.refetch()
      },
    })
  }
  //เก็บ Prefix, Name ,lastName ที่ดึงมาจาก query
  useEffect(() => {
    if (dataPersonnel.data) {
      setContentName({
        prefix: dataPersonnel.data.getPersonnel.namePrefixTH,
        fName: dataPersonnel.data.getPersonnel.firstNameTH,
        lName: dataPersonnel.data.getPersonnel.lastNameTH,
      })
    }
  }, [dataPersonnel.data])
  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          getPersonnelCertificate.refetch()
        },
      })
    }
  }, [dataDelete])

  useEffect(() => {
    if (errorDelete) {
      showAlertError(t(displayError(errorDelete)))
    }
  }, [errorDelete])

  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: text,
      onOk() {},
    })
  }

  // function setErrorField(name, errors) {
  //   // Set field errors
  //   return props.form.setFields([
  //     {
  //       name: name, // Name fields
  //       errors: errors ? [errors] : false, // Message errors OR remove fields errors
  //     },
  //   ])
  // }

  useEffect(() => {
    if (modalAdd === false) {
      setFormType('')
    }
  }, [modalAdd])

  useEffect(() => {
    if (getPersonnelCertificate?.data) {
      setCount(getPersonnelCertificate.data.getPersonnelCertificate.leght)
      let response = getPersonnelCertificate?.data?.getPersonnelCertificate
      setCount(response?.count)
      setDataLists(
        _.map(response, (data, i) => {
          return {
            key: i,
            no: i + 1,
            certificateNameTH: data.certificateNameTH,
            certificateNumber: data.certificateNumber,
            expireDate: moment(new Date(data.expireDate)).format('DD/MM/YYYY'),
            attachFile: data.attachFile,
            action: (
              <ButtonGroup
                menu={props.menu}
                icons={[
                  {
                    type: 'view',
                    onClick: () => {
                      setFormType('view')
                      setModalAdd(true)
                      setCodeEdit(data.personnelCertificateId)
                    },
                  },
                  {
                    type: 'edit',
                    onClick: () => {
                      setFormType('edit')
                      setModalAdd(true)
                      setCodeEdit(data.personnelCertificateId)
                    },
                  },
                  {
                    type: 'delete',
                    onClick: () => {
                      showConfirmDelete(data.personnelCertificateId)
                    },
                  },
                ]}
              />
            ),
          }
        }),
      )
    }
  }, [getPersonnelCertificate.data])
  return (
    <>
      {(getPersonnelCertificate.loading || deleteDataLoading) && <SpinnersNew />}
      <Row gutter={[16, 24]}>
        {contentName ? contentName.prefix + ' ' + contentName.fName + ' ' + contentName.lName : ''}
        <Col md={24}>
          <Datatable
            columns={columns}
            data={dataLists}
            handleTableChange={() => {}}
            paginationCustom={false}
            searchCustom={true}
            total={count}
            // enableScroll={{ x: true, y: true }}
            btnAdd={
              <>
                {typeAction !== 'view' ? (
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: props.menu }}
                    style={{ marginRight: '12px' }}
                    onClick={() => {
                      setModalAdd(true)
                      setFormType('add')
                      setCodeEdit('')
                    }}
                  >
                    {t('เพิ่มวุฒิบัตร')}
                  </ButtonNew>
                ) : (
                  ''
                )}
              </>
            }
          />
        </Col>
      </Row>
      <CertificateModal
        colse={(e) => setModalAdd(e)}
        open={modalAdd}
        refCode={state.refCode}
        codeEdit={codeEdit}
        formType={formType}
        setFormType={setFormType}
        getPersonnelCertificate={getPersonnelCertificate}
      />
    </>
  )
}

export default PersonnelTrainingList
