import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { Datatable } from '../../../../components/datatable/Datatable'
import { ButtonNew } from '../../../../components/button/Button'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { GET_AUDIT_ISSUE } from '../graphql/Query'
import { DELETE_AUDIT_ISSUE } from '../graphql/Mutation'
import { useLazyQuery, useMutation } from '@apollo/client'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayText } from '../../../../utilitys/helper'
import { Status } from '../../../../components/status/Status'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { Alert } from '../../../../components/alert/Alert'

const SoiAuditCommitteeTabIssue = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const action = state.action
  const mainPage = '/auditoperations/soiauditcommittee'
  const [callData, dataResponse] = useLazyQuery(GET_AUDIT_ISSUE)
  const [deleteFn, deleteResponse] = useMutation(DELETE_AUDIT_ISSUE)
  const [dataList, setDataList] = useState([])

  if (!state) return <Navigate to={mainPage} />

  useEffect(() => {
    callData({ variables: { input: encryptInput({ actionPlanId: state.actionPlanId, issueType: 2 }) } })
  }, [])

  useEffect(() => {
    if (dataResponse?.data) {
      const x = dataResponse.data.getAuditIssues
      const y = x.map((item, keys) => {
        let show = true
        let show2 = true

        if (keys !== 0) {
          show = x[keys - 1].rateFacProCode !== x[keys].rateFacProCode ? true : false
        }

        if (keys !== 0) {
          show2 = x[keys - 1].rateRiskConCode !== x[keys].rateRiskConCode ? true : false
        }

        return {
          key: keys + 1,
          issueId: item.issueId,
          show: show,
          show2: show2,
          factorsRisks:
            item.rateRiskFactorProcess?.rateFacProCode +
            ' : ' +
            displayText(item.rateRiskFactorProcess?.rateFacProNameTH, item.rateRiskFactorProcess?.rateFacProNameEN),
          controlMethod: displayText(item.rateRiskControl?.rateRiskConTH, item.rateRiskControl?.rateRiskConEN),
          issuesRemarks: displayText(item.issueTH, item.issueEN),
          // personResponsible: displayText(item.personnelsNameTH, item.personnelsNameEN),
          isActive: item.isActive,
        }
      })
      setDataList(y)
    }
  }, [dataResponse.data])

  const columns = [
    { title: t('ปัจจัย/ความเสี่ยง'), dataIndex: 'factorsRisks', width: '350px', className: 'text-nowrap' },
    { title: t('วิธีการควบคุม'), dataIndex: 'controlMethod', width: '450px', className: 'text-nowrap' },
    { title: t('ประเด็น/ข้อสังเกตุ'), dataIndex: 'issuesRemarks', width: '350px', className: 'text-nowrap' },
    {
      title: t('สถานะ'),
      dataIndex: 'isActive',
      width: '100px',
      className: 'text-nowrap',
      render: (item) => <Status text={item === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
    },
    {
      title: '',
      dataIndex: '',
      width: '80px',
      render: (record) => {
        let list = [
          {
            type: 'view',
            onClick: () => {
              navigate('auditIssue', {
                state: {
                  ...state,
                  action: 'view',
                  backToEdit: action === 'view' ? false : true,
                  issueId: record.issueId,
                },
              })
            },
          },
        ]
        if (state.action !== 'view') {
          list.push(
            {
              type: 'edit',
              onClick: () => {
                navigate('auditIssue', {
                  state: {
                    ...state,
                    action: 'edit',
                    issueId: record.issueId,
                  },
                })
              },
            },
            {
              type: 'delete',
              onClick: () => {
                Alert({
                  type: 'confirm',
                  title: t('ต้องการยืนยันการลบ?'),
                  onOk() {
                    deleteFn({
                      variables: {
                        input: encryptInput({ issueId: record.issueId }),
                      },
                    }).then((result) => {
                      if (result?.data.SoiAuditCommitteeDeleteIssue) {
                        Alert({
                          type: 'success',
                          title: t('ลบสำเร็จ'),
                          onOk() {
                            callData({
                              variables: {
                                input: encryptInput({
                                  actionPlanId: state.actionPlanId,
                                  issueType: 2,
                                }),
                              },
                            })
                          },
                        })
                      }
                    })
                  },
                  onCancel() {},
                })
              },
            },
          )
        }

        return <ButtonGroup menu={props.menu} size={18} icons={list} />
      },
    },
  ]

  return (
    <>
      {(dataResponse.loading || deleteResponse.loading) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Datatable
            columns={columns}
            data={dataList}
            btnAdd={
              action === 'view' ? null : (
                <>
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: props.menu }}
                    style={{ marginRight: '12px' }}
                    onClick={() => {
                      navigate('auditIssue', {
                        state: {
                          ...state,
                          action: 'add',
                          actionPlanId: state.actionPlanId,
                          projectCode: state.projectCode,
                        },
                      })
                    }}
                  >
                    {t('บันทึกผลการตรวจสอบ')}
                  </ButtonNew>
                </>
              )
            }
          />
        </Col>
      </Row>
    </>
  )
}

export default SoiAuditCommitteeTabIssue
