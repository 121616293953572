import React from 'react'
import { Tooltip } from 'antd'
import { LabelNew } from '../../../../../components/label/Label.jsx'
import { downloadFile } from '../../../../../utilitys/files'
import { IconNew } from '../../../../../components/icon/Icon'
import { colors } from '../../../../../configs/styles.config'
import { validateURL } from '../../../../../utilitys/helper'

const Files = (props) => {
  const { item, index } = props
  const title = item.fileId ? item.refFile.originalname : item.filename

  return (
    <Tooltip key={'toolTipFilesApprove' + index} placement="right" title={title}>
      <LabelNew
        type="tab-header-inactive"
        key={'labelFilesApprove' + index}
        onClick={() => {
          item.fileId
            ? downloadFile(item.refFile.originalname, item.refFile.name, () => {})
            : window.open(validateURL(item.links), '_blank')
        }}
        style={{ cursor: 'pointer' }}
      >
        {item.refFile && (item.refFile.fileType === '.jpg' || item.refFile.fileType === '.png') ? (
          <IconNew
            key={'iconFilesApprove' + index}
            icon={'Image'}
            size={28}
            color={colors.link}
            className="approve-downloads"
          />
        ) : (
          <IconNew
            key={'iconFilesApprove' + index}
            icon={'FileText'}
            size={28}
            color={colors.link}
            className="approve-downloads"
          />
        )}
      </LabelNew>
    </Tooltip>
  )
}

export default Files
