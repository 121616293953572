import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'antd'
import { useMutation } from '@apollo/client'

import { ModalNew as Modal } from '../../../../components/modal/Modal'
import { InputNew as Input } from '../../../../components/input/Input'
import { Radio, RadioGroup } from '../../../../components/radio/Radio'
import { Alert } from '../../../../components/alert/Alert'
import { displayError, regexEngNoSpace } from '../../../../utilitys/helper'
import SelectRateRiskFactorProcessRiskTypes from '../../../../components/inputfromapi/selectrateriskfactorprocessrisktypes/SelectRateRiskFactorProcessRiskTypes'
import { TextAreaNew } from '../../../../components/textarea/TextArea'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { useLanguage } from '../../../../hooks/useLanguage'
import { encryptInput } from '../../../../utilitys/crypto'

import { SAVE_RATE_RISK_FACTOR_PROCESS } from './graphql/Mutation'

const RateRiskFactorsProcessForm = ({
  visible = false,
  formType = 'view',
  onClose = () => {},
  onSuccess = () => {},
  dataForm = null,
  rateFacGroupCode = null,
}) => {
  const [isLang] = useLanguage()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const [editable, seteditable] = useState(false)

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] =
    useMutation(SAVE_RATE_RISK_FACTOR_PROCESS)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: displayError(errorSave),
        onOk() {},
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          onSuccess()
        },
      })
    }
  }, [dataSave])

  useEffect(() => {
    if (dataForm && visible) {
      form.setFieldsValue(dataForm)
    }
  }, [dataForm, visible])

  useEffect(() => {
    if (formType) {
      if (formType === 'add') form.resetFields()
      seteditable(formType !== 'view')
    }
  }, [formType])

  const onFinish = (data) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        const dataInput = {
          rateFacProCode: data.rateFacProCode,
          isActive: data.isActive,
          riskType: data.riskType,
          shortCode: data.shortCode,
          rateFacGroupCode: rateFacGroupCode,
        }
        if (formType === 'add') {
          dataInput.rateFacProNameTH = data.rateFacProName
          dataInput.rateFacProNameEN = data.rateFacProName
          dataInput.shortCode = data.shortCode || null
          dataInput.typeAction = 'add'
        } else {
          dataInput.rateFacProNameTH = isLang !== 'en' ? data.rateFacProName : data.rateFacProNameTH
          dataInput.rateFacProNameEN =
            isLang === 'en' ? data.rateFacProName : data.rateFacProNameEN ? data.rateFacProNameEN : data.rateFacProName
          dataInput.shortCode = data.shortCode || null
          dataInput.typeAction = 'edit'
        }
        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const footerHide =
    formType === 'view'
      ? { cancelButtonProps: { style: { display: 'none' } }, okButtonProps: { style: { display: 'none' } } }
      : {}

  const renderTitle = () => {
    if (formType === 'add') {
      return t('เพิ่มปัจจัยความเสี่ยงกระบวนการ')
    }
    if (formType === 'view') {
      return t('ดูปัจจัยความเสี่ยงกระบวนการ')
    }
    if (formType === 'edit') {
      return t('แก้ไขปัจจัยความเสี่ยงกระบวนการ')
    }

    return ''
  }

  return (
    <React.Fragment>
      {loadingSave && <SpinnersNew />}
      <Modal
        visible={visible}
        onSubmit={form.submit}
        onClose={(data) => {
          onClose(data)
          form.resetFields()
        }}
        testTitle={renderTitle()}
        typeAction={formType}
        type="medium"
        btnName={t('บันทึก')}
        {...footerHide}
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 8,
            lg: 8,
          }}
          layout="horizontal"
          name="rateRiskFactorProcess"
        >
          <Form.Item
            name={'rateFacProCode'}
            label={t('รหัสความเสี่ยงกระบวนการ')}
            rules={[
              { required: true, message: t('กรุณาระบุ') },
              {
                pattern: regexEngNoSpace,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษพิมพ์ใหญ่,ตัวเลข A-Z, 0-9 ไม่เกิน 10 ต้ว`),
              },
            ]}
          >
            <Input disabled={formType === 'edit' || !editable} placeholder={t('กรอกรหัสความเสี่ยงกระบวนการ')} />
          </Form.Item>

          <Form.Item
            name={'rateFacProName'}
            label={t('ชื่อความเสี่ยงกระบวนการ')}
            rules={[{ required: true, message: t('กรุณาระบุ') }]}
          >
            <TextAreaNew
              disabled={!editable}
              placeholder={t('กรอกชื่อความเสี่ยงกระบวนการ')}
              maxLength={8000}
              rows={5}
            />
          </Form.Item>
          <Form.Item name={'shortCode'} label={t('ชื่อย่อ')}>
            <Input disabled={!editable} placeholder={t('กรอกชื่อย่อ')} maxLength={4} />
          </Form.Item>
          <Form.Item
            name="riskType"
            label={t('ประเภทความเสี่ยง')}
            rules={[{ required: true, message: t('กรุณาระบุ') }]}
          >
            <SelectRateRiskFactorProcessRiskTypes
              disabled={!editable}
              formname="riskFactorForm"
              handleChange={(data) => {
                if (data) form.setFieldsValue({ riskType: data.value })
                else form.setFieldsValue({ riskType: null })
              }}
            />
          </Form.Item>

          <Form.Item name={'isActive'} label={t('สถานะ')} initialValue={1}>
            <RadioGroup disabled={!editable}>
              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                </Col>
                <Col>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}
export default RateRiskFactorsProcessForm
