import { gql } from '@apollo/client'
export const GET_CONFLICT_OF_INTEREST_LIST = gql`
  query getConflictOfInterestList($input: ConflictOfInterestListPagenationInput!) {
    getConflictOfInterestList(input: $input) {
      result {
        actionPlanId
        projectCode
        projectNameTH
        projectNameEN
        personnelCode
        projectRefNameTH
        projectRefNameEN
        dateStart
        dateEnd
        auditTeamNameTH
        auditTeamNameEN
        conflict
      }
      count
      page
      limit
    }
  }
`

export const GET_EXPORT_ACTION_PLAN = gql`
  query ExportActionPlan($input: ExportActionPlanInput!) {
    exportActionPlan(input: $input) {
      message
      fileUrl
    }
  }
`
