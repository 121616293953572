import { gql } from '@apollo/client'
export const GET_SELECT_FOLLOW_UP_ACTIVITY = gql`
  query getSelectFollowUpActivity($input: SelectFollowUpActivityInput!) {
    getSelectFollowUpActivity(input: $input) {
      activityId
      followUpId
      activityTH
      activityEN
      # activityType
      rateFacProCode
      rateRiskRuleCode
      rateRiskConCode
      issueId
      suggestId
      dueDate
      expectDate
      organizationCode
      organizationNameTH
      organizationNameEN
      personnelCode
      followUpStatusCode
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
      files
      fileDetails
      remarkTH
      remarkEN
    }
  }
`
