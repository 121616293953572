import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import * as _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import { ModalNew } from '../../../../../components/modal/Modal'
import { Select } from '../../../../../components/select/Select'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { decryptData, encryptInput } from '../../../../../utilitys/crypto'
import { Alert } from '../../../../../components/alert/Alert'
import { checkLanguageReturnData } from '../fnForAssessmentAuditWork'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { displayError } from '../../../../../utilitys/helper'
import { GETPERSONNELSBYASSESSMENTAUDITWORK } from '../../graphql/Query'
import { SENDFORMBYASSESSMENTAUDITWORK } from '../../graphql/Mutation'

export default function ModalSendAssesss(props) {
  const { open, onClose, dataObj } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const formName = 'modalSendAssesss'
  const userData = decryptData(localStorage.getItem('user'))
  const navigate = useNavigate()

  const Responsive = {
    md: 24,
    lg: 24,
  }

  const [getPersonnelsByAssessmentAuditWorkfn, getPersonnelsByAssessmentAuditWork] = useLazyQuery(
    GETPERSONNELSBYASSESSMENTAUDITWORK,
  )
  const [sendFormByAssessmentAuditWorkFn, { loading: loadingSave, error, data: sendFormByAssessmentAuditWork }] =
    useMutation(SENDFORMBYASSESSMENTAUDITWORK)

  const [personnelOptions, setPersonnelOptions] = useState([])

  const onFinish = (inputData) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการส่งแบบประเมินการปฏิบัติงาน ?'),
      onOk() {
        sendFormByAssessmentAuditWorkFn({
          variables: {
            input: encryptInput({
              formResultId: dataObj.formResultId,
              makerBy: inputData.makerBy,
            }),
          },
        })
      },
      onCancel() {},
    })
  }

  useEffect(() => {
    if (open) {
      getPersonnelsByAssessmentAuditWorkfn({
        variables: {
          personnelCode: userData.pCode,
        },
      })
    }
  }, [open])

  useEffect(() => {
    if (getPersonnelsByAssessmentAuditWork?.data) {
      const res = getPersonnelsByAssessmentAuditWork?.data?.getPersonnelsByAssessmentAuditWork
      setPersonnelOptions(
        _.map(res, (dt, key) => {
          return {
            key: key,
            value: dt.personnelCode,
            label: checkLanguageReturnData(dt.fullNameTH, dt.fullNameEN),
            source: dt,
          }
        }),
      )
    }
    if (getPersonnelsByAssessmentAuditWork.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(getPersonnelsByAssessmentAuditWork.error)),
        onOk() {},
      })
    }
  }, [getPersonnelsByAssessmentAuditWork?.data])

  useEffect(() => {
    if (sendFormByAssessmentAuditWork) {
      Alert({
        type: 'success',
        title: t('ส่งแบบประเมินการปฏิบัติงานสำเร็จ'),
        onOk() {
          onClose(false)
          navigate('/auditoperations/assessmentaudit/assessmentauditdetails', {
            state: {
              pageType: 'edit',
              refCode: sendFormByAssessmentAuditWork.sendFormByAssessmentAuditWork.formResultId,
            },
          })
          window.location.reload()
        },
      })
    }
    if (error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถส่งแบบประเมินการปฏิบัติงานได้'),
        content: t(displayError(error)),
        onOk() {},
      })
    }
  }, [sendFormByAssessmentAuditWork])

  return (
    <React.Fragment>
      {(getPersonnelsByAssessmentAuditWork?.loading || loadingSave) && <SpinnersNew />}
      <ModalNew
        visible={open}
        testTitle={t('ส่งแบบประเมินการปฏิบัติงาน')}
        onSubmit={form.submit}
        onClose={(e) => {
          onClose(e)
        }}
        type="medium"
      >
        <Row align="center">
          <Col {...Responsive} md={16} offset={6} align="left">
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={6} align="left">
                <Label type="tab-header-inactive">{t('ประจำปี')}</Label>
              </Col>
              <Col md={18} align="left">
                <Label type="body-primary">{dataObj.formYear}</Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={6} align="left">
                <Label type="tab-header-inactive">{t('ชื่อแบบประเมิน')}</Label>
              </Col>
              <Col md={18} align="left">
                <Label type="body-primary">{`${dataObj.formCode} : ${dataObj.formName}`}</Label>
              </Col>
            </Row>
            <Row style={{ marginBottom: 24 }} align="center">
              <Col md={6} align="left">
                <Label type="tab-header-inactive">{t('ผู้ถูกประเมิน')}</Label>
              </Col>
              <Col md={18} align="left">
                <Label type="body-primary">{dataObj.fullNameAssessed}</Label>
              </Col>
            </Row>
          </Col>
        </Row>
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name="makerBy"
            label={<Label type="tab-header-inactive">{t('ผู้ทำการประเมิน')}</Label>}
            rules={[{ required: true, message: t('กรุณาเลือกผู้ทำการประเมิน') }]}
          >
            <Select
              formname={formName}
              data={personnelOptions}
              // defaultValue={0}
              placeholder={t('เลือกผู้ทำการประเมิน')}
              scrollableId={formName}
              onChange={(e) => {
                form.setFieldsValue({
                  makerBy: e,
                })
              }}
              showSearch
            />
          </Form.Item>
        </Form>
      </ModalNew>
    </React.Fragment>
  )
}
