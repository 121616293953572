import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { GET_AUDIT_RCM_FILES, GET_PERSONNEL_ORGANIZATION, GET_AUDIT_RCMS_BY_ID } from '../../graphql/Query'
import { UPDATE_AUDIT_RCMS_FILE } from '../../graphql/Mutation'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { ButtonNew } from '../../../../../components/button/Button'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { InputNew } from '../../../../../components/input/Input'
import { UploadNew } from '../../../../../components/upload/Upload'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { Alert } from '../../../../../components/alert/Alert'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'
import { validateURL, displayError } from '../../../../../utilitys/helper'
import { downloadFile } from '../../../../../utilitys/files'
import { encryptInput } from '../../../../../utilitys/crypto'

const DocumentTab = (props) => {
  const formname = 'documentForm'
  const ResponsiveOut = { md: 24, lg: 24 }
  const Responsive = { md: 24, lg: 24 }

  const { menu } = props

  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { state } = useLocation()
  const [user] = useDecodeUser()

  if (!state) return <Navigate to={'/auditoperations/racmatrix'} />

  const [dataList, setDataLists] = useState([])
  const [isDisable, setIsDisable] = useState(false)
  const [, /* idFile */ setIdFile] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])
  const [deleteting, setDeleteting] = useState(false)
  const [download, setDownload] = useState(false)
  const [isPersonnelOrg, setIsPersonnelOrg] = useState(null)
  const [, /* isCanEstimate */ setIsCanEstimate] = useState(false)
  const [isCanEdit, setIsCanEdit] = useState(false)

  const [getAuditRCMsFileFn, getAuditRCMsFile] = useLazyQuery(GET_AUDIT_RCM_FILES)
  const [updateAuditRCMsFileFn, updateAuditRCMsFile] = useMutation(UPDATE_AUDIT_RCMS_FILE)
  const [getPersonnelOrganizationFn, getPersonnelOrganization] = useLazyQuery(GET_PERSONNEL_ORGANIZATION)
  const [getAuditRCMFn, getAuditRCM] = useLazyQuery(GET_AUDIT_RCMS_BY_ID)

  useEffect(() => {
    if (state?.rcmId) {
      getAuditRCMFn({ variables: { rcmId: encryptInput(state?.rcmId) } })
    }
  }, [state?.rcmId])

  useEffect(() => {
    if (user?.orgCode) {
      getPersonnelOrganizationFn({
        variables: {
          input: encryptInput({
            organizationCode: user?.orgCode,
            personnelCode: user?.pCode,
            organizationChecked: 2,
          }),
        },
      })
    }
  }, [user?.orgCode, user?.pCode])

  useEffect(() => {
    if (state?.typeAction) {
      switch (state?.typeAction) {
        case 'edit':
          setIsDisable(isCanEdit ? false : true)
          break
        case 'view':
          setIsDisable(isCanEdit ? true : true)
          break
        default:
          setIsDisable(isCanEdit ? false : false)
          break
      }
    }
  }, [state?.typeAction, isCanEdit])

  useEffect(() => {
    if (getPersonnelOrganization?.data) {
      setIsPersonnelOrg(
        getPersonnelOrganization?.data?.getPersonnelOrganization.length
          ? getPersonnelOrganization?.data?.getPersonnelOrganization[0]
          : null,
      )
    }
    if (getPersonnelOrganization?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getPersonnelOrganization?.error)),
        onOk() {},
      })
    }
  }, [getPersonnelOrganization?.data])

  useEffect(() => {
    if (getAuditRCM?.data) {
      const dataRCM = getAuditRCM?.data?.getAuditRCMsById?.auditRCMs

      /* ผู้สร้างสามารถแก้ไขแบบประเมินได้ */
      if (parseInt(user?.userId) === parseInt(dataRCM?.createdBy)) {
        setIsCanEdit(true)
      } else {
        setIsCanEdit(false)
      }

      if (isPersonnelOrg) {
        /* คนๆ นี้ต้องเป็น ผู้จัดทำ หรือ ผู้สอบทาน */
        /* จึงจะสามารทำแบบประเมิน */
        if (
          isPersonnelOrg?.personnelCode.toLowerCase() === dataRCM.personnelMaker.toLowerCase() ||
          isPersonnelOrg?.personnelCode.toLowerCase() === dataRCM.personnelReview.toLowerCase() ||
          parseInt(isPersonnelOrg?.isUserId) === parseInt(dataRCM?.createdBy)
        ) {
          setIsCanEstimate(true)
        } else {
          setIsCanEstimate(false)
        }
      }
    }

    if (getAuditRCM?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditRCM?.error)),
        onOk() {},
      })
    }
  }, [getAuditRCM?.data, isPersonnelOrg, user?.userId])

  useEffect(() => {
    if (state?.typeAction === 'edit' || state?.typeAction === 'view')
      getAuditRCMsFileFn({ variables: { rcmId: encryptInput(state?.rcmId) } })
  }, [state?.typeAction, state?.rcmId])

  useEffect(() => {
    if (getAuditRCMsFile?.data) {
      const data = getAuditRCMsFile?.data.getAuditRCMsFileById
      setDataLists(
        data.map((item) => {
          const uid = uuidv4()

          const dataFile = {
            key: uid,
            fileId: item.fileId,
            filename: item.filename,
            links: item.links,
            tools: item.refFile,
          }

          return dataFile
        }),
      )
    }

    if (getAuditRCMsFile?.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getAuditRCMsFile?.error)),
        onOk() {},
      })
    }
  }, [getAuditRCMsFile?.data])

  useEffect(() => {
    if (updateAuditRCMsFile?.data) {
      Alert({ type: 'success', title: t('บันทึกสำเร็จ'), onOk() {} })
    }

    if (updateAuditRCMsFile?.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(updateAuditRCMsFile?.error)),
        onOk() {},
      })
    }
  }, [updateAuditRCMsFile?.data])

  const deleteFile = (fileName) => {
    fetch(process.env.REACT_APP_API_UPLOAD + `/deleteFile/${fileName}`, { method: 'GET' }).catch(() => {})
  }

  const onUpload = (record, value, index) => {
    const data = [...dataList]
    data[index].fileId = value.uid
    data[index].tools = { originalname: value.originalname, name: value.name }

    setDataLists([...data])
    setIdFile(value.uid)
    form.setFieldsValue({ [record.key]: { fileId: value.uid } })
  }

  const onRemove = (filename, record) => {
    let newData = dataList.filter((item) => item.key !== record.key)
    setDataLists(newData)

    if (state?.actionType === 'add') {
      setDeleteting(true)
      if (filename) deleteFile(filename)
    }
  }

  const handleAdd = () => {
    const uid = uuidv4()
    const fields = form.getFieldsValue()
    const result = Object.values(fields)

    result.push({ key: uid, fileId: null, filename: null, links: null })
    form.setFieldsValue(result)

    let newData = [...dataList]
    newData.push({ key: uid, fileId: null, filename: null, links: null })

    setDataLists(newData)
  }

  const onFinish = (input) => {
    const fileDetail = Object.values(input).map((item) => {
      return {
        fileId: item.fileId || '',
        name: item.filename,
        links: item.links,
      }
    })

    const listFileId = fileDetail.map((item) => item.fileId).filter((x) => x)

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        updateAuditRCMsFileFn({
          variables: {
            input: encryptInput({
              rcmId: state?.rcmId,
              files: listFileId.join(),
              fileDetail: JSON.stringify(fileDetail),
              listFileDelete: idFileDelete,
            }),
          },
        })
      },
      onCancel() {},
    })
  }

  const columns = [
    {
      title: t('ชื่อเอกสาร'),
      dataIndex: 'filename',
      width: '30%',
      render: (item, record) => {
        return state?.typeAction === 'edit' ? (
          <>
            <Form.Item hidden={true} name={[record.key, 'fileId']} initialValue={record.fileId}>
              <InputNew disabled={isDisable} />
            </Form.Item>
            <Form.Item
              name={[record.key, 'filename']}
              initialValue={item}
              rules={[{ required: true, message: t('กรุณากรอกชื่อเอกสาร') }]}
            >
              <InputNew
                placeholder={t('ชื่อเอกสาร')}
                disabled={isDisable}
                onChange={(e) => {
                  form.setFieldsValue({ [record.key]: { filename: e.target.value } })
                }}
              />
            </Form.Item>
          </>
        ) : (
          <>{item}</>
        )
      },
    },
    {
      title: t('ลิงค์'),
      dataIndex: 'links',
      width: '40%',
      render: (item, record) => {
        return !isDisable ? (
          <Form.Item name={[record.key, 'links']} initialValue={item}>
            <InputNew onChange={(e) => form.setFieldsValue({ [record.key]: { links: e.target.value } })} />
          </Form.Item>
        ) : item ? (
          <a href={validateURL(item)} target="_blank" rel="noreferrer" style={{ color: '#2b4990' }}>
            <u>{item}</u>
          </a>
        ) : (
          '-'
        )
      },
    },
    {
      title: t('เอกสารอ้างอิง'),
      dataIndex: 'fileId',
      width: '25%',
      align: 'left',
      render: (item, record, index) => {
        return state?.typeAction === 'view' && !item ? (
          '-'
        ) : (
          <Form.Item name={[record.key, 'fileId']}>
            <UploadNew
              key={index}
              setIdFile={setIdFile}
              idFile={[record.fileId] || []}
              typeFile="file"
              inline={true}
              multiRow={true}
              setFileData={(e) => onUpload(record, e, index)}
              // actionType={state?.typeAction}
              actionType={isCanEdit ? state?.typeAction : 'view'}
              defaults={[record.fileId] || []}
            />
          </Form.Item>
        )
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '5%',
      render: (item, record) => {
        let listButton = []
        const btnDownlaod = {
          type: 'download',
          onClick: () => item && downloadFile(item.originalname, item.name, () => setDownload(false)),
        }

        const btnDelete = {
          type: 'delete',
          onClick: () => {
            setIdFileDelete([...idFileDelete, record.fileId])
            onRemove(item?.name, record)
          },
        }

        if (state?.typeAction === 'view') {
          if (item) listButton = [...listButton, btnDownlaod]
        } else {
          if (item) listButton = [...listButton, btnDownlaod]
          listButton = [...listButton, btnDelete]
        }

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} icons={isCanEdit ? listButton : []} />
          </div>
        )
      },
    },
  ]

  return (
    <>
      {(download || deleteting || getAuditRCMsFile?.loading || updateAuditRCMsFile?.loading) && <SpinnersNew />}
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ md: { span: 24 }, lg: { span: 24 } }}
        wrapperCol={{ md: { span: 24 }, lg: { span: 24 } }}
        name={formname}
      >
        <Row justify="center">
          <Col {...ResponsiveOut}>
            <Row justify="center">
              <Col {...Responsive}>
                <Datatable columns={columns} data={dataList} pagination={false} />
              </Col>
            </Row>
            {isCanEdit && (
              <Row style={{ marginTop: '5px', marginBottom: '10px' }}>
                {state?.typeAction !== 'view' && (
                  <Col {...Responsive}>
                    <ButtonNew type="addMultiple" onClick={() => handleAdd()}>
                      {t('เพิ่มเอกสาร')}
                    </ButtonNew>
                  </Col>
                )}
              </Row>
            )}
            {isCanEdit ? (
              <Row gutter={[12, 20]} justify="center" style={{ marginTop: '15px' }}>
                {state?.typeAction !== 'view' && (
                  <Col>
                    <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                      {t('บันทึก')}
                    </ButtonNew>
                  </Col>
                )}
                <Col>
                  <ButtonNew type="back" onClick={() => navigate('/auditoperations/racmatrix')}>
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Col>
              </Row>
            ) : (
              <Row gutter={[12, 20]} justify="center" style={{ marginTop: '15px' }}>
                <Col>
                  <ButtonNew type="back" onClick={() => navigate('/auditoperations/racmatrix')}>
                    {t('ย้อนกลับ')}
                  </ButtonNew>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Form>
    </>
  )
}

DocumentTab.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default DocumentTab
