import { gql } from '@apollo/client'

export const SAVE_SUB_RISK_FACTOR = gql`
  mutation SaveSubRiskFactor($input: SubRiskFactorSaveInput!) {
    saveSubRiskFactor(input: $input) {
      factorActCode
    }
  }
`

export const DELETE_SUB_RISK_FACTOR = gql`
  mutation DeleteSubRiskFactor($input: SubRiskFactorDeleteInput!) {
    deleteSubRiskFactor(input: $input) {
      message
    }
  }
`
