import styled from 'styled-components'

export const ProgressStyles = styled.div`
  .progress-color-inline .ant-progress-outer .ant-progress-inner .ant-progress-bg {
    background-color: ${(props) => props.color && props.color};
  }

  .progress-color-inline .ant-progress-text {
    color: ${(props) => props.color && props.color};
  }
`
