import { gql } from '@apollo/client'

export const GET_MASTER_OPERATION_STATUS = gql`
  query getMasterOperationStatus($input: MasterOperationStatusInput!) {
    getMasterOperationStatus(data: $input) {
      operationStatusId
      operationStatusType
      operationStatusCode
      operationStatusNameTH
      operationStatusNameEN
      isActive
    }
  }
`
