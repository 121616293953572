export const CheckStatus = (obj) => {
  let btnName = ''
  let btnText = ''
  let statusUse = ''
  let btnRoles = ''
  // เงื่อนไขรายการปรกติ ไม่มีใครส่งต่อแบบประเมินให้เรา
  if (obj.responsibilityBy === obj.userCode && obj.operationStatusCode === '11' && obj.makerBy === null) {
    btnName = 'flagOutlinedInTableBlue'
    btnText = 'ทำแบบประเมิน'
    statusUse = 'ยังไม่ประเมิน'
    btnRoles = 'edit'
    // เงื่อนไขรายการ มีคนส่งต่อแบบประเมินให้เรา
  } else if (
    obj.responsibilityBy !== obj.userCode &&
    obj.makerBy === obj.userCode &&
    obj.operationStatusCode === '12' &&
    obj.makerStatusCode === '11'
  ) {
    btnName = 'flagOutlinedInTableBlue'
    btnText = 'ทำแบบประเมิน'
    statusUse = 'ยังไม่ประเมิน'
    btnRoles = 'edit'
    // เงื่อนไขรายการ เราส่งต่อแบบประเมินให้คนอื่น
  } else if (
    obj.responsibilityBy === obj.userCode &&
    obj.makerBy !== obj.userCode &&
    obj.operationStatusCode === '12'
  ) {
    btnName = 'moreHorizontalInTableOrange'
    btnText = 'ส่งต่อแบบประเมิน'
    statusUse = 'ส่งต่อแบบประเมิน'
    btnRoles = 'edit'
    // เงื่อนไขรายการ เราส่งต่อแบบประเมินให้คนอื่น และเขาทำการบันทึกแบบประเมินแล้ว หรือ เราไม่ได้ส่งต่อแบบประเมินให้ใครแต่เราทำเอง และบันทึกแบบประเมินไว้แล้ว
  } else if (
    (obj.operationStatusCode === '20' && obj.operationStatusCode === '20') ||
    obj.operationStatusCode === '21'
  ) {
    btnName = 'moreHorizontalInTableOrange'
    btnText = 'กำลังทำแบบประเมิน'
    statusUse = 'กำลังดำเนินการ'
    btnRoles = 'edit'
    // เงื่อนไขรายการ เราส่งต่อแบบประเมินให้คนอื่น และเขาทำการบันทึก "เสร็จสิ้น" แบบประเมินแล้ว
  } else if (
    obj.responsibilityBy === obj.userCode &&
    obj.operationStatusCode === '22' &&
    obj.makerStatusCode === '31'
  ) {
    btnName = 'moreHorizontalInTableOrange'
    btnText = 'รอตรวจสอบ'
    statusUse = 'รอตรวจสอบ'
    btnRoles = 'edit'
    // เงื่อนไขรายการ มีคนส่งต่อแบบประเมินให้เรา และเราทำการบันทึก "เสร็จสิ้น" แบบประเมินแล้ว
  } else if (obj.makerBy === obj.userCode && obj.operationStatusCode === '22' && obj.makerStatusCode === '31') {
    btnName = 'moreHorizontalInTableGreen'
    btnText = 'ดูผลแบบประเมิน'
    statusUse = 'ดำเนินการแล้วเสร็จ'
    btnRoles = 'view'
    // แบบประเมิน เสร็จสิ้นการประเมิน แล้ว
  } else if (obj.operationStatusCode === '31') {
    btnName = 'moreHorizontalInTableGreen'
    btnText = 'เสร็จสิ้นการประเมิน'
    statusUse = 'เสร็จสิ้นการประเมิน'
    btnRoles = 'view'
  }
  return { btnName: btnName, btnText: btnText, statusUse: statusUse, btnRoles: btnRoles }
}
