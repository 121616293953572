import React, { useState, useEffect } from 'react'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import * as _ from 'lodash'
import { breadcrumbList } from './utils/breadcrumbData'
import { assessCooperationListColumn } from './utils/assessCooperationColumn'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable.jsx'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { handleSort } from '../../../utilitys/pagination'
import { useLanguage } from '../../../hooks/useLanguage'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { GETASSESSCOOPERATIONPAGINATION } from './graphql/Query'
import { checkLanguageReturnData } from './components/fnForManageAssessment'
import { RowStyle } from './css/tableListDateExpireStyle'
import { Alert } from '../../../components/alert/Alert'
import { displayError, displayText } from '../../../utilitys/helper'

export default function AssessCooperationList(props) {
  const { menu } = props
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const navigate = useNavigate()
  const userData = decryptData(localStorage.getItem('user'))

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])
  const [dataList, setDataList] = useState([])

  const [getAssessCooperationPaginationfn, getAssessCooperationPagination] =
    useLazyQuery(GETASSESSCOOPERATIONPAGINATION)

  const onOpenDetails = (data, pageType) => {
    navigate('/auditoperations/assesscooperation/assesscooperationdetails', {
      state: {
        pageType: pageType,
        refCode: data.formResultId,
      },
    })
  }

  useEffect(() => {
    getAssessCooperationPaginationfn({
      variables: {
        input: encryptInput({
          filters: {
            personnelCode: userData.pCode,
          },
          pagination: {
            limit: limit,
            page: page,
          },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (getAssessCooperationPagination?.data) {
      const res = getAssessCooperationPagination?.data?.getAssessCooperationPagination
      setCount(res.count)
      setDataList(
        _.map(res.result, (dt, key) => {
          return {
            key: key,
            formYear: dt.formYear ? (isLang === 'th' ? dt.formYear + 543 : dt.formYear) : '',
            formCode: dt.formCode,
            formName: checkLanguageReturnData(dt.formNameTH, dt.formNameEN),
            formNameTH: dt.formNameTH,
            formNameEN: dt.formNameEN,
            startDate: dateDisplay(dt.startDate),
            endDate: dateDisplay(dt.endDate),
            status: dt.operationStatusCode,
            operationStatusCode: dt.operationStatusCode,
            responsibilityBy: dt.responsibilityBy,
            makerBy: dt.makerBy,
            makerStatusCode: dt.makerStatusCode,
            formResultId: dt.formResultId,
            userCode: userData.pCode,
            rawStartDate: dt.startDate,
            rawEndDate: dt.endDate,
            organizationName: displayText(dt.organizationNameTH, dt.organizationNameEN),
          }
        }),
      )
    }

    if (getAssessCooperationPagination.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(getAssessCooperationPagination.error)),
        onOk() {},
      })
    }
  }, [getAssessCooperationPagination?.data])

  return (
    <React.Fragment>
      {getAssessCooperationPagination.loading && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('ประเมินความร่วมมือ')} />
      <CardNew topic={t('รายการประเมินความร่วมมือ')} icon="List" toggledrop={'false'}>
        <RowStyle>
          <Datatable
            columns={assessCooperationListColumn({ menu, onOpenDetails })}
            data={dataList}
            total={count}
            isScoll={{ x: true, y: true }}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            paginationCustom={true}
            searchCustom={true}
            pageSize={limit}
            page={page}
            handleTableChange={(e) => {
              const reNewField = [
                { projectName: displayText('projectNameTH', 'projectNameEN') },
                { formTypeName: displayText('formTypeNameTH', 'formTypeNameEN') },
                { formName: displayText('formNameTH', 'formNameEN') },
                { status: 'isActive' },
                { organizationName: displayText('organizationNameTH', 'organizationNameEN') },
              ]
              setSort(handleSort(e?.sorter, reNewField))
            }}
            rowClassName={(record) => {
              const now = new Date()
              const endDate = new Date(record.rawEndDate)
              const statusCheck = ['30', '31']
              if (
                endDate < now &&
                !statusCheck.includes(record.operationStatusCode) &&
                userData.pCode === record.responsibilityBy
              ) {
                return 'table-row-color'
              } else if (
                endDate < now &&
                !statusCheck.includes(record.makerStatusCode) &&
                userData.pCode === record.makerBy
              ) {
                return 'table-row-color'
              } else {
                return ''
              }
            }}
          />
        </RowStyle>
      </CardNew>
    </React.Fragment>
  )
}
