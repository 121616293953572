import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { GET_RAC_MATRIX_REPORT_PAGINATION, EXPORT_RAC_MATRIX_REPORT } from './graphql/Query'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { racMatrixReportListCrumb } from './utils/constants'
import { displayText, displayError } from '../../../utilitys/helper'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { encryptInput } from '../../../utilitys/crypto'
import { handleSort } from '../../../utilitys/pagination'
import { useDecodeUser } from '../../../hooks/useDecodeUser'
import RacMatrixReportFilter from './components/filter/RacMatrixReportFilter'
import ExportModal from './components/modal/ExportModal'
import SendEmailReportModal from '../components/SendEmailReportModal'

const RacMatrixReportList = (props) => {
  const wrapper = { sm: 24, md: 24, lg: 24, xl: 24 }
  const { menu } = props

  const { t } = useTranslation()
  const [user] = useDecodeUser()

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState([])
  const [visible, setVisible] = useState(false)
  const [dataExportModal, setDataExportModal] = useState(null)
  const [visibleSendMail, setVisibleSendMail] = useState(false)
  const [mailValue, setMailValue] = useState()
  const [inputSendEmail, setInputSendEmail] = useState()

  const [getRacMatrixPaginationReportFn, getRacMatrixPaginationReport] = useLazyQuery(GET_RAC_MATRIX_REPORT_PAGINATION)
  const [exportRacMatrixReportFn, exportRacMatrixReport] = useLazyQuery(EXPORT_RAC_MATRIX_REPORT)

  useEffect(() => {
    if (user?.pCode) {
      const pCode = { personnelCode: user?.pCode }

      getRacMatrixPaginationReportFn({
        variables: { input: encryptInput({ filters: { ...filters, ...pCode }, pagination: { limit, page }, sort }) },
      })
    }
  }, [page, limit, filters, sort, user?.pCode])

  useEffect(() => {
    if (getRacMatrixPaginationReport?.data) {
      const response = getRacMatrixPaginationReport?.data?.getRacMatrixReportPagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            ...data,
            key: data.projectCode,
            projectName: displayText(
              `${data.projectCode}: ${data.projectNameTH}`,
              `${data.projectCode}: ${data.projectNameEN}`,
            ),
            projectRefName: displayText(data.projectRefNameTH, data.projectRefNameEN),
            dateEnd: dateDisplay(data.dateEnd),
            dateStart: dateDisplay(data.dateStart),
            organizationName: displayText(data.organizationNameTH, data.organizationNameEN),
            personnelName: displayText(data.personnelNameTH, data.personnelNameEN),
          }
        }),
      )
    }

    if (getRacMatrixPaginationReport.error) {
      Alert({
        type: 'error',
        title: t('เกิดข้อผิดพลาด'),
        content: t(displayError(getRacMatrixPaginationReport?.error)),
        onOk() {},
      })
    }
  }, [getRacMatrixPaginationReport?.data])

  useEffect(() => {
    if (exportRacMatrixReport?.data) {
      Alert({ type: 'success', title: t('ส่งอีเมลสำเร็จ'), onOk() {} })
    }

    if (exportRacMatrixReport?.error) {
      Alert({
        type: 'error',
        title: t('ส่งอีเมลไม่สำเร็จ'),
        content: t(displayError(exportRacMatrixReport?.error)),
        onOk() {},
      })
    }
  }, [exportRacMatrixReport.data])

  useEffect(() => {
    if (inputSendEmail) {
      const obj = {
        rcmId: dataExportModal?.rcmId,
        fileType: inputSendEmail?.fileType,
        waterMark: inputSendEmail?.waterMark || '',
        lang: localStorage.getItem('lang'),
        emailData: inputSendEmail,
      }

      exportRacMatrixReportFn({ variables: { input: encryptInput(obj) } })
    }
  }, [inputSendEmail])

  const onFilter = (data) => {
    Promise.all([setPage(1), setLimit(10), setFilters(data)])
  }

  const columns = [
    { title: t('โครงการ'), dataIndex: 'projectName', width: '400px', align: 'left', sorter: { multiple: 1 } },
    { title: t('แหล่งที่มาของโครงการ'), dataIndex: 'projectRefName', width: '250px', sorter: { multiple: 2 } },
    { title: t('วันที่เริ่มต้น'), dataIndex: 'dateStart', width: '150px', sorter: { multiple: 3 }, align: 'center' },
    { title: t('วันที่สิ้นสุด'), dataIndex: 'dateEnd', width: '150px', sorter: { multiple: 4 }, align: 'center' },
    { title: t('หน่วยงานที่รับผิดชอบ'), dataIndex: 'organizationName', width: '250px', sorter: { multiple: 5 } },
    { title: t('ผู้รับผิดชอบ'), dataIndex: 'personnelName', width: '200px' },
    {
      title: '',
      dataIndex: 'tools',
      width: '120px',
      align: 'right',
      render: (text, record) => {
        let list = [
          {
            type: 'download',
            onClick: () => {
              setDataExportModal({ rcmId: record?.rcmId })
              setVisible(true)
            },
          },
          {
            type: 'settings',
            onClick: () => {
              setDataExportModal({ rcmId: record?.rcmId })
              setMailValue({ reportName: t('รายงานความเสี่ยงและการควบคุม') })
              setVisibleSendMail(true)
            },
          },
        ]
        return <ButtonGroup menu={menu} size={18} icons={list} />
      },
    },
  ]

  return (
    <>
      {(getRacMatrixPaginationReport?.loading || inputSendEmail?.loading) && <SpinnersNew />}
      <Col {...wrapper}>
        <Breadcrumb data={racMatrixReportListCrumb} title={t('รายงานความเสี่ยงและการควบคุม')} />
        <RacMatrixReportFilter onFilter={onFilter} menu={menu} />
        <div style={{ marginBottom: '2rem' }}>
          <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col {...wrapper}>
                <Datatable
                  columns={columns}
                  data={[...dataLists]}
                  total={count}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  pageSize={limit}
                  page={page}
                  searchCustom={true}
                  handleTableChange={(e) => {
                    const reNewField = [
                      { projectName: displayText('projectNameTH', 'projectNameEN') },
                      { projectRefName: displayText('projectRefNameTH', 'projectRefNameEH') },
                      { organizationName: displayText('organizationNameTH', 'organizationNameEN') },
                      { personnelName: displayText('firstNameTH', 'firstNameEN') },
                    ]

                    setSort(handleSort(e?.sorter, reNewField))
                  }}
                />
              </Col>
            </Row>
          </CardNew>
          <ExportModal
            open={visible}
            dataExportModal={dataExportModal}
            close={(e) => setVisible(e)}
            menu={props.menu}
          />
          <SendEmailReportModal
            menu={menu}
            open={visibleSendMail}
            close={(e) => setVisibleSendMail(e)}
            fileType={{ pdf: true, word: true, excel: true, csv: true }}
            setInputSendEmail={(e) => setInputSendEmail(e)}
            mailValue={mailValue}
          />
        </div>
      </Col>
    </>
  )
}

RacMatrixReportList.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default RacMatrixReportList
