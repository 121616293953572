import { Trans } from 'react-i18next'

export const controlFormListCrumb = [
  { label: <Trans>งานประเมินควบคุมภายใน</Trans>, path: '/internalcontrol/controlform' },
  { label: <Trans>แบบประเมินควบคุมภายใน</Trans> },
]

export const controlFormCreateCrumb = [
  { label: <Trans>งานประเมินควบคุมภายใน</Trans>, path: '/internalcontrol/controlform' },
  { label: <Trans>แบบประเมินควบคุมภายใน</Trans>, path: '/internalcontrol/controlform' },
  { label: <Trans>เพิ่มแบบประเมินควบคุมภายใน</Trans> },
]

export const controlFormEditCrumb = [
  { label: <Trans>งานประเมินควบคุมภายใน</Trans>, path: '/internalcontrol/controlform' },
  { label: <Trans>แบบประเมินควบคุมภายใน</Trans>, path: '/internalcontrol/controlform' },
  { label: <Trans>แก้ไขแบบประเมินควบคุมภายใน</Trans> },
]
