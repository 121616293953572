import { useTranslation } from 'react-i18next'
import { Row, Col, Form } from 'antd'
import { v4 } from 'uuid'
import { downloadFile } from '../../../../../utilitys/files'
import { validateURL } from '../../../../../utilitys/helper'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { InputNew as Input } from '../../../../../components/input/Input'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { UploadNew as Upload } from '../../../../../components/upload/Upload'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { ButtonNew as Button } from '../../../../../components/button/Button'

const AcResolutionAgendaUpload = ({ menu, form, fileData, setFileData, isDisabled, action }) => {
  const { t } = useTranslation()

  const tableFileResponsive = {
    md: 24,
  }

  const FileTableColumns = [
    {
      title: t('ชื่อเอกสาร'),
      dataIndex: 'filename',
      width: '30%',
      render: (itemText, item) => {
        return isDisabled ? (
          <>{itemText}</>
        ) : (
          <div>
            <Form.Item
              name={[item.key, 'filename']}
              initialValue={itemText}
              rules={[{ required: true, message: t('กรุณากรอกชื่อเอกสาร') }]}
            >
              <Input
                placeholder={t('ชื่อเอกสาร')}
                disabled={isDisabled}
                onChange={(e) => {
                  form.setFieldsValue({ [item.key]: { filename: e.target.value } })
                }}
              />
            </Form.Item>
          </div>
        )
      },
    },
    {
      title: t('ลิงค์'),
      dataIndex: 'links',
      width: '40%',
      render: (itemText, item) => {
        if (isDisabled) {
          if (itemText) {
            return (
              <a href={validateURL(itemText)} target="_blank" rel="noreferrer" style={{ color: '#2b4990' }}>
                <u>{itemText}</u>
              </a>
            )
          } else {
            return '-'
          }
        } else {
          return (
            <div>
              <Form.Item name={[item.key, 'links']} initialValue={itemText}>
                <Input
                  placeholder={t('links')}
                  disabled={isDisabled}
                  onChange={(e) => {
                    form.setFieldsValue({ [item.key]: { links: e.target.value } })
                  }}
                />
              </Form.Item>
            </div>
          )
        }
      },
    },
    {
      title: t('เอกสารอ้างอิง'),
      dataIndex: 'fileId',
      width: '25%',
      align: 'left',
      render: (itemText, item, index) => {
        return isDisabled && !itemText ? (
          '-'
        ) : (
          <div>
            <Form.Item
              name={[item.key, 'fileId']}
              initialValue={itemText}
              rules={[{ required: true, message: t('กรุณาเลือกเอกสาร') }]}
            >
              <Upload
                key={index}
                setIdFile={() => {}}
                idFile={[item.fileId] || []}
                typeFile="file"
                inline={true}
                multiRow={true}
                setFileData={(e) => {
                  onUpload(item, e, index)
                }}
                actionType={action}
                defaults={[item.fileId] || []}
              />
            </Form.Item>
          </div>
        )
      },
    },
    {
      title: '',
      width: '5%',
      render: (item) => {
        let listButton = []
        const btnDownload = {
          type: 'download',
          onClick: () => {
            item && downloadFile(item.tools?.originalname, item.tools?.name, () => {})
          },
          disabled: item.tools?.originalname && item.tools?.name ? false : true,
        }

        const btnDelete = {
          type: 'delete',
          onClick: () => {
            onRemove(item?.name, item)
          },
        }

        if (isDisabled && item.fileId) {
          listButton = [...listButton, btnDownload]
        } else {
          listButton = [...listButton, btnDelete]
        }

        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup menu={menu} icons={listButton} />
          </div>
        )
      },
    },
  ]

  const onUpload = (record, value, index) => {
    const data = [...fileData]
    data[index].fileId = value.uid
    data[index].tools = { originalname: value.originalname, name: value.name }

    setFileData([...data])
    form.setFieldsValue({ [record.key]: { fileId: value.uid } })
  }

  const onRemove = (filename, record) => {
    let newData = fileData.filter((item) => item.key !== record.key)
    setFileData(newData)

    if (filename) {
      deleteFile(filename)
    }
  }

  const addRowFile = () => {
    const uuid = v4()

    const newfileData = [
      ...fileData,
      {
        key: uuid,
        fileId: null,
        filename: null,
        link: null,
      },
    ]
    setFileData(newfileData)
  }

  const deleteFile = (fileName) => {
    fetch(process.env.REACT_APP_API_UPLOAD + `/deleteFile/${fileName}`, {
      method: 'GET',
    }).catch(() => {})
  }

  return (
    <>
      <Row>
        <Label type="body-header">{t('แนบเอกสาร')}</Label>
        <Col {...tableFileResponsive}>
          <Datatable
            // enableScroll={{ x: true, y: false }}
            // scroll={{ x: 1000 }}
            columns={FileTableColumns}
            data={fileData}
            pagination={false}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '5px', marginBottom: '10px' }}>
        {!isDisabled && (
          <Col {...tableFileResponsive}>
            <Button type="addMultiple" onClick={() => addRowFile()}>
              {t('เพิ่มเอกสาร')}
            </Button>
          </Col>
        )}
      </Row>
    </>
  )
}

export default AcResolutionAgendaUpload
