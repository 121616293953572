import { Trans } from 'react-i18next'
import { IconNew } from '../../../../../components/icon/Icon'

export const columnsTraining = [
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        {' '}
        <Trans>ลำดับ</Trans>
      </div>
    ),
    dataIndex: 'no',
    width: '10%',
    className: 'text-nowrap',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>ปีที่อบรม</Trans>
      </div>
    ),
    dataIndex: 'year',
    sorter: true,
    className: 'text-nowrap',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
  },
  {
    title: <Trans>วันที่เข้าร่วม (เริ่ม-สิ้นสุด)</Trans>,
    dataIndex: 'dateStart',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: <Trans>ชื่อการฝึกอบรม/สัมมนา/ประชุม</Trans>,
    dataIndex: 'course',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>ประเภท</Trans>
      </div>
    ),
    dataIndex: 'trainTypeNameTH',
    sorter: true,
    className: 'text-nowrap',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
  },
  {
    title: <Trans>ชื่อวิทยากร</Trans>,
    dataIndex: 'lecturer',
    sorter: true,
    className: 'text-nowrap',
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>จำนวนชั่วโมง</Trans>
      </div>
    ),
    dataIndex: 'hour',
    sorter: true,
    className: 'text-nowrap',
    render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
  },
  {
    title: () => (
      <div style={{ textAlign: 'center' }}>
        <Trans>ไฟล์แนบ</Trans>
      </div>
    ),
    dataIndex: 'attachFile',
    sorter: true,
    width: '15%',
    className: 'text-nowrap',
    render: (record) => {
      return record ? (
        <div style={{ textAlign: 'center', cursor: 'pointer' }}>
          <IconNew icon={'FileText'} size={14} color="" onClick={() => {}} />
        </div>
      ) : (
        ''
      )
    },
  },
  {
    title: '',
    dataIndex: 'action',
    width: '20%',
    className: 'text-nowrap',
  },
]
