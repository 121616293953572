import { gql } from '@apollo/client'

export const CREATE_ORGANIZATION = gql`
  mutation createOrganization($input: CreateOrganizationsInput!) {
    createOrganization(input: $input)
  }
`

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganization($input: UpdateOrganizationsInput!) {
    updateOrganization(input: $input)
  }
`

export const UPDATE_NAME_ORGANIZATION = gql`
  mutation updateNameOrganization($input: UpdateNameOrganizationsInput!) {
    updateNameOrganization(input: $input)
  }
`

export const DELETE_ORGANIZATION = gql`
  mutation deleteOrganization($organizationCode: String!) {
    deleteOrganization(organizationCode: $organizationCode)
  }
`
