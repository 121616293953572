import React from 'react'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { Space, Row, Col } from 'antd'
import { ButtonDowloadsExcel } from '../../../components/button/ButtonDowloadsExcel'

const ButtonIndex = (props) => {
  let code = ` import { ButtonNew } from '../../../components/button/Button'   

    export default function App(props) {
        const { menu } = props
        return <> 
                <ButtonNew type="primary" htmlType="submit" roles={{ type: "add", menu: menu }} >
                    บันทึก
                </ButtonNew>
                <ButtonNew type="primary" htmlType="submit" roles={{ type: "add", menu: menu }} >
                    ยืนยัน
                </ButtonNew>
                <ButtonNew type="primary" roles={{ type: "add", menu: menu }} >
                    เพิ่ม
                </ButtonNew>
                <ButtonNew type="success" roles={{ type: "add", menu: menu }} >
                    เสร็จสิ้น
                </ButtonNew>
                <ButtonNew type="search" >
                    ค้นหา
                </ButtonNew>
                <ButtonNew type="primary" width={121} height={37} roles={{ type: "add", menu: menu }} >
                  เลขที่หนังสือ
                </ButtonNew>
        </>
    }
`

  let code2 = `
    import { ButtonNew } from '../../../components/button/Button'

    export default function App(props) {
      const { menu } = props
      return <> 
            <ButtonNew type="back" >
              ยกเลิก
            </ButtonNew>
            <ButtonNew type="back" >
              ย้อนกลับ
            </ButtonNew>
            <ButtonNew type="allow" roles={{ type: "approve", menu: menu }} >
              อนุญาต
            </ButtonNew>
            <ButtonNew type="noAllow" roles={{ type: "approve", menu: menu }} >
              ไม่อนุญาต
            </ButtonNew>
            <ButtonNew type="clear" >
              ล้างค่า
            </ButtonNew>
            <ButtonNew type="upload">
              อัพโหลด
            </ButtonNew>
      </>
    }

    `

  let code3 = `
    import { ButtonNew } from '../../../components/button/Button'

    export default function App(props) {
      const { menu } = props
      return <> 
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                เพิ่มการตรวจสอบ
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                เพิ่มแผนปฎิบัติงาน
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                เพิ่มคลังขั้นตอนปฏิบัติงาน
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                เพิ่มขั้นตอนหลัก
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                เพิ่มขั้นตอนย่อย
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                เพิ่มการประเมินความเสี่ยง
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                เพิ่มการควบคุม
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                เพิ่มคลังขั้นตอน
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                เพิ่มผู้รับผิดชอบ
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                เพิ่มงานมอบหมาย
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                เพิ่มการกฎิบัติงานรายวัน
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                คำขออนุมัติ
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                ข้อเสนอแนะ
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                สาเหตุ
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                บันทึกผลการตรวจสอบ
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                บันทึกการให้คำปรึกษา
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                บันทึกปิดตรวจ
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                เพิ่มการเปิดตรวจ
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: "add", menu: menu }} >
                เพิ่มแบบประเมิน
              </ButtonNew>
      </>
    }
    `

  let code4 = `
    import { ButtonNew } from '../../../components/button/Button'

    export default function App(props) {
      const { menu } = props
      return <> 
              <ButtonNew type="viewTable" roles={{ type: "view", menu: menu }} >
                ดูผลการประเมิน
              </ButtonNew>
      </>
    }
    `

  let code5 = `
    import { ButtonNew } from '../../../components/button/Button'

    export default function App(props) {
      const { menu } = props
      return <> 
              <ButtonNew type="moreHorizontalInTableOrange" roles={{ type: "edit", menu: menu }} >
                แก้ไขการประเมิน
              </ButtonNew>
              <ButtonNew type="moreHorizontalInTableGreen" roles={{ type: 'edit', menu: menu }}>
                พิจารณาแล้ว
              </ButtonNew>
              <ButtonNew type="flagOutlinedInTableBlue" roles={{ type: "add", menu: menu }} >
                เริ่มการประเมิน
              </ButtonNew>
              <ButtonNew type="checkCircleInTableBlue" roles={{ type: "approve", menu: menu }} >
                อนุมัติโครงการ
              </ButtonNew>
              <ButtonNew type="moreHorizontalInTableGray" roles={{ type: "add", menu: menu }} >
                ตรวจสอบเสร็จสิ้น
              </ButtonNew>
              <ButtonNew type="xInTableOrange" width={92} height={36}>
                ยกเลิก
              </ButtonNew>
              <ButtonNew type="checkCircleInTableBlue" width={92} height={36}>
                ยืนยัน
              </ButtonNew>
      </>
    }
    `

  let code6 = `
    import { ButtonNew } from '../../../components/button/Button'

    export default function App(props) {
      const { menu } = props
      return <> 
              <ButtonNew type="module" >
                งานประเมินควบคุมภายใน
              </ButtonNew>
      </>
    }
    `

  let code8 = `
    import { ButtonNew } from '../../../components/button/Button'

    export default function App(props) {
      const { menu } = props
      return <> 
              <ButtonNew type="addMultiple">
                เพิ่มข้อมูล
              </ButtonNew>
              <ButtonNew type="addMultiple">
                เพิ่มเอกสาร
              </ButtonNew>
      </>
    }
    `
  let code10 = `
    import { ButtonGroup } from '../../../components/button/ButtonGroup'

    export default function App(props) {
      const { menu } = props
      return <> 
              <ButtonGroup menu={menu}  icons={[
                {
                type: "print",
                path: "/button/print",
                },{
                type: "view",
                path: "/button/view",
                },{
                type: "edit",
                onClick: ()=>{
                  console.log("view");
                  }
                },{
                type: "delete",
                onClick: ()=>{
                  console.log("delete");
                  }
                },{
                type: "approve",
                onClick: ()=>{
                  console.log("approve");
                  }
                },{
                type: "download",
                onClick: ()=>{
                  console.log("download");
                  }
                },{
                type: "calendar",
                onClick: ()=>{
                  console.log("calendar");
                  }
                },{
                type: "filetext",
                onClick: ()=>{
                  console.log("filetext");
                  }
                },{
                type: "copy",
                onClick: ()=>{
                  console.log("copy");
                  }
                }
            ]} 
            />
      </>
    }
    `

  let code11 = `
    import ButtonDowloadsExcel from '../../../components/button/ButtonDowloadsExcel'

    export default function App(props) {
      return <> 
      <ButtonDowloadsExcel
      file={{
        filePath: '../../../assets/templateexcel/Template_Import_Calendar.xlsx',
        fileName: 'Template_Import_Calendar.xlsx',
      }}
    >
      {'ดาวน์โหลด Template .XLSX'}
    </ButtonDowloadsExcel>
      </>
    }
    `
  const { menu } = props
  return (
    <React.Fragment>
      <div className="site-layout-background">
        <Row>
          <Col span={2}></Col>
          <Col span={19}>
            <div style={{ marginBottom: '15px' }}>
              <Space size={[8, 8]} wrap>
                <ButtonNew type="primary" htmlType="submit" roles={{ type: 'add', menu: menu }}>
                  บันทึก
                </ButtonNew>
                <ButtonNew type="primary" htmlType="submit" roles={{ type: 'add', menu: menu }}>
                  ยืนยัน
                </ButtonNew>
                <ButtonNew type="primary" roles={{ type: 'add', menu: menu }}>
                  เพิ่ม
                </ButtonNew>
                <ButtonNew type="success" roles={{ type: 'add', menu: menu }}>
                  เสร็จสิ้น
                </ButtonNew>
                <ButtonNew type="search" iconname="">
                  ค้นหา
                </ButtonNew>
                <ButtonNew type="primaryEdit" roles={{ type: 'edit', menu: menu }}>
                  แก้ไขรายละเอียด
                </ButtonNew>
                <ButtonNew type="primary" width={121} height={37} roles={{ type: 'add', menu: menu }}>
                  เลขที่หนังสือ
                </ButtonNew>
              </Space>
            </div>
          </Col>
        </Row>

        <div style={{ marginBottom: '50px' }}>
          <CodeBlock code={code} />
        </div>

        <Row>
          <Col span={2}></Col>
          <Col span={19}>
            <div style={{ marginBottom: '15px' }}>
              <Space size={[8, 8]} wrap>
                <ButtonNew type="back">ยกเลิก</ButtonNew>
                <ButtonNew type="back">ย้อนกลับ</ButtonNew>
                <ButtonNew type="allow" roles={{ type: 'approve', menu: menu }}>
                  อนุญาต
                </ButtonNew>
                <ButtonNew type="noAllow" roles={{ type: 'approve', menu: menu }}>
                  ไม่อนุญาต
                </ButtonNew>
                <ButtonNew type="clear">ล้างค่า</ButtonNew>
                <ButtonNew type="upload" iconname="">
                  อัพโหลด
                </ButtonNew>
              </Space>
            </div>
          </Col>
        </Row>

        <div style={{ marginBottom: '50px' }}>
          <CodeBlock code={code2} />
        </div>

        <Row>
          <Col span={2}></Col>
          <Col span={19}>
            <div style={{ marginBottom: '15px' }}>
              <Space size={[8, 8]} wrap>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  เพิ่มการตรวจสอบ
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  เพิ่มแผนปฎิบัติงาน
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  เพิ่มคลังขั้นตอนปฏิบัติงาน
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  เพิ่มขั้นตอนหลัก
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  เพิ่มขั้นตอนย่อย
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  เพิ่มการประเมินความเสี่ยง
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  เพิ่มการควบคุม
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  เพิ่มคลังขั้นตอน
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  เพิ่มผู้รับผิดชอบ
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  เพิ่มงานมอบหมาย
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  เพิ่มการกฎิบัติงานรายวัน
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  คำขออนุมัติ
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  ข้อเสนอแนะ
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  สาเหตุ
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  บันทึกผลการตรวจสอบ
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  บันทึกการให้คำปรึกษา
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  บันทึกปิดตรวจ
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  เพิ่มการเปิดตรวจ
                </ButtonNew>
                <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: menu }}>
                  เพิ่มแบบประเมิน
                </ButtonNew>
              </Space>
            </div>
          </Col>
        </Row>

        <div style={{ marginBottom: '50px' }}>
          <CodeBlock code={code3} />
        </div>

        <Row>
          <Col span={2}></Col>
          <Col span={19}>
            <div style={{ marginBottom: '15px' }}>
              <Space size={[8, 8]} wrap>
                <ButtonNew type="viewTable" roles={{ type: 'view', menu: menu }}>
                  ดูผลการประเมิน
                </ButtonNew>
              </Space>
            </div>
          </Col>
        </Row>

        <div style={{ marginBottom: '50px' }}>
          <CodeBlock code={code4} />
        </div>

        <Row>
          <Col span={2}></Col>
          <Col span={19}>
            <div style={{ marginBottom: '15px' }}>
              <Space size={[8, 8]} wrap>
                <ButtonNew type="moreHorizontalInTableOrange" roles={{ type: 'edit', menu: menu }}>
                  แก้ไขการประเมิน
                </ButtonNew>
                <ButtonNew type="moreHorizontalInTableGreen" roles={{ type: 'edit', menu: menu }}>
                  พิจารณาแล้ว
                </ButtonNew>
                <ButtonNew type="downloadInTableOrange" roles={{ type: 'export', menu: menu }}>
                  ดาวน์โหลดเอกสาร
                </ButtonNew>
                <ButtonNew type="flagOutlinedInTableBlue" roles={{ type: 'add', menu: menu }}>
                  เริ่มการประเมิน
                </ButtonNew>
                <ButtonNew type="checkCircleInTableBlue" roles={{ type: 'approve', menu: menu }}>
                  อนุมัติโครงการ
                </ButtonNew>
                <ButtonNew type="checkCircleInTableBlue" roles={{ type: 'approve', menu: menu }}>
                  อนุมัติโครงการ
                </ButtonNew>
                <ButtonNew type="fileTextCircleInTableBlue" roles={{ type: 'view', menu: menu }}>
                  ดูเอกสาร
                </ButtonNew>
                <ButtonNew type="moreHorizontalInTableGray" roles={{ type: 'add', menu: menu }}>
                  ตรวจสอบเสร็จสิ้น
                </ButtonNew>
                <ButtonNew type="xInTableOrange" width={92} height={36}>
                  ยกเลิก
                </ButtonNew>
                <ButtonNew type="checkCircleInTableBlue" width={92} height={36}>
                  ยืนยัน
                </ButtonNew>
              </Space>
            </div>
          </Col>
        </Row>

        <div style={{ marginBottom: '50px' }}>
          <CodeBlock code={code5} />
        </div>

        <Row>
          <Col span={2}></Col>
          <Col span={19}>
            <div style={{ marginBottom: '15px' }}>
              <Space size={[8, 8]} wrap>
                <ButtonNew type="module">งานประเมินควบคุมภายใน</ButtonNew>
              </Space>
            </div>
          </Col>
        </Row>

        <div style={{ marginBottom: '50px' }}>
          <CodeBlock code={code6} />
        </div>

        <Row>
          <Col span={2}></Col>
          <Col span={22}>
            <div style={{ marginBottom: '10px' }} roles={{ type: 'add', menu: menu }}>
              <ButtonNew type="addMultiple">เพิ่มข้อมูล</ButtonNew>
            </div>
            <div style={{ marginBottom: '15px' }} roles={{ type: 'add', menu: menu }}>
              <ButtonNew type="addMultiple">เพิ่มเอกสาร</ButtonNew>
            </div>
          </Col>
        </Row>

        <div style={{ marginBottom: '50px' }}>
          <CodeBlock code={code8} />
        </div>

        {/* <ButtonNew type="clearUseInTable" /> */}
        <Row>
          <Col span={2}></Col>
          <Col span={19}>
            <div style={{ marginBottom: '15px' }}>
              <Space size={[8, 8]} wrap>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'print',
                      path: '/button/print',
                    },
                    {
                      type: 'view',
                      path: '/button/view',
                    },
                    {
                      type: 'edit',
                      onClick: () => {
                        console.log('edit')
                      },
                    },
                    {
                      type: 'delete',
                      onClick: () => {
                        console.log('delete')
                      },
                    },
                    {
                      type: 'approve',
                      onClick: () => {
                        console.log('approve')
                      },
                    },
                    {
                      type: 'download',
                      onClick: () => {
                        console.log('download')
                      },
                    },
                    {
                      type: 'calendar',
                      onClick: () => {
                        console.log('calendar')
                      },
                    },
                    {
                      type: 'filetext',
                      onClick: () => {
                        console.log('filetext')
                      },
                    },
                    {
                      type: 'copy',
                      onClick: () => {
                        console.log('copy')
                      },
                    },
                    {
                      type: 'send',
                      onClick: () => {
                        console.log('send')
                      },
                    },
                  ]}
                />
              </Space>
            </div>
          </Col>
        </Row>

        <CodeBlock code={code10} />

        <Row>
          <Col span={19}>
            <ButtonDowloadsExcel
              file={{
                filePath: '../../../assets/templateexcel/Template_Import_Calendar.xlsx',
                fileName: 'Template_Import_Calendar.xlsx',
              }}
            >
              {'ดาวน์โหลด Template .XLSX'}
            </ButtonDowloadsExcel>
          </Col>
        </Row>

        <CodeBlock code={code11} />
      </div>
    </React.Fragment>
  )
}

export default ButtonIndex
