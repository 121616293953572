import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'antd'
import { ModalNew } from '../../../components/modal/Modal.jsx'
import { Alert } from '../../../components/alert/Alert'
import { useTranslation } from 'react-i18next'
import { InputNew } from '../../../components/input/Input.jsx'
import { RadioGroup, Radio } from '../../../components/radio/Radio.jsx'
import { LabelNew as Label } from '../../../components/label/Label.jsx'
import { useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../utilitys/crypto'
import { TASK_IN_RESPONSIBILITY_EXPORT } from './graphql/Query'
import { SpinnersNew } from '../../../components/spinners/Spinners.jsx'
import { displayError } from '../../../utilitys/helper'
import { useDecodeUser } from '../../../hooks/useDecodeUser'

const TaskInResponsibilityModal = (props) => {
  const { t } = useTranslation()
  const [user] = useDecodeUser()
  const { dataFilter /* exportAll */ } = props
  const [disableAction, setDisableAction] = useState(false)
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()

  /* ---------------- API ----------- */
  const [exportFn, { data: dataExports, loading: loadingExport, error: errorExport }] =
    useLazyQuery(TASK_IN_RESPONSIBILITY_EXPORT)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    setVisible(props.open)
  }, [props.open])

  useEffect(() => {
    if (dataExports) {
      const { fileUrl } = dataExports.taskInResponsibilityExport
      window.open(fileUrl)
    }

    if (errorExport) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorExport)),
        onOk() {},
      })
    }
  }, [dataExports])

  const Export = async (input) => {
    const dataInput = {
      ...input,
      ...dataFilter,
      waterMark: input.waterMark || '',
      personnelCode: user?.pCode,
      lang: localStorage.getItem('lang'),
    }

    await exportFn({
      variables: {
        input: encryptInput(dataInput),
      },
    })
  }

  const onSubmit = (input) => {
    Export(input)
  }

  function onClose(e) {
    setVisible(e)
    props.close(e)
  }

  return (
    <>
      {loadingExport ? <SpinnersNew /> : ''}
      <ModalNew
        visible={visible}
        testTitle={t('พิมพ์รายงาน')}
        onSubmit={() => {
          form
            .validateFields()
            .then((v) => onSubmit(v))
            .catch(() => {})
        }}
        onClose={(e) => onClose(e)}
        btnName={t('พิมพ์')}
        // type="medium"
      >
        <Form
          form={form}
          // onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="auditTeamForm"
        >
          <Form.Item
            label={<Label type="tab-header-inactive">{t('ประเภทรายงาน')}</Label>}
            name="fileType"
            initialValue={'pdf'}
            onClick={(e) => {
              if (e.target.value) {
                if (e.target.value !== 'pdf') {
                  form.setFieldsValue({
                    waterMark: '',
                  })
                  setDisableAction(true)
                } else {
                  setDisableAction(false)
                }
              }
            }}
          >
            <RadioGroup>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="pdf">
                    <Label type="tab-header-inactive">{t('PDF')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="word">
                    <Label type="tab-header-inactive">{t('Word')}</Label>
                  </Radio>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="excel">
                    <Label type="tab-header-inactive">{t('Excel')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="csv">
                    <Label type="tab-header-inactive">{t('CSV')}</Label>
                  </Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
          {!disableAction ? (
            <Form.Item name="waterMark" label={<Label type="tab-header-inactive">{t('ลายน้ำ')}</Label>}>
              <InputNew disabled={disableAction} />
            </Form.Item>
          ) : (
            ''
          )}
        </Form>
      </ModalNew>
    </>
  )
}

export default TaskInResponsibilityModal
