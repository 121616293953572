import { gql } from '@apollo/client'
export const GET_SELECT_RATE_RISK_FACTOR_PROCESS = gql`
  query GetSelectRateRiskFactorProcess($input: SelectRateRiskFactorProcessInput!) {
    getSelectRateRiskFactorProcess(input: $input) {
      rateFacProCode
      rateFacProNameTH
      rateFacProNameEN
    }
  }
`
export const GET_RATE_RISK_FACTOR_PROCESS = gql`
  query getRateRiskFactorProcess($input: SelectRateRiskFactorProcessInput!) {
    getRateRiskFactorProcess(input: $input) {
      rateFacProsId
      rateFacProCode
      rateFacProNameTH
      rateFacProNameEN
      rateFacGroupCode
      riskType
      isActive
    }
  }
`

export const GET_RATE_RISK_FACTOR_PROCESS_BY_PROJECT_CODE = gql`
  query getRateRiskFactorProcessByProjectCode($input: SelectRateRiskFactorProcessInput!) {
    getRateRiskFactorProcessByProjectCode(input: $input) {
      rateFacProsId
      rateFacProCode
      rateFacProNameTH
      rateFacProNameEN
      rateFacGroupCode
      riskType
      isActive
    }
  }
`
