import styled from 'styled-components'
export const InputStyle = styled.div`
  width: 100%;

  .ant-input,
  .ant-input-number {
    width: 100%;
    height: 37px;
    line-height: 37px;

    &::-webkit-input-placeholder {
      line-height: 37px;
    }
  }

  .ant-input-disabled {
    color: #666;
  }

  .searchbar {
    border-radius: 24px;
  }

  .right {
    .ant-input-number-input {
      text-align: right;
    }
  }

  .ant-input-password {
    height: 37px;
    .ant-input {
      height: unset;
    }
  }
`

export const InputTagStyle = styled.div`
  .ant-select {
    width: 100%;
    min-height: 37px;
    height: auto;
    .ant-select-selection-item {
      color: #333;
      background: #e6f7ff;
      border: 1px solid #90d6ff;
      .ant-select-selection-item-content {
        font-size: 14px;
      }
    }
  }
`
