import React from 'react'
import { Col } from 'antd'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { Datatable } from '../../../components/datatable/Datatable'
import { IconNew } from '../../../components/icon/Icon'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { ButtonNew } from '../../../components/button/Button'

const DataTableIndex = (props) => {
  const columns = [
    {
      title: () => <div style={{ textAlign: 'center' }}>{'ลำดับ'}</div>,
      dataIndex: 'name',
      width: '10%',
      render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
    },
    {
      title: 'ใบประกอบวิชาชีพ',
      dataIndex: 'chinese',
      sorter: true,
    },
    {
      title: 'เลขที่ใบอนุญาต',
      dataIndex: 'math',
      sorter: true,
    },
    {
      title: 'ไฟล์แนบ',
      dataIndex: 'english',
      sorter: true,
      width: '15%',
      render: (record) => {
        return record ? (
          <div style={{ textAlign: 'center', cursor: 'pointer' }}>
            <IconNew icon={'FileText'} size={14} color="" />
          </div>
        ) : (
          ''
        )
      },
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '20%',
      render: () => {
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup
              menu={props.menu}
              size={14}
              icons={[
                {
                  type: 'print',
                  path: '/button/print',
                },
                {
                  type: 'view',
                  path: '/button/view',
                },
                {
                  type: 'edit',
                  onClick: () => console.log('view'),
                },
                {
                  type: 'delete',
                  onClick: () => console.log('delete'),
                },
                {
                  type: 'approve',
                  onClick: () => console.log('approve'),
                },
                {
                  type: 'download',
                  onClick: () => console.log('download'),
                },
                {
                  type: 'calendar',
                  onClick: () => console.log('calendar'),
                },
                {
                  type: 'filetext',
                  onClick: () => console.log('filetext'),
                },
                {
                  type: 'copy',
                  onClick: () => console.log('copy'),
                },
              ]}
            />
          </div>
        )
      },
    },
  ]

  const data = [
    {
      key: '1',
      name: '1',
      chinese: 'ใบอนุญาตประกอบวิชาชีพสอบบัญชี',
      math: 'ตส 001/2564',
      english: true,
    },
    {
      key: '2',
      name: '2',
      chinese: 'ใบอนุญาตประกอบวิชาชีพบัญชี',
      math: 'ตส 023/2562',
      english: false,
    },
    {
      key: '3',
      name: '3',
      chinese: 'ใบอนุญาตประกอบวิชาชีพคำนวณ',
      math: 'ตส 021/2564',
      english: false,
    },
    {
      key: '4',
      name: '4',
      chinese: 'ใบอนุญาตประกอบวิชาชีพครู',
      math: 'ตส 001/2564',
      english: true,
    },
  ]

  const code = `
    import React from 'react'
    import { Datatable } from '../../../components/datatable/Datatable'
    import { IconNew } from '../../../components/icon/Icon'
    import { ButtonGroup } from '../../../components/button/ButtonGroup'
    import { ButtonNew } from '../../../components/button/Button'

    /*
      Passing props into Datepicker Component
      ---------------------------------------------------------------
      columns: initial header of table [array] [require],
      data: accept data from api with pagination [array] [require],
      total: accept counter data from api with pagination [number] [require],
      handleTableChange: optional filter, sorting etc. of table [object] [require],
      onChangePagination: optional pagination and response event of pagination [object],
      paginationCustom: enable/disable pagination component [boolean] [default false],
      searchCustom: enable/disable input search component [boolean] [default false],
      btnAdd: custom element in DOM search component
    */

    const columns = [
      {
        title: () => <div style={{ textAlign: 'center' }}>{'ลำดับ'}</div>,
        dataIndex: 'name',
        width: '10%',
        render: (record) => <div style={{ textAlign: 'center' }}>{record}</div>,
      },
      {
        title: 'ใบประกอบวิชาชีพ',
        dataIndex: 'chinese',
        sorter: true,
      },
      {
        title: 'เลขที่ใบอนุญาต',
        dataIndex: 'math',
        sorter: true,
      },
      {
        title: 'ไฟล์แนบ',
        dataIndex: 'english',
        sorter: true,
        width: '15%',
        render: (record) => {
          return record ? (
            <div style={{ textAlign: 'center', cursor: 'pointer' }}>
              <IconNew icon={'FileText'} size={14} color="" />
            </div>
          ) : (
            ''
          )
        },
      },
      {
        title: '',
        dataIndex: 'tools',
        width: '20%',
        render: () => {
          return (
            <div style={{ textAlign: 'center' }}>
              <ButtonGroup
                menu={props.menu}
                size={14}
                icons={[
                  {
                    type: 'print',
                    path: '/button/print',
                  },
                  {
                    type: 'view',
                    path: '/button/view',
                  },
                  {
                    type: 'edit',
                    onClick: () => console.log('view'),
                  },
                  {
                    type: 'delete',
                    onClick: () => console.log('delete'),
                  },
                  {
                    type: 'approve',
                    onClick: () => console.log('approve'),
                  },
                  {
                    type: 'download',
                    onClick: () => console.log('download'),
                  },
                  {
                    type: 'calendar',
                    onClick: () => console.log('calendar'),
                  },
                  {
                    type: 'filetext',
                    onClick: () => console.log('filetext'),
                  },
                  {
                    type: 'copy',
                    onClick: () => console.log('copy'),
                  },
                ]}
              />
            </div>
          )
        },
      },
    ]
  
    const data = [
      {
        key: '1',
        name: '1',
        chinese: 'ใบอนุญาตประกอบวิชาชีพสอบบัญชี',
        math: 'ตส 001/2564',
        english: true,
      },
      {
        key: '2',
        name: '2',
        chinese: 'ใบอนุญาตประกอบวิชาชีพบัญชี',
        math: 'ตส 023/2562',
        english: false,
      },
      {
        key: '3',
        name: '3',
        chinese: 'ใบอนุญาตประกอบวิชาชีพคำนวณ',
        math: 'ตส 021/2564',
        english: false,
      },
      {
        key: '4',
        name: '4',
        chinese: 'ใบอนุญาตประกอบวิชาชีพครู',
        math: 'ตส 001/2564',
        english: true,
      },
    ]

    export default function App(props) {
      return (
        <Datatable
          columns={columns}
          data={data}
          total={199}
          handleTableChange={(data) => console.log('Table: ', data)}
          onChangePagination={(data) => console.log('Pagination: ', data)}
          paginationCustom={true}
          searchCustom={true}
          btnAdd={
            <>
              <ButtonNew
                type="plusTableBorderPrimary"
                roles={{ type: 'add', menu: props.menu }}
                style={{ marginRight: '12px' }}
                onClick={() => console.log('HELLO')}
              >
                เพิ่มองค์ประกอบการควบคุม
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: props.menu }}>
                เพิ่มคำถาม
              </ButtonNew>
            </>
          }
        />
      )
    }
  `

  return (
    <div style={{ margin: '2rem auto' }}>
      <Col md={24} lg={20} style={{ margin: 'auto' }}>
        <Datatable
          columns={columns}
          data={data}
          total={199}
          handleTableChange={(data) => console.log('Table: ', data)}
          onChangePagination={(data) => console.log('Pagination: ', data)}
          paginationCustom={true}
          searchCustom={true}
          page={1}
          pageSize={10}
          btnAdd={
            <>
              <ButtonNew
                type="plusTableBorderPrimary"
                roles={{ type: 'add', menu: props.menu }}
                style={{ marginRight: '12px' }}
                onClick={() => console.log('HELLO')}
              >
                เพิ่มองค์ประกอบการควบคุม
              </ButtonNew>
              <ButtonNew type="plusTableBorderPrimary" roles={{ type: 'add', menu: props.menu }}>
                เพิ่มคำถาม
              </ButtonNew>
            </>
          }
        />
      </Col>
      <Col>
        <CodeBlock code={code} />
      </Col>
    </div>
  )
}

export default DataTableIndex
