import React from 'react'
import AssessmentFormList from './AssessmentFormList'

export default function AssessmentFormIndex(props) {
  return (
    <React.Fragment>
      <AssessmentFormList menu={props.menu} />
    </React.Fragment>
  )
}
