import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { GET_LIST_AUDIT_DOCUMENT_PAGINATION } from './graphql/Query'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { AuditDocumentListCrumb } from './utils/constants'
import { displayError, displayText } from '../../../utilitys/helper'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { encryptInput } from '../../../utilitys/crypto'
import { handleSort } from '../../../utilitys/pagination'
import AuditDocumentFilter from './components/filter/AuditDocumentFilter'

const AuditDocumentList = (props) => {
  const { menu } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState([])

  const [getListAuditDocumentPaginationfn, getListAuditDocumentPagination] = useLazyQuery(
    GET_LIST_AUDIT_DOCUMENT_PAGINATION,
  )

  useEffect(() => {
    getListAuditDocumentPaginationfn({
      variables: { input: encryptInput({ filters: { ...filters }, pagination: { limit, page }, sort }) },
    })
  }, [page, limit, filters, sort])

  useEffect(() => {
    if (getListAuditDocumentPagination?.data) {
      let response = getListAuditDocumentPagination?.data?.getListAuditDocumentPagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            ...data,
            key: data.projectCode,
            projectName: displayText(
              `${data.projectCode}: ${data.projectNameTH}`,
              `${data.projectCode}: ${data.projectNameEN}`,
            ),
            projectRefName: displayText(data.projectRefNameTH, data.projectRefNameEN),
            dateEnd: dateDisplay(data.dateEnd),
            dateStart: dateDisplay(data.dateStart),
            organizationName: displayText(data.organizationNameTH, data.organizationNameEN),
            personnelName: displayText(data.personnelNameTH, data.personnelNameEN),
          }
        }),
      )
    }
    const errorList = getListAuditDocumentPagination.error
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: displayError(errorList),
      })
    }
  }, [getListAuditDocumentPagination?.data])

  const onFilterAuditDocument = (data) => {
    setFilters(data)
  }

  const columns = [
    { title: t('โครงการ'), dataIndex: 'projectName', width: '400px', align: 'left', sorter: { multiple: 1 } },
    { title: t('แหล่งที่มาของโครงการ'), dataIndex: 'projectRefName', width: '250px', sorter: { multiple: 2 } },
    { title: t('วันที่เริ่มต้น'), dataIndex: 'dateStart', width: '150px', sorter: { multiple: 3 }, align: 'center' },
    { title: t('วันที่สิ้นสุด'), dataIndex: 'dateEnd', width: '150px', sorter: { multiple: 4 }, align: 'center' },
    { title: t('หน่วยงานที่รับผิดชอบ'), dataIndex: 'organizationName', width: '250px', sorter: { multiple: 5 } },
    { title: t('ผู้รับผิดชอบ'), dataIndex: 'personnelName', width: '200px', sorter: { multiple: 6 } },
    {
      title: '',
      dataIndex: 'tools',
      width: '120px',
      align: 'right',
      render: (text, data) => {
        let list = [
          {
            type: 'view',
            onClick: () =>
              navigate(`auditdocumentfiles`, {
                state: { typeAction: 'view', projectCode: data.projectCode },
              }),
          },
        ]
        return <ButtonGroup menu={menu} size={18} icons={list} />
      },
    },
  ]

  return (
    <>
      {getListAuditDocumentPagination?.loading && <SpinnersNew />}
      <Col sm={24} md={24} lg={24}>
        <Breadcrumb data={AuditDocumentListCrumb} title={t('เอกสารประกอบการตรวจสอบ')} />
        <AuditDocumentFilter onFilterAuditDocument={onFilterAuditDocument} menu={menu} />
        <div style={{ marginBottom: '2rem' }}>
          <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col sm={24} md={24} lg={24} xl={24}>
                <Datatable
                  columns={columns}
                  data={[...dataLists]}
                  total={count}
                  onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                  paginationCustom={true}
                  enableScroll={{ x: true, y: false }}
                  pageSize={limit}
                  page={page}
                  searchCustom={true}
                  handleTableChange={(e) => {
                    const reNewField = [
                      { projectName: displayText('projectNameTH', 'projectNameEN') },
                      { projectRefName: displayText('projectRefNameTH', 'projectRefNameEN') },
                      { organizationName: displayText('organizationNameTH', 'organizationNameEN') },
                      { personnelName: displayText('personnelNameTH', 'personnelNameEN') },
                    ]
                    setSort(handleSort(e?.sorter, reNewField))
                  }}
                />
              </Col>
            </Row>
          </CardNew>
        </div>
      </Col>
    </>
  )
}

AuditDocumentList.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default AuditDocumentList
