import React, { useState, useEffect } from 'react'
import { LabelNew as Label } from '../../../../../components/label/Label'
import * as _ from 'lodash'
import { Row, Col, Form, Space } from 'antd'
import { InputNew } from '../../../../../components/input/Input'
import { Radio, RadioGroup } from '../../../../../components/radio/Radio'
import { useTranslation } from 'react-i18next'
import { ButtonNew as Button } from '../../../../../components/button/Button'
import { useNavigate, useLocation } from 'react-router-dom'
// import { CardNew as Card } from '../../../../../components/card/Card'
import { Datatable } from '../../../../../components/datatable/Datatable'
import {
  GET_CANCEL_AUDIT_PROJECT_ROW_ONE,
  GETAPPROVEPROJECTNOTPLANAUDITORGANIZATIONPAGINATION,
} from '../../graphql/Query'
import { useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../../../utilitys/crypto' //encryptData
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { Alert } from '../../../../../components/alert/Alert'
import { displayError, displayText } from '../../../../../utilitys/helper'

export default function PJNPInformations() {
  const [form] = Form.useForm()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(4)
  const [count, setCount] = useState(0)
  const [dataList, setDataList] = useState([])

  const Responsive = {
    md: 24,
    lg: 24,
  }

  const column = [
    {
      title: t('ลำดับ'),
      dataIndex: 'no',
      align: 'center',
    },
    {
      title: t('รหัสหน่วยงาน'),
      dataIndex: 'organizationCode',
      align: 'left',
    },
    {
      title: t('หน่วยงาน'),
      dataIndex: 'organizationName',
      align: 'left',
    },
    {
      title: t('ระดับหน่วยงาน'),
      dataIndex: 'organizationLevelName',
      align: 'left',
    },
    {
      title: t('ผู้รับผิดชอบ'),
      dataIndex: 'fullName',
      align: 'left',
    },
  ]

  const [getCancleAuditProjectRawOnefn, getCancleAuditProjectRawOne] = useLazyQuery(GET_CANCEL_AUDIT_PROJECT_ROW_ONE)

  useEffect(() => {
    getCancleAuditProjectRawOnefn({
      variables: {
        inputData: encryptInput({
          auditProjectCode: state.refCode,
        }),
      },
    })
  }, [])

  const [getApproveAuditProjectOrganizationFn, getApproveAuditProjectOrganization] = useLazyQuery(
    GETAPPROVEPROJECTNOTPLANAUDITORGANIZATIONPAGINATION,
    {},
  )

  useEffect(() => {
    getApproveAuditProjectOrganizationFn({
      fetchPolicy: 'no-cache',
      variables: {
        inputData: {
          filters: encryptInput({ auditProjectCode: state.refCode }),
          pagination: encryptInput({ limit, page }),
          sort: [],
        },
      },
    })
  }, [getApproveAuditProjectOrganizationFn, page, limit])

  useEffect(() => {
    if (getApproveAuditProjectOrganization?.data) {
      let response = getApproveAuditProjectOrganization?.data?.getApproveProjectNotPlanAuditOrganizationPagination
      setCount(response?.count)
      setDataList(
        _.map(response?.result, (dt, keys) => {
          return {
            key: keys,
            no: dt.RowData,
            organizationCode: dt.organizationCode,
            organizationName: displayText(dt.organizationNameTH, dt.organizationNameEN),
            organizationLevelName: displayText(dt.organizationLevelNameTH, dt.organizationLevelNameEN),
            fullName: displayText(dt.fullNameTH, dt.fullNameEN),
          }
        }),
      )
    }
    if (getApproveAuditProjectOrganization.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getApproveAuditProjectOrganization.error)),
        onOk() {},
      })
    }
  }, [getApproveAuditProjectOrganization?.data])

  useEffect(() => {
    if (getCancleAuditProjectRawOne.data) {
      const res = getCancleAuditProjectRawOne.data.getCancleAuditProjectRawOne

      form.setFieldsValue({
        planName: res.planCode ? res.planCode + ' : ' + displayText(res.planNameTH, res.planNameEN) : '',
        projectCoce: res.projectCode,
        projectNameTH: displayText(res.projectNameTH, res.projectNameEN),
        projectType: res.projectTypeCode
          ? res.projectTypeCode + ' : ' + displayText(res.projectTypeNameTH, res.projectTypeNameTH)
          : '',
        projectRef: res.projectRefCode
          ? res.projectRefCode + ' : ' + displayText(res.projectRefNameTH, res.projectRefNameEN)
          : '',
        auditType: displayText(res.auditTypeNameTH, res.auditTypeNameEN),
        riskForm: displayText(res.riskFormTH, res.riskFormEN),
        projectApprove: displayText(res.approveStatusNameTH, res.approveStatusNameEN),
        projectStatus: displayText(res.projectStatusNameTH, res.projectStatusNameEN),
        isActive: res.isActive,
      })
    }
    if (getCancleAuditProjectRawOne.error) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถดึงข้อมูลได้`),
        content: t(displayError(getCancleAuditProjectRawOne.error)),
        onOk() {},
      })
    }
  }, [getCancleAuditProjectRawOne.data])

  return (
    <>
      {(getCancleAuditProjectRawOne.loading || getApproveAuditProjectOrganization.loading) && <SpinnersNew />}
      <Label type="card-title" icon="Plus">
        {t('รายละเอียดโครงการ')}
      </Label>
      <Form
        form={form}
        autoComplete="off"
        labelAlign="left"
        labelCol={{
          md: 8,
          lg: 8,
          xl: 8,
        }}
        wrapperCol={{
          md: 16,
          lg: 24,
          xl: 16,
          align: 'left',
        }}
        layout="horizontal"
        name="projectdetail_form"
      >
        <Label type="body-header">{t('รายละเอียดโครงการ')}</Label>
        <Row style={{ marginTop: 24 }} align="center">
          <Col md={24} lg={16}>
            <Form.Item name="planName" label={t('แผนการตรวจสอบ')} placeholder={t('ระบุแผนการตรวจสอบ')}>
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item
              name="projectCoce"
              label={t('รหัสโครงการ')}
              placeholder={t('ระบุรหัสโครงการ')}
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item
              name="projectNameTH"
              label={t('ชื่อโครงการภาษาไทย')}
              placeholder={t('ระบุชื่อโครงการภาษาไทย')}
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item name="projectType" label={t('ประเภทโครงการ')} placeholder={t('ระบุแบบประเมินความเสี่ยง')}>
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item
              name="projectRef"
              label={t('แหล่งที่มาของโครงการ')}
              placeholder={t('ระบุแหล่งที่มาของโครงการ')}
              rules={[{ required: true, message: 'required' }]}
            >
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item name="auditType" label={t('ประเภทการตรวจสอบ')} placeholder={t('ระบุประเภทการตรวจสอบ')}>
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item name="riskForm" label={t('แบบประเมินความเสี่ยง')} placeholder="ระบุประเภทโครงการ">
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item name="projectApprove" label={t('สถานะอนุมัติ')} placeholder="ระบุสถานะอนุมัติ">
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item name="projectStatus" label={t('สถานะการดำเนินงาน')} placeholder="ระบุสถานะการดำเนินงาน">
              <InputNew disabled={true} />
            </Form.Item>
            <Form.Item label={t('สถานะ')} name="isActive" valuePropName="checked">
              <RadioGroup disabled={true} value="1">
                <Space size={[8, 8]} wrap>
                  <Radio value="1">{t('ใช้งาน')}</Radio>
                  &nbsp;
                  <Radio value="2">{t('ไม่ใช้งาน')}</Radio>
                </Space>
              </RadioGroup>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Label type="card-title" icon="List">
        {t('หน่วยงานรับตรวจ')}
      </Label>
      <Row gutter={[16, 16]}>
        <Col {...Responsive}>
          <Datatable
            columns={column}
            data={[...dataList]}
            total={count}
            isScoll={{ x: true, y: true }}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            paginationCustom={true}
            searchCustom={true}
            pageSize={limit}
            page={page}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 24, marginBottom: 24 }} align="center">
        <Col md={3}>
          <Button
            type="back"
            onClick={() => {
              navigate('/auditplanning/approvecancelproject', {
                state: state,
              })
            }}
          >
            {t('ย้อนกลับ')}
          </Button>
        </Col>
      </Row>
    </>
  )
}
