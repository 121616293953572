import styled from 'styled-components'

export const SpinnersStyle = styled.div`
  display: table;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.19);
  z-index: 9000 !important;

  .spinner-title {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .spinner-title .ant-spin-text {
    margin-top: 10px;
    margin-left: 20px;
    color: #000;
  }
`

export const SpinnersSmaillStyle = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: rgb(0,0,0,0.1); */
  z-index: 5;
  top: 0;
  left: 0;
  .ant-spin {
    .ant-spin-dot-item {
      background-color: #1d76bb;
    }
  }
`
