import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { ModalNew } from '../../../../../components/modal/Modal'
import { colunmOrganization } from '../../utils/columnAllInRick'
import { Datatable } from '../../../../../components/datatable/Datatable'
import OrganizationFilter from '../filter/OrganizationFilter'
import { GETRISKMODELMASTERORGANIZATION } from '../../graphql/Query'
import { checkLanguageReturnData } from '../../components/fnForTemplateRisk'
import { useLanguage } from '../../../../../hooks/useLanguage'
import { encryptInput } from '../../../../../utilitys/crypto' //encryptData decryptData,
import { Alert } from '../../../../../components/alert/Alert'
import { displayError } from '../../../../../utilitys/helper'

export default function OrganizationsModal(props) {
  //   const { menu } = props
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [getData, setData] = useState([])

  const [getRiskModelMasterOrganizationfn, getRiskModelMasterOrganization] =
    useLazyQuery(GETRISKMODELMASTERORGANIZATION)

  const onClose = (e) => {
    props.onClose(e)
  }

  const onSaveModal = () => {
    props.objData(getData)
    props.onClose(false)
  }

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const onSelectChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys)
    setData(selectedRows)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => onSelectChange(selectedRowKeys, selectedRows),
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User',
      name: record.name,
    }),
  }

  useEffect(() => {
    getRiskModelMasterOrganizationfn({
      variables: {
        input: encryptInput({}),
      },
    })
  }, [props.open])

  useEffect(() => {
    if (getRiskModelMasterOrganization.data) {
      setCount(getRiskModelMasterOrganization.data.getMasterOrganizationByRiskModel.leght)
      setDataLists(
        getRiskModelMasterOrganization.data.getMasterOrganizationByRiskModel.map((dt, key) => {
          return {
            key: key,
            no: key + 1,
            organizationCode: dt.organizationCode,
            organizationName: checkLanguageReturnData(isLang, dt.organizationNameTH, dt.organizationNameEN),
            organizationNameTH: dt.organizationNameTH,
            organizationNameEN: dt.organizationNameEN,
            organizationLevelCode: dt.organizationLevelCode,
            organizationParentCode: dt.organizationParentCode,
            organizationChecked: dt.organizationChecked,
            isActive: dt.isActive,
            organizationLevelNameTH: dt.organizationLevelNameTH,
            organizationLevelNameEN: dt.organizationLevelNameEN,
            organizationLevelName: checkLanguageReturnData(
              isLang,
              dt.organizationLevelNameTH,
              dt.organizationLevelNameEN,
            ),
            fullNameTH: dt.fullNameTH,
            fullNameEN: dt.fullNameEN,
            fullName: checkLanguageReturnData(isLang, dt.fullNameTH, dt.fullNameEN),
          }
        }),
      )
    }
    if (getRiskModelMasterOrganization.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getRiskModelMasterOrganization.error)),
        onOk() {},
      })
    }
  }, [getRiskModelMasterOrganization.data])

  useEffect(() => {
    if (props.dataSelect && dataLists) {
      let lookup = _.keyBy(props.dataSelect, (o) => {
        return o.organizationCode
      })
      // find all users where "user + age" exists in index, one loop, quick lookup. no nested loops
      let result = _.filter(dataLists, (u) => {
        return lookup[u.organizationCode] !== undefined
      })
      setSelectedRowKeys(result.map((item) => item.key))
    }
  }, [props.dataSelect, dataLists])

  return (
    <>
      <ModalNew
        visible={props.open}
        testTitle={t('เพิ่มคลังประเมินความเสี่ยง')}
        onSubmit={onSaveModal}
        onClose={(e) => {
          onClose(e)
        }}
        type="big"
      >
        <OrganizationFilter
          getValue={(e) => {
            getRiskModelMasterOrganizationfn({
              variables: {
                input: encryptInput({
                  organizationCode: e.organizationCode ? e.organizationCode : '',
                  organizationNameTH: e.organizationName ? e.organizationName : '',
                  organizationLevelCode: e.organizationLevelCode ? e.organizationLevelCode : '',
                }),
              },
            })
          }}
        />
        <Datatable
          columns={colunmOrganization}
          data={dataLists}
          total={count}
          isScoll={{ x: true, y: true }}
          paginationCustom={false}
          pagination={true}
          pageSize={5}
          page={1}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
        />
      </ModalNew>
    </>
  )
}
