import { gql } from '@apollo/client'

export const GET_MASTER_CHOICE_GROUPS = gql`
  query getMasterChoiceGroups($input: MasterChoiceGroupsInput!) {
    getMasterChoiceGroups(data: $input) {
      choiceGroupId
      choiceGroupCode
      choiceGroupNameTH
      choiceGroupNameEN
      isActive
    }
  }
`
