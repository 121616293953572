import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Form, Radio, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew as Card } from '../../../components/card/Card'
import { LabelNew } from '../../../components/label/Label'
import { InputNew } from '../../../components/input/Input'
import { displayError, displayText, regexEng, regexEngNoSpace } from '../../../utilitys/helper'
import { RadioGroup } from '../../../components/radio/Radio'
import { Datatable } from '../../../components/datatable/Datatable'
import { Alert } from '../../../components/alert/Alert'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Columns } from './utils/subRiskFactorsFormColumn'
import { ButtonNew } from '../../../components/button/Button'
import SelectRiskFactors from '../../../components/inputfromapi/selectriskfactors/SelectRiskFactors'
import { encryptInput } from '../../../utilitys/crypto'
import { SAVE_SUB_RISK_FACTOR } from './graphql/Mutation'
import { GET_SUB_RISK_FACTORS } from './graphql/Query'

const SubRiskFactorForm = ({ menu }) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const { state } = useLocation()
  if (!state) {
    return <Navigate to={'/administrator/subriskfactors'} />
  }

  const { type_action = 'view', refCode: factorActCode } = state

  useEffect(() => {
    if (factorActCode) {
      const dataInput = {
        factorActCode: factorActCode,
      }
      callGet({
        variables: {
          input: encryptInput(dataInput),
        },
      })
      if (type_action === 'view') seteditable(false)
    }
  }, [factorActCode])

  const [callGet, { loading: loadingGet, error: errorGet, data: dataGet }] = useLazyQuery(GET_SUB_RISK_FACTORS)

  useEffect(() => {
    if (errorGet) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorGet)),
      })
    }
  }, [errorGet])

  useEffect(() => {
    if (dataGet) {
      const { getSubRiskFactor } = dataGet

      if (getSubRiskFactor) {
        const mapDataList = getSubRiskFactor.riskFactorActionRisk.map((items, key) => {
          return { ...items, key, factorActRiskName: displayText(items.factorActRiskNameTH, items.factorActRiskNameEN) }
        })
        const subRiskForm = {
          ...getSubRiskFactor,
          factorActNameTH: getSubRiskFactor.factorActNameTH,
          factorActNameEN: getSubRiskFactor.factorActNameEN,
          factorActName: displayText(getSubRiskFactor.factorActNameTH, getSubRiskFactor.factorActNameEN),
          riskFactorActionRisk: mapDataList,
        }

        form.setFieldsValue(subRiskForm)
        setDataLists(mapDataList)
      }
    }
  }, [dataGet])

  const formName = 'subRiskForm'

  const [editable, seteditable] = useState(true)

  const renderTextTitle = () => {
    if (type_action === 'add') return 'เพิ่มปัจจัยความเสี่ยงย่อย'
    if (type_action === 'view') return 'ดูปัจจัยความเสี่ยงย่อย'
    if (type_action === 'edit') return 'แก้ไขปัจจัยความเสี่ยงย่อย'
    return 'เพิ่มปัจจัยความเสี่ยงย่อย'
  }

  const renderIconTitle = () => {
    if (type_action === 'view') return 'FileText'
    if (type_action === 'edit') return 'Edit2'
    else return 'Plus'
  }

  const arrCrumb = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/subriskfactors' },
    { label: t('จัดการข้อมูลปัจจัยความเสี่ยงย่อย'), path: '/administrator/subriskfactors' },
    { label: t(renderTextTitle()) },
  ]

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_SUB_RISK_FACTOR)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          onSuccess()
        },
      })
    }
  }, [dataSave])

  const [dataLists, setDataLists] = useState([])

  const handlePressAdd = () => {
    let newData = {
      key: dataLists.length,
      factorActRiskCode: null,
      factorActRiskName: null,
      factorActRiskNameTH: null,
      factorActRiskNameEN: null,
      remark: null,
    }
    const fields = form.getFieldsValue()
    const result = Object.values(fields)

    result.push(newData)
    setDataLists([...dataLists, newData])
    form.setFieldsValue(result)
  }

  const fncDelete = (index) => {
    const fields = form.getFieldsValue()
    let { riskFactorActionRisk } = fields

    let filterTable = [..._.filter(riskFactorActionRisk, (e, key) => key !== index)]

    if (dataLists.length > 1) {
      let newTable = filterTable.map((item, key) => {
        return { ...item, key }
      })

      setDataLists(newTable)
      fields.riskFactorActionRisk = newTable
    } else {
      setDataLists([])
      fields.riskFactorActionRisk = []
    }

    form.setFieldsValue(fields)
  }

  const onFinishForm = () => {
    const fields = form.getFieldValue()

    const dataInput = {
      factorActCode: fields.factorActCode,
      riskFactorCode: fields.riskFactorCode,
      isActive: fields.isActive,
    }

    if (type_action === 'add') {
      dataInput.factorActNameTH = fields.factorActNameTH
      dataInput.factorActNameEN = fields.factorActNameEN ? fields.factorActNameEN : fields.factorActNameTH
      const { riskFactorActionRisk } = fields
      if (riskFactorActionRisk) {
        dataInput.riskFactorActionRisk = riskFactorActionRisk.map((items) => {
          return {
            ...items,
            factorActRiskCode: items.factorActRiskCode,
            factorActRiskNameTH: items.factorActRiskName,
            factorActRiskNameEN: items.factorActRiskName,
          }
        })
      }
      dataInput.typeAction = 'add'
    }

    if (type_action === 'edit') {
      dataInput.factorActNameTH = fields.factorActNameTH
      dataInput.factorActNameEN = fields.factorActNameEN

      const { riskFactorActionRisk } = fields
      if (riskFactorActionRisk) {
        dataInput.riskFactorActionRisk = riskFactorActionRisk.map((items) => {
          return {
            ...items,
            factorActRiskCode: items.factorActRiskCode,
            factorActRiskNameTH: displayText(items.factorActRiskName, items.factorActRiskNameTH),
            factorActRiskNameEN: displayText(items.factorActRiskNameEN, items.factorActRiskName),
          }
        })
      }
      dataInput.typeAction = 'edit'
    }

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const onSuccess = () => {
    form.resetFields()
    navigate(-1)
  }

  return (
    <React.Fragment>
      {(loadingGet || loadingSave) && <SpinnersNew />}
      <Breadcrumb data={arrCrumb} title={t('จัดการข้อมูลปัจจัยความเสี่ยงย่อย')} />

      <Card topic={t(renderTextTitle())} icon={renderIconTitle()}>
        <Form
          form={form}
          onFinish={onFinishForm}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 1,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 12,
            lg: 12,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name="factorActCode"
            label={t('รหัสกิจกรรม')}
            rules={[
              { required: true, message: t('กรุณาระบุรหัสกิจกรรม') },
              {
                pattern: regexEngNoSpace,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษพิมพ์ใหญ่,ตัวเลข A-Z, 0-9 ไม่เกิน 10 ต้ว`),
              },
            ]}
          >
            <InputNew
              maxLength={10}
              disabled={type_action !== 'add' ? true : false}
              placeholder={t('กรอกรหัสกิจกรรม')}
            />
          </Form.Item>
          <Form.Item
            name="factorActNameTH"
            label={t('ชื่อกิจกรรมภาษาไทย')}
            rules={[{ required: true, message: t('กรุณาระบุชื่อกิจกรรมภาษาไทย') }]}
          >
            <InputNew maxLength={200} disabled={!editable} placeholder={t('กรอกชื่อกิจกรรมภาษาไทย')} />
          </Form.Item>
          <Form.Item
            name="factorActNameEN"
            label={t('ชื่อกิจกรรมภาษาอังกฤษ')}
            rules={[{ pattern: regexEng, message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`) }]}
          >
            <InputNew maxLength={200} disabled={!editable} placeholder={t('กรอกชื่อกิจกรรมภาษาอังกฤษ')} />
          </Form.Item>
          <Form.Item
            name="riskFactorCode"
            label={t('ปัจจัยความเสี่ยง')}
            rules={[{ required: true, message: t('กรุณาระบุปัจจัยความเสี่ยง') }]}
          >
            <SelectRiskFactors
              disabled={!editable}
              formname={formName}
              filters={{
                riskType: 1,
                isActive: 1,
              }}
              handleChange={(data) => {
                const name = data ? displayText(data.source.riskFactorNameTH, data.source.riskFactorNameEN) : null
                const value = data ? data.source.riskFactorCode : null
                form.setFieldsValue({ riskFactorName: name, riskFactorCode: value })
              }}
            />
          </Form.Item>
          <Form.Item name="isActive" label={t('สถานะ')} initialValue={1} style={{ marginBottom: 45 }}>
            <RadioGroup disabled={!editable}>
              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                </Col>
                <Col>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
          <LabelNew type="body-header">{t('รายการสูตรประเมินความเสี่ยง')}</LabelNew>
          <Datatable
            enableScroll={{ x: true, y: false }}
            columns={Columns({ editable, fncDelete, menu })}
            data={dataLists}
            pagination={false}
          />
          {editable && (
            <ButtonNew type="addMultiple" onClick={handlePressAdd} roles={{ type: 'add', menu: menu }}>
              {t('เพิ่มข้อมูล')}
            </ButtonNew>
          )}
          <Row gutter={[12, 12]} justify="center" style={{ marginBottom: 20, marginTop: 19 }}>
            {editable && (
              <Col>
                <ButtonNew type="primary" htmlType="submit" roles={{ type: type_action, menu: menu }}>
                  {t('บันทึก')}
                </ButtonNew>
              </Col>
            )}
            <Col>
              <ButtonNew onClick={() => navigate('/administrator/subriskfactors')} type="back">
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </Card>
    </React.Fragment>
  )
}
export default SubRiskFactorForm
