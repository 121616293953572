import { useLazyQuery } from '@apollo/client'
import { Avatar, Col, Row, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import Timeline, {
  TimelineHeaders,
  SidebarHeader,
  DateHeader,
  TimelineMarkers,
  CustomMarker,
} from 'react-calendar-timeline'
import 'react-calendar-timeline/lib/Timeline.css'
import moment from 'moment'
import _ from 'lodash'

import { GET_LIST_AUDIT_JOB_STEP_BY_ACTION_PLAN_ID } from './graphql/Query'

import ActiveImg from '../../../../assets/images/Active.png'
import { Alert } from '../../../../components/alert/Alert'
import { ButtonNew } from '../../../../components/button/Button'
import { encryptInput } from '../../../../utilitys/crypto'
import { dateDisplay } from '../../../../utilitys/dateHelper'
import { SideBarHeadDiv, TimeLineStyle } from '../../../../components/genttchart/GanttChartStyles'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { LabelNew } from '../../../../components/label/Label'
import { displayText } from '../../../../utilitys/helper'
import { GET_HOLIDAY, GET_MEETINGDAY } from '../graphql/Query'

const Responsive = {
  md: 24,
  lg: 24,
}

const AuditJobStepList = ({ actionPlanId, backPath, activeKey = null }) => {
  if (activeKey !== '3') return <></>

  const color = {
    graphHeaderMonthNameBackground: '#C1C8C7',
    graphHeaderMonthNameText: '#FFFFFF',
    graphHeaderDatehNameBackground: '#FFFFFF',
    graphHeaderDatehNameText: '#000000',
    graphCurrentDateBackground: '#B2B2B2',
    graphWeekendDateBackground: '#e8d595',
    graphHolidayBackground: '#FDFFBC',
    graphMeetingBackground: '#FFFDDD',
    statusNoapprove: '#C1C8C7',
    statusNoActionPlan: '#FAE0D8',
    statusActionPlanDontApprove: '#f7d4de',
    statusWaitForProcess: '#a0c5e2',
    statusProcessing: '#2676bb',
    statusProcessed: '#54a648',
    statusLate: '#C43D56',
  }

  const { t } = useTranslation()
  const navigate = useNavigate()

  const [callList, { error: errorList, data: dataList, loading: loadingList }] = useLazyQuery(
    GET_LIST_AUDIT_JOB_STEP_BY_ACTION_PLAN_ID,
  )

  useEffect(() => {
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(errorList.message),
      })
    }
  }, [errorList])

  useEffect(() => {
    if (dataList) {
      const { getListAuditJobStepByActionPlanID } = dataList

      let noSub = ''
      const groupTemp = getListAuditJobStepByActionPlanID.map((item) => {
        let stepName = ''
        if (item.stepType === 1) {
          stepName = (
            <span style={{ fontWeight: 'bold' }}>
              {item.stepSort + '.' + displayText(item.stepNameTH, item.stepNameEN)}
            </span>
          )
          noSub = item.stepSort
        } else {
          stepName = (
            <>&emsp;{'' + noSub + '.' + item.stepSort + '.' + displayText(item.stepNameTH, item.stepNameEN)} </>
          )
        }
        return {
          ...item,
          id: item.stepId,
          title: stepName,
          stackItems: true,
          height: 60,
          dateStart: dateDisplay(item.dateStart),
          dateEnd: dateDisplay(item.dateEnd),
        }
      })
      const itemTemp = getListAuditJobStepByActionPlanID
        .map((item) => {
          const dateStart = new Date(item.dateStart)
          const dateEnd = new Date(item.dateEnd)
          const start_time = moment(
            dateStart.getFullYear() + '-' + (dateStart.getMonth() + 1) + '-' + dateStart.getDate() + ' 00:00',
          )
          const end_time = moment(
            dateEnd.getFullYear() + '-' + (dateEnd.getMonth() + 1) + '-' + dateEnd.getDate() + ' 23:59',
          )

          const title = {
            late: false,
            operationStatusCode: item.operationStatusCode,
            width: '101%',
          }

          return {
            id: item.stepId,
            group: item.stepId,
            title: title,
            start_time,
            end_time,
            canMove: false,
            canResize: false,
            canChangeGroup: false,
            operationStatusCode: item.operationStatusCode,
          }
        })
        .concat(
          getListAuditJobStepByActionPlanID
            .filter((data) => {
              return (
                data.operationStatusCode !== '30' &&
                data.operationStatusCode !== '31' &&
                moment(data.dateEnd) < moment(new Date())
              )
            })
            .map((data) => {
              let dateStart = new Date(moment(data.dateEnd).add(1, 'days'))
              let dateEnd = new Date()
              return {
                id: data.stepId + '_late',
                group: data.stepId,
                title: { late: true },
                start_time: moment(
                  dateStart.getFullYear() + '-' + (dateStart.getMonth() + 1) + '-' + dateStart.getDate() + ' 00:00',
                ),
                end_time: moment(
                  dateEnd.getFullYear() + '-' + (dateEnd.getMonth() + 1) + '-' + dateEnd.getDate() + ' 23:59',
                ),
                canMove: false,
                canResize: false,
                canChangeGroup: false,
              }
            }),
        )

      setGroups(groupTemp)
      setItems(itemTemp)
    }
  }, [dataList])

  useEffect(() => {
    fncGetList()
    callHoliday()
    callMeeting()
  }, [])

  const fncGetList = () => {
    const dataInput = {
      actionPlanId: actionPlanId,
    }

    callList({
      variables: { input: encryptInput(dataInput) },
    })
  }

  const [callHoliday, { data: dataHoliday, error: errorHoliday, loading: loadingHoliday }] = useLazyQuery(GET_HOLIDAY)

  useEffect(() => {
    if (errorHoliday) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(errorHoliday.message),
      })
    }
  }, [errorHoliday])

  useEffect(() => {
    if (dataHoliday) {
      let response = dataHoliday.auditProjectGetCalendarDetailsType1
      setHoliday(
        response.map((data) => {
          return moment(new Date(moment(data.dateAt).format('YYYY-MM-DD') + ' 00:00'))
        }),
      )
    }
  }, [dataHoliday])

  const [callMeeting, { data: dataMeeting, error: errorMeeting, loading: loadingMeeting }] =
    useLazyQuery(GET_MEETINGDAY)

  useEffect(() => {
    if (errorMeeting) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(errorMeeting.message),
      })
    }
  }, [errorMeeting])

  useEffect(() => {
    if (dataMeeting) {
      let response = dataMeeting.auditProjectGetCalendarDetailsType2
      setMeeting(
        response.map((data) => {
          return moment(new Date(moment(data.dateAt).format('YYYY-MM-DD') + ' 00:00'))
        }),
      )
    }
  }, [dataMeeting])

  const [groups, setGroups] = useState([])
  const [items, setItems] = useState([])
  const [holiday, setHoliday] = useState([])
  const [meeting, setMeeting] = useState([])
  const defaultTimeStart = moment().startOf('month').toDate()
  const defaultTimeEnd = moment().startOf('month').add(1, 'month').toDate()
  const defaultTimeRange = defaultTimeEnd - defaultTimeStart

  const groupRenderer = ({ group }) => {
    let text = null
    if (!_.isEmpty(group.personnels)) {
      text = group.personnels.map((val, idx) => {
        const name = displayText(val.personnelNameTH, val.personnelNameEN)
        return (
          <React.Fragment key={`${name}${idx}`}>
            {name}
            {idx < group.personnels.length && <br />}
          </React.Fragment>
        )
      })
    }
    return (
      <Row justify="space-between" style={{ height: '100%' }}>
        <Col md={21}>
          <Tooltip placement="top" title={group.title}>
            <Row>
              <Col md={24} style={{ height: '30px', marginTop: '-10px' }}>
                <span>{group.title}</span>
              </Col>
            </Row>
            <Row>
              <Col md={24} style={{ height: '30px', marginTop: '-10px' }}>
                <span style={{ color: color.graphHeaderMonthNameBackground, fontSize: '14px' }}>
                  {group.stepType === 1 ? <></> : <>&emsp;</>}
                  {group.dateStart + ' - ' + group.dateEnd}
                </span>
              </Col>
            </Row>
          </Tooltip>
        </Col>

        <Col md={3}>
          <Tooltip placement="top" title={text}>
            <Avatar size={47} src={<img src={ActiveImg} style={{ height: '45px', back: 'no-repeat' }} />}></Avatar>
          </Tooltip>
        </Col>
      </Row>
    )
  }

  const itemRenderer = ({ item, itemContext, getItemProps, getResizeProps }) => {
    const { left: leftResizeProps, right: rightResizeProps } = getResizeProps()
    const propsTop = getItemProps(item.itemProps)
    propsTop.style =
      item.title.late === false
        ? {
            ...propsTop.style,
            color: 'white',
            background: color.statusNoapprove,
            border: `1px solid ${color.statusNoapprove}`,
            height: '30px',
          }
        : {
            ...propsTop.style,
            color: 'white',
            background: color.statusLate,
            border: `1px solid ${color.statusLate}`,
            height: '30px',
          }

    let background = color.statusWaitForProcess

    if (item.operationStatusCode === '10') {
      background = color.statusWaitForProcess
    } else if (item.operationStatusCode === '30' || item.operationStatusCode === '31') {
      background = color.statusProcessed
    } else if (item.operationStatusCode === '20' || item.operationStatusCode === '21') {
      background = color.statusProcessing
    }

    propsTop.title = ''

    return (
      <>
        <div {...propsTop}>
          {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ''}
          {item.title.late === false ? (
            <div
              className="rct-item-content"
              style={{
                maxHeight: `${itemContext.dimensions.height}`,
                background: background,
                width: item.title.width,
              }}
            ></div>
          ) : (
            ''
          )}
          {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ''}
        </div>
      </>
    )
  }

  return (
    <React.Fragment>
      {(loadingHoliday || loadingList || loadingMeeting) && <SpinnersNew />}
      <Row>
        <Col {...Responsive}>
          {groups.length !== 0 && items.length !== 0 && (
            <TimeLineStyle height={groups.length > 10 ? '665px' : 'auto'}>
              <Timeline
                groups={groups}
                items={items}
                minZoom={defaultTimeRange}
                maxZoom={defaultTimeRange}
                defaultTimeStart={defaultTimeStart}
                defaultTimeEnd={defaultTimeEnd}
                groupRenderer={groupRenderer}
                itemRenderer={itemRenderer}
                sidebarWidth={450}
                key={{
                  groupIdKey: 'id',
                  groupTitleKey: 'title',
                  groupRightTitleKey: 'rightTitle',
                  itemIdKey: 'id',
                  itemTitleKey: 'title', // key for item div content
                  itemDivTitleKey: 'title', // key for item div title (<div title="text"/>)
                  itemGroupKey: 'group',
                  itemTimeStartKey: 'start_time',
                  itemTimeEndKey: 'end_time',
                }}
              >
                <TimelineHeaders>
                  <SidebarHeader>
                    {({ getRootProps }) => {
                      return (
                        <SideBarHeadDiv {...getRootProps()}>
                          <Row justify="space-between" align="middle" style={{ height: '100%' }}>
                            <Col>{t('ชื่องานตรวจสอบ')}</Col>
                            <Col style={{ marginRight: 10 }}>{t('ผู้รับผิดชอบ')}</Col>
                          </Row>
                        </SideBarHeadDiv>
                      )
                    }}
                  </SidebarHeader>
                  <DateHeader
                    unit="month"
                    labelFormat="MM YYYY"
                    style={{
                      height: 50,
                      color: color.graphHeaderDatehNameBackground,
                      backgroundColor: color.graphHeaderMonthNameBackground,
                      pointerEvents: 'none',
                    }}
                    intervalRenderer={({ getIntervalProps, intervalContext }) => {
                      const monthSTR = intervalContext.intervalText.split(' ')
                      const month =
                        monthSTR[0] === '01'
                          ? t('มกราคม')
                          : monthSTR[0] === '02'
                          ? t('กุมภาพันธ์')
                          : monthSTR[0] === '03'
                          ? t('มีนาคม')
                          : monthSTR[0] === '04'
                          ? t('เมษายน')
                          : monthSTR[0] === '05'
                          ? t('พฤษภาคม')
                          : monthSTR[0] === '06'
                          ? t('มิถุนายน')
                          : monthSTR[0] === '07'
                          ? t('กรกฎาคม')
                          : monthSTR[0] === '08'
                          ? t('สิงหาคม')
                          : monthSTR[0] === '09'
                          ? t('กันยายน')
                          : monthSTR[0] === '10'
                          ? t('ตุลาคม')
                          : monthSTR[0] === '11'
                          ? t('พฤศจิกายน')
                          : monthSTR[0] === '12'
                          ? t('ธันวาคม')
                          : ''
                      const year = localStorage.getItem('lang') === 'th' ? parseInt(monthSTR[1]) + 543 : monthSTR[1]
                      return (
                        <div justify="center" align="middle" {...getIntervalProps()}>
                          <div style={{ border: '1px solid #bbb' }}>{month + ' ' + year}</div>
                        </div>
                      )
                    }}
                  />
                  <DateHeader
                    unit="day"
                    labelFormat="D"
                    style={{
                      height: 50,
                      color: color.graphHeaderDatehNameText,
                      backgroundColor: color.graphHeaderMonthNameText,
                      pointerEvents: 'none',
                    }}
                    intervalRenderer={({ getIntervalProps, intervalContext }) => {
                      return (
                        <div justify="center" align="middle" {...getIntervalProps()}>
                          <div style={{ border: '1px solid #bbb', borderTop: '0px', paddingBottom: '5px' }}>
                            {intervalContext.intervalText}
                          </div>
                        </div>
                      )
                    }}
                  />
                </TimelineHeaders>
                <TimelineMarkers>
                  <CustomMarker date={new Date(moment().format('YYYY-MM-DD') + ' 00:00').getTime()}>
                    {({ styles }) => {
                      const customStyles = {
                        ...styles,
                        backgroundColor: color.graphCurrentDateBackground,
                        width: '3.325%',
                      }
                      return <div style={customStyles} />
                    }}
                  </CustomMarker>
                  {holiday.map((data, i) => {
                    const dateData = new Date(data)
                    return dateData.getDay() !== 0 && dateData.getDay() !== 6 ? (
                      <CustomMarker date={new Date(moment(data).format('YYYY-MM-DD') + ' 00:00').getTime()} key={i}>
                        {({ styles }) => {
                          const customStyles = {
                            ...styles,
                            backgroundColor: color.graphHolidayBackground,
                            width: '3.325%',
                          }
                          return <div style={customStyles} />
                        }}
                      </CustomMarker>
                    ) : (
                      ''
                    )
                  })}
                  {meeting.map((data, i) => {
                    const dateData = new Date(data)
                    return dateData.getDay() !== 0 && dateData.getDay() !== 6 ? (
                      <CustomMarker date={new Date(moment(data).format('YYYY-MM-DD') + ' 00:00').getTime()} key={i}>
                        {({ styles }) => {
                          const customStyles = {
                            ...styles,
                            backgroundColor: color.graphMeetingBackground,
                            width: '3.325%',
                          }
                          return <div style={customStyles} />
                        }}
                      </CustomMarker>
                    ) : (
                      ''
                    )
                  })}
                </TimelineMarkers>
              </Timeline>
            </TimeLineStyle>
          )}
        </Col>
      </Row>
      <Row justify="center" style={{ marginTop: '100px' }}>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{
                backgroundColor: color.statusWaitForProcess,
                width: '20px',
                height: '20px',
                marginRight: '20px',
              }}
            ></div>
            <div>
              <LabelNew type="tab-header-inactive">{t('รอดำเนินการ')}</LabelNew>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusProcessing, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <LabelNew type="tab-header-inactive">{t('อยู่ระหว่างดำเนินการ')}</LabelNew>
            </div>
          </div>
        </Col>
        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusProcessed, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <LabelNew type="tab-header-inactive">{t('ดำเนินการแล้วเสร็จ')}</LabelNew>
            </div>
          </div>
        </Col>

        <Col md={3}>
          <div style={{ display: 'flex' }}>
            <div
              style={{ backgroundColor: color.statusLate, width: '20px', height: '20px', marginRight: '20px' }}
            ></div>
            <div>
              <LabelNew type="tab-header-inactive">{t('ล่าช้ากว่ากำหนด')}</LabelNew>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[12, 12]} justify="center" style={{ marginBottom: 20, marginTop: 40 }}>
        <Col>
          <ButtonNew onClick={() => navigate(backPath ? backPath : `/auditoperations/actionplan/`)} type="back">
            {t('ย้อนกลับ')}
          </ButtonNew>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AuditJobStepList
