import React from 'react'
import { Radio as RadioAnt } from 'antd'
import { RadioStyle } from './RadioStyles'

export const Radio = ({ children, ...otherProps }) => {
  return (
    <React.Fragment>
      <RadioStyle>
        <RadioAnt {...otherProps}>{children}</RadioAnt>
      </RadioStyle>
    </React.Fragment>
  )
}

export const RadioGroup = ({ children, ...otherProps }) => {
  return (
    <React.Fragment>
      <RadioStyle>
        <RadioAnt.Group {...otherProps}>{children}</RadioAnt.Group>
      </RadioStyle>
    </React.Fragment>
  )
}
