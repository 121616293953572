import { gql } from '@apollo/client'

export const UPDATE_ASSESS_RISK = gql`
  mutation updateAssessRick($input: AssessRiskInput!) {
    updateAssessRick(input: $input) {
      riskResultId
    }
  }
`
export const CANCEL_SEND_SETUP_REPORT_SUBMISSIONS = gql`
  mutation cancelSendSetupReportSubMissions($input: SetupReportSubMissionsInput!) {
    cancelSendSetupReportSubMissions(input: $input) {
      status
    }
  }
`

export const DELETE_RISK_FORM = gql`
  mutation deleteRiskForms($riskFormCode: String!) {
    deleteRiskForms(riskFormCode: $riskFormCode) {
      riskFormCode
    }
  }
`
