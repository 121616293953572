import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import { useNavigate, useLocation, Navigate } from 'react-router-dom'
import { BreadcrumbNew as Breadcrumb } from '../../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../../components/card/Card'
import { TabNew } from '../../../../components/tab/Tab'
import { getSegmentUrl } from '../../../../utilitys/helper'
import AuditResultIssue from '../subtabone/AuditResultIssue'
import AuditResultInspection from '../subtabone/AuditResultInspection'
import AuditResultRefer from '../subtabone/AuditResultRefer'

const AuditResultForm = (props) => {
  const { menu } = props
  const navigate = useNavigate()
  const { state } = useLocation()
  const { t } = useTranslation()
  const params = getSegmentUrl(4)

  if (!state) {
    return <Navigate to={'/auditoperations/auditresult'} />
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/auditresult' },
    { label: t('บันทึกผลการปฏิบัติงานตรวจสอบ') },
    {
      label:
        state.type_action === 'add'
          ? t('เพิ่มผลการตรวจสอบ')
          : state.type_action === 'edit'
          ? t('แก้ไขผลการตรวจสอบ')
          : '',
    },
  ]

  const Responsive = {
    md: 24,
    lg: 24,
  }

  const [issueId, setIssueId] = useState()

  const tabs = [
    {
      key: 'issue',
      iconTab: () => <></>,
      name: t('ประเด็นข้อสังเกต'),
      disabled: false,
      content: () => (
        <AuditResultIssue
          setIssueId={(e) => {
            state.issueId = e
            setIssueId(e)
          }}
          menu={menu}
        />
      ),
    },
    {
      key: 'inspection',
      iconTab: () => <></>,
      name: t('หน่วยรับตรวจ'),
      disabled: issueId || state?.issueId ? false : true,
      content: () => <AuditResultInspection menu={menu} />,
    },
    {
      key: 'refer',
      iconTab: () => <></>,
      name: t('หลักฐานการตรวจสอบ'),
      disabled: issueId || state?.issueId ? false : true,
      content: () => <AuditResultRefer menu={menu} />,
    },
  ]

  return (
    <>
      <Breadcrumb
        data={breadcrumbList}
        title={
          state.type_action === 'add'
            ? t('เพิ่มผลการตรวจสอบ')
            : state.type_action === 'edit'
            ? t('แก้ไขผลการตรวจสอบ')
            : t('ดูผลการตรวจสอบ')
        }
      />
      <CardNew
        topic={
          state.type_actionSub === 'add'
            ? t('เพิ่มผลการตรวจสอบ')
            : state.type_actionSub === 'edit'
            ? t('แก้ไขผลการตรวจสอบ')
            : t('ดูผลการตรวจสอบ')
        }
        icon={state.type_actionSub === 'add' ? 'Plus' : state.type_actionSub === 'edit' ? 'Edit' : 'View'}
        toggledrop="false"
      >
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <TabNew
              tab={tabs}
              activeKey={!params ? 'issue' : params}
              onTabClick={(key) => {
                if (key === 'issue') {
                  navigate('/auditoperations/auditresult/form/issue', { state: state })
                } else if (key === 'inspection') {
                  navigate('/auditoperations/auditresult/form/inspection', { state: state })
                } else if (key === 'refer') {
                  navigate('/auditoperations/auditresult/form/refer', { state: state })
                }
              }}
            />
          </Col>
        </Row>
      </CardNew>
    </>
  )
}

export default AuditResultForm
