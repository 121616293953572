import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import errormessageEN from './lang/errormessage/en.json'
import errormessageTH from './lang/errormessage/th.json'
import generalLangEN from './lang/general/en.json'
import generalLangTH from './lang/general/th.json'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: { translations: { ...generalLangEN, ...errormessageEN } },
      th: { translations: { ...generalLangTH, ...errormessageTH } },
    },
    fallbackLng: 'en',
    debug: false,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  })

export default i18n
