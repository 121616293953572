import { gql } from '@apollo/client'

// For CheckUnitFilter.jsx
export const GET_LIST_PROJECT_STATUS_REPORT_PAGINATION = gql`
  query GetListProjectStatusReportPagination($input: ProjectStatusReportPaginationInput!) {
    getListProjectStatusReportPagination(input: $input) {
      result {
        no
        actionPlanId
        projectCode
        projectNameTH
        projectNameEN
        projectRefCode
        projectRefNameTH
        projectRefNameEN
        dateStart
        dateEnd
        organizationCode
        organizationNameTH
        organizationNameEN
        approver
        personnelNameTH
        personnelNameEN
        projectStatusCode
        projectStatusNameTH
        projectStatusNameEN
        isActive
      }
      count
      page
      limit
    }
  }
`

export const GET_EXPORT_PROJECT_STATUS_REPORT = gql`
  query ExportProjectStatusReport($input: ExportProjectStatusReportInput!) {
    exportProjectStatusReport(input: $input) {
      message
      fileUrl
    }
  }
`
