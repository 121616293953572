import { Trans } from 'react-i18next'
export const Columns = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'key',
    width: '100px',
    align: 'center',
  },
  {
    title: <Trans>ผู้รับผิดชอบ</Trans>,
    dataIndex: 'fullName',
    width: '200px',
    sorter: { multiple: 1 },
  },
  {
    title: <Trans>ลักษะณะงาน</Trans>,
    dataIndex: 'groupName',
    width: '200px',
    sorter: { multiple: 2 },
  },
  {
    title: <Trans>ประเภทงาน/ประเภทโครงการ</Trans>,
    dataIndex: 'typeName',
    width: '200px',
    sorter: { multiple: 3 },
    className: 'text-nowrap',
  },
  {
    title: <Trans>โครงการ/งาน</Trans>,
    dataIndex: 'jobAndProjectName',
    width: '300px',
    sorter: { multiple: 4 },
  },
  {
    title: <Trans>วันที่เริ่มต้น</Trans>,
    dataIndex: 'dateStart',
    width: '150px',
    align: 'center',
    sorter: { multiple: 5 },
  },
  {
    title: <Trans>วันที่สิ้นสุด</Trans>,
    dataIndex: 'dateEnd',
    width: '150px',
    align: 'center',
    sorter: { multiple: 6 },
  },
  {
    title: <Trans>วันทำงาน(วัน)</Trans>,
    dataIndex: 'manday',
    width: '150px',
    align: 'center',
    sorter: { multiple: 7 },
  },
  {
    title: <Trans>หน่วยงาน</Trans>,
    dataIndex: 'organizationName',
    width: '200px',
    sorter: { multiple: 8 },
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '100px',
  },
]
