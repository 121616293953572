import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import { ModalNew } from '../../../../../components/modal/Modal'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { regexEng, regexThai, displayError } from '../../../../../utilitys/helper'
import { InputNew } from '../../../../../components/input/Input'
import { TextAreaNew } from '../../../../../components/textarea/TextArea'
import { UploadNew } from '../../../../../components/upload/Upload'
import { UPDATE_NAME_ORGANIZATION } from '../../graphql/Mutation'
import { useMutation } from '@apollo/client'
import { Alert } from '../../../../../components/alert/Alert'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../../utilitys/crypto'

const ChangeNameModal = (props) => {
  const { dataParams } = props
  const { t } = useTranslation()
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [idFile, setIdFile] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])

  /* ---------------- API ----------- */
  const [
    updateNameOrganizationFn,
    { loading: loadingUpdateNameOrganization, error: errorUpdateNameOrganization, data: dataUpdateNameOrganization },
  ] = useMutation(UPDATE_NAME_ORGANIZATION)
  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    setVisible(props.open)
    if (dataParams) {
      form.setFieldsValue({
        organizationNameTH: dataParams?.organizationNameTH,
        organizationNameEN: dataParams?.organizationNameEN,
      })
    }
  }, [props.open])

  useEffect(() => {
    if (dataUpdateNameOrganization) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          props.refetch()
        },
      })
    }
    if (errorUpdateNameOrganization) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorUpdateNameOrganization)),
        onOk() {},
      })
    }
  }, [dataUpdateNameOrganization])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    showConfirm(input)
  }
  const showConfirm = (input) => {
    let dataInput = {
      ...input,
      organizationCode: dataParams?.organizationCode,
      remark: dataParams?.remark ? dataParams?.remark : '',
      files: idFile.join(),
    }
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        updateNameOrganizationFn({
          variables: {
            input: encryptInput(dataInput),
          },
        })
        form.resetFields()
        setVisible(false)
        props.close(false)
      },
      onCancel() {},
    })
  }

  function onClose(e) {
    if (dataParams) {
      form.setFieldsValue({
        organizationNameTH: dataParams?.organizationNameTH,
        organizationNameEN: dataParams?.organizationNameEN,
      })
    }
    setVisible(e)
    props.close(e)
  }
  return (
    <>
      {loadingUpdateNameOrganization && <SpinnersNew />}
      <ModalNew
        visible={visible}
        typeAction={dataParams?.type_action}
        testTitle={t('เพิ่มหน่วยงาน')}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        type="medium"
      >
        <Form
          form={form}
          onFinish={onFinish}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2,
          }}
          wrapperCol={{
            span: 10,
          }}
          layout="horizontal"
          name="organizationForm"
        >
          <Form.Item
            name="organizationNameTH"
            label={<Label type="tab-header-inactive">{t('ชื่อหน่วยงานภาษาไทย')}</Label>}
            rules={[
              { required: true, message: t('กรุณากรอกชื่อหน่วยงานภาษาไทย') },
              {
                pattern: regexThai,
                message: t(`กรุณากรอกด้วยภาษาไทยหรือตัวเลข ก-๙, 0-9`),
              },
            ]}
          >
            <InputNew placeholder={t('ชื่อหน่วยงานภาษาไทย')} />
          </Form.Item>
          <Form.Item
            name="organizationNameEN"
            label={<Label type="tab-header-inactive">{t('ชื่อหน่วยงานภาษาอังกฤษ')}</Label>}
            rules={[
              {
                pattern: regexEng,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`),
              },
            ]}
          >
            <InputNew placeholder={t('ชื่อหน่วยงานภาษาอังกฤษ')} />
          </Form.Item>
          <Form.Item name="remark" label={<Label type="tab-header-inactive">{t('หมายเหตุ')}</Label>}>
            <TextAreaNew rows={4} placeholder={t('ระบุหมายเหตุ')} />
          </Form.Item>
          <Form.Item
            name="files"
            label={<Label type="tab-header-inactive">{t('เอกสารแนบ')}</Label>}
            rules={[idFile.length === 0 ? { required: true, message: t('กรุณาอัพโหลดเอกสาร') } : {}]}
          >
            <UploadNew
              type="mutiple"
              setIdFile={setIdFile}
              idFile={idFile}
              typeFile="file"
              actionType="create"
              idFileDelete={idFileDelete}
              setIdFileDelete={setIdFileDelete}
            />
          </Form.Item>
        </Form>
      </ModalNew>
    </>
  )
}
export default ChangeNameModal
