import { gql } from '@apollo/client'

export const GET_TIMESHEET_PAGINATION = gql`
  query getTimeSheetPagination($input: TimeSheetPaginationInput!) {
    getTimeSheetPagination(input: $input) {
      result {
        no
        jobGroupCode
        jobGroupNameTH
        jobGroupNameEN
        timeSheetId
        jobTypeCode
        projectTypeCode
        typeNameTH
        typeNameEN
        jobDetailTH
        jobDetailEN
        dateStart
        timeStart
        dateEnd
        timeEnd
        jobStatusCode
        jobStatusNameTH
        jobStatusNameEN
        jobAndProjectNameTH
        jobAndProjectNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_TIMESHEET_BY_ID = gql`
  query getTimeSheetById($timeSheetId: String!) {
    getTimeSheetById(timeSheetId: $timeSheetId) {
      timeSheetId
      jobGroupCode
      jobTypeCode
      projectTypeCode
      jobCode
      projectCode
      stepId
      urgentCode
      placeOfWorkTH
      placeOfWorkEN
      jobDetailTH
      jobDetailEN
      jobResponsibilityTH
      jobResponsibilityEN
      outcomeTH
      outcomeEN
      outputTH
      outputEN
      usehour
      useday
      dateStart
      timeStart
      dateEnd
      timeEnd
      hour
      jobStatusCode
      attachFile
      personnelAction
      approveStatusCode
      isActive
      fileDetail
      cost
      statusSendRequest
    }
  }
`

export const GET_TIMESHEET_REQUEST_PAGINATION = gql`
  query getTimeSheetRequestPagination($input: TimeSheetPaginationInput!) {
    getTimeSheetRequestPagination(input: $input) {
      result {
        no
        requestNumber
        requestId
        dateStart
        dateEnd
        requestStatusCode
        requestStatusNameTH
        requestStatusNameEN
        approverNameTH
        approverNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_SELECT_ADHOCJOB = gql`
  query getSelectAdhocJob($input: SelectTimeSheetInput!) {
    getSelectAdhocJob(input: $input) {
      jobCode
      jobSubjectEN
      jobSubjectTH
      dateStart
      dateEnd
      useday
    }
  }
`

export const GET_SELECT_AUDITPROJECT = gql`
  query getSelectAuditProjects($input: SelectTimeSheetInput!) {
    getSelectAuditProjects(input: $input) {
      projectCode
      projectNameTH
      projectNameEN
      dateStart
      dateEnd
      useday
    }
  }
`

export const GET_SELECT_AUDIT_JOB_STEP = gql`
  query getSelectAuditJobStep($input: SelectTimeSheetInput!) {
    getSelectAuditJobStep(input: $input) {
      stepId
      actionPlanId
      jobCode
      personnelCode
      stepNameTH
      stepNameEN
      stepType
      stepSort
      stepGroup
      approveStatusCode
      isActive
      customSort
    }
  }
`

export const GET_TIMESHEET_REQUEST_BY_ID = gql`
  query getTimeSheetRequestById($requestId: String!) {
    getTimeSheetRequestById(requestId: $requestId) {
      requestId
      requestNumber
      dateStart
      dateEnd
      approver
      approverNameTH
      approverNameEN
      requestStatusCode
      requestStatusNameTH
      requestStatusNameEN
      isActive
      files
      requestPersonnel
      no
      fileDetail
      listFile {
        fileId
        fileNameGen
        fileType
        fileNameLabel
      }
      requestDetail {
        jobAndProjectNameTH
        jobAndProjectNameEN
        dateStart
        dateEnd
        requestDetailId
        requestId
        timeSheetId
        approveStatusCode
        isActive
        approveStatusNameTH
        approveStatusNameEN
        jobDetailTH
        jobDetailEN
        typeNameTH
        typeNameEN
        urgentNameTH
        urgentNameEN
      }
    }
  }
`
