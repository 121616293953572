import { gql } from '@apollo/client'
export const GET_LIST_RATE_RISK_FACTOR_PROCESS_PAGINATION = gql`
  query GetListRateRiskFactorProcessPagination($input: RateRiskFactorProcessPagination!) {
    getListRateRiskFactorProcessPagination(input: $input) {
      count
      result {
        no
        rateFacProCode
        rateFacProNameTH
        rateFacProNameEN
        rateFacGroupCode
        shortCode
        isActive
        riskType
      }
      page
      limit
    }
  }
`
