import React from 'react'
import { Row, Col } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import SetupRunningNumberList from './SetupRunningNumberList'
import { useTranslation } from 'react-i18next'
/**
 * @function SetupRunningNumberIndex
 * @description
 * Menu: SetupRunningNumberIndex
 * เมนู: จัดการเลขที่เอกสาร
 * @returns Component
 */

const SetupRunningNumberIndex = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const arrCrumb = [{ label: t('จัดการข้อมูลพื้นฐาน'), path: '#' }, { label: t('จัดการเลขที่เอกสาร') }]
  const Responesive = {
    sm: 24,
    md: 24,
    lg: 24,
  }
  return (
    <React.Fragment>
      <Row>
        <Col {...Responesive}>
          <Breadcrumb data={arrCrumb} title={t('จัดการเลขที่เอกสาร')} />
          <SetupRunningNumberList menu={menu} />
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default SetupRunningNumberIndex
