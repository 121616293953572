import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Input } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { useLanguage } from '../../../../hooks/useLanguage'
import { useDecodeUser } from '../../../../hooks/useDecodeUser'
import { ModalNew } from '../../../../components/modal/Modal'
import SelectPersonnels from '../../../../components/inputfromapi/selectpersonnels/SelectPersonnels'
import { encryptInput /*, decryptData*/ } from '../../../../utilitys/crypto'
import { LabelNew as Label } from '../../../../components/label/Label'
import { Alert } from '../../../../components/alert/Alert'
import { displayError } from '../../../../utilitys/helper'
import { FORWARD_ASSESS_RICK } from '../graphql/Mutation'
const formItemLayout = {
  labelCol: { sm: 14, md: 7, lg: 7, xl: 7 },
  wrapperCol: { sm: 16, md: 19, lg: 19, xl: 19, align: 'left' },
  Col: {
    sm: 24,
    md: 16,
    lg: 18,
  },
}
const ForwardAssessRickModal = (props) => {
  const { open, close, data, onSuccess } = props
  const { t } = useTranslation()
  const [isLang] = useLanguage()
  const [form] = Form.useForm()
  const [user] = useDecodeUser()
  const formName = 'send_assess_risk'
  const [visible, setVisible] = useState(false)
  const [forwardAssessRickFn, forwardAssessRick] = useMutation(FORWARD_ASSESS_RICK)
  useEffect(() => {
    setVisible(open)
  }, [open])

  useEffect(() => {
    if (forwardAssessRick.data) {
      Alert({
        type: 'success',
        title: t('ส่งต่อแบบประเมินสำเร็จ'),
        onOk() {
          setVisible(false)
          close(false)
          onSuccess(false)
        },
      })
    }
    if (forwardAssessRick.error) {
      const err = forwardAssessRick.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [forwardAssessRick.data])

  async function onSubmit(d) {
    const input_ = encryptInput({
      riskResultId: data.riskResultId,
      personnelCode: d.makerBy,
    })
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการส่งต่อ ?'),
      onOk: async () => {
        await forwardAssessRickFn({
          variables: {
            input: input_,
          },
        })
      },
      onCancel() {},
    })
  }
  function onClose(e) {
    setVisible(visible ? false : true)
    close(e)
  }
  return (
    <>
      <ModalNew
        visible={visible}
        onSubmit={() => {
          form
            .validateFields()
            .then((v) => onSubmit(v))
            .catch((err) => console.log(err))
        }}
        onClose={(e) => onClose(e)}
        testTitle={t('ข้อมูลการแจ้งเตือนอีเมล')}
        type="medium"
      >
        <Row justify="center">
          <Col {...formItemLayout.Col}>
            <Form
              name={formName}
              layout="horizontal"
              form={form}
              labelAlign="left"
              labelCol={{ ...formItemLayout.labelCol }}
              wrapperCol={{ ...formItemLayout.wrapperCol }}
            >
              <Form.Item
                name={'riskFormName'}
                initialValue={isLang === 'th' ? data.riskFormTH : data.riskFormEN}
                label={t('ชื่อแบบประเมิน ')}
              >
                <>
                  <Input type={'hidden'} />
                  <Label type="body-primary">{isLang === 'th' ? data.riskFormTH : data.riskFormEN}</Label>
                </>
              </Form.Item>
              <Form.Item
                name={'riskModel'}
                initialValue={isLang === 'th' ? data.riskModelTH : data.riskModelEN}
                label={t('คลังแบบประเมินความเสี่ยง ')}
              >
                <>
                  <Input type={'hidden'} />
                  <Label type="body-primary">{isLang === 'th' ? data.riskModelTH : data.riskModelEN}</Label>
                </>
              </Form.Item>
              <Form.Item
                name={'riskFormType'}
                initialValue={data.riskFormType === 1 ? t('ประเมินสรุป') : t('ประเมินรายหน่วยงาน')}
                label={t('ประเภทการประเมิน')}
              >
                <>
                  <Input type={'hidden'} />
                  <Label type="body-primary">
                    {
                      data.riskFormType === 1 ? t('ประเมินสรุป') : t('ประเมินรายหน่วยงาน')
                      // 1 = ประเมินสรุป , 2 = ประเมินรายหน่วยงาน
                    }
                  </Label>
                </>
              </Form.Item>
              <Form.Item
                name={'organizationAudit'}
                initialValue={isLang === 'th' ? data.organizationNameTH : data.organizationNameEN}
                label={t('หน่วยที่ทำแบบประเมิน')}
              >
                <>
                  <Input type={'hidden'} />
                  <Label type="body-primary">
                    {isLang === 'th' ? data.organizationNameTH : data.organizationNameEN}
                  </Label>
                </>
              </Form.Item>
              <Form.Item
                name={'makerBy'}
                label={t('ผู้รับเรื่อง')}
                rules={[
                  {
                    required: true,
                    message: t('กรุณาระุบผู้รับเรื่อง'),
                  },
                ]}
              >
                <SelectPersonnels
                  placeholder={t('ระบุผู้รับเรื่อง')}
                  formname={formName}
                  handleChange={(e) => {
                    form.setFieldsValue({
                      makerBy: e?.value,
                    })
                  }}
                  onChange={() => {}}
                  filters={{ organizationCode: user?.orgCode, personnelCode: user?.pCode }}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </ModalNew>
    </>
  )
}

export default ForwardAssessRickModal
