import { gql } from '@apollo/client'

export const GET_MASTER_CAUSE_OF_ISSUE = gql`
  query GetMasterCauseOfIssue($input: MasterCauseOfIssueInput!) {
    getMasterCauseOfIssue(input: $input) {
      causeCode
      causeNameTH
      causeNameEN
    }
  }
`
