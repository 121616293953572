import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import { Col, Row, Tooltip, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation, Navigate, useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { resultListCrumb } from './utils/constants'
import { encryptInput } from '../../../utilitys/crypto'
import { yearDisplay } from '../../../utilitys/dateHelper'
import { displayText } from '../../../utilitys/helper'
import { downloadFile } from '../../../utilitys/files'
import { colors } from '../../../configs/styles.config'
import { CardNew } from '../../../components/card/Card'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { TabNew } from '../../../components/tab/Tab'
import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonNew } from '../../../components/button/Button'
import { IconNew } from '../../../components/icon/Icon'
import { Select } from '../../../components/select/Select'
import { LabelNew as Label } from '../../../components/label/Label.jsx'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import {
  GET_CONTROL_RESULT_ID,
  GET_CONTROL_FORM_CONF,
  GET_CONTROL_FORM_QUESTION,
  GET_CONTROL_RESULTS,
  GET_MASTER_CHOICE_GROUP_DETAILS,
} from './graphql/Query'

const ResultDetailTopic = (props) => {
  const responsiveLabel = { sm: 10, md: 6, lg: 6 }
  const responsiveData = { sm: 14, md: 18, lg: 18 }

  const { state } = useLocation()
  const { t } = useTranslation()

  const [data, setData] = useState(null)
  const [organization, setOrganization] = useState([])

  const [getControlResultsIdFn, { loading: loadingControlResultById, data: dataResult }] =
    useLazyQuery(GET_CONTROL_RESULT_ID)
  const [getControlResultsFn, { loading: loadingControlResults, data: dataResults }] = useLazyQuery(GET_CONTROL_RESULTS)

  useEffect(() => {
    if (state?.controlFormCode && state?.activeType === 'views') {
      getControlResultsFn({ variables: { input: encryptInput({ controlFormCode: [state?.controlFormCode] }) } })
    }
    if (state?.controlResultId && state?.activeType === 'view') {
      getControlResultsIdFn({ variables: { controlResultId: encryptInput(state?.controlResultId) } })
    }
  }, [state.controlResultId, state.activeType, state.controlFormCode])

  useEffect(() => {
    if (dataResults?.getControlResults) {
      const result = dataResults?.getControlResults

      const getOrgs = _.map(result, (d) => ({
        label: `${d.organizationCode}: ${displayText(d.organizationNameTH, d.organizationNameEN)}`,
        value: d.controlResultId,
      }))

      const dataTopic = {}
      for (const d of result) {
        dataTopic.controlFormYear = d.controlFormYear
        dataTopic.controlFormCode = d.controlFormCode
        dataTopic.controlFormName = displayText(d.controlFormTH, d.controlFormEN)
        dataTopic.organizationName = displayText(d.organizationNameTH, d.organizationNameEN)
      }

      setOrganization(getOrgs)
      setData(dataTopic)
    }
  }, [dataResults?.getControlResults])

  useEffect(() => {
    if (dataResult) {
      const result = dataResult?.getControlResultById

      // const fullName = result?.makerBy
      //   ? displayText(
      //       `${result.n2_namePrefixTH}${result.p2_firstNameTH} ${result.p2_lastNameTH}`,
      //       `${result.n2_namePrefixEN}${result.p2_firstNameEN} ${result.p2_lastNameEN}`,
      //     )
      //   : displayText(
      //       `${result.n1_namePrefixTH}${result.p1_firstNameTH} ${result.p1_lastNameTH}`,
      //       `${result.n1_namePrefixEN}${result.p1_firstNameEN} ${result.p1_lastNameEN}`,
      //     )

      const fullName = displayText(
        `${result.n1_namePrefixTH}${result.p1_firstNameTH} ${result.p1_lastNameTH}`,
        `${result.n1_namePrefixEN}${result.p1_firstNameEN} ${result.p1_lastNameEN}`,
      )

      setData({
        controlFormYear: result.controlFormYear,
        controlFormCode: result.controlFormCode,
        controlFormName: displayText(result.controlFormTH, result.controlFormEN),
        organizationName: displayText(result.organizationNameTH, result.organizationNameEN),
        fullName: fullName,
      })
      props.getControlResult(result)
    }
  }, [dataResult?.getControlResultById])

  const handleChange = (e) => getControlResultsIdFn({ variables: { controlResultId: encryptInput(e?.value) } })

  return (
    <>
      {(loadingControlResults || loadingControlResultById) && <SpinnersNew />}
      <CardNew topic={t('รายละเอียดการประเมิน')} icon="Info" toggledrop={'false'}>
        <Col span={24} offset={2}>
          <Row gutter={[16, 24]}>
            <Col {...responsiveLabel}>{t('ประจำปี')}</Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              {yearDisplay(data?.controlFormYear)}
            </Col>

            <Col {...responsiveLabel}>{t('ชื่อแบบประเมิน')}</Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              {`${data?.controlFormCode}: ${data?.controlFormName}`}
            </Col>

            <Col {...responsiveLabel}>{t('หน่วยงานรับตรวจ')}</Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              {state?.activeType === 'view' ? (
                data?.organizationName
              ) : (
                <Form autoComplete="off" name="organizationForm">
                  <Form.Item>
                    <Select
                      data={organization}
                      placeholder={t('เลือกหน่วยรับตรวจ')}
                      scrollableId={'organizationForm'}
                      showSearch
                      loading={loadingControlResults}
                      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                      onChange={(value) => handleChange(_.find(organization, (data) => data?.value === value))}
                    />
                  </Form.Item>
                </Form>
              )}
            </Col>

            <Col {...responsiveLabel}>{t('ชื่อผู้ทำการประเมิน')}</Col>
            <Col {...responsiveData} style={{ color: colors.primary }}>
              {data?.fullName}
            </Col>
          </Row>
        </Col>
      </CardNew>
    </>
  )
}

const ResultDetail = () => {
  const { t } = useTranslation()
  const { state } = useLocation()
  const navigate = useNavigate()

  if (!state && !state?.controlFormCode) return <Navigate to={'/internalcontrol/result'} />

  const [inputControlQuest, setInputControlQuest] = useState(null)
  const [inputControlConfTab, setInputControlConfTab] = useState([])
  const [controlForm, setControlForm] = useState([])
  const [dataControlQuest, setDataControlQuest] = useState([])
  const [isQuestId, setQuestId] = useState([])
  const [controlResult, setControlResult] = useState(null)
  const [masterChoiceGroupDetail, setMasterChoiceGroupDetail] = useState(null)

  const [getControlFormConfFn, getControlFormConf] = useLazyQuery(GET_CONTROL_FORM_CONF)
  const [getControlFomQuestionFn, getControlFomQuestion] = useLazyQuery(GET_CONTROL_FORM_QUESTION)
  const [getMasterChoiceGroupDetailsFn, getMasterChoiceGroupDetails] = useLazyQuery(GET_MASTER_CHOICE_GROUP_DETAILS)

  useEffect(() => {
    getMasterChoiceGroupDetailsFn({ variables: { input: encryptInput({ isActive: 1 }) } })
  }, [])

  useEffect(() => {
    if (getMasterChoiceGroupDetails?.data) {
      const results = getMasterChoiceGroupDetails?.data?.getMasterChoiceGroupDetails
      const obj = {}
      for (const d of results) obj[d.choiceDetailId] = displayText(d.choiceDetailNameTH, d.choiceDetailNameEN)

      setMasterChoiceGroupDetail(obj)
    }
  }, [getMasterChoiceGroupDetails?.data])

  useEffect(() => {
    if (controlResult?.controlFormCode) {
      getControlFormConfFn({ variables: { controlFormCode: encryptInput(controlResult?.controlFormCode) } })
    }
  }, [controlResult])

  useEffect(() => {
    if (getControlFormConf?.data?.getControlFormConfigurations) {
      const resultConf = getControlFormConf?.data?.getControlFormConfigurations

      setInputControlQuest({
        formConfigId: _.head(resultConf).formConfigId,
        controlResultId: controlResult.controlResultId,
      })

      setInputControlConfTab(
        _.map(resultConf, (d) => ({
          key: d.formConfigId,
          name: displayText(d.formConfigTH, d.formConfigEN),
          disabled: false,
        })),
      )
    }
  }, [getControlFormConf.data])

  useEffect(() => {
    if (inputControlQuest) {
      getControlFomQuestionFn({ variables: { input: encryptInput(inputControlQuest) } })
    }
  }, [inputControlQuest])

  useEffect(() => {
    if (dataControlQuest) {
      const newDataControlQuest = _.map(isQuestId, (d) => _.find(dataControlQuest, (q) => q.key === d))

      setControlForm(
        _.map(inputControlConfTab, (d) => ({
          ...d,
          content: () => <Datatable columns={columns} data={newDataControlQuest} />,
        })),
      )
    }
  }, [inputControlConfTab, isQuestId])

  useEffect(() => {
    if (getControlFomQuestion?.data) {
      const resultQuest = getControlFomQuestion?.data?.getControlFormQuestions

      const setResultDetail = {}
      for (const d of resultQuest) {
        setResultDetail[d.resultDetailId] = {
          answerDetail: d.answerDetail,
          choiceDetailId: d.choiceDetailId,
          files: d.files ? [parseInt(d.files)] : [],
        }
      }

      setQuestId(_.map(resultQuest, 'resultDetailId'))

      const setDataQuest = _.map(resultQuest, (d) => ({
        key: d.resultDetailId,
        quest: displayText(d.questTH, d.questEN),
        choiceDetailId: d.choiceDetailId,
        answerDetail: d.answerDetail,
        files: d.files ? [parseInt(d.files)] : [],
        questRequired: d.questRequired,
        choiceTypeCode: d.choiceTypeCode,
        fileNameLabel: d.fileNameLabel,
        fileNameGen: d.fileNameGen,
        fileType: d.fileType,
        fileByte: d.fileByte,
        filepath: d.filepath,
      }))

      const setNewDataQuest = _.uniqBy([...dataControlQuest, ...setDataQuest], 'key')

      setDataControlQuest(setNewDataQuest)
    }
  }, [getControlFomQuestion?.data])

  const onTabClick = (e) =>
    setInputControlQuest({ formConfigId: parseInt(e), controlResultId: controlResult.controlResultId })

  const columns = [
    {
      title: t('คำถาม'),
      dataIndex: 'quest',
      width: '30%',
      align: 'left',
      render: (text, record, index) => <label>{`${index + 1}. ${record.quest}`}</label>,
    },
    {
      title: t('คำตอบ'),
      dataIndex: 'choiceDetailId',
      width: '10%',
      align: 'center',
      render: (text) => (text ? masterChoiceGroupDetail[text] : '-'),
    },
    {
      title: t('คำอธิบายเพิ่มเติม'),
      dataIndex: 'answerDetail',
      width: '30%',
      align: 'left',
      render: (text) => (text ? text : '-'),
    },
    {
      title: t('เอกสารอ้างอิง'),
      dataIndex: 'files',
      align: 'center',
      render: (text, record) => {
        return record?.files.length ? (
          <Tooltip key={'files'} placement="top" title={record.fileNameLabel + record.fileType}>
            <Label
              type="tab-header-inactive"
              key={'files'}
              onClick={() => downloadFile(record.fileNameLabel + record.fileType, record.fileNameGen + record.fileType)}
            >
              <IconNew icon={'FileText'} className="approve-downloads" />
            </Label>
          </Tooltip>
        ) : (
          <></>
        )
      },
    },
  ]

  return (
    <>
      {(getControlFomQuestion?.loading || getControlFormConf?.loading) && <SpinnersNew />}
      <Col sm={24} md={24} lg={24}>
        <Breadcrumb data={resultListCrumb} title={t('งานประเมินควบคุมภายใน')} />
        <ResultDetailTopic getControlResult={(d) => setControlResult(d)} />
        {controlResult && (
          <CardNew
            topic={displayText(
              `${t('แบบประเมิน')}: ${controlResult.controlFormCode} ${controlResult.controlFormTH}`,
              `${t('แบบประเมิน')}: ${controlResult.controlFormCode} ${controlResult.controlFormEN}`,
            )}
            icon="List"
            toggledrop={'false'}
          >
            <Row gutter={[12, 12]}>
              <Col sm={24} md={24} lg={24} xl={24}>
                <TabNew tab={controlForm} onTabClick={onTabClick} />
                {controlResult && (
                  <Row justify="center" style={{ marginTop: '2rem' }}>
                    <Col>
                      <ButtonNew type="back" onClick={() => navigate('/internalcontrol/result')}>
                        {t('ย้อนกลับ')}
                      </ButtonNew>
                    </Col>
                  </Row>
                )}
              </Col>
            </Row>
          </CardNew>
        )}
        {!controlResult && (
          <Row justify="center" style={{ marginTop: '2rem' }}>
            <Col>
              <ButtonNew type="back" onClick={() => navigate('/internalcontrol/result')}>
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Col>
          </Row>
        )}
      </Col>
    </>
  )
}

export default ResultDetail
