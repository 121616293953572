import React from 'react'
import { Form, Input, Card, Row, Col, Space } from 'antd'
// import moment from 'moment'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { Datepicker } from '../../../components/datepicker/Datepicker'
import { ButtonNew } from '../../../components/button/Button'
import { InputNew, InputNumberNew } from '../../../components/input/Input'
import { Select } from '../../../components/select/Select'
import { FormFilterExample } from './text'
const dataSelect = [
  { label: 'Jack', value: '01' },
  { label: 'Lucy', value: '02' },
  { label: 'Adam', value: '03' },
  { label: 'test4', value: '04', disabled: true }, // option disable
  { label: 'test5', value: '05' },
  { label: 'test6', value: '06' },
]

const Responesive = {
  inputFilter: {
    sm: 24,
    md: 12,
    lg: 8,
  },
  buttonFilter: {
    sm: 24,
    md: 24,
    lg: 24,
  },
}
const FormFiltersExampleNew = (props) => {
  const [form] = Form.useForm()
  // onFinish
  const onFinish = (values) => {
    console.log('onFinish:', values)
    setErrorField('datePickerMockUp', false) // Set false error field
    /*
      // Set Form value 
      form.setFieldsValue({
        textInput: 'Is update',
        select: 'demo',
        select2: 'demo',
        datePicker: '2020-08-12',
        selectMultiple: ['02', '05'],
        inputNumber: 100,
        switch: true,
        checkbox: true,
      })
    
      // Reset Form value 
      form.resetFields()
      */
  }
  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => {
    console.log('onFinishFailed', errorFields)
    errorFields.map((er) => {
      const nameField = er.name[0] || ''
      const message = er.errors[0] || ''
      if (nameField === 'datePicker') setErrorField('datePickerMockUp', message)
    })
  }
  // onValuesChange
  const onValuesChange = (errField, allValue) => {
    console.log('onValuesChange errField', errField)
    console.log('onValuesChange allValue', allValue)
  }

  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false, // Message errors OR remove fields errors
      },
    ])
  }

  return (
    <>
      <Row style={{ marginTop: 24 }}>
        <Col sm={24} md={24} lg={24}>
          <Card title={`Form Filters Example`}>
            <Form
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
              name="exampleFormFilter"
            >
              <Row gutter={[16, 8]}>
                <Col {...Responesive.inputFilter}>
                  <label htmlFor="textInput">Example text Input</label>
                  <Form.Item name="textInput" rules={[{ required: true, message: 'required' }]}>
                    <InputNew />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <label htmlFor="searchSelect">Example Search Select</label>
                  <Form.Item name="searchSelect" rules={[{ required: true, message: 'required' }]}>
                    <Select
                      title={''}
                      data={dataSelect}
                      placeholder={'Search select...'}
                      showSearch
                      onChange={(e, { label, value }) => console.log('label ,value', label, value)}
                      scrollableId="exampleFormFilter"
                      filterOption={(input, option) => {
                        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <label htmlFor="selectMultiple">Select Multiple</label>
                  <Form.Item name="selectMultiple" rules={[{ required: true, message: 'required' }]}>
                    <Select
                      title={''}
                      data={dataSelect}
                      placeholder="multiple select..."
                      multiple
                      onChange={(val) => console.log(val)}
                      scrollableId="exampleFormFilter"
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <label htmlFor="textInput">DatePicker</label>
                  <Form.Item hidden={true} name="datePicker" rules={[{ required: true, message: 'required' }]}>
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name="datePickerMockUp"
                    initialValue={'datePickerMockUp'}
                    rules={[{ required: true, message: 'required' }]}
                  >
                    <>
                      <Datepicker
                        language="th"
                        // value={
                        //   formType === 'UPDATE'
                        //     ? moment(new Date('2020-02-01')).format('YYYY-MM-DD')
                        //     : moment(new Date()).format('YYYY-MM-DD')
                        // }
                        // value={'11-02-2022'}
                        setValueDateFn={(data) => {
                          form.setFieldsValue({
                            datePicker: data,
                          })
                          setErrorField('datePickerMockUp', false) // set false error field
                        }}
                      />
                    </>
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <label htmlFor="textInput">Example select</label>
                  <Form.Item name="select2" rules={[{ required: true, message: 'required' }]}>
                    <Select
                      data={dataSelect}
                      placeholder="default select..."
                      onChange={(val) => console.log(val)}
                      scrollableId="exampleFormFilter"
                    />
                  </Form.Item>
                </Col>
                <Col {...Responesive.inputFilter}>
                  <label htmlFor="textInput">Example Number Input</label>
                  <Form.Item name="inputNumber" rules={[{ required: true, message: 'required' }]}>
                    <InputNumberNew min={1} max={10} />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col {...Responesive.buttonFilter} align="middle">
                  <Form.Item>
                    <>
                      <Space size={[8, 8]} wrap>
                        <ButtonNew type="search" roles={{ type: 'add', menu: 'example' }} htmlType="submit">
                          บันทึก
                        </ButtonNew>
                        &nbsp;
                        <ButtonNew type="clear" onClick={() => form.resetFields()}>
                          ยกเลิก
                        </ButtonNew>
                      </Space>
                    </>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Row>
              <Col md={24}>
                {props.disabledCode ? null : (
                  <>
                    <CodeBlock code={FormFilterExample} />
                  </>
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default FormFiltersExampleNew
