/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import { Row, Col, Space, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { useLocation, useNavigate, Navigate } from 'react-router-dom'
import { ButtonNew } from '../../../../components/button/Button'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { Alert } from '../../../../components/alert/Alert'
import { useMutation, useLazyQuery } from '@apollo/client'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { SAVE_AUDIT_RESULT_INSPEC_TION } from '../graphql/Mutation'
import { GET_AUDIT_RESULT_INSPEC_TION, GET_AUDIT_RESULT_INSPEC_TION_PROJECT } from '../graphql/Query'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayText, displayError, getSegmentUrl } from '../../../../utilitys/helper'
import CheckerOrganizationModal from '../../../components/modalformapi/modalCheckerOrganization/CheckerOrganizationModal'
import { Datatable } from '../../../../components/datatable/Datatable'

const AuditResultInspection = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { state } = useLocation()
  const [form] = Form.useForm()
  const { menu } = props
  const [errorOrganizationList, setErrorOrganizationList] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [modal, setModal] = useState(false)
  const [typeActionTab2, setTypeActionTab2] = useState('add')

  const params = getSegmentUrl(4)
  if (params !== 'inspection') return <></>

  if (!state) {
    return <Navigate to={'/auditoperations/auditresult/'} />
  }

  const [cellSave, { data: dataSave, loading: loadingSave, error: errorSave }] =
    useMutation(SAVE_AUDIT_RESULT_INSPEC_TION)

  const [cellGet, { data: dataGet, loading: loadingGet, error: errorGet }] = useLazyQuery(GET_AUDIT_RESULT_INSPEC_TION)
  const [cellGetAdd, { data: dataGetAdd, loading: loadingGetAdd, error: errorGetAdd }] = useLazyQuery(
    GET_AUDIT_RESULT_INSPEC_TION_PROJECT,
  )
  useEffect(() => {
    if (state.type_actionSub === 'edit' || state.type_actionSub === 'view') {
      if (state?.issueId) {
        let variables = encryptInput({ issueId: state.issueId })
        cellGet({
          variables,
        })
      }
    } else {
      const variables = encryptInput({ projectCode: state.refCode })
      cellGetAdd({
        variables,
      })
    }
  }, [state])

  useEffect(() => {
    if (dataGetAdd) {
      const result = dataGetAdd.auditResultInspectionProjectGet
      if (result.length > 0) {
        const dataMap = result.map((item, index) => {
          return {
            key: index + 1,
            deleteId: item.organizationCode,
            organizationCode: item.organizationCode,
            organizationId: item.organizationId,
            organizationLevelCode: item.organizationLevelCode,
            organizationLevelName: displayText(item.organizationLevelNameTH, item.organizationLevelNameEN),
            organizationName: displayText(item.organizationNameTH, item.organizationNameEN),
            organizationNameEN: item.organizationNameEN,
            organizationNameTH: item.organizationNameTH,
            organizationLevelNameTH: item.organizationLevelNameTH,
            organizationLevelNameEN: item.organizationLevelNameEN,
            personnelCode: item.personnelCode,
            personnelName: displayText(item.personnelNameTH, item.personnelNameEN),
          }
        })
        setDataLists(dataMap)
      }
    }
    if (errorGetAdd) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(errorGetAdd)),
        onOk() {},
      })
    }
  }, [dataGetAdd])

  useEffect(() => {
    if (dataGet) {
      if (dataGet.auditResultInspectionGet.length > 0) {
        const dataMap = dataGet.auditResultInspectionGet.map((item, index) => {
          return {
            key: index + 1,
            deleteId: item.organizationCode,
            organizationCode: item.organizationCode,
            organizationId: item.organizationId,
            organizationLevelCode: item.organizationLevelCode,
            organizationLevelName: displayText(item.organizationLevelNameTH, item.organizationLevelNameEN),
            organizationName: displayText(item.organizationNameTH, item.organizationNameEN),
            organizationNameEN: item.organizationNameEN,
            organizationNameTH: item.organizationNameTH,
            organizationLevelNameTH: item.organizationLevelNameTH,
            organizationLevelNameEN: item.organizationLevelNameEN,
            personnelCode: item.personnelCode,
            personnelName: displayText(item.personnelNameTH, item.personnelNameEN),
          }
        })
        setTypeActionTab2('edit')
        setDataLists(dataMap)
      }
    }
    if (errorGet) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorGet)),
        onOk() {},
      })
    }
  }, [dataGet])

  const Responsive = {
    sm: 24,
    md: 24,
    lg: 24,
  }
  const Columns = [
    {
      title: t('ลำดับ'),
      dataIndex: 'key',
      width: '8%',
      render: (row, x, index) => index + 1,
    },
    {
      title: t('รหัสหน่วยงาน'),
      width: '10%',
      dataIndex: 'organizationCode',
    },
    {
      title: t('หน่วยงาน'),
      width: '14%',
      dataIndex: 'organizationName',
    },
    {
      title: t('ระดับหน่วยงาน'),
      width: '23%',
      dataIndex: 'organizationLevelName',
    },
    {
      title: t('ผู้รับผิดชอบ'),
      width: '25%',
      dataIndex: 'personnelName',
    },
    {
      title: '',
      dataIndex: 'deleteId',
      width: '20%',
      render: (row) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {state.type_actionSub !== 'view' && (
              <ButtonGroup
                menu={menu}
                icons={[
                  {
                    type: 'delete',
                    onClick: () => {
                      if (dataLists.length > 1) return setDataLists([..._.filter(dataLists, (e) => e.deleteId !== row)])
                      if (dataLists.length === 1) {
                        setErrorOrganizationList(true)
                        setTimeout(() => setErrorOrganizationList(false), 4000)
                      }
                    },
                  },
                ]}
              />
            )}
          </div>
        )
      },
    },
  ]

  function setOrganizationList(v) {
    if (_.isEmpty(dataLists)) return setDataLists([...v])
    // check หน่วยงานซ้ำ
    setDataLists([...mergeArrayObjects(dataLists, v)])
  }

  function mergeArrayObjects(arr1, arr2) {
    const arr_ = [...arr1, ...arr2]
    return _.uniqBy(arr_, (i) => i.organizationCode)
  }

  const onFinish = () => {
    fnConfirm()
  }

  const fnConfirm = () => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก ?'),
      onOk() {
        let dataInput = {
          issueId: state.issueId,
          data: dataLists,
          typeAction: typeActionTab2,
        }
        cellSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const onFinishFailed = () => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: t('กรุณากรอกข้อมูลให้ครบถ้วน'),
      onOk() {},
    })
  }

  useEffect(() => {
    if (dataSave) {
      if (dataSave.auditResultInspectionSave.issueId) {
        Alert({
          type: 'success',
          title: t('บันทึกข้อมูลสำเร็จ'),
          onOk() {
            setTypeActionTab2('edit')
          },
        })
      }
    }

    if (errorSave) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถบันทึกข้อมูลได้`),
        content: t(displayError(errorSave)),
        onOk() {},
      })
    }
  }, [dataSave])

  return (
    <>
      {loadingSave || loadingGet || loadingGetAdd ? <SpinnersNew /> : null}
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelAlign="left"
        labelCol={{
          md: 8,
          lg: 8,
        }}
        wrapperCol={{
          md: 24,
          lg: 14,
          align: 'left',
        }}
        layout="horizontal"
        name="riskFactorExamine_form"
      >
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            <Space size={[8, 8]} wrap>
              {state.type_actionSub !== 'view' && (
                <ButtonNew
                  type="plusTableBorderPrimary"
                  roles={{ type: 'add', menu: menu }}
                  onClick={() => {
                    setModal(true)
                  }}
                >
                  {t('หน่วยงานรับตรวจ')}
                </ButtonNew>
              )}
              &nbsp;
            </Space>
            {errorOrganizationList ? (
              <>
                <span style={{ color: '#ea5456' }}>{t('กรุณาระบุหน่วยรับตรวจอย่างน้อย 1 รายการ')}</span>
              </>
            ) : null}
          </Col>
          <Col {...Responsive}>
            <Datatable
              columns={Columns}
              data={[...dataLists]}
              size="middle"
              pagination={true}
              scroll={{ x: '100%', y: 350 }}
            />
          </Col>
          <Col {...Responsive} align="center">
            <Space size={[8, 8]} wrap>
              {state.type_actionSub !== 'view' && (
                <ButtonNew
                  type="primary"
                  roles={{ type: 'add', menu: menu }}
                  htmlType="submit"
                  // loading={loading ? true : false}
                >
                  {t('บันทึก')}
                </ButtonNew>
              )}
              &nbsp;
              <ButtonNew
                type="back"
                onClick={() => {
                  navigate(`/auditoperations/auditresult/TabOne`, {
                    state: state,
                  })
                }}
              >
                {t('ย้อนกลับ')}
              </ButtonNew>
            </Space>
          </Col>
        </Row>
      </Form>

      <CheckerOrganizationModal
        close={(e) => setModal(e)}
        open={modal}
        getData={(v) => setOrganizationList(v)}
        menu={menu}
        orgActive={dataLists}
      />
    </>
  )
}

export default AuditResultInspection
