import { gql } from '@apollo/client'
export const GET_MASTER_ISO_SUB = gql`
  query GetMasterISOSub($input: MasterISOSubInput!) {
    getMasterISOSub(input: $input) {
      ISOSubId
      ISOCSubCode
      ISOCSubNameTH
      ISOCSubNameEN
      ISOCode
      isActive
    }
  }
`
