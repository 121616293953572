import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Row } from 'antd'
import { useGetGraphPersonnelTraining } from './hooks/useGetGraphPersonnelTraining'
import { useGetGraphPersonnelPosition } from './hooks/useGetGraphPersonnelPosition'
import { useGetGraphPersonnelStartWorking } from './hooks/useGetGraphPersonnelStartWorking'
import { useGetGraphPersonnelLeaveStatus } from './hooks/useGetGraphPersonnelLeaveStatus'
import { useGetGraphCertificates } from './hooks/useGetGraphCertificates'
import { useGetGraphCertificateGroup } from './hooks/useGetGraphCertificateGroup'
import { useGetGraphAssignJobs } from './hooks/useGetGraphAssignJobs'
import { useTranslation } from 'react-i18next'
import { SpinnersSmaillNew } from '../../../../components/spinners/Spinners'
import { NoData } from '../../../../components/nodatagraph/NoData'
import { GraphColumn } from './components/graphcolumn/GraphColumn'
import { GraphColumnVertical } from '../../../../components/graphcolumn/GraphColumnVertical'
import { GraphColumnGroup } from '../../../../components/graphcolumn/GraphColumnGroup'
import { GraphStyled } from './css/GraphStyled'
import { generateProfessionalColors } from '../../../../utilitys/randomcolor'
import { IconNew } from '../../../../components/icon/Icon'
import _ from 'lodash'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
  graph: {
    md: 12,
    lg: 12
  },
  graphThirdCol: {
    md: 8,
    lg: 8
  }
}

export default function OverviewAuditGraph(props) {
  const { dataFilter } = props
  const { t } = useTranslation()
  const [dataAssignJobsColumn, setDataAssignJobsColumn] = useState([])
  const [assignJobsColor, setAssignJobsColor] = useState([])
  const [dataCertificatesColumn, setDataCertificatesColumn] = useState([])
  const [certificatesRandomColor, setCertificatesRandomColor] = useState([])
  const [dataCertificateGroupColumn, setDataCertificateGroupColumn] = useState([])
  const [dataPersonnelTrainingColumn, setDataPersonnelTrainingColumn] = useState([])
  const [dataPersonnelPositionColumn, setDataPersonnelPositionColumn] = useState([])
  const [dataPersonnelLeaveStatusColumn, setDataPersonnelLeaveStatusColumn] = useState([])
  const [dataPersonnelLeaveStatusColor, setDataPersonnelLeaveStatusColor] = useState([])
  const [dataPersonnelStartWorkingColumn, setDataPersonnelStartWorkingColumn] = useState([])
  const [personnelStartWorkingColor, setPersonnelStartWorkingColor] = useState([])

  const [averagePersonnelStartWorking, setAveragePersonnelStartWorking] = useState('0')
  const inputOptions = useMemo(() => {
    return { filters: { ...dataFilter } }
  }, [dataFilter])

  // ==================================== API Column Personnel Training ====================================
  const [resGraphColumn, loadingColumn] = useGetGraphPersonnelTraining(inputOptions)

  useEffect(() => {
    if (Array.isArray(resGraphColumn) && resGraphColumn.length > 0) {
      setDataPersonnelTrainingColumn(
        resGraphColumn.map((item) => {
          return {
            ...item,
            level: t(item.level),
            type: t(item.type)
          }
        })
      )
    } else {
      setDataPersonnelTrainingColumn([])
    }
  }, [resGraphColumn])

  // ==================================== API Column Personnel Position ====================================
  const [resGraphColumnPosition, loadingColumnPosition] = useGetGraphPersonnelPosition(inputOptions)

  useEffect(() => {
    if (Array.isArray(resGraphColumnPosition) && resGraphColumnPosition.length > 0) {
      setDataPersonnelPositionColumn(
        resGraphColumnPosition.map((item) => {
          return {
            ...item,
            level: item.level, // don't need to translate
            type: item.type // don't need to translate
          }
        })
      )
    } else {
      setDataPersonnelPositionColumn([])
    }
  }, [resGraphColumnPosition])

  // ==================================== API Column Personnel Start Working ====================================
  const [resGraphColumnStartWorking, loadingColumnStartWorking] = useGetGraphPersonnelStartWorking(inputOptions)

  useEffect(() => {
    if (Array.isArray(resGraphColumnStartWorking) && resGraphColumnStartWorking.length > 0) {
      setDataPersonnelStartWorkingColumn(
        resGraphColumnStartWorking.map((item) => {
          return {
            ...item,
            level: t(item.level),
            type: item.type // don't need to translate
          }
        })
      )
      const sum = resGraphColumnStartWorking.reduce((acc, item) => acc + item.value, 0)
      const average = sum / 3 // 3 is the number of status
      setAveragePersonnelStartWorking(average.toFixed(2))

      // Set color
      setPersonnelStartWorkingColor(colorArrayByType(resGraphColumnStartWorking))
    } else {
      setDataPersonnelStartWorkingColumn([])
      setAveragePersonnelStartWorking('0')
    }
  }, [resGraphColumnStartWorking])

  // ==================================== API Column Assign Jobs ====================================
  const [resGraphColumnAssignJobs, loadingColumnAssignJobs] = useGetGraphAssignJobs(inputOptions)

  useEffect(() => {
    if (Array.isArray(resGraphColumnAssignJobs) && resGraphColumnAssignJobs.length > 0) {
      setDataAssignJobsColumn(
        resGraphColumnAssignJobs.map((item) => {
          return {
            ...item,
            level: t(item.level),
            type: t(item.type)
          }
        })
      )

      // Set color
      setAssignJobsColor(colorArrayByType(resGraphColumnAssignJobs))
    } else {
      setDataAssignJobsColumn([])
    }
  }, [resGraphColumnAssignJobs])

  // ==================================== API Column Personnel Leave Status ====================================
  const [resGraphColumnLeaveStatus, loadingColumnLeaveStatus] = useGetGraphPersonnelLeaveStatus(inputOptions)

  useEffect(() => {
    if (Array.isArray(resGraphColumnLeaveStatus) && resGraphColumnLeaveStatus.length > 0) {
      setDataPersonnelLeaveStatusColumn(
        resGraphColumnLeaveStatus.map((item) => {
          return {
            ...item,
            level: t(item.level),
            type: item.type // don't need to translate
          }
        })
      )

      // Set color
      setDataPersonnelLeaveStatusColor(colorArrayByType(resGraphColumnLeaveStatus))
    } else {
      setDataPersonnelLeaveStatusColumn([])
    }
  }, [resGraphColumnLeaveStatus])

  // ==================================== API Column Certificates ====================================
  const [resGraphColumnCertificates, loadingColumnCertificates] = useGetGraphCertificates(inputOptions)

  useEffect(() => {
    if (Array.isArray(resGraphColumnCertificates) && resGraphColumnCertificates.length > 0) {
      setDataCertificatesColumn(
        resGraphColumnCertificates.map((item) => {
          return {
            ...item,
            level: item.level, // don't need to translate
            type: item.type // don't need to translate
          }
        })
      )

      // random color by type
      const countRes = resGraphColumnCertificates.length
      const randomColor = generateProfessionalColors(countRes)

      setCertificatesRandomColor(randomColor)
    } else {
      setDataCertificatesColumn([])
    }
  }, [resGraphColumnCertificates])

  // ==================================== API Column Certificate Group ====================================
  const [resGraphColumnCertificateGroup, loadingColumnCertificateGroup] = useGetGraphCertificateGroup(inputOptions)

  useEffect(() => {
    if (Array.isArray(resGraphColumnCertificateGroup) && resGraphColumnCertificateGroup.length > 0) {
      setDataCertificateGroupColumn(
        resGraphColumnCertificateGroup.map((item) => {
          return {
            ...item,
            level: item.level, // don't need to translate
            type: item.type // don't need to translate
          }
        })
      )
    } else {
      setDataCertificateGroupColumn([])
    }
  }, [resGraphColumnCertificateGroup])

  // ==================================== Functions ====================================
  const colorArrayByType = (data) => {
    return data.reduce((acc, item) => {
      const mappedColor = [
        {
          types: ['งานนอกแผน', 'New employee', 'พนักงานใหม่', 'เกษียณ'],
          color: '#9bbb59'
        },
        {
          types: ['Relocate', 'ย้ายเข้า', 'ลาออก'],
          color: '#c0504d'
        },
        {
          types: ['งานตามแผน', 'กำลังปฏิบัติงาน', 'ย้ายออก', 'moved'],
          color: '#4f81bd'
        },
        {
          types: ['งานมอบหมาย'],
          color: '#93cddd'
        }
      ]

      const findColor = mappedColor.find((itemColor) => itemColor.types.includes(item.type))
      if (findColor) {
        acc.push(findColor.color)
      } else {
        acc.push('#ccc')
      }

      return acc
    }, [])
  }

  return (
    <Row gutter={[16, 8]}>
      <Col {...Responsive.graph}>
        <GraphStyled>
          <Card
            className="card-dash card-pie"
            title={
              <Row>
                <Col md={22} align="left">
                  <span className="card-title-icon">
                    <IconNew icon={'BarChart2'} size={17} />
                  </span>
                  <span className="card-title-text">{t('กราฟแสดงจำนวนคนที่อบรม 40 ชั่วโมง')}</span>
                </Col>
              </Row>
            }
          >
            {loadingColumn && <SpinnersSmaillNew />}
            {!_.isEmpty(dataPersonnelTrainingColumn) ? (
              <GraphColumn data={dataPersonnelTrainingColumn} colors={['#ccc1da']} />
            ) : (
              <NoData />
            )}
          </Card>
        </GraphStyled>
      </Col>
      <Col {...Responsive.graph}>
        <GraphStyled>
          <Card
            className="card-dash card-pie"
            title={
              <Row>
                <Col md={22} align="left">
                  <span className="card-title-icon">
                    <IconNew icon={'BarChart2'} size={17} />
                  </span>
                  <span className="card-title-text">{t('ตำแหน่งงาน')}</span>
                </Col>
              </Row>
            }
          >
            {loadingColumnPosition && <SpinnersSmaillNew />}
            {!_.isEmpty(dataPersonnelPositionColumn) ? (
              <GraphColumn data={dataPersonnelPositionColumn} colors={['#4f81bd']} />
            ) : (
              <NoData />
            )}
          </Card>
        </GraphStyled>
      </Col>
      <Col {...Responsive.graphThirdCol}>
        <GraphStyled>
          <Card
            className="card-dash card-pie"
            title={
              <Row>
                <Col md={22} align="left">
                  <div>
                    <span className="card-title-icon">
                      <IconNew icon={'BarChart2'} size={17} />
                    </span>
                    <span className="card-title-text">{t('สถานะการเข้าทำงาน')}</span>
                    <span className="card-title-text">{`${t('ค่าเฉลี่ยทั้งปี')} ${averagePersonnelStartWorking}`}</span>
                  </div>
                </Col>
              </Row>
            }
          >
            {loadingColumnAssignJobs && <SpinnersSmaillNew />}
            {!_.isEmpty(dataPersonnelStartWorkingColumn) ? (
              <GraphColumnVertical
                data={dataPersonnelStartWorkingColumn}
                colors={personnelStartWorkingColor}
                height={150}
              />
            ) : (
              <NoData />
            )}
          </Card>
        </GraphStyled>
      </Col>
      <Col {...Responsive.graphThirdCol}>
        <GraphStyled>
          <Card
            className="card-dash card-pie"
            title={
              <Row>
                <Col md={22} align="left">
                  <span className="card-title-icon">
                    <IconNew icon={'BarChart2'} size={17} />
                  </span>
                  <span className="card-title-text">{t('จำนวนโครงการ')}</span>
                </Col>
              </Row>
            }
          >
            {loadingColumnStartWorking && <SpinnersSmaillNew />}
            {!_.isEmpty(dataAssignJobsColumn) ? (
              <GraphColumnVertical data={dataAssignJobsColumn} colors={assignJobsColor} height={150} />
            ) : (
              <NoData />
            )}
          </Card>
        </GraphStyled>
      </Col>
      <Col {...Responsive.graphThirdCol}>
        <GraphStyled>
          <Card
            className="card-dash card-pie"
            title={
              <Row>
                <Col md={22} align="left">
                  <span className="card-title-icon">
                    <IconNew icon={'BarChart2'} size={17} />
                  </span>
                  <span className="card-title-text">{t('สถานะออกจากงาน')}</span>
                </Col>
              </Row>
            }
          >
            {loadingColumnLeaveStatus && <SpinnersSmaillNew />}
            {!_.isEmpty(dataPersonnelLeaveStatusColumn) ? (
              <GraphColumnVertical
                data={dataPersonnelLeaveStatusColumn}
                colors={dataPersonnelLeaveStatusColor}
                height={150}
              />
            ) : (
              <NoData />
            )}
          </Card>
        </GraphStyled>
      </Col>
      <Col {...Responsive.graph}>
        <GraphStyled>
          <Card
            className="card-dash card-pie"
            title={
              <Row>
                <Col md={22} align="left">
                  <span className="card-title-icon">
                    <IconNew icon={'BarChart2'} size={17} />
                  </span>
                  <span className="card-title-text">{t('จำนวนวุฒิบัตร')}</span>
                </Col>
              </Row>
            }
          >
            {loadingColumnCertificates && <SpinnersSmaillNew />}
            {!_.isEmpty(dataCertificatesColumn) ? (
              <GraphColumnGroup data={dataCertificatesColumn} colors={certificatesRandomColor} height={150} />
            ) : (
              <NoData />
            )}
          </Card>
        </GraphStyled>
      </Col>
      <Col {...Responsive.graph}>
        <GraphStyled>
          <Card
            className="card-dash card-pie"
            title={
              <Row>
                <Col md={22} align="left">
                  <span className="card-title-icon">
                    <IconNew icon={'BarChart2'} size={17} />
                  </span>
                  <span className="card-title-text">{t('จำนวนวุฒิบัตรตามกลุ่ม')}</span>
                </Col>
              </Row>
            }
          >
            {loadingColumnCertificateGroup && <SpinnersSmaillNew />}
            {!_.isEmpty(dataCertificateGroupColumn) ? (
              <GraphColumnVertical data={dataCertificateGroupColumn} colors={['#4f81bd']} height={150} />
            ) : (
              <NoData />
            )}
          </Card>
        </GraphStyled>
      </Col>
    </Row>
  )
}
