import React from 'react'
import { LabelNew } from '../../../../components/label/Label'
import { downloadFile } from '../../../../utilitys/files'
import { validateURL } from '../../../../utilitys/helper'

const Files = (props) => {
  const { item, index } = props
  const title = item.fileId && item.fileNameLabel

  return (
    <LabelNew
      type="tab-header-inactive"
      key={'labelFilesApprove' + index}
      onClick={() => {
        item.fileId
          ? downloadFile(item.fileNameLabel + item.fileType, item.fileNameGen + item.fileType, () => {})
          : window.open(validateURL(item.links), '_blank')
      }}
      style={{ cursor: 'pointer', color: '#2b4990' }}
    >
      <u>{title}</u>
    </LabelNew>
  )
}

export default Files
