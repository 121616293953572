import { gql } from '@apollo/client'
export const SAVE_AUDIT_JOB_STEP_SELECT = gql`
  mutation SaveAuditJobStepSelect($input: AuditJobStepSelectSaveInput!) {
    saveAuditJobStepSelect(input: $input) {
      actionPlanId
    }
  }
`

export const SAVE_AUDIT_JOB_STEP = gql`
  mutation SaveAuditJobStep($input: AuditJobStepSaveInput!) {
    saveAuditJobStep(input: $input) {
      message
    }
  }
`

export const DELETE_AUDIT_JOB_STEP = gql`
  mutation DeleteAuditJobStep($input: AuditJobStepDeleteInput!) {
    deleteAuditJobStep(input: $input) {
      message
    }
  }
`
