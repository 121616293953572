import React, { useState } from 'react'
import { Row, Col, Form } from 'antd'

import { Checkbox, CheckboxGroup } from '../../../components/checkbox/Checkbox'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { ButtonNew } from '../../../components/button/Button'
import { codeBasic, codeGroup, codeRequired } from './CheckboxCode'

const CheckboxIndex = (props) => {
  const [form] = Form.useForm()

  const { menu } = props

  const plainOptions = [
    { label: 'Apple', value: '01' },
    { label: 'Pear', value: '02' },
    { label: 'Orange', value: '03' },
  ]
  const defaultCheckedList = ['01', '02']

  const [checkedList, setCheckedList] = useState(defaultCheckedList)
  const [indeterminate, setIndeterminate] = useState(true)
  const [checkAll, setCheckAll] = useState(false)
  const [checked, setchecked] = useState(true)
  const [checkedRequired, setcheckedRequired] = useState(false)

  const onChangeCheckbox = (e) => {
    setchecked(e.target.checked)
    console.log(`checked = ${e.target.checked}`)
  }

  const onChangeRequired = (e) => {
    setcheckedRequired(e.target.checked)

    console.log(`checked required = ${e.target.checked}`)
  }

  const onChangeFruit = (list) => {
    console.log('list: ', list)
    setCheckedList(list)
    setIndeterminate(!!list.length && list.length < plainOptions.length)
    setCheckAll(list.length === plainOptions.length)
  }

  const onCheckAllChange = (e) => {
    setCheckedList(
      e.target.checked
        ? plainOptions.map(({ value }) => {
            return value
          })
        : [],
    )
    console.log('e.target.checked: ', e.target.checked)
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const onClickButton = () => {
    setchecked(!checked)
  }

  return (
    <React.Fragment>
      <Form form={form} name={'checkbox_form'} className="site-layout-background">
        <Row>
          <Col span={5}>
            <Checkbox checked={checked} onChange={onChangeCheckbox}>
              Basic เบสิค
            </Checkbox>
          </Col>
          <Col span={5}>
            <Checkbox onChange={onChangeCheckbox} disabled>
              Disabled
            </Checkbox>
          </Col>
          <Col span={5}>
            <ButtonNew type="primary" onClick={onClickButton} roles={{ type: 'add', menu: menu }}>
              Toggle
            </ButtonNew>
          </Col>
        </Row>
        <div style={{ marginBottom: '20px' }}></div>
        <CodeBlock code={codeBasic} />
        <div style={{ marginBottom: '20px' }}></div>
        <Row>
          <Col span={5}>
            <Form.Item
              name="required_checkbox"
              valuePropName="checked"
              rules={[
                {
                  required: true,
                  validator: (_, value) =>
                    value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement')),
                },
              ]}
            >
              <Checkbox checked={checkedRequired} onChange={onChangeRequired}>
                Required
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={5}></Col>
          <Col span={5}>
            <ButtonNew type="primary" htmlType="submit" roles={{ type: 'add', menu: menu }}>
              Submit
            </ButtonNew>
          </Col>
        </Row>
        <div style={{ marginBottom: '20px' }}></div>
        <CodeBlock code={codeRequired} />
        <div style={{ marginBottom: '20px' }}></div>

        <Row>
          <Col span={5}>
            <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
              Check all
            </Checkbox>
          </Col>
          <Col span={10}>
            <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChangeFruit} />
          </Col>
        </Row>
        <div style={{ marginBottom: '20px' }}></div>
        <CodeBlock code={codeGroup} />
        <div style={{ marginBottom: '20px' }}></div>
      </Form>
    </React.Fragment>
  )
}

export default CheckboxIndex
