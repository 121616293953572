import React, { useState, useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { ModalNew } from '../../../../components/modal/Modal'
import { useTranslation } from 'react-i18next'
import { CREATE_CERTIFICATE_PERSONNELS } from '../graphql/Mutation'
import { GET_ONE_CERTIFICATE } from '../graphql/Query'
import { InputNew } from '../../../../components/input/Input'
import { UploadNew } from '../../../../components/upload/Upload'
import { Datepicker } from '../../../../components/datepicker/Datepicker'
import { LabelNew } from '../../../../components/label/Label'
import SelectCertificates from '../../../../components/inputfromapi/selectcertificates/SelectCertificates'
import SelectCertificatesType from '../../../../components/inputfromapi/selectcertificatestype/SelectCertificatesType'
import { Alert } from '../../../../components/alert/Alert'
import { useMutation, useLazyQuery } from '@apollo/client'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayError } from '../../../../utilitys/helper'

const Responesive = {
  sm: 24,
  md: 24,
  lg: 24
}
const CertificateModal = (props) => {
  const { t } = useTranslation()
  const { formType, codeEdit, getPersonnelCertificate, open, colse } = props
  const [form] = Form.useForm()
  const [limitSize, setLimitSize] = useState()
  const [file, setFile] = useState([])
  const [idFileDelete, setIdFileDelete] = useState([])
  const [idFileDefault, setIdFileDefault] = useState([])
  const [visible, setVisible] = useState(false)
  const [certificateFn, { data: getData, error: errorData, loading: loadingData }] = useLazyQuery(GET_ONE_CERTIFICATE)

  useEffect(() => {
    if (visible && formType === 'edit') {
      form.resetFields()
      setIdFileDefault([])
      certificateFn({
        variables: { input: { personnelCertificateId: encryptInput(codeEdit) } }
      })
    }
  }, [visible])

  useEffect(() => {
    if (open) {
      setVisible(open)
    } else {
      setVisible(false)
    }
  }, [open])

  const [createPersonnelCertificate, { loading: loadingPersonnelCertificate, error: errorCertificate }] =
    useMutation(CREATE_CERTIFICATE_PERSONNELS)

  function onClose(e) {
    colse(e)
  }

  const onFinish = (values) => {
    fnConfirm(values)
  }
  const fnConfirm = (values) => {
    Alert({
      type: 'confirm',
      title: 'ต้องการยืนยันการบันทึก ?',
      onOk() {
        onSubmit(values)
      },
      onCancel() {
        onClose(false)
      }
    })
  }
  const onSubmit = async (values) => {
    let dataInput = {
      personnelCertificateId: props.codeEdit,
      personnelCode: props.refCode,
      certificateCode: values.certificateCode,
      certificateNumber: values.certificateNumber || '',
      certificateTypeCode: values.certificateTypeCode || '',
      expireDate: values.expireDate,
      attachFile: file.join(),
      idFileDelete: idFileDelete.join()
    }
    if (props.formType === 'add') {
      dataInput['action'] = 'add'
    } else if (props.formType === 'edit') {
      dataInput['action'] = 'edit'
    }
    await createPersonnelCertificate({
      variables: {
        input: encryptInput(dataInput)
      }
    }).then((res) => {
      if (res.errors) {
        Alert({
          type: 'error',
          title: t('ไม่สามารถบันทึกข้อมูลได้'),
          content: t(displayError(res.errors[0])),
          onOk() {}
        })
      } else {
        Alert({
          type: 'success',
          title: t('บันทึกข้อมูลสำเร็จ'),
          onOk() {
            onClose(false)
            getPersonnelCertificate.refetch()
          }
        })
      }
    })
  }
  const showAlertError = (text) => {
    Alert({
      type: 'error',
      title: t('ไม่สามารถบันทึกข้อมูลได้'),
      content: text,
      onOk() {}
    })
  }
  useEffect(() => {
    if (errorCertificate && errorCertificate.message) {
      showAlertError(t(displayError(errorCertificate)))
    }
    if (errorData && errorData.message) {
      showAlertError(t(displayError(errorData)))
    }
  }, [errorCertificate, errorData])

  useEffect(() => {
    // Get value From your API
    if (formType === 'edit' || formType === 'view') {
      // Set Form value on formType = UPDATE
      if (getData) {
        form.setFieldsValue({
          personnelCertificateId: getData.getCertificate.personnelCertificateId,
          personnelCode: getData.getCertificate.personnelCode,
          certificateCode: getData.getCertificate.certificateCode,
          certificateNumber: getData.getCertificate.certificateNumber,
          certificateTypeCode: getData.getCertificate.certificateTypeCode,
          expireDate: getData.getCertificate.expireDate
        })
        setFile(() => {
          return getData.getCertificate.attachFile.split(',').map((item) => {
            return parseInt(item)
          })
        })

        setIdFileDefault(() => {
          return getData.getCertificate.attachFile.split(',').map((item) => {
            return parseInt(item)
          })
        })
        setIdFileDelete([])
      }
    } else if (formType === 'add') {
      setFile([])
      setIdFileDefault([])
      setIdFileDelete([])
      // Set Form value on formType = CREATE
      form.resetFields()
    }
  }, [getData, visible])

  // onFinishFailed
  const onFinishFailed = ({ errorFields }) => {
    errorFields.map((er) => {
      const nameField = er.name[0] || ''
      const message = er.errors[0] || ''
      if (nameField === 'dateStart') setErrorField('startDate', message)
      if (nameField === 'dateEnd') setErrorField('endDate', message)
    })
  }
  // onValuesChange
  const onValuesChange = () => {}

  // Custom trow errors fields
  function setErrorField(name, errors) {
    // Set field errors
    return form.setFields([
      {
        name: name, // Name fields
        errors: errors ? [errors] : false // Message errors OR remove fields errors
      }
    ])
  }

  return (
    <>
      {loadingPersonnelCertificate || loadingData ? <SpinnersNew /> : ''}
      <ModalNew
        visible={open}
        onSubmit={form.submit}
        onClose={(e) => onClose(e)}
        testTitle={
          formType !== 'view' ? (formType === 'add' ? t('เพิ่มวุฒิบัตร') : t('แก้ไขวุฒิบัตร')) : t('ดูวุฒิบัตร')
        }
        btnName="บันทึก"
        type="medium"
        okButtonProps={formType === 'view' ? { style: { display: 'none' } } : {}}
      >
        <Row gutter={[16, 16]}>
          <Col {...Responesive}>
            <Row style={{ marginTop: 24 }}>
              <Col md={24} lg={24}>
                <Form
                  form={form}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onValuesChange={onValuesChange}
                  autoComplete="off"
                  labelAlign="left"
                  labelCol={{
                    sm: {
                      span: 10,
                      offset: 3
                    },
                    md: {
                      span: 9,
                      offset: 3
                    },
                    xl: {
                      span: 9,
                      offset: 3
                    }
                  }}
                  wrapperCol={{
                    sm: 24,
                    md: 12,
                    xl: 12,
                    align: 'left'
                  }}
                  layout="horizontal"
                  name="certificateForm"
                >
                  <Row>
                    <Col md={18}>
                      <Form.Item
                        name="certificateCode"
                        label={t('ใบประกอบวิชาชีพ')}
                        rules={[{ required: true, message: t('กรุณาระบุใบประกอบวิชาชีพ') }]}
                      >
                        <SelectCertificates
                          formname={'certificateForm'}
                          placeholder={t('เลือกใบประกอบวิชาชีพ/ประกาศนียบัตร')}
                          disabled={formType === 'view' ? true : false}
                          handleChange={(e) =>
                            form.setFieldsValue({
                              certificateCode: e?.value
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item name="certificateNumber" label={t('เลขที่ใบอนุญาต')}>
                        <InputNew placeholder={t('เลขที่ใบอนุญาต')} disabled={formType === 'view' ? true : false} />
                      </Form.Item>
                      <Form.Item
                        name="certificateTypeCode"
                        label={t('ประเภทใบประกอบวิชาชีพ')}
                        rules={[{ required: true, message: t('กรุณาระบุประเภทใบประกอบวิชาชีพ') }]}
                      >
                        <SelectCertificatesType
                          formname={'certificateForm'}
                          placeholder={t('เลือกประเภทใบประกอบวิชาชีพ/ประกาศนียบัตร')}
                          disabled={formType === 'view' ? true : false}
                          handleChange={(e) =>
                            form.setFieldsValue({
                              certificateTypeCode: e?.value
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        name="expireDate"
                        label={t('วันหมดอายุ')}
                        rules={[{ required: true, message: t('กรุณาระบุวันหมดอายุ') }]}
                      >
                        <Datepicker
                          language="th"
                          disabled={formType === 'view' ? true : false}
                          setValueDateFn={(data) => {
                            form.setFieldsValue({
                              dateStart: data
                            })
                            setErrorField('expireDate', false) // set false error field
                          }}
                        />
                      </Form.Item>
                      <Form.Item label={t('ไฟล์แนบ')}>
                        <UploadNew
                          type="mutiple"
                          setIdFile={setFile}
                          defaults={idFileDefault}
                          idFile={file}
                          typeFile="file"
                          actionType={formType === 'add' ? 'create' : formType}
                          setLimitSize={setLimitSize}
                          idFileDelete={idFileDelete}
                          setIdFileDelete={setIdFileDelete}
                          disabled={formType === 'view' ? true : false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col md={{ span: 23, offset: 1 }}>
                    <LabelNew type="error">
                      ไฟล์ต้องมีนามสกุล zip, pdf, png, jpg, jpeg, doc, docx, csv, xls, xlsx, ppt, pptx และ
                      ขนาดไฟล์ทั้งหมดรวมกันต้องไม่เกิน {limitSize} MB
                    </LabelNew>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </ModalNew>
    </>
  )
}

export default CertificateModal
