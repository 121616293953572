import { Card, Col, Row } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Datatable } from '../../../../components/datatable/Datatable'

import { Columns } from './utils/Constants'
import { DataTableStyled } from './css/DataTableStyled'

import { handleSort } from '../../../../utilitys/pagination'
import { useGetPagination } from './hooks/useGetPagination'
import { SpinnersSmaillNew } from '../../../../components/spinners/Spinners'
import { displayText } from '../../../../utilitys/helper'

const Responsive = {
  md: 24,
  lg: 24
}

const OverviewAuditList = (props) => {
  const { dataFilter } = props

  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([])

  const inputOptions = useMemo(() => {
    return { filters: { ...dataFilter }, page, limit, sort }
  }, [dataFilter, page, limit, sort])

  //========================== API PAGINATION ======================================
  const [dataList, listLoading] = useGetPagination(inputOptions)

  useEffect(() => {
    if (dataList) {
      const { result, count } = dataList

      const newData = result.map((dt, idx) => {
        return {
          ...dt,
          key: idx,
          no: idx + 1 + (page - 1) * limit,
          name: displayText(dt.nameTh, dt.nameEn),
          positionName: displayText(dt.positionNameTh, dt.positionNameEn),
          personnelStatusName: displayText(dt.personnelStatusNameTh, dt.personnelStatusNameEn),
          certificateShot: displayText(dt.certificateShotTh, dt.certificateShotEn),
          trainingHours:
            dt.trainingHours > 40
              ? 0
              : ['string', 'number'].includes(typeof dt.trainingHours)
              ? 40 - dt.trainingHours
              : '-'
        }
      })
      setDataLists(newData)
      setCount(count)
    }
  }, [dataList])

  return (
    <DataTableStyled>
      <Card className="">
        <Row gutter={[16, 8]}>
          <Col {...Responsive}>
            {listLoading && <SpinnersSmaillNew />}
            <Datatable
              columns={Columns()}
              data={dataLists}
              total={count}
              searchCustom={false}
              paginationCustom={true}
              //   scroll={{ x: 2000 }}
              enableScroll={{ x: false, y: false }}
              pageSize={limit}
              page={page}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              showSizeChanger={true}
              handleTableChange={(e) => {
                setSort(handleSort(e?.sorter))
              }}
            />
          </Col>
        </Row>
      </Card>
    </DataTableStyled>
  )
}

export default OverviewAuditList
