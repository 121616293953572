import { gql } from '@apollo/client'

export const GET_MASTER_CHOICE_DETAILS = gql`
  query getMasterChoiceDetails($input: MasterChoiceDetailInput!) {
    getMasterChoiceDetails(data: $input) {
      choiceDetialId
      choiceNameTH
      choiceNameEN
      choiceScroe
      choiceTypeCode
    }
  }
`
