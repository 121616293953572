import { gql } from '@apollo/client'

export const GET_AUDIT_PLAN_BY_APPROVE_PLAN_PAGINATION = gql`
  query getApprovePlanPagination($input: ApproveplanPaginationInput!) {
    getApprovePlanPagination(input: $input) {
      result {
        no
        RowData
        planCode
        planNameTH
        planNameEN
        planType
        planYear
        dateStart
        dateEnd
        planStatusCode
        files
        isActive
        planStatusNameTH
        approveStatusCode
      }
      count
      page
      limit
    }
  }
`

export const GET_APPROVE_FILES = gql`
  query getApproveFiles($inputData: ApproveFileInput!) {
    getApproveFiles(inputData: $inputData) {
      fileId
      fileNameLabel
      fileNameGen
      fileType
      filepath
    }
  }
`

export const GET_APPROVE_AUDIT_PROJECT = gql`
  query getApproveAuditProject($inputData: ApproveAuditProjectInput!) {
    getApproveAuditProject(inputData: $inputData) {
      projectId
      projectCode
      projectNameTH
      projectNameEN
      planCode
      projectTypeCode
      projectRefCode
      riskFromCode
      projectSizeCode
      manday
      auditTeamCode
      personnelCode
      numberOfPeople
      mandayPersonnal
      organizationCode
      dateStart
      dateEnd
      projectStatusCode
      approveStatusCode
      cancelRemarkTH
      cancelRemarkEN
      files
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
      cancelStatus
      projectRefNameTH
      projectRefNameEN
      organizationNameTH
      organizationNameEN
      approveStatusNameTH
      approveStatusNameEN
      projectTypeNameTH
      projectTypeNameEN
      auditTeamNameTH
      auditTeamNameEN
      fullNameTH
      fullNameEN
      projectSizeNameTH
      projectSizeNameEN
      planNameTH
      planNameEN
      planType
      auditTypeNameTH
      auditTypeNameEN
      riskFormTH
      riskFormEN
      projectStatusNameTH
      projectStatusNameEN
    }
  }
`

export const GET_APPROVE_AUDIT_PROJECT_LIST = gql`
  query GetApproveAuditProjectList($inputData: ApproveAuditProjectInput!) {
    getApproveAuditProjectList(inputData: $inputData) {
      projectId
      projectCode
      projectNameTH
      projectNameEN
      planCode
      projectTypeCode
      projectRefCode
      riskFromCode
      projectSizeCode
      manday
      auditTeamCode
      personnelCode
      numberOfPeople
      mandayPersonnal
      organizationCode
      dateStart
      dateEnd
      projectStatusCode
      approveStatusCode
      cancelRemarkTH
      cancelRemarkEN
      isActive
      createdAt
      createdBy
      updatedAt
      updatedBy
      cancelStatus
      projectRefNameTH
      projectRefNameEN
      organizationNameTH
      organizationNameEN
      organizationNameTHAp
      organizationNameENAp
      approveStatusNameTH
      approveStatusNameEN
      projectTypeNameTH
      projectTypeNameEN
      fullNameTH
      fullNameEN
      auditTeamNameTH
      auditTeamNameEN
      projectSizeNameTH
      projectSizeNameEN
      files
      planNameTH
      planNameEN
      planType
      auditTypeNameTH
      auditTypeNameEN
      riskFormTH
      riskFormEN
      projectStatusNameTH
      projectStatusNameEN
    }
  }
`

export const GET_APPROVE_AUDIT_PROJECT_GENERAL_INFO = gql`
  query GetApproveAuditProjectGeneralInfo($inputData: ApproveAuditProjectInput!) {
    getApproveAuditProjectGeneralInfo(inputData: $inputData) {
      projectCode
      projectNameTH
      projectNameEN
      planCode
      projectTypeCode
      projectRefCode
      isActive
      projectRefNameTH
      projectRefNameEN
      organizationNameTH
      organizationNameEN
      approveStatusNameTH
      approveStatusNameEN
      planNameTH
      planNameEN
      auditTypeNameTH
      auditTypeNameEN
      riskFormTH
      riskFormEN
      projectStatusNameTH
      projectStatusNameEN
    }
  }
`

export const GET_AUDIT_PLAN_BY_APPROVE_PLAN_ONE = gql`
  query getApproveAuditPlanOne($inputData: ApproveplanOneInput!) {
    getApproveAuditPlanOne(inputData: $inputData) {
      planCode
      planNameTH
      planNameEN
      planType
      planYear
      dateStart
      dateEnd
      planStatusCode
      isActive
      planStatusNameTH
      files
      numberOfPeople
      riskFormTH
      riskFormEN
    }
  }
`

export const GET_APPROVE_PLAN_AUDIT_ORGANIZATION_PAGINATIONS = gql`
  query getApprovePlanAuditOrganizationPagination($inputData: ApproveplanAuditOrganizationPaginationInput!) {
    getApprovePlanAuditOrganizationPagination(inputData: $inputData) {
      result {
        RowData
        organizationCode
        projectCode
        personnelCode
        organizationNameTH
        organizationNameEN
        organizationLevelNameTH
        organizationLevelNameEN
        fullNameTH
        fullNameEN
      }
      count
      page
      limit
    }
  }
`

export const GET_APPROVE_AUDIT_PROJECT_ONE = gql`
  query getApproveAuditProjectOne($inputData: ApproveAuditProjectInput!) {
    getApproveAuditProjectOne(inputData: $inputData) {
      organizationCode
      organizationNameTH
      organizationNameEN
      auditTeamCode
      auditTeamNameTH
      auditTeamNameEN
      projectSizeCode
      projectSizeNameTH
      projectSizeNameEN
      manday
      numberOfPeople
      mandayPersonnal
      dateStart
      dateEnd
      fullNameTH
      fullNameEN
      files
      fileDetail
    }
  }
`
