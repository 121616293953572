import { gql } from '@apollo/client'

export const GET_LIST_SYSTEM_USERS_REPORT_PAGINATION = gql`
  query GetListSystemUsersReportPagination($input: SystemUsersReportPaginationInput!) {
    getListSystemUsersReportPagination(input: $input) {
      result {
        no
        userId
        username
        fullnameTH
        email
        organizationCode
        fullnameEN
        organizationNameTH
        organizationNameEN
        isActive
        roleList {
          roleCode
          roleNameEN
          roleNameTH
        }
      }
      count
      page
      limit
    }
  }
`

export const GET_EXPORT_SYSTEM_USERS_LIST_REPORT = gql`
  query ExportSystemUsersListReport($input: ExportSystemUsersListReportInput!) {
    exportSystemUsersListReport(input: $input) {
      message
      fileUrl
    }
  }
`

export const GET_EXPORT_SYSTEM_USER_BY_ID_REPORT = gql`
  query ExportSystemUserByIdReport($input: ExportSystemUserByIDReportInput!) {
    exportSystemUserByIdReport(input: $input) {
      message
      fileUrl
    }
  }
`
