import React from 'react'
import { Row, Col } from 'antd'
import ApproveAdhocJobList from './ApproveAdhocJobList'

const ApproveAdhocJobIndex = (props) => {
  const { menu } = props
  const Responsive = {
    md: 24,
    lg: 24,
  }
  return (
    <>
      <Row>
        <Col {...Responsive}>
          <ApproveAdhocJobList menu={menu} />
        </Col>
      </Row>
    </>
  )
}

export default ApproveAdhocJobIndex
