import React from 'react'
import { Route, Routes } from 'react-router-dom'
import RiskIndex from './riskform/RiskIndex'
import RiskFormFirstStep from './riskform/RiskFormFirstStep'
import RiskFormSecondStep from './riskform/RiskFormSecondStep'
import AssessRiskList from './assessrisk/AssessRiskList'
import AssessRiskDetail from './assessrisk/AssessRiskDetail'
import RiskResultList from './riskresult/RiskResultList'
import RiskResultDetail from './riskresult/RiskResultDetail'
function Router() {
  return (
    <Routes>
      <Route path="/riskform">
        <Route path="" element={<RiskIndex menu={'RA01'} />} />
        <Route path="createfirststep" element={<RiskFormFirstStep menu={'RA01'} />} />
        <Route path="createsecondstep" element={<RiskFormSecondStep menu={'RA01'} />} />
      </Route>
      <Route path="/assessrisk">
        <Route path="" element={<AssessRiskList menu={'RA02'} />} />
        <Route path="assessriskdetail" element={<AssessRiskDetail menu={'RA02'} />} />
      </Route>
      <Route path="/riskresult">
        <Route path="" element={<RiskResultList menu={'RA03'} />} />
        <Route path="riskresultdetail" element={<RiskResultDetail menu={'RA03'} />} />
      </Route>
    </Routes>
  )
}
export default Router
