import { gql } from '@apollo/client'

export const SAVE_CONTROLNATURE = gql`
  mutation masterControlNatureSave($input: ControlNatureInput!) {
    masterControlNatureSave(input: $input)
  }
`

export const DELETE_CONTROLNATURE_BY_CODE = gql`
  mutation masterControlNatureDelete($controlNatureCode: String!) {
    masterControlNatureDelete(controlNatureCode: $controlNatureCode)
  }
`
