import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { Row, Col } from 'antd'
import * as _ from 'lodash'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { encryptInput } from '../../../utilitys/crypto'
import { Alert } from '../../../components/alert/Alert'
import { GET_LOGFILE_DATA_LIST } from './graphql/Query'
import { handleSort } from '../../../utilitys/pagination'
import { displayError } from '../../../utilitys/helper'
import { dateDisplay, timeDisplay } from '../../../utilitys/dateHelper'
import LogFileEditDataFilter from './components/filter/LogFileEditDataFilter.jsx'
import { ButtonGroup } from '../../../components/button/ButtonGroup.jsx'
import { displayLogEditChangeType, displayLogEditMenuNameLanguage } from '../../../utilitys/helper'
import LogFileEditDataDetailModal from './components/modal/LogFileEditDataDetailModal'
import { LabelNew as Label } from '../../../components/label/Label'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}
const LogFileEditDataList = (props) => {
  const { t } = useTranslation()
  const { menu } = props
  const breadcrumbList = [
    { label: t('Log File'), path: '/logs/logfileeditdata' },
    { label: t('ประวัติการแก้ไขข้อมูลภายในระบบ') },
  ]

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [dataLists, setDataLists] = useState([])
  const [dataParams, setDataParams] = useState()
  const [sort, setSort] = useState([])
  const [visible, setVisible] = useState(false)

  const [filters, setFilters] = useState({})

  /* ---------------- API----------- */
  const [cellPagination, { data: dataPagination, loading: loadingPagination, error: errorPagination }] =
    useLazyQuery(GET_LOGFILE_DATA_LIST)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    cellPagination({
      variables: {
        input: encryptInput({
          filters: { ...filters },
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (dataPagination) {
      let response = dataPagination.getLogFileEditDataList
      setCount(response.count)
      setDataLists(
        _.map(response.result, (item, key) => {
          return {
            key: key + 1,
            username: item.username || '',
            email: item.email || '',
            menuCode: item.menuCode,
            menuNameTH: item.menuNameTH,
            menuNameEN: item.menuNameEN,
            changeTypeId: item.changeType,
            refCode: item.refCode,
            changeType: displayLogEditChangeType(item.changeType),
            createdAt: dateDisplay(item.createdAt) + ' ' + timeDisplay(item.createdAt),
          }
        }),
      )
    }
    if (errorPagination) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorPagination)),
        onOk() {},
      })
    }
  }, [dataPagination])

  const Column = () => {
    return [
      {
        title: t('ผู้ใช้งาน'),
        dataIndex: 'username',
        width: '15%',
        className: 'text-nowrap',
      },
      {
        title: t('อีเมล'),
        dataIndex: 'email',
        width: '20%',
        className: 'text-nowrap',
      },
      {
        title: t('เมนู'),
        dataIndex: displayLogEditMenuNameLanguage(),
        width: '30%',
        className: 'text-nowrap',
      },
      {
        title: t('ประเภทการแก้ไข'),
        dataIndex: 'changeType',
        width: '10%',
        className: 'text-nowrap',
        render: (item, record) => {
          return record.changeTypeId === 1 ? (
            <Label type="body-primary">{item} </Label>
          ) : record.changeTypeId === 2 ? (
            <Label type="body-warning">{item} </Label>
          ) : (
            <Label type="body-danger">{item} </Label>
          )
        },
      },
      {
        title: t('วันเวลาที่แก้ไข'),
        dataIndex: 'createdAt',
        width: '15%',
        className: 'text-nowrap',
      },
      {
        title: '',
        dataIndex: 'tools',
        width: '10%',
        render: (item, record) => {
          return (
            <Row justify="center">
              <Col>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => {
                        fncOnpressDetail(record)
                      },
                    },
                  ]}
                />
              </Col>
            </Row>
          )
        },
      },
    ]
  }

  const fncOnpressDetail = (data) => {
    setVisible(true)
    setDataParams({ ...data, type_action: 'view' })
  }

  return (
    <>
      {loadingPagination && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('ประวัติการแก้ไขข้อมูลภายในระบบ')} />
      <LogFileEditDataFilter
        menu={menu}
        getValue={(e) => {
          const filters = {
            // email: e.email,
            username: e.username,
            changeType: e.changeType,
            menuCode: e.menuCode,
          }
          cellPagination({
            variables: {
              input: encryptInput({
                filters: filters,
                pagination: { limit, page },
                sort: [],
              }),
            },
          })
          setFilters(filters)
        }}
      />
      <CardNew topic={t('รายการ Log การแก้ไขภายในระบบ')} icon="List" toggledrop={'false'}>
        <Row gutter={[16, 16]}>
          <Col {...Responsive}>
            <Datatable
              columns={Column({ fncOnpressDetail })}
              data={[...dataLists]}
              total={count}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              paginationCustom={true}
              searchCustom={true}
              pageSize={limit}
              page={page}
              handleTableChange={(e) => {
                const reNewField = []
                setSort(handleSort(e?.sorter, reNewField))
              }}
            />
          </Col>
        </Row>
      </CardNew>
      <LogFileEditDataDetailModal
        open={visible}
        close={(e) => {
          setVisible(e)
          setDataParams()
        }}
        refetch={() => dataPagination.refetch()}
        dataParams={dataParams}
        menu={menu}
      />
    </>
  )
}

export default LogFileEditDataList
