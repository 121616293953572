import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Alert } from '../../../components/alert/Alert'
import { BreadcrumbNew } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { Status } from '../../../components/status/Status'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError, displayText } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import { DELETE_RISK_FACTOR } from './graphql/Mutation'
import { GET_LIST_RISK_FACTORS_PAGINATION } from './graphql/Query'
import RiskFactorsForm from './RiskFactorsForm'

const Responsive = {
  md: 24,
  lg: 24
}

const RiskFactorsList = ({ menu }) => {
  const { t } = useTranslation()

  const arrCrumb = [
    { label: t('จัดการข้อมูลพื้นฐาน'), path: '/administrator/riskfactors' },
    { label: t('จัดการข้อมูลปัจจัยความเสี่ยง') }
  ]

  const columns = [
    {
      title: <div style={{ textAlign: 'start' }}>{t('ลำดับ')}</div>,
      dataIndex: 'index',
      sorter: false,
      width: '50px',
      render: (item, record) => <div style={{ textAlign: 'start' }}>{record.no}</div>
    },
    {
      title: t('ปัจจัยความเสี่ยง'),
      dataIndex: 'riskFactorName',
      sorter: true,
      width: '300px',
      render: (item, record) => (
        <div>
          {record.riskFactorCode} : {record.riskFactorName}
        </div>
      )
    },
    {
      title: t('สูตรประเมินความเสี่ยง'),
      dataIndex: 'riskFormulaName',
      sorter: true,
      width: '150px',
      render: (item, record) => (
        <div>
          {record.riskFormulaCode} : {record.riskFormulaName}
        </div>
      )
    },
    {
      title: t('ประเภทความเสี่ยง'),
      dataIndex: 'riskType',
      sorter: true,
      width: '150px',
      render: (item) => {
        return item === 1 ? t('1 : ปัจจัยความเสี่ยงองค์กร') : t('2 : ปัจจัยความเสี่ยงกระบวนการ')
      }
    },
    {
      title: t('กลุ่มของความเสี่ยง'),
      dataIndex: 'riskGroup',
      sorter: true,
      width: '150px',
      render: (item) => {
        return item === 1 ? t('1 : ฝ่ายตรวจสอบภายใน') : item === 2 ? t('2 : ฝ่ายตรวจสอบเทคโนโลยีสารสนเทศ') : '-'
      }
    },
    {
      title: t('สถานะ'),
      dataIndex: 'isActive',
      sorter: true,
      width: '150px',
      render: (item) => {
        return <Status text={item === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />
      }
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '100px',
      render: (item, record) => {
        return (
          <div style={{ textAlign: 'center' }}>
            <ButtonGroup
              menu={menu}
              icons={[
                {
                  type: 'view',
                  onClick: () => {
                    settype_action('view')
                    setdataForm(record)
                    setmodal_visible(true)
                  }
                },
                {
                  type: 'edit',
                  onClick: () => {
                    settype_action('edit')
                    setdataForm(record)
                    setmodal_visible(true)
                  }
                },
                {
                  type: 'delete',
                  onClick: () => {
                    Alert({
                      type: 'confirm',
                      title: t('ต้องการยืนยันการลบ?'),
                      onOk() {
                        const dataInput = {
                          riskFactorCode: record.riskFactorCode
                        }

                        callDelete({
                          variables: {
                            input: encryptInput(dataInput)
                          }
                        })
                      },
                      onCancel() {}
                    })
                  }
                }
              ]}
            />
          </div>
        )
      }
    }
  ]

  const [editable] = useState(true)

  const [type_action, settype_action] = useState()
  const [dataForm, setdataForm] = useState()

  const [dataRow, setdataRow] = useState([])

  const [modal_visible, setmodal_visible] = useState(false)
  const [paginate, setPaginate] = useState({ page: 1, limit: 10 })

  const [sort, setsort] = useState([{ fieldSort: 'riskFactorCode', sortType: 'ASC' }])

  const [
    filters
    //  setfilters
  ] = useState({})
  const [count, setcount] = useState(1)

  const [callGetList, { loading: loadingList, error: errorList, data: dataList }] = useLazyQuery(
    GET_LIST_RISK_FACTORS_PAGINATION
  )

  useEffect(() => {
    if (errorList) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorList))
      })
    }
  }, [errorList])

  useEffect(() => {
    if (dataList) {
      let { result, count } = dataList.getListRiskFactorsPagination

      let dataTemp = result.map((item, key) => {
        let riskFactorName = displayText(item.riskFactorNameTH, item.riskFactorNameEN)
        let riskFormulaName = displayText(item.riskFormulaNameTH, item.riskFormulaNameEN)

        return {
          ...item,
          key: key + 1,
          riskFactorName,
          riskFormulaName
        }
      })

      setdataRow(dataTemp)
      setcount(count)
    }
  }, [dataList])

  useEffect(() => {
    if (sort && paginate) fncGetList()
  }, [paginate, sort])

  const [callDelete, { loading: loadingDelete, error: errorDelete, data: dataDelete }] = useMutation(DELETE_RISK_FACTOR)

  useEffect(() => {
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(errorDelete)),
        onOk() {}
      })
    }
  }, [errorDelete])

  useEffect(() => {
    if (dataDelete) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        content: t(dataDelete.message),
        onOk() {
          fncGetList()
        }
      })
    }
  }, [dataDelete])

  const fncGetList = () => {
    const dataInput = {
      sort: sort,
      filters: filters,
      pagination: {
        page: paginate.page,
        limit: paginate.limit
      }
    }

    callGetList({
      variables: {
        input: encryptInput(dataInput)
      }
    })
  }

  const handleModalOnSuccess = () => {
    setdataForm()
    setmodal_visible(false)
    fncGetList()
  }

  const handleModalOnClose = (data) => {
    setdataForm()
    setmodal_visible(data)
  }

  return (
    <React.Fragment>
      <BreadcrumbNew data={arrCrumb} title={t('จัดการข้อมูลปัจจัยความเสี่ยง')} />
      <CardNew topic={t('รายการปัจจัยความเสี่ยง')} icon="List">
        <Row>
          <Col {...Responsive}>
            <Datatable
              columns={columns}
              data={dataRow}
              total={count}
              showSizeChanger={true}
              searchCustom={true}
              pageSize={paginate.limit}
              page={paginate.page}
              isLoading={loadingList || loadingDelete}
              paginationCustom={true}
              scroll={{ x: 768 }}
              onChangePagination={({ page, pageSize }) => {
                setPaginate({ page, limit: pageSize })
              }}
              handleTableChange={(e) => {
                const reNewField = [
                  {
                    riskFactorName: 'riskFactorCode'
                  },
                  {
                    riskFormulaName: 'riskFormulaCode'
                  },
                  { riskType: 'riskType' },
                  { riskGroup: 'riskGroup' },
                  { isActive: 'isActive' }
                ]

                setsort(handleSort(e?.sorter, reNewField))
              }}
              btnAdd={
                editable ? (
                  <ButtonNew
                    onClick={() => {
                      settype_action('add')
                      setmodal_visible(true)
                    }}
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                  >
                    {t('เพิ่มปัจจัยความเสี่ยง')}
                  </ButtonNew>
                ) : (
                  <></>
                )
              }
            />
          </Col>
        </Row>
      </CardNew>
      <RiskFactorsForm
        type_action={type_action}
        dataForm={dataForm}
        visible={modal_visible}
        onSuccess={handleModalOnSuccess}
        onClose={handleModalOnClose}
      />
    </React.Fragment>
  )
}
export default RiskFactorsList
