import React, { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import _ from 'lodash'

import { GET_SELECT_AUDIT_PROJECTS_BY_ACTION_PLAN } from '../../graphql/Query'

import { displayText } from '../../../../../utilitys/helper'
import { Select } from '../../../../../components/select/Select'
import { encryptInput } from '../../../../../utilitys/crypto'
// import { encryptInput } from '../../../utilitys/crypto'

const SelectAuditProjects = (props) => {
  const { filters, projectCode, placeholder, formname, handleChange, ...otherProp } = props
  const { isActive, projectStatusCode, approveStatusCode } = filters || {}

  const [isData, isSetData] = useState([])

  const [getSelectAuditProjectsByActionPlan, { data, loading }] = useLazyQuery(GET_SELECT_AUDIT_PROJECTS_BY_ACTION_PLAN)

  useEffect(() => {
    if (data) {
      isSetData(
        data?.getSelectAuditProjectsByActionPlan.map((item) => {
          let label = displayText(item?.projectNameTH, item?.projectNameEN)
          if (!label) label = item.projectNameTH

          return {
            value: item.projectCode,
            label: `${item.projectCode} : ${label}`,
            source: item,
          }
        }),
      )
    }
  }, [data])

  useEffect(() => {
    getSelectAuditProjectsByActionPlan({
      variables: {
        input: filters
          ? projectCode
            ? encryptInput({ ...filters, projectCode, isActive: 1 })
            : encryptInput({ ...filters, isActive: 1 })
          : encryptInput({ isActive: 1 }),
      },
    })
  }, [isActive, projectStatusCode, approveStatusCode, projectCode])

  useEffect(() => {
    if (projectCode && isData) {
      handleChange(_.find(isData, (data) => data.value === projectCode))
    }
    const firstRunHandleChange = () => {
      if (projectCode && isData) {
        handleChange(_.find(isData, (data) => data.value === projectCode))
      }
    }

    firstRunHandleChange()
  }, [projectCode, isData])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        loading={loading}
        showSearch
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          handleChange(_.find(isData, (data) => data.value === value))
        }}
      />
    </>
  )
}

export default SelectAuditProjects
