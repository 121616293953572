import { Trans } from 'react-i18next'

export const breadcrumbList = [
  { label: <Trans>จัดการข้อมูลพื้นฐาน</Trans>, path: '/administrator/assessmentsetup' },
  { label: <Trans>จัดการข้อมูลแบบประเมิน</Trans> },
]

export const breadcrumbDetail = [
  { label: <Trans>จัดการข้อมูลพื้นฐาน</Trans>, path: '/administrator' },
  { label: <Trans>จัดการข้อมูลแบบประเมิน</Trans>, path: '/administrator/assessmentsetup' },
  { label: <Trans>รายละเอียดแบบประเมิน</Trans>, path: '/administrator/assessmentsetup/assessmentformdetail/edit' },
]
