import { gql } from '@apollo/client'

export const CREATE_SYSTEM_ROLES = gql`
  mutation CreateSystemRoles($input: PermissionFormInput!) {
    createSystemRoles(input: $input) {
      roleId
      roleCode
    }
  }
`

export const DELETE_SYSTEM_ROLES = gql`
  mutation DeleteSystemRoles($roleCode: String!) {
    deleteSystemRoles(roleCode: $roleCode) {
      roleCode
    }
  }
`
