import { Col, Form, Row, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as _ from 'lodash'
import { ButtonNew } from '../../../../../components/button/Button'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { LabelNew } from '../../../../../components/label/Label'
import { CalculateColumns } from '../../utils/RiskFormulaCalculateColumns'
import { ScoreColumns } from '../../utils/RiskFormulaScoreColumns'
import { CALCULATE_AND_SCORE_SAVE, RISK_FORMULAR_SAVE } from '../../graphql/Mutation'
import { useMutation } from '@apollo/client'
import { Alert } from '../../../../../components/alert/Alert'
import { useNavigate } from 'react-router-dom'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'

const CalculateAndScoreTab = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { menu, calculateData, scoreData, variableData, disabled, riskFormulaCode, stateFormRiskFormula } = props
  const formname = 'CalculateAndScoreForm'
  const [form] = Form.useForm()
  const labelCol = { sm: 8, md: 8, lg: { span: 6, offset: 2 }, xl: { span: 6, offset: 2 } }
  const wrapperCol = { sm: 16, md: 16, lg: 10, xl: 10, align: 'left' }
  const Responsive = { md: 24, lg: 24 }

  const [calculateDataLists, setCalculateDataLists] = useState([])
  const [scoreDataLists, setScoreDataLists] = useState([])
  const editable = disabled
  const [
    calculateAndScoreSaveFn,
    { loading: loadingCalculateAndScoreSave, error: errorCalculateAndScoreSave, data: dataCalculateAndScoreSave },
  ] = useMutation(CALCULATE_AND_SCORE_SAVE)
  const [
    riskFormulaSaveFn,
    { loading: loadingRiskFormulaSave, error: errorRiskFormulaSave, data: dataRiskFormulaSave },
  ] = useMutation(RISK_FORMULAR_SAVE)

  useEffect(() => {
    if (calculateData) {
      const mapDataList = calculateData.map((items, key) => {
        return { ...items, key }
      })
      setCalculateDataLists(mapDataList)
    }
    if (scoreData) {
      const mapDataList = scoreData.map((items, key) => {
        return { ...items, key }
      })
      setScoreDataLists(mapDataList)
    }
  }, [calculateData, scoreData])

  useEffect(() => {
    if (dataCalculateAndScoreSave && stateFormRiskFormula) {
      let dataInputRiskFormula = {
        ...stateFormRiskFormula,
        riskFormulaNameEN: stateFormRiskFormula?.riskFormulaNameEN ? stateFormRiskFormula?.riskFormulaNameEN : '',
        action: 'edit',
        isActive: parseInt(stateFormRiskFormula?.isActive),
      }
      riskFormulaSaveFn({
        variables: {
          input: encryptInput(dataInputRiskFormula),
        },
      })
    }
  }, [dataCalculateAndScoreSave, stateFormRiskFormula])

  useEffect(() => {
    if (dataRiskFormulaSave || dataCalculateAndScoreSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          navigate('/administrator/riskformula')
        },
      })
    }

    if (errorCalculateAndScoreSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorCalculateAndScoreSave)),
        onOk() {},
      })
    }

    if (errorRiskFormulaSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        content: t(displayError(errorRiskFormulaSave)),
        onOk() {},
      })
    }
  }, [dataRiskFormulaSave, dataCalculateAndScoreSave])

  /* ---------------- ACTION ----------- */
  const onFinish = (input) => {
    showConfirm(input)
  }

  const showConfirm = (input) => {
    const dataInput = {
      ...input,
      riskFormulaCode: riskFormulaCode,
    }
    const { riskFormulaCalculates = [], riskFormulaScores = [] } = input
    dataInput.riskFormulaCalculates = riskFormulaCalculates.map((items) => {
      return {
        variableCode: items.variableCode,
        operationType: items.operationType,
      }
    })
    dataInput.riskFormulaScores = riskFormulaScores.map((items) => {
      return {
        scoreBegin: items.scoreBegin,
        scoreEnd: items.scoreEnd,
        scoreColor: items.scoreColor ? items.scoreColor : '',
        riskLevelCode: items.riskLevelCode,
      }
    })

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        calculateAndScoreSaveFn({ variables: { input: encryptInput(dataInput) } })
        form.resetFields()
      },
      onCancel() {},
    })
  }

  const handlePressAddCalculate = () => {
    let newData = {
      key: calculateDataLists.length,
      variableCode: null,
      operationType: null,
      riskFormulaCode: riskFormulaCode,
    }

    setCalculateDataLists([...calculateDataLists, newData])
    form.setFieldsValue({ riskFormulaCalculates: [...calculateDataLists, newData] })
  }

  const fncDeleteCalculate = (index) => {
    let newTable = []

    let filterTable = [..._.filter(calculateDataLists, (e, key) => key !== index)]
    if (calculateDataLists.length > 1) {
      newTable = filterTable.map((item, key) => {
        return { ...item, key }
      })
      setCalculateDataLists(newTable)
    } else {
      setCalculateDataLists([])
    }

    form.setFieldsValue({ riskFormulaCalculates: newTable })
  }

  const handlePressAddScore = () => {
    let newData = {
      key: scoreDataLists.length,
      scoreBegin: null,
      scoreEnd: null,
      scoreColor: null,
      riskLevelCode: null,
      riskFormulaCode: riskFormulaCode,
    }
    setScoreDataLists([...scoreDataLists, newData])
    form.setFieldsValue({ riskFormulaScores: [...scoreDataLists, newData] })
  }

  const fncDeleteScore = (index) => {
    let newTable = []

    let filterTable = [..._.filter(scoreDataLists, (e, key) => key !== index)]
    if (scoreDataLists.length > 1) {
      newTable = filterTable.map((item, key) => {
        return { ...item, key }
      })
      setScoreDataLists(newTable)
    } else {
      setScoreDataLists([])
    }

    form.setFieldsValue({ riskFormulaScores: newTable })
  }

  return (
    <>
      {(loadingRiskFormulaSave || loadingCalculateAndScoreSave) && <SpinnersNew />}
      <Row>
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{ ...labelCol }}
          wrapperCol={{ ...wrapperCol }}
          layout="horizontal"
          name={formname}
          style={{ width: '100%' }}
        >
          <Col {...Responsive}>
            <LabelNew type="body-header">{t('ระดับความเสี่ยงที่ใช้ประเมิน')}</LabelNew>
            <Datatable
              enableScroll={{ x: true, y: false }}
              columns={CalculateColumns({
                editable,
                fncDeleteCalculate,
                menu,
                variableData,
                form,
                setCalculateDataLists,
                calculateDataLists,
              })}
              data={calculateDataLists}
              pagination={false}
            />
          </Col>
          <Col {...Responsive}>
            {editable && (
              <ButtonNew type="addMultiple" onClick={handlePressAddCalculate}>
                {t('เพิ่มเกณฑ์คำนวณ')}
              </ButtonNew>
            )}
          </Col>
          <Col {...Responsive}>
            <LabelNew type="body-header" style={{ marginTop: '50px' }}>
              {t('ช่วงคะแนนความเสี่ยง')}
            </LabelNew>
            <Datatable
              enableScroll={{ x: true, y: false }}
              columns={ScoreColumns({
                editable,
                fncDeleteScore,
                menu,
                form,
                setScoreDataLists,
                scoreDataLists,
              })}
              data={scoreDataLists}
              pagination={false}
            />
          </Col>
          <Col {...Responsive}>
            {editable && (
              <ButtonNew type="addMultiple" onClick={handlePressAddScore}>
                {t('เพิ่มข้อมูลช่วงคะแนนความเสี่ยง')}
              </ButtonNew>
            )}
          </Col>
          <Col span={24} align="center" style={{ marginTop: '40px' }}>
            <Space size={[8, 8]} wrap>
              {editable && (
                <ButtonNew type="primary" roles={{ type: 'add', menu: menu }} htmlType="submit">
                  {t('บันทึก')}
                </ButtonNew>
              )}
              <ButtonNew type="back" onClick={() => navigate('/administrator/riskformula')}>
                {t('ยกเลิก')}
              </ButtonNew>
            </Space>
          </Col>
        </Form>
      </Row>
    </>
  )
}
export default CalculateAndScoreTab
