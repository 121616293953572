import React, { useState } from 'react'
import { Row, Col, Form } from 'antd'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { useTranslation } from 'react-i18next'
import FilterAuditOpens from './FilterAuditOpens.jsx'
import AuditOpensList from './AuditOpensList.jsx'

const AuditOpensIndex = (props) => {
  const { t } = useTranslation()
  const breadcrumbList = [{ label: t('งานปฏิบัติการตรวจสอบ') }, { label: t('บันทึกเปิดตรวจ') }]
  const [formFilter] = Form.useForm()
  const [searchStatus, setSearchStatus] = useState(0)
  return (
    <>
      <Row>
        <Col md={24}>
          <Breadcrumb data={breadcrumbList} title={t('บันทึกเปิดตรวจ')} menu={props.menu} />
          <FilterAuditOpens
            menu={props.menu}
            formFilter={formFilter}
            searchStatus={searchStatus}
            setSearchStatus={setSearchStatus}
          />
          <AuditOpensList
            menu={props.menu}
            formFilter={formFilter}
            searchStatus={searchStatus}
            setSearchStatus={setSearchStatus}
          />
        </Col>
      </Row>
    </>
  )
}

export default AuditOpensIndex
