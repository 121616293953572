import { gql } from '@apollo/client'

export const GET_MASTER_STATUS = gql`
  query getMasterJobStatus($input: MasterJobStatusInput!) {
    getMasterJobStatus(input: $input) {
      jobStatusId
      jobStatusCode
      jobStatusNameTH
      jobStatusNameEN
      isActive
    }
  }
`
