import { gql } from '@apollo/client'
export const GET_AUDIT_ISSUES = gql`
  query getAuditIssues($input: SelectAuditIssuesInput!) {
    getAuditIssues(data: $input) {
      issueId
      issueTH
      issueEN
      rateRiskRuleCode
      rateRiskConCode
    }
  }
`
