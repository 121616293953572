import { useLazyQuery, useMutation } from '@apollo/client'
import { Col, Form, Row } from 'antd'
import * as _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Alert } from '../../../components/alert/Alert'
import { ButtonNew } from '../../../components/button/Button'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { CardNew } from '../../../components/card/Card'
import { Datatable } from '../../../components/datatable/Datatable'
import { InputNew } from '../../../components/input/Input'
import SelectIsActive from '../../../components/inputfromapi/selectisactive/SelectIsActive.jsx'
import { LabelNew as Label } from '../../../components/label/Label'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { Status } from '../../../components/status/Status'
import { encryptInput } from '../../../utilitys/crypto'
import { displayError } from '../../../utilitys/helper'
import { handleSort } from '../../../utilitys/pagination'
import AuditTeamModal from './components/modal/AuditTeamModal'
import { DELETE_AUDIT_TEAM_BY_CODE } from './graphql/Mutation'
import { GET_AUDIT_TEAM_PAGINATION } from './graphql/Query'
import { Columns } from './utils/AuditTeamColumns'

const AuditTeamList = (props) => {
  const { menu } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const Responsive = {
    md: 8,
    lg: 8
  }

  const ResponsiveTable = {
    md: 24,
    lg: 24
  }
  const [visible, setVisible] = useState(false)
  const [dataLists, setDataLists] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [sort, setSort] = useState([
    { fieldSort: 'updatedAt', sortType: 'DESC' },
    { fieldSort: 'createdAt', sortType: 'DESC' }
  ])
  const [filters, setFilters] = useState({})

  /* ---------------- API ----------- */
  const [getAuditTeamListFn, dataAuditTeamList] = useLazyQuery(GET_AUDIT_TEAM_PAGINATION)
  const [deleteAuditTeamFn, dataDeleteAuditTeam] = useMutation(DELETE_AUDIT_TEAM_BY_CODE)

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    getAuditTeamListFn({
      variables: {
        input: encryptInput({
          filters: { ...filters },
          pagination: { limit, page },
          sort: sort
        })
      }
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (dataDeleteAuditTeam.data) {
      Alert({
        type: 'success',
        title: t('ลบสำเร็จ'),
        onOk() {
          dataAuditTeamList.refetch()
        }
      })
    }

    if (dataDeleteAuditTeam.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(dataDeleteAuditTeam.error)),
        onOk() {}
      })
    }
  }, [dataDeleteAuditTeam.data])

  useEffect(() => {
    if (dataAuditTeamList?.data) {
      let response = dataAuditTeamList?.data?.getAuditTeamsPagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            ...data,
            key: data.no,
            isActive: <Status text={data.isActive === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () =>
                        navigate('/administrator/manageauditteam/detail/', {
                          state: { type_action: 'view', auditTeamCode: data.auditTeamCode }
                        })
                    },
                    {
                      type: 'edit',
                      onClick: () =>
                        navigate('/administrator/manageauditteam/detail/', {
                          state: { type_action: 'edit', auditTeamCode: data.auditTeamCode }
                        })
                    },
                    {
                      type: 'delete',
                      onClick: () => showConfirm(data.auditTeamCode)
                    }
                  ]}
                />
              </div>
            )
          }
        })
      )
    }

    if (dataAuditTeamList.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(dataAuditTeamList.error)),
        onOk() {}
      })
    }
  }, [dataAuditTeamList?.data])

  /* ---------------- ACTION ----------- */
  const showConfirm = (auditTeamCode) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        deleteAuditTeamFn({
          variables: {
            auditTeamCode: encryptInput(auditTeamCode)
          }
        })
      },
      onCancel() {}
    })
  }

  const onFinish = (input) => {
    const inputSearch = {
      ...input
    }
    setFilters(input)
    setPage(1)
    getAuditTeamListFn({
      variables: {
        input: encryptInput({
          filters: inputSearch,
          pagination: { limit, page },
          sort: sort
        })
      }
    })
  }

  const resetField = () => {
    form.resetFields()
    setFilters({})
    setPage(1)
    getAuditTeamListFn({
      variables: {
        input: encryptInput({
          filters: {},
          pagination: { limit: limit, page: page },
          sort: [
            { fieldSort: 'updatedAt', sortType: 'DESC' },
            { fieldSort: 'createdAt', sortType: 'DESC' }
          ]
        })
      }
    })
  }

  return (
    <>
      {(dataAuditTeamList.loading || dataDeleteAuditTeam.loading) && <SpinnersNew />}
      <CardNew topic={t('Filters')} icon="Filter" toggledrop="true" buttontopright="true">
        <Form name="form-search" form={form} onFinish={onFinish} layout="vertical">
          <Row gutter={[12, 12]}>
            <Col {...Responsive}>
              <Form.Item name={'auditTeamCode'} label={<Label type="tab-header-inactive">{t('รหัสทีมตรวจสอบ')}</Label>}>
                <InputNew placeholder={t('ระบุรหัสทีมตรวจสอบ')} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name={'auditTeamName'} label={<Label type="tab-header-inactive">{t('ชื่อทีมตรวจสอบ')}</Label>}>
                <InputNew placeholder={t('ระบุชื่อทีมตรวจสอบ')} maxLength={100} />
              </Form.Item>
            </Col>
            <Col {...Responsive}>
              <Form.Item name="isActive" label={<Label type="tab-header-inactive">{t('สถานะการใช้งาน')}</Label>}>
                <SelectIsActive
                  formname="form-search"
                  placeholder={t('เลือกสถานะ')}
                  handleChange={(value) => {
                    form.setFieldsValue({ isActive: value ? value.value : null })
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[12, 12]} justify="center">
            <Col>
              <ButtonNew type="search" htmlType="submit">
                {t('ค้นหา')}
              </ButtonNew>
            </Col>
            <Col>
              <ButtonNew type="clear" onClick={() => resetField()}>
                {t('ล้างค่า')}
              </ButtonNew>
            </Col>
          </Row>
        </Form>
      </CardNew>
      <CardNew topic={t('จัดการข้อมูลทีมตรวจสอบ')} icon="List" toggledrop={'false'}>
        <Row gutter={[12, 12]}>
          <Col {...ResponsiveTable}>
            <Datatable
              columns={Columns}
              data={[...dataLists]}
              total={count}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              handleTableChange={(e) => setSort(handleSort(e?.sorter))}
              paginationCustom={true}
              showSizeChanger={true}
              searchCustom={true}
              pageSize={limit}
              page={page}
              scroll={{ x: 1000, y: false }}
              btnAdd={
                <>
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    onClick={() => setVisible(true)}
                  >
                    {t('เพิ่มทีมตรวจสอบ')}
                  </ButtonNew>
                </>
              }
            />
          </Col>
        </Row>
      </CardNew>
      <AuditTeamModal open={visible} close={(e) => setVisible(e)} menu={menu} />
    </>
  )
}

export default AuditTeamList
