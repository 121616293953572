import { gql } from '@apollo/client'

export const GET_ADHOCJOB_PAGINATION = gql`
  query getAdhocJobPagination($input: AdhocJobPaginationInput!) {
    getAdhocJobPagination(input: $input) {
      result {
        no
        jobCode
        jobNumber
        jobTypeNameTH
        jobTypeNameEN
        jobSubjectTH
        jobSubjectEN
        dateStart
        dateEnd
        jobStatusCode
        responsibleOrganization
        requestOrganization
        requestPersonnel
        approvePersonnelName
        approvePersonnelNameTH
        approvePersonnelNameEN
        approveStatusCode
        responsibleOrganizationName
        responsibleOrganizationNameTH
        responsibleOrganizationNameEN
        requestOrganizationName
        requestOrganizationNameTH
        requestOrganizationNameEN
        approveStatusNameTH
        approveStatusNameEN
        jobStatusNameTH
        jobStatusNameEN
        assignJobPersonnels
        assignJobPersonnelsTH
        assignJobPersonnelsEN
      }
      count
      page
      limit
    }
  }
`

export const GET_ADHOCJOB_BY_CODE = gql`
  query getAdhocJobByCode($jobCode: String!) {
    getAdhocJobByCode(jobCode: $jobCode) {
      jobCode
      jobNumber
      jobTypeCode
      jobTypeNameTH
      jobSubjectTH
      jobSubjectEN
      jobDetailTH
      jobDetailEN
      dateStart
      dateEnd
      responsibleOrganization
      responsibleOrganizationName
      requestOrganization
      requestOrganizationName
      approveStatusCode
      jobStatusCode
      jobStatusNameTH
      approveStatusNameTH
      requestPersonnel
      telephone
      approvePersonnel
      approvePersonnelName
      jobFile
    }
  }
`

export const GET_PERSONNELS_LIST = gql`
  query getPersonnelsList($input: FilterPersonnelInput!) {
    getPersonnelsList(input: $input) {
      personnelCode
      firstNameTH
      lastNameTH
      firstNameEN
      lastNameEN
      personnelNameTH
      personnelNameEN
      positionNameTH
      positionNameEN
      organizationNameTH
      organizationNameEN
    }
  }
`

export const GET_ADHOCJOB_PERSONNELS_LIST = gql`
  query getAdhocJobPersonnelByCode($jobCode: String!) {
    getAdhocJobPersonnelByCode(jobCode: $jobCode) {
      personnelCode
      personnelNameTH
      personnelNameEN
      positionNameTH
      positionNameEN
      organizationNameTH
      organizationNameEN
    }
  }
`

export const GET_ADHOCJOB_STEP = gql`
  query getAdhocJobStepByCode($jobCode: String!) {
    getAdhocJobStepByCode(jobCode: $jobCode) {
      stepId
      stepGroup
      actionPlanId
      jobCode
      stepType
      stepSort
      stepNameTH
      stepNameEN
      stepIdRef
      dateStart
      dateEnd
      operationStatusCode
      operationStatusNameTH
      operationStatusNameEN
      responsibleTH
      responsibleEN
      isActive
    }
  }
`

export const GET_ADHOCJOB_STEP_MAIN_BY_CODE = gql`
  query getJobStepMainByCode($jobCode: String!) {
    getJobStepMainByCode(jobCode: $jobCode) {
      stepId
      stepSort
      stepGroup
      actionPlanId
      jobCode
      stepType
      stepNameTH
      stepNameEN
    }
  }
`

export const GET_ADHOCJOB_STEP_BY_ID = gql`
  query getJobStepById($stepId: String!) {
    getJobStepById(stepId: $stepId) {
      stepId
      stepGroup
      actionPlanId
      jobCode
      stepType
      stepSort
      stepNameTH
      stepNameEN
      stepIdRef
      dateStart
      dateEnd
      isActive
      responsible
    }
  }
`

export const GET_JOB_FILES = gql`
  query getAdhocJobFiles($jobCode: String!) {
    getAdhocJobFiles(jobCode: $jobCode) {
      fileId
      filename
      links
      refFile {
        originalname
        name
      }
    }
  }
`

export const GET_ADHOCJOB_CALENDAR = gql`
  query getAdhocJobCalendarPersonnel($personnelCode: String!) {
    getAdhocJobCalendarPersonnel(personnelCode: $personnelCode) {
      event {
        titleTH
        titleEN
        start
        end
        type
        reference {
          refCode
          titleTH
          titleEN
        }
        stepGroup
      }
      calendarOfYear {
        titleTH
        titleEN
        start
        end
        type
        stepGroup
      }
    }
  }
`
