import styled from 'styled-components'

export const HomeStyle = styled.div`
  .container {
    position: fixed;
    top: 50%;
    left: 50%;
  }

  .center {
    margin: 0;
    transform: translate(0%, -50%);
  }
`
