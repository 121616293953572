import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_ISO_SUB } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectISOSub = (props) => {
  const { placeholder, formname, handleChange, iSOCode, ...otherProp } = props
  const [isData, isSetData] = useState([])

  const [celGet, { data, loading }] = useLazyQuery(GET_MASTER_ISO_SUB)

  useEffect(() => {
    celGet({
      variables: { input: encryptInput({ ISOCode: iSOCode }) },
    })
  }, [iSOCode])

  useEffect(() => {
    if (data) {
      isSetData(
        _.map(data?.getMasterISOSub, (item) => {
          return {
            label: displayText(item?.ISOCSubNameTH, item?.ISOCSubNameEN),
            value: item?.ISOCSubCode,
            source: item,
          }
        }),
      )
    }
  }, [data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => {
          return handleChange(_.find(isData, (data) => data.value === value))
        }}
      />
    </>
  )
}

export default SelectISOSub
