import React, { useState } from 'react'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'
import { Row, Col, Card } from 'antd'
import { IconNew } from '../../../components/icon/Icon'
import Table from './examplecomponents/Table'
import { TabNew } from '../../../components/tab/Tab'
import { code } from './text'
import FormExample from '../form/FormExample'

function TabIndex() {
  const [value, setValue] = useState()
  console.log('value: ', value)
  const tabs = [
    // Import components content
    {
      key: 1,
      iconTab: () => <IconNew icon="List" size={14} />,
      name: '[Tab 1] Example: Table',
      disabled: false,
      onClick: () => {
        setValue('tab 1')
      },
      content: () => <Table />,
    },
    // Import components content
    {
      key: 2,
      iconTab: () => <IconNew icon="List" size={14} />,
      name: '[Tab 2] Example: Form multiple rows',
      disabled: false,
      onClick: () => {
        setValue('tab 2')
      },
      content: () => <FormExample formId={'Press enter id'} disabledCode={true} />,
    },
    // Built in components content
    {
      key: 3,
      iconTab: () => <IconNew icon="TrendingUp" size={14} />,
      name: '[Tab 3] Example: Built in components',
      disabled: false,
      content: function () {
        return (
          <div style={{ background: '#f0f0f5' }}>
            <hr />
            <h2>!!! wirte components here !!!</h2>
            <br />
            <h2>Tab 3</h2>
            <br />
            <h2>contents</h2>
            <br />
            <hr />
          </div>
        )
      },
    },
    // disabled true
    // Built in components content
    {
      key: 4,
      name: '[Tab 4] Example: Tab disabled',
      disabled: true,
      content: function () {
        return <span>Tab4</span>
      },
    },
  ]
  return (
    <React.Fragment>
      <Row>
        <Col span={24} style={{ marginTop: 16 }}>
          {/* 
              available now 
              defaultActiveKey detect from tab key
            */}
          <Card title="Tab component" extra={<a href="#">More</a>} style={{ width: '100%' }}>
            <Row>
              <Col span={24} align="middle" style={{ marginTop: 24 }}>
                <TabNew tab={tabs} defaultActiveKey="1" />
              </Col>
              <Col span={24} style={{ marginTop: 24 }}>
                <CodeBlock code={code} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default TabIndex
