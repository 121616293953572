import { gql } from '@apollo/client'

export const SAVE_RISKLEVEL = gql`
  mutation masterRiskLevelSave($input: RiskLevelInput!) {
    masterRiskLevelSave(input: $input)
  }
`

export const DELETE_RISKLEVEL = gql`
  mutation masterRiskLevelDelete($riskLevelCode: String!) {
    masterRiskLevelDelete(riskLevelCode: $riskLevelCode)
  }
`
