import React from 'react'
import { Row, Col } from 'antd'
import OverviewIssueList from './OverviewIssueList'

const Responsive = {
  sm: 24,
  md: 24,
  lg: 24,
}

const OverviewIssueIndex = (props) => {
  const { menu } = props
  return (
    <>
      <Row>
        <Col {...Responsive}>
          <OverviewIssueList menu={menu} />
        </Col>
      </Row>
    </>
  )
}

export default OverviewIssueIndex
