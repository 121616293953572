import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Col, Row } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { GET_CONTROL_FORM_PAGINATION, GET_CONTROL_RESULTS } from './graphql/Query'
import { resultListCrumb } from './utils/constants'
import { encryptInput } from '../../../utilitys/crypto'
import { handleSort } from '../../../utilitys/pagination'
import { displayText } from '../../../utilitys/helper'
import { dateTimeDisplay } from '../../../utilitys/dateHelper'
import { IconNew } from '../../../components/icon/Icon'
import { CardNew } from '../../../components/card/Card'
import { Status } from '../../../components/status/Status'
import { Datatable } from '../../../components/datatable/Datatable'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { MainTable, SubTable } from './css/Styles'
import ResultFilter from './components/filter/ResultFilter'
import ExportModal from './components/modal/ExportModal'

const ResultList = (props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [dataLists, setDataLists] = useState([])
  const [dataResult, setDataResult] = useState([])
  const [newData, setNewData] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState([])
  const [controlFormCode, setControlFormCode] = useState(null)
  const [visible, setVisible] = useState(false)
  const [dataExportModal, setDataExportModal] = useState(null)

  const [getControlFormsFn, getControlForms] = useLazyQuery(GET_CONTROL_FORM_PAGINATION)
  const [getControlResultsFn, getControlResults] = useLazyQuery(GET_CONTROL_RESULTS)

  useEffect(() => {
    getControlFormsFn({
      variables: {
        input: encryptInput({
          filters: { ...filters, checkControlResult: true },
          pagination: { limit, page },
          sort,
        }),
      },
    })
  }, [page, limit, filters, sort])

  useEffect(() => {
    if (controlFormCode && controlFormCode.length) {
      getControlResultsFn({ variables: { input: encryptInput({ controlFormCode: controlFormCode }) } })
    }
  }, [controlFormCode])

  useEffect(() => {
    if (getControlResults?.data) {
      setDataResult(
        _.map(getControlResults?.data?.getControlResults, (d) => ({
          key: d.controlResultId,
          controlFormCode: d.controlFormCode,
          succeedAt: dateTimeDisplay(d.succeedAt),
          organizationName: displayText(d.organizationNameTH, d.organizationNameEN),
          operationStatusCode: d.operationStatusCode,
          operationStatusNameTH: d?.operationStatusNameTH,
          operationStatusNameEN: d?.operationStatusNameEN,
          // responsibilty: d.makerBy
          //   ? displayText(`${d.p2_fullNameTH}`, `${d.p2_fullNameEN}`)
          //   : displayText(`${d.p1_fullNameTH}`, `${d.p1_fullNameEN}`),
          responsibilty: displayText(`${d.p1_fullNameTH}`, `${d.p1_fullNameEN}`),
        })),
      )
    }
  }, [getControlResults?.data])

  useEffect(() => {
    if (getControlForms?.data) {
      let response = getControlForms?.data?.getControlFormPagination

      setCount(response?.count)
      setDataLists(
        _.map(response?.result, (data) => {
          return {
            ...data,
            key: `${data.controlFormId}_${data.controlFormCode}`,
            controlFormName: displayText(
              `${data.controlFormCode}: ${data.controlFormTH}`,
              `${data.controlFormCode}: ${data.controlFormEN}`,
            ),
            operationStatusCode: data.operationStatusCode,
          }
        }),
      )
    }
  }, [getControlForms?.data])

  useEffect(() => {
    const controlResults = _.groupBy(dataResult, 'controlFormCode')
    setNewData(
      _.map(dataLists, (data) => ({
        ...data,
        description: (
          <SubTable>
            <Datatable
              columns={subColumns}
              data={controlResults[data.controlFormCode] ? controlResults[data.controlFormCode] : []}
            />
          </SubTable>
        ),
      })),
    )
  }, [dataLists, dataResult])

  const mainColumns = [
    { title: t('ชื่อแบบประเมิน'), dataIndex: 'controlFormName' },
    {
      title: t('สถานะ'),
      dataIndex: 'operationStatusCode',
      align: 'center',
      sorter: { multiple: 1 },
      width: '20%',
      render: (d, record) => (
        <Status text={displayText(record?.operationStatusNameTH, record?.operationStatusNameEN)} />
      ),
    },
    {
      title: '',
      dataIndex: 'operationStatusCode',
      width: '10%',
      align: 'right',
      render: (text, record) => {
        let list = [
          {
            type: 'view',
            onClick: () => {
              navigate('/internalcontrol/result/view', {
                state: {
                  activeType: 'views',
                  controlFormCode: _.last(record?.controlFormCode.split('_')),
                  controlResultId: null,
                },
              })
            },
          },
          {
            type: 'download',
            onClick: () => {
              setDataExportModal({ type: 'mainResult', controlFormCode: _.last(record?.controlFormCode.split('_')) })
              setVisible(true)
            },
          },
        ]
        return record?.operationStatusCode !== '10' ? <ButtonGroup menu={props.menu} size={18} icons={list} /> : <></>
      },
    },
  ]

  const subColumns = [
    { title: t('ผู้ประเมิน'), dataIndex: 'responsibilty' },
    { title: t('วันเวลาที่ประเมิน'), dataIndex: 'succeedAt' },
    { title: t('หน่วยรับตรวจ'), dataIndex: 'organizationName' },
    {
      title: t('สถานะ'),
      dataIndex: 'operationStatusCode',
      key: 'operationStatusCode',
      width: '15%',
      render: (d, record) => (
        <Status text={displayText(record?.operationStatusNameTH, record?.operationStatusNameEN)} />
      ),
    },
    {
      title: '',
      dataIndex: 'tools',
      render: (text, record) => {
        let list = [
          {
            type: 'view',
            onClick: () => {
              navigate('/internalcontrol/result/view', {
                state: {
                  activeType: 'view',
                  controlFormCode: record?.controlFormCode,
                  controlResultId: record?.key,
                },
              })
            },
          },
          {
            type: 'download',
            onClick: () => {
              setDataExportModal({ type: 'subResult', controlResultId: record?.key })
              setVisible(true)
            },
          },
        ]
        return <ButtonGroup menu={props.menu} size={18} icons={list} />
      },
    },
  ]

  const onFilter = (data) => {
    Promise.all([setPage(1), setLimit(10), setFilters(data)])
  }

  return (
    <>
      {(getControlResults?.loading || getControlForms?.loading) && <SpinnersNew />}
      <Col sm={24} md={24} lg={24}>
        <Breadcrumb data={resultListCrumb} title={t('ผลการประเมินควบคุมภายใน')} />
        <ResultFilter onFilter={onFilter} menu={props.menu} />
        <div style={{ marginBottom: '2rem' }}>
          <CardNew topic={t('รายการแบบประเมินควบคุมภายใน')} icon="List" toggledrop={'false'}>
            <Row gutter={[12, 12]}>
              <Col sm={24} md={24} lg={24} xl={24}>
                <MainTable>
                  <Datatable
                    columns={mainColumns}
                    data={[...newData]}
                    total={count}
                    onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
                    paginationCustom={true}
                    pageSize={limit}
                    page={page}
                    searchCustom={true}
                    handleTableChange={(e) => setSort(handleSort(e?.sorter))}
                    expandable={{
                      expandedRowRender: (record) => record.description,
                      onExpandedRowsChange: (e) => setControlFormCode(_.map(e, (d) => _.last(d.split('_')))),
                      expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                          <IconNew icon={'ChevronUp'} onClick={(e) => onExpand(record, e)} />
                        ) : (
                          <IconNew icon={'ChevronDown'} onClick={(e) => onExpand(record, e)} />
                        ),
                    }}
                  />
                </MainTable>
              </Col>
            </Row>
          </CardNew>
          <ExportModal
            open={visible}
            dataExportModal={dataExportModal}
            close={(e) => setVisible(e)}
            menu={props.menu}
          />
        </div>
      </Col>
    </>
  )
}

ResultList.propTypes = {
  menu: PropTypes.string.isRequired,
}

export default ResultList
