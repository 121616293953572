import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CardNew } from '../../../../components/card/Card'
import { BreadcrumbNew } from '../../../../components/breadcrumb/Breadcrumb'
import OverviewFollowUpFilter from './OverviewFollowUpFilter'
import OverviewFollowUpGraphPie from './OverviewFollowUpGraphPie'
import OverviewFollowUpAmount from './OverviewFollowUpAmount'
import OverviewFollowUpProject from './OverviewFollowUpProject'
import OverviewFollowUpConsult from './OverviewFollowUpConsult'
import { ButtonNew } from '../../../../components/button/Button'
import { GET_EXPORT_OVERVIEW_FOLLOWUP_CONSULT, GET_EXPORT_OVERVIEW_FOLLOWUP_PROJECT } from './graphql/Query'
import { useLazyQuery } from '@apollo/client'
import { Alert } from '../../../../components/alert/Alert'
import { displayError, displayText } from '../../../../utilitys/helper'
import { encryptInput } from '../../../../utilitys/crypto'
import { SpinnersNew } from '../../../../components/spinners/Spinners'

// import ExportModalListProject from './components/modals/ExportModalListProject'
// import ExportModalListConsult from './components/modals/ExportModalListConsult'

const arrCrumb = [{ label: 'ภาพรวมการติดตาม' }, { label: 'แดชบอร์ด', path: '/dashboard' }, { label: 'ภาพรวมการติดตาม' }]

const OverviewFollowUpIndex = (props) => {
  const { menu } = props
  const { t } = useTranslation()

  const [dataFilter, setdataFilter] = useState({
    dateStart: null,
    dateEnd: null,
    organizationCode: null,
    auditTeamCode: null,
    jobStatusCode: null,
    stepTypeCode: null
  })
  const [dataFilterProject, setdataFilterProject] = useState({
    dateStart: null,
    dateEnd: null,
    organizationCode: null,
    auditTeamCode: null,
    jobStatusCode: null,
    stepTypeCode: null,
    projectCode: null,
    dashboardType: 1,
    graph: null
  })

  const [isTableConsult, setisTableConsult] = useState(false)

  const onGetValue = (data) => {
    setdataFilter(data)
    setdataFilterProject(data)
  }

  const onGetProjectCode = (code, graph, type) => {
    if (graph === 3) {
      setisTableConsult(true)
    } else {
      setisTableConsult(false)
    }

    setdataFilterProject({
      dateStart: null,
      dateEnd: null,
      organizationCode: null,
      auditTeamCode: null,
      jobStatusCode: null,
      stepTypeCode: null,
      projectCode: code,
      dashboardType: type,
      graph: graph
    })
  }

  // ============== Consult Excel API ==============

  const [callExportConsult, { data: dataExportConsult, loading: loadingExportConsult, error: errorExportConsult }] =
    useLazyQuery(GET_EXPORT_OVERVIEW_FOLLOWUP_CONSULT)

  useEffect(() => {
    if (dataExportConsult) {
      const { getExportOverviewFollowUpConsult } = dataExportConsult
      if (getExportOverviewFollowUpConsult?.message === 'succcess') {
        window.open(`${getExportOverviewFollowUpConsult?.fileUrl}`, '_blank')
      } else {
        Alert({
          type: 'error',
          title: t('ไม่มีข้อมูล'),
          onOk() {}
        })
      }
    }
    if (errorExportConsult) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorExportConsult)),
        onOk() {}
      })
    }
  }, [dataExportConsult])

  // ============== Project Excel API ==============

  const [callExportProject, { data: dataExportProject, loading: loadingExportProject, error: errorExportProject }] =
    useLazyQuery(GET_EXPORT_OVERVIEW_FOLLOWUP_PROJECT)

  useEffect(() => {
    if (dataExportProject) {
      const { getExportOverviewFollowUpProject } = dataExportProject
      if (getExportOverviewFollowUpProject?.message === 'succcess') {
        window.open(`${getExportOverviewFollowUpProject?.fileUrl}`, '_blank')
      } else {
        Alert({
          type: 'error',
          title: t('ไม่มีข้อมูล'),
          onOk() {}
        })
      }
    }
    if (errorExportProject) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorExportProject)),
        onOk() {}
      })
    }
  }, [dataExportProject])

  // ============== Call Excel Function ==============
  const CallExcelFunc = () => {
    const dataInput = {
      filters: dataFilterProject,
      lang: displayText('th', 'en'),
      waterMark: null,
      fileType: 'excel'
    }

    if (isTableConsult) {
      callExportConsult({
        variables: {
          input: encryptInput(dataInput)
        }
      })
    } else {
      callExportProject({
        variables: {
          input: encryptInput(dataInput)
        }
      })
    }
  }

  return (
    <React.Fragment>
      <BreadcrumbNew data={arrCrumb} title={'ภาพรวมการติดตาม'} />
      {(loadingExportProject || loadingExportConsult) && <SpinnersNew />}
      {/* ============== Filter ============== */}
      <CardNew
        topic={t('ภาพรวมการติดตาม')}
        toggledrop={'false'}
        buttontopright="true"
        customRight={
          <ButtonNew type="export" roles={{ type: 'export', menu: menu }} onClick={() => CallExcelFunc()}>
            {t('ส่งออก')}
          </ButtonNew>
        }
      >
        <OverviewFollowUpFilter menu={menu} getValue={onGetValue} />
      </CardNew>
      <OverviewFollowUpGraphPie
        dataFilter={dataFilter}
        getProjectCode={(code, graph) => onGetProjectCode(code, graph, 1)}
      />
      <OverviewFollowUpAmount dataFilter={dataFilter} getProjectCode={(code) => onGetProjectCode(code, 4, 2)} />
      {isTableConsult && <OverviewFollowUpConsult dataFilter={dataFilterProject} />}
      {!isTableConsult && <OverviewFollowUpProject dataFilter={dataFilterProject} />}
    </React.Fragment>
  )
}

export default OverviewFollowUpIndex
