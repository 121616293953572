import React from 'react'
import { Checkbox as CheckboxAnt } from 'antd'
import { CheckboxStyle } from './CheckboxStyles'

export const Checkbox = ({ children, ...otherProps }) => {
  return (
    <React.Fragment>
      <CheckboxStyle>
        <CheckboxAnt {...otherProps}>{children}</CheckboxAnt>
      </CheckboxStyle>
    </React.Fragment>
  )
}

export const CheckboxGroup = ({ children, ...otherProps }) => {
  return (
    <React.Fragment>
      <CheckboxStyle>
        <CheckboxAnt.Group {...otherProps}>{children}</CheckboxAnt.Group>
      </CheckboxStyle>
    </React.Fragment>
  )
}
