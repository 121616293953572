import React, { useState } from 'react'
import { Card } from 'antd'
import { CardStyle } from './CardStyle'
import { IconNew } from '../../components/icon/Icon'
import { ButtonGroup } from '../../components/button/ButtonGroup'

export const CardNew = (props) => {
  let dropIcon
  const [show, setShow] = useState(true)
  if (props.toggledrop === 'true') {
    dropIcon = (
      <a className="card-toggle-link" onClick={() => setShow(!show)}>
        {show ? <IconNew icon={'ChevronUp'} /> : <IconNew icon={'ChevronDown'} />}
      </a>
    )
  } else {
    dropIcon = (
      <ButtonGroup
        menu={props.menu}
        icons={[
          {
            type: 'edit',
            onClick: () => props.editfnc(),
          },
          {
            type: 'delete',
            onClick: () => props.deletefnc(),
          },
        ]}
      />
    )
  }

  return (
    <>
      <CardStyle>
        <Card
          // {...props}
          title={
            <>
              {props.icon ? (
                <span className="card-title-icon">
                  <IconNew icon={props.icon} />
                </span>
              ) : (
                ''
              )}
              <span className="card-title-text" style={props.topiccolor ? { color: props.topiccolor } : null}>
                {props.topic}
              </span>
            </>
          }
          extra={props.buttontopright ? (props.customRight ? props.customRight : dropIcon) : ''}
          bordered={true}
          className={`CardNew ${show ? 'show' : 'hide'}`}
        >
          {props.children}
        </Card>
      </CardStyle>
    </>
  )
}
