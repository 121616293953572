import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { MainTable } from '../../../auditopens/css/Styles'
import { Datatable } from '../../../../../components/datatable/Datatable'
import { ButtonGroup } from '../../../../../components/button/ButtonGroup'
import { ButtonNew as Button } from '../../../../../components/button/Button'
import { LabelNew as Label } from '../../../../../components/label/Label'
import { Row, Col } from 'antd'
import { Alert } from '../../../../../components/alert/Alert'
import AcResolutionListDownloadModal from '../modal/AcResolutionListDownloadModal'
import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_ALL_AUDIT_RESOLUTION_AC_BY_RES_ID } from '../../graphql/Query'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayText, displayError } from '../../../../../utilitys/helper'
import { useDecodeUser } from '../../../../../hooks/useDecodeUser'
import { SpinnersNew as Spinner } from '../../../../../components/spinners/Spinners'
import { DELETE_AUDIT_RESOLUTION_AC } from '../../graphql/Mutation'
import { handleSort } from '../../../../../utilitys/pagination'

const AcResolutionDetailDataTable = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [tableData, setTableData] = useState([])
  const [count, setCount] = useState(0)

  const [user] = useDecodeUser()

  const [paginate, setPaginate] = useState({ page: 1, limit: 10 })
  const [agendaIdForFileExport, setAgendaIdForFileExport] = useState(0)
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)

  const [getAllAuditResolutionACByResId, getAllAuditResolutionACByResIdResult] = useLazyQuery(
    GET_ALL_AUDIT_RESOLUTION_AC_BY_RES_ID,
  )
  const [deleteAuditResolutionAC, deleteAuditResolutionACResult] = useMutation(DELETE_AUDIT_RESOLUTION_AC)

  const [sort, setSort] = useState([{ fieldSort: 'resId', sortType: 'ASC' }])

  useEffect(() => {
    if (sort && paginate.page && paginate.limit) {
      const input = encryptInput({
        filters: { resId: props.resId },
        sort: sort,
        pagination: paginate,
      })
      getAllAuditResolutionACByResId({
        variables: {
          input: input,
        },
      })
    }
  }, [sort, paginate.page, paginate.limit])

  useEffect(() => {
    if (getAllAuditResolutionACByResIdResult.data) {
      const { getAllAuditResolutionACByResId } = getAllAuditResolutionACByResIdResult.data
      const { result, count } = getAllAuditResolutionACByResId

      const newResult = result?.map((item, index) => {
        return {
          agenda: item.agendaTime,
          subAgenda: item.agendaTimeSub,
          workType: item.jobType === 1 ? 'งานโครงการ' : 'งานอื่นๆ',
          workTypeCode: item.jobType,
          subAgendaName: displayText(item.agendaSubNameTH, item.agendaSubNameEN),
          agendaId: item.agendaId,
          key: index + 1,
        }
      })
      setTableData(newResult)
      setCount(count)
    }
  }, [getAllAuditResolutionACByResIdResult.data])

  useEffect(() => {
    if (getAllAuditResolutionACByResIdResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถดึงข้อมูลได้'),
        content: t(displayError(getAllAuditResolutionACByResIdResult.error)),
        onOk() {},
      })
    }
  }, [getAllAuditResolutionACByResIdResult.error])

  useEffect(() => {
    if (deleteAuditResolutionACResult.error) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถลบข้อมูลได้'),
        content: t(displayError(deleteAuditResolutionACResult.error)),
        onOk() {},
      })
    }
  }, [deleteAuditResolutionACResult.error])

  const columns = [
    {
      title: <div style={{ textAlign: 'center' }}>{t('วาระที่')}</div>,
      dataIndex: 'agenda',
      width: '10%',
      align: 'center',
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('วาระย่อย')}</div>,
      dataIndex: 'subAgenda',
      width: '10%',
      align: 'center',
      sorter: true,
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('ประเภทงาน')}</div>,
      dataIndex: 'workType',
      width: '20%',
      align: 'center',
    },
    {
      title: <div style={{ textAlign: 'center' }}>{t('ชื่อวาระย่อย')}</div>,
      dataIndex: 'subAgendaName',
      width: '45%',
      align: 'left',
    },
    {
      title: '',
      // dataIndex: 'action',
      width: '15%',
      render: (record) => {
        let list = [
          {
            type: 'download',
            onClick: () => {
              setAgendaIdForFileExport(record.agendaId)
              setIsExportModalOpen(true)
            },
          },
          {
            type: 'view',
            onClick: () =>
              navigate('agenda', {
                state: {
                  detailPageAction: props.detailPageAction,
                  agendaPageAction: 'view',
                  resId: props.resId,
                  agendaId: record.agendaId,
                  acResolutionDetail: props.acResolutionDetail,
                },
              }),
          },
          ...(props.isDisabled
            ? ''
            : [
                {
                  type: 'edit',
                  onClick: () =>
                    navigate('agenda', {
                      state: {
                        detailPageAction: props.detailPageAction,
                        agendaPageAction: 'edit',
                        resId: props.resId,
                        agendaId: record.agendaId,
                        acResolutionDetail: props.acResolutionDetail,
                      },
                    }),
                },
                {
                  type: 'delete',
                  onClick: () => {
                    Alert({
                      type: 'confirm',
                      title: t('ต้องการยืนยันการลบ?'),
                      onOk() {
                        deleteAuditResolutionAC({
                          variables: {
                            agendaId: encryptInput(record?.agendaId),
                            userId: encryptInput(user.userId),
                          },
                        })
                          .then((response) => {
                            if (response.data?.deleteAuditResolutionAC === true) {
                              getAllAuditResolutionACByResIdResult.refetch()
                              Alert({
                                type: 'success',
                                title: t('ลบข้อมูลสำเร็จ'),
                                onOk() {},
                              })
                            } else if (response.data?.deleteAuditResolutionAC === false) {
                              if (record.workTypeCode === 1) {
                                Alert({
                                  type: 'error',
                                  title: t('ไม่สามารถลบได้เนื่องจากข้อมูลถูกนำไปใช้แล้ว'),
                                  content: t(''),
                                  onOk() {},
                                })
                              } else {
                                Alert({
                                  type: 'error',
                                  title: t('ไม่สามารถลบข้อมูลได้'),
                                  content: t(''),
                                  onOk() {},
                                })
                              }
                            }
                          })
                          .catch((error) =>
                            Alert({
                              type: 'error',
                              title: t('ไม่สามารถลบข้อมูลได้'),
                              content: t(error),
                              onOk() {},
                            }),
                          )
                      },
                      onCancel() {},
                    })
                  },
                },
              ]),
        ]
        return <ButtonGroup menu={props.menu} size={18} icons={list} />
      },
    },
  ]

  return (
    <>
      {deleteAuditResolutionACResult.loading && <Spinner />}
      <Row>
        <Col style={{ marginTop: 15, marginBottom: 15 }}>
          <Label type="body-header">{t('รายการวาระการประชุม')}</Label>
        </Col>
        <Col>
          <MainTable>
            <Datatable
              columns={columns}
              data={tableData}
              total={count}
              searchCustom={true}
              pageSize={paginate.limit}
              page={paginate.page}
              isLoading={getAllAuditResolutionACByResIdResult.loading}
              paginationCustom={true}
              scroll={{ x: 1500 }}
              onChangePagination={({ page, pageSize }) => {
                setPaginate({ page, limit: pageSize })
              }}
              handleTableChange={(e) => {
                const reNewField = [{ agenda: 'agendaTime' }, { subAgenda: 'agendaTimeSub' }]

                setSort(handleSort(e?.sorter, reNewField))
              }}
              {...(props.isDisabled
                ? ''
                : {
                    btnAdd: (
                      <>
                        <Button
                          type="plusTableBorderPrimary"
                          roles={{ type: 'add', menu: props.menu }}
                          style={{ marginRight: '12px' }}
                          onClick={() => {
                            navigate('agenda', {
                              state: {
                                detailPageAction: props.detailPageAction,
                                agendaPageAction: 'add',
                                resId: props.resId,
                                acResolutionDetail: props.acResolutionDetail,
                              },
                            })
                          }}
                        >
                          {t('เพิ่มวาระการประชุม')}
                        </Button>
                      </>
                    ),
                  })}
            />
          </MainTable>
        </Col>
      </Row>
      <AcResolutionListDownloadModal
        open={isExportModalOpen}
        setModalOpen={setIsExportModalOpen}
        agendaId={agendaIdForFileExport}
      />
    </>
  )
}

export default AcResolutionDetailDataTable
