import { gql } from '@apollo/client'

export const GET_LIST_AUDIT_DOCUMENT_PAGINATION = gql`
  query getListAuditDocumentPagination($input: AuditDocumentPaginationInput!) {
    getListAuditDocumentPagination(input: $input) {
      result {
        no
        projectCode
        projectNameTH
        projectNameEN
        projectRefCode
        projectRefNameTH
        projectRefNameEN
        dateStart
        dateEnd
        organizationCode
        organizationNameTH
        organizationNameEN
        personnelCode
        personnelNameTH
        personnelNameEN
        isActive
      }
      count
      page
      limit
    }
  }
`
export const GET_LIST_AUDIT_DOCUMENT_FILES_PAGINATION = gql`
  query getListAuditDocumentFilesPagination($input: AuditDocumentPaginationInput!) {
    getListAuditDocumentFilesPagination(input: $input) {
      result {
        no
        projectCode
        fileId
        fileDetail
        fileNameLabel
        fileNameGen
        fileType
        fileByte
        filepath
        createdAt
      }
      count
      page
      limit
    }
  }
`
