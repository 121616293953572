import { gql } from '@apollo/client'
export const MENUS = gql`
  query GetMenus {
    getMenus(parentCode: "000") {
      menuCode
      menuNameTH
      menuNameEN
      menuPath
      icon
      menuParentCode
      notification
      children {
        menuCode
        menuNameTH
        menuNameEN
        menuPath
        icon
        menuParentCode
        notification
      }
    }
  }
`
export const USER_ROLES = gql`
  query GetUserRoles {
    getUserRoles {
      menuCode
      view
      add
      edit
      delete
      send
      export
      duplicate
      approve
    }
  }
`

export const GET_LAST_SOFTWARE_VERSION = gql`
  query getLastSoftwareVersion {
    getLastSoftwareVersion {
      version
      date
      detail
    }
  }
`

export const GET_SYSTEM_CONFIGURATIONS = gql`
  query GetSystemConfigurations {
    getSystemConfigurations {
      sessionExpire
    }
  }
`
