import React, { useState, useEffect } from 'react'
import { Row, Col, Space } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { Datatable } from '../../../components/datatable/Datatable'
import { CardNew } from '../../../components/card/Card'
import { BreadcrumbNew as Breadcrumb } from '../../../components/breadcrumb/Breadcrumb'
import { ButtonNew } from '../../../components/button/Button'
import { encryptInput, decryptData } from '../../../utilitys/crypto'
import { dateDisplay } from '../../../utilitys/dateHelper'
import { Status } from '../../../components/status/Status'
import { ButtonGroup } from '../../../components/button/ButtonGroup'
import { Alert } from '../../../components/alert/Alert'
import { displayText, displayError } from '../../../utilitys/helper'
import { SpinnersNew } from '../../../components/spinners/Spinners'
import ExportModal from './Modal/ExportModal'
import { GET_CONFLICT_OF_INTEREST_LIST } from './graphql/Query'
import { UPDATE_CONFLICT_OF_INTEREST } from './graphql/Mutation'

const Responsive = { sm: 24, md: 24, lg: 24, xl: 24 }

const ConflictOfInterestList = (props) => {
  const navigate = useNavigate()
  const menu = props.menu
  const { t } = useTranslation()
  const lang = localStorage.getItem('lang')
  const { pCode } = decryptData(localStorage.getItem('user'))
  const [dataLists, setDataLists] = useState([])
  const [sort] = useState([{ fieldSort: 'createdAt', sortType: 'DESC' }])
  const [dataSelecteds, setDataSelecteds] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState(0)
  const [reFetch, setReFetch] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [actionPlanId, setActionPlanId] = useState()

  const [
    updateConflictOfInterestFn,
    {
      loading: updateConflictOfInterestLoading,
      data: updateConflictOfInterestData,
      error: updateConflictOfInterestError,
    },
  ] = useMutation(UPDATE_CONFLICT_OF_INTEREST)

  const [getConflictOfInterestListFn, getConflictOfInterestList] = useLazyQuery(GET_CONFLICT_OF_INTEREST_LIST)

  useEffect(() => {
    getConflictOfInterestListFn({
      variables: {
        input: {
          filters: encryptInput({ lang: lang, personnelCode: pCode }),
          pagination: { limit, page },
          sort: sort,
        },
      },
    })
  }, [page, limit, reFetch])

  useEffect(() => {
    if (updateConflictOfInterestData) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          setReFetch(reFetch ? false : true)
          location.reload()
        },
      })
    }
    if (updateConflictOfInterestError) {
      const err = updateConflictOfInterestError
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [updateConflictOfInterestData])

  useEffect(() => {
    if (getConflictOfInterestList.data) {
      const res = getConflictOfInterestList?.data?.getConflictOfInterestList
      const count_ = res?.count
      const d_ = res?.result
      const d = d_.map((item, idx) => {
        return {
          key: idx + 1,
          projectCode: item.projectCode,
          projectName: displayText(item.projectNameTH, item.projectNameEN),
          projectRefName: displayText(item.projectRefNameTH, item.projectRefNameEN),
          dateStart: dateDisplay(item.dateStart),
          dateEnd: dateDisplay(item.dateEnd),
          auditTeamName: displayText(item.auditTeamNameTH, item.auditTeamNameEN),
          conflict: item.conflict,
          tools: item,
        }
      })
      setCount(count_)
      setDataLists([...d])
    }
    if (getConflictOfInterestList.error) {
      const err = getConflictOfInterestList.error
      Alert({
        type: 'error',
        title: t(displayError(err)),
        onOk() {},
      })
    }
  }, [getConflictOfInterestList.data])

  const columns = [
    { title: t('เลือก'), dataIndex: 'projectCode', fixed: 'left' },
    { title: t('โครงการ'), dataIndex: 'projectName', width: '40%' },
    { title: t('แหล่งที่มาของโครงการ'), dataIndex: 'projectRefName', width: '15%' },
    { title: t('วันที่เริ่มต้น'), dataIndex: 'dateStart', width: '10%' },
    { title: t('วันที่สิ้นสุด'), dataIndex: 'dateEnd', width: '10%' },
    { title: t('ทีมรับผิดชอบ'), dataIndex: 'auditTeamName', width: '10%' },
    {
      title: t('สถานะยืนยัน'),
      dataIndex: 'conflict',
      width: '10%',
      fixed: 'right',
      render: (_) => <Status text={_ < 1 ? t('ยืนยันแล้ว') : t('ยังไม่ยืนยัน')} />,
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '10%',
      fixed: 'right',
      render: (_) => {
        const btn = [
          {
            type: 'view',
            onClick: () => {
              navigate('/auditoperations/actionplan/form', {
                state: {
                  refCode: _.actionPlanId,
                  projectCode: _.projectCode,
                  type_action: 'view',
                  backPath: '/auditoperations/conflictofinterest',
                },
              })
            },
          },
          {
            type: 'download',
            onClick: () => {
              setActionPlanId(_.actionPlanId)
              setModalVisible(true)
            },
          },
        ]
        return (
          <>
            <Row justify="end">
              <Col>
                <ButtonGroup menu={menu} icons={btn} />
              </Col>
            </Row>
          </>
        )
      },
    },
  ]

  const updateConflictOfInterest = () => {
    const _arrayProjectCode = dataSelecteds.map((item) => item.projectCode)
    Alert({
      type: 'confirm',
      title: t('คุณไม่ได้มีส่วนเกี่ยวข้องหรือผลประโยชน์จากการตรวจสอบครั้งนี้'),
      onOk: async () => {
        await updateConflictOfInterestFn({
          variables: {
            input: encryptInput({
              projectCode: _arrayProjectCode,
              personnelCode: pCode,
            }),
          },
        })
      },
      onCancel() {},
    })
  }

  const onSelectChange = (key, dataSelecteds) => setDataSelecteds([...dataSelecteds])

  const rowSelection = {
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({ disabled: record.conflict < 1 }),
  }

  const breadcrumbList = [
    { label: t('งานปฏิบัติการตรวจสอบ'), path: '/auditoperations/' },
    { label: t('การเปิดเผยความขัดแย้งทางผลประโยชน์') },
  ]

  return (
    <>
      {(updateConflictOfInterestLoading || getConflictOfInterestList.loading) && <SpinnersNew />}
      <Breadcrumb data={breadcrumbList} title={t('การเปิดเผยความขัดแย้งทางผลประโยชน์')} />
      <CardNew topic={t('รายการโครงการตรวจสอบ')} icon="List" toggledrop={'false'}>
        <Row gutter={[12, 12]}>
          <Col {...Responsive}>
            <Datatable
              columns={columns}
              data={[...dataLists]}
              paginationCustom={true}
              onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
              searchCustom={true}
              total={count}
              enableScroll={{}}
              pageSize={limit}
              page={page}
              handleTableChange={() => {}}
              rowSelection={{
                type: 'checkbox',
                ...rowSelection,
              }}
              //   handleTableChange={(e) => {
              //     const reNewField = [
              //       { riskFormName: displayText('riskFormTH', 'riskFormEN') },
              //       { Year: 'Year' },
              //       { riskFormCode: 'riskFormCode' },
              //     ]
              //     setSort(handleSort(e?.sorter, reNewField))
              //   }}
            />
          </Col>
          <Col {...Responsive} align="center">
            <Space>
              <ButtonNew
                type="primary"
                roles={{ type: 'edit', menu: menu }}
                onClick={() => updateConflictOfInterest()}
                htmlType="submit"
                disabled={dataSelecteds.length < 1 ? true : false}
              >
                {t('ยืนยัน')}
              </ButtonNew>
              &nbsp;
              <ButtonNew type="back" onClick={() => navigate('/auditoperations')}>
                {t('ยกเลิก')}
              </ButtonNew>
            </Space>
          </Col>
        </Row>
      </CardNew>
      <ExportModal
        actionPlanId={actionPlanId}
        visible={modalVisible}
        onModalClose={() => {
          setModalVisible(false)
        }}
      />
    </>
  )
}

export default ConflictOfInterestList
