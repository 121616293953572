import { gql } from '@apollo/client'
export const GET_SELECT_RISK_MODEL = gql`
  query GetSelectRiskModel($input: SelectRiskModelInput!) {
    getSelectRiskModel(input: $input) {
      riskModelCode
      riskModelTH
      riskModelEN
    }
  }
`
