import { Trans } from 'react-i18next'

export const Columns = [
  {
    title: <Trans>ลำดับ</Trans>,
    dataIndex: 'key',
    width: '8%',
    className: 'text-nowrap',
    align: 'center',
  },
  {
    title: <Trans>รหัส</Trans>,
    dataIndex: 'causeCode',
    width: '10%',
    className: 'text-nowrap',
    sorter: { multiple: 1 },
  },
  {
    title: <Trans>สาเหตุภาษาไทย</Trans>,
    dataIndex: 'causeNameTH',
    width: '25%',
    sorter: { multiple: 2 },
  },
  {
    title: <Trans>สาเหตุภาษาอังกฤษ</Trans>,
    dataIndex: 'causeNameEN',
    width: '25%',
    sorter: { multiple: 3 },
  },
  {
    title: <Trans>สถานะ</Trans>,
    dataIndex: 'isActive',
    width: '10%',
    className: 'text-nowrap',
    sorter: { multiple: 4 },
  },
  {
    title: '',
    dataIndex: 'tools',
    width: '10%',
    align: 'right',
  },
]
