import React from 'react'
import { Route, Routes } from 'react-router-dom'
import PermissionIndex from './permissions/PermissionsIndex'
import PersonnelFormIndex from './personnel/PersonnelFormIndex'
import PersonnelIndex from './personnel/PersonnelIndex'
import PermissionsForm from './permissions/PermissionsForm'
import UsersIndext from './users/UsersIndex'
import UsersForm from './users/UsersForm'
import AuditTeamIndex from './auditteam/AuditTeamIndex'
import RiskFactorList from './riskfactors/RiskFactorsList'
import AuditTeamForm from './auditteam/AuditTeamForm'
import AuditPersonnelList from './auditteam/AuditPersonnelList'
import SubRiskFactorList from './subriskfactors/SubRiskFactorList'
import SubRiskFactorForm from './subriskfactors/SubRiskFactorForm'
import CauseOfIssueIndex from './causeofissue/CauseOfIssueIndex'
import ControlNatureIndex from './controlnature/ControlNatureIndex'
import ControlTypeIndex from './controltype/ControlTypeIndex'
import RiskProcessList from './riskprocess/RiskProcessList'
import RiskProcessForm from './riskprocess/RiskProcessForm'
import TemplateRiskIndex from './templaterisk/TemplateRiskIndex'
import RiskLevelIndex from './risklevel/RiskLevelIndex'
import TemplateRiskForm from './templaterisk/TemplateRiskForm'
import OrganizationIndex from './organization/OrganizationIndex'
import CalendaSetIndex from './calendarset/CalendarSetIndex.jsx'
import OperationSetupList from './operationsetup/OperationSetupList'
import OperationSetupForm from './operationsetup/OperationSetupForm'
import CalendarSetForm from './calendarset/CalendarSetForm'
import CalendarSetImport from './calendarset/CalendarSetImport'
import SetupRunningNumberIndex from './setuprunningnumber/SetupRunningNumberIndex'
import SetupRunningNumberForm from './setuprunningnumber/SetupRunningNumberForm'
import OrganizationEdit from './organization/OrganizationEdit'
import ProjectSizeSetupList from './projectsizesetup/ProjectSizeSetupList'
import EmalSendSetupIndex from './emalsendsetup/EmalSendSetupIndex'
import AssessmentFormIndex from './assessmentform/AssessmentFormIndex.jsx'
import AssessmentFormDetails from './assessmentform/AssessmentFormDetails'
import RiskFormulaIndex from './riskformula/RiskFormulaIndex'
import RiskFormulaCreate from './riskformula/RiskFormulaCreate'
import RiskFormulaEdit from './riskformula/RiskFormulaEdit'
import SoftwareVersion from './softwareversion/SoftwareVersionIndex'
// import TabOne from './emalsendsetup/tab/TabOne'
// import TabTwo from './emalsendsetup/tab/TabTwo'

/**
 * @function Router
 * @description
 * Module: Settings
 * โมดูล: จัดการข้อมูลพื้นฐาน
 * @returns Routes
 */
function Router() {
  return (
    <Routes>
      <Route path="/softwareversion" element={<SoftwareVersion />} />
      <Route path="/permissions">
        <Route path="" element={<PermissionIndex menu={'AT01'} />} />
        <Route path="form" element={<PermissionsForm menu={'AT01'} />} />
      </Route>
      <Route path="/users">
        <Route path="" element={<UsersIndext menu={'AT02'} />} />
        <Route path="form" element={<UsersForm menu={'AT02'} />} />
      </Route>
      <Route path="/personnel">
        <Route path="" element={<PersonnelIndex menu={'AT03'} />} />
        <Route path="information" element={<PersonnelFormIndex menu={'AT03'} />} />
        <Route path="education" element={<PersonnelFormIndex menu={'AT03'} />} />
        <Route path="training" element={<PersonnelFormIndex menu={'AT03'} />} />
        <Route path="reward" element={<PersonnelFormIndex menu={'AT03'} />} />
        <Route path="certificate" element={<PersonnelFormIndex menu={'AT03'} />} />
        <Route path="experience" element={<PersonnelFormIndex menu={'AT03'} />} />
      </Route>
      <Route path="/manageauditteam">
        <Route path="" element={<AuditTeamIndex menu={'AT04'} />} />
        <Route path="detail" element={<AuditTeamForm menu={'AT04'} />} />
        <Route path="personnel" element={<AuditPersonnelList menu={'AT04'} />} />
      </Route>
      <Route path="/riskformula">
        <Route path="" element={<RiskFormulaIndex menu={'AT08'} />} />
        <Route path="create" element={<RiskFormulaCreate menu={'AT08'} />} />
        <Route path="edit" element={<RiskFormulaEdit menu={'AT08'} />} />
        <Route path="view" element={<RiskFormulaEdit menu={'AT08'} />} />
      </Route>
      <Route path="/riskfactors" element={<RiskFactorList menu={'AT09'} />} />
      <Route path="/subriskfactors">
        <Route path="" element={<SubRiskFactorList menu={'AT10'} />} />
        <Route path="form" element={<SubRiskFactorForm menu={'AT10'} />} />
      </Route>
      <Route path="/riskprocess">
        <Route path="" element={<RiskProcessList menu={'AT11'} />} />
        <Route path="form" element={<RiskProcessForm menu={'AT11'} />} />
      </Route>
      <Route path="/causeofissue" element={<CauseOfIssueIndex menu={'AT05'} />} />
      <Route path="/controlType" element={<ControlTypeIndex menu={'AT06'} />} />
      <Route path="/controlnature" element={<ControlNatureIndex menu={'AT07'} />} />

      <Route path="/templaterskprocess">
        <Route path="" element={<TemplateRiskIndex menu={'AT13'} />} />
        <Route path="form">
          <Route path="" element={<TemplateRiskForm menu={'AT13'} />}>
            <Route path="*" element={<TemplateRiskForm menu={'AT13'} />} />
          </Route>
          {/* <Route path="*" element={<AuditProjectNotPlanDetail />} /> */}
        </Route>
      </Route>

      <Route path="/risklevel" element={<RiskLevelIndex menu={'AT16'} />} />
      <Route path="/organization">
        <Route path="" element={<OrganizationIndex menu={'AT12'} />} />
        <Route path="edit" element={<OrganizationEdit menu={'AT12'} />} />
        <Route path="view" element={<OrganizationEdit menu={'AT12'} />} />
      </Route>

      <Route path="/managecalendar">
        <Route path="" element={<CalendaSetIndex menu={'AT14'} />} />
        <Route path="form">
          <Route path="" element={<CalendarSetForm menu={'AT14'} />}>
            <Route path="*" element={<CalendarSetForm menu={'AT14'} />} />
          </Route>
        </Route>
        <Route path="import" element={<CalendarSetImport menu={'AT14'} />} />
      </Route>
      <Route path="/operationsetup">
        <Route path="" element={<OperationSetupList menu={'AT15'} />} />
        <Route path="form" element={<OperationSetupForm menu={'AT15'} />} />
      </Route>

      <Route path="/emalsendsetup">
        <Route path="" element={<EmalSendSetupIndex menu={'AT17'} />}>
          <Route path="*" element={<EmalSendSetupIndex menu={'AT17'} />} />
        </Route>
      </Route>

      <Route path="/setuprunningnumber">
        <Route path="" element={<SetupRunningNumberIndex menu={'AT20'} />} />
        <Route path="form" element={<SetupRunningNumberForm menu={'AT20'} />} />
      </Route>
      <Route path="/projectsizesetup" element={<ProjectSizeSetupList menu={'AT19'} />} />

      <Route path="/assessmentsetup">
        <Route path="" element={<AssessmentFormIndex menu={'AT18'} />} />
        <Route path="assessmentformdetail">
          <Route path="" element={<AssessmentFormDetails menu={'AT18'} />}>
            <Route path="*" element={<AssessmentFormDetails menu={'AT18'} />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}
export default Router
