import { LabelNew } from '../../../../../components/label/Label'
import { Trans } from 'react-i18next'

export const Columns = () => {
  return [
    {
      title: <Trans>ชื่องาน</Trans>,
      dataIndex: 'projectName',
      key: 'projectName',
      align: 'left',
      width: '45%',
      render: (item) => {
        return <LabelNew type="body">{item || 0}</LabelNew>
      },
    },
    {
      title: <Trans>ข้อเท็จจริง/ประเด็น</Trans>,
      dataIndex: 'countIssue',
      key: 'countIssue',
      className: 'text-nowrap',
      width: '15%',
      align: 'center',
      render: (item) => {
        return <LabelNew type="body">{item || 0}</LabelNew>
      },
    },
    {
      title: <Trans>ข้อเสนอแนะ</Trans>,
      dataIndex: 'countSuggest',
      key: 'countSuggest',
      className: 'text-nowrap',
      align: 'center',
      width: '15%',
      render: (item) => {
        return <LabelNew type="body">{item || 0}</LabelNew>
      },
    },
    {
      // actionStatusCode = '20'
      title: <Trans>กิจกรรมที่ต้องดำเนินการ</Trans>,
      dataIndex: 'count20',
      key: 'count20',
      className: 'text-nowrap',
      align: 'center',
      width: '15%',
      render: (item) => {
        return (
          <LabelNew type="body" title={'จำนวนรวมของสถานะ กำลังดำเนินการ(20)'}>
            {item || 0}
          </LabelNew>
        )
      },
    },
    {
      // actionStatusCode = '10'
      title: <Trans>กำหนดแผนดำเนินการ</Trans>,
      dataIndex: 'count10',
      key: 'count10',
      align: 'center',
      className: 'text-nowrap',
      width: '10%',
      render: (item) => {
        return (
          <LabelNew type="body" title={'จำนวนรวมของสถานะ รอดำเนินการ(10)'}>
            {item || 0}
          </LabelNew>
        )
      },
    },
    {
      // actionStatusCode = '30'
      title: <Trans>ดำเนินการแล้วเสร็จ</Trans>,
      dataIndex: 'count30',
      key: 'count30',
      align: 'center',
      className: 'text-nowrap',
      width: '10%',
      render: (item) => {
        return (
          <LabelNew type="body" title={'จำนวนรวมของสถานะ ดำเนินการแล้วเสร็จ(30)'}>
            {item || 0}
          </LabelNew>
        )
      },
    },
    {
      // actionStatusCode = NULL
      title: <Trans>ยังไม่ระบุกิจกรรมที่ดำเนินการ</Trans>,
      dataIndex: 'countNull',
      key: 'countNull',
      align: 'center',
      className: 'text-nowrap',
      width: '10%',
      render: (item) => {
        return (
          <LabelNew type="body" title={'จำนวนรวมที่ยังไม่ระบุกิจกรรมที่ดำเนินการ(is null)'}>
            {item || 0}
          </LabelNew>
        )
      },
    },
  ]
}
