import React from 'react'
import { Row, Col } from 'antd'
import ConsultationResultList from './ConsultationResultList.jsx'

const ConsultationResultIndex = (props) => {
  const { menu } = props
  const Responsive = {
    md: 24,
    lg: 24,
  }
  return (
    <>
      <Row>
        <Col {...Responsive}>
          <ConsultationResultList menu={menu} />
        </Col>
      </Row>
    </>
  )
}

export default ConsultationResultIndex
