import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import _ from 'lodash'
import { Layout, Menu, Tooltip } from 'antd'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'
import menus from '../../configs/menus.config'
import { AppLogo, SidebarLineTop, SidebarStyles } from './SidebarStyles'
import { decryptData, encryptData } from '../../utilitys/crypto'
import { IconNew } from '../../components/icon/Icon'
import { displayText } from '../../utilitys/helper'
import { USER_MENUS } from './graphql/Query'
import { SubscriptionData } from '../navbar/SubscriptionData'
import { NOTI_SIDEBAR } from '../navbar/graphql/Query'
import { NOTI_READ_BY_MENU } from '../navbar/graphql/Mutation'
import { encryptInput } from '../../utilitys/crypto'

const { Sider } = Layout
const { SubMenu } = Menu

const Sidebar = () => {
  const [collapsed, setCollaped] = useState(false)
  const onCollapse = (collapsed) => setCollaped(collapsed)
  const navigate = useNavigate()
  const { pathname } = useLocation()

  let selectedKey, openKey

  function handleClick(path) {
    navigate(path)
  }

  //Get Menus LV1
  const findLv1 = (data, pathname) => {
    return data.find((obj) => String(obj.menuPath).toLowerCase() === String(pathname).toLowerCase())
  }
  //Get Menus LV2
  const findLv2 = (data, pathname) => {
    return data.map((item) => {
      return item.children.find((obj) => String(obj.menuPath).toLowerCase() === String(pathname).toLowerCase())
    })
  }
  //Check current and set active menu item
  if (pathname) {
    const arrNamePath = pathname.split(['/'], 3)
    let search = '/' + arrNamePath[1]
    search += arrNamePath[2] ? '/' + arrNamePath[2] : ''
    const selectedLv1 = findLv1(menus, search)
    const selectedLv2 = findLv2(menus, search)
    const filteredLv2 = selectedLv2.filter(function (obj) {
      return obj !== undefined
    })
    if (selectedLv1 !== undefined) selectedKey = selectedLv1.menuCode
    if (filteredLv2[0]) {
      openKey = filteredLv2[0].menuParentCode
      selectedKey = filteredLv2[0].menuCode
    }
  }

  //--- GET NOTI ---
  const [dataNoti, setDataNoti] = useState([])
  const userLogin = decryptData(localStorage.getItem('user'))
  const dataSubNoti = SubscriptionData(userLogin.pCode, userLogin.userId)
  useEffect(() => {
    const noti = []
    const newNoti = []
    if (dataSubNoti) {
      /* Noti ที่ได้จากการ subscription */
      dataSubNoti.notification.detail.forEach((item) => {
        if (item.menuCode) {
          noti[item.menuCode] = item.readStatus === 0 ? true : false
        }
      })

      /* รวมของเก่า กับของใหม่(subscription) */
      const array = [_.assign({}, noti)].concat([_.assign({}, dataNoti)])
      if (array.length) {
        array.forEach((item) => {
          const data = _.entries(item)
          if (data.length) {
            newNoti[data[0][0]] = data[0][1]
          }
        })
      }

      setDataNoti(newNoti)
    }
  }, [dataSubNoti])

  /* Call Noti Sidebar ครั้งแรก  */
  const [callSidebarNoti, { data: dataGetNoti }] = useLazyQuery(NOTI_SIDEBAR)

  useEffect(() => {
    callSidebarNoti({
      variables: encryptInput({
        personnelCode: userLogin.pCode
      })
    })
  }, [])

  useEffect(() => {
    const noti = []
    if (dataGetNoti) {
      dataGetNoti.getNotificationSidebar.detail.forEach((item) => {
        if (item.menuCode) {
          noti[item.menuCode] = item.readStatus === 0 ? true : false
        }
      })
      setDataNoti(noti)
    }
  }, [dataGetNoti])

  /* ReadNotiByMenu สำหรับการคลิกเมนู */
  const [callReadNotiByMenu] = useMutation(NOTI_READ_BY_MENU)
  const fncReadNotiMenu = (menuCode) => {
    if (menuCode) {
      callReadNotiByMenu({
        variables: encryptInput({
          menuCode: menuCode,
          personnelCode: userLogin.pCode
        })
      })
    }
  }

  const { data: dataUserMenus } = useQuery(USER_MENUS)
  useEffect(() => {
    if (dataUserMenus) {
      const user = decryptData(localStorage.getItem('user'))
      const ciphertext = encryptData({ ...user, userMenus: dataUserMenus.getUserMenus.menus })
      localStorage.setItem('user', ciphertext)
    }
  }, [dataUserMenus])
  return (
    <SidebarStyles>
      <Sider
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        onCollapse={onCollapse}
        width={285}
        trigger={null}
        style={{ backgroundColor: '#f5f5f5' }}
      >
        <SidebarLineTop />
        <AppLogo />
        {/* <AppName>{AppConfig.appShortName ? AppConfig.appShortName : ''}</AppName> */}
        <Menu
          theme="dark"
          defaultSelectedKeys={[selectedKey]}
          mode="inline"
          defaultOpenKeys={[openKey]}
          className="menu-scroll"
        >
          {menus.map((item1) =>
            item1.children.length <= 0 ? (
              <Menu.Item
                key={item1.menuCode}
                icon={item1.icon}
                className="menu-label"
                onClick={() => {
                  handleClick(item1.menuPath)
                  let noti = []
                  if (dataNoti) {
                    noti = [...dataNoti]
                    if (item1.menuCode) {
                      delete noti[item1.menuCode]
                      fncReadNotiMenu(item1.menuCode)
                    }
                    setDataNoti(noti)
                  }
                }}
              >
                {' '}
                {displayText(item1.menuNameTH, item1.menuNameEN)}
                {item1.notification === 1
                  ? dataNoti &&
                    dataNoti[item1.menuCode] && (
                      <span className="notification">
                        <IconNew icon={'Info'} size={27} color="#F24A4A" />
                      </span>
                    )
                  : ''}
              </Menu.Item>
            ) : (
              <SubMenu
                key={item1.menuCode}
                icon={item1.icon}
                title={`${displayText(item1.menuNameTH, item1.menuNameEN)}`}
                className="menu-label"
                onTitleClick={() => {}}
              >
                {item1.children.map((item2) => (
                  <Menu.Item
                    key={item2.menuCode}
                    icon={item2.icon}
                    className="menu-label"
                    style={{ paddingLeft: item2.icon ? '35px' : '56px' }}
                    onClick={() => {
                      handleClick(item2.menuPath)
                      let noti = []
                      if (dataNoti) {
                        noti = dataNoti
                        if (item2.menuCode) {
                          delete noti[item2.menuCode]
                          fncReadNotiMenu(item2.menuCode)
                        }
                        setDataNoti(noti)
                      }
                    }}
                  >
                    <Tooltip placement="right" title={displayText(item2.menuNameTH, item2.menuNameEN)}>
                      {displayText(item2.menuNameTH, item2.menuNameEN)}
                      {item2.notification === 1
                        ? dataNoti &&
                          dataNoti[item2.menuCode] && (
                            <span className="notification">
                              <IconNew icon={'Info'} size={27} color="#F24A4A" />
                            </span>
                          )
                        : ''}
                    </Tooltip>
                  </Menu.Item>
                ))}
              </SubMenu>
            )
          )}
        </Menu>
      </Sider>
    </SidebarStyles>
  )
}
export default Sidebar
