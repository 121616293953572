import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_CHOICE_TYPES } from './graphql/Query'
import { Select } from '../../select/Select'
import { displayText } from '../../../utilitys/helper'
import { encryptInput } from '../../../utilitys/crypto'

const SelectChoiceTypes = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { choiceTypeId, choiceTypeCode, choiceTypeNameTH, choiceTypeNameEN, isActive } = filters || {}

  const [isData, isSetData] = useState([])

  const [getMasterChoiceTypesFn, getMasterChoiceTypes] = useLazyQuery(GET_MASTER_CHOICE_TYPES)

  useEffect(() => {
    const ac = new AbortController()
    getMasterChoiceTypesFn({
      variables: { input: filters ? encryptInput({ ...filters }) : encryptInput({ isActive: 1 }) },
    })
    return () => ac.abort()
  }, [choiceTypeId, choiceTypeCode, choiceTypeNameTH, choiceTypeNameEN, isActive])

  useEffect(() => {
    if (getMasterChoiceTypes.data) {
      isSetData(
        _.map(getMasterChoiceTypes?.data?.getMasterChoiceTypes, (item) => {
          return {
            label: displayText(item?.choiceTypeNameTH, item?.choiceTypeNameEN),
            value: item?.choiceTypeCode,
            source: item,
          }
        }),
      )
    }
  }, [getMasterChoiceTypes.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterChoiceTypes.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectChoiceTypes
