import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Form, Row } from 'antd'
import { useMutation } from '@apollo/client'

import { SAVE_RATE_RISK_RULE } from './graphql/Mutation'

import { ModalNew as Modal } from '../../../../components/modal/Modal'
import { InputNew as Input } from '../../../../components/input/Input'
import { Radio, RadioGroup } from '../../../../components/radio/Radio'
import { Alert } from '../../../../components/alert/Alert'
import { displayError, displayText, regexEng, regexEngNoSpace, regexNumber } from '../../../../utilitys/helper'
import { TextAreaNew } from '../../../../components/textarea/TextArea'
import { encryptInput } from '../../../../utilitys/crypto'
import { SpinnersNew } from '../../../../components/spinners/Spinners'
import SelectRateRiskFactorProcess from '../../../../components/inputfromapi/selectrateriskfactorprocess/SelectRateRiskFactorProcess'

const RateRiskRulesForm = ({
  visible = false,
  formType = 'view',
  rateFacGroupCode = null,
  dataForm = null,
  onClose = () => {},
  onSuccess = () => {},
}) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    if (dataForm && visible) {
      form.setFieldsValue(dataForm)
    }
  }, [dataForm, visible])

  const [editable, seteditable] = useState(false)

  useEffect(() => {
    if (dataForm && visible) {
      form.setFieldsValue(dataForm)
    }
  }, [dataForm, visible])

  const [callSave, { loading: loadingSave, error: errorSave, data: dataSave }] = useMutation(SAVE_RATE_RISK_RULE)

  useEffect(() => {
    if (errorSave) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถบันทึกข้อมูลได้'),
        onOk() {},
        content: t(displayError(errorSave)),
      })
    }
  }, [errorSave])

  useEffect(() => {
    if (dataSave) {
      Alert({
        type: 'success',
        title: t('บันทึกสำเร็จ'),
        onOk() {
          form.resetFields()
          onSuccess()
        },
      })
    }
  }, [dataSave])

  useEffect(() => {
    if (formType) {
      if (formType === 'add') form.resetFields()
      seteditable(formType !== 'view')
    }
  }, [formType])

  const onFinish = () => {
    const field = form.getFieldValue()

    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการบันทึก?'),
      onOk() {
        const dataInput = {
          rateFacProCode: field.rateFacProCode,
          rateRiskRuleCode: field.rateRiskRuleCode,
          sort: field.sort,
          isActive: field.isActive,
        }
        if (formType === 'add') {
          dataInput.rateRiskRuleTH = field.rateRiskRuleTH
          dataInput.rateRiskRuleEN = field.rateRiskRuleEN ? field.rateRiskRuleEN : field.rateRiskRuleTH
          dataInput.typeAction = 'add'
        } else {
          dataInput.rateRiskRuleTH = field.rateRiskRuleTH
          dataInput.rateRiskRuleEN = field.rateRiskRuleEN
          dataInput.typeAction = 'edit'
        }

        callSave({
          variables: {
            input: encryptInput(dataInput),
          },
        })
      },
      onCancel() {},
    })
  }

  const footerHide =
    formType === 'view'
      ? { cancelButtonProps: { style: { display: 'none' } }, okButtonProps: { style: { display: 'none' } } }
      : {}

  const renderTitle = () => {
    if (formType === 'add') {
      return t('เพิ่มเกณฑ์ ระเบียบ คำสั่ง')
    }
    if (formType === 'view') {
      return t('ดูเกณฑ์ ระเบียบ คำสั่ง')
    }
    if (formType === 'edit') {
      return t('แก้ไขเกณฑ์ ระเบียบ คำสั่ง')
    }

    return ''
  }

  const formName = 'rule'

  return (
    <React.Fragment>
      {loadingSave && <SpinnersNew />}
      <Modal
        visible={visible}
        onSubmit={form.submit}
        onClose={(data) => {
          onClose(data)
          form.resetFields()
        }}
        testTitle={renderTitle()}
        typeAction={formType}
        type="medium"
        btnName={t('บันทึก')}
        {...footerHide}
      >
        <Form
          form={form}
          onFinish={onFinish}
          autoComplete="off"
          labelAlign="left"
          labelCol={{
            span: 6,
            offset: 3,
            md: 8,
            lg: 8,
          }}
          wrapperCol={{
            md: 8,
            lg: 8,
          }}
          layout="horizontal"
          name={formName}
        >
          <Form.Item
            name={'rateRiskRuleCode'}
            label={t('รหัสเกณฑ์ ระเบียบ คำสั่ง')}
            rules={[
              {
                required: true,
                message: t('กรุณาระบุ'),
              },
              {
                pattern: regexEngNoSpace,
                message: t(`กรุณากรอกด้วยภาษาอังกฤษพิมพ์ใหญ่,ตัวเลข A-Z, 0-9 ไม่เกิน 10 ต้ว`),
              },
            ]}
          >
            <Input disabled={formType === 'edit' || !editable} placeholder={t('กรอกรหัสเกณฑ์ ระเบียบ คำสั่ง')} />
          </Form.Item>
          <Form.Item
            name={'rateRiskRuleTH'}
            label={t('ชื่อเกณฑ์ ระเบียบ คำสั่งภาษาไทย')}
            rules={[
              {
                required: true,
                message: t('กรุณาระบุ'),
              },
            ]}
          >
            <TextAreaNew
              disabled={!editable}
              placeholder={t('กรอกชื่อเกณฑ์ ระเบียบ คำสั่งภาษาไทย')}
              maxLength={8000}
              rows={5}
            />
          </Form.Item>
          <Form.Item
            name={'rateRiskRuleEN'}
            label={t('ชื่อเกณฑ์ ระเบียบ คำสั่งภาษาอังกฤษ')}
            rules={[{ pattern: regexEng, message: t(`กรุณากรอกด้วยภาษาอังกฤษหรือตัวเลข A-Z, a-z, 0-9`) }]}
          >
            <TextAreaNew
              disabled={!editable}
              placeholder={t('กรอกชื่อเกณฑ์ ระเบียบ คำสั่งภาษาอังกฤษ')}
              maxLength={8000}
              rows={5}
            />
          </Form.Item>
          <Form.Item
            name={'rateFacProCode'}
            label={t('ความเสี่ยงกระบวนการ')}
            rules={[
              {
                required: true,
                message: t('กรุณาระบุ'),
              },
            ]}
          >
            <SelectRateRiskFactorProcess
              filters={{ isActive: 1, rateFacGroupCode: rateFacGroupCode }}
              disabled={!editable}
              formname={formName}
              placeholder={t('เลือกประเภทการควบคุม')}
              handleChange={(data) => {
                const name = data ? displayText(data.source.rateFacProNameTH, data.source.rateFacProNameEN) : null
                const value = data ? data.source.rateFacProCode : null
                form.setFieldsValue({ rateFacProName: name, rateFacProCode: value })
              }}
            />
          </Form.Item>

          <Form.Item
            name={'sort'}
            label={t('ลำดับ')}
            rules={[
              { required: true, message: t('กรุณาระบุ') },
              {
                pattern: regexNumber,
                message: t(`กรุณากรอกตัวเลข 0-9`),
              },
            ]}
          >
            <Input disabled={!editable} placeholder={t('กรอกลำดับ')} />
          </Form.Item>

          <Form.Item name={'isActive'} label={t('สถานะ')} initialValue={1}>
            <RadioGroup disabled={!editable}>
              <Row>
                <Col style={{ marginRight: 20 }}>
                  <Radio value={1}>{t('ใช้งาน')}</Radio>
                </Col>
                <Col>
                  <Radio value={0}>{t('ไม่ใช้งาน')}</Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
        </Form>
      </Modal>
    </React.Fragment>
  )
}
export default RateRiskRulesForm
