import React, { useEffect, useState } from 'react'
import * as _ from 'lodash'
import { useLazyQuery } from '@apollo/client'
import { GET_MASTER_PROJECT_REFER } from './graphql/Query'
import { Select } from '../../select/Select'
import { encryptInput } from '../../../utilitys/crypto'
import { displayText } from '../../../utilitys/helper'

const SelectProjectRefer = (props) => {
  const { filters, placeholder, formname, handleChange, ...otherProp } = props
  const { isActive } = filters || {}

  const [isData, isSetData] = useState([])

  const [getMasterProjectReferFn, getMasterProjectRefer] = useLazyQuery(GET_MASTER_PROJECT_REFER)

  useEffect(() => {
    getMasterProjectReferFn({
      variables: { input: filters ? encryptInput({ ...filters, isActive: 1 }) : encryptInput({ isActive: 1 }) },
    })
  }, [isActive])

  useEffect(() => {
    if (getMasterProjectRefer.data) {
      isSetData(
        _.map(getMasterProjectRefer?.data?.getMasterProjectRefer, (item) => {
          return {
            label: displayText(item?.projectRefNameTH, item?.projectRefNameEN),
            value: item?.projectRefCode,
            source: item,
          }
        }),
      )
    }
  }, [getMasterProjectRefer.data])

  return (
    <>
      <Select
        {...otherProp}
        data={isData}
        placeholder={placeholder}
        scrollableId={formname}
        showSearch
        loading={getMasterProjectRefer.loading}
        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        onChange={(value) => handleChange(_.find(isData, (data) => data.value === value))}
      />
    </>
  )
}

export default SelectProjectRefer
