import React from 'react'
import { Col } from 'antd'
import { Filter } from '../../../components/filter/Filter'
import { CodeBlock } from '../../../components/codeblock/CodeBlock'

const FilterIndex = () => {
  const inputList = [
    {
      type: 'text',
      name: 'firstname',
      label: 'ชื่อผู้ใช้งาน',
      placeholder: 'ระบุชื่อผู้ใช้งาน',
      defaultValues: null,
      col: { sm: 24, md: 12, lg: 8 },
    },
    {
      type: 'number',
      name: 'personalId',
      label: 'รหัสบัตรประชาขน',
      placeholder: 'ระบุรหัสบัตรประชาขน',
      defaultValues: null,
      col: { sm: 24, md: 12, lg: 8 },
    },
    {
      type: 'select',
      name: 'Year',
      label: 'ประจำปี',
      placeholder: 'ระบุประจำปี',
      defaultValues: null,
      col: { sm: 24, md: 12, lg: 8 },
    },
    {
      type: 'datepicker',
      name: 'startDate',
      label: 'วันที่เริ่มต้น',
      placeholder: 'ระบุวันที่เริ่มต้น',
      defaultValues: null,
      col: { sm: 24, md: 12, lg: 8 },
    },
    {
      type: 'timepicker',
      name: 'startTime',
      label: 'เวลาเริ่ม',
      placeholder: 'ระบุเวลาเริ่ม',
      defaultValues: null,
      col: { sm: 24, md: 12, lg: 8 },
    },
    {
      type: 'tag',
      name: 'dataType',
      label: 'ประเภทข้อมูล',
      placeholder: 'ระบุประเภทข้อมูล',
      defaultValues: null,
      col: { sm: 24, md: 12, lg: 8 },
    },
  ]

  const code = `
    import { Filter } from '../../../components/filter/Filter'

    const inputList = [
      {
        type: 'text',
        name: 'firstname',
        label: 'ชื่อผู้ใช้งาน',
        placeholder: 'ระบุชื่อผู้ใช้งาน',
        defaultValues: null,
        col: { sm: 24, md: 12, lg: 8 },
      },
      {
        type: 'number',
        name: 'personalId',
        label: 'รหัสบัตรประชาขน',
        placeholder: 'ระบุรหัสบัตรประชาขน',
        defaultValues: null,
        col: { sm: 24, md: 12, lg: 8 },
      },
      {
        type: 'select',
        name: 'Year',
        label: 'ประจำปี',
        placeholder: 'ระบุประจำปี',
        defaultValues: [
          { value: '2564', label: '2564' },
          { value: '2565', label: '2565' },
          { value: '2566', label: '2566' },
          { value: '2567', label: '2567' },
        ],
        col: { sm: 24, md: 12, lg: 8 },
      },
      {
        type: 'datepicker',
        name: 'startDate',
        label: 'วันที่เริ่มต้น',
        placeholder: 'ระบุวันที่เริ่มต้น',
        defaultValues: null,
        col: { sm: 24, md: 12, lg: 8 },
      },
      {
        type: 'timepicker',
        name: 'startTime',
        label: 'เวลาเริ่ม',
        placeholder: 'ระบุเวลาเริ่ม',
        defaultValues: null,
        col: { sm: 24, md: 12, lg: 8 },
      },
      {
        type: 'tag',
        name: 'dataType',
        label: 'ประเภทข้อมูล',
        placeholder: 'ระบุประเภทข้อมูล',
        defaultValues: [
          { value: '2564', label: '2564' },
          { value: '2565', label: '2565' },
          { value: '2566', label: '2566' },
          { value: '2567', label: '2567' },
        ],
        col: { sm: 24, md: 12, lg: 8 },
      },
    ]

    export default function App(props) {
      return (
        <Filter inputList={inputList} onFinishFn={(data) => console.log("DATA: ", data)}/>
      )
    }
  `

  return (
    <div className="site-layout-background">
      <Col>
        <Filter inputList={inputList} onFinishFn={(data) => console.log('DATA: ', data)} />
      </Col>
      <Col>
        <CodeBlock code={code} />
      </Col>
    </div>
  )
}

export default FilterIndex
