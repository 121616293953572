import React, { useEffect } from 'react'
import { Row, Col, Form } from 'antd'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { ModalNew } from '../../../../../components/modal/Modal'
import { SpinnersNew } from '../../../../../components/spinners/Spinners'
import { Alert } from '../../../../../components/alert/Alert'
import { LabelNew as Label } from '../../../../../components/label/Label'
import SelectStepModels from '../../../../../components/inputfromapi/selectstepmodels/SelectStepModels'
import { SAVE_ADHOCJOB_STEP } from '../../graphql/Mutation'
import { encryptInput } from '../../../../../utilitys/crypto'
import { displayError } from '../../../../../utilitys/helper'

const AdhocjobStepModel = (props) => {
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const ResponsiveModal = {
    md: 20,
    lg: 20,
  }

  const { refCode, typeAction } = props

  /* ---------------- API ----------- */
  const [
    saveAdhocJobStepFn,
    { loading: loadingSaveAdhocJobStep, error: errorSaveAdhocJobStep, data: saveAdhocJobStep },
  ] = useMutation(SAVE_ADHOCJOB_STEP)

  const onFinish = (input) => {
    saveAdhocJobStepFn({
      variables: {
        input: encryptInput({
          jobCode: refCode,
          stepModelCode: input.stepModelCode,
        }),
      },
    })
  }

  useEffect(() => {
    form.resetFields()
    props.setVisible(false)
    props.refetch()
  }, [saveAdhocJobStep])

  useEffect(() => {
    if (errorSaveAdhocJobStep) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถเพิ่มข้อมูลได้'),
        content: t(displayError(errorSaveAdhocJobStep)),
        onOk() {},
      })
    }
  }, [errorSaveAdhocJobStep])

  return (
    <>
      {loadingSaveAdhocJobStep && <SpinnersNew />}
      <Form
        form={form}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        labelCol={{ md: { span: 8 }, lg: { span: 8 } }}
        wrapperCol={{ md: { span: 16 }, lg: { span: 16 } }}
        layout="horizontal"
        name="stepModelForm"
      >
        <ModalNew
          visible={props.visible}
          onSubmit={form.submit}
          onClose={(e) => {
            form.resetFields(), props.setVisible(e)
          }}
          testTitle={t('คลังขั้นตอนการปฏิบัติงาน')}
          okButtonProps={typeAction === 'view' ? { style: { display: 'none' } } : {}}
        >
          <Row justify="center">
            <Col {...ResponsiveModal}>
              <Form.Item
                name="stepModelCode"
                label={<Label type="tab-header-inactive">{t('คลังขั้นตอนการปฏิบัติงาน')}</Label>}
                rules={[{ required: true, message: t('กรุณาเลือกขั้นตอนการปฏิบัติงาน') }]}
              >
                <SelectStepModels
                  placeholder={t('เลือกขั้นตอนการปฏิบัติงาน')}
                  formname={'stepModelForm'}
                  handleChange={(e) => form.setFieldsValue({ stepModelCode: e?.value })}
                />
              </Form.Item>
            </Col>
          </Row>
        </ModalNew>
      </Form>
    </>
  )
}

export default AdhocjobStepModel
