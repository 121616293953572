import { Calendar } from '@fullcalendar/core'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import moment from 'moment-timezone'

import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { CalendarTimelineStyles } from './CalendarTimelineStyles'

export const CalendarTimeline = (props) => {
  const { headerContent, path, groups, items, year, footerToolRight } = props
  const navigate = useNavigate()
  const calendarRef = useRef(null)
  const color = {
    graphHeaderMonthNameBackground: '#C1C8C7',
    graphHeaderMonthNameText: '#FFFFFF',
    graphHeaderDatehNameBackground: '#FFFFFF',
    graphHeaderDatehNameText: '#000000',
    graphCerrentDateBackground: '#B2B2B2',
    graphSanAndSunDateBackground: '#e8d595',
    graphHolidayType1DateBackground: '#FDFFBC',
    graphHolidayType2DateBackground: '#FFFDDD',
    statusNoproject: '#C1C8C7',
    statusNoActionPlan: '#FAE0D8',
    statusActionPlanDontApprove: '#f7d4de',
    statusWaitForProcess: '#a0c5e2',
    statusProcessing: '#2676bb',
    statusProcessed: '#54a648',
    statusLate: '#C43D56'
  }

  useEffect(() => {
    const fixColors = (val) => {
      if (
        val.title.approveStatusCode === '20' &&
        !val.title.countStatus10 &&
        !val.title.countStatus20 &&
        !val.title.countStatus30
      ) {
        return val.title.statusActionPlan === 'noActionPlan'
          ? color.statusNoActionPlan
          : val.title.statusActionPlan === 'dontApprove'
          ? color.statusActionPlanDontApprove
          : color.statusNoActionPlan
      } else {
        return val.title.late
          ? color.statusLate
          : val.title.countStatus30
          ? color.statusProcessed
          : val.title.countStatus20
          ? color.statusProcessing
          : val.title.countStatus10
          ? color.statusWaitForProcess
          : color.statusNoproject
      }
    }

    const fixTitle = (val) => {
      return val.countStatus30
        ? val.countStatus30 + '%'
        : val.countStatus20
        ? val.countStatus20 + '%'
        : val.countStatus10
        ? val.countStatus10 + '%'
        : ''
    }

    let resource = groups?.map((data) => {
      return {
        id: data.id,
        dateStart: data.dateStart,
        dateEnd: data.dateEnd,
        title: data.title,
        eventColor: fixColors(items.find((val) => val.group === data.id))
      }
    })

    let event = items?.map((data) => {
      return {
        id: data.id,
        resourceId: data.group,
        start: moment(data.start_time).tz('Asia/Bangkok').format('YYYY-MM-DDTHH:mm:ss.SS'),
        end: moment(data.end_time).tz('Asia/Bangkok').format('YYYY-MM-DDT23:59:59.59'),
        title: data.title,
        extendedProps: {
          description: data.title
        }
      }
    })

    const fixYear = year ? year.toString() : undefined
    const footerRight = footerToolRight ? 'prev,next' : ''

    if (calendarRef.current) {
      const calendar = new Calendar(calendarRef.current, {
        plugins: [resourceTimelinePlugin],
        timeZone: 'UTC',
        initialView: 'resourceTimelineYear',
        initialDate: moment(fixYear).tz('Asia/Bangkok').format('YYYY-MM-DDTHH:mm:ss.SS'),
        scrollTime: moment().format('HH:mm:ss'),
        aspectRatio: 1.6,
        headerToolbar: {
          left: '',
          center: '',
          right: ''
        },
        footerToolbar: {
          left: '',
          center: '',
          right: footerRight
        },
        views: {
          resourceTimelineYear: {
            slotLabelFormat: [
              { month: 'long', year: 'numeric' },
              { day: 'numeric', month: 'short' }
            ],
            slotLabelContent: function (arg) {
              if (arg.level === 0) {
                const date = arg.date
                const month = date.toLocaleString('th-TH', { month: 'long' })
                const year = date.getFullYear() + 543
                return {
                  html: `<div class="label-content-mouth">${month} ${year}</div>`
                }
              }
              if (arg.level === 1) {
                const date = arg.date
                const day = date.getDate()
                return { html: `<div class="label-content-day" >${day}</div>` }
              }
            }
          }
        },
        editable: true,
        resourceAreaWidth: '380px',
        resourceAreaHeaderContent: headerContent,
        resources: resource,
        events: event,
        eventContent: function (info) {
          return { html: `<div class="fc-event-title">${fixTitle(info.event.extendedProps.description)}</div>` }
        },
        eventClick: function (info) {
          console.log('Event: ' + info.event.title)
        },
        resourceLabelDidMount: function (info) {
          const additionalText = document.createElement('div')
          additionalText.innerHTML = `<div class="additional-text">${info.resource._resource.extendedProps.dateStart} - ${info.resource._resource.extendedProps.dateEnd}</div>`

          const cellMain = info.el.querySelector('.fc-datagrid-cell-main')
          if (cellMain) {
            cellMain.appendChild(additionalText)
          }

          info.el.addEventListener('click', () => {
            navigate(path, {
              state: {
                refCode: info.resource.id,
                year: fixYear
              }
            })
          })

          info.el.classList.add('clickable-resource')
        }
      })

      calendar.render()
    }
  }, [headerContent, path, groups, items, color, year, footerToolRight])

  return (
    <CalendarTimelineStyles>
      <div ref={calendarRef} id="calendar" />
    </CalendarTimelineStyles>
  )
}
