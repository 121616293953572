import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Row, Col } from 'antd'
import * as _ from 'lodash'
import { useLocation, Navigate } from 'react-router-dom'
import { useLazyQuery, useMutation } from '@apollo/client'
import { Datatable } from '../../../../components/datatable/Datatable.jsx'
import { Status } from '../../../../components/status/Status'
import { ButtonNew } from '../../../../components/button/Button'
import { ButtonGroup } from '../../../../components/button/ButtonGroup'
import { SpinnersNew } from '../../../../components/spinners/Spinners.jsx'
import { GET_AUDIT_CAUSE_OF_ISSUES_PAGINATION } from '../graphql/Query'
import { DELETE_AUDIT_CAUSE_OF_ISSUES } from '../graphql/Mutation'
import { encryptInput } from '../../../../utilitys/crypto'
import { displayText, displayError } from '../../../../utilitys/helper'
import { Alert } from '../../../../components/alert/Alert'
import AuditResultTwoModal from '../components/modal/AuditResultTwoModal.jsx'
import { handleSort } from '../../../../utilitys/pagination'

const TabTwo = (props) => {
  const { t } = useTranslation()
  const { menu } = props
  const [dataLists, setDataLists] = useState([])
  const { state } = useLocation()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [sort, setSort] = useState([])
  const [visible, setVisible] = useState(false)
  const [status, setStatus] = useState(false)
  const [count, setCount] = useState(0)
  const [objData, setObjData] = useState({
    type: 'add',
    causeId: 0,
  })

  if (!state) {
    return <Navigate to={'/auditoperations/auditresult/'} />
  }

  const [pagination, { data: dataPagination, loading: loadingPagination, error: errorPagination }] = useLazyQuery(
    GET_AUDIT_CAUSE_OF_ISSUES_PAGINATION,
  )

  const [cellDelete, { data: dataDelete, loading: loadingDelete, error: errorDelete }] =
    useMutation(DELETE_AUDIT_CAUSE_OF_ISSUES)

  useEffect(() => {
    if (dataDelete) {
      if (dataDelete.auditCauseOfIssuesDelete.causeId) {
        Alert({
          type: 'success',
          title: t('ลบข้อมูลสำเร็จ'),
          onOk() {
            pagination({
              variables: {
                input: encryptInput({
                  filters: { projectCode: state.refCode },
                  pagination: { limit, page },
                  sort: sort,
                }),
              },
            })
          },
        })
      }
    }
    if (errorDelete) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถลบข้อมูลได้`),
        content: t(displayError(errorDelete)),
        onOk() {},
      })
    }
  }, [dataDelete])

  useEffect(() => {
    if (status) {
      pagination({
        variables: {
          input: encryptInput({
            filters: { projectCode: state.refCode },
            pagination: { limit, page },
            sort: sort,
          }),
        },
      })
      setStatus(false)
    }
  }, [status])

  useEffect(() => {
    pagination({
      variables: {
        input: encryptInput({
          filters: { projectCode: state.refCode },
          pagination: { limit, page },
          sort: sort,
        }),
      },
    })
  }, [page, limit, sort])

  useEffect(() => {
    if (dataPagination) {
      let response = dataPagination.auditCauseOfIssuesPaginationGet
      setCount(response.count)
      setDataLists(
        _.map(response.result, (item, keys) => {
          let show = true
          let show2 = true
          let issueName = displayText(item.issueTH, item.issueEN)
          let causeName = displayText(item.causeNameTH, item.causeNameEN)
          if (keys !== 0) {
            show = response.result[keys - 1].rateFacProCode !== item.rateFacProCode ? true : false
          }

          if (keys !== 0) {
            const previousIssue = displayText(response.result[keys - 1].issueTH, response.result[keys - 1].issueEN)
            show2 = previousIssue !== item.issueName ? true : false
          }

          return {
            key: keys + 1,
            show: show,
            show2: show2,
            impactRisk: item.rateFacProCode + ' : ' + displayText(item.rateFacProNameTH, item.rateFacProNameEN),
            issueItem: issueName,
            causeType: causeName,
            description: displayText(item.causeDetailTH, item.causeDetailEN),
            isActive: <Status text={item.isActive === '1' ? 'ใช้งาน' : 'ไม่ใช้งาน'} />,
            tools: (
              <div style={{ textAlign: 'right' }}>
                <ButtonGroup
                  menu={menu}
                  icons={[
                    {
                      type: 'view',
                      onClick: () => {
                        setVisible(true)
                        setObjData({ type: 'view', causeId: item.causeId })
                      },
                    },
                    {
                      type: 'edit',
                      disabled: state.type_action === 'view' ? true : false,
                      onClick: () => {
                        setVisible(true)
                        setObjData({ type: 'edit', causeId: item.causeId })
                      },
                    },
                    {
                      type: 'delete',
                      disabled: state.type_action === 'view' ? true : false,
                      onClick: async () => {
                        fnConfirm(item.causeId)
                      },
                    },
                  ]}
                />
              </div>
            ),
          }
        }),
      )
    }
    if (errorPagination) {
      Alert({
        type: 'error',
        title: t(`ไม่สามารถโหลดข้อมูลได้`),
        content: t(displayError(errorPagination)),
        onOk() {},
      })
    }
  }, [dataPagination])

  const fnConfirm = (causeId) => {
    Alert({
      type: 'confirm',
      title: t('ต้องการยืนยันการลบ ?'),
      onOk() {
        onSubmit(causeId)
      },
      onCancel() {},
    })
  }

  const onSubmit = async (causeId) => {
    let variables = encryptInput({ causeId: causeId })
    cellDelete({
      variables,
    })
  }

  const columnsQuestion = [
    {
      title: t('ปัจจัยความเสี่ยง'),
      dataIndex: 'impactRisk',
      width: '20%',
      align: 'left',
      // sorter: true
      render: (item, record) => {
        if (record.show) {
          return <div>{record.impactRisk}</div>
        } else {
          return <></>
        }
      },
    },
    {
      title: t('ข้อสังเกตุ'),
      dataIndex: 'issueItem',
      width: '15%',
      align: 'left',
      // sorter: true,
      render: (item, record) => {
        if (record.show2) {
          return <div>{record.issueItem}</div>
        } else {
          return <></>
        }
      },
    },
    {
      title: t('ประเภทสาเหตุ'),
      dataIndex: 'causeType',
      width: '20%',
      align: 'left',
      // sorter: true,
    },
    {
      title: t('คำอธิบาย'),
      dataIndex: 'description',
      width: '20%',
      align: 'left',
      // sorter: true,
    },
    {
      title: t('สถานะ'),
      dataIndex: 'isActive',
      width: '15%',
      align: 'center',
      // sorter: true,
    },
    {
      title: '',
      dataIndex: 'tools',
      width: '10%',
    },
  ]

  return (
    <>
      {(loadingPagination || loadingDelete) && <SpinnersNew />}
      <Row>
        <Col md={24}>
          <Datatable
            columns={columnsQuestion}
            data={dataLists}
            enableScroll={{ x: true, y: true }}
            searchCustom={true}
            total={count}
            pageSize={limit}
            page={page}
            paginationCustom={true}
            onChangePagination={({ page, pageSize }) => Promise.all([setPage(page), setLimit(pageSize)])}
            handleTableChange={(e) => {
              const reNewField = [
                {
                  issueItem: localStorage.getItem('lang') === 'th' ? 'issueTH' : 'issueEN',
                  causeType: localStorage.getItem('lang') === 'th' ? 'causeNameTH' : 'causeNameEN',
                  description: localStorage.getItem('lang') === 'th' ? 'causeDetailTH' : 'causeDetailEN',
                  impactRisk: localStorage.getItem('lang') === 'th' ? 'rateFacProNameTH' : 'rateFacProNameEN',
                },
              ]
              setSort(handleSort(e?.sorter, reNewField))
            }}
            btnAdd={
              <>
                {state.type_action === 'add' || state.type_action === 'edit' ? (
                  <ButtonNew
                    type="plusTableBorderPrimary"
                    roles={{ type: 'add', menu: menu }}
                    style={{ marginRight: '12px' }}
                    onClick={() => {
                      setObjData({ type: 'add' })
                      setVisible(true)
                    }}
                  >
                    {t('สาเหตุ')}
                  </ButtonNew>
                ) : (
                  ''
                )}
              </>
            }
          />
        </Col>
      </Row>
      <AuditResultTwoModal
        open={visible}
        close={(e) => setVisible(e)}
        projectCode={state.refCode}
        objData={objData}
        setStatus={setStatus}
      />
    </>
  )
}

export default TabTwo
