import React, { useState, useEffect } from 'react'
import { Form, Row, Col } from 'antd'
import { ModalNew } from '../../../../../../components/modal/Modal.jsx'
import { Alert } from '../../../../../../components/alert/Alert.js'
import { useTranslation } from 'react-i18next'
import { InputNew } from '../../../../../../components/input/Input.jsx'
import { RadioGroup, Radio } from '../../../../../../components/radio/Radio.jsx'
import { LabelNew as Label } from '../../../../../../components/label/Label.jsx'
import { useLazyQuery } from '@apollo/client'
import { encryptInput } from '../../../../../../utilitys/crypto.js'
import { GET_DATA_OVERVIEW_AUDIT_TABLE_LIST_EXPORT } from '../../graphql/Query.js'
import { SpinnersNew } from '../../../../../../components/spinners/Spinners.jsx'
import { displayError } from '../../../../../../utilitys/helper.js'

const ExportModal = (props) => {
  const { t } = useTranslation()
  const { dataFilter, isOpen, close } = props
  const [disableAction, setDisableAction] = useState(false)
  const [form] = Form.useForm()

  /* ---------------- API ----------- */
  const [ApiExportFn, { data: dataExport, loading: loadingExport, error: errorExport }] = useLazyQuery(
    GET_DATA_OVERVIEW_AUDIT_TABLE_LIST_EXPORT
  )

  /* ---------------- EFFECT ----------- */
  useEffect(() => {
    if (dataExport) {
      const { fileUrl } = dataExport.getOverviewAuditTableListExport
      window.open(fileUrl)
    }

    if (errorExport) {
      Alert({
        type: 'error',
        title: t('ไม่สามารถโหลดข้อมูลได้'),
        content: t(displayError(errorExport)),
        onOk() {}
      })
    }
  }, [dataExport])

  const Export = async (input) => {
    const dataInput = {
      fileType: input.fileType,
      ...dataFilter,
      watermark: input.waterMark || '',
      lang: localStorage.getItem('lang')
    }

    await ApiExportFn({
      variables: {
        input: encryptInput(dataInput)
      }
    })
  }

  const onSubmit = (input) => {
    Export(input)
  }

  return (
    <>
      {loadingExport ? <SpinnersNew /> : ''}
      <ModalNew
        visible={isOpen}
        testTitle={t('พิมพ์รายงาน')}
        onSubmit={() => {
          form
            .validateFields()
            .then((v) => onSubmit(v))
            .catch(() => {})
        }}
        onClose={() => close()}
        btnName={t('พิมพ์')}
      >
        <Form
          form={form}
          labelAlign="left"
          labelCol={{
            span: 8,
            offset: 2
          }}
          wrapperCol={{
            span: 10
          }}
          layout="horizontal"
          name="auditTeamForm"
        >
          <Form.Item
            label={<Label type="tab-header-inactive">{t('ประเภทรายงาน')}</Label>}
            name="fileType"
            initialValue={'pdf'}
            onClick={(e) => {
              if (e.target.value) {
                if (e.target.value !== 'pdf') {
                  form.setFieldsValue({
                    waterMark: ''
                  })
                  setDisableAction(true)
                } else {
                  setDisableAction(false)
                }
              }
            }}
          >
            <RadioGroup>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <Radio value="pdf">
                    <Label type="tab-header-inactive">{t('PDF')}</Label>
                  </Radio>
                </Col>
                <Col span={12}>
                  <Radio value="excel">
                    <Label type="tab-header-inactive">{t('Excel')}</Label>
                  </Radio>
                </Col>
              </Row>
            </RadioGroup>
          </Form.Item>
          {!disableAction ? (
            <Form.Item name="waterMark" label={<Label type="tab-header-inactive">{t('ลายน้ำ')}</Label>}>
              <InputNew disabled={disableAction} />
            </Form.Item>
          ) : (
            ''
          )}
        </Form>
      </ModalNew>
    </>
  )
}

export default ExportModal
